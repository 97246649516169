import React from 'react';
import { getRemSize } from 'shared-components/StyledComponents/functions';
import { styled } from '@mui/system';
import { theme } from 'theme';
import { Accordion, AccordionSummary, AccordionDetails, Typography, Stack, Box, Avatar } from '@mui/material';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';

interface StyledProps {
  $isOpen?: boolean;
}

interface HAAccordionIProps {
  title: string;
  subtitle?: string | JSX.Element;
  badgeValue?: string | number;
  startOpen?: boolean;
  children: React.ReactNode;
  readOnly?: boolean;
}

const StyledAccordionTrigger = styled('button')`
  align-items: center;
  text-align: left;
  padding: 18px 0;
  border: none;
  background: none;
  width: 100%;
  font-weight: 500;
  font-size: ${getRemSize(16)};
  transition: background-color 0.3s ease;
  background-color: white;

  &.disabled {
    background-color: white !important;
  }

  &:hover,
  &:focus {
    outline: unset;
  }

  svg {
    position: relative;
    bottom: 10;
    float: right;
    path {
      fill: ${(props) => props.theme.palette.primary.main};
    }
  }
`;

const StyledAccordionContent = styled('div')`
  padding: 8px 0;
  background-color: ${(props) => props.theme.palette.grey[100]};

  & > *:last-child {
    margin-bottom: 0 !important;
  }
`;

const StyledAccordionContainer = styled('div')<StyledProps>`
  width: 98%;
  margin-bottom: 8px;
  border: unset,
  overflow: ${({ $isOpen }: StyledProps): string => ($isOpen ? 'visible' : 'hidden')};

  svg {
    margin-right: 10px;
    transition: transform 0.15s ease-in-out;
  }
`;

const StyledBadge = styled('span')`
  margin-left: 14px;
  width: 20px;
  height: 20px;
  background-color: ${(props) => props.theme.palette.info.main};
  color: ${(props) => props.theme.palette.info.contrastText};
  font-weight: 700;
  border-radius: 50%;
  display: inline-block;
  text-align: center;
  font-size: 12px;
  line-height: 20px;
`;

const StyledTitle = styled('span')`
  line-height: 28px;
  font-size: 18px;
  color: ${(props) => props.theme.palette.grey[600]};
  vertical-align: middle;
  font-weight: 700;
`;

const StyledSubtitleDiv = styled('div')`
  background: unset;
  display: block;
  font-size: 13px;
  font-weigth: 400;
  line-height: 15px;
  padding-top: 8px;
  color: ${(props) => props.theme.palette.grey[600]};
  max-width: 80%;
`;

const HAAccordion = (props: HAAccordionIProps): JSX.Element => {
  const { title, subtitle, badgeValue, startOpen, children } = props;
  const readOnly = props.readOnly || false;
  return (
    <Accordion defaultExpanded={startOpen} disabled={readOnly} sx={{ marginBottom: '8px' }}>
      <AccordionSummary expandIcon={<ExpandMoreIcon />}>
        <Stack direction="column">
          <Stack direction="row" alignItems="center">
            <Typography variant="subtitle1" fontWeight={700} color={theme.palette.grey[600]}>
              {title}
            </Typography>
            {!!badgeValue && (
              <Avatar
                sx={{ width: '20px', height: '20px', fontSize: '12px', fontWeight: 700 }}
                data-test-id={`badge-${title}`}>
                {badgeValue}
              </Avatar>
            )}
          </Stack>
          {subtitle && (
            <Typography variant="body2" color={theme.palette.grey[600]}>
              {subtitle}
            </Typography>
          )}
        </Stack>
      </AccordionSummary>
      <AccordionDetails>
        <Box sx={{ backgroundColor: theme.palette.grey[100] }}>{children}</Box>
      </AccordionDetails>
    </Accordion>
  );
};

export default HAAccordion;
