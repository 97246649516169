import Dashboards from '@highcharts/dashboards';
import DataPoolOptions from '@highcharts/dashboards/es-modules/Data/DataPoolOptions';
import ChartStackedColumn from '../Charts/ChartStackedColumn';
import SubChartBar from '../Charts/SubChartBar';
import { EditModeConfig, GuiConfig, DashboardConfig, SUB_CHART_LIMIT } from './DefaultConfig';
import { aggregateStackedBarData, aggregateData } from '../../Utils/dataFunctions';

const Diagnosis = ({
  theme,
  data,
  subChartData,
  dateFilterOverride,
  selectedIndex,
  seeMoreUpdated,
  seeMoreBottomLeft,
  seeMoreBottomRight,
  toggleSeeMoreBottomLeft,
  toggleSeeMoreBottomRight,
  onPointClick,
}: DashboardConfig): Dashboards.Board.Options => ({
  dataPool: dataPoolConfig(data, subChartData, seeMoreBottomLeft, seeMoreBottomRight),
  editMode: EditModeConfig,
  gui: GuiConfig,
  components: [
    ChartStackedColumn({
      onPointClick: onPointClick,
      selectedPoint: dateFilterOverride,
      selectedIndex: selectedIndex,
      seeMoreUpdated: seeMoreUpdated,
      leftSeriesTitle: '',
      rightSeriesTitle: '',
      leftSeriesFields: aggregateStackedBarData(data, 'diagnosis', 'months', '', [
        'breast',
        'metastasis',
        'prostate',
        'colorectal',
        'neuro',
        'skin',
        'Unspecified',
      ])[0],
      rightSeriesFields: ['Date', 'Techniques'],
    }).components?.[0] ?? {},
    SubChartBar({
      filterOverride: dateFilterOverride,
      seeMore: seeMoreBottomLeft,
      title: 'Case Mix',
      connector: 'sub-chart-left-data',
      cell: 'sub-chart-left',
      theme: theme,
      toggleSeeMore: toggleSeeMoreBottomLeft,
    }).components?.[0] ?? {},
    SubChartBar({
      filterOverride: dateFilterOverride,
      seeMore: seeMoreBottomRight,
      title: 'Referring Physicians',
      connector: 'sub-chart-right-data',
      cell: 'sub-chart-right',
      theme: theme,
      toggleSeeMore: toggleSeeMoreBottomRight,
    }).components?.[0] ?? {},
  ],
});

const dataPoolConfig = (
  data: any,
  subChartData: any,
  seeMoreBottomLeft: boolean,
  seeMoreBottomRight: boolean,
): DataPoolOptions => ({
  connectors: [
    {
      id: 'top-chart-data',
      type: 'JSON',
      options: {
        firstRowAsNames: true,
        data: aggregateStackedBarData(data, 'diagnosis', 'months'),
      },
    },
    {
      id: 'sub-chart-left-data',
      type: 'JSON',
      options: {
        data: aggregateData(subChartData, 'diagnosis_code', seeMoreBottomLeft ? undefined : SUB_CHART_LIMIT),
      },
    },
    {
      id: 'sub-chart-right-data',
      type: 'JSON',
      options: {
        data: aggregateData(subChartData, 'referrer', seeMoreBottomRight ? undefined : SUB_CHART_LIMIT),
      },
    },
  ],
});

export default Diagnosis;
