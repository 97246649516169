import React from 'react';
import { styled } from '@mui/system';
import { useFormikContext, FormikProps, getIn } from 'formik';
import { getRemSize } from 'shared-components/StyledComponents/functions';
import { sharedContent } from '../constants';

interface Props {
  fieldName: string;
  isSubField?: boolean;
  sectionFields: object;
  valuesToDisplay: (value: string, fieldName: string) => any;
  validateField: (value: string, fieldName: string) => boolean;
}

interface StyledProps {
  isSubField?: boolean;
  isError?: boolean;
}

const ArrowLine = styled('div')`
  height: 0px;
  border-bottom: solid 2px transparent;
  box-shadow: 0px 2px 4px rgba(46, 46, 46, 0.2);
  border-radius: 2px;
  position: relative;
  z-index: 1;
`;

const DownArrow = styled('div')`
  width: 0;
  height: 0;
  border-left: 40px solid transparent;
  border-right: 40px solid transparent;
  border-top: 15px solid white;
  margin-left: 75%;
  z-index: 2;
`;

const QuestionContainer = styled('div')<StyledProps>`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  padding: ${(props: StyledProps) => (props.isSubField ? '20px 0 20px 27px' : '0 0 0 27px')};
  background: ${(props) => (props.isSubField ? props.theme.palette.grey[100] : 'none')};
`;

const Question = styled('div')<StyledProps>`
  width: 50%;
  margin-right: 5px;
  height: 40px;
  font-size: ${getRemSize(14)};
  line-height: 20px;
  display: flex;
  align-items: center;
  color: ${(props) => (props.isError ? props.theme.palette.text.primary : props.theme.palette.error.main)};
`;

const Answer = styled('div')<StyledProps>`
  width: 50%;
  font-weight: bold;
  font-size: ${getRemSize(14)};
  line-height: 20px;
  display: flex;
  align-items: center;
  color: ${(props) => (props.isError ? props.theme.palette.text.primary : props.theme.palette.error.main)};
`;

const ReviewFieldQA = ({
  fieldName,
  isSubField,
  sectionFields,
  valuesToDisplay,
  validateField,
}: Props): JSX.Element => {
  const { values }: FormikProps<object> = useFormikContext();
  const { notProvided } = sharedContent.generic.label;

  const isEmptyValue = (value: any) => {
    if ([null, ''].includes(value)) {
      return true;
    }
    if (Array.isArray(getIn(values, fieldName)) && getIn(values, fieldName).length === 0) {
      return true;
    }
    return false;
  };

  const valueField = getIn(values, fieldName);
  return (
    <>
      {isSubField && (
        <ArrowLine>
          <DownArrow />
        </ArrowLine>
      )}
      <QuestionContainer
        aria-label={`review-${sectionFields[fieldName as keyof typeof sectionFields]}`}
        isSubField={isSubField}>
        <Question isError={!validateField(valueField, fieldName)}>
          {sectionFields[fieldName as keyof typeof sectionFields]}
        </Question>
        <Answer isError={!validateField(valueField, fieldName)}>
          {isEmptyValue(valueField) ? notProvided : valuesToDisplay(valueField, fieldName)}
        </Answer>
      </QuestionContainer>
    </>
  );
};

export default ReviewFieldQA;
