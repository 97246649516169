// eslint-disable-next-line no-use-before-define
import React, { Component, Fragment, useEffect } from 'react';
import { withApollo, WithApolloClient } from '@apollo/client/react/hoc';
import { Query } from '@apollo/client/react/components';
import { PatientSearchQueryItem, PatientSearchQueryResultData } from 'op-pages/RO/UK/UKPatientSearch/UKPatientSearch';
import { LoadingSpinner, StandardDialog } from 'shared-components/components';
import { BaseTextField } from 'shared-components/components/FormFields';
import { ROTable } from 'op-components/index';
import { RouteComponentProps, withRouter } from 'react-router';
import { useErrorModalContext } from 'op-contexts';
import { UK_PATIENT_SEARCH } from './Queries';
import { Button, Stack } from '@mui/material';

interface Props extends WithApolloClient<{}>, RouteComponentProps {
  isOpen: boolean;
  careplanMode?: boolean;
  dismissFunction: () => void;
}

interface State {
  search: string;
}

class UKPatientSearchModal extends Component<Props, State> {
  public constructor(props: Props) {
    super(props);
    this.state = {
      search: '',
    };
  }

  private handleChange(value: string): void {
    this.setState({ search: value });
  }

  private fetchSearchResults = (): JSX.Element => {
    const { search } = this.state;
    const { careplanMode, dismissFunction, history } = this.props;

    if (!search || search.length < 3) {
      return (
        <tbody>
          <tr>
            <td colSpan={6}>Please enter the Name/GC number into the search box</td>
          </tr>
        </tbody>
      );
    }
    return (
      <Query<PatientSearchQueryResultData> query={UK_PATIENT_SEARCH} variables={{ search }}>
        {({ loading, error, data }): JSX.Element => {
          const { setError } = useErrorModalContext();
          useEffect(() => {
            if (error) return setError();
          }, [error]);

          if (loading) {
            return (
              <tbody>
                <tr>
                  <td colSpan={6}>
                    <LoadingSpinner relativeSpinner={true} />
                  </td>
                </tr>
              </tbody>
            );
          }
          if (data && data.searchPatients && data.searchPatients.length > 0) {
            return (
              <tbody>
                {data.searchPatients.map((patient: PatientSearchQueryItem) => {
                  return (
                    <tr>
                      <td>{patient.ida}</td>
                      <td>{patient.firstName}</td>
                      <td>{patient.lastName}</td>
                      <td>{patient.dob}</td>
                      <td>
                        {patient.address &&
                          [patient.address.line1, patient.address.line2, patient.address.postcode]
                            .filter((item): boolean => !!item)
                            .join(', ')}
                      </td>
                      {careplanMode && (
                        <td>
                          <Button
                            onClick={(): void => {
                              history.push(`/radiation/patient/${patient.id}/careplan/create`);
                              dismissFunction();
                            }}>
                            Create Careplan
                          </Button>
                        </td>
                      )}
                      {!careplanMode && (
                        <td>
                          <a href={`/radiation/patient/${patient.id}/summary`}>View patient</a>
                        </td>
                      )}
                    </tr>
                  );
                })}
              </tbody>
            );
          }
          return (
            <tbody>
              <tr>
                <td colSpan={6}>No results found</td>
              </tr>
            </tbody>
          );
        }}
      </Query>
    );
  };

  public render(): JSX.Element {
    const { isOpen, dismissFunction, careplanMode } = this.props;
    const { search } = this.state;
    const tableHeaders = ['GC Number', 'First Name', 'Last Name', 'Date of birth', 'Address'];
    if (careplanMode) {
      tableHeaders.push('Careplan');
    } else {
      tableHeaders.push('Link');
    }
    return (
      <Fragment>
        <StandardDialog
          maxWidth="lg"
          fullWidth
          open={isOpen}
          className="modal-dedupe-ro-uk"
          onClose={dismissFunction.bind(this)}
          title="Patient search"
          PaperProps={{
            sx: {
              minHeight: '80vh',
              maxHeight: '80vh',
            },
          }}>
          <Stack gap={2} paddingY={2} height={1}>
            <BaseTextField
              sx={{ maxWidth: '50%', paddingLeft: 1 }}
              id="uk-patient-search"
              value={search || ''}
              placeholder="Search"
              fullWidth
              onChange={(event: React.ChangeEvent<HTMLInputElement>): void => {
                this.handleChange(event.target.value);
              }}
            />
            <ROTable tableHeaders={tableHeaders} colWidths={['10%', '10%', '10%', '10%', '50%', '10%']}>
              {this.fetchSearchResults()}
            </ROTable>
          </Stack>
        </StandardDialog>
      </Fragment>
    );
  }
}

const routedComponent = withRouter(withApollo<Props>(UKPatientSearchModal));
export default routedComponent;
