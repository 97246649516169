import { useQuery, useMutation } from '@apollo/client';
import moment from 'moment';
import React, { useEffect, useState } from 'react';
import { LoadingSpinner } from 'shared-components/components';
import { DATATYPE } from 'shared-components/components/Table/Table';
import useOncologyListData from '../Careplan/useOncologyListData';
import { CAREPLAN_STATUS, TABLE_KEYS, TABLE_LABELS } from './Constants';
import {
  GET_TREATMENT_DASHBOARD_SETTINGS,
  GET_UPCOMING_DOCTOR_CAREPLANS,
  UPDATE_TREATMENT_DASHBOARD_SETTINGS,
} from './DashboardQueries';
import { DropDownObject, PrescribingDoctor, UpcomingDoctorCareplans } from './interfaces';
import MOCareplanTable from './MOCareplanTable';

const createOptions = (options: string[]): DropDownObject[] => {
  return options.map((option, index) => {
    return { id: index.toString(), name: option, checked: true };
  });
};
const statusOptions = createOptions(Object.values(CAREPLAN_STATUS));

const MOCareplanTableApollo = (): JSX.Element => {
  const { data, loading } = useQuery(GET_UPCOMING_DOCTOR_CAREPLANS, {
    variables: { startDate: moment().format('YYYY-MM-DD'), endDate: moment().add(7, 'days').format('YYYY-MM-DD') },
    fetchPolicy: 'cache-and-network',
  });
  const referenceData = useOncologyListData(['treatingDepartment']);
  const [statusFilterOptions, setStatusFilterOptions] = useState(statusOptions);
  const { data: dataTreatmentDashboardSettings } = useQuery(GET_TREATMENT_DASHBOARD_SETTINGS, {
    fetchPolicy: 'cache-and-network',
  });
  const [treatingDepartmentOptions, setTreatingDepartmentOptions] = useState<DropDownObject[]>([]);
  const [careplans, setCareplans] = useState<UpcomingDoctorCareplans[]>([]);
  const [prescribingDoctorsFilterOptions, setPrescribingDoctorsFilterOptions] = useState<PrescribingDoctor[]>([]);

  const [updateTreatmentDashboardSettings] = useMutation(UPDATE_TREATMENT_DASHBOARD_SETTINGS, {
    awaitRefetchQueries: true,
    refetchQueries: ['UpcomingDoctorCareplans'],
  });

  const headers = [
    {
      title: TABLE_LABELS.PATIENT_NAME,
      key: TABLE_KEYS.PATIENT_NAME,
      type: DATATYPE.STRING,
      width: '14%',
    },
    {
      title: TABLE_LABELS.TREATING_DEPARTMENT,
      key: TABLE_KEYS.TREATING_DEPARTMENT,
      type: DATATYPE.STRING,
      filterOptions: treatingDepartmentOptions,
      onFilterChange: (ids: string[], checked: boolean) => {
        setTreatingDepartmentOptions((treatingDepartmentFilter) =>
          treatingDepartmentFilter?.map((filter) => {
            return { ...filter, checked: ids.includes(filter.id) ? checked : filter.checked };
          }),
        );
      },
      dropdownTitle: `${TABLE_LABELS.TREATING_DEPARTMENT}${
        treatingDepartmentOptions.every((item) => item.checked) ? ' (All)' : ''
      }`,
      width: '16%',
    },
    {
      title: TABLE_LABELS.CAREPLAN,
      key: TABLE_KEYS.CAREPLAN,
      type: DATATYPE.STRING,
      width: '19%',
      dropdownTitle: TABLE_LABELS.CAREPLAN,
    },
    {
      title: TABLE_LABELS.NEXT_TREATMENT,
      key: TABLE_KEYS.NEXT_TREATMENT,
      type: DATATYPE.STRING,
      width: '12%',
    },
    {
      title: TABLE_LABELS.STATUS,
      key: TABLE_KEYS.STATUS,
      type: DATATYPE.STRING,
      width: '12%',
      filterOptions: statusFilterOptions,
      onFilterChange: (ids: string[], checked: boolean) => {
        setStatusFilterOptions((statusFilter) =>
          statusFilter.map((filter) => {
            return { ...filter, checked: ids.includes(filter.id) ? checked : filter.checked };
          }),
        );
      },
      dropdownTitle: `${TABLE_LABELS.STATUS}${statusFilterOptions.every((item) => item.checked) ? ' (All)' : ''}`,
    },
    {
      title: TABLE_LABELS.PRESCRIBING_DOCTOR,
      key: TABLE_KEYS.PRESCRIBING_DOCTOR,
      type: DATATYPE.STRING,
      width: '15%',
      filterOptions: prescribingDoctorsFilterOptions.map((val) => {
        return { id: val.practitionerId, name: val.practitionerName, checked: val.practitionerSelected };
      }),
      disableCheckAll: true,
      onFilterChange: (ids: string[], checked: boolean) => {
        setPrescribingDoctorsFilterOptions((prescribingDoctorsFilter) => {
          const selectedPrescribingDoctor: PrescribingDoctor[] = prescribingDoctorsFilter.map((filter) => {
            return {
              ...filter,
              practitionerSelected: ids.includes(filter.practitionerId) ? checked : filter.practitionerSelected,
            };
          });
          const selectedPrescribingDoctorIds: number[] = selectedPrescribingDoctor
            .filter((val) => val.practitionerSelected)
            .map((val) => parseInt(val.practitionerId));
          updateTreatmentDashboardSettings({ variables: { selectedPrescribingDoctors: selectedPrescribingDoctorIds } });
          return selectedPrescribingDoctor;
        });
      },
      dropdownTitle: TABLE_LABELS.PRESCRIBING_DOCTOR,
      badgeNumber: prescribingDoctorsFilterOptions.filter((item) => item.practitionerSelected).length,
    },
    {
      title: TABLE_LABELS.LAST_MODIFIED,
      key: TABLE_KEYS.LAST_MODIFIED,
      type: DATATYPE.DATE,
      width: '12%',
    },
  ];

  useEffect(() => {
    // Filter the data shown in table
    if (data) {
      const statusList = statusFilterOptions.filter((status) => status.checked).map((status) => status.name);
      const treatingDepartmentList = treatingDepartmentOptions
        ?.filter((treatingDepartment) => treatingDepartment.checked)
        .map((department) => department.name);
      const prescribingDoctorsList = prescribingDoctorsFilterOptions
        .filter((status) => status.practitionerSelected)
        .map((status) => status.practitionerName);
      const tmpCareplans = data.upcomingDoctorCareplans.filter((careplan: UpcomingDoctorCareplans) => {
        return (
          statusList.includes(careplan.status) &&
          (!treatingDepartmentList || treatingDepartmentList.includes(careplan.treatingDepartment)) &&
          (!prescribingDoctorsList || prescribingDoctorsList.includes(careplan.prescribingDoctor))
        );
      });
      setCareplans(tmpCareplans);
    }
  }, [data, statusFilterOptions, treatingDepartmentOptions, prescribingDoctorsFilterOptions]);
  useEffect(() => {
    if (referenceData.treatingDepartment) {
      setTreatingDepartmentOptions(createOptions(referenceData.treatingDepartment.map((option) => option.option)));
    }
  }, [referenceData]);
  useEffect(() => {
    if (dataTreatmentDashboardSettings) {
      const treatmentDashboardSettings = dataTreatmentDashboardSettings.treatmentDashboardSettings;
      const prescribingDoctors: PrescribingDoctor[] = treatmentDashboardSettings.map((val: any) => {
        return {
          practitionerId: val.practitionerId.toString(),
          practitionerName: val.practitionerName,
          practitionerSelected: val.practitionerSelected,
        };
      });
      setPrescribingDoctorsFilterOptions(prescribingDoctors);
    }
  }, [dataTreatmentDashboardSettings]);
  if (loading && !referenceData) return <LoadingSpinner />;
  return <MOCareplanTable careplans={careplans} headers={headers} />;
};

export default MOCareplanTableApollo;
