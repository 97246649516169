import React from 'react';
import { useHistory } from 'react-router-dom';
import { styled } from '@mui/system';

interface Props {
  stepperTitle?: string;
  data?: any;
  currentPage?: string;
  handleValidation: () => void;
  isLocked: boolean;
}

interface stepperItemProps {
  page?: string;
  currentPage?: string;
  name?: string;
  isLocked?: boolean;
}

const StepperTitle = styled('div')`
  font-style: normal;
  font-weight: bold;
  font-size: 13px;
  line-height: 16px;
  letter-spacing: 0.5px;
  text-transform: uppercase;
  margin: 20px 80px 20px 20px;
  color: ${(props) => props.theme.palette.grey[600]};
`;

const StepperItem = styled('div')<stepperItemProps>`
  display: table;
  width: 100%;
  padding: 0px 15px 0px 20px;
  min-height: 40px;
  border-left: ${(props) =>
    props.page === props.currentPage ? `3px solid ${props.theme.palette.primary.main}` : '3px solid transparent'};
  background-color: ${(props: stepperItemProps): string => (props.page === props.currentPage ? 'white' : '')};
  box-shadow: ${(props: stepperItemProps): string =>
    props.page === props.currentPage ? 'inset -1px 0px 1px rgba(0, 0, 0, 0.2)' : ''};
  &:hover {
    ${(props) =>
      props.isLocked ? 'cursor: not-allowed' : `cursor: pointer; background-color: ${props.theme.palette.grey[300]}`}
  }
  &:first-child {
    padding-top: 8px;
  }
  &:last-child {
    padding-bottom: 8px;
  }
  ${(props) =>
    props.page === props.currentPage
      ? `padding-top: 8px;
          padding-bottom: 8px;
      `
      : ` &:not(:first-child) {
        &:before {
          display: block;
          content: '';
          margin-left: 14px;
          bottom: 0;
          width: 1px;
          height: 8px;
          background: ${props.theme.palette.grey[300]};
        }
      }
      &:not(:last-child) {
        &:after {
          display: block;
          content: '';
          margin-left: 14px;
          bottom: 0;
          width: 1px;
          height: 8px;
          background: ${props.theme.palette.grey[300]};
        }
      }`};
`;

const StepperBody = styled('div')`
  display: flex;
  flex-direction: row;
  vertical-align: middle;
  width: 100%;
  align-items: center;
  justify-content: center;
`;

const StepperBubbleContainer = styled('div')`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  background: white;
  border: 2px solid white;
  border-radius: 15px;
  width: 35px;
  margin-right: 10px;
`;

const StepperBubble = styled('div')<stepperItemProps>`
  background: ${(props) => (props.page === props.currentPage ? `${props.theme.palette.primary.main}` : 'white')};
  color: ${(props) => (props.page === props.currentPage ? 'white' : `${props.theme.palette.grey[600]}`)};
  border: 1.2px solid ${(props) => props.theme.palette.grey[300]};
  border-radius: 15px;
  font-weight: bold;
  width: 25px;
  height: 25px;
  text-align: center;
  line-height: 23px;
`;

const StepperName = styled('div')<stepperItemProps>`
  color: ${(props) => (props.isLocked ? `${props.theme.palette.grey[600]}` : '')};
  font-style: normal;
  font-weight: bold;
  font-size: 14px;
  line-height: 20px;
  width: 100%;
`;

const Stepper = ({ stepperTitle, data, currentPage, handleValidation, isLocked }: Props): JSX.Element => {
  const history = useHistory();

  const pageClickAndValidate = (path: string): void => {
    if (!isLocked) {
      history.push(path);
      handleValidation();
    }
  };

  return (
    <>
      <StepperTitle data-test-id={`stepper-title-${stepperTitle}`}>{stepperTitle}</StepperTitle>
      <div>
        {data.map((page: any, index: number) => {
          return (
            <StepperItem
              page={page.name}
              currentPage={currentPage}
              onClick={() => {
                pageClickAndValidate(page.path);
              }}
              key={`${page.name}-${index}`}
              name={`stepper-${page.name}`}
              isLocked={isLocked}>
              <StepperBody>
                <StepperBubbleContainer>
                  <StepperBubble page={page.name} currentPage={currentPage}>
                    {index + 1}
                  </StepperBubble>
                </StepperBubbleContainer>
                <StepperName isLocked={isLocked}>{page.sectionTitle}</StepperName>
              </StepperBody>
            </StepperItem>
          );
        })}
      </div>
    </>
  );
};

export default Stepper;
