import { useQuery } from '@apollo/client';
import { PatientAddressObject, PatientObject } from 'op-pages/PX/models';
import { memo, useMemo } from 'react';
import { LoadingSpinner } from 'shared-components/components';
import { Call, Home, Info, PermIdentity } from 'shared-components/images';
import { DateTimeConverter } from 'shared-components/utils';
import './PatientDetailsContent.scss';
import { PATIENT_DETAILS_QUERY } from './PatientDetailsQueries';
import Container from '@mui/material/Container';
import Card from '@mui/material/Card';
import CardHeader from '@mui/material/CardHeader';
import CardBody from '@mui/material/CardContent';
import Grid from '@mui/material/Grid';
import { Typography } from '@mui/material';
import Alert from '@mui/material/Alert';
import { SupervisorAccountOutlined as SupervisorAccountOutlinedIcon } from '@mui/icons-material';

const PatientDetailsContent = (): JSX.Element => {
  const componentId = 'patient-details-content';

  const {
    data: PatientDetailsQueryData,
    loading: PatientDetailsQueryLoading,
    error: PatientDetailsQueryError,
  } = useQuery(PATIENT_DETAILS_QUERY);
  const patient = useMemo(() => PatientDetailsQueryData?.loggedInPatient || {}, [PatientDetailsQueryData]);
  const emergencyContact = useMemo(() => patient?.emergencyContact || {}, [patient]);
  const GRAPHQL_API_ERROR_MESSAGE = 'An error occured, please refresh';

  /**
   * Generates patient's display name
   * @returns {string} Returns the patient name with or without the title
   */
  const displayPatientName = (patient: PatientObject['patient']): string => {
    let title = patient.resolvedNamePrefix;
    const patientResolvedNamePrefixLowerCase = patient.resolvedNamePrefix.toLowerCase();
    if (patientResolvedNamePrefixLowerCase === 'rather not say' || patientResolvedNamePrefixLowerCase === 'mx') {
      return `${patient.userProfile.user.firstName} ${patient.userProfile.user.lastName}`;
    } else {
      // If prefix does not have a full stop suffix, add it
      if (
        patient.resolvedNamePrefix.length > 0 &&
        patient.resolvedNamePrefix.charAt(patient.resolvedNamePrefix.length - 1) !== '.'
      ) {
        title += '.';
      }
      return `${title} ${patient.userProfile.user.firstName} ${patient.userProfile.user.lastName}`;
    }
  };

  /** Render content, if missing data then will show the missing text thing */
  const renderContentMessage = (content: string, missingText: string): string => {
    // If null or empty then return missing text
    if (!content || content.trim() === '') {
      return missingText || 'Not provided';
    }
    return content;
  };

  const renderContentAddress = (address: PatientAddressObject, missingText: string): JSX.Element => {
    if (address) {
      return (
        <>
          {address.line1 && <div className="newscard-content-address-item ">{address.line1}</div>}
          {address.line2 && <div className="newscard-content-address-item ">{address.line2}</div>}
          {address.city && <div className="newscard-content-address-item ">{address.city}</div>}
          {address.state && address.postcode && (
            <div className="newscard-content-address-item ">{`${address.state} ${address.postcode}`}</div>
          )}
          {address.country && <div className="newscard-content-address-item ">{address.country}</div>}
        </>
      );
    } else {
      return <p>{missingText}</p>;
    }
  };

  const getPostalMissingText = (patient: PatientObject['patient']): string => {
    if (patient['address']) {
      return 'Same as residential address';
    } else {
      return 'Postal address not provided';
    }
  };

  // Render
  if (PatientDetailsQueryLoading) return <LoadingSpinner container relativeSpinner />;
  else if (PatientDetailsQueryError) {
    return <>{GRAPHQL_API_ERROR_MESSAGE}</>;
  } else {
    return (
      <Container id="inner-container">
        <Grid container id={componentId}>
          <Grid
            item
            xs={12}
            id="patient-name"
            justifyContent={'center'}
            sx={{
              margin: '1rem auto',
            }}>
            <Typography
              id="opx-patient-name"
              variant="h4"
              align="center"
              // Add color to text
              sx={{ fontSize: '2rem', fontWeight: 'bolder', textTransform: 'capitalize' }}>
              {displayPatientName(patient)}
            </Typography>
          </Grid>
          <Grid item xs={12}>
            <Grid container spacing={3}>
              <Grid item xs={12} md={6} id="basic-details-item">
                <Card
                  id="basic-details-card"
                  sx={{
                    minHeight: '230px',
                  }}>
                  <CardHeader
                    title={
                      <>
                        <PermIdentity />
                        <h3 className="title">Basic Details</h3>
                      </>
                    }
                    disableTypography
                  />
                  <CardBody>
                    <section id="preferred-name-section" className="section">
                      <h5>{'Preferred name'}</h5>
                      <p>{renderContentMessage(patient.preferredName, 'Preferred name not provided')}</p>
                    </section>
                    <section id="dob-section" className="section">
                      <h5>{'Date of birth'}</h5>
                      <p>
                        {renderContentMessage(
                          DateTimeConverter.getFormattedDateAsDDMonthYYYY(patient.dob),
                          'Date of birth not provided',
                        )}
                      </p>
                    </section>
                  </CardBody>
                </Card>
              </Grid>
              <Grid item xs={12} md={6} id="contact-details-item">
                <Card
                  id="contact-details-card"
                  sx={{
                    minHeight: '230px',
                  }}>
                  <CardHeader
                    title={
                      <>
                        <Call />
                        <h3 className="title">Contact Details</h3>
                      </>
                    }
                    disableTypography
                  />
                  <CardBody>
                    <section id="mobile-phone-section" className="section">
                      <h5>{'Mobile'}</h5>
                      <p>{renderContentMessage(patient.primaryPhoneFormatted, 'Mobile phone number not provided')}</p>
                    </section>
                    <section id="home-phone-section" className="section">
                      <h5>{'Home'}</h5>
                      <p>{renderContentMessage(patient.secondaryPhoneFormatted, 'Home phone number not provided')}</p>
                    </section>
                  </CardBody>
                </Card>
              </Grid>
              <Grid item xs={12} md={6} id="address-item">
                <Card
                  id="address-card"
                  sx={{
                    minHeight: '350px',
                  }}>
                  <CardHeader
                    title={
                      <>
                        <Home />
                        <h3 className="title">Address</h3>
                      </>
                    }
                    disableTypography
                  />
                  <CardBody>
                    <section id="residential-address-section" className="section">
                      <h5>{'Residential'}</h5>
                      {renderContentAddress(patient.address, 'Residential address not provided')}
                    </section>
                    <section id="postal-address-section" className="section">
                      <h5>{'Postal'}</h5>
                      {renderContentAddress(patient.postalAddress, getPostalMissingText(patient))}
                    </section>
                  </CardBody>
                </Card>
              </Grid>
              <Grid item xs={12} md={6}>
                <Card
                  id="alternate-contact-card"
                  sx={{
                    minHeight: '350px',
                  }}>
                  <CardHeader
                    title={
                      <>
                        <SupervisorAccountOutlinedIcon color="primary" />
                        <h3 className="title">Alternate Contact</h3>
                      </>
                    }
                    disableTypography
                  />
                  <CardBody>
                    <section id="alternate-contact-name-section" className="section">
                      <h5>Name</h5>
                      <p>
                        {renderContentMessage(
                          `${emergencyContact.firstName} ${emergencyContact.lastName}`,
                          'Alternate contact name not provided',
                        )}
                      </p>
                    </section>
                    <section id="alternate-contact-relationship-section" className="section">
                      <h5>Relationship</h5>
                      <p>
                        {renderContentMessage(
                          emergencyContact.relationship,
                          'Alternate contact relationship not provided',
                        )}
                      </p>
                    </section>
                    <section id="alternate-contact-mobile-section" className="section">
                      <h5>Mobile</h5>
                      <p>
                        {renderContentMessage(
                          emergencyContact.mobilePhoneNumberLocalised,
                          'Alternate contact mobile phone number not provided',
                        )}
                      </p>
                    </section>
                    <section id="alternate-contact-home-section" className="section">
                      <h5>Home</h5>
                      <p>
                        {renderContentMessage(
                          emergencyContact.homePhoneNumberLocalised,
                          'Alternate contact home phone number not provided',
                        )}
                      </p>
                    </section>
                    <section id="alternate-contact-email-section" className="section">
                      <h5>Email</h5>
                      <p>{renderContentMessage(emergencyContact.email, 'Alternate contact email not provided')}</p>
                    </section>
                  </CardBody>
                </Card>
              </Grid>
              <Grid item xs={12}>
                <Alert id="data-update-note" icon={<Info className="icon-blue" />} severity="success">
                  If you made changes to this information on the registration form, it will be updated as soon as it is
                  reviewed by one of our patient services officers. For all other changes, please contact your clinic.
                </Alert>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </Container>
    );
  }
};

export default memo(PatientDetailsContent);
