import { Region } from 'shared-components/enums';
import { PatientAttachment } from 'op-interfaces';
const REACT_APP_REGION = import.meta.env.REACT_APP_REGION;

const region = REACT_APP_REGION;

const categories = ['emergency', 'basic', 'contact', 'demographics'];

const isAU = region === Region.AU;

interface ApplyRequiredProps {
  title: string;
  category: string;
  optionalForPSO: boolean;
}

export const applyRequired = ({ title, category, optionalForPSO }: ApplyRequiredProps): string => {
  return `${title}${isAU && categories.includes(category) && !optionalForPSO ? '*' : ''}`;
};

export const documentTypeOptions = [
  {
    id: 'advance_care_directive',
    name: 'Advance Care Directive',
  },
  {
    id: 'concession_card',
    name: 'Cards',
  },
  {
    id: 'medication_list',
    name: 'Medications list',
  },
  {
    id: 'pacemaker_card',
    name: 'CIED Card',
  },
  {
    id: 'power_of_attorney',
    name: 'Power of attorney',
  },
  {
    id: 'referral',
    name: 'Incoming referral',
  },
  {
    id: 'other',
    name: 'Other',
  },
];

export const getDocumentType = (file: PatientAttachment, label = 'id'): string | undefined => {
  const { documentType, suggestedDocumentType } = file;
  const typeToCheck = documentType ? documentType : suggestedDocumentType ? suggestedDocumentType : null;
  if (!suggestedDocumentType && documentType) {
    //@ts-ignore
    return documentTypeOptions.filter((item): boolean => item.id.toLowerCase() === typeToCheck)[0][label];
  }
  return typeToCheck && typeToCheck !== 'other'
    ? //@ts-ignore
      documentTypeOptions.filter((item): boolean => item.id.toLowerCase() === typeToCheck)[0][label]
    : null;
};
