import { gql } from '@apollo/client';

export const PRE_CT_TRIAGE_QUERY = gql`
  query PreCtTriage($id: ID!) {
    preCtTriage(id: $id) {
      id
      isAmendment
      isLocked
      nurseLocation
      unintentionalWeightLoss
      hasDecreasedAppetite
      isAlertAndOrientated
      hasAlteredCognitiveState
      hasCurrentPain
      painScore
      isAbleToLieFlat
      isAbleToClimbStairs
      transportMode
      otherTransport
      socialConsiderations
      livesAlone
      livesWith
      isCarer
      carerSpecification
      usualResidence
      usualResidenceSpecify
      staysAtUsualResidence
      otherResidence
      hasPatientCarerConcern
      needsAssistanceAdls
      assistance
      assistanceSpecify
      hasFamilyOrFriend
      familyOrFriendSpecification
      existingSupportService
      existingSupportSpecification
      hasSensoryImpairment
      sensoryImpairments
      sensoryImpairmentsSpecification
      englishSecondLanguage
      primaryLanguage
      requireInterpreter
      otherInformation
      pelvicFitness
      hasHighRiskFactors
      howFactorsWereIdentified
      additionalNotes
      requiresWellbeingMxPlan
      patient {
        id
      }
      updatedBy
      lastUpdateUser {
        fieldName
        lastUpdateUser
        updatedDate
      }
    }
    typeOfResidenceRefData: listData(category: "type_of_residence") {
      id
      name
    }
  }
`;

export const UPDATE_PRE_CT_TRIAGE = gql`
  mutation UpdatePreCtTriage(
    $id: ID!
    $nurseLocation: String
    $unintentionalWeightLoss: Boolean
    $hasDecreasedAppetite: Boolean
    $isAlertAndOrientated: Boolean
    $hasAlteredCognitiveState: Boolean
    $hasCurrentPain: Boolean
    $painScore: String
    $isAbleToLieFlat: Boolean
    $isAbleToClimbStairs: Boolean
    $transportMode: [String]
    $otherTransport: String
    $livesAlone: Boolean
    $livesWith: String
    $isCarer: Boolean
    $carerSpecification: String
    $usualResidence: String
    $usualResidenceSpecify: String
    $staysAtUsualResidence: Boolean
    $otherResidence: String
    $needsAssistanceAdls: Boolean
    $assistance: [String]
    $assistanceSpecify: String
    $hasFamilyOrFriend: Boolean
    $familyOrFriendSpecification: String
    $existingSupportService: Boolean
    $existingSupportSpecification: String
    $hasSensoryImpairment: Boolean
    $sensoryImpairments: [String]
    $sensoryImpairmentsSpecification: String
    $englishSecondLanguage: Boolean
    $primaryLanguage: String
    $requireInterpreter: Boolean
    $otherInformation: String
    $hasPatientCarerConcern: Boolean
    $pelvicFitness: String
    $hasHighRiskFactors: Boolean
    $howFactorsWereIdentified: String
    $additionalNotes: String
  ) {
    updatePreCtTriage(
      id: $id
      nurseLocation: $nurseLocation
      unintentionalWeightLoss: $unintentionalWeightLoss
      hasDecreasedAppetite: $hasDecreasedAppetite
      isAlertAndOrientated: $isAlertAndOrientated
      hasAlteredCognitiveState: $hasAlteredCognitiveState
      hasCurrentPain: $hasCurrentPain
      painScore: $painScore
      isAbleToLieFlat: $isAbleToLieFlat
      isAbleToClimbStairs: $isAbleToClimbStairs
      transportMode: $transportMode
      otherTransport: $otherTransport
      livesAlone: $livesAlone
      livesWith: $livesWith
      isCarer: $isCarer
      carerSpecification: $carerSpecification
      usualResidence: $usualResidence
      usualResidenceSpecify: $usualResidenceSpecify
      staysAtUsualResidence: $staysAtUsualResidence
      otherResidence: $otherResidence
      hasPatientCarerConcern: $hasPatientCarerConcern

      needsAssistanceAdls: $needsAssistanceAdls
      assistance: $assistance
      assistanceSpecify: $assistanceSpecify
      hasFamilyOrFriend: $hasFamilyOrFriend
      familyOrFriendSpecification: $familyOrFriendSpecification
      existingSupportService: $existingSupportService
      existingSupportSpecification: $existingSupportSpecification
      hasSensoryImpairment: $hasSensoryImpairment
      sensoryImpairments: $sensoryImpairments
      sensoryImpairmentsSpecification: $sensoryImpairmentsSpecification

      englishSecondLanguage: $englishSecondLanguage
      primaryLanguage: $primaryLanguage
      requireInterpreter: $requireInterpreter
      otherInformation: $otherInformation

      pelvicFitness: $pelvicFitness
      hasHighRiskFactors: $hasHighRiskFactors
      howFactorsWereIdentified: $howFactorsWereIdentified
      additionalNotes: $additionalNotes
    ) {
      preCtTriage {
        id
        nurseLocation
        unintentionalWeightLoss
        hasDecreasedAppetite
        isAlertAndOrientated
        hasAlteredCognitiveState
        hasCurrentPain
        painScore
        isAbleToLieFlat
        isAbleToClimbStairs
        transportMode
        otherTransport

        socialConsiderations
        livesAlone
        livesWith
        isCarer
        carerSpecification
        usualResidence
        usualResidenceSpecify
        staysAtUsualResidence
        otherResidence
        hasPatientCarerConcern

        needsAssistanceAdls
        assistance
        assistanceSpecify
        hasFamilyOrFriend
        familyOrFriendSpecification
        existingSupportService
        existingSupportSpecification
        hasSensoryImpairment
        sensoryImpairments
        sensoryImpairmentsSpecification

        englishSecondLanguage
        primaryLanguage
        requireInterpreter
        otherInformation

        pelvicFitness
        hasHighRiskFactors
        howFactorsWereIdentified
        additionalNotes
        lastUpdateUser {
          fieldName
          lastUpdateUser
          updatedDate
        }
      }
    }
  }
`;

export const SUBMIT_PRE_CT_TRIAGE = gql`
  mutation createInitialTriageSubmission($id: ID!, $hideNotes: Boolean) {
    createInitialTriageSubmission(id: $id, hideNotes: $hideNotes) {
      submission {
        id
      }
      submissionCreated
    }
  }
`;
