import { useLazyQuery, useMutation } from '@apollo/client';
import { useErrorModalContext } from 'op-contexts/index';
import { Fragment, memo, useCallback, useEffect, useState } from 'react';
import { LoadingSpinner } from 'shared-components/components';
import { CreateAUSAttachmentMutation, GET_UPLOAD_DETAILS_QUERY } from './FileUploadQueries';
import UploadCard from './UploadCard';
import { DELETE_RETRY, FILE_SIZE_ERROR } from './constants';
import { checkFileExtension, getFileExtension } from './helper';

interface FileUploadCardIProps {
  patient: any;
  file: File;
  refetchCallback: () => void;
  handleFileDelete: (file: File) => void;
}

const FileUploadCard = (props: FileUploadCardIProps): JSX.Element => {
  const { patient, file, refetchCallback, handleFileDelete } = props;

  const { setError } = useErrorModalContext();
  const [progress, setProgress] = useState<number>(0);
  const [xhrErrors, setXhrErrors] = useState<string[]>([]);

  const [
    getUploadDetailsQuery,
    { data: uploadDetailsQueryData, loading: uploadDetailsQueryLoading, error: uploadDetailsQueryError },
  ] = useLazyQuery(GET_UPLOAD_DETAILS_QUERY);

  useEffect(() => {
    let newFile = undefined;
    if (file?.name === 'image.jpg') {
      // ipad camera uploading new photo as image.jpg
      const newFileName = `${new Date()
        .toJSON()
        .trim()
        .replace(/[-:z.]/gi, '')}-${file.name}`;
      newFile = new File([file], newFileName);
    }

    const interceptedFile = newFile ? newFile : file;

    let hasIssue = false;
    // Check file extension
    const fileExtension = getFileExtension(file.name);
    if (fileExtension) {
      const fileExtensionError = checkFileExtension(fileExtension);
      if (fileExtensionError) {
        setXhrErrors([...xhrErrors, fileExtensionError]);
        hasIssue = true;
      }
    }
    // Check if the file is too big
    if (file.size > 20971520) {
      // 20971520 = 20Mb
      if (xhrErrors.indexOf(FILE_SIZE_ERROR) === -1) {
        setXhrErrors([...xhrErrors, FILE_SIZE_ERROR]);
        hasIssue = true;
      }
    }

    if (!hasIssue) {
      getUploadDetailsQuery({
        variables: {
          fileName: interceptedFile.name,
        },
      });
    }
  }, []);

  const encounterDate = new Date();
  const [createFileAttachmentMutation] = useMutation(CreateAUSAttachmentMutation);

  const uploadFile = useCallback((): void => {
    if (xhrErrors?.length) return;

    const fileUploadPayload = uploadDetailsQueryData.fileUploadPayload;
    const fileUploadPayloadUrl = fileUploadPayload.url;
    const fileUploadPayloadFields = fileUploadPayload.fields;

    const xhr = new XMLHttpRequest();
    const formData = new FormData();

    const parsedPayload = JSON.parse(fileUploadPayloadFields.awsPayload);
    for (const [key, value] of Object.entries(parsedPayload.fields)) {
      formData.append(key, String(value));
    }
    formData.append('Content-Type', file.type);
    formData.append('file', file);

    xhr.upload.addEventListener('progress', (e): void => {
      setProgress((e.loaded / e.total) * 100);
    });

    xhr.addEventListener('load', (): void => {
      const statusCode = xhr.status;
      if ([200, 201, 204].includes(statusCode)) {
        createFileAttachmentMutation({
          variables: {
            awsKey: fileUploadPayloadFields.awsKey,
            patientPk: patient.id,
            isRegistrationAttachment: true,
            encounterDate,
          },
        })
          .then(async () => {
            refetchCallback();
            // This is purely UI only, removes the file "uploading in progress card"
            handleFileDelete(file);
          })
          .catch(() => {
            setXhrErrors([DELETE_RETRY]);
          });
      } else {
        setXhrErrors([DELETE_RETRY]);
      }
    });

    xhr.addEventListener('error', (): void => {
      setXhrErrors([DELETE_RETRY]);
    });

    xhr.open('POST', fileUploadPayloadUrl);
    xhr.send(formData);
  }, [uploadDetailsQueryData]);

  useEffect(() => {
    if (uploadDetailsQueryData) uploadFile();
  }, [uploadDetailsQueryData]);

  /** ------ JSX.Element return render below ------ */
  if (uploadDetailsQueryError) {
    setError();
    return <Fragment />;
  } else if (uploadDetailsQueryLoading) {
    return <LoadingSpinner relativeSpinner loadingText={''} />;
  } else {
    return (
      <UploadCard
        downloadUrl=""
        errors={xhrErrors}
        progress={progress}
        filename={file.name}
        removeDocument={handleFileDelete}
      />
    );
  }
};

export default memo(FileUploadCard);
