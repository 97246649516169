// eslint-disable-next-line no-use-before-define
import React, { Component, Fragment } from 'react';
import classnames from 'classnames';
import {
  AlertHeader,
  HeaderBar,
  RegistrationNavigator,
  ResubmissionWarningHeader,
  TestInstanceWarning,
  UKPatientCard,
} from 'op-components';
import { Region } from 'shared-components/enums';
import { AlertInfo, MandatoryPatient } from 'op-interfaces';
import { Button } from '@mui/material';
import { RouteComponentProps, withRouter } from 'react-router-dom';
import { SavingStatus, SubmitButtonType } from 'shared-components/enums';
import { ErrorExclamation } from 'shared-components/images';
import { ListData } from 'shared-components/interfaces';
import RegistrationConflictsStepperButton from './RegistrationConflictsStepperButton';
import './RegistrationContainer.scss';
import RegistrationPatientDetails from './RegistrationPatientDetails/RegistrationPatientDetails';
import RegistrationStepper from './RegistrationStepper/RegistrationStepper';
import { RegistrationContext, NavigationContext } from 'op-contexts';
const REACT_APP_REGION = import.meta.env.REACT_APP_REGION;

interface Props extends RouteComponentProps {
  patient: MandatoryPatient;
  isProd?: boolean;
  genderRefData: ListData[];
  isPso?: boolean;
  saveStatus?: string;
  hasDataConflicts?: boolean;
  resolveConflictsIncomplete?: boolean;
  submitStatus?: SubmitButtonType;
  submitRegistration?: (closeModal: () => void, isPso: boolean) => void;
  resolvePatientConflicts?: any;
  fileUploaded?: boolean;
  onOpenUploadErrorModal?: any;
  alertHeader?: AlertInfo | undefined;
  children: React.ReactNode;
}
const region = REACT_APP_REGION;

interface State {
  previousPageTitle: string;
  headerBarRendered: boolean;
}

const registrationFormHeadingText = 'Registration Form';

class RegistrationContainer extends Component<Props, State> {
  public static defaultProps = {
    saveStatus: SavingStatus.FAILED,
  };

  public constructor(props: Props) {
    super(props);
    this.state = {
      previousPageTitle: '',
      headerBarRendered: false,
    };
  }

  componentDidMount() {
    this.setState({
      previousPageTitle: document.title,
    });
    document.title = `${document.title} - ${registrationFormHeadingText}`;
  }

  componentWillUnmount() {
    const previousPageTitle = this.state.previousPageTitle;
    document.title = previousPageTitle ? previousPageTitle : 'Horizon'; // Will probably be replaced with a constant
  }

  headerHasRendered = () => {
    this.setState({
      headerBarRendered: true,
    });
  };

  public render(): JSX.Element {
    // eslint-disable-next-line no-unused-vars
    const { patient, genderRefData, isPso, children, history, hasDataConflicts, alertHeader } = this.props;

    return (
      <Fragment>
        {region !== Region.UK && (
          <HeaderBar headingText={registrationFormHeadingText} menuButton={true} hasRendered={this.headerHasRendered} />
        )}

        {hasDataConflicts && alertHeader && <AlertHeader alert={alertHeader} />}

        <div id="reg-container" className="page-container full-viewport">
          <ResubmissionWarningHeader patient={patient} />
          <TestInstanceWarning patient={patient} />
          {region === Region.UK && (
            <>
              <UKPatientCard patient={patient}></UKPatientCard>
              <div id="reg-container-header" className={classnames('sticky-top')}>
                <div id="reg-container-header-title">{registrationFormHeadingText}</div>
                <div id="saving-status">{this.getSaveIndicator()}</div>
              </div>
            </>
          )}

          <div
            className={`d-flex ${classnames('reg-container-elements', {
              patient: !isPso,
            })}`}>
            {isPso && region !== Region.UK && (
              <div className="left-container px-2">
                <div className="newspaper-container">
                  <RegistrationPatientDetails patient={patient} genderRefData={genderRefData} />
                </div>
                <Button
                  onClick={(e): void => {
                    e.preventDefault();
                    const pathname = (isPso ? '/navigator/' : '/') + `patient/${patient.id}/summary`;
                    history.push(pathname);
                  }}
                  type="submit"
                  data-testid="back-to-summary-button">
                  {isPso ? 'Go to summary' : 'View all forms'}
                </Button>
              </div>
            )}
            <div className="right-container d-flex flex-wrap px-1">
              <div id="reg-container-stepper" className={classnames({ 'uk-bottom-margin': region === Region.UK })}>
                {region !== Region.UK && (
                  <div id="reg-container-header">
                    <div id="reg-container-header-title">{registrationFormHeadingText}</div>
                  </div>
                )}
                {hasDataConflicts ? (
                  <>
                    <RegistrationConflictsStepperButton patientId={patient.id} />
                    <RegistrationStepper patientId={patient.id} disabled={true} />
                  </>
                ) : (
                  <RegistrationStepper patientId={patient.id} isPso={isPso} regFormStatus={patient.regFormStatus} />
                )}
              </div>
              <div
                id="reg-container-content"
                className={`my-1 ${classnames({ 'uk-bottom-margin': region === Region.UK })}`}>
                {children}
              </div>
            </div>
          </div>
        </div>

        <RegistrationContext.Consumer>
          {(registrationContext) => (
            <NavigationContext.Consumer>
              {(navigationContext) => (
                <RegistrationNavigator
                  registrationContext={registrationContext}
                  navigationContext={navigationContext}
                  {...this.props}
                />
              )}
            </NavigationContext.Consumer>
          )}
        </RegistrationContext.Consumer>
      </Fragment>
    );
  }

  private getSaveIndicator = (): JSX.Element => {
    const { saveStatus } = this.props;

    // FIXME: Part of previous fix me
    if (saveStatus) {
      const saveFailed = saveStatus === SavingStatus.FAILED;

      return (
        <Fragment>
          {saveFailed && (
            <span id="saving-status-icon">
              <ErrorExclamation className="icon" />
            </span>
          )}
          <span id="save-status-text" className={classnames('save-status-text', { 'save-failed': saveFailed })}>
            {saveStatus}
          </span>
        </Fragment>
      );
    } else {
      return <Fragment />;
    }
  };
}

const routerComponent = withRouter(RegistrationContainer);
export default routerComponent;
