import { detect } from 'detect-browser';

export const navigateToExternalURL = (url: string): void => {
  const browser = detect();

  if (browser) {
    if (browser.name === 'edge' && parseInt(browser.version) <= 18) {
      // Navigating to external page with href not supported in edge 18 and below
      // @ts-ignore
      window.location = encodeURI(url);
    }
  }
  window.location.href = encodeURI(url);
};
