import { gql } from '@apollo/client';

export const GET_PATIENT_CAREPLANS = gql`
  query CareplanList($patientId: ID!) {
    moPatientCareplans(patientId: $patientId) {
      id
      treatingDepartment
      careplanName
      treatmentStartDate
      treatmentEndDate
      nextTreatmentDate
      nextTreatmentInfo
      nextTreatmentCycleId
      currentCycle
      status
      lastModified
      latestPageUrl
    }
  }
`;
