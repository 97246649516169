import { GraphQLErrorMessage } from 'shared-components/interfaces';
import { GraphQLError } from 'graphql';

export const djangoJSONConversion = (djangoMsg: string): string => {
  // Escape out of double quotation marks if there is already double quotation marks
  const message = djangoMsg.replace(/\"/g, '\\"');

  // Replace all the message string from ' to ", otherwise invalid JSON
  return message.replace(/\'/g, '"');
};

// Determine if a string is valid json
export const isValidJson = (jsonString: any): boolean => {
  try {
    const o = JSON.parse(jsonString);

    if (o && typeof o === 'object') {
      return true;
    }
  } catch (e) {}
  return false;
};

export const determineError = (graphQLError?: GraphQLError): GraphQLErrorMessage | null => {
  if (graphQLError) {
    // Seems like the backend is now returning valid json
    // But just to be sure if it is not valid do the django conversion dance
    let message = '';
    if (isValidJson(graphQLError.message)) {
      message = graphQLError.message;
    } else {
      message = djangoJSONConversion(graphQLError.message);
    }

    try {
      const errorObject = JSON.parse(message);
      return errorObject;
    } catch {
      // An error was detected when trying to convert the error object from graph ql, so return a 500 error
      return {
        message: 'Invalid JSON error message',
        statusCode: 500,
      };
    }
  }

  return null;
};

export const NEXT_SEARCH_PARAM = 'next';
