export const patientData = [
  {
    patientId: 453418,
    patientName: 'Jonathon Washington',
    gender: 'Male',
    MRN: '180293',
    DOB: 'Apr-12-1952',
    careplanName: 'Prostate VMAT 6000/30',
    patientStatus: 'Pretreatment',
    dynamicAlertCategory: 'Overdue',
    firstTreatmentDate: 'Mar-25-2024',
    firstTreatmentTime: '10:00 AM',
    firstTreatmentLocation: 'Fairfield',
    firstTreatmentStatus: 'Queued',
    initialConsultAppntDate: 'Mar-01-2024',
    initialConsultAppntTime: '10:30 AM',
    initialConsultStatus: 'Completed',
    initialConsultAdditionalInfo: '-',
    initialConsultOwner: 'Dr Ray Smith',
    preAuthDueDate: 'Mar-01-2024',
    preAuthStatus: 'Completed',
    preAuthOwner: 'Dr Ray Smith',
    SIMRequestDueDate: 'Mar-01-2024',
    SIMRequestStatus: 'Completed',
    SIMRequestOwner: 'Dr Ray Smith',
    prescriptionDueDate: 'Mar-01-2024',
    prescriptionStatus: 'Completed',
    prescriptionOwner: 'Dr Ray Smith',
    complianceDocsDueDate: 'Mar-04-2024',
    complianceDocsStatus: 'Completed',
    complianceDocsAdditionalInfo: '-',
    complianceDocsOwner: 'Dr Ray Smith',
    simulationAppntDueDate: 'Mar-08-2024',
    simulationAppntTime: '10:00 AM',
    simulationStatus: 'Completed',
    simulationAdditionalInfo: '-',
    simulationOwner: 'Planning Team',
    fusionDueDate: 'Mar-08-2024',
    fusionStatus: 'Completed',
    fusionOwner: 'Planning Team',
    targetVolumeDueDate: 'Mar-11-2024',
    targetVolumeStatus: 'Completed',
    targetVolumeOwner: 'Dr Ray Smith',
    contouringDueDate: 'Mar-11-2024',
    contouringStatus: 'Completed',
    contouringOwner: 'Planning Team',
    planApprovalDueDate: 'Mar-20-2024',
    planApprovalStatus: 'Overdue',
    planApprovalOwner: 'Dr Ray Smith',
  },
  {
    patientId: 36586,
    patientName: 'Adam McAdams',
    gender: 'Male',
    MRN: '023617',
    DOB: 'Mar-25-1987',
    careplanName: 'RT Brain VMAT 6000/30',
    patientStatus: 'Pretreatment',
    dynamicAlertCategory: 'Due today',
    firstTreatmentDate: 'Mar-26-2024',
    firstTreatmentTime: '11:00 AM',
    firstTreatmentLocation: 'Lincoln',
    firstTreatmentStatus: 'Old Start',
    initialConsultAppntDate: 'Mar-01-2024',
    initialConsultAppntTime: '11:10 AM',
    initialConsultStatus: 'Completed',
    initialConsultAdditionalInfo: '-',
    initialConsultOwner: 'Dr Ray Smith',
    preAuthDueDate: 'Mar-01-2024',
    preAuthStatus: 'Completed',
    preAuthOwner: 'Dr Ray Smith',
    SIMRequestDueDate: 'Mar-01-2024',
    SIMRequestStatus: 'Completed',
    SIMRequestOwner: 'Dr Ray Smith',
    prescriptionDueDate: 'Mar-01-2024',
    prescriptionStatus: 'Completed',
    prescriptionOwner: 'Dr Ray Smith',
    complianceDocsDueDate: 'Mar-04-2024',
    complianceDocsStatus: 'Completed',
    complianceDocsAdditionalInfo: '-',
    complianceDocsOwner: 'Dr Ray Smith',
    simulationAppntDueDate: 'Mar-11-2024',
    simulationAppntTime: '11:00 AM',
    simulationStatus: 'Completed',
    simulationAdditionalInfo: '-',
    simulationOwner: 'Planning Team',
    fusionDueDate: 'Mar-11-2024',
    fusionStatus: 'Completed',
    fusionOwner: 'Planning Team',
    targetVolumeDueDate: 'Mar-12-2024',
    targetVolumeStatus: 'Completed',
    targetVolumeOwner: 'Dr Ray Smith',
    contouringDueDate: 'Mar-12-2024',
    contouringStatus: 'Completed',
    contouringOwner: 'Planning Team',
    planApprovalDueDate: 'Mar-21-2024',
    planApprovalStatus: 'Overdue',
    planApprovalOwner: 'Dr Ray Smith',
  },
  {
    patientId: 10570,

    patientName: 'Olivia Taylor',
    gender: 'Female',
    MRN: '181452',
    DOB: 'Sep-01-1955',
    careplanName: 'Breast+SCF+Ax VMAT 5000/25 + Boost e1000/5',
    patientStatus: 'Pretreatment',
    dynamicAlertCategory: 'Due today',
    firstTreatmentDate: 'Mar-27-2024',
    firstTreatmentTime: '10:30 AM',
    firstTreatmentLocation: 'Fairfield',
    firstTreatmentStatus: 'New Start',
    initialConsultAppntDate: 'Mar-04-2024',
    initialConsultAppntTime: '11:30 AM',
    initialConsultStatus: 'Completed',
    initialConsultAdditionalInfo: '-',
    initialConsultOwner: 'Dr Ray Smith',
    preAuthDueDate: 'Mar-04-2024',
    preAuthStatus: 'Completed',
    preAuthOwner: 'Dr Ray Smith',
    SIMRequestDueDate: 'Mar-04-2024',
    SIMRequestStatus: 'Completed',
    SIMRequestOwner: 'Dr Ray Smith',
    prescriptionDueDate: 'Mar-04-2024',
    prescriptionStatus: 'Completed',
    prescriptionOwner: 'Dr Ray Smith',
    complianceDocsDueDate: 'Mar-09-2024',
    complianceDocsStatus: 'Completed',
    complianceDocsAdditionalInfo: '-',
    complianceDocsOwner: 'Dr Ray Smith',
    simulationAppntDueDate: 'Mar-11-2024',
    simulationAppntTime: '10:30 AM',
    simulationStatus: 'Completed',
    simulationAdditionalInfo: '-',
    simulationOwner: 'Planning Team',
    fusionDueDate: 'Not applicable',
    fusionStatus: 'Not applicable',
    fusionOwner: 'Not applicable',
    targetVolumeDueDate: 'Mar-14-2024',
    targetVolumeStatus: 'Completed',
    targetVolumeOwner: 'Dr Ray Smith',
    contouringDueDate: 'Mar-14-2024',
    contouringStatus: 'Completed',
    contouringOwner: 'Planning Team',
    planApprovalDueDate: 'Mar-22-2024',
    planApprovalStatus: 'Due today',
    planApprovalOwner: 'Dr Ray Smith',
  },
  {
    patientId: 90051162,

    patientName: 'Grace',
    gender: 'Female',
    MRN: '182246',
    DOB: 'Jan-20-1957',
    careplanName: 'Breast + SCF + Axilla VMAT 5000/25 (Left)',
    patientStatus: 'Pretreatment',
    dynamicAlertCategory: 'Due today',
    firstTreatmentDate: 'Apr-02-2024',
    firstTreatmentTime: '1:00 PM',
    firstTreatmentLocation: 'Fairfield',
    firstTreatmentStatus: 'New Start',
    initialConsultAppntDate: 'Mar-04-2024',
    initialConsultAppntTime: '3:00 PM',
    initialConsultStatus: 'Completed',
    initialConsultAdditionalInfo: '-',
    initialConsultOwner: 'Dr Ray Smith',
    preAuthDueDate: 'Mar-04-2024',
    preAuthStatus: 'Completed',
    preAuthOwner: 'Dr Ray Smith',
    SIMRequestDueDate: 'Mar-04-2024',
    SIMRequestStatus: 'Completed',
    SIMRequestOwner: 'Dr Ray Smith',
    prescriptionDueDate: 'Mar-04-2024',
    prescriptionStatus: 'Completed',
    prescriptionOwner: 'Dr Ray Smith',
    complianceDocsDueDate: 'Mar-09-2024',
    complianceDocsStatus: 'Completed',
    complianceDocsAdditionalInfo: '-',
    complianceDocsOwner: 'Dr Ray Smith',
    simulationAppntDueDate: 'Mar-11-2024',
    simulationAppntTime: '1:00 PM',
    simulationStatus: 'Completed',
    simulationAdditionalInfo: '-',
    simulationOwner: 'Planning Team',
    fusionDueDate: 'Mar-11-2024',
    fusionStatus: 'Completed',
    fusionOwner: 'Planning Team',
    targetVolumeDueDate: 'Mar-15-2024',
    targetVolumeStatus: 'Completed',
    targetVolumeOwner: 'Dr Ray Smith',
    contouringDueDate: 'Mar-15-2024',
    contouringStatus: 'Completed',
    contouringOwner: 'Planning Team',
    planApprovalDueDate: 'Mar-26-2024',
    planApprovalStatus: 'To do',
    planApprovalOwner: 'Dr Ray Smith',
    treatment: [
      {
        date: 'Due today',
        status: 'Due today',
        fractions: '1-5',
        owner: 'Dr. Ray Smith',
        showRecordOtv: true,
      },
      {
        date: 'Mar-25-2024',
        status: 'Not started',
        fractions: '6-10',
        owner: 'Dr. Ray Smith',
        showRecordOtv: false,
      },
      {
        date: 'Apr-01-2024',
        status: 'Not started',
        fractions: '11-15',
        owner: 'Dr. Ray Smith',
        showRecordOtv: false,
      },
      {
        date: 'Apr-08-2024',
        status: 'Not started',
        fractions: '16-20',
        owner: 'Dr. Ray Smith',
        showRecordOtv: false,
      },
      {
        date: 'Apr-15-2024',
        status: 'Not started',
        fractions: '21-25',
        owner: 'Dr. Ray Smith',
        showRecordOtv: false,
      },
    ],
  },
  {
    patientId: 451435,

    patientName: 'Alex Smith',
    gender: 'Male',
    MRN: '197654',
    DOB: 'Jun-03-1966',
    careplanName: 'Lung VMAT 4000/20',
    patientStatus: 'Pretreatment',
    dynamicAlertCategory: '-',
    firstTreatmentDate: 'Apr-03-2024',
    firstTreatmentTime: '9:00 AM',
    firstTreatmentLocation: 'Lincoln',
    firstTreatmentStatus: 'Old Start',
    initialConsultAppntDate: 'Mar-06-2024',
    initialConsultAppntTime: '9:30 AM',
    initialConsultStatus: 'Completed',
    initialConsultAdditionalInfo: '-',
    initialConsultOwner: 'Dr Ray Smith',
    preAuthDueDate: 'Mar-06-2024',
    preAuthStatus: 'Completed',
    preAuthOwner: 'Dr Ray Smith',
    SIMRequestDueDate: 'Mar-06-2024',
    SIMRequestStatus: 'Completed',
    SIMRequestOwner: 'Dr Ray Smith',
    prescriptionDueDate: 'Mar-06-2024',
    prescriptionStatus: 'Completed',
    prescriptionOwner: 'Dr Ray Smith',
    complianceDocsDueDate: 'Mar-11-2024',
    complianceDocsStatus: 'Completed',
    complianceDocsAdditionalInfo: '-',
    complianceDocsOwner: 'Dr Ray Smith',
    simulationAppntDueDate: 'Mar-14-2024',
    simulationAppntTime: '9:00 AM',
    simulationStatus: 'Completed',
    simulationAdditionalInfo: '-',
    simulationOwner: 'Planning Team',
    fusionDueDate: 'Mar-14-2024',
    fusionStatus: 'Completed',
    fusionOwner: 'Planning Team',
    targetVolumeDueDate: 'Mar-18-2024',
    targetVolumeStatus: 'Overdue',
    targetVolumeOwner: 'Dr Ray Smith',
    contouringDueDate: 'Mar-22-2024',
    contouringStatus: 'Due today',
    contouringOwner: 'Planning Team',
    planApprovalDueDate: 'Mar-27-2024',
    planApprovalStatus: 'To do',
    planApprovalOwner: 'Dr Ray Smith',
  },
  {
    patientId: 8860,

    patientName: 'Jane Becker',
    gender: 'Female',
    MRN: '165444',
    DOB: 'Jun-29-1959',
    careplanName: 'Breast VMAT 5000/25',
    patientStatus: 'Pretreatment',
    dynamicAlertCategory: '-',
    firstTreatmentDate: 'Apr-08-2024',
    firstTreatmentTime: '12:00 PM',
    firstTreatmentLocation: 'Fairfield',
    firstTreatmentStatus: 'New Start',
    initialConsultAppntDate: 'Mar-06-2024',
    initialConsultAppntTime: '12:45 PM',
    initialConsultStatus: 'Completed',
    initialConsultAdditionalInfo: '-',
    initialConsultOwner: 'Dr Ray Smith',
    preAuthDueDate: 'Mar-06-2024',
    preAuthStatus: 'Completed',
    preAuthOwner: 'Dr Ray Smith',
    SIMRequestDueDate: 'Mar-06-2024',
    SIMRequestStatus: 'Completed',
    SIMRequestOwner: 'Dr Ray Smith',
    prescriptionDueDate: 'Mar-06-2024',
    prescriptionStatus: 'Completed',
    prescriptionOwner: 'Dr Ray Smith',
    complianceDocsDueDate: 'Mar-12-2024',
    complianceDocsStatus: 'Completed',
    complianceDocsAdditionalInfo: '-',
    complianceDocsOwner: 'Dr Ray Smith',
    simulationAppntDueDate: 'Mar-14-2024',
    simulationAppntTime: '12:00 PM',
    simulationStatus: 'Completed',
    simulationAdditionalInfo: '-',
    simulationOwner: 'Planning Team',
    fusionDueDate: 'Not applicable',
    fusionStatus: 'Not applicable',
    fusionOwner: 'Not applicable',
    targetVolumeDueDate: 'Mar-22-2024',
    targetVolumeStatus: 'Due today',
    targetVolumeOwner: 'Dr Ray Smith',
    contouringDueDate: 'Mar-23-2024',
    contouringStatus: 'To do',
    contouringOwner: 'Planning Team',
    planApprovalDueDate: 'Apr-02-2024',
    planApprovalStatus: 'To do',
    planApprovalOwner: 'Dr Ray Smith',
  },
  {
    patientId: 32117,

    patientName: 'Sarah Nelson',
    gender: 'Female',
    MRN: '113455',
    DOB: 'Dec-23-1978',
    careplanName: 'Pelvis VMAT 5000/25',
    patientStatus: 'Pretreatment',
    dynamicAlertCategory: '-',
    firstTreatmentDate: 'Apr-09-2024',
    firstTreatmentTime: '9:45 AM',
    firstTreatmentLocation: 'Lincoln',
    firstTreatmentStatus: 'New Start',
    initialConsultAppntDate: 'Mar-07-2024',
    initialConsultAppntTime: '9:15 AM',
    initialConsultStatus: 'Completed',
    initialConsultAdditionalInfo: '-',
    initialConsultOwner: 'Dr Ray Smith',
    preAuthDueDate: 'Mar-07-2024',
    preAuthStatus: 'Completed',
    preAuthOwner: 'Dr Ray Smith',
    SIMRequestDueDate: 'Mar-07-2024',
    SIMRequestStatus: 'Completed',
    SIMRequestOwner: 'Dr Ray Smith',
    prescriptionDueDate: 'Mar-07-2024',
    prescriptionStatus: 'Completed',
    prescriptionOwner: 'Dr Ray Smith',
    complianceDocsDueDate: 'Mar-15-2024',
    complianceDocsStatus: 'Overdue',
    complianceDocsAdditionalInfo: '2/5 completed',
    complianceDocsOwner: 'Dr Ray Smith',
    simulationAppntDueDate: 'Mar-18-2024',
    simulationAppntTime: '9:45 AM',
    simulationStatus: 'Completed',
    simulationAdditionalInfo: '-',
    simulationOwner: 'Planning Team',
    fusionDueDate: 'Not applicable',
    fusionStatus: 'Not applicable',
    fusionOwner: 'Not applicable',
    targetVolumeDueDate: 'Mar-23-2024',
    targetVolumeStatus: 'To do',
    targetVolumeOwner: 'Dr Ray Smith',
    contouringDueDate: 'Mar-23-2024',
    contouringStatus: 'To do',
    contouringOwner: 'Planning Team',
    planApprovalDueDate: 'Apr-03-2024',
    planApprovalStatus: 'To do',
    planApprovalOwner: 'Dr Ray Smith',
  },
  {
    patientId: 21711,

    patientName: 'Peter Brown',
    gender: 'Male',
    MRN: '137788',
    DOB: 'Nov-24-1971',
    careplanName: 'Prostate VMAT 6000/30',
    patientStatus: 'Pretreatment',
    dynamicAlertCategory: '-',
    firstTreatmentDate: 'Apr-09-2024',
    firstTreatmentTime: '3:00 PM',
    firstTreatmentLocation: 'Lincoln',
    firstTreatmentStatus: 'New Start',
    initialConsultAppntDate: 'Mar-11-2024',
    initialConsultAppntTime: '3:10 PM',
    initialConsultStatus: 'Completed',
    initialConsultAdditionalInfo: '-',
    initialConsultOwner: 'Dr Ray Smith',
    preAuthDueDate: 'Mar-11-2024',
    preAuthStatus: 'Completed',
    preAuthOwner: 'Dr Ray Smith',
    SIMRequestDueDate: 'Mar-11-2024',
    SIMRequestStatus: 'Completed',
    SIMRequestOwner: 'Dr Ray Smith',
    prescriptionDueDate: 'Mar-11-2024',
    prescriptionStatus: 'Completed',
    prescriptionOwner: 'Dr Ray Smith',
    complianceDocsDueDate: 'Mar-15-2024',
    complianceDocsStatus: 'Overdue',
    complianceDocsAdditionalInfo: '0/5 completed',
    complianceDocsOwner: 'Dr Ray Smith',
    simulationAppntDueDate: 'Mar-18-2024',
    simulationAppntTime: '3:00 PM',
    simulationStatus: 'Completed',
    simulationAdditionalInfo: '-',
    simulationOwner: 'Planning Team',
    fusionDueDate: 'Mar-18-2024',
    fusionStatus: 'Completed',
    fusionOwner: 'Planning Team',
    targetVolumeDueDate: 'Mar-24-2024',
    targetVolumeStatus: 'To do',
    targetVolumeOwner: 'Dr Ray Smith',
    contouringDueDate: 'Mar-24-2024',
    contouringStatus: 'To do',
    contouringOwner: 'Planning Team',
    planApprovalDueDate: 'Apr-03-2024',
    planApprovalStatus: 'To do',
    planApprovalOwner: 'Dr Ray Smith',
  },
  {
    patientId: 5515,

    patientName: 'James Maple',
    gender: 'Male',
    MRN: '165533',
    DOB: 'Apr-22-1942',
    careplanName: 'LT Brain VMAT 6000/30',
    patientStatus: 'Pretreatment',
    dynamicAlertCategory: '-',
    firstTreatmentDate: 'Apr-10-2024',
    firstTreatmentTime: '10:15 AM',
    firstTreatmentLocation: 'Fairfield',
    firstTreatmentStatus: 'New Start',
    initialConsultAppntDate: 'Mar-22-2024',
    initialConsultAppntTime: '10:55 AM',
    initialConsultStatus: 'To do',
    initialConsultAdditionalInfo: 'Referral letter',
    initialConsultOwner: 'Dr Ray Smith',
    preAuthDueDate: 'Mar-22-2024',
    preAuthStatus: 'Due today',
    preAuthOwner: 'Dr Ray Smith',
    SIMRequestDueDate: 'Mar-26-2024',
    SIMRequestStatus: 'Due today',
    SIMRequestOwner: 'Dr Ray Smith',
    prescriptionDueDate: 'Mar-26-2024',
    prescriptionStatus: 'Due today',
    prescriptionOwner: 'Dr Ray Smith',
    complianceDocsDueDate: 'Mar-22-2024',
    complianceDocsStatus: 'Due today',
    complianceDocsAdditionalInfo: '-',
    complianceDocsOwner: 'Dr Ray Smith',
    simulationAppntDueDate: 'Mar-29-2024',
    simulationAppntTime: '10:15 AM',
    simulationStatus: 'To do',
    simulationAdditionalInfo: '-',
    simulationOwner: 'Planning Team',
    fusionDueDate: 'Mar-29-2024',
    fusionStatus: 'To do',
    fusionOwner: 'Planning Team',
    targetVolumeDueDate: 'Mar-27-2024',
    targetVolumeStatus: 'To do',
    targetVolumeOwner: 'Dr Ray Smith',
    contouringDueDate: 'Mar-27-2024',
    contouringStatus: 'To do',
    contouringOwner: 'Planning Team',
    planApprovalDueDate: 'Apr-04-2024',
    planApprovalStatus: 'To do',
    planApprovalOwner: 'Dr Ray Smith',
  },
  {
    patientId: 8213,
    patientName: 'Mary White',
    gender: 'Female',
    MRN: '193399',
    DOB: 'Jul-30-1973',
    careplanName: 'Oesophagus VMAT 6000/30',
    patientStatus: 'Pretreatment',
    dynamicAlertCategory: '-',
    firstTreatmentDate: 'Apr-10-2024',
    firstTreatmentTime: '2:15 PM',
    firstTreatmentLocation: 'Fairfield',
    firstTreatmentStatus: 'New Start',
    initialConsultAppntDate: 'Mar-26-2024',
    initialConsultAppntTime: '2:35 PM',
    initialConsultStatus: 'To do',
    initialConsultAdditionalInfo: 'Referral letter',
    initialConsultOwner: 'Dr Ray Smith',
    preAuthDueDate: 'Mar-26-2024',
    preAuthStatus: 'To do',
    preAuthOwner: 'Dr Ray Smith',
    SIMRequestDueDate: 'Mar-26-2024',
    SIMRequestStatus: 'To do',
    SIMRequestOwner: 'Dr Ray Smith',
    prescriptionDueDate: 'Mar-26-2024',
    prescriptionStatus: 'To do',
    prescriptionOwner: 'Dr Ray Smith',
    complianceDocsDueDate: 'Mar-26-2024',
    complianceDocsStatus: 'To do',
    complianceDocsAdditionalInfo: '-',
    complianceDocsOwner: 'Dr Ray Smith',
    simulationAppntDueDate: 'Mar-29-2024',
    simulationAppntTime: '2:15 PM',
    simulationStatus: 'To do',
    simulationAdditionalInfo: '-',
    simulationOwner: 'Planning Team',
    fusionDueDate: 'Mar-29-2024',
    fusionStatus: 'To do',
    fusionOwner: 'Planning Team',
    targetVolumeDueDate: 'Mar-27-2024',
    targetVolumeStatus: 'To do',
    targetVolumeOwner: 'Dr Ray Smith',
    contouringDueDate: 'Mar-27-2024',
    contouringStatus: 'To do',
    contouringOwner: 'Planning Team',
    planApprovalDueDate: 'Apr-04-2024',
    planApprovalStatus: 'To do',
    planApprovalOwner: 'Dr Ray Smith',
  },
];
