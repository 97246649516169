import { FormDataModel } from 'shared-components/interfaces';
import validate from 'validate.js';

// Interface that defines the data model for the Registration Form
export interface LoginFormModel extends FormDataModel {
  formData: {
    [key: string]: string;
    username: string;
    password: string;
  };
}

export default class LoginFormViewModel {
  public loginForm: LoginFormModel;
  // TODO: implement validation. Not a part of i1
  private validationRules = {};

  // Configuration options for the validator
  private validationOptions = {
    fullMessages: false,
    format: 'flat',
  };

  public constructor() {
    this.loginForm = {
      formData: {
        username: '',
        password: '',
      },
    };
  }

  /**
   * Function that updates the form data stored within this object
   * Once updated, it executes a request to Apollo to ensure the database is synced.
   * @param {string} key - Property of RegistrationFormData object
   * @param {string} newValue - Value to be assigned to the key
   * @param {function=} errorHandler - Called on a validation error
   * @param {function=} successHandler - Called on validation success
   */
  // TODO: Should probaly move this logic into a parent class and let children inherit by default
  public validateField = (
    key: string,
    newValue: string,
    errorHandler?: (message: string) => void,
    successHandler?: () => void,
  ): void => {
    // Create the data to be validated
    const validationObject: { [key: string]: string } = {};
    validationObject[key] = newValue;

    let invalidMessage: string | undefined = 'errors.invalid_message';
    invalidMessage = validate(validationObject, this.validationRules, this.validationOptions);

    // No validation issues
    if (invalidMessage === undefined) {
      // Call the success handler to update states
      if (successHandler) {
        successHandler();
      }
      // Update the form data object with the new value
      this.loginForm.formData[key] = newValue;
    } else {
      if (errorHandler) {
        errorHandler(invalidMessage[0]);
      }
    }
  };
}
