// eslint-disable-next-line no-use-before-define
import { useMutation, useQuery } from '@apollo/client';
import { ExtendLock, HeaderBar, ModalSaveExit, SideNavContainer, WelcomeBackModal } from 'op-components';
import { DeviceUtilities } from 'shared-components/utils';
import { RELEASE_LOCK_MUTATION } from 'op-graphql';
import React, { useState } from 'react';
import { withApollo } from '@apollo/client/react/hoc';
import { generatePath, Link, Route, Switch, useHistory, useParams, useRouteMatch, withRouter } from 'react-router-dom';
import { auRegoSteps, MiddleContainerCol, OncologyStepper, RightContainerCol } from 'shared-components/components';
import { supportedSize } from 'shared-components/StyledComponents/breakpoints';
import { styled } from '@mui/system';
import {
  Address,
  Basic,
  ContactDetails,
  Demographics,
  DocumentUploadPage,
  EmergencyContact,
  InformationNotice,
  Insurance,
  Referrer,
  RegistrationConflictsApollo,
  ReviewAndSubmit,
} from '.';
import { StyledArrow, StyledTitle } from '../../../components/SideNav/common';
import { PSO_SUMMARY_LINK, registrationPath } from './Helper';
import { LAST_VISITED_QUERY, UPDATE_LAST_VISITED_SECTION } from './RegistrationFormQueries';
import PatientEMR from 'op-components/SideNav/PatientEMR';
import { StandardDialog } from 'shared-components/components';
import { Typography } from '@mui/material';

const StyledContainer = styled('div')`
  height: calc(100vh - 54px);
`;

const StyledRow = styled('div')`
  height: 100%;
  flex-wrap: nowrap;
  display: flex;
  flex-direction: row;
`;

const StyledWrapper = styled('div')`
  display: flex;
  flex-direction: column;
  height: 100%;

  div:nth-child(1) {
    flex-shrink: 0;
  }
`;

const StyledLink = styled(Link)`
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 16px 0px 0 16px;
  text-decoration: none;
  height: 45px;

  &:focus,
  &:hover,
  &:visited,
  &:link,
  &:active {
    text-decoration: none;
  }
`;

const StyledDivider = styled('div')`
  border-bottom: 1px solid ${(props) => props.theme.palette.grey[300]};
  margin-left: 16px;
  margin-right: 16px;
  padding-top: 16px;
`;

const StyledStepperHeading = styled('div')`
  font-weight: 700;
  font-size: 13px;
  line-height: 16px;
  margin: 16px 0 0 16px;
  color: ${(props) => props.theme.palette.grey[600]};
`;

const StyledSideNavContainer = styled((props: any) => <SideNavContainer {...props} />)`
  @media all and (max-width: ${supportedSize.transition}px) {
    display: ${(props) => (props?.$isvisible ? 'auto' : 'none')};
  }
`;

const getCurrentPage = (): string => {
  const url = window.location.href;
  const currentPage = url.split('/').pop() || '';
  return currentPage;
};

const conflictsStepper = {
  key: 'co',
  header: 'Conflicts',
  short: 'CO',
  steps: [{ idx: -1, text: 'Conflicts', page: 'conflicts' }],
};

const RegistrationAU = (): JSX.Element => {
  const { path } = useRouteMatch();
  const { patientId: id } = useParams<{ patientId: string }>();
  const history = useHistory();
  const isMobile = DeviceUtilities.isMobileDevice();

  const [saveExitModalOpen, setSaveExitModalOpen] = useState(false);
  const [welcomeBackModalOpen, setWelcomeBackModalOpen] = useState(false);
  const [lockModalOpen, setLockModalOpen] = useState(false);
  const [isPso, setIsPso] = useState(false);

  const [updateLastVisited] = useMutation(UPDATE_LAST_VISITED_SECTION);
  const [releaseLock] = useMutation(RELEASE_LOCK_MUTATION);
  const { data, error } = useQuery(LAST_VISITED_QUERY, {
    variables: { id },
    fetchPolicy: 'network-only',
    onCompleted: (data) => {
      const { patient, user } = data;

      setIsPso(user?.isPso);
      const currentPage = getCurrentPage();
      const isLocked = patient.lock.readOnly && patient.lock.lockedBy !== user.id.toString();
      if (isLocked) {
        setLockModalOpen(true);
      } else if (
        user?.isPso === false &&
        currentPage !== 'infonotice' &&
        patient?.lastVisitedSection === getCurrentPage() &&
        patient?.regFormStatus
      ) {
        setWelcomeBackModalOpen(true);
      }
    },
  });

  if (error || !data) return <></>;

  const BackToPTSummary = (): JSX.Element => {
    const url = generatePath(`/navigator/patient/${id}/summary`);
    return (
      <>
        <StyledLink to={url} data-testid="back-to-summary-button">
          <StyledArrow />
          <StyledTitle>Back to patient summary</StyledTitle>
        </StyledLink>
        <StyledDivider />
      </>
    );
  };

  const returnToStart = (): void => {
    updateLastVisited({ variables: { id, lastVisitedSection: '' } });
    history.push({ pathname: registrationPath(id, 'infonotice'), state: { pxRedirect: true } });
  };

  const closeWelcomeModal = () => {
    updateLastVisited({ variables: { id, lastVisitedSection: '' } });
    setWelcomeBackModalOpen(false);
  };

  const closeRecordLockedModal = () => {
    const pathname = isPso ? generatePath(referringPage || PSO_SUMMARY_LINK, { patientId: id }) : `/patient/${id}/home`;
    history.push({ pathname });
  };

  const referringPage = sessionStorage.getItem('referringPage');

  const exitFormPatient = async (): Promise<void> => {
    await updateLastVisited({ variables: { id, lastVisitedSection: getCurrentPage() } });
    releaseLock({ variables: { accessPatientId: id } });
    history.push({ pathname: `/patient/${id}/home`, state: { pxRedirect: true } });
  };
  const exitFormPso = () => {
    releaseLock({ variables: { accessPatientId: id } });
    const pathname = generatePath(referringPage || PSO_SUMMARY_LINK, { patientId: id });
    history.push({ pathname });
  };

  const exitForm = isPso ? exitFormPso : () => setSaveExitModalOpen(true);

  const showSideNav = !window.location.href.includes('summary') && !isMobile;

  const diffMqData = data?.patient?.diffMqData;
  const hasDataConflicts = diffMqData && Object.entries(JSON.parse(`${diffMqData}`)).length !== 0;
  const regoSteps = isPso && hasDataConflicts ? [conflictsStepper].concat(auRegoSteps) : auRegoSteps;

  const lockErrorTextPso = `This patient record is currently in use by ${data?.patient?.lock?.lockedByName}. Please try and access again later.`;
  const lockErrorTextPatient = 'Your patient record is currently in use. Please try and access again later.';

  return (
    <>
      <StandardDialog
        open={lockModalOpen}
        title="Record in use"
        onSubmit={closeRecordLockedModal}
        submitText="Exit form">
        {<Typography>{isPso ? lockErrorTextPso : lockErrorTextPatient}</Typography>}
      </StandardDialog>
      <WelcomeBackModal
        returnToStart={() => returnToStart()}
        dismissFunction={() => closeWelcomeModal()}
        updateFirstReturn={() => {}}
        isOpen={welcomeBackModalOpen}
      />
      <ModalSaveExit
        isOpen={saveExitModalOpen}
        dismissFunction={() => setSaveExitModalOpen(false)}
        exitForm={exitFormPatient}
        exitText="Exit form"
      />
      <ExtendLock accessPatientId={data.patient.id} />
      <StyledWrapper>
        <HeaderBar
          defaultHeader={!isMobile && !isPso}
          menuButton={isMobile}
          menuButtonText={isMobile ? 'Save & Exit' : ''}
        />
        <StyledContainer>
          <StyledRow>
            <StyledSideNavContainer $isvisible={showSideNav}>
              {isPso && <PatientEMR patientId={id} />}
              <OncologyStepper steps={regoSteps} showTopDivider>
                {isPso && <BackToPTSummary />}
                <StyledStepperHeading>REGISTRATION FORM</StyledStepperHeading>
              </OncologyStepper>
            </StyledSideNavContainer>
            <MiddleContainerCol>
              <Switch>
                {isPso && hasDataConflicts && (
                  <Route path={`${path}/conflicts`}>
                    <RegistrationConflictsApollo exitForm={exitForm} />
                  </Route>
                )}
                <Route path={`${path}/basic`}>
                  <Basic exitForm={exitForm} />
                </Route>
                <Route path={`${path}/contact`}>
                  <ContactDetails exitForm={exitForm} />
                </Route>
                <Route path={`${path}/address`}>
                  <Address exitForm={exitForm} />
                </Route>
                <Route path={`${path}/emergencyContact`}>
                  <EmergencyContact exitForm={exitForm} />
                </Route>
                <Route path={`${path}/referrers`}>
                  <Referrer exitForm={exitForm} />
                </Route>
                <Route path={`${path}/insurance`}>
                  <Insurance exitForm={exitForm} />
                </Route>
                <Route path={`${path}/demographics`}>
                  <Demographics exitForm={exitForm} />
                </Route>
                <Route path={`${path}/upload`}>
                  <DocumentUploadPage exitForm={exitForm} />
                </Route>
                <Route path={`${path}/infonotice`}>
                  <InformationNotice exitForm={exitForm} />
                </Route>
                <Route path={`${path}/summary`}>
                  <ReviewAndSubmit exitForm={exitForm} />
                </Route>
              </Switch>
            </MiddleContainerCol>
            <RightContainerCol />
          </StyledRow>
        </StyledContainer>
      </StyledWrapper>
    </>
  );
};

const apolloComponent = withApollo(withRouter(RegistrationAU));
export default apolloComponent;
