// eslint-disable-next-line no-use-before-define
import React, { Component, Fragment } from 'react';

import './CovidPSOReview.scss';
import { RegistrationPatientDetails, SummaryCard, SummaryRow, PageTitle } from 'op-components';
import { RouteComponentProps, withRouter } from 'react-router-dom';
import { Button } from '@mui/material';
import { MandatoryPatient } from 'op-interfaces';
import { CovidAssessmentItem } from 'op-classes';
import { Success } from 'shared-components/images';
import moment from 'moment';
import { ASSESSMENT_AND_REVIEW } from '../constants';

interface Props extends RouteComponentProps {
  patient: MandatoryPatient;
  isPso?: boolean;
  covidScreening: CovidAssessmentItem;
}

class CovidPSOReview extends Component<Props> {
  public render(): JSX.Element {
    const { patient, history, isPso } = this.props;

    return (
      <Fragment>
        <div id="covid-review-header">
          <div id="covid-review-header-title">{ASSESSMENT_AND_REVIEW.COVID_SCREENING}</div>
        </div>
        <div className="covid-review-elements">
          <div className="left-container">
            <div className="newspaper-container">
              <RegistrationPatientDetails patient={patient} />
            </div>
            <Button
              type="submit"
              onClick={(e): void => {
                e.preventDefault();
                const pathname = (isPso ? '/navigator/' : '/') + `patient/${patient.id}/summary`;
                history.push(pathname);
              }}>
              {isPso ? 'Go to summary' : 'View all forms'}
            </Button>
          </div>
          <div className="right-container">
            <div>{this.renderCovidSummary()}</div>
          </div>
        </div>
      </Fragment>
    );
  }

  private renderCovidSummary(): JSX.Element {
    const { covidScreening } = this.props;
    const tick = <Success className={'secondary-icon valid-icon'} />;

    let dateOfSymptoms = 'Not applicable';
    if (covidScreening.hasSymptoms) {
      dateOfSymptoms = this.formatDate(covidScreening.dateOfFeverSymptoms);
    }

    return (
      <Fragment>
        <PageTitle title={'Review and submit'} idPrefix="covid" />
        <div className="covid-review-summary-page">
          <SummaryCard
            title={ASSESSMENT_AND_REVIEW.COVID_SCREENING}
            primaryIcon={<Fragment></Fragment>}
            secondaryIcon={tick}
            invalidContent={false}>
            <h2>1. COVID-19 diagnosis</h2>
            <SummaryRow
              headingText={ASSESSMENT_AND_REVIEW.HAVE_YOU_BEEN_DIAGNOSED}
              detailText={this.renderBoolContent(covidScreening.hasBeenDiagnosed)}
              displayAsError={false}
              displayAsOptional={false}
            />
            <SummaryRow
              headingText={ASSESSMENT_AND_REVIEW.TESTED_AND_WAITING}
              detailText={this.renderBoolContent(covidScreening.hasBeenTested)}
              displayAsError={false}
              displayAsOptional={false}
            />
            <h2>2. Epidemiological criteria</h2>
            <SummaryRow
              headingText={ASSESSMENT_AND_REVIEW.ADVISED_TO_QUARANTINE}
              detailText={this.renderBoolContent(covidScreening.advisedToQuarantine)}
              displayAsError={false}
              displayAsOptional={false}
            />
            <SummaryRow
              headingText={ASSESSMENT_AND_REVIEW.CLOSE_CONTACT_WITH_QUARANTINE}
              detailText={this.renderBoolContent(covidScreening.hadContact)}
              displayAsError={false}
              displayAsOptional={false}
            />
            <SummaryRow
              headingText={ASSESSMENT_AND_REVIEW.TRAVELLED_TO_HOTSPOT}
              detailText={this.renderBoolContent(covidScreening.travelledToHotspot)}
              displayAsError={false}
              displayAsOptional={false}
            />
            <h2>3. Clinical criteria</h2>
            <SummaryRow
              headingText={ASSESSMENT_AND_REVIEW.EXPERIENCING_THE_FOLLOWING}
              detailText={this.renderBoolContent(covidScreening.hasSymptoms)}
              displayAsError={false}
              displayAsOptional={false}
            />
            <SummaryRow
              headingText={ASSESSMENT_AND_REVIEW.DATE_OF_SYMPTOMS}
              detailText={dateOfSymptoms}
              displayAsError={false}
              displayAsOptional={false}
            />
          </SummaryCard>
        </div>
      </Fragment>
    );
  }

  private formatDate = (date: Date | string): string => {
    if (!date) {
      return 'Not provided';
    }
    const formattedDate = moment.utc(date).local().format('DD/MM/YYYY');
    if (formattedDate === 'Invalid date') {
      return date.toString();
    } else {
      return formattedDate;
    }
  };

  private renderBoolContent = (answer: boolean | null): string => {
    if (answer === null || typeof answer === 'undefined') {
      return 'Not provided';
    }
    return answer ? 'Yes' : 'No';
  };
}

const routerComponent = withRouter(CovidPSOReview);
export default routerComponent;
