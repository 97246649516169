import { CurrentAppConfig } from 'op-pages/RO/Careplan/AppConfig';

export const sharedContent = {
  reviewAndSubmit: {
    title: 'QCLs Generated',
    noQclsGenerated: 'No QCLs generated',
    qclGenerationText: (isAmendment: boolean): string => {
      return `Submitting this ${
        isAmendment ? 'amended assessment' : 'assessment'
      } will generate the following QCLs in Mosaiq. Please find and complete these in Mosaiq after submitting this form.`;
    },
    missingQcl: {
      title: 'Missing QCL Location Field',
      mainText1: 'Please provide an answer to the required <b>QCL location</b> field under',
      mainText2: 'before submitting the form.',
      returnToField: 'Return to field',
    },
    submit: {
      title: 'Approve and Submit',
      mainText1: 'Initial',
      mainText2: 'approved and submitted by:',
      subText1: 'You’re about to submit the Initial',
      subText2: 'assessment. Please ensure all fields are correct before submitting this assessment.',
      additionalText: 'You will be able to submit an amendment to this assessment once submitted.',
    },
    submitAmendment: {
      title: 'Approve and Submit',
      mainText1: 'Initial',
      mainText2: 'approved and submitted by:',
      subText1: 'You’re about to submit the amended Initial',
      subText2: 'assessment. Submission of this will void the original assessment.',
      additionalText: 'Please ensure all fields are correct before submitting this assessment.',
    },
    proceedToTriage: {
      title: 'Proceed to Initial Triage?',
      mainText: 'Would you like to continue to the Initial Triage Assessment now?',
      helperText:
        'Selecting ’Back to Summary’ will generate QCL Initial Triage Call Required and take you to the ’Patient Summary’ page',
      backToSummary: 'Back to Summary',
    },
    submissionError: {
      title: 'Something Went Wrong',
      mainText: 'We have encountered an error. If the issue persists, please contact GenesisCare Innovation support: ',
      emailLink: CurrentAppConfig.SupportEmail,
      contactText: 'Contact us',
    },
  },
  additionalNotes: {
    sectionTitle: 'Additional Notes',
    helperText: 'Notes will appear in Mosaiq once this assessment is submitted',
  },
  generic: {
    label: {
      selectValue: 'Please select',
      specifyValue: 'Please specify',
      notProvided: 'Not Provided',
      continue: 'Continue',
      cancel: 'Cancel',
      submit: 'Submit',
    },
  },
};

export const convertFromBooleanToString = (value: boolean | null | undefined): string => {
  if (value) return 'Yes';
  if (value === false) return 'No';
  return '-';
};
