import { SelectField, TextAreaField, ToggleButtonGroupField } from 'shared-components/components/FormikComponents';

export const BASIC_FIELDS = {
  FORM_HEADING: {
    NAME: 'formHeading',
    TITLE: 'Basic Details',
  },
  TITLE_PREFIX: {
    NAME: 'namePrefix',
    TITLE: 'Title',
    PLACE_HOLDER: 'Please select',
    COMPONENT: SelectField,
  },
  FIRST_NAME: {
    NAME: 'firstName',
    TITLE: 'First name*',
    PLACE_HOLDER: 'Enter first name',
    COMPONENT: TextAreaField,
  },
  MIDDLE_NAME: {
    NAME: 'middleName',
    TITLE: 'Middle name',
    PLACE_HOLDER: 'Enter middle name',
    COMPONENT: TextAreaField,
  },
  LAST_NAME: {
    NAME: 'lastName',
    TITLE: 'Last name*',
    PLACE_HOLDER: 'Enter last name',
    COMPONENT: TextAreaField,
  },
  PREFERRED_NAME: {
    NAME: 'preferredName',
    TITLE: 'Preferred name',
    PLACE_HOLDER: 'If different than first name',
    COMPONENT: TextAreaField,
  },
  GENDER: {
    NAME: 'gender',
    TITLE: 'What was your sex recorded at birth*',
    COMPONENT: ToggleButtonGroupField,
  },
  GENDER_IDENTIFICATION: {
    NAME: 'genderIdentification',
    TITLE: 'How would you describe your gender*',
    COMPONENT: SelectField,
  },
  SPECIFY_GENDER: {
    NAME: 'genderIdentificationOther',
    TITLE: 'Please specify',
    COMPONENT: TextAreaField,
  },
  DOB_RAW: {
    NAME: 'dob',
    TITLE: 'Date of birth*',
    COMPONENT: null,
  },
};
