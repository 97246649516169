// eslint-disable-next-line no-use-before-define
import classnames from 'classnames';
import React, { ReactNode } from 'react';
import { Region } from 'shared-components/enums';
import './PageContainer.scss';

const REACT_APP_REGION = import.meta.env.REACT_APP_REGION;
const region = REACT_APP_REGION;
interface PageContainerProps {
  children?: ReactNode;
}

const PageContainer = (props: PageContainerProps): JSX.Element => {
  const { children } = props;
  return (
    <div className="page-slider-container">
      <div
        className={classnames('page-container page-slider-container', {
          'uk-page-container': region === Region.UK,
        })}>
        <div className={classnames('page-content')}>{children}</div>
      </div>
    </div>
  );
};

export default PageContainer;
