import { gql } from '@apollo/client';

export const GET_ACTIVE_QCLS = gql`
  query Tasks($patientId: ID!, $isAmendment: Boolean!) {
    activeTasks(patientId: $patientId, isAmendment: $isAmendment) {
      id
      name
    }
  }
`;

export const GET_ALLOWED_LOCATIONS = gql`
  query Locations($roles: [String]!, $hasOther: Boolean!) {
    allowedLocations(roles: $roles, hasOther: $hasOther) {
      id
      name
      rolesUsedBy
      __typename
    }
  }
`;
