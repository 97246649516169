// eslint-disable-next-line no-use-before-define
import React, { Component, Fragment } from 'react';

import classnames from 'classnames';

import './NewsCard.scss';

import classNames from 'classnames';

interface Props {
  title: string;
  primaryIcon: JSX.Element;
  secondaryIcon?: JSX.Element;
  id?: string;
  onClick?: () => void;
  invalidContent?: boolean;
  showEdit?: boolean;
  children?: React.ReactNode;
}

class NewsCard extends Component<Props> {
  public static defaultProps = {
    invalidContent: false,
  };

  public render(): JSX.Element {
    const { title, primaryIcon, secondaryIcon, children, invalidContent, id } = this.props;
    return (
      <div className={classnames('newscard newspaper-item', { 'validation-error': invalidContent })}>
        <div
          className={classNames(this.getSticky(), 'newscard__heading', 'newscard--bold', {
            clickable: this.props.onClick,
          })}
          data-test-id={`header-${title}`}
          onClick={this.props.onClick}>
          <span className={secondaryIcon === undefined ? 'newscard-title-no-icon' : 'newscard-title'} id={id}>
            {title}
          </span>
          {primaryIcon}
          {this.renderEdit()}
          {secondaryIcon}
        </div>
        <div className="newscard-content">{children}</div>
      </div>
    );
  }

  public renderEdit(): JSX.Element {
    if (this.props.showEdit) {
      return <p className="newscard-edit">Edit</p>;
    } else {
      return <Fragment></Fragment>;
    }
  }

  /**
   * Temporary function - a chrome updated cause sticky headers to no longer render on columns correctly
   * We only use columns on desktop & tablet size. So if chrome and a desktop do not apply the stick-top css class
   * Please monitor as chrome updates, if fixed remove this function
   * @return {string} CSS sticky class to apply. If no class to apply returns a blank string.
   */
  private getSticky(): string {
    const isTabletOrDesktop = window.innerWidth > 767;
    if (this.isChrome() && isTabletOrDesktop) {
      return '';
    }
    return 'sticky-top';
  }

  // Have to use this function as azure appearst o be missing window.chrome.runtime. So no simple way to check if a browser is chromes
  private isChrome(): boolean {
    // please note,
    // that IE11 now returns undefined again for window.chrome
    // and new Opera 30 outputs true for window.chrome
    // but needs to check if window.opr is not undefined
    // and new IE Edge outputs to true now for window.chrome
    // and if not iOS Chrome check
    // so use the below updated condition
    const isChromium = window.chrome;
    const winNav = window.navigator;
    const vendorName = winNav.vendor;
    const isOpera = typeof window.opr !== 'undefined';
    const isIEedge = winNav.userAgent.indexOf('Edge') > -1;
    const isIOSChrome = winNav.userAgent.match('CriOS');

    if (isIOSChrome) {
      // is Google Chrome on IOS
      return true;
    } else if (
      isChromium !== null &&
      typeof isChromium !== 'undefined' &&
      vendorName === 'Google Inc.' &&
      isOpera === false &&
      isIEedge === false
    ) {
      // is Google Chrome
      return true;
    } else {
      // not Google Chrome
      return false;
    }
  }
}

export default NewsCard;
