import { gql } from '@apollo/client';

export const CREATE_PATIENT = gql`
  mutation CreatePatient {
    createPatient {
      patient {
        id
      }
    }
  }
`;

export const SET_USER_LANGUAGE = gql`
  mutation translation($lang: String!) {
    changeLanguage(language: $lang) {
      newLanguage {
        language
      }
      languageChanged
    }
  }
`;

export const GET_UNSUBMITTED_COUNT = gql`
  query GetUnsubmittedCount {
    getUnsubmittedCount {
      id
      drafts
      unsubmitted
    }
  }
`;

export const GET_UPCOMING_DOCTOR_CAREPLANS = gql`
  query UpcomingDoctorCareplans($startDate: Date, $endDate: Date) {
    upcomingDoctorCareplans(startDate: $startDate, endDate: $endDate) {
      id
      patient {
        id
        fullName
        dob
        gender
        address {
          id
          formattedAddress
        }
        photoUrl
      }
      treatingDepartment
      careplanName
      nextTreatmentDate
      nextTreatmentInfo
      nextTreatmentCycleId
      status
      prescribingDoctor
      lastModified
    }
  }
`;

export const GET_TREATMENT_DASHBOARD_SETTINGS = gql`
  query {
    treatmentDashboardSettings {
      practitionerId
      practitionerName
      practitionerSelected
    }
  }
`;

export const UPDATE_TREATMENT_DASHBOARD_SETTINGS = gql`
  mutation UpdateTreatmentDashboardSettings($selectedPrescribingDoctors: [Int]!) {
    updateTreatmentDashboardSettings(selectedPrescribingDoctors: $selectedPrescribingDoctors) {
      success
    }
  }
`;
