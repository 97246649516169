// eslint-disable-next-line no-use-before-define
import './WarningInfo.scss';
import { WarningOutlined as WarningOutlinedIcon } from '@mui/icons-material';

interface Props {
  warnings?: string[];
  warningMessages?: { [key: string]: string };
  black?: boolean;
}

const WarningInfo = ({ warnings, black, warningMessages }: Props): JSX.Element => {
  if (!warnings) {
    return <></>;
  }
  const className = black ? 'warning-message-black' : 'warning-message';

  return (
    <>
      {warnings.map((warning: string): JSX.Element => {
        let warningMessage = warning;

        if (warningMessages) {
          warningMessage = warningMessages[warning];
        }

        return (
          <div className="warning-state" key={warningMessage}>
            <WarningOutlinedIcon className="icon" color="warning" />
            <div className={className}>{warningMessage}</div>
          </div>
        );
      })}
    </>
  );
};

export default WarningInfo;
