// eslint-disable-next-line no-use-before-define
import React, { useState, useEffect } from 'react';
import { styled } from '@mui/system';
import moment from 'moment';

import { getRemSize } from '../../StyledComponents/functions';
import { useIsOverflow } from 'shared-components/utils/useIsOverflow';
import { BaseSelectCheckbox } from '../FormFields';
import { Stack, Typography } from '@mui/material';
import { KeyboardArrowDown, KeyboardArrowUp } from '@mui/icons-material';
import { legacyOnChange } from '../FormFields/ROSelectCheckbox/BaseSelectCheckbox';

const DATATYPE = {
  DATE: 'DATE',
  STRING: 'STRING',
  NUMBER: 'NUMBER',
};

interface StyledRowProps {
  active: boolean;
  $width?: string;
}

interface StyledNoDataRowProps {
  colspan: string;
}

interface TableHeaderProps {
  title: string;
  key: string;
  sort?: boolean;
  type: string;
  width?: string;
  filterOptions?: any;
  onFilterChange?: (...args: any[]) => any;
  disableCheckAll?: boolean;
  dropdownTitle?: string | undefined;
  badgeNumber?: number;
}

interface TableDataProps {
  title?: string;
  subtitle?: string;
  id?: string;
  key: string;
  width?: string;
  component: JSX.Element;
  sortableField?: any;
}

interface DefaultSort {
  key: string;
  order: string;
  type: string;
}
interface TableProps {
  isOverflow?: boolean;
}

interface TableContainerProps {
  minWidth?: string;
  overflow?: string;
}

interface Props {
  tableHeaders: TableHeaderProps[];
  tableData: { tableRows: TableDataProps[]; onRowClick?: any; rowBackgroundColor?: string }[];
  dateFormat?: string;
  defaultSelectedIndex?: number;
  testId?: string;
  defaultSortState?: DefaultSort;
  activeRowIndex?: any;
  setActiveRowIndex?: any;
  noRowsText?: string;
  hoverScroll?: boolean;
  tableContainerProps?: TableContainerProps;
}

const TableContainer = styled('table')<TableContainerProps>`
  height: 100%;
  display: block;
  border-collapse: separate;
  min-width: ${(props: TableContainerProps) => (props.minWidth ? props.minWidth : '0')};
  width: 100%;
  overflow: ${(props: TableContainerProps) => (props.overflow ? props.overflow : 'hidden')};
`;

const TableHead = styled('thead')<TableProps>`
  display: flex;
  padding-right: ${(props: TableProps) => (props.isOverflow ? '15px' : '0px')};
`;

const TableHeaderRow = styled('tr')`
  flex-grow: 1;
  display: flex;
`;

const TableHeader = styled('th')<{ $width?: string }>`
  position: relative;
  display: flex;
  align-items: center;
  width: ${({ $width }: { $width?: string }) => $width || '100%'};
  white-space: nowrap;
  padding: 18px 16px;
  color: ${(props) => props.theme.palette.text.primary};
  background-color: ${(props) => props.theme.palette.grey[100]};
  font-size: ${getRemSize(16)};
`;

// overflow hidden only displays first 12 careplans on Careplan Dashboard
const TableBody = styled('tbody')<any>`
  height: calc(100% - 60px);
  max-height: calc(100% - 60px);
  display: block;
  width: 100%;
  overflow: ${(props: TableProps) => (props.isOverflow ? 'scroll' : 'hidden')};
  &:hover {
    overflow: ${(props: any) => (props.hoverScroll && props?.isOverflow ? 'scroll' : 'overlay')};
  }
`;

const TableRow = styled('tr')<any>`
  display: flex;
  border-bottom: 1px solid ${(props) => props.theme.palette.grey[300]};
  background-color: ${(props): any => props.$rowBackgroundColor};
  &:hover {
    background-color: ${(props): any => props.$rowHoverColor};
    cursor: ${(props): any => (props.$onRowClick ? 'pointer' : 'auto')};
  }
`;

const TableData = styled('td')<StyledRowProps>`
  display: flex;
  align-items: center;
  width: ${(props: StyledRowProps) => props.$width || '100%'};
  padding: ${(props: StyledRowProps) => (props.active ? '6px 14px 7px 14px' : '8px 16px')};
  font-size: ${getRemSize(16)};
  vertical-align: middle;
  border-top: ${(props) => (props.active ? `2px solid ${props.theme.palette.primary.dark}` : '')};
  border-bottom: ${(props) =>
    props.active ? `2px solid ${props.theme.palette.primary.dark}` : `1px solid ${props.theme.palette.grey[300]}`};
  &:last-of-type {
    //justify-content: flex-end;
    border-right: ${(props) => (props.active ? `2px solid ${props.theme.palette.primary.dark}` : '')};
    border-radius: ${(props: StyledRowProps) => (props.active ? '0 4px 4px 0' : '')};
  }
  &:first-of-type {
    border-left: ${(props) => (props.active ? `2px solid ${props.theme.palette.primary.dark}` : '')};
    border-radius: ${(props: StyledRowProps) => (props.active ? '4px 0 0 4px' : '')};
  }
`;

const NoDataRow = styled('tr')`
  display: flex;
  width: 100%;
`;
const NoDataRowColumn = styled('td')<StyledNoDataRowProps>`
  colspan: ${(props: StyledNoDataRowProps) => props.colspan};
  width: 100%;
  border-bottom: 0px;
`;

const NoDataText = styled('div')`
  margin: 16px 0 0 16px;
`;

const SORT_TYPES = {
  ASC: 'ASC',
  DESC: 'DESC',
};

const NO_DATA = 'No results found';

const Table = (props: Props): JSX.Element => {
  const { tableHeaders, tableData, testId, defaultSortState, activeRowIndex, noRowsText } = props;
  const [sortState, setSortState] = useState(defaultSortState || { key: '', order: '', type: '' });
  const [sortedData, setSortedData] = useState<any>();
  const tableBodyRef = React.useRef();
  const isTableBodyOverflow = useIsOverflow(tableBodyRef, () => {});
  useEffect(() => {
    if (tableData?.length) {
      const copyOfTableData: any = [...tableData];
      const sortedDataOnToggle = [...copyOfTableData].sort((a: any, b: any): number => {
        const findIndex = (arrayOfObjects: any) => arrayOfObjects.find((item: any) => item.key === sortState.key);
        const indexA = findIndex(a.tableRows);
        const indexB = findIndex(b.tableRows);
        if (sortState.type === DATATYPE.DATE) {
          const atime = moment(indexA.sortableField, 'L').toDate().getTime();
          const btime = moment(indexB.sortableField, 'L').toDate().getTime();
          return sortState.order === SORT_TYPES.ASC ? btime - atime : atime - btime;
        }
        if (sortState.type === DATATYPE.STRING) {
          const order = sortState.order === SORT_TYPES.ASC ? 1 : -1;
          if (indexA.sortableField > indexB.sortableField) return order;
          if (indexA.sortableField < indexB.sortableField) return -1 * order;
        }
        return 0;
      });

      setSortedData(sortedDataOnToggle);
    } else {
      setSortedData([]);
    }
  }, [sortState, tableData]);

  const onSortToggle = (header: TableHeaderProps): void => {
    const sortOrder = sortState.order === SORT_TYPES.DESC ? SORT_TYPES.ASC : SORT_TYPES.DESC;
    setSortState({ key: header.key, order: sortOrder, type: header.type });
  };
  const NoDataDefaultContainer = (
    <NoDataRow>
      <Stack direction="row" alignItems="center" padding={1} gap={1}>
        <Typography variant="subtitle1">{noRowsText || NO_DATA}</Typography>
      </Stack>
    </NoDataRow>
  );
  return (
    <TableContainer
      data-test-id={testId}
      minWidth={props.tableContainerProps?.minWidth}
      overflow={props.tableContainerProps?.overflow}>
      <TableHead isOverflow={isTableBodyOverflow}>
        <TableHeaderRow>
          {tableHeaders.map((header) => {
            const checkedOptionCount = header?.filterOptions?.filter((item: any) => item.checked).length;
            const allSelected = checkedOptionCount === header?.filterOptions?.length;
            const activeSort = sortState['key'] === header.key;
            const headerOptions = header?.filterOptions?.map((docType: any) => {
              return {
                value: docType.name,
                id: docType.id,
                checked: docType.checked || false,
              };
            });
            return (
              <TableHeader key={header.key} $width={header.width} data-testid={'header-col-title'}>
                {header.hasOwnProperty('sort') && (
                  <Stack
                    onClick={() => {
                      onSortToggle(header);
                    }}
                    data-testid={`${header.title}-sort`}
                    sx={{ cursor: 'pointer' }}>
                    <KeyboardArrowUp
                      color={activeSort && sortState.order === SORT_TYPES.ASC ? 'primary' : 'secondary'}
                      sx={{ marginBottom: '-10px' }}
                    />
                    <KeyboardArrowDown
                      color={activeSort && !(sortState.order === SORT_TYPES.ASC) ? 'primary' : 'secondary'}
                    />
                  </Stack>
                )}
                {header.hasOwnProperty('filterOptions') ? (
                  <BaseSelectCheckbox
                    id={header.key}
                    options={headerOptions}
                    showSelectAll={!header.disableCheckAll}
                    badgeContent={allSelected ? undefined : String(header.badgeNumber || 0)}
                    placeholder={header.dropdownTitle || header.title}
                    onChange={(event) => legacyOnChange(event, headerOptions, header.onFilterChange)}
                    sx={{
                      '.MuiOutlinedInput-notchedOutline': { border: 'none', outline: 'none' },
                      fontWeight: 600,
                      fontSize: '14px',
                    }}
                  />
                ) : (
                  <Typography variant="subtitle1">{header.title}</Typography>
                )}
              </TableHeader>
            );
          })}
        </TableHeaderRow>
      </TableHead>
      <TableBody
        ref={tableBodyRef}
        data-test-id={`${testId}-body`}
        hoverScroll={props.hoverScroll}
        isOverflow={isTableBodyOverflow}>
        {sortedData?.length
          ? sortedData.map((item: any, index: number) => {
              const styledProps = {
                active: index === activeRowIndex,
              };
              return (
                <TableRow
                  data-test-id={`${testId}-row-${index}`}
                  key={`${item.tableRows[0].title}-${index}`}
                  onClick={() => {
                    if (item.onRowClick) {
                      item.onRowClick();
                    }
                  }}
                  $rowHoverColor={item?.rowHoverColor}
                  $rowBackgroundColor={item?.rowBackgroundColor}
                  $onRowClick={item?.onRowClick}>
                  {item.tableRows.map((data: any, x: number) => {
                    return (
                      <TableData key={x} {...styledProps} $width={data.width}>
                        {data.component}
                      </TableData>
                    );
                  })}
                </TableRow>
              );
            })
          : NoDataDefaultContainer}
      </TableBody>
    </TableContainer>
  );
};

export default Table;
export { TableHeaderProps, TableHeader, TableRow, SORT_TYPES, DATATYPE, DefaultSort, Props };
