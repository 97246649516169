import React from 'react';
import { FormControl, InputLabel, Autocomplete, TextField, Checkbox } from '@mui/material';

import FormRow from 'shared_components/components/FormFields/FormRow/FormRow';

import { useTheme, CustomTheme } from '@mui/material';
import CheckBoxOutlineBlankIcon from '@mui/icons-material/CheckBoxOutlineBlank';
import CheckBoxIcon from '@mui/icons-material/CheckBox';
import { getBorderColor } from 'shared-components/components/FormFields/common';
import { ROHelperText } from 'shared-components/components/FormFields';

type ChipMultiSelectProps = {
  selectedValues: string[];
  handleChange: (values: string[]) => void;
  options: string[];
  placeholder?: string;
  fieldLabel?: string;
  error?: boolean;
  id: string;
  requiredFieldColor?: string;
  required?: boolean;
};
const icon = <CheckBoxOutlineBlankIcon fontSize="small" />;
const checkedIcon = <CheckBoxIcon fontSize="small" />;

const ChipMultiSelect = (props: ChipMultiSelectProps): JSX.Element => {
  const { handleChange, selectedValues, options, placeholder, fieldLabel, error, required, id, requiredFieldColor } =
    props;
  const theme = useTheme<CustomTheme>();
  const helperTextProps = {
    error,
    id: 'selectedCareplan',
  };
  const borderColor = getBorderColor(error);
  return (
    <FormRow
      id={'selectedCareplan'}
      fieldlabel={fieldLabel}
      required={required}
      requiredFieldColor={requiredFieldColor}>
      <FormControl sx={{ width: '100%', padding: 0, borderColor: theme.palette.error.main }}>
        <InputLabel id="demo-multiple-chip-label" placeholder={placeholder} />

        <Autocomplete
          //labelId="demo-multiple-chip-label"
          id={id}
          data-testid={id}
          multiple
          disableCloseOnSelect
          limitTags={options.length}
          size="small"
          options={options}
          value={selectedValues}
          onChange={(_event, value) => {
            handleChange(value);
          }}
          renderInput={(params) => (
            <TextField
              {...params}
              size="small"
              placeholder={selectedValues.length > 0 ? '' : placeholder}
              error={error}
              helperText={error ? <ROHelperText {...helperTextProps} /> : ''}
            />
          )}
          renderOption={(props, option, { selected }) => {
            const { key, ...optionProps } = props as { key: string };
            return (
              <li key={key} {...optionProps} style={{ padding: '0px', margin: '0px' }}>
                <Checkbox icon={icon} checkedIcon={checkedIcon} style={{ marginRight: 8 }} checked={selected} />
                {option}
              </li>
            );
          }}
          sx={{
            fontWeight: 600,
            fontSize: '14px',
            maxWidth: '400px',
            minWidth: '250px',
            '& .MuiOutlinedInput-root.MuiInputBase-sizeSmall .MuiAutocomplete-input': {
              padding: '2.5px 4px 2.5px 0px',
            },
            '& .MuiOutlinedInput-root.Mui-disabled': {
              backgroundColor: theme.palette.grey[100],
            },
            '& .MuiOutlinedInput-input.Mui-disabled': {
              WebkitTextFillColor: theme.palette.text.primary,
            },
            '.MuiInputBase-root': {
              '&:hover': {
                backgroundColor: theme.palette.grey[100],
              },
            },
            ':hover .MuiOutlinedInput-notchedOutline': {
              borderColor,
            },
            'input[type="number"]::-webkit-inner-spin-button': {
              '-webkit-appearance': 'none',
              margin: 0,
            },
            'input[type="number"]::-webkit-outer-spin-button': {
              '-webkit-appearance': 'none',
              margin: 0,
            },
            '.MuiFormHelperText-root': {
              marginLeft: 0,
            },
            '& .MuiChip-label': {
              height: '24px',
            },
            '& .MuiChip-deleteIcon': {
              color: theme.palette.primary.dark,
              width: '16px',
              height: '16px',
              '&:hover': {
                color: theme.palette.primary.light,
              },
            },
          }}
        />
      </FormControl>
    </FormRow>
  );
};

export default ChipMultiSelect;
