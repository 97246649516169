import * as yup from 'yup';
import {
  valueRequired,
  positiveNumber,
  displayTextFieldOptionsForOther,
  displayTextFieldOptionsForAllergy,
} from './constants';

export const generateValidationSchema = (patientState: string | undefined, validationSchema: any): any => {
  if (patientState !== 'WA') {
    delete validationSchema.fields['outsideWaLastTwelveMonths'];
    return validationSchema;
  }
  return validationSchema;
};

export const validationSchema = yup.object({
  patientIdentificationCheck: yup.bool().typeError(valueRequired),
  patientHeight: yup.number().nullable().positive(positiveNumber).required(valueRequired),
  patientWeight: yup.number().nullable().positive(positiveNumber).required(valueRequired),
  previousRadiationTreatment: yup.bool().typeError(valueRequired),
  anticancerDrugs: yup.bool().typeError(valueRequired),
  chemoPastWeek: yup.bool().typeError(valueRequired),
  hasImplantedMedicalDevice: yup.bool().typeError(valueRequired),
  hasInfectiousDisease: yup.bool().typeError(valueRequired),
  hasMroInfection: yup.bool().typeError(valueRequired),
  outsideWaLastTwelveMonths: yup.bool().typeError(valueRequired),
  infectiousDisease: yup.array().of(
    yup.object().shape({
      id: yup.string().required(valueRequired),
      baseValue: yup.string().required(valueRequired),
      otherValue: yup.string().when('baseValue', {
        is: (val: string) => displayTextFieldOptionsForOther.includes(val),
        then: yup.string().required(valueRequired),
        otherwise: yup.string(),
      }),
    }),
  ),
  mroInfection: yup.array().of(
    yup.object().shape({
      id: yup.string().required(),
      baseValue: yup.string().required(valueRequired),
      otherValue: yup.string().when('baseValue', {
        is: (val: string) => displayTextFieldOptionsForOther.includes(val),
        then: yup.string().required(valueRequired),
        otherwise: yup.string(),
      }),
    }),
  ),
  fallLastTwelveMonths: yup.bool().typeError(valueRequired),
  safetyConcernsMobility: yup.bool().typeError(valueRequired),
  hasCurrentAcd: yup.bool().typeError(valueRequired),
  acdInfoRequested: yup.bool().when('hasCurrentAcd', {
    is: false,
    then: yup.bool().typeError(valueRequired),
    otherwise: yup.bool().nullable(),
  }),
  hasCurrentResusMxPlan: yup.bool().typeError(valueRequired),
  staffConcern: yup.bool().typeError(valueRequired),
  anxietyClaustrophobia: yup.bool().typeError(valueRequired),
  treatmentOverHalfHour: yup.bool().typeError(valueRequired),
  currentInpatient: yup.bool().typeError(valueRequired),
  requiresMaskCast: yup.bool().typeError(valueRequired),
  existingPressureInjuryWound: yup.bool().typeError(valueRequired),
  nurseLocation: yup.string().nullable().required(valueRequired),
  hasPatientAllergies: yup.bool().typeError(valueRequired),
  pcccAllergy: yup.array().of(
    yup.object().shape({
      allergyType: yup.string().required(valueRequired),
      additionalDetails: yup.string().when('allergyType', {
        is: (val: string) => displayTextFieldOptionsForAllergy.includes(val),
        then: yup.string().required(valueRequired),
        otherwise: yup.string(),
      }),
      pcccReaction: yup.array().of(
        yup.object().shape({
          baseValue: yup.string().required(valueRequired),
          otherValue: yup.string().when('baseValue', {
            is: (val: string) => displayTextFieldOptionsForOther.includes(val),
            then: yup.string().required(valueRequired),
            otherwise: yup.string(),
          }),
        }),
      ),
    }),
  ),
});
