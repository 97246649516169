// eslint-disable-next-line no-use-before-define
import React from 'react';
import { useQuery } from '@apollo/client';
import { useRouteMatch } from 'react-router';
import { gql } from '@apollo/client';
import PatientCareplans from './PatientCareplans';
import PatientCard from 'op-components/PatientCard/PatientCard';
import { ROPatientContextProvider } from 'op-pages/RO/PatientSummary/context';
import SidePanel from './SidePanel';
import { styled } from '@mui/system';
import { Grid, Drawer, useTheme } from '@mui/material';
import PatientBanner from './PatientBanner';

const GET_PATIENT_INFO = gql`
  query patient($id: ID!) {
    patient(id: $id) {
      id
    }
  }
`;

const StyledSidePatientNav = styled(SidePanel)`
  max-width: 240px;
  width: 240px;
  height: 100vh;
  background: ${(props) => props.theme.palette.grey[100]};
`;

const RootDiv = styled('div')`
  display: flex;
`;
const PatientSummary = (): JSX.Element => {
  const match = useRouteMatch<any>();
  const { id: patientId } = match.params;
  const theme = useTheme();
  useQuery(GET_PATIENT_INFO, {
    variables: { id: patientId },
    skip: !patientId,
  });
  return (
    <>
      <ROPatientContextProvider>
        <RootDiv id="patient-summary-primary">
          <Drawer variant="permanent" style={{ width: 240, flexShrink: 0 }} anchor="left">
            <StyledSidePatientNav />
          </Drawer>
          <Grid
            container
            item
            xs={12}
            md={12}
            style={{ backgroundColor: theme.palette.grey[100], width: 'calc(100vw - 240px)' }}>
            <Grid item xs={12} md={12}>
              <div style={{ backgroundColor: 'white' }}>
                <PatientCard id={patientId} />
              </div>
              <PatientBanner />
              <div style={{ height: 'calc(100vh - 50px)', display: 'flex' }}>
                <PatientCareplans id={patientId} />
              </div>
            </Grid>
          </Grid>
        </RootDiv>
      </ROPatientContextProvider>
    </>
  );
};

export default PatientSummary;
