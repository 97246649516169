export class DTBase {
  id: string;

  public constructor() {
    this.id = '';
  }
}

export class DTRatingItem extends DTBase {
  public distressRating: number;
  public version: string;

  public constructor() {
    super();
    this.distressRating = -1;
    this.version = '';
  }

  public loadData = (object: any): DTRatingItem => {
    this.id = object.id;
    this.distressRating = Number(object.distressRating);
    this.version = object.version;
    return this;
  };
}

export class DTOtherProblemsItem extends DTBase {
  public otherProblems: string;

  public constructor() {
    super();
    this.otherProblems = '';
  }

  public loadData = (object: any): DTOtherProblemsItem => {
    this.id = object.id;
    this.otherProblems = String(object.otherProblems);
    return this;
  };
}

export class DTAnswerItem {
  id: string;
  question: string;
  value: boolean;

  public constructor() {
    this.id = '';
    this.question = '';
    this.value = false;
  }
}
