import React from 'react';
import { Grid } from '@mui/material';
import { Form, Formik, Field } from 'formik';
import { FormContainer, NumberInput, Output } from './ModalComponents';
import * as Yup from 'yup';
import { useRouteMatch } from 'react-router-dom';
import { useMutation } from '@apollo/client';
import { ADD_CYCLES_TO_PROTOCOL } from '../Queries';
import { StandardDialog } from 'shared-components/components';

interface AddCyclesModalProps {
  open: boolean;
  protocolName: string;
  onClose: () => void;
  currentNumberOfCycles: number;
}

const validationSchema = Yup.object().shape({
  numberOfCycles: Yup.number().min(1, 'This value must be more than 1').required('This field is required'),
});

const AddCyclesModal = ({ open, protocolName, onClose, currentNumberOfCycles }: AddCyclesModalProps): JSX.Element => {
  const match = useRouteMatch<{ careplanId: string }>();
  const { careplanId } = match.params;
  const [addCyclesToProtocol] = useMutation(ADD_CYCLES_TO_PROTOCOL, {
    refetchQueries: ['moCareplanData', 'latestCycles', 'moAdditionalInfo'],
  });

  const initialValues = { numberOfCycles: 1 };
  const handleSubmit = (numberOfCycles: number, resetForm: () => void) => {
    addCyclesToProtocol({ variables: { careplanId: careplanId, numberOfCycles: numberOfCycles } }).then(() => {
      onClose();
      resetForm();
    });
  };
  const busy = false;
  return (
    <Formik
      initialValues={initialValues}
      onSubmit={(values, { resetForm }) => {
        handleSubmit(values.numberOfCycles, resetForm);
      }}
      validationSchema={validationSchema}
      validateOnChange={false}
      validateOnBlur={true}>
      {({ submitForm, values, resetForm }) => (
        <StandardDialog
          maxWidth="lg"
          fullWidth
          open={open}
          title="Add cycles"
          onClose={() => {
            resetForm();
            onClose();
          }}
          onSubmit={submitForm}
          submitText="Add">
          <Form>
            <FormContainer>
              <Field component={NumberInput} label="How many cycles?*" name="numberOfCycles" />
              <Output
                dataCy="new-total-cycles"
                label="New cycle count"
                value={currentNumberOfCycles + values.numberOfCycles}
              />
              <Grid item container alignItems="center">
                <Grid item xs={12}>
                  Please confirm that you would like to add new cylces to the protocol <b>{protocolName}</b>
                </Grid>
              </Grid>
            </FormContainer>
          </Form>
        </StandardDialog>
      )}
    </Formik>
  );
};

export default AddCyclesModal;
