import { datadogRum } from '@datadog/browser-rum';
import { createHash } from 'crypto';
import { useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';

const MAX_RETRIES = 3;

const fetchUserInfo = async (retries = 0): Promise<string> => {
  const url = `${window.location.origin}/server/get_user`;
  try {
    const res = await fetch(url);

    if (!res.ok) {
      throw new Error(`Network response was not ok: ${res.status}`);
    }
    const json: { error: string; state: string; role: string; region: string; userId: string } = await res.json();
    const { error, state, role, region, userId } = json;

    if (error) {
      // try again
      throw new Error('Error fetching user info: ' + error);
    }

    datadogRum.setUser({
      id: userId === 'Anonymous' ? 'Anonymous' : createHash('md5').update(`${userId}`).digest('hex'),
      state,
      role,
      region,
    });
  } catch (err: any) {
    if (retries < MAX_RETRIES) {
      setTimeout(() => fetchUserInfo(retries + 1), 1000);
    } else {
      datadogRum.setUser({
        id: 'Unknown',
        state: 'Unknown',
        role: 'Unknown',
        region: 'Unknown',
      });
    }
  }
  return datadogRum.getUser().id as string;
};

const DatadogUserInfo = (): JSX.Element => {
  const location = useLocation();
  const [userIdHash, setUserIdHash] = useState('');

  useEffect(() => {
    // try on mount
    // it might return `Unknown` (patients who aren't logged in)
    if (datadogRum.getInitConfiguration()) {
      fetchUserInfo().then(setUserIdHash);
    }
  }, []);

  useEffect(() => {
    // try again on route change if the user wasn't logged in
    if (datadogRum.getInitConfiguration() && (userIdHash === 'Unknown' || userIdHash === 'Anonymous')) {
      // blank it first to avoid spamming the server if the user is fast enough
      setUserIdHash('');
      fetchUserInfo().then(setUserIdHash);
    }
  }, [location]);

  return <></>;
};

export default DatadogUserInfo;
