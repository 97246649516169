import React from 'react';
import { styled } from '@mui/system';
import { TextField as MUITextField } from '@mui/material';

interface Props {
  name: string;
  value: string;
  invalid?: boolean;
  units?: string;
  width?: string;
  multiline?: boolean;
  rows?: number;
  onBlur?: (arg0: any) => void;
  onChange?: (arg0: any) => void;
  onFocus?: (arg0: any) => void;
  type: string;
  placeHolder?: string;
  maxLength?: number;
}

interface IStyledInput {
  invalid?: boolean;
  width?: string;
}

const StyledInput = styled(MUITextField)<IStyledInput>`
  && {
    justify-content: center;
    text-align: center;
    fieldset {
      border: 1px solid ${(props) => (props.invalid ? props.theme.palette.error.main : props.theme.palette.grey[300])};
      border-radius: 4px;
    }
    width: ${(props: IStyledInput): string => (props.width ? props.width : 'auto')};
    input[type='number']::-webkit-inner-spin-button,
    input[type='number']::-webkit-outer-spin-button {
      -webkit-appearance: none;
      margin: 0;
    }
    input {
      height: 40px;
      &.MuiInputBase-input {
        padding: 8px;
      }
    }
  }
  .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline {
    border-color: ${(props) => props.theme.palette.primary.main};
  }
  .MuiOutlinedInput-root:hover .MuiOutlinedInput-notchedOutline {
    border-color: ${(props) => props.theme.palette.primary.main};
  }
`;

const InputBox = styled('div')`
  display: flex;
  flex-direction: row;
`;

const Unit = styled('div')`
  text-transform: uppercase;
  color: ${(props) => props.theme.palette.text.primary};
  font-weight: bold;
  font-size: 0.8125rem;
  margin: auto 9px;
`;

const Input = (props: Props): JSX.Element => {
  return (
    <InputBox>
      <StyledInput
        name={props.name}
        value={props.value}
        invalid={props.invalid}
        width={props.width}
        multiline={props.multiline}
        rows={props.rows}
        onBlur={props.onBlur}
        onChange={props.onChange}
        variant="outlined"
        size="medium"
        type={props.type}
        placeholder={props.placeHolder}
        onFocus={props.onFocus}
        inputProps={{ maxLength: props.maxLength }}
      />
      <Unit>{props.units}</Unit>
    </InputBox>
  );
};

export default Input;
