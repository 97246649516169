import { gql } from '@apollo/client';

export const CREATE_OAR = gql`
  mutation createOar($siteGroupId: ID!, $organName: String, $constraint: String) {
    createOar(siteGroupId: $siteGroupId, organName: $organName, constraint: $constraint) {
      success
      oar {
        id
        constraint
        organ {
          id
          organ
        }
      }
    }
  }
`;

export const UPDATE_MANUAL_OARS = gql`
  mutation updateManualOars($siteGroupId: ID!, $templateId: ID!) {
    updateManualOars(siteGroupId: $siteGroupId, templateId: $templateId) {
      success
    }
  }
`;

export const UPDATE_OAR = gql`
  mutation updateOar($oarId: ID!, $constraint: String, $organName: String) {
    updateOar(oarId: $oarId, constraint: $constraint, organ: $organName) {
      oar {
        id
        constraint
        organ {
          id
          organ
        }
      }
    }
  }
`;

export const DELETE_OAR = gql`
  mutation deleteOar($oarId: ID!) {
    deleteOar(oarId: $oarId) {
      success
    }
  }
`;

export const DELETE_ALL_OAR = gql`
  mutation deleteAllOrganAtRisk($id: ID!) {
    deleteAllOrganAtRisk(id: $id) {
      ok
    }
  }
`;

export const GET_VOLUMING_OAR_DATA = gql`
  query roOrganAtRisk($siteGroupId: ID!) {
    roOrganAtRisk(siteGroupId: $siteGroupId) {
      organ
      volumeConstraintData {
        firstValue
        operator
        secondValue
        isVolumeData
      }
      dConstraintData {
        firstValue
        operator
        secondValue
      }
      maxValue
      maxValueUnit
      meanValue
      meanValueUnit
      alara
    }
  }
`;

export const RESET_VOLUMING_DATA = gql`
  mutation resetVolumingData($siteGroupId: ID!) {
    resetVolumingData(siteGroupId: $siteGroupId) {
      success
    }
  }
`;
