// eslint-disable-next-line no-use-before-define
import React, { Component, Fragment } from 'react';
import classNames from 'classnames';

import './HelperText.scss';

export enum HelperTextIconType {
  INFO,
  WARNING,
  ERROR,
}

interface Props {
  helperText: string;
  idPrefix?: string;
  icon?: boolean;
  iconType?: HelperTextIconType;
  children?: React.ReactNode;
}

class HelperText extends Component<Props> {
  public static defaultProps = {
    icon: false,
  };

  public render() {
    const { icon, helperText, idPrefix } = this.props;

    return (
      <Fragment>
        {this.props.children}
        <div
          id={idPrefix ? `${idPrefix}-helper-text` : undefined}
          className={classNames('helper-text', { 'with-icon': icon })}>
          {icon && this.getIcon()}
          <span>{helperText}</span>
        </div>
      </Fragment>
    );
  }

  private getIcon = (): JSX.Element => {
    const { iconType } = this.props;

    switch (iconType) {
      case HelperTextIconType.INFO:
        return <div>INFO</div>;
      case HelperTextIconType.WARNING:
        return <Fragment />;
      case HelperTextIconType.ERROR:
        return <Fragment />;
      default:
        return <Fragment />;
    }
  };
}

export default HelperText;
