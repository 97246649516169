// eslint-disable-next-line no-use-before-define
import React, { Component } from 'react';
import { Query } from '@apollo/client/react/components';

import NavigationDropDown from './NavigationDropDown';
import { PATIENT_QUERY_GQL } from '../../graphql';

interface PatientResult {
  patient: {
    firstName: string;
    lastName: string;
  };
}

class NavigationDropDownApollo extends Component {
  public render(): JSX.Element {
    return (
      <Query<PatientResult> query={PATIENT_QUERY_GQL}>
        {({ loading, data }): JSX.Element => {
          if (loading) {
            return <></>;
          }
          let firstName = 'Loading...';
          let lastName = '';
          if (data && data.patient && data.patient.firstName && data.patient.lastName) {
            firstName = data.patient.firstName;
            lastName = data.patient.lastName;
          }

          return <NavigationDropDown firstName={firstName} lastName={lastName} />;
        }}
      </Query>
    );
  }
}

export default NavigationDropDownApollo;
