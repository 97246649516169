import React from 'react';
import { useFormikContext, Field, FormikProps } from 'formik';

import { HelperMessage, FormRow } from 'shared-components/components';
import { yesNoOptions } from 'op-utils';
import { ToggleButtonGroupField } from 'shared-components/components/FormikComponents';

import { getLastUpdateUser } from '../../helpers';
import { LastUpdateUserDetails } from '../../shared/interfaces';
import { content } from '../constants';
import { PreCtTriageData } from '../interfaces/PreCtTriageInterfaces';
interface Props {
  handleMutation: (name: string, value: string) => void;
  lastUpdateUserData: LastUpdateUserDetails[];
}

const CognitiveImpairmentRisk = ({ handleMutation, lastUpdateUserData }: Props): JSX.Element => {
  const { values }: FormikProps<PreCtTriageData> = useFormikContext();
  const { hasAlteredCognitiveState, isAlertAndOrientated } = content.cognitiveImpairmentRisk.fields;
  const { isAlertAndOrientatedQCL, hasAlteredCognitiveStateQCL } = content.cognitiveImpairmentRisk.infoHelpers;

  return (
    <>
      <FormRow fieldLabel={isAlertAndOrientated} fieldName={'isAlertAndOrientated'}>
        <Field
          name="isAlertAndOrientated"
          component={ToggleButtonGroupField}
          label={isAlertAndOrientated}
          options={yesNoOptions}
          handleMutation={handleMutation}
          lastUpdateUser={getLastUpdateUser(lastUpdateUserData, 'isAlertAndOrientated')}
        />
        {values.isAlertAndOrientated === false && (
          <HelperMessage fieldName={isAlertAndOrientated} fieldText={isAlertAndOrientatedQCL} helperType="info" />
        )}
      </FormRow>
      <FormRow fieldLabel={hasAlteredCognitiveState} fieldName={'hasAlteredCognitiveState'}>
        <Field
          name="hasAlteredCognitiveState"
          component={ToggleButtonGroupField}
          label={hasAlteredCognitiveState}
          options={yesNoOptions}
          handleMutation={handleMutation}
          lastUpdateUser={getLastUpdateUser(lastUpdateUserData, 'hasAlteredCognitiveState')}
        />
        {values.hasAlteredCognitiveState === true && (
          <>
            <HelperMessage
              fieldName={hasAlteredCognitiveState}
              fieldText={hasAlteredCognitiveStateQCL}
              helperType="info"
            />
          </>
        )}
      </FormRow>
    </>
  );
};

export default CognitiveImpairmentRisk;
