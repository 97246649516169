import { NurseReviewedIcon } from 'shared-components/images';
import { SECTIONS } from './routes';
import ListData from 'shared-components/interfaces/ListData';
import { PersonOutlined as PersonOutlinedIcon } from '@mui/icons-material';
import React from 'react';
import { ReviewInfo } from 'shared-components/components/UIFormComponents/ReviewBanner';

export const TRIAGE = 'triage';

export const mapValueToRefData = (value: string, refData: ListData[]): string => {
  const filtered = refData.filter((item: ListData) => item.id === value);
  return filtered.length ? filtered[0].name : '-';
};

export const painScoreOptions = [...Array(10).keys()].map((num) => {
  const val = (num + 1).toString();
  return { label: val, value: val };
});

export const OWN_TRANSPORT = 'Own Transport';
export const RED_CROSS = 'Red Cross';
export const STRETCHER_AMBULANCE = 'Stretcher Ambulance';
export const WALKER_AMBULANCE = 'Walker Ambulance';
export const DVA_TRANSPORT = 'DVA Transport';
export const GENESIS_BUS = 'Genesis Bus';
export const CANCER_COUNCIL_BUS = 'Cancer Council Bus';

export const transportOptions = [
  OWN_TRANSPORT,
  RED_CROSS,
  STRETCHER_AMBULANCE,
  WALKER_AMBULANCE,
  DVA_TRANSPORT,
  GENESIS_BUS,
  CANCER_COUNCIL_BUS,
];
export const QLDTransportOptions = [GENESIS_BUS, CANCER_COUNCIL_BUS];

const PFC_DONE = 'PFC Done';
const BOOK_PFC = 'Book PFC';
const BOOKED_PFC = 'Booked PFC';
const REFUSED = 'Refused';
const NOT_APPROPRIATE = 'Not appropriate per RO';
const NA = 'N/A';

export const pelvicFitnessOptions = [
  { label: PFC_DONE, value: PFC_DONE },
  { label: BOOK_PFC, value: BOOK_PFC },
  { label: BOOKED_PFC, value: BOOKED_PFC },
  { label: REFUSED, value: REFUSED },
  { label: NOT_APPROPRIATE, value: NOT_APPROPRIATE },
  { label: NA, value: NA },
];

const PART_OF_TRIAGE_CALL = 'Identified as part of Triage call';
const REVIEW_PRIOR_TO_CT = 'Identified and addressed at F2F review prior to CT';

export const factorsIdentifiedOptions = [
  { label: PART_OF_TRIAGE_CALL, value: PART_OF_TRIAGE_CALL },
  {
    label: REVIEW_PRIOR_TO_CT,
    value: REVIEW_PRIOR_TO_CT,
  },
];

export const content = {
  malnutritionRisk: {
    fields: {
      nurseLocation: 'QCL Location*', // mandatory field
      unintentionalWeightLoss: 'Recent Unintentional Weight Loss',
      hasDecreasedAppetite: 'Decreased Appetite',
    },
    infoHelpers: {
      malnutritionScreeningToolQCL: 'Selecting "Yes" will create QCL: Malnutrition Screening Tool',
    },
    integrationQCL: {
      unintentionalWeightLoss: 'Malnutrition Screening Tool',
      hasDecreasedAppetite: 'Malnutrition Screening Tool',
    },
  },
  cognitiveImpairmentRisk: {
    fields: {
      isAlertAndOrientated: 'Alert and Orientated',
      hasAlteredCognitiveState: 'Altered Cognitive State',
    },
    infoHelpers: {
      isAlertAndOrientatedQCL: 'Selecting "No" will create QCLs: Cognitive Assessment and Falls Risk Assessment',
      hasAlteredCognitiveStateQCL: 'Selecting "Yes" will create QCLs: Cognitive Assessment and Falls Risk Assessment',
    },
    integrationQCL: {
      isAlertAndOrientated: 'Falls Risk Assessment|Cognitive Assessment',
      hasAlteredCognitiveState: 'Falls Risk Assessment|Cognitive Assessment',
    },
  },
  pain: {
    fields: {
      hasCurrentPain: 'Is the patient currently in pain?',
      painScore: 'Pain Score',
      isAbleToLieFlat: 'Is the patient able to lie flat?',
      isAbleToClimbStairs: 'Is the patient able to climb stairs?',
    },
    infoHelpers: {
      painAssessmentQCL: 'Selecting a value of 4 and above will create QCL: PQRST Pain Assessment',
      significantPMHx: 'If significant PMHx - Please document in Additional Notes',
    },
    tableTitles: {
      areYouInPain: 'Are you currently in pain?',
      pleaseSpecify: 'Please specify',
    },
    integrationQCL: {
      painScore: 'PQRST Pain Assessment',
    },
  },

  transportMode: {
    fields: {
      transportMode: 'Transport Mode',
      otherTransport: 'Other modes of transport',
    },
    infoHelpers: {
      cancerCouncilQCL: 'Selecting Cancer Council Bus will create QCL: Book Cancer Council Bus',
      DVAQCL: 'Selecting DVA Transport will create QCL: Book DVA Car',
      genesisBusQCL: 'Selecting Genesis Bus will create QCL: Assess Eligibility for Genesis Bus',
    },
    tableTitles: {
      doYouNeedTransport: 'Do you require transportation assistance?',
      assistanceReason: 'Reason assistance required',
      ambTitle: 'Do you have an ambulance membership?',
      ambNumberTitle: 'Ambulance membership number',
    },
    integrationQCL: {
      dvaTransport: 'Book DVA Car',
      genesisBus: 'Assess Eligibility for Genesis Bus',
      cancerCouncilBus: 'Book Cancer Council Bus',
    },
  },
  socialCircumstances: {
    fields: {
      socialConsiderations: 'Social Considerations',
    },

    livingSituation: {
      fields: {
        livesAlone: 'Does the patient live alone?',
        livesWith: 'Who do they live with?',
        isCarer: 'Are they a carer for anyone?',
        carerSpecification: 'Please specify',
        usualResidence: 'Type of usual residence',
        usualResidenceSpecify: 'Please specify',
        staysAtUsualResidence: 'Is patient staying at usual residence for treatment?',
        otherResidence: 'Where will they be staying and is any follow up required?',
      },
    },

    careRequirements: {
      fields: {
        needsAssistanceAdls: 'Assistance with ADLs required?',
        assistance: 'Select all that apply',
        assistanceSpecify: 'Please specify',
        hasFamilyOrFriend: 'Do they have family or friends who can provide assistance if required?',
        familyOrFriendSpecification: 'Please specify',
        existingSupportService: 'Do they have existing support services currently in place?',
        existingSupportSpecification: 'Please specify',
        hasSensoryImpairment: 'Do they have any sensory impairments e.g. vision, hearing, circulation?',
        sensoryImpairments: 'Select all that apply',
        sensoryImpairmentsSpecification: 'Please specify',
      },
      infoHelpers: {
        vision: 'Selecting "Vision" will create Alert: Visually Impaired',
        hearing: 'Selecting "Hearing" will create Alert: Hearing Impaired',
      },
      integrationQCL: {
        vision: 'visually impaired',
        hearing: 'hearing impaired',
      },
    },

    socialMedicalHistory: {
      fields: {
        englishSecondLanguage: 'Is English a second language for patient? ',
        primaryLanguage: 'Primary language',
        requireInterpreter: 'Do they require an interpreter?',
        otherInformation: 'Other information (as required)',
      },
      infoHelpers: {
        requireInterpreter: 'Selecting "Yes" will create Alert: Interpreter',
      },
      integrationQCL: {
        requireInterpreter: 'interpreter',
      },
    },

    tableTitles: {
      // HA
      doYouDrink: 'Do you drink alcohol?',
      drinkingFrequency: 'How frequently do you drink?',
      doYouSmoke: 'Do you or have you ever smoked?',
      yearsSmoking: 'Smoking history (Years)',
      howFrequentlySmoking: 'How frequently do/did you smoke?',
      whenStoppedSmoking: 'Date stopped smoking',
      assistAccommodation: 'Do you require accommodation assistance?',
      diabeticTitle: 'Diabetic',
      ulcerTitle: 'Existing wound/ulcer',
      // rego
      occupationTitle: 'Occupation',
      heritageTitle: 'ATSI status',
    },
  },

  wellbeingScreen: {
    fields: {
      hasPatientCarerConcern: 'Patient/Carer Concern',
    },
    helperBox: {
      heading: 'Please document details in "additional notes"; the following may be used to guide your discussion',
      questions: ['How have you been managing since your diagnosis?', 'What support mechanisms do you have in place?'],
    },
    tableTitles: {
      doYouHaveWellbeingIssues: 'Do you have any wellbeing or mental health issues?',
      distressThermometerScore: 'Distress Thermometer Score',
    },
    integrationQCL: {
      requiresWellbeingMxPlan: 'Wellbeing Mx Plan',
    },
  },
  summary: {
    fields: {
      pelvicFitness: 'Pelvic Fitness',
      hasHighRiskFactors: 'High Risk Factors Identified',
      howFactorsWereIdentified: 'How Were Those Factors Identified',
    },
    infoHelpers: {
      lowRisk: 'Low Risk Patient',
      proceedToRT: 'Proceed with CHO to RT',
      ensureReview: 'Ensure F2F review booked prior to CT Sim',
    },
    tableValues: {
      chartCheckTable: {
        previousRadiationTreatment: {
          page: 'Basic Information',
          title: '',
          value: 'Previous Radiation Treatment',
        },
        anticancerDrugs: { page: 'Chemotherapy Risks', title: '', value: 'Concurrent anticancer drugs' },
        chemoPastWeek: { page: 'Chemotherapy Risks', title: '', value: 'Chemotherapy in the last 7 days' },
        cied: {
          page: 'Implanted medical devices',
          title: 'Cardiac implanted electronic device (CIED)',
        },
        venousAccessType: {
          page: 'Implanted medical devices',
          title: 'Venous Access Type',
        },
        otherDevices: {
          page: 'Implanted medical devices',
          title: 'Other (if not listed above)',
        },
        infectiousDisease: {
          page: 'Infectious Risk',
          title: 'Infectious Disease',
        },
        outsideWaLastTwelveMonths: {
          page: 'Infectious Risk',
          title: 'Inpatient outside WA in last 12 months',
        },
        mroInfection: {
          page: 'Infectious Risk',
          title: 'MRO infection',
        },
        fallLastTwelveMonths: {
          page: 'Falls Risk',
          title: '',
          value: 'Fall in last 12 months',
        },
        safetyConcernsMobility: {
          page: 'Falls Risk',
          title: '',
          value: 'Safety concerns with mobility',
        },
        treatmentOverHalfHour: {
          page: 'Pressure Injury',
          title: '',
          value: 'Treatment length >30 minutes',
        },
        currentInpatient: {
          page: 'Pressure Injury',
          title: '',
          value: 'Current Inpatient',
        },
        requiresMaskCast: {
          page: 'Pressure Injury',
          title: '',
          value: 'Requires mask or cast',
        },
        existingPressureInjuryWound: {
          page: 'Pressure Injury',
          title: '',
          value: 'Existing pressure injury/wound',
        },
        staffConcern: {
          page: 'Wellbeing',
          title: '',
          value: 'Staff concern',
        },
        anxietyClaustrophobia: {
          page: 'Wellbeing',
          title: '',
          value: 'Anxiety/claustrophobia',
        },
        hasCurrentAcd: {
          page: 'Advanced Care Directive/NFR',
          title: '',
          value: 'Current advanced care directive',
        },
        hasCurrentResusMxPlan: {
          page: 'Advanced Care Directive/NFR',
          title: '',
          value: 'Current resus MX plan/NFR',
        },
        allergy: {
          page: 'Allergies',
          title: 'Allergy/sensitivity',
        },
      },
      triageTable: {
        unintentionalWeightLoss: {
          page: 'Malnutrition Risk',
          title: '',
          value: 'Unintentional weight loss',
        },
        hasDecreasedAppetite: {
          page: 'Malnutrition Risk',
          title: '',
          value: 'Decreased appetite',
        },
        isAlertAndOrientated: {
          page: 'Cognitive Impairment Risk',
          title: '',
          value: 'Not alert and orientated',
        },
        hasAlteredCognitiveState: {
          page: 'Cognitive Impairment Risk',
          title: '',
          value: 'Altered cognitive state',
        },
        hasCurrentPain: {
          page: 'Pain',
          title: '',
          value: 'Has current pain',
        },
        painScore: {
          page: 'Pain',
          title: 'Pain score',
        },
        isAbleToLieFlat: {
          page: 'Pain',
          title: '',
          value: 'Patient is unable to lie flat',
        },
        isAbleToClimbStairs: {
          page: 'Pain',
          title: '',
          value: 'Patient is unable to climb stairs',
        },
        transport: {
          page: 'Transport',
        },
        hasPatientCarerConcern: {
          page: 'Wellbeing',
          title: '',
          value: 'Patient/carer concern',
        },
        hasSensoryImpairment: {
          page: 'Social Circumstances',
          title: '',
          value: 'Has sensory impairment',
        },
        sensoryImpairments: {
          page: 'Social Circumstances',
          title: 'Sensory impairments',
        },
        englishSecondLanguage: {
          page: 'Social Circumstances',
          title: '',
          value: 'English is second language',
        },
        requireInterpreter: {
          page: 'Social Circumstances',
          title: '',
          value: 'Interpreter required',
        },
      },
      noChartCheckMessage:
        'Initial Chart Check is still in progress. Please complete the assessment to identify any risks.',
      emptyMessage: 'No risks identified from Initial Chart Check and Initial Triage',
    },
  },
};

export const malnutritionFields = Object.keys(content.malnutritionRisk.fields);
export const cognitiveFields = Object.keys(content.cognitiveImpairmentRisk.fields);
export const painFields = Object.keys(content.pain.fields);
export const transportFields = Object.keys(content.transportMode.fields);
export const socialFields = Object.keys(content.socialCircumstances.fields);
export const wellbeingTriageFields = Object.keys(content.wellbeingScreen.fields);
export const summaryFields = Object.keys(content.summary.fields);

export const stepperTitle = 'INITIAL TRIAGE';

export const reviewInfo: ReviewInfo[] = [
  { icon: () => React.createElement(PersonOutlinedIcon, { color: 'info' }), label: 'Patient reported' },
  { icon: NurseReviewedIcon, label: 'Nurse reviewed and edited' },
];

export const selectValue = 'Please select';
export const valueRequired = 'This field is required';
export const positiveNumber = 'Number must be positive';
export const transportRequired = 'Please select or specify at least one transport mode';

export const fieldsToValidateOnContinue = {
  [SECTIONS.MALNUTRITION_RISK]: [...Object.keys(content.malnutritionRisk.fields)],
  [SECTIONS.COGNITIVE_IMPAIRMENT_RISK]: [...Object.keys(content.cognitiveImpairmentRisk.fields)],
  [SECTIONS.PAIN]: [...Object.keys(content.pain.fields)],
  [SECTIONS.TRANSPORT_MODE]: [...Object.keys(content.transportMode.fields)],
  [SECTIONS.SOCIAL_CIRCUMSTANCES]: [...Object.keys(content.socialCircumstances.fields)],
  [SECTIONS.WELLBEING_SCREEN]: [...Object.keys(content.wellbeingScreen.fields)],
  [SECTIONS.SUMMARY]: [...Object.keys(content.summary.fields)],
  [SECTIONS.REVIEW_AND_SUBMIT]: [],
};

export enum SensoryImpairments {
  vision = 'Vision',
  hearing = 'Hearing',
  circulation = 'Circulation',
}

export const sensoryImpairmentsOptions = [
  SensoryImpairments.vision,
  SensoryImpairments.hearing,
  SensoryImpairments.circulation,
];

export const assistanceOptions = [
  'Cooking',
  'Cleaning',
  'Dressing',
  'Eating',
  'Self care e.g. showering',
  'Shopping',
  'Household chores/gardening',
  'Medication or symptom management',
  'Other',
];
