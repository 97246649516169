export const AU_PAGE_LINKS = [
  {
    linkText: 'Patient information',
    href: '/info',
  },
  {
    linkText: 'Medical history: cancer',
    href: '/history-cancer',
  },
  {
    linkText: 'Medical history: illness',
    href: '/history-illness',
  },
  {
    linkText: 'Medical history: other',
    href: '/history-other',
  },
  {
    linkText: 'Operations',
    href: '/operations',
  },
  {
    linkText: 'Social assessment',
    href: '/social',
  },
  {
    linkText: 'Medications',
    href: '/medications',
  },
  {
    linkText: 'Allergies/ sensitivities',
    href: '/allergies',
  },
  {
    linkText: 'Other medical problems',
    href: '/other',
  },
  {
    linkText: 'Review and Submit',
    href: '/summary',
  },
];

export const US_PAGE_LINKS = [
  {
    linkText: 'Patient information',
    href: '/info',
  },
  {
    linkText: 'Medical history: 1',
    href: '/history-cancer',
  },
  {
    linkText: 'Medical history: 2',
    href: '/history-illness',
  },
  {
    linkText: 'Medical history: 3',
    href: '/biological',
  },
  {
    linkText: 'Operations',
    href: '/operations',
  },
  {
    linkText: 'Mobility-fall risk assessment',
    href: '/mobility',
  },
  {
    linkText: 'Pain assessment',
    href: '/painAssessment',
  },
  {
    linkText: 'Social assessment',
    href: '/social-us',
  },
  {
    linkText: 'Medications',
    href: '/medications',
  },
  {
    linkText: 'Allergies/ sensitivities',
    href: '/allergies',
  },
  {
    linkText: 'Patient consent',
    href: '/consent',
  },
  {
    linkText: 'Review and Submit',
    href: '/summary',
  },
];
