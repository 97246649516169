// eslint-disable-next-line no-use-before-define
import { Form, Formik, FormikProps, FormikValues } from 'formik';
import { FormContainer } from 'op-components';
import { RegistrationContext } from 'op-contexts';
import { CurrentAppConfig } from 'op-pages/RO/Careplan/AppConfig';
import { mapListData, yesNoOptions } from 'op-utils';
import React, { useContext } from 'react';
import { useHistory } from 'react-router-dom';
import { FormRow } from 'shared-components/components';
import { ListData } from 'shared-components/interfaces';
import {
  CONTINUE_BUTTON_TEXT,
  HeaderSubTitle,
  HeaderTitle,
  RETURN_TO_SUBMIT_TEXT,
  SecondaryTitle,
  StyledHorizontalLine,
  StyledIndentedSection,
  isFormValid,
  linkedField,
  registrationPath,
  sharedFormContainerProps,
  standardField,
} from '../Helper';
import { PatientEmergencyContact, PatientEmergencyContactFormik, RegistrationFormUSIProps } from '../interfaces';
import { ADDITIONAL_CONTACT_FIELDS, FIELDS } from './constants';
import { generateValidationSchema } from './validation';

interface EmergencyContactFormIProps extends RegistrationFormUSIProps {
  patient: PatientEmergencyContact;
  readonly refData: {
    readonly relationshipsRefData: ListData[];
  };
  updateField: (field: string, value: string | boolean | number, contactType?: string) => void;
}

const EmergencyContactForm = (props: EmergencyContactFormIProps): JSX.Element => {
  const {
    updateField,
    patient: savedPatient,
    handleShowSaveExitConfirm,
    refData: { relationshipsRefData },
  } = props;
  const registrationContext = useContext(RegistrationContext);
  const history = useHistory();
  const goToSummary = registrationContext?.registrationSummaryVisited;
  // Need to flatten out patient data for proper validation
  const patient = {
    altContactProvided: savedPatient.altContactProvided,
    nextOfKinContact: savedPatient.nextOfKinContact,
    ...savedPatient?.emergencyContact,
    id: savedPatient?.id,
  };

  const updateEmergencyContact = (field: string, value: string | boolean | number): void => {
    if (field === FIELDS.EMAIL.NAME && String(value).length > CurrentAppConfig.EmailMaxLength) return;
    updateField(field, value, 'C');
  };

  const updateAdditionalContact = (field: string, value: string | boolean | number): void => {
    if (field === ADDITIONAL_CONTACT_FIELDS.EMAIL.NAME && String(value).length > CurrentAppConfig.EmailMaxLength)
      return;
    const fieldName = field.split('.').pop() || '';
    return updateField(fieldName, value, 'N');
  };

  return (
    <Formik
      initialValues={patient}
      validate={(values: FormikValues) => generateValidationSchema(values)}
      validateOnBlur
      validateOnChange={true}
      onSubmit={() => history.push(registrationPath(patient?.id, goToSummary ? 'summary' : 'referrers'))}>
      {({
        errors,
        submitForm,
        setFieldValue,
        setFieldTouched,
        touched,
      }: FormikProps<PatientEmergencyContactFormik>) => {
        const sharedProps = sharedFormContainerProps(FIELDS.FORM_HEADING.TITLE, 4, '');
        const formContainerProps = {
          ...sharedProps,
          submitButtonText: goToSummary ? RETURN_TO_SUBMIT_TEXT : CONTINUE_BUTTON_TEXT,
          continueDisabled: isFormValid(errors, touched) || !!errors?.nextOfKinContact?.email,
          backLink: registrationPath(patient?.id, 'address'),
          handleShowSaveExitConfirm,
        };

        const updateAddAnotherContact = (field: string, value: string | boolean | number) => {
          if (value === false) {
            setFieldValue(ADDITIONAL_CONTACT_FIELDS.EMAIL.NAME, null, true);
            updateAdditionalContact(ADDITIONAL_CONTACT_FIELDS.EMAIL.NAME, '');
          } else {
            setFieldTouched(ADDITIONAL_CONTACT_FIELDS.EMAIL.NAME, false, false);
          }

          updateField(field, value);
        };

        return (
          <FormContainer
            {...formContainerProps}
            submitForm={submitForm}
            handleValidation={(event: React.MouseEvent<HTMLInputElement>): void => {
              void event;
            }}>
            <Form>
              <HeaderTitle data-test-id="rego-header-title">{FIELDS.FORM_HEADING.TITLE}</HeaderTitle>
              <HeaderSubTitle>* = required field</HeaderSubTitle>
              <SecondaryTitle>Emergency contact</SecondaryTitle>
              {standardField(FIELDS.FIRST_NAME, updateEmergencyContact)}
              {standardField(FIELDS.LAST_NAME, updateEmergencyContact)}
              {standardField(FIELDS.RELATIONSHIP, updateEmergencyContact, mapListData(relationshipsRefData))}
              {linkedField(FIELDS.MOBILE, FIELDS.HOME_PHONE, updateEmergencyContact)}
              <FormRow labelClass={'label-form-row'}>
                <StyledHorizontalLine>
                  <span>OR</span>
                </StyledHorizontalLine>
              </FormRow>
              {linkedField(FIELDS.HOME_PHONE, FIELDS.MOBILE, updateEmergencyContact)}
              {standardField(FIELDS.EMAIL, updateEmergencyContact)}
              {standardField(FIELDS.ENQUIRIES, updateEmergencyContact, yesNoOptions)}
              {standardField(FIELDS.CONTACT_PERSON, updateEmergencyContact, yesNoOptions)}
              <SecondaryTitle>Additional contact</SecondaryTitle>
              <HeaderSubTitle data-test-id="emergency-sub-text">
                If you have someone you would like to list in addition to your emergency contact, please provide their
                details here.
              </HeaderSubTitle>
              {standardField(FIELDS.ANOTHER_CONTACT, updateAddAnotherContact, yesNoOptions)}
              {patient.altContactProvided && (
                <StyledIndentedSection>
                  {standardField(ADDITIONAL_CONTACT_FIELDS.FIRST_NAME, updateAdditionalContact)}
                  {standardField(ADDITIONAL_CONTACT_FIELDS.LAST_NAME, updateAdditionalContact)}
                  {standardField(
                    ADDITIONAL_CONTACT_FIELDS.RELATIONSHIP,
                    updateAdditionalContact,
                    mapListData(relationshipsRefData),
                  )}
                  {linkedField(
                    ADDITIONAL_CONTACT_FIELDS.MOBILE,
                    ADDITIONAL_CONTACT_FIELDS.HOME_PHONE,
                    updateAdditionalContact,
                  )}
                  <FormRow labelClass={'label-form-row'}>
                    <StyledHorizontalLine>
                      <span>OR</span>
                    </StyledHorizontalLine>
                  </FormRow>
                  {linkedField(
                    ADDITIONAL_CONTACT_FIELDS.HOME_PHONE,
                    ADDITIONAL_CONTACT_FIELDS.MOBILE,
                    updateAdditionalContact,
                  )}
                  {standardField(ADDITIONAL_CONTACT_FIELDS.EMAIL, updateAdditionalContact)}
                  {standardField(ADDITIONAL_CONTACT_FIELDS.ENQUIRIES, updateAdditionalContact, yesNoOptions)}
                  {standardField(ADDITIONAL_CONTACT_FIELDS.CONTACT_PERSON, updateAdditionalContact, yesNoOptions)}
                </StyledIndentedSection>
              )}
            </Form>
          </FormContainer>
        );
      }}
    </Formik>
  );
};

export default EmergencyContactForm;
