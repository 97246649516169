import { gql } from '@apollo/client';
import { HA_PAIN_FRAGMENT } from 'op-graphql/fragments';

export const HA_PAIN_QUERY = gql`
  query HealthAssessment($patientId: ID!) {
    healthAssessment(id: $patientId) {
      id
      ...HealthAssessmentPain
    }
    painQualityRefData: listData(category: "pain_quality") {
      id
      name
      key: appKey
    }
  }
  ${HA_PAIN_FRAGMENT}
`;

export const UPDATE_HA_PAIN = gql`
  mutation UpdateHaPainAssessment(
    $haId: ID!
    $patientId: ID!
    $inPain: String
    $painStart: String
    $painSeverity: String
    $painLocation: String
    $painQuality: String
    $painLength: String
    $painManagement: String
    $painBetter: String
    $painWorse: String
  ) {
    updateHaPain(
      id: $haId
      patientId: $patientId
      inPain: $inPain
      painStart: $painStart
      painSeverity: $painSeverity
      painLocation: $painLocation
      painQuality: $painQuality
      painLength: $painLength
      painManagement: $painManagement
      painBetter: $painBetter
      painWorse: $painWorse
    ) {
      healthAssessment {
        id
        ...HealthAssessmentPain
      }
    }
  }
  ${HA_PAIN_FRAGMENT}
`;
