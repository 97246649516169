// eslint-disable-next-line no-use-before-define
import React from 'react';
import { styled } from '@mui/system';
import CircledCross from './CircledCross';

export interface DeleteProps {
  id: string;
  show?: boolean;
}

const StyledCircledCross = styled(CircledCross)<DeleteProps>`
  width: 20px;
  height: 20px;

  path {
    fill: ${(props) =>
      ({ show }: DeleteProps): string =>
        show ? props.theme.palette.grey[600] : 'none'};
  }

  &:hover {
    cursor: pointer;
    path {
      fill: ${(props) => props.theme.palette.error.main};
    }
  }
`;

function Delete({ id, show }: DeleteProps): JSX.Element {
  return <StyledCircledCross id={id} show={show} />;
}
export default Delete;
