import { gql } from '@apollo/client';
import { REGISTRATION_ADDRESS_FRAGMENT } from 'op-graphql/fragments';

export const REGISTRATION_SOCIAL_GEO_HIST_QUERY = gql`
  query RegistrationSocialGeoHist($id: ID!) {
    patient(id: $id) {
      id
      countryOfBirth
      stateOfBirth
      areaLivedMost
      currentStateHowLong
      inThisStateAllYear
      lastVisitedSection
      regFormStatus
      alternateAddress {
        id
        ...Address
      }
      alternateAddressPhone
    }
    countryRefData: listData(category: "countryOfBirth") {
      id
      name
    }
    user {
      id
      isPso
    }
  }
  ${REGISTRATION_ADDRESS_FRAGMENT}
`;

export const UPDATE_REGISTRATION = gql`
  mutation UpdatePatientUsSocialGeoHist(
    $patientId: ID!
    $addressId: ID
    $countryOfBirth: String
    $stateOfBirth: String
    $areaLivedMost: String
    $currentStateHowLong: String
    $inThisStateAllYear: Boolean
    $alternateAddressPhone: String
  ) {
    updatePatientUsSocialGeoHist(
      patientId: $patientId
      addressId: $addressId
      countryOfBirth: $countryOfBirth
      stateOfBirth: $stateOfBirth
      areaLivedMost: $areaLivedMost
      currentStateHowLong: $currentStateHowLong
      inThisStateAllYear: $inThisStateAllYear
      alternateAddressPhone: $alternateAddressPhone
    ) {
      patient {
        id
        countryOfBirth
        stateOfBirth
        areaLivedMost
        currentStateHowLong
        inThisStateAllYear
        alternateAddress {
          id
          ...Address
        }
        alternateAddressPhone
      }
    }
  }
  ${REGISTRATION_ADDRESS_FRAGMENT}
`;
