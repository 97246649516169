// eslint-disable-next-line no-use-before-define
import React, { useState } from 'react';

import { WithApolloClient, withApollo } from '@apollo/client/react/hoc';
import classnames from 'classnames';

import { DateTimeConverter } from 'shared-components/utils';

import { PatientOptIn } from 'op-interfaces';
import { GraphUpdate, ListData } from 'shared-components/interfaces';
import { SegmentedInput } from 'shared-components/components/FormFields';
import { BaseTextField, ROHelperText } from 'shared-components/components/FormFields';
import { validateRegistration, ValidationKeys } from 'op-utils';
import { GET_PATIENTS_BY_EMAIL } from '../../OP/PatientSearch/PatientSearchQueries';
import { HEADER_TITLE, DESCRIPTION, SEGMENT_INPUT, FIELD_INFO, OPT_OUT_PLACEHOLDER } from './constants';
import { Typography, Grid, Stack, useTheme, TextareaAutosize } from '@mui/material';
import { StandardDialog } from 'shared-components/components';

const VALIDATION_MESSAGE: { [key: string]: { [key: string]: string } } = {
  primaryPhone: {
    INVALID_DATA: 'Please enter a valid mobile number.',
    MISSING_DATA: 'Please enter your mobile phone number.',
  },
  email: {
    INVALID_DATA: 'Please enter a valid email address.',
    MISSING_DATA: 'Please enter your email address.',
    DUPLICATE_EMAIL: 'Email address already in use, please use another email address',
  },
  pxOptOutComment: {
    MISSING_DATA: 'Please enter a reason for opting out.',
  },
};

interface ValidationObject {
  primaryPhone?: string[];
  email?: string[];
  pxOptOutComment?: string[];
}

export interface PatientSearchQueryItemAddress {
  line1: string;
  line2: string;
  city: string;
  state: string;
  country: string;
  postcode: string;
}

interface Props extends WithApolloClient<{}> {
  patient: PatientOptIn;
  isOpen: boolean;
  dismissFunction: () => void;
  saveFunction: (graphData: GraphUpdate[], optedIn: boolean, shouldSubmit: boolean) => void;
  patientIda: string;
  dob: string;
  address: PatientSearchQueryItemAddress;
  setMutationLoading: () => void;
  dismissLock: () => void;
}

const DialogOptIn = (props: Props) => {
  const { isOpen, dismissFunction, patient, patientIda, dob, address, setMutationLoading, saveFunction, dismissLock } =
    props;
  const { id, firstName, lastName, pxOptedIn } = patient;

  const defaultPxSignedUp = pxOptedIn ? SEGMENT_INPUT.OPT_IN : pxOptedIn === false ? SEGMENT_INPUT.OPT_OUT : '';
  const [pxSignedUp, setPxSignedUp] = useState(defaultPxSignedUp);
  const [validationObject, setValidationObject] = useState<ValidationObject>({});
  const [primaryPhone, setPrimaryPhone] = useState(patient?.primaryPhone);
  const [email, setEmail] = useState(patient?.email);
  const [pxOptOutComment, setPxOptOutComment] = useState(patient?.pxOptOutComment);

  const theme = useTheme();

  const renderConditionalForm = (): JSX.Element => {
    if (pxSignedUp === SEGMENT_INPUT.OPT_IN) {
      // Convert the error messages
      let primaryPhoneErrors: string[] | undefined = undefined;
      let emailErrors: string[] | undefined = undefined;
      if (validationObject) {
        if (validationObject.primaryPhone && validationObject.primaryPhone.length > 0) {
          primaryPhoneErrors = validationObject.primaryPhone.map((primaryPhoneError: string) => {
            return VALIDATION_MESSAGE.primaryPhone[primaryPhoneError];
          });
        }

        if (validationObject.email && validationObject.email.length > 0) {
          emailErrors = validationObject.email.map((emailError: string) => {
            return VALIDATION_MESSAGE.email[emailError];
          });
        }
      }

      return (
        <Stack gap={2} paddingBottom={2} width="80%">
          <Stack direction="row" alignItems="center" gap={2}>
            <Typography id="primaryPhoneLabel" width="30%" variant="subtitle2">
              {FIELD_INFO.PRIMARY_PHONE.TITLE}
            </Typography>
            <BaseTextField
              name="primaryPhone"
              sx={{ width: '70%' }}
              id={FIELD_INFO.PRIMARY_PHONE.NAME}
              value={primaryPhone}
              placeholder={FIELD_INFO.PRIMARY_PHONE.PLACEHOLDER}
              onChange={(e) => setPrimaryPhone(e.target.value)}
              error={Boolean(primaryPhoneErrors)}
              helperText={primaryPhoneErrors?.join(' ')}
            />
          </Stack>
          <Stack direction="row" alignItems="center" gap={2}>
            <Typography id="emailLabel" width="30%" variant="subtitle2">
              {FIELD_INFO.EMAIL.TITLE}
            </Typography>
            <BaseTextField
              name="email"
              sx={{ width: '70%' }}
              id={FIELD_INFO.EMAIL.NAME}
              value={email}
              placeholder={FIELD_INFO.EMAIL.PLACEHOLDER}
              onChange={(e) => setEmail(e.target.value)}
              error={Boolean(emailErrors)}
              helperText={emailErrors?.join(' ')}
            />
          </Stack>
        </Stack>
      );
    } else if (pxSignedUp === SEGMENT_INPUT.OPT_OUT) {
      let pxOptOutCommentErrors: string[] | undefined = undefined;
      if (validationObject?.pxOptOutComment && validationObject.pxOptOutComment.length > 0) {
        pxOptOutCommentErrors = validationObject.pxOptOutComment.map((commentError: string) => {
          return VALIDATION_MESSAGE.pxOptOutComment[commentError];
        });
      }
      return (
        <Stack width={1} paddingBottom={2}>
          <Typography data-testid="label-pxOptOutComment" variant="subtitle1">
            {FIELD_INFO.OPT_OUT_REASON.TITLE}
          </Typography>
          <TextareaAutosize
            style={{ outline: 'none', fontFamily: 'unset' }}
            placeholder={OPT_OUT_PLACEHOLDER}
            value={pxOptOutComment}
            name="pxOptOutComment"
            maxLength={500}
            onChange={(e): void => {
              setPxOptOutComment(e.target.value);
            }}
          />
          {pxOptOutCommentErrors?.map((error: string) => (
            <ROHelperText error helperText={error} />
          ))}
        </Stack>
      );
    }

    return <></>;
  };

  const saveValues = async (): Promise<void> => {
    // Default values
    let pxOptedIn = false;
    let graphData = [{ key: 'pxOptOutComment', value: pxOptOutComment, type: 'String' }];
    const shouldSubmit = checkUpdatedValue();

    if (pxSignedUp === SEGMENT_INPUT.OPT_IN) {
      pxOptedIn = true;

      const validation = validateRegistration(
        { id, firstName, lastName, email, primaryPhone: primaryPhone },
        ValidationKeys.ContactPXSignUp,
      );

      // There is a validation error so do not continue after setting the state
      if (validation && Object.keys(validation).length > 0) {
        setValidationObject({ ...validation });
        return;
      } else {
        const duplicateEmailCheck = await props.client?.query({
          query: GET_PATIENTS_BY_EMAIL,
          fetchPolicy: 'no-cache',
          variables: { email },
        });
        const patients = duplicateEmailCheck?.data.patients;
        const includesCurrentPatient = patients.filter((patient: { id: string }) => patient.id === id).length;

        if ((patients.length === 1 && !includesCurrentPatient) || patients.length > 1) {
          setValidationObject({ email: ['DUPLICATE_EMAIL'] });
          return;
        }
      }
    }

    if (pxOptedIn) {
      graphData = [
        { key: 'email', value: email, type: 'String' },
        { key: 'primaryPhone', value: primaryPhone, type: 'String' },
      ];
    } else {
      // Check to make sure that the px opt out comment has been filled out
      if (pxOptOutComment.trim().length === 0) {
        setValidationObject({ pxOptOutComment: ['MISSING_DATA'] });
        return dismissLock();
      }
    }

    saveFunction(graphData, pxOptedIn, shouldSubmit);
  };

  const checkUpdatedValue = (): boolean => {
    const primary = patient?.primaryPhone.split(' ').join('');
    const inputPhone = primaryPhone.split(' ').join('');
    return !(primary === inputPhone && email === patient?.email);
  };

  const renderPatientDetails = (
    headingText: string,
    detailText?: string | JSX.Element,
    fallbackText?: string,
  ): JSX.Element => {
    let optionalColouring = false;
    let displayText: string | JSX.Element | JSX.Element[] | undefined = undefined;

    displayText = renderContentMessage(detailText, fallbackText);
    optionalColouring = displayText === fallbackText || displayText === 'Not provided';

    return (
      <Grid container className="patient-data-row">
        <Grid item className="patient-data-title" xs={6}>
          <Typography variant="subtitle1" textAlign="right" paddingRight="16px">
            {headingText}:
          </Typography>
        </Grid>
        <Grid item className={classnames('patient-data-content', { optional: optionalColouring })} xs={6}>
          <Typography variant="body1">{displayText}</Typography>
        </Grid>
      </Grid>
    );
  };

  /**
   * Helper method to generate the detail text or the provided fall-back text if data is missing.
   */
  const renderContentMessage = (
    content?: string | JSX.Element,
    fallbackText?: string,
  ): JSX.Element | JSX.Element[] | string => {
    // If null or empty then return missing text
    if (content) return content;
    if (fallbackText) return fallbackText;
    return 'Not provided';
  };

  const createAddress = (address?: PatientSearchQueryItemAddress): JSX.Element => {
    if (address) {
      const { line1, line2, city, state, country, postcode } = address;

      if (line1 || city || state || country || postcode) {
        return (
          <>
            <div>
              {line1}&#44;
              <br />
              {renderLine2(line2)}
              {city}&#44;
              <br />
              {state} {country} {postcode}
            </div>
          </>
        );
      }
    }

    return <>Not provided</>;
  };

  const renderLine2 = (line2?: string): JSX.Element => {
    if (line2) {
      return (
        <>
          {line2}&#44;
          <br />
        </>
      );
    }

    return <></>;
  };

  return (
    <StandardDialog
      key={`patient-portal-access-${id}`}
      id="patient-portal-access"
      open={isOpen}
      onClose={dismissFunction}
      fullWidth
      maxWidth="sm"
      title={HEADER_TITLE}
      showDividers
      onSubmit={() => {
        setMutationLoading();
        saveValues();
      }}
      submitText={pxSignedUp === SEGMENT_INPUT.OPT_IN ? 'Send invitation link' : 'Save'}>
      <Stack>
        <div className="description" style={{ textAlign: 'center', paddingBottom: '16px' }}>
          <Typography variant="body1">{DESCRIPTION.LINE_1}</Typography>
          <Typography variant="body1">{DESCRIPTION.LINE_2}</Typography>
        </div>
        <form
          className="form-container"
          style={{ display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center' }}>
          <div style={{ width: '70%', paddingBottom: '16px' }}>
            <SegmentedInput
              options={[
                { id: SEGMENT_INPUT.OPT_IN, name: 'Sign up' },
                { id: SEGMENT_INPUT.OPT_OUT, name: 'Opt out' },
              ]}
              fieldName={FIELD_INFO.OPT_IN.NAME}
              defaultSelected={pxSignedUp}
              itemSelected={(selectedItem): void => {
                setPxSignedUp((selectedItem as ListData).id);
                setValidationObject({});
              }}
            />
          </div>
          {renderConditionalForm()}
        </form>
        <div id="patient-portal-name">
          <Typography
            variant="h6"
            textAlign="center"
            sx={{
              backgroundColor: theme.palette.grey[200],
              padding: '8px 0px',
            }}>{`${patient.firstName} ${patient.lastName}`}</Typography>
          {renderPatientDetails('EMR', patient.emrInstance)}
          {renderPatientDetails('Patient ID', patientIda)}
          {renderPatientDetails('Date of birth', DateTimeConverter.getFormattedDateAsDDMonthYYYY(dob))}
          {renderPatientDetails('Address', createAddress(address))}
        </div>
      </Stack>
    </StandardDialog>
  );
};

const apolloDialogOptIn = withApollo<Props>(DialogOptIn);
export default apolloDialogOptIn;
