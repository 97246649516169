interface GetFormattedDateComponentResults {
  formattedComponents: {
    dayOfMonth: string;
    month: string;
    time: string;
    dayOfWeek: string;
    year: string;
  };
}

const SUNDAY = 0;
const THURSDAY = 4;
const FRIDAY = 5;
const SATURDAY = 6;

// If today is one of these days then the cut off is the following Sunday
const FRIDAY_CUTOFF = [SUNDAY, FRIDAY, SATURDAY];
const THURSDAY_CUTOFF = [SUNDAY, THURSDAY, FRIDAY, SATURDAY];

const APPOINTMENT_LISTING_CUTOFF: { [entity: string]: number[] } = {
  SA: FRIDAY_CUTOFF,
  VIC: FRIDAY_CUTOFF,
  QLD: FRIDAY_CUTOFF,
  NSW: THURSDAY_CUTOFF,
  WA: THURSDAY_CUTOFF,
};

export default class DateTimeConverter {
  /**
   * Checks whether the given local date time matches today's date.
   * @param {Date} someDate The local date time object to check.
   * @returns {boolean} True if the date matches today's date, or false otherwise.
   */
  public static isToday(someDate: Date): boolean {
    const today = new Date();

    return (
      someDate.getDate() === today.getDate() &&
      someDate.getMonth() === today.getMonth() &&
      someDate.getFullYear() === today.getFullYear()
    );
  }

  /**
   * Formats the given date time string and returns three separate date/time components.
   * @param {string} currentLocale The current locale, e.g. en-AU.
   * @param {Date} date The Date to format.
   * @returns {GetFormattedDateComponentResults} An object containing a variety of formatted date and time related strings.
   */
  public static getFormattedDateComponents(currentLocale: string, date: Date): GetFormattedDateComponentResults {
    const today = 'Today'; // FIXME: Localise

    const dayFormat: Intl.DateTimeFormatOptions = {
      day: 'numeric',
    };

    const monthFormat: Intl.DateTimeFormatOptions = {
      month: 'short',
    };

    const timeFormat: Intl.DateTimeFormatOptions = {
      hour: 'numeric',
      minute: 'numeric',
      hourCycle: 'h12',
    };

    const weekDayFormat: Intl.DateTimeFormatOptions = {
      weekday: 'short',
    };

    const yearFormat: Intl.DateTimeFormatOptions = {
      year: 'numeric',
    };

    // Format the day of month, month, time and day of week
    const formattedDayOfMonth = date.toLocaleString(currentLocale, dayFormat); // Format: d, e.g. 2
    const formattedMonth = date.toLocaleString(currentLocale, monthFormat).replace('.', ''); // Format: MMM, e.g. Jan (NOTE: Microsoft Edge automatically adds a trailing full-stop, e.g. 'Sep.')
    const formattedTime = date.toLocaleString(currentLocale, timeFormat).replace(' ', ''); // Format: h:mmaa, e.g. 3:23pm
    const formattedDayOfWeek = DateTimeConverter.isToday(date)
      ? today
      : date.toLocaleString(currentLocale, weekDayFormat).replace('.', ''); // Format: 'Today' or EEE, e.g. Wed (NOTE: Microsoft Edge automatically adds a trailing full-stop, e.g. 'Wed.')
    const formattedYear = date.toLocaleString(currentLocale, yearFormat);

    const result = {
      formattedComponents: {
        dayOfMonth: formattedDayOfMonth,
        month: formattedMonth,
        time: formattedTime,
        dayOfWeek: formattedDayOfWeek,
        year: formattedYear,
      },
    };

    return result;
  }

  /**
   * Formats the given date time object into a string with format EEE, d MMM YYYY, e.g. Mon, 7 May 2019.
   * @param {string} currentLocale The current locale, e.g. en-AU.
   * @param {Date} date The Date to format.
   * @returns {string} A date string in 'd MMM YYYY' format.
   */
  public static getFormattedDateAsMMMYYYY(currentLocale: string, date: Date): string {
    const formattedComponents = DateTimeConverter.getFormattedDateComponents(currentLocale, date).formattedComponents;

    return formattedComponents.dayOfMonth + ' ' + formattedComponents.month + ' ' + formattedComponents.year;
  }

  public static getFormattedDateTimeAsEEEdhmmaa(currentLocale: string, date: Date): string {
    const formattedComponents = DateTimeConverter.getFormattedDateComponents(currentLocale, date).formattedComponents;

    return formattedComponents.dayOfWeek + ', ' + formattedComponents.time.toLowerCase();
  }

  /**
   * Formats the given date time object into a string with format HH:mma, e.g. 9:30am.
   * @param {string} currentLocale The current locale, e.g. en-AU.
   * @param {Date} date The Date to format.
   * @returns {string} A time string in H:mma format.
   */
  public static getFormattedTimeAsHMMa(currentLocale: string, date: Date): string {
    const formattedComponents = DateTimeConverter.getFormattedDateComponents(currentLocale, date).formattedComponents;
    return formattedComponents.time;
  }

  public static getAppointmentCutOffDate(entity: string | undefined, today: Date = new Date()): Date {
    // Without entity just do what the function previously did for WA/NSW

    const nextDay = (date: Date, dow: number) => {
      const result = new Date(date);
      result.setDate(date.getDate() + ((dow + (7 - date.getDay())) % 7));
      return result;
    };

    const addDays = (date: Date, days: number) => {
      const result = new Date(date);
      result.setDate(result.getDate() + days);
      return result;
    };

    // const today = new Date();
    const todayDayOfWeek = today.getDay();
    // Set today hours to the end of today, as the cutoff happens at the end
    today.setHours(23, 59, 59, 999);

    let makeItNextSunday = THURSDAY_CUTOFF;
    if (entity) {
      makeItNextSunday = APPOINTMENT_LISTING_CUTOFF[entity] || THURSDAY_CUTOFF;
    }

    const cutOffDate = makeItNextSunday.includes(todayDayOfWeek)
      ? nextDay(addDays(today, makeItNextSunday.length), SUNDAY) // Make sure we add days so we always get the following sunday
      : nextDay(today, SUNDAY);

    return cutOffDate;
  }
}
