import React from 'react';
import { styled } from '@mui/system';
import { Button } from '@mui/material';
import { UploadOutlined as UploadOutlinedIcon } from '@mui/icons-material';

interface DocumentUploadButtonProps {
  onClick: () => void;
}

interface StyledProps {
  $maxWidth?: string;
}

const UploadIcon = styled(UploadOutlinedIcon)`
  width: 24px;
  height: 24px;
  margin-right: -5px;
`;

const DocumentUploadButton = (props: DocumentUploadButtonProps): JSX.Element => {
  return (
    <Button
      variant="text"
      onClick={props.onClick}
      data-testid="document-upload-button"
      startIcon={<UploadIcon color="primary" />}>
      Upload document
    </Button>
  );
};

export default DocumentUploadButton;
