import React from 'react';
import { useFormikContext } from 'formik';
import { content } from '../../PreCtTriage/constants';
import { routes } from '../../PreCtTriage/routes';
import { ReviewBox } from 'shared-components/components';
import ReviewFieldQA from './ReviewFieldQA';
import { styled } from '@mui/system';

interface FieldsWithHeadingsToDisplay {
  subHeading: string;
  fieldsToDisplay: string[];
}

interface Props {
  sectionName: string;
  link: string;
  fieldsWithHeadingsToDisplay: FieldsWithHeadingsToDisplay[];
  valuesToDisplay: (value: string, fieldName: string) => any;
  validateField: (value: string, fieldName: string) => boolean;
  subFields?: string[];
  isLocked: boolean;
  newFieldsTouched: boolean;
  oldFieldHasValue: boolean;
}

const SubHeading = styled('div')`
  font-style: normal;
  font-size: 18px;
  font-weight: 700;
  line-height: 28px;
  letter-spacing: 0em;
  text-align: left;
  padding: 0 0 10px 27px;
`;

const SectionBreak = styled('div')`
  margin: 10px 0 0 10px;
`;

export const StyledHorizontalLineCustom = styled('div')`
  margin-top: 15px !important;
  margin-bottom: 25px !important;
  width: 90%;
  height: 10px;
  border-bottom: 1px solid black;
  border-color: ${(props) => props.theme.palette.grey[300]};
  text-align: center;
  margin-left: 5%;
  margin-right: 5%;

  span {
    font-size: 13px;
    background-color: white;

    padding: 0 20px;
    color: ${(props) => props.theme.palette.grey[600]};
  }
`;

const ReviewSectionWithHeading = ({
  sectionName,
  link,
  fieldsWithHeadingsToDisplay,
  valuesToDisplay,
  validateField,
  subFields,
  isLocked,
  newFieldsTouched,
  oldFieldHasValue,
}: Props): JSX.Element => {
  const { errors }: any = useFormikContext();
  //@ts-ignore
  const { fields } = content[sectionName]['livingSituation'];
  //@ts-ignore
  const { sectionTitle } = routes[sectionName];

  const displayName = {
    livingSituation: 'Living situation',
    careRequirements: 'Care requirements',
    socialMedicalHistory: 'Other significant social or medical history',
  };

  const checkErrorValidation = (fields: any): boolean => {
    for (const key of Object.keys(fields)) {
      if (errors[key]) return true;
    }
    return false;
  };

  const showNewQuestionsOnly = (): boolean => {
    // 1. if there are any new questions answered, dont show Social Considerations
    // 2. if there are no new questions answered, and there is Social Considerations data, show Social Considerations
    // 3. if there are no new questions answered, and no Social Considerations data, dont show Social Considerations

    if (!newFieldsTouched && oldFieldHasValue) {
      return false;
    } else {
      return true;
    }
  };

  return (
    <ReviewBox title={sectionTitle} error={checkErrorValidation(fields)} link={link} isLocked={isLocked}>
      {!showNewQuestionsOnly() && (
        <ReviewFieldQA
          fieldName={'socialConsiderations'}
          sectionFields={{ socialConsiderations: 'Social Considerations' }}
          valuesToDisplay={valuesToDisplay}
          validateField={validateField}
          isSubField={false}
        />
      )}

      {showNewQuestionsOnly() &&
        fieldsWithHeadingsToDisplay.map((fieldsWithHeadings: FieldsWithHeadingsToDisplay, index: number) => {
          const { fieldsToDisplay, subHeading } = fieldsWithHeadings;
          //@ts-ignore
          const { fields } = content[sectionName][subHeading];
          return (
            <>
              {index > 0 && <StyledHorizontalLineCustom />}
              {subHeading && (
                <SubHeading>
                  {
                    //@ts-ignore
                    displayName[subHeading]
                  }
                </SubHeading>
              )}

              {fieldsToDisplay.map((fieldName: string) => (
                <ReviewFieldQA
                  fieldName={fieldName}
                  sectionFields={fields}
                  valuesToDisplay={valuesToDisplay}
                  validateField={validateField}
                  isSubField={subFields && subFields.includes(fieldName)}
                />
              ))}

              <SectionBreak />
            </>
          );
        })}
    </ReviewBox>
  );
};

export default ReviewSectionWithHeading;
