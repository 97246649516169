import { Field, FormikProps, useFormikContext } from 'formik';
import { InfoCard } from 'op-components';
import { CancerItem, InfoCardRowItem, TreatmentItem } from 'op-interfaces';
import React from 'react';
import { FormRow, HelperMessage } from 'shared-components/components';
import { yesNoOptions } from 'op-utils';
import { ToggleButtonGroupField } from 'shared-components/components/FormikComponents';
import { TREATMENT_TYPES } from '../../../HealthAssessment/HAPages';
import { choiceToBooleanOptions, getLastUpdateUser, transformEmptyString } from '../../helpers';
import { LastUpdateUserDetails } from '../../shared/interfaces';
import { preCtChartCheckContent } from '../constants';
import { HealthAssessmentData, PreCtChartCheckFormValues } from '../interfaces/PreCtChartCheckInterfaces';

interface Props {
  handleMutation: (name: string, value: string) => void;
  healthAssessmentData: HealthAssessmentData;
  lastUpdateUserData: LastUpdateUserDetails[];
}

const ChemotherapyRisk = ({ handleMutation, healthAssessmentData, lastUpdateUserData }: Props): JSX.Element => {
  const { values }: FormikProps<PreCtChartCheckFormValues> = useFormikContext();
  const { anticancerDrugs, chemoPastWeek } = preCtChartCheckContent.chemoRisk.fields;
  const { typeOfCondition, typeOfTreatment, dateOfLastTreatment, treatmentStageTitle, doctorTitle, nameOfClinic } =
    preCtChartCheckContent.chemoRisk.tableTitles;
  const anticancerDrugsQCLMessage = 'Selecting "Yes" will create QCL: Cytotoxic Mx Plan';
  const chemoPastWeekQCLMessage = 'Selecting "Yes" will create Alert: Cytotoxic precautions';

  let hasPreviousCancer;
  if (healthAssessmentData.healthAssessment) {
    //@ts-ignore
    hasPreviousCancer = choiceToBooleanOptions[String(healthAssessmentData.healthAssessment.previousCancer)];
  }

  let previousCancerTableRows: InfoCardRowItem[][] = [];

  if (hasPreviousCancer === true) {
    const stageRefData = healthAssessmentData.treatmentStageRefData;
    const typeRefData = healthAssessmentData.treatmentTypeRefData;

    // Iterate through treatments
    previousCancerTableRows = healthAssessmentData.healthAssessment.cancer.flatMap(
      (cancer: CancerItem, cancerIndex: number) => {
        const treatments = cancer.treatment
          .filter((treatment) => {
            const treatmentTypeMain = typeRefData.find((ref) => ref.id === treatment.treatmentType);
            return treatmentTypeMain && treatmentTypeMain.name !== TREATMENT_TYPES.RADIATION_THERAPY;
          })
          .map((treatment: TreatmentItem, treatmentIndex: number) => {
            const treatmentStage = stageRefData.find((ref) => ref.id === treatment.stage)?.name;
            const treatmentTypeMain = typeRefData.find((ref) => ref.id === treatment.treatmentType);
            const treatmentType =
              treatmentTypeMain && treatment.other
                ? `${treatmentTypeMain.name}: ${treatment.other}`
                : transformEmptyString(treatmentTypeMain?.name);

            // Only include fields with non-empty values, not all fields are relevant to all types of treatments
            const record = [
              { title: `${typeOfCondition} ${cancerIndex + 1}`, value: cancer.cancerType },
              { title: `${typeOfTreatment} ${treatmentIndex + 1}`, value: treatmentType },
            ];

            const treatmentRawDay = parseInt(treatment.treatmentRawDay);
            const treatmentRawMonth = parseInt(treatment.treatmentRawMonth);
            const treatmentRawYear = parseInt(treatment.treatmentRawYear);

            if (treatmentRawDay || treatmentRawMonth || treatmentRawYear) {
              record.push({
                title: dateOfLastTreatment,
                value:
                  `${treatmentRawDay > 0 ? treatmentRawDay.toString().padStart(2, '0') : '--'}/` +
                  `${treatmentRawMonth > 0 ? treatmentRawMonth.toString().padStart(2, '0') : '--'}/` +
                  `${treatmentRawYear > 0 ? treatmentRawYear.toString().padStart(2, '0') : '----'}`,
              });
            }

            if (treatmentStage) {
              record.push({ title: treatmentStageTitle, value: treatmentStage });
            }

            if (treatment.doctor) {
              record.push({ title: doctorTitle, value: treatment.doctor });
            }

            if (treatment.hospital) {
              record.push({ title: nameOfClinic, value: treatment.hospital });
            }

            return record;
          });

        return treatments;
      },
    );
  }

  return (
    <>
      <FormRow fieldLabel={anticancerDrugs} fieldName={'anticancerDrugs'}>
        <Field
          name="anticancerDrugs"
          component={ToggleButtonGroupField}
          label={anticancerDrugs}
          options={yesNoOptions}
          handleMutation={handleMutation}
          lastUpdateUser={getLastUpdateUser(lastUpdateUserData, 'anticancerDrugs')}
        />
        {values.anticancerDrugs === true && (
          <HelperMessage fieldName={anticancerDrugs} fieldText={anticancerDrugsQCLMessage} helperType="info" />
        )}
      </FormRow>
      <FormRow fieldLabel={chemoPastWeek} fieldName={'chemoPastWeek'}>
        <Field
          name="chemoPastWeek"
          component={ToggleButtonGroupField}
          label={chemoPastWeek}
          options={yesNoOptions}
          handleMutation={handleMutation}
          lastUpdateUser={getLastUpdateUser(lastUpdateUserData, 'chemoPastWeek')}
        />
        {values.chemoPastWeek === true && (
          <>
            <HelperMessage fieldName={chemoPastWeek} fieldText={anticancerDrugsQCLMessage} helperType="info" />
            <HelperMessage fieldName={chemoPastWeek} fieldText={chemoPastWeekQCLMessage} helperType="info" />
          </>
        )}
      </FormRow>
      {hasPreviousCancer === true && previousCancerTableRows.length > 0 && (
        <InfoCard
          title="Patient previous cancer treatment/s"
          subTitle="(Patient reported)"
          rowDetails={previousCancerTableRows}
        />
      )}
    </>
  );
};

export default ChemotherapyRisk;
