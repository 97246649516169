import { styled } from '@mui/system';

export const TabSelectorCategoryOuter = styled('div')`
  display: flex;
  flex-direction: column;
  height: 100%;
  width: 100%;
`;

export const TabSelectorValuesOuter = styled('div')`
  display: flex;
  border-radius: 8px;
  flex-direction: column;
  height: 100%;
  width: 100%;
`;

export const TabSelectorCategoryCard = styled('div')`
  padding: 16px;
  border-bottom: 1px solid ${(props) => props.theme.palette.grey[300]};
  font-weight: 600;
  &:hover {
    background-color: ${(props) => props.theme.palette.grey[100]};
  }
  &.selected {
    border-left: 5px solid ${(props) => props.theme.palette.primary.main};
    background-color: ${(props) => props.theme.palette.grey[100]};
  }
`;

export const TabSelectorValueCard = styled('div')`
  padding: 8px;
  margin-top: 8px;
  font-weight: 550;
  border: 1px solid ${(props) => props.theme.palette.grey[300]};
  border-radius: 4px;
  &.selected {
    border-color: ${(props) => props.theme.palette.primary.main};
  }
`;

export const TabSelectorValueTitle = styled('div')`
  display: flex;
  align-items: center;
`;

export const CategoryFilterInputContainer = styled('div')`
  display: flex;
  align-items: center;
  padding: 12px;
  border-bottom: 1px solid ${(props) => props.theme.palette.grey[300]};
  position: sticky;
  top: 0;
  background-color: white;
`;

export const ValueFilterInputContainer = styled('div')`
  display: flex;
  padding: 8px;
  border-radius: 4px;
  border: 1px solid ${(props) => props.theme.palette.grey[300]};
  margin-bottom: 8px;
`;

export const StyledFilterInput = styled('input')`
  margin-left: 8px;
  border: 0;
`;

export const StyledRadioButton = styled('div')`
  width: 20px;
  height: 20px;
  margin-right: 8px;
  margin-left: 8px;
  border-radius: 16px;
  border: 1px solid ${(props) => props.theme.palette.grey[300]};

  &.selected {
    background-color: ${(props) => props.theme.palette.primary.main};
  }
`;

export const NoResultsMessage = styled('div')`
  margin: 20px auto;
  font-size: large;
  font-weight: 700;
`;
