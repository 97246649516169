import { useContext, useState, useEffect } from 'react';
import Highcharts from 'highcharts';
import HighchartsReact from 'highcharts-react-official';
import { DashboardContext } from '../DashboardContext';
import ArrowForward from '@mui/icons-material/ArrowForward';
import { findParentById } from '../Utils/utils';
import { Typography } from '@mui/material';
import { useTheme } from '@mui/material/styles';

const OverviewDonutChart = (props: { aggregatedData: any[] }) => {
  const { aggregatedData } = props;
  const { overviewChartsLoaded, isReloaded, setOverviewChartsLoaded } = useContext(DashboardContext);

  const sortOrder = ['Breast', 'Metastasis', 'Prostate', 'Colorectal', 'Neuro', 'Skin', 'Unspecified'];

  const existingKeys = new Set(aggregatedData.map((item) => item[0]));

  sortOrder.forEach((key) => {
    if (!existingKeys.has(key)) {
      aggregatedData.push([key, 0]);
    }
  });

  const chartData = aggregatedData.sort((a, b) => {
    const indexA = sortOrder.indexOf(a[0]);
    const indexB = sortOrder.indexOf(b[0]);

    if (indexA === -1) return 1;
    if (indexB === -1) return -1;

    return indexA - indexB;
  });

  return (
    <div className="overview-card-chart">
      <HighchartsReact
        highcharts={Highcharts}
        containerProps={{ style: { height: '100%' } }}
        options={{
          chart: {
            type: 'pie',
            backgroundColor: '#fff',
            spacing: [-10, 0, -5, -20],
            styledMode: true,
            events: {
              // hacking the chart height to fit the container, because highcharts doesnt play well with flexbox
              load: function (this: Highcharts.Chart) {
                setOverviewChartsLoaded(true);
                const currentElement = findParentById(this.container, 'overview-chart-container');
                const parentSize = currentElement?.getBoundingClientRect().height;

                // for some reason chart heights are different when the charts are first loaded, and also when the page is reloaded
                const offset = overviewChartsLoaded || !isReloaded ? 110 : 130;

                if (parentSize) {
                  this.setSize(undefined, Number((parentSize - offset).toFixed(0)));
                  sessionStorage.removeItem('reloaded');
                }
              },
            },
          },
          exporting: {
            enabled: false,
          },
          title: {
            text: '',
          },
          legend: {
            enabled: true,
            navigation: {
              enabled: false,
            },
            layout: 'horizontal',
            align: 'right',
            verticalAlign: 'right',
            itemStyle: {
              fontSize: '10px',
              width: '85px',
            },
            width: 170,
            itemWidth: 80,
            margin: 0,
            padding: 0,
            itemMarginTop: 2,
            itemMarginBottom: 0,
            x: 20,
            y: 10,
          },
          credits: {
            enabled: false,
          },
          tooltip: {
            enabled: false,
          },
          plotOptions: {
            pie: {
              dataLabels: {
                enabled: false,
              },
              showInLegend: true,
            },
            series: {
              innerSize: '60%',
              marker: {
                enabled: false,
              },
              states: {
                hover: {
                  enabled: false,
                },
              },
              enableMouseTracking: false,
            },
          },
          series: [
            {
              data: chartData,
            },
          ],
        }}
      />
    </div>
  );
};

const OverviewCardDonut = (props: {
  title: string;
  page: string;
  metricSuffix?: string;
  metricRound?: number;
  aggregateFunction: (data: any, field: string) => (string | number)[][];
  metricFunction: (data: any[]) => number;
}): JSX.Element => {
  const { filteredData, previousPeriodData, dateRange, navToPage } = useContext(DashboardContext);
  const { title, page, metricSuffix = '', metricRound = 0, aggregateFunction, metricFunction } = props;

  const [aggregatedData, setAggregatedData] = useState<any[][]>([]);
  const [metric, setMetric] = useState<string>('');
  const [previousPeriodMetric, setPreviousPeriodMetric] = useState<number>(0);
  const [metricChangeIndicator, setMetricChangeIndicator] = useState<string>('');

  const theme = useTheme();

  useEffect(() => {
    setAggregatedData(aggregateFunction(filteredData, 'diagnosis'));

    setMetric(metricFunction(filteredData).toFixed(metricRound) + metricSuffix);

    const current = metricFunction(filteredData);
    const previous = metricFunction(previousPeriodData);
    const percentChange = (Math.abs(current - previous) / previous) * 100;
    setPreviousPeriodMetric(percentChange);
    setMetricChangeIndicator(current > previous ? 'more' : 'less');
  }, [filteredData]);

  return (
    <div className="overview-card" id="overview-chart-container" onClick={() => navToPage(page)}>
      <div className="overview-card-metric-container">
        <Typography className="overview-card-title">{title}</Typography>
        {dateRange !== 'All time' && (
          <ArrowForward
            style={{
              transform: `rotate(${metricChangeIndicator === 'more' ? '-' : ''}90deg)`,
            }}
          />
        )}
      </div>
      <Typography variant="h5" sx={{ lineHeight: 1, marginBottom: '5px' }}>
        {metric}
      </Typography>
      <Typography variant="body2" color={theme.palette.text.secondary} sx={{ marginBottom: '8px' }}>
        {dateRange !== 'All time' && (
          <div>
            {previousPeriodMetric.toFixed(metricRound)}% {metricChangeIndicator} than previous{' '}
            {dateRange.split('Past ')[1]}
          </div>
        )}
      </Typography>
      <OverviewDonutChart aggregatedData={aggregatedData.slice(1)} />
    </div>
  );
};

export default OverviewCardDonut;
