import React from 'react';
import { useFormikContext, getIn } from 'formik';
import { InfoCard } from 'op-components';
import { InfoCardRowItem } from 'op-interfaces';
import { PreCtTriageData } from '../../interfaces/PreCtTriageInterfaces';
import { content as triageContent, malnutritionFields, wellbeingTriageFields, TRIAGE } from '../../constants';
import {
  preCtChartCheckContent,
  basicInfoFields,
  chemoRiskFields,
  fallsRiskFields,
  pressureInjuryFields,
  wellbeingCCFields,
  advancedCareFields,
  CHART_CHECK,
} from '../../../PreCtChartCheck/constants';
import {
  PreCtChartCheckData,
  ImplantedMedicalDevice,
  InfectiousDisease,
  MroInfection,
  PcccAllergy,
} from '../../../PreCtChartCheck/interfaces/PreCtChartCheckInterfaces';

interface Props {
  latestChartCheckData: PreCtChartCheckData;
}

const SummaryTable = ({ latestChartCheckData }: Props): JSX.Element => {
  const { values } = useFormikContext<PreCtTriageData>();
  const { fieldKeys } = preCtChartCheckContent.implantedMedicalDevices;
  const { chartCheckTable, triageTable, noChartCheckMessage, emptyMessage } = triageContent.summary.tableValues;
  const noChartCheck = [[{ title: '', value: noChartCheckMessage }]];
  const emptyTable = [[{ title: '', value: emptyMessage }]];

  if (latestChartCheckData) {
    const generateRows = (fields: string[], form: string): InfoCardRowItem[] => {
      const allFields: InfoCardRowItem[] = [];
      fields.forEach((field) => {
        if (
          form === CHART_CHECK &&
          latestChartCheckData[field as keyof PreCtChartCheckData] &&
          chartCheckTable.hasOwnProperty(field)
        ) {
          // @ts-ignore
          allFields.push(chartCheckTable[`${field}`]);
        }
        if (form === TRIAGE && getIn(values, field) && triageTable.hasOwnProperty(field)) {
          // @ts-ignore
          allFields.push(triageTable[`${field}`]);
        }
      });
      return allFields;
    };

    const getImplantedDevicesCCRows = (): InfoCardRowItem[] => {
      const { implantedMedicalDevice } = latestChartCheckData;
      const allFiltered = implantedMedicalDevice.filter((device: ImplantedMedicalDevice) => device.name);

      const reduceDevices = (array: ImplantedMedicalDevice[], field: string[]) => {
        const filtered = array.filter(
          (device: ImplantedMedicalDevice) => field.includes(device.deviceField) && device.name,
        );
        const reduced = filtered.length ? filtered.reduce((a: any, b: any) => a.concat(b.name), []).join(', ') : [];
        return reduced;
      };

      const ciedFormatted = reduceDevices(allFiltered, [fieldKeys.cied]);
      const venousAccessTypeFormatted = reduceDevices(allFiltered, [fieldKeys.venousAccessType]);
      const otherFormatted = reduceDevices(allFiltered, [fieldKeys.otherNotListed, fieldKeys.otherImplantedDevices]);

      const allDeviceRows = [];
      if (ciedFormatted.length) {
        allDeviceRows.push({
          ...chartCheckTable.cied,
          value: ciedFormatted,
        });
      }
      if (venousAccessTypeFormatted.length) {
        allDeviceRows.push({
          ...chartCheckTable.venousAccessType,
          value: venousAccessTypeFormatted,
        });
      }
      if (otherFormatted.length) {
        allDeviceRows.push({
          ...chartCheckTable.otherDevices,
          value: otherFormatted,
        });
      }
      return allDeviceRows;
    };

    const getInfectiousRiskCCRows = (): InfoCardRowItem[] => {
      const { mroInfection, infectiousDisease, outsideWaLastTwelveMonths } = latestChartCheckData;
      const mroInfections: InfoCardRowItem[] = [];
      const infectiousDiseases: InfoCardRowItem[] = [];
      const outsideWALast12Months: InfoCardRowItem[] = [];
      if (mroInfection.length) {
        mroInfection.map((infection: MroInfection, index: number) => {
          return mroInfections.push({
            page: chartCheckTable.mroInfection.page,
            title: `${chartCheckTable.mroInfection.title} ${index + 1}`,
            value: infection.baseValue === 'Other' && infection.otherValue ? infection.otherValue : infection.baseValue,
          });
        });
      }
      if (infectiousDisease.length) {
        infectiousDisease.map((disease: InfectiousDisease, index: number) => {
          return infectiousDiseases.push({
            page: chartCheckTable.infectiousDisease.page,
            title: `${chartCheckTable.infectiousDisease.title} ${index + 1}`,
            value: disease.baseValue === 'Other' && disease.otherValue ? disease.otherValue : disease.baseValue,
          });
        });
      }
      if (outsideWaLastTwelveMonths) {
        outsideWALast12Months.push({
          page: chartCheckTable.outsideWaLastTwelveMonths.page,
          title: `${chartCheckTable.outsideWaLastTwelveMonths.title}`,
          value: 'Yes',
        });
      }
      return [...infectiousDiseases, ...mroInfections, ...outsideWALast12Months];
    };

    const getAllergyCCRows = (): InfoCardRowItem[] => {
      const { pcccAllergy } = latestChartCheckData;
      const allergies: InfoCardRowItem[] = [];
      if (pcccAllergy.length) {
        pcccAllergy.forEach((allergy: PcccAllergy, index: number) => {
          allergies.push({
            page: chartCheckTable.allergy.page,
            title: `${chartCheckTable.allergy.title} ${index + 1}`,
            value: allergy.allergyType && allergy.additionalDetails ? allergy.additionalDetails : allergy.allergyType,
          });
        });
      }
      return allergies;
    };

    const getCognitiveRows = (): InfoCardRowItem[] => {
      const { isAlertAndOrientated, hasAlteredCognitiveState } = values;
      const cognitiveRows: InfoCardRowItem[] = [];
      if (isAlertAndOrientated === false) {
        cognitiveRows.push(triageTable.isAlertAndOrientated);
      }
      if (hasAlteredCognitiveState) {
        cognitiveRows.push(triageTable.hasAlteredCognitiveState);
      }
      return cognitiveRows;
    };

    const getPainRows = (): InfoCardRowItem[] => {
      const { hasCurrentPain, painScore, isAbleToLieFlat, isAbleToClimbStairs } = values;
      const painRows: InfoCardRowItem[] = [];
      if (hasCurrentPain) {
        painRows.push(triageTable.hasCurrentPain);
      }
      if (painScore) {
        painRows.push({
          page: triageTable.painScore.page,
          title: triageTable.painScore.title,
          value: painScore,
        });
      }
      if (isAbleToLieFlat === false) {
        painRows.push(triageTable.isAbleToLieFlat);
      }
      if (isAbleToClimbStairs === false) {
        painRows.push(triageTable.isAbleToClimbStairs);
      }
      return painRows;
    };

    const getTransportRows = (): InfoCardRowItem[] => {
      const { transportMode, otherTransport } = values;
      const transportRows: InfoCardRowItem[] = [];
      if (transportMode) {
        transportMode.forEach((transport: string) => {
          transportRows.push({
            page: triageTable.transport.page,
            title: '',
            value: transport,
          });
        });
      }
      if (otherTransport) {
        transportRows.push({
          page: triageTable.transport.page,
          title: '',
          value: otherTransport,
        });
      }
      return transportRows;
    };

    const getSocialRows = (): InfoCardRowItem[] => {
      const { hasSensoryImpairment, sensoryImpairments, englishSecondLanguage, requireInterpreter } = values;
      const socialRows: InfoCardRowItem[] = [];

      if (hasSensoryImpairment) {
        socialRows.push(triageTable.hasSensoryImpairment);
      }
      if (sensoryImpairments.length > 0) {
        socialRows.push({
          page: triageTable.sensoryImpairments.page,
          title: triageTable.sensoryImpairments.title,
          value: sensoryImpairments.join(', '),
        });
      }
      if (englishSecondLanguage) {
        socialRows.push(triageTable.englishSecondLanguage);
      }
      if (requireInterpreter) {
        socialRows.push(triageTable.requireInterpreter);
      }
      return socialRows;
    };

    const basicInfoCCRows = generateRows(basicInfoFields, CHART_CHECK);
    const chemoRiskCCRows = generateRows(chemoRiskFields, CHART_CHECK);
    const implantedDevicesCCRows = getImplantedDevicesCCRows();
    const infectiousRiskCCRows = getInfectiousRiskCCRows();
    const fallsRiskCCRows = generateRows(fallsRiskFields, CHART_CHECK);
    const pressureInjuryCCRows = generateRows(pressureInjuryFields, CHART_CHECK);
    const wellbeingCCRows = generateRows(wellbeingCCFields, CHART_CHECK);
    const advancedCareCCRows = generateRows(advancedCareFields, CHART_CHECK);
    const allergyCCRows = getAllergyCCRows();
    const malnutritionRows = generateRows(malnutritionFields, TRIAGE);
    const cognitiveImpairmentRows = getCognitiveRows();
    const painTriageRows = getPainRows();
    const transportTriageRows = getTransportRows();
    const socialCircumstancesRows = getSocialRows();
    const wellbeingTriageRows = generateRows(wellbeingTriageFields, TRIAGE);

    const allRows = [
      basicInfoCCRows,
      chemoRiskCCRows,
      implantedDevicesCCRows,
      infectiousRiskCCRows,
      fallsRiskCCRows,
      pressureInjuryCCRows,
      wellbeingCCRows,
      advancedCareCCRows,
      allergyCCRows,
      malnutritionRows,
      cognitiveImpairmentRows,
      painTriageRows,
      transportTriageRows,
      socialCircumstancesRows,
      wellbeingTriageRows,
    ];

    const tableRows = allRows.filter((item) => item !== null && item.length);

    return (
      <InfoCard
        title="Risks Identified"
        subTitle="(from Initial Chart Check and Initial Triage)"
        rowDetails={tableRows.length ? tableRows : emptyTable}
        itemSize={tableRows.length ? 6 : 12}
      />
    );
  }
  return (
    <InfoCard
      title="Risks Identified"
      subTitle="(from Initial Chart Check and Initial Triage)"
      rowDetails={noChartCheck}
      itemSize={12}
    />
  );
};

export default SummaryTable;
