// eslint-disable-next-line no-use-before-define
import React, { Component } from 'react';

import './SummaryCard.scss';

interface Props {
  isActive?: boolean;
  children: React.ReactNode;
}

// This component is a wrapper over the summary page used in erego and quarantini.
// Add all newscard components within a single ROSummaryCard component.
// Its CSS adds the necessary styles for RO design.

class ROSummaryCard extends Component<Props> {
  public render(): JSX.Element {
    return (
      <div className="reg-summary-wrapper">
        <div className={'newspaper-container'}>{this.props.children}</div>
      </div>
    );
  }
}

export default ROSummaryCard;
