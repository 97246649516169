import React from 'react';
import { yesNoOptions } from 'op-utils';
import { sharedContent } from '../../../shared/constants';
import { ReviewBox } from 'shared-components/components';
import { Question, Answer, QuestionContainer } from './ReviewSharedComponents';

interface Props {
  fields: any;
  values: any;
  error: boolean;
  link: string;
  isLocked: boolean;
}

const convertValueToLabel = (value: any, arrayLabel?: any) => {
  for (const option of arrayLabel) {
    if (option.value === value) return option.label;
  }
};

const renderQuestionRow = (item: string, fields: any, values: any, index: number): JSX.Element => {
  return (
    <QuestionContainer aria-label={`review-${fields[item]}`} key={index}>
      <Question value={values[item]}>{fields[item]}</Question>
      <Answer value={values[item]}>
        {values[item] !== null
          ? convertValueToLabel(values[item], yesNoOptions)
          : sharedContent.generic.label.notProvided}
      </Answer>
    </QuestionContainer>
  );
};
const ReviewWellbeing = (props: Props): JSX.Element => {
  const { fields, values, error, link, isLocked } = props;

  return (
    <ReviewBox title={'Wellbeing'} error={error} link={link} isLocked={isLocked}>
      {Object.keys(fields).map((item: any, index: number) => {
        return renderQuestionRow(item, fields, values, index);
      })}
    </ReviewBox>
  );
};

export default ReviewWellbeing;
