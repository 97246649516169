import { gql } from '@apollo/client';

export const UX_FEATURE_QUERY = gql`
  query uxFeature($featureName: String!) {
    uxFeature(featureName: $featureName) {
      id
      name
    }
  }
`;

export const UX_FEATURE_OPTION_QUERY = gql`
  query uxFeatureOption($featureOptionName: String!) {
    uxFeatureOption(featureOptionName: $featureOptionName) {
      id
      name
      active
    }
  }
`;
