import { FieldProps, getIn } from 'formik';
import React, { useMemo } from 'react';
import { BaseAutocomplete, SelectOptionType } from '../FormFields';

interface Option {
  label: string;
  value: string;
}

interface SelectFieldInterface extends FieldProps {
  options: Option[];
  placeholder: string;
  disabled?: boolean;
  updateMutation: (value: string | null) => void;
}

const SelectField = (props: SelectFieldInterface): JSX.Element => {
  const { options, field, form, updateMutation, disabled = false, ...otherProps } = props;
  const { name, value } = field;
  const { errors, touched, submitCount, setFieldValue } = form;
  const fieldError = getIn(errors, name);
  const fieldTouched = getIn(touched, name);
  const isFieldInvalid = !!((submitCount > 0 || fieldTouched) && fieldError);
  const selectedValue = useMemo(() => options?.find((option: Option) => option.value === value), [value, options]);

  const updateField = (newValue: string) => {
    updateMutation(newValue || '');
    setFieldValue(name, newValue, true);
  };

  return (
    <>
      <BaseAutocomplete
        {...otherProps}
        id={name}
        data-testid={name}
        fullWidth
        value={selectedValue}
        onChange={(option: SelectOptionType | string) => {
          const value = typeof option === 'string' ? option : option?.value;
          updateField(value);
        }}
        inputProps={{
          error: isFieldInvalid,
          helperText: fieldError,
        }}
        disabled={disabled}
        options={options}
      />
    </>
  );
};

export default SelectField;
