import React, { useEffect } from 'react';
import { useFormikContext, Field, FormikProps } from 'formik';
import { styled } from '@mui/system';

import { InfoCard } from 'op-components';
import { InfoCardRowItem } from 'op-interfaces';
import { yesNoOptions } from 'op-utils';
import { HelperMessage, FormRow } from 'shared-components/components';
import { ToggleButtonGroupField, SelectFieldBase } from 'shared-components/components/FormikComponents';

import { getLastUpdateUser } from '../../helpers';
import { LastUpdateUserDetails } from '../../shared/interfaces';

import { choiceToStringOptions } from '../../helpers';
import { painScoreOptions, content } from '../constants';
import { HealthAssessmentData, PreCtTriageData } from '../interfaces/PreCtTriageInterfaces';

interface Props {
  handleMutation: (name: string, value: string) => void;
  healthAssessmentData: HealthAssessmentData;
  lastUpdateUserData: LastUpdateUserDetails[];
}

const StyledHelperMessageContainer = styled('div')`
  margin-top: 24px;
`;

const Pain = ({ handleMutation, healthAssessmentData, lastUpdateUserData }: Props): JSX.Element => {
  const { setFieldTouched, setFieldValue, values }: FormikProps<PreCtTriageData> = useFormikContext();
  const { fields, tableTitles } = content.pain;
  const { hasCurrentPain, isAbleToClimbStairs, isAbleToLieFlat, painScore } = fields;
  const { areYouInPain, pleaseSpecify } = tableTitles;
  const { painAssessmentQCL, significantPMHx } = content.pain.infoHelpers;

  let isTableVisible = false;
  const tableRows: InfoCardRowItem[][] = [];

  if (healthAssessmentData.healthAssessment) {
    const inPain = healthAssessmentData.healthAssessment.inPain;
    const inPainReason = healthAssessmentData.healthAssessment.inPainReason;

    const dataRow: InfoCardRowItem[] = [];

    if (inPain !== null) {
      dataRow.push({
        title: areYouInPain,
        //@ts-ignore
        value: choiceToStringOptions[String(inPain)],
      });
    }

    if (inPainReason) {
      dataRow.push({
        title: pleaseSpecify,
        value: inPainReason,
      });
    }

    isTableVisible = dataRow.length > 0;
    tableRows.push(dataRow);
  }

  useEffect(() => {
    if (values.hasCurrentPain === false && values.painScore) {
      handleMutation('painScore', '');
      setFieldValue('painScore', null);
      setFieldTouched('painScore', false);
    }
  }, [values.hasCurrentPain]);

  return (
    <>
      <FormRow fieldLabel={hasCurrentPain} fieldName={'hasCurrentPain'}>
        <Field
          name="hasCurrentPain"
          component={ToggleButtonGroupField}
          label={hasCurrentPain}
          options={yesNoOptions}
          handleMutation={handleMutation}
          lastUpdateUser={getLastUpdateUser(lastUpdateUserData, 'hasCurrentPain')}
        />
      </FormRow>
      {values.hasCurrentPain === true && (
        <FormRow fieldLabel={painScore} fieldName={'painScore'}>
          <Field
            name="painScore"
            component={SelectFieldBase}
            fitToContent
            grow="0"
            errorMargin="9px"
            label={painScore}
            options={painScoreOptions}
            placeholder=" "
            updateMutation={(value: string) => handleMutation('painScore', value)}
            lastUpdateUser={values.painScore && getLastUpdateUser(lastUpdateUserData, 'painScore')}
          />
          {+values.painScore >= 4 && (
            <HelperMessage fieldName={painScore} fieldText={painAssessmentQCL} helperType="info" />
          )}
        </FormRow>
      )}
      <FormRow fieldLabel={isAbleToLieFlat} fieldName={'isAbleToLieFlat'}>
        <Field
          name="isAbleToLieFlat"
          component={ToggleButtonGroupField}
          label={isAbleToLieFlat}
          options={yesNoOptions}
          handleMutation={handleMutation}
          lastUpdateUser={getLastUpdateUser(lastUpdateUserData, 'isAbleToLieFlat')}
        />
      </FormRow>
      <FormRow fieldLabel={isAbleToClimbStairs} fieldName={'isAbleToClimbStairs'}>
        <Field
          name="isAbleToClimbStairs"
          component={ToggleButtonGroupField}
          label={isAbleToClimbStairs}
          options={yesNoOptions}
          handleMutation={handleMutation}
          lastUpdateUser={getLastUpdateUser(lastUpdateUserData, 'isAbleToClimbStairs')}
        />
      </FormRow>
      <StyledHelperMessageContainer>
        <HelperMessage fieldName={isAbleToLieFlat} fieldText={significantPMHx} helperType="info" />
      </StyledHelperMessageContainer>
      {isTableVisible && <InfoCard title="Pain" subTitle="(Patient Reported)" rowDetails={tableRows} />}
    </>
  );
};

export default Pain;
