// eslint-disable-next-line no-use-before-define
import React, { Component } from 'react';
import Autosuggest from 'react-autosuggest';
import { Query } from '@apollo/client/react/components';
import { gql } from '@apollo/client';
import classNames from 'classnames';

import { Region } from 'shared-components/enums';
import { ListData, PractitionerListData } from 'shared-components/interfaces';
import './AsyncAutocompleteRO.scss';
const REACT_APP_REGION = import.meta.env.REACT_APP_REGION;

const region = REACT_APP_REGION;

interface Props {
  disabled?: boolean;
  inputName: string;
  placeholder: string;
  onBlur: (string: string) => void;
  preventNumberInput?: boolean;
  defaultValue: string;
  fullName?: boolean;
  errors?: string[];
  patient: string;
  grapheneField: string;
  dataTestId?: string;
}

interface State {
  value: string;
  selectedsuggestion?: string;
  suggestions: any[];
}

interface Practitioner {
  id: string;
  fullName: string;
  location?: string;
  qualification?: string;
}

interface PratitionersQueryResult {
  choices: Practitioner[];
}
class AsyncAutocompleteRO extends Component<Props, State> {
  public constructor(props: Props) {
    super(props);

    let initState: State = {
      value: '',
      suggestions: [],
    };
    if (this.props.defaultValue) {
      initState = {
        ...initState,
        value: this.props.defaultValue,
      };
    }

    this.state = initState;
  }

  private inputWidth = 0;
  private container: any;

  private onChange = (event: any, { newValue }: any) => {
    this.setState({
      value: newValue,
    });
    if (newValue === '') {
      this.setState({ selectedsuggestion: undefined });
    }
  };
  /**
   * Clears all the autosuggestions
   */
  private setSuggestionValue = (suggestion: ListData): string => {
    this.setState({
      selectedsuggestion: suggestion.id,
    });
    this.onBlur();
    return suggestion.name;
  };

  private renderSuggestion = (suggestion: ListData): JSX.Element => {
    if (['oncologists', 'extPractitioners'].includes(this.props.grapheneField) && region === Region.UK) {
      const pracObj = suggestion as PractitionerListData;
      const pracLocation = pracObj.location ? pracObj.location : 'Centre not available';
      return (
        <div style={{ width: this.inputWidth - 20 }} className="suggestion-container">
          <div className="suggestion-item">
            <div className="item-doc-name">
              {pracObj.name} {pracObj.qualification ? '(' + pracObj.qualification + ')' : ''}
            </div>
            <div className="item-doc-location">{pracLocation}</div>
          </div>
        </div>
      );
    } else {
      return (
        <div style={{ width: this.inputWidth - 20 }} className="suggestion-container">
          <div className="suggestion-item">{suggestion.name}</div>
        </div>
      );
    }
  };

  private renderInputComponent = (inputProps: any): JSX.Element => {
    return (
      <input
        {...inputProps}
        /* eslint-disable-next-line */
        ref={(input) => {
          // inputProps refs gets overwritten by calling the ref
          // So ensure its still set, this is used for window resizing.
          if (inputProps.ref) {
            inputProps.ref(input);
          }
          this.container = input;
        }}
      />
    );
  };
  private updateOnBlur = (): boolean => {
    if (this.state.selectedsuggestion === undefined) {
      const { suggestions, value } = this.state;

      // First check if there was only one suggestion displayed onBlur
      if (suggestions.length === 1) {
        // If suggested item exactly matches the text input item then set it as the selected suggestion
        if (suggestions[0].name.toLowerCase() === value.toLowerCase()) {
          return true;
        }
      }
    }

    return false;
  };

  private onBlur(): void {
    let currentIdValue = this.state.selectedsuggestion;
    let currentValue = this.state.value;
    if (this.updateOnBlur()) {
      this.setState({
        selectedsuggestion: this.state.suggestions[0].id,
        value: this.state.suggestions[0].name,
      });
      currentIdValue = this.state.suggestions[0].id;
      currentValue = this.state.suggestions[0].name;
    }

    if (!currentIdValue) {
      currentIdValue = currentValue;
    }
    this.props.onBlur(currentIdValue);
  }

  private onKeyPress(e: React.KeyboardEvent<any>): void {
    const isNumber: boolean = isNaN(Number(e.key.trim())) ? false : true;
    if (this.props.preventNumberInput && isNumber) {
      e.preventDefault();
    }
  }

  public render(): JSX.Element {
    const { value } = this.state;
    const { disabled, inputName, placeholder, patient, errors, grapheneField, fullName, dataTestId } = this.props;
    const maxLength = 100;
    const isExternalDocQuery = ['oncologists', 'extPractitioners'].includes(grapheneField) && region === Region.UK;
    const query = gql`
    query search ($search: String! $patient: ID!) {
      choices: ${grapheneField} (search: $search patient: $patient) {
        id
        ${fullName ? 'name: fullName' : 'name'}
        ${isExternalDocQuery ? 'location' : ''}
        ${isExternalDocQuery ? 'qualification' : ''}
      }
    }`;

    return (
      <Query<PratitionersQueryResult> query={query} variables={{ search: value, patient }}>
        {({ data, error }): JSX.Element => {
          if (error) return <div>error</div>;
          return (
            <div
              data-test-id={dataTestId || 'spaget'}
              className={classNames('async-autocomplete-ro-wrapper', {
                'validation-error': errors && errors.length > 0,
              })}>
              {
                //@ts-ignore
                <Autosuggest
                  suggestions={data?.choices || []}
                  onSuggestionsFetchRequested={() => {}}
                  onSuggestionsClearRequested={() => {}}
                  getSuggestionValue={this.setSuggestionValue}
                  renderSuggestion={this.renderSuggestion}
                  renderInputComponent={this.renderInputComponent}
                  inputProps={{
                    placeholder: placeholder,
                    name: inputName,
                    value,
                    // UPGRADE: Typescript 4 Upgrade - No longer available
                    // MARK: this doesn't exist (even with previous packages)
                    // selectedsuggestion,
                    maxLength,
                    onBlur: (_: React.FormEvent<any>): void => this.onBlur(),
                    onChange: (e: React.FormEvent<any>, changeEvent: any): void => this.onChange(e, changeEvent),
                    onKeyPress: (e: React.KeyboardEvent<any>): void => this.onKeyPress(e),
                    disabled,
                  }}
                />
              }
              <div>{errors}</div>
            </div>
          );
        }}
      </Query>
    );
  }
}

export default AsyncAutocompleteRO;
