import React, { useState, useEffect } from 'react';
import { RouteComponentProps, Link } from 'react-router-dom';

import LoginBox from './LoginBox';
import { HOME_CONTENT } from './homeContent';
import { InfoTile } from '../../Components';
import {
  GenesisCareLogoDesktopWhiteText,
  SecurityIcon,
  InformationIcon,
  MobileDeviceIcon,
  TabletDeviceIcon,
} from 'shared-components/images';

import './Home.scss';

const breakpointDesktop = 1200;

const Home: React.FC<RouteComponentProps> = ({ history }): JSX.Element => {
  const { HEADER, SUBHEADER, LOGIN_HEADER, INFO_TILE, TC_LINK } = HOME_CONTENT;

  const [viewportWidth, setViewportWidth] = useState(window.innerWidth);

  useEffect((): void => {
    window.addEventListener('resize', (): void => setViewportWidth(window.innerWidth));
  }, []);

  const handleToLogin = (event: React.FormEvent): void => {
    event.preventDefault();
    const searchParams = window.location.search;
    history.push(`/login${searchParams}`);
  };

  return (
    <div className="home-page">
      <div className="hero-container">
        {viewportWidth > breakpointDesktop ? (
          <GenesisCareLogoDesktopWhiteText />
        ) : (
          <LoginBox header={LOGIN_HEADER} handleClick={handleToLogin} />
        )}
      </div>
      <div className="sub-header-container">
        <div className="header-text">{HEADER}</div>
        <div className="sub-text">{SUBHEADER}</div>
      </div>
      <div className="details-container">
        {viewportWidth > breakpointDesktop && <LoginBox header={LOGIN_HEADER} handleClick={handleToLogin} />}
        <div className="info-container">
          <div className="info-col-1">
            <InfoTile name={INFO_TILE.ALL_DEVICES}>
              <MobileDeviceIcon />
            </InfoTile>
            <InfoTile name={INFO_TILE.APPOINTMENTS}>
              <TabletDeviceIcon />
            </InfoTile>
          </div>
          <div className="info-col-2">
            <InfoTile name={INFO_TILE.SAFE_SECURE}>
              <SecurityIcon />
            </InfoTile>
            <InfoTile name={INFO_TILE.INFORMATION}>
              <InformationIcon />
            </InfoTile>
          </div>
        </div>
        <div className="tc-link">
          <Link to={'/px/termsAndConditions'}>{TC_LINK}</Link>
        </div>
      </div>
    </div>
  );
};

export default Home;
