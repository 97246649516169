// eslint-disable-next-line no-use-before-define
import React, { Component } from 'react';
import { UKNavBar } from 'op-components';
import { RouteComponentProps, withRouter } from 'react-router-dom';
import './UKHomePage.scss';
import { gql } from '@apollo/client';
import { OPUser } from 'op-interfaces';
import { LoadingSpinner } from 'shared-components/components';
import { Query } from '@apollo/client/react/components';
import SSOPage from 'op-pages/Shared/SSOPage/SSOPage';

interface UserDetailsQueryData {
  user: OPUser;
}

export const USER_DETAILS_QUERY = gql`
  query UserDetails {
    user {
      id
      isPso
      firstName
      lastName
    }
  }
`;

interface State {
  isActive?: boolean;
}

interface Props extends RouteComponentProps {
  routes: any;
}

class UKHomePage extends Component<Props, State> {
  public constructor(props: Props) {
    super(props);
    this.state = {};
  }

  public render(): JSX.Element {
    const { routes } = this.props;
    return (
      <Query<UserDetailsQueryData> query={USER_DETAILS_QUERY}>
        {({ loading, data, error }): JSX.Element => {
          if (error) {
            return <SSOPage />;
          }
          if (loading) {
            return <LoadingSpinner />;
          }
          if (data && data.user) {
            // We have a user - render the page
            if (window.location.pathname.includes('/radiation')) {
              //open RO portal
              return routes;
            } else {
              //open Quarantini or eRego
              return (
                <div id="homepage-wrapper">
                  <div className="header-wrapper">
                    <UKNavBar />
                  </div>
                  <div className="body-wrapper">
                    <div className="content-wrapper">{routes}</div>
                  </div>
                </div>
              );
            }
          }
          return <div>Error</div>;
        }}
      </Query>
    );
  }
}

export default withRouter(UKHomePage);
