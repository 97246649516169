// eslint-disable-next-line no-use-before-define
import { CovidSubmit, ModalSaveExit, PatientFormFooter } from 'op-components';
import { NavigatorDirection } from 'op-enums';
import React, { Component, Fragment } from 'react';

import { Redirect, RouteComponentProps, withRouter } from 'react-router-dom';
import { StepperLink } from 'shared-components/interfaces';
import { FormContext } from '../../../pages/OP/PatientNavigation/context';
import { getNextForm } from '../../../pages/OP/PatientNavigation/helpers';
import './CovidNavigator.scss';

interface Props extends RouteComponentProps<{ patientId: string }> {
  links: StepperLink[];
  loading: boolean;
  submitCalled: boolean;
  submitFunction: (isPso?: boolean) => void;
  covidScreening: any;
  isPso?: boolean;
  showNewRego: boolean;
}

interface State {
  submitModalIsOpen: boolean;
  saveExitModalOpen: boolean;
}

class CovidNavigator extends Component<Props, State> {
  public static contextType = FormContext;

  public constructor(props: Props) {
    super(props);

    this.state = {
      submitModalIsOpen: false,
      saveExitModalOpen: false,
    };
  }

  public render(): JSX.Element {
    const {
      links,
      loading,
      submitCalled,
      submitFunction,
      location: { pathname },
      match: {
        params: { patientId },
      },
      isPso,
    } = this.props;

    const pathSegments = pathname.split('/');
    const currentPage = pathSegments[pathSegments.length - 1];

    let pageNumber: number | undefined = undefined;

    for (let i = 0; i < links.length; i++) {
      const link = links[i];

      const linkHref = link.href.replace(/\//, '');

      if (linkHref === currentPage) {
        pageNumber = i;
        break;
      }
    }

    const firstPage = pageNumber === 0;
    const lastPage = pageNumber === links.length - 1 || isPso === true;

    // Set up the submit button to display submitting for usability.
    let submitButtonText = 'Submit';
    if (loading) {
      submitButtonText = 'Submitting...';
    }

    //@ts-ignore
    const nextForm = getNextForm(this.context.formStatusDetails, patientId!, 'covid', this.props.showNewRego);

    if (submitCalled && !loading && !isPso) {
      return <Redirect to={{ pathname: nextForm['path'], state: { pxRedirect: true } }} />;
    }

    const covid = this.props.covidScreening;

    const symptomDates = covid.symptomDay && covid.symptomMonth && covid.symptomYear;
    const symptomIssue = covid.hasSymptoms && !symptomDates;
    const anyMissingFields =
      covid.hasBeenDiagnosed == null ||
      covid.hasBeenTested == null ||
      covid.advisedToQuarantine == null ||
      covid.hadContact == null ||
      covid.travelledToHotspot == null ||
      covid.hasSymptoms == null;
    const isComplete = !symptomIssue && !anyMissingFields;

    return (
      <Fragment>
        <ModalSaveExit
          isOpen={this.state.saveExitModalOpen}
          dismissFunction={this.dismissSNEModal}
          exitForm={this.exitForm}
          exitText={'Exit form'}
        />
        <CovidSubmit
          isComplete={isComplete}
          isOpen={this.state.submitModalIsOpen}
          dismissFunction={this.dismissModal}
          submitText={submitButtonText}
          submitForm={submitFunction}
          isPSO={isPso}
          continueNextForm={nextForm['name'] !== 'home'}
        />
        <PatientFormFooter
          navComponentId="covid-nav"
          isPso={isPso ?? false}
          isFirstPage={firstPage}
          isLastPage={lastPage}
          submitCalled={submitCalled}
          handleClickOnBack={() => this.handleBackButton(lastPage, pageNumber, isPso)}
          handleSaveAndExit={() => this.setState({ saveExitModalOpen: true })}
          handleClickOnContinue={() => this.handleContinue(lastPage, pageNumber, isPso, submitCalled, submitFunction)}
        />
      </Fragment>
    );
  }

  private buttonHandler = (currentPageIndex: number, direction: NavigatorDirection, lastPage: boolean): void => {
    const { history, links } = this.props;

    // On the last page, so call the submission instead
    if (lastPage && direction === NavigatorDirection.FORWARD) {
      this.setState({ submitModalIsOpen: true });
    }
    // Need to traverse based on direction
    else {
      let navigateTo = '#';
      let nextIndex = currentPageIndex;

      switch (direction) {
        case NavigatorDirection.FORWARD:
          nextIndex += 1;
          break;
        case NavigatorDirection.BACK:
          nextIndex -= 1;
          break;
        default:
          break;
      }

      if (nextIndex >= 0 && nextIndex <= links.length - 1) {
        navigateTo = links[nextIndex].href.replace(/\//, '');
        history.push(navigateTo);
      }
    }
  };

  private dismissModal = (): void => {
    this.setState({ submitModalIsOpen: false });
  };

  private dismissSNEModal = (): void => {
    this.setState({ saveExitModalOpen: false });
  };

  private exitForm = (): void => {
    const {
      history,
      isPso,
      match: {
        params: { patientId },
      },
    } = this.props;

    if (isPso) {
      history.push('/search');
    } else {
      history.push({ pathname: `/patient/${patientId}/home`, state: { pxRedirect: true } });
    }
  };

  private handleBackButton = (isLastPage: boolean, pageIndex?: number, isPso?: boolean) => {
    if (isPso) {
      window.history.back();
    }
    if (pageIndex !== undefined) {
      this.buttonHandler(pageIndex, NavigatorDirection.BACK, isLastPage);
    }
  };

  private handleContinue = (
    isLastPage: boolean,
    pageIndex?: number,
    isPso?: boolean,
    hasBeenSubmitted?: boolean,
    handleSubmit?: any,
  ) => {
    if (isPso && !hasBeenSubmitted) {
      handleSubmit(true);
    } else if (pageIndex !== undefined) {
      this.buttonHandler(pageIndex, NavigatorDirection.FORWARD, isLastPage);
    }
  };
}

const routedComponent = withRouter(CovidNavigator);
export default routedComponent;
