import { useTheme } from '@mui/material';
import { styled } from '@mui/system';

// To be replaced by version in GCUI - Design team are still working how they want to standardise these elements so this should be deprecated soon
type ColorNames = 'primary' | 'destructive' | 'information' | 'neutral';

export interface PillProps {
  borderColor?: ColorNames;
  backgroundColor?: ColorNames;
  fontColor?: ColorNames;
  onClick?: () => void;
  width?: string;
  padding?: string;
}

export const getBaseColor = (color: ColorNames): any => {
  const theme = useTheme();
  if (color == 'destructive') return theme.palette.error;
  if (color == 'information') return theme.palette.info;
  return theme.palette.primary;
};

export const Pill = styled('div')<PillProps>`
  border-width: 2px;
  border-style: solid;
  border-radius: 100px;
  padding: ${(props: PillProps) => props.padding ?? '6px'};
  text-align: center;
  max-width: ${(props: PillProps) => props.width ?? '999px'};
  font-weight: 600;
  :hover {
    cursor: ${(props: PillProps) => (props.onClick ? 'pointer' : 'default')};
  }
`;
