export const PHONE_NUMBER_REGEX =
  '^((\\+?61 ?[23478]|\\+?\\(61 ?[23478]\\)|\\(\\+?61 ?[23478]\\)|((\\+?61|\\+?\\(61\\)|\\(\\+?61\\)) ?[23478])|0[23478]|\\(0[23478]\\)))([ -]?[0-9]{8}|([ -][0-9]{4}){2}|[ -]?[0-9]{2}([ -][0-9]{3}){2})$|';

export const MOBILE_NUMBER_REGEX =
  '^((\\+?61 ?4|\\+?\\(61 ?4\\)|\\(\\+?61 ?4\\)|((\\+?61|\\+?\\(61\\)|\\(\\+?61\\)) ?4)|04|\\(04\\)))([ -]?[0-9]{8}|([ -][0-9]{4}){2}|[ -]?[0-9]{2}([ -][0-9]{3}){2})$|';

export const UK_PHONE_REGEX =
  '^(?:(?:\\(?(?:0(?:0|11)\\)?[\\s-]?\\(?|\\+)44\\)?[\\s-]?(?:\\(?0\\)?[\\s-]?)?)|(?:\\(?0))(?:(?:\\d{5}\\)?[\\s-]?\\d{4,5})|(?:\\d{4}\\)?[\\s-]?(?:\\d{5}|\\d{3}[\\s-]?\\d{3}))|(?:\\d{3}\\)?[\\s-]?\\d{3}[\\s-]?\\d{3,4})|(?:\\d{2}\\)?[\\s-]?\\d{4}[\\s-]?\\d{4}))(?:[\\s-]?(?:x|ext\\.?|\\#)\\d{3,4})?$';

export const EMAIL_REGEX = '^[a-zA-Z0-9\\_\\-\\.]+@[a-zA-Z0-9\\-\\.]+(\\.[a-zA-Z]+)+$|';

export const UK_POSTCODE_REGEX = '^[a-zA-Z]{1,2}[0-9][a-zA-Z0-9]? ?[0-9][a-zA-Z]{2}$';

export const NAME_REGEX = '[a-zA-Z0-9\\s_.,!"\'-]*';
export const NAME_REGEXP = /^[a-z ,.'-]+$/i;

export const UK_NAME_REGEX = "[A-Za-z\\s.,'-]*";

export const UK_CITY_REGEX = '[a-zA-Z\\s\\-]+|';

export const GENDER_IDENTIFICATION_DESCRIBE_REGEX = /^[a-zA-Z\s]*$/;

/**
 * Medicare Validation
 * https://stackoverflow.com/questions/3589345/how-do-i-validate-an-australian-medicare-number
 */
export const MEDICARE_REGEXP = /^\d{4}[-\s]?\d{5}[-\s]?\d{1}$/; // Format only. Still needs a separate logic for actual validity
export const MEDICARE_IRN_REGEXP = /\d/;

export const DVA_NUMBER_REGEXP =
  /[NVQWST](([a-zA-Z]{1}[0-9]{6})|([a-zA-Z]{2}[0-9]{5})|([a-zA-Z]{3}[0-9]{4})|([a-zA-Z]{4}[0-9]{3})|( [0-9]{6}))([a-zA-Z]|)|/;
export const MEDICARE_DVA_MONTH_REGEXP = /^[0-1]{1}[\d]{1}/;
export const MEDICARE_DVA_YEAR_REGEXP = /[1-2]{1}[\d]{3}/;

export const EMAIL_STRING_REGEX =
  /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[\d]{1,3}\.[\d]{1,3}\.[\d]{1,3}\.[\d]{1,3}\])|(([\w\-\d]+\.)+[\w]{2,}))$/is;
export const AU_POSTCODE_REGEXP = /^\d{4}$/;
export const US_POSTCODE_REGEXP = /\d{5}(?:[- ]?\d{4})?/;
export const UK_POSTCODE_REGEXP = /^[a-zA-Z]{1,2}[0-9][a-zA-Z0-9]? ?[0-9][a-zA-Z]{2}$/;
export const US_SSN_REGEX = new RegExp('^([0-9]{3} - [0-9]{2} - [0-9]{4}|[X]{3} - [X]{2} - [X]{4}|[0-9]{9})$');
export const US_PHONE_REGEX = new RegExp(
  /^(?:(?:\+?1\s*(?:[.-]\s*)?)?(?:\(\s*([2-9]1[02-9]|[2-9][02-8]1|[2-9][02-8][02-9])\s*\)|([2-9]1[02-9]|[2-9][02-8]1|[2-9][02-8][02-9]))\s*(?:[.-]\s*)?)?([2-9]1[02-9]|[2-9][02-9]1|[2-9][02-9]{2})\s*(?:[.-]\s*)?([0-9]{4})(?:\s*(?:#|x\.?|ext\.?|extension)\s*(\d+))?$/,
);
export const AU_PHONE_REGEX =
  /^(?:\+?(61))? ?(?:\((?=.*\)))?(0?[2-57-8])\)? ?(\d\d(?:[- ](?=\d{3})|(?!\d\d[- ]?\d[- ]))\d\d[- ]?\d[- ]?\d{3})$/;
export const MEDICARE_REGEX = new RegExp('^([0-9]{10}|[X]{10})$');
export const VIC_AMBULANCE_MEMBERSHIP_NUM_REGEXP = /^[\w\d-]+$/is;
