import { useMemo } from 'react';
import { useQuery } from '@apollo/client';
import { CAREPLAN_CYCLES_QUERY, MO_ADDITIONAL_INFO_QUERY, PRESCRIPTION_DATA_QUERY } from './Queries';
import { CareplanCycle } from './interfaces';

interface PrescriptionProps {
  careplanId: string;
}

export const usePrescription = (props: PrescriptionProps) => {
  const { careplanId } = props;
  const { data, loading } = useQuery(PRESCRIPTION_DATA_QUERY, { variables: { careplanId }, skip: !careplanId });
  const { data: additionalInfoData, loading: additionalInfoLoading } = useQuery(MO_ADDITIONAL_INFO_QUERY, {
    variables: { careplanId },
    skip: !careplanId,
    fetchPolicy: 'cache-and-network',
  });
  const { data: latestCyclesData, loading: cyclesLoading } = useQuery(CAREPLAN_CYCLES_QUERY, {
    variables: { careplanId },
    skip: !careplanId,
    fetchPolicy: 'cache-and-network',
  });

  const careplanAdditionalInfo = useMemo(() => {
    if (additionalInfoData?.moCareplanAdditionalInfo) {
      // Surely there's a better way to convert those dates to date objects
      const rawCycles = additionalInfoData?.moCareplanAdditionalInfo.cycleSchedule;
      const formattedCycles = rawCycles.map((cycle: any) => {
        return {
          ...cycle,
          days: cycle?.days?.map((day: any) => {
            return { ...day, date: new Date(day.date + 'T00:00:00Z') };
          }),
        };
      });

      return {
        ...additionalInfoData.moCareplanAdditionalInfo,
        cycleSchedule: formattedCycles,
        careplanName: data?.careplan?.careplanName,
      };
    }
    return {
      numberOfCycles: 0,
      daysPerCycle: 0,
      careplanName: '',
    };
  }, [additionalInfoData, data]);

  const doses = useMemo(() => {
    if (data?.moCareplanDoses) {
      return data.moCareplanDoses;
    }
    return [];
  }, [data]);

  const drugOrders = useMemo(() => {
    if (data?.moCareplanDrugOrders) {
      return data.moCareplanDrugOrders;
    }
    return {};
  }, [data]);

  const cycles: CareplanCycle[] = useMemo(() => {
    if (latestCyclesData?.latestCycles) {
      return latestCyclesData.latestCycles;
    }
    return [];
  }, [latestCyclesData]);

  return {
    careplanAdditionalInfo,
    doses,
    drugOrders,
    cycles,
    loading: loading || cyclesLoading || additionalInfoLoading,
  };
};

export default usePrescription;
