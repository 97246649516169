import { DTNavigatorApollo, HeaderBar } from 'op-components';
import React, { Fragment, useEffect, useState } from 'react';
import { useQuery } from '@apollo/client';

import { Redirect, Route, RouteComponentProps, Switch, withRouter } from 'react-router-dom';
import { StepperLink } from 'shared-components/interfaces';
import DTInformation from '../DTInformation/DTInformation';
import DTQuestions from '../DTQuestions/DTQuestions';
import DTRatingApollo from '../DTRating/DTRatingApollo';
import { DTContextProvider } from '../DTContext';
import DISTRESS_THERMOMETER_VERSION from './DTContainerQuery';
import './DTContainer.scss';

interface Props extends RouteComponentProps<{ patientId: string; distressId: string }> {}

const DTContainer = (props: Props): JSX.Element => {
  const [visitedPages, setVisitedPages] = useState<Record<string, number>>({});
  const {
    location: { pathname },

    match: {
      path,
      params: { patientId, distressId },
    },
  } = props;

  const { data } = useQuery(DISTRESS_THERMOMETER_VERSION, { variables: { patientId, id: distressId } });
  const distressVersion = data?.distressThermometer?.version;
  useEffect((): void => {
    // Get the current form page
    const pathSegments = pathname.split('/');
    const currentPage = pathSegments[pathSegments.length - 1];
    // Add to state if it's not there.
    if (!Object.keys(visitedPages).includes(currentPage)) {
      setVisitedPages({ ...visitedPages, [currentPage]: 0 });
    } else {
      setVisitedPages({ ...visitedPages, [currentPage]: visitedPages[currentPage] + 1 });
    }
  }, [pathname]);

  const PAGE_LINKS: StepperLink[] = [
    {
      linkText: 'Distress thermometer Information',
      href: '/information',
    },
    {
      linkText: `Distress thermometer ${distressVersion === 'EOT' ? 'EOT' : ''}`,
      href: '/rating',
    },
    {
      linkText: 'Physical concerns',
      href: '/physical',
    },
    {
      linkText: 'Emotional concerns',
      href: '/emotional',
    },
    {
      linkText: 'Social concerns',
      href: '/social',
    },
    {
      linkText: 'Practical concerns',
      href: '/practical',
    },
    {
      linkText: 'Spiritual/Religious concerns',
      href: '/spiritual',
    },
    {
      linkText: 'Other concerns',
      href: '/other',
    },
  ];

  const BASE_PATH = `/patient/${patientId}/distress/${distressId}`;
  return (
    <DTContextProvider>
      <Fragment>
        <HeaderBar headingText={`Distress Thermometer${distressVersion === 'EOT' ? ' EOT' : ''}`} menuButton={true} />
        <div id="dt-container" className="page-container full-viewport">
          <Switch>
            <Route path={`${path}/information`}>
              <DTInformation />
            </Route>
            <Route path={`${path}/rating`}>
              <DTRatingApollo />
            </Route>
            {/* This route will take it to the inner container that will be used in later stories. Currently just leave this here for it. */}
            <Route path={`${path}/:ratingPage`}>
              <DTQuestions stepperLinks={PAGE_LINKS} stepperBasePath={BASE_PATH} visitedPages={visitedPages} />
            </Route>
            {/* This is the catch all, where it currently it will just take the user back to login, however should reconsider to an error page instead. */}
            <Route path={`${path}`}>
              <Redirect to="/patient" />
            </Route>
            <Route></Route>
          </Switch>
          <DTNavigatorApollo links={PAGE_LINKS} />
        </div>
      </Fragment>
    </DTContextProvider>
  );
};

const routedComponent = withRouter(DTContainer);
export default routedComponent;
