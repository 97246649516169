// eslint-disable-next-line no-use-before-define
import React, { Component } from 'react';
import './AppointmentsListingEmpty.scss';

import { LoadingSpinner } from 'shared-components/components';

// =========
// CONSTANTS
// =========
const LOADING_MESSAGE = 'Loading';
const NO_APPOINTMENTS = 'No upcoming appointments';

// ==========
// INTERFACES
// ==========
interface Props {
  isLoading: boolean;
}

class AppointmentsListingEmpty extends Component<Props> {
  /**
   * Function will select the correct message to display for loading message.
   */
  private selectMessage = (): string | JSX.Element => {
    if (this.props.isLoading) {
      return <LoadingSpinner loadingText={LOADING_MESSAGE} relativeSpinner={true} />;
    }

    return NO_APPOINTMENTS || '';
  };

  public render(): JSX.Element {
    return <div className="appointments-listing-empty-message">{this.selectMessage()}</div>;
  }
}

export default AppointmentsListingEmpty;
