import React, { Dispatch, SetStateAction, useRef, useEffect } from 'react';
import { styled } from '@mui/system';
import { DATATYPE } from 'shared-components/components/UIFormComponents/SimpleTable';
import Table from '@mui/material/Table';
import { wrapURLs } from '../../../utils/helpers';
import { LabPanelType, ObservationType } from './Interfaces';
import { getRemSize } from 'shared-components/StyledComponents/functions';
import { useWindowSize } from 'shared-components/utils/CustomHooks';
import {
  DescriptionOutlined as DescriptionOutlinedIcon,
  Close as CloseIcon,
  Warning as WarningIcon,
} from '@mui/icons-material';
import { Stack } from '@mui/material';

interface Props {
  activeResult?: LabPanelType;
  activeDocument?: string;
  setActiveDocument: Dispatch<SetStateAction<string | any>>;
  testId?: string;
}

interface StyledTableRowProps {
  readonly active?: boolean;
  readonly clickable?: boolean;
  key: number;
}

const StyledTableContainer = styled('div')`
  flex: 1;
`;

const StyledTable = styled(Table)`
  border-collapse: separate;
  margin-bottom: 0;

  tbody tr:last-child td {
    border-bottom: 1px solid ${(props) => props.theme.palette.grey[300]};
  }

  & td {
    padding: 7px 12px;
    border-right: 1px solid ${(props) => props.theme.palette.grey[300]};
    text-align: left;

    &:first-child {
      border-left: 1px solid ${(props) => props.theme.palette.grey[300]};
    }
  }

  & thead {
    & th {
      position: sticky;
      top: 0;
      text-align: left;
      padding: 8px 8px;
      border-right: 1px solid ${(props) => props.theme.palette.grey[300]};
      background-color: ${(props) => props.theme.palette.grey[100]};
      &:first-child {
        border-left: 1px solid ${(props) => props.theme.palette.grey[300]};
      }
    }
  }
`;

const StyledDetailsTableContainer = styled('div')`
  position: sticky;
  top: 0;
  right: 0;
`;

const StyledDetailsTable = styled(StyledTable)`
  height: 100%;
  border-left: none;
  margin-bottom: 0;
  table-layout: fixed;
`;

const StyledTableWrapper = styled('div')`
  display: flex;
  width: 100%;
  overflow: auto;
  flex: 1;
`;

const StyledTableRow = styled('tr')<StyledTableRowProps>`
  cursor: ${(props: StyledTableRowProps) => (props.clickable ? 'pointer' : 'default')} !important;

  & td {
    border-top: ${(props) => (props.active ? `2px solid ${props.theme.palette.primary.dark}` : '')};
    border-bottom: ${(props) => (props.active ? `2px solid ${props.theme.palette.primary.dark} !important` : 'none')};

    &:last-of-type {
      border-right: ${(props) => (props.active ? `2px solid ${props.theme.palette.primary.dark}` : '')};
      border-radius: ${(props: StyledTableRowProps) => (props.active ? '0 4px 4px 0' : '')};
    }

    &:first-of-type {
      border-left: ${(props) => (props.active ? `2px solid ${props.theme.palette.primary.dark}` : '')};
      border-radius: ${(props: StyledTableRowProps) => (props.active ? '4px 0 0 4px' : '')};
    }
  }
`;

const DetailsCell = styled('td')`
  font-size: ${getRemSize(16)};
  line-height: 24px;
  padding: 0 !important;

  &:hover {
    background: white;
    cursor: default;
  }

  & > div {
    padding: 7px 12px;
  }

  & a {
    text-decoration: underline;
    color: ${(props) => props.theme.palette.info.main};

    &:hover {
      text-decoration: none;
    }
  }

  & font {
    & > * {
      font-size: 16px !important;
    }
  }
`;

const DetailTable = ({ activeResult, setActiveDocument, activeDocument, testId }: Props): JSX.Element => {
  const tableBody = useRef<HTMLTableSectionElement>(null);
  const windowSize = useWindowSize();
  const tableHeaders = [
    { title: 'Test', key: 'loincCode', type: DATATYPE.STRING },
    { title: 'Result', key: 'resultValue', type: DATATYPE.STRING },
    { title: 'Units', key: 'unitOfMeasure', type: DATATYPE.STRING },
    { title: 'Normal', key: 'referenceRange', type: DATATYPE.STRING },
    { title: 'Criticality', key: 'abnormalFlags', type: DATATYPE.STRING },
    { title: 'Description', key: 'description', type: DATATYPE.STRING },
  ];
  const defaultKeys = tableHeaders.map((header) => header.key);
  const activeDocumentKeys = ['loincCode', 'resultValue'];

  const renderRows = activeResult?.observations?.map((observation: ObservationType, index): JSX.Element => {
    const keys = activeDocument ? activeDocumentKeys : defaultKeys;
    const isDocumentRow = observation.cleanedNote !== '';
    const styledProps = {
      active: Boolean(isDocumentRow && activeDocument === observation.cleanedNote),
      clickable: Boolean(isDocumentRow && activeDocument),
    };

    return (
      <StyledTableRow
        key={index}
        {...styledProps}
        onClick={(): void => {
          if (activeDocument && isDocumentRow) {
            setActiveDocument(styledProps.active ? undefined : observation.cleanedNote);
          }
        }}>
        {keys.map((key, indexTwo): JSX.Element | undefined => (
          <td key={index + indexTwo} style={{ verticalAlign: 'middle' }}>
            <Stack direction="row" gap="6px" alignItems="center">
              {key !== 'resultValue' || (key === 'resultValue' && !isDocumentRow)
                ? observation[key as keyof typeof observation] !== ''
                  ? observation[key as keyof typeof observation]
                  : '-'
                : null}
              {key === 'resultValue' && isDocumentRow ? (
                <DescriptionOutlinedIcon
                  color="primary"
                  cursor="pointer"
                  onClick={(): void => setActiveDocument(styledProps.active ? undefined : observation.cleanedNote)}
                />
              ) : null}
              {key === 'abnormalFlags' && (observation[key] === 'HH' || observation[key] === 'LL') && (
                <WarningIcon color="warning" />
              )}
            </Stack>
          </td>
        ))}
      </StyledTableRow>
    );
  });

  const setRowHeights = () => {
    const container = tableBody?.current;

    if (container && !activeDocument) {
      const rows = container.querySelectorAll('tr');
      rows.forEach((row) => {
        // Reset height before getting new height
        row.style.height = 'auto';
        row.style.height = `${row.offsetHeight}px`;
      });
    }
  };

  useEffect(setRowHeights, []);
  useEffect(setRowHeights, [windowSize]);

  return (
    <StyledTableWrapper>
      <StyledTableContainer>
        <StyledTable data-test-id={testId}>
          <thead>
            <tr>
              {tableHeaders.map((head, index) =>
                activeDocument && !activeDocumentKeys.includes(head['key']) ? null : (
                  <th key={`header-${index}`}>{head['title']}</th>
                ),
              )}
            </tr>
          </thead>
          <tbody data-test-id={`${testId}-body`} ref={tableBody}>
            {renderRows}
          </tbody>
        </StyledTable>
      </StyledTableContainer>
      {activeDocument && (
        <StyledDetailsTableContainer>
          <StyledDetailsTable>
            <thead>
              <tr>
                <th style={{ padding: '5px 8px' }}>
                  <Stack direction="row" alignItems="center" gap="4px">
                    Details
                    <CloseIcon
                      fontSize="small"
                      color="primary"
                      cursor="pointer"
                      data-testid="close-details-labs"
                      onClick={(): void => setActiveDocument(undefined)}
                    />
                  </Stack>
                </th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <DetailsCell style={{ height: 'fit-content' }}>
                  <div style={{ maxHeight: '500px', overflowY: 'auto' }}>
                    <div
                      data-test-id={`${testId}-body-notes`}
                      dangerouslySetInnerHTML={{ __html: wrapURLs(activeDocument, true) }}></div>
                  </div>
                </DetailsCell>
              </tr>
            </tbody>
          </StyledDetailsTable>
        </StyledDetailsTableContainer>
      )}
    </StyledTableWrapper>
  );
};

export default DetailTable;
