// eslint-disable-next-line no-use-before-define
import React from 'react';
import CareplanContainer from 'op-pages/RO/Careplan/Container';
import dayjs from 'dayjs';
import { ROTextField, RODatePicker, ROAutocomplete, ROToggleButtons } from 'shared-components/components/FormFields';
import { FormikProps } from 'formik';
import ROPatientCarePlanPageFooter from 'op-pages/RO/Careplan/Footer';
import AutoSave from 'shared-components/components/FormikComponents/AutoSave';
import { Dayjs } from 'dayjs';
import { SelectOptionType } from 'shared-components/components/FormFields';
export interface FormValues {
  readyForCareDate: Date;
  treatingDepartment: SelectOptionType | undefined | null;
  intent: SelectOptionType | undefined | null;
  patientAllergies: string;
  patientAllergiesSpecify: string;
}

export interface TreatmentInfoPanelExtraProps {
  intentOptions: SelectOptionType[];
  treatingDepartmentOptions: SelectOptionType[];
  updateCallback: (values: FormValues) => void;
}

const TreatmentInfoForm = (props: TreatmentInfoPanelExtraProps & FormikProps<FormValues>): JSX.Element => {
  const { values, touched, errors, setFieldValue, setFieldTouched, handleSubmit } = props;
  return (
    <div className="main-container-parent-wrapper">
      <div className="main-container-wrapper">
        <CareplanContainer>
          <div className="page-header">
            <h1>{'Treatment Information'}</h1>
            <div>{'Please enter treatment information'}</div>
          </div>
          <RODatePicker
            id="readyForCareDate"
            fieldlabel="Ready for Care Date"
            required
            error={Boolean(touched.readyForCareDate && errors?.readyForCareDate)}
            value={values.readyForCareDate ? dayjs(values.readyForCareDate) : null}
            onChange={async (date: Dayjs | null, context: any) => {
              if (context.validationError) return;
              const dateString = date ? date.format('YYYY-MM-DD').toString() : '';
              await setFieldValue('readyForCareDate', dateString, true);
              setFieldTouched('readyForCareDate');
            }}
          />
          <ROAutocomplete
            id="intent"
            fieldlabel="Intent"
            options={props.intentOptions}
            value={values.intent}
            required
            inputProps={{
              helperText: 'Please select intent',
            }}
            onChange={(option: SelectOptionType | string): void => {
              setFieldValue('intent', option, true);
              setFieldTouched('intent');
            }}
          />
          <ROAutocomplete
            id="treatingDepartment"
            fieldlabel="Treating Department"
            options={props.treatingDepartmentOptions}
            value={values.treatingDepartment}
            required
            inputProps={{
              helperText: 'Please select treating department',
            }}
            onChange={(option: SelectOptionType | string): void => {
              setFieldValue('treatingDepartment', option, true);
              setFieldTouched('treatingDepartment');
            }}
          />
          <ROToggleButtons
            id="patientAlleries"
            fieldlabel={'Patient Allergies'}
            required
            value={values.patientAllergies}
            options={[
              { label: 'Yes', value: 'Yes' },
              { label: 'Nil known allergies', value: 'Nil known allergies' },
            ]}
            handleChange={(value) => {
              setFieldValue('patientAllergies', value, true);
              setFieldTouched('patientAllergies');
              if (value !== 'Yes') {
                setFieldValue('patientAllergiesSpecify', '', true);
              }
            }}
            helperText={'Please select patient allergies'}
            error={Boolean(touched.patientAllergies && errors.patientAllergies)}
          />
          {values.patientAllergies === 'Yes' && (
            <ROTextField
              id="allergiesSpecify"
              fieldlabel="Please specify"
              placeholder="Please specify allergies details..."
              required
              rows={4}
              value={values.patientAllergiesSpecify}
              onBlur={async (event: React.FocusEvent<HTMLInputElement>) => {
                setFieldValue('patientAllergiesSpecify', event.target.value, true);
                setFieldTouched('patientAllergiesSpecify');
              }}
              helperText="Please specify patient allergies"
              error={Boolean(touched.patientAllergiesSpecify && errors.patientAllergiesSpecify)}
              multiline
              maxRows={4}
            />
          )}
        </CareplanContainer>
        <ROPatientCarePlanPageFooter
          onBack={(): void => {}}
          onNext={(): void => {
            handleSubmit();
          }}
          backDisabled={false}
          backButtonId={'treatment-info-back-button'}
        />
        <AutoSave saveMethod={props.updateCallback} />
      </div>
    </div>
  );
};

export default TreatmentInfoForm;
