import { gql } from '@apollo/client';

// Elasticsearch logs event
export const LOG_EVENT = gql`
  mutation LogEvent($data: String!) {
    logEvent(data: $data) {
      success
    }
  }
`;
