// eslint-disable-next-line no-use-before-define
import React, { useState, useEffect } from 'react';
import { useMutation } from '@apollo/client';
import { Patient } from 'op-interfaces';

import { DropDownField } from 'shared-components/components/FormFields';
import { ListData } from 'shared-components/interfaces';
import { UPDATE_CENTER_MUTATION } from '../../../PatientCard/PatientCardQueries';

import './RegistrationHomeClinic.scss';

interface Props {
  patient: Patient;
  locationListData: ListData[];
  horizonCenterId: string;
  isHomeRego: boolean;
  stateHandler?: (selection: string | undefined) => void;
  validationObject?: any;
  disabled?: boolean;
}

const RegistrationHomeClinic = (props: Props): JSX.Element => {
  const { locationListData, validationObject, disabled, isHomeRego, stateHandler, patient } = props;
  const [selectedPrimaryDepartment, setSelectedPrimaryDepartment] = useState('');
  const [saveHorizonCenterId] = useMutation(UPDATE_CENTER_MUTATION);

  const changeHandler = (value: string) => {
    if (isHomeRego) {
      if (stateHandler) {
        stateHandler(value);
      }
    }
    saveHorizonCenterId({ variables: { id: patient.id, horizonCenterId: value } });
    setSelectedPrimaryDepartment(value);
  };

  useEffect(() => {
    if (horizonCenterId === null && locationListData.length === 1) {
      changeHandler(locationListData[0].id);
    }
  }, [locationListData]);

  // cast it to string for the default option selection
  const horizonCenterId = props.horizonCenterId ? props.horizonCenterId.toString() : props.horizonCenterId;

  const selectedValue: string = selectedPrimaryDepartment ? selectedPrimaryDepartment : horizonCenterId;

  return (
    <div className="registration-home-clinic">
      <div className="home-clinic-title">{'Patient Primary Department'}</div>
      <div className="registration-home-clinic-dropdown">
        <DropDownField
          disabled={disabled}
          inputKey={selectedPrimaryDepartment}
          inputName="patient-home-clinic"
          placeholder={'Please select'}
          defaultValue={selectedValue}
          options={locationListData}
          onChange={(e): void => {
            changeHandler(e.target.value);
          }}
          errors={
            validationObject && validationObject.primaryDepartment && !selectedPrimaryDepartment
              ? validationObject.primaryDepartment
              : undefined
          }
        />
      </div>
    </div>
  );
};

export default RegistrationHomeClinic;
