import { ListData } from 'shared-components/interfaces';

export const DASHBOARD_URL = '/search';
export const TITLE = 'Basic Details';
export const SUBTITLE = 'Please enter patient basic details.';
export const SUBMIT_BUTTON_TEXT = 'Create patient';
export const GENDER_OPTIONS = [
  { label: 'Female', value: 'Female' },
  { label: 'Male', value: 'Male' },
  { label: 'Other', value: 'Other' },
];
export const US_GENDER_OPTIONS = [
  { label: 'Female', value: 'Female' },
  { label: 'Male', value: 'Male' },
  { label: 'Unknown', value: 'Unknown' },
];
export const VALUE_REQUIRED = 'This field is required';
export const PHONE_VALIDATION = 'Please enter a valid phone number';
export const SEARCH_DROPDOWN_PLACEHOLDER = 'Start typing or choose from dropdown...';
export const PHONE_REQUIRED = 'Enter at least one phone number';
export const ADDRESS_LINE1_REQUIRED = 'Please fill in Address Line 1';

interface SelectOptions {
  label: string;
  value: string;
}

export const mapListDataWithId = (listData: ListData[]): SelectOptions[] =>
  listData?.map((data: ListData) => ({ label: data.name, value: data.id })) || [];
