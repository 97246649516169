import React from 'react';
import { styled } from '@mui/system';
import { ErrorExclamation, Edit, Success } from 'shared-components/images';
import { Link } from 'react-router-dom';

interface Props {
  title: string;
  subtitle?: string;
  error?: boolean;
  children?: any;
  link?: string;
  isLocked: boolean;
}

interface ErrorProps {
  error?: boolean;
  isLocked: boolean;
}

const ReviewWrapper = styled('div')<ErrorProps>`
  padding-left: 0;
  padding-right: 0;
  display: flex;
  flex-direction: column;
  margin-bottom: 20px;
  box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.2);
  border-top: ${(props: ErrorProps): string =>
    props.isLocked || !props.error
      ? `4px solid ${(props: any) => props.theme.palette.primary.main}`
      : `4px solid ${(props: any) => props.theme.palette.error.main}`};
  border-radius: 4px;
`;

const HeaderContainer = styled('div')`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  margin-top: 20px;
  padding-bottom: 20px;
  border-bottom: 2px solid ${(props) => props.theme.palette.grey[300]};
`;

const TitleContainer = styled('div')`
  display: flex;
  flex-direction: column;
`;

const Title = styled('div')`
  margin-left: 24px;
  font-size: 20px;
  font-weight: bold;
  line-height: 28px;
  flex-grow: 1;
`;

const Subtitle = styled('div')`
  margin-top: 10px;
  margin-left: 24px;
  font-weight: bold;
  font-size: 14px;
  line-height: 20px;
  width: 70%;
`;

const StyledLink = styled(Link)`
  text-decoration: none;
  &:focus,
  &:hover,
  &:visited,
  &:link,
  &:active {
    text-decoration: none;
  }
  color: black;
`;

const EditContainer = styled('div')`
  display: flex;
  flex-direction: row;
  align-items: center;
  margin-right: 16px;
`;

const EditIcon = styled(Edit)`
  fill: ${(props) => props.theme.palette.primary.main};
  width: 24px;
  height: 24px;
  margin-right: 8px;
`;

const EditMessage = styled('div')`
  font-weight: bold;
  font-size: 14px;
  line-height: 20px;
  margin-right: 16px;
`;

const SuccessIcon = styled(Success)`
  fill: ${(props) => props.theme.palette.primary.main};
  width: 24px;
  height: 24px;
`;

const ErrorIcon = styled(ErrorExclamation)`
  fill: ${(props) => props.theme.palette.error.main};
  width: 24px;
  height: 24px;
`;

const ContentContainer = styled('div')`
  margin-bottom: 27px;
  margin-top: 27px;
`;

const ReviewBox = (props: Props): JSX.Element => {
  const { title, subtitle, error, children, link, isLocked } = props;
  return (
    <>
      <ReviewWrapper error={error} data-test-id={`review-${title}`} isLocked={isLocked}>
        <HeaderContainer>
          <TitleContainer>
            <Title>{title}</Title>
            {subtitle && <Subtitle>{subtitle}</Subtitle>}
          </TitleContainer>
          {link && !isLocked && (
            <StyledLink to={link}>
              <EditContainer>
                <EditIcon />
                <EditMessage>Edit</EditMessage>
                {error ? <ErrorIcon /> : <SuccessIcon />}
              </EditContainer>
            </StyledLink>
          )}
        </HeaderContainer>
        <ContentContainer>{children}</ContentContainer>
      </ReviewWrapper>
    </>
  );
};

export default ReviewBox;
