// eslint-disable-next-line no-use-before-define
import React, { Component, Fragment } from 'react';
import { RouteComponentProps, withRouter } from 'react-router';

import Content from './Content';

import { STATIC_CONTENT } from './staticContent';

//changes
interface Props extends RouteComponentProps<{ pageId?: string }> {
  contentShown: boolean;
  setContentShown: (to: boolean) => void;
}

class ContentWrapper extends Component<Props> {
  public render(): JSX.Element {
    const { match, contentShown, setContentShown } = this.props;
    const { pageId } = match.params;

    const data = STATIC_CONTENT.find((obj) => obj.page_id == pageId);

    return (
      <Fragment>
        <Content
          data={data}
          contentShown={contentShown}
          setContentShown={setContentShown}
          pageCategory={pageId ? pageId.split('-')[0] : undefined}
        />
        {!data && <div>Error!</div>}
      </Fragment>
    );
  }
}

const routedComponent = withRouter(ContentWrapper);
export default routedComponent;
