// eslint-disable-next-line no-use-before-define
import React, { Component, Fragment } from 'react';

import './MiniRegoHeader.scss';

interface Props {
  title: string;
  summary: string;
}

class ROMiniRegoHeader extends Component<Props> {
  public render(): JSX.Element {
    const { title, summary } = this.props;

    return (
      <Fragment>
        <p data-test-id="page-title" className="minirego-page-title">
          {title}
        </p>
        <p className="minirego-page-summary">{summary}</p>
      </Fragment>
    );
  }
}

export default ROMiniRegoHeader;
