import { Typography, useTheme } from '@mui/material';
import { styled } from '@mui/system';
import React from 'react';

export const CardTitle = ({ children }: { children: React.ReactNode }) => {
  const theme = useTheme();
  return (
    <Typography
      justifyContent="space-between"
      alignItems="center"
      sx={{ display: 'flex', borderBottom: `1px solid ${theme.palette.grey[300]}` }}
      padding={'8px 16px'}
      variant="subtitle1">
      {children}
    </Typography>
  );
};

export const CardBody = styled('div')`
  height: 300px;
  overflow: auto;
`;

export const CardBodyGroup = styled('div')`
  margin: 8px 16px;
  font-size: 14px;
  line-height: 20px;
`;

export const CardBodyBold = styled('div')`
  font-weight: bold;
`;

export const BubbleTitle = styled('div')`
  background-color: ${(props) => props.theme.palette.grey[100]};
  display: inline-block;
  line-height: 20px;
  border-radius: 16px;
  padding: 0 8px;
  margin-bottom: 4px;
  font-size: 14px;
  font-weight: bold;
`;

export const CardText = styled('div')`
  font-size: 13px;
  line-height: 15px;
`;
