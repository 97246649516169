// eslint-disable-next-line no-use-before-define
import React from 'react';
import { Stack, CircularProgress, Typography } from '@mui/material';
import { Redirect, useHistory } from 'react-router-dom';
import { useQuery } from '@apollo/client';
import { GET_USER_PROFILE } from 'op-graphql/queries';

const SSOPage = () => {
  const history = useHistory();
  const { data, loading } = useQuery(GET_USER_PROFILE);

  if (loading) {
    return (
      <Stack width={1} height={1} justifyContent="center" direction="row" padding={3} alignItems="center" gap={2}>
        <CircularProgress color="primary" />
        <Typography>Redirecting...</Typography>
      </Stack>
    );
  }

  if (data?.user) {
    // Redirect doesn't work here for some reason
    window.location.replace('/server/auth/redirect');
  }

  const pathName = history.location.pathname;
  const next = pathName === '/' ? 'server/auth/redirect' : pathName;
  // If not logged in as a user then go to sso login
  return <Redirect to={`/sso/login?next=/${next}`} />;
};

export default SSOPage;
