import React, { useState, useEffect } from 'react';
import { Dialog, DialogTitle, DialogContent, DialogActions, Button, CircularProgress, Box } from '@mui/material';

interface PDFPreviewModalProps {
  open: boolean;
  onClose: () => void;
  url: string;
}

const PDFPreviewModal: React.FC<PDFPreviewModalProps> = ({ open, onClose, url }) => {
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    setLoading(true); // Reset loading state when URL changes
  }, [url, open]);

  const handleIframeLoad = () => {
    setLoading(false);
  };

  return (
    <Dialog open={open} onClose={onClose} maxWidth="xl" sx={{ minHeight: '95vh', height: '95vh' }} fullWidth>
      <DialogTitle>PDF Preview</DialogTitle>
      <DialogContent>
        {loading && (
          <Box display="flex" justifyContent="center" alignItems="center" minHeight="75vh">
            <CircularProgress />
          </Box>
        )}
        <iframe
          src={url}
          width="100%"
          title="PDF Preview"
          onLoad={handleIframeLoad}
          style={{ display: loading ? 'none' : 'block', minHeight: '75vh' }}
        />
      </DialogContent>
      <DialogActions>
        <Button variant="text" onClick={onClose} color="primary">
          CLOSE
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default PDFPreviewModal;
