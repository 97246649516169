// eslint-disable-next-line no-use-before-define
import React from 'react';
import { gql } from '@apollo/client';
import Markdown from 'markdown-to-jsx';
import { Query } from '@apollo/client/react/components';

import './InformationNotice.scss';
import { LoadingSpinner } from 'shared-components/components';
import { useErrorModalContext } from 'op-contexts';

interface InfoNoticeQuery {
  loading: boolean;
  error?: string[];
  data: {
    informationNoticeContent: {
      id: string;
      informationNotice: string;
    };
  };
}

interface InfoNoticeType {
  informationNoticeContent: {
    id: string;
    informationNotice: string;
  };
}

const GET_TERMS_AND_CONDITIONS = gql`
  query TermsAndConditionsQuery {
    informationNoticeContent {
      id
      informationNotice
    }
  }
`;

const InformationNotice = (): JSX.Element => {
  const { setError } = useErrorModalContext();
  return (
    <Query<InfoNoticeType> query={GET_TERMS_AND_CONDITIONS} variables={{ language: 'en' }}>
      {({ loading, error, data }): JSX.Element => {
        if (error) return setError();
        if (loading) return <LoadingSpinner loadingText="Loading..." />;

        const OpenLinkInNewTab = ({ ...props }) => <a {...props} target="_blank" />;

        return (
          <div className="tnc-page-container">
            <Markdown options={{ overrides: { a: OpenLinkInNewTab } }}>
              {data?.informationNoticeContent.informationNotice || ''}
            </Markdown>
          </div>
        );
      }}
    </Query>
  );
};

export default InformationNotice;
