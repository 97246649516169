// eslint-disable-next-line no-use-before-define
import classNames from 'classnames';
import React, { Component, Fragment } from 'react';

import './SumaryRow.scss';

interface Props {
  testIdGroup?: string;
  headingText: string | null;
  detailText: string | null;
  displayAsError: boolean;
  displayAsOptional: boolean;
  fallbackText?: string;
  subQuestion?: boolean;
  allowEmptyDetailText?: boolean;
  underLine?: boolean;
}

class SummaryRow extends Component<Props> {
  public render(): JSX.Element {
    const {
      props: {
        testIdGroup,
        headingText,
        detailText,
        displayAsError,
        displayAsOptional,
        fallbackText,
        subQuestion,
        underLine,
      },
    } = this;

    let displayText = this.renderContentMessage(detailText, fallbackText);
    if (displayText.match(/^(YES|NO|UNSURE|HIGH|LOW)$/)) {
      displayText = this.capitalize(displayText);
    }

    const optionalColouring =
      displayText === fallbackText || displayText === 'Not provided' || (displayText === '-' && displayAsOptional);

    let testGroup = '';
    if (testIdGroup) {
      testGroup = testIdGroup + '-';
    }

    return (
      <Fragment>
        <div
          className={classNames('summary-row newscard-row', {
            'invalid-data': displayAsError,
            'sub-question': subQuestion,
          })}>
          <div data-test-id={`question-${testGroup}${headingText}`} className="title">
            {underLine ? (
              <span style={{ fontSize: '23px' }}>
                <u>{headingText || ''}</u>
              </span>
            ) : (
              headingText || ''
            )}
            {displayAsOptional && ' ' + '(optional)'}
          </div>
          <div
            data-test-id={`answer-${testGroup}${headingText}`}
            className={classNames('content', { optional: optionalColouring })}>
            {displayText}
          </div>
        </div>
      </Fragment>
    );
  }

  /**
   * Helper method to generate the detail text or the provided fall-back text if data is missing.
   */
  private renderContentMessage = (content: string | null, fallbackText?: string): string => {
    if (!content && this.props?.allowEmptyDetailText) {
      return '';
    }
    // If null or empty then return missing text
    if (!content || content.trim() === '') {
      if (fallbackText) {
        return fallbackText;
      } else {
        return '-';
      }
    }
    return content;
  };

  private capitalize(text: any): string {
    text = text.toLowerCase().split(' ');
    for (let i = 0; i < text.length; i++) {
      text[i] = text[i].charAt(0).toUpperCase() + text[i].slice(1);
    }
    return text.join(' ');
  }
}

export const evaluateDetailText = (value: string | boolean | null | undefined): string | null => {
  if (typeof value === 'string') return value;

  if (value === null || value === undefined) return null;
  else return value ? 'YES' : 'NO';
};

export default SummaryRow;
