// eslint-disable-next-line no-use-before-define
import React, { Component, Fragment } from 'react';
import Markdown from 'markdown-to-jsx';
import classNames from 'classnames';

import { Link } from 'react-router-dom';

import './Content.scss';

import { ContentData } from '../../../interfaces';
import { StepperLink } from 'shared-components/interfaces';
import { CONTENT_STEPPER_LINKS, CHEMO_STEPPER_LINKS, MAGIC_STRINGS } from '../constants';

import { Tips } from 'shared-components/images';
import { ChevronRight as ChevronRightIcon } from '@mui/icons-material';

interface Props {
  data?: ContentData;
  contentShown: boolean;
  setContentShown: (to: boolean) => void;
  pageCategory?: string;
}

class Content extends Component<Props> {
  public componentDidUpdate(): void {
    const { contentShown } = this.props;
    const contentContainer = document.getElementsByClassName('px-content-page-container')[0];

    if (contentContainer && contentShown) {
      contentContainer.scrollTop = 0;
    }
  }

  public render(): JSX.Element {
    const { data, contentShown } = this.props;

    const headerStyles = {
      backgroundImage: `linear-gradient(rgba(46, 46, 46, 0.5), rgba(46, 46, 46, 0.5)), url(/media/${
        data ? data.coverImage.split('_')[0] : ''
      }.jpg  )`,
      backgroundPosition: 'center',
      backgroundSize: 'cover',
      backgroundRepeat: 'no-repeat',
    };

    const OpenLinkInNewTab = ({ ...props }) => <a {...props} target="_blank" />;

    return (
      <div className={classNames('px-content-page-container', { 'content-shown': contentShown })}>
        {data && (
          <Fragment>
            <div className="header" style={headerStyles}>
              <h1>{data.pageTitle}</h1>
            </div>
            <div className="what-to-expect">
              <div className="content">
                <Markdown
                  options={{
                    overrides: {
                      a: OpenLinkInNewTab,
                    },
                  }}>
                  {data.pageContent}
                </Markdown>
              </div>
            </div>
            <div className="tips">
              <div className="title-container">
                <Tips className="icon" />
                <h2>{'Tips'}</h2>
              </div>
              <div className="content">
                <Markdown
                  options={{
                    overrides: {
                      a: OpenLinkInNewTab,
                    },
                  }}>
                  {data.tipsSectionContent}
                </Markdown>
              </div>
            </div>
          </Fragment>
        )}
        <div className="next-page">{this.renderNextURL()}</div>
      </div>
    );
  }

  private renderNextURL = (): JSX.Element | null => {
    const { setContentShown, pageCategory } = this.props;
    const currentURL = window.location.pathname;
    const { CHEMOTHERAPY } = MAGIC_STRINGS;
    let nextURL: StepperLink | undefined;
    const stepperLinks = pageCategory === CHEMOTHERAPY.toLowerCase() ? CHEMO_STEPPER_LINKS : CONTENT_STEPPER_LINKS;

    stepperLinks.map((link: StepperLink, index: number) => {
      if (link.href == currentURL) {
        if (index + 1 <= stepperLinks.length) {
          nextURL = stepperLinks[index + 1];
        } else {
          nextURL = undefined;
        }
      }
    });

    if (nextURL) {
      return (
        <Link to={nextURL.href}>
          <span>{nextURL.linkText}</span>
          <ChevronRightIcon color="primary" className="icon" />
        </Link>
      );
    } else {
      return (
        <div
          className="last-page-mobile-breadcrumb"
          onClick={() => {
            setContentShown(false);
          }}>
          <span>{'Return to information list'}</span>
          <ChevronRightIcon color="primary" className="icon" />
        </div>
      );
    }
  };
}

export default Content;
