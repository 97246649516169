import React, { useEffect } from 'react';
import { LoadingSpinner } from 'shared-components/components';
import { Card, CardContent } from '@mui/material';

interface SubmittedPanelProps {
  url: string;
  zoomLevel: number;
}

const SubmittedPanel = ({ url, zoomLevel }: SubmittedPanelProps): JSX.Element => {
  const [state, setState] = React.useState({ loading: true, error: false });

  useEffect(() => {
    setState({ loading: true, error: false });
  }, [url]);

  return (
    <Card variant="outlined" sx={{ width: '100%', height: '100%', padding: '0px' }}>
      <CardContent sx={{ height: '100%', padding: '0px', paddingBottom: '0px !important' }}>
        {state.error && <div>An error occurred while generating the PDF, please contact support</div>}
        {state.loading && <LoadingSpinner />}
        <iframe
          title="simpage"
          key={zoomLevel} // need key on iframe to force rerender for responsive zoom level
          src={url}
          width="100%"
          height="100%"
          style={{ padding: '0px', border: 'none' }}
          onLoad={(): void => {
            setState((prevState) => {
              return { ...prevState, loading: false };
            });
          }}
          onError={(): void => {
            setState({ error: true, loading: false });
          }}>
          This browser does not support PDFs.
        </iframe>
      </CardContent>
    </Card>
  );
};

export default SubmittedPanel;
