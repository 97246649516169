import { isUs } from 'op-utils/helpers';
import { addressLabelForRegion } from 'shared-components/enums';
const addressLabels = addressLabelForRegion();

export const PDF_TEXT = isUs()
  ? 'Once submittted, a PDF of this registration form will be submitted and patient information updated.'
  : 'Once submitted, a PDF of this registration form will be sent to EMR. Patient information will be updated in both systems.';

export const MODAL_SUBMIT_COPY = 'Are you sure you want to submit the form?';
export const MODAL_PSO_SUBMIT_COPY = 'Confirm and Submit';
export const FORM_TEXT = 'Please ensure all your details are correct.';
export const PSO_FORM_TEXT =
  'By submitting this page you confirm that you have reviewed all information and ensured all details are correct. Once submitted, a PDF of this registration from will be sent to MOSAIQ. Patient information will be updated in both systems.';
export const BASIC_FIELDS = {
  fields: [
    { key: 'resolvedNamePrefix', label: 'Title' },
    { key: 'firstName', label: 'First name' },
    { key: 'middleName', label: 'Middle name' },
    { key: 'lastName', label: 'Last name' },
    { key: 'preferredName', label: 'Preferred Name' },
    { key: 'patientGender', label: 'What was your sex recorded at birth' },
    { key: 'patientGenderIdentification', label: 'How would you describe your gender' },
    { key: 'patientGenderIdentificationOther', label: 'Gender description specify' },
    { key: 'dob', label: 'Date of birth' },
  ],
  title: 'Basic Details',
  path: 'basic',
};

export const CONTACT_FIELDS = {
  fields: [
    { key: 'primaryPhone', label: 'Mobile' },
    { key: 'secondaryPhone', label: 'Home phone' },
    { key: 'email', label: 'Email' },
  ],
  title: 'Contact Details',
  path: 'contact',
};

export const REFERRER_FIELDS = {
  fields: [
    { key: 'referringFirstName', label: 'First name' },
    { key: 'referringLastName', label: 'Last name' },
    { key: 'referringPractice', label: 'Practice / medical centre' },
  ],
  gpFields: [
    { key: 'gpFirstName', label: 'First name' },
    { key: 'gpLastName', label: 'Last name' },
    { key: 'gpPractice', label: 'Practice / medical centre' },
  ],
  otherReferrer: [{ key: 'referrersOtherSpecialists', label: 'Other specialist names' }],
  title: 'Referrers',
  path: 'referrers',
};

export const ADDRESS_FIELDS = {
  fields: [
    { key: 'address.line1', label: addressLabels.line1 },
    { key: 'address.line2', label: addressLabels.line2 },
    { key: 'address.city', label: addressLabels.city },
    { key: 'address.state', label: addressLabels.state },
    { key: 'address.country', label: addressLabels.country },
    { key: 'address.postcode', label: addressLabels.postcode },
  ],
  postalSameField: [
    {
      key: 'postalAddressSameAsResidential',
      label: 'Same as above (residential address)',
    },
  ],
  postalFields: [
    { key: 'postalAddress.line1', label: addressLabels.line1 },
    { key: 'postalAddress.line2', label: addressLabels.line2 },
    { key: 'postalAddress.city', label: addressLabels.city },
    { key: 'postalAddress.state', label: addressLabels.state },
    { key: 'postalAddress.country', label: addressLabels.country },
    { key: 'postalAddress.postcode', label: addressLabels.postcode },
  ],
  title: 'Address',
  path: 'address',
};

export const INSURANCE_FIELDS = {
  title: 'Health Insurance',
  path: 'insurance',
  fields: [{ key: 'healthMedicareDvaOption', label: 'Medicare and/or Department of Veterans Affairs (DVA) number' }],
  medicareFields: [
    {
      key: 'healthMedicareNumber',
      label: 'Medicare card number',
    },
    {
      key: 'healthMedicareIrn',
      label: 'IRN',
    },
    {
      key: 'medicareExpiryRawMonth',
      label: 'Medicare card expiry month',
    },
    {
      key: 'medicareExpiryRawYear',
      label: 'Medicare card expiry year',
    },
  ],
  dvaFields: [
    {
      key: 'healthDvaNumber',
      label: 'Department of Veterans Affairs (DVA) card number',
    },
    {
      key: 'healthDvaType',
      label: 'DVA card type',
    },
    {
      key: 'dvaExpiryRawMonth',
      label: 'DVA card expiry month',
    },
    {
      key: 'dvaExpiryRawYear',
      label: 'DVA card expiry year',
    },
  ],
  privateInsuranceConditional: [
    {
      key: 'healthPrivateHealthInsurance',
      label: 'Private health insurance',
    },
  ],
  privateInsuranceFields: [
    {
      key: 'healthPrivateHealthFundName',
      label: 'Private health fund name',
    },
    {
      key: 'healthPrivateHealthFundNumber',
      label: 'Private health fund number',
    },
  ],
  pensionCardConditional: [
    {
      key: 'healthPensionCard',
      label: 'Are you a concession card holder?',
    },
  ],
  pensionCardFields: [
    {
      key: 'healthPensionCardType',
      label: 'Type of Services Australia card',
    },
    {
      key: 'healthPensionCardNumber',
      label: 'Concession card number',
    },
    {
      key: 'healthPensionCardStart',
      label: 'Concession card start date',
    },
    {
      key: 'healthPensionCardExpiry',
      label: 'Concession card expiry date',
    },
  ],
};

export const DEMOGRAPHICS_FIELDS = {
  title: 'Demographics',
  path: 'demographics',
  fields: [
    { key: 'countryOfBirth', label: 'Country of birth' },
    { key: 'niceLanguageAtHome', label: 'Language spoken at home' },
  ],
  interpreterRequiredField: [{ key: 'interpreterRequired', label: 'Do you require an interpreter?' }],
  remainingFields: [
    { key: 'occupation', label: 'What is your primary occupation?' },
    { key: 'niceHeritage', label: 'Are you of Aboriginal or Torres Strait Islander heritage?' },
    { key: 'maritalStatusValue', label: 'Marital status' },
  ],
  vicSpecificFields: [{ key: 'ambulanceMembership', label: 'Do you have an ambulance membership?' }],
  ambulanceNumberFields: [{ key: 'ambulanceMembershipNumber', label: 'Ambulance membership number' }],
};

export const INFORMATION_NOTICE_FIELDS = {
  fields: [
    {
      key: 'informationNoticeAccepted',
      label: 'Patient has read and acknowledged information notice',
    },
    {
      key: 'informationNoticeAlliedHealthReceiveUpdates',
      label: 'Patient would like to receive information about allied health and well being services and information',
    },
  ],
  title: 'Information Notice',
  path: 'infonotice',
};

export const DOCUMENT_UPLOAD_FIELDS = {
  title: 'Document Upload',
  path: 'upload',
};

export const EMERGENCY_CONTACT_FIELDS = {
  fields: [
    { key: 'emergencyContact.firstName', label: 'First name' },
    { key: 'emergencyContact.lastName', label: 'Last name' },
    { key: 'emergencyContact.relationship', label: 'Relationship' },
    { key: 'emergencyContact.mobilePhoneNumber', label: 'Mobile' },
    { key: 'emergencyContact.homePhoneNumber', label: 'Home phone' },
    { key: 'emergencyContact.email', label: 'Email' },
    { key: 'emergencyContact.authorisedForEnquiries', label: 'Authorised to make enquiries for appointment times' },
    { key: 'emergencyContact.supportPerson', label: 'Identified as support person in making decisions' },
    { key: 'altContactProvided', label: 'Add another contact?' },
  ],
  nextOfKinFields: [
    { key: 'nextOfKinContact.firstName', label: 'First name' },
    { key: 'nextOfKinContact.lastName', label: 'Last name' },
    { key: 'nextOfKinContact.relationship', label: 'Relationship' },
    { key: 'nextOfKinContact.mobilePhoneNumber', label: 'Mobile' },
    { key: 'nextOfKinContact.homePhoneNumber', label: 'Home phone' },
    { key: 'nextOfKinContact.email', label: 'Email' },
    { key: 'nextOfKinContact.authorisedForEnquiries', label: 'Authorised to make enquiries for appointment times' },
    { key: 'nextOfKinContact.supportPerson', label: 'Identified as support person in making decisions' },
  ],
  title: 'Alternate Contacts',
  path: 'emergencyContact',
};

export const fieldsToExclude = [
  'email',
  'address.line2',
  'postalAddress.line2',
  'middleName',
  'preferredName',
  'postalAddressSameAsResidential',
  'altContactProvided',
  'emergencyContact.email',
  'nextOfKinContact.email',
  'patientGenderIdentificationOther',
];

// A leftover from old rego, will require refactor of doc upload to properly clean up
export const documentTypeMapping = {
  advance_care_directive: 'Advance Care Directive',
  concession_card: 'Cards',
  medication_list: 'Medications list',
  pacemaker_card: 'CIED Card',
  power_of_attorney: 'Power of attorney',
  referral: 'Incoming referral',
  other: 'Other',
};
