// eslint-disable-next-line no-use-before-define
import { useMutation } from '@apollo/client';
import { Form, Formik, FormikProps } from 'formik';
import { FileUploadField, FormContainer } from 'op-components';
import { RegistrationContext } from 'op-contexts';
import React, { useEffect, useState, useContext } from 'react';
import { useHistory, useParams } from 'react-router-dom';
import { ListData } from 'shared-components/interfaces';
import { registrationAttachments } from 'op-utils';
import {
  CONTINUE_BUTTON_TEXT,
  HeaderTitle,
  registrationPath,
  RETURN_TO_SUBMIT_TEXT,
  sharedFormContainerProps,
  HeaderSubTitle,
} from '../Helper';
import { PatientDocumentUpload } from '../interfaces';
import { FIELDS } from './constants';
import { ATTACHMENT_ACKNOWLEDGED_MUTATION } from './DocumentUploadQueries';
import { documentValidationSchema } from './documentUploadSchema';
import lodash from 'lodash';

interface DocumentTypeData extends ListData {
  conceptCode?: string;
}

interface DocumentUploadFormIProps {
  handleShowSaveExitConfirm: () => void;
  previousPageLink?: string;
  isPso: boolean;
  refetch: any;
  patient: PatientDocumentUpload;
  user: {};
  readonly refData: {
    readonly documentTypeRefData: ListData[];
  };
}

export const isDisabled = (patient: PatientDocumentUpload, isPso: boolean): boolean => {
  if (!registrationAttachments(patient?.attachments).length || !isPso) return false;
  const errors = documentValidationSchema(patient);
  return errors.attachmentAcknowledged || errors.attachments ? true : false;
};

const DocumentUploadForm = (props: DocumentUploadFormIProps): JSX.Element => {
  const registrationContext = useContext(RegistrationContext);
  const {
    patient,
    refetch,
    user,
    handleShowSaveExitConfirm,
    isPso,
    refData: { documentTypeRefData },
  } = props;
  const history = useHistory();
  const { patientId } = useParams<{ patientId: string }>();

  const goToSummary = registrationContext?.registrationSummaryVisited;

  const [uploadInfoErrorModalOpen, setUploadInfoErrorModalOpen] = useState(false);
  const [fileUploaded, setFileUploaded] = useState(false);

  const [updateAttachmentAcknowledged] = useMutation(ATTACHMENT_ACKNOWLEDGED_MUTATION);

  const formatDocumentTypeRefData = (documentTypeRefData: DocumentTypeData[]): ListData[] => {
    return lodash.cloneDeep(documentTypeRefData).map((value) => {
      if (value.conceptCode) {
        value.id = value.conceptCode!;
        delete value.conceptCode;
      }
      return value;
    });
  };

  useEffect(() => {
    if (patient.attachmentAcknowledged && !patient.attachments.length) {
      updateAttachmentAcknowledged({ variables: { patientId, attachmentAcknowledged: false } });
    }
  }, [patient.attachments]);

  return (
    <Formik initialValues={patient} onSubmit={() => history.push(registrationPath(patientId, 'summary'))}>
      {({ submitForm }: FormikProps<PatientDocumentUpload>) => {
        const sharedProps = sharedFormContainerProps('Document Upload', 8, '');
        const disable = isDisabled(patient, isPso);

        const formContainerProps = {
          ...sharedProps,
          continueDisabled: disable,
          submitButtonText: goToSummary ? RETURN_TO_SUBMIT_TEXT : CONTINUE_BUTTON_TEXT,
          backLink: registrationPath(patientId, 'demographics'),
          handleShowSaveExitConfirm,
        };

        return (
          <FormContainer
            {...formContainerProps}
            submitForm={submitForm}
            handleValidation={(event: React.MouseEvent<HTMLInputElement>): void => {
              void event;
            }}>
            <Form>
              <HeaderTitle data-test-id="doc-upload-title">{FIELDS.FORM_HEADING.TITLE}</HeaderTitle>
              {isPso && <HeaderSubTitle>* = required field</HeaderSubTitle>}
              {/* Todo: The FileUploadField does not respect the Yup schema and has its own internal validation logic. This is inconsistent with other form pages and if the validation logic changes we will have to update in two places which is not the best practice, hence would be good to work on it in the future. */}
              <FileUploadField
                refetchCallback={() => refetch()}
                documentTypeRefData={formatDocumentTypeRefData(documentTypeRefData)}
                patient={patient}
                user={user}
                fileUploaded={fileUploaded}
                onIncompleteFileUpload={() => setFileUploaded(false)}
                onCompleteFileUpload={() => setFileUploaded(true)}
                onOpenUploadErrorModal={() => setUploadInfoErrorModalOpen(true)}
                onCloseUploadErrorModal={() => setUploadInfoErrorModalOpen(false)}
                uploadInfoErrorModalOpen={uploadInfoErrorModalOpen}
                isPso={isPso}
                updateAttachmentAcknowledged={(id: string, attachmentAcknowledged: boolean) =>
                  updateAttachmentAcknowledged({ variables: { patientId, attachmentAcknowledged } })
                }
                validateOnLoad={isPso}
              />
            </Form>
          </FormContainer>
        );
      }}
    </Formik>
  );
};

export default DocumentUploadForm;
