import { gql } from '@apollo/client';
import {
  HEALTH_ASSESSMENT_CANCER_FRAGMENT,
  HEALTH_ASSESSMENT_FAMILY_HISTORY_FRAGMENT,
  HEALTH_ASSESSMENT_OTHER_RT_FRAGMENT,
} from 'op-graphql/fragments';

export const HA_MEDICAL_HISTORY_CANCER_QUERY = gql`
  query HealthAssessment($patientId: ID!) {
    healthAssessment(id: $patientId) {
      id
      previousCancer
      cancer {
        id
        ...HealthAssessmentCancer
      }
      patient {
        id
        fullName
      }
      otherRadiotherapy
      otherRadiotherapyConditions {
        id
        ...HealthAssessmentOtherRT
      }
      familyHistory
      familyHistoryAnswer
      usFamilyHistory {
        id
        ...HealthAssessmentFamilyHistory
      }
    }
    treatmentTypeRefData: listData(category: "treatmentType") {
      id
      name
    }
    treatmentStageRefData: listData(category: "treatmentStage") {
      id
      name
    }
    familyMemberRefData: listData(category: "familyMember") {
      id
      name
    }
  }

  ${HEALTH_ASSESSMENT_FAMILY_HISTORY_FRAGMENT}
  ${HEALTH_ASSESSMENT_CANCER_FRAGMENT}
  ${HEALTH_ASSESSMENT_OTHER_RT_FRAGMENT}
`;
