/* eslint-disable  */
// eslint-disable-next-line no-use-before-define
import React from 'react';
import { CarePlanAction, DEFAULT_CAREPLAN_STATE } from './Interface';

const ROPatientCarePlanContext = React.createContext({
  state: DEFAULT_CAREPLAN_STATE,
  dispatch: (_action: CarePlanAction): void => {},
});
export default ROPatientCarePlanContext;
