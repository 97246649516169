import React from 'react';
import { Link } from 'react-router-dom';
import { styled } from '@mui/system';
import { PollOutlined as PollOutlinedIcon } from '@mui/icons-material';
import { Stack } from '@mui/material';
interface Props {
  sidebarVariables: {
    patientId?: string;
    formId?: string;
  };
  children?: any;
}

const StyledSideBar = styled('div')`
  align-items: center;
`;

const StyledLink = styled(Link)`
  text-decoration: none;
  &:focus,
  &:hover,
  &:visited,
  &:link,
  &:active {
    text-decoration: none;
  }
`;

const StyledLinkContainer = styled(Stack)`
  padding: 0.5em;
  width: 80%;
  font-style: normal;
  font-weight: bold;
  font-size: smaller;
  line-height: 20px;
  color: black;
  align-items: center;
  width: 100%;
  margin: 6px 20px;
`;

const SideBar = ({ sidebarVariables, children }: Props): JSX.Element => {
  const componentId = 'sidebar';
  const { patientId } = sidebarVariables;

  return (
    <StyledSideBar id={componentId}>
      <StyledLink to={`/patient/${patientId}/summary`}>
        <StyledLinkContainer
          direction="row"
          gap="20px"
          alignItems="center"
          data-test-id={`${componentId}-patient-summary-link`}>
          <PollOutlinedIcon color="primary" />
          <span>Patient Summary</span>
        </StyledLinkContainer>
      </StyledLink>

      {/** Steppers, Quick Access, etc. */}
      <div id={`${componentId}-content`}>{children}</div>
    </StyledSideBar>
  );
};

export default SideBar;
