// eslint-disable-next-line no-use-before-define
import React, { useState, useContext } from 'react';
import { styled } from '@mui/system';
import { FileContext } from 'op-contexts';
import { Popover } from 'shared-components/components';
import { Delete } from 'shared-components/images';
import { DELETE_DOCUMENT, DELETE_FILE } from './constants';
import { ActiveFile } from './DocumentUpload';
import { CUT_OFF_POINT } from './constants';
import { useWindowSize } from 'shared-components/utils/CustomHooks';
import { Button } from '@mui/material';

export interface DeletePopoverProps {
  documentId: string;
  handleMutation: any;
  document: ActiveFile;
  dataTestId: string;
}

const Container = styled('div')`
  display: flex;
`;

const DeleteFile = styled('div')`
  cursor: pointer;
  border-radius: 128px;
  width: 90px;
  height: 36px;
  text-decoration: none;
  display: flex;
  align-items: center;
  font-size: get-rem-size(14px);
  color: ${(props) => props.theme.palette.text.primary};

  svg {
    margin-left: 11px;
    margin-right: 7px;
  }

  #delete-icon {
    path {
      fill: ${(props) => props.theme.palette.error.main};
      fill-opacity: 1;
    }
  }

  &:hover {
    background-color: ${(props) => props.theme.palette.error.light};
  }
`;

const Description = styled('div')`
  margin-bottom: 16px;
`;

const ButtonsContainer = styled('section')`
  display: flex;
  width: 100%;
  margin-bottom: 0;
  justify-content: flex-end;
`;

const DeleteDocument = ({ document, documentId, handleMutation, dataTestId }: DeletePopoverProps): JSX.Element => {
  const windowSize = useWindowSize();
  const isSmallDisplay = windowSize.width < CUT_OFF_POINT;
  const { activeFiles, setActiveFiles } = useContext(FileContext);
  const [anchorEl, setAnchorEl] = useState(null);
  const [open, setOpen] = useState(false);

  const handleClick = (event: any) => {
    const { currentTarget } = event;
    setAnchorEl(currentTarget);
    setOpen(!open);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const handleDiscardDocument = () => {
    if (document.id) {
      handleMutation({
        variables: {
          attachmentPk: document.id,
        },
      });
    } else {
      // Remove rejected file from list
      activeFiles.splice(documentId, 1);
      setActiveFiles([...activeFiles]);
    }
    setOpen(false);
  };

  return (
    <Container>
      <DeleteFile onClick={handleClick} data-test-id={dataTestId}>
        <Delete id="delete-icon" />
        <strong>{DELETE_FILE}</strong>
      </DeleteFile>
      <Popover
        id={`dd-popover-${documentId}`}
        title={DELETE_DOCUMENT.HEADER}
        open={open}
        anchorEl={anchorEl}
        handleClickAway={handleClose}
        fadeTimeout={350}
        triangleLeftPos={isSmallDisplay ? '-50px' : '-10px'}
        triangleBottomPos="-15px"
        trianglePosition={isSmallDisplay ? '285px' : '168px'}>
        <Description>{DELETE_DOCUMENT.DESCRIPTION}</Description>
        <ButtonsContainer>
          <Button onClick={handleClose}>Cancel</Button>
          <Button data-testid="delete-doc-btn" color="error" onClick={handleDiscardDocument}>
            Delete
          </Button>
        </ButtonsContainer>
      </Popover>
    </Container>
  );
};

export default DeleteDocument;
