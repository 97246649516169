import { styled } from '@mui/system';

export const CycleTab = styled('div')`
  display: flex;
  justify-content: start;
  gap: 24px;
  width: 100%;
  overflow-x: scroll;
  padding-bottom: 8px;

  ::-webkit-scrollbar {
    -webkit-appearance: none;
    width: 3px;
    height: 8px;
  }

  ::-webkit-scrollbar-thumb {
    border-radius: 6px;
    background-color: ${(props) => props.theme.palette.primary.main};
    box-shadow: 0 0 1px rgba(255, 255, 255, 0.5);
  }
`;
