// eslint-disable-next-line no-use-before-define
import React from 'react';
import { UserContext } from 'op-contexts';
import SimpleView from './SimpleView';
import DefaultView from './DefaultView';

const viewMappings = {
  Simple: <SimpleView />,
  Default: <DefaultView />,
};

const Summary = (): JSX.Element => {
  const { getEMRFeature, getUserFeature } = React.useContext(UserContext);
  const userSelectedViewObject = getUserFeature('PatientSummary');
  const userSelectedView = userSelectedViewObject ? userSelectedViewObject.featureOption?.name : null;

  const defaultFeatureObject = getEMRFeature('PatientSummary');
  const defaultView = defaultFeatureObject ? defaultFeatureObject.default.name : null;
  const view = userSelectedView || defaultView;

  return view ? viewMappings[view as keyof typeof viewMappings] : <DefaultView />;
};

export default Summary;
