// eslint-disable-next-line no-use-before-define
import { useMutation, useQuery } from '@apollo/client';
import { generatePath, useParams } from 'react-router-dom';
import DemographicsForm from './DemographicsForm';
import { DEMOGRAPHICS_QUERY, UPDATE_REGISTRATION_DEMOGRAPHIC_DETAILS } from './DemographicsQueries';
import { FIELDS } from './constants';

interface Props {
  exitForm: (status: boolean) => void;
}

const Demographics = (props: Props): JSX.Element => {
  const { exitForm } = props;
  const { patientId } = useParams<{ patientId: string }>();

  const { data, error } = useQuery(DEMOGRAPHICS_QUERY, {
    variables: { id: patientId },
  });
  const [updateRegistrationDemographic] = useMutation(UPDATE_REGISTRATION_DEMOGRAPHIC_DETAILS);

  if (error || !data) return <></>;
  const { patient, atsiHeritageRefData, maritalStatusRefData, countryOfBirthRefData, languageSpokenRefData, user } =
    data;

  const updateField = (field: string, value: string | boolean | number | null) => {
    const variables = {
      patientId,
      [field]: value,
    };
    if (field === FIELDS.LANGUAGE_AT_HOME.NAME && value === 'English') {
      variables['interpreterRequired'] = null;
    }
    updateRegistrationDemographic({ variables });
  };

  return (
    <DemographicsForm
      patient={patient}
      updateField={updateField}
      handleShowSaveExitConfirm={() => exitForm(true)}
      previousPageLink={generatePath(`/patient/${patientId}/registration/insurance`)}
      refData={{
        maritalStatusRefData,
        atsiHeritageRefData,
        countryOfBirthRefData,
        languageSpokenRefData,
      }}
      isPso={user.isPso}
    />
  );
};

export default Demographics;
