import React, { useState, useEffect } from 'react';
import { styled } from '@mui/system';
import { DropDownField } from 'shared-components/components/FormFields';
import { ChevronLeft as ChevronLeftIcon, ChevronRight as ChevronRightIcon } from '@mui/icons-material';

export interface PageChangePayload {
  currentPage: number;
  limit: number;
  startingRecord: number;
  endingRecord: number;
}

export interface PaginationProps {
  totalRecords: number;
  page?: number;
  pageLimit?: number;
  disabled?: boolean;
  loading?: boolean;
  onPageChange?: (payload: PageChangePayload) => void;
}

const PaginationContainer = styled('div')`
  display: flex;
  align-items: center;
  justify-content: flex-end;
  padding: 24px 0;
`;

const Label = styled('label')`
  margin-right: 30px;
`;

const RowsPerPageDropDown = styled(DropDownField)`
  max-width: 90px;
  border-radius: 4px;
  margin-left: 10px;

  * {
    font-weight: bold;
  }
`;

const MetaContainer = styled('div')`
  margin-right: 30px;

  .spinner-border {
    border-color: ${(props) => props.theme.palette.primary.main};
    border-right-color: transparent;
  }
`;

const NavButton = styled('button')`
  background: none;
  border: none;
  cursor: pointer;

  path {
    transition: fill 0.15s ease-in-out;
  }

  &:hover path {
    fill: ${(props) => props.theme.palette.text.primary};
  }

  &[disabled] {
    cursor: not-allowed;

    path {
      fill: ${(props) => props.theme.palette.grey[300]};
    }
  }
`;

const Pagination = ({
  totalRecords,
  page,
  pageLimit,
  disabled,
  loading,
  onPageChange,
}: PaginationProps): JSX.Element => {
  const [limit, setLimit] = useState<number>(pageLimit ? pageLimit : 25);
  const [currentPage, setCurrentPage] = useState<number>(page ? page : 1);
  const totalPages = Math.ceil(totalRecords / limit);
  const limits = ['25', '50', '100'];
  const limitOptions = limits.map((item) => ({ id: item, name: item }));
  const dropDownName = 'rows-per-page';
  const startCalc = currentPage * limit - limit;
  const startingRecord =
    startCalc < 0 ? 0 : startCalc > totalRecords ? (totalRecords - limit < 0 ? 0 : totalRecords - limit) : startCalc;
  const endCalc = currentPage * limit;
  const endingRecord = endCalc > totalRecords ? totalRecords : endCalc;
  const pageChangePayload = {
    currentPage,
    limit,
    startingRecord,
    endingRecord,
  };

  const prevDisabled = currentPage === 1 || disabled || loading || totalPages === 1;
  const nextDisabled = currentPage === totalPages || !totalPages || disabled || loading || totalPages === 1;

  const handlePageNext = () => setCurrentPage(currentPage + 1);
  const handlePagePrev = () => setCurrentPage(currentPage - 1);

  useEffect(() => {
    if (onPageChange) onPageChange(pageChangePayload);
  }, [limit, currentPage]);

  useEffect(() => {
    setCurrentPage(1);
  }, [limit]);

  useEffect(() => {
    setCurrentPage(page ? page : 1);
  }, [page]);

  return (
    <PaginationContainer>
      <Label htmlFor={dropDownName}>
        Rows per page
        <RowsPerPageDropDown
          disabled={loading || disabled}
          inputName={dropDownName}
          placeholder={'Rows per page'}
          defaultValue={limitOptions[0].name}
          options={limitOptions}
          className="rows-per-page-dropdown"
          onChange={(e): void => setLimit(parseInt(e.target.value))}
        />
      </Label>
      <MetaContainer>
        {loading ? (
          <div className="spinner-border" role="status">
            <div className="sr-only" />
          </div>
        ) : (
          `${totalRecords <= limit ? totalRecords : `${startingRecord + 1} - ${endingRecord}`} of ${totalRecords}`
        )}
      </MetaContainer>
      <NavButton onClick={handlePagePrev} disabled={prevDisabled}>
        <ChevronLeftIcon color="primary" />
      </NavButton>
      <NavButton onClick={handlePageNext} disabled={nextDisabled}>
        <ChevronRightIcon color="primary" />
      </NavButton>
    </PaginationContainer>
  );
};

export default Pagination;
