import { gql } from '@apollo/client';

export const MO_ADDITIONAL_INFO_QUERY = gql`
  query moAdditionalInfo($careplanId: ID!) {
    moCareplanAdditionalInfo(careplanId: $careplanId) {
      id
      protocolId
      startingCycle
      numberOfCycles
      daysPerCycle
      continuous
      latestComment
      cycleSchedule {
        seq
        days {
          day
          date
          breakDays
          moveDays
          hidden
        }
      }
    }
  }
`;

export const PRESCRIPTION_DATA_QUERY = gql`
  query moCareplanData($careplanId: ID!) {
    careplan(id: $careplanId) {
      id
      careplanName
    }
    moTreatmentInfo(careplanId: $careplanId) {
      id
      readyForCareDate
    }
    moCareplanDoses(careplanId: $careplanId) {
      id
      cycle
      day
      drugOrder {
        id
        drugOrderBaseId
        drugOrderVersion
        instructions
      }
      order
      doseValue
      prescribedDose
      doseAdjustment {
        id
        drugOrder {
          id
          drugOrderBaseId
          drugOrderVersion
          instructions
        }
        dosePercentage
        reasonForChange
        applyTo
        cycle
        day
        isDoseDeleted
        instructions
      }
      previousDoseAdjustment {
        id
        dosePercentage
        instructions
      }
      startVersion
    }
    moCareplanDrugOrders(careplanId: $careplanId) {
      id
      name
      base
      version
      doseBasis
      doseUnit
      minimumDose
      maximumDose
      doseIsCalculated
      doseCalculatedUnits
      doseCalculatedBy
      administrationInstructions
      patientInstructions
      type
      drugCategory
      roundToNearest
      genericName
      route
      prescribedDosePrecision
      solution
      prn
    }
  }
`;

export const CAREPLAN_CYCLES_QUERY = gql`
  query latestCycles($careplanId: ID!) {
    latestCycles(careplanId: $careplanId) {
      id
      cycleId
      version
      approvedAt
      downloadedAt
      printedAt
      treatmentStartDate
      treatmentEndDate
    }
  }
`;

export const SAVE_COMMENTS = gql`
  mutation updatePrescriptionComments($careplanId: ID!, $comments: String!) {
    updatePrescriptionComments(careplanId: $careplanId, comments: $comments) {
      moCareplanAdditionalInfo {
        id
        protocolId
        numberOfCycles
        daysPerCycle
        continuous
        latestComment
      }
    }
  }
`;

export const UPDATE_DOSE_ADJUSTMENT = gql`
  mutation updateDoseAdjustment(
    $drugOrderId: ID!
    $prescribedDoseValue: String
    $dosePercentage: Float
    $reasonForChange: String
    $applyTo: String
    $day: Int
    $cycle: Int
    $instructions: String
  ) {
    updateMoDoseAdjustment(
      drugOrderId: $drugOrderId
      prescribedDoseValue: $prescribedDoseValue
      dosePercentage: $dosePercentage
      reasonForChange: $reasonForChange
      applyTo: $applyTo
      day: $day
      cycle: $cycle
      instructions: $instructions
    ) {
      success
    }
  }
`;

export const ADD_DRUG_ORDER = gql`
  mutation addMoDrugOrder(
    $careplanId: ID!
    $drugOrderBaseId: ID!
    $drugOrderVersion: Int!
    $days: [Int]
    $cycle: Int
    $applyTo: String
    $order: Int
    $instructions: String
  ) {
    addMoDrugOrder(
      careplanId: $careplanId
      drugOrderBaseId: $drugOrderBaseId
      drugOrderVersion: $drugOrderVersion
      days: $days
      cycle: $cycle
      applyTo: $applyTo
      order: $order
      instructions: $instructions
    ) {
      success
    }
  }
`;

export const DELETE_DRUG_ORDER = gql`
  mutation deleteDrugOrder($careplanId: ID!, $drugOrderId: ID, $day: Int, $cycle: Int) {
    deleteMoDrugOrder(careplanId: $careplanId, drugOrderId: $drugOrderId, day: $day, cycle: $cycle) {
      success
    }
  }
`;

export const ADD_CYCLES_TO_PROTOCOL = gql`
  mutation addCyclesToProtocol($careplanId: ID!, $numberOfCycles: Int) {
    addCyclesToProtocol(careplanId: $careplanId, numberOfCycles: $numberOfCycles) {
      success
    }
  }
`;

export enum CareplanCycleAction {
  APPROVE = 'APPROVE',
  DOWNLOAD = 'DOWNLOAD',
  PRINT = 'PRINT',
}

export const UPDATE_CAREPLAN_CYCLE = gql`
  mutation updateCareplanCycle($careplanId: ID!, $cycleId: Int, $action: CareplanCycleAction!) {
    updateCareplanCycle(careplanId: $careplanId, cycleId: $cycleId, action: $action) {
      updated {
        id
        cycleId
        version
        approvedAt
        downloadedAt
        printedAt
      }
    }
  }
`;

export const ADD_CYCLE_BREAK_PERIOD = gql`
  mutation addCycleBreakPeriod($careplanId: ID!, $cycle: Int, $day: Int, $newDate: String, $propagate: Boolean) {
    addCycleBreakPeriod(careplanId: $careplanId, cycle: $cycle, day: $day, newDate: $newDate, propagate: $propagate) {
      success
    }
  }
`;
