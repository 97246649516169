import React from 'react';
import { styled } from '@mui/system';

interface Props {
  text: string;
  emailLink: string;
}

const SubSection = styled('section')`
  line-height: 24px;
  margin-bottom: 16px;
`;

const ModalContentSubmissionError = ({ text, emailLink }: Props): JSX.Element => (
  <SubSection>
    {text}
    <a href={`mailto:${emailLink}`}>{emailLink}</a>
  </SubSection>
);
export default ModalContentSubmissionError;
