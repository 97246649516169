import { CovidNavigator, HeaderBar } from 'op-components';
import React, { Component, Fragment } from 'react';
import { withApollo, WithApolloClient } from '@apollo/client/react/hoc';

import { Route, RouteComponentProps, Switch, withRouter } from 'react-router-dom';
import { StepperLink } from 'shared-components/interfaces';
import { CovidAssessmentApollo } from '../CovidAssessment';
import CovidInformation from '../CovidInformation/CovidInformation';
import { CovidPSOReviewApollo } from '../CovidPSOReview';
import './CovidContainer.scss';

interface Props extends WithApolloClient<{}>, RouteComponentProps<{ patientId: string }> {}

interface State {
  visitedPages: { [key: string]: number };
}

class CovidContainer extends Component<Props, State> {
  public constructor(props: Props) {
    super(props);

    this.state = {
      visitedPages: {},
    };
  }

  public static getDerivedStateFromProps(props: Props, state: State): State {
    const {
      location: { pathname },
    } = props;

    // Get the current form page
    const pathSegments = pathname.split('/');
    const currentPage = pathSegments[pathSegments.length - 1];

    // Add to state if it's not there.
    if (!Object.keys(state.visitedPages).includes(currentPage)) {
      state.visitedPages[currentPage] = 0;
    } else {
      state.visitedPages[currentPage] += 1;
    }

    return state;
  }

  public render(): JSX.Element {
    const {
      client,

      match: { path },
    } = this.props;

    const PAGE_LINKS: StepperLink[] = [
      {
        linkText: 'Covid-19 Information',
        href: '/information',
      },
      {
        linkText: 'Covid-19 Assessment',
        href: '/assessment',
      },
    ];

    const infoImage = (
      <div className="covid-information-image">
        <div className="covid-header-text">Covid-19 related questions</div>
      </div>
    );

    return (
      <Fragment>
        <HeaderBar headingText={'Covid-19'} menuButton={true} />
        <div id="covid-container" className="page-container full-viewport">
          <div id="foobar">
            <Switch>
              <Route path={`${path}/information`}>
                {infoImage}
                <CovidInformation />
              </Route>
              <Route path={`${path}/assessment`}>
                {infoImage}
                <CovidAssessmentApollo />
              </Route>
              <Route path={`${path}/review`}>
                <CovidPSOReviewApollo client={client} />
              </Route>
            </Switch>
            <CovidNavigator links={PAGE_LINKS} />
          </div>
        </div>
      </Fragment>
    );
  }
}

const apolloComponent = withApollo<Props>(CovidContainer);
const routedComponent = withRouter(apolloComponent);
export default routedComponent;
