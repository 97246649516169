// eslint-disable-next-line no-use-before-define
import React, { useCallback, useMemo } from 'react';
import { Formik, FormikProps } from 'formik';
import * as Yup from 'yup';
import TreatmentInfoForm, { FormValues } from './TreatmentInfoForm';
import useOncologyListData from '../useOncologyListData';
import { CAREPLAN_PAGE } from '../Constants';
import { useHistory, useRouteMatch } from 'react-router-dom';
import { MO_TREATMENT_INFO_MUTATION, MO_TREATMENT_INFO_QUERY } from '../Queries';
import { useMutation, useQuery } from '@apollo/client';
import { getOptionByValue } from 'op-pages/RO/Careplan/DiagnosisPages/Utils';
import moment from 'moment';
import { IS_CAREPLAN_APPROVED_ATLEAST_ONCE } from '../ProtocolSelectionPage/Queries';
import { SelectOptionType } from 'shared-components/components/FormFields';

const validationSchema = Yup.object().shape({
  readyForCareDate: Yup.date().required('Ready for Care Date required'),
  intent: Yup.object().required(),
  treatingDepartment: Yup.object().required(),
  patientAllergies: Yup.string().required(),
  patientAllergiesSpecify: Yup.string().when('patientAllergies', {
    is: (patientAllergies: string) => patientAllergies === 'Yes',
    then: Yup.string().required(),
  }),
});

const TreatmentInfoPage = (): JSX.Element => {
  const history = useHistory();
  const match = useRouteMatch<{ id?: string | undefined; careplanId?: string | undefined }>();
  const { id: patientId, careplanId } = match.params;
  const referenceData = useOncologyListData(['intent', 'treatingDepartment'], patientId);
  const intentOptions = useMemo(() => {
    return referenceData.intent
      ?.sort((a: any, b: any) => a.listWeight - b.listWeight)
      .map((intent) => {
        return { label: intent.option, value: intent.option };
      });
  }, [referenceData]);
  const treatingDepartmentOptions = useMemo(
    () =>
      referenceData.treatingDepartment
        ?.sort((a: any, b: any) => a.listWeight - b.listWeight)
        .map((treatingDepartment) => {
          return { label: treatingDepartment.option, value: treatingDepartment.option };
        }),
    [referenceData],
  );
  const { data: treatmentInfoData, loading: infoLoading } = useQuery(MO_TREATMENT_INFO_QUERY, {
    variables: { careplanId: careplanId, patientId: patientId },
  });
  const [updateTreatmentInfo] = useMutation(MO_TREATMENT_INFO_MUTATION, {
    refetchQueries: [
      { query: MO_TREATMENT_INFO_QUERY, variables: { careplanId: careplanId, patientId: patientId } },
      //'latestCycles',
    ],
  });
  const { data: approvalData } = useQuery(IS_CAREPLAN_APPROVED_ATLEAST_ONCE, {
    variables: { careplanId: careplanId },
    fetchPolicy: 'network-only',
  });

  const updateTreatmentInfoCallback = useCallback(
    (values: FormValues) => {
      if (values.readyForCareDate === null) return;
      updateTreatmentInfo({
        variables: {
          careplanId: careplanId,
          patientId: patientId,
          readyForCareDate: moment(values.readyForCareDate).startOf('day').format('YYYY-MM-DD'),
          intent: (values.intent as SelectOptionType)?.value,
          treatingDepartment: (values.treatingDepartment as SelectOptionType)?.value,
          allergy: values.patientAllergies,
          allergySpecify: values.patientAllergiesSpecify,
        },
      });
    },
    [updateTreatmentInfo, careplanId, patientId],
  );

  if (!referenceData || !referenceData?.intent || infoLoading) return <></>;
  const rfcDate = moment(treatmentInfoData.moTreatmentInfo?.readyForCareDate).startOf('day');
  return (
    <Formik
      initialValues={{
        readyForCareDate: rfcDate.toDate(),
        intent: getOptionByValue(intentOptions, treatmentInfoData.moTreatmentInfo?.intent || ''),
        treatingDepartment: getOptionByValue(
          treatingDepartmentOptions || [],
          treatmentInfoData.moTreatmentInfo?.treatingDepartment || '',
        ),
        patientAllergies: treatmentInfoData.moPatientAllergy?.allergy || '',
        patientAllergiesSpecify: treatmentInfoData.moPatientAllergy?.allergySpecify || '',
      }}
      validateOnChange={false}
      validateOnBlur={false}
      validateOnMount={false}
      validationSchema={validationSchema}
      onSubmit={(values) => {
        updateTreatmentInfoCallback(values);
        const dest = approvalData?.isCareplanApprovedOnce
          ? `${CAREPLAN_PAGE.PRESCRIPTION}/0`
          : CAREPLAN_PAGE.PROTOCOL_SELECTION;
        history.push(`/medonc/patient/${patientId}/careplan/${careplanId}/${dest}`);
      }}>
      {(props: FormikProps<FormValues>) => (
        <TreatmentInfoForm
          intentOptions={intentOptions}
          treatingDepartmentOptions={treatingDepartmentOptions}
          updateCallback={updateTreatmentInfoCallback}
          {...props}
        />
      )}
    </Formik>
  );
};

export default TreatmentInfoPage;
