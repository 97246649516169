/* eslint-disable no-console */
import moment from 'moment';
import { isUs } from 'op-utils';

export const CONSTANTS = {
  FULL_NAME: 'fullName',
  FORM: 'form',
  STATUS: 'status',
  LOCATION: 'location',
  LAST_MODIFIED: 'lastModified',
  REGISTRATION_STATUS: 'registrationStatus',
  FILTER_REVIEW_REQUIRED: 'filterReviewRequired',
  FILTER_IN_PROGRESS: 'filterInProgress',
  REVIEW_REQUIRED: 'Review Required',
  IN_PROGRESS: 'In Progress',
  REGISTRATION_FORM: 'registration',
  REGISTRATION_LABEL: 'Registration Form',
  COVID_FORM: 'covid',
  COVID_LABEL: 'COVID-19 Form',
  NO_PATIENTS_MESSAGE: 'No results to display',
  REVIEW_FORMS: 'Review Forms',
  DEFAULT_PAGINATION_LIMIT: 25,
  SELECT_ALL: 'Select All',
  BLANK: 'Blank',
};

export const TABLE_HEADERS = [
  'Patient Name',
  'Form',
  'Form Status',
  'Location',
  'Last Modified',
  'Registration Status',
];

export const formatTime = (time) => {
  const test = moment.utc(time).local();
  const formattedTime = moment.utc(time).local().format('hh:mm A');
  const today = moment().startOf('day');
  const diff = today.diff(test.startOf('day'), 'days');
  if (diff === 0) {
    return `Today ${formattedTime}`;
  } else return moment.utc(time).format(isUs() ? 'MM/DD/YYYY' : 'DD/MM/YYYY');
};

export const generateClassFromStatus = (status) => {
  if (status === CONSTANTS.FILTER_REVIEW_REQUIRED) {
    return 'review-required';
  }
  if (status === CONSTANTS.FILTER_IN_PROGRESS) {
    return 'in-progress';
  }
  return '';
};

export const formatStatusValue = (status) => {
  if (status === CONSTANTS.FILTER_REVIEW_REQUIRED) {
    return CONSTANTS.REVIEW_REQUIRED;
  }
  if (status === CONSTANTS.FILTER_IN_PROGRESS) {
    return CONSTANTS.IN_PROGRESS;
  }
  return '';
};

export const formatLocation = (locationId, locations) => {
  for (const value of Object.values(locations)) {
    if (value['id'] === locationId.toString()) {
      return value['alias'];
    }
  }
  return '';
};

export const generateFormUrl = (form, id, showNewRego, hasDataConflicts = false) => {
  if (form === CONSTANTS.COVID_FORM) {
    return `/patient/${id}/covid/review`;
  }
  if (form === CONSTANTS.REGISTRATION_FORM && (isUs() || showNewRego)) {
    if (hasDataConflicts) {
      return `/patient/${id}/registration/conflicts`;
    }
    return `/patient/${id}/registration/summary`;
  }
  if (form === CONSTANTS.REGISTRATION_FORM) {
    return `/registration/${id}/summary`;
  }
  return '';
};

export const generateFormName = (form) => {
  if (form === CONSTANTS.COVID_FORM) {
    return 'covid-form';
  }
  if (form === CONSTANTS.REGISTRATION_FORM) {
    return 'registration-form';
  }
  return 'form';
};

export const generateFormTypeValue = (type) => {
  if (type === CONSTANTS.REGISTRATION_FORM) return CONSTANTS.REGISTRATION_LABEL;
  if (type === CONSTANTS.COVID_FORM) return CONSTANTS.COVID_LABEL;
  return '';
};

export const mapFormData = (props) => {
  const { firstName, lastName, ida, overallStatus, formType, locationCode, updatedAt, status } = props;

  return [
    {
      name: CONSTANTS.FULL_NAME,
      value: `${firstName + ' ' + lastName}`,
      otherValue: `${ida}`,
      valueClass: 'url-underline',
      linkClass: 'name-ida-container',
      otherValueClass: 'ida',
    },
    {
      name: generateFormName(formType),
      value: generateFormTypeValue(formType),
      valueClass: 'url-underline',
    },
    {
      name: CONSTANTS.STATUS,
      value: formatStatusValue(status),
      valueClass: `form-status ${generateClassFromStatus(status)}`,
    },
    {
      name: CONSTANTS.LOCATION,
      value: locationCode,
    },
    {
      name: CONSTANTS.LAST_MODIFIED,
      value: formatTime(updatedAt),
    },
    {
      name: CONSTANTS.REGISTRATION_STATUS,
      value: formatStatusValue(overallStatus),
      valueClass: `form-status ${generateClassFromStatus(overallStatus)}`,
    },
  ];
};
