// eslint-disable-next-line no-use-before-define
import React, { Component, Fragment } from 'react';
import { StandardDialog } from 'shared-components/components';

import { COVID_SUBMIT } from './constants';
import { Stack } from '@mui/material';

interface Props {
  isOpen: boolean;
  isProd?: boolean;
  isPSO?: boolean;
  dismissFunction: () => void;
  submitForm: () => void;
  submitText: string;
  isComplete: boolean;
  continueNextForm: boolean;
}

interface State {
  locked: boolean;
}

class CovidSubmit extends Component<Props, State> {
  public static defaultProps = {
    isPSO: true,
  };

  public constructor(props: Props) {
    super(props);
    this.state = {
      locked: false,
    };
  }

  public render(): JSX.Element {
    const { dismissFunction, isOpen, submitForm, continueNextForm } = this.props;
    const { locked } = this.state;

    const headerText = this.props.isComplete
      ? continueNextForm
        ? COVID_SUBMIT.HEADER_TEXT_CONTINUE
        : COVID_SUBMIT.HEADER_TEXT_EXIT
      : COVID_SUBMIT.HEADER_TEXT_ERROR;

    const buttonText = this.props.isComplete
      ? continueNextForm
        ? COVID_SUBMIT.SUBMIT_AND_CONTINUE
        : COVID_SUBMIT.SUBMIT_AND_EXIT
      : COVID_SUBMIT.FIX_ERRORS;

    return (
      <StandardDialog
        id="covid-submit-modal"
        open={isOpen}
        onClose={dismissFunction}
        title={headerText}
        onSubmit={() => {
          this.props.isComplete ? submitForm() : dismissFunction();
        }}
        submitButtonProps={{
          disabled: locked,
        }}
        submitText={buttonText}>
        <Stack paddingY={2}>{this.props.isComplete ? this.renderChangeOfCircumstance() : this.renderErrorText()}</Stack>
      </StandardDialog>
    );
  }

  private renderErrorText = (): JSX.Element => {
    return <div>{COVID_SUBMIT.MODAL_TEXT_ERROR}</div>;
  };

  private renderChangeOfCircumstance = (): JSX.Element => {
    return (
      <Fragment>
        <div>
          <div>{COVID_SUBMIT.MODAL_TITLE}</div>
          <div>{COVID_SUBMIT.MODAL_TEXT}</div>
        </div>
      </Fragment>
    );
  };
}

export default CovidSubmit;
