// eslint-disable-next-line no-use-before-define
import { useLazyQuery, useMutation } from '@apollo/client';
import { ReferralType } from 'op-enums';
import { LOG_EVENT } from 'op-graphql/Logging';
import React, { useEffect, useMemo, useRef, useState } from 'react';

import { LoadingSpinner, OnboardingPopover } from 'shared-components/components';
import { Edit } from 'shared-components/images';
import { GraphQLID, PatientReferrerRego } from 'shared-components/interfaces';
import { OnboardingPopoverPageLogData } from 'shared-components/interfaces/PageLogData';
import { logPage, PRACTITIONER_LOG_EVENTS } from 'shared-components/utils/ElasticsearchHelpers';
import { PATIENT_AND_USER_DETAILS_QUERY } from './RegistrationReferrerQueries';
import { REGISTRATION_REFERRER_QUERY } from '../RegistrationFormAU/Referrer/ReferrerQueries';
import { GP_PRACTITIONER_SELECTED, PRACTITIONER_SEARCH_QUERY, SPECIALIST_SELECTED } from './graphql';
import { PractitionerSearchQueryItem, PractitionerSearchQueryResultData } from './interfaces';
import { Table, TableBody, TableCell, TableHead, TableRow, styled, tableCellClasses, Button } from '@mui/material';

import './PractitionerSearch.scss';

interface PractitionerSearchIProps {
  referralType: ReferralType;
  userInfo: PatientReferrerRego;
  closeModal: (status: boolean) => void;
  makeFieldsEditable: (referral: ReferralType) => void;
  disableFields: (referral: ReferralType) => void;
  logTime?: boolean;
  enableLogging?: boolean;
  popOverOpen?: boolean;
  popOverClose?: () => void;
  hidePopOverComponent?: boolean;
}

interface PageLogData {
  eventName: PRACTITIONER_LOG_EVENTS;
  patientHzId: GraphQLID;
  referralType: ReferralType;
  searchTerm?: string;
  initialSearchTerm?: string;
  selectedPractitionerHzId?: number;
  resultCount?: number;
  searchCount?: number;
}

const StyledTableCell = styled(TableCell)(({ theme }) => ({
  [`&.${tableCellClasses.head}`]: {
    backgroundColor: theme.palette.grey[200],
    color: theme.palette.common.black,
    fontWeight: 600,
  },
  [`&.${tableCellClasses.body}`]: {
    fontSize: 14,
  },
}));

const StyledTableRow = styled(TableRow)(({ theme }) => ({
  cursor: 'pointer',
  '&:hover': {
    backgroundColor: theme.palette.action.hover,
  },
  // hide last border
  '&:last-child td, &:last-child th': {
    border: 0,
  },
}));

const PractitionerSearch = (props: PractitionerSearchIProps): JSX.Element => {
  const {
    referralType,
    userInfo,
    closeModal,
    makeFieldsEditable,
    disableFields,
    logTime = true,
    enableLogging = false,
    popOverOpen,
    popOverClose,
    hidePopOverComponent = false,
  } = props;

  const [searchEventCount, setSearchEventCount] = useState<number>(0);
  const [searchText, setsearchText] = useState<string>('');
  const [isPopOverOpen, setIsPopOverOpen] = useState<boolean>(false);
  const anchorEl = useRef<HTMLDivElement>(null);

  const initialSearchTerm = useMemo<string>(() => {
    if (userInfo) {
      return referralType === ReferralType.GP
        ? `${userInfo.gpFirstName || ''} ${userInfo.gpLastName || ''} ${userInfo.gpPractice || ''}`
        : `${userInfo.referringFirstName || ''} ${userInfo.referringLastName || ''} ${
            userInfo.referringPractice || ''
          }`;
    } else {
      return '';
    }
  }, [userInfo]);

  const startTime = useRef<number>(new Date().getTime());
  let pageData: Partial<PageLogData> = {
    patientHzId: userInfo.id,
    referralType,
  };

  /** GraphQL */
  const [logEvent] = useMutation(LOG_EVENT);

  const [searchPractitionerQuery, { data: searchPractitionerData, loading: searchPractitionerIsLoading }] =
    useLazyQuery(PRACTITIONER_SEARCH_QUERY);

  const refetchQueries = [
    { query: REGISTRATION_REFERRER_QUERY, variables: { id: userInfo?.id } }, // Newer Patient Rego
    { query: PATIENT_AND_USER_DETAILS_QUERY, variables: { id: userInfo.id } }, // Older Patient Rego
  ];

  const mutateCommonObject: any = { id: userInfo.id };
  const [gpMutate, { loading: gpMutateLoading }] = useMutation(GP_PRACTITIONER_SELECTED, {
    variables: mutateCommonObject,
    refetchQueries,
  });
  const [referrerMutate, { loading: referrerMutateLoading }] = useMutation(SPECIALIST_SELECTED, {
    variables: mutateCommonObject,
    refetchQueries,
  });

  const isLoading = useMemo(
    () => searchPractitionerIsLoading || gpMutateLoading || referrerMutateLoading,
    [searchPractitionerIsLoading || gpMutateLoading || referrerMutateLoading],
  );

  /** Handlers */
  const handleCannotFindMatch = (): void => {
    if (enableLogging) {
      pageData = {
        ...pageData,
        eventName: PRACTITIONER_LOG_EVENTS.PRACTITIONER_OVERRIDE_ENTRY,
      };
      logPage(startTime?.current, pageData, logEvent, logTime);
    }

    makeFieldsEditable(referralType);
    closeModal(true);
  };

  const handleSearchPractitioner = () => {
    setSearchEventCount(searchEventCount + 1);

    if (enableLogging) {
      /** Log Practitioner searching */
      pageData = {
        ...pageData,
        eventName: PRACTITIONER_LOG_EVENTS.PRACTITIONER_SEARCHED,
        initialSearchTerm,
        searchTerm: searchText,
      };
      logPage(startTime?.current, pageData, logEvent, logTime);
    }

    searchPractitionerQuery({ variables: { searchTerm: searchText } });
  };

  const handleSelectPractitioner = (prac: PractitionerSearchQueryItem): void => {
    const practionerId = parseInt(prac.id);

    if (enableLogging) {
      pageData = {
        ...pageData,
        eventName: PRACTITIONER_LOG_EVENTS.PRACTITIONER_SELECTED,
        selectedPractitionerHzId: practionerId,
        searchCount: searchEventCount,
      };
      logPage(startTime?.current, pageData, logEvent, logTime);
    }

    if (referralType === ReferralType.GP) {
      gpMutate({
        variables: {
          gpId: practionerId,
        },
      });
      disableFields(referralType);
      closeModal(true);
    } else if (referralType === ReferralType.SPECIALIST) {
      referrerMutate({
        variables: {
          referringId: practionerId,
        },
      });
      disableFields(referralType);
      closeModal(true);
    }
  };

  useEffect(() => {
    if (userInfo) {
      const searchTerm = initialSearchTerm;
      const trimmedSearchTerm = searchTerm.trim();
      if (trimmedSearchTerm) setsearchText(trimmedSearchTerm);
      searchPractitionerQuery({ variables: { searchTerm: trimmedSearchTerm } });
    }
  }, [userInfo]);

  useEffect(() => {
    if (!searchPractitionerIsLoading) {
      // Only log when it resolves
      const searchPractitionerDataLength = searchPractitionerData?.searchPractitionersFuzzy?.length;
      if (searchPractitionerDataLength > 0) {
        if (enableLogging) {
          /** Log result metadata */
          pageData = {
            ...pageData,
            eventName: PRACTITIONER_LOG_EVENTS.PRACTITIONER_RESULTS,
            searchTerm: searchText,
            resultCount: searchPractitionerDataLength,
          };
          logPage(startTime?.current, pageData, logEvent, logTime);
        }
      } else {
        if (enableLogging) {
          /** Log no results */
          pageData = {
            ...pageData,
            eventName: PRACTITIONER_LOG_EVENTS.PRACTITIONER_NOT_FOUND,
            initialSearchTerm,
          };
          logPage(startTime?.current, pageData, logEvent, logTime);
        }
      }
    }
  }, [searchPractitionerIsLoading]);

  useEffect(() => {
    setTimeout(() => {
      setIsPopOverOpen(!!popOverOpen);
    }, 500);
  }, []);

  /** Renderers */
  const renderSearchResult = (): JSX.Element => {
    const renderResults = (data: PractitionerSearchQueryResultData): JSX.Element[] => {
      return data.searchPractitionersFuzzy
        .filter((practitioner) => practitioner.isInMosaiq && practitioner.providerNumber && practitioner.location)
        .map((practitioner: PractitionerSearchQueryItem, pracIndex: number): JSX.Element => {
          return (
            <StyledTableRow
              key={`${practitioner.providerNumber}-${pracIndex}`}
              onClick={() => handleSelectPractitioner(practitioner)}>
              <TableCell>
                {practitioner.prefix} {practitioner.firstName} {practitioner.lastName}
              </TableCell>
              <TableCell>{practitioner.location}</TableCell>
              <TableCell>{practitioner.providerNumber}</TableCell>
            </StyledTableRow>
          );
        });
    };

    const renderEmptyResult = (): JSX.Element => {
      return (
        <TableRow>
          <TableCell align="center" colSpan={3}>
            {'No results found'}
          </TableCell>
        </TableRow>
      );
    };

    if (isLoading) return <LoadingSpinner relativeSpinner />;

    return (
      <div className="results">
        <div id="practitioner-search-results" data-test="practitioner-search-results">
          <Table data-testid="practitioner-search-results-table">
            <TableHead>
              <TableRow>
                <StyledTableCell>{'Doctor Name'}</StyledTableCell>
                <StyledTableCell>{'Practice / Medical Centre'}</StyledTableCell>
                <StyledTableCell>{'Provider ID'}</StyledTableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {searchPractitionerData &&
              searchPractitionerData.searchPractitionersFuzzy &&
              searchPractitionerData.searchPractitionersFuzzy.length > 0 &&
              searchPractitionerData.searchPractitionersFuzzy.filter(
                (practitioner: any) => practitioner.isInMosaiq && practitioner.providerNumber && practitioner.location,
              ).length > 0
                ? renderResults(searchPractitionerData)
                : renderEmptyResult()}
            </TableBody>
          </Table>
        </div>
      </div>
    );
  };

  return (
    <>
      <div id="practitioner-search" ref={anchorEl}>
        <OnboardingPopover
          handleClose={() => {
            setIsPopOverOpen(false);
            popOverClose && popOverClose();
          }}
          handleVisitLink={() => {}}
          popoverOpen={isPopOverOpen}
          target={anchorEl.current}
          feature="GP Referrer"
          handleNext={() => {
            setIsPopOverOpen(false);
            popOverClose && popOverClose();
          }}
          step="3"
          hidePopOverComponent={hidePopOverComponent}
          logEvent={(pageData: OnboardingPopoverPageLogData) =>
            logEvent({ variables: { data: JSON.stringify(pageData) } })
          }>
          <div className="practitioner-search-input-container">
            <div className="icon"></div>
            <input
              type="text"
              id="practitioner-search-input"
              data-testid="practitioner-search-input"
              name="practitioner-search"
              placeholder="E.g. John Smith, Sally Thomas ..."
              value={searchText}
              onChange={(e: React.ChangeEvent<HTMLInputElement>) => setsearchText(e.target.value)}
              onKeyUp={(e: React.KeyboardEvent<HTMLInputElement>) => {
                if (e.key === 'Enter') handleSearchPractitioner();
              }}
              style={{ fontSize: '16px', fontFamily: 'Poppins, sans-serif' }}
            />
            <Button
              type="submit"
              data-testid="practitioner-search-button"
              variant="contained"
              onClick={handleSearchPractitioner}>
              Search
            </Button>
          </div>
          <div className="cant-find-match" onClick={handleCannotFindMatch} data-test="practitioner-search-non-match">
            <Edit className="edit-pencil" />
            {"Can't find match?"}
          </div>
        </OnboardingPopover>
      </div>
      {renderSearchResult()}
    </>
  );
};

export default PractitionerSearch;
