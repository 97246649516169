import { useMutation } from '@apollo/client';
import { Field, Form, Formik, FormikProps } from 'formik';
import dayjs from 'dayjs';
import { FormContainer } from 'op-components';
import { mapListData } from 'op-utils';
import { RegistrationContext } from 'op-contexts';
import React, { useContext } from 'react';
import { generatePath } from 'react-router';
import { useHistory, useParams } from 'react-router-dom';
import { FormRow } from 'shared-components/components';
import { RODatePicker } from 'shared-components/components/FormFields';
import { AddressSectionField, MaskField } from 'shared-components/components/FormikComponents';
import { Address, ListData } from 'shared-components/interfaces';
import {
  CONTINUE_BUTTON_TEXT,
  getDisplayValue,
  HeaderSubTitle,
  HeaderTitle,
  PSO_SUMMARY_LINK,
  registrationPath,
  RETURN_TO_SUBMIT_TEXT,
  sharedFormContainerProps,
  SPECIFY_MARITAL_FIELDS,
  standardField,
  StyledIndentedSection,
} from '../Helper';
import { PatientDemographics, RegistrationFormUSIProps } from '../interfaces';
import { REMOVE_PATIENT_ADDRESS, UPDATE_PATIENT_ADDRESS } from '../RegistrationFormQueries';
import { ALTERNATE_ADDRESS_OPTIONS, FIELDS, SPECIFY_RACE_FIELDS, SPOUSE_WORKING_STATUS_OPTIONS } from './constants';
import { DEMOGRAPHICS_QUERY } from './DemographicsQueries';

interface RegistrationDemographicsFormUSIProps extends RegistrationFormUSIProps {
  patient: PatientDemographics;
  readonly refData: {
    readonly maritalStatusRefData: ListData[];
    readonly ancestryRefData: ListData[];
    readonly raceRefData: ListData[];
    readonly languageRefData: ListData[];
  };
}

const RegistrationDemographicsFormUS = (props: RegistrationDemographicsFormUSIProps): JSX.Element => {
  const {
    patient: savedPatient,
    updateField,
    handleShowSaveExitConfirm,
    previousPageLink,
    refData: { maritalStatusRefData, ancestryRefData, raceRefData, languageRefData },
    isPso,
  } = props;

  const registrationContext = useContext(RegistrationContext);
  const patient = {
    ...savedPatient,
    maritalStatus: getDisplayValue(maritalStatusRefData, savedPatient.maritalStatus),
    ancestry: getDisplayValue(ancestryRefData, savedPatient.ancestry),
    race: getDisplayValue(raceRefData, savedPatient.race),
    languageAtHome: getDisplayValue(languageRefData, savedPatient.languageAtHome),
  };

  const goToSummary = registrationContext?.registrationSummaryVisited && !isPso;
  const history = useHistory();
  const { patientId } = useParams<{ patientId: string }>();

  const refetchQueries = [{ query: DEMOGRAPHICS_QUERY, variables: { id: patientId } }];

  const [updatePatientAddressMutation] = useMutation(UPDATE_PATIENT_ADDRESS, {
    refetchQueries: refetchQueries,
  });
  const [removePatientAddressMutation] = useMutation(REMOVE_PATIENT_ADDRESS, {
    refetchQueries: refetchQueries,
  });
  const referringPage = sessionStorage.getItem('referringPage');

  return (
    <>
      <Formik
        initialValues={patient}
        onSubmit={() => history.push(registrationPath(patient?.id, goToSummary ? 'summary' : 'socialHistory'))}>
        {({ errors, submitForm, setFieldValue, values }: FormikProps<any>) => {
          const sharedProps = sharedFormContainerProps('Demographics details', 7, previousPageLink, !isPso);
          const formContainerProps = {
            ...sharedProps,
            submitButtonText: goToSummary ? RETURN_TO_SUBMIT_TEXT : CONTINUE_BUTTON_TEXT,
            continueDisabled: Object.keys(errors).length > 0,
            handleShowSaveExitConfirm,
            saveAndExitLink: isPso && generatePath(referringPage || PSO_SUMMARY_LINK, { patientId }),
          };

          const removePatientUsSpouseAlternateAddress = () => {
            setFieldValue(`${FIELDS.ALTERNATE_ADDRESS.NAME}.line1`, '');
            setFieldValue(`${FIELDS.ALTERNATE_ADDRESS.NAME}.line2`, '');
            setFieldValue(`${FIELDS.ALTERNATE_ADDRESS.NAME}.city`, '');
            setFieldValue(`${FIELDS.ALTERNATE_ADDRESS.NAME}.postcode`, '');
            setFieldValue(`${FIELDS.ALTERNATE_ADDRESS.NAME}.state`, '');
            setFieldValue(`${FIELDS.ALTERNATE_ADDRESS.NAME}.country`, '');
            removePatientAddressMutation({
              variables: {
                patientId,
                relatedName: 'spouse_alternate_address',
              },
            });
          };

          const updateMaritalStatus = (name: string, value: string | boolean | number) => {
            updateField(name, value);
            if (!SPECIFY_MARITAL_FIELDS.includes(value as string)) {
              setFieldValue(FIELDS.SPOUSE_WORKING_STATUS.NAME, '');
              setFieldValue(FIELDS.SPOUSE_NAME.NAME, '');
              setFieldValue(FIELDS.SPOUSE_DOB.NAME, '');
              setFieldValue(FIELDS.SPOUSE_SSN.NAME, '');
              setFieldValue(FIELDS.SPOUSE_CONTACT.NAME, '');
              setFieldValue(FIELDS.ALTERNATE_ADDRESS_BOOL.NAME, '');
              removePatientUsSpouseAlternateAddress();
            }
          };

          const updateAlternateAddressBool = (name: string, value: string | boolean | number) => {
            updateField(name, value);
            if (!value) {
              removePatientUsSpouseAlternateAddress();
            }
          };

          const updateRace = (name: string, value: string | boolean | number) => {
            updateField(name, value);
            if (!SPECIFY_RACE_FIELDS.includes(value as string)) {
              setFieldValue(FIELDS.RACE_SPECIFIED.NAME, '');
            }
          };

          // Need this as autocomplete doesn't show new value after removing enableReinitialize
          const updateAutocomplete = (name: string, value: string | boolean | number) => {
            updateField(name, value);
            setFieldValue(name, value);
          };

          const handleUpdateAddress = (relatedName: string, address: Address) => {
            updatePatientAddressMutation({
              variables: {
                patientId,
                relatedName,
                line1: address?.line1 ?? '',
                line2: address?.line2 ?? '',
                city: address?.city ?? '',
                postcode: address?.postcode ?? '',
                state: address?.state ?? '',
                country: address?.country ?? '',
              },
            });
          };

          const showSpecifyRace = SPECIFY_RACE_FIELDS.includes(values?.race);
          const showMaritalSubForm = SPECIFY_MARITAL_FIELDS.includes(values?.maritalStatus);

          return (
            <FormContainer
              {...formContainerProps}
              submitForm={submitForm}
              handleValidation={(event: React.MouseEvent<HTMLInputElement>): void => {
                void event;
              }}>
              <Form>
                <HeaderTitle data-test-id="registration-demographics-page-title">
                  {FIELDS.FORM_HEADING.TITLE}
                </HeaderTitle>
                <HeaderSubTitle data-test-id="registration-demographics-page-subtitle">
                  * = required field
                </HeaderSubTitle>

                {/* Primary Occupation */}
                {standardField(FIELDS.PRIMARY_OCCUPATION, updateField)}

                {/* Marital Status */}
                {standardField(FIELDS.MARITAL_STATUS, updateMaritalStatus, mapListData(maritalStatusRefData))}

                {showMaritalSubForm && (
                  <StyledIndentedSection>
                    {standardField(FIELDS.SPOUSE_WORKING_STATUS, updateField, SPOUSE_WORKING_STATUS_OPTIONS)}
                    {standardField(FIELDS.SPOUSE_NAME, updateField)}

                    {/* Spouse DOB */}
                    <FormRow
                      fieldLabel={FIELDS.SPOUSE_DOB.TITLE}
                      fieldName={FIELDS.SPOUSE_DOB.NAME}
                      labelClass={'label-form-row'}>
                      <Field
                        name={FIELDS.SPOUSE_DOB.NAME}
                        component={RODatePicker}
                        alternateStyle
                        isUs
                        id={FIELDS.SPOUSE_DOB.NAME}
                        value={patient?.spouseDob ? dayjs(patient.spouseDob).toDate() : null}
                        disableFuture
                        isManualEditEnabled
                        onChange={(date: Date | null): void => {
                          const newDate = date ? dayjs(date).format('YYYY-MM-DD') : '';
                          updateField(FIELDS.SPOUSE_DOB.NAME, newDate);
                        }}
                      />
                    </FormRow>

                    {/* Spouse SSN */}
                    <FormRow
                      fieldLabel={FIELDS.SPOUSE_SSN.TITLE}
                      fieldName={FIELDS.SPOUSE_SSN.NAME}
                      labelClass={'label-form-row'}>
                      <Field
                        name={FIELDS.SPOUSE_SSN.NAME}
                        component={MaskField}
                        defaultValue={patient.spouseSocialSecurityNumber}
                        inputType="text"
                        updateMutation={(value: string) => updateField(FIELDS.SPOUSE_SSN.NAME, value)}
                        mask="999 - 99 - 9999"
                        maskChar="X"
                      />
                    </FormRow>

                    {standardField(FIELDS.SPOUSE_CONTACT, updateField)}
                    {standardField(
                      FIELDS.ALTERNATE_ADDRESS_BOOL,
                      updateAlternateAddressBool,
                      ALTERNATE_ADDRESS_OPTIONS,
                    )}
                    {values?.spouseAlternateAddressBool && (
                      <AddressSectionField
                        name={FIELDS.ALTERNATE_ADDRESS.NAME}
                        values={patient.spouseAlternateAddress || {}}
                        updateAddress={(address: Address) => handleUpdateAddress('spouse_alternate_address', address)}
                      />
                    )}
                  </StyledIndentedSection>
                )}

                {standardField(FIELDS.RACE, updateRace, mapListData(raceRefData))}
                {showSpecifyRace && standardField(FIELDS.RACE_SPECIFIED, updateField, [], false)}
                {standardField(FIELDS.ANCESTRY, updateField, mapListData(ancestryRefData))}
                {standardField(FIELDS.LANGUAGE, updateAutocomplete, mapListData(languageRefData))}
              </Form>
            </FormContainer>
          );
        }}
      </Formik>
    </>
  );
};

export default RegistrationDemographicsFormUS;
