import { useQuery } from '@apollo/client';
import { SummaryCard, SummaryRow } from 'op-components';
import { evaluateDetailText } from 'op-components/OP/SummaryRow/SummaryRow';
import { PAIN_ASSESSMENT_FIELDS, YES } from 'op-pages/OP/HealthAssessment/HAPages/constants';
import { AccordionBooleanData } from 'op-pages/OP/HealthAssessment/HASharedComponents';
import { renderArrow } from 'op-pages/OP/HealthAssessment/HASharedComponents/HAModalFields';
import { HA_PAIN_QUERY } from 'op-pages/OP/HealthAssessment/HASharedComponents/queries';
import {
  HAPainItem,
  HASubComponentProps,
  PainQualityObject,
} from 'op-pages/OP/HealthAssessment/HASharedComponents/types';
import React, { useMemo } from 'react';
import { Edit, Success } from 'shared-components/images';

const HAPainAssessmentUS = (props: HASubComponentProps): JSX.Element => {
  const { patientId, isPso, navigate } = props;

  const { data: haPainAssessmentData, loading } = useQuery(HA_PAIN_QUERY, {
    variables: { patientId },
  });

  const data = useMemo<HAPainItem>(() => haPainAssessmentData?.healthAssessment, [haPainAssessmentData]);
  const painQuality = useMemo<PainQualityObject>(
    () => JSON.parse(haPainAssessmentData?.healthAssessment?.painQuality || '{}'),
    [haPainAssessmentData?.healthAssessment?.painQuality],
  );
  const painQualityHasEntry = useMemo<boolean>(
    () =>
      Object.entries(painQuality)
        .map((a: [string, AccordionBooleanData]) => a[1])
        .filter((a: AccordionBooleanData) => a.value).length > 0,
    [painQuality],
  );
  const orderedPainQualityKeys = useMemo<string[]>(() => {
    const items = [];

    for (const itemKey in painQuality) {
      items.push({
        //@ts-ignore
        ...painQuality[itemKey],
        key: itemKey,
      });
    }

    return [...items]
      .sort((a, b) => {
        if (a.id < b.id) return -1;
        else if (a.id < b.id) return 1;
        else return 0;
      })
      .map((item) => item.key);
  }, [painQuality]);

  if (loading) {
    return <></>;
  }

  if (haPainAssessmentData) {
    return (
      <SummaryCard
        id="pain-assessment"
        title={'Pain assessment'}
        primaryIcon={isPso ? <></> : <Edit className="primary-icon" />}
        secondaryIcon={<Success className="secondary-icon valid-icon" />}
        showEdit={!isPso}
        onClick={() => {
          if (!isPso) {
            navigate('painAssessment');
          }
        }}>
        {/* In Pain */}
        <SummaryRow
          headingText={PAIN_ASSESSMENT_FIELDS.IN_PAIN.TITLE}
          detailText={evaluateDetailText(data.inPain)}
          displayAsError={false}
          displayAsOptional={false}
        />
        {data.inPain === YES && (
          <>
            {renderArrow()}
            <SummaryRow
              headingText={PAIN_ASSESSMENT_FIELDS.PAIN_START.TITLE}
              detailText={data.painStart}
              displayAsError={false}
              displayAsOptional={false}
              subQuestion
            />
            <SummaryRow
              headingText={PAIN_ASSESSMENT_FIELDS.PAIN_SEVERITY.TITLE}
              detailText={evaluateDetailText(data.painSeverity)}
              displayAsError={false}
              displayAsOptional={false}
              subQuestion
            />

            <SummaryRow
              headingText={PAIN_ASSESSMENT_FIELDS.PAIN_LOCATION.TITLE}
              detailText={evaluateDetailText(data.painLocation)}
              displayAsError={false}
              displayAsOptional={false}
              subQuestion
            />

            {/* Quality of Pain */}
            <SummaryRow
              headingText={PAIN_ASSESSMENT_FIELDS.PAIN_QUALITY.TITLE}
              detailText={null}
              fallbackText={painQualityHasEntry ? ' ' : ''}
              displayAsError={false}
              displayAsOptional={false}
            />

            {painQualityHasEntry && (
              <>
                {orderedPainQualityKeys
                  //@ts-ignore
                  .filter((key: string) => painQuality[key].value)
                  .map((key: string, index: number) => (
                    <SummaryRow
                      key={index}
                      // @ts-ignore
                      headingText={painQuality[key].name}
                      detailText={null}
                      fallbackText=" "
                      displayAsError={false}
                      displayAsOptional={false}
                      subQuestion
                    />
                  ))}
              </>
            )}

            {/* Pain Length */}
            <SummaryRow
              headingText={PAIN_ASSESSMENT_FIELDS.PAIN_LENGTH.TITLE}
              detailText={evaluateDetailText(data.painLength)}
              displayAsError={false}
              displayAsOptional={false}
            />

            {/* Pain Management */}
            <SummaryRow
              headingText={PAIN_ASSESSMENT_FIELDS.PAIN_MANAGEMENT.TITLE}
              detailText={evaluateDetailText(data.painManagement)}
              displayAsError={false}
              displayAsOptional={false}
            />

            {/* How to make it better */}
            <SummaryRow
              headingText={PAIN_ASSESSMENT_FIELDS.PAIN_BETTER.TITLE}
              detailText={evaluateDetailText(data.painBetter)}
              displayAsError={false}
              displayAsOptional={false}
            />

            {/* What is making it worse */}
            <SummaryRow
              headingText={PAIN_ASSESSMENT_FIELDS.PAIN_WORSE.TITLE}
              detailText={evaluateDetailText(data.painWorse)}
              displayAsError={false}
              displayAsOptional={false}
            />
          </>
        )}
      </SummaryCard>
    );
  }

  return <></>;
};

export default HAPainAssessmentUS;
