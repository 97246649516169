import { SelectField, TextAreaField, ToggleButtonGroupField } from 'shared-components/components/FormikComponents';

export const BASIC_FIELDS = {
  FORM_HEADING: {
    NAME: 'formHeading',
    TITLE: 'Basic Details',
  },
  TITLE_PREFIX: {
    NAME: 'namePrefix',
    TITLE: 'Title',
    PLACE_HOLDER: 'Please select',
    COMPONENT: SelectField,
  },
  FIRST_NAME: {
    NAME: 'firstName',
    TITLE: 'First name*',
    PLACE_HOLDER: 'Enter first name',
    COMPONENT: TextAreaField,
  },
  MIDDLE_NAME: {
    NAME: 'middleName',
    TITLE: 'Middle name',
    PLACE_HOLDER: 'Enter middle name',
    COMPONENT: TextAreaField,
  },
  LAST_NAME: {
    NAME: 'lastName',
    TITLE: 'Last name*',
    PLACE_HOLDER: 'Enter last name',
    COMPONENT: TextAreaField,
  },
  PREFERRED_NAME: {
    NAME: 'preferredName',
    TITLE: 'Preferred name',
    PLACE_HOLDER: 'If different than first name',
    COMPONENT: TextAreaField,
  },
  GENDER: {
    NAME: 'gender',
    TITLE: 'Sex*',
    COMPONENT: ToggleButtonGroupField,
  },
  DOB_RAW: {
    NAME: 'dob',
    TITLE: 'Date of birth*',
  },
  SSN: {
    NAME: 'socialSecurityNumber',
    TITLE: 'Social Security Number (SSN)',
    PLACE_HOLDER: '',
  },
};

export const NOT_VALID = 'This is not a valid SSN';
