import React, { useState } from 'react';

const DTContext = React.createContext<any>({});

export const DTContextProvider = ({ children }: any): JSX.Element => {
  const [selectedRating, setSelectedRating] = useState<number>(-1);

  return (
    <DTContext.Provider
      value={{
        selectedRating,
        setSelectedRating,
      }}>
      {children}
    </DTContext.Provider>
  );
};

export default DTContext;
