export default class AppointmentDetailsStaffViewModel {
  // Variables
  private staffName?: string;
  private staffTitle?: string;
  private staffPrefix?: string;

  // CONSTANTS
  private TITLES_REQUIRING_PREFIX_DISPLAYED = ['doctor', 'med oncologist', 'radiation oncologist'];

  /**
   * Constructor method
   * @param {string} staffName The name of the staff. (Optional)
   * @param {string} staffTitle The title of the staff. (Optional)
   * @param {string} staffPrefix The prefix of the staff, e.g. 'Dr'. (Optional)
   */
  constructor(staffName?: string, staffTitle?: string, staffPrefix?: string) {
    this.staffName = staffName;
    this.staffTitle = staffTitle;
    this.staffPrefix = staffPrefix;
  }

  /**
   * Gets the staff's display name, which may or may not include the staff's prefix depending on whether
   * it matches the list of identified titles for which the prefix should be displayed.
   * @returns {string} The staff's display name, or undefined if the staff name is not defined.
   */
  public getStaffDisplayName = (): string | undefined => {
    if (
      this.staffName &&
      this.staffTitle &&
      this.staffPrefix &&
      this.TITLES_REQUIRING_PREFIX_DISPLAYED.includes(this.staffTitle.toLowerCase())
    ) {
      return this.staffPrefix + ' ' + this.staffName;
    }
    return this.staffName;
  };

  /**
   * Gets the staff's display title.
   * @returns {string} The staff's display title, or undefined if the staff title is not defined.
   */
  public getStaffDisplayTitle = (): string | undefined => {
    return this.staffTitle;
  };
}
