// eslint-disable-next-line no-use-before-define
import { gql } from '@apollo/client';
import { Query } from '@apollo/client/react/components';
import { WithApolloClient, withApollo } from '@apollo/client/react/hoc';
import { Component, Fragment } from 'react';

import { RouteComponentProps } from 'react-router';

import { BASE_REGISTRATION_PAGES } from 'op-enums';
import { OPUser, Patient, PatientAddress } from 'op-interfaces';
import { Region, SavingStatus } from 'shared-components/enums';
import { GraphUpdate, ListData } from 'shared-components/interfaces';

import { ExtendLock } from 'op-components';
import { LoadingSpinner } from 'shared-components/components';
import withRegistrationForm, { GET_APOLLO_CACHE, WithRegistrationForm } from '../RegistrationForm';
import RegistrationAddress from './RegistrationAddress';

const PATIENT_AND_USER_DETAILS_QUERY = gql`
  query PatientAndUserDetails($id: ID!, $stateKey: String!) {
    patient(id: $id) {
      id
      lock {
        lockedBy
        readOnly
        lockedByName
      }
      firstName
      lastName
      ida
      idb
      gender
      dob
      isProd
      registrationReason
      residentialAddressLine1
      residentialAddressLine2
      residentialAddressCity
      residentialAddressState
      residentialAddressPostcode
      residentialAddressCountry
      postalAddressSameAsResidential
      postalAddressLine1
      postalAddressLine2
      postalAddressCity
      postalAddressState
      postalAddressPostcode
      postalAddressCountry
      lastVisitedSection
      regFormStatus
      address {
        id
        formattedAddress
        ukFormattedAddress
      }
    }
    countries: listData(category: "countryOfBirth") {
      id
      name
    }
    ausState: listData(category: $stateKey) {
      id
      name
    }
    genderRefData: listData(category: "gender") {
      id
      name
    }
    user {
      id
      isPso
    }
  }
`;

interface State {
  saveStatus: SavingStatus;
  pageViewed: boolean;
}

interface PatientAndUserDetailsQueryData {
  patient: PatientAddress;
  countries: ListData[];
  ausState: ListData[];
  genderRefData: ListData[];
  user: OPUser;
}

interface Props
  extends WithApolloClient<{}>,
    WithRegistrationForm,
    RouteComponentProps<{
      patientId?: string;
    }> {}

class RegistrationAddressApollo extends Component<Props, State> {
  private userIsPSO = false;

  public constructor(props: Props) {
    super(props);
    this.state = { saveStatus: SavingStatus.SAVED, pageViewed: false };
  }

  public componentDidMount(): void {
    const { client } = this.props;
    let viewedPages: string[] = [];

    // Get the apollo cache values
    this.getApolloCache().then((apolloCache): void => {
      const currentPendingSaveCount = apolloCache.currentPendingSaveCount;
      const saveErrorCount = apolloCache.saveErrorCount;
      const registrationPagesViewed = [...apolloCache.registrationPagesViewed];
      viewedPages = registrationPagesViewed;
      this.setState({
        saveStatus: this.props.getSaveStatus(currentPendingSaveCount, saveErrorCount),
        pageViewed: registrationPagesViewed.includes(BASE_REGISTRATION_PAGES.ADDRESS),
      });

      if (!viewedPages.includes(BASE_REGISTRATION_PAGES.ADDRESS)) {
        viewedPages = [...viewedPages, BASE_REGISTRATION_PAGES.ADDRESS];
      }

      client &&
        client.writeQuery({
          query: gql`
            query {
              registrationPagesViewed
            }
          `,
          data: {
            registrationPagesViewed: viewedPages,
          },
        });
    });
  }

  public render(): JSX.Element {
    const { match, showModalIfLocked } = this.props;
    const { patientId } = match.params;
    const stateKey = 'ukCounties';

    return (
      <Query<PatientAndUserDetailsQueryData>
        query={PATIENT_AND_USER_DETAILS_QUERY}
        fetchPolicy="cache-and-network"
        variables={{ id: patientId, stateKey: stateKey }}
        onCompleted={(data: { patient: Patient }): void => {
          showModalIfLocked(data);
        }}>
        {({ loading, data, error }): JSX.Element => {
          if (loading) {
            return <LoadingSpinner />;
          }

          if (data && data.patient) {
            this.userIsPSO = data.user.isPso || false;

            // We have a patient - render the form
            return (
              <Fragment>
                <ExtendLock accessPatientId={data.patient.id} />
                <RegistrationAddress
                  autosave={this.autosave}
                  patient={data.patient}
                  genderRefData={data.genderRefData}
                  countries={data.countries}
                  ausStates={data.ausState}
                  saveStatus={this.state.saveStatus}
                  validateOnLoad={this.state.pageViewed}
                  isPso={this.userIsPSO}
                />
              </Fragment>
            );
          }

          // If we get here there is an error
          if (error) return <div>{`Error loading: ${error}`}</div>;
          return <div />;
        }}
      </Query>
    );
  }

  /**
   * Autosave a field and it's values to the database
   * @param patient
   * @param key
   * @param value
   * @param type
   */
  private autosave = async (patient: Patient, updateItems: [GraphUpdate], forceFetch?: object[]): Promise<void> => {
    const client = this.props.client;
    const stateKey = 'ukCounties';
    // Get the pending save count and increment it by 1
    const apolloCache = await this.getApolloCache();
    let currentPendingSaveCount = apolloCache.currentPendingSaveCount + 1;
    let saveErrorCount = apolloCache.saveErrorCount;

    client &&
      client.writeQuery({
        query: gql`
          query {
            pendingSaveCount
          }
        `,
        data: {
          pendingSaveCount: currentPendingSaveCount,
        },
      });
    this.setState({ saveStatus: this.props.getSaveStatus(currentPendingSaveCount, saveErrorCount) });

    // Save the updated data. Once done deincrement the pending save count. If any errors saving, increment the
    // saveErrorCount
    return (
      client
        //@ts-ignore
        ?.mutate({
          ...this.props.getPatientMutation(patient, updateItems, forceFetch),
          refetchQueries: [
            {
              query: PATIENT_AND_USER_DETAILS_QUERY,
              variables: { id: patient.id, stateKey: stateKey },
              notifyOnNetworkStatusChange: true,
              fetchPolicy: 'network-only',
            },
          ],
        })
        .then((result): void => {
          if (result.data.updatePatient.errors) {
            saveErrorCount++;
          }
        })
        .catch((): void => {
          this.props.showSavingErrorModal(this.userIsPSO, this.props.history.push);
          saveErrorCount++;
        })
        .finally(async (): Promise<void> => {
          // Reload the save count incase it was set elsewhere during the run of this function
          const apolloCache = await this.getApolloCache();
          currentPendingSaveCount = apolloCache.currentPendingSaveCount - 1;
          client &&
            client.writeQuery({
              query: gql`
                query {
                  pendingSaveCount
                  saveErrorCount
                }
              `,
              data: {
                pendingSaveCount: currentPendingSaveCount,
                saveErrorCount: saveErrorCount,
              },
            });
          this.setState({ saveStatus: this.props.getSaveStatus(currentPendingSaveCount, saveErrorCount) });
        })
    );
  };

  private getApolloCache = async (): Promise<{
    currentPendingSaveCount: number;
    saveErrorCount: number;
    registrationPagesViewed: string[];
  }> => {
    const { client } = this.props;
    try {
      const apolloCache = await client?.query({ query: GET_APOLLO_CACHE });
      const currentPendingSaveCount = apolloCache?.data.pendingSaveCount;
      const saveErrorCount = apolloCache?.data.saveErrorCount;
      const registrationPagesViewed = apolloCache?.data.registrationPagesViewed;
      return {
        currentPendingSaveCount: currentPendingSaveCount,
        saveErrorCount: saveErrorCount,
        registrationPagesViewed: registrationPagesViewed,
      };
    } catch (error) {
      throw error;
    }
  };
}

const apolloComponent = withApollo<Props>(RegistrationAddressApollo);
const component = withRegistrationForm(apolloComponent);
export default component;
