// eslint-disable-next-line no-use-before-define
import React from 'react';
import { AlertInfo } from 'op-interfaces';

import './AlertHeader.scss';
import { ErrorOutline as ErrorOutlineIcon } from '@mui/icons-material';

interface Props {
  alert: AlertInfo;
}

class AlertHeader extends React.Component<Props> {
  public render(): JSX.Element {
    const {
      alert: { displayStyle, title, text },
    } = this.props;

    return (
      <span className="alert-header">
        <div className={`${displayStyle}-header-2 alert-header-2`}>
          <div className={`${displayStyle}-accent accent`}></div>
          <div className={`${displayStyle}-icon container-icon`}>{this.renderIcon()}</div>
          <div className="text-container">
            <span className="alert-title">{title}</span>
            <span>&nbsp;</span>
            <span className="alert-text">{text}</span>
          </div>
        </div>
      </span>
    );
  }

  private renderIcon = (): JSX.Element => {
    /* Please swap out to return icon in function if more styles addedd in future*/
    return <ErrorOutlineIcon color="warning" className="icon" />;
  };
}

export default AlertHeader;
