import { gql } from '@apollo/client';
import { HEALTH_ASSESSMENT_ILLNESS_FRAGMENT } from 'op-graphql/fragments';

export const HA_ILLNESS_QUERY = gql`
  query HealthAssessment($patientId: ID!) {
    healthAssessment(id: $patientId) {
      id
      ...HealthAssessmentIllness
    }
    screeningRefData: listData(category: "screening") {
      id
      name
      key: appKey
    }
    bonesJointsRefData: listData(category: "bones_joints") {
      id
      name
      key: appKey
    }
    pastMedicalHistoryRefData: listData(category: "past_medical_history") {
      id
      name
      key: appKey
    }
    cardiacRefData: listData(category: "cardiac") {
      id
      name
      key: appKey
    }
    constitutionalRefData: listData(category: "constitutional") {
      id
      name
      key: appKey
    }
    eyesEarsNoseThroatRefData: listData(category: "eyes_ears_nose_throat") {
      id
      name
      key: appKey
    }
    gastrointestinalRefData: listData(category: "gastrointestinal") {
      id
      name
      key: appKey
    }
    genitourinaryRefData: listData(category: "genitourinary") {
      id
      name
      key: appKey
    }
    neurologicalRefData: listData(category: "neurological") {
      id
      name
      key: appKey
    }
    psychiatricRefData: listData(category: "psychiatric") {
      id
      name
      key: appKey
    }
    pulmonaryRefData: listData(category: "pulmonary") {
      id
      name
      key: appKey
    }
    hematologicRefData: listData(category: "hematologic") {
      id
      name
      key: appKey
    }
    skinRefData: listData(category: "skin") {
      id
      name
      key: appKey
    }
  }
  ${HEALTH_ASSESSMENT_ILLNESS_FRAGMENT}
`;
