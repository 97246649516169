// eslint-disable-next-line no-use-before-define
import React, { Component, Fragment } from 'react';

import validate from 'validate.js';

import './HAAllergies.scss';

import { SegmentedInputBoolean } from 'shared-components/utils';

import { HAAllergiesItem } from 'op-classes';
import { MoreInfoText, PageTitle } from 'op-components';
import { HAMutationType } from 'op-enums';
import { HAAllergy } from 'op-interfaces';
import { fetchRules, ValidationKeys } from 'op-utils/HealthAssessmentValidation/HealthAssessmentValidation';
import { DropDownField, FreeTextField, SectionField, SegmentedInput } from 'shared-components/components/FormFields';
import { Delete, MinusNoFill } from 'shared-components/images';
import { ListData } from 'shared-components/interfaces';
import { Add as AddIcon } from '@mui/icons-material';

const FIELDS = {
  PAGE: {
    TITLE: 'Allergies/sensitivities',
  },
  ALLERGY_BOOLEAN: {
    NAME: 'allergyBool',
    TITLE: 'Do you have any allergies?',
  },
  ALLERGIES: 'allergies',
};

const CRUD_OPERATION = {
  CREATE: 'CREATE',
  DELETE: 'DELETE',
};

interface State {
  viewedFields: Set<string>;
}

interface Props {
  patientId: string;
  haAllergies: HAAllergiesItem;
  validateOnLoad: boolean;
  autosave: (updateObject: object, replaceId?: string, refetch?: any, mutationType?: HAMutationType) => Promise<void>;
  performCRUD: (
    haId: string,
    operation: string,
    objectType: string,
    itemId?: string,
    refetch?: any,
    relatedObjectId?: string,
  ) => void;
  refetch: any;
  reactionRefData: ListData[];
  sensitivityRefData: ListData[];
}

class HAAllergies extends Component<Props, State> {
  public constructor(props: Props) {
    super(props);

    this.state = {
      viewedFields: new Set(),
    };
  }

  public static getDerivedStateFromProps(props: Props, state: State): State {
    const { haAllergies } = props;
    if (props.validateOnLoad && state.viewedFields.size < 1) {
      const fields = [FIELDS.ALLERGY_BOOLEAN.NAME];
      const viewed = new Set(fields);
      Object.keys(haAllergies).forEach(function (key) {
        if (Array.isArray(haAllergies[key])) {
          haAllergies[key].forEach(function (item: any, index: number) {
            Object.keys(item).forEach(function (field) {
              if (field !== 'id' && field !== '__typename') {
                const place = index + 1;
                viewed.add(key + '-' + place + '-' + field);
              }
            });
          });
        }
      });
      return { viewedFields: viewed };
    }

    return state;
  }

  public render(): JSX.Element {
    const { haAllergies } = this.props;

    return (
      <Fragment>
        <PageTitle title={FIELDS.PAGE.TITLE} idPrefix="ha" />
        <div id="ha-fields" className="ha-medical-history-other">
          <SectionField title={FIELDS.ALLERGY_BOOLEAN.TITLE} htmlFor={FIELDS.ALLERGY_BOOLEAN.NAME}>
            <SegmentedInput
              options={SegmentedInputBoolean}
              fieldName={FIELDS.ALLERGY_BOOLEAN.NAME}
              optionAreBoolean={true}
              defaultSelected={haAllergies.allergyBool}
              itemSelected={(selectedItem): void => {
                const selectedValue = selectedItem as boolean;
                this.autoSaveAndValidate(
                  {
                    id: parseInt(this.props.patientId),
                    allergyBool: selectedValue,
                  },
                  FIELDS.ALLERGY_BOOLEAN.NAME,
                  this.props.refetch,
                );
              }}
            />
          </SectionField>
          {haAllergies.allergyBool && this.renderAllergies()}
        </div>
      </Fragment>
    );
  }
  private renderAllergies = (): JSX.Element => {
    const { haAllergies, performCRUD, refetch, autosave, patientId } = this.props;
    const allergiesSelection: JSX.Element[] = haAllergies.allergies.map((value, index): JSX.Element => {
      let removable = false;
      if (haAllergies.allergies.length !== 1) {
        removable = true;
      }
      return this.renderExpandableElement(
        index,
        'allergies',
        'Allergy/sensitivity',
        haAllergies.allergies,
        [],
        (object: any): void => {
          autosave({ id: parseInt(patientId), allergies: { id: value.id, ...object } }, value.id);
        },
        (): void => {
          performCRUD(haAllergies.id, CRUD_OPERATION.DELETE, 'allergyBool', value.id, refetch);
        },
        removable,
      );
    });

    return (
      <Fragment>
        {allergiesSelection}
        {this.renderAdditionalItem('Add another allergy', (): void => {
          performCRUD(haAllergies.id, CRUD_OPERATION.CREATE, 'allergyBool', undefined, refetch);
        })}
      </Fragment>
    );
  };
  private renderExpandableElement = (
    index: number,
    inputType: string,
    inputTitle: string,
    stateList: HAAllergy[],
    referenceData: ListData[],
    onChange: (object: any) => void,
    removeFunction: () => void,
    removable = true,
  ): JSX.Element => {
    const { sensitivityRefData } = this.props;
    const inputName = `${inputType}-${index + 1}`;
    const otherOptionIndex: string = sensitivityRefData.filter(
      (listItem: ListData): boolean => listItem.name.toLowerCase().trim() === 'food',
    )[0].id;
    const latexOption: ListData[] = sensitivityRefData.filter(
      (listItem: ListData): boolean => listItem.name.toLowerCase().trim() === 'latex',
    );
    const latexOptionIndex: string = latexOption.length ? latexOption[0]?.id : '-1';
    let text = '';
    let hidden = false;
    if (stateList[index].sensitivity === '' || stateList[index].sensitivity === latexOptionIndex) {
      text = '';
      hidden = true;
    }
    if (stateList[index].sensitivity === otherOptionIndex) {
      text = 'You are not required to provide more information regarding food allergies';
      hidden = true;
    }
    if (stateList[index].sensitivity === latexOptionIndex) {
      text = 'You are not required to provide more information regarding latex allergies';
      hidden = true;
    }
    return (
      <div key={inputName} className="infinite-dropdown-item">
        <SectionField htmlFor={inputName} title={`${inputTitle} ${index + 1}`}>
          <DropDownField
            inputName={`${inputName}-sensitivity`}
            placeholder={'Please select'}
            options={sensitivityRefData}
            defaultValue={stateList[index].sensitivity}
            controlled={true}
            onChange={(e): void => {
              const saveObject = {
                sensitivity: e.target.value,
                specify: stateList[index].specify,
                area: stateList[index].area,
              };
              onChange(saveObject);
              this.addToViewedFields(`${inputName}-sensitivity`);
            }}
          />
          {hidden ? (
            <MoreInfoText moreInfoText={text}>
              <Fragment />
            </MoreInfoText>
          ) : (
            <Fragment key={inputName}>
              <label className="form-section-field field-label">Specify</label>
              <FreeTextField
                inputKey={stateList[index].specify}
                inputName={`${inputName}-specify`}
                inputType="text"
                defaultValue={stateList[index].specify}
                onBlur={(e): void => {
                  const saveObject = {
                    sensitivity: stateList[index].sensitivity,
                    specify: e.target.value,
                    area: stateList[index].area,
                  };
                  this.addToViewedFields(`${inputName}-specify`);
                  onChange(saveObject);
                }}
              />

              <label className="form-section-field field-label">{'Reaction experienced'}</label>
              <Fragment>{this.renderPatientsReactions(index)}</Fragment>

              <label className="form-section-field field-label">{'Area affected'}</label>
              <FreeTextField
                inputKey={stateList[index].area}
                inputName={`${inputName}-area`}
                //placeholder={'Please specify your affected area.'}
                inputType="text"
                defaultValue={stateList[index].area}
                onBlur={(e): void => {
                  const saveObject = {
                    sensitivity: stateList[index].sensitivity,
                    specify: stateList[index].specify,
                    area: e.target.value,
                  };
                  this.addToViewedFields(`${inputName}-area`);
                  onChange(saveObject);
                }}
              />
            </Fragment>
          )}
        </SectionField>
        {removable && (
          <div className="remove-item">
            <div
              onClick={(): void => {
                removeFunction();
              }}>
              <MinusNoFill className="icon" />
              {'Remove'}
            </div>
          </div>
        )}
      </div>
    );
  };
  private renderPatientsReactions = (int: any): JSX.Element => {
    const { haAllergies, reactionRefData, autosave, performCRUD, refetch } = this.props;
    const isFirstReaction = haAllergies.allergies[int].reaction.length === 1;
    const reactionOtherOptionIndex: string = reactionRefData.filter(
      (listItem: ListData): boolean => listItem.name.toLowerCase().trim() === 'other',
    )[0].id;
    const reactionsSelection: JSX.Element[] = haAllergies.allergies[int].reaction.map(
      (reaction, index): JSX.Element => {
        return (
          <Fragment key={`reaction-${index}`}>
            <div
              className="reaction"
              onBlur={(): void => {
                this.addToViewedFields(`allergies-${int}-reaction-${index}`);
              }}>
              <DropDownField
                inputName={`reaction-${index}`}
                placeholder={'Please select'}
                options={reactionRefData}
                defaultValue={haAllergies.allergies[int].reaction[index].value}
                controlled={true}
                onChange={(e): void => {
                  autosave(
                    { id: parseInt(reaction.id), value: e.target.value },
                    '',
                    this.props.refetch,
                    HAMutationType.UPDATE_REACTION,
                  );
                }}
              />
              <div
                className="delete-reaction-button"
                onClick={(): void => {
                  performCRUD(haAllergies.id, CRUD_OPERATION.DELETE, 'reaction', reaction.id, refetch);
                }}>
                {isFirstReaction ? <Fragment /> : <Delete className="icon" />}
              </div>
            </div>
            {reaction.value === reactionOtherOptionIndex && (
              <FreeTextField
                inputKey={haAllergies.allergies[int].reaction[index].other}
                inputName={`reaction-other-${index}`}
                className={'reaction-other'}
                placeholder={'Please describe'}
                inputType="text"
                defaultValue={haAllergies.allergies[int].reaction[index].other}
                onBlur={(e): void => {
                  this.addToViewedFields(`reaction-other-${index}`);
                  autosave(
                    { id: parseInt(reaction.id), other: e.target.value },
                    '',
                    this.props.refetch,
                    HAMutationType.UPDATE_REACTION,
                  );
                }}
              />
            )}
          </Fragment>
        );
      },
    );
    return (
      <div>
        {reactionsSelection}
        <Fragment>
          {this.renderAdditionalItem('Add another reaction', (): void => {
            performCRUD(
              haAllergies.id,
              CRUD_OPERATION.CREATE,
              'reaction',
              undefined,
              refetch,
              haAllergies.allergies[int].id,
            );
          })}
        </Fragment>
      </div>
    );
  };
  private renderAdditionalItem = (buttonText: string, onClick: () => void): JSX.Element => {
    return (
      <div
        data-test-id={`additional-${buttonText}`}
        className="additional-item-button"
        onClick={(): void => {
          onClick();
        }}>
        <AddIcon className="icon" color="primary" />
        {buttonText}
      </div>
    );
  };

  private addToViewedFields = (validationKey: string): any => {
    //Get viewed fields
    const viewedFields = [...this.state.viewedFields];

    //Add updated field
    viewedFields.push(validationKey);

    //Update with new validation set
    this.setState({ viewedFields: new Set(viewedFields) });
  };

  private validateObject = (haAllergies: HAAllergiesItem): any => {
    const validationRules = fetchRules(ValidationKeys.Allergies, this.state.viewedFields);

    const options = { fullMessages: false };

    return validate(haAllergies, validationRules, options);
  };

  private autoSaveAndValidate = async (
    updateObject: object,
    validationKey: string,
    setRefetch: boolean,
  ): Promise<void> => {
    const { autosave, refetch } = this.props;

    //Save the data
    const autoSave = () => {
      return setRefetch ? autosave(updateObject, undefined, refetch) : autosave(updateObject);
    };

    await autoSave().then(() => {
      //Get viewed fields
      const viewedFields = [...this.state.viewedFields];

      //Add updated field
      viewedFields.push(validationKey);

      //Update with new validation set
      this.setState({ viewedFields: new Set(viewedFields) });
    });
  };
}

export default HAAllergies;
