import { gql } from '@apollo/client';
import { HA_CONSENT_FRAGMENT } from 'op-graphql/fragments';

export const UPDATE_HA_CONSENT = gql`
  mutation UpdateHealthAssessmentConsent(
    $patientId: ID!
    $patientAcknowledgement: Boolean
    $patientDigitalSignature: String
  ) {
    updateHealthAssessment(
      id: $patientId
      patientAcknowledgement: $patientAcknowledgement
      patientDigitalSignature: $patientDigitalSignature
    ) {
      healthAssessment {
        id
        ...HealthAssessmentConsent
      }
    }
  }
  ${HA_CONSENT_FRAGMENT}
`;
