// eslint-disable-next-line no-use-before-define
import React, { Component, useEffect } from 'react';
import { gql } from '@apollo/client';
import { WithApolloClient, withApollo } from '@apollo/client/react/hoc';
import { Query } from '@apollo/client/react/components';
import { LoadingSpinner } from 'shared-components/components';
import ROClinicList from './ROClinicList';
import moment from 'moment';
import { useErrorModalContext } from 'op-contexts';

const APPOINTMENTS_QUERY = gql`
  query getAppointments($date: Date) {
    clinicList(date: $date) {
      noFurtherAction
      id
      startTime
      duration
      patient {
        dob
        age
        address {
          id
          formattedAddress
        }
        gender
        fullName
        ida
        id
        photoUrl
      }
      appointmentType
      description
      department {
        id
        name
      }
      queuedPatient
    }
  }
`;

const NFA_MUTATION = gql`
  mutation nfa($pk: ID!) {
    noFurtherAction(pk: $pk) {
      ok
    }
  }
`;

interface Patient {
  fullName: string;
  ida: string;
  id: string;
  photoUrl: string;
  age: string;
  dob: string;
  gender: string;
  address: {
    formattedAddress: string;
  };
  emrInstance: string;
}

interface Location {
  name: string;
}

interface AppointmentObject {
  id: string;
  startTime: string;
  duration: number;
  patient: Patient | null;
  appointmentType: string;
  description: string;
  location: Location;
  department: Location;
  queuedPatient: boolean;
  noFurtherAction: boolean;
}

interface Props extends WithApolloClient<{}> {
  isActive?: boolean;
}

interface State {
  currentPage: number;
  currentDate: string;
  nfaWarning: boolean;
  currAppointment?: AppointmentObject | null;
}

class ROClinicListApollo extends Component<Props, State> {
  public constructor(props: Props) {
    super(props);

    this.state = {
      currentPage: 1,
      currentDate: moment().format('YYYY-MM-DD'),
      nfaWarning: false,
      currAppointment: null,
    };
  }

  private changeDate = (date: string) => {
    if (date === '-1') {
      this.setState({
        currentDate: moment(this.state.currentDate).subtract(1, 'days').format('YYYY-MM-DD'),
      });
    } else if (date === '+1') {
      this.setState({
        currentDate: moment(this.state.currentDate).add(1, 'days').format('YYYY-MM-DD'),
      });
    } else {
      this.setState({ currentDate: date });
    }
  };

  private flipNFAModal = (appointment?: AppointmentObject) => {
    this.setState({ nfaWarning: !this.state.nfaWarning, currAppointment: appointment });
  };

  private clickNFA = () => {
    if (this.state.currAppointment) {
      const mutationVars = {
        pk: this.state.currAppointment.id,
      };
      const queryVars = {
        date: this.state.currentDate,
      };
      this.props.client
        ?.mutate({
          mutation: NFA_MUTATION,
          variables: mutationVars,
          refetchQueries: [
            {
              query: APPOINTMENTS_QUERY,
              variables: queryVars,
              notifyOnNetworkStatusChange: true,
              fetchPolicy: 'cache-first',
            },
          ],
          awaitRefetchQueries: true,
        })
        .then(() => {
          this.setState({ currAppointment: null, nfaWarning: !this.state.nfaWarning });
        });
    }
  };

  public render(): JSX.Element {
    const { currentDate } = this.state;
    const variables = {
      date: currentDate !== '' ? currentDate : moment().format('YYYY-MM-DD'),
      BREAK: 'BREAK',
    };
    return (
      <Query query={APPOINTMENTS_QUERY} variables={variables} fetchPolicy="cache-and-network">
        {({ loading, data, error }: any): JSX.Element => {
          const { setError } = useErrorModalContext();
          useEffect(() => {
            if (error) return setError();
          }, [error, data]);
          if (loading) return <LoadingSpinner />;
          if (data?.clinicList) {
            return (
              <ROClinicList
                appointments={data.clinicList}
                currentDate={this.state.currentDate}
                onDateChange={this.changeDate}
                nfaWarning={this.state.nfaWarning}
                onShowNFAModal={this.flipNFAModal}
                currAppointment={this.state.currAppointment}
                onNFAClick={this.clickNFA}
              />
            );
          }
          return <></>;
        }}
      </Query>
    );
  }
}

export default withApollo(ROClinicListApollo);
