import React from 'react';
import { styled } from '@mui/system';
import { Delete } from 'shared-components/images';

interface Props {
  fieldId: string;
  label?: string;
  onClick: (e: any) => any;
  unclickable?: boolean;
  buttonText?: string;
}

interface IStyledIcon {
  label?: string;
  unclickable?: boolean;
}

const StyledButton = styled('button')<IStyledIcon>`
  border: none;
  background: none;
  display: flex;
  align-items: center;
  padding: 0;
  outline: none !important;
  border-radius: 4px;
  &:hover {
    cursor: ${(props: IStyledIcon): string => (!props.unclickable ? 'pointer' : 'not-allowed')};
    text-decoration: ${(props: IStyledIcon): string => (!props.unclickable ? 'underline' : 'none')};
  }
`;

const DeleteIcon = styled((props: any) => <Delete title="Delete.svg" {...props} />)<IStyledIcon>`
  margin-right: ${(props: IStyledIcon): string => (props.label ? '8px' : '0px')};
  path {
    fill: ${(props: IStyledIcon): string =>
      !props.unclickable ? `${(props: any) => props.theme.palette.error.main}` : 'black'};
    fill-opacity: ${(props: IStyledIcon): string => (!props.unclickable ? '1' : '0.54')};
  }
  width: 18px;
  height: 18px;
`;

const Label = styled('span')<IStyledIcon>`
  font-weight: bold;
  font-size: 0.875rem;
  color: ${(props: IStyledIcon): string =>
    !props.unclickable ? 'black' : `${(props: any) => props.theme.palette.grey[600]}`};
`;

const DeleteButton = ({ fieldId, label, onClick, unclickable, buttonText = 'Delete' }: Props): JSX.Element => {
  return (
    <StyledButton type="button" onClick={onClick} data-testid={`${fieldId}-button-id`} unclickable={unclickable}>
      <DeleteIcon label={label} unclickable={unclickable} />
      <Label unclickable={unclickable}>{buttonText}</Label>
    </StyledButton>
  );
};

export default DeleteButton;
