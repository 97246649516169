// eslint-disable-next-line no-use-before-define
import React from 'react';
import './AppointmentsListingSectionHeader.scss';

/**
 * Interfaces
 */
interface AppointmentsListingSectionHeaderProps {
  nameOfMonth: string;
}

function AppointmentsListingSectionHeader(props: AppointmentsListingSectionHeaderProps): JSX.Element {
  return <div className="month-section-header">{props.nameOfMonth}</div>;
}

export default AppointmentsListingSectionHeader;
