import { BottomNavigation } from 'op-pages/PX/Components';
import { memo } from 'react';

const BottomSection = (): JSX.Element => {
  const componentId = 'bottom-section';

  return (
    <div id={componentId}>
      <BottomNavigation />
    </div>
  );
};

export default memo(BottomSection);
