import { gql } from '@apollo/client';

export const LOAD_CAREPLAN_LIST_DATA = gql`
  query carepathDataReferenceList($listCategory: [String!]!, $patientId: ID) {
    carepathDataReferenceList(listCategory: $listCategory, patientId: $patientId) {
      id
      data {
        id
        option
      }
      listWeight
      category {
        id
        category
      }
    }
  }
`;
