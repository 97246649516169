import React from 'react';
import { Typography, Stack } from '@mui/material';
import { ErrorOutline as ErrorOutlineIcon } from '@mui/icons-material';

interface Props {
  primaryText?: string;
  secondaryText?: string;
}

const GeneralError = ({ primaryText, secondaryText }: Props): JSX.Element => {
  return (
    <Stack alignItems="center" paddingTop={4}>
      <ErrorOutlineIcon />
      <Typography variant="body2">{primaryText ? primaryText : 'Something went wrong.'}</Typography>
      <Typography variant="body2">{secondaryText ? secondaryText : 'Please refresh the page.'}</Typography>
    </Stack>
  );
};

export default GeneralError;
