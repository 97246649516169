// eslint-disable-next-line no-use-before-define
import { HAMedicalHistoryIllnessItem } from 'op-classes';
import { MoreInfoText, PageTitle } from 'op-components';
import { HAIssueItem } from 'op-interfaces';
import { resolveListDataValue } from 'op-utils';
import { fetchRules, ValidationKeys } from 'op-utils/HealthAssessmentValidation/HealthAssessmentValidation';
import React, { Component, Fragment } from 'react';

import { DropDownField, FreeTextField, SectionField, SegmentedInput } from 'shared-components/components/FormFields';
import { MinusNoFill } from 'shared-components/images';
import { ListData } from 'shared-components/interfaces';
import { SegementedInputYesNoUnsure } from 'shared-components/utils';
import validate from 'validate.js';

import { ILL_FIELDS } from '../constants';
import lodash from 'lodash';
import { Add as AddIcon } from '@mui/icons-material';

const CRUD_OPERATION = {
  CREATE: 'CREATE',
  DELETE: 'DELETE',
};
interface State {
  viewedFields: Set<string>;
}
interface Props {
  patientId: string;
  medicalHistoryIllness: HAMedicalHistoryIllnessItem;
  deviceRefData: ListData[];
  resistantRefData: ListData[];
  suppressiveRefData: ListData[];
  diabetesRefData: ListData[];
  autosave: (updateObject: object, replaceId?: string, refetch?: any) => Promise<void>;
  performCRUD: (haId: string, operation: string, objectType: string, itemId?: string, refetch?: any) => void;
  refetch: any;
  validateOnLoad: boolean;
}

class HAMedicalHistoryIllness extends Component<Props, State> {
  public constructor(props: Props) {
    super(props);
    this.state = {
      viewedFields: new Set(),
    };
  }
  public static getDerivedStateFromProps(props: Props, state: State): State {
    const { medicalHistoryIllness } = props;
    if (props.validateOnLoad && state.viewedFields.size < 1) {
      const fields = Object.entries(ILL_FIELDS).map((field: any) => {
        return field[1]['KEY'];
      });
      fields.push('bloodClotAnswer', 'heartOrLungAnswer', 'bloodPressureAnswer');
      const viewed = new Set(fields);
      viewed.add(ILL_FIELDS.RESISTANT.PERMISSION.NAME);
      Object.keys(medicalHistoryIllness).forEach(function (key) {
        if (Array.isArray(medicalHistoryIllness[key])) {
          medicalHistoryIllness[key].forEach(function (item: any, index: number) {
            Object.keys(item).forEach(function (field) {
              if (field !== 'id' && field !== '__typename') {
                const place = index + 1;
                viewed.add(key + '-' + place + '-' + field);
              }
            });
          });
        }
      });
      return { viewedFields: viewed };
    }

    return state;
  }
  private shouldDisplayDynamic = (value: string | null): boolean => {
    if (value === 'YES') {
      return true;
    }
    return false;
  };
  private clearDynamicViewedFields = (value: string | null): void => {
    const { viewedFields } = this.state;
    let removeValue = '';
    switch (value) {
      case 'internalDevice':
        removeValue = 'internalDevices-';
        break;
      case 'diabetes':
        removeValue = 'diabetesResponses-';
        break;
      case 'intractableInfectionOrIllness':
        removeValue = 'intractableInfectionOrIllnesses-';
        break;
      case 'immunosuppressiveCondition':
        removeValue = 'immunosuppressiveCondition-';
        break;
      default:
        break;
    }
    const copy = viewedFields;
    for (let it = copy.values(), val = null; (val = it.next().value); ) {
      if (val.includes(removeValue)) {
        copy.delete(val);
      }
    }
    this.setState({ viewedFields: copy });
  };
  public render(): JSX.Element {
    const { medicalHistoryIllness, patientId } = this.props;

    return (
      <Fragment>
        <PageTitle title={'Medical history: illness'} idPrefix="ha" />
        <div id="ha-fields" className="ha-medical-history-illness">
          <SectionField htmlFor={ILL_FIELDS.DEVICES.NAME} title={ILL_FIELDS.DEVICES.TITLE}>
            <MoreInfoText moreInfoText={ILL_FIELDS.DEVICES.INFO}>
              <SegmentedInput
                fieldName={ILL_FIELDS.DEVICES.NAME}
                options={SegementedInputYesNoUnsure}
                defaultSelected={medicalHistoryIllness.internalDevice}
                itemSelected={(selectedItem): void => {
                  const selectedValue = (selectedItem as ListData).id;
                  if (selectedValue !== 'Yes') {
                    this.clearDynamicViewedFields('internalDevice');
                  }
                  //Only trigger save if user selects something different
                  if (medicalHistoryIllness.internalDevice !== selectedValue) {
                    this.autoSaveAndValidate(
                      { id: parseInt(patientId), internalDevice: selectedValue },
                      ILL_FIELDS.DEVICES.KEY,
                      this.props.refetch,
                    );
                  }
                }}
              />
            </MoreInfoText>
            {this.shouldDisplayDynamic(medicalHistoryIllness.internalDevice) && this.renderDeviceSelection()}
          </SectionField>

          <SectionField htmlFor={ILL_FIELDS.CLOT.NAME} title={ILL_FIELDS.CLOT.TITLE}>
            <SegmentedInput
              fieldName={ILL_FIELDS.CLOT.NAME}
              options={SegementedInputYesNoUnsure}
              defaultSelected={medicalHistoryIllness.bloodClot}
              itemSelected={(selectedItem): void => {
                const selectedValue = (selectedItem as ListData).id;
                // Only trigger save if user selects something different
                if (medicalHistoryIllness.bloodClot !== selectedValue) {
                  this.autoSaveAndValidate(
                    { id: parseInt(patientId), bloodClot: selectedValue },
                    ILL_FIELDS.CLOT.KEY,
                    this.props.refetch,
                  );
                }
              }}
            />
          </SectionField>
          {this.shouldDisplayDynamic(medicalHistoryIllness.bloodClot) && this.renderClotSelection()}
          <SectionField htmlFor={ILL_FIELDS.PROBLEMS.NAME} title={ILL_FIELDS.PROBLEMS.TITLE}>
            <SegmentedInput
              fieldName={ILL_FIELDS.PROBLEMS.NAME}
              options={SegementedInputYesNoUnsure}
              defaultSelected={medicalHistoryIllness.heartOrLungProblem}
              itemSelected={(selectedItem): void => {
                const selectedValue = (selectedItem as ListData).id;
                // Only trigger save if user selects something different
                if (medicalHistoryIllness.heartOrLungProblem !== selectedValue) {
                  this.autoSaveAndValidate(
                    { id: parseInt(patientId), heartOrLungProblem: selectedValue },
                    ILL_FIELDS.PROBLEMS.KEY,
                    this.props.refetch,
                  );
                }
              }}
            />
          </SectionField>
          {this.shouldDisplayDynamic(medicalHistoryIllness.heartOrLungProblem) && this.renderProblemsSelection()}
          <SectionField htmlFor={ILL_FIELDS.PRESSURE.NAME} title={ILL_FIELDS.PRESSURE.TITLE}>
            <SegmentedInput
              fieldName={ILL_FIELDS.PRESSURE.NAME}
              options={SegementedInputYesNoUnsure}
              defaultSelected={medicalHistoryIllness.highOrLowBloodPressure}
              itemSelected={(selectedItem): void => {
                const selectedValue = (selectedItem as ListData).id;
                // Only trigger save if user selects something different
                if (medicalHistoryIllness.highOrLowBloodPressure !== selectedValue) {
                  this.autoSaveAndValidate(
                    { id: parseInt(patientId), highOrLowBloodPressure: selectedValue },
                    ILL_FIELDS.PRESSURE.KEY,
                    this.props.refetch,
                  );
                }
              }}
            />
          </SectionField>
          {this.shouldDisplayDynamic(medicalHistoryIllness.highOrLowBloodPressure) && this.renderPressureSelection()}
          <SectionField htmlFor={ILL_FIELDS.DIABETES.NAME} title={ILL_FIELDS.DIABETES.TITLE}>
            <SegmentedInput
              fieldName={ILL_FIELDS.DIABETES.NAME}
              options={SegementedInputYesNoUnsure}
              defaultSelected={medicalHistoryIllness.diabetes}
              itemSelected={(selectedItem): void => {
                const selectedValue = (selectedItem as ListData).id;
                // Only trigger save if user selects something different
                if (selectedValue !== 'Yes') {
                  this.clearDynamicViewedFields('diabetes');
                }
                if (medicalHistoryIllness.diabetes !== selectedValue) {
                  this.autoSaveAndValidate(
                    { id: parseInt(patientId), diabetes: selectedValue },
                    ILL_FIELDS.DIABETES.KEY,
                    this.props.refetch,
                  );
                }
              }}
            />

            {this.shouldDisplayDynamic(medicalHistoryIllness.diabetes) && this.renderDiabetesSelection()}
          </SectionField>
          <SectionField htmlFor={ILL_FIELDS.RESISTANT.NAME} title={ILL_FIELDS.RESISTANT.TITLE}>
            <MoreInfoText moreInfoText={ILL_FIELDS.RESISTANT.INFO}>
              <SegmentedInput
                fieldName={ILL_FIELDS.RESISTANT.NAME}
                options={SegementedInputYesNoUnsure}
                defaultSelected={medicalHistoryIllness.intractableInfectionOrIllness}
                itemSelected={(selectedItem): void => {
                  const selectedValue = (selectedItem as ListData).id;
                  // Only trigger save if user selects something different
                  if (selectedValue !== 'Yes') {
                    this.clearDynamicViewedFields('intractableInfectionOrIllness');
                  }
                  if (medicalHistoryIllness.intractableInfectionOrIllness !== selectedValue) {
                    this.autoSaveAndValidate(
                      { id: parseInt(patientId), intractableInfectionOrIllness: selectedValue },
                      ILL_FIELDS.RESISTANT.KEY,
                      this.props.refetch,
                    );
                  }
                }}
              />
            </MoreInfoText>
            {this.shouldDisplayDynamic(medicalHistoryIllness.intractableInfectionOrIllness) &&
              this.renderResistantSelection()}
          </SectionField>
          <SectionField htmlFor={ILL_FIELDS.SUPPRESSIVE.NAME} title={ILL_FIELDS.SUPPRESSIVE.TITLE}>
            <MoreInfoText moreInfoText={ILL_FIELDS.SUPPRESSIVE.INFO}>
              <SegmentedInput
                fieldName={ILL_FIELDS.SUPPRESSIVE.NAME}
                options={SegementedInputYesNoUnsure}
                defaultSelected={medicalHistoryIllness.immunosuppressiveCondition}
                itemSelected={(selectedItem): void => {
                  const selectedValue = (selectedItem as ListData).id;
                  if (selectedValue !== 'Yes') {
                    this.clearDynamicViewedFields('immunosuppressiveCondition');
                  }
                  // Only trigger save if user selects something different
                  if (medicalHistoryIllness.immunosuppressiveCondition !== selectedValue) {
                    this.autoSaveAndValidate(
                      { id: parseInt(patientId), immunosuppressiveCondition: selectedValue },
                      ILL_FIELDS.SUPPRESSIVE.KEY,
                      this.props.refetch,
                    );
                  }
                }}
              />
            </MoreInfoText>
            {this.shouldDisplayDynamic(medicalHistoryIllness.immunosuppressiveCondition) &&
              this.renderSuppressiveSelection()}
          </SectionField>
        </div>
      </Fragment>
    );
  }

  private renderDeviceSelection = (): JSX.Element => {
    const { medicalHistoryIllness, deviceRefData, autosave, performCRUD, refetch, patientId } = this.props;
    const deviceSelection: JSX.Element[] = medicalHistoryIllness.internalDevices.map((value, index): JSX.Element => {
      let removable = false;
      if (medicalHistoryIllness.internalDevices.length !== 1) {
        removable = true;
      }
      return this.renderExpandableElement(
        index,
        'internalDevices',
        'Implanted/artificial internal device',
        medicalHistoryIllness.internalDevices,
        lodash.cloneDeep(deviceRefData),
        (object: any): void => {
          autosave({ id: parseInt(patientId), internalDevices: { id: value.id, ...object } }, value.id);
        },
        (): void => {
          performCRUD(medicalHistoryIllness.id, CRUD_OPERATION.DELETE, 'internalDevice', value.id, refetch);
        },
        removable,
      );
    });

    return (
      <Fragment>
        {deviceSelection}
        {this.renderAdditionalItem('Add another device', (): void => {
          performCRUD(medicalHistoryIllness.id, CRUD_OPERATION.CREATE, 'internalDevice', undefined, refetch);
        })}
      </Fragment>
    );
  };
  private renderAdditionalItem = (buttonText: string, onClick: () => void): JSX.Element => {
    return (
      <div
        className="additional-item-button"
        onClick={(): void => {
          onClick();
        }}>
        <AddIcon color="primary" className="icon" />
        {buttonText}
      </div>
    );
  };
  private renderClotSelection = (): JSX.Element => {
    const { medicalHistoryIllness, patientId } = this.props;

    return (
      <SectionField htmlFor={ILL_FIELDS.CLOT.DETAIL.NAME} title={ILL_FIELDS.CLOT.DETAIL.TITLE}>
        <FreeTextField
          inputName={ILL_FIELDS.CLOT.DETAIL.NAME}
          defaultValue={medicalHistoryIllness.bloodClotAnswer}
          onBlur={(e): void => {
            this.autoSaveAndValidate(
              { id: parseInt(patientId), bloodClotAnswer: e.target.value },
              ILL_FIELDS.CLOT.DETAIL.KEY,
              this.props.refetch,
            );
          }}
        />
      </SectionField>
    );
  };
  private renderProblemsSelection = (): JSX.Element => {
    const { medicalHistoryIllness, patientId } = this.props;
    return (
      <SectionField htmlFor={ILL_FIELDS.PROBLEMS.DETAIL.NAME} title={ILL_FIELDS.PROBLEMS.DETAIL.TITLE}>
        <FreeTextField
          inputName={ILL_FIELDS.PROBLEMS.DETAIL.NAME}
          defaultValue={medicalHistoryIllness.heartOrLungAnswer}
          onBlur={(e): void => {
            this.autoSaveAndValidate(
              { id: parseInt(patientId), heartOrLungAnswer: e.target.value },
              ILL_FIELDS.PROBLEMS.DETAIL.KEY,
              this.props.refetch,
            );
          }}
        />
      </SectionField>
    );
  };
  private renderPressureSelection = (): JSX.Element => {
    const { medicalHistoryIllness, patientId } = this.props;
    const segmentedInputOption = [
      { id: 'LOW', name: 'Low' },
      { id: 'HIGH', name: 'High' },
    ];
    return (
      <SectionField htmlFor={ILL_FIELDS.PRESSURE.DETAIL.NAME} title={ILL_FIELDS.PRESSURE.DETAIL.TITLE}>
        <SegmentedInput
          options={segmentedInputOption}
          fieldName={ILL_FIELDS.PRESSURE.DETAIL.NAME}
          defaultSelected={medicalHistoryIllness.bloodPressureAnswer}
          itemSelected={(selectedItem): void => {
            if (typeof selectedItem === 'object') {
              this.autoSaveAndValidate(
                {
                  id: parseInt(patientId),
                  bloodPressureAnswer: selectedItem.id,
                },
                ILL_FIELDS.PRESSURE.DETAIL.KEY,
                this.props.refetch,
              );
            }
          }}
        />
      </SectionField>
    );
  };
  private renderDiabetesSelection = (): JSX.Element => {
    const { medicalHistoryIllness, diabetesRefData, autosave, performCRUD, refetch, patientId } = this.props;
    const diabetesSelection: JSX.Element[] = medicalHistoryIllness.diabetesResponses.map(
      (value, index): JSX.Element => {
        let removable = false;
        if (medicalHistoryIllness.diabetesResponses.length !== 1) {
          removable = true;
        }

        return this.renderExpandableElement(
          index,
          'diabetesResponses',
          'Diabetes',
          medicalHistoryIllness.diabetesResponses,
          lodash.cloneDeep(diabetesRefData),
          (object: any): void => {
            autosave({ id: parseInt(patientId), diabetesResponses: { id: value.id, ...object } }, value.id);
          },
          (): void => {
            performCRUD(medicalHistoryIllness.id, CRUD_OPERATION.DELETE, 'diabetes', value.id, refetch);
          },
          removable,
        );
      },
    );
    return (
      <Fragment>
        {diabetesSelection}
        {this.renderAdditionalItem('Add another diabetes type', (): void => {
          performCRUD(medicalHistoryIllness.id, CRUD_OPERATION.CREATE, 'diabetes', undefined, refetch);
        })}
      </Fragment>
    );
  };
  private renderResistantSelection = (): JSX.Element => {
    const { medicalHistoryIllness, resistantRefData, autosave, performCRUD, refetch, patientId } = this.props;
    const deviceSelection: JSX.Element[] = medicalHistoryIllness.intractableInfectionOrIllnesses.map(
      (value, index): JSX.Element => {
        let removable = false;
        if (medicalHistoryIllness.intractableInfectionOrIllnesses.length !== 1) {
          removable = true;
        }
        return this.renderExpandableElement(
          index,
          'intractableInfectionOrIllnesses',
          'Illness or infection',
          medicalHistoryIllness.intractableInfectionOrIllnesses,
          lodash.cloneDeep(resistantRefData),
          (object: any): void => {
            autosave(
              { id: parseInt(patientId), intractableInfectionOrIllnesses: { id: value.id, ...object } },
              value.id,
            );
          },
          (): void => {
            performCRUD(
              medicalHistoryIllness.id,
              CRUD_OPERATION.DELETE,
              'intractableInfectionOrIllness',
              value.id,
              refetch,
            );
          },
          removable,
          true,
        );
      },
    );

    return (
      <Fragment>
        {deviceSelection}
        {this.renderAdditionalItem('Add another illness or infection', (): void => {
          performCRUD(
            medicalHistoryIllness.id,
            CRUD_OPERATION.CREATE,
            'intractableInfectionOrIllness',
            undefined,
            refetch,
          );
        })}
      </Fragment>
    );
  };
  private renderSuppressiveSelection = (): JSX.Element => {
    const { medicalHistoryIllness, suppressiveRefData, autosave, performCRUD, refetch, patientId } = this.props;
    const immunosuppressiveConditionsSelection: JSX.Element[] = medicalHistoryIllness.immunosuppressiveConditions.map(
      (value, index): JSX.Element => {
        let removable = false;
        if (medicalHistoryIllness.immunosuppressiveConditions.length !== 1) {
          removable = true;
        }
        return this.renderExpandableElement(
          index,
          'immunosuppressiveConditions',
          'Condition or disease',
          medicalHistoryIllness.immunosuppressiveConditions,
          lodash.cloneDeep(suppressiveRefData),
          (object: any): void => {
            autosave({ id: parseInt(patientId), immunosuppressiveConditions: { id: value.id, ...object } }, value.id);
          },
          (): void => {
            performCRUD(
              medicalHistoryIllness.id,
              CRUD_OPERATION.DELETE,
              'immunosuppressiveCondition',
              value.id,
              refetch,
            );
          },
          removable,
        );
      },
    );

    return (
      <Fragment>
        {immunosuppressiveConditionsSelection}
        {this.renderAdditionalItem('Add another condition or disease', (): void => {
          performCRUD(
            medicalHistoryIllness.id,
            CRUD_OPERATION.CREATE,
            'immunosuppressiveCondition',
            undefined,
            refetch,
          );
        })}
      </Fragment>
    );
  };
  private renderExpandableElement = (
    index: number,
    inputType: string,
    inputTitle: string,
    stateList: HAIssueItem[],
    referenceData: ListData[],
    onChange: (object: any) => void,
    removeFunction: () => void,
    removable = true,
    segmented = false,
  ): JSX.Element => {
    const inputName = `${inputType}-${index + 1}`;

    // Map generates a new array with the id of the "Other" list data index.
    // Should only ever be one, therefore return first item
    // Default is undefined
    // This is an alternative to find() which isn't supported by IE
    // FIXME: This should move out of the render, and be a value calculated once and passed into this method
    const otherOptionIndex: string = referenceData.filter(
      (listItem: ListData): boolean => listItem.name.toLowerCase().trim() === 'other',
    )[0].id;
    //Put 'other' at the end without otherwise disrupting alphanumeric ordering.
    const location = referenceData.map((el) => el.name.toLowerCase().trim()).indexOf('other');
    if (location !== referenceData.length) {
      const first = referenceData.splice(location, 1);
      referenceData.push(first[0]);
    }
    return (
      <div key={inputName} className="infinite-dropdown-item">
        <SectionField htmlFor={inputName} title={`${inputTitle} ${index + 1}`}>
          <MoreInfoText
            moreInfoText={ILL_FIELDS.DEVICES.PACEMAKER_INFO}
            showIcon={true}
            showInfo={resolveListDataValue(stateList[index].value, referenceData) === 'Pacemaker'}>
            <DropDownField
              inputName={inputName}
              placeholder={'Please select'}
              options={referenceData}
              defaultValue={stateList[index].value}
              controlled={true}
              onChange={(e): void => {
                let saveObject = {};
                // If dropdown is other, don't override other value
                if (e.target.value === otherOptionIndex) {
                  saveObject = { value: e.target.value };
                } else {
                  saveObject = { value: e.target.value, other: '' };
                }
                onChange(saveObject);
              }}
            />
          </MoreInfoText>
          {stateList[index].value === otherOptionIndex && (
            <FreeTextField
              inputKey={stateList[index].other}
              inputName={`${inputName}-other`}
              placeholder={'Please describe'}
              inputType="text"
              defaultValue={stateList[index].other}
              onBlur={(e): void => {
                const saveObject = { value: stateList[index].value, other: e.target.value };
                this.addToViewedFields(`${inputName}-other`);
                onChange(saveObject);
              }}
            />
          )}
          {segmented && (
            <SectionField htmlFor={ILL_FIELDS.RESISTANT.PERMISSION.NAME} title={ILL_FIELDS.RESISTANT.PERMISSION.TITLE}>
              <SegmentedInput
                fieldName={ILL_FIELDS.RESISTANT.PERMISSION.NAME}
                options={SegementedInputYesNoUnsure}
                defaultSelected={stateList[index].doctorClearance}
                itemSelected={(selectedItem): void => {
                  const otherValue = stateList[index].other;
                  const dropdownValue = stateList[index].value;
                  let name = '';
                  if (typeof selectedItem != 'boolean') {
                    name = selectedItem.name.toUpperCase();
                  }
                  const saveObject = { value: dropdownValue, other: otherValue, doctorClearance: name };
                  onChange(saveObject);
                  this.forceUpdate();
                }}
              />
            </SectionField>
          )}
        </SectionField>

        {removable && (
          <div className="remove-item">
            <div
              onClick={(): void => {
                removeFunction();
              }}>
              <MinusNoFill className="icon" />
              {'Remove'}
            </div>
          </div>
        )}
      </div>
    );
  };

  private autoSaveAndValidate = async (
    updateObject: object,
    validationKey: string,
    setRefetch: boolean,
  ): Promise<void> => {
    // Use this to set the viewed items for the validation
    const { autosave, refetch } = this.props;

    // Save the data
    const autoSave = () => {
      return setRefetch ? autosave(updateObject, undefined, refetch) : autosave(updateObject);
    };

    await autoSave().then(() => {
      // Get viewed fields
      const viewedFields = [...this.state.viewedFields];

      // Add updated field
      viewedFields.push(validationKey);
      // Update with new validation set
      this.setState({ viewedFields: new Set(viewedFields) });
    });
  };

  private addToViewedFields = (validationKey: string): any => {
    //Get viewed fields
    const viewedFields = [...this.state.viewedFields];

    //Add updated field
    viewedFields.push(validationKey);

    //Update with new validation set
    this.setState({ viewedFields: new Set(viewedFields) });
  };

  private validateObject = (haPatientMedicalIllness: HAMedicalHistoryIllnessItem): any => {
    const validationRules = fetchRules(ValidationKeys.MedicalIllness, this.state.viewedFields);
    // Disable pre-appending of argument name to error messages
    const options = { fullMessages: false };
    return validate(haPatientMedicalIllness, validationRules, options);
  };
}

// @ts-ignore
export default HAMedicalHistoryIllness;
