export default class DictionaryUtilities {
  /**
   * Determines whether the given dictionary is empty.
   * @param {} dictionary The dictionary to test.
   * @returns {boolean} True if the dictionary is empty, or false otherwise.
   */
  public static isEmpty(dictionary: {}): boolean {
    for (const key in dictionary) {
      if (dictionary.hasOwnProperty(key)) {
        return false;
      }
    }
    return true;
  }
}
