import React, { Fragment } from 'react';
import { styled } from '@mui/system';
import { useTheme, Grid, Typography } from '@mui/material';

interface StatusProps {
  status: string;
}
const DOSE_STATUS = {
  ongoing: 'ONGOING',
  complete: 'COMPLETE',
};
const DOSE_SOURCE = {
  aria: 'ARIA',
  mq: 'MOSAIQ',
};
const getDoseStatus = (status: string): string => {
  const theme = useTheme();
  if (status.toUpperCase() === DOSE_STATUS.ongoing) return theme.palette.warning.dark;
  if (status.toUpperCase().startsWith(DOSE_STATUS.complete)) return theme.palette.success.main;
  return theme.palette.grey[600];
};

const CardContainer = styled('div')`
  background: ${(props) => props.theme.palette.grey[100]};
  border-radius: 4px;
  padding: 0px 16px 4px;
  flex-wrap: wrap;
`;
export const CardValue = styled('div')`
  line-height: 20px;
  font-weight: bold;
`;
const StatusCircle = styled('span')<StatusProps>`
  min-height: 16px;
  min-width: 16px;
  background-color: ${(props: StatusProps) => getDoseStatus(props.status)};
  border-radius: 50%;
  display: inline-block;
  margin-right: 4px;
`;
const SiteTitle = styled('div')`
  font-size: 16px;
  line-height: 20px;
  font-weight: 500;
  margin-bottom: 8px;
`;
const CardHeaderRow = styled('div')`
  border-bottom: 1px solid ${(props) => props.theme.palette.grey[300]};
  margin: 16px 0px 0px;
  padding-bottom: 8px;
`;
const CardStatusCol = styled('div')`
  display: flex;
  justify-content: flex-start;
  align-items: center;
  padding: 0px;
  min-width: 50%;
  flex-basis: auto;
`;
export const CardStatusHeader = styled('div')`
  font-size: 12px;
  color: ${(props) => props.theme.palette.grey[600]};
  display: flex;
  line-height: 15px;
  margin-right: 8px;
`;

// Something is end of treatment if it starts with complete. This will encapsulate Completed Early
const isEndOfTreatment = (status: string): boolean => status.toUpperCase().startsWith(DOSE_STATUS.complete);

const RODoseCard = (props: any): JSX.Element => {
  const { siteName, status, source, course, actualDose, totalDose, startDate, endDate, lastTreatment, technique } =
    props.attribute;
  const isAria = source.toUpperCase() === DOSE_SOURCE.aria;
  const theme = useTheme();
  return (
    <Fragment>
      <CardContainer>
        <CardHeaderRow>
          {isAria && (
            <CardStatusCol>
              <SiteTitle>{siteName}</SiteTitle>
            </CardStatusCol>
          )}
          <CardStatusCol>
            <CardStatusHeader>Status</CardStatusHeader>
            <StatusCircle status={status} />
            <Typography variant="body2" data-testid={`${siteName}-dose-status`}>
              {status}
            </Typography>
          </CardStatusCol>
        </CardHeaderRow>
        <Grid container spacing={0.5}>
          <Grid item xs={6}>
            <Typography variant="body2" color={theme.palette.text.secondary}>
              Source:
            </Typography>
            <Typography variant="body2" data-testid={`${siteName}-dose-source`}>
              {source.toUpperCase()}
            </Typography>
          </Grid>
          <Grid item xs={6}>
            <Typography variant="body2" color={theme.palette.text.secondary}>
              Course:
            </Typography>
            <Typography variant="body2" data-testid={`${siteName}-dose-course`}>
              {course}
            </Typography>
          </Grid>
          <Grid item xs={6}>
            <Typography variant="body2" color={theme.palette.text.secondary}>
              Actual dose:
            </Typography>
            <Typography variant="body2" data-testid={`${siteName}-dose-actual`}>
              {actualDose}
            </Typography>
          </Grid>
          <Grid item xs={6}>
            <Typography variant="body2" color={theme.palette.text.secondary}>
              Total dose:
            </Typography>
            <Typography variant="body2" data-testid={`${siteName}-dose-total`}>
              {totalDose}
            </Typography>
          </Grid>
          <Grid item xs={6}>
            <Typography variant="body2" color={theme.palette.text.secondary}>
              Start date:
            </Typography>
            <Typography variant="body2" data-testid={`${siteName}-dose-start`}>
              {startDate}
            </Typography>
          </Grid>
          <Grid item xs={6}>
            <Typography variant="body2" color={theme.palette.text.secondary}>
              End date:
            </Typography>
            <Typography variant="body2" data-testid={`${siteName}-dose-end`}>
              {endDate}
            </Typography>
          </Grid>
          <Grid item xs={6}>
            <Typography variant="body2" color={theme.palette.text.secondary}>
              {isEndOfTreatment(status) ? 'Last' : 'Latest'} treatment:
            </Typography>
            <Typography variant="body2" data-testid={`${siteName}-dose-last`}>
              {lastTreatment}
            </Typography>
          </Grid>
          <Grid item xs={6}>
            <Typography variant="body2" color={theme.palette.text.secondary}>
              Technique:
            </Typography>
            <Typography variant="body2" data-testid={`${siteName}-dose-technique`}>
              {technique}
            </Typography>
          </Grid>
        </Grid>
      </CardContainer>
    </Fragment>
  );
};

export default RODoseCard;
