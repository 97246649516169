import { createContext } from 'react';
import { ListData } from 'shared-components/interfaces';

interface IFormContext {
  mutations: any;
  typeOfResidenceRefData: ListData[];
}

const FormContext = createContext<IFormContext>({ mutations: {}, typeOfResidenceRefData: [] });

export default FormContext;
