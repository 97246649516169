import { useState } from 'react';
import { HelpOutline as HelpOutlineIcon } from '@mui/icons-material';
import { StandardDialog } from 'shared-components/components';

interface PropsType {
  open: boolean;
  setOpen: (value: boolean) => void;
  tooltip?: TooltipInterface;
  modalWidth?: 'small' | 'medium' | 'large';
}

export function TooltipModal(props: PropsType): JSX.Element {
  const { open, setOpen, tooltip, modalWidth = 'small' } = props;

  return (
    <StandardDialog open={open} onClose={() => setOpen(false)} title={tooltip?.header || ''}>
      <div dangerouslySetInnerHTML={{ __html: tooltip?.body || '' }}></div>
    </StandardDialog>
  );
}

interface TooltipInterface {
  header: string;
  body: string;
}

interface ToolTipProps {
  tooltip?: TooltipInterface;
}

export function FormRowToolTip(props: ToolTipProps): JSX.Element {
  const { tooltip } = props;
  const [open, setOpen] = useState(false);

  return (
    <>
      <HelpOutlineIcon sx={{ cursor: 'pointer' }} color="info" onClick={() => setOpen(true)} />
      {open && <TooltipModal open={open} tooltip={tooltip} setOpen={setOpen} />}
    </>
  );
}
