import { gql } from '@apollo/client';
import { HA_CONSENT_FRAGMENT } from 'op-graphql/fragments';

export const HA_CONSENT_QUERY = gql`
  query HealthAssessment($patientId: ID!) {
    healthAssessment(id: $patientId) {
      id
      ...HealthAssessmentConsent
    }
  }
  ${HA_CONSENT_FRAGMENT}
`;
