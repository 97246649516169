import React from 'react';
import { CardTitle, CardBody, CardBodyGroup, CardBodyBold } from './styles';
import CONSTANTS from './constants';

interface Props {
  name: string;
  patientDetails: {
    healthMedicareNumber: string;
    healthMedicareIrn: string;
    medicareExpiryRawMonth: string;
    medicareExpiryRawYear: string;
    emergencyContact: {
      firstName: string;
      homePhoneNumber: string;
      id: string;
      lastName: string;
      mobilePhoneNumber: string;
      relationship: string;
    };
  };
}

const EmergencyContactCard = ({ name, patientDetails }: Props): JSX.Element => {
  const { emergencyContact } = patientDetails;
  const contactNumber = emergencyContact?.mobilePhoneNumber || emergencyContact?.homePhoneNumber || '-';
  const hasEmergencyName = emergencyContact?.firstName && emergencyContact?.lastName;
  const { EMG_CONTACT, RELATIONSHIP, CONTACT } = CONSTANTS;

  return (
    <div data-testid="emergencyContactCard">
      <CardTitle>{name}</CardTitle>
      <CardBody>
        <CardBodyGroup data-testid="emgContactName">
          {EMG_CONTACT}
          <CardBodyBold>
            {hasEmergencyName ? `${emergencyContact.firstName} ${emergencyContact.lastName}` : '-'}
          </CardBodyBold>
        </CardBodyGroup>
        <CardBodyGroup data-testid="emgContactRelationship">
          {RELATIONSHIP}
          <CardBodyBold>{emergencyContact?.relationship || '-'}</CardBodyBold>
        </CardBodyGroup>
        <CardBodyGroup data-testid="emgContactPhone">
          {CONTACT}
          <CardBodyBold>{contactNumber}</CardBodyBold>
        </CardBodyGroup>
      </CardBody>
    </div>
  );
};

export default EmergencyContactCard;
