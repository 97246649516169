import React, { createContext, useState } from 'react';

export const PeerReviewContext = createContext<any>({});

export const PeerReviewProvider = ({ children }: any): JSX.Element => {
  const [peerReviewViolations, setPeerReviewViolations] = useState();
  return (
    <PeerReviewContext.Provider
      value={{
        peerReviewViolations,
        setPeerReviewViolations,
      }}>
      {children}
    </PeerReviewContext.Provider>
  );
};
