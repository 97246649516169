// eslint-disable-next-line no-use-before-define
import React, { Component } from 'react';

import { Region } from 'shared-components/enums';
import { PatientAttachments } from 'op-interfaces';
import { SavingStatus } from 'shared-components/enums';

import { RegistrationContainer, FileUploadField, region } from 'op-components';
import { ListData } from 'shared-components/interfaces';

interface Props {
  attachments: PatientAttachments;
  user: any;
  genderRefData: ListData[];
  documentTypeRefData: ListData[];
  refetchCallback: () => void;
  saveStatus: SavingStatus;
  validateOnLoad: boolean;
  isPso: boolean;
  updateAttachmentAcknowledged: (id: string, attachmentAcknowledged: boolean) => void;
}

interface State {
  fileUploaded: boolean;
  uploadInfoErrorModalOpen: boolean;
}

class RegistrationFileAttachments extends Component<Props, State> {
  public constructor(props: Props) {
    super(props);
    this.state = {
      fileUploaded: true,
      uploadInfoErrorModalOpen: false,
    };
  }

  private completeFileUpload = (): void => {
    this.setState({ fileUploaded: true });
  };

  private incompleteFileUpload = (): void => {
    this.setState({ fileUploaded: false });
  };

  private closeUploadInfoErrorModal = (): void => {
    this.setState({ uploadInfoErrorModalOpen: false });
  };

  private openUploadInfoErrorModal = (): void => {
    this.setState({ uploadInfoErrorModalOpen: true });
  };

  public render(): JSX.Element {
    const { attachments, genderRefData, isPso, saveStatus } = this.props;
    return (
      <RegistrationContainer
        onOpenUploadErrorModal={this.openUploadInfoErrorModal}
        fileUploaded={this.state.fileUploaded}
        patient={attachments}
        genderRefData={genderRefData}
        isPso={isPso}
        saveStatus={saveStatus}>
        {this.renderContents()}
      </RegistrationContainer>
    );
  }

  private renderContents = (): JSX.Element => {
    const {
      attachments,
      user,
      documentTypeRefData,
      refetchCallback,
      isPso,
      updateAttachmentAcknowledged,
      validateOnLoad,
    } = this.props;
    const FORM_HEADING = region === Region.AU ? 'Document Upload' : 'Attachments';

    return (
      <div className="form-page form-registration-information-notice">
        <form className="form-container">
          <div className="form-heading">{FORM_HEADING}</div>
        </form>
        <FileUploadField
          refetchCallback={refetchCallback}
          documentTypeRefData={documentTypeRefData}
          patient={attachments}
          user={user}
          fileUploaded={this.state.fileUploaded}
          onIncompleteFileUpload={this.incompleteFileUpload}
          onCompleteFileUpload={this.completeFileUpload}
          onOpenUploadErrorModal={this.openUploadInfoErrorModal}
          onCloseUploadErrorModal={this.closeUploadInfoErrorModal}
          uploadInfoErrorModalOpen={this.state.uploadInfoErrorModalOpen}
          isPso={isPso}
          updateAttachmentAcknowledged={updateAttachmentAcknowledged}
          validateOnLoad={validateOnLoad}
        />
      </div>
    );
  };
}

export default RegistrationFileAttachments;
