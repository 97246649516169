import { gql } from '@apollo/client';
import { REGISTRATION_ADDRESS_FRAGMENT } from 'op-graphql/fragments';

export const REGISTRATION_ADDRESS_QUERY = gql`
  query RegistrationAddress($id: ID!) {
    patient(id: $id) {
      id
      address {
        id
        ...Address
      }
      postalAddress {
        id
        ...Address
      }
      postalAddressSameAsResidential
    }
    user {
      id
      isPso
    }
  }
  ${REGISTRATION_ADDRESS_FRAGMENT}
`;

export const UPDATE_REGISTRATION_POSTAL_ADDRESS = gql`
  mutation UpdatePatientAuSamePostalAddress($id: ID!, $postalAddressSameAsResidential: Boolean) {
    updatePatientAuSamePostalAddress(id: $id, postalAddressSameAsResidential: $postalAddressSameAsResidential) {
      patient {
        id
        postalAddressSameAsResidential
      }
    }
  }
`;
