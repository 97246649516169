// eslint-disable-next-line no-use-before-define
import { useMutation } from '@apollo/client';
import { Form, Formik, FormikValues } from 'formik';
import { FormContainer } from 'op-components';
import { mapListData } from 'op-utils';
import { RegistrationContext } from 'op-contexts';
import React, { useContext } from 'react';
import { generatePath } from 'react-router';
import { useHistory, useParams } from 'react-router-dom';
import { FormRow } from 'shared-components/components';
import { AddressSectionField } from 'shared-components/components/FormikComponents';
import { Address, ListData } from 'shared-components/interfaces';
import {
  CONTINUE_BUTTON_TEXT,
  EMPTY_ADDRESS,
  HeaderSubTitle,
  HeaderTitle,
  linkedField,
  PSO_SUMMARY_LINK,
  registrationPath,
  RETURN_TO_SUBMIT_TEXT,
  sharedFormContainerProps,
  standardField,
  StyledHorizontalLine,
} from '../Helper';
import { PatientEmergencyContact, RegistrationFormUSIProps } from '../interfaces';
import { UPDATE_ALTERNATE_CONTACT_ADDRESS } from '../RegistrationFormQueries';
import { FIELDS } from './constants';
import { EMERGENCY_CONTACT_QUERY } from './EmergencyContactQueries';
import { generateValidationSchema } from './validation';

interface EmergencyContactFormIProps extends RegistrationFormUSIProps {
  patient: PatientEmergencyContact;
  readonly refData: {
    readonly relationshipsRefData: ListData[];
  };
}

const EmergencyContactForm = (props: EmergencyContactFormIProps): JSX.Element => {
  const {
    updateField,
    patient,
    handleShowSaveExitConfirm,
    isPso,
    refData: { relationshipsRefData },
  } = props;

  const registrationContext = useContext(RegistrationContext);
  const history = useHistory();
  const { patientId } = useParams<{ patientId: string }>();
  const goToSummary = registrationContext?.registrationSummaryVisited && !isPso;

  // Flatten out the emergency contact object
  const initialValues = { ...patient.emergencyContact, ...patient };

  const [updateAlternateContactAddressMutation] = useMutation(UPDATE_ALTERNATE_CONTACT_ADDRESS, {
    refetchQueries: [{ query: EMERGENCY_CONTACT_QUERY, variables: { id: patientId } }],
  });
  const referringPage = sessionStorage.getItem('referringPage');

  return (
    <Formik
      initialValues={initialValues}
      validate={(values: FormikValues) => generateValidationSchema(values)}
      validateOnChange={false}
      validateOnBlur={true}
      onSubmit={() => history.push(registrationPath(patientId, goToSummary ? 'summary' : 'referrers'))}>
      {({ errors, submitForm, setFieldTouched, values }) => {
        const sharedProps = sharedFormContainerProps('Emergency Contact', 4, '', !isPso);
        const formContainerProps = {
          ...sharedProps,
          submitButtonText: goToSummary ? RETURN_TO_SUBMIT_TEXT : CONTINUE_BUTTON_TEXT,
          continueDisabled: Object.keys(errors).length > 0,
          backLink: registrationPath(patientId, 'address'),
          handleShowSaveExitConfirm,
          saveAndExitLink: isPso && generatePath(referringPage || PSO_SUMMARY_LINK, { patientId }),
        };

        const handleUpdateAddress = (relatedName: string, address: Address) => {
          updateAlternateContactAddressMutation({
            variables: {
              patientId,
              alternateContactId: patient?.emergencyContact?.id,
              relatedName,
              line1: address?.line1 ?? '',
              line2: address?.line2 ?? '',
              city: address?.city ?? '',
              postcode: address?.postcode ?? '',
              state: address?.state ?? '',
              country: address?.country ?? '',
            },
          });
        };

        return (
          <FormContainer
            {...formContainerProps}
            submitForm={submitForm}
            handleValidation={(event: React.MouseEvent<HTMLInputElement>): void => {
              void event;
            }}>
            <Form>
              <HeaderTitle data-test-id="us-rego-page-title">{FIELDS.FORM_HEADING.TITLE}</HeaderTitle>
              <HeaderSubTitle data-test-id="us-rego-page-subtitle">* = required field</HeaderSubTitle>
              {standardField(FIELDS.FIRST_NAME, updateField)}
              {standardField(FIELDS.LAST_NAME, updateField)}
              {standardField(FIELDS.RELATIONSHIP, updateField, mapListData(relationshipsRefData))}
              {linkedField(FIELDS.MOBILE, FIELDS.HOME_PHONE, updateField, setFieldTouched)}
              <FormRow labelClass={'label-form-row'}>
                <StyledHorizontalLine>
                  <span>OR</span>
                </StyledHorizontalLine>
              </FormRow>
              {linkedField(FIELDS.HOME_PHONE, FIELDS.MOBILE, updateField, setFieldTouched)}
              <AddressSectionField
                name="address"
                values={values.address || EMPTY_ADDRESS}
                updateAddress={(address: Address) => handleUpdateAddress('address', address)}
              />
              {standardField(FIELDS.GUARDIAN, updateField)}
              {standardField(FIELDS.GUARDIAN_RELATIONSHIP, updateField, mapListData(relationshipsRefData))}
            </Form>
          </FormContainer>
        );
      }}
    </Formik>
  );
};

export default EmergencyContactForm;
