// eslint-disable-next-line no-use-before-define
import { useTheme } from '@mui/material';
import React, { useEffect } from 'react';
import { OnboardingPopoverPageLogData } from 'shared-components/interfaces/PageLogData';
import { POPOVER_LOG_EVENTS } from 'shared-components/utils/ElasticsearchHelpers';

interface PopoverContentsGPReferrerStepIProps {
  pageLoadEvent?: (pageData: OnboardingPopoverPageLogData) => {};
}

export const PopoverContentsGPReferrerStep1 = (props: PopoverContentsGPReferrerStepIProps): JSX.Element => {
  const { pageLoadEvent } = props;
  const theme = useTheme();

  useEffect(() => {
    const pageData: OnboardingPopoverPageLogData = {
      eventName: POPOVER_LOG_EVENTS.DISPLAYED,
    };
    pageLoadEvent && pageLoadEvent(pageData);
  }, []);

  return (
    <>
      <h1 id="onboarding-title">
        Referring specialist and GP{' '}
        <span id="onboarding-popover-new">
          <span>lookup</span>
          <span style={{ color: theme.palette.warning.dark }}>NEW</span>
        </span>
      </h1>
      <div id="onboarding-popover-textarea">
        <p>
          MOSAIQ external directory is now integrated in Horizon. You can select referring specialist and GP which will
          be added to the patient's record.
        </p>
      </div>
    </>
  );
};

export const PopoverContentsGPReferrerStep2 = (): JSX.Element => (
  <>
    <h1 id="onboarding-title">Referring specialist lookup</h1>
    <div id="onboarding-popover-textarea">
      <p>To find a referring specialist, use the lookup to open the search box.</p>
    </div>
  </>
);

export const PopoverContentsGPReferrerStep3 = (): JSX.Element => (
  <>
    <h1 id="onboarding-title">Search referring specialist</h1>
    <div id="onboarding-popover-textarea">
      <p>Type referring specialist name, click search and select the correct match.</p>
      <p>Want to look up GP? Follow the same steps using General Practitioner lookup.</p>
    </div>
  </>
);
