import { ListData } from 'shared-components/interfaces';

/**
 * Resolved the value for a given id in the provided list data. If no value found will return the id
 * @param id The id you have
 * @param listData The list data to search for an id
 */
export const resolveListDataValue = (id: string, listData: ListData[]): string => {
  const resolvedValue = listData.find((value): boolean => value.id === id);
  if (resolvedValue) {
    return resolvedValue.name;
  } else {
    return id;
  }
};
