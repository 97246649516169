import moment from 'moment';
import React from 'react';

import { generateGreetingText, isUs } from 'op-utils/helpers';
import ROProfilePhoto from 'shared-components/components/ROProfilePhoto/ROProfilePhoto';

import './GreetingsSection.scss';

interface GreetingsSectionIProps {
  showDateTime?: boolean;
  showProfilePhoto?: boolean;
  showGreeting?: boolean;
  showUsername?: boolean;
  username?: string;
}

const GreetingsSection = (props: GreetingsSectionIProps): JSX.Element => {
  const componentId = 'greetings-section';
  const dateFormat = isUs() ? 'ddd, MMMM DD YYYY' : 'ddd, DD MMMM YYYY';
  const greeting = generateGreetingText();

  const { showDateTime, showProfilePhoto, showGreeting, showUsername, username } = props;

  return (
    <div id={componentId}>
      {showDateTime && <div className="side-date-wrapper">{moment().format(dateFormat)}</div>}
      {showProfilePhoto && (
        <div className="side-user-photo">
          <ROProfilePhoto />
        </div>
      )}
      {showGreeting && <div className="side-greeting-wrapper">{greeting}</div>}
      {showUsername && <div className="side-username-wrapper">{username}</div>}
    </div>
  );
};

GreetingsSection.defaultProps = {
  showDateTime: true,
  showProfilePhoto: true,
  showGreeting: true,
  showUsername: true,
};

export default GreetingsSection;
