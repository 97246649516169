import React, { useState } from 'react';
import { DoseInfo } from './DoseEditModal';
import { useMutation } from '@apollo/client';
import { DELETE_DRUG_ORDER } from '../Queries';
import { useRouteMatch } from 'react-router-dom';
import { StandardDialog } from 'shared-components/components';
import { Typography } from '@mui/material';

interface DeleteDrugOrderModalProps {
  open: boolean;
  onClose: () => void;
  doseInfo?: DoseInfo;
  day?: number;
}

const DeleteDrugOrderModal = (props: DeleteDrugOrderModalProps): JSX.Element => {
  const [busy, setBusy] = useState(false);
  const match = useRouteMatch<{ careplanId: string }>();
  const { careplanId } = match.params;
  const { open, onClose, doseInfo, day } = props;
  const handleClose = () => {
    onClose();
  };
  const [deleteDrugOrder] = useMutation(DELETE_DRUG_ORDER, { refetchQueries: ['moCareplanData', 'latestCycles'] });
  const handleSubmit = () => {
    setBusy(true);
    deleteDrugOrder({
      variables: {
        careplanId,
        drugOrderId: doseInfo?.doseDay.dose.drugOrder.id,
        day: day ?? 0,
        cycle: doseInfo?.cycle,
      },
    })
      .then(() => {
        onClose();
      })
      .finally(() => {
        setBusy(false);
      });
  };
  return (
    <StandardDialog
      open={open}
      maxWidth="md"
      title={`Delete ${doseInfo?.drugOrder.drugName}`}
      onClose={handleClose}
      onSubmit={handleSubmit}
      submitText="Delete"
      destructiveSubmit>
      <Typography paddingY={2}>Please confirm that you would like to delete this drug.</Typography>
    </StandardDialog>
  );
};

export default DeleteDrugOrderModal;
