import React from 'react';
import { Table, TableBody, TableHead, TableCell, TableRow, tableCellClasses, Typography, styled } from '@mui/material';
interface Appt {
  activityCode: string;
  system: string;
  mappedValue: string;
  errorFields: string[];
}

interface Props {
  apptData: null | Appt[];
  setSaveFalse: (bool: boolean) => void;
  showInstructions: boolean;
}

const StyledTableCell = styled(TableCell)(({ theme }) => ({
  [`&.${tableCellClasses.head}`]: {
    backgroundColor: theme.palette.grey[200],
    color: theme.palette.common.black,
    fontWeight: 600,
  },
  [`&.${tableCellClasses.body}`]: {
    fontSize: 14,
  },
}));

const StyledTableRow = styled(TableRow)(({ theme }) => ({
  '&:nth-of-type(odd)': {
    backgroundColor: theme.palette.secondary.light,
  },
  // hide last border
  '&:last-child td, &:last-child th': {
    border: 0,
  },
}));

const StyledCell = styled(TableCell)`
  color: red;
`;

const StyledTableContainer = styled('div')<{ $showInstructions?: boolean }>`
  ${({ $showInstructions }) =>
    $showInstructions
      ? `
    overflow: auto;
    max-height: calc(100% - 670px);
  `
      : `
  overflow: auto;
  max-height: calc(100% - 510px);
  `}
`;

const validSystems = ['ROA', 'CWA', 'ARC', 'GCCQ', 'CCQ', 'ROV'];

const ApptTable = ({ apptData, setSaveFalse, showInstructions }: Props): JSX.Element => {
  return (
    <>
      <Typography variant="h6" sx={{ marginTop: '50px', marginBottom: '20px' }}>
        Appointment Mappings
      </Typography>
      <StyledTableContainer $showInstructions={showInstructions}>
        <Table data-testid="practitioner-search-results-table">
          <TableHead>
            <TableRow>
              <StyledTableCell>{'Activity Code'}</StyledTableCell>
              <StyledTableCell>{'System'}</StyledTableCell>
              <StyledTableCell>{'Mapped Value'}</StyledTableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {apptData &&
              apptData.map((data: Appt) => {
                if (!validSystems.includes(data.system)) setSaveFalse(true);
                return (
                  <StyledTableRow>
                    {renderCell('activityCode', data.activityCode, data.errorFields)}
                    {renderCell('system', data.system, data.errorFields)}
                    {renderCell('mappedValue', data.mappedValue, data.errorFields)}
                  </StyledTableRow>
                );
              })}
          </TableBody>
        </Table>
      </StyledTableContainer>
    </>
  );
};

const renderCell = (key: string, value: string, errorFields: string[]): JSX.Element => {
  if (errorFields.includes(key)) return <StyledCell>{value}</StyledCell>;
  return <TableCell>{value}</TableCell>;
};

export default ApptTable;
