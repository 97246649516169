// eslint-disable-next-line no-use-before-define
import { useMutation, useQuery } from '@apollo/client';
import React from 'react';
import { generatePath } from 'react-router';
import { useParams } from 'react-router-dom';
import { FormProps } from '../interfaces';
import AddressForm from './AddressForm';
import { REGISTRATION_ADDRESS_QUERY, UPDATE_REGISTRATION_FACILITY_DETAILS } from './AddressQueries';

const Address = (props: FormProps): JSX.Element => {
  const { patientId } = useParams<{ patientId: string }>();
  const { exitForm } = props;
  const [updateFacilityDetails] = useMutation(UPDATE_REGISTRATION_FACILITY_DETAILS);
  const { data, error } = useQuery(REGISTRATION_ADDRESS_QUERY, {
    variables: { id: patientId },
  });

  const { patient, user } = data || {};

  if (error || !data) return <></>;

  const updateField = (field: string, value: string | boolean | number) => {
    if (field === 'inHospitalHospiceFacility' && value === false) {
      updateFacilityDetails({
        variables: {
          id: patientId,
          inHospitalHospiceFacility: false,
          facilityName: '',
          facilityContactNumber: '',
        },
      });
      return;
    }

    // update other fields
    updateFacilityDetails({
      variables: {
        id: patientId,
        [field]: value,
      },
    });
  };

  return (
    <AddressForm
      updateField={updateField}
      patient={patient}
      handleShowSaveExitConfirm={() => exitForm(true)}
      previousPageLink={generatePath(`/patient/${patient.id}/registration/contact`)}
      isPso={user.isPso}
    />
  );
};

export default Address;
