import { findParentById } from '../../Utils/utils';

const CHART_SIZE_OFFSET = 580; // 670 to fit to screen
const chartSpacingConfig = [30, 20, 10, 20];

const syncConfig = {
  visibility: true,
  highlight: true,
  extremes: true,
};

const creditsConfig = {
  enabled: false,
};

const exportingConfig = {
  buttons: {
    contextButton: {
      menuItems: ['downloadCSV', 'downloadXLS'],
      y: -20,
      x: 5,
      symbol: 'download',
      symbolSize: 24,
    },
  },
};

const subExportingConfig = {
  buttons: {
    contextButton: {
      menuItems: ['downloadCSV', 'downloadXLS'],
      x: 110,
      y: -7,
      symbol: 'download',
      symbolSize: 24,
    },
  },
};

const handlePointClick = (point: Highcharts.Point, onPointClick: (seriesName: string, seriesIndex: number) => void) => {
  onPointClick(point.name, point.index);
};

// hacking the chart height to fit the container, because highcharts doesnt play well with flexbox
const setHeight = (chart: Highcharts.Chart) => {
  const currentElement = findParentById(chart.container, 'ROContainer');
  const parentSize = currentElement?.clientHeight;

  if (parentSize) {
    chart.setSize(undefined, Number((parentSize - CHART_SIZE_OFFSET).toFixed(0)));
  }
};

const selectPoint = (chart: Highcharts.Chart, selectedPoint: string, selectedIndex: number) => {
  chart.series.forEach(function (series) {
    if (series.visible) {
      const point = series.data[selectedIndex];

      if (point && selectedPoint) {
        point.graphic?.element.classList.add('point-selected');
      }
    }
  });
};

interface TopChartParams {
  onPointClick: (seriesName: string) => void;
  selectedPoint: string;
  selectedIndex: number;
  seeMoreUpdated: boolean;
  leftSeriesTitle: string;
  rightSeriesTitle: string;
  leftSeriesFields: string[];
  rightSeriesFields: string[];
  extraSeriesFields?: string[];
}

interface BottomChartParams {
  title: string;
  connector: string;
  cell: string;
  filterOverride: string;
  seeMore: boolean;
  precision?: number;
  dataLabelTitle?: string;
  dataLabelTitleWidth?: number;
  yAxisMax?: number;
  theme: any;
  toggleSeeMore: () => void;
}

const loadSeeMoreButton = (chart: Highcharts.Chart, toggleSeeMore: () => void, seeMore: boolean) => {
  const container = chart.container;

  const seeMoreButton = document.createElement('div');
  seeMoreButton.className = 'see-more-button';
  seeMoreButton.textContent = seeMore ? 'SEE LESS' : 'SEE MORE';
  seeMoreButton.onclick = () => {
    toggleChartHeight(container, seeMoreButton);
    toggleSeeMore();
  };
  if (seeMore) {
    container.classList.add('expanded');
    container.parentElement?.parentElement?.classList.add('expanded');
    container.parentElement?.parentElement?.parentElement?.classList.add('expanded');
  }
  container.appendChild(seeMoreButton);

  const toggleChartHeight = (container: any, button: any) => {
    container.classList.toggle('expanded');
    container.parentElement?.parentElement?.classList.toggle('expanded');
    container.parentElement.parentElement.parentElement.classList.toggle('expanded');

    if (container.classList.contains('expanded')) {
      button.textContent = 'SEE LESS';
    } else {
      button.textContent = 'SEE MORE';
    }
  };
};

export {
  setHeight,
  syncConfig,
  chartSpacingConfig,
  creditsConfig,
  exportingConfig,
  subExportingConfig,
  loadSeeMoreButton,
  handlePointClick,
  selectPoint,
  TopChartParams,
  BottomChartParams,
  CHART_SIZE_OFFSET,
};
