import gql from 'graphql-tag';

export const SMS_LOGS_PAGINATED = gql`
  query getSmsLogs($page: Int, $locationFilter: [String], $smsTypeFilter: [String], $statusFilter: [String]) {
    paginatedSmsLogs(
      page: $page
      locationFilter: $locationFilter
      smsTypeFilter: $smsTypeFilter
      statusFilter: $statusFilter
    ) {
      id
      success
      department
      patientId
      patientFullName
      patientDob
      message
      smsType
      activity
      sentDttm
      templateName
      bookingDttm
      practitionerName
    }
    totalSmsLogPages(locationFilter: $locationFilter, smsTypeFilter: $smsTypeFilter, statusFilter: $statusFilter)
  }
`;

export const SMS_LOGS_SMS_TYPES = gql`
  query CurrentSmsTypes {
    currentSmsTypes
  }
`;
