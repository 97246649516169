import { CarePlanState, CarePlanActionType, CarePlanAction } from './Interface';

export const CarePlanActions = (state: CarePlanState, action: CarePlanAction): CarePlanState => {
  switch (action.type) {
    case CarePlanActionType.SET_CAREPLAN_SITES:
      return { ...state, template: [...state.template, action.payload.template] };
    case CarePlanActionType.SET_CAREPLAN:
      return { ...state, careplan: { ...action.payload } };
    case CarePlanActionType.SET_TREATMENT_INFO:
      return { ...state, careplan: { ...state.careplan, treatmentInfo: { ...action.payload } } };
    case CarePlanActionType.SET_CAREPLAN_TEMPLATE:
      return { ...state, template: action.payload };
    case CarePlanActionType.SET_INTAKE_CODE_MAPPING:
      return { ...state, intakePageMapping: action.payload };
    default:
      return state;
  }
};
