import React, { useState } from 'react';
import Dialog from '@mui/material/Dialog';
import DialogContent from '@mui/material/DialogContent';
import { useRouteMatch } from 'react-router-dom';
import { LoadingSpinner } from 'shared-components/components';

interface PDFViewModalProps {
  open: boolean;
  onClose: () => void;
  cycleId: number;
}

const PDFViewModal = (props: PDFViewModalProps) => {
  const { open, onClose, cycleId } = props;
  const match = useRouteMatch<{ id: string; careplanId: string }>();
  const { careplanId } = match.params;
  const [state, setState] = useState({ loading: true, error: false });
  return (
    <Dialog
      fullWidth={true}
      maxWidth="lg"
      onClose={() => {
        onClose();
      }}
      open={open}>
      <DialogContent style={{ height: '90vh', overflowY: 'hidden' }}>
        {state.loading && (
          <LoadingSpinner loadingText={'Loading Submission'} subtitle={'Please wait while we set things up for you'} />
        )}
        {state.error && <div>An error occurred while generating the PDF, please contact support</div>}
        <iframe
          title="prescriptionpage"
          src={`/mo_portal/careplan/${careplanId}/${cycleId}/prescription.pdf`}
          width="100%"
          height="100%"
          onLoad={(): void => {
            setState((state) => {
              return { ...state, loading: false };
            });
          }}
          onError={(): void => {
            setState((state) => {
              return { ...state, error: true };
            });
          }}>
          This browser does not support PDFs.
        </iframe>
      </DialogContent>
    </Dialog>
  );
};

export default PDFViewModal;
