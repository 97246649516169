// eslint-disable-next-line no-use-before-define
import React from 'react';
import SuggestedTemplates from './SuggestedTemplates';
import { Typography } from '@mui/material';

import { CurrentAppConfig } from 'op-pages/RO/Careplan/AppConfig';
interface TemplateProps {
  id: string;
  name: string;
  ranking: number;
  approvedDate: string;
}

interface Props {
  templates: TemplateProps[];
  selectCareplan: (tumourStream: string, name: string, id: number, empty: string, type: string) => void;
  tumourStream: string;
}

const FrequentlyUsed = (props: Props): JSX.Element => {
  const { templates, selectCareplan, tumourStream } = props;

  const generateFooter = (template: TemplateProps) => {
    const currDate = new Date(template.approvedDate);
    return (
      <Typography variant="body2">
        Last modified: {currDate.getUTCDate() + '/' + (currDate.getUTCMonth() + 1) + '/' + currDate.getUTCFullYear()}
      </Typography>
    );
  };

  return (
    <SuggestedTemplates
      templates={templates}
      tumourStream={tumourStream}
      onSelect={selectCareplan}
      title={'Frequently Used Templates'}
      subheading={`The following careplan template suggestions are based on your selections for the '${tumourStream}' ${CurrentAppConfig.DefaultValue.tumourName} stream in the last 90 days.`}
      generateFooter={generateFooter}
      type={'freq'}
    />
  );
};
export default FrequentlyUsed;
