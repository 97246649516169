import { gql } from '@apollo/client';

export const DEMOGRAPHICS_QUERY = gql`
  query RegistrationDemographicDetails($id: ID!) {
    patient(id: $id) {
      id
      patientState
      countryOfBirth
      languageAtHome
      interpreterRequired
      occupation
      heritage
      maritalStatus
      ambulanceMembership
      ambulanceMembershipNumber
    }
    atsiHeritageRefData: listData(category: "heritage") {
      id
      name
    }
    maritalStatusRefData: listData(category: "maritalStatus") {
      id
      name
    }
    countryOfBirthRefData: listData(category: "countryOfBirth", removeOutdated: true) {
      id
      name
    }
    languageSpokenRefData: listData(category: "languages", removeOutdated: true) {
      id
      name
    }
    user {
      id
      isPso
    }
  }
`;

export const UPDATE_REGISTRATION_DEMOGRAPHIC_DETAILS = gql`
  mutation UpdatePatientAuDemographics(
    $patientId: ID!
    $countryOfBirth: String
    $languageAtHome: String
    $interpreterRequired: Boolean
    $occupation: String
    $heritage: String
    $maritalStatus: String
    $ambulanceMembership: Boolean
    $ambulanceMembershipNumber: String
  ) {
    updatePatientAuDemographics(
      patientId: $patientId
      countryOfBirth: $countryOfBirth
      languageAtHome: $languageAtHome
      interpreterRequired: $interpreterRequired
      occupation: $occupation
      heritage: $heritage
      maritalStatus: $maritalStatus
      ambulanceMembership: $ambulanceMembership
      ambulanceMembershipNumber: $ambulanceMembershipNumber
    ) {
      patient {
        id
        countryOfBirth
        languageAtHome
        niceLanguageAtHome
        interpreterRequired
        occupation
        heritage
        niceHeritage
        maritalStatus
        maritalStatusValue
        ambulanceMembership
        ambulanceMembershipNumber
      }
    }
  }
`;
