// eslint-disable-next-line no-use-before-define
import React, { Component } from 'react';
import Drawer from '@mui/material/Drawer';
import IconButton from '@mui/material/IconButton';
import { RouteComponentProps, withRouter } from 'react-router-dom';
import './UKSideBar.scss';
import UKSideBarList from './UKSideBarList/UKSideBarList';
import classNames from 'classnames';

interface SideProps extends RouteComponentProps {
  isActive?: boolean;
}

interface SideState {
  currentLocation: string;
  sidebarOpen: boolean;
}

class UKSideBar extends Component<SideProps, SideState> {
  public constructor(props: SideProps) {
    super(props);
    this.state = {
      currentLocation: window.location.pathname.toString().toLowerCase(),
      sidebarOpen: true,
    };
    this.props.history.listen((location) => {
      this.setState({ currentLocation: location.pathname.toString() });
    });
  }

  private toggleSidebar = () => {
    this.setState({ sidebarOpen: !this.state.sidebarOpen });
  };

  public render(): JSX.Element {
    return (
      <div
        className={classNames(
          'sidebar-wrapper',
          { 'drawer-open': this.state.sidebarOpen },
          { 'drawer-close': !this.state.sidebarOpen },
          { 'slide-open': this.state.sidebarOpen },
          { 'slide-close': !this.state.sidebarOpen },
        )}
        style={{
          display: this.state.currentLocation.includes('/appointments') ? 'flex' : 'none',
        }}>
        <div id="uk-sidebar">
          <Drawer
            variant="permanent"
            className={classNames(
              'uk-drawer',
              { 'drawer-open': this.state.sidebarOpen },
              { 'drawer-close': !this.state.sidebarOpen },
            )}>
            <div className="sidebar-handle-wrapper" style={{ width: '100%' }}>
              {
                <IconButton
                  className={classNames(
                    'sidebar-handle',
                    { 'sidebar-handle-closed': !this.state.sidebarOpen },
                    { 'sidebar-handle-opened': this.state.sidebarOpen },
                  )}
                  color="inherit"
                  aria-label="open drawer"
                  onClick={this.toggleSidebar}
                  edge="start"
                  size="large"></IconButton>
              }
            </div>

            <UKSideBarList
              displayText={this.state.sidebarOpen}
              heading="Appointment Type"
              items={[
                {
                  text: 'Pending RO',
                  iconType: 'svg',
                  path: 'M26.7999 4.00009H5.19992C4.53326 4.00009 3.99992 4.53342 3.99992 5.20009V26.8001C3.99992 27.3334 4.53326 28.0001 5.19992 28.0001H26.7999C27.3333 28.0001 27.9999 27.3334 27.9999 26.8001V5.20009C27.9999 4.53342 27.3333 4.00009 26.7999 4.00009ZM11.9998 9.33353H9.33318V12.0002H11.9998V9.33353ZM22.6665 9.33353H14.6665V12.0002H22.6665V9.33353ZM22.6665 14.6668H14.6665V17.3335H22.6665V14.6668ZM14.6665 20.0002H22.6665V22.6668H14.6665V20.0002ZM9.33318 14.6668H11.9998V17.3335H9.33318V14.6668ZM11.9998 20.0002H9.33318V22.6668H11.9998V20.0002ZM6.66651 25.3335H25.3332V6.66681H6.66651V25.3335Z',
                  component: '/appointments',
                },
                {
                  text: 'Clinic Visit',
                  iconType: 'svg',
                  path: 'M15.9998 2.6665C10.8398 2.6665 6.6665 6.83984 6.6665 11.9998C6.6665 18.9998 15.9998 29.3332 15.9998 29.3332C15.9998 29.3332 25.3332 18.9998 25.3332 11.9998C25.3332 6.83984 21.1598 2.6665 15.9998 2.6665ZM9.33319 11.9998C9.33319 8.31981 12.3199 5.33315 15.9999 5.33315C19.6799 5.33315 22.6665 8.31981 22.6665 11.9998C22.6665 15.8398 18.8265 21.5865 15.9999 25.1732C13.2265 21.6132 9.33319 15.7998 9.33319 11.9998ZM12.6665 11.9998C12.6665 10.1589 14.1589 8.6665 15.9998 8.6665C17.1907 8.6665 18.2912 9.30183 18.8866 10.3332C19.482 11.3645 19.482 12.6352 18.8866 13.6665C18.2912 14.6978 17.1907 15.3332 15.9998 15.3332C14.1589 15.3332 12.6665 13.8408 12.6665 11.9998Z',
                  component: '/appointments',
                },
              ]}
            />
          </Drawer>
        </div>
      </div>
    );
  }
}

export default withRouter(UKSideBar);
