// eslint-disable-next-line no-use-before-define
import React, { Component } from 'react';
import { gql } from '@apollo/client';

import { Logger } from 'shared-components/utils';

import { GraphQLHelper } from 'op-utils';
import { SavingStatus } from 'shared-components/enums';
import { ApolloCacheType, Diff } from 'op-types';
import { ApolloCachePromise } from 'op-interfaces';
import { HAMutationType } from 'op-enums';
import { DocumentNode } from 'graphql';

const logger = new Logger('HealthAssessment');

const GET_APOLLO_CACHE = gql`
  {
    pendingSaveCount @client
    saveErrorCount @client
  }
`;

type BeginBlock = () => void;
type SuccessBlock = (response: any) => void;
type ErrorBlock = (error: any) => void;
type FinallyBlock = () => Promise<void>;

export interface WithHealthAssessment {
  getHAMutation: (updateObject: object, replaceId?: string) => object;

  getSaveStatus: (client: any) => Promise<SavingStatus>;

  mutateGraph: (
    client: any,
    updateObject: object,
    beginBlock: BeginBlock,
    success: SuccessBlock,
    errorBlock: ErrorBlock,
    finallyBlock: FinallyBlock,
    replaceId?: string,
    crudMutation?: boolean,
    mutationType?: HAMutationType,
  ) => Promise<void>;
  getApolloCache: ApolloCacheType;
  mutateCRUDGraph: (
    client: any,
    updateObject: object,
    beginBlock: BeginBlock,
    success: SuccessBlock,
    errorBlock: ErrorBlock,
    finallyBlock: FinallyBlock,
  ) => void;
}

type WithoutHealthAssessment<T> = Diff<T, WithHealthAssessment>;

const mapOptimisticResponse = {
  mobilityAids: 'MobilityAidType',
  wellbeingIssues: 'WellbeingIssueType',
  internalDevices: 'InternalDeviceType',
  intractableInfectionOrIllnesses: 'IntractableInfectionOrIllnessType',
  diabetesResponses: 'DiabetesResponseType',
  immunosuppressiveConditions: 'ImmunosuppressiveConditionType',
  medications: 'MedicationType',
  allergies: 'AllergyType',
  operations: 'OperationType',
  cancer: 'CancerType',
  otherRadiotherapyConditions: 'OtherRadiotherapyConditionType',
};

function withHealthAssessment<Props>(
  WrappedComponent: React.ComponentClass<Props>,
): React.ComponentClass<WithoutHealthAssessment<Props>> {
  return class HealthAssessment extends Component<WithoutHealthAssessment<Props>> {
    public render(): JSX.Element {
      return (
        <>
          <WrappedComponent
            getHAMutation={(updateObject: object, replaceId?: string) => {
              return this.getHealthAssessmentMutation(
                updateObject,
                replaceId,
                HAMutationType.GET_HEALTH_ASSESSMENT_MUTATION,
              );
            }}
            getSaveStatus={(client: any): Promise<SavingStatus> => {
              return this.getSaveStatus(client);
            }}
            mutateGraph={(
              client: any,
              updateObject: object,
              beginBlock: BeginBlock,
              success: SuccessBlock,
              errorBlock: ErrorBlock,
              finallyBlock: FinallyBlock,
              replaceId?: string,
              crudMutation?: boolean,
              mutationType?: HAMutationType,
            ): Promise<void> => {
              return this.mutateGraph(
                client,
                updateObject,
                beginBlock,
                success,
                errorBlock,
                finallyBlock,
                replaceId,
                crudMutation,
                mutationType,
              );
            }}
            mutateCRUDGraph={(
              client: any,
              updateObject: object,
              beginBlock: BeginBlock,
              success: SuccessBlock,
              errorBlock: ErrorBlock,
              finallyBlock: FinallyBlock,
            ): Promise<void> => {
              return this.mutateGraph(
                client,
                updateObject,
                beginBlock,
                success,
                errorBlock,
                finallyBlock,
                undefined,
                true,
              );
            }}
            getApolloCache={(client: any): Promise<ApolloCachePromise> => {
              return this.getApolloCache(client);
            }}
            {...(this.props as Props)}
          />
        </>
      );
    }

    /**
     * Get the GQL variables that will be ued to call the mutation.
     * @param {object} updateObject The object that contains the keys and values to be updated.
     */
    private getGQLVariables = (haId: string, updateObject: object, addTypeName?: boolean): object => {
      // Always need an id for the mutation.
      const variables: { [key: string]: any } = {
        id: haId,
      };

      this.buildInternalGQLVariables(variables, updateObject, addTypeName);

      logger.debug('getGQLVariables', JSON.stringify(variables));
      return variables;
    };

    private buildInternalGQLVariables = (variables: any, updateObject: object, addTypeName?: boolean): object => {
      for (const updateEntry of Object.entries(updateObject)) {
        const [key, value] = updateEntry;

        // If the value is an object, need to do a deep copy
        if (typeof value === 'object') {
          variables[key] = { ...value };
        } else {
          variables[key] = value;
        }

        if (addTypeName && mapOptimisticResponse.hasOwnProperty(key)) {
          variables[key].__typename = mapOptimisticResponse[key as keyof typeof mapOptimisticResponse];
        }
      }

      return variables;
    };

    /**
     * Function that is used to build the mutation string for distress thermometer. It will accept an object to build the required string.
     * @param {object} updateObject The update object that will build the graphql mutation string
     */
    private getGQLUpdateHAMutationString = (updateObject: object, replaceId?: string): DocumentNode => {
      const mutationParams = GraphQLHelper.mapObjectToMutationParams(updateObject);
      const functionParams = GraphQLHelper.mapObjectToFunctionParams(updateObject);
      const functionValues = GraphQLHelper.flattenObjectToQueryString(updateObject);
      let graphMutation = `mutation UpdateHealthAssessment(${mutationParams}) {
        updateHealthAssessment(${functionParams}) {
          healthAssessment ${functionValues}
        }
      }`;

      if (replaceId) {
        graphMutation = graphMutation.replace(/#replaceId/g, `"${replaceId}"`);
      }

      logger.debug('getGQLUpdateHAMutationString', graphMutation);
      return gql(graphMutation);
    };

    private getGQLTreatmentMutationString = (updateObject: object, replaceId?: string): DocumentNode => {
      const mutationParams = GraphQLHelper.mapObjectToMutationParams(updateObject);
      const functionParams = GraphQLHelper.mapObjectToFunctionParams(updateObject);
      const functionValues = GraphQLHelper.flattenObjectToQueryString(updateObject);

      let graphMutation = `mutation UpdateTreatment(${mutationParams}) {
        updateTreatment(${functionParams}) {
          treatment ${functionValues}
        }
      }`;

      if (replaceId) {
        graphMutation = graphMutation.replace(/#replaceId/g, `"${replaceId}"`);
      }

      logger.debug('getGQLUpdateHAMutationString', graphMutation);
      return gql(graphMutation);
    };

    private getGQLReactionMutationString = (updateObject: object, replaceId?: string): DocumentNode => {
      const mutationParams = GraphQLHelper.mapObjectToMutationParams(updateObject);
      const functionParams = GraphQLHelper.mapObjectToFunctionParams(updateObject);
      const functionValues = GraphQLHelper.flattenObjectToQueryString(updateObject);

      let graphMutation = `mutation UpdateReaction(${mutationParams}) {
        updateReaction(${functionParams}) {
          reaction ${functionValues}
        }
      }`;

      if (replaceId) {
        graphMutation = graphMutation.replace(/#replaceId/g, `"${replaceId}"`);
      }

      logger.debug('getGQLReactionMutationString', graphMutation);
      return gql(graphMutation);
    };

    private getHealthAssessmentMutation = (
      updateObject: object,
      replaceId?: string,
      mutationType?: HAMutationType,
    ): object => {
      // Check to make sure that the update object has a key defined as id, and if it doesn't display a warning in console for developers.
      // @ts-ignore
      const updateObjectId = updateObject.id;
      const { UPDATE_TREATMENT, UPDATE_REACTION } = HAMutationType;

      if (updateObjectId === null || updateObjectId === undefined || updateObjectId === '') {
        logger.debug(
          'getHealthAssessmentMutation',
          '**** MISSING THE ID FROM THE UPDATE OBJECT ****',
          JSON.stringify(updateObject),
        );
      }

      const variables = this.getGQLVariables(updateObjectId, updateObject, true);

      let mutation, optimisticResponse;
      switch (mutationType) {
        case UPDATE_TREATMENT:
          mutation = this.getGQLTreatmentMutationString(updateObject, replaceId);
          optimisticResponse = {
            updateTreatment: {
              treatment: { ...variables, __typename: 'TreatmentType' },
              errors: null,
              __typename: 'UpdateTreatmentType',
            },
          };
          break;
        case UPDATE_REACTION:
          mutation = this.getGQLReactionMutationString(updateObject, replaceId);
          optimisticResponse = {
            updateReaction: {
              reaction: { ...variables, __typename: 'ReactionType' },
              errors: null,
              __typename: 'UpdateReaction',
            },
          };
          break;
        default:
          mutation = this.getGQLUpdateHAMutationString(updateObject, replaceId);
          optimisticResponse = {
            updateHealthAssessment: {
              healthAssessment: { ...variables, __typename: 'HealthAssessmentType' },
              errors: null,
              __typename: 'UpdateHealthAssessment',
            },
          };
          break;
      }

      return {
        mutation,
        variables: this.getGQLVariables(updateObjectId, updateObject),
        optimisticResponse,
      };
    };

    private getGQLCRUDHAMutationString = (updateObject: object): DocumentNode => {
      const mutationParams = GraphQLHelper.mapObjectToMutationParams(updateObject);
      const functionParams = GraphQLHelper.mapObjectToFunctionParams(updateObject);

      const graphMutation = `mutation ManageHARelatedObjects(${mutationParams}) {
        manageHARelatedObjects(${functionParams}) {
          completed
        }
      }`;

      logger.debug('getGQLCRUDHAMutationString', graphMutation);
      return gql(graphMutation);
    };

    private getHealthAssessmentCRUDMutation = (updateObject: object): object => {
      const variables = this.buildInternalGQLVariables({}, updateObject);
      const response = { ...variables };

      return {
        mutation: this.getGQLCRUDHAMutationString(updateObject),
        fetchPolicy: 'no-cache',
        variables,
        optimisticResponse: {
          manageHARelatedObjects: response,
          __typename: 'ManageHARelatedObjects',
        },
      };
    };

    private getSaveStatus = async (client: any): Promise<SavingStatus> => {
      // Check to ensure that the client has the query object
      if (client.query) {
        try {
          const apolloCache = await client.query({ query: GET_APOLLO_CACHE });
          const currentPendingSaveCount = apolloCache.data.pendingSaveCount;
          const saveErrorCount = apolloCache.data.saveErrorCount;

          if (currentPendingSaveCount > 0) {
            return SavingStatus.SAVING;
          }

          if (saveErrorCount > 0) {
            return SavingStatus.FAILED;
          }
        } catch (error) {
          logger.debug('**** FAILED TO FETCH CACHED APOLLO ****');
        }
      }

      return SavingStatus.SAVED;
    };

    /**
     * TODO: Dic Call graph mutation on the object
     *
     */
    private mutateGraph = async (
      client: any,
      updateObject: object,
      beginBlock: () => void,
      success: (response: any) => void,
      errorBlock: (error: any) => void,
      finallyBlock: () => Promise<void>,
      replaceId?: string,
      crudMutation?: boolean,
      mutationType?: HAMutationType,
    ): Promise<void> => {
      // Check to ensure that the client has the mutate function
      if (!client.mutate) {
        throw 'Mutate missing';
      }

      let errorCount = 0,
        currentPendingSaveCount = 0;

      let processFailed = false;

      try {
        const apolloCache = await this.getApolloCache(client);
        currentPendingSaveCount = apolloCache.currentPendingSaveCount;
        errorCount = apolloCache.saveErrorCount;
      } catch (error) {
        logger.debug('mutateGraph', '**** Error mutating during cached data fetch ****', error);
        processFailed = true;
        errorBlock(error);
      }

      if (!processFailed) {
        // Get the cached data from apollo
        try {
          client &&
            client.writeQuery({
              query: gql`
                query {
                  pendingSaveCount
                }
              `,
              data: {
                pendingSaveCount: currentPendingSaveCount + 1,
              },
            });
          beginBlock();

          // Call the mutation from the client after determining which type of mutation it needs to go through
          let mutation;
          if (crudMutation === true) {
            mutation = this.getHealthAssessmentCRUDMutation(updateObject);
          } else {
            mutation = this.getHealthAssessmentMutation(updateObject, replaceId, mutationType);
          }
          const result = await client.mutate(mutation);

          // Check to see if there are any errors in result
          if (result.data.updateHealthAssessment && result.data.updateHealthAssessment.errors) {
            client &&
              client.writeQuery({
                query: gql`
                  query {
                    saveErrorCount
                  }
                `,
                data: {
                  saveErrorCount: errorCount + 1,
                },
              });
          } else {
            // Call the success block
            success(result);
          }
        } catch (error) {
          logger.debug('mutateGraph', '**** Error mutating ****', error);
          // An error has been caught during the mutation, so increment the error count
          client &&
            client.writeQuery({
              query: gql`
                query {
                  saveErrorCount
                }
              `,
              data: {
                saveErrorCount: errorCount + 1,
              },
            });

          // Call the error block
          errorBlock(error);
        }
      }

      // Finally block
      const finalApolloCache = await this.getApolloCache(client);
      client &&
        client.writeQuery({
          query: gql`
            query {
              pendingSaveCount
            }
          `,
          data: {
            pendingSaveCount: finalApolloCache.currentPendingSaveCount - 1,
          },
        });

      await finallyBlock();
    };

    private getApolloCache = async (client: any): Promise<ApolloCachePromise> => {
      try {
        const apolloCache = await client.query({ query: GET_APOLLO_CACHE });
        const currentPendingSaveCount = apolloCache.data.pendingSaveCount;
        const saveErrorCount = apolloCache.data.saveErrorCount;
        return {
          currentPendingSaveCount: currentPendingSaveCount,
          saveErrorCount: saveErrorCount,
        };
      } catch (error) {
        throw error;
      }
    };
  };
}

export default withHealthAssessment;
