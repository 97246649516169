import { useQuery } from '@apollo/client';
import React, { Fragment, useMemo } from 'react';

import { SummaryCard, SummaryRow } from 'op-components';
import { evaluateDetailText } from 'op-components/OP/SummaryRow/SummaryRow';
import { HAAllergy, BaseSelectItem } from 'op-interfaces/HAInterfaces';
import { HA_ALLERGY_QUERY } from 'op-pages/OP/HealthAssessment/HASharedComponents/queries';
import { HASubComponentProps, HealthAssessmentQueryData } from 'op-pages/OP/HealthAssessment/HASharedComponents/types';
import { Edit, Success } from 'shared-components/images';
import { SerializedListDataObject } from 'shared-components/interfaces';
import { convertListDataToObject } from 'shared-components/utils/ListDataHelpers';

const AllergiesSensitivities = (props: HASubComponentProps): JSX.Element => {
  const { patientId, isPso, navigate } = props;

  const { data, loading } = useQuery<HealthAssessmentQueryData>(HA_ALLERGY_QUERY, {
    variables: { patientId },
  });

  const sensitivityRefDataSerialized = useMemo<SerializedListDataObject>(
    () => convertListDataToObject(data?.sensitivityRefData),
    [data?.sensitivityRefData],
  );

  const reactionRefDataSerialized = useMemo<SerializedListDataObject>(
    () => convertListDataToObject(data?.reactionRefData),
    [data?.reactionRefData],
  );

  if (!data || loading) return <></>;

  const {
    healthAssessment: { allergies, allergyBool },
  } = data;

  const renderSummaryRow = (titleText: string, detailText: string, subQuestion = false) => {
    return (
      <SummaryRow
        headingText={titleText}
        detailText={evaluateDetailText(detailText)}
        displayAsError={false}
        displayAsOptional={false}
        subQuestion={subQuestion}
      />
    );
  };

  const renderReactionExperienced = (reactions: BaseSelectItem[]) => {
    return reactions.map((reaction, index) => {
      return (
        <Fragment key={reaction.id}>
          {renderSummaryRow(`Reaction experienced ${index + 1}`, reactionRefDataSerialized[reaction.value], true)}
          {reactionRefDataSerialized[reaction.value] === 'Other' && renderSummaryRow('Details', reaction.other, true)}
        </Fragment>
      );
    });
  };

  const doesTheAllergyHaveOtherFieldsToRender = (sensitivityCode: HAAllergy['sensitivity']): boolean => {
    const sensitivity = sensitivityRefDataSerialized[sensitivityCode];
    return sensitivity === 'Food' || sensitivity === 'Latex' ? false : true;
  };

  return (
    <SummaryCard
      title={'Allergies/sensitivities'}
      primaryIcon={isPso ? <></> : <Edit className="primary-icon" />}
      secondaryIcon={<Success className="secondary-icon valid-icon" />}
      showEdit={!isPso}
      onClick={(): void => {
        if (!isPso) {
          navigate('allergies');
        }
      }}>
      <SummaryRow
        headingText="Do you have any allergies or reactions?"
        detailText={evaluateDetailText(allergyBool)}
        displayAsError={false}
        displayAsOptional={false}
      />

      {allergyBool &&
        allergies.map((allergy, index) => {
          return (
            <Fragment key={allergy.id}>
              {renderSummaryRow(
                `Allergy/sensitivity ${index + 1}`,
                sensitivityRefDataSerialized[allergy.sensitivity],
                true,
              )}
              {doesTheAllergyHaveOtherFieldsToRender(allergy.sensitivity) && (
                <>
                  {renderSummaryRow('Specify', allergy.specify, true)}
                  {allergy.reaction.length > 0 && renderReactionExperienced(allergy.reaction)}
                  {renderSummaryRow('Area affected', allergy.area, true)}
                </>
              )}
            </Fragment>
          );
        })}
    </SummaryCard>
  );
};

export default AllergiesSensitivities;
