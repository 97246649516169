export const CAREPLAN_PAGE = {
  CREATE: '',
  DIAGNOSIS_A: 'primary',
  DIAGNOSIS_B: 'newPrimary',
  STAGING: 'tnmstaging',
  INTAKE: 'intake',
  TREATMENT_INFO: 'treatmentInfo',
  PROTOCOL_SELECTION: 'protocolSelection',
  PRESCRIPTION: 'prescription',
  SUBMISSION: 'submission',
};

export const DRUG_ORDER_TYPE = {
  TAKE_AT_HOME: 'Take At Home',
  IN_HOUSE_TREATMENT: 'In House Treat',
};

export const ADMIN_INSTRUCTIONS = 'Admin Instructions';
export const PATIENT_INSTRUCTIONS = 'Patient Instructions';

export const LIST_OPTIONS = {
  INTENT: 'intent',
  TREATING_DEPARTMENT: 'treatingDepartment',
  DOSE_CHANGE_REASON: 'moDoseChangeReason',
};

export const DRUG_CATEGORY = {
  TREATMENT: 'Treatment',
  SUPPORTING: 'Supporting',
};
