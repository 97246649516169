import React from 'react';
import {
  ProtocolSummaryTableContainer,
  StyledProtocolSummaryTable,
  ProtocolSummaryTableInfo,
} from './StyledComponents';
import { ProtocolSummaryProps, SummaryDose, DrugOrderInterface } from '../../interfaces';
import { OpenInNew } from '@mui/icons-material';
import { getDrugName } from 'op-pages/MO/utils';

const ProtocolSummaryTable = ({ protocol, drugOrders }: ProtocolSummaryProps): JSX.Element => {
  // put drug orders in map for easy access
  const drugOrderLookup: { [index: string]: DrugOrderInterface } = {};
  for (const drugOrder of drugOrders) {
    if (drugOrder.id === undefined || drugOrder.id in drugOrderLookup) {
      continue;
    }
    drugOrderLookup[drugOrder.id] = drugOrder;
  }
  const protocolDoses = protocol.doses
    .filter((dose) => drugOrderLookup[dose.drugOrder]?.drugCategory !== 'Supporting')
    .sort((a, b) => a.cycle - b.cycle || a.day - b.day || a.order - b.order);

  if (protocolDoses.length <= 0) return <></>;

  // dedupe and list days for each cycle and sub-drug-order
  const cycleDoseDays: { [index: number]: Map<string, SummaryDose> } = {};
  for (const dose of protocolDoses) {
    if (dose.cycle in cycleDoseDays === false) {
      cycleDoseDays[dose.cycle] = new Map();
    }
    if (!cycleDoseDays[dose.cycle].has(dose.drugOrder)) {
      const drugOrder = drugOrderLookup[dose.drugOrder];
      if (drugOrder.id === undefined) {
        continue;
      }
      const doseVal = drugOrder.doseIsCalculated ? drugOrder.doseBasis : drugOrder.minimumDose;
      const doseString = `${doseVal} ${drugOrder.doseUnit}`;
      cycleDoseDays[dose.cycle].set(dose.drugOrder, {
        id: drugOrder.id,
        drug: getDrugName(drugOrder),
        dose: `${doseString}`,
        route: drugOrder.route,
        days: [],
      });
    }
    cycleDoseDays[dose.cycle].get(dose.drugOrder)?.days.push(dose.day + 1);
  }

  interface FinalAggregateCycles {
    cycles: string;
    doseSummaries: SummaryDose[];
  }

  const finalCycles: FinalAggregateCycles[] = [];

  if (protocol.continuous) {
    finalCycles.push({
      cycles: 'Cycle 1 and further cycles',
      doseSummaries: [...cycleDoseDays[0].values()],
    });
  } else {
    // stringify a summary of a cycle for easy duplication checking
    interface DedupeCycle {
      cycles: number[];
      doses: Map<string, SummaryDose>;
    }

    const dedupedCycles = new Map<string, DedupeCycle>();
    for (const cycle of Object.keys(cycleDoseDays)) {
      let tag = '';
      //@ts-ignore
      for (const dose of cycleDoseDays[cycle].values()) {
        tag += `${dose.id}:${dose.days};`;
      }
      if (!dedupedCycles.has(tag)) {
        //@ts-ignore
        dedupedCycles.set(tag, { cycles: [], doses: cycleDoseDays[cycle] });
      }
      dedupedCycles.get(tag)?.cycles.push(parseInt(cycle) + 1);
    }

    // make nice strings for the cycle
    for (const agCycles of dedupedCycles.values()) {
      const cycles = agCycles.cycles;
      const ranges: { first: number; last: number }[] = [];
      const range = { first: cycles[0], last: cycles[0] };
      for (let iii = 1; iii < cycles.length; iii += 1) {
        if (cycles[iii] === cycles[iii - 1] + 1) {
          // if consecutive, expand the range
          range.last += 1;
        } else {
          // otherwise push the current range and start over
          ranges.push(range);
          range.first = range.last = cycles[iii];
        }
      }
      ranges.push(range);
      const output = ranges
        .map((range) => (range.first === range.last ? `${range.first}` : `${range.first}-${range.last}`))
        .join(', ');

      finalCycles.push({
        cycles: (cycles.length > 1 ? 'Cycles ' : 'Cycle ') + output,
        doseSummaries: [...agCycles.doses.values()],
      });
    }
  }

  const evidenceString = `Evidence ID: ${protocol.evidenceId} ${
    protocol.evidenceVersion ? `v.${protocol.evidenceVersion}` : ''
  }`;

  return (
    <ProtocolSummaryTableContainer>
      <ProtocolSummaryTableInfo>
        <div data-cy="cycle frequency">{`Frequency: ${protocol.daysPerCycle} days`}</div>
        <div data-cy="cycle count">{`Cycles: ${protocol.continuous ? 'continuous' : protocol.numberOfCycles}`}</div>
      </ProtocolSummaryTableInfo>
      {finalCycles.map((aggregate, idx) => (
        <React.Fragment key={idx}>
          <p data-cy="cycle numbers">{`${aggregate.cycles}`}</p>
          <StyledProtocolSummaryTable data-cy={`${aggregate.cycles} table`}>
            <thead>
              <tr>
                <td>Drug</td>
                <td className="data">Dose</td>
                <td className="data">Route</td>
                <td className="data">Days</td>
              </tr>
            </thead>

            <tbody>
              {Object.values(aggregate.doseSummaries).map((drugOrder) => (
                <tr key={drugOrder.id}>
                  <td>{drugOrder.drug}</td>
                  <td data-cy="dose">{drugOrder.dose}</td>
                  <td>{drugOrder.route}</td>
                  <td data-cy="days">{drugOrder.days.join(', ')}</td>
                </tr>
              ))}
            </tbody>
          </StyledProtocolSummaryTable>
        </React.Fragment>
      ))}
      {protocol.evidenceLink ? (
        <a href={protocol.evidenceLink} target="_blank" rel="noreferrer">
          {evidenceString} <OpenInNew fontSize="small" />
        </a>
      ) : (
        <>{evidenceString}</>
      )}
    </ProtocolSummaryTableContainer>
  );
};

export default ProtocolSummaryTable;
