// eslint-disable-next-line no-use-before-define
import React from 'react';
import { INFO } from './constants';
import './DTInformation.scss';

const DTInformation = () => {
  const componentId = 'dt-info';
  const componentContainerId = `${componentId}-container`;
  const componentHeaderId = `${componentContainerId}-header`;
  const componentContentId = `${componentContainerId}-content`;

  return (
    <div id={componentContainerId}>
      <div id={`${componentHeaderId}`} />
      <div id={`${componentContentId}`}>
        <p className={`${componentContentId}-title`}>{INFO.TITLE}</p>
        <div className={`${componentContentId}-body`}>
          <p>{INFO.PARA_1}</p>
          <p>{INFO.PARA_2}</p>
          <p>{INFO.PARA_3}</p>
        </div>
      </div>
    </div>
  );
};

export default DTInformation;
