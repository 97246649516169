// eslint-disable-next-line no-use-before-define
import React from 'react';
import { FieldProps, getIn } from 'formik';
import { Input, HelperMessage, UserIcon } from 'shared-components/components';
import StyledFieldRow from './StyledFieldRow';

interface Props extends FieldProps {
  label: string;
  units?: string;
  multiline?: boolean;
  helperType?: string;
  width?: string;
  rows?: number;
  handleMutation: (name: string, value: string) => void;
  type: string;
  valueToBlank?: number | string;
  lastUpdateUser?: string;
  placeHolder?: string;
  autoComplete?: string;
  onFocus: any;
  maxLength?: number;
  height?: number;
}

const InputField = ({
  field,
  form,
  multiline = false,
  helperType,
  units,
  width,
  rows,
  handleMutation,
  type,
  valueToBlank,
  placeHolder,
  lastUpdateUser,
  maxLength,
}: Props): JSX.Element => {
  const { onBlur, name, value, onChange } = field;
  const { errors, touched, submitCount } = form;
  const fieldError = getIn(errors, name);
  const fieldTouched = getIn(touched, name);
  const fieldIsNotNull = value !== null;
  const isFieldInvalid = (submitCount > 0 || fieldIsNotNull || fieldTouched) && fieldError;

  const handleBlur = async (event: React.ChangeEvent<any>): Promise<any> => {
    onBlur(event);
    const value = valueToBlank && event.target.value === '' ? valueToBlank : event.target.value;
    handleMutation(name, value);
  };

  return (
    <>
      <StyledFieldRow>
        <Input
          name={name}
          value={value || undefined}
          invalid={isFieldInvalid}
          onChange={onChange}
          onFocus={(event) => {
            event.target.setAttribute('autocomplete', 'off');
          }}
          onBlur={(e: any): Promise<any> => handleBlur(e)}
          width={width}
          rows={rows}
          multiline={multiline}
          units={units}
          type={type}
          placeHolder={placeHolder}
          maxLength={maxLength}
        />
        {lastUpdateUser && <UserIcon userType={lastUpdateUser} />}
      </StyledFieldRow>
      {isFieldInvalid && <HelperMessage fieldName={name} helperType={helperType} fieldText={fieldError} />}
    </>
  );
};

export default InputField;
