import React from 'react';
import { ROAutocomplete, ROTextField, ROToggleButtons } from 'shared-components/components/FormFields';
import { BolusFormProps } from '.';
import { setYesNo } from '../../Common';
import { getOptionByValue } from '../../DiagnosisPages/Utils';
import { SelectOptionType } from 'shared-components/components/FormFields';
import { Typography, useTheme } from '@mui/material';

export const validationV1 = [
  {
    parent: 'bolus',
    expansionValue: 'true',
    children: ['bolusLocation', 'bolusThickness'],
  },
];

const SimulationBolusFormV1 = ({
  options,
  updateSimulation,
  fieldsId,
  siteGroup,
  SIM_NAMES,
  listValuesLookup,
  setTextFields,
  isFieldValid,
  bolusLocationValues,
  bolusThicknessCustomValues,
}: BolusFormProps) => {
  const { bolus, bolusLocation, bolusThickness, bolusThicknessCustom } = siteGroup;
  const theme = useTheme();
  return (
    <>
      <ROToggleButtons
        id={SIM_NAMES.BOLUS}
        fieldlabel="Bolus"
        options={options.bolus}
        value={setYesNo(bolus.value)}
        handleChange={(value): void => {
          const bolusBool = value === 'yes' ? true : false;
          updateSimulation({
            variables: {
              siteGroupId: fieldsId,
              bolus: bolusBool,
              bolusLocation: '',
              bolusThickness: '',
              bolusThicknessCustom: '',
            },
          });
        }}
      />

      {bolus?.value === 'true' && (
        <>
          <ROTextField
            id={SIM_NAMES.BOLUS_LOCATION}
            fieldlabel={'Bolus Location'}
            value={listValuesLookup(bolusLocationValues, fieldsId, bolusLocation ? bolusLocation.value : '')}
            onChange={(event: React.ChangeEvent<HTMLInputElement>): void => {
              setTextFields(SIM_NAMES.BOLUS_LOCATION, fieldsId, bolusLocationValues, event.target.value);
            }}
            error={!isFieldValid(bolusLocation)}
            onBlur={(): void => {
              const valueSet = bolusLocationValues.find((x: any) => x.id === fieldsId);
              if (valueSet) {
                updateSimulation({ variables: { siteGroupId: fieldsId, bolusLocation: valueSet.value } });
              }
            }}
            required
          />
          <ROAutocomplete
            id={SIM_NAMES.BOLUS_THICKNESS}
            fieldlabel="Bolus Thickness"
            options={options.bolusThickness.map((data: any) => ({ label: data.value, value: data.value }))}
            value={getOptionByValue(
              options.bolusThickness.map((data: any) => ({ label: data.label, value: data.value })),
              bolusThickness?.value || '',
            )}
            required
            inputProps={{
              error: !isFieldValid(bolusThickness),
            }}
            onChange={(option: SelectOptionType | string): void => {
              const value = typeof option === 'string' ? option : option?.value;
              updateSimulation({
                variables: { siteGroupId: fieldsId, bolusThickness: value, bolusThicknessCustom: '' },
              });
            }}
          />
          {bolusThickness?.value == 'Custom' && (
            <ROTextField
              id={SIM_NAMES.BOLUS_THICKNESS_CUSTOM}
              label={true}
              value={listValuesLookup(bolusThicknessCustomValues, fieldsId, bolusThicknessCustom?.value || '')}
              onChange={(event: React.ChangeEvent<HTMLInputElement>): void => {
                setTextFields(
                  SIM_NAMES.BOLUS_THICKNESS_CUSTOM,
                  fieldsId,
                  bolusThicknessCustomValues,
                  event.target.value,
                );
              }}
              onBlur={(): void => {
                const valueSet = bolusThicknessCustomValues.find((x: any) => x.id === fieldsId);
                if (valueSet) {
                  updateSimulation({ variables: { siteGroupId: fieldsId, bolusThicknessCustom: valueSet.value } });
                }
              }}
            />
          )}
        </>
      )}
    </>
  );
};
export default SimulationBolusFormV1;
