// eslint-disable-next-line no-use-before-define
import React from 'react';
import { StandardDialog } from 'shared-components/components';
import { Typography } from '@mui/material';

interface Props {
  title: string;
  text: string;
  isOpen: boolean;
  extraClass?: string;
  isButton?: boolean;
  buttonName?: string | undefined;
  buttonFunction?: (() => void) | undefined;
  dismissFunction: () => void;
}

const ModalInfo = (props: Props) => {
  const { title, isOpen, text, dismissFunction, isButton, buttonName, buttonFunction } = props;
  const showButton = isButton && buttonName && buttonFunction;
  return (
    <StandardDialog
      id="modal-info"
      open={isOpen}
      onClose={dismissFunction}
      title={title}
      onSubmit={showButton ? buttonFunction : undefined}
      submitText={showButton ? buttonName : undefined}
      maxWidth="sm">
      <Typography paddingY={2}>{text}</Typography>
    </StandardDialog>
  );
};

export default ModalInfo;
