import React, { Fragment } from 'react';

import { SummaryCard, SummaryRow } from 'op-components';
import { AccordionBooleanData, AccordionStringData } from 'op-pages/OP/HealthAssessment/HASharedComponents';
import { renderArrow } from 'op-pages/OP/HealthAssessment/HASharedComponents/HAModalFields';
import { Edit, Success } from 'shared-components/images';

import { useQuery } from '@apollo/client';
import { HA_ILLNESS_QUERY } from 'op-pages/OP/HealthAssessment/HASharedComponents/queries';
import { HASubComponentProps } from 'op-pages/OP/HealthAssessment/HASharedComponents/types';
import { displayRawDate } from 'shared-components/utils';
import * as medicalIllnessConstants from '../../../HAMedicalHistoryIllnessUS/constants';
import { ListData } from 'shared-components/interfaces';
import { HAIllnessQueryResponse } from 'op-pages/OP/HealthAssessment/HASharedComponents/types/HAIllnessUSTypes';

const {
  PAST_MEDICAL_HISTORY,
  CARDIAC,
  CONSTITUTIONAL,
  EYES_EARS_NOSE_THROAT,
  GASTROINTESTINAL,
  GENITOURINARY,
  NEUROLOGICAL,
  PSYCHIATRIC,
  PULMONARY,
  BONESJOINTS,
  SKIN,
  SCREENING,
  HEMATOLOGIC,
} = medicalIllnessConstants;

const convertObjectToArray = (parsedListItem: Record<string, AccordionBooleanData | AccordionStringData>) => {
  const items: Array<AccordionBooleanData | AccordionStringData> = [];

  for (const itemKey in parsedListItem) {
    items.push(parsedListItem[itemKey]);
  }

  return items;
};

const getDetailText = (item: AccordionBooleanData | AccordionStringData, detailText?: string[]) => {
  if (!detailText) return '';

  if (detailText.includes(item.name) && item.value) {
    return `${item.value}`;
  }

  return '';
};

const parseListItemsWithMapping = (listItem: string, mapping: Record<string, string> | undefined) => {
  const parsedItems = JSON.parse(listItem) as Record<string, AccordionBooleanData | AccordionStringData>;
  const mappingKeys = Object.keys(mapping || {});
  const mappingValues = Object.values(mapping || {});
  if (!parsedItems) return;

  mappingKeys.forEach((key, index) => {
    const objToMutate = parsedItems[key];
    const keyMappedValue = mappingValues[index];
    if (objToMutate?.value) {
      objToMutate.value = parsedItems[keyMappedValue]?.value || 'Not Provided';
    }
    delete parsedItems[keyMappedValue];
  });

  return parsedItems;
};

const shouldRender = (itemsToRender: Array<AccordionBooleanData | AccordionStringData>): boolean => {
  const foundItemToRender = itemsToRender.find((item) => item.value);
  return !!foundItemToRender;
};

const mapDates = (
  dateObj: any,
  parsedMutatedItems: Record<string, AccordionBooleanData | AccordionStringData>,
): Record<string, AccordionBooleanData | AccordionStringData> => {
  if (parsedMutatedItems.covidVaccine?.value) {
    parsedMutatedItems.covidVaccine.value = dateObj.covid;
  }
  if (parsedMutatedItems.influenzaVaccine?.value) {
    parsedMutatedItems.influenzaVaccine.value = dateObj.influenza;
  }
  if (parsedMutatedItems.pneumoniaVaccine?.value) {
    parsedMutatedItems.pneumoniaVaccine.value = dateObj.pneumonia;
  }
  if (parsedMutatedItems.colonoscopy?.value) {
    parsedMutatedItems.colonoscopy.value = dateObj.colonoscopy;
  }
  if (parsedMutatedItems.chestCtScan?.value) {
    parsedMutatedItems.chestCtScan.value = dateObj.chestCtScan;
  }
  return parsedMutatedItems;
};

const renderMedicalHistoryList = (
  listItem: string,
  title: string,
  refData: ListData[],
  config?: {
    mapping?: Record<string, string>;
    detailTexts?: string[];
    screeningDates?: any;
  },
) => {
  if (!listItem) return <></>;

  let parsedMutatedItems = parseListItemsWithMapping(listItem, config?.mapping);

  if (!parsedMutatedItems) return <></>;

  parsedMutatedItems =
    title === medicalIllnessConstants.SCREENING.title
      ? mapDates(config?.screeningDates, parsedMutatedItems)
      : parsedMutatedItems;

  const itemsToRender = convertObjectToArray(parsedMutatedItems);

  if (!shouldRender(itemsToRender)) return <></>;

  return (
    <>
      <SummaryRow
        headingText={title}
        detailText={''}
        displayAsError={false}
        displayAsOptional={false}
        allowEmptyDetailText={true}
      />

      {renderArrow()}

      {refData.map((listDataItem) => {
        if (!parsedMutatedItems) return <></>;
        const item = parsedMutatedItems[`${listDataItem.key}`];
        if (!item?.value) return <></>;

        return (
          <SummaryRow
            key={item.id}
            headingText={item.name}
            detailText={getDetailText(item, config?.detailTexts)}
            displayAsError={false}
            displayAsOptional={false}
            subQuestion={true}
            allowEmptyDetailText={true}
          />
        );
      })}
    </>
  );
};

const MedicalHistoryIllness = (props: HASubComponentProps): JSX.Element => {
  const { patientId, isPso, navigate } = props;

  const { data, loading } = useQuery<HAIllnessQueryResponse>(HA_ILLNESS_QUERY, {
    variables: { patientId },
  });

  if (!data || loading) return <></>;
  const {
    screeningRefData,
    bonesJointsRefData,
    pastMedicalHistoryRefData,
    cardiacRefData,
    constitutionalRefData,
    eyesEarsNoseThroatRefData,
    gastrointestinalRefData,
    genitourinaryRefData,
    neurologicalRefData,
    psychiatricRefData,
    pulmonaryRefData,
    hematologicRefData,
    skinRefData,
  } = data;

  const {
    cardiac,
    constitutional,
    eyesEarsNoseThroat,
    gastrointestinal,
    genitourinary,
    neurological,
    psychiatric,
    pulmonary,
    screening,
    bonesJoints,
    pastMedicalHistory,
    hematologic,
    skin,

    colonoscopyRawDay,
    colonoscopyRawMonth,
    colonoscopyRawYear,
    chestCtScanRawDay,
    chestCtScanRawMonth,
    chestCtScanRawYear,
  } = data.healthAssessment;

  const screeningDates = {
    colonoscopy: displayRawDate(colonoscopyRawMonth, colonoscopyRawYear, colonoscopyRawDay),
    chestCtScan: displayRawDate(chestCtScanRawMonth, chestCtScanRawYear, chestCtScanRawDay),
  };

  const evaluateRender = (): boolean => {
    const evaluateJSONObject = (obj: any, codependents?: readonly any[]): boolean => {
      if (!obj) return false;
      const jsonParsedObj = JSON.parse(obj);
      const filteredJsonParsedObj = Object.entries(jsonParsedObj).filter((a: any) => a[1]?.value);
      const filteredJsonParsedObjKeys = filteredJsonParsedObj.map((a) => a[0]);
      let returnVal = filteredJsonParsedObj.length > 0;

      if (codependents) {
        const codependentsLength: number = codependents.length;

        for (let i = 0; i < codependentsLength; i++) {
          if (filteredJsonParsedObjKeys.includes(codependents[i][0])) {
            returnVal = filteredJsonParsedObjKeys.includes(codependents[i][1]);
          }
        }
      }
      return returnVal;
    };

    const evaluatedRender =
      evaluateJSONObject(screening) ||
      evaluateJSONObject(pastMedicalHistory, [['otherText', 'other']]) ||
      evaluateJSONObject(cardiac) ||
      evaluateJSONObject(constitutional, [
        ['weightGainCount', 'weightGain'],
        ['weightLossCount', 'weightLoss'],
      ]) ||
      evaluateJSONObject(eyesEarsNoseThroat) ||
      evaluateJSONObject(gastrointestinal) ||
      evaluateJSONObject(genitourinary) ||
      evaluateJSONObject(neurological) ||
      evaluateJSONObject(psychiatric) ||
      evaluateJSONObject(pulmonary) ||
      evaluateJSONObject(bonesJoints);

    return evaluatedRender;
  };

  return (
    <Fragment>
      <SummaryCard
        title={'Medical history 2'}
        primaryIcon={isPso ? <Fragment></Fragment> : <Edit className="primary-icon" />}
        secondaryIcon={<Success className="secondary-icon valid-icon" />}
        showEdit={!isPso}
        onClick={(): void => {
          if (!isPso) {
            navigate('history-illness');
          }
        }}>
        {evaluateRender() ? (
          <>
            {renderMedicalHistoryList(screening, SCREENING.title, screeningRefData, {
              screeningDates,
              detailTexts: ['Colonoscopy', 'Chest CT scan'],
            })}
            {renderMedicalHistoryList(pastMedicalHistory, PAST_MEDICAL_HISTORY.title, pastMedicalHistoryRefData, {
              mapping: { other: 'otherText' },
              detailTexts: ['Other'],
            })}

            {renderMedicalHistoryList(cardiac, CARDIAC.title, cardiacRefData)}

            {renderMedicalHistoryList(constitutional, CONSTITUTIONAL.title, constitutionalRefData, {
              mapping: { weightLoss: 'weightLossCount', weightGain: 'weightGainCount' },
              detailTexts: ['Unexplained weight loss', 'Weight gain'],
            })}

            {renderMedicalHistoryList(eyesEarsNoseThroat, EYES_EARS_NOSE_THROAT.title, eyesEarsNoseThroatRefData)}
            {renderMedicalHistoryList(gastrointestinal, GASTROINTESTINAL.title, gastrointestinalRefData)}
            {renderMedicalHistoryList(genitourinary, GENITOURINARY.title, genitourinaryRefData)}
            {renderMedicalHistoryList(neurological, NEUROLOGICAL.title, neurologicalRefData)}
            {renderMedicalHistoryList(psychiatric, PSYCHIATRIC.title, psychiatricRefData)}

            {renderMedicalHistoryList(pulmonary, PULMONARY.title, pulmonaryRefData)}
            {renderMedicalHistoryList(bonesJoints, BONESJOINTS.title, bonesJointsRefData)}
            {renderMedicalHistoryList(hematologic, HEMATOLOGIC.title, hematologicRefData)}
            {renderMedicalHistoryList(skin, SKIN.title, skinRefData)}
          </>
        ) : (
          <SummaryRow
            headingText="Not provided"
            detailText={null}
            fallbackText=" "
            displayAsError={false}
            displayAsOptional={false}
            allowEmptyDetailText={true}
          />
        )}
      </SummaryCard>
    </Fragment>
  );
};

export default MedicalHistoryIllness;
