import * as yup from 'yup';
import { valueRequired, positiveNumber, transportRequired } from './constants';

export const validationSchema = (patientState: string | undefined): any => {
  return yup.object({
    nurseLocation: yup.string().nullable().required(valueRequired),
    unintentionalWeightLoss: yup.bool().typeError(valueRequired),
    hasDecreasedAppetite: yup.bool().typeError(valueRequired),
    isAlertAndOrientated: yup.bool().typeError(valueRequired),
    hasAlteredCognitiveState: yup.bool().typeError(valueRequired),
    hasCurrentPain: yup.bool().typeError(valueRequired),
    painScore: yup.number().when('hasCurrentPain', {
      is: true,
      then: yup.number().integer().positive(positiveNumber).required(valueRequired).typeError(valueRequired),
      otherwise: yup.number().nullable(),
    }),
    isAbleToLieFlat: yup.bool().typeError(valueRequired),
    isAbleToClimbStairs: yup.bool().typeError(valueRequired),
    otherTransport: yup.string().when('transportMode', {
      is: (val: string) => val.length === 0,
      then: yup.string().required(transportRequired),
    }),
    socialConsiderations: yup.string().required(valueRequired),
    hasPatientCarerConcern: yup.bool().typeError(valueRequired),
    pelvicFitness: patientState && patientState === 'WA' ? yup.string().required(valueRequired) : yup.string(),
    hasHighRiskFactors: yup.bool().typeError(valueRequired),
    howFactorsWereIdentified: yup
      .string()
      .nullable()
      .when('hasHighRiskFactors', {
        is: true,
        then: yup.string().required(valueRequired),
      }),
  });
};
