import { gql } from '@apollo/client';

export const GET_PATIENT_PIN = gql`
  mutation getPin($patientId: ID!, $toForm: String) {
    getPin(id: $patientId, toForm: $toForm) {
      pin
    }
  }
`;

export const UPDATE_REGISTRATION_TYPE = gql`
  mutation UpdateRegistrationType($patientId: ID!, $registrationAccessType: String!) {
    updateRegistrationType(patientId: $patientId, registrationAccessType: $registrationAccessType) {
      profile {
        id
        registrationAccessType
      }
    }
  }
`;

export const SEND_HOME_REGO_INVITATION = gql`
  mutation SendHomeRegistrationInvitation(
    $patientId: ID!
    $toForm: String!
    $recipientEmail: String!
    $recipientPhone: String!
  ) {
    sendHomeRegistrationInvitation(
      patientId: $patientId
      toForm: $toForm
      recipientEmail: $recipientEmail
      recipientPhone: $recipientPhone
    ) {
      success
      inviteLink
      patient {
        id
        email
        primaryPhone
      }
    }
  }
`;
