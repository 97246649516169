import React, { PropsWithChildren } from 'react';
import { ROBase } from 'op-components';

const MOBase = ({ children }: PropsWithChildren) => {
  return (
    <>
      <ROBase>{children}</ROBase>
    </>
  );
};

export default MOBase;
