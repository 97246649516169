// eslint-disable-next-line no-use-before-define
import classNames from 'classnames';
import React from 'react';
import { Stack } from '@mui/material';

import './SummaryCard.scss';

interface Props {
  title: string;
  primaryIcon: JSX.Element;
  secondaryIcon?: JSX.Element;
  id?: string;
  onClick?: () => void;
  invalidContent?: boolean;
  showEdit?: boolean;
  children: React.ReactNode;
}

const SummaryCard = (props: Props) => {
  const { title, primaryIcon, secondaryIcon, children, invalidContent, id, showEdit, onClick } = props;

  return (
    <div
      data-test-id={`summary-card-${title}`}
      className={classNames('summary-card', { 'validation-error': invalidContent })}>
      <div className="summary-heading">
        <div id={id} className="summary-title">
          {title}
        </div>
        <Stack className="summary-actions" direction="row" alignItems="center" sx={{ cursor: 'pointer' }}>
          <span role="button" onClick={onClick}>
            {primaryIcon}
          </span>
          {showEdit && (
            <span className="summary-edit" role="button" onClick={onClick}>
              Edit
            </span>
          )}
          <span>{secondaryIcon}</span>
        </Stack>
      </div>
      <div className="summary-content">{children}</div>
    </div>
  );
};

export default SummaryCard;
