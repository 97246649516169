import { gql } from '@apollo/client';

export const CREATE_PATIENT = gql`
  mutation CreatePatient {
    createPatient {
      patient {
        id
      }
    }
  }
`;

// TODO - does this need to be removed?
export const SET_USER_LANGUAGE = gql`
  mutation translation($lang: String!) {
    changeLanguage(language: $lang) {
      newLanguage {
        language
      }
      languageChanged
    }
  }
`;

export const GET_UNSUBMITTED_COUNT = gql`
  query GetUnsubmittedCount {
    getUnsubmittedCount {
      id
      drafts
      unsubmitted
    }
  }
`;
