// eslint-disable-next-line no-use-before-define
import React, { Component, Fragment } from 'react';
import { RouteComponentProps, withRouter } from 'react-router-dom';
import { OnboardingPopover } from 'shared-components/components';

import './Stepper.scss';

import { OnboardingPopoverPageLogData } from 'shared-components/interfaces/PageLogData';
import chemotherapyIcon from '../../images/chemotherapy-icon.png';
import radiationTherapyIcon from '../../images/radiation-therapy-icon.png';
import StepperButton from './StepperButton/StepperButton';

interface Links {
  linkText: string;
  href: string;
}

// ----------- THIS IS AN OLD COMPONENT DO NOT USE ------------------

// Below is used elsewhere, however not failing, therefore ignoring this next line.
// eslint-disable-next-line
interface Props extends RouteComponentProps {
  basePath: string;
  links: Links[];
  onStepperButtonClick?: () => void;
  visitedLinks?: { [key: string]: number };
  headerTitle?: string;
  pageCategory?: string;
  disabled?: boolean;
  popoverOpen?: boolean;
  closePopup?: () => void;
  closeAndAckPopup?: () => void;
  logPopoverData?: any;
}
class Stepper extends Component<Props> {
  referrer: React.RefObject<HTMLDivElement>;
  constructor(props: Props) {
    super(props);
    this.referrer = React.createRef();
  }
  private closeOnboardingPopover = () => {
    this.props.closeAndAckPopup && this.props.closeAndAckPopup();
  };
  private continueOnboarding = () => {
    this.props.closePopup && this.props.closePopup();
  };

  public render(): JSX.Element {
    const { basePath, links, onStepperButtonClick, visitedLinks, headerTitle, disabled, popoverOpen } = this.props;

    return (
      <div id="stepper">
        {headerTitle && (
          <div className="stepper-header-container">
            <img
              src={headerTitle === 'Chemotherapy' ? chemotherapyIcon : radiationTherapyIcon}
              alt={`${headerTitle}-icon`}
              id="stepper-icon"
            />
            <h1 id="stepper-heading">{headerTitle}</h1>
          </div>
        )}
        {links.map((link: Links, index: number): JSX.Element => {
          const linkWithId = `${basePath}${link.href}`;
          const linkDisplayNumber = index + 1;
          return (
            <Fragment key={`stepper-item-${index}`}>
              {link.linkText === 'Referrers' ? (
                <OnboardingPopover
                  handleClose={() => this.closeOnboardingPopover()}
                  handleVisitLink={() => {}}
                  popoverOpen={!!popoverOpen}
                  target={this.referrer.current}
                  feature="GP Referrer"
                  handleNext={() => this.continueOnboarding()}
                  step="1"
                  logEvent={(pageData: OnboardingPopoverPageLogData) =>
                    this.props.logPopoverData &&
                    this.props.logPopoverData({ variables: { data: JSON.stringify(pageData) } })
                  }>
                  <div ref={this.referrer}>
                    <StepperButton
                      id={'stepper-item-referrers'}
                      linkText={link.linkText}
                      linkNumber={linkDisplayNumber}
                      linkHref={linkWithId}
                      isActive={!!popoverOpen ? true : this.isLinkActive(link.href)}
                      visited={visitedLinks !== undefined ? this.hasLinkBeenVisited(link.href) : undefined}
                      onStepperButtonClick={onStepperButtonClick}
                      disabled={disabled}
                    />
                  </div>
                </OnboardingPopover>
              ) : (
                <StepperButton
                  linkText={link.linkText}
                  linkNumber={linkDisplayNumber}
                  linkHref={linkWithId}
                  isActive={this.isLinkActive(link.href)}
                  visited={visitedLinks !== undefined ? this.hasLinkBeenVisited(link.href) : undefined}
                  onStepperButtonClick={onStepperButtonClick}
                  disabled={disabled}
                />
              )}
            </Fragment>
          );
        })}
      </div>
    );
  }

  private isLinkActive = (link: string): boolean => {
    const {
      basePath,
      location: { pathname },
    } = this.props;

    // Need to check that it also includes registration to make sure that the url is in the right location.
    return pathname.includes(link) && pathname.includes(basePath);
  };

  private hasLinkBeenVisited = (link: string): boolean => {
    const { visitedLinks } = this.props;

    // Ensure you strip the '/' from it
    link = link.replace(/\//, '');

    if (visitedLinks) {
      return Object.keys(visitedLinks).includes(link);
    }

    return false;
  };
}

export default withRouter(Stepper);
