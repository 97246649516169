import React, { Component, Fragment, FocusEvent } from 'react';
import classNames from 'classnames';

import './FreeTextArea.scss';

import { ErrorInfo } from 'shared-components/components/FormFields';

interface Props {
  defaultValue?: string;
  disabled?: boolean;
  displayInputError?: boolean;
  elementRef?: (ref: HTMLTextAreaElement | null) => void;
  errors?: string[];
  inputName: string;
  maxLength?: number;
  placeholder?: string | null;
  title?: string | null;
  errorMessages?: { [key: string]: string };
  onBlur?: (event: FocusEvent<HTMLTextAreaElement>) => void;
}

class FreeTextArea extends Component<Props> {
  public static defaultProps = {
    displayInputError: false,
  };

  public render(): JSX.Element {
    const {
      defaultValue,
      disabled,
      displayInputError,
      elementRef,
      errors,
      inputName,
      maxLength,
      placeholder,
      title,
      errorMessages,
      onBlur,
    } = this.props;

    const inputError = (errors && errors.length > 0) || displayInputError;

    return (
      <Fragment>
        {title && <div className="text-area-title">{title}</div>}
        <textarea
          name={inputName}
          className={classNames('form-fields-free-text-area', { 'validation-error': inputError })}
          defaultValue={defaultValue}
          placeholder={placeholder || ''}
          maxLength={maxLength}
          ref={elementRef}
          disabled={disabled}
          onBlur={onBlur}
          style={{ font: 'unset' }}
        />
        <ErrorInfo errors={errors} errorMessages={errorMessages} />
      </Fragment>
    );
  }
}

export default FreeTextArea;
