import { DiagnosisType, DiagnosisTypeWithoutNulls, DEFAULT_TNM_STAGE_TYPE } from './Interface';
import { Region } from 'shared-components/enums';
import { CurrentAppConfig } from '../AppConfig';
import { SelectOptionType } from 'shared-components/components/FormFields';
const REACT_APP_REGION = import.meta.env.REACT_APP_REGION;

const region = REACT_APP_REGION;

export const convertDiagnosisTypeToWithoutNulls = (diagnosis: DiagnosisType): DiagnosisTypeWithoutNulls => {
  const diagnosisWithoutNulls: DiagnosisTypeWithoutNulls = {
    stageTCodePathological: diagnosis?.stageTCodePathological
      ? diagnosis.stageTCodePathological
      : DEFAULT_TNM_STAGE_TYPE,
    stageNCodePathological: diagnosis?.stageNCodePathological
      ? diagnosis.stageNCodePathological
      : DEFAULT_TNM_STAGE_TYPE,
    stageMCodePathological: diagnosis?.stageMCodePathological
      ? diagnosis.stageMCodePathological
      : DEFAULT_TNM_STAGE_TYPE,
    stageTCodeClinical: diagnosis.stageTCodeClinical ? diagnosis.stageTCodeClinical : DEFAULT_TNM_STAGE_TYPE,
    stageNCodeClinical: diagnosis.stageNCodeClinical ? diagnosis.stageNCodeClinical : DEFAULT_TNM_STAGE_TYPE,
    stageMCodeClinical: diagnosis.stageMCodeClinical ? diagnosis.stageMCodeClinical : DEFAULT_TNM_STAGE_TYPE,
    id: diagnosis.id,
    stageType: diagnosis.stageType,
    otherInfo: diagnosis.otherInfo,
    laterality: diagnosis.laterality,
    diagnosisDate: diagnosis.diagnosisDate,
    morphology: diagnosis.morphology ? diagnosis.morphology : { displayName: '' },
    diagnosisCode: diagnosis.diagnosisCode
      ? diagnosis.diagnosisCode
      : {
          id: '',
          diagnosisCode: '',
          diagnosisDescription: '',
          stageNumber: '',
          tumourStream: {
            name: '',
          },
        },
    isPrimaryDiagnosis: diagnosis.isPrimaryDiagnosis !== null ? diagnosis.isPrimaryDiagnosis : true,
    relatedPrimaryDiagnosis: diagnosis.relatedPrimaryDiagnosis,
  };
  return diagnosisWithoutNulls;
};

export const getOptionByValue = (
  options: SelectOptionType[],
  value: string | null | undefined,
): SelectOptionType | undefined | null => {
  const option = options.find((option: SelectOptionType): boolean => {
    return option.value === value?.toString();
  });
  if (option) return option;
  if (value && value !== '') {
    return {
      label: value,
      value: value,
    };
  }
  return null;
};

export const codeToIntakePageMapping = (code: string, lookup: Record<string, string>): string => {
  if (CurrentAppConfig.Intake.disabled) {
    return '';
  }
  // TODO: NOTE: C34.2 is to pass AU cypress tests
  if (region === Region.AU && code === 'C34.2') {
    return 'Lung';
  }
  const intakePage = code in lookup ? lookup[code] : '';
  // Temporary Prostate (Urogenital) intake page bypass for UK environments (EVE-5519)
  if (intakePage === 'Urogenital' && CurrentAppConfig.Intake.isProstateDisabled) {
    return '';
  }
  return intakePage;
};
