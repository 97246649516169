import { SelectField, TextAreaField } from 'shared-components/components/FormikComponents';

export const FIELDS = {
  FORM_HEADING: {
    NAME: 'formHeading',
    TITLE: 'Emergency Contact',
  },
  FIRST_NAME: {
    NAME: 'firstName',
    TITLE: 'First name*',
    PLACE_HOLDER: 'Enter first name',
    COMPONENT: TextAreaField,
  },
  LAST_NAME: {
    NAME: 'lastName',
    TITLE: 'Last name*',
    PLACE_HOLDER: 'Enter last name',
    COMPONENT: TextAreaField,
  },
  RELATIONSHIP: {
    NAME: 'relationship',
    TITLE: 'Relationship*',
    PLACE_HOLDER: 'Select from drop down',
    COMPONENT: SelectField,
  },
  GUARDIAN: {
    NAME: 'guardian',
    TITLE: 'Guardian',
    PLACE_HOLDER: '',
    COMPONENT: TextAreaField,
  },
  GUARDIAN_RELATIONSHIP: {
    NAME: 'guardianRelationship',
    TITLE: 'Guardian Relationship',
    PLACE_HOLDER: 'Select from drop down',
    COMPONENT: SelectField,
  },
  MOBILE: {
    NAME: 'mobilePhoneNumber',
    TITLE: 'Mobile*',
    PLACE_HOLDER: 'Enter at least one phone number',
    COMPONENT: TextAreaField,
  },
  HOME_PHONE: {
    NAME: 'homePhoneNumber',
    TITLE: 'Landline*',
    PLACE_HOLDER: 'Enter at least one phone number',
    COMPONENT: TextAreaField,
  },
};
