import { useMutation, useQuery } from '@apollo/client';
import React from 'react';
import { useParams } from 'react-router-dom';
import { ListData } from 'shared-components/interfaces';
import { OPUser } from 'op-interfaces';
import { FIELDS } from './constants';
import { MEDICARE_DVA_FIELD_MAPPINGS, DVA_FIELD_MAPPINGS } from '../Helper';
import { registrationPath } from '../Helper';
import { REGISTRATION_INSURANCE_QUERY, UPDATE_REGISTRATION_INSURANCE } from './InsuranceQueries';
import InsuranceForm from './InsuranceForm';
import { FormProps, PatientInsuranceOtherBenefit } from '../interfaces';

const Insurance = (props: FormProps): JSX.Element => {
  const { exitForm } = props;
  const { patientId } = useParams<{ patientId: string }>();

  const [updateRegistrationInsurance] = useMutation(UPDATE_REGISTRATION_INSURANCE);

  const { data, error, loading } = useQuery<{
    patient: PatientInsuranceOtherBenefit;
    dvaTypeRefData: ListData[];
    medicareTypeRefData: ListData[];
    concessionCardTypeRefData: ListData[];
    user: OPUser;
  }>(REGISTRATION_INSURANCE_QUERY, {
    variables: { id: patientId },
  });

  const deleteDvaData = () => {
    const fields = {
      healthDvaNumber: '',
      healthDvaType: '',
      dvaExpiryRawMonth: '',
      dvaExpiryRawYear: '',
      healthMedicareDvaOption: 'Medicare',
    };
    return updateRegistrationInsurance({ variables: { id: patientId, ...fields } });
  };

  const deleteMedicareAndDvaData = () => {
    const fields = {
      healthDvaNumber: '',
      healthDvaType: '',
      dvaExpiryRawMonth: '',
      dvaExpiryRawYear: '',
      healthMedicareNumber: '',
      healthMedicareIrn: '',
      medicareExpiryRawYear: '',
      medicareExpiryRawMonth: '',
      healthMedicareDvaOption: 'None',
    };
    return updateRegistrationInsurance({ variables: { id: patientId, ...fields } });
  };

  const deletePensionData = () => {
    const fields = {
      healthPensionCardNumber: '',
      healthPensionCardType: '',
      healthPensionCard: false,
      healthPensionCardStart: '',
      healthPensionCardExpiry: '',
    };
    return updateRegistrationInsurance({ variables: { id: patientId, ...fields } });
  };

  const updateField = (field: string, value: string | boolean | number) => {
    if (field === FIELDS.MEDICARE_OR_DVA.NAME && value === 'Medicare') return deleteDvaData();
    if (field === FIELDS.MEDICARE_OR_DVA.NAME && value === 'None') return deleteMedicareAndDvaData();
    if (field === FIELDS.HAS_PENSION_CARD.NAME && value === false) return deletePensionData();
    return updateRegistrationInsurance({ variables: { id: patientId, [field]: value } });
  };

  if (loading || !data || error) return <></>;

  const { patient, medicareTypeRefData, dvaTypeRefData, concessionCardTypeRefData, user } = data;

  const patientClone = { ...patient };
  const healthMedicareDvaOption = patient?.healthMedicareDvaOption || '';
  // Mapping of old medicare dva values to new values if needed
  if (Object.keys(MEDICARE_DVA_FIELD_MAPPINGS).includes(healthMedicareDvaOption)) {
    patientClone.healthMedicareDvaOption =
      MEDICARE_DVA_FIELD_MAPPINGS[healthMedicareDvaOption as keyof typeof MEDICARE_DVA_FIELD_MAPPINGS];
  }

  // Mapping of old dva values to new values if needed
  const dvaOption = patient?.healthDvaType || '';
  if (Object.keys(DVA_FIELD_MAPPINGS).includes(dvaOption)) {
    patientClone.healthDvaType = DVA_FIELD_MAPPINGS[dvaOption as keyof typeof DVA_FIELD_MAPPINGS];
  }

  return (
    <InsuranceForm
      refData={{
        medicareTypeRefData,
        dvaTypeRefData,
        concessionCardTypeRefData,
      }}
      patient={patientClone}
      updateField={updateField}
      handleShowSaveExitConfirm={() => exitForm(true)}
      previousPageLink={registrationPath(patient.id, 'referrers')}
      isPso={!!user.isPso}
    />
  );
};

export default Insurance;
