// eslint-disable-next-line no-use-before-define
import React, { Component, Fragment, useEffect } from 'react';
import { gql } from '@apollo/client';
import { RouteComponentProps, withRouter } from 'react-router-dom';
import { withApollo, WithApolloClient } from '@apollo/client/react/hoc';
import { Query } from '@apollo/client/react/components';
import CovidPSOReview from './CovidPSOReview';
import { MandatoryPatient, OPUser } from 'op-interfaces';
import { LoadingSpinner } from 'shared-components/components';
import { CovidAssessmentItem } from 'op-classes';
import { useErrorModalContext } from 'op-contexts';

export const PATIENT_AND_USER_DETAILS_QUERY = gql`
  query PatientAndUserDetails($id: ID!) {
    patient(id: $id) {
      id
      address {
        id
        formattedAddress
      }
      firstName
      middleName
      lastName
      ida
      idb
      gender
      dob
    }
    user {
      id
      isPso
    }
    covidScreening(id: $id) {
      id
      hasSymptoms
      dateOfFeverSymptoms
      hasBeenDiagnosed
      hasBeenTested
      advisedToQuarantine
      hadContact
      travelledToHotspot
      status
    }
  }
`;

interface CovidAssessmentQueryData {
  covidScreening: {
    id: string;
  };
}
interface Props extends WithApolloClient<{}>, RouteComponentProps<{ patientId: string }> {}

class PatientFormSummaryApollo extends Component<Props> {
  public render(): JSX.Element {
    const {
      match: {
        params: { patientId },
      },
    } = this.props;

    return (
      <Query<{ patient: MandatoryPatient; user: OPUser; covidScreening: CovidAssessmentQueryData }>
        query={PATIENT_AND_USER_DETAILS_QUERY}
        variables={{ id: patientId }}
        fetchPolicy="network-only">
        {({ loading, data, error }): JSX.Element => {
          const { setError } = useErrorModalContext();
          useEffect(() => {
            if (error) return setError();
          }, [error]);

          if (loading) {
            return <LoadingSpinner relativeSpinner={true} />;
          }
          if (data && data.patient && data.user && data.covidScreening) {
            const covidScreening = new CovidAssessmentItem();
            covidScreening.loadData(data.covidScreening);
            return (
              <Fragment>
                <CovidPSOReview patient={data.patient} isPso={data.user.isPso} covidScreening={covidScreening} />
              </Fragment>
            );
          }
          return <div></div>;
        }}
      </Query>
    );
  }
}
export default withRouter(PatientFormSummaryApollo);
