// eslint-disable-next-line no-use-before-define
import { CAREPLAN_PAGE } from './Constants';
import React from 'react';
import { withRouter } from 'react-router';
import { Switch, Route } from 'react-router-dom';
import ROPatientDiagnosisPage from 'op-pages/RO/Careplan/DiagnosisPages/DiagnosisPage';
import { MOPatientTreatmentInfoPage } from 'op-pages';
import { MOPrescriptionPage } from 'op-pages';
import { MOSubmissionPage } from 'op-pages';
import ProtocolSelectionPage from 'op-pages/MO/Careplan/ProtocolSelectionPage/ProtocolSelectionPage';

const MOPatientCarePlanPageRouter = () => {
  return (
    <Switch>
      <Route
        path={`/:oncologyType/patient/:id/careplan/:careplanId/${CAREPLAN_PAGE.TREATMENT_INFO}`}
        component={MOPatientTreatmentInfoPage}
      />
      <Route
        path={`/:oncologyType/patient/:id/careplan/:careplanId/${CAREPLAN_PAGE.PROTOCOL_SELECTION}`}
        component={ProtocolSelectionPage}
      />
      <Route
        path={`/:oncologyType/patient/:id/careplan/:careplanId/${CAREPLAN_PAGE.PRESCRIPTION}/:cycleId`}
        component={MOPrescriptionPage}
      />
      <Route
        path={`/:oncologyType/patient/:id/careplan/:careplanId/${CAREPLAN_PAGE.SUBMISSION}`}
        component={MOSubmissionPage}
      />
      <Route path="/:oncologyType/patient/:id/careplan/:careplanId/" component={ROPatientDiagnosisPage} />
    </Switch>
  );
};

const routedComponent = withRouter(MOPatientCarePlanPageRouter);
export default routedComponent;
