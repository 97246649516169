// eslint-disable-next-line no-use-before-define
import React, { Component, useEffect } from 'react';
import { Query } from '@apollo/client/react/components';
import { WithApolloClient } from '@apollo/client/react/hoc';

import { RouteComponentProps, withRouter } from 'react-router-dom';

import { HACancerItem } from 'op-classes';
import { HAMutationType } from 'op-enums';
import { ApolloSaveStatus } from 'op-types';
import { LoadingSpinner } from 'shared-components/components';
import { Dictionary, ListData } from 'shared-components/interfaces';
import { Logger } from 'shared-components/utils';

import { useErrorModalContext } from 'op-contexts';
import { HA_MEDICAL_HISTORY_CANCER_QUERY } from 'op-pages/OP/HealthAssessment/HASharedComponents/queries';
import withHealthAssessment, { WithHealthAssessment } from '../../HealthAssessment';
import HAMedicalHistoryCancer from './HAMedicalHistoryCancer';
import { NetworkStatus } from '@apollo/client';

const logger = new Logger('HAMedicalHistoryCancerApollo');

export interface MedicalHistoryOtherQueryData {
  healthAssessment: HACancerItem;
  treatmentTypeRefData: ListData[];
  treatmentStageRefData: ListData[];
  familyMemberRefData: ListData[];
}

interface Props extends WithApolloClient<{}>, WithHealthAssessment, RouteComponentProps<{ patientId: string }> {
  client: any;
  updateSaveStatus: ApolloSaveStatus;
  validateOnLoad: boolean;
}

class HAMedicalHistoryCancerApollo extends Component<Props> {
  public constructor(props: Props) {
    super(props);

    this.state = {
      selectedCancer: undefined,
    };
  }

  public render(): JSX.Element {
    const {
      match: {
        params: { patientId },
      },
      validateOnLoad,
    } = this.props;

    return (
      <Query<MedicalHistoryOtherQueryData> query={HA_MEDICAL_HISTORY_CANCER_QUERY} variables={{ patientId }}>
        {({ error, loading, data, refetch, networkStatus }): JSX.Element => {
          const { setError } = useErrorModalContext();
          useEffect(() => {
            if (error) return setError();
          }, [error]);

          // Don't rerender whole component on refetch, otherwise will flash loading and scroll to top
          if (loading && networkStatus !== NetworkStatus.refetch) return <LoadingSpinner relativeSpinner={true} />;

          if (data && data.healthAssessment) {
            const haMedicalCancer = new HACancerItem().loadData(data.healthAssessment);

            const sortOrderTreatment: string[] = [
              'Surgery',
              'Radiation therapy',
              'Chemotherapy',
              'Immunotherapy',
              'Hormonal therapy',
              'Brachytherapy',
              'None - observation',
              'Other',
              'Unsure',
            ];
            const sortOrderStage: string[] = [
              'Not started',
              'In progress',
              'In-between courses',
              'Completed',
              'Unsure',
            ];
            const sortedOrderFamilyMember = [
              'Father',
              'Mother',
              'Daughter',
              'Son',
              'Brother',
              'Sister',
              'Grandfather',
              'Grandmother',
              'Aunt',
              'Uncle',
              'Cousin',
              'Other',
            ];

            const sortedTreatmentData = this.manualSortListData(sortOrderTreatment, data.treatmentTypeRefData);
            const sortedStageData = this.manualSortListData(sortOrderStage, data.treatmentStageRefData);
            const sortedFamilyMemberRefData = this.manualSortListData(
              sortedOrderFamilyMember,
              data.familyMemberRefData,
              false,
            );

            return (
              <HAMedicalHistoryCancer
                medicalHistoryCancer={haMedicalCancer}
                autosave={this.autosave}
                performCRUD={this.performOperation}
                treatmentTypesRefData={sortedTreatmentData}
                treatmentStagesRefData={sortedStageData}
                familyMemberRefData={sortedFamilyMemberRefData}
                refetch={refetch}
                validateOnLoad={validateOnLoad}
                patientId={patientId}
              />
            );
          }
          return <div></div>;
        }}
      </Query>
    );
  }

  private autosave = (
    updateObject: object,
    replaceId?: string,
    refetch?: any,
    mutationType?: HAMutationType,
  ): Promise<void> => {
    const { mutateGraph, client, getSaveStatus, updateSaveStatus } = this.props;

    // Mutate the graph
    return mutateGraph(
      client,
      updateObject,
      (): void => {
        getSaveStatus(client).then((savingStatus) => {
          updateSaveStatus(savingStatus);
        });
      },
      (): void => {},
      (): void => {},
      async () => {
        const saveStatus = await getSaveStatus(client);
        updateSaveStatus(saveStatus);

        if (refetch !== undefined) {
          try {
            refetch();
          } catch (error) {
            logger.debug('Refetch failed', error);
          }
        }
      },
      replaceId,
      undefined,
      mutationType,
    );
  };

  private performOperation = (
    haId: string,
    operation: string,
    objectType: string,
    itemId?: string,
    refetch?: any,
    relatedObjectId?: string,
  ): void => {
    const { mutateCRUDGraph, client, getSaveStatus, updateSaveStatus } = this.props;

    // Build the update object
    const updateObject: Dictionary = {
      haId,
      objectType,
      operation,
    };

    if (relatedObjectId) {
      updateObject['relatedObjectId'] = relatedObjectId;
    }

    if (itemId !== undefined && itemId !== '') {
      updateObject.id = itemId;
    }

    // Call the mutation to update the CRUD
    mutateCRUDGraph(
      client,
      updateObject,
      (): void => {
        getSaveStatus(client).then((savingStatus): void => {
          updateSaveStatus(savingStatus);
        });
      },
      (): void => {},
      (): void => {},
      async (): Promise<void> => {
        const saveStatus = await getSaveStatus(client);
        updateSaveStatus(saveStatus);
        if (refetch !== undefined) {
          try {
            refetch();
          } catch (error) {
            logger.debug('Refetch failed', error);
          }
        }
      },
    );
  };

  /**
   * Manually sort list data on the list data.name by a given sort data
   * @param {string[]} sortedDataByName Given object name to sort by
   * @param {ListData[]} referenceData The list data to sort on
   */
  private manualSortListData = (
    sortedDataByName: string[],
    referenceData: ListData[],
    appendMissing = true,
  ): ListData[] => {
    const sortedData: ListData[] = [];

    // Loop through the array of items we want to sort on based on an listdata.name
    for (const sortName of sortedDataByName) {
      const item = referenceData.find((element) => element.name === sortName);
      if (item) {
        sortedData.push(item);
        // If we found it, then remove it from our treatment data. As we add any we did not found at the end
        referenceData = referenceData.filter((element) => {
          return element.name !== sortName;
        });
      }
    }
    // If we missed anything add it to the end of the sorted reference data
    return appendMissing ? sortedData.concat(referenceData) : sortedData;
  };
}

const routedComponent = withRouter(HAMedicalHistoryCancerApollo);
const haComponent = withHealthAssessment(routedComponent);
export default haComponent;
