import React, { useContext } from 'react';
import { styled, useTheme } from '@mui/system';
import { useHistory } from 'react-router';
import { ROPatientContext } from 'op-pages/RO/PatientSummary/context';
import { Card } from 'shared-components/components';
import PatientCareplanTableApollo from './PatientCareplanTableApollo';
import Button from '@mui/material/Button';
import AddCircleOutline from '@mui/icons-material/AddCircleOutline';
const VerticalLayout = styled('div')`
  display: flex;
  flex-direction: column;
  padding: 20px 12px;
`;
const HorizontalLayout = styled('div')`
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding-bottom: 16px;
`;

const PatientCareplans = (props: { id: string }): JSX.Element => {
  const history = useHistory();
  const theme = useTheme();
  const { patientDetails } = useContext(ROPatientContext);
  const isGenderMissing = patientDetails?.patientGender === 'Other' || patientDetails?.patientGender === '';
  const isDobMissing = patientDetails?.dob === '' || patientDetails?.dob === null;
  const isAddressMissing = patientDetails?.address === null || [null, ''].includes(patientDetails?.address?.line1);

  return (
    <Card style={{ margin: '24px', width: 'calc(100% - 48px)', height: 'calc(100vh - 160px)' }}>
      <VerticalLayout>
        <HorizontalLayout>
          <div>
            <h1 style={{ fontSize: '24px' }}>Patient careplans</h1>
          </div>
          <div>
            <Button
              data-cy={'pts-create-careplan-button'}
              variant="outlined"
              startIcon={<AddCircleOutline color={theme.palette.primary.main} />}
              onClick={(): void => {
                history.push(`/medonc/patient/${props.id}/careplan/create`);
              }}
              disabled={isGenderMissing || isDobMissing || isAddressMissing}>
              New careplan
            </Button>
          </div>
        </HorizontalLayout>
        <div style={{ overflow: 'auto', height: 'calc(100vh - 280px)' }}>
          <PatientCareplanTableApollo />
        </div>
      </VerticalLayout>
    </Card>
  );
};

export default PatientCareplans;
