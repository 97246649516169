import { useQuery } from '@apollo/client';
import { SummaryCard, SummaryRow } from 'op-components';
import { evaluateDetailText } from 'op-components/OP/SummaryRow/SummaryRow';
import { MEDICATION_FIELDS } from 'op-pages/OP/HealthAssessment/HAPages/constants';
import { renderArrow } from 'op-pages/OP/HealthAssessment/HASharedComponents/HAModalFields';
import { HA_MEDICATION_QUERY } from 'op-pages/OP/HealthAssessment/HASharedComponents/queries';
import {
  HAMedicationData,
  HASubComponentProps,
  MedicationItem,
} from 'op-pages/OP/HealthAssessment/HASharedComponents/types';
import React, { Fragment, useMemo } from 'react';
import { Edit, Success } from 'shared-components/images';

const HAMedicationsUS = (props: HASubComponentProps) => {
  const { patientId, isPso, navigate } = props;

  const { data: haMedicationsData, loading } = useQuery(HA_MEDICATION_QUERY, {
    variables: { patientId },
  });
  const data = useMemo<HAMedicationData>(() => haMedicationsData?.healthAssessment, [haMedicationsData]);

  if (loading) {
    return <></>;
  }

  if (data) {
    return (
      <SummaryCard
        id="operations"
        title={'Medications'}
        primaryIcon={isPso ? <></> : <Edit className="primary-icon" />}
        secondaryIcon={<Success className="secondary-icon valid-icon" />}
        showEdit={!isPso}
        onClick={() => {
          if (!isPso) {
            navigate('medications');
          }
        }}>
        <SummaryRow
          headingText={MEDICATION_FIELDS.MEDICATION.TITLE}
          detailText={evaluateDetailText(data.medicationBool)}
          displayAsError={false}
          displayAsOptional={false}
        />

        {data.medicationBool && (
          <>
            {data.medications.length > 0 && (
              <>
                {renderArrow()}
                {data.medications.map((medication: MedicationItem, index: number) => (
                  <Fragment key={index}>
                    <SummaryRow
                      headingText={`${MEDICATION_FIELDS.MEDICATION_NAME.TITLE} ${index + 1}`}
                      detailText={evaluateDetailText(medication.medicationName)}
                      displayAsError={false}
                      displayAsOptional={false}
                      subQuestion
                    />
                    <SummaryRow
                      headingText={MEDICATION_FIELDS.DOSAGE.TITLE}
                      detailText={evaluateDetailText(medication.dosage)}
                      displayAsError={false}
                      displayAsOptional={false}
                      subQuestion
                    />
                    <SummaryRow
                      headingText={MEDICATION_FIELDS.PHYSICIAN.TITLE}
                      detailText={evaluateDetailText(medication.prescribingPhysician)}
                      displayAsError={false}
                      displayAsOptional={false}
                      subQuestion
                    />
                    <SummaryRow
                      headingText={MEDICATION_FIELDS.ROUTE.TITLE}
                      detailText={evaluateDetailText(medication.route)}
                      displayAsError={false}
                      displayAsOptional={false}
                      subQuestion
                    />
                  </Fragment>
                ))}
              </>
            )}
            <SummaryRow
              headingText={MEDICATION_FIELDS.PHARMACY_NAME.TITLE}
              detailText={evaluateDetailText(data.pharmacyName)}
              displayAsError={false}
              displayAsOptional={false}
            />
            <SummaryRow
              headingText={MEDICATION_FIELDS.PHARMACY_PHONE.TITLE}
              detailText={evaluateDetailText(data.pharmacyPhone)}
              displayAsError={false}
              displayAsOptional={false}
            />
          </>
        )}
      </SummaryCard>
    );
  }
  return <></>;
};

export default HAMedicationsUS;
