// eslint-disable-next-line no-use-before-define

import { RouteComponentProps, withRouter } from 'react-router';

import './FAQContentContainer.scss';

import { MainLayout } from 'op-pages/PX/Components';
import { useHistory } from 'react-router-dom';
import FAQContentWrapper from './Content/FAQContentWrapper';

interface Match {
  category?: string;
}

type Props = RouteComponentProps & Match;

const FAQContentContainer = (props: Props): JSX.Element => {
  const history = useHistory<History>();
  const handleLogoClick = (): void => history.replace('/px/information/faq');

  return (
    <MainLayout clickLogoFn={handleLogoClick}>
      <div className="px-faq-content-page-container page-container">
        <FAQContentWrapper />
      </div>
    </MainLayout>
  );
};

export default withRouter(FAQContentContainer);
