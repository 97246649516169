import classNames from 'classnames';
import { memo, useMemo, useState } from 'react';
import { LinearProgress, Stack } from '@mui/material';
import { LoadingSpinner } from 'shared-components/components';
import { ErrorInfo } from 'shared-components/components/FormFields';
import { InsertDriveFile } from '@mui/icons-material';
import { Card, useTheme } from '@mui/material';
interface UploadCardIProps {
  downloadUrl: string;
  filename: string;
  removeDocument: (e: any) => void;
  progress: number;
  errors: string[];
}

const UploadCard = (props: UploadCardIProps): JSX.Element => {
  const { downloadUrl, filename, progress = 0, errors, removeDocument } = props;
  const theme = useTheme();

  const [isVisible, setIsVisible] = useState<boolean>(true);
  const hasError = useMemo<boolean>(() => !!(errors && errors?.length), [errors]);

  const alteredFilename = useMemo<string>(
    () => (filename?.length > 25 ? filename.slice(0, 22) + '…' + filename.slice(-4) : filename),
    [filename],
  );

  const showDownloadSpinner = useMemo<boolean>(
    () => progress === 100 && !hasError && !downloadUrl,
    [progress, downloadUrl],
  );

  const getUploadText = (): string => {
    if (hasError) return 'ERROR IN UPLOAD';

    return progress !== 100 ? `UPLOADING ${Math.round(progress)}%` : 'COMPLETED -';
  };

  const handleDelete = (e: any): void => {
    setIsVisible(false);
    removeDocument(e);
  };

  return (
    <>
      <div
        className={classNames({
          'attachment-card': isVisible,
          'hide-upload-box': !isVisible,
        })}>
        <Card className={classNames('file-card', { 'upload-error-outline': hasError })}>
          <Stack direction="row" justifyContent="space-evenly" alignItems="center" sx={{ height: '100%' }}>
            <InsertDriveFile fontSize="large" htmlColor={theme.palette.grey[600]} />
            <div className="upload-card-content">
              <div data-test-id={alteredFilename} className="file-upload-name">
                {alteredFilename}
              </div>
              <LinearProgress id="bar-adjustments" color="primary" variant="determinate" value={progress} />
              <div className="file-upload-status">
                {getUploadText()}
                {showDownloadSpinner && <LoadingSpinner container={false} relativeSpinner loadingText="" />}
                {downloadUrl && (
                  <a data-testid="file-preview" href={downloadUrl} rel="noreferrer" target={'_blank'}>
                    PREVIEW
                  </a>
                )}
              </div>
            </div>
          </Stack>
        </Card>
        <div className="close-section">
          {!isVisible ? (
            <LoadingSpinner relativeSpinner loadingText="" />
          ) : (
            <div
              data-test-id={`delete-${alteredFilename}`}
              className={classNames('close-icon', { 'green-icon-to-red': hasError })}
              onClick={handleDelete}
            />
          )}
        </div>
      </div>
      <div className={classNames({ 'hide-upload-box': !isVisible })}>
        <ErrorInfo errors={errors} />
      </div>
    </>
  );
};

export default memo(UploadCard);
