// eslint-disable-next-line no-use-before-define
import React from 'react';
import './InfoTile.scss';

interface Props {
  name: {
    header: string;
    body: string;
  };
  children: React.ReactNode;
}

const InfoTile: React.FC<Props> = ({ name, children }): JSX.Element => {
  const { header, body } = name;
  const image = children;

  return (
    <div className="info-tile">
      <div className="info-tile-image">{image}</div>
      <div className="info-tile-body">
        <div id="device-header" className="info-tile-header">
          {header}
        </div>
        <div id="device-text" className="info-tile-text">
          {body}
        </div>
      </div>
    </div>
  );
};

export default InfoTile;
