import { gql } from '@apollo/client';

export const MO_TREATMENT_INFO_QUERY = gql`
  query moTreatmentInfo($careplanId: ID!, $patientId: ID!) {
    moTreatmentInfo(careplanId: $careplanId) {
      id
      readyForCareDate
      intent
      treatingDepartment
    }
    moPatientAllergy(patientId: $patientId) {
      id
      allergy
      allergySpecify
    }
  }
`;

export const MO_TREATMENT_INFO_MUTATION = gql`
  mutation updateMoTreatmentInfo(
    $careplanId: ID!
    $patientId: ID!
    $allergy: String
    $allergySpecify: String
    $readyForCareDate: String
    $intent: String
    $treatingDepartment: String
  ) {
    updateMoTreatmentInfo(
      careplanId: $careplanId
      readyForCareDate: $readyForCareDate
      intent: $intent
      treatingDepartment: $treatingDepartment
    ) {
      treatmentInfo {
        id
        readyForCareDate
        intent
        treatingDepartment
      }
    }
    updateMoAllergy(patientId: $patientId, allergy: $allergy, allergySpecify: $allergySpecify) {
      moAllergy {
        id
        allergy
        allergySpecify
      }
    }
  }
`;

export const CREATE_MO_PATIENT_OBSERVATION = gql`
  mutation createMoPatientObservation(
    $careplanId: ID!
    $weight: Float!
    $height: Float!
    $serumCreatinine: Float
    $bsa: Float
    $creatinineClearance: Float
    $recalculateDoses: Boolean
  ) {
    createMoPatientObservation(
      careplanId: $careplanId
      weight: $weight
      height: $height
      serumCreatinine: $serumCreatinine
      bsa: $bsa
      creatinineClearance: $creatinineClearance
      recalculateDoses: $recalculateDoses
    ) {
      moPatientObservation {
        id
        weight
        height
        serumCreatinine
        observationDate
        bsa
        creatinineClearance
        recalculate
      }
    }
  }
`;

export const GET_LATEST_MO_PATIENT_OBSERVATION = gql`
  query latestMoPatientObservation($careplanId: ID!) {
    latestMoPatientObservation(careplanId: $careplanId) {
      id
      weight
      height
      serumCreatinine
      observationDate
      bsa
      creatinineClearance
      recalculate
    }
  }
`;

export const GET_DRUG_ORDERS = gql`
  query drugOrders($drugCategory: String!) {
    drugOrders(drugCategory: $drugCategory) {
      id
      drug
      name
      doseBasis
      route
      frequency
      prn
      type
      base
      version
      minimumDose
      doseUnit
      genericName
      doseIsCalculated
      doseCalculatedUnits
      doseCalculatedBy
      solution
      administrationInstructions
    }
  }
`;
