import { ROPatientContext } from 'op-pages/RO/PatientSummary/context';
import React, { useContext } from 'react';
import Banner, { BannerType } from '../Careplan/PrescriptionPage/Components/Banner';
import { useTheme } from '@mui/material';

const PatientBanner = (): JSX.Element => {
  const theme = useTheme();
  const { patientDetails } = useContext(ROPatientContext);
  const isGenderMissing = patientDetails?.patientGender === 'Other' || patientDetails?.patientGender === '';
  const isDobMissing = patientDetails?.dob === '' || patientDetails?.dob === null;
  const isAddressMissing = patientDetails?.address === null || [null, ''].includes(patientDetails?.address?.line1);
  if (!(isGenderMissing || isDobMissing || isAddressMissing)) return <></>;
  const missingMsg = [];
  if (isGenderMissing) missingMsg.push('Patient sex');
  if (isDobMissing) missingMsg.push('Date of birth');
  if (isAddressMissing) missingMsg.push('Address');
  return (
    <Banner type={BannerType.UNAPPROVED} color={theme.palette.warning.light} $borderColor={theme.palette.warning.main}>
      <div data-cy="missing-patient-info">
        Missing {missingMsg.join()}. Please add these in ZedMed before creating a careplan.
      </div>
    </Banner>
  );
};

export default PatientBanner;
