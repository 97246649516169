// eslint-disable-next-line no-use-before-define
import React, { Component } from 'react';
import classNames from 'classnames';
import CircularProgress from '@mui/material/CircularProgress';
import './LoadingSpinner.scss';
import { Typography } from '@mui/material';

interface Props {
  loadingText?: string | null;
  relativeSpinner?: boolean;
  visible?: boolean;
  container?: boolean;
  subtitle?: string | null;
}

const LoadingSpinner = (props: Props) => {
  const { loadingText = 'Loading page', relativeSpinner = false, visible = true, container = true, subtitle } = props;

  return (
    <div
      className={classNames({
        'spinner-container': container,
        'entire-viewport': !relativeSpinner,
        'loading-off': !visible,
      })}>
      <div className="spinner-inner-container">
        <div data-test-id="loading-spinner" className="text-display">
          {loadingText?.split('\n').map(
            (line: string, index: number): JSX.Element => (
              <Typography variant="h6" key={index}>
                {line}
              </Typography>
            ),
          )}
        </div>
        {subtitle && (
          <Typography variant="subtitle2" className="loader-subtitle">
            {subtitle}
          </Typography>
        )}
      </div>
      <CircularProgress
        color="primary"
        sx={{
          marginTop: '15px',
        }}
      />
    </div>
  );
};

export default LoadingSpinner;
