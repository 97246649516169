import { styled } from '@mui/system';

interface CardProps {
  readonly $noPadding?: boolean;
  readonly $borderRadius?: boolean;
  readonly $noShadow?: boolean;
  readonly $filled?: boolean;
}

const Card = styled('div')<CardProps>`
  background-color: white;
  padding: ${(props) => (props.$noPadding ? '0' : '16px')};
  width: 100%;
  height: ${(props) => (props.$filled ? '100%' : 'auto')};
  box-shadow: ${(props) => (props.$noShadow ? 'none' : '0px 2px 8px rgba(113, 110, 106, 0.2)')};
  border-radius: ${(props) => (props.$borderRadius ? '4px' : 'none')};
`;

Card.defaultProps = {
  $noPadding: false,
  $borderRadius: true,
  $noShadow: false,
  $filled: false,
};

export default Card;
