import { FormikValues, validateYupSchema, yupToFormErrors } from 'formik';
import * as Yup from 'yup';
import { DVA_NUMBER_REGEXP, MEDICARE_IRN_REGEXP, MEDICARE_REGEX } from 'shared-components/utils';
import {
  DVA_FORMAT_VALIDATION,
  MONTH_FORMAT_VALIDATION,
  YEAR_FORMAT_VALIDATION,
  MEDICARE_IRN_VALIDATION,
  MEDICARE_NUMBER_VALIDATION,
  VALUE_REQUIRED,
} from '../Helper';
import { ListData } from 'shared-components/interfaces';

interface generateValidationSchemaIProps {
  values: FormikValues;
  readonly refData: {
    readonly medicareTypeRefData: ListData[];
    readonly dvaTypeRefData: ListData[];
    readonly concessionCardTypeRefData: ListData[];
  };
}

const validateInsurance = (props: generateValidationSchemaIProps) => {
  const MEDICARE_NUMBER_LENGTH = 10;
  const CURRENT_YEAR = new Date().getFullYear();
  const EXPIRY_YEARS = 20; // Arbitrary number to prevent very large expiry dates

  const {
    values,
    refData: { medicareTypeRefData, dvaTypeRefData, concessionCardTypeRefData },
  } = props;

  const medicareTypeRefDataValuesList: string[] = medicareTypeRefData.map((item: ListData) => item.name);
  const dvaTypeRefDataValuesList: string[] = dvaTypeRefData.map((item: ListData) => item.name);
  const concessionCardTypeRefDataValuesList: string[] = concessionCardTypeRefData.map((item: ListData) => item.name);

  let medicareAndDvaValidationObject: any = {
    healthMedicareNumber: Yup.string()
      .max(MEDICARE_NUMBER_LENGTH, MEDICARE_NUMBER_VALIDATION)
      .matches(MEDICARE_REGEX, MEDICARE_NUMBER_VALIDATION),
    healthMedicareIrn: Yup.string().matches(MEDICARE_IRN_REGEXP, MEDICARE_IRN_VALIDATION),
    medicareExpiryRawMonth: Yup.number()
      .typeError(MONTH_FORMAT_VALIDATION)
      .min(1, MONTH_FORMAT_VALIDATION)
      .max(12, MONTH_FORMAT_VALIDATION),
    medicareExpiryRawYear: Yup.number()
      .typeError(YEAR_FORMAT_VALIDATION)
      .min(CURRENT_YEAR, YEAR_FORMAT_VALIDATION)
      .max(CURRENT_YEAR + EXPIRY_YEARS, YEAR_FORMAT_VALIDATION),
    healthPensionCard: Yup.string().ensure().required(VALUE_REQUIRED),
    healthMedicareDvaOption: Yup.string().ensure().oneOf(medicareTypeRefDataValuesList, VALUE_REQUIRED),
  };

  if (values?.healthPensionCard) {
    medicareAndDvaValidationObject = {
      ...medicareAndDvaValidationObject,
      healthPensionCardType: Yup.string()
        .ensure()
        .required(VALUE_REQUIRED)
        .oneOf(concessionCardTypeRefDataValuesList, VALUE_REQUIRED),
      healthPensionCardNumber: Yup.string().ensure().required(VALUE_REQUIRED),
      healthPensionCardStart: Yup.string().ensure().required(VALUE_REQUIRED),
      healthPensionCardExpiry: Yup.string().ensure().required(VALUE_REQUIRED),
    };
  }

  if (values?.healthMedicareDvaOption === 'Medicare and DVA') {
    medicareAndDvaValidationObject = {
      ...medicareAndDvaValidationObject,
      healthDvaType: Yup.string().ensure().required(VALUE_REQUIRED).oneOf(dvaTypeRefDataValuesList, VALUE_REQUIRED),
      healthDvaNumber: Yup.string()
        .required(VALUE_REQUIRED)
        .matches(DVA_NUMBER_REGEXP, DVA_FORMAT_VALIDATION)
        .min(8, DVA_FORMAT_VALIDATION),
      dvaExpiryRawMonth: Yup.number()
        .typeError(MONTH_FORMAT_VALIDATION)
        .required(VALUE_REQUIRED)
        .min(1, MONTH_FORMAT_VALIDATION)
        .max(12, MONTH_FORMAT_VALIDATION),
      dvaExpiryRawYear: Yup.number()
        .typeError(YEAR_FORMAT_VALIDATION)
        .required(VALUE_REQUIRED)
        .min(CURRENT_YEAR, YEAR_FORMAT_VALIDATION)
        .max(CURRENT_YEAR + EXPIRY_YEARS, YEAR_FORMAT_VALIDATION),
    };
  }

  return medicareAndDvaValidationObject;
};

export const generateValidationSchema = (props: generateValidationSchemaIProps): any => {
  const insuranceValidationObject = validateInsurance(props);

  try {
    validateYupSchema<FormikValues>(props.values, Yup.object(insuranceValidationObject), true);
  } catch (err) {
    return yupToFormErrors(err);
  }
};
