import { FormikValues, validateYupSchema, yupToFormErrors } from 'formik';
import { CurrentAppConfig } from 'op-pages/RO/Careplan/AppConfig';
import { EMAIL_LENGTH_VALIDATION, EMAIL_VALIDATION } from 'op-pages/Shared/constants';
import { AU_PHONE_REGEX, EMAIL_STRING_REGEX } from 'shared-components/utils/Regex';
import * as Yup from 'yup';
import { PHONE_REQUIRED, PHONE_VALIDATION } from '../Helper';
import 'yup-phone-lite';

export const generateValidationSchema = (values: FormikValues): any => {
  const multiFieldSchema = Yup.object().shape(
    {
      primaryPhone: Yup.string()
        .phone('AU', PHONE_VALIDATION)
        .test('secondary-number-required', PHONE_REQUIRED, function (value) {
          return !!value || !!this.parent.secondaryPhone;
        }),
      secondaryPhone: Yup.string()
        .phone('AU', PHONE_VALIDATION)
        .test('primary-number-required', PHONE_REQUIRED, function (value) {
          return !!value || !!this.parent.primaryPhone;
        }),
      email: Yup.string()
        .matches(EMAIL_STRING_REGEX, EMAIL_VALIDATION)
        .max(CurrentAppConfig.EmailMaxLength, EMAIL_LENGTH_VALIDATION),
    },
    [['primaryPhone', 'secondaryPhone']],
  );
  try {
    validateYupSchema<FormikValues>(values, multiFieldSchema, true);
  } catch (err) {
    return yupToFormErrors(err);
  }
};
