import React from 'react';
import { StandardDialog } from 'shared-components/components';

import { Typography } from '@mui/material';
import Radio from '@mui/material/Radio';
import RadioGroup from '@mui/material/RadioGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import FormControl from '@mui/material/FormControl';
import FormLabel from '@mui/material/FormLabel';
import FormHelperText from '@mui/material/FormHelperText';
import Stack from '@mui/material/Stack';
import Button from '@mui/material/Button';
import { useMutation } from '@apollo/client';
import { CAREPLAN_TEMPLATE_REQUEST_CHANGE } from './Queries';
import { GET_CAREPLAN } from '../Queries';
import { toast, Slide } from 'react-toastify';
interface RequestTemplateModalProps {
  open: boolean;
  careplanId: string;
  onClose: () => void;
}

const RequestTemplateModal: React.FC<RequestTemplateModalProps> = ({ open, careplanId, onClose }) => {
  const [value, setValue] = React.useState('');
  const [helperText, setHelperText] = React.useState('Our team will configure the template based on your request.');
  const [error, setError] = React.useState(false);

  const [requestTemplateChange] = useMutation(CAREPLAN_TEMPLATE_REQUEST_CHANGE, {
    refetchQueries: [{ query: GET_CAREPLAN, variables: { id: careplanId } }],
  });

  const handleTemplateChangeType = (event: React.ChangeEvent<HTMLInputElement>) => {
    setValue((event.target as HTMLInputElement).value);
    setHelperText('Our team will configure the template based on your request.');
    setError(false);
  };

  const onSendRequest = () => {
    if (value === '') {
      setHelperText('This is a required field.');
      setError(true);
      return;
    }
    requestTemplateChange({
      variables: {
        careplanId,
        status: value,
      },
    }).then(() => {
      onClose();

      toast.dark(<div>Request received</div>, {
        position: 'bottom-right',
        autoClose: 5000,
        hideProgressBar: true,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        transition: Slide,
        progress: undefined,
      });
    });
  };

  return (
    <StandardDialog
      maxWidth="md"
      title="Request careplan template change"
      open={open}
      onClose={onClose}
      closeText="CANCEL"
      onSubmit={onSendRequest}
      submitText="SEND REQUEST">
      <Stack>
        <Typography variant="body1" sx={{ paddingBottom: '16px' }}>
          We want to ensure your careplan templates are tailored to your needs. What would you like us to do?
        </Typography>
        <Stack>
          <FormControl error={error}>
            <FormLabel id="template-change-type">Please choose:</FormLabel>
            <RadioGroup
              aria-labelledby="template-change-type-label"
              name="template-change-type-group"
              value={value}
              onChange={handleTemplateChangeType}>
              <FormControlLabel value="modify" control={<Radio />} label="Modify selected template" />
              <FormControlLabel value="create" control={<Radio />} label="Create new template" />
            </RadioGroup>
            <FormHelperText>{helperText}</FormHelperText>
          </FormControl>
        </Stack>
      </Stack>
    </StandardDialog>
  );
};

export default RequestTemplateModal;
