import { useQuery } from '@apollo/client';
import { SummaryCard, SummaryRow } from 'op-components';
import { evaluateDetailText } from 'op-components/OP/SummaryRow/SummaryRow';
import { SOCIAL_US_FIELDS } from 'op-pages/OP/HealthAssessment/HAPages/constants';
import { renderArrow } from 'op-pages/OP/HealthAssessment/HASharedComponents/HAModalFields';
import { HA_SOCIAL_US_QUERY } from 'op-pages/OP/HealthAssessment/HASharedComponents/queries';
import {
  HASocialUSItem,
  HASubComponentProps,
  StreetDrugsObject,
} from 'op-pages/OP/HealthAssessment/HASharedComponents/types';
import React, { useMemo } from 'react';
import { Edit, Success } from 'shared-components/images';
import { SerializedListDataObject } from 'shared-components/interfaces';
import { displayRawDate } from 'shared-components/utils';
import { convertListDataToObject } from 'shared-components/utils/ListDataHelpers';

const HASocialAssessmentUS = (props: HASubComponentProps): JSX.Element => {
  const { patientId, isPso, navigate } = props;

  const { data, loading } = useQuery(HA_SOCIAL_US_QUERY, {
    variables: { patientId },
  });

  const alcoholFrequencyRefDataSerialized = useMemo<SerializedListDataObject>(
    () => convertListDataToObject(data?.alcoholFrequencyRefData),
    [data?.alcoholFrequencyRefData],
  );
  const smokingFrequencyRefDataSerialized = useMemo<SerializedListDataObject>(
    () => convertListDataToObject(data?.smokingFrequencyRefData),
    [data?.smokingFrequencyRefData],
  );

  const haSocialAssessmentData = useMemo<HASocialUSItem>(() => data?.healthAssessment, [data]);
  const streetDrugsUseData = useMemo<Partial<StreetDrugsObject>>(
    () => JSON.parse(data?.healthAssessment?.streetDrugsUse || '{}'),
    [data?.healthAssessment?.streetDrugsUse],
  );

  const evaluateStreetDrugsUseSummaryRow = () => {
    const filteredJsonParsedObj = Object.entries(streetDrugsUseData).filter((a: any) => a[1]?.value);
    return filteredJsonParsedObj.length > 0;
  };

  const evaluateSmokingHistoryYears = (value: string): string | null => {
    let returnVal = null;
    const smokingHistoryYears = evaluateDetailText(value);
    if (smokingHistoryYears === null) return returnVal;

    const smokingHistoryYearsInt = parseInt(smokingHistoryYears);
    if (smokingHistoryYearsInt > 1) {
      returnVal = `${smokingHistoryYears} years`;
    } else if (smokingHistoryYearsInt == 1) {
      returnVal = `${smokingHistoryYears} year`;
    }

    return returnVal;
  };

  if (loading) {
    return <></>;
  }

  if (haSocialAssessmentData) {
    return (
      <SummaryCard
        id="social-assessment"
        title={'Social assessment'}
        primaryIcon={isPso ? <></> : <Edit className="primary-icon" />}
        secondaryIcon={<Success className="secondary-icon valid-icon" />}
        showEdit={!isPso}
        onClick={() => {
          if (!isPso) {
            navigate('social-us');
          }
        }}>
        {/* Smoking */}
        <SummaryRow
          headingText={SOCIAL_US_FIELDS.SMOKING_BOOL.TITLE}
          detailText={evaluateDetailText(haSocialAssessmentData.smokingBool)}
          displayAsError={false}
          displayAsOptional={false}
        />
        {haSocialAssessmentData.smokingBool && (
          <>
            {renderArrow()}
            <SummaryRow
              headingText={SOCIAL_US_FIELDS.SMOKING_HISTORY.TITLE}
              detailText={evaluateSmokingHistoryYears(haSocialAssessmentData.smokingHistory)}
              displayAsError={false}
              displayAsOptional={false}
              subQuestion
            />
            <SummaryRow
              headingText={SOCIAL_US_FIELDS.SMOKING_FREQUENCY.TITLE}
              detailText={evaluateDetailText(
                smokingFrequencyRefDataSerialized[haSocialAssessmentData.smokingFrequency],
              )}
              displayAsError={false}
              displayAsOptional={false}
              subQuestion
            />
            <SummaryRow
              headingText={SOCIAL_US_FIELDS.SMOKING_STOP.TITLE}
              detailText={displayRawDate(
                haSocialAssessmentData.smokingStopRawMonth,
                haSocialAssessmentData.smokingStopRawYear,
                null,
                true,
              )}
              displayAsError={false}
              displayAsOptional={false}
              subQuestion
            />
          </>
        )}

        {/* Tobacco */}
        <SummaryRow
          headingText={SOCIAL_US_FIELDS.TOBACCO_BOOL.TITLE}
          detailText={evaluateDetailText(haSocialAssessmentData.tobaccoChew)}
          displayAsError={false}
          displayAsOptional={false}
        />
        {haSocialAssessmentData.tobaccoChew && (
          <>
            {renderArrow()}
            <SummaryRow
              headingText={SOCIAL_US_FIELDS.TOBACCO_HISTORY.TITLE}
              detailText={evaluateDetailText(haSocialAssessmentData.tobaccoChewHistory)}
              displayAsError={false}
              displayAsOptional={false}
              subQuestion
            />
            <SummaryRow
              headingText={SOCIAL_US_FIELDS.TOBACCO_STOP.TITLE}
              detailText={displayRawDate(
                haSocialAssessmentData.tobaccoStopRawMonth,
                haSocialAssessmentData.tobaccoStopRawYear,
                null,
                true,
              )}
              displayAsError={false}
              displayAsOptional={false}
              subQuestion
            />
            <SummaryRow
              headingText={SOCIAL_US_FIELDS.TOBACCO_CLASSES.TITLE}
              detailText={displayRawDate(
                haSocialAssessmentData.tobaccoStopClassesRawMonth,
                haSocialAssessmentData.tobaccoStopClassesRawYear,
                null,
                true,
              )}
              displayAsError={false}
              displayAsOptional={false}
              subQuestion
            />
          </>
        )}

        {/* Alcohol */}
        <SummaryRow
          headingText={SOCIAL_US_FIELDS.ALCOHOL_BOOL.TITLE}
          detailText={evaluateDetailText(haSocialAssessmentData.alcoholBool)}
          displayAsError={false}
          displayAsOptional={false}
        />
        {haSocialAssessmentData.alcoholBool && (
          <>
            {renderArrow()}
            <SummaryRow
              headingText={SOCIAL_US_FIELDS.ALCOHOL_FREQUENCY.TITLE}
              detailText={evaluateDetailText(
                alcoholFrequencyRefDataSerialized[haSocialAssessmentData.alcoholFrequency],
              )}
              displayAsError={false}
              displayAsOptional={false}
              subQuestion
            />
            <SummaryRow
              headingText={SOCIAL_US_FIELDS.ALCOHOL_STOP.TITLE}
              detailText={displayRawDate(
                haSocialAssessmentData.alcoholStopRawMonth,
                haSocialAssessmentData.alcoholStopRawYear,
                null,
                true,
              )}
              displayAsError={false}
              displayAsOptional={false}
              subQuestion
            />
          </>
        )}

        {/* Recreational drugs */}
        <SummaryRow
          headingText={SOCIAL_US_FIELDS.STREET_DRUGS_BOOL.TITLE}
          detailText={evaluateDetailText(haSocialAssessmentData.streetDrugs)}
          displayAsError={false}
          displayAsOptional={false}
        />
        {haSocialAssessmentData.streetDrugs && (
          <>
            {evaluateStreetDrugsUseSummaryRow() && renderArrow()}
            {streetDrugsUseData?.Marijuana?.value && (
              <SummaryRow
                headingText={'Marijuana'}
                detailText=""
                fallbackText=" "
                displayAsError={false}
                displayAsOptional={false}
                subQuestion
              />
            )}
            {streetDrugsUseData?.Cocaine?.value && (
              <SummaryRow
                headingText={'Cocaine'}
                detailText=""
                fallbackText=" "
                displayAsError={false}
                displayAsOptional={false}
                subQuestion
              />
            )}
            {streetDrugsUseData?.Methamphetamine?.value && (
              <SummaryRow
                headingText={'Methamphetamine'}
                detailText=""
                fallbackText=" "
                displayAsError={false}
                displayAsOptional={false}
                subQuestion
              />
            )}
            {streetDrugsUseData?.OtherDrugs?.value && (
              <SummaryRow
                headingText={'Other drugs'}
                detailText={evaluateDetailText(streetDrugsUseData?.OtherDrugsDescription?.value)}
                displayAsError={false}
                displayAsOptional={false}
                subQuestion
              />
            )}
          </>
        )}

        {/* Medical Marijuana */}
        <SummaryRow
          headingText={SOCIAL_US_FIELDS.MEDICAL_MARIJUANA_CARD_BOOL.TITLE}
          detailText={evaluateDetailText(haSocialAssessmentData.medicalMarijuanaCard)}
          displayAsError={false}
          displayAsOptional={false}
        />

        {/* Social Support System */}
        <SummaryRow
          headingText={SOCIAL_US_FIELDS.SOCIAL_SUPPORT_SYSTEM_BOOL.TITLE}
          detailText={evaluateDetailText(haSocialAssessmentData.socialSupportSystem)}
          displayAsError={false}
          displayAsOptional={false}
        />
        {haSocialAssessmentData.socialSupportSystem && (
          <>
            {renderArrow()}
            <SummaryRow
              headingText={SOCIAL_US_FIELDS.SOCIAL_SUPPORT_SYSTEM.TITLE}
              detailText={evaluateDetailText(haSocialAssessmentData.socialSupportPeople)}
              displayAsError={false}
              displayAsOptional={false}
              subQuestion
            />
          </>
        )}

        {/* Still Working */}
        <SummaryRow
          headingText={SOCIAL_US_FIELDS.STILL_WORKING_BOOL.TITLE}
          detailText={evaluateDetailText(haSocialAssessmentData.stillWorking)}
          displayAsError={false}
          displayAsOptional={false}
        />
        {haSocialAssessmentData.stillWorking !== null && !haSocialAssessmentData.stillWorking && (
          <>
            {renderArrow()}
            <SummaryRow
              headingText={SOCIAL_US_FIELDS.STILL_WORKING.TITLE}
              detailText={evaluateDetailText(haSocialAssessmentData.stillWorkingReason)}
              displayAsError={false}
              displayAsOptional={false}
              subQuestion
            />
          </>
        )}

        {/* Social Assistance */}
        <SummaryRow
          headingText={SOCIAL_US_FIELDS.FINANCIAL_ASSISTANCE_BOOL.TITLE}
          detailText={evaluateDetailText(haSocialAssessmentData.financialAssisstance)}
          displayAsError={false}
          displayAsOptional={false}
        />
        {haSocialAssessmentData.financialAssisstance && (
          <>
            {renderArrow()}
            <SummaryRow
              testIdGroup="financial-assistance"
              headingText={SOCIAL_US_FIELDS.FINANCIAL_ASSISTANCE_REASON.TITLE}
              detailText={evaluateDetailText(haSocialAssessmentData.financialAssisstanceReason)}
              displayAsError={false}
              displayAsOptional={false}
              subQuestion
            />
          </>
        )}

        {/* Difficulty Travelling */}
        <SummaryRow
          headingText={SOCIAL_US_FIELDS.DIFFICULTY_TRAVELLING_BOOL.TITLE}
          detailText={evaluateDetailText(haSocialAssessmentData.difficultyTravellingBool)}
          displayAsError={false}
          displayAsOptional={false}
        />
        {haSocialAssessmentData.difficultyTravellingBool && (
          <>
            {renderArrow()}
            <SummaryRow
              headingText={SOCIAL_US_FIELDS.DIFFICULTY_TRAVELLING.TITLE}
              detailText={evaluateDetailText(haSocialAssessmentData.difficultyTravelling)}
              displayAsError={false}
              displayAsOptional={false}
              subQuestion
            />
          </>
        )}

        {/* Military Service */}
        <SummaryRow
          headingText={SOCIAL_US_FIELDS.SERVED_IN_MILITARY_BOOL.TITLE}
          detailText={evaluateDetailText(haSocialAssessmentData.servedInMilitary)}
          displayAsError={false}
          displayAsOptional={false}
        />
        {haSocialAssessmentData.servedInMilitary && (
          <>
            {renderArrow()}
            <SummaryRow
              headingText={SOCIAL_US_FIELDS.SERVED_IN_MILITARY.TITLE}
              detailText={evaluateDetailText(haSocialAssessmentData.servedInMilitaryBranch)}
              displayAsError={false}
              displayAsOptional={false}
              subQuestion
            />
          </>
        )}

        {/* Physically Active */}
        <SummaryRow
          headingText={SOCIAL_US_FIELDS.PHYSICALLY_ACTIVE.TITLE}
          detailText={evaluateDetailText(haSocialAssessmentData.physicallyActive)}
          displayAsError={false}
          displayAsOptional={false}
        />
        {haSocialAssessmentData.physicallyActive && (
          <>
            {renderArrow()}
            <SummaryRow
              headingText={SOCIAL_US_FIELDS.PHYSICALLY_ACTIVE_EXERCISE.TITLE}
              detailText={evaluateDetailText(haSocialAssessmentData.physicallyActiveExercise)}
              displayAsError={false}
              displayAsOptional={false}
              subQuestion
            />
          </>
        )}

        {/* Chemical Exposure */}
        <SummaryRow
          headingText={SOCIAL_US_FIELDS.CHEMICALS_BOOL.TITLE}
          detailText={evaluateDetailText(haSocialAssessmentData.chemicals)}
          displayAsError={false}
          displayAsOptional={false}
        />
        {haSocialAssessmentData.chemicals && (
          <>
            {renderArrow()}
            <SummaryRow
              testIdGroup="chemicals"
              headingText={SOCIAL_US_FIELDS.CHEMICALS.TITLE}
              detailText={evaluateDetailText(haSocialAssessmentData.chemicalsExposed)}
              displayAsError={false}
              displayAsOptional={false}
              subQuestion
            />
          </>
        )}

        {/* Religious Beliefs */}
        <SummaryRow
          headingText={SOCIAL_US_FIELDS.RELIGIOUS_BELIEFS_BOOL.TITLE}
          detailText={evaluateDetailText(haSocialAssessmentData.religiousBeliefs)}
          displayAsError={false}
          displayAsOptional={false}
        />
        {haSocialAssessmentData.religiousBeliefs && (
          <>
            {renderArrow()}
            <SummaryRow
              headingText={SOCIAL_US_FIELDS.RELIGIOUS_BELIEFS.TITLE}
              detailText={evaluateDetailText(haSocialAssessmentData.religiousBeliefsDescription)}
              displayAsError={false}
              displayAsOptional={false}
              subQuestion
            />
          </>
        )}
      </SummaryCard>
    );
  }

  return <></>;
};

export default HASocialAssessmentUS;
