import { useTheme } from '@mui/material';
import { styled } from '@mui/system';

export const SEARCH_DROPDOWN_PLACEHOLDER = 'Start typing or choose from dropdown...';

// TODO: fix this up and use grid when all components are cleaned up
export const ChildSectionWrapper = styled('div')`
  border-left: 1px solid ${(props) => props.theme.palette.grey[300]};

  & p {
    margin-left: 16px;
  }
  .gc-form-label {
    margin-right: -17px;
    margin-left: 16px;
  }
`;

export const getBorderColor = (error?: boolean, warning?: boolean, info?: boolean): string => {
  const theme = useTheme();
  return error
    ? theme.palette.error.main
    : warning
    ? theme.palette.warning.dark
    : info
    ? theme.palette.info.main
    : theme.palette.grey[300];
};
