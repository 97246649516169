export const COPY = {
  IS_PRACTITIONER_ERROR:
    'Your account setup appears to be incomplete or missing information. Please contact GenesisCare Innovation support: ',
  CANT_FIND_ACC_DETAILS: "We Couldn't Find Your Account Details",
  SEARCH_PLACEHOLDER: 'Search patient name or type in patient details...',
  NO_RESULTS: "Sorry, we couldn't find any results matching",
  CANT_FIND_SEARCH_TIP: "Can't find what you're looking for? Try some of these search tips:",
  SEARCH_TIPS: 'Search tips',
  CHECK_SPELLING: 'Check your spelling and try again',
  NEW_MOSAIQ_PATIENT_WAIT: 'Just created a new patient in the EMR? Please wait one minute and then refresh the page',
  NAME_OR_ID_MESSAGE: "Search using the patients' first name and last name or patient ID",
  CLEAR_STATUS_FILTER: 'Try to clear the status filter',
  NOT_SIGNED_UP: 'Not signed up',
  SIGNED_UP: 'Signed up',
  OPTED_OUT: 'Opted out',
  SUBMITTED_TO_MOSAIQ: 'Submitted to Mosaiq',
  REVIEW_REQUIRED: 'Review required',
  PATIENT_REGISTRATION: 'Patient Registration',
  DISTRESS_PIN: 'Distress Thermometer PIN',
  RECORD_IN_USE_BY: 'This patient record is currently in use by',
  RECORD_IN_USE_NO_VIEW: '. Please try and access again later.',
};
