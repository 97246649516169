import { styled } from '@mui/system';
import React, { useState, useEffect } from 'react';
import useInterval from 'shared-components/utils/useInterval';
import { CurrentAppConfig } from '../../Careplan/AppConfig';
import moment from 'moment';
import { Moment } from 'moment-timezone';

const StyledDateInfo = styled('div')``;

const getStyledDate = (inputDate: Moment, practitionerTimezone: string): string => {
  if (!inputDate) return 'Invalid date';
  const calendarString = moment(inputDate).tz(practitionerTimezone).calendar(null, {
    lastDay: '[Yesterday], ddd D MMM YYYY',
    sameDay: '[Today],  ddd D MMM YYYY, hh:mmA',
    nextDay: '[Tomorrow],  ddd D MMM YYYY',
    nextWeek: 'ddd D MMM YYYY',
    lastWeek: 'ddd D MMM YYYY',
    sameElse: 'ddd D MMM YYYY',
  });
  return calendarString;
};

export const DateTimeWidget = (props: { practitionerTimezone: string; date: Moment }): JSX.Element => {
  const [practitionerTimezone, setPractitionerTimezone] = useState(CurrentAppConfig.DefaultTimezone);
  useEffect(() => {
    if (props.practitionerTimezone) setPractitionerTimezone(props.practitionerTimezone);
  }, [props.practitionerTimezone]);
  const [time, setTime] = useState(moment().tz(practitionerTimezone));
  const today = props.date && props.date.isSame(time, 'day');
  useInterval(
    () => {
      setTime(moment());
    },
    today ? 1000 : null,
  );
  return <StyledDateInfo>{getStyledDate(today ? time : props.date, practitionerTimezone)}</StyledDateInfo>;
};

export default DateTimeWidget;
