import { TextAreaField, SelectField, ToggleButtonGroupField } from 'shared-components/components/FormikComponents';

export const SOCIAL_HISTORY_FIELDS = {
  FORM_HEADING: {
    NAME: 'formHeading',
    TITLE: 'Social Geographic History',
    COMPONENT: TextAreaField,
  },
  COUNTRY_OF_BIRTH: {
    NAME: 'countryOfBirth',
    TITLE: 'Country of birth',
    COMPONENT: SelectField,
  },
  STATE_OF_BIRTH: {
    NAME: 'stateOfBirth',
    TITLE: 'In which state were you born?',
    PLACE_HOLDER: 'Enter state',
    COMPONENT: TextAreaField,
  },
  AREA_LIVED_MOST: {
    NAME: 'areaLivedMost',
    TITLE: 'In what area did you live most of your life?',
    PLACE_HOLDER: 'Enter area',
    COMPONENT: TextAreaField,
  },
  CURRENT_STATE_HOW_LONG: {
    NAME: 'currentStateHowLong',
    TITLE: 'How long have you lived in your current state of residence?',
    PLACE_HOLDER: 'e.g. 5 years and 4 months',
    COMPONENT: TextAreaField,
  },

  IN_STATE_ALL_YEAR_BOOL: {
    NAME: 'inThisStateAllYear',
    TITLE: 'Do you live in this state all year round?',
    COMPONENT: ToggleButtonGroupField,
  },

  ALTERNATE_PHONE: {
    NAME: 'alternateAddressPhone',
    TITLE: 'Alternate phone number',
    PLACE_HOLDER: 'Enter alternate phone number',
    COMPONENT: TextAreaField,
  },
};
