// eslint-disable-next-line no-use-before-define
import React from 'react';
import { generatePath, Link, useRouteMatch } from 'react-router-dom';
import './ROSideNav.scss';
import { styled } from '@mui/system';
import { Stack, Typography, useTheme } from '@mui/material';
import { ArrowBack } from '@mui/icons-material';

interface ROPatientDashboardNavType {
  id: string;
  oncologyType: string;
}

const StyledLink = styled(Link)`
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 10px 16px;
  text-decoration: none;
  border-bottom: solid 1px ${(props) => props.theme.palette.grey[300]};
  transition: border-color 0.15s ease-in-out;

  &:focus,
  &:hover,
  &:visited,
  &:link,
  &:active {
    text-decoration: none;
  }
`;

export const PatientSummaryLink = (): JSX.Element => {
  const match = useRouteMatch<ROPatientDashboardNavType>();
  const { id: patientId, oncologyType } = match.params;
  const url = generatePath(`/${oncologyType}/patient/${patientId}/summary`);
  const theme = useTheme();

  return (
    <StyledLink to={url}>
      <Stack direction="row" gap={2} alignItems="center">
        <ArrowBack color="primary" />
        <Stack>
          <Typography variant="body1">Back</Typography>
          <Typography variant="body2" color={theme.palette.text.secondary}>
            Patient summary
          </Typography>
        </Stack>
      </Stack>
    </StyledLink>
  );
};
