import { SelectField, TextAreaField, ToggleButtonGroupField } from 'shared-components/components/FormikComponents';

export const FIELDS = {
  FORM_HEADING: {
    NAME: 'formHeading',
    TITLE: 'Alternate Contacts',
  },
  FIRST_NAME: {
    NAME: 'firstName',
    TITLE: 'First name*',
    PLACE_HOLDER: 'Enter first name',
    COMPONENT: TextAreaField,
  },
  LAST_NAME: {
    NAME: 'lastName',
    TITLE: 'Last name*',
    PLACE_HOLDER: 'Enter last name',
    COMPONENT: TextAreaField,
  },
  RELATIONSHIP: {
    NAME: 'relationship',
    TITLE: 'Relationship*',
    PLACE_HOLDER: 'Select from drop down',
    COMPONENT: SelectField,
  },
  MOBILE: {
    NAME: 'mobilePhoneNumber',
    TITLE: 'Mobile*',
    PLACE_HOLDER: 'Enter at least one phone number',
    COMPONENT: TextAreaField,
  },
  HOME_PHONE: {
    NAME: 'homePhoneNumber',
    TITLE: 'Home phone*',
    PLACE_HOLDER: 'Enter at least one phone number',
    COMPONENT: TextAreaField,
  },
  EMAIL: {
    NAME: 'email',
    TITLE: 'Email',
    PLACE_HOLDER: 'Enter email address',
    COMPONENT: TextAreaField,
  },
  ENQUIRIES: {
    NAME: 'authorisedForEnquiries',
    TITLE: 'Authorised to make enquiries for appointment times*',
    COMPONENT: ToggleButtonGroupField,
  },
  CONTACT_PERSON: {
    NAME: 'supportPerson',
    TITLE: 'Identified as support person in making decisions*',
    COMPONENT: ToggleButtonGroupField,
  },
  ANOTHER_CONTACT: {
    NAME: 'altContactProvided',
    TITLE: 'Add another contact?',
    COMPONENT: ToggleButtonGroupField,
  },
};

export const ADDITIONAL_CONTACT_FIELDS = {
  FIRST_NAME: {
    NAME: 'nextOfKinContact.firstName',
    TITLE: 'First name',
    PLACE_HOLDER: 'Enter first name',
    COMPONENT: TextAreaField,
  },
  LAST_NAME: {
    NAME: 'nextOfKinContact.lastName',
    TITLE: 'Last name',
    PLACE_HOLDER: 'Enter last name',
    COMPONENT: TextAreaField,
  },
  RELATIONSHIP: {
    NAME: 'nextOfKinContact.relationship',
    TITLE: 'Relationship',
    PLACE_HOLDER: 'Select from drop down',
    COMPONENT: SelectField,
  },
  MOBILE: {
    NAME: 'nextOfKinContact.mobilePhoneNumber',
    TITLE: 'Mobile',
    PLACE_HOLDER: 'Enter at least one phone number',
    COMPONENT: TextAreaField,
  },
  HOME_PHONE: {
    NAME: 'nextOfKinContact.homePhoneNumber',
    TITLE: 'Home phone',
    PLACE_HOLDER: 'Enter at least one phone number',
    COMPONENT: TextAreaField,
  },
  EMAIL: {
    NAME: 'nextOfKinContact.email',
    TITLE: 'Email',
    PLACE_HOLDER: 'Enter email address',
    COMPONENT: TextAreaField,
  },
  ENQUIRIES: {
    NAME: 'nextOfKinContact.authorisedForEnquiries',
    TITLE: 'Authorised to make enquiries for appointment times',
    COMPONENT: ToggleButtonGroupField,
  },
  CONTACT_PERSON: {
    NAME: 'nextOfKinContact.supportPerson',
    TITLE: 'Identified as support person in making decisions',
    COMPONENT: ToggleButtonGroupField,
  },
};
