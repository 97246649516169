import React, { ReactChild, useState } from 'react';
import { CheckBoxSelected, CheckBoxUnselected } from 'shared-components/images';
import { styled } from '@mui/system';
import { Stack } from '@mui/material';

const StyledCheckbox = styled('span')<{ $isSelected: boolean }>`
  svg {
    fill: ${(props) => (props?.$isSelected ? props.theme.palette.primary.dark : props.theme.palette.grey[600])};
    cursor: pointer;
  }
`;

const StyledCol = styled(Stack)`
  padding: 9px;
  font-size: 18px;
  font-weight: 700;
  line-height: 28px;
`;

interface AccordionCheckboxItemIProps {
  label: string;
  value: boolean;
  children?: ReactChild;
  onClick: (value: boolean) => void;
  disabled?: boolean;
}

const AccordionCheckboxItem = (props: AccordionCheckboxItemIProps): JSX.Element => {
  const { label, value, children, onClick, disabled } = props;

  const [checked, setChecked] = useState<boolean>(value);

  const handleClick = () => {
    if (disabled) return;
    setChecked(!value);
    onClick(!value);
  };

  return (
    <Stack>
      <div
        onClick={handleClick}
        style={{
          cursor: 'pointer',
          padding: '6px 9px',
          display: 'flex',
          justifyContent: 'space-between',
          flexWrap: 'wrap',
          marginRight: '16px',
        }}>
        <StyledCol data-test-name={`label-${label}`}>{label}</StyledCol>
        <StyledCol>
          <StyledCheckbox $isSelected={checked} data-test-id={label}>
            {checked ? (
              <CheckBoxSelected data-test-value="checked" />
            ) : (
              <CheckBoxUnselected data-test-value="unchecked" />
            )}
          </StyledCheckbox>
        </StyledCol>
      </div>
      {children && checked && (
        <Stack sx={{ padding: '9px 18px' }}>
          <div>{children}</div>
        </Stack>
      )}
    </Stack>
  );
};

export default AccordionCheckboxItem;
