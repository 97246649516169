import React from 'react';
import { styled } from '@mui/system';

import { NurseReviewedIcon } from 'shared-components/images';
import { PersonOutlined as PersonOutlinedIcon } from '@mui/icons-material';

const StyledIconWrapper = styled('div')`
  margin-left: 10px;
  white-space: nowrap;
  padding: 0;
`;

interface Props {
  userType: string | undefined;
}

const UserIcon = ({ userType }: Props): JSX.Element => {
  const supportedIcons = {
    nurse: (
      <StyledIconWrapper data-test-id="user-icon-nurse">
        <NurseReviewedIcon />
      </StyledIconWrapper>
    ),
    patient: (
      <StyledIconWrapper data-test-id="user-icon-patient">
        <PersonOutlinedIcon color="info" />
      </StyledIconWrapper>
    ),
  };

  return userType ? supportedIcons[userType as keyof typeof supportedIcons] : <></>;
};

export default UserIcon;
