import React, { useReducer, useMemo, useState } from 'react';
import ROPatientDiagnosisContext from './Context';
import { DEFAULT_DIAGNOSIS_STATE } from './Interface';
import ROPatientDiagnosisRouter from './DiagnosisRouter';
import ROPatientDiagnosisSidePanel from './DiagnosisSidePanel';
import { actions } from './Actions';
import { Stack } from '@mui/material';

const ROPatientDiagnosisPage = (): JSX.Element => {
  const [state, dispatch] = useReducer(actions, DEFAULT_DIAGNOSIS_STATE);
  const [refreshFlag, setRefreshFlag] = useState(true);

  const contextValue = useMemo((): any => {
    return { state, dispatch };
  }, [state, dispatch]);

  return (
    <ROPatientDiagnosisContext.Provider value={contextValue}>
      <Stack direction="row" flex="1" overflow="auto">
        <ROPatientDiagnosisRouter onDataRefresh={() => setRefreshFlag(!refreshFlag)} />
        <ROPatientDiagnosisSidePanel />
      </Stack>
    </ROPatientDiagnosisContext.Provider>
  );
};

export default ROPatientDiagnosisPage;
