// eslint-disable-next-line no-use-before-define
import { useMutation } from '@apollo/client';
import { Form, Formik, FormikProps, FormikValues } from 'formik';
import { FormContainer } from 'op-components';
import { yesNoOptions } from 'op-utils';
import { RegistrationContext } from 'op-contexts';
import React, { useContext } from 'react';
import { generatePath } from 'react-router';
import { useHistory, useParams } from 'react-router-dom';
import { AddressSectionField } from 'shared-components/components/FormikComponents';
import { Address } from 'shared-components/interfaces';
import {
  CONTINUE_BUTTON_TEXT,
  EMPTY_ADDRESS,
  HeaderSubTitle,
  HeaderTitle,
  PSO_SUMMARY_LINK,
  registrationPath,
  RETURN_TO_SUBMIT_TEXT,
  sharedFormContainerProps,
  standardField,
  StyledIndentedSection,
} from '../Helper';
import { PatientAddressRego, RegistrationFormUSIProps } from '../interfaces';
import { REMOVE_PATIENT_ADDRESS, UPDATE_PATIENT_ADDRESS } from '../RegistrationFormQueries';
import { REGISTRATION_ADDRESS_QUERY } from './AddressQueries';
import { FIELDS } from './constants';
import { generateValidationSchema } from './validation';

interface AddressFormIProps extends RegistrationFormUSIProps {
  patient: PatientAddressRego;
}

const AddressForm = (props: AddressFormIProps): JSX.Element => {
  const { updateField, patient, handleShowSaveExitConfirm, previousPageLink, isPso } = props;
  const registrationContext = useContext(RegistrationContext);
  const history = useHistory();
  const { patientId } = useParams<{ patientId: string }>();
  const goToSummary = registrationContext?.registrationSummaryVisited && !isPso;
  const refetchQueries = [{ query: REGISTRATION_ADDRESS_QUERY, variables: { id: patientId } }];

  const [updatePatientAddressMutation] = useMutation(UPDATE_PATIENT_ADDRESS, { refetchQueries: refetchQueries });
  const [removePatientAddressMutation] = useMutation(REMOVE_PATIENT_ADDRESS, { refetchQueries: refetchQueries });
  const referringPage = sessionStorage.getItem('referringPage');

  return (
    <Formik
      initialValues={patient}
      validate={(values: FormikValues) => generateValidationSchema(values)}
      validateOnChange={false}
      onSubmit={() => history.push(registrationPath(patientId, goToSummary ? 'summary' : 'emergencyContact'))}>
      {({ values, submitForm, errors, setFieldValue }: FormikProps<PatientAddressRego>) => {
        const sharedProps = sharedFormContainerProps('Address', 3, previousPageLink, !isPso);
        const formContainerProps = {
          ...sharedProps,
          submitButtonText: goToSummary ? RETURN_TO_SUBMIT_TEXT : CONTINUE_BUTTON_TEXT,
          continueDisabled: Object.keys(errors).length > 0,
          handleShowSaveExitConfirm,
          saveAndExitLink: isPso && generatePath(referringPage || PSO_SUMMARY_LINK, { patientId }),
        };

        const removeFacilityAddress = () => {
          const addressString = 'facilityAddress';
          setFieldValue(`${addressString}.country`, '');
          setFieldValue(`${addressString}.line1`, '');
          setFieldValue(`${addressString}.line2`, '');
          setFieldValue(`${addressString}.city`, '');
          setFieldValue(`${addressString}.state`, '');
          setFieldValue(`${addressString}.postcode`, '');
          removePatientAddressMutation({
            variables: {
              patientId,
              relatedName: 'facility_address',
            },
          });
        };

        const updateInHospital = (name: string, value: string | boolean | number) => {
          updateField(FIELDS.IN_HOSPITAL_BOOL.NAME, value);
          if (!value) {
            setFieldValue(FIELDS.FACILITY_NAME.NAME, '');
            setFieldValue(FIELDS.CONTACT_NUMBER.NAME, '');
            removeFacilityAddress();
          }
        };

        const handleUpdateAddress = (relatedName: string, address: Address) => {
          updatePatientAddressMutation({
            variables: {
              patientId,
              relatedName,
              line1: address?.line1 ?? '',
              line2: address?.line2 ?? '',
              city: address?.city ?? '',
              postcode: address?.postcode ?? '',
              state: address?.state ?? '',
              country: address?.country ?? '',
            },
          });
        };

        return (
          <FormContainer {...formContainerProps} submitForm={submitForm}>
            <Form>
              <HeaderTitle data-test-id="us-rego-page-title">{FIELDS.FORM_HEADING.TITLE}</HeaderTitle>
              <HeaderSubTitle data-test-id="us-rego-page-subtitle">* = required field</HeaderSubTitle>

              <AddressSectionField
                name="address"
                values={values.address || EMPTY_ADDRESS}
                updateAddress={(address: Address) => handleUpdateAddress('address', address)}
                includeMandatoryLabels
              />
              {standardField(FIELDS.IN_HOSPITAL_BOOL, updateInHospital, yesNoOptions)}

              {values.inHospitalHospiceFacility && (
                <StyledIndentedSection>
                  {standardField(FIELDS.FACILITY_NAME, updateField)}
                  {standardField(FIELDS.CONTACT_NUMBER, updateField)}

                  <AddressSectionField
                    name="facilityAddress"
                    values={values.facilityAddress || EMPTY_ADDRESS}
                    updateAddress={(address: Address) => handleUpdateAddress('facility_address', address)}
                  />
                </StyledIndentedSection>
              )}
            </Form>
          </FormContainer>
        );
      }}
    </Formik>
  );
};

export default AddressForm;
