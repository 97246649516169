// eslint-disable-next-line no-use-before-define
import { useMutation, useQuery } from '@apollo/client';
import React from 'react';
import { generatePath, useParams } from 'react-router-dom';
import { US_SSN_REGEX } from 'shared-components/utils/Regex';
import { FIELDS, SPECIFY_RACE_FIELDS } from './constants';
import DemographicsForm from './DemographicsForm';
import { DEMOGRAPHICS_QUERY, UPDATE_REGISTRATION_DEMOGRAPHIC_DETAILS } from './DemographicsQueries';

interface Props {
  exitForm: (status: boolean) => void;
}

const Demographics = (props: Props): JSX.Element => {
  const { exitForm } = props;
  const { patientId } = useParams<{ patientId: string }>();

  const { data, error } = useQuery(DEMOGRAPHICS_QUERY, {
    variables: { id: patientId },
  });
  const [updateRegistrationDemographic] = useMutation(UPDATE_REGISTRATION_DEMOGRAPHIC_DETAILS);

  if (error || !data) return <></>;
  const { patient, maritalStatusRefData, ancestryRefData, raceRefData, languageRefData, user } = data;

  const updateField = (field: string, value: string | boolean | number) => {
    if (field === FIELDS.SPOUSE_SSN.NAME) {
      if (!value.toString().match(US_SSN_REGEX)) {
        return;
      }
      // strip all non numeric characters before saving
      value = value.toString().replace(/\D/g, '');
    }

    let variables = {
      patientId,
      [field]: value,
    };

    if (field === FIELDS.RACE.NAME) {
      if (!SPECIFY_RACE_FIELDS.includes(value as string)) {
        variables[FIELDS.RACE_SPECIFIED.NAME] = '';
      }
    }

    if (field === FIELDS.SPOUSE_DOB.NAME) {
      const dobDate = value ? new Date(value as string) : null;
      const dobRawDay = dobDate ? dobDate.getDate().toString() : '';
      const dobRawMonth = dobDate ? (dobDate.getMonth() + 1).toString() : '';
      const dobRawYear = dobDate ? dobDate.getFullYear().toString() : '';

      variables = {
        patientId,
        [`${FIELDS.SPOUSE_DOB.NAME}RawYear`]: dobRawYear,
        [`${FIELDS.SPOUSE_DOB.NAME}RawMonth`]: dobRawMonth,
        [`${FIELDS.SPOUSE_DOB.NAME}RawDay`]: dobRawDay,
      };
    }
    updateRegistrationDemographic({ variables });
  };

  return (
    <DemographicsForm
      patient={patient}
      updateField={updateField}
      handleShowSaveExitConfirm={() => exitForm(true)}
      previousPageLink={generatePath(`/patient/${patientId}/registration/insurance`)}
      refData={{
        maritalStatusRefData,
        ancestryRefData,
        raceRefData,
        languageRefData,
      }}
      isPso={user.isPso}
    />
  );
};

export default Demographics;
