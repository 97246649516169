import React from 'react';
import { DrugOrderInterface } from 'op-pages/MO/interfaces';
import { DoseDay } from '../interfaces';
import { getBaseColor, Pill } from './Pill';

interface DoseCellProps {
  day: DoseDay;
  drugOrder: DrugOrderInterface;
  onPillClick?: () => void;
  justText?: boolean;
}

const DoseCell = ({ drugOrder, day, onPillClick, justText }: DoseCellProps): JSX.Element => {
  if (!day.dose) {
    return <></>;
  }

  if (drugOrder.doseIsCalculated && day.dose.doseValue === 0) {
    return (
      <Pill borderColor={'neutral'} padding="8px 16px" backgroundColor={'neutral'}>
        TBD
      </Pill>
    );
  }

  const unitArr = drugOrder.doseIsCalculated ? drugOrder.doseCalculatedUnits.split(' ') : drugOrder.doseUnit.split(' ');
  const unitObj =
    unitArr.join(' ') === 'International Units' ? (
      <span>
        {unitArr[0]}
        <br />
        {unitArr[1]}
      </span>
    ) : (
      unitArr.join(' ')
    );

  if (!day.dose.doseAdjustment || day.dose.doseAdjustment.dosePercentage === 100) {
    const inner = (
      <>
        {drugOrder.doseIsCalculated ? day.dose.prescribedDose : day.dose.doseValue}&nbsp;
        {unitObj}
      </>
    );
    if (justText) {
      return <div style={{ fontWeight: 600 }}>{inner}</div>;
    }
    return (
      <Pill borderColor={'information'} padding="8px 16px" onClick={onPillClick}>
        {inner}
      </Pill>
    );
  }

  // reduced dose pill
  return (
    <>
      <Pill borderColor={'destructive'} padding="8px 16px" onClick={onPillClick}>
        {day.dose.prescribedDose}&nbsp;
        {unitObj}
      </Pill>
      {drugOrder.drugCategory !== 'Supporting' && (
        <div style={{ color: getBaseColor('destructive').base, paddingLeft: '5px' }}>
          {Math.round(day.dose.doseAdjustment.dosePercentage)}%
        </div>
      )}
    </>
  );
};

export default DoseCell;
