// eslint-disable-next-line no-use-before-define
import { HAPatientInformationItem } from 'op-classes';
import { PageTitle } from 'op-components';
import { CurrentAppConfig } from 'op-pages/RO/Careplan/AppConfig';
import { fetchRules, ValidationKeys } from 'op-utils/HealthAssessmentValidation/HealthAssessmentValidation';
import React, { Component, Fragment } from 'react';

import { FreeTextField, HelperText, SectionField } from 'shared-components/components/FormFields';
import { StringDictionary } from 'shared-components/interfaces';
import validate from 'validate.js';
import './HAPatientInformation.scss';
import { isUs } from 'op-utils';
import _ from 'lodash';
import {
  convertCmToFeetAndInches,
  convertCmToInch,
  convertInchToCm,
  convertKgToPound,
  convertPoundToKg,
} from 'op-utils/MeasurementConverters';

const FIELD_NAMES: StringDictionary = {
  PATIENT_FULL_NAME: 'patient-full-name',
  PATIENT_DOB: 'patient-dob',
  PATIENT_DOB_DAY: 'patient-dob-day',
  PATIENT_DOB_MONTH: 'patient-dob-month',
  PATIENT_DOB_YEAR: 'patient-dob-year',
  HEIGHT: 'patient-height',
  WEIGHT: 'patient-weight',
};

const FIELD_VALIDATION_KEYS: StringDictionary = {
  HEIGHT: 'height',
  WEIGHT: 'weight',
};

const ERROR_MESSAGES = {
  HEIGHT: {
    MISSING_DATA: 'Please enter Height',
    INVALID_DATA: 'Please enter a valid Height',
  },
  WEIGHT: {
    MISSING_DATA: 'Please enter Weight',
    INVALID_DATA: 'Please enter a valid Weight',
  },
};

interface State {
  viewedFields: Set<string>;
}

interface Props {
  autosave: (updateObject: object) => Promise<void>;
  patientId: string;
  haPatientInfo: HAPatientInformationItem;
  validateOnLoad: boolean;
  isPso: boolean;
}

class HAPatientInformation extends Component<Props, State> {
  public constructor(props: Props) {
    super(props);

    this.state = {
      viewedFields: new Set(),
    };
  }

  public static getDerivedStateFromProps(props: Props, state: State): State {
    if (props.validateOnLoad) {
      const fields = Object.keys(FIELD_VALIDATION_KEYS).map((keyName: string) => {
        return FIELD_VALIDATION_KEYS[keyName];
      });
      const viewed = new Set(fields);
      return { viewedFields: viewed };
    }

    return state;
  }

  public render(): JSX.Element {
    const { haPatientInfo, isPso } = this.props;

    // Check if the dob is a valid format (DD-MM-YYYY). If not valid set day, month and year to ''
    const dob = haPatientInfo.patient.dob || '';
    const [dobYear, dobMonth, dobDay] = (dob.match(/-/g) || []).length === 2 ? dob.split('-') : ['', '', ''];

    const validationObject = this.validateObject(haPatientInfo);

    const restrictErrorMessage = (validationObject: any): string[] | undefined => {
      const validationObjectCopy = validationObject;

      if (!isPso) {
        const elementIndex = validationObject?.indexOf('MISSING_DATA');
        if (elementIndex >= 0) {
          validationObjectCopy.splice(elementIndex, 1);
        }
      }

      const validationObjectCopyLength = validationObjectCopy?.length;
      if (validationObjectCopyLength) return validationObjectCopy;
      return undefined;
    };

    const usHeightAutoSaveAndValidate = (value = 0, isFeet = false) => {
      const currentValue = convertCmToInch(parseFloat(haPatientInfo.height)) || 0;
      const currentFeet = _.round(Math.floor(currentValue / 12), 0);
      const remainderInch = Math.floor(currentValue % 12);
      const totalInches = isFeet ? value * 12 + remainderInch : currentFeet * 12 + value;
      const totalHeightInCm = convertInchToCm(totalInches).toString();

      this.autoSaveAndValidate(
        {
          id: parseInt(this.props.patientId),
          height: totalHeightInCm === '0' ? '' : totalHeightInCm,
        },
        FIELD_VALIDATION_KEYS.HEIGHT,
      );
    };

    return (
      <Fragment>
        <PageTitle title={'Patient Information'} idPrefix="ha" />
        <div id="ha-fields">
          <SectionField title={'Patient Name'} htmlFor={FIELD_NAMES.PATIENT_FULL_NAME}>
            <FreeTextField
              inputName={FIELD_NAMES.PATIENT_FULL_NAME}
              disabled={true}
              defaultValue={haPatientInfo.patient.fullName}
              inputType="text"
            />
          </SectionField>
          <HelperText helperText={CurrentAppConfig.DateFormat} idPrefix="ha-dob">
            <SectionField title={'Date of Birth'} htmlFor={FIELD_NAMES.PATIENT_DOB}>
              <div className="dob-fields">
                {isUs() ? (
                  <>
                    <FreeTextField
                      disabled={true}
                      inputName={FIELD_NAMES.PATIENT_DOB_MONTH}
                      maxLength={2}
                      inputType="text"
                      defaultValue={dobMonth}
                    />
                    <FreeTextField
                      disabled={true}
                      inputName={FIELD_NAMES.PATIENT_DOB_DAY}
                      maxLength={2}
                      inputType="text"
                      defaultValue={dobDay}
                    />
                    <FreeTextField
                      disabled={true}
                      inputName={FIELD_NAMES.PATIENT_DOB_YEAR}
                      maxLength={4}
                      inputType="text"
                      defaultValue={dobYear}
                    />
                  </>
                ) : (
                  <>
                    <FreeTextField
                      disabled={true}
                      inputName={FIELD_NAMES.PATIENT_DOB_DAY}
                      maxLength={2}
                      inputType="text"
                      defaultValue={dobDay}
                    />
                    <FreeTextField
                      disabled={true}
                      inputName={FIELD_NAMES.PATIENT_DOB_MONTH}
                      maxLength={2}
                      inputType="text"
                      defaultValue={dobMonth}
                    />
                    <FreeTextField
                      disabled={true}
                      inputName={FIELD_NAMES.PATIENT_DOB_YEAR}
                      maxLength={4}
                      inputType="text"
                      defaultValue={dobYear}
                    />
                  </>
                )}
              </div>
            </SectionField>
          </HelperText>
          <SectionField title={'Height'} htmlFor={FIELD_NAMES.HEIGHT}>
            {isUs() ? (
              <>
                <FreeTextField
                  inputName={`${FIELD_NAMES.HEIGHT}-ft`}
                  defaultValue={convertCmToFeetAndInches(haPatientInfo.height).heightFt}
                  inputType="number"
                  sideLabel={'Feet'}
                  inputProps={{ min: 0 }}
                  maxLength={200}
                  onBlur={(e): void => {
                    let val = parseFloat(e.target.value);
                    if (val < 0) {
                      val = 0;
                      e.target.value = '0';
                    }
                    usHeightAutoSaveAndValidate(val || 0, true);
                  }}
                  errors={validationObject?.height}
                  errorMessages={ERROR_MESSAGES.HEIGHT}
                  showError={false}
                  inputStyle={{ width: '100px' }}
                />
                <FreeTextField
                  inputName={`${FIELD_NAMES.HEIGHT}-in`}
                  defaultValue={convertCmToFeetAndInches(haPatientInfo.height).heightIn}
                  inputType="number"
                  sideLabel={'Inches'}
                  inputProps={{ max: 11, min: 0 }}
                  maxLength={200}
                  onBlur={(e): void => {
                    let val = parseFloat(e.target.value);
                    if (val > 11) {
                      val = 11;
                      e.target.value = '11';
                    } else if (val < 0) {
                      val = 0;
                      e.target.value = '0';
                    }
                    usHeightAutoSaveAndValidate(val || 0);
                  }}
                  errors={validationObject?.height}
                  errorMessages={ERROR_MESSAGES.HEIGHT}
                  inputStyle={{ width: '100px', marginLeft: '8px' }}
                />
              </>
            ) : (
              <FreeTextField
                inputName={FIELD_NAMES.HEIGHT}
                defaultValue={haPatientInfo.height}
                inputType="number"
                sideLabel={'cm'}
                inputProps={{ max: 300, min: 0 }}
                maxLength={200}
                onBlur={(e): void => {
                  this.autoSaveAndValidate(
                    {
                      id: parseInt(this.props.patientId),
                      height: e.target.value,
                    },
                    FIELD_VALIDATION_KEYS.HEIGHT,
                  );
                }}
                errors={restrictErrorMessage(validationObject?.height)}
                errorMessages={ERROR_MESSAGES.HEIGHT}
              />
            )}
          </SectionField>
          <SectionField title={'Weight'} htmlFor={FIELD_NAMES.WEIGHT}>
            <FreeTextField
              inputName={FIELD_NAMES.WEIGHT}
              defaultValue={
                isUs() ? convertKgToPound(parseFloat(haPatientInfo.weight), 2).toString() : haPatientInfo.weight
              }
              inputType="number"
              sideLabel={isUs() ? 'Pounds' : 'kg'}
              inputProps={{ min: 3 }}
              maxLength={200}
              onBlur={(e): void => {
                this.autoSaveAndValidate(
                  {
                    id: parseInt(this.props.patientId),
                    weight: isUs() ? this.getNewWeightInKg(e.target.value) : e.target.value,
                  },
                  FIELD_VALIDATION_KEYS.WEIGHT,
                );
              }}
              errors={restrictErrorMessage(validationObject?.weight)}
              errorMessages={ERROR_MESSAGES.WEIGHT}
              inputStyle={isUs() ? { width: '100px' } : undefined}
            />
          </SectionField>
        </div>
      </Fragment>
    );
  }

  private getNewWeightInKg = (weightInPound: string) => {
    if (weightInPound === '') return '';

    return convertPoundToKg(parseFloat(weightInPound)).toString();
  };

  private validateObject = (haPatientInfo: HAPatientInformationItem): any => {
    const validationRules = fetchRules(ValidationKeys.Info, this.state.viewedFields);

    // Disable pre-appending of argument name to error messages
    const options = { fullMessages: false };

    return validate(haPatientInfo, validationRules, options);
  };

  private autoSaveAndValidate = async (updateObject: object, validationKey: string): Promise<void> => {
    const { autosave } = this.props;

    // Save the data
    autosave(updateObject).then(() => {
      // Get viewed fields
      const viewedFields = [...this.state.viewedFields];

      // Add updated field
      viewedFields.push(validationKey);

      // Update with new validation set
      this.setState({ viewedFields: new Set(viewedFields) });
    });
  };
}

//@ts-ignore
export default HAPatientInformation;
