import React from 'react';
import { useMutation } from '@apollo/client';
import { DISCARD_OUTCOME, GET_OUTCOMES_BY_DIAGNOSIS } from './Queries';
import { OutcomeInterface } from './Interfaces';
import { WarningOutlined as WarningOutlinedIcon } from '@mui/icons-material';
import { StandardDialog } from 'shared-components/components';
import { Stack, Typography } from '@mui/material';

interface Props {
  open: boolean;
  setOpen: (value: boolean) => void;
  outcome: OutcomeInterface;
  handleBack: () => void;
  patientId: string;
}

const DiscardModal = ({ open, setOpen, outcome, handleBack, patientId }: Props): JSX.Element => {
  const [saveOutcomeData] = useMutation(DISCARD_OUTCOME, {
    onCompleted: () => {
      setOpen(false);
      handleBack();
    },
    refetchQueries: [{ query: GET_OUTCOMES_BY_DIAGNOSIS, variables: { patientId: patientId } }],
  });

  const handleClose = () => {
    setOpen(false);
  };

  const discard = () => {
    saveOutcomeData({
      variables: {
        outcomeId: outcome.id,
      },
    });
  };

  return (
    <StandardDialog
      open={open}
      onClose={handleClose}
      title="Discard Outcome"
      titleIcon={<WarningOutlinedIcon color="warning" />}
      onSubmit={discard}
      submitText="Discard Outcome"
      destructiveSubmit>
      <Stack>
        <Typography>You are about to discard the following outcome:</Typography>
        <Typography>{outcome?.diagnosisName}</Typography>
        <Typography>{outcome?.timePoint} years follow up</Typography>
      </Stack>
    </StandardDialog>
  );
};
export default DiscardModal;
