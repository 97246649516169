import React from 'react';
import { styled } from '@mui/system';

import { Region } from 'shared-components/enums';
import { ErrorInfo } from 'shared-components/components/FormFields';
const REACT_APP_REGION = import.meta.env.REACT_APP_REGION;

interface Props {
  validationErrors?: any;
  mandatoryFields: string[];
}

interface keyNames {
  firstName: string;
  middleName: string;
  lastName: string;
  namePrefix: string;
  gender: string;
  dobRaw: string;
  primaryCenter: string;
  primaryPhone: string;
  secondaryPhone: string;
  email: string;
  residentialAddressLine1: string;
  residentialAddressCity: string;
  residentialAddressState: string;
  residentialAddressCountry: string;
  residentialAddressPostcode: string;
  'emergencyContact.firstName': string;
  'emergencyContact.lastName': string;
  'emergencyContact.relationship': string;
  'emergencyContact.mobilePhoneNumber': string;
  'emergencyContact.homePhoneNumber': string;
  'emergencyContact.authorisedForEnquiries': string;
  'emergencyContact.supportPerson': string;
  'emergencyContact.email': string;
  generalPractitioner: string;
  referringSurgeon: string;
  oncologist: string;
  idb: string;
  nhsOptions: string;
  payor: string;
  registrationReason: string;
  registrationReasonText: string;
  apptClash: string;
  countryOfBirth: string;
  languageAtHome: string;
  heritage: string;
  maritalStatus: string;
  attachments: string;
  attachmentAcknowledged: string;
}

const StyledErrorIcon = styled(ErrorInfo)`
  width: 13px;
`;

const TextContainer = styled('div')`
  margin-bottom: 24px;
`;

const keyNames: any = {
  firstName: 'First name',
  middleName: 'Middle name',
  lastName: 'Last name',
  namePrefix: 'Name prefix',
  gender: 'Gender',
  dobRaw: 'Date of birth',
  primaryCenter: 'Preferred centre',
  primaryPhone: 'Home or mobile phone number',
  secondaryPhone: 'Home or mobile phone number',
  email: 'Email',
  residentialAddressLine1: 'Address line 1',
  residentialAddressCity: 'Town/City',
  residentialAddressState: 'County',
  residentialAddressCountry: 'Country',
  residentialAddressPostcode: 'Post code',
  'emergencyContact.firstName': 'Emergency contact first name',
  'emergencyContact.lastName': 'Emergency contact last name',
  'emergencyContact.relationship': 'Emergency contact relationship',
  'emergencyContact.mobilePhoneNumber': 'Emergency contact home or mobile phone number',
  'emergencyContact.homePhoneNumber': 'Emergency contact home or mobile phone number',
  'emergencyContact.authorisedForEnquiries': 'Emergency contact authorised for enquiries',
  'emergencyContact.supportPerson': 'Emergency contact identified as support',
  'emergencyContact.email': 'Emergency contact email',
  generalPractitioner: 'General practitioner',
  referringSurgeon: 'Referring surgeon',
  oncologist: 'Responsible clinician',
  idb: 'NHS ID number',
  nhsOptions: 'Reason NHS ID not provided',
  payor: 'Insurance company',
  registrationReason: 'Registration reason',
  registrationReasonText: 'Registration reason',
  apptClash: 'Selected appointment',
  countryOfBirth: 'Country of birth',
  languageAtHome: 'Language spoken at home',
  heritage: 'Aboriginal or Torres Strait Islander',
  maritalStatus: 'Marital status',
  attachments: 'Document type',
  attachmentAcknowledged: 'Document type acknowledgement',
};

const ModalValidationError = ({ validationErrors, mandatoryFields }: Props): JSX.Element => {
  const region = REACT_APP_REGION;
  const isAU = region === Region.AU;

  // This removes duplicate validation errors for person and emergency phone numbers
  if (validationErrors && validationErrors.primaryPhone && validationErrors.secondaryPhone) {
    delete validationErrors.secondaryPhone;
  }
  if (
    validationErrors &&
    validationErrors['emergencyContact.homePhoneNumber'] &&
    validationErrors['emergencyContact.mobilePhoneNumber']
  ) {
    delete validationErrors['emergencyContact.mobilePhoneNumber'];
  }

  const renderError = (fieldDisplayName: string): JSX.Element => {
    return <StyledErrorIcon errors={[fieldDisplayName]} key={fieldDisplayName} />;
  };

  const missingMandatoryFields = mandatoryFields.filter((value: string) =>
    Object.keys(validationErrors).includes(value),
  );

  const excessFields = missingMandatoryFields.length - 5;

  const orderedValidation = generateOrderedValidation(keyNames, validationErrors);

  return (
    <div className="modal-submit-validation-error-body" data-testid="modal-submit-validation-error-body">
      {!isAU && (
        <>
          {validationErrors &&
            Object.keys(validationErrors).length > 5 &&
            'The form contains multiple errors. Please fix and try again'}
          {validationErrors &&
            Object.keys(validationErrors).length < 6 &&
            'Please complete the below required fields for submission and ensure that there are no errors in all fields.'}

          {validationErrors &&
            Object.keys(validationErrors).length < 6 &&
            Object.keys(validationErrors).map((key: string): JSX.Element | undefined => {
              if (keyNames[key]) return renderError(keyNames[key]);
            })}
        </>
      )}
      {isAU && validationErrors && (
        <>
          <TextContainer>
            Please answer all mandatory questions before submitting. Mandatory questions are marked with an{' '}
            <strong>asterisk*</strong>. Use the left menu or Review and Submit page to return to a section.
          </TextContainer>

          {missingMandatoryFields.length > 0 &&
            orderedValidation.slice(0, 5).map((key: string): JSX.Element | undefined => {
              return renderError(key);
            })}
          {missingMandatoryFields.length > 5 && (
            <StyledErrorIcon
              errors={[`And ${excessFields} more incomplete ${excessFields > 1 ? 'fields' : 'field'}...`]}
            />
          )}
        </>
      )}
    </div>
  );
};

const generateOrderedValidation = (keyNames: keyNames, validationErrors: any) => {
  return Object.keys(keyNames)
    .filter((key: string) => Object.keys(validationErrors).includes(key))
    .map((key: string) => keyNames[key as keyof keyNames]);
};

export default ModalValidationError;
