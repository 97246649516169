import { useMutation } from '@apollo/client';
import { ModalDeleteForm, PatientCard } from 'op-components';
import { default as React, useState } from 'react';
import { HeaderTitle } from '../common';
import ManagementPlanRow from '../components/ManagementPlanRow';
import ManagementPlanSideDrawer from '../components/ManagementPlanSideDrawer';
import ManagementPlanSidePanel from '../components/ManagementPlanSidePanel';
import {
  ALL_MANAGEMENT_PLAN_QUERY,
  ARCHIVE_MANAGEMENT_PLAN,
  DELETE_MANAGEMENT_PLAN,
  DUPLICATE_MANAGEMENT_PLAN,
  SUBMITTED_MANAGEMENT_PLAN_QUERY,
} from '../graphql/ManagementPlanQueries';
import { Stack } from '@mui/material';

interface Props {
  patient: any;
  plans: any;
}

const ManagementSearchPage = (props: Props): JSX.Element => {
  const containerComponentId = 'mx-search-page-container';
  const { patient, plans } = props;
  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const [showArchiveModal, setShowArchiveModal] = useState(false);
  const [formId, setFormId] = useState('');

  const [duplicateManagementPlan] = useMutation(DUPLICATE_MANAGEMENT_PLAN, {
    awaitRefetchQueries: true,
    refetchQueries: [
      { query: ALL_MANAGEMENT_PLAN_QUERY, variables: { patientId: patient.id } },
      { query: SUBMITTED_MANAGEMENT_PLAN_QUERY, variables: { patientId: patient.id } },
    ],
  });
  const [deleteManagementPlan] = useMutation(DELETE_MANAGEMENT_PLAN, {
    awaitRefetchQueries: true,
    refetchQueries: [{ query: ALL_MANAGEMENT_PLAN_QUERY, variables: { patientId: patient.id } }],
  });
  const [archiveManagementPlan] = useMutation(ARCHIVE_MANAGEMENT_PLAN, {
    awaitRefetchQueries: true,
    refetchQueries: [
      { query: ALL_MANAGEMENT_PLAN_QUERY, variables: { patientId: patient.id } },
      { query: SUBMITTED_MANAGEMENT_PLAN_QUERY, variables: { patientId: patient.id } },
    ],
  });

  // Split plans into active and non-active for display purposes
  const activePlans = plans?.filter((plan: any) => !plan.archivedAt);
  const archivedPlans = plans?.filter((plan: any) => plan.archivedAt);
  const orderedPlans = activePlans?.concat(archivedPlans);

  return (
    <>
      <Stack sx={{ height: '100%', width: '100%', padding: '8px' }} id={containerComponentId}>
        <PatientCard id={patient.id} simple />
        <Stack style={{ height: '100%' }} direction="row">
          <Stack
            sx={{
              height: '100%',
              width: '100%',
              padding: '16px',
              boxShadow: '0 .5rem 1rem #00000026 !important',
            }}>
            <HeaderTitle data-test-id="management-search-header">Management plans</HeaderTitle>
            {orderedPlans?.length ? (
              orderedPlans?.map((plan: any, index: number) => (
                <ManagementPlanRow
                  key={`management-plan-${index}`}
                  plan={plan}
                  index={index}
                  handleDuplicate={(formId: string) => duplicateManagementPlan({ variables: { formId } })}
                  handleDelete={(formId: string) => {
                    setFormId(formId);
                    setShowDeleteModal(true);
                  }}
                  handleArchive={(formId: string) => {
                    setFormId(formId);
                    setShowArchiveModal(true);
                  }}
                  patient={patient}
                />
              ))
            ) : (
              <div style={{ padding: '16px 0' }} data-test-id="no-mx-plan-status">
                No management plan has been created.
              </div>
            )}
          </Stack>
          <ManagementPlanSideDrawer width={580} style={{ paddingTop: '8px' }}>
            <ManagementPlanSidePanel showDT={false} />
          </ManagementPlanSideDrawer>
        </Stack>
      </Stack>

      {showDeleteModal && (
        <ModalDeleteForm
          isOpen={showDeleteModal}
          title="Discard Wellbeing Management Plan"
          setIsOpen={setShowDeleteModal}
          deleteFormMutation={() => deleteManagementPlan({ variables: { formId } })}
          discard={true}
        />
      )}
      {showArchiveModal && (
        <ModalDeleteForm
          isOpen={showArchiveModal}
          title="Deactivate Wellbeing Management Plan"
          setIsOpen={setShowArchiveModal}
          deleteFormMutation={() => archiveManagementPlan({ variables: { formId } })}
          discard={false}
        />
      )}
    </>
  );
};

export default ManagementSearchPage;
