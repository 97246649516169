import { MainLayout } from 'op-pages/PX/Components';
import { memo } from 'react';
import PatientDetailsContent from './PatientDetailsContent';

const PatientDetails = (): JSX.Element => {
  return (
    <MainLayout>
      <PatientDetailsContent />
    </MainLayout>
  );
};

export default memo(PatientDetails);
