// eslint-disable-next-line no-use-before-define
import { gql } from '@apollo/client';
import { Query } from '@apollo/client/react/components';
import { WithApolloClient, withApollo } from '@apollo/client/react/hoc';
import { Component, Fragment } from 'react';

import { RouteComponentProps } from 'react-router';

import { ExtendLock } from 'op-components';
import { OPUser, Patient, UKPatientDemographics } from 'op-interfaces';
import { LoadingSpinner } from 'shared-components/components';
import { SavingStatus } from 'shared-components/enums';
import { ListData } from 'shared-components/interfaces';

import withRegistrationForm, { GET_APOLLO_CACHE, WithRegistrationForm } from '../RegistrationForm';
import UKRegistrationDemographics from './UKRegistrationDemographics';

export const PATIENT_AND_USER_DETAILS_QUERY = gql`
  query PatientAndUserDetails($id: ID!) {
    patient(id: $id) {
      id
      ida
      idb
      isProd
      ethnicity
      religion
      maritalStatus
      firstName
      lastName
      dob
      address {
        id
        ukFormattedAddress
      }
      lock {
        lockedBy
        readOnly
        lockedByName
      }
    }
    user {
      id
      isPso
    }
    genderRefData: listData(category: "gender") {
      id
      name
    }
    ethnicityRefData: listData(category: "ethnicity") {
      id
      name
    }
    religionRefData: listData(category: "religion") {
      id
      name
    }
    maritalStatusRefData: listData(category: "maritalStatus") {
      id
      name
    }
  }
`;

interface State {
  saveStatus: SavingStatus;
}

interface PatientAndUserDetailsQueryData {
  patient: UKPatientDemographics;
  genderRefData: ListData[];
  ethnicityRefData: ListData[];
  religionRefData: ListData[];
  maritalStatusRefData: ListData[];
  user: OPUser;
}

interface Props
  extends WithApolloClient<{}>,
    WithRegistrationForm,
    RouteComponentProps<{
      patientId?: string;
    }> {}

class UKRegistrationDemographicsApollo extends Component<Props, State> {
  public constructor(props: Props) {
    super(props);
    this.state = { saveStatus: SavingStatus.SAVED };
    // Get the saving status and set it
    this.getPendingSaveCount().then((result): void => {
      const currentPendingSaveCount = result.currentPendingSaveCount;
      const saveErrorCount = result.saveErrorCount;
      this.state = {
        saveStatus: this.props.getSaveStatus(currentPendingSaveCount, saveErrorCount),
      };
    });
  }

  public render(): JSX.Element {
    const { match, showModalIfLocked } = this.props;
    const { patientId } = match.params;
    return (
      <Query<PatientAndUserDetailsQueryData>
        query={PATIENT_AND_USER_DETAILS_QUERY}
        variables={{ id: patientId }}
        onCompleted={(data: { patient: Patient }): void => {
          showModalIfLocked(data);
        }}>
        {({ loading, data, error }): JSX.Element => {
          if (loading) {
            return <LoadingSpinner />;
          }

          if (data && data.patient) {
            // We have a patient - render the form
            const modifiedPatient = { ...data.patient };

            return (
              <Fragment>
                <ExtendLock accessPatientId={data.patient.id} />
                <UKRegistrationDemographics
                  autosave={this.autosave}
                  patient={modifiedPatient}
                  genderRefData={data.genderRefData}
                  religionRefData={data.religionRefData}
                  ethnicityRefData={data.ethnicityRefData}
                  maritalStatusRefData={data.maritalStatusRefData}
                  saveStatus={this.state.saveStatus}
                  isPso={data.user.isPso || false}
                />
              </Fragment>
            );
          }

          // If we get here there is an error
          if (error) return <div>{`Error loading: ${error}`}</div>;
          return <div />;
        }}
      </Query>
    );
  }

  /**
   * Autosave a field and it's values to the database
   * @param patient
   * @param key
   * @param value
   * @param type
   */
  private autosave = async (
    patient: UKPatientDemographics,
    key: string,
    value: string,
    type = 'String',
  ): Promise<void> => {
    const client = this.props.client;
    const update = {
      key,
      value,
      type,
    };

    // Get the pending save count and increment it by 1
    const saveCount = await this.getPendingSaveCount();
    let currentPendingSaveCount = saveCount.currentPendingSaveCount + 1;
    let saveErrorCount = saveCount.saveErrorCount;

    client &&
      client.writeQuery({
        query: gql`
          query {
            pendingSaveCount
          }
        `,
        data: {
          pendingSaveCount: currentPendingSaveCount,
        },
      });
    this.setState({ saveStatus: this.props.getSaveStatus(currentPendingSaveCount, saveErrorCount) });

    // Save the updated data. Once done deincrement the pending save count. If any erros saving increment the saveErrorCount
    client
      //@ts-ignore
      ?.mutate(this.props.getPatientMutation(patient, [update]))
      .then((result): void => {
        if (result.data.updatePatient.errors) {
          saveErrorCount++;
        }
      })
      .catch((): void => {
        saveErrorCount++;
      })
      .finally(async (): Promise<void> => {
        // Reload the save count incase it was set elsewhere during the run of this function
        const saveCount = await this.getPendingSaveCount();
        currentPendingSaveCount = saveCount.currentPendingSaveCount - 1;
        client &&
          client.writeQuery({
            query: gql`
              query {
                pendingSaveCount
                saveErrorCount
              }
            `,
            data: {
              pendingSaveCount: currentPendingSaveCount,
              saveErrorCount: saveErrorCount,
            },
          });
        this.setState({ saveStatus: this.props.getSaveStatus(currentPendingSaveCount, saveErrorCount) });
      });
  };

  private getPendingSaveCount = async (): Promise<{ currentPendingSaveCount: number; saveErrorCount: number }> => {
    const client = this.props.client;
    let currentPendingSaveCount = 0;
    let saveErrorCount = 0;
    try {
      const data = await client?.query({ query: GET_APOLLO_CACHE, variables: {} });
      currentPendingSaveCount = data?.data.pendingSaveCount;
      saveErrorCount = data?.data.saveErrorCount;
      return {
        currentPendingSaveCount: currentPendingSaveCount,
        saveErrorCount: saveErrorCount,
      };
    } catch (error) {
      throw error;
    }
  };
}

const apolloComponent = withApollo<Props>(UKRegistrationDemographicsApollo);
const component = withRegistrationForm(apolloComponent);
export default component;
