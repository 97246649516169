import _ from 'lodash';

/**
 * Constants and functions below SHOULD BE similar to the
 * functions found in django/oncology_portal/oncology/utils.py
 */

const CM_INCH_CONVERSION_FACTOR = 2.54;
const KG_LB_CONVERSION_FACTOR = 2.20462262185;
const LB_KG_CONVERSION_FACTOR = 0.45359237;
const DEFAULT_PRECISION_TO_DB = 6;

// Height
export const convertCmToInch = (value: number, precision = DEFAULT_PRECISION_TO_DB): number => {
  return _.round(value / CM_INCH_CONVERSION_FACTOR, precision);
};

export const convertInchToCm = (value: number, precision = DEFAULT_PRECISION_TO_DB): number => {
  return _.round(value * CM_INCH_CONVERSION_FACTOR, precision);
};

// Weight
export const convertKgToPound = (value: number, precision = DEFAULT_PRECISION_TO_DB): number => {
  return _.round(value * KG_LB_CONVERSION_FACTOR, precision);
};

export const convertPoundToKg = (value: number, precision = DEFAULT_PRECISION_TO_DB): number => {
  return _.round(value * LB_KG_CONVERSION_FACTOR, precision);
};

interface FeetAndInches {
  heightFt: string;
  heightIn: string;
}

export const convertCmToFeetAndInches = (patientHeightInCm: string): FeetAndInches => {
  const parsedPatientHeightInCm = parseFloat(patientHeightInCm);

  if (isNaN(parsedPatientHeightInCm)) {
    return {
      heightFt: '',
      heightIn: '',
    };
  }
  const parsedHeight = convertCmToInch(parseFloat(patientHeightInCm));
  const parsedHeightFt = _.round(Math.floor(parsedHeight / 12), 0).toString();
  const parsedHeightIn = _.round(parsedHeight % 12, 2).toString();

  return {
    heightFt: parsedHeightFt,
    heightIn: parsedHeightIn,
  };
};
