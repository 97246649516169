import { useCallback, useContext } from 'react';
import { useMutation } from '@apollo/client';
import { CREATE_DIAGNOSIS, GET_DIAGNOSIS } from './Queries';
import { CREATE_CAREPLAN, UPDATE_CAREPLAN_DIAGNOSIS } from '../Queries';
import { useRouteMatch } from 'react-router-dom';
import { DiagnosisActionType, DiagnosisRouterParams, DiagnosisType } from './Interface';
import ROPatientDiagnosisContext from './Context';
import { ROPatientContext } from 'op-pages/RO/PatientSummary/context';

interface DiagnosisWorflowInterface {
  createPrimaryDiagnosisWithContext: (callback: (newDiagnosisId: string) => void) => void;
  createMetastasisDiagnosisWithContext: (callback: (newDiagnosisId: string) => void) => void;
  createRelatedDiagnosisWithContext: (callback: (newDiagnosisId: string) => void) => void;
  createCareplanAndPrimaryDiagnosis: (callback: (careplanId: string, newDiagnosisId: string) => void) => void;
  createCareplanAndMetastasisDiagnosis: (callback: (careplanId: string, newDiagnosisId: string) => void) => void;
  updateCareplanDiagnosis: (careplanId: string, diagnosisId: string) => void;
  hasExistingPrimaryDiagnosis: () => boolean;
  hasExistingMetastasisDiagnosis: () => boolean;
}

const useDiagnosisWorkflow = (): DiagnosisWorflowInterface => {
  const match = useRouteMatch<DiagnosisRouterParams>();
  const { id: patientId, careplanId, diagnosisId } = match.params;
  const [createDiagnosisMutation] = useMutation(CREATE_DIAGNOSIS);
  const [createCareplanMutation] = useMutation(CREATE_CAREPLAN);
  const [updateCareplanMutation] = useMutation(UPDATE_CAREPLAN_DIAGNOSIS, {
    refetchQueries: [{ query: GET_DIAGNOSIS, variables: { diagnosisId } }],
    awaitRefetchQueries: true,
  });
  const { state: diagnosisState, dispatch } = useContext(ROPatientDiagnosisContext);
  const { patientDetails } = useContext(ROPatientContext);

  const createDiagnosis = useCallback(
    (isPrimaryDiagnosis: boolean, callback: (x: any) => void) => {
      createDiagnosisMutation({
        variables: {
          patientId: patientId,
          diagnosisInput: { isPrimaryDiagnosis: isPrimaryDiagnosis },
        },
      }).then(({ data }) => {
        if (callback) {
          callback(data.createDiagnosis.diagnosis);
        }
      });
    },
    [createDiagnosisMutation, patientId],
  );

  const createPrimaryDiagnosisWithContext = useCallback(
    (callback: (id: string) => void) => {
      createDiagnosis(true, (newDiagnosis: DiagnosisType) => {
        dispatch({
          type: DiagnosisActionType.SET_DIAGNOSIS,
          payload: { diagnosis: { ...newDiagnosis } },
        });
        if (callback) {
          callback(newDiagnosis.id);
        }
      });
    },
    [createDiagnosis, dispatch],
  );

  const createMetastasisDiagnosisWithContext = useCallback(
    (callback: (id: string) => void) => {
      createDiagnosis(false, (newDiagnosis: DiagnosisType) => {
        dispatch({
          type: DiagnosisActionType.SET_DIAGNOSIS,
          payload: { diagnosis: { ...newDiagnosis } },
        });
        if (callback) {
          callback(newDiagnosis.id);
        }
      });
    },
    [createDiagnosis, dispatch],
  );

  const createRelatedDiagnosisWithContext = useCallback(
    (callback: (id: string) => void) => {
      createDiagnosis(true, (newDiagnosis: DiagnosisType) => {
        dispatch({
          type: DiagnosisActionType.SET_DIAGNOSIS,
          payload: { diagnosis: { ...diagnosisState.diagnosis, relatedPrimaryDiagnosis: { ...newDiagnosis } } },
        });
        updateCareplanMutation({
          variables: {
            careplanId: careplanId,
            diagnosisId: diagnosisId,
            relatedPrimaryDiagnosisId: newDiagnosis.id,
          },
        }).then(() => {
          if (callback) {
            callback(newDiagnosis.id);
          }
        });
      });
    },
    [createDiagnosis, diagnosisState, updateCareplanMutation, diagnosisId, dispatch],
  );

  const createCareplan = useCallback(
    (newDiagnosisId: string, isPrimaryDiagnosis: boolean, callback: (id: string) => void) => {
      createCareplanMutation({
        variables: {
          patientId: patientId,
          diagnosisId: newDiagnosisId,
          isPrimDiagnosisOwner: isPrimaryDiagnosis,
          isMetsDiagnosisOwner: !isPrimaryDiagnosis,
        },
      }).then(({ data }) => {
        if (callback) {
          callback(data.createCareplan.careplan.id);
        }
      });
    },
    [createCareplanMutation, patientId],
  );

  const createCareplanAndPrimaryDiagnosis = useCallback(
    (callback: (x: string, y: string) => void): void => {
      createPrimaryDiagnosisWithContext((newDiagnosisId: string) => {
        createCareplan(newDiagnosisId, true, (newCareplanId: string) => {
          if (callback) {
            callback(newCareplanId, newDiagnosisId);
          }
        });
      });
    },
    [createCareplan, createPrimaryDiagnosisWithContext],
  );

  const createCareplanAndMetastasisDiagnosis = useCallback(
    (callback: (x: string, y: string) => void) => {
      createMetastasisDiagnosisWithContext((newDiagnosisId: string) => {
        createCareplan(newDiagnosisId, false, (newCareplanId: string) => {
          if (callback) {
            callback(newCareplanId, newDiagnosisId);
          }
        });
      });
    },
    [createCareplan, createMetastasisDiagnosisWithContext],
  );

  const updateCareplanDiagnosis = useCallback(
    (careplanId: string, newDiagnosisId: string) => {
      updateCareplanMutation({
        variables: {
          careplanId: careplanId,
          diagnosisId: newDiagnosisId,
        },
      });
    },
    [updateCareplanMutation],
  );

  const hasExistingPrimaryDiagnosis = useCallback((): boolean => {
    return patientDetails?.carepathPatientDiagnosis.some(
      (diagnosis: DiagnosisType) => diagnosis.isPrimaryDiagnosis && diagnosis.emrDiagnosisKey !== null,
    );
  }, [patientDetails]);

  const hasExistingMetastasisDiagnosis = useCallback((): boolean => {
    return patientDetails?.carepathPatientDiagnosis.some(
      (diagnosis: DiagnosisType) => !diagnosis.isPrimaryDiagnosis && diagnosis.emrDiagnosisKey !== null,
    );
  }, [patientDetails]);

  return {
    createPrimaryDiagnosisWithContext,
    createMetastasisDiagnosisWithContext,
    createCareplanAndPrimaryDiagnosis,
    createCareplanAndMetastasisDiagnosis,
    createRelatedDiagnosisWithContext,
    updateCareplanDiagnosis,
    hasExistingPrimaryDiagnosis,
    hasExistingMetastasisDiagnosis,
  };
};

export default useDiagnosisWorkflow;
