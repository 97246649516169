import React from 'react';
import { Badge } from 'shared-components/components';
import { CardTitle, CardBody, CardBodyGroup, CardText, BubbleTitle } from './styles';
import CONSTANTS from './constants';
import { useTheme } from '@mui/material';
const { SEVERITY, NOTES, NO_ALLERGIES } = CONSTANTS;

const AllergiesCard = ({ name, allergies }: { name: string; allergies: any }): JSX.Element => {
  const filteredAllergies = allergies.filter((allergy: any) => allergy.status === 'Active');
  const theme = useTheme();
  const isNoKnownAllergy = filteredAllergies?.some(
    (allergy: any): boolean =>
      allergy.name && ['no known drug allergies', 'no known allergies'].includes(allergy.name.toLowerCase()),
  );
  return (
    <div data-testid="allergiesCard">
      <CardTitle>
        {name}{' '}
        <Badge
          $bgColor={
            isNoKnownAllergy
              ? theme.palette.grey[600]
              : filteredAllergies?.length !== 0
              ? theme.palette.error.main
              : theme.palette.warning.dark
          }
          data-testid="allergiesBadge">
          {isNoKnownAllergy ? 'N' : filteredAllergies.length > 0 ? filteredAllergies.length : '?'}
        </Badge>
      </CardTitle>
      <CardBody>
        {filteredAllergies.length ? (
          filteredAllergies.map((allergy: any, index: number) => (
            <CardBodyGroup key={allergy.name}>
              <BubbleTitle>
                {index + 1}. {allergy.name}
              </BubbleTitle>
              <CardText>
                {SEVERITY}: <b>{allergy.severity}</b>
              </CardText>
              <CardText>
                {NOTES}: <b>{allergy.reaction}</b>
              </CardText>
            </CardBodyGroup>
          ))
        ) : (
          <CardBodyGroup>
            <b>{NO_ALLERGIES}</b>
          </CardBodyGroup>
        )}
      </CardBody>
    </div>
  );
};

export default AllergiesCard;
