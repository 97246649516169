// eslint-disable-next-line no-use-before-define
import React from 'react';

import './PatientIdentifierBar.scss';

import { MandatoryPatient } from 'op-interfaces/PatientInterfaces';
import { ListData } from 'shared-components/interfaces';
import { DateTimeConverter, calculateMomentAge } from 'shared-components/utils';
import PhotoWidget from 'shared-components/components/UIFormComponents/PhotoWidget';
import moment from 'moment';
import { styled } from '@mui/system';
import { genderTitle } from 'op-utils/PatientHelper';
import { Region } from 'shared-components/enums';

interface Props {
  patient: MandatoryPatient;
  genderRefData?: ListData[];
  includePhoto?: boolean;
  includeGenderAbrev?: boolean;
}

const StyledPhoto = styled('div')`
  margin-right: 10px;
`;

const PatientIdentifierBar = (props: Props): JSX.Element => {
  const { patient, includePhoto, includeGenderAbrev } = props;

  const dob = DateTimeConverter.getFormattedDateAsDDMonthYYYY(patient.dob, import.meta.env.REACT_APP_REGION as Region);

  return (
    <div className="identifier-bar-container">
      {includePhoto && (
        <StyledPhoto>
          <PhotoWidget enableOverlay={true} size={'sm'} />
        </StyledPhoto>
      )}
      <div id="header-detail-1" className="identifier-bar-name">
        {patient.firstName} {patient.lastName}
        {includeGenderAbrev && patient.patientGender && genderTitle(patient.patientGender.toString())}
      </div>
      <div className="identifier-bar-stack">
        <p>Patient ID</p>
        <strong id="header-detail-2">{patient.ida}</strong>
      </div>
      <div className="identifier-bar-stack">
        <p>Date of birth</p>
        <strong id="header-detail-3">{`${dob} (age ${calculateMomentAge(moment(patient.dob))})`}</strong>
      </div>
      <div className="identifier-bar-stack">
        <p>Patient Address</p>
        <strong id="header-detail-4">{patient.address ? patient.address.formattedAddress : '-'}</strong>
      </div>
    </div>
  );
};

export default PatientIdentifierBar;
