// eslint-disable-next-line no-use-before-define
import React from 'react';
import Pagination from '@mui/material/Pagination';

interface PaginationProps {
  totalPages: number;
  currentPage: number;
  onPageChange: any;
}
interface PaginationState {
  totalPages?: number;
}

const GCPagination = (props: PaginationProps): JSX.Element => {
  return (
    <Pagination
      sx={{ alignSelf: 'center' }}
      showFirstButton
      showLastButton
      variant="outlined"
      shape="rounded"
      count={props.totalPages}
      boundaryCount={5}
      page={props.currentPage}
      onChange={(_event, page) => {
        props.onPageChange(page);
      }}
    />
  );
};

export default GCPagination;
