import { gql } from '@apollo/client';

export const PATIENT_DETAILS_QUERY = gql`
  query PatientDetails {
    loggedInPatient {
      id
      preferredName
      dob
      resolvedNamePrefix
      primaryPhoneFormatted
      secondaryPhoneFormatted
      userProfile {
        id
        user {
          id
          firstName
          lastName
          email
        }
      }
      address {
        id
        line1
        line2
        city
        state
        postcode
        country
      }
      postalAddress {
        id
        line1
        line2
        city
        state
        postcode
        country
      }
      emergencyContact {
        id
        contactType
        relationship
        firstName
        lastName
        mobilePhoneNumber
        mobilePhoneNumberLocalised
        homePhoneNumber
        homePhoneNumberLocalised
        email
        authorisedForEnquiries
        supportPerson
      }
    }
  }
`;
