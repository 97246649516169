import {
  syncConfig,
  chartSpacingConfig,
  creditsConfig,
  exportingConfig,
  handlePointClick,
  TopChartParams,
  setHeight,
  selectPoint,
} from './DefaultConfig';

const ChartColumnLine = ({
  onPointClick,
  selectedPoint,
  selectedIndex,
  seeMoreUpdated,
  leftSeriesTitle,
  rightSeriesTitle,
  leftSeriesFields,
  rightSeriesFields,
}: TopChartParams): Dashboards.Board.Options => ({
  components: [
    {
      cell: 'top-chart',
      connector: {
        id: 'top-chart-data',
        columnAssignment: [
          {
            seriesId: 'series-0',
            data: leftSeriesFields,
          },
          {
            seriesId: 'series-1',
            data: rightSeriesFields,
          },
        ],
      },
      sync: syncConfig,
      type: 'Highcharts',
      chartOptions: {
        exporting: exportingConfig,
        chart: {
          type: 'column',
          animation: false,
          spacing: chartSpacingConfig,
          events: {
            load() {
              setHeight(this);
            },
            redraw() {
              selectPoint(this, selectedPoint, selectedIndex);
            },
          },
        },
        title: { text: '' },
        xAxis: { type: 'category' },
        yAxis: [
          { title: { text: leftSeriesTitle } },
          {
            title: { text: rightSeriesTitle },
            opposite: true,
          },
        ],
        credits: creditsConfig,
        tooltip: {
          pointFormat: '{series.name}: {point.y:,.2f}<br/>',
          shared: true,
        },
        series: [
          {
            id: 'series-0',
            type: 'column',
            name: leftSeriesTitle,
            yAxis: 0,
          },
          {
            id: 'series-1',
            type: 'spline',
            name: rightSeriesTitle,
            marker: {
              enabled: false,
            },
            yAxis: 1,
          },
        ],
        plotOptions: {
          column: {
            borderRadius: 8,
          },
          series: {
            cursor: 'pointer',
            animation: selectedPoint === '' && !seeMoreUpdated,
            states: {
              select: {
                enabled: false,
              },
            },
            point: {
              events: {
                click: function () {
                  handlePointClick(this, onPointClick);
                },
              },
            },
          },
        },
      },
    },
  ],
});

export default ChartColumnLine;
