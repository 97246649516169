export const HEADER_TITLE = 'Patient Portal access';
export const DESCRIPTION = {
  LINE_1: 'Does the patient want to sign up?',
  LINE_2: 'This will update the Patient Portal access status.',
};

export const SEGMENT_INPUT = {
  OPT_IN: 'optIn',
  OPT_OUT: 'optOut',
};

export const FIELD_INFO = {
  OPT_IN: {
    NAME: 'pxOptedIn',
    KEY: 'pxOptedIn',
  },
  EMAIL: {
    NAME: 'email',
    KEY: 'email',
    PLACEHOLDER: 'Enter email address',
    TITLE: 'Email',
  },
  PRIMARY_PHONE: {
    NAME: 'primaryPhone',
    KEY: 'primaryPhone',
    PLACEHOLDER: 'Enter mobile',
    TITLE: 'Mobile',
  },
  OPT_OUT_REASON: {
    NAME: 'pxOptOutComment',
    KEY: 'pxOptOutComment',
    PLACEHOLDER:
      'Please enter the reason for opting out as this will help us to improve the experience... (limit to 500 characters)',
    TITLE: 'Reason for opting out of Patient Portal',
  },
};

export const OPT_OUT_PLACEHOLDER =
  'Please enter the reason for opting out as this will help us to improve the experience... (limit to 500 characters)';
