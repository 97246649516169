import { gql } from '@apollo/client';

export const HAS_LATEST_NOTIFICATION = gql`
  query HasLatestNotification {
    hasLatestNotification {
      hasLatest
    }
  }
`;

export const UPDATE_USER_NOTIFICATION = gql`
  mutation UpdateUserNotification {
    updateUserNotification {
      ok
    }
  }
`;

export const USER_QUERY = gql`
  {
    user {
      id
      email
      firstName
      lastName
      isPso
      isSuperuser
      isShowcaseUser
      primaryRole
    }
  }
`;

export const LOGGED_IN_PATIENT_QUERY = gql`
  {
    patient {
      id
      ida
    }
  }
`;
