// eslint-disable-next-line no-use-before-define
import React, { Component } from 'react';
import './RORegSummary.scss';

import { ROMiniRegoHeader, ROSummaryCard } from 'op-components';
import { NewsCard } from 'shared-components/components';
import { ErrorExclamation, Success } from 'shared-components/images';
import classNames from 'classnames';
import { ListData } from 'shared-interfaces';
import {
  calculateMomentAge,
  EMAIL_STRING_REGEX,
  UK_CITY_REGEX,
  UK_NAME_REGEX,
  UK_PHONE_REGEX,
} from 'shared-components/utils';
import moment from 'moment';
import { resolveListDataValue } from 'op-utils';

interface Props {
  patient: any;
  user: any;
  registrationReasonRefData: ListData[];
  nhsOptionsRefData: ListData[];
  titleRefData: ListData[];
  insurerRefData: ListData[];
  relationshipRefData: ListData[];
  gpSurgeryRefData: ListData[];
  surgeonLocationRefData: ListData[];
  gpRefData: ListData[];
  surgeonRefData: ListData[];
  extPracRefData: ListData[];
  oncologistRefData: ListData[];
  relationshipsRefData: ListData[];
  maritalStatusRefData: ListData[];
  ethnicityRefData: ListData[];
  religionRefData: ListData[];
  acceptsDataShareRefData: ListData[];
  countryOfBirthRefData: ListData[];
  genderRefData: ListData[];
  primaryCenterRefData: ListData[];
  documentTypeRefData: ListData[];
  languageSpokenRefData: ListData[];
  ausStateRefData: ListData[];
  ukCounties: ListData[];
  countries: ListData[];
  navigateTo: (path: string) => void;
}

class RORegSummary extends Component<Props> {
  public constructor(props: Props) {
    super(props);
  }

  private australianIdInCountry = '';

  private renderContentMessage = (content: string | null, fallbackText?: string): string => {
    // If null or empty then return missing text
    if (!content || content.trim() === '') {
      return fallbackText ? fallbackText : 'Not provided';
    }
    return content;
  };

  private getDisplayValue = (refData: ListData[], option: string): string => {
    for (let i = 0; i < refData.length; i++) {
      if (refData[i].id === option) {
        return refData[i].name;
      }
    }
    return option;
  };

  /*private isInvalidSection = (section: string, validationResult?: any): boolean => {
    if (validationResult && validationResult.invalidSections) {
      return validationResult.invalidSections.includes(section);
    }

    return false;
  };*/

  private getCountryRefId = (): number => {
    const { countryOfBirthRefData } = this.props;

    // TODO: This needs to be updated once country of birth ref data moves to react list data
    return countryOfBirthRefData.findIndex((country: ListData): boolean => {
      return country.name.toLowerCase() === 'united kingdom';
    });
  };

  /*private getUKCounty = (country: string, state: string): string => {
    const { countryOfBirthRefData, ausStateRefData } = this.props;

    const ukId = this.getCountryRefId();

    if (country === countryOfBirthRefData[ukId].id) {
      return this.getDisplayValue(ausStateRefData, state);
    }

    return state;
  };*/

  private validationIcon = (isInvalidSection: boolean): JSX.Element => {
    const iconClass = 'secondary-icon';
    if (isInvalidSection) {
      return <ErrorExclamation className={`${iconClass} invalid-icon`} />;
    }

    return <Success className={`${iconClass} valid-icon`} />;
  };

  private renderNewsCardRow = (
    headingText: string,
    detailText: string | null,
    displayAsError = false,
    displayAsMandatory = false,
    fallbackText?: string,
  ): JSX.Element => {
    const displayText = this.renderContentMessage(detailText, fallbackText);
    const optionalColouring = (displayText === fallbackText || displayText === 'Not provided') && !displayAsMandatory;

    const dataTestId = headingText.toLowerCase().replace(/ /g, '-');

    return (
      <div className={classNames('newscard-row', { 'invalid-data': displayAsError })}>
        <div className={classNames('newscard__title', { 'newscard__title-error': displayAsError })}>
          {headingText}
          {displayAsMandatory && '*'}
        </div>
        <div
          className={classNames(
            'newscard__content newscard--bold',
            { 'newscard__content-error': displayAsError },
            { optional: optionalColouring },
          )}
          data-test-id={`summary-${dataTestId}`}>
          {optionalColouring && displayText === 'Not provided' ? '-' : displayText}
        </div>
      </div>
    );
  };

  private validIdb(value: string | undefined): boolean | undefined {
    if (value === '') {
      return true;
    } else if (typeof value === 'string') {
      const mul = [];
      const newValue = value.replace(/ /g, '');
      for (let i = 0; i < 9; i++) {
        // @ts-ignore
        mul.push(parseInt(newValue[i]) * [i + 1]);
      }
      const sum = mul.reduce((n, a) => n + a, 0);
      const mod = sum % 11;
      return mod === parseInt(newValue[9]);
    }
  }

  private nhsFormat(nhsNumber: string | null, format: string) {
    if (!nhsNumber) {
      return null;
    }
    let i = 0;
    const val = nhsNumber.toString().replace(/ /g, '');
    return format.replace(/#/g, () => (typeof val[i] != 'undefined' ? val[i++] : ''));
  }

  public render(): JSX.Element {
    const {
      patient,
      titleRefData,
      genderRefData,
      primaryCenterRefData,
      insurerRefData,
      relationshipRefData,
      documentTypeRefData,
      countries,
      ukCounties,
    } = this.props;
    const residentialAddressCityInvalid = patient.residentialAddressCity
      ? (patient.residentialAddressCity as any).match(UK_CITY_REGEX)[0] !== patient.residentialAddressCity
      : false;
    let stateValidationFormatPattern = '[a-zA-Z\\s]+|';
    const australianIndex = countries.findIndex((country) => {
      return country.name === 'United Kingdom';
    });
    this.australianIdInCountry = countries[australianIndex].id;
    if (patient.residentialAddressCountry === this.australianIdInCountry) {
      stateValidationFormatPattern = '.*'; // State is stored as digit so allow any values
    }
    const residentialAddressCountyInvalid =
      patient.residentialAddressState && patient.residentialAddressCountry !== this.australianIdInCountry
        ? (resolveListDataValue(patient.residentialAddressState, ukCounties) as any).match(
            stateValidationFormatPattern,
          )[0] !== patient.residentialAddressState
        : false;
    const residentialAddressCountryInvalid = patient.residentialAddressCountry
      ? resolveListDataValue(patient.residentialAddressCountry, countries) === patient.residentialAddressCountry
      : false;
    const addressInvalid =
      residentialAddressCityInvalid || residentialAddressCountyInvalid || residentialAddressCountryInvalid;

    const validationObject = {
      basic: {
        title: !patient.namePrefix,
        firstName: !patient.firstName || patient.firstName.match(UK_NAME_REGEX)[0] !== patient.firstName,
        middleName: false,
        lastName: !patient.lastName || patient.lastName.match(UK_NAME_REGEX)[0] !== patient.lastName,
        gender: !patient.gender,
        dob: !patient.dob,
        nhsId: !this.validIdb(patient.idb) || patient.idbConflict,
        nhsReason: false,
        primaryCentre: !patient.primaryCenter,
        primaryPhone:
          patient.primaryPhone || patient.secondaryPhone
            ? patient.primaryPhone
              ? !patient.primaryPhone.match(UK_PHONE_REGEX)
              : false
            : true,
        secondaryPhone:
          patient.primaryPhone || patient.secondaryPhone
            ? patient.secondaryPhone
              ? !patient.secondaryPhone.match(UK_PHONE_REGEX)
              : false
            : true,
        email: patient.email ? !EMAIL_STRING_REGEX.test(String(patient.email).toLowerCase()) : false,
        address: addressInvalid,
      },
      insurance: {
        payor: false,
        relationship: false,
        policyNumber: false,
        preAuthNumber: false,
        insuranceNotes: patient.preAuthNotes ? (patient.preAuthNotes.length > 2048 ? true : false) : false,
      },
      attachments: {},
    };
    const pageValidation: any = {};
    Object.keys(validationObject).forEach((page) => {
      pageValidation[page] = false;
      Object.keys(validationObject[page as keyof typeof validationObject]).forEach((propertyName) => {
        //@ts-ignore
        if (validationObject[page][propertyName]) {
          pageValidation[page] = true;
        }
      });
    });
    //const pageValidation =
    const calculatedAge = patient.dob ? calculateMomentAge(moment(patient.dob)) : '-';
    const fullAge: string = patient.dob ? patient.dob + ' (Age ' + calculatedAge + ')' : 'Not provided';
    const primaryCenter = patient.primaryCenter
      ? this.getDisplayValue(primaryCenterRefData, patient.primaryCenter)
      : '';
    return (
      <div className="main-container ro-uk-full-width reg-summary-wrapper">
        <ROMiniRegoHeader
          title={'Review & Submit'}
          summary={'Please review and submit patient registration details.'}></ROMiniRegoHeader>
        <div className="newspaper-container">
          <ROSummaryCard>
            <NewsCard
              title="Basic Details"
              primaryIcon={
                <div className="primary-icon-wrapper">
                  <div className="primary-icon"></div>
                  <div className="primary-icon-text">Edit</div>
                </div>
              }
              secondaryIcon={this.validationIcon(pageValidation['basic'])}
              invalidContent={pageValidation['basic']}
              onClick={(): void => {
                this.props.navigateTo('basic');
              }}>
              {this.renderNewsCardRow(
                'Title',
                this.getDisplayValue(titleRefData, patient.namePrefix),
                validationObject.basic.title,
                true,
              )}
              {this.renderNewsCardRow('First name', patient.firstName, validationObject.basic.firstName, true)}
              {this.renderNewsCardRow('Middle name', patient.middleName, validationObject.basic.middleName, false)}
              {this.renderNewsCardRow('Last name', patient.lastName, validationObject.basic.lastName, true)}
              {this.renderNewsCardRow(
                'Gender',
                this.getDisplayValue(genderRefData, patient.gender),
                validationObject.basic.gender,
                true,
              )}
              {this.renderNewsCardRow(
                'Date of birth',

                fullAge,
                validationObject.basic.dob,
                true,
              )}
              {this.renderNewsCardRow(
                'NHS ID',

                this.nhsFormat(patient.idb, '### ### ####'),
                validationObject.basic.nhsId,
                false,
              )}
              {/*this.renderNewsCardRow(
                'Reason NHS ID is not provided',
                patient.nhsOptions ? this.getDisplayValue(nhsOptionsRefData, patient.nhsOptions) : '',
                validationObject.basic.nhsReason,
              )*/}
              {this.renderNewsCardRow('Primary centre', primaryCenter, validationObject.basic.primaryCentre, true)}
              {this.renderNewsCardRow(
                'Mobile phone number',
                patient.primaryPhone,
                validationObject.basic.primaryPhone,
                true,
              )}
              {this.renderNewsCardRow(
                'Home phone number',
                patient.secondaryPhone,
                validationObject.basic.secondaryPhone,
                false,
              )}
              {this.renderNewsCardRow('Email', patient.email ? patient.email : '', validationObject.basic.email, false)}
              {this.renderNewsCardRow(
                'Address',
                patient.address ? patient.address.ukFormattedAddress : '',
                validationObject.basic.address,
                false,
              )}
            </NewsCard>
            <NewsCard
              title="Insurance"
              primaryIcon={
                <div className="primary-icon-wrapper">
                  <div className="primary-icon"></div>
                  <div className="primary-icon-text">Edit</div>
                </div>
              }
              secondaryIcon={this.validationIcon(pageValidation['insurance'])}
              invalidContent={pageValidation['insurance']}
              onClick={(): void => {
                this.props.navigateTo('insurance');
              }}>
              {this.renderNewsCardRow(
                'Payor',
                this.getDisplayValue(insurerRefData, patient.payor),
                validationObject.insurance.payor,
              )}
              {this.renderNewsCardRow(
                'Relationship to policy holder',
                this.getDisplayValue(relationshipRefData, patient.coverageRelationship),
                validationObject.insurance.relationship,
              )}
              {this.renderNewsCardRow('Policy number', patient.policyNumber, validationObject.insurance.policyNumber)}
              {this.renderNewsCardRow(
                'Pre-auth number',
                patient.preAuthNumber,
                validationObject.insurance.preAuthNumber,
              )}
              {this.renderNewsCardRow(
                'Insurance notes',
                patient.preAuthNotes,
                validationObject.insurance.insuranceNotes,
              )}
            </NewsCard>
            <NewsCard
              title="Attachments"
              primaryIcon={
                <div className="primary-icon-wrapper">
                  <div className="primary-icon"></div>
                  <div className="primary-icon-text">Edit</div>
                </div>
              }
              secondaryIcon={this.validationIcon(pageValidation['attachments'])}
              invalidContent={pageValidation['attachments']}
              onClick={(): void => {
                this.props.navigateTo('attachments');
              }}>
              {patient.attachments.map((attachment: any) => {
                return this.renderNewsCardRow(
                  this.getDisplayValue(documentTypeRefData, attachment.typeDisplay),
                  attachment.filename,
                );
              })}
            </NewsCard>
          </ROSummaryCard>
        </div>
      </div>
    );
  }
}

export default RORegSummary;
