import React from 'react';
import { sharedContent } from '../constants';
import { styled } from '@mui/system';

interface Props {
  formName: string;
  submittedByNurse: string;
  isAmendment?: boolean;
}

const MainSection = styled('section')`
  font-size: 1.125rem;
  margin-bottom: 10px;
  line-height: 28px;
`;

const SubSection = styled('section')`
  line-height: 24px;
  margin-bottom: 16px;
`;

const ModalContentSubmit = ({ isAmendment, formName, submittedByNurse }: Props): JSX.Element => {
  const {
    mainText1,
    mainText2,
    subText1,
    subText2,
    additionalText = '',
  } = isAmendment ? sharedContent.reviewAndSubmit.submitAmendment : sharedContent.reviewAndSubmit.submit;

  return (
    <>
      <MainSection>
        <div>{`${mainText1} ${formName} ${mainText2}`}</div>
        <b>{submittedByNurse}</b>
      </MainSection>
      <SubSection>{`${subText1} ${formName} ${subText2}`}</SubSection>
      {additionalText ? <SubSection>{additionalText}</SubSection> : ''}
    </>
  );
};

export default ModalContentSubmit;
