import React from 'react';
import PhotoWidget from 'shared-components/components/UIFormComponents/PhotoWidget';
import { styled } from '@mui/system';
import moment from 'moment';
import { getRemSize } from 'shared-components/StyledComponents/functions';
import { calculateMomentAge } from 'shared-components/utils';
import { isDemo } from 'op-utils';
import { patientGenderStockImage } from 'op-utils/PatientHelper';
interface AddressProps {
  id: number;
  formattedAddress: string;
}
interface ROPatientProfilePopupProps {
  id: number;
  fullName: string;
  ida: string;
  dob: Date;
  address: AddressProps;
  photoUrl: string;
  gender: string;
}

const PopupContainter = styled('div')`
  display: flex;
  flex-direction: row;
  width: 400px;
  height: auto;
  justify-content: flex-start;
  justify-items: flex-start;
  align-items: center;
  & svg: {
    max-width: 32px;
    max-height: 32px;
  }
  z-index: 1180;
  background: white;
  padding: 0px;
  margin: 0px;
  left: 0px;
  top: 0px;
  position: absolute;
  border-radius: 4px;
  box-shadow: 0px 4px 8px rgb(113 110 106 / 50%);
`;

const PatientProfile = styled('div')`
  display: flex;
  flex-direction: column;
  width: 100%;
  color: black;
  text-align: left;
  padding: 16px 0px 8px;
  width: 300px;

  & .div {
    padding-left: 0px;
    margin-left: 0px;
  }
`;
const PhotoWrapper = styled('div')`
  width: 64px;
  height: 64px;
  align-content: center;
  display: flex;
  margin: 16px 4px auto 16px;
`;

const StyledName = styled('h3')`
  font-size: ${getRemSize(18)};
  font-weight: bold;
  color: ${(props) => props.theme.palette.text.primary};
`;

const StyledID = styled('span')`
  font-size: ${getRemSize(14)};
  font-weight: bold;
  color: ${(props) => props.theme.palette.grey[600]};
  padding-bottom: 8px;
`;
const StyledBoldText = styled('span')`
  font-size: ${getRemSize(14)};
  font-weight: bold;
  color: ${(props) => props.theme.palette.text.primary};
  padding-bottom: 8px;
`;

const ROPatientProfilePopup = (props: ROPatientProfilePopupProps) => {
  const formattedDateOfBirth = props.dob ? moment(props.dob).format('Do MMM YYYY') : '-';
  const calculatedAge = props.dob ? calculateMomentAge(moment(props.dob)) : '-';
  const patientImage = isDemo ? patientGenderStockImage(props.gender) : null;
  return (
    <PopupContainter>
      <PhotoWrapper>
        <PhotoWidget url={patientImage} patientId={props.id} />
      </PhotoWrapper>
      <PatientProfile>
        <StyledName>{props.fullName}</StyledName>
        <StyledID>Patient ID: {props.ida}</StyledID>
        <span>Date of Birth:</span>
        <StyledBoldText>
          {formattedDateOfBirth} (Age {calculatedAge})
        </StyledBoldText>
        <div>Address:</div>
        <StyledBoldText>{props.address && props.address.formattedAddress}</StyledBoldText>
      </PatientProfile>
    </PopupContainter>
  );
};

export default ROPatientProfilePopup;
