import React from 'react';
import { OncologyStepper } from 'shared-components/components';

const CareplanSteps = [
  {
    key: 'dg',
    header: 'Diagnosis',
    short: 'DG',
    steps: [
      { idx: 0, text: 'Diagnosis Selection', page: ['primary', 'metastasis', 'create'] },
      { idx: 1, text: 'Staging', page: 'tnmstaging' },
      { idx: 2, text: 'Intake Info', page: 'intake' },
    ],
  },
  {
    key: 'cp',
    header: 'Carepath',
    short: 'cp',
    steps: [
      { idx: 3, text: 'Treatment Info', page: 'treatmentInfo' },
      { idx: 4, text: 'Careplan Selection', page: 'protocolSelection' },
    ],
  },
  {
    key: 'tx',
    header: 'Treatment',
    short: 'tx',
    steps: [{ idx: 5, text: 'Prescription', page: 'prescription' }],
  },
];

const CareplanStepper = () => {
  return (
    <div>
      <OncologyStepper steps={CareplanSteps} />
    </div>
  );
};

export default CareplanStepper;
