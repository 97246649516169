import { gql } from '@apollo/client';

export const TENANT_CONFIG_QUERY = gql`
  query tenantConfig {
    tenantConfig {
      id
      tenantName
      doseUnit
      ariaDoseUnit
      mosaiqDoseUnit
      fractionUnit
    }
  }
`;
