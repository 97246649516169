// eslint-disable-next-line no-use-before-define
import { Field, Form, Formik, FormikProps } from 'formik';
import { FormContainer } from 'op-components';
import { RegistrationContext } from 'op-contexts';
import React, { useContext } from 'react';
import { generatePath } from 'react-router';
import { useHistory, useParams } from 'react-router-dom';
import { FormRow } from 'shared-components/components';
import {
  CONTINUE_BUTTON_TEXT,
  extraInformation,
  HeaderSubTitle,
  HeaderTitle,
  PSO_SUMMARY_LINK,
  registrationPath,
  RETURN_TO_SUBMIT_TEXT,
  sharedFormContainerProps,
  standardField,
  StandardFieldProps,
  yesNoOptions,
} from '../Helper';
import { PatientReferrerRego, RegistrationFormUSIProps } from '../interfaces';
import { FIELDS } from './constants';

interface ReferrerFormIProps extends RegistrationFormUSIProps {
  patient: PatientReferrerRego;
}

const ReferrerForm = (props: ReferrerFormIProps): JSX.Element => {
  const { patient, handleShowSaveExitConfirm, previousPageLink, updateField, isPso } = props;

  const registrationContext = useContext(RegistrationContext);
  const history = useHistory();
  const { patientId } = useParams<{ patientId: string }>();
  const goToSummary = registrationContext?.registrationSummaryVisited && !isPso;
  const referringPage = sessionStorage.getItem('referringPage');

  const helpTextField = (
    field: StandardFieldProps,
    updateField: (field: string, value: string | number | boolean) => void,
    helpText: JSX.Element,
  ): JSX.Element => (
    <FormRow fieldLabel={field.TITLE} fieldName={field.NAME} labelClass={'label-form-row'}>
      <Field
        name={field.NAME}
        component={field.COMPONENT}
        alternateStyle
        updateMutation={(value: string) => updateField(field.NAME, value)}
        handleMutation={updateField}
        placeholder={field.PLACE_HOLDER}
        options={yesNoOptions}
      />
      {helpText}
    </FormRow>
  );

  return (
    <Formik
      enableReinitialize={true}
      initialValues={patient}
      validateOnChange={false}
      onSubmit={() => {
        history.push(registrationPath(patient?.id, goToSummary ? 'summary' : 'insurance'));
      }}>
      {({ submitForm, errors, values }: FormikProps<PatientReferrerRego>) => {
        const sharedProps = sharedFormContainerProps('Referrers', 2, previousPageLink, !isPso);
        const formContainerProps = {
          ...sharedProps,
          submitButtonText: goToSummary ? RETURN_TO_SUBMIT_TEXT : CONTINUE_BUTTON_TEXT,
          continueDisabled: Object.keys(errors).length > 0,
          handleShowSaveExitConfirm,
          saveAndExitLink: isPso && generatePath(referringPage || PSO_SUMMARY_LINK, { patientId }),
        };
        return (
          <FormContainer {...formContainerProps} submitForm={submitForm}>
            <Form>
              <HeaderTitle data-test-id="us-rego-page-title">{FIELDS.FORM_HEADING.TITLE}</HeaderTitle>
              <HeaderSubTitle data-test-id="us-rego-page-subtitle">* = required field</HeaderSubTitle>
              {standardField(FIELDS.PRIMARY_PROVIDER, updateField)}
              {standardField(FIELDS.REFERRING_PROVIDER, updateField)}
              {helpTextField(
                FIELDS.ADVANCED_DIRECTIVE,
                updateField,
                values.advancedDirective ? extraInformation(FIELDS.ADVANCED_DIRECTIVE.NAME) : <></>,
              )}
              {helpTextField(
                FIELDS.POWER_OF_ATTORNEY,
                updateField,
                values.powerOfAttorney ? extraInformation(FIELDS.POWER_OF_ATTORNEY.NAME) : <></>,
              )}
              {helpTextField(
                FIELDS.LIVING_WILL,
                updateField,
                values.livingWill ? extraInformation(FIELDS.LIVING_WILL.NAME) : <></>,
              )}
            </Form>
          </FormContainer>
        );
      }}
    </Formik>
  );
};

export default ReferrerForm;
