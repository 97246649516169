import Dashboards from '@highcharts/dashboards';
import DataPoolOptions from '@highcharts/dashboards/es-modules/Data/DataPoolOptions';
import ChartStackedColumnPercent from '../Charts/ChartStackedColumnPercent';
import SubChartBar from '../Charts/SubChartBar';
import SubChartStackedBarPercentTotal from '../Charts/SubChartStackedBarPercentTotal';
import { EditModeConfig, GuiConfig, DashboardConfig, SUB_CHART_LIMIT } from './DefaultConfig';
import { aggregateStackedBarData, aggregateData, aggregateTechniqueByField } from '../../Utils/dataFunctions';

const TechniqueUtilisation = ({
  theme,
  data,
  subChartData,
  dateFilterOverride,
  selectedIndex,
  seeMoreUpdated,
  seeMoreBottomLeft,
  seeMoreBottomRight,
  toggleSeeMoreBottomLeft,
  toggleSeeMoreBottomRight,
  onPointClick,
}: DashboardConfig): Dashboards.Board.Options => ({
  dataPool: dataPoolConfig(data, subChartData, seeMoreBottomLeft, seeMoreBottomRight),
  editMode: EditModeConfig,
  gui: GuiConfig,
  components: [
    ChartStackedColumnPercent({
      onPointClick: onPointClick,
      selectedPoint: dateFilterOverride,
      selectedIndex: selectedIndex,
      seeMoreUpdated: seeMoreUpdated,
      leftSeriesTitle: '',
      rightSeriesTitle: '',
      leftSeriesFields: aggregateStackedBarData(data, 'technique', 'months', 'Complex Percentage', [
        'IMRT',
        'VMAT',
        'Stereotactic',
        'Conformal',
        'Brachytherapy',
        'Electrons',
        'Unspecified',
      ])[0].slice(0, -1),
      rightSeriesFields: ['Date', 'Complex Percentage'],
    }).components?.[0] ?? {},
    SubChartBar({
      filterOverride: dateFilterOverride,
      seeMore: seeMoreBottomLeft,
      title: 'Case Mix',
      connector: 'sub-chart-left-data',
      cell: 'sub-chart-left',
      theme: theme,
      toggleSeeMore: toggleSeeMoreBottomLeft,
    }).components?.[0] ?? {},
    SubChartStackedBarPercentTotal({
      filterOverride: dateFilterOverride,
      seeMore: seeMoreBottomRight,
      title: 'Physicians',
      connector: 'sub-chart-right-data',
      cell: 'sub-chart-right',
      theme: theme,
      toggleSeeMore: toggleSeeMoreBottomRight,
    }).components?.[0] ?? {},
  ],
});

const dataPoolConfig = (
  data: any,
  subChartData: any,
  seeMoreBottomLeft: boolean,
  seeMoreBottomRight: boolean,
): DataPoolOptions => ({
  connectors: [
    {
      id: 'top-chart-data',
      type: 'JSON',
      options: {
        firstRowAsNames: true,
        data: aggregateStackedBarData(data, 'technique', 'months', 'Complex Percentage'),
      },
    },
    {
      id: 'sub-chart-left-data',
      type: 'JSON',
      options: {
        data: aggregateData(subChartData, 'diagnosis', seeMoreBottomLeft ? undefined : SUB_CHART_LIMIT),
      },
    },
    {
      id: 'sub-chart-right-data',
      type: 'JSON',
      options: {
        firstRowAsNames: true,
        data: aggregateTechniqueByField(subChartData, 'physician', seeMoreBottomRight ? undefined : SUB_CHART_LIMIT, [
          'IMRT',
          'VMAT',
          'Stereotactic',
          'Conformal',
          'Brachytherapy',
          'Electrons',
          'Unspecified',
        ]),
      },
    },
  ],
});

export default TechniqueUtilisation;
