import React, { Fragment } from 'react';
import { styled } from '@mui/system';
import moment from 'moment';
import { CardBodyGroup, CardTitle, CardBody } from './styles';
import CONSTANTS from './constants';

const { NO_UPCOMING } = CONSTANTS;

const ApptHeader = styled('div')`
  display: flex;
  justify-content: space-between;
  background: ${(props) => props.theme.palette.grey[100]};
  font-size: 12px;
  padding: 8px 16px;
`;

const ApptItemWrapper = styled('div')`
  margin-bottom: 8px;
  display: flex;
`;

const ApptTime = styled('div')`
  padding-right: 8px;
  display: inline-block;
  width: 60px;
  font-size: 13px;
`;

const ApptLocation = styled('div')`
  display: inline-flex;
  border-left: 1px solid ${(props) => props.theme.palette.primary.main};
  flex-direction: column;
  padding-left: 8px;
`;

interface FormattedAppointment {
  time: string;
  displayTime: string;
  location: string;
  description: string | null;
  department: string;
}

export interface ReducedAppointment {
  appts: FormattedAppointment[];
  date: string;
  formattedDate: string;
  isToday: boolean;
  isTomorrow: boolean;
}

interface Appointment {
  description?: string;
  id: string;
  startTime: string;
  location: {
    id: string;
    name: string;
  };
}

interface Props {
  name: string;
  appointments: Appointment[];
}

export const getReducedAppointments = (appointments: Appointment[]): any =>
  appointments.reduce((acc: any, curr: any) => {
    const formattedDate = moment(curr.startTime).format('ddd, Do MMMM YYYY');
    const today = moment().format('ddd, Do MMMM YYYY');
    const tomorrow = moment().add(1, 'days').format('ddd, Do MMMM YYYY');

    const apptObj = {
      time: moment(curr?.startTime),
      displayTime: moment(curr?.startTime).format('h:mma'),
      description: curr?.description || '',
      location: curr?.location?.name || '',
      department: curr?.department?.alias || '',
    };

    // Only add appointments from today onwards
    if (moment(curr.startTime).diff(moment()) > 0) {
      // Group appointments by date in same object
      const existingDateIndex = acc.findIndex((item: ReducedAppointment) => item.formattedDate === formattedDate);
      if (existingDateIndex >= 0) {
        acc[existingDateIndex].appts.push(apptObj);
      } else
        acc.push({
          date: curr.startTime,
          formattedDate: formattedDate,
          isToday: formattedDate === today,
          isTomorrow: formattedDate === tomorrow,
          appts: [apptObj],
        });
    }

    return [...acc].sort(
      (a: ReducedAppointment, b: ReducedAppointment) => new Date(a.date).getTime() - new Date(b.date).getTime(),
    );
  }, []);

const AppointmentsCard = ({ name, appointments }: Props): JSX.Element => {
  const reducedSortedAppts = appointments.length > 0 ? getReducedAppointments(appointments) : [];

  const renderAppointments = (appointmentsArr: FormattedAppointment[]) => {
    const sortedAppts = [...appointmentsArr].sort((a: any, b: any) => a.time - b.time);
    return sortedAppts.map((appt: FormattedAppointment) => (
      <CardBodyGroup key={appt.displayTime}>
        <ApptItemWrapper>
          <ApptTime>{appt.displayTime}</ApptTime>
          <ApptLocation>
            {appt.description && <b>{appt.description}</b>}
            {appt.department ? appt.department.concat(' - ', appt.location) : appt.location}
          </ApptLocation>
        </ApptItemWrapper>
      </CardBodyGroup>
    ));
  };

  return (
    <div data-testid="appointmentsCard">
      <CardTitle>{name}</CardTitle>
      <CardBody>
        {reducedSortedAppts.length > 0 ? (
          reducedSortedAppts.map((apptDate: ReducedAppointment) => (
            <Fragment key={apptDate.formattedDate}>
              <ApptHeader>
                <b>{apptDate.formattedDate}</b>
                {apptDate.isToday ? 'Today' : apptDate.isTomorrow ? 'Tomorrow' : null}
              </ApptHeader>
              {renderAppointments(apptDate.appts)}
            </Fragment>
          ))
        ) : (
          <CardBodyGroup>{NO_UPCOMING}</CardBodyGroup>
        )}
      </CardBody>
    </div>
  );
};

export default AppointmentsCard;
