// eslint-disable-next-line no-use-before-define
import React, { Component, Fragment, useEffect } from 'react';
import { gql } from '@apollo/client';
import { RouteComponentProps, withRouter } from 'react-router-dom';
import { Query } from '@apollo/client/react/components';

import { HAReviewSummaryItem } from 'op-classes';
import { LoadingSpinner } from 'shared-components/components';
import { ListData } from 'shared-components/interfaces';

import HAReviewSummary from './HAReviewSummary';
import * as fragments from 'op-graphql/fragments';
import withHealthAssessment, { WithHealthAssessment } from '../../HealthAssessment';
import { useErrorModalContext } from 'op-contexts';

// prettier-ignore
const HEALTH_ASSESMENT_SUMMARY_QUERY = gql`
  query HealthAssessment($patientId: ID!) {
    healthAssessment(id: $patientId) {
      id
      patient {
        id
        fullName
        dob
      }
      height
      weight
      previousCancer
      cancer {
        id
        ...HealthAssessmentCancer
      }
      otherRadiotherapy
      otherRadiotherapyConditions {
        id
        ...HealthAssessmentOtherRT
      }
      familyHistory
      familyHistoryAnswer
      internalDevice
      internalDevices {
        id
        ...HealthAssessmentInternalDevice
      }
      bloodClot
      bloodClotAnswer
      heartOrLungProblem
      heartOrLungAnswer
      highOrLowBloodPressure
      bloodPressureAnswer
      diabetes
      diabetesResponses {
        id
        ...HealthAssessmentDiabetesResponse
      }
      intractableInfectionOrIllness
      intractableInfectionOrIllnesses {
        id
        ...HealthAssessmentInfection
      }
      immunosuppressiveCondition
      immunosuppressiveConditions {
        id
      ...HealthAssessmentImmunosuppressiveCondition
      }
      mobilityAid
      mobilityAids {
        id
        ...HealthAssessmentMobilityAid
      }
      wellbeingIssue
      wellbeingIssues {
        id
        ...HealthAssessmentWellbeingIssue
      }
      activeInjury
      activeInjuryReason
      inPain
      inPainReason
      fallen
      fallenReason
      pregnant
      advancedCareDirective
      advancedCareAdditionalInformation
      alcoholBool
      alcoholFrequency
      smokingBool
      smokingHistory
      smokingFrequency
      smokingStopDate
      smokingStopRawMonth
      smokingStopRawYear
      exerciseBool
      exerciseFrequency
      accommodationAssistance
      accommodationAssistanceReason
      transportationAssistance
      transportationAssistanceReason
      prevOperation
      operations {
        id
      ...HealthAssessmentOperation
      }
      medicationBool
      medications {
        id
        ...HealthAssessmentMedication
      }
      allergyBool
      allergies {
        id
        ...HealthAssessmentAllergy
      }
      otherMedProblemBool
      otherMedProblemDescription
      altMedicineBool
      altMedicineDescription
    }
    referenceData: listData(
      categories: [
        "treatmentType",
        "treatmentStage",
        "diabetes",
        "immunosuppresiveConditions",
        "internalDevices",
        "intractableInfectionOrIllnesses",
        "mobilityAids",
        "wellbeingIssues",
        "alcohol_frequency",
        "smoking_frequency",
        "exercise_frequency",
        "exercise_frequency",
        "sensitivity",
        "reaction",
      ]
    ) {
      id
      name
    }
    treatmentTypeRefData: listData(category: "treatmentType") {
      id
      name
    }
    treatmentStageRefData: listData(category: "treatmentStage") {
      id
      name
    }
    diabetesRefData: listData(category: "diabetes") {
      id
      name
    }
    immunosuppresiveConditionsRefData: listData(category: "immunosuppresiveConditions") {
      id
      name
    }
    internalDevicesRefData: listData(category: "internalDevices") {
      id
      name
    }
    intractableInfectionOrIllnessesRefData: listData(category: "intractableInfectionOrIllnesses") {
      id
      name
    }
    mobilityAidRefData: listData(category: "mobilityAids") {
      id
      name
    }
    wellbeingIssueRefData: listData(category: "wellbeingIssues") {
      id
      name
    }
    alcoholFrequencyRefData: listData(category: "alcohol_frequency") {
      id
      name
    }
    smokingFrequencyRefData: listData(category: "smoking_frequency") {
      id
      name
    }
    exerciseFrequencyRefData: listData(category: "exercise_frequency") {
      id
      name
    }
    sensitivityRefData: listData(category: "sensitivity") {
      id
      name
    }
    reactionRefData: listData(category: "reaction") {
      id
      name
    }
    user {
      id
      isPso
    }
  }

  ${fragments.HEALTH_ASSESSMENT_ALLERGY_FRAGMENT}
  ${fragments.HEALTH_ASSESSMENT_CANCER_FRAGMENT}
  ${fragments.HEALTH_ASSESSMENT_DIABETES_RESPONSE_FRAGMENT}
  ${fragments.HEALTH_ASSESSMENT_IMMUNOSUPPRESSIVE_CONDITION_FRAGMENT}
  ${fragments.HEALTH_ASSESSMENT_INFECTION_FRAGMENT}
  ${fragments.HEALTH_ASSESSMENT_INTERNAL_DEVICE_FRAGMENT}
  ${fragments.HEALTH_ASSESSMENT_MEDICATION_FRAGMENT}
  ${fragments.HEALTH_ASSESSMENT_MOBILITY_AID_FRAGMENT}
  ${fragments.HEALTH_ASSESSMENT_OPERATION_FRAGMENT}
  ${fragments.HEALTH_ASSESSMENT_OTHER_RT_FRAGMENT}
  ${fragments.HEALTH_ASSESSMENT_WELLBEING_ISSUE_FRAGMENT}
`;

interface HealthAssessmentQueryData {
  healthAssessment: HAReviewSummaryItem;
  treatmentTypeRefData: ListData[];
  treatmentStageRefData: ListData[];
  diabetesRefData: ListData[];
  immunosuppresiveConditionsRefData: ListData[];
  internalDevicesRefData: ListData[];
  intractableInfectionOrIllnessesRefData: ListData[];
  mobilityAidRefData: ListData[];
  wellbeingIssueRefData: ListData[];
  alcoholFrequencyRefData: ListData[];
  smokingFrequencyRefData: ListData[];
  exerciseFrequencyRefData: ListData[];
  sensitivityRefData: ListData[];
  reactionRefData: ListData[];
  referenceData: ListData[];
  user: {
    id: string;
    isPso: boolean;
  };
}

interface Props extends WithHealthAssessment, RouteComponentProps<{ patientId: string; haId: string }> {}

class HAReviewSummaryApollo extends Component<Props> {
  public render(): JSX.Element {
    const {
      match: {
        params: { patientId },
      },
    } = this.props;

    return (
      <Fragment>
        <Query<HealthAssessmentQueryData> query={HEALTH_ASSESMENT_SUMMARY_QUERY} variables={{ patientId }}>
          {({ loading, data, error }): JSX.Element => {
            const { setError } = useErrorModalContext();
            useEffect(() => {
              if (error) return setError();
            }, [error]);

            if (loading) {
              return <LoadingSpinner relativeSpinner={true} />;
            }

            if (data && data.healthAssessment) {
              // TODO: HA Summary class
              const haPatientSummary = new HAReviewSummaryItem();
              haPatientSummary.loadData(data.healthAssessment);

              return (
                <HAReviewSummary
                  haPatientSummary={haPatientSummary}
                  patientId={patientId}
                  internalDevicesRefData={data.internalDevicesRefData}
                  intractableInfectionOrIllnessesRefData={data.intractableInfectionOrIllnessesRefData}
                  immunosuppresiveConditionsRefData={data.immunosuppresiveConditionsRefData}
                  mobilityAidRefData={data.mobilityAidRefData}
                  diabetesRefData={data.diabetesRefData}
                  wellbeingIssueRefData={data.wellbeingIssueRefData}
                  treatmentTypeRefData={data.treatmentTypeRefData}
                  treatmentStageRefData={data.treatmentStageRefData}
                  referenceData={data.referenceData}
                  navigate={this.navigate}
                  isPso={data.user && data.user.isPso}
                />
              );
            }
            return <div></div>;
          }}
        </Query>
      </Fragment>
    );
  }

  private navigate = (link: string): void => {
    this.props.history.push(link);
  };
}

const routedComponent = withRouter(HAReviewSummaryApollo);
const haComponent = withHealthAssessment(routedComponent);
export default haComponent;
