import { gql } from '@apollo/client';
import { REGISTRATION_INSURANCE_US_FRAGMENT } from 'op-graphql/fragments';

export const REGISTRATION_INSURANCE_QUERY = gql`
  query RegistrationInsuranceOtherBenefits($id: ID!) {
    patient(id: $id) {
      id
      primaryInsurance {
        id
        ...InsuranceUS
      }
      hasSecondaryInsurance
      secondaryInsurance {
        id
        ...InsuranceUS
      }
      receiveVeteransAdminBenefits
      veteransAdminName
      veteransContactNumber
      veteransCity
      veteransStateProvince
      veteransZip
    }
    relationshipRefData: listData(category: "relationships") {
      id
      name
    }
    user {
      id
      isPso
    }
  }
  ${REGISTRATION_INSURANCE_US_FRAGMENT}
`;

export const UPDATE_REGISTRATION_INSURANCE = gql`
  mutation UpdatePatientUsInsurance(
    $id: ID!
    $insuranceId: ID
    $fundName: String
    $planId: String
    $group: String
    $phoneNumber: String
    $policyHolder: String
    $policyDob: String
    $guarantor: String
    $guarantorRelationship: String
    $hasSecondaryInsurance: Boolean
    $secondaryInsuranceId: ID
    $secondaryfundName: String
    $secondaryplanId: String
    $secondarygroup: String
    $secondaryphoneNumber: String
    $secondarypolicyHolder: String
    $secondarypolicyDob: String
    $secondaryguarantor: String
    $secondaryguarantorRelationship: String
    $receiveVeteransAdminBenefits: Boolean
    $veteransAdminName: String
    $veteransContactNumber: String
    $veteransCity: String
    $veteransStateProvince: String
    $veteransZip: String
  ) {
    updatePatientUsInsurance(
      id: $id
      primaryInsurance: {
        id: $insuranceId
        fundName: $fundName
        planId: $planId
        group: $group
        phoneNumber: $phoneNumber
        policyHolder: $policyHolder
        policyDob: $policyDob
        guarantor: $guarantor
        guarantorRelationship: $guarantorRelationship
      }
      hasSecondaryInsurance: $hasSecondaryInsurance
      secondaryInsurance: {
        id: $secondaryInsuranceId
        fundName: $secondaryfundName
        planId: $secondaryplanId
        group: $secondarygroup
        phoneNumber: $secondaryphoneNumber
        policyHolder: $secondarypolicyHolder
        policyDob: $secondarypolicyDob
        guarantor: $secondaryguarantor
        guarantorRelationship: $secondaryguarantorRelationship
      }
      receiveVeteransAdminBenefits: $receiveVeteransAdminBenefits
      veteransAdminName: $veteransAdminName
      veteransContactNumber: $veteransContactNumber
      veteransCity: $veteransCity
      veteransStateProvince: $veteransStateProvince
      veteransZip: $veteransZip
    ) {
      patient {
        id
        primaryInsurance {
          id
          ...InsuranceUS
        }
        hasSecondaryInsurance
        secondaryInsurance {
          id
          ...InsuranceUS
        }
        receiveVeteransAdminBenefits
        veteransAdminName
        veteransContactNumber
        veteransCity
        veteransStateProvince
        veteransZip
      }
    }
  }
  ${REGISTRATION_INSURANCE_US_FRAGMENT}
`;
