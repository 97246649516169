import { LastUpdateUserDetails } from './shared/interfaces';

export const transformEmptyString = (str: string | undefined): string => {
  return str ? str : '-';
};

export const formatDtRating = (distressThermometer: {
  id: string;
  distressRating: number;
  version: string;
}): string | null => {
  return distressThermometer && (distressThermometer.distressRating || distressThermometer.distressRating >= 0)
    ? `${distressThermometer.distressRating}/10`
    : null;
};

export const getLastUpdateUser = (
  lastUpdateUserData: LastUpdateUserDetails[],
  fieldName: string,
): string | undefined => {
  return lastUpdateUserData.find((item) => item.fieldName === fieldName)?.lastUpdateUser;
};

export const choiceToStringOptions = {
  true: 'Yes',
  false: 'No',
  null: '-',
  YES: 'Yes',
  NO: 'No',
  UNSURE: 'Unsure',
};

export const choiceToBooleanOptions = {
  YES: true,
  NO: false,
  UNSURE: null,
};

export const getRecentlyCreated = (data: any): any => {
  const sortedData = data.sort((a: any, b: any) => (Number(a.id) > Number(b.id) ? -1 : 1));
  return sortedData[0];
};
