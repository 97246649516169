// eslint-disable-next-line no-use-before-define
import React from 'react';
import { styled } from '@mui/system';
import { InfoCircleBlueIcon, Success } from 'shared-components/images';
import { useTheme, Stack } from '@mui/material';

interface Props {
  text: string;
  type: string;
  subtext?: string;
  testId?: string;
}

interface StyledProps {
  borderColor: string;
  backgroundColor: string;
}

const Wrapper = styled('div')<StyledProps>`
  border-left: ${({ borderColor }: StyledProps): string => `3px solid ${borderColor}`};
  padding: 10px;
  background: ${({ backgroundColor }: StyledProps): string => backgroundColor};
  display: flex;
  align-items: center;
`;

const SuccessIcon = styled((props: any) => <Success title="Success.svg" {...props} />)`
  margin-right: 8px;
  fill: ${(props) => props.theme.palette.primary.dark};
  width: 20px;
  height: 20px;
`;

const InfoIcon = styled(InfoCircleBlueIcon)`
  margin-right: 8px;
  fill: ${(props) => props.theme.palette.warning.dark};
  width: 20px;
  height: 20px;

  path {
    fill: ${(props) => props.theme.palette.warning.dark};
  }
`;

const Banner = ({ subtext = '', text, type, testId }: Props): JSX.Element => {
  const theme = useTheme();
  const bannerIconMap = {
    success: <SuccessIcon title="Success.svg" />,
    info: <InfoIcon title="InfoCircleBlueIcon.svg" />,
  };
  const styledPropsMap = {
    success: {
      borderColor: theme.palette.primary.dark,
      backgroundColor: theme.palette.secondary.light,
    },
    info: {
      borderColor: theme.palette.warning.dark,
      backgroundColor: theme.palette.warning.light,
    },
  };

  const dataTestId = testId ? `banner-${testId}` : 'banner';

  return (
    <Wrapper {...styledPropsMap[type as keyof typeof styledPropsMap]} data-test-id={dataTestId}>
      {bannerIconMap[type as keyof typeof bannerIconMap]}
      <Stack marginRight={0.5}>{subtext}</Stack>
      {text}
    </Wrapper>
  );
};

export default Banner;
