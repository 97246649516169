import { gql } from '@apollo/client';

export const UNSEEN_POPUP_NOTIFICATIONS_FRAGMENT = gql`
  fragment UnseenPopups on UnseenPopupType {
    id
    description
    createdBy
  }
`;

export const UNSEEN_POPUP_NOTIFICATIONS = gql`
  query UnseenPopupNotifications {
    unseenPopups {
      id
      ...UnseenPopups
    }
  }

  ${UNSEEN_POPUP_NOTIFICATIONS_FRAGMENT}
`;

export const UPDATE_USER_POPUP_NOTIFICATION = gql`
  mutation UpdateUserPopupNotification($id: ID!) {
    updateUserPopupNotification(id: $id) {
      ok
    }
  }
`;

export const HAS_LATEST_NOTIFICATION = gql`
  query HasLatestNotification {
    hasLatestNotification {
      hasLatest
    }
  }
`;

export const UPDATE_USER_NOTIFICATION = gql`
  mutation UpdateUserNotification {
    updateUserNotification {
      ok
    }
  }
`;
