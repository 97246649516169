import React from 'react';
import { styled } from '@mui/system';
import { yesNoOptions } from 'op-utils';
import { sharedContent } from '../../../shared/constants';
import { InfectiousRiskFields, PreCtChartCheckFormValues } from '../../interfaces/PreCtChartCheckInterfaces';
import { MandatoryPatient } from 'op-interfaces';
import { getRemSize } from 'shared-components/StyledComponents/functions';
import { ReviewBox } from 'shared-components/components';
import ReviewSubFields from '../../../shared/components/ReviewSubFields';
import { AustralianStates } from 'shared-components/enums';

interface Props {
  fields: InfectiousRiskFields;
  values: PreCtChartCheckFormValues;
  patient: MandatoryPatient;
  error: boolean;
  link: string;
  isLocked: boolean;
}

interface valueResponse {
  value?: boolean | string;
}

interface Position {
  position?: string;
}

const QuestionContainer = styled('div')<Position>`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  margin-left: 27px;
  margin-top: ${(props: Position): string => (props.position === 'top' ? '0px' : '20px')};
  margin-bottom: ${(props: Position): string => (props.position === 'bottom' ? '0px' : '20px')}; ;
`;

const SubQuestionContainer = styled('div')`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  margin-left: 27px;
`;

const ArrayQuestion = styled('div')<valueResponse>`
  width: 50%;
  height: 40px;
  font-size: ${getRemSize(14)};
  line-height: 20px;
  display: flex;
  align-items: center;
  color: ${(props) =>
    props.value !== '' ? `${props.theme.palette.text.primary}` : `${props.theme.palette.error.main}`};
`;

const ArrayAnswer = styled('div')<valueResponse>`
  width: 50%;
  font-weight: bold;
  font-size: ${getRemSize(14)};
  line-height: 20px;
  display: flex;
  align-items: center;
  color: ${(props) =>
    props.value !== '' ? `${props.theme.palette.text.primary}` : `${props.theme.palette.error.main}`};
`;

const Question = styled('div')<valueResponse>`
  width: 50%;
  height: 40px;
  font-size: ${getRemSize(14)};
  line-height: 20px;
  display: flex;
  align-items: center;
  color: ${(props) =>
    props.value !== null ? `${props.theme.palette.text.primary}` : `${props.theme.palette.error.main}`};
`;

const Answer = styled('div')<valueResponse>`
  width: 50%;
  font-weight: bold;
  font-size: ${getRemSize(14)};
  line-height: 20px;
  display: flex;
  align-items: center;
  color: ${(props) =>
    props.value !== null ? `${props.theme.palette.text.primary}` : `${props.theme.palette.error.main}`};
`;

const convertValueToLabel = (value: any, arrayLabel: { label: string; value: boolean }[]) => {
  for (const option of arrayLabel) {
    if (option.value === value) return option.label;
  }
  if (typeof value === 'object') {
    if (value.length > 0) {
      return value.join(', ');
    } else {
      return sharedContent.generic.label.notProvided;
    }
  }
};

const renderQuestion = (item: { baseValue: string; otherValue: string }, title: string, index: number): JSX.Element => {
  if (item.baseValue !== 'Other') {
    return (
      <SubQuestionContainer aria-label={`review-${title}`} key={index}>
        <ArrayQuestion value={item.baseValue}>{title}</ArrayQuestion>
        <ArrayAnswer value={item.baseValue}>
          {item.baseValue ? item.baseValue : sharedContent.generic.label.notProvided}
        </ArrayAnswer>
      </SubQuestionContainer>
    );
  }
  return (
    <SubQuestionContainer aria-label={`review-${title}`} key={index}>
      <ArrayQuestion value={item.otherValue}>{title}</ArrayQuestion>
      <ArrayAnswer value={item.otherValue}>
        {item.otherValue ? item.otherValue : sharedContent.generic.label.notProvided}
      </ArrayAnswer>
    </SubQuestionContainer>
  );
};

const ReviewInfections = (props: Props): JSX.Element => {
  const { fields, values, patient, error, link, isLocked } = props;

  return (
    <ReviewBox title={'Infectious Risk'} error={error} link={link} isLocked={isLocked}>
      <QuestionContainer position="top" aria-label={`review-${fields.hasInfectiousDisease}`}>
        <Question value={values.hasInfectiousDisease}>{fields.hasInfectiousDisease}</Question>
        <Answer value={values.hasInfectiousDisease}>
          {values.hasInfectiousDisease !== null
            ? convertValueToLabel(values.hasInfectiousDisease, yesNoOptions)
            : sharedContent.generic.label.notProvided}
        </Answer>
      </QuestionContainer>
      {values.hasInfectiousDisease && (
        <ReviewSubFields>
          {Object.values(values.infectiousDisease).map(
            (item: { baseValue: string; otherValue: string }, index: number) =>
              renderQuestion(item, `Infectious Disease ${index + 1}`, index),
          )}
        </ReviewSubFields>
      )}
      <QuestionContainer aria-label={`review-${fields.hasMroInfection}`}>
        <Question value={values.hasMroInfection}>{fields.hasMroInfection}</Question>
        <Answer value={values.hasMroInfection}>
          {values.hasMroInfection !== null
            ? convertValueToLabel(values.hasMroInfection, yesNoOptions)
            : sharedContent.generic.label.notProvided}
        </Answer>
      </QuestionContainer>
      {values.hasMroInfection && (
        <ReviewSubFields>
          {Object.values(values.mroInfection).map((item: { baseValue: string; otherValue: string }, index: number) =>
            renderQuestion(item, `MRO Infection ${index + 1}`, index + values.infectiousDisease.length),
          )}
        </ReviewSubFields>
      )}
      {patient.userProfile
        ? patient.userProfile.systemState === AustralianStates.WA && (
            <QuestionContainer position="bottom">
              <Question value={values.outsideWaLastTwelveMonths}>{fields.outsideWaLastTwelveMonths}</Question>
              <Answer value={values.outsideWaLastTwelveMonths}>
                {values.outsideWaLastTwelveMonths !== null
                  ? convertValueToLabel(values.outsideWaLastTwelveMonths, yesNoOptions)
                  : sharedContent.generic.label.notProvided}
              </Answer>
            </QuestionContainer>
          )
        : null}
    </ReviewBox>
  );
};

export default ReviewInfections;
