import React from 'react';
import { useFormikContext } from 'formik';
import { content } from '../../PreCtTriage/constants';
import { routes } from '../../PreCtTriage/routes';
import { ReviewBox } from 'shared-components/components';
import ReviewFieldQA from './ReviewFieldQA';

interface Props {
  sectionName: string;
  link: string;
  fieldsToDisplay: string[];
  valuesToDisplay: (value: string, fieldName: string) => any;
  validateField: (value: string, fieldName: string) => boolean;
  subFields?: string[];
  isLocked: boolean;
}

const ReviewSection = ({
  sectionName,
  link,
  fieldsToDisplay,
  valuesToDisplay,
  validateField,
  subFields,
  isLocked,
}: Props): JSX.Element => {
  const { errors }: any = useFormikContext();
  //@ts-ignore
  const { fields } = content[sectionName];
  //@ts-ignore
  const { sectionTitle } = routes[sectionName];

  const checkErrorValidation = (fields: any): boolean => {
    for (const key of Object.keys(fields)) {
      if (errors[key]) return true;
    }
    return false;
  };

  return (
    <ReviewBox title={sectionTitle} error={checkErrorValidation(fields)} link={link} isLocked={isLocked}>
      {fieldsToDisplay.map((fieldName: any) => {
        return (
          <ReviewFieldQA
            fieldName={fieldName}
            sectionFields={fields}
            valuesToDisplay={valuesToDisplay}
            validateField={validateField}
            isSubField={subFields && subFields.includes(fieldName)}
          />
        );
      })}
    </ReviewBox>
  );
};

export default ReviewSection;
