import React from 'react';
import { useQuery } from '@apollo/client';
import { useFormikContext, FormikProps, getIn } from 'formik';
import { generatePath } from 'react-router';
import { useParams } from 'react-router-dom';
import { styled } from '@mui/system';

import { MandatoryPatient } from 'op-interfaces';
import { yesNoOptions } from 'op-utils';
import { AustralianStates } from 'shared-components/enums';

import ReviewQcl from '../../shared/components/ReviewQCL';
import ReviewSection from '../../shared/components/ReviewSection';
import ReviewSectionWithHeading from '../../shared/components/ReviewSectionWithHeading';
import { LocationListData } from '../../shared/interfaces';
import { GET_ACTIVE_QCLS } from '../../shared/queries';

import { content, factorsIdentifiedOptions } from '../constants';
import { PreCtTriageData } from '../interfaces/PreCtTriageInterfaces';
import { routes } from '../routes';

interface Props {
  patient: MandatoryPatient;
  allowedLocations: LocationListData[];
  isLocked: boolean;
}

const Wrapper = styled('div')`
  width: 65%;
`;

const subFields = [
  'painScore',
  'howFactorsWereIdentified',
  'livesWith',
  'carerSpecification',
  'otherResidence',
  'usualResidenceSpecify',
  'assistance',
  'assistanceSpecify',
  'familyOrFriendSpecification',
  'existingSupportSpecification',
  'sensoryImpairments',
  'primaryLanguage',
  'sensoryImpairmentsSpecification',
  'requireInterpreter',
];

const ReviewAndSubmit = ({ patient, allowedLocations, isLocked }: Props): JSX.Element => {
  const { values } = useFormikContext<PreCtTriageData>();
  const { patientId, formId } = useParams<{ patientId: string; formId: string }>();
  const sections = Object.keys(content);
  const { data, error, loading } = useQuery(GET_ACTIVE_QCLS, {
    variables: { patientId: patientId, isAmendment: values.isAmendment },
  });
  const customQclRules = (fieldName: string, values: object): void =>
    (fieldName !== 'isAlertAndOrientated' && getIn(values, fieldName) === true) ||
    (fieldName === 'isAlertAndOrientated' && getIn(values, fieldName) === false) ||
    (fieldName === 'painScore' && parseInt(getIn(values, fieldName)) > 3) ||
    (patient.userProfile &&
      patient.userProfile.systemState === AustralianStates.QLD &&
      ((fieldName === 'dvaTransport' && getIn(values, 'transportMode').includes('DVA Transport')) ||
        (fieldName === 'genesisBus' && getIn(values, 'transportMode').includes('Genesis Bus')) ||
        (fieldName === 'cancerCouncilBus' && getIn(values, 'transportMode').includes('Cancer Council Bus'))));

  const socialCircumstanceSections = ['livingSituation', 'careRequirements', 'socialMedicalHistory'];

  const fieldsToDisplay = (section: string) =>
    Object.keys(content[section as keyof typeof content].fields).filter((fieldName) => {
      if (section === 'pain') {
        return !(fieldName === 'painScore' && !values.hasCurrentPain);
      }
      if (section === 'summary') {
        return !(
          (fieldName === 'pelvicFitness' &&
            patient.userProfile &&
            patient.userProfile.systemState !== AustralianStates.WA) ||
          (fieldName === 'howFactorsWereIdentified' && !values.hasHighRiskFactors)
        );
      }
      return fieldName;
    });

  const valuesToDisplay = (value: any, fieldName?: string) => {
    if (
      fieldName &&
      ['painScore', 'otherTransport', 'socialConsiderations', 'pelvicFitness'].includes(fieldName) &&
      value
    )
      return value;
    if (fieldName === 'transportMode') {
      if (value.length > 0) {
        return value.join(', ');
      } else {
        return 'Not Provided';
      }
    }
    if (fieldName === 'nurseLocation') {
      return allowedLocations.filter((location: any) => location.id === value.toString())[0]['name'];
    }
    if (fieldName === 'howFactorsWereIdentified') {
      for (const option of factorsIdentifiedOptions) {
        if (option.value === value) return option.label;
      }
    }
    // all remaining fields with yes/no toggles
    for (const option of yesNoOptions) {
      if (option.value === value) return option.label;
    }
  };

  const headingsValuesToDisplay = (value: any, fieldName: string) => {
    const textShowTextQuestions = [
      'socialConsiderations',
      'livesWith',
      'carerSpecification',
      'otherResidence',
      'usualResidence',
      'usualResidenceSpecify',
      'familyOrFriendSpecification',
      'existingSupportSpecification',
      'otherInformation',
      'assistance',
      'assistanceSpecify',
      'primaryLanguage',
      'sensoryImpairmentsSpecification',
    ];

    if (fieldName === 'assistance') {
      return value.join(', ');
    }
    if (fieldName === 'sensoryImpairments') {
      return value.join(', ');
    }

    if (fieldName && textShowTextQuestions.includes(fieldName) && value) return value;

    // all remaining fields with yes/no toggles
    for (const option of yesNoOptions) {
      if (option.value === value) return option.label;
    }
  };

  const makeQuestionsAndHeadingsData = () => {
    const headingsData: any = [];

    socialCircumstanceSections.forEach((section) => {
      headingsData.push({
        subHeading: section,
        // @ts-ignore
        fieldsToDisplay: Object.keys(content['socialCircumstances'][section]['fields']).filter((fieldName) => {
          // skip questions based on parent question answer
          if (section === 'livingSituation') {
            if (fieldName === 'usualResidenceSpecify') {
              return values.usualResidence === 'Other';
            }
            if (fieldName === 'livesWith') {
              return values.livesAlone === false;
            }
            if (fieldName === 'carerSpecification') {
              return values.isCarer;
            }
            if (fieldName === 'otherResidence') {
              return values.staysAtUsualResidence === false;
            }
          }
          if (section === 'careRequirements') {
            if (fieldName === 'assistance') {
              return values.needsAssistanceAdls;
            }
            if (fieldName === 'assistanceSpecify') {
              return values.assistance.length > 0;
            }
            if (fieldName === 'familyOrFriendSpecification') {
              return values.hasFamilyOrFriend;
            }
            if (fieldName === 'existingSupportSpecification') {
              return values.existingSupportService;
            }
            if (fieldName === 'sensoryImpairments') {
              return values.hasSensoryImpairment;
            }
            if (fieldName === 'sensoryImpairmentsSpecification') {
              return values.hasSensoryImpairment && values.sensoryImpairments.length > 0;
            }
          }
          if (section === 'socialMedicalHistory') {
            if (fieldName === 'primaryLanguage') {
              return values.englishSecondLanguage;
            }
            if (fieldName === 'requireInterpreter') {
              return values.englishSecondLanguage;
            }
          }
          return fieldName;
        }),
      });
    });
    return headingsData;
  };

  const validateField = (value: string, fieldName: string) => {
    let failedValidation =
      (values['transportMode'].length < 1 &&
        !values['otherTransport'] &&
        ['transportMode', 'otherTransport'].includes(fieldName)) ||
      (['', null].includes(value) && !['transportMode', 'otherTransport'].includes(fieldName));

    if (Array.isArray(getIn(values, fieldName)) && getIn(values, fieldName).length === 0) {
      failedValidation = true;
    }

    return failedValidation;
  };

  const newFieldsTouched = () => {
    const newFields: any = [];
    let touched = false;
    // list all new fields
    socialCircumstanceSections.forEach((section) => {
      // @ts-ignore
      Object.keys(content['socialCircumstances'][section]['fields']).forEach((question) => {
        newFields.push(question);
      });
    });

    // check each new field (except arrays) if has a value
    newFields.forEach((field: string) => {
      if (typeof getIn(values, field) !== 'object' && getIn(values, field) !== null && getIn(values, field) !== '') {
        touched = true;
      }
    });

    return touched;
  };

  return (
    <Wrapper>
      {!loading && (
        <ReviewQcl
          activeQcls={!isLocked && !error ? data.activeTasks : []}
          formContent={content}
          customQclRules={customQclRules}
          isLocked={isLocked}
        />
      )}

      {sections.map((section) => {
        if (section === 'socialCircumstances')
          return (
            <ReviewSectionWithHeading
              sectionName={section}
              link={generatePath(routes[section].path, { patientId, formId })}
              valuesToDisplay={headingsValuesToDisplay}
              fieldsWithHeadingsToDisplay={makeQuestionsAndHeadingsData()}
              validateField={validateField}
              subFields={subFields}
              isLocked={isLocked}
              newFieldsTouched={newFieldsTouched()}
              oldFieldHasValue={!!values['socialConsiderations']}
            />
          );

        return (
          <ReviewSection
            sectionName={section}
            link={generatePath(routes[section as keyof typeof routes].path, { patientId, formId })}
            valuesToDisplay={valuesToDisplay}
            fieldsToDisplay={fieldsToDisplay(section)}
            validateField={validateField}
            subFields={subFields}
            isLocked={isLocked}
          />
        );
      })}
    </Wrapper>
  );
};

export default ReviewAndSubmit;
