// eslint-disable-next-line no-use-before-define
import React, { useEffect, useRef, useState } from 'react';
import { useHistory, useRouteMatch } from 'react-router-dom';
import { ListCheckData } from 'shared-components/interfaces';
import SimpleTable, { DATATYPE } from 'shared-components/components/UIFormComponents/SimpleTable';
import { usePrevious } from 'shared-components/utils/CustomHooks';
import { PatientDocumentType } from '../PatientSummary/PatientSummaryInterfaces';
import DocumentUploadButton from './DocumentUploadButton';
import { CurrentAppConfig } from 'op-pages/RO/Careplan/AppConfig';
import dayjs from 'dayjs';
import { Stack, useTheme, styled, Typography } from '@mui/material';
import { FindInPageOutlined as FindInPageOutlinedIcon } from '@mui/icons-material';

const StyledDocListCol = styled(Stack)`
  flex-grow: 1;
  margin-top: 0.5em;
  color: ${(props) => props.theme.palette.text.primary};
  overflow: auto;
  padding: 0px;

  th {
    white-space: nowrap;
  }
  td {
    font-size: 14px;
  }
  table {
    margin: 0;
  }
`;

const StyledContainer = styled('div')`
  height: 100%;
  margin-bottom: 4px;
  display: flex;
  flex-direction: column;
  tbody tr:hover {
    cursor: pointer;
  }
`;

const StyledSimpleTable = styled(SimpleTable)`
  line-height: 40px;
  margin: 25px 5px;
`;

const HeadingBlock = styled('div')`
  display: flex;
  justify-content: space-between;
`;

interface ROPatientDocsListProps {
  setSelectedDocument: any;
  selectedDocument: any;
  documents: any;
}

const TABLE_HEADER = [
  { header: 'Type', key: 'description', sort: false, type: DATATYPE.STRING },
  { header: 'Status', key: 'status', sort: true, type: DATATYPE.STRING },
  { header: 'Date', key: 'dateOfDocument', sort: true, type: DATATYPE.DATE },
];

const HEADING = 'Documents';
const DOC_FILTER_FIELD = CurrentAppConfig.PatientSummary.documentFilterField;

const DocumentsList = (props: ROPatientDocsListProps): JSX.Element => {
  const match = useRouteMatch<any>();
  const history = useHistory();
  const [docTypeOptions, setDocTypeOptions] = useState<ListCheckData[]>([]);
  const [documentsList, setDocumentsList] = useState<PatientDocumentType[]>([]);
  const docsListRef = useRef<HTMLDivElement>(null);
  const { id: patientId } = match.params;
  const { documents, setSelectedDocument, selectedDocument } = props;
  const prevPatientId = usePrevious(patientId);
  const theme = useTheme();

  const sortAlphabetically = (array: Array<any>, parameter: string) => {
    return [...array].sort((a: any, b: any) => {
      if (!a.hasOwnProperty(parameter) || !b.hasOwnProperty(parameter)) return 0;
      const parameter1 = a[parameter].toLowerCase();
      const parameter2 = b[parameter].toLowerCase();
      return parameter1 > parameter2 ? 1 : parameter2 > parameter1 ? -1 : 0;
    });
  };

  const documentSelectCallback = (id: string | null): void => {
    setSelectedDocument(id != null ? documentsList.filter((doc: any) => doc.id === id)[0] : null);
  };

  const formatData = (inputData: any) => {
    return inputData.map((data: any) => {
      return { ...data, dateOfDocument: dayjs(data['dateOfDocument']).format('DD/MM/YYYY') };
    });
  };

  const getDocumentTypeOptions = (documents: PatientDocumentType[]): ListCheckData[] => {
    const documentTypesList = documents.map((doc: PatientDocumentType) => doc[DOC_FILTER_FIELD]);
    const documentTypes = [...new Set(documentTypesList)].map((value: string) => {
      const hasOption = docTypeOptions && docTypeOptions.filter((option) => option.id === value).length;
      let checked = true;

      if (hasOption && patientId === prevPatientId) {
        checked = docTypeOptions.filter((option) => option.id === value)[0].checked;
      }

      return {
        id: value,
        name: value,
        checked,
      };
    });

    return documentTypes;
  };

  const updateDocumentTypeOptions = (documentTypes: string[], checked: boolean): void => {
    const updatedPatientDocsFilter: ListCheckData[] = docTypeOptions.map((item: ListCheckData) => {
      const updatedOption = documentTypes.filter((currentItem) => currentItem === item.id);
      if (updatedOption.length) {
        return { id: updatedOption[0], name: updatedOption[0], checked: checked };
      }

      return item;
    });

    setDocTypeOptions(updatedPatientDocsFilter ? sortAlphabetically(updatedPatientDocsFilter, 'name') : []);
  };

  /* Update local documents and document type filters when data is refreshed */
  useEffect(() => {
    if (documents) {
      setDocumentsList(documents);
      const documentTypeOptions = getDocumentTypeOptions(documents);
      const sortedDocumentTypes = sortAlphabetically(documentTypeOptions, 'name');
      setDocTypeOptions(sortedDocumentTypes);
    }
  }, [documents]);

  /* Set first document on the list to be displayed initially */
  useEffect(() => {
    if (
      documentsList?.length &&
      documentsList[0].patient.id === patientId &&
      !documentsList.filter((doc: any) => doc.id === selectedDocument?.id).length
    ) {
      documentSelectCallback(documentsList[0].id);
    } else if (!documents.length) {
      documentSelectCallback(null);
    }
  }, [documentsList]);

  /* Update document list based on selected types */
  useEffect(() => {
    const typesList = docTypeOptions
      .filter((option: ListCheckData) => option.checked)
      .map((option: ListCheckData) => option.id);
    const newDocuments = documents.filter((doc: PatientDocumentType) => typesList.includes(doc[DOC_FILTER_FIELD]));

    setDocumentsList(newDocuments);
  }, [docTypeOptions]);

  const cleanedData = formatData(documentsList);
  const formattedData = cleanedData.length ? cleanedData : [{ id: 0 }];

  return (
    <StyledContainer>
      <HeadingBlock>
        <Typography variant="h6">{HEADING}</Typography>
        {CurrentAppConfig.PatientSummary.RODocumentUpload && (
          <DocumentUploadButton
            onClick={() => {
              history.push(`/radiation/patient/${patientId}/documents/upload`);
            }}
          />
        )}
      </HeadingBlock>
      <StyledDocListCol ref={docsListRef}>
        {documents?.length ? (
          <StyledSimpleTable
            header={HEADING}
            onRowClick={(id: string) => {
              setSelectedDocument(documentsList.filter((doc: any) => doc.id === id)[0]);
            }}
            dataProps={TABLE_HEADER}
            data={formattedData}
            selectedRow={selectedDocument ? selectedDocument.id : formattedData.length ? formattedData[0].id : null}
            docTypeOptions={docTypeOptions}
            updateDocumentTypeOptions={updateDocumentTypeOptions}
          />
        ) : (
          <Stack alignItems="center" paddingTop={4}>
            <FindInPageOutlinedIcon fontSize="large" color="primary" />
            <Typography variant="body1">No document available</Typography>
          </Stack>
        )}
      </StyledDocListCol>
    </StyledContainer>
  );
};

export default DocumentsList;
