// eslint-disable-next-line no-use-before-define
import { gql } from '@apollo/client';
import { Query } from '@apollo/client/react/components';
import { WithApolloClient, withApollo } from '@apollo/client/react/hoc';
import lodash from 'lodash';
import { Component, Fragment } from 'react';

import { RouteComponentProps } from 'react-router-dom';

// @ts-ignore
import withRegistrationForm, { GET_APOLLO_CACHE, WithRegistrationForm } from '../RegistrationForm';
import RegistrationFileAttachments from './RegistrationFileAttachments';

import { ExtendLock } from 'op-components';
import { BASE_REGISTRATION_PAGES } from 'op-enums';
import { OPUser, Patient, PatientAttachment, PatientAttachments, UserProfile } from 'op-interfaces';
import { LoadingSpinner } from 'shared-components/components';
import { SavingStatus } from 'shared-components/enums';
import { ListData } from 'shared-components/interfaces';
import { Logger } from 'shared-components/utils';

const logger = new Logger('RegistrationFileAttachmentsApollo');

const PATIENT_AND_USER_DETAILS_QUERY = gql`
  query PatientAndUserDetails($id: ID!) {
    patient(id: $id) {
      id
      isProd
      ida
      idb
      firstName
      lastName
      dob
      lastVisitedSection
      regFormStatus
      address {
        id
        ukFormattedAddress
      }
      lock {
        lockedBy
        readOnly
        lockedByName
      }
      acceptsDataShare
      userProfile {
        id
        systemState
      }
      attachments {
        id
        documentType
        encounterDate
        isRegistrationAttachment
        filename
        filesize
        populatedDocumentType
        submitted
        suggestedDocumentType
        url
      }
      attachmentAcknowledged
    }
    genderRefData: listData(category: "gender") {
      id
      name
    }
    documentTypeRefData: attachmentTypes {
      id
      conceptCode
      name: conceptDisplay
    }
    user {
      id
      isPso
    }
  }
`;

const ATTACHMENT_ACKNOWLEDGED_MUTATION = gql`
  mutation UpdatePatient($id: ID!, $attachmentAcknowledged: Boolean!) {
    updatePatient(id: $id, attachmentAcknowledged: $attachmentAcknowledged) {
      patient {
        id
        attachmentAcknowledged
      }
    }
  }
`;

interface PatientAndUserDetailsQueryData {
  patient: PatientAttachments;
  userProfile: UserProfile;
  genderRefData: ListData[];
  documentTypeRefData: ListData[];
  user: OPUser;
}

interface DocumentTypeData extends ListData {
  conceptCode?: string;
}

interface State {
  saveStatus: SavingStatus;
  pageViewed: boolean;
}

interface Props
  extends WithApolloClient<{}>,
    WithRegistrationForm,
    RouteComponentProps<{
      patientId?: string;
    }> {}

class RegistrationFileAttachmentsApollo extends Component<Props, State> {
  private userIsPSO = false;

  public constructor(props: Props) {
    super(props);
    this.state = { saveStatus: SavingStatus.SAVED, pageViewed: false };
  }

  public componentDidMount(): void {
    const { client } = this.props;
    let viewedPages: string[] = [];

    // Get the apollo cache values
    this.getApolloCache().then((apolloCache): void => {
      const currentPendingSaveCount = apolloCache.currentPendingSaveCount;
      const saveErrorCount = apolloCache.saveErrorCount;
      const registrationPagesViewed = apolloCache.registrationPagesViewed;
      viewedPages = registrationPagesViewed;
      this.setState({
        saveStatus: this.props.getSaveStatus(currentPendingSaveCount, saveErrorCount),
        pageViewed: registrationPagesViewed.includes(BASE_REGISTRATION_PAGES.PREFERENCES),
      });

      if (!viewedPages.includes(BASE_REGISTRATION_PAGES.PREFERENCES)) {
        viewedPages = [...viewedPages, BASE_REGISTRATION_PAGES.PREFERENCES];
      }

      client &&
        client.writeQuery({
          query: gql`
            query {
              registrationPagesViewed
            }
          `,
          data: {
            registrationPagesViewed: viewedPages,
          },
        });
    });
  }

  public render(): JSX.Element {
    const { match, showModalIfLocked } = this.props;
    const { patientId } = match.params;

    return (
      <Query<PatientAndUserDetailsQueryData>
        query={PATIENT_AND_USER_DETAILS_QUERY}
        variables={{ id: patientId }}
        onCompleted={(data: { patient: Patient }): void => {
          showModalIfLocked(data);
        }}>
        {({ loading, error, data, refetch }): JSX.Element => {
          if (loading) return <LoadingSpinner />;

          if (data && data.patient) {
            this.userIsPSO = data.user.isPso || false;

            // Filter out attachments that were not uploaded as part of the registration process
            let patient = data.patient;
            const hasAttachments = data.patient && data.patient.attachments.length;

            if (hasAttachments) {
              patient = {
                ...patient,
                attachments: patient.attachments.filter((item: PatientAttachment) => item.isRegistrationAttachment),
              };
            }

            return (
              <Fragment>
                <ExtendLock accessPatientId={data.patient.id} />
                <RegistrationFileAttachments
                  attachments={patient}
                  genderRefData={data.genderRefData}
                  refetchCallback={refetch}
                  documentTypeRefData={this.formatdocumentTypeRefData(data.documentTypeRefData)}
                  saveStatus={this.state.saveStatus}
                  validateOnLoad={this.state.pageViewed}
                  isPso={this.userIsPSO}
                  user={data.user}
                  updateAttachmentAcknowledged={this.updateAttachmentAcknowledged}
                />
              </Fragment>
            );
          }

          if (error) return <div>{`Error loading: ${error}`}</div>;
          return <div />;
        }}
      </Query>
    );
  }

  private formatdocumentTypeRefData = (documentTypeRefData: DocumentTypeData[]): ListData[] => {
    return documentTypeRefData.map((value) => {
      const valueCopy = lodash.cloneDeep(value);
      if (valueCopy.conceptCode) {
        valueCopy.id = valueCopy.conceptCode!;
        delete valueCopy.conceptCode;
      }
      return valueCopy;
    });
  };

  private updateAttachmentAcknowledged = (patientId: string, attachmentAcknowledged: boolean): void => {
    const { client } = this.props;

    client
      ?.mutate({
        mutation: ATTACHMENT_ACKNOWLEDGED_MUTATION,
        variables: { id: patientId, attachmentAcknowledged: attachmentAcknowledged },
        optimisticResponse: {
          __typename: 'Mutation',
          updatePatient: {
            patient: {
              id: patientId,
              attachmentAcknowledged: attachmentAcknowledged,
              __typename: 'PatientType',
            },
            __typename: 'PatientType',
          },
        },
      })
      .catch((): void => {
        logger.debug('autosave', `**** FAILED TO SAVE TO PATIENT ${patientId} ****`);
      });
  };

  private getApolloCache = async (): Promise<{
    currentPendingSaveCount: number;
    saveErrorCount: number;
    registrationPagesViewed: string[];
  }> => {
    const { client } = this.props;
    try {
      const apolloCache = await client?.query({ query: GET_APOLLO_CACHE });
      const currentPendingSaveCount = apolloCache?.data.pendingSaveCount;
      const saveErrorCount = apolloCache?.data.saveErrorCount;
      const registrationPagesViewed = apolloCache?.data.registrationPagesViewed;
      return {
        currentPendingSaveCount: currentPendingSaveCount,
        saveErrorCount: saveErrorCount,
        registrationPagesViewed: registrationPagesViewed,
      };
    } catch (error) {
      throw error;
    }
  };
}

const apolloComponent = withApollo<Props>(RegistrationFileAttachmentsApollo);
const component = withRegistrationForm(apolloComponent);
export default component;
