export const ARCHIVE = {
  CONFIRMATION: {
    NO_NAME: 'You are about to delete this patient. This action cannot be undone. Are you sure you want to proceed?',
    NAME_PRE: 'You are about to delete the patient, ',
    NAME_POST: '. This action cannot be undone. Are you sure you want to proceed?',
  },
  MODAL: {
    HEADER: 'Really delete patient?',
  },
};
