import { styled } from '@mui/system';

interface BadgeProps {
  readonly $bgColor?: string;
}

const Badge = styled('div')<BadgeProps>`
  color: white;
  background-color: ${(props) => props.$bgColor};
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 50%;
  width: 20px;
  height: 20px;
  padding-left: 0.1em;
  padding-right: 0.1em;
  margin-left: 4px;
  font-size: 12px;
  font-weight: bold;
`;

Badge.defaultProps = {
  $bgColor: 'black',
};

export default Badge;
