// eslint-disable-next-line no-use-before-define
import React, { Component } from 'react';

import { UKPatientDemographics } from 'op-interfaces';
import { SavingStatus } from 'shared-components/enums';

import { DropDownField, SectionField } from 'shared-components/components/FormFields';
import { ListData } from 'shared-components/interfaces';
import { RegistrationContainer } from 'op-components';
import validate from 'validate.js';

const FORM_HEADING = 'Demographics';

const FIELD_INFO = {
  ETHNICITY: {
    key: 'ethnicity',
    name: 'ethnicity',
    emptyMessage: 'Please select one of the options',
    heading: 'Ethnicity',
    placeholder: '',
  },
  RELIGION: {
    key: 'religion',
    name: 'religion',
    emptyMessage: 'Please select one of the options',
    heading: 'Religion',
    placeholder: '',
  },
  MARITAL_STATUS: {
    key: 'maritalStatus',
    name: 'maritalStatus',
    emptyMessage: 'Please select one of the options',
    heading: 'Marital status',
    placeholder: '',
  },
};

interface State {
  viewed: Set<string>;
}

interface Props {
  autosave: (patient: UKPatientDemographics, key: string, value: string) => void;
  patient: UKPatientDemographics;
  genderRefData: ListData[];
  ethnicityRefData: ListData[];
  religionRefData: ListData[];
  maritalStatusRefData: ListData[];
  saveStatus: SavingStatus;
  isPso: boolean;
}

class UKRegistrationDemographics extends Component<Props, State> {
  public constructor(props: Props) {
    super(props);
    this.state = {
      viewed: new Set(),
    };
  }

  public render(): JSX.Element {
    const { patient, genderRefData, isPso, saveStatus } = this.props;
    return (
      <RegistrationContainer patient={patient} genderRefData={genderRefData} isPso={isPso} saveStatus={saveStatus}>
        {this.renderRegistrationBasicDetailsForm(patient)}
      </RegistrationContainer>
    );
  }

  /**
   * Autosave a field and it's values to the database
   * @param patient
   * @param key
   * @param value
   * @param type
   */
  private autosave(patient: UKPatientDemographics, key: string, value: string, validateOnBlur = true): void {
    this.props.autosave(patient, key, value);
    if (validateOnBlur) {
      const viewed = this.state.viewed.add(key);
      this.setState({ viewed });
    }
  }

  /**
   * Validate the whole form
   */
  private validateForm(): void {
    const fields = [FIELD_INFO.ETHNICITY.key, FIELD_INFO.RELIGION.key, FIELD_INFO.MARITAL_STATUS.key];

    const viewed = new Set(fields);
    this.setState({ viewed });
  }

  /**
   * Renders the registration form
   * @param {Patient['patient']} patient The patient object for the form we are updating
   * @return {JSX.Element}
   */
  private renderRegistrationBasicDetailsForm(patient: UKPatientDemographics): JSX.Element {
    const { ethnicityRefData, religionRefData, maritalStatusRefData } = this.props;

    // Global rules for the validator, must match the patient interface keys
    const globalValidationRules: { [key: string]: object } = {
      ethnicity: {
        presence: {
          allowEmpty: false,
          message: FIELD_INFO.ETHNICITY.emptyMessage,
        },
      },
      religion: {
        presence: {
          allowEmpty: false,
          message: FIELD_INFO.RELIGION.emptyMessage,
        },
      },
      maritalStatus: {
        presence: {
          allowEmpty: false,
          message: FIELD_INFO.MARITAL_STATUS.emptyMessage,
        },
      },
    };

    // Ensure fields that been viewed only have validation run on them
    const specificValidationRules: { [key: string]: object } = {};
    for (const viewed of this.state.viewed.keys()) {
      specificValidationRules[viewed] = globalValidationRules[viewed];
    }

    const disableFullMessages = { fullMessages: false };
    const modifiedPatient = { ...patient };

    const validationObject = validate(modifiedPatient, specificValidationRules, disableFullMessages);

    return (
      <div className="form-page">
        <form className="form-container">
          <div className="form-heading basic-details">{FORM_HEADING}</div>
          <SectionField
            isValid={!(validationObject && validationObject.ethnicity)}
            htmlFor={FIELD_INFO.ETHNICITY.name}
            title={FIELD_INFO.ETHNICITY.heading}>
            <DropDownField
              disabled={patient.lock && patient.lock.readOnly}
              inputName={FIELD_INFO.ETHNICITY.name}
              placeholder={FIELD_INFO.ETHNICITY.placeholder}
              defaultValue={patient.ethnicity}
              options={ethnicityRefData}
              onChange={(e): void => this.autosave(patient, 'ethnicity', e.target.value)}
              errors={validationObject && validationObject.ethnicity ? validationObject.ethnicity : undefined}
            />
          </SectionField>
          <SectionField
            isValid={!(validationObject && validationObject.religion)}
            htmlFor={FIELD_INFO.RELIGION.name}
            title={FIELD_INFO.RELIGION.heading}>
            <DropDownField
              disabled={patient.lock && patient.lock.readOnly}
              inputName={FIELD_INFO.RELIGION.name}
              placeholder={FIELD_INFO.RELIGION.placeholder}
              defaultValue={patient.religion}
              options={religionRefData}
              onChange={(e): void => this.autosave(patient, 'religion', e.target.value)}
              errors={validationObject && validationObject.religion ? validationObject.religion : undefined}
            />
          </SectionField>
          <SectionField
            isValid={!(validationObject && validationObject.maritalStatus)}
            htmlFor={FIELD_INFO.MARITAL_STATUS.name}
            title={FIELD_INFO.MARITAL_STATUS.heading}>
            <DropDownField
              disabled={patient.lock && patient.lock.readOnly}
              inputName={FIELD_INFO.MARITAL_STATUS.name}
              placeholder={FIELD_INFO.MARITAL_STATUS.placeholder}
              defaultValue={patient.maritalStatus}
              options={maritalStatusRefData}
              onChange={(e): void => this.autosave(patient, 'maritalStatus', e.target.value)}
              errors={validationObject && validationObject.maritalStatus ? validationObject.maritalStatus : undefined}
            />
          </SectionField>
        </form>
      </div>
    );
  }
}

export default UKRegistrationDemographics;
