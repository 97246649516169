import { gql } from '@apollo/client';

export const REGISTRATION_REFERRER_QUERY = gql`
  query RegistrationReferrer($id: ID!) {
    patient(id: $id) {
      id
      referringFirstName
      referringLastName
      referringPractice
      referringIsInMosaiq
      gpFirstName
      gpLastName
      gpPractice
      gpIsInMosaiq
      referrersOtherSpecialists
    }
    user {
      id
      isPso
    }
    profile(id: $id) {
      id
      patientFeatures(id: $id) {
        doctorReferralLookupEnabled
      }
    }
  }
`;

export const UPDATE_REGISTRATION_REFERRER_LOOKUP = gql`
  mutation UpdatePatientAuReferrerByLookup(
    $id: ID!
    $referrerType: String
    $referrerId: ID
    $firstName: String
    $lastName: String
    $practice: String
  ) {
    updatePatientAuReferrerByLookup(
      id: $id
      referrerType: $referrerType
      referrer: { referrerId: $referrerId, firstName: $firstName, lastName: $lastName, practice: $practice }
    ) {
      patient {
        id
        referringFirstName
        referringLastName
        referringPractice
        referringIsInMosaiq
        gpFirstName
        gpLastName
        gpPractice
        gpIsInMosaiq
        referrersOtherSpecialists
      }
    }
  }
`;

export const UPDATE_REGISTRATION_REFERRER = gql`
  mutation UpdatePatientAuReferrer($id: ID!, $referrersOtherSpecialists: String) {
    updatePatientAuReferrer(id: $id, referrersOtherSpecialists: $referrersOtherSpecialists) {
      patient {
        id
        referringFirstName
        referringLastName
        referringPractice
        referringIsInMosaiq
        gpFirstName
        gpLastName
        gpPractice
        gpIsInMosaiq
        referrersOtherSpecialists
      }
    }
  }
`;
