import { gql } from '@apollo/client';

export const RECORD_LOCK_QUERY = gql`
  query LockDetails($id: ID!) {
    patient(id: $id) {
      id
      lock {
        lockedBy
        readOnly
        lockedByName
      }
    }
  }
`;

export const RELEASE_LOCK_MUTATION = gql`
  mutation ReleaseLock($accessPatientId: ID!) {
    releaseLock(accessPatientId: $accessPatientId) {
      success
    }
  }
`;
