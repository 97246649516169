import moment from 'moment';
import React, { useMemo } from 'react';
import { Months } from 'shared-components/enums';
import { DropDownProps } from './DropDownProps';
import { BaseSelect } from 'shared-components/components/FormFields';

interface DropDownROMonthProps extends Omit<DropDownProps, 'options'> {
  selectedYear?: number;
  useLastOption: boolean;
}

const DropDownROMonth = (props: DropDownROMonthProps) => {
  const { selectedYear, value, onChange, useLastOption, ...otherProps } = props;

  const monthOptions = useMemo(() => {
    const monthOptions = Months.map((data) => ({ label: data, value: data }));
    const currentYear = parseInt(moment().format('YYYY'));

    if (selectedYear === currentYear) {
      const maxMonth = parseInt(moment().format('MM'));
      return monthOptions.slice(0, maxMonth);
    }

    return monthOptions;
  }, [selectedYear]);

  const modifiedValue = useMemo(() => {
    if (monthOptions.find((a) => a.value == value)) {
      return value;
    }

    let returnValue = '';
    if (value && useLastOption) {
      returnValue = monthOptions[monthOptions.length - 1].value;
    }

    onChange(returnValue);
    return returnValue;
  }, [value, monthOptions]);

  return (
    <BaseSelect
      value={modifiedValue}
      options={[{ label: '--', value: '' }, ...monthOptions]}
      onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
        onChange(event.target.value);
      }}
      sx={{
        '.MuiOutlinedInput-root': {
          backgroundColor: 'white',
        },
        width: '120px',
        paddingRight: '8px',
        paddingBottom: '6px',
      }}
      {...otherProps}
    />
  );
};

DropDownROMonth.defaultProps = {
  required: true,
  readonly: false,
  searchable: true,
  useLastOption: false,
  placeholder: 'Month',
};

export default DropDownROMonth;
