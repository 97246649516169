import React from 'react';
import { ROAutocomplete, ROTextField, ROToggleButtons } from 'shared-components/components/FormFields';
import { BolusVersionProps } from '.';
import { SelectOptionType } from 'shared-components/components/FormFields';
import { Typography, useTheme } from '@mui/material';

export const validationV2 = [
  {
    parent: 'bolus',
    expansionValue: 'true',
    children: ['bolusLocationType', 'bolusThickness', 'bolusFrequency'],
  },

  {
    parent: 'bolusLocationType',
    expansionValue: 'Specify location',
    children: ['bolusLocation'],
  },
];
const PrescriptionBolusFormV2 = ({
  options,
  site,
  updatePrescription,
  updateData,
  getBolusValue,
  cpotReadOnly,
  state,
  setState,
  isFieldValid,
}: BolusVersionProps) => {
  const theme = useTheme();
  return (
    <>
      <ROToggleButtons
        id="treatmentBolus"
        fieldlabel="Bolus"
        options={options.bolus}
        value={getBolusValue()}
        disabled={cpotReadOnly}
        handleChange={(value): void => {
          const bolusBool = value === 'yes';
          setState({
            ...state,
            bolus: { value: bolusBool.toString(), isShown: true },
            bolusLocationType: '',
            bolusLocation: '',
            bolusThickness: '',
            bolusThicknessCustom: '',
            bolusFrequency: '',
            is3dBolus: { value: '', isShown: false },
          });

          const is3dBolusField = state?.is3dBolus?.isShown ? { is3dBolus: '' } : {};
          updatePrescription({
            variables: {
              siteId: site.id,
              bolus: bolusBool,
              bolusLocationType: '',
              bolusLocation: '',
              bolusThickness: '',
              bolusThicknessCustom: '',
              bolusFrequency: '',
              ...is3dBolusField,
            },
          });
        }}
      />
      {state?.bolus?.value === 'true' && (
        <>
          {state.is3dBolus?.isShown && (
            <ROToggleButtons
              id="is3dBolus"
              fieldlabel="3D Bolus"
              options={options.is3dBolus}
              value={state.is3dBolus?.value || ''}
              error={!isFieldValid(state.is3dBolus)}
              handleChange={(value): void => {
                setState({ ...state, is3dBolus: { value: value, isShown: true } });
                updatePrescription({
                  variables: { siteId: site.id, is3dBolus: value },
                });
              }}
              disabled={cpotReadOnly}
            />
          )}
          <ROToggleButtons
            id="bolusLocationType"
            fieldlabel="Bolus Location"
            options={options.bolusLocationType}
            value={state.bolusLocationType}
            required
            error={!isFieldValid(state.bolusLocationType)}
            handleChange={(value): void => {
              setState({ ...state, bolusLocationType: value });
              updatePrescription({
                variables: {
                  siteId: site.id,
                  bolusLocationType: value,
                  bolusLocation: value === 'Specify location' ? '' : value,
                },
              });
            }}
            disabled={cpotReadOnly}
          />
          {state.bolusLocationType === 'Specify location' && (
            <ROTextField
              id="treatmentBolusLocation"
              fieldlabel="Bolus Location Specify"
              value={state.bolusLocation}
              onChange={(event: React.ChangeEvent<HTMLInputElement>): void => {
                setState({ ...state, bolusLocation: event.target.value });
              }}
              onBlur={() => updateData()}
              error={!isFieldValid(state.bolusLocation)}
              disabled={cpotReadOnly}
            />
          )}
          <ROAutocomplete
            id="treatmentBolusThickness"
            fieldlabel="Bolus Thickness"
            options={options.bolusThickness}
            value={{ value: state.bolusThickness, label: state.bolusThickness }}
            required
            inputProps={{
              error: !isFieldValid(state.bolusThickness),
            }}
            onChange={(option: SelectOptionType | string): void => {
              const value = typeof option === 'string' ? option : option?.value;
              setState({ ...state, bolusThickness: value, bolusThicknessCustom: '' });
              updatePrescription({
                variables: { siteId: site.id, bolusThickness: value, bolusThicknessCustom: '' },
              });
            }}
            disabled={cpotReadOnly}
          />
          {state?.bolusThickness === 'Custom' && (
            <ROTextField
              id="treatmentBolusThicknessCustom"
              value={state.bolusThicknessCustom}
              onChange={(event: React.ChangeEvent<HTMLInputElement>): void => {
                setState({ ...state, bolusThicknessCustom: event.target.value });
              }}
              onBlur={() => updateData()}
              disabled={cpotReadOnly}
            />
          )}
          <ROAutocomplete
            id="bolusFrequency"
            fieldlabel="Bolus Frequency"
            options={options.bolusFrequency}
            value={{ value: state.bolusFrequency, label: state.bolusFrequency }}
            required
            inputProps={{
              error: !isFieldValid(state.bolusFrequency),
            }}
            onChange={(option: SelectOptionType | string): void => {
              const value = typeof option === 'string' ? option : option?.value;
              setState({ ...state, bolusFrequency: value });
              updatePrescription({ variables: { siteId: site.id, bolusFrequency: value } });
            }}
            disabled={cpotReadOnly}
          />
        </>
      )}
    </>
  );
};
export default PrescriptionBolusFormV2;
