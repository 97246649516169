import { gql } from '@apollo/client';

export const ATTACHMENT_ACKNOWLEDGED_MUTATION = gql`
  mutation UpdateAttachmentAcknowledgement($patientId: ID!, $attachmentAcknowledged: Boolean!) {
    updateAttachmentAcknowledgement(patientId: $patientId, attachmentAcknowledged: $attachmentAcknowledged) {
      patient {
        id
        attachmentAcknowledged
      }
    }
  }
`;

export const DOCUMENT_UPLOAD_QUERY = gql`
  query DocumentUploadQuery($id: ID!) {
    patient(id: $id) {
      id
      attachments {
        id
        documentType
        encounterDate
        isRegistrationAttachment
        filename
        filesize
        populatedDocumentType
        submitted
        suggestedDocumentType
        url
      }
      attachmentAcknowledged
    }
    documentTypeRefData: attachmentTypes {
      id
      conceptCode
      name: conceptDisplay
    }
    user {
      id
      isPso
    }
  }
`;
