// eslint-disable-next-line no-use-before-define
import { Form, Formik, FormikProps } from 'formik';
import { FormContainer } from 'op-components';
import { mapListData } from 'op-utils';
import { RegistrationContext } from 'op-contexts';
import React, { useContext } from 'react';
import { generatePath } from 'react-router';
import { useHistory, useParams } from 'react-router-dom';
import { CheckboxField } from 'shared-components/components/UIFormComponents';
import { ListData } from 'shared-components/interfaces';
import { getRemSize } from 'shared-components/StyledComponents/functions';
import { styled } from '@mui/system';
import {
  CONTINUE_BUTTON_TEXT,
  HeaderSubTitle,
  HeaderTitle,
  PSO_SUMMARY_LINK,
  registrationPath,
  RETURN_TO_SUBMIT_TEXT,
  StyledIndentedSection,
  sharedFormContainerProps,
  standardField,
} from '../Helper';
import { PatientFeedback, RegistrationFormUSIProps } from '../interfaces';
import { EXPERIENCE_TEXT, FIELDS, WILLING_TEXT } from './constants';

const TextHeading = styled('div')`
  font-size: ${getRemSize(16)};
  font-weight: bold;
  padding-bottom: 10px;
  padding-top: 10px;
`;

interface FeedbackFormIProps extends RegistrationFormUSIProps {
  patient: PatientFeedback;
  readonly refData: {
    readonly heardAboutRefData: ListData[];
    readonly internetForResearchRefData: ListData[];
  };
}

const FeedbackForm = (props: FeedbackFormIProps): JSX.Element => {
  const {
    updateField,
    patient,
    handleShowSaveExitConfirm,
    isPso,
    refData: { heardAboutRefData, internetForResearchRefData },
  } = props;

  const registrationContext = useContext(RegistrationContext);
  const history = useHistory();
  const { patientId } = useParams<{ patientId: string }>();

  const goToSummary = registrationContext?.registrationSummaryVisited && !isPso;
  const referringPage = sessionStorage.getItem('referringPage');

  return (
    <Formik initialValues={patient} onSubmit={() => history.push(registrationPath(patientId, 'summary'))}>
      {({ submitForm, values }: FormikProps<PatientFeedback>) => {
        const sharedProps = sharedFormContainerProps('Your Feedback', 8, '', !isPso);
        const formContainerProps = {
          ...sharedProps,
          submitButtonText: goToSummary ? RETURN_TO_SUBMIT_TEXT : CONTINUE_BUTTON_TEXT,
          backLink: registrationPath(patientId, 'socialHistory'),
          handleShowSaveExitConfirm,
          saveAndExitLink: isPso && generatePath(referringPage || PSO_SUMMARY_LINK, { patientId }),
        };

        return (
          <FormContainer
            {...formContainerProps}
            submitForm={submitForm}
            handleValidation={(event: React.MouseEvent<HTMLInputElement>): void => {
              void event;
            }}>
            <Form>
              <HeaderTitle data-test-id="us-rego-page-title">{FIELDS.FORM_HEADING.TITLE}</HeaderTitle>
              <HeaderSubTitle data-test-id="us-rego-page-subtitle">* = required field</HeaderSubTitle>
              {standardField(FIELDS.HEARD_ABOUT_US, updateField, mapListData(heardAboutRefData))}
              {values?.heardAboutUs === 'Other' && (
                <StyledIndentedSection>{standardField(FIELDS.HEARD_ABOUT_US_OTHER, updateField)}</StyledIndentedSection>
              )}
              {standardField(FIELDS.INTERNET_FOR_RESEARCH, updateField, mapListData(internetForResearchRefData))}
              <>
                <TextHeading className="label-form-row" data-test-id="share-exp-div">
                  Share your experience
                </TextHeading>
                <div data-test-id="experience-text-div">{EXPERIENCE_TEXT}</div>
                <CheckboxField
                  label={FIELDS.AGREE_TO_SURVEY.TITLE}
                  name="agreeToSurvey"
                  handleMutation={(value: boolean) => updateField(FIELDS.AGREE_TO_SURVEY.NAME, value)}
                  field={{ value: patient.agreeToSurvey }}
                  width="100%"
                  marginTop="30px"
                  marginBottom="30px"
                />
                <div data-test-id="willing-text-div">{WILLING_TEXT}</div>
              </>
            </Form>
          </FormContainer>
        );
      }}
    </Formik>
  );
};

export default FeedbackForm;
