import React, { createContext, useState } from 'react';

export const ClinexContext = createContext<any>({});

export const ClinexProvider = ({ children }: any): JSX.Element => {
  const [pages, setPages] = useState<any[]>([]);
  const [pageIndex, setPageIndex] = useState(0);
  const [indexLock, setIndexLock] = useState(false);
  const [highlights, setHighlights] = useState<any>({});
  const [fieldSelected, setFieldSelected] = useState();
  const [clinexData, setClinexData] = useState<any>();
  const [clinexDocuments, setClinexDocuments] = useState<any>({});
  const [clinexModalOpen, setClinexModalOpen] = useState<boolean>(false);
  const [uuid, setUuid] = useState<string>();
  const [intakeFieldsLoaded, setIntakeFieldsLoaded] = useState(false);
  const [showClinexDocument, setShowClinexDocument] = useState(true);
  return (
    <ClinexContext.Provider
      value={{
        pageIndex,
        setPageIndex,
        indexLock,
        setIndexLock,
        pages,
        setPages,
        highlights,
        setHighlights,
        fieldSelected,
        setFieldSelected,
        clinexData,
        setClinexData,
        clinexDocuments,
        setClinexDocuments,
        clinexModalOpen,
        setClinexModalOpen,
        uuid,
        setUuid,
        intakeFieldsLoaded,
        setIntakeFieldsLoaded,
        showClinexDocument,
        setShowClinexDocument,
      }}>
      {children}
    </ClinexContext.Provider>
  );
};
