import { Field, Formik, FormikProps } from 'formik';
import React, { RefObject } from 'react';
import FakeRadioButton from 'shared-components/components/FakeRadioButton/FakeRadioButton';
import { TabSelectorValueTitle } from 'shared-components/components/TabSelector/StyledComponents';
import { NumberInput } from '../PrescriptionPage/Components/ModalComponents';
import * as Yup from 'yup';
import { ProtocolTemplateInterface } from 'op-pages/MO/interfaces';
import { Grid } from '@mui/material';

const validationSchema = Yup.object().shape({
  startingCycle: Yup.number().min(1, 'Please enter a valid cycle number').required('Please enter a valid cycle number'),
});
export interface StartingCycleInterface {
  startingCycle: number;
  maxCycles: number;
  isContinuous: boolean;
}

interface ProtocolSelectionTabTitleProps {
  selectedValue: string;
  protocol: ProtocolTemplateInterface;
  startingCycleRef: RefObject<FormikProps<StartingCycleInterface>>;
  startingCycle: number;
}

const ProtocolSelectionTabTitle = (props: ProtocolSelectionTabTitleProps): JSX.Element => {
  const { protocol, selectedValue, startingCycleRef, startingCycle } = props;
  return (
    <TabSelectorValueTitle>
      <Grid container alignItems="center">
        <Grid item xs={protocol.id === selectedValue ? 7 : 12} spacing={1}>
          <div style={{ display: 'flex', alignItems: 'center' }}>
            <FakeRadioButton selected={protocol.id === selectedValue} />
            <span>{protocol.name}</span>
          </div>
        </Grid>
        <Grid item xs={protocol.id === selectedValue ? 5 : 1}>
          {protocol.id === selectedValue ? (
            <Formik
              innerRef={startingCycleRef}
              initialValues={{
                startingCycle: startingCycle,
                maxCycles: protocol.numberOfCycles,
                isContinuous: protocol.continuous,
              }}
              onSubmit={() => {}}
              validationSchema={validationSchema}
              validateOnChange={true}
              validateOnBlur={true}>
              {({ setFieldTouched, handleChange, errors }: FormikProps<StartingCycleInterface>) => {
                return (
                  <div
                    style={{
                      display: 'flex',
                      marginLeft: 'auto',
                      alignItems: 'center',
                      fontSize: '14px',
                      textAlign: 'right',
                    }}>
                    <Field
                      component={NumberInput}
                      label="Starting&nbsp;Cycle:"
                      data-test-id={'starting-cycle'}
                      name="startingCycle"
                      inputXs="8"
                      reserveUnitsSpace={false}
                      error={errors.startingCycle}
                      onChange={(e: any) => {
                        setFieldTouched('startingCycle');
                        handleChange(e);
                      }}
                    />
                  </div>
                );
              }}
            </Formik>
          ) : (
            <div></div>
          )}
        </Grid>
      </Grid>
    </TabSelectorValueTitle>
  );
};

export default ProtocolSelectionTabTitle;
