import React, { useEffect, useState, useRef, FunctionComponent, CSSProperties } from 'react';
import { ExpandLess, ExpandMore } from '@mui/icons-material';
import { AccordionDetails, AccordionSummary, Accordion as MuiAccordion, Typography, useTheme } from '@mui/material';

interface StyledProps {
  $isOpen?: boolean;
}

interface TitleType {
  open: string;
  closed: string;
}

interface Props {
  title: string | TitleType;
  useAltTitle?: boolean;
  subtitle?: string | JSX.Element;
  badgeValue?: string | number;
  children: any;
  open?: boolean;
  style?: CSSProperties;
  className?: string;
  onOpen?: () => void;
  onClose?: () => void;
  readOnly?: boolean;
}

const Accordion: FunctionComponent<Props> = ({
  title,
  children,
  open,
  readOnly = false,
  onOpen,
  onClose,
}: Props): JSX.Element => {
  const [isOpen, setOpen] = useState(open);
  const initialRender = useRef(true);
  const theme = useTheme();

  useEffect(() => {
    if (initialRender.current) {
      initialRender.current = false;
      return;
    } else {
      if (isOpen && onOpen) {
        onOpen();
      } else if (!isOpen && onClose) {
        onClose();
      }
    }
  }, [isOpen]);
  return (
    <MuiAccordion
      data-testid="accordion"
      disabled={readOnly}
      expanded={isOpen}
      onChange={() => {
        if (!readOnly) setOpen(!isOpen);
      }}>
      <AccordionSummary>
        <>
          {isOpen ? (
            <ExpandLess fontSize="large" color="primary" />
          ) : (
            <ExpandMore fontSize="large" color={readOnly ? 'disabled' : 'primary'} />
          )}
          <Typography variant="subtitle1" style={{ alignSelf: 'center' }}>
            {typeof title === 'string' && title}
            {typeof title === 'object' ? (isOpen ? title.open : title.closed) : null}
          </Typography>
        </>
      </AccordionSummary>
      <AccordionDetails>{children}</AccordionDetails>
    </MuiAccordion>
  );
};

export default Accordion;
