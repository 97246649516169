import { FormikValues, validateYupSchema, yupToFormErrors } from 'formik';
import { ListData } from 'shared-components/interfaces';
import * as Yup from 'yup';
import { VALUE_REQUIRED, VIC_AMBULANCE_NUMBER_LENGTH_VALIDATION, VIC_AMBULANCE_NUMBER_VALIDATION } from '../Helper';
import { VIC_AMBULANCE_MEMBERSHIP_NUM_REGEXP } from 'shared-components/utils/Regex';

interface generateValidationSchemaIProps {
  values: FormikValues;
  readonly refData: {
    readonly maritalStatusRefData: ListData[];
    readonly atsiHeritageRefData: ListData[];
    readonly countryOfBirthRefData: ListData[];
    readonly languageSpokenRefData: ListData[];
  };
}

export const generateValidationSchema = (props: generateValidationSchemaIProps): any => {
  const {
    values,
    refData: { maritalStatusRefData, atsiHeritageRefData, countryOfBirthRefData, languageSpokenRefData },
  } = props;

  const countryOfBirthRefDataValuesList: string[] = countryOfBirthRefData.map((item: ListData) => item.name);
  const languageSpokenRefDataValuesList: string[] = languageSpokenRefData.map((item: ListData) => item.name);
  const atsiHeritageRefDataValuesList: string[] = atsiHeritageRefData.map((item: ListData) => item.name);
  const maritalStatusRefDataValuesList: string[] = maritalStatusRefData.map((item: ListData) => item.name);

  const basicSchema = {
    countryOfBirth: Yup.string()
      .ensure()
      .required(VALUE_REQUIRED)
      .oneOf(countryOfBirthRefDataValuesList, VALUE_REQUIRED),
    languageAtHome: Yup.string()
      .ensure()
      .required(VALUE_REQUIRED)
      .oneOf(languageSpokenRefDataValuesList, VALUE_REQUIRED),
    heritage: Yup.string().ensure().required(VALUE_REQUIRED).oneOf(atsiHeritageRefDataValuesList, VALUE_REQUIRED),
    maritalStatus: Yup.string().ensure().required(VALUE_REQUIRED).oneOf(maritalStatusRefDataValuesList, VALUE_REQUIRED),
    ambulanceMembershipNumber: Yup.string()
      .nullable()
      .when('ambulanceMembership', {
        is: true,
        then: Yup.string()
          .ensure()
          .max(20, VIC_AMBULANCE_NUMBER_LENGTH_VALIDATION)
          .required(VALUE_REQUIRED)
          .matches(VIC_AMBULANCE_MEMBERSHIP_NUM_REGEXP, {
            message: VIC_AMBULANCE_NUMBER_VALIDATION,
          }),
      }),
  };

  try {
    validateYupSchema<FormikValues>(values, Yup.object(basicSchema), true);
  } catch (err) {
    return yupToFormErrors(err);
  }

  return {};
};
