// eslint-disable-next-line no-use-before-define
import React from 'react';
import { Oops } from 'shared-components/images';
import { MODAL_HEADER, ERROR, CONTACT_SUPPORT, SEE_FRONT_DESK, HOME, LOGOUT } from './constants';
import { StandardDialog } from 'shared-components/components';
import { Stack, Typography } from '@mui/material';

interface Props {
  isPSO: boolean;
  isOpen: boolean;
  callbackRouting?: () => void;
}

const ModalInfo = (props: Props) => {
  const { isOpen, isPSO } = props;
  return (
    <StandardDialog
      open={isOpen}
      title={MODAL_HEADER}
      onSubmit={() => {
        if (props.callbackRouting) {
          props.callbackRouting();
        }
      }}
      submitText={isPSO ? HOME : LOGOUT}>
      <Stack paddingY={2}>
        <Oops />
        <Typography>{ERROR}</Typography>
        <Typography>{isPSO ? CONTACT_SUPPORT : SEE_FRONT_DESK}</Typography>
      </Stack>
    </StandardDialog>
  );
};

export default ModalInfo;
