import React, { useEffect, useState, useContext } from 'react';
import { useQuery, useMutation } from '@apollo/client';
import { useRouteMatch } from 'react-router-dom';
import { useErrorModalContext } from 'op-contexts';
import { LoadingSpinner } from 'shared-components/components';
import { GET_PEER_REVIEW, UPDATE_PEER_REVIEW_NOTES } from './SidePanelQueries';
import { BaseTextField } from 'shared-components/components/FormFields';
import { PeerReviewContext } from '../PeerReviewContext';
import { Stack, useTheme } from '@mui/material';
import SidePanelItem from './SidePanelItem';
import './SidePanel.scss';

const ROPeerReviewSidePanel = (): JSX.Element => {
  const match: { params: { careplanId: string } } = useRouteMatch();
  const { careplanId } = match.params;
  const theme = useTheme();
  const { setError } = useErrorModalContext();
  const [notes, setNotes] = useState<string>('');
  const [disableNotes, setDisableNotes] = useState<boolean>(false);
  const { setPeerReviewViolations } = useContext(PeerReviewContext);
  const { loading, error, data } = useQuery(GET_PEER_REVIEW, {
    variables: { careplanId },
    onCompleted: () => {
      setNotes(data?.peerReview?.notes);
      setPeerReviewViolations(data?.peerReview?.violations);
      setDisableNotes(data?.peerReview?.disableNotes);
    },
  });

  const [updateNotes] = useMutation(UPDATE_PEER_REVIEW_NOTES, {
    refetchQueries: [{ query: GET_PEER_REVIEW, variables: { careplanId } }],
  });

  useEffect(() => {
    if (error) return setError('NON_BLOCKING');
  }, [error]);

  if (loading)
    return (
      <Stack sx={{ width: '100%' }} className="px-0">
        <LoadingSpinner loadingText={'Loading peer review'} relativeSpinner={true} />
      </Stack>
    );

  if (!data) return <div />;

  const peerReview = data?.peerReview;

  const alwaysTrigger = peerReview?.alwaysTrigger;
  const credentialsTriggered = peerReview?.credentials?.length > 0;
  const violationsTriggered = peerReview?.violations?.length > 0;

  if (!violationsTriggered && !credentialsTriggered && !alwaysTrigger) return <div />;

  const displayViolations = (violations: any) =>
    violations?.map((violation: any, index: number) => (
      <div key={`violation-wrapper-${index}`}>
        <div className="peer-review-text" key={`violation-${index}`}>
          {violation?.site?.name}
        </div>
        <div className="peer-review-text" key={`violation-peer-review-${index}`}>
          {violation?.ruleSets.map((ruleSet: any, iTwo: number) => (
            <div key={`violation-ruleSet-${index}-${iTwo}`}>
              {ruleSet.rules.map((rule: any, iThree: number) => {
                const val = rule.peerReviewValue;
                const niceVal = !isNaN(Number(val)) ? parseFloat(val) : val;
                return (
                  <div key={`violation-rule-${index}-${iTwo}-${iThree}`}>
                    {` \u2022 ${rule.field} must be ${rule.operator} ${niceVal}`}
                  </div>
                );
              })}
            </div>
          ))}
        </div>
      </div>
    ));

  const container = (
    <>
      <BaseTextField
        id="reason"
        defaultValue={notes}
        maxRows={4}
        minRows={4}
        multiline
        fullWidth
        disabled={disableNotes}
        placeholder={
          disableNotes
            ? 'Peer review already submitted'
            : 'Type your notes here... (Notes will be autosaved and added to the submission PDF)'
        }
        onBlur={(): void => {
          if (typeof notes !== 'undefined') updateNotes({ variables: { careplanId, notes } });
        }}
        onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
          setNotes(event.target.value);
        }}
        sx={{
          padding: '12px',
        }}
      />
      {alwaysTrigger && (
        <div data-test-id="pr-auto-trigger">
          <div className="peer-review-sub-heading">Automatically triggered</div>
          <div className="peer-review-text" key={'auto-trigger'}>
            {`Peer review is set to always trigger for ${alwaysTrigger}`}
          </div>
        </div>
      )}
      {credentialsTriggered && (
        <div data-test-id="pr-credentials">
          <div className="peer-review-sub-heading">Missing credentials</div>
          {peerReview?.credentials?.map((credential: string, index: number) => (
            <div className="peer-review-text" key={`credential-${index}`} data-test-id={`pr-credentials-${index}`}>
              {credential}
            </div>
          ))}
        </div>
      )}
      {violationsTriggered && (
        <div data-test-id="pr-violations">
          <div className="peer-review-sub-heading">Off careplan protocol</div>
          {displayViolations(peerReview?.violations)}
        </div>
      )}
    </>
  );

  return (
    <SidePanelItem title="Peer review" topBorderColor={theme.palette.warning.main}>
      {container}
    </SidePanelItem>
  );
};

export default ROPeerReviewSidePanel;
