import { gql } from '@apollo/client';
import { REGISTRATION_DEMOGRAPHICS_FRAGMENT, LIST_DATA_FRAGMENT } from 'op-graphql/fragments';

export const DEMOGRAPHICS_QUERY = gql`
  query RegistrationDemographicDetails($id: ID!) {
    patient(id: $id) {
      id
      ...PatientDemographics
      lastVisitedSection
      regFormStatus
    }
    raceRefData: listData(category: "race") {
      id
      ...ListData
    }
    ancestryRefData: listData(category: "ancestry") {
      id
      ...ListData
    }
    maritalStatusRefData: listData(category: "maritalStatus") {
      id
      ...ListData
    }
    languageRefData: listData(category: "languages") {
      id
      ...ListData
    }
    user {
      id
      isPso
    }
  }
  ${REGISTRATION_DEMOGRAPHICS_FRAGMENT}
  ${LIST_DATA_FRAGMENT}
`;

export const UPDATE_REGISTRATION_DEMOGRAPHIC_DETAILS = gql`
  mutation UpdatePatientUsDemographics(
    $patientId: ID!
    $primaryOccupation: String
    $maritalStatus: String
    $spouseWorkingStatus: String
    $spouseName: String
    $spouseDobRawYear: String
    $spouseDobRawMonth: String
    $spouseDobRawDay: String
    $spouseSocialSecurityNumber: String
    $spouseContactNumber: String
    $spouseAlternateAddressBool: Boolean
    $race: String
    $raceSpecified: String
    $ancestry: String
    $languageAtHome: String
  ) {
    updatePatientUsDemographics(
      patientId: $patientId
      primaryOccupation: $primaryOccupation
      maritalStatus: $maritalStatus
      spouseWorkingStatus: $spouseWorkingStatus
      spouseName: $spouseName
      spouseDobRawYear: $spouseDobRawYear
      spouseDobRawMonth: $spouseDobRawMonth
      spouseDobRawDay: $spouseDobRawDay
      spouseSocialSecurityNumber: $spouseSocialSecurityNumber
      spouseContactNumber: $spouseContactNumber
      spouseAlternateAddressBool: $spouseAlternateAddressBool
      race: $race
      raceSpecified: $raceSpecified
      ancestry: $ancestry
      languageAtHome: $languageAtHome
    ) {
      patient {
        id
        ...PatientDemographics
      }
    }
  }
  ${REGISTRATION_DEMOGRAPHICS_FRAGMENT}
`;
