import { useQuery } from '@apollo/client';
import React, { useContext, useEffect } from 'react';

import { RouteComponentProps, useParams, withRouter } from 'react-router-dom';

import { PageTitle } from 'op-components';
import { RegistrationContext, useErrorModalContext } from 'op-contexts';
import { LoadingSpinner } from 'shared-components/components';
import { ListData } from 'shared-components/interfaces';

import { HEALTH_ASSESMENT_US_SUMMARY_QUERY } from 'op-pages/OP/HealthAssessment/HASharedComponents/queries';
import { HAReviewSummaryUSItem } from 'op-pages/OP/HealthAssessment/HASharedComponents/types';
import AllergiesSensitivities from './components/AllergiesSensitivities/AllergiesSensitivities';
import MedicalHistoryBiological from './components/MedicalHistoryBiological/MedicalHistoryBiological';
import MedicalHistoryCancer from './components/MedicalHistoryCancer/MedicalHistoryCancer';
import MedicalHistoryIllness from './components/MedicalHistroyIllness/MedicalHistoryIllness';
import HAMedicationsUS from './components/Medications/HAMedicationsUS';
import HAMobilityAssessmentUS from './components/MobilityAssessment/HAMobilityAssessmentUS';
import HAOperationsUS from './components/Operations/HAOperationsUS';
import HAPainAssessmentUS from './components/PainAssessment/HAPainAssessmentUS';
import PatientInformation from './components/PatientInformation/PatientInformation';
import HASocialAssessmentUS from './components/SocialAssessment/HASocialAssessmentUS';
import './HAReviewSummaryUS.scss';
import HAPatientConsent from './components/PatientConsent/PatientConsent';

export interface HealthAssessmentQueryData {
  healthAssessment: HAReviewSummaryUSItem;
  treatmentTypeRefData: ListData[];
  treatmentStageRefData: ListData[];
  diabetesRefData: ListData[];
  immunosuppresiveConditionsRefData: ListData[];
  internalDevicesRefData: ListData[];
  intractableInfectionOrIllnessesRefData: ListData[];
  mobilityAidRefData: ListData[];
  wellbeingIssueRefData: ListData[];
  alcoholFrequencyRefData: ListData[];
  smokingFrequencyRefData: ListData[];
  exerciseFrequencyRefData: ListData[];
  sensitivityRefData: ListData[];
  reactionRefData: ListData[];
  referenceData: ListData[];
  user: {
    id: string;
    isPso: boolean;
  };
}

const HAReviewSummaryUS = ({ history }: RouteComponentProps) => {
  const { setHaSummaryVisited } = useContext(RegistrationContext);

  const { patientId } = useParams<{ patientId: string }>();

  const { data, loading, error } = useQuery<HealthAssessmentQueryData>(HEALTH_ASSESMENT_US_SUMMARY_QUERY, {
    variables: { patientId },
  });

  const { setError } = useErrorModalContext();

  useEffect(() => {
    if (error) return setError();
  }, [error]);

  useEffect(() => {
    setHaSummaryVisited(true);
  }, []);

  if (loading) return <LoadingSpinner relativeSpinner={true} />;
  if (!data) return <></>;

  const isPso = data.user.isPso;
  const pageTitle = isPso ? 'Review' : 'Review and submit';

  const navigate = (link: string) => history.push(link);

  return (
    <div className="ha-review-summary-page">
      <PageTitle title={pageTitle} idPrefix="ha" />
      <div className="newspaper-container">
        <PatientInformation isPso={isPso} navigate={navigate} healthAssessment={data.healthAssessment} />
        <MedicalHistoryCancer isPso={isPso} navigate={navigate} patientId={patientId} />
        <MedicalHistoryIllness isPso={isPso} navigate={navigate} patientId={patientId} />
        <MedicalHistoryBiological isPso={isPso} navigate={navigate} patientId={patientId} />
        <HAOperationsUS isPso={isPso} navigate={navigate} patientId={patientId} />
        <HAMobilityAssessmentUS isPso={isPso} navigate={navigate} patientId={patientId} />
        <HAPainAssessmentUS isPso={isPso} navigate={navigate} patientId={patientId} />
        <HASocialAssessmentUS isPso={isPso} navigate={navigate} patientId={patientId} />
        <HAMedicationsUS isPso={isPso} navigate={navigate} patientId={patientId} />
        <AllergiesSensitivities isPso={isPso} navigate={navigate} patientId={patientId} />
        <HAPatientConsent isPso={isPso} navigate={navigate} patientId={patientId} />
      </div>
    </div>
  );
};

export default withRouter(HAReviewSummaryUS);
