// eslint-disable-next-line no-use-before-define
import React, { Component } from 'react';

/**
 * Form Section Props interface
 * @property {string} header contains the header of the form section
 */
interface Props {
  header?: string;
  children?: React.ReactNode;
}

/**
 * Form Section component. Wraps all elements of the section and provides a sticky top header
 */
class FormSection extends Component<Props> {
  private renderHeader(): JSX.Element {
    const {
      props: { header },
    } = this;

    return <h2 className="form-section-header">{header}</h2>;
  }

  public render(): JSX.Element {
    const {
      props: { header },
    } = this;

    return (
      <div className="form-section">
        {header && this.renderHeader()} {this.props.children}
      </div>
    );
  }
}

export default FormSection;
