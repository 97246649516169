import {
  syncConfig,
  chartSpacingConfig,
  creditsConfig,
  exportingConfig,
  handlePointClick,
  TopChartParams,
  setHeight,
  selectPoint,
} from './DefaultConfig';

const ChartBar = ({
  onPointClick,
  selectedPoint,
  selectedIndex,
  seeMoreUpdated,
}: TopChartParams): Dashboards.Board.Options => ({
  components: [
    {
      cell: 'top-chart',
      connector: {
        id: 'top-chart-data',
      },
      sync: syncConfig,
      type: 'Highcharts',
      chartOptions: {
        exporting: exportingConfig,
        chart: {
          type: 'bar',
          animation: false,
          spacing: chartSpacingConfig,
          events: {
            load() {
              setHeight(this);
            },
            redraw() {
              selectPoint(this, selectedPoint, selectedIndex);
            },
          },
        },
        title: { text: '' },
        xAxis: { type: 'category' },
        yAxis: [{ title: { text: '' }, tickInterval: 10 }],
        credits: creditsConfig,
        tooltip: {
          pointFormat: '{series.name}: {point.y}<br/>',
        },
        plotOptions: {
          series: {
            animation: selectedPoint === '' && !seeMoreUpdated,
            states: {
              select: {
                enabled: false,
              },
            },
            point: {
              events: {
                click: function () {
                  handlePointClick(this, onPointClick);
                },
              },
            },
          },
        },
      },
    },
  ],
});

export default ChartBar;
