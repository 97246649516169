import React from 'react';
import { styled } from '@mui/system';
import { StandardDialog } from 'shared-components/components';
import { Typography, Stack } from '@mui/material';

interface Props {
  submitFormMutation: any;
  title?: string;
  isOpen: boolean;
  setIsOpen: any;
  submitText?: string;
  pdfText?: string;
  formText?: string;
}

const ModalSubmitForm = ({
  submitFormMutation,
  title = 'Confirm and Submit',
  isOpen,
  setIsOpen,
  submitText = 'Submit plan',
  formText = 'By submitting you confirm that you have reviewed all information on this form and ensured all details are correct.',
  pdfText,
}: Props): JSX.Element => {
  const submitFormText = (
    <Stack paddingY={2}>
      <Typography>{formText}</Typography>
      {pdfText && (
        <Typography paddingTop={2}>
          Once submitted, a PDF of this registration from will be sent to EMR. Patient information will be updated in
          both systems.
        </Typography>
      )}
    </Stack>
  );

  return (
    <StandardDialog
      open={isOpen}
      title={title}
      onClose={() => setIsOpen(false)}
      submitText={submitText}
      onSubmit={() => {
        submitFormMutation();
        setIsOpen(false);
      }}>
      {submitFormText}
    </StandardDialog>
  );
};

export default ModalSubmitForm;
