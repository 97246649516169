// eslint-disable-next-line no-use-before-define
import React, { Component } from 'react';
import classNames from 'classnames';

import { RouteComponentProps, withRouter } from 'react-router-dom';

import './NavigationBar.scss';

import { NavigationType } from 'op-enums';

import { Calendar, MyDetails } from 'shared-components/images';
import { DescriptionOutlined as DescriptionOutlinedIcon } from '@mui/icons-material';

interface NavItemObject {
  title: string;
  url: string;
}

interface Props extends RouteComponentProps {
  footer?: boolean;
  navOnClick?: () => void;
}

class NavigationBar extends Component<Props> {
  private navList: NavItemObject[] = [
    { title: 'Appointments', url: '/px/appointments' },
    { title: 'Information', url: '/px/information' },
    { title: 'My details', url: '/px/details' },
  ];

  public render(): JSX.Element {
    return (
      <div
        className={classNames('nav-bar-container', { footer: this.props.footer })}
        onClick={() => {
          const { navOnClick } = this.props;

          if (navOnClick) {
            navOnClick();
          }
        }}>
        {this.renderNavItems()}
      </div>
    );
  }

  private renderNavItems = (): JSX.Element[] => {
    const navItemsDOM: JSX.Element[] = this.navList.map((navItem: NavItemObject): JSX.Element => {
      // Determine if the nav item is selected from the URL path
      const pathname = this.props.location.pathname;
      const urlComponent = navItem.url;
      const selected = pathname.includes(urlComponent);

      return (
        <div
          key={navItem.title}
          onClick={(): void => {
            if (!selected || urlComponent === '/px/information') this.handleNavClick(navItem.url);
          }}
          className={classNames('nav-bar-link-button', { selected })}>
          {this.getIcon(navItem.title)}
          <span className="link-text">{navItem.title}</span>
        </div>
      );
    });

    return navItemsDOM;
  };

  private handleNavClick = (url: string): void => {
    this.props.history.push(url);
  };

  private getIcon = (type: string): JSX.Element | undefined => {
    if (this.props.footer) {
      if (type === NavigationType.APPOINTMENT) {
        return <Calendar className="icon" />;
      } else if (type === NavigationType.INFORMATION) {
        return <DescriptionOutlinedIcon color="primary" className="icon" />;
      } else {
        return <MyDetails className="icon" />;
      }
    }

    return undefined;
  };
}

const routerComponent = withRouter(NavigationBar);
export default routerComponent;
