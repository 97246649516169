import { gql } from '@apollo/client';
export const ONBOARDING_QUERY = gql`
  query userOnboardingList($featureName: String!) {
    userOnboardingList(featureName: $featureName) {
      id
      status
      hideExpired
    }
  }
`;

export const ONBOARDING_MUTATION = gql`
  mutation UpdateUserOnboarding($featureId: ID!, $status: String!) {
    updateUserOnboarding(featureId: $featureId, status: $status) {
      success
    }
  }
`;

export const USER_PREFERENCE_QUERY = gql`
  query userFeatureList($featureName: String!) {
    userFeatureList(featureName: $featureName) {
      id
      feature {
        id
        name
      }
      featureOption {
        id
        name
      }
    }
    featureList {
      id
      feature {
        id
        name
      }
      options {
        id
        name
      }
      default {
        id
        name
      }
      onboarding
    }
  }
`;
export const UPDATE_USER_PREFERENCE_MUTATION = gql`
  mutation UpdateUserPreference($featureId: ID!, $featureOptionId: ID!) {
    updateUserPreference(featureId: $featureId, featureOptionId: $featureOptionId) {
      success
    }
  }
`;
