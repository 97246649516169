import React, { Component, Fragment, ReactNode } from 'react';

import classNames from 'classnames';

import './MoreInfoText.scss';

import { Info } from 'shared-components/images';

interface Props {
  moreInfoText: string;
  showIcon?: boolean;
  showInfo?: boolean | null;
  children?: ReactNode;
}

class MoreInfoText extends Component<Props> {
  public static defaultProps = {
    showIcon: false,
  };

  public render(): ReactNode {
    const { children, moreInfoText, showIcon, showInfo } = this.props;
    return (
      <Fragment>
        {children}
        {(showInfo === undefined || showInfo) && (
          <div className="more-info-text">
            {showIcon === true && <Info className="icon" />}
            <span className={classNames({ 'with-icon': showIcon })}>{moreInfoText}</span>
          </div>
        )}
      </Fragment>
    );
  }
}

export default MoreInfoText;
