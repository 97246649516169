// eslint-disable-next-line no-use-before-define
import { HASocialAssessmentItem } from 'op-classes';
import { PageTitle } from 'op-components';
import { appendZeroInFront } from 'op-utils';
import { fetchRules, ValidationKeys } from 'op-utils/HealthAssessmentValidation/HealthAssessmentValidation';
import React, { Component, Fragment } from 'react';
import { DropDownROMonth, DropDownROYear } from 'op-pages/OP/HealthAssessment/HASharedComponents';
import {
  DropDownField,
  FreeTextField,
  HelperText,
  SectionField,
  SegmentedInput,
} from 'shared-components/components/FormFields';
import { Months } from 'shared-components/enums';
import { ListData, StringDictionary } from 'shared-components/interfaces';
import { SegmentedInputBoolean } from 'shared-components/utils';
import validate from 'validate.js';
import { SA_FIELDS } from '../constants';
import './HASocialAssessment.scss';

const FIELD_VALIDATION_KEYS: StringDictionary = {
  ALCOHOL_BOOL: 'alcoholBool',
  ALCOHOL_FREQUENCY: 'alcoholFrequency',
  SMOKING_BOOL: 'smokingBool',
  SMOKING_HISTORY: 'smokingHistory',
  SMOKING_FREQUENCY: 'smokingFrequency',
  EXERCISE_BOOL: 'exerciseBool',
  EXERCISE_FREQUENCY: 'exerciseFrequency',
  ACCOMMODATION_BOOL: 'accommodationAssistance',
  ACCOMMODATION_REASON: 'accommodationAssistanceReason',
  TRANSPORTATION_BOOL: 'transportationAssistance',
  TRANSPORTATION_REASON: 'transportationAssistanceReason',
};

interface State {
  viewedFields: Set<string>;
}

interface Props {
  autosave: (updateObject: object) => Promise<void>;
  patientId: string;
  haSocialAssessment: HASocialAssessmentItem;
  validateOnLoad: boolean;
  alcoholFrequencyRefData: ListData[];
  smokingFrequencyRefData: ListData[];
  exerciseFrequencyRefData: ListData[];
  isPso: boolean;
}
class HASocialAssessment extends Component<Props, State> {
  public constructor(props: Props) {
    super(props);

    this.state = {
      viewedFields: new Set(),
    };
  }

  public static getDerivedStateFromProps(props: Props, state: State): State {
    if (props.validateOnLoad) {
      const fields = Object.keys(FIELD_VALIDATION_KEYS).map((keyName: string) => {
        return FIELD_VALIDATION_KEYS[keyName];
      });
      const viewed = new Set(fields);
      return { viewedFields: viewed };
    }

    return state;
  }

  public render(): JSX.Element {
    const { haSocialAssessment } = this.props;
    const validationObject = this.validateObject(haSocialAssessment);

    return (
      <Fragment>
        <PageTitle title={'Social assessment'} idPrefix="ha" />
        <div id="ha-fields">
          <SectionField htmlFor={SA_FIELDS.ALCOHOL.NAME} title={SA_FIELDS.ALCOHOL.TITLE}>
            <SegmentedInput
              fieldName={SA_FIELDS.ALCOHOL.NAME}
              options={SegmentedInputBoolean}
              optionAreBoolean={true}
              defaultSelected={haSocialAssessment.alcoholBool}
              itemSelected={(selectedItem): void => {
                const selectedValue = selectedItem as boolean;
                this.autoSaveAndValidate(
                  {
                    id: parseInt(this.props.patientId),
                    alcoholBool: selectedValue,
                  },
                  FIELD_VALIDATION_KEYS.ALCOHOL_BOOL,
                );
              }}
            />
          </SectionField>
          {this.renderDrinkerFields()}
          <SectionField htmlFor={SA_FIELDS.SMOKING.NAME} title={SA_FIELDS.SMOKING.TITLE}>
            <SegmentedInput
              fieldName={SA_FIELDS.SMOKING.NAME}
              options={SegmentedInputBoolean}
              optionAreBoolean={true}
              defaultSelected={haSocialAssessment.smokingBool}
              itemSelected={(selectedItem): void => {
                const selectedValue = selectedItem as boolean;
                this.autoSaveAndValidate(
                  {
                    id: parseInt(this.props.patientId),
                    smokingBool: selectedValue,
                  },
                  FIELD_VALIDATION_KEYS.SMOKING_BOOL,
                );
              }}
            />
          </SectionField>
          {this.renderSmokingFields(validationObject)}

          <SectionField htmlFor={SA_FIELDS.EXERCISE.NAME} title={SA_FIELDS.EXERCISE.TITLE}>
            <SegmentedInput
              fieldName={SA_FIELDS.EXERCISE.NAME}
              options={SegmentedInputBoolean}
              optionAreBoolean={true}
              defaultSelected={haSocialAssessment.exerciseBool}
              itemSelected={(selectedItem): void => {
                const selectedValue = selectedItem as boolean;
                this.autoSaveAndValidate(
                  {
                    id: parseInt(this.props.patientId),
                    exerciseBool: selectedValue,
                  },
                  FIELD_VALIDATION_KEYS.EXERCISE_BOOL,
                );
              }}
            />
          </SectionField>
          {this.renderExerciseFields()}

          <SectionField htmlFor={SA_FIELDS.ACCOMMODATION.NAME} title={SA_FIELDS.ACCOMMODATION.TITLE}>
            <SegmentedInput
              fieldName={SA_FIELDS.ACCOMMODATION.NAME}
              options={SegmentedInputBoolean}
              optionAreBoolean={true}
              defaultSelected={haSocialAssessment.accommodationAssistance}
              itemSelected={(selectedItem): void => {
                const selectedValue = selectedItem as boolean;
                this.autoSaveAndValidate(
                  {
                    id: parseInt(this.props.patientId),
                    accommodationAssistance: selectedValue,
                  },
                  FIELD_VALIDATION_KEYS.ACCOMMODATION_BOOL,
                );
              }}
            />
          </SectionField>
          {this.renderAccomodationFields()}

          <SectionField htmlFor={SA_FIELDS.TRANSPORTATION.NAME} title={SA_FIELDS.TRANSPORTATION.TITLE}>
            <SegmentedInput
              fieldName={SA_FIELDS.TRANSPORTATION.NAME}
              options={SegmentedInputBoolean}
              optionAreBoolean={true}
              defaultSelected={haSocialAssessment.transportationAssistance}
              itemSelected={(selectedItem): void => {
                const selectedValue = selectedItem as boolean;
                this.autoSaveAndValidate(
                  {
                    id: parseInt(this.props.patientId),
                    transportationAssistance: selectedValue,
                  },
                  FIELD_VALIDATION_KEYS.TRANSPORTATION_BOOL,
                );
              }}
            />
          </SectionField>
          {this.renderTransportationFields()}
        </div>
      </Fragment>
    );
  }

  public renderDrinkerFields(): JSX.Element {
    const { haSocialAssessment, alcoholFrequencyRefData } = this.props;

    if (haSocialAssessment.alcoholBool) {
      return (
        <SectionField title={SA_FIELDS.ALCOHOL_FREQUENCY.TITLE} htmlFor={SA_FIELDS.ALCOHOL_FREQUENCY.NAME}>
          <DropDownField
            inputName={SA_FIELDS.ALCOHOL_FREQUENCY.KEY}
            placeholder={'Please select'}
            options={alcoholFrequencyRefData}
            defaultValue={haSocialAssessment.alcoholFrequency}
            controlled={true}
            onChange={(e): void => {
              this.autoSaveAndValidate(
                {
                  id: parseInt(this.props.patientId),
                  alcoholFrequency: e.target.value,
                },
                FIELD_VALIDATION_KEYS.ALCOHOL_FREQUENCY,
              );
            }}
          />
        </SectionField>
      );
    } else {
      return <Fragment></Fragment>;
    }
  }

  public renderSmokingFields(validationObject: any): JSX.Element {
    const { haSocialAssessment, smokingFrequencyRefData, isPso } = this.props;
    const sortedRefData = [...smokingFrequencyRefData].sort((a, b) =>
      a.name.localeCompare(b.name, undefined, { numeric: true, sensitivity: 'base' }),
    );

    const restrictErrorMessage = (validationObject: any): string[] | undefined => {
      const validationObjectCopy = validationObject;

      if (!isPso) {
        const elementIndex = validationObject?.indexOf('Please enter valid years for your smoking history.');
        if (elementIndex >= 0) {
          validationObjectCopy.splice(elementIndex, 1);
        }
      }

      const validationObjectCopyLength = validationObjectCopy?.length;
      if (validationObjectCopyLength) return validationObjectCopy;
      return undefined;
    };

    if (haSocialAssessment.smokingBool) {
      return (
        <Fragment>
          <HelperText helperText={SA_FIELDS.SMOKING_HISTORY.MORE_INFO} idPrefix="ha-history">
            <SectionField title={SA_FIELDS.SMOKING_HISTORY.TITLE} htmlFor={SA_FIELDS.SMOKING_HISTORY.KEY}>
              <FreeTextField
                inputType="number"
                inputProps={{ min: 0 }}
                inputName={SA_FIELDS.SMOKING_HISTORY.KEY}
                maxLength={2}
                defaultValue={haSocialAssessment.smokingHistory}
                sideLabel={'year(s)'}
                onBlur={(e): void => {
                  this.autoSaveAndValidate(
                    {
                      id: parseInt(this.props.patientId),
                      smokingHistory: e.target.value,
                    },
                    FIELD_VALIDATION_KEYS.SMOKING_HISTORY,
                  );
                }}
                errors={restrictErrorMessage(validationObject?.smokingHistory)}
              />
            </SectionField>
          </HelperText>
          <SectionField title={SA_FIELDS.SMOKING_FREQUENCY.TITLE} htmlFor={SA_FIELDS.SMOKING_FREQUENCY.NAME}>
            <DropDownField
              inputName={SA_FIELDS.SMOKING_FREQUENCY.KEY}
              placeholder={'Please select'}
              options={sortedRefData}
              defaultValue={haSocialAssessment.smokingFrequency}
              controlled={true}
              onChange={(e): void => {
                this.autoSaveAndValidate(
                  {
                    id: parseInt(this.props.patientId),
                    smokingFrequency: e.target.value,
                  },
                  FIELD_VALIDATION_KEYS.SMOKING_FREQUENCY,
                );
              }}
            />
          </SectionField>
          <HelperText helperText={SA_FIELDS.SMOKING_STOP.MORE_INFO} idPrefix="ha-date-stop">
            <SectionField title={SA_FIELDS.SMOKING_STOP.TITLE} htmlFor={SA_FIELDS.SMOKING_STOP.KEY}>
              <div className="stop-fields">
                <DropDownROMonth
                  id="smoking-month"
                  value={
                    parseInt(haSocialAssessment.smokingStopRawMonth) > 0
                      ? Months[parseInt(haSocialAssessment.smokingStopRawMonth) - 1]
                      : ''
                  }
                  onChange={(val): void => {
                    const resultVal = Months.indexOf(val) >= 0 ? Months.indexOf(val) + 1 : '';
                    this.autoSaveAndValidate({
                      id: parseInt(this.props.patientId),
                      smokingStopRawMonth: resultVal.toString(),
                    });
                  }}
                  selectedYear={parseInt(haSocialAssessment.smokingStopRawYear)}
                  useLastOption
                />
                <DropDownROYear
                  id="smoking-year"
                  value={haSocialAssessment.smokingStopRawYear}
                  onChange={(val): void => {
                    this.autoSaveAndValidate({
                      id: parseInt(this.props.patientId),
                      smokingStopRawYear: val,
                    });
                  }}
                />
              </div>
            </SectionField>
          </HelperText>
        </Fragment>
      );
    } else {
      return <Fragment></Fragment>;
    }
  }

  public renderExerciseFields(): JSX.Element {
    const { haSocialAssessment, exerciseFrequencyRefData } = this.props;

    if (haSocialAssessment.exerciseBool) {
      return (
        <SectionField title={SA_FIELDS.EXERCISE_FREQUENCY.TITLE} htmlFor={SA_FIELDS.EXERCISE_FREQUENCY.NAME}>
          <DropDownField
            inputName={SA_FIELDS.EXERCISE_FREQUENCY.KEY}
            placeholder={'Please select'}
            options={exerciseFrequencyRefData}
            defaultValue={haSocialAssessment.exerciseFrequency}
            controlled={true}
            onChange={(e): void => {
              this.autoSaveAndValidate(
                {
                  id: parseInt(this.props.patientId),
                  exerciseFrequency: e.target.value,
                },
                FIELD_VALIDATION_KEYS.EXERCISE_FREQUENCY,
              );
            }}
          />
        </SectionField>
      );
    } else {
      return <Fragment></Fragment>;
    }
  }

  public renderAccomodationFields(): JSX.Element {
    const { haSocialAssessment } = this.props;

    if (haSocialAssessment.accommodationAssistance) {
      return (
        <SectionField title={SA_FIELDS.ACCOMMODATION_REASON.TITLE} htmlFor={SA_FIELDS.ACCOMMODATION_REASON.NAME}>
          <FreeTextField
            inputName={SA_FIELDS.ACCOMMODATION_REASON.KEY}
            defaultValue={haSocialAssessment.accommodationAssistanceReason}
            placeholder=""
            onBlur={(e): void => {
              this.autoSaveAndValidate(
                {
                  id: parseInt(this.props.patientId),
                  accommodationAssistanceReason: e.target.value,
                },
                FIELD_VALIDATION_KEYS.ACCOMMODATION_REASON,
              );
            }}
          />
        </SectionField>
      );
    } else {
      return <Fragment></Fragment>;
    }
  }

  public renderTransportationFields(): JSX.Element {
    const { haSocialAssessment } = this.props;

    if (haSocialAssessment.transportationAssistance) {
      return (
        <SectionField title={SA_FIELDS.TRANSPORTATION_REASON.TITLE} htmlFor={SA_FIELDS.TRANSPORTATION_REASON.NAME}>
          <FreeTextField
            inputName={SA_FIELDS.TRANSPORTATION_REASON.KEY}
            defaultValue={haSocialAssessment.transportationAssistanceReason}
            placeholder=""
            onBlur={(e): void => {
              this.autoSaveAndValidate(
                {
                  id: parseInt(this.props.patientId),
                  transportationAssistanceReason: e.target.value,
                },
                FIELD_VALIDATION_KEYS.TRANSPORTATION_REASON,
              );
            }}
          />
        </SectionField>
      );
    } else {
      return <Fragment></Fragment>;
    }
  }

  private validateObject = (haSocialAssessment: HASocialAssessmentItem): any => {
    const validationRules = fetchRules(ValidationKeys.SocialAssessment, this.state.viewedFields);

    const options = { fullMessages: false };
    const modifiedHA = { ...haSocialAssessment };
    modifiedHA.smokingStopDate = `${appendZeroInFront(modifiedHA.smokingStopRawMonth)}${modifiedHA.smokingStopRawYear}`;

    return validate(modifiedHA, validationRules, options);
  };

  private autoSaveAndValidate = async (updateObject: object, validationKey?: string): Promise<void> => {
    const { autosave } = this.props;
    //Save the data
    autosave(updateObject).then(() => {
      //Get viewed fields
      const viewedFields = [...this.state.viewedFields];

      //Add updated field
      validationKey && viewedFields.push(validationKey);

      //Update with new validation set
      this.setState({ viewedFields: new Set(viewedFields) });
    });
  };
}

export default HASocialAssessment;
