import React, { useContext } from 'react';
import { useFormikContext, Field, getIn } from 'formik';

import { Region } from 'shared-components/enums';
import { ROToggleButtons } from 'shared-components/components/FormFields';

import { IntakePageProps, FormikValues } from '../Interface';
import {
  VALUE_REQUIRED,
  POSITIVE_NEGATIVE_UNKNOWN_OPTIONS,
  YES_NO_UNKNOWN_OPTIONS,
  YES_NO_NOTAPPLICABLE_OPTIONS,
  GRADE_OPTIONS,
} from '../helpers';
import { isFieldLowCertainty, LOW_CERTAINTY, ClinexTooltip } from '../../Clinex/helpers';
import { ClinexContext } from '../../Clinex/context';
import ClinexFieldWrapper from '../../Clinex/ClinexFieldWrapper';
const REACT_APP_REGION = import.meta.env.REACT_APP_REGION;

const region = REACT_APP_REGION;

export const BreastIntakeV1 = ({ updateIntakeHandler }: IntakePageProps): JSX.Element => {
  const { values, touched } = useFormikContext<FormikValues>();
  const clinexContexts = useContext(ClinexContext);
  const { clinexData } = clinexContexts;

  if (values.intake?.breast) {
    const { grade, er, pgr, her2, mastectomy, reconstruction, excerciseReferral } = values.intake.breast;
    return (
      <>
        <ClinexFieldWrapper field="grade">
          <ClinexTooltip clinexData={clinexData} clinexField={'grade'} formValues={values.intake.breast}>
            <Field
              name="grade"
              component={ROToggleButtons}
              id="grade"
              fieldlabel="Grade"
              options={GRADE_OPTIONS}
              value={grade}
              handleChange={(value: string): void => {
                updateIntakeHandler({ grade: value });
              }}
              error={getIn(touched, 'grade') && !grade}
              required
              helperText={!grade ? VALUE_REQUIRED : LOW_CERTAINTY}
              warning={isFieldLowCertainty(clinexData, 'grade', values)}
            />
          </ClinexTooltip>
        </ClinexFieldWrapper>
        <ClinexFieldWrapper field="er">
          <ClinexTooltip clinexData={clinexData} clinexField={'er'} formValues={values.intake.breast}>
            <Field
              name="er"
              component={ROToggleButtons}
              id="er"
              fieldlabel="ER"
              options={POSITIVE_NEGATIVE_UNKNOWN_OPTIONS}
              value={er}
              handleChange={(value: string): void => {
                updateIntakeHandler({ er: value });
              }}
              error={getIn(touched, 'er') && !er}
              required
              helperText={!er ? VALUE_REQUIRED : LOW_CERTAINTY}
              warning={isFieldLowCertainty(clinexData, 'er', values)}
            />
          </ClinexTooltip>
        </ClinexFieldWrapper>
        <ClinexFieldWrapper field="pgr">
          <ClinexTooltip clinexData={clinexData} clinexField={'pgr'} formValues={values.intake.breast}>
            <Field
              name="pgr"
              component={ROToggleButtons}
              id="pgr"
              fieldlabel="PgR"
              options={POSITIVE_NEGATIVE_UNKNOWN_OPTIONS}
              value={pgr}
              handleChange={(value: string): void => {
                updateIntakeHandler({ pgr: value });
              }}
              error={getIn(touched, 'pgr') && !pgr}
              required
              helperText={!pgr ? VALUE_REQUIRED : LOW_CERTAINTY}
              warning={isFieldLowCertainty(clinexData, 'pgr', values)}
            />
          </ClinexTooltip>
        </ClinexFieldWrapper>
        <ClinexFieldWrapper field="her2">
          <ClinexTooltip clinexData={clinexData} clinexField={'her2'} formValues={values.intake.breast}>
            <Field
              name="her2"
              component={ROToggleButtons}
              id="her2"
              fieldlabel="Her2"
              options={POSITIVE_NEGATIVE_UNKNOWN_OPTIONS}
              value={her2}
              handleChange={(value: string): void => {
                updateIntakeHandler({ her2: value });
              }}
              error={getIn(touched, 'her2') && !her2}
              required
              helperText={!her2 ? VALUE_REQUIRED : LOW_CERTAINTY}
              warning={isFieldLowCertainty(clinexData, 'her2', values)}
            />
          </ClinexTooltip>
        </ClinexFieldWrapper>
        <ClinexFieldWrapper field="mastectomy">
          <ClinexTooltip clinexData={clinexData} clinexField={'mastectomy'} formValues={values.intake.breast}>
            <Field
              name="mastectomy"
              component={ROToggleButtons}
              id="mastectomy"
              fieldlabel="Mastectomy"
              options={YES_NO_UNKNOWN_OPTIONS}
              value={mastectomy}
              error={getIn(touched, 'mastectomy') && !mastectomy}
              handleChange={(value: string): void => {
                updateIntakeHandler({ mastectomy: value });
              }}
              message={LOW_CERTAINTY}
              warning={isFieldLowCertainty(clinexData, 'mastectomy', values)}
            />
          </ClinexTooltip>
        </ClinexFieldWrapper>
        <ClinexFieldWrapper field="reconstruction">
          <ClinexTooltip clinexData={clinexData} clinexField={'reconstruction'} formValues={values.intake.breast}>
            <Field
              name="reconstruction"
              component={ROToggleButtons}
              id="reconstruction"
              fieldlabel="Reconstruction"
              options={YES_NO_UNKNOWN_OPTIONS}
              value={reconstruction}
              error={getIn(touched, 'reconstruction') && !reconstruction}
              handleChange={(value: string): void => {
                updateIntakeHandler({ reconstruction: value });
              }}
              message={LOW_CERTAINTY}
              warning={isFieldLowCertainty(clinexData, 'reconstruction', values)}
            />
          </ClinexTooltip>
        </ClinexFieldWrapper>
        {region !== Region.UK && (
          <Field
            name="exerciseReferral"
            component={ROToggleButtons}
            id="excercise"
            fieldlabel={'Referred for Exercise Clinic'}
            options={YES_NO_NOTAPPLICABLE_OPTIONS}
            value={excerciseReferral}
            error={getIn(touched, 'excerciseReferral') && !excerciseReferral}
            handleChange={(value: string): void => {
              updateIntakeHandler({ excerciseReferral: value });
            }}
          />
        )}
      </>
    );
  }
  return <> </>;
};
