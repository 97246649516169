import { MutableRefObject, RefObject, useEffect, useState, useRef, useMemo, useCallback } from 'react';

export function useOnClickOutside(
  ref: MutableRefObject<HTMLElement | undefined> | RefObject<HTMLElement | undefined>,
  handler: CallableFunction,
): any {
  useEffect(() => {
    const listener = (event: Event) => {
      // Do nothing if clicking ref's element or descendent elements
      if (!ref.current || ref.current.contains(event.target as Node)) {
        return;
      }

      handler(event);
    };

    document.addEventListener('mousedown', listener);
    document.addEventListener('touchstart', listener);

    return () => {
      document.removeEventListener('mousedown', listener);
      document.removeEventListener('touchstart', listener);
    };
  }, [ref, handler]);
}

interface Size {
  width: number;
  height: number;
}

export function useWindowSize(): Size {
  const [windowSize, setWindowSize] = useState<Size>({
    width: window.innerWidth,
    height: window.innerHeight,
  });

  useEffect(() => {
    function handleResize() {
      setWindowSize({
        width: window.innerWidth,
        height: window.innerHeight,
      });
    }

    window.addEventListener('resize', handleResize);
    handleResize();

    return () => window.removeEventListener('resize', handleResize);
  }, []);

  return windowSize;
}

const defaultMutationCallback = (mutationList: MutationRecord[]) => mutationList;

export function useMutationObserver(
  ref: RefObject<HTMLElement>,
  config: MutationObserverInit,
  callback: (mutationList: MutationRecord[], observer: MutationObserver) => any = defaultMutationCallback,
): MutationRecord | undefined {
  const [value, setValue] = useState<MutationRecord | undefined>();
  const observer = useMemo(
    () =>
      new MutationObserver((mutationList, observer) => {
        const result = callback(mutationList, observer);
        setValue(result);
      }),
    [callback],
  );

  useEffect(() => {
    if (ref?.current) {
      observer.observe(ref?.current, config);
      return () => observer.disconnect();
    }
  }, [ref, config]);

  return value;
}

export function useOverflow(ref: RefObject<HTMLElement>): {
  refXOverflowing: boolean;
  refYOverflowing: boolean;
  refXScrollBegin: boolean;
  refXScrollEnd: boolean;
  refYScrollBegin: boolean;
  refYScrollEnd: boolean;
} {
  const [refXOverflowing, setRefXOverflowing] = useState(false);
  const [refYOverflowing, setRefYOverflowing] = useState(false);

  const [refXScrollBegin, setRefXScrollBegin] = useState(true);
  const [refXScrollEnd, setRefXScrollEnd] = useState(false);

  const [refYScrollBegin, setRefYScrollBegin] = useState(true);
  const [refYScrollEnd, setRefYScrollEnd] = useState(false);

  const [mutation, setMutation] = useState<MutationRecord>();

  const size = useWindowSize();

  const handleScroll = (): void => {
    if (ref && ref.current) {
      // Handle X Overflow
      const offsetRight = ref!.current!.scrollWidth! - ref!.current!.clientWidth!;
      if (ref!.current!.scrollLeft! >= offsetRight && refXScrollEnd === false) {
        setRefXScrollEnd(true);
      } else {
        setRefXScrollEnd(false);
      }

      if (ref?.current?.scrollLeft === 0) {
        setRefXScrollBegin(true);
      } else {
        setRefXScrollBegin(false);
      }

      // Handle Y Overflow
      const offsetBottom = ref!.current!.scrollHeight! - ref!.current!.clientHeight!;
      if (ref!.current!.scrollTop! >= offsetBottom && refYScrollEnd === false) {
        setRefYScrollEnd(true);
      } else {
        setRefYScrollEnd(false);
      }

      if (ref?.current?.scrollTop === 0) {
        setRefYScrollBegin(true);
      } else {
        setRefYScrollBegin(false);
      }
    }
  };

  const observerConfig = { childList: true, subtree: true };
  const observerCallback = (mutationsList: MutationRecord[]) => {
    for (const mutation of mutationsList) {
      if (mutation.type === 'childList') setMutation(mutation);
    }
  };

  useMutationObserver(ref, observerConfig, observerCallback);

  useEffect((): any => {
    if (!ref?.current) {
      return;
    }
    const isXOverflowing = ref.current.scrollWidth > ref.current.clientWidth;
    const isYOverflowing = ref.current.scrollHeight > ref.current.clientHeight;

    if (refXOverflowing !== isXOverflowing) {
      setRefXOverflowing(isXOverflowing);
    }

    if (refYOverflowing !== isYOverflowing) {
      setRefYOverflowing(isYOverflowing);
    }

    ref.current.addEventListener('scroll', handleScroll);

    return (): void => {
      ref.current?.removeEventListener('scroll', handleScroll);
    };
  }, [ref, size.width, mutation]); // Empty array ensures that effect is only run on mount and unmount

  return {
    refXOverflowing,
    refYOverflowing,
    refXScrollBegin,
    refXScrollEnd,
    refYScrollBegin,
    refYScrollEnd,
  };
}

export function usePrevious(value: any): any {
  // The ref object is a generic container whose current property is mutable ...
  // ... and can hold any value, similar to an instance property on a class
  const ref = useRef();
  // Store current value in ref
  useEffect(() => {
    ref.current = value;
  }, [value]); // Only re-run if value changes
  // Return previous value (happens before update in useEffect above)
  return ref.current;
}

export const usePreventUnload = (isPrevented: boolean): any => {
  const preventUnload = useCallback((event: BeforeUnloadEvent) => {
    event.preventDefault();
    event.returnValue = '';
  }, []);

  useEffect(() => {
    if (isPrevented) {
      window.addEventListener('beforeunload', preventUnload);
    }
    return () => {
      if (isPrevented) {
        window.removeEventListener('beforeunload', preventUnload);
      }
    };
  }, [isPrevented, preventUnload]);
};

export const deepCopyFunction = (inObject: any): any => {
  let value, key;

  if (typeof inObject !== 'object' || inObject === null) {
    return inObject; // Return the value if inObject is not an object
  }
  if (inObject instanceof Date) {
    return new Date(inObject);
  }

  if (inObject instanceof Date) {
    return new Date(inObject);
  }

  // Create an array or object to hold the values
  const outObject = Array.isArray(inObject) ? [] : {};

  for (key in inObject) {
    value = inObject[key];

    // Recursively (deep) copy for nested objects, including arrays
    // @ts-ignore
    outObject[key] = deepCopyFunction(value);
  }

  return outObject;
};
