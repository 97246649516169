import React, { useContext } from 'react';
import { styled } from '@mui/system';
import { useFormikContext, Field } from 'formik';
import _ from 'lodash';

import { FormRow } from 'shared-components/components';
import { SelectFieldBase, SelectFieldGroup, TextAreaField } from 'shared-components/components/FormikComponents';

import { sharedContent } from '../../../shared/constants';
import { preCtChartCheckContent, displayTextFieldOptionsForAllergy } from '../../constants';
import FormContext from '../../contexts';
import { getRecentlyCreated } from '../../../helpers';

interface Props {
  index: number;
}

const ReactionExperienced = styled('div')`
  margin-top: 24px;
`;

const StyledField = styled(Field)`
  max-width: 40px;
`;

const AllergyCard = ({ index }: Props): JSX.Element => {
  const { values, setFieldValue }: { values: any; setFieldValue: any } = useFormikContext();
  const formContext = useContext(FormContext);
  const { updateAllergy, createReaction, deleteReaction, updateReaction } = formContext.mutations;

  const {
    id: allergyId,
    allergyType: allergyValues,
    pcccReaction: reactionValues,
    lastUpdateUser,
  } = values.pcccAllergy[index];

  const { allergies } = preCtChartCheckContent;
  const { fields, options, actionLabels } = allergies;
  const { pcccAllergy, pcccReaction } = fields;

  const updateAllergyMutation = async (fieldName: string, value: string) => {
    const { data } = await updateAllergy({
      variables: { id: allergyId, [fieldName]: value },
    });
    const returnedAllergies = data.updatePcccAllergy.preCtChartCheck.pcccAllergy;
    const updatedAllergies = returnedAllergies.reduce((formikAllergies: any, allergy: any) => {
      formikAllergies.map((formikAllergy: any, index: number) => {
        if (formikAllergy.id === allergy.id) {
          formikAllergies[index] = allergy;
        }
      });
      return formikAllergies;
    }, _.cloneDeep(values.pcccAllergy));
    return updatedAllergies;
  };

  const createReactionMutation = async () => {
    const { data } = await createReaction({
      variables: { allergyId, baseValue: '', otherValue: '' },
    });
    const updatedReactions = data.createPcccReaction.preCtChartCheck.pcccAllergy[index].pcccReaction;
    return getRecentlyCreated(updatedReactions);
  };

  const deleteReactionMutation = (id: string) => {
    deleteReaction({ variables: { id } });
  };

  const conditionalHandleChange = (value: string) => {
    setFieldValue(`pcccAllergy[${index}].lastUpdateUser`, 'nurse');

    if (value !== 'Food' && (!reactionValues || reactionValues.length === 0)) {
      createReactionMutation().then((data) => {
        setFieldValue(`pcccAllergy[${index}].pcccReaction[0]`, data);
      });
    }
    if (value === 'Food' && reactionValues && reactionValues.length > 0) {
      updateAllergy({
        variables: { id: allergyId, additionalDetails: '' },
      });
      reactionValues.forEach((item: any) => {
        deleteReactionMutation(item.id);
      });
      setFieldValue(`pcccAllergy[${index}].pcccReaction`, []);
    }
  };

  return (
    <>
      <FormRow fieldLabel={pcccAllergy} fieldName={`pcccAllergy[${index}].allergyType`}>
        <StyledField
          component={SelectFieldBase}
          width={'500px'}
          name={`pcccAllergy[${index}].allergyType`}
          options={options.allergy}
          placeholder={sharedContent.generic.label.selectValue}
          updateMutation={(value: string) => updateAllergyMutation('allergyType', value)}
          conditionalHandleChange={conditionalHandleChange}
          lastUpdateUser={lastUpdateUser}
        />
      </FormRow>
      {displayTextFieldOptionsForAllergy.includes(allergyValues) && (
        <>
          <FormRow fieldName={`pcccAllergy[${index}].additionalDetails`}>
            <Field
              component={TextAreaField}
              width={'500px'}
              name={`pcccAllergy[${index}].additionalDetails`}
              placeholder={sharedContent.generic.label.specifyValue}
              updateMutation={(value: string) => updateAllergyMutation('additionalDetails', value)}
            />
          </FormRow>
          {reactionValues && (
            <ReactionExperienced>
              <SelectFieldGroup
                name={`pcccAllergy[${index}].pcccReaction`}
                addButtonText={actionLabels.addReaction}
                createMutation={createReactionMutation}
                deleteMutation={deleteReactionMutation}
                updateMutation={updateReaction}
                label={pcccReaction}
                options={options.reaction}
                selectPlaceholder={sharedContent.generic.label.selectValue}
                selectFieldName="baseValue"
                textFieldName="otherValue"
                textFieldOptionsForOther={displayTextFieldOptionsForAllergy}
                values={reactionValues}
                userIconFieldName="lastUpdateUser"
                userTypeValue="nurse"
                width={'500px'}
              />
            </ReactionExperienced>
          )}
        </>
      )}
    </>
  );
};

export default AllergyCard;
