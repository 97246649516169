// eslint-disable-next-line no-use-before-define
import React, { Component } from 'react';

import { PatientHomeNavigation } from 'op-interfaces';
import moment from 'moment-timezone';

import './HeroImage.scss';
import { calculateMomentAge } from 'shared-components/utils';
import { isUs } from 'op-utils';

interface Props {
  patient: PatientHomeNavigation;
}
class HeroImage extends Component<Props> {
  public render(): JSX.Element {
    const { patient } = this.props;
    return (
      <div id="hero-image">
        <div className="image">
          <div className="patient-identifiers">
            <div className="title">{`Welcome ${patient.resolvedNamePrefix} ${patient.firstName} ${patient.lastName}`}</div>
            <div className="content-container">
              <div data-test-id="dob-subtitle" className="sub-title">
                Date of birth
              </div>
              <div data-test-id="dob-content" className="sub-content">
                {this.getDob()}
              </div>
            </div>
            <div className="content-container">
              <div data-test-id="address-subtitle" className="sub-title">
                Address
              </div>
              <div data-test-id="address-content" className="sub-content">
                {this.getAddress()}
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }

  private getAddress = (): string => {
    const {
      patient: { address },
    } = this.props;
    if (!address) return '';

    const { formattedAddress } = address;

    return formattedAddress;
  };

  private getDob = (): string => {
    const { patient } = this.props;
    const date = moment(patient.dob, 'YYYY-MM-DD');
    return `${date.format(isUs() ? 'MMMM DD, YYYY' : 'DD MMM YYYY')} (age ${calculateMomentAge(date)})`;
  };
}

export default HeroImage;
