export const REGISTRATION_CONFLICTS_CONTENT = {
  HEADER: 'Resolve data conflicts',
  SUBHEADER:
    'An update made in Mosaiq has resulted in the following data conflicts. Please confirm which information is correct before submitting this registration form.',
  NO_DATA_CONFLICTS_MESSAGE:
    'There are currently no data conflicts to resolve, please continue reviewing the Registration form for submission.',
  RADIO: {
    LABEL: {
      NOT_PROVIDED: 'Not Provided',
    },
    VALUE: {
      HZ: 'HZ',
      MQ: 'MQ',
    },
  },
  BUTTON: {
    RESOLVE_CONFLICTS: 'Resolve conflicts',
  },
};
