// eslint-disable-next-line no-use-before-define
import React, { useState } from 'react';
import { useQuery } from '@apollo/client';
import { useRouteMatch } from 'react-router';
import AddNotes from '../Notes/AddNotes';
import OuterContainer from '../PatientSummary/OuterContainer';
import DocumentsList from './DocumentsList';
import DocumentView from '../PatientSummary/Docs';
import { ROPatientContextProvider } from '../PatientSummary/context';
import { GET_PATIENT_DOCUMENTS } from '../PatientSummary/PatientProfileQueries';
import { useWindowSize } from 'shared-components/utils/CustomHooks';
import { LoadingSpinner } from 'shared-components/components';
import useInterval from 'shared-components/utils/useInterval';
import { LARGE_DISPLAY_SIZE } from 'shared-components/enums';
import { Grid } from '@mui/material';

interface DocumentsPageRouteProps {
  id: string;
  page: string;
}

export const DocumentsPage = (): JSX.Element => {
  const match = useRouteMatch<DocumentsPageRouteProps>();
  const { id: patientId } = match.params;
  const [selectedDocument, setSelectedDocument] = useState(null);
  const windowSize = useWindowSize();
  const isSmallDevice = windowSize.width < LARGE_DISPLAY_SIZE;

  const { data, loading, error, refetch } = useQuery(GET_PATIENT_DOCUMENTS, {
    variables: { patientId: patientId },
    skip: !patientId,
    fetchPolicy: 'network-only',
  });
  const documents = data?.documents;

  /* Refresh documents list every 8 seconds */
  const refetchInterval = 8000;
  useInterval(() => {
    refetch();
  }, refetchInterval);

  if (error) return <div>Error</div>;

  return (
    <ROPatientContextProvider>
      <OuterContainer>
        <Grid container sx={{ padding: '0 16px' }}>
          <Grid
            xs={isSmallDevice ? 4 : 3}
            sx={{
              padding: '16px',
              borderRadius: '4px',
              boxShadow: '0px 2px 8px rgba(113, 110, 106, 0.4)',
              backgroundColor: 'white',
              height: '100%',
            }}>
            {loading ? (
              <LoadingSpinner loadingText={'Loading Documents'} relativeSpinner={true} />
            ) : (
              <DocumentsList
                documents={documents}
                selectedDocument={selectedDocument}
                setSelectedDocument={setSelectedDocument}
              />
            )}
          </Grid>
          <Grid xs={isSmallDevice ? 7 : 6} sx={{ paddingLeft: '16px' }}>
            <DocumentView document={selectedDocument} />
          </Grid>
          <Grid xs={isSmallDevice ? 1 : 3}>
            <AddNotes />
          </Grid>
        </Grid>
      </OuterContainer>
    </ROPatientContextProvider>
  );
};
