// eslint-disable-next-line no-use-before-define
import { NetworkStatus, gql } from '@apollo/client';
import { HAMedicalHistoryIllnessItem } from 'op-classes';
import { useErrorModalContext } from 'op-contexts';
import * as fragments from 'op-graphql/fragments';
import { ApolloSaveStatus } from 'op-types';
import React, { Component, useEffect } from 'react';
import { Query } from '@apollo/client/react/components';
import { RouteComponentProps, withRouter } from 'react-router-dom';
import { LoadingSpinner } from 'shared-components/components';
import { ApolloNetworkStatus } from 'shared-components/enums';
import { Dictionary, ListData } from 'shared-components/interfaces';
import { Logger } from 'shared-components/utils';
import withHealthAssessment, { WithHealthAssessment } from '../../HealthAssessment';
import HAMedicalHistoryIllness from './HAMedicalHistoryIllness';

const logger = new Logger('HAMedicalHistoryIllnessApollo');

const HA_MEDICAL_ILLNESS = gql`
  query HealthAssessment($id: ID!) {
    healthAssessment(id: $id) {
      id
      internalDevice
      internalDevices {
        id
        ...HealthAssessmentInternalDevice
      }
      bloodClot
      bloodClotAnswer
      heartOrLungProblem
      heartOrLungAnswer
      highOrLowBloodPressure
      bloodPressureAnswer
      diabetes
      diabetesResponses {
        id
        ...HealthAssessmentDiabetesResponse
      }
      intractableInfectionOrIllness
      intractableInfectionOrIllnesses {
        id
        ...HealthAssessmentInfection
      }
      immunosuppressiveCondition
      immunosuppressiveConditions {
        id
        ...HealthAssessmentImmunosuppressiveCondition
      }
    }
    diabetesRefData: listData(category: "diabetes") {
      id
      name
    }
    immunosuppresiveConditionsRefData: listData(category: "immunosuppresiveConditions") {
      id
      name
    }
    internalDevicesRefData: listData(category: "internalDevices") {
      id
      name
    }
    intractableInfectionOrIllnessesRefData: listData(category: "intractableInfectionOrIllnesses") {
      id
      name
    }
  }

  ${fragments.HEALTH_ASSESSMENT_DIABETES_RESPONSE_FRAGMENT}
  ${fragments.HEALTH_ASSESSMENT_IMMUNOSUPPRESSIVE_CONDITION_FRAGMENT}
  ${fragments.HEALTH_ASSESSMENT_INFECTION_FRAGMENT}
  ${fragments.HEALTH_ASSESSMENT_INTERNAL_DEVICE_FRAGMENT}
`;

interface MedicalHistoryIllnessQueryData {
  healthAssessment: HAMedicalHistoryIllnessItem;
  diabetesRefData: ListData[];
  immunosuppresiveConditionsRefData: ListData[];
  internalDevicesRefData: ListData[];
  intractableInfectionOrIllnessesRefData: ListData[];
}

interface Props extends WithHealthAssessment, RouteComponentProps<{ patientId: string; haId: string }> {
  client: any;
  updateSaveStatus: ApolloSaveStatus;
  validateOnLoad: boolean;
}

class HAMedicalHistoryIllnessApollo extends Component<Props> {
  public render(): JSX.Element {
    const {
      match: {
        params: { patientId },
      },
      validateOnLoad,
    } = this.props;

    return (
      <Query<MedicalHistoryIllnessQueryData> query={HA_MEDICAL_ILLNESS} variables={{ id: patientId }}>
        {({ error, loading, data, refetch, networkStatus }): JSX.Element => {
          const { setError } = useErrorModalContext();
          useEffect(() => {
            if (error) return setError();
          }, [error]);

          if (loading && networkStatus !== NetworkStatus.refetch) return <LoadingSpinner relativeSpinner={true} />;

          if (data && data.healthAssessment) {
            const haMedicalIllness = new HAMedicalHistoryIllnessItem().loadData(data.healthAssessment);
            return (
              <HAMedicalHistoryIllness
                medicalHistoryIllness={haMedicalIllness}
                deviceRefData={data.internalDevicesRefData}
                resistantRefData={data.intractableInfectionOrIllnessesRefData}
                suppressiveRefData={data.immunosuppresiveConditionsRefData}
                diabetesRefData={data.diabetesRefData}
                autosave={this.autosave}
                performCRUD={this.performOperation}
                refetch={refetch}
                validateOnLoad={validateOnLoad}
                patientId={patientId}
              />
            );
          }

          return <div></div>;
        }}
      </Query>
    );
  }
  private autosave = (updateObject: object, replaceId?: string, refetch?: any): Promise<void> => {
    const { mutateGraph, client, getSaveStatus, updateSaveStatus } = this.props;

    // Mutate the graph
    return mutateGraph(
      client,
      updateObject,
      () => {
        getSaveStatus(client).then((savingStatus) => {
          updateSaveStatus(savingStatus);
        });
      },
      () => {},
      () => {},
      async () => {
        const saveStatus = await getSaveStatus(client);
        updateSaveStatus(saveStatus);

        if (refetch !== undefined) {
          try {
            refetch();
          } catch (error) {
            logger.debug('Refetch failed', error);
          }
        }
      },
      replaceId,
    );
  };

  private performOperation = (
    haId: string,
    operation: string,
    objectType: string,
    itemId?: string,
    refetch?: any,
  ): void => {
    const { mutateCRUDGraph, client, getSaveStatus, updateSaveStatus } = this.props;

    // Build the update object
    const updateObject: Dictionary = {
      haId,
      objectType,
      operation,
    };

    if (itemId !== undefined && itemId !== '') {
      updateObject.id = itemId;
    }

    // Call the mutation to update the CRUD
    mutateCRUDGraph(
      client,
      updateObject,
      (): void => {
        getSaveStatus(client).then((savingStatus): void => {
          updateSaveStatus(savingStatus);
        });
      },
      (): void => {},
      (): void => {},
      async (): Promise<void> => {
        const saveStatus = await getSaveStatus(client);
        updateSaveStatus(saveStatus);
        if (refetch !== undefined) {
          try {
            refetch();
          } catch (error) {
            logger.debug('Refetch failed', error);
          }
        }
      },
    );
  };
}

const routedComponent = withRouter(HAMedicalHistoryIllnessApollo);
const haComponent = withHealthAssessment(routedComponent);
export default haComponent;
