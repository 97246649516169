import React from 'react';

export interface ChildField {
  key: string;
  label: string;
  parentKey: string;
  inputType?: string;
}

export interface Section {
  key: string;
  title: string;
  subtitle: string | JSX.Element;
  childFields?: ChildField[];
}

export const PAST_MEDICAL_HISTORY = {
  key: 'pastMedicalHistory',
  title: 'Past Medical History',
  subtitle: 'Have you been diagnosed with any of the following conditions?',
  childFields: [
    {
      parentKey: 'other',
      key: 'otherText',
      label: 'What other?',
    },
  ],
};
export const CARDIAC = {
  key: 'cardiac',
  title: 'Cardiac',
  subtitle: (
    <>
      Symptoms related to the heart such as <b>Chest pain, irregular heartbeat etc.</b>
    </>
  ),
};
export const CONSTITUTIONAL = {
  key: 'constitutional',
  title: 'Constitutional',
  subtitle: (
    <>
      Symptoms that may impact the whole body or the general well-being of an individual such as{' '}
      <b>fever/chills, fatigue etc.</b>
    </>
  ),
  childFields: [
    {
      parentKey: 'weightLoss',
      key: 'weightLossCount',
      label: 'How much (pounds)?',
      inputType: 'number',
    },
    {
      parentKey: 'weightGain',
      key: 'weightGainCount',
      label: 'How much (pounds)?',
      inputType: 'number',
    },
  ],
};

export const SCREENING = {
  key: 'screening',
  title: 'Screening',
  subtitle: <>Have you had any of the following screening examinations in the past?</>,
  childFields: [
    {
      parentKey: 'chestCtScan',
      key: 'chestCtScan',
      label: 'Date of last CT scan',
      inputType: 'date',
    },

    {
      parentKey: 'colonoscopy',
      key: 'colonoscopy',
      label: 'Date of last colonoscopy',
      inputType: 'date',
    },
  ],
};

export const HEMATOLOGIC = {
  key: 'hematologic',
  title: 'Hematologic',
  subtitle: (
    <>
      Symptoms related to blood such as <b>bleeding, paleness, enlarged lymph nodes etc</b>
    </>
  ),
};

export const SKIN = {
  key: 'skin',
  title: 'Skin',
  subtitle: (
    <>
      Symptoms related to skin such as <b>itching, nodule/tumor, rashes</b>
    </>
  ),
};

export const EYES_EARS_NOSE_THROAT = {
  key: 'eyesEarsNoseThroat',
  title: 'Eyes/Ears/Nose/Throat',
  subtitle: (
    <>
      Symptoms such as <b>Hearing, vision loss, hoarseness, sinus problems etc.</b>
    </>
  ),
};

export const GASTROINTESTINAL = {
  key: 'gastrointestinal',
  title: 'Gastrointestinal',
  subtitle: (
    <>
      Symptoms that may impact the stomach or bowel such as <b>reflux, diarrhea, vomitting etc.</b>
    </>
  ),
  childFields: [
    {
      parentKey: 'colonoscopySigmoidoscopy',
      key: 'colonoscopy',
      label: 'Date (if known)',
      inputType: 'date',
    },
  ],
};
export const GENITOURINARY = {
  key: 'genitourinary',
  title: 'Genitourinary',
  subtitle: (
    <>
      Symptoms related to the urinary tract or reproductive system such as{' '}
      <b>painful urination, erectile dysfunction etc.</b>
    </>
  ),
};
export const NEUROLOGICAL = {
  key: 'neurological',
  title: 'Neurological',
  subtitle: (
    <>
      Symptoms related to brain, spinal cord or nerves such as <b>dizziness, numbness, seizures etc.</b>
    </>
  ),
};
export const PSYCHIATRIC = {
  key: 'psychiatric',
  title: 'Psychiatric',
  subtitle: (
    <>
      Symptoms related to mental health such as <b>anxiety, depression etc.</b>
    </>
  ),
};
export const PULMONARY = {
  key: 'pulmonary',
  title: 'Pulmonary',
  subtitle: (
    <>
      Symptoms related to lungs such as <b>cough, shortness of breath etc.</b>
    </>
  ),
  childFields: [
    {
      parentKey: 'covidVaccine',
      key: 'covid19Vaccine',
      label: 'Date of vaccination (if known)',
      inputType: 'date',
    },
    {
      parentKey: 'influenzaVaccine',
      key: 'influenzaVaccine',
      label: 'Date of vaccination (if known)',
      inputType: 'date',
    },
    {
      parentKey: 'pneumoniaVaccine',
      key: 'pneumoniaVaccine',
      label: 'Date of vaccination (if known)',
      inputType: 'date',
    },
  ],
};
export const BONESJOINTS = {
  key: 'bonesJoints',
  title: 'Bones/joints',
  subtitle: (
    <>
      Symptoms related to joints and bones <b>bone pain, back pain, fractures etc.</b>
    </>
  ),
  childFields: [
    {
      parentKey: 'brokenBones',
      key: 'brokenBonesCount',
      label: 'How many broken bones?',
      inputType: 'number',
    },
  ],
};
