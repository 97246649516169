import { useMutation } from '@apollo/client';
import { CheckboxTile } from 'op-components';
import _ from 'lodash';
import React, { Fragment, useContext } from 'react';
import { useParams } from 'react-router-dom';
import { styled } from '@mui/system';

import { Success, WarningTriangle } from 'shared-components/images';

import { registrationAttachments } from 'op-utils';
import { ReferralType } from 'op-enums';
import { ATTACHMENT_ACKNOWLEDGED_MUTATION } from '../DocumentUploadPage/DocumentUploadQueries';
import { HeaderSubTitle } from '../Helper';
import { PatientReviewAndSubmit } from '../interfaces';
import {
  ADDRESS_FIELDS,
  BASIC_FIELDS,
  CONTACT_FIELDS,
  DEMOGRAPHICS_FIELDS,
  documentTypeMapping,
  DOCUMENT_UPLOAD_FIELDS,
  EMERGENCY_CONTACT_FIELDS,
  fieldsToExclude,
  INSURANCE_FIELDS,
  REFERRER_FIELDS,
} from './constants';
import { Field } from './interfaces';
import {
  Header,
  RowBlock,
  StyledCard,
  StyledConditionalSection,
  StyledDivider,
  StyledField,
  StyledInfoField,
  StyledRow,
  StyledSubHeading,
} from './ReviewAndSubmit';
import RegistrationContext, { RegistrationContextType } from 'op-contexts/RegistrationContext/RegistrationContext';
import { useTheme } from '@mui/material';

const BannerContainer = styled('div')`
  display: flex;
  flex-direction: row;
  align-items: center;
  margin-bottom: 12px;
`;

const BannerTextContainer = styled('div')`
  display: flex;
  flex-direction: column;
  padding-left: 8px;
`;

const StyledSuccess = styled(Success)`
  fill: ${(props) => props.theme.palette.primary.main};
`;

const renderAttentionBanner = (): JSX.Element => {
  return (
    <BannerContainer>
      <WarningTriangle title="Warning_Triangle.svg" />
      <BannerTextContainer>
        <p>= requires attention. Please complete the missing information (‘-‘) if applicable before submission. </p>
      </BannerTextContainer>
    </BannerContainer>
  );
};

const renderCompleteBanner = (): JSX.Element => {
  return (
    <BannerContainer>
      <StyledSuccess />
      <BannerTextContainer>
        <h1>Completed & ready for review</h1>
      </BannerTextContainer>
    </BannerContainer>
  );
};

export const reviewValidationHelpers = {
  getInvalidFields: (fields: Field[], patient: PatientReviewAndSubmit): Field[] => {
    // The constant pairedFields are key value pair for the fields which require any one of the two properties.
    // If one property is valid, it will make other optional.
    // For example if the patient has primary phone number , the secondary is optional.

    const pairedFields = {
      primaryPhone: 'secondaryPhone',
      secondaryPhone: 'primaryPhone',
      'emergencyContact.mobilePhoneNumber': 'emergencyContact.homePhoneNumber',
      'emergencyContact.homePhoneNumber': 'emergencyContact.mobilePhoneNumber',
      'nextOfKinContact.mobilePhoneNumber': 'nextOfKinContact.homePhoneNumber',
      'nextOfKinContact.homePhoneNumber': 'nextOfKinContact.mobilePhoneNumber',
    };

    const invalidFields = _.filter(fields, (field: Field) => {
      if (fieldsToExclude.includes(field?.key)) return false;
      const value = _.get(patient, field?.key);

      if (Object.keys(pairedFields).includes(field?.key)) {
        //@ts-ignore
        const pairedValue = _.get(patient, pairedFields[field?.key]);
        return ['', null, undefined].includes(value || pairedValue);
      }

      return ['', null, undefined].includes(value);
    }) as Field[];
    return invalidFields;
  },
};

export const documentUploadInvalid = (patient: PatientReviewAndSubmit): boolean => {
  const attachments = registrationAttachments(patient.attachments);

  if (!attachments?.length) return false;

  const hasEmptyDocumentType = attachments.some((attachment) => !attachment.documentType);

  return hasEmptyDocumentType || !patient.attachmentAcknowledged;
};

const resolveDocumentType = (documentType: string): string => {
  if (Object.keys(documentTypeMapping).includes(documentType))
    return documentTypeMapping[documentType as keyof typeof documentTypeMapping];
  return documentType;
};

const RenderInvalidSections = ({ patient }: { patient: PatientReviewAndSubmit }): JSX.Element => {
  const registrationContext = useContext<RegistrationContextType>(RegistrationContext);
  const theme = useTheme();
  const { patientId } = useParams<{ patientId: string }>();
  const [updateAttachmentAcknowledged] = useMutation(ATTACHMENT_ACKNOWLEDGED_MUTATION, {
    refetchQueries: ['ReviewAndSubmit'],
  });

  const getInvalidFields = reviewValidationHelpers.getInvalidFields;

  const basicFields = getInvalidFields(BASIC_FIELDS.fields, patient);

  const contactFields = getInvalidFields(CONTACT_FIELDS.fields, patient);

  const primaryAddressFields = getInvalidFields(ADDRESS_FIELDS.fields, patient);

  const postalAddressFields = patient.postalAddressSameAsResidential
    ? []
    : getInvalidFields(ADDRESS_FIELDS.postalFields, patient);
  const addressFields = primaryAddressFields.concat(postalAddressFields);

  const alternateContactFields = getInvalidFields(EMERGENCY_CONTACT_FIELDS.fields, patient);
  const nextOfKinFields = patient.altContactProvided
    ? getInvalidFields(EMERGENCY_CONTACT_FIELDS.nextOfKinFields, patient)
    : [];
  const emergencyContactFields = alternateContactFields.concat(nextOfKinFields);

  const referrerGpFields = getInvalidFields(REFERRER_FIELDS.gpFields, patient);
  const referrerSpecialistFields = getInvalidFields(REFERRER_FIELDS.fields, patient);
  const referrerFields = referrerGpFields.concat(referrerSpecialistFields);

  const referrerMatched = (referralType: ReferralType): boolean => {
    const {
      referringFirstName,
      referringLastName,
      referringPractice,
      referringIsInMosaiq,
      gpFirstName,
      gpLastName,
      gpPractice,
      gpIsInMosaiq,
    } = patient;

    if (referralType === ReferralType.GP) {
      return !!(gpFirstName && gpLastName && gpPractice && gpIsInMosaiq);
    } else if (referralType === ReferralType.SPECIALIST) {
      return !!(referringFirstName && referringLastName && referringPractice && referringIsInMosaiq);
    }

    return false;
  };

  const medicareOption = getInvalidFields(INSURANCE_FIELDS.fields, patient);
  const medicareFields = ['Medicare', 'Medicare and DVA'].includes(patient.healthMedicareDvaOption)
    ? getInvalidFields(INSURANCE_FIELDS.medicareFields, patient)
    : [];
  const dvaFields = ['Medicare and DVA'].includes(patient.healthMedicareDvaOption)
    ? getInvalidFields(INSURANCE_FIELDS.dvaFields, patient)
    : [];
  const privateConditional = getInvalidFields(INSURANCE_FIELDS.privateInsuranceConditional, patient);
  const privateFields = patient?.healthPrivateHealthInsurance
    ? getInvalidFields(INSURANCE_FIELDS.privateInsuranceFields, patient)
    : [];
  const pensionCardConditional = getInvalidFields(INSURANCE_FIELDS.pensionCardConditional, patient);
  const pensionCardFields = patient?.healthPensionCard
    ? getInvalidFields(INSURANCE_FIELDS.pensionCardFields, patient)
    : [];
  const insuranceFields = medicareOption.concat(
    medicareFields,
    dvaFields,
    privateConditional,
    privateFields,
    pensionCardConditional,
    pensionCardFields,
  );

  const showInterpreterRequired = patient?.niceLanguageAtHome && patient?.niceLanguageAtHome !== 'English';

  const demographicFirstFields = getInvalidFields(DEMOGRAPHICS_FIELDS.fields, patient);
  const demographicInterpreterFields = showInterpreterRequired
    ? getInvalidFields(DEMOGRAPHICS_FIELDS.interpreterRequiredField, patient)
    : [];
  const demographicRemainingFields = getInvalidFields(DEMOGRAPHICS_FIELDS.remainingFields, patient);

  const demographicFields = demographicFirstFields.concat(demographicRemainingFields, demographicInterpreterFields);

  const documentUploadSectionInvalid = documentUploadInvalid(patient);

  const hasError =
    !!(
      basicFields.length ||
      contactFields.length ||
      addressFields.length ||
      emergencyContactFields.length ||
      referrerFields.length ||
      (!referrerMatched(ReferralType.SPECIALIST) && !patient.referringEditedByPso) ||
      (!referrerMatched(ReferralType.GP) && !patient.generalPractitionerEditedByPso) ||
      insuranceFields.length ||
      demographicFields.length
    ) || documentUploadSectionInvalid;

  if (!hasError) {
    return (
      <HeaderSubTitle data-test-id="no-invalid-data-heading">
        Please review the following details and click 'Submit'.
      </HeaderSubTitle>
    );
  }
  const renderUploadedDocuments = () => {
    return (
      <>
        {registrationAttachments(patient.attachments).map((attachment, index) => {
          return (
            <Fragment key={`upload-${index}`}>
              <u>
                <a
                  style={{ color: theme.palette.info.main }}
                  href={attachment.url}
                  target="_blank"
                  rel="noopener noreferrer">
                  {attachment.filename}
                </a>
              </u>
              <StyledRow>
                <StyledField>Document type*</StyledField>
                <StyledInfoField data-test-id={`document-type-${index}`}>
                  {attachment.documentType ? (
                    resolveDocumentType(attachment.documentType)
                  ) : (
                    <span style={{ color: theme.palette.error.main }}>This field is required</span>
                  )}
                </StyledInfoField>
              </StyledRow>
              <u></u>
            </Fragment>
          );
        })}
      </>
    );
  };

  const renderDocumentUploadAck = () => {
    return (
      <StyledRow>
        <StyledField>
          <b>Acknowledgement*</b>
        </StyledField>
        <StyledInfoField>
          <CheckboxTile
            label="I have reviewed and acknowledge the above document type(s) are correct"
            error={!patient.attachmentAcknowledged}
            id="doc-ack-pso"
            checked={patient.attachmentAcknowledged}
            onChange={() => {
              updateAttachmentAcknowledged({
                variables: { patientId, attachmentAcknowledged: !patient.attachmentAcknowledged },
              });
            }}
            errorMessage={'This field is required'}
          />
        </StyledInfoField>
      </StyledRow>
    );
  };

  const renderReferrerErrorMessage = (referralType: ReferralType, hasMissingFields = false): JSX.Element => {
    const allFieldsAvailable = referrerMatched(referralType);

    if (!allFieldsAvailable && !hasMissingFields) {
      return (
        <StyledRow style={{ color: theme.palette.error.main }}>
          Referrer not found. Click 'edit' to lookup referrer or add new record in MOSAIQ.
        </StyledRow>
      );
    }
    return <></>;
  };

  const referrerNotMatched =
    !referrerMatched(ReferralType.SPECIALIST) &&
    !(patient.referringEditedByPso || registrationContext.referrerLookupUsed);
  const gpNotMatched =
    !referrerMatched(ReferralType.GP) && !(patient.generalPractitionerEditedByPso || registrationContext.gpLookupUsed);
  const showReferrer = referrerFields.length > 0 || referrerNotMatched || gpNotMatched;

  return (
    <div style={{ marginTop: '12px' }}>
      {renderAttentionBanner()}
      {documentUploadSectionInvalid && (
        <StyledCard notvalid={true} data-testid="document-upload-invalid">
          <Header title={DOCUMENT_UPLOAD_FIELDS.title} path={DOCUMENT_UPLOAD_FIELDS.path} notvalid={true} />
          {renderUploadedDocuments()}
          {renderDocumentUploadAck()}
        </StyledCard>
      )}

      {showReferrer && (
        <StyledCard notvalid={true} data-testid="referrer-invalid">
          <Header title={REFERRER_FIELDS.title} path={REFERRER_FIELDS.path} notvalid={true} />
          {(referrerSpecialistFields.length > 0 || referrerNotMatched) && (
            <>
              <StyledSubHeading data-test-id="referring-sub-heading">Referring specialist</StyledSubHeading>
              <RowBlock
                fields={REFERRER_FIELDS.fields}
                isValidationField={!referrerNotMatched || referrerSpecialistFields.length > 0}
                patient={patient}
                isPso={false}
              />
              {renderReferrerErrorMessage(ReferralType.SPECIALIST, referrerSpecialistFields.length > 0)}
            </>
          )}

          {(referrerGpFields.length > 0 || gpNotMatched) && (
            <>
              <StyledSubHeading data-test-id="gp-sub-heading">{'GP (General practitioner)'}</StyledSubHeading>
              <RowBlock
                fields={REFERRER_FIELDS.gpFields}
                isValidationField={gpNotMatched || referrerGpFields.length > 0}
                patient={patient}
                isPso={false}
              />
              {renderReferrerErrorMessage(ReferralType.GP, referrerGpFields.length > 0)}
            </>
          )}
        </StyledCard>
      )}

      {basicFields.length > 0 && (
        <StyledCard notvalid={true} data-testid="basic-invalid">
          <Header title={BASIC_FIELDS.title} path={BASIC_FIELDS.path} notvalid={true} />
          <RowBlock fields={basicFields} isValidationField={true} patient={patient} isPso={false} />
        </StyledCard>
      )}
      {contactFields.length > 0 && (
        <StyledCard notvalid={true} data-testid="contact-invalid">
          <Header title={CONTACT_FIELDS.title} path={CONTACT_FIELDS.path} notvalid={true} />
          <RowBlock fields={contactFields} isValidationField={true} patient={patient} isPso={false} />
        </StyledCard>
      )}
      {addressFields.length > 0 && (
        <StyledCard notvalid={true} data-testid="address-invalid">
          <Header title={ADDRESS_FIELDS.title} path={ADDRESS_FIELDS.path} notvalid={true} />
          <RowBlock fields={primaryAddressFields} isValidationField={true} patient={patient} isPso={false} />
          {postalAddressFields.length > 0 && (
            <>
              <StyledSubHeading data-test-id="separate-postal-sub-heading">Postal address</StyledSubHeading>
              <StyledConditionalSection>
                <RowBlock fields={postalAddressFields} isValidationField={true} patient={patient} isPso={false} />
              </StyledConditionalSection>
            </>
          )}
        </StyledCard>
      )}
      {emergencyContactFields.length > 0 && (
        <StyledCard notvalid={true} data-testid="emergency-contact-invalid">
          <Header title={EMERGENCY_CONTACT_FIELDS.title} path={EMERGENCY_CONTACT_FIELDS.path} notvalid={true} />
          <RowBlock fields={alternateContactFields} isValidationField={true} patient={patient} isPso={false} />
          {nextOfKinFields.length > 0 && (
            <>
              <StyledSubHeading data-test-id="additional-contact-sub-heading">Additional contact</StyledSubHeading>
              <StyledConditionalSection>
                <RowBlock
                  fields={EMERGENCY_CONTACT_FIELDS.nextOfKinFields}
                  isValidationField={true}
                  patient={patient}
                  isPso={false}
                />
              </StyledConditionalSection>
            </>
          )}
        </StyledCard>
      )}

      {insuranceFields.length > 0 && (
        <StyledCard notvalid={true} data-testid="insurance-invalid">
          <Header title={INSURANCE_FIELDS.title} path={INSURANCE_FIELDS.path} notvalid={true} />
          {medicareOption.length > 0 && (
            <RowBlock fields={medicareOption} isValidationField={true} patient={patient} isPso={false} />
          )}
          {medicareFields.length > 0 && (
            <>
              <StyledSubHeading data-test-id="medicare-sub-heading">Medicare card</StyledSubHeading>
              <StyledConditionalSection>
                <RowBlock
                  fields={INSURANCE_FIELDS.medicareFields}
                  isValidationField={true}
                  patient={patient}
                  isPso={false}
                />
              </StyledConditionalSection>
            </>
          )}
          {dvaFields.length > 0 && (
            <>
              <StyledSubHeading data-test-id="dva-sub-heading">DVA card</StyledSubHeading>
              <StyledConditionalSection>
                <RowBlock
                  fields={INSURANCE_FIELDS.dvaFields}
                  isValidationField={true}
                  patient={patient}
                  isPso={false}
                />
              </StyledConditionalSection>
            </>
          )}
          {privateConditional.length > 0 && (
            <RowBlock fields={privateConditional} isValidationField={true} patient={patient} isPso={false} />
          )}
          {privateFields.length > 0 && (
            <>
              <StyledRow>
                <StyledField>Private health insurance?</StyledField>
                <StyledField>Yes</StyledField>
              </StyledRow>
              <StyledConditionalSection>
                <RowBlock fields={privateFields} isValidationField={true} patient={patient} isPso={false} />
              </StyledConditionalSection>
            </>
          )}
          {pensionCardConditional.length > 0 && (
            <>
              <RowBlock fields={pensionCardConditional} patient={patient} isPso={false} />
            </>
          )}
          {pensionCardFields.length > 0 && (
            <>
              <StyledRow>
                <StyledField>Are you a concession card holder?</StyledField>
                <StyledField>Yes</StyledField>
              </StyledRow>
              <StyledConditionalSection>
                <RowBlock fields={pensionCardFields} isValidationField={true} patient={patient} isPso={false} />
              </StyledConditionalSection>
            </>
          )}
        </StyledCard>
      )}
      {demographicFields.length > 0 && (
        <StyledCard notvalid={true} data-testid="demographic-invalid">
          <Header title={DEMOGRAPHICS_FIELDS.title} path={DEMOGRAPHICS_FIELDS.path} notvalid={true} />
          <RowBlock fields={demographicFirstFields} isValidationField={true} patient={patient} isPso={false} />
          {demographicInterpreterFields.length > 0 && (
            <StyledConditionalSection>
              <RowBlock
                fields={demographicInterpreterFields}
                isValidationField={true}
                patient={patient}
                isPso={false}
              />
            </StyledConditionalSection>
          )}
          <RowBlock fields={demographicRemainingFields} isValidationField={true} patient={patient} isPso={false} />
        </StyledCard>
      )}
      <StyledDivider />
      {renderCompleteBanner()}
    </div>
  );
};

export default RenderInvalidSections;
