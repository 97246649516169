import { styled } from '@mui/system';

export default styled('div')<any>`
  font-size: 13px;
  line-height: 16px;
  color: ${(props) => props.theme.palette.grey[600]};
  font-weight: bold;
  margin-bottom: 16px;
  text-transform: uppercase;
`;
