import React from 'react';
import { styled } from '@mui/system';

const ArrowLine = styled('div')`
  height: 0px;
  border-bottom: solid 2px transparent;
  box-shadow: 0px 2px 4px rgba(46, 46, 46, 0.2);
  border-radius: 2px;
  position: relative;
  z-index: 1;
`;

const DownArrow = styled('div')`
  width: 0;
  height: 0;
  border-left: 40px solid transparent;
  border-right: 40px solid transparent;
  border-top: 15px solid white;
  margin-left: 75%;
  z-index: 2;
`;

const GrayBackground = styled('div')`
  background: ${(props) => props.theme.palette.grey[100]};
  padding: 20px 0px;
`;

// To be obselete @ ChartCheck's Review/Submit cleanup, just taken out to try reduce duplicates where possible.
const ReviewSubFields = ({ children }: any): JSX.Element => (
  <>
    <ArrowLine>
      <DownArrow />
    </ArrowLine>
    <GrayBackground>{children}</GrayBackground>
  </>
);

export default ReviewSubFields;
