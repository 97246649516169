// eslint-disable-next-line no-use-before-define
import React, { useState } from 'react';
import { ToggleButtons } from 'shared-components/components/FormFields';
import { StandardDialog } from 'shared-components/components';
import { Stack, Typography } from '@mui/material';

const BOOLEAN_OPTIONS = [
  { label: 'Yes', value: 'yes' },
  { label: 'No', value: 'no' },
];

interface Props {
  isOpen: boolean;
  submitFunction: (value: string) => void;
}

export interface ButtonInterface {
  text: string;
  value: string;
}

const ModalSimulationSubmission = (props: Props): JSX.Element => {
  const { isOpen, submitFunction } = props;
  const [currentValue, setCurrentValue] = useState<string>('');
  const [isInvalid, setIsInvalid] = useState<boolean>(false);
  const checkSimSubmission = () => {
    if (currentValue === '') {
      setIsInvalid(true);
      return;
    }
    submitFunction(currentValue);
  };

  return (
    <StandardDialog
      open={isOpen}
      title="Would you like to request a new simulation?"
      onSubmit={checkSimSubmission}
      submitText="Confirm">
      <Stack gap={2} paddingY={2}>
        <Typography>
          A change to the simulation or treatment sites has occured. Please confirm if a new simulation is required*
        </Typography>
        <ToggleButtons
          id={'modal-simulation-submission'}
          options={BOOLEAN_OPTIONS}
          fullWidth
          value={currentValue}
          handleChange={(value: string): void => {
            setIsInvalid(false);
            setCurrentValue(value);
          }}
          error={isInvalid}
        />
      </Stack>
    </StandardDialog>
  );
};

export default ModalSimulationSubmission;
