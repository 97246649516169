import { TextAreaField, ToggleButtonGroupField } from 'shared-components/components/FormikComponents';

export const FIELDS = {
  FORM_HEADING: {
    NAME: 'formHeading',
    TITLE: 'Address',
  },
  IN_HOSPITAL_BOOL: {
    NAME: 'inHospitalHospiceFacility',
    TITLE: 'Are you currently admitted to a hospital or enrolled in a Hospice or Skilled Nursing Facility?',
    COMPONENT: ToggleButtonGroupField,
  },
  FACILITY_NAME: {
    NAME: 'facilityName',
    TITLE: 'Facility name',
    PLACE_HOLDER: 'Enter facility name',
    COMPONENT: TextAreaField,
  },
  CONTACT_NUMBER: {
    NAME: 'facilityContactNumber',
    TITLE: 'Contact number',
    PLACE_HOLDER: 'Enter contact number',
    COMPONENT: TextAreaField,
  },
};

export const ADDRESS_VALIDATION = 'Please enter a valid address';
export const POSTCODE_VALIDATION = 'Please enter your correct Postcode';
