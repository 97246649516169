// eslint-disable-next-line no-use-before-define
import { gql } from '@apollo/client';
import { Query } from '@apollo/client/react/components';
import { WithApolloClient, withApollo } from '@apollo/client/react/hoc';
import lodash from 'lodash';
import { OPUser, Patient, UKFullPatient, UpdatePatientResult } from 'op-interfaces';
import { Component, Fragment } from 'react';

import { RouteComponentProps } from 'react-router';
import { SavingStatus, SubmitButtonType } from 'shared-components/enums';
import { ListData } from 'shared-components/interfaces';
import { Logger } from 'shared-components/utils';

import { ExtendLock } from 'op-components';
import { LoadingSpinner } from 'shared-components/components';

import withRegistrationForm, { GET_APOLLO_CACHE, WithRegistrationForm } from '../RegistrationForm';
import UKRegistrationSummary from './UKRegistrationSummary';

const logger = new Logger('RegistrationSummaryApollo');

const PATIENT_AND_USER_DETAILS_QUERY = gql`
  query PatientAndUserDetails($id: ID!) {
    patient(id: $id) {
      id
      ida
      lock {
        lockedBy
        readOnly
        lockedByName
      }
      firstName
      middleName
      lastName
      namePrefix
      preferredName
      dob
      address {
        id
        ukFormattedAddress
      }
      serviceRequests {
        id
        referralType
        referralStatus
        date
        encounterDate
      }
      dobRawDay
      dobRawMonth
      dobRawYear
      primaryPhone
      secondaryPhone
      email
      preferenceText
      preferenceEmail
      preferenceMessage
      contactPreferences
      attachments {
        id
        filename
        typeDisplay
        filesize
        url
      }
      residentialAddressLine1
      residentialAddressLine2
      residentialAddressCity
      residentialAddressState
      residentialAddressPostcode
      residentialAddressCountry
      residentialAddressCountryName
      postalAddressSameAsResidential
      postalAddressLine1
      postalAddressLine2
      postalAddressCity
      postalAddressState
      postalAddressPostcode
      postalAddressCountry
      emergencyContact {
        id
        firstName
        lastName
        relationship
        mobilePhoneNumber
        homePhoneNumber
        email
        authorisedForEnquiries
        supportPerson
      }
      nextOfKinContact {
        id
        firstName
        lastName
        relationship
        mobilePhoneNumber
        homePhoneNumber
        email
        authorisedForEnquiries
        supportPerson
      }
      idb
      generalPractitionerLocation
      generalPractitioner
      referringSurgeonLocation
      referringSurgeon
      oncologist
      payor
      coverageRelationship
      policyNumber
      preAuthNumber
      countryOfBirth
      languageAtHome
      gender
      maritalStatus
      ethnicity
      religion
      additionalComments
      acceptsDataShare
      primaryCenter
      sameAsEmergency
      nhsOptions
      idbConflict
      registrationReason
      registrationReasonText
      isProd
      hasPendingSubmission
    }
    documentTypeRefData: listData(category: "attachmentType") {
      id
      name
    }
    gpSurgeryRefData: practitionerLocations(patient: $id, search: "") {
      id
      name: fullName
    }
    surgeonLocationRefData: practitionerLocations(patient: $id, search: "") {
      id
      name: fullName
    }
    gpRefData: practitioners(url: "https://genesiscare.com/fhir/general-practice-surgery", patient: $id) {
      id
      name
    }
    surgeonRefData: extPractitioners(patient: $id, search: "") {
      id
      name: name
    }
    oncologistRefData: oncologists(patient: $id, search: "") {
      id
      name
    }
    titleRefData: listData(category: "nameTitle") {
      id
      name
    }
    relationshipsRefData: listData(category: "relationships") {
      id
      name
    }
    heritageRefData: listData(category: "heritage") {
      id
      name
    }
    maritalStatusRefData: listData(category: "maritalStatus") {
      id
      name
    }
    ethnicityRefData: listData(category: "ethnicity") {
      id
      name
    }
    religionRefData: listData(category: "religion") {
      id
      name
    }
    countryOfBirthRefData: listData(category: "countryOfBirth") {
      id
      name
    }
    genderRefData: listData(category: "gender") {
      id
      name
    }
    languageSpokenRefData: listData(category: "languages") {
      id
      name
    }
    medicareTypeRefData: listData(category: "healthFund") {
      id
      name
      appKey
    }
    insurerRefData: departments {
      id
      name
    }
    relationshipRefData: listData(category: "coverageRelationship") {
      id
      name
    }
    dvaTypeRefData: listData(category: "dvaCardType") {
      id
      name
      appKey
    }
    ausStateRefData: listData(category: "ukCounties") {
      id
      name
    }
    primaryCenterRefData: configs {
      id
      name
    }
    nhsOptionsRefData: listData(category: "nhsOptions") {
      id
      name
    }
    registrationReasonRefData: listData(category: "registrationReason") {
      id
      name
    }
    user {
      id
      isPso
    }
  }
`;

const CREATE_SUBMISSION = gql`
  mutation CreateSubmission($patientID: ID) {
    createSubmission(patientId: $patientID) {
      submission {
        id
        pdf
        patient {
          id
          firstName
          lastName
        }
      }
    }
  }
`;

interface PatientAndUserDetailsQueryData {
  patient: UKFullPatient;
  registrationReasonRefData: ListData[];
  nhsOptionsRefData: ListData[];
  insurerRefData: ListData[];
  relationshipRefData: ListData[];
  gpSurgeryRefData: ListData[];
  surgeonLocationRefData: ListData[];
  gpRefData: ListData[];
  extPracRefData: ListData[];
  surgeonRefData: ListData[];
  oncologistRefData: ListData[];
  titleRefData: ListData[];
  relationshipsRefData: ListData[];
  maritalStatusRefData: ListData[];
  ethnicityRefData: ListData[];
  religionRefData: ListData[];
  acceptsDataShareRefData: ListData[];
  countryOfBirthRefData: ListData[];
  genderRefData: ListData[];
  languageSpokenRefData: ListData[];
  ausStateRefData: ListData[];
  documentTypeRefData: ListData[];
  primaryCenterRefData: ListData[];
  user: OPUser;
}

interface CreateSubmissionMutationResponse {
  createSubmission: {
    submission: {
      id: string;
      pdf: string;
      patient: {
        firstName: string;
        lastName: string;
      };
    };
  };
}

interface State {
  saveStatus: SavingStatus;
  submitStatus: SubmitButtonType;
}

interface Props extends WithApolloClient<{}>, WithRegistrationForm, RouteComponentProps<{ patientId?: string }> {}

class UKRegistrationSummaryApollo extends Component<Props, State> {
  private userIsPSO = false;

  public constructor(props: Props) {
    super(props);
    this.state = { saveStatus: SavingStatus.SAVED, submitStatus: SubmitButtonType.SUBMIT_AND_EXIT };
    // Get the saving status and set it
    this.getPendingSaveCount().then((result): void => {
      const currentPendingSaveCount = result.currentPendingSaveCount;
      const saveErrorCount = result.saveErrorCount;
      this.state = {
        saveStatus: this.props.getSaveStatus(currentPendingSaveCount, saveErrorCount),
        submitStatus: SubmitButtonType.SUBMIT_AND_EXIT,
      };
    });
  }

  public render(): JSX.Element {
    const { match, showModalIfLocked } = this.props;
    const { patientId } = match.params;

    return (
      <Query<PatientAndUserDetailsQueryData>
        query={PATIENT_AND_USER_DETAILS_QUERY}
        variables={{ id: patientId }}
        onCompleted={(data: { patient: Patient }): void => {
          showModalIfLocked(data);
        }}>
        {({ loading, data, error }): JSX.Element => {
          if (loading) return <LoadingSpinner />;

          if (data && data.patient) {
            this.userIsPSO = data.user.isPso || false;
            return (
              <Fragment>
                <ExtendLock accessPatientId={data.patient.id} />
                <UKRegistrationSummary
                  patient={data.patient}
                  registrationReasonRefData={data.registrationReasonRefData}
                  nhsOptionsRefData={data.nhsOptionsRefData}
                  insurerRefData={data.insurerRefData}
                  relationshipRefData={data.relationshipRefData}
                  gpSurgeryRefData={this.formatRefData(data.gpSurgeryRefData)}
                  surgeonLocationRefData={data.surgeonLocationRefData}
                  gpRefData={data.gpRefData}
                  extPracRefData={data.extPracRefData}
                  documentTypeRefData={data.documentTypeRefData}
                  surgeonRefData={this.formatRefData(data.surgeonRefData)}
                  oncologistRefData={data.oncologistRefData}
                  titleRefData={data.titleRefData}
                  relationshipsRefData={data.relationshipsRefData}
                  maritalStatusRefData={data.maritalStatusRefData}
                  ethnicityRefData={data.ethnicityRefData}
                  religionRefData={data.religionRefData}
                  acceptsDataShareRefData={data.acceptsDataShareRefData}
                  countryOfBirthRefData={data.countryOfBirthRefData}
                  genderRefData={data.genderRefData}
                  primaryCenterRefData={data.primaryCenterRefData}
                  languageSpokenRefData={data.languageSpokenRefData}
                  ausStateRefData={data.ausStateRefData}
                  navigateToPage={this.navigate}
                  autosave={this.autosave}
                  saveStatus={this.state.saveStatus}
                  submitRegistration={this.submitRegistration}
                  submitStatus={this.state.submitStatus}
                  isPso={this.userIsPSO}
                />
              </Fragment>
            );
          }

          if (error) return <div>{`Error loading: ${error}`}</div>;
          return <div />;
        }}
      </Query>
    );
  }

  private formatRefData = (refData: ListData[]): ListData[] => {
    return refData.map((value) => {
      const valueClone = lodash.cloneDeep(value);
      valueClone.id = valueClone.name;
      return value;
    });
  };

  private autosave = async (patient: UKFullPatient, key: string, value: string): Promise<void> => {
    const client = this.props.client;
    const update = {
      key,
      value,
      type: 'String',
    };

    // Get the pending save count and increment it by 1
    const saveCount = await this.getPendingSaveCount();
    let currentPendingSaveCount = saveCount.currentPendingSaveCount + 1;
    let saveErrorCount = saveCount.saveErrorCount;
    client &&
      client.writeQuery({
        query: gql`
          query {
            pendingSaveCount
          }
        `,
        data: {
          pendingSaveCount: currentPendingSaveCount,
        },
      });
    this.setState({ saveStatus: this.props.getSaveStatus(currentPendingSaveCount, saveErrorCount) });

    // Save the updated data. Once done deincrement the pending save count. If any erros saving increment the saveErrorCount
    client
      //@ts-ignore
      ?.mutate(this.props.getPatientMutation(patient, [update]))
      //@ts-ignore
      .then((result: { data: UpdatePatientResult }): void => {
        if (result.data.updatePatient.errors) {
          saveErrorCount++;
        }
      })
      .catch((): void => {
        this.props.showSavingErrorModal(this.userIsPSO, this.props.history.push);
        saveErrorCount++;
      })
      .finally(async (): Promise<void> => {
        // Reload the save count incase it was set elsewhere during the run of this function
        const saveCount = await this.getPendingSaveCount();
        currentPendingSaveCount = saveCount.currentPendingSaveCount - 1;
        client &&
          client.writeQuery({
            query: gql`
              query {
                pendingSaveCount
                saveErrorCount
              }
            `,
            data: {
              pendingSaveCount: currentPendingSaveCount,
              saveErrorCount: saveErrorCount,
            },
          });
        this.setState({ saveStatus: this.props.getSaveStatus(currentPendingSaveCount, saveErrorCount) });
      });
  };

  private getPendingSaveCount = async (): Promise<{ currentPendingSaveCount: number; saveErrorCount: number }> => {
    const client = this.props.client;
    let currentPendingSaveCount = 0;
    let saveErrorCount = 0;
    try {
      const data = await client?.query({ query: GET_APOLLO_CACHE, variables: {} });
      currentPendingSaveCount = data?.data.pendingSaveCount;
      saveErrorCount = data?.data.saveErrorCount;
      return {
        currentPendingSaveCount: currentPendingSaveCount,
        saveErrorCount: saveErrorCount,
      };
    } catch (error) {
      throw error;
    }
  };

  private navigate = (link: string): void => {
    this.props.history.push(link);
  };

  private submitRegistration = (closeModal: () => void): void => {
    const {
      match: {
        params: { patientId },
      },
      client,
    } = this.props;

    // Change the status of the submission to be submitting
    this.setState({ submitStatus: SubmitButtonType.SUBMITTING });

    client
      ?.mutate({
        mutation: CREATE_SUBMISSION,
        variables: {
          patientID: patientId,
        },
      })
      .then((response): void => {
        if (response.data && response.data.createSubmission) {
          // Change the status to SUBMITTED regardless of whether a submission was created.
          // NOTE: `submitted` will be null for Patients and not null for PSOs.
          this.setState({ submitStatus: SubmitButtonType.SUBMITTED });

          if (response.data.createSubmission.submission && response.data.createSubmission.submission.pdf) {
            logger.debug(
              'submitRegistration',
              `Response came back: /server/media/${response.data.createSubmission.submission.pdf}`,
            );
          }
          closeModal();
          const { patient } = response.data.createSubmission.submission;
          const name = encodeURI(`${patient.firstName}+${patient.lastName}`);
          this.props.history.replace(`/search?q=${name}`);
        }
      });
  };
}

const apolloComponent = withApollo<Props>(UKRegistrationSummaryApollo);
const component = withRegistrationForm(apolloComponent);
export default component;
