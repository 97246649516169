import Dashboards from '@highcharts/dashboards';
import DataPoolOptions from '@highcharts/dashboards/es-modules/Data/DataPoolOptions';
import ChartColumn from '../Charts/ChartColumn';
import SubChartBar from '../Charts/SubChartBar';
import SubChartBarCount from '../Charts/SubChartBarCount';
import { EditModeConfig, GuiConfig, DashboardConfig, SUB_CHART_LIMIT } from './DefaultConfig';
import { aggregateAverageSimToTreat, aggregateData } from '../../Utils/dataFunctions';

const PatientWaitTimes = ({
  theme,
  data,
  subChartData,
  dateFilterOverride,
  selectedIndex,
  seeMoreUpdated,
  seeMoreBottomLeft,
  seeMoreBottomRight,
  toggleSeeMoreBottomLeft,
  toggleSeeMoreBottomRight,
  onPointClick,
}: DashboardConfig): Dashboards.Board.Options => ({
  dataPool: dataPoolConfig(data, subChartData, seeMoreBottomLeft, seeMoreBottomRight),
  editMode: EditModeConfig,
  gui: GuiConfig,
  components: [
    ChartColumn({
      onPointClick: onPointClick,
      selectedPoint: dateFilterOverride,
      selectedIndex: selectedIndex,
      seeMoreUpdated: seeMoreUpdated,
      leftSeriesTitle: 'Average sim to treatment time (Days)',
      rightSeriesTitle: 'Target',
      leftSeriesFields: ['Date', 'Sim to treat'],
      rightSeriesFields: ['Date', 'Target'],
    }).components?.[0] ?? {},
    SubChartBarCount({
      filterOverride: dateFilterOverride,
      seeMore: seeMoreBottomLeft,
      title: 'Physicians',
      connector: 'sub-chart-left-data',
      cell: 'sub-chart-left',
      dataLabelTitle: 'Average sim to treatment time (Days)',
      dataLabelTitleWidth: 120,
      precision: 1,
      yAxisMax: 30,
      theme: theme,
      toggleSeeMore: toggleSeeMoreBottomLeft,
    }).components?.[0] ?? {},
    SubChartBar({
      filterOverride: dateFilterOverride,
      seeMore: seeMoreBottomRight,
      title: 'Priority',
      connector: 'sub-chart-right-data',
      cell: 'sub-chart-right',
      theme: theme,
      toggleSeeMore: toggleSeeMoreBottomRight,
    }).components?.[0] ?? {},
  ],
});

const dataPoolConfig = (
  data: any,
  subChartData: any,
  seeMoreBottomLeft: boolean,
  seeMoreBottomRight: boolean,
): DataPoolOptions => ({
  connectors: [
    {
      id: 'top-chart-data',
      type: 'JSON',
      options: {
        firstRowAsNames: false,
        columnNames: ['Date', 'Sim to treat', 'Target'],
        data: aggregateAverageSimToTreat(data, 'months'),
      },
    },
    {
      id: 'sub-chart-left-data',
      type: 'JSON',
      options: {
        data: aggregateData(subChartData, 'physician', seeMoreBottomLeft ? undefined : SUB_CHART_LIMIT, undefined, 12),
      },
    },
    {
      id: 'sub-chart-right-data',
      type: 'JSON',
      options: {
        data: aggregateData(subChartData, 'priority', seeMoreBottomRight ? undefined : SUB_CHART_LIMIT),
      },
    },
  ],
});

export default PatientWaitTimes;
