import { useQuery } from '@apollo/client';
import { SummaryCard, SummaryRow } from 'op-components';
import { evaluateDetailText } from 'op-components/OP/SummaryRow/SummaryRow';
import { MOBILITY_FIELDS } from 'op-pages/OP/HealthAssessment/HAPages/constants';
import { renderArrow } from 'op-pages/OP/HealthAssessment/HASharedComponents/HAModalFields';
import { HA_MOBILITY_QUERY } from 'op-pages/OP/HealthAssessment/HASharedComponents/queries';
import { HAMobilityItem, HASubComponentProps } from 'op-pages/OP/HealthAssessment/HASharedComponents/types';
import React, { useMemo } from 'react';
import { Edit, Success } from 'shared-components/images';
import { SerializedListDataObject } from 'shared-components/interfaces';
import { convertListDataToObject } from 'shared-components/utils/ListDataHelpers';

const HAMobilityAssessmentUS = (props: HASubComponentProps) => {
  const { patientId, isPso, navigate } = props;

  const { data: haMobilityAssessmentData, loading } = useQuery(HA_MOBILITY_QUERY, {
    variables: { patientId },
  });

  const mobilityAidRefDataSerialized = useMemo<SerializedListDataObject>(
    () => convertListDataToObject(haMobilityAssessmentData?.mobilityAidRefData),
    [haMobilityAssessmentData?.mobilityAidRefData],
  );
  const data = useMemo<HAMobilityItem>(() => haMobilityAssessmentData?.healthAssessment, [haMobilityAssessmentData]);

  if (loading) {
    return <></>;
  }

  if (haMobilityAssessmentData) {
    return (
      <SummaryCard
        id="mobility-assessment"
        title={'Mobility-fall risk assessment'}
        primaryIcon={isPso ? <></> : <Edit className="primary-icon" />}
        secondaryIcon={<Success className="secondary-icon valid-icon" />}
        showEdit={!isPso}
        onClick={() => {
          if (!isPso) {
            navigate('mobility');
          }
        }}>
        <SummaryRow
          headingText={MOBILITY_FIELDS.MOBILITY_AID.TITLE}
          detailText={evaluateDetailText(data.mobilityAid)}
          displayAsError={false}
          displayAsOptional={false}
        />
        {data.mobilityAid == 'YES' && (
          <>
            {renderArrow()}
            {data.mobilityAids.map((mobilityAid: any, index: number) => (
              <SummaryRow
                key={index}
                headingText={`Mobility aid ${index + 1}`}
                detailText={
                  mobilityAidRefDataSerialized[mobilityAid.value] === 'Other'
                    ? evaluateDetailText(mobilityAid.other)
                    : mobilityAidRefDataSerialized[mobilityAid.value]
                }
                fallbackText={' '}
                displayAsError={false}
                displayAsOptional={false}
                subQuestion
              />
            ))}
          </>
        )}
        <SummaryRow
          headingText={MOBILITY_FIELDS.FALLEN.TITLE}
          detailText={evaluateDetailText(data.fallen)}
          displayAsError={false}
          displayAsOptional={false}
        />
        {data.fallen == 'YES' && (
          <>
            {renderArrow()}
            <SummaryRow
              headingText={MOBILITY_FIELDS.FALLEN_HOW_MANY.TITLE}
              detailText={evaluateDetailText(data.fallenHowMany)}
              displayAsError={false}
              displayAsOptional={false}
              subQuestion
            />
            <SummaryRow
              headingText={MOBILITY_FIELDS.FALLEN_INJURIES.TITLE}
              detailText={evaluateDetailText(data.fallenInjuries)}
              displayAsError={false}
              displayAsOptional={false}
              subQuestion
            />
          </>
        )}
        <SummaryRow
          headingText={MOBILITY_FIELDS.FOOT_PAIN.TITLE}
          detailText={evaluateDetailText(data.footPain)}
          displayAsError={false}
          displayAsOptional={false}
        />
        <SummaryRow
          headingText={MOBILITY_FIELDS.UNSTEADY.TITLE}
          detailText={evaluateDetailText(data.unsteady)}
          displayAsError={false}
          displayAsOptional={false}
        />
        <SummaryRow
          headingText={MOBILITY_FIELDS.LIGHTHEADED.TITLE}
          detailText={evaluateDetailText(data.lightheaded)}
          displayAsError={false}
          displayAsOptional={false}
        />
      </SummaryCard>
    );
  }

  return <></>;
};

export default HAMobilityAssessmentUS;
