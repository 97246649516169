import { ApolloLink, Observable, FetchResult } from '@apollo/client';

/**
 * Some devices add special characters for punctuation such as iOS smart punctuation
 * This will return a map which has the special characters and a standard replacement charater
 */
function createStringReplacementMap(): { [key: string]: string } {
  const map: { [key: string]: string } = {};

  // Open-quotes: http://www.fileformat.info/info/unicode/category/Pi/list.htm
  map['\u2018'] = "'";
  map['\u201b'] = "'";
  map['\u201c'] = '"';
  map['\u201f'] = '"';

  // Close-quotes: http://www.fileformat.info/info/unicode/category/Pf/list.htm
  map['\u2019'] = "'";
  map['\u201d'] = '"';

  // Primes: http://www.fileformat.info/info/unicode/category/Po/list.htm
  map['\u2032'] = "'";
  map['\u2033'] = '"';
  map['\u2035'] = "'";
  map['\u2036'] = '"';

  map['\u2014'] = '-'; // iOS 11 also replaces dashes with em-dash
  map['\u2013'] = '-'; // and "--" with en-dash

  return map;
}

const mapReplace = (str: string, map: { [key: string]: string }): string => {
  const matchStr = Object.keys(map).join('|');
  if (!matchStr) return str;
  const regexp = new RegExp(matchStr, 'g');
  return str.replace(regexp, (match): string => map[match]);
};

export const FormatSpecialCharactersMiddleware = new ApolloLink(
  (operation, forward): Observable<FetchResult> | null => {
    // Replace varible charachers
    if (operation.variables) {
      Object.entries(operation.variables).forEach(([key, value]): void => {
        if (typeof value === 'string') {
          // Replaces characters such as smart punctuation in iOS to standard counterparts
          // https://stackoverflow.com/questions/48678359/ios-11-safari-html-disable-smart-punctuation
          const stringReplacementMap = createStringReplacementMap();
          operation.variables[key] = mapReplace(value, stringReplacementMap);
        }
      });
    }
    // return forward(operation);
    if (forward) {
      return forward(operation);
    } else {
      return null;
    }
  },
);
