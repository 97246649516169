// eslint-disable-next-line no-use-before-define
import classnames from 'classnames';
import { Field, FieldHookConfig, useField } from 'formik';
import React, { useState } from 'react';
import PlacesAutocomplete, { geocodeByAddress, PropTypes } from 'react-places-autocomplete';
import HelperMessage from 'shared-components/components/UIFormComponents/HelperMessage';
import { Region } from 'shared-components/enums';
import { Address } from 'shared-components/interfaces';
import { getRemSize } from 'shared-components/StyledComponents/functions';
import { styled } from '@mui/system';
import { CircularProgress } from '@mui/material';
import { BaseTextField } from 'shared-components/components/FormFields';

const StyledField = styled((props: any) => <Field {...props} />)`
  width: 100%;
  height: 100%;
  font-size: ${getRemSize(14)};
  box-shadow: none;
  outline: none;
  height: 40px;
  resize: none;
`;

const StyledSpinner = styled(CircularProgress)`
  position: absolute;
  margin-left: 8px;
  bottom: 11px;
  right: 8px;
  color: ${(props) => props.theme.palette.primary.main};
`;

const StyledSuggestionsContainer = styled('div')`
  position: relative;
  margin-top: 8px;
  border-radius: 4px;
`;

const StyledSuggestions = styled((props: any) => <div {...props} />)`
  position: absolute;
  z-index: 1000;
  background: white;
  width: 100%;
  border-radius: 5px;
  box-shadow: 0 0 0 1px hsl(0deg 0% 0% / 10%), 0 4px 11px hsl(0deg 0% 0% / 10%);

  &:first-child {
    padding-top: 5px;
  }

  .suggestion-item {
    cursor: pointer;
    padding-left: 8px;

    &.active {
      &:hover {
        background-color: ${(props) => props.theme.palette.grey[100]};
      }
    }
  }

  &:last-child {
    padding-bottom: 5px;
  }
`;

interface Props {
  placeholder?: string;
  value?: string;
  locationBiasing?: boolean;
  updateAddressField?: (value: string) => void;
  updateAutoFilledAddress: (address: Address) => void;
}

const AddressAutocompleteField = (props: FieldHookConfig<string> & Props): JSX.Element => {
  const { placeholder, name, updateAddressField, updateAutoFilledAddress, value = '', locationBiasing = true } = props;

  const [displaySuggestions, setDisplaySuggestions] = useState<boolean>(true);

  const searchOptions: PropTypes['searchOptions'] = {
    types: ['address'],
    radius: 2000,
  };

  if (locationBiasing) {
    if (import.meta.env.REACT_APP_REGION === Region.AU) searchOptions.location = new google.maps.LatLng(-25, 134);
    if (import.meta.env.REACT_APP_REGION === Region.UK) searchOptions.location = new google.maps.LatLng(55, -3);
    if (import.meta.env.REACT_APP_REGION === Region.US) searchOptions.location = new google.maps.LatLng(37, -96);
  }

  const [, , autofilledHelpers] = useField(`${name}.autofilled`);
  const [, , countryHelpers] = useField(`${name}.country`);
  const [, , stateHelpers] = useField(`${name}.state`);
  const [, line1Meta, line1Helpers] = useField(`${name}.line1`);
  const [, , line2Helpers] = useField(`${name}.line2`);
  const [, , cityHelpers] = useField(`${name}.city`);
  const [, , postcodeHelpers] = useField(`${name}.postcode`);

  const [address, setAddress] = useState<string>(value);

  const handleBlur = (e: React.FocusEvent<HTMLTextAreaElement>) => {
    e.preventDefault();
    updateAddressField && updateAddressField(e.target.value);
    setDisplaySuggestions(false);
  };

  const handleSelect = (address: string) => {
    geocodeByAddress(address).then((results) => {
      if (results.length > 0) {
        const resultVal = results[0];
        const formattedAddress = resultVal.formatted_address;
        const addressComponents = resultVal.address_components;

        // Autofilled
        setAddress(formattedAddress);
        autofilledHelpers.setValue(formattedAddress);

        // Country
        let countryValue = addressComponents.filter((a) => a.types.includes('country'))[0]?.long_name;
        switch (countryValue) {
          case 'US':
          case 'USA':
          case 'United States':
          case 'United States of America':
            countryValue = 'United States of America';
            break;
          case 'UK':
          case 'United Kingdom':
            countryValue = 'United Kingdom';
            break;
          default: // do nothing
        }

        let line1Value = '';
        let line2Value = '';
        let cityValue = '';
        let postcodeValue = '';
        let stateValue = '';
        for (const component of resultVal.address_components) {
          // based on https://developers.google.com/maps/documentation/javascript/examples/places-autocomplete-addressform
          const componentType = component.types[0];
          switch (componentType) {
            // Line 1
            case 'street_number': {
              line1Value = `${component.long_name} ${line1Value}`;
              break;
            }
            case 'route': {
              line1Value += component.short_name;
              break;
            }

            // Line 2
            case 'subpremise': {
              line2Value = `${component.long_name}`;
              break;
            }

            // Postcode / ZIP
            case 'postal_code': {
              postcodeValue = `${component.long_name}${postcodeValue}`;
              break;
            }
            case 'postal_code_suffix': {
              postcodeValue = `${postcodeValue}-${component.long_name}`;
              break;
            }

            // City / Suburb
            case 'locality':
              cityValue = component.long_name;
              break;
            case 'postal_town':
              cityValue = component.long_name; // for UK and Sweden
              break;

            // State
            case 'administrative_area_level_1': {
              stateValue = countryValue === 'Australia' ? component?.short_name : component?.long_name;
              break;
            }

            default: // do nothing
          }
          setAddress(line1Value);
          line1Helpers.setValue(line1Value);
          line2Helpers.setValue(line2Value);
          cityHelpers.setValue(cityValue);
          stateHelpers.setValue(stateValue);
          postcodeHelpers.setValue(postcodeValue);
          countryHelpers.setValue(countryValue);
        }
        updateAutoFilledAddress({
          country: countryValue,
          line1: line1Value,
          line2: line2Value,
          city: cityValue,
          state: stateValue,
          postcode: postcodeValue,
        });
      }
    });
  };

  const handleChange = (value: string) => {
    line1Helpers.setValue(value);
    setAddress(value);
    setDisplaySuggestions(true);
  };

  return (
    <>
      <PlacesAutocomplete value={address} onChange={handleChange} onSelect={handleSelect} searchOptions={searchOptions}>
        {({ getInputProps, suggestions, getSuggestionItemProps, loading }) => (
          <>
            <StyledField
              component={BaseTextField}
              id={`${name}.line1`}
              {...getInputProps({
                placeholder: placeholder,
                className: 'location-search-input',
              })}
              onFocus={(event: any) => {
                event.target.setAttribute('autocomplete', 'random-text');
              }}
              name={`${name}.line1`}
              onBlur={handleBlur}
            />
            {(loading || suggestions?.length > 0) && (
              <StyledSuggestionsContainer>
                {loading ? (
                  <StyledSpinner sx={{ height: '24px !important', width: '24px !important', marginBottom: '6px' }} />
                ) : (
                  displaySuggestions &&
                  suggestions.length > 0 && (
                    <StyledSuggestions
                      className={classnames('autocomplete-dropdown-container', {
                        'with-suggestions': suggestions.length > 0,
                      })}>
                      {suggestions.map((suggestion, i) => {
                        const className = classnames('suggestion-item', { active: suggestion.active });
                        return (
                          <div
                            {...getSuggestionItemProps(suggestion, {
                              className,
                            })}
                            key={i}>
                            <span>{suggestion.description}</span>
                          </div>
                        );
                      })}
                    </StyledSuggestions>
                  )
                )}
              </StyledSuggestionsContainer>
            )}
            {line1Meta.touched && line1Meta?.error && (
              <HelperMessage fieldName={name} fieldText={line1Meta.error} helperType="error" fontSize={14} />
            )}
          </>
        )}
      </PlacesAutocomplete>
    </>
  );
};

export default AddressAutocompleteField;
