// eslint-disable-next-line no-use-before-define
import React from 'react';
import './LogoutOP.scss';
import logo from 'shared-components/images/Horizon_Green_Logo.png';
import { CustomTheme, useTheme } from '@mui/material';

const LogoutOP = (): JSX.Element => {
  const theme: CustomTheme = useTheme();
  return (
    <div id="logout-container" className="logout-container">
      <img src={logo}></img>
      <h1>You have been logged out of {theme.custom.title}</h1>
      <p>You may now safely close this window</p>
    </div>
  );
};

export default LogoutOP;
