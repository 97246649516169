import { UserContextState } from 'op-contexts/UserContext/UserContext';
import { CareplanCycle } from './Careplan/PrescriptionPage/interfaces';
import { DrugOrderInterface, NewSupportingDrugOrder } from './interfaces';

export const getDrugName = ({
  doseIsCalculated,
  doseBasis,
  minimumDose,
  doseUnit,
  route,
  genericName,
  prn,
}: DrugOrderInterface): string => {
  const doseString = `${doseIsCalculated ? doseBasis : minimumDose} ${doseUnit}`;
  return (prn ? 'PRN: ' : '') + `${genericName} ${route} ${doseString}`;
};

export const isNewSupportingDrugApplicable = (
  cycleId: number,
  supportingDrugOrder: NewSupportingDrugOrder | null,
): boolean =>
  Boolean(
    supportingDrugOrder &&
      ((supportingDrugOrder.cycleId !== null &&
        supportingDrugOrder.applyTo === 'careplan' &&
        cycleId >= supportingDrugOrder.cycleId) ||
        (supportingDrugOrder.applyTo === 'cycle' && cycleId === supportingDrugOrder.cycleId)),
  );

export const getCycleStatus = (
  cycleInfo: CareplanCycle,
  newSupportingDrugOrder: NewSupportingDrugOrder | null,
): 'printed' | 'approved' | 'unapproved' | '' => {
  const isSupportingDrugApplicable = isNewSupportingDrugApplicable(cycleInfo?.cycleId, newSupportingDrugOrder);
  if (cycleInfo === null || cycleInfo === undefined) return '';
  if (!isSupportingDrugApplicable && cycleInfo.approvedAt && cycleInfo.downloadedAt && cycleInfo.printedAt)
    return 'printed';
  if (!isSupportingDrugApplicable && cycleInfo.approvedAt) return 'approved';
  if (isSupportingDrugApplicable && cycleInfo.approvedAt) return 'unapproved';
  if (cycleInfo.version > 0) return 'unapproved';
  return '';
};

export const isPractitionerAllowedToApprove = (
  userDetails: UserContextState,
  cycleSchedule: CareplanCycle[],
  careplanAdditionalInfo: { startingCycle: number },
): boolean => {
  const isAnyCycleApproved = cycleSchedule.some((cycle) => cycle.approvedAt !== null || cycle.version !== 0);
  // all non-registrar doctors are allowed to  approve
  if (!userDetails.isRegistrar) {
    return true;
  }
  if (careplanAdditionalInfo.startingCycle > 0 || isAnyCycleApproved) {
    return true;
  }
  return false;
};
