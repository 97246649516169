// eslint-disable-next-line no-use-before-define
import React, { Fragment, useEffect, useState, useMemo } from 'react';
import VerificationInput from 'react-verification-input';
import axios from 'axios';
import validate from 'validate.js';
import throttle from 'lodash.throttle';

import { withRouter, RouteComponentProps, Link } from 'react-router-dom';
import { withApollo, WithApolloClient } from '@apollo/client/react/hoc';

import 'url-search-params-polyfill';

import './HomeRegLogin.scss';

import { DeviceUtilities } from 'shared-components/utils';
import { HeaderBar } from 'op-components';

import { FormContent, FormSection, SectionField } from 'shared-components/components/FormComponents';
import { ErrorInfo, Checkbox } from 'shared-components/components/FormFields';
import { LoadingSpinner } from 'shared-components/components';
import { isUs } from 'op-utils';
import { Stack, Tooltip, styled, useTheme } from '@mui/material';
import { MobileOff as MobileOffIcon } from '@mui/icons-material';
import { LoadingButton } from '@mui/lab';

interface SendSMSLoginResults {
  token?: string;
  user: string;
  lastThreeDigits: string;
  lastFourDigits: string;
  informationNoticeAccepted: boolean;
  tokenExpired?: boolean;
}

interface VerifySmsLoginResults {
  patid?: string;
  error?: string;
  details?: string;
  expired?: boolean;
  navigateto?: any;
}

interface LastMobileDigitsResults {
  lastThreeDigits: string;
  lastFourDigits: string;
}

interface StyledProps {
  $cursor: string;
}

const ResendPasscodeLink = styled('span')<StyledProps>`
  color: ${(props) => props.theme.palette.text.primary};
  text-decoration-line: underline;
  cursor: ${({ $cursor }: StyledProps): string => $cursor};
`;

// CONSTANTS
export const DEFAULT_DASHBOARD = '/appointments';
const INITAL_FORM_TITLE = 'Confirm your mobile number';
const FORM_TITLE = 'Verification Passcode';
const FORM_DESCRIPTION = `A text message with your passcode has been sent to: ${isUs() ? '' : '*'}*** *** `;
const INITAL_FORM_DESCRIPTION = `A text message will be sent to your mobile number ending in ${
  isUs() ? '' : '*'
}*** *** `;
const FORM_INSTRUCTION = 'Enter 6 digit code';
const FORM_SEND_CODE_TEXT = 'Send code';
const FORM_RESEND_LINK_TEXT = 'Resend passcode';
const INFO_NOTICE_TEXT =
  'By filling in the following forms, I consent to GenesisCare collecting and using my personal and health information for patient registration purposes in accordance with the';
const INFO_NOTICE_LINK_TEXT = 'Information Notice';
const TIME_BETWEEN_MFA_CODES = '15';

interface Props extends RouteComponentProps<{ token: string }>, WithApolloClient<{}> {}

const US_VALIDATION_RULES = {
  smsPin: {
    presence: {
      allowEmpty: false,
      message: 'Please enter a valid code or resend a new passcode',
    },
    length: {
      is: 6,
      message: 'Please enter a valid code or resend a new passcode',
    },
  },
};

const AU_VALIDATION_RULES = {
  infoNoticeAccepted: {
    presence: {
      allowEmpty: false,
      message: 'You must agree with the information notice',
    },
    inclusion: {
      within: [true],
      message: 'You must agree with the information notice',
    },
  },
  ...US_VALIDATION_RULES,
};

const VALIDATION_RULES: any = !isUs() ? AU_VALIDATION_RULES : US_VALIDATION_RULES;

const HomeRegLogin = (props: Props): JSX.Element => {
  const [loadingPage, setLoadingPage] = useState(true);
  const [lastMobileDigits, setLastMobileDigits] = useState<any>('');
  const [debugToken, setDebugToken] = useState<any>('');
  const [verifyLoading, setVerifyLoading] = useState(false);
  const [, setSendTokenLoading] = useState(false);
  const [infoNoticeAccepted, setInfoNoticeAccepted] = useState(true);
  const [tokenValidationError, setTokenValidationError] = useState(false);
  const [smsPin, setSmsPin] = useState('');
  const [viewedFields, setViewedFields] = useState(new Set());
  const [hideInfoNotice, setHideInfoNotice] = useState(false);
  const [hasSentInitialSms, setSentInitialSms] = useState(false);
  const [hasError, setHasError] = useState<any>(null);
  const [beginCountdown, setBeginCountdown] = useState(false);
  const [enableResendPasscode, setEnableResendPasscode] = useState(false);
  const [minutesUntilEnabled, setMinutesUntilEnabled] = useState<number>(20);
  const [intervalId, setIntervalId] = useState<NodeJS.Timeout>();
  const inputCode = React.useRef(null);
  const theme = useTheme();

  /**
   * Function that will render the token onto the screen for development purposes. It will render the passed in token,
   * or the token that has been passed into the props.
   * @returns {JSX.Element | null} The token will only be rendered if running as a development environment. Otherwise,
   * will not display.
   */
  const renderToken = (token?: string): JSX.Element | null => {
    return token ? <div id="debug-token">{token} shown because debug is enabled</div> : null;
  };

  const renderInfoNoticeLabel = (): JSX.Element => {
    return hideInfoNotice ? (
      <Fragment />
    ) : (
      <Fragment>
        <p className="info-notice-text">
          {INFO_NOTICE_TEXT}&nbsp;
          <Link to="/homeRegistration/informationNotice" target="_blank" className="info-notice-link">
            {INFO_NOTICE_LINK_TEXT}
          </Link>
        </p>
      </Fragment>
    );
  };

  const getAllFieldsValid = (): boolean => {
    const options = { fullMessages: false };
    const validationFields = { smsPin, infoNoticeAccepted };
    const validationObject = validate(validationFields, VALIDATION_RULES, options);
    return validationObject === undefined;
  };

  useEffect(() => {
    getLastThreeDigits();

    // On first load get values from session storage
    setMinutesUntilEnabled(
      parseInt(
        sessionStorage.getItem('minutesUntilEnabled') !== null
          ? sessionStorage.getItem('minutesUntilEnabled')!
          : TIME_BETWEEN_MFA_CODES,
      ),
    );
    if (sessionStorage.getItem('hasSentInitialSms') === 'true') {
      setSentInitialSms(true);
      setBeginCountdown(true);
    }
  }, []);

  useEffect(() => {
    // Update session storage any time state changes
    sessionStorage.setItem('minutesUntilEnabled', String(minutesUntilEnabled));
    sessionStorage.setItem('hasSentInitialSms', String(hasSentInitialSms));

    // Clear countdown if timer has finished
    if (enableResendPasscode) {
      clearInterval(intervalId);
      setMinutesUntilEnabled(parseInt(TIME_BETWEEN_MFA_CODES));
    }

    // Begin countdown could be triggered from sending initial code or on page refresh
    if (beginCountdown) {
      countdownToResendPasscode();
      setBeginCountdown(false);
    }
  }, [minutesUntilEnabled, hasSentInitialSms, enableResendPasscode, beginCountdown]);

  const verifySmsCode = (smscode: string): void => {
    const { match, history } = props;

    setVerifyLoading(true);
    // Send token and sms code
    const { token } = match.params;

    const smsVerifyData = {
      token: token,
      smscode: smscode,
    };

    axios
      .post('/server/auth/verify_login_code', smsVerifyData, {
        headers: {
          'Content-Type': 'application/json',
        },
      })
      .then((response): void => {
        // Redirection
        const results: VerifySmsLoginResults = response.data;

        if (results.expired) {
          setVerifyLoading(false);
          setTokenValidationError(true);
          history.push('/homeRegistration/linkExpired');
        }
        if (results.error) {
          setVerifyLoading(false);
          setTokenValidationError(true);
        } else if (results && results.patid) {
          history.push(`/patient/${results.patid}/home`);
        } else {
          setVerifyLoading(false);
        }
      })
      .catch((): void => {
        setVerifyLoading(false);
        setTokenValidationError(true);
      });
  };

  const getAuthTokenFromUrl = () => {
    const { match } = props;
    const { token } = match.params;
    return token;
  };

  const countdownToResendPasscode = () => {
    // Disable resend passcode
    setEnableResendPasscode(false);

    // Re-enable resend passcode after X minutes
    setTimeout(() => setEnableResendPasscode(true), minutesUntilEnabled * 60000);

    // Set interval for tooltip countdown
    const id = setInterval(() => {
      setMinutesUntilEnabled((minutesUntilEnabled) => minutesUntilEnabled - 1);
    }, 60000);
    setIntervalId(id);
  };

  const handleResendPasscode = () => {
    if (enableResendPasscode) {
      countdownToResendPasscode();
      throttledGetSmsLoginCode();
    }
  };

  // Throttle 'Resend passcode' function so it can't be continuously called over and over
  const throttledGetSmsLoginCode = useMemo(() => throttle(() => getSmsLoginCode(), 60000, { leading: true }), []);

  const getSmsLoginCode = () => {
    const { history } = props;
    const data = {
      token: getAuthTokenFromUrl(),
    };

    axios
      .post('/server/auth/send_login', data, {
        headers: {
          'Content-Type': 'application/json',
        },
      })
      .then((response): void => {
        setSentInitialSms(true);
        setSendTokenLoading(true);
        const results: SendSMSLoginResults = response.data;
        let hideInfoNotice = false;
        let infoNoticeAccept = infoNoticeAccepted;

        if (results.tokenExpired) {
          setSendTokenLoading(false);
          setLoadingPage(false);
          history.push('/homeRegistration/linkExpired');
        }

        hideInfoNotice = results.informationNoticeAccepted;
        infoNoticeAccept = results.informationNoticeAccepted;

        if (results && results.token && results.token !== '') setDebugToken(results.token);

        setSendTokenLoading(false);
        setLastMobileDigits(isUs() ? results.lastFourDigits : results.lastThreeDigits);
        setInfoNoticeAccepted(infoNoticeAccept);
        setLoadingPage(false);
        setHideInfoNotice(hideInfoNotice);
      })
      .catch((): void => {
        setSendTokenLoading(false);
        setLoadingPage(false);
      });
  };

  const isTokenInputValid = (validationResults: any): boolean => {
    return tokenValidationError ? false : !(validationResults && validationResults.smsPin);
  };

  const errorState = (): JSX.Element => {
    const navigatorContent = `App version: ${window.navigator.appVersion}%0D%0ALanguage: ${window.navigator.language}%0D%0APlatform: ${window.navigator.platform} ${window.navigator.appName}%0D%0ACookies: ${window.navigator.cookieEnabled}%0D%0A`;
    const errorDetails = `Status: ${hasError.request.status} ${hasError.request.statusText}%0D%0AResponse URL: ${hasError.request.responseURL}%0D%0ACurrent URL: ${window.location.href}`;
    const mailTo = 'patientportal@genesiscare.com';
    const subjectLine = `Home Registration URL failure: ${new Date()}`;
    const body = `Hello%0D%0A%0D%0AThis email is in regards to a ${subjectLine}%0D%0A%0D%0AMy name is: %0D%0A%0D%0AI attend the clinic: %0D%0A%0D%0AMy phone number is: %0D%0A%0D%0A%0D%0A%0D%0A%0D%0A%0D%0A--------------%0D%0AThe following is the error message used internally to help determine the issue%0D%0A%0D%0A${errorDetails}%0D%0A%0D%0A${navigatorContent}`;
    return (
      <div className="auth-container home-reg-container">
        <div className="auth-container-inner">
          <FormContent>
            <div className="title-container">
              <div className="title">It appears that there is an issue with your registration link</div>
              <div className="description">
                Please open your email and click on the link again. If you continue to see this message, please{' '}
                <a href={`mailto:${mailTo}?subject=${subjectLine}&body=${body}`}>click here</a> to send an email for
                further support.
              </div>
            </div>
          </FormContent>
        </div>
      </div>
    );
  };

  const styledProps = {
    $cursor: enableResendPasscode ? 'pointer' : 'default',
  };

  const contentsRender = (validationResults: any): JSX.Element => {
    if (hasError) return errorState();
    return (
      <div className="auth-container home-reg-container">
        <div className="auth-container-inner">
          <FormContent>
            <div className="title-container">
              <div className="title">{hasSentInitialSms ? FORM_TITLE : INITAL_FORM_TITLE}</div>
            </div>
            <FormSection>
              {lastMobileDigits && (
                <div className="description">
                  {`${hasSentInitialSms ? FORM_DESCRIPTION : INITAL_FORM_DESCRIPTION}${lastMobileDigits}`}
                </div>
              )}
              {hasSentInitialSms && (
                <>
                  <SectionField
                    htmlFor="pin"
                    title={FORM_INSTRUCTION}
                    invalidInput={!isTokenInputValid(validationResults)}>
                    <VerificationInput
                      containerProps={{ className: 'pin-input' }}
                      validChars="0-9"
                      length={6}
                      value={smsPin}
                      onChange={(value: any): void => setSmsPin(value)}
                      ref={inputCode}
                    />
                  </SectionField>
                  {tokenValidationError && <ErrorInfo errors={[VALIDATION_RULES?.smsPin.presence.message]} />}
                  {!tokenValidationError && (
                    <ErrorInfo
                      errors={validationResults && validationResults.smsPin ? validationResults.smsPin : undefined}
                    />
                  )}
                </>
              )}

              {!hideInfoNotice && hasSentInitialSms && !isUs() && (
                <div id="remember-checkbox">
                  <Checkbox
                    inputLabel={renderInfoNoticeLabel()}
                    inputName="dont-ask-again"
                    isChecked={infoNoticeAccepted}
                    errors={
                      validationResults && validationResults.infoNoticeAccepted
                        ? validationResults.infoNoticeAccepted
                        : undefined
                    }
                    onChange={(value: any): void => {
                      const viewedFieldsUpdate = [...viewedFields];
                      viewedFieldsUpdate.push('infoNoticeAccepted');
                      setInfoNoticeAccepted(value);
                      setViewedFields(new Set([...viewedFieldsUpdate]));
                    }}
                  />
                </div>
              )}
              <div className="flex-horizontal-center">
                {hasSentInitialSms && (
                  <LoadingButton
                    onClick={(): void => {
                      const isValid = getAllFieldsValid();
                      if (isValid) {
                        verifySmsCode(smsPin);
                      } else {
                        const allFields = new Set(['infoNoticeAccepted', 'smsPin']);
                        setViewedFields(allFields);
                      }
                    }}
                    variant="contained"
                    name="mfa-verify"
                    loading={verifyLoading}>
                    Verify
                  </LoadingButton>
                )}
                {!hasSentInitialSms && (
                  <LoadingButton
                    onClick={(): void => {
                      getSmsLoginCode();
                      setBeginCountdown(true);
                    }}
                    variant="contained"
                    name="mfa-send-initial"
                    loading={verifyLoading}>
                    Send code
                  </LoadingButton>
                )}
              </div>
              {hasSentInitialSms && (
                <>
                  <Stack justifyContent="center">
                    <Tooltip
                      arrow
                      componentsProps={{
                        tooltip: { sx: { bgcolor: 'black', minWidth: '300px', fontSize: '0.9rem' } },
                        arrow: { sx: { color: 'black' } },
                      }}
                      data-test-id="resend-token-link-tooltip"
                      title={
                        !enableResendPasscode &&
                        `Please try again in ${minutesUntilEnabled} minute${minutesUntilEnabled > 1 && 's'}`
                      }>
                      <ResendPasscodeLink
                        id="resend-token-link"
                        onClick={(): void => {
                          handleResendPasscode();
                        }}
                        style={{ textAlign: 'center' }}
                        {...styledProps}>
                        {FORM_RESEND_LINK_TEXT}
                      </ResendPasscodeLink>
                    </Tooltip>
                  </Stack>
                  {!hideInfoNotice && !isUs() && (
                    <div className="consent-agreement">* You must agree to the consent to continue</div>
                  )}
                  {renderToken(debugToken)}
                </>
              )}
            </FormSection>
          </FormContent>
        </div>
      </div>
    );
  };

  const getLastThreeDigits = () => {
    const data = {
      token: getAuthTokenFromUrl(),
    };
    axios
      .post('/server/auth/last_mobile_digits', data, {
        headers: {
          'Content-Type': 'application/json',
        },
      })
      .then((response) => {
        if (response.status !== 200) {
          setHasError(response);
          setLoadingPage(false);
        } else {
          const results: LastMobileDigitsResults = response.data;
          setLastMobileDigits(isUs() ? results.lastFourDigits : results.lastThreeDigits);
          setLoadingPage(false);
        }
      })
      .catch((error) => {
        setHasError(error);
        setLoadingPage(false);
      });
  };

  // If loading show loading screen
  if (loadingPage) return <LoadingSpinner />;

  if (DeviceUtilities.isMobileDevice())
    return (
      <div className="mobile ">
        <HeaderBar defaultHeader />
        <div className="mobile-off-container">
          <MobileOffIcon htmlColor={theme.palette.grey[500]} className="mobile-off" />
        </div>
        <h1>Mobile access unavailable</h1>
        <p>Please use a computer or tablet to complete the registration.</p>
      </div>
    );

  const specificRules: { [key: string]: object } = {};
  // eslint-disable-next-line
  if (!isUs() && viewedFields.has('infoNoticeAccepted'))
    specificRules['infoNoticeAccepted'] = VALIDATION_RULES?.infoNoticeAccepted;
  if (viewedFields.has('smsPin')) specificRules['smsPin'] = VALIDATION_RULES?.smsPin;
  const options = { fullMessages: false };

  const validationObject: any = {
    infoNoticeAccepted: infoNoticeAccepted,
    smsPin: smsPin,
  };

  const validationResults = validate(validationObject, specificRules, options);

  return contentsRender(validationResults);
};
const apolloComponent = withApollo<Props>(HomeRegLogin);
export default withRouter(apolloComponent);
