const MockData = [
  {
    fractions: 22,
    site: 'Red Hills',
    physician: 'Dr Wong',
    date: '2024-10-25',
    diagnosis: 'skin',
    fraction_dose: 2.1,
    adherence: true,
    technique: 'Brachytherapy',
    linac: 'Machine 1',
    sim_to_treat: 3,
    priority: 'Emergency',
    referrer: 'Dr. Alex Reynolds',
    diagnosis_code: 'C43.9',
  },
  {
    fractions: 13,
    site: 'Red Hills',
    physician: 'Dr Smith',
    date: '2024-04-09',
    diagnosis: 'metastasis',
    fraction_dose: 2.2,
    adherence: true,
    technique: 'Brachytherapy',
    linac: 'Machine 2',
    sim_to_treat: 9,
    priority: 'Urgent',
    referrer: 'Dr. Alex Reynolds',
    diagnosis_code: 'C34.90',
  },
  {
    fractions: 23,
    site: 'Red Hills',
    physician: 'Dr Smith',
    date: '2024-05-27',
    diagnosis: 'prostate',
    fraction_dose: 1.9,
    adherence: true,
    technique: 'VMAT',
    linac: 'Machine 1',
    sim_to_treat: 3,
    priority: 'Urgent',
    referrer: 'Dr. Alex Reynolds',
    diagnosis_code: 'C71.9',
  },
  {
    fractions: 11,
    site: 'Red Hills',
    physician: 'Dr Wong',
    date: '2024-07-03',
    diagnosis: 'prostate',
    fraction_dose: 1.8,
    adherence: true,
    technique: 'Conformal',
    linac: 'Machine 1',
    sim_to_treat: 2,
    priority: 'Standard',
    referrer: 'Dr. Sophie Greenberg',
    diagnosis_code: 'C34.90',
  },
  {
    fractions: 22,
    site: 'Lakeside',
    physician: 'Dr Wong',
    date: '2024-05-28',
    diagnosis: 'skin',
    fraction_dose: 2.1,
    adherence: true,
    technique: 'IMRT',
    linac: 'Machine 2',
    sim_to_treat: 1,
    priority: 'Standard',
    referrer: 'Dr. Victor Hernandes',
    diagnosis_code: 'C34.90',
  },
  {
    fractions: 18,
    site: 'Red Hills',
    physician: 'Dr Smith',
    date: '2024-01-12',
    diagnosis: 'prostate',
    fraction_dose: 2.1,
    adherence: true,
    technique: 'Electrons',
    linac: 'Machine 4',
    sim_to_treat: 10,
    priority: 'Standard',
    referrer: 'Dr. Alex Reynolds',
    diagnosis_code: 'C71.9',
  },
  {
    fractions: 21,
    site: 'Campsie',
    physician: 'Dr Wong',
    date: '2024-06-02',
    diagnosis: 'breast',
    fraction_dose: 1.6,
    adherence: true,
    technique: 'VMAT',
    linac: 'Machine 1',
    sim_to_treat: 10,
    priority: 'Urgent',
    referrer: 'Dr. Aisha Patel',
    diagnosis_code: 'C43.9',
  },
  {
    fractions: 22,
    site: 'Red Hills',
    physician: 'Dr Lopez',
    date: '2024-07-15',
    diagnosis: 'metastasis',
    fraction_dose: 2.7,
    adherence: true,
    technique: 'Stereotactic',
    linac: 'Machine 3',
    sim_to_treat: 2,
    priority: 'Emergency',
    referrer: 'Dr. Victor Hernandes',
    diagnosis_code: 'C43.9',
  },
  {
    fractions: 11,
    site: 'Red Hills',
    physician: 'Dr Smith',
    date: '2024-06-13',
    diagnosis: 'breast',
    fraction_dose: 1.4,
    adherence: true,
    technique: 'VMAT',
    linac: 'Machine 1',
    sim_to_treat: 2,
    priority: 'Emergency',
    referrer: 'Dr. Alex Reynolds',
    diagnosis_code: 'C20',
  },
  {
    fractions: 24,
    site: 'Lakeside',
    physician: 'Dr Lopez',
    date: '2024-07-10',
    diagnosis: 'skin',
    fraction_dose: 2.7,
    adherence: true,
    technique: 'Conformal',
    linac: 'Machine 2',
    sim_to_treat: 10,
    priority: 'Emergency',
    referrer: 'Dr. Aisha Patel',
    diagnosis_code: 'C61',
  },
  {
    fractions: 22,
    site: 'Red Hills',
    physician: 'Dr Smith',
    date: '2024-10-31',
    diagnosis: 'neuro',
    fraction_dose: 2.8,
    adherence: true,
    technique: 'Electrons',
    linac: 'Machine 3',
    sim_to_treat: 2,
    priority: 'Urgent',
    referrer: 'Dr. Julian Foster',
    diagnosis_code: 'C71.9',
  },
  {
    fractions: 21,
    site: 'Lakeside',
    physician: 'Dr Adams',
    date: '2024-04-25',
    diagnosis: 'metastasis',
    fraction_dose: 3.0,
    adherence: true,
    technique: 'Conformal',
    linac: 'Machine 4',
    sim_to_treat: 3,
    priority: 'Emergency',
    referrer: 'Dr. Aisha Patel',
    diagnosis_code: 'C61',
  },
  {
    fractions: 10,
    site: 'Red Hills',
    physician: 'Dr Adams',
    date: '2024-10-04',
    diagnosis: 'colorectal',
    fraction_dose: 2.9,
    adherence: true,
    technique: 'Electrons',
    linac: 'Machine 3',
    sim_to_treat: 4,
    priority: 'Emergency',
    referrer: 'Dr. Alex Reynolds',
    diagnosis_code: 'C61',
  },
  {
    fractions: 18,
    site: 'Campsie',
    physician: 'Dr Smith',
    date: '2024-01-20',
    diagnosis: 'metastasis',
    fraction_dose: 1.2,
    adherence: true,
    technique: 'VMAT',
    linac: 'Machine 1',
    sim_to_treat: 6,
    priority: 'Urgent',
    referrer: 'Dr. Clara Thompson',
    diagnosis_code: 'C20',
  },
  {
    fractions: 21,
    site: 'Rosebush',
    physician: 'Dr Smith',
    date: '2024-01-15',
    diagnosis: 'metastasis',
    fraction_dose: 3.1,
    adherence: true,
    technique: 'Electrons',
    linac: 'Machine 2',
    sim_to_treat: 6,
    priority: 'Standard',
    referrer: 'Dr. Michael Stern',
    diagnosis_code: 'C43.9',
  },
  {
    fractions: 22,
    site: 'Campsie',
    physician: 'Dr Lopez',
    date: '2024-01-22',
    diagnosis: 'colorectal',
    fraction_dose: 2.8,
    adherence: true,
    technique: 'IMRT',
    linac: 'Machine 2',
    sim_to_treat: 9,
    priority: 'Urgent',
    referrer: 'Dr. Aisha Patel',
    diagnosis_code: 'C20',
  },
  {
    fractions: 16,
    site: 'Red Hills',
    physician: 'Dr Adams',
    date: '2024-07-06',
    diagnosis: 'prostate',
    fraction_dose: 3.2,
    adherence: true,
    technique: 'Electrons',
    linac: 'Machine 3',
    sim_to_treat: 8,
    priority: 'Emergency',
    referrer: 'Dr. Clara Thompson',
    diagnosis_code: 'C71.9',
  },
  {
    fractions: 17,
    site: 'Campsie',
    physician: 'Dr Lopez',
    date: '2024-04-04',
    diagnosis: 'colorectal',
    fraction_dose: 2.9,
    adherence: true,
    technique: 'Stereotactic',
    linac: 'Machine 2',
    sim_to_treat: 4,
    priority: 'Urgent',
    referrer: 'Dr. Julian Foster',
    diagnosis_code: 'C79.81',
  },
  {
    fractions: 14,
    site: 'Red Hills',
    physician: 'Dr Adams',
    date: '2024-03-18',
    diagnosis: 'neuro',
    fraction_dose: 3.3,
    adherence: true,
    technique: 'VMAT',
    linac: 'Machine 1',
    sim_to_treat: 2,
    priority: 'Emergency',
    referrer: 'Dr. Julian Foster',
    diagnosis_code: 'C71.9',
  },
  {
    fractions: 19,
    site: 'Rosebush',
    physician: 'Dr Adams',
    date: '2024-10-04',
    diagnosis: 'prostate',
    fraction_dose: 3.3,
    adherence: true,
    technique: 'Electrons',
    linac: 'Machine 4',
    sim_to_treat: 9,
    priority: 'Urgent',
    referrer: 'Dr. Alex Reynolds',
    diagnosis_code: 'C79.81',
  },
  {
    fractions: 21,
    site: 'Lakeside',
    physician: 'Dr Adams',
    date: '2024-10-24',
    diagnosis: 'neuro',
    fraction_dose: 1.2,
    adherence: true,
    technique: 'IMRT',
    linac: 'Machine 3',
    sim_to_treat: 10,
    priority: 'Standard',
    referrer: 'Dr. Jonathan Blake',
    diagnosis_code: 'C20',
  },
  {
    fractions: 23,
    site: 'Rosebush',
    physician: 'Dr Lopez',
    date: '2024-09-01',
    diagnosis: 'Unspecified',
    fraction_dose: 1.4,
    adherence: true,
    technique: 'Unspecified',
    linac: 'Machine 1',
    sim_to_treat: 7,
    priority: 'Standard',
    referrer: 'Dr. Julian Foster',
    diagnosis_code: 'C79.81',
  },
  {
    fractions: 12,
    site: 'Lakeside',
    physician: 'Dr Wong',
    date: '2024-01-12',
    diagnosis: 'Unspecified',
    fraction_dose: 1.8,
    adherence: true,
    technique: 'Unspecified',
    linac: 'Machine 4',
    sim_to_treat: 1,
    priority: 'Urgent',
    referrer: 'Dr. Alex Reynolds',
    diagnosis_code: 'C71.9',
  },
  {
    fractions: 24,
    site: 'Rosebush',
    physician: 'Dr Wong',
    date: '2024-10-16',
    diagnosis: 'Unspecified',
    fraction_dose: 2.5,
    adherence: true,
    technique: 'Unspecified',
    linac: 'Machine 1',
    sim_to_treat: 1,
    priority: 'Standard',
    referrer: 'Dr. Aisha Patel',
    diagnosis_code: 'C61',
  },
  {
    fractions: 16,
    site: 'Red Hills',
    physician: 'Dr Smith',
    date: '2024-03-30',
    diagnosis: 'Unspecified',
    fraction_dose: 2.4,
    adherence: false,
    technique: 'Unspecified',
    linac: 'Machine 4',
    sim_to_treat: 8,
    priority: 'Urgent',
    referrer: 'Dr. Sophie Greenberg',
    diagnosis_code: 'C34.90',
  },
  {
    fractions: 16,
    site: 'Lakeside',
    physician: 'Dr Adams',
    date: '2024-09-25',
    diagnosis: 'Unspecified',
    fraction_dose: 2.9,
    adherence: true,
    technique: 'Unspecified',
    linac: 'Machine 1',
    sim_to_treat: 6,
    priority: 'Urgent',
    referrer: 'Dr. Rachel Nguyen',
    diagnosis_code: 'C20',
  },
  {
    fractions: 18,
    site: 'Rosebush',
    physician: 'Dr Adams',
    date: '2024-08-19',
    diagnosis: 'Unspecified',
    fraction_dose: 3.4,
    adherence: false,
    technique: 'Unspecified',
    linac: 'Machine 1',
    sim_to_treat: 8,
    priority: 'Standard',
    referrer: 'Dr. Jonathan Blake',
    diagnosis_code: 'C61',
  },
  {
    fractions: 21,
    site: 'Red Hills',
    physician: 'Dr Wong',
    date: '2024-02-12',
    diagnosis: 'Unspecified',
    fraction_dose: 2.7,
    adherence: true,
    technique: 'Unspecified',
    linac: 'Machine 1',
    sim_to_treat: 1,
    priority: 'Urgent',
    referrer: 'Dr. Sophie Greenberg',
    diagnosis_code: 'C20',
  },
  {
    fractions: 17,
    site: 'Red Hills',
    physician: 'Dr Smith',
    date: '2024-10-06',
    diagnosis: 'Unspecified',
    fraction_dose: 3.3,
    adherence: true,
    technique: 'Unspecified',
    linac: 'Machine 2',
    sim_to_treat: 1,
    priority: 'Standard',
    referrer: 'Dr. Victor Hernandes',
    diagnosis_code: 'C20',
  },
  {
    fractions: 19,
    site: 'Red Hills',
    physician: 'Dr Lopez',
    date: '2024-06-20',
    diagnosis: 'Unspecified',
    fraction_dose: 2.6,
    adherence: true,
    technique: 'Unspecified',
    linac: 'Machine 1',
    sim_to_treat: 7,
    priority: 'Urgent',
    referrer: 'Dr. Aisha Patel',
    diagnosis_code: 'Unspecified',
  },
  {
    fractions: 18,
    site: 'Rosebush',
    physician: 'Dr Lopez',
    date: '2024-05-18',
    diagnosis: 'prostate',
    fraction_dose: 2.0,
    adherence: true,
    technique: 'IMRT',
    linac: 'Machine 4',
    sim_to_treat: 10,
    priority: 'Urgent',
    referrer: 'Dr. Michael Stern',
    diagnosis_code: 'C79.81',
  },
  {
    fractions: 24,
    site: 'Rosebush',
    physician: 'Dr Smith',
    date: '2024-04-07',
    diagnosis: 'prostate',
    fraction_dose: 2.3,
    adherence: true,
    technique: 'Brachytherapy',
    linac: 'Machine 4',
    sim_to_treat: 6,
    priority: 'Standard',
    referrer: 'Dr. Rachel Nguyen',
    diagnosis_code: 'C61',
  },
  {
    fractions: 11,
    site: 'Red Hills',
    physician: 'Dr Lopez',
    date: '2024-02-05',
    diagnosis: 'colorectal',
    fraction_dose: 1.4,
    adherence: true,
    technique: 'Electrons',
    linac: 'Machine 2',
    sim_to_treat: 6,
    priority: 'Urgent',
    referrer: 'Dr. Alex Reynolds',
    diagnosis_code: 'C79.81',
  },
  {
    fractions: 24,
    site: 'Rosebush',
    physician: 'Dr Wong',
    date: '2024-01-14',
    diagnosis: 'neuro',
    fraction_dose: 2.0,
    adherence: true,
    technique: 'Electrons',
    linac: 'Machine 4',
    sim_to_treat: 1,
    priority: 'Emergency',
    referrer: 'Dr. Rachel Nguyen',
    diagnosis_code: 'C71.9',
  },
  {
    fractions: 13,
    site: 'Red Hills',
    physician: 'Dr Lopez',
    date: '2024-03-23',
    diagnosis: 'breast',
    fraction_dose: 3.0,
    adherence: true,
    technique: 'Brachytherapy',
    linac: 'Machine 1',
    sim_to_treat: 2,
    priority: 'Urgent',
    referrer: 'Dr. Michael Stern',
    diagnosis_code: 'C20',
  },
  {
    fractions: 16,
    site: 'Red Hills',
    physician: 'Dr Lopez',
    date: '2024-05-29',
    diagnosis: 'metastasis',
    fraction_dose: 1.7,
    adherence: true,
    technique: 'Brachytherapy',
    linac: 'Machine 3',
    sim_to_treat: 5,
    priority: 'Standard',
    referrer: 'Dr. Rachel Nguyen',
    diagnosis_code: 'C20',
  },
  {
    fractions: 21,
    site: 'Rosebush',
    physician: 'Dr Wong',
    date: '2024-02-14',
    diagnosis: 'breast',
    fraction_dose: 2.6,
    adherence: true,
    technique: 'Electrons',
    linac: 'Machine 4',
    sim_to_treat: 9,
    priority: 'Emergency',
    referrer: 'Dr. Jonathan Blake',
    diagnosis_code: 'C79.81',
  },
  {
    fractions: 14,
    site: 'Rosebush',
    physician: 'Dr Smith',
    date: '2024-05-17',
    diagnosis: 'metastasis',
    fraction_dose: 2.8,
    adherence: true,
    technique: 'Electrons',
    linac: 'Machine 2',
    sim_to_treat: 2,
    priority: 'Emergency',
    referrer: 'Dr. Michael Stern',
    diagnosis_code: 'C71.9',
  },
  {
    fractions: 10,
    site: 'Campsie',
    physician: 'Dr Adams',
    date: '2024-02-12',
    diagnosis: 'skin',
    fraction_dose: 1.6,
    adherence: true,
    technique: 'Brachytherapy',
    linac: 'Machine 3',
    sim_to_treat: 7,
    priority: 'Emergency',
    referrer: 'Dr. Aisha Patel',
    diagnosis_code: 'C79.81',
  },
  {
    fractions: 21,
    site: 'Red Hills',
    physician: 'Dr Lopez',
    date: '2024-05-01',
    diagnosis: 'neuro',
    fraction_dose: 2.4,
    adherence: true,
    technique: 'Conformal',
    linac: 'Machine 4',
    sim_to_treat: 8,
    priority: 'Urgent',
    referrer: 'Dr. Julian Foster',
    diagnosis_code: 'C34.90',
  },
  {
    fractions: 15,
    site: 'Lakeside',
    physician: 'Dr Adams',
    date: '2024-05-15',
    diagnosis: 'neuro',
    fraction_dose: 2.3,
    adherence: true,
    technique: 'IMRT',
    linac: 'Machine 3',
    sim_to_treat: 3,
    priority: 'Urgent',
    referrer: 'Dr. Jonathan Blake',
    diagnosis_code: 'C61',
  },
  {
    fractions: 17,
    site: 'Campsie',
    physician: 'Dr Adams',
    date: '2024-01-10',
    diagnosis: 'colorectal',
    fraction_dose: 1.5,
    adherence: true,
    technique: 'Conformal',
    linac: 'Machine 2',
    sim_to_treat: 6,
    priority: 'Emergency',
    referrer: 'Dr. Michael Stern',
    diagnosis_code: 'C79.81',
  },
  {
    fractions: 23,
    site: 'Campsie',
    physician: 'Dr Adams',
    date: '2024-09-14',
    diagnosis: 'skin',
    fraction_dose: 1.5,
    adherence: true,
    technique: 'Conformal',
    linac: 'Machine 4',
    sim_to_treat: 8,
    priority: 'Emergency',
    referrer: 'Dr. Aisha Patel',
    diagnosis_code: 'C71.9',
  },
  {
    fractions: 17,
    site: 'Rosebush',
    physician: 'Dr Wong',
    date: '2024-06-30',
    diagnosis: 'metastasis',
    fraction_dose: 2.1,
    adherence: true,
    technique: 'Stereotactic',
    linac: 'Machine 3',
    sim_to_treat: 6,
    priority: 'Standard',
    referrer: 'Dr. Clara Thompson',
    diagnosis_code: 'C79.81',
  },
  {
    fractions: 18,
    site: 'Red Hills',
    physician: 'Dr Lopez',
    date: '2024-07-07',
    diagnosis: 'prostate',
    fraction_dose: 1.3,
    adherence: true,
    technique: 'Stereotactic',
    linac: 'Machine 4',
    sim_to_treat: 7,
    priority: 'Urgent',
    referrer: 'Dr. Julian Foster',
    diagnosis_code: 'C71.9',
  },
  {
    fractions: 17,
    site: 'Red Hills',
    physician: 'Dr Wong',
    date: '2024-04-14',
    diagnosis: 'metastasis',
    fraction_dose: 2.3,
    adherence: true,
    technique: 'Brachytherapy',
    linac: 'Machine 2',
    sim_to_treat: 8,
    priority: 'Emergency',
    referrer: 'Dr. Julian Foster',
    diagnosis_code: 'C43.9',
  },
  {
    fractions: 25,
    site: 'Campsie',
    physician: 'Dr Adams',
    date: '2024-06-16',
    diagnosis: 'neuro',
    fraction_dose: 1.3,
    adherence: true,
    technique: 'Conformal',
    linac: 'Machine 4',
    sim_to_treat: 5,
    priority: 'Standard',
    referrer: 'Dr. Clara Thompson',
    diagnosis_code: 'C34.90',
  },
  {
    fractions: 19,
    site: 'Lakeside',
    physician: 'Dr Adams',
    date: '2024-09-19',
    diagnosis: 'colorectal',
    fraction_dose: 1.3,
    adherence: true,
    technique: 'IMRT',
    linac: 'Machine 3',
    sim_to_treat: 2,
    priority: 'Emergency',
    referrer: 'Dr. Sophie Greenberg',
    diagnosis_code: 'C20',
  },
  {
    fractions: 22,
    site: 'Lakeside',
    physician: 'Dr Wong',
    date: '2024-01-07',
    diagnosis: 'skin',
    fraction_dose: 1.8,
    adherence: true,
    technique: 'Brachytherapy',
    linac: 'Machine 2',
    sim_to_treat: 5,
    priority: 'Standard',
    referrer: 'Dr. Julian Foster',
    diagnosis_code: 'C61',
  },
  {
    fractions: 21,
    site: 'Campsie',
    physician: 'Dr Wong',
    date: '2024-09-15',
    diagnosis: 'skin',
    fraction_dose: 2.3,
    adherence: true,
    technique: 'IMRT',
    linac: 'Machine 2',
    sim_to_treat: 8,
    priority: 'Emergency',
    referrer: 'Dr. Julian Foster',
    diagnosis_code: 'C34.90',
  },
  {
    fractions: 25,
    site: 'Lakeside',
    physician: 'Dr Wong',
    date: '2024-09-13',
    diagnosis: 'colorectal',
    fraction_dose: 2.8,
    adherence: false,
    technique: 'Stereotactic',
    linac: 'Machine 4',
    sim_to_treat: 3,
    priority: 'Standard',
    referrer: 'Dr. Clara Thompson',
    diagnosis_code: 'C79.81',
  },
  {
    fractions: 19,
    site: 'Campsie',
    physician: 'Dr Lopez',
    date: '2024-02-15',
    diagnosis: 'colorectal',
    fraction_dose: 1.9,
    adherence: true,
    technique: 'Brachytherapy',
    linac: 'Machine 3',
    sim_to_treat: 8,
    priority: 'Emergency',
    referrer: 'Dr. Jonathan Blake',
    diagnosis_code: 'C34.90',
  },
  {
    fractions: 16,
    site: 'Red Hills',
    physician: 'Dr Wong',
    date: '2024-01-22',
    diagnosis: 'metastasis',
    fraction_dose: 2.6,
    adherence: true,
    technique: 'Brachytherapy',
    linac: 'Machine 1',
    sim_to_treat: 2,
    priority: 'Emergency',
    referrer: 'Dr. Rachel Nguyen',
    diagnosis_code: 'C20',
  },
  {
    fractions: 20,
    site: 'Lakeside',
    physician: 'Dr Wong',
    date: '2024-01-24',
    diagnosis: 'skin',
    fraction_dose: 1.5,
    adherence: true,
    technique: 'Stereotactic',
    linac: 'Machine 1',
    sim_to_treat: 8,
    priority: 'Standard',
    referrer: 'Dr. Victor Hernandes',
    diagnosis_code: 'C79.81',
  },
  {
    fractions: 25,
    site: 'Lakeside',
    physician: 'Dr Lopez',
    date: '2024-07-23',
    diagnosis: 'metastasis',
    fraction_dose: 1.9,
    adherence: true,
    technique: 'Brachytherapy',
    linac: 'Machine 2',
    sim_to_treat: 5,
    priority: 'Urgent',
    referrer: 'Dr. Emily Hartman',
    diagnosis_code: 'C79.81',
  },
  {
    fractions: 12,
    site: 'Red Hills',
    physician: 'Dr Adams',
    date: '2024-03-18',
    diagnosis: 'breast',
    fraction_dose: 2.5,
    adherence: true,
    technique: 'Brachytherapy',
    linac: 'Machine 4',
    sim_to_treat: 10,
    priority: 'Standard',
    referrer: 'Dr. Michael Stern',
    diagnosis_code: 'C61',
  },
  {
    fractions: 12,
    site: 'Red Hills',
    physician: 'Dr Smith',
    date: '2024-08-12',
    diagnosis: 'metastasis',
    fraction_dose: 3.3,
    adherence: false,
    technique: 'Brachytherapy',
    linac: 'Machine 1',
    sim_to_treat: 1,
    priority: 'Standard',
    referrer: 'Dr. Rachel Nguyen',
    diagnosis_code: 'C43.9',
  },
  {
    fractions: 23,
    site: 'Campsie',
    physician: 'Dr Smith',
    date: '2024-07-08',
    diagnosis: 'metastasis',
    fraction_dose: 2.0,
    adherence: true,
    technique: 'Stereotactic',
    linac: 'Machine 4',
    sim_to_treat: 8,
    priority: 'Standard',
    referrer: 'Dr. Jonathan Blake',
    diagnosis_code: 'C20',
  },
  {
    fractions: 12,
    site: 'Campsie',
    physician: 'Dr Lopez',
    date: '2024-05-21',
    diagnosis: 'skin',
    fraction_dose: 2.2,
    adherence: true,
    technique: 'Conformal',
    linac: 'Machine 1',
    sim_to_treat: 8,
    priority: 'Emergency',
    referrer: 'Dr. Alex Reynolds',
    diagnosis_code: 'C79.81',
  },
  {
    fractions: 10,
    site: 'Rosebush',
    physician: 'Dr Lopez',
    date: '2024-08-13',
    diagnosis: 'neuro',
    fraction_dose: 1.8,
    adherence: true,
    technique: 'Stereotactic',
    linac: 'Machine 3',
    sim_to_treat: 4,
    priority: 'Standard',
    referrer: 'Dr. Alex Reynolds',
    diagnosis_code: 'C43.9',
  },
  {
    fractions: 12,
    site: 'Red Hills',
    physician: 'Dr Lopez',
    date: '2024-08-11',
    diagnosis: 'colorectal',
    fraction_dose: 1.6,
    adherence: true,
    technique: 'Brachytherapy',
    linac: 'Machine 1',
    sim_to_treat: 7,
    priority: 'Standard',
    referrer: 'Dr. Victor Hernandes',
    diagnosis_code: 'C71.9',
  },
  {
    fractions: 18,
    site: 'Campsie',
    physician: 'Dr Lopez',
    date: '2024-08-08',
    diagnosis: 'colorectal',
    fraction_dose: 2.3,
    adherence: true,
    technique: 'Conformal',
    linac: 'Machine 3',
    sim_to_treat: 9,
    priority: 'Urgent',
    referrer: 'Dr. Jonathan Blake',
    diagnosis_code: 'C79.81',
  },
  {
    fractions: 14,
    site: 'Campsie',
    physician: 'Dr Lopez',
    date: '2024-01-21',
    diagnosis: 'prostate',
    fraction_dose: 2.8,
    adherence: true,
    technique: 'IMRT',
    linac: 'Machine 3',
    sim_to_treat: 4,
    priority: 'Urgent',
    referrer: 'Dr. Clara Thompson',
    diagnosis_code: 'C43.9',
  },
  {
    fractions: 16,
    site: 'Rosebush',
    physician: 'Dr Smith',
    date: '2024-08-02',
    diagnosis: 'breast',
    fraction_dose: 1.4,
    adherence: true,
    technique: 'Unspecified',
    linac: 'Machine 4',
    sim_to_treat: 5,
    priority: 'Standard',
    referrer: 'Dr. Julian Foster',
    diagnosis_code: 'C20',
  },
  {
    fractions: 21,
    site: 'Rosebush',
    physician: 'Dr Adams',
    date: '2024-04-26',
    diagnosis: 'breast',
    fraction_dose: 1.9,
    adherence: true,
    technique: 'Unspecified',
    linac: 'Machine 4',
    sim_to_treat: 7,
    priority: 'Urgent',
    referrer: 'Dr. Jonathan Blake',
    diagnosis_code: 'C61',
  },
  {
    fractions: 12,
    site: 'Lakeside',
    physician: 'Dr Smith',
    date: '2024-07-20',
    diagnosis: 'breast',
    fraction_dose: 3.3,
    adherence: true,
    technique: 'Unspecified',
    linac: 'Machine 2',
    sim_to_treat: 1,
    priority: 'Emergency',
    referrer: 'Dr. Rachel Nguyen',
    diagnosis_code: 'C34.90',
  },
  {
    fractions: 17,
    site: 'Red Hills',
    physician: 'Dr Wong',
    date: '2024-03-23',
    diagnosis: 'colorectal',
    fraction_dose: 2.1,
    adherence: true,
    technique: 'Unspecified',
    linac: 'Machine 2',
    sim_to_treat: 3,
    priority: 'Urgent',
    referrer: 'Dr. Jonathan Blake',
    diagnosis_code: 'C20',
  },
  {
    fractions: 11,
    site: 'Red Hills',
    physician: 'Dr Lopez',
    date: '2024-08-24',
    diagnosis: 'neuro',
    fraction_dose: 3.2,
    adherence: true,
    technique: 'Unspecified',
    linac: 'Machine 2',
    sim_to_treat: 9,
    priority: 'Urgent',
    referrer: 'Dr. Victor Hernandes',
    diagnosis_code: 'C71.9',
  },
  {
    fractions: 21,
    site: 'Rosebush',
    physician: 'Dr Lopez',
    date: '2024-04-14',
    diagnosis: 'colorectal',
    fraction_dose: 2.8,
    adherence: true,
    technique: 'Unspecified',
    linac: 'Machine 4',
    sim_to_treat: 3,
    priority: 'Emergency',
    referrer: 'Dr. Rachel Nguyen',
    diagnosis_code: 'C20',
  },
  {
    fractions: 22,
    site: 'Campsie',
    physician: 'Dr Smith',
    date: '2024-06-04',
    diagnosis: 'breast',
    fraction_dose: 2.9,
    adherence: true,
    technique: 'Unspecified',
    linac: 'Machine 1',
    sim_to_treat: 6,
    priority: 'Urgent',
    referrer: 'Dr. Alex Reynolds',
    diagnosis_code: 'C20',
  },
  {
    fractions: 19,
    site: 'Lakeside',
    physician: 'Dr Wong',
    date: '2024-10-05',
    diagnosis: 'prostate',
    fraction_dose: 3.1,
    adherence: true,
    technique: 'Unspecified',
    linac: 'Machine 4',
    sim_to_treat: 5,
    priority: 'Standard',
    referrer: 'Dr. Michael Stern',
    diagnosis_code: 'C71.9',
  },
  {
    fractions: 25,
    site: 'Lakeside',
    physician: 'Dr Lopez',
    date: '2024-08-15',
    diagnosis: 'neuro',
    fraction_dose: 1.3,
    adherence: true,
    technique: 'Unspecified',
    linac: 'Machine 2',
    sim_to_treat: 9,
    priority: 'Emergency',
    referrer: 'Dr. Julian Foster',
    diagnosis_code: 'C34.90',
  },
  {
    fractions: 14,
    site: 'Campsie',
    physician: 'Dr Smith',
    date: '2024-06-22',
    diagnosis: 'colorectal',
    fraction_dose: 3.4,
    adherence: true,
    technique: 'Unspecified',
    linac: 'Machine 2',
    sim_to_treat: 5,
    priority: 'Urgent',
    referrer: 'Dr. Victor Hernandes',
    diagnosis_code: 'C20',
  },
  {
    fractions: 15,
    site: 'Red Hills',
    physician: 'Dr Adams',
    date: '2024-09-25',
    diagnosis: 'colorectal',
    fraction_dose: 2.1,
    adherence: true,
    technique: 'Unspecified',
    linac: 'Machine 4',
    sim_to_treat: 5,
    priority: 'Emergency',
    referrer: 'Dr. Emily Hartman',
    diagnosis_code: 'C71.9',
  },
  {
    fractions: 17,
    site: 'Lakeside',
    physician: 'Dr Wong',
    date: '2024-05-26',
    diagnosis: 'colorectal',
    fraction_dose: 3.0,
    adherence: true,
    technique: 'Stereotactic',
    linac: 'Machine 1',
    sim_to_treat: 8,
    priority: 'Emergency',
    referrer: 'Dr. Rachel Nguyen',
    diagnosis_code: 'C79.81',
  },
  {
    fractions: 17,
    site: 'Campsie',
    physician: 'Dr Wong',
    date: '2024-08-04',
    diagnosis: 'neuro',
    fraction_dose: 2.7,
    adherence: true,
    technique: 'Unspecified',
    linac: 'Machine 4',
    sim_to_treat: 8,
    priority: 'Urgent',
    referrer: 'Dr. Victor Hernandes',
    diagnosis_code: 'C79.81',
  },
  {
    fractions: 21,
    site: 'Rosebush',
    physician: 'Dr Adams',
    date: '2024-08-29',
    diagnosis: 'skin',
    fraction_dose: 2.4,
    adherence: true,
    technique: 'Unspecified',
    linac: 'Machine 4',
    sim_to_treat: 5,
    priority: 'Urgent',
    referrer: 'Dr. Rachel Nguyen',
    diagnosis_code: 'C20',
  },
  {
    fractions: 13,
    site: 'Campsie',
    physician: 'Dr Wong',
    date: '2024-09-06',
    diagnosis: 'breast',
    fraction_dose: 2.7,
    adherence: true,
    technique: 'Brachytherapy',
    linac: 'Machine 2',
    sim_to_treat: 3,
    priority: 'Standard',
    referrer: 'Dr. Sophie Greenberg',
    diagnosis_code: 'C43.9',
  },
  {
    fractions: 24,
    site: 'Campsie',
    physician: 'Dr Lopez',
    date: '2024-08-31',
    diagnosis: 'colorectal',
    fraction_dose: 1.6,
    adherence: true,
    technique: 'Unspecified',
    linac: 'Machine 3',
    sim_to_treat: 4,
    priority: 'Standard',
    referrer: 'Dr. Alex Reynolds',
    diagnosis_code: 'C43.9',
  },
  {
    fractions: 24,
    site: 'Red Hills',
    physician: 'Dr Adams',
    date: '2024-07-20',
    diagnosis: 'prostate',
    fraction_dose: 1.7,
    adherence: true,
    technique: 'Unspecified',
    linac: 'Machine 2',
    sim_to_treat: 10,
    priority: 'Urgent',
    referrer: 'Dr. Jonathan Blake',
    diagnosis_code: 'C71.9',
  },
  {
    fractions: 13,
    site: 'Rosebush',
    physician: 'Dr Wong',
    date: '2024-07-01',
    diagnosis: 'colorectal',
    fraction_dose: 2.3,
    adherence: true,
    technique: 'Conformal',
    linac: 'Machine 2',
    sim_to_treat: 10,
    priority: 'Urgent',
    referrer: 'Dr. Michael Stern',
    diagnosis_code: 'C20',
  },
  {
    fractions: 22,
    site: 'Lakeside',
    physician: 'Dr Wong',
    date: '2024-03-10',
    diagnosis: 'breast',
    fraction_dose: 3.3,
    adherence: true,
    technique: 'Unspecified',
    linac: 'Machine 2',
    sim_to_treat: 2,
    priority: 'Standard',
    referrer: 'Dr. Michael Stern',
    diagnosis_code: 'C20',
  },
  {
    fractions: 20,
    site: 'Campsie',
    physician: 'Dr Adams',
    date: '2024-08-07',
    diagnosis: 'metastasis',
    fraction_dose: 3.4,
    adherence: true,
    technique: 'Electrons',
    linac: 'Machine 1',
    sim_to_treat: 3,
    priority: 'Emergency',
    referrer: 'Dr. Alex Reynolds',
    diagnosis_code: 'C34.90',
  },
  {
    fractions: 14,
    site: 'Rosebush',
    physician: 'Dr Wong',
    date: '2024-08-16',
    diagnosis: 'neuro',
    fraction_dose: 3.1,
    adherence: true,
    technique: 'Unspecified',
    linac: 'Machine 2',
    sim_to_treat: 9,
    priority: 'Urgent',
    referrer: 'Dr. Clara Thompson',
    diagnosis_code: 'C20',
  },
  {
    fractions: 11,
    site: 'Red Hills',
    physician: 'Dr Lopez',
    date: '2024-08-23',
    diagnosis: 'prostate',
    fraction_dose: 3.4,
    adherence: true,
    technique: 'Conformal',
    linac: 'Machine 3',
    sim_to_treat: 1,
    priority: 'Standard',
    referrer: 'Dr. Michael Stern',
    diagnosis_code: 'C61',
  },
  {
    fractions: 19,
    site: 'Red Hills',
    physician: 'Dr Lopez',
    date: '2024-04-30',
    diagnosis: 'colorectal',
    fraction_dose: 1.8,
    adherence: false,
    technique: 'Conformal',
    linac: 'Machine 1',
    sim_to_treat: 1,
    priority: 'Urgent',
    referrer: 'Dr. Michael Stern',
    diagnosis_code: 'C34.90',
  },
  {
    fractions: 17,
    site: 'Red Hills',
    physician: 'Dr Wong',
    date: '2024-07-12',
    diagnosis: 'colorectal',
    fraction_dose: 3.0,
    adherence: true,
    technique: 'VMAT',
    linac: 'Machine 1',
    sim_to_treat: 10,
    priority: 'Emergency',
    referrer: 'Dr. Alex Reynolds',
    diagnosis_code: 'C20',
  },
  {
    fractions: 11,
    site: 'Lakeside',
    physician: 'Dr Smith',
    date: '2024-02-05',
    diagnosis: 'colorectal',
    fraction_dose: 2.7,
    adherence: true,
    technique: 'Conformal',
    linac: 'Machine 3',
    sim_to_treat: 8,
    priority: 'Urgent',
    referrer: 'Dr. Julian Foster',
    diagnosis_code: 'C61',
  },
  {
    fractions: 10,
    site: 'Rosebush',
    physician: 'Dr Smith',
    date: '2024-06-11',
    diagnosis: 'prostate',
    fraction_dose: 1.8,
    adherence: true,
    technique: 'IMRT',
    linac: 'Machine 2',
    sim_to_treat: 4,
    priority: 'Emergency',
    referrer: 'Dr. Alex Reynolds',
    diagnosis_code: 'C43.9',
  },
  {
    fractions: 22,
    site: 'Campsie',
    physician: 'Dr Wong',
    date: '2024-03-02',
    diagnosis: 'skin',
    fraction_dose: 1.4,
    adherence: true,
    technique: 'Electrons',
    linac: 'Machine 1',
    sim_to_treat: 7,
    priority: 'Emergency',
    referrer: 'Dr. Clara Thompson',
    diagnosis_code: 'C34.90',
  },
  {
    fractions: 16,
    site: 'Red Hills',
    physician: 'Dr Lopez',
    date: '2024-08-05',
    diagnosis: 'colorectal',
    fraction_dose: 1.6,
    adherence: true,
    technique: 'Brachytherapy',
    linac: 'Machine 4',
    sim_to_treat: 6,
    priority: 'Emergency',
    referrer: 'Dr. Sophie Greenberg',
    diagnosis_code: 'C71.9',
  },
  {
    fractions: 17,
    site: 'Lakeside',
    physician: 'Dr Lopez',
    date: '2024-10-22',
    diagnosis: 'colorectal',
    fraction_dose: 2.8,
    adherence: true,
    technique: 'Stereotactic',
    linac: 'Machine 4',
    sim_to_treat: 9,
    priority: 'Standard',
    referrer: 'Dr. Alex Reynolds',
    diagnosis_code: 'C79.81',
  },
  {
    fractions: 15,
    site: 'Lakeside',
    physician: 'Dr Lopez',
    date: '2024-05-07',
    diagnosis: 'prostate',
    fraction_dose: 2.9,
    adherence: true,
    technique: 'Conformal',
    linac: 'Machine 4',
    sim_to_treat: 4,
    priority: 'Urgent',
    referrer: 'Dr. Aisha Patel',
    diagnosis_code: 'C79.81',
  },
  {
    fractions: 24,
    site: 'Lakeside',
    physician: 'Dr Lopez',
    date: '2024-05-27',
    diagnosis: 'neuro',
    fraction_dose: 2.2,
    adherence: true,
    technique: 'Brachytherapy',
    linac: 'Machine 2',
    sim_to_treat: 9,
    priority: 'Urgent',
    referrer: 'Dr. Sophie Greenberg',
    diagnosis_code: 'C79.81',
  },
  {
    fractions: 19,
    site: 'Red Hills',
    physician: 'Dr Lopez',
    date: '2024-07-05',
    diagnosis: 'metastasis',
    fraction_dose: 2.6,
    adherence: true,
    technique: 'Electrons',
    linac: 'Machine 3',
    sim_to_treat: 9,
    priority: 'Urgent',
    referrer: 'Dr. Julian Foster',
    diagnosis_code: 'C61',
  },
  {
    fractions: 24,
    site: 'Lakeside',
    physician: 'Dr Adams',
    date: '2024-03-10',
    diagnosis: 'breast',
    fraction_dose: 3.2,
    adherence: true,
    technique: 'Electrons',
    linac: 'Machine 3',
    sim_to_treat: 10,
    priority: 'Urgent',
    referrer: 'Dr. Rachel Nguyen',
    diagnosis_code: 'C71.9',
  },
  {
    fractions: 10,
    site: 'Red Hills',
    physician: 'Dr Adams',
    date: '2024-02-05',
    diagnosis: 'neuro',
    fraction_dose: 2.7,
    adherence: false,
    technique: 'Electrons',
    linac: 'Machine 1',
    sim_to_treat: 1,
    priority: 'Emergency',
    referrer: 'Dr. Michael Stern',
    diagnosis_code: 'C20',
  },
  {
    fractions: 17,
    site: 'Red Hills',
    physician: 'Dr Adams',
    date: '2024-01-06',
    diagnosis: 'breast',
    fraction_dose: 3.1,
    adherence: true,
    technique: 'Brachytherapy',
    linac: 'Machine 1',
    sim_to_treat: 5,
    priority: 'Emergency',
    referrer: 'Dr. Sophie Greenberg',
    diagnosis_code: 'C34.90',
  },
  {
    fractions: 25,
    site: 'Rosebush',
    physician: 'Dr Wong',
    date: '2024-09-14',
    diagnosis: 'skin',
    fraction_dose: 2.4,
    adherence: true,
    technique: 'Stereotactic',
    linac: 'Machine 2',
    sim_to_treat: 7,
    priority: 'Standard',
    referrer: 'Dr. Michael Stern',
    diagnosis_code: 'C34.90',
  },
  {
    fractions: 15,
    site: 'Lakeside',
    physician: 'Dr Smith',
    date: '2024-05-08',
    diagnosis: 'skin',
    fraction_dose: 3.4,
    adherence: true,
    technique: 'Conformal',
    linac: 'Machine 3',
    sim_to_treat: 4,
    priority: 'Urgent',
    referrer: 'Dr. Julian Foster',
    diagnosis_code: 'C43.9',
  },

  {
    fractions: 22,
    site: 'Red Hills',
    physician: 'Dr Wong',
    date: '2024-10-25',
    diagnosis: 'skin',
    fraction_dose: 2.1,
    adherence: true,
    technique: 'Brachytherapy',
    linac: 'Machine 1',
    sim_to_treat: 3,
    priority: 'Emergency',
    referrer: 'Dr. Alex Reynolds',
    diagnosis_code: 'C43.9',
  },
  {
    fractions: 13,
    site: 'Red Hills',
    physician: 'Dr Smith',
    date: '2024-04-09',
    diagnosis: 'metastasis',
    fraction_dose: 2.2,
    adherence: false,
    technique: 'Brachytherapy',
    linac: 'Machine 2',
    sim_to_treat: 9,
    priority: 'Urgent',
    referrer: 'Dr. Alex Reynolds',
    diagnosis_code: 'C34.90',
  },
  {
    fractions: 23,
    site: 'Red Hills',
    physician: 'Dr Smith',
    date: '2024-05-27',
    diagnosis: 'prostate',
    fraction_dose: 1.9,
    adherence: true,
    technique: 'VMAT',
    linac: 'Machine 1',
    sim_to_treat: 3,
    priority: 'Urgent',
    referrer: 'Dr. Alex Reynolds',
    diagnosis_code: 'C71.9',
  },
  {
    fractions: 11,
    site: 'Red Hills',
    physician: 'Dr Wong',
    date: '2024-07-03',
    diagnosis: 'prostate',
    fraction_dose: 1.8,
    adherence: true,
    technique: 'Conformal',
    linac: 'Machine 1',
    sim_to_treat: 2,
    priority: 'Standard',
    referrer: 'Dr. Sophie Greenberg',
    diagnosis_code: 'C34.90',
  },
  {
    fractions: 22,
    site: 'Lakeside',
    physician: 'Dr Wong',
    date: '2024-05-28',
    diagnosis: 'skin',
    fraction_dose: 2.1,
    adherence: true,
    technique: 'IMRT',
    linac: 'Machine 2',
    sim_to_treat: 1,
    priority: 'Standard',
    referrer: 'Dr. Victor Hernandes',
    diagnosis_code: 'C34.90',
  },
  {
    fractions: 18,
    site: 'Red Hills',
    physician: 'Dr Smith',
    date: '2024-01-12',
    diagnosis: 'prostate',
    fraction_dose: 2.1,
    adherence: true,
    technique: 'Electrons',
    linac: 'Machine 4',
    sim_to_treat: 10,
    priority: 'Standard',
    referrer: 'Dr. Alex Reynolds',
    diagnosis_code: 'C71.9',
  },
  {
    fractions: 21,
    site: 'Campsie',
    physician: 'Dr Wong',
    date: '2024-06-02',
    diagnosis: 'breast',
    fraction_dose: 1.6,
    adherence: true,
    technique: 'VMAT',
    linac: 'Machine 1',
    sim_to_treat: 10,
    priority: 'Urgent',
    referrer: 'Dr. Aisha Patel',
    diagnosis_code: 'C43.9',
  },
  {
    fractions: 22,
    site: 'Red Hills',
    physician: 'Dr Lopez',
    date: '2024-07-15',
    diagnosis: 'metastasis',
    fraction_dose: 2.7,
    adherence: true,
    technique: 'Stereotactic',
    linac: 'Machine 3',
    sim_to_treat: 2,
    priority: 'Emergency',
    referrer: 'Dr. Victor Hernandes',
    diagnosis_code: 'C43.9',
  },
  {
    fractions: 11,
    site: 'Red Hills',
    physician: 'Dr Smith',
    date: '2024-06-13',
    diagnosis: 'breast',
    fraction_dose: 1.4,
    adherence: true,
    technique: 'VMAT',
    linac: 'Machine 1',
    sim_to_treat: 2,
    priority: 'Emergency',
    referrer: 'Dr. Alex Reynolds',
    diagnosis_code: 'C20',
  },
  {
    fractions: 24,
    site: 'Lakeside',
    physician: 'Dr Lopez',
    date: '2024-07-10',
    diagnosis: 'skin',
    fraction_dose: 2.7,
    adherence: true,
    technique: 'Conformal',
    linac: 'Machine 2',
    sim_to_treat: 10,
    priority: 'Emergency',
    referrer: 'Dr. Aisha Patel',
    diagnosis_code: 'C61',
  },
  {
    fractions: 22,
    site: 'Red Hills',
    physician: 'Dr Smith',
    date: '2024-10-31',
    diagnosis: 'neuro',
    fraction_dose: 2.8,
    adherence: true,
    technique: 'Electrons',
    linac: 'Machine 3',
    sim_to_treat: 2,
    priority: 'Urgent',
    referrer: 'Dr. Julian Foster',
    diagnosis_code: 'C71.9',
  },
  {
    fractions: 21,
    site: 'Lakeside',
    physician: 'Dr Adams',
    date: '2024-04-25',
    diagnosis: 'metastasis',
    fraction_dose: 3.0,
    adherence: false,
    technique: 'Conformal',
    linac: 'Machine 4',
    sim_to_treat: 3,
    priority: 'Emergency',
    referrer: 'Dr. Aisha Patel',
    diagnosis_code: 'C61',
  },
  {
    fractions: 10,
    site: 'Red Hills',
    physician: 'Dr Adams',
    date: '2024-10-04',
    diagnosis: 'colorectal',
    fraction_dose: 2.9,
    adherence: true,
    technique: 'Electrons',
    linac: 'Machine 3',
    sim_to_treat: 4,
    priority: 'Emergency',
    referrer: 'Dr. Alex Reynolds',
    diagnosis_code: 'C61',
  },
  {
    fractions: 18,
    site: 'Campsie',
    physician: 'Dr Smith',
    date: '2024-01-20',
    diagnosis: 'metastasis',
    fraction_dose: 1.2,
    adherence: true,
    technique: 'VMAT',
    linac: 'Machine 1',
    sim_to_treat: 6,
    priority: 'Urgent',
    referrer: 'Dr. Clara Thompson',
    diagnosis_code: 'C20',
  },
  {
    fractions: 21,
    site: 'Rosebush',
    physician: 'Dr Smith',
    date: '2024-01-15',
    diagnosis: 'metastasis',
    fraction_dose: 3.1,
    adherence: true,
    technique: 'Electrons',
    linac: 'Machine 2',
    sim_to_treat: 6,
    priority: 'Standard',
    referrer: 'Dr. Michael Stern',
    diagnosis_code: 'C43.9',
  },
  {
    fractions: 22,
    site: 'Campsie',
    physician: 'Dr Lopez',
    date: '2024-01-22',
    diagnosis: 'colorectal',
    fraction_dose: 2.8,
    adherence: true,
    technique: 'IMRT',
    linac: 'Machine 2',
    sim_to_treat: 9,
    priority: 'Urgent',
    referrer: 'Dr. Aisha Patel',
    diagnosis_code: 'C20',
  },
  {
    fractions: 16,
    site: 'Red Hills',
    physician: 'Dr Adams',
    date: '2024-07-06',
    diagnosis: 'prostate',
    fraction_dose: 3.2,
    adherence: true,
    technique: 'Electrons',
    linac: 'Machine 3',
    sim_to_treat: 8,
    priority: 'Emergency',
    referrer: 'Dr. Clara Thompson',
    diagnosis_code: 'C71.9',
  },
  {
    fractions: 17,
    site: 'Campsie',
    physician: 'Dr Lopez',
    date: '2024-04-04',
    diagnosis: 'colorectal',
    fraction_dose: 2.9,
    adherence: true,
    technique: 'Stereotactic',
    linac: 'Machine 2',
    sim_to_treat: 4,
    priority: 'Urgent',
    referrer: 'Dr. Julian Foster',
    diagnosis_code: 'C79.81',
  },
  {
    fractions: 14,
    site: 'Red Hills',
    physician: 'Dr Adams',
    date: '2024-03-18',
    diagnosis: 'neuro',
    fraction_dose: 3.3,
    adherence: true,
    technique: 'VMAT',
    linac: 'Machine 1',
    sim_to_treat: 2,
    priority: 'Emergency',
    referrer: 'Dr. Julian Foster',
    diagnosis_code: 'C71.9',
  },
  {
    fractions: 19,
    site: 'Rosebush',
    physician: 'Dr Adams',
    date: '2024-10-04',
    diagnosis: 'prostate',
    fraction_dose: 3.3,
    adherence: true,
    technique: 'Electrons',
    linac: 'Machine 4',
    sim_to_treat: 9,
    priority: 'Urgent',
    referrer: 'Dr. Alex Reynolds',
    diagnosis_code: 'C79.81',
  },
  {
    fractions: 21,
    site: 'Lakeside',
    physician: 'Dr Adams',
    date: '2024-10-24',
    diagnosis: 'neuro',
    fraction_dose: 1.2,
    adherence: true,
    technique: 'IMRT',
    linac: 'Machine 3',
    sim_to_treat: 10,
    priority: 'Standard',
    referrer: 'Dr. Jonathan Blake',
    diagnosis_code: 'C20',
  },
  {
    fractions: 23,
    site: 'Rosebush',
    physician: 'Dr Lopez',
    date: '2024-09-01',
    diagnosis: 'metastasis',
    fraction_dose: 1.4,
    adherence: true,
    technique: 'Conformal',
    linac: 'Machine 1',
    sim_to_treat: 7,
    priority: 'Standard',
    referrer: 'Dr. Julian Foster',
    diagnosis_code: 'C79.81',
  },
  {
    fractions: 12,
    site: 'Lakeside',
    physician: 'Dr Wong',
    date: '2024-01-12',
    diagnosis: 'neuro',
    fraction_dose: 1.8,
    adherence: true,
    technique: 'IMRT',
    linac: 'Machine 4',
    sim_to_treat: 1,
    priority: 'Urgent',
    referrer: 'Dr. Alex Reynolds',
    diagnosis_code: 'C71.9',
  },
  {
    fractions: 24,
    site: 'Rosebush',
    physician: 'Dr Wong',
    date: '2024-10-16',
    diagnosis: 'metastasis',
    fraction_dose: 2.5,
    adherence: true,
    technique: 'Conformal',
    linac: 'Machine 1',
    sim_to_treat: 1,
    priority: 'Standard',
    referrer: 'Dr. Aisha Patel',
    diagnosis_code: 'C61',
  },
  {
    fractions: 16,
    site: 'Red Hills',
    physician: 'Dr Smith',
    date: '2024-03-30',
    diagnosis: 'colorectal',
    fraction_dose: 2.4,
    adherence: false,
    technique: 'Conformal',
    linac: 'Machine 4',
    sim_to_treat: 8,
    priority: 'Urgent',
    referrer: 'Dr. Sophie Greenberg',
    diagnosis_code: 'C34.90',
  },
  {
    fractions: 16,
    site: 'Lakeside',
    physician: 'Dr Adams',
    date: '2024-09-25',
    diagnosis: 'prostate',
    fraction_dose: 2.9,
    adherence: true,
    technique: 'Electrons',
    linac: 'Machine 1',
    sim_to_treat: 6,
    priority: 'Urgent',
    referrer: 'Dr. Rachel Nguyen',
    diagnosis_code: 'C20',
  },
  {
    fractions: 18,
    site: 'Rosebush',
    physician: 'Dr Adams',
    date: '2024-08-19',
    diagnosis: 'neuro',
    fraction_dose: 3.4,
    adherence: false,
    technique: 'Stereotactic',
    linac: 'Machine 1',
    sim_to_treat: 8,
    priority: 'Standard',
    referrer: 'Dr. Jonathan Blake',
    diagnosis_code: 'C61',
  },
  {
    fractions: 21,
    site: 'Red Hills',
    physician: 'Dr Wong',
    date: '2024-02-12',
    diagnosis: 'breast',
    fraction_dose: 2.7,
    adherence: true,
    technique: 'Conformal',
    linac: 'Machine 1',
    sim_to_treat: 1,
    priority: 'Urgent',
    referrer: 'Dr. Sophie Greenberg',
    diagnosis_code: 'C20',
  },
  {
    fractions: 17,
    site: 'Red Hills',
    physician: 'Dr Smith',
    date: '2024-10-06',
    diagnosis: 'breast',
    fraction_dose: 3.3,
    adherence: true,
    technique: 'IMRT',
    linac: 'Machine 2',
    sim_to_treat: 1,
    priority: 'Standard',
    referrer: 'Dr. Victor Hernandes',
    diagnosis_code: 'C20',
  },
  {
    fractions: 19,
    site: 'Red Hills',
    physician: 'Dr Lopez',
    date: '2024-06-20',
    diagnosis: 'metastasis',
    fraction_dose: 2.6,
    adherence: true,
    technique: 'Stereotactic',
    linac: 'Machine 1',
    sim_to_treat: 7,
    priority: 'Urgent',
    referrer: 'Dr. Aisha Patel',
    diagnosis_code: 'C71.9',
  },
  {
    fractions: 18,
    site: 'Rosebush',
    physician: 'Dr Lopez',
    date: '2024-05-18',
    diagnosis: 'prostate',
    fraction_dose: 2.0,
    adherence: false,
    technique: 'IMRT',
    linac: 'Machine 4',
    sim_to_treat: 10,
    priority: 'Urgent',
    referrer: 'Dr. Michael Stern',
    diagnosis_code: 'C79.81',
  },
  {
    fractions: 24,
    site: 'Rosebush',
    physician: 'Dr Smith',
    date: '2024-04-07',
    diagnosis: 'prostate',
    fraction_dose: 2.3,
    adherence: true,
    technique: 'Brachytherapy',
    linac: 'Machine 4',
    sim_to_treat: 6,
    priority: 'Standard',
    referrer: 'Dr. Rachel Nguyen',
    diagnosis_code: 'C61',
  },
  {
    fractions: 11,
    site: 'Red Hills',
    physician: 'Dr Lopez',
    date: '2024-02-05',
    diagnosis: 'colorectal',
    fraction_dose: 1.4,
    adherence: true,
    technique: 'Electrons',
    linac: 'Machine 2',
    sim_to_treat: 6,
    priority: 'Urgent',
    referrer: 'Dr. Alex Reynolds',
    diagnosis_code: 'C79.81',
  },
  {
    fractions: 24,
    site: 'Rosebush',
    physician: 'Dr Wong',
    date: '2024-01-14',
    diagnosis: 'neuro',
    fraction_dose: 2.0,
    adherence: true,
    technique: 'Electrons',
    linac: 'Machine 4',
    sim_to_treat: 1,
    priority: 'Emergency',
    referrer: 'Dr. Rachel Nguyen',
    diagnosis_code: 'C71.9',
  },
  {
    fractions: 13,
    site: 'Red Hills',
    physician: 'Dr Lopez',
    date: '2024-03-23',
    diagnosis: 'breast',
    fraction_dose: 3.0,
    adherence: true,
    technique: 'Brachytherapy',
    linac: 'Machine 1',
    sim_to_treat: 2,
    priority: 'Urgent',
    referrer: 'Dr. Michael Stern',
    diagnosis_code: 'C20',
  },
  {
    fractions: 16,
    site: 'Red Hills',
    physician: 'Dr Lopez',
    date: '2024-05-29',
    diagnosis: 'metastasis',
    fraction_dose: 1.7,
    adherence: false,
    technique: 'Brachytherapy',
    linac: 'Machine 3',
    sim_to_treat: 5,
    priority: 'Standard',
    referrer: 'Dr. Rachel Nguyen',
    diagnosis_code: 'C20',
  },
  {
    fractions: 21,
    site: 'Rosebush',
    physician: 'Dr Wong',
    date: '2024-02-14',
    diagnosis: 'breast',
    fraction_dose: 2.6,
    adherence: true,
    technique: 'Electrons',
    linac: 'Machine 4',
    sim_to_treat: 9,
    priority: 'Emergency',
    referrer: 'Dr. Jonathan Blake',
    diagnosis_code: 'C79.81',
  },
  {
    fractions: 14,
    site: 'Rosebush',
    physician: 'Dr Smith',
    date: '2024-05-17',
    diagnosis: 'metastasis',
    fraction_dose: 2.8,
    adherence: false,
    technique: 'Electrons',
    linac: 'Machine 2',
    sim_to_treat: 2,
    priority: 'Emergency',
    referrer: 'Dr. Michael Stern',
    diagnosis_code: 'C71.9',
  },
  {
    fractions: 10,
    site: 'Campsie',
    physician: 'Dr Adams',
    date: '2024-02-12',
    diagnosis: 'skin',
    fraction_dose: 1.6,
    adherence: true,
    technique: 'Brachytherapy',
    linac: 'Machine 3',
    sim_to_treat: 7,
    priority: 'Emergency',
    referrer: 'Dr. Aisha Patel',
    diagnosis_code: 'C79.81',
  },
  {
    fractions: 21,
    site: 'Red Hills',
    physician: 'Dr Lopez',
    date: '2024-05-01',
    diagnosis: 'neuro',
    fraction_dose: 2.4,
    adherence: true,
    technique: 'Conformal',
    linac: 'Machine 4',
    sim_to_treat: 8,
    priority: 'Urgent',
    referrer: 'Dr. Julian Foster',
    diagnosis_code: 'C34.90',
  },
  {
    fractions: 15,
    site: 'Lakeside',
    physician: 'Dr Adams',
    date: '2024-05-15',
    diagnosis: 'neuro',
    fraction_dose: 2.3,
    adherence: true,
    technique: 'IMRT',
    linac: 'Machine 3',
    sim_to_treat: 3,
    priority: 'Urgent',
    referrer: 'Dr. Jonathan Blake',
    diagnosis_code: 'C61',
  },
  {
    fractions: 17,
    site: 'Campsie',
    physician: 'Dr Adams',
    date: '2024-01-10',
    diagnosis: 'colorectal',
    fraction_dose: 1.5,
    adherence: true,
    technique: 'Conformal',
    linac: 'Machine 2',
    sim_to_treat: 6,
    priority: 'Emergency',
    referrer: 'Dr. Michael Stern',
    diagnosis_code: 'C79.81',
  },
  {
    fractions: 23,
    site: 'Campsie',
    physician: 'Dr Adams',
    date: '2024-09-14',
    diagnosis: 'skin',
    fraction_dose: 1.5,
    adherence: true,
    technique: 'Conformal',
    linac: 'Machine 4',
    sim_to_treat: 8,
    priority: 'Emergency',
    referrer: 'Dr. Aisha Patel',
    diagnosis_code: 'C71.9',
  },
  {
    fractions: 17,
    site: 'Rosebush',
    physician: 'Dr Wong',
    date: '2024-06-30',
    diagnosis: 'metastasis',
    fraction_dose: 2.1,
    adherence: false,
    technique: 'Stereotactic',
    linac: 'Machine 3',
    sim_to_treat: 6,
    priority: 'Standard',
    referrer: 'Dr. Clara Thompson',
    diagnosis_code: 'C79.81',
  },
  {
    fractions: 18,
    site: 'Red Hills',
    physician: 'Dr Lopez',
    date: '2024-07-07',
    diagnosis: 'prostate',
    fraction_dose: 1.3,
    adherence: true,
    technique: 'Stereotactic',
    linac: 'Machine 4',
    sim_to_treat: 7,
    priority: 'Urgent',
    referrer: 'Dr. Julian Foster',
    diagnosis_code: 'C71.9',
  },
  {
    fractions: 17,
    site: 'Red Hills',
    physician: 'Dr Wong',
    date: '2024-04-14',
    diagnosis: 'metastasis',
    fraction_dose: 2.3,
    adherence: true,
    technique: 'Brachytherapy',
    linac: 'Machine 2',
    sim_to_treat: 8,
    priority: 'Emergency',
    referrer: 'Dr. Julian Foster',
    diagnosis_code: 'C43.9',
  },
  {
    fractions: 25,
    site: 'Campsie',
    physician: 'Dr Adams',
    date: '2024-06-16',
    diagnosis: 'neuro',
    fraction_dose: 1.3,
    adherence: false,
    technique: 'Conformal',
    linac: 'Machine 4',
    sim_to_treat: 5,
    priority: 'Standard',
    referrer: 'Dr. Clara Thompson',
    diagnosis_code: 'C34.90',
  },
  {
    fractions: 19,
    site: 'Lakeside',
    physician: 'Dr Adams',
    date: '2024-09-19',
    diagnosis: 'colorectal',
    fraction_dose: 1.3,
    adherence: true,
    technique: 'IMRT',
    linac: 'Machine 3',
    sim_to_treat: 2,
    priority: 'Emergency',
    referrer: 'Dr. Sophie Greenberg',
    diagnosis_code: 'C20',
  },
  {
    fractions: 22,
    site: 'Lakeside',
    physician: 'Dr Wong',
    date: '2024-01-07',
    diagnosis: 'skin',
    fraction_dose: 1.8,
    adherence: true,
    technique: 'Brachytherapy',
    linac: 'Machine 2',
    sim_to_treat: 5,
    priority: 'Standard',
    referrer: 'Dr. Julian Foster',
    diagnosis_code: 'C61',
  },
  {
    fractions: 21,
    site: 'Campsie',
    physician: 'Dr Wong',
    date: '2024-09-15',
    diagnosis: 'skin',
    fraction_dose: 2.3,
    adherence: true,
    technique: 'IMRT',
    linac: 'Machine 2',
    sim_to_treat: 8,
    priority: 'Emergency',
    referrer: 'Dr. Julian Foster',
    diagnosis_code: 'C34.90',
  },
  {
    fractions: 25,
    site: 'Lakeside',
    physician: 'Dr Wong',
    date: '2024-09-13',
    diagnosis: 'colorectal',
    fraction_dose: 2.8,
    adherence: false,
    technique: 'Stereotactic',
    linac: 'Machine 4',
    sim_to_treat: 3,
    priority: 'Standard',
    referrer: 'Dr. Clara Thompson',
    diagnosis_code: 'C79.81',
  },
  {
    fractions: 19,
    site: 'Campsie',
    physician: 'Dr Lopez',
    date: '2024-02-15',
    diagnosis: 'colorectal',
    fraction_dose: 1.9,
    adherence: true,
    technique: 'Brachytherapy',
    linac: 'Machine 3',
    sim_to_treat: 8,
    priority: 'Emergency',
    referrer: 'Dr. Jonathan Blake',
    diagnosis_code: 'C34.90',
  },
  {
    fractions: 16,
    site: 'Red Hills',
    physician: 'Dr Wong',
    date: '2024-01-22',
    diagnosis: 'metastasis',
    fraction_dose: 2.6,
    adherence: true,
    technique: 'Brachytherapy',
    linac: 'Machine 1',
    sim_to_treat: 2,
    priority: 'Emergency',
    referrer: 'Dr. Rachel Nguyen',
    diagnosis_code: 'C20',
  },
  {
    fractions: 20,
    site: 'Lakeside',
    physician: 'Dr Wong',
    date: '2024-01-24',
    diagnosis: 'skin',
    fraction_dose: 1.5,
    adherence: true,
    technique: 'Stereotactic',
    linac: 'Machine 1',
    sim_to_treat: 8,
    priority: 'Standard',
    referrer: 'Dr. Victor Hernandes',
    diagnosis_code: 'C79.81',
  },
  {
    fractions: 25,
    site: 'Lakeside',
    physician: 'Dr Lopez',
    date: '2024-07-23',
    diagnosis: 'metastasis',
    fraction_dose: 1.9,
    adherence: true,
    technique: 'Brachytherapy',
    linac: 'Machine 2',
    sim_to_treat: 5,
    priority: 'Urgent',
    referrer: 'Dr. Emily Hartman',
    diagnosis_code: 'C79.81',
  },
  {
    fractions: 12,
    site: 'Red Hills',
    physician: 'Dr Adams',
    date: '2024-03-18',
    diagnosis: 'breast',
    fraction_dose: 2.5,
    adherence: true,
    technique: 'Brachytherapy',
    linac: 'Machine 4',
    sim_to_treat: 10,
    priority: 'Standard',
    referrer: 'Dr. Michael Stern',
    diagnosis_code: 'C61',
  },
  {
    fractions: 12,
    site: 'Red Hills',
    physician: 'Dr Smith',
    date: '2024-08-12',
    diagnosis: 'metastasis',
    fraction_dose: 3.3,
    adherence: false,
    technique: 'Brachytherapy',
    linac: 'Machine 1',
    sim_to_treat: 1,
    priority: 'Standard',
    referrer: 'Dr. Rachel Nguyen',
    diagnosis_code: 'C43.9',
  },
  {
    fractions: 23,
    site: 'Campsie',
    physician: 'Dr Smith',
    date: '2024-07-08',
    diagnosis: 'metastasis',
    fraction_dose: 2.0,
    adherence: true,
    technique: 'Stereotactic',
    linac: 'Machine 4',
    sim_to_treat: 8,
    priority: 'Standard',
    referrer: 'Dr. Jonathan Blake',
    diagnosis_code: 'C20',
  },
  {
    fractions: 12,
    site: 'Campsie',
    physician: 'Dr Lopez',
    date: '2024-05-21',
    diagnosis: 'skin',
    fraction_dose: 2.2,
    adherence: true,
    technique: 'Conformal',
    linac: 'Machine 1',
    sim_to_treat: 8,
    priority: 'Emergency',
    referrer: 'Dr. Alex Reynolds',
    diagnosis_code: 'C79.81',
  },
  {
    fractions: 10,
    site: 'Rosebush',
    physician: 'Dr Lopez',
    date: '2024-08-13',
    diagnosis: 'neuro',
    fraction_dose: 1.8,
    adherence: true,
    technique: 'Stereotactic',
    linac: 'Machine 3',
    sim_to_treat: 4,
    priority: 'Standard',
    referrer: 'Dr. Alex Reynolds',
    diagnosis_code: 'C43.9',
  },
  {
    fractions: 12,
    site: 'Red Hills',
    physician: 'Dr Lopez',
    date: '2024-08-11',
    diagnosis: 'colorectal',
    fraction_dose: 1.6,
    adherence: true,
    technique: 'Brachytherapy',
    linac: 'Machine 1',
    sim_to_treat: 7,
    priority: 'Standard',
    referrer: 'Dr. Victor Hernandes',
    diagnosis_code: 'C71.9',
  },
  {
    fractions: 18,
    site: 'Campsie',
    physician: 'Dr Lopez',
    date: '2024-08-08',
    diagnosis: 'colorectal',
    fraction_dose: 2.3,
    adherence: true,
    technique: 'Conformal',
    linac: 'Machine 3',
    sim_to_treat: 9,
    priority: 'Urgent',
    referrer: 'Dr. Jonathan Blake',
    diagnosis_code: 'C79.81',
  },
  {
    fractions: 14,
    site: 'Campsie',
    physician: 'Dr Lopez',
    date: '2024-01-21',
    diagnosis: 'prostate',
    fraction_dose: 2.8,
    adherence: true,
    technique: 'IMRT',
    linac: 'Machine 3',
    sim_to_treat: 4,
    priority: 'Urgent',
    referrer: 'Dr. Clara Thompson',
    diagnosis_code: 'C43.9',
  },
  {
    fractions: 16,
    site: 'Rosebush',
    physician: 'Dr Smith',
    date: '2024-08-02',
    diagnosis: 'breast',
    fraction_dose: 1.4,
    adherence: true,
    technique: 'Conformal',
    linac: 'Machine 4',
    sim_to_treat: 5,
    priority: 'Standard',
    referrer: 'Dr. Julian Foster',
    diagnosis_code: 'C20',
  },
  {
    fractions: 21,
    site: 'Rosebush',
    physician: 'Dr Adams',
    date: '2024-04-26',
    diagnosis: 'breast',
    fraction_dose: 1.9,
    adherence: true,
    technique: 'Electrons',
    linac: 'Machine 4',
    sim_to_treat: 7,
    priority: 'Urgent',
    referrer: 'Dr. Jonathan Blake',
    diagnosis_code: 'C61',
  },
  {
    fractions: 12,
    site: 'Lakeside',
    physician: 'Dr Smith',
    date: '2024-07-20',
    diagnosis: 'breast',
    fraction_dose: 3.3,
    adherence: true,
    technique: 'Electrons',
    linac: 'Machine 2',
    sim_to_treat: 1,
    priority: 'Emergency',
    referrer: 'Dr. Rachel Nguyen',
    diagnosis_code: 'C34.90',
  },
  {
    fractions: 17,
    site: 'Red Hills',
    physician: 'Dr Wong',
    date: '2024-03-23',
    diagnosis: 'colorectal',
    fraction_dose: 2.1,
    adherence: true,
    technique: 'Electrons',
    linac: 'Machine 2',
    sim_to_treat: 3,
    priority: 'Urgent',
    referrer: 'Dr. Jonathan Blake',
    diagnosis_code: 'C20',
  },
  {
    fractions: 11,
    site: 'Red Hills',
    physician: 'Dr Lopez',
    date: '2024-08-24',
    diagnosis: 'neuro',
    fraction_dose: 3.2,
    adherence: true,
    technique: 'Conformal',
    linac: 'Machine 2',
    sim_to_treat: 9,
    priority: 'Urgent',
    referrer: 'Dr. Victor Hernandes',
    diagnosis_code: 'C71.9',
  },
  {
    fractions: 21,
    site: 'Rosebush',
    physician: 'Dr Lopez',
    date: '2024-04-14',
    diagnosis: 'colorectal',
    fraction_dose: 2.8,
    adherence: true,
    technique: 'Electrons',
    linac: 'Machine 4',
    sim_to_treat: 3,
    priority: 'Emergency',
    referrer: 'Dr. Rachel Nguyen',
    diagnosis_code: 'C20',
  },
  {
    fractions: 22,
    site: 'Campsie',
    physician: 'Dr Smith',
    date: '2024-06-04',
    diagnosis: 'breast',
    fraction_dose: 2.9,
    adherence: false,
    technique: 'VMAT',
    linac: 'Machine 1',
    sim_to_treat: 6,
    priority: 'Urgent',
    referrer: 'Dr. Alex Reynolds',
    diagnosis_code: 'C20',
  },
  {
    fractions: 19,
    site: 'Lakeside',
    physician: 'Dr Wong',
    date: '2024-10-05',
    diagnosis: 'prostate',
    fraction_dose: 3.1,
    adherence: true,
    technique: 'Brachytherapy',
    linac: 'Machine 4',
    sim_to_treat: 5,
    priority: 'Standard',
    referrer: 'Dr. Michael Stern',
    diagnosis_code: 'C71.9',
  },
  {
    fractions: 25,
    site: 'Lakeside',
    physician: 'Dr Lopez',
    date: '2024-08-15',
    diagnosis: 'neuro',
    fraction_dose: 1.3,
    adherence: true,
    technique: 'IMRT',
    linac: 'Machine 2',
    sim_to_treat: 9,
    priority: 'Emergency',
    referrer: 'Dr. Julian Foster',
    diagnosis_code: 'C34.90',
  },
  {
    fractions: 14,
    site: 'Campsie',
    physician: 'Dr Smith',
    date: '2024-06-22',
    diagnosis: 'colorectal',
    fraction_dose: 3.4,
    adherence: true,
    technique: 'VMAT',
    linac: 'Machine 2',
    sim_to_treat: 5,
    priority: 'Urgent',
    referrer: 'Dr. Victor Hernandes',
    diagnosis_code: 'C20',
  },
  {
    fractions: 15,
    site: 'Red Hills',
    physician: 'Dr Adams',
    date: '2024-09-25',
    diagnosis: 'colorectal',
    fraction_dose: 2.1,
    adherence: true,
    technique: 'Electrons',
    linac: 'Machine 4',
    sim_to_treat: 5,
    priority: 'Emergency',
    referrer: 'Dr. Emily Hartman',
    diagnosis_code: 'C71.9',
  },
  {
    fractions: 17,
    site: 'Lakeside',
    physician: 'Dr Wong',
    date: '2024-05-26',
    diagnosis: 'colorectal',
    fraction_dose: 3.0,
    adherence: true,
    technique: 'Stereotactic',
    linac: 'Machine 1',
    sim_to_treat: 8,
    priority: 'Emergency',
    referrer: 'Dr. Rachel Nguyen',
    diagnosis_code: 'C79.81',
  },
  {
    fractions: 17,
    site: 'Campsie',
    physician: 'Dr Wong',
    date: '2024-08-04',
    diagnosis: 'neuro',
    fraction_dose: 2.7,
    adherence: true,
    technique: 'VMAT',
    linac: 'Machine 4',
    sim_to_treat: 8,
    priority: 'Urgent',
    referrer: 'Dr. Victor Hernandes',
    diagnosis_code: 'C79.81',
  },
  {
    fractions: 21,
    site: 'Rosebush',
    physician: 'Dr Adams',
    date: '2024-08-29',
    diagnosis: 'skin',
    fraction_dose: 2.4,
    adherence: true,
    technique: 'VMAT',
    linac: 'Machine 4',
    sim_to_treat: 5,
    priority: 'Urgent',
    referrer: 'Dr. Rachel Nguyen',
    diagnosis_code: 'C20',
  },
  {
    fractions: 13,
    site: 'Campsie',
    physician: 'Dr Wong',
    date: '2024-09-06',
    diagnosis: 'breast',
    fraction_dose: 2.7,
    adherence: true,
    technique: 'Brachytherapy',
    linac: 'Machine 2',
    sim_to_treat: 3,
    priority: 'Standard',
    referrer: 'Dr. Sophie Greenberg',
    diagnosis_code: 'C43.9',
  },
  {
    fractions: 24,
    site: 'Campsie',
    physician: 'Dr Lopez',
    date: '2024-08-31',
    diagnosis: 'colorectal',
    fraction_dose: 1.6,
    adherence: true,
    technique: 'Electrons',
    linac: 'Machine 3',
    sim_to_treat: 4,
    priority: 'Standard',
    referrer: 'Dr. Alex Reynolds',
    diagnosis_code: 'C43.9',
  },
  {
    fractions: 24,
    site: 'Red Hills',
    physician: 'Dr Adams',
    date: '2024-07-20',
    diagnosis: 'prostate',
    fraction_dose: 1.7,
    adherence: false,
    technique: 'Electrons',
    linac: 'Machine 2',
    sim_to_treat: 10,
    priority: 'Urgent',
    referrer: 'Dr. Jonathan Blake',
    diagnosis_code: 'C71.9',
  },
  {
    fractions: 13,
    site: 'Rosebush',
    physician: 'Dr Wong',
    date: '2024-07-01',
    diagnosis: 'colorectal',
    fraction_dose: 2.3,
    adherence: false,
    technique: 'Conformal',
    linac: 'Machine 2',
    sim_to_treat: 10,
    priority: 'Urgent',
    referrer: 'Dr. Michael Stern',
    diagnosis_code: 'C20',
  },
  {
    fractions: 22,
    site: 'Lakeside',
    physician: 'Dr Wong',
    date: '2024-03-10',
    diagnosis: 'breast',
    fraction_dose: 3.3,
    adherence: true,
    technique: 'Stereotactic',
    linac: 'Machine 2',
    sim_to_treat: 2,
    priority: 'Standard',
    referrer: 'Dr. Michael Stern',
    diagnosis_code: 'C20',
  },
  {
    fractions: 20,
    site: 'Campsie',
    physician: 'Dr Adams',
    date: '2024-08-07',
    diagnosis: 'metastasis',
    fraction_dose: 3.4,
    adherence: true,
    technique: 'Electrons',
    linac: 'Machine 1',
    sim_to_treat: 3,
    priority: 'Emergency',
    referrer: 'Dr. Alex Reynolds',
    diagnosis_code: 'C34.90',
  },
  {
    fractions: 14,
    site: 'Rosebush',
    physician: 'Dr Wong',
    date: '2024-08-16',
    diagnosis: 'neuro',
    fraction_dose: 3.1,
    adherence: true,
    technique: 'Stereotactic',
    linac: 'Machine 2',
    sim_to_treat: 9,
    priority: 'Urgent',
    referrer: 'Dr. Clara Thompson',
    diagnosis_code: 'C20',
  },
  {
    fractions: 11,
    site: 'Red Hills',
    physician: 'Dr Lopez',
    date: '2024-08-23',
    diagnosis: 'prostate',
    fraction_dose: 3.4,
    adherence: true,
    technique: 'Conformal',
    linac: 'Machine 3',
    sim_to_treat: 1,
    priority: 'Standard',
    referrer: 'Dr. Michael Stern',
    diagnosis_code: 'C61',
  },
  {
    fractions: 19,
    site: 'Red Hills',
    physician: 'Dr Lopez',
    date: '2024-04-30',
    diagnosis: 'colorectal',
    fraction_dose: 1.8,
    adherence: true,
    technique: 'Conformal',
    linac: 'Machine 1',
    sim_to_treat: 1,
    priority: 'Urgent',
    referrer: 'Dr. Michael Stern',
    diagnosis_code: 'C34.90',
  },
  {
    fractions: 17,
    site: 'Red Hills',
    physician: 'Dr Wong',
    date: '2024-07-12',
    diagnosis: 'colorectal',
    fraction_dose: 3.0,
    adherence: true,
    technique: 'VMAT',
    linac: 'Machine 1',
    sim_to_treat: 10,
    priority: 'Emergency',
    referrer: 'Dr. Alex Reynolds',
    diagnosis_code: 'C20',
  },
  {
    fractions: 11,
    site: 'Lakeside',
    physician: 'Dr Smith',
    date: '2024-02-05',
    diagnosis: 'colorectal',
    fraction_dose: 2.7,
    adherence: true,
    technique: 'Conformal',
    linac: 'Machine 3',
    sim_to_treat: 8,
    priority: 'Urgent',
    referrer: 'Dr. Julian Foster',
    diagnosis_code: 'C61',
  },
  {
    fractions: 10,
    site: 'Rosebush',
    physician: 'Dr Smith',
    date: '2024-06-11',
    diagnosis: 'prostate',
    fraction_dose: 1.8,
    adherence: true,
    technique: 'IMRT',
    linac: 'Machine 2',
    sim_to_treat: 4,
    priority: 'Emergency',
    referrer: 'Dr. Alex Reynolds',
    diagnosis_code: 'C43.9',
  },
  {
    fractions: 22,
    site: 'Campsie',
    physician: 'Dr Wong',
    date: '2024-03-02',
    diagnosis: 'skin',
    fraction_dose: 1.4,
    adherence: true,
    technique: 'Electrons',
    linac: 'Machine 1',
    sim_to_treat: 7,
    priority: 'Emergency',
    referrer: 'Dr. Clara Thompson',
    diagnosis_code: 'C34.90',
  },
  {
    fractions: 16,
    site: 'Red Hills',
    physician: 'Dr Lopez',
    date: '2024-08-05',
    diagnosis: 'colorectal',
    fraction_dose: 1.6,
    adherence: true,
    technique: 'Brachytherapy',
    linac: 'Machine 4',
    sim_to_treat: 6,
    priority: 'Emergency',
    referrer: 'Dr. Sophie Greenberg',
    diagnosis_code: 'C71.9',
  },
  {
    fractions: 17,
    site: 'Lakeside',
    physician: 'Dr Lopez',
    date: '2024-10-22',
    diagnosis: 'colorectal',
    fraction_dose: 2.8,
    adherence: true,
    technique: 'Stereotactic',
    linac: 'Machine 4',
    sim_to_treat: 9,
    priority: 'Standard',
    referrer: 'Dr. Alex Reynolds',
    diagnosis_code: 'C79.81',
  },
  {
    fractions: 15,
    site: 'Lakeside',
    physician: 'Dr Lopez',
    date: '2024-05-07',
    diagnosis: 'prostate',
    fraction_dose: 2.9,
    adherence: true,
    technique: 'Conformal',
    linac: 'Machine 4',
    sim_to_treat: 4,
    priority: 'Urgent',
    referrer: 'Dr. Aisha Patel',
    diagnosis_code: 'C79.81',
  },
  {
    fractions: 24,
    site: 'Lakeside',
    physician: 'Dr Lopez',
    date: '2024-05-27',
    diagnosis: 'neuro',
    fraction_dose: 2.2,
    adherence: true,
    technique: 'Brachytherapy',
    linac: 'Machine 2',
    sim_to_treat: 9,
    priority: 'Urgent',
    referrer: 'Dr. Sophie Greenberg',
    diagnosis_code: 'C79.81',
  },
  {
    fractions: 19,
    site: 'Red Hills',
    physician: 'Dr Lopez',
    date: '2024-07-05',
    diagnosis: 'metastasis',
    fraction_dose: 2.6,
    adherence: true,
    technique: 'Electrons',
    linac: 'Machine 3',
    sim_to_treat: 9,
    priority: 'Urgent',
    referrer: 'Dr. Julian Foster',
    diagnosis_code: 'C61',
  },
  {
    fractions: 24,
    site: 'Lakeside',
    physician: 'Dr Adams',
    date: '2024-03-10',
    diagnosis: 'breast',
    fraction_dose: 3.2,
    adherence: true,
    technique: 'Electrons',
    linac: 'Machine 3',
    sim_to_treat: 10,
    priority: 'Urgent',
    referrer: 'Dr. Rachel Nguyen',
    diagnosis_code: 'C71.9',
  },
  {
    fractions: 10,
    site: 'Red Hills',
    physician: 'Dr Adams',
    date: '2024-02-05',
    diagnosis: 'neuro',
    fraction_dose: 2.7,
    adherence: false,
    technique: 'Electrons',
    linac: 'Machine 1',
    sim_to_treat: 1,
    priority: 'Emergency',
    referrer: 'Dr. Michael Stern',
    diagnosis_code: 'C20',
  },
  {
    fractions: 17,
    site: 'Red Hills',
    physician: 'Dr Adams',
    date: '2024-01-06',
    diagnosis: 'breast',
    fraction_dose: 3.1,
    adherence: true,
    technique: 'Brachytherapy',
    linac: 'Machine 1',
    sim_to_treat: 5,
    priority: 'Emergency',
    referrer: 'Dr. Sophie Greenberg',
    diagnosis_code: 'C34.90',
  },
  {
    fractions: 25,
    site: 'Rosebush',
    physician: 'Dr Wong',
    date: '2024-09-14',
    diagnosis: 'skin',
    fraction_dose: 2.4,
    adherence: true,
    technique: 'Stereotactic',
    linac: 'Machine 2',
    sim_to_treat: 7,
    priority: 'Standard',
    referrer: 'Dr. Michael Stern',
    diagnosis_code: 'C34.90',
  },
  {
    fractions: 15,
    site: 'Lakeside',
    physician: 'Dr Smith',
    date: '2024-05-08',
    diagnosis: 'skin',
    fraction_dose: 3.4,
    adherence: true,
    technique: 'Conformal',
    linac: 'Machine 3',
    sim_to_treat: 4,
    priority: 'Urgent',
    referrer: 'Dr. Julian Foster',
    diagnosis_code: 'C43.9',
  },

  {
    fractions: 22,
    site: 'Red Hills',
    physician: 'Dr Wong',
    date: '2024-10-25',
    diagnosis: 'skin',
    fraction_dose: 2.1,
    adherence: true,
    technique: 'Brachytherapy',
    linac: 'Machine 1',
    sim_to_treat: 3,
    priority: 'Emergency',
    referrer: 'Dr. Alex Reynolds',
    diagnosis_code: 'C43.9',
  },
  {
    fractions: 13,
    site: 'Red Hills',
    physician: 'Dr Smith',
    date: '2024-04-09',
    diagnosis: 'metastasis',
    fraction_dose: 2.2,
    adherence: true,
    technique: 'Brachytherapy',
    linac: 'Machine 2',
    sim_to_treat: 9,
    priority: 'Urgent',
    referrer: 'Dr. Alex Reynolds',
    diagnosis_code: 'C34.90',
  },
  {
    fractions: 23,
    site: 'Red Hills',
    physician: 'Dr Smith',
    date: '2024-05-27',
    diagnosis: 'prostate',
    fraction_dose: 1.9,
    adherence: true,
    technique: 'VMAT',
    linac: 'Machine 1',
    sim_to_treat: 3,
    priority: 'Urgent',
    referrer: 'Dr. Alex Reynolds',
    diagnosis_code: 'C71.9',
  },
  {
    fractions: 11,
    site: 'Red Hills',
    physician: 'Dr Wong',
    date: '2024-07-03',
    diagnosis: 'prostate',
    fraction_dose: 1.8,
    adherence: true,
    technique: 'Conformal',
    linac: 'Machine 1',
    sim_to_treat: 2,
    priority: 'Standard',
    referrer: 'Dr. Sophie Greenberg',
    diagnosis_code: 'C34.90',
  },
  {
    fractions: 22,
    site: 'Lakeside',
    physician: 'Dr Wong',
    date: '2024-05-28',
    diagnosis: 'skin',
    fraction_dose: 2.1,
    adherence: true,
    technique: 'IMRT',
    linac: 'Machine 2',
    sim_to_treat: 1,
    priority: 'Standard',
    referrer: 'Dr. Victor Hernandes',
    diagnosis_code: 'C34.90',
  },
  {
    fractions: 18,
    site: 'Red Hills',
    physician: 'Dr Smith',
    date: '2024-01-12',
    diagnosis: 'prostate',
    fraction_dose: 2.1,
    adherence: true,
    technique: 'Electrons',
    linac: 'Machine 4',
    sim_to_treat: 10,
    priority: 'Standard',
    referrer: 'Dr. Alex Reynolds',
    diagnosis_code: 'C71.9',
  },
  {
    fractions: 21,
    site: 'Campsie',
    physician: 'Dr Wong',
    date: '2024-06-02',
    diagnosis: 'breast',
    fraction_dose: 1.6,
    adherence: true,
    technique: 'VMAT',
    linac: 'Machine 1',
    sim_to_treat: 10,
    priority: 'Urgent',
    referrer: 'Dr. Aisha Patel',
    diagnosis_code: 'C43.9',
  },
  {
    fractions: 22,
    site: 'Red Hills',
    physician: 'Dr Lopez',
    date: '2024-07-15',
    diagnosis: 'metastasis',
    fraction_dose: 2.7,
    adherence: true,
    technique: 'Stereotactic',
    linac: 'Machine 3',
    sim_to_treat: 2,
    priority: 'Emergency',
    referrer: 'Dr. Victor Hernandes',
    diagnosis_code: 'C43.9',
  },
  {
    fractions: 11,
    site: 'Red Hills',
    physician: 'Dr Smith',
    date: '2024-06-13',
    diagnosis: 'breast',
    fraction_dose: 1.4,
    adherence: true,
    technique: 'VMAT',
    linac: 'Machine 1',
    sim_to_treat: 2,
    priority: 'Emergency',
    referrer: 'Dr. Alex Reynolds',
    diagnosis_code: 'C20',
  },
  {
    fractions: 24,
    site: 'Lakeside',
    physician: 'Dr Lopez',
    date: '2024-07-10',
    diagnosis: 'skin',
    fraction_dose: 2.7,
    adherence: true,
    technique: 'Conformal',
    linac: 'Machine 2',
    sim_to_treat: 10,
    priority: 'Emergency',
    referrer: 'Dr. Aisha Patel',
    diagnosis_code: 'C61',
  },
  {
    fractions: 22,
    site: 'Red Hills',
    physician: 'Dr Smith',
    date: '2024-10-31',
    diagnosis: 'neuro',
    fraction_dose: 2.8,
    adherence: true,
    technique: 'Electrons',
    linac: 'Machine 3',
    sim_to_treat: 2,
    priority: 'Urgent',
    referrer: 'Dr. Julian Foster',
    diagnosis_code: 'C71.9',
  },
  {
    fractions: 21,
    site: 'Lakeside',
    physician: 'Dr Adams',
    date: '2024-04-25',
    diagnosis: 'metastasis',
    fraction_dose: 3.0,
    adherence: true,
    technique: 'Conformal',
    linac: 'Machine 4',
    sim_to_treat: 3,
    priority: 'Emergency',
    referrer: 'Dr. Aisha Patel',
    diagnosis_code: 'C61',
  },
  {
    fractions: 10,
    site: 'Red Hills',
    physician: 'Dr Adams',
    date: '2024-10-04',
    diagnosis: 'colorectal',
    fraction_dose: 2.9,
    adherence: true,
    technique: 'Electrons',
    linac: 'Machine 3',
    sim_to_treat: 4,
    priority: 'Emergency',
    referrer: 'Dr. Alex Reynolds',
    diagnosis_code: 'C61',
  },
  {
    fractions: 18,
    site: 'Campsie',
    physician: 'Dr Smith',
    date: '2024-01-20',
    diagnosis: 'metastasis',
    fraction_dose: 1.2,
    adherence: true,
    technique: 'VMAT',
    linac: 'Machine 1',
    sim_to_treat: 6,
    priority: 'Urgent',
    referrer: 'Dr. Clara Thompson',
    diagnosis_code: 'C20',
  },
  {
    fractions: 21,
    site: 'Rosebush',
    physician: 'Dr Smith',
    date: '2024-01-15',
    diagnosis: 'metastasis',
    fraction_dose: 3.1,
    adherence: true,
    technique: 'Electrons',
    linac: 'Machine 2',
    sim_to_treat: 6,
    priority: 'Standard',
    referrer: 'Dr. Michael Stern',
    diagnosis_code: 'C43.9',
  },
  {
    fractions: 22,
    site: 'Campsie',
    physician: 'Dr Lopez',
    date: '2024-01-22',
    diagnosis: 'colorectal',
    fraction_dose: 2.8,
    adherence: true,
    technique: 'IMRT',
    linac: 'Machine 2',
    sim_to_treat: 9,
    priority: 'Urgent',
    referrer: 'Dr. Aisha Patel',
    diagnosis_code: 'C20',
  },
  {
    fractions: 16,
    site: 'Red Hills',
    physician: 'Dr Adams',
    date: '2024-07-06',
    diagnosis: 'prostate',
    fraction_dose: 3.2,
    adherence: true,
    technique: 'Electrons',
    linac: 'Machine 3',
    sim_to_treat: 8,
    priority: 'Emergency',
    referrer: 'Dr. Clara Thompson',
    diagnosis_code: 'C71.9',
  },
  {
    fractions: 17,
    site: 'Campsie',
    physician: 'Dr Lopez',
    date: '2024-04-04',
    diagnosis: 'colorectal',
    fraction_dose: 2.9,
    adherence: true,
    technique: 'Stereotactic',
    linac: 'Machine 2',
    sim_to_treat: 4,
    priority: 'Urgent',
    referrer: 'Dr. Julian Foster',
    diagnosis_code: 'C79.81',
  },
  {
    fractions: 14,
    site: 'Red Hills',
    physician: 'Dr Adams',
    date: '2024-03-18',
    diagnosis: 'neuro',
    fraction_dose: 3.3,
    adherence: true,
    technique: 'VMAT',
    linac: 'Machine 1',
    sim_to_treat: 2,
    priority: 'Emergency',
    referrer: 'Dr. Julian Foster',
    diagnosis_code: 'C71.9',
  },
  {
    fractions: 19,
    site: 'Rosebush',
    physician: 'Dr Adams',
    date: '2024-10-04',
    diagnosis: 'prostate',
    fraction_dose: 3.3,
    adherence: true,
    technique: 'Electrons',
    linac: 'Machine 4',
    sim_to_treat: 9,
    priority: 'Urgent',
    referrer: 'Dr. Alex Reynolds',
    diagnosis_code: 'C79.81',
  },
  {
    fractions: 21,
    site: 'Lakeside',
    physician: 'Dr Adams',
    date: '2024-10-24',
    diagnosis: 'neuro',
    fraction_dose: 1.2,
    adherence: true,
    technique: 'IMRT',
    linac: 'Machine 3',
    sim_to_treat: 10,
    priority: 'Standard',
    referrer: 'Dr. Jonathan Blake',
    diagnosis_code: 'C20',
  },
  {
    fractions: 23,
    site: 'Rosebush',
    physician: 'Dr Lopez',
    date: '2024-09-01',
    diagnosis: 'metastasis',
    fraction_dose: 1.4,
    adherence: false,
    technique: 'Conformal',
    linac: 'Machine 1',
    sim_to_treat: 7,
    priority: 'Standard',
    referrer: 'Dr. Julian Foster',
    diagnosis_code: 'C79.81',
  },
  {
    fractions: 12,
    site: 'Lakeside',
    physician: 'Dr Wong',
    date: '2024-01-12',
    diagnosis: 'neuro',
    fraction_dose: 1.8,
    adherence: true,
    technique: 'IMRT',
    linac: 'Machine 4',
    sim_to_treat: 1,
    priority: 'Urgent',
    referrer: 'Dr. Alex Reynolds',
    diagnosis_code: 'C71.9',
  },
  {
    fractions: 24,
    site: 'Rosebush',
    physician: 'Dr Wong',
    date: '2024-10-16',
    diagnosis: 'metastasis',
    fraction_dose: 2.5,
    adherence: true,
    technique: 'Conformal',
    linac: 'Machine 1',
    sim_to_treat: 1,
    priority: 'Standard',
    referrer: 'Dr. Aisha Patel',
    diagnosis_code: 'C61',
  },
  {
    fractions: 16,
    site: 'Red Hills',
    physician: 'Dr Smith',
    date: '2024-03-30',
    diagnosis: 'colorectal',
    fraction_dose: 2.4,
    adherence: false,
    technique: 'Conformal',
    linac: 'Machine 4',
    sim_to_treat: 8,
    priority: 'Urgent',
    referrer: 'Dr. Sophie Greenberg',
    diagnosis_code: 'C34.90',
  },
  {
    fractions: 16,
    site: 'Lakeside',
    physician: 'Dr Adams',
    date: '2024-09-25',
    diagnosis: 'prostate',
    fraction_dose: 2.9,
    adherence: false,
    technique: 'Electrons',
    linac: 'Machine 1',
    sim_to_treat: 6,
    priority: 'Urgent',
    referrer: 'Dr. Rachel Nguyen',
    diagnosis_code: 'C20',
  },
  {
    fractions: 18,
    site: 'Rosebush',
    physician: 'Dr Adams',
    date: '2024-08-19',
    diagnosis: 'neuro',
    fraction_dose: 3.4,
    adherence: false,
    technique: 'Stereotactic',
    linac: 'Machine 1',
    sim_to_treat: 8,
    priority: 'Standard',
    referrer: 'Dr. Jonathan Blake',
    diagnosis_code: 'C61',
  },
  {
    fractions: 21,
    site: 'Red Hills',
    physician: 'Dr Wong',
    date: '2024-02-12',
    diagnosis: 'breast',
    fraction_dose: 2.7,
    adherence: true,
    technique: 'Conformal',
    linac: 'Machine 1',
    sim_to_treat: 1,
    priority: 'Urgent',
    referrer: 'Dr. Sophie Greenberg',
    diagnosis_code: 'C20',
  },
  {
    fractions: 17,
    site: 'Red Hills',
    physician: 'Dr Smith',
    date: '2024-10-06',
    diagnosis: 'breast',
    fraction_dose: 3.3,
    adherence: true,
    technique: 'IMRT',
    linac: 'Machine 2',
    sim_to_treat: 1,
    priority: 'Standard',
    referrer: 'Dr. Victor Hernandes',
    diagnosis_code: 'C20',
  },
  {
    fractions: 19,
    site: 'Red Hills',
    physician: 'Dr Lopez',
    date: '2024-06-20',
    diagnosis: 'metastasis',
    fraction_dose: 2.6,
    adherence: true,
    technique: 'Stereotactic',
    linac: 'Machine 1',
    sim_to_treat: 7,
    priority: 'Urgent',
    referrer: 'Dr. Aisha Patel',
    diagnosis_code: 'C71.9',
  },
  {
    fractions: 18,
    site: 'Rosebush',
    physician: 'Dr Lopez',
    date: '2024-09-18',
    diagnosis: 'prostate',
    fraction_dose: 2.0,
    adherence: true,
    technique: 'IMRT',
    linac: 'Machine 4',
    sim_to_treat: 10,
    priority: 'Urgent',
    referrer: 'Dr. Michael Stern',
    diagnosis_code: 'C79.81',
  },
  {
    fractions: 24,
    site: 'Rosebush',
    physician: 'Dr Smith',
    date: '2024-04-07',
    diagnosis: 'prostate',
    fraction_dose: 2.3,
    adherence: true,
    technique: 'Brachytherapy',
    linac: 'Machine 4',
    sim_to_treat: 6,
    priority: 'Standard',
    referrer: 'Dr. Rachel Nguyen',
    diagnosis_code: 'C61',
  },
  {
    fractions: 11,
    site: 'Red Hills',
    physician: 'Dr Lopez',
    date: '2024-02-05',
    diagnosis: 'colorectal',
    fraction_dose: 1.4,
    adherence: true,
    technique: 'Electrons',
    linac: 'Machine 2',
    sim_to_treat: 6,
    priority: 'Urgent',
    referrer: 'Dr. Alex Reynolds',
    diagnosis_code: 'C79.81',
  },
  {
    fractions: 24,
    site: 'Rosebush',
    physician: 'Dr Wong',
    date: '2024-01-14',
    diagnosis: 'neuro',
    fraction_dose: 2.0,
    adherence: true,
    technique: 'Electrons',
    linac: 'Machine 4',
    sim_to_treat: 1,
    priority: 'Emergency',
    referrer: 'Dr. Rachel Nguyen',
    diagnosis_code: 'C71.9',
  },
  {
    fractions: 13,
    site: 'Red Hills',
    physician: 'Dr Lopez',
    date: '2024-03-23',
    diagnosis: 'breast',
    fraction_dose: 3.0,
    adherence: true,
    technique: 'Brachytherapy',
    linac: 'Machine 1',
    sim_to_treat: 2,
    priority: 'Urgent',
    referrer: 'Dr. Michael Stern',
    diagnosis_code: 'C20',
  },
  {
    fractions: 16,
    site: 'Red Hills',
    physician: 'Dr Lopez',
    date: '2024-09-29',
    diagnosis: 'metastasis',
    fraction_dose: 1.7,
    adherence: true,
    technique: 'Brachytherapy',
    linac: 'Machine 3',
    sim_to_treat: 5,
    priority: 'Standard',
    referrer: 'Dr. Rachel Nguyen',
    diagnosis_code: 'C20',
  },
  {
    fractions: 21,
    site: 'Rosebush',
    physician: 'Dr Wong',
    date: '2024-02-14',
    diagnosis: 'breast',
    fraction_dose: 2.6,
    adherence: true,
    technique: 'Electrons',
    linac: 'Machine 4',
    sim_to_treat: 9,
    priority: 'Emergency',
    referrer: 'Dr. Jonathan Blake',
    diagnosis_code: 'C79.81',
  },
  {
    fractions: 14,
    site: 'Rosebush',
    physician: 'Dr Smith',
    date: '2024-09-17',
    diagnosis: 'metastasis',
    fraction_dose: 2.8,
    adherence: true,
    technique: 'Electrons',
    linac: 'Machine 2',
    sim_to_treat: 2,
    priority: 'Emergency',
    referrer: 'Dr. Michael Stern',
    diagnosis_code: 'C71.9',
  },
  {
    fractions: 10,
    site: 'Campsie',
    physician: 'Dr Adams',
    date: '2024-02-12',
    diagnosis: 'skin',
    fraction_dose: 1.6,
    adherence: true,
    technique: 'Brachytherapy',
    linac: 'Machine 3',
    sim_to_treat: 7,
    priority: 'Emergency',
    referrer: 'Dr. Aisha Patel',
    diagnosis_code: 'C79.81',
  },
  {
    fractions: 21,
    site: 'Red Hills',
    physician: 'Dr Lopez',
    date: '2024-05-01',
    diagnosis: 'neuro',
    fraction_dose: 2.4,
    adherence: true,
    technique: 'Conformal',
    linac: 'Machine 4',
    sim_to_treat: 8,
    priority: 'Urgent',
    referrer: 'Dr. Julian Foster',
    diagnosis_code: 'C34.90',
  },
  {
    fractions: 15,
    site: 'Lakeside',
    physician: 'Dr Adams',
    date: '2024-05-15',
    diagnosis: 'neuro',
    fraction_dose: 2.3,
    adherence: true,
    technique: 'IMRT',
    linac: 'Machine 3',
    sim_to_treat: 3,
    priority: 'Urgent',
    referrer: 'Dr. Jonathan Blake',
    diagnosis_code: 'C61',
  },
  {
    fractions: 17,
    site: 'Campsie',
    physician: 'Dr Adams',
    date: '2024-01-10',
    diagnosis: 'colorectal',
    fraction_dose: 1.5,
    adherence: true,
    technique: 'Conformal',
    linac: 'Machine 2',
    sim_to_treat: 6,
    priority: 'Emergency',
    referrer: 'Dr. Michael Stern',
    diagnosis_code: 'C79.81',
  },
  {
    fractions: 23,
    site: 'Campsie',
    physician: 'Dr Adams',
    date: '2024-09-14',
    diagnosis: 'skin',
    fraction_dose: 1.5,
    adherence: true,
    technique: 'Conformal',
    linac: 'Machine 4',
    sim_to_treat: 8,
    priority: 'Emergency',
    referrer: 'Dr. Aisha Patel',
    diagnosis_code: 'C71.9',
  },
  {
    fractions: 17,
    site: 'Rosebush',
    physician: 'Dr Wong',
    date: '2024-09-30',
    diagnosis: 'metastasis',
    fraction_dose: 2.1,
    adherence: true,
    technique: 'Stereotactic',
    linac: 'Machine 3',
    sim_to_treat: 6,
    priority: 'Standard',
    referrer: 'Dr. Clara Thompson',
    diagnosis_code: 'C79.81',
  },
  {
    fractions: 18,
    site: 'Red Hills',
    physician: 'Dr Lopez',
    date: '2024-07-07',
    diagnosis: 'prostate',
    fraction_dose: 1.3,
    adherence: true,
    technique: 'Stereotactic',
    linac: 'Machine 4',
    sim_to_treat: 7,
    priority: 'Urgent',
    referrer: 'Dr. Julian Foster',
    diagnosis_code: 'C71.9',
  },
  {
    fractions: 17,
    site: 'Red Hills',
    physician: 'Dr Wong',
    date: '2024-04-14',
    diagnosis: 'metastasis',
    fraction_dose: 2.3,
    adherence: true,
    technique: 'Brachytherapy',
    linac: 'Machine 2',
    sim_to_treat: 8,
    priority: 'Emergency',
    referrer: 'Dr. Julian Foster',
    diagnosis_code: 'C43.9',
  },
  {
    fractions: 25,
    site: 'Campsie',
    physician: 'Dr Adams',
    date: '2024-09-16',
    diagnosis: 'neuro',
    fraction_dose: 1.3,
    adherence: true,
    technique: 'Conformal',
    linac: 'Machine 4',
    sim_to_treat: 5,
    priority: 'Standard',
    referrer: 'Dr. Clara Thompson',
    diagnosis_code: 'C34.90',
  },
  {
    fractions: 19,
    site: 'Lakeside',
    physician: 'Dr Adams',
    date: '2024-09-19',
    diagnosis: 'colorectal',
    fraction_dose: 1.3,
    adherence: true,
    technique: 'IMRT',
    linac: 'Machine 3',
    sim_to_treat: 2,
    priority: 'Emergency',
    referrer: 'Dr. Sophie Greenberg',
    diagnosis_code: 'C20',
  },
  {
    fractions: 22,
    site: 'Lakeside',
    physician: 'Dr Wong',
    date: '2024-01-07',
    diagnosis: 'skin',
    fraction_dose: 1.8,
    adherence: true,
    technique: 'Brachytherapy',
    linac: 'Machine 2',
    sim_to_treat: 5,
    priority: 'Standard',
    referrer: 'Dr. Julian Foster',
    diagnosis_code: 'C61',
  },
  {
    fractions: 21,
    site: 'Campsie',
    physician: 'Dr Wong',
    date: '2024-09-15',
    diagnosis: 'skin',
    fraction_dose: 2.3,
    adherence: true,
    technique: 'IMRT',
    linac: 'Machine 2',
    sim_to_treat: 8,
    priority: 'Emergency',
    referrer: 'Dr. Julian Foster',
    diagnosis_code: 'C34.90',
  },
  {
    fractions: 25,
    site: 'Lakeside',
    physician: 'Dr Wong',
    date: '2024-09-13',
    diagnosis: 'colorectal',
    fraction_dose: 2.8,
    adherence: false,
    technique: 'Stereotactic',
    linac: 'Machine 4',
    sim_to_treat: 3,
    priority: 'Standard',
    referrer: 'Dr. Clara Thompson',
    diagnosis_code: 'C79.81',
  },
  {
    fractions: 19,
    site: 'Campsie',
    physician: 'Dr Lopez',
    date: '2024-02-15',
    diagnosis: 'colorectal',
    fraction_dose: 1.9,
    adherence: true,
    technique: 'Brachytherapy',
    linac: 'Machine 3',
    sim_to_treat: 8,
    priority: 'Emergency',
    referrer: 'Dr. Jonathan Blake',
    diagnosis_code: 'C34.90',
  },
  {
    fractions: 16,
    site: 'Red Hills',
    physician: 'Dr Wong',
    date: '2024-01-22',
    diagnosis: 'metastasis',
    fraction_dose: 2.6,
    adherence: true,
    technique: 'Brachytherapy',
    linac: 'Machine 1',
    sim_to_treat: 2,
    priority: 'Emergency',
    referrer: 'Dr. Rachel Nguyen',
    diagnosis_code: 'C20',
  },
  {
    fractions: 20,
    site: 'Lakeside',
    physician: 'Dr Wong',
    date: '2024-01-24',
    diagnosis: 'skin',
    fraction_dose: 1.5,
    adherence: true,
    technique: 'Stereotactic',
    linac: 'Machine 1',
    sim_to_treat: 8,
    priority: 'Standard',
    referrer: 'Dr. Victor Hernandes',
    diagnosis_code: 'C79.81',
  },
  {
    fractions: 25,
    site: 'Lakeside',
    physician: 'Dr Lopez',
    date: '2024-07-23',
    diagnosis: 'metastasis',
    fraction_dose: 1.9,
    adherence: true,
    technique: 'Brachytherapy',
    linac: 'Machine 2',
    sim_to_treat: 5,
    priority: 'Urgent',
    referrer: 'Dr. Emily Hartman',
    diagnosis_code: 'C79.81',
  },
  {
    fractions: 12,
    site: 'Red Hills',
    physician: 'Dr Adams',
    date: '2024-03-18',
    diagnosis: 'breast',
    fraction_dose: 2.5,
    adherence: true,
    technique: 'Brachytherapy',
    linac: 'Machine 4',
    sim_to_treat: 10,
    priority: 'Standard',
    referrer: 'Dr. Michael Stern',
    diagnosis_code: 'C61',
  },
  {
    fractions: 12,
    site: 'Red Hills',
    physician: 'Dr Smith',
    date: '2024-08-12',
    diagnosis: 'metastasis',
    fraction_dose: 3.3,
    adherence: false,
    technique: 'Brachytherapy',
    linac: 'Machine 1',
    sim_to_treat: 1,
    priority: 'Standard',
    referrer: 'Dr. Rachel Nguyen',
    diagnosis_code: 'C43.9',
  },
  {
    fractions: 23,
    site: 'Campsie',
    physician: 'Dr Smith',
    date: '2024-07-08',
    diagnosis: 'metastasis',
    fraction_dose: 2.0,
    adherence: true,
    technique: 'Stereotactic',
    linac: 'Machine 4',
    sim_to_treat: 8,
    priority: 'Standard',
    referrer: 'Dr. Jonathan Blake',
    diagnosis_code: 'C20',
  },
  {
    fractions: 12,
    site: 'Campsie',
    physician: 'Dr Lopez',
    date: '2024-05-21',
    diagnosis: 'skin',
    fraction_dose: 2.2,
    adherence: true,
    technique: 'Conformal',
    linac: 'Machine 1',
    sim_to_treat: 8,
    priority: 'Emergency',
    referrer: 'Dr. Alex Reynolds',
    diagnosis_code: 'C79.81',
  },
  {
    fractions: 10,
    site: 'Rosebush',
    physician: 'Dr Lopez',
    date: '2024-08-13',
    diagnosis: 'neuro',
    fraction_dose: 1.8,
    adherence: true,
    technique: 'Stereotactic',
    linac: 'Machine 3',
    sim_to_treat: 4,
    priority: 'Standard',
    referrer: 'Dr. Alex Reynolds',
    diagnosis_code: 'C43.9',
  },
  {
    fractions: 12,
    site: 'Red Hills',
    physician: 'Dr Lopez',
    date: '2024-08-11',
    diagnosis: 'colorectal',
    fraction_dose: 1.6,
    adherence: true,
    technique: 'Brachytherapy',
    linac: 'Machine 1',
    sim_to_treat: 7,
    priority: 'Standard',
    referrer: 'Dr. Victor Hernandes',
    diagnosis_code: 'C71.9',
  },
  {
    fractions: 18,
    site: 'Campsie',
    physician: 'Dr Lopez',
    date: '2024-08-08',
    diagnosis: 'colorectal',
    fraction_dose: 2.3,
    adherence: true,
    technique: 'Conformal',
    linac: 'Machine 3',
    sim_to_treat: 9,
    priority: 'Urgent',
    referrer: 'Dr. Jonathan Blake',
    diagnosis_code: 'C79.81',
  },
  {
    fractions: 14,
    site: 'Campsie',
    physician: 'Dr Lopez',
    date: '2024-01-21',
    diagnosis: 'prostate',
    fraction_dose: 2.8,
    adherence: true,
    technique: 'IMRT',
    linac: 'Machine 3',
    sim_to_treat: 4,
    priority: 'Urgent',
    referrer: 'Dr. Clara Thompson',
    diagnosis_code: 'C43.9',
  },
  {
    fractions: 16,
    site: 'Rosebush',
    physician: 'Dr Smith',
    date: '2024-08-02',
    diagnosis: 'breast',
    fraction_dose: 1.4,
    adherence: true,
    technique: 'Conformal',
    linac: 'Machine 4',
    sim_to_treat: 5,
    priority: 'Standard',
    referrer: 'Dr. Julian Foster',
    diagnosis_code: 'C20',
  },
  {
    fractions: 21,
    site: 'Rosebush',
    physician: 'Dr Adams',
    date: '2024-04-26',
    diagnosis: 'breast',
    fraction_dose: 1.9,
    adherence: true,
    technique: 'Electrons',
    linac: 'Machine 4',
    sim_to_treat: 7,
    priority: 'Urgent',
    referrer: 'Dr. Jonathan Blake',
    diagnosis_code: 'C61',
  },
  {
    fractions: 12,
    site: 'Lakeside',
    physician: 'Dr Smith',
    date: '2024-07-20',
    diagnosis: 'breast',
    fraction_dose: 3.3,
    adherence: true,
    technique: 'Electrons',
    linac: 'Machine 2',
    sim_to_treat: 1,
    priority: 'Emergency',
    referrer: 'Dr. Rachel Nguyen',
    diagnosis_code: 'C34.90',
  },
  {
    fractions: 17,
    site: 'Red Hills',
    physician: 'Dr Wong',
    date: '2024-03-23',
    diagnosis: 'colorectal',
    fraction_dose: 2.1,
    adherence: true,
    technique: 'Electrons',
    linac: 'Machine 2',
    sim_to_treat: 3,
    priority: 'Urgent',
    referrer: 'Dr. Jonathan Blake',
    diagnosis_code: 'C20',
  },
  {
    fractions: 11,
    site: 'Red Hills',
    physician: 'Dr Lopez',
    date: '2024-08-24',
    diagnosis: 'neuro',
    fraction_dose: 3.2,
    adherence: true,
    technique: 'Conformal',
    linac: 'Machine 2',
    sim_to_treat: 9,
    priority: 'Urgent',
    referrer: 'Dr. Victor Hernandes',
    diagnosis_code: 'C71.9',
  },
  {
    fractions: 21,
    site: 'Rosebush',
    physician: 'Dr Lopez',
    date: '2024-04-14',
    diagnosis: 'colorectal',
    fraction_dose: 2.8,
    adherence: true,
    technique: 'Electrons',
    linac: 'Machine 4',
    sim_to_treat: 3,
    priority: 'Emergency',
    referrer: 'Dr. Rachel Nguyen',
    diagnosis_code: 'C20',
  },
  {
    fractions: 22,
    site: 'Campsie',
    physician: 'Dr Smith',
    date: '2024-09-04',
    diagnosis: 'breast',
    fraction_dose: 2.9,
    adherence: true,
    technique: 'VMAT',
    linac: 'Machine 1',
    sim_to_treat: 6,
    priority: 'Urgent',
    referrer: 'Dr. Alex Reynolds',
    diagnosis_code: 'C20',
  },
  {
    fractions: 19,
    site: 'Lakeside',
    physician: 'Dr Wong',
    date: '2024-10-05',
    diagnosis: 'prostate',
    fraction_dose: 3.1,
    adherence: true,
    technique: 'Brachytherapy',
    linac: 'Machine 4',
    sim_to_treat: 5,
    priority: 'Standard',
    referrer: 'Dr. Michael Stern',
    diagnosis_code: 'C71.9',
  },
  {
    fractions: 25,
    site: 'Lakeside',
    physician: 'Dr Lopez',
    date: '2024-08-15',
    diagnosis: 'neuro',
    fraction_dose: 1.3,
    adherence: true,
    technique: 'IMRT',
    linac: 'Machine 2',
    sim_to_treat: 9,
    priority: 'Emergency',
    referrer: 'Dr. Julian Foster',
    diagnosis_code: 'C34.90',
  },
  {
    fractions: 14,
    site: 'Campsie',
    physician: 'Dr Smith',
    date: '2024-06-22',
    diagnosis: 'colorectal',
    fraction_dose: 3.4,
    adherence: true,
    technique: 'VMAT',
    linac: 'Machine 2',
    sim_to_treat: 5,
    priority: 'Urgent',
    referrer: 'Dr. Victor Hernandes',
    diagnosis_code: 'C20',
  },
  {
    fractions: 15,
    site: 'Red Hills',
    physician: 'Dr Adams',
    date: '2024-09-25',
    diagnosis: 'colorectal',
    fraction_dose: 2.1,
    adherence: true,
    technique: 'Electrons',
    linac: 'Machine 4',
    sim_to_treat: 5,
    priority: 'Emergency',
    referrer: 'Dr. Emily Hartman',
    diagnosis_code: 'C71.9',
  },
  {
    fractions: 17,
    site: 'Lakeside',
    physician: 'Dr Wong',
    date: '2024-05-26',
    diagnosis: 'colorectal',
    fraction_dose: 3.0,
    adherence: true,
    technique: 'Stereotactic',
    linac: 'Machine 1',
    sim_to_treat: 8,
    priority: 'Emergency',
    referrer: 'Dr. Rachel Nguyen',
    diagnosis_code: 'C79.81',
  },
  {
    fractions: 17,
    site: 'Campsie',
    physician: 'Dr Wong',
    date: '2024-08-04',
    diagnosis: 'neuro',
    fraction_dose: 2.7,
    adherence: true,
    technique: 'VMAT',
    linac: 'Machine 4',
    sim_to_treat: 8,
    priority: 'Urgent',
    referrer: 'Dr. Victor Hernandes',
    diagnosis_code: 'C79.81',
  },
  {
    fractions: 21,
    site: 'Rosebush',
    physician: 'Dr Adams',
    date: '2024-08-29',
    diagnosis: 'skin',
    fraction_dose: 2.4,
    adherence: true,
    technique: 'VMAT',
    linac: 'Machine 4',
    sim_to_treat: 5,
    priority: 'Urgent',
    referrer: 'Dr. Rachel Nguyen',
    diagnosis_code: 'C20',
  },
  {
    fractions: 13,
    site: 'Campsie',
    physician: 'Dr Wong',
    date: '2024-09-06',
    diagnosis: 'breast',
    fraction_dose: 2.7,
    adherence: true,
    technique: 'Brachytherapy',
    linac: 'Machine 2',
    sim_to_treat: 3,
    priority: 'Standard',
    referrer: 'Dr. Sophie Greenberg',
    diagnosis_code: 'C43.9',
  },
  {
    fractions: 24,
    site: 'Campsie',
    physician: 'Dr Lopez',
    date: '2024-08-31',
    diagnosis: 'colorectal',
    fraction_dose: 1.6,
    adherence: true,
    technique: 'Electrons',
    linac: 'Machine 3',
    sim_to_treat: 4,
    priority: 'Standard',
    referrer: 'Dr. Alex Reynolds',
    diagnosis_code: 'C43.9',
  },
  {
    fractions: 24,
    site: 'Red Hills',
    physician: 'Dr Adams',
    date: '2024-07-20',
    diagnosis: 'prostate',
    fraction_dose: 1.7,
    adherence: true,
    technique: 'Electrons',
    linac: 'Machine 2',
    sim_to_treat: 10,
    priority: 'Urgent',
    referrer: 'Dr. Jonathan Blake',
    diagnosis_code: 'C71.9',
  },
  {
    fractions: 13,
    site: 'Rosebush',
    physician: 'Dr Wong',
    date: '2024-07-01',
    diagnosis: 'colorectal',
    fraction_dose: 2.3,
    adherence: true,
    technique: 'Conformal',
    linac: 'Machine 2',
    sim_to_treat: 10,
    priority: 'Urgent',
    referrer: 'Dr. Michael Stern',
    diagnosis_code: 'C20',
  },
  {
    fractions: 22,
    site: 'Lakeside',
    physician: 'Dr Wong',
    date: '2024-03-10',
    diagnosis: 'breast',
    fraction_dose: 3.3,
    adherence: true,
    technique: 'Stereotactic',
    linac: 'Machine 2',
    sim_to_treat: 2,
    priority: 'Standard',
    referrer: 'Dr. Michael Stern',
    diagnosis_code: 'C20',
  },
  {
    fractions: 20,
    site: 'Campsie',
    physician: 'Dr Adams',
    date: '2024-08-07',
    diagnosis: 'metastasis',
    fraction_dose: 3.4,
    adherence: true,
    technique: 'Electrons',
    linac: 'Machine 1',
    sim_to_treat: 3,
    priority: 'Emergency',
    referrer: 'Dr. Alex Reynolds',
    diagnosis_code: 'C34.90',
  },
  {
    fractions: 14,
    site: 'Rosebush',
    physician: 'Dr Wong',
    date: '2024-08-16',
    diagnosis: 'neuro',
    fraction_dose: 3.1,
    adherence: true,
    technique: 'Stereotactic',
    linac: 'Machine 2',
    sim_to_treat: 9,
    priority: 'Urgent',
    referrer: 'Dr. Clara Thompson',
    diagnosis_code: 'C20',
  },
  {
    fractions: 11,
    site: 'Red Hills',
    physician: 'Dr Lopez',
    date: '2024-08-23',
    diagnosis: 'prostate',
    fraction_dose: 3.4,
    adherence: true,
    technique: 'Conformal',
    linac: 'Machine 3',
    sim_to_treat: 1,
    priority: 'Standard',
    referrer: 'Dr. Michael Stern',
    diagnosis_code: 'C61',
  },
  {
    fractions: 19,
    site: 'Red Hills',
    physician: 'Dr Lopez',
    date: '2024-04-30',
    diagnosis: 'colorectal',
    fraction_dose: 1.8,
    adherence: true,
    technique: 'Conformal',
    linac: 'Machine 1',
    sim_to_treat: 1,
    priority: 'Urgent',
    referrer: 'Dr. Michael Stern',
    diagnosis_code: 'C34.90',
  },
  {
    fractions: 17,
    site: 'Red Hills',
    physician: 'Dr Wong',
    date: '2024-07-12',
    diagnosis: 'colorectal',
    fraction_dose: 3.0,
    adherence: true,
    technique: 'VMAT',
    linac: 'Machine 1',
    sim_to_treat: 10,
    priority: 'Emergency',
    referrer: 'Dr. Alex Reynolds',
    diagnosis_code: 'C20',
  },
  {
    fractions: 11,
    site: 'Lakeside',
    physician: 'Dr Smith',
    date: '2024-02-05',
    diagnosis: 'colorectal',
    fraction_dose: 2.7,
    adherence: true,
    technique: 'Conformal',
    linac: 'Machine 3',
    sim_to_treat: 8,
    priority: 'Urgent',
    referrer: 'Dr. Julian Foster',
    diagnosis_code: 'C61',
  },
  {
    fractions: 10,
    site: 'Rosebush',
    physician: 'Dr Smith',
    date: '2024-06-11',
    diagnosis: 'prostate',
    fraction_dose: 1.8,
    adherence: true,
    technique: 'IMRT',
    linac: 'Machine 2',
    sim_to_treat: 4,
    priority: 'Emergency',
    referrer: 'Dr. Alex Reynolds',
    diagnosis_code: 'C43.9',
  },
  {
    fractions: 22,
    site: 'Campsie',
    physician: 'Dr Wong',
    date: '2024-03-02',
    diagnosis: 'skin',
    fraction_dose: 1.4,
    adherence: true,
    technique: 'Electrons',
    linac: 'Machine 1',
    sim_to_treat: 7,
    priority: 'Emergency',
    referrer: 'Dr. Clara Thompson',
    diagnosis_code: 'C34.90',
  },
  {
    fractions: 16,
    site: 'Red Hills',
    physician: 'Dr Lopez',
    date: '2024-08-05',
    diagnosis: 'colorectal',
    fraction_dose: 1.6,
    adherence: true,
    technique: 'Brachytherapy',
    linac: 'Machine 4',
    sim_to_treat: 6,
    priority: 'Emergency',
    referrer: 'Dr. Sophie Greenberg',
    diagnosis_code: 'C71.9',
  },
  {
    fractions: 17,
    site: 'Lakeside',
    physician: 'Dr Lopez',
    date: '2024-10-22',
    diagnosis: 'colorectal',
    fraction_dose: 2.8,
    adherence: true,
    technique: 'Stereotactic',
    linac: 'Machine 4',
    sim_to_treat: 9,
    priority: 'Standard',
    referrer: 'Dr. Alex Reynolds',
    diagnosis_code: 'C79.81',
  },
  {
    fractions: 15,
    site: 'Lakeside',
    physician: 'Dr Lopez',
    date: '2024-05-07',
    diagnosis: 'prostate',
    fraction_dose: 2.9,
    adherence: true,
    technique: 'Conformal',
    linac: 'Machine 4',
    sim_to_treat: 4,
    priority: 'Urgent',
    referrer: 'Dr. Aisha Patel',
    diagnosis_code: 'C79.81',
  },
  {
    fractions: 24,
    site: 'Lakeside',
    physician: 'Dr Lopez',
    date: '2024-05-27',
    diagnosis: 'neuro',
    fraction_dose: 2.2,
    adherence: true,
    technique: 'Brachytherapy',
    linac: 'Machine 2',
    sim_to_treat: 9,
    priority: 'Urgent',
    referrer: 'Dr. Sophie Greenberg',
    diagnosis_code: 'C79.81',
  },
  {
    fractions: 19,
    site: 'Red Hills',
    physician: 'Dr Lopez',
    date: '2024-07-05',
    diagnosis: 'metastasis',
    fraction_dose: 2.6,
    adherence: true,
    technique: 'Electrons',
    linac: 'Machine 3',
    sim_to_treat: 9,
    priority: 'Urgent',
    referrer: 'Dr. Julian Foster',
    diagnosis_code: 'C61',
  },
  {
    fractions: 24,
    site: 'Lakeside',
    physician: 'Dr Adams',
    date: '2024-03-10',
    diagnosis: 'breast',
    fraction_dose: 3.2,
    adherence: true,
    technique: 'Electrons',
    linac: 'Machine 3',
    sim_to_treat: 10,
    priority: 'Urgent',
    referrer: 'Dr. Rachel Nguyen',
    diagnosis_code: 'C71.9',
  },
  {
    fractions: 10,
    site: 'Red Hills',
    physician: 'Dr Adams',
    date: '2024-02-05',
    diagnosis: 'neuro',
    fraction_dose: 2.7,
    adherence: false,
    technique: 'Electrons',
    linac: 'Machine 1',
    sim_to_treat: 1,
    priority: 'Emergency',
    referrer: 'Dr. Michael Stern',
    diagnosis_code: 'C20',
  },
  {
    fractions: 17,
    site: 'Red Hills',
    physician: 'Dr Adams',
    date: '2024-01-06',
    diagnosis: 'breast',
    fraction_dose: 3.1,
    adherence: true,
    technique: 'Brachytherapy',
    linac: 'Machine 1',
    sim_to_treat: 5,
    priority: 'Emergency',
    referrer: 'Dr. Sophie Greenberg',
    diagnosis_code: 'C34.90',
  },
  {
    fractions: 25,
    site: 'Rosebush',
    physician: 'Dr Wong',
    date: '2024-09-14',
    diagnosis: 'skin',
    fraction_dose: 2.4,
    adherence: true,
    technique: 'Stereotactic',
    linac: 'Machine 2',
    sim_to_treat: 7,
    priority: 'Standard',
    referrer: 'Dr. Michael Stern',
    diagnosis_code: 'C34.90',
  },
  {
    fractions: 15,
    site: 'Lakeside',
    physician: 'Dr Smith',
    date: '2024-05-08',
    diagnosis: 'skin',
    fraction_dose: 3.4,
    adherence: true,
    technique: 'Conformal',
    linac: 'Machine 3',
    sim_to_treat: 4,
    priority: 'Urgent',
    referrer: 'Dr. Julian Foster',
    diagnosis_code: 'C43.9',
  },

  {
    fractions: 22,
    site: 'Red Hills',
    physician: 'Dr Wong',
    date: '2024-10-25',
    diagnosis: 'skin',
    fraction_dose: 2.1,
    adherence: true,
    technique: 'Brachytherapy',
    linac: 'Machine 1',
    sim_to_treat: 3,
    priority: 'Emergency',
    referrer: 'Dr. Alex Reynolds',
    diagnosis_code: 'C43.9',
  },
  {
    fractions: 13,
    site: 'Red Hills',
    physician: 'Dr Smith',
    date: '2024-04-09',
    diagnosis: 'metastasis',
    fraction_dose: 2.2,
    adherence: true,
    technique: 'Brachytherapy',
    linac: 'Machine 2',
    sim_to_treat: 9,
    priority: 'Urgent',
    referrer: 'Dr. Alex Reynolds',
    diagnosis_code: 'C34.90',
  },
  {
    fractions: 23,
    site: 'Red Hills',
    physician: 'Dr Smith',
    date: '2024-05-27',
    diagnosis: 'prostate',
    fraction_dose: 1.9,
    adherence: true,
    technique: 'VMAT',
    linac: 'Machine 1',
    sim_to_treat: 3,
    priority: 'Urgent',
    referrer: 'Dr. Alex Reynolds',
    diagnosis_code: 'C71.9',
  },
  {
    fractions: 11,
    site: 'Red Hills',
    physician: 'Dr Wong',
    date: '2024-07-03',
    diagnosis: 'prostate',
    fraction_dose: 1.8,
    adherence: true,
    technique: 'Conformal',
    linac: 'Machine 1',
    sim_to_treat: 2,
    priority: 'Standard',
    referrer: 'Dr. Sophie Greenberg',
    diagnosis_code: 'C34.90',
  },
  {
    fractions: 22,
    site: 'Lakeside',
    physician: 'Dr Wong',
    date: '2024-05-28',
    diagnosis: 'skin',
    fraction_dose: 2.1,
    adherence: true,
    technique: 'IMRT',
    linac: 'Machine 2',
    sim_to_treat: 1,
    priority: 'Standard',
    referrer: 'Dr. Victor Hernandes',
    diagnosis_code: 'C34.90',
  },
  {
    fractions: 18,
    site: 'Red Hills',
    physician: 'Dr Smith',
    date: '2024-01-12',
    diagnosis: 'prostate',
    fraction_dose: 2.1,
    adherence: true,
    technique: 'Electrons',
    linac: 'Machine 4',
    sim_to_treat: 10,
    priority: 'Standard',
    referrer: 'Dr. Alex Reynolds',
    diagnosis_code: 'C71.9',
  },
  {
    fractions: 21,
    site: 'Campsie',
    physician: 'Dr Wong',
    date: '2024-06-02',
    diagnosis: 'breast',
    fraction_dose: 1.6,
    adherence: true,
    technique: 'VMAT',
    linac: 'Machine 1',
    sim_to_treat: 10,
    priority: 'Urgent',
    referrer: 'Dr. Aisha Patel',
    diagnosis_code: 'C43.9',
  },
  {
    fractions: 22,
    site: 'Red Hills',
    physician: 'Dr Lopez',
    date: '2024-07-15',
    diagnosis: 'metastasis',
    fraction_dose: 2.7,
    adherence: true,
    technique: 'Stereotactic',
    linac: 'Machine 3',
    sim_to_treat: 2,
    priority: 'Emergency',
    referrer: 'Dr. Victor Hernandes',
    diagnosis_code: 'C43.9',
  },
  {
    fractions: 11,
    site: 'Red Hills',
    physician: 'Dr Smith',
    date: '2024-06-13',
    diagnosis: 'breast',
    fraction_dose: 1.4,
    adherence: true,
    technique: 'VMAT',
    linac: 'Machine 1',
    sim_to_treat: 2,
    priority: 'Emergency',
    referrer: 'Dr. Alex Reynolds',
    diagnosis_code: 'C20',
  },
  {
    fractions: 24,
    site: 'Lakeside',
    physician: 'Dr Lopez',
    date: '2024-07-10',
    diagnosis: 'skin',
    fraction_dose: 2.7,
    adherence: true,
    technique: 'Conformal',
    linac: 'Machine 2',
    sim_to_treat: 10,
    priority: 'Emergency',
    referrer: 'Dr. Aisha Patel',
    diagnosis_code: 'C61',
  },
  {
    fractions: 22,
    site: 'Red Hills',
    physician: 'Dr Smith',
    date: '2024-10-31',
    diagnosis: 'neuro',
    fraction_dose: 2.8,
    adherence: true,
    technique: 'Electrons',
    linac: 'Machine 3',
    sim_to_treat: 2,
    priority: 'Urgent',
    referrer: 'Dr. Julian Foster',
    diagnosis_code: 'C71.9',
  },
  {
    fractions: 21,
    site: 'Lakeside',
    physician: 'Dr Adams',
    date: '2024-04-25',
    diagnosis: 'metastasis',
    fraction_dose: 3.0,
    adherence: true,
    technique: 'Conformal',
    linac: 'Machine 4',
    sim_to_treat: 3,
    priority: 'Emergency',
    referrer: 'Dr. Aisha Patel',
    diagnosis_code: 'C61',
  },
  {
    fractions: 10,
    site: 'Red Hills',
    physician: 'Dr Adams',
    date: '2024-10-04',
    diagnosis: 'colorectal',
    fraction_dose: 2.9,
    adherence: true,
    technique: 'Electrons',
    linac: 'Machine 3',
    sim_to_treat: 4,
    priority: 'Emergency',
    referrer: 'Dr. Alex Reynolds',
    diagnosis_code: 'C61',
  },
  {
    fractions: 18,
    site: 'Campsie',
    physician: 'Dr Smith',
    date: '2024-01-20',
    diagnosis: 'metastasis',
    fraction_dose: 1.2,
    adherence: true,
    technique: 'VMAT',
    linac: 'Machine 1',
    sim_to_treat: 6,
    priority: 'Urgent',
    referrer: 'Dr. Clara Thompson',
    diagnosis_code: 'C20',
  },
  {
    fractions: 21,
    site: 'Rosebush',
    physician: 'Dr Smith',
    date: '2024-01-15',
    diagnosis: 'metastasis',
    fraction_dose: 3.1,
    adherence: true,
    technique: 'Electrons',
    linac: 'Machine 2',
    sim_to_treat: 6,
    priority: 'Standard',
    referrer: 'Dr. Michael Stern',
    diagnosis_code: 'C43.9',
  },
  {
    fractions: 22,
    site: 'Campsie',
    physician: 'Dr Lopez',
    date: '2024-01-22',
    diagnosis: 'colorectal',
    fraction_dose: 2.8,
    adherence: true,
    technique: 'IMRT',
    linac: 'Machine 2',
    sim_to_treat: 9,
    priority: 'Urgent',
    referrer: 'Dr. Aisha Patel',
    diagnosis_code: 'C20',
  },
  {
    fractions: 16,
    site: 'Red Hills',
    physician: 'Dr Adams',
    date: '2024-07-06',
    diagnosis: 'prostate',
    fraction_dose: 3.2,
    adherence: true,
    technique: 'Electrons',
    linac: 'Machine 3',
    sim_to_treat: 8,
    priority: 'Emergency',
    referrer: 'Dr. Clara Thompson',
    diagnosis_code: 'C71.9',
  },
  {
    fractions: 17,
    site: 'Campsie',
    physician: 'Dr Lopez',
    date: '2024-04-04',
    diagnosis: 'colorectal',
    fraction_dose: 2.9,
    adherence: true,
    technique: 'Stereotactic',
    linac: 'Machine 2',
    sim_to_treat: 4,
    priority: 'Urgent',
    referrer: 'Dr. Julian Foster',
    diagnosis_code: 'C79.81',
  },
  {
    fractions: 14,
    site: 'Red Hills',
    physician: 'Dr Adams',
    date: '2024-03-18',
    diagnosis: 'neuro',
    fraction_dose: 3.3,
    adherence: true,
    technique: 'VMAT',
    linac: 'Machine 1',
    sim_to_treat: 2,
    priority: 'Emergency',
    referrer: 'Dr. Julian Foster',
    diagnosis_code: 'C71.9',
  },
  {
    fractions: 19,
    site: 'Rosebush',
    physician: 'Dr Adams',
    date: '2024-10-04',
    diagnosis: 'prostate',
    fraction_dose: 3.3,
    adherence: true,
    technique: 'Electrons',
    linac: 'Machine 4',
    sim_to_treat: 9,
    priority: 'Urgent',
    referrer: 'Dr. Alex Reynolds',
    diagnosis_code: 'C79.81',
  },
  {
    fractions: 21,
    site: 'Lakeside',
    physician: 'Dr Adams',
    date: '2024-10-24',
    diagnosis: 'neuro',
    fraction_dose: 1.2,
    adherence: true,
    technique: 'IMRT',
    linac: 'Machine 3',
    sim_to_treat: 10,
    priority: 'Standard',
    referrer: 'Dr. Jonathan Blake',
    diagnosis_code: 'C20',
  },
  {
    fractions: 23,
    site: 'Rosebush',
    physician: 'Dr Lopez',
    date: '2024-09-01',
    diagnosis: 'metastasis',
    fraction_dose: 1.4,
    adherence: true,
    technique: 'Conformal',
    linac: 'Machine 1',
    sim_to_treat: 7,
    priority: 'Standard',
    referrer: 'Dr. Julian Foster',
    diagnosis_code: 'C79.81',
  },
  {
    fractions: 12,
    site: 'Lakeside',
    physician: 'Dr Wong',
    date: '2024-01-12',
    diagnosis: 'neuro',
    fraction_dose: 1.8,
    adherence: true,
    technique: 'IMRT',
    linac: 'Machine 4',
    sim_to_treat: 1,
    priority: 'Urgent',
    referrer: 'Dr. Alex Reynolds',
    diagnosis_code: 'C71.9',
  },
  {
    fractions: 24,
    site: 'Rosebush',
    physician: 'Dr Wong',
    date: '2024-10-16',
    diagnosis: 'metastasis',
    fraction_dose: 2.5,
    adherence: true,
    technique: 'Conformal',
    linac: 'Machine 1',
    sim_to_treat: 1,
    priority: 'Standard',
    referrer: 'Dr. Aisha Patel',
    diagnosis_code: 'C61',
  },
  {
    fractions: 16,
    site: 'Red Hills',
    physician: 'Dr Smith',
    date: '2024-03-30',
    diagnosis: 'colorectal',
    fraction_dose: 2.4,
    adherence: false,
    technique: 'Conformal',
    linac: 'Machine 4',
    sim_to_treat: 8,
    priority: 'Urgent',
    referrer: 'Dr. Sophie Greenberg',
    diagnosis_code: 'C34.90',
  },
  {
    fractions: 16,
    site: 'Lakeside',
    physician: 'Dr Adams',
    date: '2024-09-25',
    diagnosis: 'prostate',
    fraction_dose: 2.9,
    adherence: true,
    technique: 'Electrons',
    linac: 'Machine 1',
    sim_to_treat: 6,
    priority: 'Urgent',
    referrer: 'Dr. Rachel Nguyen',
    diagnosis_code: 'C20',
  },
  {
    fractions: 18,
    site: 'Rosebush',
    physician: 'Dr Adams',
    date: '2024-08-19',
    diagnosis: 'neuro',
    fraction_dose: 3.4,
    adherence: false,
    technique: 'Stereotactic',
    linac: 'Machine 1',
    sim_to_treat: 8,
    priority: 'Standard',
    referrer: 'Dr. Jonathan Blake',
    diagnosis_code: 'C61',
  },
  {
    fractions: 21,
    site: 'Red Hills',
    physician: 'Dr Wong',
    date: '2024-02-12',
    diagnosis: 'breast',
    fraction_dose: 2.7,
    adherence: true,
    technique: 'Conformal',
    linac: 'Machine 1',
    sim_to_treat: 1,
    priority: 'Urgent',
    referrer: 'Dr. Sophie Greenberg',
    diagnosis_code: 'C20',
  },
  {
    fractions: 17,
    site: 'Red Hills',
    physician: 'Dr Smith',
    date: '2024-10-06',
    diagnosis: 'breast',
    fraction_dose: 3.3,
    adherence: true,
    technique: 'IMRT',
    linac: 'Machine 2',
    sim_to_treat: 1,
    priority: 'Standard',
    referrer: 'Dr. Victor Hernandes',
    diagnosis_code: 'C20',
  },
  {
    fractions: 19,
    site: 'Red Hills',
    physician: 'Dr Lopez',
    date: '2024-06-20',
    diagnosis: 'metastasis',
    fraction_dose: 2.6,
    adherence: true,
    technique: 'Stereotactic',
    linac: 'Machine 1',
    sim_to_treat: 7,
    priority: 'Urgent',
    referrer: 'Dr. Aisha Patel',
    diagnosis_code: 'C71.9',
  },
  {
    fractions: 18,
    site: 'Rosebush',
    physician: 'Dr Lopez',
    date: '2024-05-18',
    diagnosis: 'prostate',
    fraction_dose: 2.0,
    adherence: true,
    technique: 'IMRT',
    linac: 'Machine 4',
    sim_to_treat: 10,
    priority: 'Urgent',
    referrer: 'Dr. Michael Stern',
    diagnosis_code: 'C79.81',
  },
  {
    fractions: 24,
    site: 'Rosebush',
    physician: 'Dr Smith',
    date: '2024-04-07',
    diagnosis: 'prostate',
    fraction_dose: 2.3,
    adherence: true,
    technique: 'Brachytherapy',
    linac: 'Machine 4',
    sim_to_treat: 6,
    priority: 'Standard',
    referrer: 'Dr. Rachel Nguyen',
    diagnosis_code: 'C61',
  },
  {
    fractions: 11,
    site: 'Red Hills',
    physician: 'Dr Lopez',
    date: '2024-02-05',
    diagnosis: 'colorectal',
    fraction_dose: 1.4,
    adherence: true,
    technique: 'Electrons',
    linac: 'Machine 2',
    sim_to_treat: 6,
    priority: 'Urgent',
    referrer: 'Dr. Alex Reynolds',
    diagnosis_code: 'C79.81',
  },
  {
    fractions: 24,
    site: 'Rosebush',
    physician: 'Dr Wong',
    date: '2024-01-14',
    diagnosis: 'neuro',
    fraction_dose: 2.0,
    adherence: true,
    technique: 'Electrons',
    linac: 'Machine 4',
    sim_to_treat: 1,
    priority: 'Emergency',
    referrer: 'Dr. Rachel Nguyen',
    diagnosis_code: 'C71.9',
  },
  {
    fractions: 13,
    site: 'Red Hills',
    physician: 'Dr Lopez',
    date: '2024-03-23',
    diagnosis: 'breast',
    fraction_dose: 3.0,
    adherence: true,
    technique: 'Brachytherapy',
    linac: 'Machine 1',
    sim_to_treat: 2,
    priority: 'Urgent',
    referrer: 'Dr. Michael Stern',
    diagnosis_code: 'C20',
  },
  {
    fractions: 16,
    site: 'Red Hills',
    physician: 'Dr Lopez',
    date: '2024-05-29',
    diagnosis: 'metastasis',
    fraction_dose: 1.7,
    adherence: true,
    technique: 'Brachytherapy',
    linac: 'Machine 3',
    sim_to_treat: 5,
    priority: 'Standard',
    referrer: 'Dr. Rachel Nguyen',
    diagnosis_code: 'C20',
  },
  {
    fractions: 21,
    site: 'Rosebush',
    physician: 'Dr Wong',
    date: '2024-02-14',
    diagnosis: 'breast',
    fraction_dose: 2.6,
    adherence: true,
    technique: 'Electrons',
    linac: 'Machine 4',
    sim_to_treat: 9,
    priority: 'Emergency',
    referrer: 'Dr. Jonathan Blake',
    diagnosis_code: 'C79.81',
  },
  {
    fractions: 14,
    site: 'Rosebush',
    physician: 'Dr Smith',
    date: '2024-05-17',
    diagnosis: 'metastasis',
    fraction_dose: 2.8,
    adherence: true,
    technique: 'Electrons',
    linac: 'Machine 2',
    sim_to_treat: 2,
    priority: 'Emergency',
    referrer: 'Dr. Michael Stern',
    diagnosis_code: 'C71.9',
  },
  {
    fractions: 10,
    site: 'Campsie',
    physician: 'Dr Adams',
    date: '2024-02-12',
    diagnosis: 'skin',
    fraction_dose: 1.6,
    adherence: true,
    technique: 'Brachytherapy',
    linac: 'Machine 3',
    sim_to_treat: 7,
    priority: 'Emergency',
    referrer: 'Dr. Aisha Patel',
    diagnosis_code: 'C79.81',
  },
  {
    fractions: 21,
    site: 'Red Hills',
    physician: 'Dr Lopez',
    date: '2024-05-01',
    diagnosis: 'neuro',
    fraction_dose: 2.4,
    adherence: true,
    technique: 'Conformal',
    linac: 'Machine 4',
    sim_to_treat: 8,
    priority: 'Urgent',
    referrer: 'Dr. Julian Foster',
    diagnosis_code: 'C34.90',
  },
  {
    fractions: 15,
    site: 'Lakeside',
    physician: 'Dr Adams',
    date: '2024-05-15',
    diagnosis: 'neuro',
    fraction_dose: 2.3,
    adherence: true,
    technique: 'IMRT',
    linac: 'Machine 3',
    sim_to_treat: 3,
    priority: 'Urgent',
    referrer: 'Dr. Jonathan Blake',
    diagnosis_code: 'C61',
  },
  {
    fractions: 17,
    site: 'Campsie',
    physician: 'Dr Adams',
    date: '2024-01-10',
    diagnosis: 'colorectal',
    fraction_dose: 1.5,
    adherence: true,
    technique: 'Conformal',
    linac: 'Machine 2',
    sim_to_treat: 6,
    priority: 'Emergency',
    referrer: 'Dr. Michael Stern',
    diagnosis_code: 'C79.81',
  },
  {
    fractions: 23,
    site: 'Campsie',
    physician: 'Dr Adams',
    date: '2024-09-14',
    diagnosis: 'skin',
    fraction_dose: 1.5,
    adherence: true,
    technique: 'Conformal',
    linac: 'Machine 4',
    sim_to_treat: 8,
    priority: 'Emergency',
    referrer: 'Dr. Aisha Patel',
    diagnosis_code: 'C71.9',
  },
  {
    fractions: 17,
    site: 'Rosebush',
    physician: 'Dr Wong',
    date: '2024-06-30',
    diagnosis: 'metastasis',
    fraction_dose: 2.1,
    adherence: true,
    technique: 'Stereotactic',
    linac: 'Machine 3',
    sim_to_treat: 6,
    priority: 'Standard',
    referrer: 'Dr. Clara Thompson',
    diagnosis_code: 'C79.81',
  },
  {
    fractions: 18,
    site: 'Red Hills',
    physician: 'Dr Lopez',
    date: '2024-07-07',
    diagnosis: 'prostate',
    fraction_dose: 1.3,
    adherence: true,
    technique: 'Stereotactic',
    linac: 'Machine 4',
    sim_to_treat: 7,
    priority: 'Urgent',
    referrer: 'Dr. Julian Foster',
    diagnosis_code: 'C71.9',
  },
  {
    fractions: 17,
    site: 'Red Hills',
    physician: 'Dr Wong',
    date: '2024-04-14',
    diagnosis: 'metastasis',
    fraction_dose: 2.3,
    adherence: true,
    technique: 'Brachytherapy',
    linac: 'Machine 2',
    sim_to_treat: 8,
    priority: 'Emergency',
    referrer: 'Dr. Julian Foster',
    diagnosis_code: 'C43.9',
  },
  {
    fractions: 25,
    site: 'Campsie',
    physician: 'Dr Adams',
    date: '2024-06-16',
    diagnosis: 'neuro',
    fraction_dose: 1.3,
    adherence: true,
    technique: 'Conformal',
    linac: 'Machine 4',
    sim_to_treat: 5,
    priority: 'Standard',
    referrer: 'Dr. Clara Thompson',
    diagnosis_code: 'C34.90',
  },
  {
    fractions: 19,
    site: 'Lakeside',
    physician: 'Dr Adams',
    date: '2024-09-19',
    diagnosis: 'colorectal',
    fraction_dose: 1.3,
    adherence: true,
    technique: 'IMRT',
    linac: 'Machine 3',
    sim_to_treat: 2,
    priority: 'Emergency',
    referrer: 'Dr. Sophie Greenberg',
    diagnosis_code: 'C20',
  },
  {
    fractions: 22,
    site: 'Lakeside',
    physician: 'Dr Wong',
    date: '2024-01-07',
    diagnosis: 'skin',
    fraction_dose: 1.8,
    adherence: true,
    technique: 'Brachytherapy',
    linac: 'Machine 2',
    sim_to_treat: 5,
    priority: 'Standard',
    referrer: 'Dr. Julian Foster',
    diagnosis_code: 'C61',
  },
  {
    fractions: 21,
    site: 'Campsie',
    physician: 'Dr Wong',
    date: '2024-09-15',
    diagnosis: 'skin',
    fraction_dose: 2.3,
    adherence: true,
    technique: 'IMRT',
    linac: 'Machine 2',
    sim_to_treat: 8,
    priority: 'Emergency',
    referrer: 'Dr. Julian Foster',
    diagnosis_code: 'C34.90',
  },
  {
    fractions: 25,
    site: 'Lakeside',
    physician: 'Dr Wong',
    date: '2024-09-13',
    diagnosis: 'colorectal',
    fraction_dose: 2.8,
    adherence: false,
    technique: 'Stereotactic',
    linac: 'Machine 4',
    sim_to_treat: 3,
    priority: 'Standard',
    referrer: 'Dr. Clara Thompson',
    diagnosis_code: 'C79.81',
  },
  {
    fractions: 19,
    site: 'Campsie',
    physician: 'Dr Lopez',
    date: '2024-02-15',
    diagnosis: 'colorectal',
    fraction_dose: 1.9,
    adherence: true,
    technique: 'Brachytherapy',
    linac: 'Machine 3',
    sim_to_treat: 8,
    priority: 'Emergency',
    referrer: 'Dr. Jonathan Blake',
    diagnosis_code: 'C34.90',
  },
  {
    fractions: 16,
    site: 'Red Hills',
    physician: 'Dr Wong',
    date: '2024-01-22',
    diagnosis: 'metastasis',
    fraction_dose: 2.6,
    adherence: true,
    technique: 'Brachytherapy',
    linac: 'Machine 1',
    sim_to_treat: 2,
    priority: 'Emergency',
    referrer: 'Dr. Rachel Nguyen',
    diagnosis_code: 'C20',
  },
  {
    fractions: 20,
    site: 'Lakeside',
    physician: 'Dr Wong',
    date: '2024-01-24',
    diagnosis: 'skin',
    fraction_dose: 1.5,
    adherence: true,
    technique: 'Stereotactic',
    linac: 'Machine 1',
    sim_to_treat: 8,
    priority: 'Standard',
    referrer: 'Dr. Victor Hernandes',
    diagnosis_code: 'C79.81',
  },
  {
    fractions: 25,
    site: 'Lakeside',
    physician: 'Dr Lopez',
    date: '2024-07-23',
    diagnosis: 'metastasis',
    fraction_dose: 1.9,
    adherence: true,
    technique: 'Brachytherapy',
    linac: 'Machine 2',
    sim_to_treat: 5,
    priority: 'Urgent',
    referrer: 'Dr. Emily Hartman',
    diagnosis_code: 'C79.81',
  },
  {
    fractions: 12,
    site: 'Red Hills',
    physician: 'Dr Adams',
    date: '2024-03-18',
    diagnosis: 'breast',
    fraction_dose: 2.5,
    adherence: true,
    technique: 'Brachytherapy',
    linac: 'Machine 4',
    sim_to_treat: 10,
    priority: 'Standard',
    referrer: 'Dr. Michael Stern',
    diagnosis_code: 'C61',
  },
  {
    fractions: 12,
    site: 'Red Hills',
    physician: 'Dr Smith',
    date: '2024-08-12',
    diagnosis: 'metastasis',
    fraction_dose: 3.3,
    adherence: false,
    technique: 'Brachytherapy',
    linac: 'Machine 1',
    sim_to_treat: 1,
    priority: 'Standard',
    referrer: 'Dr. Rachel Nguyen',
    diagnosis_code: 'C43.9',
  },
  {
    fractions: 23,
    site: 'Campsie',
    physician: 'Dr Smith',
    date: '2024-07-08',
    diagnosis: 'metastasis',
    fraction_dose: 2.0,
    adherence: true,
    technique: 'Stereotactic',
    linac: 'Machine 4',
    sim_to_treat: 8,
    priority: 'Standard',
    referrer: 'Dr. Jonathan Blake',
    diagnosis_code: 'C20',
  },
  {
    fractions: 12,
    site: 'Campsie',
    physician: 'Dr Lopez',
    date: '2024-05-21',
    diagnosis: 'skin',
    fraction_dose: 2.2,
    adherence: true,
    technique: 'Conformal',
    linac: 'Machine 1',
    sim_to_treat: 8,
    priority: 'Emergency',
    referrer: 'Dr. Alex Reynolds',
    diagnosis_code: 'C79.81',
  },
  {
    fractions: 10,
    site: 'Rosebush',
    physician: 'Dr Lopez',
    date: '2024-08-13',
    diagnosis: 'neuro',
    fraction_dose: 1.8,
    adherence: true,
    technique: 'Stereotactic',
    linac: 'Machine 3',
    sim_to_treat: 4,
    priority: 'Standard',
    referrer: 'Dr. Alex Reynolds',
    diagnosis_code: 'C43.9',
  },
  {
    fractions: 12,
    site: 'Red Hills',
    physician: 'Dr Lopez',
    date: '2024-08-11',
    diagnosis: 'colorectal',
    fraction_dose: 1.6,
    adherence: true,
    technique: 'Brachytherapy',
    linac: 'Machine 1',
    sim_to_treat: 7,
    priority: 'Standard',
    referrer: 'Dr. Victor Hernandes',
    diagnosis_code: 'C71.9',
  },
  {
    fractions: 18,
    site: 'Campsie',
    physician: 'Dr Lopez',
    date: '2024-08-08',
    diagnosis: 'colorectal',
    fraction_dose: 2.3,
    adherence: true,
    technique: 'Conformal',
    linac: 'Machine 3',
    sim_to_treat: 9,
    priority: 'Urgent',
    referrer: 'Dr. Jonathan Blake',
    diagnosis_code: 'C79.81',
  },
  {
    fractions: 14,
    site: 'Campsie',
    physician: 'Dr Lopez',
    date: '2024-01-21',
    diagnosis: 'prostate',
    fraction_dose: 2.8,
    adherence: true,
    technique: 'IMRT',
    linac: 'Machine 3',
    sim_to_treat: 4,
    priority: 'Urgent',
    referrer: 'Dr. Clara Thompson',
    diagnosis_code: 'C43.9',
  },
  {
    fractions: 16,
    site: 'Rosebush',
    physician: 'Dr Smith',
    date: '2024-08-02',
    diagnosis: 'breast',
    fraction_dose: 1.4,
    adherence: true,
    technique: 'Conformal',
    linac: 'Machine 4',
    sim_to_treat: 5,
    priority: 'Standard',
    referrer: 'Dr. Julian Foster',
    diagnosis_code: 'C20',
  },
  {
    fractions: 21,
    site: 'Rosebush',
    physician: 'Dr Adams',
    date: '2024-04-26',
    diagnosis: 'breast',
    fraction_dose: 1.9,
    adherence: true,
    technique: 'Electrons',
    linac: 'Machine 4',
    sim_to_treat: 7,
    priority: 'Urgent',
    referrer: 'Dr. Jonathan Blake',
    diagnosis_code: 'C61',
  },
  {
    fractions: 12,
    site: 'Lakeside',
    physician: 'Dr Smith',
    date: '2024-07-20',
    diagnosis: 'breast',
    fraction_dose: 3.3,
    adherence: true,
    technique: 'Electrons',
    linac: 'Machine 2',
    sim_to_treat: 1,
    priority: 'Emergency',
    referrer: 'Dr. Rachel Nguyen',
    diagnosis_code: 'C34.90',
  },
  {
    fractions: 17,
    site: 'Red Hills',
    physician: 'Dr Wong',
    date: '2024-03-23',
    diagnosis: 'colorectal',
    fraction_dose: 2.1,
    adherence: true,
    technique: 'Electrons',
    linac: 'Machine 2',
    sim_to_treat: 3,
    priority: 'Urgent',
    referrer: 'Dr. Jonathan Blake',
    diagnosis_code: 'C20',
  },
  {
    fractions: 11,
    site: 'Red Hills',
    physician: 'Dr Lopez',
    date: '2024-08-24',
    diagnosis: 'neuro',
    fraction_dose: 3.2,
    adherence: true,
    technique: 'Conformal',
    linac: 'Machine 2',
    sim_to_treat: 9,
    priority: 'Urgent',
    referrer: 'Dr. Victor Hernandes',
    diagnosis_code: 'C71.9',
  },
  {
    fractions: 21,
    site: 'Rosebush',
    physician: 'Dr Lopez',
    date: '2024-04-14',
    diagnosis: 'colorectal',
    fraction_dose: 2.8,
    adherence: true,
    technique: 'Electrons',
    linac: 'Machine 4',
    sim_to_treat: 3,
    priority: 'Emergency',
    referrer: 'Dr. Rachel Nguyen',
    diagnosis_code: 'C20',
  },
  {
    fractions: 22,
    site: 'Campsie',
    physician: 'Dr Smith',
    date: '2024-06-04',
    diagnosis: 'breast',
    fraction_dose: 2.9,
    adherence: true,
    technique: 'VMAT',
    linac: 'Machine 1',
    sim_to_treat: 6,
    priority: 'Urgent',
    referrer: 'Dr. Alex Reynolds',
    diagnosis_code: 'C20',
  },
  {
    fractions: 19,
    site: 'Lakeside',
    physician: 'Dr Wong',
    date: '2024-10-05',
    diagnosis: 'prostate',
    fraction_dose: 3.1,
    adherence: true,
    technique: 'Brachytherapy',
    linac: 'Machine 4',
    sim_to_treat: 5,
    priority: 'Standard',
    referrer: 'Dr. Michael Stern',
    diagnosis_code: 'C71.9',
  },
  {
    fractions: 25,
    site: 'Lakeside',
    physician: 'Dr Lopez',
    date: '2024-08-15',
    diagnosis: 'neuro',
    fraction_dose: 1.3,
    adherence: true,
    technique: 'IMRT',
    linac: 'Machine 2',
    sim_to_treat: 9,
    priority: 'Emergency',
    referrer: 'Dr. Julian Foster',
    diagnosis_code: 'C34.90',
  },
  {
    fractions: 14,
    site: 'Campsie',
    physician: 'Dr Smith',
    date: '2024-06-22',
    diagnosis: 'colorectal',
    fraction_dose: 3.4,
    adherence: true,
    technique: 'VMAT',
    linac: 'Machine 2',
    sim_to_treat: 5,
    priority: 'Urgent',
    referrer: 'Dr. Victor Hernandes',
    diagnosis_code: 'C20',
  },
  {
    fractions: 15,
    site: 'Red Hills',
    physician: 'Dr Adams',
    date: '2024-09-25',
    diagnosis: 'colorectal',
    fraction_dose: 2.1,
    adherence: true,
    technique: 'Electrons',
    linac: 'Machine 4',
    sim_to_treat: 5,
    priority: 'Emergency',
    referrer: 'Dr. Emily Hartman',
    diagnosis_code: 'C71.9',
  },
  {
    fractions: 17,
    site: 'Lakeside',
    physician: 'Dr Wong',
    date: '2024-05-26',
    diagnosis: 'colorectal',
    fraction_dose: 3.0,
    adherence: true,
    technique: 'Stereotactic',
    linac: 'Machine 1',
    sim_to_treat: 8,
    priority: 'Emergency',
    referrer: 'Dr. Rachel Nguyen',
    diagnosis_code: 'C79.81',
  },
  {
    fractions: 17,
    site: 'Campsie',
    physician: 'Dr Wong',
    date: '2024-08-04',
    diagnosis: 'neuro',
    fraction_dose: 2.7,
    adherence: true,
    technique: 'VMAT',
    linac: 'Machine 4',
    sim_to_treat: 8,
    priority: 'Urgent',
    referrer: 'Dr. Victor Hernandes',
    diagnosis_code: 'C79.81',
  },
  {
    fractions: 21,
    site: 'Rosebush',
    physician: 'Dr Adams',
    date: '2024-08-29',
    diagnosis: 'skin',
    fraction_dose: 2.4,
    adherence: true,
    technique: 'VMAT',
    linac: 'Machine 4',
    sim_to_treat: 5,
    priority: 'Urgent',
    referrer: 'Dr. Rachel Nguyen',
    diagnosis_code: 'C20',
  },
  {
    fractions: 13,
    site: 'Campsie',
    physician: 'Dr Wong',
    date: '2024-09-06',
    diagnosis: 'breast',
    fraction_dose: 2.7,
    adherence: true,
    technique: 'Brachytherapy',
    linac: 'Machine 2',
    sim_to_treat: 3,
    priority: 'Standard',
    referrer: 'Dr. Sophie Greenberg',
    diagnosis_code: 'C43.9',
  },
  {
    fractions: 24,
    site: 'Campsie',
    physician: 'Dr Lopez',
    date: '2024-08-31',
    diagnosis: 'colorectal',
    fraction_dose: 1.6,
    adherence: true,
    technique: 'Electrons',
    linac: 'Machine 3',
    sim_to_treat: 4,
    priority: 'Standard',
    referrer: 'Dr. Alex Reynolds',
    diagnosis_code: 'C43.9',
  },
  {
    fractions: 24,
    site: 'Red Hills',
    physician: 'Dr Adams',
    date: '2024-07-20',
    diagnosis: 'prostate',
    fraction_dose: 1.7,
    adherence: true,
    technique: 'Electrons',
    linac: 'Machine 2',
    sim_to_treat: 10,
    priority: 'Urgent',
    referrer: 'Dr. Jonathan Blake',
    diagnosis_code: 'C71.9',
  },
  {
    fractions: 13,
    site: 'Rosebush',
    physician: 'Dr Wong',
    date: '2024-07-01',
    diagnosis: 'colorectal',
    fraction_dose: 2.3,
    adherence: true,
    technique: 'Conformal',
    linac: 'Machine 2',
    sim_to_treat: 10,
    priority: 'Urgent',
    referrer: 'Dr. Michael Stern',
    diagnosis_code: 'C20',
  },
  {
    fractions: 22,
    site: 'Lakeside',
    physician: 'Dr Wong',
    date: '2024-03-10',
    diagnosis: 'breast',
    fraction_dose: 3.3,
    adherence: true,
    technique: 'Stereotactic',
    linac: 'Machine 2',
    sim_to_treat: 2,
    priority: 'Standard',
    referrer: 'Dr. Michael Stern',
    diagnosis_code: 'C20',
  },
  {
    fractions: 20,
    site: 'Campsie',
    physician: 'Dr Adams',
    date: '2024-08-07',
    diagnosis: 'metastasis',
    fraction_dose: 3.4,
    adherence: true,
    technique: 'Electrons',
    linac: 'Machine 1',
    sim_to_treat: 3,
    priority: 'Emergency',
    referrer: 'Dr. Alex Reynolds',
    diagnosis_code: 'C34.90',
  },
  {
    fractions: 14,
    site: 'Rosebush',
    physician: 'Dr Wong',
    date: '2024-08-16',
    diagnosis: 'neuro',
    fraction_dose: 3.1,
    adherence: true,
    technique: 'Stereotactic',
    linac: 'Machine 2',
    sim_to_treat: 9,
    priority: 'Urgent',
    referrer: 'Dr. Clara Thompson',
    diagnosis_code: 'C20',
  },
  {
    fractions: 11,
    site: 'Red Hills',
    physician: 'Dr Lopez',
    date: '2024-08-23',
    diagnosis: 'prostate',
    fraction_dose: 3.4,
    adherence: true,
    technique: 'Conformal',
    linac: 'Machine 3',
    sim_to_treat: 1,
    priority: 'Standard',
    referrer: 'Dr. Michael Stern',
    diagnosis_code: 'C61',
  },
  {
    fractions: 19,
    site: 'Red Hills',
    physician: 'Dr Lopez',
    date: '2024-04-30',
    diagnosis: 'colorectal',
    fraction_dose: 1.8,
    adherence: true,
    technique: 'Conformal',
    linac: 'Machine 1',
    sim_to_treat: 1,
    priority: 'Urgent',
    referrer: 'Dr. Michael Stern',
    diagnosis_code: 'C34.90',
  },
  {
    fractions: 17,
    site: 'Red Hills',
    physician: 'Dr Wong',
    date: '2024-07-12',
    diagnosis: 'colorectal',
    fraction_dose: 3.0,
    adherence: true,
    technique: 'VMAT',
    linac: 'Machine 1',
    sim_to_treat: 10,
    priority: 'Emergency',
    referrer: 'Dr. Alex Reynolds',
    diagnosis_code: 'C20',
  },
  {
    fractions: 11,
    site: 'Lakeside',
    physician: 'Dr Smith',
    date: '2024-02-05',
    diagnosis: 'colorectal',
    fraction_dose: 2.7,
    adherence: true,
    technique: 'Conformal',
    linac: 'Machine 3',
    sim_to_treat: 8,
    priority: 'Urgent',
    referrer: 'Dr. Julian Foster',
    diagnosis_code: 'C61',
  },
  {
    fractions: 10,
    site: 'Rosebush',
    physician: 'Dr Smith',
    date: '2024-06-11',
    diagnosis: 'prostate',
    fraction_dose: 1.8,
    adherence: true,
    technique: 'IMRT',
    linac: 'Machine 2',
    sim_to_treat: 4,
    priority: 'Emergency',
    referrer: 'Dr. Alex Reynolds',
    diagnosis_code: 'C43.9',
  },
  {
    fractions: 22,
    site: 'Campsie',
    physician: 'Dr Wong',
    date: '2024-03-02',
    diagnosis: 'skin',
    fraction_dose: 1.4,
    adherence: true,
    technique: 'Electrons',
    linac: 'Machine 1',
    sim_to_treat: 7,
    priority: 'Emergency',
    referrer: 'Dr. Clara Thompson',
    diagnosis_code: 'C34.90',
  },
  {
    fractions: 16,
    site: 'Red Hills',
    physician: 'Dr Lopez',
    date: '2024-08-05',
    diagnosis: 'colorectal',
    fraction_dose: 1.6,
    adherence: true,
    technique: 'Brachytherapy',
    linac: 'Machine 4',
    sim_to_treat: 6,
    priority: 'Emergency',
    referrer: 'Dr. Sophie Greenberg',
    diagnosis_code: 'C71.9',
  },
  {
    fractions: 17,
    site: 'Lakeside',
    physician: 'Dr Lopez',
    date: '2024-10-22',
    diagnosis: 'colorectal',
    fraction_dose: 2.8,
    adherence: true,
    technique: 'Stereotactic',
    linac: 'Machine 4',
    sim_to_treat: 9,
    priority: 'Standard',
    referrer: 'Dr. Alex Reynolds',
    diagnosis_code: 'C79.81',
  },
  {
    fractions: 15,
    site: 'Lakeside',
    physician: 'Dr Lopez',
    date: '2024-05-07',
    diagnosis: 'prostate',
    fraction_dose: 2.9,
    adherence: true,
    technique: 'Conformal',
    linac: 'Machine 4',
    sim_to_treat: 4,
    priority: 'Urgent',
    referrer: 'Dr. Aisha Patel',
    diagnosis_code: 'C79.81',
  },
  {
    fractions: 24,
    site: 'Lakeside',
    physician: 'Dr Lopez',
    date: '2024-05-27',
    diagnosis: 'neuro',
    fraction_dose: 2.2,
    adherence: true,
    technique: 'Brachytherapy',
    linac: 'Machine 2',
    sim_to_treat: 9,
    priority: 'Urgent',
    referrer: 'Dr. Sophie Greenberg',
    diagnosis_code: 'C79.81',
  },
  {
    fractions: 19,
    site: 'Red Hills',
    physician: 'Dr Lopez',
    date: '2024-07-05',
    diagnosis: 'metastasis',
    fraction_dose: 2.6,
    adherence: true,
    technique: 'Electrons',
    linac: 'Machine 3',
    sim_to_treat: 9,
    priority: 'Urgent',
    referrer: 'Dr. Julian Foster',
    diagnosis_code: 'C61',
  },
  {
    fractions: 24,
    site: 'Lakeside',
    physician: 'Dr Adams',
    date: '2024-03-10',
    diagnosis: 'breast',
    fraction_dose: 3.2,
    adherence: true,
    technique: 'Electrons',
    linac: 'Machine 3',
    sim_to_treat: 10,
    priority: 'Urgent',
    referrer: 'Dr. Rachel Nguyen',
    diagnosis_code: 'C71.9',
  },
  {
    fractions: 10,
    site: 'Red Hills',
    physician: 'Dr Adams',
    date: '2024-02-05',
    diagnosis: 'neuro',
    fraction_dose: 2.7,
    adherence: false,
    technique: 'Electrons',
    linac: 'Machine 1',
    sim_to_treat: 1,
    priority: 'Emergency',
    referrer: 'Dr. Michael Stern',
    diagnosis_code: 'C20',
  },
  {
    fractions: 17,
    site: 'Red Hills',
    physician: 'Dr Adams',
    date: '2024-01-06',
    diagnosis: 'breast',
    fraction_dose: 3.1,
    adherence: true,
    technique: 'Brachytherapy',
    linac: 'Machine 1',
    sim_to_treat: 5,
    priority: 'Emergency',
    referrer: 'Dr. Sophie Greenberg',
    diagnosis_code: 'C34.90',
  },
  {
    fractions: 25,
    site: 'Rosebush',
    physician: 'Dr Wong',
    date: '2024-09-14',
    diagnosis: 'skin',
    fraction_dose: 2.4,
    adherence: true,
    technique: 'Stereotactic',
    linac: 'Machine 2',
    sim_to_treat: 7,
    priority: 'Standard',
    referrer: 'Dr. Michael Stern',
    diagnosis_code: 'C34.90',
  },
  {
    fractions: 15,
    site: 'Lakeside',
    physician: 'Dr Smith',
    date: '2024-05-08',
    diagnosis: 'skin',
    fraction_dose: 3.4,
    adherence: true,
    technique: 'Conformal',
    linac: 'Machine 3',
    sim_to_treat: 4,
    priority: 'Urgent',
    referrer: 'Dr. Julian Foster',
    diagnosis_code: 'C43.9',
  },
  {
    fractions: 10,
    site: 'Campsie',
    physician: 'Dr Wong',
    date: '2023-03-30',
    diagnosis: 'breast',
    fraction_dose: 1.68,
    adherence: true,
    technique: 'IMRT',
    linac: 'Machine 3',
    sim_to_treat: 6,
    priority: 'Emergency',
    referrer: 'Dr. Rachel Nguyen',
    diagnosis_code: 'C61',
  },
  {
    fractions: 17,
    site: 'Rosebush',
    physician: 'Dr Wong',
    date: '2023-08-08',
    diagnosis: 'Unspecified',
    fraction_dose: 2.27,
    adherence: true,
    technique: 'Brachytherapy',
    linac: 'Machine 2',
    sim_to_treat: 8,
    priority: 'Standard',
    referrer: 'Dr. Rachel Nguyen',
    diagnosis_code: 'C61',
  },
  {
    fractions: 23,
    site: 'Red Hills',
    physician: 'Dr Lopez',
    date: '2023-08-22',
    diagnosis: 'skin',
    fraction_dose: 1.66,
    adherence: true,
    technique: 'Conformal',
    linac: 'Machine 3',
    sim_to_treat: 10,
    priority: 'Standard',
    referrer: 'Dr. Michael Stern',
    diagnosis_code: 'C71.9',
  },
  {
    fractions: 25,
    site: 'Rosebush',
    physician: 'Dr Smith',
    date: '2023-04-23',
    diagnosis: 'prostate',
    fraction_dose: 1.83,
    adherence: true,
    technique: 'VMAT',
    linac: 'Machine 3',
    sim_to_treat: 6,
    priority: 'Urgent',
    referrer: 'Dr. Julian Foster',
    diagnosis_code: 'C71.9',
  },
  {
    fractions: 25,
    site: 'Campsie',
    physician: 'Dr Smith',
    date: '2023-12-07',
    diagnosis: 'breast',
    fraction_dose: 1.79,
    adherence: true,
    technique: 'VMAT',
    linac: 'Machine 1',
    sim_to_treat: 10,
    priority: 'Standard',
    referrer: 'Dr. Julian Foster',
    diagnosis_code: 'C43.9',
  },
  {
    fractions: 19,
    site: 'Rosebush',
    physician: 'Dr Smith',
    date: '2023-12-17',
    diagnosis: 'Unspecified',
    fraction_dose: 3.39,
    adherence: true,
    technique: 'VMAT',
    linac: 'Machine 2',
    sim_to_treat: 1,
    priority: 'Urgent',
    referrer: 'Dr. Michael Stern',
    diagnosis_code: 'C20',
  },
  {
    fractions: 16,
    site: 'Red Hills',
    physician: 'Dr Adams',
    date: '2023-10-02',
    diagnosis: 'colorectal',
    fraction_dose: 2.15,
    adherence: true,
    technique: 'Electrons',
    linac: 'Machine 1',
    sim_to_treat: 8,
    priority: 'Urgent',
    referrer: 'Dr. Victor Hernandes',
    diagnosis_code: 'C43.9',
  },
  {
    fractions: 12,
    site: 'Lakeside',
    physician: 'Dr Smith',
    date: '2023-08-06',
    diagnosis: 'prostate',
    fraction_dose: 1.81,
    adherence: true,
    technique: 'Unspecified',
    linac: 'Machine 1',
    sim_to_treat: 9,
    priority: 'Standard',
    referrer: 'Dr. Clara Thompson',
    diagnosis_code: 'C71.9',
  },
  {
    fractions: 16,
    site: 'Red Hills',
    physician: 'Dr Wong',
    date: '2023-06-18',
    diagnosis: 'breast',
    fraction_dose: 2.93,
    adherence: true,
    technique: 'VMAT',
    linac: 'Machine 2',
    sim_to_treat: 9,
    priority: 'Standard',
    referrer: 'Dr. Victor Hernandes',
    diagnosis_code: 'C71.9',
  },
  {
    fractions: 11,
    site: 'Campsie',
    physician: 'Dr Lopez',
    date: '2023-06-24',
    diagnosis: 'colorectal',
    fraction_dose: 3.28,
    adherence: true,
    technique: 'VMAT',
    linac: 'Machine 4',
    sim_to_treat: 2,
    priority: 'Emergency',
    referrer: 'Dr. Sophie Greenberg',
    diagnosis_code: 'C71.9',
  },
  {
    fractions: 24,
    site: 'Rosebush',
    physician: 'Dr Adams',
    date: '2023-05-23',
    diagnosis: 'prostate',
    fraction_dose: 1.25,
    adherence: true,
    technique: 'Conformal',
    linac: 'Machine 4',
    sim_to_treat: 8,
    priority: 'Urgent',
    referrer: 'Dr. Julian Foster',
    diagnosis_code: 'C61',
  },
  {
    fractions: 22,
    site: 'Campsie',
    physician: 'Dr Wong',
    date: '2023-11-15',
    diagnosis: 'metastasis',
    fraction_dose: 3.37,
    adherence: true,
    technique: 'Stereotactic',
    linac: 'Machine 1',
    sim_to_treat: 9,
    priority: 'Standard',
    referrer: 'Dr. Aisha Patel',
    diagnosis_code: 'C43.9',
  },
  {
    fractions: 19,
    site: 'Red Hills',
    physician: 'Dr Adams',
    date: '2023-10-19',
    diagnosis: 'skin',
    fraction_dose: 1.73,
    adherence: true,
    technique: 'Electrons',
    linac: 'Machine 4',
    sim_to_treat: 4,
    priority: 'Urgent',
    referrer: 'Dr. Clara Thompson',
    diagnosis_code: 'C20',
  },
  {
    fractions: 23,
    site: 'Rosebush',
    physician: 'Dr Wong',
    date: '2023-07-12',
    diagnosis: 'skin',
    fraction_dose: 2.78,
    adherence: true,
    technique: 'Unspecified',
    linac: 'Machine 1',
    sim_to_treat: 1,
    priority: 'Urgent',
    referrer: 'Dr. Rachel Nguyen',
    diagnosis_code: 'C61',
  },
  {
    fractions: 22,
    site: 'Lakeside',
    physician: 'Dr Lopez',
    date: '2023-05-14',
    diagnosis: 'skin',
    fraction_dose: 2.4,
    adherence: true,
    technique: 'Conformal',
    linac: 'Machine 1',
    sim_to_treat: 9,
    priority: 'Standard',
    referrer: 'Dr. Aisha Patel',
    diagnosis_code: 'C34.90',
  },
  {
    fractions: 15,
    site: 'Campsie',
    physician: 'Dr Lopez',
    date: '2023-08-26',
    diagnosis: 'breast',
    fraction_dose: 1.83,
    adherence: true,
    technique: 'IMRT',
    linac: 'Machine 2',
    sim_to_treat: 7,
    priority: 'Urgent',
    referrer: 'Dr. Michael Stern',
    diagnosis_code: 'C43.9',
  },
  {
    fractions: 20,
    site: 'Campsie',
    physician: 'Dr Lopez',
    date: '2023-08-13',
    diagnosis: 'metastasis',
    fraction_dose: 2.2,
    adherence: true,
    technique: 'Stereotactic',
    linac: 'Machine 2',
    sim_to_treat: 4,
    priority: 'Standard',
    referrer: 'Dr. Aisha Patel',
    diagnosis_code: 'C34.90',
  },
  {
    fractions: 19,
    site: 'Red Hills',
    physician: 'Dr Adams',
    date: '2023-05-18',
    diagnosis: 'metastasis',
    fraction_dose: 2.59,
    adherence: true,
    technique: 'Electrons',
    linac: 'Machine 2',
    sim_to_treat: 5,
    priority: 'Standard',
    referrer: 'Dr. Julian Foster',
    diagnosis_code: 'C79.81',
  },
  {
    fractions: 10,
    site: 'Lakeside',
    physician: 'Dr Wong',
    date: '2023-05-20',
    diagnosis: 'colorectal',
    fraction_dose: 2.38,
    adherence: true,
    technique: 'IMRT',
    linac: 'Machine 4',
    sim_to_treat: 2,
    priority: 'Emergency',
    referrer: 'Dr. Jonathan Blake',
    diagnosis_code: 'C20',
  },
  {
    fractions: 25,
    site: 'Lakeside',
    physician: 'Dr Lopez',
    date: '2023-03-26',
    diagnosis: 'neuro',
    fraction_dose: 2.08,
    adherence: true,
    technique: 'Conformal',
    linac: 'Machine 2',
    sim_to_treat: 7,
    priority: 'Urgent',
    referrer: 'Dr. Emily Hartman',
    diagnosis_code: 'C20',
  },
  {
    fractions: 24,
    site: 'Campsie',
    physician: 'Dr Lopez',
    date: '2023-02-03',
    diagnosis: 'prostate',
    fraction_dose: 1.25,
    adherence: true,
    technique: 'Conformal',
    linac: 'Machine 4',
    sim_to_treat: 5,
    priority: 'Standard',
    referrer: 'Dr. Clara Thompson',
    diagnosis_code: 'C34.90',
  },
  {
    fractions: 25,
    site: 'Rosebush',
    physician: 'Dr Wong',
    date: '2023-07-16',
    diagnosis: 'prostate',
    fraction_dose: 2.5,
    adherence: true,
    technique: 'Conformal',
    linac: 'Machine 3',
    sim_to_treat: 3,
    priority: 'Urgent',
    referrer: 'Dr. Emily Hartman',
    diagnosis_code: 'C61',
  },
  {
    fractions: 15,
    site: 'Rosebush',
    physician: 'Dr Lopez',
    date: '2023-10-31',
    diagnosis: 'Unspecified',
    fraction_dose: 1.67,
    adherence: true,
    technique: 'VMAT',
    linac: 'Machine 4',
    sim_to_treat: 5,
    priority: 'Urgent',
    referrer: 'Dr. Aisha Patel',
    diagnosis_code: 'C34.90',
  },
  {
    fractions: 14,
    site: 'Lakeside',
    physician: 'Dr Smith',
    date: '2023-10-13',
    diagnosis: 'prostate',
    fraction_dose: 1.88,
    adherence: true,
    technique: 'Stereotactic',
    linac: 'Machine 2',
    sim_to_treat: 10,
    priority: 'Emergency',
    referrer: 'Dr. Clara Thompson',
    diagnosis_code: 'C71.9',
  },
  {
    fractions: 23,
    site: 'Campsie',
    physician: 'Dr Smith',
    date: '2023-04-24',
    diagnosis: 'prostate',
    fraction_dose: 2.88,
    adherence: true,
    technique: 'Electrons',
    linac: 'Machine 4',
    sim_to_treat: 6,
    priority: 'Urgent',
    referrer: 'Dr. Alex Reynolds',
    diagnosis_code: 'C43.9',
  },
  {
    fractions: 14,
    site: 'Campsie',
    physician: 'Dr Wong',
    date: '2023-09-29',
    diagnosis: 'skin',
    fraction_dose: 3.09,
    adherence: true,
    technique: 'Brachytherapy',
    linac: 'Machine 4',
    sim_to_treat: 1,
    priority: 'Urgent',
    referrer: 'Dr. Victor Hernandes',
    diagnosis_code: 'C20',
  },
  {
    fractions: 12,
    site: 'Red Hills',
    physician: 'Dr Smith',
    date: '2023-03-12',
    diagnosis: 'breast',
    fraction_dose: 1.59,
    adherence: true,
    technique: 'IMRT',
    linac: 'Machine 3',
    sim_to_treat: 7,
    priority: 'Standard',
    referrer: 'Dr. Clara Thompson',
    diagnosis_code: 'C20',
  },
  {
    fractions: 12,
    site: 'Campsie',
    physician: 'Dr Smith',
    date: '2023-11-21',
    diagnosis: 'prostate',
    fraction_dose: 1.82,
    adherence: true,
    technique: 'IMRT',
    linac: 'Machine 1',
    sim_to_treat: 5,
    priority: 'Urgent',
    referrer: 'Dr. Julian Foster',
    diagnosis_code: 'C43.9',
  },
  {
    fractions: 17,
    site: 'Lakeside',
    physician: 'Dr Smith',
    date: '2023-06-23',
    diagnosis: 'breast',
    fraction_dose: 1.29,
    adherence: true,
    technique: 'Conformal',
    linac: 'Machine 3',
    sim_to_treat: 2,
    priority: 'Urgent',
    referrer: 'Dr. Victor Hernandes',
    diagnosis_code: 'C34.90',
  },
  {
    fractions: 12,
    site: 'Campsie',
    physician: 'Dr Wong',
    date: '2023-04-24',
    diagnosis: 'colorectal',
    fraction_dose: 2.36,
    adherence: true,
    technique: 'Conformal',
    linac: 'Machine 3',
    sim_to_treat: 8,
    priority: 'Emergency',
    referrer: 'Dr. Sophie Greenberg',
    diagnosis_code: 'C61',
  },
  {
    fractions: 11,
    site: 'Lakeside',
    physician: 'Dr Adams',
    date: '2023-02-27',
    diagnosis: 'colorectal',
    fraction_dose: 1.29,
    adherence: true,
    technique: 'Conformal',
    linac: 'Machine 1',
    sim_to_treat: 8,
    priority: 'Urgent',
    referrer: 'Dr. Jonathan Blake',
    diagnosis_code: 'C34.90',
  },
  {
    fractions: 22,
    site: 'Lakeside',
    physician: 'Dr Smith',
    date: '2023-08-25',
    diagnosis: 'prostate',
    fraction_dose: 2.04,
    adherence: true,
    technique: 'Stereotactic',
    linac: 'Machine 4',
    sim_to_treat: 6,
    priority: 'Standard',
    referrer: 'Dr. Aisha Patel',
    diagnosis_code: 'C20',
  },
  {
    fractions: 12,
    site: 'Lakeside',
    physician: 'Dr Lopez',
    date: '2023-01-15',
    diagnosis: 'colorectal',
    fraction_dose: 1.6,
    adherence: true,
    technique: 'Brachytherapy',
    linac: 'Machine 3',
    sim_to_treat: 9,
    priority: 'Standard',
    referrer: 'Dr. Jonathan Blake',
    diagnosis_code: 'C71.9',
  },
  {
    fractions: 25,
    site: 'Red Hills',
    physician: 'Dr Wong',
    date: '2023-12-30',
    diagnosis: 'Unspecified',
    fraction_dose: 2.88,
    adherence: true,
    technique: 'Unspecified',
    linac: 'Machine 1',
    sim_to_treat: 8,
    priority: 'Urgent',
    referrer: 'Dr. Sophie Greenberg',
    diagnosis_code: 'C61',
  },
  {
    fractions: 11,
    site: 'Campsie',
    physician: 'Dr Wong',
    date: '2023-11-15',
    diagnosis: 'colorectal',
    fraction_dose: 3.25,
    adherence: true,
    technique: 'Unspecified',
    linac: 'Machine 2',
    sim_to_treat: 1,
    priority: 'Standard',
    referrer: 'Dr. Clara Thompson',
    diagnosis_code: 'C71.9',
  },
  {
    fractions: 20,
    site: 'Lakeside',
    physician: 'Dr Smith',
    date: '2023-11-02',
    diagnosis: 'neuro',
    fraction_dose: 3.2,
    adherence: true,
    technique: 'Stereotactic',
    linac: 'Machine 4',
    sim_to_treat: 9,
    priority: 'Standard',
    referrer: 'Dr. Michael Stern',
    diagnosis_code: 'C20',
  },
  {
    fractions: 19,
    site: 'Lakeside',
    physician: 'Dr Lopez',
    date: '2023-12-04',
    diagnosis: 'Unspecified',
    fraction_dose: 3.28,
    adherence: true,
    technique: 'VMAT',
    linac: 'Machine 1',
    sim_to_treat: 9,
    priority: 'Standard',
    referrer: 'Dr. Alex Reynolds',
    diagnosis_code: 'C34.90',
  },
  {
    fractions: 10,
    site: 'Red Hills',
    physician: 'Dr Lopez',
    date: '2023-03-20',
    diagnosis: 'prostate',
    fraction_dose: 2.49,
    adherence: true,
    technique: 'Brachytherapy',
    linac: 'Machine 3',
    sim_to_treat: 5,
    priority: 'Emergency',
    referrer: 'Dr. Victor Hernandes',
    diagnosis_code: 'C79.81',
  },
  {
    fractions: 25,
    site: 'Lakeside',
    physician: 'Dr Adams',
    date: '2023-03-02',
    diagnosis: 'Unspecified',
    fraction_dose: 1.22,
    adherence: true,
    technique: 'Unspecified',
    linac: 'Machine 4',
    sim_to_treat: 5,
    priority: 'Urgent',
    referrer: 'Dr. Alex Reynolds',
    diagnosis_code: 'C43.9',
  },
  {
    fractions: 20,
    site: 'Campsie',
    physician: 'Dr Wong',
    date: '2023-04-15',
    diagnosis: 'Unspecified',
    fraction_dose: 1.89,
    adherence: true,
    technique: 'Electrons',
    linac: 'Machine 1',
    sim_to_treat: 10,
    priority: 'Emergency',
    referrer: 'Dr. Julian Foster',
    diagnosis_code: 'C79.81',
  },
  {
    fractions: 22,
    site: 'Lakeside',
    physician: 'Dr Lopez',
    date: '2023-11-10',
    diagnosis: 'metastasis',
    fraction_dose: 1.71,
    adherence: true,
    technique: 'IMRT',
    linac: 'Machine 4',
    sim_to_treat: 8,
    priority: 'Emergency',
    referrer: 'Dr. Alex Reynolds',
    diagnosis_code: 'C61',
  },
  {
    fractions: 25,
    site: 'Rosebush',
    physician: 'Dr Adams',
    date: '2023-10-05',
    diagnosis: 'breast',
    fraction_dose: 1.94,
    adherence: true,
    technique: 'Stereotactic',
    linac: 'Machine 2',
    sim_to_treat: 1,
    priority: 'Urgent',
    referrer: 'Dr. Julian Foster',
    diagnosis_code: 'C43.9',
  },
  {
    fractions: 16,
    site: 'Red Hills',
    physician: 'Dr Wong',
    date: '2023-12-30',
    diagnosis: 'skin',
    fraction_dose: 3.36,
    adherence: true,
    technique: 'Electrons',
    linac: 'Machine 3',
    sim_to_treat: 9,
    priority: 'Standard',
    referrer: 'Dr. Emily Hartman',
    diagnosis_code: 'C43.9',
  },
  {
    fractions: 18,
    site: 'Red Hills',
    physician: 'Dr Wong',
    date: '2023-09-14',
    diagnosis: 'colorectal',
    fraction_dose: 2.56,
    adherence: true,
    technique: 'IMRT',
    linac: 'Machine 1',
    sim_to_treat: 10,
    priority: 'Standard',
    referrer: 'Dr. Rachel Nguyen',
    diagnosis_code: 'C20',
  },
  {
    fractions: 13,
    site: 'Red Hills',
    physician: 'Dr Wong',
    date: '2023-09-15',
    diagnosis: 'skin',
    fraction_dose: 1.83,
    adherence: true,
    technique: 'IMRT',
    linac: 'Machine 2',
    sim_to_treat: 3,
    priority: 'Urgent',
    referrer: 'Dr. Aisha Patel',
    diagnosis_code: 'C20',
  },
  {
    fractions: 10,
    site: 'Red Hills',
    physician: 'Dr Smith',
    date: '2023-05-26',
    diagnosis: 'neuro',
    fraction_dose: 3.4,
    adherence: true,
    technique: 'Unspecified',
    linac: 'Machine 2',
    sim_to_treat: 8,
    priority: 'Standard',
    referrer: 'Dr. Sophie Greenberg',
    diagnosis_code: 'C71.9',
  },
  {
    fractions: 13,
    site: 'Campsie',
    physician: 'Dr Smith',
    date: '2023-05-16',
    diagnosis: 'skin',
    fraction_dose: 1.29,
    adherence: true,
    technique: 'Electrons',
    linac: 'Machine 1',
    sim_to_treat: 9,
    priority: 'Emergency',
    referrer: 'Dr. Emily Hartman',
    diagnosis_code: 'C61',
  },
  {
    fractions: 14,
    site: 'Lakeside',
    physician: 'Dr Smith',
    date: '2023-12-25',
    diagnosis: 'skin',
    fraction_dose: 2.71,
    adherence: true,
    technique: 'Conformal',
    linac: 'Machine 2',
    sim_to_treat: 4,
    priority: 'Standard',
    referrer: 'Dr. Aisha Patel',
    diagnosis_code: 'C61',
  },
  {
    fractions: 16,
    site: 'Campsie',
    physician: 'Dr Smith',
    date: '2023-03-22',
    diagnosis: 'skin',
    fraction_dose: 3.27,
    adherence: true,
    technique: 'IMRT',
    linac: 'Machine 1',
    sim_to_treat: 10,
    priority: 'Urgent',
    referrer: 'Dr. Rachel Nguyen',
    diagnosis_code: 'C20',
  },
  {
    fractions: 15,
    site: 'Campsie',
    physician: 'Dr Adams',
    date: '2023-11-11',
    diagnosis: 'Unspecified',
    fraction_dose: 2.85,
    adherence: true,
    technique: 'Brachytherapy',
    linac: 'Machine 2',
    sim_to_treat: 9,
    priority: 'Emergency',
    referrer: 'Dr. Sophie Greenberg',
    diagnosis_code: 'C34.90',
  },
  {
    fractions: 10,
    site: 'Lakeside',
    physician: 'Dr Lopez',
    date: '2023-04-03',
    diagnosis: 'metastasis',
    fraction_dose: 1.43,
    adherence: true,
    technique: 'Electrons',
    linac: 'Machine 3',
    sim_to_treat: 5,
    priority: 'Urgent',
    referrer: 'Dr. Jonathan Blake',
    diagnosis_code: 'C43.9',
  },
  {
    fractions: 24,
    site: 'Rosebush',
    physician: 'Dr Adams',
    date: '2023-10-18',
    diagnosis: 'prostate',
    fraction_dose: 1.88,
    adherence: true,
    technique: 'Unspecified',
    linac: 'Machine 1',
    sim_to_treat: 3,
    priority: 'Emergency',
    referrer: 'Dr. Emily Hartman',
    diagnosis_code: 'C43.9',
  },
  {
    fractions: 10,
    site: 'Red Hills',
    physician: 'Dr Adams',
    date: '2023-01-23',
    diagnosis: 'skin',
    fraction_dose: 1.63,
    adherence: true,
    technique: 'Conformal',
    linac: 'Machine 1',
    sim_to_treat: 7,
    priority: 'Emergency',
    referrer: 'Dr. Julian Foster',
    diagnosis_code: 'C34.90',
  },
  {
    fractions: 25,
    site: 'Red Hills',
    physician: 'Dr Adams',
    date: '2023-05-08',
    diagnosis: 'neuro',
    fraction_dose: 1.78,
    adherence: true,
    technique: 'Brachytherapy',
    linac: 'Machine 2',
    sim_to_treat: 3,
    priority: 'Emergency',
    referrer: 'Dr. Aisha Patel',
    diagnosis_code: 'C20',
  },
  {
    fractions: 14,
    site: 'Red Hills',
    physician: 'Dr Lopez',
    date: '2023-03-05',
    diagnosis: 'colorectal',
    fraction_dose: 1.78,
    adherence: true,
    technique: 'VMAT',
    linac: 'Machine 2',
    sim_to_treat: 7,
    priority: 'Urgent',
    referrer: 'Dr. Clara Thompson',
    diagnosis_code: 'C79.81',
  },
  {
    fractions: 17,
    site: 'Red Hills',
    physician: 'Dr Lopez',
    date: '2023-09-11',
    diagnosis: 'Unspecified',
    fraction_dose: 2.77,
    adherence: true,
    technique: 'Conformal',
    linac: 'Machine 3',
    sim_to_treat: 1,
    priority: 'Standard',
    referrer: 'Dr. Clara Thompson',
    diagnosis_code: 'C71.9',
  },
  {
    fractions: 15,
    site: 'Red Hills',
    physician: 'Dr Lopez',
    date: '2023-07-03',
    diagnosis: 'colorectal',
    fraction_dose: 2.69,
    adherence: true,
    technique: 'VMAT',
    linac: 'Machine 3',
    sim_to_treat: 7,
    priority: 'Emergency',
    referrer: 'Dr. Victor Hernandes',
    diagnosis_code: 'C61',
  },
  {
    fractions: 21,
    site: 'Lakeside',
    physician: 'Dr Smith',
    date: '2023-07-29',
    diagnosis: 'breast',
    fraction_dose: 2.2,
    adherence: true,
    technique: 'VMAT',
    linac: 'Machine 3',
    sim_to_treat: 1,
    priority: 'Urgent',
    referrer: 'Dr. Jonathan Blake',
    diagnosis_code: 'C20',
  },
  {
    fractions: 14,
    site: 'Red Hills',
    physician: 'Dr Smith',
    date: '2023-10-09',
    diagnosis: 'colorectal',
    fraction_dose: 2.52,
    adherence: true,
    technique: 'Conformal',
    linac: 'Machine 2',
    sim_to_treat: 3,
    priority: 'Emergency',
    referrer: 'Dr. Jonathan Blake',
    diagnosis_code: 'C43.9',
  },
  {
    fractions: 13,
    site: 'Campsie',
    physician: 'Dr Lopez',
    date: '2023-12-03',
    diagnosis: 'neuro',
    fraction_dose: 2.9,
    adherence: true,
    technique: 'Unspecified',
    linac: 'Machine 1',
    sim_to_treat: 2,
    priority: 'Standard',
    referrer: 'Dr. Alex Reynolds',
    diagnosis_code: 'C43.9',
  },
  {
    fractions: 20,
    site: 'Campsie',
    physician: 'Dr Wong',
    date: '2023-08-14',
    diagnosis: 'Unspecified',
    fraction_dose: 2.27,
    adherence: false,
    technique: 'VMAT',
    linac: 'Machine 3',
    sim_to_treat: 2,
    priority: 'Urgent',
    referrer: 'Dr. Rachel Nguyen',
    diagnosis_code: 'C61',
  },
  {
    fractions: 23,
    site: 'Lakeside',
    physician: 'Dr Adams',
    date: '2023-01-08',
    diagnosis: 'skin',
    fraction_dose: 2.03,
    adherence: true,
    technique: 'Brachytherapy',
    linac: 'Machine 4',
    sim_to_treat: 2,
    priority: 'Standard',
    referrer: 'Dr. Alex Reynolds',
    diagnosis_code: 'C20',
  },
  {
    fractions: 13,
    site: 'Campsie',
    physician: 'Dr Wong',
    date: '2023-12-23',
    diagnosis: 'Unspecified',
    fraction_dose: 1.34,
    adherence: true,
    technique: 'VMAT',
    linac: 'Machine 2',
    sim_to_treat: 9,
    priority: 'Urgent',
    referrer: 'Dr. Rachel Nguyen',
    diagnosis_code: 'C79.81',
  },
  {
    fractions: 21,
    site: 'Campsie',
    physician: 'Dr Lopez',
    date: '2023-01-14',
    diagnosis: 'metastasis',
    fraction_dose: 2.21,
    adherence: true,
    technique: 'Unspecified',
    linac: 'Machine 4',
    sim_to_treat: 10,
    priority: 'Emergency',
    referrer: 'Dr. Julian Foster',
    diagnosis_code: 'C79.81',
  },
  {
    fractions: 10,
    site: 'Lakeside',
    physician: 'Dr Wong',
    date: '2023-03-28',
    diagnosis: 'skin',
    fraction_dose: 1.72,
    adherence: true,
    technique: 'VMAT',
    linac: 'Machine 2',
    sim_to_treat: 7,
    priority: 'Urgent',
    referrer: 'Dr. Sophie Greenberg',
    diagnosis_code: 'C34.90',
  },
  {
    fractions: 19,
    site: 'Lakeside',
    physician: 'Dr Adams',
    date: '2023-09-20',
    diagnosis: 'metastasis',
    fraction_dose: 2.39,
    adherence: true,
    technique: 'Electrons',
    linac: 'Machine 4',
    sim_to_treat: 8,
    priority: 'Standard',
    referrer: 'Dr. Victor Hernandes',
    diagnosis_code: 'C43.9',
  },
  {
    fractions: 12,
    site: 'Red Hills',
    physician: 'Dr Lopez',
    date: '2023-12-20',
    diagnosis: 'breast',
    fraction_dose: 2.11,
    adherence: true,
    technique: 'IMRT',
    linac: 'Machine 1',
    sim_to_treat: 9,
    priority: 'Emergency',
    referrer: 'Dr. Jonathan Blake',
    diagnosis_code: 'C20',
  },
  {
    fractions: 12,
    site: 'Lakeside',
    physician: 'Dr Smith',
    date: '2023-05-21',
    diagnosis: 'breast',
    fraction_dose: 2.91,
    adherence: true,
    technique: 'Unspecified',
    linac: 'Machine 2',
    sim_to_treat: 1,
    priority: 'Standard',
    referrer: 'Dr. Julian Foster',
    diagnosis_code: 'C71.9',
  },
  {
    fractions: 15,
    site: 'Rosebush',
    physician: 'Dr Smith',
    date: '2023-10-24',
    diagnosis: 'metastasis',
    fraction_dose: 2.27,
    adherence: true,
    technique: 'Electrons',
    linac: 'Machine 3',
    sim_to_treat: 1,
    priority: 'Standard',
    referrer: 'Dr. Rachel Nguyen',
    diagnosis_code: 'C71.9',
  },
  {
    fractions: 14,
    site: 'Lakeside',
    physician: 'Dr Lopez',
    date: '2023-04-22',
    diagnosis: 'Unspecified',
    fraction_dose: 3.05,
    adherence: false,
    technique: 'IMRT',
    linac: 'Machine 2',
    sim_to_treat: 1,
    priority: 'Urgent',
    referrer: 'Dr. Sophie Greenberg',
    diagnosis_code: 'C61',
  },
  {
    fractions: 20,
    site: 'Lakeside',
    physician: 'Dr Smith',
    date: '2023-06-30',
    diagnosis: 'colorectal',
    fraction_dose: 3.29,
    adherence: true,
    technique: 'Unspecified',
    linac: 'Machine 4',
    sim_to_treat: 6,
    priority: 'Urgent',
    referrer: 'Dr. Rachel Nguyen',
    diagnosis_code: 'C79.81',
  },
  {
    fractions: 11,
    site: 'Rosebush',
    physician: 'Dr Adams',
    date: '2023-08-28',
    diagnosis: 'Unspecified',
    fraction_dose: 2.07,
    adherence: true,
    technique: 'Conformal',
    linac: 'Machine 1',
    sim_to_treat: 5,
    priority: 'Standard',
    referrer: 'Dr. Rachel Nguyen',
    diagnosis_code: 'C20',
  },
  {
    fractions: 15,
    site: 'Lakeside',
    physician: 'Dr Lopez',
    date: '2023-04-03',
    diagnosis: 'skin',
    fraction_dose: 2.1,
    adherence: true,
    technique: 'Brachytherapy',
    linac: 'Machine 4',
    sim_to_treat: 2,
    priority: 'Urgent',
    referrer: 'Dr. Rachel Nguyen',
    diagnosis_code: 'C79.81',
  },
  {
    fractions: 15,
    site: 'Lakeside',
    physician: 'Dr Adams',
    date: '2023-07-08',
    diagnosis: 'prostate',
    fraction_dose: 1.48,
    adherence: true,
    technique: 'Brachytherapy',
    linac: 'Machine 3',
    sim_to_treat: 4,
    priority: 'Urgent',
    referrer: 'Dr. Clara Thompson',
    diagnosis_code: 'C61',
  },
  {
    fractions: 19,
    site: 'Lakeside',
    physician: 'Dr Adams',
    date: '2023-07-19',
    diagnosis: 'metastasis',
    fraction_dose: 1.39,
    adherence: true,
    technique: 'Stereotactic',
    linac: 'Machine 4',
    sim_to_treat: 1,
    priority: 'Standard',
    referrer: 'Dr. Alex Reynolds',
    diagnosis_code: 'C79.81',
  },
  {
    fractions: 22,
    site: 'Red Hills',
    physician: 'Dr Lopez',
    date: '2023-07-01',
    diagnosis: 'breast',
    fraction_dose: 2.2,
    adherence: true,
    technique: 'Conformal',
    linac: 'Machine 3',
    sim_to_treat: 10,
    priority: 'Emergency',
    referrer: 'Dr. Alex Reynolds',
    diagnosis_code: 'C20',
  },
  {
    fractions: 21,
    site: 'Red Hills',
    physician: 'Dr Wong',
    date: '2023-03-21',
    diagnosis: 'breast',
    fraction_dose: 1.48,
    adherence: true,
    technique: 'IMRT',
    linac: 'Machine 1',
    sim_to_treat: 4,
    priority: 'Emergency',
    referrer: 'Dr. Rachel Nguyen',
    diagnosis_code: 'C43.9',
  },
  {
    fractions: 18,
    site: 'Rosebush',
    physician: 'Dr Smith',
    date: '2023-12-13',
    diagnosis: 'metastasis',
    fraction_dose: 3.03,
    adherence: true,
    technique: 'Conformal',
    linac: 'Machine 2',
    sim_to_treat: 7,
    priority: 'Standard',
    referrer: 'Dr. Julian Foster',
    diagnosis_code: 'C20',
  },
  {
    fractions: 22,
    site: 'Campsie',
    physician: 'Dr Lopez',
    date: '2023-05-04',
    diagnosis: 'prostate',
    fraction_dose: 1.21,
    adherence: true,
    technique: 'Brachytherapy',
    linac: 'Machine 4',
    sim_to_treat: 5,
    priority: 'Urgent',
    referrer: 'Dr. Rachel Nguyen',
    diagnosis_code: 'C61',
  },
  {
    fractions: 19,
    site: 'Red Hills',
    physician: 'Dr Smith',
    date: '2023-11-19',
    diagnosis: 'Unspecified',
    fraction_dose: 2.53,
    adherence: true,
    technique: 'Brachytherapy',
    linac: 'Machine 1',
    sim_to_treat: 7,
    priority: 'Standard',
    referrer: 'Dr. Victor Hernandes',
    diagnosis_code: 'C20',
  },
  {
    fractions: 25,
    site: 'Campsie',
    physician: 'Dr Lopez',
    date: '2023-07-26',
    diagnosis: 'colorectal',
    fraction_dose: 2.48,
    adherence: true,
    technique: 'Brachytherapy',
    linac: 'Machine 1',
    sim_to_treat: 7,
    priority: 'Emergency',
    referrer: 'Dr. Emily Hartman',
    diagnosis_code: 'C34.90',
  },
  {
    fractions: 15,
    site: 'Red Hills',
    physician: 'Dr Smith',
    date: '2023-04-07',
    diagnosis: 'Unspecified',
    fraction_dose: 3.1,
    adherence: true,
    technique: 'Brachytherapy',
    linac: 'Machine 2',
    sim_to_treat: 8,
    priority: 'Emergency',
    referrer: 'Dr. Michael Stern',
    diagnosis_code: 'C61',
  },
  {
    fractions: 19,
    site: 'Rosebush',
    physician: 'Dr Wong',
    date: '2023-10-23',
    diagnosis: 'breast',
    fraction_dose: 2.01,
    adherence: true,
    technique: 'Brachytherapy',
    linac: 'Machine 1',
    sim_to_treat: 10,
    priority: 'Emergency',
    referrer: 'Dr. Michael Stern',
    diagnosis_code: 'C61',
  },
  {
    fractions: 25,
    site: 'Rosebush',
    physician: 'Dr Adams',
    date: '2023-07-26',
    diagnosis: 'metastasis',
    fraction_dose: 2.55,
    adherence: true,
    technique: 'Brachytherapy',
    linac: 'Machine 2',
    sim_to_treat: 5,
    priority: 'Emergency',
    referrer: 'Dr. Alex Reynolds',
    diagnosis_code: 'C20',
  },
  {
    fractions: 16,
    site: 'Red Hills',
    physician: 'Dr Smith',
    date: '2023-03-19',
    diagnosis: 'prostate',
    fraction_dose: 1.58,
    adherence: true,
    technique: 'Brachytherapy',
    linac: 'Machine 4',
    sim_to_treat: 10,
    priority: 'Standard',
    referrer: 'Dr. Clara Thompson',
    diagnosis_code: 'C71.9',
  },
  {
    fractions: 17,
    site: 'Rosebush',
    physician: 'Dr Adams',
    date: '2023-09-04',
    diagnosis: 'breast',
    fraction_dose: 1.48,
    adherence: true,
    technique: 'Electrons',
    linac: 'Machine 1',
    sim_to_treat: 10,
    priority: 'Emergency',
    referrer: 'Dr. Julian Foster',
    diagnosis_code: 'C34.90',
  },
  {
    fractions: 18,
    site: 'Campsie',
    physician: 'Dr Adams',
    date: '2023-05-14',
    diagnosis: 'skin',
    fraction_dose: 1.5,
    adherence: true,
    technique: 'VMAT',
    linac: 'Machine 1',
    sim_to_treat: 7,
    priority: 'Emergency',
    referrer: 'Dr. Rachel Nguyen',
    diagnosis_code: 'C43.9',
  },
  {
    fractions: 23,
    site: 'Red Hills',
    physician: 'Dr Adams',
    date: '2023-09-03',
    diagnosis: 'neuro',
    fraction_dose: 3.11,
    adherence: true,
    technique: 'Unspecified',
    linac: 'Machine 2',
    sim_to_treat: 9,
    priority: 'Standard',
    referrer: 'Dr. Sophie Greenberg',
    diagnosis_code: 'C34.90',
  },
  {
    fractions: 12,
    site: 'Red Hills',
    physician: 'Dr Adams',
    date: '2023-07-13',
    diagnosis: 'Unspecified',
    fraction_dose: 1.9,
    adherence: true,
    technique: 'Unspecified',
    linac: 'Machine 2',
    sim_to_treat: 2,
    priority: 'Emergency',
    referrer: 'Dr. Victor Hernandes',
    diagnosis_code: 'C34.90',
  },
  {
    fractions: 23,
    site: 'Campsie',
    physician: 'Dr Smith',
    date: '2023-02-06',
    diagnosis: 'colorectal',
    fraction_dose: 1.56,
    adherence: true,
    technique: 'Electrons',
    linac: 'Machine 4',
    sim_to_treat: 10,
    priority: 'Urgent',
    referrer: 'Dr. Alex Reynolds',
    diagnosis_code: 'C71.9',
  },
  {
    fractions: 23,
    site: 'Red Hills',
    physician: 'Dr Adams',
    date: '2023-11-04',
    diagnosis: 'colorectal',
    fraction_dose: 2.0,
    adherence: true,
    technique: 'Conformal',
    linac: 'Machine 1',
    sim_to_treat: 3,
    priority: 'Emergency',
    referrer: 'Dr. Jonathan Blake',
    diagnosis_code: 'C43.9',
  },
  {
    fractions: 19,
    site: 'Lakeside',
    physician: 'Dr Wong',
    date: '2023-11-06',
    diagnosis: 'metastasis',
    fraction_dose: 2.46,
    adherence: true,
    technique: 'Conformal',
    linac: 'Machine 2',
    sim_to_treat: 5,
    priority: 'Urgent',
    referrer: 'Dr. Victor Hernandes',
    diagnosis_code: 'C61',
  },
  {
    fractions: 20,
    site: 'Campsie',
    physician: 'Dr Smith',
    date: '2023-04-17',
    diagnosis: 'neuro',
    fraction_dose: 2.67,
    adherence: true,
    technique: 'Unspecified',
    linac: 'Machine 2',
    sim_to_treat: 7,
    priority: 'Urgent',
    referrer: 'Dr. Julian Foster',
    diagnosis_code: 'C71.9',
  },
  {
    fractions: 11,
    site: 'Red Hills',
    physician: 'Dr Smith',
    date: '2023-11-21',
    diagnosis: 'breast',
    fraction_dose: 2.16,
    adherence: true,
    technique: 'IMRT',
    linac: 'Machine 1',
    sim_to_treat: 1,
    priority: 'Standard',
    referrer: 'Dr. Clara Thompson',
    diagnosis_code: 'C71.9',
  },
  {
    fractions: 23,
    site: 'Rosebush',
    physician: 'Dr Lopez',
    date: '2023-10-15',
    diagnosis: 'neuro',
    fraction_dose: 1.65,
    adherence: true,
    technique: 'IMRT',
    linac: 'Machine 1',
    sim_to_treat: 9,
    priority: 'Emergency',
    referrer: 'Dr. Sophie Greenberg',
    diagnosis_code: 'C20',
  },
  {
    fractions: 17,
    site: 'Campsie',
    physician: 'Dr Smith',
    date: '2023-05-12',
    diagnosis: 'colorectal',
    fraction_dose: 3.05,
    adherence: true,
    technique: 'Unspecified',
    linac: 'Machine 3',
    sim_to_treat: 5,
    priority: 'Standard',
    referrer: 'Dr. Alex Reynolds',
    diagnosis_code: 'C34.90',
  },
  {
    fractions: 18,
    site: 'Lakeside',
    physician: 'Dr Lopez',
    date: '2023-10-05',
    diagnosis: 'colorectal',
    fraction_dose: 1.2,
    adherence: true,
    technique: 'Electrons',
    linac: 'Machine 4',
    sim_to_treat: 1,
    priority: 'Standard',
    referrer: 'Dr. Julian Foster',
    diagnosis_code: 'C61',
  },
  {
    fractions: 23,
    site: 'Lakeside',
    physician: 'Dr Wong',
    date: '2023-12-01',
    diagnosis: 'breast',
    fraction_dose: 2.95,
    adherence: true,
    technique: 'VMAT',
    linac: 'Machine 1',
    sim_to_treat: 8,
    priority: 'Urgent',
    referrer: 'Dr. Sophie Greenberg',
    diagnosis_code: 'C43.9',
  },
  {
    fractions: 25,
    site: 'Lakeside',
    physician: 'Dr Wong',
    date: '2023-09-07',
    diagnosis: 'metastasis',
    fraction_dose: 3.17,
    adherence: true,
    technique: 'IMRT',
    linac: 'Machine 4',
    sim_to_treat: 4,
    priority: 'Emergency',
    referrer: 'Dr. Michael Stern',
    diagnosis_code: 'C43.9',
  },
  {
    fractions: 22,
    site: 'Red Hills',
    physician: 'Dr Smith',
    date: '2023-04-07',
    diagnosis: 'skin',
    fraction_dose: 2.25,
    adherence: true,
    technique: 'Conformal',
    linac: 'Machine 4',
    sim_to_treat: 1,
    priority: 'Urgent',
    referrer: 'Dr. Rachel Nguyen',
    diagnosis_code: 'C79.81',
  },
  {
    fractions: 10,
    site: 'Rosebush',
    physician: 'Dr Wong',
    date: '2023-12-04',
    diagnosis: 'Unspecified',
    fraction_dose: 1.35,
    adherence: true,
    technique: 'IMRT',
    linac: 'Machine 4',
    sim_to_treat: 6,
    priority: 'Urgent',
    referrer: 'Dr. Jonathan Blake',
    diagnosis_code: 'C43.9',
  },
  {
    fractions: 20,
    site: 'Rosebush',
    physician: 'Dr Adams',
    date: '2023-10-29',
    diagnosis: 'prostate',
    fraction_dose: 3.0,
    adherence: true,
    technique: 'IMRT',
    linac: 'Machine 1',
    sim_to_treat: 1,
    priority: 'Emergency',
    referrer: 'Dr. Alex Reynolds',
    diagnosis_code: 'C71.9',
  },
  {
    fractions: 14,
    site: 'Campsie',
    physician: 'Dr Smith',
    date: '2023-10-19',
    diagnosis: 'prostate',
    fraction_dose: 3.2,
    adherence: true,
    technique: 'IMRT',
    linac: 'Machine 4',
    sim_to_treat: 5,
    priority: 'Standard',
    referrer: 'Dr. Aisha Patel',
    diagnosis_code: 'C61',
  },
  {
    fractions: 11,
    site: 'Lakeside',
    physician: 'Dr Smith',
    date: '2023-03-02',
    diagnosis: 'metastasis',
    fraction_dose: 1.7,
    adherence: true,
    technique: 'Electrons',
    linac: 'Machine 4',
    sim_to_treat: 4,
    priority: 'Emergency',
    referrer: 'Dr. Alex Reynolds',
    diagnosis_code: 'C61',
  },
  {
    fractions: 24,
    site: 'Campsie',
    physician: 'Dr Lopez',
    date: '2023-12-30',
    diagnosis: 'colorectal',
    fraction_dose: 2.14,
    adherence: true,
    technique: 'VMAT',
    linac: 'Machine 2',
    sim_to_treat: 4,
    priority: 'Urgent',
    referrer: 'Dr. Clara Thompson',
    diagnosis_code: 'C34.90',
  },
  {
    fractions: 15,
    site: 'Red Hills',
    physician: 'Dr Smith',
    date: '2023-11-25',
    diagnosis: 'metastasis',
    fraction_dose: 2.1,
    adherence: true,
    technique: 'Conformal',
    linac: 'Machine 2',
    sim_to_treat: 9,
    priority: 'Urgent',
    referrer: 'Dr. Julian Foster',
    diagnosis_code: 'C79.81',
  },
  {
    fractions: 25,
    site: 'Campsie',
    physician: 'Dr Wong',
    date: '2023-09-02',
    diagnosis: 'metastasis',
    fraction_dose: 2.93,
    adherence: true,
    technique: 'VMAT',
    linac: 'Machine 2',
    sim_to_treat: 7,
    priority: 'Emergency',
    referrer: 'Dr. Sophie Greenberg',
    diagnosis_code: 'C43.9',
  },
  {
    fractions: 22,
    site: 'Red Hills',
    physician: 'Dr Wong',
    date: '2023-03-22',
    diagnosis: 'neuro',
    fraction_dose: 2.52,
    adherence: true,
    technique: 'VMAT',
    linac: 'Machine 4',
    sim_to_treat: 8,
    priority: 'Standard',
    referrer: 'Dr. Sophie Greenberg',
    diagnosis_code: 'C61',
  },
  {
    fractions: 18,
    site: 'Red Hills',
    physician: 'Dr Adams',
    date: '2023-06-25',
    diagnosis: 'Unspecified',
    fraction_dose: 2.47,
    adherence: true,
    technique: 'Electrons',
    linac: 'Machine 4',
    sim_to_treat: 9,
    priority: 'Standard',
    referrer: 'Dr. Aisha Patel',
    diagnosis_code: 'C43.9',
  },
  {
    fractions: 20,
    site: 'Rosebush',
    physician: 'Dr Adams',
    date: '2023-05-04',
    diagnosis: 'Unspecified',
    fraction_dose: 3.08,
    adherence: true,
    technique: 'Brachytherapy',
    linac: 'Machine 4',
    sim_to_treat: 8,
    priority: 'Urgent',
    referrer: 'Dr. Sophie Greenberg',
    diagnosis_code: 'C61',
  },
  {
    fractions: 22,
    site: 'Lakeside',
    physician: 'Dr Lopez',
    date: '2023-11-18',
    diagnosis: 'breast',
    fraction_dose: 2.08,
    adherence: true,
    technique: 'VMAT',
    linac: 'Machine 2',
    sim_to_treat: 8,
    priority: 'Emergency',
    referrer: 'Dr. Alex Reynolds',
    diagnosis_code: 'C61',
  },
  {
    fractions: 15,
    site: 'Campsie',
    physician: 'Dr Wong',
    date: '2023-09-05',
    diagnosis: 'colorectal',
    fraction_dose: 2.54,
    adherence: true,
    technique: 'Stereotactic',
    linac: 'Machine 3',
    sim_to_treat: 4,
    priority: 'Urgent',
    referrer: 'Dr. Rachel Nguyen',
    diagnosis_code: 'C61',
  },
  {
    fractions: 11,
    site: 'Lakeside',
    physician: 'Dr Smith',
    date: '2023-04-01',
    diagnosis: 'colorectal',
    fraction_dose: 1.32,
    adherence: true,
    technique: 'VMAT',
    linac: 'Machine 3',
    sim_to_treat: 3,
    priority: 'Emergency',
    referrer: 'Dr. Julian Foster',
    diagnosis_code: 'C61',
  },
  {
    fractions: 16,
    site: 'Red Hills',
    physician: 'Dr Adams',
    date: '2023-10-05',
    diagnosis: 'metastasis',
    fraction_dose: 2.7,
    adherence: true,
    technique: 'Conformal',
    linac: 'Machine 1',
    sim_to_treat: 5,
    priority: 'Emergency',
    referrer: 'Dr. Rachel Nguyen',
    diagnosis_code: 'C79.81',
  },
  {
    fractions: 17,
    site: 'Red Hills',
    physician: 'Dr Wong',
    date: '2023-01-15',
    diagnosis: 'skin',
    fraction_dose: 2.36,
    adherence: true,
    technique: 'IMRT',
    linac: 'Machine 1',
    sim_to_treat: 10,
    priority: 'Emergency',
    referrer: 'Dr. Aisha Patel',
    diagnosis_code: 'C71.9',
  },
  {
    fractions: 23,
    site: 'Lakeside',
    physician: 'Dr Adams',
    date: '2023-11-24',
    diagnosis: 'prostate',
    fraction_dose: 2.6,
    adherence: true,
    technique: 'VMAT',
    linac: 'Machine 4',
    sim_to_treat: 2,
    priority: 'Standard',
    referrer: 'Dr. Victor Hernandes',
    diagnosis_code: 'C71.9',
  },
  {
    fractions: 22,
    site: 'Campsie',
    physician: 'Dr Lopez',
    date: '2023-04-27',
    diagnosis: 'Unspecified',
    fraction_dose: 3.37,
    adherence: true,
    technique: 'VMAT',
    linac: 'Machine 2',
    sim_to_treat: 10,
    priority: 'Urgent',
    referrer: 'Dr. Clara Thompson',
    diagnosis_code: 'C20',
  },
  {
    fractions: 24,
    site: 'Red Hills',
    physician: 'Dr Smith',
    date: '2023-05-07',
    diagnosis: 'neuro',
    fraction_dose: 2.41,
    adherence: true,
    technique: 'Brachytherapy',
    linac: 'Machine 4',
    sim_to_treat: 7,
    priority: 'Standard',
    referrer: 'Dr. Julian Foster',
    diagnosis_code: 'C79.81',
  },
  {
    fractions: 17,
    site: 'Lakeside',
    physician: 'Dr Wong',
    date: '2023-03-29',
    diagnosis: 'Unspecified',
    fraction_dose: 2.44,
    adherence: true,
    technique: 'Brachytherapy',
    linac: 'Machine 2',
    sim_to_treat: 8,
    priority: 'Standard',
    referrer: 'Dr. Victor Hernandes',
    diagnosis_code: 'C79.81',
  },
  {
    fractions: 21,
    site: 'Campsie',
    physician: 'Dr Smith',
    date: '2023-04-14',
    diagnosis: 'Unspecified',
    fraction_dose: 1.81,
    adherence: true,
    technique: 'Conformal',
    linac: 'Machine 1',
    sim_to_treat: 1,
    priority: 'Urgent',
    referrer: 'Dr. Victor Hernandes',
    diagnosis_code: 'C43.9',
  },
  {
    fractions: 23,
    site: 'Red Hills',
    physician: 'Dr Smith',
    date: '2023-04-26',
    diagnosis: 'neuro',
    fraction_dose: 2.14,
    adherence: true,
    technique: 'Unspecified',
    linac: 'Machine 3',
    sim_to_treat: 9,
    priority: 'Standard',
    referrer: 'Dr. Alex Reynolds',
    diagnosis_code: 'C79.81',
  },
  {
    fractions: 15,
    site: 'Red Hills',
    physician: 'Dr Lopez',
    date: '2023-07-19',
    diagnosis: 'metastasis',
    fraction_dose: 1.48,
    adherence: true,
    technique: 'Electrons',
    linac: 'Machine 3',
    sim_to_treat: 8,
    priority: 'Urgent',
    referrer: 'Dr. Michael Stern',
    diagnosis_code: 'C61',
  },
  {
    fractions: 20,
    site: 'Lakeside',
    physician: 'Dr Adams',
    date: '2023-02-11',
    diagnosis: 'prostate',
    fraction_dose: 1.37,
    adherence: true,
    technique: 'Brachytherapy',
    linac: 'Machine 4',
    sim_to_treat: 5,
    priority: 'Standard',
    referrer: 'Dr. Julian Foster',
    diagnosis_code: 'C71.9',
  },
  {
    fractions: 14,
    site: 'Red Hills',
    physician: 'Dr Adams',
    date: '2023-10-27',
    diagnosis: 'Unspecified',
    fraction_dose: 3.31,
    adherence: true,
    technique: 'IMRT',
    linac: 'Machine 3',
    sim_to_treat: 6,
    priority: 'Urgent',
    referrer: 'Dr. Emily Hartman',
    diagnosis_code: 'C34.90',
  },
  {
    fractions: 10,
    site: 'Rosebush',
    physician: 'Dr Wong',
    date: '2023-12-28',
    diagnosis: 'breast',
    fraction_dose: 3.08,
    adherence: true,
    technique: 'Conformal',
    linac: 'Machine 1',
    sim_to_treat: 9,
    priority: 'Emergency',
    referrer: 'Dr. Victor Hernandes',
    diagnosis_code: 'C34.90',
  },
  {
    fractions: 19,
    site: 'Rosebush',
    physician: 'Dr Lopez',
    date: '2023-05-07',
    diagnosis: 'colorectal',
    fraction_dose: 3.09,
    adherence: true,
    technique: 'VMAT',
    linac: 'Machine 3',
    sim_to_treat: 6,
    priority: 'Emergency',
    referrer: 'Dr. Julian Foster',
    diagnosis_code: 'C71.9',
  },
  {
    fractions: 17,
    site: 'Rosebush',
    physician: 'Dr Smith',
    date: '2023-03-01',
    diagnosis: 'metastasis',
    fraction_dose: 2.86,
    adherence: true,
    technique: 'Unspecified',
    linac: 'Machine 3',
    sim_to_treat: 7,
    priority: 'Standard',
    referrer: 'Dr. Michael Stern',
    diagnosis_code: 'C34.90',
  },
  {
    fractions: 14,
    site: 'Red Hills',
    physician: 'Dr Wong',
    date: '2023-02-07',
    diagnosis: 'skin',
    fraction_dose: 2.69,
    adherence: true,
    technique: 'VMAT',
    linac: 'Machine 2',
    sim_to_treat: 1,
    priority: 'Standard',
    referrer: 'Dr. Michael Stern',
    diagnosis_code: 'C71.9',
  },
  {
    fractions: 15,
    site: 'Rosebush',
    physician: 'Dr Smith',
    date: '2023-02-01',
    diagnosis: 'prostate',
    fraction_dose: 3.23,
    adherence: true,
    technique: 'Unspecified',
    linac: 'Machine 3',
    sim_to_treat: 9,
    priority: 'Urgent',
    referrer: 'Dr. Emily Hartman',
    diagnosis_code: 'C43.9',
  },
  {
    fractions: 25,
    site: 'Lakeside',
    physician: 'Dr Wong',
    date: '2023-10-16',
    diagnosis: 'metastasis',
    fraction_dose: 2.44,
    adherence: true,
    technique: 'Brachytherapy',
    linac: 'Machine 3',
    sim_to_treat: 8,
    priority: 'Urgent',
    referrer: 'Dr. Victor Hernandes',
    diagnosis_code: 'C43.9',
  },
  {
    fractions: 11,
    site: 'Campsie',
    physician: 'Dr Smith',
    date: '2023-07-25',
    diagnosis: 'neuro',
    fraction_dose: 2.09,
    adherence: true,
    technique: 'Brachytherapy',
    linac: 'Machine 3',
    sim_to_treat: 5,
    priority: 'Urgent',
    referrer: 'Dr. Emily Hartman',
    diagnosis_code: 'C43.9',
  },
  {
    fractions: 12,
    site: 'Lakeside',
    physician: 'Dr Wong',
    date: '2023-10-13',
    diagnosis: 'metastasis',
    fraction_dose: 1.25,
    adherence: true,
    technique: 'Unspecified',
    linac: 'Machine 2',
    sim_to_treat: 9,
    priority: 'Standard',
    referrer: 'Dr. Julian Foster',
    diagnosis_code: 'C71.9',
  },
  {
    fractions: 14,
    site: 'Campsie',
    physician: 'Dr Lopez',
    date: '2023-05-02',
    diagnosis: 'colorectal',
    fraction_dose: 1.42,
    adherence: true,
    technique: 'IMRT',
    linac: 'Machine 1',
    sim_to_treat: 10,
    priority: 'Emergency',
    referrer: 'Dr. Victor Hernandes',
    diagnosis_code: 'C43.9',
  },
  {
    fractions: 25,
    site: 'Red Hills',
    physician: 'Dr Wong',
    date: '2023-02-18',
    diagnosis: 'colorectal',
    fraction_dose: 2.05,
    adherence: true,
    technique: 'Conformal',
    linac: 'Machine 3',
    sim_to_treat: 7,
    priority: 'Emergency',
    referrer: 'Dr. Jonathan Blake',
    diagnosis_code: 'C71.9',
  },
  {
    fractions: 13,
    site: 'Lakeside',
    physician: 'Dr Smith',
    date: '2023-06-15',
    diagnosis: 'breast',
    fraction_dose: 1.64,
    adherence: true,
    technique: 'Electrons',
    linac: 'Machine 3',
    sim_to_treat: 5,
    priority: 'Urgent',
    referrer: 'Dr. Aisha Patel',
    diagnosis_code: 'C20',
  },
  {
    fractions: 19,
    site: 'Rosebush',
    physician: 'Dr Lopez',
    date: '2023-04-03',
    diagnosis: 'colorectal',
    fraction_dose: 1.81,
    adherence: true,
    technique: 'IMRT',
    linac: 'Machine 1',
    sim_to_treat: 6,
    priority: 'Standard',
    referrer: 'Dr. Julian Foster',
    diagnosis_code: 'C43.9',
  },
  {
    fractions: 13,
    site: 'Campsie',
    physician: 'Dr Smith',
    date: '2023-05-21',
    diagnosis: 'prostate',
    fraction_dose: 1.78,
    adherence: true,
    technique: 'IMRT',
    linac: 'Machine 3',
    sim_to_treat: 6,
    priority: 'Emergency',
    referrer: 'Dr. Michael Stern',
    diagnosis_code: 'C20',
  },
  {
    fractions: 10,
    site: 'Lakeside',
    physician: 'Dr Adams',
    date: '2023-09-08',
    diagnosis: 'neuro',
    fraction_dose: 1.74,
    adherence: true,
    technique: 'Conformal',
    linac: 'Machine 2',
    sim_to_treat: 10,
    priority: 'Emergency',
    referrer: 'Dr. Michael Stern',
    diagnosis_code: 'C61',
  },
  {
    fractions: 11,
    site: 'Rosebush',
    physician: 'Dr Lopez',
    date: '2023-09-16',
    diagnosis: 'metastasis',
    fraction_dose: 1.36,
    adherence: true,
    technique: 'Unspecified',
    linac: 'Machine 2',
    sim_to_treat: 6,
    priority: 'Emergency',
    referrer: 'Dr. Rachel Nguyen',
    diagnosis_code: 'C79.81',
  },
  {
    fractions: 25,
    site: 'Rosebush',
    physician: 'Dr Adams',
    date: '2023-03-06',
    diagnosis: 'Unspecified',
    fraction_dose: 2.62,
    adherence: true,
    technique: 'IMRT',
    linac: 'Machine 4',
    sim_to_treat: 9,
    priority: 'Urgent',
    referrer: 'Dr. Jonathan Blake',
    diagnosis_code: 'C43.9',
  },
  {
    fractions: 25,
    site: 'Lakeside',
    physician: 'Dr Lopez',
    date: '2023-01-13',
    diagnosis: 'colorectal',
    fraction_dose: 2.44,
    adherence: true,
    technique: 'Electrons',
    linac: 'Machine 3',
    sim_to_treat: 10,
    priority: 'Emergency',
    referrer: 'Dr. Alex Reynolds',
    diagnosis_code: 'C71.9',
  },
  {
    fractions: 20,
    site: 'Rosebush',
    physician: 'Dr Lopez',
    date: '2023-04-23',
    diagnosis: 'metastasis',
    fraction_dose: 2.5,
    adherence: true,
    technique: 'Unspecified',
    linac: 'Machine 1',
    sim_to_treat: 5,
    priority: 'Urgent',
    referrer: 'Dr. Alex Reynolds',
    diagnosis_code: 'C79.81',
  },
  {
    fractions: 20,
    site: 'Campsie',
    physician: 'Dr Smith',
    date: '2023-09-23',
    diagnosis: 'skin',
    fraction_dose: 2.6,
    adherence: true,
    technique: 'Conformal',
    linac: 'Machine 2',
    sim_to_treat: 9,
    priority: 'Standard',
    referrer: 'Dr. Jonathan Blake',
    diagnosis_code: 'C43.9',
  },
  {
    fractions: 12,
    site: 'Rosebush',
    physician: 'Dr Lopez',
    date: '2023-02-02',
    diagnosis: 'Unspecified',
    fraction_dose: 2.28,
    adherence: true,
    technique: 'VMAT',
    linac: 'Machine 3',
    sim_to_treat: 4,
    priority: 'Standard',
    referrer: 'Dr. Sophie Greenberg',
    diagnosis_code: 'C79.81',
  },
  {
    fractions: 21,
    site: 'Red Hills',
    physician: 'Dr Lopez',
    date: '2023-11-06',
    diagnosis: 'colorectal',
    fraction_dose: 2.53,
    adherence: true,
    technique: 'Unspecified',
    linac: 'Machine 3',
    sim_to_treat: 6,
    priority: 'Standard',
    referrer: 'Dr. Jonathan Blake',
    diagnosis_code: 'C79.81',
  },
  {
    fractions: 19,
    site: 'Rosebush',
    physician: 'Dr Smith',
    date: '2023-01-30',
    diagnosis: 'prostate',
    fraction_dose: 2.43,
    adherence: true,
    technique: 'Electrons',
    linac: 'Machine 1',
    sim_to_treat: 8,
    priority: 'Standard',
    referrer: 'Dr. Rachel Nguyen',
    diagnosis_code: 'C43.9',
  },
  {
    fractions: 21,
    site: 'Lakeside',
    physician: 'Dr Smith',
    date: '2023-02-23',
    diagnosis: 'metastasis',
    fraction_dose: 1.38,
    adherence: false,
    technique: 'Unspecified',
    linac: 'Machine 1',
    sim_to_treat: 1,
    priority: 'Standard',
    referrer: 'Dr. Clara Thompson',
    diagnosis_code: 'C20',
  },
  {
    fractions: 22,
    site: 'Red Hills',
    physician: 'Dr Adams',
    date: '2023-11-18',
    diagnosis: 'prostate',
    fraction_dose: 1.74,
    adherence: true,
    technique: 'Unspecified',
    linac: 'Machine 4',
    sim_to_treat: 2,
    priority: 'Emergency',
    referrer: 'Dr. Aisha Patel',
    diagnosis_code: 'C20',
  },
  {
    fractions: 13,
    site: 'Red Hills',
    physician: 'Dr Smith',
    date: '2023-02-06',
    diagnosis: 'breast',
    fraction_dose: 3.08,
    adherence: true,
    technique: 'Brachytherapy',
    linac: 'Machine 4',
    sim_to_treat: 9,
    priority: 'Urgent',
    referrer: 'Dr. Michael Stern',
    diagnosis_code: 'C61',
  },
  {
    fractions: 23,
    site: 'Red Hills',
    physician: 'Dr Adams',
    date: '2023-06-23',
    diagnosis: 'colorectal',
    fraction_dose: 3.12,
    adherence: true,
    technique: 'Conformal',
    linac: 'Machine 1',
    sim_to_treat: 10,
    priority: 'Urgent',
    referrer: 'Dr. Julian Foster',
    diagnosis_code: 'C61',
  },
  {
    fractions: 23,
    site: 'Campsie',
    physician: 'Dr Lopez',
    date: '2023-11-26',
    diagnosis: 'prostate',
    fraction_dose: 2.93,
    adherence: true,
    technique: 'Brachytherapy',
    linac: 'Machine 3',
    sim_to_treat: 5,
    priority: 'Emergency',
    referrer: 'Dr. Julian Foster',
    diagnosis_code: 'C43.9',
  },
  {
    fractions: 10,
    site: 'Campsie',
    physician: 'Dr Wong',
    date: '2023-06-07',
    diagnosis: 'prostate',
    fraction_dose: 1.91,
    adherence: true,
    technique: 'Unspecified',
    linac: 'Machine 2',
    sim_to_treat: 5,
    priority: 'Emergency',
    referrer: 'Dr. Michael Stern',
    diagnosis_code: 'C61',
  },
  {
    fractions: 24,
    site: 'Red Hills',
    physician: 'Dr Adams',
    date: '2023-07-01',
    diagnosis: 'Unspecified',
    fraction_dose: 1.39,
    adherence: true,
    technique: 'Stereotactic',
    linac: 'Machine 4',
    sim_to_treat: 7,
    priority: 'Standard',
    referrer: 'Dr. Emily Hartman',
    diagnosis_code: 'C71.9',
  },
  {
    fractions: 21,
    site: 'Rosebush',
    physician: 'Dr Wong',
    date: '2023-01-01',
    diagnosis: 'neuro',
    fraction_dose: 1.41,
    adherence: true,
    technique: 'Brachytherapy',
    linac: 'Machine 1',
    sim_to_treat: 2,
    priority: 'Standard',
    referrer: 'Dr. Michael Stern',
    diagnosis_code: 'C20',
  },
  {
    fractions: 10,
    site: 'Lakeside',
    physician: 'Dr Adams',
    date: '2023-01-04',
    diagnosis: 'neuro',
    fraction_dose: 1.66,
    adherence: true,
    technique: 'Unspecified',
    linac: 'Machine 4',
    sim_to_treat: 2,
    priority: 'Emergency',
    referrer: 'Dr. Julian Foster',
    diagnosis_code: 'C34.90',
  },
  {
    fractions: 14,
    site: 'Lakeside',
    physician: 'Dr Lopez',
    date: '2023-07-13',
    diagnosis: 'skin',
    fraction_dose: 1.55,
    adherence: true,
    technique: 'Electrons',
    linac: 'Machine 2',
    sim_to_treat: 7,
    priority: 'Standard',
    referrer: 'Dr. Julian Foster',
    diagnosis_code: 'C79.81',
  },
  {
    fractions: 14,
    site: 'Campsie',
    physician: 'Dr Wong',
    date: '2023-08-31',
    diagnosis: 'neuro',
    fraction_dose: 1.22,
    adherence: true,
    technique: 'Unspecified',
    linac: 'Machine 2',
    sim_to_treat: 5,
    priority: 'Emergency',
    referrer: 'Dr. Sophie Greenberg',
    diagnosis_code: 'C79.81',
  },
  {
    fractions: 22,
    site: 'Red Hills',
    physician: 'Dr Lopez',
    date: '2023-08-19',
    diagnosis: 'colorectal',
    fraction_dose: 1.74,
    adherence: true,
    technique: 'Stereotactic',
    linac: 'Machine 4',
    sim_to_treat: 7,
    priority: 'Urgent',
    referrer: 'Dr. Julian Foster',
    diagnosis_code: 'C43.9',
  },
  {
    fractions: 14,
    site: 'Rosebush',
    physician: 'Dr Smith',
    date: '2023-06-07',
    diagnosis: 'neuro',
    fraction_dose: 1.61,
    adherence: true,
    technique: 'Unspecified',
    linac: 'Machine 4',
    sim_to_treat: 7,
    priority: 'Urgent',
    referrer: 'Dr. Clara Thompson',
    diagnosis_code: 'C61',
  },
  {
    fractions: 19,
    site: 'Red Hills',
    physician: 'Dr Smith',
    date: '2023-05-08',
    diagnosis: 'breast',
    fraction_dose: 2.11,
    adherence: true,
    technique: 'Conformal',
    linac: 'Machine 2',
    sim_to_treat: 2,
    priority: 'Standard',
    referrer: 'Dr. Clara Thompson',
    diagnosis_code: 'C20',
  },
  {
    fractions: 15,
    site: 'Lakeside',
    physician: 'Dr Smith',
    date: '2023-09-03',
    diagnosis: 'Unspecified',
    fraction_dose: 2.46,
    adherence: true,
    technique: 'Stereotactic',
    linac: 'Machine 4',
    sim_to_treat: 1,
    priority: 'Emergency',
    referrer: 'Dr. Clara Thompson',
    diagnosis_code: 'C61',
  },
  {
    fractions: 18,
    site: 'Rosebush',
    physician: 'Dr Wong',
    date: '2023-07-27',
    diagnosis: 'breast',
    fraction_dose: 2.91,
    adherence: true,
    technique: 'Conformal',
    linac: 'Machine 1',
    sim_to_treat: 7,
    priority: 'Emergency',
    referrer: 'Dr. Sophie Greenberg',
    diagnosis_code: 'C20',
  },
  {
    fractions: 18,
    site: 'Rosebush',
    physician: 'Dr Lopez',
    date: '2023-10-13',
    diagnosis: 'colorectal',
    fraction_dose: 2.97,
    adherence: false,
    technique: 'IMRT',
    linac: 'Machine 3',
    sim_to_treat: 2,
    priority: 'Standard',
    referrer: 'Dr. Aisha Patel',
    diagnosis_code: 'C61',
  },
  {
    fractions: 25,
    site: 'Red Hills',
    physician: 'Dr Lopez',
    date: '2023-01-13',
    diagnosis: 'metastasis',
    fraction_dose: 1.33,
    adherence: true,
    technique: 'Brachytherapy',
    linac: 'Machine 4',
    sim_to_treat: 6,
    priority: 'Standard',
    referrer: 'Dr. Clara Thompson',
    diagnosis_code: 'C20',
  },
  {
    fractions: 24,
    site: 'Red Hills',
    physician: 'Dr Wong',
    date: '2023-02-26',
    diagnosis: 'metastasis',
    fraction_dose: 1.98,
    adherence: true,
    technique: 'IMRT',
    linac: 'Machine 4',
    sim_to_treat: 10,
    priority: 'Urgent',
    referrer: 'Dr. Sophie Greenberg',
    diagnosis_code: 'C43.9',
  },
  {
    fractions: 21,
    site: 'Campsie',
    physician: 'Dr Lopez',
    date: '2023-01-18',
    diagnosis: 'colorectal',
    fraction_dose: 3.2,
    adherence: true,
    technique: 'Unspecified',
    linac: 'Machine 4',
    sim_to_treat: 6,
    priority: 'Standard',
    referrer: 'Dr. Clara Thompson',
    diagnosis_code: 'C61',
  },
  {
    fractions: 14,
    site: 'Rosebush',
    physician: 'Dr Smith',
    date: '2023-12-16',
    diagnosis: 'neuro',
    fraction_dose: 1.75,
    adherence: true,
    technique: 'Conformal',
    linac: 'Machine 2',
    sim_to_treat: 7,
    priority: 'Urgent',
    referrer: 'Dr. Sophie Greenberg',
    diagnosis_code: 'C71.9',
  },
  {
    fractions: 17,
    site: 'Campsie',
    physician: 'Dr Lopez',
    date: '2023-03-18',
    diagnosis: 'neuro',
    fraction_dose: 1.25,
    adherence: true,
    technique: 'Brachytherapy',
    linac: 'Machine 2',
    sim_to_treat: 5,
    priority: 'Standard',
    referrer: 'Dr. Julian Foster',
    diagnosis_code: 'C34.90',
  },
  {
    fractions: 11,
    site: 'Rosebush',
    physician: 'Dr Wong',
    date: '2023-09-19',
    diagnosis: 'metastasis',
    fraction_dose: 1.83,
    adherence: true,
    technique: 'Stereotactic',
    linac: 'Machine 1',
    sim_to_treat: 6,
    priority: 'Urgent',
    referrer: 'Dr. Alex Reynolds',
    diagnosis_code: 'C71.9',
  },
  {
    fractions: 14,
    site: 'Lakeside',
    physician: 'Dr Adams',
    date: '2023-06-29',
    diagnosis: 'skin',
    fraction_dose: 3.36,
    adherence: true,
    technique: 'Unspecified',
    linac: 'Machine 1',
    sim_to_treat: 8,
    priority: 'Standard',
    referrer: 'Dr. Victor Hernandes',
    diagnosis_code: 'C34.90',
  },
  {
    fractions: 11,
    site: 'Red Hills',
    physician: 'Dr Lopez',
    date: '2023-04-01',
    diagnosis: 'prostate',
    fraction_dose: 3.24,
    adherence: true,
    technique: 'Conformal',
    linac: 'Machine 3',
    sim_to_treat: 1,
    priority: 'Urgent',
    referrer: 'Dr. Sophie Greenberg',
    diagnosis_code: 'C79.81',
  },
  {
    fractions: 19,
    site: 'Red Hills',
    physician: 'Dr Wong',
    date: '2023-06-18',
    diagnosis: 'breast',
    fraction_dose: 1.87,
    adherence: true,
    technique: 'Unspecified',
    linac: 'Machine 4',
    sim_to_treat: 8,
    priority: 'Urgent',
    referrer: 'Dr. Emily Hartman',
    diagnosis_code: 'C20',
  },
  {
    fractions: 11,
    site: 'Rosebush',
    physician: 'Dr Smith',
    date: '2023-08-01',
    diagnosis: 'breast',
    fraction_dose: 2.33,
    adherence: true,
    technique: 'Conformal',
    linac: 'Machine 3',
    sim_to_treat: 1,
    priority: 'Standard',
    referrer: 'Dr. Emily Hartman',
    diagnosis_code: 'C34.90',
  },
  {
    fractions: 24,
    site: 'Red Hills',
    physician: 'Dr Wong',
    date: '2023-07-13',
    diagnosis: 'breast',
    fraction_dose: 2.68,
    adherence: false,
    technique: 'Conformal',
    linac: 'Machine 2',
    sim_to_treat: 2,
    priority: 'Standard',
    referrer: 'Dr. Jonathan Blake',
    diagnosis_code: 'C43.9',
  },
  {
    fractions: 14,
    site: 'Red Hills',
    physician: 'Dr Smith',
    date: '2023-07-08',
    diagnosis: 'prostate',
    fraction_dose: 2.13,
    adherence: true,
    technique: 'Electrons',
    linac: 'Machine 4',
    sim_to_treat: 6,
    priority: 'Urgent',
    referrer: 'Dr. Aisha Patel',
    diagnosis_code: 'C71.9',
  },
  {
    fractions: 11,
    site: 'Rosebush',
    physician: 'Dr Adams',
    date: '2023-01-19',
    diagnosis: 'colorectal',
    fraction_dose: 1.9,
    adherence: true,
    technique: 'Stereotactic',
    linac: 'Machine 4',
    sim_to_treat: 6,
    priority: 'Standard',
    referrer: 'Dr. Victor Hernandes',
    diagnosis_code: 'C61',
  },
  {
    fractions: 18,
    site: 'Rosebush',
    physician: 'Dr Wong',
    date: '2023-02-15',
    diagnosis: 'metastasis',
    fraction_dose: 3.29,
    adherence: true,
    technique: 'VMAT',
    linac: 'Machine 3',
    sim_to_treat: 2,
    priority: 'Urgent',
    referrer: 'Dr. Michael Stern',
    diagnosis_code: 'C20',
  },
  {
    fractions: 11,
    site: 'Lakeside',
    physician: 'Dr Smith',
    date: '2023-04-04',
    diagnosis: 'prostate',
    fraction_dose: 2.95,
    adherence: true,
    technique: 'Conformal',
    linac: 'Machine 2',
    sim_to_treat: 3,
    priority: 'Standard',
    referrer: 'Dr. Victor Hernandes',
    diagnosis_code: 'C61',
  },
  {
    fractions: 14,
    site: 'Red Hills',
    physician: 'Dr Adams',
    date: '2023-06-12',
    diagnosis: 'skin',
    fraction_dose: 2.02,
    adherence: true,
    technique: 'Electrons',
    linac: 'Machine 1',
    sim_to_treat: 3,
    priority: 'Urgent',
    referrer: 'Dr. Emily Hartman',
    diagnosis_code: 'C61',
  },
  {
    fractions: 25,
    site: 'Campsie',
    physician: 'Dr Lopez',
    date: '2023-03-05',
    diagnosis: 'colorectal',
    fraction_dose: 3.09,
    adherence: true,
    technique: 'Conformal',
    linac: 'Machine 3',
    sim_to_treat: 10,
    priority: 'Urgent',
    referrer: 'Dr. Aisha Patel',
    diagnosis_code: 'C71.9',
  },
  {
    fractions: 24,
    site: 'Rosebush',
    physician: 'Dr Wong',
    date: '2023-08-20',
    diagnosis: 'Unspecified',
    fraction_dose: 2.98,
    adherence: true,
    technique: 'VMAT',
    linac: 'Machine 4',
    sim_to_treat: 10,
    priority: 'Urgent',
    referrer: 'Dr. Jonathan Blake',
    diagnosis_code: 'C79.81',
  },
  {
    fractions: 11,
    site: 'Rosebush',
    physician: 'Dr Lopez',
    date: '2023-10-27',
    diagnosis: 'colorectal',
    fraction_dose: 2.41,
    adherence: true,
    technique: 'Stereotactic',
    linac: 'Machine 3',
    sim_to_treat: 8,
    priority: 'Urgent',
    referrer: 'Dr. Sophie Greenberg',
    diagnosis_code: 'C43.9',
  },
  {
    fractions: 17,
    site: 'Red Hills',
    physician: 'Dr Lopez',
    date: '2023-04-17',
    diagnosis: 'metastasis',
    fraction_dose: 1.45,
    adherence: true,
    technique: 'Electrons',
    linac: 'Machine 2',
    sim_to_treat: 5,
    priority: 'Standard',
    referrer: 'Dr. Victor Hernandes',
    diagnosis_code: 'C71.9',
  },
  {
    fractions: 25,
    site: 'Red Hills',
    physician: 'Dr Adams',
    date: '2023-08-08',
    diagnosis: 'Unspecified',
    fraction_dose: 1.8,
    adherence: true,
    technique: 'Brachytherapy',
    linac: 'Machine 4',
    sim_to_treat: 6,
    priority: 'Standard',
    referrer: 'Dr. Rachel Nguyen',
    diagnosis_code: 'C71.9',
  },
  {
    fractions: 13,
    site: 'Campsie',
    physician: 'Dr Lopez',
    date: '2023-08-22',
    diagnosis: 'colorectal',
    fraction_dose: 1.65,
    adherence: true,
    technique: 'Electrons',
    linac: 'Machine 1',
    sim_to_treat: 6,
    priority: 'Emergency',
    referrer: 'Dr. Emily Hartman',
    diagnosis_code: 'C43.9',
  },
  {
    fractions: 24,
    site: 'Lakeside',
    physician: 'Dr Wong',
    date: '2023-07-09',
    diagnosis: 'breast',
    fraction_dose: 1.79,
    adherence: true,
    technique: 'Stereotactic',
    linac: 'Machine 3',
    sim_to_treat: 10,
    priority: 'Urgent',
    referrer: 'Dr. Aisha Patel',
    diagnosis_code: 'C20',
  },
  {
    fractions: 14,
    site: 'Rosebush',
    physician: 'Dr Smith',
    date: '2023-03-09',
    diagnosis: 'Unspecified',
    fraction_dose: 1.56,
    adherence: true,
    technique: 'VMAT',
    linac: 'Machine 2',
    sim_to_treat: 4,
    priority: 'Urgent',
    referrer: 'Dr. Michael Stern',
    diagnosis_code: 'C43.9',
  },
  {
    fractions: 11,
    site: 'Rosebush',
    physician: 'Dr Smith',
    date: '2023-07-28',
    diagnosis: 'breast',
    fraction_dose: 2.55,
    adherence: true,
    technique: 'Stereotactic',
    linac: 'Machine 1',
    sim_to_treat: 4,
    priority: 'Standard',
    referrer: 'Dr. Jonathan Blake',
    diagnosis_code: 'C71.9',
  },
  {
    fractions: 25,
    site: 'Campsie',
    physician: 'Dr Adams',
    date: '2023-12-25',
    diagnosis: 'colorectal',
    fraction_dose: 2.28,
    adherence: true,
    technique: 'Unspecified',
    linac: 'Machine 2',
    sim_to_treat: 7,
    priority: 'Emergency',
    referrer: 'Dr. Rachel Nguyen',
    diagnosis_code: 'C43.9',
  },
  {
    fractions: 25,
    site: 'Lakeside',
    physician: 'Dr Adams',
    date: '2023-08-21',
    diagnosis: 'metastasis',
    fraction_dose: 2.03,
    adherence: true,
    technique: 'IMRT',
    linac: 'Machine 3',
    sim_to_treat: 8,
    priority: 'Emergency',
    referrer: 'Dr. Sophie Greenberg',
    diagnosis_code: 'C71.9',
  },
  {
    fractions: 16,
    site: 'Rosebush',
    physician: 'Dr Smith',
    date: '2023-10-16',
    diagnosis: 'neuro',
    fraction_dose: 1.58,
    adherence: true,
    technique: 'Unspecified',
    linac: 'Machine 2',
    sim_to_treat: 5,
    priority: 'Urgent',
    referrer: 'Dr. Jonathan Blake',
    diagnosis_code: 'C34.90',
  },
  {
    fractions: 22,
    site: 'Campsie',
    physician: 'Dr Wong',
    date: '2023-03-02',
    diagnosis: 'colorectal',
    fraction_dose: 2.69,
    adherence: true,
    technique: 'Electrons',
    linac: 'Machine 4',
    sim_to_treat: 8,
    priority: 'Standard',
    referrer: 'Dr. Sophie Greenberg',
    diagnosis_code: 'C34.90',
  },
  {
    fractions: 12,
    site: 'Lakeside',
    physician: 'Dr Wong',
    date: '2023-09-30',
    diagnosis: 'breast',
    fraction_dose: 2.69,
    adherence: true,
    technique: 'VMAT',
    linac: 'Machine 1',
    sim_to_treat: 1,
    priority: 'Standard',
    referrer: 'Dr. Aisha Patel',
    diagnosis_code: 'C61',
  },
  {
    fractions: 17,
    site: 'Red Hills',
    physician: 'Dr Adams',
    date: '2023-04-14',
    diagnosis: 'skin',
    fraction_dose: 1.37,
    adherence: true,
    technique: 'VMAT',
    linac: 'Machine 4',
    sim_to_treat: 2,
    priority: 'Emergency',
    referrer: 'Dr. Michael Stern',
    diagnosis_code: 'C71.9',
  },
  {
    fractions: 22,
    site: 'Rosebush',
    physician: 'Dr Smith',
    date: '2023-04-14',
    diagnosis: 'skin',
    fraction_dose: 3.11,
    adherence: true,
    technique: 'IMRT',
    linac: 'Machine 3',
    sim_to_treat: 2,
    priority: 'Emergency',
    referrer: 'Dr. Aisha Patel',
    diagnosis_code: 'C71.9',
  },
  {
    fractions: 21,
    site: 'Lakeside',
    physician: 'Dr Wong',
    date: '2023-08-19',
    diagnosis: 'metastasis',
    fraction_dose: 1.83,
    adherence: true,
    technique: 'Stereotactic',
    linac: 'Machine 4',
    sim_to_treat: 9,
    priority: 'Emergency',
    referrer: 'Dr. Julian Foster',
    diagnosis_code: 'C43.9',
  },
  {
    fractions: 19,
    site: 'Rosebush',
    physician: 'Dr Adams',
    date: '2023-06-20',
    diagnosis: 'Unspecified',
    fraction_dose: 2.38,
    adherence: true,
    technique: 'Unspecified',
    linac: 'Machine 4',
    sim_to_treat: 1,
    priority: 'Urgent',
    referrer: 'Dr. Julian Foster',
    diagnosis_code: 'C71.9',
  },
  {
    fractions: 17,
    site: 'Campsie',
    physician: 'Dr Adams',
    date: '2023-07-06',
    diagnosis: 'prostate',
    fraction_dose: 3.21,
    adherence: true,
    technique: 'Electrons',
    linac: 'Machine 2',
    sim_to_treat: 8,
    priority: 'Standard',
    referrer: 'Dr. Alex Reynolds',
    diagnosis_code: 'C34.90',
  },
  {
    fractions: 12,
    site: 'Lakeside',
    physician: 'Dr Lopez',
    date: '2023-07-10',
    diagnosis: 'colorectal',
    fraction_dose: 3.0,
    adherence: true,
    technique: 'Electrons',
    linac: 'Machine 2',
    sim_to_treat: 2,
    priority: 'Standard',
    referrer: 'Dr. Michael Stern',
    diagnosis_code: 'C71.9',
  },
  {
    fractions: 25,
    site: 'Rosebush',
    physician: 'Dr Adams',
    date: '2023-02-03',
    diagnosis: 'skin',
    fraction_dose: 2.43,
    adherence: true,
    technique: 'Unspecified',
    linac: 'Machine 2',
    sim_to_treat: 9,
    priority: 'Emergency',
    referrer: 'Dr. Rachel Nguyen',
    diagnosis_code: 'C34.90',
  },
  {
    fractions: 19,
    site: 'Red Hills',
    physician: 'Dr Lopez',
    date: '2023-06-11',
    diagnosis: 'colorectal',
    fraction_dose: 1.65,
    adherence: true,
    technique: 'Brachytherapy',
    linac: 'Machine 3',
    sim_to_treat: 10,
    priority: 'Emergency',
    referrer: 'Dr. Julian Foster',
    diagnosis_code: 'C43.9',
  },
  {
    fractions: 14,
    site: 'Rosebush',
    physician: 'Dr Smith',
    date: '2023-04-08',
    diagnosis: 'neuro',
    fraction_dose: 2.93,
    adherence: true,
    technique: 'VMAT',
    linac: 'Machine 4',
    sim_to_treat: 7,
    priority: 'Urgent',
    referrer: 'Dr. Julian Foster',
    diagnosis_code: 'C79.81',
  },
  {
    fractions: 16,
    site: 'Red Hills',
    physician: 'Dr Adams',
    date: '2023-10-24',
    diagnosis: 'Unspecified',
    fraction_dose: 2.33,
    adherence: true,
    technique: 'VMAT',
    linac: 'Machine 1',
    sim_to_treat: 1,
    priority: 'Emergency',
    referrer: 'Dr. Jonathan Blake',
    diagnosis_code: 'C61',
  },
  {
    fractions: 10,
    site: 'Campsie',
    physician: 'Dr Wong',
    date: '2023-08-18',
    diagnosis: 'skin',
    fraction_dose: 1.66,
    adherence: true,
    technique: 'Brachytherapy',
    linac: 'Machine 4',
    sim_to_treat: 10,
    priority: 'Emergency',
    referrer: 'Dr. Michael Stern',
    diagnosis_code: 'C43.9',
  },
  {
    fractions: 25,
    site: 'Rosebush',
    physician: 'Dr Adams',
    date: '2023-08-04',
    diagnosis: 'prostate',
    fraction_dose: 2.6,
    adherence: true,
    technique: 'Conformal',
    linac: 'Machine 2',
    sim_to_treat: 2,
    priority: 'Emergency',
    referrer: 'Dr. Michael Stern',
    diagnosis_code: 'C71.9',
  },
  {
    fractions: 10,
    site: 'Red Hills',
    physician: 'Dr Smith',
    date: '2023-06-05',
    diagnosis: 'colorectal',
    fraction_dose: 1.57,
    adherence: true,
    technique: 'VMAT',
    linac: 'Machine 4',
    sim_to_treat: 8,
    priority: 'Urgent',
    referrer: 'Dr. Alex Reynolds',
    diagnosis_code: 'C43.9',
  },
  {
    fractions: 24,
    site: 'Rosebush',
    physician: 'Dr Wong',
    date: '2023-04-14',
    diagnosis: 'colorectal',
    fraction_dose: 1.55,
    adherence: true,
    technique: 'Conformal',
    linac: 'Machine 4',
    sim_to_treat: 3,
    priority: 'Urgent',
    referrer: 'Dr. Jonathan Blake',
    diagnosis_code: 'C34.90',
  },
  {
    fractions: 20,
    site: 'Campsie',
    physician: 'Dr Smith',
    date: '2023-03-13',
    diagnosis: 'prostate',
    fraction_dose: 1.44,
    adherence: true,
    technique: 'Conformal',
    linac: 'Machine 3',
    sim_to_treat: 3,
    priority: 'Urgent',
    referrer: 'Dr. Julian Foster',
    diagnosis_code: 'C43.9',
  },
  {
    fractions: 24,
    site: 'Campsie',
    physician: 'Dr Smith',
    date: '2023-05-16',
    diagnosis: 'prostate',
    fraction_dose: 1.24,
    adherence: true,
    technique: 'Stereotactic',
    linac: 'Machine 1',
    sim_to_treat: 7,
    priority: 'Urgent',
    referrer: 'Dr. Julian Foster',
    diagnosis_code: 'C43.9',
  },
  {
    fractions: 24,
    site: 'Red Hills',
    physician: 'Dr Lopez',
    date: '2023-12-28',
    diagnosis: 'colorectal',
    fraction_dose: 1.32,
    adherence: true,
    technique: 'Brachytherapy',
    linac: 'Machine 3',
    sim_to_treat: 9,
    priority: 'Urgent',
    referrer: 'Dr. Jonathan Blake',
    diagnosis_code: 'C61',
  },
  {
    fractions: 20,
    site: 'Rosebush',
    physician: 'Dr Smith',
    date: '2023-10-31',
    diagnosis: 'prostate',
    fraction_dose: 3.3,
    adherence: true,
    technique: 'IMRT',
    linac: 'Machine 1',
    sim_to_treat: 5,
    priority: 'Urgent',
    referrer: 'Dr. Clara Thompson',
    diagnosis_code: 'C79.81',
  },
  {
    fractions: 20,
    site: 'Red Hills',
    physician: 'Dr Smith',
    date: '2023-10-15',
    diagnosis: 'breast',
    fraction_dose: 1.9,
    adherence: true,
    technique: 'Electrons',
    linac: 'Machine 3',
    sim_to_treat: 6,
    priority: 'Urgent',
    referrer: 'Dr. Aisha Patel',
    diagnosis_code: 'C61',
  },
  {
    fractions: 23,
    site: 'Lakeside',
    physician: 'Dr Lopez',
    date: '2023-11-15',
    diagnosis: 'metastasis',
    fraction_dose: 3.04,
    adherence: true,
    technique: 'VMAT',
    linac: 'Machine 3',
    sim_to_treat: 4,
    priority: 'Urgent',
    referrer: 'Dr. Julian Foster',
    diagnosis_code: 'C20',
  },
  {
    fractions: 20,
    site: 'Red Hills',
    physician: 'Dr Lopez',
    date: '2023-05-18',
    diagnosis: 'breast',
    fraction_dose: 1.86,
    adherence: true,
    technique: 'VMAT',
    linac: 'Machine 2',
    sim_to_treat: 9,
    priority: 'Urgent',
    referrer: 'Dr. Rachel Nguyen',
    diagnosis_code: 'C79.81',
  },
  {
    fractions: 18,
    site: 'Red Hills',
    physician: 'Dr Adams',
    date: '2023-09-19',
    diagnosis: 'breast',
    fraction_dose: 2.88,
    adherence: true,
    technique: 'Brachytherapy',
    linac: 'Machine 4',
    sim_to_treat: 1,
    priority: 'Standard',
    referrer: 'Dr. Julian Foster',
    diagnosis_code: 'C34.90',
  },
  {
    fractions: 16,
    site: 'Campsie',
    physician: 'Dr Adams',
    date: '2023-05-31',
    diagnosis: 'prostate',
    fraction_dose: 2.5,
    adherence: true,
    technique: 'VMAT',
    linac: 'Machine 3',
    sim_to_treat: 3,
    priority: 'Standard',
    referrer: 'Dr. Emily Hartman',
    diagnosis_code: 'C34.90',
  },
  {
    fractions: 13,
    site: 'Lakeside',
    physician: 'Dr Smith',
    date: '2023-01-31',
    diagnosis: 'neuro',
    fraction_dose: 2.0,
    adherence: true,
    technique: 'Brachytherapy',
    linac: 'Machine 2',
    sim_to_treat: 3,
    priority: 'Emergency',
    referrer: 'Dr. Sophie Greenberg',
    diagnosis_code: 'C61',
  },
  {
    fractions: 16,
    site: 'Lakeside',
    physician: 'Dr Wong',
    date: '2023-06-03',
    diagnosis: 'prostate',
    fraction_dose: 2.08,
    adherence: true,
    technique: 'Unspecified',
    linac: 'Machine 1',
    sim_to_treat: 7,
    priority: 'Emergency',
    referrer: 'Dr. Sophie Greenberg',
    diagnosis_code: 'C71.9',
  },
  {
    fractions: 13,
    site: 'Red Hills',
    physician: 'Dr Adams',
    date: '2023-05-20',
    diagnosis: 'metastasis',
    fraction_dose: 1.81,
    adherence: true,
    technique: 'Stereotactic',
    linac: 'Machine 3',
    sim_to_treat: 3,
    priority: 'Emergency',
    referrer: 'Dr. Clara Thompson',
    diagnosis_code: 'C61',
  },
  {
    fractions: 16,
    site: 'Rosebush',
    physician: 'Dr Lopez',
    date: '2023-04-01',
    diagnosis: 'skin',
    fraction_dose: 1.56,
    adherence: true,
    technique: 'VMAT',
    linac: 'Machine 2',
    sim_to_treat: 8,
    priority: 'Emergency',
    referrer: 'Dr. Victor Hernandes',
    diagnosis_code: 'C79.81',
  },
  {
    fractions: 16,
    site: 'Red Hills',
    physician: 'Dr Adams',
    date: '2023-06-10',
    diagnosis: 'Unspecified',
    fraction_dose: 2.23,
    adherence: true,
    technique: 'Electrons',
    linac: 'Machine 4',
    sim_to_treat: 3,
    priority: 'Urgent',
    referrer: 'Dr. Alex Reynolds',
    diagnosis_code: 'C20',
  },
  {
    fractions: 25,
    site: 'Campsie',
    physician: 'Dr Lopez',
    date: '2023-11-21',
    diagnosis: 'metastasis',
    fraction_dose: 1.77,
    adherence: true,
    technique: 'Conformal',
    linac: 'Machine 3',
    sim_to_treat: 9,
    priority: 'Urgent',
    referrer: 'Dr. Julian Foster',
    diagnosis_code: 'C43.9',
  },
  {
    fractions: 16,
    site: 'Lakeside',
    physician: 'Dr Wong',
    date: '2023-11-11',
    diagnosis: 'colorectal',
    fraction_dose: 1.93,
    adherence: true,
    technique: 'VMAT',
    linac: 'Machine 1',
    sim_to_treat: 2,
    priority: 'Standard',
    referrer: 'Dr. Alex Reynolds',
    diagnosis_code: 'C71.9',
  },
  {
    fractions: 15,
    site: 'Red Hills',
    physician: 'Dr Smith',
    date: '2023-07-07',
    diagnosis: 'Unspecified',
    fraction_dose: 1.31,
    adherence: true,
    technique: 'Unspecified',
    linac: 'Machine 4',
    sim_to_treat: 9,
    priority: 'Urgent',
    referrer: 'Dr. Victor Hernandes',
    diagnosis_code: 'C34.90',
  },
  {
    fractions: 12,
    site: 'Rosebush',
    physician: 'Dr Wong',
    date: '2023-10-13',
    diagnosis: 'metastasis',
    fraction_dose: 1.96,
    adherence: true,
    technique: 'VMAT',
    linac: 'Machine 4',
    sim_to_treat: 5,
    priority: 'Standard',
    referrer: 'Dr. Rachel Nguyen',
    diagnosis_code: 'C61',
  },
  {
    fractions: 17,
    site: 'Campsie',
    physician: 'Dr Smith',
    date: '2023-04-07',
    diagnosis: 'skin',
    fraction_dose: 1.83,
    adherence: true,
    technique: 'IMRT',
    linac: 'Machine 2',
    sim_to_treat: 8,
    priority: 'Standard',
    referrer: 'Dr. Julian Foster',
    diagnosis_code: 'C71.9',
  },
  {
    fractions: 21,
    site: 'Campsie',
    physician: 'Dr Wong',
    date: '2023-05-02',
    diagnosis: 'metastasis',
    fraction_dose: 3.15,
    adherence: true,
    technique: 'VMAT',
    linac: 'Machine 1',
    sim_to_treat: 5,
    priority: 'Standard',
    referrer: 'Dr. Aisha Patel',
    diagnosis_code: 'C79.81',
  },
  {
    fractions: 16,
    site: 'Red Hills',
    physician: 'Dr Smith',
    date: '2023-11-18',
    diagnosis: 'skin',
    fraction_dose: 1.92,
    adherence: true,
    technique: 'IMRT',
    linac: 'Machine 4',
    sim_to_treat: 7,
    priority: 'Urgent',
    referrer: 'Dr. Victor Hernandes',
    diagnosis_code: 'C20',
  },
  {
    fractions: 23,
    site: 'Campsie',
    physician: 'Dr Adams',
    date: '2023-06-25',
    diagnosis: 'colorectal',
    fraction_dose: 1.7,
    adherence: true,
    technique: 'Brachytherapy',
    linac: 'Machine 2',
    sim_to_treat: 3,
    priority: 'Urgent',
    referrer: 'Dr. Victor Hernandes',
    diagnosis_code: 'C71.9',
  },
  {
    fractions: 14,
    site: 'Campsie',
    physician: 'Dr Smith',
    date: '2023-11-03',
    diagnosis: 'metastasis',
    fraction_dose: 3.36,
    adherence: true,
    technique: 'Stereotactic',
    linac: 'Machine 3',
    sim_to_treat: 4,
    priority: 'Standard',
    referrer: 'Dr. Julian Foster',
    diagnosis_code: 'C20',
  },
  {
    fractions: 20,
    site: 'Campsie',
    physician: 'Dr Adams',
    date: '2023-05-17',
    diagnosis: 'neuro',
    fraction_dose: 1.57,
    adherence: false,
    technique: 'Conformal',
    linac: 'Machine 2',
    sim_to_treat: 3,
    priority: 'Urgent',
    referrer: 'Dr. Aisha Patel',
    diagnosis_code: 'C61',
  },
  {
    fractions: 24,
    site: 'Red Hills',
    physician: 'Dr Lopez',
    date: '2023-06-15',
    diagnosis: 'skin',
    fraction_dose: 2.68,
    adherence: true,
    technique: 'Electrons',
    linac: 'Machine 3',
    sim_to_treat: 9,
    priority: 'Standard',
    referrer: 'Dr. Clara Thompson',
    diagnosis_code: 'C34.90',
  },
  {
    fractions: 23,
    site: 'Campsie',
    physician: 'Dr Wong',
    date: '2023-05-17',
    diagnosis: 'colorectal',
    fraction_dose: 1.28,
    adherence: true,
    technique: 'IMRT',
    linac: 'Machine 1',
    sim_to_treat: 2,
    priority: 'Emergency',
    referrer: 'Dr. Julian Foster',
    diagnosis_code: 'C43.9',
  },
  {
    fractions: 21,
    site: 'Lakeside',
    physician: 'Dr Wong',
    date: '2023-11-26',
    diagnosis: 'prostate',
    fraction_dose: 1.46,
    adherence: true,
    technique: 'Electrons',
    linac: 'Machine 4',
    sim_to_treat: 1,
    priority: 'Urgent',
    referrer: 'Dr. Emily Hartman',
    diagnosis_code: 'C43.9',
  },
  {
    fractions: 15,
    site: 'Rosebush',
    physician: 'Dr Wong',
    date: '2023-03-11',
    diagnosis: 'breast',
    fraction_dose: 2.01,
    adherence: true,
    technique: 'IMRT',
    linac: 'Machine 2',
    sim_to_treat: 8,
    priority: 'Emergency',
    referrer: 'Dr. Michael Stern',
    diagnosis_code: 'C61',
  },
  {
    fractions: 18,
    site: 'Lakeside',
    physician: 'Dr Wong',
    date: '2023-06-29',
    diagnosis: 'prostate',
    fraction_dose: 3.38,
    adherence: true,
    technique: 'VMAT',
    linac: 'Machine 4',
    sim_to_treat: 8,
    priority: 'Standard',
    referrer: 'Dr. Rachel Nguyen',
    diagnosis_code: 'C43.9',
  },
  {
    fractions: 18,
    site: 'Campsie',
    physician: 'Dr Lopez',
    date: '2023-03-23',
    diagnosis: 'neuro',
    fraction_dose: 3.32,
    adherence: true,
    technique: 'IMRT',
    linac: 'Machine 2',
    sim_to_treat: 4,
    priority: 'Standard',
    referrer: 'Dr. Clara Thompson',
    diagnosis_code: 'C20',
  },
  {
    fractions: 20,
    site: 'Lakeside',
    physician: 'Dr Lopez',
    date: '2023-03-04',
    diagnosis: 'neuro',
    fraction_dose: 1.9,
    adherence: true,
    technique: 'Unspecified',
    linac: 'Machine 4',
    sim_to_treat: 9,
    priority: 'Standard',
    referrer: 'Dr. Victor Hernandes',
    diagnosis_code: 'C34.90',
  },
  {
    fractions: 24,
    site: 'Rosebush',
    physician: 'Dr Lopez',
    date: '2023-06-19',
    diagnosis: 'colorectal',
    fraction_dose: 2.27,
    adherence: true,
    technique: 'Electrons',
    linac: 'Machine 3',
    sim_to_treat: 2,
    priority: 'Urgent',
    referrer: 'Dr. Julian Foster',
    diagnosis_code: 'C61',
  },
  {
    fractions: 23,
    site: 'Red Hills',
    physician: 'Dr Smith',
    date: '2023-04-22',
    diagnosis: 'Unspecified',
    fraction_dose: 1.37,
    adherence: true,
    technique: 'Brachytherapy',
    linac: 'Machine 3',
    sim_to_treat: 1,
    priority: 'Urgent',
    referrer: 'Dr. Sophie Greenberg',
    diagnosis_code: 'C71.9',
  },
  {
    fractions: 16,
    site: 'Campsie',
    physician: 'Dr Lopez',
    date: '2023-01-01',
    diagnosis: 'colorectal',
    fraction_dose: 1.55,
    adherence: false,
    technique: 'Stereotactic',
    linac: 'Machine 4',
    sim_to_treat: 10,
    priority: 'Emergency',
    referrer: 'Dr. Clara Thompson',
    diagnosis_code: 'C34.90',
  },
  {
    fractions: 19,
    site: 'Red Hills',
    physician: 'Dr Wong',
    date: '2023-08-26',
    diagnosis: 'prostate',
    fraction_dose: 2.92,
    adherence: true,
    technique: 'IMRT',
    linac: 'Machine 3',
    sim_to_treat: 8,
    priority: 'Urgent',
    referrer: 'Dr. Aisha Patel',
    diagnosis_code: 'C79.81',
  },
  {
    fractions: 14,
    site: 'Red Hills',
    physician: 'Dr Smith',
    date: '2023-07-09',
    diagnosis: 'skin',
    fraction_dose: 1.75,
    adherence: true,
    technique: 'Conformal',
    linac: 'Machine 1',
    sim_to_treat: 6,
    priority: 'Urgent',
    referrer: 'Dr. Michael Stern',
    diagnosis_code: 'C34.90',
  },
  {
    fractions: 11,
    site: 'Lakeside',
    physician: 'Dr Smith',
    date: '2023-09-27',
    diagnosis: 'neuro',
    fraction_dose: 2.59,
    adherence: true,
    technique: 'Brachytherapy',
    linac: 'Machine 2',
    sim_to_treat: 5,
    priority: 'Emergency',
    referrer: 'Dr. Victor Hernandes',
    diagnosis_code: 'C34.90',
  },
  {
    fractions: 24,
    site: 'Rosebush',
    physician: 'Dr Adams',
    date: '2023-01-13',
    diagnosis: 'metastasis',
    fraction_dose: 1.72,
    adherence: true,
    technique: 'Electrons',
    linac: 'Machine 4',
    sim_to_treat: 8,
    priority: 'Standard',
    referrer: 'Dr. Michael Stern',
    diagnosis_code: 'C61',
  },
  {
    fractions: 21,
    site: 'Campsie',
    physician: 'Dr Smith',
    date: '2023-03-22',
    diagnosis: 'prostate',
    fraction_dose: 3.06,
    adherence: true,
    technique: 'Unspecified',
    linac: 'Machine 4',
    sim_to_treat: 6,
    priority: 'Emergency',
    referrer: 'Dr. Victor Hernandes',
    diagnosis_code: 'C20',
  },
  {
    fractions: 22,
    site: 'Red Hills',
    physician: 'Dr Lopez',
    date: '2023-08-06',
    diagnosis: 'colorectal',
    fraction_dose: 1.37,
    adherence: true,
    technique: 'Conformal',
    linac: 'Machine 4',
    sim_to_treat: 5,
    priority: 'Standard',
    referrer: 'Dr. Sophie Greenberg',
    diagnosis_code: 'C61',
  },
  {
    fractions: 10,
    site: 'Lakeside',
    physician: 'Dr Wong',
    date: '2023-05-06',
    diagnosis: 'breast',
    fraction_dose: 2.57,
    adherence: true,
    technique: 'Brachytherapy',
    linac: 'Machine 4',
    sim_to_treat: 4,
    priority: 'Standard',
    referrer: 'Dr. Michael Stern',
    diagnosis_code: 'C79.81',
  },
  {
    fractions: 13,
    site: 'Campsie',
    physician: 'Dr Adams',
    date: '2023-01-05',
    diagnosis: 'skin',
    fraction_dose: 2.74,
    adherence: true,
    technique: 'IMRT',
    linac: 'Machine 3',
    sim_to_treat: 9,
    priority: 'Urgent',
    referrer: 'Dr. Julian Foster',
    diagnosis_code: 'C43.9',
  },
  {
    fractions: 25,
    site: 'Campsie',
    physician: 'Dr Lopez',
    date: '2023-03-27',
    diagnosis: 'metastasis',
    fraction_dose: 3.35,
    adherence: true,
    technique: 'Conformal',
    linac: 'Machine 1',
    sim_to_treat: 8,
    priority: 'Emergency',
    referrer: 'Dr. Aisha Patel',
    diagnosis_code: 'C34.90',
  },
  {
    fractions: 24,
    site: 'Campsie',
    physician: 'Dr Adams',
    date: '2023-01-16',
    diagnosis: 'neuro',
    fraction_dose: 2.37,
    adherence: true,
    technique: 'VMAT',
    linac: 'Machine 1',
    sim_to_treat: 7,
    priority: 'Emergency',
    referrer: 'Dr. Aisha Patel',
    diagnosis_code: 'C34.90',
  },
  {
    fractions: 25,
    site: 'Rosebush',
    physician: 'Dr Adams',
    date: '2023-04-01',
    diagnosis: 'breast',
    fraction_dose: 2.67,
    adherence: true,
    technique: 'Stereotactic',
    linac: 'Machine 3',
    sim_to_treat: 10,
    priority: 'Emergency',
    referrer: 'Dr. Aisha Patel',
    diagnosis_code: 'C43.9',
  },
  {
    fractions: 13,
    site: 'Lakeside',
    physician: 'Dr Adams',
    date: '2023-07-15',
    diagnosis: 'Unspecified',
    fraction_dose: 1.74,
    adherence: true,
    technique: 'Electrons',
    linac: 'Machine 3',
    sim_to_treat: 8,
    priority: 'Emergency',
    referrer: 'Dr. Aisha Patel',
    diagnosis_code: 'C79.81',
  },
  {
    fractions: 21,
    site: 'Campsie',
    physician: 'Dr Adams',
    date: '2023-05-22',
    diagnosis: 'prostate',
    fraction_dose: 1.7,
    adherence: true,
    technique: 'IMRT',
    linac: 'Machine 3',
    sim_to_treat: 6,
    priority: 'Emergency',
    referrer: 'Dr. Michael Stern',
    diagnosis_code: 'C20',
  },
  {
    fractions: 14,
    site: 'Campsie',
    physician: 'Dr Smith',
    date: '2023-05-28',
    diagnosis: 'colorectal',
    fraction_dose: 1.69,
    adherence: true,
    technique: 'VMAT',
    linac: 'Machine 4',
    sim_to_treat: 7,
    priority: 'Standard',
    referrer: 'Dr. Michael Stern',
    diagnosis_code: 'C34.90',
  },
  {
    fractions: 24,
    site: 'Lakeside',
    physician: 'Dr Lopez',
    date: '2023-12-11',
    diagnosis: 'prostate',
    fraction_dose: 1.82,
    adherence: true,
    technique: 'Stereotactic',
    linac: 'Machine 3',
    sim_to_treat: 5,
    priority: 'Standard',
    referrer: 'Dr. Michael Stern',
    diagnosis_code: 'C71.9',
  },
  {
    fractions: 16,
    site: 'Lakeside',
    physician: 'Dr Smith',
    date: '2023-09-25',
    diagnosis: 'Unspecified',
    fraction_dose: 1.39,
    adherence: true,
    technique: 'Electrons',
    linac: 'Machine 1',
    sim_to_treat: 2,
    priority: 'Urgent',
    referrer: 'Dr. Sophie Greenberg',
    diagnosis_code: 'C71.9',
  },
  {
    fractions: 14,
    site: 'Red Hills',
    physician: 'Dr Adams',
    date: '2023-11-29',
    diagnosis: 'skin',
    fraction_dose: 2.6,
    adherence: true,
    technique: 'Electrons',
    linac: 'Machine 3',
    sim_to_treat: 3,
    priority: 'Emergency',
    referrer: 'Dr. Clara Thompson',
    diagnosis_code: 'C20',
  },
  {
    fractions: 20,
    site: 'Red Hills',
    physician: 'Dr Adams',
    date: '2023-12-20',
    diagnosis: 'metastasis',
    fraction_dose: 1.29,
    adherence: true,
    technique: 'Conformal',
    linac: 'Machine 4',
    sim_to_treat: 5,
    priority: 'Standard',
    referrer: 'Dr. Emily Hartman',
    diagnosis_code: 'C61',
  },
  {
    fractions: 25,
    site: 'Red Hills',
    physician: 'Dr Smith',
    date: '2023-07-09',
    diagnosis: 'metastasis',
    fraction_dose: 1.34,
    adherence: true,
    technique: 'Unspecified',
    linac: 'Machine 4',
    sim_to_treat: 6,
    priority: 'Standard',
    referrer: 'Dr. Victor Hernandes',
    diagnosis_code: 'C79.81',
  },
  {
    fractions: 19,
    site: 'Rosebush',
    physician: 'Dr Smith',
    date: '2023-02-08',
    diagnosis: 'neuro',
    fraction_dose: 1.6,
    adherence: true,
    technique: 'Stereotactic',
    linac: 'Machine 3',
    sim_to_treat: 6,
    priority: 'Urgent',
    referrer: 'Dr. Alex Reynolds',
    diagnosis_code: 'C61',
  },
  {
    fractions: 13,
    site: 'Lakeside',
    physician: 'Dr Smith',
    date: '2023-08-16',
    diagnosis: 'skin',
    fraction_dose: 2.08,
    adherence: true,
    technique: 'Unspecified',
    linac: 'Machine 1',
    sim_to_treat: 2,
    priority: 'Urgent',
    referrer: 'Dr. Julian Foster',
    diagnosis_code: 'C20',
  },
  {
    fractions: 20,
    site: 'Campsie',
    physician: 'Dr Lopez',
    date: '2023-08-23',
    diagnosis: 'Unspecified',
    fraction_dose: 3.18,
    adherence: false,
    technique: 'Stereotactic',
    linac: 'Machine 4',
    sim_to_treat: 2,
    priority: 'Standard',
    referrer: 'Dr. Jonathan Blake',
    diagnosis_code: 'C43.9',
  },
  {
    fractions: 14,
    site: 'Red Hills',
    physician: 'Dr Wong',
    date: '2023-09-18',
    diagnosis: 'colorectal',
    fraction_dose: 2.08,
    adherence: true,
    technique: 'VMAT',
    linac: 'Machine 2',
    sim_to_treat: 1,
    priority: 'Urgent',
    referrer: 'Dr. Alex Reynolds',
    diagnosis_code: 'C71.9',
  },
  {
    fractions: 21,
    site: 'Red Hills',
    physician: 'Dr Lopez',
    date: '2023-09-30',
    diagnosis: 'Unspecified',
    fraction_dose: 2.16,
    adherence: true,
    technique: 'VMAT',
    linac: 'Machine 2',
    sim_to_treat: 9,
    priority: 'Standard',
    referrer: 'Dr. Clara Thompson',
    diagnosis_code: 'C34.90',
  },
  {
    fractions: 13,
    site: 'Campsie',
    physician: 'Dr Smith',
    date: '2023-07-21',
    diagnosis: 'neuro',
    fraction_dose: 1.22,
    adherence: true,
    technique: 'Unspecified',
    linac: 'Machine 4',
    sim_to_treat: 5,
    priority: 'Emergency',
    referrer: 'Dr. Emily Hartman',
    diagnosis_code: 'C34.90',
  },
  {
    fractions: 24,
    site: 'Lakeside',
    physician: 'Dr Lopez',
    date: '2023-09-19',
    diagnosis: 'prostate',
    fraction_dose: 1.88,
    adherence: true,
    technique: 'Conformal',
    linac: 'Machine 2',
    sim_to_treat: 4,
    priority: 'Urgent',
    referrer: 'Dr. Victor Hernandes',
    diagnosis_code: 'C43.9',
  },
  {
    fractions: 20,
    site: 'Lakeside',
    physician: 'Dr Adams',
    date: '2023-01-23',
    diagnosis: 'neuro',
    fraction_dose: 2.59,
    adherence: true,
    technique: 'VMAT',
    linac: 'Machine 2',
    sim_to_treat: 2,
    priority: 'Standard',
    referrer: 'Dr. Clara Thompson',
    diagnosis_code: 'C43.9',
  },
  {
    fractions: 22,
    site: 'Red Hills',
    physician: 'Dr Smith',
    date: '2023-11-16',
    diagnosis: 'skin',
    fraction_dose: 2.49,
    adherence: true,
    technique: 'VMAT',
    linac: 'Machine 1',
    sim_to_treat: 3,
    priority: 'Standard',
    referrer: 'Dr. Clara Thompson',
    diagnosis_code: 'C71.9',
  },
  {
    fractions: 25,
    site: 'Rosebush',
    physician: 'Dr Smith',
    date: '2023-08-07',
    diagnosis: 'Unspecified',
    fraction_dose: 3.29,
    adherence: true,
    technique: 'VMAT',
    linac: 'Machine 4',
    sim_to_treat: 3,
    priority: 'Urgent',
    referrer: 'Dr. Michael Stern',
    diagnosis_code: 'C43.9',
  },
  {
    fractions: 18,
    site: 'Campsie',
    physician: 'Dr Smith',
    date: '2023-04-22',
    diagnosis: 'metastasis',
    fraction_dose: 3.31,
    adherence: true,
    technique: 'Conformal',
    linac: 'Machine 3',
    sim_to_treat: 7,
    priority: 'Urgent',
    referrer: 'Dr. Clara Thompson',
    diagnosis_code: 'C79.81',
  },
  {
    fractions: 25,
    site: 'Red Hills',
    physician: 'Dr Wong',
    date: '2023-06-04',
    diagnosis: 'colorectal',
    fraction_dose: 3.08,
    adherence: true,
    technique: 'Electrons',
    linac: 'Machine 1',
    sim_to_treat: 7,
    priority: 'Standard',
    referrer: 'Dr. Rachel Nguyen',
    diagnosis_code: 'C71.9',
  },
  {
    fractions: 21,
    site: 'Lakeside',
    physician: 'Dr Adams',
    date: '2023-08-28',
    diagnosis: 'Unspecified',
    fraction_dose: 2.47,
    adherence: true,
    technique: 'Electrons',
    linac: 'Machine 1',
    sim_to_treat: 1,
    priority: 'Standard',
    referrer: 'Dr. Michael Stern',
    diagnosis_code: 'C61',
  },
  {
    fractions: 20,
    site: 'Red Hills',
    physician: 'Dr Lopez',
    date: '2023-06-18',
    diagnosis: 'Unspecified',
    fraction_dose: 1.41,
    adherence: true,
    technique: 'VMAT',
    linac: 'Machine 1',
    sim_to_treat: 4,
    priority: 'Urgent',
    referrer: 'Dr. Emily Hartman',
    diagnosis_code: 'C61',
  },
  {
    fractions: 11,
    site: 'Lakeside',
    physician: 'Dr Smith',
    date: '2023-02-17',
    diagnosis: 'prostate',
    fraction_dose: 1.62,
    adherence: true,
    technique: 'VMAT',
    linac: 'Machine 3',
    sim_to_treat: 1,
    priority: 'Urgent',
    referrer: 'Dr. Rachel Nguyen',
    diagnosis_code: 'C79.81',
  },
  {
    fractions: 15,
    site: 'Campsie',
    physician: 'Dr Smith',
    date: '2023-06-02',
    diagnosis: 'skin',
    fraction_dose: 1.56,
    adherence: true,
    technique: 'VMAT',
    linac: 'Machine 3',
    sim_to_treat: 5,
    priority: 'Standard',
    referrer: 'Dr. Jonathan Blake',
    diagnosis_code: 'C79.81',
  },
  {
    fractions: 10,
    site: 'Red Hills',
    physician: 'Dr Wong',
    date: '2023-02-03',
    diagnosis: 'colorectal',
    fraction_dose: 2.34,
    adherence: true,
    technique: 'Unspecified',
    linac: 'Machine 1',
    sim_to_treat: 2,
    priority: 'Standard',
    referrer: 'Dr. Rachel Nguyen',
    diagnosis_code: 'C34.90',
  },
  {
    fractions: 11,
    site: 'Campsie',
    physician: 'Dr Lopez',
    date: '2023-07-15',
    diagnosis: 'neuro',
    fraction_dose: 2.75,
    adherence: true,
    technique: 'Unspecified',
    linac: 'Machine 4',
    sim_to_treat: 8,
    priority: 'Urgent',
    referrer: 'Dr. Emily Hartman',
    diagnosis_code: 'C61',
  },
  {
    fractions: 23,
    site: 'Red Hills',
    physician: 'Dr Wong',
    date: '2023-03-09',
    diagnosis: 'prostate',
    fraction_dose: 1.27,
    adherence: true,
    technique: 'Unspecified',
    linac: 'Machine 4',
    sim_to_treat: 3,
    priority: 'Emergency',
    referrer: 'Dr. Michael Stern',
    diagnosis_code: 'C61',
  },
  {
    fractions: 14,
    site: 'Rosebush',
    physician: 'Dr Lopez',
    date: '2023-08-27',
    diagnosis: 'Unspecified',
    fraction_dose: 3.09,
    adherence: false,
    technique: 'Unspecified',
    linac: 'Machine 2',
    sim_to_treat: 8,
    priority: 'Emergency',
    referrer: 'Dr. Alex Reynolds',
    diagnosis_code: 'C43.9',
  },
  {
    fractions: 18,
    site: 'Red Hills',
    physician: 'Dr Wong',
    date: '2023-05-31',
    diagnosis: 'Unspecified',
    fraction_dose: 1.62,
    adherence: true,
    technique: 'Stereotactic',
    linac: 'Machine 3',
    sim_to_treat: 4,
    priority: 'Urgent',
    referrer: 'Dr. Julian Foster',
    diagnosis_code: 'C43.9',
  },
  {
    fractions: 22,
    site: 'Rosebush',
    physician: 'Dr Smith',
    date: '2023-05-28',
    diagnosis: 'metastasis',
    fraction_dose: 2.99,
    adherence: true,
    technique: 'Unspecified',
    linac: 'Machine 2',
    sim_to_treat: 2,
    priority: 'Urgent',
    referrer: 'Dr. Emily Hartman',
    diagnosis_code: 'C71.9',
  },
  {
    fractions: 10,
    site: 'Rosebush',
    physician: 'Dr Adams',
    date: '2023-08-20',
    diagnosis: 'metastasis',
    fraction_dose: 1.72,
    adherence: true,
    technique: 'IMRT',
    linac: 'Machine 2',
    sim_to_treat: 9,
    priority: 'Emergency',
    referrer: 'Dr. Aisha Patel',
    diagnosis_code: 'C43.9',
  },
  {
    fractions: 14,
    site: 'Lakeside',
    physician: 'Dr Smith',
    date: '2023-05-20',
    diagnosis: 'neuro',
    fraction_dose: 1.22,
    adherence: true,
    technique: 'Stereotactic',
    linac: 'Machine 4',
    sim_to_treat: 10,
    priority: 'Emergency',
    referrer: 'Dr. Rachel Nguyen',
    diagnosis_code: 'C34.90',
  },
  {
    fractions: 16,
    site: 'Lakeside',
    physician: 'Dr Adams',
    date: '2023-03-02',
    diagnosis: 'colorectal',
    fraction_dose: 1.42,
    adherence: true,
    technique: 'Brachytherapy',
    linac: 'Machine 1',
    sim_to_treat: 5,
    priority: 'Standard',
    referrer: 'Dr. Alex Reynolds',
    diagnosis_code: 'C79.81',
  },
  {
    fractions: 24,
    site: 'Rosebush',
    physician: 'Dr Lopez',
    date: '2023-02-15',
    diagnosis: 'breast',
    fraction_dose: 2.85,
    adherence: true,
    technique: 'Conformal',
    linac: 'Machine 1',
    sim_to_treat: 8,
    priority: 'Urgent',
    referrer: 'Dr. Rachel Nguyen',
    diagnosis_code: 'C43.9',
  },
  {
    fractions: 20,
    site: 'Rosebush',
    physician: 'Dr Lopez',
    date: '2023-07-09',
    diagnosis: 'breast',
    fraction_dose: 2.57,
    adherence: true,
    technique: 'Unspecified',
    linac: 'Machine 4',
    sim_to_treat: 7,
    priority: 'Emergency',
    referrer: 'Dr. Emily Hartman',
    diagnosis_code: 'C71.9',
  },
  {
    fractions: 16,
    site: 'Rosebush',
    physician: 'Dr Wong',
    date: '2023-07-26',
    diagnosis: 'Unspecified',
    fraction_dose: 1.92,
    adherence: true,
    technique: 'IMRT',
    linac: 'Machine 4',
    sim_to_treat: 2,
    priority: 'Standard',
    referrer: 'Dr. Emily Hartman',
    diagnosis_code: 'C61',
  },
  {
    fractions: 24,
    site: 'Campsie',
    physician: 'Dr Wong',
    date: '2023-10-10',
    diagnosis: 'metastasis',
    fraction_dose: 3.02,
    adherence: false,
    technique: 'Brachytherapy',
    linac: 'Machine 3',
    sim_to_treat: 7,
    priority: 'Emergency',
    referrer: 'Dr. Sophie Greenberg',
    diagnosis_code: 'C43.9',
  },
  {
    fractions: 17,
    site: 'Lakeside',
    physician: 'Dr Wong',
    date: '2023-04-01',
    diagnosis: 'neuro',
    fraction_dose: 1.53,
    adherence: true,
    technique: 'VMAT',
    linac: 'Machine 3',
    sim_to_treat: 7,
    priority: 'Emergency',
    referrer: 'Dr. Jonathan Blake',
    diagnosis_code: 'C20',
  },
  {
    fractions: 10,
    site: 'Rosebush',
    physician: 'Dr Lopez',
    date: '2023-05-16',
    diagnosis: 'skin',
    fraction_dose: 3.13,
    adherence: true,
    technique: 'Unspecified',
    linac: 'Machine 4',
    sim_to_treat: 5,
    priority: 'Urgent',
    referrer: 'Dr. Aisha Patel',
    diagnosis_code: 'C20',
  },
  {
    fractions: 18,
    site: 'Lakeside',
    physician: 'Dr Wong',
    date: '2023-06-04',
    diagnosis: 'skin',
    fraction_dose: 2.87,
    adherence: true,
    technique: 'VMAT',
    linac: 'Machine 1',
    sim_to_treat: 1,
    priority: 'Emergency',
    referrer: 'Dr. Emily Hartman',
    diagnosis_code: 'C34.90',
  },
  {
    fractions: 25,
    site: 'Campsie',
    physician: 'Dr Adams',
    date: '2023-01-07',
    diagnosis: 'Unspecified',
    fraction_dose: 2.45,
    adherence: true,
    technique: 'VMAT',
    linac: 'Machine 3',
    sim_to_treat: 2,
    priority: 'Urgent',
    referrer: 'Dr. Aisha Patel',
    diagnosis_code: 'C20',
  },
  {
    fractions: 13,
    site: 'Campsie',
    physician: 'Dr Smith',
    date: '2023-11-28',
    diagnosis: 'prostate',
    fraction_dose: 2.64,
    adherence: true,
    technique: 'VMAT',
    linac: 'Machine 4',
    sim_to_treat: 4,
    priority: 'Urgent',
    referrer: 'Dr. Julian Foster',
    diagnosis_code: 'C20',
  },
  {
    fractions: 12,
    site: 'Lakeside',
    physician: 'Dr Wong',
    date: '2023-10-08',
    diagnosis: 'neuro',
    fraction_dose: 1.85,
    adherence: true,
    technique: 'Conformal',
    linac: 'Machine 4',
    sim_to_treat: 10,
    priority: 'Emergency',
    referrer: 'Dr. Michael Stern',
    diagnosis_code: 'C71.9',
  },
  {
    fractions: 23,
    site: 'Campsie',
    physician: 'Dr Wong',
    date: '2023-09-03',
    diagnosis: 'skin',
    fraction_dose: 2.44,
    adherence: false,
    technique: 'IMRT',
    linac: 'Machine 3',
    sim_to_treat: 9,
    priority: 'Emergency',
    referrer: 'Dr. Aisha Patel',
    diagnosis_code: 'C20',
  },
  {
    fractions: 23,
    site: 'Campsie',
    physician: 'Dr Smith',
    date: '2023-10-09',
    diagnosis: 'prostate',
    fraction_dose: 2.53,
    adherence: true,
    technique: 'IMRT',
    linac: 'Machine 3',
    sim_to_treat: 7,
    priority: 'Standard',
    referrer: 'Dr. Julian Foster',
    diagnosis_code: 'C20',
  },
  {
    fractions: 19,
    site: 'Lakeside',
    physician: 'Dr Wong',
    date: '2023-11-22',
    diagnosis: 'skin',
    fraction_dose: 1.36,
    adherence: true,
    technique: 'Conformal',
    linac: 'Machine 3',
    sim_to_treat: 4,
    priority: 'Urgent',
    referrer: 'Dr. Emily Hartman',
    diagnosis_code: 'C43.9',
  },
  {
    fractions: 17,
    site: 'Red Hills',
    physician: 'Dr Wong',
    date: '2023-02-05',
    diagnosis: 'prostate',
    fraction_dose: 2.64,
    adherence: true,
    technique: 'Electrons',
    linac: 'Machine 3',
    sim_to_treat: 3,
    priority: 'Emergency',
    referrer: 'Dr. Jonathan Blake',
    diagnosis_code: 'C71.9',
  },
  {
    fractions: 13,
    site: 'Rosebush',
    physician: 'Dr Lopez',
    date: '2023-09-09',
    diagnosis: 'neuro',
    fraction_dose: 3.16,
    adherence: true,
    technique: 'VMAT',
    linac: 'Machine 1',
    sim_to_treat: 1,
    priority: 'Urgent',
    referrer: 'Dr. Jonathan Blake',
    diagnosis_code: 'C34.90',
  },
  {
    fractions: 25,
    site: 'Campsie',
    physician: 'Dr Smith',
    date: '2023-11-24',
    diagnosis: 'Unspecified',
    fraction_dose: 1.43,
    adherence: true,
    technique: 'IMRT',
    linac: 'Machine 3',
    sim_to_treat: 8,
    priority: 'Emergency',
    referrer: 'Dr. Sophie Greenberg',
    diagnosis_code: 'C43.9',
  },
  {
    fractions: 25,
    site: 'Rosebush',
    physician: 'Dr Smith',
    date: '2023-07-09',
    diagnosis: 'Unspecified',
    fraction_dose: 1.74,
    adherence: true,
    technique: 'Unspecified',
    linac: 'Machine 3',
    sim_to_treat: 7,
    priority: 'Emergency',
    referrer: 'Dr. Sophie Greenberg',
    diagnosis_code: 'C20',
  },
  {
    fractions: 21,
    site: 'Lakeside',
    physician: 'Dr Lopez',
    date: '2023-05-09',
    diagnosis: 'neuro',
    fraction_dose: 2.11,
    adherence: true,
    technique: 'VMAT',
    linac: 'Machine 2',
    sim_to_treat: 2,
    priority: 'Urgent',
    referrer: 'Dr. Michael Stern',
    diagnosis_code: 'C79.81',
  },
  {
    fractions: 17,
    site: 'Campsie',
    physician: 'Dr Lopez',
    date: '2023-03-10',
    diagnosis: 'colorectal',
    fraction_dose: 1.41,
    adherence: true,
    technique: 'VMAT',
    linac: 'Machine 1',
    sim_to_treat: 8,
    priority: 'Emergency',
    referrer: 'Dr. Alex Reynolds',
    diagnosis_code: 'C61',
  },
  {
    fractions: 10,
    site: 'Red Hills',
    physician: 'Dr Lopez',
    date: '2023-01-05',
    diagnosis: 'colorectal',
    fraction_dose: 3.11,
    adherence: true,
    technique: 'Stereotactic',
    linac: 'Machine 4',
    sim_to_treat: 9,
    priority: 'Urgent',
    referrer: 'Dr. Michael Stern',
    diagnosis_code: 'C79.81',
  },
  {
    fractions: 20,
    site: 'Lakeside',
    physician: 'Dr Adams',
    date: '2023-02-07',
    diagnosis: 'prostate',
    fraction_dose: 3.08,
    adherence: true,
    technique: 'Unspecified',
    linac: 'Machine 3',
    sim_to_treat: 7,
    priority: 'Standard',
    referrer: 'Dr. Sophie Greenberg',
    diagnosis_code: 'C43.9',
  },
  {
    fractions: 20,
    site: 'Lakeside',
    physician: 'Dr Wong',
    date: '2023-09-16',
    diagnosis: 'neuro',
    fraction_dose: 2.0,
    adherence: true,
    technique: 'Unspecified',
    linac: 'Machine 1',
    sim_to_treat: 1,
    priority: 'Emergency',
    referrer: 'Dr. Julian Foster',
    diagnosis_code: 'C34.90',
  },
  {
    fractions: 13,
    site: 'Red Hills',
    physician: 'Dr Wong',
    date: '2023-02-07',
    diagnosis: 'colorectal',
    fraction_dose: 2.22,
    adherence: true,
    technique: 'IMRT',
    linac: 'Machine 1',
    sim_to_treat: 2,
    priority: 'Urgent',
    referrer: 'Dr. Alex Reynolds',
    diagnosis_code: 'C34.90',
  },
  {
    fractions: 23,
    site: 'Campsie',
    physician: 'Dr Lopez',
    date: '2023-03-30',
    diagnosis: 'neuro',
    fraction_dose: 2.04,
    adherence: true,
    technique: 'Brachytherapy',
    linac: 'Machine 2',
    sim_to_treat: 3,
    priority: 'Emergency',
    referrer: 'Dr. Sophie Greenberg',
    diagnosis_code: 'C43.9',
  },
  {
    fractions: 22,
    site: 'Lakeside',
    physician: 'Dr Smith',
    date: '2023-01-22',
    diagnosis: 'neuro',
    fraction_dose: 1.32,
    adherence: true,
    technique: 'IMRT',
    linac: 'Machine 2',
    sim_to_treat: 10,
    priority: 'Urgent',
    referrer: 'Dr. Sophie Greenberg',
    diagnosis_code: 'C20',
  },
  {
    fractions: 22,
    site: 'Rosebush',
    physician: 'Dr Smith',
    date: '2023-11-02',
    diagnosis: 'neuro',
    fraction_dose: 2.48,
    adherence: true,
    technique: 'Electrons',
    linac: 'Machine 2',
    sim_to_treat: 7,
    priority: 'Emergency',
    referrer: 'Dr. Jonathan Blake',
    diagnosis_code: 'C79.81',
  },
  {
    fractions: 16,
    site: 'Lakeside',
    physician: 'Dr Adams',
    date: '2023-12-09',
    diagnosis: 'metastasis',
    fraction_dose: 2.09,
    adherence: true,
    technique: 'Brachytherapy',
    linac: 'Machine 3',
    sim_to_treat: 4,
    priority: 'Standard',
    referrer: 'Dr. Michael Stern',
    diagnosis_code: 'C43.9',
  },
  {
    fractions: 14,
    site: 'Campsie',
    physician: 'Dr Adams',
    date: '2023-03-27',
    diagnosis: 'prostate',
    fraction_dose: 2.88,
    adherence: true,
    technique: 'Stereotactic',
    linac: 'Machine 1',
    sim_to_treat: 10,
    priority: 'Emergency',
    referrer: 'Dr. Sophie Greenberg',
    diagnosis_code: 'C71.9',
  },
  {
    fractions: 19,
    site: 'Rosebush',
    physician: 'Dr Adams',
    date: '2023-02-13',
    diagnosis: 'breast',
    fraction_dose: 3.11,
    adherence: true,
    technique: 'Unspecified',
    linac: 'Machine 1',
    sim_to_treat: 8,
    priority: 'Standard',
    referrer: 'Dr. Rachel Nguyen',
    diagnosis_code: 'C43.9',
  },
  {
    fractions: 20,
    site: 'Lakeside',
    physician: 'Dr Wong',
    date: '2023-01-20',
    diagnosis: 'metastasis',
    fraction_dose: 1.77,
    adherence: true,
    technique: 'IMRT',
    linac: 'Machine 1',
    sim_to_treat: 10,
    priority: 'Urgent',
    referrer: 'Dr. Alex Reynolds',
    diagnosis_code: 'C61',
  },
  {
    fractions: 23,
    site: 'Red Hills',
    physician: 'Dr Lopez',
    date: '2023-12-15',
    diagnosis: 'colorectal',
    fraction_dose: 3.16,
    adherence: true,
    technique: 'Electrons',
    linac: 'Machine 3',
    sim_to_treat: 1,
    priority: 'Standard',
    referrer: 'Dr. Victor Hernandes',
    diagnosis_code: 'C43.9',
  },
  {
    fractions: 11,
    site: 'Red Hills',
    physician: 'Dr Wong',
    date: '2023-10-22',
    diagnosis: 'skin',
    fraction_dose: 2.34,
    adherence: true,
    technique: 'IMRT',
    linac: 'Machine 4',
    sim_to_treat: 4,
    priority: 'Urgent',
    referrer: 'Dr. Julian Foster',
    diagnosis_code: 'C71.9',
  },
  {
    fractions: 12,
    site: 'Campsie',
    physician: 'Dr Wong',
    date: '2023-04-17',
    diagnosis: 'neuro',
    fraction_dose: 1.6,
    adherence: true,
    technique: 'VMAT',
    linac: 'Machine 4',
    sim_to_treat: 8,
    priority: 'Standard',
    referrer: 'Dr. Michael Stern',
    diagnosis_code: 'C20',
  },
  {
    fractions: 13,
    site: 'Campsie',
    physician: 'Dr Lopez',
    date: '2023-08-14',
    diagnosis: 'neuro',
    fraction_dose: 2.74,
    adherence: true,
    technique: 'VMAT',
    linac: 'Machine 4',
    sim_to_treat: 10,
    priority: 'Emergency',
    referrer: 'Dr. Michael Stern',
    diagnosis_code: 'C43.9',
  },
  {
    fractions: 20,
    site: 'Lakeside',
    physician: 'Dr Smith',
    date: '2023-07-11',
    diagnosis: 'colorectal',
    fraction_dose: 2.79,
    adherence: true,
    technique: 'IMRT',
    linac: 'Machine 4',
    sim_to_treat: 1,
    priority: 'Urgent',
    referrer: 'Dr. Alex Reynolds',
    diagnosis_code: 'C43.9',
  },
  {
    fractions: 13,
    site: 'Red Hills',
    physician: 'Dr Adams',
    date: '2023-01-02',
    diagnosis: 'metastasis',
    fraction_dose: 1.28,
    adherence: true,
    technique: 'Unspecified',
    linac: 'Machine 1',
    sim_to_treat: 2,
    priority: 'Emergency',
    referrer: 'Dr. Rachel Nguyen',
    diagnosis_code: 'C71.9',
  },
  {
    fractions: 11,
    site: 'Rosebush',
    physician: 'Dr Lopez',
    date: '2023-09-27',
    diagnosis: 'neuro',
    fraction_dose: 2.76,
    adherence: true,
    technique: 'Unspecified',
    linac: 'Machine 3',
    sim_to_treat: 5,
    priority: 'Urgent',
    referrer: 'Dr. Clara Thompson',
    diagnosis_code: 'C79.81',
  },
  {
    fractions: 15,
    site: 'Lakeside',
    physician: 'Dr Adams',
    date: '2023-02-14',
    diagnosis: 'breast',
    fraction_dose: 1.94,
    adherence: true,
    technique: 'Unspecified',
    linac: 'Machine 3',
    sim_to_treat: 1,
    priority: 'Standard',
    referrer: 'Dr. Emily Hartman',
    diagnosis_code: 'C43.9',
  },
  {
    fractions: 22,
    site: 'Red Hills',
    physician: 'Dr Adams',
    date: '2023-02-26',
    diagnosis: 'colorectal',
    fraction_dose: 1.34,
    adherence: true,
    technique: 'Stereotactic',
    linac: 'Machine 4',
    sim_to_treat: 1,
    priority: 'Standard',
    referrer: 'Dr. Alex Reynolds',
    diagnosis_code: 'C61',
  },
  {
    fractions: 17,
    site: 'Lakeside',
    physician: 'Dr Adams',
    date: '2023-10-28',
    diagnosis: 'neuro',
    fraction_dose: 3.17,
    adherence: true,
    technique: 'VMAT',
    linac: 'Machine 1',
    sim_to_treat: 10,
    priority: 'Standard',
    referrer: 'Dr. Emily Hartman',
    diagnosis_code: 'C79.81',
  },
  {
    fractions: 12,
    site: 'Rosebush',
    physician: 'Dr Adams',
    date: '2023-01-21',
    diagnosis: 'neuro',
    fraction_dose: 2.27,
    adherence: true,
    technique: 'Unspecified',
    linac: 'Machine 4',
    sim_to_treat: 3,
    priority: 'Emergency',
    referrer: 'Dr. Michael Stern',
    diagnosis_code: 'C61',
  },
  {
    fractions: 17,
    site: 'Campsie',
    physician: 'Dr Smith',
    date: '2023-04-01',
    diagnosis: 'skin',
    fraction_dose: 1.3,
    adherence: true,
    technique: 'Stereotactic',
    linac: 'Machine 2',
    sim_to_treat: 9,
    priority: 'Urgent',
    referrer: 'Dr. Victor Hernandes',
    diagnosis_code: 'C34.90',
  },
  {
    fractions: 15,
    site: 'Lakeside',
    physician: 'Dr Wong',
    date: '2023-10-11',
    diagnosis: 'prostate',
    fraction_dose: 2.21,
    adherence: true,
    technique: 'IMRT',
    linac: 'Machine 2',
    sim_to_treat: 6,
    priority: 'Emergency',
    referrer: 'Dr. Alex Reynolds',
    diagnosis_code: 'C61',
  },
  {
    fractions: 10,
    site: 'Lakeside',
    physician: 'Dr Lopez',
    date: '2023-10-20',
    diagnosis: 'colorectal',
    fraction_dose: 2.94,
    adherence: true,
    technique: 'Conformal',
    linac: 'Machine 4',
    sim_to_treat: 8,
    priority: 'Standard',
    referrer: 'Dr. Victor Hernandes',
    diagnosis_code: 'C34.90',
  },
  {
    fractions: 13,
    site: 'Rosebush',
    physician: 'Dr Adams',
    date: '2023-07-28',
    diagnosis: 'Unspecified',
    fraction_dose: 3.27,
    adherence: false,
    technique: 'Conformal',
    linac: 'Machine 2',
    sim_to_treat: 10,
    priority: 'Emergency',
    referrer: 'Dr. Sophie Greenberg',
    diagnosis_code: 'C43.9',
  },
  {
    fractions: 17,
    site: 'Campsie',
    physician: 'Dr Adams',
    date: '2023-08-02',
    diagnosis: 'breast',
    fraction_dose: 3.01,
    adherence: true,
    technique: 'Stereotactic',
    linac: 'Machine 4',
    sim_to_treat: 2,
    priority: 'Urgent',
    referrer: 'Dr. Victor Hernandes',
    diagnosis_code: 'C34.90',
  },
  {
    fractions: 18,
    site: 'Rosebush',
    physician: 'Dr Lopez',
    date: '2023-09-10',
    diagnosis: 'skin',
    fraction_dose: 1.32,
    adherence: true,
    technique: 'Brachytherapy',
    linac: 'Machine 1',
    sim_to_treat: 1,
    priority: 'Standard',
    referrer: 'Dr. Sophie Greenberg',
    diagnosis_code: 'C20',
  },
  {
    fractions: 18,
    site: 'Red Hills',
    physician: 'Dr Adams',
    date: '2023-05-30',
    diagnosis: 'skin',
    fraction_dose: 3.33,
    adherence: true,
    technique: 'IMRT',
    linac: 'Machine 3',
    sim_to_treat: 6,
    priority: 'Emergency',
    referrer: 'Dr. Michael Stern',
    diagnosis_code: 'C71.9',
  },
  {
    fractions: 18,
    site: 'Red Hills',
    physician: 'Dr Lopez',
    date: '2023-09-04',
    diagnosis: 'Unspecified',
    fraction_dose: 1.41,
    adherence: true,
    technique: 'Unspecified',
    linac: 'Machine 4',
    sim_to_treat: 5,
    priority: 'Standard',
    referrer: 'Dr. Aisha Patel',
    diagnosis_code: 'C79.81',
  },
  {
    fractions: 20,
    site: 'Campsie',
    physician: 'Dr Smith',
    date: '2023-12-14',
    diagnosis: 'colorectal',
    fraction_dose: 2.57,
    adherence: true,
    technique: 'Brachytherapy',
    linac: 'Machine 1',
    sim_to_treat: 9,
    priority: 'Urgent',
    referrer: 'Dr. Aisha Patel',
    diagnosis_code: 'C34.90',
  },
  {
    fractions: 16,
    site: 'Red Hills',
    physician: 'Dr Lopez',
    date: '2023-04-06',
    diagnosis: 'prostate',
    fraction_dose: 1.74,
    adherence: true,
    technique: 'IMRT',
    linac: 'Machine 1',
    sim_to_treat: 8,
    priority: 'Standard',
    referrer: 'Dr. Emily Hartman',
    diagnosis_code: 'C43.9',
  },
  {
    fractions: 19,
    site: 'Red Hills',
    physician: 'Dr Lopez',
    date: '2023-09-01',
    diagnosis: 'Unspecified',
    fraction_dose: 1.53,
    adherence: true,
    technique: 'Electrons',
    linac: 'Machine 2',
    sim_to_treat: 4,
    priority: 'Emergency',
    referrer: 'Dr. Jonathan Blake',
    diagnosis_code: 'C71.9',
  },
  {
    fractions: 24,
    site: 'Campsie',
    physician: 'Dr Wong',
    date: '2023-07-01',
    diagnosis: 'colorectal',
    fraction_dose: 2.65,
    adherence: true,
    technique: 'Brachytherapy',
    linac: 'Machine 3',
    sim_to_treat: 1,
    priority: 'Urgent',
    referrer: 'Dr. Emily Hartman',
    diagnosis_code: 'C43.9',
  },
  {
    fractions: 20,
    site: 'Lakeside',
    physician: 'Dr Smith',
    date: '2023-12-19',
    diagnosis: 'metastasis',
    fraction_dose: 2.82,
    adherence: true,
    technique: 'Stereotactic',
    linac: 'Machine 1',
    sim_to_treat: 3,
    priority: 'Urgent',
    referrer: 'Dr. Michael Stern',
    diagnosis_code: 'C20',
  },
  {
    fractions: 18,
    site: 'Campsie',
    physician: 'Dr Lopez',
    date: '2023-11-30',
    diagnosis: 'breast',
    fraction_dose: 2.46,
    adherence: true,
    technique: 'Electrons',
    linac: 'Machine 2',
    sim_to_treat: 1,
    priority: 'Emergency',
    referrer: 'Dr. Emily Hartman',
    diagnosis_code: 'C20',
  },
  {
    fractions: 23,
    site: 'Red Hills',
    physician: 'Dr Lopez',
    date: '2023-04-04',
    diagnosis: 'Unspecified',
    fraction_dose: 2.78,
    adherence: true,
    technique: 'IMRT',
    linac: 'Machine 3',
    sim_to_treat: 4,
    priority: 'Standard',
    referrer: 'Dr. Julian Foster',
    diagnosis_code: 'C79.81',
  },
  {
    fractions: 21,
    site: 'Rosebush',
    physician: 'Dr Wong',
    date: '2023-10-20',
    diagnosis: 'breast',
    fraction_dose: 1.77,
    adherence: true,
    technique: 'IMRT',
    linac: 'Machine 4',
    sim_to_treat: 2,
    priority: 'Standard',
    referrer: 'Dr. Michael Stern',
    diagnosis_code: 'C71.9',
  },
  {
    fractions: 13,
    site: 'Red Hills',
    physician: 'Dr Wong',
    date: '2023-07-24',
    diagnosis: 'breast',
    fraction_dose: 2.23,
    adherence: true,
    technique: 'Conformal',
    linac: 'Machine 3',
    sim_to_treat: 10,
    priority: 'Urgent',
    referrer: 'Dr. Rachel Nguyen',
    diagnosis_code: 'C71.9',
  },
  {
    fractions: 15,
    site: 'Rosebush',
    physician: 'Dr Smith',
    date: '2023-05-07',
    diagnosis: 'colorectal',
    fraction_dose: 2.14,
    adherence: true,
    technique: 'Unspecified',
    linac: 'Machine 1',
    sim_to_treat: 6,
    priority: 'Emergency',
    referrer: 'Dr. Aisha Patel',
    diagnosis_code: 'C34.90',
  },
  {
    fractions: 13,
    site: 'Red Hills',
    physician: 'Dr Lopez',
    date: '2023-03-25',
    diagnosis: 'breast',
    fraction_dose: 1.99,
    adherence: true,
    technique: 'Electrons',
    linac: 'Machine 4',
    sim_to_treat: 5,
    priority: 'Standard',
    referrer: 'Dr. Victor Hernandes',
    diagnosis_code: 'C71.9',
  },
  {
    fractions: 21,
    site: 'Red Hills',
    physician: 'Dr Lopez',
    date: '2023-09-18',
    diagnosis: 'Unspecified',
    fraction_dose: 1.81,
    adherence: true,
    technique: 'VMAT',
    linac: 'Machine 3',
    sim_to_treat: 10,
    priority: 'Emergency',
    referrer: 'Dr. Jonathan Blake',
    diagnosis_code: 'C20',
  },
  {
    fractions: 20,
    site: 'Campsie',
    physician: 'Dr Adams',
    date: '2023-01-04',
    diagnosis: 'neuro',
    fraction_dose: 1.86,
    adherence: true,
    technique: 'Unspecified',
    linac: 'Machine 3',
    sim_to_treat: 8,
    priority: 'Emergency',
    referrer: 'Dr. Clara Thompson',
    diagnosis_code: 'C61',
  },
  {
    fractions: 20,
    site: 'Lakeside',
    physician: 'Dr Smith',
    date: '2023-09-22',
    diagnosis: 'neuro',
    fraction_dose: 1.56,
    adherence: true,
    technique: 'Stereotactic',
    linac: 'Machine 2',
    sim_to_treat: 2,
    priority: 'Urgent',
    referrer: 'Dr. Emily Hartman',
    diagnosis_code: 'C61',
  },
  {
    fractions: 13,
    site: 'Rosebush',
    physician: 'Dr Smith',
    date: '2023-08-04',
    diagnosis: 'breast',
    fraction_dose: 1.21,
    adherence: true,
    technique: 'IMRT',
    linac: 'Machine 3',
    sim_to_treat: 4,
    priority: 'Urgent',
    referrer: 'Dr. Alex Reynolds',
    diagnosis_code: 'C71.9',
  },
  {
    fractions: 16,
    site: 'Rosebush',
    physician: 'Dr Smith',
    date: '2023-01-23',
    diagnosis: 'breast',
    fraction_dose: 1.33,
    adherence: true,
    technique: 'Conformal',
    linac: 'Machine 2',
    sim_to_treat: 8,
    priority: 'Standard',
    referrer: 'Dr. Jonathan Blake',
    diagnosis_code: 'C43.9',
  },
  {
    fractions: 24,
    site: 'Red Hills',
    physician: 'Dr Adams',
    date: '2023-03-08',
    diagnosis: 'neuro',
    fraction_dose: 2.7,
    adherence: true,
    technique: 'Conformal',
    linac: 'Machine 4',
    sim_to_treat: 10,
    priority: 'Urgent',
    referrer: 'Dr. Alex Reynolds',
    diagnosis_code: 'C61',
  },
  {
    fractions: 13,
    site: 'Rosebush',
    physician: 'Dr Wong',
    date: '2023-06-30',
    diagnosis: 'metastasis',
    fraction_dose: 3.03,
    adherence: true,
    technique: 'Unspecified',
    linac: 'Machine 4',
    sim_to_treat: 6,
    priority: 'Standard',
    referrer: 'Dr. Julian Foster',
    diagnosis_code: 'C34.90',
  },
  {
    fractions: 10,
    site: 'Lakeside',
    physician: 'Dr Adams',
    date: '2023-11-15',
    diagnosis: 'skin',
    fraction_dose: 1.84,
    adherence: true,
    technique: 'Conformal',
    linac: 'Machine 2',
    sim_to_treat: 5,
    priority: 'Emergency',
    referrer: 'Dr. Emily Hartman',
    diagnosis_code: 'C20',
  },
  {
    fractions: 24,
    site: 'Lakeside',
    physician: 'Dr Smith',
    date: '2023-06-11',
    diagnosis: 'skin',
    fraction_dose: 1.22,
    adherence: true,
    technique: 'Electrons',
    linac: 'Machine 2',
    sim_to_treat: 5,
    priority: 'Standard',
    referrer: 'Dr. Julian Foster',
    diagnosis_code: 'C71.9',
  },
  {
    fractions: 21,
    site: 'Lakeside',
    physician: 'Dr Lopez',
    date: '2023-07-16',
    diagnosis: 'prostate',
    fraction_dose: 1.64,
    adherence: true,
    technique: 'Stereotactic',
    linac: 'Machine 3',
    sim_to_treat: 10,
    priority: 'Emergency',
    referrer: 'Dr. Alex Reynolds',
    diagnosis_code: 'C79.81',
  },
  {
    fractions: 23,
    site: 'Red Hills',
    physician: 'Dr Wong',
    date: '2023-12-07',
    diagnosis: 'prostate',
    fraction_dose: 2.11,
    adherence: true,
    technique: 'IMRT',
    linac: 'Machine 4',
    sim_to_treat: 2,
    priority: 'Standard',
    referrer: 'Dr. Emily Hartman',
    diagnosis_code: 'C34.90',
  },
  {
    fractions: 18,
    site: 'Lakeside',
    physician: 'Dr Lopez',
    date: '2023-02-25',
    diagnosis: 'neuro',
    fraction_dose: 2.56,
    adherence: true,
    technique: 'Stereotactic',
    linac: 'Machine 2',
    sim_to_treat: 5,
    priority: 'Emergency',
    referrer: 'Dr. Jonathan Blake',
    diagnosis_code: 'C79.81',
  },
  {
    fractions: 23,
    site: 'Lakeside',
    physician: 'Dr Lopez',
    date: '2023-09-18',
    diagnosis: 'prostate',
    fraction_dose: 3.19,
    adherence: true,
    technique: 'VMAT',
    linac: 'Machine 3',
    sim_to_treat: 9,
    priority: 'Urgent',
    referrer: 'Dr. Clara Thompson',
    diagnosis_code: 'C71.9',
  },
  {
    fractions: 10,
    site: 'Rosebush',
    physician: 'Dr Wong',
    date: '2023-05-16',
    diagnosis: 'Unspecified',
    fraction_dose: 1.29,
    adherence: true,
    technique: 'Brachytherapy',
    linac: 'Machine 2',
    sim_to_treat: 9,
    priority: 'Emergency',
    referrer: 'Dr. Clara Thompson',
    diagnosis_code: 'C20',
  },
  {
    fractions: 24,
    site: 'Campsie',
    physician: 'Dr Adams',
    date: '2023-07-25',
    diagnosis: 'prostate',
    fraction_dose: 3.36,
    adherence: false,
    technique: 'VMAT',
    linac: 'Machine 4',
    sim_to_treat: 2,
    priority: 'Emergency',
    referrer: 'Dr. Aisha Patel',
    diagnosis_code: 'C34.90',
  },
  {
    fractions: 16,
    site: 'Rosebush',
    physician: 'Dr Wong',
    date: '2023-09-27',
    diagnosis: 'colorectal',
    fraction_dose: 1.27,
    adherence: true,
    technique: 'Brachytherapy',
    linac: 'Machine 2',
    sim_to_treat: 6,
    priority: 'Standard',
    referrer: 'Dr. Sophie Greenberg',
    diagnosis_code: 'C43.9',
  },
  {
    fractions: 17,
    site: 'Rosebush',
    physician: 'Dr Smith',
    date: '2023-01-21',
    diagnosis: 'neuro',
    fraction_dose: 1.66,
    adherence: true,
    technique: 'Unspecified',
    linac: 'Machine 2',
    sim_to_treat: 10,
    priority: 'Standard',
    referrer: 'Dr. Michael Stern',
    diagnosis_code: 'C79.81',
  },
  {
    fractions: 22,
    site: 'Lakeside',
    physician: 'Dr Adams',
    date: '2023-07-03',
    diagnosis: 'skin',
    fraction_dose: 2.49,
    adherence: true,
    technique: 'Stereotactic',
    linac: 'Machine 4',
    sim_to_treat: 3,
    priority: 'Emergency',
    referrer: 'Dr. Alex Reynolds',
    diagnosis_code: 'C43.9',
  },
  {
    fractions: 21,
    site: 'Red Hills',
    physician: 'Dr Adams',
    date: '2023-08-07',
    diagnosis: 'breast',
    fraction_dose: 2.45,
    adherence: true,
    technique: 'Conformal',
    linac: 'Machine 2',
    sim_to_treat: 5,
    priority: 'Urgent',
    referrer: 'Dr. Michael Stern',
    diagnosis_code: 'C61',
  },
  {
    fractions: 16,
    site: 'Campsie',
    physician: 'Dr Lopez',
    date: '2023-08-05',
    diagnosis: 'Unspecified',
    fraction_dose: 3.35,
    adherence: true,
    technique: 'Unspecified',
    linac: 'Machine 1',
    sim_to_treat: 2,
    priority: 'Urgent',
    referrer: 'Dr. Michael Stern',
    diagnosis_code: 'C79.81',
  },
  {
    fractions: 12,
    site: 'Lakeside',
    physician: 'Dr Adams',
    date: '2023-02-11',
    diagnosis: 'Unspecified',
    fraction_dose: 2.88,
    adherence: true,
    technique: 'VMAT',
    linac: 'Machine 2',
    sim_to_treat: 9,
    priority: 'Standard',
    referrer: 'Dr. Alex Reynolds',
    diagnosis_code: 'C34.90',
  },
  {
    fractions: 13,
    site: 'Lakeside',
    physician: 'Dr Wong',
    date: '2023-10-07',
    diagnosis: 'prostate',
    fraction_dose: 2.47,
    adherence: true,
    technique: 'Unspecified',
    linac: 'Machine 2',
    sim_to_treat: 8,
    priority: 'Standard',
    referrer: 'Dr. Michael Stern',
    diagnosis_code: 'C71.9',
  },
  {
    fractions: 20,
    site: 'Red Hills',
    physician: 'Dr Lopez',
    date: '2023-09-22',
    diagnosis: 'breast',
    fraction_dose: 3.18,
    adherence: true,
    technique: 'VMAT',
    linac: 'Machine 1',
    sim_to_treat: 6,
    priority: 'Emergency',
    referrer: 'Dr. Rachel Nguyen',
    diagnosis_code: 'C34.90',
  },
  {
    fractions: 18,
    site: 'Campsie',
    physician: 'Dr Lopez',
    date: '2023-04-13',
    diagnosis: 'skin',
    fraction_dose: 1.95,
    adherence: true,
    technique: 'Electrons',
    linac: 'Machine 3',
    sim_to_treat: 8,
    priority: 'Urgent',
    referrer: 'Dr. Aisha Patel',
    diagnosis_code: 'C61',
  },
  {
    fractions: 25,
    site: 'Campsie',
    physician: 'Dr Adams',
    date: '2023-06-11',
    diagnosis: 'skin',
    fraction_dose: 2.2,
    adherence: true,
    technique: 'IMRT',
    linac: 'Machine 3',
    sim_to_treat: 10,
    priority: 'Emergency',
    referrer: 'Dr. Sophie Greenberg',
    diagnosis_code: 'C20',
  },
  {
    fractions: 22,
    site: 'Red Hills',
    physician: 'Dr Wong',
    date: '2023-02-09',
    diagnosis: 'colorectal',
    fraction_dose: 3.16,
    adherence: true,
    technique: 'IMRT',
    linac: 'Machine 2',
    sim_to_treat: 9,
    priority: 'Standard',
    referrer: 'Dr. Victor Hernandes',
    diagnosis_code: 'C71.9',
  },
  {
    fractions: 21,
    site: 'Red Hills',
    physician: 'Dr Lopez',
    date: '2023-08-02',
    diagnosis: 'Unspecified',
    fraction_dose: 3.17,
    adherence: true,
    technique: 'IMRT',
    linac: 'Machine 1',
    sim_to_treat: 8,
    priority: 'Emergency',
    referrer: 'Dr. Victor Hernandes',
    diagnosis_code: 'C79.81',
  },
  {
    fractions: 23,
    site: 'Campsie',
    physician: 'Dr Wong',
    date: '2023-07-26',
    diagnosis: 'prostate',
    fraction_dose: 1.83,
    adherence: false,
    technique: 'Stereotactic',
    linac: 'Machine 3',
    sim_to_treat: 7,
    priority: 'Emergency',
    referrer: 'Dr. Sophie Greenberg',
    diagnosis_code: 'C20',
  },
  {
    fractions: 22,
    site: 'Rosebush',
    physician: 'Dr Adams',
    date: '2023-05-22',
    diagnosis: 'metastasis',
    fraction_dose: 2.08,
    adherence: false,
    technique: 'IMRT',
    linac: 'Machine 3',
    sim_to_treat: 9,
    priority: 'Standard',
    referrer: 'Dr. Michael Stern',
    diagnosis_code: 'C61',
  },
  {
    fractions: 24,
    site: 'Lakeside',
    physician: 'Dr Adams',
    date: '2023-04-03',
    diagnosis: 'breast',
    fraction_dose: 2.68,
    adherence: true,
    technique: 'VMAT',
    linac: 'Machine 3',
    sim_to_treat: 9,
    priority: 'Emergency',
    referrer: 'Dr. Rachel Nguyen',
    diagnosis_code: 'C71.9',
  },
  {
    fractions: 13,
    site: 'Lakeside',
    physician: 'Dr Lopez',
    date: '2023-05-25',
    diagnosis: 'breast',
    fraction_dose: 1.89,
    adherence: true,
    technique: 'Unspecified',
    linac: 'Machine 4',
    sim_to_treat: 6,
    priority: 'Emergency',
    referrer: 'Dr. Sophie Greenberg',
    diagnosis_code: 'C43.9',
  },
  {
    fractions: 21,
    site: 'Red Hills',
    physician: 'Dr Lopez',
    date: '2023-11-24',
    diagnosis: 'Unspecified',
    fraction_dose: 1.36,
    adherence: true,
    technique: 'Unspecified',
    linac: 'Machine 3',
    sim_to_treat: 10,
    priority: 'Emergency',
    referrer: 'Dr. Clara Thompson',
    diagnosis_code: 'C71.9',
  },
  {
    fractions: 12,
    site: 'Red Hills',
    physician: 'Dr Smith',
    date: '2023-06-29',
    diagnosis: 'skin',
    fraction_dose: 1.32,
    adherence: true,
    technique: 'VMAT',
    linac: 'Machine 4',
    sim_to_treat: 5,
    priority: 'Urgent',
    referrer: 'Dr. Jonathan Blake',
    diagnosis_code: 'C34.90',
  },
  {
    fractions: 17,
    site: 'Lakeside',
    physician: 'Dr Wong',
    date: '2023-04-20',
    diagnosis: 'colorectal',
    fraction_dose: 1.94,
    adherence: true,
    technique: 'Stereotactic',
    linac: 'Machine 3',
    sim_to_treat: 2,
    priority: 'Emergency',
    referrer: 'Dr. Emily Hartman',
    diagnosis_code: 'C79.81',
  },
  {
    fractions: 15,
    site: 'Rosebush',
    physician: 'Dr Lopez',
    date: '2023-06-03',
    diagnosis: 'colorectal',
    fraction_dose: 1.83,
    adherence: true,
    technique: 'Stereotactic',
    linac: 'Machine 4',
    sim_to_treat: 2,
    priority: 'Standard',
    referrer: 'Dr. Victor Hernandes',
    diagnosis_code: 'C34.90',
  },
  {
    fractions: 20,
    site: 'Lakeside',
    physician: 'Dr Smith',
    date: '2023-07-12',
    diagnosis: 'Unspecified',
    fraction_dose: 2.73,
    adherence: true,
    technique: 'Electrons',
    linac: 'Machine 4',
    sim_to_treat: 7,
    priority: 'Standard',
    referrer: 'Dr. Julian Foster',
    diagnosis_code: 'C71.9',
  },
  {
    fractions: 10,
    site: 'Red Hills',
    physician: 'Dr Adams',
    date: '2023-03-16',
    diagnosis: 'prostate',
    fraction_dose: 3.24,
    adherence: true,
    technique: 'Stereotactic',
    linac: 'Machine 2',
    sim_to_treat: 6,
    priority: 'Standard',
    referrer: 'Dr. Aisha Patel',
    diagnosis_code: 'C71.9',
  },
  {
    fractions: 12,
    site: 'Campsie',
    physician: 'Dr Smith',
    date: '2023-05-29',
    diagnosis: 'Unspecified',
    fraction_dose: 2.22,
    adherence: true,
    technique: 'Unspecified',
    linac: 'Machine 1',
    sim_to_treat: 1,
    priority: 'Urgent',
    referrer: 'Dr. Rachel Nguyen',
    diagnosis_code: 'C43.9',
  },
  {
    fractions: 14,
    site: 'Campsie',
    physician: 'Dr Wong',
    date: '2023-02-07',
    diagnosis: 'Unspecified',
    fraction_dose: 3.06,
    adherence: true,
    technique: 'Conformal',
    linac: 'Machine 2',
    sim_to_treat: 4,
    priority: 'Standard',
    referrer: 'Dr. Aisha Patel',
    diagnosis_code: 'C71.9',
  },
  {
    fractions: 10,
    site: 'Campsie',
    physician: 'Dr Lopez',
    date: '2023-04-23',
    diagnosis: 'prostate',
    fraction_dose: 1.31,
    adherence: true,
    technique: 'IMRT',
    linac: 'Machine 2',
    sim_to_treat: 2,
    priority: 'Emergency',
    referrer: 'Dr. Alex Reynolds',
    diagnosis_code: 'C71.9',
  },
  {
    fractions: 15,
    site: 'Red Hills',
    physician: 'Dr Adams',
    date: '2023-10-29',
    diagnosis: 'skin',
    fraction_dose: 2.15,
    adherence: true,
    technique: 'VMAT',
    linac: 'Machine 4',
    sim_to_treat: 1,
    priority: 'Urgent',
    referrer: 'Dr. Alex Reynolds',
    diagnosis_code: 'C34.90',
  },
  {
    fractions: 23,
    site: 'Red Hills',
    physician: 'Dr Wong',
    date: '2023-07-19',
    diagnosis: 'breast',
    fraction_dose: 2.76,
    adherence: true,
    technique: 'VMAT',
    linac: 'Machine 2',
    sim_to_treat: 1,
    priority: 'Emergency',
    referrer: 'Dr. Michael Stern',
    diagnosis_code: 'C71.9',
  },
  {
    fractions: 20,
    site: 'Lakeside',
    physician: 'Dr Wong',
    date: '2023-10-04',
    diagnosis: 'Unspecified',
    fraction_dose: 2.89,
    adherence: true,
    technique: 'VMAT',
    linac: 'Machine 2',
    sim_to_treat: 3,
    priority: 'Urgent',
    referrer: 'Dr. Victor Hernandes',
    diagnosis_code: 'C34.90',
  },
  {
    fractions: 18,
    site: 'Campsie',
    physician: 'Dr Smith',
    date: '2023-12-18',
    diagnosis: 'colorectal',
    fraction_dose: 1.42,
    adherence: true,
    technique: 'Brachytherapy',
    linac: 'Machine 3',
    sim_to_treat: 8,
    priority: 'Standard',
    referrer: 'Dr. Jonathan Blake',
    diagnosis_code: 'C34.90',
  },
  {
    fractions: 23,
    site: 'Red Hills',
    physician: 'Dr Smith',
    date: '2023-07-25',
    diagnosis: 'skin',
    fraction_dose: 2.85,
    adherence: true,
    technique: 'Stereotactic',
    linac: 'Machine 1',
    sim_to_treat: 5,
    priority: 'Urgent',
    referrer: 'Dr. Michael Stern',
    diagnosis_code: 'C34.90',
  },
  {
    fractions: 18,
    site: 'Campsie',
    physician: 'Dr Wong',
    date: '2023-04-05',
    diagnosis: 'prostate',
    fraction_dose: 1.49,
    adherence: true,
    technique: 'Unspecified',
    linac: 'Machine 1',
    sim_to_treat: 9,
    priority: 'Emergency',
    referrer: 'Dr. Michael Stern',
    diagnosis_code: 'C71.9',
  },
  {
    fractions: 18,
    site: 'Rosebush',
    physician: 'Dr Adams',
    date: '2023-05-05',
    diagnosis: 'colorectal',
    fraction_dose: 2.89,
    adherence: true,
    technique: 'Unspecified',
    linac: 'Machine 3',
    sim_to_treat: 3,
    priority: 'Standard',
    referrer: 'Dr. Emily Hartman',
    diagnosis_code: 'C71.9',
  },
  {
    fractions: 19,
    site: 'Campsie',
    physician: 'Dr Wong',
    date: '2023-10-23',
    diagnosis: 'neuro',
    fraction_dose: 1.27,
    adherence: true,
    technique: 'Unspecified',
    linac: 'Machine 4',
    sim_to_treat: 2,
    priority: 'Emergency',
    referrer: 'Dr. Emily Hartman',
    diagnosis_code: 'C43.9',
  },
  {
    fractions: 18,
    site: 'Rosebush',
    physician: 'Dr Wong',
    date: '2023-09-12',
    diagnosis: 'colorectal',
    fraction_dose: 1.5,
    adherence: true,
    technique: 'Brachytherapy',
    linac: 'Machine 1',
    sim_to_treat: 8,
    priority: 'Standard',
    referrer: 'Dr. Alex Reynolds',
    diagnosis_code: 'C61',
  },
  {
    fractions: 25,
    site: 'Rosebush',
    physician: 'Dr Lopez',
    date: '2023-01-04',
    diagnosis: 'breast',
    fraction_dose: 3.01,
    adherence: true,
    technique: 'Stereotactic',
    linac: 'Machine 2',
    sim_to_treat: 8,
    priority: 'Standard',
    referrer: 'Dr. Victor Hernandes',
    diagnosis_code: 'C71.9',
  },
  {
    fractions: 13,
    site: 'Rosebush',
    physician: 'Dr Wong',
    date: '2023-08-22',
    diagnosis: 'skin',
    fraction_dose: 3.29,
    adherence: true,
    technique: 'Stereotactic',
    linac: 'Machine 4',
    sim_to_treat: 1,
    priority: 'Emergency',
    referrer: 'Dr. Sophie Greenberg',
    diagnosis_code: 'C43.9',
  },
  {
    fractions: 24,
    site: 'Rosebush',
    physician: 'Dr Lopez',
    date: '2023-11-30',
    diagnosis: 'breast',
    fraction_dose: 1.4,
    adherence: true,
    technique: 'Electrons',
    linac: 'Machine 1',
    sim_to_treat: 5,
    priority: 'Standard',
    referrer: 'Dr. Victor Hernandes',
    diagnosis_code: 'C43.9',
  },
  {
    fractions: 22,
    site: 'Campsie',
    physician: 'Dr Adams',
    date: '2023-04-21',
    diagnosis: 'colorectal',
    fraction_dose: 2.43,
    adherence: true,
    technique: 'IMRT',
    linac: 'Machine 4',
    sim_to_treat: 10,
    priority: 'Standard',
    referrer: 'Dr. Victor Hernandes',
    diagnosis_code: 'C20',
  },
  {
    fractions: 24,
    site: 'Rosebush',
    physician: 'Dr Adams',
    date: '2023-08-31',
    diagnosis: 'breast',
    fraction_dose: 3.08,
    adherence: true,
    technique: 'VMAT',
    linac: 'Machine 1',
    sim_to_treat: 2,
    priority: 'Standard',
    referrer: 'Dr. Victor Hernandes',
    diagnosis_code: 'C43.9',
  },
  {
    fractions: 19,
    site: 'Rosebush',
    physician: 'Dr Adams',
    date: '2023-09-21',
    diagnosis: 'neuro',
    fraction_dose: 2.18,
    adherence: false,
    technique: 'Conformal',
    linac: 'Machine 1',
    sim_to_treat: 7,
    priority: 'Standard',
    referrer: 'Dr. Victor Hernandes',
    diagnosis_code: 'C61',
  },
  {
    fractions: 14,
    site: 'Red Hills',
    physician: 'Dr Lopez',
    date: '2023-09-06',
    diagnosis: 'Unspecified',
    fraction_dose: 3.35,
    adherence: true,
    technique: 'Unspecified',
    linac: 'Machine 1',
    sim_to_treat: 9,
    priority: 'Urgent',
    referrer: 'Dr. Emily Hartman',
    diagnosis_code: 'C20',
  },
  {
    fractions: 22,
    site: 'Red Hills',
    physician: 'Dr Adams',
    date: '2023-12-18',
    diagnosis: 'prostate',
    fraction_dose: 2.11,
    adherence: true,
    technique: 'Stereotactic',
    linac: 'Machine 4',
    sim_to_treat: 4,
    priority: 'Standard',
    referrer: 'Dr. Clara Thompson',
    diagnosis_code: 'C61',
  },
  {
    fractions: 21,
    site: 'Campsie',
    physician: 'Dr Adams',
    date: '2023-12-03',
    diagnosis: 'metastasis',
    fraction_dose: 1.61,
    adherence: true,
    technique: 'Brachytherapy',
    linac: 'Machine 3',
    sim_to_treat: 3,
    priority: 'Emergency',
    referrer: 'Dr. Alex Reynolds',
    diagnosis_code: 'C79.81',
  },
  {
    fractions: 23,
    site: 'Rosebush',
    physician: 'Dr Smith',
    date: '2023-08-21',
    diagnosis: 'neuro',
    fraction_dose: 1.65,
    adherence: true,
    technique: 'Unspecified',
    linac: 'Machine 2',
    sim_to_treat: 4,
    priority: 'Urgent',
    referrer: 'Dr. Victor Hernandes',
    diagnosis_code: 'C20',
  },
  {
    fractions: 13,
    site: 'Lakeside',
    physician: 'Dr Wong',
    date: '2023-08-13',
    diagnosis: 'colorectal',
    fraction_dose: 1.32,
    adherence: true,
    technique: 'VMAT',
    linac: 'Machine 4',
    sim_to_treat: 9,
    priority: 'Emergency',
    referrer: 'Dr. Julian Foster',
    diagnosis_code: 'C61',
  },
  {
    fractions: 14,
    site: 'Rosebush',
    physician: 'Dr Wong',
    date: '2023-07-19',
    diagnosis: 'Unspecified',
    fraction_dose: 3.07,
    adherence: true,
    technique: 'Conformal',
    linac: 'Machine 2',
    sim_to_treat: 3,
    priority: 'Emergency',
    referrer: 'Dr. Sophie Greenberg',
    diagnosis_code: 'C71.9',
  },
  {
    fractions: 10,
    site: 'Campsie',
    physician: 'Dr Lopez',
    date: '2023-08-27',
    diagnosis: 'neuro',
    fraction_dose: 3.09,
    adherence: true,
    technique: 'Electrons',
    linac: 'Machine 2',
    sim_to_treat: 5,
    priority: 'Urgent',
    referrer: 'Dr. Emily Hartman',
    diagnosis_code: 'C71.9',
  },
  {
    fractions: 15,
    site: 'Lakeside',
    physician: 'Dr Wong',
    date: '2023-05-18',
    diagnosis: 'breast',
    fraction_dose: 2.99,
    adherence: true,
    technique: 'IMRT',
    linac: 'Machine 3',
    sim_to_treat: 7,
    priority: 'Urgent',
    referrer: 'Dr. Julian Foster',
    diagnosis_code: 'C61',
  },
  {
    fractions: 13,
    site: 'Campsie',
    physician: 'Dr Adams',
    date: '2023-07-28',
    diagnosis: 'breast',
    fraction_dose: 1.94,
    adherence: true,
    technique: 'Brachytherapy',
    linac: 'Machine 4',
    sim_to_treat: 9,
    priority: 'Urgent',
    referrer: 'Dr. Victor Hernandes',
    diagnosis_code: 'C79.81',
  },
  {
    fractions: 21,
    site: 'Red Hills',
    physician: 'Dr Smith',
    date: '2023-03-10',
    diagnosis: 'metastasis',
    fraction_dose: 2.69,
    adherence: true,
    technique: 'Electrons',
    linac: 'Machine 4',
    sim_to_treat: 7,
    priority: 'Standard',
    referrer: 'Dr. Victor Hernandes',
    diagnosis_code: 'C34.90',
  },
  {
    fractions: 21,
    site: 'Rosebush',
    physician: 'Dr Lopez',
    date: '2023-09-18',
    diagnosis: 'metastasis',
    fraction_dose: 3.25,
    adherence: true,
    technique: 'IMRT',
    linac: 'Machine 3',
    sim_to_treat: 7,
    priority: 'Emergency',
    referrer: 'Dr. Aisha Patel',
    diagnosis_code: 'C61',
  },
  {
    fractions: 13,
    site: 'Campsie',
    physician: 'Dr Wong',
    date: '2023-02-19',
    diagnosis: 'metastasis',
    fraction_dose: 3.19,
    adherence: true,
    technique: 'Electrons',
    linac: 'Machine 4',
    sim_to_treat: 7,
    priority: 'Standard',
    referrer: 'Dr. Michael Stern',
    diagnosis_code: 'C71.9',
  },
  {
    fractions: 21,
    site: 'Lakeside',
    physician: 'Dr Smith',
    date: '2023-05-17',
    diagnosis: 'metastasis',
    fraction_dose: 1.65,
    adherence: true,
    technique: 'IMRT',
    linac: 'Machine 1',
    sim_to_treat: 6,
    priority: 'Urgent',
    referrer: 'Dr. Jonathan Blake',
    diagnosis_code: 'C71.9',
  },
  {
    fractions: 14,
    site: 'Rosebush',
    physician: 'Dr Wong',
    date: '2023-09-01',
    diagnosis: 'colorectal',
    fraction_dose: 1.32,
    adherence: true,
    technique: 'Electrons',
    linac: 'Machine 4',
    sim_to_treat: 10,
    priority: 'Standard',
    referrer: 'Dr. Aisha Patel',
    diagnosis_code: 'C20',
  },
  {
    fractions: 16,
    site: 'Campsie',
    physician: 'Dr Wong',
    date: '2023-04-12',
    diagnosis: 'breast',
    fraction_dose: 2.43,
    adherence: true,
    technique: 'VMAT',
    linac: 'Machine 2',
    sim_to_treat: 7,
    priority: 'Emergency',
    referrer: 'Dr. Victor Hernandes',
    diagnosis_code: 'C61',
  },
  {
    fractions: 15,
    site: 'Red Hills',
    physician: 'Dr Wong',
    date: '2023-10-23',
    diagnosis: 'metastasis',
    fraction_dose: 3.25,
    adherence: true,
    technique: 'Electrons',
    linac: 'Machine 4',
    sim_to_treat: 3,
    priority: 'Standard',
    referrer: 'Dr. Emily Hartman',
    diagnosis_code: 'C34.90',
  },
  {
    fractions: 18,
    site: 'Campsie',
    physician: 'Dr Wong',
    date: '2023-05-02',
    diagnosis: 'metastasis',
    fraction_dose: 2.61,
    adherence: true,
    technique: 'Electrons',
    linac: 'Machine 2',
    sim_to_treat: 6,
    priority: 'Standard',
    referrer: 'Dr. Julian Foster',
    diagnosis_code: 'C43.9',
  },
  {
    fractions: 20,
    site: 'Campsie',
    physician: 'Dr Wong',
    date: '2023-12-28',
    diagnosis: 'prostate',
    fraction_dose: 2.99,
    adherence: true,
    technique: 'VMAT',
    linac: 'Machine 2',
    sim_to_treat: 5,
    priority: 'Urgent',
    referrer: 'Dr. Jonathan Blake',
    diagnosis_code: 'C61',
  },
  {
    fractions: 15,
    site: 'Rosebush',
    physician: 'Dr Smith',
    date: '2023-08-09',
    diagnosis: 'breast',
    fraction_dose: 1.29,
    adherence: true,
    technique: 'Unspecified',
    linac: 'Machine 2',
    sim_to_treat: 7,
    priority: 'Urgent',
    referrer: 'Dr. Jonathan Blake',
    diagnosis_code: 'C43.9',
  },
  {
    fractions: 20,
    site: 'Red Hills',
    physician: 'Dr Wong',
    date: '2023-04-13',
    diagnosis: 'prostate',
    fraction_dose: 2.81,
    adherence: true,
    technique: 'Unspecified',
    linac: 'Machine 3',
    sim_to_treat: 8,
    priority: 'Emergency',
    referrer: 'Dr. Michael Stern',
    diagnosis_code: 'C20',
  },
  {
    fractions: 17,
    site: 'Red Hills',
    physician: 'Dr Wong',
    date: '2023-05-29',
    diagnosis: 'metastasis',
    fraction_dose: 1.65,
    adherence: true,
    technique: 'VMAT',
    linac: 'Machine 3',
    sim_to_treat: 9,
    priority: 'Emergency',
    referrer: 'Dr. Michael Stern',
    diagnosis_code: 'C20',
  },
  {
    fractions: 22,
    site: 'Lakeside',
    physician: 'Dr Adams',
    date: '2023-03-28',
    diagnosis: 'prostate',
    fraction_dose: 2.11,
    adherence: true,
    technique: 'VMAT',
    linac: 'Machine 4',
    sim_to_treat: 3,
    priority: 'Emergency',
    referrer: 'Dr. Jonathan Blake',
    diagnosis_code: 'C20',
  },
  {
    fractions: 17,
    site: 'Lakeside',
    physician: 'Dr Adams',
    date: '2023-07-06',
    diagnosis: 'colorectal',
    fraction_dose: 1.41,
    adherence: true,
    technique: 'Stereotactic',
    linac: 'Machine 4',
    sim_to_treat: 1,
    priority: 'Urgent',
    referrer: 'Dr. Jonathan Blake',
    diagnosis_code: 'C61',
  },
  {
    fractions: 18,
    site: 'Campsie',
    physician: 'Dr Wong',
    date: '2023-12-13',
    diagnosis: 'colorectal',
    fraction_dose: 2.8,
    adherence: true,
    technique: 'Stereotactic',
    linac: 'Machine 3',
    sim_to_treat: 10,
    priority: 'Emergency',
    referrer: 'Dr. Jonathan Blake',
    diagnosis_code: 'C20',
  },
  {
    fractions: 19,
    site: 'Campsie',
    physician: 'Dr Lopez',
    date: '2023-08-22',
    diagnosis: 'Unspecified',
    fraction_dose: 2.78,
    adherence: true,
    technique: 'Electrons',
    linac: 'Machine 4',
    sim_to_treat: 9,
    priority: 'Emergency',
    referrer: 'Dr. Michael Stern',
    diagnosis_code: 'C61',
  },
  {
    fractions: 10,
    site: 'Red Hills',
    physician: 'Dr Lopez',
    date: '2023-05-27',
    diagnosis: 'Unspecified',
    fraction_dose: 3.29,
    adherence: false,
    technique: 'Electrons',
    linac: 'Machine 2',
    sim_to_treat: 5,
    priority: 'Emergency',
    referrer: 'Dr. Aisha Patel',
    diagnosis_code: 'C61',
  },
  {
    fractions: 18,
    site: 'Lakeside',
    physician: 'Dr Wong',
    date: '2023-06-29',
    diagnosis: 'neuro',
    fraction_dose: 1.63,
    adherence: true,
    technique: 'Brachytherapy',
    linac: 'Machine 1',
    sim_to_treat: 4,
    priority: 'Emergency',
    referrer: 'Dr. Michael Stern',
    diagnosis_code: 'C34.90',
  },
  {
    fractions: 22,
    site: 'Lakeside',
    physician: 'Dr Smith',
    date: '2023-04-08',
    diagnosis: 'Unspecified',
    fraction_dose: 1.7,
    adherence: true,
    technique: 'Unspecified',
    linac: 'Machine 4',
    sim_to_treat: 10,
    priority: 'Standard',
    referrer: 'Dr. Alex Reynolds',
    diagnosis_code: 'C43.9',
  },
  {
    fractions: 22,
    site: 'Campsie',
    physician: 'Dr Wong',
    date: '2023-07-14',
    diagnosis: 'skin',
    fraction_dose: 2.89,
    adherence: true,
    technique: 'Unspecified',
    linac: 'Machine 2',
    sim_to_treat: 1,
    priority: 'Urgent',
    referrer: 'Dr. Clara Thompson',
    diagnosis_code: 'C61',
  },
  {
    fractions: 22,
    site: 'Rosebush',
    physician: 'Dr Wong',
    date: '2023-03-17',
    diagnosis: 'skin',
    fraction_dose: 1.74,
    adherence: true,
    technique: 'IMRT',
    linac: 'Machine 3',
    sim_to_treat: 5,
    priority: 'Emergency',
    referrer: 'Dr. Sophie Greenberg',
    diagnosis_code: 'C34.90',
  },
  {
    fractions: 14,
    site: 'Rosebush',
    physician: 'Dr Wong',
    date: '2023-01-16',
    diagnosis: 'skin',
    fraction_dose: 2.72,
    adherence: true,
    technique: 'Electrons',
    linac: 'Machine 4',
    sim_to_treat: 4,
    priority: 'Urgent',
    referrer: 'Dr. Michael Stern',
    diagnosis_code: 'C34.90',
  },
  {
    fractions: 15,
    site: 'Campsie',
    physician: 'Dr Smith',
    date: '2023-10-11',
    diagnosis: 'prostate',
    fraction_dose: 1.26,
    adherence: true,
    technique: 'Brachytherapy',
    linac: 'Machine 2',
    sim_to_treat: 3,
    priority: 'Standard',
    referrer: 'Dr. Sophie Greenberg',
    diagnosis_code: 'C79.81',
  },
  {
    fractions: 11,
    site: 'Campsie',
    physician: 'Dr Smith',
    date: '2023-05-09',
    diagnosis: 'Unspecified',
    fraction_dose: 3.22,
    adherence: true,
    technique: 'IMRT',
    linac: 'Machine 3',
    sim_to_treat: 10,
    priority: 'Emergency',
    referrer: 'Dr. Aisha Patel',
    diagnosis_code: 'C43.9',
  },
  {
    fractions: 19,
    site: 'Lakeside',
    physician: 'Dr Adams',
    date: '2023-06-11',
    diagnosis: 'metastasis',
    fraction_dose: 1.65,
    adherence: true,
    technique: 'IMRT',
    linac: 'Machine 2',
    sim_to_treat: 1,
    priority: 'Emergency',
    referrer: 'Dr. Aisha Patel',
    diagnosis_code: 'C20',
  },
  {
    fractions: 22,
    site: 'Red Hills',
    physician: 'Dr Smith',
    date: '2023-09-14',
    diagnosis: 'neuro',
    fraction_dose: 2.06,
    adherence: true,
    technique: 'Conformal',
    linac: 'Machine 4',
    sim_to_treat: 7,
    priority: 'Urgent',
    referrer: 'Dr. Rachel Nguyen',
    diagnosis_code: 'C20',
  },
  {
    fractions: 12,
    site: 'Red Hills',
    physician: 'Dr Smith',
    date: '2023-05-04',
    diagnosis: 'metastasis',
    fraction_dose: 1.92,
    adherence: true,
    technique: 'Stereotactic',
    linac: 'Machine 3',
    sim_to_treat: 10,
    priority: 'Emergency',
    referrer: 'Dr. Victor Hernandes',
    diagnosis_code: 'C79.81',
  },
  {
    fractions: 19,
    site: 'Campsie',
    physician: 'Dr Adams',
    date: '2023-04-29',
    diagnosis: 'prostate',
    fraction_dose: 2.86,
    adherence: true,
    technique: 'VMAT',
    linac: 'Machine 2',
    sim_to_treat: 10,
    priority: 'Urgent',
    referrer: 'Dr. Sophie Greenberg',
    diagnosis_code: 'C20',
  },
  {
    fractions: 21,
    site: 'Lakeside',
    physician: 'Dr Wong',
    date: '2023-09-22',
    diagnosis: 'Unspecified',
    fraction_dose: 1.7,
    adherence: false,
    technique: 'IMRT',
    linac: 'Machine 2',
    sim_to_treat: 10,
    priority: 'Emergency',
    referrer: 'Dr. Clara Thompson',
    diagnosis_code: 'C71.9',
  },
  {
    fractions: 14,
    site: 'Lakeside',
    physician: 'Dr Wong',
    date: '2023-10-18',
    diagnosis: 'prostate',
    fraction_dose: 2.72,
    adherence: true,
    technique: 'Stereotactic',
    linac: 'Machine 1',
    sim_to_treat: 4,
    priority: 'Standard',
    referrer: 'Dr. Emily Hartman',
    diagnosis_code: 'C43.9',
  },
  {
    fractions: 19,
    site: 'Red Hills',
    physician: 'Dr Adams',
    date: '2023-05-28',
    diagnosis: 'metastasis',
    fraction_dose: 3.06,
    adherence: true,
    technique: 'Unspecified',
    linac: 'Machine 1',
    sim_to_treat: 10,
    priority: 'Emergency',
    referrer: 'Dr. Sophie Greenberg',
    diagnosis_code: 'C71.9',
  },
  {
    fractions: 25,
    site: 'Rosebush',
    physician: 'Dr Wong',
    date: '2023-08-07',
    diagnosis: 'skin',
    fraction_dose: 1.27,
    adherence: false,
    technique: 'Electrons',
    linac: 'Machine 2',
    sim_to_treat: 9,
    priority: 'Standard',
    referrer: 'Dr. Aisha Patel',
    diagnosis_code: 'C20',
  },
  {
    fractions: 16,
    site: 'Red Hills',
    physician: 'Dr Lopez',
    date: '2023-06-14',
    diagnosis: 'metastasis',
    fraction_dose: 3.03,
    adherence: false,
    technique: 'Brachytherapy',
    linac: 'Machine 1',
    sim_to_treat: 5,
    priority: 'Standard',
    referrer: 'Dr. Aisha Patel',
    diagnosis_code: 'C43.9',
  },
  {
    fractions: 12,
    site: 'Lakeside',
    physician: 'Dr Smith',
    date: '2023-12-30',
    diagnosis: 'breast',
    fraction_dose: 3.3,
    adherence: true,
    technique: 'Stereotactic',
    linac: 'Machine 3',
    sim_to_treat: 1,
    priority: 'Standard',
    referrer: 'Dr. Rachel Nguyen',
    diagnosis_code: 'C20',
  },
  {
    fractions: 11,
    site: 'Lakeside',
    physician: 'Dr Smith',
    date: '2023-10-28',
    diagnosis: 'metastasis',
    fraction_dose: 2.37,
    adherence: true,
    technique: 'Stereotactic',
    linac: 'Machine 3',
    sim_to_treat: 5,
    priority: 'Standard',
    referrer: 'Dr. Alex Reynolds',
    diagnosis_code: 'C79.81',
  },
  {
    fractions: 22,
    site: 'Red Hills',
    physician: 'Dr Adams',
    date: '2023-01-14',
    diagnosis: 'neuro',
    fraction_dose: 2.42,
    adherence: true,
    technique: 'Stereotactic',
    linac: 'Machine 2',
    sim_to_treat: 3,
    priority: 'Urgent',
    referrer: 'Dr. Aisha Patel',
    diagnosis_code: 'C71.9',
  },
  {
    fractions: 19,
    site: 'Red Hills',
    physician: 'Dr Smith',
    date: '2023-09-05',
    diagnosis: 'neuro',
    fraction_dose: 2.13,
    adherence: false,
    technique: 'Unspecified',
    linac: 'Machine 1',
    sim_to_treat: 7,
    priority: 'Emergency',
    referrer: 'Dr. Sophie Greenberg',
    diagnosis_code: 'C79.81',
  },
  {
    fractions: 15,
    site: 'Lakeside',
    physician: 'Dr Smith',
    date: '2023-02-23',
    diagnosis: 'colorectal',
    fraction_dose: 1.59,
    adherence: true,
    technique: 'Stereotactic',
    linac: 'Machine 3',
    sim_to_treat: 4,
    priority: 'Emergency',
    referrer: 'Dr. Clara Thompson',
    diagnosis_code: 'C43.9',
  },
  {
    fractions: 20,
    site: 'Campsie',
    physician: 'Dr Adams',
    date: '2023-10-26',
    diagnosis: 'skin',
    fraction_dose: 2.69,
    adherence: true,
    technique: 'Brachytherapy',
    linac: 'Machine 1',
    sim_to_treat: 8,
    priority: 'Urgent',
    referrer: 'Dr. Rachel Nguyen',
    diagnosis_code: 'C20',
  },
  {
    fractions: 19,
    site: 'Lakeside',
    physician: 'Dr Lopez',
    date: '2023-07-18',
    diagnosis: 'metastasis',
    fraction_dose: 1.39,
    adherence: true,
    technique: 'Brachytherapy',
    linac: 'Machine 4',
    sim_to_treat: 2,
    priority: 'Emergency',
    referrer: 'Dr. Emily Hartman',
    diagnosis_code: 'C20',
  },
  {
    fractions: 19,
    site: 'Red Hills',
    physician: 'Dr Smith',
    date: '2023-09-27',
    diagnosis: 'colorectal',
    fraction_dose: 2.31,
    adherence: true,
    technique: 'Unspecified',
    linac: 'Machine 4',
    sim_to_treat: 7,
    priority: 'Emergency',
    referrer: 'Dr. Rachel Nguyen',
    diagnosis_code: 'C61',
  },
  {
    fractions: 13,
    site: 'Rosebush',
    physician: 'Dr Adams',
    date: '2023-09-20',
    diagnosis: 'breast',
    fraction_dose: 2.26,
    adherence: true,
    technique: 'Stereotactic',
    linac: 'Machine 2',
    sim_to_treat: 9,
    priority: 'Emergency',
    referrer: 'Dr. Emily Hartman',
    diagnosis_code: 'C61',
  },
  {
    fractions: 16,
    site: 'Lakeside',
    physician: 'Dr Wong',
    date: '2023-10-21',
    diagnosis: 'colorectal',
    fraction_dose: 1.7,
    adherence: true,
    technique: 'Stereotactic',
    linac: 'Machine 4',
    sim_to_treat: 2,
    priority: 'Urgent',
    referrer: 'Dr. Michael Stern',
    diagnosis_code: 'C34.90',
  },
  {
    fractions: 15,
    site: 'Campsie',
    physician: 'Dr Adams',
    date: '2023-07-07',
    diagnosis: 'prostate',
    fraction_dose: 2.04,
    adherence: true,
    technique: 'IMRT',
    linac: 'Machine 1',
    sim_to_treat: 1,
    priority: 'Urgent',
    referrer: 'Dr. Rachel Nguyen',
    diagnosis_code: 'C34.90',
  },
  {
    fractions: 23,
    site: 'Campsie',
    physician: 'Dr Wong',
    date: '2023-09-11',
    diagnosis: 'prostate',
    fraction_dose: 2.62,
    adherence: true,
    technique: 'Electrons',
    linac: 'Machine 1',
    sim_to_treat: 1,
    priority: 'Standard',
    referrer: 'Dr. Jonathan Blake',
    diagnosis_code: 'C20',
  },
  {
    fractions: 21,
    site: 'Lakeside',
    physician: 'Dr Smith',
    date: '2023-12-16',
    diagnosis: 'neuro',
    fraction_dose: 1.56,
    adherence: true,
    technique: 'Electrons',
    linac: 'Machine 2',
    sim_to_treat: 5,
    priority: 'Emergency',
    referrer: 'Dr. Alex Reynolds',
    diagnosis_code: 'C61',
  },
  {
    fractions: 14,
    site: 'Rosebush',
    physician: 'Dr Wong',
    date: '2023-09-23',
    diagnosis: 'Unspecified',
    fraction_dose: 3.3,
    adherence: true,
    technique: 'IMRT',
    linac: 'Machine 1',
    sim_to_treat: 7,
    priority: 'Emergency',
    referrer: 'Dr. Julian Foster',
    diagnosis_code: 'C34.90',
  },
  {
    fractions: 16,
    site: 'Rosebush',
    physician: 'Dr Wong',
    date: '2023-03-04',
    diagnosis: 'metastasis',
    fraction_dose: 2.09,
    adherence: true,
    technique: 'Electrons',
    linac: 'Machine 4',
    sim_to_treat: 1,
    priority: 'Urgent',
    referrer: 'Dr. Emily Hartman',
    diagnosis_code: 'C20',
  },
  {
    fractions: 18,
    site: 'Campsie',
    physician: 'Dr Smith',
    date: '2023-07-02',
    diagnosis: 'colorectal',
    fraction_dose: 1.8,
    adherence: true,
    technique: 'Unspecified',
    linac: 'Machine 1',
    sim_to_treat: 4,
    priority: 'Emergency',
    referrer: 'Dr. Jonathan Blake',
    diagnosis_code: 'C61',
  },
  {
    fractions: 19,
    site: 'Rosebush',
    physician: 'Dr Smith',
    date: '2023-06-04',
    diagnosis: 'prostate',
    fraction_dose: 1.23,
    adherence: true,
    technique: 'Electrons',
    linac: 'Machine 3',
    sim_to_treat: 9,
    priority: 'Urgent',
    referrer: 'Dr. Michael Stern',
    diagnosis_code: 'C34.90',
  },
  {
    fractions: 17,
    site: 'Red Hills',
    physician: 'Dr Lopez',
    date: '2023-12-28',
    diagnosis: 'colorectal',
    fraction_dose: 1.4,
    adherence: true,
    technique: 'Electrons',
    linac: 'Machine 4',
    sim_to_treat: 3,
    priority: 'Emergency',
    referrer: 'Dr. Rachel Nguyen',
    diagnosis_code: 'C34.90',
  },
  {
    fractions: 23,
    site: 'Campsie',
    physician: 'Dr Lopez',
    date: '2023-06-19',
    diagnosis: 'Unspecified',
    fraction_dose: 1.73,
    adherence: true,
    technique: 'IMRT',
    linac: 'Machine 3',
    sim_to_treat: 5,
    priority: 'Emergency',
    referrer: 'Dr. Julian Foster',
    diagnosis_code: 'C71.9',
  },
  {
    fractions: 14,
    site: 'Lakeside',
    physician: 'Dr Smith',
    date: '2023-08-21',
    diagnosis: 'neuro',
    fraction_dose: 1.42,
    adherence: true,
    technique: 'Stereotactic',
    linac: 'Machine 2',
    sim_to_treat: 2,
    priority: 'Standard',
    referrer: 'Dr. Victor Hernandes',
    diagnosis_code: 'C79.81',
  },
  {
    fractions: 12,
    site: 'Rosebush',
    physician: 'Dr Lopez',
    date: '2023-04-12',
    diagnosis: 'prostate',
    fraction_dose: 1.3,
    adherence: true,
    technique: 'Conformal',
    linac: 'Machine 3',
    sim_to_treat: 1,
    priority: 'Emergency',
    referrer: 'Dr. Sophie Greenberg',
    diagnosis_code: 'C61',
  },
  {
    fractions: 20,
    site: 'Rosebush',
    physician: 'Dr Smith',
    date: '2023-10-05',
    diagnosis: 'prostate',
    fraction_dose: 2.77,
    adherence: true,
    technique: 'IMRT',
    linac: 'Machine 4',
    sim_to_treat: 3,
    priority: 'Emergency',
    referrer: 'Dr. Aisha Patel',
    diagnosis_code: 'C79.81',
  },
  {
    fractions: 14,
    site: 'Rosebush',
    physician: 'Dr Wong',
    date: '2023-04-22',
    diagnosis: 'colorectal',
    fraction_dose: 3.19,
    adherence: true,
    technique: 'Brachytherapy',
    linac: 'Machine 2',
    sim_to_treat: 3,
    priority: 'Emergency',
    referrer: 'Dr. Aisha Patel',
    diagnosis_code: 'C79.81',
  },
  {
    fractions: 11,
    site: 'Campsie',
    physician: 'Dr Smith',
    date: '2023-08-26',
    diagnosis: 'metastasis',
    fraction_dose: 3.36,
    adherence: true,
    technique: 'IMRT',
    linac: 'Machine 3',
    sim_to_treat: 2,
    priority: 'Urgent',
    referrer: 'Dr. Clara Thompson',
    diagnosis_code: 'C20',
  },
  {
    fractions: 17,
    site: 'Red Hills',
    physician: 'Dr Wong',
    date: '2023-04-07',
    diagnosis: 'Unspecified',
    fraction_dose: 2.01,
    adherence: true,
    technique: 'VMAT',
    linac: 'Machine 2',
    sim_to_treat: 8,
    priority: 'Standard',
    referrer: 'Dr. Julian Foster',
    diagnosis_code: 'C79.81',
  },
  {
    fractions: 25,
    site: 'Rosebush',
    physician: 'Dr Wong',
    date: '2023-11-27',
    diagnosis: 'neuro',
    fraction_dose: 3.38,
    adherence: true,
    technique: 'VMAT',
    linac: 'Machine 3',
    sim_to_treat: 1,
    priority: 'Standard',
    referrer: 'Dr. Aisha Patel',
    diagnosis_code: 'C71.9',
  },
  {
    fractions: 22,
    site: 'Campsie',
    physician: 'Dr Wong',
    date: '2023-03-18',
    diagnosis: 'skin',
    fraction_dose: 2.72,
    adherence: true,
    technique: 'Stereotactic',
    linac: 'Machine 2',
    sim_to_treat: 8,
    priority: 'Standard',
    referrer: 'Dr. Jonathan Blake',
    diagnosis_code: 'C79.81',
  },
  {
    fractions: 24,
    site: 'Campsie',
    physician: 'Dr Lopez',
    date: '2023-09-29',
    diagnosis: 'colorectal',
    fraction_dose: 2.07,
    adherence: true,
    technique: 'IMRT',
    linac: 'Machine 1',
    sim_to_treat: 10,
    priority: 'Emergency',
    referrer: 'Dr. Emily Hartman',
    diagnosis_code: 'C79.81',
  },
  {
    fractions: 14,
    site: 'Campsie',
    physician: 'Dr Lopez',
    date: '2023-04-03',
    diagnosis: 'Unspecified',
    fraction_dose: 1.35,
    adherence: true,
    technique: 'Electrons',
    linac: 'Machine 4',
    sim_to_treat: 1,
    priority: 'Urgent',
    referrer: 'Dr. Julian Foster',
    diagnosis_code: 'C61',
  },
  {
    fractions: 22,
    site: 'Red Hills',
    physician: 'Dr Lopez',
    date: '2023-08-15',
    diagnosis: 'breast',
    fraction_dose: 2.01,
    adherence: true,
    technique: 'Conformal',
    linac: 'Machine 3',
    sim_to_treat: 6,
    priority: 'Urgent',
    referrer: 'Dr. Aisha Patel',
    diagnosis_code: 'C34.90',
  },
  {
    fractions: 11,
    site: 'Campsie',
    physician: 'Dr Adams',
    date: '2023-01-19',
    diagnosis: 'skin',
    fraction_dose: 1.91,
    adherence: true,
    technique: 'IMRT',
    linac: 'Machine 2',
    sim_to_treat: 2,
    priority: 'Emergency',
    referrer: 'Dr. Emily Hartman',
    diagnosis_code: 'C61',
  },
  {
    fractions: 20,
    site: 'Campsie',
    physician: 'Dr Smith',
    date: '2023-06-13',
    diagnosis: 'colorectal',
    fraction_dose: 1.88,
    adherence: true,
    technique: 'IMRT',
    linac: 'Machine 4',
    sim_to_treat: 6,
    priority: 'Standard',
    referrer: 'Dr. Jonathan Blake',
    diagnosis_code: 'C79.81',
  },
  {
    fractions: 15,
    site: 'Campsie',
    physician: 'Dr Adams',
    date: '2023-06-09',
    diagnosis: 'prostate',
    fraction_dose: 2.92,
    adherence: false,
    technique: 'Unspecified',
    linac: 'Machine 2',
    sim_to_treat: 7,
    priority: 'Urgent',
    referrer: 'Dr. Rachel Nguyen',
    diagnosis_code: 'C34.90',
  },
  {
    fractions: 20,
    site: 'Red Hills',
    physician: 'Dr Wong',
    date: '2023-09-22',
    diagnosis: 'metastasis',
    fraction_dose: 1.82,
    adherence: true,
    technique: 'Brachytherapy',
    linac: 'Machine 2',
    sim_to_treat: 6,
    priority: 'Standard',
    referrer: 'Dr. Julian Foster',
    diagnosis_code: 'C34.90',
  },
  {
    fractions: 15,
    site: 'Lakeside',
    physician: 'Dr Smith',
    date: '2023-01-06',
    diagnosis: 'colorectal',
    fraction_dose: 2.57,
    adherence: true,
    technique: 'Brachytherapy',
    linac: 'Machine 2',
    sim_to_treat: 4,
    priority: 'Standard',
    referrer: 'Dr. Emily Hartman',
    diagnosis_code: 'C79.81',
  },
  {
    fractions: 14,
    site: 'Lakeside',
    physician: 'Dr Smith',
    date: '2023-01-02',
    diagnosis: 'breast',
    fraction_dose: 3.01,
    adherence: true,
    technique: 'IMRT',
    linac: 'Machine 3',
    sim_to_treat: 2,
    priority: 'Standard',
    referrer: 'Dr. Alex Reynolds',
    diagnosis_code: 'C79.81',
  },
  {
    fractions: 17,
    site: 'Campsie',
    physician: 'Dr Lopez',
    date: '2023-07-23',
    diagnosis: 'neuro',
    fraction_dose: 2.95,
    adherence: true,
    technique: 'IMRT',
    linac: 'Machine 4',
    sim_to_treat: 6,
    priority: 'Standard',
    referrer: 'Dr. Aisha Patel',
    diagnosis_code: 'C20',
  },
  {
    fractions: 11,
    site: 'Red Hills',
    physician: 'Dr Wong',
    date: '2023-01-21',
    diagnosis: 'prostate',
    fraction_dose: 2.19,
    adherence: true,
    technique: 'Conformal',
    linac: 'Machine 2',
    sim_to_treat: 4,
    priority: 'Urgent',
    referrer: 'Dr. Alex Reynolds',
    diagnosis_code: 'C61',
  },
  {
    fractions: 10,
    site: 'Rosebush',
    physician: 'Dr Wong',
    date: '2023-02-12',
    diagnosis: 'skin',
    fraction_dose: 3.19,
    adherence: true,
    technique: 'Unspecified',
    linac: 'Machine 2',
    sim_to_treat: 2,
    priority: 'Emergency',
    referrer: 'Dr. Victor Hernandes',
    diagnosis_code: 'C71.9',
  },
  {
    fractions: 23,
    site: 'Red Hills',
    physician: 'Dr Smith',
    date: '2023-10-19',
    diagnosis: 'colorectal',
    fraction_dose: 1.21,
    adherence: true,
    technique: 'Electrons',
    linac: 'Machine 1',
    sim_to_treat: 10,
    priority: 'Emergency',
    referrer: 'Dr. Michael Stern',
    diagnosis_code: 'C79.81',
  },
  {
    fractions: 24,
    site: 'Lakeside',
    physician: 'Dr Smith',
    date: '2023-12-17',
    diagnosis: 'prostate',
    fraction_dose: 1.22,
    adherence: true,
    technique: 'Conformal',
    linac: 'Machine 4',
    sim_to_treat: 8,
    priority: 'Standard',
    referrer: 'Dr. Sophie Greenberg',
    diagnosis_code: 'C20',
  },
  {
    fractions: 25,
    site: 'Red Hills',
    physician: 'Dr Adams',
    date: '2023-07-23',
    diagnosis: 'metastasis',
    fraction_dose: 1.91,
    adherence: true,
    technique: 'IMRT',
    linac: 'Machine 4',
    sim_to_treat: 4,
    priority: 'Urgent',
    referrer: 'Dr. Victor Hernandes',
    diagnosis_code: 'C34.90',
  },
  {
    fractions: 14,
    site: 'Red Hills',
    physician: 'Dr Smith',
    date: '2023-03-28',
    diagnosis: 'skin',
    fraction_dose: 2.47,
    adherence: true,
    technique: 'IMRT',
    linac: 'Machine 2',
    sim_to_treat: 2,
    priority: 'Emergency',
    referrer: 'Dr. Jonathan Blake',
    diagnosis_code: 'C43.9',
  },
  {
    fractions: 23,
    site: 'Lakeside',
    physician: 'Dr Wong',
    date: '2023-12-24',
    diagnosis: 'metastasis',
    fraction_dose: 2.73,
    adherence: true,
    technique: 'IMRT',
    linac: 'Machine 3',
    sim_to_treat: 2,
    priority: 'Standard',
    referrer: 'Dr. Victor Hernandes',
    diagnosis_code: 'C61',
  },
  {
    fractions: 11,
    site: 'Rosebush',
    physician: 'Dr Smith',
    date: '2023-01-08',
    diagnosis: 'metastasis',
    fraction_dose: 1.35,
    adherence: true,
    technique: 'Stereotactic',
    linac: 'Machine 2',
    sim_to_treat: 9,
    priority: 'Standard',
    referrer: 'Dr. Victor Hernandes',
    diagnosis_code: 'C34.90',
  },
  {
    fractions: 11,
    site: 'Rosebush',
    physician: 'Dr Lopez',
    date: '2023-06-08',
    diagnosis: 'prostate',
    fraction_dose: 2.22,
    adherence: true,
    technique: 'Brachytherapy',
    linac: 'Machine 2',
    sim_to_treat: 6,
    priority: 'Urgent',
    referrer: 'Dr. Alex Reynolds',
    diagnosis_code: 'C61',
  },
  {
    fractions: 16,
    site: 'Lakeside',
    physician: 'Dr Smith',
    date: '2023-07-16',
    diagnosis: 'metastasis',
    fraction_dose: 1.8,
    adherence: true,
    technique: 'VMAT',
    linac: 'Machine 2',
    sim_to_treat: 8,
    priority: 'Emergency',
    referrer: 'Dr. Sophie Greenberg',
    diagnosis_code: 'C61',
  },
  {
    fractions: 16,
    site: 'Red Hills',
    physician: 'Dr Adams',
    date: '2023-02-19',
    diagnosis: 'colorectal',
    fraction_dose: 2.49,
    adherence: true,
    technique: 'Conformal',
    linac: 'Machine 3',
    sim_to_treat: 7,
    priority: 'Emergency',
    referrer: 'Dr. Sophie Greenberg',
    diagnosis_code: 'C34.90',
  },
  {
    fractions: 19,
    site: 'Campsie',
    physician: 'Dr Lopez',
    date: '2023-08-09',
    diagnosis: 'breast',
    fraction_dose: 1.9,
    adherence: true,
    technique: 'Brachytherapy',
    linac: 'Machine 3',
    sim_to_treat: 7,
    priority: 'Urgent',
    referrer: 'Dr. Alex Reynolds',
    diagnosis_code: 'C34.90',
  },
  {
    fractions: 17,
    site: 'Red Hills',
    physician: 'Dr Adams',
    date: '2023-08-22',
    diagnosis: 'colorectal',
    fraction_dose: 1.28,
    adherence: true,
    technique: 'VMAT',
    linac: 'Machine 3',
    sim_to_treat: 5,
    priority: 'Urgent',
    referrer: 'Dr. Clara Thompson',
    diagnosis_code: 'C20',
  },
  {
    fractions: 17,
    site: 'Lakeside',
    physician: 'Dr Smith',
    date: '2023-09-05',
    diagnosis: 'breast',
    fraction_dose: 1.91,
    adherence: true,
    technique: 'Brachytherapy',
    linac: 'Machine 1',
    sim_to_treat: 2,
    priority: 'Standard',
    referrer: 'Dr. Jonathan Blake',
    diagnosis_code: 'C71.9',
  },
  {
    fractions: 19,
    site: 'Campsie',
    physician: 'Dr Adams',
    date: '2023-09-04',
    diagnosis: 'colorectal',
    fraction_dose: 3.32,
    adherence: true,
    technique: 'VMAT',
    linac: 'Machine 4',
    sim_to_treat: 1,
    priority: 'Urgent',
    referrer: 'Dr. Emily Hartman',
    diagnosis_code: 'C20',
  },
  {
    fractions: 24,
    site: 'Rosebush',
    physician: 'Dr Lopez',
    date: '2023-11-30',
    diagnosis: 'neuro',
    fraction_dose: 1.42,
    adherence: true,
    technique: 'Brachytherapy',
    linac: 'Machine 2',
    sim_to_treat: 6,
    priority: 'Standard',
    referrer: 'Dr. Sophie Greenberg',
    diagnosis_code: 'C20',
  },
  {
    fractions: 22,
    site: 'Red Hills',
    physician: 'Dr Smith',
    date: '2023-05-21',
    diagnosis: 'prostate',
    fraction_dose: 2.23,
    adherence: true,
    technique: 'IMRT',
    linac: 'Machine 1',
    sim_to_treat: 7,
    priority: 'Emergency',
    referrer: 'Dr. Victor Hernandes',
    diagnosis_code: 'C79.81',
  },
  {
    fractions: 15,
    site: 'Lakeside',
    physician: 'Dr Adams',
    date: '2023-06-11',
    diagnosis: 'breast',
    fraction_dose: 2.67,
    adherence: true,
    technique: 'Unspecified',
    linac: 'Machine 2',
    sim_to_treat: 5,
    priority: 'Urgent',
    referrer: 'Dr. Aisha Patel',
    diagnosis_code: 'C71.9',
  },
  {
    fractions: 12,
    site: 'Rosebush',
    physician: 'Dr Lopez',
    date: '2023-12-24',
    diagnosis: 'prostate',
    fraction_dose: 1.54,
    adherence: true,
    technique: 'Electrons',
    linac: 'Machine 2',
    sim_to_treat: 9,
    priority: 'Emergency',
    referrer: 'Dr. Victor Hernandes',
    diagnosis_code: 'C71.9',
  },
  {
    fractions: 22,
    site: 'Lakeside',
    physician: 'Dr Lopez',
    date: '2023-01-08',
    diagnosis: 'skin',
    fraction_dose: 3.19,
    adherence: true,
    technique: 'Unspecified',
    linac: 'Machine 1',
    sim_to_treat: 2,
    priority: 'Urgent',
    referrer: 'Dr. Aisha Patel',
    diagnosis_code: 'C71.9',
  },
  {
    fractions: 11,
    site: 'Rosebush',
    physician: 'Dr Wong',
    date: '2023-01-12',
    diagnosis: 'prostate',
    fraction_dose: 1.31,
    adherence: true,
    technique: 'Unspecified',
    linac: 'Machine 2',
    sim_to_treat: 7,
    priority: 'Urgent',
    referrer: 'Dr. Aisha Patel',
    diagnosis_code: 'C43.9',
  },
  {
    fractions: 23,
    site: 'Rosebush',
    physician: 'Dr Lopez',
    date: '2023-10-26',
    diagnosis: 'neuro',
    fraction_dose: 1.85,
    adherence: false,
    technique: 'Unspecified',
    linac: 'Machine 2',
    sim_to_treat: 5,
    priority: 'Emergency',
    referrer: 'Dr. Alex Reynolds',
    diagnosis_code: 'C71.9',
  },
  {
    fractions: 10,
    site: 'Lakeside',
    physician: 'Dr Lopez',
    date: '2023-09-06',
    diagnosis: 'Unspecified',
    fraction_dose: 3.32,
    adherence: true,
    technique: 'Electrons',
    linac: 'Machine 1',
    sim_to_treat: 4,
    priority: 'Emergency',
    referrer: 'Dr. Aisha Patel',
    diagnosis_code: 'C61',
  },
  {
    fractions: 12,
    site: 'Lakeside',
    physician: 'Dr Lopez',
    date: '2023-07-23',
    diagnosis: 'breast',
    fraction_dose: 1.98,
    adherence: true,
    technique: 'Unspecified',
    linac: 'Machine 4',
    sim_to_treat: 7,
    priority: 'Emergency',
    referrer: 'Dr. Victor Hernandes',
    diagnosis_code: 'C43.9',
  },
  {
    fractions: 25,
    site: 'Campsie',
    physician: 'Dr Wong',
    date: '2023-09-14',
    diagnosis: 'prostate',
    fraction_dose: 1.26,
    adherence: true,
    technique: 'Brachytherapy',
    linac: 'Machine 1',
    sim_to_treat: 5,
    priority: 'Emergency',
    referrer: 'Dr. Jonathan Blake',
    diagnosis_code: 'C20',
  },
  {
    fractions: 20,
    site: 'Lakeside',
    physician: 'Dr Wong',
    date: '2023-03-18',
    diagnosis: 'colorectal',
    fraction_dose: 1.66,
    adherence: true,
    technique: 'VMAT',
    linac: 'Machine 4',
    sim_to_treat: 4,
    priority: 'Standard',
    referrer: 'Dr. Clara Thompson',
    diagnosis_code: 'C79.81',
  },
  {
    fractions: 12,
    site: 'Campsie',
    physician: 'Dr Lopez',
    date: '2023-01-24',
    diagnosis: 'breast',
    fraction_dose: 2.51,
    adherence: true,
    technique: 'Electrons',
    linac: 'Machine 1',
    sim_to_treat: 4,
    priority: 'Standard',
    referrer: 'Dr. Emily Hartman',
    diagnosis_code: 'C79.81',
  },
  {
    fractions: 11,
    site: 'Lakeside',
    physician: 'Dr Wong',
    date: '2023-08-30',
    diagnosis: 'metastasis',
    fraction_dose: 1.5,
    adherence: true,
    technique: 'Unspecified',
    linac: 'Machine 3',
    sim_to_treat: 10,
    priority: 'Emergency',
    referrer: 'Dr. Sophie Greenberg',
    diagnosis_code: 'C61',
  },
  {
    fractions: 16,
    site: 'Red Hills',
    physician: 'Dr Smith',
    date: '2023-10-02',
    diagnosis: 'Unspecified',
    fraction_dose: 2.32,
    adherence: true,
    technique: 'VMAT',
    linac: 'Machine 3',
    sim_to_treat: 1,
    priority: 'Standard',
    referrer: 'Dr. Jonathan Blake',
    diagnosis_code: 'C79.81',
  },
  {
    fractions: 25,
    site: 'Campsie',
    physician: 'Dr Smith',
    date: '2023-03-27',
    diagnosis: 'Unspecified',
    fraction_dose: 1.86,
    adherence: true,
    technique: 'Brachytherapy',
    linac: 'Machine 3',
    sim_to_treat: 6,
    priority: 'Emergency',
    referrer: 'Dr. Sophie Greenberg',
    diagnosis_code: 'C43.9',
  },
  {
    fractions: 20,
    site: 'Rosebush',
    physician: 'Dr Lopez',
    date: '2023-12-28',
    diagnosis: 'breast',
    fraction_dose: 2.54,
    adherence: true,
    technique: 'Conformal',
    linac: 'Machine 4',
    sim_to_treat: 9,
    priority: 'Emergency',
    referrer: 'Dr. Victor Hernandes',
    diagnosis_code: 'C43.9',
  },
  {
    fractions: 19,
    site: 'Lakeside',
    physician: 'Dr Wong',
    date: '2023-02-05',
    diagnosis: 'metastasis',
    fraction_dose: 2.79,
    adherence: true,
    technique: 'Conformal',
    linac: 'Machine 3',
    sim_to_treat: 7,
    priority: 'Emergency',
    referrer: 'Dr. Sophie Greenberg',
    diagnosis_code: 'C79.81',
  },
  {
    fractions: 21,
    site: 'Lakeside',
    physician: 'Dr Adams',
    date: '2023-09-20',
    diagnosis: 'colorectal',
    fraction_dose: 2.22,
    adherence: true,
    technique: 'Conformal',
    linac: 'Machine 2',
    sim_to_treat: 10,
    priority: 'Urgent',
    referrer: 'Dr. Victor Hernandes',
    diagnosis_code: 'C43.9',
  },
  {
    fractions: 13,
    site: 'Rosebush',
    physician: 'Dr Adams',
    date: '2023-06-13',
    diagnosis: 'colorectal',
    fraction_dose: 1.33,
    adherence: true,
    technique: 'Stereotactic',
    linac: 'Machine 2',
    sim_to_treat: 10,
    priority: 'Emergency',
    referrer: 'Dr. Rachel Nguyen',
    diagnosis_code: 'C79.81',
  },
  {
    fractions: 11,
    site: 'Rosebush',
    physician: 'Dr Adams',
    date: '2023-01-07',
    diagnosis: 'neuro',
    fraction_dose: 1.87,
    adherence: true,
    technique: 'Conformal',
    linac: 'Machine 3',
    sim_to_treat: 10,
    priority: 'Emergency',
    referrer: 'Dr. Rachel Nguyen',
    diagnosis_code: 'C79.81',
  },
  {
    fractions: 16,
    site: 'Lakeside',
    physician: 'Dr Adams',
    date: '2023-02-22',
    diagnosis: 'prostate',
    fraction_dose: 1.71,
    adherence: true,
    technique: 'Brachytherapy',
    linac: 'Machine 4',
    sim_to_treat: 5,
    priority: 'Standard',
    referrer: 'Dr. Rachel Nguyen',
    diagnosis_code: 'C34.90',
  },
  {
    fractions: 25,
    site: 'Campsie',
    physician: 'Dr Adams',
    date: '2023-03-23',
    diagnosis: 'prostate',
    fraction_dose: 1.9,
    adherence: true,
    technique: 'Conformal',
    linac: 'Machine 3',
    sim_to_treat: 4,
    priority: 'Standard',
    referrer: 'Dr. Alex Reynolds',
    diagnosis_code: 'C34.90',
  },
  {
    fractions: 21,
    site: 'Campsie',
    physician: 'Dr Adams',
    date: '2023-04-21',
    diagnosis: 'colorectal',
    fraction_dose: 1.3,
    adherence: true,
    technique: 'Electrons',
    linac: 'Machine 4',
    sim_to_treat: 1,
    priority: 'Standard',
    referrer: 'Dr. Alex Reynolds',
    diagnosis_code: 'C71.9',
  },
  {
    fractions: 10,
    site: 'Rosebush',
    physician: 'Dr Smith',
    date: '2023-01-29',
    diagnosis: 'skin',
    fraction_dose: 2.81,
    adherence: true,
    technique: 'Stereotactic',
    linac: 'Machine 1',
    sim_to_treat: 6,
    priority: 'Standard',
    referrer: 'Dr. Michael Stern',
    diagnosis_code: 'C34.90',
  },
  {
    fractions: 21,
    site: 'Campsie',
    physician: 'Dr Lopez',
    date: '2023-08-16',
    diagnosis: 'breast',
    fraction_dose: 1.6,
    adherence: true,
    technique: 'VMAT',
    linac: 'Machine 3',
    sim_to_treat: 9,
    priority: 'Urgent',
    referrer: 'Dr. Rachel Nguyen',
    diagnosis_code: 'C20',
  },
  {
    fractions: 18,
    site: 'Rosebush',
    physician: 'Dr Smith',
    date: '2023-10-22',
    diagnosis: 'colorectal',
    fraction_dose: 3.24,
    adherence: true,
    technique: 'Electrons',
    linac: 'Machine 3',
    sim_to_treat: 3,
    priority: 'Standard',
    referrer: 'Dr. Emily Hartman',
    diagnosis_code: 'C61',
  },
  {
    fractions: 10,
    site: 'Campsie',
    physician: 'Dr Lopez',
    date: '2023-08-21',
    diagnosis: 'metastasis',
    fraction_dose: 2.11,
    adherence: true,
    technique: 'Conformal',
    linac: 'Machine 3',
    sim_to_treat: 1,
    priority: 'Emergency',
    referrer: 'Dr. Jonathan Blake',
    diagnosis_code: 'C34.90',
  },
  {
    fractions: 19,
    site: 'Red Hills',
    physician: 'Dr Lopez',
    date: '2023-11-01',
    diagnosis: 'breast',
    fraction_dose: 3.07,
    adherence: true,
    technique: 'Unspecified',
    linac: 'Machine 2',
    sim_to_treat: 2,
    priority: 'Standard',
    referrer: 'Dr. Jonathan Blake',
    diagnosis_code: 'C43.9',
  },
  {
    fractions: 12,
    site: 'Campsie',
    physician: 'Dr Adams',
    date: '2023-04-25',
    diagnosis: 'prostate',
    fraction_dose: 2.41,
    adherence: true,
    technique: 'IMRT',
    linac: 'Machine 4',
    sim_to_treat: 2,
    priority: 'Urgent',
    referrer: 'Dr. Aisha Patel',
    diagnosis_code: 'C61',
  },
  {
    fractions: 16,
    site: 'Campsie',
    physician: 'Dr Lopez',
    date: '2023-01-12',
    diagnosis: 'breast',
    fraction_dose: 2.04,
    adherence: true,
    technique: 'IMRT',
    linac: 'Machine 3',
    sim_to_treat: 6,
    priority: 'Emergency',
    referrer: 'Dr. Jonathan Blake',
    diagnosis_code: 'C61',
  },
  {
    fractions: 21,
    site: 'Rosebush',
    physician: 'Dr Lopez',
    date: '2023-05-02',
    diagnosis: 'breast',
    fraction_dose: 1.38,
    adherence: true,
    technique: 'Electrons',
    linac: 'Machine 3',
    sim_to_treat: 4,
    priority: 'Emergency',
    referrer: 'Dr. Michael Stern',
    diagnosis_code: 'C20',
  },
  {
    fractions: 15,
    site: 'Campsie',
    physician: 'Dr Smith',
    date: '2023-04-06',
    diagnosis: 'neuro',
    fraction_dose: 2.21,
    adherence: true,
    technique: 'Conformal',
    linac: 'Machine 2',
    sim_to_treat: 2,
    priority: 'Standard',
    referrer: 'Dr. Aisha Patel',
    diagnosis_code: 'C20',
  },
  {
    fractions: 24,
    site: 'Red Hills',
    physician: 'Dr Adams',
    date: '2023-04-29',
    diagnosis: 'breast',
    fraction_dose: 2.22,
    adherence: true,
    technique: 'Brachytherapy',
    linac: 'Machine 3',
    sim_to_treat: 7,
    priority: 'Urgent',
    referrer: 'Dr. Alex Reynolds',
    diagnosis_code: 'C71.9',
  },
  {
    fractions: 14,
    site: 'Rosebush',
    physician: 'Dr Adams',
    date: '2023-07-12',
    diagnosis: 'prostate',
    fraction_dose: 2.54,
    adherence: true,
    technique: 'Electrons',
    linac: 'Machine 1',
    sim_to_treat: 7,
    priority: 'Urgent',
    referrer: 'Dr. Sophie Greenberg',
    diagnosis_code: 'C61',
  },
  {
    fractions: 15,
    site: 'Rosebush',
    physician: 'Dr Lopez',
    date: '2023-09-23',
    diagnosis: 'neuro',
    fraction_dose: 2.0,
    adherence: true,
    technique: 'Brachytherapy',
    linac: 'Machine 3',
    sim_to_treat: 1,
    priority: 'Emergency',
    referrer: 'Dr. Aisha Patel',
    diagnosis_code: 'C20',
  },
  {
    fractions: 25,
    site: 'Rosebush',
    physician: 'Dr Wong',
    date: '2023-05-25',
    diagnosis: 'breast',
    fraction_dose: 2.76,
    adherence: false,
    technique: 'Brachytherapy',
    linac: 'Machine 2',
    sim_to_treat: 7,
    priority: 'Emergency',
    referrer: 'Dr. Julian Foster',
    diagnosis_code: 'C71.9',
  },
  {
    fractions: 25,
    site: 'Campsie',
    physician: 'Dr Lopez',
    date: '2023-08-20',
    diagnosis: 'skin',
    fraction_dose: 2.77,
    adherence: true,
    technique: 'Unspecified',
    linac: 'Machine 3',
    sim_to_treat: 4,
    priority: 'Standard',
    referrer: 'Dr. Emily Hartman',
    diagnosis_code: 'C34.90',
  },
  {
    fractions: 25,
    site: 'Lakeside',
    physician: 'Dr Smith',
    date: '2023-05-25',
    diagnosis: 'breast',
    fraction_dose: 2.17,
    adherence: true,
    technique: 'Unspecified',
    linac: 'Machine 3',
    sim_to_treat: 10,
    priority: 'Emergency',
    referrer: 'Dr. Michael Stern',
    diagnosis_code: 'C34.90',
  },
  {
    fractions: 16,
    site: 'Rosebush',
    physician: 'Dr Adams',
    date: '2023-03-13',
    diagnosis: 'skin',
    fraction_dose: 2.48,
    adherence: true,
    technique: 'Stereotactic',
    linac: 'Machine 3',
    sim_to_treat: 1,
    priority: 'Standard',
    referrer: 'Dr. Rachel Nguyen',
    diagnosis_code: 'C20',
  },
  {
    fractions: 19,
    site: 'Campsie',
    physician: 'Dr Wong',
    date: '2023-04-27',
    diagnosis: 'Unspecified',
    fraction_dose: 2.67,
    adherence: true,
    technique: 'VMAT',
    linac: 'Machine 2',
    sim_to_treat: 3,
    priority: 'Urgent',
    referrer: 'Dr. Emily Hartman',
    diagnosis_code: 'C43.9',
  },
  {
    fractions: 25,
    site: 'Red Hills',
    physician: 'Dr Wong',
    date: '2023-01-06',
    diagnosis: 'breast',
    fraction_dose: 3.3,
    adherence: true,
    technique: 'IMRT',
    linac: 'Machine 3',
    sim_to_treat: 2,
    priority: 'Urgent',
    referrer: 'Dr. Julian Foster',
    diagnosis_code: 'C43.9',
  },
  {
    fractions: 10,
    site: 'Red Hills',
    physician: 'Dr Smith',
    date: '2023-07-16',
    diagnosis: 'breast',
    fraction_dose: 2.28,
    adherence: true,
    technique: 'Stereotactic',
    linac: 'Machine 1',
    sim_to_treat: 8,
    priority: 'Emergency',
    referrer: 'Dr. Sophie Greenberg',
    diagnosis_code: 'C79.81',
  },
  {
    fractions: 22,
    site: 'Campsie',
    physician: 'Dr Wong',
    date: '2023-09-23',
    diagnosis: 'Unspecified',
    fraction_dose: 2.19,
    adherence: true,
    technique: 'Conformal',
    linac: 'Machine 4',
    sim_to_treat: 4,
    priority: 'Standard',
    referrer: 'Dr. Rachel Nguyen',
    diagnosis_code: 'C61',
  },
  {
    fractions: 23,
    site: 'Red Hills',
    physician: 'Dr Smith',
    date: '2023-01-21',
    diagnosis: 'breast',
    fraction_dose: 1.86,
    adherence: true,
    technique: 'Conformal',
    linac: 'Machine 3',
    sim_to_treat: 10,
    priority: 'Urgent',
    referrer: 'Dr. Emily Hartman',
    diagnosis_code: 'C79.81',
  },
  {
    fractions: 20,
    site: 'Campsie',
    physician: 'Dr Adams',
    date: '2023-06-23',
    diagnosis: 'breast',
    fraction_dose: 2.87,
    adherence: true,
    technique: 'Stereotactic',
    linac: 'Machine 4',
    sim_to_treat: 1,
    priority: 'Standard',
    referrer: 'Dr. Michael Stern',
    diagnosis_code: 'C34.90',
  },
  {
    fractions: 10,
    site: 'Lakeside',
    physician: 'Dr Wong',
    date: '2023-02-18',
    diagnosis: 'neuro',
    fraction_dose: 1.4,
    adherence: true,
    technique: 'Unspecified',
    linac: 'Machine 2',
    sim_to_treat: 3,
    priority: 'Standard',
    referrer: 'Dr. Alex Reynolds',
    diagnosis_code: 'C34.90',
  },
  {
    fractions: 21,
    site: 'Campsie',
    physician: 'Dr Smith',
    date: '2023-05-14',
    diagnosis: 'Unspecified',
    fraction_dose: 1.32,
    adherence: true,
    technique: 'IMRT',
    linac: 'Machine 4',
    sim_to_treat: 9,
    priority: 'Urgent',
    referrer: 'Dr. Julian Foster',
    diagnosis_code: 'C43.9',
  },
  {
    fractions: 15,
    site: 'Campsie',
    physician: 'Dr Smith',
    date: '2023-01-26',
    diagnosis: 'neuro',
    fraction_dose: 1.67,
    adherence: true,
    technique: 'Electrons',
    linac: 'Machine 1',
    sim_to_treat: 4,
    priority: 'Standard',
    referrer: 'Dr. Clara Thompson',
    diagnosis_code: 'C61',
  },
  {
    fractions: 20,
    site: 'Rosebush',
    physician: 'Dr Adams',
    date: '2023-01-11',
    diagnosis: 'prostate',
    fraction_dose: 2.96,
    adherence: true,
    technique: 'VMAT',
    linac: 'Machine 2',
    sim_to_treat: 10,
    priority: 'Urgent',
    referrer: 'Dr. Aisha Patel',
    diagnosis_code: 'C71.9',
  },
  {
    fractions: 22,
    site: 'Rosebush',
    physician: 'Dr Smith',
    date: '2023-12-26',
    diagnosis: 'neuro',
    fraction_dose: 1.97,
    adherence: true,
    technique: 'VMAT',
    linac: 'Machine 3',
    sim_to_treat: 9,
    priority: 'Emergency',
    referrer: 'Dr. Julian Foster',
    diagnosis_code: 'C79.81',
  },
  {
    fractions: 14,
    site: 'Campsie',
    physician: 'Dr Adams',
    date: '2023-07-29',
    diagnosis: 'skin',
    fraction_dose: 1.89,
    adherence: true,
    technique: 'Unspecified',
    linac: 'Machine 2',
    sim_to_treat: 5,
    priority: 'Urgent',
    referrer: 'Dr. Aisha Patel',
    diagnosis_code: 'C43.9',
  },
  {
    fractions: 25,
    site: 'Red Hills',
    physician: 'Dr Smith',
    date: '2023-12-04',
    diagnosis: 'prostate',
    fraction_dose: 1.88,
    adherence: true,
    technique: 'VMAT',
    linac: 'Machine 3',
    sim_to_treat: 2,
    priority: 'Standard',
    referrer: 'Dr. Victor Hernandes',
    diagnosis_code: 'C20',
  },
  {
    fractions: 22,
    site: 'Red Hills',
    physician: 'Dr Adams',
    date: '2023-07-03',
    diagnosis: 'prostate',
    fraction_dose: 3.11,
    adherence: true,
    technique: 'VMAT',
    linac: 'Machine 2',
    sim_to_treat: 7,
    priority: 'Emergency',
    referrer: 'Dr. Rachel Nguyen',
    diagnosis_code: 'C61',
  },
  {
    fractions: 16,
    site: 'Red Hills',
    physician: 'Dr Wong',
    date: '2023-12-17',
    diagnosis: 'colorectal',
    fraction_dose: 3.0,
    adherence: true,
    technique: 'VMAT',
    linac: 'Machine 2',
    sim_to_treat: 1,
    priority: 'Emergency',
    referrer: 'Dr. Aisha Patel',
    diagnosis_code: 'C61',
  },
  {
    fractions: 11,
    site: 'Lakeside',
    physician: 'Dr Wong',
    date: '2023-08-22',
    diagnosis: 'breast',
    fraction_dose: 2.46,
    adherence: true,
    technique: 'Stereotactic',
    linac: 'Machine 4',
    sim_to_treat: 7,
    priority: 'Emergency',
    referrer: 'Dr. Victor Hernandes',
    diagnosis_code: 'C71.9',
  },
  {
    fractions: 10,
    site: 'Red Hills',
    physician: 'Dr Adams',
    date: '2023-03-07',
    diagnosis: 'skin',
    fraction_dose: 2.55,
    adherence: true,
    technique: 'VMAT',
    linac: 'Machine 4',
    sim_to_treat: 5,
    priority: 'Standard',
    referrer: 'Dr. Aisha Patel',
    diagnosis_code: 'C61',
  },
  {
    fractions: 18,
    site: 'Campsie',
    physician: 'Dr Adams',
    date: '2023-12-17',
    diagnosis: 'metastasis',
    fraction_dose: 2.37,
    adherence: true,
    technique: 'Brachytherapy',
    linac: 'Machine 4',
    sim_to_treat: 4,
    priority: 'Urgent',
    referrer: 'Dr. Jonathan Blake',
    diagnosis_code: 'C79.81',
  },
  {
    fractions: 12,
    site: 'Campsie',
    physician: 'Dr Wong',
    date: '2023-01-02',
    diagnosis: 'neuro',
    fraction_dose: 1.84,
    adherence: true,
    technique: 'Stereotactic',
    linac: 'Machine 2',
    sim_to_treat: 1,
    priority: 'Standard',
    referrer: 'Dr. Alex Reynolds',
    diagnosis_code: 'C34.90',
  },
  {
    fractions: 19,
    site: 'Lakeside',
    physician: 'Dr Smith',
    date: '2023-04-25',
    diagnosis: 'Unspecified',
    fraction_dose: 2.37,
    adherence: true,
    technique: 'IMRT',
    linac: 'Machine 2',
    sim_to_treat: 3,
    priority: 'Standard',
    referrer: 'Dr. Jonathan Blake',
    diagnosis_code: 'C34.90',
  },
  {
    fractions: 15,
    site: 'Rosebush',
    physician: 'Dr Wong',
    date: '2023-12-03',
    diagnosis: 'neuro',
    fraction_dose: 1.75,
    adherence: false,
    technique: 'Conformal',
    linac: 'Machine 4',
    sim_to_treat: 7,
    priority: 'Urgent',
    referrer: 'Dr. Sophie Greenberg',
    diagnosis_code: 'C61',
  },
  {
    fractions: 15,
    site: 'Red Hills',
    physician: 'Dr Smith',
    date: '2023-01-29',
    diagnosis: 'prostate',
    fraction_dose: 2.03,
    adherence: true,
    technique: 'Conformal',
    linac: 'Machine 3',
    sim_to_treat: 5,
    priority: 'Standard',
    referrer: 'Dr. Sophie Greenberg',
    diagnosis_code: 'C34.90',
  },
  {
    fractions: 14,
    site: 'Lakeside',
    physician: 'Dr Smith',
    date: '2023-01-10',
    diagnosis: 'Unspecified',
    fraction_dose: 2.04,
    adherence: true,
    technique: 'Conformal',
    linac: 'Machine 4',
    sim_to_treat: 3,
    priority: 'Emergency',
    referrer: 'Dr. Rachel Nguyen',
    diagnosis_code: 'C43.9',
  },
  {
    fractions: 21,
    site: 'Lakeside',
    physician: 'Dr Adams',
    date: '2023-06-06',
    diagnosis: 'colorectal',
    fraction_dose: 2.17,
    adherence: true,
    technique: 'Conformal',
    linac: 'Machine 1',
    sim_to_treat: 1,
    priority: 'Standard',
    referrer: 'Dr. Alex Reynolds',
    diagnosis_code: 'C43.9',
  },
  {
    fractions: 23,
    site: 'Rosebush',
    physician: 'Dr Adams',
    date: '2023-03-19',
    diagnosis: 'breast',
    fraction_dose: 1.72,
    adherence: true,
    technique: 'VMAT',
    linac: 'Machine 3',
    sim_to_treat: 4,
    priority: 'Emergency',
    referrer: 'Dr. Emily Hartman',
    diagnosis_code: 'C20',
  },
  {
    fractions: 10,
    site: 'Lakeside',
    physician: 'Dr Lopez',
    date: '2023-01-07',
    diagnosis: 'neuro',
    fraction_dose: 2.54,
    adherence: true,
    technique: 'Stereotactic',
    linac: 'Machine 3',
    sim_to_treat: 3,
    priority: 'Standard',
    referrer: 'Dr. Emily Hartman',
    diagnosis_code: 'C71.9',
  },
  {
    fractions: 25,
    site: 'Rosebush',
    physician: 'Dr Wong',
    date: '2023-05-05',
    diagnosis: 'Unspecified',
    fraction_dose: 1.23,
    adherence: true,
    technique: 'Conformal',
    linac: 'Machine 2',
    sim_to_treat: 1,
    priority: 'Standard',
    referrer: 'Dr. Alex Reynolds',
    diagnosis_code: 'C43.9',
  },
  {
    fractions: 16,
    site: 'Campsie',
    physician: 'Dr Adams',
    date: '2023-07-14',
    diagnosis: 'skin',
    fraction_dose: 1.43,
    adherence: true,
    technique: 'Brachytherapy',
    linac: 'Machine 2',
    sim_to_treat: 1,
    priority: 'Urgent',
    referrer: 'Dr. Jonathan Blake',
    diagnosis_code: 'C34.90',
  },
  {
    fractions: 12,
    site: 'Campsie',
    physician: 'Dr Lopez',
    date: '2023-01-18',
    diagnosis: 'colorectal',
    fraction_dose: 1.58,
    adherence: true,
    technique: 'VMAT',
    linac: 'Machine 3',
    sim_to_treat: 5,
    priority: 'Urgent',
    referrer: 'Dr. Rachel Nguyen',
    diagnosis_code: 'C71.9',
  },
  {
    fractions: 24,
    site: 'Rosebush',
    physician: 'Dr Wong',
    date: '2023-05-16',
    diagnosis: 'skin',
    fraction_dose: 3.13,
    adherence: true,
    technique: 'Electrons',
    linac: 'Machine 2',
    sim_to_treat: 2,
    priority: 'Standard',
    referrer: 'Dr. Sophie Greenberg',
    diagnosis_code: 'C71.9',
  },
  {
    fractions: 17,
    site: 'Campsie',
    physician: 'Dr Adams',
    date: '2023-05-14',
    diagnosis: 'colorectal',
    fraction_dose: 1.96,
    adherence: true,
    technique: 'IMRT',
    linac: 'Machine 3',
    sim_to_treat: 2,
    priority: 'Standard',
    referrer: 'Dr. Alex Reynolds',
    diagnosis_code: 'C71.9',
  },
  {
    fractions: 13,
    site: 'Campsie',
    physician: 'Dr Smith',
    date: '2023-08-11',
    diagnosis: 'prostate',
    fraction_dose: 2.9,
    adherence: true,
    technique: 'Unspecified',
    linac: 'Machine 4',
    sim_to_treat: 10,
    priority: 'Emergency',
    referrer: 'Dr. Julian Foster',
    diagnosis_code: 'C20',
  },
  {
    fractions: 19,
    site: 'Campsie',
    physician: 'Dr Lopez',
    date: '2023-10-14',
    diagnosis: 'breast',
    fraction_dose: 1.24,
    adherence: true,
    technique: 'VMAT',
    linac: 'Machine 4',
    sim_to_treat: 1,
    priority: 'Standard',
    referrer: 'Dr. Clara Thompson',
    diagnosis_code: 'C71.9',
  },
  {
    fractions: 15,
    site: 'Red Hills',
    physician: 'Dr Smith',
    date: '2023-01-20',
    diagnosis: 'breast',
    fraction_dose: 1.65,
    adherence: true,
    technique: 'Conformal',
    linac: 'Machine 2',
    sim_to_treat: 1,
    priority: 'Urgent',
    referrer: 'Dr. Jonathan Blake',
    diagnosis_code: 'C43.9',
  },
  {
    fractions: 23,
    site: 'Lakeside',
    physician: 'Dr Wong',
    date: '2023-04-04',
    diagnosis: 'breast',
    fraction_dose: 1.93,
    adherence: true,
    technique: 'Unspecified',
    linac: 'Machine 2',
    sim_to_treat: 4,
    priority: 'Standard',
    referrer: 'Dr. Jonathan Blake',
    diagnosis_code: 'C43.9',
  },
  {
    fractions: 17,
    site: 'Red Hills',
    physician: 'Dr Smith',
    date: '2023-04-25',
    diagnosis: 'metastasis',
    fraction_dose: 1.94,
    adherence: true,
    technique: 'Conformal',
    linac: 'Machine 2',
    sim_to_treat: 1,
    priority: 'Emergency',
    referrer: 'Dr. Michael Stern',
    diagnosis_code: 'C71.9',
  },
  {
    fractions: 25,
    site: 'Campsie',
    physician: 'Dr Smith',
    date: '2023-04-29',
    diagnosis: 'metastasis',
    fraction_dose: 2.83,
    adherence: true,
    technique: 'VMAT',
    linac: 'Machine 4',
    sim_to_treat: 8,
    priority: 'Urgent',
    referrer: 'Dr. Aisha Patel',
    diagnosis_code: 'C61',
  },
  {
    fractions: 23,
    site: 'Red Hills',
    physician: 'Dr Lopez',
    date: '2023-03-25',
    diagnosis: 'metastasis',
    fraction_dose: 2.51,
    adherence: true,
    technique: 'Unspecified',
    linac: 'Machine 4',
    sim_to_treat: 4,
    priority: 'Emergency',
    referrer: 'Dr. Clara Thompson',
    diagnosis_code: 'C34.90',
  },
  {
    fractions: 17,
    site: 'Campsie',
    physician: 'Dr Wong',
    date: '2023-07-26',
    diagnosis: 'neuro',
    fraction_dose: 1.77,
    adherence: true,
    technique: 'Brachytherapy',
    linac: 'Machine 1',
    sim_to_treat: 5,
    priority: 'Standard',
    referrer: 'Dr. Julian Foster',
    diagnosis_code: 'C79.81',
  },
  {
    fractions: 20,
    site: 'Rosebush',
    physician: 'Dr Lopez',
    date: '2023-11-10',
    diagnosis: 'breast',
    fraction_dose: 2.16,
    adherence: true,
    technique: 'VMAT',
    linac: 'Machine 3',
    sim_to_treat: 6,
    priority: 'Standard',
    referrer: 'Dr. Emily Hartman',
    diagnosis_code: 'C71.9',
  },
  {
    fractions: 18,
    site: 'Rosebush',
    physician: 'Dr Smith',
    date: '2023-06-10',
    diagnosis: 'colorectal',
    fraction_dose: 2.63,
    adherence: true,
    technique: 'Unspecified',
    linac: 'Machine 4',
    sim_to_treat: 7,
    priority: 'Standard',
    referrer: 'Dr. Julian Foster',
    diagnosis_code: 'C79.81',
  },
  {
    fractions: 15,
    site: 'Campsie',
    physician: 'Dr Adams',
    date: '2023-10-18',
    diagnosis: 'breast',
    fraction_dose: 1.49,
    adherence: true,
    technique: 'Brachytherapy',
    linac: 'Machine 2',
    sim_to_treat: 6,
    priority: 'Emergency',
    referrer: 'Dr. Julian Foster',
    diagnosis_code: 'C43.9',
  },
  {
    fractions: 18,
    site: 'Rosebush',
    physician: 'Dr Adams',
    date: '2023-03-25',
    diagnosis: 'colorectal',
    fraction_dose: 2.05,
    adherence: true,
    technique: 'Brachytherapy',
    linac: 'Machine 4',
    sim_to_treat: 10,
    priority: 'Urgent',
    referrer: 'Dr. Sophie Greenberg',
    diagnosis_code: 'C34.90',
  },
  {
    fractions: 18,
    site: 'Lakeside',
    physician: 'Dr Adams',
    date: '2023-09-03',
    diagnosis: 'metastasis',
    fraction_dose: 2.91,
    adherence: true,
    technique: 'VMAT',
    linac: 'Machine 4',
    sim_to_treat: 6,
    priority: 'Urgent',
    referrer: 'Dr. Rachel Nguyen',
    diagnosis_code: 'C61',
  },
  {
    fractions: 20,
    site: 'Campsie',
    physician: 'Dr Smith',
    date: '2023-07-05',
    diagnosis: 'neuro',
    fraction_dose: 2.82,
    adherence: true,
    technique: 'Brachytherapy',
    linac: 'Machine 2',
    sim_to_treat: 8,
    priority: 'Standard',
    referrer: 'Dr. Jonathan Blake',
    diagnosis_code: 'C34.90',
  },
  {
    fractions: 25,
    site: 'Red Hills',
    physician: 'Dr Smith',
    date: '2023-04-29',
    diagnosis: 'metastasis',
    fraction_dose: 2.7,
    adherence: true,
    technique: 'Brachytherapy',
    linac: 'Machine 4',
    sim_to_treat: 8,
    priority: 'Urgent',
    referrer: 'Dr. Sophie Greenberg',
    diagnosis_code: 'C20',
  },
  {
    fractions: 19,
    site: 'Rosebush',
    physician: 'Dr Adams',
    date: '2023-12-05',
    diagnosis: 'metastasis',
    fraction_dose: 1.79,
    adherence: true,
    technique: 'Stereotactic',
    linac: 'Machine 1',
    sim_to_treat: 3,
    priority: 'Urgent',
    referrer: 'Dr. Jonathan Blake',
    diagnosis_code: 'C61',
  },
  {
    fractions: 17,
    site: 'Lakeside',
    physician: 'Dr Lopez',
    date: '2023-05-23',
    diagnosis: 'metastasis',
    fraction_dose: 2.58,
    adherence: true,
    technique: 'Conformal',
    linac: 'Machine 3',
    sim_to_treat: 3,
    priority: 'Emergency',
    referrer: 'Dr. Aisha Patel',
    diagnosis_code: 'C71.9',
  },
  {
    fractions: 19,
    site: 'Campsie',
    physician: 'Dr Adams',
    date: '2023-09-09',
    diagnosis: 'breast',
    fraction_dose: 1.5,
    adherence: true,
    technique: 'Stereotactic',
    linac: 'Machine 3',
    sim_to_treat: 6,
    priority: 'Urgent',
    referrer: 'Dr. Sophie Greenberg',
    diagnosis_code: 'C79.81',
  },
  {
    fractions: 12,
    site: 'Campsie',
    physician: 'Dr Wong',
    date: '2023-03-10',
    diagnosis: 'metastasis',
    fraction_dose: 3.01,
    adherence: true,
    technique: 'Stereotactic',
    linac: 'Machine 3',
    sim_to_treat: 9,
    priority: 'Emergency',
    referrer: 'Dr. Jonathan Blake',
    diagnosis_code: 'C43.9',
  },
  {
    fractions: 21,
    site: 'Campsie',
    physician: 'Dr Smith',
    date: '2023-08-23',
    diagnosis: 'prostate',
    fraction_dose: 2.39,
    adherence: true,
    technique: 'Stereotactic',
    linac: 'Machine 3',
    sim_to_treat: 4,
    priority: 'Urgent',
    referrer: 'Dr. Alex Reynolds',
    diagnosis_code: 'C20',
  },
  {
    fractions: 19,
    site: 'Lakeside',
    physician: 'Dr Adams',
    date: '2023-09-10',
    diagnosis: 'Unspecified',
    fraction_dose: 2.0,
    adherence: true,
    technique: 'VMAT',
    linac: 'Machine 1',
    sim_to_treat: 7,
    priority: 'Urgent',
    referrer: 'Dr. Emily Hartman',
    diagnosis_code: 'C20',
  },
  {
    fractions: 18,
    site: 'Rosebush',
    physician: 'Dr Wong',
    date: '2023-12-30',
    diagnosis: 'breast',
    fraction_dose: 2.11,
    adherence: true,
    technique: 'Unspecified',
    linac: 'Machine 2',
    sim_to_treat: 3,
    priority: 'Urgent',
    referrer: 'Dr. Rachel Nguyen',
    diagnosis_code: 'C61',
  },
  {
    fractions: 18,
    site: 'Rosebush',
    physician: 'Dr Wong',
    date: '2023-01-19',
    diagnosis: 'breast',
    fraction_dose: 1.72,
    adherence: true,
    technique: 'VMAT',
    linac: 'Machine 4',
    sim_to_treat: 6,
    priority: 'Emergency',
    referrer: 'Dr. Clara Thompson',
    diagnosis_code: 'C79.81',
  },
  {
    fractions: 16,
    site: 'Red Hills',
    physician: 'Dr Smith',
    date: '2023-02-09',
    diagnosis: 'skin',
    fraction_dose: 1.91,
    adherence: true,
    technique: 'VMAT',
    linac: 'Machine 2',
    sim_to_treat: 5,
    priority: 'Emergency',
    referrer: 'Dr. Alex Reynolds',
    diagnosis_code: 'C71.9',
  },
  {
    fractions: 16,
    site: 'Campsie',
    physician: 'Dr Wong',
    date: '2023-08-23',
    diagnosis: 'neuro',
    fraction_dose: 2.36,
    adherence: true,
    technique: 'Unspecified',
    linac: 'Machine 2',
    sim_to_treat: 2,
    priority: 'Standard',
    referrer: 'Dr. Michael Stern',
    diagnosis_code: 'C20',
  },
  {
    fractions: 23,
    site: 'Red Hills',
    physician: 'Dr Adams',
    date: '2023-12-01',
    diagnosis: 'neuro',
    fraction_dose: 3.0,
    adherence: true,
    technique: 'Unspecified',
    linac: 'Machine 1',
    sim_to_treat: 10,
    priority: 'Emergency',
    referrer: 'Dr. Clara Thompson',
    diagnosis_code: 'C43.9',
  },
  {
    fractions: 20,
    site: 'Red Hills',
    physician: 'Dr Adams',
    date: '2023-04-20',
    diagnosis: 'neuro',
    fraction_dose: 1.37,
    adherence: true,
    technique: 'Conformal',
    linac: 'Machine 2',
    sim_to_treat: 3,
    priority: 'Standard',
    referrer: 'Dr. Michael Stern',
    diagnosis_code: 'C61',
  },
  {
    fractions: 17,
    site: 'Red Hills',
    physician: 'Dr Lopez',
    date: '2023-12-07',
    diagnosis: 'skin',
    fraction_dose: 3.4,
    adherence: true,
    technique: 'Conformal',
    linac: 'Machine 4',
    sim_to_treat: 8,
    priority: 'Emergency',
    referrer: 'Dr. Clara Thompson',
    diagnosis_code: 'C61',
  },
  {
    fractions: 19,
    site: 'Campsie',
    physician: 'Dr Lopez',
    date: '2023-04-08',
    diagnosis: 'neuro',
    fraction_dose: 1.43,
    adherence: true,
    technique: 'Brachytherapy',
    linac: 'Machine 2',
    sim_to_treat: 4,
    priority: 'Emergency',
    referrer: 'Dr. Alex Reynolds',
    diagnosis_code: 'C20',
  },
  {
    fractions: 14,
    site: 'Lakeside',
    physician: 'Dr Adams',
    date: '2023-11-21',
    diagnosis: 'breast',
    fraction_dose: 1.51,
    adherence: true,
    technique: 'Stereotactic',
    linac: 'Machine 2',
    sim_to_treat: 8,
    priority: 'Urgent',
    referrer: 'Dr. Aisha Patel',
    diagnosis_code: 'C79.81',
  },
  {
    fractions: 15,
    site: 'Rosebush',
    physician: 'Dr Wong',
    date: '2023-03-16',
    diagnosis: 'breast',
    fraction_dose: 2.03,
    adherence: true,
    technique: 'Brachytherapy',
    linac: 'Machine 4',
    sim_to_treat: 6,
    priority: 'Emergency',
    referrer: 'Dr. Alex Reynolds',
    diagnosis_code: 'C20',
  },
  {
    fractions: 11,
    site: 'Lakeside',
    physician: 'Dr Wong',
    date: '2023-11-26',
    diagnosis: 'skin',
    fraction_dose: 2.41,
    adherence: true,
    technique: 'Unspecified',
    linac: 'Machine 2',
    sim_to_treat: 2,
    priority: 'Standard',
    referrer: 'Dr. Emily Hartman',
    diagnosis_code: 'C20',
  },
  {
    fractions: 13,
    site: 'Lakeside',
    physician: 'Dr Smith',
    date: '2023-03-01',
    diagnosis: 'neuro',
    fraction_dose: 2.04,
    adherence: false,
    technique: 'Brachytherapy',
    linac: 'Machine 3',
    sim_to_treat: 10,
    priority: 'Standard',
    referrer: 'Dr. Emily Hartman',
    diagnosis_code: 'C20',
  },
  {
    fractions: 13,
    site: 'Rosebush',
    physician: 'Dr Wong',
    date: '2023-05-07',
    diagnosis: 'Unspecified',
    fraction_dose: 3.06,
    adherence: true,
    technique: 'Brachytherapy',
    linac: 'Machine 2',
    sim_to_treat: 6,
    priority: 'Standard',
    referrer: 'Dr. Aisha Patel',
    diagnosis_code: 'C61',
  },
  {
    fractions: 20,
    site: 'Campsie',
    physician: 'Dr Adams',
    date: '2023-05-18',
    diagnosis: 'colorectal',
    fraction_dose: 1.43,
    adherence: true,
    technique: 'VMAT',
    linac: 'Machine 3',
    sim_to_treat: 2,
    priority: 'Emergency',
    referrer: 'Dr. Clara Thompson',
    diagnosis_code: 'C43.9',
  },
  {
    fractions: 20,
    site: 'Campsie',
    physician: 'Dr Wong',
    date: '2023-07-24',
    diagnosis: 'skin',
    fraction_dose: 1.55,
    adherence: true,
    technique: 'Brachytherapy',
    linac: 'Machine 3',
    sim_to_treat: 4,
    priority: 'Standard',
    referrer: 'Dr. Alex Reynolds',
    diagnosis_code: 'C79.81',
  },
  {
    fractions: 12,
    site: 'Campsie',
    physician: 'Dr Adams',
    date: '2023-11-04',
    diagnosis: 'colorectal',
    fraction_dose: 3.14,
    adherence: true,
    technique: 'Stereotactic',
    linac: 'Machine 4',
    sim_to_treat: 9,
    priority: 'Urgent',
    referrer: 'Dr. Victor Hernandes',
    diagnosis_code: 'C79.81',
  },
  {
    fractions: 19,
    site: 'Campsie',
    physician: 'Dr Smith',
    date: '2023-05-20',
    diagnosis: 'metastasis',
    fraction_dose: 3.22,
    adherence: true,
    technique: 'Electrons',
    linac: 'Machine 1',
    sim_to_treat: 9,
    priority: 'Emergency',
    referrer: 'Dr. Rachel Nguyen',
    diagnosis_code: 'C20',
  },
  {
    fractions: 15,
    site: 'Rosebush',
    physician: 'Dr Adams',
    date: '2023-09-07',
    diagnosis: 'prostate',
    fraction_dose: 3.0,
    adherence: true,
    technique: 'Electrons',
    linac: 'Machine 2',
    sim_to_treat: 10,
    priority: 'Standard',
    referrer: 'Dr. Julian Foster',
    diagnosis_code: 'C79.81',
  },
  {
    fractions: 19,
    site: 'Lakeside',
    physician: 'Dr Wong',
    date: '2023-06-11',
    diagnosis: 'Unspecified',
    fraction_dose: 1.88,
    adherence: true,
    technique: 'Electrons',
    linac: 'Machine 4',
    sim_to_treat: 6,
    priority: 'Emergency',
    referrer: 'Dr. Clara Thompson',
    diagnosis_code: 'C71.9',
  },
  {
    fractions: 19,
    site: 'Campsie',
    physician: 'Dr Adams',
    date: '2023-05-06',
    diagnosis: 'colorectal',
    fraction_dose: 2.49,
    adherence: true,
    technique: 'Stereotactic',
    linac: 'Machine 2',
    sim_to_treat: 2,
    priority: 'Standard',
    referrer: 'Dr. Rachel Nguyen',
    diagnosis_code: 'C20',
  },
  {
    fractions: 11,
    site: 'Lakeside',
    physician: 'Dr Wong',
    date: '2023-06-30',
    diagnosis: 'breast',
    fraction_dose: 2.34,
    adherence: true,
    technique: 'Conformal',
    linac: 'Machine 1',
    sim_to_treat: 9,
    priority: 'Urgent',
    referrer: 'Dr. Victor Hernandes',
    diagnosis_code: 'C79.81',
  },
  {
    fractions: 14,
    site: 'Lakeside',
    physician: 'Dr Smith',
    date: '2023-03-04',
    diagnosis: 'metastasis',
    fraction_dose: 3.26,
    adherence: true,
    technique: 'Unspecified',
    linac: 'Machine 4',
    sim_to_treat: 8,
    priority: 'Emergency',
    referrer: 'Dr. Rachel Nguyen',
    diagnosis_code: 'C61',
  },
  {
    fractions: 13,
    site: 'Rosebush',
    physician: 'Dr Adams',
    date: '2023-04-28',
    diagnosis: 'breast',
    fraction_dose: 1.45,
    adherence: true,
    technique: 'Unspecified',
    linac: 'Machine 2',
    sim_to_treat: 10,
    priority: 'Urgent',
    referrer: 'Dr. Emily Hartman',
    diagnosis_code: 'C71.9',
  },
  {
    fractions: 18,
    site: 'Rosebush',
    physician: 'Dr Wong',
    date: '2023-01-14',
    diagnosis: 'prostate',
    fraction_dose: 2.24,
    adherence: true,
    technique: 'Unspecified',
    linac: 'Machine 1',
    sim_to_treat: 7,
    priority: 'Emergency',
    referrer: 'Dr. Sophie Greenberg',
    diagnosis_code: 'C34.90',
  },
  {
    fractions: 10,
    site: 'Red Hills',
    physician: 'Dr Smith',
    date: '2023-12-13',
    diagnosis: 'breast',
    fraction_dose: 1.4,
    adherence: true,
    technique: 'IMRT',
    linac: 'Machine 3',
    sim_to_treat: 3,
    priority: 'Urgent',
    referrer: 'Dr. Jonathan Blake',
    diagnosis_code: 'C20',
  },
  {
    fractions: 25,
    site: 'Lakeside',
    physician: 'Dr Wong',
    date: '2023-08-21',
    diagnosis: 'neuro',
    fraction_dose: 3.15,
    adherence: true,
    technique: 'Brachytherapy',
    linac: 'Machine 4',
    sim_to_treat: 2,
    priority: 'Standard',
    referrer: 'Dr. Jonathan Blake',
    diagnosis_code: 'C71.9',
  },
  {
    fractions: 19,
    site: 'Campsie',
    physician: 'Dr Smith',
    date: '2023-06-14',
    diagnosis: 'neuro',
    fraction_dose: 3.18,
    adherence: true,
    technique: 'Conformal',
    linac: 'Machine 2',
    sim_to_treat: 9,
    priority: 'Standard',
    referrer: 'Dr. Aisha Patel',
    diagnosis_code: 'C79.81',
  },
  {
    fractions: 24,
    site: 'Lakeside',
    physician: 'Dr Wong',
    date: '2023-06-28',
    diagnosis: 'neuro',
    fraction_dose: 2.87,
    adherence: true,
    technique: 'Stereotactic',
    linac: 'Machine 3',
    sim_to_treat: 2,
    priority: 'Urgent',
    referrer: 'Dr. Emily Hartman',
    diagnosis_code: 'C79.81',
  },
  {
    fractions: 15,
    site: 'Campsie',
    physician: 'Dr Wong',
    date: '2023-07-21',
    diagnosis: 'metastasis',
    fraction_dose: 1.26,
    adherence: true,
    technique: 'Conformal',
    linac: 'Machine 4',
    sim_to_treat: 7,
    priority: 'Urgent',
    referrer: 'Dr. Sophie Greenberg',
    diagnosis_code: 'C20',
  },
  {
    fractions: 16,
    site: 'Lakeside',
    physician: 'Dr Lopez',
    date: '2023-11-19',
    diagnosis: 'skin',
    fraction_dose: 1.25,
    adherence: true,
    technique: 'Brachytherapy',
    linac: 'Machine 3',
    sim_to_treat: 1,
    priority: 'Urgent',
    referrer: 'Dr. Sophie Greenberg',
    diagnosis_code: 'C43.9',
  },
  {
    fractions: 19,
    site: 'Rosebush',
    physician: 'Dr Smith',
    date: '2023-01-22',
    diagnosis: 'Unspecified',
    fraction_dose: 3.32,
    adherence: true,
    technique: 'Stereotactic',
    linac: 'Machine 3',
    sim_to_treat: 3,
    priority: 'Urgent',
    referrer: 'Dr. Alex Reynolds',
    diagnosis_code: 'C34.90',
  },
  {
    fractions: 23,
    site: 'Rosebush',
    physician: 'Dr Adams',
    date: '2023-07-15',
    diagnosis: 'Unspecified',
    fraction_dose: 1.28,
    adherence: true,
    technique: 'IMRT',
    linac: 'Machine 4',
    sim_to_treat: 8,
    priority: 'Emergency',
    referrer: 'Dr. Jonathan Blake',
    diagnosis_code: 'C79.81',
  },
  {
    fractions: 23,
    site: 'Campsie',
    physician: 'Dr Wong',
    date: '2023-01-25',
    diagnosis: 'colorectal',
    fraction_dose: 2.61,
    adherence: true,
    technique: 'IMRT',
    linac: 'Machine 4',
    sim_to_treat: 9,
    priority: 'Urgent',
    referrer: 'Dr. Jonathan Blake',
    diagnosis_code: 'C34.90',
  },
  {
    fractions: 23,
    site: 'Rosebush',
    physician: 'Dr Lopez',
    date: '2023-05-08',
    diagnosis: 'prostate',
    fraction_dose: 1.5,
    adherence: true,
    technique: 'Unspecified',
    linac: 'Machine 3',
    sim_to_treat: 10,
    priority: 'Emergency',
    referrer: 'Dr. Jonathan Blake',
    diagnosis_code: 'C71.9',
  },
  {
    fractions: 12,
    site: 'Red Hills',
    physician: 'Dr Lopez',
    date: '2023-04-17',
    diagnosis: 'prostate',
    fraction_dose: 2.15,
    adherence: true,
    technique: 'VMAT',
    linac: 'Machine 4',
    sim_to_treat: 1,
    priority: 'Standard',
    referrer: 'Dr. Jonathan Blake',
    diagnosis_code: 'C20',
  },
  {
    fractions: 19,
    site: 'Lakeside',
    physician: 'Dr Lopez',
    date: '2023-04-25',
    diagnosis: 'skin',
    fraction_dose: 1.22,
    adherence: true,
    technique: 'Electrons',
    linac: 'Machine 1',
    sim_to_treat: 9,
    priority: 'Standard',
    referrer: 'Dr. Alex Reynolds',
    diagnosis_code: 'C20',
  },
  {
    fractions: 21,
    site: 'Campsie',
    physician: 'Dr Smith',
    date: '2023-06-07',
    diagnosis: 'colorectal',
    fraction_dose: 2.15,
    adherence: true,
    technique: 'Stereotactic',
    linac: 'Machine 3',
    sim_to_treat: 1,
    priority: 'Emergency',
    referrer: 'Dr. Clara Thompson',
    diagnosis_code: 'C20',
  },
  {
    fractions: 25,
    site: 'Lakeside',
    physician: 'Dr Wong',
    date: '2023-05-06',
    diagnosis: 'Unspecified',
    fraction_dose: 2.66,
    adherence: true,
    technique: 'Conformal',
    linac: 'Machine 4',
    sim_to_treat: 2,
    priority: 'Standard',
    referrer: 'Dr. Jonathan Blake',
    diagnosis_code: 'C61',
  },
  {
    fractions: 22,
    site: 'Lakeside',
    physician: 'Dr Adams',
    date: '2023-10-17',
    diagnosis: 'metastasis',
    fraction_dose: 1.37,
    adherence: true,
    technique: 'Unspecified',
    linac: 'Machine 1',
    sim_to_treat: 10,
    priority: 'Emergency',
    referrer: 'Dr. Alex Reynolds',
    diagnosis_code: 'C71.9',
  },
  {
    fractions: 18,
    site: 'Rosebush',
    physician: 'Dr Lopez',
    date: '2023-01-27',
    diagnosis: 'Unspecified',
    fraction_dose: 2.75,
    adherence: true,
    technique: 'Unspecified',
    linac: 'Machine 2',
    sim_to_treat: 2,
    priority: 'Emergency',
    referrer: 'Dr. Victor Hernandes',
    diagnosis_code: 'C20',
  },
  {
    fractions: 25,
    site: 'Lakeside',
    physician: 'Dr Wong',
    date: '2023-04-29',
    diagnosis: 'prostate',
    fraction_dose: 2.72,
    adherence: true,
    technique: 'Conformal',
    linac: 'Machine 1',
    sim_to_treat: 5,
    priority: 'Emergency',
    referrer: 'Dr. Julian Foster',
    diagnosis_code: 'C34.90',
  },
  {
    fractions: 15,
    site: 'Rosebush',
    physician: 'Dr Wong',
    date: '2023-01-30',
    diagnosis: 'skin',
    fraction_dose: 2.12,
    adherence: true,
    technique: 'Stereotactic',
    linac: 'Machine 2',
    sim_to_treat: 9,
    priority: 'Emergency',
    referrer: 'Dr. Michael Stern',
    diagnosis_code: 'C79.81',
  },
  {
    fractions: 12,
    site: 'Red Hills',
    physician: 'Dr Lopez',
    date: '2023-12-14',
    diagnosis: 'breast',
    fraction_dose: 1.57,
    adherence: true,
    technique: 'IMRT',
    linac: 'Machine 4',
    sim_to_treat: 2,
    priority: 'Emergency',
    referrer: 'Dr. Julian Foster',
    diagnosis_code: 'C61',
  },
  {
    fractions: 10,
    site: 'Rosebush',
    physician: 'Dr Lopez',
    date: '2023-06-14',
    diagnosis: 'Unspecified',
    fraction_dose: 1.53,
    adherence: true,
    technique: 'Electrons',
    linac: 'Machine 1',
    sim_to_treat: 10,
    priority: 'Urgent',
    referrer: 'Dr. Jonathan Blake',
    diagnosis_code: 'C43.9',
  },
  {
    fractions: 21,
    site: 'Red Hills',
    physician: 'Dr Wong',
    date: '2023-04-14',
    diagnosis: 'colorectal',
    fraction_dose: 1.5,
    adherence: true,
    technique: 'VMAT',
    linac: 'Machine 4',
    sim_to_treat: 9,
    priority: 'Emergency',
    referrer: 'Dr. Sophie Greenberg',
    diagnosis_code: 'C71.9',
  },
  {
    fractions: 10,
    site: 'Lakeside',
    physician: 'Dr Smith',
    date: '2023-01-05',
    diagnosis: 'neuro',
    fraction_dose: 2.2,
    adherence: true,
    technique: 'Brachytherapy',
    linac: 'Machine 4',
    sim_to_treat: 3,
    priority: 'Urgent',
    referrer: 'Dr. Rachel Nguyen',
    diagnosis_code: 'C43.9',
  },
  {
    fractions: 19,
    site: 'Campsie',
    physician: 'Dr Wong',
    date: '2023-07-16',
    diagnosis: 'metastasis',
    fraction_dose: 1.33,
    adherence: true,
    technique: 'Electrons',
    linac: 'Machine 1',
    sim_to_treat: 2,
    priority: 'Emergency',
    referrer: 'Dr. Clara Thompson',
    diagnosis_code: 'C34.90',
  },
  {
    fractions: 18,
    site: 'Lakeside',
    physician: 'Dr Wong',
    date: '2023-10-01',
    diagnosis: 'metastasis',
    fraction_dose: 2.92,
    adherence: true,
    technique: 'IMRT',
    linac: 'Machine 3',
    sim_to_treat: 8,
    priority: 'Standard',
    referrer: 'Dr. Michael Stern',
    diagnosis_code: 'C43.9',
  },
  {
    fractions: 21,
    site: 'Rosebush',
    physician: 'Dr Smith',
    date: '2023-12-16',
    diagnosis: 'colorectal',
    fraction_dose: 2.61,
    adherence: true,
    technique: 'IMRT',
    linac: 'Machine 4',
    sim_to_treat: 3,
    priority: 'Standard',
    referrer: 'Dr. Sophie Greenberg',
    diagnosis_code: 'C79.81',
  },
  {
    fractions: 12,
    site: 'Lakeside',
    physician: 'Dr Lopez',
    date: '2023-01-05',
    diagnosis: 'prostate',
    fraction_dose: 1.35,
    adherence: true,
    technique: 'Conformal',
    linac: 'Machine 1',
    sim_to_treat: 10,
    priority: 'Emergency',
    referrer: 'Dr. Alex Reynolds',
    diagnosis_code: 'C20',
  },
  {
    fractions: 15,
    site: 'Rosebush',
    physician: 'Dr Smith',
    date: '2023-06-12',
    diagnosis: 'skin',
    fraction_dose: 2.18,
    adherence: true,
    technique: 'IMRT',
    linac: 'Machine 3',
    sim_to_treat: 8,
    priority: 'Urgent',
    referrer: 'Dr. Sophie Greenberg',
    diagnosis_code: 'C34.90',
  },
  {
    fractions: 25,
    site: 'Lakeside',
    physician: 'Dr Adams',
    date: '2023-09-22',
    diagnosis: 'breast',
    fraction_dose: 2.2,
    adherence: true,
    technique: 'Electrons',
    linac: 'Machine 3',
    sim_to_treat: 4,
    priority: 'Urgent',
    referrer: 'Dr. Emily Hartman',
    diagnosis_code: 'C71.9',
  },
  {
    fractions: 19,
    site: 'Rosebush',
    physician: 'Dr Adams',
    date: '2023-04-07',
    diagnosis: 'prostate',
    fraction_dose: 2.38,
    adherence: true,
    technique: 'Brachytherapy',
    linac: 'Machine 3',
    sim_to_treat: 5,
    priority: 'Standard',
    referrer: 'Dr. Clara Thompson',
    diagnosis_code: 'C79.81',
  },
  {
    fractions: 21,
    site: 'Red Hills',
    physician: 'Dr Wong',
    date: '2023-12-17',
    diagnosis: 'colorectal',
    fraction_dose: 2.57,
    adherence: true,
    technique: 'IMRT',
    linac: 'Machine 4',
    sim_to_treat: 1,
    priority: 'Emergency',
    referrer: 'Dr. Clara Thompson',
    diagnosis_code: 'C71.9',
  },
  {
    fractions: 16,
    site: 'Lakeside',
    physician: 'Dr Smith',
    date: '2023-01-23',
    diagnosis: 'Unspecified',
    fraction_dose: 2.24,
    adherence: true,
    technique: 'Unspecified',
    linac: 'Machine 2',
    sim_to_treat: 3,
    priority: 'Emergency',
    referrer: 'Dr. Alex Reynolds',
    diagnosis_code: 'C20',
  },
  {
    fractions: 19,
    site: 'Lakeside',
    physician: 'Dr Smith',
    date: '2023-12-07',
    diagnosis: 'neuro',
    fraction_dose: 1.73,
    adherence: true,
    technique: 'Stereotactic',
    linac: 'Machine 4',
    sim_to_treat: 2,
    priority: 'Urgent',
    referrer: 'Dr. Victor Hernandes',
    diagnosis_code: 'C61',
  },
  {
    fractions: 16,
    site: 'Rosebush',
    physician: 'Dr Lopez',
    date: '2023-04-09',
    diagnosis: 'metastasis',
    fraction_dose: 2.58,
    adherence: true,
    technique: 'IMRT',
    linac: 'Machine 2',
    sim_to_treat: 9,
    priority: 'Urgent',
    referrer: 'Dr. Michael Stern',
    diagnosis_code: 'C71.9',
  },
  {
    fractions: 10,
    site: 'Campsie',
    physician: 'Dr Smith',
    date: '2023-07-06',
    diagnosis: 'Unspecified',
    fraction_dose: 1.4,
    adherence: true,
    technique: 'Conformal',
    linac: 'Machine 2',
    sim_to_treat: 9,
    priority: 'Standard',
    referrer: 'Dr. Sophie Greenberg',
    diagnosis_code: 'C43.9',
  },
  {
    fractions: 13,
    site: 'Campsie',
    physician: 'Dr Wong',
    date: '2023-08-23',
    diagnosis: 'prostate',
    fraction_dose: 1.24,
    adherence: true,
    technique: 'Stereotactic',
    linac: 'Machine 4',
    sim_to_treat: 5,
    priority: 'Standard',
    referrer: 'Dr. Julian Foster',
    diagnosis_code: 'C34.90',
  },
  {
    fractions: 23,
    site: 'Campsie',
    physician: 'Dr Smith',
    date: '2023-08-02',
    diagnosis: 'neuro',
    fraction_dose: 1.96,
    adherence: true,
    technique: 'IMRT',
    linac: 'Machine 4',
    sim_to_treat: 10,
    priority: 'Urgent',
    referrer: 'Dr. Michael Stern',
    diagnosis_code: 'C71.9',
  },
  {
    fractions: 11,
    site: 'Campsie',
    physician: 'Dr Smith',
    date: '2023-02-06',
    diagnosis: 'Unspecified',
    fraction_dose: 2.14,
    adherence: true,
    technique: 'Brachytherapy',
    linac: 'Machine 1',
    sim_to_treat: 8,
    priority: 'Urgent',
    referrer: 'Dr. Clara Thompson',
    diagnosis_code: 'C61',
  },
  {
    fractions: 16,
    site: 'Lakeside',
    physician: 'Dr Wong',
    date: '2023-09-24',
    diagnosis: 'skin',
    fraction_dose: 1.9,
    adherence: true,
    technique: 'Electrons',
    linac: 'Machine 1',
    sim_to_treat: 6,
    priority: 'Emergency',
    referrer: 'Dr. Jonathan Blake',
    diagnosis_code: 'C61',
  },
  {
    fractions: 25,
    site: 'Red Hills',
    physician: 'Dr Smith',
    date: '2023-03-05',
    diagnosis: 'breast',
    fraction_dose: 2.32,
    adherence: true,
    technique: 'VMAT',
    linac: 'Machine 2',
    sim_to_treat: 5,
    priority: 'Emergency',
    referrer: 'Dr. Alex Reynolds',
    diagnosis_code: 'C43.9',
  },
  {
    fractions: 12,
    site: 'Campsie',
    physician: 'Dr Wong',
    date: '2023-08-18',
    diagnosis: 'metastasis',
    fraction_dose: 3.27,
    adherence: true,
    technique: 'Electrons',
    linac: 'Machine 4',
    sim_to_treat: 5,
    priority: 'Standard',
    referrer: 'Dr. Rachel Nguyen',
    diagnosis_code: 'C61',
  },
  {
    fractions: 20,
    site: 'Lakeside',
    physician: 'Dr Wong',
    date: '2023-11-18',
    diagnosis: 'Unspecified',
    fraction_dose: 3.28,
    adherence: true,
    technique: 'IMRT',
    linac: 'Machine 3',
    sim_to_treat: 10,
    priority: 'Emergency',
    referrer: 'Dr. Clara Thompson',
    diagnosis_code: 'C43.9',
  },
  {
    fractions: 12,
    site: 'Rosebush',
    physician: 'Dr Adams',
    date: '2023-06-02',
    diagnosis: 'prostate',
    fraction_dose: 1.45,
    adherence: true,
    technique: 'VMAT',
    linac: 'Machine 4',
    sim_to_treat: 4,
    priority: 'Standard',
    referrer: 'Dr. Emily Hartman',
    diagnosis_code: 'C34.90',
  },
  {
    fractions: 15,
    site: 'Rosebush',
    physician: 'Dr Wong',
    date: '2023-12-13',
    diagnosis: 'prostate',
    fraction_dose: 2.55,
    adherence: true,
    technique: 'Conformal',
    linac: 'Machine 1',
    sim_to_treat: 1,
    priority: 'Emergency',
    referrer: 'Dr. Victor Hernandes',
    diagnosis_code: 'C71.9',
  },
  {
    fractions: 18,
    site: 'Lakeside',
    physician: 'Dr Lopez',
    date: '2023-08-19',
    diagnosis: 'Unspecified',
    fraction_dose: 1.89,
    adherence: true,
    technique: 'VMAT',
    linac: 'Machine 3',
    sim_to_treat: 2,
    priority: 'Urgent',
    referrer: 'Dr. Alex Reynolds',
    diagnosis_code: 'C71.9',
  },
  {
    fractions: 11,
    site: 'Campsie',
    physician: 'Dr Wong',
    date: '2023-04-19',
    diagnosis: 'breast',
    fraction_dose: 2.86,
    adherence: true,
    technique: 'Conformal',
    linac: 'Machine 3',
    sim_to_treat: 6,
    priority: 'Standard',
    referrer: 'Dr. Michael Stern',
    diagnosis_code: 'C61',
  },
  {
    fractions: 10,
    site: 'Campsie',
    physician: 'Dr Smith',
    date: '2023-11-16',
    diagnosis: 'prostate',
    fraction_dose: 3.05,
    adherence: true,
    technique: 'Brachytherapy',
    linac: 'Machine 4',
    sim_to_treat: 4,
    priority: 'Standard',
    referrer: 'Dr. Michael Stern',
    diagnosis_code: 'C43.9',
  },
  {
    fractions: 23,
    site: 'Lakeside',
    physician: 'Dr Wong',
    date: '2023-04-20',
    diagnosis: 'colorectal',
    fraction_dose: 2.2,
    adherence: true,
    technique: 'Stereotactic',
    linac: 'Machine 1',
    sim_to_treat: 4,
    priority: 'Urgent',
    referrer: 'Dr. Sophie Greenberg',
    diagnosis_code: 'C79.81',
  },
  {
    fractions: 12,
    site: 'Red Hills',
    physician: 'Dr Wong',
    date: '2023-04-04',
    diagnosis: 'colorectal',
    fraction_dose: 2.67,
    adherence: true,
    technique: 'IMRT',
    linac: 'Machine 1',
    sim_to_treat: 9,
    priority: 'Standard',
    referrer: 'Dr. Alex Reynolds',
    diagnosis_code: 'C20',
  },
  {
    fractions: 25,
    site: 'Lakeside',
    physician: 'Dr Adams',
    date: '2023-06-27',
    diagnosis: 'colorectal',
    fraction_dose: 1.53,
    adherence: true,
    technique: 'VMAT',
    linac: 'Machine 4',
    sim_to_treat: 10,
    priority: 'Emergency',
    referrer: 'Dr. Clara Thompson',
    diagnosis_code: 'C79.81',
  },
  {
    fractions: 14,
    site: 'Campsie',
    physician: 'Dr Wong',
    date: '2023-03-28',
    diagnosis: 'metastasis',
    fraction_dose: 2.63,
    adherence: true,
    technique: 'Electrons',
    linac: 'Machine 2',
    sim_to_treat: 10,
    priority: 'Standard',
    referrer: 'Dr. Rachel Nguyen',
    diagnosis_code: 'C71.9',
  },
  {
    fractions: 15,
    site: 'Campsie',
    physician: 'Dr Lopez',
    date: '2023-07-10',
    diagnosis: 'prostate',
    fraction_dose: 2.08,
    adherence: true,
    technique: 'Stereotactic',
    linac: 'Machine 3',
    sim_to_treat: 10,
    priority: 'Standard',
    referrer: 'Dr. Jonathan Blake',
    diagnosis_code: 'C20',
  },
  {
    fractions: 16,
    site: 'Rosebush',
    physician: 'Dr Smith',
    date: '2023-01-18',
    diagnosis: 'Unspecified',
    fraction_dose: 2.41,
    adherence: true,
    technique: 'VMAT',
    linac: 'Machine 3',
    sim_to_treat: 9,
    priority: 'Urgent',
    referrer: 'Dr. Victor Hernandes',
    diagnosis_code: 'C61',
  },
  {
    fractions: 16,
    site: 'Rosebush',
    physician: 'Dr Adams',
    date: '2023-10-10',
    diagnosis: 'colorectal',
    fraction_dose: 2.46,
    adherence: true,
    technique: 'Electrons',
    linac: 'Machine 4',
    sim_to_treat: 2,
    priority: 'Standard',
    referrer: 'Dr. Emily Hartman',
    diagnosis_code: 'C43.9',
  },
  {
    fractions: 17,
    site: 'Rosebush',
    physician: 'Dr Wong',
    date: '2023-03-27',
    diagnosis: 'prostate',
    fraction_dose: 1.49,
    adherence: true,
    technique: 'Conformal',
    linac: 'Machine 3',
    sim_to_treat: 7,
    priority: 'Urgent',
    referrer: 'Dr. Michael Stern',
    diagnosis_code: 'C34.90',
  },
  {
    fractions: 19,
    site: 'Lakeside',
    physician: 'Dr Wong',
    date: '2023-09-30',
    diagnosis: 'Unspecified',
    fraction_dose: 1.66,
    adherence: true,
    technique: 'VMAT',
    linac: 'Machine 1',
    sim_to_treat: 5,
    priority: 'Standard',
    referrer: 'Dr. Michael Stern',
    diagnosis_code: 'C71.9',
  },
  {
    fractions: 22,
    site: 'Campsie',
    physician: 'Dr Adams',
    date: '2023-10-18',
    diagnosis: 'breast',
    fraction_dose: 2.73,
    adherence: true,
    technique: 'Conformal',
    linac: 'Machine 4',
    sim_to_treat: 10,
    priority: 'Standard',
    referrer: 'Dr. Emily Hartman',
    diagnosis_code: 'C71.9',
  },
  {
    fractions: 20,
    site: 'Campsie',
    physician: 'Dr Lopez',
    date: '2023-03-08',
    diagnosis: 'breast',
    fraction_dose: 1.41,
    adherence: true,
    technique: 'IMRT',
    linac: 'Machine 4',
    sim_to_treat: 5,
    priority: 'Urgent',
    referrer: 'Dr. Julian Foster',
    diagnosis_code: 'C34.90',
  },
  {
    fractions: 11,
    site: 'Lakeside',
    physician: 'Dr Adams',
    date: '2023-06-12',
    diagnosis: 'neuro',
    fraction_dose: 1.44,
    adherence: true,
    technique: 'Stereotactic',
    linac: 'Machine 2',
    sim_to_treat: 2,
    priority: 'Standard',
    referrer: 'Dr. Michael Stern',
    diagnosis_code: 'C34.90',
  },
  {
    fractions: 23,
    site: 'Red Hills',
    physician: 'Dr Adams',
    date: '2023-08-14',
    diagnosis: 'breast',
    fraction_dose: 1.73,
    adherence: true,
    technique: 'IMRT',
    linac: 'Machine 4',
    sim_to_treat: 8,
    priority: 'Standard',
    referrer: 'Dr. Emily Hartman',
    diagnosis_code: 'C71.9',
  },
  {
    fractions: 12,
    site: 'Campsie',
    physician: 'Dr Wong',
    date: '2023-02-19',
    diagnosis: 'Unspecified',
    fraction_dose: 2.72,
    adherence: true,
    technique: 'Stereotactic',
    linac: 'Machine 4',
    sim_to_treat: 3,
    priority: 'Standard',
    referrer: 'Dr. Julian Foster',
    diagnosis_code: 'C34.90',
  },
  {
    fractions: 23,
    site: 'Red Hills',
    physician: 'Dr Smith',
    date: '2023-01-09',
    diagnosis: 'metastasis',
    fraction_dose: 3.17,
    adherence: true,
    technique: 'VMAT',
    linac: 'Machine 2',
    sim_to_treat: 6,
    priority: 'Urgent',
    referrer: 'Dr. Jonathan Blake',
    diagnosis_code: 'C43.9',
  },
  {
    fractions: 10,
    site: 'Red Hills',
    physician: 'Dr Adams',
    date: '2023-10-06',
    diagnosis: 'colorectal',
    fraction_dose: 1.58,
    adherence: true,
    technique: 'Stereotactic',
    linac: 'Machine 4',
    sim_to_treat: 4,
    priority: 'Emergency',
    referrer: 'Dr. Aisha Patel',
    diagnosis_code: 'C79.81',
  },
  {
    fractions: 12,
    site: 'Red Hills',
    physician: 'Dr Smith',
    date: '2023-11-19',
    diagnosis: 'neuro',
    fraction_dose: 1.86,
    adherence: true,
    technique: 'Conformal',
    linac: 'Machine 1',
    sim_to_treat: 7,
    priority: 'Urgent',
    referrer: 'Dr. Michael Stern',
    diagnosis_code: 'C71.9',
  },
  {
    fractions: 23,
    site: 'Campsie',
    physician: 'Dr Wong',
    date: '2023-11-15',
    diagnosis: 'neuro',
    fraction_dose: 1.21,
    adherence: true,
    technique: 'Conformal',
    linac: 'Machine 1',
    sim_to_treat: 5,
    priority: 'Standard',
    referrer: 'Dr. Rachel Nguyen',
    diagnosis_code: 'C20',
  },
  {
    fractions: 19,
    site: 'Rosebush',
    physician: 'Dr Wong',
    date: '2023-07-20',
    diagnosis: 'prostate',
    fraction_dose: 2.83,
    adherence: true,
    technique: 'VMAT',
    linac: 'Machine 4',
    sim_to_treat: 5,
    priority: 'Standard',
    referrer: 'Dr. Michael Stern',
    diagnosis_code: 'C20',
  },
  {
    fractions: 12,
    site: 'Red Hills',
    physician: 'Dr Smith',
    date: '2023-10-29',
    diagnosis: 'Unspecified',
    fraction_dose: 1.83,
    adherence: true,
    technique: 'Brachytherapy',
    linac: 'Machine 1',
    sim_to_treat: 6,
    priority: 'Urgent',
    referrer: 'Dr. Michael Stern',
    diagnosis_code: 'C34.90',
  },
  {
    fractions: 16,
    site: 'Lakeside',
    physician: 'Dr Lopez',
    date: '2023-09-09',
    diagnosis: 'metastasis',
    fraction_dose: 1.9,
    adherence: true,
    technique: 'Unspecified',
    linac: 'Machine 2',
    sim_to_treat: 5,
    priority: 'Emergency',
    referrer: 'Dr. Sophie Greenberg',
    diagnosis_code: 'C20',
  },
  {
    fractions: 15,
    site: 'Rosebush',
    physician: 'Dr Smith',
    date: '2023-01-12',
    diagnosis: 'skin',
    fraction_dose: 1.31,
    adherence: true,
    technique: 'Conformal',
    linac: 'Machine 4',
    sim_to_treat: 3,
    priority: 'Urgent',
    referrer: 'Dr. Aisha Patel',
    diagnosis_code: 'C20',
  },
  {
    fractions: 13,
    site: 'Campsie',
    physician: 'Dr Smith',
    date: '2023-02-07',
    diagnosis: 'neuro',
    fraction_dose: 2.56,
    adherence: true,
    technique: 'VMAT',
    linac: 'Machine 3',
    sim_to_treat: 9,
    priority: 'Urgent',
    referrer: 'Dr. Clara Thompson',
    diagnosis_code: 'C79.81',
  },
  {
    fractions: 21,
    site: 'Rosebush',
    physician: 'Dr Lopez',
    date: '2023-02-12',
    diagnosis: 'prostate',
    fraction_dose: 2.97,
    adherence: true,
    technique: 'Electrons',
    linac: 'Machine 4',
    sim_to_treat: 10,
    priority: 'Urgent',
    referrer: 'Dr. Michael Stern',
    diagnosis_code: 'C34.90',
  },
  {
    fractions: 15,
    site: 'Red Hills',
    physician: 'Dr Lopez',
    date: '2023-03-15',
    diagnosis: 'neuro',
    fraction_dose: 3.0,
    adherence: true,
    technique: 'Brachytherapy',
    linac: 'Machine 2',
    sim_to_treat: 10,
    priority: 'Emergency',
    referrer: 'Dr. Clara Thompson',
    diagnosis_code: 'C34.90',
  },
  {
    fractions: 13,
    site: 'Lakeside',
    physician: 'Dr Adams',
    date: '2023-03-07',
    diagnosis: 'metastasis',
    fraction_dose: 2.67,
    adherence: true,
    technique: 'Stereotactic',
    linac: 'Machine 2',
    sim_to_treat: 10,
    priority: 'Standard',
    referrer: 'Dr. Aisha Patel',
    diagnosis_code: 'C61',
  },
  {
    fractions: 21,
    site: 'Rosebush',
    physician: 'Dr Smith',
    date: '2023-09-28',
    diagnosis: 'metastasis',
    fraction_dose: 2.03,
    adherence: true,
    technique: 'Stereotactic',
    linac: 'Machine 2',
    sim_to_treat: 3,
    priority: 'Standard',
    referrer: 'Dr. Victor Hernandes',
    diagnosis_code: 'C71.9',
  },
  {
    fractions: 14,
    site: 'Lakeside',
    physician: 'Dr Smith',
    date: '2023-12-26',
    diagnosis: 'colorectal',
    fraction_dose: 2.19,
    adherence: true,
    technique: 'Brachytherapy',
    linac: 'Machine 2',
    sim_to_treat: 4,
    priority: 'Standard',
    referrer: 'Dr. Jonathan Blake',
    diagnosis_code: 'C34.90',
  },
  {
    fractions: 15,
    site: 'Campsie',
    physician: 'Dr Adams',
    date: '2023-02-08',
    diagnosis: 'metastasis',
    fraction_dose: 2.38,
    adherence: true,
    technique: 'Unspecified',
    linac: 'Machine 3',
    sim_to_treat: 9,
    priority: 'Emergency',
    referrer: 'Dr. Emily Hartman',
    diagnosis_code: 'C61',
  },
  {
    fractions: 25,
    site: 'Red Hills',
    physician: 'Dr Lopez',
    date: '2023-02-26',
    diagnosis: 'breast',
    fraction_dose: 2.24,
    adherence: true,
    technique: 'VMAT',
    linac: 'Machine 2',
    sim_to_treat: 10,
    priority: 'Urgent',
    referrer: 'Dr. Rachel Nguyen',
    diagnosis_code: 'C34.90',
  },
  {
    fractions: 12,
    site: 'Lakeside',
    physician: 'Dr Smith',
    date: '2023-07-16',
    diagnosis: 'Unspecified',
    fraction_dose: 1.94,
    adherence: true,
    technique: 'Electrons',
    linac: 'Machine 3',
    sim_to_treat: 8,
    priority: 'Emergency',
    referrer: 'Dr. Clara Thompson',
    diagnosis_code: 'C34.90',
  },
  {
    fractions: 13,
    site: 'Rosebush',
    physician: 'Dr Adams',
    date: '2023-12-16',
    diagnosis: 'skin',
    fraction_dose: 2.58,
    adherence: true,
    technique: 'Conformal',
    linac: 'Machine 4',
    sim_to_treat: 6,
    priority: 'Emergency',
    referrer: 'Dr. Sophie Greenberg',
    diagnosis_code: 'C34.90',
  },
  {
    fractions: 22,
    site: 'Rosebush',
    physician: 'Dr Lopez',
    date: '2023-05-29',
    diagnosis: 'metastasis',
    fraction_dose: 1.5,
    adherence: true,
    technique: 'Unspecified',
    linac: 'Machine 4',
    sim_to_treat: 6,
    priority: 'Emergency',
    referrer: 'Dr. Alex Reynolds',
    diagnosis_code: 'C20',
  },
  {
    fractions: 12,
    site: 'Rosebush',
    physician: 'Dr Lopez',
    date: '2023-02-11',
    diagnosis: 'Unspecified',
    fraction_dose: 2.83,
    adherence: true,
    technique: 'VMAT',
    linac: 'Machine 3',
    sim_to_treat: 9,
    priority: 'Standard',
    referrer: 'Dr. Michael Stern',
    diagnosis_code: 'C71.9',
  },
  {
    fractions: 20,
    site: 'Lakeside',
    physician: 'Dr Smith',
    date: '2023-07-02',
    diagnosis: 'breast',
    fraction_dose: 1.32,
    adherence: true,
    technique: 'Stereotactic',
    linac: 'Machine 1',
    sim_to_treat: 2,
    priority: 'Urgent',
    referrer: 'Dr. Michael Stern',
    diagnosis_code: 'C79.81',
  },
  {
    fractions: 25,
    site: 'Lakeside',
    physician: 'Dr Smith',
    date: '2023-12-17',
    diagnosis: 'skin',
    fraction_dose: 2.47,
    adherence: true,
    technique: 'Electrons',
    linac: 'Machine 3',
    sim_to_treat: 10,
    priority: 'Urgent',
    referrer: 'Dr. Sophie Greenberg',
    diagnosis_code: 'C79.81',
  },
  {
    fractions: 10,
    site: 'Red Hills',
    physician: 'Dr Adams',
    date: '2023-02-21',
    diagnosis: 'prostate',
    fraction_dose: 1.79,
    adherence: true,
    technique: 'Stereotactic',
    linac: 'Machine 3',
    sim_to_treat: 1,
    priority: 'Urgent',
    referrer: 'Dr. Clara Thompson',
    diagnosis_code: 'C34.90',
  },
  {
    fractions: 18,
    site: 'Red Hills',
    physician: 'Dr Lopez',
    date: '2023-09-09',
    diagnosis: 'skin',
    fraction_dose: 1.78,
    adherence: true,
    technique: 'Brachytherapy',
    linac: 'Machine 1',
    sim_to_treat: 10,
    priority: 'Emergency',
    referrer: 'Dr. Julian Foster',
    diagnosis_code: 'C34.90',
  },
  {
    fractions: 12,
    site: 'Campsie',
    physician: 'Dr Adams',
    date: '2023-02-28',
    diagnosis: 'colorectal',
    fraction_dose: 3.28,
    adherence: true,
    technique: 'VMAT',
    linac: 'Machine 2',
    sim_to_treat: 4,
    priority: 'Standard',
    referrer: 'Dr. Clara Thompson',
    diagnosis_code: 'C79.81',
  },
  {
    fractions: 10,
    site: 'Red Hills',
    physician: 'Dr Adams',
    date: '2023-04-02',
    diagnosis: 'neuro',
    fraction_dose: 3.14,
    adherence: true,
    technique: 'VMAT',
    linac: 'Machine 4',
    sim_to_treat: 9,
    priority: 'Urgent',
    referrer: 'Dr. Aisha Patel',
    diagnosis_code: 'C43.9',
  },
  {
    fractions: 12,
    site: 'Rosebush',
    physician: 'Dr Adams',
    date: '2023-06-20',
    diagnosis: 'Unspecified',
    fraction_dose: 1.27,
    adherence: true,
    technique: 'IMRT',
    linac: 'Machine 3',
    sim_to_treat: 8,
    priority: 'Emergency',
    referrer: 'Dr. Rachel Nguyen',
    diagnosis_code: 'C43.9',
  },
  {
    fractions: 10,
    site: 'Rosebush',
    physician: 'Dr Lopez',
    date: '2023-01-20',
    diagnosis: 'Unspecified',
    fraction_dose: 2.44,
    adherence: true,
    technique: 'Brachytherapy',
    linac: 'Machine 2',
    sim_to_treat: 7,
    priority: 'Urgent',
    referrer: 'Dr. Sophie Greenberg',
    diagnosis_code: 'C61',
  },
  {
    fractions: 14,
    site: 'Rosebush',
    physician: 'Dr Lopez',
    date: '2023-06-03',
    diagnosis: 'prostate',
    fraction_dose: 2.25,
    adherence: true,
    technique: 'Electrons',
    linac: 'Machine 4',
    sim_to_treat: 2,
    priority: 'Urgent',
    referrer: 'Dr. Emily Hartman',
    diagnosis_code: 'C43.9',
  },
  {
    fractions: 21,
    site: 'Rosebush',
    physician: 'Dr Smith',
    date: '2023-08-24',
    diagnosis: 'breast',
    fraction_dose: 2.74,
    adherence: true,
    technique: 'VMAT',
    linac: 'Machine 4',
    sim_to_treat: 4,
    priority: 'Emergency',
    referrer: 'Dr. Alex Reynolds',
    diagnosis_code: 'C20',
  },
  {
    fractions: 23,
    site: 'Lakeside',
    physician: 'Dr Adams',
    date: '2023-01-13',
    diagnosis: 'metastasis',
    fraction_dose: 3.03,
    adherence: true,
    technique: 'Brachytherapy',
    linac: 'Machine 3',
    sim_to_treat: 1,
    priority: 'Urgent',
    referrer: 'Dr. Emily Hartman',
    diagnosis_code: 'C79.81',
  },
  {
    fractions: 13,
    site: 'Rosebush',
    physician: 'Dr Adams',
    date: '2023-11-23',
    diagnosis: 'breast',
    fraction_dose: 2.88,
    adherence: true,
    technique: 'Unspecified',
    linac: 'Machine 4',
    sim_to_treat: 3,
    priority: 'Urgent',
    referrer: 'Dr. Rachel Nguyen',
    diagnosis_code: 'C20',
  },
  {
    fractions: 13,
    site: 'Rosebush',
    physician: 'Dr Lopez',
    date: '2023-08-30',
    diagnosis: 'skin',
    fraction_dose: 3.32,
    adherence: true,
    technique: 'VMAT',
    linac: 'Machine 4',
    sim_to_treat: 1,
    priority: 'Standard',
    referrer: 'Dr. Jonathan Blake',
    diagnosis_code: 'C79.81',
  },
  {
    fractions: 15,
    site: 'Red Hills',
    physician: 'Dr Lopez',
    date: '2023-08-17',
    diagnosis: 'skin',
    fraction_dose: 1.8,
    adherence: true,
    technique: 'Electrons',
    linac: 'Machine 3',
    sim_to_treat: 3,
    priority: 'Emergency',
    referrer: 'Dr. Emily Hartman',
    diagnosis_code: 'C79.81',
  },
  {
    fractions: 13,
    site: 'Red Hills',
    physician: 'Dr Adams',
    date: '2023-12-21',
    diagnosis: 'metastasis',
    fraction_dose: 2.11,
    adherence: true,
    technique: 'IMRT',
    linac: 'Machine 3',
    sim_to_treat: 1,
    priority: 'Standard',
    referrer: 'Dr. Alex Reynolds',
    diagnosis_code: 'C43.9',
  },
  {
    fractions: 15,
    site: 'Lakeside',
    physician: 'Dr Lopez',
    date: '2023-11-10',
    diagnosis: 'metastasis',
    fraction_dose: 3.36,
    adherence: true,
    technique: 'Electrons',
    linac: 'Machine 4',
    sim_to_treat: 9,
    priority: 'Urgent',
    referrer: 'Dr. Sophie Greenberg',
    diagnosis_code: 'C71.9',
  },
  {
    fractions: 22,
    site: 'Red Hills',
    physician: 'Dr Adams',
    date: '2023-08-29',
    diagnosis: 'metastasis',
    fraction_dose: 2.1,
    adherence: true,
    technique: 'Electrons',
    linac: 'Machine 3',
    sim_to_treat: 7,
    priority: 'Urgent',
    referrer: 'Dr. Emily Hartman',
    diagnosis_code: 'C79.81',
  },
  {
    fractions: 25,
    site: 'Campsie',
    physician: 'Dr Wong',
    date: '2023-03-11',
    diagnosis: 'Unspecified',
    fraction_dose: 2.63,
    adherence: true,
    technique: 'IMRT',
    linac: 'Machine 2',
    sim_to_treat: 5,
    priority: 'Urgent',
    referrer: 'Dr. Michael Stern',
    diagnosis_code: 'C34.90',
  },
  {
    fractions: 21,
    site: 'Lakeside',
    physician: 'Dr Lopez',
    date: '2023-08-13',
    diagnosis: 'metastasis',
    fraction_dose: 1.76,
    adherence: true,
    technique: 'IMRT',
    linac: 'Machine 2',
    sim_to_treat: 5,
    priority: 'Emergency',
    referrer: 'Dr. Clara Thompson',
    diagnosis_code: 'C71.9',
  },
  {
    fractions: 19,
    site: 'Lakeside',
    physician: 'Dr Lopez',
    date: '2023-09-13',
    diagnosis: 'prostate',
    fraction_dose: 2.46,
    adherence: true,
    technique: 'IMRT',
    linac: 'Machine 3',
    sim_to_treat: 3,
    priority: 'Standard',
    referrer: 'Dr. Alex Reynolds',
    diagnosis_code: 'C71.9',
  },
  {
    fractions: 11,
    site: 'Lakeside',
    physician: 'Dr Wong',
    date: '2023-01-13',
    diagnosis: 'skin',
    fraction_dose: 2.84,
    adherence: true,
    technique: 'Brachytherapy',
    linac: 'Machine 3',
    sim_to_treat: 8,
    priority: 'Emergency',
    referrer: 'Dr. Alex Reynolds',
    diagnosis_code: 'C43.9',
  },
  {
    fractions: 25,
    site: 'Rosebush',
    physician: 'Dr Wong',
    date: '2023-03-02',
    diagnosis: 'Unspecified',
    fraction_dose: 3.23,
    adherence: true,
    technique: 'Conformal',
    linac: 'Machine 1',
    sim_to_treat: 10,
    priority: 'Emergency',
    referrer: 'Dr. Sophie Greenberg',
    diagnosis_code: 'C34.90',
  },
  {
    fractions: 22,
    site: 'Campsie',
    physician: 'Dr Lopez',
    date: '2023-08-03',
    diagnosis: 'neuro',
    fraction_dose: 1.33,
    adherence: true,
    technique: 'VMAT',
    linac: 'Machine 2',
    sim_to_treat: 4,
    priority: 'Emergency',
    referrer: 'Dr. Aisha Patel',
    diagnosis_code: 'C20',
  },
  {
    fractions: 24,
    site: 'Rosebush',
    physician: 'Dr Lopez',
    date: '2023-02-28',
    diagnosis: 'neuro',
    fraction_dose: 2.56,
    adherence: true,
    technique: 'Unspecified',
    linac: 'Machine 4',
    sim_to_treat: 10,
    priority: 'Urgent',
    referrer: 'Dr. Clara Thompson',
    diagnosis_code: 'C79.81',
  },
  {
    fractions: 11,
    site: 'Campsie',
    physician: 'Dr Lopez',
    date: '2023-08-16',
    diagnosis: 'breast',
    fraction_dose: 2.68,
    adherence: true,
    technique: 'Unspecified',
    linac: 'Machine 4',
    sim_to_treat: 6,
    priority: 'Standard',
    referrer: 'Dr. Michael Stern',
    diagnosis_code: 'C61',
  },
  {
    fractions: 19,
    site: 'Campsie',
    physician: 'Dr Wong',
    date: '2023-06-15',
    diagnosis: 'skin',
    fraction_dose: 1.5,
    adherence: true,
    technique: 'Unspecified',
    linac: 'Machine 2',
    sim_to_treat: 7,
    priority: 'Urgent',
    referrer: 'Dr. Michael Stern',
    diagnosis_code: 'C61',
  },
  {
    fractions: 18,
    site: 'Rosebush',
    physician: 'Dr Lopez',
    date: '2023-03-28',
    diagnosis: 'breast',
    fraction_dose: 1.84,
    adherence: true,
    technique: 'Unspecified',
    linac: 'Machine 2',
    sim_to_treat: 5,
    priority: 'Standard',
    referrer: 'Dr. Aisha Patel',
    diagnosis_code: 'C61',
  },
  {
    fractions: 21,
    site: 'Lakeside',
    physician: 'Dr Lopez',
    date: '2023-01-11',
    diagnosis: 'Unspecified',
    fraction_dose: 2.77,
    adherence: true,
    technique: 'Electrons',
    linac: 'Machine 1',
    sim_to_treat: 1,
    priority: 'Urgent',
    referrer: 'Dr. Rachel Nguyen',
    diagnosis_code: 'C61',
  },
  {
    fractions: 10,
    site: 'Rosebush',
    physician: 'Dr Wong',
    date: '2023-10-09',
    diagnosis: 'metastasis',
    fraction_dose: 2.26,
    adherence: true,
    technique: 'Brachytherapy',
    linac: 'Machine 2',
    sim_to_treat: 4,
    priority: 'Standard',
    referrer: 'Dr. Victor Hernandes',
    diagnosis_code: 'C34.90',
  },
  {
    fractions: 11,
    site: 'Lakeside',
    physician: 'Dr Lopez',
    date: '2023-02-14',
    diagnosis: 'metastasis',
    fraction_dose: 1.4,
    adherence: true,
    technique: 'VMAT',
    linac: 'Machine 2',
    sim_to_treat: 6,
    priority: 'Urgent',
    referrer: 'Dr. Rachel Nguyen',
    diagnosis_code: 'C79.81',
  },
  {
    fractions: 15,
    site: 'Rosebush',
    physician: 'Dr Smith',
    date: '2023-05-17',
    diagnosis: 'skin',
    fraction_dose: 1.58,
    adherence: true,
    technique: 'Unspecified',
    linac: 'Machine 1',
    sim_to_treat: 1,
    priority: 'Emergency',
    referrer: 'Dr. Julian Foster',
    diagnosis_code: 'C43.9',
  },
  {
    fractions: 25,
    site: 'Campsie',
    physician: 'Dr Smith',
    date: '2023-06-21',
    diagnosis: 'Unspecified',
    fraction_dose: 2.01,
    adherence: true,
    technique: 'IMRT',
    linac: 'Machine 3',
    sim_to_treat: 4,
    priority: 'Urgent',
    referrer: 'Dr. Victor Hernandes',
    diagnosis_code: 'C34.90',
  },
  {
    fractions: 15,
    site: 'Campsie',
    physician: 'Dr Lopez',
    date: '2023-02-28',
    diagnosis: 'neuro',
    fraction_dose: 1.78,
    adherence: true,
    technique: 'Stereotactic',
    linac: 'Machine 3',
    sim_to_treat: 2,
    priority: 'Emergency',
    referrer: 'Dr. Michael Stern',
    diagnosis_code: 'C71.9',
  },
  {
    fractions: 18,
    site: 'Lakeside',
    physician: 'Dr Lopez',
    date: '2023-10-05',
    diagnosis: 'Unspecified',
    fraction_dose: 2.65,
    adherence: true,
    technique: 'Brachytherapy',
    linac: 'Machine 2',
    sim_to_treat: 8,
    priority: 'Urgent',
    referrer: 'Dr. Alex Reynolds',
    diagnosis_code: 'C43.9',
  },
  {
    fractions: 16,
    site: 'Lakeside',
    physician: 'Dr Smith',
    date: '2023-11-15',
    diagnosis: 'prostate',
    fraction_dose: 1.93,
    adherence: true,
    technique: 'Unspecified',
    linac: 'Machine 4',
    sim_to_treat: 1,
    priority: 'Standard',
    referrer: 'Dr. Sophie Greenberg',
    diagnosis_code: 'C20',
  },
  {
    fractions: 10,
    site: 'Campsie',
    physician: 'Dr Adams',
    date: '2023-08-08',
    diagnosis: 'metastasis',
    fraction_dose: 3.12,
    adherence: true,
    technique: 'Electrons',
    linac: 'Machine 1',
    sim_to_treat: 4,
    priority: 'Emergency',
    referrer: 'Dr. Clara Thompson',
    diagnosis_code: 'C79.81',
  },
  {
    fractions: 22,
    site: 'Red Hills',
    physician: 'Dr Lopez',
    date: '2023-01-25',
    diagnosis: 'Unspecified',
    fraction_dose: 1.72,
    adherence: true,
    technique: 'Stereotactic',
    linac: 'Machine 3',
    sim_to_treat: 4,
    priority: 'Standard',
    referrer: 'Dr. Victor Hernandes',
    diagnosis_code: 'C34.90',
  },
  {
    fractions: 23,
    site: 'Lakeside',
    physician: 'Dr Wong',
    date: '2023-10-18',
    diagnosis: 'Unspecified',
    fraction_dose: 2.07,
    adherence: true,
    technique: 'Unspecified',
    linac: 'Machine 1',
    sim_to_treat: 7,
    priority: 'Standard',
    referrer: 'Dr. Clara Thompson',
    diagnosis_code: 'C71.9',
  },
  {
    fractions: 18,
    site: 'Rosebush',
    physician: 'Dr Lopez',
    date: '2023-04-19',
    diagnosis: 'prostate',
    fraction_dose: 2.17,
    adherence: true,
    technique: 'IMRT',
    linac: 'Machine 2',
    sim_to_treat: 8,
    priority: 'Standard',
    referrer: 'Dr. Jonathan Blake',
    diagnosis_code: 'C61',
  },
  {
    fractions: 25,
    site: 'Rosebush',
    physician: 'Dr Adams',
    date: '2023-10-23',
    diagnosis: 'metastasis',
    fraction_dose: 3.32,
    adherence: true,
    technique: 'Conformal',
    linac: 'Machine 2',
    sim_to_treat: 6,
    priority: 'Emergency',
    referrer: 'Dr. Clara Thompson',
    diagnosis_code: 'C34.90',
  },
  {
    fractions: 18,
    site: 'Rosebush',
    physician: 'Dr Wong',
    date: '2023-12-01',
    diagnosis: 'prostate',
    fraction_dose: 3.29,
    adherence: true,
    technique: 'Brachytherapy',
    linac: 'Machine 2',
    sim_to_treat: 2,
    priority: 'Emergency',
    referrer: 'Dr. Clara Thompson',
    diagnosis_code: 'C71.9',
  },
  {
    fractions: 24,
    site: 'Red Hills',
    physician: 'Dr Smith',
    date: '2023-10-01',
    diagnosis: 'neuro',
    fraction_dose: 1.69,
    adherence: true,
    technique: 'Conformal',
    linac: 'Machine 2',
    sim_to_treat: 2,
    priority: 'Standard',
    referrer: 'Dr. Jonathan Blake',
    diagnosis_code: 'C34.90',
  },
  {
    fractions: 17,
    site: 'Lakeside',
    physician: 'Dr Wong',
    date: '2023-06-01',
    diagnosis: 'skin',
    fraction_dose: 3.05,
    adherence: true,
    technique: 'Stereotactic',
    linac: 'Machine 3',
    sim_to_treat: 8,
    priority: 'Emergency',
    referrer: 'Dr. Aisha Patel',
    diagnosis_code: 'C20',
  },
  {
    fractions: 16,
    site: 'Rosebush',
    physician: 'Dr Smith',
    date: '2023-03-22',
    diagnosis: 'prostate',
    fraction_dose: 1.67,
    adherence: true,
    technique: 'Unspecified',
    linac: 'Machine 4',
    sim_to_treat: 7,
    priority: 'Urgent',
    referrer: 'Dr. Jonathan Blake',
    diagnosis_code: 'C20',
  },
  {
    fractions: 12,
    site: 'Red Hills',
    physician: 'Dr Lopez',
    date: '2023-06-15',
    diagnosis: 'neuro',
    fraction_dose: 1.34,
    adherence: true,
    technique: 'Conformal',
    linac: 'Machine 3',
    sim_to_treat: 5,
    priority: 'Urgent',
    referrer: 'Dr. Jonathan Blake',
    diagnosis_code: 'C34.90',
  },
  {
    fractions: 21,
    site: 'Red Hills',
    physician: 'Dr Adams',
    date: '2023-09-02',
    diagnosis: 'prostate',
    fraction_dose: 2.9,
    adherence: true,
    technique: 'Stereotactic',
    linac: 'Machine 2',
    sim_to_treat: 2,
    priority: 'Emergency',
    referrer: 'Dr. Clara Thompson',
    diagnosis_code: 'C61',
  },
  {
    fractions: 22,
    site: 'Rosebush',
    physician: 'Dr Adams',
    date: '2023-11-09',
    diagnosis: 'Unspecified',
    fraction_dose: 2.54,
    adherence: true,
    technique: 'Electrons',
    linac: 'Machine 4',
    sim_to_treat: 1,
    priority: 'Standard',
    referrer: 'Dr. Rachel Nguyen',
    diagnosis_code: 'C61',
  },
  {
    fractions: 15,
    site: 'Lakeside',
    physician: 'Dr Smith',
    date: '2023-08-25',
    diagnosis: 'neuro',
    fraction_dose: 1.51,
    adherence: true,
    technique: 'Brachytherapy',
    linac: 'Machine 2',
    sim_to_treat: 4,
    priority: 'Urgent',
    referrer: 'Dr. Alex Reynolds',
    diagnosis_code: 'C61',
  },
  {
    fractions: 10,
    site: 'Red Hills',
    physician: 'Dr Lopez',
    date: '2023-04-26',
    diagnosis: 'metastasis',
    fraction_dose: 1.79,
    adherence: true,
    technique: 'Unspecified',
    linac: 'Machine 1',
    sim_to_treat: 5,
    priority: 'Standard',
    referrer: 'Dr. Julian Foster',
    diagnosis_code: 'C79.81',
  },
  {
    fractions: 14,
    site: 'Lakeside',
    physician: 'Dr Adams',
    date: '2023-08-16',
    diagnosis: 'prostate',
    fraction_dose: 2.6,
    adherence: true,
    technique: 'Electrons',
    linac: 'Machine 2',
    sim_to_treat: 3,
    priority: 'Standard',
    referrer: 'Dr. Victor Hernandes',
    diagnosis_code: 'C71.9',
  },
  {
    fractions: 21,
    site: 'Red Hills',
    physician: 'Dr Lopez',
    date: '2023-08-30',
    diagnosis: 'neuro',
    fraction_dose: 2.61,
    adherence: true,
    technique: 'Conformal',
    linac: 'Machine 1',
    sim_to_treat: 8,
    priority: 'Emergency',
    referrer: 'Dr. Sophie Greenberg',
    diagnosis_code: 'C43.9',
  },
  {
    fractions: 16,
    site: 'Lakeside',
    physician: 'Dr Smith',
    date: '2023-03-04',
    diagnosis: 'metastasis',
    fraction_dose: 1.32,
    adherence: true,
    technique: 'Conformal',
    linac: 'Machine 1',
    sim_to_treat: 6,
    priority: 'Emergency',
    referrer: 'Dr. Clara Thompson',
    diagnosis_code: 'C61',
  },
  {
    fractions: 20,
    site: 'Rosebush',
    physician: 'Dr Lopez',
    date: '2023-09-05',
    diagnosis: 'colorectal',
    fraction_dose: 1.47,
    adherence: true,
    technique: 'VMAT',
    linac: 'Machine 2',
    sim_to_treat: 1,
    priority: 'Emergency',
    referrer: 'Dr. Sophie Greenberg',
    diagnosis_code: 'C20',
  },
  {
    fractions: 15,
    site: 'Red Hills',
    physician: 'Dr Lopez',
    date: '2023-03-22',
    diagnosis: 'breast',
    fraction_dose: 1.23,
    adherence: true,
    technique: 'Brachytherapy',
    linac: 'Machine 2',
    sim_to_treat: 7,
    priority: 'Standard',
    referrer: 'Dr. Rachel Nguyen',
    diagnosis_code: 'C43.9',
  },
  {
    fractions: 11,
    site: 'Lakeside',
    physician: 'Dr Lopez',
    date: '2023-05-26',
    diagnosis: 'metastasis',
    fraction_dose: 3.37,
    adherence: true,
    technique: 'VMAT',
    linac: 'Machine 1',
    sim_to_treat: 1,
    priority: 'Standard',
    referrer: 'Dr. Rachel Nguyen',
    diagnosis_code: 'C71.9',
  },
  {
    fractions: 19,
    site: 'Campsie',
    physician: 'Dr Smith',
    date: '2023-02-07',
    diagnosis: 'Unspecified',
    fraction_dose: 1.37,
    adherence: true,
    technique: 'Conformal',
    linac: 'Machine 1',
    sim_to_treat: 10,
    priority: 'Emergency',
    referrer: 'Dr. Aisha Patel',
    diagnosis_code: 'C34.90',
  },
  {
    fractions: 21,
    site: 'Campsie',
    physician: 'Dr Lopez',
    date: '2023-09-21',
    diagnosis: 'breast',
    fraction_dose: 1.85,
    adherence: true,
    technique: 'Brachytherapy',
    linac: 'Machine 2',
    sim_to_treat: 3,
    priority: 'Emergency',
    referrer: 'Dr. Jonathan Blake',
    diagnosis_code: 'C43.9',
  },
  {
    fractions: 12,
    site: 'Lakeside',
    physician: 'Dr Lopez',
    date: '2023-10-08',
    diagnosis: 'skin',
    fraction_dose: 2.11,
    adherence: true,
    technique: 'IMRT',
    linac: 'Machine 1',
    sim_to_treat: 8,
    priority: 'Urgent',
    referrer: 'Dr. Sophie Greenberg',
    diagnosis_code: 'C20',
  },
  {
    fractions: 24,
    site: 'Lakeside',
    physician: 'Dr Adams',
    date: '2023-08-22',
    diagnosis: 'neuro',
    fraction_dose: 2.78,
    adherence: false,
    technique: 'Unspecified',
    linac: 'Machine 1',
    sim_to_treat: 2,
    priority: 'Urgent',
    referrer: 'Dr. Emily Hartman',
    diagnosis_code: 'C71.9',
  },
  {
    fractions: 23,
    site: 'Red Hills',
    physician: 'Dr Lopez',
    date: '2023-03-31',
    diagnosis: 'prostate',
    fraction_dose: 1.49,
    adherence: true,
    technique: 'VMAT',
    linac: 'Machine 4',
    sim_to_treat: 4,
    priority: 'Emergency',
    referrer: 'Dr. Julian Foster',
    diagnosis_code: 'C34.90',
  },
  {
    fractions: 17,
    site: 'Red Hills',
    physician: 'Dr Wong',
    date: '2023-12-22',
    diagnosis: 'colorectal',
    fraction_dose: 1.8,
    adherence: true,
    technique: 'VMAT',
    linac: 'Machine 1',
    sim_to_treat: 4,
    priority: 'Urgent',
    referrer: 'Dr. Julian Foster',
    diagnosis_code: 'C79.81',
  },
  {
    fractions: 13,
    site: 'Red Hills',
    physician: 'Dr Smith',
    date: '2023-08-02',
    diagnosis: 'skin',
    fraction_dose: 2.64,
    adherence: true,
    technique: 'Electrons',
    linac: 'Machine 1',
    sim_to_treat: 7,
    priority: 'Emergency',
    referrer: 'Dr. Rachel Nguyen',
    diagnosis_code: 'C61',
  },
  {
    fractions: 19,
    site: 'Red Hills',
    physician: 'Dr Lopez',
    date: '2023-10-02',
    diagnosis: 'skin',
    fraction_dose: 3.34,
    adherence: true,
    technique: 'Stereotactic',
    linac: 'Machine 1',
    sim_to_treat: 1,
    priority: 'Standard',
    referrer: 'Dr. Emily Hartman',
    diagnosis_code: 'C61',
  },
  {
    fractions: 17,
    site: 'Campsie',
    physician: 'Dr Adams',
    date: '2023-09-02',
    diagnosis: 'skin',
    fraction_dose: 1.5,
    adherence: false,
    technique: 'VMAT',
    linac: 'Machine 1',
    sim_to_treat: 2,
    priority: 'Urgent',
    referrer: 'Dr. Emily Hartman',
    diagnosis_code: 'C20',
  },
  {
    fractions: 22,
    site: 'Lakeside',
    physician: 'Dr Smith',
    date: '2023-01-28',
    diagnosis: 'neuro',
    fraction_dose: 2.51,
    adherence: true,
    technique: 'VMAT',
    linac: 'Machine 3',
    sim_to_treat: 5,
    priority: 'Emergency',
    referrer: 'Dr. Michael Stern',
    diagnosis_code: 'C43.9',
  },
  {
    fractions: 23,
    site: 'Red Hills',
    physician: 'Dr Adams',
    date: '2023-09-06',
    diagnosis: 'metastasis',
    fraction_dose: 2.67,
    adherence: true,
    technique: 'Conformal',
    linac: 'Machine 3',
    sim_to_treat: 1,
    priority: 'Standard',
    referrer: 'Dr. Clara Thompson',
    diagnosis_code: 'C79.81',
  },
  {
    fractions: 14,
    site: 'Campsie',
    physician: 'Dr Adams',
    date: '2023-12-24',
    diagnosis: 'neuro',
    fraction_dose: 1.65,
    adherence: true,
    technique: 'Conformal',
    linac: 'Machine 4',
    sim_to_treat: 6,
    priority: 'Urgent',
    referrer: 'Dr. Julian Foster',
    diagnosis_code: 'C79.81',
  },
  {
    fractions: 11,
    site: 'Rosebush',
    physician: 'Dr Smith',
    date: '2023-10-31',
    diagnosis: 'Unspecified',
    fraction_dose: 2.66,
    adherence: true,
    technique: 'Conformal',
    linac: 'Machine 1',
    sim_to_treat: 3,
    priority: 'Emergency',
    referrer: 'Dr. Michael Stern',
    diagnosis_code: 'C61',
  },
  {
    fractions: 21,
    site: 'Lakeside',
    physician: 'Dr Smith',
    date: '2023-01-09',
    diagnosis: 'Unspecified',
    fraction_dose: 2.57,
    adherence: true,
    technique: 'Electrons',
    linac: 'Machine 2',
    sim_to_treat: 10,
    priority: 'Standard',
    referrer: 'Dr. Victor Hernandes',
    diagnosis_code: 'C34.90',
  },
  {
    fractions: 19,
    site: 'Lakeside',
    physician: 'Dr Lopez',
    date: '2023-08-28',
    diagnosis: 'neuro',
    fraction_dose: 2.01,
    adherence: true,
    technique: 'Unspecified',
    linac: 'Machine 4',
    sim_to_treat: 4,
    priority: 'Emergency',
    referrer: 'Dr. Michael Stern',
    diagnosis_code: 'C20',
  },
  {
    fractions: 10,
    site: 'Campsie',
    physician: 'Dr Adams',
    date: '2023-10-17',
    diagnosis: 'Unspecified',
    fraction_dose: 1.49,
    adherence: true,
    technique: 'Unspecified',
    linac: 'Machine 2',
    sim_to_treat: 3,
    priority: 'Emergency',
    referrer: 'Dr. Sophie Greenberg',
    diagnosis_code: 'C20',
  },
  {
    fractions: 24,
    site: 'Red Hills',
    physician: 'Dr Wong',
    date: '2023-03-18',
    diagnosis: 'colorectal',
    fraction_dose: 3.31,
    adherence: true,
    technique: 'VMAT',
    linac: 'Machine 2',
    sim_to_treat: 8,
    priority: 'Urgent',
    referrer: 'Dr. Aisha Patel',
    diagnosis_code: 'C20',
  },
  {
    fractions: 13,
    site: 'Lakeside',
    physician: 'Dr Lopez',
    date: '2023-06-12',
    diagnosis: 'Unspecified',
    fraction_dose: 2.13,
    adherence: true,
    technique: 'Stereotactic',
    linac: 'Machine 4',
    sim_to_treat: 7,
    priority: 'Emergency',
    referrer: 'Dr. Alex Reynolds',
    diagnosis_code: 'C71.9',
  },
  {
    fractions: 19,
    site: 'Rosebush',
    physician: 'Dr Lopez',
    date: '2023-03-06',
    diagnosis: 'metastasis',
    fraction_dose: 1.23,
    adherence: true,
    technique: 'Brachytherapy',
    linac: 'Machine 3',
    sim_to_treat: 9,
    priority: 'Standard',
    referrer: 'Dr. Sophie Greenberg',
    diagnosis_code: 'C61',
  },
  {
    fractions: 10,
    site: 'Rosebush',
    physician: 'Dr Smith',
    date: '2023-07-04',
    diagnosis: 'metastasis',
    fraction_dose: 1.89,
    adherence: true,
    technique: 'Stereotactic',
    linac: 'Machine 1',
    sim_to_treat: 6,
    priority: 'Standard',
    referrer: 'Dr. Sophie Greenberg',
    diagnosis_code: 'C79.81',
  },
  {
    fractions: 19,
    site: 'Rosebush',
    physician: 'Dr Adams',
    date: '2023-12-11',
    diagnosis: 'neuro',
    fraction_dose: 1.78,
    adherence: true,
    technique: 'Brachytherapy',
    linac: 'Machine 4',
    sim_to_treat: 4,
    priority: 'Standard',
    referrer: 'Dr. Aisha Patel',
    diagnosis_code: 'C43.9',
  },
  {
    fractions: 17,
    site: 'Red Hills',
    physician: 'Dr Lopez',
    date: '2023-04-18',
    diagnosis: 'breast',
    fraction_dose: 1.49,
    adherence: true,
    technique: 'Conformal',
    linac: 'Machine 2',
    sim_to_treat: 1,
    priority: 'Emergency',
    referrer: 'Dr. Julian Foster',
    diagnosis_code: 'C79.81',
  },
  {
    fractions: 16,
    site: 'Red Hills',
    physician: 'Dr Adams',
    date: '2023-12-15',
    diagnosis: 'prostate',
    fraction_dose: 2.14,
    adherence: true,
    technique: 'Brachytherapy',
    linac: 'Machine 2',
    sim_to_treat: 4,
    priority: 'Emergency',
    referrer: 'Dr. Clara Thompson',
    diagnosis_code: 'C61',
  },
  {
    fractions: 10,
    site: 'Lakeside',
    physician: 'Dr Adams',
    date: '2023-08-18',
    diagnosis: 'prostate',
    fraction_dose: 3.14,
    adherence: true,
    technique: 'Electrons',
    linac: 'Machine 4',
    sim_to_treat: 7,
    priority: 'Standard',
    referrer: 'Dr. Rachel Nguyen',
    diagnosis_code: 'C61',
  },
  {
    fractions: 11,
    site: 'Lakeside',
    physician: 'Dr Smith',
    date: '2023-08-04',
    diagnosis: 'neuro',
    fraction_dose: 2.14,
    adherence: true,
    technique: 'Electrons',
    linac: 'Machine 4',
    sim_to_treat: 10,
    priority: 'Urgent',
    referrer: 'Dr. Clara Thompson',
    diagnosis_code: 'C61',
  },
  {
    fractions: 22,
    site: 'Campsie',
    physician: 'Dr Lopez',
    date: '2023-11-18',
    diagnosis: 'skin',
    fraction_dose: 1.33,
    adherence: true,
    technique: 'VMAT',
    linac: 'Machine 4',
    sim_to_treat: 3,
    priority: 'Urgent',
    referrer: 'Dr. Victor Hernandes',
    diagnosis_code: 'C34.90',
  },
  {
    fractions: 21,
    site: 'Rosebush',
    physician: 'Dr Adams',
    date: '2023-06-15',
    diagnosis: 'skin',
    fraction_dose: 2.55,
    adherence: true,
    technique: 'Stereotactic',
    linac: 'Machine 1',
    sim_to_treat: 2,
    priority: 'Emergency',
    referrer: 'Dr. Jonathan Blake',
    diagnosis_code: 'C71.9',
  },
  {
    fractions: 17,
    site: 'Rosebush',
    physician: 'Dr Smith',
    date: '2023-04-25',
    diagnosis: 'breast',
    fraction_dose: 2.77,
    adherence: true,
    technique: 'Conformal',
    linac: 'Machine 1',
    sim_to_treat: 9,
    priority: 'Standard',
    referrer: 'Dr. Emily Hartman',
    diagnosis_code: 'C61',
  },
  {
    fractions: 19,
    site: 'Lakeside',
    physician: 'Dr Wong',
    date: '2023-11-08',
    diagnosis: 'metastasis',
    fraction_dose: 3.05,
    adherence: true,
    technique: 'Unspecified',
    linac: 'Machine 4',
    sim_to_treat: 4,
    priority: 'Urgent',
    referrer: 'Dr. Jonathan Blake',
    diagnosis_code: 'C43.9',
  },
  {
    fractions: 11,
    site: 'Campsie',
    physician: 'Dr Adams',
    date: '2023-09-26',
    diagnosis: 'colorectal',
    fraction_dose: 2.44,
    adherence: false,
    technique: 'Conformal',
    linac: 'Machine 3',
    sim_to_treat: 2,
    priority: 'Standard',
    referrer: 'Dr. Rachel Nguyen',
    diagnosis_code: 'C71.9',
  },
  {
    fractions: 20,
    site: 'Lakeside',
    physician: 'Dr Wong',
    date: '2023-10-31',
    diagnosis: 'Unspecified',
    fraction_dose: 2.98,
    adherence: true,
    technique: 'Conformal',
    linac: 'Machine 1',
    sim_to_treat: 6,
    priority: 'Standard',
    referrer: 'Dr. Sophie Greenberg',
    diagnosis_code: 'C71.9',
  },
  {
    fractions: 16,
    site: 'Lakeside',
    physician: 'Dr Lopez',
    date: '2023-06-21',
    diagnosis: 'skin',
    fraction_dose: 1.75,
    adherence: true,
    technique: 'Electrons',
    linac: 'Machine 3',
    sim_to_treat: 7,
    priority: 'Emergency',
    referrer: 'Dr. Alex Reynolds',
    diagnosis_code: 'C71.9',
  },
  {
    fractions: 21,
    site: 'Rosebush',
    physician: 'Dr Lopez',
    date: '2023-03-25',
    diagnosis: 'breast',
    fraction_dose: 3.21,
    adherence: true,
    technique: 'Stereotactic',
    linac: 'Machine 4',
    sim_to_treat: 3,
    priority: 'Standard',
    referrer: 'Dr. Julian Foster',
    diagnosis_code: 'C61',
  },
  {
    fractions: 17,
    site: 'Lakeside',
    physician: 'Dr Adams',
    date: '2023-12-17',
    diagnosis: 'Unspecified',
    fraction_dose: 2.13,
    adherence: true,
    technique: 'Conformal',
    linac: 'Machine 3',
    sim_to_treat: 6,
    priority: 'Standard',
    referrer: 'Dr. Sophie Greenberg',
    diagnosis_code: 'C34.90',
  },
  {
    fractions: 16,
    site: 'Lakeside',
    physician: 'Dr Adams',
    date: '2023-12-18',
    diagnosis: 'prostate',
    fraction_dose: 1.23,
    adherence: true,
    technique: 'Electrons',
    linac: 'Machine 3',
    sim_to_treat: 8,
    priority: 'Urgent',
    referrer: 'Dr. Alex Reynolds',
    diagnosis_code: 'C71.9',
  },
  {
    fractions: 12,
    site: 'Lakeside',
    physician: 'Dr Lopez',
    date: '2023-01-05',
    diagnosis: 'neuro',
    fraction_dose: 3.21,
    adherence: true,
    technique: 'Brachytherapy',
    linac: 'Machine 3',
    sim_to_treat: 8,
    priority: 'Standard',
    referrer: 'Dr. Jonathan Blake',
    diagnosis_code: 'C43.9',
  },
  {
    fractions: 17,
    site: 'Red Hills',
    physician: 'Dr Adams',
    date: '2023-10-14',
    diagnosis: 'skin',
    fraction_dose: 2.14,
    adherence: true,
    technique: 'Brachytherapy',
    linac: 'Machine 3',
    sim_to_treat: 5,
    priority: 'Urgent',
    referrer: 'Dr. Clara Thompson',
    diagnosis_code: 'C61',
  },
  {
    fractions: 11,
    site: 'Campsie',
    physician: 'Dr Smith',
    date: '2023-05-31',
    diagnosis: 'neuro',
    fraction_dose: 3.36,
    adherence: true,
    technique: 'Brachytherapy',
    linac: 'Machine 4',
    sim_to_treat: 1,
    priority: 'Urgent',
    referrer: 'Dr. Clara Thompson',
    diagnosis_code: 'C43.9',
  },
  {
    fractions: 19,
    site: 'Red Hills',
    physician: 'Dr Lopez',
    date: '2023-06-27',
    diagnosis: 'Unspecified',
    fraction_dose: 2.61,
    adherence: true,
    technique: 'Conformal',
    linac: 'Machine 2',
    sim_to_treat: 4,
    priority: 'Urgent',
    referrer: 'Dr. Clara Thompson',
    diagnosis_code: 'C43.9',
  },
  {
    fractions: 19,
    site: 'Campsie',
    physician: 'Dr Wong',
    date: '2023-10-03',
    diagnosis: 'Unspecified',
    fraction_dose: 1.47,
    adherence: true,
    technique: 'Brachytherapy',
    linac: 'Machine 2',
    sim_to_treat: 5,
    priority: 'Urgent',
    referrer: 'Dr. Sophie Greenberg',
    diagnosis_code: 'C34.90',
  },
  {
    fractions: 22,
    site: 'Rosebush',
    physician: 'Dr Smith',
    date: '2023-11-08',
    diagnosis: 'colorectal',
    fraction_dose: 3.39,
    adherence: true,
    technique: 'Electrons',
    linac: 'Machine 4',
    sim_to_treat: 1,
    priority: 'Urgent',
    referrer: 'Dr. Michael Stern',
    diagnosis_code: 'C43.9',
  },
  {
    fractions: 22,
    site: 'Rosebush',
    physician: 'Dr Lopez',
    date: '2023-03-29',
    diagnosis: 'prostate',
    fraction_dose: 1.74,
    adherence: true,
    technique: 'Brachytherapy',
    linac: 'Machine 4',
    sim_to_treat: 1,
    priority: 'Urgent',
    referrer: 'Dr. Aisha Patel',
    diagnosis_code: 'C79.81',
  },
  {
    fractions: 15,
    site: 'Rosebush',
    physician: 'Dr Wong',
    date: '2023-06-15',
    diagnosis: 'colorectal',
    fraction_dose: 2.49,
    adherence: true,
    technique: 'VMAT',
    linac: 'Machine 1',
    sim_to_treat: 6,
    priority: 'Emergency',
    referrer: 'Dr. Julian Foster',
    diagnosis_code: 'C34.90',
  },
  {
    fractions: 18,
    site: 'Campsie',
    physician: 'Dr Lopez',
    date: '2023-01-23',
    diagnosis: 'breast',
    fraction_dose: 2.55,
    adherence: false,
    technique: 'Brachytherapy',
    linac: 'Machine 2',
    sim_to_treat: 10,
    priority: 'Urgent',
    referrer: 'Dr. Victor Hernandes',
    diagnosis_code: 'C34.90',
  },
  {
    fractions: 18,
    site: 'Rosebush',
    physician: 'Dr Wong',
    date: '2023-01-20',
    diagnosis: 'prostate',
    fraction_dose: 2.23,
    adherence: true,
    technique: 'Stereotactic',
    linac: 'Machine 1',
    sim_to_treat: 8,
    priority: 'Urgent',
    referrer: 'Dr. Alex Reynolds',
    diagnosis_code: 'C71.9',
  },
  {
    fractions: 12,
    site: 'Red Hills',
    physician: 'Dr Wong',
    date: '2023-11-12',
    diagnosis: 'metastasis',
    fraction_dose: 2.22,
    adherence: true,
    technique: 'IMRT',
    linac: 'Machine 3',
    sim_to_treat: 1,
    priority: 'Standard',
    referrer: 'Dr. Jonathan Blake',
    diagnosis_code: 'C20',
  },
  {
    fractions: 19,
    site: 'Rosebush',
    physician: 'Dr Wong',
    date: '2023-04-05',
    diagnosis: 'neuro',
    fraction_dose: 3.07,
    adherence: true,
    technique: 'Brachytherapy',
    linac: 'Machine 2',
    sim_to_treat: 3,
    priority: 'Emergency',
    referrer: 'Dr. Rachel Nguyen',
    diagnosis_code: 'C20',
  },
  {
    fractions: 20,
    site: 'Rosebush',
    physician: 'Dr Adams',
    date: '2023-01-04',
    diagnosis: 'neuro',
    fraction_dose: 2.73,
    adherence: true,
    technique: 'Electrons',
    linac: 'Machine 2',
    sim_to_treat: 10,
    priority: 'Standard',
    referrer: 'Dr. Jonathan Blake',
    diagnosis_code: 'C20',
  },
  {
    fractions: 13,
    site: 'Campsie',
    physician: 'Dr Lopez',
    date: '2023-12-09',
    diagnosis: 'colorectal',
    fraction_dose: 3.32,
    adherence: true,
    technique: 'Unspecified',
    linac: 'Machine 1',
    sim_to_treat: 5,
    priority: 'Standard',
    referrer: 'Dr. Clara Thompson',
    diagnosis_code: 'C20',
  },
  {
    fractions: 16,
    site: 'Lakeside',
    physician: 'Dr Smith',
    date: '2023-10-28',
    diagnosis: 'metastasis',
    fraction_dose: 3.08,
    adherence: true,
    technique: 'Conformal',
    linac: 'Machine 2',
    sim_to_treat: 7,
    priority: 'Urgent',
    referrer: 'Dr. Jonathan Blake',
    diagnosis_code: 'C71.9',
  },
  {
    fractions: 17,
    site: 'Lakeside',
    physician: 'Dr Wong',
    date: '2023-03-21',
    diagnosis: 'breast',
    fraction_dose: 1.98,
    adherence: true,
    technique: 'IMRT',
    linac: 'Machine 4',
    sim_to_treat: 3,
    priority: 'Emergency',
    referrer: 'Dr. Sophie Greenberg',
    diagnosis_code: 'C20',
  },
  {
    fractions: 24,
    site: 'Rosebush',
    physician: 'Dr Wong',
    date: '2023-02-21',
    diagnosis: 'Unspecified',
    fraction_dose: 1.96,
    adherence: true,
    technique: 'IMRT',
    linac: 'Machine 3',
    sim_to_treat: 10,
    priority: 'Urgent',
    referrer: 'Dr. Victor Hernandes',
    diagnosis_code: 'C79.81',
  },
  {
    fractions: 24,
    site: 'Rosebush',
    physician: 'Dr Smith',
    date: '2023-04-23',
    diagnosis: 'colorectal',
    fraction_dose: 3.17,
    adherence: true,
    technique: 'IMRT',
    linac: 'Machine 1',
    sim_to_treat: 7,
    priority: 'Standard',
    referrer: 'Dr. Julian Foster',
    diagnosis_code: 'C34.90',
  },
  {
    fractions: 11,
    site: 'Red Hills',
    physician: 'Dr Wong',
    date: '2023-09-06',
    diagnosis: 'neuro',
    fraction_dose: 2.69,
    adherence: true,
    technique: 'Unspecified',
    linac: 'Machine 4',
    sim_to_treat: 4,
    priority: 'Emergency',
    referrer: 'Dr. Rachel Nguyen',
    diagnosis_code: 'C34.90',
  },
  {
    fractions: 10,
    site: 'Lakeside',
    physician: 'Dr Smith',
    date: '2023-06-22',
    diagnosis: 'Unspecified',
    fraction_dose: 2.22,
    adherence: true,
    technique: 'IMRT',
    linac: 'Machine 2',
    sim_to_treat: 1,
    priority: 'Standard',
    referrer: 'Dr. Jonathan Blake',
    diagnosis_code: 'C79.81',
  },
  {
    fractions: 21,
    site: 'Lakeside',
    physician: 'Dr Adams',
    date: '2023-03-29',
    diagnosis: 'skin',
    fraction_dose: 3.29,
    adherence: true,
    technique: 'Stereotactic',
    linac: 'Machine 3',
    sim_to_treat: 1,
    priority: 'Urgent',
    referrer: 'Dr. Emily Hartman',
    diagnosis_code: 'C61',
  },
  {
    fractions: 23,
    site: 'Lakeside',
    physician: 'Dr Adams',
    date: '2023-06-29',
    diagnosis: 'breast',
    fraction_dose: 1.67,
    adherence: true,
    technique: 'Brachytherapy',
    linac: 'Machine 3',
    sim_to_treat: 7,
    priority: 'Urgent',
    referrer: 'Dr. Jonathan Blake',
    diagnosis_code: 'C20',
  },
  {
    fractions: 11,
    site: 'Lakeside',
    physician: 'Dr Lopez',
    date: '2023-10-02',
    diagnosis: 'colorectal',
    fraction_dose: 2.2,
    adherence: true,
    technique: 'Unspecified',
    linac: 'Machine 2',
    sim_to_treat: 5,
    priority: 'Urgent',
    referrer: 'Dr. Emily Hartman',
    diagnosis_code: 'C79.81',
  },
  {
    fractions: 16,
    site: 'Red Hills',
    physician: 'Dr Lopez',
    date: '2023-08-06',
    diagnosis: 'skin',
    fraction_dose: 1.28,
    adherence: true,
    technique: 'Stereotactic',
    linac: 'Machine 1',
    sim_to_treat: 5,
    priority: 'Emergency',
    referrer: 'Dr. Jonathan Blake',
    diagnosis_code: 'C20',
  },
  {
    fractions: 21,
    site: 'Rosebush',
    physician: 'Dr Adams',
    date: '2023-08-30',
    diagnosis: 'metastasis',
    fraction_dose: 2.06,
    adherence: true,
    technique: 'Stereotactic',
    linac: 'Machine 4',
    sim_to_treat: 3,
    priority: 'Standard',
    referrer: 'Dr. Jonathan Blake',
    diagnosis_code: 'C43.9',
  },
  {
    fractions: 16,
    site: 'Lakeside',
    physician: 'Dr Adams',
    date: '2023-12-10',
    diagnosis: 'Unspecified',
    fraction_dose: 2.99,
    adherence: true,
    technique: 'Brachytherapy',
    linac: 'Machine 4',
    sim_to_treat: 6,
    priority: 'Urgent',
    referrer: 'Dr. Emily Hartman',
    diagnosis_code: 'C20',
  },
  {
    fractions: 15,
    site: 'Rosebush',
    physician: 'Dr Wong',
    date: '2023-02-01',
    diagnosis: 'colorectal',
    fraction_dose: 2.52,
    adherence: true,
    technique: 'Brachytherapy',
    linac: 'Machine 1',
    sim_to_treat: 7,
    priority: 'Emergency',
    referrer: 'Dr. Jonathan Blake',
    diagnosis_code: 'C61',
  },
  {
    fractions: 25,
    site: 'Lakeside',
    physician: 'Dr Adams',
    date: '2023-02-05',
    diagnosis: 'prostate',
    fraction_dose: 2.42,
    adherence: true,
    technique: 'IMRT',
    linac: 'Machine 2',
    sim_to_treat: 2,
    priority: 'Standard',
    referrer: 'Dr. Aisha Patel',
    diagnosis_code: 'C79.81',
  },
  {
    fractions: 18,
    site: 'Lakeside',
    physician: 'Dr Smith',
    date: '2023-01-30',
    diagnosis: 'skin',
    fraction_dose: 2.72,
    adherence: true,
    technique: 'IMRT',
    linac: 'Machine 3',
    sim_to_treat: 1,
    priority: 'Urgent',
    referrer: 'Dr. Julian Foster',
    diagnosis_code: 'C20',
  },
  {
    fractions: 25,
    site: 'Rosebush',
    physician: 'Dr Smith',
    date: '2023-02-25',
    diagnosis: 'breast',
    fraction_dose: 1.58,
    adherence: true,
    technique: 'Stereotactic',
    linac: 'Machine 3',
    sim_to_treat: 5,
    priority: 'Emergency',
    referrer: 'Dr. Jonathan Blake',
    diagnosis_code: 'C20',
  },
  {
    fractions: 17,
    site: 'Campsie',
    physician: 'Dr Lopez',
    date: '2023-01-12',
    diagnosis: 'neuro',
    fraction_dose: 2.84,
    adherence: true,
    technique: 'Unspecified',
    linac: 'Machine 3',
    sim_to_treat: 7,
    priority: 'Emergency',
    referrer: 'Dr. Clara Thompson',
    diagnosis_code: 'C61',
  },
  {
    fractions: 21,
    site: 'Rosebush',
    physician: 'Dr Smith',
    date: '2023-08-21',
    diagnosis: 'Unspecified',
    fraction_dose: 3.01,
    adherence: true,
    technique: 'IMRT',
    linac: 'Machine 1',
    sim_to_treat: 8,
    priority: 'Urgent',
    referrer: 'Dr. Michael Stern',
    diagnosis_code: 'C79.81',
  },
  {
    fractions: 12,
    site: 'Lakeside',
    physician: 'Dr Lopez',
    date: '2023-07-11',
    diagnosis: 'neuro',
    fraction_dose: 2.1,
    adherence: true,
    technique: 'VMAT',
    linac: 'Machine 1',
    sim_to_treat: 3,
    priority: 'Standard',
    referrer: 'Dr. Jonathan Blake',
    diagnosis_code: 'C61',
  },
  {
    fractions: 12,
    site: 'Red Hills',
    physician: 'Dr Wong',
    date: '2023-10-21',
    diagnosis: 'metastasis',
    fraction_dose: 2.78,
    adherence: true,
    technique: 'Unspecified',
    linac: 'Machine 4',
    sim_to_treat: 7,
    priority: 'Standard',
    referrer: 'Dr. Sophie Greenberg',
    diagnosis_code: 'C34.90',
  },
  {
    fractions: 20,
    site: 'Lakeside',
    physician: 'Dr Adams',
    date: '2023-09-01',
    diagnosis: 'breast',
    fraction_dose: 3.13,
    adherence: true,
    technique: 'IMRT',
    linac: 'Machine 1',
    sim_to_treat: 5,
    priority: 'Urgent',
    referrer: 'Dr. Michael Stern',
    diagnosis_code: 'C61',
  },
  {
    fractions: 18,
    site: 'Campsie',
    physician: 'Dr Wong',
    date: '2023-12-11',
    diagnosis: 'skin',
    fraction_dose: 2.04,
    adherence: true,
    technique: 'Brachytherapy',
    linac: 'Machine 4',
    sim_to_treat: 2,
    priority: 'Urgent',
    referrer: 'Dr. Jonathan Blake',
    diagnosis_code: 'C79.81',
  },
  {
    fractions: 12,
    site: 'Lakeside',
    physician: 'Dr Wong',
    date: '2023-03-04',
    diagnosis: 'prostate',
    fraction_dose: 2.02,
    adherence: true,
    technique: 'Stereotactic',
    linac: 'Machine 1',
    sim_to_treat: 8,
    priority: 'Emergency',
    referrer: 'Dr. Rachel Nguyen',
    diagnosis_code: 'C20',
  },
  {
    fractions: 24,
    site: 'Rosebush',
    physician: 'Dr Adams',
    date: '2023-10-01',
    diagnosis: 'prostate',
    fraction_dose: 2.63,
    adherence: true,
    technique: 'IMRT',
    linac: 'Machine 3',
    sim_to_treat: 9,
    priority: 'Standard',
    referrer: 'Dr. Emily Hartman',
    diagnosis_code: 'C20',
  },
  {
    fractions: 21,
    site: 'Lakeside',
    physician: 'Dr Adams',
    date: '2023-10-12',
    diagnosis: 'neuro',
    fraction_dose: 3.06,
    adherence: true,
    technique: 'Stereotactic',
    linac: 'Machine 1',
    sim_to_treat: 7,
    priority: 'Emergency',
    referrer: 'Dr. Victor Hernandes',
    diagnosis_code: 'C61',
  },
  {
    fractions: 18,
    site: 'Rosebush',
    physician: 'Dr Adams',
    date: '2023-01-23',
    diagnosis: 'breast',
    fraction_dose: 2.32,
    adherence: true,
    technique: 'IMRT',
    linac: 'Machine 1',
    sim_to_treat: 5,
    priority: 'Emergency',
    referrer: 'Dr. Emily Hartman',
    diagnosis_code: 'C79.81',
  },
  {
    fractions: 13,
    site: 'Campsie',
    physician: 'Dr Adams',
    date: '2023-02-04',
    diagnosis: 'colorectal',
    fraction_dose: 2.37,
    adherence: true,
    technique: 'VMAT',
    linac: 'Machine 3',
    sim_to_treat: 2,
    priority: 'Urgent',
    referrer: 'Dr. Rachel Nguyen',
    diagnosis_code: 'C71.9',
  },
  {
    fractions: 20,
    site: 'Rosebush',
    physician: 'Dr Smith',
    date: '2023-05-10',
    diagnosis: 'colorectal',
    fraction_dose: 2.28,
    adherence: true,
    technique: 'Stereotactic',
    linac: 'Machine 4',
    sim_to_treat: 2,
    priority: 'Emergency',
    referrer: 'Dr. Rachel Nguyen',
    diagnosis_code: 'C20',
  },
  {
    fractions: 13,
    site: 'Red Hills',
    physician: 'Dr Adams',
    date: '2023-08-18',
    diagnosis: 'neuro',
    fraction_dose: 2.53,
    adherence: true,
    technique: 'IMRT',
    linac: 'Machine 1',
    sim_to_treat: 2,
    priority: 'Emergency',
    referrer: 'Dr. Alex Reynolds',
    diagnosis_code: 'C79.81',
  },
  {
    fractions: 19,
    site: 'Red Hills',
    physician: 'Dr Lopez',
    date: '2023-01-22',
    diagnosis: 'prostate',
    fraction_dose: 2.01,
    adherence: true,
    technique: 'Electrons',
    linac: 'Machine 1',
    sim_to_treat: 3,
    priority: 'Standard',
    referrer: 'Dr. Rachel Nguyen',
    diagnosis_code: 'C43.9',
  },
  {
    fractions: 12,
    site: 'Rosebush',
    physician: 'Dr Smith',
    date: '2023-04-30',
    diagnosis: 'prostate',
    fraction_dose: 3.13,
    adherence: true,
    technique: 'Unspecified',
    linac: 'Machine 2',
    sim_to_treat: 3,
    priority: 'Standard',
    referrer: 'Dr. Rachel Nguyen',
    diagnosis_code: 'C43.9',
  },
  {
    fractions: 25,
    site: 'Rosebush',
    physician: 'Dr Lopez',
    date: '2023-02-09',
    diagnosis: 'breast',
    fraction_dose: 1.42,
    adherence: true,
    technique: 'Stereotactic',
    linac: 'Machine 3',
    sim_to_treat: 10,
    priority: 'Emergency',
    referrer: 'Dr. Emily Hartman',
    diagnosis_code: 'C61',
  },
  {
    fractions: 25,
    site: 'Rosebush',
    physician: 'Dr Lopez',
    date: '2023-03-16',
    diagnosis: 'prostate',
    fraction_dose: 1.4,
    adherence: true,
    technique: 'Unspecified',
    linac: 'Machine 1',
    sim_to_treat: 5,
    priority: 'Urgent',
    referrer: 'Dr. Clara Thompson',
    diagnosis_code: 'C43.9',
  },
  {
    fractions: 17,
    site: 'Lakeside',
    physician: 'Dr Wong',
    date: '2023-05-31',
    diagnosis: 'breast',
    fraction_dose: 1.75,
    adherence: true,
    technique: 'Unspecified',
    linac: 'Machine 4',
    sim_to_treat: 4,
    priority: 'Emergency',
    referrer: 'Dr. Jonathan Blake',
    diagnosis_code: 'C71.9',
  },
  {
    fractions: 13,
    site: 'Rosebush',
    physician: 'Dr Lopez',
    date: '2023-10-27',
    diagnosis: 'breast',
    fraction_dose: 1.46,
    adherence: true,
    technique: 'Brachytherapy',
    linac: 'Machine 2',
    sim_to_treat: 9,
    priority: 'Standard',
    referrer: 'Dr. Jonathan Blake',
    diagnosis_code: 'C34.90',
  },
  {
    fractions: 22,
    site: 'Rosebush',
    physician: 'Dr Adams',
    date: '2023-10-21',
    diagnosis: 'skin',
    fraction_dose: 2.41,
    adherence: true,
    technique: 'Brachytherapy',
    linac: 'Machine 4',
    sim_to_treat: 6,
    priority: 'Emergency',
    referrer: 'Dr. Julian Foster',
    diagnosis_code: 'C61',
  },
  {
    fractions: 12,
    site: 'Lakeside',
    physician: 'Dr Wong',
    date: '2023-10-02',
    diagnosis: 'colorectal',
    fraction_dose: 1.69,
    adherence: true,
    technique: 'Brachytherapy',
    linac: 'Machine 1',
    sim_to_treat: 1,
    priority: 'Urgent',
    referrer: 'Dr. Victor Hernandes',
    diagnosis_code: 'C34.90',
  },
  {
    fractions: 23,
    site: 'Lakeside',
    physician: 'Dr Adams',
    date: '2023-08-14',
    diagnosis: 'prostate',
    fraction_dose: 2.49,
    adherence: true,
    technique: 'Brachytherapy',
    linac: 'Machine 4',
    sim_to_treat: 10,
    priority: 'Urgent',
    referrer: 'Dr. Clara Thompson',
    diagnosis_code: 'C61',
  },
  {
    fractions: 14,
    site: 'Lakeside',
    physician: 'Dr Smith',
    date: '2023-11-20',
    diagnosis: 'neuro',
    fraction_dose: 2.18,
    adherence: true,
    technique: 'Electrons',
    linac: 'Machine 4',
    sim_to_treat: 10,
    priority: 'Standard',
    referrer: 'Dr. Victor Hernandes',
    diagnosis_code: 'C20',
  },
  {
    fractions: 22,
    site: 'Campsie',
    physician: 'Dr Wong',
    date: '2023-06-20',
    diagnosis: 'skin',
    fraction_dose: 1.98,
    adherence: true,
    technique: 'IMRT',
    linac: 'Machine 2',
    sim_to_treat: 8,
    priority: 'Emergency',
    referrer: 'Dr. Victor Hernandes',
    diagnosis_code: 'C79.81',
  },
  {
    fractions: 15,
    site: 'Red Hills',
    physician: 'Dr Adams',
    date: '2023-11-19',
    diagnosis: 'colorectal',
    fraction_dose: 2.21,
    adherence: true,
    technique: 'Stereotactic',
    linac: 'Machine 4',
    sim_to_treat: 7,
    priority: 'Standard',
    referrer: 'Dr. Michael Stern',
    diagnosis_code: 'C71.9',
  },
  {
    fractions: 20,
    site: 'Lakeside',
    physician: 'Dr Smith',
    date: '2023-09-20',
    diagnosis: 'Unspecified',
    fraction_dose: 1.71,
    adherence: true,
    technique: 'Conformal',
    linac: 'Machine 2',
    sim_to_treat: 10,
    priority: 'Urgent',
    referrer: 'Dr. Julian Foster',
    diagnosis_code: 'C61',
  },
  {
    fractions: 17,
    site: 'Campsie',
    physician: 'Dr Lopez',
    date: '2023-03-21',
    diagnosis: 'skin',
    fraction_dose: 2.12,
    adherence: true,
    technique: 'IMRT',
    linac: 'Machine 1',
    sim_to_treat: 6,
    priority: 'Standard',
    referrer: 'Dr. Aisha Patel',
    diagnosis_code: 'C20',
  },
  {
    fractions: 22,
    site: 'Red Hills',
    physician: 'Dr Lopez',
    date: '2023-12-19',
    diagnosis: 'breast',
    fraction_dose: 2.07,
    adherence: true,
    technique: 'Conformal',
    linac: 'Machine 2',
    sim_to_treat: 10,
    priority: 'Urgent',
    referrer: 'Dr. Alex Reynolds',
    diagnosis_code: 'C79.81',
  },
  {
    fractions: 11,
    site: 'Lakeside',
    physician: 'Dr Smith',
    date: '2023-07-01',
    diagnosis: 'colorectal',
    fraction_dose: 3.16,
    adherence: true,
    technique: 'Stereotactic',
    linac: 'Machine 3',
    sim_to_treat: 5,
    priority: 'Standard',
    referrer: 'Dr. Sophie Greenberg',
    diagnosis_code: 'C34.90',
  },
  {
    fractions: 11,
    site: 'Lakeside',
    physician: 'Dr Wong',
    date: '2023-03-09',
    diagnosis: 'prostate',
    fraction_dose: 3.34,
    adherence: false,
    technique: 'VMAT',
    linac: 'Machine 2',
    sim_to_treat: 7,
    priority: 'Emergency',
    referrer: 'Dr. Alex Reynolds',
    diagnosis_code: 'C20',
  },
  {
    fractions: 23,
    site: 'Red Hills',
    physician: 'Dr Lopez',
    date: '2023-05-04',
    diagnosis: 'prostate',
    fraction_dose: 2.74,
    adherence: true,
    technique: 'IMRT',
    linac: 'Machine 3',
    sim_to_treat: 1,
    priority: 'Standard',
    referrer: 'Dr. Michael Stern',
    diagnosis_code: 'C71.9',
  },
  {
    fractions: 22,
    site: 'Rosebush',
    physician: 'Dr Wong',
    date: '2023-06-02',
    diagnosis: 'neuro',
    fraction_dose: 3.33,
    adherence: true,
    technique: 'Brachytherapy',
    linac: 'Machine 4',
    sim_to_treat: 4,
    priority: 'Urgent',
    referrer: 'Dr. Aisha Patel',
    diagnosis_code: 'C61',
  },
  {
    fractions: 11,
    site: 'Red Hills',
    physician: 'Dr Smith',
    date: '2023-12-01',
    diagnosis: 'prostate',
    fraction_dose: 2.41,
    adherence: true,
    technique: 'Unspecified',
    linac: 'Machine 2',
    sim_to_treat: 7,
    priority: 'Emergency',
    referrer: 'Dr. Aisha Patel',
    diagnosis_code: 'C20',
  },
  {
    fractions: 22,
    site: 'Rosebush',
    physician: 'Dr Lopez',
    date: '2023-03-22',
    diagnosis: 'prostate',
    fraction_dose: 1.75,
    adherence: true,
    technique: 'VMAT',
    linac: 'Machine 2',
    sim_to_treat: 7,
    priority: 'Emergency',
    referrer: 'Dr. Rachel Nguyen',
    diagnosis_code: 'C61',
  },
  {
    fractions: 24,
    site: 'Campsie',
    physician: 'Dr Smith',
    date: '2023-11-14',
    diagnosis: 'neuro',
    fraction_dose: 2.0,
    adherence: true,
    technique: 'Stereotactic',
    linac: 'Machine 3',
    sim_to_treat: 1,
    priority: 'Standard',
    referrer: 'Dr. Clara Thompson',
    diagnosis_code: 'C34.90',
  },
  {
    fractions: 22,
    site: 'Rosebush',
    physician: 'Dr Wong',
    date: '2023-07-20',
    diagnosis: 'breast',
    fraction_dose: 1.91,
    adherence: true,
    technique: 'VMAT',
    linac: 'Machine 3',
    sim_to_treat: 10,
    priority: 'Emergency',
    referrer: 'Dr. Julian Foster',
    diagnosis_code: 'C34.90',
  },
  {
    fractions: 18,
    site: 'Lakeside',
    physician: 'Dr Smith',
    date: '2023-10-01',
    diagnosis: 'skin',
    fraction_dose: 3.0,
    adherence: true,
    technique: 'Stereotactic',
    linac: 'Machine 1',
    sim_to_treat: 1,
    priority: 'Emergency',
    referrer: 'Dr. Michael Stern',
    diagnosis_code: 'C34.90',
  },
  {
    fractions: 13,
    site: 'Rosebush',
    physician: 'Dr Smith',
    date: '2023-11-06',
    diagnosis: 'skin',
    fraction_dose: 2.28,
    adherence: true,
    technique: 'IMRT',
    linac: 'Machine 2',
    sim_to_treat: 4,
    priority: 'Urgent',
    referrer: 'Dr. Rachel Nguyen',
    diagnosis_code: 'C79.81',
  },
  {
    fractions: 10,
    site: 'Campsie',
    physician: 'Dr Adams',
    date: '2023-05-09',
    diagnosis: 'breast',
    fraction_dose: 3.38,
    adherence: true,
    technique: 'IMRT',
    linac: 'Machine 1',
    sim_to_treat: 7,
    priority: 'Standard',
    referrer: 'Dr. Alex Reynolds',
    diagnosis_code: 'C43.9',
  },
  {
    fractions: 15,
    site: 'Rosebush',
    physician: 'Dr Wong',
    date: '2023-12-27',
    diagnosis: 'colorectal',
    fraction_dose: 1.58,
    adherence: true,
    technique: 'Conformal',
    linac: 'Machine 3',
    sim_to_treat: 8,
    priority: 'Urgent',
    referrer: 'Dr. Rachel Nguyen',
    diagnosis_code: 'C34.90',
  },
  {
    fractions: 24,
    site: 'Lakeside',
    physician: 'Dr Smith',
    date: '2023-02-23',
    diagnosis: 'colorectal',
    fraction_dose: 1.83,
    adherence: true,
    technique: 'VMAT',
    linac: 'Machine 1',
    sim_to_treat: 9,
    priority: 'Urgent',
    referrer: 'Dr. Jonathan Blake',
    diagnosis_code: 'C34.90',
  },
  {
    fractions: 23,
    site: 'Campsie',
    physician: 'Dr Wong',
    date: '2023-04-18',
    diagnosis: 'neuro',
    fraction_dose: 1.27,
    adherence: true,
    technique: 'Electrons',
    linac: 'Machine 3',
    sim_to_treat: 7,
    priority: 'Standard',
    referrer: 'Dr. Sophie Greenberg',
    diagnosis_code: 'C71.9',
  },
  {
    fractions: 10,
    site: 'Lakeside',
    physician: 'Dr Lopez',
    date: '2023-08-14',
    diagnosis: 'skin',
    fraction_dose: 1.56,
    adherence: true,
    technique: 'Stereotactic',
    linac: 'Machine 1',
    sim_to_treat: 8,
    priority: 'Emergency',
    referrer: 'Dr. Rachel Nguyen',
    diagnosis_code: 'C79.81',
  },
  {
    fractions: 16,
    site: 'Campsie',
    physician: 'Dr Smith',
    date: '2023-10-23',
    diagnosis: 'prostate',
    fraction_dose: 2.23,
    adherence: true,
    technique: 'VMAT',
    linac: 'Machine 3',
    sim_to_treat: 6,
    priority: 'Emergency',
    referrer: 'Dr. Rachel Nguyen',
    diagnosis_code: 'C43.9',
  },
  {
    fractions: 20,
    site: 'Red Hills',
    physician: 'Dr Lopez',
    date: '2023-05-25',
    diagnosis: 'colorectal',
    fraction_dose: 2.49,
    adherence: true,
    technique: 'Brachytherapy',
    linac: 'Machine 4',
    sim_to_treat: 4,
    priority: 'Standard',
    referrer: 'Dr. Jonathan Blake',
    diagnosis_code: 'C34.90',
  },
  {
    fractions: 21,
    site: 'Campsie',
    physician: 'Dr Lopez',
    date: '2023-07-07',
    diagnosis: 'neuro',
    fraction_dose: 1.21,
    adherence: true,
    technique: 'Stereotactic',
    linac: 'Machine 2',
    sim_to_treat: 3,
    priority: 'Emergency',
    referrer: 'Dr. Emily Hartman',
    diagnosis_code: 'C61',
  },
  {
    fractions: 21,
    site: 'Lakeside',
    physician: 'Dr Adams',
    date: '2023-10-11',
    diagnosis: 'prostate',
    fraction_dose: 1.79,
    adherence: true,
    technique: 'VMAT',
    linac: 'Machine 4',
    sim_to_treat: 9,
    priority: 'Emergency',
    referrer: 'Dr. Emily Hartman',
    diagnosis_code: 'C34.90',
  },
  {
    fractions: 22,
    site: 'Rosebush',
    physician: 'Dr Adams',
    date: '2023-04-12',
    diagnosis: 'metastasis',
    fraction_dose: 1.31,
    adherence: true,
    technique: 'Unspecified',
    linac: 'Machine 4',
    sim_to_treat: 7,
    priority: 'Standard',
    referrer: 'Dr. Jonathan Blake',
    diagnosis_code: 'C79.81',
  },
  {
    fractions: 10,
    site: 'Lakeside',
    physician: 'Dr Smith',
    date: '2023-03-31',
    diagnosis: 'neuro',
    fraction_dose: 1.73,
    adherence: true,
    technique: 'Unspecified',
    linac: 'Machine 4',
    sim_to_treat: 4,
    priority: 'Emergency',
    referrer: 'Dr. Sophie Greenberg',
    diagnosis_code: 'C20',
  },
  {
    fractions: 19,
    site: 'Red Hills',
    physician: 'Dr Lopez',
    date: '2023-04-09',
    diagnosis: 'breast',
    fraction_dose: 3.03,
    adherence: true,
    technique: 'Conformal',
    linac: 'Machine 3',
    sim_to_treat: 1,
    priority: 'Urgent',
    referrer: 'Dr. Victor Hernandes',
    diagnosis_code: 'C61',
  },
  {
    fractions: 14,
    site: 'Campsie',
    physician: 'Dr Lopez',
    date: '2023-02-15',
    diagnosis: 'prostate',
    fraction_dose: 1.22,
    adherence: true,
    technique: 'IMRT',
    linac: 'Machine 1',
    sim_to_treat: 6,
    priority: 'Standard',
    referrer: 'Dr. Rachel Nguyen',
    diagnosis_code: 'C20',
  },
  {
    fractions: 23,
    site: 'Campsie',
    physician: 'Dr Adams',
    date: '2023-04-22',
    diagnosis: 'neuro',
    fraction_dose: 2.49,
    adherence: true,
    technique: 'VMAT',
    linac: 'Machine 1',
    sim_to_treat: 4,
    priority: 'Emergency',
    referrer: 'Dr. Jonathan Blake',
    diagnosis_code: 'C71.9',
  },
  {
    fractions: 19,
    site: 'Lakeside',
    physician: 'Dr Wong',
    date: '2023-02-19',
    diagnosis: 'Unspecified',
    fraction_dose: 2.11,
    adherence: true,
    technique: 'Unspecified',
    linac: 'Machine 2',
    sim_to_treat: 6,
    priority: 'Urgent',
    referrer: 'Dr. Clara Thompson',
    diagnosis_code: 'C71.9',
  },
  {
    fractions: 22,
    site: 'Lakeside',
    physician: 'Dr Adams',
    date: '2023-10-02',
    diagnosis: 'prostate',
    fraction_dose: 2.05,
    adherence: true,
    technique: 'Unspecified',
    linac: 'Machine 2',
    sim_to_treat: 3,
    priority: 'Standard',
    referrer: 'Dr. Michael Stern',
    diagnosis_code: 'C43.9',
  },
  {
    fractions: 13,
    site: 'Lakeside',
    physician: 'Dr Smith',
    date: '2023-05-08',
    diagnosis: 'metastasis',
    fraction_dose: 3.24,
    adherence: true,
    technique: 'Stereotactic',
    linac: 'Machine 2',
    sim_to_treat: 6,
    priority: 'Standard',
    referrer: 'Dr. Aisha Patel',
    diagnosis_code: 'C61',
  },
  {
    fractions: 13,
    site: 'Rosebush',
    physician: 'Dr Smith',
    date: '2023-01-28',
    diagnosis: 'skin',
    fraction_dose: 2.2,
    adherence: true,
    technique: 'Unspecified',
    linac: 'Machine 3',
    sim_to_treat: 4,
    priority: 'Standard',
    referrer: 'Dr. Michael Stern',
    diagnosis_code: 'C71.9',
  },
  {
    fractions: 15,
    site: 'Lakeside',
    physician: 'Dr Lopez',
    date: '2023-06-25',
    diagnosis: 'skin',
    fraction_dose: 2.21,
    adherence: true,
    technique: 'Conformal',
    linac: 'Machine 3',
    sim_to_treat: 8,
    priority: 'Emergency',
    referrer: 'Dr. Julian Foster',
    diagnosis_code: 'C34.90',
  },
  {
    fractions: 10,
    site: 'Campsie',
    physician: 'Dr Lopez',
    date: '2023-12-30',
    diagnosis: 'neuro',
    fraction_dose: 1.76,
    adherence: true,
    technique: 'VMAT',
    linac: 'Machine 2',
    sim_to_treat: 6,
    priority: 'Urgent',
    referrer: 'Dr. Jonathan Blake',
    diagnosis_code: 'C34.90',
  },
  {
    fractions: 20,
    site: 'Red Hills',
    physician: 'Dr Lopez',
    date: '2023-11-13',
    diagnosis: 'Unspecified',
    fraction_dose: 3.33,
    adherence: true,
    technique: 'Brachytherapy',
    linac: 'Machine 3',
    sim_to_treat: 6,
    priority: 'Emergency',
    referrer: 'Dr. Julian Foster',
    diagnosis_code: 'C61',
  },
  {
    fractions: 19,
    site: 'Rosebush',
    physician: 'Dr Wong',
    date: '2023-01-16',
    diagnosis: 'metastasis',
    fraction_dose: 2.28,
    adherence: true,
    technique: 'VMAT',
    linac: 'Machine 4',
    sim_to_treat: 10,
    priority: 'Standard',
    referrer: 'Dr. Jonathan Blake',
    diagnosis_code: 'C61',
  },
  {
    fractions: 18,
    site: 'Rosebush',
    physician: 'Dr Lopez',
    date: '2023-08-09',
    diagnosis: 'colorectal',
    fraction_dose: 2.5,
    adherence: true,
    technique: 'VMAT',
    linac: 'Machine 2',
    sim_to_treat: 3,
    priority: 'Standard',
    referrer: 'Dr. Michael Stern',
    diagnosis_code: 'C71.9',
  },
  {
    fractions: 10,
    site: 'Campsie',
    physician: 'Dr Smith',
    date: '2023-06-23',
    diagnosis: 'metastasis',
    fraction_dose: 2.19,
    adherence: true,
    technique: 'IMRT',
    linac: 'Machine 1',
    sim_to_treat: 1,
    priority: 'Urgent',
    referrer: 'Dr. Clara Thompson',
    diagnosis_code: 'C34.90',
  },
  {
    fractions: 15,
    site: 'Campsie',
    physician: 'Dr Smith',
    date: '2023-02-09',
    diagnosis: 'colorectal',
    fraction_dose: 2.36,
    adherence: true,
    technique: 'Stereotactic',
    linac: 'Machine 4',
    sim_to_treat: 7,
    priority: 'Emergency',
    referrer: 'Dr. Emily Hartman',
    diagnosis_code: 'C79.81',
  },
  {
    fractions: 22,
    site: 'Lakeside',
    physician: 'Dr Wong',
    date: '2023-07-23',
    diagnosis: 'Unspecified',
    fraction_dose: 2.24,
    adherence: true,
    technique: 'Unspecified',
    linac: 'Machine 2',
    sim_to_treat: 10,
    priority: 'Standard',
    referrer: 'Dr. Michael Stern',
    diagnosis_code: 'C61',
  },
  {
    fractions: 17,
    site: 'Lakeside',
    physician: 'Dr Adams',
    date: '2023-09-26',
    diagnosis: 'prostate',
    fraction_dose: 1.56,
    adherence: true,
    technique: 'Unspecified',
    linac: 'Machine 1',
    sim_to_treat: 10,
    priority: 'Urgent',
    referrer: 'Dr. Clara Thompson',
    diagnosis_code: 'C79.81',
  },
  {
    fractions: 12,
    site: 'Red Hills',
    physician: 'Dr Smith',
    date: '2023-12-11',
    diagnosis: 'metastasis',
    fraction_dose: 2.59,
    adherence: true,
    technique: 'IMRT',
    linac: 'Machine 1',
    sim_to_treat: 4,
    priority: 'Urgent',
    referrer: 'Dr. Michael Stern',
    diagnosis_code: 'C34.90',
  },
  {
    fractions: 22,
    site: 'Rosebush',
    physician: 'Dr Adams',
    date: '2023-04-09',
    diagnosis: 'skin',
    fraction_dose: 1.79,
    adherence: true,
    technique: 'Unspecified',
    linac: 'Machine 4',
    sim_to_treat: 4,
    priority: 'Standard',
    referrer: 'Dr. Sophie Greenberg',
    diagnosis_code: 'C43.9',
  },
  {
    fractions: 18,
    site: 'Rosebush',
    physician: 'Dr Smith',
    date: '2023-06-06',
    diagnosis: 'skin',
    fraction_dose: 3.0,
    adherence: true,
    technique: 'Stereotactic',
    linac: 'Machine 2',
    sim_to_treat: 2,
    priority: 'Standard',
    referrer: 'Dr. Sophie Greenberg',
    diagnosis_code: 'C71.9',
  },
  {
    fractions: 12,
    site: 'Lakeside',
    physician: 'Dr Smith',
    date: '2023-07-12',
    diagnosis: 'neuro',
    fraction_dose: 2.17,
    adherence: true,
    technique: 'Brachytherapy',
    linac: 'Machine 1',
    sim_to_treat: 3,
    priority: 'Standard',
    referrer: 'Dr. Jonathan Blake',
    diagnosis_code: 'C71.9',
  },
  {
    fractions: 20,
    site: 'Red Hills',
    physician: 'Dr Wong',
    date: '2023-04-30',
    diagnosis: 'colorectal',
    fraction_dose: 2.63,
    adherence: true,
    technique: 'IMRT',
    linac: 'Machine 1',
    sim_to_treat: 1,
    priority: 'Emergency',
    referrer: 'Dr. Emily Hartman',
    diagnosis_code: 'C34.90',
  },
  {
    fractions: 23,
    site: 'Lakeside',
    physician: 'Dr Wong',
    date: '2023-09-05',
    diagnosis: 'neuro',
    fraction_dose: 1.24,
    adherence: true,
    technique: 'IMRT',
    linac: 'Machine 3',
    sim_to_treat: 8,
    priority: 'Emergency',
    referrer: 'Dr. Julian Foster',
    diagnosis_code: 'C71.9',
  },
  {
    fractions: 12,
    site: 'Red Hills',
    physician: 'Dr Smith',
    date: '2023-05-25',
    diagnosis: 'breast',
    fraction_dose: 3.32,
    adherence: true,
    technique: 'Unspecified',
    linac: 'Machine 2',
    sim_to_treat: 4,
    priority: 'Urgent',
    referrer: 'Dr. Michael Stern',
    diagnosis_code: 'C79.81',
  },
  {
    fractions: 19,
    site: 'Rosebush',
    physician: 'Dr Lopez',
    date: '2023-08-11',
    diagnosis: 'Unspecified',
    fraction_dose: 1.44,
    adherence: true,
    technique: 'Conformal',
    linac: 'Machine 3',
    sim_to_treat: 4,
    priority: 'Urgent',
    referrer: 'Dr. Emily Hartman',
    diagnosis_code: 'C20',
  },
  {
    fractions: 19,
    site: 'Campsie',
    physician: 'Dr Lopez',
    date: '2023-08-26',
    diagnosis: 'prostate',
    fraction_dose: 3.11,
    adherence: true,
    technique: 'Brachytherapy',
    linac: 'Machine 3',
    sim_to_treat: 1,
    priority: 'Standard',
    referrer: 'Dr. Emily Hartman',
    diagnosis_code: 'C20',
  },
  {
    fractions: 24,
    site: 'Campsie',
    physician: 'Dr Lopez',
    date: '2023-07-24',
    diagnosis: 'neuro',
    fraction_dose: 2.07,
    adherence: true,
    technique: 'IMRT',
    linac: 'Machine 4',
    sim_to_treat: 4,
    priority: 'Standard',
    referrer: 'Dr. Sophie Greenberg',
    diagnosis_code: 'C20',
  },
  {
    fractions: 23,
    site: 'Red Hills',
    physician: 'Dr Adams',
    date: '2023-08-04',
    diagnosis: 'colorectal',
    fraction_dose: 2.0,
    adherence: true,
    technique: 'IMRT',
    linac: 'Machine 3',
    sim_to_treat: 4,
    priority: 'Urgent',
    referrer: 'Dr. Clara Thompson',
    diagnosis_code: 'C61',
  },
  {
    fractions: 19,
    site: 'Rosebush',
    physician: 'Dr Adams',
    date: '2023-06-22',
    diagnosis: 'breast',
    fraction_dose: 2.2,
    adherence: true,
    technique: 'Electrons',
    linac: 'Machine 2',
    sim_to_treat: 7,
    priority: 'Emergency',
    referrer: 'Dr. Rachel Nguyen',
    diagnosis_code: 'C61',
  },
  {
    fractions: 17,
    site: 'Campsie',
    physician: 'Dr Wong',
    date: '2023-04-03',
    diagnosis: 'Unspecified',
    fraction_dose: 3.13,
    adherence: true,
    technique: 'Electrons',
    linac: 'Machine 4',
    sim_to_treat: 3,
    priority: 'Standard',
    referrer: 'Dr. Julian Foster',
    diagnosis_code: 'C61',
  },
  {
    fractions: 18,
    site: 'Red Hills',
    physician: 'Dr Lopez',
    date: '2023-11-11',
    diagnosis: 'colorectal',
    fraction_dose: 2.11,
    adherence: true,
    technique: 'Stereotactic',
    linac: 'Machine 2',
    sim_to_treat: 7,
    priority: 'Emergency',
    referrer: 'Dr. Clara Thompson',
    diagnosis_code: 'C71.9',
  },
  {
    fractions: 13,
    site: 'Red Hills',
    physician: 'Dr Adams',
    date: '2023-07-05',
    diagnosis: 'breast',
    fraction_dose: 1.7,
    adherence: true,
    technique: 'Brachytherapy',
    linac: 'Machine 1',
    sim_to_treat: 10,
    priority: 'Standard',
    referrer: 'Dr. Emily Hartman',
    diagnosis_code: 'C79.81',
  },
  {
    fractions: 21,
    site: 'Rosebush',
    physician: 'Dr Smith',
    date: '2023-12-17',
    diagnosis: 'neuro',
    fraction_dose: 1.47,
    adherence: true,
    technique: 'Stereotactic',
    linac: 'Machine 3',
    sim_to_treat: 10,
    priority: 'Urgent',
    referrer: 'Dr. Michael Stern',
    diagnosis_code: 'C71.9',
  },
  {
    fractions: 10,
    site: 'Campsie',
    physician: 'Dr Lopez',
    date: '2023-07-19',
    diagnosis: 'skin',
    fraction_dose: 1.41,
    adherence: true,
    technique: 'IMRT',
    linac: 'Machine 2',
    sim_to_treat: 8,
    priority: 'Standard',
    referrer: 'Dr. Clara Thompson',
    diagnosis_code: 'C34.90',
  },
  {
    fractions: 17,
    site: 'Lakeside',
    physician: 'Dr Wong',
    date: '2023-08-05',
    diagnosis: 'colorectal',
    fraction_dose: 2.18,
    adherence: true,
    technique: 'Brachytherapy',
    linac: 'Machine 1',
    sim_to_treat: 7,
    priority: 'Standard',
    referrer: 'Dr. Alex Reynolds',
    diagnosis_code: 'C20',
  },
  {
    fractions: 21,
    site: 'Campsie',
    physician: 'Dr Lopez',
    date: '2023-07-13',
    diagnosis: 'skin',
    fraction_dose: 2.7,
    adherence: true,
    technique: 'Stereotactic',
    linac: 'Machine 3',
    sim_to_treat: 3,
    priority: 'Emergency',
    referrer: 'Dr. Michael Stern',
    diagnosis_code: 'C61',
  },
  {
    fractions: 21,
    site: 'Rosebush',
    physician: 'Dr Smith',
    date: '2023-04-29',
    diagnosis: 'colorectal',
    fraction_dose: 2.97,
    adherence: true,
    technique: 'Unspecified',
    linac: 'Machine 3',
    sim_to_treat: 5,
    priority: 'Standard',
    referrer: 'Dr. Julian Foster',
    diagnosis_code: 'C20',
  },
  {
    fractions: 25,
    site: 'Rosebush',
    physician: 'Dr Wong',
    date: '2023-04-15',
    diagnosis: 'Unspecified',
    fraction_dose: 2.02,
    adherence: true,
    technique: 'IMRT',
    linac: 'Machine 2',
    sim_to_treat: 2,
    priority: 'Emergency',
    referrer: 'Dr. Jonathan Blake',
    diagnosis_code: 'C43.9',
  },
  {
    fractions: 12,
    site: 'Red Hills',
    physician: 'Dr Lopez',
    date: '2023-07-31',
    diagnosis: 'Unspecified',
    fraction_dose: 2.49,
    adherence: true,
    technique: 'VMAT',
    linac: 'Machine 1',
    sim_to_treat: 1,
    priority: 'Emergency',
    referrer: 'Dr. Emily Hartman',
    diagnosis_code: 'C34.90',
  },
  {
    fractions: 13,
    site: 'Lakeside',
    physician: 'Dr Adams',
    date: '2023-04-07',
    diagnosis: 'prostate',
    fraction_dose: 3.03,
    adherence: true,
    technique: 'IMRT',
    linac: 'Machine 2',
    sim_to_treat: 7,
    priority: 'Standard',
    referrer: 'Dr. Jonathan Blake',
    diagnosis_code: 'C43.9',
  },
  {
    fractions: 17,
    site: 'Lakeside',
    physician: 'Dr Smith',
    date: '2023-05-16',
    diagnosis: 'breast',
    fraction_dose: 2.79,
    adherence: true,
    technique: 'VMAT',
    linac: 'Machine 2',
    sim_to_treat: 3,
    priority: 'Standard',
    referrer: 'Dr. Clara Thompson',
    diagnosis_code: 'C20',
  },
  {
    fractions: 23,
    site: 'Campsie',
    physician: 'Dr Lopez',
    date: '2023-03-16',
    diagnosis: 'skin',
    fraction_dose: 2.3,
    adherence: true,
    technique: 'IMRT',
    linac: 'Machine 4',
    sim_to_treat: 3,
    priority: 'Emergency',
    referrer: 'Dr. Rachel Nguyen',
    diagnosis_code: 'C71.9',
  },
  {
    fractions: 23,
    site: 'Campsie',
    physician: 'Dr Lopez',
    date: '2023-12-03',
    diagnosis: 'prostate',
    fraction_dose: 1.71,
    adherence: true,
    technique: 'IMRT',
    linac: 'Machine 3',
    sim_to_treat: 6,
    priority: 'Emergency',
    referrer: 'Dr. Rachel Nguyen',
    diagnosis_code: 'C43.9',
  },
  {
    fractions: 19,
    site: 'Lakeside',
    physician: 'Dr Wong',
    date: '2023-09-30',
    diagnosis: 'colorectal',
    fraction_dose: 3.14,
    adherence: true,
    technique: 'Conformal',
    linac: 'Machine 3',
    sim_to_treat: 2,
    priority: 'Emergency',
    referrer: 'Dr. Victor Hernandes',
    diagnosis_code: 'C79.81',
  },
  {
    fractions: 11,
    site: 'Campsie',
    physician: 'Dr Smith',
    date: '2023-12-15',
    diagnosis: 'neuro',
    fraction_dose: 2.04,
    adherence: true,
    technique: 'Stereotactic',
    linac: 'Machine 4',
    sim_to_treat: 4,
    priority: 'Urgent',
    referrer: 'Dr. Michael Stern',
    diagnosis_code: 'C61',
  },
  {
    fractions: 19,
    site: 'Red Hills',
    physician: 'Dr Wong',
    date: '2023-03-08',
    diagnosis: 'Unspecified',
    fraction_dose: 3.35,
    adherence: true,
    technique: 'Electrons',
    linac: 'Machine 2',
    sim_to_treat: 3,
    priority: 'Standard',
    referrer: 'Dr. Victor Hernandes',
    diagnosis_code: 'C71.9',
  },
  {
    fractions: 16,
    site: 'Campsie',
    physician: 'Dr Adams',
    date: '2023-11-14',
    diagnosis: 'metastasis',
    fraction_dose: 2.07,
    adherence: true,
    technique: 'Electrons',
    linac: 'Machine 3',
    sim_to_treat: 3,
    priority: 'Emergency',
    referrer: 'Dr. Michael Stern',
    diagnosis_code: 'C43.9',
  },
  {
    fractions: 17,
    site: 'Campsie',
    physician: 'Dr Smith',
    date: '2023-10-31',
    diagnosis: 'skin',
    fraction_dose: 1.94,
    adherence: false,
    technique: 'Electrons',
    linac: 'Machine 3',
    sim_to_treat: 10,
    priority: 'Emergency',
    referrer: 'Dr. Clara Thompson',
    diagnosis_code: 'C71.9',
  },
  {
    fractions: 21,
    site: 'Campsie',
    physician: 'Dr Wong',
    date: '2023-03-04',
    diagnosis: 'colorectal',
    fraction_dose: 2.63,
    adherence: true,
    technique: 'Brachytherapy',
    linac: 'Machine 4',
    sim_to_treat: 4,
    priority: 'Urgent',
    referrer: 'Dr. Sophie Greenberg',
    diagnosis_code: 'C61',
  },
  {
    fractions: 16,
    site: 'Campsie',
    physician: 'Dr Wong',
    date: '2023-09-18',
    diagnosis: 'skin',
    fraction_dose: 2.88,
    adherence: true,
    technique: 'Brachytherapy',
    linac: 'Machine 3',
    sim_to_treat: 8,
    priority: 'Urgent',
    referrer: 'Dr. Jonathan Blake',
    diagnosis_code: 'C71.9',
  },
  {
    fractions: 23,
    site: 'Campsie',
    physician: 'Dr Adams',
    date: '2023-12-19',
    diagnosis: 'Unspecified',
    fraction_dose: 3.37,
    adherence: true,
    technique: 'Unspecified',
    linac: 'Machine 4',
    sim_to_treat: 10,
    priority: 'Emergency',
    referrer: 'Dr. Jonathan Blake',
    diagnosis_code: 'C61',
  },
  {
    fractions: 22,
    site: 'Red Hills',
    physician: 'Dr Lopez',
    date: '2023-10-31',
    diagnosis: 'metastasis',
    fraction_dose: 2.38,
    adherence: true,
    technique: 'Conformal',
    linac: 'Machine 3',
    sim_to_treat: 2,
    priority: 'Standard',
    referrer: 'Dr. Victor Hernandes',
    diagnosis_code: 'C71.9',
  },
  {
    fractions: 23,
    site: 'Rosebush',
    physician: 'Dr Adams',
    date: '2023-01-12',
    diagnosis: 'Unspecified',
    fraction_dose: 2.29,
    adherence: true,
    technique: 'IMRT',
    linac: 'Machine 4',
    sim_to_treat: 3,
    priority: 'Urgent',
    referrer: 'Dr. Clara Thompson',
    diagnosis_code: 'C71.9',
  },
  {
    fractions: 13,
    site: 'Red Hills',
    physician: 'Dr Adams',
    date: '2023-05-06',
    diagnosis: 'metastasis',
    fraction_dose: 2.81,
    adherence: true,
    technique: 'Conformal',
    linac: 'Machine 2',
    sim_to_treat: 7,
    priority: 'Emergency',
    referrer: 'Dr. Julian Foster',
    diagnosis_code: 'C71.9',
  },
  {
    fractions: 24,
    site: 'Lakeside',
    physician: 'Dr Smith',
    date: '2023-09-28',
    diagnosis: 'prostate',
    fraction_dose: 3.27,
    adherence: true,
    technique: 'Unspecified',
    linac: 'Machine 1',
    sim_to_treat: 4,
    priority: 'Standard',
    referrer: 'Dr. Jonathan Blake',
    diagnosis_code: 'C34.90',
  },
  {
    fractions: 14,
    site: 'Campsie',
    physician: 'Dr Lopez',
    date: '2023-01-26',
    diagnosis: 'neuro',
    fraction_dose: 1.73,
    adherence: true,
    technique: 'Electrons',
    linac: 'Machine 1',
    sim_to_treat: 3,
    priority: 'Standard',
    referrer: 'Dr. Rachel Nguyen',
    diagnosis_code: 'C61',
  },
  {
    fractions: 13,
    site: 'Lakeside',
    physician: 'Dr Smith',
    date: '2023-01-05',
    diagnosis: 'metastasis',
    fraction_dose: 1.22,
    adherence: true,
    technique: 'Unspecified',
    linac: 'Machine 3',
    sim_to_treat: 4,
    priority: 'Emergency',
    referrer: 'Dr. Alex Reynolds',
    diagnosis_code: 'C79.81',
  },
  {
    fractions: 11,
    site: 'Lakeside',
    physician: 'Dr Smith',
    date: '2023-08-08',
    diagnosis: 'prostate',
    fraction_dose: 1.61,
    adherence: true,
    technique: 'Stereotactic',
    linac: 'Machine 1',
    sim_to_treat: 3,
    priority: 'Urgent',
    referrer: 'Dr. Aisha Patel',
    diagnosis_code: 'C34.90',
  },
  {
    fractions: 18,
    site: 'Rosebush',
    physician: 'Dr Wong',
    date: '2023-08-03',
    diagnosis: 'breast',
    fraction_dose: 2.39,
    adherence: true,
    technique: 'Unspecified',
    linac: 'Machine 1',
    sim_to_treat: 9,
    priority: 'Standard',
    referrer: 'Dr. Jonathan Blake',
    diagnosis_code: 'C20',
  },
  {
    fractions: 23,
    site: 'Red Hills',
    physician: 'Dr Smith',
    date: '2023-07-14',
    diagnosis: 'colorectal',
    fraction_dose: 3.17,
    adherence: true,
    technique: 'Brachytherapy',
    linac: 'Machine 3',
    sim_to_treat: 1,
    priority: 'Emergency',
    referrer: 'Dr. Rachel Nguyen',
    diagnosis_code: 'C61',
  },
  {
    fractions: 12,
    site: 'Lakeside',
    physician: 'Dr Lopez',
    date: '2023-12-13',
    diagnosis: 'breast',
    fraction_dose: 1.71,
    adherence: true,
    technique: 'VMAT',
    linac: 'Machine 1',
    sim_to_treat: 9,
    priority: 'Urgent',
    referrer: 'Dr. Rachel Nguyen',
    diagnosis_code: 'C71.9',
  },
  {
    fractions: 25,
    site: 'Rosebush',
    physician: 'Dr Lopez',
    date: '2023-11-24',
    diagnosis: 'skin',
    fraction_dose: 2.67,
    adherence: true,
    technique: 'Brachytherapy',
    linac: 'Machine 2',
    sim_to_treat: 7,
    priority: 'Urgent',
    referrer: 'Dr. Victor Hernandes',
    diagnosis_code: 'C20',
  },
  {
    fractions: 18,
    site: 'Campsie',
    physician: 'Dr Smith',
    date: '2023-09-01',
    diagnosis: 'neuro',
    fraction_dose: 1.47,
    adherence: true,
    technique: 'Brachytherapy',
    linac: 'Machine 3',
    sim_to_treat: 4,
    priority: 'Urgent',
    referrer: 'Dr. Emily Hartman',
    diagnosis_code: 'C43.9',
  },
  {
    fractions: 20,
    site: 'Rosebush',
    physician: 'Dr Adams',
    date: '2023-07-24',
    diagnosis: 'neuro',
    fraction_dose: 2.94,
    adherence: true,
    technique: 'VMAT',
    linac: 'Machine 4',
    sim_to_treat: 6,
    priority: 'Standard',
    referrer: 'Dr. Jonathan Blake',
    diagnosis_code: 'C61',
  },
  {
    fractions: 15,
    site: 'Red Hills',
    physician: 'Dr Smith',
    date: '2023-03-09',
    diagnosis: 'breast',
    fraction_dose: 3.23,
    adherence: true,
    technique: 'VMAT',
    linac: 'Machine 3',
    sim_to_treat: 9,
    priority: 'Emergency',
    referrer: 'Dr. Julian Foster',
    diagnosis_code: 'C34.90',
  },
  {
    fractions: 19,
    site: 'Lakeside',
    physician: 'Dr Smith',
    date: '2023-12-01',
    diagnosis: 'Unspecified',
    fraction_dose: 2.62,
    adherence: true,
    technique: 'VMAT',
    linac: 'Machine 1',
    sim_to_treat: 1,
    priority: 'Emergency',
    referrer: 'Dr. Emily Hartman',
    diagnosis_code: 'C79.81',
  },
  {
    fractions: 21,
    site: 'Lakeside',
    physician: 'Dr Smith',
    date: '2023-11-18',
    diagnosis: 'breast',
    fraction_dose: 2.8,
    adherence: true,
    technique: 'Conformal',
    linac: 'Machine 3',
    sim_to_treat: 6,
    priority: 'Emergency',
    referrer: 'Dr. Alex Reynolds',
    diagnosis_code: 'C71.9',
  },
  {
    fractions: 14,
    site: 'Campsie',
    physician: 'Dr Wong',
    date: '2023-12-09',
    diagnosis: 'metastasis',
    fraction_dose: 2.58,
    adherence: true,
    technique: 'IMRT',
    linac: 'Machine 1',
    sim_to_treat: 5,
    priority: 'Emergency',
    referrer: 'Dr. Julian Foster',
    diagnosis_code: 'C71.9',
  },
  {
    fractions: 25,
    site: 'Rosebush',
    physician: 'Dr Smith',
    date: '2023-09-16',
    diagnosis: 'skin',
    fraction_dose: 2.61,
    adherence: true,
    technique: 'Stereotactic',
    linac: 'Machine 1',
    sim_to_treat: 1,
    priority: 'Standard',
    referrer: 'Dr. Alex Reynolds',
    diagnosis_code: 'C43.9',
  },
  {
    fractions: 20,
    site: 'Rosebush',
    physician: 'Dr Lopez',
    date: '2023-01-23',
    diagnosis: 'metastasis',
    fraction_dose: 3.15,
    adherence: true,
    technique: 'Brachytherapy',
    linac: 'Machine 1',
    sim_to_treat: 10,
    priority: 'Emergency',
    referrer: 'Dr. Julian Foster',
    diagnosis_code: 'C61',
  },
  {
    fractions: 15,
    site: 'Red Hills',
    physician: 'Dr Wong',
    date: '2023-05-03',
    diagnosis: 'skin',
    fraction_dose: 3.03,
    adherence: true,
    technique: 'VMAT',
    linac: 'Machine 2',
    sim_to_treat: 6,
    priority: 'Standard',
    referrer: 'Dr. Aisha Patel',
    diagnosis_code: 'C79.81',
  },
  {
    fractions: 19,
    site: 'Rosebush',
    physician: 'Dr Wong',
    date: '2023-10-06',
    diagnosis: 'metastasis',
    fraction_dose: 1.22,
    adherence: true,
    technique: 'VMAT',
    linac: 'Machine 2',
    sim_to_treat: 4,
    priority: 'Emergency',
    referrer: 'Dr. Victor Hernandes',
    diagnosis_code: 'C61',
  },
  {
    fractions: 16,
    site: 'Lakeside',
    physician: 'Dr Lopez',
    date: '2023-11-20',
    diagnosis: 'breast',
    fraction_dose: 3.13,
    adherence: true,
    technique: 'IMRT',
    linac: 'Machine 4',
    sim_to_treat: 3,
    priority: 'Emergency',
    referrer: 'Dr. Emily Hartman',
    diagnosis_code: 'C79.81',
  },
  {
    fractions: 21,
    site: 'Rosebush',
    physician: 'Dr Smith',
    date: '2023-05-03',
    diagnosis: 'colorectal',
    fraction_dose: 1.36,
    adherence: true,
    technique: 'Unspecified',
    linac: 'Machine 1',
    sim_to_treat: 9,
    priority: 'Emergency',
    referrer: 'Dr. Sophie Greenberg',
    diagnosis_code: 'C71.9',
  },
  {
    fractions: 13,
    site: 'Campsie',
    physician: 'Dr Smith',
    date: '2023-01-14',
    diagnosis: 'breast',
    fraction_dose: 3.07,
    adherence: true,
    technique: 'Stereotactic',
    linac: 'Machine 2',
    sim_to_treat: 10,
    priority: 'Urgent',
    referrer: 'Dr. Aisha Patel',
    diagnosis_code: 'C43.9',
  },
  {
    fractions: 11,
    site: 'Rosebush',
    physician: 'Dr Adams',
    date: '2023-09-22',
    diagnosis: 'skin',
    fraction_dose: 2.11,
    adherence: true,
    technique: 'Brachytherapy',
    linac: 'Machine 3',
    sim_to_treat: 3,
    priority: 'Standard',
    referrer: 'Dr. Alex Reynolds',
    diagnosis_code: 'C79.81',
  },
  {
    fractions: 24,
    site: 'Campsie',
    physician: 'Dr Adams',
    date: '2023-08-25',
    diagnosis: 'metastasis',
    fraction_dose: 2.34,
    adherence: true,
    technique: 'IMRT',
    linac: 'Machine 4',
    sim_to_treat: 6,
    priority: 'Standard',
    referrer: 'Dr. Clara Thompson',
    diagnosis_code: 'C61',
  },
  {
    fractions: 17,
    site: 'Red Hills',
    physician: 'Dr Adams',
    date: '2023-09-18',
    diagnosis: 'neuro',
    fraction_dose: 2.79,
    adherence: true,
    technique: 'Conformal',
    linac: 'Machine 2',
    sim_to_treat: 10,
    priority: 'Urgent',
    referrer: 'Dr. Rachel Nguyen',
    diagnosis_code: 'C20',
  },
  {
    fractions: 12,
    site: 'Rosebush',
    physician: 'Dr Lopez',
    date: '2023-12-15',
    diagnosis: 'breast',
    fraction_dose: 1.75,
    adherence: true,
    technique: 'Electrons',
    linac: 'Machine 4',
    sim_to_treat: 7,
    priority: 'Standard',
    referrer: 'Dr. Clara Thompson',
    diagnosis_code: 'C79.81',
  },
  {
    fractions: 14,
    site: 'Rosebush',
    physician: 'Dr Lopez',
    date: '2023-11-19',
    diagnosis: 'metastasis',
    fraction_dose: 1.87,
    adherence: true,
    technique: 'Brachytherapy',
    linac: 'Machine 2',
    sim_to_treat: 10,
    priority: 'Emergency',
    referrer: 'Dr. Emily Hartman',
    diagnosis_code: 'C43.9',
  },
  {
    fractions: 18,
    site: 'Rosebush',
    physician: 'Dr Wong',
    date: '2023-10-21',
    diagnosis: 'colorectal',
    fraction_dose: 1.99,
    adherence: true,
    technique: 'Electrons',
    linac: 'Machine 4',
    sim_to_treat: 10,
    priority: 'Urgent',
    referrer: 'Dr. Clara Thompson',
    diagnosis_code: 'C34.90',
  },
  {
    fractions: 14,
    site: 'Red Hills',
    physician: 'Dr Lopez',
    date: '2023-12-21',
    diagnosis: 'Unspecified',
    fraction_dose: 2.43,
    adherence: true,
    technique: 'Unspecified',
    linac: 'Machine 1',
    sim_to_treat: 3,
    priority: 'Standard',
    referrer: 'Dr. Emily Hartman',
    diagnosis_code: 'C79.81',
  },
  {
    fractions: 21,
    site: 'Campsie',
    physician: 'Dr Wong',
    date: '2023-06-23',
    diagnosis: 'breast',
    fraction_dose: 2.65,
    adherence: true,
    technique: 'Unspecified',
    linac: 'Machine 3',
    sim_to_treat: 7,
    priority: 'Emergency',
    referrer: 'Dr. Rachel Nguyen',
    diagnosis_code: 'C61',
  },
  {
    fractions: 14,
    site: 'Lakeside',
    physician: 'Dr Wong',
    date: '2023-06-25',
    diagnosis: 'skin',
    fraction_dose: 2.89,
    adherence: true,
    technique: 'VMAT',
    linac: 'Machine 1',
    sim_to_treat: 2,
    priority: 'Emergency',
    referrer: 'Dr. Jonathan Blake',
    diagnosis_code: 'C71.9',
  },
  {
    fractions: 18,
    site: 'Red Hills',
    physician: 'Dr Lopez',
    date: '2023-08-19',
    diagnosis: 'skin',
    fraction_dose: 1.25,
    adherence: false,
    technique: 'Unspecified',
    linac: 'Machine 4',
    sim_to_treat: 4,
    priority: 'Standard',
    referrer: 'Dr. Jonathan Blake',
    diagnosis_code: 'C79.81',
  },
  {
    fractions: 18,
    site: 'Campsie',
    physician: 'Dr Smith',
    date: '2023-12-25',
    diagnosis: 'Unspecified',
    fraction_dose: 1.84,
    adherence: true,
    technique: 'IMRT',
    linac: 'Machine 4',
    sim_to_treat: 9,
    priority: 'Urgent',
    referrer: 'Dr. Emily Hartman',
    diagnosis_code: 'C20',
  },
  {
    fractions: 12,
    site: 'Lakeside',
    physician: 'Dr Smith',
    date: '2023-05-01',
    diagnosis: 'colorectal',
    fraction_dose: 2.71,
    adherence: true,
    technique: 'Electrons',
    linac: 'Machine 1',
    sim_to_treat: 6,
    priority: 'Standard',
    referrer: 'Dr. Michael Stern',
    diagnosis_code: 'C71.9',
  },
  {
    fractions: 14,
    site: 'Lakeside',
    physician: 'Dr Wong',
    date: '2023-08-22',
    diagnosis: 'Unspecified',
    fraction_dose: 3.18,
    adherence: true,
    technique: 'IMRT',
    linac: 'Machine 2',
    sim_to_treat: 2,
    priority: 'Urgent',
    referrer: 'Dr. Jonathan Blake',
    diagnosis_code: 'C61',
  },
  {
    fractions: 23,
    site: 'Rosebush',
    physician: 'Dr Lopez',
    date: '2023-12-03',
    diagnosis: 'Unspecified',
    fraction_dose: 2.76,
    adherence: true,
    technique: 'VMAT',
    linac: 'Machine 3',
    sim_to_treat: 4,
    priority: 'Standard',
    referrer: 'Dr. Sophie Greenberg',
    diagnosis_code: 'C43.9',
  },
  {
    fractions: 14,
    site: 'Rosebush',
    physician: 'Dr Adams',
    date: '2023-08-03',
    diagnosis: 'metastasis',
    fraction_dose: 2.85,
    adherence: true,
    technique: 'Brachytherapy',
    linac: 'Machine 4',
    sim_to_treat: 7,
    priority: 'Emergency',
    referrer: 'Dr. Sophie Greenberg',
    diagnosis_code: 'C43.9',
  },
  {
    fractions: 18,
    site: 'Campsie',
    physician: 'Dr Wong',
    date: '2023-05-06',
    diagnosis: 'breast',
    fraction_dose: 2.36,
    adherence: true,
    technique: 'Brachytherapy',
    linac: 'Machine 4',
    sim_to_treat: 8,
    priority: 'Standard',
    referrer: 'Dr. Clara Thompson',
    diagnosis_code: 'C20',
  },
  {
    fractions: 20,
    site: 'Campsie',
    physician: 'Dr Adams',
    date: '2023-12-03',
    diagnosis: 'Unspecified',
    fraction_dose: 1.74,
    adherence: false,
    technique: 'Conformal',
    linac: 'Machine 3',
    sim_to_treat: 5,
    priority: 'Standard',
    referrer: 'Dr. Rachel Nguyen',
    diagnosis_code: 'C71.9',
  },
  {
    fractions: 21,
    site: 'Red Hills',
    physician: 'Dr Smith',
    date: '2023-04-17',
    diagnosis: 'neuro',
    fraction_dose: 2.52,
    adherence: true,
    technique: 'IMRT',
    linac: 'Machine 4',
    sim_to_treat: 4,
    priority: 'Emergency',
    referrer: 'Dr. Victor Hernandes',
    diagnosis_code: 'C34.90',
  },
  {
    fractions: 11,
    site: 'Rosebush',
    physician: 'Dr Wong',
    date: '2023-04-12',
    diagnosis: 'colorectal',
    fraction_dose: 2.29,
    adherence: true,
    technique: 'Stereotactic',
    linac: 'Machine 4',
    sim_to_treat: 8,
    priority: 'Emergency',
    referrer: 'Dr. Emily Hartman',
    diagnosis_code: 'C79.81',
  },
  {
    fractions: 14,
    site: 'Lakeside',
    physician: 'Dr Smith',
    date: '2023-09-20',
    diagnosis: 'colorectal',
    fraction_dose: 1.47,
    adherence: true,
    technique: 'Stereotactic',
    linac: 'Machine 4',
    sim_to_treat: 3,
    priority: 'Standard',
    referrer: 'Dr. Sophie Greenberg',
    diagnosis_code: 'C43.9',
  },
  {
    fractions: 13,
    site: 'Red Hills',
    physician: 'Dr Adams',
    date: '2023-01-05',
    diagnosis: 'breast',
    fraction_dose: 3.4,
    adherence: true,
    technique: 'Unspecified',
    linac: 'Machine 2',
    sim_to_treat: 2,
    priority: 'Standard',
    referrer: 'Dr. Rachel Nguyen',
    diagnosis_code: 'C34.90',
  },
  {
    fractions: 14,
    site: 'Red Hills',
    physician: 'Dr Adams',
    date: '2023-08-24',
    diagnosis: 'neuro',
    fraction_dose: 2.09,
    adherence: true,
    technique: 'VMAT',
    linac: 'Machine 1',
    sim_to_treat: 5,
    priority: 'Urgent',
    referrer: 'Dr. Clara Thompson',
    diagnosis_code: 'C20',
  },
  {
    fractions: 22,
    site: 'Lakeside',
    physician: 'Dr Adams',
    date: '2023-07-21',
    diagnosis: 'metastasis',
    fraction_dose: 2.67,
    adherence: true,
    technique: 'Unspecified',
    linac: 'Machine 4',
    sim_to_treat: 8,
    priority: 'Emergency',
    referrer: 'Dr. Clara Thompson',
    diagnosis_code: 'C79.81',
  },
  {
    fractions: 12,
    site: 'Campsie',
    physician: 'Dr Adams',
    date: '2023-04-12',
    diagnosis: 'neuro',
    fraction_dose: 2.16,
    adherence: true,
    technique: 'IMRT',
    linac: 'Machine 1',
    sim_to_treat: 2,
    priority: 'Urgent',
    referrer: 'Dr. Jonathan Blake',
    diagnosis_code: 'C61',
  },
  {
    fractions: 24,
    site: 'Lakeside',
    physician: 'Dr Lopez',
    date: '2023-06-07',
    diagnosis: 'breast',
    fraction_dose: 2.4,
    adherence: true,
    technique: 'Conformal',
    linac: 'Machine 4',
    sim_to_treat: 5,
    priority: 'Standard',
    referrer: 'Dr. Jonathan Blake',
    diagnosis_code: 'C71.9',
  },
  {
    fractions: 14,
    site: 'Lakeside',
    physician: 'Dr Lopez',
    date: '2023-10-25',
    diagnosis: 'colorectal',
    fraction_dose: 2.48,
    adherence: true,
    technique: 'Stereotactic',
    linac: 'Machine 2',
    sim_to_treat: 6,
    priority: 'Standard',
    referrer: 'Dr. Michael Stern',
    diagnosis_code: 'C34.90',
  },
  {
    fractions: 16,
    site: 'Lakeside',
    physician: 'Dr Wong',
    date: '2023-01-02',
    diagnosis: 'Unspecified',
    fraction_dose: 2.79,
    adherence: true,
    technique: 'Conformal',
    linac: 'Machine 1',
    sim_to_treat: 7,
    priority: 'Standard',
    referrer: 'Dr. Jonathan Blake',
    diagnosis_code: 'C79.81',
  },
  {
    fractions: 19,
    site: 'Red Hills',
    physician: 'Dr Wong',
    date: '2023-05-02',
    diagnosis: 'prostate',
    fraction_dose: 2.25,
    adherence: true,
    technique: 'Stereotactic',
    linac: 'Machine 4',
    sim_to_treat: 3,
    priority: 'Emergency',
    referrer: 'Dr. Jonathan Blake',
    diagnosis_code: 'C79.81',
  },
  {
    fractions: 14,
    site: 'Rosebush',
    physician: 'Dr Smith',
    date: '2023-09-27',
    diagnosis: 'colorectal',
    fraction_dose: 2.05,
    adherence: true,
    technique: 'Brachytherapy',
    linac: 'Machine 4',
    sim_to_treat: 10,
    priority: 'Emergency',
    referrer: 'Dr. Jonathan Blake',
    diagnosis_code: 'C43.9',
  },
  {
    fractions: 11,
    site: 'Campsie',
    physician: 'Dr Smith',
    date: '2023-07-11',
    diagnosis: 'metastasis',
    fraction_dose: 1.5,
    adherence: true,
    technique: 'Conformal',
    linac: 'Machine 3',
    sim_to_treat: 6,
    priority: 'Standard',
    referrer: 'Dr. Rachel Nguyen',
    diagnosis_code: 'C61',
  },
  {
    fractions: 22,
    site: 'Red Hills',
    physician: 'Dr Adams',
    date: '2023-05-20',
    diagnosis: 'Unspecified',
    fraction_dose: 2.19,
    adherence: true,
    technique: 'Conformal',
    linac: 'Machine 3',
    sim_to_treat: 1,
    priority: 'Urgent',
    referrer: 'Dr. Jonathan Blake',
    diagnosis_code: 'C79.81',
  },
  {
    fractions: 11,
    site: 'Campsie',
    physician: 'Dr Lopez',
    date: '2023-01-23',
    diagnosis: 'Unspecified',
    fraction_dose: 2.33,
    adherence: false,
    technique: 'IMRT',
    linac: 'Machine 4',
    sim_to_treat: 10,
    priority: 'Urgent',
    referrer: 'Dr. Victor Hernandes',
    diagnosis_code: 'C43.9',
  },
  {
    fractions: 17,
    site: 'Red Hills',
    physician: 'Dr Smith',
    date: '2023-11-29',
    diagnosis: 'metastasis',
    fraction_dose: 3.2,
    adherence: true,
    technique: 'VMAT',
    linac: 'Machine 4',
    sim_to_treat: 10,
    priority: 'Emergency',
    referrer: 'Dr. Aisha Patel',
    diagnosis_code: 'C79.81',
  },
  {
    fractions: 12,
    site: 'Campsie',
    physician: 'Dr Smith',
    date: '2023-12-16',
    diagnosis: 'Unspecified',
    fraction_dose: 3.08,
    adherence: true,
    technique: 'Unspecified',
    linac: 'Machine 1',
    sim_to_treat: 8,
    priority: 'Standard',
    referrer: 'Dr. Michael Stern',
    diagnosis_code: 'C43.9',
  },
  {
    fractions: 17,
    site: 'Lakeside',
    physician: 'Dr Wong',
    date: '2023-12-14',
    diagnosis: 'colorectal',
    fraction_dose: 2.58,
    adherence: true,
    technique: 'Brachytherapy',
    linac: 'Machine 3',
    sim_to_treat: 2,
    priority: 'Emergency',
    referrer: 'Dr. Jonathan Blake',
    diagnosis_code: 'C43.9',
  },
  {
    fractions: 19,
    site: 'Rosebush',
    physician: 'Dr Wong',
    date: '2023-06-16',
    diagnosis: 'breast',
    fraction_dose: 2.02,
    adherence: true,
    technique: 'Brachytherapy',
    linac: 'Machine 4',
    sim_to_treat: 1,
    priority: 'Emergency',
    referrer: 'Dr. Emily Hartman',
    diagnosis_code: 'C61',
  },
  {
    fractions: 11,
    site: 'Campsie',
    physician: 'Dr Lopez',
    date: '2023-11-12',
    diagnosis: 'prostate',
    fraction_dose: 2.16,
    adherence: true,
    technique: 'Conformal',
    linac: 'Machine 2',
    sim_to_treat: 1,
    priority: 'Standard',
    referrer: 'Dr. Emily Hartman',
    diagnosis_code: 'C79.81',
  },
  {
    fractions: 21,
    site: 'Campsie',
    physician: 'Dr Smith',
    date: '2023-07-30',
    diagnosis: 'skin',
    fraction_dose: 2.12,
    adherence: true,
    technique: 'Stereotactic',
    linac: 'Machine 1',
    sim_to_treat: 7,
    priority: 'Urgent',
    referrer: 'Dr. Alex Reynolds',
    diagnosis_code: 'C34.90',
  },
  {
    fractions: 23,
    site: 'Red Hills',
    physician: 'Dr Lopez',
    date: '2023-02-26',
    diagnosis: 'neuro',
    fraction_dose: 1.31,
    adherence: true,
    technique: 'Electrons',
    linac: 'Machine 3',
    sim_to_treat: 7,
    priority: 'Emergency',
    referrer: 'Dr. Sophie Greenberg',
    diagnosis_code: 'C71.9',
  },
  {
    fractions: 11,
    site: 'Red Hills',
    physician: 'Dr Smith',
    date: '2023-10-19',
    diagnosis: 'prostate',
    fraction_dose: 3.39,
    adherence: true,
    technique: 'Electrons',
    linac: 'Machine 3',
    sim_to_treat: 9,
    priority: 'Standard',
    referrer: 'Dr. Emily Hartman',
    diagnosis_code: 'C79.81',
  },
  {
    fractions: 11,
    site: 'Rosebush',
    physician: 'Dr Smith',
    date: '2023-12-05',
    diagnosis: 'Unspecified',
    fraction_dose: 1.51,
    adherence: true,
    technique: 'VMAT',
    linac: 'Machine 1',
    sim_to_treat: 9,
    priority: 'Urgent',
    referrer: 'Dr. Emily Hartman',
    diagnosis_code: 'C71.9',
  },
  {
    fractions: 13,
    site: 'Lakeside',
    physician: 'Dr Lopez',
    date: '2023-09-07',
    diagnosis: 'neuro',
    fraction_dose: 3.33,
    adherence: true,
    technique: 'Brachytherapy',
    linac: 'Machine 1',
    sim_to_treat: 4,
    priority: 'Emergency',
    referrer: 'Dr. Sophie Greenberg',
    diagnosis_code: 'C43.9',
  },
  {
    fractions: 14,
    site: 'Lakeside',
    physician: 'Dr Adams',
    date: '2023-01-26',
    diagnosis: 'metastasis',
    fraction_dose: 1.77,
    adherence: true,
    technique: 'Unspecified',
    linac: 'Machine 2',
    sim_to_treat: 7,
    priority: 'Standard',
    referrer: 'Dr. Aisha Patel',
    diagnosis_code: 'C20',
  },
  {
    fractions: 12,
    site: 'Red Hills',
    physician: 'Dr Adams',
    date: '2023-05-02',
    diagnosis: 'neuro',
    fraction_dose: 2.82,
    adherence: true,
    technique: 'VMAT',
    linac: 'Machine 4',
    sim_to_treat: 10,
    priority: 'Emergency',
    referrer: 'Dr. Emily Hartman',
    diagnosis_code: 'C43.9',
  },
  {
    fractions: 20,
    site: 'Rosebush',
    physician: 'Dr Adams',
    date: '2023-10-13',
    diagnosis: 'Unspecified',
    fraction_dose: 3.23,
    adherence: true,
    technique: 'Brachytherapy',
    linac: 'Machine 1',
    sim_to_treat: 5,
    priority: 'Standard',
    referrer: 'Dr. Alex Reynolds',
    diagnosis_code: 'C34.90',
  },
  {
    fractions: 21,
    site: 'Rosebush',
    physician: 'Dr Wong',
    date: '2023-02-22',
    diagnosis: 'colorectal',
    fraction_dose: 3.19,
    adherence: true,
    technique: 'Electrons',
    linac: 'Machine 2',
    sim_to_treat: 6,
    priority: 'Standard',
    referrer: 'Dr. Victor Hernandes',
    diagnosis_code: 'C34.90',
  },
  {
    fractions: 18,
    site: 'Lakeside',
    physician: 'Dr Lopez',
    date: '2023-12-14',
    diagnosis: 'colorectal',
    fraction_dose: 1.37,
    adherence: true,
    technique: 'IMRT',
    linac: 'Machine 1',
    sim_to_treat: 9,
    priority: 'Emergency',
    referrer: 'Dr. Aisha Patel',
    diagnosis_code: 'C43.9',
  },
  {
    fractions: 16,
    site: 'Lakeside',
    physician: 'Dr Wong',
    date: '2023-08-02',
    diagnosis: 'Unspecified',
    fraction_dose: 3.21,
    adherence: true,
    technique: 'IMRT',
    linac: 'Machine 4',
    sim_to_treat: 10,
    priority: 'Urgent',
    referrer: 'Dr. Michael Stern',
    diagnosis_code: 'C34.90',
  },
  {
    fractions: 24,
    site: 'Lakeside',
    physician: 'Dr Smith',
    date: '2023-05-08',
    diagnosis: 'metastasis',
    fraction_dose: 1.85,
    adherence: true,
    technique: 'Conformal',
    linac: 'Machine 1',
    sim_to_treat: 5,
    priority: 'Urgent',
    referrer: 'Dr. Emily Hartman',
    diagnosis_code: 'C34.90',
  },
  {
    fractions: 16,
    site: 'Campsie',
    physician: 'Dr Wong',
    date: '2023-11-17',
    diagnosis: 'skin',
    fraction_dose: 3.26,
    adherence: true,
    technique: 'Conformal',
    linac: 'Machine 4',
    sim_to_treat: 1,
    priority: 'Standard',
    referrer: 'Dr. Alex Reynolds',
    diagnosis_code: 'C71.9',
  },
  {
    fractions: 16,
    site: 'Lakeside',
    physician: 'Dr Smith',
    date: '2023-01-08',
    diagnosis: 'breast',
    fraction_dose: 2.53,
    adherence: true,
    technique: 'Conformal',
    linac: 'Machine 3',
    sim_to_treat: 9,
    priority: 'Emergency',
    referrer: 'Dr. Aisha Patel',
    diagnosis_code: 'C61',
  },
  {
    fractions: 21,
    site: 'Rosebush',
    physician: 'Dr Smith',
    date: '2023-08-29',
    diagnosis: 'neuro',
    fraction_dose: 2.43,
    adherence: true,
    technique: 'IMRT',
    linac: 'Machine 3',
    sim_to_treat: 1,
    priority: 'Emergency',
    referrer: 'Dr. Sophie Greenberg',
    diagnosis_code: 'C79.81',
  },
  {
    fractions: 12,
    site: 'Lakeside',
    physician: 'Dr Smith',
    date: '2023-12-12',
    diagnosis: 'Unspecified',
    fraction_dose: 2.13,
    adherence: true,
    technique: 'VMAT',
    linac: 'Machine 3',
    sim_to_treat: 4,
    priority: 'Standard',
    referrer: 'Dr. Emily Hartman',
    diagnosis_code: 'C61',
  },
  {
    fractions: 21,
    site: 'Lakeside',
    physician: 'Dr Wong',
    date: '2023-02-01',
    diagnosis: 'skin',
    fraction_dose: 2.75,
    adherence: true,
    technique: 'Brachytherapy',
    linac: 'Machine 2',
    sim_to_treat: 6,
    priority: 'Emergency',
    referrer: 'Dr. Emily Hartman',
    diagnosis_code: 'C71.9',
  },
  {
    fractions: 16,
    site: 'Campsie',
    physician: 'Dr Smith',
    date: '2023-03-19',
    diagnosis: 'breast',
    fraction_dose: 2.45,
    adherence: true,
    technique: 'Conformal',
    linac: 'Machine 1',
    sim_to_treat: 6,
    priority: 'Emergency',
    referrer: 'Dr. Aisha Patel',
    diagnosis_code: 'C34.90',
  },
  {
    fractions: 18,
    site: 'Campsie',
    physician: 'Dr Adams',
    date: '2023-03-11',
    diagnosis: 'prostate',
    fraction_dose: 2.39,
    adherence: true,
    technique: 'Unspecified',
    linac: 'Machine 2',
    sim_to_treat: 2,
    priority: 'Standard',
    referrer: 'Dr. Rachel Nguyen',
    diagnosis_code: 'C34.90',
  },
  {
    fractions: 10,
    site: 'Red Hills',
    physician: 'Dr Adams',
    date: '2023-10-24',
    diagnosis: 'Unspecified',
    fraction_dose: 2.69,
    adherence: true,
    technique: 'Electrons',
    linac: 'Machine 2',
    sim_to_treat: 7,
    priority: 'Emergency',
    referrer: 'Dr. Aisha Patel',
    diagnosis_code: 'C34.90',
  },
  {
    fractions: 16,
    site: 'Campsie',
    physician: 'Dr Wong',
    date: '2023-02-13',
    diagnosis: 'skin',
    fraction_dose: 2.8,
    adherence: true,
    technique: 'Stereotactic',
    linac: 'Machine 2',
    sim_to_treat: 5,
    priority: 'Urgent',
    referrer: 'Dr. Rachel Nguyen',
    diagnosis_code: 'C20',
  },
  {
    fractions: 21,
    site: 'Campsie',
    physician: 'Dr Adams',
    date: '2023-12-06',
    diagnosis: 'neuro',
    fraction_dose: 1.69,
    adherence: true,
    technique: 'VMAT',
    linac: 'Machine 1',
    sim_to_treat: 3,
    priority: 'Urgent',
    referrer: 'Dr. Alex Reynolds',
    diagnosis_code: 'C34.90',
  },
  {
    fractions: 15,
    site: 'Lakeside',
    physician: 'Dr Wong',
    date: '2023-01-03',
    diagnosis: 'breast',
    fraction_dose: 3.14,
    adherence: true,
    technique: 'Electrons',
    linac: 'Machine 2',
    sim_to_treat: 8,
    priority: 'Urgent',
    referrer: 'Dr. Sophie Greenberg',
    diagnosis_code: 'C71.9',
  },
  {
    fractions: 12,
    site: 'Rosebush',
    physician: 'Dr Adams',
    date: '2023-05-12',
    diagnosis: 'metastasis',
    fraction_dose: 1.74,
    adherence: false,
    technique: 'Stereotactic',
    linac: 'Machine 4',
    sim_to_treat: 1,
    priority: 'Standard',
    referrer: 'Dr. Julian Foster',
    diagnosis_code: 'C34.90',
  },
  {
    fractions: 19,
    site: 'Campsie',
    physician: 'Dr Lopez',
    date: '2023-02-05',
    diagnosis: 'breast',
    fraction_dose: 1.91,
    adherence: true,
    technique: 'Unspecified',
    linac: 'Machine 4',
    sim_to_treat: 10,
    priority: 'Emergency',
    referrer: 'Dr. Victor Hernandes',
    diagnosis_code: 'C20',
  },
  {
    fractions: 24,
    site: 'Campsie',
    physician: 'Dr Lopez',
    date: '2023-10-02',
    diagnosis: 'metastasis',
    fraction_dose: 2.05,
    adherence: true,
    technique: 'Brachytherapy',
    linac: 'Machine 1',
    sim_to_treat: 5,
    priority: 'Emergency',
    referrer: 'Dr. Alex Reynolds',
    diagnosis_code: 'C34.90',
  },
  {
    fractions: 25,
    site: 'Rosebush',
    physician: 'Dr Lopez',
    date: '2023-11-22',
    diagnosis: 'metastasis',
    fraction_dose: 2.41,
    adherence: false,
    technique: 'VMAT',
    linac: 'Machine 3',
    sim_to_treat: 6,
    priority: 'Emergency',
    referrer: 'Dr. Rachel Nguyen',
    diagnosis_code: 'C61',
  },
  {
    fractions: 25,
    site: 'Campsie',
    physician: 'Dr Lopez',
    date: '2023-05-20',
    diagnosis: 'colorectal',
    fraction_dose: 2.17,
    adherence: true,
    technique: 'Electrons',
    linac: 'Machine 2',
    sim_to_treat: 4,
    priority: 'Emergency',
    referrer: 'Dr. Rachel Nguyen',
    diagnosis_code: 'C79.81',
  },
  {
    fractions: 14,
    site: 'Lakeside',
    physician: 'Dr Smith',
    date: '2023-05-03',
    diagnosis: 'skin',
    fraction_dose: 1.56,
    adherence: true,
    technique: 'Electrons',
    linac: 'Machine 4',
    sim_to_treat: 6,
    priority: 'Emergency',
    referrer: 'Dr. Alex Reynolds',
    diagnosis_code: 'C61',
  },
  {
    fractions: 11,
    site: 'Lakeside',
    physician: 'Dr Wong',
    date: '2023-09-27',
    diagnosis: 'Unspecified',
    fraction_dose: 1.84,
    adherence: true,
    technique: 'Unspecified',
    linac: 'Machine 1',
    sim_to_treat: 7,
    priority: 'Urgent',
    referrer: 'Dr. Alex Reynolds',
    diagnosis_code: 'C20',
  },
  {
    fractions: 10,
    site: 'Rosebush',
    physician: 'Dr Wong',
    date: '2023-05-18',
    diagnosis: 'metastasis',
    fraction_dose: 2.98,
    adherence: true,
    technique: 'Unspecified',
    linac: 'Machine 1',
    sim_to_treat: 10,
    priority: 'Standard',
    referrer: 'Dr. Emily Hartman',
    diagnosis_code: 'C61',
  },
];

export default MockData;
