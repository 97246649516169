import React, { useState } from 'react';

import { FullPatient, RadioOptions } from 'op-interfaces';
import { RegistrationContainer, RadioGroupField } from 'op-components';
import { snakeToCamel, resolveListDataValue } from 'op-utils';
import { AlertInfo } from 'op-interfaces';
import { ListData } from 'shared-components/interfaces';
import { NewsCard } from 'shared-components/components';
import { ErrorExclamation, Success } from 'shared-components/images';

import { categoryFieldNames } from './constants';
import { REGISTRATION_CONFLICTS_CONTENT } from './registrationConflictContent';
import moment from 'moment';

import './RegistrationConflicts.scss';
import { ErrorOutline as ErrorOutlineIcon } from '@mui/icons-material';

interface Props {
  patient: FullPatient;
  refData: { [key: string]: ListData[] };
  updatePatient: any;
  resolvePatientConflicts: any;
  isPso?: boolean;
  saveStatus?: string;
}

interface CategoriesFields {
  [key: string]: {
    [key: string]: {
      [key: string]: string;
    };
  };
}

const preNestedCategory = ['emergencyContact'];

const mapFieldToCategory = (fields: any, categoryFieldNames: any): any => {
  const categoryNames = Object.keys(categoryFieldNames).filter(
    (categoryName) => !preNestedCategory.includes(categoryName),
  );
  const mappedFieldsToCategories = Object.entries(fields).reduce((mappedFieldsToCategories, field) => {
    const [fieldName, fieldDetails] = field;

    categoryNames.forEach((categoryName) => {
      if (categoryFieldNames[categoryName].hasOwnProperty(fieldName)) {
        if (mappedFieldsToCategories[categoryName as keyof typeof mappedFieldsToCategories]) {
          //@ts-ignore
          mappedFieldsToCategories[categoryName][fieldName] = fieldDetails;
        } else {
          //@ts-ignore
          mappedFieldsToCategories[categoryName] = { [fieldName]: fieldDetails };
        }
      }
    });
    return mappedFieldsToCategories;
  }, {});

  //TODO: make this more generic for other future nested fields.
  if (fields.hasOwnProperty('emergencyContact')) {
    const { emergencyContact } = fields;
    return { ...mappedFieldsToCategories, emergencyContact };
  } else {
    return mappedFieldsToCategories;
  }
};

const displayLabelAs = ({
  value,
  refData,
  formatAs,
}: {
  value: any;
  refData: ListData[];
  formatAs: string | undefined;
}): string => {
  const { NOT_PROVIDED } = REGISTRATION_CONFLICTS_CONTENT.RADIO.LABEL;
  if (value === '') {
    return NOT_PROVIDED;
  } else if (refData) {
    return resolveListDataValue(value.toString(), refData);
  } else if (formatAs) {
    if (formatAs == 'dayMonth') {
      return moment(value).format('MM / YYYY');
    }
  }
  return value;
};

const RegistrationConflicts = ({
  patient,
  refData,
  updatePatient,
  resolvePatientConflicts,
  isPso,
  saveStatus,
}: Props) => {
  const { HEADER, SUBHEADER, NO_DATA_CONFLICTS_MESSAGE, RADIO } = REGISTRATION_CONFLICTS_CONTENT;
  const diffMqData = JSON.parse(patient.diffMqData);
  const mappedDiffDataToCategories = mapFieldToCategory(diffMqData, categoryFieldNames);

  const [categoriesFields, setCategoriesFields] = useState(mappedDiffDataToCategories);

  const LISTED_FIELDS = {
    name_prefix: refData.titleRefData,
    relationship: refData.relationshipsRefData,
    heritage: refData.heritageRefData,
    gender: refData.genderRefData,
    marital_status: refData.maritalStatusRefData,
    country_of_birth: refData.countryOfBirthRefData,
    language_spoken: refData.languageSpokenRefData,
    medicare_type: refData.medicareTypeRefData,
    aus_state: refData.ausStateRefData,
  };

  const handleChange = async (
    category: string,
    fieldName: string,
    patientId: string,
    event: React.ChangeEvent<HTMLInputElement>,
  ) => {
    let diffSelectedKey = fieldName;
    let diffSelectedValue = event.target.value;
    const updatedCategoryField = {
      [category]: {
        ...categoriesFields[category],
        [diffSelectedKey]: {
          ...categoriesFields[category][diffSelectedKey],
          selectedOption: diffSelectedValue,
        },
      },
    };
    setCategoriesFields({ ...categoriesFields, ...updatedCategoryField });

    if (preNestedCategory.includes(category)) {
      diffSelectedKey = category;
      diffSelectedValue = JSON.stringify({ ...updatedCategoryField[category] });
    }
    updatePatient({ variables: { patientId, diffSelectedKey, diffSelectedValue } });
  };

  const resolveConflictsIncomplete = Object.values(categoriesFields as CategoriesFields)
    .reduce(
      (values: { selectedOption: string; mqConflictValue: string }[], categoryFields): any[] => [
        ...values,
        ...Object.values(categoryFields),
      ],
      [],
    )
    .map((diffField: { selectedOption: string; mqConflictValue: string }) => diffField['selectedOption'])
    .includes('');

  const alertHeader: AlertInfo = {
    displayStyle: 'warning',
    title: 'Warning: ',
    text: 'Resolve data conflicts. Please resolve these conflicts first before reviewing the registration form.',
  };

  const hasDataConflicts =
    patient && patient.diffMqData && Object.entries(JSON.parse(`${patient.diffMqData}`)).length !== 0;

  return (
    <RegistrationContainer
      patient={patient}
      genderRefData={refData.genderRefData}
      isPso={isPso}
      saveStatus={saveStatus}
      resolvePatientConflicts={resolvePatientConflicts}
      hasDataConflicts={hasDataConflicts}
      resolveConflictsIncomplete={resolveConflictsIncomplete}
      alertHeader={alertHeader}>
      {hasDataConflicts ? (
        <div className="form-page form-page-registration-conflicts">
          <div className="form-heading">{HEADER}</div>
          <div className="info-container">
            <ErrorOutlineIcon color="warning" className="alertIcon" />
            <span data-test-id="form-subheader">{SUBHEADER}</span>
          </div>
          <form className="form-container">
            {Object.entries(categoriesFields as CategoriesFields).map((data, idx) => {
              const [category, fields] = data;
              const { categoryLabel } = categoryFieldNames[category as keyof typeof categoryFieldNames];

              const categoryFieldsIncomplete = Object.entries(fields)
                .map((field) => field[1]['selectedOption'])
                .includes('');

              const transformHzLabel = (category: any, name: any): any => {
                const value = preNestedCategory.includes(category)
                  ? // @ts-ignore
                    patient[category]![snakeToCamel(name)]
                  : patient[snakeToCamel(name)];
                return {
                  value,
                  refData: LISTED_FIELDS[name as keyof typeof LISTED_FIELDS],
                };
              };

              return (
                <div className="newspaper-container" key={`category-card-${category}-${idx}`}>
                  <NewsCard
                    title={categoryLabel}
                    invalidContent={categoryFieldsIncomplete}
                    primaryIcon={
                      categoryFieldsIncomplete ? (
                        <ErrorExclamation className={'primary-icon invalid-icon'} />
                      ) : (
                        <Success className={'primary-icon valid-icon'} />
                      )
                    }>
                    {Object.entries(fields).map((fieldObject: any[], idx) => {
                      const [name, diffDetails] = fieldObject;
                      // @ts-ignore
                      const fieldLabel = categoryFieldNames[category][name]['label'];
                      // @ts-ignore
                      const formatAs = categoryFieldNames[category][name]['formatAs'];

                      const mqOptionLabel = displayLabelAs({
                        value: diffDetails.mqConflictValue,
                        refData: LISTED_FIELDS[name as keyof typeof LISTED_FIELDS],
                        formatAs: formatAs,
                      });

                      const transFormHzLabel = transformHzLabel(category, name);
                      transFormHzLabel.formatAs = formatAs;
                      const hzOptionLabel = displayLabelAs(transFormHzLabel);

                      const options: RadioOptions[] = [
                        { label: hzOptionLabel, value: RADIO.VALUE.HZ },
                        { label: mqOptionLabel, value: RADIO.VALUE.MQ },
                      ];

                      return (
                        <RadioGroupField
                          key={`radiogroupfield-${name}-${idx}`}
                          name={`${category}${name}`}
                          fieldLabel={fieldLabel}
                          options={options}
                          selectedOption={categoriesFields[category][name]['selectedOption']}
                          handleChange={(e) => handleChange(category, name, patient.id, e)}
                        />
                      );
                    })}
                  </NewsCard>
                </div>
              );
            })}
          </form>
        </div>
      ) : (
        <div>{NO_DATA_CONFLICTS_MESSAGE}</div>
      )}
    </RegistrationContainer>
  );
};

export default RegistrationConflicts;
