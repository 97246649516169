import { gql } from '@apollo/client';

export const REGISTRATION_INFORMATION_NOTICE_QUERY = gql`
  query RegistrationInformationNotice($id: ID!) {
    patient(id: $id) {
      id
      informationNoticeAccepted
      informationNoticeAlliedHealthReceiveUpdates
    }
    informationNoticeContent {
      id
      informationNotice
    }
    user {
      id
      isPso
    }
  }
`;

export const UPDATE_REGISTRATION = gql`
  mutation UpdatePatientInformationNotice(
    $patientId: ID!
    $informationNoticeAccepted: Boolean
    $informationNoticeAlliedHealthReceiveUpdates: Boolean
  ) {
    updatePatientInformationNotice(
      patientId: $patientId
      informationNoticeAccepted: $informationNoticeAccepted
      informationNoticeAlliedHealthReceiveUpdates: $informationNoticeAlliedHealthReceiveUpdates
    ) {
      patient {
        id
        informationNoticeContent
        informationNoticeAccepted
        informationNoticeAlliedHealthReceiveUpdates
      }
    }
  }
`;
