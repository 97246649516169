export const STATIC_CONTENT = [
  {
    id: 2,
    page_id: 'medicare-cost',
    pageTitle: 'Out-of-pocket cost and Medicare',
    pageContent:
      '## What to expect?\nAny out-of-pocket costs associated with your treatment will be discussed with you before starting treatment.\nAn accounts staff member will talk you through the treatment fees, payment options and Medicare reimbursement process.',
    tipsSectionContent:
      'Please feel free to ask us any questions you may have.\nYou’re welcome to bring someone with you or write notes in a journal.',
    coverImage: 'content/images/medicare-cost_kjDdOtv.jpg',
    language: 'en',
  },
  {
    id: 6,
    page_id: 'side-effects',
    pageTitle: 'Side effects',
    pageContent:
      '## What to expect?\nLike most medical treatments, your treatment can cause side effects depending on the type of cancer and where on/in your body it’s located.\nSide effects vary from person to person, so it’s difficult to know how you’ll be affected, some people may experience more side effects towards the end of the course of treatment.\nSide effects may last a few weeks or longer; others may be permanent; your Radiation Oncologist will discuss side effects with you and how to manage them so your experience is as comfortable as possible.\nIf you are undergoing radiation therapy treatment, you may experience some or all of these common side effects:\n- Tiredness\n- Skin changes and inflammation\n- Stress, anxiety',
    tipsSectionContent:
      'If you are concerned about side effects or feel they are not improving, please contact your care team at your treatment centre.\nMuscle mass and bone mineral density, which can be affected by some cancer treatments, can be maintained or even increased with exercise - talk to your Radiation Oncologist or nurse about how exercise physiology may benefit you.\nWe can provide you with information about allied health services to help you during and after your treatment.',
    coverImage: 'content/images/side-effects_tNyTW99.jpg',
    language: 'en',
  },
  {
    id: 7,
    page_id: 'remaining-treatments',
    pageTitle: 'Remaining treatments',
    pageContent:
      '## What to expect?\nThe rest of your radiation therapy appointments will be like your first treatment. The number of treatments depends on your individual plan.\nDuring your treatment you will get to know our friendly team. Please let them know if you have any concerns or problems’.\nAfter your daily treatments you will be able to go about your usual activities. We will provide you with any self-care tips to take home with you.\nWe will monitor how you are feeling during treatment and support you when you need it.',
    tipsSectionContent:
      'Our team aim to minimise your time at our Centre, however occasionally there may be delays. In the event of a delay, our team will keep you as up to date as possible.\nOur team can offer you a variety of different music to choose from. Listening to music while you’re receiving treatment can help you relax.\nIf at any time during your treatment you feel uncomfortable you can tell your Radiation Therapist through the intercom system, or raise your hand and they can stop the treatment and come into the room to speak to you.\nIt could be beneficial to keep a journal throughout your treatment to note any questions, concerns or side effects that you might be experiencing.',
    coverImage: 'content/images/remaining-treatments_fnM04Zs.jpg',
    language: 'en',
  },
  {
    id: 8,
    page_id: 'treatment-aftercare',
    pageTitle: 'Care after treatment',
    pageContent:
      '## What to expect?\nWe don’t leave you at the end of your treatment; we are here for you as long as you need us.\nAfter your treatment, we’ll arrange follow-up appointments to check on your progress. If you have questions before or between appointments, please contact our team.\nWe are here to discuss any health issues or questions you might have post-treatment.\nYour follow up care plan will include checking the results of your treatment and arranging any further treatment, if required.',
    tipsSectionContent:
      'You might want to bring someone with you or keep a journal with you to take note of discussions around:\n- When you can get back to your normal lifestyle\n- How often you will need to see your care team after treatment\n- Symptoms or side effects to keep an eye out for and ways to help manage these\n- Any special dietary and physical activity requirements you might have',
    coverImage: 'content/images/treatment-aftercare_M1YJAQk.jpg',
    language: 'en',
  },
  {
    id: 10,
    page_id: 'chemotherapy-medicare-cost',
    pageTitle: 'Out-of-pocket costs and Medicare',
    pageContent:
      '## What to expect?\nAny out-of-pocket costs associated with your treatment will be discussed with you prior to starting treatment.\n',
    tipsSectionContent:
      'Please feel free to ask questions. Again, you can bring someone with you and write notes in a journal\n',
    coverImage: 'content/images/medicare-cost_dOq4nMu.jpg',
    language: 'en',
  },
  {
    id: 11,
    page_id: 'chemotherapy-imaging-pathology',
    pageTitle: 'Imaging and pathology',
    pageContent:
      '## What to expect?\nIn order to develop your treatment plan, you may be required to attend a few appointments. These could include a visit to an imaging department and pathology centre for your blood tests\n',
    tipsSectionContent:
      'You will be provided with these request forms at the initial consult. These services are available close to our centres.\n',
    coverImage: 'content/images/ct-simulations_0e8LRvg.jpg',
    language: 'en',
  },
  {
    id: 12,
    page_id: 'chemotherapy-review-treatment',
    pageTitle: 'Treatment review',
    pageContent:
      '## What to expect?\nPrior to receiving your cycle of treatment, your medical oncologist will review your results and confirm your treatment plan.\nA nurse will also be available to discuss any concerns you may have.\n',
    tipsSectionContent:
      'Please feel free to ask any questions health related or otherwise. It’s normal to feel anxious. Let the team know how you are feeling.\n',
    coverImage: 'content/images/treatment-review_oOCbjA6.jpg',
    language: 'en',
  },
  {
    id: 13,
    page_id: 'chemotherapy-treatment',
    pageTitle: 'Treatment',
    pageContent:
      '## What to expect?\nThe nursing team will deliver your treatments, which can be given intravenously or as injections. This can last between 1 to 6 hours. You will be required to stay within our centre for this time. The nursing team will monitor and provide care during your treatment.\n',
    tipsSectionContent:
      'You may be in the chemotherapy chair for a few hours so bring a book, iPad or a friend to help pass the time.\n',
    coverImage: 'content/images/nurse-education_qtJ8xxv.jpg',
    language: 'en',
  },
  {
    id: 14,
    page_id: 'chemotherapy-remaining-treatments',
    pageTitle: 'Remaining treatments',
    pageContent:
      '## What to expect?\nFor your remaining treatments, you will be required to have repeat blood tests and treatment reviews with the medical oncologist and nurse.\n',
    tipsSectionContent:
      'We aim to get you familiar with the friendly staff and our process as quickly as possible. Please feel free to ask questions at any time\n',
    coverImage: 'content/images/remaining-treatments_Pq12jY4.jpg',
    language: 'en',
  },
  {
    id: 15,
    page_id: 'chemotherapy-care-after-treatment',
    pageTitle: 'Care after treatment',
    pageContent:
      '## What to expect?\nWe don’t leave you at the end of your treatment; we are here for you as long as you need us.\nAfter your treatment, we’ll arrange follow-up appointments to check on your progress. If you have questions before or between appointments, please contact our team.\nWe are here to discuss any health issues or questions you might have post-treatment.\nYour follow up care plan will include checking the results of your treatment and arranging any further treatment, if required\n',
    tipsSectionContent:
      'You might want to bring someone with your or keep a journal with you to take note of discussions around:\n- When you can get back to your normal lifestyle\n- How often you will need to see your care team after treatment\n- Symptoms or side effects to keep an eye out for\n- Any special dietary requirements you might have\n',
    coverImage: 'content/images/treatment-aftercare_tzaNMuP.jpg',
    language: 'en',
  },
  {
    id: 1,
    page_id: 'initial-consultation',
    pageTitle: 'Initial consultation',
    pageContent:
      '## What to expect?\nBefore treatment starts, your radiation oncologist will explain your diagnosis, the treatment process, options and potential side-effects.\nThey will ask you some basic questions about your medical history and lifestyle, and allow you to ask any questions or raise concerns.\nIf you have any appointments, commitments, or family engagements coming up, please tell us so that we can build your care plan around them.',
    tipsSectionContent:
      'It’s a good idea to bring someone with you to consultation or keep a journal to help you remember what the doctor says.\nPlease refer to local COVID-19 restrictions regarding visitors.\nSome of our centres offer allied health support such as massage, psychology and exercise physiology, so make sure to speak to our friendly staff at the clinic to find out on how you can access these services.\nIf you have any further questions on how to get to the centre, please see the contact details on the appointments page of this portal.\nView [‘Our doctors’](https://www.genesiscare.com/au/our-doctors/) page to find out more about your care team before coming in for your first consultation.',
    coverImage: 'content/images/initial-consultation_1Fv3hVr.jpg',
    language: 'en',
  },
  {
    id: 3,
    page_id: 'nurse-education',
    pageTitle: 'Nurse education',
    pageContent:
      '## What to expect?\nA nurse will assess your overall health, answer any questions you may have and give you advice on how to manage side-effects or symptoms you may experience.\nThe nursing team will refer you to appropriate allied health or support services.',
    tipsSectionContent:
      'Our website has helpful information about cancer and types of treatment available. Visit our website at: [GenesisCare - Cancer Information](http://www.genesiscare.com/au/condition/cancer/)\nIf you have any concerns, please let the nurse know.',
    coverImage: 'content/images/nurse-education_7g7R7uG.jpg',
    language: 'en',
  },
  {
    id: 4,
    page_id: 'ct-simulations',
    pageTitle: 'CT and simulation',
    pageContent:
      '## What to expect?\nTo see the CT simulation process, here is a link to watch a [‘Having a CT scan’](https://vimeo.com/192064322) video.\nYour Radiation Therapist will explain the CT simulation process and answer any questions you may have.\nThey will help you into position for your CT scan on the treatment bed and record your position details to deliver your treatment most accurately.\nTo be able to replicate your treatment daily, there may be small permanent skin ink markers the size of a dot, put onto your skin. These are very small and difficult to see.\nYour Radiation Oncologist will prescribe the best treatment for you. A treatment plan will be developed by our specialist Radiation Therapists and approved by your Radiation Oncologist before you start treatment.',
    tipsSectionContent:
      'People can feel worried, concerned, or anxious, please contact our Centre and let us know how you are feeling and we can help.\nThe room temperature is set to 20°C so please ask for a blanket if you feel cold.\nPlease make sure that you wear, loose and comfortable clothing. You may be asked to change into a gown.\nThe bed surface is hard, this ensures there is minimal movement and enables many different types of treatment to be given safely. The Radiation Therapist will ensure you are comfortable, and your treatment position is easily reproducible.\nWe are here to talk about any concerns you may have and can provide other options if necessary.\nThe time between your CT Simulation appointment and your first treatment may take up to 2 weeks as your Radiation Therapy Team is working to create your tailored treatment plan.',
    coverImage: 'content/images/ct-simulations_KtGcnZa.jpg',
    language: 'en',
  },
  {
    id: 5,
    page_id: 'first-treatment',
    pageTitle: 'Your first treatment',
    pageContent:
      '## What to expect?\nYou will be positioned on the treatment bed in the same position as your CT scan, but this time you will receive radiation treatment, guided by your Radiation Therapists.\nYour Radiation Therapist may take an x-ray image or scan to check your position is correct before treatment.\nThe time allowed for treatment is usually between 10-20 minutes. Most of this time is taken to ensure you are in the perfect position for treatment. The treatment beam itself is only on for 1-2 minutes and is painless.\nDuring this time the Radiation Therapist will leave the room. However, they will be monitoring you on a television screen and intercom system. You can either speak out or raise your hand at any time if you need to.\nYour Radiation Therapists will help you through this process so you feel as comfortable as possible.',
    tipsSectionContent:
      'Please take the time to watch the [‘What is radiation therapy’](http://www.genesiscare.com/au/treatment/cancer/radiation-therapy/) video on our website to help you better understand the treatment and know what to expect.\nIf you have any questions or concerns, please ask one of our friendly staff.',
    coverImage: 'content/images/first-treatment_OuGxFuv.jpg',
    language: 'en',
  },
  {
    id: 9,
    page_id: 'chemotherapy-initial-consultation',
    pageTitle: 'Initial consultation',
    pageContent:
      '## What to expect?\nBefore treatment commences, your medical oncologist will explain the treatment process, treatment options and any potential side effects. You will receive a care plan tailored to your needs.\nA nurse will also be present to assess your overall health status, assist with any questions you have and help with any symptom management or side effects throughout your course of treatment.',
    tipsSectionContent:
      'It is a good idea to bring someone with you to the initial appointment and keep a journal to help you remember what the doctor says.\nPlease refer to local COVID-19 restrictions regarding visitors.\nTake this opportunity to also ask your doctor about options such as scalp cooling.',
    coverImage: 'content/images/initial-consultation_qjTlWex.jpg',
    language: 'en',
  },
];
