import { DoseUnit, GY_TO_CGY } from 'op-enums';

export const MAX_DOSAGE = 150; // In Gy

export const getDoseOverrideMessage = (isDoseInCorrectOrder: boolean, isDoseWithinLimit: boolean, doseUnit: string) => {
  let doseErrorMessage = '';

  if (!isDoseInCorrectOrder) {
    doseErrorMessage += 'Dose values are not in order of high to low ';
  }

  if (!isDoseWithinLimit) {
    if (doseErrorMessage !== '') {
      doseErrorMessage += 'and ';
    }
    if (doseUnit === DoseUnit.CGY) {
      doseErrorMessage += `Dose exceeds limitations of ${MAX_DOSAGE * GY_TO_CGY} ${doseUnit} `;
    } else {
      doseErrorMessage += `Dose exceeds limitations of ${MAX_DOSAGE} ${doseUnit} `;
    }
  }

  return doseErrorMessage;
};
