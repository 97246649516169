import { gql } from '@apollo/client';
import { REGISTRATION_ADDRESS_FRAGMENT } from 'op-graphql/fragments';

export const REVIEW_AND_SUBMIT_QUERY = gql`
  query ReviewAndSubmit($id: ID!) {
    patient(id: $id) {
      id
      patientState
      userProfile {
        id
        showHealthAssessment
        showDistressThermometer
      }
      ida
      attachmentAcknowledged
      regFormStatus
      healthAssessment {
        id
        status
      }
      firstName
      middleName
      lastName
      patientGender
      patientGenderIdentification
      patientGenderIdentificationOther
      dob
      resolvedNamePrefix
      preferredName

      primaryPhone
      secondaryPhone
      email
      workPhone
      employer

      guardian
      guardianRelationship
      emergencyContact {
        id
        contactType
        relationship
        firstName
        lastName
        mobilePhoneNumber
        homePhoneNumber
        address {
          id
          line1
          line2
          city
          postcode
          state
          country
        }
      }

      countryOfBirth
      stateOfBirth
      areaLivedMost
      currentStateHowLong
      inThisStateAllYear
      alternateAddress {
        id
        ...Address
      }
      alternateAddressPhone

      address {
        id
        ...Address
      }
      postalAddressSameAsResidential
      postalAddress {
        id
        ...Address
      }

      referringFirstName
      referringLastName
      referringPractice
      referringIsInMosaiq
      gpFirstName
      gpLastName
      gpPractice
      gpIsInMosaiq
      referrersOtherSpecialists
      referringEditedByPso
      generalPractitionerEditedByPso

      altContactProvided
      emergencyContact {
        id
        contactType
        relationship
        firstName
        lastName
        mobilePhoneNumber
        homePhoneNumber
        email
        authorisedForEnquiries
        supportPerson
      }
      nextOfKinContact {
        id
        contactType
        firstName
        lastName
        relationship
        mobilePhoneNumber
        homePhoneNumber
        email
        authorisedForEnquiries
        supportPerson
      }

      healthMedicareDvaOption
      healthMedicareNumber
      healthMedicareIrn
      medicareExpiryRawYear
      medicareExpiryRawMonth
      dvaExpiryRawYear
      dvaExpiryRawMonth
      healthDvaNumber
      healthDvaType
      healthPrivateHealthInsurance
      healthPrivateHealthFundName
      healthPrivateHealthFundNumber
      healthPensionCard
      healthPensionCardType
      healthPensionCardNumber
      healthPensionCardExpiry
      healthPensionCardStart

      countryOfBirth
      interpreterRequired
      occupation
      niceHeritage
      maritalStatusValue
      niceLanguageAtHome
      ambulanceMembership
      ambulanceMembershipNumber

      informationNoticeAccepted
      informationNoticeAlliedHealthReceiveUpdates

      attachments {
        id
        filename
        url
        documentType
        isRegistrationAttachment
      }
      emrInstance
      emr
    }
    user {
      id
      isPso
    }
    profile(id: $id) {
      id
      registrationAccessType
    }
    distressThermometerInitial(patientId: $id) {
      id
      status
      version
    }
  }
  ${REGISTRATION_ADDRESS_FRAGMENT}
`;

export const SUBMIT_FORM = gql`
  mutation PatientSubmitRegistrationUs($id: ID!) {
    patientSubmitRegistrationUs(id: $id) {
      patient {
        id
        regFormStatus
      }
    }
  }
`;

export const SUBMIT_FORM_PSO = gql`
  mutation StaffSubmitRegistrationUs($id: ID!) {
    staffSubmitRegistrationUs(id: $id) {
      patient {
        id
        regFormStatus
      }
      pdfName
    }
  }
`;
