// eslint-disable-next-line no-use-before-define
import React, { Component } from 'react';

import classNames from 'classnames';

import { MandatoryPatient } from 'op-interfaces';

import { NewsCard } from 'shared-components/components';
import { ListData } from 'shared-components/interfaces';
import { DateTimeConverter } from 'shared-components/utils';
import { PermIdentity } from 'shared-components/images';
import { Region } from 'shared-components/enums';

interface Props {
  patient: MandatoryPatient;
  genderRefData?: ListData[];
}

class RegistrationPatientDetails extends Component<Props> {
  public render(): JSX.Element {
    const { patient } = this.props;

    let patientAddress = '';

    // If patient.address is null, react fails to compile
    // We display patientAddress as '' when patient.address is null
    if (patient.address) {
      patientAddress = patient.address.formattedAddress;
    }

    return (
      <NewsCard title={`${patient.firstName} ${patient.lastName}`} primaryIcon={<PermIdentity />}>
        {this.renderNewsCardRow('EMR:', patient.emrInstance)}
        {this.renderNewsCardRow('Patient ID:', patient.ida)}
        {this.renderNewsCardRow(
          'Date of birth:',
          DateTimeConverter.getFormattedDateAsDDMonthYYYY(patient.dob, import.meta.env.REACT_APP_REGION as Region),
        )}
        {this.renderNewsCardRow('Address:', patientAddress)}
      </NewsCard>
    );
  }

  /**
   * Helper method to generate a details row in the NewsCard component.
   * TODO: This method should eventually be moved to a separate component.
   */
  private renderNewsCardRow = (headingText: string, detailText?: string, fallbackText?: string): JSX.Element => {
    const displayText = this.renderContentMessage(detailText, fallbackText);
    const optionalColouring = displayText === fallbackText || displayText === 'Not provided';
    return (
      <div className="newscard-row">
        <div className="newscard__title newscard--bold">{headingText}</div>
        <div className={classNames('newscard__content', { optional: optionalColouring })}>{displayText}</div>
      </div>
    );
  };

  /**
   * Helper method to generate the detail text or the provided fall-back text if data is missing.
   */
  private renderContentMessage = (content?: string, fallbackText?: string): string => {
    // If null or empty then return missing text
    if (!content || content.trim() === '') {
      return fallbackText || 'Not provided';
    }
    return content;
  };

  private getDisplayValue = (refData: ListData[], option: string): string => {
    for (let i = 0; i < refData.length; i++) {
      if (refData[i].id === option) {
        return refData[i].name;
      }
    }

    return option;
  };
}

export default RegistrationPatientDetails;
