import React from 'react';
import Accordion from '@mui/material/Accordion';
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';
import ArrowForwardIosSharpIcon from '@mui/icons-material/ArrowForwardIosSharp';
import Typography from '@mui/material/Typography';
import { useTheme } from '@mui/material';

interface Props {
  title: string;
  content: string;
}

const QAAccordion = (props: Props): JSX.Element => {
  const { title, content } = props;
  const theme = useTheme();
  return (
    <Accordion style={{ marginBottom: '2px' }}>
      <AccordionSummary
        expandIcon={<ArrowForwardIosSharpIcon sx={{ fontSize: '0.9rem' }} />}
        sx={{
          '& .MuiAccordionSummary-expandIconWrapper.Mui-expanded': {
            transform: 'rotate(90deg)',
          },
        }}>
        <Typography className="question-title" variant="subtitle2" style={{ fontWeight: 'bold' }}>
          {title}
        </Typography>
      </AccordionSummary>
      <AccordionDetails>
        <Typography variant="body2" style={{ color: theme.palette.grey[700] }}>
          {content}
        </Typography>
      </AccordionDetails>
    </Accordion>
  );
};

export default QAAccordion;
