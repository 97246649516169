// eslint-disable-next-line no-use-before-define
import React from 'react';

import './Appointment.scss';

import { DateTimeConverter } from '../../utils';
import { Success, Info } from 'shared-components/images';

const ICONS = {
  EVENT: 'event',
  LOCATION: 'location',
  STAFF: 'staff',
  SUCCESS: 'success',
  INFO: 'info',
};
const CONFIRMED_APPOINTMENT_STATUS = ['initial consultation', 'follow up', 'follow-up consultation'];

/**
 * Interfaces
 */
interface Location {
  name?: string;
  address?: {
    city?: string;
  };
}

interface AppointmentProps {
  appointment: {
    startTime?: string;
    appointmentType?: string;
    location?: Location;
    entity?: string;
  };
}

function Appointment(props: AppointmentProps): JSX.Element {
  // Convert the scheduled appointment time into the required date time components
  const currentLocale = window.navigator.language;
  const localDateTime = props.appointment.startTime ? new Date(props.appointment.startTime) : null;
  let formattedDateComponents;
  if (localDateTime) {
    formattedDateComponents = DateTimeConverter.getFormattedDateComponents(
      currentLocale,
      localDateTime,
    ).formattedComponents;
  } else {
    formattedDateComponents = { dayOfWeek: '', dayOfMonth: '', time: '' }; // Default
  }

  const appointmentType = props.appointment.appointmentType ? props.appointment.appointmentType : '';
  const isConfirmedType = CONFIRMED_APPOINTMENT_STATUS.includes(appointmentType.toLowerCase());
  const cutOffDate = DateTimeConverter.getAppointmentCutOffDate(props.appointment.entity);
  const toBeConfirmed = localDateTime ? localDateTime >= cutOffDate && !isConfirmedType : true;
  const time = toBeConfirmed ? 'TBC' : formattedDateComponents.time.toLowerCase();

  const generateIcon = (icon: string): JSX.Element => {
    switch (icon) {
      case ICONS.SUCCESS:
        return <Success className="icon icon-green" />;
      default:
        return <Info className="icon icon-blue" />;
    }
  };

  const generateConfirmation = (): JSX.Element => {
    if (localDateTime === null) {
      return <div />;
    }

    if (toBeConfirmed) {
      return (
        <div className="appointment-status">
          <div className="appointment-row">
            {generateIcon(ICONS.INFO)}
            <div className="appointment-confirmation">{'Time may vary'}</div>
          </div>
          <div className="more-info">more info</div>
        </div>
      );
    }

    return (
      <div className="appointment-status">
        <div className="appointment-row">
          {generateIcon(ICONS.SUCCESS)}
          <div className="appointment-confirmation">{'Confirmed'}</div>
        </div>
      </div>
    );
  };

  return (
    <div className="appointment">
      <div className="grid-left">
        <div className="dayOfWeek">{formattedDateComponents.dayOfWeek}</div>
        <div className="dayOfMonth">{formattedDateComponents.dayOfMonth}</div>
        <div className="time">{time}</div>
      </div>
      <div className="grid-right">
        <div className="type">{props.appointment.appointmentType ? props.appointment.appointmentType : ''}</div>
        <div className="location-group">
          <div className="department">{props.appointment.location ? props.appointment.location.name : ''}</div>
          <div className="department">
            {props.appointment.location && props.appointment.location.address
              ? props.appointment.location.address.city
              : ''}
          </div>
        </div>
        {generateConfirmation()}
      </div>
      <div className="disclosure-arrow" />
    </div>
  );
}

export default Appointment;
