import React from 'react';
import { styled } from '@mui/system';
import { FileContextProvider } from 'op-contexts/FileContext/FileContext';
import { ROPatientContextProvider } from '../context';
import { GridContainer, GridSection } from 'shared-components/components/Grid';

import OuterContainer from '../OuterContainer';
import AddNotes from '../../Notes/AddNotes';
import ClinicalInformation from './ClinicalInformation';
import OtherInformation from './OtherInformation';
import DoseSiteSummarySimple from './DoseSiteSummarySimple';

import { useWindowSize } from 'shared-components/utils/CustomHooks';
import { LARGE_DISPLAY_SIZE } from 'shared-components/enums';

const SummaryWrapper = styled(GridContainer)`
  position: relative;
`;
const MiddleWrapper = styled('div')<{ $windowWidth: number }>`
  display: flex;
  flex-direction: column;
  width: ${({ $windowWidth }: { $windowWidth: number }) =>
    $windowWidth < LARGE_DISPLAY_SIZE ? 'calc(100% - 88px)' : '75%'};
`;

const InformationWrapper = styled('div')`
  width: 100%;
  height: 66%;
  display: flex;
  margin-bottom: 8px;
  min-height: 0;
`;

const ClinicalInfoWrapper = styled(GridSection)`
  width: 50%;
  margin-right: 8px;
`;

const OtherInfoWrapper = styled(GridSection)`
  width: 50%;
  margin-left: 8px;
`;

const DoseSiteWrapper = styled(GridSection)`
  height: 34%;
  margin-top: 8px;
`;

const Summary = (): JSX.Element => {
  const windowSize = useWindowSize();
  return (
    <FileContextProvider>
      <ROPatientContextProvider>
        <OuterContainer patientCardView={'roSimplified'}>
          <SummaryWrapper id="patient-summary-primary">
            <MiddleWrapper $windowWidth={windowSize?.width}>
              <InformationWrapper>
                <ClinicalInfoWrapper>
                  <ClinicalInformation />
                </ClinicalInfoWrapper>
                <OtherInfoWrapper>
                  <OtherInformation />
                </OtherInfoWrapper>
              </InformationWrapper>
              <DoseSiteWrapper>
                <DoseSiteSummarySimple />
              </DoseSiteWrapper>
            </MiddleWrapper>
            <AddNotes showToast />
          </SummaryWrapper>
        </OuterContainer>
      </ROPatientContextProvider>
    </FileContextProvider>
  );
};

export default Summary;
