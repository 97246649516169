import React, { useEffect, useState } from 'react';
import { Search } from 'shared-components/images';
import FakeRadioButton from '../FakeRadioButton/FakeRadioButton';
import { CategoryProps, FilterProps, TabSelectorValueInterface, ValueProps } from './Interfaces';
import {
  CategoryFilterInputContainer,
  StyledFilterInput,
  TabSelectorCategoryCard,
  TabSelectorCategoryOuter,
  TabSelectorValueCard,
  TabSelectorValuesOuter,
  TabSelectorValueTitle,
  ValueFilterInputContainer,
  NoResultsMessage,
} from './StyledComponents';
import { FilterListOutlined as FilterListOutlinedIcon } from '@mui/icons-material';
import { useTheme } from '@mui/material';

const TabSelectorCategoryFilter = ({ setFilterText, placeholder }: FilterProps): JSX.Element => {
  const theme = useTheme();
  return (
    <CategoryFilterInputContainer>
      <FilterListOutlinedIcon htmlColor={theme.palette.grey[600]} />
      <StyledFilterInput
        type="text"
        name="tab-category-filter"
        placeholder={placeholder}
        onInput={(e: any) => setFilterText(e?.target?.value)}
      />
    </CategoryFilterInputContainer>
  );
};

export const TabSelectorCategories = ({
  categories,
  selectedCategory,
  setSelectedCategory,
  filterPlaceholder,
}: CategoryProps): JSX.Element => {
  const [filterText, setFilterText] = useState('');

  const [filteredCategories, setFilteredCategories] = useState<string[]>([]);

  useEffect(() => {
    // Filter the catergories list based on search text
    setFilteredCategories(
      categories.filter((c: string) => {
        return c.toLowerCase().indexOf(filterText.toLowerCase()) > -1;
      }),
    );
  }, [categories, filterText]);

  return (
    <TabSelectorCategoryOuter>
      <TabSelectorCategoryFilter setFilterText={setFilterText} placeholder={filterPlaceholder} />

      {filteredCategories.map((category: string, index: number) => (
        <TabSelectorCategoryCard
          key={index}
          className={category === selectedCategory ? 'selected' : ''}
          onClick={() => {
            setSelectedCategory(category);
          }}>
          {category}
        </TabSelectorCategoryCard>
      ))}
    </TabSelectorCategoryOuter>
  );
};

export const TabSelectorValues = ({
  values,
  selectedCategory,
  selectedValue,
  setSelectedValue,
  filterPlaceholder,
  hasExpandedContent,
  noResultsMessage = 'No results found for this category',
}: ValueProps): JSX.Element => {
  const [filterText, setFilterText] = useState('');
  const [filteredValues, setFilteredValues] = useState<TabSelectorValueInterface[]>([]);

  useEffect(() => {
    // Filter the catergories list based on search text
    setFilteredValues(
      values.filter((c: TabSelectorValueInterface) => {
        return c.label.toLowerCase().indexOf(filterText.toLowerCase()) > -1;
      }),
    );
  }, [values, filterText]);

  return (
    <TabSelectorValuesOuter>
      <ValueFilterInputContainer>
        <Search />
        <StyledFilterInput
          type="text"
          name="tab-category-filter"
          data-testid="tab-category-filter"
          placeholder={filterPlaceholder}
          onInput={(e: any) => setFilterText(e?.target?.value)}
        />
      </ValueFilterInputContainer>
      {selectedCategory &&
        (filteredValues.length > 0 ? (
          filteredValues.map((value: TabSelectorValueInterface, index: number) => {
            const { TitleComponent } = value;
            return (
              value.category === selectedCategory && (
                <TabSelectorValueCard
                  className={value.id === selectedValue ? 'selected' : ''}
                  key={index}
                  data-testid="tab-selector-value-card"
                  onClick={() => setSelectedValue(value.id)}>
                  {TitleComponent ? (
                    <TitleComponent selectedValue={selectedValue} />
                  ) : (
                    <TabSelectorValueTitle>
                      <FakeRadioButton selected={value.id === selectedValue} />
                      <span>{value.label}</span>
                    </TabSelectorValueTitle>
                  )}

                  {hasExpandedContent && value.id === selectedValue && value.expandedContent && value.expandedContent}
                </TabSelectorValueCard>
              )
            );
          })
        ) : (
          <NoResultsMessage>{noResultsMessage}</NoResultsMessage>
        ))}
    </TabSelectorValuesOuter>
  );
};
