import React from 'react';
import moment from 'moment';
import { calculateMomentAge } from 'shared-components/utils';
import { LoadingSpinner } from 'shared-components/components';
import { PatientDetailsCardProps } from '../interfaces';
import SideBarCard from 'op-pages/MO/Careplan/RightSideBar/SideBarCard';
import { PersonOutline } from '@mui/icons-material';
import { Grid } from '@mui/material';
import { CardLabel, CardData } from './StyledComponents';

const PatientDetailsCard = ({ onEdit, patient, observation, loading }: PatientDetailsCardProps): JSX.Element => {
  if (loading || patient === undefined) {
    return <LoadingSpinner loadingText="" />;
  }
  const age = patient?.dob ? calculateMomentAge(moment(patient.dob)) : 0;
  return (
    <SideBarCard
      icon={<PersonOutline />}
      title="Patient observations"
      subTitle={`Last updated ${observation?.observationDate || '-'}`}
      onEdit={onEdit}>
      <Grid container spacing={2}>
        <CardLabel>Patient weight:</CardLabel>
        <CardData data-cy="obs-weight">{observation?.weight || '-'} kg</CardData>
        <CardLabel>Patient height:</CardLabel>
        <CardData>{observation?.height || '-'} cm</CardData>
        <CardLabel>Patient age:</CardLabel>
        <CardData>{age}</CardData>
        <CardLabel>Patient sex:</CardLabel>
        <CardData>{patient.patientGender}</CardData>
        <CardLabel>BSA:</CardLabel>
        <CardData data-cy="obs-bsa">{observation?.bsa ? observation?.bsa : '-'} m²</CardData>
        <CardLabel>Serum creatinine:</CardLabel>
        <CardData>{observation?.serumCreatinine || '-'} μmol/L</CardData>
        <CardLabel>Creatinine clearance:</CardLabel>
        <CardData data-cy="obs-creatinine-clearance">
          {observation?.creatinineClearance && Number.isFinite(observation?.creatinineClearance)
            ? observation?.creatinineClearance
            : '-'}{' '}
          mL/min
        </CardData>
      </Grid>
    </SideBarCard>
  );
};

export default PatientDetailsCard;
