// eslint-disable-next-line no-use-before-define
import React, { Component, Fragment, ChangeEvent } from 'react';
import classNames from 'classnames';

import './DropDownField.scss';

import { Region } from 'shared-components/enums';
import { ListData, ReactListData } from 'shared-components/interfaces';
import { ErrorInfo } from 'shared-components/components/FormFields';
const REACT_APP_REGION = import.meta.env.REACT_APP_REGION;

const region = REACT_APP_REGION;

interface Props {
  inputKey?: string;
  inputName: string;
  placeholder: string;
  defaultValue?: string | null;
  options: ListData[];
  onChange: (e: React.ChangeEvent<HTMLSelectElement>) => void;
  errors?: string[];
  disabled?: boolean;
  controlled?: boolean;
  innerRef?: any;
  className?: string;
  enabledPlaceholder?: boolean | null;
  errorType?: string;
  dataTestId?: string;
}

interface SelectOptionInterface {
  defaultValue?: string;
  value?: string;
}

class DropDownField extends Component<Props> {
  public render(): JSX.Element {
    const {
      defaultValue,
      disabled,
      errors,
      inputName,
      options,
      placeholder,
      controlled,
      className,
      enabledPlaceholder,
      errorType,
      dataTestId,
    } = this.props;

    const selectOption: SelectOptionInterface = {
      defaultValue: placeholder,
      value: placeholder,
    };

    const foundOptions = options?.filter((optionItem: ListData) => {
      const appKey = (optionItem as ReactListData).appKey;
      if (optionItem.name === defaultValue || optionItem.id === defaultValue || appKey === defaultValue) {
        return optionItem;
      }
    });

    if (foundOptions.length > 0 && defaultValue) {
      selectOption.defaultValue = defaultValue;
      selectOption.value = defaultValue;
    }

    // Controlled drop down so wipe out the default selected value and substitute for the selected value
    if (controlled) {
      delete selectOption.defaultValue;
    } else {
      delete selectOption.value;
    }

    return (
      <Fragment>
        <select
          key={this.props.inputKey}
          ref={this.props.innerRef}
          name={inputName}
          className={classNames(
            'form-fields-dropdown',
            { placeholder: defaultValue ? false : true },
            { 'validation-error': errors && errors.length > 0 },
            { 'UK-dropdown-style': region === Region.UK },
            { 'disabled-arrow': disabled },
            className,
          )}
          onChange={this.onChangeHandler}
          disabled={disabled}
          data-test-id={dataTestId}
          {...selectOption}>
          <option
            key={`option-${this.props.inputKey}`}
            className="placeholder"
            disabled={!enabledPlaceholder ? true : false}
            value={placeholder}>
            {placeholder}
          </option>
          {options.map((option: ListData, index: number): JSX.Element => {
            let selectedOption = false;
            let value = option.id;
            const appKey = (option as ReactListData).appKey;
            if (appKey) {
              value = appKey;
              selectedOption = defaultValue === appKey;
            }

            return (
              <option key={index} value={value} defaultChecked={defaultValue === option.id || selectedOption}>
                {option.name}
              </option>
            );
          })}
        </select>
        <ErrorInfo errors={errors} type={errorType} />
      </Fragment>
    );
  }

  private onChangeHandler = (onChangeEvent: ChangeEvent<HTMLSelectElement>): void => {
    this.props.onChange(onChangeEvent);
  };
}

export default DropDownField;
