import {
  syncConfig,
  chartSpacingConfig,
  creditsConfig,
  exportingConfig,
  handlePointClick,
  TopChartParams,
  setHeight,
  selectPoint,
} from './DefaultConfig';

const columnSeriesMapping = (leftSeriesFields: string[]) => {
  const trimmedFields = leftSeriesFields.slice(1);
  return trimmedFields.map((field, index) => ({
    seriesId: 'series-' + index,
    data: ['Date', field],
  }));
};

const seriesMapping = (leftSeriesFields: string[]) => {
  const trimmedFields = leftSeriesFields.slice(1);

  return trimmedFields.map((field, index) => ({
    id: 'series-' + index,
    type: 'column',
    name: field,
    color: seriesColorMapping[field as keyof typeof seriesColorMapping] || 'rgba(158, 158, 158)',
  })) as Highcharts.SeriesOptionsType[];
};

const seriesColorMapping: { [key: string]: string } = {
  Breast: 'rgba(170, 161, 253)',
  Metastasis: 'rgba(62, 167, 178)',
  Prostate: 'rgba(255, 189, 91)',
  Colorectal: 'rgba(251, 106, 118)',
  Neuro: 'rgba(128, 0, 128)',
  Skin: 'rgba(89, 39, 218)',
};

const ChartStackedColumnPercent = ({
  onPointClick,
  selectedPoint,
  selectedIndex,
  seeMoreUpdated,
  leftSeriesTitle,
  leftSeriesFields,
}: TopChartParams): Dashboards.Board.Options => ({
  components: [
    {
      cell: 'top-chart',
      connector: {
        id: 'top-chart-data',
        columnAssignment: columnSeriesMapping(leftSeriesFields),
      },
      sync: syncConfig,
      type: 'Highcharts',
      chartOptions: {
        exporting: exportingConfig,
        chart: {
          type: 'column',
          animation: false,
          spacing: chartSpacingConfig,
          styledMode: false,
          events: {
            load() {
              setHeight(this);
            },
            redraw() {
              selectPoint(this, selectedPoint, selectedIndex);
            },
          },
        },
        title: { text: '' },
        xAxis: {
          type: 'category',
        },
        yAxis: {
          title: { text: leftSeriesTitle },
        },
        credits: creditsConfig,
        tooltip: {
          pointFormat: '{series.name}: {point.y}<br/>',
          shared: true,
        },
        series: seriesMapping(leftSeriesFields),
        plotOptions: {
          column: {
            stacking: 'normal',
          },
          series: {
            cursor: 'pointer',
            animation: selectedPoint === '' && !seeMoreUpdated,
            states: {
              select: {
                enabled: false,
              },
            },
            point: {
              events: {
                click: function () {
                  handlePointClick(this, onPointClick);
                },
              },
            },
          },
        },
      },
    },
  ],
});

export default ChartStackedColumnPercent;
