import moment from 'moment';
import React, { useMemo } from 'react';
import { DropDownProps } from './DropDownProps';
import { BaseSelect } from 'shared-components/components/FormFields';

interface DropDownRODayProps extends Omit<DropDownProps, 'options'> {
  selectedMonth?: number;
  selectedYear?: number;
  useLastOption: boolean;
}

const DropDownRODay = (props: DropDownRODayProps) => {
  const { selectedMonth, selectedYear, value, onChange, useLastOption, ...otherProps } = props;

  const dayOptions = useMemo(() => {
    const referenceYear = selectedYear ? selectedYear : 2000; // arbitrary leap year value
    const referenceMonth = selectedMonth ? selectedMonth : 12; // arbitrary month value

    let lastDayOfMonthOption = 31;
    const currentYear = parseInt(moment().format('YYYY'));
    const currentMonth = parseInt(moment().format('MM'));
    const currentDay = parseInt(moment().format('DD'));

    if (referenceYear === currentYear && referenceMonth === currentMonth) {
      lastDayOfMonthOption = currentDay;
    } else {
      const endDt = new Date(referenceYear, referenceMonth, 0);
      const lastDayOfMonth = endDt.getDate() ?? 31;
      lastDayOfMonthOption = lastDayOfMonth;
    }

    const daysList = [...Array(lastDayOfMonthOption).keys()];

    return daysList.map((data) => {
      const adjustedData = data + 1;
      return { label: adjustedData.toString(), value: adjustedData.toString() };
    });
  }, [selectedMonth, selectedYear]);

  const modifiedValue = useMemo(() => {
    if (dayOptions.find((a) => a.value == value)) {
      return value;
    }

    let returnValue = '';
    if (value && useLastOption) {
      returnValue = dayOptions[dayOptions.length - 1].value;
    }

    onChange(returnValue);
    return returnValue;
  }, [value, dayOptions]);

  return (
    <BaseSelect
      value={modifiedValue}
      options={[{ label: '--', value: '' }, ...dayOptions]}
      onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
        onChange(event.target.value);
      }}
      {...otherProps}
      sx={{
        '.MuiOutlinedInput-root': {
          backgroundColor: 'white',
        },
        width: '120px',
        paddingRight: '8px',
        paddingBottom: '6px',
      }}
    />
  );
};

DropDownRODay.defaultProps = {
  required: true,
  readonly: false,
  searchable: true,
  useLastOption: true,
  placeholder: 'Date',
};

export default DropDownRODay;
