import { gql } from '@apollo/client';
import { REGISTRATION_ADDRESS_FRAGMENT } from 'op-graphql/fragments';

export const LAST_VISITED_QUERY = gql`
  query RegistrationLastVisited($id: ID!) {
    patient(id: $id) {
      id
      lastVisitedSection
      regFormStatus
    }
    user {
      id
      isPso
    }
  }
`;

export const UPDATE_LAST_VISITED_SECTION = gql`
  mutation UpdatePatientUsBasic($id: ID!, $lastVisitedSection: String!) {
    updatePatientUsBasic(id: $id, lastVisitedSection: $lastVisitedSection) {
      patient {
        id
        lastVisitedSection
        regFormStatus
      }
    }
  }
`;

export const UPDATE_PATIENT_ADDRESS = gql`
  mutation UpdatePatientAddress(
    $patientId: ID!
    $relatedName: String!
    $line1: String
    $line2: String
    $city: String
    $postcode: String
    $state: String
    $country: String
  ) {
    updatePatientAddress(
      patientId: $patientId
      relatedName: $relatedName
      address: { line1: $line1, line2: $line2, city: $city, postcode: $postcode, state: $state, country: $country }
    ) {
      address {
        id
        ...Address
      }
    }
  }
  ${REGISTRATION_ADDRESS_FRAGMENT}
`;

export const REMOVE_PATIENT_ADDRESS = gql`
  mutation RemovePatientAddress($patientId: ID!, $relatedName: String!) {
    removePatientAddress(patientId: $patientId, relatedName: $relatedName) {
      success
    }
  }
`;

export const UPDATE_ALTERNATE_CONTACT_ADDRESS = gql`
  mutation UpdateAlternateContactAddress(
    $patientId: ID!
    $alternateContactId: ID!
    $relatedName: String!
    $line1: String
    $line2: String
    $city: String
    $postcode: String
    $state: String
    $country: String
  ) {
    updateAlternateContactAddress(
      patientId: $patientId
      alternateContactId: $alternateContactId
      relatedName: $relatedName
      address: { line1: $line1, line2: $line2, city: $city, postcode: $postcode, state: $state, country: $country }
    ) {
      address {
        id
        ...Address
      }
    }
  }
  ${REGISTRATION_ADDRESS_FRAGMENT}
`;

export const REMOVE_ALTERNATE_CONTACT_ADDRESS = gql`
  mutation RemoveAlternateContactAddress($patientId: ID!, $alternateContactId: ID!, $relatedName: String!) {
    removeAlternateContactAddress(
      patientId: $patientId
      alternateContactId: $alternateContactId
      relatedName: $relatedName
    ) {
      success
    }
  }
`;
