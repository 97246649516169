import React from 'react';
import { useQuery } from '@apollo/client';
import { styled } from '@mui/system';
import { useFormikContext, FormikProps, getIn } from 'formik';
import { generatePath } from 'react-router';

import { MandatoryPatient } from 'op-interfaces';

import { preCtChartCheckContent } from '../constants';
import { PreCtChartCheckFormValues } from '../interfaces/PreCtChartCheckInterfaces';
import { routes } from '../routes';
import { LocationListData } from '../../shared/interfaces';
import ReviewQcl from '../../shared/components/ReviewQCL';
import { GET_ACTIVE_QCLS } from '../../shared/queries';

import ReviewBasicInfo from './ReviewAndSubmitComponents/ReviewBasicInfo';
import ReviewChemoRisk from './ReviewAndSubmitComponents/ReviewChemoRisk';
import ReviewDevices from './ReviewAndSubmitComponents/ReviewDevices';
import ReviewInfections from './ReviewAndSubmitComponents/ReviewInfections';
import ReviewFallsRisk from './ReviewAndSubmitComponents/ReviewFallsRisk';
import ReviewPressureInjury from './ReviewAndSubmitComponents/ReviewPressureInjury';
import ReviewWellbeing from './ReviewAndSubmitComponents/ReviewWellbeing';
import ReviewACD from './ReviewAndSubmitComponents/ReviewACD';
import ReviewAllergies from './ReviewAndSubmitComponents/ReviewAllergies';

interface Props {
  handleMutation?: any;
  patientId: string;
  formId: string;
  allowedLocations: LocationListData[];
  patient: MandatoryPatient;
  isLocked: boolean;
}

const Wrapper = styled('div')`
  width: 65%;
`;

const ReviewAndSubmit = (props: Props): JSX.Element => {
  const { allowedLocations, patientId, patient, formId, isLocked } = props;
  const { values, errors }: FormikProps<PreCtChartCheckFormValues> = useFormikContext();
  const basicInfoFields = preCtChartCheckContent.basicInformation.fields;
  const chemoRiskFields = preCtChartCheckContent.chemoRisk.fields;
  const implantedMedicalDevicesFields = preCtChartCheckContent.implantedMedicalDevices.fields;
  const infectiousRiskFields = preCtChartCheckContent.infectiousRisk.fields;
  const fallsRiskFields = preCtChartCheckContent.fallsRisk.fields;
  const pressureInjuryFields = preCtChartCheckContent.pressureInjuryRisk.fields;
  const wellbeingFields = preCtChartCheckContent.wellbeing.fields;
  const advanceCareFields = preCtChartCheckContent.advancedCareDirective.fields;
  const allergiesFields = preCtChartCheckContent.allergies.fields;

  const { data, error, loading } = useQuery(GET_ACTIVE_QCLS, {
    variables: { patientId: patientId, isAmendment: values.isAmendment },
  });

  const customQclRules = (fieldName: string, values: object) =>
    (fieldName !== 'cied' && getIn(values, fieldName) === true) ||
    (fieldName === 'cied' &&
      getIn(values, fieldName).length > 0 &&
      getIn(values, 'hasImplantedMedicalDevice') === true);

  const checkErrorValidation = (fields: object): boolean => {
    for (const key of Object.keys(fields)) {
      if (getIn(errors, key)) return true;
    }
    return false;
  };

  const validateMedicalDevices = (): boolean => {
    const allDeviceValues = [
      ...values.cied,
      ...values.venousAccessType,
      ...values.otherImplantedDevices,
      ...values.otherNotListed,
    ];
    const sectionIsEmpty = values.hasImplantedMedicalDevice === true && allDeviceValues.length === 0;
    return Boolean(errors['hasImplantedMedicalDevice'] || sectionIsEmpty);
  };

  return (
    <Wrapper>
      {!loading && (
        <ReviewQcl
          activeQcls={!isLocked && !error ? data.activeTasks : []}
          formContent={preCtChartCheckContent}
          customQclRules={customQclRules}
          isLocked={isLocked}
        />
      )}
      <ReviewBasicInfo
        fields={basicInfoFields}
        values={values}
        error={checkErrorValidation(basicInfoFields)}
        link={generatePath(routes.basicInformation.path, { patientId: patientId, formId: formId })}
        allowedLocations={allowedLocations}
        isLocked={isLocked}
      />
      <ReviewChemoRisk
        fields={chemoRiskFields}
        values={values}
        error={checkErrorValidation(chemoRiskFields)}
        link={generatePath(routes.chemoRisk.path, { patientId: patientId, formId: formId })}
        isLocked={isLocked}
      />

      <ReviewDevices
        fields={implantedMedicalDevicesFields}
        values={values}
        error={validateMedicalDevices()}
        link={generatePath(routes.implantedMedicalDevices.path, { patientId: patientId, formId: formId })}
        isLocked={isLocked}
      />

      <ReviewInfections
        fields={infectiousRiskFields}
        values={values}
        patient={patient}
        error={checkErrorValidation(infectiousRiskFields)}
        link={generatePath(routes.infectiousRisk.path, { patientId: patientId, formId: formId })}
        isLocked={isLocked}
      />

      <ReviewFallsRisk
        fields={fallsRiskFields}
        values={values}
        error={checkErrorValidation(fallsRiskFields)}
        link={generatePath(routes.fallsRisk.path, { patientId: patientId, formId: formId })}
        isLocked={isLocked}
      />
      <ReviewPressureInjury
        fields={pressureInjuryFields}
        values={values}
        error={checkErrorValidation(pressureInjuryFields)}
        link={generatePath(routes.pressureInjuryRisk.path, { patientId: patientId, formId: formId })}
        isLocked={isLocked}
      />
      <ReviewWellbeing
        fields={wellbeingFields}
        values={values}
        error={checkErrorValidation(wellbeingFields)}
        link={generatePath(routes.wellbeing.path, { patientId: patientId, formId: formId })}
        isLocked={isLocked}
      />
      <ReviewACD
        fields={advanceCareFields}
        values={values}
        error={checkErrorValidation(advanceCareFields)}
        link={generatePath(routes.advancedCareDirective.path, { patientId: patientId, formId: formId })}
        isLocked={isLocked}
      />
      <ReviewAllergies
        fields={allergiesFields}
        values={values}
        error={checkErrorValidation(allergiesFields)}
        link={generatePath(routes.allergies.path, { patientId: patientId, formId: formId })}
        isLocked={isLocked}
      />
    </Wrapper>
  );
};

export default ReviewAndSubmit;
