import React from 'react';
import { Field, FieldArray, FormikProps, useFormikContext } from 'formik';
import { InfoCard } from 'op-components';
import { FormStatus } from 'op-enums';
import { InfoCardRowItem } from 'op-interfaces';
import { FormRow, HelperMessage, UserIcon } from 'shared-components/components';
import {
  SelectFieldBase,
  StyledFieldRow,
  TextAreaField,
  ToggleButtonGroupField,
} from 'shared-components/components/FormikComponents';

import { choiceToStringOptions, getLastUpdateUser, transformEmptyString } from '../../helpers';
import { sharedContent } from '../../shared/constants';
import { LastUpdateUserDetails } from '../../shared/interfaces';
import {
  content,
  mapValueToRefData,
  sensoryImpairmentsOptions,
  assistanceOptions,
  SensoryImpairments,
} from '../constants';
import { RegistrationFormData, HealthAssessmentData, PreCtTriageData } from '../interfaces/PreCtTriageInterfaces';
import { mapListData, yesNoOptions } from 'op-utils/helpers';
import { StyledHorizontalLine } from 'op-pages/OP/RegistrationFormUS/Helper';
import SelectButton from 'shared-components/components/UIFormComponents/SelectButton';
import { styled } from '@mui/system';
import { ListData } from 'shared-components/interfaces';
import { YES } from 'op-pages/OP/HealthAssessment/HAPages/constants';
import { BaseSelectItem } from 'op-interfaces/HAInterfaces';
import Grid from '@mui/material/Grid';

interface Props {
  handleMutation: (name: string, value: string | null | boolean | string[]) => void;
  healthAssessmentData: HealthAssessmentData;
  registrationFormData: RegistrationFormData;
  lastUpdateUserData: LastUpdateUserDetails[];
  typeOfResidenceRefData: ListData[];
}

const SubHeading = styled('div')`
  font-style: normal;
  font-size: 18px;
  font-weight: 700;
  line-height: 28px;
  letter-spacing: 0em;
  text-align: left;
`;

const StyledFieldRowMultiSelect = styled(StyledFieldRow)`
  align-items: baseline;
`;
const SocialCircumstances = ({
  handleMutation,
  healthAssessmentData,
  registrationFormData,
  lastUpdateUserData,
  typeOfResidenceRefData,
}: Props): JSX.Element => {
  const { tableTitles, livingSituation, careRequirements, socialMedicalHistory, fields } = content.socialCircumstances;
  const {
    livesAlone,
    livesWith,
    isCarer,
    carerSpecification,
    usualResidence,
    staysAtUsualResidence,
    otherResidence,
    usualResidenceSpecify,
  } = livingSituation.fields;
  const {
    infoHelpers: { hearing: hearingInfo, vision: visionInfo },
  } = careRequirements;
  const {
    infoHelpers: { requireInterpreter: requireInterpreterInfo },
  } = socialMedicalHistory;

  const { values, setFieldValue }: FormikProps<PreCtTriageData> = useFormikContext();
  const {
    needsAssistanceAdls,
    assistance,
    assistanceSpecify,
    hasFamilyOrFriend,
    familyOrFriendSpecification,
    existingSupportService,
    existingSupportSpecification,
    hasSensoryImpairment,
    sensoryImpairments,
    sensoryImpairmentsSpecification,
  } = careRequirements.fields;

  const { englishSecondLanguage, primaryLanguage, requireInterpreter, otherInformation } = socialMedicalHistory.fields;

  const {
    doYouDrink,
    drinkingFrequency,
    doYouSmoke,
    yearsSmoking,
    howFrequentlySmoking,
    whenStoppedSmoking,
    assistAccommodation,
    ulcerTitle,
    diabeticTitle,
    heritageTitle,
    occupationTitle,
  } = tableTitles;

  // Patient registration values
  let isTableVisible = false;
  const { alcoholFrequencyRefData, smokingFrequencyRefData, diabetesRefData } = healthAssessmentData;
  const { occupation, heritage, regFormStatus } = registrationFormData.patient;

  const regoValuesRow: InfoCardRowItem[] = [];
  if (regFormStatus === FormStatus.REG_SUBMITTED) {
    regoValuesRow.push(
      {
        title: occupationTitle,
        value: occupation === '' ? '-' : occupation,
      },
      {
        title: heritageTitle,
        value: mapValueToRefData(heritage, registrationFormData.heritageRefData),
      },
    );
  }

  const getDiabetesData = (hasDiabetes: string, diabetes: BaseSelectItem[]) => {
    if (!hasDiabetes) {
      return '-';
    }

    const diabetesAns = [];
    //@ts-ignore
    diabetesAns.push(choiceToStringOptions[String(hasDiabetes)]);

    const other: string[] = [];
    if (hasDiabetes === 'YES' && diabetes) {
      diabetes.forEach((diabetesObj, index) => {
        if (diabetesObj.other) {
          other.push(
            `Diabetes ${index + 1}: ${mapValueToRefData(diabetesObj.value, diabetesRefData)} - '${diabetesObj.other}'`,
          );
        } else diabetesAns.push(`Diabetes ${index + 1}: ${mapValueToRefData(diabetesObj.value, diabetesRefData)}`);
      });
    }

    return [...diabetesAns, ...other];
  };

  // Add health assessment values, if applicable
  let haValuesRow: InfoCardRowItem[] = [];
  if (healthAssessmentData.healthAssessment) {
    const {
      alcoholBool,
      alcoholFrequency,
      smokingBool,
      smokingFrequency,
      smokingHistory,
      smokingStopRawMonth,
      smokingStopRawYear,
      accommodationAssistance,
      activeInjury,
      activeInjuryReason,
      diabetes,
      diabetesResponses,
    } = healthAssessmentData.healthAssessment;

    const localSmokingStopRawMonth = parseInt(smokingStopRawMonth);
    const localSmokingStopRawYear = parseInt(smokingStopRawYear);

    let localSmokingStopDate = '-';
    if (localSmokingStopRawMonth > 0 || localSmokingStopRawYear > 0) {
      localSmokingStopDate =
        `${localSmokingStopRawMonth > 0 ? localSmokingStopRawMonth.toString().padStart(2, '0') : '--'}/` +
        `${localSmokingStopRawYear > 0 ? localSmokingStopRawYear.toString().padStart(2, '0') : '----'}`;
    }

    haValuesRow = [
      {
        title: doYouDrink,
        value: choiceToStringOptions[String(alcoholBool) as keyof typeof choiceToStringOptions],
      },
      {
        title: drinkingFrequency,
        value: mapValueToRefData(alcoholFrequency, alcoholFrequencyRefData),
      },
      {
        title: doYouSmoke,
        value: choiceToStringOptions[String(smokingBool) as keyof typeof choiceToStringOptions],
      },
      {
        title: yearsSmoking,
        value: transformEmptyString(smokingHistory),
      },
      {
        title: howFrequentlySmoking,
        value: mapValueToRefData(smokingFrequency, smokingFrequencyRefData),
      },
      {
        title: whenStoppedSmoking,
        value: localSmokingStopDate,
      },
      {
        title: diabeticTitle,
        value: getDiabetesData(diabetes, diabetesResponses),
      },
      {
        title: ulcerTitle,
        value: `${choiceToStringOptions[String(activeInjury) as keyof typeof choiceToStringOptions]}${
          activeInjury === YES && activeInjuryReason ? `- ${activeInjuryReason}` : ''
        }`,
      },
      {
        title: assistAccommodation,
        value: choiceToStringOptions[String(accommodationAssistance) as keyof typeof choiceToStringOptions],
      },
    ].filter((e) => e.value !== '-');
  }

  // Create final read-only table
  isTableVisible = haValuesRow.length > 0 || regoValuesRow.length > 0;
  const tableRows: InfoCardRowItem[][] = [[...haValuesRow], [...regoValuesRow]];

  const renderLivingSituation = () => {
    return (
      <>
        <SubHeading>Living situation</SubHeading>

        <FormRow fieldLabel={livesAlone} fieldName={'livesAlone'}>
          <Field
            name="livesAlone"
            component={ToggleButtonGroupField}
            label={livesAlone}
            options={yesNoOptions}
            handleMutation={(name: string, updatedValue: boolean) => {
              if (values.livesWith) {
                handleMutation('livesWith', '');
                setFieldValue('livesWith', null);
              }

              handleMutation(name, updatedValue);
            }}
            lastUpdateUser={getLastUpdateUser(lastUpdateUserData, 'livesAlone')}
          />
        </FormRow>
        {values.livesAlone === false && (
          <FormRow fieldLabel={livesWith} fieldName="livesWith">
            <Field
              name="livesWith"
              placeholder={sharedContent.generic.label.specifyValue}
              component={TextAreaField}
              updateMutation={(value: string) => handleMutation('livesWith', value)}
              lastUpdateUser={getLastUpdateUser(lastUpdateUserData, 'livesWith')}
            />
          </FormRow>
        )}
        <FormRow fieldLabel={isCarer} fieldName={'isCarer'}>
          <Field
            name="isCarer"
            component={ToggleButtonGroupField}
            label={isCarer}
            options={yesNoOptions}
            handleMutation={(name: string, updatedValue: boolean) => {
              if (values.carerSpecification && !updatedValue) {
                handleMutation('carerSpecification', '');
                setFieldValue('carerSpecification', null);
              }

              handleMutation(name, updatedValue);
            }}
            lastUpdateUser={getLastUpdateUser(lastUpdateUserData, 'isCarer')}
          />
        </FormRow>

        {values.isCarer && (
          <FormRow fieldLabel={carerSpecification} fieldName="carerSpecification">
            <Field
              name="carerSpecification"
              placeholder={sharedContent.generic.label.specifyValue}
              component={TextAreaField}
              updateMutation={(value: string) => handleMutation('carerSpecification', value)}
              lastUpdateUser={getLastUpdateUser(lastUpdateUserData, 'carerSpecification')}
            />
          </FormRow>
        )}

        <FormRow fieldLabel={usualResidence} fieldName={'usualResidence'}>
          <Field
            name="usualResidence"
            component={SelectFieldBase}
            options={mapListData(typeOfResidenceRefData)}
            placeholder={sharedContent.generic.label.selectValue}
            updateMutation={(value: string) => {
              if (values.usualResidenceSpecify && value !== 'Other') {
                handleMutation('usualResidenceSpecify', '');
                setFieldValue('usualResidenceSpecify', null);
              }
              handleMutation('usualResidence', value);
            }}
            lastUpdateUser={getLastUpdateUser(lastUpdateUserData, 'usualResidence')}
          />
        </FormRow>

        {values.usualResidence === 'Other' && (
          <FormRow fieldLabel={usualResidenceSpecify} fieldName="usualResidenceSpecify">
            <Field
              name="usualResidenceSpecify"
              placeholder={sharedContent.generic.label.specifyValue}
              component={TextAreaField}
              updateMutation={(value: string) => handleMutation('usualResidenceSpecify', value)}
              lastUpdateUser={getLastUpdateUser(lastUpdateUserData, 'usualResidenceSpecify')}
            />
          </FormRow>
        )}

        <FormRow fieldLabel={staysAtUsualResidence} fieldName={'staysAtUsualResidence'}>
          <Field
            name="staysAtUsualResidence"
            component={ToggleButtonGroupField}
            label={staysAtUsualResidence}
            options={yesNoOptions}
            handleMutation={(name: string, updatedValue: boolean) => {
              if (values.otherResidence && updatedValue) {
                handleMutation('otherResidence', '');
                setFieldValue('otherResidence', null);
              }

              handleMutation(name, updatedValue);
            }}
            lastUpdateUser={getLastUpdateUser(lastUpdateUserData, 'staysAtUsualResidence')}
          />
        </FormRow>
        {values.staysAtUsualResidence === false && (
          <FormRow fieldLabel={otherResidence} fieldName="otherResidence">
            <Field
              name="otherResidence"
              placeholder={sharedContent.generic.label.specifyValue}
              component={TextAreaField}
              updateMutation={(value: string) => handleMutation('otherResidence', value)}
              lastUpdateUser={getLastUpdateUser(lastUpdateUserData, 'otherResidence')}
            />
          </FormRow>
        )}
        <br />
        <StyledHorizontalLine />
        <br />
      </>
    );
  };

  const renderCareRequirements = () => {
    return (
      <>
        <SubHeading>Care Requirements</SubHeading>
        <FormRow fieldLabel={needsAssistanceAdls} fieldName={'needsAssistanceAdls'}>
          <Field
            name="needsAssistanceAdls"
            component={ToggleButtonGroupField}
            label={needsAssistanceAdls}
            options={yesNoOptions}
            handleMutation={(name: string, updatedValue: boolean) => {
              if (values.assistance?.length && !updatedValue) {
                handleMutation('assistance', []);
                setFieldValue('assistance', []);
                handleMutation('assistanceSpecify', '');
                setFieldValue('assistanceSpecify', null);
              }

              handleMutation(name, updatedValue);
            }}
            lastUpdateUser={getLastUpdateUser(lastUpdateUserData, 'needsAssistanceAdls')}
          />
        </FormRow>
        {values.needsAssistanceAdls && (
          <>
            {/* Todo: create multi select component if does not exist and generalize */}
            <FieldArray name="assistance">
              {({ push, remove }) => {
                return (
                  <FormRow fieldLabel={assistance} fieldName={'assistance'}>
                    <StyledFieldRowMultiSelect>
                      <Grid container spacing={2}>
                        {assistanceOptions.map((item: string, idx: number) => {
                          const isActive = values?.assistance?.includes(item);

                          const onButtonClick = (): void => {
                            if (!isActive && !values?.assistance?.includes(item)) {
                              push(item);
                              handleMutation('assistance', [item, ...values.assistance]);

                              if (values.assistance.length < 1) {
                                handleMutation('assistanceSpecify', '');
                                setFieldValue('assistanceSpecify', null);
                              }
                            }
                            if (isActive && values.assistance.includes(item)) {
                              const valueIndex = values.assistance.indexOf(item);
                              remove(valueIndex);
                              const filteredValues = values.assistance.filter((value: string) => value !== item);

                              if (filteredValues.length < 1) {
                                handleMutation('assistanceSpecify', '');
                                setFieldValue('assistanceSpecify', null);
                              }

                              handleMutation('assistance', filteredValues);
                            }
                          };
                          return (
                            <Grid item xs={6} key={idx}>
                              <Field
                                name={`assistance.${idx}`}
                                key={item}
                                component={SelectButton}
                                // invalid={sectionIsEmpty}
                                width={'100%'}
                                active={isActive}
                                buttonText={item}
                                showCheckIcon
                                onClick={onButtonClick}
                              />
                            </Grid>
                          );
                        })}
                      </Grid>
                      <UserIcon userType={getLastUpdateUser(lastUpdateUserData, 'assistance')} />
                    </StyledFieldRowMultiSelect>
                  </FormRow>
                );
              }}
            </FieldArray>
          </>
        )}
        {values.assistance?.length > 0 && (
          <FormRow fieldLabel={assistanceSpecify} fieldName="assistanceSpecify">
            <Field
              name="assistanceSpecify"
              placeholder={sharedContent.generic.label.specifyValue}
              component={TextAreaField}
              updateMutation={(value: string) => handleMutation('assistanceSpecify', value)}
              lastUpdateUser={getLastUpdateUser(lastUpdateUserData, 'assistanceSpecify')}
            />
          </FormRow>
        )}

        <FormRow fieldLabel={hasFamilyOrFriend} fieldName={'hasFamilyOrFriend'}>
          <Field
            name="hasFamilyOrFriend"
            component={ToggleButtonGroupField}
            label={hasFamilyOrFriend}
            options={yesNoOptions}
            handleMutation={(name: string, updatedValue: boolean) => {
              if (values.familyOrFriendSpecification && !updatedValue) {
                handleMutation('familyOrFriendSpecification', '');
                setFieldValue('familyOrFriendSpecification', null);
              }

              handleMutation(name, updatedValue);
            }}
            lastUpdateUser={getLastUpdateUser(lastUpdateUserData, 'hasFamilyOrFriend')}
          />
        </FormRow>
        {values.hasFamilyOrFriend && (
          <FormRow fieldLabel={familyOrFriendSpecification} fieldName="familyOrFriendSpecification">
            <Field
              name="familyOrFriendSpecification"
              placeholder={sharedContent.generic.label.specifyValue}
              component={TextAreaField}
              updateMutation={(value: string) => handleMutation('familyOrFriendSpecification', value)}
              lastUpdateUser={getLastUpdateUser(lastUpdateUserData, 'familyOrFriendSpecification')}
            />
          </FormRow>
        )}

        <FormRow fieldLabel={existingSupportService} fieldName={'existingSupportService'}>
          <Field
            name="existingSupportService"
            component={ToggleButtonGroupField}
            label={existingSupportService}
            options={yesNoOptions}
            handleMutation={(name: string, updatedValue: boolean) => {
              if (values.existingSupportSpecification && !updatedValue) {
                handleMutation('existingSupportSpecification', '');
                setFieldValue('existingSupportSpecification', null);
              }

              handleMutation(name, updatedValue);
            }}
            lastUpdateUser={getLastUpdateUser(lastUpdateUserData, 'existingSupportService')}
          />
        </FormRow>

        {values.existingSupportService && (
          <FormRow fieldLabel={existingSupportSpecification} fieldName="existingSupportSpecification">
            <Field
              name="existingSupportSpecification"
              placeholder={sharedContent.generic.label.specifyValue}
              component={TextAreaField}
              updateMutation={(value: string) => handleMutation('existingSupportSpecification', value)}
              lastUpdateUser={getLastUpdateUser(lastUpdateUserData, 'existingSupportSpecification')}
            />
          </FormRow>
        )}

        <FormRow fieldLabel={hasSensoryImpairment} fieldName={'hasSensoryImpairment'}>
          <Field
            name="hasSensoryImpairment"
            component={ToggleButtonGroupField}
            label={hasSensoryImpairment}
            options={yesNoOptions}
            handleMutation={(name: string, updatedValue: boolean) => {
              if (values.sensoryImpairments?.length && !updatedValue) {
                handleMutation('sensoryImpairments', []);
                setFieldValue('sensoryImpairments', []);
                handleMutation('sensoryImpairmentsSpecification', '');
                setFieldValue('sensoryImpairmentsSpecification', null);
              }

              handleMutation(name, updatedValue);
            }}
            lastUpdateUser={getLastUpdateUser(lastUpdateUserData, 'hasSensoryImpairment')}
          />
        </FormRow>
        {values.hasSensoryImpairment && (
          <>
            <FieldArray name="sensoryImpairments">
              {({ push, remove }) => {
                return (
                  <FormRow fieldLabel={sensoryImpairments} fieldName={'sensoryImpairments'}>
                    <StyledFieldRowMultiSelect>
                      <Grid container spacing={2}>
                        {sensoryImpairmentsOptions.map((item: string, idx: number) => {
                          const isActive = values?.sensoryImpairments?.includes(item);

                          const onButtonClick = (): void => {
                            if (!isActive && !values?.sensoryImpairments?.includes(item)) {
                              push(item);
                              handleMutation('sensoryImpairments', [item, ...values.sensoryImpairments]);
                            }

                            if (isActive && values.sensoryImpairments.includes(item)) {
                              const valueIndex = values.sensoryImpairments.indexOf(item);
                              remove(valueIndex);
                              const filteredValues = values.sensoryImpairments.filter(
                                (value: string) => value !== item,
                              );

                              if (filteredValues.length < 1) {
                                handleMutation('sensoryImpairmentsSpecification', '');
                                setFieldValue('sensoryImpairmentsSpecification', null);
                              }
                              handleMutation('sensoryImpairments', filteredValues);
                            }
                          };
                          return (
                            <Grid item xs={6} key={idx}>
                              <Field
                                name={`sensoryImpairments.${idx}`}
                                key={item}
                                component={SelectButton}
                                width={'100%'}
                                active={isActive}
                                buttonText={item}
                                showCheckIcon
                                onClick={onButtonClick}
                              />
                            </Grid>
                          );
                        })}
                      </Grid>
                      <UserIcon userType={getLastUpdateUser(lastUpdateUserData, 'sensoryImpairments')} />
                    </StyledFieldRowMultiSelect>
                    {values.sensoryImpairments.includes(SensoryImpairments.hearing) && (
                      <HelperMessage fieldName={'sensoryImpairments'} fieldText={hearingInfo} helperType="info" />
                    )}
                    {values.sensoryImpairments.includes(SensoryImpairments.vision) && (
                      <HelperMessage fieldName={'sensoryImpairments'} fieldText={visionInfo} helperType="info" />
                    )}
                  </FormRow>
                );
              }}
            </FieldArray>
          </>
        )}

        {values.sensoryImpairments?.length > 0 && (
          <FormRow fieldLabel={sensoryImpairmentsSpecification} fieldName="sensoryImpairmentsSpecification">
            <Field
              name="sensoryImpairmentsSpecification"
              placeholder={sharedContent.generic.label.specifyValue}
              component={TextAreaField}
              updateMutation={(value: string) => handleMutation('sensoryImpairmentsSpecification', value)}
              lastUpdateUser={getLastUpdateUser(lastUpdateUserData, 'sensoryImpairmentsSpecification')}
            />
          </FormRow>
        )}
        <br />
        <StyledHorizontalLine />
        <br />
      </>
    );
  };
  const renderSocialMedicalHistory = () => {
    return (
      <>
        <SubHeading>Other significant social or medical history</SubHeading>
        <FormRow fieldLabel={englishSecondLanguage} fieldName={'englishSecondLanguage'}>
          <Field
            name="englishSecondLanguage"
            component={ToggleButtonGroupField}
            label={englishSecondLanguage}
            options={yesNoOptions}
            handleMutation={(name: string, updatedValue: boolean) => {
              if (values.primaryLanguage && !updatedValue) {
                handleMutation('primaryLanguage', '');
                setFieldValue('primaryLanguage', null);
              }

              if (values.requireInterpreter != null && !updatedValue) {
                // Leaving this here as hopefully one day graphene will support null values
                // handleMutation('requireInterpreter', null);
                setFieldValue('requireInterpreter', null);
              }

              handleMutation(name, updatedValue);
            }}
            lastUpdateUser={getLastUpdateUser(lastUpdateUserData, 'englishSecondLanguage')}
          />
        </FormRow>

        {values.englishSecondLanguage && (
          <>
            <FormRow fieldLabel={primaryLanguage} fieldName="primaryLanguage">
              <Field
                name="primaryLanguage"
                placeholder={sharedContent.generic.label.specifyValue}
                component={TextAreaField}
                updateMutation={(value: string) => handleMutation('primaryLanguage', value)}
                lastUpdateUser={getLastUpdateUser(lastUpdateUserData, 'primaryLanguage')}
              />
            </FormRow>

            <FormRow fieldLabel={requireInterpreter} fieldName={'requireInterpreter'}>
              <Field
                name="requireInterpreter"
                component={ToggleButtonGroupField}
                label={requireInterpreter}
                options={yesNoOptions}
                handleMutation={handleMutation}
                lastUpdateUser={getLastUpdateUser(lastUpdateUserData, 'requireInterpreter')}
              />
              {values.requireInterpreter && (
                <HelperMessage fieldName={'requireInterpreter'} fieldText={requireInterpreterInfo} helperType="info" />
              )}
            </FormRow>
          </>
        )}

        <FormRow fieldLabel={otherInformation} fieldName="otherInformation">
          <Field
            name="otherInformation"
            placeholder={sharedContent.generic.label.specifyValue}
            component={TextAreaField}
            multiline
            updateMutation={(value: string) => handleMutation('otherInformation', value)}
            lastUpdateUser={getLastUpdateUser(lastUpdateUserData, 'otherInformation')}
          />
        </FormRow>
      </>
    );
  };
  const { socialConsiderations } = fields;
  return (
    <>
      {values.socialConsiderations && (
        <FormRow fieldLabel={socialConsiderations} fieldName={'socialConsiderations'}>
          <Field
            name="socialConsiderations"
            component={TextAreaField}
            disabled={true}
            multiline
            label={socialConsiderations}
            placeholder={sharedContent.generic.label.specifyValue}
            updateMutation={(value: string) => handleMutation('socialConsiderations', value)}
            lastUpdateUser={getLastUpdateUser(lastUpdateUserData, 'socialConsiderations')}
            maxLength={2048}
          />
        </FormRow>
      )}
      {renderLivingSituation()}
      {renderCareRequirements()}
      {renderSocialMedicalHistory()}
      {isTableVisible && (
        <InfoCard title="Social Circumstances" subTitle="(Patient Reported)" rowDetails={tableRows} itemSize={6} />
      )}
    </>
  );
};

export default SocialCircumstances;
