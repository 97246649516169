// eslint-disable-next-line no-use-before-define
import { useMutation, useQuery } from '@apollo/client';
import { gql } from '@apollo/client';
import React, { useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';

import { region } from 'op-components';
import { LOG_EVENT } from 'op-graphql/Logging';
import { Stepper } from 'shared-components/components';
import { Region } from 'shared-components/enums';
import { StepperLink } from 'shared-components/interfaces';
import { UNSEEN_POPUP_NOTIFICATIONS, UPDATE_USER_POPUP_NOTIFICATION } from 'op-graphql/queries/Notification';

export const USER_DETAILS_QUERY = gql`
  query PatientAndUserDetails($id: ID!) {
    profile(id: $id) {
      id
      patientFeatures(id: $id) {
        doctorReferralLookupEnabled
      }
    }
  }
`;

interface Props {
  patientId: string;
  disabled?: boolean;
  isPso?: boolean;
  regFormStatus?: string;
}

const RegistrationStepper = (props: Props): JSX.Element => {
  const history = useHistory();

  const { patientId, disabled, isPso, regFormStatus } = props;

  const StepperLinks = (): StepperLink[] => {
    return [
      {
        linkText: 'Basic details',
        href: '/basic',
        isVisible: true,
      },
      {
        linkText: 'Contact details',
        href: '/contact',
        isVisible: true,
      },
      {
        linkText: 'Address',
        href: '/address',
        isVisible: true,
      },
      {
        linkText: 'Alternate contacts',
        href: '/altcontact',
        isVisible: true,
      },
      {
        linkText: region === Region.UK ? 'Referring clinicians' : 'Referrers',
        href: '/gp',
        isVisible: true,
      },
      {
        linkText: 'Health insurance',
        href: '/medicare',
        isVisible: region !== Region.UK,
      },
      {
        linkText: 'Demographics',
        href: '/demographics',
        isVisible: true,
      },
      {
        linkText: 'Information notice',
        href: '/infonotice',
        isVisible: region !== Region.UK,
      },
      {
        linkText: 'Insurance',
        href: '/insurance',
        isVisible: region === Region.UK,
      },
      {
        linkText: 'File attachments',
        href: '/attachments',
        isVisible: region === Region.UK,
      },
      {
        linkText: region === Region.AU ? 'Document Upload' : 'Attachments',
        href: '/attachments',
        isVisible: region !== Region.UK,
      },
      {
        linkText: 'Review & submit',
        href: '/summary',
        isVisible: true,
      },
    ];
  };

  const LINKS: StepperLink[] = StepperLinks().filter((item) => item.isVisible);

  const [popoverOpen, setPopoverOpen] = useState(false);

  const [logEvent] = useMutation(LOG_EVENT);
  const { data: notificationData } = useQuery(UNSEEN_POPUP_NOTIFICATIONS, { fetchPolicy: 'no-cache' });

  const [updateLatestPopupNotification] = useMutation(UPDATE_USER_POPUP_NOTIFICATION, {
    awaitRefetchQueries: true,
    refetchQueries: [{ query: UNSEEN_POPUP_NOTIFICATIONS }],
  });

  const gpReferrerNotificationId: string = notificationData?.unseenPopups?.find(
    (unseenPopup: { description: string }) => unseenPopup.description === 'GP Referrer',
  )?.id;
  const showNotificationUpdate = !!gpReferrerNotificationId;

  const { data: userData } = useQuery(USER_DETAILS_QUERY, {
    skip: !isPso,
    variables: { id: patientId },
    fetchPolicy: 'no-cache',
    onCompleted: (data: any): any => {
      return data;
    },
  });

  useEffect(() => {
    // if regPage is gp, dont show the popover on the stepper.
    const regPage = window.location.pathname.split('/').slice(-1)[0];

    if (notificationData && userData) {
      const openPopover =
        notificationData &&
        showNotificationUpdate &&
        isPso &&
        userData?.profile?.patientFeatures?.doctorReferralLookupEnabled &&
        regFormStatus === 'filterReviewRequired' &&
        regPage !== 'gp';

      setPopoverOpen(openPopover);
    }
  }, [isPso, regFormStatus, notificationData, userData]);

  const closeAndAckPopup = () => {
    updateLatestPopupNotification({ variables: { id: gpReferrerNotificationId } });
    setPopoverOpen(false);
  };

  const closePopup = () => {
    setPopoverOpen(false);
    history.push(`/registration/${patientId}/gp`);
  };

  return (
    <>
      {notificationData && (
        <Stepper
          links={LINKS}
          basePath={`/registration/${patientId}`}
          disabled={disabled}
          closePopup={closePopup}
          closeAndAckPopup={closeAndAckPopup}
          popoverOpen={popoverOpen}
          logPopoverData={logEvent}
        />
      )}
    </>
  );
};

export default RegistrationStepper;
