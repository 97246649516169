import { gql } from '@apollo/client';

export const CREATE_SITEGROUP_FROM_TEMPLATE = gql`
  mutation createSiteGroupFromTemplate(
    $careplanId: ID!
    $templateName: String
    $treatmentSite: String
    $templateId: ID
    $treatmentSiteSpecify: String
    $selectionMethod: String
  ) {
    createSiteGroupFromTemplate(
      careplanId: $careplanId
      templateId: $templateId
      templateName: $templateName
      treatmentSite: $treatmentSite
      treatmentSiteSpecify: $treatmentSiteSpecify
      selectionMethod: $selectionMethod
    ) {
      siteGroup {
        id
      }
    }
  }
`;

export const LIST_SITE_FILTERS = gql`
  query listSiteFilters($tumourStream: String!, $patientId: ID!) {
    siteFilterList(tumourStream: $tumourStream, patientId: $patientId)
  }
`;

export const LIST_DOSE_FILTERS = gql`
  query listDoseFilters($tumourStream: String!, $siteFilter: String!, $patientId: ID!) {
    doseFilterList(tumourStream: $tumourStream, siteFilter: $siteFilter, patientId: $patientId)
  }
`;

export const LIST_TECHNIQUE_FILTERS = gql`
  query listTechniqueFilters($tumourStream: String!, $siteFilter: String!, $doseFilter: String!, $patientId: ID!) {
    techniqueFilterList(
      tumourStream: $tumourStream
      siteFilter: $siteFilter
      doseFilter: $doseFilter
      patientId: $patientId
    )
  }
`;

export const LIST_CAREPLAN_FILTERS = gql`
  query careplanTemplateList(
    $patientId: ID!
    $tumourStream: String!
    $siteFilter: String
    $doseFilter: String
    $techniqueFilter: String
    $search: String
    $hasOar: Boolean
  ) {
    careplanTemplateList(
      patientId: $patientId
      tumourStream: $tumourStream
      siteFilter: $siteFilter
      doseFilter: $doseFilter
      techniqueFilter: $techniqueFilter
      search: $search
      hasOar: $hasOar
    ) {
      id
      name
      rules {
        id
        field
        defaultValue
      }
    }
  }
`;

export const LIST_TUMOUR_STREAMS = gql`
  query tumourStreamList {
    tumourStreamList
  }
`;

export const LIST_TREATMENT_SITES_ORDERBY_USAGE = gql`
  query mostUsedSiteReferenceList {
    mostUsedSiteReferenceList {
      id
      treatmentSite
    }
  }
`;

export const GET_TUMOUR_STREAM_FROM_CAREPLAN = gql`
  query careplan($careplanId: ID!) {
    careplan(id: $careplanId) {
      id
      practitioner {
        id
      }
      diagnosis {
        id
        diagnosisCode {
          id
          diagnosisCode
          tumourStream {
            id
            name
          }
        }
        isPrimaryDiagnosis
        relatedPrimaryDiagnosis {
          id
          diagnosisCode {
            id
            tumourStream {
              id
              name
            }
          }
        }
      }
    }
  }
`;
