import React from 'react';
import { styled } from '@mui/system';
import { ToggleButton as MUIToggleButton } from '@mui/material';

interface IStyledToggleButton {
  name: string;
  value: string | number;
  invalid?: boolean;
  firstChild?: boolean;
  lastChild?: boolean;
  selected?: boolean;
  children?: any;
  minwidth?: string;
  alternateStyle?: boolean;
}

interface StyleProps {
  $invalid?: boolean;
  $firstChild?: boolean;
  $lastChild?: boolean;
  selected?: boolean;
  $alternateStyle?: boolean;
  minwidth?: string;
  borderradius: string;
}

const StyledToggleButton = styled(MUIToggleButton)<StyleProps>`
&& {
  font-weight: bold;
  line-height: normal;
  text-transform: none;
  outline: none;
  border: 1px solid ${(props) =>
    ({ $invalid }: StyleProps): string =>
      $invalid ? props.theme.palette.error.main : props.theme.palette.grey[300]} !important;
  border-radius: ${(props) =>
    ({ borderradius }: StyleProps): string =>
      borderradius};
  color: ${(props) =>
    ({ selected }: StyleProps): string =>
      selected ? props.theme.palette.primary.contrastText : props.theme.palette.text.primary} !important;
  background-color: ${(props) =>
    ({ selected }: StyleProps): string =>
      selected ? props.theme.palette.primary.dark : 'white'} !important;
  &:hover {
    background-color: ${(props) =>
      ({ selected }: StyleProps): string =>
        selected ? props.theme.palette.primary.dark : 'white'} !important;
  }
  min-width: ${({ $alternateStyle, minwidth }: StyleProps): string =>
    minwidth ? minwidth : $alternateStyle ? '131px' : '176px'};
  max-height: ${({ $alternateStyle }: StyleProps): string => ($alternateStyle ? '40px' : '48px')};;
  }

  .MuiToggleButton-root {
    line-height: initial;
  }
}}
`;

const ToggleButton = (props: IStyledToggleButton): JSX.Element => {
  const { firstChild, lastChild, invalid, alternateStyle = false, value, ...rest } = props;

  const radius = alternateStyle ? '4px' : '8px';
  const getBorderRadius = () => {
    if (firstChild && lastChild) return radius;
    if (firstChild) return `${radius} 0 0 ${radius}`;
    if (lastChild) return `0 ${radius} ${radius} 0`;
    return '0';
  };

  return (
    <StyledToggleButton
      disableRipple
      fullWidth
      $firstChild={firstChild}
      $lastChild={lastChild}
      $invalid={invalid}
      $alternateStyle={alternateStyle}
      borderradius={getBorderRadius()}
      value={value}
      {...rest}
    />
  );
};

export default ToggleButton;
