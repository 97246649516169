// eslint-disable-next-line no-use-before-define
import React, { Component } from 'react';
import { Info } from 'shared-components/images';
import { SUBMIT_MODAL } from './constants';
import { Region } from 'shared-components/enums';
import { Stack } from '@mui/material';
import { StandardDialog } from 'shared-components/components';

const REACT_APP_REGION = import.meta.env.REACT_APP_REGION;

interface Props {
  isOpen: boolean;
  isProd?: boolean;
  isPSO?: boolean;
  dismissFunction: () => void;
  submitForm: () => void;
  submitText: string;
  headerText?: string;
  bodyText?: string | JSX.Element;
  isUs?: boolean | JSX.Element;
  emptyBody?: boolean;
  removeHeaderPadding?: boolean;
  keepUnlocked?: boolean;
  continueNextForm?: boolean;
}

interface State {
  locked: boolean;
}

const region = REACT_APP_REGION;

class ModalSubmit extends Component<Props, State> {
  public static defaultProps = {
    isPSO: true,
  };

  public constructor(props: Props) {
    super(props);
    this.state = {
      locked: false,
    };
  }
  private setLocked() {
    this.setState({ locked: true });
  }
  public render(): JSX.Element {
    const { dismissFunction, isOpen, isPSO, submitForm, submitText, headerText, bodyText, emptyBody, keepUnlocked } =
      this.props;
    const { locked } = this.state;

    return (
      <StandardDialog
        id="submit-modal"
        open={isOpen}
        title={headerText ? headerText : isPSO ? SUBMIT_MODAL.CONFIRM_EXIT : SUBMIT_MODAL.CONFIRM_SUBMIT}
        onClose={dismissFunction}
        submitText={submitText}
        submitButtonProps={{
          disabled: locked,
        }}
        onSubmit={() => {
          submitForm();
          if (!keepUnlocked) {
            this.setLocked();
          }
        }}>
        <Stack>
          {emptyBody ? null : bodyText ? bodyText : isPSO ? this.renderPSOContent() : this.renderPatientContent()}
        </Stack>
      </StandardDialog>
    );
  }

  private renderPatientContent = (): JSX.Element => {
    const { continueNextForm } = this.props;
    return (
      <div>
        <div>{continueNextForm ? SUBMIT_MODAL.CONFIRM_DETAILS_CONTINUE : SUBMIT_MODAL.CONFIRM_DETAILS_COMPLETE}</div>
      </div>
    );
  };

  private renderPSOContent = (): JSX.Element => {
    const { isProd } = this.props;
    if (region === Region.UK) {
      return (
        <div>
          <ul>
            <li>{SUBMIT_MODAL.CONFIRM_PATIENT_DETAILS}</li>
            <li>{SUBMIT_MODAL.PATIENT_INFO_UPDATE}</li>
            <li id="warning-message">{isProd ? SUBMIT_MODAL.SUBMIT_TO_MOSAIQ : SUBMIT_MODAL.SUBMIT_TO_TEST}</li>
          </ul>
        </div>
      );
    } else {
      return (
        <div>
          <ul>
            <li>{SUBMIT_MODAL.CONFIRM_PATIENT_DETAILS}</li>
            <li>{SUBMIT_MODAL.PDF_SENT}</li>
            <li>{SUBMIT_MODAL.PATIENT_INFO_UPDATE}</li>
          </ul>
          <Stack direction="row" width={1}>
            <Info className="info icon" />
            <div>{SUBMIT_MODAL.PATIENT_PORTAL_REMINDER}</div>
          </Stack>
        </div>
      );
    }
  };
}

export default ModalSubmit;
