import React from 'react';
import { Field, FieldArray, FormikProps, useFormikContext } from 'formik';
import { styled } from '@mui/system';

import { InfoCard } from 'op-components';
import { InfoCardRowItem } from 'op-interfaces';
import { FormStatus } from 'op-enums';
import { HelperMessage, FormRow, UserIcon } from 'shared-components/components';
import { StyledFieldRow, TextAreaField } from 'shared-components/components/FormikComponents';
import SelectButton from 'shared-components/components/UIFormComponents/SelectButton';
import { AustralianStates } from 'shared-components/enums';

import { choiceToStringOptions, getLastUpdateUser } from '../../helpers';
import { sharedContent, convertFromBooleanToString } from '../../shared/constants';
import { LastUpdateUserDetails } from '../../shared/interfaces';
import {
  content,
  transportOptions,
  QLDTransportOptions,
  CANCER_COUNCIL_BUS,
  GENESIS_BUS,
  DVA_TRANSPORT,
} from '../constants';
import { RegistrationFormData, HealthAssessmentData, PreCtTriageData } from '../interfaces/PreCtTriageInterfaces';
import Grid from '@mui/material/Grid';

interface Props {
  handleMutation: (name: string, value: string | string[]) => void;
  healthAssessmentData: HealthAssessmentData;
  registrationFormData: RegistrationFormData;
  patientState: string | undefined;
  lastUpdateUserData: LastUpdateUserDetails[];
}

const HelperMessageContainer = styled('div')`
  margin-top: 16px;
`;

const TransportMode = ({
  handleMutation,
  healthAssessmentData,
  registrationFormData,
  patientState,
  lastUpdateUserData,
}: Props): JSX.Element => {
  const { values, touched }: FormikProps<PreCtTriageData> = useFormikContext();
  const { fields, infoHelpers, tableTitles } = content.transportMode;
  const { transportMode, otherTransport } = fields;
  const { doYouNeedTransport, assistanceReason, ambTitle, ambNumberTitle } = tableTitles;
  const transportFieldsTouched = [touched.transportMode, touched.otherTransport];
  const sectionIsEmpty =
    values.transportMode.length === 0 && !values.otherTransport && transportFieldsTouched.includes(true);

  let isTableVisible = false;
  const {
    patientState: regPatientState,
    ambulanceMembership,
    ambulanceMembershipNumber,
    regFormStatus,
  } = registrationFormData.patient;

  const tableRows: InfoCardRowItem[][] = [];
  const healthAssessmentDataRow: InfoCardRowItem[] = [];

  // Add registration data, if applicable
  if (regFormStatus === FormStatus.REG_SUBMITTED && regPatientState === AustralianStates.VIC) {
    tableRows.push([
      {
        title: ambTitle,
        value: convertFromBooleanToString(ambulanceMembership),
      },
      {
        title: ambNumberTitle,
        value: ambulanceMembershipNumber || '-',
      },
    ]);
  }

  // Add health assessment data, if applicable
  if (healthAssessmentData.healthAssessment) {
    const transportationAssistance = healthAssessmentData.healthAssessment.transportationAssistance;
    const transportationAssistanceReason = healthAssessmentData.healthAssessment.transportationAssistanceReason;

    if (transportationAssistance) {
      healthAssessmentDataRow.push({
        title: doYouNeedTransport,
        //@ts-ignore
        value: choiceToStringOptions[String(transportationAssistance)],
      });
    }

    if (transportationAssistanceReason) {
      healthAssessmentDataRow.push({
        title: assistanceReason,
        value: transportationAssistanceReason,
      });
    }

    healthAssessmentDataRow.length > 0 && tableRows.push(healthAssessmentDataRow);
  }

  isTableVisible = tableRows.length > 0;

  return (
    <>
      <FieldArray name="transportMode">
        {({ push, remove }) => {
          return (
            <FormRow fieldLabel={transportMode} fieldName={'transportMode'}>
              <StyledFieldRow>
                <Grid container spacing={2}>
                  {transportOptions.map((item: string, idx: number) => {
                    const isActive = values.transportMode.includes(item);
                    const isHidden = QLDTransportOptions.includes(item) && patientState !== AustralianStates.QLD;

                    const onButtonClick = (): void => {
                      if (!isActive && !values.transportMode.includes(item)) {
                        push(item);
                        handleMutation('transportMode', [item, ...values.transportMode]);
                      }
                      if (isActive && values.transportMode.includes(item)) {
                        const valueIndex = values.transportMode.indexOf(item);
                        remove(valueIndex);
                        const filteredValues = values.transportMode.filter((value: string) => value !== item);
                        handleMutation('transportMode', filteredValues);
                      }
                    };
                    return (
                      <Grid item xs={6} sx={{ display: isHidden ? 'none' : 'flex' }} key={idx}>
                        <Field
                          name={`transportMode.${idx}`}
                          key={idx}
                          component={SelectButton}
                          invalid={sectionIsEmpty}
                          width={'100%'}
                          active={isActive}
                          buttonText={item}
                          showCheckIcon
                          onClick={onButtonClick}
                        />
                      </Grid>
                    );
                  })}
                </Grid>
                <UserIcon userType={getLastUpdateUser(lastUpdateUserData, 'transportMode')} />
              </StyledFieldRow>
            </FormRow>
          );
        }}
      </FieldArray>
      <FormRow fieldLabel={otherTransport} fieldName="otherTransport">
        <Field
          name="otherTransport"
          placeholder={sharedContent.generic.label.specifyValue}
          multiline
          component={TextAreaField}
          updateMutation={(value: string) => handleMutation('otherTransport', value)}
          lastUpdateUser={getLastUpdateUser(lastUpdateUserData, 'otherTransport')}
        />
        {patientState === AustralianStates.QLD && (
          <HelperMessageContainer>
            {values.transportMode.includes(CANCER_COUNCIL_BUS) && (
              <HelperMessage fieldName={'transportMode'} fieldText={infoHelpers.cancerCouncilQCL} helperType="info" />
            )}
            {values.transportMode.includes(DVA_TRANSPORT) && (
              <HelperMessage fieldName={'transportMode'} fieldText={infoHelpers.DVAQCL} helperType="info" />
            )}
            {values.transportMode.includes(GENESIS_BUS) && (
              <HelperMessage fieldName={'transportMode'} fieldText={infoHelpers.genesisBusQCL} helperType="info" />
            )}
          </HelperMessageContainer>
        )}
      </FormRow>
      {isTableVisible && (
        <InfoCard title="Transportation Assistance" subTitle="(Patient Reported)" rowDetails={tableRows} />
      )}
    </>
  );
};

export default TransportMode;
