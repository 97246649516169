import { gql } from '@apollo/client';
import { HEALTH_ASSESSMENT_MOBILITY_AID_FRAGMENT, HA_MOBILITY_FRAGMENT } from 'op-graphql/fragments';

export const HA_MOBILITY_QUERY = gql`
  query HealthAssessment($patientId: ID!) {
    healthAssessment(id: $patientId) {
      id
      mobilityAids {
        id
        ...HealthAssessmentMobilityAid
      }
      ...HealthAssessmentMobility
    }
    mobilityAidRefData: listData(category: "mobilityAids") {
      id
      name
    }
  }
  ${HA_MOBILITY_FRAGMENT}
  ${HEALTH_ASSESSMENT_MOBILITY_AID_FRAGMENT}
`;

export const UPDATE_HA_MOBILITY = gql`
  mutation UpdateHaMobility(
    $haId: ID!
    $patientId: ID!
    $mobilityAid: String
    $fallen: String
    $fallenHowMany: String
    $fallenInjuries: String
    $footPain: String
    $unsteady: String
    $lightheaded: String
  ) {
    updateHaMobility(
      id: $haId
      patientId: $patientId
      mobilityAid: $mobilityAid
      fallen: $fallen
      fallenHowMany: $fallenHowMany
      fallenInjuries: $fallenInjuries
      footPain: $footPain
      unsteady: $unsteady
      lightheaded: $lightheaded
    ) {
      healthAssessment {
        id
        mobilityAids {
          id
          ...HealthAssessmentMobilityAid
        }
        ...HealthAssessmentMobility
      }
    }
  }
  ${HA_MOBILITY_FRAGMENT}
  ${HEALTH_ASSESSMENT_MOBILITY_AID_FRAGMENT}
`;

export const DELETE_MOBILITY_AID = gql`
  mutation DeleteMobilityAid($id: ID!, $patientId: ID!) {
    deleteMobilityAid(id: $id, patientId: $patientId) {
      success
    }
  }
`;

export const ADD_MOBILITY_AID = gql`
  mutation AddMobilityAid($haId: ID!, $patientId: ID!) {
    addMobilityAid(haId: $haId, patientId: $patientId) {
      mobilityAid {
        id
        ...HealthAssessmentMobilityAid
      }
    }
  }
  ${HEALTH_ASSESSMENT_MOBILITY_AID_FRAGMENT}
`;

export const UPDATE_MOBILITY_AID = gql`
  mutation UpdateMobilityAid($id: ID!, $patientId: ID!, $value: String, $other: String) {
    updateMobilityAid(id: $id, patientId: $patientId, value: $value, other: $other) {
      healthAssessment {
        id
        mobilityAids {
          id
          ...HealthAssessmentMobilityAid
        }
        ...HealthAssessmentMobility
      }
    }
  }
  ${HA_MOBILITY_FRAGMENT}
  ${HEALTH_ASSESSMENT_MOBILITY_AID_FRAGMENT}
`;
