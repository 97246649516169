// eslint-disable-next-line no-use-before-define
import React, { useState, useEffect } from 'react';
import { styled } from '@mui/system';
import { FieldProps, getIn } from 'formik';

import { BaseTextField } from '../FormFields';
import { UserIcon } from 'shared-components/components';
import StyledFieldRow from './StyledFieldRow';

interface ContainerProps {
  width?: string;
}

const Container = styled('div')<ContainerProps>`
  display: flex;
  flex-direction: column;
  width: ${(props: ContainerProps): string => (props.width ? props.width : '100%')};
`;

interface Props extends FieldProps {
  placeholder: string;
  updateMutation?: (value: string) => void;
  height?: string;
  rows?: string;
  lastUpdateUser?: string;
  width?: string;
  maxLength?: number;
  alternateStyle?: boolean;
  disabled?: boolean;
}
/**
 * @deprecated the TextAreaField is deprecated, use the BaseTextField instead
 */
const TextAreaField = ({
  field,
  form,
  placeholder,
  updateMutation,
  rows,
  lastUpdateUser,
  width,
  maxLength,
  alternateStyle,
  disabled = false,
  ...props
}: Props): JSX.Element => {
  const { name, value, onBlur, onChange } = field;
  const [lengthWarning, setLengthWarning] = useState({
    show: false,
    message: 'Character limit reached',
  });
  const { errors, touched, submitCount } = form;
  const fieldError = getIn(errors, name);
  const fieldTouched = getIn(touched, name);
  const isFieldInvalid = (submitCount > 0 || fieldTouched) && fieldError;

  const handleBlur = async (event: React.ChangeEvent<any>): Promise<any> => {
    onBlur(event);
    updateMutation && updateMutation(event.target.value);
  };

  useEffect(() => {
    if (maxLength && value.length === maxLength) {
      setLengthWarning({ ...lengthWarning, show: true });
    } else setLengthWarning({ ...lengthWarning, show: false });
  }, [value]);

  return (
    <Container width={width}>
      <StyledFieldRow>
        <BaseTextField
          id={name}
          name={name}
          fullWidth
          value={value}
          error={isFieldInvalid}
          warning={lengthWarning.show}
          onChange={onChange}
          onBlur={(e: any): Promise<any> => handleBlur(e)}
          placeholder={placeholder}
          rows={rows}
          maxLength={maxLength}
          disabled={disabled}
          helperText={fieldError || lengthWarning.message}
          {...props}
        />
        {lastUpdateUser && <UserIcon userType={lastUpdateUser} />}
      </StyledFieldRow>
    </Container>
  );
};

export default TextAreaField;
