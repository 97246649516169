import { gql } from '@apollo/client';
import { HEALTH_ASSESSMENT_FAMILY_HISTORY_FRAGMENT } from 'op-graphql/fragments';

export const UPDATE_FAMILY_HISTORY = gql`
  mutation UpdateFamilyHistory(
    $id: ID!
    $patientId: ID!
    $familyMember: String
    $cancerType: String
    $ageOfFamilyMember: String
    $stillAlive: Boolean
    $other: String
  ) {
    updateFamilyHistory(
      id: $id
      patientId: $patientId
      familyMember: $familyMember
      cancerType: $cancerType
      ageOfFamilyMember: $ageOfFamilyMember
      stillAlive: $stillAlive
      other: $other
    ) {
      usFamilyHistory {
        id
        ...HealthAssessmentFamilyHistory
      }
    }
  }

  ${HEALTH_ASSESSMENT_FAMILY_HISTORY_FRAGMENT}
`;

export const DELETE_FAMILY_HISTORY = gql`
  mutation DeleteFamilyHistory($id: ID!, $patientId: ID!) {
    deleteFamilyHistory(id: $id, patientId: $patientId) {
      success
    }
  }
`;

export const ADD_FAMILY_HISTORY = gql`
  mutation AddFamilyHistory($haId: ID!, $patientId: ID!) {
    addFamilyHistory(haId: $haId, patientId: $patientId) {
      usFamilyHistory {
        id
        ...HealthAssessmentFamilyHistory
      }
    }
  }
  ${HEALTH_ASSESSMENT_FAMILY_HISTORY_FRAGMENT}
`;
