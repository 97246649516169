// eslint-disable-next-line no-use-before-define
import { useMutation } from '@apollo/client';
import { Form, Formik, FormikProps } from 'formik';
import { FormContainer } from 'op-components';
import { yesNoOptions } from 'op-utils';
import { RegistrationContext } from 'op-contexts';
import React, { useContext } from 'react';
import { useHistory } from 'react-router-dom';
import { AddressSectionField } from 'shared-components/components/FormikComponents';
import { Address } from 'shared-components/interfaces';
import {
  CONTINUE_BUTTON_TEXT,
  EMPTY_ADDRESS,
  HeaderTitle,
  registrationPath,
  RETURN_TO_SUBMIT_TEXT,
  sharedFormContainerProps,
  standardField,
  StyledIndentedSection,
  SecondaryTitle,
} from '../Helper';
import { PatientAddressRego, RegistrationFormUSIProps } from '../interfaces';
import { REMOVE_PATIENT_ADDRESS, UPDATE_PATIENT_ADDRESS } from '../RegistrationFormQueries';
import { REGISTRATION_ADDRESS_QUERY } from './AddressQueries';
import { FIELDS } from './constants';

interface AddressFormIProps extends RegistrationFormUSIProps {
  patient: PatientAddressRego;
}

const AddressForm = (props: AddressFormIProps): JSX.Element => {
  const { updateField, patient, handleShowSaveExitConfirm, previousPageLink } = props;
  const registrationContext = useContext(RegistrationContext);

  const history = useHistory();
  const goToSummary = registrationContext?.registrationSummaryVisited;
  const refetchQueries = [{ query: REGISTRATION_ADDRESS_QUERY, variables: { id: patient?.id } }];

  const [updatePatientAddressMutation] = useMutation(UPDATE_PATIENT_ADDRESS, { refetchQueries: refetchQueries });
  const [removePatientAddressMutation] = useMutation(REMOVE_PATIENT_ADDRESS, { refetchQueries: refetchQueries });

  return (
    <Formik
      initialValues={patient}
      onSubmit={() => history.push(registrationPath(patient?.id, goToSummary ? 'summary' : 'emergencyContact'))}>
      {({ values, submitForm, errors, setFieldValue }: FormikProps<PatientAddressRego>) => {
        const sharedProps = sharedFormContainerProps('Address', 3, previousPageLink);
        const formContainerProps = {
          ...sharedProps,
          submitButtonText: goToSummary ? RETURN_TO_SUBMIT_TEXT : CONTINUE_BUTTON_TEXT,
          continueDisabled: Object.keys(errors).length > 0,
          handleShowSaveExitConfirm,
        };

        const removePostalAddress = () => {
          const addressString = 'postalAddress';
          setFieldValue(`${addressString}.country`, '');
          setFieldValue(`${addressString}.line1`, '');
          setFieldValue(`${addressString}.line2`, '');
          setFieldValue(`${addressString}.city`, '');
          setFieldValue(`${addressString}.state`, '');
          setFieldValue(`${addressString}.postcode`, '');
          removePatientAddressMutation({
            variables: {
              patientId: patient?.id,
              relatedName: 'postal_address',
            },
          });
        };

        const updateRegularField = (name: string, value: string | boolean | number) => {
          updateField(FIELDS.SAME_POSTAL_ADDRESS.NAME, value);
          if (!value) {
            removePostalAddress();
          }
        };

        const handleUpdateAddress = (relatedName: string, address: Address) => {
          updatePatientAddressMutation({
            variables: {
              patientId: patient?.id,
              relatedName,
              line1: address?.line1 ?? '',
              line2: address?.line2 ?? '',
              city: address?.city ?? '',
              postcode: address?.postcode ?? '',
              state: address?.state ?? '',
              country: address?.country ?? '',
            },
          });
        };

        return (
          <FormContainer {...formContainerProps} submitForm={submitForm}>
            <Form>
              <HeaderTitle data-test-id="rego-page-title">{FIELDS.FORM_HEADING.TITLE}</HeaderTitle>
              <SecondaryTitle>Residential Address</SecondaryTitle>
              <AddressSectionField
                name="address"
                values={values.address || EMPTY_ADDRESS}
                updateAddress={(address: Address) => handleUpdateAddress('address', address)}
              />
              <SecondaryTitle>Postal Address</SecondaryTitle>
              {standardField(FIELDS.SAME_POSTAL_ADDRESS, updateRegularField, yesNoOptions)}
              {values.postalAddressSameAsResidential === false && (
                <StyledIndentedSection>
                  <AddressSectionField
                    name="postalAddress"
                    values={values.postalAddress || EMPTY_ADDRESS}
                    updateAddress={(address: Address) => handleUpdateAddress('postal_address', address)}
                  />
                </StyledIndentedSection>
              )}
            </Form>
          </FormContainer>
        );
      }}
    </Formik>
  );
};

export default AddressForm;
