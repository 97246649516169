import { gql } from '@apollo/client';
import { HEALTH_ASSESSMENT_CANCER_FRAGMENT } from 'op-graphql/fragments';

export const UPDATE_HA_CANCER_MODAL = gql`
  mutation UpdateHaCancerModal($haId: ID!, $patientId: ID!, $cancer: CancerInputType!) {
    updateHaCancerModal(haId: $haId, patientId: $patientId, cancer: $cancer) {
      cancer {
        id
        ...HealthAssessmentCancer
      }
    }
  }
  ${HEALTH_ASSESSMENT_CANCER_FRAGMENT}
`;
