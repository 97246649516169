import * as Yup from 'yup';
import { US_PHONE_REGEX } from 'shared-components/utils/Regex';
import { FormikValues, validateYupSchema, yupToFormErrors } from 'formik';

export const generateValidationSchema = (values: FormikValues): any => {
  const PHONE_VALIDATION = 'Please enter a valid phone number';

  const objectToVerify = Yup.object().shape({
    secondaryInsurance: Yup.object()
      .shape({
        phoneNumber: Yup.string().matches(US_PHONE_REGEX, PHONE_VALIDATION).nullable(true),
      })
      .nullable(true),
    primaryInsurance: Yup.object()
      .shape({
        phoneNumber: Yup.string().matches(US_PHONE_REGEX, PHONE_VALIDATION).nullable(true),
      })
      .nullable(true),
    veteransContactNumber: Yup.string().matches(US_PHONE_REGEX, PHONE_VALIDATION).nullable(true),
  });

  try {
    validateYupSchema<FormikValues>(values, objectToVerify, true);
  } catch (err) {
    return yupToFormErrors(err);
  }
};
