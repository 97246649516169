import * as Yup from 'yup';
import { addressLabelForRegion } from 'shared-components/enums';
import { US_POSTCODE_REGEXP, AU_POSTCODE_REGEXP, UK_POSTCODE_REGEXP } from 'shared-components/utils/Regex';
import { Address } from 'shared-components/interfaces';
import { FormikValues, validateYupSchema, yupToFormErrors } from 'formik';

const validateAddress = (address: Address) => {
  let postcodeValidation: Yup.StringSchema<string | undefined, object> = Yup.string();
  const addressLabels = addressLabelForRegion();
  const postcodeLabel = `Please enter your correct ${addressLabels.postcode}`;
  switch (address?.country) {
    case 'Australia':
      postcodeValidation = Yup.string().matches(AU_POSTCODE_REGEXP, postcodeLabel);
      break;
    case 'United Kingdom':
    case 'Scotland':
    case 'England':
    case 'Northern Ireland':
    case 'Wales':
      postcodeValidation = Yup.string().matches(UK_POSTCODE_REGEXP, postcodeLabel);
      break;
    case 'United States of America':
      postcodeValidation = Yup.string().matches(US_POSTCODE_REGEXP, postcodeLabel);
      break;
    default:
    // no validation
  }
  return postcodeValidation;
};

export const generateValidationSchema = (values: FormikValues): any => {
  const postcodeValidation = validateAddress(values?.alternateAddress);

  const schema = Yup.object().shape({
    alternateAddress: Yup.object().shape({
      autofilled: Yup.string().when('postcode', {
        is: (postcode: string) => postcodeValidation.isValidSync(postcode),
        then: (schema: any) => schema.ensure(),
        otherwise: () => postcodeValidation,
      }),
      postcode: Yup.string().when(['$validatePostcode'], (validatePostcode: any, schema: any) =>
        validatePostcode ? postcodeValidation : schema.ensure(),
      ),
    }),
  });

  try {
    validateYupSchema<FormikValues>(values, schema, true, {
      validatePostcode: values.alternateAddress.postcode && values.alternateAddress.country ? true : false,
    });
  } catch (err) {
    return yupToFormErrors(err);
  }
};
