import React, { useState, useContext, useEffect, useCallback } from 'react';
import CareplanContainer from 'op-pages/RO/Careplan/Container';
import ROPatientCarePlanPageFooter from 'op-pages/RO/Careplan/Footer';
import { usePrescription } from './usePrescription';
import { useHistory, useRouteMatch } from 'react-router';
import { CAREPLAN_PAGE } from '../Constants';
import PatientObservationModal from 'op-pages/MO/Careplan/PrescriptionPage/Components/PatientObservationModal';
import { useQuery } from '@apollo/client';
import { GET_LATEST_MO_PATIENT_OBSERVATION } from '../Queries';
import { LoadingSpinner } from 'shared-components/components';
import { ROPatientContext } from 'op-pages/RO/PatientSummary/context';
import { UserContext } from 'op-contexts';
import DoseEditModal, { DoseInfo } from './Components/DoseEditModal';
import { CareplanCycle, CareplanInterface, PatientObservation } from './interfaces';
import { ViewWidthMinusSidebars, SpaceBetweenDiv, SideBar, PageHeader } from './Components/StyledComponents';
import { CycleTable } from './Components/CycleTable';
import { CycleDateRange } from './Components/CycleDateRange';
import moment from 'moment';
import PatientDetailsCard from './Components/PatientDetailsCard';
import { PrescriptionComments } from './Components/PrescriptionComments';
import DeleteDrugOrderModal from './Components/DeleteDrugOrderModal';
import AddCyclesModal from './Components/AddCyclesModal';
import ApproveCycle from './Components/ApproveCycle';
import { CycleBanner } from './Components/Banner';
import CycleButton from './Components/CycleButton';
import { NewSupportingDrugOrder } from 'op-pages/MO/interfaces';
import { styled } from '@mui/system';
import { getCycleStatus, isNewSupportingDrugApplicable, isPractitionerAllowedToApprove } from 'op-pages/MO/utils';
import { CycleTab } from './Components/CycleTab';
import Button from '@mui/material/Button';
import { IconButton, Stack } from '@mui/material';
import AddCircleOutline from '@mui/icons-material/AddCircleOutline';

const StyledCareplanContainer = styled(CareplanContainer)`
  overflow-x: hidden !important;
  padding: 10px 9px !important;
`;

const MOPrescriptionPage = (): JSX.Element => {
  const match = useRouteMatch<{ id: string; careplanId: string; cycleId: string }>();
  const { id: patientId, careplanId, cycleId } = match.params;
  const { careplanAdditionalInfo, doses, drugOrders, cycles, loading } = usePrescription({
    careplanId,
  });
  const [selectedCycle, setSelectedCycle] = useState(0);
  const [observationModalOpen, setObservationModalOpen] = useState(false);
  const [latestObservation, setLatestObservation] = useState<PatientObservation | null>(null);
  const [doseEditModalOpen, setDoseEditModalOpen] = useState(false);
  const [deleteDrugOrderModalOpen, setDeleteDrugOrderModalOpen] = useState(false);
  const [addCyclesModalOpen, setAddCyclesModalOpen] = useState(false);

  const [doseToEdit, setDoseToEdit] = useState<DoseInfo | undefined>(undefined);
  const [deleteFromDay, setDeleteFromDay] = useState<number | undefined>(undefined);
  const [newSupportingDrugOrder, setNewSupportingDrugOrder] = useState<NewSupportingDrugOrder | null>(null);

  const history = useHistory();
  const { patientDetails } = useContext(ROPatientContext);
  const { state: userDetails } = useContext(UserContext);

  const { loading: observationDataLoading } = useQuery(GET_LATEST_MO_PATIENT_OBSERVATION, {
    variables: { careplanId },
    onCompleted: (data) => {
      const observationDateToLocalTimezone =
        data.latestMoPatientObservation?.observationDate &&
        moment(data.latestMoPatientObservation.observationDate).tz(userDetails.timezone).format('L LT');
      setLatestObservation({ ...data.latestMoPatientObservation, observationDate: observationDateToLocalTimezone });
    },
    fetchPolicy: 'network-only',
  });

  const openDoseEditModal = (doseInfo: DoseInfo) => {
    setDoseToEdit(doseInfo);
    setDoseEditModalOpen(true);
  };

  const openDeleteDrugOrderModal = (doseInfo: DoseInfo, day?: number) => {
    setDeleteDrugOrderModalOpen(true);
    setDoseToEdit(doseInfo);
    setDeleteFromDay(day);
  };

  const careplan: CareplanInterface = {
    doses: doses,
    ...careplanAdditionalInfo,
  };

  const [cycleStartDate, setCycleStartDate] = useState<moment.Moment | null>(null);
  const [cycleEndDate, setCycleEndDate] = useState<moment.Moment | null>(null);
  const [careplanEndDate, setCareplanEndDate] = useState<moment.Moment | null>(null);

  useEffect(() => {
    if (loading) return;
    setCycleStartDate(moment(cycles[selectedCycle].treatmentStartDate));
    setCycleEndDate(moment(cycles[selectedCycle].treatmentEndDate));
    setCareplanEndDate(
      moment(careplan.cycleSchedule[careplan.numberOfCycles - 1].days[careplan.daysPerCycle - 1].date),
    );
  }, [loading, selectedCycle]);

  useEffect(() => {
    if (cycleId && parseInt(cycleId) !== selectedCycle) {
      setSelectedCycle(parseInt(cycleId));
    }
  }, [cycleId]);

  const checkAnyApproved = useCallback(() => {
    return (cycles as CareplanCycle[]).some((cycle) => cycle.approvedAt !== null || cycle.version > 0);
  }, [cycles]);

  const cycleInfoMap = Object.assign({}, ...cycles.map((x: CareplanCycle) => ({ [x.cycleId]: x })));
  const selectedCycleInfo = cycles.filter((cycle: CareplanCycle) => cycle.cycleId === selectedCycle)[0];
  const hasTbdDoses = careplan.doses
    .filter((dose) => dose.cycle === selectedCycle)
    .some((dose) => dose.doseValue === 0 && dose.doseAdjustment === null);
  return (
    <div className="main-container-parent-wrapper">
      <div className="main-container-wrapper">
        <CycleBanner cycleInfo={selectedCycleInfo} supportingDrugOrder={newSupportingDrugOrder} />
        <StyledCareplanContainer>
          <ViewWidthMinusSidebars>
            <PageHeader>
              <h1 data-cy="protocol-name">{careplanAdditionalInfo.careplanName}</h1>
              <hr style={{ marginTop: '8px', marginBottom: '16px' }} />
              <SpaceBetweenDiv>
                <h3 data-cy="cycle-header">Cycles</h3>
                <CycleDateRange startDate={cycleStartDate} endDate={cycleEndDate} />
              </SpaceBetweenDiv>
            </PageHeader>

            <Stack flexDirection="row" style={{ alignItems: 'baseline' }}>
              <CycleTab>
                {[...Array(careplan.numberOfCycles).keys()]
                  .filter((cycle) => cycle >= careplanAdditionalInfo.startingCycle)
                  .map((cycle) => (
                    <CycleButton
                      key={cycle}
                      cycleId={cycleInfoMap[cycle]?.cycleId}
                      isSelected={selectedCycle === cycle}
                      onClick={setSelectedCycle}
                      type={getCycleStatus(cycleInfoMap[cycle], newSupportingDrugOrder)}
                      disabled={newSupportingDrugOrder !== null}
                    />
                  ))}
              </CycleTab>
              <div className="ml-3 pb-1">
                <Button
                  startIcon={<AddCircleOutline color="primary" />}
                  data-cy="add-cycles-modal-button"
                  sx={{ whiteSpace: 'nowrap' }}
                  variant={'text'}
                  size={'small'}
                  disabled={newSupportingDrugOrder !== null}
                  onClick={() => {
                    setAddCyclesModalOpen(true);
                  }}>
                  Add cycles
                </Button>
              </div>
              <div className="ml-3 pb-1">
                <ApproveCycle
                  careplanId={careplanId}
                  cycleInfo={selectedCycleInfo}
                  disabled={
                    hasTbdDoses ||
                    isNewSupportingDrugApplicable(selectedCycle, newSupportingDrugOrder) ||
                    !isPractitionerAllowedToApprove(userDetails, cycles, careplanAdditionalInfo)
                  }
                  tooltip={
                    !hasTbdDoses &&
                    !isNewSupportingDrugApplicable(selectedCycle, newSupportingDrugOrder) &&
                    !isPractitionerAllowedToApprove(userDetails, cycles, careplanAdditionalInfo)
                      ? 'This careplan needs to be first approved by the primary consultant'
                      : ''
                  }
                  isApproved={
                    selectedCycleInfo?.approvedAt &&
                    !isNewSupportingDrugApplicable(selectedCycle, newSupportingDrugOrder)
                  }
                />
              </div>
            </Stack>
            {!(doses.length > 0) ? (
              <LoadingSpinner />
            ) : (
              <CycleTable
                selectedCycle={selectedCycle}
                careplan={careplan}
                cycles={cycles}
                drugOrders={drugOrders}
                openDoseEdit={openDoseEditModal}
                openDeleteDrugOrder={openDeleteDrugOrderModal}
                newSupportingDrugOrder={newSupportingDrugOrder}
                setNewSupportingDrugOrder={setNewSupportingDrugOrder}
              />
            )}
          </ViewWidthMinusSidebars>
        </StyledCareplanContainer>
        <ROPatientCarePlanPageFooter
          onBack={() => {
            const dest = checkAnyApproved() ? CAREPLAN_PAGE.TREATMENT_INFO : CAREPLAN_PAGE.PROTOCOL_SELECTION;
            history.push(`/medonc/patient/${patientId}/careplan/${careplanId}/${dest}`);
          }}
          nextHidden={true}
          backDisabled={false}
          backButtonId={'prescription-back-button'}
        />
      </div>
      <SideBar>
        <PatientDetailsCard
          onEdit={() => setObservationModalOpen(true)}
          patient={patientDetails}
          observation={latestObservation}
          loading={observationDataLoading}
        />

        {careplanAdditionalInfo?.protocolId && (
          <PrescriptionComments comments={careplanAdditionalInfo.latestComment} careplanId={careplanId} />
        )}
      </SideBar>

      <PatientObservationModal
        open={observationModalOpen}
        latestObservation={latestObservation}
        onClose={() => setObservationModalOpen(false)}
        careplanId={parseInt(careplanId)}
        patient={patientDetails}
        setLatestObservation={setLatestObservation}
      />
      <DoseEditModal
        open={doseEditModalOpen}
        onClose={() => setDoseEditModalOpen(false)}
        doseInfo={doseToEdit}
        observationResults={latestObservation}
        cycleEndDate={cycleEndDate}
        careplanEndDate={careplanEndDate}
        numberOfCycles={careplan.numberOfCycles}
        daysPerCycle={careplan.daysPerCycle}
      />
      <DeleteDrugOrderModal
        open={deleteDrugOrderModalOpen}
        onClose={() => setDeleteDrugOrderModalOpen(false)}
        doseInfo={doseToEdit}
        day={deleteFromDay}
      />
      <AddCyclesModal
        open={addCyclesModalOpen}
        onClose={() => setAddCyclesModalOpen(false)}
        protocolName={careplanAdditionalInfo.careplanName}
        currentNumberOfCycles={careplanAdditionalInfo.numberOfCycles}
      />
    </div>
  );
};

export default MOPrescriptionPage;
