import React, { createContext, useState } from 'react';

export const FormContext = createContext<any>({});

export const FormContextProvider = ({ children }: any): JSX.Element => {
  const [formStatusDetails, setFormStatusDetails] = useState<any>();
  return (
    <FormContext.Provider
      value={{
        formStatusDetails,
        setFormStatusDetails,
      }}>
      {children}
    </FormContext.Provider>
  );
};
