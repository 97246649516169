import React from 'react';
import { EventsType, useIdleTimer } from 'react-idle-timer';
const idleTimeOut = 1000 * 60 * 60; // 1 hour
const onIdleHandler = (): void => {
  // Reload the current page after the timer has expired
  if (!window.location.pathname.includes('radiation')) {
    // Exclude the RO pages from refreshing
    window.location.reload();
  }
};
const Refresh = () => {
  const filteredEvents: EventsType[] = ['mousedown', 'touchstart', 'MSPointerDown'];
  useIdleTimer({ onIdle: onIdleHandler, timeout: idleTimeOut, events: filteredEvents });

  return <></>;
};

export default Refresh;
