import React from 'react';
import { NoteType } from './Notes';
import { BaseSelectCheckbox } from 'shared-components/components/FormFields';
import { legacyOnChange } from 'shared-components/components/FormFields/ROSelectCheckbox/BaseSelectCheckbox';

export interface NotesFilterProps {
  noteTypes: NoteType[];
  setFilterNoteTypes: any;
}

const NotesFilter = (props: NotesFilterProps): JSX.Element => {
  const { noteTypes, setFilterNoteTypes } = props;

  const updateNoteCheckBoxOptionTypes = (messageTypes: string[], checked: boolean): any => {
    const updateTypeFilter: NoteType[] | undefined = noteTypes?.map((item: NoteType) => {
      const updated = messageTypes?.filter((currentItem) => currentItem === item.id);
      if (updated.length) {
        item['checked'] = checked;
      }
      return item;
    });
    setFilterNoteTypes(updateTypeFilter || []);
  };

  const options = noteTypes.map((noteType) => {
    return {
      value: noteType.name,
      id: noteType.id,
      checked: noteType.checked || false,
    };
  });

  return (
    <BaseSelectCheckbox
      id="notes-filter"
      options={options}
      placeholder="Notes filter"
      showSelectAll
      fullWidth
      onChange={(event) => legacyOnChange(event, options, updateNoteCheckBoxOptionTypes)}
    />
  );
};

export default NotesFilter;
