import React, { useState } from 'react';
import { SideDrawer, SideDrawerToggle } from 'shared-components/components/SideDrawer';
import { supportedSize } from 'shared-components/StyledComponents/breakpoints';
import { useWindowSize } from 'shared-components/utils/CustomHooks';
import { styled } from '@mui/system';
import { Close as CloseIcon, MoreHoriz as MoreHorizIcon } from '@mui/icons-material';

const StyledSideDrawer = styled(SideDrawer)<{ $width?: string | number }>`
  z-index: 1000;
  margin-top: 8px;
  padding-top: 0 !important;
  min-width: ${({ $width }) => ($width ? $width : '768')}px;
  max-height: calc(100% - 130px);

  & > div {
    width: 100%;
    padding: 0;

    div {
      margin: 0;
      width: 100%;
    }

    .tab-link {
      width: fit-content;
    }
  }

  @media screen and (max-width: ${supportedSize.desktop - 1}px) {
    width: 50%;
    top: unset;
    z-index: 2000;
    margin-right: 15px;
    max-height: calc(100% - 140px);
  }
`;

interface IProps extends React.HTMLAttributes<HTMLElement> {
  children?: React.ReactNode;
  width?: string | number;
}

const ManagementPlanSideDrawer = ({ children, width, ...others }: IProps): JSX.Element => {
  const componentId = 'mx-side-drawer';
  const [drawerIsOpen, setDrawerIsOpen] = useState<boolean>(false);
  const windowSize = useWindowSize();
  const isSmallDisplay = windowSize.width < supportedSize.desktop;

  return (
    <StyledSideDrawer
      id={componentId}
      isOpen={drawerIsOpen}
      showToggleBtn={isSmallDisplay}
      toggleBtn={
        <SideDrawerToggle
          id={`${componentId}-toggle-btn`}
          data-test-id={`${componentId}-toggle`}
          onClick={() => setDrawerIsOpen(!drawerIsOpen)}
          primaryIcon={drawerIsOpen ? <CloseIcon htmlColor="white" /> : <MoreHorizIcon htmlColor="white" />}
          text={drawerIsOpen ? 'Close' : 'More Info'}
        />
      }
      $width={width}
      {...others}>
      {children}
    </StyledSideDrawer>
  );
};

export default ManagementPlanSideDrawer;
