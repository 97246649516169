import { gql } from '@apollo/client';

export const REGISTRATION_INSURANCE_QUERY = gql`
  query RegistrationInsuranceOtherBenefits($id: ID!) {
    patient(id: $id) {
      id
      healthMedicareDvaOption
      healthMedicareNumber
      healthMedicareIrn
      medicareExpiryRawYear
      medicareExpiryRawMonth
      dvaExpiryRawYear
      dvaExpiryRawMonth
      healthDvaNumber
      healthDvaType
      healthPrivateHealthInsurance
      healthPrivateHealthFundName
      healthPrivateHealthFundNumber
      healthPensionCard
      healthPensionCardType
      healthPensionCardNumber
      healthPensionCardExpiry
      healthPensionCardStart
    }
    medicareTypeRefData: listData(category: "healthFund") {
      id
      name
      appKey
    }
    dvaTypeRefData: listData(category: "dvaCardType") {
      id
      name
      appKey
    }
    concessionCardTypeRefData: listData(category: "concessionCardType") {
      id
      name
      appKey
    }
    user {
      id
      isPso
    }
  }
`;

export const UPDATE_REGISTRATION_INSURANCE = gql`
  mutation UpdatePatientAuInsurance(
    $id: ID!
    $healthMedicareDvaOption: String
    $healthMedicareNumber: String
    $healthMedicareIrn: String
    $medicareExpiryRawYear: String
    $medicareExpiryRawMonth: String
    $dvaExpiryRawYear: String
    $dvaExpiryRawMonth: String
    $healthDvaNumber: String
    $healthDvaType: String
    $healthPrivateHealthInsurance: Boolean
    $healthPrivateHealthFundName: String
    $healthPrivateHealthFundNumber: String
    $healthPensionCard: Boolean
    $healthPensionCardType: String
    $healthPensionCardNumber: String
    $healthPensionCardExpiry: String
    $healthPensionCardStart: String
  ) {
    updatePatientAuInsurance(
      id: $id
      healthMedicareDvaOption: $healthMedicareDvaOption
      healthMedicareNumber: $healthMedicareNumber
      healthMedicareIrn: $healthMedicareIrn
      medicareExpiryRawYear: $medicareExpiryRawYear
      medicareExpiryRawMonth: $medicareExpiryRawMonth
      dvaExpiryRawYear: $dvaExpiryRawYear
      dvaExpiryRawMonth: $dvaExpiryRawMonth
      healthDvaNumber: $healthDvaNumber
      healthDvaType: $healthDvaType
      healthPrivateHealthInsurance: $healthPrivateHealthInsurance
      healthPrivateHealthFundName: $healthPrivateHealthFundName
      healthPrivateHealthFundNumber: $healthPrivateHealthFundNumber
      healthPensionCard: $healthPensionCard
      healthPensionCardType: $healthPensionCardType
      healthPensionCardNumber: $healthPensionCardNumber
      healthPensionCardExpiry: $healthPensionCardExpiry
      healthPensionCardStart: $healthPensionCardStart
    ) {
      patient {
        id
        healthMedicareDvaOption
        healthMedicareNumber
        healthMedicareIrn
        medicareExpiryRawYear
        medicareExpiryRawMonth
        dvaExpiryRawYear
        dvaExpiryRawMonth
        healthDvaNumber
        healthDvaType
        healthPrivateHealthInsurance
        healthPrivateHealthFundName
        healthPrivateHealthFundNumber
        healthPensionCard
        healthPensionCardType
        healthPensionCardNumber
        healthPensionCardExpiry
        healthPensionCardStart
      }
    }
  }
`;
