import Button from '@mui/material/Button/Button';
import Typography from '@mui/material/Typography/Typography';
import React, { useCallback } from 'react';
import WarningIcon from '@mui/icons-material/Warning';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import Box from '@mui/material/Box';
import { BannerType, OnTreatmentReviewType } from './Interface';
import {
  DELETE_ON_TREATMENT_REVIEW,
  DISCARD_ON_TREATMENT_REVIEW,
  GET_ON_TREATMENT_REVIEW,
  GET_ON_TREATMENT_REVIEW_LIST,
} from './Queries';
import { useMutation } from '@apollo/client';
import { useHistory } from 'react-router-dom';
import DiscardDialog from './DiscardDialog';
import dayjs from 'dayjs';
import { lighten } from '@mui/system';

const renderDraftBanner = (discardCallback: () => void) => {
  return (
    <>
      <div style={{ padding: '24px' }}>
        <WarningIcon sx={{ color: (theme) => theme.palette.warning.dark }} />
      </div>
      <Typography variant="body2" sx={{ flex: 1, color: (theme) => theme.palette.warning.dark }}>
        You have unsubmitted changes, please submit.
      </Typography>
      <Button
        variant="text"
        size="small"
        sx={{ color: (theme) => theme.palette.warning.dark, fontSize: '13px' }}
        onClick={discardCallback}
        data-testid="on-treatment-review-discard-changes">
        DISCARD DRAFT
      </Button>
    </>
  );
};

const renderUnsubmittedBanner = (submittedAt: string | null, discardCallback: () => void) => {
  return (
    <>
      <div style={{ padding: '24px' }}>
        <WarningIcon sx={{ color: (theme) => theme.palette.warning.dark }} />
      </div>
      <Typography variant="body2" sx={{ flex: 1, color: (theme) => theme.palette.warning.dark }}>
        You have unsubmitted changes, please resubmit. Last submitted at: {submittedAt}
      </Typography>
      <Button
        variant="text"
        size="small"
        sx={{ color: (theme) => theme.palette.warning.dark, fontSize: '13px' }}
        onClick={discardCallback}
        data-testid="on-treatment-review-discard-changes">
        DISCARD CHANGES
      </Button>
    </>
  );
};

const renderSubmittedBanner = () => {
  return (
    <>
      <div style={{ padding: '24px' }}>
        <CheckCircleIcon sx={{ color: (theme) => theme.palette.success.dark }} />
      </div>
      <Typography variant="body2" sx={{ flex: 1, color: (theme) => theme.palette.success.dark }}>
        This has been submitted.
      </Typography>
    </>
  );
};

interface BannerProps {
  review: OnTreatmentReviewType;
  patientId: string;
  discardCallback: () => void;
}

const isChanged = (value: string): boolean => !['', null, []].includes(value);

const getBannerType = (review: OnTreatmentReviewType): BannerType => {
  const draftChanges = [
    review.imageReview,
    review.clinicalAssessment,
    review.plan,
    review.painLevel,
    review.painManagementPlan,
    review.treatmentFractionsReviewed,
  ]
    .map((value) => isChanged(value))
    .some((value) => value);

  if (draftChanges && review.lastSubmittedAt === null) {
    return BannerType.DRAFT;
  }
  if (review.lastSubmittedAt !== null && review.currentChanges) {
    return BannerType.UNSUBMITTED;
  }
  if (review.lastSubmittedAt !== null && !review.currentChanges) {
    return BannerType.SUBMITTED;
  }
  return BannerType.NONE;
};

const Banner = ({ review, patientId, discardCallback }: BannerProps): JSX.Element => {
  const history = useHistory();
  const [openDiscardDialog, setOpenDiscardDialog] = React.useState(false);
  const [deleteTreatmentReview] = useMutation(DELETE_ON_TREATMENT_REVIEW, {
    refetchQueries: [{ query: GET_ON_TREATMENT_REVIEW_LIST, variables: { patientId } }],
  });
  const [discardOnTreatmentReview] = useMutation(DISCARD_ON_TREATMENT_REVIEW, {
    refetchQueries: [{ query: GET_ON_TREATMENT_REVIEW, variables: { id: review.id } }],
  });

  const deleteTreatmentReviewCallback = useCallback(() => {
    deleteTreatmentReview({ variables: { id: review.id } }).then(() => {
      setOpenDiscardDialog(false);
      history.push(`/radiation/patient/${patientId}/on-treatment-review`);
    });
  }, [deleteTreatmentReview]);
  const discardTreatmentReviewCallback = useCallback(() => {
    discardOnTreatmentReview({ variables: { id: review.id } }).then(() => {
      setOpenDiscardDialog(false);
      discardCallback();
    });
  }, [discardOnTreatmentReview]);

  const bannerType = getBannerType(review);
  const bannerCallback =
    bannerType === BannerType.DRAFT ? deleteTreatmentReviewCallback : discardTreatmentReviewCallback;
  const bannerRender = () => {
    switch (bannerType) {
      case BannerType.DRAFT:
        return renderDraftBanner(() => {
          setOpenDiscardDialog(true);
        });
      case BannerType.UNSUBMITTED:
        return renderUnsubmittedBanner(dayjs(review.lastSubmittedAt).format('MMM-DD-YYYY hh:mm A'), () => {
          setOpenDiscardDialog(true);
        });
      case BannerType.SUBMITTED:
        return renderSubmittedBanner();
      default:
        return <></>;
    }
  };
  if (bannerType === BannerType.NONE) return <></>;
  return (
    <Box
      sx={{
        display: 'flex',
        width: '100%',
        height: '52px',
        backgroundColor: (theme) => {
          return bannerType === BannerType.DRAFT
            ? theme.palette.warning.light
            : bannerType === BannerType.UNSUBMITTED
            ? theme.palette.warning.light
            : bannerType === BannerType.SUBMITTED
            ? lighten(theme.palette.success.light, 0.9)
            : 'inherit';
        },
        alignItems: 'center',
      }}
      data-testid={bannerType === BannerType.DRAFT ? 'draft-changes-banner' : 'unsubmitted-changes-banner'}>
      {bannerRender()}
      <DiscardDialog
        review={review}
        bannerType={bannerType}
        open={openDiscardDialog}
        discardCallback={() => {
          bannerCallback();
        }}
        cancelCallback={() => setOpenDiscardDialog(false)}
      />
    </Box>
  );
};

export default Banner;
