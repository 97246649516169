export enum NavigationType {
  APPOINTMENT = 'Appointments',
  INFORMATION = 'Information',
  DETAILS = 'My details',
}

export enum NavigationDropDownMenuType {
  CHANGE_PASSWORD = 'Change password',
  FEEDBACK = 'Feedback',
  TERMS_CONDITION = 'Terms and Conditions',
  LOGOUT = 'Log out',
}

export enum SubmitStatus {
  PENDING,
  SUBMITTING,
  SUBMITTED,
  ERROR,
}
