// eslint-disable-next-line no-use-before-define
import { ApolloClient, ApolloQueryResult } from '@apollo/client';
import { InMemoryCache } from '@apollo/client/cache';
import { gql } from '@apollo/client';
import {
  ArchiveButton,
  ModalInfo,
  ModalSaveExit,
  ModalSubmit,
  ModalValidationError,
  region,
  WelcomeBackModal,
} from 'op-components';
import { NavigatorDirection } from 'op-enums';
import { MandatoryPatient } from 'op-interfaces';
import { FormContext } from '../../../pages/OP/PatientNavigation/context';
import { getNextForm } from '../../../pages/OP/PatientNavigation/helpers';
import { PATIENT_AND_USER_DETAILS_QUERY } from './queries';
import { isUs, validateRegistration, ValidationKeys } from 'op-utils';
import React, { Component } from 'react';
import { withApollo, WithApolloClient } from '@apollo/client/react/hoc';

import { Redirect, RouteComponentProps, withRouter } from 'react-router-dom';
import { SubmitButtonType, Region } from 'shared-components/enums';
import { RegistrationContextType } from 'op-contexts/RegistrationContext/RegistrationContext';
import './RegistrationNavigator.scss';
import ModalResolveConflicts from 'shared-components/components/Modals/ModalResolveConflicts/ModalResolveConflicts';
import { OPUser, PatientClinicSelection } from 'op-interfaces';
import { ListData } from 'shared-interfaces';
import { StandardDialog } from 'shared-components/components';
import { Button, Stack } from '@mui/material';

interface Props extends WithApolloClient<{}>, RouteComponentProps<{ patientId?: string }> {
  patient?: MandatoryPatient;
  isProd?: boolean;
  isPso?: boolean;
  submitRegistration?: (closeModal: () => void, isPso: boolean) => void;
  resolvePatientConflicts?: (id: any) => void;
  submitStatus?: SubmitButtonType;
  saveStatus?: string;
  hasDataConflicts?: boolean;
  resolveConflictsIncomplete?: boolean;
  fileUploaded?: boolean;
  onOpenUploadErrorModal?: any;
  registrationContext: RegistrationContextType;
  navigationContext: any;
}

interface PatientAndUserDetailsQueryData {
  patient: PatientClinicSelection;
  nameTitle: ListData[];
  genderRefData: ListData[];
  clinicTypes: ListData[];
  callers: ListData[];
  user: OPUser;
  locationsRefData: ListData[];
}

interface fieldLinks {
  firstName: string;
  middleName: string;
  lastName: string;
  namePrefix: string;
  gender: string;
  dobRaw: string;
  primaryCenter: string;
  primaryPhone: string;
  secondaryPhone: string;
  email: string;
  residentialAddressLine1: string;
  residentialAddressCity: string;
  residentialAddressState: string;
  residentialAddressCountry: string;
  residentialAddressPostcode: string;
  'emergencyContact.firstName': string;
  'emergencyContact.lastName': string;
  'emergencyContact.relationship': string;
  'emergencyContact.mobilePhoneNumber': string;
  'emergencyContact.homePhoneNumber': string;
  'emergencyContact.authorisedForEnquiries': string;
  'emergencyContact.supportPerson': string;
  'emergencyContact.email': string;
  generalPractitioner: string;
  referringSurgeon: string;
  oncologist: string;
  idb: string;
  nhsOptions: string;
  payor: string;
  registrationReason: string;
  registrationReasonText: string;
  apptClash: string;
  countryOfBirth: string;
  languageAtHome: string;
  heritage: string;
  maritalStatus: string;
  attachments: string;
  attachmentAcknowledged: string;
}

const fieldLinks: any = {
  namePrefix: 'basic',
  firstName: 'basic',
  middleName: 'basic',
  lastName: 'basic',
  gender: 'basic',
  dobRaw: 'basic',
  primaryCenter: 'basic',
  idb: 'basic',
  nhsOptions: 'basic',
  registrationReason: 'basic',
  registrationReasonText: 'basic',
  primaryPhone: 'contact',
  secondaryPhone: 'contact',
  email: 'contact',
  residentialAddressLine1: 'address',
  residentialAddressCity: 'address',
  residentialAddressState: 'address',
  residentialAddressCountry: 'address',
  residentialAddressPostcode: 'address',
  generalPractitioner: 'gp',
  referringSurgeon: 'gp',
  oncologist: 'gp',
  'emergencyContact.firstName': 'altcontact',
  'emergencyContact.lastName': 'altcontact',
  'emergencyContact.relationship': 'altcontact',
  'emergencyContact.mobilePhoneNumber': 'altcontact',
  'emergencyContact.homePhoneNumber': 'altcontact',
  'emergencyContact.authorisedForEnquiries': 'altcontact',
  'emergencyContact.supportPerson': 'altcontact',
  'emergencyContact.email': 'altcontact',
  countryOfBirth: 'demographics',
  languageAtHome: 'demographics',
  heritage: 'demographics',
  maritalStatus: 'demographics',
  payor: 'insurance',
  attachments: 'attachments',
  attachmentAcknowledged: 'attachments',
};

const mandatoryFields: string[] = [
  'namePrefix',
  'firstName',
  'middleName',
  'lastName',
  'dobRaw',
  'gender',
  'primaryCenter',
  'idb',
  'nhsOptions',
  'registrationReason',
  'registrationReasonText',
  'primaryPhone',
  'secondaryPhone',
  'email',
  'residentialAddressLine1',
  'residentialAddressCity',
  'residentialAddressState',
  'residentialAddressCountry',
  'residentialAddressPostcode',
  'generalPractitioner',
  'referringSurgeon',
  'oncologist',
  'emergencyContact.firstName',
  'emergencyContact.lastName',
  'emergencyContact.relationship',
  'emergencyContact.mobilePhoneNumber',
  'emergencyContact.homePhoneNumber',
  'emergencyContact.authorisedForEnquiries',
  'emergencyContact.supportPerson',
  'emergencyContact.email',
  'maritalStatus',
  'countryOfBirth',
  'heritage',
  'languageAtHome',
  'payor',
  'attachments',
  'attachmentAcknowledged',
];

interface State {
  redirecting: boolean;
  redirectTo?: string;
  saveExitModalOpen: boolean;
  submitModalOpen: boolean;
  submitValidationErrorModalOpen: boolean;
  submitValidationErrors: any;
  submitInfoErrorModalOpen: boolean;
  submitInfoErrorTitle?: string;
  submitInfoErrorText?: string;
  resolveConflictsModalOpen: boolean;
  welcomeBackModalOpen: boolean;
}

const MISSING_FIELDS_TITLE = 'Required Fields Are Incomplete';
const MISSING_FIELD_BUTTON_TEXT = 'Go to incomplete field';
const PENDING_SUBMISSION_TITLE = 'Patient Submission Pending';
const PENDING_SUBMISSION_TEXT =
  'You cannot submit this patient currently because there is a pending submission. Please try again later.';

const UPDATE_LAST_VISITED_SECTION = gql`
  mutation UpdatePatient($id: ID!, $lastVisitedSection: String!) {
    updatePatient(id: $id, lastVisitedSection: $lastVisitedSection) {
      patient {
        id
        lastVisitedSection
      }
    }
  }
`;

const GET_FIRST_RETURN = gql`
  query getFieldVisbility {
    firstReturnToForm @client
  }
`;

const cache = new InMemoryCache();
cache.writeQuery({
  query: gql`
    query {
      firstReturnToForm
    }
  `,
  data: {
    firstReturnToForm: true,
  },
});

export const navClient = new ApolloClient({
  cache,
});

const updateFirstReturn = (firstReturnValue: boolean): void => {
  cache.writeQuery({
    query: gql`
      query {
        firstReturnToForm
      }
    `,
    data: {
      firstReturnToForm: firstReturnValue,
    },
  });
};

class RegistrationNavigator extends Component<Props, State> {
  public static contextType = FormContext;
  private currentRegPage: string;
  public static defaultProps = {
    submitRegistration: (): void => {},
    isPso: false,
  };

  public constructor(props: Props) {
    super(props);

    this.state = {
      redirecting: false,
      redirectTo: undefined,
      saveExitModalOpen: false,
      submitModalOpen: false,
      submitValidationErrorModalOpen: false,
      submitValidationErrors: undefined,
      submitInfoErrorModalOpen: false,
      submitInfoErrorTitle: undefined,
      submitInfoErrorText: undefined,
      resolveConflictsModalOpen: false,
      welcomeBackModalOpen: false,
    };

    // Get last segment of url path
    const pathSegments = this.props.location.pathname.split('/');
    this.currentRegPage = pathSegments[pathSegments.length - 1];
  }

  private resetSubmitModalStates = (): void => {
    this.setState({
      submitModalOpen: false,
    });
  };

  private openSubmitModal = (): void => {
    this.setState({
      submitModalOpen: true,
    });
  };

  private closeSubmitValidationErrorModal = (): void => {
    this.setState({
      submitValidationErrorModalOpen: false,
    });
  };

  private openSubmitValidationErrorModal = (validationErrors: any): void => {
    this.setState({
      submitValidationErrorModalOpen: true,
      submitValidationErrors: validationErrors,
    });
  };

  private openWelcomeModal = (): void => {
    this.setState({
      welcomeBackModalOpen: true,
    });
  };

  private closeWelcomeModal = (): void => {
    updateFirstReturn(false);
    this.setState({
      welcomeBackModalOpen: false,
    });
  };

  private refetchRegistrationData = (
    patientId: any,
  ): Promise<ApolloQueryResult<PatientAndUserDetailsQueryData>> | undefined => {
    const data = this.props.client?.query<PatientAndUserDetailsQueryData>({
      query: PATIENT_AND_USER_DETAILS_QUERY,
      variables: { id: patientId },
    });
    return data;
  };

  private closeSubmitInfoErrorModal = (): void => {
    this.setState({
      submitInfoErrorModalOpen: false,
    });
  };

  private openSubmitInfoErrorModal = (title: string, text: string): void => {
    this.setState({
      submitInfoErrorModalOpen: true,
      submitInfoErrorTitle: title,
      submitInfoErrorText: text,
    });
  };

  private resetResolveConflictsModalStates = (): void => {
    this.setState({
      resolveConflictsModalOpen: false,
    });
  };

  private openResolveConflictsModal = (): void => {
    this.setState({
      resolveConflictsModalOpen: true,
    });
  };

  private returnToStart = (): void => {
    const { history, patient } = this.props;
    updateFirstReturn(false);
    history.push({ pathname: `/registration/${patient?.id}/basic`, state: { pxRedirect: true } });
  };

  public componentDidMount(): void {
    const { patient, isPso, client } = this.props;
    if (!patient?.regFormStatus)
      client &&
        client.writeQuery({
          query: gql`
            query {
              firstReturnToForm
            }
          `,
          data: {
            firstReturnToForm: true,
          },
        });

    const firstReturn = navClient.readQuery({ query: GET_FIRST_RETURN });
    if (
      !isUs() &&
      !isPso &&
      patient?.lastVisitedSection &&
      patient?.lastVisitedSection !== 'basic' &&
      patient?.regFormStatus &&
      firstReturn.firstReturnToForm
    ) {
      this.openWelcomeModal();
    }
  }

  // The navigation order of pages. Array is set to stepper order from Stepper.tsx
  public render(): JSX.Element {
    const {
      patient,
      submitRegistration,
      resolvePatientConflicts,
      resolveConflictsIncomplete,
      isPso,
      submitStatus,
      history,
      isProd,
      match: {
        params: { patientId },
      },
      registrationContext,
    } = this.props;

    const recordLocked = patient && patient.lock && patient.lock.readOnly ? patient.lock.readOnly : false;

    const BASE_PAGE_ORDER = ['basic', 'contact', 'address', 'altcontact', 'gp'];

    const PAGE_ORDER =
      region === Region.UK
        ? BASE_PAGE_ORDER.concat(['demographics', 'insurance', 'attachments', 'summary'])
        : BASE_PAGE_ORDER.concat(['medicare', 'demographics', 'infonotice', 'attachments', 'summary']);

    const { redirecting, redirectTo } = this.state;

    // Calculate next form to determine submit modal text
    // @ts-ignore
    const nextForm = getNextForm(this.context.formStatusDetails, patientId!, 'registration');

    // Convert the submit status to text displayed
    let submitButtonText = '';
    switch (submitStatus) {
      case SubmitButtonType.SUBMITTED:
        submitButtonText = SubmitButtonType.SUBMITTED;
        break;
      case SubmitButtonType.SUBMITTING:
        submitButtonText = SubmitButtonType.SUBMITTING;
        break;
      default:
        submitButtonText =
          nextForm['name'] === 'home' ? SubmitButtonType.SUBMIT_AND_EXIT : SubmitButtonType.SUBMIT_AND_CONTINUE;
        break;
    }

    const orderedValidation = this.generateOrderedValidation(fieldLinks);

    const formSectionRedirect = orderedValidation && fieldLinks[orderedValidation[0]];

    if (redirecting && redirectTo) {
      return <Redirect to={redirectTo} />;
    } else {
      // @ts-ignore
      return (
        <div className="reg-nav-container">
          <ModalSaveExit
            isOpen={this.state.saveExitModalOpen}
            dismissFunction={(): void => {
              // Reset to the default state
              this.resetSaveExitModalStates();
            }}
            exitForm={(): void => {
              if (region === Region.UK) {
                history.push('/search');
              } else {
                this.props.client?.mutate({
                  mutation: UPDATE_LAST_VISITED_SECTION,
                  variables: { id: patientId, lastVisitedSection: this.currentRegPage },
                });
                updateFirstReturn(true);
                this.refetchRegistrationData(patientId)?.then(() =>
                  history.push({ pathname: `/patient/${patientId}/home`, state: { pxRedirect: true } }),
                );
              }
            }}
            exitText={'Exit form'}
          />
          <StandardDialog
            open={this.state.submitValidationErrorModalOpen}
            title={MISSING_FIELDS_TITLE}
            onClose={() => this.closeSubmitValidationErrorModal()}
            submitText={MISSING_FIELD_BUTTON_TEXT}
            onSubmit={() => {
              this.refetchRegistrationData(patientId)?.then(() =>
                history.push(`/registration/${patientId}/${formSectionRedirect}`),
              );
            }}>
            <ModalValidationError
              validationErrors={this.state.submitValidationErrors}
              mandatoryFields={mandatoryFields}
            />
          </StandardDialog>
          <WelcomeBackModal
            returnToStart={() => this.returnToStart()}
            dismissFunction={(): void => {
              this.closeWelcomeModal();
            }}
            updateFirstReturn={() => updateFirstReturn(false)}
            isOpen={this.state.welcomeBackModalOpen}>
            <></>
          </WelcomeBackModal>
          <ModalInfo
            isOpen={this.state.submitInfoErrorModalOpen}
            title={this.state.submitInfoErrorTitle || ''}
            text={this.state.submitInfoErrorText || ''}
            extraClass="pending-submit-modal"
            dismissFunction={(): void => {
              this.closeSubmitInfoErrorModal();
            }}
          />
          <ModalSubmit
            isOpen={this.state.submitModalOpen}
            isPSO={isPso}
            isProd={isProd}
            dismissFunction={(): void => {
              // Reset to the default state
              this.resetSubmitModalStates();
            }}
            submitForm={(): void => {
              if (typeof submitRegistration !== 'undefined' && typeof isPso !== 'undefined') {
                submitRegistration(this.resetSubmitModalStates, isPso);
              }
            }}
            submitText={submitButtonText}
            continueNextForm={nextForm['name'] !== 'home'}
          />
          <ModalResolveConflicts
            isOpen={this.state.resolveConflictsModalOpen}
            dismissFunction={(): void => {
              // Reset to the default state
              this.resetResolveConflictsModalStates();
            }}
            resolveConflictsPage={(): void => {
              if (typeof resolvePatientConflicts !== 'undefined' && patient) {
                resolvePatientConflicts({ variables: { patientId } });
              }
            }}
          />
          <div className="reg-nav-wrapper">
            <div className="left-container left-container-uk">
              <Stack direction="row" className="button-container" gap={1}>
                {this.currentRegPage !== PAGE_ORDER[0] && (
                  <Button
                    size="large"
                    onClick={(): void => {
                      if (this.props.fileUploaded !== undefined) {
                        if (this.props.fileUploaded) {
                          this.traverseRegistration(NavigatorDirection.BACK, PAGE_ORDER);
                        } else {
                          this.props.onOpenUploadErrorModal();
                        }
                      } else {
                        this.traverseRegistration(NavigatorDirection.BACK, PAGE_ORDER);
                      }
                    }}
                    name="registration-nav-back">
                    Back
                  </Button>
                )}
                <Button
                  onClick={(): void => {
                    if (this.props.fileUploaded !== undefined) {
                      if (this.props.fileUploaded) {
                        this.handleSaveAndExitButtonClicked();
                      } else {
                        this.props.onOpenUploadErrorModal();
                      }
                    } else {
                      this.handleSaveAndExitButtonClicked();
                    }
                  }}
                  size="large"
                  name="registration-nav-save-and-exit">
                  Exit
                </Button>
              </Stack>
            </div>
            <div className="right-container-uk">
              <div className="button-container">
                {this.currentRegPage !== PAGE_ORDER[PAGE_ORDER.length - 1] && (
                  <Button
                    size="large"
                    onClick={(): void => {
                      if (this.props.fileUploaded !== undefined) {
                        if (this.props.fileUploaded) {
                          this.traverseRegistration(NavigatorDirection.FORWARD, PAGE_ORDER);
                        } else {
                          this.props.onOpenUploadErrorModal();
                        }
                      } else {
                        this.traverseRegistration(NavigatorDirection.FORWARD, PAGE_ORDER);
                      }
                    }}
                    name="registration-nav-continue"
                    variant="contained">
                    Continue
                  </Button>
                )}
                {this.currentRegPage === PAGE_ORDER[PAGE_ORDER.length - 1] && (
                  <Button
                    size="large"
                    onClick={(): void => {
                      const validationErrors =
                        patient &&
                        validateRegistration(patient, ValidationKeys.Mosaiq, undefined, Region.UK, isPso || false);
                      if (validationErrors) {
                        this.openSubmitValidationErrorModal(validationErrors);
                      } else if (patient && patient.hasPendingSubmission) {
                        this.openSubmitInfoErrorModal(PENDING_SUBMISSION_TITLE, PENDING_SUBMISSION_TEXT);
                      } else {
                        this.openSubmitModal();
                      }
                    }}
                    name="registration-nav-submit"
                    disabled={recordLocked}
                    variant="contained">
                    Submit
                  </Button>
                )}
                {patient && (
                  // @ts-ignore
                  <ArchiveButton patient={patient} redirectPath="/search" />
                )}
              </div>
            </div>
          </div>
        </div>
      );
    }
  }

  private generateOrderedValidation = (fieldLinks: fieldLinks): string[] => {
    const orderedValidation: string[] = [];

    Object.keys(fieldLinks).map((key: string) => {
      if (this.state.submitValidationErrors && this.state.submitValidationErrors[key]) {
        orderedValidation.push(key);
      }
    });

    return orderedValidation;
  };

  private resetSaveExitModalStates = (): void => {
    this.setState({
      saveExitModalOpen: false,
    });
  };

  private handleSaveAndExitButtonClicked = (): void => {
    const {
      isPso,
      match: {
        params: { patientId },
      },
    } = this.props;

    // if context is populated with history, use that. Otherwise default to patient summary page
    let redirectTo =
      (this.props.navigationContext && this.props.navigationContext.state.regEntryPath) ||
      `/navigator/patient/${patientId}/summary`;
    if (region === Region.UK) {
      redirectTo = '/search';
    }
    if (isPso) {
      this.setState({
        redirecting: true,
        redirectTo,
      });
    } else {
      this.setState({
        saveExitModalOpen: true,
      });
    }
  };

  /**
   * Utility function to take direction and navigate to the new form page
   */
  private traverseRegistration = (direction: NavigatorDirection, PAGE_ORDER: string[]): void => {
    const nextPage: number = PAGE_ORDER.indexOf(this.currentRegPage);
    // Remove the last segment of the current path
    let newPath = window.location.pathname.split('/').slice(0, -1).join('/');

    switch (direction) {
      case NavigatorDirection.BACK:
        newPath += `/${PAGE_ORDER[nextPage - 1]}`;
        break;
      case NavigatorDirection.FORWARD:
        newPath += `/${PAGE_ORDER[nextPage + 1]}`;
        break;
      default:
        break;
    }

    this.setState({
      redirecting: true,
      redirectTo: newPath,
    });
  };
}

const routedComponent = withRouter(withApollo<Props>(RegistrationNavigator));
export default routedComponent;
