export const TABLE_KEYS = {
  TREATING_DEPARTMENT: 'treatingDepartment',
  CAREPLAN: 'careplan',
  TREATMENT_START_DATE: 'treatmentStartDate',
  TREATMENT_END_DATE: 'treatmentEndDate',
  CURRENT_CYCLE: 'currentCycle',
  NEXT_TREATMENT: 'next_treatment',
  STATUS: 'status',
  LAST_MODIFIED: 'lastModified',
};
export const TABLE_LABELS = {
  TREATING_DEPARTMENT: 'Treating department',
  CAREPLAN: 'Careplan',
  TREATMENT_START_DATE: 'Start date',
  TREATMENT_END_DATE: 'End date',
  CURRENT_CYCLE: 'Current cycle',
  NEXT_TREATMENT: 'Next treatment',
  STATUS: 'Status',
  LAST_MODIFIED: 'Last modified',
};

export const CAREPLAN_STATUS = {
  APPROVED: 'Approved',
  APPROVAL_REQUIRED: 'Approval Required',
  SAVE_PRINT_REQUIRED: 'Save/Print Required',
  DRAFT: 'Draft',
  COMPLETED: 'Careplan completed',
};
