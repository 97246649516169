// eslint-disable-next-line no-use-before-define
import React from 'react';
import { IntakePageProps } from '../Interface';
import { BreastIntakeV1 } from './BreastIntakeV1';
import { BreastIntakeV2 } from './BreastIntakeV2';
import { CurrentAppConfig } from 'op-pages/RO/Careplan/AppConfig';

const ROPatientDiagnosisIntakeBreastPageRouter = ({
  updateIntakeHandler,
  featureVersion,
}: IntakePageProps): JSX.Element => {
  if (featureVersion !== undefined && featureVersion > 1 && CurrentAppConfig.Intake.breastFormVersion === 2) {
    return <BreastIntakeV2 updateIntakeHandler={updateIntakeHandler} />;
  } else {
    return <BreastIntakeV1 updateIntakeHandler={updateIntakeHandler} />;
  }
};

export default ROPatientDiagnosisIntakeBreastPageRouter;
