import React from 'react';
import { styled } from '@mui/system';
import { FieldProps } from 'formik';
import SelectButton from 'shared-components/components/UIFormComponents/SelectButton';
import { HelperMessage } from 'shared-components/components';
import { GraphQLResponse } from 'shared-components/interfaces';

interface Props extends FieldProps {
  options: string[];
  error?: string;
  createMutation: (field: string, name: string) => any;
  deleteMutation: (id: string) => void;
  parentField: string;
}

interface ButtonContainerProps {
  isLastItem: boolean;
}

const MultiSelectContainer = styled('section')`
  display: flex;
`;

const SelectButtonContainer = styled('section')<ButtonContainerProps>`
  margin-right: ${({ isLastItem }: ButtonContainerProps) => (isLastItem ? '0' : '16px')};
`;

const MultiSelectButtonGroup = ({
  options,
  createMutation,
  deleteMutation,
  parentField,
  form,
  field,
  error,
}: Props): JSX.Element => {
  const { name } = field;
  const { values, setFieldValue, setTouched } = form;
  const fieldError = error;

  const isActive = (option: string): boolean => {
    return values[name].filter((item: any) => item.name === option).length;
  };

  const handleClick = (fieldName: string, value: string) => {
    const fieldValues = values[fieldName];
    const existingValues = fieldValues.filter((item: any) => item['name'] === value);
    if (existingValues.length > 0) {
      // Delete value and set new fieldValues
      deleteMutation(existingValues[0].id);
      const filteredValues = fieldValues.filter((item: any) => item['name'] !== value);
      setFieldValue(fieldName, filteredValues, false);
    } else {
      // Add placeholder to fieldValues before response returned from gql, then replace
      fieldValues.push({ fieldName, value });
      createMutation(fieldName, value).then((result: GraphQLResponse) => {
        const newItem: any = Object.values(result.data)[0];
        fieldValues.pop();
        setFieldValue(fieldName, [...fieldValues, { ...newItem[parentField] }], false);
      });
    }
    setTouched({ [fieldName]: true });
  };

  return (
    <>
      <MultiSelectContainer>
        {options.map((item: string, index: number): JSX.Element => {
          const isLastItem = index === options.length - 1;
          const styledProps = { isLastItem };

          return (
            <SelectButtonContainer {...styledProps} key={item} onClick={() => handleClick(name, item)}>
              <SelectButton buttonText={item} active={isActive(item)} showCheckIcon />
            </SelectButtonContainer>
          );
        })}
      </MultiSelectContainer>
      {fieldError && <HelperMessage fieldName={name} fieldText={fieldError} />}
    </>
  );
};

export default MultiSelectButtonGroup;
