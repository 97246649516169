// eslint-disable-next-line no-use-before-define
import React, { Component } from 'react';
import Markdown from 'markdown-to-jsx';
import { STATIC_CONTENT } from './staticContent';

import './TermsAndConditions.scss';

class TermsAndConditions extends Component {
  public render(): JSX.Element {
    const OpenLinkInNewTab = ({ ...props }) => <a {...props} target="_blank" />;

    return (
      <div className="px-tnc-page-container">
        <Markdown options={{ overrides: { a: OpenLinkInNewTab } }}>{STATIC_CONTENT}</Markdown>
      </div>
    );
  }
}

export default TermsAndConditions;
