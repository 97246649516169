import React from 'react';
import { styled } from '@mui/system';
import { getRemSize } from 'shared-components/StyledComponents/functions';

interface Props {
  id: string;
  triangle: boolean;
  className?: string;
  children: any;
}

const Wrapper = styled('div')<Props>`
  position: relative;
  border: 1px solid ${(props) => props.theme.palette.text.primary};
  margin-bottom: 8px;
  padding: 8px 10px;
  background-color: ${(props) => props.theme.palette.text.primary};
  color: ${(props) => props.theme.palette.primary.contrastText};
  font-size: ${getRemSize(14)};
  border-radius: 4px;
  width: max-content;
  line-height: 20px;
  ${(props) =>
    ({ triangle }: Props) =>
      triangle &&
      `::after {
      content: '';
      box-sizing: border-box;
      position: absolute;
      left: 50%;
      bottom: -7px;
      border-style: solid;
      border-width: 0 7px 6px 7px;
      border-color: transparent;
      border-bottom-color: ${props.theme.palette.text.primary};
      transform: rotate(180deg);
    }
  `};
`;

const Tooltip = ({ id, triangle, className, children }: Props): JSX.Element => (
  <Wrapper id={id} triangle={triangle} className={className} role="tooltip">
    {children}
  </Wrapper>
);

export default Tooltip;
