import React from 'react';
import { styled } from '@mui/system';
import { useLocation } from 'react-router-dom';
import { ReviewBanner, FormFooter, Banner } from 'shared-components/components';
import { PatientIdentifierBar } from 'op-components';
import { MandatoryPatient } from 'op-interfaces';
import { supportedSize } from 'shared-components/StyledComponents/breakpoints';
import ManagementPlanSidePanel from 'op-pages/OP/ManagementPlan/components/ManagementPlanSidePanel';
import ManagementPlanSideDrawer from 'op-pages/OP/ManagementPlan/components/ManagementPlanSideDrawer';
import { ReviewInfo } from 'shared-components/components/UIFormComponents/ReviewBanner';

const FormDiv = styled('div')`
  display: flex;
  flex-direction: column;
  height: 100%;
  width: 100%;
`;

const Wrapper = styled('div')`
  display: flex;
  margin: 24px 32px;
  flex-direction: column;
  max-width: 870px;

  @media all and (max-width: ${supportedSize.transition}px) {
    width: 100%;
    margin: 0;
    padding: 24px 32px;
  }

  @media all and (max-width: ${supportedSize.tablet}px) {
    width: 100%;
    margin: 0;
    padding: 12px 12px;
  }
`;

const OverflowContainer = styled('div')`
  overflow: auto;
  height: 100%;
  flex: 1;
  flex-basis: 0;

  @media all and (max-width: ${supportedSize.transition}px) {
    margin-bottom: 84px;
    padding-bottom: 100px;
  }

  @media all and (max-width: ${supportedSize.tablet}px) {
    margin-bottom: 56px;
  }
`;

const FormSidePanelWrapper = styled('div')`
  display: flex;
  height: 100%;
  width: 100%;
`;

interface Props {
  sectionTitle: string;
  pageNumber?: number;
  patient?: MandatoryPatient;
  reviewInfo?: ReviewInfo[];
  children?: React.ReactNode;
  backLink?: string;
  saveAndExitLink?: string;
  continueLink?: string;
  handleValidation?: (event: React.MouseEvent<HTMLInputElement>) => void;
  submitForm?: () => void;
  showSaveAndExit?: boolean;
  isLocked?: boolean;
  deleteModal: () => void;
  setDeleteModalIsOpen?: (value: boolean) => void;
  createAmendmentAndRedirect?: () => void;
  updatedBy: any;
  isAmendment?: boolean;
  lastUpdateUser?: any;
  sidePanelWidth?: string;
  showMxPlanSidePanel?: boolean;
  showDT?: boolean;
  activeTabOption?: string;
  submitButtonText?: string;
  showDiscard?: boolean;
  footerPosition?: string;
  isLoading?: boolean;
  continueDisabled?: boolean;
  isShowSaveExitConfirm?: boolean;
  handleShowSaveExitConfirm?: () => void;
  showPTSummaryNav?: boolean;
}

const FormContainer = ({
  patient,
  sectionTitle,
  reviewInfo,
  pageNumber,
  backLink,
  saveAndExitLink,
  continueLink,
  handleValidation,
  submitForm,
  showSaveAndExit,
  children,
  isLocked,
  deleteModal,
  setDeleteModalIsOpen,
  createAmendmentAndRedirect,
  isAmendment,
  updatedBy,
  lastUpdateUser,
  sidePanelWidth,
  showMxPlanSidePanel,
  showDT = false,
  activeTabOption,
  submitButtonText,
  showDiscard = true,
  footerPosition = 'fixed',
  isLoading = false,
  continueDisabled = false,
  isShowSaveExitConfirm = false,
  handleShowSaveExitConfirm,
  showPTSummaryNav = false,
}: Props): JSX.Element => {
  const sectionId = sectionTitle
    .toLowerCase()
    .replace(/[^\w\s]/g, '')
    .replace(/\s/g, '-');

  const location = useLocation();
  const { pathname } = location;
  const formType = pathname.includes('chartcheck') || pathname.includes('triage') ? 'nurseAssessment' : '';

  const styledProps = {
    $height: patient ? 'calc(100% - 84px)' : '100%',
  };

  const formLockedMessage = {
    nurseAssessment: 'This form has been submitted',
  };

  const dateUpdated = lastUpdateUser?.filter((item: { fieldName: string }) => item.fieldName === 'updatedBy').length
    ? new Date(lastUpdateUser?.filter((item: { fieldName: string }) => item.fieldName === 'updatedBy')[0].updatedDate)
    : '';

  const updatedDateTime =
    dateUpdated !== ''
      ? `${dateUpdated.toLocaleDateString('en-AU')} ${dateUpdated.toLocaleTimeString('en-AU', {
          hour: '2-digit',
          minute: '2-digit',
        })}`
      : '';
  const text = {
    subtext: 'You are amending this assessment.',
    text: updatedDateTime ? `Last modified ${updatedDateTime} by ${updatedBy}` : '',
  };

  const renderForm = (): JSX.Element => {
    return (
      <OverflowContainer>
        {reviewInfo && <ReviewBanner sectionTitle={sectionTitle} reviewInfo={reviewInfo} />}
        <Wrapper>{children}</Wrapper>
      </OverflowContainer>
    );
  };

  const renderFooter = (): JSX.Element => {
    return (
      <FormFooter
        pageNumber={pageNumber}
        backLink={backLink}
        saveAndExitLink={saveAndExitLink}
        continueLink={continueLink}
        handleClickOnBack={handleValidation}
        handleClickOnContinue={handleValidation}
        submitForm={submitForm}
        showSaveAndExit={showSaveAndExit}
        isLocked={isLocked}
        isAmendment={isAmendment}
        deleteModal={() => deleteModal()}
        setDeleteModalIsOpen={setDeleteModalIsOpen}
        createAmendmentAndRedirect={createAmendmentAndRedirect}
        sidePanelWidth={sidePanelWidth}
        submitButtonText={submitButtonText}
        showDiscard={showDiscard}
        footerPosition={footerPosition}
        isLoading={isLoading}
        continueDisabled={continueDisabled}
        isShowSaveExitConfirm={isShowSaveExitConfirm}
        handleShowSaveExitConfirm={handleShowSaveExitConfirm}
      />
    );
  };

  return (
    <FormDiv {...styledProps} id={`${sectionId}-section-form-div`}>
      {patient && (
        <PatientIdentifierBar patient={patient} includePhoto={showPTSummaryNav} includeGenderAbrev={showPTSummaryNav} />
      )}
      {isLocked && ( //@ts-ignore
        <Banner text={formLockedMessage[formType]} type={'success'} />
      )}
      {isAmendment && !isLocked && <Banner type={'info'} {...text} />}
      {showMxPlanSidePanel ? (
        <FormSidePanelWrapper>
          <div style={{ display: 'flex', flexDirection: 'column', width: '100%' }}>
            {renderForm()}
            {renderFooter()}
          </div>
          <ManagementPlanSideDrawer>
            <ManagementPlanSidePanel showDT={showDT} activeTabOption={activeTabOption} blur={true} />
          </ManagementPlanSideDrawer>
        </FormSidePanelWrapper>
      ) : (
        <>
          {renderForm()}
          {renderFooter()}
        </>
      )}
    </FormDiv>
  );
};

export default FormContainer;
