// eslint-disable-next-line no-use-before-define
import React, { Component } from 'react';

import './DTQuestionContent.scss';

import { DTAnswerItem } from 'op-classes';
import { Stack } from '@mui/material';
import { CheckBox as CheckboxIcon } from '@mui/icons-material';
import { Checkbox } from 'shared-components/components/FormFields';
import { DT_CATEGORY } from 'op-enums';

const CONTENT_HEADING = {
  physical: 'Physical concerns',
  emotional: 'Emotional concerns',
  social: 'Social concerns',
  practical: 'Practical concerns',
  spiritual: 'Spiritual/Religious concerns',
  other: 'Other concerns',
};

interface Props {
  distressId: string;
  answers: DTAnswerItem[];
  category: DT_CATEGORY;
  patientId: string;
  autosave: (updateObject: object, replaceId?: string) => void;
}

class DTQuestionContent extends Component<Props> {
  public render(): JSX.Element {
    const { category } = this.props;

    const messagePartOne = 'Please indicate by ticking the boxes';
    const messagePartTwo = 'below if any of the following has been a problem for you in the past week including today.';

    return (
      <div id="dt-question-content">
        <div id="dt-question-title">{CONTENT_HEADING[category as keyof typeof CONTENT_HEADING]}</div>
        <div id="dt-question-instructions">
          {messagePartOne}
          <CheckboxIcon color="primary" sx={{ margin: '0 4px', verticalAlign: 'sub' }} />
          {messagePartTwo}
        </div>
        <div id="dt-question-checkboxes">{this.mapAnswers()}</div>
      </div>
    );
  }

  private mapAnswers = (): JSX.Element[] => {
    const { answers, category, distressId, patientId } = this.props;
    return answers.map((answerItem, index): JSX.Element => {
      const inputItem = <span className="checkbox-item-label">{answerItem.question}</span>;
      const questionFirstWord = answerItem.question.split(' ')[0].toLowerCase();
      return (
        <div key={answerItem.id} className="checkbox-item">
          <Checkbox
            inputName={`${category}-${index + 1}-${questionFirstWord}-${answerItem.id}`}
            inputLabel={inputItem}
            isChecked={answerItem.value}
            labelFirst={true}
            onChange={(isChecked): void => {
              // Build the object that will be updated
              const updateObject = {
                id: distressId,
                patientId,
                answers: { id: answerItem.id, value: isChecked },
              };
              this.props.autosave(updateObject, answerItem.id);
            }}
          />
        </div>
      );
    });
  };
}

export default DTQuestionContent;
