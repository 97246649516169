/**
 * Note: Keys for validation objects are the keys that are returned as part of the data Query
 */
import { HAReviewSummaryItem } from 'op-classes';
import validate from 'shared-components/utils/validate';
import { isUs } from 'op-utils';

export enum ValidationKeys {
  All,
  Info,
  OtherMedicalProblems,
  MedicalOther,
  SocialAssessment,
  MedicalIllness,
  Medication,
  Operations,
  Cancer,
  Allergies,
}

const VALIDATION_MESSAGE_TYPE = {
  INVALID: 'INVALID_DATA',
  MISSING: 'MISSING_DATA',
  MISSING_DESCRIPTION: 'Please enter a description',
};

export const INVALID_SECTIONS = {
  INFO: 'info',
  OTHER_MEDICAL_PROBLEMS: 'otherMedicalProblems',
  MEDICAL_HISTORY_OTHER: 'medicalHistoryOther',
  MEDICAL_HISTORY_ILLNESS: 'medicalHistoryIlness',
  SOCIAL_ASSESSMENT: 'socialAssesment',
  MEDICATION: 'medication',
  OPERATIONS: 'operations',
  ALLERGIES: 'allergies',
  CANCER: 'cancer',
};

const INFO_RULES = {
  height: {
    presence: {
      allowEmpty: false,
      message: VALIDATION_MESSAGE_TYPE.MISSING,
    },
    numeric: {
      greaterThan: 0,
      lessThan: 300,
      message: VALIDATION_MESSAGE_TYPE.INVALID,
    },
  },
  weight: {
    presence: {
      allowEmpty: false,
      message: VALIDATION_MESSAGE_TYPE.MISSING,
    },
    numeric: {
      greaterThan: 0,
      message: VALIDATION_MESSAGE_TYPE.INVALID,
    },
  },
};
const MEDICAL_HISTORY_ILLNESS_ERRORS = {
  MISSING: 'Please select an option.',
};
const MEDICAL_HISTORY_ILLNESS_RULES = {
  internalDevice: {
    presence: {
      allowEmpty: false,
      message: MEDICAL_HISTORY_ILLNESS_ERRORS.MISSING,
    },
  },
  internalDevices: {
    presence: {
      allowEmpty: false,
      message: MEDICAL_HISTORY_ILLNESS_ERRORS.MISSING,
    },
  },
  doctorClearance: {
    presence: {
      allowEmpty: false,
      message: MEDICAL_HISTORY_ILLNESS_ERRORS.MISSING,
    },
  },
  bloodClot: {
    presence: {
      allowEmpty: false,
      message: MEDICAL_HISTORY_ILLNESS_ERRORS.MISSING,
    },
  },
  bloodClotAnswer: {
    presence: {
      allowEmpty: false,
      message: 'Please describe your blood clot.',
    },
  },
  heartOrLungProblem: {
    presence: {
      allowEmpty: false,
      message: MEDICAL_HISTORY_ILLNESS_ERRORS.MISSING,
    },
  },
  heartOrLungAnswer: {
    presence: {
      allowEmpty: false,
      message: 'Please describe your heart or lung problems.',
    },
  },
  highOrLowBloodPressure: {
    presence: {
      allowEmpty: false,
      message: MEDICAL_HISTORY_ILLNESS_ERRORS.MISSING,
    },
  },
  bloodPressureAnswer: {
    presence: {
      allowEmpty: false,
      message: MEDICAL_HISTORY_ILLNESS_ERRORS.MISSING,
    },
  },
  internalDeviceAnswer: {
    presence: {
      allowEmpty: false,
      message: MEDICAL_HISTORY_ILLNESS_ERRORS.MISSING,
    },
  },
  diabetesAnswer: {
    presence: {
      allowEmpty: false,
      message: MEDICAL_HISTORY_ILLNESS_ERRORS.MISSING,
    },
  },
  intractableInfectionOrIllnessAnswer: {
    presence: {
      allowEmpty: false,
      message: MEDICAL_HISTORY_ILLNESS_ERRORS.MISSING,
    },
  },
  immunosuppressiveConditionAnswer: {
    presence: {
      allowEmpty: false,
      message: MEDICAL_HISTORY_ILLNESS_ERRORS.MISSING,
    },
  },
  diabetes: {
    presence: {
      allowEmpty: false,
      message: MEDICAL_HISTORY_ILLNESS_ERRORS.MISSING,
    },
  },
  intractableInfectionOrIllness: {
    presence: {
      allowEmpty: false,
      message: MEDICAL_HISTORY_ILLNESS_ERRORS.MISSING,
    },
  },
  immunosuppressiveCondition: {
    presence: {
      allowEmpty: false,
      message: MEDICAL_HISTORY_ILLNESS_ERRORS.MISSING,
    },
  },
};
const MEDICAL_HISTORY_OTHER_ERRORS = {
  MISSING: 'Please select an option.',
};
const MEDICAL_HISTORY_OTHER_RULES = {
  mobilityAid: {
    presence: {
      allowEmpty: false,
      message: MEDICAL_HISTORY_OTHER_ERRORS.MISSING,
    },
  },
  value: {
    presence: {
      allowEmpty: false,
      message: MEDICAL_HISTORY_OTHER_ERRORS.MISSING,
    },
  },
  wellbeingIssue: {
    presence: {
      allowEmpty: false,
      message: MEDICAL_HISTORY_OTHER_ERRORS.MISSING,
    },
  },
  activeInjury: {
    presence: {
      allowEmpty: false,
      message: MEDICAL_HISTORY_OTHER_ERRORS.MISSING,
    },
  },
  activeInjuryReason: {
    presence: {
      allowEmpty: false,
      message: 'Please describe your pressure injury.',
    },
  },
  inPain: {
    presence: {
      allowEmpty: false,
      message: MEDICAL_HISTORY_OTHER_ERRORS.MISSING,
    },
  },
  inPainReason: {
    presence: {
      allowEmpty: false,
      message: 'Please describe your pain.',
    },
  },
  fallen: {
    presence: {
      allowEmpty: false,
      message: MEDICAL_HISTORY_OTHER_ERRORS.MISSING,
    },
  },
  fallenReason: {
    presence: {
      allowEmpty: false,
      message: 'Please describe your recent fall.',
    },
  },
  pregnant: {
    presence: {
      allowEmpty: false,
      message: MEDICAL_HISTORY_OTHER_ERRORS.MISSING,
    },
  },
  advancedCareDirective: {
    presence: {
      allowEmpty: false,
      message: MEDICAL_HISTORY_OTHER_ERRORS.MISSING,
    },
  },
};

const OTHER_MEDICAL_PROBLEMS_RULES = {
  otherMedProblemBool: {
    presence: {
      allowEmpty: false,
      message: 'Please select an option',
    },
  },
  otherMedProblemDescription: {
    presence: {
      allowEmpty: false,
      message: VALIDATION_MESSAGE_TYPE.MISSING_DESCRIPTION,
    },
  },
  altMedicineBool: {
    presence: {
      allowEmpty: false,
      message: 'Please select an option',
    },
  },
  altMedicineDescription: {
    presence: {
      allowEmpty: false,
      message: VALIDATION_MESSAGE_TYPE.MISSING_DESCRIPTION,
    },
  },
};

const SOCIAL_ASSESSMENT_RULES = {
  alcoholBool: {
    presence: {
      allowEmpty: false,
      message: 'Please select an option',
    },
  },
  alcoholFrequency: {
    presence: {
      allowEmpty: false,
      message: 'Please select a drinking frequency.',
    },
  },
  smokingBool: {
    presence: {
      allowEmpty: false,
      message: 'Please select an option.',
    },
  },
  smokingHistory: {
    presence: {
      allowEmpty: false,
      message: 'Please enter valid years for your smoking history.',
    },
    numeric: {
      greaterThan: 0,
      message: 'Please enter valid years for your smoking history.',
    },
  },
  smokingFrequency: {
    presence: {
      allowEmpty: false,
      message: 'Please select your smoking frequency.',
    },
  },
  exerciseBool: {
    presence: {
      allowEmpty: false,
      message: 'Please select an option.',
    },
  },
  exerciseFrequency: {
    presence: {
      allowEmpty: false,
      message: 'Please select exercise frequency.',
    },
  },
  accommodationAssistance: {
    presence: {
      allowEmpty: false,
      message: 'Please select an option.',
    },
  },
  accommodationAssistanceReason: {
    presence: {
      allowEmpty: false,
      message: 'Please enter the reason assistance is required.',
    },
  },
  transportationAssistance: {
    presence: {
      allowEmpty: false,
      message: 'Please select an option.',
    },
  },
  transportationAssistanceReason: {
    presence: {
      allowEmpty: false,
      message: 'Please enter the reason assistance is required.',
    },
  },
};

const CANCER_RULES = {
  previousCancer: {
    presence: {
      allowEmpty: false,
      message: 'Please select an option.',
    },
  },
  otherRadiotherapy: {
    presence: {
      allowEmpty: false,
      message: 'Please select an option.',
    },
  },
  familyHistory: {
    presence: {
      allowEmpty: false,
      message: 'Please select an option.',
    },
  },
  familyHistoryAnswer: {
    presence: {
      allowEmpty: false,
      message: 'Please enter your family history of cancer.',
    },
  },
  hospital: {
    presence: {
      allowEmpty: false,
      message: 'Please enter the name of the clinic/hospital.',
    },
  },
  condition: {
    presence: {
      allowEmpty: false,
      message: 'Please enter your condition name.',
    },
  },
  doctor: {
    presence: {
      allowEmpty: false,
      message: "Please enter your doctor's name.",
    },
  },
  bodyPart: {
    presence: {
      allowEmpty: false,
      message: 'Please enter your part of body that was treated.',
    },
  },
  cancerType: {
    presence: {
      allowEmpty: false,
      message: 'Please enter a type of cancer',
    },
  },
};

const OPERATIONS_RULES = {
  prevOperation: {
    presence: {
      allowEmpty: false,
      message: 'Please select an option',
    },
  },
  operationSpecify: {
    presence: {
      allowEmpty: false,
      message: 'Please specify your operation',
    },
  },
};

const ALLERGIES_RULES = {
  allergyBool: {
    presence: {
      allowEmpty: false,
      message: 'Please select an option',
    },
  },
  sensitivity: {
    presence: {
      allowEmpty: false,
      message: 'Please select your allergy/sensitivity',
    },
  },
  specify: {
    presence: {
      allowEmpty: false,
      message: 'Please provide details about your allergy/sensitivity',
    },
  },
  area: {
    presence: {
      allowEmpty: false,
      message: 'Please specify your affected area',
    },
  },
};

const GENERIC_RULES = {
  treatmentDateSlash: {
    futureDateDDMMYYYY: {
      inputDateformat: 'DD/MM/YYYY',
      message: 'Please enter a valid date of treatment',
    },
  },
};

/**
 * Function that will use the global set rules to return a specific rule set.
 * @param type The type of validation that is being performed.
 * @param viewedKeys The keys that has been viewed so the rules can be fetched.
 * @returns Optional rule set that should be used to validate.
 */
export const fetchRules = (type: ValidationKeys, viewedKeys: Set<string>): { [key: string]: object } | undefined => {
  let globalRules: { [key: string]: object } | null = null;
  let specificRules: { [key: string]: object } | null = null;
  switch (type) {
    case ValidationKeys.Info:
      globalRules = INFO_RULES;
      break;
    case ValidationKeys.OtherMedicalProblems:
      globalRules = OTHER_MEDICAL_PROBLEMS_RULES;
      break;
    case ValidationKeys.MedicalOther:
      globalRules = MEDICAL_HISTORY_OTHER_RULES;
      break;
    case ValidationKeys.SocialAssessment:
      globalRules = SOCIAL_ASSESSMENT_RULES;
      break;
    case ValidationKeys.MedicalIllness:
      globalRules = MEDICAL_HISTORY_ILLNESS_RULES;
      break;
    case ValidationKeys.Medication:
      globalRules = isUs() ? MEDICATION_RULES_US : MEDICATION_RULES;
      break;
    case ValidationKeys.Operations:
      globalRules = OPERATIONS_RULES;
      break;
    case ValidationKeys.Cancer:
      globalRules = CANCER_RULES;
      break;
    case ValidationKeys.Allergies:
      globalRules = ALLERGIES_RULES;
      break;
    default:
      // Do nothing
      break;
  }

  if (globalRules !== null) {
    specificRules = {};
    for (const viewed of viewedKeys) {
      specificRules[viewed] = globalRules[viewed];
    }
    return specificRules;
  }

  // Could not find the correct global rules so reject with undefined rules.
  return undefined;
};

/**
 * Deletes any validation rules for fields that are hidden, pased on a value set not set as yes
 * which will show subfields
 * @param haPatientSummary
 * @param rules
 */
const removeHiddenFieldRules = (haPatientSummary: HAReviewSummaryItem, rules: any): any => {
  const updatedRules = { ...rules };

  // Some fields are not used for overall validation. They are simply used to retrive the validation message
  // For multiple input fields otherwise they always show errors

  // Cancer
  if (haPatientSummary.previousCancer && haPatientSummary.previousCancer !== 'YES') {
    delete updatedRules['cancerType'];
  } else if (haPatientSummary.previousCancer === 'YES') {
    let missingInput = false;
    for (let i = 0; i < haPatientSummary.cancer.length; i++) {
      if (haPatientSummary.cancer[i].cancerType === '') {
        missingInput = true;
      }
    }
    if (!missingInput) {
      delete updatedRules['cancerType'];
    }
  }
  if (haPatientSummary.otherRadiotherapy && haPatientSummary.otherRadiotherapy !== 'YES') {
    ['treatmentDate', 'condition', 'hospital', 'doctor', 'bodyPart'].forEach((field) => delete updatedRules[field]);
  } else if (haPatientSummary.otherRadiotherapy === 'YES') {
    let missingInput = false;
    for (let i = 0; i < haPatientSummary.otherRadiotherapyConditions.length; i++) {
      const otherRadiotherapyObject = haPatientSummary.otherRadiotherapyConditions[i];
      if (
        [
          otherRadiotherapyObject.condition,
          otherRadiotherapyObject.bodyPart,
          otherRadiotherapyObject.doctor,
          otherRadiotherapyObject.hospital,
        ].includes('')
      ) {
        missingInput = true;
      }
    }
    if (!missingInput) {
      ['treatmentDate', 'condition', 'hospital', 'doctor', 'bodyPart'].forEach((field) => delete updatedRules[field]);
    }
  }

  if (haPatientSummary.familyHistory && haPatientSummary.familyHistory !== 'YES') {
    delete updatedRules['familyHistoryAnswer'];
  } else if (haPatientSummary.familyHistoryAnswer !== '' && haPatientSummary.familyHistory === 'YES') {
    delete updatedRules['familyHistoryAnswer'];
  }

  // Illness
  if (haPatientSummary.highOrLowBloodPressure !== 'YES') {
    delete updatedRules['bloodPressureAnswer'];
  }
  if (haPatientSummary.internalDevice !== 'YES') {
    ['internalDeviceAnswer', 'internalDevices'].forEach((field) => delete updatedRules[field]);
  } else if (haPatientSummary.internalDevice === 'YES') {
    let notProvided = false;
    for (let i = 0; i < haPatientSummary.internalDevices.length; i++) {
      if (haPatientSummary.internalDevices[i].value === '') {
        notProvided = true;
      }
    }
    if (!notProvided) {
      ['internalDeviceAnswer', 'internalDevices'].forEach((field) => delete updatedRules[field]);
    }
  }
  if (haPatientSummary.diabetes !== 'YES') {
    delete updatedRules['diabetesAnswer'];
  } else if (haPatientSummary.diabetes === 'YES') {
    let notProvided = false;
    for (let i = 0; i < haPatientSummary.diabetesResponses.length; i++) {
      if (haPatientSummary.diabetesResponses[i].value === '') {
        notProvided = true;
      }
    }
    if (!notProvided) {
      ['diabetesAnswer', 'diabetes'].forEach((field) => delete updatedRules[field]);
    }
  }
  if (haPatientSummary.intractableInfectionOrIllness !== 'YES') {
    ['intractableInfectionOrIllnessAnswer', 'doctorClearance'].forEach((field) => delete updatedRules[field]);
  } else if (haPatientSummary.intractableInfectionOrIllness === 'YES') {
    let notProvided = false;
    for (let i = 0; i < haPatientSummary.intractableInfectionOrIllnesses.length; i++) {
      const infectionObject = haPatientSummary.intractableInfectionOrIllnesses[i];
      if (infectionObject.value === '' || infectionObject.doctorClearance === null) {
        notProvided = true;
      }
    }
    if (!notProvided) {
      ['intractableInfectionOrIllnessAnswer', 'doctorClearance'].forEach((field) => delete updatedRules[field]);
    }
  }
  if (haPatientSummary.immunosuppressiveCondition !== 'YES') {
    delete updatedRules['immunosuppressiveConditionAnswer'];
  } else if (haPatientSummary.immunosuppressiveCondition === 'YES') {
    let notProvided = false;
    for (let i = 0; i < haPatientSummary.immunosuppressiveConditions.length; i++) {
      if (haPatientSummary.immunosuppressiveConditions[i].value === '') {
        notProvided = true;
      }
    }
    if (!notProvided) {
      delete updatedRules['immunosuppressiveConditionAnswer'];
    }
  }

  //Medical history: Other
  if (haPatientSummary.activeInjury !== 'YES') {
    delete updatedRules['activeInjuryReason'];
  }
  if (haPatientSummary.inPain !== 'YES') {
    delete updatedRules['inPainReason'];
  }
  if (haPatientSummary.fallen !== 'YES') {
    delete updatedRules['fallenReason'];
  }
  if (haPatientSummary.mobilityAid !== 'YES') {
    delete updatedRules['value'];
  }
  if (haPatientSummary.mobilityAid === 'YES') {
    let missingInput = false;
    for (let i = 0; i < haPatientSummary.mobilityAids.length; i++) {
      if (haPatientSummary.mobilityAids[i].value === '') {
        missingInput = true;
      }
    }
    if (!missingInput) delete updatedRules['value'];
  }

  // Operations
  if (!haPatientSummary.prevOperation) {
    ['operationSpecify', 'operationRawDay', 'operationDate'].forEach((field) => delete updatedRules[field]);
  }
  if (haPatientSummary.prevOperation) {
    let missingInput = false;
    for (let i = 0; i < haPatientSummary.operations.length; i++) {
      const operationObject = haPatientSummary.operations[i];
      if (!operationObject.operationSpecify) {
        missingInput = true;
      }
    }
    if (!missingInput) {
      ['operationSpecify', 'operationRawDay', 'operationDate'].forEach((field) => delete updatedRules[field]);
    }
  }

  // Medications
  if (!haPatientSummary.medicationBool) {
    ['medicationName', 'dosage'].forEach((field) => delete updatedRules[field]);
  }
  if (haPatientSummary.medicationBool) {
    let missingInput = false;
    for (let i = 0; i < haPatientSummary.medications.length; i++) {
      const medicationObject = haPatientSummary.medications[i];
      if (medicationObject.dosage === '' || medicationObject.medicationName === '') missingInput = true;
    }
    if (!missingInput) {
      ['medicationName', 'dosage'].forEach((field) => delete updatedRules[field]);
    }
  }

  // Allergies
  if (!haPatientSummary.allergyBool) {
    ['sensitivity', 'specify', 'area'].forEach((field) => delete updatedRules[field]);
  } else {
    let missingInput = false;
    for (let i = 0; i < haPatientSummary.allergies.length; i++) {
      const allergyObject = haPatientSummary.allergies[i];
      if (allergyObject.sensitivity === '') missingInput = true;
    }
    if (!missingInput) {
      ['sensitivity', 'specify', 'area'].forEach((field) => delete updatedRules[field]);
    }
  }

  // Other Medical problems
  return updatedRules;
};

export const validateHealthAssesment = (haPatientSummary: HAReviewSummaryItem): any => {
  const options = { fullMessages: false };
  const rules = {
    ...INFO_RULES,
    ...OTHER_MEDICAL_PROBLEMS_RULES,
    ...MEDICAL_HISTORY_OTHER_RULES,
    ...SOCIAL_ASSESSMENT_RULES,
    ...MEDICAL_HISTORY_ILLNESS_RULES,
    ...(isUs() ? MEDICATION_RULES_US : MEDICATION_RULES),
    ...OPERATIONS_RULES,
    ...CANCER_RULES,
    ...ALLERGIES_RULES,
  };
  // Modify rules here to not inclide hidden fields
  const updatedRules = removeHiddenFieldRules(haPatientSummary, rules);

  // Get the validation results
  const afterValidate = validate(haPatientSummary, updatedRules, options);
  const validationResults = getInvalidSection(afterValidate);
  return validationResults;
};

export const getDynamicFieldErrors = (haPatientSummary: HAReviewSummaryItem, rules: any): any => {
  const options = { fullMessages: false };
  let errors: any = [];
  Object.keys(haPatientSummary).forEach((key: any): void => {
    if (Array.isArray(haPatientSummary[key]) && haPatientSummary[key].length) {
      haPatientSummary[key].forEach(function (item: any) {
        Object.keys(item).forEach((prop: any): void => {
          if (typeof rules[prop] !== 'undefined') {
            errors = validate(prop, [rules[prop]], options);
          }
        });
      });
    }
  });
  return errors;
};
export const getValidationForSingleProperty = (objectValue: any, rule: any, property: string): any => {
  const options = { fullMessages: false };
  let result = false;
  const errors = validate(objectValue, rule, options);
  if (errors && errors[property] && Array.isArray(errors[property]) && errors[property].length) {
    result = true;
  }
  return result;
};
export const getValidationRule = (property: any): any => {
  const rules: any = {
    ...INFO_RULES,
    ...OTHER_MEDICAL_PROBLEMS_RULES,
    ...MEDICAL_HISTORY_OTHER_RULES,
    ...SOCIAL_ASSESSMENT_RULES,
    ...MEDICAL_HISTORY_ILLNESS_RULES,
    ...MEDICATION_RULES,
    ...OPERATIONS_RULES,
    ...CANCER_RULES,
    ...ALLERGIES_RULES,
    ...GENERIC_RULES,
  };
  return rules[property];
};
export const getErrorsForSingleViewedField = (objectValue: any, rule: any, property: string): any => {
  const options = { fullMessages: false };
  let result = undefined;
  const errors = validate(objectValue, rule, options);
  if (errors && errors[property] && Array.isArray(errors[property]) && errors[property].length) {
    result = errors[property];
  }
  return result;
};

export const getInvalidSection = (validationResults: any): any => {
  const invalidSections = new Set<string>();

  // Check if there is a validation error in each section, if so add it to the invalid sections set
  if (validationResults) {
    Object.keys(validationResults).forEach((key: string): void => {
      if (Object.keys(INFO_RULES).includes(key)) {
        invalidSections.add(INVALID_SECTIONS.INFO);
      }
      if (Object.keys(OTHER_MEDICAL_PROBLEMS_RULES).includes(key)) {
        invalidSections.add(INVALID_SECTIONS.OTHER_MEDICAL_PROBLEMS);
      }
      if (Object.keys(MEDICAL_HISTORY_OTHER_RULES).includes(key)) {
        invalidSections.add(INVALID_SECTIONS.MEDICAL_HISTORY_OTHER);
      }
      if (Object.keys(SOCIAL_ASSESSMENT_RULES).includes(key)) {
        invalidSections.add(INVALID_SECTIONS.SOCIAL_ASSESSMENT);
      }
      if (Object.keys(MEDICAL_HISTORY_ILLNESS_RULES).includes(key)) {
        invalidSections.add(INVALID_SECTIONS.MEDICAL_HISTORY_ILLNESS);
      }
      if (Object.keys(MEDICATION_RULES).includes(key)) {
        invalidSections.add(INVALID_SECTIONS.MEDICATION);
      }
      if (Object.keys(OPERATIONS_RULES).includes(key)) {
        invalidSections.add(INVALID_SECTIONS.OPERATIONS);
      }
      if (Object.keys(CANCER_RULES).includes(key)) {
        invalidSections.add(INVALID_SECTIONS.CANCER);
      }
      if (Object.keys(ALLERGIES_RULES).includes(key)) {
        invalidSections.add(INVALID_SECTIONS.ALLERGIES);
      }
    });
  }

  // Add the invalid section set to the validation results
  if (invalidSections.size > 0) {
    const invalidSectionsArray: string[] = [];
    invalidSections.forEach((value: string) => {
      invalidSectionsArray.push(value);
    });

    validationResults.invalidSections = invalidSectionsArray;
  }

  return validationResults;
};

const defaultButtonOption = {
  presence: {
    allowEmpty: false,
    message: 'Please select an option.',
  },
};

const defaultValueOption = {
  presence: {
    allowEmpty: false,
    message: 'Please enter a value.',
  },
};

export const MOBILITY_RULES = {
  mobilityAid: defaultButtonOption,
  fallen: defaultButtonOption,
  fallenHowMany: {
    presence: {
      allowEmpty: false,
      message: 'Please enter the number of falls.',
    },
  },
  fallenInjuries: {
    presence: {
      allowEmpty: false,
      message: 'Please enter the injuries sustained.',
    },
  },
  footPain: defaultButtonOption,
  unsteady: defaultButtonOption,
  lightheaded: defaultButtonOption,
  value: defaultButtonOption,
  other: defaultValueOption,
};

export const BIOLOGICAL_RULES = {
  biologicalGender: defaultButtonOption,
};

export const MEDICATION_RULES = {
  medicationBool: {
    presence: {
      allowEmpty: false,
      message: 'Please select an option',
    },
  },
  medicationName: {
    presence: {
      allowEmpty: false,
      message: 'Please enter a value',
    },
  },
  dosage: {
    presence: {
      allowEmpty: false,
      message: 'Please enter a value',
    },
  },
};

export const MEDICATION_RULES_US = {
  medicationBool: {
    presence: {
      allowEmpty: false,
      message: 'Please select an option',
    },
  },
  pharmacyName: {
    presence: {
      allowEmpty: false,
      message: 'Please enter a value',
    },
  },
  pharmacyPhone: {
    presence: {
      allowEmpty: false,
      message: 'Please enter a value',
    },
  },
  medicationName: {
    presence: {
      allowEmpty: false,
      message: 'Please enter a value',
    },
  },
  dosage: {
    presence: {
      allowEmpty: false,
      message: 'Please enter a value',
    },
  },
  route: {
    presence: {
      allowEmpty: false,
      message: 'Please enter a value',
    },
  },
  prescribingPhysician: {
    presence: {
      allowEmpty: false,
      message: 'Please enter a value',
    },
  },
};

export const PAIN_RULES = {
  inPain: defaultButtonOption,
  painStart: defaultValueOption,
  painSeverity: defaultValueOption,
  painLocation: defaultValueOption,
  painQuality: defaultValueOption,
  painLength: defaultValueOption,
  painManagement: defaultValueOption,
  painBetter: defaultValueOption,
  painWorse: defaultValueOption,
};
