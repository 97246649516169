import {
  BOOLEAN_OPTIONS,
  BOOLEAN_TBC_OPTIONS,
  BOOLEAN_UNKNOWN_OPTIONS,
  UK_CIED_OPTIONS,
} from './TreatmentInfoPage/Interface';
import {
  AuthorizedICDTreatingDepartments,
  CareplanFilter,
  CareplanStatusFilter,
  RoDashboardTabs,
  RoleType,
} from 'op-enums';
import { Region } from 'shared-components/enums';

const REACT_APP_REGION = import.meta.env.REACT_APP_REGION;
const { CROMWELL } = AuthorizedICDTreatingDepartments;

interface BaseConfigInterface {
  ErrorPopup: {
    Generic: string;
    Practitioner: string;
  };
  DefaultValue: {
    cied: string;
    patientStatus: string;
    previousRadioTreatment: string;
    treatmentRORequired: string;
    tumourName: string;
  };
  Intake: {
    disabled: boolean;
    isProstateDisabled: boolean;
    breastMandatoryFields: string[];
    lungMandatoryFields: string[];
    headNeckMandatoryFields: string[];
    lymphomaMandatoryFields: string[];
    breastFormVersion: number;
  };
  PatientSummary: {
    isVisible: boolean;
    labsEnabled: boolean;
    RODocumentUpload: boolean;
    documentFilterField: 'description' | 'type';
  };
  OutcomesPage: {
    isVisible: boolean;
    DateFormat: string;
    DateTimeFormat: string;
  };
  OTRPage: {
    isVisible: boolean;
    DateFormat: string;
    DateTimeFormat: string;
  };
  DisplayButton: {
    displaySimButton: boolean;
  };
  DisplayModal: {
    submission: boolean;
  };
  RadiationDashboard: {
    dateTimeFormat: string;
    active: string;
    careplanDashboard: {
      careplanStatusFilters: { id: string; name: string; checked: boolean }[];
      careplanFilters: { id: string; name: string; checked: boolean }[];
      isPatientSummaryLinkEnabled: boolean;
    };
    isCancelledAppointmentsToggleEnabled: boolean;
  };
  TreatmentInfoPage: {
    mandatoryFields: string[];
    ReadyForCareDate: boolean;
    AdditionalBookingInfo: boolean;
    DelayCategory: boolean;
    MDT: boolean;
    PreviousTreatmentRetreatment: boolean;
    PreviousTreatmentRetreatmentType: boolean;
    AuthorizedICDTreatingDepartments: string[];
    Hormones: boolean;
    Brachytherapy: boolean;
    ccip: boolean;
  };
  SimulationPage: {
    ContrastRequiredChildFields?: boolean;
    SpacerGelWarning: boolean;
    imagingText: string;
    imagingCountTitle: string;
    disableExtentDefined: boolean;
    disablePositionSetup: boolean;
    immobilisationLabel: string;
  };
  PrescriptionPage: {
    MicrodosimetryTld: boolean;
    WeeklyPhysicsCheck: boolean;
    BasicDoseCalculation: boolean;
  };
  SubmissionPage: {
    PlanAimSubmissionType: string;
    CPOTEnabled: boolean;
  };
  VolumingPage: {
    UsePrioritizedOARs: boolean;
  };
  QuestionLabel: {
    CIED: string;
    RoRequired: string;
    GoldSeeds: string;
    phase: string;
  };
  QuestionOptions: {
    CIED: {
      label: string;
      value: string;
    }[];
    previousRadioTreatment: {
      label: string;
      value: string;
    }[];
  };
  QuestionValidationChildren: {
    CIED: string[];
    Staging: {
      Primary: {
        RO: string[];
        MO: string[];
      };
      Metastasis: {
        RO: string[];
        MO: string[];
      };
    };
  };
  ResetMutationVariableDefaults: {
    CIED: { [key: string]: string };
    CiedProtocolRequired: { [key: string]: string };
  };
  GetMutationVariableDefaults: {
    CIED: { [key: string]: string };
    CiedProtocolRequired: { [key: string]: string };
  };
  // Control whether DoseSiteSummary is displayed within the care plan workflow
  DoseSiteSummary: {
    enabled: boolean;
    DateFormat: string;
  };
  SubmitDiagnosisOnlyAndExit: boolean;
  CardioReferralField: boolean;
  Tooltips: {
    hasPriority: boolean;
    hasClinicalPathologicalStaging: boolean;
  };
  SpecialPhysicsConsultVisible: boolean;
  RequestTemplateChangeActive: boolean;
  DefaultTimezone: string;
  DateFormat: string;
  // The labels for referring doctors
  ReferringPhysicians: {
    oncologist: string;
    generalPractitioner: string;
    referringSurgeon: string;
    referringSurgeon2: string;
    referringSurgeon3: string;
  };
  CareplanVoidTimeRestriction: number;
  CareplanVoidDescription: string;
  EmailMaxLength: number;
  Outage: {
    link: string;
    showBanner: boolean;
    message: string;
  };
  Notes: {
    EnableSubmit: boolean;
    DateTimeFormat: string;
  };
  DefaultLocale: string;
  SupportEmail: string;
  ShowResetDemoDataMenuLink: boolean;
  MenuItems: {
    manageProtocols: boolean;
  };
}

interface AppConfigInterface {
  [region: string]: BaseConfigInterface;
}

export const AppConfig: AppConfigInterface = {
  [Region.AU]: {
    SpecialPhysicsConsultVisible: false,
    RequestTemplateChangeActive: false,
    ErrorPopup: {
      Generic:
        "We encountered an error. Please refresh the page. If the issue persists, please contact GenesisCare IT support: <a href='mailto:itsupport@genesiscare.com.au'>itsupport@genesiscare.com.au</a>",
      Practitioner:
        "We encountered an error. Please refresh the page. If the issue persists, please contact GenesisCare IT support: <a href='mailto:itsupport@genesiscare.com.au'>itsupport@genesiscare.com.au</a>",
    },
    ReferringPhysicians: {
      oncologist: 'Responsible Physician',
      generalPractitioner: 'Primary Physician (GP)',
      referringSurgeon: 'Referring Physician 1',
      referringSurgeon2: 'Referring Physician 2',
      referringSurgeon3: 'Referring Physician 3',
    },
    DefaultValue: {
      cied: '',
      patientStatus: 'unknown',
      previousRadioTreatment: '',
      treatmentRORequired: '',
      tumourName: 'tumour ',
    },
    Intake: {
      disabled: false,
      isProstateDisabled: false,
      breastMandatoryFields: ['grade', 'er', 'pgr', 'her2'],
      lungMandatoryFields: ['weightLoss', 'smokingHistory', 'egfr', 'alk', 'pdl1'],
      headNeckMandatoryFields: ['P16', 'smokingHistory'],
      lymphomaMandatoryFields: ['stage', 'aOrBSymptoms', 'extranodal', 'bulkDisease'],
      breastFormVersion: 2,
    },
    PatientSummary: {
      isVisible: true,
      labsEnabled: true,
      RODocumentUpload: false,
      documentFilterField: 'description',
    },
    OutcomesPage: {
      isVisible: true,
      DateFormat: 'DD-MMM-YYYY',
      DateTimeFormat: 'DD MMM YYYY h:mma',
    },
    OTRPage: {
      isVisible: false,
      DateFormat: 'DD-MMM-YYYY',
      DateTimeFormat: 'DD MMM YYYY h:mma',
    },
    DisplayButton: {
      displaySimButton: true,
    },
    DisplayModal: {
      submission: false,
    },
    TreatmentInfoPage: {
      mandatoryFields: ['intent', 'treatingDepartment', 'readyForCareDate', 'cied', 'previousRadioTreatment'],
      ReadyForCareDate: true,
      AdditionalBookingInfo: true,
      DelayCategory: true,
      MDT: false,
      PreviousTreatmentRetreatment: false,
      PreviousTreatmentRetreatmentType: false,
      AuthorizedICDTreatingDepartments: [],
      Hormones: true,
      Brachytherapy: true,
      ccip: false,
    },
    SimulationPage: {
      ContrastRequiredChildFields: true,
      SpacerGelWarning: false,
      imagingText: 'Add imaging request',
      imagingCountTitle: 'Imaging',
      disableExtentDefined: true,
      disablePositionSetup: true,
      immobilisationLabel: 'Immobilisation',
    },
    PrescriptionPage: {
      MicrodosimetryTld: false,
      WeeklyPhysicsCheck: false,
      BasicDoseCalculation: false,
    },
    SubmissionPage: {
      PlanAimSubmissionType: 'Plan Aim',
      CPOTEnabled: true,
    },
    VolumingPage: {
      UsePrioritizedOARs: false,
    },
    RadiationDashboard: {
      dateTimeFormat: 'DD/MM/YYYY hh:mm A',
      active: RoDashboardTabs.APPOINTMENTS,
      careplanDashboard: {
        careplanStatusFilters: [
          { id: CareplanStatusFilter.DRAFT, name: CareplanStatusFilter.DRAFT, checked: true },
          { id: CareplanStatusFilter.SIMULATION, name: CareplanStatusFilter.SIMULATION, checked: true },
          { id: CareplanStatusFilter.PLAN_AIM, name: CareplanStatusFilter.PLAN_AIM, checked: true },
          { id: CareplanStatusFilter.PRESCRIPTION, name: CareplanStatusFilter.PRESCRIPTION, checked: true },
          { id: CareplanStatusFilter.CPOT, name: CareplanStatusFilter.CPOT, checked: true },
        ],
        careplanFilters: [{ id: CareplanFilter.UNSUBMITTED, name: CareplanFilter.UNSUBMITTED, checked: false }],
        isPatientSummaryLinkEnabled: true,
      },
      isCancelledAppointmentsToggleEnabled: false,
    },
    QuestionLabel: {
      CIED: 'Cardiac Implantable Electronic Device (CIED)',
      RoRequired: 'RO Required',
      GoldSeeds: 'Gold Seeds',
      phase: 'Phase',
    },
    QuestionOptions: {
      CIED: BOOLEAN_TBC_OPTIONS,
      previousRadioTreatment: BOOLEAN_OPTIONS,
    },
    QuestionValidationChildren: {
      CIED: ['ciedProtocolRequired', 'preTreatmentCiedCheckRequired'],
      Staging: {
        Primary: {
          RO: ['laterality', 'diagnosisDate'],
          MO: ['laterality'],
        },
        Metastasis: {
          RO: ['laterality', 'diagnosisDate'],
          MO: [],
        },
      },
    },
    ResetMutationVariableDefaults: {
      CIED: {
        ciedProtocolRequired: '',
        preTreatmentCiedCheckRequired: '',
        referralToGcCardiologist: '',
        otherCiedInstructions: '',
      },
      CiedProtocolRequired: {
        preTreatmentCiedCheckRequired: '',
        referralToGcCardiologist: '',
        otherCiedInstructions: '',
      },
    },
    GetMutationVariableDefaults: {
      CIED: {
        ciedProtocolRequired: 'yes',
        preTreatmentCiedCheckRequired: 'yes',
        referralToGcCardiologist: 'no',
        otherCiedInstructions: '',
      },
      CiedProtocolRequired: {
        preTreatmentCiedCheckRequired: 'yes',
        referralToGcCardiologist: 'no',
        otherCiedInstructions: '',
      },
    },
    DoseSiteSummary: {
      enabled: true,
      DateFormat: 'DD/MM/YYYY',
    },
    SubmitDiagnosisOnlyAndExit: false,
    CardioReferralField: true,
    Tooltips: {
      hasPriority: false,
      hasClinicalPathologicalStaging: false,
    },
    DefaultTimezone: 'Australia/Sydney',
    DateFormat: 'DD MM YYYY',
    CareplanVoidTimeRestriction: 168,
    CareplanVoidDescription: 'Documentation will be voided and a clinic note will be added into Mosaiq.',
    EmailMaxLength: 40,
    Outage: {
      link: 'https://genesiscare.sharepoint.com/sites/Oncologyportal/SitePages/Horizon-Offline-weekend.aspx',
      showBanner: true,
      message: 'Horizon will be offline from 9 PM Friday, March 15th to Sunday, March 17th, 2024, AEST.',
    },
    Notes: {
      EnableSubmit: true,
      DateTimeFormat: 'DD/MM/YYYY hh:mm A',
    },
    DefaultLocale: 'en-au',
    SupportEmail: 'itsupport@genesiscare.com.au',
    ShowResetDemoDataMenuLink: false,
    MenuItems: {
      manageProtocols: false,
    },
  },
  [Region.UK]: {
    SpecialPhysicsConsultVisible: false,
    RequestTemplateChangeActive: false,
    ErrorPopup: {
      Generic:
        "We encountered an error. Please refresh the page. If the issue persists, please contact GenesisCare IT support: <a href='mailto:genesiscare.itservicedesk@genesiscare.co.uk'>genesiscare.itservicedesk@genesiscare.co.uk</a>",
      Practitioner:
        "Your account setup appears to be incomplete or missing information. Please contact GenesisCare IT support: <a href='mailto:genesiscare.itservicedesk@genesiscare.co.uk'>genesiscare.itservicedesk@genesiscare.co.uk</a>",
    },
    ReferringPhysicians: {
      oncologist: 'Responsible Physician',
      generalPractitioner: 'Primary Physician (GP)',
      referringSurgeon: 'Referring Clinician',
      referringSurgeon2: 'Referring Other',
      referringSurgeon3: 'Referring Other',
    },
    DefaultValue: {
      cied: '',
      patientStatus: 'outPatient',
      previousRadioTreatment: '',
      treatmentRORequired: '',
      tumourName: 'tumour ',
    },
    Intake: {
      disabled: true,
      isProstateDisabled: true,
      breastMandatoryFields: ['grade', 'er', 'pgr', 'her2'],
      lungMandatoryFields: ['weightLoss', 'smokingHistory', 'egfr', 'alk', 'pdl1'],
      headNeckMandatoryFields: ['P16', 'smokingHistory'],
      lymphomaMandatoryFields: ['stage', 'aOrBSymptoms', 'extranodal', 'bulkDisease'],
      breastFormVersion: 1,
    },
    PatientSummary: {
      isVisible: true,
      labsEnabled: false,
      RODocumentUpload: true,
      documentFilterField: 'description',
    },
    OutcomesPage: {
      isVisible: false,
      DateFormat: 'DD-MMM-YYYY',
      DateTimeFormat: 'DD MMM YYYY h:mma',
    },
    OTRPage: {
      isVisible: false,
      DateFormat: 'DD-MMM-YYYY',
      DateTimeFormat: 'DD MMM YYYY h:mma',
    },
    DisplayButton: {
      displaySimButton: false,
    },
    DisplayModal: {
      submission: true,
    },
    SimulationPage: {
      SpacerGelWarning: true,
      imagingText: 'Add imaging request',
      imagingCountTitle: 'Imaging',
      disableExtentDefined: true,
      disablePositionSetup: true,
      immobilisationLabel: 'Immobilisation',
    },
    TreatmentInfoPage: {
      mandatoryFields: ['intent', 'treatingDepartment', 'readyForCareDate', 'cied', 'previousRadioTreatment'],
      ReadyForCareDate: true,
      AdditionalBookingInfo: true,
      MDT: true,
      DelayCategory: true,
      PreviousTreatmentRetreatment: true,
      PreviousTreatmentRetreatmentType: true,
      AuthorizedICDTreatingDepartments: [CROMWELL],
      Hormones: true,
      Brachytherapy: true,
      ccip: false,
    },
    PrescriptionPage: {
      MicrodosimetryTld: false,
      WeeklyPhysicsCheck: false,
      BasicDoseCalculation: false,
    },
    SubmissionPage: {
      PlanAimSubmissionType: 'Plan Aim',
      CPOTEnabled: true,
    },
    VolumingPage: {
      UsePrioritizedOARs: false,
    },
    RadiationDashboard: {
      dateTimeFormat: 'DD/MM/YYYY hh:mm A',
      active: RoDashboardTabs.CLINIC_LIST,
      careplanDashboard: {
        careplanStatusFilters: [
          { id: CareplanStatusFilter.DRAFT, name: CareplanStatusFilter.DRAFT, checked: true },
          { id: CareplanStatusFilter.PLAN_AIM, name: CareplanStatusFilter.PLAN_AIM, checked: true },
          { id: CareplanStatusFilter.PRESCRIPTION, name: CareplanStatusFilter.PRESCRIPTION, checked: true },
        ],
        careplanFilters: [{ id: CareplanFilter.UNSUBMITTED, name: CareplanFilter.UNSUBMITTED, checked: false }],
        isPatientSummaryLinkEnabled: true,
      },
      isCancelledAppointmentsToggleEnabled: false,
    },
    QuestionLabel: {
      CIED: 'Cardiac Device',
      RoRequired: 'Clinical Oncologist required',
      GoldSeeds: 'Fiducials',
      phase: 'Phase',
    },
    QuestionOptions: {
      CIED: UK_CIED_OPTIONS,
      previousRadioTreatment: BOOLEAN_OPTIONS,
    },
    QuestionValidationChildren: {
      CIED: ['ciedPatientDependancy', 'ciedLinacRequired'],
      Staging: {
        Primary: {
          RO: ['laterality', 'diagnosisDate'],
          MO: ['laterality'],
        },
        Metastasis: {
          RO: ['laterality', 'diagnosisDate'],
          MO: [],
        },
      },
    },
    ResetMutationVariableDefaults: {
      CIED: {
        // Yes, there is a spelling mistake with Dependency
        ciedPatientDependancy: '',
        ciedLinacRequired: '',
      },
      CiedProtocolRequired: {},
    },
    GetMutationVariableDefaults: {
      CIED: {
        // Yes, there is a spelling mistake with Dependency
        ciedPatientDependancy: '',
        ciedLinacRequired: '',
      },
      CiedProtocolRequired: {},
    },
    DoseSiteSummary: {
      enabled: false,
      DateFormat: 'DD/MM/YYYY',
    },
    SubmitDiagnosisOnlyAndExit: false,
    CardioReferralField: false,
    Tooltips: {
      hasPriority: false,
      hasClinicalPathologicalStaging: false,
    },
    DefaultTimezone: 'Europe/London',
    DateFormat: 'DD MM YYYY',
    CareplanVoidTimeRestriction: 8,
    CareplanVoidDescription: 'Documentation will be voided and a clinic note will be added into Mosaiq.',
    EmailMaxLength: 40,
    Outage: {
      link: 'https://genesiscare.sharepoint.com/sites/HorizonPortalUK/SitePages/Lumonus-Transition.aspx',
      showBanner: true,
      message: 'Horizon will be offline from 7PM Friday, March 15th to Sunday, March 17th, 2024, GMT',
    },
    Notes: {
      EnableSubmit: true,
      DateTimeFormat: 'DD/MM/YYYY hh:mm A',
    },
    DefaultLocale: 'en-gb',
    SupportEmail: 'itsupport@genesiscare.com.au',
    ShowResetDemoDataMenuLink: false,
    MenuItems: {
      manageProtocols: false,
    },
  },
  [Region.US]: {
    SpecialPhysicsConsultVisible: true,
    RequestTemplateChangeActive: true,
    ErrorPopup: {
      Generic:
        "We encountered an error. Please refresh the page. If the issue persists, please contact support: <a href='mailto:support-us@lumonus.com'>support-us@lumonus.com</a>",
      Practitioner:
        "We encountered an error. Please refresh the page. If the issue persists, please contact support: <a href='mailto:support-us@lumonus.com'>support-us@lumonus.com</a>",
    },
    ReferringPhysicians: {
      oncologist: 'Responsible Physician',
      generalPractitioner: 'Primary Physician (GP)',
      referringSurgeon: 'Referring Physician 1',
      referringSurgeon2: 'Referring Physician 2',
      referringSurgeon3: 'Referring Physician 3',
    },
    DefaultValue: {
      cied: 'tbc',
      patientStatus: 'unknown',
      previousRadioTreatment: 'Unknown',
      treatmentRORequired: 'true',
      tumourName: 'tumor',
    },
    Intake: {
      disabled: false,
      isProstateDisabled: false,
      breastMandatoryFields: [],
      lungMandatoryFields: [],
      headNeckMandatoryFields: [],
      lymphomaMandatoryFields: [],
      breastFormVersion: 2,
    },
    PatientSummary: {
      isVisible: true,
      labsEnabled: false,
      RODocumentUpload: false,
      documentFilterField: 'type',
    },
    OutcomesPage: {
      isVisible: false,
      DateFormat: 'MMM-DD-YYYY',
      DateTimeFormat: 'MMM DD YYYY h:mma',
    },
    OTRPage: {
      isVisible: true,
      DateFormat: 'MMM-DD-YYYY',
      DateTimeFormat: 'MMM DD YYYY h:mma',
    },
    DisplayButton: {
      displaySimButton: true,
    },
    DisplayModal: {
      submission: false,
    },
    TreatmentInfoPage: {
      mandatoryFields: ['intent', 'treatingDepartment', 'cied', 'previousRadioTreatment'],
      ReadyForCareDate: false,
      AdditionalBookingInfo: false,
      DelayCategory: false,
      MDT: false,
      PreviousTreatmentRetreatment: false,
      PreviousTreatmentRetreatmentType: false,
      AuthorizedICDTreatingDepartments: [],
      Hormones: false,
      Brachytherapy: false,
      ccip: true,
    },
    SimulationPage: {
      SpacerGelWarning: false,
      imagingText: 'Add outside imaging request',
      imagingCountTitle: 'Outside imaging',
      disableExtentDefined: false,
      disablePositionSetup: false,
      immobilisationLabel: 'Immobilization',
    },
    PrescriptionPage: {
      MicrodosimetryTld: true,
      WeeklyPhysicsCheck: true,
      BasicDoseCalculation: true,
    },
    SubmissionPage: {
      PlanAimSubmissionType: 'Prescription Directive',
      CPOTEnabled: false,
    },
    VolumingPage: {
      UsePrioritizedOARs: true,
    },
    RadiationDashboard: {
      dateTimeFormat: 'MM/DD/YYYY hh:mm A',
      active: RoDashboardTabs.APPOINTMENTS,
      careplanDashboard: {
        careplanStatusFilters: [
          { id: CareplanStatusFilter.DRAFT, name: CareplanStatusFilter.DRAFT, checked: true },
          { id: CareplanStatusFilter.SIMULATION, name: CareplanStatusFilter.SIMULATION, checked: true },
          {
            id: CareplanStatusFilter.PRESCRIPTION_DIRECTIVE,
            name: CareplanStatusFilter.PRESCRIPTION_DIRECTIVE,
            checked: true,
          },
          { id: CareplanStatusFilter.PRESCRIPTION, name: CareplanStatusFilter.PRESCRIPTION, checked: true },
        ],
        careplanFilters: [{ id: CareplanFilter.UNSUBMITTED, name: CareplanFilter.UNSUBMITTED, checked: false }],
        isPatientSummaryLinkEnabled: true,
      },
      isCancelledAppointmentsToggleEnabled: true,
    },
    QuestionLabel: {
      CIED: 'Cardiac Implantable Electronic Device (CIED)',
      RoRequired: 'Primary Physician required',
      GoldSeeds: 'Fiducials',
      phase: 'Sequence',
    },
    QuestionOptions: {
      CIED: BOOLEAN_TBC_OPTIONS,
      previousRadioTreatment: BOOLEAN_UNKNOWN_OPTIONS,
    },
    QuestionValidationChildren: {
      CIED: ['ciedProtocolRequired', 'preTreatmentCiedCheckRequired'],
      Staging: {
        Primary: {
          RO: ['laterality'],
          MO: ['laterality'],
        },
        Metastasis: {
          RO: ['laterality'],
          MO: [],
        },
      },
    },
    ResetMutationVariableDefaults: {
      CIED: {
        ciedProtocolRequired: '',
        preTreatmentCiedCheckRequired: '',
        referralToGcCardiologist: '',
        otherCiedInstructions: '',
      },
      CiedProtocolRequired: {
        preTreatmentCiedCheckRequired: '',
        referralToGcCardiologist: '',
        otherCiedInstructions: '',
      },
    },
    GetMutationVariableDefaults: {
      CIED: {
        ciedProtocolRequired: 'yes',
        preTreatmentCiedCheckRequired: 'yes',
        referralToGcCardiologist: 'no',
        otherCiedInstructions: '',
      },
      CiedProtocolRequired: {
        preTreatmentCiedCheckRequired: 'yes',
        referralToGcCardiologist: 'no',
        otherCiedInstructions: '',
      },
    },
    DoseSiteSummary: {
      enabled: true,
      DateFormat: 'MM/DD/YYYY',
    },
    SubmitDiagnosisOnlyAndExit: false,
    CardioReferralField: false,
    Tooltips: {
      hasPriority: true,
      hasClinicalPathologicalStaging: true,
    },
    DefaultTimezone: 'US/Eastern',
    DateFormat: 'MM DD YYYY',
    CareplanVoidTimeRestriction: 48,
    CareplanVoidDescription: 'Documentation will be voided in your OIS.',
    EmailMaxLength: 40,
    Outage: {
      showBanner: false,
      link: '',
      message: '',
    },
    Notes: {
      EnableSubmit: false,
      DateTimeFormat: 'MM/DD/YYYY hh:mm A',
    },
    DefaultLocale: 'en',
    SupportEmail: 'support-us@lumonus.com',
    ShowResetDemoDataMenuLink: false,
    MenuItems: {
      manageProtocols: true,
    },
  },
  [Region.DEMO]: {
    SpecialPhysicsConsultVisible: true,
    RequestTemplateChangeActive: true,
    ErrorPopup: {
      Generic:
        "We encountered an error. Please refresh the page. If the issue persists, please contact Lumonus support: <a href='mailto:support@lumonus.com'>support@lumonus.com</a>",
      Practitioner:
        "We encountered an error. Please refresh the page. If the issue persists, please contact Lumonus support: <a href='mailto:support@lumonus.com'>support@lumonus.com</a>",
    },
    ReferringPhysicians: {
      oncologist: 'Responsible Physician',
      generalPractitioner: 'Primary Physician (GP)',
      referringSurgeon: 'Referring Physician 1',
      referringSurgeon2: 'Referring Physician 2',
      referringSurgeon3: 'Referring Physician 3',
    },
    DefaultValue: {
      cied: '',
      patientStatus: 'unknown',
      previousRadioTreatment: '',
      treatmentRORequired: '',
      tumourName: 'tumor',
    },
    Intake: {
      disabled: false,
      isProstateDisabled: false,
      breastMandatoryFields: ['grade', 'er', 'pgr', 'her2'],
      lungMandatoryFields: ['weightLoss', 'smokingHistory', 'egfr', 'alk', 'pdl1'],
      headNeckMandatoryFields: ['P16', 'smokingHistory'],
      lymphomaMandatoryFields: ['stage', 'aOrBSymptoms', 'extranodal', 'bulkDisease'],
      breastFormVersion: 2,
    },
    PatientSummary: {
      isVisible: true,
      labsEnabled: false,
      RODocumentUpload: false,
      documentFilterField: 'description',
    },
    OutcomesPage: {
      isVisible: true,
      DateFormat: 'DD-MMM-YYYY',
      DateTimeFormat: 'DD MMM YYYY h:mma',
    },
    OTRPage: {
      isVisible: true,
      DateFormat: 'DD-MMM-YYYY',
      DateTimeFormat: 'DD MMM YYYY h:mma',
    },
    DisplayButton: {
      displaySimButton: true,
    },
    DisplayModal: {
      submission: false,
    },
    TreatmentInfoPage: {
      mandatoryFields: ['intent', 'treatingDepartment', 'cied', 'previousRadioTreatment'],
      ReadyForCareDate: false,
      AdditionalBookingInfo: false,
      DelayCategory: false,
      MDT: false,
      PreviousTreatmentRetreatment: false,
      PreviousTreatmentRetreatmentType: false,
      AuthorizedICDTreatingDepartments: [],
      Hormones: false,
      Brachytherapy: false,
      ccip: true,
    },
    SimulationPage: {
      ContrastRequiredChildFields: true,
      SpacerGelWarning: false,
      imagingText: 'Add outside imaging request',
      imagingCountTitle: 'Outside imaging',
      disableExtentDefined: false,
      disablePositionSetup: false,
      immobilisationLabel: 'Immobilization',
    },
    PrescriptionPage: {
      MicrodosimetryTld: true,
      WeeklyPhysicsCheck: true,
      BasicDoseCalculation: true,
    },
    SubmissionPage: {
      PlanAimSubmissionType: 'Plan Aim',
      CPOTEnabled: false,
    },
    VolumingPage: {
      UsePrioritizedOARs: true,
    },
    RadiationDashboard: {
      dateTimeFormat: 'MM/DD/YYYY hh:mm A',
      active: RoDashboardTabs.APPOINTMENTS,
      careplanDashboard: {
        careplanStatusFilters: [
          { id: CareplanStatusFilter.DRAFT, name: CareplanStatusFilter.DRAFT, checked: true },
          { id: CareplanStatusFilter.SIMULATION, name: CareplanStatusFilter.SIMULATION, checked: true },
          { id: CareplanStatusFilter.PLAN_AIM, name: CareplanStatusFilter.PLAN_AIM, checked: true },
          { id: CareplanStatusFilter.PRESCRIPTION, name: CareplanStatusFilter.PRESCRIPTION, checked: true },
          { id: CareplanStatusFilter.CPOT, name: CareplanStatusFilter.CPOT, checked: true },
        ],
        careplanFilters: [{ id: CareplanFilter.UNSUBMITTED, name: CareplanFilter.UNSUBMITTED, checked: false }],
        isPatientSummaryLinkEnabled: true,
      },
      isCancelledAppointmentsToggleEnabled: false,
    },
    QuestionLabel: {
      CIED: 'Cardiac Implantable Electronic Device (CIED)',
      RoRequired: 'RO Required',
      GoldSeeds: 'Gold Seeds',
      phase: 'Sequence',
    },
    QuestionOptions: {
      CIED: BOOLEAN_TBC_OPTIONS,
      previousRadioTreatment: BOOLEAN_OPTIONS,
    },
    QuestionValidationChildren: {
      CIED: ['ciedProtocolRequired', 'preTreatmentCiedCheckRequired'],
      Staging: {
        Primary: {
          RO: ['laterality', 'diagnosisDate'],
          MO: ['laterality'],
        },
        Metastasis: {
          RO: ['laterality', 'diagnosisDate'],
          MO: [],
        },
      },
    },
    ResetMutationVariableDefaults: {
      CIED: {
        ciedProtocolRequired: '',
        preTreatmentCiedCheckRequired: '',
        referralToGcCardiologist: '',
        otherCiedInstructions: '',
      },
      CiedProtocolRequired: {
        preTreatmentCiedCheckRequired: '',
        referralToGcCardiologist: '',
        otherCiedInstructions: '',
      },
    },
    GetMutationVariableDefaults: {
      CIED: {
        ciedProtocolRequired: 'yes',
        preTreatmentCiedCheckRequired: 'yes',
        referralToGcCardiologist: 'no',
        otherCiedInstructions: '',
      },
      CiedProtocolRequired: {
        preTreatmentCiedCheckRequired: 'yes',
        referralToGcCardiologist: 'no',
        otherCiedInstructions: '',
      },
    },
    DoseSiteSummary: {
      enabled: true,
      DateFormat: 'MM/DD/YYYY',
    },
    SubmitDiagnosisOnlyAndExit: false,
    CardioReferralField: false,
    Tooltips: {
      hasPriority: false,
      hasClinicalPathologicalStaging: false,
    },
    DefaultTimezone: 'America/New_York',
    DateFormat: 'MM DD YYYY',
    CareplanVoidTimeRestriction: 168,
    CareplanVoidDescription: 'Documentation will be voided in your OIS.',
    EmailMaxLength: 40,
    Outage: {
      link: 'https://genesiscare.sharepoint.com/sites/Oncologyportal/SitePages/Horizon-Offline-weekend.aspx',
      showBanner: true,
      message: 'Horizon will be offline from 9 PM Friday, March 15th to Sunday, March 17th, 2024, AEST.',
    },
    Notes: {
      EnableSubmit: true,
      DateTimeFormat: 'MM/DD/YYYY hh:mm A',
    },
    DefaultLocale: 'en',
    SupportEmail: 'support-us@lumonus.com',
    ShowResetDemoDataMenuLink: true,
    MenuItems: {
      manageProtocols: true,
    },
  },
};
export const CurrentAppConfig: BaseConfigInterface = AppConfig[REACT_APP_REGION || Region.AU];
