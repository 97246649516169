// eslint-disable-next-line no-use-before-define
import { useMutation, useQuery } from '@apollo/client';
import React from 'react';
import { useParams } from 'react-router-dom';
import { FormProps } from '../interfaces';
import FeedbackForm from './FeedbackForm';
import { FEEDBACK_QUERY, UPDATE_FEEDBACK } from './FeedbackQueries';

const Feedback = (props: FormProps): JSX.Element => {
  const { exitForm } = props;
  const { patientId: id } = useParams<{ patientId: string }>();

  const [updateRegistration] = useMutation(UPDATE_FEEDBACK);
  const { data, error } = useQuery(FEEDBACK_QUERY, { variables: { id } });

  if (error || !data) return <></>;

  const { heardAboutRefData, internetForResearchRefData, patient, user } = data;

  const updateField = (field: string, value: string | number | boolean) => {
    updateRegistration({ variables: { id, [field]: value } });
  };

  return (
    <FeedbackForm
      refData={{
        heardAboutRefData,
        internetForResearchRefData,
      }}
      patient={patient}
      updateField={updateField}
      handleShowSaveExitConfirm={() => exitForm(true)}
      isPso={user.isPso}
    />
  );
};

export default Feedback;
