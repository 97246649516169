export const ASSESSMENT_AND_REVIEW = {
  ASSESSMENT_TITLE: 'We greatly appreciate your cooperation and your time to fill out this form',
  HAVE_YOU_BEEN_DIAGNOSED: 'Have you been diagnosed with COVID-19?',
  TESTED_AND_WAITING: 'Or have you been tested for COVID-19 and are awaiting results?',
  ADVISED_TO_QUARANTINE: 'Have you been advised to quarantine in regard to COVID-19?',
  CLOSE_CONTACT_WITH_QUARANTINE:
    'Have you had close contact with a person who is in quarantine or is a confirmed case?',
  TRAVELLED_TO_HOTSPOT: 'Have you or any household member visited an exposure site in the last 14 days?',
  EXPERIENCING_THE_FOLLOWING:
    'Are you currently experiencing (or have recently experienced) one or more of the following:\n- A fever 37.5°C or above\n- Symptoms such as night sweats, chills, cough, sore throat, shortness of breath, loss of smell or taste',
  VALID_DATE: 'Please enter a valid date.',
  SELECT_OPTION: 'Please select an option.',
  COVID_SCREENING: 'Covid-19 Screening',
  DATE_OF_SYMPTOMS: 'Date of symptom/s onset?',
};

export const INFO = {
  TITLE: 'We are committed to the health and safety of all our patients and staff',
  PARA_1:
    'We are asking all patients to complete COVID-19 related questions to ensure we can keep everyone safe when attending our clinic.',
  PARA_2: 'You will be asked to repeat this questionnaire after a break from treatment or between treatment cycles.',
  PARA_3: 'The answers will not impact our care, it will assist us in keeping all patients, visitors and staff safe.',
  VISITORS_PARA_1:
    'For the safety of all patients and staff, visitor numbers are to be minimised to ensure appropriate social distancing within the centre.',
  VISITORS_PARA_2:
    'In areas where there are COVID-19 restrictions, visitors are limited to only those required for patient safety and wellbeing – if you are unsure if this applies to you, please contact your Centre for more information.',
};
