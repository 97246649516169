import React, { useContext } from 'react';
import { Field, useFormikContext, FormikProps } from 'formik';

import { MandatoryPatient, HAIssueItem } from 'op-interfaces';
import { InfoCard } from 'op-components';
import { FormRow, HelperMessage } from 'shared-components/components';
import { yesNoOptions } from 'op-utils';
import { SelectFieldGroup, ToggleButtonGroupField } from 'shared-components/components/FormikComponents';
import { AustralianStates } from 'shared-components/enums';
import ListData from 'shared-components/interfaces/ListData';

import { getLastUpdateUser, choiceToStringOptions, getRecentlyCreated } from '../../helpers';
import { sharedContent } from '../../shared/constants';
import { LastUpdateUserDetails } from '../../shared/interfaces';
import { displayTextFieldOptionsForOther, preCtChartCheckContent, noDataTableRow } from '../constants';
import FormContext from '../contexts';
import { HealthAssessmentData, PreCtChartCheckFormValues } from '../interfaces/PreCtChartCheckInterfaces';

interface Props {
  assessmentId: string;
  patient: MandatoryPatient;
  updateAssessment: any;
  healthAssessmentData: HealthAssessmentData;
  lastUpdateUserData: LastUpdateUserDetails[];
}
interface TableData {
  data: any;
  title: string;
  refData: ListData[];
}

const InfectiousRisk = ({
  assessmentId,
  patient,
  updateAssessment,
  lastUpdateUserData,
  healthAssessmentData,
}: Props): JSX.Element => {
  const { infectiousRisk } = preCtChartCheckContent;
  const { fields, options, infoHelpers, actionLabels, tableTitles } = infectiousRisk;
  const { doctorClearanceTitle, mroInfectionTitle } = tableTitles;
  const { hasInfectiousDisease, hasMroInfection, outsideWaLastTwelveMonths, infectiousDisease, mroInfection } = fields;
  const { infectiousDiseaseOptions, mroInfectionOptions } = options;
  const infectiousRiskAlertMessage = infoHelpers.infectiousRiskAlert;
  const infectiousRiskQCLMessage = infoHelpers.infectiousRiskQCL;

  let intractableInfectionOrIllnesses;
  let intractableInfectionOrIllnessesRefData;
  if (healthAssessmentData.healthAssessment) {
    intractableInfectionOrIllnesses = healthAssessmentData.healthAssessment.intractableInfectionOrIllnesses;
    intractableInfectionOrIllnessesRefData = healthAssessmentData.intractableInfectionOrIllnessesRefData;
  }

  const { setFieldValue, setFieldTouched, values }: FormikProps<PreCtChartCheckFormValues> = useFormikContext();
  const formContext = useContext(FormContext);
  const {
    createInfectiousDisease,
    createMroInfection,
    deleteInfectiousDisease,
    deleteMroInfection,
    updateInfectiousDisease,
    updateMroInfection,
  } = formContext.mutations;
  const { infectiousDisease: infectiousDiseaseValues, mroInfection: mroInfectionValues } = values;
  const createInfectiousDiseaseMutation = async () => {
    const { data } = await createInfectiousDisease({
      variables: { assessmentId, baseValue: '', otherValue: '' },
    });
    const updatedInfectiousDiseases = data.createInfectiousDisease.preCtChartCheck.infectiousDisease;
    return getRecentlyCreated(updatedInfectiousDiseases);
  };

  const createMroInfectionMutation = async () => {
    const { data } = await createMroInfection({
      variables: { assessmentId, baseValue: '', otherValue: '' },
    });
    const updatedMroInfections = data.createMroInfection.preCtChartCheck.mroInfection;
    return getRecentlyCreated(updatedMroInfections);
  };

  const deleteInfectiousDiseaseMutation = (id: string) => {
    deleteInfectiousDisease({ variables: { id } });
  };

  const deleteMroInfectionMutation = (id: string) => {
    deleteMroInfection({ variables: { id } });
  };

  const handleChangeInfectiousDisease = (value: boolean) => {
    if (value === true && values.infectiousDisease.length === 0) {
      createInfectiousDisease({
        variables: { assessmentId, baseValue: '', otherValue: '' },
      }).then(({ data }: { data: any }) => {
        const firstInfectiousDisease = data.createInfectiousDisease.preCtChartCheck.infectiousDisease[0];
        setFieldValue('infectiousDisease', [firstInfectiousDisease]);
      });
    }
    if (value === false && values.infectiousDisease.length > 0) {
      values.infectiousDisease.forEach((item: any) => {
        deleteInfectiousDisease({ variables: { id: item.id } });
      });
      setFieldValue('infectiousDisease', []);
      setFieldTouched('infectiousDisease', undefined);
    }
  };

  const handleChangeMroInfection = (value: boolean) => {
    if (value === true && values.mroInfection.length === 0) {
      createMroInfection({
        variables: { assessmentId, baseValue: '', otherValue: '' },
      }).then(({ data }: { data: any }) => {
        const firstMroInfection = data.createMroInfection.preCtChartCheck.mroInfection[0];
        setFieldValue('mroInfection', [firstMroInfection]);
      });
    }
    if (value === false && values.mroInfection.length > 0) {
      values.mroInfection.forEach((item: any) => {
        deleteMroInfection({ variables: { id: item.id } });
      });
      setFieldValue('mroInfection', []);
      setFieldTouched('mroInfection', undefined);
    }
  };

  const mapHaData = ({ data, title, refData }: TableData) => {
    return data
      .filter((record: HAIssueItem) => record.value !== '')
      .map((issue: HAIssueItem, index: number) => {
        const value = issue.other || refData.find((ref: ListData) => ref.id === issue.value)?.name;

        const updatedTitle = `${title} ${index + 1}`;
        const doctorClearance = issue.hasOwnProperty('doctorClearance');
        const doctorClearanceRow = {
          title: doctorClearanceTitle,
          // @ts-ignore
          value: choiceToStringOptions[String(issue.doctorClearance)],
        };
        const tableRow = {
          title: updatedTitle,
          value,
        };
        return doctorClearance ? [tableRow, doctorClearanceRow] : tableRow;
      });
  };

  let mroInfectionTableData;
  if (intractableInfectionOrIllnesses && intractableInfectionOrIllnessesRefData) {
    const hasValidValue = intractableInfectionOrIllnesses.some((obj) => obj.value || obj.other);
    hasValidValue &&
      (mroInfectionTableData = mapHaData({
        data: intractableInfectionOrIllnesses,
        title: mroInfectionTitle,
        refData: intractableInfectionOrIllnessesRefData,
      }));
  }

  return (
    <>
      <FormRow fieldLabel={hasInfectiousDisease} fieldName={'hasInfectiousDisease'}>
        <Field
          name="hasInfectiousDisease"
          component={ToggleButtonGroupField}
          label={hasInfectiousDisease}
          options={yesNoOptions}
          handleMutation={updateAssessment}
          conditionalHandleChange={handleChangeInfectiousDisease}
          lastUpdateUser={getLastUpdateUser(lastUpdateUserData, 'hasInfectiousDisease')}
        />
        {values.hasInfectiousDisease === true && (
          <>
            <HelperMessage
              fieldName={'hasInfectiousDisease'}
              fieldText={infectiousRiskAlertMessage}
              helperType="info"
            />
            <HelperMessage fieldName={'hasInfectiousDisease'} fieldText={infectiousRiskQCLMessage} helperType="info" />
          </>
        )}
      </FormRow>
      {values.hasInfectiousDisease === true && (
        <SelectFieldGroup
          name="infectiousDisease"
          addButtonText={actionLabels.addInfectiousRisk}
          createMutation={createInfectiousDiseaseMutation}
          deleteMutation={deleteInfectiousDiseaseMutation}
          updateMutation={updateInfectiousDisease}
          label={infectiousDisease}
          options={infectiousDiseaseOptions}
          selectPlaceholder={sharedContent.generic.label.selectValue}
          selectFieldName="baseValue"
          textFieldName="otherValue"
          textFieldOptionsForOther={displayTextFieldOptionsForOther}
          values={infectiousDiseaseValues}
          userIconFieldName="lastUpdateUser"
          userTypeValue="nurse"
          width={'500px'}
        />
      )}
      <FormRow fieldLabel={hasMroInfection} fieldName={'hasMroInfection'}>
        <Field
          name="hasMroInfection"
          component={ToggleButtonGroupField}
          label={hasMroInfection}
          options={yesNoOptions}
          handleMutation={updateAssessment}
          conditionalHandleChange={handleChangeMroInfection}
          lastUpdateUser={getLastUpdateUser(lastUpdateUserData, 'hasMroInfection')}
        />
        {values.hasMroInfection === true && (
          <>
            <HelperMessage fieldName={'hasMroInfection'} fieldText={infectiousRiskAlertMessage} helperType="info" />
            <HelperMessage fieldName={'hasMroInfection'} fieldText={infectiousRiskQCLMessage} helperType="info" />
          </>
        )}
      </FormRow>
      {values.hasMroInfection === true && (
        <SelectFieldGroup
          name="mroInfection"
          addButtonText={actionLabels.addMroInfection}
          createMutation={createMroInfectionMutation}
          deleteMutation={deleteMroInfectionMutation}
          updateMutation={updateMroInfection}
          label={mroInfection}
          options={mroInfectionOptions}
          selectPlaceholder={sharedContent.generic.label.selectValue}
          selectFieldName="baseValue"
          textFieldName="otherValue"
          textFieldOptionsForOther={displayTextFieldOptionsForOther}
          values={mroInfectionValues}
          userIconFieldName="lastUpdateUser"
          userTypeValue="nurse"
          width={'500px'}
        />
      )}
      {mroInfectionTableData && (
        <InfoCard
          title="Patient Reported"
          subTitle="Health Assessment MRO infection"
          rowDetails={mroInfectionTableData.length ? [...mroInfectionTableData] : noDataTableRow}
        />
      )}
      {patient.userProfile
        ? patient.userProfile.systemState === AustralianStates.WA && (
            <FormRow fieldLabel={outsideWaLastTwelveMonths} fieldName={'outsideWaLastTwelveMonths'}>
              <Field
                name="outsideWaLastTwelveMonths"
                component={ToggleButtonGroupField}
                label={outsideWaLastTwelveMonths}
                helperType="error"
                options={yesNoOptions}
                handleMutation={updateAssessment}
                lastUpdateUser={getLastUpdateUser(lastUpdateUserData, 'outsideWaLastTwelveMonths')}
              />
              {values.outsideWaLastTwelveMonths === true && (
                <>
                  <HelperMessage
                    fieldName={'outsideWaLastTwelveMonths'}
                    fieldText={infectiousRiskQCLMessage}
                    helperType="info"
                  />
                </>
              )}
            </FormRow>
          )
        : null}
    </>
  );
};

export default InfectiousRisk;
