import { gql } from '@apollo/client';

const PATIENT_FRAGMENT = gql`
  fragment Patient on PatientType {
    id
    firstName
    middleName
    lastName
    gender
    dob
    primaryPhone
    secondaryPhone
    creatorRole
    creator {
      id
    }
    address {
      id
      line1
      line2
      city
      postcode
      state
      country
    }
    postalAddress {
      id
      line1
      line2
      city
      postcode
      state
      country
    }
  }
`;

export const ADD_PATIENT = gql`
  mutation AddPatient(
    $firstName: String!
    $middleName: String
    $lastName: String!
    $gender: String!
    $dob: Date!
    $responsiblePhysicianId: ID!
    $mobilePhoneNumber: String
    $homePhoneNumber: String
    $line1: String
    $line2: String
    $city: String
    $postcode: String
    $state: String
    $country: String
  ) {
    addPatient(
      firstName: $firstName
      middleName: $middleName
      lastName: $lastName
      gender: $gender
      dob: $dob
      responsiblePhysicianId: $responsiblePhysicianId
      mobilePhoneNumber: $mobilePhoneNumber
      homePhoneNumber: $homePhoneNumber
      addressLineOne: $line1
      addressLineTwo: $line2
      addressCity: $city
      addressPostcode: $postcode
      addressState: $state
      addressCountry: $country
    ) {
      patient {
        id
        ...Patient
      }
    }
  }

  ${PATIENT_FRAGMENT}
`;

export const CREATE_PATIENT_DUPLICATION_CHECK = gql`
  query createPatientDuplicationCheck(
    $firstName: String!
    $middleName: String
    $lastName: String!
    $gender: String!
    $dob: Date!
    $mobilePhoneNumber: String
    $homePhoneNumber: String
  ) {
    createPatientDuplicationCheck(
      firstName: $firstName
      middleName: $middleName
      lastName: $lastName
      gender: $gender
      dob: $dob
      mobilePhoneNumber: $mobilePhoneNumber
      homePhoneNumber: $homePhoneNumber
    ) {
      id
      firstName
      middleName
      lastName
      gender
      dob
      photoUrl
      primaryPhoneFormatted
      secondaryPhoneFormatted
      address {
        id
        state
        country
        formattedAddress
        ukFormattedAddress
      }
      residentialAddressLine1
      residentialAddressLine2
      residentialAddressCity
      residentialAddressPostcode
      residentialAddressState
      residentialAddressCountry
      residentialAddressCountryName
      postalAddressLine1
      postalAddressLine2
      postalAddressCity
      postalAddressPostcode
      postalAddressState
      postalAddressCountry
    }
  }
`;
