import React from 'react';
import AddNotes from './AddNotes';
import DisplayNotes from './DisplayNotes';
import OuterContainer from '../PatientSummary/OuterContainer';

import { ROPatientContextProvider } from '../PatientSummary/context';
import { useWindowSize } from 'shared-components/utils/CustomHooks';
import { Grid } from '@mui/material';
import { LARGE_DISPLAY_SIZE } from 'shared-components/enums';

const Notes = (): JSX.Element => {
  const windowSize = useWindowSize();
  const isSmall = windowSize.width < LARGE_DISPLAY_SIZE;

  return (
    <ROPatientContextProvider>
      <OuterContainer>
        <Grid container sx={{ padding: '0 16px' }}>
          <Grid
            xs={isSmall ? 11 : 9}
            sx={{
              padding: '16px',
              borderRadius: '4px',
              boxShadow: '0px 2px 8px rgba(113, 110, 106, 0.4)',
              backgroundColor: 'white',
            }}>
            <DisplayNotes />
          </Grid>
          <Grid xs={isSmall ? 1 : 3}>
            <AddNotes />
          </Grid>
        </Grid>
      </OuterContainer>
    </ROPatientContextProvider>
  );
};

export default Notes;
