import { FormikValues, validateYupSchema, yupToFormErrors } from 'formik';
import * as Yup from 'yup';

export const generateValidationSchema = (values: FormikValues): any => {
  try {
    validateYupSchema<FormikValues>(
      values,
      Yup.object().shape({
        feedbackMsg: Yup.string().ensure().required('Please complete your feedback'),
      }),
      true,
    );
  } catch (err) {
    return yupToFormErrors(err);
  }

  return {};
};
