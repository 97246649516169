import React, { useState, useEffect, useRef, useContext } from 'react';
import { Form, Formik, Field, FormikProps, useFormikContext } from 'formik';
import * as Yup from 'yup';
import { useMutation } from '@apollo/client';
import { CREATE_MO_PATIENT_OBSERVATION } from '../../Queries';
import { calculateBSA, calculateCreatinineClearance } from '../../../../../utils/MOCalculationUtils';
import { calculateMomentAge } from 'shared-components/utils';
import moment from 'moment';
import { FormContainer, NumberInput, Output, CheckboxInput } from './ModalComponents';
import { PatientObservation } from './../interfaces';
import { UserContext } from 'op-contexts/index';
import { StandardDialog } from 'shared-components/components';

interface PropsType {
  onSubmit?: any;
  onClose: () => void;
  open: boolean;
  careplanId: number;
  patient: any;
  latestObservation: PatientObservation | null;
  setLatestObservation: (obs: PatientObservation) => void;
}

interface ObservationsFields {
  weight: number | '';
  height: number | '';
  serumCreatinine: number | '';
  recalculate: boolean;
  creatinineClearance: string;
  bsa: string;
  age: number;
  gender: string;
}

const CalculatedOutputBSA = ({ units, label }: any) => {
  const {
    values: { height, weight, ...values },
    setFieldValue,
  } = useFormikContext<ObservationsFields>();
  useEffect(() => {
    const bsa = calculateBSA(height, weight);
    setFieldValue('bsa', isNaN(bsa) ? '-' : bsa);
  }, [height, weight]);
  return <Output label={label} value={values.bsa} units={units} />;
};

const CalculatedOutputCreatinineClearance = ({ units, label }: any) => {
  const {
    values: { weight, age, serumCreatinine, gender, creatinineClearance },
    setFieldValue,
  } = useFormikContext<ObservationsFields>();
  useEffect(() => {
    const cc = calculateCreatinineClearance(weight, age, serumCreatinine, gender);
    setFieldValue('creatinineClearance', isNaN(cc) ? '-' : cc);
  }, [serumCreatinine, weight, age, gender]);
  return <Output label={label} value={creatinineClearance} units={units} />;
};

const ValidationSchema = Yup.object().shape({
  weight: Yup.number().min(0, 'Invalid value').required('invalid value'),
  height: Yup.number().min(0, 'Invalid value').required('invalid value'),
});
const PatientObservationsModal = ({
  onClose,
  open,
  careplanId,
  patient,
  latestObservation,
  setLatestObservation,
}: PropsType): JSX.Element => {
  const [busy, setBusy] = useState(false);
  const [validateOnBlur, setValidateOnBlur] = useState(false);
  const { state: userDetails } = useContext(UserContext);
  useEffect(() => {
    if (open) {
      setBusy(false);
      setValidateOnBlur(false);
    }
  }, [open]);

  const initialValues: ObservationsFields = {
    weight: latestObservation?.weight || '',
    height: latestObservation?.height || '',
    serumCreatinine: latestObservation?.serumCreatinine || '',
    bsa: '-',
    creatinineClearance: '-',
    recalculate: true, // default to true, but checkbox will be hidden on first observation
    age: patient?.dob ? calculateMomentAge(moment(patient.dob)) : 0,
    gender: patient?.patientGender,
  };

  const [createObservation] = useMutation(CREATE_MO_PATIENT_OBSERVATION, {
    refetchQueries: ['moCareplanData', 'latestCycles'],
    onCompleted: (data) => {
      const observationDateToLocalTimezone =
        data.createMoPatientObservation.moPatientObservation &&
        moment(data.createMoPatientObservation.moPatientObservation?.observationDate)
          .tz(userDetails.timezone)
          .format('L LT');
      setLatestObservation({
        ...data.createMoPatientObservation.moPatientObservation,
        observationDate: observationDateToLocalTimezone,
      });
      onClose();
    },
  });

  const handleSubmit = () => {
    if (formRef.current?.values === undefined) {
      setBusy(false);
      return;
    }
    const { weight, height, serumCreatinine, bsa, creatinineClearance, recalculate } = formRef.current.values;
    formRef.current.validateForm().then((errors) => {
      setValidateOnBlur(true);
      if (Object.keys(errors).length === 0) {
        createObservation({
          variables: {
            careplanId,
            weight: weight ? weight : undefined,
            height: height ? height : undefined,
            serumCreatinine: serumCreatinine ? serumCreatinine : undefined,
            bsa: bsa !== '-' ? bsa : undefined,
            creatinineClearance: creatinineClearance !== '-' ? creatinineClearance : undefined,
            recalculateDoses: recalculate,
          },
        });
      } else {
        setBusy(false);
      }
      return errors;
    });
  };

  const formRef = useRef<FormikProps<ObservationsFields>>(null);

  return (
    <StandardDialog
      open={open}
      maxWidth="lg"
      onClose={onClose}
      title="Add patient Information"
      onSubmit={() => {
        setBusy(true);
        handleSubmit();
      }}
      submitText="Add">
      <Formik
        initialValues={initialValues}
        onSubmit={() => {}}
        innerRef={formRef}
        initialTouched={{ weight: true, height: true }}
        validationSchema={ValidationSchema}
        validateOnChange={false}
        validateOnBlur={validateOnBlur}>
        {({ values }) => (
          <Form data-test-id={'observation-form'}>
            <FormContainer>
              <Field
                component={NumberInput}
                label="Patient weight: *"
                data-test-id={'patient-weight'}
                name="weight"
                units="kg"
                inputProps={{ min: 0 }}
              />
              <Field
                component={NumberInput}
                label="Patient height: *"
                data-test-id={'patient-height'}
                name="height"
                units="cm"
                inputProps={{ min: 0 }}
              />
              <Field
                component={NumberInput}
                label="Serum creatinine:"
                data-test-id={'patient-creatinine'}
                name="serumCreatinine"
                units="μmol/L"
                inputProps={{ min: 0 }}
              />
              <Output label="Patient sex:" value={values.gender} />
              <Output label="Patient age:" value={values.age} />
              <Field component={CalculatedOutputBSA} label="BSA:" units="m²" />
              <Field component={CalculatedOutputCreatinineClearance} label="Creatinine Clearance:" units="mL/min" />
              {latestObservation?.recalculate !== undefined && (
                <Field
                  label="Recalculate drug doses:"
                  name="recalculate"
                  component={CheckboxInput}
                  id={'recalculate-checkbox'}
                  type="checkbox"
                />
              )}
            </FormContainer>
          </Form>
        )}
      </Formik>
    </StandardDialog>
  );
};

export default PatientObservationsModal;
