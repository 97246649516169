import { gql } from '@apollo/client';
import { HA_BIOLOGICAL_FRAGMENT } from 'op-graphql/fragments';

export const HA_BIOLOGICAL_QUERY = gql`
  query HealthAssessment($patientId: ID!) {
    healthAssessment(id: $patientId) {
      id
      ...HealthAssessmentBiological
    }
  }
  ${HA_BIOLOGICAL_FRAGMENT}
`;

export const UPDATE_HA_BIOLOGICAL = gql`
  mutation UpdateHaBiological(
    $haId: ID!
    $patientId: ID!
    $biologicalGender: String
    $periodAge: String
    $breastBiopsy: String
    $abnormalMammograms: String
    $periodWhen: String
    $menopause: String
    $menopauseAge: String
    $biologicalPregnant: String
    $pregnantCount: String
    $pregnantDeliveries: String
    $pregnantAge: String
    $breastfeed: String
    $hormones: String
    $hormonesDuration: String
    $braSize: String
    $nippleDischarge: String
    $nippleDischargeExplain: String
    $vaginalBleeding: String
    $vaginalBleedingExplain: String
    $vaginalDischarge: String
    $vaginalDischargeExplain: String
    $stdFemale: String
    $papSmearDateRawMonth: String
    $papSmearDateRawDay: String
    $papSmearDateRawYear: String
    $mammogramDateRawMonth: String
    $mammogramDateRawDay: String
    $mammogramDateRawYear: String
    $psaDateRawMonth: String
    $psaDateRawDay: String
    $psaDateRawYear: String
    $psaScore: String
    $psaLocation: String
    $stdMale: String
  ) {
    updateHaBiological(
      id: $haId
      patientId: $patientId
      biologicalGender: $biologicalGender
      periodAge: $periodAge
      breastBiopsy: $breastBiopsy
      abnormalMammograms: $abnormalMammograms
      periodWhen: $periodWhen
      menopause: $menopause
      menopauseAge: $menopauseAge
      biologicalPregnant: $biologicalPregnant
      pregnantCount: $pregnantCount
      pregnantDeliveries: $pregnantDeliveries
      pregnantAge: $pregnantAge
      breastfeed: $breastfeed
      hormones: $hormones
      hormonesDuration: $hormonesDuration
      braSize: $braSize
      nippleDischarge: $nippleDischarge
      nippleDischargeExplain: $nippleDischargeExplain
      vaginalBleeding: $vaginalBleeding
      vaginalBleedingExplain: $vaginalBleedingExplain
      vaginalDischarge: $vaginalDischarge
      vaginalDischargeExplain: $vaginalDischargeExplain
      stdFemale: $stdFemale
      papSmearDateRawMonth: $papSmearDateRawMonth
      papSmearDateRawDay: $papSmearDateRawDay
      papSmearDateRawYear: $papSmearDateRawYear
      mammogramDateRawMonth: $mammogramDateRawMonth
      mammogramDateRawDay: $mammogramDateRawDay
      mammogramDateRawYear: $mammogramDateRawYear
      psaDateRawMonth: $psaDateRawMonth
      psaDateRawDay: $psaDateRawDay
      psaDateRawYear: $psaDateRawYear
      psaScore: $psaScore
      psaLocation: $psaLocation
      stdMale: $stdMale
    ) {
      healthAssessment {
        id
        ...HealthAssessmentBiological
      }
    }
  }
  ${HA_BIOLOGICAL_FRAGMENT}
`;
