import moment from 'moment-timezone';

import { AppointmentObject } from '../../models';

export default class AppointmentViewModel {
  // =========
  // VARIABLES
  // =========
  private allAppointments: AppointmentObject[];
  public sortedAppointments: { [index: string]: AppointmentObject[][] };
  public appointmentMappings: { [id: string]: { dictKey: string; arrayIndex: number; apptIndex: number } };

  // =========
  // CONSTANTS
  // =========
  private SINGLE_APPOINTMENT = 1;
  private PATH_SEPARATOR = '/';
  private APPOINTMENT_ID_INDEX = 3;
  private EMPTY_LENGTH = 0;

  /**
   * Constructor method
   */
  constructor() {
    this.allAppointments = [];
    this.sortedAppointments = {};
    this.appointmentMappings = {};
  }

  // ==============
  // PUBLIC METHODS
  // ==============

  /**
   * Sets all the appointments without sorting the data, the method will automatically sort the data.
   * @param {AppointmentObject[]} allAppointments: The appointments data from GraphQL without modification.
   */
  public setAllAppointmentsAndSort = (allAppointments: AppointmentObject[]): void => {
    this.allAppointments = allAppointments;
    this.sortQueryResultData();
  };

  /**
   * Gets the selected appointment object by the given ID.
   * @param {string | null} id: The ID of the appointment that is being looked for.
   * @returns {AppointmentObject | null} The appointment object associated with the given ID, or if the ID is invalid, returns null.
   */
  public getSelectedAppointmentByID = (id: string | null): AppointmentObject | null => {
    if (id) {
      const mappings = this.getDictionaryMappingForID(id);
      if (mappings) {
        return this.sortedAppointments[mappings.dictKey][mappings.arrayIndex][mappings.apptIndex];
      }
    }

    return null;
  };

  /**
   * Gets the first appointment object in the dictionary.
   * @returns {AppointmentObject | null} The first appointment object in the dictionary or null if there was an error.
   */
  public getFirstAppointmentInListing = (): AppointmentObject | null => {
    const firstDictKey = Object.keys(this.sortedAppointments)[0];
    const firstDictionary = this.sortedAppointments[firstDictKey];

    if (firstDictionary) {
      const firstAppointmentArray = firstDictionary[0];

      if (firstAppointmentArray) {
        const firstAppointmentObject = firstAppointmentArray[0];

        if (firstAppointmentObject) {
          return firstAppointmentObject;
        }
      }
    }
    return null;
  };

  /**
   * Gets the location of the appointment with the given ID in the sorted dictionary.
   * @param {string} id: The appointment ID.
   * @returns {{ dictKey: string; arrayIndex: number; apptIndex: number } | null} An object containing the
   * dictionary key, array index and day array index, or null if a mapping for this ID does not exist.
   */
  public getDictionaryMappingForID = (
    id: string,
  ): { dictKey: string; arrayIndex: number; apptIndex: number } | null => {
    const mapping = this.appointmentMappings[id];
    if (mapping) {
      return mapping;
    }
    return null;
  };

  /**
   * Determines if the appointment is a same day appointment.
   * @param {string} id: The ID of the appointment to check.
   * @returns {boolean} A boolean value, true if the appointment is a same day appointment, false if not.
   */
  public isSameDayAppointment = (id: string): boolean => {
    const mapping = this.appointmentMappings[id];
    if (mapping) {
      return this.sortedAppointments[mapping.dictKey][mapping.arrayIndex].length > this.SINGLE_APPOINTMENT;
    }

    return false; // Default
  };

  /**
   * Gets the selected appointment ID from the URL.
   * @param {string} pathname: The path of the current URL.
   * @returns {string} The selected appointment ID, or null if there is no appointment ID specified.
   */
  public getAppointmentIDFromURL = (pathname: string): string | null => {
    const id = pathname.split(this.PATH_SEPARATOR)[this.APPOINTMENT_ID_INDEX];
    if (id) {
      return id;
    }

    // No id has been found from the path, so return null
    return null;
  };

  /**
   * Checks if the data is empty or not so that an empty message can be shown.
   * @returns {boolean} True if the data set is empty, false if the data is not empty.
   */
  public isDataEmpty = (): boolean => {
    return this.allAppointments.length === this.EMPTY_LENGTH;
  };

  // ===============
  // PRIVATE METHODS
  // ===============

  /**
   * Sort the query result data into a dictionary of month and days in the format:
   * { <MMMM YYYY>: [[<SameDayAppointmentObject>, <SameDayAppointmentObject>, [...]], <MMMM YYYY>: [[...]] }
   */
  private sortQueryResultData = (): void => {
    this.sortedAppointments = {};

    // Stores the last seen date
    let lastSeenDayOfYear: number;

    // Iterate through each appointment result
    this.allAppointments.forEach((apptObj: AppointmentObject) => {
      if (apptObj.startTime) {
        // Calculate the current month and year
        const utcDate = moment.utc(apptObj.startTime);
        const localDate = utcDate.local();
        const month = localDate.format('MMMM YYYY');
        const dayOfYear = localDate.dayOfYear();

        // Store the current appointment in the appropriate month array
        if (!this.sortedAppointments[month]) {
          // The key does not yet exist, so create a new 'key' and assign it a value
          this.sortedAppointments[month] = [[apptObj]];
        } else if (dayOfYear === lastSeenDayOfYear) {
          // The key already exists and the date is the same, so add to the same date array as the last
          this.sortedAppointments[month][this.sortedAppointments[month].length - 1].push(apptObj);
        } else {
          // The key already exists but the date is different to the last seen date, so push a new date array
          this.sortedAppointments[month].push([apptObj]);
        }

        // Update the last seen date
        lastSeenDayOfYear = dayOfYear;

        // Store the appointment ID, along with its 'mapping' info (month key, array number and day array index)
        this.appointmentMappings[apptObj.id] = {
          dictKey: month,
          arrayIndex: this.sortedAppointments[month].length - 1,
          apptIndex: this.sortedAppointments[month][this.sortedAppointments[month].length - 1].length - 1,
        };
      } else {
        // FIXME: Handle the error or report to sentry
      }
    });
  };
}
