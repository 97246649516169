export const useReferrers = (allReferrers: any[]): any => {
  const getReferrer = (type: string) =>
    allReferrers.filter((referrer: any) => referrer.relationshipType.startsWith(type));
  const responsiblePhysician = getReferrer('oncologist')[0];
  const primaryPhysician = getReferrer('generalPractitioner')[0];
  const referringSurgeon = getReferrer('referringSurgeon').sort((a, b) =>
    a.relationshipType > b.relationshipType ? 1 : -1,
  );
  return { responsiblePhysician, primaryPhysician, referringSurgeon };
};
