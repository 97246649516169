export interface IntakeLungData {
  id?: string | null;
  weightLoss: string | null;
  smokingHistory: string | null;
  egfr: string | null;
  alk: string | null;
  pdl1: string | null;
}

export interface IntakeUrogenitalData {
  id?: string | null;
  gleasonOne: any;
  gleasonTwo: any;
  isupGrade: string | null;
  psa: string | null;
  psaDate: string | null;
  psma: string | null;
  positiveSite: string[];
  prostatectomy: string | null;
}

export interface IntakeBreastData {
  id?: string | null;
  grade: string | null;
  er: string | null;
  pgr: string | null;
  her2: string | null;
  mastectomy: string | null;
  reconstruction: string | null;
  excerciseReferral: string | null;
  surgery: string | null;
  oncoplasticSurgery: string | null;
  reconstructionTimeline: string | null;
  reconstructionType: string | null;
  expandersInSitu: string | null;
  lymphovascularInvasion: string | null;
  lymphovascularInvasionExtent: string | null;
  radialMarginStatus: string | null;
  neoadjuvantSystemicTherapy: string | null;
  nstAdditionalInfo: string | null;
  exerciseDiscussedWithPatient: string | null;
}

export interface IntakeLymphomaData {
  id?: string | null;
  stage: string | null;
  aOrBSymptoms: string | null;
  extranodal: string | null;
  bulkDisease: string | null;
}

export interface IntakeHeadAndNeckData {
  id?: string | null;
  p16: string | null;
  smokingHistory: string | null;
  stageTCode: string | null;
  stageNCode: string | null;
  stageMCode: string | null;
  cigarettesPerDay: number | null;
  yearsSmoked: number | null;
  packYears: number | null;
  dateOfSurgery: string | null;
  extracapsularExtension: string | null;
  margins: string | null;
  marginsMeasurement: number | null;
  periNeuralInvasion: string | null;
  pniType: string | null;
  patientDiscussedAtMdt: string | null;
  treatmentConsistentMdt: string | null;
}

export interface IntakeData {
  lung: IntakeLungData | null;
  urogenital: IntakeUrogenitalData | null;
  breast: IntakeBreastData | null;
  lymphoma: IntakeLymphomaData | null;
  headAndNeck: IntakeHeadAndNeckData | null;
}

export interface FormikValues {
  intake?: IntakeData;
}

export interface IntakePageProps {
  updateIntakeHandler: (changed: { [key: string]: string | string[] | number | null }) => void;
  dateError?: string | null;
  setDateError?: any;
  featureVersion?: number;
}

export const DEFAULT_INTAKE_HEAD_AND_NECK_DATA: IntakeHeadAndNeckData = {
  id: '',
  p16: null,
  smokingHistory: null,
  stageTCode: null,
  stageNCode: null,
  stageMCode: null,
  cigarettesPerDay: null,
  yearsSmoked: null,
  packYears: null,
  dateOfSurgery: null,
  extracapsularExtension: null,
  margins: null,
  marginsMeasurement: null,
  periNeuralInvasion: null,
  pniType: null,
  patientDiscussedAtMdt: null,
  treatmentConsistentMdt: null,
};

export const DEFAULT_INTAKE_UROGENITAL_DATA: IntakeUrogenitalData = {
  id: '',
  gleasonOne: null,
  gleasonTwo: null,
  isupGrade: null,
  psa: null,
  psaDate: null,
  psma: null,
  positiveSite: [],
  prostatectomy: null,
};

export const DEFAULT_INTAKE_LUNG_DATA: IntakeLungData = {
  id: '',
  weightLoss: null,
  smokingHistory: null,
  egfr: null,
  alk: null,
  pdl1: null,
};

export const DEFAULT_INTAKE_BREAST_DATA: IntakeBreastData = {
  id: '',
  grade: null,
  er: null,
  pgr: null,
  her2: null,
  mastectomy: null,
  reconstruction: null,
  excerciseReferral: null,
  surgery: null,
  oncoplasticSurgery: null,
  reconstructionTimeline: null,
  reconstructionType: null,
  expandersInSitu: null,
  lymphovascularInvasion: null,
  lymphovascularInvasionExtent: null,
  radialMarginStatus: null,
  neoadjuvantSystemicTherapy: null,
  nstAdditionalInfo: null,
  exerciseDiscussedWithPatient: null,
};

export const DEFAULT_INTAKE_LYMPHOMA_DATA: IntakeLymphomaData = {
  id: '',
  stage: null,
  aOrBSymptoms: null,
  extranodal: null,
  bulkDisease: null,
};

export const DEFAULT_INTAKE_DATA: IntakeData = {
  lung: DEFAULT_INTAKE_LUNG_DATA,
  urogenital: DEFAULT_INTAKE_UROGENITAL_DATA,
  breast: DEFAULT_INTAKE_BREAST_DATA,
  lymphoma: DEFAULT_INTAKE_LYMPHOMA_DATA,
  headAndNeck: DEFAULT_INTAKE_HEAD_AND_NECK_DATA,
};
