import { useState, useEffect, SetStateAction, Dispatch } from 'react';
import { useQuery } from '@apollo/client';
import { gql } from '@apollo/client';

export const GET_PATIENT_NOTES = gql`
  query notes($patientId: ID!) {
    notes(patientId: $patientId) {
      id
      title
      formattedMessage
      messageType
      messageTypeAlias
      practitioner
      practitionerQualification
      status
      createdAt
      approvedAt
      editedAt
      updatedAt
      isDraft
      createdBy {
        id
        name
      }
      editedBy {
        id
        name
      }
    }
  }
`;

export interface PatientNote {
  id: string;
  title: string;
  formattedMessage: string;
  messageType: string;
  messageTypeAlias: string;
  practitioner: string;
  practitionerQualification: string;
  approvedAt: string;
  createdAt: string;
  updatedAt: string;
  editedAt: string;
  isDraft: boolean;
  createdBy: {
    name: string;
    id: string;
  };
  editedBy: {
    name: string;
    id: string;
  };
}

export const GET_NOTE_TYPES = gql`
  query notesTypes($patientId: ID!) {
    notesTypes(patientId: $patientId) {
      id
      alias
      name
      mosaiqId
      fallbackMosaiqId
      listWeight
    }
  }
`;

export interface NoteType {
  alias?: string;
  fallbackMosaiqId?: number;
  id: string;
  mosaiqId?: number;
  type?: string;
  name: string;
  checked?: boolean;
  listWeight?: boolean;
}

export interface UsePatientNoteType {
  notes: PatientNote[];
  filteredNotes: PatientNote[];
  filterNoteTypes: NoteType[];
  setFilterNoteTypes: Dispatch<SetStateAction<NoteType[]>>;
  isLoading: boolean;
  hasErrors: boolean;
}

export function usePatientNotes(patientId: string): UsePatientNoteType {
  const [notes, setNotes] = useState<PatientNote[]>([]);
  const [filteredNotes, setFilteredNotes] = useState<PatientNote[]>([]);
  const [isLoading, setIsLoading] = useState<boolean>(true);
  const [hasErrors, setHasErrors] = useState<boolean>(false);
  const [filterNoteTypes, setFilterNoteTypes] = useState<NoteType[]>([]);
  const { data, loading, error } = useQuery(GET_PATIENT_NOTES, {
    variables: { patientId },
    fetchPolicy: 'cache-and-network',
    skip: !patientId,
  });
  useEffect(() => {
    setIsLoading(loading);
  }, [loading]);

  useEffect(() => {
    if (data) {
      setNotes(data.notes);
      if (data.notes && data.notes.length) {
        const existingNoteTypes = filterNoteTypes.map((note: NoteType) => note.type);
        const mapMessageTypeToAlias = data.notes.reduce((msgAlias: {}, note: PatientNote): any => {
          //@ts-ignore
          msgAlias[note.messageType] = note.messageTypeAlias;
          return msgAlias;
        }, {});
        const newFilterNoteTypes = [...new Set<string>(data.notes.map((note: PatientNote) => note.messageType))]
          .filter((noteName): boolean => !existingNoteTypes.includes(noteName))
          .map((noteName, index): NoteType => {
            return {
              id: (existingNoteTypes.length + index).toString(),
              type: noteName,
              alias: mapMessageTypeToAlias[noteName],
              name: mapMessageTypeToAlias[noteName],
              checked: true,
            };
          });

        setFilterNoteTypes([...filterNoteTypes, ...newFilterNoteTypes]);
      } else {
        setFilterNoteTypes([]);
      }
    }
  }, [data]);

  useEffect(() => {
    if (error && !hasErrors) {
      setHasErrors(true);
    }
    if (!error && hasErrors) {
      setHasErrors(false);
    }
  }, [error]);

  useEffect(() => {
    //Filter the notes based on the notes types
    if (filterNoteTypes && filterNoteTypes.length) {
      const noteTypes = filterNoteTypes
        .filter((noteType) => noteType.checked)
        .map((noteType: NoteType): string | undefined => noteType?.type);
      if (notes && notes.length) {
        setFilteredNotes(
          notes.filter((note: PatientNote): boolean => noteTypes.includes(note.messageType) && !note.isDraft),
        );
      }
    } else setFilteredNotes([]);
  }, [filterNoteTypes]);
  return { notes, filteredNotes, filterNoteTypes, setFilterNoteTypes, isLoading, hasErrors };
}

export function useNoteTypes(patientId: string): NoteType[] {
  const [noteTypes, setNoteTypes] = useState<NoteType[]>([]);
  useQuery(GET_NOTE_TYPES, {
    variables: { patientId },
    fetchPolicy: 'cache-and-network',
    onCompleted: (data) => {
      if (data && data.notesTypes) {
        setNoteTypes(data.notesTypes);
      }
    },
  });
  return noteTypes;
}
