import React from 'react';
import { styled } from '@mui/system';
import { AddOutlineCircle, RemoveOutlineCircle } from 'shared-components/images';

interface Props {
  fieldId: string;
  type?: string;
  label?: string;
  onClick: () => void;
}

const StyledButton = styled('button')`
  border: none;
  background: none;
  padding: 0;
  align-items: center;
  display: flex;
  cursor: pointer;
  outline: none !important;
`;

interface IStyledIcon {
  label?: string;
}

const AddIcon = styled((props: any) => <AddOutlineCircle title="AddOutlineCircle.svg" {...props} />)<IStyledIcon>`
  margin-right: ${(props: IStyledIcon): string => (props.label ? '8px' : '0px')};
`;

const RemoveIcon = styled((props: any) => (
  <RemoveOutlineCircle title="RemoveOutlineCircle.svg" {...props} />
))<IStyledIcon>`
  margin-right: ${(props: IStyledIcon): string => (props.label ? '8px' : '0px')};
`;

const Label = styled('span')`
  font-weight: bold;
  font-size: 0.875rem;
`;

const ActionButton = ({ type, fieldId, label, onClick }: Props): JSX.Element => {
  return (
    <StyledButton type="button" onClick={onClick} data-testid={`${fieldId}-${type}-button-id`}>
      {type === 'add' ? <AddIcon label={label} /> : <RemoveIcon label={label} />}
      {label && <Label>{label}</Label>}
    </StyledButton>
  );
};

export default ActionButton;
