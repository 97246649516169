export const sharedContent = {
  feedbackForm: {
    placeholder: 'Type your message here...',
    closeButton: 'Close feedback',
    submissionSuccess: 'Your feedback has been submitted successfully. Thank you for your time.',
  },
  menuLinks: {
    feedback: 'Give us feedback',
    newSummary: 'New patient summary',
    manageProtocols: 'Manage protocols',
    logout: 'Log out',
  },
  generic: {
    label: {
      feedback: 'Feedback',
      myAccount: 'My Account',
    },
  },
};
