import React, { useState } from 'react';
import { gql } from '@apollo/client';
import { RouteComponentProps, withRouter } from 'react-router-dom';
import { WithApolloClient, withApollo } from '@apollo/client/react/hoc';
import { useQuery } from '@apollo/client';

import { StepperLink } from 'shared-components/interfaces';
import { Logger } from 'shared-components/utils';
import { FeatureOption } from 'op-interfaces';

import DTNavigator from './DTNavigator';

const logger = new Logger('DTNavigatorApollo');

const DT_VERSION_QUERY = gql`
  query DistressThermometer($patientId: ID!, $id: ID!) {
    distressThermometer(patientId: $patientId, id: $id) {
      id
      version
    }
    featureOptions {
      id
      name
      description
      active
    }
  }
`;

const DT_SUBMISSION_MUTATION = gql`
  mutation CreateDTSubmission($patientId: ID!, $id: ID!) {
    createDTSubmission(patientId: $patientId, id: $id) {
      submissionCreated
      submission {
        id
        pdf
      }
    }
  }
`;

interface CreateSubmissionMutationResponse {
  createDTSubmission: {
    submission: {
      id: string;
      pdf: string;
    };
  };
}

interface Props extends WithApolloClient<{}>, RouteComponentProps<{ patientId: string; distressId: string }> {
  links: StepperLink[];
}

const DTNavigatorApollo = (props: Props): JSX.Element => {
  const [loadingMutation, setLoadingMutation] = useState<boolean>(false);
  const [mutationCalled, setMutationCalled] = useState<boolean>(false);

  const {
    client,
    links,
    match: {
      params: { patientId, distressId },
    },
  } = props;

  const { data } = useQuery(DT_VERSION_QUERY, { variables: { patientId, id: distressId } });
  const distressVersion = data?.distressThermometer?.version;

  const submitDT = (): void => {
    if (!loadingMutation && !mutationCalled) {
      setLoadingMutation(true);

      const callClient = (): void => {
        // Call the client to submit the distress thermometer
        client
          ?.mutate({
            mutation: DT_SUBMISSION_MUTATION,
            variables: {
              patientId,
              id: distressId,
            },
          })
          .then((response): void => {
            if (response.data && response.data.createDTSubmission && response.data.createDTSubmission.submission) {
              logger.debug(
                'submitDT',
                'The pdf has been loaded in: ',
                `${window.location.origin}/server/media/${response.data.createDTSubmission.submission.pdf}`,
              );
            }
            setLoadingMutation(false);
            setMutationCalled(true);
          });
      };
      callClient();
    }
  };
  const showNewRego = data?.featureOptions?.find(
    (featureOption: FeatureOption) => featureOption.name === 'NewAusRego' && featureOption.active,
  );
  return (
    <DTNavigator
      links={links}
      loading={loadingMutation}
      submitFunction={submitDT}
      submitCalled={mutationCalled}
      distressVersion={distressVersion}
      showNewRego={showNewRego}
    />
  );
};

const routeComponent = withRouter(withApollo<Props>(DTNavigatorApollo));
export default routeComponent;
