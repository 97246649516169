// eslint-disable-next-line no-use-before-define
import React, { useState, useContext, useEffect } from 'react';
import { UK_POSTCODE_REGEXP } from 'shared-components/utils/Regex';
import PlacesAutocomplete, { geocodeByPlaceId } from 'react-places-autocomplete';
import classnames from 'classnames';

import './AddressAutocomplete.scss';

import { RegistrationContext } from 'op-contexts';
import { AddressUkComponents } from 'op-interfaces';

import {
  BaseTextField,
  SelectOptionType,
  BaseAutocomplete,
  ROHelperText,
} from 'shared-components/components/FormFields';

import { fetchAddresses } from './PostcodeLookup';
import { Stack } from '@mui/material';
import { Search } from '@mui/icons-material';

interface Props {
  placeholder: string;
  name: string;
  loadingMessage: string;
  errorMessage?: string[];
  onSelected: (components: AddressUkComponents) => void;
  disabled?: boolean;
  defaultValue?: string;
  inputOnBlur?: (e: React.FocusEvent<HTMLInputElement> | undefined, hasError: boolean) => void;
  region?: string;
  fullWidth?: boolean;
  isRO?: boolean;
  dataTestId?: string;
}

interface PostCodeData {
  address: string;
  url: string;
  id: string;
}

const AddressAutocomplete = (props: Props) => {
  const { addressPlaceId, setAddressPlaceId } = useContext(RegistrationContext);
  const [suggestionsDisplayed, setSuggestionsDisplayed] = useState(false);
  const [hasError, setHasError] = useState(false);
  const [address, setAddress] = useState(props?.defaultValue || '');
  const [autocompleteAddress, setAutocompleteAddress] = useState('');
  const [placeId, setPlaceId] = useState(addressPlaceId || '');
  const [addresses, setAddresses] = useState<AddressUkComponents[]>([]);
  const [postcodeErrors, setPostcodeErrors] = useState<string[]>([]);

  useEffect(() => {
    setAddress(props?.defaultValue || '');
  }, [props?.defaultValue]);

  /**
   * Called when a suggested address option has been clicked on.
   */
  const onSelectHandler = (address: string, placeId: string): void => {
    // State
    setAddress(address);
    setPlaceId(placeId);
    setHasError(false);

    // Context
    setAddressPlaceId(placeId);

    getGeocodeAndSelect(placeId);
  };

  /**
   * Called each time the textfield is being updated, e.g. after a character is entered or deleted.
   */
  const onChangeHandler = (address: string): void => {
    setAddress(address);
    setHasError(false);
    setSuggestionsDisplayed(true);

    if (address === '') {
      setPlaceId('');
    }

    // Call to reset the error state for the section header when text has changed.
    if (props.inputOnBlur) {
      props.inputOnBlur(undefined, false);
    }
  };

  /**
   * Called when there are no addresses matching the given address input.
   */
  // eslint-disable-next-line
  const onErrorHandler = (status: string, clearSuggestions: any): void => {
    const { inputOnBlur } = props;

    clearSuggestions();
    setHasError(true);

    if (inputOnBlur) {
      inputOnBlur(undefined, hasError);
    }
  };

  /**
   * Called when the geocode needs to be fetched using the place id and then also call the component handler.
   * @param {string} placeId The place id for the geo code provided by Google API.
   */
  const getGeocodeAndSelect = async (placeId: string): Promise<void> => {
    if (placeId) {
      const results = await geocodeByPlaceId(placeId);

      if (results.length > 0) {
        //@ts-ignore
        setAddressPlaceId(placeId);
      }
    }
  };

  // determine if uk address line1 is Residential or not
  const isResidentialAddress = (addressLine1: string) => {
    const businessStrings = ['Ltd'];

    for (let i = 0; i < businessStrings.length; i++) {
      if (addressLine1.indexOf(businessStrings[i]) > -1) {
        return false;
      }
    }
    return true;
  };

  const getApiAddress = async (postcode: string): Promise<void> => {
    setPostcodeErrors([]);
    setAddresses([]);

    if (!postcode) {
      return;
    }

    // validate against REGEX
    if (!UK_POSTCODE_REGEXP.test(postcode.toString())) {
      setPostcodeErrors(['Valid postcode required']);
      return;
    }

    fetchAddresses(postcode)
      .then((result) => {
        if (!result || result.suggestions.length === 0) {
          setPostcodeErrors(['Not found']);
          return;
        }
        const addressResult: AddressUkComponents[] = [];

        // strip out address elements based on api request 'template'
        // if county returns blank from api, use city for county as well. Required for addresses in 'London'
        result.suggestions.forEach((addressObj: PostCodeData) => {
          if (isResidentialAddress(addressObj.address.split('|')[0])) {
            addressResult.push({
              id: addressObj.id,
              line1: addressObj.address.split('|')[0],
              line2: addressObj.address.split('|')[1],
              locality: addressObj.address.split('|')[2],
              city: addressObj.address.split('|')[3],
              county: addressObj.address.split('|')[4] || addressObj.address.split('|')[3],
              country: addressObj.address.split('|')[6],
              postcode: addressObj.address.split('|')[5],
            });
          }
        });
        setAddresses(addressResult);
      })
      .catch((error: any) => {
        setPostcodeErrors([error.message || 'API Error']);
      });
  };

  const handleSelect = (id: string) => {
    const address = addresses.find((addr: AddressUkComponents) => addr.id === id);
    if (address) {
      props.onSelected(address);
    }
  };

  const submitPostcodeSearch = (keyType: string, searchTerm: string) => {
    if (keyType === 'Enter') {
      getApiAddress(searchTerm);
    }
  };

  const { loadingMessage, errorMessage, region } = props;

  let country = ['au', 'nz'];
  if (region && region.toLowerCase() === 'uk') {
    country = ['uk', 'im', 'gg', 'je'];
  }

  const searchOptions = {
    types: ['address'],
    componentRestrictions: {
      country,
    },
  };

  const addressOptions = addresses.map((addr: AddressUkComponents) => {
    let addrName = addr.line1;
    if (addr.line2) {
      addrName += `, ${addr.line2}`;
    }
    if (addr.locality) {
      addrName += `, ${addr.locality}`;
    }
    return {
      id: addr.id,
      name: addrName,
    };
  });

  return (
    <PlacesAutocomplete
      value={address}
      onSelect={onSelectHandler}
      onChange={onChangeHandler}
      onError={onErrorHandler}
      searchOptions={searchOptions}>
      {({ suggestions, getSuggestionItemProps, loading }): JSX.Element => {
        return (
          <Stack sx={{ width: '100%', maxWidth: '400px' }} gap="8px">
            <BaseTextField
              name="postcode-search-input"
              id="postcode-search-input"
              placeholder="Search postcode"
              InputProps={{
                startAdornment: <Search color="primary" />,
              }}
              autoComplete="off"
              onBlur={(e): Promise<void> => getApiAddress(e.target.value)}
              onKeyDown={(e) => submitPostcodeSearch(e.key, (e.target as HTMLInputElement).value)}
            />
            {postcodeErrors.map((postCodeError) => (
              <ROHelperText error helperText={postCodeError} />
            ))}
            <BaseAutocomplete
              id="uk-address-select"
              value={{ label: autocompleteAddress, value: autocompleteAddress }}
              placeholder="Please select address"
              disabled={addresses.length === 0}
              options={addressOptions.map((option) => ({ label: option.name, value: option.id }))}
              onChange={(option: SelectOptionType | string): void => {
                const value = typeof option === 'string' ? option : option?.value;
                const addressValue = addressOptions.filter((option) => option.id === value)?.[0];
                setAutocompleteAddress(addressValue?.name);
                handleSelect(value);
              }}
              fullWidth
              disableClearable
            />

            {loading && <div className="loading-message">{loadingMessage}</div>}
            {!loading && suggestionsDisplayed && (
              <div
                className={classnames('suggestions-container', {
                  'is-open': suggestions.length > 0,
                })}>
                {suggestions &&
                  suggestions.map(
                    (suggestion): JSX.Element => (
                      <div className="suggestion" {...getSuggestionItemProps(suggestion)}>
                        <div className="suggested-address-container">{suggestion.description}</div>
                      </div>
                    ),
                  )}
              </div>
            )}
            {!loading && hasError && (
              <Stack>
                {errorMessage?.map((errorMessage) => (
                  <ROHelperText error helperText={errorMessage} />
                ))}
              </Stack>
            )}
          </Stack>
        );
      }}
    </PlacesAutocomplete>
  );
};

export default AddressAutocomplete;
