import React from 'react';
import { preCtChartCheckContent } from '../../PreCtChartCheck/constants';
import { SideTextAreaField } from 'shared-components/components/FormikComponents';

interface Props {
  handleMutation: (field: string, value: string) => void;
  value: string;
  handleChange: (value: React.ChangeEvent<HTMLInputElement>) => void;
  name: string;
  isLocked: boolean;
}

const AdditionalNotes = ({ handleMutation, value, handleChange, name, isLocked }: Props): JSX.Element => {
  const {
    additionalNotes: { sectionTitle, helperText },
  } = preCtChartCheckContent;

  return (
    <SideTextAreaField
      name={name}
      value={value}
      onBlur={(e: React.ChangeEvent<any>) => handleMutation(name, e.target.innerText)}
      onChange={handleChange}
      title={sectionTitle}
      helperText={helperText}
      disabled={isLocked}
    />
  );
};

export default AdditionalNotes;
