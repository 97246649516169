// eslint-disable-next-line no-use-before-define
import React, { Component } from 'react';
import { Table, TableHead, TableBody, TableRow, styled } from '@mui/material';
import { StandardDialog } from 'shared-components/components';
import TableCell, { tableCellClasses } from '@mui/material/TableCell';
import { PatientBasic } from 'op-interfaces';

interface Props {
  isOpen: boolean;
  data?: { ukDedupSearch: PatientBasic[] };
  redirect?: string;
  dismissFunction: () => void;
  patient: PatientBasic;
}

const StyledTableCell = styled(TableCell)(({ theme }) => ({
  [`&.${tableCellClasses.head}`]: {
    backgroundColor: theme.palette.grey[300],
    color: theme.palette.common.black,
    fontWeight: 600,
  },
  [`&.${tableCellClasses.body}`]: {
    fontSize: 14,
  },
}));

const StyledTableRow = styled(TableRow)(({ theme }) => ({
  // hide last border
  '&:last-child td, &:last-child th': {
    border: 0,
  },
}));

class ModalSearchResults extends Component<Props> {
  public render(): JSX.Element {
    const { isOpen, dismissFunction, patient, data, redirect } = this.props;
    let searchResults: JSX.Element[] = [];

    if (data && data.ukDedupSearch && redirect) {
      searchResults = data.ukDedupSearch
        .filter((searchPatient: PatientBasic): boolean => {
          return searchPatient.id !== patient.id;
        })
        .map((currPatient: PatientBasic): JSX.Element => {
          const redirectTo = redirect.replace('temppatientid', currPatient.id);
          return (
            <StyledTableRow>
              <TableCell className={!currPatient.ida ? 'grey-text' : undefined}>
                {currPatient.ida || 'Not yet in Mosaiq'}
              </TableCell>
              <TableCell>{currPatient.firstName}</TableCell>
              <TableCell>{currPatient.lastName}</TableCell>
              <TableCell>{currPatient.dob}</TableCell>
              <TableCell>
                {[
                  currPatient.residentialAddressLine1,
                  currPatient.residentialAddressCity,
                  currPatient.residentialAddressPostCode,
                ]
                  .filter((item): boolean => !!item)
                  .join(', ')}
              </TableCell>
              <TableCell>
                <a href={redirectTo}>View patient</a>
              </TableCell>
            </StyledTableRow>
          );
        });
    }
    return (
      <StandardDialog
        open={isOpen}
        onClose={dismissFunction.bind(this)}
        maxWidth="lg"
        title="Potential duplicate patients found">
        <Table>
          <TableHead>
            <TableRow>
              <StyledTableCell>GC Number</StyledTableCell>
              <StyledTableCell>First name</StyledTableCell>
              <StyledTableCell>Last name</StyledTableCell>
              <StyledTableCell>Date of birth</StyledTableCell>
              <StyledTableCell>Address</StyledTableCell>
              <StyledTableCell>Link</StyledTableCell>
            </TableRow>
          </TableHead>
          <TableBody>{searchResults}</TableBody>
        </Table>
      </StandardDialog>
    );
  }
}

export default ModalSearchResults;
