// eslint-disable-next-line no-use-before-define
import React, { useEffect } from 'react';
import { useRouteMatch } from 'react-router-dom';
import { useQuery } from '@apollo/client';
import StagingPageLegacy from './StagingPageLegacy';
import StagingPage from './StagingPage';
import { GET_DIAGNOSIS } from '../Queries';
import { DiagnosisRouterParams } from '../Interface';
import { LoadingSpinner } from 'shared-components/components';
import { useErrorModalContext } from 'op-contexts';

interface DiagRouterProps {
  onDataRefresh: any;
}

const StagingPageRouter = (props: DiagRouterProps): JSX.Element => {
  const match = useRouteMatch<DiagnosisRouterParams>();
  const { diagnosisId } = match.params;
  const { setError } = useErrorModalContext();

  const {
    data: diagnosisData,
    loading: diagnosisLoading,
    error: diagnosisError,
  } = useQuery(GET_DIAGNOSIS, {
    fetchPolicy: 'network-only',
    variables: { diagnosisId: diagnosisId },
  });

  useEffect(() => {
    if (diagnosisError) return setError();
  }, [diagnosisError]);

  if (diagnosisLoading) {
    return <LoadingSpinner loadingText={'Loading Staging'} subtitle={'Please wait while we set things up for you'} />;
  } else if (diagnosisData?.diagnosis?.ajccMajorVersion > 7) {
    return <StagingPage onDataRefresh={props.onDataRefresh} />;
  } else {
    return <StagingPageLegacy onDataRefresh={props.onDataRefresh} />;
  }
};

export default StagingPageRouter;
