import React from 'react';
import { Field } from 'formik';
import { styled } from '@mui/system';

import { DTRatingItem } from 'op-classes';
import { InfoCard } from 'op-components';
import { HAIssueItem, InfoCardRowItem } from 'op-interfaces';
import { yesNoOptions } from 'op-utils';
import { FormRow } from 'shared-components/components';
import { ToggleButtonGroupField } from 'shared-components/components/FormikComponents';
import { getRemSize } from 'shared-components/StyledComponents/functions';

import {
  choiceToBooleanOptions,
  choiceToStringOptions,
  formatDtRating,
  getLastUpdateUser,
  transformEmptyString,
} from '../../helpers';
import { LastUpdateUserDetails } from '../../shared/interfaces';
import { content } from '../constants';
import { HealthAssessmentData } from '../interfaces/PreCtTriageInterfaces';

const InfoBox = styled('div')`
  width: 100%;
  border-radius: 4px;
  border: 1px solid ${(props) => props.theme.palette.grey[300]};
  border-top: 4px solid ${(props) => props.theme.palette.primary.main};
  padding: 10px 20px 16px;
  margin: 16px 0 20px;
`;

const InfoBoxTitle = styled('div')`
  font-size: ${getRemSize(14)};
  line-height: ${getRemSize(20)};
  font-weight: 700;
  margin-bottom: 8px;
`;
const ReviewBoxData = styled('div')`
  font-size: ${getRemSize(14)};
  padding-bottom: 8px;
`;

interface Props {
  handleMutation: (name: string, value: string) => void;
  healthAssessmentData: HealthAssessmentData;
  distressThermometerData: DTRatingItem;
  lastUpdateUserData: LastUpdateUserDetails[];
}

const Wellbeing = ({
  distressThermometerData,
  handleMutation,
  healthAssessmentData,
  lastUpdateUserData,
}: Props): JSX.Element => {
  const { fields, helperBox, tableTitles } = content.wellbeingScreen;
  const { hasPatientCarerConcern } = fields;
  const { doYouHaveWellbeingIssues, distressThermometerScore } = tableTitles;
  const distressRating = formatDtRating(distressThermometerData);

  let hasWellbeingIssue;
  let wellbeingIssue;
  const wellbeingStaticValues: InfoCardRowItem[] = [];

  if (healthAssessmentData.healthAssessment) {
    //@ts-ignore
    hasWellbeingIssue = choiceToBooleanOptions[String(healthAssessmentData.healthAssessment.wellbeingIssue)];
    wellbeingIssue = healthAssessmentData.healthAssessment.wellbeingIssue;

    if (hasWellbeingIssue) {
      wellbeingStaticValues.push({
        title: doYouHaveWellbeingIssues,
        //@ts-ignore
        value: choiceToStringOptions[String(wellbeingIssue)],
      });
    }
  }

  if (distressRating) {
    wellbeingStaticValues.push({
      title: distressThermometerScore,
      value: distressRating ? distressRating : 'N/A',
    });
  }

  let wellbeingIssueValues: InfoCardRowItem[] = [];

  if (hasWellbeingIssue === true) {
    const { wellbeingIssues } = healthAssessmentData.healthAssessment;
    const refData = healthAssessmentData.wellbeingIssueRefData;

    wellbeingIssueValues = wellbeingIssues.map((issue: HAIssueItem, index: number) => {
      const other = issue.other;
      const value = transformEmptyString(refData.find((ref) => ref.id === issue.value)?.name);
      const title = `Wellbeing or mental health issue ${index + 1}`;

      return {
        title: title,
        value: other ? other : value,
      };
    });
  }

  const isTableVisible = wellbeingStaticValues.length > 0 || wellbeingIssueValues.length > 0;
  const tableRows: InfoCardRowItem[][] = [[...wellbeingStaticValues, ...wellbeingIssueValues]];

  return (
    <>
      <FormRow fieldLabel={hasPatientCarerConcern} fieldName={'hasPatientCarerConcern'}>
        <Field
          name="hasPatientCarerConcern"
          component={ToggleButtonGroupField}
          label={hasPatientCarerConcern}
          options={yesNoOptions}
          handleMutation={handleMutation}
          lastUpdateUser={getLastUpdateUser(lastUpdateUserData, 'hasPatientCarerConcern')}
        />
        <InfoBox>
          <InfoBoxTitle>{helperBox.heading}</InfoBoxTitle>
          {helperBox.questions.map((item: string) => (
            <ReviewBoxData key={item}>{item}</ReviewBoxData>
          ))}
        </InfoBox>
      </FormRow>
      {isTableVisible && <InfoCard title="Wellbeing" subTitle="(Patient Reported)" rowDetails={tableRows} />}
    </>
  );
};

export default Wellbeing;
