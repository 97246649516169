import { Tooltip, Typography, Stack, useTheme } from '@mui/material';
import { CareplanStatusBubble } from 'op-components/SideNav/CareplanStatusBubble';

const renderSelectedDiagnosis = (diagnosisCodeSet: any): JSX.Element => {
  const theme = useTheme();
  return (
    <>
      <Typography paddingTop={2} variant="body1">
        Selected Diagnosis
      </Typography>
      {diagnosisCodeSet.map((diagnosis: any, index: number) => {
        const id = `${diagnosis.type}-diagnosis`;
        return (
          <Tooltip
            key={`${diagnosis.code}-${index}`}
            componentsProps={{
              tooltip: { sx: { minWidth: '250px', lineHeight: '1.2rem' } },
            }}
            title={
              <>
                <span>{diagnosis.type} diagnosis:</span>
                <br />
                <span>
                  {diagnosis.code} {diagnosis.name} {(diagnosis.description && diagnosis.description) || '-'}
                </span>
              </>
            }
            arrow>
            <Typography color={theme.palette.text.secondary} variant="body2" id={id} whiteSpace="break-spaces">
              {diagnosis.type}: {diagnosis.code} {diagnosis.name}
            </Typography>
          </Tooltip>
        );
      })}
    </>
  );
};

interface Props {
  status: string;
  statusText: string;
  diagnosisCodeSet: any;
}

const isCpot = (status: string) => status === 'cpot';

const ROStepperSummary = ({ status, statusText, diagnosisCodeSet }: Props): JSX.Element => {
  const { pathname } = window.location;
  const splitPathname = pathname.split('/');
  const dxIndex = splitPathname.includes('diagnosis') ? splitPathname.indexOf('diagnosis') + 1 : null;
  const diagnosisId = dxIndex ? splitPathname[dxIndex] : null;
  return (
    <Stack padding={2} paddingBottom={1}>
      <Typography paddingBottom={0.5} variant="body1">
        Careplan Status
      </Typography>
      <Stack direction="row" alignItems="center" padding={'2px 0'}>
        <CareplanStatusBubble status={status} />
        <Typography
          variant="body2"
          textTransform="capitalize"
          data-testid="careplan-status-text"
          sx={{ textWrap: 'wrap' }}>
          {isCpot(statusText) ? 'Change prescription on treatment' : statusText}
        </Typography>
      </Stack>
      {pathname.includes('diagnosis') &&
        diagnosisId !== 'create' &&
        diagnosisCodeSet &&
        renderSelectedDiagnosis(diagnosisCodeSet)}
    </Stack>
  );
};

export default ROStepperSummary;
