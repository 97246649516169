import { setYesNo } from '../../Common';
import { PrescriptionSite } from '../Interface';
import PrescriptionBolusFormV1, { validationV1 } from './PrescriptionBolusFormV1';
import PrescriptionBolusFormV2, { validationV2 } from './PrescriptionBolusFormV2';

export interface BolusFormProps {
  version: number;
  options: any;
  site: any;
  state: PrescriptionSite;
  setState: (v: any) => void;
  updatePrescription: (v: any) => void;
  updateData: () => void;
  isFieldValid: (field: any) => boolean;
  cpotReadOnly: boolean;
}

export interface BolusVersionProps extends BolusFormProps {
  getBolusValue: () => string;
}

export const getBolusValidationStructure = (version: number) => {
  if (version >= 2) {
    return validationV2;
  } else if (version >= 1) {
    return validationV1;
  }
  return [];
};

const BolusForm = (props: BolusFormProps) => {
  const state = props.state;

  const getBolusValue = (): string => {
    if (state.bolus && state.bolus.value) {
      return setYesNo(state.bolus.value);
    }
    if (typeof state.bolus == 'boolean') {
      return setYesNo(state.bolus);
    }
    return '';
  };

  const versionProps = {
    ...props,
    getBolusValue: getBolusValue,
  };

  if (props.version >= 2) {
    return <PrescriptionBolusFormV2 {...versionProps} />;
  } else if (props.version >= 1) {
    return <PrescriptionBolusFormV1 {...versionProps} />;
  }
  return <>No Version Provided</>;
};

export default BolusForm;
