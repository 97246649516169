// eslint-disable-next-line no-use-before-define
import React, { Component } from 'react';
import { WithApolloClient, withApollo } from '@apollo/client/react/hoc';
import { RouteComponentProps } from 'react-router-dom';
import RegistrationLogin from './RegistrationLogin';

import { LOGOUT_MUTATION } from 'op-graphql';
import { Logger } from 'shared-components/utils';
import { gql } from '@apollo/client';

const logger = new Logger('RegistrationLoginApollo');

interface Props extends WithApolloClient<{}>, RouteComponentProps {}

class RegistrationLoginApollo extends Component<Props> {
  private loggedOut = false;

  public componentDidMount(): void {
    const { client } = this.props;

    // Clear the cache when the patient URL is loaded
    client?.clearStore().then((): void => {
      client &&
        client.writeQuery({
          query: gql`
            query {
              pendingSaveCount
              saveErrorCount
              registrationPagesViewed
            }
          `,
          data: {
            pendingSaveCount: 0,
            saveErrorCount: 0,
            registrationPagesViewed: [],
          },
        });
    });

    // Check to determine if the log out needs to occur
    if (!this.loggedOut) {
      logger.debug('componentDidMount', 'Calling logout');
      client?.mutate({
        mutation: LOGOUT_MUTATION,
      });
      logger.debug('componentDidMount', 'Called logout');
      this.loggedOut = true;
    }
  }

  public render(): JSX.Element {
    return <RegistrationLogin />;
  }
}

export default withApollo<Props>(RegistrationLoginApollo);
