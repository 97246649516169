import validate from 'validate.js';
import moment from 'moment';

import { ListData } from 'shared-components/interfaces';
import { Logger } from 'shared-components/utils';
const logger = new Logger('Validate');

/*
This class is an extension of validate.js. Add any custom validators here and import this instead.
This will allow them to be used anywhere in the app
*/

/*
Validate if a given value is in a listdata item (checks on the id)
This is used for auto suggest
On the validator pass in listdata for the list to check
And message for the message to display if the item is not in the list
*/
// eslint-disable-next-line
validate.validators.list = function (value: string, options: any): string | null {
  // No error for empty field
  if (value === '' || value === null) {
    return null;
  }
  const listdata = options.listdata;
  const found = listdata.find((c: ListData): boolean => {
    return c.id === value;
  });
  if (found === undefined) {
    return options.message;
  }
  return null;
};

// eslint-disable-next-line
validate.validators.medicareNumber = function (value: string, options: any): string | null {
  // Don't validate for empty input
  if (value === '') {
    return null;
  }

  let isValid = false;
  let medicareNumber = value;
  medicareNumber = medicareNumber.replace(/ /g, '');

  // Check if the entire medicare number are digits only
  if (!medicareNumber.match(/^(\d)*$/)) {
    return options.message;
  }

  if (medicareNumber && medicareNumber.length === 10) {
    const matches = medicareNumber.match(/^(\d{8})(\d)/);

    if (!matches) {
      return options.message;
    }

    const base = matches[1];
    const checkDigit = matches[2];
    const weights = [1, 3, 7, 9, 1, 3, 7, 9];

    let sum = 0;
    for (let i = 0; i < weights.length; i++) {
      sum += parseInt(base[i], 10) * weights[i];
    }

    isValid = sum % 10 === parseInt(checkDigit, 10);
  }

  if (isValid) {
    return null;
  }
  return options.message;
};

validate.validators.patientDobRaw = function (value: string, options: any): string | null {
  const date = moment(value, 'DDMMYYYY');
  if (value) {
    if (value.length !== 8) {
      return options.message;
    }

    if (date.isValid()) {
      if (date.isAfter(moment())) {
        return options.afterMessage;
      }
      return null;
    } else {
      return options.message;
    }
  }
  return null;
};

validate.validators.cardExpiryMMYYYY = function (value: string, options: any): string | null {
  if (value) {
    if (options.inputDateformat === undefined) {
      logger.error('No inputFormat provided. Please provide it in the options, e.g. inputDateFormat "MMYYYY"');
    }

    // Ensure that the string has digits or dash separators
    if (!value.match(/^(\d|\-)*$/)) {
      return options.message;
    }

    // Check that the number of characters match the input value
    if (value.length !== options.inputDateformat.length) {
      return options.message;
    }

    const date = moment(value, options.inputDateformat);
    if (date.isValid()) {
      const endOfLastMonth = moment().subtract('1', 'months').endOf('month');
      if (moment(date).isSameOrBefore(endOfLastMonth)) {
        return options.message; // If date is before current month and current year it has expired
      } else {
        return null;
      }
    } else {
      return options.message;
    }
  }
  return null;
};

validate.validators.futureDateDDMMYYYY = function (value: string, options: any): string | null {
  if (value) {
    if (options.inputDateformat === undefined) {
      logger.error('No inputFormat provided. Please provide it in the options, e.g. inputDateFormat "MMYYYY"');
    }

    // Check that the number of characters match the input value
    if (value.length !== options.inputDateformat.length) {
      return options.message;
    }

    const date = moment(value, options.inputDateformat);

    if (date.isValid()) {
      const today = moment();
      if (moment(date).isAfter(today)) {
        return options.message; // If date is after today its invalid
      } else {
        return null;
      }
    } else {
      return options.message;
    }
  }
  return options.message;
};

validate.validators.futureDateMMYYYY = function (value: string, options: any): string | null {
  if (value) {
    if (options.inputDateformat === undefined) {
      logger.error('No inputFormat provided. Please provide it in the options, e.g. inputDateFormat "MMYYYY"');
    }
    logger.info('asd');

    // Ensure that the string has no minus
    if (!value.match(/^(\d)*$/)) {
      return options.message;
    }

    // Check that the number of characters match the input value
    if (value.length !== options.inputDateformat.length) {
      return options.message;
    }

    const date = moment(value, options.inputDateformat);

    if (date.isValid()) {
      const nextMonth = moment().add('1', 'months').startOf('month');
      if (moment(date).isSameOrAfter(nextMonth)) {
        return options.message; // If date is after current month and current year it's invalid
      } else {
        return null;
      }
    } else {
      return options.message;
    }
  }
  return null;
};

validate.validators.numeric = function (value: string, options: any): string | null {
  // If it's blank or non-existent, don't validate it
  if (value) {
    const intValue = parseInt(value);

    if (isNaN(intValue)) {
      return options.message;
    }

    if (options.greaterThan !== undefined) {
      if (intValue <= options.greaterThan) {
        return options.message;
      }
    }

    if (options.lessThan !== undefined) {
      if (intValue >= options.lessThan) {
        return options.message;
      }
    }
  }

  return null;
};

export default validate;
