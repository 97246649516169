export const STATIC_CONTENT = [
  {
    id: 1,
    questions: [
      'What is radiation therapy?',
      'Is radiation therapy the same as radiotherapy?',
      'How does radiation therapy work?',
      'How is radiation therapy given?',
      'How many treatments do I need to have and how often?',
      'Can radiation therapy treat my cancer?',
      'Do I need to have surgery or other treatments as well?',
      'Why is there a gap between having my planning scan and starting treatment?',
      "I've had cancer before - can this affect my ability to have treatment his time?",
      'Will this radiation therapy treatment cause cancer?',
      'Is radiation therapy painful?',
      'Will I feel anything?',
      'What are the side effects of radiation therapy?',
      'When will side effects start?',
      'How long will side effects last?',
      'Does it harm other organs?',
      "How do you know if it's been correctly delivered?",
      'How long does radiation therapy take?',
      'Can it be given with other treatments e.g. chemo/immunotherapy?',
      'Why do I need to have it daily?',
      'What are the steps involved in starting radiation therapy?',
      'Is radiation therapy safe?',
      'What happens when my radiation therapy treatment is over?',
      'I have diabetes/heart disease/other illness - can this affect treatment?',
    ],
    answers: [
      'Radiation therapy is the use of carefully targeted radiation e.g. x-ray beams that damages or destroys cancer cells to stop them from growing and multiplying.',
      'Yes',
      'Radiation damages or destroys the parts of cancer cells that make it grow and multiply in your DNA. It does this by breaking the DNA directly or making parts of the cell unstable which damages the DNA. Cancer cells are more sensitive to radiation therapy than healthy cells, this is why radiation therapy is an effective way to treat your cancer while minimising harm to your healthy tissue.',
      'Radiation therapy is most often given from outside the body (external beam) using a machine that directs the radiation beam at the cancer. It can also be delivered from inside the body (brachytherapy) using radioactive material placed inside your body next to the cancer.',
      'The length of a radiation treatment program depends on the type of radiation therapy chosen and the stage, size and location of the cancer. Some people require just a few sessions while others may require daily visits for a number of weeks. Breaking up the total prescribed dose into smaller daily doses allows maximum effect to cancer cells, whilst allowing healthy cells to recover.',
      'Radiation therapy is an effective treatment for cancer and an effective therapy for shrinking and managing the disease. Success rates will depend on the type of cancer, its stage and its location.',
      'Radiation therapy can be used on its own as the main treatment for your cancer, or it can be used  in combination with other treatments such as surgery, chemotherapy, immunotherapy or hormone therapy. Your doctor works closely with other specialists to determine the best plan of treatment for you and your type of cancer.',
      'During this time, your radiation therapy team uses the pictures taken during your planning scan to map how they will direct the radiation into the cancer cells and avoid other areas as much as possible.',
      'If you have had radiation therapy in the past, this may affect how much radiation therapy you can have now, if the treatments are in a similar area. Your doctor will look at your previous treatment record to determine if you can have further radiation therapy.',
      'The chance of developing a second cancer due to treatment with radiation therapy is very small and depends on factors such as the area and type of cancer being treated. The benefits of treating your cancer with radiation therapy if recommended, far outweigh this potential small risk.',
      'External beam radiation therapy is painless, you will not feel or see the radiation during treatment, it is similar to having an x-ray taken. Brachytherapy may have some discomfort when the implants are inserted, this is temporary and managed with simple pain relief medication. If you have pain related to your cancer or side effects of treatment, speak to your nurse or doctor who can help you.',
      'You will not feel or see the radiation during treatment, it is similar to having an x-ray taken',
      'New technology has significantly reduced the side effects of most radiation therapy treatment. The side effects that you may experience will depend on the area of the body you are having treated, the type of radiation therapy technique used and the dose prescribed.nMost side effects get better within a few weeks after treatment although some may last a bit longer. Your treating team will discuss your potential side effects with you, as well as how to manage them.',
      "This depends on many factors such as the type of radiation therapy technique, dose and treatment plan and if you're having any other treatments such as chemotherapy. Most short-term side effects start between the first and second week of treatment, however late effects will happen well after treatment has finished.",
      'Short-term side effects of treatment such as skin changes usually get better within a couple of weeks of finishing treatment. Treatment related fatigue can take a few months. Late effects may last years or be permanent.',
      "Modern treatment techniques are very individualised and we take every precaution available to avoid healthy tissue but there's a risk that some healthy tissue will be within the treatment area in order to treat the targeted area properly.nAny associated side effects are usually temporary, but there may be a risk of ongoing effects which your treatment team will help you manage with an ongoing care plan. It's important to raise any concerns with your Radiation Oncologist (RO) before commencing treatment.",
      'Each morning, our radiation therapists run a series of checks on the machine to verify that each treatment is administering the exact amount of radiation. The x-ray/ CT scan taken prior to each treatment ensure you are positioned correctly to receive treatment. The machine itself also has a series of safety checks to ensure that everything is running perfectly and our medical physicists run extensive checks on the machine monthly and annually.',
      'Each treatment session typically lasts from 10 minutes up to 45 minutes depending on the type of treatment.',
      'Yes, radiation therapy can be given at the same time as you are having other treatments such as chemotherapy or immunotherapy.',
      'The total dose of radiation therapy needed to treat your cancer would cause too much harm to your body if it were given in a single treatment. Giving small doses daily allows cancer cells to be treated and gives time for healthy cells to recover. It also increases the chances of radiation reaching the cancer cells when they are in their most sensitive state.',
      'If your Radiation Oncologist (RO) has recommended a course of radiation therapy, the next step is to have a x-ray/ CT scan that will be used to map how your individualised radiation therapy plan will be delivered. This can take up to a week for our radiation therapy team to finalise and can be longer for specialised treatments. Once the plan is complete and passes a series of quality and accuracy checks, you are ready to start. ',
      "Administering any amount of radiation involves a small amount of risk. Rigorous daily quality checks and safety measures ensures that if the machine detects even the slightest issue, it will stop the radiation until all issues have been resolved.nAdditionally, modern radiation therapy techniques allow for highly precise treatment; we take exceptional care in fine tuning the dose, to target the required areas while avoiding the healthy surrounding tissue as much as possible.nIt's important to note that any radiation is only present when the machine is on and may only be for 30 seconds of a 10 minute appointment.",
      "When you've finished your course of radiation therapy treatment, you may need to attend follow up appointments with your Radiation Oncologist (RO) and/or nurse. These appointments may be in person or through telehealth and will be organised in discussion with you towards the end of treatment.",
      'Your doctor and nurse will ask you about past medical history. Some conditions may affect how quickly your body recovers from any side effects you may develop, but it will not affect your radiation treatment.',
    ],
    category_id: 'radiotherapy',
  },
  {
    id: 2,
    questions: [
      'What if I have another appointment at the same time?',
      'What parking/transport options are available and what costs?',
      'Can appointments be at the same time every day?',
      'What happens if I miss a treatment?',
      'Can I eat before treatment?',
      'What should I do to prepare my skin before my treatment begins?',
      'Do I need to undress for treatment?',
      'What if I need to move or cough during treatment?',
      'Will I need a mask or other special equipment?',
      'Are we allowed to use electronic devices in the department? Eg. Phones',
      'Does it matter if I fall asleep?',
      'Do I have to take jewellery off?',
      'Do I get a cannula?',
      'Why do they take an x-ray or CT scan before my treatment each day?',
      'I just had a CT/PET scan, why do I need to have another one?',
    ],
    answers: [
      "Let our team know as early as possible of any other appointments so we can accommodate these in your radiation therapy schedule. Speak to your Centre's administration team as soon as you are aware of conflicting appointments.",
      'Each Centre has parking availability. As access to this differs for each Centre, we ask that you contact your treating Centre to find out what parking is available to you.',
      'We do our best to meet your needs with appointment preferences, however we cannot guarantee that appointments will be exactly the same every day.',
      'Radiation therapy is most effective when a course is completed as prescribed, however on occasion, they may be a need to miss or delay a treatment session. Whether or not this missed treatment needs to be made up another time depends on many factors and will be determined by your Radiation Oncologist (RO) in discussion with you.',
      'Patients having treatment to certain areas of the body e.g. stomach, may be asked not to eat or drink for some time before each treatment e.g. fasting. Your doctor or nurse will advise if you need to fast, otherwise you may eat and drink as normal before treatment.',
      'Taking care of your skin during radiation therapy treatment is important. We recommend the use of a pH neutral moisturiser and body wash. Please speak to your treating nurse for more guidance.',
      'Depending on the treatment area, you may need to remove certain items of clothing. This allows Radiation Therapists to see positioning marks that are used to line you up in the correct position.',
      "Involuntarily movements like coughing are unavoidable and are no cause for concern. As part of your treatment, your Radiation Therapists will position you in a way that's stable and comfortable to enable you to keep as still as possible.nIt's important not to move your arms to cover your mouth or sit up, but if you feel you need to sit up, or your coughing will not subside, simply wave your hand and your Radiation Therapists will pause treatment to enter the room to assist you. Once the coughing subsides, you will be re-set into position, and the treatment will recommence.",
      "For treatment to the head and neck you'll likely need a mask to help keep you in the correct position for your treatment. For most other treatments, special mats and cushions may be used for comfort and positioning. There are also other treatment techniques that require specialist equipment which your treatment team will explain, if they are relevant to your treatment.",
      'Yes, you are allowed to use electronic devices like your mobile phone in the waiting areas, just not while your treatment is being delivered.',
      'No, unless you move while asleep. Your Radiation Therapist team will monitor your treatment on a camera from outside the room. If you move they will pause treatment and reposition you before delivering any more radiation.',
      "You may be asked to take your jewellery off if it's in the area being treated or in the set up/measurement fields.",
      'You do not need a cannula for radiation therapy treatment. If you require Intravenous contrast for your planning scan, or if you are having Theranostics, you may require a cannula. Contrast is a fluid that is injected into a vein during your scan that makes it easier to see your blood vessels, internal organs and tissue.',
      "It's very important that you're positioned the same way each day for your treatment sessions to make sure that the correct area is treated. Your team of Radiation Therapists will use measurements to align you under the machine and confirm that you're in the correct position by taking a quick x-ray/CT scan while you're on the treatment machine.",
      'Scans that are done when investigating your diagnosis give your doctors information about your cancer to help decide how to treat it. The scans done as part of your radiation therapy planning help to guide the accurate and effective delivery of your radiation therapy treatment plan. In some cases, your doctor may ask for copies of your recent scans so we can compare the images to help to mark out the areas we want to treat.',
    ],
    category_id: 'attending-your-appointments',
  },
  {
    id: 3,
    questions: [
      'Can I drive during radiation therapy treatment?',
      'Can I be around pregnant women and children?',
      'Do I need to use contraception?',
      'Can I have flu or other vaccinations whilst having radiation therapy treatment?',
      'Can I go to work during my radiation therapy treatment?',
      'Will I lose my hair?',
      'Will treatment affect my sex life?',
      'Will treatment affect my fertility?',
      'Will I be radioactive?',
      'Will radiation therapy affect/reduce my immune system?',
      'What should I do to prepare my skin before my treatment begins?',
    ],
    answers: [
      "Yes, it's fine to drive after radiation therapy treatment. However if your doctor or nurse has told you not to drive due to a medical condition, potential side effects of treatment or medication, please follow their instruction.",
      'If you are being treated with external beam radiation therapy, it is safe to be around pregnant women and children as the radiation does not stay in your body. If you are being treated with low dose Brachytherapy (inside the body), you will be radioactive for a short while and may need to restrict some contact. Your treating team will let you know what restrictions you need to take and for how long.',
      'Yes, you should use contraception whilst undergoing cancer treatment as certain treatments can be harmful to an unborn baby. Your doctor or nurse can advise you on the most suitable type of contraception for you and how long you should use it for.',
      "You can have the flu vaccination whilst having radiation therapy treatment. Most other vaccinations such as tetanus are also okay to have during radiation therapy treatment. Some vaccinations may not be appropriate, especially if you're also having chemotherapy. Speak to your doctor if you're considering having vaccinations other than the flu vaccination during treatment.",
      'You can continue to work if you feel well enough. As your treatment course continues, you may find you feel more tired and lacking in energy than usual, you may need to consider adjusting your work schedule (where possible) at this time.',
      "Radiation therapy only affects the area being treated. As such, you'll only lose hair if it's in the treatment area. This loss may be temporary or permanent depending on the amount of radiation the area is receiving.",
      'Your desire to have sex may decrease during treatment due to factors such as fatigue or anxiety. Some side effects of treatment may also make sexual activity more uncomfortable. Your doctor or nurse can discuss this with you, as well as ways to manage it.',
      'Some cancer treatments can affect your fertility, making it difficult or not possible to get pregnant. Prior to starting any treatment, your doctor will talk to you about your fertility risks and if options such as egg or sperm freezing is an appropriate option for you.',
      "Patients having the most common type of radiation therapy, External Beam Radiation Therapy, will not be radioactive at any time therefore it's safe for you to be around loved ones, including pregnant women, children and the elderly.nFor patients having low dose rate (LDR) Radiation Therapy where permanent radioactive sources are inserted, there's usually a need to observe simple precautions for a few days or weeks after the procedure. Detailed instructions will be provided by your nurse or doctor if this is relevant to you.",
      'Radiation therapy has a limited risk to the immune system, the main risk is the potential introduction of germs through broken skin associated with skin reactions. Other treatment modalities such as chemotherapy can have a greater impact on your immune system and should be discussed with your doctor or nurse if you are having combined treatment.',
      'Taking care of your skin during radiation therapy treatment is important. We recommend the use of a pH neutral moisturiser and body wash. Please speak to your treating nurse for more guidance.',
    ],
    category_id: 'while-on-treatment',
  },
  {
    id: 4,
    questions: ['What about my physical and emotional wellbeing?', 'Who can I talk to if I am not coping?'],
    answers: [
      "It's important that you speak with us regarding any concerns you may have with your radiation treatment. We're here to help and listen and we can also offer referrals to other support groups.",
      'You can speak to your treating doctor, nurse or you can call the Cancer Council support hotline on 13 11 20.',
    ],
    category_id: 'support',
  },
];
