// eslint-disable-next-line no-use-before-define
import React from 'react';
import { RadioFieldTile } from 'shared-components/components';
import { SectionField } from 'shared-components/components/FormFields';
import { RadioOptions } from 'op-interfaces';

interface Props {
  name: string;
  fieldLabel: string;
  options: RadioOptions[];
  selectedOption: string;
  handleChange: (event: React.ChangeEvent<HTMLInputElement>) => void;
}

const RadioGroupField = ({ name, fieldLabel, options, selectedOption, handleChange }: Props): JSX.Element => {
  return (
    <div onChange={handleChange}>
      <SectionField htmlFor="radioGroupField" title={fieldLabel} isValid={selectedOption !== ''}>
        {options.map(
          (option): JSX.Element => (
            <RadioFieldTile
              keyId={`radio-${option.label}`}
              name={name}
              label={option.label}
              value={option.value}
              checked={selectedOption === option.value}
            />
          ),
        )}
      </SectionField>
    </div>
  );
};

export default RadioGroupField;
