// eslint-disable-next-line no-use-before-define
import React from 'react';
import BaseAutocomplete, { Props as BaseProps } from './BaseAutocomplete';
import FormRow, { Props as FormRowProps } from '../FormRow/FormRow';

interface Props extends BaseProps, FormRowProps {
  id: string;
  fieldlabel?: string;
  required?: boolean;
  requiredFieldColor?: string;
  label?: string;
  icon?: JSX.Element;
}

const ROAutocomplete = ({ sx, requiredFieldColor, tooltip, ...rest }: Props): JSX.Element => {
  return (
    <FormRow
      id={`row-${rest.id}`}
      fieldlabel={rest.fieldlabel}
      required={rest.required}
      requiredFieldColor={requiredFieldColor}
      tooltip={tooltip}>
      <BaseAutocomplete
        {...rest}
        sx={{
          ...{
            width: '100%',
            maxWidth: '400px',
            minWidth: '250px',
            alignItems: 'center',
            '& .MuiTextField-root': {
              verticalAlign: 'unset',
            },
          },
          ...sx,
        }}
        placeholder={rest.placeholder || 'Please select'}
        label={rest.label}
        icon={rest.icon}
      />
    </FormRow>
  );
};

export default ROAutocomplete;
