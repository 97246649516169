import { useEffect, useContext, useState, useLayoutEffect } from 'react';
import Dashboards from '@highcharts/dashboards';

import DashboardHeader from './DashboardHeader';
import { DashboardContext } from '../DashboardContext';
import ArrowForward from '@mui/icons-material/ArrowForward';
import { Typography, Stack } from '@mui/material';
import { useTheme } from '@mui/material/styles';

const Dashboard = (props: {
  title: string;
  metric: string;
  metricFunction: (data: any[]) => number;
  config: any;
  metricSuffix?: string;
  metricRound?: number;
}): JSX.Element => {
  const {
    filteredData,
    previousPeriodData,
    subChartFilteredData,
    dateFilterOverride,
    selectedPointIndex,
    dateRange,
    handleDateClick,
    onChartClick,
  } = useContext(DashboardContext);
  const { title, config, metric, metricSuffix = '', metricRound = 0, metricFunction } = props;

  const [previousPeriodMetric, setPreviousPeriodMetric] = useState<number>(0);
  const [metricChangeIndicator, setMetricChangeIndicator] = useState<string>('');
  const [seeMoreBottomLeft, setSeeMoreBottomLeft] = useState<boolean>(false);
  const [seeMoreBottomRight, setSeeMoreBottomRight] = useState<boolean>(false);
  const [seeMoreUpdated, setSeeMoreUpdated] = useState<boolean>(false);

  const theme = useTheme();

  useEffect(() => {
    Dashboards.board(
      'dashboard-container',
      config({
        theme: theme,
        data: filteredData,
        subChartData: subChartFilteredData,
        dateFilterOverride: dateFilterOverride,
        selectedIndex: selectedPointIndex,
        seeMoreUpdated: seeMoreUpdated,
        seeMoreBottomLeft: seeMoreBottomLeft,
        seeMoreBottomRight: seeMoreBottomRight,
        toggleSeeMoreBottomLeft: toggleSeeMoreBottomLeft,
        toggleSeeMoreBottomRight: toggleSeeMoreBottomRight,
        onPointClick: handleDateClick,
      }),
    );

    const current = metricFunction(filteredData);
    const previous = metricFunction(previousPeriodData);
    const percentChange = (Math.abs(current - previous) / previous) * 100;
    setPreviousPeriodMetric(percentChange);
    setMetricChangeIndicator(current > previous ? 'more' : 'less');

    setSeeMoreUpdated(false);
  }, [subChartFilteredData, seeMoreBottomLeft, seeMoreBottomRight]);

  const toggleSeeMoreBottomLeft = () => {
    setSeeMoreUpdated(true);
    setSeeMoreBottomLeft(!seeMoreBottomLeft);
    onChartClick();
  };

  const toggleSeeMoreBottomRight = () => {
    setSeeMoreUpdated(true);
    setSeeMoreBottomRight(!seeMoreBottomRight);
    onChartClick();
  };

  return (
    <div className="insights-dashboard-container">
      <DashboardHeader title={title} />
      <div className="dashboard-metrics">
        <Typography variant="h5">
          {metricFunction(filteredData).toFixed(metricRound)}
          {metricSuffix} {metric}
        </Typography>
        <Typography color={theme.palette.text.secondary} className="metrics-subtitle">
          {dateRange !== 'All time' && (
            <Stack alignItems="center" direction="row">
              <ArrowForward
                style={{
                  zIndex: 1,
                  marginRight: '5px',
                  transform: `rotate(${metricChangeIndicator === 'more' ? '-' : ''}90deg)`,
                  width: '16px',
                }}
              />
              {previousPeriodMetric.toFixed(metricRound)}% {metricChangeIndicator} than previous{' '}
              {dateRange.split('Past ')[1]}
            </Stack>
          )}
        </Typography>
      </div>
      <div id="dashboard-container" className="highcharts-light" />
    </div>
  );
};

export default Dashboard;
