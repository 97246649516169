import { useMutation, useQuery } from '@apollo/client';
import React, { useEffect, useState, useContext } from 'react';
import { useRouteMatch } from 'react-router';
import { Slide, toast } from 'react-toastify';
import { Banner as UnsubmittedBanner, LoadingSpinner } from 'shared-components/components';
import { SideDrawer, SideDrawerToggle } from 'shared-components/components/SideDrawer';
import { ErrorCircleFill } from 'shared-components/images';
import { supportedSize } from 'shared-components/StyledComponents/breakpoints';
import { usePreventUnload, useWindowSize } from 'shared-components/utils/CustomHooks';
import { styled } from '@mui/system';
import { ADD_PATIENT_NOTE, UPDATE_PATIENT_NOTE } from '../PatientSummary/PatientProfileQueries';
import { UserContext } from 'op-contexts';
import { NoteType, GET_PATIENT_NOTES, PatientNote, useNoteTypes, usePatientNotes } from 'op-components/RO/Notes/Notes';
import { ToastNotificationMessage } from '../PatientSummary/CompactNotes/CompactNotes';
import { GET_PRACTITIONER } from 'op-graphql/queries';
import { TextareaAutosize, Typography, Button } from '@mui/material';
import { Close as CloseIcon, EditOutlined as EditOutlinedIcon } from '@mui/icons-material';
import { CurrentAppConfig } from 'op-pages/RO/Careplan/AppConfig';
interface Props {
  className?: string;
  showToast?: boolean;
}

const TopContainer = styled('div')`
  flex-grow: 1;
  padding: 16px;
  display: flex;
  flex-direction: column;
`;

const AddNotesTitle = styled('h3')`
  font-size: 18px;
  color: ${(props) => props.theme.palette.primary.dark};
  margin-bottom: 12px;
`;

const NoteTypesContainer = styled('div')`
  display: flex;
`;

const NoteTypeButtonContainer = styled('div')`
  display: flex;
  flex-direction: column;
  margin: 0 16px 16px 0;
`;

const NoteTypeButton = styled('button')`
  border: none;
  background: none;
  padding: 0;
  margin-bottom: 4px;
`;

const NoteTypeText = styled('span')`
  text-transform: uppercase;
  margin-bottom: 4px;
  font-size: 16px;
`;

const ActiveNoteType = styled('div')`
  width: 100%;
  height: 3px;
  background-color: ${(props) => props.theme.palette.primary.main};
`;

const AddNoteButtonContainer = styled('div')`
  border-top: 2px solid ${(props) => props.theme.palette.primary.dark};
  display: flex;
  justify-content: flex-end;
  padding: 12px 9px 12px 16px;
`;

const WarningIcon = styled(ErrorCircleFill)`
  position: absolute;
  left: -10px;
  top: -10px;
`;

const AddNotes = (props: Props): JSX.Element => {
  const { className, showToast } = props;
  const match = useRouteMatch<any>();
  const { id: patientId } = match.params;
  const noteTypes = useNoteTypes(patientId);
  const { notes, isLoading, hasErrors } = usePatientNotes(patientId);
  const [selectedNoteType, setSelectedNoteType] = useState<NoteType>();
  const [addingNote, setAddingNote] = useState(false);
  const [timeoutId, setTimeoutId] = useState<any>(null);
  const [draftNoteText, setDraftNoteText] = useState('');
  const [draftNote, setDraftNote] = useState<any>();
  const [open, setOpen] = useState(false);
  const { state } = useContext(UserContext);
  const windowSize = useWindowSize();
  const isSmallDisplay = windowSize.width < supportedSize.desktop;

  const { data } = useQuery(GET_PRACTITIONER, {
    variables: { patientId },
  });
  const practitionerId = data?.practitioner?.id;
  const [addNoteMutation] = useMutation(ADD_PATIENT_NOTE, {
    refetchQueries: [{ query: GET_PATIENT_NOTES, variables: { patientId } }],
    onCompleted: () => {
      setAddingNote(false);
    },
  });
  const [updateNoteMutation] = useMutation(UPDATE_PATIENT_NOTE, {
    refetchQueries: [{ query: GET_PATIENT_NOTES, variables: { patientId } }],
  });

  const addNoteHandler = (message: string, type: string, id: string | null) => {
    setAddingNote(true);
    addNoteMutation({ variables: { patientId, message, messageType: type, noteId: id } });
  };

  const updateNoteHandler = (message: string, type: string, id: string | null) => {
    updateNoteMutation({ variables: { patientId, message, messageType: type, noteId: id } });
  };

  useEffect(() => {
    if (noteTypes) {
      setSelectedNoteType(noteTypes[0]);
    }
  }, [noteTypes]);
  useEffect(() => {
    if (notes && notes.length) {
      const filteredDraft = notes.filter(
        (note: PatientNote) => note.isDraft && note.createdBy?.id === practitionerId,
      )[0];
      setDraftNote(filteredDraft || null);
      setDraftNoteText(filteredDraft?.formattedMessage || '');
      if (filteredDraft) {
        setSelectedNoteType(noteTypes.filter((type: any) => type?.name === filteredDraft?.messageType)[0]);
      } else setSelectedNoteType(noteTypes[0]);
    }
  }, [notes]);

  const handleBlur = (e: React.FocusEvent<HTMLTextAreaElement>) => {
    const { relatedTarget }: any = e;
    const idsToBlockUpdate = ['add-note-button', 'note-type-button'];
    if (!idsToBlockUpdate.includes(relatedTarget?.id) && selectedNoteType && !addingNote) {
      updateNoteHandler(e.target.value, selectedNoteType.name, draftNote?.id);
    }
  };
  const disableNotes = !CurrentAppConfig.Notes.EnableSubmit;

  usePreventUnload(draftNote && draftNoteText !== draftNote?.formattedMessage);
  if (isLoading) return <LoadingSpinner />;
  if (hasErrors) return <div>Error</div>;
  return (
    <SideDrawer
      isOpen={open}
      className={className}
      showToggleBtn={noteTypes.length > 0 && isSmallDisplay}
      toggleBtn={
        <SideDrawerToggle
          data-test-id="add-notes-toggle"
          onClick={() => setOpen(!open)}
          primaryIcon={open ? <CloseIcon htmlColor="white" /> : <EditOutlinedIcon htmlColor="white" />}
          showStatusIcon={draftNote && draftNoteText.length > 0 && !open}
          statusIcon={<WarningIcon />}
          text={open ? 'Close' : 'Add Notes'}
        />
      }>
      {draftNoteText && (
        <UnsubmittedBanner
          type="info"
          text=""
          subtext="This note is not submitted, please add note"
          testId="unsubmitted-notes"
        />
      )}
      <TopContainer>
        <Typography paddingBottom={1} variant="h6">
          Add Notes
        </Typography>
        <NoteTypesContainer>
          {[...noteTypes]
            .sort((a: any, b: any) => {
              return a.listWeight - b.listWeight;
            })
            .map((noteType: NoteType) => {
              const { alias = null, name } = noteType;
              return (
                <NoteTypeButtonContainer key={name}>
                  <NoteTypeButton
                    type="button"
                    id="note-type-button"
                    onClick={() => {
                      setSelectedNoteType(noteType);
                      if (draftNoteText) {
                        updateNoteHandler(draftNoteText, name, draftNote?.id);
                      }
                    }}
                    key={noteType?.id}>
                    <NoteTypeText>{alias ? alias : name}</NoteTypeText>
                  </NoteTypeButton>
                  {selectedNoteType?.alias === noteType.alias && <ActiveNoteType />}
                </NoteTypeButtonContainer>
              );
            })}
        </NoteTypesContainer>
        <TextareaAutosize
          id="add-note-textarea"
          placeholder={disableNotes ? 'Please add notes in your EMR.' : 'Type your notes here...'}
          disabled={disableNotes}
          value={draftNoteText}
          onChange={(e) => setDraftNoteText(e.target.value)}
          onBlur={(e) => {
            // The timeout is here to prevent a race condition between the update and add notes
            // if a user clicks out of the text box straight to the add note button
            const timeout = setTimeout(() => handleBlur(e), 400);
            setTimeoutId(timeout);
          }}
          style={{
            fontFamily: 'unset',
            border: 'unset',
            padding: 0,
            maxHeight: 'calc(100vh - 460px)',
          }}
        />
      </TopContainer>
      <AddNoteButtonContainer>
        <Button
          data-testid="add-note-button"
          id="add-note-button"
          variant="contained"
          disabled={!draftNoteText || addingNote || disableNotes}
          onClick={() => {
            if (selectedNoteType) {
              clearTimeout(timeoutId);
              setTimeoutId(null);
              setDraftNoteText('');
              addNoteHandler(draftNoteText, selectedNoteType.name, draftNote?.id);

              state?.primaryRole === 'ro' &&
                showToast &&
                toast.dark(<ToastNotificationMessage />, {
                  position: 'bottom-right',
                  autoClose: 5000,
                  hideProgressBar: true,
                  closeOnClick: true,
                  pauseOnHover: true,
                  draggable: true,
                  transition: Slide,
                  progress: undefined,
                });
            }
          }}>
          Add note
        </Button>
      </AddNoteButtonContainer>
    </SideDrawer>
  );
};

export default AddNotes;
