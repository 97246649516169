import React, { useState } from 'react';

import { RegistrationContext } from 'op-contexts';

interface Props {
  children: React.ReactNode;
}

const RegistrationContextProvider: React.FC<Props> = (props): JSX.Element => {
  const [addressPlaceId, setAddressPlaceId] = useState('');
  const [registrationSummaryVisited, setRegistrationSummaryVisited] = useState(false);
  const [haSummaryVisited, setHaSummaryVisited] = useState(false);
  const [gpLookupUsed, setGpLookupUsed] = useState(false);
  const [referrerLookupUsed, setReferrerLookupUsed] = useState(false);

  return (
    <RegistrationContext.Provider
      value={{
        addressPlaceId,
        setAddressPlaceId,
        registrationSummaryVisited,
        setRegistrationSummaryVisited,
        haSummaryVisited,
        setHaSummaryVisited,
        gpLookupUsed,
        setGpLookupUsed,
        referrerLookupUsed,
        setReferrerLookupUsed,
      }}>
      {props.children}
    </RegistrationContext.Provider>
  );
};

export default RegistrationContextProvider;
