import { gql } from '@apollo/client';

export const COUNTRY_LIST = gql`
  query CountryList {
    data: listData(category: "countryOfBirth") {
      id
      name
    }
  }
`;

export const AUSTRALIA_STATES_LIST = gql`
  query AustraliaStatesList {
    data: listData(category: "australiaStates") {
      id
      name
    }
  }
`;

export const UK_COUNTIES_LIST = gql`
  query UKCountiesList {
    data: listData(category: "ukCounties") {
      id
      name
    }
  }
`;
