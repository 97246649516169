// eslint-disable-next-line no-use-before-define
import classnames from 'classnames';
import { Link } from 'react-router-dom';
import './ContentTile.scss';

interface Props {
  title: string;
  url: string;
  image: string;
  icon?: string;
  testId?: string;
  titleClass?: string;
}

const ContentTile = (props: Props): JSX.Element => {
  const { title, url, image, icon, testId, titleClass } = props;
  const classTitle = title.toLowerCase();

  const imageStyle = {
    backgroundImage: `url(${image})`,
    backgroundPosition: 'center center',
    backgroundSize: 'cover',
    backgroundRepeat: 'no-repeat',
  };

  return (
    <Link to={url} className="tile-container" data-testid={testId}>
      <div className="tile-image" style={imageStyle} />
      <div className="tile-title-container">
        {icon && <img src={icon} alt={`${classTitle}-icon`} className="tile-icon" />}
        <div className={classnames('title', { [`${titleClass}`]: titleClass })}>{title}</div>
      </div>
    </Link>
  );
};

export default ContentTile;
