// eslint-disable-next-line no-use-before-define
import React, { useState, useContext } from 'react';
import { gql, useQuery, useMutation } from '@apollo/client';
import { RouteComponentProps, withRouter } from 'react-router-dom';
import { StepperLink } from 'shared-components/interfaces';
import { Logger } from 'shared-components/utils';
import { FormContext } from '../../../pages/OP/PatientNavigation/context';
import { RegistrationContext } from 'op-contexts';
import { FormStatus } from 'op-enums';

import HANavigator from './HANavigator';
import { FeatureOption } from 'op-interfaces/PatientInterfaces';

const HA_SUBMISSION_MUTATION = gql`
  mutation CreateHASubmission($haId: ID!) {
    createHASubmission(haId: $haId) {
      submissionCreated
      submission {
        id
        pdf
      }
    }
  }
`;

const HEALTH_ASSESSMENT_AND_USER_DETAILS = gql`
  query UserDetailsAndHealthAssessment($patientId: ID!) {
    healthAssessment(id: $patientId) {
      id
    }
    patient(id: $patientId) {
      id
      regFormStatus
      userProfile {
        id
        showRegistration
      }
    }
    user {
      id
      isPso
    }
    profile(id: $patientId) {
      id
      registrationAccessType
    }

    featureOptions {
      id
      name
      description
      active
    }
  }
`;

const GET_PDF_URL = gql`
  mutation GenerateHAPDF($haId: ID!) {
    generateHAPdf(haId: $haId) {
      pdfLink
    }
  }
`;

interface UserDetailsQueryData {
  user: {
    id: string;
    isPso: boolean;
  };
  patient: {
    regFormStatus: string;
    userProfile: {
      showRegistration: boolean;
    };
  };
  healthAssessment: {
    id: string;
  };
  profile: {
    id: string;
    registrationAccessType: string;
  };

  featureOptions: FeatureOption[];
}

interface Props extends RouteComponentProps<{ patientId: string }> {
  links: StepperLink[];
  generateURL?: () => void;
}

const HANavigatorApollo = (props: Props) => {
  const [haId, setHaId] = useState('');
  const [loadingMutation, setLoadingMutation] = useState(false);
  const [mutationCalled, setMutationCalled] = useState(false);

  const { formStatusDetails, setFormStatusDetails } = useContext(FormContext);

  const { data } = useQuery<UserDetailsQueryData>(HEALTH_ASSESSMENT_AND_USER_DETAILS, {
    variables: { patientId: props.match.params.patientId },
    fetchPolicy: 'network-only',
  });
  const [submitHealthAssessment] = useMutation(HA_SUBMISSION_MUTATION);
  const [getPdfUr] = useMutation(GET_PDF_URL);

  const { links } = props;

  const generatePDFURL = (): void => {
    if (!loadingMutation && !mutationCalled) {
      setLoadingMutation(true);
      getPdfUr({ variables: { haId } }).then((response): void => {
        const url = `${response.data.generateHAPdf.pdfLink}`;
        const win = window.open(url, '_blank');
        if (win != null) {
          win.focus();
        }
        setLoadingMutation(false);
        setMutationCalled(false);
      });
    }
  };

  const submitHA = (): void => {
    if (!loadingMutation && !mutationCalled) {
      setLoadingMutation(true);
      submitHealthAssessment({
        variables: { haId },
      }).then((response): void => {
        setLoadingMutation(false);
        setMutationCalled(true);
        //@ts-ignore
        setFormStatusDetails({
          //@ts-ignore
          ...formStatusDetails,
          healthAssessment: { status: 'filterSubmittedToMosaiq' },
        });
      });
    }
  };

  if (data && data.healthAssessment && haId !== data.healthAssessment.id) {
    setHaId(data.healthAssessment.id);
  }
  const regFormStatus = data?.patient?.regFormStatus || '';
  const isInClinic = data?.profile?.registrationAccessType === 'inClinic';
  const showRegistration = data?.patient?.userProfile?.showRegistration;

  const activeNewRegoFeature = data?.featureOptions.find(
    (featureOption: FeatureOption) => featureOption.name === 'NewAusRego' && featureOption.active,
  );
  const redirectToRegistraton =
    [FormStatus.REG_SUBMITTED as string, FormStatus.REG_REVIEW_REQUIRED as string].includes(regFormStatus) ||
    !showRegistration;
  return (
    <RegistrationContext.Consumer>
      {(registrationContext) => (
        <HANavigator
          links={links}
          redirectToRegistraton={redirectToRegistraton}
          registrationContext={registrationContext}
          loading={loadingMutation}
          submitFunction={submitHA}
          submitCalled={mutationCalled}
          isInClinic={isInClinic}
          isPso={data?.user?.isPso || false}
          generateURL={generatePDFURL}
          showNewRego={!!activeNewRegoFeature}
        />
      )}
    </RegistrationContext.Consumer>
  );
};

const routeComponent = withRouter(HANavigatorApollo);
export default routeComponent;
