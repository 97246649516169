import React, { useState, useEffect, useMemo } from 'react';
import { useQuery } from '@apollo/client';
import { styled } from '@mui/system';
import { useRouteMatch } from 'react-router';
import { LoadingSpinner } from 'shared-components/components';
import { usePatientNotes, PatientNote } from 'op-components/RO/Notes/Notes';
import NotesFilter from 'op-components/RO/Notes/NotesFilter';
import { GET_PRACTITIONER } from 'op-graphql/queries';
import { CurrentAppConfig } from 'op-pages/RO/Careplan/AppConfig';
import { formatNoteCreationDate } from 'op-utils/helpers';
import { sanitizeNote } from './sanitizeNote';
import { Typography, Stack, useTheme } from '@mui/material';

const Container = styled('div')`
  height: 100%;
  display: flex;
  flex-direction: column;
  overflow: auto;
  max-height: calc(100vh - 255px);
`;

const AllNotesContainer = styled('div')`
  border: 1px solid ${(props) => props.theme.palette.grey[300]};
  border-radius: 4px;
  flex-grow: 1;
  overflow: hidden;
  margin-top: 12px;
  display: flex;
`;

const NotesListContainer = styled('div')`
  display: flex;
  flex-direction: column;
  width: 25%;
  border-right: 1px solid ${(props) => props.theme.palette.grey[300]};
  max-height: 100%;
  overflow-y: auto;
`;

const NotesPreviewContainer = styled('div')`
  display: flex;
  flex-direction: column;
  width: 75%;
  overflow-y: auto;
`;

const NoteText = styled('div')<{ $format: boolean }>`
  line-height: 24px;
  font-size: 16px;
  white-space: ${({ $format }: { $format: boolean }) => ($format ? 'normal' : 'pre-wrap')};
  color: ${(props) => props.theme.palette.text.primary};
  word-break: break-word;
  font,
  *,
`;

const NoNotes = styled('div')`
  padding: 16px;
`;
export const formatNoteDateTime = (note: PatientNote, timezone: string, format: string): string =>
  formatNoteCreationDate(note, timezone, format);

const DisplayNotes = (): JSX.Element => {
  const theme = useTheme();
  const match = useRouteMatch<any>();
  const { id: patientId } = match.params;
  const [selectedNoteIndex, setSelectedNoteIndex] = useState<number | undefined>();
  const { notes, filteredNotes, filterNoteTypes, setFilterNoteTypes, isLoading, hasErrors } =
    usePatientNotes(patientId);

  const { data: pracData } = useQuery(GET_PRACTITIONER, {
    variables: { patientId },
    skip: !patientId,
  });
  const practitionerTimezone = useMemo(
    () => pracData?.practitioner?.timezone || CurrentAppConfig.DefaultTimezone,
    [pracData?.practitioner?.timezone],
  );

  useEffect(() => {
    const selectedNote = document.getElementById(`note-preview-${selectedNoteIndex}`);
    if (selectedNote) {
      selectedNote.scrollIntoView({ behavior: 'smooth' });
      (selectedNote as HTMLElement).focus({ preventScroll: true });
    }
  }, [selectedNoteIndex]);

  if (isLoading && !notes) return <LoadingSpinner loadingText={'Loading notes'} />;
  if (hasErrors) return <div>Error</div>;
  const NoNotesFound = () => <NoNotes>No notes found.</NoNotes>;
  return (
    <Container>
      <Typography paddingBottom={1} variant="h6">
        Notes
      </Typography>
      <Stack direction="row" gap={1} alignItems="center" width="25%">
        <Typography variant="subtitle1">Type:</Typography>
        <NotesFilter noteTypes={filterNoteTypes} setFilterNoteTypes={setFilterNoteTypes} />
      </Stack>
      <AllNotesContainer>
        <NotesListContainer>
          {filteredNotes.length ? (
            filteredNotes.map((note: PatientNote, index: number) => (
              <Stack
                padding={1}
                key={note.id}
                data-test-id={`display-note-${index}`}
                borderBottom={'1px solid'}
                borderColor={theme.palette.grey[300]}
                sx={{
                  backgroundColor: index === selectedNoteIndex ? theme.palette.action.selected : 'inherit',
                  cursor: 'pointer',
                  '&:hover': {
                    backgroundColor:
                      index === selectedNoteIndex ? theme.palette.action.selected : theme.palette.action.hover,
                  },
                }}
                onClick={() => setSelectedNoteIndex(index)}>
                <Typography variant="body1">{note.messageType}</Typography>
                <Typography variant="body2" color={theme.palette.text.secondary}>
                  {formatNoteDateTime(note, practitionerTimezone, 'L')}
                </Typography>
              </Stack>
            ))
          ) : (
            <NoNotesFound />
          )}
        </NotesListContainer>
        <NotesPreviewContainer>
          {filteredNotes.length ? (
            filteredNotes.map((note: PatientNote, index: number) => (
              <Stack
                paddingX={2}
                paddingY={1}
                borderBottom={'1px solid'}
                borderColor={theme.palette.grey[300]}
                key={note.id}
                id={`note-preview-${index}`}>
                <Stack justifyContent="space-between" alignItems="center" direction="row" marginBottom={1}>
                  <Stack>
                    <Typography variant="subtitle1">
                      {note.createdBy?.name} {note.practitionerQualification && `(${note.practitionerQualification})`}
                    </Typography>
                    {note.messageTypeAlias && (
                      <Typography color={theme.palette.text.secondary} variant="body2">
                        {note.messageTypeAlias}
                      </Typography>
                    )}
                  </Stack>
                  <Typography color={theme.palette.text.secondary} variant="body2">
                    {formatNoteDateTime(note, practitionerTimezone, 'L hh:mmA')}
                  </Typography>
                </Stack>
                <NoteText
                  $format={note.formattedMessage.startsWith('<!DOCTYPE html')}
                  dangerouslySetInnerHTML={sanitizeNote(note.formattedMessage)}
                />
              </Stack>
            ))
          ) : (
            <NoNotesFound />
          )}
        </NotesPreviewContainer>
      </AllNotesContainer>
    </Container>
  );
};

export default DisplayNotes;
