export const logPage = (startTime: number, eventData: any, logEvent: any, logTime = true): void => {
  const currentTime = new Date().getTime();
  const timeOnPage = (currentTime - startTime) / 1000;
  let pageData = { ...eventData };
  if (logTime)
    // To prevent unit test failures on mock functions due to arbitrary timeOnPage value
    pageData = { ...pageData, timeOnPage };
  logEvent({ variables: { data: JSON.stringify(pageData) } });
};

export enum ELASTIC_SEARCH_EVENTS {
  DUPLICATE_TRIGGERED = 'create_patient_duplicate_triggered',
  DUPLICATE_IGNORED = 'create_patient_duplicate_ignored',
  DUPLICATE_SEARCH_TIME = 'create_patient_duplicate_search_time',
  SUBMIT = 'create_patient_submit',
  START = 'create_patient_start',
}

export enum PRACTITIONER_LOG_EVENTS {
  // Exact record found
  PRACTITIONER_EXACT_MATCH = 'practitioner_exact_match',
  // No Exact record found
  PRACTITIONER_SELECTED = 'practitioner_selected',
  PRACTITIONER_NOT_FOUND = 'practitioner_not_found',
  PRACTITIONER_OVERRIDE_ENTRY = 'practitioner_override_entry',
  PRACTITIONER_SEARCHED = 'practitioner_searched',
  PRACTITIONER_RESULTS = 'practitioner_results',
}

export enum POPOVER_LOG_EVENTS {
  DISPLAYED = 'popover_displayed',
  NEXT_STEP = 'popover_next_step',
  COMPLETE = 'popover_complete',
  EXIT = 'popover_exit',
}

export enum BUZZ_WIDGET_POPOVER_LOG_EVENTS {
  CLICKED_LATEST_UPDATE = 'buzz_onboarding_widget_latest_update',
  CLICKED_GOT_IT = 'buzz_onboarding_widget_clicked_got_it',
}

export enum BUZZ_DASHBOARD_POPOVER_LOG_EVENTS {
  CLICKED_LATEST_UPDATE = 'buzz_onboarding_dashboard_latest_update',
  CLICKED_GOT_IT = 'buzz_onboarding_dashboard_clicked_got_it',
  CLICKED_BUZZ_SMS_TAB = 'buzz_onboarding_dashboard_tab',
}
