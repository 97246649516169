// eslint-disable-next-line no-use-before-define
import React, { Component } from 'react';
import validate from 'validate.js';

import Markdown from 'markdown-to-jsx';

import { PatientInformationNotice } from 'op-interfaces';
import { RegistrationContainer } from 'op-components';

import { ListData } from 'shared-components/interfaces';
import { SavingStatus } from 'shared-components/enums';
import { Checkbox } from 'shared-components/components/FormFields';

import './RegistrationInformationNotice.scss';

const FIELD_INFO = {
  INFORMATION_NOTICE: {
    NAME: 'infoNotice',
  },
  I_AGREE: {
    KEY: 'informationNoticeAccepted',
    MISSING_INPUT_ERROR: 'Information notice must be read and acknowledged to process registration',
    NAME: 'agreeToInfoNotice',
    TITLE:
      'I confirm that I understand what I have read and consent to the collection, use and disclosure of my personal information (including health and other sensitive information) for the purposes described in this notice.',
    WITHDRAWAL: 'You are free to withdraw, alter or restrict your consent at any time by contacting us.',
  },
  ALLIED_HEALTH: {
    KEY: 'informationNoticeAlliedHealthReceiveUpdates',
    NAME: 'alliedHealthRecieveUpdates',
    INSTRUCTION_TEXT: 'Allied health and well being services and information',
    TITLE:
      'During or after your time as a patient of GenesisCare, you may wish to consider accessing certain allied health or wellbeing services or information. GenesisCare (or third parties with whom we work) may offer or provide access to such services or information relevant to you. If you are interested in hearing more about allied health and wellbeing initiatives, please tick here.',
  },
};

const VALIDATION_FIELDS = [FIELD_INFO.I_AGREE.KEY];
const FORM_HEADING = 'Information notice';
const OPTIONAL_TEXT = '(optional)';

interface ValidationObject {
  [key: string]: string[];
  informationNoticeAccepted: string[];
}

export interface InformationNoticeContent {
  id: string;
  informationNotice: string;
}

interface Props {
  information: PatientInformationNotice;
  informationNoticeContent: InformationNoticeContent;
  genderRefData: ListData[];
  autosave: (patient: PatientInformationNotice, key: string, value: string | boolean, type: string) => Promise<void>;
  saveStatus: SavingStatus;
  validateOnLoad: boolean;
  isPso: boolean;
}

interface State {
  viewed: Set<string>;
}

class RegistrationInformationNotice extends Component<Props, State> {
  public constructor(props: Props) {
    super(props);
    this.state = {
      viewed: new Set(),
    };
  }

  public static getDerivedStateFromProps(props: Props, state: State): State {
    if (props.validateOnLoad && props.information.lock && !props.information.lock.readOnly) {
      const viewed = new Set(VALIDATION_FIELDS);
      return { viewed: viewed };
    }

    return state;
  }

  public render(): JSX.Element {
    const { information, genderRefData, isPso, saveStatus } = this.props;
    return (
      <RegistrationContainer patient={information} genderRefData={genderRefData} isPso={isPso} saveStatus={saveStatus}>
        {this.renderContents()}
      </RegistrationContainer>
    );
  }

  private renderAlliedHealthServiceInfoCheckbox = (information: PatientInformationNotice) => {
    return (
      <>
        <div className="gc-foundation-container">
          <div id="allied-health-instruction">{FIELD_INFO.ALLIED_HEALTH.INSTRUCTION_TEXT + ' ' + OPTIONAL_TEXT}</div>
          <Checkbox
            disabled={information.lock?.readOnly}
            inputName={FIELD_INFO.ALLIED_HEALTH.NAME}
            inputLabel={FIELD_INFO.ALLIED_HEALTH.TITLE}
            isChecked={!!information?.informationNoticeAlliedHealthReceiveUpdates}
            onChange={(isChecked): void => {
              this.autosave(information, FIELD_INFO.ALLIED_HEALTH.KEY, isChecked, 'Boolean');
            }}
          />
        </div>
      </>
    );
  };

  private renderContents = (): JSX.Element => {
    const { information, informationNoticeContent } = this.props;

    const validationObject: ValidationObject = this.validateObject(information);
    return (
      <div className="form-page form-registration-information-notice">
        <form className="form-container">
          <div className="form-heading">{FORM_HEADING}</div>
          <div id={FIELD_INFO.INFORMATION_NOTICE.NAME}>
            <Markdown>{informationNoticeContent.informationNotice}</Markdown>
          </div>
          <Checkbox
            disabled={information.lock && information.lock.readOnly}
            inputName={FIELD_INFO.I_AGREE.NAME}
            inputLabel={FIELD_INFO.I_AGREE.TITLE}
            isChecked={information.informationNoticeAccepted}
            onChange={(isChecked): void => {
              this.autosave(information, FIELD_INFO.I_AGREE.KEY, isChecked, 'Boolean');
            }}
            errors={
              validationObject && validationObject.informationNoticeAccepted
                ? validationObject.informationNoticeAccepted
                : undefined
            }
          />
          <div className="consent-withdrawal-text">{FIELD_INFO.I_AGREE.WITHDRAWAL}</div>
          {this.renderAlliedHealthServiceInfoCheckbox(information)}
        </form>
      </div>
    );
  };

  /**
   * Autosave functionality that will call the props auto save function.
   */
  private autosave = async (
    infoNotice: PatientInformationNotice,
    key: string,
    value: string | boolean,
    type = 'String',
  ): Promise<void> => {
    const { autosave } = this.props;
    await autosave(infoNotice, key, value, type).then(() => {
      const viewed = this.state.viewed.add(key);
      this.setState({ viewed });
    });
  };

  /**
   * Validates the entire form.
   */
  private validateForm(): void {
    const viewed = new Set(VALIDATION_FIELDS);
    this.setState({ viewed });
  }

  private validateObject = (patient: PatientInformationNotice): any => {
    // Global rules for the validator, must match the patient interface keys
    const globalValidationRules: { [key: string]: object } = {
      informationNoticeAccepted: {
        inclusion: {
          within: [true],
          message: FIELD_INFO.I_AGREE.MISSING_INPUT_ERROR,
        },
      },
    };

    // Ensure fields that been viewed only have validation run on them
    const specificValidationRules: { [key: string]: object } = {};
    for (const viewed of this.state.viewed.keys()) {
      specificValidationRules[viewed] = globalValidationRules[viewed];
    }
    // Disable pre-appending of argument name to error messages
    const disableFullMessages = { fullMessages: false };
    // Run validation on all the fields
    return validate(patient, specificValidationRules, disableFullMessages);
  };
}

//@ts-ignore
export default RegistrationInformationNotice;
