import React, { useEffect } from 'react';
import { Field, useFormikContext, FormikProps } from 'formik';

import { HelperMessage, FormRow } from 'shared-components/components';
import { yesNoOptions } from 'op-utils';
import { SelectFieldBase, ToggleButtonGroupField } from 'shared-components/components/FormikComponents';
import { AustralianStates } from 'shared-components/enums';

import { PreCtChartCheckData } from '../../../PreCtChartCheck/interfaces/PreCtChartCheckInterfaces';
import { getLastUpdateUser } from '../../../helpers';
import { sharedContent } from '../../../shared/constants';
import { LastUpdateUserDetails } from '../../../shared/interfaces';
import { content, factorsIdentifiedOptions, pelvicFitnessOptions } from '../../constants';
import { PreCtTriageData } from '../../interfaces/PreCtTriageInterfaces';
import SummaryTable from './SummaryTable';

interface Props {
  handleMutation: (name: string, value: string) => void;
  patientState: string | undefined;
  lastUpdateUserData: LastUpdateUserDetails[];
  latestChartCheckData: PreCtChartCheckData;
}

const Summary = ({ handleMutation, patientState, lastUpdateUserData, latestChartCheckData }: Props): JSX.Element => {
  const { fields, infoHelpers } = content.summary;
  const { pelvicFitness, hasHighRiskFactors, howFactorsWereIdentified } = fields;
  const { values, setFieldValue, setTouched }: FormikProps<PreCtTriageData> = useFormikContext();

  useEffect(() => {
    if (values.hasHighRiskFactors === false) {
      setFieldValue('howFactorsWereIdentified', '');
      setTouched({ [howFactorsWereIdentified]: false });
    }
  }, [values.hasHighRiskFactors]);

  return (
    <>
      {patientState === AustralianStates.WA && (
        <FormRow fieldLabel={pelvicFitness} fieldName={'pelvicFitness'}>
          <Field
            name="pelvicFitness"
            component={SelectFieldBase}
            placeholder={sharedContent.generic.label.selectValue}
            options={pelvicFitnessOptions}
            updateMutation={(value: string) => handleMutation('pelvicFitness', value)}
            lastUpdateUser={getLastUpdateUser(lastUpdateUserData, 'pelvicFitness')}
          />
        </FormRow>
      )}
      <FormRow fieldLabel={hasHighRiskFactors} fieldName="hasHighRiskFactors">
        <Field
          name="hasHighRiskFactors"
          component={ToggleButtonGroupField}
          label={hasHighRiskFactors}
          options={yesNoOptions}
          handleMutation={handleMutation}
          lastUpdateUser={getLastUpdateUser(lastUpdateUserData, 'hasHighRiskFactors')}
        />
        {values.hasHighRiskFactors === false && (
          <HelperMessage fieldName={'hasHighRiskFactors'} fieldText={infoHelpers.lowRisk} helperType="info" />
        )}
      </FormRow>
      {values.hasHighRiskFactors === true && (
        <FormRow fieldLabel={howFactorsWereIdentified} fieldName={'howFactorsWereIdentified'}>
          <Field
            name="howFactorsWereIdentified"
            component={SelectFieldBase}
            placeholder={sharedContent.generic.label.selectValue}
            options={factorsIdentifiedOptions}
            updateMutation={(value: string) => handleMutation('howFactorsWereIdentified', value)}
            lastUpdateUser={
              values.howFactorsWereIdentified && getLastUpdateUser(lastUpdateUserData, 'howFactorsWereIdentified')
            }
          />
          {values.howFactorsWereIdentified === factorsIdentifiedOptions[0].label && (
            <HelperMessage
              fieldName={'howFactorsWereIdentified'}
              fieldText={infoHelpers.ensureReview}
              helperType="info"
            />
          )}
          {values.howFactorsWereIdentified === factorsIdentifiedOptions[1].label && (
            <HelperMessage
              fieldName={'howFactorsWereIdentified'}
              fieldText={infoHelpers.proceedToRT}
              helperType="info"
            />
          )}
        </FormRow>
      )}
      <SummaryTable latestChartCheckData={latestChartCheckData} />
    </>
  );
};

export default Summary;
