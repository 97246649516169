import React, { useState, useEffect } from 'react';
import { styled } from '@mui/system';
import { FieldProps, getIn } from 'formik';
import ToggleButtonGroup from '@mui/material/ToggleButtonGroup';
import { DeviceUtilities } from 'shared-components/utils';

import { ToggleButton, HelperMessage, UserIcon } from 'shared-components/components';
import StyledFieldRow from './StyledFieldRow';
interface Props extends FieldProps {
  label: string;
  options: { label: string; value: boolean }[];
  helperType: string;
  handleMutation?: (name: string, value: string) => void;
  conditionalHandleChange?: any;
  lastUpdateUser?: string;
  allowUnset?: boolean;
  minwidth?: string;
  alternateStyle?: boolean;
}

interface StyleProps {
  width?: string;
}

const StyledToggleButtonGroup = styled(ToggleButtonGroup)<StyleProps>`
  && {
    width: ${({ width }: StyleProps): string => width || ''};
  }
`;

const ToggleButtonGroupField = ({
  field,
  form,
  label,
  options,
  helperType,
  handleMutation,
  conditionalHandleChange,
  lastUpdateUser,
  allowUnset = false,
  minwidth,
  alternateStyle,
}: Props): JSX.Element => {
  const { name, value } = field;
  const { touched, errors, setFieldValue, submitCount } = form;
  const [selectedValue, setSelectedValue] = useState(value);
  const fieldError = getIn(errors, name);
  const fieldTouched = getIn(touched, name);
  const fieldIsNotNull = value !== null && value !== '';
  const isFieldInvalid = (submitCount > 0 || fieldIsNotNull || fieldTouched) && fieldError;
  const isMobile = DeviceUtilities.isMobileDevice();

  useEffect(() => {
    // Formik initial values sets value but not selectedValue.
    //  selectedValue needs to be set here for the button to be selected correctly on load
    if (value && !selectedValue) {
      setSelectedValue(value);
    }
  }, [value, selectedValue]);

  const handleChange = (_event: React.ChangeEvent<any>, updatedValue: any): void => {
    if (updatedValue !== null || allowUnset) {
      handleMutation && handleMutation(name, updatedValue);
      setFieldValue(name, updatedValue);
      setSelectedValue(updatedValue);
      conditionalHandleChange && conditionalHandleChange(updatedValue);
    }
  };

  return (
    <>
      <StyledFieldRow width={isMobile ? '100%' : undefined}>
        <StyledToggleButtonGroup
          {...field}
          value={selectedValue}
          exclusive
          width={isMobile ? '100%' : undefined}
          onChange={handleChange}
          aria-label={`${label}-group`}>
          {options.map(
            (option: { label: string; value: any }, idx: number): JSX.Element => (
              <ToggleButton
                key={idx}
                name={name}
                firstChild={idx === 0}
                lastChild={idx === options.length - 1}
                invalid={isFieldInvalid}
                selected={selectedValue === option.value}
                value={option.value}
                aria-label={option.label}
                minwidth={isMobile ? `${100 / (options?.length || 1)}%` : minwidth}
                alternateStyle={alternateStyle}>
                {option.label}
              </ToggleButton>
            ),
          )}
        </StyledToggleButtonGroup>
        {lastUpdateUser && <UserIcon userType={lastUpdateUser} />}
      </StyledFieldRow>
      {isFieldInvalid && (
        <HelperMessage
          fieldName={name}
          fieldText={fieldError}
          helperType={helperType}
          fontSize={alternateStyle ? 14 : 16}
        />
      )}
    </>
  );
};

export default ToggleButtonGroupField;
