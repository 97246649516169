// eslint-disable-next-line no-use-before-define
import React, { useState } from 'react';
import { useLocation } from 'react-router-dom';
import './Appointments.scss';
import AppointmentViewModel from './AppointmentViewModel';
import { useQuery } from '@apollo/client';
import { APPOINTMENTS_QUERY } from './AppointmentsQueries';
import { LOCAL_TIMEZONE, GRAPHQL_API_ERROR_MESSAGE } from './constants';
import { AppointmentsListing, AppointmentsListingEmpty, AppointmentDetails } from '../../Components';
import { DictionaryUtilities } from 'shared-components/utils';
import { DeviceUtilities } from 'shared-components/utils';
import { MainLayout } from 'op-pages/PX/Components';

import { AppointmentObject } from '../../models/AppointmentObject';

const Appointments = (): JSX.Element => {
  const location = useLocation();
  const [mobileDetailsPanelDisplayed, setMobileDetailsPanelDisplayed] = useState<boolean>(false);
  const [selectedAppointment, setSelectedAppointment] = useState<AppointmentObject | null>(null);
  const { loading, data, error } = useQuery(APPOINTMENTS_QUERY, { variables: { afterTz: LOCAL_TIMEZONE } });

  const appointmentViewModel = new AppointmentViewModel();

  if (loading) return <AppointmentsListingEmpty isLoading={true} />;

  if (error || !data || !data.user.username) {
    return <div>{GRAPHQL_API_ERROR_MESSAGE}</div>;
  }

  const resetState = () => {
    setMobileDetailsPanelDisplayed(false);
    setSelectedAppointment(null);
  };

  const handleBackButtonPressed = (): void => {
    resetState();
  };

  const generateTitle = (): string => {
    const defaultTitle = 'Appointments';

    if (DeviceUtilities.isMobileDevice() && selectedAppointment) {
      return selectedAppointment.appointmentType || defaultTitle;
    }

    return defaultTitle;
  };

  const handleSelection = (dictKey: string, arrayIndex: number, apptIndex: number): void => {
    setSelectedAppointment(appointmentViewModel.sortedAppointments[dictKey][arrayIndex][apptIndex]);
    setMobileDetailsPanelDisplayed(true);
  };

  const getSelectedAppointmentUsingIDFromURL = (): AppointmentObject | null => {
    // Find the selected appointment ID from the URL (will be null for the initial render).
    const pathName = location.pathname;
    const appointmentID = appointmentViewModel.getAppointmentIDFromURL(pathName);

    if (appointmentID) {
      return appointmentViewModel.getSelectedAppointmentByID(appointmentID);
    }
    return appointmentViewModel.getFirstAppointmentInListing();
  };

  const generatePageElements = (): JSX.Element => {
    // Using the ID, find the selected appointment object.
    const selectedAppointment = getSelectedAppointmentUsingIDFromURL();
    let selectedAppointmentID = null; // Default
    let isSameDayAppointment = false; // Default

    if (selectedAppointment) {
      selectedAppointmentID = selectedAppointment.id;
      isSameDayAppointment = appointmentViewModel.isSameDayAppointment(selectedAppointment.id);
    }

    // Generate the listing and details section based on the data.
    return (
      <div className="appointments">
        <AppointmentsListing
          appointmentsData={appointmentViewModel.sortedAppointments}
          selectedAppointmentID={selectedAppointmentID}
          handleAppointmentSelected={handleSelection}
        />
        {selectedAppointment && (
          <AppointmentDetails
            appointment={selectedAppointment}
            isSameDayAppointment={isSameDayAppointment}
            applyTransitionToDetailsPanel={mobileDetailsPanelDisplayed}
          />
        )}
      </div>
    );
  };

  const fetchDataAndGeneratePageElements = (): JSX.Element => {
    // Set the appointment data and sort through the view model
    appointmentViewModel.setAllAppointmentsAndSort(data.allAppointments);

    // There is no data so return an empty page
    if (appointmentViewModel.isDataEmpty()) {
      return (
        <div className="appointments">
          <AppointmentsListingEmpty isLoading={false} />
        </div>
      );
    }

    return <>{generatePageElements()}</>;
  };

  const generatePage = (): JSX.Element => {
    // If there is no data, fetch it first before generating the required page elements.
    if (DictionaryUtilities.isEmpty(appointmentViewModel.sortedAppointments)) {
      return fetchDataAndGeneratePageElements();
    }
    // Else, use the existing data to generate the page elements.
    return generatePageElements();
  };

  return (
    <MainLayout clickLogoFn={handleBackButtonPressed}>
      <div className="container width-contained">{generatePage()}</div>
    </MainLayout>
  );
};

export default Appointments;
