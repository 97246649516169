import React, { useState } from 'react';
import { styled } from '@mui/system';
import { PatientPhotoIcon, PatientPhotoWhiteIcon } from 'shared-components/images';
import { GET_PATIENT_PHOTO } from 'op-components/PatientCard/PatientCardQueries';
import { useQuery } from '@apollo/client';
import useInterval from 'shared-components/utils/useInterval';
import { Avatar, Popover } from '@mui/material';
interface PhotoStyledProps {
  size: number;
}
const PhotoWrapper = styled('div')<PhotoStyledProps>`
  height: ${(props: PhotoStyledProps) => props.size}px;
  width: ${(props: PhotoStyledProps) => props.size}px;
  & img {
    object-fit: cover;
    width: ${(props: PhotoStyledProps) => props.size}px;
    height: ${(props: PhotoStyledProps) => props.size}px;
  }
  & svg {
    width: ${(props: PhotoStyledProps) => props.size}px;
    height: ${(props: PhotoStyledProps) => props.size}px;
  }
`;

const StyledPopupWindow = styled('div')`
  height: 320px;
  width: 320px;
  border-radius: 32px;
  overflow: hidden;
  & img {
    object-fit: cover;
    height: 320px;
    width: 320px;
    border-radius: 32px;
    border-radius: 32px;
    border: 4px solid ${(props) => props.theme.palette.grey[300]};
  }
`;

interface PhotoWidgetProps {
  patientId?: string | number;
  url?: string; // allow override of patient (and default) photo
  enableOverlay?: boolean;
  numRetries?: number;
  size?: string;
}

const PHOTO_SIZE = {
  sm: 32,
  reg: 48,
  md: 64,
  lg: 128,
};

const PhotoWidget = (props: PhotoWidgetProps): JSX.Element => {
  const [isPhotoLoadError, setPhotoLoadError] = useState(true);
  const [countTimer, setCountTimer] = useState(0);
  const [anchorEl, setAnchorEl] = React.useState<HTMLElement | null>(null);
  const numRetries = props.numRetries ? props.numRetries : 3; // Retries 10 times and gives up
  const size = props.size ? PHOTO_SIZE[props.size as keyof typeof PHOTO_SIZE] : PHOTO_SIZE['md'];
  const DefaultIcon = <PatientPhotoIcon viewBox={'0 0 32 32'} height={'50%'} />;

  const { data } = useQuery(GET_PATIENT_PHOTO, {
    variables: { patientId: props.patientId },
    skip: !!props.url || !props.patientId, // skip if url is provided or patientId is not provided
  });

  useInterval(
    () => {
      setCountTimer(countTimer + 1);
    },
    isPhotoLoadError && countTimer < numRetries ? 10000 : null,
  );
  const handlePopoverOpen = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handlePopoverClose = () => {
    setAnchorEl(null);
  };
  const open = Boolean(anchorEl);

  return (
    <>
      <PhotoWrapper
        data-test-id="photo-wrapper"
        size={size}
        onMouseEnter={handlePopoverOpen}
        onMouseLeave={handlePopoverClose}>
        {props.url || data?.patientPhoto?.photoUrl ? (
          <Avatar
            sx={{ width: size, height: size }}
            src={props.url || data.patientPhoto.photoUrl}
            hidden={isPhotoLoadError}
            onLoad={() => {
              setPhotoLoadError(false);
            }}>
            {DefaultIcon}
          </Avatar>
        ) : (
          DefaultIcon
        )}
      </PhotoWrapper>
      <Popover
        id="mouse-over-popover"
        sx={{
          pointerEvents: 'none',
          backgroundColor: 'transparent',
          borderRadius: '28px',
          boxShadow: 'none',
          '& .MuiPopover-paper': {
            boxShadow: 'none',
          },
        }}
        open={open}
        anchorEl={anchorEl}
        anchorOrigin={{
          vertical: 'top',
          horizontal: 'right',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'left',
        }}
        onClose={handlePopoverClose}
        disableRestoreFocus>
        {(props.url || data?.patientPhoto?.photoUrl) && (
          <StyledPopupWindow>
            <img src={props.url || data.patientPhoto.photoUrl} />
          </StyledPopupWindow>
        )}
      </Popover>
    </>
  );
};

export default PhotoWidget;
