import { gql } from '@apollo/client';

export const PATIENT_DETAILS_FRAGMENT = gql`
  fragment PatientDetails on PatientType {
    id
    address {
      id
      formattedAddress
    }
    firstName
    middleName
    lastName
    ida
    idb
    gender
    dob
    updatedAt
    updatedBy
    horizonCenterId
    regFormStatus
    diffMqData
    userProfile {
      id
      systemState
      registrationAccessType
      showDistressThermometer
    }
  }
`;

export const QUERY_PSO = gql`
  query PatientAndUserDetails($id: ID!, $patientId: ID!) {
    patient(id: $id) {
      id
      ...PatientDetails
    }
    healthAssessment(id: $id) {
      id
      updatedAt
      updatedBy
      status
    }
    distressThermometerInitial(patientId: $patientId) {
      id
      updatedAt
      updatedBy
      status
    }
    distressThermometerEot(patientId: $patientId) {
      id
      status
      version
    }
    profile(id: $id) {
      id
      showDistressThermometer
      patientFeatures(id: $id) {
        distressThermometerEnabled
        healthAssessmentEnabled
        distressThermometerEotEnabled
        covidEnabled
      }
    }
    covidScreening(id: $id) {
      id
      updatedAt
      updatedBy
      status
    }
    locationsPsoSystem(patientId: $patientId) {
      id
      alias
    }
    featureOptions {
      id
      name
      description
      active
    }
  }
  ${PATIENT_DETAILS_FRAGMENT}
`;

export const QUERY_NURSE = gql`
  query PatientAndUserDetails($id: ID!) {
    user {
      id
      firstName
      lastName
    }
    patient(id: $id) {
      id
      ...PatientDetails
    }
    allPreCtChartCheck(patientId: $id) {
      id
      isAmendment
      nurseLocation
      updatedBy
      updatedAt
      status
      isLocked
    }
    allPreCtTriage(patientId: $id) {
      id
      isAmendment
      isLocked
      updatedBy
      updatedAt
      submissionUser {
        id
      }
      status
    }
  }
  ${PATIENT_DETAILS_FRAGMENT}
`;

export const CREATE_PRE_CT_CHART_CHECK = gql`
  mutation CreatePreCtChartCheck($patientId: ID!) {
    createPreCtChartCheck(patientId: $patientId) {
      preCtChartCheck {
        id
        updatedBy
        updatedAt
        isAmendment
      }
    }
  }
`;

export const CREATE_AMENDED_PRE_CT_CHART_CHECK = gql`
  mutation CreateAmendedPreCtChartCheck($id: ID!) {
    createAmendedPreCtChartCheck(id: $id) {
      preCtChartCheck {
        id
        updatedBy
        updatedAt
        isAmendment
        status
      }
    }
  }
`;

export const DUPLICATE_PRE_CT_CHART_CHECK = gql`
  mutation duplicatePreCtChartCheck($id: ID!) {
    duplicatePreCtChartCheck(id: $id) {
      preCtChartCheck {
        id
        updatedBy
        updatedAt
        isAmendment
        status
      }
    }
  }
`;

export const DELETE_PRE_CT_CHART_CHECK = gql`
  mutation deletePreCtChartCheck($id: ID!) {
    deletePreCtChartCheck(id: $id) {
      ok
    }
  }
`;

export const CREATE_AMENDED_PRE_CT_TRIAGE = gql`
  mutation CreateAmendedPreCtTriage($id: ID!) {
    createAmendedPreCtTriage(id: $id) {
      preCtTriage {
        id
        updatedBy
        updatedAt
        isAmendment
        status
      }
    }
  }
`;

export const DUPLICATE_PRE_CT_TRIAGE = gql`
  mutation duplicatePreCtTriage($id: ID!) {
    duplicatePreCtTriage(id: $id) {
      preCtTriage {
        id
        updatedBy
        updatedAt
        isAmendment
        status
      }
    }
  }
`;

export const CREATE_PRE_CT_TRIAGE = gql`
  mutation CreatePreCtTriage($patientId: ID!) {
    createPreCtTriage(patientId: $patientId) {
      preCtTriage {
        id
        isAmendment
        updatedBy
        updatedAt
      }
    }
  }
`;

export const DELETE_PRE_CT_TRIAGE = gql`
  mutation deletePreCtTriage($id: ID!) {
    deletePreCtTriage(id: $id) {
      ok
    }
  }
`;

// to have nurse & pso view in the future, currently is same as query_pso
export const QUERY_SUPERUSER = QUERY_PSO;
