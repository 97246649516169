import { getSiteRepresentation } from 'op-pages/RO/Careplan/Common';
import { CurrentAppConfig } from 'op-pages/RO/Careplan/AppConfig';
import dayjs from 'dayjs';
import { Careplan } from 'op-pages/RO/Careplan/Interface';

export const getCareplanName = (careplan: Careplan): string => {
  let careplanName = '';
  careplan.sitegroupSet.forEach((template: any, index: number) => {
    if (index > 0 && careplanName !== '') {
      careplanName += ', ';
    }
    careplanName += getTemplateGroupRepresentation(template);
  });
  if (careplanName === '') {
    const diagnosisCode = careplan?.diagnosis?.diagnosisCode;
    if (diagnosisCode?.diagnosisCode && diagnosisCode?.diagnosisDescription) {
      careplanName = `${diagnosisCode?.diagnosisCode}: ${diagnosisCode?.diagnosisDescription}`;
    }
  }
  return careplanName;
};

export const getTemplateGroupRepresentation = (template: any): string => {
  const values = template.siteSet
    .map((site: any, count: number) => (count < 3 ? getSiteRepresentation(site) : null))
    .filter(Boolean);

  const siteString = template.siteSet.length - values.length === 1 ? 'site' : 'sites';

  return template.siteSet.length - values.length !== 0
    ? values.join(', ') + ` + ${template.siteSet.length - values.length} ${siteString}`
    : values.join(', ');
};

export const getFormattedDateTime = (dateTime: string): string => {
  const dateTimeFormat = CurrentAppConfig.RadiationDashboard.dateTimeFormat;
  return dayjs(dateTime).isSame(dayjs(), 'day')
    ? 'Today ' + dayjs(dateTime).format('hh:mm A')
    : dayjs(dateTime).format(dateTimeFormat);
};
