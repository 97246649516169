// eslint-disable-next-line no-use-before-define
import { useMutation, useQuery } from '@apollo/client';
import React from 'react';
import { useParams } from 'react-router-dom';
import { FormProps } from '../interfaces';
import BasicForm from './BasicForm';
import { REGISTRATION_BASIC_DETAILS_QUERY, UPDATE_REGISTRATION_BASIC_DETAILS } from './BasicQueries';

const Basic = (props: FormProps): JSX.Element => {
  const { exitForm } = props;
  const { patientId } = useParams<{ patientId: string }>();

  const [updateRegistrationBasic] = useMutation(UPDATE_REGISTRATION_BASIC_DETAILS);
  const { data, error } = useQuery(REGISTRATION_BASIC_DETAILS_QUERY, {
    variables: { id: patientId },
  });

  if (error || !data) return <></>;

  const { genderRefData, genderIdentificationRefData, nameTitle, patient, user } = data;

  const updateField = (field: string, value: string | boolean | number | null) => {
    // dont allow saving first or last name as blank (causes downstream issues)
    if (['firstName', 'lastName'].includes(field) && value === '') return;

    const variables = {
      id: patientId,
      [field]: value,
    };

    if (field === 'genderIdentification' && value !== 'Different Term') {
      variables['genderIdentificationOther'] = null;
    }

    updateRegistrationBasic({ variables });
  };

  return (
    <BasicForm
      refData={{
        genderRefData,
        genderIdentificationRefData,
        titleReferenceData: nameTitle,
      }}
      patient={patient}
      updateField={updateField}
      handleShowSaveExitConfirm={() => exitForm(true)}
      isPso={user.isPso}
    />
  );
};

export default Basic;
