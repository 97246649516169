import React from 'react';
import { Months } from 'shared-components/enums';
import { DropDownRODay, DropDownROMonth, DropDownROYear } from './Dropdowns';
import { isUs } from 'op-utils';

import './HASharedComponents.scss';

interface Props {
  dayValue: string;
  dayField: string;
  monthValue: number;
  monthField: string;
  yearValue: string;
  yearField: string;
  onChange: (field: string, value: string) => void;
}

interface MonthYearProps {
  monthValue: string;
  monthField: string;
  yearValue: string;
  yearField: string;
  onChange: (field: string, value: string | boolean) => void;
}

export const HADate = ({
  dayValue,
  dayField,
  monthValue,
  monthField,
  yearValue,
  yearField,
  onChange,
}: Props): JSX.Element => {
  // onChange passes back field name and field value
  const month = (
    <DropDownROMonth
      id={monthField}
      value={monthValue > 0 ? Months[monthValue - 1] : ''}
      onChange={(val): void => {
        if (val) {
          const resultVal = Months.indexOf(val) >= 0 ? Months.indexOf(val) + 1 : '';
          onChange(monthField, resultVal.toString());
        }
      }}
      selectedYear={parseInt(yearValue)}
      dataTestId={`${monthField}-subform-date-month`}
    />
  );
  const day = (
    <DropDownRODay
      id={dayField}
      value={dayValue}
      onChange={(val): void => {
        if (val) {
          onChange(dayField, val);
        }
      }}
      selectedMonth={monthValue}
      selectedYear={parseInt(yearValue)}
      dataTestId={`${dayField}-subform-date-day`}
    />
  );
  return (
    <div className="ha-shared_components-date-fields">
      {isUs() ? month : day}
      {isUs() ? day : month}
      <DropDownROYear
        id={yearField}
        value={yearValue}
        onChange={(val): void => {
          onChange(yearField, val);
        }}
        dataTestId={`${yearField}-subform-date-year`}
      />
    </div>
  );
};

export const HAMonthYear = ({
  monthValue,
  monthField,
  yearValue,
  yearField,
  onChange,
}: MonthYearProps): JSX.Element => {
  // onChange passes back field name and field value
  const monthValueNum = parseInt(monthValue);
  return (
    <div className="ha-shared_components-date-fields">
      <DropDownROMonth
        id={monthField}
        value={monthValueNum > 0 ? Months[monthValueNum - 1] : ''}
        onChange={(val): void => {
          if (val) {
            const resultVal = Months.indexOf(val) >= 0 ? Months.indexOf(val) + 1 : '';
            onChange(monthField, resultVal.toString());
          }
        }}
        selectedYear={parseInt(yearValue)}
      />
      <DropDownROYear
        id={yearField}
        value={yearValue}
        onChange={(val): void => {
          onChange(yearField, val);
        }}
      />
    </div>
  );
};
