import React from 'react';
import { styled } from '@mui/system';
import { yesNoOptions } from 'op-utils';
import { DevicesFields, PreCtChartCheckFormValues } from '../../interfaces/PreCtChartCheckInterfaces';
import { sharedContent } from '../../../shared/constants';
import { getRemSize } from 'shared-components/StyledComponents/functions';
import { ReviewBox } from 'shared-components/components';
import ReviewSubFields from '../../../shared/components/ReviewSubFields';
import { Question, Answer, QuestionContainer } from './ReviewSharedComponents';

interface Props {
  fields: DevicesFields;
  values: PreCtChartCheckFormValues;
  error: boolean;
  link: string;
  isLocked: boolean;
}

interface arrayValueResponse {
  value: string[];
}

const ArrayQuestion = styled('div')<arrayValueResponse>`
  width: 50%;
  height: 40px;
  font-size: ${getRemSize(14)};
  line-height: 20px;
  display: flex;
  align-items: center;
  color: ${(props: arrayValueResponse): string =>
    props.value && props.value.length > 0
      ? `${(props: any) => props.theme.palette.text.primary}`
      : `${(props: any) => props.theme.palette.error.main}`};
`;

const ArrayAnswer = styled('div')<arrayValueResponse>`
  width: 50%;
  font-weight: bold;
  font-size: ${getRemSize(14)};
  line-height: 20px;
  display: flex;
  align-items: center;
  color: ${(props: arrayValueResponse): string =>
    props.value && props.value.length > 0
      ? `${(props: any) => props.theme.palette.text.primary}`
      : `${(props: any) => props.theme.palette.error.main}`};
`;

const convertValueToLabel = (value: any, arrayLabel?: any) => {
  for (const option of arrayLabel) {
    if (option.value === value) return option.label;
  }
  const responseArray = [];
  if (typeof value === 'object') {
    if (value.length > 0) {
      for (const item of value) {
        responseArray.push(item.name);
      }
      return responseArray.join(', ');
    } else {
      return sharedContent.generic.label.notProvided;
    }
  }
};

const ReviewDevices = (props: Props): JSX.Element => {
  const { fields, values, error, link, isLocked } = props;

  const allDeviceValues = [
    ...values.cied,
    ...values.venousAccessType,
    ...values.otherImplantedDevices,
    ...values.otherNotListed,
  ];
  const sectionIsEmpty = values.hasImplantedMedicalDevice === true && allDeviceValues.length === 0;

  return (
    <ReviewBox title={'Implanted Medical Devices'} error={error} link={link} isLocked={isLocked}>
      <QuestionContainer aria-label={`review-${fields.hasImplantedMedicalDevice}`}>
        <Question value={values.hasImplantedMedicalDevice}>{fields.hasImplantedMedicalDevice}</Question>
        <Answer value={values.hasImplantedMedicalDevice}>
          {values.hasImplantedMedicalDevice !== null
            ? convertValueToLabel(values.hasImplantedMedicalDevice, yesNoOptions)
            : sharedContent.generic.label.notProvided}
        </Answer>
      </QuestionContainer>
      {values.hasImplantedMedicalDevice && (
        <ReviewSubFields>
          {Object.keys(fields).map((item: string, index: number) => {
            if (item !== 'hasImplantedMedicalDevice' && item !== 'otherNotListed') {
              //@ts-ignore
              if (sectionIsEmpty || values[item].length !== 0) {
                return (
                  //@ts-ignore
                  <QuestionContainer aria-label={`review-${fields[item]}`} key={index}>
                    <ArrayQuestion
                      value={
                        //@ts-ignore
                        values[item]
                      }>
                      {
                        //@ts-ignore
                        fields[item]
                      }
                    </ArrayQuestion>
                    <ArrayAnswer
                      value={
                        //@ts-ignore
                        values[item]
                      }>
                      {
                        //@ts-ignore
                        convertValueToLabel(values[item], yesNoOptions)
                      }
                    </ArrayAnswer>
                  </QuestionContainer>
                );
              }
              //@ts-ignore
              if (!sectionIsEmpty && values[item].length === 0) {
                return (
                  //@ts-ignore
                  <QuestionContainer aria-label={`review-${fields[item]}`}>
                    <Question value={item}>
                      {
                        //@ts-ignore
                        fields[item]
                      }
                    </Question>
                    <Answer value={item}>No</Answer>
                  </QuestionContainer>
                );
              }
            }
            return null;
          })}
          {Object.values(values.otherNotListed).map(
            (item: { name: string; id: string; deviceField: string }, index: number) => {
              return (
                <QuestionContainer aria-label={`review-${fields.otherNotListed}-${index}`} key={index}>
                  <Question value={item.name}>{fields.otherNotListed}</Question>
                  <Answer value={item.name}>{item.name}</Answer>
                </QuestionContainer>
              );
            },
          )}
        </ReviewSubFields>
      )}
    </ReviewBox>
  );
};

export default ReviewDevices;
