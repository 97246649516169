const CONSTANTS = {
  RESPONSIBLE: 'Responsible Physician',
  PRIMARY: 'Primary Physician (GP)',
  REFERRING: 'Referring Physician',
  CONTACT: 'Contact number',
  NO_UPCOMING: 'No upcoming appointments',
  MEDICARE_NO: 'Medicare card number/IRN',
  MEDICARE_EXP: 'Medicare card expiry',
  EMG_CONTACT: 'Emergency Contact',
  RELATIONSHIP: 'Relationship',
  PRIM_DIAG: 'Primary',
  METASTASIS: 'Metastasis',
  NO_DIAGNOSIS: 'No diagnosis available',
  SEVERITY: 'Severity',
  NOTES: 'Notes',
  NO_ALLERGIES: 'No allergies available',
  STATUS: 'Status',
  ASSIGNED: 'Assigned',
  NO_ALERTS: 'No alerts available',
  INSURANCE: 'Insurance policy number',
  EXPIRATION_DATE: 'Expiration date',
};
export default CONSTANTS;
