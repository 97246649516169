import { gql } from '@apollo/client';

export const HEALTH_ASSESSMENT_ALLERGY_FRAGMENT = gql`
  fragment HealthAssessmentAllergy on AllergyType {
    id
    sensitivity
    specify
    area
    reaction {
      id
      value
      other
    }
  }
`;

export const HEALTH_ASSESSMENT_ILLNESS_FRAGMENT = gql`
  fragment HealthAssessmentIllness on HealthAssessmentType {
    id
    screening
    pastMedicalHistory
    cardiac
    constitutional
    eyesEarsNoseThroat
    gastrointestinal
    genitourinary
    neurological
    psychiatric
    pulmonary
    bonesJoints
    hematologic
    skin

    colonoscopyDate
    colonoscopyRawYear
    colonoscopyRawMonth
    colonoscopyRawDay

    chestCtScanDate
    chestCtScanRawYear
    chestCtScanRawMonth
    chestCtScanRawDay
  }
`;

export const HEALTH_ASSESSMENT_CANCER_FRAGMENT = gql`
  fragment HealthAssessmentCancer on CancerType {
    id
    cancerType
    cancerStage
    treatment {
      id
      other
      treatmentType
      hospital
      doctor
      bodyPart
      stage
      treatmentRawYear
      treatmentRawDay
      treatmentRawMonth
      treatmentDate
      medication
    }
  }
`;

export const HEALTH_ASSESSMENT_FAMILY_HISTORY_FRAGMENT = gql`
  fragment HealthAssessmentFamilyHistory on FamilyHistoryType {
    id
    familyMember
    cancerType
    stillAlive
    ageOfFamilyMember
    other
  }
`;

export const HEALTH_ASSESSMENT_DIABETES_RESPONSE_FRAGMENT = gql`
  fragment HealthAssessmentDiabetesResponse on DiabetesResponseType {
    id
    value
    other
  }
`;

export const HEALTH_ASSESSMENT_IMMUNOSUPPRESSIVE_CONDITION_FRAGMENT = gql`
  fragment HealthAssessmentImmunosuppressiveCondition on ImmunosuppressiveConditionType {
    id
    value
    other
  }
`;

export const HEALTH_ASSESSMENT_INFECTION_FRAGMENT = gql`
  fragment HealthAssessmentInfection on IntractableInfectionOrIllnessType {
    id
    value
    other
    doctorClearance
  }
`;

export const HEALTH_ASSESSMENT_INTERNAL_DEVICE_FRAGMENT = gql`
  fragment HealthAssessmentInternalDevice on InternalDeviceType {
    id
    value
    other
  }
`;

export const HEALTH_ASSESSMENT_MEDICATION_FRAGMENT = gql`
  fragment HealthAssessmentMedication on MedicationType {
    id
    medicationName
    dosage
    route
    prescribingPhysician
  }
`;

export const HEALTH_ASSESSMENT_MOBILITY_AID_FRAGMENT = gql`
  fragment HealthAssessmentMobilityAid on MobilityAidType {
    id
    value
    other
  }
`;

export const HA_MOBILITY_FRAGMENT = gql`
  fragment HealthAssessmentMobility on HealthAssessmentType {
    id
    mobilityAid
    fallen
    fallenHowMany
    fallenInjuries
    footPain
    unsteady
    lightheaded
  }
`;

export const HEALTH_ASSESSMENT_OPERATION_FRAGMENT = gql`
  fragment HealthAssessmentOperation on OperationType {
    id
    operationSpecify
    operationDate
    operationRawDay
    operationRawMonth
    operationRawYear
    operationOther
  }
`;

export const HEALTH_ASSESSMENT_OTHER_RT_FRAGMENT = gql`
  fragment HealthAssessmentOtherRT on OtherRadiotherapyConditionType {
    id
    condition
    hospital
    doctor
    bodyPart
    treatmentDate
    treatmentRawDay
    treatmentRawMonth
    treatmentRawYear
  }
`;

export const HEALTH_ASSESSMENT_WELLBEING_ISSUE_FRAGMENT = gql`
  fragment HealthAssessmentWellbeingIssue on WellbeingIssueType {
    id
    value
    other
  }
`;

export const HA_BIOLOGICAL_FRAGMENT = gql`
  fragment HealthAssessmentBiological on HealthAssessmentType {
    id
    biologicalGender
    periodAge
    abnormalMammograms
    breastBiopsy
    periodWhen
    menopause
    menopauseAge
    biologicalPregnant
    pregnantCount
    pregnantDeliveries
    pregnantAge
    breastfeed
    hormones
    hormonesDuration
    braSize
    nippleDischarge
    nippleDischargeExplain
    vaginalBleeding
    vaginalBleedingExplain
    vaginalDischarge
    vaginalDischargeExplain
    stdFemale
    papSmearDateRawMonth
    papSmearDateRawDay
    papSmearDateRawYear
    mammogramDateRawMonth
    mammogramDateRawDay
    mammogramDateRawYear
    psaDateRawMonth
    psaDateRawDay
    psaDateRawYear
    psaScore
    psaLocation
    stdMale
  }
`;

export const HA_SOCIAL_US_FRAGMENT = gql`
  fragment HealthAssessmentSocialUS on HealthAssessmentType {
    id
    smokingBool
    smokingHistory
    smokingFrequency
    smokingStopRawMonth
    smokingStopRawYear
    tobaccoChew
    tobaccoChewHistory
    tobaccoStopRawMonth
    tobaccoStopRawYear
    tobaccoStopClassesRawMonth
    tobaccoStopClassesRawYear
    alcoholBool
    alcoholFrequency
    alcoholStopRawMonth
    alcoholStopRawYear
    streetDrugs
    streetDrugsUse
    medicalMarijuanaCard
    socialSupportSystem
    socialSupportPeople
    religiousBeliefs
    religiousBeliefsDescription
    stillWorking
    stillWorkingReason
    financialAssisstance
    financialAssisstanceReason
    servedInMilitary
    servedInMilitaryBranch
    chemicalsExposed
    chemicals
    physicallyActive
    difficultyTravelling
    physicallyActiveExercise
    difficultyTravellingBool
  }
`;

export const HA_PAIN_FRAGMENT = gql`
  fragment HealthAssessmentPain on HealthAssessmentType {
    id
    inPain
    painStart
    painSeverity
    painLocation
    painQuality
    painLength
    painManagement
    painBetter
    painWorse
  }
`;

export const HA_CONSENT_FRAGMENT = gql`
  fragment HealthAssessmentConsent on HealthAssessmentType {
    id
    patientAcknowledgement
    patientDigitalSignature
  }
`;
