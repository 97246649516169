import moment from 'moment-timezone';
import { Region } from 'shared-components/enums';

// Need to define this here instead of importing from op-utils as this file is shared between px and op

export default class DateTimeConverter {
  /**
   * Converts the given 'YYYY-MM-DD' date string into the the full date string format
   * Depending on the region (e.g. 'DD MMMM YYYY' for AU, 'MMMM DD YYYY' for US)
   * @param {string} dateString The date string to format.
   * @returns {string} A localised date string in the 'DD MMMM YYYY' format.
   */
  public static getFormattedDateAsDDMonthYYYY(dateString?: string, region: Region = Region.AU): string {
    if (dateString && dateString.match('[0-9]{4}-[0-9]{2}-[0-9]{2}')) {
      const newFormat = region === Region.US ? 'MMMM DD, YYYY' : 'DD MMMM YYYY';
      const formattedDate = moment(dateString, 'YYYY-MM-DD').format(newFormat);
      if (formattedDate.toLowerCase() === 'invalid date') {
        return '';
      }
      return formattedDate;
    }

    return '';
  }

  /**
   * Converts the given 'YYYY-MM-DD' date string into the dateFormat format for the current locale.
   * @param {string} dateString The date string to format.
   * @param {string} dateFormat The date format to return in
   * @returns {string} A localised date string in the dateFormat
   */
  public static getFormattedDate(dateString: string, dateFormat: string): string {
    if (dateString && dateString.match('[0-9]{4}-[0-9]{2}-[0-9]{2}')) {
      const formattedDate = moment(dateString, 'YYYY-MM-DD').format(dateFormat);
      if (formattedDate.toLowerCase() === 'invalid date') {
        return '';
      }
      return formattedDate;
    }

    return '';
  }
}

export const calculateMomentAge = (dobMomentFormat: moment.Moment): number => {
  const currentDate = moment();
  return currentDate.diff(dobMomentFormat, 'year');
};

export const displayRawDate = (
  month: string | null,
  year: string | null,
  date?: string | null,
  mmyy = false,
): string => {
  const displayDate = date ? date.padStart(2, '0') : '--';
  const displayMonth = month && parseInt(month) > 0 ? month.padStart(2, '0') : '--';
  const displayYear = year ? year : '----';

  if (mmyy) {
    if (month || year) {
      return `${displayMonth}/${displayYear}`;
    } else {
      return '-';
    }
  } else {
    if (date || month || year) {
      if (import.meta.env.REACT_APP_REGION === Region.US) return `${displayMonth}/${displayDate}/${displayYear}`;
      else return `${displayDate}/${displayMonth}/${displayYear}`;
    } else {
      return '-';
    }
  }
};
