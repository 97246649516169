import { CareplanListFilter, CareplanRule } from '../Interface';

const extractDepartments = (template: CareplanListFilter): string[] => {
  const treatingDepartment = template?.rules?.find((rule: CareplanRule) => rule.field === 'Treating_Department');

  if (treatingDepartment?.defaultValue) {
    const defaultValue = treatingDepartment.defaultValue.replace(/'/g, '"');
    if (defaultValue) {
      return JSON.parse(defaultValue);
    }
  }
  return [];
};

export const shouldDisableTemplate = (template: CareplanListFilter, department: string): boolean => {
  const departments = extractDepartments(template);
  if (departments.length === 0) {
    return false;
  }
  return !departments.includes(department);
};
