// eslint-disable-next-line no-use-before-define
import React, { Component } from 'react';

import { HeaderBar } from 'op-components';

import './SessionTimeout.scss';
// eslint-disable-next-line
interface Props {} // Eslint is very opinionated with no empty interface, but since we need the extension, just ignore this next line.

class SessionTimeout extends Component<Props> {
  public render(): JSX.Element {
    return (
      <div id="session-timeout-page">
        <HeaderBar defaultHeader preventQuery={true} />
        <div className="session-container">
          <div id="session-timeout-image">
            <div className="image"></div>
          </div>
          <main>
            <h1 id="session-title">{'Session timeout'}</h1>
            <p id="session-detail">
              Your session has timed out due to more than 15 minutes inactivity. Please return to the link in your email
              if you wish to continue.
            </p>
          </main>
        </div>
        <div className="timeout-home-bottom-navigator"></div>
      </div>
    );
  }
}

export default SessionTimeout;
