// eslint-disable-next-line no-use-before-define
import React, { Component } from 'react';
import { MandatoryPatient } from 'op-interfaces';
import './UKPatientCard.scss';
import classnames from 'classnames';
import { Tooltip } from '@mui/material';

interface Props {
  patient: MandatoryPatient;
}

const GC_NUMBER_DEFAULT_TEXT = 'Patient not submitted';
const MISSING_PLACEHOLDER = 'Not provided yet';

class UKPatientCard extends Component<Props> {
  public constructor(props: Props) {
    super(props);
  }

  private nhsFormat(nhsNumber: any, format: string) {
    let i = 0;
    const val = nhsNumber.toString().replace(/ /g, '');
    return format.replace(/#/g, () => (typeof val[i] != 'undefined' ? val[i++] : ''));
  }

  public render(): JSX.Element {
    const { patient } = this.props;
    const address = patient.address ? patient.address.ukFormattedAddress : MISSING_PLACEHOLDER;
    const firstName = patient.firstName ? patient.firstName : '';
    const lastName = patient.lastName ? patient.lastName : '';
    const name = firstName === '' && lastName === '' ? MISSING_PLACEHOLDER : firstName + ' ' + lastName;
    const nhsNumber =
      patient.idb && patient.idb !== '' ? this.nhsFormat(patient.idb, '### ### ####') : MISSING_PLACEHOLDER;
    const dob = patient.dob ? patient.dob : MISSING_PLACEHOLDER;
    const gcNumber = patient.ida ? patient.ida : GC_NUMBER_DEFAULT_TEXT;

    return (
      <div className="patient-card" id="patient-card">
        <Tooltip arrow title="Patient Card">
          <table className="card-table">
            <tbody>
              <tr>
                <th className="twenty">Name</th>
                <th className={classnames('twenty', { disabled: gcNumber === GC_NUMBER_DEFAULT_TEXT })}>GC number</th>
                <th className="twenty">NHS Number</th>
                <th className="twenty">Date of birth</th>
                <th className="forty">Address</th>
              </tr>
              <tr>
                <td className={classnames('twenty', { disabled: name === MISSING_PLACEHOLDER })}>{name}</td>
                <td className={classnames('twenty', { disabled: gcNumber === GC_NUMBER_DEFAULT_TEXT })}>{gcNumber}</td>
                <td className={classnames('twenty', { disabled: nhsNumber === MISSING_PLACEHOLDER })}>{nhsNumber}</td>
                <td className={classnames('twenty', { disabled: dob === MISSING_PLACEHOLDER })}>{dob}</td>
                <td className={classnames('twenty', { disabled: address === MISSING_PLACEHOLDER })}>{address}</td>
              </tr>
            </tbody>
          </table>
        </Tooltip>
      </div>
    );
  }
}

export default UKPatientCard;
