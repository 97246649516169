import React, { useEffect, useState } from 'react';
import * as Sentry from '@sentry/browser';

import { Logger } from 'shared-components/utils';

const logger = new Logger('SentryUser');

const SentryUser = (): JSX.Element => {
  const [username, setUsername] = useState('Anonymous');
  const maxRetries = 3;
  const retryDelay = 1000;

  const fetchUserId = async (retryCount = 0) => {
    const url = `${window.location.origin}/server/get_user`;
    try {
      const res = await fetch(url);

      if (!res.ok) {
        throw new Error(`Network response was not ok: ${res.status}`);
      }
      const json: { username: string; error: string } = await res.json();
      const { username, error } = json;

      if (error) {
        logger.error(`"${username}" Failed to fetchUserId at this url: ${url}. error=${error}`);
      } else {
        setUsername(json.username);
      }
    } catch (err: any) {
      if (retryCount < maxRetries) {
        setTimeout(() => fetchUserId(retryCount + 1), retryDelay); // Retry after a delay
      } else {
        setUsername('FailedToFetch');
      }
    }
  };

  useEffect(() => {
    fetchUserId();
  }, []);

  useEffect(() => {
    Sentry.getCurrentScope().setUser({
      username: username,
    });
  }, [username]);
  return <></>;
};
export default SentryUser;
