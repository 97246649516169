import { SelectOptionType } from 'shared-components/components/FormFields';

export const DEFAULT_OPTION: SelectOptionType[] = [
  {
    label: '',
    value: '',
  },
];

export const filterListdata = (data: any, listCategory: string): any => {
  if (!data) return [];
  const categoryOptions = data.carepathDataReferenceList.filter(
    (options: any): boolean => options.category.category == listCategory,
  );
  const sorted = [...categoryOptions].sort((a: any, b: any) => a.listWeight - b.listWeight);
  return sorted.map((option: any) => ({
    label: option.data.option,
    value: option.data.option,
  }));
};
