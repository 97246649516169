import React, { useEffect } from 'react';
import { useFormikContext, Field, FormikProps } from 'formik';

import { HelperMessage, FormRow } from 'shared-components/components';
import { ToggleButtonGroupField } from 'shared-components/components/FormikComponents';
import { yesNoOptions } from 'op-utils';

import { getLastUpdateUser } from '../../helpers';
import { LastUpdateUserDetails } from '../../shared/interfaces';
import { PreCtChartCheckFormValues } from '../interfaces/PreCtChartCheckInterfaces';
import { preCtChartCheckContent } from '../constants';

interface Props {
  updateAssessment: (name: string, value: any) => void;
  lastUpdateUserData: LastUpdateUserDetails[];
}
const AdvancedCareDirective = ({ updateAssessment, lastUpdateUserData }: Props): JSX.Element => {
  const { setFieldTouched, setFieldValue, values }: FormikProps<PreCtChartCheckFormValues> = useFormikContext();
  const { hasCurrentAcd, acdInfoRequested, hasCurrentResusMxPlan } =
    preCtChartCheckContent.advancedCareDirective.fields;
  const { hasCurrentAcdQCL, hasCurrentResusMxPlanQCL } = preCtChartCheckContent.advancedCareDirective.infoHelpers;

  useEffect(() => {
    if (values.hasCurrentAcd === true) {
      setFieldValue('acdInfoRequested', null);
      setFieldTouched('acdInfoRequested', false);
    }
  }, [values.hasCurrentAcd]);

  return (
    <>
      <FormRow fieldLabel={hasCurrentAcd} fieldName={'hasCurrentAcd'}>
        <Field
          name="hasCurrentAcd"
          component={ToggleButtonGroupField}
          label={hasCurrentAcd}
          options={yesNoOptions}
          handleMutation={updateAssessment}
          lastUpdateUser={getLastUpdateUser(lastUpdateUserData, 'hasCurrentAcd')}
        />
        {values.hasCurrentAcd === true && (
          <>
            <HelperMessage fieldName={'hasCurrentAcd'} fieldText={hasCurrentAcdQCL} helperType="info" />
          </>
        )}
      </FormRow>
      {values.hasCurrentAcd === false && (
        <FormRow fieldLabel={acdInfoRequested} fieldName={'acdInfoRequested'}>
          <Field
            name="acdInfoRequested"
            component={ToggleButtonGroupField}
            label={acdInfoRequested}
            options={yesNoOptions}
            handleMutation={updateAssessment}
            lastUpdateUser={
              (values.acdInfoRequested === true || values.acdInfoRequested === false) &&
              getLastUpdateUser(lastUpdateUserData, 'acdInfoRequested')
            }
          />
        </FormRow>
      )}
      <FormRow fieldLabel={hasCurrentResusMxPlan} fieldName={'hasCurrentResusMxPlan'}>
        <Field
          name="hasCurrentResusMxPlan"
          component={ToggleButtonGroupField}
          label={hasCurrentResusMxPlan}
          options={yesNoOptions}
          handleMutation={updateAssessment}
          lastUpdateUser={getLastUpdateUser(lastUpdateUserData, 'hasCurrentResusMxPlan')}
        />
        {values.hasCurrentResusMxPlan === true && (
          <>
            <HelperMessage fieldName={'hasCurrentResusMxPlan'} fieldText={hasCurrentResusMxPlanQCL} helperType="info" />
          </>
        )}
      </FormRow>
    </>
  );
};

export default AdvancedCareDirective;
