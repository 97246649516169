import { decimalFormatter } from '../VolumingPage/OrgansAtRisk/OrgansAtRisk';

// CPOT quick maths
export const calculateRevisedDose = (cpotFraction: number, doses: (number | null)[], fractions: number) =>
  doses?.map((dose: number | null) => {
    return decimalFormatter(Number(dose) * (cpotFraction / fractions));
  });
export const calculateRemainingFractions = (cpotFraction: number, originalFraction: number) =>
  originalFraction - cpotFraction + 1;

export const calculateRemainingDose = (cpotFraction: number, doses: (number | null)[], fractions: number) =>
  doses?.map((dose: string | number | null) => {
    return decimalFormatter(Number(dose) - Number(dose) * ((cpotFraction - 1) / fractions));
  });

export const calculateTotalDoses = (
  cpotFraction: number,
  doses: (number | null)[],
  newDoses: (number | null)[],
  fractions: number,
) => {
  // If cpot fraction not set, keep as the original dose
  if (!cpotFraction) return doses;
  // Calculate the new total doses, using the ratio of treated original dose and then adding
  // the remaining dose
  const totalDoses = doses.map((originalDose: string | number | null, index: number) => {
    const dose = newDoses[index];
    return decimalFormatter(Number(originalDose) * ((cpotFraction - 1) / fractions) + Number(dose));
  });
  return totalDoses;
};

export const calculateTotalFractions = (cpotFraction: number, fractions: number) =>
  cpotFraction ? cpotFraction + fractions - 1 : fractions;
