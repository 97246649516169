import { FormikValues, validateYupSchema, yupToFormErrors } from 'formik';
import { CurrentAppConfig } from 'op-pages/RO/Careplan/AppConfig';
import { EMAIL_LENGTH_VALIDATION, EMAIL_VALIDATION, PHONE_VALIDATION, VALUE_REQUIRED } from 'op-pages/Shared/constants';
import { Region } from 'shared-components/enums';
import { SelectOptions } from 'shared-components/interfaces';
import { AU_PHONE_REGEX, EMAIL_STRING_REGEX, UK_PHONE_REGEX, US_PHONE_REGEX } from 'shared-components/utils/Regex';
import * as Yup from 'yup';

export const generateValidationSchema = (values: FormikValues, departmentRefData: readonly SelectOptions[]): any => {
  const region = import.meta.env.REACT_APP_REGION;
  let mobileRegex = AU_PHONE_REGEX;

  if (region === Region.US) {
    mobileRegex = US_PHONE_REGEX;
  } else if (region === Region.UK) {
    mobileRegex = new RegExp(UK_PHONE_REGEX);
  }

  const departmentRefDataValuesList: string[] = departmentRefData.map((item: SelectOptions) => item.value.toString());

  const patientAccessModalSchema = {
    userProfile: Yup.object().shape({
      registrationAccessType: Yup.string().ensure().required(VALUE_REQUIRED),
    }),
    horizonCenterId: Yup.string().ensure().required(VALUE_REQUIRED).oneOf(departmentRefDataValuesList, VALUE_REQUIRED),
    selectedFormPack: Yup.string().ensure().required(VALUE_REQUIRED),
    primaryPhone: Yup.string()
      .ensure()
      .when('userProfile.registrationAccessType', {
        is: 'atHome',
        then: Yup.string().required(VALUE_REQUIRED).matches(mobileRegex, PHONE_VALIDATION),
      }),
    email: Yup.string()
      .ensure()
      .when('userProfile.registrationAccessType', {
        is: 'atHome',
        then: Yup.string().required(VALUE_REQUIRED).matches(EMAIL_STRING_REGEX, EMAIL_VALIDATION),
      })
      .max(CurrentAppConfig.EmailMaxLength, EMAIL_LENGTH_VALIDATION),
  };

  try {
    validateYupSchema<FormikValues>(values, Yup.object(patientAccessModalSchema), true);
  } catch (err) {
    return yupToFormErrors(err);
  }
};
