import { ToggleButtonGroupField } from 'shared-components/components/FormikComponents';

export const FIELDS = {
  FORM_HEADING: {
    NAME: 'formHeading',
    TITLE: 'Address',
  },
  SAME_POSTAL_ADDRESS: {
    NAME: 'postalAddressSameAsResidential',
    TITLE: 'Same as above (Residential address)',
    COMPONENT: ToggleButtonGroupField,
  },
};
