import * as Yup from 'yup';
import { VALUE_REQUIRED } from './Helper';
import { FormikValues, validateYupSchema, yupToFormErrors } from 'formik';
import { ConvertedDiagnosisType } from '../Interface';
import { CurrentAppConfig } from '../../AppConfig';

export const generateValidationSchema = (
  oncologyType: string,
  values: FormikValues,
  metastasisDiagnosis: ConvertedDiagnosisType | undefined,
  primaryDiagnosis: ConvertedDiagnosisType,
): any => {
  let primaryDiagnosisSchema = {};
  let metastasisDiagnosisSchema = {};

  const primaryStagingConfig =
    oncologyType === 'radiation'
      ? CurrentAppConfig.QuestionValidationChildren.Staging.Primary.RO
      : CurrentAppConfig.QuestionValidationChildren.Staging.Primary.MO;
  const metastasisStagingConfig =
    oncologyType === 'radiation'
      ? CurrentAppConfig.QuestionValidationChildren.Staging.Metastasis.RO
      : CurrentAppConfig.QuestionValidationChildren.Staging.Metastasis.MO;
  const isMetastasisDiagnosisDateRequired = metastasisStagingConfig.includes('diagnosisDate');
  const isPrimaryDiagnosisDateRequired = primaryStagingConfig.includes('diagnosisDate');
  const isMetastasisLateralityRequired = metastasisStagingConfig.includes('laterality');
  const isPrimaryLateralityRequired = primaryStagingConfig.includes('laterality');

  if (metastasisDiagnosis) {
    metastasisDiagnosisSchema = {
      ...(isMetastasisLateralityRequired && {
        metastasisLaterality: Yup.object()
          .shape({
            value: Yup.string(),
            label: Yup.string(),
          })
          .nullable()
          .required(VALUE_REQUIRED),
      }),
      ...(isMetastasisDiagnosisDateRequired && {
        metastasisDiagnosisDate: Yup.string().ensure().required(VALUE_REQUIRED),
      }),
    };
  }

  if (primaryDiagnosis) {
    if (
      !metastasisDiagnosis ||
      (metastasisDiagnosis && metastasisDiagnosis?.relatedPrimaryDiagnosis?.draft !== false)
    ) {
      primaryDiagnosisSchema = {
        ...(isPrimaryLateralityRequired && {
          laterality: Yup.object()
            .shape({
              value: Yup.string(),
              label: Yup.string(),
            })
            .nullable()
            .required(VALUE_REQUIRED),
        }),
        ...(isPrimaryDiagnosisDateRequired && { diagnosisDate: Yup.string().ensure().required(VALUE_REQUIRED) }),
      };
    }
  }

  const stagingValidationSchema = { ...primaryDiagnosisSchema, ...metastasisDiagnosisSchema };

  try {
    validateYupSchema<FormikValues>(values, Yup.object(stagingValidationSchema), true);
  } catch (err) {
    return yupToFormErrors(err);
  }

  return {};
};
