import React from 'react';
import { isUs } from 'op-utils';
import { CustomTheme, Stack, useTheme } from '@mui/material';
import { StandardDialog } from 'shared-components/components';

interface ModalCreateNewPatientIProps {
  isOpen: boolean;
  handleClose: () => void;
  isLoading: boolean;
  submissionProcessingTime: number;
  rightButtonHandleClick: () => void;
}

const ModalCreateNewPatient = (props: ModalCreateNewPatientIProps) => {
  const { isOpen, handleClose, submissionProcessingTime = 1, isLoading, rightButtonHandleClick } = props;
  const theme: CustomTheme = useTheme();
  const title = theme.custom.title;
  return (
    <StandardDialog
      onClose={handleClose}
      open={isOpen}
      maxWidth="sm"
      title="Create New Patient"
      onSubmit={rightButtonHandleClick}
      submitText="Create patient"
      submitButtonProps={{
        disabled: isLoading,
        loading: isLoading,
      }}>
      <Stack>
        {isUs() ? (
          <p>Proceeding will create a new patient record. Please ensure all patient details are correct.</p>
        ) : (
          <p>
            Proceeding will create a new patient record in both {title} and Mosaiq. Please ensure all patient details
            are correct.
          </p>
        )}

        <p>
          It may take up to {submissionProcessingTime} min{submissionProcessingTime > 1 && 's'}, please do{' '}
          <b>
            <u>NOT</u>
          </b>{' '}
          close the browser while loading.
        </p>
        <br />
      </Stack>
    </StandardDialog>
  );
};

export default ModalCreateNewPatient;
