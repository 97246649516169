import classNames from 'classnames';
import { NavItemType } from 'op-pages/PX/models';
import { pxNavList } from 'op-pages/PX/utils';
import { memo, useMemo } from 'react';

import { useHistory, useLocation } from 'react-router-dom';
import './BottomNavigation.scss';

const BottomNavigation = (): JSX.Element => {
  const componentId = 'bottom-navigation';

  const history = useHistory();
  const location = useLocation();
  const pathname = useMemo<string>(() => location.pathname, [location]);

  const handleNavClick = (url: string): void => history.push(url);

  return (
    <div id={componentId} style={{ display: 'flex', justifyContent: 'space-around', width: '100%' }}>
      {pxNavList.map((navItem: NavItemType) => {
        const urlComponent = navItem.url;
        const selected = pathname.includes(urlComponent);

        return (
          <div
            key={`${componentId}-${navItem.title}`}
            onClick={(): void => {
              if (!selected || urlComponent === '/px/information') handleNavClick(navItem.url);
            }}
            className={classNames('nav-item text-nowrap text-center', { selected })}>
            <div className="icon">{navItem.icon}</div>
            <div className="link-text">{navItem.title}</div>
          </div>
        );
      })}
    </div>
  );
};

export default memo(BottomNavigation);
