// eslint-disable-next-line no-use-before-define
import { useMutation } from '@apollo/client';
import { HACancerItem } from 'op-classes';
import { MoreInfoText, PageTitle } from 'op-components';
import { HAMutationType } from 'op-enums';
import { CancerItem, FamilyHistoryItem, RadiotherapyCondition } from 'op-interfaces';
import { appendZeroInFront, isUs, resolveListDataValue } from 'op-utils';
import React, { useEffect, useState } from 'react';
import { FreeTextArea, FreeTextField, SectionField, SegmentedInput } from 'shared-components/components/FormFields';
import { Months } from 'shared-components/enums';
import { Edit, MinusNoFill } from 'shared-components/images';
import { ListData } from 'shared-components/interfaces';
import { SegementedInputYesNoUnsure } from 'shared-components/utils';
import { usePrevious } from 'shared-components/utils/CustomHooks';
import { ADD_FAMILY_HISTORY, DELETE_FAMILY_HISTORY } from '../../HAQueries';
import { CR_FIELDS } from '../constants';
import './HAMedicalHistoryCancer.scss';
import HAMedicalHistoryCancerModal from './HAMedicalHistoryCancerModal';
import lodash from 'lodash';
import { DropDownRODay, DropDownROMonth, DropDownROYear } from '../../HASharedComponents';
import { Add as AddIcon, Close as CloseIcon } from '@mui/icons-material';

const CRUD_OPERATION = {
  CREATE: 'CREATE',
  DELETE: 'DELETE',
};

const OTHER = 'Other';

interface Props {
  patientId: string;
  medicalHistoryCancer: HACancerItem;
  autosave: (updateObject: object, replaceId?: string, refetch?: any, mutationType?: HAMutationType) => Promise<void>;
  performCRUD: (
    haId: string,
    operation: string,
    objectType: string,
    itemId?: string,
    refetch?: any,
    relatedObjectId?: string,
  ) => void;
  refetch: any;
  validateOnLoad: boolean;
  treatmentTypesRefData: ListData[];
  treatmentStagesRefData: ListData[];
  familyMemberRefData: ListData[];
}

const HAMedicalHistoryCancer = (props: Props): JSX.Element => {
  const {
    medicalHistoryCancer,
    autosave,
    performCRUD,
    refetch,
    treatmentTypesRefData,
    treatmentStagesRefData,
    familyMemberRefData,
    patientId,
    validateOnLoad,
  } = props;

  const initialViewedFields = () => {
    if (validateOnLoad) {
      const viewed = new Set(['previousCancer', 'otherRadiotherapy', 'familyHistory', 'familyHistoryAnswer']);
      Object.keys(medicalHistoryCancer).forEach(function (key) {
        if (Array.isArray(medicalHistoryCancer[key])) {
          medicalHistoryCancer[key].forEach(function (item: any, index: number) {
            Object.keys(item).forEach(function (field) {
              if (field !== 'id' && field !== '__typename') {
                const place = index + 1;
                viewed.add(key + '-' + place + '-' + field);
              }
            });
          });
        }
      });
      return viewed;
    }
    return new Set();
  };

  const [showCancerModal, setShowCancerModal] = useState(false);
  const [showFamilyHistoryModal, setShowFamilyHistoryModal] = useState(false);
  const [selectedCancerId, setSelectedCancerId] = useState<string | undefined>(undefined);
  const [selectedFamilyHistoryId, setSelectedFamilyHistoryId] = useState<string | undefined>(undefined);
  const [viewedFields, setViewedFields] = useState(initialViewedFields());

  const previousData = usePrevious(medicalHistoryCancer);

  const [addFamilyHistory, { loading: addFamilyHistoryLoading }] = useMutation(ADD_FAMILY_HISTORY, {
    refetchQueries: ['HealthAssessment'],
    onCompleted: (data: any): void => {
      setShowFamilyHistoryModal(true);
      setSelectedFamilyHistoryId(data.addFamilyHistory.usFamilyHistory.id);
    },
  });
  const [deleteFamilyHistory, { loading: deleteFamilyHistoryLoading }] = useMutation(DELETE_FAMILY_HISTORY, {
    refetchQueries: ['HealthAssessment'],
  });

  useEffect(() => {
    const currentCancer = medicalHistoryCancer.cancer;
    const prevCancer = previousData?.cancer;

    // If another cancer was added trigger the modal to show and pass in the selected id
    if (currentCancer?.length > prevCancer?.length) {
      setShowCancerModal(true);
      setSelectedCancerId(currentCancer[currentCancer.length - 1].id);
    }
  }, [medicalHistoryCancer]);

  const shouldDisplayDynamic = (value: string | null): boolean => value === 'YES';

  const renderCancerList = (): JSX.Element[] => {
    return medicalHistoryCancer.cancer.map((cancer, index): JSX.Element => {
      const isFirstTreatment = medicalHistoryCancer.cancer.length === 1;

      return (
        <div key={`cancerList-${index}`}>
          <div className={'flex previous-cancer-list'} key={`cancerList=${index}`}>
            <div className="flex details-container">
              <div>
                <div className="cancer-condition">{cancer.cancerType}</div>
                <div className="cancer-types">{renderTreatementForCancer(cancer)}</div>
              </div>
              <div
                className="flex edit-cancer"
                role="button"
                onClick={(): void => {
                  setShowCancerModal(true);
                  setSelectedCancerId(cancer.id);
                }}>
                {'Edit'} <Edit className="icon edit-pencil" />
              </div>
            </div>

            {!isFirstTreatment && (
              <div
                className="delete-cancer"
                onClick={(): void => {
                  performCRUD(medicalHistoryCancer.id, CRUD_OPERATION.DELETE, 'cancer', cancer.id, refetch);
                }}>
                <CloseIcon color="primary" className="icon" />
              </div>
            )}
          </div>
        </div>
      );
    });
  };

  const renderFamilyHistoryList = (): JSX.Element[] => {
    const isFirstTreatment = medicalHistoryCancer.usFamilyHistory.length === 1;

    const resolveFamilyMemberOrOther = (familyHistory: FamilyHistoryItem): string => {
      const familyMember = resolveListDataValue(familyHistory?.familyMember, familyMemberRefData);
      const displayValue = familyMember === OTHER && familyHistory.other ? familyHistory.other : familyMember;
      return displayValue;
    };

    const allFamilyMembers = medicalHistoryCancer?.usFamilyHistory?.map((familyHistory: FamilyHistoryItem): string =>
      resolveFamilyMemberOrOther(familyHistory),
    );
    const seenFamilyMembers: string[] = [];

    return medicalHistoryCancer?.usFamilyHistory?.map(
      (familyHistory: FamilyHistoryItem, index: number): JSX.Element => {
        // Check if this family member exists multiple times, and index if needed
        const familyMember = resolveFamilyMemberOrOther(familyHistory);
        let displayValue = familyMember;
        if (allFamilyMembers.filter((x: string) => x === familyMember).length > 1) {
          const index = seenFamilyMembers.filter((x: string) => x === familyMember).length + 1;
          seenFamilyMembers.push(familyMember);
          displayValue = `${displayValue} ${index}`;
        }

        return (
          <div key={`familyHistoryParent-${index}`}>
            <div className={'flex previous-cancer-list'} key={`familyHistory-${index}`}>
              <div className="flex details-container">
                <div>
                  <div className="cancer-condition">{displayValue}</div>
                  <div className="cancer-types">{familyHistory?.cancerType}</div>
                </div>
                <div
                  className="flex edit-cancer"
                  role="button"
                  onClick={(): void => {
                    setShowFamilyHistoryModal(true);
                    setSelectedFamilyHistoryId(familyHistory.id);
                  }}>
                  {'Edit'} <Edit className="icon edit-pencil" />
                </div>
              </div>

              {!isFirstTreatment && (
                <div
                  className="delete-cancer"
                  onClick={(): void => {
                    if (!deleteFamilyHistoryLoading) {
                      deleteFamilyHistory({ variables: { id: familyHistory.id, patientId } });
                    }
                  }}>
                  <CloseIcon color="primary" className="icon" />
                </div>
              )}
            </div>
          </div>
        );
      },
    );
  };

  const renderTreatementForCancer = (cancer: CancerItem): JSX.Element[] =>
    cancer.treatment.map(
      (cancer, index): JSX.Element => (
        <div key={`treatmentForCancer-${index}`}>
          {resolveListDataValue(cancer.treatmentType, treatmentTypesRefData)}
        </div>
      ),
    );

  const renderAdditionalItem = (buttonText: string, onClick: () => void): JSX.Element => (
    <div className="additional-item-button" onClick={(): void => onClick()}>
      <AddIcon className="icon" color="primary" />
      {buttonText}
    </div>
  );

  const renderRadiotherapyDetails = (): JSX.Element => {
    const otherRadiotherapyConditionsSelection: JSX.Element[] = medicalHistoryCancer.otherRadiotherapyConditions.map(
      (value, index): JSX.Element => {
        return renderExpandableElement(
          index,
          (object: any): void => {
            autosave({ id: parseInt(patientId), otherRadiotherapyConditions: { id: value.id, ...object } }, value.id);
          },
          (): void => {
            performCRUD(medicalHistoryCancer.id, CRUD_OPERATION.DELETE, 'otherRadiotherapy', value.id, refetch);
          },
        );
      },
    );

    return (
      <>
        {otherRadiotherapyConditionsSelection}
        {renderAdditionalItem('Add another condition', (): void => {
          performCRUD(medicalHistoryCancer.id, CRUD_OPERATION.CREATE, 'otherRadiotherapy', undefined, refetch);
        })}
      </>
    );
  };

  const renderHistoryDetails = (): JSX.Element => (
    <SectionField title={CR_FIELDS.HISTORY.DETAILS.TITLE} htmlFor={CR_FIELDS.HISTORY.DETAILS.NAME}>
      <FreeTextArea
        inputName={CR_FIELDS.HISTORY.DETAILS.NAME}
        defaultValue={medicalHistoryCancer.familyHistoryAnswer}
        placeholder=""
        onBlur={(e): void => {
          autoSaveAndValidate(
            {
              id: parseInt(patientId),
              familyHistoryAnswer: e.target.value,
            },
            CR_FIELDS.HISTORY.DETAILS.NAME,
            false,
          );
        }}
      />
    </SectionField>
  );

  const renderTreatmentDateField = (
    index: number,
    inputType: string,
    stateList: RadiotherapyCondition[],
    onChange: (object: any) => void,
  ): JSX.Element => {
    const inputName = `${inputType}-${index + 1}`;

    const dateOfTreatment = `${appendZeroInFront(stateList[index].treatmentRawDay)}${appendZeroInFront(
      stateList[index].treatmentRawMonth,
    )}${stateList[index].treatmentRawYear}`;

    stateList[index].treatmentDate = dateOfTreatment;

    const day = (
      <DropDownRODay
        id={`${inputName}-day`}
        value={stateList[index].treatmentRawDay}
        onChange={(val): void => {
          const saveObject = {
            condition: stateList[index].condition,
            hospital: stateList[index].hospital,
            doctor: stateList[index].doctor,
            bodyPart: stateList[index].bodyPart,
            treatmentRawDay: val,
            treatmentRawMonth: stateList[index].treatmentRawMonth,
            treatmentRawYear: stateList[index].treatmentRawYear,
          };
          onChange(saveObject);
          addToViewedFields(inputName + '-treatmentRawDay');
        }}
        selectedMonth={parseInt(stateList[index].treatmentRawMonth)}
        selectedYear={parseInt(stateList[index].treatmentRawYear)}
      />
    );
    const month = (
      <DropDownROMonth
        id={`${inputName}-month`}
        value={
          parseInt(stateList[index].treatmentRawMonth) > 0
            ? Months[parseInt(stateList[index].treatmentRawMonth) - 1]
            : ''
        }
        onChange={(val): void => {
          const resultVal = Months.indexOf(val) >= 0 ? Months.indexOf(val) + 1 : '';
          const saveObject = {
            condition: stateList[index].condition,
            hospital: stateList[index].hospital,
            doctor: stateList[index].doctor,
            bodyPart: stateList[index].bodyPart,
            treatmentRawDay: stateList[index].treatmentRawDay,
            treatmentRawMonth: resultVal.toString(),
            treatmentRawYear: stateList[index].treatmentRawYear,
          };
          onChange(saveObject);
          addToViewedFields(inputName + '-treatmentRawMonth');
        }}
        selectedYear={parseInt(stateList[index].treatmentRawYear)}
      />
    );

    return (
      <SectionField title={'Date of treatment'} htmlFor={inputName}>
        <MoreInfoText moreInfoText={`${'Please enter date if known'}`}>
          <div className="dob-fields">
            {isUs() ? month : day}
            {isUs() ? day : month}
            <DropDownROYear
              id={`${inputName}-year`}
              value={stateList[index].treatmentRawYear}
              onChange={(val): void => {
                const saveObject = {
                  condition: stateList[index].condition,
                  hospital: stateList[index].hospital,
                  doctor: stateList[index].doctor,
                  bodyPart: stateList[index].bodyPart,
                  treatmentRawDay: stateList[index].treatmentRawDay,
                  treatmentRawMonth: stateList[index].treatmentRawMonth,
                  treatmentRawYear: val,
                };
                onChange(saveObject);
                addToViewedFields(inputName + '-treatmentRawYear');
              }}
            />
          </div>
        </MoreInfoText>
      </SectionField>
    );
  };

  const renderExpandableElement = (
    index: number,
    onChange: (object: any) => void,
    removeFunction: () => void,
  ): JSX.Element => {
    const inputType = 'otherRadiotherapyConditions';
    const inputTitle = 'Condition';
    const inputName = `${inputType}-${index + 1}`;
    const stateList = lodash.cloneDeep(medicalHistoryCancer.otherRadiotherapyConditions);
    const removable = medicalHistoryCancer.otherRadiotherapyConditions.length !== 1;

    return (
      <div key={`radiotherapy-${index}`} className="infinite-dropdown-item">
        <SectionField htmlFor={inputName} title={`${inputTitle} ${index + 1}`}>
          <FreeTextField
            inputKey={stateList[index].condition}
            inputName={`${inputName}-condition`}
            inputType="text"
            defaultValue={stateList[index].condition}
            onBlur={(e): void => {
              const saveObject = {
                condition: e.target.value,
                hospital: stateList[index].hospital,
                doctor: stateList[index].doctor,
                bodyPart: stateList[index].bodyPart,
                treatmentRawDay: stateList[index].treatmentRawDay,
                treatmentRawMonth: stateList[index].treatmentRawMonth,
                treatmentRawYear: stateList[index].treatmentRawYear,
              };
              addToViewedFields(`${inputName}-condition`);
              onChange(saveObject);
            }}
          />
        </SectionField>
        <SectionField htmlFor={inputName} title={`${'Name of clinic/hospital'}`}>
          <FreeTextField
            inputKey={stateList[index].hospital}
            inputName={`${inputName}-hospital`}
            inputType="text"
            defaultValue={stateList[index].hospital}
            onBlur={(e): void => {
              const saveObject = {
                condition: stateList[index].condition,
                hospital: e.target.value,
                doctor: stateList[index].doctor,
                bodyPart: stateList[index].bodyPart,
                treatmentRawDay: stateList[index].treatmentRawDay,
                treatmentRawMonth: stateList[index].treatmentRawMonth,
                treatmentRawYear: stateList[index].treatmentRawYear,
              };
              addToViewedFields(`${inputName}-hospital`);
              onChange(saveObject);
            }}
          />
        </SectionField>
        <SectionField htmlFor={inputName} title={`${'Doctor'}`}>
          <FreeTextField
            inputKey={stateList[index].doctor}
            inputName={`${inputName}-doctor`}
            inputType="text"
            defaultValue={stateList[index].doctor}
            onBlur={(e): void => {
              const saveObject = {
                condition: stateList[index].condition,
                hospital: stateList[index].hospital,
                doctor: e.target.value,
                bodyPart: stateList[index].bodyPart,
                treatmentRawDay: stateList[index].treatmentRawDay,
                treatmentRawMonth: stateList[index].treatmentRawMonth,
                treatmentRawYear: stateList[index].treatmentRawYear,
              };
              addToViewedFields(`${inputName}-doctor`);
              onChange(saveObject);
            }}
          />
        </SectionField>
        <SectionField htmlFor={inputName} title="What part of your body?">
          <MoreInfoText moreInfoText="e.g. left hand, nose, left side of neck">
            <FreeTextField
              inputKey={stateList[index].bodyPart}
              inputName={`${inputName}-bodyPart`}
              inputType="text"
              defaultValue={stateList[index].bodyPart}
              onBlur={(e): void => {
                const saveObject = {
                  condition: stateList[index].condition,
                  hospital: stateList[index].hospital,
                  doctor: stateList[index].doctor,
                  bodyPart: e.target.value,
                  treatmentDate: stateList[index].treatmentDate,
                  treatmentRawDay: stateList[index].treatmentRawDay,
                  treatmentRawMonth: stateList[index].treatmentRawMonth,
                  treatmentRawYear: stateList[index].treatmentRawYear,
                };
                addToViewedFields(`${inputName}-bodyPart`);
                onChange(saveObject);
              }}
            />
          </MoreInfoText>
        </SectionField>
        {renderTreatmentDateField(index, inputType, stateList, onChange)}

        {removable && (
          <div className="remove-item">
            <div
              onClick={(): void => {
                removeFunction();
              }}>
              <MinusNoFill className="icon" />
              {'Remove'}
            </div>
          </div>
        )}
      </div>
    );
  };

  const autoSaveAndValidate = async (
    updateObject: object,
    validationKey: string,
    setRefetch: boolean,
  ): Promise<void> => {
    // Save the data
    const autoSave = () => {
      return setRefetch ? autosave(updateObject, undefined, refetch) : autosave(updateObject);
    };

    await autoSave().then(() => {
      const newViewedFields = [...viewedFields];
      newViewedFields.push(validationKey);
      setViewedFields(new Set([...viewedFields]));
    });
  };
  const addToViewedFields = (validationKey: string): any => {
    const newViewedFields = [...viewedFields];
    newViewedFields.push(validationKey);
    setViewedFields(new Set(newViewedFields));
  };

  return (
    <>
      <PageTitle title={`Medical history: ${isUs() ? '1' : 'cancer'}`} idPrefix="ha" />
      <div id="ha-fields" className="ha-medical-history-cancer">
        <SectionField htmlFor={CR_FIELDS.CANCER.NAME} title={CR_FIELDS.CANCER.TITLE}>
          <SegmentedInput
            fieldName={CR_FIELDS.CANCER.NAME}
            options={SegementedInputYesNoUnsure}
            defaultSelected={medicalHistoryCancer.previousCancer}
            itemSelected={(selectedItem): void => {
              const selectedValue = (selectedItem as ListData).id;
              // Only trigger save if user selects something different
              if (medicalHistoryCancer.previousCancer !== selectedValue) {
                autoSaveAndValidate(
                  { id: parseInt(patientId), previousCancer: selectedValue },
                  CR_FIELDS.CANCER.NAME,
                  refetch,
                );
              }
            }}
          />
        </SectionField>
        <HAMedicalHistoryCancerModal
          medicalHistoryCancer={medicalHistoryCancer}
          dismissModal={() => setShowCancerModal(false)}
          selectedCancerId={selectedCancerId}
          treatmentTypesRefData={treatmentTypesRefData}
          treatmentStagesRefData={treatmentStagesRefData}
          patientId={patientId}
          isOpen={!!(showCancerModal && selectedCancerId)}
        />

        {medicalHistoryCancer.previousCancer === 'YES' && (
          <>
            {renderCancerList()}
            {renderAdditionalItem('Add another cancer', (): void => {
              performCRUD(medicalHistoryCancer.id, CRUD_OPERATION.CREATE, 'cancer', undefined, refetch);
            })}
          </>
        )}

        <SectionField htmlFor={CR_FIELDS.RADIOTHERAPY.NAME} title={CR_FIELDS.RADIOTHERAPY.TITLE}>
          <MoreInfoText
            moreInfoText={CR_FIELDS.RADIOTHERAPY.INFO}
            showIcon={true}
            showInfo={medicalHistoryCancer.otherRadiotherapy === 'YES'}>
            <SegmentedInput
              fieldName={CR_FIELDS.RADIOTHERAPY.NAME}
              options={SegementedInputYesNoUnsure}
              defaultSelected={medicalHistoryCancer.otherRadiotherapy}
              itemSelected={(selectedItem): void => {
                const selectedValue = (selectedItem as ListData).id;
                // Only trigger save if user selects something different
                if (medicalHistoryCancer.otherRadiotherapy !== selectedValue) {
                  autoSaveAndValidate(
                    { id: parseInt(patientId), otherRadiotherapy: selectedValue },
                    CR_FIELDS.RADIOTHERAPY.NAME,
                    refetch,
                  );
                }
              }}
            />
          </MoreInfoText>
        </SectionField>
        {shouldDisplayDynamic(medicalHistoryCancer.otherRadiotherapy) && renderRadiotherapyDetails()}

        <SectionField htmlFor={CR_FIELDS.HISTORY.NAME} title={CR_FIELDS.HISTORY.TITLE}>
          <SegmentedInput
            fieldName={CR_FIELDS.HISTORY.NAME}
            options={SegementedInputYesNoUnsure}
            defaultSelected={medicalHistoryCancer.familyHistory}
            itemSelected={(selectedItem): void => {
              const selectedValue = (selectedItem as ListData).id;
              // Only trigger save if user selects something different
              if (medicalHistoryCancer.familyHistory !== selectedValue) {
                autoSaveAndValidate(
                  { id: parseInt(patientId), familyHistory: selectedValue },
                  CR_FIELDS.CANCER.KEY,
                  refetch,
                );
                if (isUs() && selectedValue === 'YES' && !addFamilyHistoryLoading) {
                  addFamilyHistory({ variables: { haId: medicalHistoryCancer.id, patientId } });
                }
              }
            }}
          />
        </SectionField>
        {isUs() && medicalHistoryCancer.familyHistory === 'YES' && (
          <>
            {renderFamilyHistoryList()}
            {renderAdditionalItem('Add another family member', (): void => {
              if (!addFamilyHistoryLoading) {
                addFamilyHistory({ variables: { haId: medicalHistoryCancer.id, patientId } });
              }
            })}
          </>
        )}
        {!isUs() && shouldDisplayDynamic(medicalHistoryCancer.familyHistory) && renderHistoryDetails()}
      </div>
    </>
  );
};

export default HAMedicalHistoryCancer;
