// eslint-disable-next-line no-use-before-define
import React from 'react';

import { Region } from 'shared-components/enums';
import { MandatoryPatient } from 'op-interfaces/PatientInterfaces';
import './TestInstanceWarning.scss';

interface Props {
  patient: MandatoryPatient;
}

class TestInstanceWarning extends React.Component<Props> {
  public render(): JSX.Element {
    const REGION = import.meta.env.REACT_APP_REGION;
    const { patient } = this.props;

    if (!patient.isProd && REGION === Region.UK) {
      return (
        <div className="warning-header">
          <strong>{'Warning: You are working with the TEST instance of MOSAIQ'}</strong>
        </div>
      );
    }
    return <span />;
  }
}

export default TestInstanceWarning;
