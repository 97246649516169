// eslint-disable-next-line no-use-before-define
import React from 'react';
import { StandardDialog } from 'shared-components/components';

import Typography from '@mui/material/Typography';

interface Props {
  isOpen: boolean;
  dismissFunction: () => void;
  exitForm: () => void;
  exitText: string;
}

const ModalSaveExit = (props: Props) => {
  const { isOpen, dismissFunction, exitForm, exitText } = props;
  return (
    <StandardDialog
      open={isOpen}
      onClose={dismissFunction}
      title="Are you sure you want to exit the form without submitting?"
      submitText={exitText}
      onSubmit={exitForm}>
      <Typography paddingY={2}>
        All changes have been successfully saved and you will be redirected to the Main Menu
      </Typography>
    </StandardDialog>
  );
};

export default ModalSaveExit;
