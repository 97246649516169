import { useContext, useState } from 'react';
import MultiSelectWithSearch, { MultiSelectOptions } from '../../MultiSelectWithSearch';
import { ROAutocomplete } from 'shared-components/components/FormFields';
import { styled } from '@mui/system';
import { DashboardContext } from '../DashboardContext';
import { SelectOptionType } from 'shared-components/components/FormFields';
import LocationOn from '@mui/icons-material/LocationOn';
import PersonSearch from '@mui/icons-material/PersonSearch';
import FilterList from '@mui/icons-material/FilterList';
import Event from '@mui/icons-material/Event';
import { Typography } from '@mui/material';
import { useTheme } from '@mui/material/styles';

const StyledDropDown = styled(ROAutocomplete)`
  width: 300px;
  min-width: 300px;
  border-radius: 4px;
  border: 1px ${(props) => props.theme.palette.text.secondary} solid;
  font-size: 14px;
  padding: 0;
  margin: 0;
  height: 40px;
`;

const monthNames = ['Jan', 'Feb', 'March', 'April', 'May', 'June', 'July', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'];

const subDays = (date: Date, days: number): Date => {
  const result = new Date(date);
  result.setDate(result.getDate() - days);
  return result;
};

const getEarliestDate = (data: { date: string }[]): string => {
  return data.reduce((earliest, current) => {
    return new Date(current.date) < new Date(earliest.date) ? current : earliest;
  }).date;
};

const dateRangeMap: Record<string, number> = {
  'Past 30 days': 30,
  'Past 90 days': 90,
  'Past 6 months': 180,
  'Past 1 year': 365,
  'All time': 0,
};

const DashboardHeader = (props: { title: string }): JSX.Element => {
  const { title } = props;

  const {
    filteredData,
    dateRange,
    endDate,
    tumourStreams,
    physicians,
    sites,
    tumourStreamOptions,
    physicianOptions,
    siteOptions,
    setEndDate,
    setDateRange,
    setTumourStreams,
    setPhysicians,
    setSites,
  } = useContext(DashboardContext);

  const selectedDate =
    dateRange === 'All time' ? new Date(getEarliestDate(filteredData)) : subDays(endDate, dateRangeMap[dateRange]);

  const theme = useTheme();

  const [showAllSites, setShowAllSites] = useState<boolean>(false);
  const [showAllPhysicians, setShowAllPhysicians] = useState<boolean>(false);
  const [showAllTumourStreams, setShowAllTumourStreams] = useState<boolean>(false);

  return (
    <div style={{ position: 'sticky', top: 0, background: 'white', zIndex: 2 }}>
      <div className="insights-dashboard-header-wrapper">
        <div className="insights-dashboard-title-wrapper">
          <Typography variant="h5" sx={{ marginRight: '10px' }}>
            {title}
          </Typography>
          <Typography color={theme.palette.text.secondary} sx={{ paddingBottom: '1px' }}>
            {selectedDate.getDate()} {monthNames[selectedDate.getMonth()]}, {selectedDate.getFullYear()} -{' '}
            {endDate.getDate()} {monthNames[endDate.getMonth()]}, {endDate.getFullYear()}
          </Typography>
        </div>
      </div>
      <div className="header-controls">
        <Typography
          color={theme.palette.text.secondary}
          sx={{ display: 'flex', alignItems: 'center', paddingBottom: '10px' }}>
          Filters:
        </Typography>
        <div className="dashboard-filter date">
          <StyledDropDown
            id="filter-by-date"
            icon={<Event />}
            placeholder={'Date'}
            value={{ label: dateRange, value: dateRange }}
            label="Date"
            options={Object.entries(dateRangeMap).map(
              ([key]): SelectOptionType => ({
                label: key,
                value: key,
              }),
            )}
            onChange={(option: SelectOptionType | string) => {
              const value = typeof option === 'string' ? option : option['value'];
              setDateRange(value);
              setEndDate(new Date());
            }}
          />
        </div>
        <div className="dashboard-filter">
          <MultiSelectWithSearch
            limitTags={2}
            size="small"
            adornmentIcon={sites.length === siteOptions.length && !showAllSites ? undefined : <LocationOn />}
            options={siteOptions}
            id={'multi-select-search-filter-by-site'}
            value={sites.length === siteOptions.length && !showAllSites ? [] : sites}
            label={sites.length === siteOptions.length && !showAllSites ? '' : 'Sites'}
            placeholder={sites.length === siteOptions.length && !showAllSites ? 'Sites' : ''}
            onChange={(values: MultiSelectOptions[]) => {
              setShowAllSites(values.length === siteOptions.length);
              setSites(values);
            }}
            disableCloseOnSelect
          />
        </div>
        <div className="dashboard-filter">
          <MultiSelectWithSearch
            limitTags={2}
            size="small"
            adornmentIcon={
              physicians.length === physicianOptions.length && !showAllPhysicians ? undefined : <PersonSearch />
            }
            options={physicianOptions}
            id={'multi-select-search-filter-by-physician'}
            value={physicians.length === physicianOptions.length && !showAllPhysicians ? [] : physicians}
            label={physicians.length === physicianOptions.length && !showAllPhysicians ? '' : 'Physicians'}
            placeholder={physicians.length === physicianOptions.length && !showAllPhysicians ? 'Physicians' : ''}
            onChange={(values: MultiSelectOptions[]) => {
              setShowAllPhysicians(values.length === physicianOptions.length);
              setPhysicians(values);
            }}
            disableCloseOnSelect
          />
        </div>
        <div className="dashboard-filter">
          <MultiSelectWithSearch
            limitTags={2}
            size="small"
            adornmentIcon={
              tumourStreams.length === tumourStreamOptions.length && !showAllTumourStreams ? undefined : <FilterList />
            }
            options={tumourStreamOptions}
            id={'multi-select-search-filter-by-tumour-stream'}
            value={tumourStreams.length === tumourStreamOptions.length && !showAllTumourStreams ? [] : tumourStreams}
            label={tumourStreams.length === tumourStreamOptions.length && !showAllTumourStreams ? '' : 'Tumor streams'}
            placeholder={
              tumourStreams.length === tumourStreamOptions.length && !showAllTumourStreams ? 'Tumor streams' : ''
            }
            onChange={(values: MultiSelectOptions[]) => {
              setShowAllTumourStreams(values.length === tumourStreamOptions.length);
              setTumourStreams(values);
            }}
            disableCloseOnSelect
          />
        </div>
      </div>
    </div>
  );
};

export default DashboardHeader;
