import { gql } from '@apollo/client';

export const FEEDBACK_QUERY = gql`
  query FeedbackQuery($id: ID!) {
    patient(id: $id) {
      id
      heardAboutUs
      heardAboutUsOther
      internetForResearch
      agreeToSurvey
    }
    heardAboutRefData: listData(category: "heardAboutUs") {
      id
      name
    }
    internetForResearchRefData: listData(category: "internetForResearch") {
      id
      name
    }
    user {
      id
      isPso
    }
  }
`;

export const UPDATE_FEEDBACK = gql`
  mutation UpdateFeedbackUs(
    $id: ID!
    $heardAboutUs: String
    $internetForResearch: String
    $agreeToSurvey: Boolean
    $heardAboutUsOther: String
  ) {
    updateFeedbackUs(
      id: $id
      heardAboutUs: $heardAboutUs
      heardAboutUsOther: $heardAboutUsOther
      internetForResearch: $internetForResearch
      agreeToSurvey: $agreeToSurvey
    ) {
      patient {
        id
        heardAboutUs
        heardAboutUsOther
        internetForResearch
        agreeToSurvey
      }
    }
  }
`;
