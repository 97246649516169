// eslint-disable-next-line no-use-before-define
import React, { Component, useEffect } from 'react';
import { gql } from '@apollo/client';

import { RouteComponentProps } from 'react-router-dom';
import RORegSummary from './RORegSummary';
import { LoadingSpinner } from 'shared-components/components';
import { Query } from '@apollo/client/react/components';
import { ListData } from 'shared-components/interfaces';
import { useErrorModalContext } from 'op-contexts';

const PATIENT_QUERY = gql`
  query patientQuery($id: ID) {
    patient(id: $id) {
      id
      ida
      idb
      idbConflict
      payor
      coverageRelationship
      policyNumber
      preAuthNumber
      preAuthNotes
      attachments {
        id
        dateAttached
        encounterDate
        documentType
        submitted
        file
        url
        filename
        docStatus
        filesize
        typeDisplay
      }
      primaryPhone
      secondaryPhone
      email
      firstName
      middleName
      lastName
      namePrefix
      fullName
      gender
      dob
      dobRawDay
      dobRawMonth
      dobRawYear
      primaryCenter
      otherInformation
      address {
        id
        ukFormattedAddress
      }
      residentialAddressCity
      residentialAddressState
      residentialAddressCountry
    }
    user {
      id
      isPso
    }
    documentTypeRefData: attachmentTypes {
      id
      name: conceptDisplay
    }
    titleRefData: listData(category: "nameTitle") {
      id
      name
    }
    gpSurgeryRefData: practitionerLocations(patient: $id, search: "") {
      id
      name: fullName
    }
    surgeonLocationRefData: practitionerLocations(patient: $id, search: "") {
      id
      name: fullName
    }
    gpRefData: practitioners(url: "https://genesiscare.com/fhir/general-practice-surgery", patient: $id) {
      id
      name
    }
    surgeonRefData: extPractitioners(patient: $id, search: "") {
      id
      name: name
    }
    oncologistRefData: oncologists(patient: $id, search: "") {
      id
      name
    }
    relationshipsRefData: listData(category: "relationships") {
      id
      name
    }
    heritageRefData: listData(category: "heritage") {
      id
      name
    }
    maritalStatusRefData: listData(category: "maritalStatus") {
      id
      name
    }
    ethnicityRefData: listData(category: "ethnicity") {
      id
      name
    }
    religionRefData: listData(category: "religion") {
      id
      name
    }
    countryOfBirthRefData: listData(category: "countryOfBirth") {
      id
      name
    }
    genderRefData: listData(category: "gender") {
      id
      name
    }
    languageSpokenRefData: listData(category: "languages") {
      id
      name
    }
    medicareTypeRefData: listData(category: "healthFund") {
      id
      name
      appKey
    }
    insurerRefData: departments {
      id
      name
    }
    relationshipRefData: listData(category: "coverageRelationship") {
      id
      name
    }
    dvaTypeRefData: listData(category: "dvaCardType") {
      id
      name
      appKey
    }
    ausStateRefData: listData(category: "ukCounties") {
      id
      name
    }
    primaryCenterRefData: configs {
      id
      name
    }
    nhsOptionsRefData: listData(category: "nhsOptions") {
      id
      name
    }
    registrationReasonRefData: listData(category: "registrationReason") {
      id
      name
    }
    ukCounties: listData(category: "ukCounties") {
      id
      name
    }
    countries: listData(category: "countryOfBirth") {
      id
      name
    }
  }
`;

interface Props extends RouteComponentProps {
  isActive?: boolean;
}

class RORegSummaryApollo extends Component<Props> {
  private navigate = (link: string): void => {
    this.props.history.push(link);
  };

  public render(): JSX.Element {
    const match: any = this.props.match;
    const patientId: string = match.params.id;
    const variables = {
      id: patientId,
    };

    return (
      <Query query={PATIENT_QUERY} variables={variables} fetchPolicy="cache-and-network">
        {({ loading, data, error }: any): JSX.Element => {
          const { setError } = useErrorModalContext();
          useEffect(() => {
            if (error) return setError();
          }, [error]);
          if (loading) return <LoadingSpinner />;
          if (data && data.patient && data.user) {
            return (
              <RORegSummary
                navigateTo={this.navigate}
                registrationReasonRefData={data.registrationReasonRefData}
                nhsOptionsRefData={data.nhsOptionsRefData}
                insurerRefData={data.insurerRefData}
                relationshipRefData={data.relationshipRefData}
                gpSurgeryRefData={data.gpSurgeryRefData}
                surgeonLocationRefData={data.surgeonLocationRefData}
                gpRefData={data.gpRefData}
                extPracRefData={data.extPracRefData}
                documentTypeRefData={data.documentTypeRefData}
                surgeonRefData={this.formatRefData(data.surgeonRefData)}
                oncologistRefData={data.oncologistRefData}
                titleRefData={data.titleRefData}
                relationshipsRefData={data.relationshipsRefData}
                maritalStatusRefData={data.maritalStatusRefData}
                ethnicityRefData={data.ethnicityRefData}
                religionRefData={data.religionRefData}
                acceptsDataShareRefData={data.acceptsDataShareRefData}
                countryOfBirthRefData={data.countryOfBirthRefData}
                genderRefData={data.genderRefData}
                primaryCenterRefData={data.primaryCenterRefData}
                languageSpokenRefData={data.languageSpokenRefData}
                ausStateRefData={data.ausStateRefData}
                patient={data.patient}
                countries={data.countries}
                ukCounties={data.ukCounties}
                user={data.user}
              />
            );
          }
          return <div></div>;
        }}
      </Query>
    );
  }

  private formatRefData = (refData: ListData[]): ListData[] => {
    return refData.map((value) => {
      const newValue = {
        id: value.name,
        name: value.name,
      };
      return newValue;
    });
  };
}

export default RORegSummaryApollo;
