export default class DeviceUtilities {
  /**
   * Determines whether current screen size is that of a mobile device.
   * @returns {boolean} True if the screen size is that of a mobile device, or false otherwise.
   */
  public static isMobileDevice(): boolean {
    return window.innerWidth < 768 ? true : false;
  }

  /**
   * Determines whether the size of the screen is a tablet device.
   * @returns {boolean} True if the screen size is that of a tablet device. False if mobile or desktop
   */
  public static isTabletDevice(): boolean {
    return window.innerWidth >= 768 && window.innerWidth < 993;
  }

  /**
   * Determines whether the size of the screen is a desktop device.
   * @returns {boolean} True if the screen size is that of a desktop device. False if mobile or tablet
   */
  public static isDesktopDevice(): boolean {
    return window.innerWidth >= 993;
  }

  /**
   * Determines whether the device is an apple device as there are some things that iOS devices will behave differently to other devices.
   * @returns {boolean} True if the device is an iPhone or iPad, false otherwise.
   */
  public static isAppleDevice(): boolean {
    const isIpad = window.navigator.userAgent.match(/iPad/i);
    const isIphone = window.navigator.userAgent.match(/iPhone/i);
    if (isIpad || isIphone) {
      return true;
    }

    return false;
  }
}
