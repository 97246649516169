import React from 'react';
import { ROAutocomplete, ROTextField, ROToggleButtons } from 'shared-components/components/FormFields';
import { BolusFormProps } from '.';
import { setYesNo } from '../../Common';
import { getOptionByValue } from '../../DiagnosisPages/Utils';
import { SelectOptionType } from 'shared-components/components/FormFields';
import { Typography, useTheme } from '@mui/material';

export const validationV2 = [
  {
    parent: 'bolus',
    expansionValue: 'true',
    children: ['bolusLocationType', 'bolusThickness', 'bolusFrequency'],
  },
  {
    parent: 'bolusLocationType',
    expansionValue: 'Specify location',
    children: ['bolusLocation'],
  },
];

const SimulationBolusFormV2 = ({
  options,
  updateSimulation,
  fieldsId,
  siteGroup,
  SIM_NAMES,
  listValuesLookup,
  setTextFields,
  isFieldValid,
  bolusLocationValues,
  bolusThicknessCustomValues,
}: BolusFormProps) => {
  const theme = useTheme();
  const { bolus, bolusLocation, bolusThickness, bolusThicknessCustom, bolusFrequency, is3dBolus, bolusLocationType } =
    siteGroup;
  return (
    <>
      <ROToggleButtons
        id={SIM_NAMES.BOLUS}
        fieldlabel="Bolus"
        options={options.bolus}
        value={setYesNo(bolus.value)}
        handleChange={(value): void => {
          const bolusBool = value === 'yes' ? true : false;

          const is3dBolusField = is3dBolus?.isShown ? { is3dBolus: '' } : {};

          const variables = {
            siteGroupId: fieldsId,
            bolus: bolusBool,
            bolusLocation: '',
            bolusLocationType: '',
            bolusThickness: '',
            bolusThicknessCustom: '',
            bolusFrequency: '',
            ...is3dBolusField,
          };

          updateSimulation({
            variables,
          });
        }}
      />

      {bolus?.value === 'true' && (
        <>
          {is3dBolus?.isShown && (
            <ROToggleButtons
              id={SIM_NAMES.IS_3D_BOLUS}
              fieldlabel="3D Bolus"
              options={options.is3dBolus}
              value={is3dBolus?.value || ''}
              error={!isFieldValid(is3dBolus)}
              handleChange={(value): void => {
                updateSimulation({
                  variables: { siteGroupId: fieldsId, is3dBolus: value },
                });
              }}
            />
          )}
          <ROToggleButtons
            id={SIM_NAMES.BOLUS_LOCATION_TYPE}
            fieldlabel="Bolus Location"
            options={options.bolusLocationType}
            value={bolusLocationType ? bolusLocationType.value : ''}
            required
            error={!isFieldValid(bolusLocationType)}
            handleChange={(value): void => {
              updateSimulation({
                variables: {
                  siteGroupId: fieldsId,
                  bolusLocationType: value,
                  bolusLocation: value === 'Specify location' ? '' : value,
                },
              });
            }}
          />
          {bolusLocationType?.value === 'Specify location' && (
            <ROTextField
              id={SIM_NAMES.BOLUS_LOCATION}
              fieldlabel="Bolus Location Specify"
              value={listValuesLookup(bolusLocationValues, fieldsId, bolusLocation?.value || '')}
              onChange={(event: React.ChangeEvent<HTMLInputElement>): void => {
                setTextFields(SIM_NAMES.BOLUS_LOCATION, fieldsId, bolusLocationValues, event.target.value);
              }}
              error={!isFieldValid(bolusLocation)}
              onBlur={(): void => {
                const valueSet = bolusLocationValues.find((x: any) => x.id === fieldsId);
                if (valueSet) {
                  updateSimulation({ variables: { siteGroupId: fieldsId, bolusLocation: valueSet.value } });
                }
              }}
              required
            />
          )}
          <ROAutocomplete
            id={SIM_NAMES.BOLUS_THICKNESS}
            fieldlabel="Bolus Thickness"
            options={options.bolusThickness.map((data: any) => ({ label: data.value, value: data.value }))}
            value={getOptionByValue(
              options.bolusThickness.map((data: any) => ({ label: data.label, value: data.value })),
              bolusThickness?.value || '',
            )}
            required
            inputProps={{
              error: !isFieldValid(bolusThickness),
            }}
            onChange={(option: SelectOptionType | string): void => {
              const value = typeof option === 'string' ? option : option?.value;
              updateSimulation({
                variables: { siteGroupId: fieldsId, bolusThickness: value, bolusThicknessCustom: '' },
              });
            }}
          />
          {bolusThickness?.value == 'Custom' && (
            <ROTextField
              id={SIM_NAMES.BOLUS_THICKNESS_CUSTOM}
              label={true}
              value={listValuesLookup(
                bolusThicknessCustomValues,
                fieldsId,
                bolusThicknessCustom ? bolusThicknessCustom.value : '',
              )}
              onChange={(event: React.ChangeEvent<HTMLInputElement>): void => {
                setTextFields(
                  SIM_NAMES.BOLUS_THICKNESS_CUSTOM,
                  fieldsId,
                  bolusThicknessCustomValues,
                  event.target.value,
                );
              }}
              onBlur={(): void => {
                const valueSet = bolusThicknessCustomValues.find((x: any) => x.id === fieldsId);
                if (valueSet) {
                  updateSimulation({ variables: { siteGroupId: fieldsId, bolusThicknessCustom: valueSet.value } });
                }
              }}
            />
          )}
          <ROAutocomplete
            id={SIM_NAMES.BOLUS_FREQUENCY}
            fieldlabel="Bolus Frequency"
            options={options.bolusFrequency.map((data: any) => ({ label: data.value, value: data.value }))}
            value={getOptionByValue(
              options.bolusFrequency.map((data: any) => ({ label: data.label, value: data.value })),
              bolusFrequency?.value || '',
            )}
            required
            inputProps={{
              error: !isFieldValid(bolusFrequency),
            }}
            onChange={(option: SelectOptionType | string): void => {
              const value = typeof option === 'string' ? option : option?.value;
              updateSimulation({
                variables: { siteGroupId: fieldsId, bolusFrequency: value },
              });
            }}
          />
        </>
      )}
    </>
  );
};
export default SimulationBolusFormV2;
