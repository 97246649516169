import { useQuery } from '@apollo/client';
import React from 'react';
import { SummaryCard, SummaryRow } from 'op-components';
import { HASubComponentProps } from 'op-pages/OP/HealthAssessment/HASharedComponents/types';
import { Edit, Success } from 'shared-components/images';

import { evaluateDetailText } from 'op-components/OP/SummaryRow/SummaryRow';
import {
  HAConsentItem,
  patientConsentQuestions,
} from 'op-pages/OP/HealthAssessment/HAPages/HAPatientConsentUS/HAPatientConsent';
import { HA_CONSENT_QUERY } from 'op-pages/OP/HealthAssessment/HASharedComponents/queries/HAPatientConsentQueries';

const HAPatientConsent = (props: HASubComponentProps): JSX.Element => {
  const { patientId, isPso, navigate } = props;

  const { data, loading } = useQuery<{ healthAssessment: HAConsentItem }>(HA_CONSENT_QUERY, {
    variables: { patientId },
  });

  if (loading) {
    return <></>;
  }

  const patientComponentItems = data?.healthAssessment;

  if (patientComponentItems) {
    return (
      <SummaryCard
        id="consent"
        title={'Patient consent'}
        primaryIcon={isPso ? <></> : <Edit className="primary-icon" />}
        secondaryIcon={<Success className="secondary-icon valid-icon" />}
        showEdit={!isPso}
        onClick={() => {
          if (!isPso) {
            navigate('consent');
          }
        }}>
        <SummaryRow
          headingText={patientConsentQuestions.acknowledgement}
          detailText={evaluateDetailText(patientComponentItems.patientAcknowledgement)}
          displayAsError={false}
          displayAsOptional={false}
        />
        <SummaryRow
          headingText={patientConsentQuestions.patientSignature}
          detailText={evaluateDetailText(patientComponentItems.patientDigitalSignature)}
          displayAsError={false}
          displayAsOptional={false}
        />
      </SummaryCard>
    );
  }

  return <></>;
};

export default HAPatientConsent;
