import React, { useContext } from 'react';
import { styled } from '@mui/system';
import moment from 'moment';
import 'moment/locale/en-gb';
import 'moment/locale/es';
import './PatientCard.scss';
import PhotoWidget from 'shared-components/components/UIFormComponents/PhotoWidget';
import { DetailedCardProps } from './types';
import { ROPatientContext } from 'op-pages/RO/PatientSummary/context';

const PatientCardWrapper = styled('div')`
  box-shadow: 0px 1px 1px rgb(0 0 0 / 20%);
  display: flex;
  justify-content: space-between;
  position: relative;
  z-index: 2;

  padding: 16px;
`;

const NameAvatarContainer = styled('div')`
  display: flex;
  min-width: 30%;
  max-width: 40%;
  margin-right: 16px;
`;

const NameInfoContainer = styled('div')`
  display: flex;
  flex-direction: column;
  margin-left: 16px;
`;

const PatientName = styled('div')`
  font-weight: bold;
  font-size: 18px;
  line-height: 28px;
  color: ${(props) => props.theme.palette.primary.dark};
`;
const BirthIdContainer = styled('div')`
  display: flex;
  font-size: 14px;
  line-height: 20px;
`;

const PatientId = styled('div')`
  margin-right: 16px;
`;
const PatientDOB = styled('div')``;

const MoreDetails = styled('div')`
  font-size: 16px;
  line-height: 24px;
  cursor: pointer;
  color: ${(props) => props.theme.palette.info.main};
  text-decoration: underline;
`;

const InfoBlockContainer = styled('div')`
  display: flex;
  flex-direction: column;
  line-height: 20px;
  font-size: 14px;
  max-width: 20%;
  margin-right: 16px;
`;

const SimplifiedPatientCard = (props: DetailedCardProps): JSX.Element => {
  const { attributes, moreDetailsClickHandler, showMoreDetails } = props;
  const address = attributes.address ? attributes.address.ukFormattedAddress : '-';
  const { patientDetails } = useContext(ROPatientContext);
  const allReferrers = patientDetails?.allReferrers || [];

  const getReferrer = (type: string) => allReferrers.filter((referrer: any) => referrer.relationshipType === type);
  const responsiblePhysician = getReferrer('oncologist')[0];
  const primaryPhysician = getReferrer('generalPractitioner')[0];
  const referringSurgeon = getReferrer('referringSurgeon');
  const allPhysicians = [];
  if (responsiblePhysician) {
    allPhysicians.push(
      `${responsiblePhysician.practitioner.firstName} ${responsiblePhysician.practitioner.lastName} (Responsible)`,
    );
  }
  if (primaryPhysician) {
    allPhysicians.push(`${primaryPhysician.practitioner.firstName} ${primaryPhysician.practitioner.lastName} (GP)`);
  }
  if (referringSurgeon.length) {
    referringSurgeon.forEach((surgeon: any) =>
      allPhysicians.push(`${surgeon.practitioner.firstName} ${surgeon.practitioner.lastName}`),
    );
  }

  const dob = moment(patientDetails?.dob).format('DD MMM YYYY');

  return (
    <PatientCardWrapper>
      <NameAvatarContainer>
        <PhotoWidget patientId={attributes.patientId} enableOverlay={true} />
        <NameInfoContainer>
          <PatientName>{attributes.fullName}</PatientName>
          <BirthIdContainer>
            <PatientId>
              Patient ID <b>{attributes.ida}</b>
            </PatientId>
            <PatientDOB>
              DOB <b>{dob}</b>
            </PatientDOB>
          </BirthIdContainer>
          <MoreDetails
            role="presentation"
            onClick={() => {
              if (moreDetailsClickHandler) {
                moreDetailsClickHandler();
              }
            }}
            className="more-details-button"
            data-testid="more-details-button">
            {showMoreDetails ? 'Hide' : 'Show'} details & appts
          </MoreDetails>
        </NameInfoContainer>
      </NameAvatarContainer>
      <InfoBlockContainer>
        <div>Address</div>
        <b>{address}</b>
      </InfoBlockContainer>
      <InfoBlockContainer>
        <div>Contact</div>
        <b>M: {attributes.mobile}</b>
        <b>L: {attributes.landline}</b>
      </InfoBlockContainer>
      <InfoBlockContainer>
        <div>Treating Physicians</div>
        {allPhysicians?.length > 0 &&
          allPhysicians.map((item: any, index: number) => (index <= 1 ? <b>{item}</b> : null))}
        {allPhysicians?.length > 2 && (
          <div
            role="presentation"
            onClick={() => {
              if (moreDetailsClickHandler) {
                moreDetailsClickHandler();
              }
            }}>
            <b>...</b>
          </div>
        )}
        {allPhysicians.length === 0 && <div>-</div>}
      </InfoBlockContainer>
      <div className="ro-patient-card-vertical-bar" />
    </PatientCardWrapper>
  );
};

export default SimplifiedPatientCard;
