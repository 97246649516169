// eslint-disable-next-line no-use-before-define
import React from 'react';
import { ErrorCircleFill } from 'shared-components/images';
import { styled } from '@mui/system';
import { getCareplanStatusColor } from './common';

interface StyleProps {
  status: string;
}

const StyledStatusBubble = styled('div')<StyleProps>`
  align-self: center;
  background-repeat: no-repeat;
  background-position: center;
  background-size: auto;
  border-radius: 50%;
  min-width: 20px;
  max-width: 20px;
  min-height: 20px;
  max-height: 20px;
  margin: 0 0.5em 0 0;
  margin-right: 8px;
  background-color: ${(props: StyleProps) => getCareplanStatusColor(props.status)};
  border: 2px solid ${(props: StyleProps) => getCareplanStatusColor(props.status)};
  // Inner text
  font-style: normal;
  font-weight: bold;
  font-size: 12px;
  text-transform: uppercase;
  color: ${(props) => props.theme.palette.primary.contrastText};
  display: flex;
  justify-content: center;
  align-items: center;
`;

const WarningIcon = styled(ErrorCircleFill)`
  position: absolute;
  left: 26px;
  top: -1px;
  width: 18px;
  height: 18px;
`;

interface props {
  status: string;
  hasUnsubmittedChanges?: any;
}

export const CareplanStatusBubble = ({ status, hasUnsubmittedChanges }: props): JSX.Element => {
  return (
    <StyledStatusBubble status={status}>
      {statusAbbreviations(status)}
      {hasUnsubmittedChanges && <WarningIcon />}
    </StyledStatusBubble>
  );
};

const statusAbbreviations = (status: string): string => {
  const statusArray = status.split('_');
  let statusAbbr = '';

  statusArray.map((item: string): string => (statusAbbr += item.charAt(0)));
  return statusAbbr;
};
