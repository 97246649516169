import { RulesInterface } from './RegistrationValidationInterfaces';
// TODO: Update registration form sub-pages to utilise this consolidated validation class. For more information, refer to EVE-1439.
import lodash from 'lodash';

import {
  AddressValidationReference,
  DemographicsValidationReference,
  FullPatient,
  OPClinicPatient,
  Patient,
  PatientAddress,
  PatientAltContact,
  PatientAttachment,
  PatientAttachments,
  PatientBasic,
  PatientContactDetails,
  PatientCoverage,
  PatientInformationNotice,
  PatientMedicare,
  PatientQuarantiniInsurance,
  UKDemographicsValidationReference,
  UKFullPatient,
  UKPatientAltContact,
  UKPatientGP,
  ValidationReference,
} from 'op-interfaces';
import { CoverageRefData, UKGPValidationReference } from 'op-interfaces/ValidationReferenceInterfaces';
import { isUs } from 'op-utils';
import { INVALID_SECTIONS, MEDICARE_TYPES } from 'op-utils/RegistrationTypes';
import { Region } from 'shared-components/enums';
import { ListData } from 'shared-components/interfaces';
import {
  EMAIL_REGEX,
  MOBILE_NUMBER_REGEX,
  NAME_REGEX,
  PHONE_NUMBER_REGEX,
  UK_CITY_REGEX,
  UK_NAME_REGEX,
  UK_PHONE_REGEX,
  UK_POSTCODE_REGEX,
  US_PHONE_REGEX,
} from 'shared-components/utils';
import validate from 'shared-components/utils/validate';

export enum ValidationKeys {
  All,
  Basic,
  Contact,
  Address,
  AltContact,
  GpInfo,
  Medicare,
  Demographics,
  Mosaiq,
  MosaiqPatient,
  ContactPXSignUp,
  HomeRegSignup,
  Quarantini,
}

const VALIDATION_MESSAGE_TYPE = {
  INVALID: 'INVALID_DATA',
  MISSING: 'MISSING_DATA',
};

const options = { fullMessages: false };

// Rules that are used by multiple validation objects
const _FIRST_NAME_RULE = {
  presence: {
    allowEmpty: false,
    message: VALIDATION_MESSAGE_TYPE.MISSING,
  },
  format: {
    pattern: NAME_REGEX,
    message: VALIDATION_MESSAGE_TYPE.INVALID,
  },
};

const _LAST_NAME_RULE = {
  presence: {
    allowEmpty: false,
    message: VALIDATION_MESSAGE_TYPE.MISSING,
  },
  format: {
    pattern: NAME_REGEX,
    message: VALIDATION_MESSAGE_TYPE.INVALID,
  },
};

const _DOB_RAW_RULE = {
  presence: {
    allowEmpty: false,
    message: VALIDATION_MESSAGE_TYPE.MISSING,
  },
  patientDobRaw: {
    message: VALIDATION_MESSAGE_TYPE.INVALID,
    afterMessage: VALIDATION_MESSAGE_TYPE.INVALID,
  },
};

const _GENDER_RULE = {
  presence: {
    allowEmpty: false,
    message: VALIDATION_MESSAGE_TYPE.MISSING,
  },
};

const _ATTACHMENT_ACKNOWLEDGED_RULE = {
  attachmentAcknowledged: {
    inclusion: {
      within: [true],
      message: VALIDATION_MESSAGE_TYPE.MISSING,
    },
  },
};

const BASIC_RULES = {
  namePrefix: {
    presence: {
      allowEmpty: false,
      message: VALIDATION_MESSAGE_TYPE.MISSING,
    },
  },
  firstName: _FIRST_NAME_RULE,
  lastName: _LAST_NAME_RULE,
  middleName: {
    presence: {
      allowEmpty: true,
    },
    format: {
      pattern: NAME_REGEX,
      message: VALIDATION_MESSAGE_TYPE.INVALID,
    },
  },
  preferredName: {
    presence: {
      allowEmpty: true,
    },
    format: {
      pattern: NAME_REGEX,
      message: VALIDATION_MESSAGE_TYPE.INVALID,
    },
  },
  gender: _GENDER_RULE,
  dobRaw: _DOB_RAW_RULE,
};

// Validation objects
const MOSAIQ_MINIMUM_REQUIREMENT_RULES = {
  firstName: _FIRST_NAME_RULE,
  lastName: _LAST_NAME_RULE,
  dobRaw: _DOB_RAW_RULE,
  gender: _GENDER_RULE,
};

function isIsBn(value: string | undefined): boolean | undefined {
  if (value === '') {
    return true;
  } else if (typeof value === 'string') {
    const mul = [];
    const newValue = value.replace(/ /g, '');
    for (let i = 0; i < 9; i++) {
      // @ts-ignore
      mul.push(parseInt(newValue[i]) * [i + 1]);
    }
    const sum = mul.reduce((n, a) => n + a, 0);
    const mod = sum % 11;
    return mod === parseInt(newValue[9]);
  }
}

function isAbove18(date: any) {
  return (
    new Date(parseInt(date.slice(0, 4)) + 18, parseInt(date.slice(5, 7)) - 1, parseInt(date.slice(8, 10))) <= new Date()
  );
}

function isLessThen120(date: any) {
  return (
    new Date(parseInt(date.slice(0, 4)) + 120, parseInt(date.slice(5, 7)) - 1, parseInt(date.slice(8, 10))) >=
    new Date()
  );
}

const getUKBasicRules = (patient: PatientBasic): { [key: string]: object } => {
  const validIsbn = isIsBn(patient.idb);
  let validAge;
  if (patient.dob) {
    validAge = isAbove18(patient.dob) ? isLessThen120(patient.dob) : false;
  } else {
    validAge = true;
  }

  return {
    namePrefix: {
      presence: {
        allowEmpty: false,
        message: VALIDATION_MESSAGE_TYPE.MISSING,
      },
    },
    firstName: {
      presence: {
        allowEmpty: false,
        message: VALIDATION_MESSAGE_TYPE.MISSING,
      },
      format: {
        pattern: UK_NAME_REGEX,
        message: VALIDATION_MESSAGE_TYPE.INVALID,
      },
    },
    middleName: {
      presence: {
        allowEmpty: true,
        message: VALIDATION_MESSAGE_TYPE.MISSING,
      },
      format: {
        pattern: !!patient.middleName ? UK_NAME_REGEX : '.*',
        message: VALIDATION_MESSAGE_TYPE.INVALID,
      },
    },
    lastName: {
      presence: {
        allowEmpty: false,
        message: VALIDATION_MESSAGE_TYPE.MISSING,
      },
      format: {
        pattern: UK_NAME_REGEX,
        message: VALIDATION_MESSAGE_TYPE.INVALID,
      },
    },
    dobRaw: {
      presence: {
        allowEmpty: false,
        message: 'Please complete date of birth.',
      },
      format: {
        pattern: !validAge ? 'ERROR' : '.*',
        message: 'Please enter valid date of birth.',
      },
    },
    gender: {
      presence: {
        allowEmpty: false,
        message: VALIDATION_MESSAGE_TYPE.MISSING,
      },
    },
    primaryCenter: {
      presence: {
        allowEmpty: false,
        message: VALIDATION_MESSAGE_TYPE.MISSING,
      },
    },
    idb: {
      presence: {
        allowEmpty: !!patient.nhsOptions,
      },
      format:
        !validIsbn || patient.idbConflict
          ? {
              pattern: "'a^'",
              message: 'This is not a valid NHS number',
            }
          : undefined,
    },
    nhsOptions: {
      presence: {
        allowEmpty: !!patient.idb,
        message: VALIDATION_MESSAGE_TYPE.MISSING,
      },
    },
    registrationReason: {
      presence: {
        allowEmpty: false,
        message: VALIDATION_MESSAGE_TYPE.MISSING,
      },
    },
    registrationReasonText: {
      presence: {
        allowEmpty: patient.registrationReason !== 'Other',
        message: VALIDATION_MESSAGE_TYPE.MISSING,
      },
    },
  };
};

const getUKContactRules = (
  details: { primaryPhone?: string; secondaryPhone?: string },
  regexPattern: string,
  optionalEmail: boolean,
): { [key: string]: object } => {
  return {
    primaryPhone: {
      presence: {
        allowEmpty: !!details.secondaryPhone,
        message: VALIDATION_MESSAGE_TYPE.MISSING,
      },
      format: {
        pattern: !!details.primaryPhone ? regexPattern : '.*',
        message: VALIDATION_MESSAGE_TYPE.INVALID,
      },
    },
    secondaryPhone: {
      presence: {
        allowEmpty: !!details.primaryPhone,
        message: VALIDATION_MESSAGE_TYPE.MISSING,
      },
      format: {
        pattern: !!details.secondaryPhone ? regexPattern : '.*',
        message: VALIDATION_MESSAGE_TYPE.INVALID,
      },
    },
    email: {
      presence: {
        allowEmpty: optionalEmail,
        message: VALIDATION_MESSAGE_TYPE.MISSING,
      },
      format: {
        pattern: EMAIL_REGEX,
        message: VALIDATION_MESSAGE_TYPE.INVALID,
      },
    },
  };
};

const getQuarantiniPatient = (patient: OPClinicPatient): { [key: string]: object } => {
  const validIsbn = isIsBn(patient.idb);
  let validAge;
  if (patient.dob) {
    validAge = isAbove18(patient.dob);
  } else {
    validAge = true;
  }

  const rules = {
    namePrefix: {
      presence: {
        allowEmpty: false,
        message: VALIDATION_MESSAGE_TYPE.MISSING,
      },
    },
    firstName: {
      presence: {
        allowEmpty: false,
        message: VALIDATION_MESSAGE_TYPE.MISSING,
      },
      format: {
        pattern: UK_NAME_REGEX,
        message: VALIDATION_MESSAGE_TYPE.INVALID,
      },
    },
    middleName: {
      presence: {
        allowEmpty: true,
        message: VALIDATION_MESSAGE_TYPE.MISSING,
      },
      format: {
        pattern: UK_NAME_REGEX,
        message: VALIDATION_MESSAGE_TYPE.INVALID,
      },
    },
    lastName: {
      presence: {
        allowEmpty: false,
        message: VALIDATION_MESSAGE_TYPE.MISSING,
      },
      format: {
        pattern: UK_NAME_REGEX,
        message: VALIDATION_MESSAGE_TYPE.INVALID,
      },
    },
    previousName: {
      presence: {
        allowEmpty: true,
      },
      format: {
        pattern: UK_NAME_REGEX,
        message: VALIDATION_MESSAGE_TYPE.INVALID,
      },
    },
    gender: {
      presence: {
        allowEmpty: false,
        message: VALIDATION_MESSAGE_TYPE.MISSING,
      },
    },
    dob: {
      presence: {
        allowEmpty: false,
        message: 'Please complete date of birth',
      },
      format: {
        pattern: !validAge ? 'ERROR' : '.*',
        message: 'Patient is under 18',
      },
    },
    idb: {
      presence: {
        allowEmpty: true,
      },
      format:
        !validIsbn || patient.idbConflict
          ? {
              pattern: "'a^'",
              message: 'This is not a valid NHS number',
            }
          : undefined,
    },
    primaryPhone: {
      presence: {
        allowEmpty: !!patient.secondaryPhone,
        message: VALIDATION_MESSAGE_TYPE.MISSING,
      },
      format: {
        pattern: !!patient.primaryPhone ? UK_PHONE_REGEX : '.*',
        message: VALIDATION_MESSAGE_TYPE.INVALID,
      },
    },
    secondaryPhone: {
      presence: {
        allowEmpty: !!patient.primaryPhone,
        message: VALIDATION_MESSAGE_TYPE.MISSING,
      },
      format: {
        pattern: !!patient.secondaryPhone ? UK_PHONE_REGEX : '.*',
        message: VALIDATION_MESSAGE_TYPE.INVALID,
      },
    },
    email: {
      presence: {
        allowEmpty: true,
        message: VALIDATION_MESSAGE_TYPE.MISSING,
      },
      format: {
        pattern: EMAIL_REGEX,
        message: VALIDATION_MESSAGE_TYPE.INVALID,
      },
    },
  };

  return rules;
};

const CLINIC_RULES: { [key: string]: object } = {
  clinicType: {
    presence: {
      allowEmpty: false,
      message: 'Please select clinic type',
    },
  },
  primaryCenter: {
    presence: {
      allowEmpty: false,
      message: 'Please select a GenesisCare centre',
    },
  },
  caller: {
    presence: {
      allowEmpty: false,
      message: 'Please select caller',
    },
  },
  enquirySource: {
    presence: {
      allowEmpty: false,
      message: 'Please select enquiry source',
    },
  },
};

const getClinicPayorRules = (patient: PatientQuarantiniInsurance): { [key: string]: object } => {
  let isRequired = patient.isSymptomatic === false;
  patient.selfPaid && (isRequired = true);
  return {
    payor: {
      presence: {
        allowEmpty: patient.isSymptomatic === false,
        message: 'Please select payor',
      },
    },
    coverageRelationship: {
      presence: {
        allowEmpty: isRequired,
        message: 'Please select relationship to policy holder',
      },
    },
    policyNumber: {
      presence: {
        allowEmpty: isRequired,
        message: 'Please enter policy number',
      },
    },
    preAuthNumber: {
      presence: {
        allowEmpty: true,
      },
    },
  };
};

const getContactRules = (
  details: { primaryPhone?: string; secondaryPhone?: string },
  regexPattern: string,
  optionalEmail: boolean,
): { [key: string]: object } => {
  return {
    primaryPhone: {
      presence: {
        allowEmpty: !details.secondaryPhone ? false : true,
        message: VALIDATION_MESSAGE_TYPE.MISSING,
      },
      format: {
        pattern: regexPattern,
        message: VALIDATION_MESSAGE_TYPE.INVALID,
      },
    },
    secondaryPhone: {
      presence: {
        allowEmpty: !details.primaryPhone ? false : true,
        message: VALIDATION_MESSAGE_TYPE.MISSING,
      },
      format: {
        pattern: regexPattern,
        message: VALIDATION_MESSAGE_TYPE.INVALID,
      },
    },
    email: {
      presence: {
        allowEmpty: optionalEmail,
        message: VALIDATION_MESSAGE_TYPE.MISSING,
      },
      format: {
        pattern: EMAIL_REGEX,
        message: VALIDATION_MESSAGE_TYPE.INVALID,
      },
    },
  };
};

export const getHomeRegSignUpRules = (): { [key: string]: object } => {
  return {
    primaryPhone: {
      presence: {
        allowEmpty: false,
        message: 'Please enter a mobile phone number.',
      },
      format: {
        pattern: isUs() ? US_PHONE_REGEX.source : PHONE_NUMBER_REGEX,
        message: 'Please enter a valid mobile number.',
      },
    },
    email: {
      presence: {
        allowEmpty: false,
        message: 'Please enter a email address.',
      },
      format: {
        pattern: EMAIL_REGEX,
        message: 'Please enter a valid email address.',
      },
    },
    primaryDepartment: {
      presence: {
        allowEmpty: false,
        message: 'Please select',
      },
    },
  };
};

const getPXSignUpRules = (regexPattern: string, optionalEmail: boolean): { [key: string]: object } => {
  return {
    primaryPhone: {
      presence: {
        allowEmpty: false,
        message: VALIDATION_MESSAGE_TYPE.MISSING,
      },
      format: {
        pattern: regexPattern,
        message: VALIDATION_MESSAGE_TYPE.INVALID,
      },
    },
    email: {
      presence: {
        allowEmpty: optionalEmail,
        message: VALIDATION_MESSAGE_TYPE.MISSING,
      },
      format: {
        pattern: EMAIL_REGEX,
        message: VALIDATION_MESSAGE_TYPE.INVALID,
      },
    },
  };
};

const getAlternateContactRules = (altContact: PatientAltContact, region?: string): { [key: string]: object } => {
  interface ObjectKey {
    [key: string]: object;
  }

  let rules: ObjectKey = {};

  const createRuleSet = (ruleType: string, mobilePhoneNumber?: string, homePhoneNumber?: string): ObjectKey => {
    const modifiedRule: ObjectKey = {};
    // Set up the emergency rules using dot notation for the keys
    for (const ruleEntry of Object.entries(getAlternateBaseRules(mobilePhoneNumber, homePhoneNumber, region))) {
      const [key, value] = ruleEntry;
      modifiedRule[`${ruleType}.${key}`] = { ...value };
    }

    return modifiedRule;
  };

  const { emergencyContact, nextOfKinContact } = altContact;
  rules = createRuleSet('emergencyContact', emergencyContact.mobilePhoneNumber, emergencyContact.homePhoneNumber);

  if (altContact.altContactProvided || altContact.sameAsEmergency === false) {
    if (nextOfKinContact) {
      rules = {
        ...rules,
        ...createRuleSet('nextOfKinContact', nextOfKinContact.mobilePhoneNumber, nextOfKinContact.homePhoneNumber),
      };
    }
  }

  return rules;
};

const getAlternateBaseRules = (mobilePhoneNumber?: string, homePhoneNumber?: string, region?: string): object => {
  let nameRegex = NAME_REGEX;
  let homePhoneRegex = PHONE_NUMBER_REGEX;
  let mobilePhoneRegex = PHONE_NUMBER_REGEX;

  const presences = {
    firstName: false,
    lastName: false,
    relationship: false,
    homePhoneNumber: !mobilePhoneNumber ? false : true,
    mobilePhoneNumber: !homePhoneNumber ? false : true,
    email: true,
    authorisedForEnquiries: false,
    supportPerson: false,
  };

  // Change the regex and presence if the region is set
  if (region === Region.UK) {
    nameRegex = UK_NAME_REGEX;
    homePhoneRegex = homePhoneNumber ? UK_PHONE_REGEX : '.*';
    mobilePhoneRegex = mobilePhoneNumber ? UK_PHONE_REGEX : '.*';

    presences.firstName = true;
    presences.lastName = true;
    presences.relationship = true;
    presences.homePhoneNumber = true;
    presences.mobilePhoneNumber = true;
  }

  const rules: RulesInterface = {
    firstName: {
      presence: {
        allowEmpty: presences.firstName,
        message: VALIDATION_MESSAGE_TYPE.MISSING,
      },
      format: {
        pattern: nameRegex,
        message: VALIDATION_MESSAGE_TYPE.INVALID,
      },
    },
    lastName: {
      presence: {
        allowEmpty: presences.lastName,
        message: VALIDATION_MESSAGE_TYPE.MISSING,
      },
      format: {
        pattern: nameRegex,
        message: VALIDATION_MESSAGE_TYPE.INVALID,
      },
    },
    relationship: {
      presence: {
        allowEmpty: presences.relationship,
        message: VALIDATION_MESSAGE_TYPE.MISSING,
      },
    },
    homePhoneNumber: {
      presence: {
        allowEmpty: presences.homePhoneNumber,
        message: VALIDATION_MESSAGE_TYPE.MISSING,
      },
      format: {
        pattern: homePhoneRegex,
        message: VALIDATION_MESSAGE_TYPE.INVALID,
      },
    },
    mobilePhoneNumber: {
      presence: {
        allowEmpty: presences.mobilePhoneNumber,
        message: VALIDATION_MESSAGE_TYPE.MISSING,
      },
      format: {
        pattern: mobilePhoneRegex,
        message: VALIDATION_MESSAGE_TYPE.INVALID,
      },
    },
    email: {
      presence: {
        allowEmpty: presences.email,
      },
      format: {
        pattern: EMAIL_REGEX,
        message: VALIDATION_MESSAGE_TYPE.INVALID,
      },
    },
    authorisedForEnquiries: {
      presence: {
        allowEmpty: presences.authorisedForEnquiries,
        message: VALIDATION_MESSAGE_TYPE.MISSING,
      },
    },
    supportPerson: {
      presence: {
        allowEmpty: presences.supportPerson,
        message: VALIDATION_MESSAGE_TYPE.MISSING,
      },
    },
  };

  // Delete the rules that are not required
  if (region === Region.UK) {
    delete rules.authorisedForEnquiries;
    delete rules.supportPerson;
  }

  return rules;
};

const getUKGPRules = (
  gpSurgeryRefData: ListData[],
  surgeonLocationRefData: ListData[],
  gpRefData: ListData[],
  surgeonRefData: ListData[],
  oncologistRefData: ListData[],
  details: UKPatientGP,
): object => {
  return {
    generalPractitionerLocation: {
      presence: {
        allowEmpty: true,
        message: 'Please select a surgery',
      },
    },
    generalPractitioner: {
      presence: {
        allowEmpty: !details.generalPractitionerLocation,
        message: 'Please select a practitioner',
      },
      list: {
        listdata: gpRefData,
        message: VALIDATION_MESSAGE_TYPE.INVALID,
      },
    },
    referringSurgeon: {
      presence: {
        allowEmpty: true,
        message: 'Please select a clinician',
      },
    },
    referringSurgeonLocation: {
      presence: {
        allowEmpty: true,
        message: 'Please select a location',
      },
    },
    oncologist: {
      presence: {
        allowEmpty: false,
        message: 'Please select oncologist',
      },
      format: {
        pattern: NAME_REGEX,
        message: VALIDATION_MESSAGE_TYPE.INVALID,
      },
    },
  };
};

const GP_RULES = {
  gpFirstName: {
    presence: {
      allowEmpty: false,
      message: VALIDATION_MESSAGE_TYPE.MISSING,
    },
    format: {
      pattern: NAME_REGEX,
      message: VALIDATION_MESSAGE_TYPE.INVALID,
    },
  },
  gpLastName: {
    presence: {
      allowEmpty: false,
      message: VALIDATION_MESSAGE_TYPE.MISSING,
    },
    format: {
      pattern: NAME_REGEX,
      message: VALIDATION_MESSAGE_TYPE.INVALID,
    },
  },
  gpPractice: {
    presence: {
      allowEmpty: false,
      message: VALIDATION_MESSAGE_TYPE.MISSING,
    },
  },
  referringFirstName: {
    presence: {
      allowEmpty: false,
      message: VALIDATION_MESSAGE_TYPE.MISSING,
    },
    format: {
      pattern: '[A-Za-z0-9\\s_.,!"\'-]*',
      message: VALIDATION_MESSAGE_TYPE.INVALID,
    },
  },
  referringLastName: {
    presence: {
      allowEmpty: false,
      message: VALIDATION_MESSAGE_TYPE.MISSING,
    },
    format: {
      pattern: '[A-Za-z0-9\\s_.,!"\'-]*',
      message: VALIDATION_MESSAGE_TYPE.INVALID,
    },
  },
  referringPractice: {
    presence: {
      allowEmpty: false,
      message: VALIDATION_MESSAGE_TYPE.MISSING,
    },
  },
};

const gpOptionalFields = ['gpFirstName', 'gpLastName', 'gpPractice'];

const MEDICARE_RULES = {
  healthMedicareDvaOption: {
    presence: {
      allowEmpty: false,
      message: VALIDATION_MESSAGE_TYPE.MISSING,
    },
  },
  healthPrivateHealthInsurance: {
    presence: {
      allowEmpty: false,
      message: VALIDATION_MESSAGE_TYPE.MISSING,
    },
  },
  healthPensionCard: {
    presence: {
      allowEmpty: false,
      message: VALIDATION_MESSAGE_TYPE.MISSING,
    },
  },
  healthMedicareExpiry: {
    cardExpiryMMYYYY: {
      inputDateformat: 'YYYY-MM-DD',
      message: VALIDATION_MESSAGE_TYPE.INVALID,
    },
    presence: {
      allowEmpty: false,
      message: VALIDATION_MESSAGE_TYPE.MISSING,
    },
  },
  healthPensionCardExpiry: {
    cardExpiryMMYYYY: {
      inputDateformat: 'YYYY-MM-DD',
      message: VALIDATION_MESSAGE_TYPE.INVALID,
    },
    presence: {
      allowEmpty: false,
      message: VALIDATION_MESSAGE_TYPE.MISSING,
    },
  },
  healthDvaExpiry: {
    cardExpiryMMYYYY: {
      inputDateformat: 'YYYY-MM-DD',
      message: VALIDATION_MESSAGE_TYPE.INVALID,
    },
    presence: {
      allowEmpty: false,
      message: VALIDATION_MESSAGE_TYPE.MISSING,
    },
  },
};

const quarantiniReferralRules = {
  isSymptomatic: {
    presence: {
      allowEmpty: false,
      message: VALIDATION_MESSAGE_TYPE.MISSING,
    },
  },
  hasGpReferral: {
    presence: {
      allowEmpty: false,
      message: VALIDATION_MESSAGE_TYPE.MISSING,
    },
  },
};

const getUkAddressRules = (isMandatory = false): object => {
  return {
    residentialAddressLine1: {
      presence: {
        allowEmpty: !isMandatory,
        message: VALIDATION_MESSAGE_TYPE.MISSING,
      },
    },
    residentialAddressCity: {
      presence: {
        allowEmpty: !isMandatory,
        message: VALIDATION_MESSAGE_TYPE.MISSING,
      },
    },
    residentialAddressCountry: {
      presence: {
        allowEmpty: !isMandatory,
        message: VALIDATION_MESSAGE_TYPE.MISSING,
      },
    },
    residentialAddressState: {
      presence: {
        allowEmpty: !isMandatory,
        message: VALIDATION_MESSAGE_TYPE.MISSING,
      },
    },
    residentialAddressPostcode: {
      presence: {
        allowEmpty: !isMandatory,
        message: VALIDATION_MESSAGE_TYPE.MISSING,
      },
      format: {
        pattern: isMandatory ? UK_POSTCODE_REGEX : '.*',
        message: VALIDATION_MESSAGE_TYPE.INVALID,
      },
    },
  };
};

const getAddressRules = (
  referenceData: ListData[],
  residentialStatePattern: string,
  allowEmptyState = false,
  region: string,
): object => {
  let residentialAddressPostcode = {};
  const rules = {
    residentialAddressLine1: {
      presence: {
        allowEmpty: false,
        message: VALIDATION_MESSAGE_TYPE.MISSING,
      },
    },
    residentialAddressCity: {
      presence: {
        allowEmpty: false,
        message: VALIDATION_MESSAGE_TYPE.MISSING,
      },
      format: {
        pattern: UK_CITY_REGEX,
        message: VALIDATION_MESSAGE_TYPE.INVALID,
      },
    },
    residentialAddressCountry: {
      presence: {
        allowEmpty: false,
        message: VALIDATION_MESSAGE_TYPE.MISSING,
      },
      list: {
        listdata: referenceData,
        message: VALIDATION_MESSAGE_TYPE.INVALID,
      },
    },
    residentialAddressState: {
      presence: {
        allowEmpty: allowEmptyState,
        message: VALIDATION_MESSAGE_TYPE.MISSING,
      },
      format: {
        pattern: residentialStatePattern,
        message: VALIDATION_MESSAGE_TYPE.INVALID,
      },
    },
  };

  if (region === Region.UK) {
    residentialAddressPostcode = {
      presence: {
        allowEmpty: allowEmptyState,
        message: VALIDATION_MESSAGE_TYPE.MISSING,
      },
      format: {
        pattern: !allowEmptyState ? UK_POSTCODE_REGEX : '.*',
        message: VALIDATION_MESSAGE_TYPE.INVALID,
      },
    };
  } else {
    residentialAddressPostcode = {
      presence: {
        allowEmpty: allowEmptyState,
        message: VALIDATION_MESSAGE_TYPE.MISSING,
      },
    };
  }
  return { ...rules, residentialAddressPostcode };
};

const getUKDemographicRules = (ethnicities: ListData[], religions: ListData[], maritalStatuses: ListData[]): object => {
  return {
    ethnicity: {
      presence: {
        allowEmpty: true,
        message: VALIDATION_MESSAGE_TYPE.MISSING,
      },
      list: {
        listdata: ethnicities,
        message: VALIDATION_MESSAGE_TYPE.INVALID,
      },
    },
    religion: {
      presence: {
        allowEmpty: true,
        message: VALIDATION_MESSAGE_TYPE.MISSING,
      },
      list: {
        listdata: religions,
        message: VALIDATION_MESSAGE_TYPE.INVALID,
      },
    },
    maritalStatus: {
      presence: {
        allowEmpty: true,
        message: VALIDATION_MESSAGE_TYPE.MISSING,
      },
      list: {
        listdata: maritalStatuses,
        message: VALIDATION_MESSAGE_TYPE.INVALID,
      },
    },
  };
};

const getDemographicRules = (countries: ListData[], languages: ListData[]): object => {
  return {
    countryOfBirth: {
      presence: {
        allowEmpty: false,
        message: VALIDATION_MESSAGE_TYPE.MISSING,
      },
      list: {
        listdata: countries,
        message: VALIDATION_MESSAGE_TYPE.INVALID,
      },
    },
    languageAtHome: {
      presence: {
        allowEmpty: false,
        message: VALIDATION_MESSAGE_TYPE.MISSING,
      },
      list: {
        listdata: languages,
        message: VALIDATION_MESSAGE_TYPE.INVALID,
      },
    },
    occupation: {
      presence: {
        allowEmpty: false,
        message: VALIDATION_MESSAGE_TYPE.MISSING,
      },
    },
    heritage: {
      presence: {
        allowEmpty: false,
        message: VALIDATION_MESSAGE_TYPE.MISSING,
      },
    },
    maritalStatus: {
      presence: {
        allowEmpty: false,
        message: VALIDATION_MESSAGE_TYPE.MISSING,
      },
    },
  };
};
const validateAddressRules = (
  patientAddress: PatientAddress,
  referenceData?: ValidationReference,
  region: string = Region.AU,
): object => {
  let residentialStateValidationFormatPattern = '[a-zA-Z\\s]+|';
  let postalStateValidationFormatPattern = '[a-zA-Z\\s]+|';
  let allowEmptyState = true;
  let isMandatory = true;

  // Find the Australian and UK indices in the countries dictionary
  const countryReferenceData = (referenceData as AddressValidationReference).countries;
  const australianIndex = countryReferenceData.findIndex((country) => country.name === 'Australia');

  // UK is hidden as an option in the AU instance of Horizon, ignore UK validation rules if not in Horizon UK
  const countryName = region === Region.UK ? 'United Kingdom' : 'Scotland';
  const ukIndex = countryReferenceData.findIndex((country) => country.name === countryName);

  const australiaCountryIndex = countryReferenceData[australianIndex].id;
  const ukCountryIndex = countryReferenceData[ukIndex].id;

  if (
    patientAddress.residentialAddressCountry === australiaCountryIndex ||
    patientAddress.residentialAddressCountry === ukCountryIndex
  ) {
    residentialStateValidationFormatPattern = '.*';
    if (patientAddress.residentialAddressCountry === ukCountryIndex) allowEmptyState = false;
  }

  if (patientAddress.postalAddressCountry === australiaCountryIndex) {
    postalStateValidationFormatPattern = '.*';
  }
  if (!patientAddress.residentialAddressCountry) allowEmptyState = false;
  if (region === Region.UK && patientAddress.registrationReason) {
    isMandatory = patientAddress.registrationReason !== 'New Patient for Outpatient Visit';
  }

  let validationRules: object;
  if (!isMandatory) {
    validationRules = getUkAddressRules(false);
  } else {
    validationRules = getAddressRules(
      countryReferenceData,
      residentialStateValidationFormatPattern,
      allowEmptyState,
      region,
    );
  }

  if (patientAddress.postalAddressSameAsResidential === false) {
    validationRules = {
      ...validationRules,
      postalAddressLine1: {
        presence: {
          allowEmpty: false,
          message: VALIDATION_MESSAGE_TYPE.MISSING,
        },
      },
      postalAddressCity: {
        presence: {
          allowEmpty: false,
          message: VALIDATION_MESSAGE_TYPE.MISSING,
        },
        format: {
          pattern: '[a-zA-Z\\s]+|',
          message: VALIDATION_MESSAGE_TYPE.INVALID,
        },
      },
      postalAddressCountry: {
        presence: {
          allowEmpty: false,
          message: VALIDATION_MESSAGE_TYPE.MISSING,
        },
        list: {
          listdata: countryReferenceData,
          message: VALIDATION_MESSAGE_TYPE.INVALID,
        },
      },
      postalAddressState: {
        presence: {
          allowEmpty: !!patientAddress.postalAddressCountry,
          message: VALIDATION_MESSAGE_TYPE.MISSING,
        },
        format: {
          pattern: postalStateValidationFormatPattern,
          message: VALIDATION_MESSAGE_TYPE.INVALID,
        },
      },
      postalAddressPostcode: {
        presence: {
          allowEmpty: isMandatory ? allowEmptyState : true,
          message: VALIDATION_MESSAGE_TYPE.MISSING,
        },
      },
    };
  }

  return validationRules;
};

const getUKMosaiqRules = (patient: { [key: string]: object }): { [key: string]: object } => {
  const { primaryPhone, secondaryPhone, email }: { [key: string]: object } = getUKContactRules(
    patient,
    UK_PHONE_REGEX,
    true,
  );
  let isMandatory = true;
  const patientAddress = patient as PatientAddress;
  if (patientAddress.registrationReason) {
    isMandatory = patientAddress.registrationReason !== 'New Patient for Outpatient Visit';
  }

  let allowEmptyState = true;
  // @ts-ignore
  if (patient.residentialAddressCountryName === 'United Kingdom') {
    allowEmptyState = false;
  }

  const addressRules = {
    residentialAddressLine1: {
      presence: {
        allowEmpty: !isMandatory,
        message: VALIDATION_MESSAGE_TYPE.MISSING,
      },
    },
    residentialAddressCity: {
      presence: {
        allowEmpty: !isMandatory,
        message: VALIDATION_MESSAGE_TYPE.MISSING,
      },
    },
    residentialAddressState: {
      presence: {
        allowEmpty: allowEmptyState,
        message: VALIDATION_MESSAGE_TYPE.MISSING,
      },
    },
    residentialAddressCountry: {
      presence: {
        allowEmpty: !isMandatory,
        message: VALIDATION_MESSAGE_TYPE.MISSING,
      },
    },
    residentialAddressPostcode: {
      presence: {
        allowEmpty: allowEmptyState,
        message: VALIDATION_MESSAGE_TYPE.MISSING,
      },
      format: {
        pattern: !allowEmptyState ? UK_POSTCODE_REGEX : '.*',
        message: VALIDATION_MESSAGE_TYPE.INVALID,
      },
    },
  };

  const altContact = getAlternateContactRules(patient as PatientAltContact, Region.UK);
  const basicRules = getUKBasicRules(patient as PatientBasic);
  const mosaiqRules = {
    primaryPhone,
    secondaryPhone,
    email,
    generalPractitioner: {
      presence: {
        allowEmpty: !patient.generalPractitionerLocation,
        message: VALIDATION_MESSAGE_TYPE.MISSING,
      },
    },
    referringSurgeon: {
      presence: {
        allowEmpty: !patient.referringSurgeonLocation,
        message: VALIDATION_MESSAGE_TYPE.MISSING,
      },
    },
    oncologist: {
      presence: {
        allowEmpty: false,
        message: VALIDATION_MESSAGE_TYPE.MISSING,
      },
    },
    payor: {
      presence: {
        allowEmpty: !patient.policyNumber,
        message: VALIDATION_MESSAGE_TYPE.MISSING,
      },
    },
  };

  const rules = { ...altContact, ...basicRules, ...mosaiqRules, ...addressRules };

  // @ts-ignore
  return rules;
};

const validateRegistration = (
  patient: Patient,
  validationKey: ValidationKeys,
  referenceData?: ValidationReference,
  region: string = Region.AU,
  isPso = false,
): any => {
  switch (validationKey) {
    case ValidationKeys.Basic:
      const modifiedPatient = modifyPatientDOBRaw(patient);
      if (region === Region.UK) {
        const UK_BASIC_RULES = getUKBasicRules(patient as PatientBasic);
        return validate(modifiedPatient, UK_BASIC_RULES, options);
      } else {
        return validate(modifiedPatient, BASIC_RULES, options);
      }
    case ValidationKeys.Contact:
      const contactRules = validateContactRules(patient as PatientContactDetails, region);
      return validate(patient, contactRules, options);
    case ValidationKeys.ContactPXSignUp:
      const contactPXSignUpRules = validatePXSignUpRules(patient as PatientContactDetails, MOBILE_NUMBER_REGEX, false);
      return validate(patient, contactPXSignUpRules, options);
    case ValidationKeys.Address:
      const addressRules = validateAddressRules(patient as PatientAddress, referenceData, region);
      return validate(patient, addressRules, options);
    case ValidationKeys.AltContact:
      let altContactRules = null;
      if (region === Region.UK) {
        altContactRules = validateUKAltContactRules(patient as UKPatientAltContact);
      } else {
        altContactRules = validateAltContactRules(patient as PatientAltContact);
      }
      return validate(patient, altContactRules, options);
    case ValidationKeys.GpInfo:
      if (region === Region.UK) {
        const gpRules = validateUKGPRules(patient as UKPatientGP, referenceData);
        return validate(patient, gpRules, options);
      } else {
        return validate(patient, GP_RULES, options);
      }
    case ValidationKeys.Medicare:
      const medicareRules = validateMedicareRules(patient as PatientMedicare);
      return validate(patient, medicareRules, options);
    case ValidationKeys.Demographics:
      let demographicRules = null;
      if (region === Region.UK) {
        demographicRules = validateUKDemographicsRules(referenceData);
      } else {
        demographicRules = validateDemographicsRules(referenceData);
      }
      return validate(patient, demographicRules, options);
    case ValidationKeys.Mosaiq:
      const updatedPatient = modifyPatientDOBRaw(patient);
      if (region === Region.UK) {
        // @ts-ignore
        const rules = getUKMosaiqRules(patient);
        return validate(updatedPatient, rules, options);
      } else {
        let rules = { ...MOSAIQ_MINIMUM_REQUIREMENT_RULES };
        if (
          patient.userProfile?.systemState &&
          patient.userProfile.systemState !== 'THA' &&
          patient.attachments &&
          patient.attachments.length &&
          patient.attachments.filter((item: PatientAttachment) => item.isRegistrationAttachment).length
        ) {
          rules = { ...rules, ..._ATTACHMENT_ACKNOWLEDGED_RULE };
        }
        let validation = validate(updatedPatient, rules, options);
        const attachmentValidation = validateAttachments(patient as PatientAttachments, isPso);
        if (attachmentValidation) {
          validation = { ...validation, ...attachmentValidation };
        }
        return validation;
      }
    case ValidationKeys.MosaiqPatient:
      const modifiedDobPatient = modifyPatientDOBRaw(patient);
      const patientRules = getAUMosaiqRules(patient);
      return validate(modifiedDobPatient, patientRules, options);
    case ValidationKeys.Quarantini:
      const PATIENT_RULES = getQuarantiniPatient(patient as OPClinicPatient);
      const REFERRAL_RULES = { ...quarantiniReferralRules };
      if (patient.clinicType === 'Urology') {
        // @ts-ignore
        REFERRAL_RULES.recentBloodResults = {
          presence: {
            allowEmpty: false,
            message: VALIDATION_MESSAGE_TYPE.MISSING,
          },
        };
      } else if (patient.clinicType === 'Breast') {
        // @ts-ignore
        REFERRAL_RULES.laterality = {
          presence: {
            allowEmpty: false,
            message: VALIDATION_MESSAGE_TYPE.MISSING,
          },
        };
      }
      const addrRules = getUkAddressRules(true);
      const payorRules = getClinicPayorRules(patient as PatientQuarantiniInsurance);
      const FULL_PATIENT_RULES = { ...PATIENT_RULES, ...addrRules };
      const quarantiniRules = {
        ...CLINIC_RULES,
        ...payorRules,
        ...FULL_PATIENT_RULES,
        ...REFERRAL_RULES,
      };

      const optionalAppt = !patient.isSymptomatic || patient.selfPaid || patient.nextAppointment;
      if (!optionalAppt && !patient.interimApptStart) {
        // @ts-ignore
        quarantiniRules.appointmentMissing = {
          presence: {
            allowEmpty: false,
          },
        };
      }
      if (patient.apptClash) {
        // @ts-ignore
        quarantiniRules.apptClash = {
          presence: {
            allowEmpty: false,
          },
          format: {
            pattern: "'a^'",
            message: 'The appointment time you have selected is no longer available',
          },
        };
      }
      const validationResults = validate(patient, quarantiniRules, options);

      const invalidSections = new Set<string>();

      if (validationResults) {
        Object.keys(validationResults).forEach((key: string): void => {
          if (Object.keys(CLINIC_RULES).includes(key)) {
            invalidSections.add(INVALID_SECTIONS.CLINIC);
          } else if (Object.keys(payorRules).includes(key)) {
            invalidSections.add(INVALID_SECTIONS.CLINIC_PAYOR);
          } else if (Object.keys(FULL_PATIENT_RULES).includes(key)) {
            invalidSections.add(INVALID_SECTIONS.PATIENT);
          } else if (Object.keys(REFERRAL_RULES).includes(key)) {
            invalidSections.add(INVALID_SECTIONS.REFERRAL);
          }
        });

        if (invalidSections.size > 0) {
          const invalidSectionsArray: string[] = [];
          invalidSections.forEach((value: string) => {
            invalidSectionsArray.push(value);
          });

          validationResults.invalidSections = invalidSectionsArray;
        }
      }
      return validationResults;
    default:
      if (region === Region.UK) {
        return validateAllUKSections(patient as UKFullPatient, referenceData, region);
      } else {
        return validateAllSections(patient as FullPatient, referenceData, region, isPso);
      }
  }
};

const validatePXSignUpRules = (
  patientContact: PatientContactDetails,
  regexPattern: string = PHONE_NUMBER_REGEX,
  optionalEmail = true,
): object => {
  const validationRules: { [key: string]: object } = getPXSignUpRules(regexPattern, optionalEmail);
  // TODO: No longer needs to be a function. To be cleaned up as part of bulk cleanup.
  return validationRules;
};

const validateContactRules = (
  patientContact: PatientContactDetails,
  region: string = Region.AU,
  optionalEmail = true,
): object => {
  let validationRules: { [key: string]: object };

  if (region === Region.UK) {
    validationRules = getUKContactRules(patientContact, UK_PHONE_REGEX, optionalEmail);
  } else {
    validationRules = getContactRules(patientContact, PHONE_NUMBER_REGEX, optionalEmail);
  }
  return validationRules;
};

const validateUKAltContactRules = (patientAltContact: UKPatientAltContact): object => {
  const validationRules: { [key: string]: object } = getAlternateContactRules(patientAltContact, Region.UK);
  return validationRules;
};

const validateAltContactRules = (patientAltContact: PatientAltContact): object => {
  const validationRules: { [key: string]: object } = getAlternateContactRules(patientAltContact);
  return validationRules;
};

const validateAttachments = (patient: PatientAttachments, isPso = false): object | void => {
  const { attachments } = patient;
  const validateAttachments =
    attachments && patient.userProfile?.systemState && patient.userProfile.systemState !== 'THA';

  if (validateAttachments) {
    // Filter out any attachments that were uploaded outside of the registration process e.g. patient summary doc upload
    const registrationAttachments = attachments.filter((item: PatientAttachment) => item.isRegistrationAttachment);
    const attachmentRules = {
      documentType: function () {
        if (!isPso) return null;

        return {
          presence: {
            allowEmpty: false,
          },
        };
      },
    };
    const validatedItems = {};
    registrationAttachments &&
      registrationAttachments.forEach((item: any): void => {
        const validatedItem = validate(item, attachmentRules);
        if (validatedItem) {
          // @ts-ignore
          validatedItems[`${item.filename}`] = validatedItem;
        }
      });

    if (Object.keys(validatedItems).length !== 0) {
      return { attachments: validatedItems };
    }
  }
};

const validateMedicareRules = (patientMedicare: PatientMedicare): object => {
  let validationRules: { [key: string]: object } = { ...MEDICARE_RULES };
  const showMedicareDVA = patientMedicare.healthMedicareDvaOption === MEDICARE_TYPES.MEDICARE_DVA;
  const showMedicare = showMedicareDVA || patientMedicare.healthMedicareDvaOption === MEDICARE_TYPES.MEDICARE;
  const showNone = showMedicareDVA || patientMedicare.healthMedicareDvaOption === MEDICARE_TYPES.NONE;

  if (showMedicareDVA) {
    validationRules = {
      ...validationRules,
      healthDvaNumber: {
        presence: {
          allowEmpty: false,
          message: VALIDATION_MESSAGE_TYPE.MISSING,
        },
        // See: https://meteor.aihw.gov.au/content/index.phtml/itemId/339127 for valid dva card pattern
        format: {
          pattern:
            '[NVQWST](([a-zA-Z]{1}[0-9]{6})|([a-zA-Z]{2}[0-9]{5})|([a-zA-Z]{3}[0-9]{4})|([a-zA-Z]{4}[0-9]{3})|( [0-9]{6}))([a-zA-Z]|)|',
          message: VALIDATION_MESSAGE_TYPE.INVALID,
        },
      },
      healthDvaType: {
        presence: {
          allowEmpty: false,
          message: VALIDATION_MESSAGE_TYPE.MISSING,
        },
      },
    };
    if (!patientMedicare.healthPensionCard) {
      delete validationRules['healthPensionCardExpiry'];
    }
  }

  if (showMedicare) {
    validationRules = {
      ...validationRules,
      healthMedicareNumber: {
        presence: {
          allowEmpty: false,
          message: VALIDATION_MESSAGE_TYPE.MISSING,
        },
        // Medicare rules are defined in validate.ts.
        // See https://stackoverflow.com/questions/3589345/how-do-i-validate-an-australian-medicare-number for rules.
        medicareNumber: {
          message: VALIDATION_MESSAGE_TYPE.INVALID,
        },
      },
      healthMedicareIrn: {
        presence: {
          allowEmpty: false,
          message: VALIDATION_MESSAGE_TYPE.MISSING,
        },
        format: {
          pattern: '[0-9]|',
          message: VALIDATION_MESSAGE_TYPE.INVALID,
        },
      },
    };

    if (!patientMedicare.healthPensionCard) {
      delete validationRules['healthPensionCardExpiry'];
    }
    delete validationRules['healthDvaExpiry'];
  }

  if (showNone) {
    if (!patientMedicare.healthPensionCard) {
      delete validationRules['healthPensionCardExpiry'];
    }
    delete validationRules['healthMedicareExpiry'];
    delete validationRules['healthDvaExpiry'];
  }

  if (patientMedicare.healthPrivateHealthInsurance) {
    validationRules = {
      ...validationRules,
      healthPrivateHealthFundName: {
        presence: {
          allowEmpty: false,
          message: VALIDATION_MESSAGE_TYPE.MISSING,
        },
      },
      healthPrivateHealthFundNumber: {
        presence: {
          allowEmpty: false,
          message: VALIDATION_MESSAGE_TYPE.MISSING,
        },
      },
    };
  }

  if (patientMedicare.healthPensionCard) {
    validationRules = {
      ...validationRules,
      healthPensionCardNumber: {
        presence: {
          allowEmpty: false,
          message: VALIDATION_MESSAGE_TYPE.MISSING,
        },
        format: {
          pattern: '[0-9]{9}[a-zA-Z]{1}|',
          message: VALIDATION_MESSAGE_TYPE.INVALID,
        },
      },
    };
  }

  if (patientMedicare.ambulanceMembership) {
    validationRules = {
      ...validationRules,
      ambulanceMembershipNumber: {
        presence: {
          allowEmpty: false,
          message: VALIDATION_MESSAGE_TYPE.MISSING,
        },
      },
    };
  }

  return validationRules;
};

const validateDemographicsRules = (referenceData?: ValidationReference): object => {
  const demographicsRefData = referenceData as DemographicsValidationReference;
  const validationRules = getDemographicRules(demographicsRefData.countries, demographicsRefData.languages);

  return validationRules;
};

const validateUKDemographicsRules = (referenceData?: ValidationReference): object => {
  const demographicsRefData = referenceData as UKDemographicsValidationReference;
  const validationRules = getUKDemographicRules(
    demographicsRefData.ethnicities,
    demographicsRefData.religions,
    demographicsRefData.maritalStatuses,
  );

  return validationRules;
};

const validateInformationNoticeRules = (patient: PatientInformationNotice): object => {
  let validationRules: { [key: string]: object } = {};
  if (!patient.informationNoticeAccepted) {
    validationRules = {
      informationNoticeAccepted: {
        inclusion: {
          within: [true],
          message: VALIDATION_MESSAGE_TYPE.INVALID,
        },
      },
    };
  }
  return validationRules;
};

const validateAttachmentAcknowledgedRule = (patient: Patient, isPso = false): object => {
  let validationRules: { [key: string]: object } = {};

  if (!patient.attachments || !patient.attachments.length) {
    return validationRules;
  }

  const registrationAttachments = patient.attachments.filter(
    (item: PatientAttachment) => item.isRegistrationAttachment,
  );

  if (!patient.attachmentAcknowledged && registrationAttachments && registrationAttachments.length && isPso) {
    validationRules = {
      attachmentAcknowledged: {
        inclusion: {
          within: [true],
          message: VALIDATION_MESSAGE_TYPE.MISSING,
        },
      },
    };
  }

  return validationRules;
};

const validateUKGPRules = (patient: UKPatientGP, referenceData?: ValidationReference): object => {
  const gpRefData = referenceData as UKGPValidationReference;
  const validationRules = getUKGPRules(
    gpRefData.gpSurgeryRefData,
    gpRefData.surgeonLocationRefData,
    gpRefData.gpRefData,
    gpRefData.surgeonRefData,
    gpRefData.oncologistRefData,
    patient,
  );
  return validationRules;
};

const getInsuranceRules = (patient: PatientCoverage, referenceData?: ValidationReference): object => {
  const refData = referenceData as CoverageRefData;

  return {
    payor: {
      presence: {
        allowEmpty: !patient.policyNumber,
        message: VALIDATION_MESSAGE_TYPE.MISSING,
      },
      list: {
        listdata: refData.insurers,
        message: VALIDATION_MESSAGE_TYPE.INVALID,
      },
    },
    preAuthNumber: {
      presence: {
        allowEmpty: true,
        message: VALIDATION_MESSAGE_TYPE.MISSING,
      },
    },
    policyNumber: {
      presence: {
        allowEmpty: true,
        message: VALIDATION_MESSAGE_TYPE.MISSING,
      },
    },
    coverageRelationship: {
      presence: {
        allowEmpty: true,
        message: VALIDATION_MESSAGE_TYPE.MISSING,
      },
      list: {
        listdata: refData.coverageRelationships,
        message: VALIDATION_MESSAGE_TYPE.INVALID,
      },
    },
  };
};

const validateAllUKSections = (
  patient: UKFullPatient,
  referenceData?: ValidationReference,
  region = Region.UK,
): object => {
  // Need to convert some of the patient data into something that can be validated by the basic
  const modifiedPatient = modifyPatientDOBRaw(patient);
  const contactRules = validateContactRules(patient as PatientContactDetails, region);
  const patientCopy = lodash.cloneDeep(patient);
  if (region === Region.UK) {
    patientCopy.postalAddressSameAsResidential = true;
  }
  const addressRules = validateAddressRules(patientCopy as PatientAddress, referenceData, region);
  const altContactRules = validateUKAltContactRules(patientCopy as UKPatientAltContact);
  const demographicRules = validateUKDemographicsRules(referenceData);
  const gpRules = validateUKGPRules(patientCopy as UKPatientGP, referenceData);
  const UK_BASIC_RULES = getUKBasicRules(patientCopy as PatientBasic);
  const insuranceRules = getInsuranceRules(patientCopy as PatientCoverage, referenceData);

  const rules = {
    ...UK_BASIC_RULES,
    ...contactRules,
    ...addressRules,
    ...altContactRules,
    ...gpRules,
    ...demographicRules,
    ...insuranceRules,
  };

  // Get the validation results
  const validationResults = validate(modifiedPatient, rules, options);
  const invalidSections = new Set<string>();

  if (validationResults) {
    Object.keys(validationResults).forEach((key: string): void => {
      if (Object.keys(UK_BASIC_RULES).includes(key)) {
        invalidSections.add(INVALID_SECTIONS.BASIC);
      } else if (Object.keys(contactRules).includes(key)) {
        invalidSections.add(INVALID_SECTIONS.CONTACT);
      } else if (Object.keys(addressRules).includes(key)) {
        invalidSections.add(INVALID_SECTIONS.ADDRESS);
      } else if (Object.keys(gpRules).includes(key)) {
        invalidSections.add(INVALID_SECTIONS.UK_GP);
      } else if (Object.keys(altContactRules).includes(key)) {
        invalidSections.add(INVALID_SECTIONS.UK_ALT_CONTACT);
      } else if (Object.keys(demographicRules).includes(key)) {
        invalidSections.add(INVALID_SECTIONS.UK_DEMOGRAPHICS);
      } else if (Object.keys(insuranceRules).includes(key)) {
        invalidSections.add(INVALID_SECTIONS.INSURANCE);
      }
    });

    if (invalidSections.size > 0) {
      const invalidSectionsArray: string[] = [];
      invalidSections.forEach((value: string) => {
        invalidSectionsArray.push(value);
      });

      validationResults.invalidSections = invalidSectionsArray;
    }
  }

  return validationResults;
};

const validateAllSections = (
  patient: FullPatient,
  referenceData?: ValidationReference,
  region: string = Region.AU,
  isPso = false,
): object => {
  // Need to convert some of the patient data into something that can be validated by the basic
  let modifiedPatient = modifyPatientDOBRaw(patient);
  modifiedPatient = modifyPatientCountryOfBirth(modifiedPatient, referenceData?.countries);
  const contactRules = validateContactRules(patient as PatientContactDetails, region);
  const addressRules = validateAddressRules(patient as PatientAddress, referenceData, region);
  // FIXME: Need to update this once the rest is working
  const altContactRules = validateAltContactRules(patient as PatientAltContact);
  const medicareRules = validateMedicareRules(patient as PatientMedicare);
  const demographicRules = validateDemographicsRules(referenceData);
  const informationNoticeRules = validateInformationNoticeRules(patient as PatientInformationNotice);
  const attachmentAcknowledgedRule = validateAttachmentAcknowledgedRule(patient, isPso);
  const rules = {
    ...BASIC_RULES,
    ...contactRules,
    ...addressRules,
    ...altContactRules,
    ...GP_RULES,
    ...medicareRules,
    ...demographicRules,
    ...informationNoticeRules,
    ...attachmentAcknowledgedRule,
  };

  // Get the validation results
  let validationResults = validate(modifiedPatient, rules, options);
  const attachmentValidationResults = validateAttachments(patient as PatientAttachments, isPso);
  if (attachmentValidationResults) {
    validationResults = { ...validationResults, ...attachmentValidationResults };
  }

  const invalidSections = new Set<string>();

  if (validationResults) {
    Object.keys(validationResults).forEach((key: string): void => {
      if (Object.keys(BASIC_RULES).includes(key)) {
        invalidSections.add(INVALID_SECTIONS.BASIC);
      } else if (Object.keys(contactRules).includes(key)) {
        invalidSections.add(INVALID_SECTIONS.CONTACT);
      } else if (Object.keys(addressRules).includes(key)) {
        invalidSections.add(INVALID_SECTIONS.ADDRESS);
      } else if (Object.keys(GP_RULES).includes(key) && !gpOptionalFields.includes(key)) {
        invalidSections.add(INVALID_SECTIONS.GP);
      } else if (Object.keys(altContactRules).includes(key)) {
        invalidSections.add(INVALID_SECTIONS.ALT_CONTACT);
      } else if (Object.keys(medicareRules).includes(key)) {
        invalidSections.add(INVALID_SECTIONS.MEDICARE);
      } else if (Object.keys(demographicRules).includes(key)) {
        invalidSections.add(INVALID_SECTIONS.DEMOGRAPHICS);
      } else if (Object.keys(informationNoticeRules).includes(key)) {
        invalidSections.add(INVALID_SECTIONS.INFORMATION_NOTICE);
      } else if (Object.keys(attachmentAcknowledgedRule).includes(key) || attachmentValidationResults) {
        invalidSections.add(INVALID_SECTIONS.ATTACHMENTS);
      }
    });

    if (invalidSections.size > 0) {
      const invalidSectionsArray: string[] = [];
      invalidSections.forEach((value: string) => {
        invalidSectionsArray.push(value);
      });

      validationResults.invalidSections = invalidSectionsArray;
    }
  }

  return validationResults;
};

// Need to replace country name with id for validation of list data
export const modifyPatientCountryOfBirth = (patient: Patient, countryOfBirthRefData?: ListData[]): Patient => {
  if (countryOfBirthRefData) {
    const id = countryOfBirthRefData.find((country: ListData) => country.name === patient.countryOfBirth)?.id;
    if (id) {
      patient['countryOfBirth'] = id;
    }
  }
  return patient;
};

const modifyPatientDOBRaw = (patient: Patient): Patient => {
  const modifiedPatient = { ...patient };
  modifiedPatient.dobRaw = appendRawToDOBRawString(patient as PatientBasic);
  return modifiedPatient;
};

export const appendRawToDOBRawString = (patient: PatientBasic): string => {
  const { dob, dobRawYear } = patient;
  let { dobRawDay: modifiedDobDay, dobRawMonth: modifiedDobMonth } = patient;

  // Return a blank value if all values are undefined or empty.
  if (!modifiedDobDay || !modifiedDobMonth || !dobRawYear) {
    // Convert the dob into parts so that it can be used for validation if there is nothing in the dob raw items
    if (dob) {
      // Convert DJango date to dob input pattern
      let dobRaw = dob.replace(/-/g, '');
      // Convert yyyymmdd to ddmmyyyy
      dobRaw = `${dobRaw.slice(6, 8)}${dobRaw.slice(4, 6)}${dobRaw.slice(0, 4)}`;
      return dobRaw;
    }

    // There is no dob either, so just return an empty string
    return '';
  }

  // Append the values and put it into a full string for validation purposes
  if (modifiedDobDay && modifiedDobDay.length === 1) {
    modifiedDobDay = `0${modifiedDobDay}`;
  }

  if (modifiedDobMonth && modifiedDobMonth.length === 1) {
    modifiedDobMonth = `0${modifiedDobMonth}`;
  }

  // Append the modified dob day and month
  return `${modifiedDobDay}${modifiedDobMonth}${dobRawYear}`;
};

/**
 * Function that will use the global set rules to return a specific rule set.
 * @param type The type of validation that is being performed.
 * @param viewedKeys The keys that has been viewed so the rules can be fetched.
 * @returns Optional rule set that should be used to validate.
 */
export const fetchRules = (type: ValidationKeys, viewedKeys: Set<string>): { [key: string]: object } | undefined => {
  let globalRules: { [key: string]: object } | null = null;
  let specificRules: { [key: string]: object } | null = null;
  switch (type) {
    case ValidationKeys.GpInfo:
      globalRules = GP_RULES;
      break;
    case ValidationKeys.HomeRegSignup:
      globalRules = getHomeRegSignUpRules();
      break;
    default:
      // Do nothing
      break;
  }

  if (globalRules !== null) {
    specificRules = {};
    for (const viewed of viewedKeys) {
      specificRules[viewed] = globalRules[viewed];
    }

    return specificRules;
  }

  // Could not find the correct global rules so reject with undefined rules.
  return undefined;
};

const getAUMosaiqRules = (patient: any): { [key: string]: object } => {
  const contactRules = validateContactRules(patient as PatientContactDetails);
  const emergencyContactRules = getAlternateContactRules(patient);
  const demographicsRules = {
    heritage: {
      presence: {
        allowEmpty: false,
        message: VALIDATION_MESSAGE_TYPE.MISSING,
      },
    },
    maritalStatus: {
      presence: {
        allowEmpty: false,
        message: VALIDATION_MESSAGE_TYPE.MISSING,
      },
    },
    countryOfBirth: {
      presence: {
        allowEmpty: false,
        message: VALIDATION_MESSAGE_TYPE.MISSING,
      },
    },
    languageAtHome: {
      presence: {
        allowEmpty: false,
        message: VALIDATION_MESSAGE_TYPE.MISSING,
      },
    },
  };

  return {
    ...MOSAIQ_MINIMUM_REQUIREMENT_RULES,
    ...contactRules,
    ...demographicsRules,
    ...emergencyContactRules,
  };
};

export default validateRegistration;
