// eslint-disable-next-line no-use-before-define
import React, { Component, Fragment, useEffect } from 'react';
import { Query } from '@apollo/client/react/components';
import { RouteComponentProps, withRouter } from 'react-router-dom';

import { HAAllergiesItem } from 'op-classes';
import { HAMutationType } from 'op-enums';
import { ApolloSaveStatus } from 'op-types';
import { LoadingSpinner } from 'shared-components/components';
import { ApolloNetworkStatus } from 'shared-components/enums';
import { Dictionary } from 'shared-components/interfaces';
import { Logger } from 'shared-components/utils';

import { useErrorModalContext } from 'op-contexts';
import { HA_ALLERGY_QUERY } from '../../HASharedComponents/queries';
import { HealthAssessmentQueryData } from '../../HASharedComponents/types';
import withHealthAssessment, { WithHealthAssessment } from '../../HealthAssessment';
import HAAllergies from './HAAllergies';
import { NetworkStatus } from '@apollo/client';

const logger = new Logger('HAAllergiesApollo');

interface Props extends WithHealthAssessment, RouteComponentProps<{ patientId: string; haId: string }> {
  client: any;
  updateSaveStatus: ApolloSaveStatus;
  validateOnLoad: boolean;
}

class HAAllergiesApollo extends Component<Props> {
  public render(): JSX.Element {
    const {
      match: {
        params: { patientId },
      },
      validateOnLoad,
    } = this.props;
    return (
      <Fragment>
        <Query<HealthAssessmentQueryData> query={HA_ALLERGY_QUERY} variables={{ patientId }}>
          {({ loading, data, refetch, networkStatus, error }): JSX.Element => {
            const { setError } = useErrorModalContext();
            useEffect(() => {
              if (error) return setError();
            }, [error]);

            // Don't rerender whole component on refetch, otherwise will flash loading and scroll to top
            if (loading && networkStatus !== NetworkStatus.refetch) return <LoadingSpinner relativeSpinner={true} />;

            if (data && data.healthAssessment) {
              const haAllergies = new HAAllergiesItem().loadData(data.healthAssessment);
              return (
                <HAAllergies
                  patientId={patientId}
                  autosave={this.autosave}
                  performCRUD={this.performOperation}
                  refetch={refetch}
                  haAllergies={haAllergies}
                  validateOnLoad={validateOnLoad}
                  sensitivityRefData={data.sensitivityRefData}
                  reactionRefData={data.reactionRefData}
                />
              );
            }
            return <div></div>;
          }}
        </Query>
      </Fragment>
    );
  }

  private autosave = (
    updateObject: object,
    replaceId?: string,
    refetch?: any,
    mutationType?: HAMutationType,
  ): Promise<void> => {
    const { mutateGraph, client, getSaveStatus, updateSaveStatus } = this.props;

    // Mutate the graph
    return mutateGraph(
      client,
      updateObject,
      () => {
        getSaveStatus(client).then((savingStatus) => {
          updateSaveStatus(savingStatus);
        });
      },
      () => {},
      () => {},
      async () => {
        const saveStatus = await getSaveStatus(client);
        updateSaveStatus(saveStatus);

        if (refetch !== undefined) {
          try {
            refetch();
          } catch (error) {
            logger.debug('Refetch failed', error);
          }
        }
      },
      replaceId,
      undefined,
      mutationType,
    );
  };

  private performOperation = (
    haId: string,
    operation: string,
    objectType: string,
    itemId?: string,
    refetch?: any,
    relatedObjectId?: string,
  ): void => {
    const { mutateCRUDGraph, client, getSaveStatus, updateSaveStatus } = this.props;

    // Build the update object
    const updateObject: Dictionary = {
      haId,
      objectType,
      operation,
    };

    if (relatedObjectId) {
      updateObject['relatedObjectId'] = relatedObjectId;
    }

    if (itemId !== undefined && itemId !== '') {
      updateObject.id = itemId;
    }

    // Call the mutation to update the CRUD
    mutateCRUDGraph(
      client,
      updateObject,
      (): void => {
        getSaveStatus(client).then((savingStatus): void => {
          updateSaveStatus(savingStatus);
        });
      },
      (): void => {},
      (): void => {},
      async (): Promise<void> => {
        const saveStatus = await getSaveStatus(client);
        updateSaveStatus(saveStatus);
        if (refetch !== undefined) {
          try {
            refetch();
          } catch (error) {
            logger.debug('Refetch failed', error);
          }
        }
      },
    );
  };
}

const routedComponent = withRouter(HAAllergiesApollo);
const haComponent = withHealthAssessment(routedComponent);
export default haComponent;
