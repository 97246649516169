import type { DraggableLocation } from '@hello-pangea/dnd';

export interface OAR {
  alara: boolean;
  dConstraintData: any;
  maxValue: null | number[];
  maxValueUnit: null | string;
  meanValue?: null | number[];
  meanValueUnit: string;
  organ: string;
  volumeConstraintData: any;
  prioritized: boolean;
  index: number;
}

export interface OARMap {
  [key: string]: OAR[];
}

function reorder<TItem>(list: TItem[], startIndex: number, endIndex: number): TItem[] {
  const result = [...list];
  const [removed] = result.splice(startIndex, 1);
  result.splice(endIndex, 0, removed);

  return result;
}

export default reorder;

interface ReorderOARMapArgs {
  oarMap: OARMap;
  source: DraggableLocation;
  destination: DraggableLocation;
}

export const reorderOARMap = ({ oarMap, source, destination }: ReorderOARMapArgs): OARMap => {
  const current: OAR[] = [...oarMap[source.droppableId]];
  const next: OAR[] = [...oarMap[destination.droppableId]];
  const target: OAR = current[source.index];

  // moving to same list
  if (source.droppableId === destination.droppableId) {
    const reordered: OAR[] = reorder(current, source.index, destination.index);
    const result: OARMap = {
      ...oarMap,
      [source.droppableId]: reordered,
    };
    return result;
  }

  // moving to different list

  // remove from original
  current.splice(source.index, 1);
  // insert into next
  next.splice(destination.index, 0, target);

  const result: OARMap = {
    ...oarMap,
    [source.droppableId]: current,
    [destination.droppableId]: next,
  };

  return result;
};

interface List<T> {
  id: string;
  values: T[];
}

interface MoveBetweenArgs<T> {
  list1: List<T>;
  list2: List<T>;
  source: DraggableLocation;
  destination: DraggableLocation;
}

interface MoveBetweenResult<T> {
  list1: List<T>;
  list2: List<T>;
}

export function moveBetween<T>({ list1, list2, source, destination }: MoveBetweenArgs<T>): MoveBetweenResult<T> {
  const newFirst = [...list1.values];
  const newSecond = [...list2.values];

  const moveFrom = source.droppableId === list1.id ? newFirst : newSecond;
  const moveTo = moveFrom === newFirst ? newSecond : newFirst;

  const [moved] = moveFrom.splice(source.index, 1);
  moveTo.splice(destination.index, 0, moved);

  return {
    list1: {
      ...list1,
      values: newFirst,
    },
    list2: {
      ...list2,
      values: newSecond,
    },
  };
}
