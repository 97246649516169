import { useCallback, useContext } from 'react';
import { UserContext } from 'op-contexts/index';
import { ROPatientContext } from 'op-pages/RO/PatientSummary/context';
import { useRouteMatch } from 'react-router-dom';
import axios from 'axios';
import moment from 'moment';
import { useMutation } from '@apollo/client';
import { CareplanCycleAction, UPDATE_CAREPLAN_CYCLE } from '../Queries';

const DownloadCyclePdf = (
  careplanId: number,
  cycleId: number,
  version: number,
  approveDate: string,
  firstName: string,
  lastName: string,
) => {
  const data = async () => {
    const response = await axios.get(`/mo_portal/careplan/${careplanId}/${cycleId}/prescription.pdf`, {
      responseType: 'arraybuffer',
    });
    return response.data;
  };
  data().then((pdfData) => {
    const blob = new Blob([pdfData], { type: 'application/pdf' });
    const url = window.URL.createObjectURL(blob);
    const a = document.createElement('a');
    a.href = url;
    a.download = `${approveDate}_${lastName}_${firstName}_cycle${cycleId + 1}_v${version}.pdf`;
    a.click();
  });
};

const PrintCyclePdf = (careplanId: number, cycleId: number) => {
  const data = async () => {
    const response = await axios.get(`/mo_portal/careplan/${careplanId}/${cycleId}/prescription.pdf`, {
      responseType: 'arraybuffer',
    });
    return response.data;
  };
  data().then((pdfData) => {
    const blob = new Blob([pdfData], { type: 'application/pdf' });
    const url = window.URL.createObjectURL(blob);
    const iframe = document.createElement('iframe');
    iframe.setAttribute('data-cy', `cycle-print-${cycleId}`);
    iframe.style.display = 'none';
    iframe.src = url;
    document.body.appendChild(iframe);
    iframe.contentWindow?.focus();
    iframe.contentWindow?.print();
  });
};

const useCyclePdf = () => {
  const match = useRouteMatch<{ id: string; careplanId: string }>();
  const { careplanId } = match.params;
  const { state: userDetails } = useContext(UserContext);
  const { patientDetails } = useContext(ROPatientContext);
  const [updateCycle] = useMutation(UPDATE_CAREPLAN_CYCLE, { refetchQueries: ['latestCycles'] });

  const savePdfCallback = useCallback(
    (cycleId: number) => {
      updateCycle({
        variables: {
          careplanId: careplanId,
          cycleId: cycleId,
          action: CareplanCycleAction.DOWNLOAD,
        },
      }).then((data: any) => {
        const approveDateString = moment(data.data.updateCareplanCycle.updated.approvedDate)
          .tz(userDetails.timezone)
          .format('L');
        DownloadCyclePdf(
          parseInt(careplanId),
          cycleId,
          data.data.updateCareplanCycle.updated.version + 1,
          approveDateString,
          patientDetails.firstName,
          patientDetails.lastName,
        );
      });
    },
    [careplanId, patientDetails],
  );

  const printPdfCallback = useCallback(
    (cycleId: number) => {
      updateCycle({
        variables: {
          careplanId: careplanId,
          cycleId: cycleId,
          action: CareplanCycleAction.PRINT,
        },
      }).then(() => {
        PrintCyclePdf(parseInt(careplanId), cycleId);
      });
    },
    [careplanId, patientDetails],
  );
  return [savePdfCallback, printPdfCallback];
};

export default useCyclePdf;
