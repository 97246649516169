// eslint-disable-next-line no-use-before-define
import React, { Component, Fragment } from 'react';
import { gql } from '@apollo/client';

// @ts-ignore
import RORegBasic from './RORegBasic';
import { Patient, RORegPatientBasic, UpdatePatientResult } from 'op-interfaces/PatientInterfaces';
import { GraphUpdate, ListData } from 'shared-interfaces/index';
import { OPUser } from 'op-interfaces/index';
import { withApollo, WithApolloClient } from '@apollo/client/react/hoc';
import { Query } from '@apollo/client/react/components';
import withROCreatePatient from '../withROCreatePatient';
import { LoadingSpinner } from 'shared-components/components';
import { ExtendLock } from 'op-components/index';
import { GET_APOLLO_CACHE, WithRegistrationForm } from 'op-pages/OP/RegistrationForm/RegistrationForm';
import { RouteComponentProps } from 'react-router';
import { SavingStatus } from 'shared-components/enums';

const PATIENT_AND_USER_DETAILS_QUERY = gql`
  query PatientAndUserDetails($id: ID!) {
    patient(id: $id) {
      id
      lock {
        lockedBy
        readOnly
        lockedByName
      }
      previousAttendance
      namePrefix
      firstName
      middleName
      lastName
      gender
      dob
      idb
      ida
      idbConflict
      primaryCenter
      primaryPhone
      secondaryPhone
      email
      residentialAddressLine1
      residentialAddressLine2
      residentialAddressCity
      residentialAddressState
      residentialAddressPostcode
      residentialAddressCountry
      otherInformation
      referringSurgeon
      address {
        id
        formattedAddress
        ukFormattedAddress
        country
      }
    }
    ukCounties: listData(category: "ukCounties") {
      id
      name
    }
    genderRefData: listData(category: "gender") {
      id
      name
    }
    nameTitle: listData(category: "nameTitle") {
      id
      name
    }
    locationsRefData: configs(excludeMo: true) {
      id
      name
    }
    countries: listData(category: "countryOfBirth") {
      id
      name
    }
  }
`;

interface PatientAndUserDetailsQueryData {
  patient: RORegPatientBasic;
  nameTitle: ListData[];
  genderRefData: ListData[];
  countries: ListData[];
  ukCounties: ListData[];
  user: OPUser;
  locationsRefData: ListData[];
}

interface State {
  activeTab: string;
  saveStatus: SavingStatus;
  pageViewed: boolean;
}

interface Props
  extends WithApolloClient<{}>,
    WithRegistrationForm,
    RouteComponentProps<{
      id?: string;
    }> {
  isActive?: boolean;
  updatedFields: any[];
  onFieldVisit: (keys: string[]) => void;
}

class RORegBasicApollo extends Component<Props, State> {
  public constructor(props: Props) {
    super(props);
    this.state = {
      activeTab: 'clinicList',
      saveStatus: SavingStatus.SAVED,
      pageViewed: false,
    };
  }

  public render(): JSX.Element {
    const { match, showModalIfLocked } = this.props;
    const { id } = match.params;
    return (
      <Query
        query={PATIENT_AND_USER_DETAILS_QUERY}
        variables={{ id }}
        onCompleted={(data: { patient: Patient }): void => {
          showModalIfLocked(data);
        }}>
        {({
          loading,
          data,
          refetch,
        }: {
          loading: boolean;
          data: PatientAndUserDetailsQueryData;
          refetch: any;
        }): JSX.Element => {
          if (loading) {
            return <LoadingSpinner />;
          }

          if (data && data.patient) {
            return (
              <Fragment>
                <ExtendLock accessPatientId={data.patient.id} />
                <RORegBasic
                  updatedFields={this.props.updatedFields}
                  onFieldVisit={this.props.onFieldVisit}
                  autosave={this.autosave}
                  patient={data.patient}
                  locationsRefData={data.locationsRefData}
                  genderRefData={data.genderRefData}
                  titleReferenceData={data.nameTitle}
                  saveStatus={this.state.saveStatus}
                  validateOnLoad={this.state.pageViewed}
                  forceRefetch={refetch}
                  countries={data.countries}
                  ukCounties={data.ukCounties}
                />
              </Fragment>
            );
          }

          // If we get here there is an error
          return <div>Error</div>;
        }}
      </Query>
    );
  }

  private autosave = async (patient: Patient, updateItems: [GraphUpdate], forceFetch?: object[]): Promise<void> => {
    const patientMutationObj: any = this.props.getPatientMutation(patient, updateItems, forceFetch);
    const { match } = this.props;
    const { id } = match.params;
    patientMutationObj['refetchQueries'] = [
      {
        query: PATIENT_AND_USER_DETAILS_QUERY,
        variables: { id },
        notifyOnNetworkStatusChange: true,
        fetchPolicy: 'cache-and-network',
      },
    ];
    patientMutationObj['awaitRefetchQueries'] = true;
    const client = this.props.client;
    if (!client) return;

    // Get the pending save count and increment it by 1
    const apolloCache = await this.getApolloCache();
    let currentPendingSaveCount = apolloCache.currentPendingSaveCount + 1;
    let saveErrorCount = apolloCache.saveErrorCount;
    client &&
      client.writeQuery({
        query: gql`
          query {
            pendingSaveCount
          }
        `,
        data: {
          pendingSaveCount: currentPendingSaveCount,
        },
      });
    this.setState({ saveStatus: this.props.getSaveStatus(currentPendingSaveCount, saveErrorCount) });

    // Save the updated data. Once done deincrement the pending save count. If any erros saving increment the saveErrorCount
    client
      .mutate(patientMutationObj)
      //@ts-ignore
      .then((result: { data: UpdatePatientResult }): void => {
        if (result.data.updatePatient.errors) {
          saveErrorCount++;
        }
      })
      .catch((): void => {
        this.props.showSavingErrorModal(false, this.props.history.push);
        saveErrorCount++;
      })
      .finally(async (): Promise<void> => {
        // Reload the save count incase it was set elsewhere during the run of this function
        const apolloCache = await this.getApolloCache();
        currentPendingSaveCount = apolloCache.currentPendingSaveCount - 1;
        client &&
          client.writeQuery({
            query: gql`
              query {
                pendingSaveCount
                saveErrorCount
              }
            `,
            data: {
              pendingSaveCount: currentPendingSaveCount,
              saveErrorCount: saveErrorCount,
            },
          });
        this.setState({ saveStatus: this.props.getSaveStatus(currentPendingSaveCount, saveErrorCount) });
      });
  };

  private getApolloCache = async (): Promise<{
    currentPendingSaveCount: number;
    saveErrorCount: number;
    registrationPagesViewed: string[];
  }> => {
    const { client } = this.props;
    if (!client) {
      throw new Error('Client not found');
    }
    try {
      const apolloCache = await client.query({ query: GET_APOLLO_CACHE });
      const currentPendingSaveCount = apolloCache.data.pendingSaveCount;
      const saveErrorCount = apolloCache.data.saveErrorCount;
      const registrationPagesViewed = apolloCache.data.registrationPagesViewed;
      return {
        currentPendingSaveCount: currentPendingSaveCount,
        saveErrorCount: saveErrorCount,
        registrationPagesViewed: registrationPagesViewed,
      };
    } catch (error) {
      throw error;
    }
  };
}

const apolloComponent = withApollo<Props>(RORegBasicApollo);
export default withROCreatePatient(apolloComponent);
