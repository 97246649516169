import { NavItemType } from 'op-pages/PX/models';
import { Calendar, MyDetails } from 'shared-components/images';
import { DescriptionOutlined as DescriptionOutlinedIcon } from '@mui/icons-material';

export const patientDashboardUrl = '/px/appointments';

export const pxNavList: NavItemType[] = [
  {
    title: 'Appointments',
    url: '/px/appointments',
    icon: <Calendar data-testid="opx-appointments-nav-icon" />,
    dataTestId: 'opx-appointments-nav',
  },
  {
    title: 'Information',
    url: '/px/information',
    icon: <DescriptionOutlinedIcon color="primary" data-testid="opx-information-nav-icon" />,
    dataTestId: 'opx-information-nav',
  },
  {
    title: 'My details',
    url: '/px/details',
    icon: <MyDetails data-testid="opx-mydetails-nav-icon" />,
    dataTestId: 'opx-mydetails-nav',
  },
];

export const pxTitles: string[] = pxNavList.map((pxNav) => pxNav.title);
export const pxUrls: string[] = pxNavList.map((pxNav) => pxNav.url);
export const pxIcons: JSX.Element[] = pxNavList.map((pxNav) => pxNav.icon);

export type PxUrlTypes = typeof pxUrls[number];

/**
 * Find Title given an OPX Url
 * @param url an opx url
 * @returns page title for the url
 */
export const findTitleFromUrl = (url: PxUrlTypes): string => {
  const otherPxPages: Partial<NavItemType>[] = [
    { title: 'Feedback', url: '/px/feedback' },
    { title: 'FAQs', url: '/px/information/faq' },
    { title: 'Change password', url: '/px/changePassword' },
  ];

  return (
    [...otherPxPages, ...pxNavList].find((pxNav: Partial<NavItemType>) => url.indexOf(pxNav?.url ?? '') === 0)?.title ??
    ''
  );
};
