import { ListData, SerializedListDataObject } from 'shared-components/interfaces';

export const convertListDataToObject = (listData?: ListData[], key = 'id'): SerializedListDataObject | {} => {
  const initialValue: ListData | {} = {};

  if (!listData) return {};

  return listData.reduce((obj, item) => {
    return item[key as keyof typeof item]
      ? {
          ...obj,
          [item[key as keyof typeof item] as string]: item.name,
        }
      : obj;
  }, initialValue);
};
