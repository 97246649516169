import { getWeekNumber, sortFunctions } from './utils';

// AGGREGATE FUNCTIONS
const aggregateAverageFractions = (data: any, aggregateBy: 'days' | 'weeks' | 'months') => {
  const aggregated = data.reduce((acc: any, { date, fractions, fraction_dose }: any) => {
    const dateObj = new Date(date);
    const dateKey =
      aggregateBy === 'days'
        ? date
        : aggregateBy === 'weeks'
        ? `week_${getWeekNumber(dateObj)}`
        : dateObj.toLocaleString('default', { month: 'long', year: 'numeric' });

    if (!acc[dateKey]) acc[dateKey] = { totalFractions: 0, totalFractionDose: 0, count: 0 };
    acc[dateKey].totalFractions += fractions;
    acc[dateKey].count += 1;
    acc[dateKey].totalFractionDose += fraction_dose;

    return acc;
  }, {});

  return Object.keys(aggregated)
    .sort(sortFunctions[aggregateBy])
    .map((date) => [
      date,
      aggregated[date].totalFractions / aggregated[date].count,
      aggregated[date].totalFractionDose / aggregated[date].count,
    ]);
};

const aggregateAverageSimToTreat = (data: any, aggregateBy: 'days' | 'weeks' | 'months') => {
  const aggregated = data.reduce((acc: any, { date, sim_to_treat }: any) => {
    const dateObj = new Date(date);
    const dateKey =
      aggregateBy === 'days'
        ? date
        : aggregateBy === 'weeks'
        ? `week_${getWeekNumber(dateObj)}`
        : dateObj.toLocaleString('default', { month: 'long', year: 'numeric' });

    if (!acc[dateKey]) acc[dateKey] = { sim_to_treat: 0, count: 0 };
    acc[dateKey].sim_to_treat += sim_to_treat;
    acc[dateKey].count += 1;

    return acc;
  }, {});

  return Object.keys(aggregated)
    .sort(sortFunctions[aggregateBy])
    .map((date) => [date, aggregated[date].sim_to_treat / aggregated[date].count, 5]);
};

const aggregateStackedBarData = (
  data: any,
  field: string,
  aggregateBy: 'days' | 'weeks' | 'months',
  extraField?: string,
  sortOrder?: string[],
) => {
  const aggregated = data.reduce((acc: any, { date, [field]: fieldValue }: any) => {
    const dateObj = new Date(date);
    const dateKey =
      aggregateBy === 'days'
        ? date
        : aggregateBy === 'weeks'
        ? `week_${getWeekNumber(dateObj)}`
        : dateObj.toLocaleString('default', { month: 'long', year: 'numeric' });

    if (!acc[dateKey]) acc[dateKey] = { date: dateKey, total: 0, extraTotal: 0 };
    if (!acc[dateKey][fieldValue]) acc[dateKey][fieldValue] = 0;

    acc[dateKey][fieldValue] += 1;
    acc[dateKey].total += 1;

    if ((extraField && fieldValue === 'IMRT') || fieldValue === 'VMAT' || fieldValue === 'Stereotactic') {
      acc[dateKey].extraTotal += 1;
    }

    return acc;
  }, {});

  const uniqueFieldValues = Array.from(new Set(data.map((d: { [key: string]: any }) => d[field]))) as string[];

  const sortedFieldValues = sortOrder
    ? uniqueFieldValues.sort((a, b) => sortOrder.indexOf(a) - sortOrder.indexOf(b))
    : uniqueFieldValues;

  const columnNames = ['Date', ...sortedFieldValues.map((value) => value.charAt(0).toUpperCase() + value.slice(1))];
  if (extraField) columnNames.push(extraField);

  const rows = Object.keys(aggregated)
    .sort(sortFunctions[aggregateBy])
    .map((dateKey) => {
      const row = [dateKey, ...sortedFieldValues.map((value: unknown) => aggregated[dateKey][value as string] || 0)];
      if (extraField) row.push((aggregated[dateKey].extraTotal / aggregated[dateKey].total) * 100);
      return row;
    });

  return [columnNames, ...rows];
};

const aggregateTechniquePercentage = (data: any[], aggregateBy: 'days' | 'weeks' | 'months') => {
  const targetTechniques = ['IMRT', 'VMAT', 'Stereotactic'];

  const aggregated = data.reduce((acc: any, { date, technique }: any) => {
    const dateObj = new Date(date);
    const dateKey =
      aggregateBy === 'days'
        ? date
        : aggregateBy === 'weeks'
        ? `week_${getWeekNumber(dateObj)}`
        : dateObj.toLocaleString('default', { month: 'long', year: 'numeric' });

    if (!acc[dateKey]) acc[dateKey] = { totalPlans: 0, matchingTechniques: 0 };
    acc[dateKey].totalPlans += 1;
    if (targetTechniques.includes(technique)) {
      acc[dateKey].matchingTechniques += 1;
    }
    return acc;
  }, {});

  return Object.keys(aggregated)
    .sort(sortFunctions[aggregateBy])
    .map((date) => [date, (aggregated[date].matchingTechniques / aggregated[date].totalPlans) * 100]);
};

const aggregateMonth = (data: any) => {
  const diagnosisTypes: string[] = [...new Set((data as { diagnosis: string }[]).map((item) => item.diagnosis))];
  const monthlyData = data.reduce((acc: any, { date, diagnosis }: any) => {
    const month = date.slice(0, 7);
    if (!acc[month])
      acc[month] = diagnosisTypes.reduce((typeAcc: any, type: string) => ({ ...typeAcc, [type]: 0 }), {});
    acc[month][diagnosis] += 1;
    return acc;
  }, {});

  return [
    ['Date', ...diagnosisTypes.map((type) => type.charAt(0).toUpperCase() + type.slice(1))],
    ...Object.keys(monthlyData)
      .sort((a, b) => new Date(a + '-01').getTime() - new Date(b + '-01').getTime())
      .map((month) => [month, ...diagnosisTypes.map((type) => monthlyData[month][type])]),
  ];
};

const aggregateAdherencePercentage = (data: any[], aggregateBy: 'days' | 'weeks' | 'months') => {
  const aggregated = data.reduce((acc: any, { date, adherence, fraction_dose }: any) => {
    const dateObj = new Date(date);
    const dateKey =
      aggregateBy === 'days'
        ? date
        : aggregateBy === 'weeks'
        ? `week_${getWeekNumber(dateObj)}`
        : dateObj.toLocaleString('default', { month: 'long', year: 'numeric' });

    if (!acc[dateKey]) acc[dateKey] = { totalRecords: 0, adherentRecords: 0, peerReviewRecords: 0 };
    acc[dateKey].totalRecords += 1;
    if (adherence === true) {
      acc[dateKey].adherentRecords += 1;
    }

    if (fraction_dose > 2.5) {
      acc[dateKey].peerReviewRecords += 1;
    }
    return acc;
  }, {});

  return Object.keys(aggregated)
    .sort(sortFunctions[aggregateBy])
    .map((date) => [
      date,
      (aggregated[date].adherentRecords / aggregated[date].totalRecords) * 100,
      aggregated[date].totalRecords - aggregated[date].adherentRecords,
      aggregated[date].peerReviewRecords,
    ]);
};

const aggregateAdherenceValues = (data: any[], aggregateBy: 'days' | 'weeks' | 'months') => {
  const aggregated = data.reduce((acc: any, { date, adherence, fraction_dose }: any) => {
    const dateObj = new Date(date);
    const dateKey =
      aggregateBy === 'days'
        ? date
        : aggregateBy === 'weeks'
        ? `week_${getWeekNumber(dateObj)}`
        : dateObj.toLocaleString('default', { month: 'long', year: 'numeric' });

    if (!acc[dateKey]) acc[dateKey] = { totalRecords: 0, adherentRecords: 0, peerReviewRecords: 0 };
    acc[dateKey].totalRecords += 1;
    if (adherence === true) {
      acc[dateKey].adherentRecords += 1;
    }

    if (fraction_dose > 2.5) {
      acc[dateKey].peerReviewRecords += 1;
    }
    return acc;
  }, {});

  return Object.keys(aggregated)
    .sort(sortFunctions[aggregateBy])
    .map((date) => [
      date,
      aggregated[date].adherentRecords,
      aggregated[date].totalRecords - aggregated[date].adherentRecords,
      aggregated[date].peerReviewRecords,
    ]);
};

const aggregateAdherenceByField = (
  data: { diagnosis: string; adherence: boolean }[],
  field: string,
  limit?: number,
): [string, number, number][] => {
  const aggregated = data.reduce<
    Record<string, { totalRecords: number; adherentRecords: number; nonAdherentRecords: number }>
  >((acc, record: { [key: string]: any }) => {
    const key = record[field];
    if (!acc[key]) {
      acc[key] = { totalRecords: 0, adherentRecords: 0, nonAdherentRecords: 0 };
    }
    acc[key].totalRecords += 1;
    if (record.adherence === true) {
      acc[key].adherentRecords += 1;
    }
    if (record.adherence === false) {
      acc[key].nonAdherentRecords += 1;
    }
    return acc;
  }, {});

  const sortedTypes = Object.entries(aggregated).sort((a, b) => b[1].adherentRecords - a[1].adherentRecords);
  const limitedTypes = limit ? sortedTypes.slice(0, limit) : sortedTypes;

  return limitedTypes.map(([key, stats]): [string, number, number] => [
    key.charAt(0).toUpperCase() + key.slice(1),
    stats.adherentRecords,
    stats.totalRecords - stats.adherentRecords,
  ]);
};

const aggregateTechniqueByField = (
  data: Record<string, any>[],
  field: string,
  limit?: number,
  sortOrder?: string[],
): (string | number)[][] => {
  const aggregated = data.reduce<Record<string, Record<string, number>>>((acc, record) => {
    const key = record[field];
    const technique = record.technique;

    if (!acc[key]) {
      acc[key] = {};
    }

    if (!acc[key][technique]) {
      acc[key][technique] = 0;
    }

    acc[key][technique] += 1;

    return acc;
  }, {});

  const allTechniques = Array.from(new Set(Object.values(aggregated).flatMap((techniques) => Object.keys(techniques))));

  const sortedTechniques = sortOrder
    ? allTechniques.sort((a, b) => sortOrder.indexOf(a) - sortOrder.indexOf(b))
    : allTechniques;

  const headerRow = [field.charAt(0).toUpperCase() + field.slice(1), ...sortedTechniques];

  const sortedTypes = Object.entries(aggregated).sort(
    (a, b) =>
      Object.values(b[1]).reduce((sum, count) => sum + count, 0) -
      Object.values(a[1]).reduce((sum, count) => sum + count, 0),
  );
  const limitedTypes = limit ? sortedTypes.slice(0, limit) : sortedTypes;

  const dataRows = limitedTypes.map(([key, techniqueCounts]) => {
    const row: Array<string | number> = [key.charAt(0).toUpperCase() + key.slice(1)];
    sortedTechniques.forEach((technique) => {
      row.push(techniqueCounts[technique] || 0);
    });
    return row;
  });

  return [headerRow, ...dataRows];
};

const aggregateTotalReferrals = (data: any[], aggregateBy: 'days' | 'weeks' | 'months') => {
  const aggregated = data.reduce((acc: any, { date }: any) => {
    const dateObj = new Date(date);
    const dateKey =
      aggregateBy === 'days'
        ? date
        : aggregateBy === 'weeks'
        ? `week_${getWeekNumber(dateObj)}`
        : dateObj.toLocaleString('default', { month: 'long', year: 'numeric' });

    if (!acc[dateKey]) acc[dateKey] = 0;
    acc[dateKey] += 1;
    return acc;
  }, {});

  return Object.keys(aggregated)
    .sort(sortFunctions[aggregateBy])
    .map((date) => [date, aggregated[date]]);
};

const aggregateTotalDiagnosis = (data: any[], aggregateBy: 'days' | 'weeks' | 'months') => {
  const aggregated = data.reduce((acc: any, { date, diagnosis }: any) => {
    const dateObj = new Date(date);
    const dateKey =
      aggregateBy === 'days'
        ? date
        : aggregateBy === 'weeks'
        ? `week_${getWeekNumber(dateObj)}`
        : dateObj.toLocaleString('default', { month: 'long', year: 'numeric' });

    if (!acc[dateKey]) acc[dateKey] = 0;

    if (diagnosis !== 'Unspecified') {
      acc[dateKey] += 1;
    }

    return acc;
  }, {});

  return Object.keys(aggregated)
    .sort(sortFunctions[aggregateBy])
    .map((date) => [date, aggregated[date]]);
};

const aggregateTotalPlans = (data: any[], aggregateBy: 'days' | 'weeks' | 'months') => {
  const aggregated = data.reduce((acc: any, { date, technique, fractions }: any) => {
    const dateObj = new Date(date);
    const dateKey =
      aggregateBy === 'days'
        ? date
        : aggregateBy === 'weeks'
        ? `week_${getWeekNumber(dateObj)}`
        : dateObj.toLocaleString('default', { month: 'long', year: 'numeric' });

    if (!acc[dateKey]) acc[dateKey] = 0;

    if (!acc[dateKey]) acc[dateKey] = { totalPlans: 0, totalFractions: 0 };
    acc[dateKey].totalFractions += fractions;

    if (technique !== 'Unspecified') {
      acc[dateKey].totalPlans += 1;
    }

    return acc;
  }, {});

  return Object.keys(aggregated)
    .sort(sortFunctions[aggregateBy])
    .map((date) => [date, aggregated[date].totalPlans, aggregated[date].totalFractions]);
};

const aggregateData = (data: any[], field: string, limit?: number, seriesName?: string, divider: number = 1) => {
  const aggregatedData = data.reduce((acc: any, item: any) => {
    const value = item[field];
    if (!acc[value]) acc[value] = 0;
    acc[value] += 1;
    return acc;
  }, {});

  const sortedTypes = Object.keys(aggregatedData).sort((a, b) => aggregatedData[b] - aggregatedData[a]);
  const limitedTypes = limit ? sortedTypes.slice(0, limit) : sortedTypes;

  return [
    [field.charAt(0).toUpperCase() + field.slice(1), seriesName ? seriesName : 'Count'],
    ...limitedTypes.map((type) => [type.charAt(0).toUpperCase() + type.slice(1), aggregatedData[type] / divider]),
  ];
};

const aggregatePhysicianFractions = (data: any, limit?: number) => {
  const physicianData = data.reduce((acc: any, { physician, fractions, fraction_dose }: any) => {
    if (!acc[physician]) {
      acc[physician] = { totalFractions: 0, totalFractionDose: 0, count: 0 };
    }
    acc[physician].totalFractions += fractions;
    acc[physician].totalFractionDose += fraction_dose;
    acc[physician].count += 1;
    return acc;
  }, {});

  const physicianAverages = Object.entries(physicianData).map(([physician, data]: [string, any]) => ({
    physician,
    avgFractions: data.totalFractions / data.count,
    avgFractionDose: data.totalFractionDose / data.count,
  }));

  const sortedPhysicianAverages = physicianAverages.sort((a, b) => b.avgFractions - a.avgFractions);
  const limitedPhysicianAverages = limit ? sortedPhysicianAverages.slice(0, limit) : sortedPhysicianAverages;

  return [
    [' ', 'Average fractions (#)', 'Average dose/fraction (Gy)'],
    ...limitedPhysicianAverages.map(({ physician, avgFractions, avgFractionDose }) => [
      physician,
      Number(avgFractions.toFixed(1)),
      Number(avgFractionDose.toFixed(1)),
    ]),
  ];
};

const aggregateAverageDailyRecords = (data: any[], aggregateBy: 'days' | 'weeks' | 'months') => {
  const isWeekday = (date: Date) => {
    const day = date.getDay();
    return day !== 0 && day !== 6;
  };

  const aggregated = data.reduce((acc: any, { date, adherence }: any) => {
    const dateObj = new Date(date);
    if (!isWeekday(dateObj)) return acc;

    const dateKey =
      aggregateBy === 'days'
        ? date
        : aggregateBy === 'weeks'
        ? `week_${getWeekNumber(dateObj)}`
        : dateObj.toLocaleString('default', { month: 'long', year: 'numeric' });

    if (!acc[dateKey]) acc[dateKey] = { count: 0, automationCount: 0, weekdays: new Set() };

    acc[dateKey].count += 1;
    acc[dateKey].weekdays.add(date);

    // TODO change to automation instead of adherance
    if (adherence === true) {
      acc[dateKey].automationCount += 1;
    }

    return acc;
  }, {});

  return Object.keys(aggregated)
    .sort(sortFunctions[aggregateBy])
    .map((date) => [date, aggregated[date].count / aggregated[date].weekdays.size, aggregated[date].automationCount]);
};

const aggregateAverageDailyTreatmentsPerLinac = (data: any[], aggregateBy: 'days' | 'weeks' | 'months') => {
  const isWeekday = (date: Date) => {
    const day = date.getDay();
    return day !== 0 && day !== 6;
  };

  const aggregated = data.reduce((acc: any, { date, fractions }: any) => {
    const dateObj = new Date(date);
    if (!isWeekday(dateObj)) return acc;

    const dateKey =
      aggregateBy === 'days'
        ? date
        : aggregateBy === 'weeks'
        ? `week_${getWeekNumber(dateObj)}`
        : dateObj.toLocaleString('default', { month: 'long', year: 'numeric' });

    if (!acc[dateKey]) acc[dateKey] = { count: 0, dailyTreatments: 0, weekdays: new Set() };

    acc[dateKey].count += 1;
    acc[dateKey].weekdays.add(date);

    acc[dateKey].dailyTreatments += fractions;

    return acc;
  }, {});

  return Object.keys(aggregated)
    .sort(sortFunctions[aggregateBy])
    .map((date) => [
      date,
      aggregated[date].dailyTreatments / aggregated[date].weekdays.size / 4,
      aggregated[date].dailyTreatments / aggregated[date].weekdays.size / 4 - 3,
    ]);
};

// METRICS FUNCTIONS
const calcAverageFractions = (data: any[]): number => {
  if (data.length === 0) {
    return 0;
  }
  const totalFractions = data.reduce((sum, item) => sum + item.fractions, 0);

  return totalFractions / data.length;
};

const calcAverageSimToTreat = (data: any[]): number => {
  if (data.length === 0) {
    return 0;
  }
  const totalSimToTreat = data.reduce((sum, item) => sum + item.sim_to_treat, 0);

  return totalSimToTreat / data.length;
};

const calcComplexTechnique = (data: any[]): number => {
  const targetTechniques = ['IMRT', 'VMAT', 'Stereotactic'];
  const matchingRecords = data.filter((record) => targetTechniques.includes(record.technique));
  const percentage = (matchingRecords.length / data.length) * 100;

  return percentage;
};

const calcAdherencePercentage = (data: any[]): number => {
  const adherentRecords = data.filter((record) => record.adherence === true);
  const percentage = (adherentRecords.length / data.length) * 100;

  return percentage;
};

const calcTotalReferrals = (data: any[]): number => {
  return data.length;
};

const calcTotalDiagnosis = (data: any[]): number => {
  const diagnosisRecords = data.filter((record) => record.diagnosis !== 'Unspecified');
  return diagnosisRecords.length;
};

const calcTotalPlans = (data: any[]): number => {
  const techniqueRecords = data.filter((record) => record.technique !== 'Unspecified');
  return techniqueRecords.length;
};

const calcAverageDailyPlans = (data: any[]): number => {
  const isWeekday = (date: Date) => {
    const day = date.getDay();
    return day !== 0 && day !== 6;
  };

  const weekdays = new Set();
  let totalRecords = 0;

  data.forEach(({ date }: any) => {
    const dateObj = new Date(date);
    if (isWeekday(dateObj)) {
      totalRecords += 1;
      weekdays.add(date);
    }
  });

  const totalWeekdays = weekdays.size;
  return totalWeekdays > 0 ? totalRecords / totalWeekdays : 0;
};

const calcAverageDailyTreatmentsPerLinac = (data: any[]) => {
  const isWeekday = (date: Date) => {
    const day = date.getDay();
    return day !== 0 && day !== 6;
  };

  const aggregated = data.reduce(
    (acc: any, { date, fractions }: any) => {
      const dateObj = new Date(date);
      if (!isWeekday(dateObj)) return acc;

      acc.totalTreatments += fractions;
      acc.weekdays.add(date);
      return acc;
    },
    { totalTreatments: 0, weekdays: new Set() },
  );

  const totalWeekdays = aggregated.weekdays.size;
  const averageDailyTreatments = totalWeekdays > 0 ? aggregated.totalTreatments / totalWeekdays / 4 : 0;

  return averageDailyTreatments;
};

export {
  aggregateAverageFractions,
  aggregateMonth,
  calcAverageFractions,
  calcComplexTechnique,
  aggregateTechniquePercentage,
  aggregateAdherencePercentage,
  calcAdherencePercentage,
  aggregateTotalReferrals,
  calcTotalReferrals,
  aggregateAverageDailyTreatmentsPerLinac,
  aggregateAverageSimToTreat,
  calcAverageSimToTreat,
  aggregateAverageDailyRecords,
  calcAverageDailyPlans,
  aggregateTotalDiagnosis,
  calcTotalDiagnosis,
  aggregateTotalPlans,
  calcTotalPlans,
  aggregatePhysicianFractions,
  aggregateData,
  aggregateStackedBarData,
  aggregateAdherenceByField,
  aggregateTechniqueByField,
  calcAverageDailyTreatmentsPerLinac,
  aggregateAdherenceValues,
};
