import { TextAreaField, ToggleButtonGroupField } from 'shared-components/components/FormikComponents';

export const FIELDS = {
  FORM_HEADING: {
    NAME: 'formHeading',
    TITLE: 'Referrers',
  },
  PRIMARY_PROVIDER: {
    NAME: 'primaryProvider',
    TITLE: 'Primary provider',
    PLACE_HOLDER: 'Enter primary provider name',
    COMPONENT: TextAreaField,
  },
  REFERRING_PROVIDER: {
    NAME: 'referringProvider',
    TITLE: 'Referring provider',
    PLACE_HOLDER: 'Enter referring provider name',
    COMPONENT: TextAreaField,
  },
  ADVANCED_DIRECTIVE: {
    NAME: 'advancedDirective',
    TITLE: 'Do you have an Advanced Directive?',
    COMPONENT: ToggleButtonGroupField,
  },
  POWER_OF_ATTORNEY: {
    NAME: 'powerOfAttorney',
    TITLE: 'Do you have a medical Durable Power of Attorney?',
    COMPONENT: ToggleButtonGroupField,
  },
  LIVING_WILL: {
    NAME: 'livingWill',
    TITLE: 'Do you have a Living Will?',
    COMPONENT: ToggleButtonGroupField,
  },
};
