import { FormStatus } from 'op-enums';
import { Gender, Region } from 'shared-components/enums';
import { isUs } from 'op-utils';
import { formatPhoneNumber, Value } from 'react-phone-number-input';
import { PatientAttachment } from 'op-interfaces';
import stockImageF from 'shared-components/images/stockImageF.png';
import stockImageM from 'shared-components/images/stockImageM.png';
/**
 * Get a friendly name for the form status
 * @param status The listdata form status
 * @param mode If the view mode is from a pso or patient
 * @returns Human friendly form status name
 */

export const getFormStatus = (status: string, mode: string): string => {
  let formStatus = '';
  switch (status) {
    case FormStatus.REG_SUBMITTED:
      if (mode === 'patient') {
        formStatus = 'Completed';
        break;
      }
      formStatus = isUs() ? 'Submitted' : 'Submitted to MQ';
      break;
    case FormStatus.REG_REVIEW_REQUIRED:
      if (mode === 'patient') {
        formStatus = 'Completed';
        break;
      }
      formStatus = 'Review required';
      break;
    case FormStatus.IN_PROGRESS:
      formStatus = 'In progress';
      break;
    case FormStatus.IN_PROGRESS_AMENDED:
      formStatus = 'In progress (Amendment)';
      break;
    case FormStatus.CREATED:
      formStatus = 'Not started';
      break;
    default:
      if (mode === 'patient') {
        formStatus = 'Not started';
        break;
      }
      formStatus = '';
      break;
  }
  return formStatus;
};

export const patientGenderStockImage = (gender: number | string) => {
  if (gender === Gender.MALE || gender == 1) {
    return stockImageM;
  }
  if (gender === Gender.FEMALE || gender == 2) {
    return stockImageF;
  }
  return false;
};

export const genderTitle = (gender: number | string) =>
  gender === Gender.MALE ? '(M)' : gender === Gender.FEMALE ? '(F)' : '';

export const formatMyPhoneNumber = (phoneNumber: string, region: string): string => {
  const countryCode = {
    [Region.AU]: '+61',
    [Region.UK]: '+44',
    [Region.US]: '+1',
  };
  if (!phoneNumber.startsWith(countryCode[region as keyof typeof countryCode])) {
    phoneNumber = countryCode[region as keyof typeof countryCode].concat(phoneNumber);
  }
  return formatPhoneNumber(phoneNumber as Value);
};

export const registrationAttachments = (attachments: PatientAttachment[]): PatientAttachment[] =>
  attachments?.filter((attachment: PatientAttachment) => attachment.isRegistrationAttachment);
