import { gql } from '@apollo/client';

export const ALL_MANAGEMENT_PLAN_QUERY = gql`
  query allManagementPlan($patientId: ID!) {
    allManagementPlan(patientId: $patientId) {
      id
      updatedAt
      timepoint
      planType
      submittedBy
      submittedAt
      archivedAt
      status
      isReview
      reviewComments
    }
    timepointOptions: listData(category: "timepoint") {
      id
      name
    }
    scoreOptions: listData(category: "distressScore") {
      id
      name
    }
    serviceTypeOptions: listData(category: "serviceType") {
      id
      name
    }
    checkInStatusOptions: listData(category: "checkInStatus") {
      id
      name
    }
  }
`;

export const SUBMITTED_MANAGEMENT_PLAN_QUERY = gql`
  query submittedManagementPlan($patientId: ID!) {
    submittedManagementPlan(patientId: $patientId) {
      id
      updatedAt
      timepoint
      planType
      submittedBy
      submittedAt
      archivedAt
      status
      isReview
      activityLog {
        sections {
          title
          field
          content
        }
        goalOfCare {
          id
          goal
        }
      }
    }
  }
`;

export const MANAGEMENT_PLAN_QUERY = gql`
  query ManagementPlan($id: ID!) {
    managementPlan(id: $id) {
      id
      status
      planType
      timepoint
      reviewedDt
      distressScore
      distressScorePdf
      checkInStatus
      checkInNotes
      reviewedEq5d5l
      patientConcernsNeeds
      goalOfCare {
        id
        goal
      }
      planOfCare
      resourcesProvided
      reasonForDeactivation
      submittedAt
      reviewComments
      isReview
      reviewComments
    }
  }
`;

export const UPDATE_MANAGEMENT_PLAN = gql`
  mutation updateManagementPlan(
    $id: ID!
    $timepoint: String
    $reviewedDt: Boolean
    $distressScore: Int
    $checkInStatus: String
    $checkInNotes: String
    $reviewedEq5d5l: Boolean
    $patientConcernsNeeds: String
    $goalOfCare: [GoalOfCareInputType]
    $planOfCare: String
    $resourcesProvided: String
    $reasonForDeactivation: String
    $reviewComments: String
  ) {
    updateManagementPlan(
      id: $id
      timepoint: $timepoint
      reviewedDt: $reviewedDt
      distressScore: $distressScore
      checkInStatus: $checkInStatus
      checkInNotes: $checkInNotes
      reviewedEq5d5l: $reviewedEq5d5l
      patientConcernsNeeds: $patientConcernsNeeds
      goalOfCare: $goalOfCare
      planOfCare: $planOfCare
      resourcesProvided: $resourcesProvided
      reasonForDeactivation: $reasonForDeactivation
      reviewComments: $reviewComments
    ) {
      managementPlan {
        id
        status
        planType
        timepoint
        reviewedDt
        distressScore
        checkInStatus
        checkInNotes
        reviewedEq5d5l
        patientConcernsNeeds
        goalOfCare {
          id
          goal
        }
        planOfCare
        resourcesProvided
        reasonForDeactivation
        reviewComments
      }
    }
  }
`;

export const CREATE_MANAGEMENT_PLAN = gql`
  mutation createManagementPlan($patientId: ID!, $planType: String!) {
    createManagementPlan(patientId: $patientId, planType: $planType) {
      managementPlan {
        id
      }
    }
  }
`;

export const SUBMIT_MANAGEMENT_PLAN = gql`
  mutation submitManagementPlan($formId: ID!) {
    submitManagementPlan(formId: $formId) {
      success
    }
  }
`;

export const DELETE_MANAGEMENT_PLAN = gql`
  mutation deleteManagementPlan($formId: ID!) {
    deleteManagementPlan(formId: $formId) {
      success
    }
  }
`;

export const DUPLICATE_MANAGEMENT_PLAN = gql`
  mutation duplicateManagementPlan($formId: ID!) {
    duplicateManagementPlan(formId: $formId) {
      managementPlan {
        id
      }
    }
  }
`;

export const ARCHIVE_MANAGEMENT_PLAN = gql`
  mutation archiveManagementPlan($formId: ID!) {
    archiveManagementPlan(formId: $formId) {
      managementPlan {
        id
      }
    }
  }
`;

export const CREATE_GOAL_OF_CARE = gql`
  mutation createGoalOfCare($formId: ID!) {
    createGoalOfCare(formId: $formId) {
      success
    }
  }
`;

export const DELETE_GOAL_OF_CARE = gql`
  mutation deleteGoalOfCare($formId: ID!, $goalId: ID!) {
    deleteGoalOfCare(formId: $formId, goalId: $goalId) {
      success
    }
  }
`;

export const UPDATE_GOAL_OF_CARE = gql`
  mutation updateGoalOfCare($formId: ID!, $goalId: ID!, $goal: String!) {
    updateGoalOfCare(formId: $formId, goalId: $goalId, goal: $goal) {
      managementPlan {
        id
        goalOfCare {
          id
          goal
        }
      }
    }
  }
`;

export const CREATE_REFERRAL_EXISTING_SERVICE = gql`
  mutation createReferralExistingService($patientId: ID!) {
    createReferralExistingService(patientId: $patientId) {
      referralExistingService {
        id
      }
    }
  }
`;

export const ALL_REFERRAL_EXISTING_SERVICE_QUERY = gql`
  query allReferralExistingServices($patientId: ID!) {
    allReferralExistingServices(patientId: $patientId) {
      id
      referral {
        id
        name
        serviceType
        description
        contactDetails
      }
    }
  }
`;

export const REFERRAL_EXISTING_SERVICE_QUERY = gql`
  query referralExistingServices($formId: ID!) {
    referralExistingServices(id: $formId) {
      id
      referral {
        id
        name
        serviceType
        description
        contactDetails
      }
    }
  }
`;

export const CREATE_REFERRAL = gql`
  mutation createReferral($referralExistingServiceId: ID!) {
    createReferral(referralExistingServiceId: $referralExistingServiceId) {
      referral {
        id
        name
        serviceType
        description
        contactDetails
      }
    }
  }
`;

export const DELETE_REFERRAL = gql`
  mutation deleteReferral($referralId: ID!) {
    deleteReferral(referralId: $referralId) {
      success
    }
  }
`;

export const DELETE_REFERRAL_EXISTING_SERVICE = gql`
  mutation deleteReferralExistingService($referralExistingServiceId: ID!) {
    deleteReferralExistingService(referralExistingServiceId: $referralExistingServiceId) {
      success
    }
  }
`;

export const UPDATE_REFERRAL = gql`
  mutation updateReferral(
    $referralId: ID!
    $name: String
    $serviceType: String
    $description: String
    $contactDetails: String
  ) {
    updateReferral(
      referralId: $referralId
      name: $name
      serviceType: $serviceType
      description: $description
      contactDetails: $contactDetails
    ) {
      referral {
        id
        name
        serviceType
        description
        contactDetails
      }
    }
  }
`;

export const SUBMIT_REFERRAL_EXISTING_SERVICE = gql`
  mutation submitReferralExistingService($referralExistingServiceId: ID!) {
    submitReferralExistingService(referralExistingServiceId: $referralExistingServiceId) {
      referralExistingService {
        id
      }
    }
  }
`;
