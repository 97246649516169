// eslint-disable-next-line no-use-before-define
import { Component } from 'react';

import validate from 'validate.js';

import lodash from 'lodash';
import { Patient, PatientContactDetails } from 'op-interfaces';
import { Region, SavingStatus } from 'shared-components/enums';
import { ListData } from 'shared-components/interfaces';
import { EMAIL_REGEX, PHONE_NUMBER_REGEX, UK_PHONE_REGEX } from 'shared-components/utils';

import { RegistrationContainer } from 'op-components';
import { Checkbox, FreeTextField, SectionField } from 'shared-components/components/FormFields';
import { Info } from 'shared-components/images';
import { applyRequired } from '../utils';
import './RegistrationContactDetails.scss';
const REACT_APP_REGION = import.meta.env.REACT_APP_REGION;

// Constants
const FORM_HEADING = 'Contact details';
const region = REACT_APP_REGION;

interface FieldInfo {
  [key: string]: FieldInfoItem;
}

interface FieldInfoItem {
  TITLE: string;
  PLACEHOLDER: string;
  KEY: string;
  NAME: string;
  EMPTY?: string;
  INVALID?: string;
}

const FIELD_INFO: FieldInfo = {
  PRIMARY: {
    TITLE: 'Mobile phone number',
    PLACEHOLDER: 'Enter mobile',
    KEY: 'primaryPhone',
    NAME: 'primaryPhone',
    EMPTY: 'Please enter your home or mobile phone number.',
    INVALID: 'Please enter a valid mobile number.',
  },
  SECONDARY: {
    TITLE: 'Landline phone number',
    PLACEHOLDER: 'Please include area code e.g. 02 8236 3300',
    KEY: 'secondaryPhone',
    NAME: 'secondaryPhone',
    EMPTY: 'Please enter your home or mobile phone number.',
    INVALID: 'Please enter a valid home number.',
  },
  EMAIL: {
    TITLE: 'Email',
    PLACEHOLDER: 'Enter your email address',
    KEY: 'email',
    NAME: 'email',
    INVALID: 'Please enter a valid email address.',
  },
  PREFERENCES: {
    TITLE: 'Contact preferences',
    PLACEHOLDER: '',
    NAME: 'contactPreferences',
    KEY: 'contactPreferences',
  },
};

if (region === Region.UK) {
  FIELD_INFO.PRIMARY.TITLE = 'Mobile phone number';
  FIELD_INFO.PRIMARY.PLACEHOLDER = 'At least one phone number is required';
  FIELD_INFO.SECONDARY.TITLE = 'Home phone number';
  FIELD_INFO.SECONDARY.PLACEHOLDER = 'At least one phone number is required';
  FIELD_INFO.EMAIL.PLACEHOLDER = '';
  FIELD_INFO.PREFERENCES.TITLE = 'Contact preferences';
  FIELD_INFO.PREFERENCES.NAME = 'contactPreferences';
  FIELD_INFO.PREFERENCES.KEY = 'contactPreferences';
}

// Interfaces
interface ValidationObject {
  [key: string]: string[];
  primaryPhone: string[];
  secondaryPhone: string[];
  email: string[];
}

interface Props {
  details: PatientContactDetails;
  genderRefData: ListData[];
  autosave: (details: Patient, key: string, value: string | boolean, type: string) => Promise<void>;
  saveStatus: SavingStatus;
  validateOnLoad: boolean;
  isPso: boolean;
}

interface State {
  viewed: Set<string>;
  details: PatientContactDetails;
}

class RegistrationContactDetails extends Component<Props, State> {
  public constructor(props: Props) {
    super(props);
    this.state = {
      viewed: new Set(),
      details: props.details,
    };
  }

  public static getDerivedStateFromProps(props: Props, state: State): State {
    if (props.validateOnLoad && props.details.lock && !props.details.lock.readOnly) {
      const keys = Object.keys(FIELD_INFO).map((keyName: string) => {
        return FIELD_INFO[keyName].KEY;
      });

      const viewed = new Set(keys);
      return { viewed: viewed, details: props.details };
    }

    return state;
  }

  public render(): JSX.Element {
    const { details, genderRefData, isPso, saveStatus } = this.props;
    return (
      <RegistrationContainer patient={details} genderRefData={genderRefData} isPso={isPso} saveStatus={saveStatus}>
        {this.renderContents()}
      </RegistrationContainer>
    );
  }

  private renderContents = (): JSX.Element => {
    const { isPso } = this.props;
    const details = this.state.details;
    const validationObject = this.validateObject(details);
    const categoryName = 'contact';
    return (
      <div className="form-page">
        <form className="form-container">
          <div className="form-heading">{FORM_HEADING}</div>
          {region === Region.AU && !isPso && (
            <div className="phone-required-message">
              <Info /> <span>At least one phone number is required</span>
            </div>
          )}
          <SectionField
            isValid={validationObject && validationObject.primaryPhone ? false : true}
            htmlFor={FIELD_INFO.PRIMARY.NAME}
            title={applyRequired({
              title: FIELD_INFO.PRIMARY.TITLE,
              category: categoryName,
              optionalForPSO: isPso,
            })}>
            <FreeTextField
              disabled={details.lock && details.lock.readOnly}
              inputName={FIELD_INFO.PRIMARY.NAME}
              maxLength={25}
              placeholder={FIELD_INFO.PRIMARY.PLACEHOLDER}
              defaultValue={details.primaryPhone}
              onBlur={(e): void => {
                this.autosave(details, FIELD_INFO.PRIMARY.KEY, e.target.value);
              }}
              errors={validationObject && validationObject.primaryPhone ? validationObject.primaryPhone : undefined}
            />
          </SectionField>
          <SectionField
            isValid={validationObject && validationObject.secondaryPhone ? false : true}
            htmlFor={FIELD_INFO.SECONDARY.NAME}
            title={applyRequired({
              title: FIELD_INFO.SECONDARY.TITLE,
              category: categoryName,
              optionalForPSO: isPso,
            })}
            hideOptional={region === Region.UK}>
            <FreeTextField
              disabled={details.lock && details.lock.readOnly}
              inputName={FIELD_INFO.SECONDARY.NAME}
              maxLength={25}
              placeholder={FIELD_INFO.SECONDARY.PLACEHOLDER}
              defaultValue={details.secondaryPhone}
              onBlur={(e): void => {
                this.autosave(details, FIELD_INFO.SECONDARY.KEY, e.target.value);
              }}
              errors={validationObject && validationObject.secondaryPhone ? validationObject.secondaryPhone : undefined}
            />
          </SectionField>
          <SectionField
            isValid={validationObject && validationObject.email ? false : true}
            htmlFor={FIELD_INFO.EMAIL.NAME}
            title={FIELD_INFO.EMAIL.TITLE}
            hideOptional={true}
            required={false}>
            <FreeTextField
              disabled={details.lock && details.lock.readOnly}
              inputName={FIELD_INFO.EMAIL.NAME}
              maxLength={40}
              placeholder={FIELD_INFO.EMAIL.PLACEHOLDER}
              defaultValue={details.email}
              onBlur={(e): void => {
                this.autosave(details, FIELD_INFO.EMAIL.KEY, e.target.value);
              }}
              errors={validationObject && validationObject.email ? validationObject.email : undefined}
            />
          </SectionField>
          {region === Region.UK && (
            <SectionField
              isValid={true}
              htmlFor={FIELD_INFO.PREFERENCES.NAME}
              title={FIELD_INFO.PREFERENCES.TITLE}
              hideOptional={region === Region.UK}
              required={false}>
              <div className="comms-checkbox-wrapper">
                <div className="comms-checkbox">
                  <Checkbox
                    disabled={details.lock && details.lock.readOnly}
                    inputName={'preferenceMessage'}
                    inputLabel={'Voicemail'}
                    onChange={(e): void => {
                      const booleanValue = e as boolean;
                      this.autosave(details, 'preferenceMessage', booleanValue, 'Boolean');
                    }}
                    isChecked={!!details.preferenceMessage}
                  />
                </div>
                <div className="comms-checkbox">
                  <Checkbox
                    disabled={details.lock && details.lock.readOnly}
                    inputName={'preferenceText'}
                    inputLabel={'Text (SMS)'}
                    onChange={(e): void => {
                      const booleanValue = e as boolean;
                      this.autosave(details, 'preferenceText', booleanValue, 'Boolean');
                    }}
                    isChecked={!!details.preferenceText}
                  />
                </div>
                <div className="comms-checkbox">
                  <Checkbox
                    disabled={
                      !details.email ||
                      (validationObject && validationObject.email && validationObject.email.length > 0)
                    }
                    inputName={'preferenceEmail'}
                    inputLabel={'Email'}
                    onChange={(e): void => {
                      const booleanValue = e as boolean;
                      this.autosave(details, 'preferenceEmail', booleanValue, 'Boolean');
                    }}
                    isChecked={!!details.preferenceEmail}
                  />
                </div>
              </div>
            </SectionField>
          )}
          <br />
        </form>
      </div>
    );
  };

  private autosave = async (
    details: PatientContactDetails,
    key: string,
    value: string | boolean,
    type = 'String',
  ): Promise<void> => {
    const detailsCopy = lodash.cloneDeep(details);
    detailsCopy[key] = value;
    this.setState({ details: detailsCopy });
    const { autosave } = this.props;
    await autosave(detailsCopy, key, value, type).then(() => {
      const { viewed } = this.state;
      viewed.add(key);
      this.setState({ viewed });
    });
  };

  private validateForm = (): void => {
    const keys = Object.keys(FIELD_INFO).map((keyName: string) => {
      return FIELD_INFO[keyName].KEY;
    });

    const viewed = new Set(keys);
    this.setState({ viewed });
  };

  private validateObject = (details: PatientContactDetails): ValidationObject | undefined => {
    // Global rules for the validator, must match the patient interface keys
    const globalValidationRules: { [key: string]: object } = {
      primaryPhone: {
        presence: {
          allowEmpty: !details.secondaryPhone ? false : true,
          message: FIELD_INFO.PRIMARY.EMPTY,
        },
        format: {
          pattern: region === Region.UK ? UK_PHONE_REGEX : PHONE_NUMBER_REGEX,
          message: FIELD_INFO.PRIMARY.INVALID,
        },
      },
      secondaryPhone: {
        presence: {
          allowEmpty: !details.primaryPhone ? false : true,
          message: FIELD_INFO.SECONDARY.EMPTY,
        },
        format: {
          pattern: region === Region.UK ? UK_PHONE_REGEX : PHONE_NUMBER_REGEX,
          message: FIELD_INFO.SECONDARY.INVALID,
        },
      },
      email: {
        presence: {
          allowEmpty: true,
        },
        format: {
          pattern: EMAIL_REGEX,
          message: FIELD_INFO.EMAIL.INVALID,
        },
      },
    };

    if (details.primaryPhone === '') {
      // @ts-ignore
      globalValidationRules.primaryPhone.format.pattern = '.*';
    } else if (details.secondaryPhone === '') {
      // @ts-ignore
      globalValidationRules.secondaryPhone.format.pattern = '.*';
    }

    // Ensure fields that been viewed only have validation run on them
    const specificValidationRules: { [key: string]: object } = {};
    for (const viewed of this.state.viewed.keys()) {
      specificValidationRules[viewed] = globalValidationRules[viewed];
    }
    // Disable pre-appending of argument name to error messages
    const disableFullMessages = { fullMessages: false };
    // Run validation on all the fields
    return validate(details, specificValidationRules, disableFullMessages);
  };
}
// @ts-ignore
export default RegistrationContactDetails;
