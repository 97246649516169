import React from 'react';
import { styled } from '@mui/system';
import MUISwitch from '@mui/material/Switch';

interface Props {
  checked: boolean;
  handleChange: (e: React.ChangeEvent<HTMLInputElement>) => void;
}

const StyledSwitch = styled(MUISwitch)`
  &&& {
    & .Mui-checked {
      color: ${(props) => props.theme.palette.primary.main};
    }
    & .MuiSwitch-track {
      background-color: ${(props) => props.theme.palette.primary.main};
    }
  }
`;

const Switch = ({ checked, handleChange }: Props): JSX.Element => {
  return <StyledSwitch checked={checked} onChange={handleChange} />;
};

export default Switch;
