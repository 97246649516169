// eslint-disable-next-line no-use-before-define
import React, { Component, Fragment } from 'react';
import classNames from 'classnames';

import './SegmentedInput.scss';

import { Region } from 'shared-components/enums';
import { ListData, ReactListData } from 'shared-components/interfaces';
import { ErrorInfo } from 'shared-components/components/FormFields';
const REACT_APP_REGION = import.meta.env.REACT_APP_REGION;

const region = REACT_APP_REGION;

/**
 * Props
 * @property {string} fieldName Name of this field
 * @property {string} options Set of options to show in a segmented control
 * @property {string} defaultSelected The default selected option
 * @property {string[]} errors The errors that are to be displayed
 * @property {function} itemSelected The item that has been selected by the user, it will return the object with the value and the id of the list data.
 */
interface Props {
  fieldName: string;
  options: ListData[];
  itemSelected: (selectedItem: ListData | boolean) => void;
  defaultSelected?: string | boolean | null;
  errors?: string[];
  disabled?: boolean;
  optionAreBoolean?: boolean;
  inputKey?: string;
}

/**
 * State
 * @property {ListData} ListData The current selected option item
 */
interface State {
  getDefaultValue: any;
  selectedOption?: ListData;
}

class SegmentedInput extends Component<Props, State> {
  /**
   * Constructor
   * @param props
   */
  public constructor(props: Props) {
    super(props);

    const defaultSelectedOption: ListData | undefined = this.getDefaultSelectedOption(props);
    this.state = {
      getDefaultValue: this.getDefaultSelectedOption.bind(this),
      selectedOption: defaultSelectedOption,
    };
  }

  /**
   * If we have a map of segment control inputs and we remove and item from the map
   * The segment's do not update thier selected option to the correct state
   * So if the props update make sure we update the state selected option
   */
  public static getDerivedStateFromProps(props: Props, state: State): State {
    const defaultSelectedOption: ListData | undefined = state.getDefaultValue(props);
    if (state.selectedOption !== defaultSelectedOption) {
      state.selectedOption = defaultSelectedOption;
    }
    return state;
  }

  public getDefaultSelectedOption = (props: Props): ListData | undefined => {
    // Convert the default selected string into a selection of the options that have been passed in.
    let defaultSelectedOption: ListData | undefined = undefined;
    if (props.defaultSelected !== undefined && props.defaultSelected !== null) {
      defaultSelectedOption = props.options.filter((value: ListData) => {
        if (value.id === this.parseDefaultValue(props.defaultSelected)) {
          return true;
        }

        const optionAppKey = (value as ReactListData).appKey;
        if (optionAppKey && optionAppKey === props.defaultSelected) {
          return true;
        }

        return false;
      })[0];
    }

    return defaultSelectedOption;
  };

  /**
   * Set the selected segment option
   * @param optionItem The selected segment
   */
  private setSelectedItem = (optionItem: ListData): void => {
    const { itemSelected, optionAreBoolean } = this.props;
    this.setState({ selectedOption: optionItem });

    let selectedOption: ListData | boolean = optionItem;
    if (optionAreBoolean) {
      selectedOption = this.convertFromStringToBoolean(optionItem.id);
    }

    itemSelected(selectedOption);
  };

  /**
   * Get if a segment is checked
   * @param optionItem The current segment
   */
  private getChecked = (optionItem: string): boolean => {
    if (this.state.selectedOption != null) {
      return optionItem === this.state.selectedOption.id;
    } else {
      return false;
    }
  };

  private parseDefaultValue = (value: boolean | string | undefined | null): string | undefined => {
    if (typeof value === 'string') {
      return value;
    }

    if (value) {
      return '1';
    } else if (value === false) {
      return '2';
    }

    return undefined;
  };

  private convertFromStringToBoolean = (value: string): boolean => {
    if (value === '1') {
      return true;
    }

    return false;
  };

  public render(): JSX.Element {
    const { fieldName, options, errors } = this.props;

    /**
     * Generate the segement options
     */
    const optionItems = options.map((option: ListData, index: number): JSX.Element => {
      return (
        <Fragment key={index}>
          <Fragment key={index}>
            <input
              type="radio"
              radioGroup={fieldName}
              value={option.id}
              id={`${fieldName}_${option.name}`}
              checked={this.getChecked(option.id)}
              aria-checked={this.getChecked(option.id)}
              onChange={(): void => this.setSelectedItem(option)}
              disabled={this.props.disabled}
            />
            <label
              className={classNames(
                { 'disabled-label': this.props.disabled },
                { 'UK-label-style': region === Region.UK },
              )}
              htmlFor={`${fieldName}-${option.name}`}
              onClick={(): void => {
                if (!this.props.disabled) this.setSelectedItem(option);
              }}>
              {option.name}
            </label>
          </Fragment>
        </Fragment>
      );
    });

    return (
      <div style={{ width: '100%' }}>
        <div className={classNames('segmented-control', { 'segmented-error': errors && errors.length > 0 }, fieldName)}>
          {optionItems}
        </div>
        <ErrorInfo errors={errors} />
      </div>
    );
  }
}

export default SegmentedInput;
