import Highcharts from 'highcharts';
import { syncConfig, creditsConfig, BottomChartParams, loadSeeMoreButton, subExportingConfig } from './DefaultConfig';

const SubChartBarCount = ({
  title,
  connector,
  cell,
  filterOverride,
  seeMore,
  precision = 0,
  dataLabelTitle = 'Count',
  dataLabelTitleWidth = 50,
  yAxisMax = 50,
  theme,
  toggleSeeMore,
}: BottomChartParams): Dashboards.Board.Options => ({
  components: [
    {
      cell: cell,
      connector: { id: connector },
      sync: syncConfig,
      type: 'Highcharts',
      chartOptions: {
        exporting: {
          buttons: {
            contextButton: {
              menuItems: ['downloadCSV', 'downloadXLS'],
              y: -7,
              x: 40,
              symbol: 'download',
              symbolSize: 24,
            },
          },
        },
        chart: {
          type: 'bar',
          spacing: [20, 60, 70, 20],
          height: 320,
          events: {
            load() {
              loadSeeMoreButton(this, toggleSeeMore, seeMore);

              const chart = this as Highcharts.Chart & {
                labelHeader1?: Highcharts.SVGElement;
              };

              const positionText = () => {
                const xPosition1 = chart.chartWidth - dataLabelTitleWidth - 130;

                if (chart.labelHeader1) {
                  chart.labelHeader1.attr({ x: xPosition1 });
                } else {
                  chart.labelHeader1 = chart.renderer
                    .text(dataLabelTitle, xPosition1, 60)
                    .css({
                      color: theme.palette.text.primary,
                      fontSize: '12px',
                      fontFamily: 'Poppins, sans-serif',
                    })
                    .add();
                }
              };

              positionText();

              chart.redraw = function () {
                Highcharts.Chart.prototype.redraw.call(this);
                positionText();
              };
            },
          },
        },
        title: {
          text: title,
          align: 'left',
        },
        subtitle: {
          text: filterOverride,
          align: 'left',
          style: {
            color: 'rgba(8, 30, 67, 0.60)',
            fontSize: '14px',
          },
        },
        xAxis: {
          type: 'category',
          scrollbar: {
            enabled: true,
          },
        },
        yAxis: {
          reversed: true,
          visible: false,
          max: yAxisMax,
          scrollbar: {
            enabled: true,
          },
        },
        legend: {
          enabled: false,
        },
        tooltip: {
          enabled: false,
        },
        credits: creditsConfig,
        plotOptions: {
          bar: {
            borderRadius: 8,
            pointWidth: 9,
          },
          series: {
            cursor: 'pointer',
            dataLabels: [
              {
                enabled: true,
                formatter: function () {
                  return (
                    '<span style="font-weight: 400; font-family: Poppins, sans-serif;">' +
                    this.y?.toFixed(precision) +
                    '</span>'
                  );
                },
                align: 'right',
                verticalAlign: 'middle',
                alignTo: 'plotEdges',
                x: 40,
                y: -1,
                crop: false,
                overflow: 'allow',
              },
            ],
          },
        },
      },
    },
  ],
});

export default SubChartBarCount;
