import React from 'react';

export type TenantContextType = {
  doseUnit: string;
  setDoseUnit: (val: string) => void;
  ariaDoseUnit: string;
  setAriaDoseUnit: (val: string) => void;
  mosaiqDoseUnit: string;
  setMosaiqDoseUnit: (val: string) => void;
  fractionUnit: string;
  setFractionUnit: (val: string) => void;
};

export const TenantContext = React.createContext<TenantContextType>({
  doseUnit: 'Gy',
  setDoseUnit: () => {},
  ariaDoseUnit: 'Gy',
  setAriaDoseUnit: () => {},
  mosaiqDoseUnit: 'cGy',
  setMosaiqDoseUnit: () => {},
  fractionUnit: '#',
  setFractionUnit: () => {},
});
