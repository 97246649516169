import React from 'react';
import { styled } from '@mui/system';
import { yesNKAOptions } from '../../constants';
import { getRemSize } from 'shared-components/StyledComponents/functions';
import { ReviewBox } from 'shared-components/components';
import ReviewSubFields from '../../../shared/components/ReviewSubFields';
import { Question, Answer } from './ReviewSharedComponents';

interface Props {
  fields: any;
  values: any;
  error: boolean;
  link: string;
  isLocked: boolean;
}

interface valueResponse {
  value?: any;
}

interface Position {
  position?: string;
}

const QuestionContainer = styled('div')<Position>`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  margin-left: 27px;
  margin-top: ${(props: Position): string => (props.position === 'top' ? '0px' : '20px')};
  margin-bottom: ${(props: Position): string => (props.position === 'bottom' ? '0px' : '20px')}; ;
`;

const SubQuestionContainer = styled('div')`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  margin-left: 27px;
`;

const ArrayQuestion = styled('div')<valueResponse>`
  width: 50%;
  height: 40px;
  font-size: ${getRemSize(14)};
  line-height: 20px;
  display: flex;
  align-items: center;
  color: ${(props) =>
    !props.value.includes('Not Provided') ? props.theme.palette.text.primary : props.theme.palette.error.main};
`;

const ArrayAnswer = styled('div')<valueResponse>`
  width: 50%;
  font-weight: bold;
  font-size: ${getRemSize(14)};
  line-height: 20px;
  display: flex;
  align-items: center;
  color: ${(props) =>
    !props.value.includes('Not Provided') ? props.theme.palette.text.primary : props.theme.palette.error.main};
`;

const AllergyTitle = styled('div')`
  font-weight: bold;
  font-size: ${getRemSize(18)};
  line-height: 28px;
  margin-left: 27px;
  margin-top: 10px;
`;

const convertValueToLabel = (value: any, arrayLabel?: any) => {
  for (const option of arrayLabel) {
    if (option.value === value) return option.label;
  }
  if (typeof value === 'object') {
    if (value.length > 0) {
      return value.join(', ');
    } else {
      return 'Not Provided';
    }
  }
};

const renderQuestion = (value: any, title: string): JSX.Element => {
  return (
    <SubQuestionContainer aria-label={`review-${title}`}>
      <Question value={value}>{title}</Question>
      <Answer value={value}>{value ? value : 'Not Provided'}</Answer>
    </SubQuestionContainer>
  );
};

const generateReactionList = (reactions: any): JSX.Element => {
  const reactionList = [];

  if (reactions) {
    for (const value of reactions) {
      if (value.baseValue !== 'Other') {
        reactionList.push(value.baseValue !== '' ? value.baseValue : 'Not Provided');
      }
    }
  }
  const reactionString = reactionList.length > 0 ? reactionList.join(', ') : 'Not Provided';
  if (reactionList.length > 0) {
    return (
      <SubQuestionContainer aria-label={'review-Reaction/s experienced'}>
        <ArrayQuestion value={reactionString}>Reaction/s experienced</ArrayQuestion>
        <ArrayAnswer value={reactionString}>{reactionString}</ArrayAnswer>
      </SubQuestionContainer>
    );
  }
  return <></>;
};

const ReviewInfections = (props: Props): JSX.Element => {
  const { fields, values, error, link, isLocked } = props;

  return (
    <ReviewBox title={'Allergies'} error={error} link={link} isLocked={isLocked}>
      <QuestionContainer position="top" aria-label={`review-${fields.hasPatientAllergies}`}>
        <Question value={values.hasPatientAllergies}>{fields.hasPatientAllergies}</Question>
        <Answer value={values.hasPatientAllergies}>
          {values.hasPatientAllergies !== null
            ? convertValueToLabel(values.hasPatientAllergies, yesNKAOptions)
            : 'Not Provided'}
        </Answer>
      </QuestionContainer>
      {values.hasPatientAllergies && (
        <ReviewSubFields>
          {Object.values(values.pcccAllergy).map((item: any, index: number) => {
            return (
              <div aria-label={`review-Allergy/Sensitivity ${index + 1}`} key={index}>
                <AllergyTitle>Allergy/Sensitivity {index + 1}</AllergyTitle>
                {renderQuestion(item.allergyType, 'Allergy/sensitivity')}
                {item.allergyType !== 'Food' && (
                  <>
                    {renderQuestion(item.additionalDetails, 'Please specify')}
                    {generateReactionList(item.pcccReaction)}
                  </>
                )}
                {item.pcccReaction &&
                  Object.values(
                    item.pcccReaction.map((item: any) => {
                      if (item.baseValue === 'Other') {
                        return renderQuestion(item.otherValue, 'Other reaction/s experienced');
                      }
                      return null;
                    }),
                  )}
              </div>
            );
          })}
        </ReviewSubFields>
      )}
    </ReviewBox>
  );
};

export default ReviewInfections;
