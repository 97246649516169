import React, { useContext } from 'react';
import { useHistory, useRouteMatch } from 'react-router-dom';
import { Paper, Typography, Stack, AccordionDetails } from '@mui/material';
import { AddCircleOutline as AddCircleOutlineIcon, Circle as CircleIcon } from '@mui/icons-material';
import { useMutation, useQuery } from '@apollo/client';
import { GET_DOSE_SITE_SUMMARIES } from '../PatientSummary/PatientProfileQueries';
import { isUs } from 'op-utils/helpers';
import dayjs from 'dayjs';
import { Accordion, AccordionSummary } from './Accordion';
import ReviewCard from './ReviewCard';
import { CREATE_ON_TREATMENT_REVIEW, GET_ON_TREATMENT_REVIEW_LIST } from './Queries';
import { useState } from 'react';
import { DoseSiteSummaryType, OnTreatmentReviewType } from './Interface';
import { getOTVCareplanName } from 'op-pages/RO/Careplan/SidePanel/SidePanel';
import { TenantContext } from 'op-contexts';

const Status = ({ status, ...props }: { status: string }): JSX.Element => {
  const textMap: { [status: string]: string } = {
    'In progress': 'In Progress',
    'Unsubmitted changes': 'Unsubmitted Changes',
    Submitted: 'Submitted',
  };

  const colourMap: { [status: string]: 'warning' | 'success' } = {
    'In progress': 'warning',
    'Unsubmitted changes': 'warning',
    Submitted: 'success',
  };

  return (
    <Stack direction="row" gap={1} alignItems="center" {...props}>
      <CircleIcon fontSize="small" color={colourMap[status]} />
      <Typography fontSize={16}>{textMap[status]}</Typography>
    </Stack>
  );
};

const formatDateNicely = (date: string): string => dayjs(date).format(`${isUs() ? 'MMM-DD' : 'DD-MMM'}-YYYY`);

const LandingPage = (): JSX.Element => {
  const match: { params: { id: string } } = useRouteMatch();
  const { id: patientId } = match.params;
  const history = useHistory();
  const tenantContext = useContext(TenantContext);
  const [creatingReview, setCreatingReview] = useState(false);
  const { data: doseSiteSummariesData, loading } = useQuery(GET_DOSE_SITE_SUMMARIES, {
    variables: { patientId },
    skip: !patientId,
  });
  const { data: onTreatmentReviewData, loading: onTreatmentReviewLoading } = useQuery(GET_ON_TREATMENT_REVIEW_LIST, {
    variables: { patientId },
    fetchPolicy: 'network-only',
    nextFetchPolicy: 'network-only',
    skip: !patientId,
  });
  const [createOnTreatmentReview] = useMutation(CREATE_ON_TREATMENT_REVIEW);

  const handleAddReview = (careplan: string, doseSiteSummary: any) => {
    if (creatingReview) return;
    setCreatingReview(true);
    createOnTreatmentReview({
      variables: {
        patientId,
        careplan: careplan,
        dss: JSON.stringify(doseSiteSummary),
      },
    })
      .then((res) => {
        history.push(
          `/radiation/patient/${patientId}/on-treatment-review/${res.data.createOnTreatmentReview.review.id}`,
        );
      })
      .finally(() => {
        setCreatingReview(false);
      });
  };
  const handleExistingReview = (id: string) => {
    history.push(`/radiation/patient/${patientId}/on-treatment-review/${id}`);
  };

  if (loading || onTreatmentReviewLoading) return <p>Loading...</p>;
  const sortedOnTreatmentReviewList = onTreatmentReviewData?.treatmentReviewList
    ? [...onTreatmentReviewData.treatmentReviewList].sort(function (
        a: OnTreatmentReviewType,
        b: OnTreatmentReviewType,
      ) {
        const fractionA = a.treatmentFractionsReviewed ? (a.treatmentFractionsReviewed.split('-') || ['0'])[0] : '0';
        const fractionB = b.treatmentFractionsReviewed ? (b.treatmentFractionsReviewed.split('-') || ['0'])[0] : '0';
        return parseInt(fractionA) - parseInt(fractionB);
      })
    : [];
  return (
    <Paper sx={{ width: 1, height: 1, marginX: 2, padding: 2 }} elevation={10}>
      <Stack direction="column" spacing={2}>
        <Typography variant="h6" lineHeight="28px">
          On Treatment Review
        </Typography>
        <Stack direction="column" spacing={1}>
          {(!doseSiteSummariesData?.doseSiteSummaries || doseSiteSummariesData.doseSiteSummaries.length === 0) && (
            <Typography variant="body1" style={{ fontSize: '16px' }}>
              On Treatment Review will be available once treatment has started.
            </Typography>
          )}
          {doseSiteSummariesData?.doseSiteSummaries.map((doseSiteSummary: DoseSiteSummaryType) => {
            const careplanName = getOTVCareplanName(doseSiteSummary, tenantContext);
            return (
              <Accordion key={doseSiteSummary.id} data-testid={`site-${doseSiteSummary.id}`} defaultExpanded>
                <AccordionSummary>
                  <Stack direction="row" spacing={4}>
                    <Typography fontSize={16} fontWeight={700}>
                      {careplanName}
                    </Typography>
                    <Typography fontSize={16}>
                      Treatment Start Date: {formatDateNicely(doseSiteSummary.firstTreatment)}
                    </Typography>
                  </Stack>
                </AccordionSummary>
                <AccordionDetails sx={{ paddingX: 0 }}>
                  <Stack direction="row" flexWrap="wrap" columnGap={2} rowGap={2}>
                    <ReviewCard
                      variant="outlined"
                      onClick={() => handleAddReview(careplanName, doseSiteSummary)}
                      aria-disabled={creatingReview}
                      data-testid="add-review">
                      <Stack direction="column" spacing={1}>
                        <Typography fontSize={16}>Add on treatment review</Typography>
                        <AddCircleOutlineIcon fontSize="large" />
                      </Stack>
                    </ReviewCard>
                    {sortedOnTreatmentReviewList
                      .filter((review: OnTreatmentReviewType) => review.careplan === careplanName)
                      .map(
                        (fractionData: {
                          id: string;
                          treatmentFractionsReviewed: any;
                          reviewDate: { toString: () => string };
                          status: string;
                        }) => {
                          return (
                            <ReviewCard
                              key={fractionData.id}
                              variant="outlined"
                              data-testid={`review-card-${fractionData.id}`}
                              onClick={() => handleExistingReview(fractionData.id)}>
                              <Stack direction="column" spacing={1}>
                                <Typography fontSize={18} fontWeight={700} data-testid="fraction">
                                  Fraction: {fractionData.treatmentFractionsReviewed || '-'}
                                </Typography>
                                <Typography fontSize={16} data-testid="review-date">
                                  Review Date: {formatDateNicely(fractionData.reviewDate?.toString())}
                                </Typography>
                                <Status status={fractionData.status} data-testid="status" />
                              </Stack>
                            </ReviewCard>
                          );
                        },
                      )}
                  </Stack>
                </AccordionDetails>
              </Accordion>
            );
          })}
        </Stack>
        <Typography variant="h6" lineHeight="28px">
          End of Treatment
        </Typography>
        <Typography variant="body1" lineHeight="28px">
          Coming Soon...
        </Typography>
      </Stack>
    </Paper>
  );
};

export default LandingPage;
