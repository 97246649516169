// eslint-disable-next-line no-use-before-define
import React from 'react';

import checkedIcon from 'shared-components/images/checked_icon.png';
import notCheckedIcon from 'shared-components/images/not_checked_icon.png';

import './RadioFieldTile.scss';

interface Props {
  name: string;
  label: string;
  value: string;
  checked: boolean;
  keyId: string;
}

const RadioFieldTile = ({ name, label, value, checked, keyId }: Props): JSX.Element => {
  return (
    <ul key={keyId} className="radio-field-tile">
      <div className="radio-field-value">{value}</div>
      <li className="radio-field-check-label">
        <input
          id={`radio-${name}-${value}`}
          type="radio"
          name={name}
          value={value}
          checked={checked}
          onChange={(): void => {}}
        />
        <label htmlFor={`radio-${name}-${value}`}>
          {checked ? <img src={checkedIcon} alt="checked icon" /> : <img src={notCheckedIcon} alt="unchecked icon" />}
          <span data-testid="radio-label">{label}</span>
        </label>
      </li>
    </ul>
  );
};

export default RadioFieldTile;
