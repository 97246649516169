// eslint-disable-next-line no-use-before-define
import { MainLayout } from 'op-pages/PX/Components';
import { Component } from 'react';

import { RouteComponentProps } from 'react-router';

import './ContentContainer.scss';

import { StepperLink } from 'shared-components/interfaces';
import { CHEMO_STEPPER_LINKS, CONTENT_STEPPER_LINKS, MAGIC_STRINGS } from './constants';

import { Stepper } from 'shared-components/components';
import ContentWrapper from './Content/ContentWrapper';

interface State {
  contentShown: boolean;
}

interface Match {
  pageId?: string;
}

type Props = RouteComponentProps;

class ContentContainer extends Component<RouteComponentProps, State> {
  public constructor(props: RouteComponentProps) {
    super(props);

    this.state = {
      contentShown: false,
    };
  }

  public render(): JSX.Element {
    const { match } = this.props;
    const { pageId }: Match = match.params;
    const { contentShown } = this.state;
    const { CHEMOTHERAPY, RADIATION_THERAPY } = MAGIC_STRINGS;
    const pageCategory = pageId ? pageId.split('-')[0] : undefined;
    const headerTitle =
      pageCategory && (pageCategory === CHEMOTHERAPY.toLowerCase() ? CHEMOTHERAPY : RADIATION_THERAPY);

    return (
      <MainLayout
        clickLogoFn={(): void => {
          this.state.contentShown ? this.setContentShown(false) : this.props.history.push('/px/information');
        }}>
        <div className="page-container width-contained">
          <div className="px-content-inner-container">
            <Stepper
              links={headerTitle === CHEMOTHERAPY ? CHEMO_STEPPER_LINKS : CONTENT_STEPPER_LINKS}
              onStepperButtonClick={() => {
                if (!contentShown) {
                  this.setContentShown(true);
                }
              }}
              basePath=""
              headerTitle={headerTitle}
            />
            <ContentWrapper contentShown={contentShown} setContentShown={this.setContentShown} />
          </div>
        </div>
      </MainLayout>
    );
  }

  private setContentShown = (to: boolean) => {
    this.setState({
      contentShown: to,
    });
  };
}

export default ContentContainer;
