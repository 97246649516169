// eslint-disable-next-line no-use-before-define
import React, { Component, Fragment } from 'react';

import 'shared-components/components/FormFields/Form/Form.scss';
import './RegistrationAddress.scss';

import { Region } from 'shared-components/enums';
import { RegistrationContainer, AddressAutocomplete } from 'op-components';
import { Patient, PatientAddress, AddressComponents, AddressComponent, AddressUkComponents } from 'op-interfaces';

import { ListData, GraphUpdate } from 'shared-components/interfaces';
import { AddressField, SegmentedInput, SectionField } from 'shared-components/components/FormFields';
import { AddressInputs } from 'shared-components/components/FormFields/types';
import { SavingStatus } from 'shared-components/enums';
import { Logger } from 'shared-components/utils';
import { Search } from 'shared-components/images';
const REACT_APP_REGION = import.meta.env.REACT_APP_REGION;

const logger = new Logger('RegistrationAddress');

const FORM_HEADING = 'Address';
const region = REACT_APP_REGION;

interface State {
  validateAddressFields: boolean;
  manualAddressEntry: boolean;
  autoCompleteBlankError: boolean;
  autoCompleteInvalidError: boolean;
  currentAddress: string;
}

interface Props {
  autosave: (patient: Patient, updateItems: [GraphUpdate], forceFetch?: object[]) => Promise<void>;
  patient: PatientAddress;
  countries: ListData[];
  ausStates: ListData[];
  genderRefData: ListData[];
  saveStatus: SavingStatus;
  validateOnLoad: boolean;
  isPso: boolean;
}

class RegistrationAddress extends Component<Props, State> {
  private forceFetchItems =
    region !== Region.UK
      ? [{ address: { id: '', formattedAddress: '', __typename: 'AddressType' } }]
      : [{ address: { id: '', formattedAddress: '', ukFormattedAddress: '', __typename: 'AddressType' } }];

  public constructor(props: Props) {
    super(props);
    this.state = {
      autoCompleteBlankError: false,
      autoCompleteInvalidError: false,
      validateAddressFields: false,
      currentAddress: '',
      manualAddressEntry: Boolean(this.props.patient.address?.formattedAddress),
    };
  }

  public static getDerivedStateFromProps(props: Props, state: State): State {
    if (props.validateOnLoad && props.patient.lock && !props.patient.lock.readOnly) {
      let validateAutoComplete = false;
      if (props.patient && props.patient.address && props.patient.address.formattedAddress === '') {
        validateAutoComplete = true;
      }
      state = { ...state, validateAddressFields: true, autoCompleteBlankError: validateAutoComplete };
    } else {
      if (region === Region.UK) {
        if (
          props.patient.address &&
          state.currentAddress !== (props.patient.address as any).ukFormattedAddress &&
          (props.patient.address as any).ukFormattedAddress !== ''
        ) {
          return {
            ...state,
            currentAddress: (props.patient.address as any).ukFormattedAddress,
            manualAddressEntry: true,
          };
        }
      }
    }
    return state;
  }

  public render(): JSX.Element {
    const { patient, genderRefData, saveStatus, isPso } = this.props;
    return (
      <RegistrationContainer patient={patient} genderRefData={genderRefData} isPso={isPso} saveStatus={saveStatus}>
        {this.renderRegistrationAddressForm(patient)}
      </RegistrationContainer>
    );
  }

  private renderRegistrationAddressForm(patient: PatientAddress): JSX.Element {
    const {
      countries,
      ausStates,
      patient: { postalAddressSameAsResidential },
    } = this.props;

    // Define address input items

    const residentialAddressInput: AddressInputs = {
      line1: {
        inputName: 'line1',
        defaultValue: patient.residentialAddressLine1,
        graphArgumentKey: 'residentialAddressLine1',
        maxlength: 60,
      },
      line2: {
        inputName: 'line2',
        defaultValue: patient.residentialAddressLine2,
        graphArgumentKey: 'residentialAddressLine2',
        maxlength: 60,
      },
      city: {
        inputName: 'city',
        defaultValue: patient.residentialAddressCity,
        graphArgumentKey: 'residentialAddressCity',
        maxlength: 40,
      },
      country: {
        inputName: 'country',
        defaultValue: patient.residentialAddressCountry,
        graphArgumentKey: 'residentialAddressCountry',
      },
      stateprovienceregion: {
        inputName: 'stateprovienceregion',
        defaultValue: patient.residentialAddressState,
        graphArgumentKey: 'residentialAddressState',
      },
      zipPostcode: {
        inputName: 'zippostcode',
        defaultValue: patient.residentialAddressPostcode,
        graphArgumentKey: 'residentialAddressPostcode',
      },
      state: {
        inputName: 'state',
        defaultValue: patient.residentialAddressState,
        graphArgumentKey: 'residentialAddressState',
      },
    };

    // Define address input items
    const postalAddressInput: AddressInputs = {
      line1: {
        inputName: 'line1',
        defaultValue: patient.postalAddressLine1,
        graphArgumentKey: 'postalAddressLine1',
      },
      line2: {
        inputName: 'line2',
        defaultValue: patient.postalAddressLine2,
        graphArgumentKey: 'postalAddressLine2',
      },
      city: {
        inputName: 'city',
        defaultValue: patient.postalAddressCity,
        graphArgumentKey: 'postalAddressCity',
      },
      country: {
        inputName: 'country',
        defaultValue: patient.postalAddressCountry,
        graphArgumentKey: 'postalAddressCountry',
      },
      stateprovienceregion: {
        inputName: 'stateprovienceregion',
        defaultValue: patient.postalAddressState,
        graphArgumentKey: 'postalAddressState',
      },
      zipPostcode: {
        inputName: 'zippostcode',
        defaultValue: patient.postalAddressPostcode,
        graphArgumentKey: 'postalAddressPostcode',
      },
      state: {
        inputName: 'state',
        defaultValue: patient.postalAddressState,
        graphArgumentKey: 'postalAddressState',
      },
    };

    // Convert the postal same as residential to one of the ids for selected value
    const defaultSelectedSegment = postalAddressSameAsResidential ? '1' : '2';

    let isMandatory = true;

    if (region === Region.UK && patient.registrationReason) {
      isMandatory = patient.registrationReason !== 'New Patient for Outpatient Visit';
    }

    const { manualAddressEntry } = this.state;

    return (
      <div className="reg-address-form">
        <div className="form-page">
          <form className="form-container">
            <div className="form-heading basic-details">{FORM_HEADING}</div>
            {!manualAddressEntry && (
              <div>
                <SectionField
                  htmlFor="residential-autocomplete"
                  title={'Residential address'}
                  isValid={
                    isMandatory ? !this.state.autoCompleteBlankError && !this.state.autoCompleteInvalidError : true
                  }>
                  <AddressAutocomplete
                    placeholder={'Start typing'}
                    name="residential-autocomplete"
                    onSelected={this.handleAddressAutocomplete}
                    disabled={patient.lock && patient.lock.readOnly}
                    loadingMessage={'Loading...'}
                    errorMessage={isMandatory ? this.getAddressAutocompleteErrorMessage() : undefined}
                    defaultValue={patient.address && patient.address.formattedAddress}
                    inputOnBlur={this.handleAutoCompleteOnBlur}
                    region={region}
                  />
                </SectionField>
                <div
                  className="clickable-link manual-entry"
                  onClick={() => {
                    this.setState({ manualAddressEntry: true });
                  }}>
                  {"Can't find your address? Click here."}
                </div>
              </div>
            )}
            {manualAddressEntry && (
              <Fragment>
                <div className="address-row">
                  <Search className="icon" />
                  <div
                    className="clickable-link"
                    onClick={() => {
                      this.setState({
                        manualAddressEntry: false,
                        autoCompleteBlankError: false,
                        autoCompleteInvalidError: false,
                      });
                    }}>
                    {'Address lookup'}
                  </div>
                </div>
                <AddressField
                  isMandatory={isMandatory}
                  disabled={patient.lock && patient.lock.readOnly}
                  validateForm={this.state.validateAddressFields}
                  inputName="residential"
                  addressInputs={residentialAddressInput}
                  onBlur={(graphItems: [GraphUpdate]): void => {
                    this.autoSaveAddress(this.props.patient, graphItems, this.forceFetchItems);
                  }}
                  countries={countries}
                  ausStates={ausStates}
                />
              </Fragment>
            )}
            {region !== Region.UK ? (
              <SectionField htmlFor="segmented-input" title={'Postal address'}>
                <p className="postal-subtitle">{'Same as above'}</p>
                <SegmentedInput
                  disabled={patient.lock && patient.lock.readOnly}
                  options={[
                    { id: '1', name: 'Yes' },
                    { id: '2', name: 'No' },
                  ]}
                  fieldName="postal-selection"
                  defaultSelected={defaultSelectedSegment}
                  optionAreBoolean={true}
                  itemSelected={(selectedItem): void => {
                    const postalSameAsResidential = selectedItem as boolean;
                    const graphItems: [GraphUpdate] = [
                      { key: 'postalAddressSameAsResidential', value: postalSameAsResidential, type: 'Boolean' },
                    ];
                    this.autoSaveAddress(patient, graphItems);
                  }}
                />
              </SectionField>
            ) : null}
            {!postalAddressSameAsResidential && region !== Region.UK && (
              <AddressField
                isMandatory={isMandatory}
                disabled={patient.lock && patient.lock.readOnly}
                validateForm={this.state.validateAddressFields}
                inputName="postal"
                ausStates={ausStates}
                countries={countries}
                addressInputs={postalAddressInput}
                onBlur={(graphItems: [GraphUpdate]): void => {
                  this.autoSaveAddress(this.props.patient, graphItems, this.forceFetchItems);
                }}
              />
            )}
          </form>
        </div>
      </div>
    );
  }

  private autoSaveAddress(patient: Patient, graphItems: [GraphUpdate], forceFetch?: object[]): void {
    this.props.autosave(patient, graphItems, forceFetch);
  }

  private handleAddressAutocomplete = (components: AddressUkComponents): void => {
    const { patient, ausStates } = this.props;

    const tempPatientAddress: { [key: string]: string } = {
      residentialAddressLine1: '',
      residentialAddressLine2: '',
      residentialAddressCity: '',
      residentialAddressCountry: '',
      residentialAddressPostcode: '',
      residentialAddressState: '',
    };

    const countrymap = components.country === 'England' ? 'United Kingdom' : components.country;

    // if locality exists in address suggesstion, add it to line2
    const line2 = [components.line2, components.locality].filter((e) => e).join(', ');

    tempPatientAddress.residentialAddressLine1 = components.line1;
    tempPatientAddress.residentialAddressLine2 = line2;
    tempPatientAddress.residentialAddressCity = components.city;
    tempPatientAddress.residentialAddressPostcode = components.postcode;
    tempPatientAddress.residentialAddressState = components.county;

    const countryRef = this.props.countries.filter((value) => {
      if (value.name.toLowerCase() === countrymap.toLowerCase()) {
        return value;
      }
    });

    if (countryRef.length > 0) {
      tempPatientAddress.residentialAddressCountry = countryRef[0].id;
    } else {
      tempPatientAddress.residentialAddressCountry = countrymap;
    }

    const indexOfAustralia = this.props.countries.findIndex((value) => {
      if (value.id === tempPatientAddress.residentialAddressCountry && value.name.toLowerCase() === 'australia') {
        return true;
      }
      return false;
    });

    const indexOfUk = this.props.countries.findIndex((value) => {
      if (value.id === tempPatientAddress.residentialAddressCountry && value.name.toLowerCase() === 'united kingdom') {
        return true;
      }
      return false;
    });

    logger.debug('handleAddressAutocomplete', 'The temp patient address is: ', JSON.stringify(tempPatientAddress));

    const graphItems = Object.keys(tempPatientAddress).map((key) => {
      return {
        key,
        value: tempPatientAddress[key],
        type: 'String',
      };
    });

    this.autoSaveAddress(patient, graphItems as [GraphUpdate], this.forceFetchItems);
  };

  private handleAutoCompleteOnBlur = (e: React.FocusEvent<HTMLInputElement> | undefined, hasError: boolean): void => {
    const { patient } = this.props;
    if (e && e.target && e.target.value.trim() === '') {
      // The input field is now empty, so update the residential address to be empty
      const tempPatientAddress: { [key: string]: string } = {
        residentialAddressLine1: '',
        residentialAddressLine2: '',
        residentialAddressCity: '',
        residentialAddressCountry: '',
        residentialAddressPostcode: '',
        residentialAddressState: '',
      };

      const graphItems = Object.keys(tempPatientAddress).map((key) => {
        return {
          key,
          value: tempPatientAddress[key],
          type: 'String',
        };
      });

      this.setState({ autoCompleteBlankError: true, autoCompleteInvalidError: false });
      this.autoSaveAddress(patient, graphItems as [GraphUpdate], this.forceFetchItems);
    } else {
      this.setState({ autoCompleteBlankError: false, autoCompleteInvalidError: hasError });
    }
  };

  private getAddressAutocompleteErrorMessage = (): string[] | undefined => {
    let errorMessage;

    if (this.state.autoCompleteBlankError) {
      errorMessage = 'Please complete your address.';
    } else if (this.state.autoCompleteInvalidError) {
      errorMessage = 'Cannot find address';
    }

    if (errorMessage) {
      return [errorMessage];
    }
    return undefined;
  };
}
export default RegistrationAddress;
