import React, { useCallback } from 'react';
import SideBarCard from 'op-pages/MO/Careplan/RightSideBar/SideBarCard';
import { ModeCommentOutlined } from '@mui/icons-material';
import { Field, Form, Formik } from 'formik';
import AutoSave from 'shared-components/components/FormikComponents/AutoSave';
import TextAreaField from 'shared-components/components/FormikComponents/TextAreaField';
import { useMutation } from '@apollo/client';
import { SAVE_COMMENTS } from '../Queries';
import { styled } from '@mui/system';

interface CommentsProps {
  careplanId: string;
  comments: string;
}

const StyledCommentIcon = styled(ModeCommentOutlined)`
  path {
    width: 20px;
    height: 20px;
  }
`;
export const PrescriptionComments = ({ careplanId, comments }: CommentsProps) => {
  const [saveComments] = useMutation(SAVE_COMMENTS, { refetchQueries: ['latestCycles'] });

  const saveMethod = useCallback((values: any) => {
    saveComments({ variables: { careplanId: careplanId, comments: values.comments } });
  }, []);

  return (
    <SideBarCard icon={<StyledCommentIcon />} title="Additional prescription comments" subTitle={''}>
      <Formik initialValues={{ comments: comments }} onSubmit={() => {}}>
        <Form>
          <Field component={TextAreaField} name={'comments'} multiline rows={3} />
          <AutoSave saveMethod={saveMethod} />
        </Form>
      </Formik>
    </SideBarCard>
  );
};
