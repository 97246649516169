import { CustomTheme, Typography } from '@mui/material';
import { useTheme } from '@mui/material';
import React from 'react';

interface PopoverContentsCreatePatientProps {
  handleVisitLink: () => void;
}

export const PopoverContentsCreatePatient = (props: PopoverContentsCreatePatientProps): JSX.Element => {
  const theme: CustomTheme = useTheme();
  const title = theme.custom.title;
  return (
    <>
      <Typography variant="h6" id="onboarding-title" sx={{ fontWeight: 700 }}>
        Create patient record from{' '}
        <span id="onboarding-popover-new">
          <span>{title}</span>
          <span style={{ color: theme.palette.warning.dark, fontSize: '14px', verticalAlign: 'super' }}>NEW</span>
        </span>
      </Typography>
      <Typography variant="body2" id="onboarding-popover-textarea">
        <p>You can now create a new patient record directly from {title}.</p>
        <p>
          The feature conducts a duplicate record search and if none are found, creates a new patient record both in
          {title} and Mosaiq.
        </p>
        <br />
        <p>
          Click{' '}
          <span onClick={props.handleVisitLink} style={{ color: theme.palette.info.main }}>
            LATEST UPDATE
          </span>{' '}
          to learn more about this feature.
        </p>
      </Typography>
    </>
  );
};
