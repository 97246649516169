export const usRegoSteps = [
  {
    key: 'pi',
    header: 'Patient Info',
    short: 'PI',
    steps: [
      { idx: 0, text: 'Basic details', page: 'basic' },
      { idx: 1, text: 'Contact details', page: 'contact' },
      { idx: 2, text: 'Address', page: 'address' },
      { idx: 3, text: 'Emergency contact', page: 'emergencyContact' },
    ],
  },
  {
    key: 'mc',
    header: 'Referrers & Insurance',
    short: 'MC',
    steps: [
      { idx: 4, text: 'Referrers', page: 'referrers' },
      { idx: 5, text: 'Insurance & other benefits', page: 'insurance' },
    ],
  },
  {
    key: 'de',
    header: 'Demographics',
    short: 'DE',
    steps: [
      { idx: 6, text: 'Demographic details', page: 'demographics' },
      { idx: 7, text: 'Social geographic history', page: 'socialHistory' },
      { idx: 8, text: 'Your feedback', page: 'feedback' },
    ],
  },
  {
    key: 'su',
    header: 'Submission',
    short: 'SU',
    steps: [{ idx: 9, text: 'Review & submit', page: 'summary' }],
  },
];

export const createPatientSteps = [
  {
    key: 'cp',
    header: 'Create New Patient',
    short: 'CP',
    steps: [{ idx: 0, text: 'Basic details', page: 'create' }],
  },
];

export const auRegoSteps = [
  {
    key: 'pi',
    header: 'Patient Info',
    short: 'PI',
    steps: [
      { idx: 0, text: 'Information notice', page: 'infonotice' },
      { idx: 1, text: 'Basic details', page: 'basic' },
      { idx: 2, text: 'Contact details', page: 'contact' },
      { idx: 3, text: 'Address', page: 'address' },
      { idx: 4, text: 'Alternate contacts', page: 'emergencyContact' },
    ],
  },
  {
    key: 'mc',
    header: 'Referrers & Insurance',
    short: 'MC',
    steps: [
      { idx: 5, text: 'Referrers', page: 'referrers' },
      { idx: 6, text: 'Health insurance', page: 'insurance' },
    ],
  },
  {
    key: 'de',
    header: 'Information',
    short: 'DE',
    steps: [
      { idx: 7, text: 'Demographics', page: 'demographics' },
      { idx: 8, text: 'Document upload', page: 'upload' },
    ],
  },
  {
    key: 'su',
    header: 'Submission',
    short: 'SU',
    steps: [{ idx: 9, text: 'Review & submit', page: 'summary' }],
  },
];
