// eslint-disable-next-line no-use-before-define
import { gql } from '@apollo/client';
import { Query } from '@apollo/client/react/components';
import { WithApolloClient, withApollo } from '@apollo/client/react/hoc';
import { Component, Fragment } from 'react';

import { RouteComponentProps } from 'react-router';

import { ExtendLock } from 'op-components';
import { BASE_REGISTRATION_PAGES } from 'op-enums';
import { OPUser, PatientCoverage } from 'op-interfaces';
import { LoadingSpinner } from 'shared-components/components';
import { SavingStatus } from 'shared-components/enums';
import { ListData } from 'shared-components/interfaces';

import withRegistrationForm, { GET_APOLLO_CACHE, WithRegistrationForm } from '../RegistrationForm';
import RegistrationInsurance from './RegistrationInsurance';

export const PATIENT_AND_USER_DETAILS_QUERY = gql`
  query PatientAndUserDetails($id: ID!) {
    patient(id: $id) {
      id
      isProd
      ida
      idb
      firstName
      lastName
      dob
      address {
        id
        ukFormattedAddress
      }
      lock {
        lockedBy
        readOnly
        lockedByName
      }
      payor
      policyNumber
      coverageRelationship
      preAuthNumber
    }
    user {
      id
      isPso
    }
    genderRefData: listData(category: "gender") {
      id
      name
    }
    insurerRefData: departments {
      id
      name
    }
    relationshipRefData: listData(category: "coverageRelationship") {
      id
      name
    }
  }
`;

interface State {
  saveStatus: SavingStatus;
  pageViewed: boolean;
}

interface PatientAndUserDetailsQueryData {
  patient: PatientCoverage;
  genderRefData: ListData[];
  nameTitle: ListData[];
  insurerRefData: ListData[];
  relationshipRefData: ListData[];
  user: OPUser;
}

interface Props
  extends WithApolloClient<{}>,
    WithRegistrationForm,
    RouteComponentProps<{
      patientId?: string;
    }> {}

class RegistrationInsuranceApollo extends Component<Props, State> {
  private userIsPSO = false;

  public constructor(props: Props) {
    super(props);
    this.state = { saveStatus: SavingStatus.SAVED, pageViewed: false };
  }

  public componentDidMount(): void {
    const { client } = this.props;
    let viewedPages: string[] = [];

    // Get the apollo cache values
    this.getApolloCache().then((apolloCache): void => {
      const currentPendingSaveCount = apolloCache.currentPendingSaveCount;
      const saveErrorCount = apolloCache.saveErrorCount;
      const registrationPagesViewed = apolloCache.registrationPagesViewed;
      viewedPages = registrationPagesViewed;
      this.setState({
        saveStatus: this.props.getSaveStatus(currentPendingSaveCount, saveErrorCount),
        pageViewed: registrationPagesViewed.includes(BASE_REGISTRATION_PAGES.INSURANCE),
      });

      if (!viewedPages.includes(BASE_REGISTRATION_PAGES.INSURANCE)) {
        viewedPages = [...viewedPages, BASE_REGISTRATION_PAGES.INSURANCE];
      }

      client &&
        client.writeQuery({
          query: gql`
            query {
              registrationPagesViewed
            }
          `,
          data: {
            registrationPagesViewed: viewedPages,
          },
        });
    });
  }

  public render(): JSX.Element {
    const { match, showModalIfLocked } = this.props;
    const { patientId } = match.params;
    return (
      <Query<PatientAndUserDetailsQueryData>
        query={PATIENT_AND_USER_DETAILS_QUERY}
        variables={{ id: patientId }}
        onCompleted={(data: { patient: PatientCoverage }): void => {
          showModalIfLocked(data);
        }}>
        {({ loading, data, error }): JSX.Element => {
          if (loading) {
            return <LoadingSpinner />;
          }

          if (data && data.patient) {
            // We have a patient - render the form
            const modifiedPatient = { ...data.patient };

            this.userIsPSO = data.user.isPso || false;

            return (
              <Fragment>
                <ExtendLock accessPatientId={data.patient.id} />
                <RegistrationInsurance
                  autosave={this.autosave}
                  coverage={modifiedPatient}
                  genderRefData={data.genderRefData}
                  insurerRefData={data.insurerRefData}
                  relationshipRefData={data.relationshipRefData}
                  saveStatus={this.state.saveStatus}
                  validateOnLoad={this.state.pageViewed}
                  isPso={this.userIsPSO}
                />
              </Fragment>
            );
          }

          // If we get here there is an error
          if (error) return <div>{`Error loading: ${error}`}</div>;
          return <div />;
        }}
      </Query>
    );
  }

  /**
   * Autosave a field and it's values to the database
   * @param patient
   * @param key
   * @param value
   * @param type
   */
  private autosave = async (patient: PatientCoverage, key: string, value: string, type = 'String'): Promise<void> => {
    const client = this.props.client;
    const update = {
      key,
      value,
      type,
    };

    // Get the pending save count and increment it by 1
    const apolloCache = await this.getApolloCache();
    let currentPendingSaveCount = apolloCache.currentPendingSaveCount + 1;
    let saveErrorCount = apolloCache.saveErrorCount;

    client &&
      client.writeQuery({
        query: gql`
          query {
            pendingSaveCount
          }
        `,
        data: {
          pendingSaveCount: currentPendingSaveCount,
        },
      });
    this.setState({ saveStatus: this.props.getSaveStatus(currentPendingSaveCount, saveErrorCount) });

    // Save the updated data. Once done deincrement the pending save count. If any erros saving increment the saveErrorCount
    return (
      client
        //@ts-ignore
        ?.mutate(this.props.getPatientMutation(patient, [update]))
        .then((result): void => {
          if (result.data.updatePatient.errors) {
            saveErrorCount++;
          }
        })
        .catch((): void => {
          this.props.showSavingErrorModal(this.userIsPSO, this.props.history.push);
          saveErrorCount++;
        })
        .finally(async (): Promise<void> => {
          // Reload the save count incase it was set elsewhere during the run of this function
          const apolloCache = await this.getApolloCache();
          currentPendingSaveCount = apolloCache.currentPendingSaveCount - 1;
          client &&
            client.writeQuery({
              query: gql`
                query {
                  pendingSaveCount
                  saveErrorCount
                }
              `,
              data: {
                pendingSaveCount: currentPendingSaveCount,
                saveErrorCount: saveErrorCount,
              },
            });
          this.setState({ saveStatus: this.props.getSaveStatus(currentPendingSaveCount, saveErrorCount) });
        })
    );
  };

  private getApolloCache = async (): Promise<{
    currentPendingSaveCount: number;
    saveErrorCount: number;
    registrationPagesViewed: string[];
  }> => {
    const { client } = this.props;
    try {
      const apolloCache = await client?.query({ query: GET_APOLLO_CACHE });
      const currentPendingSaveCount = apolloCache?.data.pendingSaveCount;
      const saveErrorCount = apolloCache?.data.saveErrorCount;
      const registrationPagesViewed = apolloCache?.data.registrationPagesViewed;
      return {
        currentPendingSaveCount: currentPendingSaveCount,
        saveErrorCount: saveErrorCount,
        registrationPagesViewed: registrationPagesViewed,
      };
    } catch (error) {
      throw error;
    }
  };
}

const apolloComponent = withApollo<Props>(RegistrationInsuranceApollo);
const component = withRegistrationForm(apolloComponent);
export default component;
