import { gql } from '@apollo/client';

export const GET_PATIENT_CAREPLAN_LIST = gql`
  query CareplanList($patientId: ID!) {
    careplanList(patientId: $patientId) {
      id
      careplanStatus
      submittedAt
      simulationModification
      prescriptionModification
      diagnosisModification
      latestPageUrl
      diagnosis {
        id
        diagnosisCode {
          id
          diagnosisCode
          diagnosisDescription
        }
      }
      sitegroupSet {
        orderRanking
        id
        siteSet {
          id
          orderRanking
          treatmentSite {
            id
            treatmentSite
          }
          sitevaluesSet {
            id
            field {
              id
              name
            }
            value
          }
        }
      }
    }
  }
`;

export const GET_NOTE_TYPES = gql`
  query notesTypes($patientId: ID!) {
    notesTypes(patientId: $patientId) {
      id
      alias
      name
      mosaiqId
      fallbackMosaiqId
      listWeight
    }
  }
`;

export const ADD_PATIENT_NOTE = gql`
  mutation SubmitNote($patientId: ID!, $message: String!, $messageType: String!, $noteId: ID) {
    submitNote(patientId: $patientId, message: $message, messageType: $messageType, noteId: $noteId) {
      noteCreated
    }
  }
`;

export const UPDATE_PATIENT_NOTE = gql`
  mutation UpdateNote($patientId: ID!, $message: String!, $messageType: String!, $noteId: ID) {
    updateNote(patientId: $patientId, message: $message, messageType: $messageType, noteId: $noteId) {
      note {
        id
        title
        formattedMessage
        messageType
        messageTypeAlias
        practitioner
        status
        createdAt
        updatedAt
        isDraft
        createdBy {
          id
          name
        }
      }
    }
  }
`;

export const GET_PATIENT_DOCUMENTS = gql`
  query documents($patientId: ID!) {
    documents(patientId: $patientId) {
      id
      status
      type
      description
      dateOfDocument
      docUrl
      patient {
        id
      }
    }
  }
`;

export const PREFETCH_PATIENT_DOCUMENTS = gql`
  query prefetchDocuments($patientId: ID!) {
    prefetchDocuments(patientId: $patientId) {
      success
    }
  }
`;

export const GET_PATIENT_DOCUMENT = gql`
  query document($documentId: ID!) {
    document(documentId: $documentId) {
      id
      status
      type
      description
      dateOfDocument
      docUrl
      hash: finkPath
    }
  }
`;

export const GET_DISTRESS_THERMOMETER_PDF = gql`
  query dtPdf($patientId: ID!) {
    dtPdf(patientId: $patientId) {
      id
      status
      type
      description
      dateOfDocument
      docUrl
      patient {
        id
      }
    }
  }
`;

export const GET_DOSE_SITE_SUMMARIES = gql`
  query getDoseSiteSummaries($patientId: ID!) {
    doseSiteSummaries(patientId: $patientId) {
      id
      isAria
      firstTreatment
      latestTreatment
      deliveredDosageQuantity
      perFractionDoseQuantity
      plannedDosageQuantity
      remainingDoseQuantity
      rxFxUniformQuantity
      totalDosageLimit
      deliveredDosageRatio
      plannedDosageRatio
      categorySiteGroup
      identifierSite
      emrDiagnosisKey
      medicationReference
      status
      statusReason
      technique
      endDate
    }
  }
`;

export const GET_PATIENT_LAB_PANELS = gql`
  query labPanels($patientId: ID!) {
    labPanels(patientId: $patientId) {
      id
      status
      panelName
      observationDate
      observations {
        id
        loincCode
        abnormalFlags
        referenceRange
        resultValue
        unitOfMeasure
        cleanedNote
        description
      }
    }
  }
`;

export const GET_PATIENT_PROFILE = gql`
  query patient($patientId: ID!) {
    patient(id: $patientId) {
      id
      emrInstance
      userProfile {
        id
        hasPatId1
      }
    }
  }
`;
