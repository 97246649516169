import React from 'react';
import { useFormikContext, Field, FormikProps } from 'formik';

import { DTRatingItem } from 'op-classes';
import { HAIssueItem, InfoCardRowItem } from 'op-interfaces';
import { InfoCard } from 'op-components';
import { yesNoOptions } from 'op-utils';
import { FormRow, HelperMessage } from 'shared-components/components';
import { ToggleButtonGroupField } from 'shared-components/components/FormikComponents';

import {
  choiceToBooleanOptions,
  choiceToStringOptions,
  formatDtRating,
  getLastUpdateUser,
  transformEmptyString,
} from '../../helpers';
import { LastUpdateUserDetails } from '../../shared/interfaces';
import { preCtChartCheckContent } from '../constants';
import { HealthAssessmentData, PreCtChartCheckFormValues } from '../interfaces/PreCtChartCheckInterfaces';

interface Props {
  distressThermometerData: DTRatingItem;
  handleMutation: (name: string, value: string) => void;
  healthAssessmentData: HealthAssessmentData;
  lastUpdateUserData: LastUpdateUserDetails[];
}

const Wellbeing = ({
  distressThermometerData,
  handleMutation,
  healthAssessmentData,
  lastUpdateUserData,
}: Props): JSX.Element => {
  const { values }: FormikProps<PreCtChartCheckFormValues> = useFormikContext();
  const { staffConcern, anxietyClaustrophobia } = preCtChartCheckContent.wellbeing.fields;
  const { dtScoreTitle, hasWellbeingIssueTitle, wellbeingIssueTitle } = preCtChartCheckContent.wellbeing.tableTitles;
  const anxietyClaustrophobiaMessage = preCtChartCheckContent.wellbeing.infoHelpers.anxietyClaustrophobiaMessage;
  const distressRating = formatDtRating(distressThermometerData);

  let hasWellbeingIssue;
  let wellbeingIssue;
  const wellbeingStaticValues: InfoCardRowItem[] = [];

  if (healthAssessmentData.healthAssessment) {
    //@ts-ignore
    hasWellbeingIssue = choiceToBooleanOptions[String(healthAssessmentData.healthAssessment.wellbeingIssue)];
    wellbeingIssue = healthAssessmentData.healthAssessment.wellbeingIssue;

    if (hasWellbeingIssue) {
      wellbeingStaticValues.push({
        title: hasWellbeingIssueTitle,
        //@ts-ignore
        value: choiceToStringOptions[String(wellbeingIssue)],
      });
    }
  }

  if (distressRating) {
    wellbeingStaticValues.push({
      title: dtScoreTitle,
      value: distressRating ? distressRating : 'N/A',
    });
  }

  let wellbeingIssueValues: InfoCardRowItem[] = [];

  if (hasWellbeingIssue === true) {
    const wellbeingIssues = healthAssessmentData.healthAssessment.wellbeingIssues;
    const refData = healthAssessmentData.wellbeingIssueRefData;

    wellbeingIssueValues = wellbeingIssues.map((issue: HAIssueItem, index: number) => {
      const other = issue.other;
      const value = transformEmptyString(refData.find((ref) => ref.id === issue.value)?.name);
      const title = `${wellbeingIssueTitle} ${index + 1}`;

      return {
        title: title,
        value: other ? other : value,
      };
    });
  }

  const tableRows: InfoCardRowItem[][] = [[...wellbeingStaticValues, ...wellbeingIssueValues]];

  return (
    <>
      <FormRow fieldLabel={staffConcern} fieldName={'staffConcern'}>
        <Field
          name="staffConcern"
          component={ToggleButtonGroupField}
          label={staffConcern}
          helperType="error"
          options={yesNoOptions}
          handleMutation={handleMutation}
          lastUpdateUser={getLastUpdateUser(lastUpdateUserData, 'staffConcern')}
        />
      </FormRow>
      {(wellbeingStaticValues.length > 0 || wellbeingIssueValues.length > 0) && (
        <InfoCard title="Patient Reported" subTitle="(Health Assessment/Distress Thermometer)" rowDetails={tableRows} />
      )}
      <FormRow fieldLabel={anxietyClaustrophobia} fieldName={'anxietyClaustrophobia'}>
        <Field
          name="anxietyClaustrophobia"
          component={ToggleButtonGroupField}
          label={anxietyClaustrophobia}
          helperType="error"
          options={yesNoOptions}
          handleMutation={handleMutation}
          lastUpdateUser={getLastUpdateUser(lastUpdateUserData, 'anxietyClaustrophobia')}
        />
        {values.anxietyClaustrophobia === true && (
          <HelperMessage
            fieldName={'anxietyClaustrophobia'}
            fieldText={anxietyClaustrophobiaMessage}
            helperType="info"
          />
        )}
      </FormRow>
    </>
  );
};

export default Wellbeing;
