import React, { createContext, useState } from 'react';

export const ROPatientContext = createContext<any>({});

export const ROPatientContextProvider = ({ children }: any): JSX.Element => {
  const [patientDetails, setPatientDetails] = useState<any>();
  const [patientAlerts, setPatientAlerts] = useState<any>();
  return (
    <ROPatientContext.Provider
      value={{
        patientDetails,
        setPatientDetails,
        patientAlerts,
        setPatientAlerts,
      }}>
      {children}
    </ROPatientContext.Provider>
  );
};
