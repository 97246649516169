import { useMutation, useQuery } from '@apollo/client';
import { ROBase } from 'op-components';
import SidePatientNav from 'op-components/SideNav/SidePatientNav';
import { useErrorModalContext } from 'op-contexts';
import { QUERY_NURSE } from 'op-pages/OP/PatientFormSummary/PatientFormSummaryQueries';
import { ROPatientContextProvider } from 'op-pages/RO/PatientSummary/context';
import { default as React, useEffect } from 'react';
import { generatePath, Route, Switch, useParams, useRouteMatch } from 'react-router';
import { useHistory } from 'react-router-dom';
import { LoadingSpinner } from 'shared-components/components';
import { styled } from '@mui/system';
import { STATUS_IN_PROGRESS, STATUS_SUBMITTED } from './common';
import {
  ALL_MANAGEMENT_PLAN_QUERY,
  CREATE_MANAGEMENT_PLAN,
  CREATE_REFERRAL_EXISTING_SERVICE,
} from './graphql/ManagementPlanQueries';
import { PREFETCH_PATIENT_DOCUMENTS } from 'op-pages/RO/PatientSummary/PatientProfileQueries';
import ConsultationPage from './pages/ConsultationPage';
import ManagementSearchPage from './pages/ManagementSearchPage';
import ReferralPage from './pages/ReferralPage';
import { formRoutes } from './routes';
import { Stack } from '@mui/material';

const MiddleContainerCol = styled(Stack)`
  display: flex;
  max-height: 100%;
  height: 100%;
  width: 100%;

  #form-footer {
    @media screen and (min-width: 767px) and (max-width: 1919px) {
      width: 100%;
      position: relative;
      right: 0;
    }
  }
`;

const StyledSidePatientNavCol = styled(Stack)`
  max-width: 240px;
  min-width: 240px;
  height: 100%;
  background-color: ${(props) => props.theme.palette.grey[100]};
  border-right: 1px solid rgba(0, 0, 0, 0.2);
`;

const StyledSidePatientNav = styled(SidePatientNav)`
  max-width: 240px;
  min-width: 240px;
  height: 100vh;
`;

const ManagementPlan = (): JSX.Element => {
  const history = useHistory();
  const { patientId } = useParams<{ patientId: string }>();
  const { setError } = useErrorModalContext();

  // Prefetch all documents for patient so that DT and DT EOT PDFs are ready if needed
  useQuery(PREFETCH_PATIENT_DOCUMENTS, {
    variables: { patientId: patientId },
    skip: !patientId,
    fetchPolicy: 'network-only',
  });

  const {
    loading: patientDetailsLoading,
    data: patientDetailsData,
    error: patientDetailsError,
  } = useQuery(QUERY_NURSE, {
    variables: { id: patientId },
  });

  const {
    loading: managementPlanLoading,
    data: managementPlanData,
    error: managementPlanError,
  } = useQuery(ALL_MANAGEMENT_PLAN_QUERY, {
    variables: { patientId },
  });

  useEffect(() => {
    if (patientDetailsError || managementPlanError) return setError();
  }, [patientDetailsError, managementPlanError]);

  const { path } = useRouteMatch();
  const currentPage = window.location.pathname.split('/').slice(-1)[0];
  const showButtons = currentPage === 'management';

  const [createManagementPlan] = useMutation(CREATE_MANAGEMENT_PLAN, {
    awaitRefetchQueries: true,
    refetchQueries: [{ query: ALL_MANAGEMENT_PLAN_QUERY, variables: { patientId } }],
  });

  const [createReferralExistingService] = useMutation(CREATE_REFERRAL_EXISTING_SERVICE, {
    awaitRefetchQueries: true,
    refetchQueries: [{ query: ALL_MANAGEMENT_PLAN_QUERY, variables: { patientId } }],
  });

  const patient = patientDetailsData?.patient;
  const plans = managementPlanData?.allManagementPlan;

  const isDataLoading = patientDetailsLoading || managementPlanLoading; // TEMP
  const isDataAvailable = patient; // TEMP

  const disableCreateButton = plans?.some((plan: any) => [STATUS_IN_PROGRESS, STATUS_SUBMITTED].includes(plan.status));

  const createPlan = () =>
    createManagementPlan({ variables: { patientId, planType: 'Wellbeing' } }).then(({ data }) => {
      const formId = data?.createManagementPlan.managementPlan.id;
      history.push(generatePath(formRoutes.consultation.path, { patientId, formId }));
    });

  const createReferralService = () =>
    createReferralExistingService({ variables: { patientId } }).then(({ data }) => {
      const formId = data?.createReferralExistingService.referralExistingService.id;
      history.push(generatePath(formRoutes.referral.path, { patientId, formId }));
    });

  const consultationListData = {
    timepointOptions: managementPlanData?.timepointOptions || [],
    scoreOptions: managementPlanData?.scoreOptions || [],
    checkInStatusOptions: managementPlanData?.checkInStatusOptions || [],
  };
  const referralListData = { serviceTypeOptions: managementPlanData?.serviceTypeOptions || [] };

  return (
    <ROBase>
      <div style={{ height: '100%' }}>
        {isDataLoading && <LoadingSpinner />}
        {isDataAvailable && (
          <ROPatientContextProvider>
            <Stack sx={{ display: 'flex', flexDirection: 'row', height: '100%' }}>
              <StyledSidePatientNavCol className="px-0 d-none d-lg-block">
                <StyledSidePatientNav
                  patientId={patientId}
                  createManagementPlan={createPlan}
                  createReferralExistingService={createReferralService}
                  disableCreateButton={disableCreateButton}
                  showButtons={showButtons}
                />
              </StyledSidePatientNavCol>

              <MiddleContainerCol className="px-0">
                <Switch>
                  <Route exact path={path}>
                    <ManagementSearchPage patient={patient} plans={plans} />
                  </Route>
                  <Route path={formRoutes.consultation.path}>
                    <ConsultationPage patient={patient} listData={consultationListData} />
                  </Route>
                  <Route path={formRoutes.referral.path}>
                    <ReferralPage patient={patient} listData={referralListData} />
                  </Route>
                </Switch>
              </MiddleContainerCol>
            </Stack>
          </ROPatientContextProvider>
        )}
      </div>
    </ROBase>
  );
};

export default ManagementPlan;
