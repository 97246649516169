// eslint-disable-next-line no-use-before-define
import { useMutation } from '@apollo/client';
import { Form, Formik, FormikProps, FormikValues } from 'formik';
import { FormContainer } from 'op-components';
import { mapListData, yesNoOptions } from 'op-utils';
import { RegistrationContext } from 'op-contexts';
import React, { useContext } from 'react';
import { generatePath } from 'react-router';
import { useHistory, useParams } from 'react-router-dom';
import { AddressSectionField } from 'shared-components/components/FormikComponents';
import { Address, ListData } from 'shared-components/interfaces';
import {
  CONTINUE_BUTTON_TEXT,
  EMPTY_ADDRESS,
  HeaderSubTitle,
  HeaderTitle,
  PSO_SUMMARY_LINK,
  registrationPath,
  RETURN_TO_SUBMIT_TEXT,
  sharedFormContainerProps,
  standardField,
  StyledIndentedSection,
} from '../Helper';
import { PatientSocialGeoHistRego, RegistrationFormUSIProps } from '../interfaces';
import { REMOVE_PATIENT_ADDRESS, UPDATE_PATIENT_ADDRESS } from '../RegistrationFormQueries';
import { SOCIAL_HISTORY_FIELDS } from './constants';
import { REGISTRATION_SOCIAL_GEO_HIST_QUERY } from './SocialGeographicHistoryQueries';
import { generateValidationSchema } from './validation';

interface SocialGeographicHistoryFormIProps extends RegistrationFormUSIProps {
  patient: PatientSocialGeoHistRego;
  readonly refData: {
    readonly countryRefData: ListData[];
  };
}

const SocialGeographicHistoryForm = (props: SocialGeographicHistoryFormIProps): JSX.Element => {
  const {
    updateField,
    patient: savedPatient,
    handleShowSaveExitConfirm,
    previousPageLink,
    isPso,
    refData: { countryRefData },
  } = props;

  const registrationContext = useContext(RegistrationContext);
  const history = useHistory();
  const { patientId } = useParams<{ patientId: string }>();
  const goToSummary = registrationContext?.registrationSummaryVisited && !isPso;
  const referringPage = sessionStorage.getItem('referringPage');

  const [updatePatientAddressMutation] = useMutation(UPDATE_PATIENT_ADDRESS, {
    refetchQueries: [{ query: REGISTRATION_SOCIAL_GEO_HIST_QUERY, variables: { id: patientId } }],
  });
  const [removePatientAddressMutation] = useMutation(REMOVE_PATIENT_ADDRESS, {
    refetchQueries: [{ query: REGISTRATION_SOCIAL_GEO_HIST_QUERY, variables: { id: patientId } }],
  });

  const getDisplayValue = (refData: ListData[], option: string): string => {
    const filtered = refData.filter((item: ListData) => item.id === option);
    return filtered.length ? filtered[0].name : option;
  };

  const patient = {
    ...savedPatient,
    countryOfBirth: getDisplayValue(countryRefData, savedPatient.countryOfBirth),
  };

  return (
    <>
      <Formik
        initialValues={patient}
        validate={(values: FormikValues) => generateValidationSchema(values)}
        validateOnChange={false}
        onSubmit={() => history.push(registrationPath(patientId, goToSummary ? 'summary' : 'feedback'))}>
        {({ submitForm, errors, values, setFieldValue }: FormikProps<PatientSocialGeoHistRego>) => {
          const sharedProps = sharedFormContainerProps('Social Geographic History', 8, previousPageLink, !isPso);
          const formContainerProps = {
            ...sharedProps,
            submitButtonText: goToSummary ? RETURN_TO_SUBMIT_TEXT : CONTINUE_BUTTON_TEXT,
            continueDisabled: Object.keys(errors).length > 0,
            handleShowSaveExitConfirm,
            saveAndExitLink: isPso && generatePath(referringPage || PSO_SUMMARY_LINK, { patientId }),
          };

          const removeAddress = () => {
            const addressString = 'alternateAddress';
            setFieldValue(`${addressString}.country`, '');
            setFieldValue(`${addressString}.line1`, '');
            setFieldValue(`${addressString}.line2`, '');
            setFieldValue(`${addressString}.city`, '');
            setFieldValue(`${addressString}.state`, '');
            setFieldValue(`${addressString}.postcode`, '');
            removePatientAddressMutation({
              variables: {
                patientId,
                relatedName: 'alternate_address',
              },
            });
          };

          const updateInState = (name: string, value: string | boolean | number) => {
            updateField(SOCIAL_HISTORY_FIELDS.IN_STATE_ALL_YEAR_BOOL.NAME, value);
            if (value) {
              setFieldValue(SOCIAL_HISTORY_FIELDS.ALTERNATE_PHONE.NAME, '');
              removeAddress();
            }
          };

          const handleUpdateAddress = (relatedName: string, address: Address) => {
            updatePatientAddressMutation({
              variables: {
                patientId,
                relatedName,
                line1: address?.line1 ?? '',
                line2: address?.line2 ?? '',
                city: address?.city ?? '',
                postcode: address?.postcode ?? '',
                state: address?.state ?? '',
                country: address?.country ?? '',
              },
            });
          };

          return (
            <FormContainer
              {...formContainerProps}
              submitForm={submitForm}
              handleValidation={(event: React.MouseEvent<HTMLInputElement>): void => {
                void event;
              }}>
              <Form>
                <HeaderTitle data-test-id="us-rego-page-title">{SOCIAL_HISTORY_FIELDS.FORM_HEADING.TITLE}</HeaderTitle>
                <HeaderSubTitle data-test-id="us-rego-page-subtitle">* = required field</HeaderSubTitle>

                {standardField(SOCIAL_HISTORY_FIELDS.COUNTRY_OF_BIRTH, updateField, mapListData(countryRefData))}

                {values.countryOfBirth === 'United States of America' && (
                  <StyledIndentedSection>
                    {standardField(SOCIAL_HISTORY_FIELDS.STATE_OF_BIRTH, updateField)}
                  </StyledIndentedSection>
                )}

                {standardField(SOCIAL_HISTORY_FIELDS.AREA_LIVED_MOST, updateField)}
                {standardField(SOCIAL_HISTORY_FIELDS.CURRENT_STATE_HOW_LONG, updateField)}
                {standardField(SOCIAL_HISTORY_FIELDS.IN_STATE_ALL_YEAR_BOOL, updateInState, yesNoOptions)}

                {values.inThisStateAllYear === false && (
                  <StyledIndentedSection>
                    <AddressSectionField
                      name="alternateAddress"
                      values={values.alternateAddress || EMPTY_ADDRESS}
                      updateAddress={(address: Address) => handleUpdateAddress('alternate_address', address)}
                    />

                    {standardField(SOCIAL_HISTORY_FIELDS.ALTERNATE_PHONE, updateField)}
                  </StyledIndentedSection>
                )}
              </Form>
            </FormContainer>
          );
        }}
      </Formik>
    </>
  );
};

export default SocialGeographicHistoryForm;
