import { gql } from '@apollo/client';
import { HEALTH_ASSESSMENT_OPERATION_FRAGMENT } from 'op-graphql/fragments';

export const HA_OPERATIONS_QUERY = gql`
  query HealthAssessment($patientId: ID!) {
    healthAssessment(id: $patientId) {
      id
      prevOperation
      operations {
        id
        ...HealthAssessmentOperation
      }
    }
    operationsRefData: listData(category: "operations") {
      id
      name
    }
  }

  ${HEALTH_ASSESSMENT_OPERATION_FRAGMENT}
`;
