import { gql } from '@apollo/client';

export const EMERGENCY_CONTACT_QUERY = gql`
  query EmergencyContactQuery($id: ID!) {
    patient(id: $id) {
      id
      altContactProvided
      emergencyContact {
        id
        contactType
        relationship
        firstName
        lastName
        mobilePhoneNumber
        homePhoneNumber
        email
        authorisedForEnquiries
        supportPerson
      }
      nextOfKinContact {
        id
        contactType
        firstName
        lastName
        relationship
        mobilePhoneNumber
        homePhoneNumber
        email
        authorisedForEnquiries
        supportPerson
      }
    }
    relationshipsRefData: listData(category: "relationships") {
      id
      name
    }
    user {
      id
      isPso
    }
  }
`;

export const UPDATE_EMERGENCY_CONTACT = gql`
  mutation UpdateEmergencyContactAu(
    $id: ID!
    $firstName: String
    $lastName: String
    $relationship: String
    $mobilePhoneNumber: String
    $homePhoneNumber: String
    $email: String
    $authorisedForEnquiries: Boolean
    $supportPerson: Boolean
    $altContactProvided: Boolean
    $contactType: String
  ) {
    updateEmergencyContactAu(
      id: $id
      firstName: $firstName
      lastName: $lastName
      relationship: $relationship
      mobilePhoneNumber: $mobilePhoneNumber
      homePhoneNumber: $homePhoneNumber
      email: $email
      authorisedForEnquiries: $authorisedForEnquiries
      supportPerson: $supportPerson
      altContactProvided: $altContactProvided
      contactType: $contactType
    ) {
      patient {
        id
        altContactProvided
        emergencyContact {
          id
          contactType
          relationship
          firstName
          lastName
          mobilePhoneNumber
          homePhoneNumber
          email
          authorisedForEnquiries
          supportPerson
        }
        nextOfKinContact {
          id
          contactType
          firstName
          lastName
          relationship
          mobilePhoneNumber
          homePhoneNumber
          email
          authorisedForEnquiries
          supportPerson
        }
      }
    }
  }
`;
