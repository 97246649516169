// eslint-disable-next-line no-use-before-define
import React, { Component, Fragment, useEffect } from 'react';
import { NetworkStatus, gql } from '@apollo/client';
import { RouteComponentProps, withRouter } from 'react-router-dom';
import { ApolloSaveStatus } from 'op-types';
import { Query } from '@apollo/client/react/components';

import { HAOperationsItem } from 'op-classes';
import { LoadingSpinner } from 'shared-components/components';
import { ApolloNetworkStatus } from 'shared-components/enums';
import { Dictionary, ListData } from 'shared-components/interfaces';
import { Logger } from 'shared-components/utils';

import HAOperations from './HAOperations';
import { HEALTH_ASSESSMENT_OPERATION_FRAGMENT } from 'op-graphql/fragments';
import withHealthAssessment, { WithHealthAssessment } from '../../HealthAssessment';
import { useErrorModalContext } from 'op-contexts';

const logger = new Logger('HAOperationsApollo');

// eslint-disable-next-line
const HEALTH_ASSESMENT_QUERY = gql`
  query HealthAssessment($id: ID!) {
    healthAssessment(id: $id) {
      id
      patient {
        id
        fullName
        dob
      }
      prevOperation
      operations {
        id
        ...HealthAssessmentOperation
      }
    }
    operationsRefData: listData(category: "operations") {
      id
      name
    }
  }

  ${HEALTH_ASSESSMENT_OPERATION_FRAGMENT}
`;

interface HealthAssessmentQueryData {
  healthAssessment: HAOperationsItem;
  operationsRefData: ListData[];
}

interface Props extends WithHealthAssessment, RouteComponentProps<{ patientId: string; haId: string }> {
  client: any;
  updateSaveStatus: ApolloSaveStatus;
  validateOnLoad: boolean;
}

class HAOperationsApollo extends Component<Props> {
  public render(): JSX.Element {
    const {
      match: {
        params: { patientId },
      },
      validateOnLoad,
    } = this.props;
    return (
      <Fragment>
        <Query<HealthAssessmentQueryData> query={HEALTH_ASSESMENT_QUERY} variables={{ id: patientId }}>
          {({ error, loading, data, refetch, networkStatus }): JSX.Element => {
            const { setError } = useErrorModalContext();
            useEffect(() => {
              if (error) return setError();
            }, [error]);

            if (loading && networkStatus !== NetworkStatus.refetch) {
              return <LoadingSpinner relativeSpinner={true} />;
            }
            const sortFunction = (a: ListData, b: ListData): number => {
              if (a.name === 'Other') return 1;
              return a.name.localeCompare(b.name);
            };

            const sortedOperationsRefData = data?.operationsRefData
              ? [...data?.operationsRefData].sort(sortFunction)
              : [];

            if (data?.healthAssessment) {
              const haOperations = new HAOperationsItem().loadData(data.healthAssessment);
              return (
                <HAOperations
                  patientId={patientId}
                  autosave={this.autosave}
                  haOperations={haOperations}
                  validateOnLoad={validateOnLoad}
                  performCRUD={this.performOperation}
                  refetch={refetch}
                  operationsRefData={sortedOperationsRefData}
                />
              );
            }
            return <div></div>;
          }}
        </Query>
      </Fragment>
    );
  }

  private autosave = (updateObject: object, replaceId?: string, refetch?: any): Promise<void> => {
    const { mutateGraph, client, getSaveStatus, updateSaveStatus } = this.props;
    // Mutate the graph
    return mutateGraph(
      client,
      updateObject,
      () => {
        getSaveStatus(client).then((savingStatus) => {
          updateSaveStatus(savingStatus);
        });
      },
      () => {},
      () => {},
      async () => {
        const saveStatus = await getSaveStatus(client);
        updateSaveStatus(saveStatus);

        if (refetch !== undefined) {
          try {
            refetch();
          } catch (error) {
            logger.debug('Refetch failed', error);
          }
        }
      },
      replaceId,
    );
  };

  private performOperation = (
    haId: string,
    operation: string,
    objectType: string,
    itemId?: string,
    refetch?: any,
  ): void => {
    const { mutateCRUDGraph, client, getSaveStatus, updateSaveStatus } = this.props;

    // Build the update object
    const updateObject: Dictionary = {
      haId,
      objectType,
      operation,
    };

    if (itemId !== undefined && itemId !== '') {
      updateObject.id = itemId;
    }

    // Call the mutation to update the CRUD
    mutateCRUDGraph(
      client,
      updateObject,
      (): void => {
        getSaveStatus(client).then((savingStatus): void => {
          updateSaveStatus(savingStatus);
        });
      },
      (): void => {},
      (): void => {},
      async (): Promise<void> => {
        const saveStatus = await getSaveStatus(client);
        updateSaveStatus(saveStatus);
        if (refetch !== undefined) {
          try {
            refetch();
          } catch (error) {
            logger.debug('Refetch failed', error);
          }
        }
      },
    );
  };
}

const routedComponent = withRouter(HAOperationsApollo);
const haComponent = withHealthAssessment(routedComponent);
export default haComponent;
