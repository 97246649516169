// eslint-disable-next-line no-use-before-define
import { useMutation } from '@apollo/client';
import { WarningSharp } from '@mui/icons-material';
import { GET_PATIENT_NOTES } from 'op-components/RO/Notes/Notes';
import { CAREPLAN_STATUS } from 'op-pages/RO/Careplan/Common';
import { CurrentAppConfig } from 'op-pages/RO/Careplan/AppConfig';
import { Careplan, SiteGroupSet } from 'op-pages/RO/Careplan/Interface';
import { SOFT_DELETE_CAREPLAN } from 'op-pages/RO/Careplan/Queries';
import { GET_PATIENT_CAREPLAN_LIST } from 'op-pages/RO/PatientSummary/PatientProfileQueries';
import React, { useState } from 'react';
import { Slide, toast } from 'react-toastify';
import { Popover } from 'shared-components/components';
import { CheckboxField } from 'shared-components/components/UIFormComponents';
import { Region } from 'shared-components/enums';
import { styled } from '@mui/system';
import { generateCareplanName, getSiteGroupRepresentation } from './common';
import { useTheme, Typography, Stack } from '@mui/material';
import { StandardDialog, DialogButton } from 'shared-components/components';

export interface DeletePopoverProps {
  careplan: Careplan;
  patientId: any;
  handlePopUpClose: () => void;
  anchorEl: any;
  isPopUpOpen: boolean;
}

const Container = styled('div')`
  display: flex;
`;

const Description = styled('div')`
  margin-bottom: 16px;
`;

const ButtonsContainer = styled('section')`
  display: flex;
  width: 100%;
  margin-bottom: 0;
  justify-content: flex-end;
`;

const List = styled('ul')`
  margin: 8px auto 16px 0;
  list-style: none;
  li::before {
    margin-left: 0;
    content: '\\2022';
    width: 1em;
    font-size: 32px;
    font-weight: bold;
    vertical-align: middle;
    color: ${(props) => props.theme.palette.primary.main};
  }
  li {
    margin-bottom: 0;
  }
`;

const BoldUnderline = styled('span')`
  font-size: 16px;
  font-weight: 500;
  text-decoration: underline;
`;

const DeleteCareplan = ({
  careplan,
  patientId,
  handlePopUpClose,
  anchorEl,
  isPopUpOpen,
}: DeletePopoverProps): JSX.Element => {
  const [modalIsOpen, setIsModalOpen] = useState(false);
  const [voidAcknowledged, setVoidAcknowledged] = useState(false);
  const isUsRegion = import.meta.env.REACT_APP_REGION === Region.US;
  const theme = useTheme();

  const [softDeleteCareplan] = useMutation(SOFT_DELETE_CAREPLAN, {
    refetchQueries: [
      { query: GET_PATIENT_CAREPLAN_LIST, variables: { patientId } },
      { query: GET_PATIENT_NOTES, variables: { patientId } },
    ],
  });

  const [softDeleteCareplanDraft] = useMutation(SOFT_DELETE_CAREPLAN, {
    refetchQueries: [{ query: GET_PATIENT_CAREPLAN_LIST, variables: { patientId } }],
  });

  const POPUP = {
    HEADER: `${careplan.careplanStatus === CAREPLAN_STATUS.draft ? 'Discard Draft' : 'Void'} Careplan`,
    DESCRIPTION:
      careplan.careplanStatus === CAREPLAN_STATUS.draft
        ? 'All data will be deleted. Once deleted careplan data cannot be recovered.'
        : 'Would you like to proceed with this action?',
  };

  const DIALOG = {
    HEADER: 'Void Careplan',
  };

  const handleDiscardCareplan = () => {
    softDeleteCareplanDraft({ variables: { id: careplan.id } });
  };

  const handleModalOpen = () => {
    handlePopUpClose();
    setIsModalOpen(true);
  };

  const handleVoidCareplan = async () => {
    await softDeleteCareplan({ variables: { id: careplan.id } });
    const NotificationMessage = () => (
      <>
        <strong style={{ maxWidth: '270px', wordWrap: 'normal', overflow: 'hidden' }} className="title">
          Careplan Voided
        </strong>
        {careplan.sitegroupSet.map((siteGroup: SiteGroupSet, index: React.Key | null | undefined) => {
          return (
            <div style={{ maxWidth: '270px', wordWrap: 'normal', overflow: 'hidden' }} key={index}>
              {getSiteGroupRepresentation(siteGroup)}
            </div>
          );
        })}
      </>
    );

    toast.dark(<NotificationMessage />, {
      position: 'bottom-right',
      autoClose: 5000,
      hideProgressBar: true,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      transition: Slide,
      progress: undefined,
    });
  };

  return (
    <>
      <Container>
        <Popover
          id={`dd-popover-${careplan.id}`}
          title={POPUP.HEADER}
          open={isPopUpOpen}
          anchorEl={anchorEl}
          handleClickAway={handlePopUpClose}
          fadeTimeout={350}
          placement="bottom-end">
          <Description>{POPUP.DESCRIPTION}</Description>
          <ButtonsContainer>
            <DialogButton data-testid="dialog-cancel-btn" onClick={handlePopUpClose}>
              Cancel
            </DialogButton>
            <DialogButton
              data-testid="dialog-discard-btn"
              onClick={careplan.careplanStatus === CAREPLAN_STATUS.draft ? handleDiscardCareplan : handleModalOpen}
              color="error"
              sx={{ color: theme.palette.error.main }}>
              Yes
            </DialogButton>
          </ButtonsContainer>
        </Popover>
      </Container>
      <StandardDialog
        open={modalIsOpen}
        onClose={() => {
          setIsModalOpen(false);
        }}
        title={DIALOG.HEADER}
        destructiveSubmit
        titleIcon={<WarningSharp htmlColor={theme.palette.warning.dark}></WarningSharp>}
        maxWidth="md"
        onSubmit={() => {
          handleVoidCareplan();
          setIsModalOpen(false);
        }}
        submitButtonProps={{
          disabled: !voidAcknowledged,
        }}
        submitText="Void Careplan">
        <Stack>
          <Description>
            <Typography variant="subtitle1">Careplan: {generateCareplanName(careplan.sitegroupSet, ' + ')}</Typography>
          </Description>
          <Description>
            <div>This action should only be completed when:</div>
            <List>
              <li>
                The careplan is associated with an <BoldUnderline>incorrect patient or created in error</BoldUnderline>
              </li>
              <li>
                AND the patient has <BoldUnderline>NOT started</BoldUnderline> treatment
              </li>
            </List>
            <div>{CurrentAppConfig.CareplanVoidDescription}</div>
          </Description>
          {!isUsRegion && (
            <Description>
              If you need to revert this action please contact support:{' '}
              <a href={`mailto:${CurrentAppConfig.SupportEmail}`}>{CurrentAppConfig.SupportEmail}</a>
            </Description>
          )}
          <Typography paddingBottom={1} variant="subtitle1">
            Acknowledgement is required prior to voiding careplan*
          </Typography>
          <CheckboxField
            name="voidAcknowledgement"
            label="I acknowledge"
            onClick={() => setVoidAcknowledged(!voidAcknowledged)}
            width={'100%'}
          />
        </Stack>
      </StandardDialog>
    </>
  );
};

export default DeleteCareplan;
