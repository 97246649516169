import { gql } from '@apollo/client';

export const GET_MO_TEMPLATES = gql`
  query moTemplateList($tumourStream: String) {
    moTemplateList(tumourStream: $tumourStream) {
      id
      templates {
        id
        name
        statusName
        tumourStream
        numberOfCycles
        daysPerCycle
        evidenceId
        evidenceLink
        evidenceVersion
        continuous
        doses {
          id
          drugOrder
          cycle
          day
          order
        }
      }
      drugOrders {
        id
        drug
        name
        route
        dose
        doseUnit
        doseBasis
        minimumDose
        doseIsCalculated
        drugCategory
        genericName
      }
    }
  }
`;

export const GET_CAREPLAN_ADDITIONAL_INFO = gql`
  query moGetCareplanAdditionalInfo($careplanId: ID!) {
    moCareplanAdditionalInfo(careplanId: $careplanId) {
      id
      protocolId
      numberOfCycles
      daysPerCycle
      continuous
      evidenceId
      evidenceVersion
      evidenceLink
      startingCycle
    }
    careplan(id: $careplanId) {
      id
      careplanName
    }
  }
`;

export const UPDATE_MO_CAREPLAN_PROTOCOL = gql`
  mutation updateMoCareplanProtocol($careplanId: ID!, $protocolId: ID!, $startingCycle: Int!) {
    updateMoCareplanProtocol(careplanId: $careplanId, protocolId: $protocolId, startingCycle: $startingCycle) {
      moCareplanAdditionalInfo {
        id
        protocolId
        numberOfCycles
        daysPerCycle
        continuous
        evidenceId
        evidenceVersion
        evidenceLink
        cycleSchedule {
          seq
          days {
            day
            date
            breakDays
          }
        }
      }
    }
  }
`;

export const IS_CAREPLAN_APPROVED_ATLEAST_ONCE = gql`
  query isCareplanApprovedOnce($careplanId: ID!) {
    isCareplanApprovedOnce(careplanId: $careplanId)
  }
`;
