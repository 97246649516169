import {
  SelectField,
  MaskField,
  TextAreaField,
  ToggleButtonGroupField,
} from 'shared-components/components/FormikComponents';

export const SPOUSE_WORKING_STATUS_OPTIONS = [
  { label: 'Working', value: 'Working' },
  { label: 'Retired', value: 'Retired' },
  { label: 'Other', value: 'Other' },
];

export const ALTERNATE_ADDRESS_OPTIONS = [
  { label: 'Yes', value: true },
  { label: 'No', value: false },
];

export const FIELDS = {
  FORM_HEADING: {
    NAME: 'formHeading',
    TITLE: 'Demographic Details',
  },
  PRIMARY_OCCUPATION: {
    NAME: 'primaryOccupation',
    TITLE: 'What is/was your primary occupation?',
    PLACE_HOLDER: 'Enter primary occupation',
    COMPONENT: TextAreaField,
  },
  MARITAL_STATUS: {
    NAME: 'maritalStatus',
    TITLE: 'Marital status',
    PLACE_HOLDER: 'Please select',
    COMPONENT: SelectField,
  },
  SPOUSE_WORKING_STATUS: {
    NAME: 'spouseWorkingStatus',
    TITLE: 'Spouse working status',
    PLACE_HOLDER: '',
    COMPONENT: ToggleButtonGroupField,
  },
  SPOUSE_NAME: {
    NAME: 'spouseName',
    TITLE: 'Spouse name',
    PLACE_HOLDER: 'Enter spouse name',
    COMPONENT: TextAreaField,
  },
  SPOUSE_DOB: {
    NAME: 'spouseDob',
    TITLE: 'Spouse date of birth',
    PLACE_HOLDER: '',
  },
  SPOUSE_SSN: {
    NAME: 'spouseSocialSecurityNumber',
    TITLE: 'Spouse Social Security Number (SSN)',
    PLACE_HOLDER: '',
    COMPONENT: MaskField,
  },
  SPOUSE_CONTACT: {
    NAME: 'spouseContactNumber',
    TITLE: 'Spouse contact number',
    PLACE_HOLDER: 'Enter contact number',
    COMPONENT: TextAreaField,
  },
  ALTERNATE_ADDRESS_BOOL: {
    NAME: 'spouseAlternateAddressBool',
    TITLE: "Is your spouse's address different to yours?",
    PLACE_HOLDER: '',
    COMPONENT: ToggleButtonGroupField,
  },
  ALTERNATE_ADDRESS: {
    NAME: 'spouseAlternateAddress',
    TITLE: '',
    PLACE_HOLDER: '',
  },
  RACE: {
    NAME: 'race',
    TITLE: 'Which of the following best describes your race?',
    PLACE_HOLDER: 'Please select',
    COMPONENT: SelectField,
  },
  RACE_SPECIFIED: {
    NAME: 'raceSpecified',
    TITLE: 'Please specify',
    PLACE_HOLDER: 'Please specify',
    COMPONENT: TextAreaField,
  },
  ANCESTRY: {
    NAME: 'ancestry',
    TITLE: 'Please select one ethnic group that best describes your ancestry',
    PLACE_HOLDER: 'Please select',
    COMPONENT: SelectField,
  },
  LANGUAGE: {
    NAME: 'languageAtHome',
    TITLE: 'What language do you feel most comfortable using when discussing your healthcare?',
    PLACE_HOLDER: 'Start typing or select from dropdown',
    COMPONENT: SelectField,
  },
};

export const NOT_VALID = 'This is not a valid SSN';
export const SPECIFY_RACE_FIELDS = ['Other', 'More than one race'];
