import React, { useState, useEffect } from 'react';

import DTContext from '../DTContext';
import classnames from 'classnames';

import './DTRating.scss';

import { DTRatingItem } from 'op-classes';

interface Props {
  autosave: (updateObject: object) => void;
  dtRating: DTRatingItem;
  patientId: string;
}

const DTRating = (props: Props): JSX.Element => {
  const {
    autosave,
    patientId,
    dtRating: { id: distressId, distressRating },
  } = props;
  const [windowHeight, setWindowHeight] = useState(window.innerHeight);
  const { selectedRating, setSelectedRating } = React.useContext(DTContext);
  const SLIDER_PADDING_HEIGHT = 640;
  const MINIMUM_SLIDER_HEIGHT = 190;

  const handleWindowResize = (): void => {
    setWindowHeight(window.innerHeight);
  };

  useEffect((): (() => void) => {
    if (distressRating >= 0) {
      setSelectedRating(distressRating);
    }
    window.addEventListener('resize', handleWindowResize);
    return (): void => window.removeEventListener('resize', handleWindowResize);
  }, []);

  const calculateHeightStyle = (): string => {
    // Calculate the height of the bar, and there is a minimum height due to the left content container having a minimum height set.
    let calculatedHeight = windowHeight - SLIDER_PADDING_HEIGHT;
    if (calculatedHeight < MINIMUM_SLIDER_HEIGHT) {
      calculatedHeight = MINIMUM_SLIDER_HEIGHT;
    }
    // The width should be a proportion of the height
    return `${calculatedHeight}px`;
  };

  const handleInputChange = (e: React.ChangeEvent<HTMLInputElement>): void => {
    setSelectedRating(Number(e.target.value));
  };

  const handleInputMouseUp = (e: any): void => {
    setSelectedRating(e.target.value);
    // Create the object that will be used to update the backend
    const updateObject = {
      id: distressId,
      patientId,
      distressRating: Number(e.target.value),
    };
    autosave(updateObject);
  };

  return (
    <div id="dt-rating">
      <div id="dt-rating-image" />
      <div id="dt-rating-content">
        <div id="dt-rating-left-content">
          <div className="text-content">
            <div className="title">{'Instructions'}</div>
            <div className="content">
              Please use the slider on the right to select a number (0-10) that best describes how much distress you
              have been experiencing in the past week including today.
            </div>
          </div>
          <div id="dt-rating-display-value">
            <div className="scale-title">{'Scale:'}</div>
            <div className="scale-value">
              <span className={classnames('scale-value', { highlight: selectedRating >= 0 })}>
                {selectedRating < 0 ? '-' : selectedRating}
              </span>
              /10
            </div>
          </div>
        </div>
        <div id="dt-rating-right-content">
          <div className="top-label">
            <div className="numeric-text-fields">
              <div
                className={classnames('numeric-text-value', {
                  disabled: selectedRating < 0,
                })}>
                10
              </div>
              <div className="numeric-text">{'Extreme distress'}</div>
            </div>
          </div>
          <input
            className={classnames({
              disabled: selectedRating < 0,
            })}
            style={{ width: calculateHeightStyle() }}
            id="dt-rating-input"
            type="range"
            min="0"
            max="10"
            step="1"
            defaultValue={distressRating ? distressRating : 0}
            onChange={(e): void => {
              handleInputChange(e);
            }}
            onMouseUp={(e): void => {
              handleInputMouseUp(e);
            }}
            onTouchEnd={(e): void => {
              handleInputMouseUp(e);
            }}
          />
          <div className="bottom-label">
            <div className="numeric-text-fields">
              <div
                className={classnames('numeric-text-value', {
                  disabled: selectedRating < 0,
                })}>
                0
              </div>
              <div className="numeric-text">{'No distress'}</div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default DTRating;
