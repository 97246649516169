// eslint-disable-next-line no-use-before-define
import React, { Component, Fragment } from 'react';

import validate from 'shared-components/utils/validate';
import {
  AutoSuggestTextField,
  FreeTextField,
  SectionField,
  DropDownField,
} from 'shared-components/components/FormFields';
import { AddressInputs } from 'shared-components/components/FormFields/types';
import { Region } from 'shared-components/enums';
import { ListData, GraphUpdate } from 'shared-components/interfaces';
import { Logger, UK_POSTCODE_REGEX, UK_CITY_REGEX } from 'shared-components/utils';
const REACT_APP_REGION = import.meta.env.REACT_APP_REGION;

const logger = new Logger('AddressField');

let LINE1_RESIDENTIAL_FIELD_HEADING = 'Residential address line 1';
const LINE1_POSTAL_LINE_HEADING = 'Postal address line 1';
let LINE2_HEADING = 'Address line 2 (optional)';
let CITYSUBURB_FIELD_HEADING = 'City / Suburb';
const COUNTRY_FIELD_HEADING = 'Country';
let HEADING = {
  AUS_STATE: 'State or territory',
  REGION: 'State / Province / Region',
};
let ZIP_POSTCODE_HEADING = 'ZIP / Postcode';
let COUNTRY_NAME = 'Australia';

const PLACEHOLDERS = {
  LINE1: 'Street name and number',
  LINE2: '',
  CITY: 'Enter city / suburb',
  COUNTRY: 'Start typing',
  AUS_STATE: 'Select state or territory',
  REGION: 'Enter State / Province / Region',
  ZIP_POSTCODE: 'Enter ZIP / Postcode',
};

const region = REACT_APP_REGION;

if (region === Region.UK) {
  COUNTRY_NAME = 'United Kingdom';
  LINE1_RESIDENTIAL_FIELD_HEADING = 'Address line 1';
  LINE2_HEADING = 'Address line 2';
  CITYSUBURB_FIELD_HEADING = 'City / Town';
  HEADING = {
    AUS_STATE: 'County',
    REGION: 'County',
  };
  ZIP_POSTCODE_HEADING = 'Postcode';
}

interface State {
  viewed: Set<string>;
}

interface Props {
  isMandatory: boolean;
  inputName: string;
  addressInputs: AddressInputs;
  onBlur: (graphItem: [GraphUpdate]) => void;
  countries: ListData[];
  ausStates: ListData[];
  validateForm?: boolean;
  disabled?: boolean;
}

class AddressField extends Component<Props, State> {
  public static defaultProps = {
    validateForm: false,
  };

  private australianIdInCountry = '';

  public constructor(props: Props) {
    super(props);
    this.state = {
      viewed: new Set(),
    };

    // Find the australian id in the countries dictionary that will be used later
    // UK has this overridden for region === Region.UK to search for 'United Kingdom'
    const australianIndex = props.countries.findIndex((country) => {
      return country.name === COUNTRY_NAME;
    });

    this.australianIdInCountry = props.countries[australianIndex].id;
  }

  public render(): JSX.Element {
    const { addressInputs, inputName } = this.props;
    let stateValidationPresenceMessage = 'Please enter your State / Province / Region.';
    let stateValidationFormatPattern = '[a-zA-Z\\s]+|';
    let postCodeValidationFormatPattern = '.*';
    // Australia state dropdown validation messages and checks
    if (addressInputs.country.defaultValue === this.australianIdInCountry) {
      stateValidationPresenceMessage = 'Please select your state or territory';
      stateValidationFormatPattern = '.*'; // State is stored as digit so allow any values
    }

    // UK has a specific postcode type.
    if (region === Region.UK && addressInputs.country.defaultValue === this.australianIdInCountry) {
      postCodeValidationFormatPattern = UK_POSTCODE_REGEX;
    }
    let addressValidationRules: { [key: string]: object } = {};
    if (this.props.isMandatory) {
      addressValidationRules = {
        line1: {
          presence: {
            allowEmpty: false,
            message: 'Please enter your street address.',
          },
        },
        city: {
          presence: {
            allowEmpty: false,
            message: region === Region.UK ? 'Please enter your City / Town' : 'Please enter your City / Suburb.',
          },
          format: {
            pattern: UK_CITY_REGEX,
            message: region === Region.UK ? 'Please enter a valid city/town' : 'Please enter a valid city/suburb.',
          },
        },
        country: {
          presence: {
            allowEmpty: false,
            message: 'Please enter your Country.',
          },
          list: {
            listdata: this.props.countries,
            message: 'Please enter a valid country.',
          },
        },
        state: {
          presence: {
            allowEmpty: region === Region.UK && addressInputs.country.defaultValue !== this.australianIdInCountry,
            message: region === Region.UK ? 'Please enter your County' : stateValidationPresenceMessage,
          },
          format: {
            pattern: stateValidationFormatPattern,
            message:
              region === Region.UK
                ? 'Please enter your county'
                : 'Please enter a valid format for state/province/region.',
          },
        },
        zipPostcode: {
          presence: {
            allowEmpty: region === Region.UK && addressInputs.country.defaultValue !== this.australianIdInCountry,
            message: 'Please enter your postcode.',
          },
          format: {
            pattern: postCodeValidationFormatPattern,
            message: 'Please enter valid postcode.',
          },
        },
      };
    } else {
      addressValidationRules = {
        line1: {
          presence: {
            allowEmpty: true,
            message: 'Please enter your street address.',
          },
        },
        city: {
          presence: {
            allowEmpty: true,
            message: region === Region.UK ? 'Please enter your City / Town' : 'Please enter your City / Suburb.',
          },
        },
        country: {
          presence: {
            allowEmpty: true,
            message: 'Please enter your Country.',
          },
        },
        state: {
          presence: {
            allowEmpty: true,
            message: region === Region.UK ? 'Please enter your County' : stateValidationPresenceMessage,
          },
        },
        zipPostcode: {
          presence: {
            allowEmpty: true,
            message: 'Please enter your postcode.',
          },
          format: {
            pattern: postCodeValidationFormatPattern,
            message: 'Please enter valid postcode.',
          },
        },
      };
    }

    // Ensure fields that been viewed only have validation run on them
    const specificValidationRules: { [key: string]: object } = {};
    let viewedFields = this.state.viewed.keys();
    // If validate form is true ensure all the fields are validated
    if (this.props.validateForm) {
      viewedFields = new Set(['line1', 'city', 'country', 'state', 'zipPostcode']).keys();
    }
    for (const viewed of viewedFields) {
      specificValidationRules[viewed] = addressValidationRules[viewed];
    }

    // Disable pre-appending of argument name to error messages
    const disableFullMessages = { fullMessages: false };

    const addressObject: { [key: string]: string } = {};
    for (const key of Object.keys(addressInputs)) {
      addressObject[key] = addressInputs[key].defaultValue;
    }

    const validationObject = validate(addressObject, specificValidationRules, disableFullMessages);

    let line1FieldHeading: string = LINE1_RESIDENTIAL_FIELD_HEADING;
    if (inputName === 'postal') {
      line1FieldHeading = LINE1_POSTAL_LINE_HEADING;
    }
    let displayPostcodePlaceholder = true;
    if (
      region === Region.UK &&
      addressInputs.country &&
      addressInputs.country.defaultValue === this.australianIdInCountry
    ) {
      displayPostcodePlaceholder = false;
    }

    return (
      <Fragment>
        <SectionField
          isValid={validationObject && validationObject.line1 ? false : true}
          htmlFor={`${inputName}-line1`}
          title={line1FieldHeading}>
          <FreeTextField
            disabled={this.props.disabled}
            inputName={`${inputName}-line1`}
            placeholder={PLACEHOLDERS.LINE1}
            defaultValue={addressInputs.line1.defaultValue}
            maxLength={60}
            onBlur={(e): void => {
              this.onBlur([addressInputs.line1.graphArgumentKey], [e.target.value], ['String'], ['line1']);
            }}
            errors={validationObject && validationObject.line1 ? validationObject.line1 : undefined}
          />
        </SectionField>
        <SectionField isValid={true} htmlFor={`${inputName}-line2`} title={LINE2_HEADING}>
          <FreeTextField
            disabled={this.props.disabled}
            inputName={`${inputName}-line2`}
            placeholder={PLACEHOLDERS.LINE2}
            defaultValue={addressInputs.line2.defaultValue}
            maxLength={60}
            onBlur={(e): void => {
              this.onBlur([addressInputs.line2.graphArgumentKey], [e.target.value], ['String'], ['line2']);
            }}
            errors={validationObject ? validationObject.line2 : undefined}
          />
        </SectionField>
        <SectionField
          isValid={validationObject && validationObject.city ? false : true}
          htmlFor={`${inputName}-city`}
          title={CITYSUBURB_FIELD_HEADING}>
          <FreeTextField
            disabled={this.props.disabled}
            inputName={`${inputName}-city`}
            placeholder={PLACEHOLDERS.CITY || ''}
            defaultValue={addressInputs.city.defaultValue}
            maxLength={40}
            onBlur={(e): void => {
              this.onBlur([addressInputs.city.graphArgumentKey], [e.target.value], ['String'], ['city']);
            }}
            errors={validationObject && validationObject.city ? validationObject.city : undefined}
          />
        </SectionField>
        <SectionField
          isValid={validationObject && validationObject.country ? false : true}
          htmlFor={`${inputName}-country`}
          title={COUNTRY_FIELD_HEADING}>
          <AutoSuggestTextField
            disabled={this.props.disabled}
            inputName={`${inputName}-country`}
            preventNumberInput={true}
            suggestionList={this.props.countries}
            placeholder={PLACEHOLDERS.COUNTRY}
            maxLength={100}
            onBlur={(value): void => {
              this.updateStateWithCountry(value);
            }}
            defaultValue={addressInputs.country.defaultValue}
            errors={validationObject && validationObject.country ? validationObject.country : undefined}
          />
        </SectionField>
        {this.renderStateOrRegion(validationObject)}
        <SectionField
          isValid={validationObject && validationObject.zipPostcode ? false : true}
          htmlFor={`${inputName}-zipPostcode`}
          title={ZIP_POSTCODE_HEADING}>
          <FreeTextField
            disabled={this.props.disabled}
            inputName={`${inputName}-zipPostcode`}
            placeholder={displayPostcodePlaceholder ? PLACEHOLDERS.ZIP_POSTCODE : ''}
            defaultValue={addressInputs.zipPostcode.defaultValue}
            maxLength={25}
            onBlur={(e): void => {
              this.onBlur([addressInputs.zipPostcode.graphArgumentKey], [e.target.value], ['String'], ['zipPostcode']);
            }}
            errors={validationObject && validationObject.zipPostcode ? validationObject.zipPostcode : undefined}
          />
        </SectionField>
      </Fragment>
    );
  }

  private onBlur(graphKey: string[], value: string[], type: string[], validationKeys: string[]): void {
    // Update the viewed items
    const viewed = this.state.viewed;

    const shouldAddKey = (key: string) => {
      // Check if the key is for state, and if it arrived with country, do not process it, and remove it
      // This is to enable, wiping of state data if country changes.
      if (key === 'state') {
        if (validationKeys.includes('country')) {
          viewed.delete(key);
        }
      } else if (!viewed.has(key)) {
        viewed.add(key);
      }
    };

    const graphItems: [GraphUpdate] = [{ key: graphKey[0], value: value[0], type: type[0] }];
    shouldAddKey(validationKeys[0]);

    for (let i = 1; i < graphKey.length; i++) {
      graphItems.push({ key: graphKey[i], value: value[i], type: type[i] });
      shouldAddKey(validationKeys[i]);
    }

    this.setState({ viewed });

    this.props.onBlur(graphItems);
  }

  /**
   * This method is to only edit the residential or postal state if and when either the new country value is changing to Australia, or the existing value is Australia.
   *
   * This is to ensure that when the country is changed from Australia, the state will not have some random id value that had been set. When the country is being changed to Australia, the placeholder of selecting a state should be chosen instead of the first state in the list.
   * @param {string} newCountryValue The new value that the country is being set to.
   */
  private updateStateWithCountry = (newCountryValue: string): void => {
    logger.debug('updateStateWithCountry', 'The country value is: ', newCountryValue);
    const { addressInputs } = this.props;
    const changingFromAusToAnother =
      addressInputs.country.defaultValue === this.australianIdInCountry &&
      newCountryValue !== this.australianIdInCountry;

    const changingFromOtherToAus =
      addressInputs.country.defaultValue !== this.australianIdInCountry &&
      newCountryValue === this.australianIdInCountry;

    // Set up the default values to be passed into the on blur function
    const graphKeyValues = [addressInputs.country.graphArgumentKey];
    const graphValues = [newCountryValue];
    const graphValueTypes = ['String'];
    const validationKeys = ['country'];

    if (changingFromOtherToAus || changingFromAusToAnother) {
      logger.debug('updateStateWithCountry', 'The state should be updated');
      // The country is changing to Australia or from Australia to something else, therefore the state should be reset.
      graphKeyValues.push(addressInputs.state.graphArgumentKey);
      graphValues.push('');
      graphValueTypes.push('String');
      validationKeys.push('state');
    }

    this.onBlur(graphKeyValues, graphValues, graphValueTypes, validationKeys);
  };

  private renderStateOrRegion = (validationObject: any): JSX.Element => {
    const { addressInputs, inputName, ausStates } = this.props;
    return (
      <SectionField
        isValid={validationObject && validationObject.state ? false : true}
        htmlFor={`${inputName}-state`}
        title={HEADING.REGION}>
        <FreeTextField
          disabled={this.props.disabled}
          inputName={`${inputName}-state`}
          placeholder={PLACEHOLDERS.REGION || ''}
          defaultValue={addressInputs.state.defaultValue}
          maxLength={100}
          errors={validationObject && validationObject.state ? validationObject.state : undefined}
          onBlur={(e): void => {
            this.state.viewed.add('state');
            this.onBlur([addressInputs.state.graphArgumentKey], [e.target.value], ['String'], ['state']);
          }}
        />
      </SectionField>
    );
  };
}

export default AddressField;
