import { useTheme, Tooltip, Stack, Grid, Typography } from '@mui/material';
import { shouldDisableTemplate } from './utils';
import { CareplanTemplate } from 'shared-components/images';
import { AddCircleOutline } from '@mui/icons-material';
import { CareplanListFilter } from '../Interface';
import { TenantContext } from 'op-contexts';
import { useContext } from 'react';

interface TemplateTableColumnProps {
  title: string;
  options: string[];
  id: string;
  onClick: (value: string) => void;
  selectedValue: string;
}

interface CareplanColumnProps {
  title: string;
  options: CareplanListFilter[];
  id: string;
  onClick: (id: number, value: string) => void;
  treatingDepartment: string;
}

interface Props {
  siteFilter: string;
  siteFilterOptions: string[];
  setSiteFilter: (value: string) => void;
  doseFilter: string;
  doseFilterOptions: string[];
  setDoseFilter: (value: string) => void;
  techniqueFilter: string;
  techniqueFilterOptions: string[];
  setTechniqueFilter: (value: string) => void;
  treatingDepartment: string;
  setCareplan: (id: number, name: string) => void;
  careplanOptions: CareplanListFilter[];
}

const ColumnTitle = ({ title }: { title: string | JSX.Element }) => {
  const theme = useTheme();
  return (
    <Typography
      padding={2}
      sx={{
        backgroundColor: theme.palette.grey[100],
        borderRadius: '4px 4px 0 0',
        borderBottom: `1px solid ${theme.palette.grey[300]}`,
      }}
      variant="subtitle1">
      {title}
    </Typography>
  );
};

const CareplanColumn = ({ title, options, id, onClick, treatingDepartment }: CareplanColumnProps) => {
  const theme = useTheme();
  return (
    <Stack width="100%" height="100%" sx={{ border: `1px solid ${theme.palette.grey[300]}`, borderRadius: '4px' }}>
      <ColumnTitle
        title={
          <Stack direction="row" alignItems="center">
            <CareplanTemplate />
            {title}
          </Stack>
        }
      />
      <Stack data-testid={id} sx={{ overflowY: 'auto', height: '100%' }}>
        {options?.map((option, index) => {
          const disabled = shouldDisableTemplate(option, treatingDepartment) || false;
          return (
            <Tooltip
              key={`tooltip-${index}`}
              arrow
              title={
                disabled && 'This template cannot be used for the selected treating department on the previous page.'
              }>
              <Stack
                id={`template-${index}`}
                data-testid={option.name}
                key={`${option.name + option.id}`}
                direction="row"
                alignItems="center"
                justifyContent="space-between"
                padding={2}
                sx={{
                  cursor: 'pointer',
                  borderBottom: `1px solid ${theme.palette.grey[100]}`,
                  backgroundColor: 'transparent',
                  ':hover': {
                    backgroundColor: theme.palette.action.hover,
                  },
                }}
                onClick={(): void => {
                  if (disabled) return;
                  onClick(option?.id, option?.name);
                }}>
                <Typography
                  sx={{
                    color: disabled ? theme.palette.grey[600] : theme.palette.text.primary,
                  }}
                  variant="body1">
                  {option.name}
                </Typography>
                {!disabled && <AddCircleOutline color="primary" />}
              </Stack>
            </Tooltip>
          );
        })}
      </Stack>
    </Stack>
  );
};

const TemplateTableColumn = ({ title, options, id, onClick, selectedValue = '' }: TemplateTableColumnProps) => {
  const theme = useTheme();
  return (
    <Stack width="100%" height="100%" sx={{ border: `1px solid ${theme.palette.grey[300]}`, borderRadius: '4px' }}>
      <ColumnTitle title={title} />
      <Stack data-testid={id} sx={{ overflowY: 'auto', height: '100%' }}>
        {options?.map((option, index) => {
          const active = selectedValue === option;
          return (
            <Stack
              id={`item-${index}`}
              data-testid={option}
              key={option}
              direction="row"
              alignItems="center"
              justifyContent="space-between"
              padding={2}
              sx={{
                cursor: 'pointer',
                borderBottom: `1px solid ${theme.palette.grey[100]}`,
                borderLeft: active ? `4px solid ${theme.palette.primary.main}` : 'none',
                backgroundColor: active ? theme.palette.secondary.light : 'transparent',
                ':hover': {
                  backgroundColor: active ? theme.palette.secondary.light : theme.palette.action.hover,
                },
              }}
              onClick={(): void => {
                onClick(option);
              }}>
              <Typography variant="body1">{option}</Typography>
            </Stack>
          );
        })}
      </Stack>
    </Stack>
  );
};

const TemplateSelectionTable = ({
  siteFilter,
  siteFilterOptions,
  doseFilter,
  doseFilterOptions,
  techniqueFilter,
  techniqueFilterOptions,
  setSiteFilter,
  setDoseFilter,
  setTechniqueFilter,
  treatingDepartment,
  setCareplan,
  careplanOptions,
}: Props) => {
  const { doseUnit } = useContext(TenantContext);
  return (
    <Grid container width="100%" columns={14} sx={{ height: '100%' }}>
      <Grid item xs={4} paddingRight={2}>
        <TemplateTableColumn
          id="site-filter-column"
          title="1) Site Filter"
          selectedValue={siteFilter}
          options={siteFilterOptions}
          onClick={(newSiteFilter: string) => {
            setSiteFilter(newSiteFilter);
            setDoseFilter('');
            setTechniqueFilter('');
          }}
        />
      </Grid>
      <Grid item xs={3} paddingRight={2}>
        <TemplateTableColumn
          id="dose-filter-column"
          title={`2) Dose Filter (${doseUnit})`}
          selectedValue={doseFilter}
          options={doseFilterOptions}
          onClick={(newDoseFilter: string) => {
            setDoseFilter(newDoseFilter);
            setTechniqueFilter('');
          }}
        />
      </Grid>
      <Grid item xs={3} paddingRight={2}>
        <TemplateTableColumn
          id="technique-filter-column"
          title="3) Technique Filter"
          selectedValue={techniqueFilter}
          options={techniqueFilterOptions}
          onClick={(newTechniqueFilter: string) => {
            setTechniqueFilter(newTechniqueFilter);
          }}
        />
      </Grid>
      <Grid item xs={4}>
        <CareplanColumn
          id="careplan-column"
          title="Careplan Template"
          options={careplanOptions}
          onClick={(id: number, newCareplan: string) => {
            setCareplan(id, newCareplan);
          }}
          treatingDepartment={treatingDepartment}
        />
      </Grid>
    </Grid>
  );
};

export default TemplateSelectionTable;
