import { useQuery } from '@apollo/client';
import React, { useMemo } from 'react';

import { SummaryCard, SummaryRow } from 'op-components';
import { evaluateDetailText } from 'op-components/OP/SummaryRow/SummaryRow';
import { CancerItem, FamilyHistoryItem, RadiotherapyCondition, TreatmentItem } from 'op-interfaces/HAInterfaces';
import {
  CR_FIELDS,
  CR_MODAL_FIELDS,
  FAMILY_HISTORY_MODAL,
  TREATMENT_TYPES,
  YES,
} from 'op-pages/OP/HealthAssessment/HAPages/constants';
import { renderArrow } from 'op-pages/OP/HealthAssessment/HASharedComponents/HAModalFields';
import { HA_MEDICAL_HISTORY_CANCER_QUERY } from 'op-pages/OP/HealthAssessment/HASharedComponents/queries';
import { HASubComponentProps } from 'op-pages/OP/HealthAssessment/HASharedComponents/types';
import { Edit, Success } from 'shared-components/images';
import { SerializedListDataObject } from 'shared-components/interfaces';
import { displayRawDate } from 'shared-components/utils';
import { convertListDataToObject } from 'shared-components/utils/ListDataHelpers';
import './../../HAReviewSummaryUS.scss';

const MedicalHistoryCancer = (props: HASubComponentProps): JSX.Element => {
  const { patientId, isPso, navigate } = props;

  const { data, loading } = useQuery(HA_MEDICAL_HISTORY_CANCER_QUERY, {
    variables: { patientId },
  });

  const familyMemberRefDataSerialized = useMemo<SerializedListDataObject>(
    () => convertListDataToObject(data?.familyMemberRefData),
    [data?.familyMemberRefData],
  );
  const treatmentStageRefDataSerialized = useMemo<SerializedListDataObject>(
    () => convertListDataToObject(data?.treatmentStageRefData),
    [data?.treatmentStageRefData],
  );
  const treatmentTypeRefDataSerialized = useMemo<SerializedListDataObject>(
    () => convertListDataToObject(data?.treatmentTypeRefData),
    [data?.treatmentTypeRefData],
  );

  if (!data || loading) return <></>;

  const { cancer, previousCancer, otherRadiotherapyConditions, familyHistory, otherRadiotherapy, usFamilyHistory } =
    data.healthAssessment;

  const renderSummaryRow = (
    title: string,
    text: string | boolean | undefined,
    subQuestion = false,
    allowEmpty = false,
    displayAsOptional = false,
  ) => {
    return (
      <SummaryRow
        headingText={title}
        detailText={evaluateDetailText(text)}
        displayAsError={false}
        displayAsOptional={displayAsOptional}
        subQuestion={subQuestion}
        allowEmptyDetailText={allowEmpty}
      />
    );
  };
  const renderSummaryRowTitle = (title: string) => {
    return (
      <SummaryRow
        headingText={title}
        detailText={''}
        displayAsError={false}
        displayAsOptional={false}
        subQuestion={true}
        allowEmptyDetailText={true}
        underLine={true}
      />
    );
  };

  const renderTreatmentByType = (cancerTreatment: TreatmentItem) => {
    const treatmentTypeName = treatmentTypeRefDataSerialized[cancerTreatment.treatmentType];
    const stageData = treatmentStageRefDataSerialized[cancerTreatment.stage];

    switch (treatmentTypeName) {
      case TREATMENT_TYPES.SURGERY:
      case TREATMENT_TYPES.BRACHYTHERAPY:
      case TREATMENT_TYPES.NONE:
      case TREATMENT_TYPES.UNSURE:
        return <>{renderSummaryRow(CR_MODAL_FIELDS.TREATMENT_TYPE.TITLE, treatmentTypeName, true)}</>;

      case TREATMENT_TYPES.RADIATION_THERAPY:
        return (
          <>
            {renderSummaryRow(CR_MODAL_FIELDS.TREATMENT_TYPE.TITLE, treatmentTypeName, true)}
            {renderSummaryRow(CR_MODAL_FIELDS.HOSPITAL.TITLE, cancerTreatment.hospital, true)}
            {renderSummaryRow(CR_MODAL_FIELDS.DOCTOR.TITLE, cancerTreatment.doctor, true, false, true)}
            {renderSummaryRow(CR_MODAL_FIELDS.BODY.TITLE, cancerTreatment.bodyPart, true)}
            {renderSummaryRow(
              CR_MODAL_FIELDS.TREATMENT_DATE_HEADING,
              displayRawDate(
                cancerTreatment.treatmentRawMonth,
                cancerTreatment.treatmentRawYear,
                cancerTreatment.treatmentRawDay,
              ),
              true,
            )}
          </>
        );

      case TREATMENT_TYPES.CHEMOTHERAPY:
      case TREATMENT_TYPES.IMMUNOTHERAPY:
        return (
          <>
            {renderSummaryRow(CR_MODAL_FIELDS.TREATMENT_TYPE.TITLE, treatmentTypeName, true)}
            {renderSummaryRow(CR_MODAL_FIELDS.STAGE.TITLE, stageData, true)}
            {renderSummaryRow(CR_MODAL_FIELDS.DOCTOR.TITLE, cancerTreatment.doctor, true, false, true)}
            {stageData !== 'Not started' &&
              stageData !== 'Unsure' &&
              renderSummaryRow(
                CR_MODAL_FIELDS.TREATMENT_DATE_HEADING,
                displayRawDate(
                  cancerTreatment.treatmentRawMonth,
                  cancerTreatment.treatmentRawYear,
                  cancerTreatment.treatmentRawDay,
                ),
                true,
              )}
          </>
        );

      case TREATMENT_TYPES.HORMONAL_THERAPY:
        return (
          <>
            {renderSummaryRow(CR_MODAL_FIELDS.TREATMENT_TYPE.TITLE, treatmentTypeName, true)}
            {renderSummaryRow(CR_MODAL_FIELDS.HORMONAL.TITLE, cancerTreatment.medication, true)}
            {renderSummaryRow(
              CR_MODAL_FIELDS.TREATMENT_DATE_HEADING,
              displayRawDate(
                cancerTreatment.treatmentRawMonth,
                cancerTreatment.treatmentRawYear,
                cancerTreatment.treatmentRawDay,
              ),
              true,
            )}
          </>
        );

      case TREATMENT_TYPES.OTHER:
        return (
          <>
            {renderSummaryRow(CR_MODAL_FIELDS.TREATMENT_TYPE.TITLE, treatmentTypeName, true)}
            {renderSummaryRow(CR_MODAL_FIELDS.OTHER.TITLE, cancerTreatment.other, true)}
          </>
        );

      default:
        return renderSummaryRow(CR_MODAL_FIELDS.TREATMENT_TYPE.TITLE, '', true);
    }
  };

  const renderCancerSection = (cancers: CancerItem[]) => {
    if (!cancers) return <></>;

    return (
      <>
        {renderSummaryRow(CR_FIELDS.CANCER.TITLE, previousCancer)}
        {previousCancer === YES && (
          <>
            {renderArrow()}
            {cancers.map((cancer, index) => {
              return (
                <div key={`cancer-${cancer.id}`} className="key-color">
                  {renderSummaryRowTitle(`Cancer ${index + 1}`)}
                  {renderSummaryRow(CR_MODAL_FIELDS.CANCER_TYPE.TITLE, cancer.cancerType, true)}
                  {renderSummaryRow(CR_MODAL_FIELDS.CANCER_STAGE.TITLE, cancer.cancerStage, true)}

                  {cancer.treatment.map((cancerTreatment, treatmentIndex) => {
                    return (
                      <div key={`cancer-treatment-${cancerTreatment.id}`}>
                        {renderSummaryRowTitle(`Treatment ${treatmentIndex + 1}`)}
                        {renderTreatmentByType(cancerTreatment)}
                      </div>
                    );
                  })}
                </div>
              );
            })}
          </>
        )}
      </>
    );
  };

  const renderOtherConditionSection = (otherRadiotherapyConditions: RadiotherapyCondition[]) => {
    return (
      <>
        {renderSummaryRow(CR_FIELDS.RADIOTHERAPY.TITLE, otherRadiotherapy)}
        {otherRadiotherapy === YES && (
          <>
            {renderArrow()}
            {otherRadiotherapyConditions.map((condition, index) => {
              return (
                <div key={condition.id} className="key-color">
                  {renderSummaryRowTitle(`Condition ${index + 1}`)}
                  {renderSummaryRow('Type of condition', condition.condition, true)}
                  {renderSummaryRow(CR_MODAL_FIELDS.HOSPITAL.TITLE, condition.hospital, true)}
                  {renderSummaryRow(CR_MODAL_FIELDS.DOCTOR.TITLE, condition.doctor, true)}
                  {renderSummaryRow(CR_MODAL_FIELDS.BODY.TITLE, condition.bodyPart, true)}
                  {renderSummaryRow(
                    'Date of treatment',
                    displayRawDate(condition.treatmentRawMonth, condition.treatmentRawYear, condition.treatmentRawDay),
                    true,
                  )}
                </div>
              );
            })}
          </>
        )}
      </>
    );
  };

  const renderFamilyHistorySection = (familyHistoryAnswers: FamilyHistoryItem[]) => {
    return (
      <>
        {renderSummaryRow(CR_FIELDS.HISTORY.TITLE, familyHistory)}
        {familyHistory === YES && (
          <>
            {renderArrow()}
            {familyHistoryAnswers.map((member, index) => {
              return (
                <div key={member.id} className="key-color">
                  {renderSummaryRowTitle(`Member ${index + 1}`)}
                  {renderSummaryRow(
                    FAMILY_HISTORY_MODAL.FAMILY_MEMBER.TITLE,
                    familyMemberRefDataSerialized[member.familyMember],
                    true,
                  )}
                  {familyMemberRefDataSerialized[member.familyMember] === 'Other' &&
                    renderSummaryRow('Please specify', member.other, true, true)}
                  {renderSummaryRow('Type', member.cancerType, true)}
                  {renderSummaryRow('Alive', member.stillAlive, true)}
                  {renderSummaryRow('Age', member.ageOfFamilyMember, true)}
                </div>
              );
            })}
          </>
        )}
      </>
    );
  };

  return (
    <SummaryCard
      title={'Medical history 1'}
      primaryIcon={isPso ? <></> : <Edit className="primary-icon" />}
      secondaryIcon={<Success className="secondary-icon valid-icon" />}
      showEdit={!isPso}
      onClick={(): void => {
        if (!isPso) {
          navigate('history-cancer');
        }
      }}>
      {renderCancerSection(cancer)}
      {renderOtherConditionSection(otherRadiotherapyConditions)}
      {renderFamilyHistorySection(usFamilyHistory)}
    </SummaryCard>
  );
};

export default MedicalHistoryCancer;
