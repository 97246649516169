// eslint-disable-next-line no-use-before-define
import React from 'react';
import { withRouter } from 'react-router';
import { Switch, Route } from 'react-router-dom';
import ROPatientDiagnosisPage from './DiagnosisPages/DiagnosisPage';
import ROPatientTreatmentInfoPage from './TreatmentInfoPage/TreatmentInfoPage';
import ROPatientVolumingPage from './VolumingPage/VolumingPage';
import ROPatientCarePathTemplatePage from './TemplatePage/TemplatePage';
import ROPatientTreatmentSimulationPage from './SimulationPage/SimulationPage';
import ROPatientPrescriptionPage from './PrescriptionPage/PrescriptionPage';
import ROPatientTreatmentSubmissionPage from './SubmissionPage/SubmissionPage';
import ROPatientCarePathSiteSetupPage from './SiteSetupPage/SiteSetupPage';
import ROPatientSpecialPhysicsConsult from './SpecialPhysicsConsultPage/SpecialPhysicsConsultPage';
import { CAREPLAN_PAGES } from './Constants';
import { PeerReviewProvider } from './PeerReviewContext';
import { CurrentAppConfig } from './AppConfig';

const ROPatientCarePlanPageRouter = () => {
  const showSpecialPhysicsConsultPage = CurrentAppConfig.SpecialPhysicsConsultVisible;

  return (
    <PeerReviewProvider>
      <Switch>
        {showSpecialPhysicsConsultPage && (
          <Route
            path={`/:oncologyType/patient/:id/careplan/:careplanId/${CAREPLAN_PAGES.SPECIAL_PHYSICS_CONSULT}/:siteGroupIdx?`}
            component={ROPatientSpecialPhysicsConsult}
          />
        )}
        <Route
          path={`/:oncologyType/patient/:id/careplan/:careplanId/${CAREPLAN_PAGES.VOLUMING}/:siteGroupIdx?`}
          component={ROPatientVolumingPage}
        />
        <Route
          path={`/:oncologyType/patient/:id/careplan/:careplanId/${CAREPLAN_PAGES.SUBMISSION}`}
          component={ROPatientTreatmentSubmissionPage}
        />
        <Route
          path={`/:oncologyType/patient/:id/careplan/:careplanId/${CAREPLAN_PAGES.PRESCRIPTION}/:siteIdx?`}
          component={ROPatientPrescriptionPage}
        />
        <Route
          path={`/:oncologyType/patient/:id/careplan/:careplanId/${CAREPLAN_PAGES.SIMULATION}/:siteGroupIdx?`}
          component={ROPatientTreatmentSimulationPage}
        />
        <Route
          path={`/:oncologyType/patient/:id/careplan/:careplanId/${CAREPLAN_PAGES.TEMPLATE_SELECTION}`}
          component={ROPatientCarePathTemplatePage}
        />
        <Route
          path={`/:oncologyType/patient/:id/careplan/:careplanId/${CAREPLAN_PAGES.TREATMENT_INFO}`}
          component={ROPatientTreatmentInfoPage}
        />
        <Route
          path={`/:oncologyType/patient/:id/careplan/:careplanId/${CAREPLAN_PAGES.SITESETUP}`}
          component={ROPatientCarePathSiteSetupPage}
        />
        <Route path="/:oncologyType/patient/:id/careplan/:careplanId/" component={ROPatientDiagnosisPage} />
      </Switch>
    </PeerReviewProvider>
  );
};

const routedComponent = withRouter(ROPatientCarePlanPageRouter);
export default routedComponent;
