import React, { useEffect, useState } from 'react';
import { Button } from '@mui/material';
import { styled } from '@mui/system';
import './PatientFormFooter.scss';
import { DeviceUtilities } from 'shared-components/utils';
import { Stack } from '@mui/material';

const FooterContainer = styled('div')`
  z-index: 1000;
  position: fixed;
  bottom: 0;
  padding: 12px 24px;
  width: 100%;
  border-top: 1px solid ${(props) => props.theme.palette.grey[300]};
  background-color: white;
  box-shadow: 0 -2px 12px rgba(0, 0, 0, 0.25);
  display: flex;
  justify-content: space-between;
  flex-direction: row;
`;

interface Props {
  handleClickOnBack?: any;
  handleClickOnContinue?: any;
  handleSaveAndExit?: any;
  handleResolveConflict?: any;
  navComponentId: string;
  isRecordLocked?: boolean;
  hasConflicts?: boolean;
  resolveConflictsIncomplete?: boolean;
  isPso: boolean | null;
  isInRegistrationForm?: boolean;
  isFirstPage?: boolean;
  isLastPage?: boolean;
  submitCalled?: boolean;
  showPrintPdfButton?: boolean;
  showContinueButton?: boolean;
  summaryVisited?: boolean;
}

const PatientFormFooter = ({
  handleClickOnBack,
  handleClickOnContinue,
  handleSaveAndExit,
  handleResolveConflict,
  navComponentId,
  isRecordLocked = false,
  hasConflicts = false,
  resolveConflictsIncomplete = false,
  isPso = null,
  isInRegistrationForm = false,
  isFirstPage,
  isLastPage,
  submitCalled = false,
  showPrintPdfButton = false,
  showContinueButton = true,
  summaryVisited = false,
}: Props): JSX.Element => {
  const componentId = 'patient-form-footer';
  const isMobile = DeviceUtilities.isMobileDevice();

  // isPso comes into component initially as null. Prevent flashing buttons by introducing loading flag
  const [loadingPage, setLoadingPage] = useState(true);

  useEffect(() => {
    if (isPso !== null) {
      setLoadingPage(false);
    }
  }, [isPso]);

  let continueButtonTitle = '';
  if (isLastPage) {
    continueButtonTitle = submitCalled ? 'Submitted' : 'Submit';
  } else {
    continueButtonTitle = summaryVisited ? 'Return to submit' : 'Continue';
  }

  const backButtonTitle = isPso && !isInRegistrationForm ? 'Exit' : 'Back';

  return (
    <FooterContainer id={componentId}>
      <div id={`${navComponentId}-left`}>
        {!loadingPage && isPso && !hasConflicts && !isFirstPage && (
          <Button size="large" data-testid={`${navComponentId}-back`} onClick={(): void => handleClickOnBack()}>
            {backButtonTitle}
          </Button>
        )}

        {!isMobile && !loadingPage && (!isPso || (isPso && isInRegistrationForm)) && (
          <Button
            size="large"
            data-testid={`${navComponentId}-save-and-exit`}
            onClick={(): void => handleSaveAndExit()}>
            {isRecordLocked ? 'Exit' : 'Save and exit'}
          </Button>
        )}
      </div>
      <Stack id={`${navComponentId}-right`} direction="row" gap="16px">
        {isInRegistrationForm && hasConflicts && (
          <Button
            size="large"
            onClick={(): void => handleResolveConflict()}
            data-testid={`${navComponentId}-resolve-conflicts`}
            disabled={resolveConflictsIncomplete}
            variant="contained">
            Resolve conflicts
          </Button>
        )}

        {!loadingPage && showPrintPdfButton && !isInRegistrationForm && isPso && (
          <Button
            size="large"
            variant="contained"
            data-testid={`${navComponentId}-pdf`}
            onClick={(): void => handleClickOnContinue()}>
            Print PDF
          </Button>
        )}

        {!loadingPage && !hasConflicts && !isPso && !isFirstPage && (
          <Button size="large" data-testid={`${navComponentId}-back`} onClick={(): void => handleClickOnBack()}>
            {backButtonTitle}
          </Button>
        )}

        {!hasConflicts && showContinueButton && (
          <Button
            size="large"
            id={`${navComponentId}-submit`}
            variant="contained"
            disabled={isRecordLocked}
            data-testid={`${navComponentId}-${isLastPage ? 'submit' : 'continue'}`}
            onClick={(): void => handleClickOnContinue()}>
            {continueButtonTitle}
          </Button>
        )}
      </Stack>
    </FooterContainer>
  );
};

export default PatientFormFooter;
