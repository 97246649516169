import { gql } from '@apollo/client';

export const GET_USER = gql`
  query User {
    user {
      id
      isNurse
      isPso
      isRo
      isMo
      isShowcaseUser
      isSuperuser
      isRegistrar
      name
      hasStaffId
      primaryRole
      timezone
      isPractitioner
      state
    }
  }
`;

export const GET_USER_PROFILE = gql`
  query UserAndLanguage {
    user {
      id
      isNurse
      isPso
      isRo
      isMo
      isShowcaseUser
      isSuperuser
      isRegistrar
      name
      hasStaffId
      primaryRole
      timezone
      isPractitioner
      state
    }
    language {
      language
    }
  }
`;

export const GET_INTERNAL_ONCOLOGISTS = gql`
  query InternalOncologists {
    internalOncologists {
      id
      name
    }
  }
`;

export const GET_PRACTITIONER = gql`
  query PractitionerQuery($patientId: ID!) {
    practitioner(patientId: $patientId) {
      id
      timezone
    }
  }
`;
