import React from 'react';
import { supportedSize } from 'shared-components/StyledComponents/breakpoints';
import { styled } from '@mui/system';

export const MiddleContainerCol = styled('div')`
  max-height: 100%;
  height: 100%;
  padding: 0px;
  flex-shrink: 0;
  flex-grow: 1;
  flex-basis: 0;

  #form-footer {
    @media all and (max-width: ${supportedSize.transition}px) {
      width: 100%;
      position: fixed;
      right: 0;
      bottom: 0;
    }

    @media all and (max-width: ${supportedSize.tablet}px) {
      padding: 9px 0;
    }
  }
`;

export const RightContainerCol = styled('div')`
  border-left: 1px solid rgba(0, 0, 0, 0.2);
  @media all and (max-width: ${supportedSize.transition}px) {
    display: none;
  }
  @media (min-width: ${supportedSize.transition}px) {
    max-width: 16.66666667%;
    display: flex;
    flex: 0 0 16.66666667%;
  }
`;
