// eslint-disable-next-line no-use-before-define
import { Field } from 'formik';
import React from 'react';
import { generatePath } from 'react-router';
import { FormRow } from 'shared-components/components';
import { Info } from 'shared-components/images';
import { SelectOptions, ListData } from 'shared-components/interfaces';
import { supportedSize } from 'shared-components/StyledComponents/breakpoints';
import { styled } from '@mui/system';

// CONSTANTS
export const CONTINUE_BUTTON_TEXT = 'Continue';
export const RETURN_TO_SUBMIT_TEXT = 'Return to submit';
export const VALUE_REQUIRED = 'This field is required';
export const PHONE_VALIDATION = 'Please enter a valid phone number';
export const PHONE_REQUIRED = 'Please enter at least one phone number';
export const PSO_SUMMARY_LINK = '/navigator/patient/:patientId/summary';
export const SPECIFY_MARITAL_FIELDS = ['Married', 'De Facto', 'Domestic Partner'];

export const EMPTY_ADDRESS = {
  country: '',
  line1: '',
  line2: '',
  city: '',
  state: '',
  postcode: '',
};

// STYLED COMPONENTS
export const HeaderTitle = styled('div')`
  font-size: 24px;
  line-height: 28px;
  font-weight: 500;
  margin-bottom: 8px;
`;

export const SectionTitle = styled('div')`
  font-weight: 700;
  font-size: 18px;
  line-height: 28px;

  color: ${(props) => props.theme.palette.text.primary};
`;

export const HeaderSubTitle = styled('div')`
  margin-bottom: 30px;
`;

export const StyledIndentedSection = styled('div')`
  border-left: 1px solid ${(props) => props.theme.palette.grey[300]};
  label {
    margin-left: 16px;
    @media all and (max-width: ${supportedSize.transition}px) {
      margin-left: 0;
    }
  }
  padding-left: 10px;
  @media all and (max-width: ${supportedSize.transition}px) {
    padding-left: 18px;
  }
  margin-top: 10px;
`;

export const StyledHorizontalLine = styled('div')`
  margin-top: -5px !important;
  margin-bottom: 5px !important;
  width: 100%;
  height: 10px;
  border-bottom: 1px solid black;
  border-color: ${(props) => props.theme.palette.grey[300]};
  text-align: center;

  span {
    font-size: 13px;
    background-color: white;
    padding: 0 20px;
    color: ${(props) => props.theme.palette.grey[600]};
  }
`;

const ExtraInformation = styled('div')`
  margin-top: 6px;
  color: ${(props) => props.theme.palette.info.main};
  font-size: 13px;
  display: flex;
  align-items: center;
`;

const StyledInfoIcon = styled(Info)`
  fill: ${(props) => props.theme.palette.info.main};
  width: 16px;
  height: 16px;
  margin-right: 6px;
`;

export const extraInformation = (name?: string): JSX.Element => (
  <ExtraInformation>
    <StyledInfoIcon />
    <span data-test-id={['extra', 'info', name ?? 'generic'].join('-')}>
      Please provide a copy of the document to the clinic
    </span>
  </ExtraInformation>
);

export const yesNoOptions = [
  { label: 'Yes', value: true },
  { label: 'No', value: false },
];

// FUNCTIONS

export const getDisplayValue = (refData: ListData[], option: string): string => {
  const filtered = refData?.filter((item: ListData) => item.id === option);
  return filtered?.length ? filtered[0].name : option;
};

export const sharedFormContainerProps = (
  sectionTitle: string,
  pageNumber?: number | undefined,
  previousPageLink?: string,
  isShowSaveExitConfirm = true,
): any => {
  return {
    isLocked: false,
    updatedBy: '',
    deleteModal: () => {},
    showSaveAndExit: true,
    pageNumber,
    sectionTitle: sectionTitle,
    showDiscard: false,
    sidePanelWidth: '-15%',
    footerPosition: 'relative',
    isLoading: false,
    isShowSaveExitConfirm: isShowSaveExitConfirm,
    backLink: previousPageLink,
  };
};

export const registrationPath = (patientId: string | number, suffix: string): string => {
  return generatePath(`/patient/${patientId}/registration/${suffix}`);
};

export interface StandardFieldProps {
  NAME: string;
  COMPONENT: any;
  TITLE: string;
  PLACE_HOLDER?: string;
}

export const standardField = (
  field: StandardFieldProps,
  updateField: (field: string, value: string | number | boolean) => void,
  options?: SelectOptions[],
  alternateStyle = true,
): JSX.Element => (
  <FormRow fieldLabel={field.TITLE} fieldName={field.NAME} labelClass={'label-form-row'}>
    <Field
      name={field.NAME}
      component={field.COMPONENT}
      alternateStyle={alternateStyle}
      updateMutation={(value: string) => updateField(field.NAME, value)}
      handleMutation={updateField}
      placeholder={field.PLACE_HOLDER}
      options={options || []}
    />
  </FormRow>
);

export const linkedField = (
  field: StandardFieldProps,
  linkedField: StandardFieldProps,
  updateField: (field: string, value: string | number) => void,
  touchField: (field: string, isTouched: boolean) => void,
): JSX.Element => (
  <FormRow fieldLabel={field.TITLE} fieldName={field.NAME} labelClass={'label-form-row'}>
    <Field
      name={field.NAME}
      component={field.COMPONENT}
      alternateStyle
      updateMutation={(value: string) => {
        touchField(linkedField.NAME, true);
        updateField(field.NAME, value);
      }}
      placeholder={field.PLACE_HOLDER}
    />
  </FormRow>
);
