import { gql } from '@apollo/client';
export const USER_FEATURE_QUERY = gql`
  query userFeatureList($featureName: String) {
    userFeatureList(featureName: $featureName) {
      id
      feature {
        id
        name
      }
      featureOption {
        id
        name
      }
    }
    featureList {
      id
      feature {
        id
        name
      }
      options {
        id
        name
      }
      default {
        id
        name
      }
    }
  }
`;
