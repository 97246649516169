export const HOME_CONTENT = {
  HEADER: 'Introducing the Patient Portal',
  SUBHEADER: 'Our new portal offers you a way to connect to us and to your treatment plan when you need it most.',
  LOGIN_HEADER: 'Log in to Patient Portal',
  INFO_TILE: {
    ALL_DEVICES: {
      header: 'On all devices',
      body: 'View on your mobile, tablet or desktop.',
    },
    APPOINTMENTS: {
      header: 'Appointments',
      body: 'Be up-to-date with your schedule and plan ahead.',
    },
    SAFE_SECURE: {
      header: 'Safe & Secure',
      body: 'Your personal data is secure with us.',
    },
    INFORMATION: {
      header: 'Information',
      body: "Be better informed on what's important to you.",
    },
  },
  TC_LINK: 'Terms & Conditions • Privacy Policy',
};
