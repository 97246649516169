// Currently validation is determined by wether a the desired field has any value populated

let REACT_APP_BYPASS_HRO_FE_VALIDATION = import.meta.env.REACT_APP_BYPASS_HRO_FE_VALIDATION;
export const setReactAppValidation = (val: string) => {
  REACT_APP_BYPASS_HRO_FE_VALIDATION = val;
};

export const NULL_VALUES = ['', 'null', 'None', 'NaN'];

export interface ROValidationDependencyStructure {
  parent: string | null;
  expansionValue?: any | null;
  expansionCondition?: (parent: any) => {};
  children: string[];
}

/*
    Sample structure:

    const validationDependencyStructure = [
      {
        parent: null,
        expansionValue: null,
        children: [
          'intent',
          'treatingDepartment',
          'readyForCareDate',
          'delayCategory',
          'cied',
          'previousRadioTreatment',
        ],
      },
      { parent: 'previousRadioTreatment', expansionValue: 'yes', children: ['previousCompositePlanRequired'] },
      { parent: 'cied', expansionValue: 'yes', children: ['ciedProtocolRequired', 'preTreatmentCiedCheckRequired'] },
    ];
*/

const validationCandidates = (lookup: any, items: string[]) => items.map((item: any) => lookup[item]);

/* Determine whether a field matches the requirements of
    Values (django/ro_portal/models/careplan_values/values.py).
    This provides the ability to determine whether a field should be
    evaluated by it's internal `value` */
const isValue = (field: any): boolean =>
  typeof field === 'object' && field && field.hasOwnProperty('isShown') && field.hasOwnProperty('value');

/*
This is used to extract a child `value` from an object if it exists or return the current value

Useful for conditional fields
*/

const extractValue = (field: any): any => {
  return isValue(field) ? field.value : field;
};

export const validateField = (field: any): any => {
  if (REACT_APP_BYPASS_HRO_FE_VALIDATION === 'Y') return true;
  // When given an array, all values must be valid
  if (Array.isArray(field)) {
    return !field
      .map((item) => {
        return Boolean(item) && !NULL_VALUES.includes(item);
      })
      .includes(false);
  }
  const extractedValue = extractValue(field);
  const valid = Boolean(extractedValue) && !NULL_VALUES.includes(extractedValue);
  return valid;
};

const allFieldsValid = (lookup: any, items: string[]): boolean =>
  !validationCandidates(lookup, items)
    .map((item: any): boolean => validateField(item))
    .includes(false);

export const isPageValid = (dependencyStructure: ROValidationDependencyStructure[], data: any): boolean => {
  if (REACT_APP_BYPASS_HRO_FE_VALIDATION === 'Y') return true;
  if (!Boolean(data)) return false;
  return !dependencyStructure
    .map((subset) => {
      const parentValue = subset.parent ? data[subset.parent] : null;
      if (!subset.parent && !subset.expansionCondition) {
        return allFieldsValid(data, subset.children);
      }

      // Don't validate when an expansion condition is provided and not met
      if (subset.expansionCondition && subset.expansionCondition(parentValue) === false) {
        return true;
      }
      const parentRequirementWasMet =
        // When we have an expansion condition and an expansion value
        (subset.expansionCondition !== undefined &&
          subset.hasOwnProperty('expansionValue') &&
          subset.expansionCondition(parentValue) &&
          subset.expansionValue === extractValue(parentValue)) ||
        // When we have an expansion condition without an expansion value
        (subset.expansionCondition !== undefined &&
          !subset.hasOwnProperty('expansionValue') &&
          subset.expansionCondition(parentValue));

      // eslint-disable-next-line eqeqeq
      const parentRequirementNotMet = extractValue(parentValue) != subset.expansionValue;

      // Evaluate validation when expansion condition & value are met
      if (parentRequirementWasMet) {
        return allFieldsValid(data, subset.children);
      }

      // When there is a parent dependency but the expansion condition is not met, we should not evalutate it for validation. Therefore we return true
      if (parentRequirementNotMet) {
        return true;
      }

      return allFieldsValid(data, subset.children);
    })
    .includes(false);
};
