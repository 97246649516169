// eslint-disable-next-line no-use-before-define
import React, { Component, Fragment } from 'react';
import validate from 'validate.js';

import { Region } from 'shared-components/enums';
import { PatientAlternateContactBase, Patient } from 'op-interfaces';
import { ListData } from 'shared-components/interfaces';
import { SegmentedInputBoolean, PHONE_NUMBER_REGEX } from 'shared-components/utils';
import { DropDownField, FreeTextField, SectionField, SegmentedInput } from 'shared-components/components/FormFields';
import { Dictionary } from 'shared-components/interfaces';
import { applyRequired } from '../../../pages/OP/RegistrationForm/utils';
import { Info } from 'shared-components/images';
const REACT_APP_REGION = import.meta.env.REACT_APP_REGION;

const region = REACT_APP_REGION;

// Constants
const FIELD_NAMES: { [key: string]: string } = {
  FIRST_NAME: 'firstName',
  LAST_NAME: 'lastName',
  RELATIONSHIP: 'relationship',
  MOBILE_NUMBER: 'mobilePhoneNumber',
  HOME_NUMBER: 'homePhoneNumber',
  EMAIL_ADDRESS: 'email',
  AUTHORISED_STATUS: 'authorisedForEnquiries',
  SUPPORT_PERSON: 'supportPerson',
};

const FIELD_SIZE_LIMITS = {
  FIRST_NAME: 40,
  LAST_NAME: 40,
  MOBILE_NUMBER: 25,
  HOME_NUMBER: 25,
  EMAIL_ADDRESS: 40,
};

const FIELD_HEADINGS = {
  FIRST_NAME: 'First name',
  LAST_NAME: 'Last name',
  RELATIONSHIP: 'Relationship',
  MOBILE_NUMBER: 'Mobile phone number',
  HOME_NUMBER: 'Landline phone number',
  EMAIL_ADDRESS: 'Email',
  AUTHORISED_STATUS: 'Authorised to make enquiries for appointment times?',
  SUPPORT_PERSON: 'Identified as support person in making decisions',
};

const FIELD_PLACEHOLDERS = {
  FIRST_NAME: 'Enter first name',
  LAST_NAME: 'Enter last name',
  RELATIONSHIP: 'Select relationship',
  MOBILE_NUMBER: 'Enter mobile',
  HOME_NUMBER: 'Please include area code e.g. 02 8236 3300',
  EMAIL_ADDRESS: 'Enter email address',
};

const FIELD_INCOMPLETE_INPUT_ERRORS = {
  FIRST_NAME: 'Please enter First name.',
  LAST_NAME: 'Please enter Last name.',
  RELATIONSHIP: 'Please select relationship from the drop-down menu.',
  MOBILE_NUMBER: 'Please enter emergency contact home or mobile phone number.',
  HOME_NUMBER: 'Please enter emergency contact home or mobile phone number.',
  AUTHORISED_STATUS: 'Please select an option.',
  SUPPORT_PERSON: 'Please select an option.',
};

const FIELD_INVALID_INPUT_ERRORS = {
  FIRST_NAME: 'Please enter a valid format for First name.',
  LAST_NAME: 'Please enter a valid format for Last name.',
  MOBILE_NUMBER: 'Please enter a valid mobile number.',
  HOME_NUMBER: 'Please enter a valid home number.',
  EMAIL_ADDRESS: 'Please enter a valid email address.',
  AUTHORISED_STATUS: 'Please select an option',
  SUPPORT_PERSON: 'Please select an option',
};

interface Props {
  altContactInfo: PatientAlternateContactBase;
  patient: Patient;
  inputName: string;
  autosave: (patient: Patient, updateObject: object) => Promise<void>;
  relationshipsReferenceData: ListData[];
  validateOnLoad: boolean;
  isPso: boolean;
}

interface State {
  viewed: Set<string>;
  altContactInfo: PatientAlternateContactBase;
}

class AlternateContactDetails extends Component<Props, State> {
  public static defaultProps = {
    inputName: 'emergency',
  };

  public constructor(props: Props) {
    super(props);

    this.state = {
      viewed: new Set<string>(),
      altContactInfo: props.altContactInfo,
    };
  }

  public static getDerivedStateFromProps(props: Props, state: State): State {
    if (props.validateOnLoad && props.patient.lock && !props.patient.lock.readOnly) {
      // Add all the field's to viewed - this will trigger validation on the whole form
      const fields = Object.keys(FIELD_NAMES).map((keyName: string): string => {
        return FIELD_NAMES[keyName];
      });
      const viewed = new Set(fields);
      return { altContactInfo: props.altContactInfo, viewed: viewed };
    }

    return state;
  }

  public render(): JSX.Element {
    const { patient, relationshipsReferenceData, inputName, isPso } = this.props;
    const altContactInfo = this.state.altContactInfo;
    const validationObject = this.validateObject(altContactInfo);
    const relationshipId = relationshipsReferenceData.find(
      (relationship: ListData) => relationship.name === altContactInfo.relationship,
    )?.id;

    // @ts-ignore
    const autosave = (key, value) => this.autosave(altContactInfo, key, value);
    return (
      <Fragment>
        <SectionField
          isValid={validationObject && validationObject.firstName ? false : true}
          htmlFor={this.getInputNameCamelCase(FIELD_NAMES.FIRST_NAME)}
          title={applyRequired({ title: FIELD_HEADINGS.FIRST_NAME, category: inputName, optionalForPSO: isPso })}>
          <FreeTextField
            disabled={patient.lock && patient.lock.readOnly}
            inputName={this.getInputNameCamelCase(FIELD_NAMES.FIRST_NAME)}
            maxLength={FIELD_SIZE_LIMITS.FIRST_NAME}
            placeholder={FIELD_PLACEHOLDERS.FIRST_NAME}
            defaultValue={altContactInfo.firstName}
            onBlur={(e): Promise<void> => autosave(FIELD_NAMES.FIRST_NAME, e.target.value)}
            errors={validationObject && validationObject.firstName ? validationObject.firstName : undefined}
          />
        </SectionField>
        <SectionField
          isValid={validationObject && validationObject.lastName ? false : true}
          htmlFor={this.getInputNameCamelCase(FIELD_NAMES.LAST_NAME)}
          title={applyRequired({ title: FIELD_HEADINGS.LAST_NAME, category: inputName, optionalForPSO: isPso })}>
          <FreeTextField
            disabled={patient.lock && patient.lock.readOnly}
            inputName={this.getInputNameCamelCase(FIELD_NAMES.LAST_NAME)}
            maxLength={FIELD_SIZE_LIMITS.LAST_NAME}
            placeholder={FIELD_PLACEHOLDERS.LAST_NAME}
            defaultValue={altContactInfo.lastName}
            onBlur={(e): Promise<void> => autosave(FIELD_NAMES.LAST_NAME, e.target.value)}
            errors={validationObject && validationObject.lastName ? validationObject.lastName : undefined}
          />
        </SectionField>
        <SectionField
          isValid={validationObject && validationObject.relationship ? false : true}
          htmlFor={this.getInputNameCamelCase(FIELD_NAMES.RELATIONSHIP)}
          title={applyRequired({ title: FIELD_HEADINGS.RELATIONSHIP, category: inputName, optionalForPSO: isPso })}>
          <DropDownField
            disabled={patient.lock && patient.lock.readOnly}
            inputName={this.getInputNameCamelCase(FIELD_NAMES.RELATIONSHIP)}
            placeholder={FIELD_PLACEHOLDERS.RELATIONSHIP}
            defaultValue={relationshipId}
            options={relationshipsReferenceData}
            onChange={(e): Promise<void> => autosave(FIELD_NAMES.RELATIONSHIP, e.target.value)}
            errors={validationObject && validationObject.relationship ? validationObject.relationship : undefined}
          />
        </SectionField>
        {region === Region.AU && !isPso && (
          <div className="phone-required-message">
            <Info /> <span>At least one phone number is required</span>
          </div>
        )}
        <SectionField
          isValid={validationObject && validationObject.mobilePhoneNumber ? false : true}
          htmlFor={this.getInputNameCamelCase(FIELD_NAMES.MOBILE_NUMBER)}
          title={applyRequired({ title: FIELD_HEADINGS.MOBILE_NUMBER, category: inputName, optionalForPSO: isPso })}>
          <FreeTextField
            disabled={patient.lock && patient.lock.readOnly}
            inputName={this.getInputNameCamelCase(FIELD_NAMES.MOBILE_NUMBER)}
            maxLength={FIELD_SIZE_LIMITS.MOBILE_NUMBER}
            placeholder={FIELD_PLACEHOLDERS.MOBILE_NUMBER}
            defaultValue={altContactInfo.mobilePhoneNumber}
            onBlur={(e): Promise<void> => autosave(FIELD_NAMES.MOBILE_NUMBER, e.target.value)}
            errors={
              validationObject && validationObject.mobilePhoneNumber ? validationObject.mobilePhoneNumber : undefined
            }
          />
        </SectionField>
        <SectionField
          isValid={validationObject && validationObject.homePhoneNumber ? false : true}
          htmlFor={this.getInputNameCamelCase(FIELD_NAMES.HOME_NUMBER)}
          title={applyRequired({ title: FIELD_HEADINGS.HOME_NUMBER, category: inputName, optionalForPSO: isPso })}>
          <FreeTextField
            disabled={patient.lock && patient.lock.readOnly}
            inputName={this.getInputNameCamelCase(FIELD_NAMES.HOME_NUMBER)}
            maxLength={FIELD_SIZE_LIMITS.HOME_NUMBER}
            placeholder={FIELD_PLACEHOLDERS.HOME_NUMBER}
            defaultValue={altContactInfo.homePhoneNumber}
            onBlur={(e): Promise<void> => autosave(FIELD_NAMES.HOME_NUMBER, e.target.value)}
            errors={validationObject && validationObject.homePhoneNumber ? validationObject.homePhoneNumber : undefined}
          />
        </SectionField>
        <SectionField
          isValid={validationObject && validationObject.email ? false : true}
          htmlFor={this.getInputNameCamelCase(FIELD_NAMES.EMAIL_ADDRESS)}
          title={FIELD_HEADINGS.EMAIL_ADDRESS}
          required={false}>
          <FreeTextField
            disabled={patient.lock && patient.lock.readOnly}
            inputName={this.getInputNameCamelCase(FIELD_NAMES.EMAIL_ADDRESS)}
            maxLength={FIELD_SIZE_LIMITS.EMAIL_ADDRESS}
            placeholder={FIELD_PLACEHOLDERS.EMAIL_ADDRESS}
            defaultValue={altContactInfo.email}
            onBlur={(e): Promise<void> => autosave(FIELD_NAMES.EMAIL_ADDRESS, e.target.value)}
            errors={validationObject && validationObject.email ? validationObject.email : undefined}
            inputType={'email'}
          />
        </SectionField>
        <SectionField
          isValid={validationObject && validationObject.authorisedForEnquiries ? false : true}
          htmlFor={this.getInputNameCamelCase(FIELD_NAMES.AUTHORISED_STATUS)}
          title={applyRequired({
            title: FIELD_HEADINGS.AUTHORISED_STATUS,
            category: inputName,
            optionalForPSO: isPso,
          })}>
          <SegmentedInput
            disabled={patient.lock && patient.lock.readOnly}
            fieldName={this.getInputNameCamelCase(FIELD_NAMES.AUTHORISED_STATUS)}
            options={SegmentedInputBoolean}
            optionAreBoolean={true}
            defaultSelected={altContactInfo.authorisedForEnquiries}
            itemSelected={(selectedItem): void => {
              const booleanValue = selectedItem as boolean;
              autosave(FIELD_NAMES.AUTHORISED_STATUS, booleanValue);
            }}
            errors={
              validationObject && validationObject.authorisedForEnquiries
                ? validationObject.authorisedForEnquiries
                : undefined
            }
          />
        </SectionField>
        <SectionField
          isValid={validationObject && validationObject.supportPerson ? false : true}
          htmlFor={this.getInputNameCamelCase(FIELD_NAMES.SUPPORT_PERSON)}
          title={applyRequired({
            title: FIELD_HEADINGS.SUPPORT_PERSON,
            category: inputName,
            optionalForPSO: isPso,
          })}>
          <SegmentedInput
            disabled={patient.lock && patient.lock.readOnly}
            fieldName={this.getInputNameCamelCase(FIELD_NAMES.SUPPORT_PERSON)}
            options={SegmentedInputBoolean}
            optionAreBoolean={true}
            defaultSelected={altContactInfo.supportPerson}
            itemSelected={(selectedItem): void => {
              const booleanValue = selectedItem as boolean;
              autosave(FIELD_NAMES.SUPPORT_PERSON, booleanValue);
            }}
            errors={validationObject && validationObject.supportPerson ? validationObject.supportPerson : undefined}
          />
        </SectionField>
      </Fragment>
    );
  }

  /**
   * Autosave functionality that will call the props auto save function.
   */
  private autosave = async (altContactInfo: any, key: string, value: string | boolean): Promise<void> => {
    // Convert this into an object that can be saved
    const { patient, inputName } = this.props;
    altContactInfo[key] = value;
    this.setState({ altContactInfo });
    const updateObject: Dictionary = {
      id: patient.id,
    };
    const innerObject: Dictionary = {};
    innerObject[key] = value;

    // Add the inner object to the update object
    updateObject[`${inputName}Contact`] = innerObject;

    await this.props.autosave(patient, updateObject).then(() => {
      const viewed = this.state.viewed.add(key);
      this.setState({ viewed });
    });
  };

  private validateObject = (patient: PatientAlternateContactBase): any => {
    // Global rules for the validator, must match the patient interface keys
    const globalValidationRules: { [key: string]: object } = {
      firstName: {
        presence: {
          allowEmpty: false,
          message: FIELD_INCOMPLETE_INPUT_ERRORS.FIRST_NAME,
        },
        format: {
          pattern: '[A-Za-z0-9\\s_.,!"\'-]*',
          message: FIELD_INVALID_INPUT_ERRORS.FIRST_NAME,
        },
      },
      lastName: {
        presence: {
          allowEmpty: false,
          message: FIELD_INCOMPLETE_INPUT_ERRORS.LAST_NAME,
        },
        format: {
          pattern: '[A-Za-z0-9\\s_.,!"\'-]*',
          message: FIELD_INVALID_INPUT_ERRORS.LAST_NAME,
        },
      },
      relationship: {
        presence: {
          allowEmpty: false,
          message: FIELD_INCOMPLETE_INPUT_ERRORS.RELATIONSHIP,
        },
      },
      mobilePhoneNumber: {
        presence: {
          allowEmpty: !patient.homePhoneNumber ? false : true,
          message: FIELD_INCOMPLETE_INPUT_ERRORS.MOBILE_NUMBER,
        },
        format: {
          pattern: PHONE_NUMBER_REGEX,
          message: FIELD_INVALID_INPUT_ERRORS.MOBILE_NUMBER,
        },
      },
      homePhoneNumber: {
        presence: {
          allowEmpty: !patient.mobilePhoneNumber ? false : true,
          message: FIELD_INCOMPLETE_INPUT_ERRORS.HOME_NUMBER,
        },
        format: {
          pattern: PHONE_NUMBER_REGEX,
          message: FIELD_INVALID_INPUT_ERRORS.HOME_NUMBER,
        },
      },
      email: {
        presence: {
          allowEmpty: true,
        },
        format: {
          pattern: '^[a-zA-Z0-9\\_\\-\\.]+@[a-zA-Z0-9\\-\\.]+(\\.[a-zA-Z]+)+$|',
          message: FIELD_INVALID_INPUT_ERRORS.EMAIL_ADDRESS,
        },
      },
      authorisedForEnquiries: {
        presence: {
          allowEmpty: false,
          message: FIELD_INCOMPLETE_INPUT_ERRORS.AUTHORISED_STATUS,
        },
      },
      supportPerson: {
        presence: {
          allowEmpty: false,
          message: FIELD_INCOMPLETE_INPUT_ERRORS.SUPPORT_PERSON,
        },
      },
    };

    // Ensure fields that been viewed only have validation run on them
    const specificValidationRules: { [key: string]: object } = {};
    for (const viewed of this.state.viewed.keys()) {
      specificValidationRules[viewed] = globalValidationRules[viewed];
    }
    // Disable pre-appending of argument name to error messages
    const disableFullMessages = { fullMessages: false };
    // Run validation on all the fields
    return validate(patient, specificValidationRules, disableFullMessages);
  };

  /**
   * Function to generate the camel case variant of a field name.
   * Used to set the inputName attributes on form fields.
   * @param {string} fieldName: The
   */
  private getInputNameCamelCase = (fieldName: string): string => {
    const { inputName } = this.props;
    const firstLetter = fieldName[0].toUpperCase();
    const remainingLetters = fieldName.substr(1);

    return `${inputName}${firstLetter}${remainingLetters}`;
  };
}

//@ts-ignore
export default AlternateContactDetails;
