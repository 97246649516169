import { gql } from '@apollo/client';

export const REGISTRATION_BASIC_DETAILS_QUERY = gql`
  query RegistrationBasicDetails($id: ID!) {
    patient(id: $id) {
      id
      firstName
      middleName
      lastName
      gender
      genderIdentification
      genderIdentificationOther
      dob
      namePrefix
      preferredName
    }
    nameTitle: listData(category: "nameTitle") {
      id
      name
    }
    genderRefData: listData(category: "gender") {
      id
      name
    }
    genderIdentificationRefData: listData(category: "genderIdentification") {
      id
      name
    }
    user {
      id
      isPso
    }
  }
`;

export const UPDATE_REGISTRATION_BASIC_DETAILS = gql`
  mutation UpdatePatientUsBasic(
    $id: ID!
    $firstName: String
    $middleName: String
    $lastName: String
    $namePrefix: String
    $preferredName: String
    $gender: String
    $genderIdentification: String
    $genderIdentificationOther: String
    $dob: String
  ) {
    updatePatientUsBasic(
      id: $id
      firstName: $firstName
      middleName: $middleName
      lastName: $lastName
      namePrefix: $namePrefix
      preferredName: $preferredName
      gender: $gender
      genderIdentification: $genderIdentification
      genderIdentificationOther: $genderIdentificationOther
      dob: $dob
    ) {
      patient {
        id
        firstName
        middleName
        lastName
        namePrefix
        preferredName
        gender
        genderIdentification
        genderIdentificationOther
        dob
      }
    }
  }
`;
