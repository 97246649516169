import React from 'react';
import { CardTitle, CardBody, CardBodyGroup, CardBodyBold } from './styles';
import CONSTANTS from './constants';
import { CurrentAppConfig } from 'op-pages/RO/Careplan/AppConfig';
import { useReferrers } from '../constants';

interface AllReferrers {
  id: string;
  relationshipType?: string;
  practitioner: {
    firstName: string;
    id: string;
    lastName: string;
    phone?: string;
    qualification?: string;
  };
}

interface Props {
  name: string;
  allReferrers: AllReferrers[];
}

const TreatmentTeamCard = ({ name, allReferrers }: Props): JSX.Element => {
  const { CONTACT, REFERRING } = CONSTANTS;
  const { responsiblePhysician, primaryPhysician, referringSurgeon } = useReferrers(allReferrers);

  return (
    <div data-testid="treatmentTeamCard">
      <CardTitle>{name}</CardTitle>
      <CardBody>
        <CardBodyGroup data-testid="responsiblePhysician">
          {CurrentAppConfig.ReferringPhysicians.oncologist}
          <CardBodyBold>
            {responsiblePhysician
              ? `${responsiblePhysician.practitioner.firstName} ${responsiblePhysician.practitioner.lastName}`
              : '-'}
          </CardBodyBold>
        </CardBodyGroup>
        <CardBodyGroup data-testid="primaryPhysician">
          {CurrentAppConfig.ReferringPhysicians.generalPractitioner}
          <CardBodyBold>
            {primaryPhysician
              ? `${primaryPhysician.practitioner.firstName} ${primaryPhysician.practitioner.lastName}`
              : '-'}
          </CardBodyBold>
          {primaryPhysician?.practitioner?.phone && (
            <CardBodyBold>
              {CONTACT}: {primaryPhysician.practitioner.phone}
            </CardBodyBold>
          )}
        </CardBodyGroup>
        {referringSurgeon.length > 0 ? (
          referringSurgeon.map((item: any, index: number) => (
            <CardBodyGroup key={`${item.practitioner.firstName}-${index}`} data-testid={`referringPhysician-${index}`}>
              {
                CurrentAppConfig.ReferringPhysicians[
                  item.relationshipType as keyof typeof CurrentAppConfig.ReferringPhysicians
                ]
              }
              <CardBodyBold>
                {item.practitioner.firstName} {item.practitioner.lastName}
              </CardBodyBold>
              {item.practitioner.phone && (
                <CardBodyBold>
                  {CONTACT}: {item.practitioner.phone}
                </CardBodyBold>
              )}
            </CardBodyGroup>
          ))
        ) : (
          <CardBodyGroup>
            {REFERRING}
            <CardBodyBold>-</CardBodyBold>
          </CardBodyGroup>
        )}
      </CardBody>
    </div>
  );
};

export default TreatmentTeamCard;
