import { TextAreaField } from 'shared-components/components/FormikComponents';

export const FIELDS = {
  FORM_HEADING: {
    NAME: 'formHeading',
    TITLE: 'Referrers',
  },
  REFERRING_FIRST_NAME: {
    NAME: 'referringFirstName',
    TITLE: 'First name',
    PLACE_HOLDER: 'Enter first name',
    COMPONENT: TextAreaField,
  },
  REFERRING_LAST_NAME: {
    NAME: 'referringLastName',
    TITLE: 'Last name',
    PLACE_HOLDER: 'Enter last name',
    COMPONENT: TextAreaField,
  },
  REFERRING_PRACTICE: {
    NAME: 'referringPractice',
    TITLE: 'Practice / medical centre',
    PLACE_HOLDER: 'Enter practice / medical centre /name',
    COMPONENT: TextAreaField,
  },
  GP_FIRST_NAME: {
    NAME: 'gpFirstName',
    TITLE: 'First name',
    PLACE_HOLDER: 'Enter first name',
    COMPONENT: TextAreaField,
  },
  GP_LAST_NAME: {
    NAME: 'gpLastName',
    TITLE: 'Last name',
    PLACE_HOLDER: 'Enter last name',
    COMPONENT: TextAreaField,
  },
  GP_PRACTICE: {
    NAME: 'gpPractice',
    TITLE: 'Practice / medical centre',
    PLACE_HOLDER: 'Enter practice / medical centre /name',
    COMPONENT: TextAreaField,
  },
  OTHER_SPECIALISTS: {
    NAME: 'referrersOtherSpecialists',
    TITLE: 'Names of other specialists',
    PLACE_HOLDER: 'E.g. John Smith, Sally Thomas...',
    COMPONENT: TextAreaField,
  },
};
