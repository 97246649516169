import React from 'react';
import { GridSection } from 'shared-components/components/Grid';
import { supportedSize } from 'shared-components/StyledComponents/breakpoints';
import { styled } from '@mui/system';

const StyledSideDrawerContainer = styled(GridSection)<{ isOpen?: boolean }>`
  margin-left: 16px;
  flex-grow: 1;
  background-color: white;
  padding: 0;
  display: flex;
  height: 100%;

  ${({ isOpen }: { isOpen?: boolean }) => `
    @media screen and (max-width: ${supportedSize.desktop - 1}px) {
      width: 35%;
      position: absolute;
      right: 0;
      transform: translateX(${isOpen ? '0' : '100%'});
      transition: transform 0.2s ease-in-out;
      max-height: calc(100% - 32px);
    }`}
`;

const SideDrawerContent = styled('div')`
  display: flex;
  flex-direction: column;
  height: 100%;
  position: relative;
  width: 100%;
`;

interface IProps extends React.HTMLAttributes<HTMLElement> {
  isOpen?: boolean;
  children?: React.ReactNode;
  showToggleBtn?: boolean;
  toggleBtn?: React.ReactNode;
}

const SideDrawer = ({ isOpen, children, showToggleBtn, toggleBtn, ...other }: IProps): JSX.Element => {
  return (
    <StyledSideDrawerContainer isOpen={isOpen} {...other}>
      <SideDrawerContent className="overflow-auto">{children}</SideDrawerContent>
      {showToggleBtn && toggleBtn}
    </StyledSideDrawerContainer>
  );
};

export default SideDrawer;
