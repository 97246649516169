import { gql } from '@apollo/client';

const DISTRESS_THERMOMETER_VERSION = gql`
  query DistressThermometer($patientId: ID!, $id: ID) {
    distressThermometer(patientId: $patientId, id: $id) {
      id
      version
    }
  }
`;

export default DISTRESS_THERMOMETER_VERSION;
