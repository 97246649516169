import React from 'react';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import Typography from '@mui/material/Typography';
import Grid from '@mui/material/Grid';
import { Box } from '@mui/material';
import Checkbox from '@mui/material/Checkbox';

import FormControlLabel from '@mui/material/FormControlLabel';
import { HtmlTooltip, TooltipContainer } from './ToolTip';

import ReportProblemIcon from '@mui/icons-material/ReportProblem';

import Chip from '@mui/material/Chip';
import { Link } from 'react-router-dom';

interface ListDataType {
  count: number;
  label: string;
}

const HEADERS = [
  'Patients',
  'First treatment',
  'Initial consult',
  'Pre-auth',
  'SIM request',
  'Prescription',
  'Compliance documents',
  'Simulation',
  'Fusion',
  'Target Volume',
  'Contouring',
  'Plan approval',
];

const statusToColor = (status: string) => {
  const status_string = status ? status.toLowerCase() : 'to do';
  switch (status_string) {
    case 'due today':
      return '#FFBD5B';
    case 'overdue':
      return '#FB6A76';
    case 'complete':
      return '#081E43';
    case 'completed':
      return '#081E43';
    default:
      return 'unset';
  }
};
const statusToIndeterminate = (status: string) => {
  const status_string = status ? status.toLowerCase() : 'to do';
  switch (status_string) {
    case 'due today':
      return true;
    case 'overdue':
      return true;
    case 'complete':
      return false;
    case 'completed':
      return false;
    default:
      return false;
  }
};

const isChecked = (status: string) => {
  return status === 'To do';
};

interface PatientCheckboxType {
  date: string;
  status: string;
  tooltipType: string;
  patientData: any;
  treatmentWeek?: number;
  enableTooltip?: boolean;
}
interface TriggeredAlertType {
  alertKey?: string;
  alertType?: string;
}

export const PatientCheckboxes = (props: PatientCheckboxType): JSX.Element => {
  const { date, status, treatmentWeek, patientData, tooltipType, enableTooltip } = props;
  const dateText = date === 'Not applicable' ? 'N/A' : date.replace('-2024', '');
  return (
    <HtmlTooltip
      disableHoverListener={!enableTooltip}
      title={<TooltipContainer tooltipType={tooltipType} patient={patientData} treatmentWeek={treatmentWeek} />}
      placement="bottom-start">
      <FormControlLabel
        value={dateText}
        control={
          <Checkbox
            style={{
              color: statusToColor(status),
            }}
            indeterminate={statusToIndeterminate(status)}
          />
        }
        label={dateText}
        labelPlacement="bottom"
        checked={!isChecked(status)}
      />
    </HtmlTooltip>
  );
};

const PatientCell = (props: any): JSX.Element => {
  const { patient } = props;
  const patientStatus = patient.patientStatus == 'Pretreatment' ? 'Pre-treatment' : patient.patientStatus;

  const getAlert = (patient: any): TriggeredAlertType => {
    const keys = Object.keys(patient);
    for (let i = 0; i < keys.length; i++) {
      const item = patient[keys[i]];

      if (item === 'Overdue' && keys[i] !== 'dynamicAlertCategory') {
        const alertKey = keys[i];
        const alertType = item;
        return { alertKey, alertType };
      } else if (item === 'Due today' && keys[i] !== 'dynamicAlertCategory') {
        const alertKey = keys[i];
        const alertType = item;
        return { alertKey, alertType };
      }
    }
    const alertKey = undefined;
    const alertType = undefined;
    return { alertKey, alertType };
  };
  const { alertKey, alertType } = getAlert(patient);

  return (
    <Grid container alignItems="left" sx={{ color: '#081E43' }}>
      <Grid item xs={12}>
        <Link
          to={`/radiation/patient/${patient.patientId}/summary`}
          style={{ textDecoration: 'none', color: '#081E43', padding: 0 }}>
          <Typography
            variant="h6"
            sx={{ fontWeight: 500, fontSize: '16px', textDecoration: 'underline', lineHeight: '20px' }}>
            {patient.patientName} ({patient.gender[0]})
          </Typography>
        </Link>
      </Grid>
      <Grid item xs={12}>
        <Typography variant="body2" sx={{ fontWeight: 400, fontSize: '14px' }}>
          MRN: {patient.MRN} | DOB: {patient.DOB}
        </Typography>
      </Grid>
      <Grid item xs={12}>
        <Typography variant="body2" sx={{ fontWeight: 400, color: 'rgba(8,	30,	67,.6)' }}>
          {patient.careplanName}
        </Typography>
      </Grid>

      <Grid item xs={12}>
        <Box
          sx={{
            display: 'flex',
            justifyContent: 'space-between',
            paddingTop: '10px',
          }}>
          <Chip
            label={patientStatus}
            style={{
              color: '#081E43',
              padding: '0px',
              backgroundColor: 'rgba(116,93,229,.12)',
              borderRadius: '100px',
              height: '30px',
              width: '145px',
              textTransform: 'capitalize',
            }}
          />
          {alertType && alertKey && (
            <HtmlTooltip
              title={<TooltipContainer tooltipType={alertKey.replace('Status', '')} patient={patient} />}
              placement="bottom-start">
              <ReportProblemIcon sx={{ color: statusToColor(alertType), marginLeft: '5px' }} />
            </HtmlTooltip>
          )}
        </Box>
      </Grid>
    </Grid>
  );
};

const FirstTreatmentCell = (props: any): JSX.Element => {
  const { patient } = props;
  return (
    <Grid container alignItems="left" sx={{ maxWidth: '250px' }} style={{ color: '#081E43' }}>
      <Grid item xs={12}>
        <Typography variant="body2" sx={{ fontWeight: 500 }}>
          {patient.firstTreatmentDate} {patient.firstTreatmentTime}
        </Typography>
      </Grid>
      <Grid item xs={12}>
        <Typography variant="body2" sx={{ fontWeight: 500 }}>
          Clinic: {patient.firstTreatmentLocation}
        </Typography>
      </Grid>
      <Grid item xs={12}>
        <Typography variant="body2" sx={{ fontWeight: 400, color: 'rgba(8,	30,	67,.6)' }}>
          Status: {patient.firstTreatmentStatus}
        </Typography>
      </Grid>
    </Grid>
  );
};

const PatientTable = (data: any) => {
  if (!data) return null;
  const { patientData } = data;

  return (
    <TableContainer
      component={Paper}
      sx={{
        margin: '0 auto',
        marginTop: '15px',
        color: '#081E43',
        overflowX: 'scroll',
        maxHeight: '90vh',
        backgroundColor: 'white',
        paddingBottom: '125px',
      }}>
      <Table sx={{ maxHeight: 500 }} stickyHeader>
        <TableHead>
          <TableRow>
            {HEADERS.map((header: string, index: number) => (
              <TableCell
                align={header == 'Patients' ? 'left' : 'center'}
                sx={{
                  fontWeight: 550,
                  minWidth: index == 0 ? '250px' : '100px',
                  maxWidth: index == 0 ? 'auto' : '125px',
                }}>
                {header}
              </TableCell>
            ))}
          </TableRow>
        </TableHead>
        <TableBody sx={{ overflow: 'auto' }}>
          {patientData.map((patient: any, index: any) => {
            return (
              <TableRow
                key={`${patient.patientName}+${index}`}
                sx={{ color: '#081E43', '&:last-child td, &:last-child th': { border: 0 } }}>
                <TableCell
                  align="left"
                  component="th"
                  scope="row"
                  sx={{ minWidth: '250px', maxWidth: '275px', borderRight: '1px solid lightgray' }}>
                  <PatientCell patient={patient} />
                </TableCell>
                <TableCell align="left" component="th" scope="row" sx={{ maxWidth: '150px' }}>
                  <FirstTreatmentCell patient={patient} />
                </TableCell>
                <TableCell align="center" component="th" scope="row" sx={{ maxWidth: '125px' }}>
                  <PatientCheckboxes
                    date={patient.initialConsultAppntDate}
                    status={patient.initialConsultStatus}
                    patientData={patient}
                    tooltipType={'initialConsult'}
                    enableTooltip={true}
                  />
                </TableCell>
                <TableCell align="center" component="th" scope="row" sx={{ maxWidth: '125px' }}>
                  <PatientCheckboxes
                    date={patient.preAuthDueDate}
                    status={patient.preAuthStatus}
                    patientData={patient}
                    tooltipType={'preAuth'}
                    enableTooltip={true}
                  />
                </TableCell>
                <TableCell align="center" component="th" scope="row" sx={{ maxWidth: '125px' }}>
                  <PatientCheckboxes
                    date={patient.SIMRequestDueDate}
                    status={patient.SIMRequestStatus}
                    patientData={patient}
                    tooltipType={'SIMRequest'}
                    enableTooltip={true}
                  />
                </TableCell>
                <TableCell align="center" component="th" scope="row" sx={{ maxWidth: '125px' }}>
                  <PatientCheckboxes
                    date={patient.prescriptionDueDate}
                    status={patient.prescriptionStatus}
                    patientData={patient}
                    tooltipType={'prescription'}
                    enableTooltip={true}
                  />
                </TableCell>
                <TableCell align="center" component="th" scope="row" sx={{ maxWidth: '125px' }}>
                  <PatientCheckboxes
                    date={patient.complianceDocsDueDate}
                    status={patient.complianceDocsStatus}
                    patientData={patient}
                    tooltipType={'complianceDocs'}
                    enableTooltip={true}
                  />
                </TableCell>
                <TableCell align="center" component="th" scope="row" sx={{ maxWidth: '125px' }}>
                  <PatientCheckboxes
                    date={patient.simulationAppntDueDate}
                    status={patient.simulationStatus}
                    patientData={patient}
                    tooltipType={'simulation'}
                    enableTooltip={true}
                  />
                </TableCell>
                <TableCell align="center" component="th" scope="row" sx={{ maxWidth: '125px' }}>
                  <PatientCheckboxes
                    date={patient.fusionDueDate}
                    status={patient.fusionStatus}
                    patientData={patient}
                    tooltipType={'fusion'}
                    enableTooltip={true}
                  />
                </TableCell>
                <TableCell align="center" component="th" scope="row" sx={{ maxWidth: '125px' }}>
                  <PatientCheckboxes
                    date={patient.targetVolumeDueDate}
                    status={patient.targetVolumeStatus}
                    patientData={patient}
                    tooltipType={'targetVolume'}
                    enableTooltip={true}
                  />
                </TableCell>
                <TableCell align="center" component="th" scope="row" sx={{ maxWidth: '125px' }}>
                  <PatientCheckboxes
                    date={patient.contouringDueDate}
                    status={patient.contouringStatus}
                    patientData={patient}
                    tooltipType={'contouring'}
                    enableTooltip={true}
                  />
                </TableCell>
                <TableCell align="center" component="th" scope="row" sx={{ maxWidth: '125px' }}>
                  <PatientCheckboxes
                    date={patient.planApprovalDueDate}
                    status={patient.planApprovalStatus}
                    patientData={patient}
                    tooltipType={'planApproval'}
                    enableTooltip={true}
                  />
                </TableCell>
              </TableRow>
            );
          })}
          {/*  // Need the extra row to show the last border set table */}
          <TableRow />
        </TableBody>
      </Table>
    </TableContainer>
  );
};

export default PatientTable;
