// eslint-disable-next-line no-use-before-define
import { useMutation, useQuery } from '@apollo/client';
import PatientAccessModal from 'op-components/Modals/PatientAccessModal/PatientAccessModal';
import { COPY } from 'op-components/OP/PatientSearch/constants';
import { GET_PATIENT_PROFILE } from 'op-components/PatientCard/PatientCardQueries';
import { FormType, RoleType } from 'op-enums';
import { RECORD_LOCK_QUERY, RELEASE_LOCK_MUTATION } from 'op-graphql';
import { PatientOptIn, PatientSearchQueryItem, PatientSearchQueryItemAddress } from 'op-interfaces';
import { getFormData, renderFormStatus, staticRowForm } from 'op-pages/OP/PatientFormSummary/PatientFormSummary';
import { isUs } from 'op-utils';
import React, { Fragment, useRef, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { Card, LoadingSpinner } from 'shared-components/components';
import { PAT_ID_1_REFRESH_TIME } from 'shared-components/enums';
import { getRemSize } from 'shared-components/StyledComponents/functions';
import useInterval from 'shared-components/utils/useInterval';
import { PatientFormSummaryQueryResponse } from '../../OP/PatientFormSummary/PatientFormSummaryApollo';
import { useTheme, styled, Stack, Tooltip, Typography, Button } from '@mui/material';
import {
  Launch as LaunchIcon,
  FindInPageOutlined as FindInPageOutlinedIcon,
  CheckCircleOutlined as CheckCircleOutlinedIcon,
  HighlightOff as HighlightOffIcon,
} from '@mui/icons-material';

const { PSO } = RoleType;

interface State {
  registrationInviteModalOpen: boolean;
  modalPatientRegAcces: boolean;
  modalHeader?: string;
  selectedPIN: string;
  selectedName: string;
  buttonLoadingId: string;
  selectedPatientOptIn: PatientOptIn;
  optInModalOpen: boolean;
  optInModalInDOM: boolean;
  optInModalLoadingId: string;
  selectedPatient: PatientSearchQueryItem;
  selectedPatientId: string;
  selectedPatientIda: string;
  selectedDob: string;
  selectedAddress: PatientSearchQueryItemAddress;
  lockErrorModalOpen: boolean;
  lockErrorText: string;
  distressThermometerEnabled: boolean;
  allowedLocations: {
    alias: string;
    id: string;
  }[];
  primaryRole: string;
  locationFilter: string;
  formType: string;
  profileSlideOpen: boolean;
  pageShown: string;
  isPractitioner: boolean;
}

const ResponsiveContainer = styled('div')`
  overflow-x: hidden;
  overflow-y: hidden;
`;

const TableScrollContainer = styled('div')`
  overflow: ${isUs() ? 'unset' : 'scroll'};
  max-height: 100%;
`;

const TableContainer = styled('table')`
  display: table;
  width: 100%;
  font-size: 16px;
  line-height: 24px;
  float: left;
  border-collapse: separate;
  margin: 10px 0px;
`;

const Header = styled('thead')`
  width: auto;
  font-weight: 500;
  margin: 25px 5px;

  th {
    top: 0;
    background: ${(props) => props.theme.palette.grey[100]};
    font-weight: 500;
    position: sticky;
  }
`;

const HeaderRow = styled('tr')`
  width: auto;
`;

const HeaderCol = styled('th')`
  width: auto;
  vertical-align: middle;
  border-collapse: collapse;
  padding: 8px;
  text-align: left;
`;

const DataBody = styled('tbody')`
  width: auto;
`;

const DataCol = styled('td')`
  width: auto;
  vertical-align: middle;
  border-collapse: collapse;
  padding: 8px;
  text-align: left;
`;

const FlexDiv = styled('div')`
  display: flex;
  align-items: center;
`;

interface DataRowProps {
  highlight: boolean;
}

const DataRow = styled('tr')<DataRowProps>`
  background: ${(props: DataRowProps) => (props.highlight ? 'none' : 'none')};
  & td:first-child {
    text-align: left;
    border-radius: ${(props: DataRowProps) => (props.highlight ? '5px  0  0 5px' : 'none')};
    border-left: ${(props) => (props.highlight ? `2px solid ${props.theme.palette.primary.main}` : 'none')};
  }
  & td:last-child {
    border-radius: ${(props: DataRowProps) => (props.highlight ? '0px  5px  5px  0px' : 'none')};
    border-right: ${(props) => (props.highlight ? `2px solid ${props.theme.palette.primary.main}` : 'none')};
  }
  & td {
    border-top: ${(props) => (props.highlight ? `2px solid ${props.theme.palette.primary.main}` : 'none')};
    border-bottom: ${(props) => (props.highlight ? `2px solid ${props.theme.palette.primary.main}` : 'none')};
    text-align: left;
  }
  &:hover {
    background: ${(props) => props.theme.palette.grey[100]};
  }
  box-shadow: 0px 1px 0px ${(props) => props.theme.palette.grey[300]};
  width: auto;
  height: 50px;
`;

const StyledDocListCol = styled(Card)`
  color: ${(props) => props.theme.palette.text.primary};
  overflow: auto;

  td {
    font-size: 14px;
  }
  table {
    margin: 0;
  }
`;

const StyledLabelCol = styled(Stack)`
  height: 2em;
  display: flex;
  align-items: center;
`;

const ProvideAccessBlock = styled(StyledLabelCol)`
  display: flex;
  align-items: center;
  flex-direction: row;
  justify-content: flex-end;
  cursor: pointer;
`;

const NoDocumentContainer = styled('div')`
  margin-top: 40px;
  display: flex;
  flex-direction: column;
  align-items: center;
`;

const NoDocumentMessage = styled('div')`
  margin-top: 28px;
  font-size: ${getRemSize(18)};
  font-weight: 700;
  color: ${(props) => props.theme.palette.grey[600]};
`;

const CircleDiv = styled('div')`
  width: 16px;
  height: 16px;
  margin-right: 10px;
  border-radius: 10px;
`;

const FormTitleText = styled('div')`
  font-size: 16px;
  line-height: 24px;
`;

const FormLocationText = styled('div')`
  font-size: 12px;
  margin-left: 8px;
  line-height: 15px;
`;

interface ROPatientformListProps {
  isSmallDisplay: boolean;
  patientId: string;
}

const SmallCross = styled(HighlightOffIcon)`
  height: 16px;
  width: 16px;
`;

const SmallSuccess = styled(CheckCircleOutlinedIcon)`
  height: 16px;
  width: 16px;
`;

const FlexRow = styled('div')`
  width: 100%;
  justify-content: space-between;
  display: flex;
  align-items: baseline;
  padding-bottom: 15px;
  margin-top: -5px;
`;

const displayLocationAccessed = (location: string): JSX.Element => {
  let displayLocationAccessedResult = 'Not provided';

  if (location === 'inClinic') {
    displayLocationAccessedResult = 'In clinic';
  } else if (location === 'atHome') {
    displayLocationAccessedResult = 'At home';
  }

  const iconResult = ['inClinic', 'atHome'].includes(location) ? (
    <SmallSuccess color="primary" />
  ) : (
    <SmallCross color="error" />
  );

  return (
    <FlexDiv>
      {iconResult}
      <FormLocationText>{displayLocationAccessedResult}</FormLocationText>
    </FlexDiv>
  );
};

const ROPatientForms = (props: ROPatientformListProps): JSX.Element => {
  const theme = useTheme();
  const EMPTY_PATIENT_ADDRESS = {
    line1: '',
    line2: '',
    city: '',
    state: '',
    country: '',
    postcode: '',
  };

  const EMPTY_PATIENT = {
    id: '',
    firstName: '',
    lastName: '',
    ida: '',
    dob: '',
    primaryPhone: '',
    primaryPhoneFormatted: '',
    email: '',
    primaryCenterAlias: '',
    address: EMPTY_PATIENT_ADDRESS,
    pxOptedIn: false,
    pxOptOutComment: '',
    regFormStatus: '',
    overallStatus: '',
    userProfile: {
      id: '',
      registrationAccessType: '',
      showRegistration: true,
      showHealthAssessment: true,
      patientFeatures: {
        distressThermometerEotEnabled: false,
        distressThermometerEnabled: false,
        managementPlansEnabled: false,
        covidEnabled: false,
      },
    },
    horizonCenterId: '',
    tags: [],
    emrInstance: '',
  };

  const EMPTY_PATIENT_OPT_IN = {
    id: '',
    firstName: '',
    lastName: '',
    pxOptedIn: false,
    primaryPhone: '',
    email: '',
    pxOptOutComment: '',
    emrInstance: '',
  };

  const formListRef = useRef<HTMLDivElement>(null);

  const formData = PatientFormSummaryQueryResponse(PSO, props.patientId);
  const {
    data,
    loading: patientDataLoading,
    refetch,
  } = useQuery(GET_PATIENT_PROFILE, { variables: { patientId: props.patientId } });
  const patient = data?.patient;

  // Refresh profile every few seconds until patient has pat_id_1
  useInterval(
    () => {
      refetch();
    },
    patient?.userProfile?.hasPatId1 ? null : PAT_ID_1_REFRESH_TIME,
  );

  const { loading } = formData;

  const [popupState, setPopupState] = useState<State>({
    registrationInviteModalOpen: false,
    modalPatientRegAcces: false,
    modalHeader: undefined,
    selectedName: '',
    selectedPIN: '',
    buttonLoadingId: '',
    optInModalLoadingId: '',
    optInModalOpen: false,
    optInModalInDOM: false,
    selectedPatient: EMPTY_PATIENT,
    selectedPatientId: '',
    selectedPatientIda: '',
    selectedDob: '',
    selectedAddress: { ...EMPTY_PATIENT_ADDRESS },
    lockErrorModalOpen: false,
    lockErrorText: '',
    selectedPatientOptIn: { ...EMPTY_PATIENT_OPT_IN },
    distressThermometerEnabled: false,
    allowedLocations: [],
    primaryRole: '',
    locationFilter: '',
    formType: '',
    profileSlideOpen: false,
    pageShown: 'search',
    isPractitioner: true,
  });
  const [showRegModal, setShowRegModal] = useState(false);

  const heading = 'Forms';

  const attributes = {
    patient: formData.data?.patient,
    haInfo: formData.data?.healthAssessment,
    dtInfo: formData.data?.distressThermometerInitial,
    dtEotInfo: formData.data?.distressThermometerEot,
    pCCCInfo: formData.data?.allPreCtChartCheck,
    preCtTriageInfo: formData.data?.allPreCtTriage,
    profile: formData.data?.profile,
    covidInfo: formData.data?.covidScreening,
    locationsPsoSystem: formData.data?.locationsPsoSystem,
    primaryRole: PSO,
    featureOptions: formData.data?.featureOptions,
  };
  const constructTableData = !loading && formData.data && getFormData(attributes);
  const getCircleBubble = (status: string) => {
    const statusColours = {
      '-': theme.palette.grey[600],
      'Review required': theme.palette.warning.dark,
      'Not started': theme.palette.grey[600],
      'In progress': theme.palette.info.main,
      'Submitted to MQ': theme.palette.primary.dark,
      Submitted: theme.palette.primary.dark,
    };

    return <CircleDiv style={{ background: statusColours[status as keyof typeof statusColours] }} />;
  };

  const getFormStatus = (typename: string, attributes: any) =>
    renderFormStatus(typename, attributes, undefined, true).props?.children === '-'
      ? 'Not started'
      : renderFormStatus(typename, attributes, undefined, true);

  const cleanedData = formData && formData.data ? formData.data : {};
  const [releaseLockMutation] = useMutation(RELEASE_LOCK_MUTATION);

  const { refetch: recordLockQuery } = useQuery(RECORD_LOCK_QUERY, { variables: { id: '0' }, skip: true });
  const getLockDetails = (patientItem: PatientSearchQueryItem): any => {
    // Before opening and setting the state for the modal, attempt to acquire the lock before opening the modal

    return recordLockQuery({ id: patientItem.id }).then(({ data }: any) => {
      const patient = data.patient;
      if (patient && patient.lock && !patient.lock.readOnly) {
        return { readOnly: true, lockedByName: undefined }; // Lock was acquired
      }
      return { readOnly: false, lockedByName: patient?.lock ? patient.lock.lockedByName : 'a staff member' }; // Lock wasn't acquired
    });
  };

  const openErrorModalAndResetLoading = (lockedByName?: string): void => {
    if (lockedByName) {
      const RECORD_IN_USE_BY = COPY.RECORD_IN_USE_BY;
      const RECORD_IN_USE_NO_VIEW = COPY.RECORD_IN_USE_NO_VIEW;
      const lockErrorText = `${RECORD_IN_USE_BY} ${lockedByName}${RECORD_IN_USE_NO_VIEW}`;
      setPopupState({
        ...popupState,
        lockErrorModalOpen: true,
        optInModalLoadingId: '',
        buttonLoadingId: '',
        lockErrorText,
      });
    }
  };

  const releaseLock = (patientId: string): void => {
    releaseLockMutation({
      variables: { accessPatientId: patientId },
    });
  };
  const handleGetPINModalOpen = (patientItem: PatientSearchQueryItem, form = COPY.PATIENT_REGISTRATION): void => {
    let modalHeader = undefined;
    const formTypeOption = form;

    // Distress Pin has a different header
    if (formTypeOption === FormType.DISTRESS) {
      modalHeader = COPY.DISTRESS_PIN;
    }

    // Using ID instead of ida since the ida could potentially be blank, but if a result is found the id will always exist.
    setPopupState({
      ...popupState,
      buttonLoadingId: patientItem.id,
      modalHeader: modalHeader,
    });

    getLockDetails(patientItem).then((value: any) => {
      if (value.readOnly) {
        setShowRegModal(true);
        setPopupState({
          ...popupState,
          selectedName: `${patientItem.firstName} ${patientItem.lastName}`,
          selectedPatient: patientItem,
          selectedPatientId: patientItem.id,
          selectedDob: patientItem.dob,
          buttonLoadingId: '',
          selectedPatientIda: patientItem.ida,
          selectedAddress: patientItem.address,
          selectedPatientOptIn: {
            id: patientItem.id,
            firstName: patientItem.firstName,
            lastName: patientItem.lastName,
            pxOptedIn: patientItem.pxOptedIn,
            primaryPhone: patientItem.primaryPhone,
            email: patientItem.email,
            pxOptOutComment: patientItem.pxOptOutComment,
            emrInstance: patientItem.emrInstance,
          },
        });
        // Release the lock after the PIN has been generated so that another user can immediately access the record if needed.
        releaseLock(patientItem.id);
      } else {
        openErrorModalAndResetLoading(value.lockedByName);
      }
    });
  };
  const history = useHistory();

  const { selectedPatient, selectedPatientId } = popupState;
  const hasPatId1 = patient?.userProfile?.hasPatId1;

  const isLoading = loading || patientDataLoading;

  return (
    <Fragment>
      {showRegModal && selectedPatientId && (
        <>
          <PatientAccessModal
            patient={selectedPatient}
            isOpen={showRegModal}
            onClose={(): void => {
              setShowRegModal(false);
            }}
          />
        </>
      )}
      <Stack sx={{ height: '50%' }}>
        <StyledDocListCol $noPadding style={{ display: 'flex', height: '100%' }} ref={formListRef}>
          {isLoading ? <LoadingSpinner loadingText={'Loading Forms'} relativeSpinner={true} /> : null}
          {!isLoading && formData?.data ? (
            <ResponsiveContainer style={{ height: '100%', padding: '16px', width: '100%' }}>
              <FlexRow>
                <StyledLabelCol>
                  <Typography variant="h6">{heading}</Typography>
                </StyledLabelCol>
                <Button
                  onClick={(e: any): void => {
                    e.preventDefault();
                    handleGetPINModalOpen(patient);
                  }}
                  variant="text"
                  data-test-id="provide-access"
                  startIcon={<LaunchIcon color="primary" />}>
                  Provide access
                </Button>
              </FlexRow>
              <TableScrollContainer>
                <TableContainer data-test-id={'forms-table'}>
                  <Header>
                    <HeaderRow>
                      <HeaderCol id={'head-1'} key={'head-1'}>
                        Type
                      </HeaderCol>
                      <HeaderCol id={'head-2'} key={'head-2'}>
                        Status
                      </HeaderCol>
                    </HeaderRow>
                  </Header>
                  {cleanedData ? (
                    <DataBody id={'form-list-table'} className="document-list-table">
                      {constructTableData
                        .filter((item: any) => !item.isHidden)
                        .map((row: any, index: number) => (
                          <Fragment key={`row-${index}`}>
                            <Tooltip
                              title={
                                <div
                                  key={`tooltip-${index}`}
                                  style={{ fontSize: '14px', lineHeight: '24px', textAlign: 'left' }}>
                                  Last modified by: {row['updatedBy']}
                                  <br />
                                  Last modified: {row['updatedAt']}
                                </div>
                              }
                              arrow>
                              <DataRow
                                data-test-id={`form-table-row-${row['formStatus']}`}
                                id={`row-${index}`}
                                key={`row-${index}`}
                                highlight={false}
                                className={
                                  !staticRowForm.includes(row.id) && hasPatId1 ? 'form-hover-as-pointer' : 'not-clicky'
                                }
                                onClick={() => {
                                  if (staticRowForm.includes(row.id) || !hasPatId1) return;
                                  sessionStorage.setItem('referringPage', `/navigator/patient/${patient.id}/summary`);
                                  history.push(row.formLink);
                                }}>
                                <DataCol id={`${row['title']}-title-col-${index}`} key={`title-col-${index}`}>
                                  <FormTitleText>{row['title']}</FormTitleText>
                                  {displayLocationAccessed(attributes.patient.userProfile.registrationAccessType)}
                                </DataCol>
                                <DataCol id={`${row['title']}-status-col-${index}`} key={`status-col-${index}`}>
                                  <FlexDiv>
                                    {getCircleBubble(getFormStatus(row.formStatus, attributes))}{' '}
                                    {getFormStatus(row.formStatus, attributes)}
                                  </FlexDiv>
                                </DataCol>
                              </DataRow>
                            </Tooltip>
                          </Fragment>
                        ))}
                    </DataBody>
                  ) : (
                    <></>
                  )}
                </TableContainer>
              </TableScrollContainer>
            </ResponsiveContainer>
          ) : null}
          {!cleanedData && (
            <NoDocumentContainer>
              <FindInPageOutlinedIcon fontSize="large" htmlColor={theme.palette.grey[600]} />
              <NoDocumentMessage>No forms available</NoDocumentMessage>
            </NoDocumentContainer>
          )}
        </StyledDocListCol>
      </Stack>
    </Fragment>
  );
};

export default ROPatientForms;
