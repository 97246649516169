import React from 'react';
import { Badge } from 'shared-components/components';
import { CardTitle, CardBody, CardBodyGroup, CardText, BubbleTitle } from './styles';
import CONSTANTS from './constants';
import { useTheme } from '@mui/material';

interface Props {
  name: string;
  patientDiagnosis: {
    id: string;
    isPrimaryDiagnosis: boolean;
    diagnosisCode: {
      diagnosisCode: string;
      id: string;
      diagnosisDescription: string;
      tumourStream: {
        name: string;
      };
    };
  }[];
}

const DiagnosisCard = ({ name, patientDiagnosis }: Props): JSX.Element => {
  const { PRIM_DIAG, METASTASIS, NO_DIAGNOSIS } = CONSTANTS;
  const theme = useTheme();
  return (
    <div data-testid="diagnosisCard">
      <CardTitle>
        {name}{' '}
        <Badge $bgColor={theme.palette.grey[600]} data-testid="diagnosisBadge">
          {patientDiagnosis?.length || 0}
        </Badge>
      </CardTitle>
      <CardBody>
        {patientDiagnosis.length > 0 ? (
          patientDiagnosis
            .filter((diagnosis: any) => !!diagnosis?.diagnosisCode)
            .map((diagnosis: any, index: number) => (
              <CardBodyGroup key={diagnosis.diagnosisCode.diagnosisCode}>
                <BubbleTitle>
                  {index + 1}. {diagnosis.diagnosisCode.diagnosisCode}{' '}
                  {diagnosis.isPrimaryDiagnosis ? PRIM_DIAG : METASTASIS}
                </BubbleTitle>
                <CardText>
                  {`${diagnosis.diagnosisCode.tumourStream.name} - ${diagnosis.diagnosisCode.diagnosisDescription}`}
                </CardText>
              </CardBodyGroup>
            ))
        ) : (
          <CardBodyGroup>
            <b>{NO_DIAGNOSIS}</b>
          </CardBodyGroup>
        )}
      </CardBody>
    </div>
  );
};

export default DiagnosisCard;
