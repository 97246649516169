import { gql } from '@apollo/client';

export const APPOINTMENTS_QUERY = gql`
  query AllAppointmentsAndUser($afterTz: String!) {
    allAppointments(afterTz: $afterTz) {
      id
      startTime
      entity
      appointmentType: mappingType
      department {
        id
        name
      }
      status
      duration
      practitioner {
        id
        name
        prefix
        qualification
      }
      location {
        id
        name: mappingName
        phone
        address {
          id
          city
          formattedAddress
        }
        mapSearchUrl
      }
    }
    user {
      id
      username
    }
  }
`;
