import { gql } from '@apollo/client';

export const DOCTOR_APPOINTMENT_QUERY = gql`
  query doctorAppointmentByDate($startDate: DateTime, $endDate: DateTime) {
    doctorAppointmentsByDate(startDate: $startDate, endDate: $endDate) {
      id
      patient {
        id
        fullName
        ida
        dob
        gender
        address {
          id
          formattedAddress
        }
        photoUrl
      }
      location {
        id
        name
      }
      startTime
      duration
      comment
      activity
      statusAbbreviation
      description
      department {
        id
        alias
      }
    }
  }
`;
