import { Form, Formik, FormikProps, FormikValues } from 'formik';
import { FormContainer } from 'op-components';
import { mapListData, yesNoOptions } from 'op-utils';
import { RegistrationContext } from 'op-contexts';
import React, { useContext } from 'react';
import { useHistory } from 'react-router-dom';
import { ListData } from 'shared-components/interfaces';
import { generateValidationSchema } from './validation';
import {
  CONTINUE_BUTTON_TEXT,
  getDisplayValue,
  HeaderSubTitle,
  SubText,
  HeaderTitle,
  registrationPath,
  RETURN_TO_SUBMIT_TEXT,
  sharedFormContainerProps,
  standardField,
  StyledIndentedSection,
  isFormValid,
} from '../Helper';
import { PatientDemographics, RegistrationFormUSIProps } from '../interfaces';
import { FIELDS } from './constants';
import { AustralianStates } from 'shared-components/enums';

interface RegistrationDemographicsFormAUIProps extends RegistrationFormUSIProps {
  patient: PatientDemographics;
  readonly refData: {
    readonly maritalStatusRefData: ListData[];
    readonly atsiHeritageRefData: ListData[];
    readonly countryOfBirthRefData: ListData[];
    readonly languageSpokenRefData: ListData[];
  };
}

const RegistrationDemographicsFormAU = (props: RegistrationDemographicsFormAUIProps): JSX.Element => {
  const registrationContext = useContext(RegistrationContext);
  const {
    patient: savedPatient,
    updateField,
    handleShowSaveExitConfirm,
    previousPageLink,
    refData: { maritalStatusRefData, atsiHeritageRefData, countryOfBirthRefData, languageSpokenRefData },
  } = props;

  const patient = {
    ...savedPatient,
    maritalStatus: getDisplayValue(maritalStatusRefData, savedPatient.maritalStatus),
    heritage: getDisplayValue(atsiHeritageRefData, savedPatient.heritage),
    countryOfBirth: getDisplayValue(countryOfBirthRefData, savedPatient.countryOfBirth),
    languageAtHome: getDisplayValue(languageSpokenRefData, savedPatient.languageAtHome),
  };

  const goToSummary = registrationContext?.registrationSummaryVisited;
  const history = useHistory();

  return (
    <>
      <Formik
        initialValues={patient}
        validate={(values: FormikValues) =>
          generateValidationSchema({
            values,
            refData: { maritalStatusRefData, atsiHeritageRefData, countryOfBirthRefData, languageSpokenRefData },
          })
        }
        validateOnBlur
        validateOnChange
        onSubmit={() => history.push(registrationPath(patient?.id, goToSummary ? 'summary' : 'upload'))}>
        {({ errors, submitForm, values, touched, setFieldValue, setFieldTouched }: FormikProps<any>) => {
          const sharedProps = sharedFormContainerProps(FIELDS.FORM_HEADING.TITLE, 7, previousPageLink);
          const formContainerProps = {
            ...sharedProps,
            submitButtonText: goToSummary ? RETURN_TO_SUBMIT_TEXT : CONTINUE_BUTTON_TEXT,
            continueDisabled: isFormValid(errors, touched),
            handleShowSaveExitConfirm,
          };

          const showInterpreterRequired = !['English', ''].includes(values?.languageAtHome);
          const updateLanguageField = (field: string, value: string | boolean | number) => {
            if (field === FIELDS.LANGUAGE_AT_HOME.NAME && value === 'English') {
              // Reset interpreter required field if we change language to english
              setFieldValue('interpreterRequired', null, true);
            }
            updateField(field, value);
          };

          const updateAmbulanceMembershipField = (field: string, value: string | boolean | number) => {
            if (field === FIELDS.AMBULANCE_MEMBERSHIP.NAME && value === false) {
              setFieldValue(FIELDS.AMBULANCE_MEMBERSHIP_NUMBER.NAME, null, true);
              updateField(FIELDS.AMBULANCE_MEMBERSHIP_NUMBER.NAME, '');
            } else {
              setFieldTouched(FIELDS.AMBULANCE_MEMBERSHIP_NUMBER.NAME, false, false);
            }
            updateField(field, value);
          };
          const sortedLanguageRefData = [...languageSpokenRefData].sort((a: ListData, b: ListData) => {
            if (a.name === 'English') return -10;
            return a.name.localeCompare(b.name);
          });
          return (
            <FormContainer
              {...formContainerProps}
              submitForm={submitForm}
              handleValidation={(event: React.MouseEvent<HTMLInputElement>): void => {
                void event;
              }}>
              <Form>
                <HeaderTitle data-test-id="registration-demographics-page-title">
                  {FIELDS.FORM_HEADING.TITLE}
                </HeaderTitle>
                <HeaderSubTitle data-test-id="registration-demographics-page-subtitle">
                  * = required field
                </HeaderSubTitle>

                {standardField(FIELDS.COUNTRY_OF_BIRTH, updateField, mapListData(countryOfBirthRefData))}
                {standardField(FIELDS.LANGUAGE_AT_HOME, updateLanguageField, mapListData(sortedLanguageRefData))}
                {showInterpreterRequired && (
                  <StyledIndentedSection>
                    {standardField(FIELDS.INTERPRETER, updateField, yesNoOptions)}
                  </StyledIndentedSection>
                )}
                {standardField(FIELDS.OCCUPATION, updateField)}
                {standardField(FIELDS.HERITAGE, updateField, mapListData(atsiHeritageRefData))}
                <SubText data-test-id="demographics-sub-text">
                  This is used to help collect better information on Aboriginal and Torres Strait Islander people,
                  including how and why services are used. The information is used to help measure progress in health
                  and wellbeing and will ensure all Australians receive the best possible services in the future.
                </SubText>
                {standardField(FIELDS.MARITAL_STATUS, updateField, mapListData(maritalStatusRefData))}

                {patient.patientState === AustralianStates.VIC && (
                  <>
                    {standardField(FIELDS.AMBULANCE_MEMBERSHIP, updateAmbulanceMembershipField, yesNoOptions)}
                    {values?.ambulanceMembership && standardField(FIELDS.AMBULANCE_MEMBERSHIP_NUMBER, updateField)}
                  </>
                )}
              </Form>
            </FormContainer>
          );
        }}
      </Formik>
    </>
  );
};

export default RegistrationDemographicsFormAU;
