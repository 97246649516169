import { gql } from '@apollo/client';

const ATTACHMENT_FRAGMENT = gql`
  fragment Attachment on AttachmentType {
    id
    url
    documentType
    submitted
    encounterDate
    isRegistrationAttachment
  }
`;

export const GET_PATIENT_ATTACHMENTS = gql`
  query PatientAndUserDetails($id: ID!) {
    patient(id: $id) {
      id
      attachments {
        id
        filename
        documentType
        submitted
        uploader {
          id
        }
        url
        encounterDate
        staging
        isRegistrationAttachment
      }
      userProfile {
        id
        hasPatId1
      }
    }
    user {
      id
      isRo
    }
  }
`;

export const GET_UPLOAD_DETAILS = gql`
  query GetUploadDetails($fileName: String!) {
    fileUploadPayload(fileName: $fileName) {
      url
      fields {
        awsKey
        awsPayload
      }
    }
  }
`;

export const CREATE_ATTACHMENT = gql`
  mutation createAttachment(
    $awsKey: String!
    $patientPk: String!
    $documentType: String!
    $encounterDate: String!
    $isRegistrationAttachment: Boolean
  ) {
    createAttachment(
      awsKey: $awsKey
      patientPk: $patientPk
      documentType: $documentType
      encounterDate: $encounterDate
      isRegistrationAttachment: $isRegistrationAttachment
    ) {
      attachment {
        id
        ...Attachment
      }
    }
  }

  ${ATTACHMENT_FRAGMENT}
`;

export const CHANGE_DOCUMENT_TYPE = gql`
  mutation changeDocumentType($attachmentPk: String!, $documentType: String!) {
    changeDocumentType(attachmentPk: $attachmentPk, documentType: $documentType) {
      attachment {
        id
        ...Attachment
      }
    }
  }

  ${ATTACHMENT_FRAGMENT}
`;

export const CHANGE_ENCOUNTER_DATE = gql`
  mutation changeEncounterDate($attachmentPk: String!, $encounterDate: String!) {
    changeEncounterDate(attachmentPk: $attachmentPk, encounterDate: $encounterDate) {
      attachment {
        id
        ...Attachment
      }
    }
  }

  ${ATTACHMENT_FRAGMENT}
`;

export const DELETE_ATTACHMENT = gql`
  mutation deleteDocument($attachmentPk: String!) {
    deleteAttachment(attachmentPk: $attachmentPk) {
      ok
    }
  }
`;

export const SET_STAGING_VALUE = gql`
  mutation setStagingValue($id: ID) {
    setStagingValue(id: $id) {
      attachment {
        id
        url
        documentType
        submitted
        staging
      }
    }
  }
`;

export const SUBMIT_TO_MOSAIQ = gql`
  mutation CreateSubmission(
    $patientID: ID!
    $includePdf: Boolean
    $updateFormStatus: Boolean
    $fromHomeRego: Boolean
    $userPk: ID
    $isRegistrationSubmission: Boolean
  ) {
    createSubmission(
      patientId: $patientID
      includePdf: $includePdf
      updateFormStatus: $updateFormStatus
      fromHomeRego: $fromHomeRego
      userPk: $userPk
      isRegistrationSubmission: $isRegistrationSubmission
    ) {
      submission {
        id
        pdf
      }
    }
  }
`;

export const SUBMIT_ATTACHMENT = gql`
  mutation SubmitAttachment($patientID: ID!) {
    submitAttachment(patientId: $patientID) {
      success
    }
  }
`;

export const UPDATE_ACKNOWLEDGMENT = gql`
  mutation UpdatePatient($id: ID!, $attachmentAcknowledged: Boolean!) {
    updatePatient(id: $id, attachmentAcknowledged: $attachmentAcknowledged) {
      patient {
        id
        attachmentAcknowledged
      }
    }
  }
`;

export const GET_ATTACHMENTS_TYPES = gql`
  query attachmentTypes {
    documentTypeRefData: attachmentTypes {
      id
      conceptCode
      conceptDisplay
    }
  }
`;
