// eslint-disable-next-line no-use-before-define
import React, { Component } from 'react';
import './Table.scss';

interface State {
  activeTab: string;
}

interface Props {
  tableHeaders: string[];
  colWidths: string[];
  children: React.ReactNode;
}

/*
  This component defines the styles and the headers for a table in RO portal. It does not attempt to define the table rows, as each table has different requirements.
  Pass the rows as children of this component, and define their callbacks wherever the children are declared.
  Eg:
  Please check RORegAttachments.tsx for an example of the component implementation.
*/

class ROTable extends Component<Props, State> {
  public render(): JSX.Element {
    const { tableHeaders, colWidths } = this.props;

    return (
      <div className="RO-table-wrapper">
        <table className="resp-table">
          <thead>
            <tr>
              {tableHeaders.map((tableHeader, index) => {
                return (
                  <th key={index} style={{ minWidth: colWidths[index] }}>
                    <div className="header-style">{tableHeader}</div>
                  </th>
                );
              })}
            </tr>
          </thead>
          {this.props.children}
        </table>
      </div>
    );
  }
}

export default ROTable;
