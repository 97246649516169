import { gql } from '@apollo/client';
import { HEALTH_ASSESSMENT_ILLNESS_FRAGMENT } from 'op-graphql/fragments';

export const UPDATE_HA_ILLNESS = gql`
  mutation UpdateIllness(
    $id: ID!
    $patientId: ID!
    $colonoscopyRawYear: String
    $colonoscopyRawMonth: String
    $colonoscopyRawDay: String
    $chestCtScanRawYear: String
    $chestCtScanRawMonth: String
    $chestCtScanRawDay: String
    $covid19VaccineRawYear: String
    $covid19VaccineRawMonth: String
    $covid19VaccineRawDay: String
    $influenzaVaccineRawYear: String
    $influenzaVaccineRawMonth: String
    $influenzaVaccineRawDay: String
    $pneumoniaVaccineRawYear: String
    $pneumoniaVaccineRawMonth: String
    $pneumoniaVaccineRawDay: String
    $screening: String
    $pastMedicalHistory: String
    $cardiac: String
    $constitutional: String
    $eyesEarsNoseThroat: String
    $gastrointestinal: String
    $genitourinary: String
    $neurological: String
    $psychiatric: String
    $pulmonary: String
    $bonesJoints: String
    $hematologic: String
    $skin: String
  ) {
    updateHaIllness(
      id: $id
      patientId: $patientId
      colonoscopyRawYear: $colonoscopyRawYear
      colonoscopyRawMonth: $colonoscopyRawMonth
      colonoscopyRawDay: $colonoscopyRawDay
      chestCtScanRawYear: $chestCtScanRawYear
      chestCtScanRawMonth: $chestCtScanRawMonth
      chestCtScanRawDay: $chestCtScanRawDay
      covid19VaccineRawYear: $covid19VaccineRawYear
      covid19VaccineRawMonth: $covid19VaccineRawMonth
      covid19VaccineRawDay: $covid19VaccineRawDay
      influenzaVaccineRawYear: $influenzaVaccineRawYear
      influenzaVaccineRawMonth: $influenzaVaccineRawMonth
      influenzaVaccineRawDay: $influenzaVaccineRawDay
      pneumoniaVaccineRawYear: $pneumoniaVaccineRawYear
      pneumoniaVaccineRawMonth: $pneumoniaVaccineRawMonth
      pneumoniaVaccineRawDay: $pneumoniaVaccineRawDay
      screening: $screening
      pastMedicalHistory: $pastMedicalHistory
      cardiac: $cardiac
      constitutional: $constitutional
      eyesEarsNoseThroat: $eyesEarsNoseThroat
      gastrointestinal: $gastrointestinal
      genitourinary: $genitourinary
      neurological: $neurological
      psychiatric: $psychiatric
      pulmonary: $pulmonary
      bonesJoints: $bonesJoints
      hematologic: $hematologic
      skin: $skin
    ) {
      healthAssessment {
        id
        ...HealthAssessmentIllness
      }
    }
  }

  ${HEALTH_ASSESSMENT_ILLNESS_FRAGMENT}
`;
