import { gql } from '@apollo/client';

import {
  PCCC_ALLERGY_FRAGMENT,
  PCCC_IMPLANTED_DEVICE_FRAGMENT,
  PCCC_INFECTIOUS_DISEASE_FRAGMENT,
  PCCC_MRO_INFECTION_FRAGMENT,
} from 'op-graphql/fragments';

export const PRE_CT_CHART_CHECK_QUERY = gql`
  query PreCtChartCheck($id: ID!) {
    preCtChartCheck(id: $id) {
      id
      isLocked
      isAmendment
      nurseLocation
      patientIdentificationCheck
      patientHeight
      patientWeight
      previousRadiationTreatment
      anticancerDrugs
      chemoPastWeek
      hasImplantedMedicalDevice
      treatmentOverHalfHour
      currentInpatient
      requiresMaskCast
      existingPressureInjuryWound
      implantedMedicalDevice {
        id
        ...ImplantedDevice
      }
      hasInfectiousDisease
      infectiousDisease {
        id
        ...InfectiousDisease
      }
      hasMroInfection
      mroInfection {
        id
        ...MroInfection
      }
      outsideWaLastTwelveMonths
      fallLastTwelveMonths
      safetyConcernsMobility
      hasCurrentAcd
      acdInfoRequested
      hasCurrentResusMxPlan
      staffConcern
      anxietyClaustrophobia
      hasPatientAllergies
      pcccAllergy {
        id
        ...Allergy
      }
      additionalNotes
      updatedBy
      lastUpdateUser {
        fieldName
        lastUpdateUser
        updatedDate
      }
    }
  }

  ${PCCC_ALLERGY_FRAGMENT}
  ${PCCC_IMPLANTED_DEVICE_FRAGMENT}
  ${PCCC_INFECTIOUS_DISEASE_FRAGMENT}
  ${PCCC_MRO_INFECTION_FRAGMENT}
`;

export const UPDATE_PRE_CT_CHART_CHECK = gql`
  mutation UpdatePreCtChartCheck(
    $id: ID!
    $nurseLocation: String
    $patientIdentificationCheck: Boolean
    $patientHeight: String
    $patientWeight: String
    $previousRadiationTreatment: Boolean
    $anticancerDrugs: Boolean
    $chemoPastWeek: Boolean
    $hasImplantedMedicalDevice: Boolean
    $hasInfectiousDisease: Boolean
    $hasMroInfection: Boolean
    $outsideWaLastTwelveMonths: Boolean
    $fallLastTwelveMonths: Boolean
    $safetyConcernsMobility: Boolean
    $hasCurrentAcd: Boolean
    $acdInfoRequested: Boolean
    $hasCurrentResusMxPlan: Boolean
    $staffConcern: Boolean
    $anxietyClaustrophobia: Boolean
    $treatmentOverHalfHour: Boolean
    $currentInpatient: Boolean
    $requiresMaskCast: Boolean
    $existingPressureInjuryWound: Boolean
    $hasPatientAllergies: Boolean
    $additionalNotes: String
  ) {
    updatePreCtChartCheck(
      id: $id
      nurseLocation: $nurseLocation
      patientIdentificationCheck: $patientIdentificationCheck
      patientHeight: $patientHeight
      patientWeight: $patientWeight
      previousRadiationTreatment: $previousRadiationTreatment
      anticancerDrugs: $anticancerDrugs
      chemoPastWeek: $chemoPastWeek
      hasImplantedMedicalDevice: $hasImplantedMedicalDevice
      hasInfectiousDisease: $hasInfectiousDisease
      hasMroInfection: $hasMroInfection
      outsideWaLastTwelveMonths: $outsideWaLastTwelveMonths
      fallLastTwelveMonths: $fallLastTwelveMonths
      safetyConcernsMobility: $safetyConcernsMobility
      hasCurrentAcd: $hasCurrentAcd
      acdInfoRequested: $acdInfoRequested
      hasCurrentResusMxPlan: $hasCurrentResusMxPlan
      staffConcern: $staffConcern
      anxietyClaustrophobia: $anxietyClaustrophobia
      treatmentOverHalfHour: $treatmentOverHalfHour
      currentInpatient: $currentInpatient
      requiresMaskCast: $requiresMaskCast
      existingPressureInjuryWound: $existingPressureInjuryWound
      hasPatientAllergies: $hasPatientAllergies
      additionalNotes: $additionalNotes
    ) {
      preCtChartCheck {
        id
        nurseLocation
        patientIdentificationCheck
        patientHeight
        patientWeight
        previousRadiationTreatment
        anticancerDrugs
        chemoPastWeek
        hasImplantedMedicalDevice
        hasInfectiousDisease
        infectiousDisease {
          id
          ...InfectiousDisease
        }
        hasMroInfection
        mroInfection {
          id
          ...MroInfection
        }
        outsideWaLastTwelveMonths
        fallLastTwelveMonths
        safetyConcernsMobility
        hasCurrentAcd
        acdInfoRequested
        hasCurrentResusMxPlan
        staffConcern
        anxietyClaustrophobia
        treatmentOverHalfHour
        currentInpatient
        requiresMaskCast
        existingPressureInjuryWound
        hasPatientAllergies
        additionalNotes
        lastUpdateUser {
          fieldName
          lastUpdateUser
          updatedDate
        }
      }
    }
  }

  ${PCCC_INFECTIOUS_DISEASE_FRAGMENT}
  ${PCCC_MRO_INFECTION_FRAGMENT}
`;

export const DELETE_PRE_CT_CHART_CHECK = gql`
  mutation deletePreCtChartCheck($id: ID!) {
    deletePreCtChartCheck(id: $id) {
      ok
    }
  }
`;

export const CREATE_ASSESSMENT_DEVICE = gql`
  mutation createAssessmentDevice($assessmentId: ID!, $deviceField: String!, $name: String!) {
    createAssessmentDevice(assessmentId: $assessmentId, deviceField: $deviceField, name: $name) {
      preCtChartCheck {
        id
        implantedMedicalDevice {
          id
          ...ImplantedDevice
        }
      }
      implantedMedicalDevice {
        id
        ...ImplantedDevice
      }
    }
  }

  ${PCCC_IMPLANTED_DEVICE_FRAGMENT}
`;

export const DELETE_ASSESSMENT_DEVICE = gql`
  mutation deleteAssessmentDevice($id: ID!) {
    deleteAssessmentDevice(id: $id) {
      preCtChartCheck {
        id
        implantedMedicalDevice {
          id
          ...ImplantedDevice
        }
      }
      ok
    }
  }

  ${PCCC_IMPLANTED_DEVICE_FRAGMENT}
`;

export const UPDATE_ASSESSMENT_DEVICE = gql`
  mutation updateAssessmentDevice($id: ID!, $deviceField: String!, $name: String!) {
    updateAssessmentDevice(id: $id, deviceField: $deviceField, name: $name) {
      preCtChartCheck {
        id
        implantedMedicalDevice {
          id
          ...ImplantedDevice
        }
      }
    }
  }

  ${PCCC_IMPLANTED_DEVICE_FRAGMENT}
`;

export const CREATE_INFECTIOUS_DISEASE = gql`
  mutation CreateInfectiousDisease($assessmentId: ID!, $baseValue: String!, $otherValue: String) {
    createInfectiousDisease(assessmentId: $assessmentId, baseValue: $baseValue, otherValue: $otherValue) {
      preCtChartCheck {
        id
        infectiousDisease {
          id
          ...InfectiousDisease
        }
      }
      infectiousDisease {
        id
        ...InfectiousDisease
      }
    }
  }

  ${PCCC_INFECTIOUS_DISEASE_FRAGMENT}
`;

export const DELETE_INFECTIOUS_DISEASE = gql`
  mutation DeleteInfectiousDisease($id: ID!) {
    deleteInfectiousDisease(id: $id) {
      preCtChartCheck {
        id
        infectiousDisease {
          id
          ...InfectiousDisease
        }
      }
      ok
    }
  }

  ${PCCC_INFECTIOUS_DISEASE_FRAGMENT}
`;

export const UPDATE_INFECTIOUS_DISEASE = gql`
  mutation UpdateInfectiousDisease($id: ID!, $baseValue: String, $otherValue: String) {
    updateInfectiousDisease(id: $id, baseValue: $baseValue, otherValue: $otherValue) {
      preCtChartCheck {
        id
        infectiousDisease {
          id
          ...InfectiousDisease
        }
      }
    }
  }

  ${PCCC_INFECTIOUS_DISEASE_FRAGMENT}
`;

export const CREATE_MRO_INFECTION = gql`
  mutation CreateMroInfection($assessmentId: ID!, $baseValue: String!, $otherValue: String) {
    createMroInfection(assessmentId: $assessmentId, baseValue: $baseValue, otherValue: $otherValue) {
      preCtChartCheck {
        id
        mroInfection {
          id
          ...MroInfection
        }
      }
      mroInfection {
        id
        ...MroInfection
      }
    }
  }

  ${PCCC_MRO_INFECTION_FRAGMENT}
`;

export const DELETE_MRO_INFECTION = gql`
  mutation DeleteMroInfection($id: ID!) {
    deleteMroInfection(id: $id) {
      preCtChartCheck {
        id
        mroInfection {
          id
          ...MroInfection
        }
      }
      ok
    }
  }

  ${PCCC_MRO_INFECTION_FRAGMENT}
`;

export const UPDATE_MRO_INFECTION = gql`
  mutation UpdateMroInfection($id: ID!, $baseValue: String, $otherValue: String) {
    updateMroInfection(id: $id, baseValue: $baseValue, otherValue: $otherValue) {
      preCtChartCheck {
        id
        mroInfection {
          id
          ...MroInfection
        }
      }
    }
  }

  ${PCCC_MRO_INFECTION_FRAGMENT}
`;

export const CREATE_ALLERGY = gql`
  mutation CreatePcccAllergy($assessmentId: ID!, $allergyType: String!, $additionalDetails: String) {
    createPcccAllergy(assessmentId: $assessmentId, allergyType: $allergyType, additionalDetails: $additionalDetails) {
      preCtChartCheck {
        id
        pcccAllergy {
          id
          ...Allergy
        }
      }
    }
  }

  ${PCCC_ALLERGY_FRAGMENT}
`;

export const DELETE_ALLERGY = gql`
  mutation DeletePcccAllergy($id: ID!) {
    deletePcccAllergy(id: $id) {
      preCtChartCheck {
        id
        pcccAllergy {
          id
          ...Allergy
        }
      }
      ok
    }
  }

  ${PCCC_ALLERGY_FRAGMENT}
`;

export const UPDATE_ALLERGY = gql`
  mutation UpdatePcccAllergy($id: ID!, $allergyType: String, $additionalDetails: String) {
    updatePcccAllergy(id: $id, allergyType: $allergyType, additionalDetails: $additionalDetails) {
      preCtChartCheck {
        id
        pcccAllergy {
          id
          ...Allergy
        }
      }
    }
  }

  ${PCCC_ALLERGY_FRAGMENT}
`;

export const CREATE_REACTION = gql`
  mutation CreatePcccReaction($allergyId: ID!, $baseValue: String, $otherValue: String) {
    createPcccReaction(allergyId: $allergyId, baseValue: $baseValue, otherValue: $otherValue) {
      preCtChartCheck {
        id
        pcccAllergy {
          id
          ...Allergy
        }
      }
    }
  }

  ${PCCC_ALLERGY_FRAGMENT}
`;

export const DELETE_REACTION = gql`
  mutation DeletePcccReaction($id: ID!) {
    deletePcccReaction(id: $id) {
      preCtChartCheck {
        id
        pcccAllergy {
          id
          ...Allergy
        }
      }
      ok
    }
  }

  ${PCCC_ALLERGY_FRAGMENT}
`;

export const UPDATE_REACTION = gql`
  mutation UpdatePcccReaction($id: ID!, $baseValue: String, $otherValue: String) {
    updatePcccReaction(id: $id, baseValue: $baseValue, otherValue: $otherValue) {
      preCtChartCheck {
        id
        pcccAllergy {
          id
          ...Allergy
        }
      }
    }
  }

  ${PCCC_ALLERGY_FRAGMENT}
`;

export const SUBMIT_PRE_CT_CHART_CHECK = gql`
  mutation createPreCTChartCheckSubmission($id: ID!) {
    createPreCTChartCheckSubmission(id: $id) {
      submission {
        id
        pdf
      }
      submissionCreated
    }
  }
`;

export const CREATE_INITIAL_TRIAGE_QCL_CALL = gql`
  mutation createInitialTriageQclCall($id: ID!) {
    createInitialTriageQclCall(id: $id) {
      submissionCreated
    }
  }
`;
