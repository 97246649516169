import React from 'react';
import {
  SuccessWhiteBorderIcon,
  InfoCircleBlueWhiteBorderIcon,
  WarningCircleWhiteBorderIcon,
} from 'shared-components/images';
import { styled } from '@mui/system';
interface CycleButtonProps {
  isSelected: boolean;
  cycleId: number | null;
  type: 'approved' | 'unapproved' | 'printed' | '';
  onClick: (cycle: number) => void;
  disabled?: boolean;
}

const StyledCircularTab = styled('div')`
  display: flex;
  flex-shrink: 0;
  justify-content: center;
  align-items: center;
  width: 56px;
  height: 56px;
  border-radius: 50%;
  font-weight: 700;
  color: ${(props) => props.theme.palette.primary.contrastText};
  text-decoration: none;
  font-size: 16px;
  background-color: ${(props) => props.theme.palette.action.active};
  cursor: pointer;
  &.active {
    color: white !important;
    background-color: ${(props) => props.theme.palette.primary.main} !important;
  }
  &.continuous {
    pointer-events: none;
  }
  &:hover {
    background-color: ${(props) => props.theme.palette.action.hover};
    text-decoration: none;
    color: inherit;
  }
  position: relative;
  flex-direction: column;
  &[data-disabled='true'] {
    cursor: not-allowed;
    &:hover {
      background-color: ${(props) => props.theme.palette.grey[100]};
    }
  }
`;

const StyledSuccessOutline = styled(SuccessWhiteBorderIcon)`
  width: 20px;
  height: 20px;
`;

const StyledInfoIcon = styled(InfoCircleBlueWhiteBorderIcon)`
  width: 20px;
  height: 20px;
`;

const StyledWarning = styled(WarningCircleWhiteBorderIcon)`
  width: 20px;
  height: 20px;
`;

const StyledIconWrapper = styled('div')`
  position: absolute;
  bottom: -8px;
`;

const CycleButton = (props: CycleButtonProps): JSX.Element => {
  const { cycleId, type, onClick, isSelected, disabled } = props;
  const icon = () => {
    if (type === 'printed') return <StyledSuccessOutline />;
    if (type === 'approved') return <StyledInfoIcon />;
    if (type === 'unapproved') return <StyledWarning />;
    return <></>;
  };
  if (cycleId === null || cycleId === undefined) return <></>;
  return (
    <StyledCircularTab
      key={cycleId}
      onClick={() => (disabled ? () => {} : onClick(cycleId))}
      id={`cycle-button-${cycleId}`}
      data-test-id={`cycle-button-${cycleId}`}
      className={isSelected ? 'active' : ''}
      data-disabled={disabled ?? false}>
      <div>{cycleId + 1}</div>
      <StyledIconWrapper>{icon()}</StyledIconWrapper>
    </StyledCircularTab>
  );
};

export default CycleButton;
