// eslint-disable-next-line no-use-before-define
import React from 'react';

import CovidCard from './CovidCard';
import './Covid.scss';

const CovidAppointment = (): JSX.Element => {
  return (
    <div className="covid">
      <div className="covid-appointment">
        <div className="covid-appointment-info">
          <h2>{'COVID-19 and your appointments'}</h2>
          <p>
            If your doctor has recommended continuing with your planned appointments, it is important that you do so.
            Click below for more information.
          </p>
        </div>
      </div>
      <CovidCard
        title={'Arrive no more than 10 minutes early'}
        content={
          'Please ensure you do not arrive in the centre any earlier than 10 minutes prior to your appointment time.'
        }
      />
      <CovidCard
        title={'Help us maintain safe distancing'}
        content={
          'In areas where there are COVID-19 restrictions, visitors are limited to only those required for patient safety and wellbeing – if you are unsure if this applies to you, please contact your Centre for more information'
        }
      />
      <CovidCard
        title={'Contact us if you feel unwell'}
        content={
          'Call your treatment centre prior to attending if you feel unwell, have any symptoms, are a close contact of a suspected or confirmed case, or just unsure if you should attend.'
        }
        classes="final"
      />
    </div>
  );
};

export default CovidAppointment;
