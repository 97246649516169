import React, { useRef, useState } from 'react';
import { useLayoutEffect } from 'react';
import { Tooltip } from '@mui/material';
import { styled } from '@mui/system';

interface AutoTruncateProps {
  id: string | number;
  text: string;
}

const TruncatedText = styled('span')`
  flex: 1;
  flex-wrap: nowrap;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
`;

export const ReactiveTruncatedText = ({ text, id }: AutoTruncateProps): JSX.Element => {
  const ref = useRef<HTMLElement>(null);
  const [hasOverflow, setHasOverflow] = useState<boolean>(false);
  useLayoutEffect(() => {
    const { current } = ref;
    if (!current) return;
    setHasOverflow(current.scrollWidth > current.clientWidth);
  }, [ref]);

  return hasOverflow ? (
    <Tooltip title={text} style={{ textAlign: 'left' }}>
      <TruncatedText ref={ref} id={'tooltip_' + id}>
        {text}
      </TruncatedText>
    </Tooltip>
  ) : (
    <TruncatedText ref={ref}>{text}</TruncatedText>
  );
};
