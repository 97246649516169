// eslint-disable-next-line no-use-before-define
import React, { Component } from 'react';
import { StandardDialog } from 'shared-components/components';
import { Stack, Typography } from '@mui/material';

interface Props {
  isOpen: boolean;
  dismissFunction: () => void;
  returnToStart: () => void;
  updateFirstReturn: (arg0: boolean) => void;
  children?: React.ReactNode;
}

class WelcomeBackModal extends Component<Props> {
  public render(): JSX.Element {
    const { isOpen, dismissFunction, returnToStart, updateFirstReturn } = this.props;
    return (
      <StandardDialog
        open={isOpen}
        onClose={dismissFunction}
        title="Welcome Back!"
        closeText="Continue from here"
        submitText="Go back to start"
        onSubmit={() => {
          dismissFunction();
          returnToStart();
        }}>
        <Stack paddingY={2} gap={1}>
          <Typography>
            Would you like to continue from your last change or start from the beginning of this form?
          </Typography>
          <Typography>Any information you previously entered will remain saved.</Typography>
        </Stack>
      </StandardDialog>
    );
  }
}

export default WelcomeBackModal;
