import { BufferOp, GeoJSONReader, GeoJSONWriter } from 'turf-jsts';
import { multiPolygon } from '@turf/helpers';
import React, { createRef, useEffect } from 'react';
import polygonClipping, { Geom } from 'polygon-clipping';

const reader = new GeoJSONReader();
const writer = new GeoJSONWriter();

type PolygonFeature = GeoJSON.Feature<GeoJSON.Polygon | GeoJSON.MultiPolygon>;

const bufferFeature = (feature: PolygonFeature | null, distance: number) => {
  if (feature === null) {
    return null;
  }
  if (feature.properties === null) {
    feature.properties = {};
  }
  if (!('buffered' in feature.properties)) {
    try {
      const geom = reader.read(feature.geometry);
      feature.geometry = writer.write(BufferOp.bufferOp(geom, distance));
      feature.properties.buffered = true;
    } catch (error) {}
  }
  return feature;
};

const unionFeatures = (features: PolygonFeature[]) => {
  if (features.length === 0) {
    return null;
  }
  const coordinates = features.map((f) => f.geometry.coordinates);
  return multiPolygon(polygonClipping.union.apply(null, coordinates as [geom: Geom, ...geoms: Geom[]]));
};

const getPolygonCoordinates = (feature: PolygonFeature | null) => {
  if (feature !== null) {
    if (feature.geometry.type === 'Polygon') {
      return feature.geometry.coordinates;
    } else if (feature.geometry.type === 'MultiPolygon') {
      return feature.geometry.coordinates.flat();
    }
  }
  return [];
};

type PageImageViewerProps = {
  img: HTMLImageElement | null;
  features: PolygonFeature[];
  width: number;
  imgWidth: number;
  loading: boolean;
};

function PageImageViewer({ img, features, width, imgWidth, loading }: PageImageViewerProps): JSX.Element {
  const canvasRef = createRef<HTMLCanvasElement>();

  useEffect(() => {
    if (img === null) {
      return;
    }
    const bufferSize = 10;
    const unionFeature = bufferFeature(unionFeatures(features), bufferSize);
    const polygonCoordinates = getPolygonCoordinates(unionFeature);
    const canvas = canvasRef.current;
    const drawImageWithHighlighting = (img: HTMLImageElement) => {
      if (canvas === null) {
        return;
      }
      const scale = width / imgWidth;
      canvas.width = width;
      canvas.height = img.height * (width / img.width);
      canvas.style.webkitFilter = loading ? 'blur(5px)' : 'blur(0px)';
      canvas.style.opacity = loading ? '0.6' : '1.0';
      canvas.style.imageRendering = 'crisp-edges';
      const ctx = canvas.getContext('2d');
      if (ctx !== null) {
        ctx.drawImage(img, 0, 0, img.width, img.height, 0, 0, canvas.width, canvas.height);
        ctx.scale(scale, scale);
        ctx.globalCompositeOperation = 'multiply';
        for (const coords of polygonCoordinates) {
          if (coords.length > 2) {
            ctx.fillStyle = 'rgba(251, 188, 5, 0.3)';
            ctx.beginPath();
            ctx.moveTo(coords[0][0], coords[0][1]);
            for (let i = 1; i < coords.length; i += 1) {
              const [x, y] = coords[i];
              ctx.lineTo(x, y);
            }
            ctx.closePath();
            ctx.fill();
          }
        }
      }
    };
    img.onload = () => drawImageWithHighlighting(img);
    if (img.complete) {
      drawImageWithHighlighting(img);
    }
  }, [img, features, width, imgWidth, loading, canvasRef]);

  return <canvas ref={canvasRef} />;
}

export default PageImageViewer;
