// eslint-disable-next-line no-use-before-define
import { useLazyQuery, useMutation } from '@apollo/client';
import { Field, Form, Formik, FormikProps, FormikValues } from 'formik';
import { FormContainer } from 'op-components';
import { useErrorModalContext } from 'op-contexts';
import UserContext, { UserContextType } from 'op-contexts/UserContext/UserContext';
import { LOG_EVENT } from 'op-graphql';
import { NewPatient } from 'op-interfaces/PatientInterfaces';
import { isUs } from 'op-utils/helpers';
import { formatMyPhoneNumber } from 'op-utils/PatientHelper';
import { default as React, useContext, useEffect, useMemo, useRef, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { FormRow } from 'shared-components/components';
import { getOptionByValue } from 'op-pages/RO/Careplan/DiagnosisPages/Utils';
import dayjs from 'dayjs';
import {
  AddressSectionField,
  ToggleButtonGroupField,
  TextAreaField,
} from 'shared-components/components/FormikComponents';
import { ELASTIC_SEARCH_EVENTS, logPage } from 'shared-components/utils';
import { styled } from '@mui/system';
import { ADD_PATIENT, CREATE_PATIENT_DUPLICATION_CHECK } from '../../../graphql/Patient';
import { StyledHorizontalLine } from '../RegistrationFormUS/Helper';
import {
  GENDER_OPTIONS,
  mapListDataWithId,
  PHONE_REQUIRED,
  SEARCH_DROPDOWN_PLACEHOLDER,
  SUBMIT_BUTTON_TEXT,
  SUBTITLE,
  TITLE,
  US_GENDER_OPTIONS,
} from './common';
import ModalCreateNewPatient from './ModalCreateNewPatient';
import ModalDuplicationResult from './ModalDuplicationResult';
import { generateValidationSchema } from './validation';
import { BaseDatePicker, BaseAutocomplete, SelectOptionType } from 'shared-components/components/FormFields';
import { Stack } from '@mui/material';
import { Dayjs } from 'dayjs';
import { useTheme } from '@mui/material';

const REACT_APP_REGION = import.meta.env.REACT_APP_REGION;
const region = REACT_APP_REGION;

interface Props {
  physiciansRefData: [
    {
      id: any;
      name: string;
    },
  ];
}

const SidePanel = styled('div')`
  border-left: 1px solid ${(props) => props.theme.palette.grey[300]};
  height: 100%;
  width: 20%;
`;

const HeaderTitle = styled('div')`
  font-size: 24px;
  line-height: 28px;
  margin-bottom: 8px;
`;

const SubTitle = styled('div')`
  font-size: 14px;
  line-height: 20px;
  margin-bottom: 40px;
`;

const CreatePatientBasicForm = ({ physiciansRefData }: Props): JSX.Element => {
  const history = useHistory();
  const { setError } = useErrorModalContext();

  const EMPTY_PATIENT = {
    firstName: '',
    middleName: '',
    lastName: '',
    gender: '',
    dateOfBirth: '',
    responsiblePhysicianId: '',
    mobileNumber: '',
    homeNumber: '',
    address: {
      autofilled: '',
      country: '',
      line1: '',
      line2: '',
      city: '',
      state: '',
      postcode: '',
    },
  };
  const [showCreateModal, setShowCreateModal] = useState<boolean>(false);
  const [showDuplicationCheckModal, setShowDuplicationCheckModal] = useState<boolean>(false);
  const [submitCountTracker, setSubmitCountTracker] = useState<number>(0);
  const [newPatient, setNewPatient] = useState<NewPatient>(EMPTY_PATIENT);
  const [hasValidationErrors, setHasValidationErrors] = useState<boolean>(false);
  const [searchStartTime, setSearchStartTime] = useState(new Date().getTime());
  const { state } = useContext<UserContextType>(UserContext);
  const startTime = useRef<number>(new Date().getTime());
  const theme = useTheme();

  // This key is currently set on PSO entry to create patient (PSO Dashboard). If
  // more entry points are added in the future this key will need to be set on all
  // of them as well
  const createPatientKey = sessionStorage.getItem('createPatientKey');

  const [
    checkForPatientDuplicates,
    { data: duplicationCheckResult, error: duplicationCheckError, loading: duplicationCheckLoading },
  ] = useLazyQuery(CREATE_PATIENT_DUPLICATION_CHECK, {
    onCompleted: () => {
      const searchTime = (new Date().getTime() - searchStartTime) / 1000;
      const pageData = { eventName: ELASTIC_SEARCH_EVENTS.DUPLICATE_SEARCH_TIME, searchTime, createPatientKey };
      logEvent({ variables: { data: JSON.stringify(pageData) } });
    },
  });
  const [createPatientMutation, { loading: createPatientIsLoading, error: createPatientError }] =
    useMutation(ADD_PATIENT);
  const [logEvent] = useMutation(LOG_EVENT);
  const potentialDuplicateData = useMemo(
    () => duplicationCheckResult?.createPatientDuplicationCheck ?? [],
    [duplicationCheckResult],
  );

  // eslint-disable-next-line
  console.log(
    potentialDuplicateData,
    duplicationCheckError,
    submitCountTracker,
    duplicationCheckLoading,
    createPatientError,
  );

  useEffect(() => {
    if (duplicationCheckError || createPatientError) return setError();

    setShowCreateModal(false);
    setShowDuplicationCheckModal(false);

    if (submitCountTracker > 0 && !hasValidationErrors) {
      if (duplicationCheckResult) {
        const numberOfDuplicates = duplicationCheckResult.createPatientDuplicationCheck?.length;
        if (numberOfDuplicates > 0) {
          // let duplicateIds = Array(10).fill('-1');
          duplicationCheckResult.createPatientDuplicationCheck.map((data: { id: string }) => data?.id);
          const duplicateIds = {};
          for (let i = 0; i < 10; i++) {
            //@ts-ignore
            duplicateIds[`duplicateId${i}`] = duplicationCheckResult.createPatientDuplicationCheck?.[i]?.id || '0';
          }
          const pageData = {
            eventName: ELASTIC_SEARCH_EVENTS.DUPLICATE_TRIGGERED,
            numberOfDuplicates,
            createPatientKey,
            ...duplicateIds,
          };
          logPage(startTime?.current, pageData, logEvent);
          setShowDuplicationCheckModal(true);
        } else {
          // eslint-disable-next-line
          console.log('AAAAA');
          setShowCreateModal(true);
        }
      }
    }
  }, [potentialDuplicateData, duplicationCheckError, submitCountTracker, duplicationCheckLoading, createPatientError]);

  const createPatient = () =>
    createPatientMutation({
      variables: {
        firstName: newPatient.firstName,
        middleName: newPatient.middleName,
        lastName: newPatient.lastName,
        gender: newPatient.gender,
        dob: newPatient.dateOfBirth,
        responsiblePhysicianId: newPatient.responsiblePhysicianId,
        mobilePhoneNumber: newPatient.mobileNumber,
        homePhoneNumber: newPatient.homeNumber,
        line1: newPatient.address.line1,
        line2: newPatient.address.line2,
        city: newPatient.address.city,
        postcode: newPatient.address.postcode,
        state: newPatient.address.state,
        country: newPatient.address.country,
      },
    }).then((response: any) => {
      const newPatientData = response.data.addPatient.patient;
      const pageData = {
        eventName: ELASTIC_SEARCH_EVENTS.SUBMIT,
        patientId: newPatientData.id,
        createPatientKey,
      };
      logPage(startTime?.current, pageData, logEvent);
      //for mo
      if (state?.primaryRole === 'mo') {
        history.push(`/medonc/patient/${newPatientData.id}/summary`);
        return;
      }
      history.push(state?.primaryRole === 'ro' ? '/radiation' : `/navigator/patient/${newPatientData.id}/summary`);
    });

  const submitTrackerHandler = (newValue: number) => {
    // state values are getting refreshed before getting duplication check back.
    // check if new submit count > greater than old submit count
    if (newValue > submitCountTracker) setSubmitCountTracker(newValue);
  };

  const formContainerProps = {
    isLocked: false,
    updatedBy: '', // TEMP
    deleteModal: () => {},
    showSaveAndExit: false,
    pageNumber: 1,
    sectionTitle: TITLE,
    continueLink: '',
    submitButtonText: SUBMIT_BUTTON_TEXT,
    showDiscard: false,
    sidePanelWidth: '-15%',
    footerPosition: 'relative',
    isLoading: duplicationCheckLoading,
  };
  return (
    <>
      <Formik
        initialValues={newPatient}
        onSubmit={(values: NewPatient) => {
          setNewPatient(values);
          setSearchStartTime(new Date().getTime());
          checkForPatientDuplicates({
            variables: {
              firstName: values.firstName,
              middleName: values.middleName,
              lastName: values.lastName,
              gender: values.gender,
              dob: values.dateOfBirth,
              mobilePhoneNumber: values.mobileNumber,
              homePhoneNumber: values.homeNumber,
              line1: values.address.line1,
              line2: values.address.line2,
              city: values.address.city,
              postcode: values.address.postcode,
              state: values.address.state,
              country: values.address.country,
            },
          });
        }}
        validate={(values: FormikValues) => generateValidationSchema(values)}
        validateOnBlur={submitCountTracker > 0}>
        {({ values, errors, submitForm, submitCount, setFieldValue }: FormikProps<NewPatient>) => {
          setHasValidationErrors(Object.keys(errors).length > 0);
          submitTrackerHandler(submitCount);
          const selectStyle = {
            dropdownIndicator: () => ({ color: theme.palette.primary.dark, marginRight: '8px' }),
            indicatorSeparator: () => ({ display: 'none' }),
            control: (base: any) => ({
              ...base,
              border:
                submitCount > 0 && errors.responsiblePhysicianId
                  ? `1px solid ${theme.palette.error.main}`
                  : `1px solid ${theme.palette.grey[300]}`,
              boxShadow: 'none',
              height: '40px',
              '&:hover': {
                border: `1px solid ${theme.palette.grey[300]}`,
              },
            }),
            valueContainer: (base: any) => ({
              ...base,
              lineHeight: '32px',
              padding: '0px 8px',
            }),
          };

          return (
            <FormContainer
              {...formContainerProps}
              handleValidation={(event: React.MouseEvent<HTMLInputElement>): void => {
                void event;
              }}
              submitForm={submitForm}>
              <Form>
                <HeaderTitle data-test-id="create-patient-page-title">{TITLE}</HeaderTitle>
                <SubTitle data-test-id="create-patient-page-subtitle">{SUBTITLE}</SubTitle>
                <FormRow fieldLabel={'First name*'} fieldName={'firstName'} labelClass={'label-form-row'}>
                  <Field id="firstName" name="firstName" component={TextAreaField} />
                </FormRow>
                <FormRow fieldLabel="Middle name" fieldName={'middleName'} labelClass={'label-form-row'}>
                  <Field id="middleName" name="middleName" component={TextAreaField} />
                </FormRow>
                <FormRow fieldLabel={'Last name*'} fieldName={'lastName'} labelClass={'label-form-row'}>
                  <Field id="lastName" name="lastName" component={TextAreaField} />
                </FormRow>
                <FormRow fieldLabel={isUs() ? 'Sex*' : 'Gender*'} fieldName={'gender'} labelClass={'label-form-row'}>
                  <Field
                    name="gender"
                    component={ToggleButtonGroupField}
                    options={isUs() ? US_GENDER_OPTIONS : GENDER_OPTIONS}
                    minwidth={'131px'}
                    alternateStyle
                  />
                </FormRow>
                <FormRow
                  fieldLabel="Date of birth*"
                  fieldName="dateOfBirth"
                  className={
                    submitCount > 0 && errors.dateOfBirth ? 'create-patient-date-error' : 'create-patient-date'
                  }
                  labelClass={'label-form-row'}>
                  <Stack sx={{ maxWidth: '200px' }}>
                    <BaseDatePicker
                      id="dateOfBirth"
                      disableFuture
                      value={values.dateOfBirth ? dayjs(values.dateOfBirth) : null}
                      error={submitCount > 0 && !!errors.dateOfBirth}
                      onChange={(date: Dayjs | null, context: any) => {
                        if (context.validationError) return;
                        const dateString = date ? dayjs(date).format('YYYY-MM-DD') : '';
                        setFieldValue('dateOfBirth', dateString);
                      }}
                    />
                  </Stack>
                </FormRow>
                <FormRow
                  fieldLabel={isUs() ? 'Assigned provider*' : 'Responsible physician*'}
                  fieldName={'responsiblePhysicianId'}
                  labelClass={'label-form-row'}>
                  <Field
                    name="responsiblePhysicianId"
                    id="responsiblePhysicianId"
                    component={BaseAutocomplete}
                    value={getOptionByValue(
                      physiciansRefData.map((physician) => ({ label: physician?.name, value: physician?.id })),
                      values?.responsiblePhysicianId,
                    )}
                    options={physiciansRefData ? mapListDataWithId(physiciansRefData) : []}
                    styles={selectStyle}
                    placeholder={SEARCH_DROPDOWN_PLACEHOLDER}
                    classNamePrefix={'responsible-physician-search'}
                    noOptionsMessage={() => 'No matches found'}
                    onChange={(option: SelectOptionType | string) => {
                      const value = typeof option === 'string' ? option : option?.value;
                      setFieldValue('responsiblePhysicianId', value);
                    }}
                    inputProps={{
                      error: submitCount > 0 && !!errors.responsiblePhysicianId,
                    }}
                  />
                </FormRow>

                <FormRow fieldLabel={'Mobile phone number*'} fieldName={'mobileNumber'} labelClass={'label-form-row'}>
                  <Field
                    id="mobileNumber"
                    name="mobileNumber"
                    component={TextAreaField}
                    placeholder={PHONE_REQUIRED}
                    updateMutation={(value: any) => {
                      // we dont format mobile numbers in US rego forms, so dont format them here either
                      const formattedPhoneNumber = formatMyPhoneNumber(value, region);
                      setFieldValue('mobileNumber', formattedPhoneNumber);
                    }}
                  />
                </FormRow>
                <FormRow labelClass={'label-form-row'}>
                  <StyledHorizontalLine>
                    <span>OR</span>
                  </StyledHorizontalLine>
                </FormRow>
                <FormRow fieldLabel={'Home phone number*'} fieldName={'homeNumber'} labelClass={'label-form-row'}>
                  <Field
                    id="homeNumber"
                    name="homeNumber"
                    placeholder={PHONE_REQUIRED}
                    component={TextAreaField}
                    updateMutation={(value: any) => {
                      // we dont format mobile numbers in US rego forms, so dont format them here either
                      const formattedPhoneNumber = formatMyPhoneNumber(value, region);
                      setFieldValue('homeNumber', formattedPhoneNumber);
                    }}
                  />
                </FormRow>
                <AddressSectionField name="address" values={values.address} includeMandatoryLabels={isUs()} />
              </Form>
            </FormContainer>
          );
        }}
      </Formik>
      <SidePanel />

      {showDuplicationCheckModal && (
        <ModalDuplicationResult
          newPatientData={newPatient}
          duplicateData={potentialDuplicateData}
          isOpen={showDuplicationCheckModal}
          handleClose={() => setShowDuplicationCheckModal(false)}
          rightButtonHandleClick={() => {
            const pageData = {
              eventName: ELASTIC_SEARCH_EVENTS.DUPLICATE_IGNORED,
              createPatientKey,
            };
            logPage(startTime?.current, pageData, logEvent);
            setShowCreateModal(true);
            setShowDuplicationCheckModal(false);
          }}
        />
      )}

      {showCreateModal && (
        <ModalCreateNewPatient
          isOpen={showCreateModal}
          handleClose={() => setShowCreateModal(false)}
          isLoading={createPatientIsLoading}
          submissionProcessingTime={1}
          rightButtonHandleClick={() => {
            createPatient();
          }}
        />
      )}
    </>
  );
};

export default CreatePatientBasicForm;
