import React from 'react';
import {
  Dialog,
  DialogProps,
  DialogTitle,
  DialogContent,
  DialogActions,
  Typography,
  Divider,
  Stack,
} from '@mui/material';
import { LoadingButtonProps } from '@mui/lab';
import { DialogButton, DialogLoadingButton } from './DialogButtons';

interface StandardDialogProps extends DialogProps {
  title: string;
  open: boolean;
  children?: React.ReactNode;
  onClose?: (val: boolean) => void;
  closeText?: string;
  onSubmit?: () => void;
  submitText?: string;
  onSecondarySubmit?: () => void;
  secondarySubmitText?: string;
  showDividers?: boolean;
  enableCloseOnBackdropClick?: boolean;
  destructiveSubmit?: boolean;
  titleIcon?: React.ReactNode;
  submitButtonProps?: LoadingButtonProps;
}

/*
  Basic dialog for showing a title, content and a close button
*/
const StandardDialog = ({
  title,
  open,
  onClose,
  children,
  closeText,
  onSubmit,
  submitText = 'Submit',
  onSecondarySubmit,
  secondarySubmitText = 'Secondary Submit',
  enableCloseOnBackdropClick,
  showDividers,
  destructiveSubmit,
  titleIcon,
  submitButtonProps,
  ...rest
}: StandardDialogProps): JSX.Element => {
  const noSubmit = !onSubmit && !onSecondarySubmit;

  // If there is a submit or secondary submit function, change the close text to 'Cancel'
  const defaultCloseText = closeText ? closeText : noSubmit ? 'Close' : 'Cancel';

  const handleClose = (_: any, reason: string) => {
    // Prevent closing the dialog when clicking on the backdrop
    // If there is no submit functionality, default to closing the dialog
    const shouldClose = noSubmit || enableCloseOnBackdropClick;
    if (reason && reason === 'backdropClick' && !shouldClose) return;
    onClose && onClose(false);
  };

  return (
    <Dialog open={open} onClose={handleClose} {...rest}>
      <DialogTitle id="alert-dialog-title" data-testid="dialog-title">
        <Stack direction="row" alignItems="center" gap={1}>
          <Typography variant="h6">{title}</Typography>
          {titleIcon}
        </Stack>
      </DialogTitle>
      {showDividers && <Divider />}
      <DialogContent>{children}</DialogContent>
      {showDividers && <Divider />}
      <DialogActions>
        {onClose && (
          <DialogButton data-testid="dialog-cancel-btn" onClick={() => onClose(false)}>
            {defaultCloseText}
          </DialogButton>
        )}
        {onSecondarySubmit && (
          <DialogButton data-testid="dialog-secondary-submit-btn" onClick={onSecondarySubmit}>
            {secondarySubmitText}
          </DialogButton>
        )}
        {onSubmit && (
          <DialogLoadingButton
            data-testid="dialog-submit-btn"
            onClick={onSubmit}
            color={destructiveSubmit ? 'error' : 'primary'}
            variant="text"
            sx={destructiveSubmit ? { color: 'error.main' } : {}}
            {...submitButtonProps}>
            {submitText}
          </DialogLoadingButton>
        )}
      </DialogActions>
    </Dialog>
  );
};

export default StandardDialog;
