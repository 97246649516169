export const FIELDS = {
  FORM_HEADING: {
    NAME: 'formHeading',
    TITLE: 'Information Notice',
  },
  NOTICE: {
    NAME: 'informationNoticeAccepted',
    TITLE: 'Social Geographic History',
    CONTENT:
      'By signing below, you confirm that you understand what you have read and consent to the collection, use and disclosure of your personal information (including health and other sensitive information) as described in this form. You are free to withdraw your consent at any time by contacting us.',
  },
  ALLIED_HEALTH: {
    NAME: 'informationNoticeAlliedHealthReceiveUpdates',
    SUB_TITLE: 'Allied health and well being services and information',
    CONTENT:
      'During or after your time as a patient of GenesisCare, you may wish to consider accessing certain allied health or wellbeing services or information. GenesisCare (or third parties with whom we work) may offer or provide access to such services or information relevant to you. If you are interested in hearing more about allied health and wellbeing initiatives, please tick here.',
  },
};
