import React, { Fragment } from 'react';
import { DropDownField, FreeTextField, SectionField, SegmentedInput } from 'shared-components/components/FormFields';
import { ListData } from 'shared-components/interfaces';
import './HASharedComponents.scss';

interface FieldProps {
  name: string;
  title: string;
  hint?: string;
}

interface TextFieldProps extends FieldProps {
  onBlur: (e: React.ChangeEvent<HTMLInputElement>) => void;
  required?: boolean;
  defaultValue: string;
  errors?: string[];
}

interface DropDownProps extends FieldProps {
  options: ListData[];
  defaultValue: string;
  onChange: (e: React.ChangeEvent<HTMLSelectElement>) => void;
}

interface RadioProps extends FieldProps {
  options: ListData[];
  errors?: string[];
  defaultValue: boolean;
  onChange: any;
}

export const HAFreeTextField = ({
  name,
  title,
  required = false,
  defaultValue,
  onBlur,
  hint,
  errors,
}: TextFieldProps): JSX.Element => {
  return (
    <SectionField htmlFor={name} title={title} required={required}>
      <FreeTextField
        inputKey={defaultValue}
        inputName={name}
        defaultValue={defaultValue}
        onBlur={onBlur}
        errors={errors}
      />
      {hint && (
        <div data-test-id="body-hint-text" className="example-text">
          {hint}
        </div>
      )}
    </SectionField>
  );
};

export const HADropDownField = ({ name, title, defaultValue, onChange, hint, options }: DropDownProps): JSX.Element => {
  return (
    <SectionField htmlFor={name} title={title}>
      <DropDownField
        inputName={name}
        placeholder={'Please select'}
        options={options}
        defaultValue={defaultValue}
        controlled={true}
        onChange={onChange}
      />
      {hint && (
        <div data-test-id="body-hint-text" className="example-text">
          {hint}
        </div>
      )}
    </SectionField>
  );
};

export const HARadioField = ({ name, title, defaultValue, onChange, options, errors }: RadioProps): JSX.Element => (
  <SectionField htmlFor={name} title={title}>
    <SegmentedInput
      fieldName={name}
      options={options}
      defaultSelected={defaultValue === null ? null : defaultValue === true ? '1' : '2'}
      itemSelected={(selectedItem): void => {
        const selectedValue = (selectedItem as ListData).id === '1';
        // Only trigger save if user selects something different
        if (defaultValue !== selectedValue) {
          onChange(selectedValue);
        }
      }}
      errors={errors}
    />
  </SectionField>
);

// WIP-8738 find right place to keep this component.
export const renderArrow = (): JSX.Element => {
  return (
    <Fragment>
      <div className="line">
        <div className="arrow-down"></div>
      </div>
    </Fragment>
  );
};
