import React, { useState } from 'react';
import { sharedContent } from './constants';
import TextArea from 'shared-components/components/UIFormComponents/TextArea';
import { styled, Button } from '@mui/material';
import { CheckCircleOutlined as CheckCircleOutlinedIcon } from '@mui/icons-material';
import { getRemSize } from 'shared-components/StyledComponents/functions';
import { SUBMIT_FEEDBACK_QUERY } from './NavigationDropdownQueries';
import { useMutation } from '@apollo/client';

const StyledH3 = styled('h3')`
  font-style: normal;
  font-weight: bold;
  font-size: ${getRemSize(18)};
  line-height: 1.55em;
  color: ${(props) => props.theme.palette.text.primary};
`;

const StyledFeedbackFormButtonWrapper = styled('div')`
  display: flex;
  justify-content: space-between;
  background-color: ${(props) => props.theme.palette.grey[100]};
  padding: 8px;

  button {
    font-size: 14px !important;
    width: 148px !important;
    height: 40px !important;
  }
`;

const StyledFeedbackSuccessMessage = styled('div')`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: calc(100% - 40px);
  max-width: 320px;
  padding: 0 20px;

  h3 {
    text-align: center;
    padding: 25px 0 50px;
  }
`;
const StyledFeedbackFormInputWrapper = styled('div')`
  height: calc(100% - 100px);

  textarea {
    border: none;
    height: 100%;
    resize: none;
  }
`;

interface FeedbackFormProps {
  setOpenDropDown: (value: boolean) => void;
  setIsFeedbackFormOpen: (value: boolean) => void;
}

export const FeedbackForm = ({ setOpenDropDown, setIsFeedbackFormOpen }: FeedbackFormProps): JSX.Element => {
  const [submitFeedback] = useMutation(SUBMIT_FEEDBACK_QUERY);
  const [feedbackText, setFeedbackText] = useState('');
  const [feedbackSent, setFeedbackSent] = useState(false);

  const onSubmitFeedback = (): void => {
    submitFeedback({
      variables: {
        message: feedbackText,
        page: location.pathname,
      },
    })
      .then(() => {
        onFeedbackSubmit();
      })
      .catch((error) => {
        onFeedbackSubmit();
      });
  };

  const onFeedbackSubmit = (): void => {
    setFeedbackSent(true);
    setTimeout(function () {
      setOpenDropDown(false);
      setIsFeedbackFormOpen(false);
      setFeedbackSent(false);
      setFeedbackText('');
    }, 5000);
  };
  return (
    <>
      {feedbackSent ? (
        <StyledFeedbackSuccessMessage>
          <CheckCircleOutlinedIcon color="primary" />
          <StyledH3>{sharedContent.feedbackForm.submissionSuccess}</StyledH3>
          <Button
            id="closeFeedbackSuccessMessage"
            key={sharedContent.feedbackForm.closeButton}
            title={sharedContent.feedbackForm.closeButton}
            onClick={(): void => {
              setOpenDropDown(false);
              setIsFeedbackFormOpen(false);
              setFeedbackSent(false);
              setFeedbackText('');
            }}
            variant="contained"
          />
        </StyledFeedbackSuccessMessage>
      ) : (
        <>
          <StyledFeedbackFormInputWrapper>
            <TextArea
              key={'FeedbackTextArea'}
              placeholder={sharedContent.feedbackForm.placeholder}
              value={feedbackText}
              onBlur={() => {}}
              onChange={(e: any) => {
                setFeedbackText(e.target.value);
              }}
              name={'FeedbackFormTextArea'}
            />
          </StyledFeedbackFormInputWrapper>

          <StyledFeedbackFormButtonWrapper>
            <Button
              id="FeedbackBackButton"
              key="Back"
              onClick={(): void => {
                setIsFeedbackFormOpen(false);
              }}>
              Back
            </Button>
            <Button
              id="SubmitFeedbackButton"
              key="Submit"
              onClick={(): void => {
                onSubmitFeedback();
              }}
              disabled={feedbackText.length < 1}
              variant={feedbackText.length >= 1 ? 'contained' : 'text'}>
              Submit
            </Button>
          </StyledFeedbackFormButtonWrapper>
        </>
      )}
    </>
  );
};
