// eslint-disable-next-line no-use-before-define
import React, { useContext } from 'react';
import classNames from 'classnames';
import { Stack, useTheme, CustomTheme } from '@mui/material';

import { UserContext } from 'op-contexts';

import './ROSideNav.scss';

interface Props {
  children: React.ReactNode;
}

const ROSideNav = ({ children }: Props): JSX.Element => {
  const { state } = useContext(UserContext);
  const theme = useTheme<CustomTheme>();

  return (
    <div
      className={classNames('ro-side-nav', {
        active: state.navShow,
      })}>
      <Stack
        sx={{ backgroundColor: theme.palette.background.secondary }}
        id="profile-summary-side"
        className="ro-side-nav-wrapper">
        {children}
      </Stack>
    </div>
  );
};

export default ROSideNav;
