import { useContext } from 'react';
import OverviewCard from './OverviewCard';
import OverviewCardDonut from './OverviewCardDonut';
import {
  aggregateAdherencePercentage,
  aggregateAverageDailyRecords,
  aggregateAverageDailyTreatmentsPerLinac,
  aggregateAverageFractions,
  aggregateAverageSimToTreat,
  aggregateTechniquePercentage,
  aggregateTotalPlans,
  aggregateTotalReferrals,
  aggregateData,
  calcAdherencePercentage,
  calcAverageDailyPlans,
  calcAverageFractions,
  calcAverageSimToTreat,
  calcComplexTechnique,
  calcTotalDiagnosis,
  calcTotalPlans,
  calcTotalReferrals,
  calcAverageDailyTreatmentsPerLinac,
} from '../Utils/dataFunctions';
import DashboardHeader from './DashboardHeader';
import { LoadingSpinner } from 'shared-components/components';
import { DashboardContext } from '../DashboardContext';
import { Typography } from '@mui/material';
import { useTheme } from '@mui/material/styles';

const DashboardOverview = () => {
  const { isLoading } = useContext(DashboardContext);
  const theme = useTheme();

  return (
    <div className="dashboard-overview-container">
      <DashboardHeader title="Insights Dashboard" />
      {isLoading ? (
        <LoadingSpinner loadingText={''} />
      ) : (
        <div className="overview-card-container">
          <Typography className="overview-title-separator" color={theme.palette.text.secondary}>
            Quality
          </Typography>
          <div className="overview-card-row-container">
            <OverviewCard
              title="Careplan adherence"
              aggregateFunction={aggregateAdherencePercentage}
              metricFunction={calcAdherencePercentage}
              page="carepathway"
              metricSuffix="%"
            />
            <OverviewCard
              title="IMRT/VMAT/Stereotactic plans"
              aggregateFunction={aggregateTechniquePercentage}
              metricFunction={calcComplexTechnique}
              page="techniques"
              metricSuffix="%"
            />
            <OverviewCard
              title="Average fractions"
              aggregateFunction={aggregateAverageFractions}
              metricFunction={calcAverageFractions}
              page="fractions"
              metricRound={1}
            />
          </div>
          <Typography className="overview-title-separator" color={theme.palette.text.secondary}>
            Access
          </Typography>
          <div className="overview-card-row-container">
            <OverviewCard
              title="Total referrals"
              aggregateFunction={aggregateTotalReferrals}
              metricFunction={calcTotalReferrals}
              page="referrers"
            />
            <OverviewCardDonut
              title="Total diagnosis"
              aggregateFunction={aggregateData}
              metricFunction={calcTotalDiagnosis}
              page="diagnosis"
            />
            <OverviewCard
              title="Total plans"
              aggregateFunction={aggregateTotalPlans}
              metricFunction={calcTotalPlans}
              page="activity"
            />
          </div>
          <Typography className="overview-title-separator" color={theme.palette.text.secondary}>
            Efficiency
          </Typography>
          <div className="overview-card-row-container">
            <OverviewCard
              title="Average daily treatments per Linac"
              aggregateFunction={aggregateAverageDailyTreatmentsPerLinac}
              metricFunction={calcAverageDailyTreatmentsPerLinac}
              page="machines"
              metricRound={1}
              targetType="track"
            />
            <OverviewCard
              title="Average sim to treatment time (Days)"
              aggregateFunction={aggregateAverageSimToTreat}
              metricFunction={calcAverageSimToTreat}
              page="wait-times"
              metricRound={1}
              targetType="flat"
            />
            <OverviewCard
              title="Average daily plans"
              aggregateFunction={aggregateAverageDailyRecords}
              metricFunction={calcAverageDailyPlans}
              page="productivity"
              metricRound={1}
            />
          </div>
        </div>
      )}
    </div>
  );
};

export default DashboardOverview;
