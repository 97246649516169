import React, { Component, ComponentClass, Fragment } from 'react';
import { gql } from '@apollo/client';

import { Logger } from 'shared-components/utils';

import { GraphQLHelper } from 'op-utils';
import { DocumentNode } from 'graphql';

const logger = new Logger('DistressThermometer');

export interface WithDistressThermometer {
  getDTMutation?: (updateObject: object, replaceId?: string) => object;
}

function withDistressThermometer<Props>(WrappedComponent: React.ComponentType<Props>): ComponentClass<Props> {
  return class extends Component<Props> {
    public render(): JSX.Element {
      return (
        <Fragment>
          <WrappedComponent
            getDTMutation={(updateObject: object, replaceId?: string) => {
              return this.getDistressThermometerMutation(updateObject, replaceId);
            }}
            {...this.props}
          />
        </Fragment>
      );
    }

    /**
     * Get the GQL variables that will be ued to call the mutation.
     * @param {object} updateObject The object that contains the keys and values to be updated.
     */
    private getGQLVariables = (dtId: string, updateObject: object, addTypeName?: boolean): object => {
      // Always need an id for the mutation.
      const variables: { [key: string]: any } = {
        id: dtId,
      };

      for (const updateEntry of Object.entries(updateObject)) {
        const [key, value] = updateEntry;

        // If the value is an object, need to do a deep copy
        if (typeof value === 'object') {
          variables[key] = { ...value };
        } else {
          variables[key] = value;
        }

        // Currently the mutation doesn't need a typename since there is no need for object in object, however when this is required, follow RegistrationForm.tsx to add this in.
        if (addTypeName && key === 'answers') {
          variables[key].__typename = 'DTCheckboxAnswerMutationType';
        }
      }
      logger.debug('getGQLVariables', JSON.stringify(variables));
      return variables;
    };

    /**
     * Function that is used to build the mutation string for distress thermometer. It will accept an object to build the required string.
     * @param {object} updateObject The update object that will build the graphql mutation string
     */
    private getGQLUpdateDTMutationString = (updateObject: object, replaceId?: string): DocumentNode => {
      const mutationParams = GraphQLHelper.mapObjectToMutationParams(updateObject);
      const functionParams = GraphQLHelper.mapObjectToFunctionParams(updateObject);
      const functionValues = GraphQLHelper.flattenObjectToQueryString(updateObject);

      let graphMutation = `mutation UpdateDistressThermometer(${mutationParams}) {
        updateDistressThermometer(${functionParams}) {
          distressThermometer ${functionValues}
        }
      }`;

      if (replaceId) {
        graphMutation = graphMutation.replace(/#replaceId/g, `"${replaceId}"`);
      }

      logger.debug('getGQLUpdateDTMutationString', graphMutation);
      return gql(graphMutation);
    };

    private getDistressThermometerMutation = (updateObject: object, replaceId?: string): object => {
      // Check to make sure that the update object has a key defined as id, and if it doesn't display a warning in console for developers.
      // @ts-ignore
      const updateObjectId = updateObject.id;
      if (updateObjectId === null || updateObjectId === undefined || updateObjectId === '') {
        logger.debug(
          'getDistressThermometerMutation',
          '**** MISSING THE ID FROM THE UPDATE OBJECT ****',
          JSON.stringify(updateObject),
        );
      }

      const variables = this.getGQLVariables(updateObjectId, updateObject, true);
      const dtResponse = { ...variables, __typename: 'DistressThermometerType' };

      return {
        mutation: this.getGQLUpdateDTMutationString(updateObject, replaceId),
        variables: this.getGQLVariables(updateObjectId, updateObject),
        optimisticResponse: {
          updateDistressThermometer: {
            distressThermometer: dtResponse,
            errors: null,
            __typename: 'UpdateDistressThermometer',
          },
        },
      };
    };
  };
}

export default withDistressThermometer;
