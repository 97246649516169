// eslint-disable-next-line no-use-before-define
import React from 'react';
import { useLocation } from 'react-router-dom';
import { STATIC_CONTENT } from './staticContent';

import FAQContent from './FAQContent';

import { FAQ_CONSTANTS } from '../faqConstants';

const FAQContentWrapper = (): JSX.Element => {
  const location = useLocation();
  const splitPath = location.pathname.split('/');
  const category = splitPath[splitPath.length - 1];

  const data = STATIC_CONTENT.find((obj) => obj.category_id == category);

  return <>{data && <FAQContent data={data} pageCategory={category as keyof typeof FAQ_CONSTANTS} />}</>;
};

export default FAQContentWrapper;
