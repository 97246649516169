import { FormikValues, validateYupSchema, yupToFormErrors } from 'formik';
import * as Yup from 'yup';
import { VALUE_REQUIRED, GENDER_DIFFERENT_TERM_VALIDATION, CHAR_ONLY } from '../Helper';
import { GENDER_IDENTIFICATION_DESCRIBE_REGEX } from 'shared-components/utils/Regex';

export const generateValidationSchema = (values: FormikValues): any => {
  const registrationBasicSchema = {
    firstName: Yup.string().ensure().required(VALUE_REQUIRED),
    lastName: Yup.string().ensure().required(VALUE_REQUIRED),
    gender: Yup.string().ensure().required(VALUE_REQUIRED),
    genderIdentification: Yup.string().ensure().required(VALUE_REQUIRED),
    genderIdentificationOther: Yup.string()
      .matches(GENDER_IDENTIFICATION_DESCRIBE_REGEX, CHAR_ONLY)
      .max(25, GENDER_DIFFERENT_TERM_VALIDATION)
      .nullable(),
    dob: Yup.string().ensure().required(VALUE_REQUIRED),
  };

  try {
    validateYupSchema<FormikValues>(values, Yup.object(registrationBasicSchema), true);
  } catch (err) {
    return yupToFormErrors(err);
  }

  return {};
};
