import React, { useState, Fragment } from 'react';

import classNames from 'classnames';
import { TnmStageType, DiagnosisTypeWithoutNulls } from './Interface';
import { convertDiagnosisTypeToWithoutNulls } from './Utils';
import { MetastasisIcon } from 'shared-components/images/ro_portal';
import { CheckCircle, Adjust, Link } from '@mui/icons-material';
import './CurrentCode.scss';
import moment from 'moment';
import { Collapse, useTheme, Typography } from '@mui/material';
import { KeyboardArrowDown, KeyboardArrowUp } from '@mui/icons-material';

const getTNMSubCode = (stage: TnmStageType | null): string => {
  return stage ? stage.subCode : '';
};

const renderPrimaryCode = (diagnosis: DiagnosisTypeWithoutNulls): JSX.Element => {
  const theme = useTheme();
  return (
    <div id="diagnosis-current-code" className="diagnosis-code-body">
      <Typography data-testid="diagnosis-code-clinical" variant="subtitle2" className="diagnosis-item-body-type-title">
        Clinical
      </Typography>
      <div
        className="item-body"
        style={{ paddingTop: '0px', marginBottom: '8px', paddingBottom: '0px', maxHeight: '40px' }}>
        <div className="item-stages-container">
          <div className="item-stage-container">
            <div className="item-stage">
              <div className="item-stage-title">{'T Stage'}</div>
              <div className="item-stage-name">{getTNMSubCode(diagnosis.stageTCodeClinical) || '-'}</div>
            </div>
          </div>
          <div className="item-stage-container">
            <div className="item-stage">
              <div className="item-stage-title">{'N Stage'}</div>
              <div className="item-stage-name">{getTNMSubCode(diagnosis.stageNCodeClinical) || '-'}</div>
            </div>
          </div>
          <div className="item-stage-container">
            <div className="item-stage">
              <div className="item-stage-title">{'M Stage'}</div>
              <div className="item-stage-name">{getTNMSubCode(diagnosis.stageMCodeClinical) || '-'}</div>
            </div>
          </div>
        </div>
      </div>
      <Typography
        data-testid="diagnosis-code-pathological"
        variant="subtitle2"
        className="diagnosis-item-body-type-title">
        Pathological
      </Typography>
      <div
        className="item-body"
        style={{ paddingTop: '0px', marginBottom: '8px', paddingBottom: '0px', maxHeight: '40px' }}>
        <div className="item-stages-container">
          <div className="item-stage-container">
            <div className="item-stage">
              <div className="item-stage-title">{'T Stage'}</div>
              <div className="item-stage-name">{getTNMSubCode(diagnosis.stageTCodePathological) || '-'}</div>
            </div>
          </div>
          <div className="item-stage-container">
            <div className="item-stage">
              <div className="item-stage-title">{'N Stage'}</div>
              <div className="item-stage-name">{getTNMSubCode(diagnosis.stageNCodePathological) || '-'}</div>
            </div>
          </div>
          <div className="item-stage-container">
            <div className="item-stage">
              <div className="item-stage-title">{'M Stage'}</div>
              <div className="item-stage-name">{getTNMSubCode(diagnosis.stageMCodePathological) || '-'}</div>
            </div>
          </div>
        </div>
      </div>
      <div className="item-footer">
        <div className="item-detail-row">
          <div className="item-detail-col diagnosis-date">
            <Typography variant="subtitle2" color={theme.palette.text.secondary} className="item-detail-title">
              {'Diagnosis Date'}
            </Typography>
            <Typography variant="body2" className="item-detail">
              {diagnosis.diagnosisDate ? moment(diagnosis.diagnosisDate).format('L') : '-'}
            </Typography>
          </div>
        </div>
        <div className="item-detail-row morphology">
          <Typography variant="subtitle2" color={theme.palette.text.secondary} className="item-detail-title">
            {'Morphology'}
          </Typography>
          <Typography variant="body2" className="item-detail morphology-wrapper">
            {diagnosis.morphology.displayName || '-'}
          </Typography>
        </div>
      </div>
    </div>
  );
};

const renderMetastasisCode = (diagnosis: DiagnosisTypeWithoutNulls): JSX.Element => {
  const relatedPrimaryDiagnosis = diagnosis.relatedPrimaryDiagnosis;
  const relatedDiagnosisCode = relatedPrimaryDiagnosis && relatedPrimaryDiagnosis.diagnosisCode;
  const theme = useTheme();
  return (
    <div id="diagnosis-current-code" className="diagnosis-code-body">
      <div style={{ background: theme.palette.grey[300] }} className="metastasis-link">
        <Link color="secondary" />
        <div>
          {relatedDiagnosisCode
            ? `Related to ${relatedDiagnosisCode.diagnosisCode} - Primary`
            : 'Not related to a primary diagnosis'}
        </div>
      </div>
      <div className="item-footer">
        <div className="item-detail-row">
          <div className="item-detail-col diagnosis-date">
            <div className="item-detail-title">{'Diagnosis Date'}</div>
            <div className="item-detail">
              {diagnosis.diagnosisDate ? moment(diagnosis.diagnosisDate).format('L') : '-'}
            </div>
          </div>
        </div>
        <div className="item-detail-row related-primary-diagnosis">
          <div className="item-detail-title">{'Related Primary Diagnosis'}</div>
          <div className="item-detail">
            {relatedDiagnosisCode
              ? `${relatedDiagnosisCode.diagnosisCode} ${relatedDiagnosisCode.tumourStream.name} - ${relatedDiagnosisCode.diagnosisDescription}`
              : '-'}
          </div>
        </div>
      </div>
    </div>
  );
};

const ROPatientDiagnosisCurrentCode = (props: any): JSX.Element => {
  const [openInfo, setOpenInfo] = useState(false);
  const theme = useTheme();
  const { open, showTitle, showExpandButton, active, onClick, showPrimary, diagnosisCodeSet } = props;
  if (!props.diagnosisCodeSet) return <Fragment />;
  const diagnosisWithoutNulls = convertDiagnosisTypeToWithoutNulls(props.diagnosisCodeSet);
  const showType = typeof showPrimary === 'undefined' ? diagnosisWithoutNulls.isPrimaryDiagnosis : showPrimary;
  const diagnosisCode = diagnosisWithoutNulls.diagnosisCode;
  const { isPrimaryDiagnosis } = diagnosisCodeSet;

  return (
    <div
      className={classNames('ro-careplan-tweaks', 'diagnosis-code-container', {
        primary: isPrimaryDiagnosis,
        metastasis: !isPrimaryDiagnosis,
      })}
      style={{
        cursor: 'pointer',
        border: active ? `3px solid ${theme.palette.primary.main}` : 'none',
        paddingBottom: '8px',
      }}
      data-test-id="existing-diagnosis"
      onClick={(): void => {
        if (onClick) {
          onClick();
        }
      }}>
      <div
        style={{
          borderTopColor: showType ? theme.palette.primary.main : theme.palette.warning.main,
          margin: '0 -2px',
          borderTopWidth: '4px',
        }}
        className={classNames('diagnosis-code-header', {
          primary: showType,
          'is-expand': openInfo,
        })}>
        {showTitle && (
          <div className="diagnosis-code-title">
            {active ? (
              <CheckCircle sx={{ fontSize: '30px' }} color="primary" />
            ) : showType ? (
              <Adjust sx={{ color: theme.palette.grey[600] }} />
            ) : (
              <MetastasisIcon />
            )}
            <Typography
              variant="subtitle1"
              style={{ color: theme.palette.text.primary }}
              className="diagnosis-code-header-text">
              {showType ? 'Primary diagnosis' : 'Metastasis diagnosis'}
            </Typography>
          </div>
        )}
        <div className="diagnosis-current-item">
          <span>
            {diagnosisCode && `${diagnosisCode.diagnosisCode} `}
            {(diagnosisCode && diagnosisCode.tumourStream.name) || '-'} :{' '}
          </span>
          <span>{(diagnosisCode && diagnosisCode.diagnosisDescription) || '-'}</span>
          <span>{diagnosisWithoutNulls.laterality ? ` (${diagnosisWithoutNulls.laterality})` : ''}</span>
        </div>
        {showExpandButton && (
          <button
            onClick={(): void => {
              setOpenInfo(!openInfo);
            }}
            className="diag-sidebar-collapse-button"
            aria-controls="diagnosis-current-code"
            aria-expanded={open || openInfo}
            style={{ border: 0, background: 'unset', cursor: 'pointer' }}>
            {open || openInfo ? (
              <KeyboardArrowUp fontSize="large" color="primary" sx={{ paddingTop: '12px', marginBotom: '-8px' }} />
            ) : (
              <KeyboardArrowDown fontSize="large" color="primary" sx={{ paddingTop: '12px', marginBotom: '-8px' }} />
            )}
          </button>
        )}
      </div>
      <Collapse in={open || openInfo}>
        {showType ? renderPrimaryCode(diagnosisWithoutNulls) : renderMetastasisCode(diagnosisWithoutNulls)}
      </Collapse>
    </div>
  );
};

export default ROPatientDiagnosisCurrentCode;
