import React, { Fragment, useContext } from 'react';
import { styled } from '@mui/system';
import { Link, generatePath, useRouteMatch, useLocation } from 'react-router-dom';
import { FileContext } from 'op-contexts';
import { LEAVE_PAGE_WARNING } from 'op-components/DocumentUpload/constants';
import { StyledTitle, StyledArrow } from './common';
import PatientEMR from 'op-components/SideNav/PatientEMR';
import { Stack } from '@mui/material';
import { useTheme } from '@mui/material';
import { PollOutlined as PollOutlinedIcon } from '@mui/icons-material';

interface Props {
  refreshChartSummary?: any;
}

interface PatientNavOptionType {
  icon: any;
  label: string;
  linkTo: string;
  id: string;
}

interface PSOPatientDashboardNavType {
  id: string;
}

interface PatientNavLinks {
  option: PatientNavOptionType;
  key: string;
}

interface StyledProps {
  $borderLeft: string;
  $backgroundColor: string;
}

const menuOptions: PatientNavOptionType[] = [
  {
    icon: <PollOutlinedIcon color="primary" />,
    label: 'Patient summary',
    linkTo: '/navigator/patient/:id/summary',
    id: 'summary',
  },
];

const StyledLink = styled(Link)`
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 16px 16px 16px 16px;
  text-decoration: none;
  height: 53px;
  transition: border-color 0.15s ease-in-out;
  margin-bottom: 3px;
  &:focus,
  &:hover,
  &:visited,
  &:link,
  &:active {
    text-decoration: none;
  }
`;

const StyledRow = styled('div')<StyledProps>`
  border-left: ${({ $borderLeft }: StyledProps): string => $borderLeft};
  width: 100%;
  padding: 8px;
  background-color: ${({ $backgroundColor }: StyledProps): string => $backgroundColor};
`;

const StyledNavLabel = styled('span')`
  color: black;
  font-size: 14px;
  text-decoration: none;
  font-weight: bold;
  margin-left: 5px;
`;

const StyledNavLink = styled(Link)`
  display: flex;
  align-items: center;
  text-decoration: none;
`;

const PSOSidePatientNav = ({ refreshChartSummary }: Props): JSX.Element => {
  const { activeFiles } = useContext(FileContext);
  const match = useRouteMatch<PSOPatientDashboardNavType>();
  const { id: patientId } = match.params;

  const BackToDashboard = (): JSX.Element => {
    const url = generatePath('/search');
    return (
      <StyledLink to={url} data-test-id="back-to-search-button">
        <StyledArrow />
        <StyledTitle>Back to search</StyledTitle>
      </StyledLink>
    );
  };

  const handleOnClick = (e: any): void => {
    if (activeFiles.length > 0) {
      e.preventDefault();
      const res = window.confirm(LEAVE_PAGE_WARNING);
      if (res) {
        refreshChartSummary();
      }
    } else {
      refreshChartSummary();
    }
  };

  const PatientNavLinks = (props: PatientNavLinks): JSX.Element => {
    const { linkTo, icon, label, id } = props.option;
    const location = useLocation();
    const splitPath = location.pathname.split('/');
    const isActive = splitPath.includes(id.toLowerCase());
    const url = generatePath(linkTo, {
      id: patientId,
    });
    const theme = useTheme();

    const styledProps = {
      $borderLeft: isActive ? `5px solid ${(props: any) => props.theme.palette.primary.main}` : '5px solid transparent',
      $backgroundColor: isActive ? theme.palette.grey[300] : theme.palette.grey[100],
    };

    return (
      <StyledRow {...styledProps}>
        <StyledNavLink to={url} onClick={(e) => handleOnClick(e)} data-test-id="nav-links-container">
          {icon}
          <StyledNavLabel>{label}</StyledNavLabel>
        </StyledNavLink>
      </StyledRow>
    );
  };

  return (
    <Fragment>
      <Stack>
        <PatientEMR patientId={patientId} />
        <BackToDashboard />
        <Stack className="ro-side-patient-nav pso" style={{ width: '100%' }}>
          {menuOptions.map(
            (option: PatientNavOptionType): JSX.Element => (
              <PatientNavLinks option={option} key={option.label} />
            ),
          )}
        </Stack>
      </Stack>
    </Fragment>
  );
};

export default PSOSidePatientNav;
