// eslint-disable-next-line no-use-before-define
import React, { Fragment, useEffect, useContext } from 'react';
import { useQuery } from '@apollo/client';
import { useLocation } from 'react-router-dom';
import { gql } from '@apollo/client';
import { ReviewFormsTable } from 'op-components';
import { CONSTANTS } from '../../../components/OP/ReviewForms/utils';
import { LoadingSpinner } from 'shared-components/components';
import { useErrorModalContext, NavigationContext } from 'op-contexts';
import { FeatureOption } from 'op-interfaces';

const FORMS_IN_REVIEW_QUERY = gql`
  query PatientSearch($start: Int, $limit: Int, $location: String) {
    reviewRequiredPatients(start: $start, limit: $limit, location: $location) {
      totalCount
      forms {
        id
        firstName
        lastName
        patientId
        ida
        horizonCenterId
        status
        overallStatus
        formType
        updatedAt
        hasDataConflicts
      }
    }
  }
`;

const ALLOWED_LOCATION_QUERY = gql`
  query AllowedLocations($roles: [String]!, $hasOther: Boolean!) {
    allowedLocations(roles: $roles, hasOther: $hasOther) {
      id
      alias
    }
    featureOptions {
      id
      name
      description
      active
    }
  }
`;

const ReviewFormsApollo = (): JSX.Element => {
  const location = useLocation();
  const { setError } = useErrorModalContext();
  const { data: locations, error: locationsError } = useQuery(ALLOWED_LOCATION_QUERY, {
    variables: {
      roles: ['PSO'],
      hasOther: true,
    },
  });
  const {
    loading,
    data: formData,
    refetch,
    error: formsError,
  } = useQuery(FORMS_IN_REVIEW_QUERY, {
    variables: {
      start: 0,
      limit: CONSTANTS.DEFAULT_PAGINATION_LIMIT,
    },
  });

  const navigationContext = useContext(NavigationContext);
  const featureOptions = locations?.featureOptions || [];

  const showNewRego = featureOptions.find(
    (featureOption: FeatureOption) => featureOption.name === 'NewAusRego' && featureOption.active,
  );

  useEffect(() => {
    if (locationsError || formsError) return setError();
  }, [locationsError, formsError]);

  useEffect(() => {
    navigationContext.setRegEntryPath(location.pathname);
  }, [location.pathname]);

  return (
    <Fragment>
      {formData && locations && (
        <ReviewFormsTable
          forms={formData.reviewRequiredPatients.forms}
          totalCount={formData.reviewRequiredPatients.totalCount}
          allowedLocations={locations.allowedLocations}
          loading={loading}
          getFormsInReview={refetch}
          showNewRego={showNewRego}
        />
      )}
      {!formData && loading && <LoadingSpinner loadingText={'Loading forms'} />}
    </Fragment>
  );
};

export default ReviewFormsApollo;
