import { useQuery } from '@apollo/client';
import moment from 'moment';
import { useErrorModalContext } from 'op-contexts';
import React, { Fragment, memo, useCallback, useEffect, useState } from 'react';
import { useParams } from 'react-router';
import { LoadingSpinner } from 'shared-components/components';
import { supportedSize } from 'shared-components/StyledComponents/breakpoints';
import { styled } from '@mui/system';
import { ALL_REFERRAL_EXISTING_SERVICE_QUERY, SUBMITTED_MANAGEMENT_PLAN_QUERY } from '../graphql/ManagementPlanQueries';
import { GET_DISTRESS_THERMOMETER_PDF } from 'op-pages/RO/PatientSummary/PatientProfileQueries';
import { Stack, useTheme, Divider } from '@mui/material';

interface Props {
  blur?: boolean;
  showDT: boolean;
  activeTabOption?: string;
}

interface StyledProps {
  $margin: string;
  $borderRadius: string;
  $boxShadow: string;
}

interface StyledTabProps {
  $borderBottom: string;
  $color: string;
}

interface TabOptionType {
  label: string;
  id: string;
}

interface TabLinks {
  option: TabOptionType;
  key: string;
}

interface ReferralType {
  name: string;
  serviceType: string;
  description: string;
  contactDetails: string;
  submittedAt: string;
}

interface ListItemProps {
  item: {
    title: string;
    content: string;
    fieldName: string;
  };
}

const tabOptions: TabOptionType[] = [
  {
    label: 'DISTRESS THERMOMETER',
    id: 'distress',
  },
  {
    label: 'ACTIVITY LOG',
    id: 'activity',
  },
  {
    label: 'REFERRAL',
    id: 'referral',
  },
];

const StyledDivContainer = styled('div')<StyledProps>`
  @media screen and (min-width: ${supportedSize.desktop}) {
    width: 580px;
  }

  height: 100%;
  background-color: white;
  margin: ${({ $margin }: StyledProps): string => $margin};
  box-shadow: ${({ $boxShadow }: StyledProps): string => $boxShadow};
  border-radius: ${({ $borderRadius }: StyledProps): string => $borderRadius};
  display: flex;
  flex-direction: column;
`;

const TabLinksWrapper = styled('div')`
  height: 48px;
  border-bottom: 1px solid ${(props) => props.theme.palette.grey[300]};
  display: flex;
  justify-content: start;
  padding-left: 16px;
  padding-top: 8px;
  gap: 8px;
`;

const StyledTab = styled('div')<StyledTabProps>`
  border-bottom: ${({ $borderBottom }: StyledTabProps): string => $borderBottom};
  width: auto;
  whitespace: nowrap;
  color: ${({ $color }: StyledTabProps): string => $color};
  font-size: 13px;
  font-weight: bold;
  cursor: pointer;
  margin-right: 16px;
  padding-top: 16px;
`;

const ContentWrapper = styled('div')`
  height: 100%;
  overflow: auto;

  tr:hover {
    background-color: unset !important;
    border-radius: unset !important;
    cursor: unset !important;
  }
`;

const HeadingWrapper = styled('div')`
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

const Timepoint = styled('div')`
  color: ${(props) => props.theme.palette.text.primary};
  font-size: 16px;
  font-weight: bold;
  white-space: nowrap;
  padding: 0.5em;
`;

const StyledDate = styled('div')`
  font-size: 14px;
  text-align: right;
  padding: 0.5em;
`;

const Tag = styled('div')`
  background-color: ${(props) => props.theme.palette.grey[100]};
  border-radius: 16px;
  max-width: 110px;
  font-size: 14px;
  text-align: center;
  padding: 0.5em;
`;

const ListTitle = styled('div')`
  font-weight: bold;
  margin-bottom: 7px;
`;

const TableRow = styled('tr')`
  line-height: 1.5em;
`;

const ManagementPlanSidePanel = ({ showDT, activeTabOption, blur }: Props): JSX.Element => {
  const { setError } = useErrorModalContext();
  const { patientId } = useParams<{ patientId: string }>();
  let tabs = showDT ? tabOptions : tabOptions.filter((tab) => tab.id !== 'distress');
  const [activeTab, setActiveTab] = useState(activeTabOption ? activeTabOption : tabs[0].id);
  const styledProps = {
    $margin: blur ? '0px' : '8px 0px 0px 8px',
    $boxShadow: blur ? '-2px 0px 2px rgba(113, 110, 106, 0.4)' : '0px 2px 8px rgba(113, 110, 106, 0.4)',
    $borderRadius: blur ? '0px' : '4px',
  };
  const theme = useTheme();

  const {
    loading: plansLoading,
    data: plansData,
    error: plansError,
  } = useQuery(SUBMITTED_MANAGEMENT_PLAN_QUERY, {
    variables: { patientId },
  });

  const {
    loading: referralsLoading,
    data: referralsData,
    error: referralsError,
  } = useQuery(ALL_REFERRAL_EXISTING_SERVICE_QUERY, {
    variables: { patientId },
  });

  const {
    loading: pdfLoading,
    data: pdfData,
    error: pdfError,
  } = useQuery(GET_DISTRESS_THERMOMETER_PDF, {
    variables: { patientId },
  });

  useEffect(() => {
    if (plansError || referralsError || pdfError) return setError();
  }, [plansError, referralsError, pdfError]);

  useEffect(() => {
    tabs = showDT ? tabOptions : tabOptions.filter((tab) => tab.id !== 'distress');
    setActiveTab(activeTabOption ? activeTabOption : tabs[0].id);
  }, [showDT]);

  const submittedPlans = plansData?.submittedManagementPlan;
  const submittedReferrals = referralsData?.allReferralExistingServices?.map((allRef: any) => allRef?.referral).flat();
  const isDataLoading = plansLoading || referralsLoading || pdfLoading;

  const TabLinks = (props: TabLinks): JSX.Element => {
    const componentClass = 'tab-link';
    const { label, id } = props.option;
    const styledTabProps = {
      $borderBottom: activeTab === id ? `5px solid ${theme.palette.primary.main}` : '5px solid transparent',
      $color: activeTab === id ? `${theme.palette.text.primary}` : `${theme.palette.grey[600]}`,
    };
    return (
      <StyledTab
        id={`${componentClass}-${id}`}
        data-test-id={`${componentClass}-${id}`}
        className={componentClass}
        style={{ padding: '4px', alignContent: 'center' }}
        {...styledTabProps}
        onClick={() => setActiveTab(id)}>
        {label}
      </StyledTab>
    );
  };

  const DistressThermometer = useCallback((): JSX.Element => {
    // Find most recently submitted distress thermometer pdf
    const dtPdfUrl = pdfData?.dtPdf[0]?.docUrl;
    return dtPdfUrl ? (
      <iframe
        title="patientDistressThermometer"
        src={dtPdfUrl.concat('#view=fitH')} // Add view parameter so PDF defaults to fit to width
        width="100%"
        height="100%"
        style={{ flex: 1 }}
        onError={(): void => {
          setError();
        }}>
        This browser does not support PDFs.
      </iframe>
    ) : (
      <span>Unable to locate Distress Thermometer PDF in Mosaiq</span>
    );
  }, [pdfData]);

  const ListItem = ({ item }: ListItemProps): JSX.Element => {
    if (item.title.includes('Distress thermometer')) {
      return (
        <li style={{ listStyleType: 'disc', marginBottom: '10px' }} data-test-id={`item-${item.fieldName}`}>
          <ListTitle>{item.title}</ListTitle>
        </li>
      );
    }
    return item.content && item.title ? (
      <li style={{ listStyleType: 'disc', marginBottom: '10px' }}>
        <ListTitle>{item.title}</ListTitle>
        <div className="py-1" data-test-id={`item-${item.fieldName}`}>
          {item.content}
        </div>
      </li>
    ) : (
      <span />
    );
  };

  const DistressScoreLog = ({ sections, index }: any): JSX.Element => {
    const distressScore = getSection(sections, 'distress_score');
    const checkInStatus = getSection(sections, 'check_in_status');
    const checkInNotes = getSection(sections, 'check_in_notes');

    return (
      <>
        {distressScore.content && (
          <li style={{ listStyleType: 'disc' }} key={index + 'distress_score'}>
            <div style={{ display: 'flex', width: '25%', marginBottom: '8px' }} data-test-id="item-distress-score">
              <ListTitle>{distressScore.title}</ListTitle>
              {distressScore.content}
            </div>
          </li>
        )}
        {checkInStatus.content && (
          <li
            style={{ listStyleType: 'disc', marginBottom: '10px', marginLeft: '24px' }}
            key={index + 'check_in_status'}
            data-test-id="item-check-in-status">
            {checkInStatus.content}
          </li>
        )}
        {checkInNotes.content && (
          <li
            style={{ listStyleType: 'disc', marginBottom: '10px', marginLeft: '24px' }}
            key={index + 'check_in_notes'}
            data-test-id="item-check-in-notes">
            {checkInNotes.content}
          </li>
        )}
      </>
    );
  };

  const ReferralCountCircle = ({ count }: any): JSX.Element => {
    return (
      <span style={{ paddingLeft: '4px', paddingRight: '4px' }}>
        <svg className="referral-circle" height="20" width="20">
          <circle cx="10" cy="10" r="10" fill={theme.palette.grey[600]} />
          <text fontSize="14px" x="30%" y="70%" fill="white" data-test-id="referral-count">
            {count}
          </text>
        </svg>
      </span>
    );
  };

  const getSection = (sections: any, fieldName: string) => {
    const section = sections?.filter((section: any) => section.field === fieldName)[0];
    return { title: section.title, content: section.content, fieldName: fieldName };
  };

  const ActivityLog = (): JSX.Element => {
    if (!submittedPlans || !submittedPlans.length)
      return <Stack sx={{ padding: '8px' }}>No submission has been made.</Stack>;
    return (
      <Stack sx={{ padding: '8px', maxWidth: '700px' }}>
        {submittedPlans.map((plan: any, index: any): JSX.Element => {
          const sections = plan.activityLog.sections;
          const goalsOfCare = plan.activityLog.goalOfCare;
          return (
            <Fragment key={`activity-log-${index}`}>
              {index !== 0 && <Divider style={{ margin: '8px 4px' }} />}
              <Stack key={`activity-log-${index}`}>
                <HeadingWrapper style={{ marginBottom: '8px' }}>
                  <Timepoint data-test-id="timepoint">{plan.timepoint}</Timepoint>
                  {plan.archivedAt && (
                    <div>
                      <Tag>{'Archived'}</Tag>
                    </div>
                  )}
                  <StyledDate>{moment(plan.updatedAt).format('DD/MM/YYYY HH:mm A')}</StyledDate>
                </HeadingWrapper>
                <ul style={{ marginLeft: '16px' }}>
                  <ListItem item={getSection(sections, 'reviewed_dt')} key={`${index} 'reviewed_dt'`} />{' '}
                  <DistressScoreLog sections={sections} index={index} />
                  <ListItem
                    item={getSection(sections, 'patient_concerns_needs')}
                    key={`${index} patient_concerns_needs`}
                  />
                  {goalsOfCare.map(
                    (goal: any, goalIndex: any): JSX.Element => (
                      <ListItem
                        item={{
                          title: `Goal of care #${goalIndex + 1}:`,
                          content: goal.goal,
                          fieldName: 'goalOfCare',
                        }}
                        key={`${index} ${goalIndex} goalOfCare`}
                      />
                    ),
                  )}
                  <ListItem item={getSection(sections, 'plan_of_care')} key={`${index} plan_of_care`} />
                  <ListItem item={getSection(sections, 'resources_provided')} key={`${index} resources_provided`} />
                  <ListItem item={getSection(sections, 'review_comments')} key={`${index} review_comments`} />
                </ul>
              </Stack>
            </Fragment>
          );
        })}
      </Stack>
    );
  };

  const Referral = (): JSX.Element => {
    const componentId = 'referral';
    if (!submittedReferrals?.length) return <Stack sx={{ padding: '8px' }}>No referrals made.</Stack>;
    return (
      <table
        style={{ padding: '8px', border: `1px solid ${theme.palette.grey[300]}`, width: '100%' }}
        id={`${componentId}-table`}>
        <thead>
          <tr style={{ width: '100%' }}>
            <th
              style={{ padding: '18px 25px', textAlign: 'left', backgroundColor: theme.palette.grey[100] }}
              colSpan={3}>
              <span style={{ display: 'flex', alignItems: 'center' }}>
                Referral
                <ReferralCountCircle count={submittedReferrals.length} />
              </span>
            </th>
          </tr>
        </thead>
        <tbody>
          {submittedReferrals.map(
            (referral: ReferralType, index: any): JSX.Element => (
              <TableRow key={`referral-${index}`}>
                <td style={{ padding: '18px 0 18px 25px' }}>
                  <div>
                    <div data-test-id={`referral-${index}-service-type`}>
                      {
                        //Check if referral type is 'other'
                        referral.serviceType === 'Other' ? (
                          <div>
                            {index + 1}. {referral.serviceType}:{' '}
                            {referral.description === '' ? 'Not Specified' : referral.description}
                          </div>
                        ) : (
                          index + 1 + '. ' + referral.serviceType
                        )
                      }
                    </div>
                    <div style={{ fontWeight: 'bold' }} data-test-id={`referral-${index}-name`}>
                      {referral.name}
                    </div>
                  </div>
                </td>
                <td style={{ padding: '18px 0 18px 25px' }}>
                  <div>
                    <div>{'Contact'}</div>
                    <div style={{ fontWeight: 'bold' }} data-test-id={`referral-${index}-contact`}>
                      {referral.contactDetails}
                    </div>
                  </div>
                </td>
                <td style={{ padding: '18px 0 18px 25px' }}>
                  <div>
                    <div>{'Date submitted'}</div>
                    <div style={{ fontWeight: 'bold' }} data-test-id={`referral-${index}-date-submitted`}>
                      {moment(referral.submittedAt).format('DD/MM/YYYY')}
                    </div>
                  </div>
                </td>
              </TableRow>
            ),
          )}
        </tbody>
      </table>
    );
  };

  const contentMap = {
    distress: DistressThermometer,
    activity: ActivityLog,
    referral: Referral,
  };
  const Content = contentMap[activeTab as keyof typeof contentMap];

  return (
    <StyledDivContainer {...styledProps}>
      <TabLinksWrapper>
        {tabs.map(
          (option: TabOptionType): JSX.Element => (
            <TabLinks option={option} key={option.id} />
          ),
        )}
      </TabLinksWrapper>
      <ContentWrapper style={{ padding: '8px' }}>{isDataLoading ? <LoadingSpinner /> : <Content />}</ContentWrapper>
    </StyledDivContainer>
  );
};

export default memo(ManagementPlanSidePanel);
