import { Stack } from '@mui/material';
import React, { useContext } from 'react';
import { ArrowRight as ArrowRightIcon, PlagiarismOutlined } from '@mui/icons-material';
import { ClinexContext } from '../Clinex/context';
import { navigateToClinexHighlight } from './helpers';

interface Props {
  field: string;
  children: React.ReactNode;
  ajccVersion?: number;
}

const ClinexFieldWrapper = ({ children, ajccVersion, field }: Props): JSX.Element => {
  let navigateTo = field;
  const tnm_stage = field.slice(0, 7);
  const clinexContext = useContext(ClinexContext);
  const { fieldSelected, clinexData, setClinexModalOpen } = clinexContext;

  let fieldHasHighlight = clinexData?.[field]?.['highlight_geoms'];
  if (!fieldHasHighlight && ajccVersion) {
    const currentType = field.slice(8);
    const otherType = currentType === 'clinical' ? 'pathological' : 'clinical';
    navigateTo = field.replace(currentType, otherType);
    fieldHasHighlight = clinexData?.[navigateTo]?.['highlight_geoms'];
  }

  const showArrowRight = (fieldSelected?.slice(0, 7) === tnm_stage && ajccVersion) || fieldSelected === navigateTo;

  return (
    <Stack direction="row" alignItems="center" sx={{ width: '100%' }} data-testid={`clinex-wrapper-${field}`}>
      {children}
      <Stack direction="row" sx={{ height: '100%', marginBottom: 'auto' }}>
        {fieldHasHighlight && (
          <PlagiarismOutlined
            data-testid="clinex-icon"
            sx={{ cursor: 'pointer', marginLeft: '6px', height: '100%', marginTop: '6px' }}
            color="primary"
            onClick={() => {
              navigateToClinexHighlight(navigateTo, clinexContext);
              setClinexModalOpen(true);
            }}
          />
        )}
        {fieldHasHighlight &&
          (showArrowRight ? (
            <ArrowRightIcon
              data-testid="clinex-icon-arrow"
              sx={{ fontSize: '32px', marginLeft: '-4px', marginRight: '-6px', marginTop: '2px' }}
              color="primary"
            />
          ) : (
            <Stack sx={{ width: '22px' }} />
          ))}
      </Stack>
    </Stack>
  );
};

export default ClinexFieldWrapper;
