import React from 'react';
import { styled } from '@mui/system';
import { ErrorCross, InfoCircleBlueIcon } from 'shared-components/images';
import { getRemSize } from 'shared-components/StyledComponents/functions';
import { useTheme } from '@mui/material';
import { ErrorOutline as ErrorOutlineIcon } from '@mui/icons-material';

interface Props {
  fieldName?: string;
  fieldText: string;
  helperType?: string;
  fontSize?: number;
  iconWidth?: string;
  iconHeight?: string;
  errorMargin?: string;
}

interface WrapperProps {
  color: string;
  $fontSize: number;
  $margin: string;
}

const Wrapper = styled('div')<WrapperProps>`
  display: flex;
  color: ${(props: WrapperProps): string => props.color};
  font-size: ${(props: any): string => getRemSize(props.$fontSize)};
  margin: ${(props: WrapperProps): string => props.$margin};
  justify-content: flex-start;
  align-items: end;
  line-height: 1.25;
`;

const ErrorIcon = styled(ErrorCross)`
  min-width: ${(props: any): string => props.$iconWidth || '20px'};
  height: ${(props: any): string => props.$iconHeight || '20px'};
  margin-right: 5px;
`;

const InfoIcon = styled(InfoCircleBlueIcon)`
  min-width: ${(props: any): string => props.$iconWidth || '20px'};
  height: ${(props: any): string => props.$iconHeight || '20px'};
  margin-right: 5px;
`;

const WarningIcon = styled(ErrorOutlineIcon)`
  min-width: ${(props: any): string => props.$iconWidth || '20px'};
  height: ${(props: any): string => props.$iconHeight || '20px'};
  margin-right: 5px;
`;

const TextWrapper = styled('span')``;

const HelperMessage = ({ fieldName, fieldText, helperType, fontSize, errorMargin }: Props): JSX.Element => {
  const theme = useTheme();
  if (!helperType) helperType = 'error';
  // Add icons/colors to typeConfig object
  const typeConfig = {
    error: {
      icon: ErrorIcon,
      color: theme.palette.error.main,
    },
    info: {
      icon: InfoIcon,
      color: theme.palette.info.main,
    },
    warning: {
      icon: WarningIcon,
      color: theme.palette.warning.dark,
    },
  };

  const wrapperStyledProps = {
    color: typeConfig[helperType as keyof typeof typeConfig].color,
    $fontSize: fontSize || 16,
    $margin: errorMargin || '9px 0',
  };

  return (
    <Wrapper {...wrapperStyledProps} id="form-helper-message" data-testid={`${helperType}-${fieldName}`}>
      <TextWrapper>{fieldText}</TextWrapper>
    </Wrapper>
  );
};

export default HelperMessage;
