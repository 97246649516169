import { gql } from '@apollo/client';
import { REGISTRATION_ADDRESS_FRAGMENT } from 'op-graphql/fragments';

export const EMERGENCY_CONTACT_QUERY = gql`
  query EmergencyContactQuery($id: ID!) {
    patient(id: $id) {
      id
      guardian
      guardianRelationship
      emergencyContact {
        id
        contactType
        relationship
        firstName
        lastName
        mobilePhoneNumber
        homePhoneNumber
        address {
          id
          ...Address
        }
      }
    }
    relationshipsRefData: listData(category: "relationships") {
      id
      name
    }
    user {
      id
      isPso
    }
  }
  ${REGISTRATION_ADDRESS_FRAGMENT}
`;

export const UPDATE_EMERGENCY_CONTACT = gql`
  mutation UpdateEmergencyContactUs(
    $id: ID!
    $firstName: String
    $lastName: String
    $relationship: String
    $mobilePhoneNumber: String
    $homePhoneNumber: String
    $guardian: String
    $guardianRelationship: String
  ) {
    updateEmergencyContactUs(
      id: $id
      firstName: $firstName
      lastName: $lastName
      relationship: $relationship
      mobilePhoneNumber: $mobilePhoneNumber
      homePhoneNumber: $homePhoneNumber
      guardian: $guardian
      guardianRelationship: $guardianRelationship
    ) {
      patient {
        id
        guardian
        guardianRelationship
        emergencyContact {
          id
          contactType
          relationship
          firstName
          lastName
          mobilePhoneNumber
          homePhoneNumber
          address {
            id
            ...Address
          }
        }
      }
    }
  }

  ${REGISTRATION_ADDRESS_FRAGMENT}
`;
