export const STATIC_CONTENT = `
# GenesisCare Patient Portal



## Terms and Conditions



These Terms and Conditions (**Terms**) apply to the GenesisCare Patient Portal (**Portal**) and any services used or accessed through the Portal.



These Terms form part of the agreement between you and the GenesisCare Group (hereinafter referred to as "GenesisCare", "we", "our" or "us"), the operator of a platform that gives you, the user, access to our Portal. Our Portal offers many features such as access to your appointments, tailored content relating to your treatment plan and your care profile.



Any reference to “GenesisCare” in these Terms includes the GenesisCare Group which comprises all related bodies of Genesis Care Pty Ltd ACN 137 188 464 as that term is defined under the Corporations Act 2001 (Cth).



## Acceptance



By using the Portal, you are deemed to accept these Terms which govern GenesisCare’s relationship with you in relation to the Patient Portal.



GenesisCare may change the content of the Portal at our discretion and without notice. By continuing to use the Portal, you accept these Terms as they apply as amended from time to time.



## Your use of the Portal



The Portal is an internet application that provides patients with a secure means to view parts of his/her electronic medical records. Use of the Portal is optional. Your username and password protect the confidentiality of your health information.



You agree that the following security measures are in place for the protection of your privacy:



- **Unique Username and Password.** You will define your own unique username and password in order to access the Portal. You agree not to share your username and password to the Portal with anyone else. You acknowledge your understanding that by sharing your unique username and password, another person may be able to see your health information and communications on the Patient Portal.
- **Encryption.** Information transmitted in the portal is encrypted via https connection(s) with single-socket layer (SSL) certificates.
- **Multi-Factor Authentication.** Communications in the Portal are secured by SSL encryption and the option of multi-factor authentication (MFA) via SMS to your phone is given to patients for added security. You understand that you may opt to “remember me” which will de-activate MFA for seven (7) days from your last login for your convenience, but that in doing so, you are reducing the level of security on your account.
- **Temporary suspension.** In the case where your phone is lost, misplaced or stolen, you agree to inform us as soon as reasonably practicable. For your protection we will suspend access to your Portal account until your account security can be verified.
- **Access requirements.** When you are provided access to define your unique username and password by GenesisCare, you are authorised to access and use the Portal in accordance with these Terms and in compliance with all applicable laws, rules and regulations.
- **Responsibility for username and password.** You are solely responsible for any activities related to your username and password. You will immediately report:
  - any inappropriate access to your account
  - any loss or disclosure of your username or password
  - any suspected or actual inappropriate access to or disclosure of treatment-related information or patient-related files
- **Right to revoke username and password.** GenesisCare reserves the right to revoke your access to the Portal at any time in its sole discretion, including to protect your privacy. You will be notified if we discontinue your access to the Portal.
- **Environmental appropriateness.** You are solely responsible for choosing an appropriate environment from which to log into the Portal.
- **Logout.** You shall always log out of the Portal before leaving your computer.
- **Wireless.** If you access the Portal using a wireless device, you are solely responsible for the security of the session.
- **Portal Security.** The Portal has security measures in place to help protect against the loss, misuse, or alteration of information under our control. These measures include using a secured messaging service when we send you personal information electronically. Despite these measures, the confidentiality of any communication or material transmitted to or from us via the Portal by Internet or e-mail cannot be guaranteed.
- **Site Visitor data.** GenesisCare monitors the Portal including from time to time frequency of usage, issues with usage and other specific usage.
- **Evaluation and research.** We may periodically ask users to complete surveys asking about their experiences with features of the Portal. Our surveys may ask visitors for demographic information such as age, gender, and education, but will not request in such surveys that users provide specific information about any medical condition. We use survey information for research and quality improvement purposes, including helping GenesisCare improve the Patient Portal. In addition, users giving feedback may be individually contacted for follow-up due to concerns raised during the course of such evaluation.
- **Disclosure.** We may disclose personal information if required or permitted by law.



The Portal is not intended to replace your doctor nor is it for use in emergency situations. The information contained in the Portal is for your general information and not intended to replace, delay the seeking out of or otherwise affect your medical advice. You should not stop or change any course of treatment ordered by your doctor before asking him or her, and you should not take any action based on the information provided in the Portal without consulting a doctor or other health care provider first.



The materials and contents contained in the Portal are GenesisCare’s property or the property of the persons who have granted us an applicable licence (this also applies to any relevant third-party software forming part of the Portal). We grant you the right to use these materials and content, but only for the purposes of using the Portal in accordance with these Terms.
You acknowledge that our ownership and centralised management of medical records and other health-related and scheduling information of patients who receive medical services at GenesisCare is necessary to support the proper and professional operation of GenesisCare’s services to patients, including to ensure continuity of patient care, compliance with the requirements of the Privacy Act 1988 (Cth) (Privacy Act) and to manage risk.



You should not disregard professional advice purely due to the information contained in the Portal. Whilst we provide general health information, the circumstance of each individual will differ. You should carefully evaluate the available information and exercise your own judgement while using the Portal.



## Disclaimer of liability



Although GenesisCare has taken due care in the compilation of the information on the Portal, its contents may contain errors, faults and inaccuracies, and may not be complete or current. Accordingly, the information and the Patient Portal is provided ‘as is’ without any express or implied warranty of any kind (including without limitation, as to fitness for purpose or that use will be uninterrupted or error-free), and the Portal is used at your own risk.



To the maximum extent permitted by law, GenesisCare expressly disclaims all liability (including negligence) for any loss, damage, injury, expense or costs incurred by you accessing or relying on the information of the Portal.



## Copyright and trademarks



We grant you the right to personally access and use the Portal. You are prohibited to transfer this right to anyone else, or to sell, give away or transfer your User Account to anyone else. Your right to access the Portal does not preclude us granting other persons the right to access the Portal.



In return for your warranty to comply with these Terms, we grant you a limited, non-transferable, non-sublicensable, personalised, non-exclusive, revocable right to access the Portal under the conditions set forth in these Terms or the Privacy Policy.



The Portal may contain trademarks owned by GenesisCare and third parties. You may not display or use in any manner any trademarks featured without the prior written permission of GenesisCare.
If, and to the extent that, our Portal contains links to other websites or resources operated by third parties, these links are to be deemed as being provided for your information only.



We are unable to influence the content of such websites or resources provided by third parties, and we may not be held liable for the content of information or such websites. We may not be held liable for any financial or physical damages that you may incur as a result of, or in connection with, the use of such websites or resources.



## Patient Portal



The Portal includes text, software code, scripts, graphics, displays and others.



Due to the nature of the internet and the given technology, we are unable to guarantee that you will be able to use the Portal at all times without any interruption and without delay or faults, and that the Portal will at all times meet your expectations. We can therefore make no assurance with respect to the performance or availability of the Portal.



## User account



You may access the Portal directly via the web-based application.



After accessing the Portal, you can set up a user account with a username (email or mobile phone number) and password. When you register your user account or provide us with other information, you are also declaring that the information provided to us is correct and complete. In the event of any change in the information at a later time, you declare that you will promptly notify us of such changes by updating the information.



## Collection of personal information



We are committed to protecting the personal information you share with us.



By using the Patient Portal, you acknowledge that GenesisCare will collect your name and contact details to enable you to log in to the Portal and verify your identity. If you do not provide us with this information, we will not be able to provide you with secure access to the Portal.



In addition, if a user visits any of our websites to read, browse or download information, our system may record information such as the user’s IP address, browser type, domain names, access times and referring website addresses. This information is used by GenesisCare for the operation of the service, to maintain quality of the service, and to provide general statistics regarding use of our websites. Our websites may also use cookies to help identify and interact more effectively with users’ devices. A cookie is a text file that is placed on a user’s device by a web page server. Cookies cannot be used to run programs or deliver viruses to your computer. The cookie helps us to maintain the continuity of users’ browsing sessions and remember their details and preferences for when they return. You have the ability to accept or decline cookies. Most web browsers automatically accept cookies, but you can usually modify your browser setting to decline cookies if you prefer. If you choose to decline cookies, you may not be able to fully experience the interactive features of our websites.



For more information about how we handle your personal information, please refer to our Privacy Policy at [www.genesiscare.com/au/privacy-policy](https://www.genesiscare.com/au/privacy-policy). Our Privacy Policy also contains information about how you can make a request to access the personal information we hold about you and make corrections to it, as well as who to contact if you have a privacy enquiry or complaint.



## Closing your account



If you want to discontinue your use of the Patient Portal, you agree to inform GenesisCare of your request to close your account. You understand that if you do this, you will no longer be able to use or access the Portal and your account on the Portal will be closed. You understand that any information stored in the Patient Portal at the time your account is closed and will be archived in our database.



## Questions



If you would like to contact us regarding these Terms or any other document mentioned in them, please email us at [patientportal@genesiscare.com](mailto:patientportal@genesiscare.com)
`;
