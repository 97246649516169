import { Checkbox, FormControlLabel, FormGroup, Typography, useTheme } from '@mui/material';

export interface ButtonOptions {
  label: string;
  value: string;
}

interface Props {
  id: string;
  value: any[];
  disabled?: boolean;
  onBlur?: React.FocusEventHandler<HTMLElement>;
  onChange: (value: any[]) => void;
  options: ButtonOptions[];
}

const CheckBoxGroup = (props: Props): JSX.Element => {
  const { id, value, disabled, options, onBlur, onChange } = props;
  const theme = useTheme();

  return (
    <FormGroup>
      {options?.map((option: ButtonOptions): JSX.Element => {
        const toggleId = `toggle-button-${option.value.replace(/\s/g, '')}`;
        return (
          <FormControlLabel
            sx={{
              width: '100%',
              borderRadius: '4px',
              border: `1px solid ${(props: any) => props.theme.palette.grey[300]}`,
              '&:hover': {
                backgroundColor: theme.palette.primary.light,
              },
            }}
            control={<Checkbox />}
            id={toggleId}
            data-testid={`${id + option.value}`}
            key={option.value}
            value={option.value}
            disabled={disabled}
            onBlur={onBlur}
            onChange={(): void => {
              if (disabled) {
                return;
              }
              if (value.includes(option.value)) {
                onChange(value.filter((item) => item !== option.value));
              } else {
                onChange([...value, option.value]);
              }
            }}
            checked={value.includes(option.value)}
            label={<Typography sx={{ fontSize: '14px' }}>{option.label}</Typography>}
          />
        );
      })}
    </FormGroup>
  );
};

export default CheckBoxGroup;
