// eslint-disable-next-line no-use-before-define
import React from 'react';

import './ErrorPage.scss';
import { CurrentAppConfig } from 'op-pages/RO/Careplan/AppConfig';
import { CustomTheme, useTheme } from '@mui/material';

const ErrorPage = (): JSX.Element => {
  const theme: CustomTheme = useTheme();
  return (
    <div id="error-page">
      <div id="error-container">
        <div>
          {theme.custom.darkLogo}
          <div id="error-title">{'You do not have permission to view this page'}</div>
          <div id="error-detail">
            <span>
              If you believe you are seeing this screen in error, please contact Innovation Support by email at:
            </span>
            &nbsp;
            <a href={`mailto:${CurrentAppConfig.SupportEmail}`}>{CurrentAppConfig.SupportEmail}</a>
          </div>
        </div>
        <div id="error-logo">{theme.custom.logoOnly}</div>
      </div>
    </div>
  );
};

export default ErrorPage;
