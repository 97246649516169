// eslint-disable-next-line no-use-before-define
import React from 'react';
import Checkbox from '@mui/material/Checkbox';
import TextField from '@mui/material/TextField';
import Autocomplete from '@mui/material/Autocomplete';
import CheckBoxOutlineBlankIcon from '@mui/icons-material/CheckBoxOutlineBlank';
import CheckBoxIcon from '@mui/icons-material/CheckBox';
import { styled } from '@mui/system';
import FormControlLabel from '@mui/material/FormControlLabel';
import { useTheme } from '@mui/material';

import InputAdornment from '@mui/material/InputAdornment';

const StyledMultiSelectAutoComplete = styled(Autocomplete)`
  '.MuiAutocomplete-inputRoot': {
    color: ${(props) => props.theme.palette.text.primary},
    backgroundColor: 'white',
    border: ${(props) => props.theme.palette.grey[600]},
    '& .MuiOutlinedInput-notchedOutline': {
      borderWidth: '1px',
      borderColor: ${(props) => props.theme.palette.grey[600]},
    },
    '&.Mui-focused .MuiOutlinedInput-notchedOutline': {
      borderWidth: '1px',
      borderColor: ${(props) => props.theme.palette.primary.main},
    },
  },
`;

const icon = <CheckBoxOutlineBlankIcon fontSize="small" />;
const checkedIcon = <CheckBoxIcon fontSize="small" />;

interface MultiSelectOptions {
  label: string;
  value: string;
}

interface MultiSelectProps {
  options: MultiSelectOptions[];
  id?: string;
  value?: MultiSelectOptions[];
  onChange: (value: any) => void;
  label?: string;
  placeholder?: string;
  disableCloseOnSelect?: boolean;
  limitTags?: number;
  adornmentIcon?: JSX.Element;
  size?: 'small' | 'medium';
}

const filterOptions = (optionsToDisplay: MultiSelectOptions[], previousOptions: MultiSelectOptions[]) => {
  const selectedValues = new Set(
    previousOptions.map((option: MultiSelectOptions) => {
      return option.value;
    }),
  );

  return optionsToDisplay.filter((option: MultiSelectOptions) => !selectedValues.has(option.value));
};

const MultiSelectWithSearch = (props: MultiSelectProps): JSX.Element => {
  const {
    options,
    id,
    value,
    onChange,
    label,
    placeholder,
    disableCloseOnSelect,
    limitTags = -1,
    adornmentIcon,
    size,
  } = props;
  const theme = useTheme();
  return (
    <StyledMultiSelectAutoComplete
      multiple
      limitTags={limitTags}
      data-testid="autocomplete-search"
      id={id || ''}
      value={value}
      options={value ? filterOptions(options, value) : options}
      onChange={(_, value) => {
        onChange(value);
      }}
      disableCloseOnSelect={disableCloseOnSelect}
      getOptionLabel={(option: any) => option.label}
      renderOption={(props: any) => {
        const option = props.key;
        return (
          <FormControlLabel
            labelPlacement="end"
            label={option}
            {...props}
            control={
              <Checkbox
                data-testid="autocomplete-checkbox"
                icon={icon}
                checkedIcon={checkedIcon}
                style={{ marginRight: 8 }}
                checked={value?.includes(option) || false}
              />
            }
          />
        );
      }}
      style={{ width: 325 }}
      renderInput={(params) => (
        <TextField
          {...params}
          size={size || undefined}
          variant="outlined"
          label={label}
          placeholder={placeholder}
          InputLabelProps={{
            style: {
              color: theme.palette.grey[600],
            },
          }}
          InputProps={{
            ...params.InputProps,
            startAdornment: (
              <>
                {adornmentIcon && <InputAdornment position="start">{adornmentIcon}</InputAdornment>}
                {params.InputProps.startAdornment}
              </>
            ),
          }}
        />
      )}
    />
  );
};

export default MultiSelectWithSearch;
export { MultiSelectOptions, MultiSelectProps };
