import React from 'react';

const initialNavigationContext = {
  state: {
    regEntryPath: '',
    searchTerm: '',
    locationFilter: '',
    regStatusFilter: '',
    tagFilter: '',
  },
  setRegEntryPath: (_regEntryPath: string): void => {},
  setSearchTerm: (_searchTerm: string): void => {},
  setLocationFilter: (_locationFilter: string): void => {},
  setRegStatusFilter: (_regStatusFilter: string): void => {},
  setTagFilter: (_tagFilter: string): void => {},
};

const NavigationContext = React.createContext(initialNavigationContext);

export default NavigationContext;
