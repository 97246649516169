import { Field, Form, Formik, FormikValues } from 'formik';
import moment from 'moment';
import { FormContainer } from 'op-components';
import { mapListData } from 'op-utils';
import { RegistrationContext } from 'op-contexts';
import React, { useContext, useMemo } from 'react';
import { generatePath } from 'react-router';
import { useHistory, useParams } from 'react-router-dom';
import { FormRow } from 'shared-components/components';
import { RODatePicker } from 'shared-components/components/FormFields';
import { ListData, SelectOptions } from 'shared-components/interfaces';
import {
  CONTINUE_BUTTON_TEXT,
  HeaderSubTitle,
  HeaderTitle,
  PSO_SUMMARY_LINK,
  registrationPath,
  RETURN_TO_SUBMIT_TEXT,
  SectionTitle,
  sharedFormContainerProps,
  standardField,
  StyledIndentedSection,
  yesNoOptions,
} from '../Helper';
import { PatientInsuranceOtherBenefit, RegistrationFormUSIProps } from '../interfaces';
import { FIELDS } from './constants';
import { generateValidationSchema } from './validation';
import dayjs from 'dayjs';

interface InsuranceFormIProps extends RegistrationFormUSIProps {
  patient: PatientInsuranceOtherBenefit;
  readonly refData: {
    readonly relationshipRefData: ListData[];
  };
}

const { FORM_HEADING } = FIELDS;

const renderOtherBenefits = (updateField: (title: string, value: string | number | boolean) => void) => (
  <>
    {standardField(FIELDS.VETERANS_ADMIN_NAME, updateField)}
    {standardField(FIELDS.VETERANS_CONTACT_NUMBER, updateField)}
    {standardField(FIELDS.VETERANS_CITY, updateField)}
    {standardField(FIELDS.VETERANS_STATE_PROVINCE, updateField)}
    {standardField(FIELDS.VETERANS_ZIP, updateField)}
  </>
);

const removePrefix = (propertyNameWithPrefix: string, insuranceType: string) => {
  let newPropertyNameForMutation = propertyNameWithPrefix.replace(`${insuranceType.toLowerCase()}Insurance.`, '');

  if (insuranceType === 'SECONDARY') {
    newPropertyNameForMutation = `secondary${newPropertyNameForMutation}`;
  }

  return newPropertyNameForMutation;
};

const renderInsurance = (
  insuranceType: 'PRIMARY' | 'SECONDARY',
  updateField: (name: string, value: string | boolean | number) => void,
  patient: PatientInsuranceOtherBenefit,
  relationOptions: SelectOptions[],
) => {
  const fieldConstants = FIELDS[insuranceType];
  const updateFieldWithoutPrefix = (name: string, value: string | boolean | number) => {
    return updateField(removePrefix(name, insuranceType), value);
  };
  return (
    <>
      {standardField(fieldConstants.HEALTH_FUND_NAME, updateFieldWithoutPrefix)}
      {standardField(fieldConstants.PLAN_ID, updateFieldWithoutPrefix)}
      {standardField(fieldConstants.GROUP, updateFieldWithoutPrefix)}
      {standardField(fieldConstants.PHONE_NUMBER, updateFieldWithoutPrefix)}
      {standardField(fieldConstants.POLICY_HOLDER, updateFieldWithoutPrefix)}
      <FormRow
        fieldLabel={fieldConstants.HOLDER_DOB.TITLE}
        fieldName={fieldConstants.HOLDER_DOB.NAME}
        className="registration-date"
        labelClass={'label-form-row'}>
        <Field
          name={fieldConstants.HOLDER_DOB.NAME}
          component={RODatePicker}
          alternateStyle
          isUs={true}
          id={fieldConstants.HOLDER_DOB.NAME}
          value={
            //@ts-ignore
            patient[`${insuranceType.toLowerCase()}Insurance`]?.policyDob
              ? //@ts-ignore
                dayjs(patient[`${insuranceType.toLowerCase()}Insurance`].policyDob).toDate()
              : null
          }
          disableFuture
          onChange={(date: Date | null): void => {
            const newDate = date ? moment(date).format('YYYY-MM-DD') : '';
            updateFieldWithoutPrefix(fieldConstants.HOLDER_DOB.NAME, newDate);
          }}
        />
      </FormRow>

      {standardField(fieldConstants.GUARANTOR, updateFieldWithoutPrefix)}
      {standardField(fieldConstants.GUARANTOR_RELATION, updateFieldWithoutPrefix, relationOptions)}
    </>
  );
};

const InsuranceForm = (props: InsuranceFormIProps): JSX.Element => {
  const {
    updateField,
    patient,
    previousPageLink,
    handleShowSaveExitConfirm,
    isPso,
    refData: { relationshipRefData },
  } = props;

  const registrationContext = useContext(RegistrationContext);
  const history = useHistory();
  const { patientId } = useParams<{ patientId: string }>();

  const relationOptions = useMemo(() => mapListData(relationshipRefData), relationshipRefData);
  const goToSummary = registrationContext?.registrationSummaryVisited && !isPso;
  const referringPage = sessionStorage.getItem('referringPage');

  return (
    <Formik
      initialValues={patient}
      validateOnBlur={true}
      validate={(values: FormikValues) => generateValidationSchema(values)}
      onSubmit={() => history.push(registrationPath(patient?.id, goToSummary ? 'summary' : 'demographics'))}>
      {({ submitForm, values, errors, setFieldValue }) => {
        const sharedProps = sharedFormContainerProps('Insurance and other benefits', 2, previousPageLink, !isPso);

        const formContainerProps = {
          ...sharedProps,
          submitButtonText: goToSummary ? RETURN_TO_SUBMIT_TEXT : CONTINUE_BUTTON_TEXT,
          continueDisabled: Object.keys(errors).length > 0,
          handleShowSaveExitConfirm,
          saveAndExitLink: isPso && generatePath(referringPage || PSO_SUMMARY_LINK, { patientId }),
        };
        const updateHasSecondary = (name: string, value: string | boolean | number) => {
          updateField(name, value);
          setFieldValue('secondaryInsurance.fundName', '');
          setFieldValue('secondaryInsurance.planId', '');
          setFieldValue('secondaryInsurance.group', '');
          setFieldValue('secondaryInsurance.phoneNumber', '');
          setFieldValue('secondaryInsurance.policyHolder', '');
          setFieldValue('secondaryInsurance.policyDob', '');
          setFieldValue('secondaryInsurance.guarantor', '');
          setFieldValue('secondaryInsurance.guarantorRelationship', '');
        };
        const updateOtherBenefits = (name: string, value: string | boolean | number) => {
          updateField(name, value);
          setFieldValue('veteransAdminName', '');
          setFieldValue('veteransContactNumber', '');
          setFieldValue('veteransCity', '');
          setFieldValue('veteransStateProvince', '');
          setFieldValue('veteransZip', '');
        };
        return (
          <FormContainer
            {...formContainerProps}
            submitForm={submitForm}
            handleValidation={(event: React.MouseEvent<HTMLInputElement>): void => {
              void event;
            }}>
            <Form>
              <HeaderTitle data-test-id="us-rego-page-title">{FORM_HEADING.TITLE}</HeaderTitle>
              <HeaderSubTitle data-test-id="us-rego-page-subtitle">* = required field</HeaderSubTitle>
              <SectionTitle data-test-id="us-rego-primary-insurance-section-title">Primary Insurance</SectionTitle>
              {renderInsurance('PRIMARY', updateField, patient, relationOptions)}
              <hr></hr>
              <SectionTitle data-test-id="us-rego-secondary-insurance-section-title">Secondary Insurance</SectionTitle>
              {standardField(FIELDS.HAS_SECONDARY_INSURANCE, updateHasSecondary, yesNoOptions)}
              {values.hasSecondaryInsurance && (
                <StyledIndentedSection>
                  {renderInsurance('SECONDARY', updateField, patient, relationOptions)}
                </StyledIndentedSection>
              )}

              <hr></hr>
              <SectionTitle data-test-id="us-rego-benefits-insurance-section-title">Other Benefits</SectionTitle>
              {standardField(FIELDS.OTHER_BENEFITS, updateOtherBenefits, yesNoOptions)}
              <StyledIndentedSection>
                {values.receiveVeteransAdminBenefits && renderOtherBenefits(updateField)}
              </StyledIndentedSection>
            </Form>
          </FormContainer>
        );
      }}
    </Formik>
  );
};

export default InsuranceForm;
