import * as Yup from 'yup';
import { ADDRESS_VALIDATION, POSTCODE_VALIDATION } from './constants';
import { PHONE_VALIDATION } from '../Helper';
import { addressLabelForRegion } from 'shared-components/enums';
import {
  US_PHONE_REGEX,
  US_POSTCODE_REGEXP,
  AU_POSTCODE_REGEXP,
  UK_POSTCODE_REGEXP,
} from 'shared-components/utils/Regex';
import { Address } from 'shared-components/interfaces';
import { FormikValues, validateYupSchema, yupToFormErrors } from 'formik';

const validateAddress = (address: Address) => {
  let postcodeValidation: Yup.StringSchema<string | undefined, object> = Yup.string();
  const addressLabels = addressLabelForRegion();
  const postcodeLabel = `Please enter your correct ${addressLabels.postcode}`;
  switch (address?.country) {
    case 'Australia':
      postcodeValidation = Yup.string().matches(AU_POSTCODE_REGEXP, postcodeLabel);
      break;
    case 'United Kingdom':
    case 'Scotland':
    case 'England':
    case 'Northern Ireland':
    case 'Wales':
      postcodeValidation = Yup.string().matches(UK_POSTCODE_REGEXP, postcodeLabel);
      break;
    case 'US':
    case 'USA':
    case 'United States':
    case 'United States of America':
      postcodeValidation = Yup.string().matches(US_POSTCODE_REGEXP, postcodeLabel);
      break;
    default:
    // no validation
  }
  return postcodeValidation;
};

export const generateValidationSchema = (values: FormikValues): any => {
  const homePostcodeValidation = validateAddress(values?.adress);
  const facilityPostcodeValidation = validateAddress(values?.facilityAddress);

  const schema = Yup.object().shape({
    facilityContactNumber: Yup.string().matches(US_PHONE_REGEX, PHONE_VALIDATION),
    address: Yup.object().shape({
      autofilled: Yup.string().when('postcode', {
        is: (postcode: string) => homePostcodeValidation.isValidSync(postcode),
        then: (schema: any) => schema.ensure(),
        otherwise: () => homePostcodeValidation,
      }),
      line1: Yup.string().required(ADDRESS_VALIDATION),
      city: Yup.string().required(ADDRESS_VALIDATION),
      state: Yup.string().required(ADDRESS_VALIDATION),
      country: Yup.string().required(ADDRESS_VALIDATION),
      postcode: Yup.string()
        .required(POSTCODE_VALIDATION)
        .when(['$validatePostcode'], (validatePostcode: any, schema: any) =>
          validatePostcode ? homePostcodeValidation : schema.ensure(),
        ),
    }),
    facilityAddress: Yup.object()
      .shape({
        autofilled: Yup.string().when('postcode', {
          is: (postcode: string) => facilityPostcodeValidation.isValidSync(postcode),
          then: (schema: any) => schema.ensure(),
          otherwise: () => facilityPostcodeValidation,
        }),
        postcode: Yup.string().when(['$validatePostcode'], (validatePostcode: any, schema: any) =>
          validatePostcode ? facilityPostcodeValidation : schema.ensure(),
        ),
      })
      .nullable(),
  });

  try {
    validateYupSchema<FormikValues>(values, schema, true, {
      validatePostcode: values.address.postcode && values.address.country ? true : false,
    });
  } catch (err) {
    return yupToFormErrors(err);
  }
};
