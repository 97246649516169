import React, { useState } from 'react';
import CareplanContainer from 'op-pages/RO/Careplan/Container';
import ROPatientCarePlanPageFooter from 'op-pages/RO/Careplan/Footer';
import { useHistory, useRouteMatch } from 'react-router-dom';
import { CAREPLAN_PAGE } from '../Constants';
import { ROSidePanel } from 'op-components/index';
import { LoadingSpinner } from 'shared-components/components';

const MOSubmissionPage = (): JSX.Element => {
  const match = useRouteMatch<{ id: string; careplanId: string }>();
  const { id: patientId, careplanId } = match.params;

  const history = useHistory();
  const [state, setState] = useState({ loading: true, error: false });
  return (
    <div className="main-container-parent-wrapper">
      <div className="main-container-wrapper">
        <CareplanContainer>
          {' '}
          <div className="page-header">
            <h1>{'Submission'}</h1>
            <h2>{'Please confirm submission information.'}</h2>
          </div>
          {state.loading && (
            <LoadingSpinner
              loadingText={'Loading Submission'}
              subtitle={'Please wait while we set things up for you'}
            />
          )}
          {state.error && <div>An error occurred while generating the PDF, please contact support</div>}
          <iframe
            title="prescriptioinpage"
            src={`/mo_portal/careplan/${careplanId}/prescription.pdf`}
            width="100%"
            height="100%"
            onLoad={(): void => {
              setState((state) => {
                return { ...state, loading: false };
              });
            }}
            onError={(): void => {
              setState((state) => {
                return { ...state, error: true };
              });
            }}>
            This browser does not support PDFs.
          </iframe>
        </CareplanContainer>
        <ROPatientCarePlanPageFooter
          onBack={(): void => {
            history.push(`/medonc/patient/${patientId}/careplan/${careplanId}/${CAREPLAN_PAGE.PRESCRIPTION}`);
          }}
          onNext={(): void => {}}
          backDisabled={false}
          backButtonId={'submission-back-button'}
          nextText={'Prescription' || ''}
          nextTextId="prescription"
        />
      </div>
      <ROSidePanel />
    </div>
  );
};

export default MOSubmissionPage;
