// eslint-disable-next-line no-use-before-define
import React, { Component } from 'react';

import classNames from 'classnames';

import './SectionField.scss';

/**
 * Props interface for the FieldComponent
 * @param {string} title Contains the title of the componenet
 * @param {string} htmlFor  Relation to the componenet that's the child
 * @param {boolean=} required Boolean tracking the required status of the component. Optional, defaults to false
 * @param {boolean=} isValid Shows validation error state
 */
interface Props {
  title: string;
  htmlFor: string;
  required?: boolean;
  isValid?: boolean;
  hideOptional?: boolean;
  disabled?: boolean;
  additionalClassNames?: string | null;
  children?: React.ReactNode;
}

class FormSegmentField extends Component<Props> {
  public static defaultProps = {
    additionalClassNames: null,
    required: true,
    isValid: true,
  };

  public render(): JSX.Element {
    const {
      props: { isValid, htmlFor, title, required, hideOptional },
    } = this;

    return (
      <div
        data-test-id={`section-${htmlFor}`}
        className="form-section-field"
        style={{ display: 'flex', flexWrap: 'wrap', alignItems: 'baseline' }}>
        <label
          style={{ flex: '1 1 100%' }}
          className={classNames(
            'field-label',
            { 'validation-error-text': !isValid },
            this.props.additionalClassNames ? this.props.additionalClassNames : null,
          )}
          htmlFor={htmlFor}>
          {`${title}${!hideOptional && !required ? ' (optional)' : ''}`}
        </label>
        {this.props.children}
      </div>
    );
  }
}

export default FormSegmentField;
