// eslint-disable-next-line no-use-before-define
import React, { Fragment } from 'react';
import { Switch, Route } from 'react-router-dom';
import ROPatientDiagnosisPrimaryOrMetastasis from './DiagnosisPrimaryOrMetastasis';
import ROPatientDiagnosisSelectionPage from './DiagnosisSelectionPage';
import ROPatientDiagnosisICD10Page from './ICD10Page';
import ROPatientDiagnosisIntakePage from './Intake/IntakePage';
import StagingPageRouter from './Staging/StagingPageRouter';
import { CAREPLAN_PAGES } from '../Constants';

interface DiagRouterProps {
  onDataRefresh: any;
}

const ROPatientDiagnosisRouter = (props: DiagRouterProps): JSX.Element => {
  return (
    <Fragment>
      <Switch>
        <Route
          path={`/:oncologyType/patient/:id/careplan/:careplanId/diagnosis/:diagnosisId/${CAREPLAN_PAGES.INTAKE}`}
          component={ROPatientDiagnosisIntakePage}
        />
        <Route
          path={`/:oncologyType/patient/:id/careplan/:careplanId/diagnosis/:diagnosisId/${CAREPLAN_PAGES.STAGING}`}>
          <StagingPageRouter onDataRefresh={props.onDataRefresh} />
        </Route>
        <Route
          path="/:oncologyType/patient/:id/careplan/:careplanId/diagnosis/:diagnosisId/:page/:operation"
          component={ROPatientDiagnosisICD10Page}
        />
        <Route
          path="/:oncologyType/patient/:id/careplan/:careplanId/diagnosis/:diagnosisId/:page"
          component={ROPatientDiagnosisSelectionPage}
        />
        <Route
          path="/:oncologyType/patient/:id/careplan/:careplanId/(diagnosis)?/:diagnosisId?"
          component={ROPatientDiagnosisPrimaryOrMetastasis}
        />
      </Switch>
    </Fragment>
  );
};

export default ROPatientDiagnosisRouter;
