// eslint-disable-next-line no-use-before-define
import React, { Component } from 'react';
import validate from 'validate.js';

import { UKPatientAltContact } from 'op-interfaces';
import { ListData, Dictionary } from 'shared-components/interfaces';
import { SavingStatus } from 'shared-components/enums';
import { UK_PHONE_REGEX, SegmentedInputBoolean } from 'shared-components/utils';

import { DropDownField, FreeTextField, SectionField, SegmentedInput } from 'shared-components/components/FormFields';
import { RegistrationContainer } from 'op-components';

// Constants
const FIELD_NAMES: { [key: string]: string } = {
  E_FIRST_NAME: 'emergencyContactFirstName',
  E_LAST_NAME: 'emergencyContactLastName',
  E_RELATIONSHIP: 'emergencyContactRelationship',
  E_PRIMARY_PHONE_NUMBER: 'emergencyContactMobilePhoneNumber',
  E_SECONDARY_PHONE_NUMBER: 'emergencyContactHomePhoneNumber',
  E_EMAIL_ADDRESS: 'emergencyContactEmail',
  SAME_AS_EMERGENCY: 'sameAsEmergency',
  N_FIRST_NAME: 'nextOfKinContactFirstName',
  N_LAST_NAME: 'nextOfKinContactLastName',
  N_RELATIONSHIP: 'nextOfKinContactRelationship',
  N_PRIMARY_PHONE_NUMBER: 'nextOfKinContactMobilePhoneNumber',
  N_SECONDARY_PHONE_NUMBER: 'nextOfKinContactHomePhoneNumber',
  N_EMAIL_ADDRESS: 'nextOfKinContactEmail',
};

const BASE_KEYS = {
  FIRST_NAME: 'firstName',
  LAST_NAME: 'lastName',
  RELATIONSHIP: 'relationship',
  MOBILE_PHONE: 'mobilePhoneNumber',
  HOME_PHONE: 'homePhoneNumber',
  EMAIL_ADDRESS: 'email',
  EMERGENCY: 'emergencyContact',
  NEXT_OF_KIN: 'nextOfKinContact',
};

const EMERGENCY_VALIDATION = {
  FIRST_NAME: `${BASE_KEYS.EMERGENCY}.${BASE_KEYS.FIRST_NAME}`,
  LAST_NAME: `${BASE_KEYS.EMERGENCY}.${BASE_KEYS.LAST_NAME}`,
  RELATIONSHIP: `${BASE_KEYS.EMERGENCY}.${BASE_KEYS.RELATIONSHIP}`,
  MOBILE_PHONE: `${BASE_KEYS.EMERGENCY}.${BASE_KEYS.MOBILE_PHONE}`,
  HOME_PHONE: `${BASE_KEYS.EMERGENCY}.${BASE_KEYS.HOME_PHONE}`,
  EMAIL_ADDRESS: `${BASE_KEYS.EMERGENCY}.${BASE_KEYS.EMAIL_ADDRESS}`,
};

const NEXT_OF_KIN_VALIDATION = {
  FIRST_NAME: `${BASE_KEYS.NEXT_OF_KIN}.${BASE_KEYS.FIRST_NAME}`,
  LAST_NAME: `${BASE_KEYS.NEXT_OF_KIN}.${BASE_KEYS.LAST_NAME}`,
  RELATIONSHIP: `${BASE_KEYS.NEXT_OF_KIN}.${BASE_KEYS.RELATIONSHIP}`,
  MOBILE_PHONE: `${BASE_KEYS.NEXT_OF_KIN}.${BASE_KEYS.MOBILE_PHONE}`,
  HOME_PHONE: `${BASE_KEYS.NEXT_OF_KIN}.${BASE_KEYS.HOME_PHONE}`,
  EMAIL_ADDRESS: `${BASE_KEYS.NEXT_OF_KIN}.${BASE_KEYS.EMAIL_ADDRESS}`,
};

const FIELD_SIZE_LIMITS = {
  E_FIRST_NAME: 40,
  E_LAST_NAME: 40,
  E_PRIMARY_PHONE_NUMBER: 25,
  E_SECONDARY_PHONE_NUMBER: 25,
  E_EMAIL_ADDRESS: 40,
  N_FIRST_NAME: 40,
  N_LAST_NAME: 40,
  N_PRIMARY_PHONE_NUMBER: 25,
  N_SECONDARY_PHONE_NUMBER: 25,
  N_EMAIL_ADDRESS: 40,
};

const FIELD_HEADINGS = {
  E_FIRST_NAME: 'First name',
  E_LAST_NAME: 'Last name',
  E_RELATIONSHIP: 'Relationship',
  E_PRIMARY_PHONE_NUMBER: 'Mobile phone number',
  E_SECONDARY_PHONE_NUMBER: 'Home phone number',
  E_EMAIL_ADDRESS: 'Email',
  SAME_AS_EMERGENCY: 'Next of kin is the same as emergency contact',
  N_FIRST_NAME: 'First name',
  N_LAST_NAME: 'Last name',
  N_RELATIONSHIP: 'Relationship',
  N_PRIMARY_PHONE_NUMBER: 'Mobile phone number',
  N_SECONDARY_PHONE_NUMBER: 'Home phone number',
  N_EMAIL_ADDRESS: 'Email',
};

const FIELD_PLACEHOLDERS = {
  E_FIRST_NAME: '',
  E_LAST_NAME: '',
  E_RELATIONSHIP: '',
  E_PRIMARY_PHONE_NUMBER: '',
  E_SECONDARY_PHONE_NUMBER: '',
  E_EMAIL_ADDRESS: '',
  N_FIRST_NAME: '',
  N_LAST_NAME: '',
  N_RELATIONSHIP: '',
  N_PRIMARY_PHONE_NUMBER: '',
  N_SECONDARY_PHONE_NUMBER: '',
  N_EMAIL_ADDRESS: '',
};

const FIELD_INCOMPLETE_INPUT_ERRORS = {
  E_FIRST_NAME: 'Please enter First name.',
  E_LAST_NAME: 'Please enter Last name.',
  E_RELATIONSHIP: 'Please select relationship from the drop-down menu.',
  PHONE_NUMBER: 'Please enter your mobile or home phone number.',
  SAME_AS_EMERGENCY: 'Please select and option.',
  N_FIRST_NAME: 'Please enter First name.',
  N_LAST_NAME: 'Please enter Last name.',
  N_RELATIONSHIP: 'Please select relationship from the drop-down menu.',
};

const FIELD_INVALID_INPUT_ERRORS = {
  E_FIRST_NAME: 'Please enter a valid format for First name.',
  E_LAST_NAME: 'Please enter a valid format for Last name.',
  E_PRIMARY_PHONE_NUMBER: 'Please enter a valid mobile number.',
  E_SECONDARY_PHONE_NUMBER: 'Please enter a valid home phone number.',
  E_EMAIL_ADDRESS: 'Please enter a valid email address.',
  SAME_AS_EMERGENCY: 'Please select an option.',
  N_FIRST_NAME: 'Please enter a valid format for First name.',
  N_LAST_NAME: 'Please enter a valid format for Last name.',
  N_PRIMARY_PHONE_NUMBER: 'Please enter a valid mobile number..',
  N_SECONDARY_PHONE_NUMBER: 'Please enter a valid home phone number.',
  N_EMAIL_ADDRESS: 'Please enter a valid email address.',
};

const E_FORM_HEADING = 'Emergency contact';
const N_FORM_HEADING = 'Next of Kin';

interface Props {
  altContactInfo: UKPatientAltContact;
  autosave: (patient: UKPatientAltContact, updateObject: object, forceFetch?: object[]) => void;
  relationshipsReferenceData: ListData[];
  genderRefData: ListData[];
  saveStatus: SavingStatus;
  validateOnLoad: boolean;
  isPso: boolean;
}

interface State {
  viewed: Set<string>;
}

class UKRegistrationAltContact extends Component<Props, State> {
  public constructor(props: Props) {
    super(props);
    this.state = {
      viewed: new Set(),
    };
  }

  public static getDerivedStateFromProps(props: Props, state: State): State {
    if (props.validateOnLoad && props.altContactInfo.lock && !props.altContactInfo.lock.readOnly) {
      // Add all the field's to viewed - this will trigger validation on the whole form
      const fields = Object.keys(FIELD_NAMES).map((keyName: string): string => {
        return FIELD_NAMES[keyName];
      });
      const viewed = new Set(fields);
      return { viewed };
    }

    return state;
  }

  public componentDidMount(): void {
    // Since the same as emergency will come from the backend as a null object, and it needs to default to true, do it once the component has mounted.
    const { altContactInfo, autosave } = this.props;

    if (altContactInfo.sameAsEmergency === null) {
      autosave(altContactInfo, { id: altContactInfo.id, sameAsEmergency: true });
    }
  }

  public render(): JSX.Element {
    const { altContactInfo, genderRefData, saveStatus, isPso } = this.props;
    return (
      <RegistrationContainer
        patient={altContactInfo}
        genderRefData={genderRefData}
        isPso={isPso}
        saveStatus={saveStatus}>
        {this.renderContents()}
      </RegistrationContainer>
    );
  }

  private renderContents = (): JSX.Element => {
    const { altContactInfo, relationshipsReferenceData } = this.props;
    const { emergencyContact, nextOfKinContact } = altContactInfo;

    const validationObject = this.validateObject(altContactInfo);

    return (
      <div className="form-page">
        <form className="form-container">
          <div className="form-heading alternate-contact">{E_FORM_HEADING}</div>
          <SectionField
            isValid={validationObject && validationObject[EMERGENCY_VALIDATION.FIRST_NAME] ? false : true}
            htmlFor={FIELD_NAMES.E_FIRST_NAME}
            title={FIELD_HEADINGS.E_FIRST_NAME}>
            <FreeTextField
              disabled={altContactInfo.lock && altContactInfo.lock.readOnly}
              inputName={FIELD_NAMES.E_FIRST_NAME}
              maxLength={FIELD_SIZE_LIMITS.E_FIRST_NAME}
              placeholder={FIELD_PLACEHOLDERS.E_FIRST_NAME}
              defaultValue={emergencyContact.firstName}
              onBlur={(e): void => this.autosave(FIELD_NAMES.E_FIRST_NAME, BASE_KEYS.FIRST_NAME, e.target.value)}
              errors={
                validationObject && validationObject[EMERGENCY_VALIDATION.FIRST_NAME]
                  ? validationObject[EMERGENCY_VALIDATION.FIRST_NAME]
                  : undefined
              }
            />
          </SectionField>
          <SectionField
            isValid={validationObject && validationObject[EMERGENCY_VALIDATION.LAST_NAME] ? false : true}
            htmlFor={FIELD_NAMES.E_LAST_NAME}
            title={FIELD_HEADINGS.E_LAST_NAME}>
            <FreeTextField
              disabled={altContactInfo.lock && altContactInfo.lock.readOnly}
              inputName={FIELD_NAMES.E_LAST_NAME}
              maxLength={FIELD_SIZE_LIMITS.E_LAST_NAME}
              placeholder={FIELD_PLACEHOLDERS.E_LAST_NAME}
              defaultValue={emergencyContact.lastName}
              onBlur={(e): void => this.autosave(FIELD_NAMES.E_LAST_NAME, BASE_KEYS.LAST_NAME, e.target.value)}
              errors={
                validationObject && validationObject[EMERGENCY_VALIDATION.LAST_NAME]
                  ? validationObject[EMERGENCY_VALIDATION.LAST_NAME]
                  : undefined
              }
            />
          </SectionField>
          <SectionField
            isValid={validationObject && validationObject[EMERGENCY_VALIDATION.RELATIONSHIP] ? false : true}
            htmlFor={FIELD_NAMES.E_RELATIONSHIP}
            title={FIELD_HEADINGS.E_RELATIONSHIP}>
            <DropDownField
              disabled={altContactInfo.lock && altContactInfo.lock.readOnly}
              inputName={FIELD_NAMES.E_RELATIONSHIP}
              placeholder={FIELD_PLACEHOLDERS.E_RELATIONSHIP}
              defaultValue={
                relationshipsReferenceData.find((relData: ListData) => relData.name === emergencyContact?.relationship)
                  ?.id ?? ''
              }
              options={relationshipsReferenceData}
              onChange={(e): void => this.autosave(FIELD_NAMES.E_RELATIONSHIP, BASE_KEYS.RELATIONSHIP, e.target.value)}
              errors={
                validationObject && validationObject[EMERGENCY_VALIDATION.RELATIONSHIP]
                  ? validationObject[EMERGENCY_VALIDATION.RELATIONSHIP]
                  : undefined
              }
            />
          </SectionField>
          <SectionField
            isValid={validationObject && validationObject[EMERGENCY_VALIDATION.MOBILE_PHONE] ? false : true}
            htmlFor={FIELD_NAMES.E_PRIMARY_PHONE_NUMBER}
            title={FIELD_HEADINGS.E_PRIMARY_PHONE_NUMBER}>
            <FreeTextField
              disabled={altContactInfo.lock && altContactInfo.lock.readOnly}
              inputName={FIELD_NAMES.E_PRIMARY_PHONE_NUMBER}
              maxLength={FIELD_SIZE_LIMITS.E_PRIMARY_PHONE_NUMBER}
              placeholder={FIELD_PLACEHOLDERS.E_PRIMARY_PHONE_NUMBER}
              defaultValue={emergencyContact.mobilePhoneNumber}
              onBlur={(e): void =>
                this.autosave(FIELD_NAMES.E_PRIMARY_PHONE_NUMBER, BASE_KEYS.MOBILE_PHONE, e.target.value)
              }
              errors={
                validationObject && validationObject[EMERGENCY_VALIDATION.MOBILE_PHONE]
                  ? validationObject[EMERGENCY_VALIDATION.MOBILE_PHONE]
                  : undefined
              }
            />
          </SectionField>
          <SectionField
            isValid={validationObject && validationObject[EMERGENCY_VALIDATION.HOME_PHONE] ? false : true}
            htmlFor={FIELD_NAMES.E_SECONDARY_PHONE_NUMBER}
            title={FIELD_HEADINGS.E_SECONDARY_PHONE_NUMBER}>
            <FreeTextField
              disabled={altContactInfo.lock && altContactInfo.lock.readOnly}
              inputName={FIELD_NAMES.E_SECONDARY_PHONE_NUMBER}
              maxLength={FIELD_SIZE_LIMITS.E_SECONDARY_PHONE_NUMBER}
              placeholder={FIELD_PLACEHOLDERS.E_SECONDARY_PHONE_NUMBER}
              defaultValue={emergencyContact.homePhoneNumber}
              onBlur={(e): void =>
                this.autosave(FIELD_NAMES.E_SECONDARY_PHONE_NUMBER, BASE_KEYS.HOME_PHONE, e.target.value)
              }
              errors={
                validationObject && validationObject[EMERGENCY_VALIDATION.HOME_PHONE]
                  ? validationObject[EMERGENCY_VALIDATION.HOME_PHONE]
                  : undefined
              }
            />
          </SectionField>
          <SectionField
            isValid={validationObject && validationObject[EMERGENCY_VALIDATION.EMAIL_ADDRESS] ? false : true}
            htmlFor={FIELD_NAMES.E_EMAIL_ADDRESS}
            title={FIELD_HEADINGS.E_EMAIL_ADDRESS}
            hideOptional
            required={false}>
            <FreeTextField
              disabled={altContactInfo.lock && altContactInfo.lock.readOnly}
              inputName={FIELD_NAMES.E_EMAIL_ADDRESS}
              maxLength={FIELD_SIZE_LIMITS.E_EMAIL_ADDRESS}
              placeholder={FIELD_PLACEHOLDERS.E_EMAIL_ADDRESS}
              defaultValue={emergencyContact.email}
              onBlur={(e): void => this.autosave(FIELD_NAMES.E_EMAIL_ADDRESS, BASE_KEYS.EMAIL_ADDRESS, e.target.value)}
              errors={
                validationObject && validationObject[EMERGENCY_VALIDATION.EMAIL_ADDRESS]
                  ? validationObject[EMERGENCY_VALIDATION.EMAIL_ADDRESS]
                  : undefined
              }
              inputType={'email'}
            />
          </SectionField>
          <SectionField
            isValid={validationObject && validationObject.sameAsEmergency ? false : true}
            htmlFor={FIELD_NAMES.SAME_AS_EMERGENCY}
            title={FIELD_HEADINGS.SAME_AS_EMERGENCY}>
            <SegmentedInput
              disabled={altContactInfo.lock && altContactInfo.lock.readOnly}
              fieldName={FIELD_NAMES.SAME_AS_EMERGENCY}
              options={SegmentedInputBoolean}
              optionAreBoolean={true}
              defaultSelected={altContactInfo.sameAsEmergency === null ? false : altContactInfo.sameAsEmergency}
              itemSelected={(selectedItem): void => {
                const sameAsEmergency = selectedItem as boolean;
                const updateObject: Dictionary = {
                  id: altContactInfo.id,
                  sameAsEmergency,
                };

                if (!sameAsEmergency && !!nextOfKinContact) {
                  // Set next of kin contact to also reset the object if the same as emergency is set to false so that data is not carried over.
                  updateObject.nextOfKinContact = {
                    firstName: '',
                    lastName: '',
                    relationship: '',
                    mobilePhoneNumber: '',
                    homePhoneNumber: '',
                    email: '',
                  };
                }

                // Update the with the selection of adding another alternate contact
                this.props.autosave(altContactInfo, updateObject);
              }}
              errors={
                validationObject && validationObject.sameAsEmergency ? validationObject.sameAsEmergency : undefined
              }
            />
          </SectionField>
        </form>
        {altContactInfo.sameAsEmergency !== true && (
          <form className="form-container">
            <div className="form-heading alternate-contact">{N_FORM_HEADING}</div>
            <SectionField
              isValid={validationObject && validationObject[NEXT_OF_KIN_VALIDATION.FIRST_NAME] ? false : true}
              htmlFor={FIELD_NAMES.N_FIRST_NAME}
              title={FIELD_HEADINGS.N_FIRST_NAME}>
              <FreeTextField
                disabled={altContactInfo.lock && altContactInfo.lock.readOnly}
                inputName={FIELD_NAMES.N_FIRST_NAME}
                maxLength={FIELD_SIZE_LIMITS.N_FIRST_NAME}
                placeholder={FIELD_PLACEHOLDERS.N_FIRST_NAME}
                defaultValue={nextOfKinContact ? nextOfKinContact.firstName : ''}
                onBlur={(e): void =>
                  this.autosave(FIELD_NAMES.N_FIRST_NAME, BASE_KEYS.FIRST_NAME, e.target.value, true)
                }
                errors={
                  validationObject && validationObject[NEXT_OF_KIN_VALIDATION.FIRST_NAME]
                    ? validationObject[NEXT_OF_KIN_VALIDATION.FIRST_NAME]
                    : undefined
                }
              />
            </SectionField>
            <SectionField
              isValid={validationObject && validationObject[NEXT_OF_KIN_VALIDATION.LAST_NAME] ? false : true}
              htmlFor={FIELD_NAMES.N_LAST_NAME}
              title={FIELD_HEADINGS.N_LAST_NAME}>
              <FreeTextField
                disabled={altContactInfo.lock && altContactInfo.lock.readOnly}
                inputName={FIELD_NAMES.N_LAST_NAME}
                maxLength={FIELD_SIZE_LIMITS.N_LAST_NAME}
                placeholder={FIELD_PLACEHOLDERS.N_LAST_NAME}
                defaultValue={nextOfKinContact ? nextOfKinContact.lastName : ''}
                onBlur={(e): void => this.autosave(FIELD_NAMES.N_LAST_NAME, BASE_KEYS.LAST_NAME, e.target.value, true)}
                errors={
                  validationObject && validationObject[NEXT_OF_KIN_VALIDATION.LAST_NAME]
                    ? validationObject[NEXT_OF_KIN_VALIDATION.LAST_NAME]
                    : undefined
                }
              />
            </SectionField>
            <SectionField
              isValid={validationObject && validationObject[NEXT_OF_KIN_VALIDATION.RELATIONSHIP] ? false : true}
              htmlFor={FIELD_NAMES.N_RELATIONSHIP}
              title={FIELD_HEADINGS.N_RELATIONSHIP}>
              <DropDownField
                disabled={altContactInfo.lock && altContactInfo.lock.readOnly}
                inputName={FIELD_NAMES.N_RELATIONSHIP}
                placeholder={FIELD_PLACEHOLDERS.N_RELATIONSHIP}
                defaultValue={
                  relationshipsReferenceData.find(
                    (relData: ListData) => relData.name === nextOfKinContact?.relationship,
                  )?.id ?? ''
                }
                options={relationshipsReferenceData}
                onChange={(e): void =>
                  this.autosave(FIELD_NAMES.N_RELATIONSHIP, BASE_KEYS.RELATIONSHIP, e.target.value, true)
                }
                errors={
                  validationObject && validationObject[NEXT_OF_KIN_VALIDATION.RELATIONSHIP]
                    ? validationObject[NEXT_OF_KIN_VALIDATION.RELATIONSHIP]
                    : undefined
                }
              />
            </SectionField>
            <SectionField
              isValid={validationObject && validationObject[NEXT_OF_KIN_VALIDATION.MOBILE_PHONE] ? false : true}
              htmlFor={FIELD_NAMES.N_PRIMARY_PHONE_NUMBER}
              title={FIELD_HEADINGS.N_PRIMARY_PHONE_NUMBER}>
              <FreeTextField
                disabled={altContactInfo.lock && altContactInfo.lock.readOnly}
                inputName={FIELD_NAMES.N_PRIMARY_PHONE_NUMBER}
                maxLength={FIELD_SIZE_LIMITS.N_PRIMARY_PHONE_NUMBER}
                placeholder={FIELD_PLACEHOLDERS.N_PRIMARY_PHONE_NUMBER}
                defaultValue={nextOfKinContact ? nextOfKinContact.mobilePhoneNumber : ''}
                onBlur={(e): void =>
                  this.autosave(FIELD_NAMES.N_PRIMARY_PHONE_NUMBER, BASE_KEYS.MOBILE_PHONE, e.target.value, true)
                }
                errors={
                  validationObject && validationObject[NEXT_OF_KIN_VALIDATION.MOBILE_PHONE]
                    ? validationObject[NEXT_OF_KIN_VALIDATION.MOBILE_PHONE]
                    : undefined
                }
              />
            </SectionField>
            <SectionField
              isValid={validationObject && validationObject[NEXT_OF_KIN_VALIDATION.HOME_PHONE] ? false : true}
              htmlFor={FIELD_NAMES.N_SECONDARY_PHONE_NUMBER}
              title={FIELD_HEADINGS.N_SECONDARY_PHONE_NUMBER}>
              <FreeTextField
                disabled={altContactInfo.lock && altContactInfo.lock.readOnly}
                inputName={FIELD_NAMES.N_SECONDARY_PHONE_NUMBER}
                maxLength={FIELD_SIZE_LIMITS.N_SECONDARY_PHONE_NUMBER}
                placeholder={FIELD_PLACEHOLDERS.N_SECONDARY_PHONE_NUMBER}
                defaultValue={nextOfKinContact ? nextOfKinContact.homePhoneNumber : ''}
                onBlur={(e): void =>
                  this.autosave(FIELD_NAMES.N_SECONDARY_PHONE_NUMBER, BASE_KEYS.HOME_PHONE, e.target.value, true)
                }
                errors={
                  validationObject && validationObject[NEXT_OF_KIN_VALIDATION.HOME_PHONE]
                    ? validationObject[NEXT_OF_KIN_VALIDATION.HOME_PHONE]
                    : undefined
                }
              />
            </SectionField>
            <SectionField
              isValid={validationObject && validationObject[NEXT_OF_KIN_VALIDATION.EMAIL_ADDRESS] ? false : true}
              htmlFor={FIELD_NAMES.N_EMAIL_ADDRESS}
              title={FIELD_HEADINGS.N_EMAIL_ADDRESS}
              hideOptional
              required={false}>
              <FreeTextField
                disabled={altContactInfo.lock && altContactInfo.lock.readOnly}
                inputName={FIELD_NAMES.N_EMAIL_ADDRESS}
                maxLength={FIELD_SIZE_LIMITS.N_EMAIL_ADDRESS}
                placeholder={FIELD_PLACEHOLDERS.N_EMAIL_ADDRESS}
                defaultValue={nextOfKinContact ? nextOfKinContact.email : ''}
                onBlur={(e): void =>
                  this.autosave(FIELD_NAMES.N_EMAIL_ADDRESS, BASE_KEYS.EMAIL_ADDRESS, e.target.value, true)
                }
                errors={
                  validationObject && validationObject[NEXT_OF_KIN_VALIDATION.EMAIL_ADDRESS]
                    ? validationObject[NEXT_OF_KIN_VALIDATION.EMAIL_ADDRESS]
                    : undefined
                }
                inputType={'email'}
              />
            </SectionField>
          </form>
        )}
      </div>
    );
  };

  /**
   * Autosave functionality that will call the props auto save function.
   */
  private autosave = (inputKey: string, graphKey: string, value: string | boolean, forNextOfKin?: boolean): void => {
    const { altContactInfo, autosave } = this.props;

    // Create the update object
    const updateObject: Dictionary = {
      id: altContactInfo.id,
    };

    // Create the inner object for the emergency contact or next of kin
    const innerObject: Dictionary = {};
    innerObject[graphKey] = value;

    // Add the inner object to the correct type
    let innerKey = 'emergencyContact';
    if (forNextOfKin) {
      innerKey = 'nextOfKinContact';
    }

    updateObject[innerKey] = innerObject;

    autosave(altContactInfo, updateObject);
    const viewed = this.state.viewed.add(inputKey);
    this.setState({ viewed });
  };

  private validateObject = (patient: UKPatientAltContact): any => {
    const { emergencyContact, nextOfKinContact } = patient;
    // Global rules for the validator, must match the patient interface keys
    const globalValidationRules: { [key: string]: object } = {
      'emergencyContact.firstName': {
        presence: {
          allowEmpty: true,
          message: FIELD_INCOMPLETE_INPUT_ERRORS.E_FIRST_NAME,
        },
        format: {
          pattern: "[A-Za-z\\s.,'-]*",
          message: FIELD_INVALID_INPUT_ERRORS.E_FIRST_NAME,
        },
      },
      'emergencyContact.lastName': {
        presence: {
          allowEmpty: true,
          message: FIELD_INCOMPLETE_INPUT_ERRORS.E_LAST_NAME,
        },
        format: {
          pattern: "[A-Za-z\\s.,'-]*",
          message: FIELD_INVALID_INPUT_ERRORS.E_LAST_NAME,
        },
      },
      'emergencyContact.relationship': {
        presence: {
          allowEmpty: true,
          message: FIELD_INCOMPLETE_INPUT_ERRORS.E_RELATIONSHIP,
        },
      },
      'emergencyContact.mobilePhoneNumber': {
        presence: {
          allowEmpty: true,
          message: FIELD_INCOMPLETE_INPUT_ERRORS.PHONE_NUMBER,
        },
        format: {
          pattern: emergencyContact.mobilePhoneNumber ? UK_PHONE_REGEX : '.*',
          message: FIELD_INVALID_INPUT_ERRORS.E_PRIMARY_PHONE_NUMBER,
        },
      },
      'emergencyContact.homePhoneNumber': {
        presence: {
          allowEmpty: true,
          message: FIELD_INCOMPLETE_INPUT_ERRORS.PHONE_NUMBER,
        },
        format: {
          pattern: emergencyContact.homePhoneNumber ? UK_PHONE_REGEX : '.*',
          message: FIELD_INVALID_INPUT_ERRORS.E_SECONDARY_PHONE_NUMBER,
        },
      },
      'emergencyContact.email': {
        presence: {
          allowEmpty: true,
        },
        format: {
          pattern: '^[a-zA-Z0-9\\_\\-\\.]+@[a-zA-Z0-9\\-\\.]+(\\.[a-zA-Z]+)+$|',
          message: FIELD_INVALID_INPUT_ERRORS.E_EMAIL_ADDRESS,
        },
      },
      sameAsEmergency: {
        presence: {
          allowEmpty: false,
          message: FIELD_INCOMPLETE_INPUT_ERRORS.SAME_AS_EMERGENCY,
        },
      },
      'nextOfKinContact.firstName': {
        presence: {
          allowEmpty: true,
          message: FIELD_INCOMPLETE_INPUT_ERRORS.N_FIRST_NAME,
        },
        format: {
          pattern: "[A-Za-z\\s.,'-]*",
          message: FIELD_INVALID_INPUT_ERRORS.N_FIRST_NAME,
        },
      },
      'nextOfKinContact.lastName': {
        presence: {
          allowEmpty: true,
          message: FIELD_INCOMPLETE_INPUT_ERRORS.N_LAST_NAME,
        },
        format: {
          pattern: "[A-Za-z\\s.,'-]*",
          message: FIELD_INVALID_INPUT_ERRORS.N_LAST_NAME,
        },
      },
      'nextOfKinContact.relationship': {
        presence: {
          allowEmpty: true,
          message: FIELD_INCOMPLETE_INPUT_ERRORS.N_RELATIONSHIP,
        },
      },
      'nextOfKinContact.mobilePhoneNumber': {
        presence: {
          allowEmpty: true,
          message: FIELD_INCOMPLETE_INPUT_ERRORS.PHONE_NUMBER,
        },
        format: {
          pattern: nextOfKinContact && nextOfKinContact.mobilePhoneNumber ? UK_PHONE_REGEX : '.*',
          message: FIELD_INVALID_INPUT_ERRORS.N_PRIMARY_PHONE_NUMBER,
        },
      },
      'nextOfKinContact.homePhoneNumber': {
        presence: {
          allowEmpty: true,
          message: FIELD_INCOMPLETE_INPUT_ERRORS.PHONE_NUMBER,
        },
        format: {
          pattern: nextOfKinContact && nextOfKinContact.homePhoneNumber ? UK_PHONE_REGEX : '.*',
          message: FIELD_INVALID_INPUT_ERRORS.N_SECONDARY_PHONE_NUMBER,
        },
      },
      'nextOfKinContact.email': {
        presence: {
          allowEmpty: true,
        },
        format: {
          pattern: '^[a-zA-Z0-9\\_\\-\\.]+@[a-zA-Z0-9\\-\\.]+(\\.[a-zA-Z]+)+$|',
          message: FIELD_INVALID_INPUT_ERRORS.N_EMAIL_ADDRESS,
        },
      },
    };

    if (!!emergencyContact.mobilePhoneNumber && !emergencyContact.homePhoneNumber) {
      // @ts-ignore
      globalValidationRules[EMERGENCY_VALIDATION.HOME_PHONE].format.pattern = '.*';
    }
    if (!!emergencyContact.homePhoneNumber && !emergencyContact.mobilePhoneNumber) {
      // @ts-ignore
      globalValidationRules[EMERGENCY_VALIDATION.MOBILE_PHONE].format.pattern = '.*';
    }

    if (nextOfKinContact) {
      if (!!nextOfKinContact.mobilePhoneNumber && !nextOfKinContact.homePhoneNumber) {
        // @ts-ignore
        globalValidationRules[NEXT_OF_KIN_VALIDATION.HOME_PHONE].format.pattern = '.*';
      }
      if (!!nextOfKinContact.homePhoneNumber && !nextOfKinContact.mobilePhoneNumber) {
        // @ts-ignore
        globalValidationRules[NEXT_OF_KIN_VALIDATION.MOBILE_PHONE].format.pattern = '.*';
      }
    }

    // Ensure fields that been viewed only have validation run on them
    const specificValidationRules: { [key: string]: object } = {};
    for (const viewed of this.state.viewed.keys()) {
      const convertedKey = this.convertViewedKeyToValidationKey(viewed);
      specificValidationRules[convertedKey] = globalValidationRules[convertedKey];
    }
    // Disable pre-appending of argument name to error messages
    const disableFullMessages = { fullMessages: false };
    // Run validation on all the fields
    return validate(patient, specificValidationRules, disableFullMessages);
  };

  /**
   * Since the validation key is now using dot notation, need to convert the input key to something that the validation can use.
   * @param {string} key The key to be converted.
   * @return {string} The converted viewed key to a dot notation.
   */
  private convertViewedKeyToValidationKey = (key: string): string => {
    let type: string | undefined = undefined;
    if (key.includes(BASE_KEYS.NEXT_OF_KIN)) {
      type = BASE_KEYS.NEXT_OF_KIN;
    } else if (key.includes(BASE_KEYS.EMERGENCY)) {
      type = BASE_KEYS.EMERGENCY;
    }

    if (type === undefined) {
      // They key does not include emergencyContact or nextOfKinContact, return the key itself back
      return key;
    }

    // Add a period and lower case the following letter after the end of the emergency contact
    let fieldName = key.slice(type.length);
    fieldName = fieldName[0].toLowerCase() + fieldName.substr(1);
    return `${type}.${fieldName}`;
  };
}

// @ts-ignore
export default UKRegistrationAltContact;
