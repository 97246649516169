// eslint-disable-next-line no-use-before-define
import { gql } from '@apollo/client';
import { Query } from '@apollo/client/react/components';
import { withApollo, WithApolloClient } from '@apollo/client/react/hoc';
import { DeleteDocumentMutation } from 'op-components/FileUploadField/FileUploadQueries';
import { Component } from 'react';

import { RouteComponentProps } from 'react-router-dom';
import { LoadingSpinner } from 'shared-components/components';
import RORegAttachments from './RORegAttachments';

export const PATIENT_QUERY = gql`
  query patientQuery($id: ID) {
    patient(id: $id) {
      id
      ida
      attachments {
        id
        dateAttached
        encounterDate
        documentType
        submitted
        file
        url
        filename
        docStatus
        filesize
      }
      fullName
    }
    user {
      id
      isPso
    }
    documentTypeRefData: attachmentTypes {
      id
      conceptCode
      conceptDisplay
    }
  }
`;

interface Props extends RouteComponentProps, WithApolloClient<{}> {}

interface RORefData {
  id: string;
  conceptCode: string;
  conceptDisplay: string;
}
interface ListData {
  id: string;
  name: string;
}

interface State {
  fileUploaded: boolean;
}

class RORegAttachmentsApollo extends Component<Props, State> {
  public constructor(props: Props) {
    super(props);
    this.state = {
      fileUploaded: true,
    };
  }

  private completeFileUpload = () => {
    this.setState({ fileUploaded: true });
  };

  private incompleteFileUpload = () => {
    this.setState({ fileUploaded: false });
  };

  private formatRefData = (refData: RORefData[]): ListData[] => {
    return refData.map((value) => ({
      id: value.conceptCode,
      name: value.conceptDisplay,
    }));
  };

  private deleteDocument = (attachmentPk: string) => {
    const match: any = this.props.match;
    const patientId: string = match.params.id;
    const variables = {
      id: patientId,
    };
    this.props.client?.mutate({
      mutation: DeleteDocumentMutation,
      variables: {
        attachmentPk,
      },
      refetchQueries: [
        {
          query: PATIENT_QUERY,
          variables: variables,
          notifyOnNetworkStatusChange: true,
          fetchPolicy: 'cache-first',
        },
      ],
      awaitRefetchQueries: true,
    });
    //.then(() => refetchCallback());
  };
  public render(): JSX.Element {
    const match: any = this.props.match;
    const patientId: string = match.params.id;
    const variables = {
      id: patientId,
    };

    return (
      <Query
        query={PATIENT_QUERY}
        variables={variables}
        fetchPolicy="cache-and-network"
        notifyOnNetworkStatusChange={true}>
        {({ loading, data, refetch }: any): JSX.Element => {
          if (loading) return <LoadingSpinner />;
          if (data && data.patient && data.user) {
            return (
              <RORegAttachments
                onDelete={this.deleteDocument}
                patient={data.patient}
                fileUploaded={this.state.fileUploaded}
                onIncompleteFileUpload={this.incompleteFileUpload}
                onCompleteFileUpload={this.completeFileUpload}
                user={data.user}
                documentTypeRefData={this.formatRefData(data.documentTypeRefData)}
                refetchCallback={refetch}></RORegAttachments>
            );
          }
          return <div>Error loading...</div>;
        }}
      </Query>
    );
  }
}

export default withApollo<Props>(RORegAttachmentsApollo);
