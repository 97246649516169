// eslint-disable-next-line no-use-before-define
import React, { useEffect } from 'react';
import { RouteComponentProps, withRouter } from 'react-router-dom';
import { useQuery } from '@apollo/client';
import { MandatoryPatient } from 'op-interfaces';
import { LoadingSpinner } from 'shared-components/components';
import { RoleType } from 'op-enums';
import PatientFormSummary from './PatientFormSummary';
import { QUERY_NURSE, QUERY_PSO, QUERY_SUPERUSER } from './PatientFormSummaryQueries';
import { HeaderBar } from 'op-components';
import { useErrorModalContext } from 'op-contexts';

const { SUPERUSER, PSO, NURSE } = RoleType;

const mapQuery = {
  [NURSE]: QUERY_NURSE,
  [PSO]: QUERY_PSO,
  [SUPERUSER]: QUERY_SUPERUSER,
};

interface Patient {
  firstName: string | null;
  lastName: string | null;
}

export interface ProfileNavigation {
  patientFeatures: {
    distressThermometerEnabled: boolean;
    healthAssessmentEnabled: boolean;
    distressThermometerEotEnabled?: boolean;
    covidEnabled: boolean;
  };
}

export interface RegFormInfo extends MandatoryPatient {
  regFormStatus: string;
  updatedAt: string;
  updatedBy: string;
  horizonCenterId: string;
  diffMqData: any;
}
export interface FormInfo {
  id: string;
  updatedAt: string;
  updatedBy: string;
  status: string;
}

export interface ThaInfo {
  id: string;
  updatedAt: string;
  patient: Patient;
  status: string;
}

export interface pCCCInfo {
  id: string;
  updatedBy: string;
  updatedAt: string;
  status?: string;
}

export interface PreCTTriageInfo {
  id: string;
  updatedBy: string;
  updatedAt: string;
  status?: string;
}

interface Props extends RouteComponentProps<{ patientId: string }> {
  client: any;
  primaryRole: string;
  patientId: string;
}

const PatientFormSummaryData = ({ primaryRole, patientId }: Props): any => {
  const { setError, loading, data, error } = PatientFormSummaryQueryResponse(primaryRole, patientId);

  useEffect(() => {
    if (error) return setError();
  }, [error]);

  return (
    <>
      {loading && <LoadingSpinner relativeSpinner={true} />}
      {data && data.patient && primaryRole && (
        <PatientFormSummary
          primaryRole={primaryRole}
          patient={data.patient}
          haInfo={data.healthAssessment}
          dtInfo={data.distressThermometerInitial}
          dtEotInfo={data.distressThermometerEot}
          pCCCInfo={data.allPreCtChartCheck}
          preCtTriageInfo={data.allPreCtTriage}
          profile={data.profile}
          covidInfo={data.covidScreening}
          locationsPsoSystem={data.locationsPsoSystem}
          featureOptions={data.featureOptions}
        />
      )}
    </>
  );
};

const PatientFormSummaryApollo = (props: Props): JSX.Element => {
  const {
    match: {
      params: { patientId },
    },
  } = props;

  return (
    <HeaderBar>
      <PatientFormSummaryData {...props} patientId={patientId} />
    </HeaderBar>
  );
};

export const PatientFormSummaryQueryResponse = (primaryRole: any, patientId: any): any => {
  const QUERY = mapQuery[primaryRole];
  const { setError } = useErrorModalContext();
  const { loading, data, error } = useQuery(QUERY, {
    variables: { id: patientId, patientId: patientId },
    fetchPolicy: 'network-only',
  });

  return { setError, loading, data, error };
};

export default withRouter(PatientFormSummaryApollo);
