// eslint-disable-next-line no-use-before-define
import React from 'react';
import { useRouteMatch } from 'react-router';
import DocumentsUploadPage from './DocumentUploadPage';
import { CurrentAppConfig } from 'op-pages/RO/Careplan/AppConfig';
import { DocumentsPage } from './DocumentsPage';

interface DocumentsPageRouteProps {
  id: string;
  page: string;
}

const DocumentsPageRouter = (): JSX.Element => {
  const match = useRouteMatch<DocumentsPageRouteProps>();
  const { id: patientId, page } = match.params;

  if (page === 'view') return <DocumentsPage />;
  if (page === 'upload' && CurrentAppConfig.PatientSummary.RODocumentUpload)
    return <DocumentsUploadPage patientId={patientId} />;
  return <></>;
};

export default DocumentsPageRouter;
