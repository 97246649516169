import { gql } from '@apollo/client';

export const CACHE_ERROR_MSG_QUERY = gql`
  query GlobalErrorMessage {
    error {
      message
      statusCode
    }
  }
`;

export const GET_CONTENT_SHOWN = gql`
  query GetContentShown {
    contentShown
  }
`;
