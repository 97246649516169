import { isUs } from 'op-utils/helpers';
import { addressLabelForRegion } from 'shared-components/enums';
const addressLabels = addressLabelForRegion();

export const PDF_TEXT = isUs()
  ? 'Once submittted, a PDF of this registration form will be submitted and patient information updated.'
  : 'Once submitted, a PDF of this registration form will be sent to EMR. Patient information will be updated in both systems.';

export const FORM_TEXT = 'Please ensure all your details are correct.';
export const BASIC_FIELDS = {
  fields: [
    { key: 'resolvedNamePrefix', label: 'Title' },
    { key: 'firstName', label: 'First name' },
    { key: 'middleName', label: 'Middle name' },
    { key: 'lastName', label: 'Last name' },
    { key: 'preferredName', label: 'Preferred name' },
    { key: 'patientGender', label: 'Sex' },
    { key: 'dob', label: 'Date of birth' },
    { key: 'formattedSsn', label: 'Social Security Number (SSN)' },
  ],
  title: 'Basic Details',
  path: 'basic',
};

export const CONTACT_FIELDS = {
  fields: [
    { key: 'primaryPhone', label: 'Mobile' },
    { key: 'secondaryPhone', label: 'Home phone' },
    { key: 'email', label: 'Email' },
    { key: 'employer', label: 'Employer' },
    { key: 'workPhone', label: 'Work phone' },
  ],
  title: 'Contact Details',
  path: 'contact',
};

export const REFERRER_FIELDS = {
  fields: [
    { key: 'primaryProvider', label: 'Primary provider' },
    { key: 'referringProvider', label: 'Referring provider' },
    { key: 'advancedDirective', label: 'Do you have an Advanced Directive?' },
    { key: 'powerOfAttorney', label: 'Do you have a medical Durable Power of Attorney?' },
    { key: 'livingWill', label: 'Do you have a Living Will?' },
  ],
  title: 'Referrers',
  path: 'referrers',
};

export const ADDRESS_FIELDS = {
  fields: [
    { key: 'address.line1', label: addressLabels.line1 },
    { key: 'address.line2', label: addressLabels.line2 },
    { key: 'address.city', label: addressLabels.city },
    { key: 'address.state', label: addressLabels.state },
    { key: 'address.country', label: addressLabels.country },
    { key: 'address.postcode', label: addressLabels.postcode },
    {
      key: 'inHospitalHospiceFacility',
      label: 'Are you currently admitted to a hospital or enrolled in a Hospice or Skilled Nursive Facility?',
    },
  ],
  facilityFields: [
    { key: 'facilityName', label: 'Facility Name' },
    { key: 'facilityContactNumber', label: 'Contact number' },
    { key: 'facilityAddress.line1', label: addressLabels.line1 },
    { key: 'facilityAddress.line2', label: addressLabels.line2 },
    { key: 'facilityAddress.city', label: addressLabels.city },
    { key: 'facilityAddress.state', label: addressLabels.state },
    { key: 'facilityAddress.country', label: addressLabels.country },
    { key: 'facilityAddress.postcode', label: addressLabels.postcode },
  ],
  title: 'Address',
  path: 'address',
};

export const INSURANCE_FIELDS = {
  title: 'Insurance & Other Benefits',
  path: 'insurance',
  fields: [
    { key: 'primaryInsurance.fundName', label: 'Health fund name' },
    { key: 'primaryInsurance.planId', label: 'Plan ID' },
    { key: 'primaryInsurance.group', label: 'Group #' },
    { key: 'primaryInsurance.phoneNumber', label: 'Phone number' },
    { key: 'primaryInsurance.policyHolder', label: 'Policyholder' },
    { key: 'primaryInsurance.policyDob', label: 'Policyholder date of birth' },
    { key: 'primaryInsurance.guarantor', label: 'Guarantor' },
    { key: 'primaryInsurance.guarantorRelationship', label: 'Guarantor relationship' },
  ],
  secondaryFields: [
    { key: 'secondaryInsurance.fundName', label: 'Health fund name' },
    { key: 'secondaryInsurance.planId', label: 'Plan ID' },
    { key: 'secondaryInsurance.group', label: 'Group #' },
    { key: 'secondaryInsurance.phoneNumber', label: 'Phone number' },
    { key: 'secondaryInsurance.policyHolder', label: 'Policyholder' },
    { key: 'secondaryInsurance.policyDob', label: 'Policyholder date of birth' },
    { key: 'secondaryInsurance.guarantor', label: 'Guarantor' },
    { key: 'secondaryInsurance.guarantorRelationship', label: 'Guarantor relationship' },
  ],
  secondaryConditional: {
    key: 'hasSecondaryInsurance',
    label: 'Do you have a secondary insurance?',
  },
  veteranConditional: {
    key: 'receiveVeteransAdminBenefits',
    label: 'Are you receiving benefits from the Veterans Administration?',
  },
  veteranFields: [
    { key: 'veteransAdminName', label: 'Veterans Administration name' },
    { key: 'veteransContactNumber', label: 'Contact number' },
    { key: 'veteransCity', label: addressLabels.city },
    { key: 'veteransStateProvince', label: addressLabels.state },
    { key: 'veteransZip', label: addressLabels.postcode },
  ],
};

export const DEMOGRAPHICS_FIELDS = {
  fields: [
    { key: 'primaryOccupation', label: 'What is/was your primary occupation?' },
    { key: 'maritalStatusValue', label: 'Marital status' },
  ],
  title: 'Demographic Details',
  path: 'demographics',
  spouseFields: [
    { key: 'spouseWorkingStatus', label: 'Spouse working status' },
    { key: 'spouseName', label: 'Spouse name' },
    { key: 'spouseDob', label: 'Spouse date of birth' },
    { key: 'formattedSpouseSsn', label: 'Spouse Social Security Number (SSN)' },
    { key: 'spouseAlternateAddressBool', label: "Is your spouse's address different to yours?" },
  ],
  spouseAlternateAddressFields: [
    { key: 'spouseAlternateAddress.line1', label: addressLabels.line1 },
    { key: 'spouseAlternateAddress.line2', label: addressLabels.line2 },
    { key: 'spouseAlternateAddress.city', label: addressLabels.city },
    { key: 'spouseAlternateAddress.state', label: addressLabels.state },
    { key: 'spouseAlternateAddress.country', label: addressLabels.country },
    { key: 'spouseAlternateAddress.postcode', label: addressLabels.postcode },
  ],
  raceField: [{ key: 'race', label: 'Which of the following best describes your race?' }],
  raceSpecifiedFields: [{ key: 'raceSpecified', label: 'Please specify' }],
  ancestryAndLanguageFields: [
    { key: 'ancestry', label: 'Please select one ethnic group that best describes your ancestry' },
    {
      key: 'niceLanguageAtHome',
      label: 'What language do you feel most comfortable using when discussing your healthcare?',
    },
  ],
};

export const SOCIAL_GEOGRAPHIC_FIELDS = {
  countryConditional: { key: 'countryOfBirth', label: 'Country of birth' },
  stateConditional: { key: 'stateOfBirth', label: 'In which state where you born?' },
  fields: [
    {
      key: 'areaLivedMost',
      label: 'In what area did you live most of your life?',
    },
    { key: 'currentStateHowLong', label: 'How long have you lived in your current state of residence?' },
    { key: 'inThisStateAllYear', label: 'Do you live in this state all year round?' },
  ],
  addressFields: [
    { key: 'alternateAddress.line1', label: addressLabels.line1 },
    { key: 'alternateAddress.line2', label: addressLabels.line2 },
    { key: 'alternateAddress.city', label: addressLabels.city },
    { key: 'alternateAddress.state', label: addressLabels.state },
    { key: 'alternateAddress.country', label: addressLabels.country },
    { key: 'alternateAddress.postcode', label: addressLabels.postcode },
    { key: 'alternateAddressPhone', label: 'Alternate phone number' },
  ],
  title: 'Social Geographic History',
  path: 'socialHistory',
};

export const FEEDBACK_FIELDS = {
  heardAboutUs: { key: 'heardAboutUs', label: 'How did you hear about us?' },
  heardAboutUsOther: { key: 'heardAboutUsOther', label: 'Please specify' },
  fields: [
    {
      key: 'internetForResearch',
      label: 'When conducting your own research, how often do you use the internet for gathering information?',
    },
    { key: 'agreeToSurvey', label: 'May we contact you by mail, email, SMS or telephone for our survey?' },
  ],
  title: 'Your Feedback',
  path: 'feedback',
};

export const EMERGENCY_CONTACT_FIELDS = {
  fields: [
    { key: 'emergencyContact.firstName', label: 'First name' },
    { key: 'emergencyContact.lastName', label: 'Last name' },
    { key: 'emergencyContact.relationship', label: 'Relationship' },
    { key: 'emergencyContact.mobilePhoneNumber', label: 'Mobile' },
    { key: 'emergencyContact.homePhoneNumber', label: 'Landline' },
    { key: 'emergencyContact.address.line1', label: addressLabels.line1 },
    { key: 'emergencyContact.address.line2', label: addressLabels.line2 },
    { key: 'emergencyContact.address.city', label: addressLabels.city },
    { key: 'emergencyContact.address.state', label: addressLabels.state },
    { key: 'emergencyContact.address.country', label: addressLabels.country },
    { key: 'emergencyContact.address.postcode', label: addressLabels.postcode },
    { key: 'guardian', label: 'Guardian' },
    { key: 'guardianRelationship', label: 'Guardian relationship' },
  ],
  title: 'Emergency Contact',
  path: 'emergencyContact',
};
