// eslint-disable-next-line no-use-before-define
import BaseSelect, { SelectOptionType, Props as BaseDropdownProps } from './BaseSelect';
import FormRow from '../FormRow/FormRow';
//import FormRow from 'shared-components/components/TempFormRow';

interface Props extends BaseDropdownProps {
  id: string;
  fieldlabel: string;
  options: SelectOptionType[];
  helperText?: string;
  indent?: boolean;
}

const ROSelect = (props: Props): JSX.Element => {
  const { indent, ...rest } = props;
  const { fieldlabel, id, required } = rest;
  return (
    <FormRow {...{ fieldlabel, id, required }} indent={indent}>
      <BaseSelect {...rest} />
    </FormRow>
  );
};

export default ROSelect;
