// eslint-disable-next-line no-use-before-define
import React, { Component, Fragment } from 'react';
import InputMask from 'react-input-mask';
import classNames from 'classnames';

import { ErrorInfo } from 'shared-components/components/FormFields';

interface Props {
  mask: string;
  inputName: string;
  defaultValue?: string;
  onBlur?: (event: React.FocusEvent<HTMLInputElement>) => void;
  onChange?: (event: React.ChangeEvent<HTMLInputElement>) => void;
  errors?: string[];
  displayInputError?: boolean;
  inputType?: string;
  elementRef?: (ref: any) => void;
  disabled?: boolean;
  autoComplete?: string;
  dataTestId?: string;
}

class MaskField extends Component<Props> {
  public static defaultProps = {
    displayInputError: false,
    disabled: false,
  };
  public render(): JSX.Element {
    const {
      errors,
      inputName,
      inputType = 'text',
      displayInputError,
      elementRef,
      onChange,
      autoComplete = 'off',
      mask,
      dataTestId,
    } = this.props;

    const inputError = (errors && errors.length > 0) || displayInputError;

    return (
      <Fragment>
        <InputMask
          autoComplete={autoComplete}
          mask={mask}
          alwaysShowMask={true}
          name={inputName}
          className={classNames('form-fields-free-text-field ', { 'validation-error': inputError })}
          type={inputType}
          defaultValue={this.props.defaultValue}
          onBlur={this.props.onBlur}
          onChange={onChange}
          ref={elementRef}
          disabled={this.props.disabled}
          data-test-id={dataTestId}
        />
        <ErrorInfo errors={errors} />
      </Fragment>
    );
  }
}

export default MaskField;
