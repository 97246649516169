import React from 'react';
import { styled } from '@mui/system';
import { FieldProps, getIn } from 'formik';
import { HelperMessage, UserIcon } from 'shared-components/components';
import StyledFieldRow from './StyledFieldRow';
import { getRemSize } from 'shared-components/StyledComponents/functions';

interface IStyledInput {
  invalid: boolean;
}

const Container = styled('div')`
  display: flex;
  flex-direction: column;
`;

const StyledInput = styled('input')<IStyledInput>`
  border: 1px solid ${(props) => (props.invalid ? props.theme.palette.error.main : props.theme.palette.grey[300])};
  width: 100%;
  padding: 8px 8px;
  font-size: ${getRemSize(14)};
  border-radius: 4px;
  height: 40px;
`;

interface Props extends FieldProps {
  placeholder: string;
  updateMutation?: (value: string) => void;
  height?: string;
  lastUpdateUser?: string;
  width?: string;
  disabled?: boolean;
  inputProps?: any;
}

const HtmlInputField = ({
  field,
  form,
  placeholder,
  updateMutation,
  lastUpdateUser,
  width,
  inputProps,
  disabled = false,
}: Props): JSX.Element => {
  const { name, value, onBlur, onChange } = field;
  const { errors, touched, submitCount } = form;
  const fieldError = getIn(errors, name);
  const fieldTouched = getIn(touched, name);
  const isFieldInvalid = (submitCount > 0 || fieldTouched) && fieldError;

  const handleBlur = async (event: React.ChangeEvent<any>): Promise<any> => {
    onBlur(event);
    updateMutation && updateMutation(event.target.value);
  };

  return (
    <Container>
      <StyledFieldRow width={width}>
        <StyledInput
          name={name}
          value={value}
          invalid={isFieldInvalid}
          onChange={onChange}
          onBlur={(e: any): Promise<any> => handleBlur(e)}
          placeholder={placeholder}
          disabled={disabled}
          onInput={(e: React.ChangeEvent<HTMLInputElement>) => {
            if (inputProps?.maxLength) {
              e.target.value = e.target.value.slice(0, inputProps.maxLength);
            }
          }}
          {...inputProps}
        />
        {lastUpdateUser && <UserIcon userType={lastUpdateUser} />}
      </StyledFieldRow>
      {isFieldInvalid && <HelperMessage fieldName={name} fieldText={fieldError} helperType={'error'} fontSize={14} />}
    </Container>
  );
};

export default HtmlInputField;
