import React from 'react';
import { styled } from '@mui/system';
import { StandardDialog } from 'shared-components/components';
import { useTheme } from '@mui/material';

const DeleteFormText = styled('div')`
  margin-bottom: 40px;
  line-height: 24px;
`;

interface Props {
  deleteFormMutation: any;
  title: string;
  isOpen: boolean;
  setIsOpen: any;
  discard?: boolean;
}

const ModalDeleteForm = ({ deleteFormMutation, title, isOpen, setIsOpen, discard = true }: Props): JSX.Element => {
  const theme = useTheme();
  const deleteFormText = (
    <DeleteFormText>
      {discard ? (
        <>
          <div>
            You'll lose all the answers you have entered on this form. We can't recover them once you discard it.
          </div>
          <br />
          <div>Are you sure you want to discard?</div>
        </>
      ) : (
        <>
          <div>
            Deactivating this plan will complete and archive this plan, making it and all previous versions inactive.
          </div>
          <br />
          <div>You will need to create a new plan for this patient, if required in future.</div>
        </>
      )}
    </DeleteFormText>
  );

  return (
    <StandardDialog
      open={isOpen}
      title={title}
      onClose={() => setIsOpen(false)}
      submitText={discard ? 'Discard' : 'Deactivate plan'}
      destructiveSubmit
      onSubmit={() => {
        deleteFormMutation();
        setIsOpen(false);
      }}>
      {deleteFormText}
    </StandardDialog>
  );
};

export default ModalDeleteForm;
