import React from 'react';
import { styled } from '@mui/system';

import { ListOptionType } from 'shared-components/interfaces';
import ChevronDown from 'shared-components/images/Chevron_Down.svg';

interface Props {
  invalid?: boolean;
  name: string;
  onChange: (e: React.ChangeEvent<HTMLSelectElement>) => void;
  onBlur?: (e: React.ChangeEvent<HTMLSelectElement>) => void;
  options: ListOptionType[];
  placeholder: string;
  value: string;
  className?: string;
  removable?: boolean;
  width?: string;
  borderRadius?: string;
  padding?: string;
}

interface IStyledSelectField {
  background?: any;
  invalid?: boolean;
  removable?: boolean;
  width?: string;
  borderRadius?: string;
  padding?: string;
}

const StyledSelectField = styled('select')<IStyledSelectField>`
  background: url(${(props) => props.background});
  border-radius: ${(props: IStyledSelectField): string => (props.borderRadius ? props.borderRadius : '0')};
  padding: ${(props: IStyledSelectField): string => (props.padding ? props.padding : '8px 32px 8px 16px')};
  background-repeat: no-repeat;
  background-size: 28px 28px;
  background-position: calc(100% - 5px);
  max-width: ${(props: IStyledSelectField): string => (props.width ? props.width : '100%')};
  min-width: ${(props: IStyledSelectField): string => (props.width ? props.width : '100%')};

  border: 1px solid ${(props) => (props.invalid ? props.theme.palette.error.main : props.theme.palette.grey[300])};
  height: 42px;
  flex: 1;
  margin-right: ${(props: IStyledSelectField): string => (props.removable ? '12px' : '0px')};

  -moz-appearance: none;
  -webkit-appearance: none;
  &::-ms-expand {
    display: none;
  }
`;

const Select = ({
  invalid,
  name,
  onChange,
  onBlur,
  options,
  placeholder,
  value,
  className,
  removable,
  width,
  borderRadius,
  padding,
}: Props): JSX.Element => {
  return (
    <StyledSelectField
      background={ChevronDown}
      width={width}
      borderRadius={borderRadius}
      padding={padding}
      invalid={invalid}
      name={name}
      value={value || ''}
      onChange={onChange}
      onBlur={onBlur}
      className={className}
      removable={removable}>
      <option value={''} disabled={placeholder ? true : false}>
        {placeholder}
      </option>
      {options.map((option, index) => {
        return (
          <option key={index} value={option.value}>
            {option.label}
          </option>
        );
      })}
    </StyledSelectField>
  );
};

export default Select;
