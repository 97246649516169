// eslint-disable-next-line no-use-before-define
import { HAReviewSummaryItem } from 'op-classes';
import { PageTitle, SummaryCard, SummaryRow } from 'op-components';
import { RegistrationContext } from 'op-contexts';
import {
  BaseSelectItem,
  CancerItem,
  HAAllergy,
  HAIssueItem,
  HAMedication,
  HAOperationsDateItem,
  TreatmentItem,
} from 'op-interfaces';
import { appendZeroInFront, resolveListDataValue } from 'op-utils';
import {
  getInvalidSection,
  getValidationForSingleProperty,
  getValidationRule,
  INVALID_SECTIONS,
  validateHealthAssesment,
} from 'op-utils/HealthAssessmentValidation/HealthAssessmentValidation';
import React, { Component, Fragment } from 'react';

import { Edit, ErrorExclamation, Success } from 'shared-components/images';
import { ListData } from 'shared-components/interfaces';
import { DateTimeConverter, displayRawDate } from 'shared-components/utils';
import {
  CR_FIELDS,
  CR_MODAL_FIELDS,
  ILL_FIELDS,
  MHO_FIELDS,
  OP_FIELDS,
  SA_FIELDS,
  TREATMENT_TYPES,
} from '../constants';
import './HAReviewSummary.scss';

interface Props {
  patientId: string;
  haPatientSummary: HAReviewSummaryItem;
  intractableInfectionOrIllnessesRefData: ListData[];
  immunosuppresiveConditionsRefData: ListData[];
  internalDevicesRefData: ListData[];
  mobilityAidRefData: ListData[];
  diabetesRefData: ListData[];
  wellbeingIssueRefData: ListData[];
  treatmentTypeRefData: ListData[];
  treatmentStageRefData: ListData[];
  referenceData: ListData[];
  navigate: (link: string) => void;
  isPso: boolean;
}

const notProvidedDisplayValue = '-';

class HAReviewSummary extends Component<Props> {
  public static contextType = RegistrationContext;

  public componentDidMount() {
    const { isPso } = this.props;

    if (!isPso) {
      //@ts-ignore
      this.context.setHaSummaryVisited(true);
    }
  }

  public render(): JSX.Element {
    const { haPatientSummary, isPso } = this.props;
    const modifiedHA = { ...haPatientSummary };
    modifiedHA.smokingStopDate = `${appendZeroInFront(modifiedHA.smokingStopRawMonth)}${modifiedHA.smokingStopRawYear}`;

    const initialValidationResults = validateHealthAssesment(modifiedHA);
    const validationResults = this.reValidate(initialValidationResults);
    const pageTitle = isPso ? 'Review' : 'Review and submit';

    return (
      <div className="ha-review-summary-page">
        <PageTitle title={pageTitle} idPrefix="ha" />
        <div className="newspaper-container">
          {this.renderPatientInformation(validationResults)}
          {this.renderCancerSection(validationResults)}
          {this.renderIllnessSection(validationResults)}
          {this.renderMedicalHistoryOtherSection(validationResults)}
          {this.renderOperations(validationResults)}
          {this.renderSocialAssessment(validationResults)}
          {this.renderMedications(validationResults)}
          {this.renderAllergies(validationResults)}
          {this.renderOtherMedicalProblems(validationResults)}
        </div>
      </div>
    );
  }
  private renderArrow = (): JSX.Element => {
    return (
      <Fragment>
        <div className="line">
          <div className="arrow-down"></div>
        </div>
      </Fragment>
    );
  };

  private renderPatientInformation = (validationResults: any): JSX.Element => {
    const { haPatientSummary, navigate, isPso } = this.props;
    const patInfo = haPatientSummary.patient;
    const invalidSection = isPso && this.isInvalidSection(INVALID_SECTIONS.INFO, validationResults);
    const primaryIcon = isPso ? <Fragment></Fragment> : <Edit className="primary-icon" />;
    const showEdit = isPso ? false : true;
    return (
      <Fragment>
        <SummaryCard
          title="Patient information"
          primaryIcon={primaryIcon}
          secondaryIcon={this.validationIcon(invalidSection)}
          invalidContent={invalidSection}
          showEdit={showEdit}
          onClick={(): void => {
            if (!isPso) {
              navigate('info');
            }
          }}>
          <SummaryRow
            headingText="Patient Name"
            detailText={patInfo.fullName}
            displayAsError={false}
            displayAsOptional={false}
          />
          <SummaryRow
            headingText="Date of Birth"
            detailText={DateTimeConverter.getFormattedDateAsDDMonthYYYY(patInfo.dob)}
            displayAsError={false}
            displayAsOptional={false}
          />
          <SummaryRow
            headingText="Height"
            detailText={haPatientSummary.height ? `${haPatientSummary.height} cm` : notProvidedDisplayValue}
            displayAsError={isPso && !!validationResults?.height}
            displayAsOptional={false}
          />
          <SummaryRow
            headingText="Weight"
            detailText={haPatientSummary.weight ? `${haPatientSummary.weight} kg` : notProvidedDisplayValue}
            displayAsError={isPso && !!validationResults?.weight}
            displayAsOptional={false}
          />
        </SummaryCard>
      </Fragment>
    );
  };

  private renderMedicalHistoryOtherSection = (validationResults: any): JSX.Element => {
    const { haPatientSummary, mobilityAidRefData, wellbeingIssueRefData, navigate, isPso } = this.props;
    let invalidSection = this.isInvalidSection(INVALID_SECTIONS.MEDICAL_HISTORY_OTHER, validationResults);
    const primaryIcon = isPso ? <Fragment /> : <Edit className="primary-icon" />;
    const showEdit = !isPso;

    // Function that fixes validation for field with infinite inputs
    // i.e. mobilityAids and wellbeingIssues
    invalidSection = this.validateDropdownOther(haPatientSummary.mobilityAids, mobilityAidRefData, invalidSection);
    invalidSection = this.validateDropdownOther(
      haPatientSummary.wellbeingIssues,
      wellbeingIssueRefData,
      invalidSection,
    );

    invalidSection = isPso && invalidSection;

    return (
      <Fragment>
        <SummaryCard
          title="Medical history: other"
          primaryIcon={primaryIcon}
          secondaryIcon={this.validationIcon(invalidSection)}
          invalidContent={invalidSection}
          showEdit={showEdit}
          onClick={(): void => {
            if (!isPso) {
              navigate('history-other');
            }
          }}>
          <SummaryRow
            headingText={MHO_FIELDS.WALKING.TITLE}
            detailText={haPatientSummary.mobilityAid}
            displayAsError={isPso && !!validationResults?.mobilityAid}
            displayAsOptional={false}
          />
          {haPatientSummary.mobilityAid === 'YES' && this.renderArrow()}
          {haPatientSummary.mobilityAids.map((value: HAIssueItem, index: any): JSX.Element => {
            const detailText = resolveListDataValue(value.value, mobilityAidRefData);
            return (
              <SummaryRow
                key={index}
                headingText={`Mobility Aid ${index + 1}`}
                detailText={this.getDetailTextWithOther(detailText, value)}
                displayAsError={isPso && ((detailText === 'Other' && value.other === '') || detailText === '')}
                displayAsOptional={false}
                subQuestion
              />
            );
          })}

          <SummaryRow
            headingText={MHO_FIELDS.MENTAL.TITLE}
            detailText={haPatientSummary.wellbeingIssue}
            displayAsError={isPso && !!validationResults?.wellbeingIssue}
            displayAsOptional={false}
          />
          {haPatientSummary.wellbeingIssue === 'YES' && this.renderArrow()}
          {haPatientSummary.wellbeingIssues.map((value: HAIssueItem, index: any): JSX.Element => {
            const detailText = resolveListDataValue(value.value, wellbeingIssueRefData);
            return (
              <SummaryRow
                key={index}
                headingText={`Wellbeing or mental health issue ${index + 1}`}
                detailText={this.getDetailTextWithOther(detailText, value)}
                displayAsError={isPso && ((detailText === 'Other' && value.other === '') || detailText === '')}
                displayAsOptional={false}
                subQuestion
              />
            );
          })}

          <SummaryRow
            headingText={MHO_FIELDS.ACTIVE_INJURY.TITLE}
            detailText={haPatientSummary.activeInjury}
            displayAsError={isPso && !!validationResults?.activeInjury}
            displayAsOptional={false}
          />
          {haPatientSummary.activeInjury === 'YES' && this.renderArrow()}
          {haPatientSummary.activeInjury === 'YES' && (
            <SummaryRow
              testIdGroup="pressure"
              headingText={MHO_FIELDS.ACTIVE_INJURY_REASON.TITLE}
              detailText={haPatientSummary.activeInjuryReason}
              displayAsError={isPso && !!validationResults?.activeInjuryReason}
              displayAsOptional={false}
              subQuestion
            />
          )}

          <SummaryRow
            headingText={MHO_FIELDS.IN_PAIN.TITLE}
            detailText={haPatientSummary.inPain}
            displayAsError={
              isPso && (validationResults && validationResults.inPain ? validationResults.inPain : undefined)
            }
            displayAsOptional={false}
          />
          {haPatientSummary.inPain === 'YES' && this.renderArrow()}
          {haPatientSummary.inPain === 'YES' && (
            <SummaryRow
              testIdGroup="pain"
              headingText={MHO_FIELDS.IN_PAIN_REASON.TITLE}
              detailText={haPatientSummary.inPainReason}
              displayAsError={isPso && !!validationResults?.inPainReason}
              displayAsOptional={false}
              subQuestion
            />
          )}

          <SummaryRow
            headingText={MHO_FIELDS.FALLEN.TITLE}
            detailText={haPatientSummary.fallen}
            displayAsError={isPso && !!validationResults?.fallen}
            displayAsOptional={false}
          />
          {haPatientSummary.fallen === 'YES' && this.renderArrow()}
          {haPatientSummary.fallen === 'YES' && (
            <SummaryRow
              headingText={MHO_FIELDS.FALLEN_REASON.TITLE}
              detailText={haPatientSummary.fallenReason}
              displayAsError={isPso && !!validationResults?.fallenReason}
              displayAsOptional={false}
              subQuestion
            />
          )}

          <SummaryRow
            headingText={MHO_FIELDS.ADVANCED.TITLE}
            detailText={haPatientSummary.advancedCareDirective}
            displayAsError={isPso && !!validationResults?.advancedCareDirective}
            displayAsOptional={false}
          />
          {haPatientSummary.advancedCareDirective === 'NO' && this.renderArrow()}
          {haPatientSummary.advancedCareDirective === 'NO' && (
            <SummaryRow
              headingText={MHO_FIELDS.INFO.TITLE}
              detailText={this.renderBoolContent(haPatientSummary.advancedCareAdditionalInformation)}
              displayAsError={false}
              displayAsOptional={false}
              subQuestion
            />
          )}

          <SummaryRow
            headingText={MHO_FIELDS.PREGNANT.TITLE}
            detailText={haPatientSummary.pregnant}
            displayAsError={isPso && !!validationResults?.pregnant}
            displayAsOptional={false}
          />
        </SummaryCard>
      </Fragment>
    );
  };

  private renderCancerSection = (validationResults: any): JSX.Element => {
    const { haPatientSummary, treatmentStageRefData, treatmentTypeRefData, navigate, isPso } = this.props;
    let invalidSection = this.isInvalidSection(INVALID_SECTIONS.CANCER, validationResults);
    const primaryIcon = isPso ? <Fragment></Fragment> : <Edit className="primary-icon" />;
    const showEdit = isPso ? false : true;

    const chemoAndImmunoTreatment = ['Chemotherapy', 'Immunotherapy'];

    haPatientSummary.cancer.forEach((value: CancerItem): void => {
      value.treatment.forEach((value: TreatmentItem): void => {
        const treatmentType = resolveListDataValue(value.treatmentType, treatmentTypeRefData);
        const treatmentStage = resolveListDataValue(value.stage, treatmentStageRefData);

        if (
          (chemoAndImmunoTreatment.includes(treatmentType) && treatmentStage === '') ||
          (treatmentType === 'Radiation therapy' && (value.hospital === '' || value.bodyPart === '')) ||
          (treatmentType === 'Other' && value.other === '') ||
          treatmentType === ''
        )
          invalidSection = true;
      });
    });

    invalidSection = isPso && invalidSection;

    return (
      <SummaryCard
        title="Medical history: cancer"
        primaryIcon={primaryIcon}
        secondaryIcon={this.validationIcon(invalidSection)}
        invalidContent={invalidSection}
        showEdit={showEdit}
        onClick={(): void => {
          if (!isPso) {
            navigate('history-cancer');
          }
        }}>
        <SummaryRow
          headingText={CR_FIELDS.CANCER.TITLE}
          detailText={haPatientSummary.previousCancer}
          displayAsError={isPso && !!validationResults?.previousCancer}
          displayAsOptional={false}
        />
        {haPatientSummary.previousCancer === 'YES' && this.renderArrow()}
        <div className="summary-section grey">
          {haPatientSummary.cancer.map((value: CancerItem, index: any): JSX.Element => {
            return (
              <Fragment key={index}>
                <div className="section-title">{`Cancer ${index + 1}`}</div>
                <SummaryRow
                  headingText={CR_MODAL_FIELDS.CANCER_TYPE.TITLE}
                  detailText={value.cancerType}
                  displayAsError={isPso && !value.cancerType}
                  displayAsOptional={false}
                  subQuestion
                />
                {value.treatment.map((value: TreatmentItem, index: any): JSX.Element => {
                  const treatmentType = resolveListDataValue(value.treatmentType, treatmentTypeRefData);
                  const treatmentStage = resolveListDataValue(value.stage, treatmentStageRefData);
                  const displayTreatmentDate =
                    treatmentStage === 'Unsure' || treatmentStage === 'Not started' ? false : true;
                  const treatDate = displayRawDate(
                    value.treatmentRawMonth,
                    value.treatmentRawYear,
                    value.treatmentRawDay,
                  );
                  const displayRadiotheraphy = treatmentType === TREATMENT_TYPES.RADIATION_THERAPY;
                  const displayOther = treatmentType === TREATMENT_TYPES.OTHER;
                  const displayChemoImmuno =
                    treatmentType === TREATMENT_TYPES.CHEMOTHERAPY || treatmentType === TREATMENT_TYPES.IMMUNOTHERAPY;

                  return (
                    <Fragment key={index}>
                      <div className="sub-section-title">{`Treatment ${index + 1}`}</div>
                      <SummaryRow
                        headingText={CR_MODAL_FIELDS.TREATMENT_TYPE.TITLE}
                        detailText={treatmentType}
                        displayAsError={isPso && !treatmentType}
                        displayAsOptional={false}
                        subQuestion
                      />
                      {displayOther && (
                        <SummaryRow
                          headingText={CR_MODAL_FIELDS.OTHER.TITLE}
                          detailText={value.other}
                          displayAsError={isPso && !value.other}
                          displayAsOptional={false}
                          subQuestion
                        />
                      )}
                      {displayRadiotheraphy && (
                        <SummaryRow
                          headingText={CR_MODAL_FIELDS.HOSPITAL.TITLE}
                          detailText={value.hospital}
                          displayAsError={isPso && !value.hospital}
                          displayAsOptional={false}
                          subQuestion
                        />
                      )}
                      {displayChemoImmuno && (
                        <SummaryRow
                          headingText={CR_MODAL_FIELDS.STAGE.TITLE}
                          detailText={treatmentStage}
                          displayAsError={isPso && !treatmentStage}
                          displayAsOptional={false}
                          subQuestion
                        />
                      )}
                      {(displayRadiotheraphy || displayChemoImmuno) && (
                        <SummaryRow
                          headingText={CR_MODAL_FIELDS.DOCTOR.TITLE}
                          detailText={value.doctor}
                          displayAsError={false}
                          displayAsOptional={true}
                          subQuestion
                        />
                      )}
                      {displayRadiotheraphy && (
                        <SummaryRow
                          headingText={CR_MODAL_FIELDS.BODY.TITLE}
                          detailText={value.bodyPart}
                          displayAsError={isPso && !value.bodyPart}
                          displayAsOptional={true}
                          subQuestion
                        />
                      )}
                      {(displayRadiotheraphy || displayChemoImmuno) && displayTreatmentDate && (
                        <SummaryRow
                          headingText={CR_MODAL_FIELDS.TREATMENT_DATE_HEADING}
                          detailText={treatDate}
                          displayAsError={false}
                          displayAsOptional
                          subQuestion
                        />
                      )}
                    </Fragment>
                  );
                })}
              </Fragment>
            );
          })}
        </div>

        <SummaryRow
          headingText={CR_FIELDS.RADIOTHERAPY.TITLE}
          detailText={haPatientSummary.otherRadiotherapy}
          displayAsError={isPso && !!validationResults?.otherRadiotherapy}
          displayAsOptional={false}
        />
        {haPatientSummary.otherRadiotherapy == 'YES' && this.renderArrow()}
        <div className="summary-section grey">
          {haPatientSummary.otherRadiotherapy === 'YES' &&
            haPatientSummary.otherRadiotherapyConditions.map((value, index: any): JSX.Element => {
              const testIdGroup = 'condition';
              const treatDateCondition = displayRawDate(
                value.treatmentRawMonth,
                value.treatmentRawYear,
                value.treatmentRawDay,
              );
              return (
                <Fragment key={index}>
                  <div className="sub-section-title">{`Condition ${index + 1}`}</div>
                  <SummaryRow
                    testIdGroup={testIdGroup}
                    headingText={'Condition'}
                    detailText={value.condition}
                    displayAsError={isPso && !value.condition}
                    displayAsOptional={false}
                    subQuestion
                  />
                  <SummaryRow
                    testIdGroup={testIdGroup}
                    headingText={'Hospital'}
                    detailText={value.hospital}
                    displayAsError={isPso && !value.hospital}
                    displayAsOptional={false}
                    subQuestion
                  />
                  <SummaryRow
                    testIdGroup={testIdGroup}
                    headingText={'Doctor'}
                    detailText={value.doctor}
                    displayAsError={isPso && !value.doctor}
                    displayAsOptional={false}
                    subQuestion
                  />
                  <SummaryRow
                    testIdGroup={testIdGroup}
                    headingText={'Body Part'}
                    detailText={value.bodyPart}
                    displayAsError={isPso && !value.bodyPart}
                    displayAsOptional={false}
                    subQuestion
                  />
                  <SummaryRow
                    testIdGroup={testIdGroup}
                    headingText={'Treatment Date'}
                    detailText={treatDateCondition}
                    displayAsError={isPso && treatDateCondition === notProvidedDisplayValue}
                    displayAsOptional={false}
                    subQuestion
                  />
                </Fragment>
              );
            })}
        </div>

        <SummaryRow
          headingText={CR_FIELDS.HISTORY.TITLE}
          detailText={haPatientSummary.familyHistory}
          displayAsError={isPso && !!validationResults?.familyHistory}
          displayAsOptional={false}
        />
        {haPatientSummary.familyHistory === 'YES' && this.renderArrow()}
        {haPatientSummary.familyHistory === 'YES' && (
          <SummaryRow
            testIdGroup={'history-cancer'}
            headingText={CR_FIELDS.HISTORY.DETAILS.TITLE}
            detailText={haPatientSummary.familyHistoryAnswer}
            displayAsError={isPso && !haPatientSummary.familyHistoryAnswer}
            displayAsOptional={false}
            subQuestion
          />
        )}
      </SummaryCard>
    );
  };

  private renderIllnessSection = (validationResults: any): JSX.Element => {
    const {
      haPatientSummary,
      internalDevicesRefData,
      immunosuppresiveConditionsRefData,
      intractableInfectionOrIllnessesRefData,
      diabetesRefData,
      referenceData,
      navigate,
      isPso,
    } = this.props;
    let invalidSection = this.isInvalidSection(INVALID_SECTIONS.MEDICAL_HISTORY_ILLNESS, validationResults);
    const primaryIcon = isPso ? <Fragment></Fragment> : <Edit className="primary-icon" />;
    const showEdit = isPso ? false : true;
    invalidSection = this.validateDropdownOther(
      haPatientSummary.internalDevices,
      internalDevicesRefData,
      invalidSection,
    );
    invalidSection = this.validateDropdownOther(haPatientSummary.diabetesResponses, diabetesRefData, invalidSection);
    invalidSection = this.validateDropdownOther(
      haPatientSummary.intractableInfectionOrIllnesses,
      intractableInfectionOrIllnessesRefData,
      invalidSection,
    );
    invalidSection = this.validateDropdownOther(
      haPatientSummary.immunosuppressiveConditions,
      immunosuppresiveConditionsRefData,
      invalidSection,
    );

    invalidSection = isPso && invalidSection;

    return (
      <Fragment>
        <SummaryCard
          title="Medical history: illness"
          primaryIcon={primaryIcon}
          secondaryIcon={this.validationIcon(invalidSection)}
          invalidContent={invalidSection}
          showEdit={showEdit}
          onClick={(): void => {
            if (!isPso) {
              navigate('history-illness');
            }
          }}>
          <SummaryRow
            headingText={ILL_FIELDS.DEVICES.TITLE}
            detailText={haPatientSummary.internalDevice}
            displayAsError={isPso && !!validationResults?.internalDevice}
            displayAsOptional={false}
          />
          {haPatientSummary.internalDevice === 'YES' && this.renderArrow()}
          {haPatientSummary.internalDevices.map((value: HAIssueItem, index: any): JSX.Element => {
            const detailText = resolveListDataValue(value.value, referenceData);
            return (
              <SummaryRow
                key={index}
                headingText={`Implanted / artificial internal device ${index + 1}`}
                detailText={this.getDetailTextWithOther(detailText, value)}
                displayAsError={isPso && ((detailText === 'Other' && value.other === '') || detailText === '')}
                displayAsOptional={false}
                subQuestion
              />
            );
          })}

          <SummaryRow
            headingText={ILL_FIELDS.CLOT.TITLE}
            detailText={haPatientSummary.bloodClot}
            displayAsError={isPso && !!validationResults?.bloodClot}
            displayAsOptional={false}
          />
          {haPatientSummary.bloodClot === 'YES' && this.renderArrow()}
          {haPatientSummary.bloodClot === 'YES' && (
            <SummaryRow
              testIdGroup={'blood-clot'}
              headingText={ILL_FIELDS.CLOT.DETAIL.TITLE}
              detailText={haPatientSummary.bloodClotAnswer}
              displayAsError={isPso && !!validationResults?.bloodClotAnswer}
              displayAsOptional={false}
              subQuestion
            />
          )}

          <SummaryRow
            headingText={ILL_FIELDS.PROBLEMS.TITLE}
            detailText={haPatientSummary.heartOrLungProblem}
            displayAsError={isPso && !!validationResults?.heartOrLungProblem}
            displayAsOptional={false}
          />
          {haPatientSummary.heartOrLungProblem === 'YES' && this.renderArrow()}
          {haPatientSummary.heartOrLungProblem === 'YES' && (
            <SummaryRow
              testIdGroup={'heart-lung'}
              headingText={ILL_FIELDS.PROBLEMS.DETAIL.TITLE}
              detailText={haPatientSummary.heartOrLungAnswer}
              displayAsError={isPso && !!validationResults?.heartOrLungAnswer}
              displayAsOptional={false}
              subQuestion
            />
          )}

          <SummaryRow
            headingText={ILL_FIELDS.PRESSURE.TITLE}
            detailText={haPatientSummary.highOrLowBloodPressure}
            displayAsError={isPso && !!validationResults?.highOrLowBloodPressure}
            displayAsOptional={false}
          />
          {haPatientSummary.highOrLowBloodPressure === 'YES' && this.renderArrow()}
          {haPatientSummary.highOrLowBloodPressure === 'YES' && (
            <SummaryRow
              testIdGroup={'blood-pressure'}
              headingText={ILL_FIELDS.PRESSURE.DETAIL.TITLE}
              detailText={haPatientSummary.bloodPressureAnswer}
              displayAsError={isPso && !!validationResults?.bloodPressureAnswer}
              displayAsOptional={false}
              subQuestion
            />
          )}

          <SummaryRow
            headingText={ILL_FIELDS.DIABETES.TITLE}
            detailText={haPatientSummary.diabetes}
            displayAsError={isPso && !!validationResults?.diabetes}
            displayAsOptional={false}
          />
          {haPatientSummary.diabetes === 'YES' && this.renderArrow()}
          {haPatientSummary.diabetesResponses.map((value: HAIssueItem, index: any): JSX.Element => {
            const detailText = resolveListDataValue(value.value, referenceData);
            return (
              <SummaryRow
                key={index}
                headingText={`${ILL_FIELDS.DIABETES.DETAIL.TITLE} ${index + 1}`}
                detailText={this.getDetailTextWithOther(detailText, value)}
                displayAsError={isPso && ((detailText === 'Other' && value.other === '') || detailText === '')}
                displayAsOptional={false}
                subQuestion
              />
            );
          })}

          <SummaryRow
            headingText={ILL_FIELDS.RESISTANT.TITLE}
            detailText={haPatientSummary.intractableInfectionOrIllness}
            displayAsError={isPso && !!validationResults?.intractableInfectionOrIllness}
            displayAsOptional={false}
          />
          {haPatientSummary.intractableInfectionOrIllness === 'YES' && this.renderArrow()}
          {haPatientSummary.intractableInfectionOrIllnesses.map((value: HAIssueItem, index: any): JSX.Element => {
            const detailText = resolveListDataValue(value.value, referenceData);
            const clearance = value.doctorClearance ? value.doctorClearance : '';

            return (
              <Fragment key={index}>
                <div className="sub-section-title">{`Illness or infection ${index + 1}`}</div>
                <SummaryRow
                  key={`resistant-${index}`}
                  headingText="Illness or infection"
                  detailText={this.getDetailTextWithOther(detailText, value)}
                  displayAsError={
                    isPso &&
                    ((detailText === 'Other' &&
                      (getValidationForSingleProperty(
                        { other: value.other },
                        { other: getValidationRule('other') },
                        'other',
                      ) ||
                        value.other === '')) ||
                      detailText === '')
                  }
                  displayAsOptional={false}
                  subQuestion
                />

                <SummaryRow
                  key={`clerance-${index}`}
                  headingText={`${ILL_FIELDS.RESISTANT.PERMISSION.TITLE}`}
                  detailText={clearance}
                  displayAsError={
                    isPso &&
                    getValidationForSingleProperty(
                      { doctorClearance: value.doctorClearance },
                      { doctorClearance: getValidationRule('doctorClearance') },
                      'doctorClearance',
                    )
                  }
                  displayAsOptional={false}
                  subQuestion
                />
              </Fragment>
            );
          })}

          <SummaryRow
            headingText={ILL_FIELDS.SUPPRESSIVE.TITLE}
            detailText={haPatientSummary.immunosuppressiveCondition}
            displayAsError={isPso && !!validationResults?.immunosuppressiveCondition}
            displayAsOptional={false}
          />
          {haPatientSummary.immunosuppressiveCondition === 'YES' && this.renderArrow()}
          {haPatientSummary.immunosuppressiveConditions.map((value: HAIssueItem, index: any): JSX.Element => {
            const detailText = resolveListDataValue(value.value, referenceData);
            return (
              <SummaryRow
                key={`resistant-${index}`}
                headingText={`Condition or disease ${index + 1}`}
                detailText={this.getDetailTextWithOther(detailText, value)}
                displayAsError={isPso && ((detailText === 'Other' && value.other === '') || detailText === '')}
                displayAsOptional={false}
                subQuestion
              />
            );
          })}
        </SummaryCard>
      </Fragment>
    );
  };

  private renderOperations = (validationResults: any): JSX.Element => {
    const { haPatientSummary, navigate, isPso } = this.props;
    const invalidSection = isPso && this.isInvalidSection(INVALID_SECTIONS.OPERATIONS, validationResults);
    const primaryIcon = isPso ? <Fragment></Fragment> : <Edit className="primary-icon" />;
    const showEdit = isPso ? false : true;

    return (
      <Fragment>
        <SummaryCard
          title="Operations"
          primaryIcon={primaryIcon}
          secondaryIcon={this.validationIcon(invalidSection)}
          invalidContent={isPso && invalidSection}
          showEdit={showEdit}
          onClick={(): void => {
            if (!isPso) {
              navigate('operations');
            }
          }}>
          <SummaryRow
            headingText={OP_FIELDS.PREV_OPERATION.TITLE}
            detailText={this.renderBoolContent(haPatientSummary.prevOperation)}
            displayAsError={isPso && !!validationResults?.prevOperation}
            displayAsOptional={false}
          />
          {haPatientSummary.prevOperation && this.renderArrow()}
          {haPatientSummary.prevOperation &&
            haPatientSummary.operations.map((value: HAOperationsDateItem, index: any): JSX.Element => {
              const opDate = displayRawDate(value.operationRawMonth, value.operationRawYear, value.operationRawDay);
              return (
                <Fragment key={index}>
                  <div className="sub-section-title">{`Operation ${index + 1}`}</div>
                  <SummaryRow
                    headingText="Operation"
                    detailText={value.operationSpecify}
                    displayAsError={
                      isPso &&
                      getValidationForSingleProperty(
                        { operationSpecify: value.operationSpecify },
                        { operationSpecify: getValidationRule('operationSpecify') },
                        'operationSpecify',
                      )
                    }
                    displayAsOptional={false}
                    subQuestion
                  />
                  <SummaryRow
                    headingText="Operation date"
                    detailText={opDate}
                    displayAsError={false}
                    displayAsOptional
                    subQuestion
                  />
                </Fragment>
              );
            })}
        </SummaryCard>
      </Fragment>
    );
  };

  private renderSocialAssessment = (validationResults: any): JSX.Element => {
    const { haPatientSummary, navigate, referenceData, isPso } = this.props;
    const alcoholFrequency = resolveListDataValue(haPatientSummary.alcoholFrequency, referenceData);
    const smokingFrequency = resolveListDataValue(haPatientSummary.smokingFrequency, referenceData);
    const exerciseFrequency = resolveListDataValue(haPatientSummary.exerciseFrequency, referenceData);

    const smokingStop = displayRawDate(
      haPatientSummary.smokingStopRawMonth,
      haPatientSummary.smokingStopRawYear,
      null,
      true,
    );
    const invalidSection = isPso && this.isInvalidSection(INVALID_SECTIONS.SOCIAL_ASSESSMENT, validationResults);
    const primaryIcon = isPso ? <Fragment></Fragment> : <Edit className="primary-icon" />;
    const showEdit = isPso ? false : true;

    return (
      <Fragment>
        <SummaryCard
          title="Social assessment"
          primaryIcon={primaryIcon}
          secondaryIcon={this.validationIcon(invalidSection)}
          invalidContent={invalidSection}
          showEdit={showEdit}
          onClick={(): void => {
            if (!isPso) {
              navigate('social');
            }
          }}>
          <SummaryRow
            headingText={SA_FIELDS.ALCOHOL.TITLE}
            detailText={this.renderBoolContent(haPatientSummary.alcoholBool)}
            displayAsError={isPso && !!validationResults?.alcoholBool}
            displayAsOptional={false}
          />
          {haPatientSummary.alcoholBool && this.renderArrow()}
          {haPatientSummary.alcoholBool && (
            <SummaryRow
              headingText={SA_FIELDS.ALCOHOL_FREQUENCY.TITLE}
              detailText={haPatientSummary.alcoholFrequency ? `${alcoholFrequency}` : notProvidedDisplayValue}
              displayAsError={isPso && !!validationResults?.alcoholFrequency}
              displayAsOptional={false}
              subQuestion
            />
          )}

          <SummaryRow
            headingText={SA_FIELDS.SMOKING.TITLE}
            detailText={this.renderBoolContent(haPatientSummary.smokingBool)}
            displayAsError={isPso && !!validationResults?.smokingBool}
            displayAsOptional={false}
          />

          {haPatientSummary.smokingBool && this.renderArrow()}
          {haPatientSummary.smokingBool && (
            <Fragment>
              <SummaryRow
                headingText={SA_FIELDS.SMOKING_HISTORY.TITLE}
                detailText={
                  haPatientSummary.smokingHistory
                    ? `${haPatientSummary.smokingHistory} year(s)`
                    : notProvidedDisplayValue
                }
                displayAsError={isPso && !!validationResults?.smokingHistory}
                displayAsOptional={false}
                subQuestion
              />
              <SummaryRow
                headingText={SA_FIELDS.SMOKING_FREQUENCY.TITLE}
                detailText={haPatientSummary.smokingFrequency ? `${smokingFrequency}` : notProvidedDisplayValue}
                displayAsError={isPso && !!validationResults?.smokingFrequency}
                displayAsOptional={false}
                subQuestion
              />
              <SummaryRow
                headingText={SA_FIELDS.SMOKING_STOP.TITLE}
                detailText={smokingStop}
                displayAsError={isPso && !!validationResults?.smokingStopDate}
                displayAsOptional
                subQuestion
              />
            </Fragment>
          )}

          <SummaryRow
            headingText={SA_FIELDS.EXERCISE.TITLE}
            detailText={this.renderBoolContent(haPatientSummary.exerciseBool)}
            displayAsError={isPso && !!validationResults?.exerciseBool}
            displayAsOptional={false}
          />
          {haPatientSummary.exerciseBool && this.renderArrow()}
          {haPatientSummary.exerciseBool && (
            <SummaryRow
              headingText={SA_FIELDS.EXERCISE_FREQUENCY.TITLE}
              detailText={haPatientSummary.exerciseFrequency ? `${exerciseFrequency}` : notProvidedDisplayValue}
              displayAsError={isPso && !!validationResults?.exerciseFrequency}
              displayAsOptional={false}
              subQuestion
            />
          )}

          <SummaryRow
            headingText={SA_FIELDS.ACCOMMODATION.TITLE}
            detailText={this.renderBoolContent(haPatientSummary.accommodationAssistance)}
            displayAsError={isPso && !!validationResults?.accommodationAssistance}
            displayAsOptional={false}
          />

          {haPatientSummary.accommodationAssistance && this.renderArrow()}
          {haPatientSummary.accommodationAssistance && (
            <SummaryRow
              headingText={SA_FIELDS.ACCOMMODATION_REASON.TITLE}
              detailText={haPatientSummary?.accommodationAssistanceReason ?? notProvidedDisplayValue}
              displayAsError={isPso && !!validationResults?.accommodationAssistanceReason}
              displayAsOptional={false}
              subQuestion
            />
          )}

          <SummaryRow
            headingText={SA_FIELDS.TRANSPORTATION.TITLE}
            detailText={this.renderBoolContent(haPatientSummary.transportationAssistance)}
            displayAsError={isPso && !!validationResults?.transportationAssistance}
            displayAsOptional={false}
          />
          {haPatientSummary.transportationAssistance && this.renderArrow()}
          {haPatientSummary.transportationAssistance && (
            <SummaryRow
              headingText={SA_FIELDS.TRANSPORTATION_REASON.TITLE}
              detailText={haPatientSummary?.transportationAssistanceReason ?? notProvidedDisplayValue}
              displayAsError={isPso && !!validationResults?.transportationAssistanceReason}
              displayAsOptional={false}
              subQuestion
            />
          )}
        </SummaryCard>
      </Fragment>
    );
  };

  private renderMedications = (validationResults: any): JSX.Element => {
    const { haPatientSummary, navigate, isPso } = this.props;
    const invalidSection = isPso && this.isInvalidSection(INVALID_SECTIONS.MEDICATION, validationResults);
    const primaryIcon = isPso ? <Fragment></Fragment> : <Edit className="primary-icon" />;
    const showEdit = isPso ? false : true;

    return (
      <Fragment>
        <SummaryCard
          title="Medications"
          primaryIcon={primaryIcon}
          secondaryIcon={this.validationIcon(invalidSection)}
          invalidContent={isPso && invalidSection}
          showEdit={showEdit}
          onClick={(): void => {
            if (!isPso) {
              navigate('medications');
            }
          }}>
          <SummaryRow
            headingText="Are you currently on any medications?"
            detailText={this.renderBoolContent(haPatientSummary.medicationBool)}
            displayAsError={isPso && !!validationResults?.medicationBool}
            displayAsOptional={false}
          />
          {haPatientSummary.medicationBool && this.renderArrow()}
          {haPatientSummary.medicationBool &&
            haPatientSummary.medications.map((value: HAMedication, index: any): JSX.Element => {
              return (
                <Fragment key={index}>
                  <div className="sub-section-title">{`Medication ${index + 1}`}</div>
                  <SummaryRow
                    headingText="Medication"
                    detailText={value.medicationName}
                    displayAsError={
                      isPso &&
                      getValidationForSingleProperty(
                        { medicationName: value.medicationName },
                        { medicationName: getValidationRule('medicationName') },
                        'medicationName',
                      )
                    }
                    displayAsOptional={false}
                    subQuestion
                  />
                  <SummaryRow
                    headingText="Dosage and frequency"
                    detailText={value.dosage}
                    displayAsError={
                      isPso &&
                      getValidationForSingleProperty(
                        { dosage: value.dosage },
                        { dosage: getValidationRule('dosage') },
                        'dosage',
                      )
                    }
                    displayAsOptional={false}
                    subQuestion
                  />
                </Fragment>
              );
            })}
        </SummaryCard>
      </Fragment>
    );
  };

  private renderAllergies = (validationResults: any): JSX.Element => {
    const { haPatientSummary, navigate, referenceData, isPso } = this.props;
    let invalidSection = this.isInvalidSection(INVALID_SECTIONS.ALLERGIES, validationResults);
    const primaryIcon = isPso ? <Fragment></Fragment> : <Edit className="primary-icon" />;
    const showEdit = isPso ? false : true;

    // This function validates the allergy card on the summary page
    if (haPatientSummary.allergies) {
      haPatientSummary.allergies.map((value: HAAllergy): void => {
        const sensitivity = resolveListDataValue(value.sensitivity, referenceData);
        const specify = value.specify;
        const area = value.area;
        const areaSpecifyBool = specify !== '' && area !== '';
        let reactionBool = true;
        if (sensitivity === 'Food') {
          invalidSection = false;
        } else {
          value.reaction.map((item: BaseSelectItem): void => {
            const rxn = resolveListDataValue(item.value, referenceData);
            reactionBool = (rxn === 'Other' && item.other !== '') || (rxn !== '' && rxn !== 'Other');
          });

          invalidSection = !(reactionBool && areaSpecifyBool);
        }
      });
    }

    invalidSection = isPso && invalidSection;

    return (
      <Fragment>
        <SummaryCard
          title="Allergies/sensitivities"
          primaryIcon={primaryIcon}
          secondaryIcon={this.validationIcon(invalidSection)}
          invalidContent={isPso && invalidSection}
          showEdit={showEdit}
          onClick={(): void => {
            if (!isPso) {
              navigate('allergies');
            }
          }}>
          <SummaryRow
            headingText="Do you have any allergies?"
            detailText={this.renderBoolContent(haPatientSummary.allergyBool)}
            displayAsError={isPso && !!validationResults?.allergyBool}
            displayAsOptional={false}
          />
          {haPatientSummary.allergyBool && this.renderArrow()}
          {haPatientSummary.allergyBool &&
            haPatientSummary.allergies.map((value: HAAllergy, index: any): JSX.Element => {
              const sensitivity = resolveListDataValue(value.sensitivity, referenceData);
              return (
                <Fragment key={index}>
                  <div className="sub-section-title">{`Allergy/sensitivity ${index + 1}`}</div>
                  <SummaryRow
                    headingText="Allergy/sensitivity"
                    detailText={sensitivity}
                    displayAsError={isPso && !sensitivity}
                    displayAsOptional={false}
                    subQuestion
                  />
                  {sensitivity !== 'Food' && sensitivity !== '' && (
                    <Fragment>
                      <SummaryRow
                        headingText="Specify"
                        detailText={value.specify}
                        displayAsError={isPso && !value.specify}
                        displayAsOptional={false}
                        subQuestion
                      />
                      {value.reaction.map((item: BaseSelectItem, rxnIndex: any): JSX.Element => {
                        const rxn = resolveListDataValue(item.value, referenceData);
                        return (
                          <SummaryRow
                            key={`reaction-${rxnIndex}`}
                            headingText="Reaction experienced"
                            detailText={this.getDetailTextWithOther(rxn, item)}
                            displayAsError={isPso && ((rxn === 'Other' && item.other === '') || rxn === '')}
                            displayAsOptional={false}
                            subQuestion
                          />
                        );
                      })}
                      <SummaryRow
                        headingText="Area affected"
                        detailText={value.area}
                        displayAsError={isPso && !value.area}
                        displayAsOptional={false}
                        subQuestion
                      />
                    </Fragment>
                  )}
                </Fragment>
              );
            })}
        </SummaryCard>
      </Fragment>
    );
  };

  private renderOtherMedicalProblems = (validationResults: any): JSX.Element => {
    const { haPatientSummary, navigate, isPso } = this.props;
    const invalidSection = isPso && this.isInvalidSection(INVALID_SECTIONS.OTHER_MEDICAL_PROBLEMS, validationResults);
    const primaryIcon = isPso ? <Fragment></Fragment> : <Edit className="primary-icon" />;
    const showEdit = isPso ? false : true;

    return (
      <Fragment>
        <SummaryCard
          title="Other medical problems"
          primaryIcon={primaryIcon}
          secondaryIcon={this.validationIcon(invalidSection)}
          invalidContent={isPso && invalidSection}
          showEdit={showEdit}
          onClick={(): void => {
            if (!isPso) {
              navigate('other');
            }
          }}>
          <SummaryRow
            headingText="Do you have any other medical problems?"
            detailText={this.renderBoolContent(haPatientSummary.otherMedProblemBool)}
            displayAsError={isPso && !!validationResults?.otherMedProblemBool}
            displayAsOptional={false}
          />

          {haPatientSummary.otherMedProblemBool && this.renderArrow()}
          {haPatientSummary.otherMedProblemBool && (
            <SummaryRow
              headingText="Other medical problems description"
              detailText={haPatientSummary.otherMedProblemDescription}
              displayAsError={isPso && !!validationResults?.otherMedProblemDescription}
              displayAsOptional={false}
              subQuestion={true}
            />
          )}
          <SummaryRow
            headingText="Do you use complementary or alternative medicine?"
            detailText={this.renderBoolContent(haPatientSummary.altMedicineBool)}
            displayAsError={isPso && !!validationResults?.altMedicineBool}
            displayAsOptional={false}
          />
          {haPatientSummary.altMedicineBool && this.renderArrow()}
          {haPatientSummary.altMedicineBool && (
            <SummaryRow
              headingText="Alternative medicine description"
              detailText={haPatientSummary.altMedicineDescription}
              displayAsError={isPso && !!validationResults?.altMedicineDescription}
              displayAsOptional={false}
              subQuestion={true}
            />
          )}
        </SummaryCard>
      </Fragment>
    );
  };
  private getDetailTextWithOther = (displayText: string, haIssueItem: HAIssueItem): string => {
    if (displayText === 'Other') {
      const specify = haIssueItem.other ? haIssueItem.other : notProvidedDisplayValue;
      return `${displayText}\n${specify}`;
    } else {
      return displayText;
    }
  };

  private renderBoolContent = (answer: boolean | null): string => {
    if (answer === null || typeof answer === 'undefined') return notProvidedDisplayValue;
    return answer ? 'Yes' : 'No';
  };

  private validationIcon = (isInvalidSection: boolean): JSX.Element => {
    const iconClass = 'secondary-icon';
    if (isInvalidSection) {
      return <ErrorExclamation className={`${iconClass} invalid-icon`} />;
    }

    return <Success className={`${iconClass} valid-icon`} />;
  };

  private isInvalidSection = (section: string, validationResult?: any): boolean => {
    if (validationResult && validationResult.invalidSections) {
      return validationResult.invalidSections.includes(section);
    }

    return false;
  };

  private reValidate = (validationResults: any): any => {
    // Remove the fields that are dependent on another question
    const { haPatientSummary } = this.props;
    const validAnswer = ['NO', 'UNSURE', false, null];
    const copyResults = validationResults;

    if (!validationResults) {
      return validationResults;
    }

    if (copyResults && 'invalidSections' in copyResults) {
      delete copyResults['invalidSections'];
    }

    const keyMap: { [key: string]: any } = {
      otherMedProblemDescription: haPatientSummary.otherMedProblemBool,
      altMedicineDescription: haPatientSummary.altMedicineBool,
      activeInjuryReason: haPatientSummary.activeInjury,
      inPainReason: haPatientSummary.inPain,
      fallenReason: haPatientSummary.fallen,
      alcoholFrequency: haPatientSummary.alcoholBool,
      smokingHistory: haPatientSummary.smokingBool,
      smokingFrequency: haPatientSummary.smokingBool,
      smokingStopDate: haPatientSummary.smokingBool,
      exerciseFrequency: haPatientSummary.exerciseBool,
      accommodationAssistanceReason: haPatientSummary.accommodationAssistance,
      transportationAssistanceReason: haPatientSummary.transportationAssistance,
      bloodClotAnswer: haPatientSummary.bloodClot,
      heartOrLungAnswer: haPatientSummary.heartOrLungProblem,
      allergies: haPatientSummary.allergyBool,
      pharmacyPhone: haPatientSummary.medicationBool,
      pharmacyName: haPatientSummary.medicationBool,
      medicationName: haPatientSummary.medicationBool,
      dosage: haPatientSummary.medicationBool,
      route: haPatientSummary.medicationBool,
      prescribingPhysician: haPatientSummary.medicationBool,
    };

    Object.keys(validationResults).forEach((key: string): void => {
      if (Object.keys(keyMap).includes(key) && validAnswer.includes(keyMap[key])) {
        delete copyResults[key];
      }
    });

    return getInvalidSection(copyResults);
  };

  private validateDropdownOther = (
    dropdownObject: HAIssueItem[] | null,
    referenceData: ListData[],
    invalidSection: boolean,
  ): boolean => {
    if (dropdownObject !== null) {
      dropdownObject.map((value: HAIssueItem): void => {
        const detailText = resolveListDataValue(value.value, referenceData);
        if (detailText === 'Other' && value.other === '') {
          invalidSection = true;
        }
      });
      return invalidSection;
    } else {
      return invalidSection;
    }
  };
}

export default HAReviewSummary;
