import React, { useState } from 'react';

import { FullPatient, RadioOptions } from 'op-interfaces';
import { FormContainer, RadioGroupField } from 'op-components';
import { snakeToCamel, resolveListDataValue } from 'op-utils';
import { ListData } from 'shared-components/interfaces';
import { NewsCard } from 'shared-components/components';
import { ErrorExclamation, Success } from 'shared-components/images';
import { sharedFormContainerProps, HeaderTitle, HeaderSubTitle } from '../Helper';

import { categoryFieldNames } from './constants';
import { REGISTRATION_CONFLICTS_CONTENT } from './registrationConflictContent';
import moment from 'moment';

import './RegistrationConflicts.scss';

interface Props {
  patient: FullPatient;
  refData: { [key: string]: ListData[] };
  updatePatient: any;
  resolvePatientConflicts: any;
  handleShowSaveExitConfirm: () => void;
  saveStatus?: string;
}

interface CategoriesFields {
  [key: string]: {
    [key: string]: {
      [key: string]: string;
    };
  };
}

const preNestedCategory = ['emergencyContact'];

const mapFieldToCategory = (fields: any, categoryFieldNames: any): any => {
  const categoryNames = Object.keys(categoryFieldNames).filter(
    (categoryName) => !preNestedCategory.includes(categoryName),
  );
  const mappedFieldsToCategories = Object.entries(fields).reduce((mappedFieldsToCategories, field) => {
    const [fieldName, fieldDetails] = field;

    categoryNames.forEach((categoryName) => {
      if (categoryFieldNames[categoryName].hasOwnProperty(fieldName)) {
        //@ts-ignore
        if (mappedFieldsToCategories[categoryName]) {
          //@ts-ignore
          mappedFieldsToCategories[categoryName][fieldName] = fieldDetails;
        } else {
          //@ts-ignore
          mappedFieldsToCategories[categoryName] = { [fieldName]: fieldDetails };
        }
      }
    });
    return mappedFieldsToCategories;
  }, {});

  //TODO: make this more generic for other future nested fields.
  if (fields.hasOwnProperty('emergencyContact')) {
    const { emergencyContact } = fields;
    return { ...mappedFieldsToCategories, emergencyContact };
  } else {
    return mappedFieldsToCategories;
  }
};

const displayLabelAs = ({
  value,
  refData,
  formatAs,
}: {
  value: any;
  refData: ListData[];
  formatAs: string | undefined;
}): string => {
  const { NOT_PROVIDED } = REGISTRATION_CONFLICTS_CONTENT.RADIO.LABEL;
  if (value === '') {
    return NOT_PROVIDED;
  } else if (refData) {
    return resolveListDataValue(value.toString(), refData);
  } else if (formatAs) {
    if (formatAs === 'dayMonth') {
      return moment(value).format('MM / YYYY');
    }
  }
  return value;
};

const RegistrationConflicts = ({
  patient,
  refData,
  updatePatient,
  resolvePatientConflicts,
  handleShowSaveExitConfirm,
}: Props): JSX.Element => {
  const { HEADER, SUBHEADER, RADIO } = REGISTRATION_CONFLICTS_CONTENT;
  const diffMqData = JSON.parse(patient.diffMqData);
  const mappedDiffDataToCategories = mapFieldToCategory(diffMqData, categoryFieldNames);

  const [categoriesFields, setCategoriesFields] = useState(mappedDiffDataToCategories);

  const LISTED_FIELDS = {
    name_prefix: refData.titleRefData,
    relationship: refData.relationshipsRefData,
    heritage: refData.heritageRefData,
    gender: refData.genderRefData,
    genderIdentificationRefData: refData.genderIdentificationRefData,
    marital_status: refData.maritalStatusRefData,
    country_of_birth: refData.countryOfBirthRefData,
    language_spoken: refData.languageSpokenRefData,
    medicare_type: refData.medicareTypeRefData,
    aus_state: refData.ausStateRefData,
  };

  const handleChange = async (
    category: string,
    fieldName: string,
    patientId: string,
    event: React.ChangeEvent<HTMLInputElement>,
  ) => {
    let diffSelectedKey = fieldName;
    let diffSelectedValue = event.target.value;
    const updatedCategoryField = {
      [category]: {
        ...categoriesFields[category],
        [diffSelectedKey]: {
          ...categoriesFields[category][diffSelectedKey],
          selectedOption: diffSelectedValue,
        },
      },
    };
    setCategoriesFields({ ...categoriesFields, ...updatedCategoryField });

    if (preNestedCategory.includes(category)) {
      diffSelectedKey = category;
      diffSelectedValue = JSON.stringify({ ...updatedCategoryField[category] });
    }
    updatePatient({ variables: { patientId, diffSelectedKey, diffSelectedValue } });
  };

  const resolveConflictsIncomplete = Object.values(categoriesFields as CategoriesFields)
    .reduce(
      (values: { selectedOption: string; mqConflictValue: string }[], categoryFields): any[] => [
        ...values,
        ...Object.values(categoryFields),
      ],
      [],
    )
    .map((diffField: { selectedOption: string; mqConflictValue: string }) => diffField['selectedOption'])
    .includes('');

  // const alertHeader: AlertInfo = {
  //   displayStyle: 'warning',
  //   title: 'Warning: ',
  //   text: 'Resolve data conflicts. Please resolve these conflicts first before reviewing the registration form.',
  // };;
  const onSubmit = () => resolvePatientConflicts();
  const sharedProps = sharedFormContainerProps('Basic Details', undefined, '');
  const formContainerProps = {
    ...sharedProps,
    submitForm: () => onSubmit(),
    submitButtonText: 'Resolve Conflicts',
    continueDisabled: resolveConflictsIncomplete,
    handleShowSaveExitConfirm,
  };

  return (
    <FormContainer {...formContainerProps}>
      {/* <AlertHeader alert={alertHeader} /> */}
      <div className="form-page form-page-registration-conflicts">
        <HeaderTitle data-test-id="au-rego-page-title">{HEADER}</HeaderTitle>
        <HeaderSubTitle data-test-id="au-rego-page-subtitle">{SUBHEADER}</HeaderSubTitle>
        <form className="form-container">
          {Object.entries(categoriesFields as CategoriesFields).map((data, idx) => {
            const [category, fields] = data;
            //@ts-ignore
            const { categoryLabel } = categoryFieldNames[category];

            const categoryFieldsIncomplete = Object.entries(fields)
              .map((field) => field[1]['selectedOption'])
              .includes('');

            const transformHzLabel = (category: any, name: any): any => {
              const value = preNestedCategory.includes(category)
                ? //@ts-ignore
                  patient[category]![snakeToCamel(name)]
                : patient[snakeToCamel(name)];
              return {
                value,
                //@ts-ignore
                refData: LISTED_FIELDS[name],
              };
            };

            return (
              <div className="newspaper-container" key={`category-card-${category}-${idx}`}>
                <NewsCard
                  title={categoryLabel}
                  invalidContent={categoryFieldsIncomplete}
                  primaryIcon={
                    categoryFieldsIncomplete ? (
                      <ErrorExclamation className={'primary-icon invalid-icon'} />
                    ) : (
                      <Success className={'primary-icon valid-icon'} />
                    )
                  }>
                  {Object.entries(fields).map((fieldObject: any[], idx) => {
                    const [name, diffDetails] = fieldObject;
                    //@ts-ignore
                    const fieldLabel = categoryFieldNames[category][name]['label'];
                    //@ts-ignore
                    const formatAs = categoryFieldNames[category][name]['formatAs'];

                    const mqOptionLabel = displayLabelAs({
                      value: diffDetails.mqConflictValue,
                      //@ts-ignore
                      refData: LISTED_FIELDS[name],
                      formatAs: formatAs,
                    });

                    const transFormHzLabel = transformHzLabel(category, name);
                    transFormHzLabel.formatAs = formatAs;
                    const hzOptionLabel = displayLabelAs(transFormHzLabel);

                    const options: RadioOptions[] = [
                      { label: hzOptionLabel, value: RADIO.VALUE.HZ },
                      { label: mqOptionLabel, value: RADIO.VALUE.MQ },
                    ];

                    return (
                      <div key={`radiogroupfield-${name}-${idx}`}>
                        <RadioGroupField
                          name={`${category}${name}`}
                          fieldLabel={fieldLabel}
                          options={options}
                          selectedOption={categoriesFields[category][name]['selectedOption']}
                          handleChange={(e) => handleChange(category, name, patient.id, e)}
                        />
                      </div>
                    );
                  })}
                </NewsCard>
              </div>
            );
          })}
        </form>
      </div>
    </FormContainer>
  );
};

export default RegistrationConflicts;
