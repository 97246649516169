// eslint-disable-next-line no-use-before-define
import React from 'react';

import { Region } from 'shared-components/enums';
import { MandatoryPatient } from 'op-interfaces/PatientInterfaces';
import './ResubmissionWarningHeader.scss';

interface Props {
  patient: MandatoryPatient;
}

class ResubmissionWarningHeader extends React.Component<Props> {
  public render(): JSX.Element {
    const REGION = import.meta.env.REACT_APP_REGION;
    const { patient } = this.props;

    if (patient.ida && REGION === Region.UK) {
      return (
        <div className="warning-header">
          <strong>Warning: </strong>
          This patient has already been submitted to Mosaiq. Any further changes you make to this patient here will not
          be reflected in Mosaiq until you submit them
        </div>
      );
    }
    return <span />;
  }
}

export default ResubmissionWarningHeader;
