import moment from 'moment';
import React, { useMemo } from 'react';
import { DropDownProps } from './DropDownProps';
import { BaseSelect } from 'shared-components/components/FormFields';

const DropDownROYear = (props: Omit<DropDownProps, 'options'>) => {
  const yearOptions = useMemo(() => {
    const currentYear = parseInt(moment().format('YYYY'));
    const computedEarliestYear = currentYear - 150; // arbitrary value
    const staticEarliestYear = 1900; // arbitrary value
    const earliestYear =
      computedEarliestYear > 0 && computedEarliestYear > staticEarliestYear ? computedEarliestYear : staticEarliestYear;

    const generatedValues: number[] = [];
    for (let start = earliestYear; start <= currentYear; start++) {
      generatedValues.push(start);
    }

    return generatedValues.reverse().map((data) => ({ label: data.toString(), value: data.toString() }));
  }, []);

  return (
    <BaseSelect
      options={[{ label: '--', value: '' }, ...yearOptions]}
      sx={{
        '.MuiOutlinedInput-root': {
          backgroundColor: 'white',
        },
        width: '120px',
        paddingRight: '8px',
        paddingBottom: '6px',
      }}
      {...props}
      onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
        props?.onChange(event.target.value);
      }}
    />
  );
};

DropDownROYear.defaultProps = {
  required: true,
  readonly: false,
  searchable: true,
  placeholder: 'Year',
};

export default DropDownROYear;
