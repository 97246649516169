import React, { useContext } from 'react';
import { styled } from '@mui/system';
import { useFormikContext, FieldArray, Field } from 'formik';
import { FormCard, ActionButton } from 'shared-components/components';
import FormContext from '../../contexts';
import AllergyCard from './AllergyCard';
import { getRecentlyCreated } from '../../../helpers';

const CardGroupFooter = styled('div')`
  display: flex;
  justify-content: flex-end;
  padding-top: 28px;
`;

interface Props {
  name: string;
  label: string;
  actionButtonLabel?: string;
  assessmentId: string;
}

const AllergyCardGroupField = ({ name, label, actionButtonLabel, assessmentId }: Props): JSX.Element => {
  const { values }: { values: any } = useFormikContext();
  const formContext = useContext(FormContext);
  const { createAllergy, deleteAllergy } = formContext.mutations;

  return (
    <FieldArray name={name}>
      {({ push, remove }) => (
        <>
          {values[name].map((_: null, index: number) => {
            return (
              <div key={index}>
                <Field
                  name={`${name}[${index}]`}
                  as={FormCard}
                  id={`${name}[${index}]-form-card`}
                  remove={!(values[name].length <= 1)}
                  heading={`${label} ${index + 1}`}
                  onClick={() => {
                    const { id } = values[name][index];
                    deleteAllergy({ variables: { id } });
                    remove(index);
                  }}>
                  <AllergyCard index={index} />
                </Field>
              </div>
            );
          })}
          <CardGroupFooter>
            <ActionButton
              fieldId={name}
              type="add"
              label={actionButtonLabel}
              onClick={() => {
                createAllergy({
                  variables: { assessmentId, allergyType: '', additionalDetails: '' },
                }).then(({ data }: { data: any }) => {
                  const updatedAllergies = data.createPcccAllergy.preCtChartCheck.pcccAllergy;
                  push(getRecentlyCreated(updatedAllergies));
                });
              }}
            />
          </CardGroupFooter>
        </>
      )}
    </FieldArray>
  );
};

export default AllergyCardGroupField;
