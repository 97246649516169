import { gql } from '@apollo/client';
import { REGISTRATION_ADDRESS_FRAGMENT } from 'op-graphql/fragments';

export const REGISTRATION_ADDRESS_QUERY = gql`
  query RegistrationAddress($id: ID!) {
    patient(id: $id) {
      id
      lastVisitedSection
      regFormStatus
      address {
        id
        ...Address
      }
      facilityAddress {
        id
        ...Address
      }
      inHospitalHospiceFacility
      facilityName
      facilityContactNumber
    }
    user {
      id
      isPso
    }
  }
  ${REGISTRATION_ADDRESS_FRAGMENT}
`;

export const UPDATE_REGISTRATION_FACILITY_DETAILS = gql`
  mutation UpdatePatientUsAddressFacility(
    $id: ID!
    $inHospitalHospiceFacility: Boolean
    $facilityName: String
    $facilityContactNumber: String
  ) {
    updatePatientUsAddressFacility(
      id: $id
      inHospitalHospiceFacility: $inHospitalHospiceFacility
      facilityName: $facilityName
      facilityContactNumber: $facilityContactNumber
    ) {
      patient {
        id
        inHospitalHospiceFacility
        facilityName
        facilityContactNumber
      }
    }
  }
`;
