// eslint-disable-next-line no-use-before-define
import React, { Component, Fragment } from 'react';
import classNames from 'classnames';

import './UKRegistrationSummary.scss';

import moment from 'moment-timezone';

import { Region } from 'shared-components/enums';
import { UKFullPatient } from 'op-interfaces';
import { ListData } from 'shared-components/interfaces';
import { SavingStatus } from 'shared-components/enums';
import { DateTimeConverter } from 'shared-components/utils';
import { INVALID_SECTIONS } from 'op-utils/RegistrationTypes';

import { validateRegistration, ValidationKeys } from 'op-utils';

import { RegistrationContainer } from 'op-components';
import { NewsCard } from 'shared-components/components';
import { SubmitButtonType } from 'shared-components/enums';

import { Edit, ErrorExclamation, Success } from 'shared-components/images';

const FORM_HEADING = 'Review & submit';

interface Props {
  patient: UKFullPatient;
  registrationReasonRefData: ListData[];
  nhsOptionsRefData: ListData[];
  titleRefData: ListData[];
  insurerRefData: ListData[];
  relationshipRefData: ListData[];
  gpSurgeryRefData: ListData[];
  surgeonLocationRefData: ListData[];
  gpRefData: ListData[];
  surgeonRefData: ListData[];
  extPracRefData: ListData[];
  oncologistRefData: ListData[];
  relationshipsRefData: ListData[];
  maritalStatusRefData: ListData[];
  ethnicityRefData: ListData[];
  religionRefData: ListData[];
  acceptsDataShareRefData: ListData[];
  countryOfBirthRefData: ListData[];
  genderRefData: ListData[];
  primaryCenterRefData: ListData[];
  documentTypeRefData: ListData[];
  languageSpokenRefData: ListData[];
  ausStateRefData: ListData[];
  autosave: (patient: UKFullPatient, key: string, value: string) => void;
  navigateToPage: (link: string) => void;
  submitRegistration: (closeModal: () => void) => void;
  saveStatus: SavingStatus;
  submitStatus: SubmitButtonType;
  isPso: boolean;
}

class UKRegistrationSummary extends Component<Props> {
  public render(): JSX.Element {
    const { patient, genderRefData, isPso, saveStatus, submitRegistration, submitStatus } = this.props;
    return (
      <RegistrationContainer
        patient={patient}
        genderRefData={genderRefData}
        isProd={patient.isProd}
        isPso={isPso}
        saveStatus={saveStatus}
        submitRegistration={submitRegistration}
        submitStatus={submitStatus}>
        {this.renderContents()}
      </RegistrationContainer>
    );
  }

  private renderContents = (): JSX.Element => {
    const {
      patient,
      countryOfBirthRefData,
      languageSpokenRefData,
      ethnicityRefData,
      religionRefData,
      maritalStatusRefData,
      gpSurgeryRefData,
      surgeonLocationRefData,
      insurerRefData,
      relationshipRefData,
      gpRefData,
      surgeonRefData,
      oncologistRefData,
      documentTypeRefData,
    } = this.props;

    const referenceData = {
      countries: countryOfBirthRefData,
      languages: languageSpokenRefData,
      ethnicities: ethnicityRefData,
      religions: religionRefData,
      maritalStatuses: maritalStatusRefData,
      gpSurgeryRefData: gpSurgeryRefData,
      surgeonLocationRefData: surgeonLocationRefData,
      gpRefData: gpRefData,
      surgeonRefData: surgeonRefData,
      insurers: insurerRefData,
      coverageRelationships: relationshipRefData,
      oncologistRefData: oncologistRefData,
      documentTypeRefData: documentTypeRefData,
    };

    const validationResults = validateRegistration(patient, ValidationKeys.All, referenceData, Region.UK);
    return (
      <div className="form-page form-page-registration-summary">
        <div className="form-heading">{FORM_HEADING}</div>
        <form className="form-container">
          <div className="newspaper-container">
            {this.renderBasicDetails(validationResults)}
            {this.renderContactDetails(validationResults)}
            {this.renderAddress(validationResults)}
            {this.renderAlternateContact(validationResults)}
            {this.renderGPInformation(validationResults)}
            {this.renderDemographics(validationResults)}
            {this.renderPreferencesInformation(validationResults)}
            {this.renderInsuranceInformation(validationResults)}
            {patient.attachments.length > 0 && this.renderAttachments()}
          </div>
        </form>
      </div>
    );
  };

  private renderBasicDetails = (validationResults: any): JSX.Element => {
    const {
      patient,
      titleRefData,
      genderRefData,
      primaryCenterRefData,
      navigateToPage,
      nhsOptionsRefData,
      registrationReasonRefData,
    } = this.props;

    // eslint-disable-next-line ,no-unused-vars
    const invalidSection = this.isInvalidSection(INVALID_SECTIONS.BASIC, validationResults);

    let primaryCenter = '';
    if (patient.primaryCenter) {
      primaryCenter = this.getDisplayValue(primaryCenterRefData, patient.primaryCenter);
    }
    let nhsOption = '';
    if (patient.nhsOptions) {
      nhsOption = this.getDisplayValue(nhsOptionsRefData, patient.nhsOptions);
    }
    let registrationReason = '';
    if (patient.registrationReason) {
      registrationReason = this.getDisplayValue(registrationReasonRefData, patient.registrationReason);
    }
    let registrationReasonText = '';
    if (patient.registrationReasonText) {
      registrationReasonText = patient.registrationReasonText;
    }
    return (
      <NewsCard
        title="Basic details"
        primaryIcon={<Edit className="primary-icon" />}
        secondaryIcon={this.validationIcon(invalidSection)}
        invalidContent={invalidSection}
        onClick={(): void => {
          navigateToPage('basic');
        }}>
        {this.renderNewsCardRow(
          'Title',
          this.getDisplayValue(titleRefData, patient.namePrefix),
          validationResults && validationResults.namePrefix ? validationResults.namePrefix : undefined,
        )}
        {this.renderNewsCardRow(
          'First name',
          patient.firstName,
          validationResults && validationResults.firstName ? validationResults.firstName : undefined,
        )}
        {this.renderNewsCardRow(
          'Middle name',
          patient.middleName,
          validationResults && validationResults.middleName ? validationResults.middleName : undefined,
        )}
        {this.renderNewsCardRow(
          'Last name',
          patient.lastName,
          validationResults && validationResults.lastName ? validationResults.lastName : undefined,
        )}
        {this.renderNewsCardRow('Gender', this.getDisplayValue(genderRefData, patient.gender), !patient.gender)}
        {this.renderNewsCardRow(
          'Date of birth',
          DateTimeConverter.getFormattedDateAsDDMonthYYYY(patient.dob),
          validationResults && validationResults.dobRaw ? validationResults.dobRaw : undefined,
        )}
        {this.renderNewsCardRow('Preferred centre', primaryCenter, !primaryCenter)}
        {this.renderNewsCardRow(
          'NHS number',
          patient.idb,
          validationResults && validationResults.idb ? validationResults.idb : undefined,
        )}
        {this.renderNewsCardRow(
          'Reason NHS ID is not provided',
          nhsOption,
          validationResults && validationResults.nhsOptions ? validationResults.nhsOptions : undefined,
        )}
        {this.renderNewsCardRow(
          'Registration reason',
          registrationReason,
          validationResults && validationResults.registrationReason ? validationResults.registrationReason : undefined,
        )}
        {registrationReason === 'Other' &&
          this.renderNewsCardRow(
            'Registration reason',
            registrationReasonText,
            validationResults && validationResults.registrationReasonText
              ? validationResults.registrationReasonText
              : undefined,
          )}
      </NewsCard>
    );
  };

  private addPlus = (strValue: string, newStr: string) => {
    if (strValue !== '') {
      return strValue + ' + ' + newStr;
    } else {
      return strValue + newStr;
    }
  };

  private getPreference = () => {
    const { patient } = this.props;
    let preferences = '';
    if (patient.preferenceMessage) {
      preferences = this.addPlus(preferences, 'Voicemail');
    }
    if (patient.preferenceText) {
      preferences = this.addPlus(preferences, 'Text (SMS)');
    }
    if (patient.preferenceEmail) {
      preferences = this.addPlus(preferences, 'Email');
    }
    if (preferences !== '' && preferences.split(' ').length === 1) {
      preferences += ' ONLY';
    }
    return preferences;
  };

  private renderContactDetails = (validationResults: any): JSX.Element => {
    const { patient, navigateToPage } = this.props;

    const invalidSection = this.isInvalidSection(INVALID_SECTIONS.CONTACT, validationResults);

    const preferences = this.getPreference();
    //let preferences = '';
    //patient.contactPreferences && (preferences = this.getPreference());

    //patient.contactPreferences && (preferences = patient.contactPreferences);

    return (
      <NewsCard
        title="Contact details"
        primaryIcon={<Edit className="primary-icon" />}
        secondaryIcon={this.validationIcon(invalidSection)}
        invalidContent={invalidSection}
        onClick={(): void => {
          navigateToPage('contact');
        }}>
        {this.renderNewsCardRow(
          'Mobile phone number',
          patient.primaryPhone,
          validationResults && validationResults.primaryPhone ? validationResults.primaryPhone : undefined,
        )}
        {this.renderNewsCardRow(
          'Home phone number',
          patient.secondaryPhone,
          validationResults && validationResults.secondaryPhone ? validationResults.secondaryPhone : undefined,
        )}
        {this.renderNewsCardRow(
          'Email',
          patient.email,
          validationResults && validationResults.email ? validationResults.email : undefined,
          false,
        )}
        {this.renderNewsCardRow('Contact preferences', preferences, false, true)}
      </NewsCard>
    );
  };

  private renderAddress = (validationResults: any): JSX.Element => {
    const { patient, countryOfBirthRefData, navigateToPage } = this.props;

    const invalidSection = this.isInvalidSection(INVALID_SECTIONS.ADDRESS, validationResults);
    return (
      <NewsCard
        title="Address"
        primaryIcon={<Edit className="primary-icon" />}
        secondaryIcon={this.validationIcon(invalidSection)}
        invalidContent={invalidSection}
        onClick={(): void => {
          navigateToPage('address');
        }}>
        {this.renderNewsCardRow(
          'Address line 1',
          patient.residentialAddressLine1,
          validationResults && validationResults.residentialAddressLine1
            ? validationResults.residentialAddressLine1
            : undefined,
        )}
        {this.renderNewsCardRow('Address line 2', patient.residentialAddressLine2, false, true)}
        {this.renderNewsCardRow(
          'City / Town',
          patient.residentialAddressCity,
          validationResults && validationResults.residentialAddressCity
            ? validationResults.residentialAddressCity
            : undefined,
        )}
        {this.renderNewsCardRow(
          'Country',
          this.getDisplayValue(countryOfBirthRefData, patient.residentialAddressCountry),
          validationResults && validationResults.residentialAddressCountry
            ? validationResults.residentialAddressCountry
            : undefined,
        )}
        {this.renderNewsCardRow(
          'County',
          this.getUKCounty(patient.residentialAddressCountry, patient.residentialAddressState),
          validationResults && validationResults.residentialAddressState
            ? validationResults.residentialAddressState
            : undefined,
        )}
        {this.renderNewsCardRow(
          'Postcode',
          patient.residentialAddressPostcode,
          validationResults && validationResults.residentialAddressPostcode
            ? validationResults.residentialAddressPostcode
            : undefined,
        )}
      </NewsCard>
    );
  };

  private renderAlternateContact = (validationResults: any): JSX.Element => {
    const { patient, relationshipsRefData, navigateToPage } = this.props;
    const { emergencyContact, nextOfKinContact } = patient;

    const showSecondaryPhone = !!emergencyContact.homePhoneNumber;
    const showNextSecondaryPhone = nextOfKinContact && !!nextOfKinContact.homePhoneNumber;

    const invalidSection = this.isInvalidSection(INVALID_SECTIONS.UK_ALT_CONTACT, validationResults);

    // TODO: Verify that the styling of the sub section heading is meant to be that way. There is a renderSubsectionHeading method in the AU registration summary page.
    return (
      <NewsCard
        title="Alternate contacts"
        primaryIcon={<Edit className="primary-icon" />}
        secondaryIcon={this.validationIcon(invalidSection)}
        invalidContent={invalidSection}
        onClick={(): void => {
          navigateToPage('altcontact');
        }}>
        <div className="form-heading">Emergency</div>
        {this.renderNewsCardRow(
          'First name',
          emergencyContact.firstName,
          validationResults && validationResults['emergencyContact.firstName']
            ? validationResults['emergencyContact.firstName']
            : undefined,
        )}
        {this.renderNewsCardRow(
          'Last name',
          emergencyContact.lastName,
          validationResults && validationResults['emergencyContact.lastName']
            ? validationResults['emergencyContact.lastName']
            : undefined,
        )}
        {this.renderNewsCardRow(
          'Relationship',
          this.getDisplayValue(relationshipsRefData, emergencyContact.relationship),
          validationResults && validationResults['emergencyContact.relationship']
            ? validationResults['emergencyContact.relationship']
            : undefined,
        )}
        {this.renderNewsCardRow(
          'Mobile phone number',
          emergencyContact.mobilePhoneNumber,
          validationResults && validationResults['emergencyContact.mobilePhoneNumber']
            ? validationResults['emergencyContact.mobilePhoneNumber']
            : undefined,
        )}
        {showSecondaryPhone &&
          this.renderNewsCardRow(
            'Home phone number',
            emergencyContact.homePhoneNumber,
            validationResults && validationResults['emergencyContact.homePhoneNumber']
              ? validationResults['emergencyContact.homePhoneNumber']
              : undefined,
          )}
        {this.renderNewsCardRow(
          'Email',
          emergencyContact.email,
          validationResults && validationResults['emergencyContact.email']
            ? validationResults['emergencyContact.email']
            : undefined,
        )}
        {this.renderNewsCardRow(
          'Next of Kin is the same as Emergency contact',
          this.convertFromBooleanToString(patient.sameAsEmergency),
          validationResults && validationResults.sameAsEmergency ? validationResults.sameAsEmergency : undefined,
        )}
        {patient.sameAsEmergency !== true && !!nextOfKinContact && (
          <Fragment>
            <div className="form-heading">Next of Kin</div>
            {this.renderNewsCardRow(
              'First name',
              nextOfKinContact.firstName,
              validationResults && validationResults['nextOfKinContact.firstName']
                ? validationResults['nextOfKinContact.firstName']
                : undefined,
            )}
            {this.renderNewsCardRow(
              'Last name',
              nextOfKinContact.lastName,
              validationResults && validationResults['nextOfKinContact.lastName']
                ? validationResults['nextOfKinContact.lastName']
                : undefined,
            )}
            {this.renderNewsCardRow(
              'Relationship',
              this.getDisplayValue(relationshipsRefData, nextOfKinContact.relationship),
              validationResults && validationResults['nextOfKinContact.relationship']
                ? validationResults['nextOfKinContact.relationship']
                : undefined,
            )}
            {this.renderNewsCardRow(
              'Mobile phone number',
              nextOfKinContact.mobilePhoneNumber,
              validationResults && validationResults['nextOfKinContact.mobilePhoneNumber']
                ? validationResults['nextOfKinContact.mobilePhoneNumber']
                : undefined,
            )}
            {showNextSecondaryPhone &&
              this.renderNewsCardRow(
                'Home phone number',
                nextOfKinContact.homePhoneNumber,
                validationResults && validationResults['nextOfKinContact.homePhoneNumber']
                  ? validationResults['nextOfKinContact.homePhoneNumber']
                  : undefined,
              )}
            {this.renderNewsCardRow(
              'Email',
              nextOfKinContact.email,
              validationResults && validationResults['nextOfKinContact.email']
                ? validationResults['nextOfKinContact.email']
                : undefined,
            )}
          </Fragment>
        )}
      </NewsCard>
    );
  };

  private renderGPInformation = (validationResults: any): JSX.Element => {
    const {
      patient,
      navigateToPage,
      gpRefData,
      gpSurgeryRefData,
      surgeonLocationRefData,
      surgeonRefData,
      oncologistRefData,
    } = this.props;

    const invalidSection = this.isInvalidSection(INVALID_SECTIONS.UK_GP, validationResults);

    const serviceRequests = patient.serviceRequests ? patient.serviceRequests : [];

    return (
      <NewsCard
        title="Referring clinicians"
        primaryIcon={<Edit className="primary-icon" />}
        secondaryIcon={this.validationIcon(invalidSection)}
        invalidContent={invalidSection}
        onClick={(): void => {
          navigateToPage('gp');
        }}>
        {this.renderNewsCardRow(
          'GP surgery',
          this.getDisplayValue(gpSurgeryRefData, patient.generalPractitionerLocation),
          validationResults && validationResults.generalPractitionerLocation
            ? validationResults.generalPractitionerLocation
            : undefined,
        )}
        {this.renderNewsCardRow(
          'GP name',
          this.getDisplayValue(gpRefData, patient.generalPractitioner),
          validationResults && validationResults.generalPractitioner
            ? validationResults.generalPractitioner
            : undefined,
        )}
        {this.renderNewsCardRow(
          'Referring clinician name',
          this.getDisplayValue(surgeonRefData, patient.referringSurgeon),
          validationResults && validationResults.referringSurgeon ? validationResults.referringSurgeon : undefined,
        )}
        {this.renderNewsCardRow(
          'Referring facility',
          this.getDisplayValue(surgeonLocationRefData, patient.referringSurgeonLocation),
          validationResults && validationResults.referringSurgeonLocation
            ? validationResults.referringSurgeonLocation
            : undefined,
        )}
        {this.renderNewsCardRow(
          'Responsible clinician',
          this.getDisplayValue(oncologistRefData, patient.oncologist),
          validationResults && validationResults.oncologist ? validationResults.oncologist : undefined,
        )}
        {serviceRequests.length > 0 && <div className="form-heading">Referrals</div>}
        {serviceRequests.length > 0 &&
          // eslint-disable-next-line
          patient.serviceRequests.map((request) => {
            return this.renderNewsCardRow('Pending Referral', moment(request.encounterDate).format('DD MMM YYYY'));
          })}
      </NewsCard>
    );
  };

  private renderDemographics = (validationResults: any): JSX.Element => {
    const { patient, maritalStatusRefData, ethnicityRefData, religionRefData, navigateToPage } = this.props;
    const invalidSection = this.isInvalidSection(INVALID_SECTIONS.UK_DEMOGRAPHICS, validationResults);

    return (
      <NewsCard
        title="Demographics"
        primaryIcon={<Edit className="primary-icon" />}
        secondaryIcon={this.validationIcon(invalidSection)}
        invalidContent={invalidSection}
        onClick={(): void => {
          navigateToPage('demographics');
        }}>
        {this.renderNewsCardRow(
          'Ethnicity',
          this.getDisplayValue(ethnicityRefData, patient.ethnicity),
          validationResults && validationResults.ethnicity ? validationResults.ethnicity : undefined,
        )}
        {this.renderNewsCardRow(
          'Religion',
          this.getDisplayValue(religionRefData, patient.religion),
          validationResults && validationResults.religion ? validationResults.religion : undefined,
        )}
        {this.renderNewsCardRow(
          'Marital status',
          this.getDisplayValue(maritalStatusRefData, patient.maritalStatus),
          validationResults && validationResults.maritalStatus ? validationResults.maritalStatus : undefined,
        )}
      </NewsCard>
    );
  };

  private renderPreferencesInformation = (validationResults: any): JSX.Element => {
    const { patient, navigateToPage } = this.props;

    const invalidSection = this.isInvalidSection(INVALID_SECTIONS.PREFERENCES, validationResults);
    return (
      <NewsCard
        title="Preferences"
        primaryIcon={<Edit className="primary-icon" />}
        secondaryIcon={this.validationIcon(invalidSection)}
        invalidContent={invalidSection}
        onClick={(): void => {
          navigateToPage('preferences');
        }}>
        {this.renderNewsCardRow(
          'Accepts Data Share',
          this.convertFromBooleanToString(patient.acceptsDataShare),
          validationResults && validationResults.acceptsDataShare ? validationResults.acceptsDataShare : false,
        )}
      </NewsCard>
    );
  };

  private renderInsuranceInformation = (validationResults: any): JSX.Element => {
    const { patient, navigateToPage, relationshipRefData, insurerRefData } = this.props;

    // eslint-disable-next-line no-unused-vars
    const invalidSection = this.isInvalidSection(INVALID_SECTIONS.INSURANCE, validationResults);
    return (
      <NewsCard
        title="Insurance"
        primaryIcon={<Edit className="primary-icon" />}
        secondaryIcon={this.validationIcon(invalidSection)}
        invalidContent={invalidSection}
        onClick={(): void => {
          navigateToPage('insurance');
        }}>
        {this.renderNewsCardRow(
          'Insurance company',
          this.getDisplayValue(insurerRefData, patient.payor),
          validationResults && validationResults.payor ? validationResults.payor : undefined,
        )}
        {this.renderNewsCardRow(
          'Patient relationship to policy holder',
          this.getDisplayValue(relationshipRefData, patient.coverageRelationship),
          validationResults && validationResults.coverageRelationship
            ? validationResults.coverageRelationship
            : undefined,
        )}
        {this.renderNewsCardRow(
          'Pre auth number',
          patient.preAuthNumber,
          validationResults && validationResults.preAuthNumber ? validationResults.preAuthNumber : undefined,
        )}
        {this.renderNewsCardRow(
          'Insurance policy number',
          patient.policyNumber,
          validationResults && validationResults.policyNumber ? validationResults.policyNumber : undefined,
        )}
      </NewsCard>
    );
  };

  private renderAttachments = (): JSX.Element => {
    const { patient, navigateToPage, documentTypeRefData } = this.props;

    return (
      <NewsCard
        title="Attachments"
        primaryIcon={<Edit className="primary-icon" />}
        secondaryIcon={this.validationIcon(false)}
        invalidContent={false}
        onClick={(): void => {
          navigateToPage('attachments');
        }}>
        {patient.attachments.map((attachment: any) => {
          return this.renderNewsCardRow(
            this.getDisplayValue(documentTypeRefData, attachment.typeDisplay),
            attachment.filename,
          );
        })}
      </NewsCard>
    );
  };

  /**
   * Helper method to generate a details row in the NewsCard component.
   * TODO: This method should eventually be moved to a separate component.
   */
  private renderNewsCardRow = (
    headingText: string,
    detailText: string | null,
    displayAsError = false,
    displayAsOptional = false,
    fallbackText?: string,
  ): JSX.Element => {
    const displayText = this.renderContentMessage(detailText, fallbackText);
    const optionalColouring = (displayText === fallbackText || displayText === 'Not provided') && displayAsOptional;

    return (
      <div className={classNames('newscard-row', { 'invalid-data': displayAsError })}>
        <div className="newscard__title newscard--bold">
          {headingText}
          {displayAsOptional && ' ' + '(optional)'}
        </div>
        <div className={classNames('newscard__content', { optional: optionalColouring })}>{displayText}</div>
      </div>
    );
  };

  /**
   * Helper method to generate the detail text or the provided fall-back text if data is missing.
   */
  private renderContentMessage = (content: string | null, fallbackText?: string): string => {
    // If null or empty then return missing text
    if (!content || content.trim() === '') {
      return fallbackText || 'Not provided';
    }
    return content;
  };

  private convertFromBooleanToString = (value: boolean | null): string => {
    if (value) {
      return 'Yes';
    } else if (value === false) {
      return 'No';
    }

    return '';
  };

  private getDisplayValue = (refData: ListData[], option: string): string => {
    for (let i = 0; i < refData.length; i++) {
      if (refData[i].id === option) {
        return refData[i].name;
      }
    }

    return option;
  };

  private getUKCounty = (country: string, state: string): string => {
    const { countryOfBirthRefData, ausStateRefData } = this.props;

    const ukId = this.getCountryRefId();

    if (country === countryOfBirthRefData[ukId].id) {
      return this.getDisplayValue(ausStateRefData, state);
    }

    return state;
  };

  private getStateOrTerritoryLabel = (country: string): string => {
    const { countryOfBirthRefData } = this.props;
    const countryId = this.getCountryRefId();

    if (country === countryOfBirthRefData[countryId].id) {
      return 'County';
    }

    return 'State / Province / Region';
  };

  private getCountryRefId = (): number => {
    const { countryOfBirthRefData } = this.props;

    // TODO: This needs to be updated once country of birth ref data moves to react list data
    return countryOfBirthRefData.findIndex((country): boolean => {
      return country.name.toLowerCase() === 'united kingdom';
    });
  };

  private isInvalidSection = (section: string, validationResult?: any): boolean => {
    if (validationResult && validationResult.invalidSections) {
      return validationResult.invalidSections.includes(section);
    }

    return false;
  };

  private validationIcon = (isInvalidSection: boolean): JSX.Element => {
    const iconClass = 'secondary-icon';
    if (isInvalidSection) {
      return <ErrorExclamation className={`${iconClass} invalid-icon`} />;
    }

    return <Success className={`${iconClass} valid-icon`} />;
  };
}

export default UKRegistrationSummary;
