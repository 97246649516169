// eslint-disable-next-line no-use-before-define
import React, { Component } from 'react';

import { RouteComponentProps, withRouter } from 'react-router-dom';
import { INFO } from '../constants';

import './CovidInformation.scss';

interface State {
  windowHeight: number;
}

class CovidInformation extends Component<RouteComponentProps, State> {
  public render(): JSX.Element {
    return (
      <div id="covid-content">
        <div className="covid-information-content">
          <div className="covid-information-text-body">
            <p className="covid-information-title">{INFO.TITLE}</p>

            <div className="covid-top-half">
              <p>{INFO.PARA_1}</p>
              <p>{INFO.PARA_2}</p>
              <p>{INFO.PARA_3}</p>
            </div>
            <div className="covid-bottom-half">
              <div className="covid-left-section">
                <h4 className="visitors-title">Visitors</h4>
                <div className="visitors-content">
                  <p>{INFO.VISITORS_PARA_1}</p>
                  <p>{INFO.VISITORS_PARA_2}</p>
                </div>
              </div>
              <div className="covid-right-section">
                <div className="covid-image" />
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

const routedComponent = withRouter(CovidInformation);
export default routedComponent;
