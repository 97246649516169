import { gql } from '@apollo/client';

export const GET_RECENT_PATIENTS = gql`
  query GetRecentPatients {
    getRecentPatients {
      dob
      age
      fullName
      id
      ida
      gender
      emrInstance
      images {
        id
      }
      photoUrl
      address {
        city
        country
        formattedAddress
        id
        line1
        line2
        postcode
        state
      }
    }
  }
`;

export const UPDATE_PATIENT_FORM_VISIBILITY = gql`
  mutation UpdatePatientFormVisibility(
    $patientId: ID!
    $showDistressThermometer: Boolean
    $showRegistration: Boolean
    $showHealthAssessment: Boolean
  ) {
    updatePatientFormVisibility(
      patientId: $patientId
      showDistressThermometer: $showDistressThermometer
      showRegistration: $showRegistration
      showHealthAssessment: $showHealthAssessment
    ) {
      profile {
        id
        showDistressThermometer
        showRegistration
        showHealthAssessment
      }
    }
  }
`;
