import { gql } from '@apollo/client';

export const GET_PATIENT_EMR = gql`
  query patient($patientId: ID!) {
    patient(id: $patientId) {
      id
      emrInstance
    }
  }
`;
