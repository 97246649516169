import React from 'react';
import { useFormikContext, Field, FormikProps } from 'formik';

import { InfoCard } from 'op-components';
import { yesNoOptions } from 'op-utils';
import { InfoCardRowItem, HAIssueItem } from 'op-interfaces';
import { FormRow, HelperMessage } from 'shared-components/components';
import { ToggleButtonGroupField } from 'shared-components/components/FormikComponents';

import { choiceToBooleanOptions, getLastUpdateUser } from '../../helpers';
import { LastUpdateUserDetails } from '../../shared/interfaces';
import { preCtChartCheckContent } from '../constants';
import { HealthAssessmentData, PreCtChartCheckFormValues } from '../interfaces/PreCtChartCheckInterfaces';

interface Props {
  handleMutation: (name: string, value: string) => void;
  healthAssessmentData: HealthAssessmentData;
  lastUpdateUserData: LastUpdateUserDetails[];
}

const FallsRisk = ({ handleMutation, healthAssessmentData, lastUpdateUserData }: Props): JSX.Element => {
  const { values }: FormikProps<PreCtChartCheckFormValues> = useFormikContext();
  const { fallLastTwelveMonths, safetyConcernsMobility } = preCtChartCheckContent.fallsRisk.fields;
  const { walkingDeviceTitle } = preCtChartCheckContent.fallsRisk.tableTitles;
  const fallsRiskQCLMessage = preCtChartCheckContent.fallsRisk.infoHelpers.fallsRiskQCLMessage;

  let hasFallen;
  let hasWalkingAid;
  if (healthAssessmentData.healthAssessment) {
    //@ts-ignore
    hasFallen = choiceToBooleanOptions[String(healthAssessmentData.healthAssessment.fallen)];
    //@ts-ignore
    hasWalkingAid = choiceToBooleanOptions[String(healthAssessmentData.healthAssessment.mobilityAid)];
  }

  const walkingAidTableRows: InfoCardRowItem[][] = [];

  if (hasWalkingAid === true) {
    const walkingAids = healthAssessmentData.healthAssessment.mobilityAids;
    const refData = healthAssessmentData.mobilityAidRefData;

    walkingAidTableRows.push(
      walkingAids.map((aid: HAIssueItem, index: number) => {
        const other = aid.other;
        const refValue = refData.find((ref) => ref.id === aid.value);
        const value = refValue ? refValue.name : '-';
        const title = `${walkingDeviceTitle} ${index + 1}`;

        return {
          title: title,
          value: other ? other : value,
        };
      }),
    );
  }

  return (
    <>
      <FormRow fieldLabel={fallLastTwelveMonths} fieldName={'fallLastTwelveMonths'}>
        <Field
          name="fallLastTwelveMonths"
          component={ToggleButtonGroupField}
          label={fallLastTwelveMonths}
          helperType="error"
          options={yesNoOptions}
          handleMutation={handleMutation}
          lastUpdateUser={getLastUpdateUser(lastUpdateUserData, 'fallLastTwelveMonths')}
        />
        {values.fallLastTwelveMonths === true && (
          <HelperMessage fieldName={fallLastTwelveMonths} fieldText={fallsRiskQCLMessage} helperType="info" />
        )}
      </FormRow>
      {hasFallen === true && (
        <InfoCard
          title="Fall in the last 12 months"
          subTitle="(Patient reported)"
          rowDetails={[
            [
              {
                title: 'Please specify: Fall in last 12 months',
                value: healthAssessmentData.healthAssessment.fallenReason,
              },
            ],
          ]}
        />
      )}
      <FormRow fieldLabel={safetyConcernsMobility} fieldName={'safetyConcernsMobility'}>
        <Field
          name="safetyConcernsMobility"
          component={ToggleButtonGroupField}
          label={safetyConcernsMobility}
          helperType="error"
          options={yesNoOptions}
          handleMutation={handleMutation}
          lastUpdateUser={getLastUpdateUser(lastUpdateUserData, 'safetyConcernsMobility')}
        />
        {values.safetyConcernsMobility === true && (
          <HelperMessage fieldName={fallLastTwelveMonths} fieldText={fallsRiskQCLMessage} helperType="info" />
        )}
      </FormRow>
      {hasWalkingAid === true && walkingAidTableRows.length > 0 && (
        <InfoCard title="Walking Devices" subTitle="(Patient reported)" rowDetails={walkingAidTableRows} />
      )}
    </>
  );
};

export default FallsRisk;
