// eslint-disable-next-line no-use-before-define
import React, { Component, Fragment } from 'react';

import validate from 'validate.js';

import { fetchRules, ValidationKeys } from 'op-utils/HealthAssessmentValidation/HealthAssessmentValidation';

import './HAMedicalHistoryOther.scss';

import { HAMedicalHistoryOtherItem } from 'op-classes';
import { MoreInfoText, PageTitle } from 'op-components';
import { HAIssueItem } from 'op-interfaces';
import {
  DropDownField,
  FreeTextArea,
  FreeTextField,
  SectionField,
  SegmentedInput,
} from 'shared-components/components/FormFields';
import { MinusNoFill } from 'shared-components/images';
import { ListData } from 'shared-components/interfaces';
import { SegementedInputYesNoUnsure } from 'shared-components/utils';
import { MHO_FIELDS } from '../constants';
import lodash from 'lodash';
import { Add as AddIcon } from '@mui/icons-material';

const CRUD_OPERATION = {
  CREATE: 'CREATE',
  DELETE: 'DELETE',
};
interface State {
  viewedFields: Set<string>;
}

interface Props {
  patientId: string;
  medicalHistoryOther: HAMedicalHistoryOtherItem;
  mobilityAidRefData: ListData[];
  wellbeingIssueRefData: ListData[];
  autosave: (updateObject: object, replaceId?: string, refetch?: any) => Promise<void>;
  performCRUD: (haId: string, operation: string, objectType: string, itemId?: string, refetch?: any) => void;
  refetch: any;
  validateOnLoad: boolean;
}

class HAMedicalHistoryOther extends Component<Props, State> {
  public constructor(props: Props) {
    super(props);

    this.state = {
      viewedFields: new Set(),
    };
  }

  public static getDerivedStateFromProps(props: Props, state: State): State {
    const { medicalHistoryOther } = props;
    if (props.validateOnLoad) {
      const fields = Object.entries(MHO_FIELDS).map((field: any) => {
        return field[1]['KEY'];
      });
      const viewed = new Set(fields);
      Object.keys(medicalHistoryOther).forEach(function (key) {
        if (Array.isArray(medicalHistoryOther[key])) {
          medicalHistoryOther[key].forEach(function (item: any, index: number) {
            Object.keys(item).forEach(function (field) {
              if (field !== 'id' && field !== '__typename') {
                const place = index + 1;
                viewed.add(key + '-' + place + '-' + field);
              }
            });
          });
        }
      });
      return { viewedFields: viewed };
    }

    return state;
  }

  public render(): JSX.Element {
    const { medicalHistoryOther, patientId } = this.props;

    return (
      <Fragment>
        <PageTitle title={'Medical history: other'} idPrefix="ha" />
        <div id="ha-fields" className="ha-medical-history-other">
          <SectionField htmlFor={MHO_FIELDS.WALKING.NAME} title={MHO_FIELDS.WALKING.TITLE}>
            <MoreInfoText moreInfoText={MHO_FIELDS.WALKING.MORE_INFO}>
              <SegmentedInput
                fieldName={MHO_FIELDS.WALKING.NAME}
                options={SegementedInputYesNoUnsure}
                defaultSelected={medicalHistoryOther.mobilityAid}
                itemSelected={(selectedItem): void => {
                  const selectedValue = (selectedItem as ListData).id;
                  // Only trigger save if user selects something different
                  if (medicalHistoryOther.mobilityAid !== selectedValue) {
                    this.autoSaveAndValidate(
                      { id: parseInt(patientId), mobilityAid: selectedValue },
                      MHO_FIELDS.WALKING.KEY,
                      this.props.refetch,
                    );
                  }
                }}
              />
            </MoreInfoText>
            {this.shouldDisplayDynamic(medicalHistoryOther.mobilityAid) && this.renderWalkingSelection()}
          </SectionField>
          <SectionField htmlFor={MHO_FIELDS.MENTAL.NAME} title={MHO_FIELDS.MENTAL.TITLE}>
            <MoreInfoText moreInfoText={MHO_FIELDS.MENTAL.MORE_INFO}>
              <SegmentedInput
                fieldName={MHO_FIELDS.MENTAL.NAME}
                options={SegementedInputYesNoUnsure}
                defaultSelected={medicalHistoryOther.wellbeingIssue}
                itemSelected={(selectedItem): void => {
                  const selectedValue = (selectedItem as ListData).id;
                  // Only trigger save if user selects something different
                  if (medicalHistoryOther.wellbeingIssue !== selectedValue) {
                    this.autoSaveAndValidate(
                      { id: parseInt(patientId), wellbeingIssue: selectedValue },
                      MHO_FIELDS.MENTAL.KEY,
                      this.props.refetch,
                    );
                  }
                }}
              />
            </MoreInfoText>
            {this.shouldDisplayDynamic(medicalHistoryOther.wellbeingIssue) && this.renderMentalHealth()}
          </SectionField>
          <SectionField htmlFor={MHO_FIELDS.ACTIVE_INJURY.NAME} title={MHO_FIELDS.ACTIVE_INJURY.TITLE}>
            <SegmentedInput
              fieldName={MHO_FIELDS.ACTIVE_INJURY.NAME}
              options={SegementedInputYesNoUnsure}
              defaultSelected={medicalHistoryOther.activeInjury}
              itemSelected={(selectedItem): void => {
                const selectedValue = (selectedItem as ListData).id;
                this.autoSaveAndValidate(
                  { id: parseInt(patientId), activeInjury: selectedValue },
                  MHO_FIELDS.ACTIVE_INJURY.KEY,
                  this.props.refetch,
                );
              }}
            />
          </SectionField>
          {this.shouldDisplayDynamic(medicalHistoryOther.activeInjury) && this.renderActiveInjury()}
          <SectionField htmlFor={MHO_FIELDS.IN_PAIN.NAME} title={MHO_FIELDS.IN_PAIN.TITLE}>
            <SegmentedInput
              fieldName={MHO_FIELDS.IN_PAIN.NAME}
              options={SegementedInputYesNoUnsure}
              defaultSelected={medicalHistoryOther.inPain}
              itemSelected={(selectedItem): void => {
                const selectedValue = (selectedItem as ListData).id;
                this.autoSaveAndValidate(
                  { id: parseInt(patientId), inPain: selectedValue },
                  MHO_FIELDS.IN_PAIN.KEY,
                  this.props.refetch,
                );
              }}
            />
          </SectionField>
          {this.shouldDisplayDynamic(medicalHistoryOther.inPain) && this.renderInPainReason()}
          <SectionField htmlFor={MHO_FIELDS.FALLEN.NAME} title={MHO_FIELDS.FALLEN.TITLE}>
            <SegmentedInput
              fieldName={MHO_FIELDS.FALLEN.NAME}
              options={SegementedInputYesNoUnsure}
              defaultSelected={medicalHistoryOther.fallen}
              itemSelected={(selectedItem): void => {
                const selectedValue = (selectedItem as ListData).id;
                this.autoSaveAndValidate(
                  { id: parseInt(patientId), fallen: selectedValue },
                  MHO_FIELDS.FALLEN.KEY,
                  this.props.refetch,
                );
              }}
            />
          </SectionField>
          {this.shouldDisplayDynamic(medicalHistoryOther.fallen) && this.renderFallenReason()}
          <SectionField htmlFor={MHO_FIELDS.ADVANCED.NAME} title={MHO_FIELDS.ADVANCED.TITLE}>
            <MoreInfoText
              moreInfoText={MHO_FIELDS.ADVANCED.MORE_INFO}
              showIcon={true}
              showInfo={medicalHistoryOther.advancedCareDirective === 'YES'}>
              <SegmentedInput
                fieldName={MHO_FIELDS.ADVANCED.NAME}
                options={SegementedInputYesNoUnsure}
                defaultSelected={medicalHistoryOther.advancedCareDirective}
                itemSelected={(selectedItem): void => {
                  const selectedValue = (selectedItem as ListData).id;
                  this.autoSaveAndValidate(
                    { id: parseInt(patientId), advancedCareDirective: selectedValue },
                    MHO_FIELDS.ADVANCED.KEY,
                    this.props.refetch,
                  );
                }}
              />
            </MoreInfoText>
          </SectionField>
          {medicalHistoryOther.advancedCareDirective === 'NO' && this.renderFurtherInfo()}
          <SectionField htmlFor={MHO_FIELDS.PREGNANT.NAME} title={MHO_FIELDS.PREGNANT.TITLE}>
            <SegmentedInput
              fieldName={MHO_FIELDS.PREGNANT.NAME}
              options={SegementedInputYesNoUnsure}
              defaultSelected={medicalHistoryOther.pregnant}
              itemSelected={(selectedItem): void => {
                const selectedValue = (selectedItem as ListData).id;
                this.autoSaveAndValidate(
                  { id: parseInt(patientId), pregnant: selectedValue },
                  MHO_FIELDS.PREGNANT.KEY,
                  this.props.refetch,
                );
              }}
            />
          </SectionField>
        </div>
      </Fragment>
    );
  }

  private validateObject = (haPatientMedicalOther: HAMedicalHistoryOtherItem): any => {
    const validationRules = fetchRules(ValidationKeys.MedicalOther, this.state.viewedFields);
    // Disable pre-appending of argument name to error messages
    const options = { fullMessages: false };
    return validate(haPatientMedicalOther, validationRules, options);
  };

  private autoSaveAndValidate = async (
    updateObject: object,
    validationKey: string,
    setRefetch: boolean,
  ): Promise<void> => {
    // Use this to set the viewed items for the validation
    const { autosave, refetch } = this.props;

    // Save the data
    const autoSave = () => {
      return setRefetch ? autosave(updateObject, undefined, refetch) : autosave(updateObject);
    };

    await autoSave().then(() => {
      // Get viewed fields
      const viewedFields = [...this.state.viewedFields];

      // Add updated field
      viewedFields.push(validationKey);
      // Update with new validation set
      this.setState({ viewedFields: new Set(viewedFields) });
    });
  };

  private shouldDisplayDynamic = (value: string | null): boolean => {
    if (value === 'YES') {
      return true;
    }

    return false;
  };

  private renderExpandableElement = (
    index: number,
    inputType: string,
    inputTitle: string,
    stateList: HAIssueItem[],
    referenceData: ListData[],
    onChange: (object: any) => void,
    removeFunction: () => void,
    removable = true,
  ): JSX.Element => {
    const inputName = `${inputType}-${index + 1}`;

    // Map generates a new array with the id of the "Other" list data index.
    // Should only ever be one, therefore return first item
    // Default is undefined
    // This is an alternative to find() which isn't supported by IE
    // FIXME: This should move out of the render, and be a value calculated once and passed into this method
    const otherOptionIndex: string = referenceData.filter(
      (listItem: ListData): boolean => listItem.name.toLowerCase().trim() === 'other',
    )[0].id;
    //Put 'other' at the end without otherwise disrupting alphanumeric ordering.
    const location = referenceData.map((el) => el.name.toLowerCase().trim()).indexOf('other');
    if (location !== referenceData.length) {
      const first = referenceData.splice(location, 1);
      referenceData.push(first[0]);
    }
    return (
      <div key={inputName} className="infinite-dropdown-item">
        <SectionField htmlFor={inputName} title={`${inputTitle} ${index + 1}`}>
          <DropDownField
            inputName={inputName}
            placeholder={'Please select'}
            options={referenceData}
            defaultValue={stateList[index].value}
            controlled={true}
            onChange={(e): void => {
              let saveObject = {};
              // If dropdown is other, don't override other value
              if (e.target.value === otherOptionIndex) {
                saveObject = { value: e.target.value };
              } else {
                saveObject = { value: e.target.value, other: '' };
              }
              onChange(saveObject);
            }}
          />

          {stateList[index].value === otherOptionIndex && (
            <FreeTextField
              inputKey={stateList[index].other}
              inputName={`${inputName}-other`}
              placeholder={'Please describe'}
              inputType="text"
              defaultValue={stateList[index].other}
              onBlur={(e): void => {
                const saveObject = { value: otherOptionIndex, other: e.target.value };
                this.addToViewedFields(`${inputName}-other`);
                onChange(saveObject);
              }}
            />
          )}
        </SectionField>
        {removable && (
          <div
            className="remove-item"
            onClick={(): void => {
              removeFunction();
            }}>
            <MinusNoFill className="icon" />
            {'Remove'}
          </div>
        )}
      </div>
    );
  };

  private renderAdditionalItem = (buttonText: string, onClick: () => void): JSX.Element => {
    return (
      <div
        className="additional-item-button"
        onClick={(): void => {
          onClick();
        }}>
        <AddIcon className="icon" color="primary" />
        {buttonText}
      </div>
    );
  };

  private renderWalkingSelection = (): JSX.Element => {
    const { medicalHistoryOther, mobilityAidRefData, autosave, performCRUD, refetch, patientId } = this.props;

    const walkingAidsSelection: JSX.Element[] = medicalHistoryOther.mobilityAids.map((value, index): JSX.Element => {
      let removable = false;
      if (medicalHistoryOther.mobilityAids.length !== 1) {
        removable = true;
      }
      return this.renderExpandableElement(
        index,
        'mobilityAids',
        'Mobility aid',
        medicalHistoryOther.mobilityAids,
        lodash.cloneDeep(mobilityAidRefData),
        (object: any): void => {
          autosave({ id: parseInt(patientId), mobilityAids: { id: value.id, ...object } }, value.id);
        },
        (): void => {
          performCRUD(medicalHistoryOther.id, CRUD_OPERATION.DELETE, 'mobilityAid', value.id, refetch);
        },
        removable,
      );
    });

    return (
      <Fragment>
        {walkingAidsSelection}
        {this.renderAdditionalItem('Add another mobility aid', (): void => {
          performCRUD(medicalHistoryOther.id, CRUD_OPERATION.CREATE, 'mobilityAid', undefined, refetch);
        })}
      </Fragment>
    );
  };

  private renderMentalHealth = (): JSX.Element => {
    const { medicalHistoryOther, wellbeingIssueRefData, performCRUD, refetch, autosave, patientId } = this.props;

    const mentalHealthSelection: JSX.Element[] = medicalHistoryOther.wellbeingIssues.map(
      (value, index): JSX.Element => {
        let removable = false;
        if (medicalHistoryOther.wellbeingIssues.length !== 1) {
          removable = true;
        }
        return this.renderExpandableElement(
          index,
          'wellbeingIssues',
          'Wellbeing or mental health issue',
          medicalHistoryOther.wellbeingIssues,
          lodash.cloneDeep(wellbeingIssueRefData),
          (object: any): void => {
            autosave({ id: parseInt(patientId), wellbeingIssues: { id: value.id, ...object } }, value.id);
          },
          (): void => {
            performCRUD(medicalHistoryOther.id, CRUD_OPERATION.DELETE, 'wellbeingIssue', value.id, refetch);
          },
          removable,
        );
      },
    );
    return (
      <Fragment>
        {mentalHealthSelection}
        {this.renderAdditionalItem('Add another wellbeing or mental health issue', (): void => {
          performCRUD(medicalHistoryOther.id, CRUD_OPERATION.CREATE, 'wellbeingIssue', undefined, refetch);
        })}
      </Fragment>
    );
  };

  private renderActiveInjury = (): JSX.Element => {
    const { medicalHistoryOther, patientId } = this.props;

    return (
      <SectionField htmlFor={MHO_FIELDS.ACTIVE_INJURY_REASON.NAME} title={MHO_FIELDS.ACTIVE_INJURY_REASON.TITLE}>
        <FreeTextField
          inputName={MHO_FIELDS.ACTIVE_INJURY_REASON.NAME}
          defaultValue={medicalHistoryOther.activeInjuryReason}
          onBlur={(e): void => {
            this.autoSaveAndValidate(
              { id: parseInt(patientId), activeInjuryReason: e.target.value },
              MHO_FIELDS.ACTIVE_INJURY_REASON.KEY,
              this.props.refetch,
            );
          }}
        />
      </SectionField>
    );
  };

  private renderInPainReason = (): JSX.Element => {
    const { medicalHistoryOther, patientId } = this.props;

    return (
      <SectionField htmlFor={MHO_FIELDS.IN_PAIN_REASON.NAME} title={MHO_FIELDS.IN_PAIN_REASON.TITLE}>
        <FreeTextField
          inputName={MHO_FIELDS.IN_PAIN_REASON.NAME}
          defaultValue={medicalHistoryOther.inPainReason}
          onBlur={(e): void => {
            this.autoSaveAndValidate(
              { id: parseInt(patientId), inPainReason: e.target.value },
              MHO_FIELDS.IN_PAIN_REASON.KEY,
              this.props.refetch,
            );
          }}
        />
      </SectionField>
    );
  };

  private renderFallenReason = (): JSX.Element => {
    const { medicalHistoryOther, patientId } = this.props;

    return (
      <SectionField htmlFor={MHO_FIELDS.FALLEN_REASON.NAME} title={MHO_FIELDS.FALLEN_REASON.TITLE}>
        <FreeTextArea
          placeholder=""
          inputName={MHO_FIELDS.FALLEN_REASON.NAME}
          defaultValue={medicalHistoryOther.fallenReason}
          onBlur={(e): void => {
            this.autoSaveAndValidate(
              { id: parseInt(patientId), fallenReason: e.target.value },
              MHO_FIELDS.FALLEN_REASON.KEY,
              this.props.refetch,
            );
          }}
        />
      </SectionField>
    );
  };
  private addToViewedFields = (validationKey: string): any => {
    //Get viewed fields
    const viewedFields = [...this.state.viewedFields];

    //Add updated field
    viewedFields.push(validationKey);

    //Update with new validation set
    this.setState({ viewedFields: new Set(viewedFields) });
  };

  private renderFurtherInfo = (): JSX.Element => {
    const { medicalHistoryOther, patientId } = this.props;
    const segmentedInputOption = [
      { id: '1', name: 'Yes' },
      { id: '2', name: 'No' },
    ];
    return (
      <SectionField
        title={'Would you like further information about the Advance Care Directive?'}
        htmlFor={MHO_FIELDS.INFO.NAME}
        required={false}>
        <SegmentedInput
          options={segmentedInputOption}
          fieldName="advancedCareAdditionalInformation"
          optionAreBoolean={true}
          defaultSelected={medicalHistoryOther.advancedCareAdditionalInformation}
          itemSelected={(selectedItem): void => {
            const selectedValue = selectedItem as boolean;
            this.autoSaveAndValidate(
              {
                id: parseInt(patientId),
                advancedCareAdditionalInformation: selectedValue,
              },
              MHO_FIELDS.INFO.KEY,
              this.props.refetch,
            );
          }}
        />
      </SectionField>
    );
  };
}

//@ts-ignore
export default HAMedicalHistoryOther;
