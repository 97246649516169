import React, { useEffect, useState } from 'react';
import { gql, useLazyQuery, useQuery } from '@apollo/client';
import { Logger } from 'shared-components/utils';
import StandardDialog from '../StandardDialog/StandardDialog';
import { CustomTheme, Modal, useTheme } from '@mui/material';

const logger = new Logger('SentryUser');
export const GET_APP_VERSION = gql`
  query GetAppVersion {
    getAppVersion {
      appVersion
    }
  }
`;
const interval = 1000 * 60 * 60 * 2; // 2 hours
export const modalTitle = 'New Version Available';
const CountDownMessage = (props: { startTimer: boolean; handleReload: () => void }): JSX.Element => {
  const theme: CustomTheme = useTheme();
  const title = theme.custom.title;
  const [countdown, setCountdown] = useState(30);
  const startCountdown = () => {
    let timer = 30;
    setCountdown(timer);
    const countdownInterval = setInterval(() => {
      timer -= 1;
      setCountdown(timer);
      if (timer <= 0) {
        clearInterval(countdownInterval);
        props.handleReload();
      }
    }, 1000);
  };
  useEffect(() => {
    if (props.startTimer) {
      startCountdown();
    }
  }, [props.startTimer]);
  return (
    <p>
      A new version of {title} has been released. Please reload the page.
      <br />
      The page will automatically refresh in {countdown} seconds.
    </p>
  );
};

const AppVersion = (): JSX.Element => {
  const [open, setOpen] = useState(false);

  // Fetch the app version every interval to make we have the latest version
  const { data } = useQuery(GET_APP_VERSION, {
    fetchPolicy: 'cache-and-network',
    pollInterval: interval,
    onError: (error) => {
      logger.error('Error fetching app version', error);
    },
  });

  const handleReload = () => {
    localStorage.removeItem('appVersion');
    setOpen(false);
    window.location.reload();
  };
  useEffect(() => {
    // Check if the app version has changed
    if (data && data.getAppVersion && data.getAppVersion.appVersion && data.getAppVersion.appVersion !== null) {
      const storedVersion = localStorage.getItem('appVersion');
      const currentVersion = data.getAppVersion.appVersion;
      if (storedVersion === null) {
        localStorage.setItem('appVersion', currentVersion);
      } else if (storedVersion !== currentVersion) {
        // If the stored version is different from the current version, reload the page
        setOpen(true);
      }
    }
  }, [data]);

  return (
    <StandardDialog
      maxWidth="lg"
      open={open}
      title={modalTitle}
      onSubmit={() => {
        handleReload();
      }}
      submitText={'Refresh page'}>
      <CountDownMessage startTimer={open} handleReload={handleReload} />
    </StandardDialog>
  );
};
export default AppVersion;
