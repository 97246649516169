// eslint-disable-next-line no-use-before-define
import React, { Component } from 'react';
import dayjs from 'dayjs';
import './RORegBasic.scss';
import { PatientBasic, RORegPatientBasic } from 'op-interfaces/PatientInterfaces';
import { GraphUpdate, ListData } from 'shared-interfaces/index';
import { SavingStatus } from 'shared-components/enums';
import {
  MaskField,
  AddressFieldRO,
  AsyncAutocompleteRO,
  FormRow,
  ROHelperText,
  ROTextField,
  RODatePicker,
  ROAutocomplete,
  ROToggleButtons,
} from 'shared-components/components/FormFields';
import { region, ROMiniRegoHeader, AddressAutocomplete, UKPatientSearchModal } from 'op-components/index';
import { AddressUkComponents } from 'op-interfaces/AddressComponents';
import { AddressInputs } from 'shared-components/components/FormFields/types';
import { EMAIL_STRING_REGEX, UK_NAME_REGEX, UK_PHONE_REGEX } from 'shared-components/utils';
import { DeduplicationModal } from './DeduplicationModal';
import { Dayjs } from 'dayjs';
import { SelectOptionType } from 'shared-components/components/FormFields';
import { getOptionByValue } from 'op-pages/RO/Careplan/DiagnosisPages/Utils';
import { Stack } from '@mui/material';

interface Props {
  autosave: (patient: RORegPatientBasic, updateItems: [GraphUpdate], forceFetch?: object[]) => void;
  forceRefetch: () => void;
  patient: RORegPatientBasic;
  genderRefData: ListData[];
  locationsRefData: ListData[];
  titleReferenceData: ListData[];
  saveStatus: SavingStatus;
  validateOnLoad: boolean;
  countries: ListData[];
  ukCounties: ListData[];
  updatedFields: any[];
  onFieldVisit: (keys: string[]) => void;
}

interface SearchVariables {
  search?: string;
  dob?: string;
  existingPatient: string;
}

interface State {
  firstName: string;
  lastName: string;
  middleName: string;
  idb: string;
  gender: string;
  primaryPhone: string;
  secondaryPhone: string;
  email: string;
  validateAddressFields: boolean;
  autoCompleteBlankError: boolean;
  autoCompleteInvalidError: boolean;
  manualAddressEntry: boolean;
  openPreviousAttendance: boolean;
  searchVariables: SearchVariables;
}

const previousVisitOptions: SelectOptionType[] = [
  { label: 'Yes', value: 'Yes' },
  { label: 'No', value: 'No' },
];

const stringTable = {
  MAIN_HEADING: 'Basic details',
  SUBHEADING: 'Please enter patient basic details',
  FORM_LABELS: {
    TITLE: 'Title',
    FIRST_NAME: 'First name',
    LAST_NAME: 'Last name',
    MIDDLE_NAME: 'Middle name',
    PREVIOUS_VISIT: 'Has the patient attended any GenesisCare centres before?',
    GENDER: 'Gender',
    DATE_OF_BIRTH: 'Date of birth',
    IDB: 'NHS number',
    PRIMARY_CENTER: 'Primary centre',
    SURGEON_NAME: 'Referring clinician',
    PRIMARY_PHONE: 'Mobile phone number',
    SECONDARY_PHONE: 'Home phone number',
    EMAIL: 'Email',
    ADDRESS: 'Address',
  },
  FORM_KEYS: {
    TITLE: 'namePrefix',
    FIRST_NAME: 'firstName',
    LAST_NAME: 'lastName',
    MIDDLE_NAME: 'middleName',
    PREVIOUS_VISIT: 'previousVisit',
    GENDER: 'gender',
    DATE_OF_BIRTH: 'dobDateSelector',
    IDB: 'idb',
    SURGEON_NAME: 'referringSurgeon',
    PRIMARY_CENTER: 'primaryCenter',
    PRIMARY_PHONE: 'primaryPhone',
    SECONDARY_PHONE: 'secondaryPhone',
    EMAIL: 'email',
    ADDRESS: 'address',
  },
};

class RORegBasic extends Component<Props, State> {
  private forceFetchItems = [
    { address: { id: '', formattedAddress: '', ukFormattedAddress: '', __typename: 'AddressType' } },
  ];

  public componentWillUnmount(): void {
    this.props.onFieldVisit(Object.values(stringTable.FORM_KEYS));
  }

  public constructor(props: Props) {
    super(props);
    const { patient } = props;
    this.state = {
      firstName: patient.firstName,
      lastName: patient.lastName,
      middleName: patient.middleName,
      gender: patient.gender,
      idb: patient.idb,
      primaryPhone: patient.primaryPhone,
      secondaryPhone: patient.secondaryPhone,
      email: patient.email,
      autoCompleteBlankError: false,
      autoCompleteInvalidError: false,
      validateAddressFields: false,
      manualAddressEntry: !!patient.address && patient.address.formattedAddress !== '',
      searchVariables: {
        search: '',
        existingPatient: patient.id,
      },
      openPreviousAttendance: false,
    };
  }

  private autosave(key: string, value: string, validateOnBlur = true, type = 'String'): void {
    const { patient } = this.props;
    if (key === 'idb') {
      value = value.replace(/ /g, '').replace(/_/g, '');
    }

    const update = {
      key: key,
      value: value,
      type: type,
    };
    const propAutosave = async () => {
      await this.props.autosave(patient, [update]);
    };
    propAutosave().then(() => {
      if (validateOnBlur) {
        let modifiedKey = key;
        // Need to modify the key for validation purposes since the dob raw is made up of 3 fields now, but the error is still only displayed in one area. The error will need to be displayed in one location.
        if (key === 'dobRawYear') {
          modifiedKey = 'dobRaw';
        }
        this.props.onFieldVisit([modifiedKey]);
        //this.setState({ ...this.state, updatedFields: [...this.state.updatedFields, modifiedKey] });
      }

      const updateSearchVariable = (key: string): void => {
        let { searchVariables } = this.state;
        const { patient } = this.props;
        if (key === 'firstName' || key === 'lastName' || key === 'dob') {
          if (patient.dob) {
            searchVariables = {
              ...searchVariables,
              search: `${patient.firstName} ${patient.lastName}`,
              dob: patient.dob,
            };
          }
          this.setState({ searchVariables });
        }
      };
      setTimeout(updateSearchVariable, 300, key);
    });
  }

  public handlePreviousAttendanceChange(value: string) {
    const { patient } = this.props;
    if (value === 'Yes') {
      if (!patient.ida) {
        this.setState({ openPreviousAttendance: true });
      }
    }
    this.autosave('previousAttendance', value);
  }

  private validIdb(value: string | undefined): boolean | undefined {
    if (value === '') {
      return true;
    } else if (typeof value === 'string') {
      const mul = [];
      const newValue = value.replace(/ /g, '');
      for (let i = 0; i < 9; i++) {
        mul.push(parseInt(newValue[i]) * Number([i + 1]));
      }
      const sum = mul.reduce((n, a) => n + a, 0);
      const mod = sum % 11;
      return mod === parseInt(newValue[9]);
    }
  }

  private handleAddressAutocomplete = (components: AddressUkComponents): void => {
    const { countries, patient } = this.props;

    const tempPatientAddress: { [key: string]: string } = {
      residentialAddressLine1: '',
      residentialAddressLine2: '',
      residentialAddressCity: '',
      residentialAddressCountry: '',
      residentialAddressPostcode: '',
      residentialAddressState: '',
    };

    const countrymap = components.country === 'England' ? 'United Kingdom' : components.country;

    // if locality exists in address suggesstion, add it to line2
    const line2 = [components.line2, components.locality].filter((e) => e).join(', ');

    tempPatientAddress.residentialAddressLine1 = components.line1;
    tempPatientAddress.residentialAddressLine2 = line2;
    tempPatientAddress.residentialAddressCity = components.city;
    tempPatientAddress.residentialAddressPostcode = components.postcode;
    tempPatientAddress.residentialAddressState = components.county;

    const countryRef = countries.filter((value) => {
      if (value.name.toLowerCase() === countrymap.toLowerCase()) {
        return value;
      }
    });

    if (countryRef.length > 0) {
      tempPatientAddress.residentialAddressCountry = countryRef[0].id;
    } else {
      tempPatientAddress.residentialAddressCountry = countrymap;
    }

    const graphItems = Object.keys(tempPatientAddress).map((key) => {
      return {
        key,
        value: tempPatientAddress[key],
        type: 'String',
      };
    });
    this.autoSaveAddress(patient, graphItems as [GraphUpdate], this.forceFetchItems);
    this.setState({ manualAddressEntry: true });
  };

  private autoSaveAddress(patient: RORegPatientBasic, graphItems: [GraphUpdate], forceFetch?: object[]): void {
    const { updatedFields } = this.props;
    this.props.autosave(patient, graphItems, forceFetch);
    if (updatedFields.indexOf('addressAutocomplete') === -1) {
      this.props.onFieldVisit(['addressAutocomplete']);
    }
  }

  private handleAutoCompleteOnBlur = (e: React.FocusEvent<HTMLInputElement> | undefined, hasError: boolean): void => {
    const { patient, updatedFields } = this.props;
    if (e && e.target && e.target.value.trim() === '') {
      // The input field is now empty, so update the residential address to be empty
      const tempPatientAddress: { [key: string]: string } = {
        residentialAddressLine1: '',
        residentialAddressLine2: '',
        residentialAddressCity: '',
        residentialAddressCountry: '',
        residentialAddressPostcode: '',
        residentialAddressState: '',
      };

      const graphItems = Object.keys(tempPatientAddress).map((key) => {
        return {
          key,
          value: tempPatientAddress[key],
          type: 'String',
        };
      });

      this.setState({ autoCompleteBlankError: true, autoCompleteInvalidError: false });
      this.autoSaveAddress(patient, graphItems as [GraphUpdate], this.forceFetchItems);
    } else {
      this.setState({ autoCompleteBlankError: false, autoCompleteInvalidError: hasError });
    }
    if (updatedFields.indexOf('addressAutocomplete') === -1) {
      this.props.onFieldVisit(['addressAutocomplete']);
    }
  };

  public hideModal(): void {
    this.autosave('previousAttendance', 'No');
    this.setState({ openPreviousAttendance: false });
  }

  public render(): JSX.Element {
    const { patient, titleReferenceData, genderRefData, locationsRefData, ukCounties, countries, updatedFields } =
      this.props;
    const {
      firstName,
      middleName,
      lastName,
      gender,
      primaryPhone,
      secondaryPhone,
      email,
      manualAddressEntry,
      validateAddressFields,
      openPreviousAttendance,
      searchVariables,
      autoCompleteInvalidError,
    } = this.state;
    const titleOptions = titleReferenceData.map((title) => ({
      value: title.id,
      label: title.name,
    }));
    const genderOptions = genderRefData.map((data) => ({ label: data.name, value: data.id }));
    const primaryCenterOptions = locationsRefData.map((data) => ({ label: data.name, value: data.id }));

    const firstNameInvalid = !firstName || firstName.match(UK_NAME_REGEX)?.[0] !== firstName;
    const middleNameInvalid = middleName.match(UK_NAME_REGEX)?.[0] !== middleName;
    const lastNameInvalid = !lastName || lastName.match(UK_NAME_REGEX)?.[0] !== lastName;
    const primaryPhoneInvalid =
      primaryPhone || secondaryPhone ? (primaryPhone ? !primaryPhone.match(UK_PHONE_REGEX) : false) : true;
    const referringSurgeonInvalid = false;
    const secondaryPhoneInvalid =
      primaryPhone || secondaryPhone ? (secondaryPhone ? !secondaryPhone.match(UK_PHONE_REGEX) : false) : true;
    const emailInvalid = email ? !EMAIL_STRING_REGEX.test(String(email).toLowerCase()) : false;
    const today = dayjs();
    const isDOBValid = patient.dob
      ? dayjs(today).diff(patient.dob, 'year') >= 18 && dayjs(today).diff(patient.dob, 'year') <= 120
      : false;

    const residentialAddressInput: AddressInputs = {
      line1: {
        inputName: 'line1',
        defaultValue: patient.residentialAddressLine1,
        graphArgumentKey: 'residentialAddressLine1',
        maxlength: 60,
      },
      line2: {
        inputName: 'line2',
        defaultValue: patient.residentialAddressLine2,
        graphArgumentKey: 'residentialAddressLine2',
        maxlength: 60,
      },
      city: {
        inputName: 'city',
        defaultValue: patient.residentialAddressCity,
        graphArgumentKey: 'residentialAddressCity',
        maxlength: 40,
      },
      country: {
        inputName: 'country',
        defaultValue: patient.residentialAddressCountry,
        graphArgumentKey: 'residentialAddressCountry',
      },
      stateprovienceregion: {
        inputName: 'stateprovienceregion',
        defaultValue: patient.residentialAddressState,
        graphArgumentKey: 'residentialAddressState',
      },
      zipPostcode: {
        inputName: 'zippostcode',
        defaultValue: patient.residentialAddressPostcode,
        graphArgumentKey: 'residentialAddressPostcode',
      },
      state: {
        inputName: 'state',
        defaultValue: patient.residentialAddressState,
        graphArgumentKey: 'residentialAddressState',
      },
    };
    return (
      <div className="main-container ro-uk-full-width">
        <UKPatientSearchModal isOpen={openPreviousAttendance} dismissFunction={this.hideModal.bind(this)} />
        <DeduplicationModal
          redirect={'/radiation/patient/temppatientid/summary'}
          patient={patient as PatientBasic}
          //@ts-ignore
          variables={searchVariables}
        />
        <ROMiniRegoHeader title={stringTable.MAIN_HEADING} summary={stringTable.SUBHEADING} />
        <ROToggleButtons
          id="previousVisit"
          fieldlabel={stringTable.FORM_LABELS.PREVIOUS_VISIT}
          required
          options={previousVisitOptions}
          value={patient.ida || patient.previousAttendance ? 'Yes' : 'No'}
          handleChange={(value) => {
            this.handlePreviousAttendanceChange(value);
          }}
        />
        <ROAutocomplete
          fieldlabel={stringTable.FORM_LABELS.TITLE}
          required
          id="namePrefix"
          value={getOptionByValue(
            titleOptions.map((data) => ({ label: data.label, value: data.value })),
            patient.namePrefix,
          )}
          onChange={(option: SelectOptionType | string): void => {
            const value = typeof option === 'string' ? option : option.value;
            this.autosave('namePrefix', value);
          }}
          placeholder="Required"
          options={titleOptions}
          inputProps={{
            error: updatedFields.indexOf('namePrefix') > -1 && !patient.namePrefix,
            helperText: 'This field is required',
          }}
        />
        <ROTextField
          id="firstName"
          fieldlabel={stringTable.FORM_LABELS.FIRST_NAME}
          error={updatedFields.indexOf('firstName') > -1 && firstNameInvalid}
          helperText={firstName ? 'Please enter a valid name' : 'First name is required'}
          value={firstName}
          placeholder="Required"
          onChange={(event: React.ChangeEvent<HTMLInputElement>): void => {
            this.setState({ ...this.state, firstName: event.target.value });
          }}
          onBlur={() => this.autosave('firstName', firstName)}
          required
        />
        <ROTextField
          id="middleName"
          fieldlabel={stringTable.FORM_LABELS.MIDDLE_NAME}
          error={updatedFields.indexOf('middleName') > -1 && middleNameInvalid}
          helperText={'Please enter a valid name'}
          value={middleName}
          onChange={(event: React.ChangeEvent<HTMLInputElement>): void => {
            this.setState({ ...this.state, middleName: event.target.value });
          }}
          onBlur={() => this.autosave('middleName', middleName)}
        />
        <ROTextField
          id="lastName"
          fieldlabel={stringTable.FORM_LABELS.LAST_NAME}
          error={updatedFields.indexOf('lastName') > -1 && lastNameInvalid}
          helperText={lastName ? 'Please enter a valid name' : 'Last name is required'}
          value={lastName}
          placeholder="Required"
          onChange={(event: React.ChangeEvent<HTMLInputElement>): void => {
            this.setState({ ...this.state, lastName: event.target.value });
          }}
          onBlur={() => this.autosave('lastName', lastName)}
          required
        />
        <ROToggleButtons
          id="gender"
          required
          fieldlabel={stringTable.FORM_LABELS.GENDER}
          error={updatedFields.indexOf('gender') > -1 && !gender}
          helperText={updatedFields.indexOf('gender') > -1 && !gender ? 'This field is required' : undefined}
          options={genderOptions}
          value={gender}
          handleChange={(value: string) => {
            this.setState({ ...this.state, gender: value });
            this.autosave('gender', value);
          }}
        />
        <RODatePicker
          id="date-of-birth"
          fieldlabel={stringTable.FORM_LABELS.DATE_OF_BIRTH}
          required
          value={patient.dob ? dayjs(patient.dob) : null}
          maxDate={dayjs().subtract(18, 'year')}
          minDate={dayjs().subtract(121, 'year')}
          onAccept={(value: Dayjs | null): void => {
            const date = dayjs(value);
            if (date.isValid()) {
              this.autosave('dob', date.format('YYYY-MM-DD'));
            } else if (value === null) {
              this.autosave('dob', '');
            }
          }}
          openTo="year"
          onClose={() => {
            this.props.onFieldVisit(['dobDateSelector']);
          }}
          onBlur={(e: React.FocusEvent<HTMLInputElement | HTMLTextAreaElement, Element>): void => {
            const value = e.target.value;
            const date = dayjs(value, 'DD/MM/YYYY');
            if (date.isValid()) {
              this.autosave('dob', date.format('YYYY-MM-DD'));
            } else if (value === null) {
              this.autosave('dob', '');
            }
          }}
          error={updatedFields.indexOf('dobDateSelector') > -1 && !isDOBValid}
        />
        <FormRow id="nhs-number" fieldlabel={stringTable.FORM_LABELS.IDB}>
          <Stack>
            <Stack style={{ maxWidth: '300px' }}>
              <MaskField
                dataTestId={'nhs-number'}
                mask="999 999 9999"
                disabled={patient.lock && patient.lock.readOnly}
                inputName="miniregoBasicIdbField"
                inputType="text"
                defaultValue={patient.idb}
                onBlur={(e: any): void => {
                  this.autosave('idb', e.target.value);
                }}
              />
            </Stack>
            {(updatedFields.indexOf('idb') > -1 && !this.validIdb(patient.idb)) || patient.idbConflict ? (
              <ROHelperText
                id="disease"
                error={true}
                helperText={
                  patient.idbConflict ? 'This NHS number is already in use' : 'This is not a valid NHS number'
                }
              />
            ) : null}
          </Stack>
        </FormRow>
        <ROAutocomplete
          fieldlabel={stringTable.FORM_LABELS.PRIMARY_CENTER}
          required
          id="primaryCenter"
          value={getOptionByValue(
            primaryCenterOptions.map((data) => ({ label: data.label, value: data.value })),
            patient.primaryCenter,
          )}
          onChange={(option: SelectOptionType | string): void => {
            const value = typeof option === 'string' ? option : option.value;
            this.autosave('primaryCenter', value);
          }}
          placeholder="Required"
          options={primaryCenterOptions}
          inputProps={{
            error: updatedFields.indexOf('primaryCenter') > -1 && !patient.primaryCenter,
            helperText: 'This field is required',
          }}
        />
        <FormRow id="referring-surgeon" fieldlabel={stringTable.FORM_LABELS.SURGEON_NAME}>
          <AsyncAutocompleteRO
            dataTestId={'referring-surgeon'}
            disabled={false}
            inputName={stringTable.FORM_KEYS.SURGEON_NAME}
            placeholder={'Type to search'}
            grapheneField={'extPractitioners'}
            fullName={false}
            patient={patient.id}
            onBlur={(value): void => {
              //this.autosave(gpInfo, FIELD_NAMES.SURGEON_NAME, value);
              this.autosave(stringTable.FORM_KEYS.SURGEON_NAME, value);
            }}
            defaultValue={typeof patient.referringSurgeon === 'string' ? patient.referringSurgeon : ''}
          />
          {updatedFields.indexOf(stringTable.FORM_KEYS.SURGEON_NAME) > -1 && referringSurgeonInvalid ? (
            <ROHelperText id="disease" error={true} />
          ) : null}
        </FormRow>
        <ROTextField
          id="mobile-phone"
          fieldlabel={stringTable.FORM_LABELS.PRIMARY_PHONE}
          error={
            (updatedFields.indexOf('primaryPhone') > -1 || updatedFields.indexOf('secondaryPhone') > -1) &&
            primaryPhoneInvalid
          }
          helperText={
            !primaryPhone && !secondaryPhone ? 'At least one phone number is required' : 'Invalid phone number'
          }
          value={primaryPhone}
          onChange={(event: React.ChangeEvent<HTMLInputElement>): void => {
            this.setState({ ...this.state, primaryPhone: event.target.value });
          }}
          onBlur={() => {
            this.setState({ ...this.state, primaryPhone: this.state.primaryPhone.trim() });
            this.autosave('primaryPhone', primaryPhone);
          }}
          required
        />
        <ROTextField
          id="secondary-phone"
          fieldlabel={stringTable.FORM_LABELS.SECONDARY_PHONE}
          error={
            (updatedFields.indexOf('primaryPhone') > -1 || updatedFields.indexOf('secondaryPhone') > -1) &&
            secondaryPhoneInvalid
          }
          helperText={!secondaryPhone ? 'At least one phone number is required' : 'Invalid phone number'}
          value={secondaryPhone}
          onChange={(event: React.ChangeEvent<HTMLInputElement>): void => {
            this.setState({ ...this.state, secondaryPhone: event.target.value });
          }}
          onBlur={() => {
            this.setState({ ...this.state, secondaryPhone: this.state.secondaryPhone.trim() });
            this.autosave('secondaryPhone', secondaryPhone);
          }}
        />
        <ROTextField
          id="email"
          fieldlabel={stringTable.FORM_LABELS.EMAIL}
          error={updatedFields.indexOf('email') > -1 && emailInvalid}
          helperText="Please enter a valid email address."
          value={email}
          onChange={(event: React.ChangeEvent<HTMLInputElement>): void => {
            this.setState({ ...this.state, email: event.target.value });
          }}
          onBlur={() => this.autosave('email', email)}
        />
        <div className="addressField-RO">
          <FormRow id="residential-autocomplete" fieldlabel={stringTable.FORM_LABELS.ADDRESS}>
            <AddressAutocomplete
              isRO={true}
              placeholder={'Type to search'}
              name="residential-autocomplete"
              dataTestId="residential-country"
              fullWidth={true}
              onSelected={this.handleAddressAutocomplete}
              disabled={patient.lock && patient.lock.readOnly}
              loadingMessage={'Loading...'}
              defaultValue={patient.address && patient.address.ukFormattedAddress}
              inputOnBlur={this.handleAutoCompleteOnBlur}
              region={region}
            />
          </FormRow>
          <AddressFieldRO
            isMandatory={true}
            disabled={patient.lock && patient.lock.readOnly}
            validateForm={true}
            inputName="residential"
            addressInputs={residentialAddressInput}
            onBlur={(graphItems: [GraphUpdate]): void => {
              if (graphItems.length > 0) {
                this.props.onFieldVisit([graphItems[0]['key']]);
              }
              this.autoSaveAddress(this.props.patient, graphItems, this.forceFetchItems);
            }}
            countries={countries}
            ausStates={ukCounties}
          />
        </div>
      </div>
    );
  }
}

export default RORegBasic;
