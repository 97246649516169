import Dashboards from '@highcharts/dashboards';
import DataPoolOptions from '@highcharts/dashboards/es-modules/Data/DataPoolOptions';
import ChartArea from '../Charts/ChartArea';
import { EditModeConfig, GuiConfig, DashboardConfig, SUB_CHART_LIMIT } from './DefaultConfig';
import { aggregateAdherenceValues, aggregateAdherenceByField } from '../../Utils/dataFunctions';
import SubChartStackedBarPercent from '../Charts/SubChartStackedBarPercent';

const CarepathUtilisation = ({
  theme,
  data,
  subChartData,
  dateFilterOverride,
  selectedIndex,
  seeMoreUpdated,
  seeMoreBottomLeft,
  seeMoreBottomRight,
  toggleSeeMoreBottomLeft,
  toggleSeeMoreBottomRight,
  onPointClick,
}: DashboardConfig): Dashboards.Board.Options => ({
  dataPool: dataPoolConfig(data, subChartData, seeMoreBottomLeft, seeMoreBottomRight),
  editMode: EditModeConfig,
  gui: GuiConfig,
  components: [
    ChartArea({
      onPointClick: onPointClick,
      selectedPoint: dateFilterOverride,
      selectedIndex: selectedIndex,
      seeMoreUpdated: seeMoreUpdated,
      leftSeriesTitle: 'Careplan adherence',
      rightSeriesTitle: 'Peer review',
      leftSeriesFields: ['Date', 'Adherence'],
      rightSeriesFields: ['Date', 'Peer Review'],
      extraSeriesFields: ['Date', 'Non-Adherent'],
    }).components?.[0] ?? {},
    SubChartStackedBarPercent({
      filterOverride: dateFilterOverride,
      seeMore: seeMoreBottomLeft,
      title: 'Case Mix',
      connector: 'sub-chart-left-data',
      cell: 'sub-chart-left',
      theme: theme,
      toggleSeeMore: toggleSeeMoreBottomLeft,
    }).components?.[0] ?? {},
    SubChartStackedBarPercent({
      filterOverride: dateFilterOverride,
      seeMore: seeMoreBottomRight,
      title: 'Physicians',
      connector: 'sub-chart-right-data',
      cell: 'sub-chart-right',
      theme: theme,
      toggleSeeMore: toggleSeeMoreBottomRight,
    }).components?.[0] ?? {},
  ],
});

const dataPoolConfig = (
  data: any,
  subChartData: any,
  seeMoreBottomLeft: boolean,
  seeMoreBottomRight: boolean,
): DataPoolOptions => ({
  connectors: [
    {
      id: 'top-chart-data',
      type: 'JSON',
      options: {
        firstRowAsNames: false,
        columnNames: ['Date', 'Adherence', 'Non-Adherent', 'Peer Review'],
        data: aggregateAdherenceValues(data, 'months'),
      },
    },
    {
      id: 'sub-chart-left-data',
      type: 'JSON',
      options: {
        firstRowAsNames: false,
        columnNames: ['Field', 'Adherence', 'Non-Adherent'],
        data: aggregateAdherenceByField(subChartData, 'diagnosis', seeMoreBottomLeft ? undefined : SUB_CHART_LIMIT),
      },
    },
    {
      id: 'sub-chart-right-data',
      type: 'JSON',
      options: {
        firstRowAsNames: false,
        columnNames: ['Field', 'Adherence', 'Non-Adherent'],
        data: aggregateAdherenceByField(subChartData, 'physician', seeMoreBottomRight ? undefined : SUB_CHART_LIMIT),
      },
    },
  ],
});

export default CarepathUtilisation;
