import React from 'react';

const initialErrorContext = {
  state: {
    error: '',
  },
  setError: (_error: string): void => {},
};

const ErrorModalContext = React.createContext(initialErrorContext);

export default ErrorModalContext;
