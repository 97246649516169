// eslint-disable-next-line no-use-before-define
import React, { Component, Fragment } from 'react';
import { HorizonLogoNavigator } from 'shared-components/components';

import { RouteComponentProps, Redirect, NavLink } from 'react-router-dom';
import { Query } from '@apollo/client/react/components';
import { gql } from '@apollo/client';
import { Stack } from '@mui/material';

interface UserQuery {
  loading: boolean;
  error: string;
  data: {
    user: {
      email: string;
      firstName: string;
      lastName: string;
      isPso: boolean;
      isRo: boolean;
      isSuperuser: boolean;
      isShowcaseUser: boolean;
    };
  };
}

interface UserType {
  user: {
    email: string;
    firstName: string;
    lastName: string;
    isPso: boolean;
    isRo: boolean;
    isSuperuser: boolean;
    isShowcaseUser: boolean;
  };
}

const GET_USER_QUERY = gql`
  {
    user {
      id
      email
      firstName
      lastName
      isPso
      isRo
      isSuperuser
      isShowcaseUser
    }
  }
`;

class AppSelector extends Component<RouteComponentProps> {
  public renderInnerComponent(): JSX.Element {
    return (
      <Query<UserType> query={GET_USER_QUERY} fetchPolicy="network-only">
        {({ error, data }): JSX.Element => {
          if (error) return <Redirect to="/error" />;
          if (data) {
            const { isPso, isRo, isShowcaseUser } = data.user;
            return (
              <div className="filter-wrapper">
                <div className="create-patient-button-wrapper">
                  <Stack gap="8px">
                    {isPso && <NavLink to="/search">PAO portal</NavLink>}
                    {(isRo || isShowcaseUser) && <NavLink to="/radiation">RO portal</NavLink>}
                  </Stack>
                </div>
              </div>
            );
          }
          return <div>Loading...</div>;
        }}
      </Query>
    );
  }
  public render(): JSX.Element {
    return (
      <Fragment>
        <div id="navbar-container">
          <HorizonLogoNavigator linkToSearch={false} />
        </div>
        <div className="CC-dashboard-wrapper">
          <div className="patient-dashboard-wrapper">
            <div className="title-wrapper">
              <div className="table-title-wrapper">
                <span className="table-title">App selector</span>
              </div>
            </div>
            {this.renderInnerComponent()}
          </div>
        </div>
      </Fragment>
    );
  }
}

export default AppSelector;
