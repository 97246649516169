export const FILE_SIZE_ERROR = 'Max file size is 20MB';
export const ATTACHMENT_AMOUNT_ERROR = 'Patient can only have up to 10 attachments';
export const FORGOT_UPLOAD_TITLE = 'Forgot to upload?';
export const FORGOT_UPLOAD_TEXT = 'Please upload the last file you added or remove it from the list.';

export const DELETE_RETRY = 'Please delete and upload again';
export const UNKNOWN_ERROR = 'An unknown error occurred';

export const AUSFIELD = {
  HELPER_TEXT:
    'To help our staff prepare for your upcoming appointment, you are required to upload a copy or photo of the following documents and cards (if they are applicable to you)',
  HELPER_TITLE: 'Documents to upload (if applicable)',
  DOCUMENTS: {
    MEDICARE: 'Medicare card',
    PRIVATE_INSURANCE: 'Private insurance card',
    PENSION: 'Pension card',
    DVA: 'DVA card',
    TEST_RESULTS: 'Test results',
    PACEMAKER: 'Pacemaker card',
    REFERRAL: 'Referral letter',
    ATTORNEY: 'Power of attorney',
    AMBULANCE: 'Ambulance membership',
    MEDICATIONS: 'Medications',
    ADVANCE_CARE: 'Advance care directive',
  },
  ATTACHMENTS_INFO:
    'If you do not have an electronic copy of any of the above documents, please bring them with you to your first appointment',
  SYSTEM_STATE_MSG:
    'Horizon will try to choose a document type for patient-uploaded attachments. Please review each the following attachments and acknowledge once document type/s are correct.',
  MODAL: {
    HEADER: 'Are you sure you want to delete this attachment?',
    SUBMIT: 'Delete',
  },
  PATIENT_ATTACHMENTS: {
    FILE_NAME: 'File name',
    PREVIEW: 'Preview',
    DELETE: 'Delete',
    DOC_TYPE: 'Document type*',
    SUGGESTED_DOC_TYPE: 'Suggested document type',
  },
  ACKNOWLEDGMENT: {
    TITLE: 'Acknowledgement*',
    SUBTITLE: '(Please tick after uploading any additional attachments)',
    INPUT_LABEL: 'I have reviewed and acknowledge the above document labels are correct',
    INPUT_ERROR: 'Document types must be reviewed and acknowledged',
  },
  CARD: {
    TAKE_OR_ATTACH: 'Take photos or attach files here',
    DRAG_AND_DROP: 'Drag and drop files here',
    CHOOSE_FILE: 'Choose file or photo',
    LIMIT_REACHED: 'File limit of 10 has been reached.',
    REMOVE_EXISTING: 'If you would like to upload something else, please remove one of the existing files.',
    FILE_TYPES: 'File types accepted: JPG, JPEG, BMP, PDF, PNG, GIF',
  },
};

export const UKFIELD = {
  HEADER: {
    FILENAME: 'Filename',
    FILE_SIZE: 'File size',
    DOC_TYPE: 'Document Type',
    SUBMIT_TO_MOSAIQ: 'Submitted to Mosaiq?',
    ENCOUNTER_DATE: 'Encounter date',
    UPLOAD: 'Upload',
    PROGRESS: 'Progress',
  },
  REMOVE_OR_UPLOAD: 'Please remove or upload the above file(s) before adding more',
  CLICK_OR_DRAG: 'Click or drag here to attach some files',
};
