import { gql } from '@apollo/client';

export const CreateAUSAttachmentMutation = gql`
  mutation createAttachment(
    $awsKey: String!
    $patientPk: String!
    $encounterDate: String!
    $isRegistrationAttachment: Boolean
  ) {
    createAttachment(
      awsKey: $awsKey
      patientPk: $patientPk
      encounterDate: $encounterDate
      isRegistrationAttachment: $isRegistrationAttachment
    ) {
      attachment {
        id
        url
        documentType
        submitted
        encounterDate
        isRegistrationAttachment
      }
    }
  }
`;

export const CreateUKAttachmentMutation = gql`
  mutation createAttachment(
    $awsKey: String!
    $patientPk: String!
    $documentType: String!
    $encounterDate: String!
    $isRegistrationAttachment: Boolean
  ) {
    createAttachment(
      awsKey: $awsKey
      patientPk: $patientPk
      documentType: $documentType
      encounterDate: $encounterDate
      isRegistrationAttachment: $isRegistrationAttachment
    ) {
      attachment {
        id
        url
        documentType
        submitted
        encounterDate
        isRegistrationAttachment
      }
    }
  }
`;

export const ChangeDocumentTypeMutation = gql`
  mutation changeDocumentType($attachmentPk: String!, $documentType: String!) {
    changeDocumentType(attachmentPk: $attachmentPk, documentType: $documentType) {
      attachment {
        id
        url
        documentType
        submitted
        encounterDate
      }
    }
  }
`;

export const ChangeEncounterDateMutation = gql`
  mutation changeEncounterDate($attachmentPk: String!, $encounterDate: String!) {
    changeEncounterDate(attachmentPk: $attachmentPk, encounterDate: $encounterDate) {
      attachment {
        id
        url
        documentType
        submitted
        encounterDate
      }
    }
  }
`;

export const DeleteDocumentMutation = gql`
  mutation deleteDocument($attachmentPk: String!) {
    deleteAttachment(attachmentPk: $attachmentPk) {
      ok
    }
  }
`;

export const GET_UPLOAD_DETAILS_QUERY = gql`
  query GetUploadDetails($fileName: String!) {
    fileUploadPayload(fileName: $fileName) {
      url
      fields {
        awsKey
        awsPayload
      }
    }
  }
`;
