// eslint-disable-next-line no-use-before-define
import { useMutation } from '@apollo/client';
import _ from 'lodash';
import { HACancerItem } from 'op-classes';
import { MoreInfoText } from 'op-components';
import { CancerItem, TreatmentItem } from 'op-interfaces';
import {
  HADropDownField,
  HAFreeTextField,
  DropDownRODay,
  DropDownROMonth,
  DropDownROYear,
} from 'op-pages/OP/HealthAssessment/HASharedComponents';
import { isUs, resolveListDataValue } from 'op-utils';
import React, { useEffect, useState } from 'react';

import { SectionField } from 'shared-components/components/FormFields';
import { Months } from 'shared-components/enums';
import { MinusNoFill } from 'shared-components/images';
import { ListData } from 'shared-components/interfaces';
import { styled } from '@mui/system';
import { CR_MODAL_FIELDS, TREATMENT_TYPES } from '../constants';
import { UPDATE_HA_CANCER_MODAL } from './HAModalQueries';
import { Add as AddIcon } from '@mui/icons-material';
import { StandardDialog } from 'shared-components/components';
import { Stack } from '@mui/material';

const DEFAULT_CANCER_ITEM: CancerItem = {
  id: '-1',
  cancerType: '',
  cancerStage: '',
  treatment: [],
};

const DEFAULT_TREATMENT_ITEM: TreatmentItem = {
  other: '',
  treatmentType: '',
  hospital: '',
  doctor: '',
  bodyPart: '',
  stage: '',
  treatmentRawYear: '',
  treatmentRawDay: '',
  treatmentRawMonth: '',
  medication: '',
};

const StyledTreatmentTitle = styled('div')`
  font-size: 18px;
  font-weight: bold;
  line-height: 28px;
  margin-bottom: 9px;
`;

const StyledCancerSection = styled('div')`
  background-color: ${(props) => props.theme.palette.grey[100]};
  padding: 16px 24px;
  margin-bottom: 16px;
`;

interface ModalField {
  KEY: string;
  NAME: string;
  TITLE: string;
  HINT?: string;
}

interface Props {
  isOpen?: boolean;
  medicalHistoryCancer: HACancerItem;
  selectedCancerId: string | undefined;
  patientId: string;
  dismissModal: () => void;
  treatmentTypesRefData: ListData[];
  treatmentStagesRefData: ListData[];
}

const renderRemoveItem = (buttonText: string, onClick: () => void): JSX.Element => (
  <div className="remove-item">
    <div onClick={onClick}>
      <MinusNoFill className="icon" />
      {buttonText}
    </div>
  </div>
);

const renderAdditionalItem = (buttonText: string, onClick: () => void): JSX.Element => (
  <div>
    <div data-test-id={buttonText} className="additional-item-button" onClick={onClick}>
      <AddIcon className="icon" color="primary" />
      {buttonText}
    </div>
  </div>
);

const HAMedicalHistoryCancerModal = (props: Props): JSX.Element => {
  const {
    isOpen,
    dismissModal,
    medicalHistoryCancer,
    selectedCancerId,
    patientId,
    treatmentTypesRefData,
    treatmentStagesRefData,
  } = props;
  const selectedCancer = medicalHistoryCancer.cancer.find((c: CancerItem) => c?.id?.toString() === selectedCancerId);
  const [cancer, setCancer] = useState<CancerItem>(selectedCancer || DEFAULT_CANCER_ITEM);
  const [treatments, setTreatments] = useState<TreatmentItem[]>(selectedCancer?.treatment || [DEFAULT_TREATMENT_ITEM]);
  const [updateHaCancer] = useMutation(UPDATE_HA_CANCER_MODAL);

  const resetData = () => {
    const selectedCancer =
      medicalHistoryCancer.cancer.find((c: CancerItem) => c?.id?.toString() === selectedCancerId) ||
      DEFAULT_CANCER_ITEM;
    setCancer(_.omit(selectedCancer, '__typename'));
    setTreatments(
      selectedCancer?.treatment?.map(
        (t: TreatmentItem) => _.omit(t, ['__typename', 'treatmentDate']) as TreatmentItem,
      ) || [DEFAULT_TREATMENT_ITEM],
    );
    dismissModal();
  };

  const updateCancer = () => {
    cancer.treatment = treatments;
    updateHaCancer({ variables: { cancer, patientId, haId: medicalHistoryCancer.id } });
    dismissModal();
  };

  const updateTreatment = (treatmentIndex: number, field: string, value: any) => {
    setTreatments(
      [...treatments].map((object, index) => {
        if (index === treatmentIndex) {
          return {
            ...object,
            [field]: value,
          };
        }
        return object;
      }),
    );
  };

  const checkStageOfTreatment = (treatment: TreatmentItem, index: number): JSX.Element => {
    const resolvedStageType = resolveListDataValue(treatment.stage, treatmentStagesRefData);
    if (['Not started', 'Unsure'].includes(resolvedStageType)) return <></>;
    return renderDateOfTreatement(treatment, index);
  };

  const treatmentFreeTextField = (
    field: string,
    modalFields: ModalField,
    treatment: TreatmentItem,
    index: number,
    required = true,
  ) => {
    const defaultValue = treatment[field as keyof TreatmentItem];
    return (
      <HAFreeTextField
        name={modalFields.NAME}
        title={modalFields.TITLE}
        required={required}
        defaultValue={defaultValue || ''}
        hint={modalFields.HINT}
        onBlur={(e: React.ChangeEvent<HTMLInputElement>) => {
          updateTreatment(index, field, e.target.value);
        }}
      />
    );
  };

  const cancerFreeTextField = (field: string, modalFields: ModalField, cancer: CancerItem, required = true) => {
    // @ts-ignore
    const defaultValue = cancer[field];
    return (
      <HAFreeTextField
        name={modalFields.NAME}
        title={modalFields.TITLE}
        required={required}
        defaultValue={defaultValue}
        hint={modalFields.HINT}
        onBlur={(e: React.ChangeEvent<HTMLInputElement>) => {
          // @ts-ignore
          cancer[field] = e.target.value;
          setCancer({ ...cancer });
        }}
      />
    );
  };

  const dropDownField = (
    field: string,
    modalFields: ModalField,
    treatment: TreatmentItem,
    options: ListData[],
    index: number,
  ) => {
    const defaultValue = treatment[field as keyof TreatmentItem];
    const onChange = (e: React.ChangeEvent<HTMLSelectElement>): void => {
      updateTreatment(index, field, e.target.value);
    };
    return (
      <HADropDownField
        name={modalFields.NAME}
        title={modalFields.TITLE}
        defaultValue={defaultValue || ''}
        hint={modalFields.HINT}
        options={options}
        onChange={onChange}
      />
    );
  };

  useEffect(() => {
    const selectedCancer =
      medicalHistoryCancer.cancer.find((c: CancerItem) => c?.id?.toString() === selectedCancerId) ||
      DEFAULT_CANCER_ITEM;
    setCancer(_.omit(selectedCancer, '__typename'));
    setTreatments(
      selectedCancer?.treatment?.map(
        (t: TreatmentItem) => _.omit(t, ['__typename', 'treatmentDate']) as TreatmentItem,
      ) || [DEFAULT_TREATMENT_ITEM],
    );
  }, [selectedCancerId, medicalHistoryCancer]);

  const renderDateOfTreatement = (treatment: TreatmentItem, index: number): JSX.Element => {
    const day = (
      <DropDownRODay
        id={`${CR_MODAL_FIELDS.TREATMENT_DATE_RAW_DAY.NAME}-${index}`}
        menuPlacement="top"
        value={treatment.treatmentRawDay}
        onChange={(val): void => {
          updateTreatment(index, 'treatmentRawDay', val);
        }}
        selectedMonth={parseInt(treatment.treatmentRawMonth)}
        selectedYear={parseInt(treatment.treatmentRawYear)}
      />
    );
    const month = (
      <DropDownROMonth
        id={`${CR_MODAL_FIELDS.TREATMENT_DATE_RAW_MONTH.NAME}-${index}`}
        menuPlacement="top"
        value={parseInt(treatment.treatmentRawMonth) > 0 ? Months[parseInt(treatment.treatmentRawMonth) - 1] : ''}
        onChange={(val): void => {
          const resultVal = Months.indexOf(val) >= 0 ? Months.indexOf(val) + 1 : '';
          updateTreatment(index, 'treatmentRawMonth', resultVal.toString());
        }}
        selectedYear={parseInt(treatment.treatmentRawYear)}
      />
    );

    return (
      <div className="date-of-operation-container">
        <div className="date-dd-mm-yyy">
          <SectionField htmlFor={CR_MODAL_FIELDS.TREATMENT_DATE.NAME} title={CR_MODAL_FIELDS.TREATMENT_DATE_HEADING}>
            <div className="treatment-date">
              {isUs() ? month : day}
              {isUs() ? day : month}
              <DropDownROYear
                id={`${CR_MODAL_FIELDS.TREATMENT_DATE_RAW_YEAR.NAME}-${index}`}
                menuPlacement="top"
                value={treatment.treatmentRawYear}
                onChange={(val: string): void => {
                  updateTreatment(index, 'treatmentRawYear', val);
                }}
              />
            </div>
            <div data-test-id="date-hint-text" className="example-text">
              {CR_MODAL_FIELDS.TREATMENT_DATE_EXAMPLE_TEXT}
            </div>
          </SectionField>
        </div>
      </div>
    );
  };

  const renderTreatementRadioTherapy = (treatment: TreatmentItem, index: number): JSX.Element => (
    <>
      {treatmentFreeTextField('hospital', CR_MODAL_FIELDS.HOSPITAL, treatment, index)}
      {treatmentFreeTextField('doctor', CR_MODAL_FIELDS.DOCTOR, treatment, index, false)}
      {treatmentFreeTextField('bodyPart', CR_MODAL_FIELDS.BODY, treatment, index)}
      {renderDateOfTreatement(treatment, index)}
    </>
  );

  const renderTreatementChemoAndImmuno = (treatment: TreatmentItem, index: number): JSX.Element => (
    <>
      {dropDownField('stage', CR_MODAL_FIELDS.STAGE, treatment, treatmentStagesRefData, index)}
      {treatmentFreeTextField('doctor', CR_MODAL_FIELDS.DOCTOR, treatment, index, false)}
      {checkStageOfTreatment(treatment, index)}
    </>
  );

  const renderHormonalTherapy = (treatment: TreatmentItem, index: number): JSX.Element => (
    <>
      {treatmentFreeTextField('medication', CR_MODAL_FIELDS.HORMONAL, treatment, index)}
      {renderDateOfTreatement(treatment, index)}
    </>
  );

  const renderOther = (treatment: TreatmentItem, index: number): JSX.Element =>
    treatmentFreeTextField('other', CR_MODAL_FIELDS.OTHER, treatment, index);

  const renderTreatmentFields = (treatment: TreatmentItem, index: number): JSX.Element => {
    const resolvedTreatmentType = resolveListDataValue(treatment.treatmentType, treatmentTypesRefData);
    switch (resolvedTreatmentType) {
      case TREATMENT_TYPES.RADIATION_THERAPY:
        return renderTreatementRadioTherapy(treatment, index);
      case TREATMENT_TYPES.CHEMOTHERAPY:
      case TREATMENT_TYPES.IMMUNOTHERAPY:
        return renderTreatementChemoAndImmuno(treatment, index);
      case TREATMENT_TYPES.HORMONAL_THERAPY:
        return isUs() ? renderHormonalTherapy(treatment, index) : <></>;
      case TREATMENT_TYPES.OTHER:
        return renderOther(treatment, index);
      default:
        return <></>; // Nothing is selected
    }
  };

  const renderCancerTreatments = (): JSX.Element => {
    const isFirstTreatment = treatments?.length === 1;
    const treatmentSection: JSX.Element[] = treatments.map((treatment, index): JSX.Element => {
      return (
        <StyledCancerSection key={index} data-testid={`treatment-section-${index}`}>
          <StyledTreatmentTitle>{`Treatment ${index + 1}`}</StyledTreatmentTitle>
          {dropDownField('treatmentType', CR_MODAL_FIELDS.TREATMENT_TYPE, treatment, treatmentTypesRefData, index)}
          {renderTreatmentFields(treatment, index)}
          {!isFirstTreatment &&
            renderRemoveItem('Remove treatment', (): void => {
              setTreatments([...treatments].filter((t: TreatmentItem) => t.id !== treatment.id));
            })}
        </StyledCancerSection>
      );
    });

    return (
      <Stack width={1}>
        <MoreInfoText showIcon={false} moreInfoText={'Please start with the most recent diagnosis'} />
        {cancerFreeTextField('cancerType', CR_MODAL_FIELDS.CANCER_TYPE, cancer, true)}
        {isUs() && cancerFreeTextField('cancerStage', CR_MODAL_FIELDS.CANCER_STAGE, cancer, true)}
        {treatmentSection}
        {renderAdditionalItem('Add another treatment', (): void => {
          if (cancer) {
            setTreatments((treatments) => [...treatments, DEFAULT_TREATMENT_ITEM]);
          }
        })}
      </Stack>
    );
  };

  return (
    <StandardDialog
      title="Enter Cancer Type"
      open={isOpen || false}
      maxWidth="sm"
      fullWidth
      onClose={resetData}
      onSubmit={updateCancer}
      submitText="Add">
      {renderCancerTreatments()}
    </StandardDialog>
  );
};

export default HAMedicalHistoryCancerModal;
