import { useContext, useState, useEffect } from 'react';
import Highcharts from 'highcharts';
import HighchartsReact from 'highcharts-react-official';
import { DashboardContext } from '../DashboardContext';
import ArrowForward from '@mui/icons-material/ArrowForward';
import { findParentById } from '../Utils/utils';
import { Typography } from '@mui/material';
import { useTheme } from '@mui/material/styles';

const shortenMonth = (dateString: string) => {
  const month = dateString.split(' ')[0];
  const year = dateString.split(' ')[1];
  return month.substring(0, 3) + ' ' + year.substring(2, 4);
};

const OverviewChart = (props: { aggregatedData: any[]; targetData: any[]; yAxisSuffix: string }) => {
  const { aggregatedData, targetData, yAxisSuffix } = props;
  const { overviewChartsLoaded, isReloaded, setOverviewChartsLoaded } = useContext(DashboardContext);

  return (
    <div className="overview-card-chart">
      <HighchartsReact
        highcharts={Highcharts}
        containerProps={{ style: { height: '100%' } }}
        options={{
          chart: {
            type: 'line',
            backgroundColor: '#fff',
            spacing: [15, 0, 0, 5],
            events: {
              // hacking the chart height to fit the container, because highcharts doesnt play well with flexbox
              load: function (this: Highcharts.Chart) {
                setOverviewChartsLoaded(true);
                const currentElement = findParentById(this.container, 'overview-chart-container');
                const parentSize = currentElement?.getBoundingClientRect().height;

                // for some reason chart heights are different when the charts are first loaded, and also when the page is reloaded
                const offset = overviewChartsLoaded || !isReloaded ? 115 : 135;

                if (parentSize) {
                  this.setSize(undefined, Number((parentSize - offset).toFixed(0)));
                  sessionStorage.removeItem('reloaded');
                }
              },
            },
          },
          exporting: {
            enabled: false,
          },
          title: {
            text: '',
          },
          legend: {
            enabled: targetData.length > 0 ? true : false,
            align: 'right',
            floating: true,
            y: -25,
            verticalAlign: 'top',
          },
          credits: {
            enabled: false,
          },
          xAxis: {
            categories: aggregatedData.map((point) => shortenMonth(point[0].toString())),
            tickmarkPlacement: 'on',
          },
          yAxis: {
            title: {
              text: null,
            },
            labels: {
              enabled: true,
              formatter: function (this: Highcharts.AxisLabelsFormatterContextObject): string {
                return `${this.value}${yAxisSuffix}`;
              },
            },
          },
          tooltip: {
            enabled: false,
          },
          plotOptions: {
            series: {
              marker: {
                enabled: false,
              },
              states: {
                hover: {
                  enabled: false,
                },
              },
              enableMouseTracking: false,
            },
          },
          series: [
            {
              data: aggregatedData.map((point) => point[1]),
              color: '#AAA1FD',
              name: 'Actual',
              showInLegend: false,
            },
            {
              data: targetData,
              color: '#FF969D',
              name: 'Target',
            },
          ],
        }}
      />
    </div>
  );
};

const getDeterministicOffset = (value: number, min: number, max: number) => {
  const hash = value
    .toString()
    .split('')
    .reduce((acc, char) => {
      return char.charCodeAt(0) + ((acc << 5) - acc);
    }, 0);

  return (Math.abs(hash) % (max - min + 1)) + min;
};

const OverviewCard = (props: {
  title: string;
  page: string;
  metricSuffix?: string;
  metricRound?: number;
  targetType?: string;
  aggregateFunction: (data: any, aggregateBy: 'days' | 'weeks' | 'months') => (string | number)[][];
  metricFunction: (data: any[]) => number;
}): JSX.Element => {
  const { filteredData, previousPeriodData, dateRange, navToPage } = useContext(DashboardContext);
  const { title, page, metricSuffix = '', metricRound = 0, targetType, aggregateFunction, metricFunction } = props;

  const [aggregatedData, setAggregatedData] = useState<any[][]>([]);
  const [targetData, setTargetData] = useState<any[]>([]);
  const [metric, setMetric] = useState<string>('');
  const [previousPeriodMetric, setPreviousPeriodMetric] = useState<number>(0);
  const [metricChangeIndicator, setMetricChangeIndicator] = useState<string>('');

  const theme = useTheme();

  useEffect(() => {
    setAggregatedData(aggregateFunction(filteredData, 'months'));
    setMetric(metricFunction(filteredData).toFixed(metricRound) + metricSuffix);

    const current = metricFunction(filteredData);
    const previous = metricFunction(previousPeriodData);
    const percentChange = (Math.abs(current - previous) / previous) * 100;
    setPreviousPeriodMetric(percentChange);
    setMetricChangeIndicator(current > previous ? 'more' : 'less');
  }, [filteredData]);

  useEffect(() => {
    // target data does not exist in the mock data at the moment. Making up values for it for now
    const average = aggregatedData.reduce((acc, point) => acc + point[1], 0) / aggregatedData.length;

    if (targetType === 'flat') {
      setTargetData(aggregatedData.map((point: any) => average));
    } else if (targetType === 'track') {
      setTargetData(aggregatedData.map((point: any) => getDeterministicOffset(point[1], average - 5, average + 5)));
    }
  }, [aggregatedData]);

  return (
    <div className="overview-card" id="overview-chart-container" onClick={() => navToPage(page)}>
      <div className="overview-card-metric-container">
        <Typography className="overview-card-title">{title}</Typography>
        {dateRange !== 'All time' && (
          <ArrowForward
            style={{
              transform: `rotate(${metricChangeIndicator === 'more' ? '-' : ''}90deg)`,
            }}
          />
        )}
      </div>
      <Typography variant="h5" sx={{ lineHeight: 1, marginBottom: '5px' }}>
        {metric}
      </Typography>
      <Typography variant="body2" color={theme.palette.text.secondary} sx={{ marginBottom: '8px' }}>
        {dateRange !== 'All time' && (
          <div>
            {previousPeriodMetric.toFixed(metricRound)}% {metricChangeIndicator} than previous{' '}
            {dateRange.split('Past ')[1]}
          </div>
        )}
      </Typography>
      <OverviewChart aggregatedData={aggregatedData} targetData={targetData} yAxisSuffix={metricSuffix} />
    </div>
  );
};

export default OverviewCard;
