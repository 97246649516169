import React, { useMemo } from 'react';
import { styled } from '@mui/system';
import { EMROutlined } from 'shared-components/images';
import { GET_PATIENT_EMR } from './SideNavQueries';
import { useQuery } from '@apollo/client';
import { Typography, useTheme } from '@mui/material';

interface Props {
  patientId: string;
}

const EMRContainer = styled('div')`
  display: flex;
  border: 1px ${(props) => props.theme.palette.grey[300]};
  border-style: solid none;
  justify-content: flex-start;
  padding: 21px 16px 18px 20px;
  align-items: center;
  width: 100%;
`;

const PatientEMR = ({ patientId }: Props): JSX.Element => {
  const { data } = useQuery(GET_PATIENT_EMR, {
    variables: { patientId },
  });
  const theme = useTheme();

  const emr = useMemo(() => data?.patient?.emrInstance || '-', [data?.patient?.emrInstance]);
  return (
    <EMRContainer data-testid="emr-icon-container">
      <EMROutlined />
      <Typography
        color={theme.palette.text.secondary}
        paddingLeft={1}
        variant="subtitle1"
        data-testid="emr-display-text">
        EMR: {emr}
      </Typography>
    </EMRContainer>
  );
};

export default PatientEMR;
