import React from 'react';
import { styled } from '@mui/system';
import Divider from './Divider';
import ActionButton from './ActionButton';

const Wrapper = styled('div')`
  width: 100%;
`;

const HeaderWrapper = styled('div')`
  margin: 40px 0 32px 0;
  display: flex;
  font-weight: bold;
`;

const Header = styled('div')`
  font-size: 1.125rem;
  width: 50%;
`;

const Footer = styled('div')`
  display: flex;
  justify-content: flex-end;
  width: 50%;
`;

interface Props {
  id: string;
  heading?: string;
  onClick?: any;
  remove: boolean;
  children?: React.ReactNode;
}

const FormCard = ({ id, heading, onClick, remove, children }: Props): JSX.Element => {
  return (
    <Wrapper data-testid={`${id}-id`}>
      <HeaderWrapper>
        <Header data-testid={`${id}-header-id`}>{heading}</Header>
        {remove && (
          <Footer>
            <ActionButton fieldId={id} type="remove" label="Remove" onClick={onClick} />
          </Footer>
        )}
      </HeaderWrapper>
      <div>{children}</div>
      <Divider />
    </Wrapper>
  );
};

export default FormCard;
