// eslint-disable-next-line no-use-before-define
import React, { Component } from 'react';

import { Link } from 'react-router-dom';

import './AppointmentsListing.scss';

import Appointment from '../Appointment/Appointment';
import AppointmentsListingSectionHeader from '../AppointmentsListingSectionHeader/AppointmentsListingSectionHeader';
import { DeviceUtilities } from 'shared-components/utils';
import { AppointmentObject } from '../../models';

import DateTimeConverter from '../../utils/DateTimeConverter';
import { Info } from 'shared-components/images';
import classNames from 'classnames';

/**
 * Constants
 */
const NO_UPCOMING_APPOINTMENTS_BEYOND_THIS_DATE_MESSAGE = 'No upcoming appointments beyond this date';
const CONFIRMED_APPOINTMENT_STATUS = ['initial consultation', 'follow up', 'follow-up consultation'];

/**
 * Interfaces
 */
interface Props {
  appointmentsData: { [index: string]: AppointmentObject[][] };
  selectedAppointmentID: string | null;
  handleAppointmentSelected: (dictKey: string, arrayIndex: number, apptIndex: number) => void;
}

class AppointmentsListing extends Component<Props> {
  public componentDidMount(): void {
    this.scrollToSelectedAppointment();
  }
  public componentDidUpdate(): void {
    this.scrollToSelectedAppointment();
  }

  /**
   * Scrolls to the selected appointment in the list.
   */
  private scrollToSelectedAppointment(): void {
    const containerDiv = document.getElementById('appointments-listing-container');
    const childDiv = document.getElementById(`appointment-container-${this.props.selectedAppointmentID}`);
    if (containerDiv && childDiv) {
      const scrollMin = containerDiv.scrollTop;
      const scrollMax = scrollMin + containerDiv.clientHeight;
      const childTop = childDiv.offsetTop;
      const childBottom = childDiv.offsetTop + childDiv.clientHeight;
      // The size of the Month header element.
      // Enables scrolling to just under the month header element.
      const monthHeaderHeight = 60;
      // Ensuring the top of the selected appointment div is above the bottom of the scroll list
      // and vice versa for the bottom of the apppointment and top of the scroll list
      if (childBottom < scrollMin || childTop > scrollMax) {
        containerDiv.scrollTop = childTop - monthHeaderHeight;
      }
    }
  }

  /**
   * Handles when an appointment in the listing is clicked. If an appointment is already
   * selected, do nothing, else propogate the selection to the parent component to handle.
   * @param {React.MouseEvent<HTMLAnchorElement, MouseEvent>} e The click event.
   * @param {string} apptID The appointmentID of the appointment that was just clicked.
   * @param {string} dictKey A 'MMMM YYYY' string representing the dictionary key that this appointment is associated with.
   * @param {number} arrayIndex The index of the 'day' array in the dictionary.
   * @param {number} apptIndex The index of the newly selected appointment object in the 'day' array in the dictionary.
   */
  private handleAppointmentClicked = (
    e: React.MouseEvent<HTMLAnchorElement, MouseEvent>,
    apptID: string,
    dictKey: string,
    arrayIndex: number,
    apptIndex: number,
  ): void => {
    // Only handle the click if a different appointment to the current one is selected
    if (apptID !== this.props.selectedAppointmentID) {
      this.props.handleAppointmentSelected(dictKey, arrayIndex, apptIndex);
    } else {
      // Or, if the appointment is the same as the previous but the device is a mobile
      if (DeviceUtilities.isMobileDevice()) {
        e.preventDefault();
        this.props.handleAppointmentSelected(dictKey, arrayIndex, apptIndex);
      } else {
        // Prevent re-selection of the already selected appointment on desktop/tablets.
        e.preventDefault();
      }
    }
  };

  /**
   * Generates an Appointment element from the given data set.
   * @param {AppointmentObject} appt The AppointmentObject with data.
   * @param {boolean} isSelected True if the appointment is the currently selected appointment, or false otherwise.
   * @param {string} dictKey A string in the form of 'MMMM YYYY' that represents a key in the data dictionary.
   * @param {number} arrayIndex The index of the 'day' array in the dictionary.
   * @param {number} apptIndex The index of the appointment in the associated 'day' array.
   * @returns {JSX.Element} An Appointment element to render.
   */
  private generateAppointmentElement(
    appt: AppointmentObject,
    isSelected: boolean,
    dictKey: string,
    arrayIndex: number,
    apptIndex: number,
  ): JSX.Element {
    // Generate the appointment element

    const appointmentType = appt.appointmentType ? appt.appointmentType : '';
    const isConfirmedType = CONFIRMED_APPOINTMENT_STATUS.includes(appointmentType.toLowerCase());
    const cutOffDate = DateTimeConverter.getAppointmentCutOffDate(appt.entity);
    const appointmentDate = appt.startTime ? new Date(appt.startTime) : null;
    const isPending = appointmentDate ? appointmentDate >= cutOffDate && !isConfirmedType : true;

    return (
      <div
        id={'appointment-container-' + appt.id}
        className={classNames('appointment-container', {
          selected: isSelected,
          'appointment-pending': isPending,
        })}
        key={'apptKey_' + appt.id}>
        <Link
          to={'/px/appointments/' + appt.id}
          onClick={(e): void => {
            this.handleAppointmentClicked(e, appt.id, dictKey, arrayIndex, apptIndex);
          }}>
          <Appointment appointment={appt} />
        </Link>
      </div>
    );
  }

  /**
   * Generates an Appointment element for each appointment in the current 'day' array.
   * @param {string} dictKey A string in the form of 'MMMM YYYY' that represents a key in the data dictionary.
   * @param {number} arrayIndex The index of the 'day' array in the dictionary.
   * @returns {JSX.Element[]} An array of Appointment elements to render.
   */
  private generateCurrentDayAppointmentElements(dictKey: string, arrayIndex: number): JSX.Element[] {
    return this.props.appointmentsData[dictKey][arrayIndex].map(
      // For each element in the current day array
      (appt: AppointmentObject, apptIndex: number): JSX.Element => {
        // Set the default value for the `isSelected` flag.
        let isSelected = false;

        // If the appointment ID matches the provided props' selected appointment ID,
        // then set the flag to selected, so that the appointment can be visually highlighted
        // in the appointments listing.
        if (appt.id === this.props.selectedAppointmentID) {
          isSelected = true;
        }

        // Generate an appointment element from the given data set.
        return this.generateAppointmentElement(appt, isSelected, dictKey, arrayIndex, apptIndex);
      },
    );
  }

  /**
   * Generates a Month header and Appointment element for each object in the dictionary.
   * @returns {JSX.Element[]} A collection of Month and Appointment elements to render.
   */
  private generateMonthAndAppointmentElements(): JSX.Element[] {
    // Stores the entire collection of Month and Appointment elements to render.
    let allElements: JSX.Element[] = [];
    let isPendingRendered = false;

    // For each month, generate the appointment
    for (const dictKey in this.props.appointmentsData) {
      // Extract the MMMM from the 'MMMM YYYY' key
      const month = dictKey.split(' ')[0];

      // Add the month heading divider for the current month
      const monthHeader = <AppointmentsListingSectionHeader key={dictKey} nameOfMonth={month} />;

      let currentMonthElements: JSX.Element[] = [];
      // For each month array in the dictionary
      for (let arrayIndex = 0; arrayIndex < this.props.appointmentsData[dictKey].length; arrayIndex++) {
        const appointment = this.props.appointmentsData[dictKey][arrayIndex][0];
        const startTime = appointment.startTime;
        const appointmentDate = startTime ? new Date(startTime) : null;
        const cutOffDate = DateTimeConverter.getAppointmentCutOffDate(appointment.entity);
        if (appointmentDate && appointmentDate >= cutOffDate && !isPendingRendered) {
          currentMonthElements = currentMonthElements.concat(this.generatePendingHeader(appointment.entity));
          isPendingRendered = true;
        }
        // Generate the appointments for the current day
        const currentDayElements = this.generateCurrentDayAppointmentElements(dictKey, arrayIndex);
        // Append all the appointments for the current day to the current month
        currentMonthElements = currentMonthElements.concat(currentDayElements);
      }

      // Append all the appointments for the current month to the collection
      allElements = allElements.concat([
        <div key={'monthContainerKey_' + dictKey}>
          {monthHeader}
          {currentMonthElements}
        </div>,
      ]);
    }
    return allElements;
  }

  /**
   * Generates a 'No upcoming appointments' message element.
   * @returns {JSX.Element} An element representing the message to be displayed.
   */
  private generateNoUpcomingAppointmentsMessage(): JSX.Element {
    return <div className="appointments-listing-end-message">{NO_UPCOMING_APPOINTMENTS_BEYOND_THIS_DATE_MESSAGE}</div>;
  }

  /**
   * Generates all required elements for the appointments listing.
   * @returns {JSX.Element} A single element containing all the appointment elements to be displayed, or a 'No upcoming appointments' message if there are none.
   */
  private generateAppointmentsListing(): JSX.Element {
    return (
      <React.Fragment>
        {this.generateMonthAndAppointmentElements()}
        {this.generateNoUpcomingAppointmentsMessage()}
      </React.Fragment>
    );
  }

  private generatePendingHeader(entity: string | undefined): JSX.Element {
    let text = 'Appointment times for next week will appear as "TBC" (to be confirmed) until Thursday the week before';
    if (entity && ['SA', 'VIC', 'QLD'].includes(entity)) {
      // These are confirmed Wednesday night EVE-4587
      text = 'Appointment times for next week will appear as "TBC" (to be confirmed) until Friday the week before';
    }
    return (
      <div key="pendingSection" className="pending-section">
        <div className="pending-section-header">
          <Info className="icon-blue" />
          Time may vary
        </div>
        <div className="pending-section-body">{text}</div>
      </div>
    );
  }

  /**
   * Render method.
   * @returns {JSX.Element} A JSX.Element that will be displayed.
   */
  public render(): JSX.Element {
    return (
      <div id="appointments-listing-container" className="appointments-listing-container">
        {this.generateAppointmentsListing()}
      </div>
    );
  }
}

export default AppointmentsListing;
