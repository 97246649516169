// eslint-disable-next-line no-use-before-define
import React, { Component, Fragment } from 'react';

import './HAPSOReview.scss';
import { RegistrationPatientDetails, HANavigatorApollo } from 'op-components';
import { RouteComponentProps, withRouter } from 'react-router-dom';
import { MandatoryPatient } from 'op-interfaces';
import { HAReviewSummaryApollo, HAReviewSummaryUS } from '../HAPages';
import { StepperLink } from 'shared-components/interfaces';
import { isUs } from 'op-utils/helpers';
import { styled } from '@mui/system';
import { Button } from '@mui/material';

interface Props extends RouteComponentProps {
  patient: MandatoryPatient;
  isPso?: boolean;
}

const FooterContainer = styled('footer')`
  position: fixed;
  bottom: 0;
  padding: 12px 24px;
  height: 76px;
  width: 100%;
  border-top: 1px solid ${(props) => props.theme.palette.primary.main};
`;

class HAPSOReview extends Component<Props> {
  public constructor(props: Props) {
    super(props);
  }

  public render(): JSX.Element {
    const { patient, history, isPso } = this.props;
    const PAGE_LINKS: StepperLink[] = [
      {
        linkText: 'Patient summary',
        href: `/patient/${patient.id}/summary`,
      },
    ];

    return (
      <Fragment>
        <div id="ha-review-header">
          <div id="ha-review-header-title">{'Health Assessment'}</div>
        </div>
        <div className="ha-review-elements">
          <div className="left-container">
            <div className="card-wrapper">
              <div className="newspaper-container">
                <RegistrationPatientDetails patient={patient} />
              </div>
            </div>
            <Button
              data-testid="back-to-summary-button"
              type="submit"
              onClick={(e): void => {
                e.preventDefault();
                const pathname = (isPso ? '/navigator/' : '/') + `patient/${patient.id}/summary`;
                history.push(pathname);
              }}>
              {isPso ? 'Back to patient summary' : 'View all forms'}
            </Button>
          </div>
          <div className="right-container">
            <div>{this.renderHASummary()}</div>
          </div>
          {isUs() ? (
            <FooterContainer />
          ) : (
            //@ts-ignore
            <HANavigatorApollo links={PAGE_LINKS} />
          )}
        </div>
      </Fragment>
    );
  }

  private renderHASummary(): JSX.Element {
    return <Fragment>{isUs() ? <HAReviewSummaryUS /> : <HAReviewSummaryApollo />}</Fragment>;
  }
}

const routerComponent = withRouter(HAPSOReview);
export default routerComponent;
