import { EMRFeature, FeaturesList, UserFeature } from 'op-interfaces/User';
import { createContext, useContext } from 'react';

export interface UserContextState {
  hasStaffId: boolean;
  navShow: boolean;
  panelShow: boolean;
  primaryRole: string;
  timezone: string;
  features: FeaturesList;
  isPractitioner: boolean;
  isRegistrar: boolean;
}

export interface UserContextType {
  state: UserContextState;
  setHasStaffId: (hasStaffId: boolean) => void;
  setNavShow: (navShow: boolean) => void;
  setPanelShow: (panelShow: boolean) => void;
  setPrimaryRole: (primaryRole: string) => void;
  setTimezone: (timezone: string) => void;
  setFeatures: (item: FeaturesList) => void;
  setIsPractitioner: (isPractitioner: boolean) => void;
  setIsRegistrar: (isRegistrar: boolean) => void;
  isFeatureLoading: () => boolean;
  hasFeature: (featureName: string) => boolean | undefined;
  getEMRFeature: (featureName: string) => EMRFeature | undefined;
  getUserFeature: (featureName: string) => UserFeature | undefined;
}

export const initialContext: UserContextType = {
  state: {
    hasStaffId: false,
    navShow: true,
    panelShow: true,
    primaryRole: '',
    timezone: '',
    features: {} as FeaturesList,
    isPractitioner: false,
    isRegistrar: false,
  },
  setHasStaffId: (_hasStaffId: boolean): void => {},
  setNavShow: (_navShow: boolean): void => {},
  setPanelShow: (_panelShow: boolean): void => {},
  setPrimaryRole: (_primaryRole: string): void => {},
  setTimezone: (_timezone: string): void => {},
  setFeatures: (_item: any): void => {},
  setIsPractitioner: (_isPractitioner: boolean) => {},
  setIsRegistrar: (_isRegistrar: boolean) => {},
  isFeatureLoading: () => true,
  hasFeature: (_featureName: string) => undefined,
  getEMRFeature: (_featureName: string) => undefined,
  getUserFeature: (_featureName: string) => undefined,
};

const UserContext = createContext<UserContextType>(initialContext);

export const Features = {
  SIGMA: 'Sigma',
  AJCC: 'AJCC',
  PATIENT_SUMMARY: 'PatientSummary',
};

export default UserContext;
