import { useRouteMatch } from 'react-router';
import { ROPatientContextProvider } from '../PatientSummary/context';
import OuterContainer from '../PatientSummary/OuterContainer';
import OutcomeNote from './OutcomeNote';
import { useQuery } from '@apollo/client';
import { OutcomeInterface } from './Interfaces';
import { LoadingSpinner } from 'shared-components/components';
import { GET_OUTCOME } from './Queries';
import OutcomeForm from './OutcomeForm';

interface OutcomeDataInterface {
  outcome: OutcomeInterface;
}

interface ROPatientOutcomesNavType {
  id: string;
  outcomeId: string;
}

const OutcomeFormPage = (): JSX.Element => {
  const match = useRouteMatch<ROPatientOutcomesNavType>();
  const { id: patientId, outcomeId } = match.params;

  const { data: outcomeData, error: outcomeError } = useQuery<OutcomeDataInterface, any>(GET_OUTCOME, {
    variables: { outcomeId },
    fetchPolicy: 'network-only', // MM - As we do not return the values in the autosave mutation, the cache will not be updated so we will ignore it here and refetch it.
  });

  return (
    <ROPatientContextProvider>
      <OuterContainer rowSpace="0">
        {!outcomeData ? (
          <LoadingSpinner />
        ) : (
          <>
            <OutcomeForm outcome={outcomeData?.outcome} patientId={patientId} />
            <OutcomeNote outcome={outcomeData?.outcome} />
          </>
        )}
      </OuterContainer>
    </ROPatientContextProvider>
  );
};

export default OutcomeFormPage;
