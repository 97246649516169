// eslint-disable-next-line no-use-before-define
import React from 'react';
import { useMutation, useQuery } from '@apollo/client';
import { useParams } from 'react-router-dom';
import BasicForm from './BasicForm';
import { US_SSN_REGEX } from 'shared-components/utils/Regex';
import { REGISTRATION_BASIC_DETAILS_QUERY, UPDATE_REGISTRATION_BASIC_DETAILS } from './BasicQueries';
import { FormProps } from '../interfaces';

const Basic = (props: FormProps): JSX.Element => {
  const { exitForm } = props;
  const { patientId } = useParams<{ patientId: string }>();

  const [updateRegistrationBasic] = useMutation(UPDATE_REGISTRATION_BASIC_DETAILS);
  const { data, error } = useQuery(REGISTRATION_BASIC_DETAILS_QUERY, {
    variables: { id: patientId },
  });

  if (error || !data) return <></>;

  const { genderRefData, nameTitle, patient, user } = data;

  const updateField = (field: string, value: string | boolean | number) => {
    // dont allow saving required fields as blank
    if ((field === 'firstName' || field === 'lastName') && value === '') {
      return;
    }

    if (field === 'socialSecurityNumber') {
      if (!value.toString().match(US_SSN_REGEX)) {
        return;
      }
      // strip all non numeric characters before saving
      value = value.toString().replace(/\D/g, '');
    }

    // update date fields or update others
    updateRegistrationBasic({ variables: { id: patientId, [field]: value } });
  };

  return (
    <BasicForm
      refData={{
        genderRefData,
        titleReferenceData: nameTitle,
      }}
      patient={patient}
      updateField={updateField}
      handleShowSaveExitConfirm={() => exitForm(true)}
      isPso={user.isPso}
    />
  );
};

export default Basic;
