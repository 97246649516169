// eslint-disable-next-line no-use-before-define
import React, { Component, Fragment } from 'react';
import validate from 'validate.js';

import { SegmentedInputBoolean } from 'shared-components/utils';

import './CovidAssessment.scss';

import { CovidAssessmentItem } from 'op-classes';
import { appendZeroInFront } from 'op-utils';
import { ApolloAutoSave } from 'op-types';
import { StringDictionary } from 'shared-components/interfaces';
import { ASSESSMENT_AND_REVIEW } from '../constants';

import {
  ErrorInfo,
  SectionField,
  FreeTextField,
  HelperText,
  SegmentedInput,
} from 'shared-components/components/FormFields';

const FIELD_NAMES: StringDictionary = {
  HAS_BEEN_DIAGNOSED: 'hasBeenDiagnosed',
  HAS_BEEN_TESTED: 'hasBeenTested',
  ADVISED_TO_QUARANTINE: 'advisedToQuarantine',
  HAD_CONTACT: 'hadContact',
  TRAVELLED_TO_HOTSPOT: 'travelledToHotspot',
  HAS_SYMPTOMS: 'hasSymptoms',

  SYMPTOM_DATE: 'dateOfFeverSymptoms',
  SYMPTOM_DATE_DAY: 'symptom_date_day',
  SYMPTOM_DATE_MONTH: 'symptom_date_month',
  SYMPTOM_DATE_YEAR: 'symptom_date_year',
};

interface State {
  viewedFields: Set<string>;
  hasBeenDiagnosed: boolean;
  hasBeenTested: boolean;
  advisedToQuarantine: boolean;
  hadContact: boolean;
  travelledToHotspot: boolean;
  hasSymptoms: boolean;

  symptomDay: string;
  symptomMonth: string;
  symptomYear: string;

  symptomDateError: boolean;
}

interface Props {
  autosave: ApolloAutoSave;
  patientId: string;
  covidScreening: CovidAssessmentItem;
  validateOnLoad: boolean;
}

class CovidAssessment extends Component<Props, State> {
  public constructor(props: Props) {
    super(props);

    let symptomDay = '';
    let symptomMonth = '';
    let symptomYear = '';
    if (this.props.covidScreening.dateOfFeverSymptoms) {
      const dateOfFeverSymptoms = new Date(this.props.covidScreening.dateOfFeverSymptoms);
      symptomDay = dateOfFeverSymptoms.getDate().toString();
      symptomMonth = (dateOfFeverSymptoms.getMonth() + 1).toString();
      symptomYear = dateOfFeverSymptoms.getFullYear().toString();
    }

    this.state = {
      viewedFields: new Set(),
      hasBeenDiagnosed: false,
      hasBeenTested: false,
      advisedToQuarantine: false,
      hadContact: false,
      travelledToHotspot: false,
      hasSymptoms: false,

      symptomDay: symptomDay,
      symptomMonth: symptomMonth,
      symptomYear: symptomYear,

      symptomDateError: false,
    };
  }

  public componentDidMount(): void {
    setTimeout(this.addValidationToSubmit, 500);
  }

  /**
   * When the user clicks the submit button add the viewed fields to the state to trigger validation
   */
  private addValidationToSubmit = (): void => {
    const submitButton = document.getElementById('covid-nav-submit');
    if (submitButton) {
      submitButton.addEventListener('click', (): void => {
        const viewedFields = [
          FIELD_NAMES.HAS_BEEN_DIAGNOSED,
          FIELD_NAMES.HAS_BEEN_TESTED,
          FIELD_NAMES.ADVISED_TO_QUARANTINE,
          FIELD_NAMES.HAD_CONTACT,
          FIELD_NAMES.TRAVELLED_TO_HOTSPOT,
          FIELD_NAMES.HAS_SYMPTOMS,
          FIELD_NAMES.SYMPTOM_DATE,
        ];
        this.setState({ viewedFields: new Set(viewedFields) });
      });
    } else {
      setTimeout(this.addValidationToSubmit, 1000);
    }
  };

  public renderSymptomsDateField(validationObject: any): JSX.Element {
    const { covidScreening } = this.props;
    if (covidScreening.hasSymptoms) {
      const day = this.state.symptomDay;
      const month = this.state.symptomMonth;
      const year = this.state.symptomYear;

      return (
        <HelperText helperText={'DD MM YYYY e.g. 10 03 2020'} idPrefix="covid_symptom">
          <SectionField
            required={true}
            isValid={
              validationObject && validationObject.dateOfFeverSymptoms
                ? validationObject.dateOfFeverSymptoms
                : undefined
            }
            title={'Date of symptom/s onset'}
            htmlFor={FIELD_NAMES.SYMPTOM_DATE_DAY}>
            <div className="date-dd-mm-yyy">
              <FreeTextField
                inputKey={day}
                inputName={FIELD_NAMES.SYMPTOM_DATE_DAY}
                maxLength={2}
                inputType="text"
                defaultValue={day}
                onBlur={(e): void => {
                  this.setState({ symptomDay: e.target.value });
                  this.addToViewedFields(FIELD_NAMES.SYMPTOM_DATE_DAY);
                  if (month && year) {
                    this.autoSaveAndValidate(
                      {
                        id: parseInt(this.props.patientId),
                        symptomDay: e.target.value,
                        symptomMonth: this.state.symptomMonth,
                        symptomYear: this.state.symptomYear,
                      },
                      FIELD_NAMES.SYMPTOM_DATE_DAY,
                    );
                  }
                }}
                displayInputError={
                  validationObject && validationObject.dateOfFeverSymptoms
                    ? validationObject.dateOfFeverSymptoms
                    : undefined
                }
                placeholder={'DD'}
              />
              <FreeTextField
                inputKey={month}
                inputName={FIELD_NAMES.SYMPTOM_DATE_MONTH}
                maxLength={2}
                inputType="text"
                defaultValue={month}
                onBlur={(e): void => {
                  this.setState({ symptomMonth: e.target.value });
                  this.addToViewedFields(FIELD_NAMES.SYMPTOM_DATE_MONTH);
                  if (day && year) {
                    this.autoSaveAndValidate(
                      {
                        id: parseInt(this.props.patientId),
                        symptomDay: this.state.symptomDay,
                        symptomMonth: e.target.value,
                        symptomYear: this.state.symptomYear,
                      },
                      FIELD_NAMES.SYMPTOM_DATE_MONTH,
                    );
                  }
                }}
                displayInputError={
                  validationObject && validationObject.dateOfFeverSymptoms
                    ? validationObject.dateOfFeverSymptoms
                    : undefined
                }
                placeholder={'MM'}
              />
              <FreeTextField
                inputKey={year}
                inputName={FIELD_NAMES.SYMPTOM_DATE_YEAR}
                maxLength={4}
                inputType="text"
                defaultValue={year}
                onBlur={(e): void => {
                  this.setState({ symptomYear: e.target.value });
                  this.addToViewedFields(FIELD_NAMES.SYMPTOM_DATE);
                  if (day && month) {
                    this.autoSaveAndValidate(
                      {
                        id: parseInt(this.props.patientId),
                        symptomDay: this.state.symptomDay,
                        symptomMonth: this.state.symptomMonth,
                        symptomYear: e.target.value,
                      },
                      FIELD_NAMES.SYMPTOM_DATE,
                    );
                  }
                }}
                displayInputError={
                  validationObject && validationObject.dateOfFeverSymptoms
                    ? validationObject.dateOfFeverSymptoms
                    : undefined
                }
                placeholder={'YYYY'}
              />
            </div>
            <ErrorInfo
              errors={
                validationObject && validationObject.dateOfFeverSymptoms
                  ? validationObject.dateOfFeverSymptoms
                  : undefined
              }
            />
          </SectionField>
        </HelperText>
      );
    } else {
      return <Fragment></Fragment>;
    }
  }

  public render(): JSX.Element {
    return (
      <Fragment>
        <div className="covid-assessment-content">
          <div className="covid-assessment-right-content">{this.renderCovidQuestion()}</div>
        </div>
      </Fragment>
    );
  }

  private renderCovidQuestion(): JSX.Element {
    const { covidScreening } = this.props;

    const validationObject = this.validateObject(covidScreening);

    return (
      <div className="covid-fields">
        <SectionField
          title={ASSESSMENT_AND_REVIEW.HAVE_YOU_BEEN_DIAGNOSED}
          htmlFor={FIELD_NAMES.HAS_BEEN_DIAGNOSED}
          required={true}>
          <SegmentedInput
            fieldName={FIELD_NAMES.HAS_BEEN_DIAGNOSED}
            options={SegmentedInputBoolean}
            optionAreBoolean={true}
            defaultSelected={covidScreening.hasBeenDiagnosed}
            itemSelected={(selectedItem): void => {
              this.autoSave({
                id: parseInt(this.props.patientId),
                hasBeenDiagnosed: selectedItem,
              });
            }}
            errors={
              validationObject && validationObject.hasBeenDiagnosed ? validationObject.hasBeenDiagnosed : undefined
            }
          />
        </SectionField>
        <SectionField
          required={true}
          title={ASSESSMENT_AND_REVIEW.TESTED_AND_WAITING}
          htmlFor={FIELD_NAMES.HAS_BEEN_TESTED}>
          <SegmentedInput
            fieldName={FIELD_NAMES.HAS_BEEN_TESTED}
            options={SegmentedInputBoolean}
            optionAreBoolean={true}
            defaultSelected={covidScreening.hasBeenTested}
            itemSelected={(selectedItem): void => {
              this.setState({ hasBeenTested: Boolean(selectedItem) });
              this.autoSave({
                id: parseInt(this.props.patientId),
                hasBeenTested: selectedItem,
              });
            }}
            errors={validationObject && validationObject.hasBeenTested ? validationObject.hasBeenTested : undefined}
          />
        </SectionField>
        <SectionField
          required={true}
          isValid={true}
          title={ASSESSMENT_AND_REVIEW.ADVISED_TO_QUARANTINE}
          htmlFor={FIELD_NAMES.ADVISED_TO_QUARANTINE}>
          <SegmentedInput
            fieldName={FIELD_NAMES.ADVISED_TO_QUARANTINE}
            options={SegmentedInputBoolean}
            optionAreBoolean={true}
            defaultSelected={covidScreening.advisedToQuarantine}
            itemSelected={(selectedItem): void => {
              this.autoSave({
                id: parseInt(this.props.patientId),
                advisedToQuarantine: selectedItem,
              });
            }}
            errors={
              validationObject && validationObject.advisedToQuarantine
                ? validationObject.advisedToQuarantine
                : undefined
            }
          />
        </SectionField>
        <SectionField
          required={true}
          isValid={true}
          title={ASSESSMENT_AND_REVIEW.CLOSE_CONTACT_WITH_QUARANTINE}
          htmlFor={FIELD_NAMES.HAD_CONTACT}>
          <SegmentedInput
            fieldName={FIELD_NAMES.HAD_CONTACT}
            options={SegmentedInputBoolean}
            optionAreBoolean={true}
            defaultSelected={covidScreening.hadContact}
            itemSelected={(selectedItem): void => {
              this.autoSave({
                id: parseInt(this.props.patientId),
                hadContact: selectedItem,
              });
            }}
            errors={validationObject && validationObject.hadContact ? validationObject.hadContact : undefined}
          />
        </SectionField>
        <SectionField
          required={true}
          isValid={true}
          title={ASSESSMENT_AND_REVIEW.TRAVELLED_TO_HOTSPOT}
          htmlFor={FIELD_NAMES.TRAVELLED_TO_HOTSPOT}>
          <SegmentedInput
            fieldName={FIELD_NAMES.TRAVELLED_TO_HOTSPOT}
            options={SegmentedInputBoolean}
            optionAreBoolean={true}
            defaultSelected={covidScreening.travelledToHotspot}
            itemSelected={(selectedItem): void => {
              this.autoSave({
                id: parseInt(this.props.patientId),
                travelledToHotspot: selectedItem,
              });
            }}
            errors={
              validationObject && validationObject.travelledToHotspot ? validationObject.travelledToHotspot : undefined
            }
          />
        </SectionField>
        <SectionField
          required={true}
          title={ASSESSMENT_AND_REVIEW.EXPERIENCING_THE_FOLLOWING}
          additionalClassNames={'prewrapText'}
          htmlFor={FIELD_NAMES.HAS_SYMPTOMS}>
          <SegmentedInput
            fieldName={FIELD_NAMES.HAS_SYMPTOMS}
            options={SegmentedInputBoolean}
            optionAreBoolean={true}
            defaultSelected={covidScreening.hasSymptoms}
            itemSelected={(selectedItem): void => {
              this.setState({ hasSymptoms: Boolean(selectedItem) });
              this.autoSave({
                id: parseInt(this.props.patientId),
                hasSymptoms: selectedItem,
              });
            }}
            errors={validationObject && validationObject.hasSymptoms ? validationObject.hasSymptoms : undefined}
          />
        </SectionField>
        {this.renderSymptomsDateField(validationObject)}
      </div>
    );
  }

  private validateObject = (covid: CovidAssessmentItem): any => {
    // Global rules for the validator, must match the patient interface keys
    const globalValidationRules: { [key: string]: object } = {
      dateOfFeverSymptoms: {
        futureDateDDMMYYYY: {
          inputDateformat: 'DDMMYYYY',
          message: ASSESSMENT_AND_REVIEW.VALID_DATE,
        },
      },
      hasBeenDiagnosed: {
        presence: {
          allowEmpty: false,
          message: ASSESSMENT_AND_REVIEW.SELECT_OPTION,
        },
      },
      hasBeenTested: {
        presence: {
          allowEmpty: false,
          message: ASSESSMENT_AND_REVIEW.SELECT_OPTION,
        },
      },
      advisedToQuarantine: {
        presence: {
          allowEmpty: false,
          message: ASSESSMENT_AND_REVIEW.SELECT_OPTION,
        },
      },
      hadContact: {
        presence: {
          allowEmpty: false,
          message: ASSESSMENT_AND_REVIEW.SELECT_OPTION,
        },
      },
      travelledToHotspot: {
        presence: {
          allowEmpty: false,
          message: ASSESSMENT_AND_REVIEW.SELECT_OPTION,
        },
      },
      hasSymptoms: {
        presence: {
          allowEmpty: false,
          message: ASSESSMENT_AND_REVIEW.SELECT_OPTION,
        },
      },
    };

    const modifiedCovid = { ...covid };
    modifiedCovid.dateOfFeverSymptoms = `${appendZeroInFront(this.state.symptomDay)}${appendZeroInFront(
      this.state.symptomMonth,
    )}${this.state.symptomYear}`;
    // Ensure fields that been viewed only have validation run on them
    const specificValidationRules: { [key: string]: object } = {};
    for (const viewed of this.state.viewedFields.keys()) {
      specificValidationRules[viewed] = globalValidationRules[viewed];
    }
    // Disable pre-appending of argument name to error messages
    const disableFullMessages = { fullMessages: false };
    // Run validation on all the fields
    return validate(modifiedCovid, specificValidationRules, disableFullMessages);
  };
  private autoSaveAndValidate = (updateObject: object, validationKey: string): void => {
    const { autosave } = this.props;

    // Save the data
    autosave(updateObject);

    // Get viewed fields
    const viewedFields = [...this.state.viewedFields];

    // Add updated field
    viewedFields.push(validationKey);

    // Update with new validation set
    this.setState({ viewedFields: new Set(viewedFields) });
  };

  private autoSave = (updateObject: object): void => {
    const { autosave } = this.props;

    autosave(updateObject);
  };

  private addToViewedFields = (validationKey: string): void => {
    // Get viewed fields
    const viewedFields = [...this.state.viewedFields];

    // Add updated field
    viewedFields.push(validationKey);

    // Update with new validation set
    this.setState({ viewedFields: new Set(viewedFields) });
  };
}

export default CovidAssessment;
