import React from 'react';
import { SummaryIcon } from 'shared-components/images/ro_portal';
import { styled } from '@mui/system';
import SidePanelLink from './components/SidePanelLink';
const Panel = styled('div')`
  width: 240px;
  padding-top: 70px;
`;

const SidePanel = (): JSX.Element => {
  return (
    <Panel>
      <SidePanelLink active={true} name="Patient summary" logo={<SummaryIcon />} />
    </Panel>
  );
};

export default SidePanel;
