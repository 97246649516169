import { FormikValues, validateYupSchema, yupToFormErrors } from 'formik';
import * as Yup from 'yup';

export const documentValidationSchema = (values: FormikValues): any => {
  const documentSchema = {
    attachmentAcknowledged: Yup.bool().oneOf([true], 'Field must be checked.'),
    attachments: Yup.array().of(
      Yup.object().shape({
        documentType: Yup.string().required(),
      }),
    ),
  };

  try {
    validateYupSchema<FormikValues>(values, Yup.object(documentSchema), true);
  } catch (err) {
    return yupToFormErrors(err);
  }

  return {};
};
