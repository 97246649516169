// eslint-disable-next-line no-use-before-define
import React, { ReactElement } from 'react';
import { styled } from '@mui/system';

import { InfoCircleBlueIcon } from 'shared-components/images';
import { Tooltip } from '@mui/material';

export const primaryDiagnosisDateTooltip = (
  <div>
    The first diagnosis of cancer by a recognised medical practitioner. This may be a clinical diagnosis and may not
    ever be confirmed histologically. When the biopsy is histologically confirmed, the date of diagnosis is the date of
    the first clinical diagnosis and not the date of confirmation (per NCI SEER). Where specific date is not known,
    enter month/01/year.
  </div>
);

export const clinicalPathologicalStagingTooltip = (
  <div>
    Always indicate the staging basis (chronology or time period) of the staging. Clinical staging is based on
    information gained up to the initial definitive treatment. Pathologic staging includes clinical information and
    information obtained from pathologic examination of resected primary and regional lymph nodes (per AJCC General
    Guidelines for TNM Staging).
  </div>
);

export const priorityTooltip = (
  <div>
    <b>Emergency</b>: aim 24hrs, accept 48 hours
    <br />
    <b>Urgent</b>: within 3 working days, 5 days acceptable
    <br />
    <b>Standard</b>:
    <br />
    <li>Palliative: within 5 working days, 7 days acceptable</li>
    <li>Curative: within 5 working days, 10 days acceptable</li>
    <li>SABR: anything within working 10 days (i.e. 2 weeks)</li>
    <br />
    <b>Note</b>: assume working days calculated from simulation date.
  </div>
);

export const TooltipIcon = styled(InfoCircleBlueIcon)`
  height: 16px;
  width: 16px;
  margin-left: 2px;
`;

export const tooltipIconWrapper = (title: ReactElement) => {
  return (
    <Tooltip arrow title={title}>
      <span>
        <TooltipIcon />
      </span>
    </Tooltip>
  );
};
interface RcrCodes {
  name: string;
  diagnosis: Array<Array<number>>;
  morphology: Array<Array<number>>;
}

const validRcrCodeRanges: RcrCodes[] = [
  {
    name: 'H&N',
    diagnosis: [
      [0, 14.9],
      [30, 32.9],
    ],
    morphology: [[8050, 8084]],
  },
  {
    name: 'Anus',
    diagnosis: [[21, 21.8]],
    morphology: [[8050, 8084]],
  },
  {
    name: 'Oesophagus',
    diagnosis: [[15, 16]],
    morphology: [
      [8050, 8084],
      [8140, 8430],
    ],
  },
  {
    name: 'Gynaecological',
    diagnosis: [[51, 58]],
    morphology: [[8050, 8084]],
  },
  {
    name: 'Cutaneous',
    diagnosis: [[44, 44.9]],
    morphology: [[8050, 8084]],
  },
  {
    name: 'Medullablastoma',
    diagnosis: [[72.8, 72.8]],
    morphology: [[9470, 9470]],
  },
  {
    name: 'Breast Carcinoma',
    diagnosis: [[50, 50.9]],
    morphology: [[8530, 8530]],
  },
  {
    name: 'Lung',
    diagnosis: [[34, 34.9]],
    morphology: [
      [8050, 8084],
      [8041, 8041],
    ],
  },
];

export const isRcrCodeInRange = (diagnosisCode: number, morphologyCode: number): any => {
  return validRcrCodeRanges.some(
    (value: RcrCodes) =>
      value.diagnosis.some((range) => diagnosisCode >= range[0] && diagnosisCode <= range[1]) &&
      value.morphology.some((range) => morphologyCode >= range[0] && morphologyCode <= range[1]),
  );
};
