import React from 'react';
import { styled } from '@mui/system';
import { Field, FieldArray, FormikProps, useFormikContext } from 'formik';

import { FormRow, ActionButton } from 'shared-components/components';
import { ListOptionType } from 'shared-components/interfaces';
import { SelectFieldBase } from 'shared-components/components/FormikComponents';

import TextAreaField from './TextAreaField';

/**
 * Props
 * @property {string} addButtonText Text that will be displayed next to the "add" button
 * @property {async (selectValue:string, textFieldValue:string) => TData} createMutation Creates an object
 * @property {async (options?:MutationOptions) => Promise<FetchResult>} deleteMutation Deletes an object
 * @property {async (options?:MutationOptions) => Promise<FetchResult>} updateMutation Updates an object
 * @property {string} label Field label that will be used as a template for field names in the field array
 * @property {string} name Name of the Formik FieldArray, should be used for validation
 * @property {ListOptionType} options Options that will be used by the select fields
 * @property {string} selectFieldName Name of the DB field that stores the select field value e.g. 'baseValue'
 * @property {string} textFieldName Name of the DB field that stores the text field value e.g. 'otherValue'
 * @property {string} textFieldOptionsForOther Array of field values which need manual specification e.g. "Other"
 * @property {string} selectPlaceholder Placeholder label when a new select field is created
 * @property {any[]} values List of objects containing current values
 * @property {string} userIconFieldName Name of the DB field that stores the user type used to render the user icon
 * @property {string} userTypeValue User type value that should be set in Formik when an item is updated
 */
interface Props {
  addButtonText: string;
  createMutation: any;
  deleteMutation: any;
  updateMutation: any;
  label: string;
  name: string;
  options: ListOptionType[];
  selectFieldName: string;
  selectPlaceholder: string;
  textFieldName: string;
  textFieldOptionsForOther: string[];
  values: any[];
  userIconFieldName?: string;
  userTypeValue?: string;
  width?: string;
}

const StyledFormRow = styled(FormRow)`
  margin-top: 0px;
`;

const SelectFieldContainer = styled('div')`
  display: flex;
  align-items: center;
  margin-bottom: 16px;
  width: 560px;
`;

interface TextAreaContainerProps {
  isLastItem: boolean;
  index: number;
}

const TextAreaContainer = styled('div')<TextAreaContainerProps>`
  width: 526px;
  align-items: ${(props: TextAreaContainerProps) => !props.isLastItem && 'center'};
  margin-bottom: ${(props: TextAreaContainerProps) => (props.isLastItem ? '12px' : '16px')};
  padding-right: ${(props: TextAreaContainerProps) => (props.index === 0 ? '0px' : '32px')}; ;
`;

const SelectFieldGroup = ({
  name,
  addButtonText,
  createMutation,
  deleteMutation,
  updateMutation,
  label,
  options,
  selectPlaceholder,
  selectFieldName,
  textFieldName,
  textFieldOptionsForOther,
  values,
  userIconFieldName,
  userTypeValue,
  width,
}: Props): JSX.Element => {
  const { setFieldValue }: FormikProps<any> = useFormikContext();

  return (
    <FieldArray
      name={name}
      render={({ push, remove }) => {
        const handleAddButton = async () => {
          const data = await createMutation();
          push(data);
        };

        return (
          <>
            {values.length > 0
              ? values.map((item: any, index: any) => {
                  const fieldLabel = `${label} ${index + 1}`;
                  const isLastItem = index === values.length - 1;
                  const { id, [selectFieldName]: selectValue } = item;
                  const selectName = `${name}[${index}].${selectFieldName}`;
                  const textAreaName = `${name}[${index}].${textFieldName}`;

                  // Fields are only used when a user icon needs to be rendered
                  let userType;
                  let userTypeName = '';
                  if (userIconFieldName) {
                    userType = item[userIconFieldName];
                    userTypeName = `${name}[${index}].${userIconFieldName}`;
                  }

                  return (
                    <StyledFormRow key={index} fieldLabel={fieldLabel} fieldName={fieldLabel}>
                      <SelectFieldContainer>
                        <Field
                          name={selectName}
                          component={SelectFieldBase}
                          width={width}
                          updateMutation={(value: string) => {
                            updateMutation({ variables: { id, [selectFieldName]: value } });
                            if (userIconFieldName) {
                              setFieldValue(userTypeName, userTypeValue);
                            }
                          }}
                          placeholder={selectPlaceholder}
                          options={options}
                          removable={values.length > 1}
                          handleRemoveButton={() => {
                            remove(index);
                            deleteMutation(id);
                          }}
                          lastUpdateUser={userType}
                        />
                      </SelectFieldContainer>
                      {textFieldOptionsForOther.includes(selectValue) && (
                        <>
                          <TextAreaContainer isLastItem={isLastItem} index={index}>
                            <Field
                              component={TextAreaField}
                              name={textAreaName}
                              width={width}
                              placeholder="Please specify"
                              updateMutation={(value: string) => {
                                updateMutation({ variables: { id, [textFieldName]: value } });
                              }}
                            />
                          </TextAreaContainer>
                        </>
                      )}

                      {isLastItem && (
                        <ActionButton fieldId={name} type="add" onClick={handleAddButton} label={addButtonText} />
                      )}
                    </StyledFormRow>
                  );
                })
              : null}
          </>
        );
      }}
    />
  );
};

export default SelectFieldGroup;
