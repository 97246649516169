import { useState, useEffect, useRef, useLayoutEffect, useCallback } from 'react';
import { useHistory } from 'react-router-dom';
import Highcharts from 'highcharts';
import Dashboards from '@highcharts/dashboards';
import DataGrid from '@highcharts/dashboards/datagrid';
import LayoutModule from '@highcharts/dashboards/modules/layout';

import { StyledTile } from '../Dashboard';
import './InsightsDashboard.scss';
import Dashboard from './Components/Dashboard';
import { DashboardContextProvider } from './DashboardContext';
import MockData from './Utils/MockData';
import ExpandMore from '@mui/icons-material/ExpandMore';
import ArrowForward from '@mui/icons-material/ArrowForward';
import DashboardOverview from './Components/DashboardOverview';
import exporting from 'highcharts/modules/exporting';
import exportData from 'highcharts/modules/export-data';
import {
  calcAdherencePercentage,
  calcAverageDailyPlans,
  calcAverageFractions,
  calcAverageSimToTreat,
  calcComplexTechnique,
  calcTotalDiagnosis,
  calcTotalPlans,
  calcTotalReferrals,
  calcAverageDailyTreatmentsPerLinac,
} from './Utils/dataFunctions';
import * as Config from './Components/DashboardConfigs';
import { Typography } from '@mui/material';
import { useTheme } from '@mui/material/styles';

LayoutModule(Dashboards);
exporting(Highcharts);
exportData(Highcharts);
Dashboards.HighchartsPlugin.custom.connectHighcharts(Highcharts);
Dashboards.DataGridPlugin.custom.connectDataGrid(DataGrid);
Dashboards.PluginHandler.addPlugin(Dashboards.HighchartsPlugin);
Dashboards.PluginHandler.addPlugin(Dashboards.DataGridPlugin);

Highcharts.SVGRenderer.prototype.symbols.download = function (x: any, y: any, w: any, h: any) {
  return [
    // Rectangle (Bottom Bar)
    'M',
    x + w * 0.22,
    y + h * 0.83,
    'L',
    x + w * 0.78,
    y + h * 0.83,
    'L',
    x + w * 0.78,
    y + h * 0.75,
    'L',
    x + w * 0.22,
    y + h * 0.75,
    'Z',

    // Arrow Shape
    'M',
    x + w * 0.78,
    y + h * 0.375,
    'L',
    x + w * 0.62,
    y + h * 0.375,
    'L',
    x + w * 0.62,
    y + h * 0.125,
    'L',
    x + w * 0.38,
    y + h * 0.125,
    'L',
    x + w * 0.38,
    y + h * 0.375,
    'L',
    x + w * 0.22,
    y + h * 0.375,
    'L',
    x + w * 0.5,
    y + h * 0.67,
    'Z',
  ];
};

type SectionNames = 'Quality' | 'Access' | 'Efficiency';

const qualityNavItemsMapping: { [key: string]: string } = {
  carepathway: 'Carepathway Utilization',
  techniques: 'Technique Utilization',
  fractions: 'Fractionation Schedule',
};

const accessNavItemsMapping: { [key: string]: string } = {
  referrers: 'Top 10 Referrers',
  diagnosis: 'Diagnosis',
  activity: 'Clinical Activity',
};

const efficiencyNavItemsMapping: { [key: string]: string } = {
  machines: 'Machine Utilization',
  'wait-times': 'Patient Wait Times',
  productivity: 'Planning Productivity',
};

const NavBar = (openSections: any, toggleSection: any, navToPage: any, currentPage: any, theme: any) => (
  <div className="insights-nav-pane">
    <button className="back-button" onClick={() => navToPage('index')}>
      <div style={{ display: 'flex', alignItems: 'center', marginBottom: '5px' }}>
        <ArrowForward
          style={{
            marginRight: '15px',
            transform: 'rotate(180deg)',
            opacity: '54%',
            color: theme.palette.primary.dark,
          }}
        />
        <div>
          <Typography>Back</Typography>
          <Typography variant="subtitle2" color={theme.palette.text.secondary} sx={{ fontWeight: 400 }}>
            Insights Dashboard
          </Typography>
        </div>
      </div>
    </button>
    <div className="nav-section">
      <Typography className="section-header" variant="subtitle1" onClick={() => toggleSection('Quality')}>
        <ExpandMore style={{ transform: `rotate(${openSections.Quality ? 0 : '-90deg'})` }} />
        Quality
      </Typography>
      {openSections.Quality && (
        <NavList navToPage={navToPage} currentPage={currentPage} navItems={qualityNavItemsMapping} />
      )}
      <Typography className="section-header" variant="subtitle1" onClick={() => toggleSection('Access')}>
        <ExpandMore style={{ transform: `rotate(${openSections.Access ? 0 : '-90deg'})` }} />
        Access
      </Typography>
      {openSections.Access && (
        <NavList navToPage={navToPage} currentPage={currentPage} navItems={accessNavItemsMapping} />
      )}
      <Typography className="section-header" variant="subtitle1" onClick={() => toggleSection('Efficiency')}>
        <ExpandMore style={{ transform: `rotate(${openSections.Efficiency ? 0 : '-90deg'})` }} />
        Efficiency
      </Typography>
      {openSections.Efficiency && (
        <NavList navToPage={navToPage} currentPage={currentPage} navItems={efficiencyNavItemsMapping} />
      )}
    </div>
  </div>
);

const NavList = ({
  navToPage,
  currentPage,
  navItems,
}: {
  navToPage: (page: string) => void;
  currentPage: string;
  navItems: { [key: string]: string };
}) => {
  return (
    <ul className="section-list">
      {Object.entries(navItems).map(([label, value]) => (
        <li
          key={label}
          className={`section-item ${currentPage === label ? 'active' : ''}`}
          onClick={() => navToPage(label)}>
          {value}
        </li>
      ))}
    </ul>
  );
};

const InsightsDashboard = () => {
  const history = useHistory();
  const theme = useTheme();

  const [currentPage, setCurrentPage] = useState('index');
  const [openSections, setOpenSections] = useState<Record<SectionNames, boolean>>({
    Quality: true,
    Access: true,
    Efficiency: true,
  });

  const navToPage = (page: string) => {
    if (currentPage === page) {
      return;
    }

    if (page === 'index') {
      history.push('/radiation/insights');
    } else {
      history.push(`/radiation/insights/${page}`);
    }
    setCurrentPage(page);
  };

  useEffect(() => {
    const baseUrl = '/radiation/insights';

    const pageMapping: { [key: string]: string } = {
      baseUrl: 'index',
      [`${baseUrl}/carepathway`]: 'carepathway',
      [`${baseUrl}/techniques`]: 'techniques',
      [`${baseUrl}/fractions`]: 'fractions',
      [`${baseUrl}/referrers`]: 'referrers',
      [`${baseUrl}/diagnosis`]: 'diagnosis',
      [`${baseUrl}/activity`]: 'activity',
      [`${baseUrl}/machines`]: 'machines',
      [`${baseUrl}/wait-times`]: 'wait-times',
      [`${baseUrl}/productivity`]: 'productivity',
    };

    setCurrentPage(pageMapping[location.pathname] || 'index');
    restoreScrollPosition(0);
    scrollPositionRef.current = 0;

    const unlisten = history.listen((location) => {
      setCurrentPage(pageMapping[location.pathname] || 'index');
    });

    return () => {
      unlisten();
    };
  }, [history, location.pathname]);

  const toggleSection = (section: SectionNames) => {
    setOpenSections((prevOpenSections) => ({
      ...prevOpenSections,
      [section]: !prevOpenSections[section],
    }));
  };

  const tableWrapperRef = useRef<HTMLDivElement | null>(null);
  const scrollPositionRef = useRef<number>(0);

  const saveScrollPosition = useCallback(() => {
    if (tableWrapperRef.current && tableWrapperRef.current.scrollTop !== 0) {
      scrollPositionRef.current = tableWrapperRef.current.scrollTop;
    }
  }, []);

  const restoreScrollPosition = (override?: number) => {
    if (tableWrapperRef.current) {
      requestAnimationFrame(() => {
        if (override || override === 0) {
          tableWrapperRef.current!.scrollTop = override;
        } else {
          tableWrapperRef.current!.scrollTop = scrollPositionRef.current;
        }
      });
    }
  };

  useEffect(() => {
    const tableWrapper = tableWrapperRef.current;
    if (tableWrapper) {
      tableWrapper.addEventListener('scroll', saveScrollPosition);
    }

    return () => {
      if (tableWrapper) {
        tableWrapper.removeEventListener('scroll', saveScrollPosition);
      }
    };
  }, [saveScrollPosition]);

  return (
    <DashboardContextProvider data={MockData} navToPage={navToPage} onChartClick={restoreScrollPosition}>
      <div className="insights-wrapper insights-ro-dashboard-wrapper">
        {currentPage !== 'index' && NavBar(openSections, toggleSection, navToPage, currentPage, theme)}
        <StyledTile className="careplan-dashboard-wrapper insights-dashboard-wrapper">
          <div className="table-wrapper" ref={tableWrapperRef}>
            {currentPage === 'index' && <DashboardOverview />}
            {currentPage === 'carepathway' && (
              <Dashboard
                title="Carepathway Utilization"
                metric="Careplan Adherence"
                metricFunction={calcAdherencePercentage}
                config={Config.CarepathUtilisation}
                metricSuffix="%"
              />
            )}
            {currentPage === 'techniques' && (
              <Dashboard
                title="Technique Utilization"
                metric="IMRT/VMAT/Stereotactic plans"
                metricFunction={calcComplexTechnique}
                config={Config.TechniqueUtilisation}
                metricSuffix="%"
              />
            )}
            {currentPage === 'fractions' && (
              <Dashboard
                title="Fractionation Schedule"
                metric="Average Fractions"
                metricFunction={calcAverageFractions}
                metricRound={1}
                config={Config.FractionationSchedule}
              />
            )}
            {currentPage === 'referrers' && (
              <Dashboard
                title="Top 10 Referrers"
                metric="Total Referrals"
                metricFunction={calcTotalReferrals}
                config={Config.Top10Referrers}
              />
            )}
            {currentPage === 'diagnosis' && (
              <Dashboard
                title="Diagnosis"
                metric="Total Diagnosis"
                metricFunction={calcTotalDiagnosis}
                config={Config.Diagnosis}
              />
            )}
            {currentPage === 'activity' && (
              <Dashboard
                title="Clinical Activity"
                metric="Total Plans"
                metricFunction={calcTotalPlans}
                config={Config.ClinicalActivity}
              />
            )}
            {currentPage === 'machines' && (
              <Dashboard
                title="Machine Utilization"
                metric="Average Daily Treatments Per Linac"
                metricFunction={calcAverageDailyTreatmentsPerLinac}
                config={Config.MachineUtilisation}
                metricRound={1}
              />
            )}
            {currentPage === 'wait-times' && (
              <Dashboard
                title="Patient Wait Times"
                metric="Average Sim to Treatment Time (Days)"
                metricFunction={calcAverageSimToTreat}
                config={Config.PatientWaitTimes}
                metricRound={1}
              />
            )}
            {currentPage === 'productivity' && (
              <Dashboard
                title="Planning Productivity"
                metric="Average Daily Plans"
                metricFunction={calcAverageDailyPlans}
                config={Config.PlanningProductivity}
                metricRound={1}
              />
            )}
          </div>
        </StyledTile>
      </div>
    </DashboardContextProvider>
  );
};

export default InsightsDashboard;
