import Highcharts from 'highcharts';
import { syncConfig, creditsConfig, BottomChartParams, subExportingConfig, loadSeeMoreButton } from './DefaultConfig';

const positionText = (
  chart: Highcharts.Chart & { labelHeader1?: Highcharts.SVGElement; labelHeader2?: Highcharts.SVGElement },
  theme: any,
) => {
  const textWidth = 50;
  const xPosition1 = chart.chartWidth - textWidth - 68;
  const xPosition2 = chart.chartWidth - textWidth - 20;

  if (chart.labelHeader1 && chart.labelHeader2) {
    chart.labelHeader1.attr({ x: xPosition1 });
    chart.labelHeader2.attr({ x: xPosition2 });
  } else {
    chart.labelHeader1 = chart.renderer
      .text('Count', xPosition1, 60)
      .css({
        color: theme.palette.text.primary,
        fontSize: '12px',
        fontFamily: 'Poppins, sans-serif',
      })
      .add();

    chart.labelHeader2 = chart.renderer
      .text('% of total', xPosition2, 60)
      .css({
        color: theme.palette.text.secondary,
        fontSize: '12px',
        fontFamily: 'Poppins, sans-serif',
      })
      .add();
  }
};

const SubChartBar = ({
  title,
  connector,
  cell,
  filterOverride,
  seeMore,
  precision = 0,
  theme,
  toggleSeeMore,
}: BottomChartParams): Dashboards.Board.Options => ({
  components: [
    {
      cell: cell,
      connector: { id: connector },
      sync: syncConfig,
      type: 'Highcharts',
      chartOptions: {
        exporting: subExportingConfig,
        chart: {
          type: 'bar',
          spacing: [20, 130, 70, 20],
          height: 320,
          events: {
            load() {
              loadSeeMoreButton(this, toggleSeeMore, seeMore);
              positionText(this, theme);

              this.redraw = function () {
                Highcharts.Chart.prototype.redraw.call(this);
                positionText(this, theme);
              };
            },
          },
        },
        title: {
          text: title,
          align: 'left',
        },
        subtitle: {
          text: filterOverride,
          align: 'left',
          style: {
            color: 'rgba(8, 30, 67, 0.60)',
            fontSize: '14px',
          },
        },
        xAxis: {
          type: 'category',
          scrollbar: {
            enabled: true,
          },
        },
        yAxis: {
          reversed: true,
          visible: false,
          max: 200,
          scrollbar: {
            enabled: true,
          },
        },
        legend: {
          enabled: false,
        },
        tooltip: {
          enabled: false,
        },
        credits: creditsConfig,
        plotOptions: {
          bar: {
            borderRadius: 8,
            pointWidth: 9,
          },
          series: {
            cursor: 'pointer',
            dataLabels: [
              {
                enabled: true,
                formatter: function () {
                  return (
                    '<span style="font-weight: 400; font-family: Poppins, sans-serif">' +
                    this.y?.toFixed(precision) +
                    '</span>'
                  );
                },
                align: 'right',
                verticalAlign: 'middle',
                alignTo: 'plotEdges',
                x: 50,
                y: -1,
                crop: false,
                overflow: 'allow',
              },
              {
                enabled: true,
                formatter: function () {
                  const series = this.series;
                  const total = series.points.reduce(function (sum, point) {
                    if (point.y !== undefined) {
                      return sum + point.y;
                    }
                    return sum;
                  }, 0);
                  const percentage = (this.y !== null && this.y !== undefined ? (this.y / total) * 100 : 0).toFixed(0);
                  return (
                    '<span style="color: #696969; font-weight: 400; font-family: Poppins, sans-serif">' +
                    percentage +
                    '%' +
                    '</span>'
                  );
                },
                align: 'right',
                verticalAlign: 'middle',
                alignTo: 'plotEdges',
                x: 110,
                y: -1,
                crop: false,
                overflow: 'allow',
              },
            ],
          },
        },
      },
    },
  ],
});

export default SubChartBar;
