import React from 'react';
import { useFormikContext, Field, FormikProps } from 'formik';

import { HelperMessage, FormRow } from 'shared-components/components';
import { yesNoOptions } from 'op-utils';
import { ToggleButtonGroupField, SelectFieldBase } from 'shared-components/components/FormikComponents';

import { getLastUpdateUser } from '../../helpers';
import { content } from '../constants';
import { PreCtTriageData } from '../interfaces/PreCtTriageInterfaces';
import { sharedContent } from '../../shared/constants';
import { LastUpdateUserDetails, LocationListData } from '../../shared/interfaces';

interface Props {
  allowedLocations: LocationListData[];
  handleMutation: (name: string, value: string) => void;
  lastUpdateUserData: LastUpdateUserDetails[];
}

const MalnutritionRisk = ({ handleMutation, lastUpdateUserData, allowedLocations }: Props): JSX.Element => {
  const { values }: FormikProps<PreCtTriageData> = useFormikContext();
  const { nurseLocation, unintentionalWeightLoss, hasDecreasedAppetite } = content.malnutritionRisk.fields;
  const { malnutritionScreeningToolQCL } = content.malnutritionRisk.infoHelpers;

  const transformedLocationData = allowedLocations.reduce((allLocations: any, location) => {
    if (location.rolesUsedBy.includes('Nurse')) {
      allLocations.push({ label: location.name, value: location.id });
    }
    return allLocations;
  }, []);

  return (
    <>
      <FormRow fieldLabel={nurseLocation} fieldName={'nurseLocation'}>
        <Field
          name="nurseLocation"
          component={SelectFieldBase}
          options={transformedLocationData}
          placeholder={sharedContent.generic.label.selectValue}
          updateMutation={(value: string) => {
            handleMutation('nurseLocation', value);
          }}
          lastUpdateUser={getLastUpdateUser(lastUpdateUserData, 'nurseLocation')}
        />
      </FormRow>
      <FormRow fieldLabel={unintentionalWeightLoss} fieldName={'unintentionalWeightLoss'}>
        <Field
          name="unintentionalWeightLoss"
          component={ToggleButtonGroupField}
          label={unintentionalWeightLoss}
          options={yesNoOptions}
          handleMutation={handleMutation}
          lastUpdateUser={getLastUpdateUser(lastUpdateUserData, 'unintentionalWeightLoss')}
        />
        {values.unintentionalWeightLoss === true && (
          <HelperMessage
            fieldName={unintentionalWeightLoss}
            fieldText={malnutritionScreeningToolQCL}
            helperType="info"
          />
        )}
      </FormRow>
      <FormRow fieldLabel={hasDecreasedAppetite} fieldName={'hasDecreasedAppetite'}>
        <Field
          name="hasDecreasedAppetite"
          component={ToggleButtonGroupField}
          label={hasDecreasedAppetite}
          options={yesNoOptions}
          handleMutation={handleMutation}
          lastUpdateUser={getLastUpdateUser(lastUpdateUserData, 'hasDecreasedAppetite')}
        />
        {values.hasDecreasedAppetite === true && (
          <>
            <HelperMessage
              fieldName={hasDecreasedAppetite}
              fieldText={malnutritionScreeningToolQCL}
              helperType="info"
            />
          </>
        )}
      </FormRow>
    </>
  );
};

export default MalnutritionRisk;
