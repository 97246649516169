import { Box, useTheme, Button, Menu, MenuProps } from '@mui/material';
import React from 'react';
import { Close as CloseIcon, FilterList } from '@mui/icons-material';
import { styled } from '@mui/system';

const StyledSvg = styled('svg')`
  margin-bottom: 8px;
  margin-left: -8px;
  position: absolute;
}
`;

const StyledClose = styled('div')`
  cursor: pointer;
`;

const StyledMenu = (props: MenuProps) => (
  <Menu
    elevation={2}
    anchorOrigin={{
      vertical: 'bottom',
      horizontal: 'center',
    }}
    transformOrigin={{
      vertical: 'top',
      horizontal: 'center',
    }}
    {...props}
  />
);

interface Props {
  children: any;
  showAlert: boolean;
}

const SearchFilter = (props: Props): JSX.Element => {
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const theme = useTheme();

  const handleClick = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const greenCircle = (
    <StyledSvg height="15" width="15">
      <circle cx="7.5" cy="7.5" r="7.5" fill={theme.palette.success.dark} />
    </StyledSvg>
  );

  return (
    <div>
      <Button data-testid="search-filter-button" color="secondary" startIcon={<FilterList />} onClick={handleClick}>
        Filter
      </Button>
      <StyledMenu id="customized-menu" anchorEl={anchorEl} keepMounted open={Boolean(anchorEl)} onClose={handleClose}>
        <Box display="flex" flexDirection="row-reverse" mx={1}>
          <StyledClose data-test-id="close-filter" onClick={handleClose}>
            <CloseIcon color="primary" />
          </StyledClose>
        </Box>
        {props.children}
      </StyledMenu>
      {props.showAlert && greenCircle}
    </div>
  );
};

export default SearchFilter;
