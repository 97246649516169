// eslint-disable-next-line no-use-before-define
import React, { useState, useEffect, useRef } from 'react';
import { ApplyToType, DrugOrderInterface, NewSupportingDrugOrder } from 'op-pages/MO/interfaces';
import { CycleScheduleInterface, DoseDay } from '../interfaces';
import { useQuery } from '@apollo/client';
import { GET_DRUG_ORDERS } from '../../Queries';
import { Stack, styled } from '@mui/system';
import { DRUG_CATEGORIES } from 'op-pages/MO/constants';
import { InfoCircleBlueWhiteBorderIcon } from 'shared-components/images';
import { Grid, TextField } from '@mui/material';
import { ErrorOutline as ErrorOutlineIcon } from '@mui/icons-material';
import { getDrugName } from 'op-pages/MO/utils';
import { useTheme, Button, ToggleButton } from '@mui/material';
import { SelectOptionType, BaseTextField, BaseAutocomplete } from 'shared-components/components/FormFields';
import { StandardDialog } from 'shared-components/components';

export interface DoseInfo {
  drugOrder: DrugOrderInterface;
  doseDay: DoseDay;
  cycle: any;
}

interface Props {
  open: boolean;
  selectedCycle: number;
  cycleSchedule: CycleScheduleInterface[];
  setNewSupportingDrugOrder: (value: NewSupportingDrugOrder) => void;
  setSupportingDrugModalOpen: (value: boolean) => void;
}

const StyledLabel = styled('label')`
  font-size: 15px;
`;

const StyledErrorRow = styled('div')`
  color: ${(props) => props.theme.palette.error.main};
  display: flex;
  padding-top: 8px;
  align-items: center;
`;
const SupportingDrugModal = ({
  open,
  cycleSchedule,
  setNewSupportingDrugOrder,
  setSupportingDrugModalOpen,
  selectedCycle,
}: Props): JSX.Element => {
  const [applyTo, setApplyTo] = useState<ApplyToType>('careplan');
  const [drugTypeFilter, setDrugTypeFilter] = useState<string>('In House Treat');
  const [selectedDays, setSelectedDays] = useState<number[]>(() => []);
  const [selectedDrugOrder, setSelectedDrugOrder] = useState<DrugOrderInterface | null>(null);
  const [drugOrderDropdownOptions, setDrugOrderDropdownOptions] = useState<SelectOptionType[]>([]);
  const [instructions, setInstructions] = useState<string>('');
  const { data: drugOrderQuery } = useQuery(GET_DRUG_ORDERS, {
    variables: { drugCategory: DRUG_CATEGORIES.SUPPORTING },
  });
  const [validated, setValidated] = useState(false);
  const selectRef = useRef(null);
  const theme = useTheme();

  // Drug order search
  useEffect(() => {
    if (!drugOrderQuery) return;

    const uniqueDrugOrders: { [key: string]: { label: string; value: string } } = {};

    drugOrderQuery.drugOrders
      .filter((drugOrder: DrugOrderInterface) => drugOrder.type === drugTypeFilter)
      .forEach((drugOrder: DrugOrderInterface) => {
        const formattedName = getDrugName(drugOrder);
        if (!uniqueDrugOrders.hasOwnProperty(formattedName)) {
          uniqueDrugOrders[formattedName] = { label: formattedName, value: drugOrder.id };
        }
      });

    const newOptions = Object.values(uniqueDrugOrders).sort((a, b) => (a.label > b.label ? 1 : -1));
    setDrugOrderDropdownOptions(newOptions);
  }, [drugTypeFilter, drugOrderQuery]);

  useEffect(() => {
    if (selectedDrugOrder?.type === 'In House Treat') {
      setInstructions(selectedDrugOrder.administrationInstructions);
    } else {
      setInstructions('');
    }
  }, [selectedDrugOrder]);

  const handleSubmit = () => {
    if (!selectedDrugOrder || selectedDays.length === 0 || !instructions) {
      setValidated(true);
      return;
    }

    setNewSupportingDrugOrder({
      applyTo: applyTo,
      drugOrder: selectedDrugOrder,
      days: selectedDays,
      insertIndex: null,
      inStaging: selectedDrugOrder.type === 'In House Treat',
      cycleId: selectedCycle,
      instructions: instructions,
    });

    setSupportingDrugModalOpen(false);
    setInstructions('');
    setValidated(false);
    resetForm();
  };

  const toggleDay = (day: number) => {
    if (selectedDays.includes(day)) {
      setSelectedDays(
        selectedDays.filter((d) => {
          return d !== day;
        }),
      );
    } else {
      setSelectedDays([...selectedDays, day]);
    }
  };

  const resetForm = () => {
    setSelectedDrugOrder(null);
    setSelectedDays([]);
    setValidated(false);
  };

  const selectDrugOrder = (option: any) => {
    if (!option) return setSelectedDrugOrder(null);
    const actualDrug = drugOrderQuery.drugOrders.find((d: DrugOrderInterface) => d.id === option.value);
    setSelectedDrugOrder(actualDrug);
    setValidated(false);
  };

  const closeModal = () => {
    setSupportingDrugModalOpen(false);
    resetForm();
  };

  const labelWidth = 2;

  if (!drugOrderQuery) {
    return <></>;
  }

  // Note/TODO - Many aspects of styling on this to be determined following determination of styling standards by devs and implementation of GCUI standardisation
  // This includes (at least) the following items:
  // Form layout: (currently using inline styles and bootstrap classes)
  // Form Labels
  // Day buttons: currently using the GCUI button, but should be the new 'Tag' defined in the design system. Have suggested that this could be handled by buttons, but TBA
  // Searchable Dropdown: to be standardised

  const instructionsLabel = drugTypeFilter === 'Take At Home' ? 'Patient instructions*' : 'Admin instructions*';
  const instructionsPlaceholderText =
    drugTypeFilter === 'Take At Home' ? 'Please enter patient instructions.' : 'Please enter admin instructions.';

  const enabledDays = Object.entries<boolean>(
    cycleSchedule
      .slice(selectedCycle, applyTo === 'careplan' ? cycleSchedule.length : selectedCycle + 1)
      .reduce((previous, current) => {
        current.days.forEach((day) => {
          //@ts-ignore
          previous[day.day] = previous[day.day] !== undefined ? previous[day.day] || day.hidden : day.hidden;
        });
        return previous;
      }, {}),
  )
    .map((keyValue) => {
      return { id: parseInt(keyValue[0]), disabled: keyValue[1] };
    })
    .sort((a, b) => a.id - b.id);
  const anyDisabledDays = enabledDays.some((value) => value.disabled);
  return (
    <StandardDialog
      maxWidth="lg"
      open={open}
      onClose={closeModal}
      title="Add supporting drug"
      showDividers
      onSubmit={handleSubmit}
      submitText="Add">
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <div className={`col-${labelWidth}`}>
            <StyledLabel>Drug Type:</StyledLabel>
          </div>

          <div className="col">
            {/* <ToggleButton
              data-cy="add-supporting-drug-type"
              value={drugTypeFilter}
              setter={setDrugTypeFilter}
              onChange={() => {
                resetForm()
                setDrugTypeFilter
              )}
              buttons={[
                { value: 'Take At Home', text: 'Discharge Medication' },
                { value: 'In House Treat', text: 'In Clinic' },
              ]}
            /> */}
          </div>
        </Grid>

        <Grid item xs={12}>
          <div className={`col-${labelWidth}`}>
            <StyledLabel>Drug Lookup:</StyledLabel>
          </div>
          <div className="col">
            <BaseAutocomplete
              fullWidth
              ref={selectRef}
              value={selectedDrugOrder && { label: getDrugName(selectedDrugOrder), value: selectedDrugOrder.name }}
              id={'supporting-drug-dropdown'}
              options={drugOrderDropdownOptions}
              onChange={selectDrugOrder}
            />
          </div>
        </Grid>

        {selectedDrugOrder ? (
          <Grid container item xs={12} gap="8px">
            <Grid item xs={12}>
              <div className={`col-${labelWidth}`}>
                <StyledLabel>Apply To:</StyledLabel>
              </div>
              <div className="col">
                {/* <ToggleButton
                  data-cy="apply-to"
                  setter={setApplyTo}
                  value={applyTo}
                  buttons={[
                    { value: 'careplan', text: 'Rest of Careplan' },
                    { value: 'cycle', text: 'This Cycle' },
                  ]}
                /> */}
              </div>
            </Grid>
            <Grid item xs={12}>
              <div className="col">
                <InfoCircleBlueWhiteBorderIcon style={{ width: '17px', height: '17px' }} />
                <span style={{ paddingLeft: '8px', fontSize: '0.8125rem' }}>
                  {applyTo === 'careplan'
                    ? 'The selected days will be applied to all cycles from the current cycle'
                    : `The selected days will be applied to cycle ${selectedCycle + 1}`}
                </span>
              </div>
            </Grid>
            <Grid item xs={12}>
              <div className={`col-${labelWidth}`}>
                <StyledLabel>{instructionsLabel}</StyledLabel>
              </div>
              <div className="col">
                <TextField
                  variant="outlined"
                  fullWidth
                  size="small"
                  placeholder={instructionsPlaceholderText}
                  onChange={(text) => {
                    setInstructions(text.target.value);
                  }}
                  value={instructions}
                  multiline
                  rows={4}
                  id="instructions"
                  error={validated && !instructions}
                />
              </div>
            </Grid>
            {validated && !instructions && (
              <Grid item xs={12}>
                <div className={`col-${labelWidth}`}></div>
                <div className="col">
                  <StyledErrorRow>
                    <ErrorOutlineIcon style={{ width: '17px', height: '17px' }} />
                    <span style={{ paddingLeft: '8px', fontSize: '0.8125rem' }}>This field is required</span>
                  </StyledErrorRow>
                </div>
              </Grid>
            )}
            {!['0', ''].includes(selectedDrugOrder.solution) && (
              <Grid item xs={12}>
                <div className={`col-${labelWidth}`}>
                  <StyledLabel>Solution:</StyledLabel>
                </div>
                <div className="col">
                  <BaseTextField
                    disabled
                    fullWidth
                    data-cy={'supporting-drug-solution'}
                    value={selectedDrugOrder.solution}
                  />
                </div>
              </Grid>
            )}
            <Grid item xs={12}>
              <div className="col">Select days</div>
            </Grid>
            {anyDisabledDays && (
              <Grid item xs={12}>
                <div className="col" style={{ fontSize: '13px', color: theme.palette.grey[600] }}>
                  Some days are disabled due to day changes
                </div>
              </Grid>
            )}
            <Stack flexDirection={'row'} flexWrap={'wrap'}>
              {enabledDays.map((day) => (
                <div key={day.id} style={{ width: 70, margin: 8 }}>
                  <Button
                    data-cy={`add-drug-day-${day.id}`}
                    onClick={() => {
                      toggleDay(day.id);
                    }}
                    disabled={day.disabled}>
                    Day {day.id + 1}
                  </Button>
                </div>
              ))}
            </Stack>
          </Grid>
        ) : (
          <div style={{ minHeight: 300 }}></div>
        )}
      </Grid>
    </StandardDialog>
  );
};

export default SupportingDrugModal;
