import CareplanTable from './CareplanTable/CareplanTable';
import RODailyAppointment from './DailyAppointments/RODailyAppointment';
import { ROClinicListApollo } from 'op-pages/index';
import { Route, Switch, useRouteMatch } from 'react-router-dom';
import DashboardLHSPane from './DashboardLHSPane';
import { Stack, Card } from '@mui/material';
import { useState } from 'react';
import { Region } from 'shared-components/enums';
import { styled } from '@mui/system';
import './Dashboard.scss';
const REACT_APP_REGION = import.meta.env.REACT_APP_REGION;

// Some legacy jank to remove later
export const StyledTile = styled(Card)`
  flex-grow: 1;
  display: flex;
  align-items: center;
  flex-direction: column;
  height: auto;
  margin: 16px;
  padding: 16px;
`;

const Dashboard = (): JSX.Element => {
  const { path } = useRouteMatch();

  const [careplanFilter, setCareplanFilter] = useState<string[]>([]);
  const [statusFilter, setStatusFilter] = useState<string[]>([]);
  const isInUK = REACT_APP_REGION === Region.UK;

  return (
    <Stack direction="row" height={1} width={1}>
      <DashboardLHSPane setCareplanFilter={setCareplanFilter} setStatusFilter={setStatusFilter} />
      <Switch>
        <Route path={`${path}/careplans`}>
          <CareplanTable
            careplanFilter={careplanFilter}
            statusFilter={statusFilter}
            setCareplanFilter={setCareplanFilter}
            setStatusFilter={setStatusFilter}
          />
        </Route>
        <Route path={path} component={isInUK ? ROClinicListApollo : RODailyAppointment} />
      </Switch>
    </Stack>
  );
};

export default Dashboard;
