export const fieldValues = {
  bookingRequired: 'Booking Required',
  contrastType: 'Contrast Type',
  egfr_gt_30: 'EGFR > 30',
  oral: 'Oral',
  iv: 'IV',
  yes: 'yes',
  no: 'no',
  awaitingResults: 'Awaiting results',
};

export const BOOLEAN_OPTIONS = [
  { label: 'Yes', value: fieldValues.yes },
  { label: 'No', value: 'no' },
];

export const EGFR_GT_30 = [
  {
    label: 'Yes',
    value: fieldValues.yes,
  },
  { label: 'No', value: fieldValues.no },
  {
    label: fieldValues.awaitingResults,
    value: fieldValues.awaitingResults,
  },
];

export const BLOOD_TEST_LAST_3_MONTHS = [
  {
    label: 'Yes',
    value: fieldValues.yes,
  },
  {
    label: fieldValues.bookingRequired,
    value: fieldValues.bookingRequired,
  },
];

export const CONTRAST_TYPES = [
  {
    label: fieldValues.iv,
    value: fieldValues.iv,
  },
  {
    label: fieldValues.oral,
    value: fieldValues.oral,
  },
];

export const IMAGING_REQUIREMENT_OPTIONS = [
  { label: 'Book Imaging', value: 'bookImaging' },
  { label: 'Retrieve Imaging', value: 'retrieveImaging' },
];
