import { gql } from '@apollo/client';

import * as fragments from 'op-graphql/fragments';

export const HEALTH_ASSESSMENT_QUERY = gql`
  query HealthAssessment($id: ID!, $status: String!) {
    healthAssessment(id: $id, status: $status) {
      id
      status
      previousCancer
      cancer {
        id
        ...HealthAssessmentCancer
      }
      intractableInfectionOrIllnesses {
        id
        doctorClearance
        value
        other
      }
      immunosuppressiveConditions {
        id
        value
        other
      }
      otherRadiotherapy
      otherRadiotherapyConditions {
        id
        ...HealthAssessmentOtherRT
      }
      mobilityAid
      mobilityAids {
        id
        ...HealthAssessmentMobilityAid
      }
      wellbeingIssue
      wellbeingIssues {
        id
        ...HealthAssessmentWellbeingIssue
      }
      activeInjury
      activeInjuryReason
      fallen
      fallenReason
    }
    treatmentTypeRefData: listData(category: "treatmentType") {
      id
      name
    }
    treatmentStageRefData: listData(category: "treatmentStage") {
      id
      name
    }
    mobilityAidRefData: listData(category: "mobilityAids") {
      id
      name
    }
    wellbeingIssueRefData: listData(category: "wellbeingIssues") {
      id
      name
    }
    intractableInfectionOrIllnessesRefData: listData(category: "intractableInfectionOrIllnesses") {
      id
      name
    }
    immunosuppresiveConditionsRefData: listData(category: "immunosuppresiveConditions") {
      id
      name
    }
  }

  ${fragments.HEALTH_ASSESSMENT_CANCER_FRAGMENT}
  ${fragments.HEALTH_ASSESSMENT_MOBILITY_AID_FRAGMENT}
  ${fragments.HEALTH_ASSESSMENT_OTHER_RT_FRAGMENT}
  ${fragments.HEALTH_ASSESSMENT_WELLBEING_ISSUE_FRAGMENT}
`;

export const DT_RATING_QUERY = gql`
  query DTRating($patientId: ID!, $status: String!, $version: String!) {
    distressThermometer(patientId: $patientId, status: $status, version: $version) {
      id
      distressRating
      version
    }
  }
`;

export const LATEST_SUBMITTED_INITIAL_TRIAGE_QUERY = gql`
  query PreCtTriage($patientId: ID!) {
    lastSubmittedPreCtTriage(patientId: $patientId) {
      id
      isAmendment
      isLocked
      nurseLocation
      unintentionalWeightLoss
      hasDecreasedAppetite
      isAlertAndOrientated
      hasAlteredCognitiveState
      hasCurrentPain
      painScore
      isAbleToLieFlat
      isAbleToClimbStairs
      transportMode
      otherTransport
      socialConsiderations
      hasPatientCarerConcern
      pelvicFitness
      hasHighRiskFactors
      howFactorsWereIdentified
      additionalNotes
      patient {
        id
      }
      updatedBy
      lastUpdateUser {
        fieldName
        lastUpdateUser
        updatedDate
      }
    }
  }
`;
