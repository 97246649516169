import React, { createContext, useState } from 'react';

export const AssessmentContext = createContext<any>({});

export const AssessmentProvider = ({ children }: any): JSX.Element => {
  const [triageIdentifier, setTriageIdentifier] = useState(0);
  const [chartCheckIdentifier, setChartCheckIdentifier] = useState(0);

  return (
    <AssessmentContext.Provider
      value={{
        triageIdentifier,
        chartCheckIdentifier,
        setTriageIdentifier,
        setChartCheckIdentifier,
      }}>
      {children}
    </AssessmentContext.Provider>
  );
};
