import { HeaderNavigation } from 'op-pages/PX/Components';
import { findTitleFromUrl } from 'op-pages/PX/utils';
import { memo, useMemo } from 'react';
import { useHistory, useLocation } from 'react-router-dom';

import NavigationDropDown from '../../NavigationDropDown/NavigationDropDownApollo';

import classNames from 'classnames';
import { patientDashboardUrl } from 'op-pages/PX/utils/px_navigation';
import { DeviceUtilities } from 'shared-components/utils';
import './HeaderSection.scss';
import { Stack } from '@mui/material';

interface HeaderSectionIProps {
  clickLogoFn?: () => void;
  showNavigation?: boolean;
  titleOverride?: string;
  displayProfile?: boolean;
}

const HeaderSection = (props: HeaderSectionIProps): JSX.Element => {
  const { clickLogoFn, showNavigation = true, titleOverride, displayProfile = true } = props;
  const componentId = 'header-section';

  const history = useHistory<History>();
  const location = useLocation();
  const pathname = useMemo<string>(() => location.pathname, [location]);
  const title = useMemo<string>(() => findTitleFromUrl(pathname), [pathname]);
  const showLogo = useMemo<boolean>(() => !(title === 'Appointments' && DeviceUtilities.isMobileDevice()), [title]);

  const handleLogoClick = (): void => (clickLogoFn ? clickLogoFn() : history.replace(patientDashboardUrl));

  return (
    <Stack id={componentId} sx={{ display: 'flex', flexDirection: 'row' }}>
      <Stack
        id={`${componentId}-row`}
        sx={{
          width: '100%',
          display: 'flex',
          justifyContent: 'flex-end',
          flexDirection: 'row',
          boxShadow: '0 .125rem .25rem #00000013',
        }}>
        <div
          id={`${componentId}-logo`}
          className={classNames('text-left', { 'd-none': !showLogo })}
          onClick={handleLogoClick}
        />
        <div id={`${componentId}-page-title`} className="align-self-center text-center text-nowrap">
          {titleOverride ? titleOverride : title}
        </div>
        {showNavigation && (
          <div id={`${componentId}-navigation`}>
            <HeaderNavigation />
          </div>
        )}
      </Stack>
      {displayProfile && (
        <div className="nav-item text-nowrap">
          <NavigationDropDown />
        </div>
      )}
    </Stack>
  );
};

export default memo(HeaderSection);
