// eslint-disable-next-line no-use-before-define
import { useQuery } from '@apollo/client';
import { useParams } from 'react-router-dom';
import { LoadingSpinner } from 'shared-components/components';
import { FormProps } from '../interfaces';
import DocumentUploadForm from './DocumentUploadForm';
import { DOCUMENT_UPLOAD_QUERY } from './DocumentUploadQueries';

const DocumentUpload = (props: FormProps): JSX.Element => {
  const { exitForm } = props;
  const { patientId: id } = useParams<{ patientId: string }>();
  const { data, loading, error, refetch } = useQuery(DOCUMENT_UPLOAD_QUERY, { variables: { id } });

  if (loading) {
    return <LoadingSpinner container relativeSpinner />;
  } else if (error || !data) {
    return <></>;
  } else {
    const { documentTypeRefData, patient, user } = data;

    return (
      <DocumentUploadForm
        refData={{
          documentTypeRefData,
        }}
        refetch={refetch}
        patient={patient}
        handleShowSaveExitConfirm={() => exitForm(true)}
        user={user}
        isPso={user.isPso}
      />
    );
  }
};

export default DocumentUpload;
