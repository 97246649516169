import { StepperLink } from 'shared-components/interfaces';

export const CONTENT_STEPPER_LINKS: StepperLink[] = [
  {
    linkText: 'Initial consultation',
    href: '/px/information/initial-consultation',
  },
  {
    linkText: 'Out-of-pocket costs and Medicare',
    href: '/px/information/medicare-cost',
  },
  {
    linkText: 'Patient education with a Nurse',
    href: '/px/information/nurse-education',
  },
  {
    linkText: 'CT and Simulation',
    href: '/px/information/ct-simulations',
  },
  {
    linkText: 'Your first treatment',
    href: '/px/information/first-treatment',
  },
  {
    linkText: 'Remaining treatments',
    href: '/px/information/remaining-treatments',
  },
  {
    linkText: 'Potential side effects',
    href: '/px/information/side-effects',
  },
  {
    linkText: 'Care after treatment',
    href: '/px/information/treatment-aftercare',
  },
];

export const CHEMO_STEPPER_LINKS: StepperLink[] = [
  {
    linkText: 'Initial consultation',
    href: '/px/information/chemotherapy-initial-consultation',
  },
  {
    linkText: 'Out-of-pocket costs and Medicare',
    href: '/px/information/chemotherapy-medicare-cost',
  },
  {
    linkText: 'Imaging and pathology',
    href: '/px/information/chemotherapy-imaging-pathology',
  },
  {
    linkText: 'Treatment review',
    href: '/px/information/chemotherapy-review-treatment',
  },
  {
    linkText: 'Treatment',
    href: '/px/information/chemotherapy-treatment',
  },
  {
    linkText: 'Remaining treatments',
    href: '/px/information/chemotherapy-remaining-treatments',
  },
  {
    linkText: 'Care after treatment',
    href: '/px/information/chemotherapy-care-after-treatment',
  },
];

export const MAGIC_STRINGS = {
  CHEMOTHERAPY: 'Chemotherapy',
  RADIATION_THERAPY: 'Radiation Therapy',
};
