import moment from 'moment-timezone';
import 'moment/dist/locale/en-au';
const REACT_APP_REGION = import.meta.env.REACT_APP_REGION;

// Set the moment locale to the window navigator locale
export const setMomentLocale = (): void => {
  // This is can be an issue when user have different locale set for browser.
  // moment.locale(window.navigator.language);

  // Set locale based on the deployment.
  const envToLocaleMapping = { AU: 'en-au', UK: 'en-gb', US: 'en' };
  const locale =
    (REACT_APP_REGION && envToLocaleMapping[REACT_APP_REGION as keyof typeof envToLocaleMapping]) ||
    window.navigator.language;
  moment.locale(locale);
};
