// eslint-disable-next-line no-use-before-define
import React, { useCallback, useEffect, useState } from 'react';
import Select, { ValueType, components } from 'react-select';
import { SEARCH_DROPDOWN_PLACEHOLDER } from '../common';
import { FilteredList } from './FilteredList';
import { useTheme, IconButton, Tooltip } from '@mui/material';
import { styled } from '@mui/system';
import { Clear } from '@mui/icons-material';
import { SelectOptionType } from '../ROSelect/BaseSelect';

export interface FilteredListOption extends SelectOptionType {
  category?: string;
}

interface Props {
  defaultSelectedFilter?: string;
  selectedOption?: FilteredListOption;
  options?: FilteredListOption[];
  onChange: (selectedOption: ValueType<FilteredListOption>) => void;
  isLoading?: boolean;
  isDisabled?: boolean;
  hasError?: boolean;
  hasWarning?: boolean;
  id?: string;
  tooltip?: string;
}

const StyledClearIndication = styled('div')`
  svg {
    cursor: pointer;
    display: none;
  }
`;

const ClearIndicator = (props: any): JSX.Element => {
  const {
    children = (
      <IconButton size="small">
        <Clear color="primary" sx={{ height: '20px', width: '20px' }} />
      </IconButton>
    ),
    innerProps: { ref, ...restInnerProps },
  } = props;
  return (
    <StyledClearIndication {...restInnerProps} ref={ref}>
      <div>{children}</div>
    </StyledClearIndication>
  );
};

export const FilteredListDropdown = (props: Props): JSX.Element => {
  const { options, onChange, hasError, isLoading, isDisabled, selectedOption, id, hasWarning, defaultSelectedFilter } =
    props;
  const [menuOpen, setMenuOpen] = useState(false);
  const [inputValue, setInputValue] = useState('');
  const [optionsList, setOptionsList] = useState<FilteredListOption[]>([]);
  const theme = useTheme();

  useEffect(() => {
    function getOptionsList() {
      if (options) {
        if (inputValue.length > 0) {
          const uniqueValues = [...new Set(options?.map((option) => option.value))];
          const uniqueOptions = uniqueValues.map(
            (uniqueValue) => options.filter((option) => option.value === uniqueValue)[0],
          );

          // removes any duplicates, sorts alphabetically
          const uniqueSortedOptions: FilteredListOption[] = [...new Set(uniqueOptions)]
            .map((uniqueOption) => JSON.parse(JSON.stringify(uniqueOption)))
            .sort((a, b) => (a.label > b.label ? 1 : -1));

          const matchingOptions = uniqueSortedOptions.filter((options) => {
            return options.label.toLowerCase().includes(inputValue.toLowerCase());
          });
          return matchingOptions;
        } else {
          const categories = [...new Set(options.map((option) => option.category))];
          const filterListOptions: FilteredListOption[] = categories.map((category) => {
            const validOptions = options?.filter((option) => {
              return option.category === category;
            });
            return {
              value: category ?? '',
              label: category ?? '',
              options: validOptions || [],
            };
          });
          return filterListOptions;
        }
      }
      return [];
    }
    setOptionsList(getOptionsList());
  }, [inputValue, options]);

  const customStyles = {
    menu: (provided: any) => ({
      ...provided,
      display: inputValue.length > 0 && optionsList.length > 0 ? 'block' : 'flex',
      overflow: inputValue.length > 0 ? 'auto' : 'hidden',
      minHeight:
        inputValue.length > 0 || optionsList.length === 0 ? (optionsList.length > 0 ? 'auto' : '104px') : '200px',
      maxHeight: optionsList.length === 0 ? '104px' : '200px',
    }),
    menulist: (provided: any) => ({ ...provided, flex: 1 }),
    clearIndicator: () => ({
      padding: 0,
    }),
    dropdownIndicator: () => ({
      color: isDisabled ? theme.palette.grey[300] : theme.palette.primary.dark,
      padding: '8px',
      transition: 'transform 300ms',
      transform: menuOpen ? 'rotate(180deg)' : 'none',
    }),
    indicatorSeparator: () => ({ display: 'none' }),
    singleValue: () => ({
      color: theme.palette.text.primary,
    }),
    control: (base: any) => ({
      ...base,
      color: theme.palette.text.primary,
      cursor: 'pointer',
      boxShadow: 'none',
      height: '40px',
      border: `1px solid ${
        hasError
          ? theme.palette.error.main
          : hasWarning
          ? theme.palette.warning.dark
          : menuOpen
          ? theme.palette.primary.dark
          : theme.palette.grey[300]
      }`,
      '&:hover': {
        backgroundColor: theme.palette.grey[100],
        svg: {
          display: 'block !important',
        },
      },
    }),
    valueContainer: (base: any) => ({
      ...base,
      lineHeight: '32px',
      padding: '0px 8px',
      whiteSpace: 'nowrap',
      textOverflow: 'ellipsis',
      fontSize: '16px',
      cursor: 'pointer',
      '> div': {
        maxWidth: '300px',
      },
    }),
  };
  const tooltip = props.tooltip || '';
  const CustomControl = useCallback(
    (props: any): JSX.Element => {
      return (
        <Tooltip arrow title={tooltip} placement="bottom" disableInteractive>
          <div>
            <components.Control {...props} />
          </div>
        </Tooltip>
      );
    },
    [tooltip],
  );
  return (
    <Select
      tooltip={'hello world'}
      id={id}
      data-testid={id}
      defaultSelectedFilter={defaultSelectedFilter}
      onInputChange={(newInputValue) => setInputValue(newInputValue)}
      value={selectedOption}
      onMenuOpen={() => setMenuOpen(true)}
      onBlur={() => {
        setMenuOpen(false);
      }}
      onMenuClose={() => setMenuOpen(false)}
      onChange={onChange}
      isLoading={menuOpen ? isLoading : false}
      isDisabled={isDisabled}
      placeholder={SEARCH_DROPDOWN_PLACEHOLDER}
      styles={customStyles}
      isClearable={true}
      components={{
        Control: CustomControl,
        MenuList: FilteredList,
        ClearIndicator: ClearIndicator,
        LoadingIndicator: null,
      }}
      options={isLoading ? undefined : optionsList}
      classNamePrefix={'filtered-option-search'}></Select>
  );
};
