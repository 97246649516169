// eslint-disable-next-line no-use-before-define
import React, { Component } from 'react';
import Autosuggest from 'react-autosuggest';
import classNames from 'classnames';

import './AutoSuggestTextField.scss';

import { ListData } from 'shared-components/interfaces';

import { ErrorInfo } from 'shared-components/components/FormFields';

/**
 * Props
 * @property {String} inputName The input name
 * @property {ListData} suggestionList The list of suggestions to show to use
 * @property {string=} defaultValue Default selected suggestion if there is one
 * @property {string} placeholder Input placeholder text
 * @property {string=} preventNumberInput Prevents the user typing in numbers. False by default.
 * @property {string[]=} errors Array of error messages to show for an invalid input
 */
interface Props {
  inputName: string;
  suggestionList: ListData[];
  defaultValue?: string;
  maxLength?: number;
  placeholder: string;
  preventNumberInput?: boolean;
  onBlur: (string: string) => void;
  errors?: string[];
  disabled?: boolean;
}

/**
 * State
 * @property {string} value The current input textbox value
 * @property {ListData} selectedsuggestion Current selected suggestion
 * @property {ListData[]} suggestions The suggestions to display as the user is typing
 */
interface State {
  value: string;
  selectedsuggestion?: string;
  suggestions: ListData[];
}

class AutoSuggestText extends Component<Props, State> {
  // View model for the registration form component

  /**
   * @property {number} inputWidth The width of the suggestion box
   * @property {container} any A reference to the input component. Used to calgulate what the suggestion box width should be
   * @property {NodeJS.Timeout | undefined} any Stores a timeout to trigger a resize. So resize is constantly
   * @property {RESIZE_TIMEOUT} number Stored the timeout in which after a user has finished resizing the window the input will resize
   */
  private inputWidth = 0;
  private container: any;
  private resizeId?: NodeJS.Timeout;
  private RESIZE_TIMEOUT = 500;

  private suggestionList: ListData[] = this.props.suggestionList;

  /**
   * Constructor
   * Set the default values for the compoent
   * If there is a selected suggestion passed then set it
   * @param props
   */
  public constructor(props: Props) {
    super(props);

    let initState: State = {
      value: '',
      suggestions: [],
    };

    // If there is a default selected suggestion set it
    if (
      this.props.defaultValue !== undefined &&
      this.props.suggestionList.find((x): boolean => x.id === this.props.defaultValue)
    ) {
      // We already checked that there was something found in the if statement
      // So allow for next line to be force unwrapped
      /* eslint-disable-next-line */
      let selectedSuggestion: ListData = this.props.suggestionList.find(
        (x): boolean => x.id === this.props.defaultValue,
      )!; // eslint-disable

      initState = {
        ...initState,
        selectedsuggestion: selectedSuggestion.id,
        value: selectedSuggestion.name,
      };
    } else if (this.props.defaultValue) {
      initState = {
        ...initState,
        value: this.props.defaultValue,
      };
    }

    this.state = initState;
  }

  /**
   * Set the suggestion box width on component did mount
   * And add a listeners for a window resize event
   */
  public componentDidMount = (): void => {
    // As suggestion box is absolute we need to dynamically set its width
    // In order to be responsive
    this.setSuggestionBoxWidth();
    window.addEventListener('resize', (): void => {
      // Throttle suggestionbox resizing to only happen 0.5s after user stopped resizing window
      if (this.resizeId) {
        clearTimeout(this.resizeId);
      }

      this.resizeId = setTimeout(this.setSuggestionBoxWidth, this.RESIZE_TIMEOUT);
    });
  };

  /**
   * Renders a suggestion item in the dropdown list
   * @param suggestion The suggestion being rendered
   */
  private renderSuggestion = (suggestion: ListData): JSX.Element => (
    <div style={{ width: this.inputWidth - 20 }} className="suggestion-container">
      <div className="suggestion-item">{suggestion.name}</div>
    </div>
  );

  /**
   * Render the input component, to keep accessible this component MUST
   * render an input
   * pass through all the provided inputProps to the input
   * @param inputProps
   */
  // Changing any to TS will break this
  /* eslint-disable-next-line */
  private renderInputComponent = (inputProps: any): JSX.Element => {
    return (
      <input
        {...inputProps}
        /* eslint-disable-next-line */
        ref={(input) => {
          // inputProps refs gets overwritten by calling the ref
          // So ensure its still set, this is used for window resizing.
          if (inputProps.ref) {
            inputProps.ref(input);
          }
          this.container = input;
        }}
      />
    );
  };

  /**
   * Set the suggestion box width
   */
  private setSuggestionBoxWidth = (): void => {
    if (this.container) {
      this.inputWidth = this.container.getBoundingClientRect().width;
    }
  };

  /**
   * This is called when a user clicks on a suggestion
   * Set the current selected suggestion,
   * @return {string} Text to display in the input field
   */
  private setSuggestionValue = (suggestion: ListData): string => {
    this.setState({
      selectedsuggestion: suggestion.id,
    });
    return suggestion.name;
  };

  /**
   * Called every time the user changes the input
   * @param newValue The new input value
   * @param method The string describing how the change occured, the possible values are:
   *  'down' - user pressed Down
   *  'up' - user pressed Up
   *  'escape' - user pressed Escape
   *  'enter' - user pressed Enter
   *  'click' - user clicked (or tapped) on suggestion
   *  'type'- when the user types something in the field (Could be a character, backspace, etc..)
   */
  private onChange(event: React.FormEvent<any>, { newValue, method }: Autosuggest.ChangeEvent): void {
    // Input is dirty, set selected suggestion to undefined
    // Will be set again once the user selects one or types to exactly match one
    if (method === 'type') {
      this.setState({
        selectedsuggestion: undefined,
      });
    }

    // Set the display value
    this.setState({ value: newValue });
  }

  /**
   * Called when input loses focus
   * If there was no suggestion selected, and the user's exactly input matches the only one left
   * Then set it as the selectedSuggestion otherwise do nothing
   * @param event Blur event
   * @param highlightedSuggestion The last suggestion that was highlited
   */
  private onBlur(): void {
    let currentIdValue = this.state.selectedsuggestion;
    let currentValue = this.state.value;
    if (this.updateOnBlur()) {
      this.setState({
        selectedsuggestion: this.state.suggestions[0].id,
        value: this.state.suggestions[0].name,
      });
      currentIdValue = this.state.suggestions[0].id;
      currentValue = this.state.suggestions[0].name;
    }

    if (!currentIdValue) {
      currentIdValue = currentValue;
    }
    this.props.onBlur(currentIdValue);
  }

  /**
   * Called everytime we need to update the suggestions
   */
  private onSuggestionsFetchRequested = ({ value }: any): void => {
    const newValue = this.getSuggestions(value);
    this.setState({
      suggestions: newValue,
    });
  };

  /**
   * Clears all the autosuggestions
   */
  private onSuggestionsClearRequested = (): void => {
    this.setState({
      suggestions: [],
    });
  };

  /**
   * Returns true if the component should update it's state on blur
   * Only if there is one remaining auto suggest left and the users input value matches it exactly
   * @param state The components current state, readonly
   * @returns {boolean} If the model should update on a blur
   */
  private updateOnBlur = (): boolean => {
    if (this.state.selectedsuggestion === undefined) {
      const { suggestions, value } = this.state;

      // First check if there was only one suggestion displayed onBlur
      if (suggestions.length === 1) {
        // If suggested item exactly matches the text input item then set it as the selected suggestion
        if (suggestions[0].name.toLowerCase() === value.toLowerCase()) {
          return true;
        }
      }
    }

    return false;
  };

  private onKeyPress(e: React.KeyboardEvent<any>): void {
    const isNumber: boolean = isNaN(Number(e.key.trim())) ? false : true;
    if (this.props.preventNumberInput && isNumber) {
      e.preventDefault();
    }
  }

  /**
   * Calculate the suggestions for a given input value
   * The suggestions will be filtered of the 'Display' key
   * @param {string} value Suggestion list will filter on this value
   * @returns {ListData[]} Filtered list based on the value
   */
  private getSuggestions = (value: string): ListData[] => {
    const inputValue = value.trim().toLowerCase();
    const inputLength = inputValue.length;

    return inputLength === 0
      ? []
      : this.suggestionList.filter((suggestion): boolean => suggestion.name.toLowerCase().includes(inputValue));
  };

  public render(): JSX.Element {
    const { value, suggestions } = this.state;
    const { errors, maxLength } = this.props;

    return (
      <div className={classNames({ 'validation-error': errors && errors.length > 0 })}>
        <Autosuggest
          suggestions={suggestions}
          onSuggestionsFetchRequested={this.onSuggestionsFetchRequested}
          onSuggestionsClearRequested={this.onSuggestionsClearRequested}
          getSuggestionValue={this.setSuggestionValue}
          renderSuggestion={this.renderSuggestion}
          renderInputComponent={this.renderInputComponent}
          inputProps={{
            placeholder: this.props.placeholder || '',
            name: this.props.inputName,
            value,
            // UPGRADE: Typescript 4 Upgrade - No longer available
            // selectedsuggestion,
            maxLength,
            onBlur: (_: React.FormEvent<any>): void => this.onBlur(),
            onChange: (e: React.FormEvent<any>, changeEvent: any): void => this.onChange(e, changeEvent),
            onKeyPress: (e: React.KeyboardEvent<any>): void => this.onKeyPress(e),
            disabled: this.props.disabled,
          }}
        />
        <ErrorInfo errors={errors} />
      </div>
    );
  }
}

export default AutoSuggestText;
