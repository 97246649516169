import React from 'react';
import { sharedContent } from '../constants';
import { styled } from '@mui/system';

const MainSection = styled('section')`
  font-size: 1.125rem;
  margin-bottom: 10px;
  line-height: 28px;
`;

const { mainText1, mainText2 } = sharedContent.reviewAndSubmit.missingQcl;

interface Props {
  sectionName: string;
}
// HTML set from NurseAssessment constants file
const ModalContentMissingQcl = ({ sectionName }: Props): JSX.Element => (
  <MainSection>{<div dangerouslySetInnerHTML={{ __html: `${mainText1} ${sectionName} ${mainText2}` }} />}</MainSection>
);

export default ModalContentMissingQcl;
