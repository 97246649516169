// eslint-disable-next-line no-use-before-define
import React, { Component } from 'react';

/**
 * Props interface for the FieldComponent
 * @param {string} title Contains the title of the componenet
 * @param {string} htmlFor  Relation to the componenet that's the child
 * @param {boolean=} required Boolean tracking the required status of the component defaults to false
 * @param {boolean=} invalidInput invalidInput tracking if the component had invalid input defaults to false
 */
interface Props {
  title: string;
  htmlFor: string;
  required?: boolean;
  invalidInput?: boolean;
  children?: React.ReactNode;
}

class FormSegmentField extends Component<Props> {
  public static defaultProps = {
    required: false,
    invalidInput: true,
  };

  public render(): JSX.Element {
    const {
      props: { htmlFor, title, required },
    } = this;

    return (
      <div className="form-section-field">
        <label className={'field-label ' + (this.props.invalidInput ? 'validation-error' : null)} htmlFor={htmlFor}>
          {required ? title + '*' : title}
        </label>
        {this.props.children}
      </div>
    );
  }
}

export default FormSegmentField;
