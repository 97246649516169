export const SUBMIT_MODAL = {
  CONFIRM_EXIT: 'Are you sure you want to submit and exit the form?',
  CONFIRM_SUBMIT: 'Are you sure you want to submit the form?',
  CONFIRM_DETAILS_COMPLETE:
    'Please ensure all your details are correct. You will be redirected to the Main Menu upon submission.',
  CONFIRM_DETAILS_CONTINUE:
    'Please ensure all your details are correct. You will be redirected to the next form upon submission.',
  CONFIRM_PATIENT_DETAILS: 'Please ensure patient details are correct.',
  PATIENT_INFO_UPDATE: 'Patient information will be updated in both systems.',
  SUBMIT_TO_MOSAIQ:
    'You are submitting a patient to the Clinical Mosaiq system. This should not be used to create test patient records; once created records cannot be deleted.',
  SUBMIT_TO_TEST: 'You are about to submit a patient to the TEST instance of MOSAIQ',
  PDF_SENT: 'A PDF of this registration form will be sent to Mosaiq.',
  PATIENT_PORTAL_REMINDER: "Don't forget to ask the patient if they would like to join the Patient Portal",
};
