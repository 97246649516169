// eslint-disable-next-line no-use-before-define
import React, { Component, PropsWithChildren } from 'react';

/**
 * Form Content component. Wraps all the form content and differentiates from the stepper component
 */
class FormContent extends Component<PropsWithChildren> {
  public render(): JSX.Element {
    return <div className="form-content">{this.props.children}</div>;
  }
}

export default FormContent;
