import { BaseSingleInputFieldProps, DateValidationError, FieldSection, UseDateFieldProps } from '@mui/x-date-pickers';
import { Dayjs } from 'dayjs';
import React from 'react';
import { TableColumn } from '../interfaces';
import { DayLabel, HeaderContainer } from './StyledComponents';
import moment from 'moment';

interface DatePickerHeaderProps
  extends UseDateFieldProps<Dayjs, false>,
    BaseSingleInputFieldProps<Dayjs | null, Dayjs, FieldSection, false, DateValidationError> {
  setOpen?: React.Dispatch<React.SetStateAction<boolean>>;
  column: TableColumn;
}

const DatePickerHeader = (props: DatePickerHeaderProps): JSX.Element => {
  const {
    setOpen,
    label,
    id,
    disabled,
    InputProps: { ref } = {},
    inputProps: { 'aria-label': ariaLabel } = {},
    column,
  } = props;
  return (
    <HeaderContainer
      id={id}
      data-cy={`cycle-table-date-header-${column.day}`}
      ref={ref}
      aria-label={ariaLabel}
      onClick={() => setOpen?.((prev) => !prev)}>
      <span className="mb-1">{moment(column.date).format('L')}</span>
      <DayLabel> DAY {column.day + 1}</DayLabel>
    </HeaderContainer>
  );
};

export default DatePickerHeader;
