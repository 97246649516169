// eslint-disable-next-line no-use-before-define
import React from 'react';

import { StepperButton } from 'shared-components/components';

import './RegistrationConflictsStepperButton.scss';

interface Props {
  patientId: string;
}

const RegistrationConflictsStepperButton = ({ patientId }: Props): JSX.Element => {
  return (
    <div className="registration-conflicts-stepper-button">
      <StepperButton
        linkText={'Resolve data conflicts'}
        linkHref={`/registration/${patientId}/conflicts`}
        isActive={true}
        visited={true}
        alert={true}
      />
    </div>
  );
};

export default RegistrationConflictsStepperButton;
