import { gql } from '@apollo/client';

const FORM_INSTANCE_FRAGMENT = gql`
  fragment formInstance on FormInstanceType {
    formConfig {
      name
      label
      components {
        id
        parentId
        type
        field {
          formControl
          name
          dataType
          listDataCategory
        }
        label
        visible
        tooltip
        validators
        placeholder
      }
    }
    values {
      field
      value
    }
    listData {
      category
      references {
        data {
          option
        }
        listWeight
      }
    }
  }
`;

export const GET_OUTCOMES_BY_DIAGNOSIS = gql`
  ${FORM_INSTANCE_FRAGMENT}
  query GetOutcomesByDiagnosis($patientId: ID!) {
    defaultTimePoints
    outcomesByDiagnosis(patientId: $patientId) {
      diagnosisId
      diagnosisDate
      diagnosis
      outcomes {
        id
        timePoint
        lastModified
        active
        status
        createdAt
        formInstance {
          ...formInstance
        }
      }
    }
  }
`;

export const GET_OUTCOME = gql`
  ${FORM_INSTANCE_FRAGMENT}
  query outcome($outcomeId: ID!) {
    outcome(outcomeId: $outcomeId) {
      id
      timePoint
      diagnosisName
      status
      createdAt
      submittedAt
      isDefaultTimepoint
      notePreview
      note {
        createdBy {
          firstName
          lastName
        }
        createdAt
        messageType {
          alias
        }
      }
      formInstance {
        ...formInstance
      }
    }
  }
`;

export const CREATE_OUTCOME = gql`
  mutation CreateOutcome($diagnosisId: ID!) {
    createOutcome(diagnosisId: $diagnosisId) {
      outcome {
        id
      }
    }
  }
`;

export const UPDATE_OUTCOME = gql`
  mutation UpdateOutcome($outcomeId: ID!, $status: String, $values: String) {
    updateOutcome(outcomeId: $outcomeId, status: $status, values: $values) {
      outcome {
        id
        status
        lastModified
        active
        submittedAt
      }
    }
  }
`;

export const SAVE_OUTCOME_DATA = gql`
  mutation saveOutcomeData($outcomeId: ID!, $jsonData: String!) {
    saveOutcomeData(outcomeId: $outcomeId, jsonData: $jsonData) {
      outcome {
        id
        status
        lastModified
        notePreview
      }
    }
  }
`;

export const DISCARD_OUTCOME = gql`
  mutation ($outcomeId: ID!) {
    discardOutcome(outcomeId: $outcomeId) {
      outcome {
        id
        status
        active
      }
    }
  }
`;
