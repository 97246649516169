import { FormikValues, validateYupSchema, yupToFormErrors } from 'formik';
import { CurrentAppConfig } from 'op-pages/RO/Careplan/AppConfig';
import { EMAIL_LENGTH_VALIDATION } from 'op-pages/Shared/constants';
import { EMAIL_STRING_REGEX, US_PHONE_REGEX } from 'shared-components/utils/Regex';
import * as Yup from 'yup';
import { PHONE_REQUIRED, PHONE_VALIDATION } from '../Helper';
import { EMAIL_VALIDATION } from './constants';

export const generateValidationSchema = (values: FormikValues): any => {
  const multiFieldSchema = Yup.object().shape(
    {
      primaryPhone: Yup.string()
        .ensure()
        .when('secondaryPhone', {
          is: '',
          then: Yup.string().required(PHONE_REQUIRED).matches(US_PHONE_REGEX, PHONE_VALIDATION),
        }),
      secondaryPhone: Yup.string()
        .ensure()
        .when('primaryPhone', {
          is: '',
          then: Yup.string().required(PHONE_REQUIRED).matches(US_PHONE_REGEX, PHONE_VALIDATION),
        }),
      email: Yup.string()
        .matches(new RegExp(EMAIL_STRING_REGEX), EMAIL_VALIDATION)
        .max(CurrentAppConfig.EmailMaxLength, EMAIL_LENGTH_VALIDATION),
      workPhone: Yup.string().matches(US_PHONE_REGEX, PHONE_VALIDATION),
    },
    [['primaryPhone', 'secondaryPhone']],
  );

  const regexSchema = {
    primaryPhone: Yup.string().matches(US_PHONE_REGEX, PHONE_VALIDATION),
    secondaryPhone: Yup.string().matches(new RegExp(US_PHONE_REGEX), PHONE_VALIDATION),
  };

  try {
    validateYupSchema<FormikValues>(values, multiFieldSchema, true);
    validateYupSchema<FormikValues>(values, Yup.object(regexSchema), true);
  } catch (err) {
    return yupToFormErrors(err);
  }
};
