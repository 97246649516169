// eslint-disable-next-line no-use-before-define
import React, { Component } from 'react';
import { FormDataModel } from 'shared-components/interfaces';

import './Form.scss';

/**
 * Form Props interface
 * @property {string} id - id html attribute. Identifies the form instance uniquely
 * @property {FormDataModel} formData - Form Data Model object containing the data of the form
 */
interface Props {
  id: string;
  formData: FormDataModel;
  submit?: () => void;
  children?: React.ReactNode;
}

/**
 * Form component. Acts as a wrapper and tracks the form model and the data associated with the entire form
 */
class Form extends Component<Props, FormDataModel> {
  public constructor(props: Props) {
    super(props);
    this.state = this.props.formData;
  }

  public render(): JSX.Element {
    return (
      <form
        id={this.props.id}
        className="form-wrapper"
        onSubmit={(e): boolean => {
          e.preventDefault();
          if (this.props.submit) {
            this.props.submit();
          }
          return false;
        }}>
        {this.props.children}
      </form>
    );
  }
}

export default Form;
