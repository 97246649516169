import { TextAreaField, HtmlInputField, ToggleButtonGroupField } from 'shared-components/components/FormikComponents';
import { MaskField } from 'shared-components/components/FormikComponents';

export const FIELDS = {
  FORM_HEADING: {
    NAME: 'formHeading',
    TITLE: 'Health insurance',
  },
  MEDICARE_OR_DVA: {
    NAME: 'healthMedicareDvaOption',
    TITLE: 'Medicare and/or Department of Veterans Affairs (DVA) number*',
    PLACE_HOLDER: 'Start typing or select from dropdown',
    COMPONENT: ToggleButtonGroupField,
  },
  MEDICARE_CARD_NUMBER: {
    NAME: 'healthMedicareNumber',
    TITLE: 'Medicare card number',
    COMPONENT: MaskField,
  },
  MEDICARE_CARD_IRN: {
    NAME: 'healthMedicareIrn',
    TITLE: 'IRN',
    PLACE_HOLDER: '#',
    COMPONENT: HtmlInputField,
  },
  MEDICARE_CARD_EXPIRY_MONTH: {
    NAME: 'medicareExpiryRawMonth',
    TITLE: 'Medicare card expiry month',
    PLACE_HOLDER: 'MM',
    COMPONENT: HtmlInputField,
  },
  MEDICARE_CARD_EXPIRY_YEAR: {
    NAME: 'medicareExpiryRawYear',
    TITLE: 'Medicare card expiry year',
    PLACE_HOLDER: 'YYYY',
    COMPONENT: HtmlInputField,
  },
  DVA_CARD_NUMBER: {
    NAME: 'healthDvaNumber',
    TITLE: 'Department of Veterans Affairs (DVA) card number*',
    PLACE_HOLDER: 'e.g. QSS12345 or VSS54321A',
    COMPONENT: HtmlInputField,
  },
  DVA_CARD_TYPE: {
    NAME: 'healthDvaType',
    TITLE: 'DVA card type*',
    PLACE_HOLDER: 'e.g. QSS12345 or VSS54321A',
    COMPONENT: ToggleButtonGroupField,
  },
  DVA_CARD_EXPIRY_MONTH: {
    NAME: 'dvaExpiryRawMonth',
    TITLE: 'DVA card expiry month*',
    PLACE_HOLDER: 'MM',
    COMPONENT: HtmlInputField,
  },
  DVA_CARD_EXPIRY_YEAR: {
    NAME: 'dvaExpiryRawYear',
    TITLE: 'DVA card expiry year*',
    PLACE_HOLDER: 'YYYY',
    COMPONENT: HtmlInputField,
  },
  HAS_PRIVATE_INSURANCE: {
    NAME: 'healthPrivateHealthInsurance',
    TITLE: 'Private health insurance',
    COMPONENT: ToggleButtonGroupField,
  },
  HEALTH_FUND_NAME: {
    NAME: 'healthPrivateHealthFundName',
    TITLE: 'Private health fund name',
    PLACE_HOLDER: 'Enter fund name',
    COMPONENT: TextAreaField,
  },
  HEALTH_FUND_NUMBER: {
    NAME: 'healthPrivateHealthFundNumber',
    TITLE: 'Private health fund number',
    PLACE_HOLDER: 'Enter fund number',
    COMPONENT: TextAreaField,
  },
  HAS_PENSION_CARD: {
    NAME: 'healthPensionCard',
    TITLE: 'Are you a concession card holder?*',
    COMPONENT: ToggleButtonGroupField,
  },
  CONCESSION_CARD_TYPE: {
    NAME: 'healthPensionCardType',
    TITLE: 'Type of Services Australia card*',
    COMPONENT: ToggleButtonGroupField,
  },
  PENSION_CRN: {
    NAME: 'healthPensionCardNumber',
    TITLE: 'Concession card number*',
    PLACE_HOLDER: 'e.g. 123123123A',
    COMPONENT: HtmlInputField,
  },
  PENSION_CARD_EXPIRY: {
    NAME: 'healthPensionCardExpiry',
    TITLE: 'Concession card expiry date*',
    COMPONENT: null,
  },
  PENSION_CARD_START: {
    NAME: 'healthPensionCardStart',
    TITLE: 'Concession card start date*',
    COMPONENT: null,
  },
};

export const dvaFieldsToDelete = [
  FIELDS.DVA_CARD_NUMBER.NAME,
  FIELDS.DVA_CARD_EXPIRY_MONTH.NAME,
  FIELDS.DVA_CARD_EXPIRY_YEAR.NAME,
  FIELDS.DVA_CARD_TYPE.NAME,
];
export const medicareFieldsToDelete = [
  FIELDS.MEDICARE_CARD_NUMBER.NAME,
  FIELDS.MEDICARE_CARD_IRN.NAME,
  FIELDS.MEDICARE_CARD_EXPIRY_MONTH.NAME,
  FIELDS.MEDICARE_CARD_EXPIRY_YEAR.NAME,
];
export const privateFieldsToDelete = [FIELDS.HEALTH_FUND_NAME.NAME, FIELDS.HEALTH_FUND_NUMBER.NAME];
export const pensionFieldsToDelete = [
  FIELDS.PENSION_CRN.NAME,
  FIELDS.PENSION_CARD_EXPIRY.NAME,
  FIELDS.PENSION_CARD_START.NAME,
  FIELDS.CONCESSION_CARD_TYPE.NAME,
];
