export const patientSummaryLink = '/patient/:patientId/summary';

export enum SECTIONS {
  MALNUTRITION_RISK = 'malnutrition-risk',
  COGNITIVE_IMPAIRMENT_RISK = 'cognitive-impairment-risk',
  PAIN = 'pain',
  TRANSPORT_MODE = 'transport-mode',
  SOCIAL_CIRCUMSTANCES = 'social-circumstances',
  WELLBEING_SCREEN = 'wellbeing-screen',
  SUMMARY = 'summary',
  REVIEW_AND_SUBMIT = 'review-and-submit',
}

export const routes = {
  malnutritionRisk: {
    sectionTitle: 'Malnutrition Risk',
    path: `/patient/:patientId/nurse/precttriage/:formId/${SECTIONS.MALNUTRITION_RISK}`,
    name: SECTIONS.MALNUTRITION_RISK,
  },
  cognitiveImpairmentRisk: {
    sectionTitle: 'Cognitive Impairment Risk',
    path: `/patient/:patientId/nurse/precttriage/:formId/${SECTIONS.COGNITIVE_IMPAIRMENT_RISK}`,
    name: SECTIONS.COGNITIVE_IMPAIRMENT_RISK,
  },
  pain: {
    sectionTitle: 'Pain',
    path: `/patient/:patientId/nurse/precttriage/:formId/${SECTIONS.PAIN}`,
    name: SECTIONS.PAIN,
  },
  transportMode: {
    sectionTitle: 'Transport Mode',
    path: `/patient/:patientId/nurse/precttriage/:formId/${SECTIONS.TRANSPORT_MODE}`,
    name: SECTIONS.TRANSPORT_MODE,
  },
  socialCircumstances: {
    sectionTitle: 'Social Circumstances',
    path: `/patient/:patientId/nurse/precttriage/:formId/${SECTIONS.SOCIAL_CIRCUMSTANCES}`,
    name: SECTIONS.SOCIAL_CIRCUMSTANCES,
  },
  wellbeingScreen: {
    sectionTitle: 'Wellbeing Screen',
    path: `/patient/:patientId/nurse/precttriage/:formId/${SECTIONS.WELLBEING_SCREEN}`,
    name: SECTIONS.WELLBEING_SCREEN,
  },
  summary: {
    sectionTitle: 'Summary',
    path: `/patient/:patientId/nurse/precttriage/:formId/${SECTIONS.SUMMARY}`,
    name: SECTIONS.SUMMARY,
  },
  reviewAndSubmit: {
    sectionTitle: 'Review & Submit',
    path: `/patient/:patientId/nurse/precttriage/:formId/${SECTIONS.REVIEW_AND_SUBMIT}`,
    name: SECTIONS.REVIEW_AND_SUBMIT,
  },
};
