// eslint-disable-next-line no-use-before-define
import React, { Fragment, useContext, useEffect, useRef, useState } from 'react';
import { useFormikContext } from 'formik';
import classNames from 'classnames';

import ROPatientDiagnosisIntakeHNPage from './HNPage';
import ROPatientDiagnosisIntakeBreastPageRouter from './BreastPages/BreastPageRouter';
import ROPatientDiagnosisIntakeLungPage from './LungPage';
import ROPatientDiagnosisIntakeUrogenitalPage from './UrogenitalPage';
import ROPatientDiagnosisIntakeLymphomaPage from './LymphomaPage';
import ROPatientCarePlanPageFooter from '../../Footer';
import { useRouteMatch, useHistory } from 'react-router-dom';
import { DiagnosisRouterParams } from '../Interface';
import { GET_DIAGNOSIS, UPDATE_DIAGNOSIS, LOG_CLINEX_MUTATION } from '../Queries';
import { DateValidationError } from '@mui/x-date-pickers';
import { useQuery, useMutation } from '@apollo/client';
import { CREATE_CAREPLAN, GET_CAREPLAN } from 'op-pages/RO/Careplan/Queries';
import { Logger, logPage } from 'shared-components/utils';
import ROPatientCarePlanContext from 'op-pages/RO/Careplan/ROPatientCarePlanContext';
import { CurrentAppConfig } from 'op-pages/RO/Careplan/AppConfig';
import { LoadingSpinner } from 'shared-components/components';
import { useWindowSize } from 'shared-components/utils/CustomHooks';
import CareplanContainer from '../../Container';
import { ClinexContext } from '../Clinex/context';
import {
  PLEASE_REVIEW_CLINEX,
  breastIntakeClinexMappings,
  urogenitalClinexMappings,
  lungIntakeClinexMappings,
  selectFirstHighlight,
} from '../Clinex/helpers';
import { GET_DIAGNOSIS_INTAKE, UPDATE_DIAGNOSIS_INTAKE } from './Queries';
import { CareplanBanners } from '../../Banner/Banner';
import { CareplanFormikValues } from '../../Interface';
import { logMissingDiagnosisCode } from '../../Common';
import { Typography } from '@mui/material';

const logger = new Logger('ROPatientDiagnosisIntakePage');

export const intakePageMapping = {
  'H&N': {
    createVariable: { headAndNeck: { p16: '' } },
    key: 'headAndNeck',
    component: ROPatientDiagnosisIntakeHNPage,
  },
  Breast: {
    createVariable: { breast: { grade: '' } },
    key: 'breast',
    component: ROPatientDiagnosisIntakeBreastPageRouter,
    clinexMappings: breastIntakeClinexMappings,
  },
  Lung: {
    createVariable: { lung: { weightLoss: '' } },
    key: 'lung',
    component: ROPatientDiagnosisIntakeLungPage,
    clinexMappings: lungIntakeClinexMappings,
  },
  Lymphoma: {
    createVariable: { lymphoma: { stage: '' } },
    key: 'lymphoma',
    component: ROPatientDiagnosisIntakeLymphomaPage,
  },
  Urogenital: {
    createVariable: { urogenital: { isupGrade: '' } },
    key: 'urogenital',
    component: ROPatientDiagnosisIntakeUrogenitalPage,
    clinexMappings: urogenitalClinexMappings,
  },
};

const ROPatientDiagnosisIntakePage = (): JSX.Element => {
  const history = useHistory();
  const match = useRouteMatch<DiagnosisRouterParams>();
  // const [intake, setIntake] = useState<any>();
  const { state } = useContext(ROPatientCarePlanContext);
  const { diagnosisId, id: patientId, careplanId, oncologyType } = match.params;
  const [createCareplan] = useMutation(CREATE_CAREPLAN);
  const [updateDiagnosis] = useMutation(UPDATE_DIAGNOSIS);
  const clinexContexts = useContext(ClinexContext);
  const { clinexData, uuid, setIntakeFieldsLoaded } = clinexContexts;
  const startTime = useRef<number>(new Date().getTime());
  const windowSize = useWindowSize();
  const isSmallDevice = windowSize.width < 1900;
  const [logEvent] = useMutation(LOG_CLINEX_MUTATION);
  const { data: careplan, loading: careplanLoading } = useQuery(GET_CAREPLAN, {
    fetchPolicy: 'network-only',
    variables: { id: careplanId },
  });
  const { values, setFieldValue, setFieldTouched, validateForm } = useFormikContext<CareplanFormikValues>();
  const { loading: intakeDataLoading } = useQuery(GET_DIAGNOSIS_INTAKE, {
    variables: { diagnosisId },
    fetchPolicy: 'cache-and-network',
  });
  const [updateIntake] = useMutation(UPDATE_DIAGNOSIS_INTAKE);
  const { loading: dxLoading } = useQuery(GET_DIAGNOSIS, {
    variables: { diagnosisId },
    onCompleted: (data) => {
      logMissingDiagnosisCode(logger, data, 'Intake Information');
    },
  });

  const intake = values?.intake;
  const intakePage = values?.intakePage as keyof typeof intakePageMapping;
  const [dateError, setDateError] = useState<DateValidationError | null>(null);

  const logClinex = () => {
    //@ts-ignore
    if (intakePage && intake[intakePageMapping[intakePage]?.key]) {
      //@ts-ignore
      const intakeCopy = (({ __typename, id, ...o }) => o)(intake[`${intakePageMapping[intakePage].key}`]);
      const pageData = {
        fields: intakeCopy,
        patientId,
        diagnosisId,
        page: 'intake',
        intakeType: intakePageMapping[intakePage].key,
        careplanId,
        clinexResponseUuid: uuid,
      };
      logPage(startTime?.current, pageData, logEvent);
    }
  };

  useEffect(() => {
    //@ts-ignore
    if (!isSmallDevice && values?.intake?.id && intakePageMapping[intakePage]?.clinexMappings) {
      //@ts-ignore
      selectFirstHighlight(intakePageMapping[intakePage].clinexMappings, clinexContexts);
    }
    //@ts-ignore
  }, [clinexData, intakePage, values?.intake?.id]);

  if (!intakePage || (dxLoading && !values?.diagnosis) || (intakeDataLoading && !values.intake) || careplanLoading)
    return (
      <LoadingSpinner loadingText={'Loading Intake Info'} subtitle={'Please wait while we set things up for you'} />
    );
  let footerOptions = {};

  if (CurrentAppConfig.SubmitDiagnosisOnlyAndExit) {
    footerOptions = {
      additionLeftText: 'Submit diagnosis only and exit',
      additionLeftDisabled: values?.user?.isShowcaseUser,
      onAdditionLeft: (): void => {
        updateDiagnosis({ variables: { id: diagnosisId, draft: false, isSubmitDiagnosisOnly: true } }).then(
          (): void => {
            history.push(`/${oncologyType}/patient/${patientId}/summary`);
          },
          (error): void => {
            logger.error(error);
          },
        );
      },
    };
  }

  //@ts-ignore
  if (intakePage && values?.intake && values?.intake[intakePageMapping[intakePage]?.key]) {
    setIntakeFieldsLoaded(true);
    const FormComponent = intakePageMapping[intakePage]?.component;
    const currentPageKey = intakePageMapping[intakePage]?.key;
    //@ts-ignore
    const currentIntakeCopy = (({ __typename, id, ...o }) => o)(values.intake[currentPageKey]);
    const updateIntakeHandler = (changedValues: { [key: string]: string | string[] | number | null }): void => {
      const fieldKeys = Object.keys(changedValues);
      fieldKeys.forEach((field) =>
        setFieldValue(`intake.${[intakePageMapping[intakePage].key]}.${field}`, changedValues[field]),
      );

      updateIntake({
        variables: {
          diagnosisId,
          intakeData: {
            [`${currentPageKey}`]: {
              ...currentIntakeCopy,
              ...changedValues,
            },
          },
        },
      });
    };

    return (
      <div
        className={classNames('main-container-wrapper', {
          'clinex-wrapper': clinexData && !isSmallDevice,
        })}>
        <CareplanBanners data={careplan} />
        <CareplanContainer className={'ro-intake-page'}>
          <div className="page-header">
            <Typography variant="h5">Intake Info</Typography>
            {clinexData && PLEASE_REVIEW_CLINEX}
          </div>
          <FormComponent
            updateIntakeHandler={updateIntakeHandler}
            setDateError={setDateError}
            featureVersion={careplan?.careplan?.featureVersion}
          />
        </CareplanContainer>
        <ROPatientCarePlanPageFooter
          {...footerOptions}
          onBack={(): void => {
            history.push(
              `/${oncologyType}/patient/${patientId}/careplan/${careplanId}/diagnosis/${diagnosisId}/tnmstaging`,
            );
          }}
          onNext={(): void => {
            logClinex();
            validateForm().then((errors: any) => {
              const intakePageFields = Object.keys(currentIntakeCopy);
              intakePageFields.forEach((field: string) => setFieldTouched(field));
              if (Object.keys(errors).length > 0 || dateError) return;
              if (careplanId === 'create' && !state.careplan.id) {
                createCareplan({ variables: { patientId, diagnosisId } }).then((result): void => {
                  const newCareplanId = result.data.createCareplan.careplan.id;
                  history.push(`/${oncologyType}/patient/${patientId}/careplan/${newCareplanId}/treatmentInfo`);
                });
              } else {
                history.push(`/${oncologyType}/patient/${patientId}/careplan/${state.careplan.id}/treatmentInfo`);
              }
            });
          }}
        />
      </div>
    );
  } else return <Fragment />;
};

export default ROPatientDiagnosisIntakePage;
