import * as Yup from 'yup';
import { NOT_VALID } from './constants';
import { VALUE_REQUIRED } from '../Helper';
import { US_SSN_REGEX } from 'shared-components/utils/Regex';
import { FormikValues, validateYupSchema, yupToFormErrors } from 'formik';

export const generateValidationSchema = (values: FormikValues): any => {
  const registrationBasicSchema = {
    firstName: Yup.string().ensure().required(VALUE_REQUIRED),
    lastName: Yup.string().ensure().required(VALUE_REQUIRED),
    gender: Yup.string().ensure().required(VALUE_REQUIRED),
    socialSecurityNumber: Yup.string().matches(US_SSN_REGEX, NOT_VALID),
  };

  try {
    validateYupSchema<FormikValues>(values, Yup.object(registrationBasicSchema), true);
  } catch (err) {
    return yupToFormErrors(err);
  }

  return {};
};
