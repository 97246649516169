// eslint-disable-next-line no-use-before-define
import { Field, Form, Formik, FormikProps, FormikValues } from 'formik';
import moment from 'moment';
import { FormContainer } from 'op-components';
import { RegistrationContext } from 'op-contexts/index';
import { mapListData } from 'op-utils';
import React, { useContext, useState } from 'react';
import { generatePath } from 'react-router';
import { useHistory, useParams } from 'react-router-dom';
import { FormRow, HelperMessage } from 'shared-components/components';
import { RODatePicker } from 'shared-components/components/FormFields';
import { MaskField } from 'shared-components/components/FormikComponents';
import { ListData } from 'shared-components/interfaces';
import {
  CONTINUE_BUTTON_TEXT,
  getDisplayValue,
  HeaderSubTitle,
  HeaderTitle,
  PSO_SUMMARY_LINK,
  registrationPath,
  RETURN_TO_SUBMIT_TEXT,
  sharedFormContainerProps,
  standardField,
  VALUE_REQUIRED,
} from '../Helper';
import { PatientBasicRego, RegistrationFormUSIProps } from '../interfaces';
import { BASIC_FIELDS } from './constants';
import { generateValidationSchema } from './validation';

interface Props extends RegistrationFormUSIProps {
  patient: PatientBasicRego;
  readonly refData: {
    readonly genderRefData: ListData[];
    readonly titleReferenceData: ListData[];
  };
}

const BasicForm = (props: Props): JSX.Element => {
  const {
    updateField,
    patient: savedPatient,
    handleShowSaveExitConfirm,
    isPso,
    refData: { titleReferenceData, genderRefData },
  } = props;
  const registrationContext = useContext(RegistrationContext);

  const patient = {
    ...savedPatient,
    namePrefix: getDisplayValue(titleReferenceData, savedPatient.namePrefix),
    gender: getDisplayValue(genderRefData, savedPatient.gender),
  };
  const goToSummary = registrationContext?.registrationSummaryVisited && !isPso;

  const [dobChanged, setDobChanged] = useState(false); // dont run manual validation until date is changed

  const history = useHistory();
  const { patientId } = useParams<{ patientId: string }>();
  const referringPage = sessionStorage.getItem('referringPage');

  return (
    <Formik
      initialValues={patient}
      validate={(values: FormikValues) => generateValidationSchema(values)}
      validateOnChange={false}
      validateOnBlur={true}
      onSubmit={() => history.push(registrationPath(patient?.id, goToSummary ? 'summary' : 'contact'))}>
      {({ errors, submitForm, values }: FormikProps<PatientBasicRego>) => {
        const sharedProps = sharedFormContainerProps('Basic Details', undefined, '', !isPso);
        const formContainerProps = {
          ...sharedProps,
          submitButtonText: goToSummary ? RETURN_TO_SUBMIT_TEXT : CONTINUE_BUTTON_TEXT,
          continueDisabled: Object.keys(errors).length > 0 || !patient?.dob,
          handleShowSaveExitConfirm,
          saveAndExitLink: isPso && generatePath(referringPage || PSO_SUMMARY_LINK, { patientId }),
        };

        return (
          <FormContainer
            {...formContainerProps}
            submitForm={submitForm}
            handleValidation={(event: React.MouseEvent<HTMLInputElement>): void => {
              void event;
            }}>
            <Form>
              <HeaderTitle data-test-id="us-rego-page-title">{BASIC_FIELDS.FORM_HEADING.TITLE}</HeaderTitle>
              <HeaderSubTitle data-test-id="us-rego-page-subtitle">* = required field</HeaderSubTitle>
              {standardField(BASIC_FIELDS.TITLE_PREFIX, updateField, mapListData(titleReferenceData))}
              {standardField(BASIC_FIELDS.FIRST_NAME, updateField)}
              {standardField(BASIC_FIELDS.MIDDLE_NAME, updateField)}
              {standardField(BASIC_FIELDS.LAST_NAME, updateField)}
              {standardField(BASIC_FIELDS.PREFERRED_NAME, updateField)}
              {standardField(BASIC_FIELDS.GENDER, updateField, mapListData(genderRefData))}

              <FormRow
                fieldLabel={BASIC_FIELDS.DOB_RAW.TITLE}
                fieldName={BASIC_FIELDS.DOB_RAW.NAME}
                className={dobChanged && values.dob === null ? 'registration-date-error' : 'registration-date'}
                labelClass={'label-form-row'}>
                <Field
                  name={BASIC_FIELDS.DOB_RAW.NAME}
                  component={RODatePicker}
                  alternateStyle
                  isUs={true}
                  id={BASIC_FIELDS.DOB_RAW.NAME}
                  value={patient.dob ? moment(patient.dob).toDate() : null}
                  disableFuture
                  onChange={(date: Date | null): void => {
                    const newDate = date ? moment(date).format('YYYY-MM-DD') : '';
                    setDobChanged(newDate ? false : true);
                    updateField(BASIC_FIELDS.DOB_RAW.NAME, newDate);
                  }}
                  isManualEditEnabled
                />
                {dobChanged && values.dob === null && (
                  <HelperMessage
                    fieldName={BASIC_FIELDS.DOB_RAW.NAME}
                    fieldText={VALUE_REQUIRED}
                    helperType={'error'}
                    fontSize={14}
                  />
                )}
              </FormRow>

              <FormRow
                fieldLabel={BASIC_FIELDS.SSN.TITLE}
                fieldName={BASIC_FIELDS.SSN.NAME}
                labelClass={'label-form-row'}>
                <Field
                  name={BASIC_FIELDS.SSN.NAME}
                  component={MaskField}
                  defaultValue={patient.socialSecurityNumber}
                  inputType="text"
                  updateMutation={(value: string) => updateField(BASIC_FIELDS.SSN.NAME, value)}
                  mask="999 - 99 - 9999"
                  maskChar="X"
                />
              </FormRow>
            </Form>
          </FormContainer>
        );
      }}
    </Formik>
  );
};

export default BasicForm;
