import { gql } from '@apollo/client';

import * as fragments from 'op-graphql/fragments';

import {
  PCCC_ALLERGY_FRAGMENT,
  PCCC_IMPLANTED_DEVICE_FRAGMENT,
  PCCC_INFECTIOUS_DISEASE_FRAGMENT,
  PCCC_MRO_INFECTION_FRAGMENT,
} from 'op-graphql/fragments';

export const HEALTH_ASSESSMENT_QUERY = gql`
  query HealthAssessment($id: ID!, $status: String!) {
    healthAssessment(id: $id, status: $status) {
      id
      status
      accommodationAssistance
      alcoholBool
      alcoholFrequency
      inPain
      inPainReason
      smokingBool
      smokingFrequency
      smokingHistory
      smokingStopDate
      smokingStopRawMonth
      smokingStopRawYear
      transportationAssistance
      transportationAssistanceReason
      wellbeingIssue
      wellbeingIssues {
        id
        ...HealthAssessmentWellbeingIssue
      }
      diabetes
      diabetesResponses {
        id
        ...HealthAssessmentDiabetesResponse
      }
      activeInjury
      activeInjuryReason
    }
    diabetesRefData: listData(category: "diabetes") {
      id
      name
    }
    wellbeingIssueRefData: listData(category: "wellbeingIssues") {
      id
      name
    }
    alcoholFrequencyRefData: listData(category: "alcohol_frequency") {
      id
      name
    }
    smokingFrequencyRefData: listData(category: "smoking_frequency") {
      id
      name
    }
  }
  ${fragments.HEALTH_ASSESSMENT_DIABETES_RESPONSE_FRAGMENT}
  ${fragments.HEALTH_ASSESSMENT_WELLBEING_ISSUE_FRAGMENT}
`;

export const REGISTRATION_FORM_QUERY = gql`
  query Patient($id: ID!) {
    patient(id: $id) {
      id
      patientState
      ambulanceMembership
      ambulanceMembershipNumber
      regFormStatus
      occupation
      heritage
    }
    heritageRefData: listData(category: "heritage") {
      id
      name
    }
  }
`;

export const DT_RATING_QUERY = gql`
  query DTRating($patientId: ID!, $status: String!, $version: String!) {
    distressThermometer(patientId: $patientId, status: $status, version: $version) {
      id
      distressRating
      version
    }
  }
`;

export const LATEST_SUBMITTED_CHART_CHECK_QUERY = gql`
  query PreCtChartCheck($patientId: ID!) {
    lastSubmittedPreCtChartCheck(patientId: $patientId) {
      id
      isLocked
      nurseLocation
      patientIdentificationCheck
      patientHeight
      patientWeight
      previousRadiationTreatment
      anticancerDrugs
      chemoPastWeek
      hasImplantedMedicalDevice
      treatmentOverHalfHour
      currentInpatient
      requiresMaskCast
      existingPressureInjuryWound
      implantedMedicalDevice {
        id
        ...ImplantedDevice
      }
      hasInfectiousDisease
      infectiousDisease {
        id
        ...InfectiousDisease
      }
      hasMroInfection
      mroInfection {
        id
        ...MroInfection
      }
      outsideWaLastTwelveMonths
      fallLastTwelveMonths
      safetyConcernsMobility
      hasCurrentAcd
      acdInfoRequested
      hasCurrentResusMxPlan
      staffConcern
      anxietyClaustrophobia
      hasPatientAllergies
      pcccAllergy {
        id
        ...Allergy
      }
      additionalNotes
      lastUpdateUser {
        fieldName
        lastUpdateUser
        updatedDate
      }
    }
  }

  ${PCCC_ALLERGY_FRAGMENT}
  ${PCCC_IMPLANTED_DEVICE_FRAGMENT}
  ${PCCC_INFECTIOUS_DISEASE_FRAGMENT}
  ${PCCC_MRO_INFECTION_FRAGMENT}
`;
