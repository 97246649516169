import { Field } from 'formik';
import { InfoCard } from 'op-components';
import { CancerItem, InfoCardRowItem, RadiotherapyCondition } from 'op-interfaces';
import React from 'react';
import { FormRow } from 'shared-components/components';
import { yesNoOptions } from 'op-utils';
import { InputField, SelectFieldBase, ToggleButtonGroupField } from 'shared-components/components/FormikComponents';
import { TREATMENT_TYPES } from '../../../HealthAssessment/HAPages';
import { choiceToBooleanOptions, getLastUpdateUser, transformEmptyString } from '../../helpers';
import { sharedContent } from '../../shared/constants';
import { LastUpdateUserDetails, LocationListData } from '../../shared/interfaces';
import { preCtChartCheckContent, previousRadiationTreatmentTableFields } from '../constants';
import { HealthAssessmentData } from '../interfaces/PreCtChartCheckInterfaces';

interface Props {
  allowedLocations: LocationListData[];
  handleMutation: (name: string, value: string) => void;
  healthAssessmentData: HealthAssessmentData;
  lastUpdateUserData: LastUpdateUserDetails[];
}

const BasicInformation = ({
  allowedLocations,
  handleMutation,
  healthAssessmentData,
  lastUpdateUserData,
}: Props): JSX.Element => {
  const { nurseLocation, patientIdentificationCheck, patientHeight, patientWeight, previousRadiationTreatment } =
    preCtChartCheckContent.basicInformation.fields;
  const { typeOfCondition } = preCtChartCheckContent.basicInformation.tableTitles;

  let hasPreviousCancer;
  let hasPreviousRadiationTreatment;

  if (healthAssessmentData.healthAssessment) {
    //@ts-ignore
    hasPreviousCancer = choiceToBooleanOptions[String(healthAssessmentData.healthAssessment.previousCancer)];
    hasPreviousRadiationTreatment =
      //@ts-ignore
      choiceToBooleanOptions[String(healthAssessmentData.healthAssessment.otherRadiotherapy)];
  }

  const handleTreatmentDateFieldValue = (treatment: any): string => {
    const treatmentRawDay = parseInt(treatment['treatmentRawDay']);
    const treatmentRawMonth = parseInt(treatment['treatmentRawMonth']);
    const treatmentRawYear = parseInt(treatment['treatmentRawYear']);

    let resultValue = '-';

    if (treatmentRawDay || treatmentRawMonth || treatmentRawYear) {
      resultValue =
        `${treatmentRawDay > 0 ? treatmentRawDay.toString().padStart(2, '0') : '--'}/` +
        `${treatmentRawMonth > 0 ? treatmentRawMonth.toString().padStart(2, '0') : '--'}/` +
        `${treatmentRawYear > 0 ? treatmentRawYear.toString().padStart(2, '0') : '----'}`;
    }

    return resultValue;
  };

  let previousRadiationTreatmentTableRows: InfoCardRowItem[][] = [];

  if (hasPreviousCancer) {
    const typeRefData = healthAssessmentData.treatmentTypeRefData;

    const previousCancerWithRT = healthAssessmentData.healthAssessment.cancer.flatMap((cancer: CancerItem) => {
      const treatments = cancer.treatment
        .filter((treatment) => {
          const treatmentTypeMain = typeRefData.find((ref) => ref.id === treatment.treatmentType);
          return treatmentTypeMain && treatmentTypeMain.name === TREATMENT_TYPES.RADIATION_THERAPY;
        })
        .map((treatment) => {
          const condition = { title: typeOfCondition, value: transformEmptyString(cancer.cancerType) };
          const items = previousRadiationTreatmentTableFields
            .slice(1)
            .map((field) => {
              let fieldValue = transformEmptyString(treatment[field.fieldName as keyof typeof treatment]);
              if (field.fieldName === 'treatmentDate') {
                fieldValue = handleTreatmentDateFieldValue(treatment);
              }

              return { title: field.title, value: fieldValue };
            })
            .filter((e) => e.value !== '-');
          items.unshift(condition);

          return items;
        });

      return treatments;
    });

    previousRadiationTreatmentTableRows = previousRadiationTreatmentTableRows.concat(previousCancerWithRT);
  }

  if (hasPreviousRadiationTreatment) {
    const otherRadiotherapyConditions = healthAssessmentData.healthAssessment.otherRadiotherapyConditions.map(
      (condition: RadiotherapyCondition) =>
        previousRadiationTreatmentTableFields
          .map((field: any) => {
            let fieldValue = transformEmptyString(condition[field.fieldName as keyof typeof condition]);
            if (field.fieldName === 'treatmentDate') {
              fieldValue = handleTreatmentDateFieldValue(condition);
            }

            return { title: field.title, value: fieldValue };
          })
          .filter((e) => e.value !== '-'),
    );

    previousRadiationTreatmentTableRows = previousRadiationTreatmentTableRows.concat(otherRadiotherapyConditions);
  }

  const transformedLocationData = allowedLocations.reduce((allLocations: any, location) => {
    if (location.rolesUsedBy.includes('Nurse')) {
      allLocations.push({ label: location.name, value: location.id });
    }
    return allLocations;
  }, []);

  return (
    <>
      <FormRow fieldLabel={nurseLocation} fieldName={'nurseLocation'}>
        <Field
          name="nurseLocation"
          component={SelectFieldBase}
          options={transformedLocationData}
          placeholder={sharedContent.generic.label.selectValue}
          updateMutation={(value: string) => {
            handleMutation('nurseLocation', value);
          }}
          lastUpdateUser={getLastUpdateUser(lastUpdateUserData, 'nurseLocation')}
        />
      </FormRow>
      <FormRow fieldLabel={patientIdentificationCheck} fieldName={'patientIdentificationCheck'}>
        <Field
          name="patientIdentificationCheck"
          component={ToggleButtonGroupField}
          label={patientIdentificationCheck}
          options={yesNoOptions}
          handleMutation={handleMutation}
          lastUpdateUser={getLastUpdateUser(lastUpdateUserData, 'patientIdentificationCheck')}
        />
      </FormRow>
      <FormRow fieldLabel={patientHeight} fieldName={'patientHeight'}>
        <Field
          component={InputField}
          name="patientHeight"
          label={patientHeight}
          units="CM"
          width="72px"
          handleMutation={handleMutation}
          type="number"
          lastUpdateUser={getLastUpdateUser(lastUpdateUserData, 'patientHeight')}
        />
      </FormRow>
      <FormRow fieldLabel={patientWeight} fieldName={'patientWeight'}>
        <Field
          component={InputField}
          name="patientWeight"
          label={patientWeight}
          units="KG"
          width="72px"
          handleMutation={handleMutation}
          type="number"
          lastUpdateUser={getLastUpdateUser(lastUpdateUserData, 'patientWeight')}
        />
      </FormRow>
      <FormRow fieldLabel={previousRadiationTreatment} fieldName={'previousRadiationTreatment'}>
        <Field
          name="previousRadiationTreatment"
          component={ToggleButtonGroupField}
          label={previousRadiationTreatment}
          options={yesNoOptions}
          handleMutation={handleMutation}
          lastUpdateUser={getLastUpdateUser(lastUpdateUserData, 'previousRadiationTreatment')}
        />
      </FormRow>
      {previousRadiationTreatmentTableRows.length > 0 && (
        <InfoCard
          title="Previous Radiation Treatment"
          subTitle="(Patient reported)"
          rowDetails={previousRadiationTreatmentTableRows}
        />
      )}
    </>
  );
};

export default BasicInformation;
