// eslint-disable-next-line no-use-before-define
import React, { useEffect, useCallback, useRef } from 'react';
import { useFormikContext } from 'formik';
import _ from 'lodash';

interface AutosaveProps {
  saveMethod: (values: any) => void;
  debounceMs?: number;
}

const AutoSave = ({ saveMethod = (_: any): void => {}, debounceMs = 1000 }: AutosaveProps): JSX.Element => {
  // saveMethod must be a useCallback function, else the debouncing useEffect below will cause an infinite loop
  const prevFormikValues = useRef<unknown>();
  const formik = useFormikContext();
  const debouncedSubmit = useCallback(
    _.debounce((values: any) => {
      return saveMethod(values);
    }, debounceMs),
    [saveMethod, debounceMs],
  );

  useEffect(() => {
    // Don't call the submit if the values haven't changed or loading for the first time
    if (!prevFormikValues.current) {
      prevFormikValues.current = formik.values;
    }
    if (!_.isEqual(prevFormikValues.current, formik.values)) {
      debouncedSubmit(formik.values);
      prevFormikValues.current = formik.values;
    }
  }, [debouncedSubmit, formik.values]);

  useEffect(() => {
    return () => {
      debouncedSubmit.flush();
    };
  }, [debouncedSubmit]);
  return <></>;
};

export default AutoSave;
