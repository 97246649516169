import { createContext } from 'react';
import { RoCarePlanPages } from 'shared-components/enums';
import './CarePlanPage.scss';

const { DX_LANDING_PG } = RoCarePlanPages;

export interface RoCareplanContextType {
  page: RoCarePlanPages;
  primaryOrMetastasis: string;
  careplanId: string;
  diagnosisId: string;
  setForm: (formName: string, name: string, value: any) => void;
  setPage: (page: RoCarePlanPages) => void;
  setPageState: (field: string, value: any) => void;
  resetForm: () => void;
  activeSiteGroupIdx: number;
  activeSiteIdx: number;
  newDiagnosis: boolean;
  patientId: string;
}

export const INITIAL_RO_CAREPLAN_CONTEXT = {
  page: DX_LANDING_PG,
  primaryOrMetastasis: 'primary',
  careplanId: 'create',
  diagnosisId: '0',
  activeSiteIdx: 0,
  activeSiteGroupIdx: 0,
  setPage: (_page: RoCarePlanPages): void => {},
  setPageState: (_field: string, _value: any): void => {},
  resetForm: (): void => {},
  newDiagnosis: false,
};

const ROCareplanContext = createContext(INITIAL_RO_CAREPLAN_CONTEXT);

export default ROCareplanContext;
