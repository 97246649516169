// eslint-disable-next-line no-use-before-define
import React from 'react';
import { useMutation, useQuery } from '@apollo/client';
import { useParams } from 'react-router-dom';
import { registrationPath } from '../Helper';
import ContactDetailsForm from './ContactDetailsForm';
import { REGISTRATION_CONTACT_DETAILS_QUERY, UPDATE_REGISTRATION_CONTACT_DETAILS } from './ContactDetailsQueries';
import { FormProps } from '../interfaces';

const ContactDetails = (props: FormProps): JSX.Element => {
  const { exitForm } = props;
  const { patientId } = useParams<{ patientId: string }>();
  const [updateRegistration] = useMutation(UPDATE_REGISTRATION_CONTACT_DETAILS);
  const { data, error } = useQuery(REGISTRATION_CONTACT_DETAILS_QUERY, {
    variables: { id: patientId },
  });

  if (error || !data) return <></>;

  const { patient, user } = data;

  const updateField = (field: string, value: string | boolean | number) => {
    updateRegistration({ variables: { id: patientId, [field]: value } });
  };

  return (
    <ContactDetailsForm
      updateField={updateField}
      patient={patient}
      handleShowSaveExitConfirm={() => exitForm(true)}
      previousPageLink={registrationPath(patient.id, 'basic')}
      isPso={user.isPso}
    />
  );
};

export default ContactDetails;
