// eslint-disable-next-line no-use-before-define
import React from 'react';
import { styled } from '@mui/system';
import SuggestedTemplates from './SuggestedTemplates';
import { SigmaTemplate } from '../TemplatePageInterface';
import { Typography, useTheme } from '@mui/material';

const SigmaScoreContainer = styled('div')`
  display: flex;
  align-items: center;
`;

const ProgressBarContainer = styled('div')`
  background-color: ${(props) => props.theme.palette.grey[300]};
  border-radius: 8px;
  height: 8px;
  margin: 0px 12px;
  max-width: 214px;
  width: 100%;
`;

const ProgressBar = styled('div')<any>`
  height: 8px;
  width: ${({ width }: any) => width}%;
  background-color: ${(props) => props.theme.palette.statusColors.simulation};
  border-radius: 8px;
`;

const SUBHEADING =
  'The following careplan templates are suggested by Sigma based on the diagnosis data entered and other past prescription submissions.';

interface SigmaProps {
  templates: SigmaTemplate[];
  selectCareplan: (tumourStream: string, name: string, id: number, empty: string, type: string) => void;
  tumourStream: string;
}

const Sigma = (props: SigmaProps): JSX.Element => {
  const { templates, selectCareplan, tumourStream } = props;
  const theme = useTheme();
  const generateFooter = (template: SigmaTemplate) => {
    return (
      <SigmaScoreContainer>
        <Typography color={theme.palette.text.secondary} variant="body2">
          Sigma score:{' '}
        </Typography>
        <ProgressBarContainer>
          <ProgressBar data-testid={template.id} width={Number(template.score) * 100}></ProgressBar>
        </ProgressBarContainer>
      </SigmaScoreContainer>
    );
  };

  return (
    <SuggestedTemplates
      templates={templates}
      tumourStream={tumourStream}
      onSelect={selectCareplan}
      title={'Suggested Templates'}
      subheading={SUBHEADING}
      generateFooter={generateFooter}
      type={'sigma'}
    />
  );
};
export default Sigma;
