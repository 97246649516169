import { gql } from '@apollo/client';

export const REGISTRATION_ADDRESS_FRAGMENT = gql`
  fragment Address on AddressType {
    id
    formattedAddress
    line1
    line2
    city
    state
    country
    postcode
  }
`;

export const REGISTRATION_INSURANCE_US_FRAGMENT = gql`
  fragment InsuranceUS on InsuranceUsType {
    id
    fundName
    planId
    group
    phoneNumber
    policyHolder
    policyDob
    guarantor
    guarantorRelationship
  }
`;

export const REGISTRATION_DEMOGRAPHICS_FRAGMENT = gql`
  fragment PatientDemographics on PatientType {
    id
    primaryOccupation
    maritalStatus
    maritalStatusValue
    spouseWorkingStatus
    spouseName
    spouseDob
    spouseDobRawYear
    spouseDobRawMonth
    spouseDobRawDay
    formattedSpouseSsn
    spouseSocialSecurityNumber
    spouseContactNumber
    spouseAlternateAddressBool
    spouseAlternateAddress {
      id
      ...Address
    }
    race
    raceSpecified
    ancestry
    languageAtHome
    niceLanguageAtHome
  }
  ${REGISTRATION_ADDRESS_FRAGMENT}
`;

export const LIST_DATA_FRAGMENT = gql`
  fragment ListData on ListDataType {
    id
    name
    orderRank
  }
`;
