// eslint-disable-next-line no-use-before-define
import React, { Component, Fragment } from 'react';
import classNames from 'classnames';

import './FreeTextField.scss';

import { ErrorInfo } from 'shared-components/components/FormFields';
import { Dictionary } from 'shared-components/interfaces';

interface Props {
  inputName: string;
  maxLength?: number;
  placeholder?: string;
  defaultValue?: string;
  onBlur?: (event: React.FocusEvent<HTMLInputElement>) => void;
  onChange?: (event: React.ChangeEvent<HTMLInputElement>) => void;
  onKeyPress?: (event: React.KeyboardEvent<HTMLInputElement>) => void;
  errors?: string[];
  displayInputError?: boolean;
  inputType?: string;
  elementRef?: (ref: HTMLInputElement | null) => void;
  disabled?: boolean;
  autoComplete?: string;
  errorMessages?: { [key: string]: string };
  className?: string;
  sideLabel?: string;
  inputKey?: string;
  dataTestId?: string;
  inputStyle?: React.CSSProperties | undefined;
  showError?: boolean;
  inputProps?: React.InputHTMLAttributes<HTMLInputElement>;
}

class FreeTextField extends Component<Props> {
  public static defaultProps = {
    displayInputError: false,
    disabled: false,
    showError: true,
  };

  public render(): JSX.Element {
    const {
      maxLength,
      errors,
      inputName,
      inputType = 'text',
      displayInputError,
      elementRef,
      onChange,
      autoComplete = 'off',
      errorMessages,
      className,
      sideLabel,
      onKeyPress,
      dataTestId,
      inputStyle,
      showError,
      inputProps,
    } = this.props;

    const customClassName: Dictionary = {};

    if (className) {
      customClassName[className] = className;
    }

    const inputError = (errors && errors.length > 0) || displayInputError;

    return (
      <Fragment>
        <input
          autoComplete={autoComplete}
          name={inputName}
          className={classNames('form-fields-free-text-field', {
            'validation-error': inputError,
            'side-label-field': sideLabel,
          })}
          type={inputType}
          defaultValue={this.props.defaultValue}
          onBlur={this.props.onBlur}
          onChange={onChange}
          onKeyPress={onKeyPress}
          placeholder={this.props.placeholder !== null ? this.props.placeholder : ''}
          maxLength={maxLength}
          ref={elementRef}
          disabled={this.props.disabled}
          key={this.props.inputKey}
          data-test-id={dataTestId}
          style={inputStyle}
          {...inputProps}
        />
        {sideLabel && <label className="side-label">{sideLabel}</label>}
        {showError && <ErrorInfo errors={errors} errorMessages={errorMessages} />}
      </Fragment>
    );
  }
}

export default FreeTextField;
