import { DiagnosisActionType, DiagnosisState, DiagnosisAction, DEFAULT_DIAGNOSIS_TYPE } from './Interface';

export const actions = (state: DiagnosisState, action: DiagnosisAction): DiagnosisState => {
  switch (action.type) {
    case DiagnosisActionType.REPLACE_CURRENT_DIAGNOSIS:
      return {
        ...state,
        diagnosis: {
          ...action.payload.diagnosis,
        },
      };
    case DiagnosisActionType.SET_DIAGNOSIS:
      return {
        ...state,
        diagnosis: action.payload.diagnosis,
      };
    case DiagnosisActionType.SET_INTAKE:
      return {
        ...state,
        intake: action.payload.intake,
      };
    case DiagnosisActionType.SET_NEW_PRIMARY:
      if (!state.diagnosis.isPrimaryDiagnosis) {
        return {
          ...state,
          newPrimary: true,
          diagnosis: {
            ...state.diagnosis,
            relatedPrimaryDiagnosis: {
              ...DEFAULT_DIAGNOSIS_TYPE,
              isPrimaryDiagnosis: true,
            },
          },
        };
      }
      return {
        ...state,
        newPrimary: true,
        diagnosis: {
          ...state.diagnosis,
          id: '',
          diagnosisCode: null,
          stageMCodeClinical: null,
          stageMCodePathological: null,
          stageNCodeClinical: null,
          stageNCodePathological: null,
          stageTCodeClinical: null,
          stageTCodePathological: null,
          diagnosisDate: '',
          laterality: '',
        },
      };
    case DiagnosisActionType.SET_NEW_METASTASIS:
      return {
        ...state,
        newSecondary: true,
        diagnosis: {
          ...state.diagnosis,
          id: '',
          diagnosisCode: null,
          relatedPrimaryDiagnosis: null,
          stageMCodeClinical: null,
          stageMCodePathological: null,
          stageNCodeClinical: null,
          stageNCodePathological: null,
          stageTCodeClinical: null,
          stageTCodePathological: null,
          diagnosisDate: '',
          laterality: '',
        },
      };
    case DiagnosisActionType.CREATE_DIAGNOSIS:
      return {
        ...state,
        creatingDiagnosis: true,
      };
    case DiagnosisActionType.COMPLETED_CREATING_DIAGNOSIS:
      return {
        ...state,
        creatingDiagnosis: false,
        createDiagnosisOnly: false,
      };
    case DiagnosisActionType.CREATE_DIAGNOSIS_ONLY:
      return {
        ...state,
        creatingDiagnosis: true,
        createDiagnosisOnly: true,
      };
    case DiagnosisActionType.RESET:
      return {
        creatingDiagnosis: false,
        createDiagnosisOnly: false,
        newSecondary: false,
        newPrimary: false,
        diagnosis: {
          ajccMajorVersion: '',
          id: '',
          stageType: '',
          stageTCodePathological: null,
          stageMCodePathological: null,
          stageNCodePathological: null,
          stageTCodeClinical: null,
          stageMCodeClinical: null,
          stageNCodeClinical: null,
          morphology: null,
          otherInfo: '',
          laterality: '',
          diagnosisDate: '',
          diagnosisCode: null,
          isPrimaryDiagnosis:
            action.payload.isPrimaryDiagnosis !== undefined ? action.payload.isPrimaryDiagnosis : null,
          relatedPrimaryDiagnosis: null,
          draft: true,
          hasTreatmentStarted: false,
          metastasisLaterality: '',
          metastasisDiagnosisDate: '',
          disease: undefined,
        },
        intake: {
          lung: null,
          urogenital: null,
          breast: null,
          lymphoma: null,
          headAndNeck: null,
        },
      };
    default:
      return state;
  }
};
