// eslint-disable-next-line no-use-before-define
import { useMutation, useQuery } from '@apollo/client';
import { MoreInfoText, PageTitle } from 'op-components';
import { AccordionCheckboxItem } from 'op-pages/OP/HealthAssessment/HASharedComponents';
import { HA_PAIN_QUERY, UPDATE_HA_PAIN } from 'op-pages/OP/HealthAssessment/HASharedComponents/queries';
import { PainQualityObject } from 'op-pages/OP/HealthAssessment/HASharedComponents/types';
import React, { useState } from 'react';

import { useParams } from 'react-router-dom';
import {
  DropDownField,
  FreeTextArea,
  FreeTextField,
  SectionField,
  SegmentedInput,
} from 'shared-components/components/FormFields';
import { ListData as BaseListData } from 'shared-components/interfaces';
import { SegementedInputYesNoUnsure } from 'shared-components/utils';
import { styled } from '@mui/system';
import { PAIN_ASSESSMENT_FIELDS as FIELDS, YES } from '../constants';
import './HAPainAssessment.scss';

const initialViewedFields = [
  'inPain',
  'painStart',
  'painSeverity',
  'painLocation',
  'painLength',
  'painManagement',
  'painBetter',
  'painWorse',
];

interface ListData extends BaseListData {
  key: string;
}

interface Props {
  validateOnLoad: boolean;
}

const StyledQuestionsBackground = styled('div')`
  background-color: ${(props) => props.theme.palette.grey[100]};
  padding: 24px 24px 42px 14px;
`;

const HAMobility = (props: Props): JSX.Element => {
  const { validateOnLoad } = props;
  const { patientId } = useParams<{ patientId: string }>();

  const [viewedFields, setViewedFields] = useState(new Set<string>());

  const { data, error } = useQuery(HA_PAIN_QUERY, {
    variables: { patientId },
    onCompleted: () => {
      if (!validateOnLoad) return; // Only run the below if returning to the page
      const newViewedFields = new Set(initialViewedFields);
      setViewedFields(newViewedFields);
    },
  });
  const [updateHaPain, { loading }] = useMutation(UPDATE_HA_PAIN);

  if (error || !data?.healthAssessment) return <></>;

  const healthAssessment = data.healthAssessment;
  const painQualityOptions = data.painQualityRefData;
  const haId = healthAssessment.id;
  const showPainQuestions = healthAssessment.inPain === YES;

  const painQuality: PainQualityObject = JSON.parse(healthAssessment?.painQuality || '{}');

  const addToViewedFields = (field: string) => {
    const newViewedFields = [...viewedFields];
    newViewedFields.push(field);
    setViewedFields(new Set(newViewedFields));
  };

  const updateField = (field: string, value: string) => {
    updateHaPain({ variables: { haId, patientId, [field]: value } });
    addToViewedFields(field);
  };

  const updatePainQuality = (option: ListData, value: boolean) => {
    // This is a json field, so we have some special json
    //@ts-ignore
    painQuality[option.key] = { id: option.id, value, name: option.name };
    updateField(FIELDS.PAIN_QUALITY.NAME, JSON.stringify(painQuality));
  };

  const painSeverityNumbers = Array.from(Array(11).keys()).map((val: number) => val.toString());
  const painSeverityOptions = painSeverityNumbers.map((severity: string): BaseListData => {
    return { id: severity, name: severity };
  });

  const renderQualityCheckboxes = () =>
    painQualityOptions.map((option: ListData) => (
      <AccordionCheckboxItem
        key={option.key}
        label={option.name}
        disabled={loading}
        //@ts-ignore
        value={painQuality[option.key]?.value}
        onClick={(value: boolean) => updatePainQuality(option, value)}
      />
    ));

  return (
    <>
      <PageTitle title={'Pain assessment'} idPrefix="ha" />
      <div id="ha-fields" className="ha-pain-assessment">
        <SectionField htmlFor={FIELDS.IN_PAIN.NAME} title={FIELDS.IN_PAIN.TITLE}>
          <SegmentedInput
            fieldName={FIELDS.IN_PAIN.NAME}
            options={SegementedInputYesNoUnsure}
            defaultSelected={healthAssessment.inPain}
            itemSelected={(selectedItem): void => {
              const selectedValue = (selectedItem as ListData).id;
              updateField(FIELDS.IN_PAIN.NAME, selectedValue);
            }}
          />
        </SectionField>
        {showPainQuestions && (
          <StyledQuestionsBackground key="pain-questions">
            <SectionField htmlFor={FIELDS.PAIN_START.NAME} title={FIELDS.PAIN_START.TITLE}>
              <MoreInfoText moreInfoText={FIELDS.PAIN_START.MORE_INFO}>
                <FreeTextField
                  inputKey={healthAssessment.painStart}
                  inputName={FIELDS.PAIN_START.NAME}
                  defaultValue={healthAssessment.painStart}
                  onBlur={(e: React.ChangeEvent<HTMLInputElement>): void => {
                    updateField(FIELDS.PAIN_START.NAME, e.target.value);
                  }}
                />
              </MoreInfoText>
            </SectionField>
            <SectionField htmlFor={FIELDS.PAIN_SEVERITY.NAME} title={FIELDS.PAIN_SEVERITY.TITLE}>
              <MoreInfoText moreInfoText={FIELDS.PAIN_SEVERITY.MORE_INFO}>
                <DropDownField
                  inputName={FIELDS.PAIN_SEVERITY.NAME}
                  placeholder={'Please select'}
                  options={painSeverityOptions}
                  defaultValue={healthAssessment.painSeverity}
                  controlled={true}
                  onChange={(e: React.ChangeEvent<HTMLSelectElement>): void => {
                    updateField(FIELDS.PAIN_SEVERITY.NAME, e.target.value);
                  }}
                />
              </MoreInfoText>
            </SectionField>
            <SectionField htmlFor={FIELDS.PAIN_LOCATION.NAME} title={FIELDS.PAIN_LOCATION.TITLE}>
              <MoreInfoText moreInfoText={FIELDS.PAIN_LOCATION.MORE_INFO}>
                <FreeTextArea
                  inputName={FIELDS.PAIN_LOCATION.NAME}
                  defaultValue={healthAssessment.painLocation}
                  onBlur={(e: React.ChangeEvent<HTMLTextAreaElement>): void => {
                    updateField(FIELDS.PAIN_LOCATION.NAME, e.target.value);
                  }}
                />
              </MoreInfoText>
            </SectionField>
            <SectionField htmlFor={FIELDS.PAIN_QUALITY.NAME} title={FIELDS.PAIN_QUALITY.TITLE}>
              {renderQualityCheckboxes()}
            </SectionField>
            <SectionField htmlFor={FIELDS.PAIN_LENGTH.NAME} title={FIELDS.PAIN_LENGTH.TITLE}>
              <FreeTextField
                inputKey={healthAssessment.painLength}
                inputName={FIELDS.PAIN_LENGTH.NAME}
                defaultValue={healthAssessment.painLength}
                onBlur={(e: React.ChangeEvent<HTMLInputElement>): void => {
                  updateField(FIELDS.PAIN_LENGTH.NAME, e.target.value);
                }}
              />
            </SectionField>
            <SectionField htmlFor={FIELDS.PAIN_MANAGEMENT.NAME} title={FIELDS.PAIN_MANAGEMENT.TITLE}>
              <FreeTextField
                inputKey={healthAssessment.painManagement}
                inputName={FIELDS.PAIN_MANAGEMENT.NAME}
                defaultValue={healthAssessment.painManagement}
                onBlur={(e: React.ChangeEvent<HTMLInputElement>): void => {
                  updateField(FIELDS.PAIN_MANAGEMENT.NAME, e.target.value);
                }}
              />
            </SectionField>
            <SectionField htmlFor={FIELDS.PAIN_BETTER.NAME} title={FIELDS.PAIN_BETTER.TITLE}>
              <FreeTextField
                inputKey={healthAssessment.painBetter}
                inputName={FIELDS.PAIN_BETTER.NAME}
                defaultValue={healthAssessment.painBetter}
                onBlur={(e: React.ChangeEvent<HTMLInputElement>): void => {
                  updateField(FIELDS.PAIN_BETTER.NAME, e.target.value);
                }}
              />
            </SectionField>
            <SectionField htmlFor={FIELDS.PAIN_WORSE.NAME} title={FIELDS.PAIN_WORSE.TITLE}>
              <FreeTextField
                inputKey={healthAssessment.painWorse}
                inputName={FIELDS.PAIN_WORSE.NAME}
                defaultValue={healthAssessment.painWorse}
                onBlur={(e: React.ChangeEvent<HTMLInputElement>): void => {
                  updateField(FIELDS.PAIN_WORSE.NAME, e.target.value);
                }}
              />
            </SectionField>
          </StyledQuestionsBackground>
        )}
      </div>
    </>
  );
};

export default HAMobility;
