import { TextAreaField, ToggleButtonGroupField, SelectField } from 'shared-components/components/FormikComponents';
import { addressLabelForRegion } from 'shared-components/enums';

const addressLabels = addressLabelForRegion();

export const FIELDS = {
  FORM_HEADING: {
    NAME: 'formHeading',
    TITLE: 'Insurance & Other Benefits',
  },
  PRIMARY: {
    HEALTH_FUND_NAME: {
      NAME: 'primaryInsurance.fundName',
      TITLE: 'Health fund name',
      PLACE_HOLDER: 'Enter primary health fund name',
      COMPONENT: TextAreaField,
    },
    PLAN_ID: {
      NAME: 'primaryInsurance.planId',
      TITLE: 'Plan ID',
      PLACE_HOLDER: 'Enter plan ID',
      COMPONENT: TextAreaField,
    },
    GROUP: {
      NAME: 'primaryInsurance.group',
      TITLE: 'Group #',
      PLACE_HOLDER: 'Enter group #',
      COMPONENT: TextAreaField,
    },
    PHONE_NUMBER: {
      NAME: 'primaryInsurance.phoneNumber',
      TITLE: 'Phone number',
      PLACE_HOLDER: 'Enter phone number',
      COMPONENT: TextAreaField,
    },
    POLICY_HOLDER: {
      NAME: 'primaryInsurance.policyHolder',
      TITLE: 'Policyholder',
      PLACE_HOLDER: 'Enter policyholder name',
      COMPONENT: TextAreaField,
    },
    HOLDER_DOB: {
      NAME: 'primaryInsurance.policyDob',
      TITLE: 'Policyholder date of birth',
    },
    GUARANTOR: {
      NAME: 'primaryInsurance.guarantor',
      TITLE: 'Guarantor',
      PLACE_HOLDER: 'Enter guarantor name',
      COMPONENT: TextAreaField,
    },
    GUARANTOR_RELATION: {
      NAME: 'primaryInsurance.guarantorRelationship',
      TITLE: 'Guarantor relationship',
      PLACE_HOLDER: 'Start typing or select from dropdown',
      COMPONENT: SelectField,
    },
  },
  HAS_SECONDARY_INSURANCE: {
    NAME: 'hasSecondaryInsurance',
    TITLE: 'Do you have a secondary insurance?',
    COMPONENT: ToggleButtonGroupField,
  },
  SECONDARY: {
    HEALTH_FUND_NAME: {
      NAME: 'secondaryInsurance.fundName',
      TITLE: 'Health fund name',
      PLACE_HOLDER: 'Enter secondary health fund name',
      COMPONENT: TextAreaField,
    },
    PLAN_ID: {
      NAME: 'secondaryInsurance.planId',
      TITLE: 'Plan ID',
      PLACE_HOLDER: 'Enter plan ID',
      COMPONENT: TextAreaField,
    },
    GROUP: {
      NAME: 'secondaryInsurance.group',
      TITLE: 'Group #',
      PLACE_HOLDER: 'Enter group #',
      COMPONENT: TextAreaField,
    },
    PHONE_NUMBER: {
      NAME: 'secondaryInsurance.phoneNumber',
      TITLE: 'Phone number',
      PLACE_HOLDER: 'Enter phone number',
      COMPONENT: TextAreaField,
    },
    POLICY_HOLDER: {
      NAME: 'secondaryInsurance.policyHolder',
      TITLE: 'Policyholder',
      PLACE_HOLDER: 'Enter policyholder name',
      COMPONENT: TextAreaField,
    },
    HOLDER_DOB: {
      NAME: 'secondaryInsurance.policyDob',
      TITLE: 'Policyholder date of birth',
    },
    GUARANTOR: {
      NAME: 'secondaryInsurance.guarantor',
      TITLE: 'Guarantor',
      PLACE_HOLDER: 'Enter guarantor name',
      COMPONENT: TextAreaField,
    },
    GUARANTOR_RELATION: {
      NAME: 'secondaryInsurance.guarantorRelationship',
      TITLE: 'Guarantor relationship',
      PLACE_HOLDER: 'Start typing or select from dropdown',
      COMPONENT: SelectField,
    },
  },
  OTHER_BENEFITS: {
    NAME: 'receiveVeteransAdminBenefits',
    TITLE: 'Are you receiving benefits from the Veterans Administration?',
    COMPONENT: ToggleButtonGroupField,
  },

  VETERANS_ADMIN_NAME: {
    NAME: 'veteransAdminName',
    TITLE: 'Veterans Administration name',
    PLACE_HOLDER: 'Enter Veterans Administration name',
    COMPONENT: TextAreaField,
  },
  VETERANS_CONTACT_NUMBER: {
    NAME: 'veteransContactNumber',
    TITLE: 'Contact number',
    PLACE_HOLDER: 'Enter contact number',
    COMPONENT: TextAreaField,
  },
  VETERANS_CITY: {
    NAME: 'veteransCity',
    TITLE: addressLabels.city,
    PLACE_HOLDER: `Enter ${addressLabels.city.toLowerCase()}`,
    COMPONENT: TextAreaField,
  },
  VETERANS_STATE_PROVINCE: {
    NAME: 'veteransStateProvince',
    TITLE: addressLabels.state,
    PLACE_HOLDER: `Enter ${addressLabels.state.toLowerCase()}`,
    COMPONENT: TextAreaField,
  },

  VETERANS_ZIP: {
    NAME: 'veteransZip',
    TITLE: addressLabels.postcode,
    PLACE_HOLDER: `Enter ${addressLabels.postcode.toLowerCase()}`,
    COMPONENT: TextAreaField,
  },
};
