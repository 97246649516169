import React, { PropsWithChildren, useState } from 'react';

import { NavigationContext } from 'op-contexts';

const NavigationContextProvider = (props: PropsWithChildren): JSX.Element => {
  const [regEntryPath, setRegEntryPath] = useState('');
  const [searchTerm, setSearchTerm] = useState('');
  const [locationFilter, setLocationFilter] = useState('');
  const [regStatusFilter, setRegStatusFilter] = useState('');
  const [tagFilter, setTagFilter] = useState('');

  return (
    <NavigationContext.Provider
      value={{
        state: { regEntryPath, searchTerm, locationFilter, regStatusFilter, tagFilter },
        setRegEntryPath,
        setSearchTerm,
        setLocationFilter,
        setRegStatusFilter,
        setTagFilter,
      }}>
      {props.children}
    </NavigationContext.Provider>
  );
};

export default NavigationContextProvider;
