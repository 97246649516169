const sortFunctions: any = {
  days: (a: string, b: string) => a.localeCompare(b),
  weeks: (a: string, b: string) => parseInt(a.split('_')[1]) - parseInt(b.split('_')[1]),
  months: (a: string, b: string) => new Date(a).getTime() - new Date(b).getTime(),
};

const getWeekNumber = (date: Date) => {
  const firstDayOfYear = new Date(date.getFullYear(), 0, 1);
  return Math.ceil(((date.getTime() - firstDayOfYear.getTime()) / 86400000 + firstDayOfYear.getDay() + 1) / 7);
};

const findParentById = (element: HTMLElement, id: string) => {
  let currentElement = element;
  while (currentElement !== null && currentElement !== document.body) {
    if (currentElement.id === id) {
      return currentElement;
    }
    currentElement = currentElement.parentElement!;
  }
  return null;
};

export { sortFunctions, getWeekNumber, findParentById };
