import React, { useContext } from 'react';
import { StandardDialog } from 'shared-components/components';
import { Typography } from '@mui/material';
import { FractionDoseModalInterface } from './Interface';
import { WarningOutlined as WarningOutlinedIcon } from '@mui/icons-material';
import { TenantContext } from 'op-contexts';
import { DoseUnit } from 'op-enums';

const FractionDoseWarningModal: React.FC<FractionDoseModalInterface> = ({ isOpen, handleClose, handleContinue }) => {
  const { doseUnit } = useContext(TenantContext);
  const value = doseUnit === DoseUnit.CGY ? '500' : '5.0';
  return (
    <StandardDialog
      id="outcome-modal"
      open={isOpen}
      title="Dose per fraction"
      titleIcon={<WarningOutlinedIcon color="warning" />}
      onSubmit={handleContinue}
      onClose={handleClose}
      closeText="Adjust"
      submitText="Confirm">
      <Typography>
        {`The daily dose per fraction is >${value}${doseUnit}. Please confirm or adjust the value before continuing.`}
      </Typography>
    </StandardDialog>
  );
};

export default FractionDoseWarningModal;
