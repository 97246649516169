import { gql } from '@apollo/client';
import { UNSEEN_POPUP_NOTIFICATIONS_FRAGMENT } from 'op-graphql/queries/Notification';

export const PATIENT_AND_USER_DETAILS_QUERY = gql`
  query PatientAndUserDetails($id: ID!) {
    patient(id: $id) {
      id
      lock {
        lockedBy
        readOnly
        lockedByName
      }
      address {
        id
        formattedAddress
      }
      firstName
      lastName
      ida
      gender
      dob
      gpFirstName
      gpLastName
      gpPractice
      gpIsInMosaiq
      referrersOtherSpecialists
      referringFirstName
      referringLastName
      referringPractice
      referringIsInMosaiq
      regFormStatus
      lastVisitedSection
      regFormStatus
    }
    genderRefData: listData(category: "gender") {
      id
      name
    }
    user {
      id
      isPso
    }
    profile(id: $id) {
      id
      patientFeatures(id: $id) {
        doctorReferralLookupEnabled
      }
    }
    unseenPopups {
      id
      ...UnseenPopups
    }
  }

  ${UNSEEN_POPUP_NOTIFICATIONS_FRAGMENT}
`;
