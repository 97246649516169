import React, { useRef, useEffect } from 'react';

interface Props {
  clickOutside: () => void;
  children: React.ReactNode;
}

/**
 * Hook that alerts clicks outside of the passed ref
 */
function useOutsideAlerter(ref: React.MutableRefObject<null>, clickOutside: () => void) {
  useEffect(() => {
    function handleClickOutside(event: MouseEvent) {
      //@ts-ignore
      if (ref.current && !ref.current.contains(event.target)) {
        clickOutside();
      }
    }
    // Bind the event listener
    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      // Unbind the event listener on clean up
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, [ref]);
}

/**
 * Component that alerts if you click outside of it
 */
export default function NavigationOutsideAlerter(props: Props) {
  const wrapperRef = useRef(null);
  useOutsideAlerter(wrapperRef, props.clickOutside);

  return <div ref={wrapperRef}>{props.children}</div>;
}
