import React from 'react';

import SideTextArea from 'shared-components/components/UIFormComponents/SideTextArea';

interface Props {
  onChange: (value: React.ChangeEvent<HTMLInputElement>) => void;
  onBlur: (value: React.ChangeEvent<HTMLInputElement>) => void;
  value: string;
  name: string;
  title: string;
  helperText: string;
  disabled: boolean;
}

const SideTextAreaField = ({ name, onChange, onBlur, value, title, helperText, disabled }: Props): JSX.Element => {
  return (
    <SideTextArea
      name={name}
      value={value}
      onBlur={onBlur}
      onChange={onChange}
      title={title}
      helperText={helperText}
      disabled={disabled}
    />
  );
};

export default SideTextAreaField;
