import React, { useEffect, useState } from 'react';
import { FormikProps, useFormikContext } from 'formik';
import { TabSelectorCategories, TabSelectorValues } from 'shared-components/components/TabSelector/TabSelector';

import { LIST_TUMOUR_STREAMS } from 'op-pages/RO/Careplan/TemplatePage/TemplatePageQueries';
import { useMutation, useQuery } from '@apollo/client';
import {
  GET_CAREPLAN_ADDITIONAL_INFO,
  GET_MO_TEMPLATES,
  UPDATE_MO_CAREPLAN_PROTOCOL,
  IS_CAREPLAN_APPROVED_ATLEAST_ONCE,
} from './Queries';
import { LoadingSpinner } from 'shared-components/components';
import { TumourStreamContainer, ProtocolContainer, FixedGrid, FixedGridItem } from './StyledComponents';
import { ProtocolTemplateInterface } from '../../interfaces';
import { TabSelectorValueInterface } from 'shared-components/components/TabSelector/Interfaces';
import ROPatientCarePlanPageFooter from 'op-pages/RO/Careplan/Footer';
import { useHistory, useRouteMatch } from 'react-router';
import CareplanContainer from 'op-pages/RO/Careplan/Container';
import { CAREPLAN_PAGE } from '../Constants';
import { PRESCRIPTION_DATA_QUERY } from '../PrescriptionPage/Queries';
import ProtocolSummaryTable from './ProtocolSummaryTable';
import ProtocolCyclesErrorModal from './ProtocolCyclesErrorModal';
import ProtocolSelectionTabTitle, { StartingCycleInterface } from './ProtocolSelectionTabTitle';
import { CurrentAppConfig } from 'op-pages/RO/Careplan/AppConfig';

const ProtocolSelectionPage = (): JSX.Element => {
  const { data: tumourStreamData } = useQuery(LIST_TUMOUR_STREAMS);
  const { values: formikValues }: FormikProps<any> = useFormikContext();
  const [selectedTumourStream, setSelectedTumourStream] = useState('');
  const [protocolsForTabSelector, setProtocolsForTabSelector] = useState<TabSelectorValueInterface[]>([]);

  const [selectedProtocol, setSelectedProtocol] = useState('');
  const history = useHistory();
  const match = useRouteMatch<{ id: string; careplanId: string }>();
  const { id: patientId, careplanId } = match.params;

  // Get a tumourStream from the diagnosis to determine what is initially selected.
  // If metastasis, use the related primary diagnosis
  const defaultTumourStream = formikValues?.diagnosis?.isPrimaryDiagnosis
    ? formikValues?.diagnosis?.diagnosisCode?.tumourStream?.name
    : formikValues?.diagnosis?.relatedPrimaryDiagnosis?.diagnosisCode?.tumourStream?.name;

  const { data: protocolData, loading } = useQuery(GET_MO_TEMPLATES, {
    variables: { tumourStream: selectedTumourStream || defaultTumourStream },
    skip: !formikValues?.diagnosis?.diagnosisCode,
  });
  const { data: careplanData } = useQuery(GET_CAREPLAN_ADDITIONAL_INFO, { variables: { careplanId: careplanId } });
  const { data: cyclesData } = useQuery(IS_CAREPLAN_APPROVED_ATLEAST_ONCE, {
    variables: { careplanId: careplanId },
    fetchPolicy: 'network-only',
  });
  const [updateCareplanProtocol] = useMutation(UPDATE_MO_CAREPLAN_PROTOCOL, {
    awaitRefetchQueries: true,
    refetchQueries: [{ query: PRESCRIPTION_DATA_QUERY, variables: { careplanId } }],
  });
  const [protocolCyclesErrorProps, setProtocolCyclesErrorProps] = useState<{
    startingCycle: number;
    maxCycles: number;
    isOpen: boolean;
  }>({ startingCycle: 1, maxCycles: 1, isOpen: false });
  const startingCycleRef = React.useRef<FormikProps<StartingCycleInterface>>(null);

  useEffect(() => {
    if (!protocolData || !careplanData) return;
    setProtocolsForTabSelector(
      protocolData.moTemplateList.templates.map((p: ProtocolTemplateInterface) => {
        return {
          id: p.id,
          category: p.tumourStream,
          label: p.name,
          expandedContent: ProtocolSummaryTable({ protocol: p, drugOrders: protocolData.moTemplateList.drugOrders }),
          TitleComponent: (props: { selectedValue: string }) => (
            <ProtocolSelectionTabTitle
              selectedValue={props.selectedValue}
              startingCycleRef={startingCycleRef}
              protocol={p}
              startingCycle={
                careplanData.moCareplanAdditionalInfo &&
                p.id === careplanData.moCareplanAdditionalInfo.protocolId.toString()
                  ? careplanData.moCareplanAdditionalInfo.startingCycle + 1
                  : 1
              }
            />
          ),
        };
      }),
    );
  }, [protocolData, careplanData]);

  useEffect(() => {
    if (careplanData?.moCareplanAdditionalInfo) {
      setSelectedProtocol(careplanData.moCareplanAdditionalInfo.protocolId.toString());
    }
  }, [careplanData]);

  if (!tumourStreamData || !protocolData) return <></>;
  const UpdateProtocolCallback = (startingCycle: number) => {
    updateCareplanProtocol({
      variables: {
        careplanId: careplanId,
        protocolId: selectedProtocol,
        startingCycle: startingCycle - 1,
      },
    }).then(() => {
      history.push(
        `/medonc/patient/${patientId}/careplan/${careplanId}/${CAREPLAN_PAGE.PRESCRIPTION}/${startingCycle - 1}`,
      );
    });
  };
  return (
    <div className="main-container-parent-wrapper">
      <div className="main-container-wrapper">
        <CareplanContainer>
          <div className="page-header">
            <h1>{'Careplan Selection'}</h1>
          </div>
          <FixedGrid container spacing={2}>
            <FixedGridItem item xs={12} md={3}>
              <TumourStreamContainer data-testid="tumourstream">
                <TabSelectorCategories
                  categories={tumourStreamData.tumourStreamList}
                  selectedCategory={selectedTumourStream || defaultTumourStream}
                  setSelectedCategory={setSelectedTumourStream}
                  filterPlaceholder={`${CurrentAppConfig.DefaultValue.tumourName} Stream Filter`}
                />
              </TumourStreamContainer>
            </FixedGridItem>
            <FixedGridItem item xs={12} md={9}>
              <ProtocolContainer>
                {loading ? (
                  <LoadingSpinner />
                ) : (
                  <TabSelectorValues
                    values={protocolsForTabSelector}
                    selectedCategory={selectedTumourStream || defaultTumourStream}
                    selectedValue={selectedProtocol}
                    setSelectedValue={setSelectedProtocol}
                    filterPlaceholder={'Search protocol or drug name'}
                    noResultsMessage={`No Templates found for this ${CurrentAppConfig.DefaultValue.tumourName} stream`}
                    hasExpandedContent
                    data-testid="protocol-container"
                  />
                )}
              </ProtocolContainer>
            </FixedGridItem>
          </FixedGrid>
          <ProtocolCyclesErrorModal
            {...protocolCyclesErrorProps}
            onCancel={() => {
              setProtocolCyclesErrorProps({ ...protocolCyclesErrorProps, isOpen: false });
            }}
            onConfirm={() => {
              setProtocolCyclesErrorProps({ ...protocolCyclesErrorProps, isOpen: false });
              UpdateProtocolCallback(protocolCyclesErrorProps.startingCycle);
            }}
          />
        </CareplanContainer>
        <ROPatientCarePlanPageFooter
          onBack={(): void => {
            history.push(`/medonc/patient/${patientId}/careplan/${careplanId}/${CAREPLAN_PAGE.TREATMENT_INFO}`);
          }}
          onNext={(): void => {
            if (startingCycleRef.current) {
              const { startingCycle, maxCycles, isContinuous } = startingCycleRef.current?.values;
              if (startingCycle === undefined || startingCycle < 1) return;
              if (!isContinuous && maxCycles < startingCycle) {
                setProtocolCyclesErrorProps({ startingCycle: startingCycle, maxCycles: maxCycles, isOpen: true });
                return;
              }
              UpdateProtocolCallback(startingCycle);
            } else {
              UpdateProtocolCallback(careplanData.moCareplanAdditionalInfo.startingCycle + 1);
            }
          }}
          nextDisabled={
            !selectedProtocol ||
            (cyclesData?.isCareplanApprovedOnce &&
              selectedProtocol !== careplanData.moCareplanAdditionalInfo.protocolId.toString())
          }
          backDisabled={false}
          backButtonId={'treatment-info-back-button'}
        />
      </div>
    </div>
  );
};

export default ProtocolSelectionPage;
