import winston from 'winston';
import * as Sentry from '@sentry/browser';

// Local Development: true || false
// process.env.REACT_APP_LOGGING_ENABLED is set at build time and will equate to true if DJANGO_TESTING environment variable is Y
// window.LOGGING_ENABLED will be false as default value is "DJANGO_TESTING" and it will not be replaced.
// Integrated environment: undefined || true
// process.env.REACT_APP_LOGGING_ENABLED will be undefined at build time
// window.LOGGING_ENABLED will be replaced with 'Y' by the set_react_env_vars script
const LOGGING_ENABLED = import.meta.env.REACT_APP_LOGGING_ENABLED === 'Y';

class Logger {
  private className: string;
  private logger: winston.Logger;

  constructor(className: string) {
    this.className = className;

    this.logger = winston.createLogger({
      levels: {
        error: 0,
        debug: 1,
        info: 2,
      },
      format: winston.format.simple(),
      transports: new winston.transports.Console({ level: 'info', stderrLevels: ['error'] }),
    });
  }

  /**
   * Function to overload winston's error logging.
   * @param {string} functionName Name of the function from which the logger is being called
   * @param {string} functionName Basic minimum message
   * @param {string[]]} args All additional messages, variables and things for the log message
   */
  public error = (functionName: string, ...messages: any[]) => {
    this.log('error', functionName, ...messages);
    this.sentry(functionName, ...messages);
  };

  /**
   * Function to overload winston's debug logging.
   * @param {string} functionName Name of the function from which the logger is being called
   * @param {string} functionName Basic minimum message
   * @param {string[]]} args All additional messages, variables and things for the log message
   */
  public debug = (functionName: string, ...messages: any[]) => {
    this.log('debug', functionName, ...messages);
  };

  /**
   * Function to overload winston's info logging.
   * @param {string?} functionName Name of the function from which the logger is being called
   * @param {string[]]} messages All messages, variables and things for the log message
   */
  public info = (functionName: string, ...messages: any[]) => {
    this.log('info', functionName, ...messages);
  };

  /**
   * Function to send a specific sentry message
   * @param {string?} functionName Name of the function from which the logger is being called
   * @param {string[]]} messages All messages, variables and things for the log message
   */
  public sentry = (functionName: string, ...messages: any[]) => {
    Sentry.captureEvent({
      level: 'error',
      message: `${this.className}::${functionName}`,
      extra: { Extra: messages },
    });
  };

  /**
   * Generic logging function that creates a combined log
   * @param {string} level Log level from winston configuration
   * @param {string = 'N/A'} functionName Name of the function from which the logger is being called
   * @param {string = 'N/A'} message Basic message that the logger should output
   * @param {string[]} args Additional arguments, variables, further messages
   */
  private log = (level: 'error' | 'debug' | 'info', functionName: string, ...messages: any[]) => {
    if (LOGGING_ENABLED) {
      const timestamp = new Date().toLocaleTimeString();
      if (messages.length > 0) {
        this.logger.log(level, `${timestamp}::${this.className}::${functionName}::${messages}`);
      } else {
        this.logger.log(level, `${timestamp}::${this.className}::${functionName}`);
      }
    }
  };
}

export default Logger;
