import { ROPatientContextProvider } from '../PatientSummary/context';
import OuterContainer from '../PatientSummary/OuterContainer';
import LandingPage from './LandingPage';

const OnTreatmentReview = (): JSX.Element => {
  return (
    <ROPatientContextProvider>
      <OuterContainer>
        <LandingPage />
      </OuterContainer>
    </ROPatientContextProvider>
  );
};

export default OnTreatmentReview;
