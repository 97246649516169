export const supportedSize = {
  tablet: 768,
  transition: 920,
  desktop: 1920,
};

export const device = {
  tablet: `(min-width: ${supportedSize.tablet}px)`,
  desktop: `(min-width: ${supportedSize.desktop}px)`,
};
