import React, { ReactNode } from 'react';
import { styled, useTheme } from '@mui/system';
import Typography from '@mui/material/Typography';

interface SidePanelLinkProps {
  name: string;
  active: boolean;
  logo?: ReactNode;
}

const Link = styled('div')<{ active: boolean }>`
  display: flex;
  height: 41px;
  margin: 8px;
  align-items: center;
  gap: 17px;
  padding: 12px;
  border-radius: 4px;
  border-color: ${(props) => props.theme.palette.primary.main};
  background-color: ${(props) => props.theme.palette.primary.main};
  color: ${(props) => props.theme.palette.primary.contrastText};
  text-warp: no-wrap;

  & svg {
    fill: ${(props) => props.theme.palette.primary.contrastText};
  }
`;

const SidePanelLink = (props: SidePanelLinkProps): JSX.Element => {
  const theme = useTheme();
  return (
    <Link active={props.active}>
      {props.logo}
      <Typography
        variant="h6"
        sx={{
          color: props.active ? theme.palette.primary.contrastText : theme.palette.text.primary,
          textWrap: 'nowrap',
        }}>
        {props.name}
      </Typography>
    </Link>
  );
};

export default SidePanelLink;
