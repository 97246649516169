import { gql } from '@apollo/client';

export const UK_PATIENT_SEARCH = gql`
  query ukPatientSearch($search: String) {
    searchPatients: ukPatientSearch(search: $search) {
      id
      firstName
      lastName
      fullName
      ida
      dob
      primaryPhone
      primaryPhoneFormatted
      hasDraftCareplan
      email
      pxOptedIn
      regFormStatus
      pxOptOutComment
      address {
        id
        line1
        line2
        city
        state
        country
        postcode
      }
    }
  }
`;

export const PATIENT_SEARCH_RO = gql`
  query PatientSearch($search: String) {
    searchPatientsRo(searchString: $search) {
      id
      fullName
      ida
      dob
      city
      id
      line1
      line2
      postcode
      state
      emrInstance
    }
  }
`;
