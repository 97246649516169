import * as Yup from 'yup';
import { VALUE_REQUIRED, PHONE_VALIDATION, PHONE_REQUIRED } from '../Helper';
import { FormikValues, validateYupSchema, yupToFormErrors } from 'formik';
import { US_PHONE_REGEX } from 'shared-components/utils/Regex';

export const generateValidationSchema = (values: FormikValues): any => {
  const multiFieldSchema = Yup.object().shape(
    {
      firstName: Yup.string().ensure().required(VALUE_REQUIRED),
      lastName: Yup.string().ensure().required(VALUE_REQUIRED),
      relationship: Yup.string().ensure().required(VALUE_REQUIRED),
      homePhoneNumber: Yup.string()
        .matches(US_PHONE_REGEX, { message: PHONE_VALIDATION, excludeEmptyString: true })
        .ensure()
        .when('mobilePhoneNumber', {
          is: '',
          then: Yup.string().required(PHONE_REQUIRED).matches(US_PHONE_REGEX, PHONE_VALIDATION),
        }),
      mobilePhoneNumber: Yup.string()
        .matches(US_PHONE_REGEX, { message: PHONE_VALIDATION, excludeEmptyString: true })
        .ensure()
        .when('homePhoneNumber', {
          is: '',
          then: Yup.string().required(PHONE_REQUIRED).matches(US_PHONE_REGEX, PHONE_VALIDATION),
        }),
    },
    [['homePhoneNumber', 'mobilePhoneNumber']],
  );

  try {
    validateYupSchema<FormikValues>(values, multiFieldSchema, true);
  } catch (err) {
    return yupToFormErrors(err);
  }

  return {};
};
