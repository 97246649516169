import React from 'react';
import moment from 'moment';

interface PropsType {
  startDate: moment.Moment | null;
  endDate: moment.Moment | null;
}

export const CycleDateRange = ({ startDate, endDate }: PropsType): JSX.Element => {
  if (!startDate || !endDate) return <></>;
  const startString = startDate.format('Do MMMM');
  const endString = endDate.format('Do MMMM');

  return (
    <h3>
      <span data-cy="cycle-start">{startString}</span> - <span data-cy="cycle-end">{endString}</span>
    </h3>
  );
};
