import { gql } from '@apollo/client';

// TODO: Diagnosis intake page does not actually have an ID on the return
export const GET_DIAGNOSIS_INTAKE = gql`
  query intake($diagnosisId: ID!) {
    intake(diagnosisId: $diagnosisId) {
      id
      breast {
        id
        grade
        er
        pgr
        her2
        mastectomy
        reconstruction
        excerciseReferral
        surgery
        oncoplasticSurgery
        reconstructionTimeline
        reconstructionType
        expandersInSitu
        lymphovascularInvasion
        lymphovascularInvasionExtent
        radialMarginStatus
        neoadjuvantSystemicTherapy
        nstAdditionalInfo
        exerciseDiscussedWithPatient
      }
      lung {
        id
        weightLoss
        smokingHistory
        egfr
        alk
        pdl1
      }
      urogenital {
        id
        gleasonOne
        gleasonTwo
        isupGrade
        psa
        psaDate
        psma
        positiveSite
        prostatectomy
      }
      lymphoma {
        id
        stage
        aOrBSymptoms
        extranodal
        bulkDisease
      }
      headAndNeck {
        id
        p16
        smokingHistory
        stageTCode
        stageNCode
        stageMCode
        cigarettesPerDay
        yearsSmoked
        packYears
        dateOfSurgery
        extracapsularExtension
        margins
        marginsMeasurement
        periNeuralInvasion
        pniType
        patientDiscussedAtMdt
        treatmentConsistentMdt
      }
    }
  }
`;

export const CREATE_DIAGNOSIS_INTAKE = gql`
  mutation createIntake($diagnosisId: ID!, $careplanId: ID!, $intakeData: IntakeData) {
    createIntake(diagnosisId: $diagnosisId, careplanId: $careplanId, intakeData: $intakeData) {
      intake {
        id
        breast {
          id
          grade
          er
          pgr
          her2
          mastectomy
          reconstruction
          excerciseReferral
          surgery
          oncoplasticSurgery
          reconstructionTimeline
          reconstructionType
          expandersInSitu
          lymphovascularInvasion
          lymphovascularInvasionExtent
          radialMarginStatus
          neoadjuvantSystemicTherapy
          nstAdditionalInfo
          exerciseDiscussedWithPatient
        }
        lung {
          id
          weightLoss
          smokingHistory
          egfr
          alk
          pdl1
        }
        urogenital {
          id
          gleasonOne
          gleasonTwo
          isupGrade
          psa
          psaDate
          psma
          positiveSite
          prostatectomy
        }
        lymphoma {
          id
          stage
          aOrBSymptoms
          extranodal
          bulkDisease
        }
        headAndNeck {
          id
          p16
          smokingHistory
          stageTCode
          stageNCode
          stageMCode
          cigarettesPerDay
          yearsSmoked
          packYears
          dateOfSurgery
          extracapsularExtension
          margins
          marginsMeasurement
          periNeuralInvasion
          pniType
          patientDiscussedAtMdt
          treatmentConsistentMdt
        }
      }
    }
  }
`;

// TODO: Intake does not have an id, breaks intake page
export const UPDATE_DIAGNOSIS_INTAKE = gql`
  mutation updateIntake($diagnosisId: ID!, $intakeData: IntakeData) {
    updateIntake(diagnosisId: $diagnosisId, intakeData: $intakeData) {
      intake {
        id
        breast {
          id
          grade
          er
          pgr
          her2
          mastectomy
          reconstruction
          excerciseReferral
          surgery
          oncoplasticSurgery
          reconstructionTimeline
          reconstructionType
          expandersInSitu
          lymphovascularInvasion
          lymphovascularInvasionExtent
          radialMarginStatus
          neoadjuvantSystemicTherapy
          nstAdditionalInfo
          exerciseDiscussedWithPatient
        }
        lung {
          id
          weightLoss
          smokingHistory
          egfr
          alk
          pdl1
        }
        urogenital {
          id
          gleasonOne
          gleasonTwo
          isupGrade
          psa
          psaDate
          psma
          positiveSite
          prostatectomy
        }
        lymphoma {
          id
          stage
          aOrBSymptoms
          extranodal
          bulkDisease
        }
        headAndNeck {
          id
          p16
          smokingHistory
          stageTCode
          stageNCode
          stageMCode
          cigarettesPerDay
          yearsSmoked
          packYears
          dateOfSurgery
          extracapsularExtension
          margins
          marginsMeasurement
          periNeuralInvasion
          pniType
          patientDiscussedAtMdt
          treatmentConsistentMdt
        }
      }
    }
  }
`;
