import { gql } from '@apollo/client';
import { HEALTH_ASSESSMENT_MEDICATION_FRAGMENT } from 'op-graphql/fragments';

export const HA_MEDICATION_QUERY = gql`
  query HealthAssessment($patientId: ID!) {
    healthAssessment(id: $patientId) {
      id
      pharmacyName
      pharmacyPhone
      medicationBool
      medications {
        id
        ...HealthAssessmentMedication
      }
    }
  }
  ${HEALTH_ASSESSMENT_MEDICATION_FRAGMENT}
`;

export const UPDATE_HA_MEDICATION = gql`
  mutation UpdateHaMedication(
    $haId: ID!
    $patientId: ID!
    $medicationBool: Boolean
    $pharmacyName: String
    $pharmacyPhone: String
  ) {
    updateHaMedication(
      id: $haId
      patientId: $patientId
      medicationBool: $medicationBool
      pharmacyName: $pharmacyName
      pharmacyPhone: $pharmacyPhone
    ) {
      healthAssessment {
        id
        pharmacyName
        pharmacyPhone
        medicationBool
        medications {
          id
          ...HealthAssessmentMedication
        }
      }
    }
  }
  ${HEALTH_ASSESSMENT_MEDICATION_FRAGMENT}
`;

export const DELETE_MEDICATION = gql`
  mutation DeleteMedication($id: ID!, $patientId: ID!) {
    deleteMedication(id: $id, patientId: $patientId) {
      success
    }
  }
`;

export const ADD_MEDICATION = gql`
  mutation AddMedication($haId: ID!, $patientId: ID!) {
    addMedication(haId: $haId, patientId: $patientId) {
      medication {
        id
        ...HealthAssessmentMedication
      }
    }
  }
  ${HEALTH_ASSESSMENT_MEDICATION_FRAGMENT}
`;

export const UPDATE_MEDICATION = gql`
  mutation UpdateMedication(
    $id: ID!
    $patientId: ID!
    $medicationName: String
    $dosage: String
    $route: String
    $prescribingPhysician: String
  ) {
    updateMedication(
      id: $id
      patientId: $patientId
      medicationName: $medicationName
      dosage: $dosage
      route: $route
      prescribingPhysician: $prescribingPhysician
    ) {
      healthAssessment {
        id
        pharmacyName
        pharmacyPhone
        medicationBool
        medications {
          id
          ...HealthAssessmentMedication
        }
      }
    }
  }
  ${HEALTH_ASSESSMENT_MEDICATION_FRAGMENT}
`;
