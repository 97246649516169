import { gql } from '@apollo/client';

export const REGISTRATION_CONTACT_DETAILS_QUERY = gql`
  query RegistrationContactDetails($id: ID!) {
    patient(id: $id) {
      id
      primaryPhone
      secondaryPhone
      email
    }
    user {
      id
      isPso
    }
  }
`;

export const UPDATE_REGISTRATION_CONTACT_DETAILS = gql`
  mutation UpdatePatientUsContacts($id: ID!, $primaryPhone: String, $secondaryPhone: String, $email: String) {
    updatePatientUsContacts(id: $id, primaryPhone: $primaryPhone, secondaryPhone: $secondaryPhone, email: $email) {
      patient {
        id
        primaryPhone
        secondaryPhone
        email
      }
    }
  }
`;
