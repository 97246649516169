import React from 'react';
import { styled } from '@mui/system';
import { HeaderBar } from 'op-components';

const HeaderWrapper = styled('div')`
  position: fixed;
  width: 100%;
  z-index: 1000;
`;

const ContentWrapper = styled('div')`
  display: flex;
  padding-top: 60px;
  height: 100%;
`;

interface Props {
  children: any;
}

const NAPageContainer = (props: Props): JSX.Element => {
  return (
    <>
      <HeaderWrapper>
        <HeaderBar />
      </HeaderWrapper>
      <ContentWrapper>{props.children}</ContentWrapper>
    </>
  );
};

export default NAPageContainer;
