import { gql } from '@apollo/client';
import * as fragments from 'op-graphql/fragments';

export const HEALTH_ASSESMENT_US_SUMMARY_QUERY = gql`
  query HealthAssessment($patientId: ID!) {
    healthAssessment(id: $patientId) {
      id
      patient {
        userProfile {
          id
          showRegistration
        }
        id
        fullName
        dob
      }
      height
      weight
      previousCancer
      cancer {
        id
        ...HealthAssessmentCancer
      }
      otherRadiotherapy
      otherRadiotherapyConditions {
        id
        ...HealthAssessmentOtherRT
      }
      familyHistory
      familyHistoryAnswer
      mobilityAid
      mobilityAids {
        id
        ...HealthAssessmentMobilityAid
      }
      wellbeingIssue
      wellbeingIssues {
        id
        ...HealthAssessmentWellbeingIssue
      }
      activeInjury
      activeInjuryReason
      inPain
      inPainReason
      fallen
      fallenReason
      pregnant
      advancedCareDirective
      advancedCareAdditionalInformation
      alcoholBool
      alcoholFrequency
      smokingBool
      smokingHistory
      smokingFrequency
      smokingStopDate
      smokingStopRawMonth
      smokingStopRawYear
      exerciseBool
      exerciseFrequency
      accommodationAssistance
      accommodationAssistanceReason
      transportationAssistance
      transportationAssistanceReason
      prevOperation
      operations {
        id
        ...HealthAssessmentOperation
      }
      medicationBool
      medications {
        id
        ...HealthAssessmentMedication
      }
      allergyBool
      allergies {
        id
        ...HealthAssessmentAllergy
      }
      otherMedProblemBool
      otherMedProblemDescription
      altMedicineBool
      altMedicineDescription
    }
    referenceData: listData(
      categories: [
        "treatmentType"
        "treatmentStage"
        "diabetes"
        "immunosuppresiveConditions"
        "internalDevices"
        "intractableInfectionOrIllnesses"
        "mobilityAids"
        "wellbeingIssues"
        "alcohol_frequency"
        "smoking_frequency"
        "exercise_frequency"
        "exercise_frequency"
        "sensitivity"
        "reaction"
      ]
    ) {
      id
      name
    }
    treatmentTypeRefData: listData(category: "treatmentType") {
      id
      name
    }
    treatmentStageRefData: listData(category: "treatmentStage") {
      id
      name
    }
    diabetesRefData: listData(category: "diabetes") {
      id
      name
    }
    immunosuppresiveConditionsRefData: listData(category: "immunosuppresiveConditions") {
      id
      name
    }
    internalDevicesRefData: listData(category: "internalDevices") {
      id
      name
    }
    intractableInfectionOrIllnessesRefData: listData(category: "intractableInfectionOrIllnesses") {
      id
      name
    }
    mobilityAidRefData: listData(category: "mobilityAids") {
      id
      name
    }
    wellbeingIssueRefData: listData(category: "wellbeingIssues") {
      id
      name
    }
    alcoholFrequencyRefData: listData(category: "alcohol_frequency") {
      id
      name
    }
    smokingFrequencyRefData: listData(category: "smoking_frequency") {
      id
      name
    }
    exerciseFrequencyRefData: listData(category: "exercise_frequency") {
      id
      name
    }
    sensitivityRefData: listData(category: "sensitivity") {
      id
      name
    }
    reactionRefData: listData(category: "reaction") {
      id
      name
    }
    user {
      id
      isPso
    }
  }
  ${fragments.HEALTH_ASSESSMENT_ALLERGY_FRAGMENT}
  ${fragments.HEALTH_ASSESSMENT_CANCER_FRAGMENT}
  ${fragments.HEALTH_ASSESSMENT_MEDICATION_FRAGMENT}
  ${fragments.HEALTH_ASSESSMENT_MOBILITY_AID_FRAGMENT}
  ${fragments.HEALTH_ASSESSMENT_OPERATION_FRAGMENT}
  ${fragments.HEALTH_ASSESSMENT_OTHER_RT_FRAGMENT}
  ${fragments.HEALTH_ASSESSMENT_WELLBEING_ISSUE_FRAGMENT}
`;
