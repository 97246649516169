export const getFileExtension = (filename: string) => {
  const filenameSplit = filename.split('.');
  return filenameSplit[filenameSplit.length - 1].toLowerCase();
};

export const allowedExtensions = ['JPG', 'JPEG', 'PDF', 'BMP', 'PNG', 'GIF'];

export const checkFileExtension = (fileExtension: string): string => {
  if (allowedExtensions.indexOf(fileExtension.toUpperCase()) === -1) {
    return `File attachments must be in ${allowedExtensions.join(', ')} format`;
  }
  return '';
};
