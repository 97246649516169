import xss from 'xss';
// Package: https://www.npmjs.com/package/sanitize-html
// not typed no plan for it to be :(
const whiteList = {
  h1: [],
  h2: [],
  h3: [],
  h4: [],
  h5: [],
  h6: [],
  b: [],
  i: [],
  em: [],
  strong: [],
  div: [],
  table: [],
  tbody: [],
  td: [],
  tfoot: [],
  th: [],
  thead: [],
  tr: [],
  span: [],
  hr: [],
  li: [],
  ul: [],
  ol: [],
  p: [],
  br: [],
  img: ['src', 'alt'],
  a: [],
};
export const sanitizeNote = (dirty: string): any => ({
  __html: xss(dirty, {
    whiteList,
    stripIgnoreTag: true,
  }).trim(),
});
