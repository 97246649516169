import { AppointmentDetail, AppointmentObject } from '../../models';
import { DateTimeConverter } from '../../utils';

export default class AppointmentDetailsViewModel {
  /**
   * Generate a dictionary of values for display.
   * @param {AppointmentObject | null} appointment The appointment object for which this dictionary should be generated for.
   * @returns {{ [index: string]: any } | null} A string or object value if there is data, or null if there is no data to display.
   */
  public generateDetailsDataDictionary = (appointment: AppointmentObject | null): AppointmentDetail | null => {
    // There is no currently selected appointment, so return null.
    if (appointment === null) {
      return null;
    }

    // Otherwise, generate the required display data from the currently selected appointment object.
    const currentLocale = window.navigator.language;
    if (appointment.startTime) {
      const date = new Date(appointment.startTime);
      const dataDictionary: AppointmentDetail = {
        dateTime: { date: '', time: '' },
        location: { address: {} },
        staff: {},
      };

      dataDictionary.dateTime.date = DateTimeConverter.getFormattedDateAsMMMYYYY(currentLocale, date);
      dataDictionary.dateTime.time = DateTimeConverter.getFormattedDateTimeAsEEEdhmmaa(currentLocale, date);

      const duration = appointment.duration;
      if (duration) {
        dataDictionary.dateTime.durationInMinutes = Math.round((duration / 60) * 10) / 10;
      }

      const department = appointment.department;
      if (department && department.name) {
        dataDictionary.location.department = department.name;
      }

      // Check if there is a phone number in location and then add into department array if required
      const location = appointment.location;
      if (location && location.phone) {
        const phoneNumber = location.phone;

        // Write the result back into the data dictionary
        dataDictionary.location.phone = phoneNumber;
      }

      if (location && location.name) {
        const name = location.name;
        dataDictionary.location.name = name;
      }

      if (location && location.address && location.address.formattedAddress && location.mapSearchUrl) {
        dataDictionary.location.address.formattedAddress = location.address.formattedAddress;
        dataDictionary.location.address.mapSearchUrl = location.mapSearchUrl;
        dataDictionary.location.address.city = location.address.city;
      }

      const practitioner = appointment.practitioner;

      if (practitioner) {
        dataDictionary.staff.name = practitioner.name;
        dataDictionary.staff.prefix = practitioner.prefix;
        dataDictionary.staff.qualification = practitioner.qualification;
      }

      return dataDictionary;
    } else {
      // FIXME: Handle the error or report to sentry
      return null;
    }
  };
}
