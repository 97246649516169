// eslint-disable-next-line no-use-before-define
import React, { Component, Fragment } from 'react';

import './SuccessInfo.scss';

import { Success } from 'shared-components/images';

interface Props {
  successes?: string[];
  successMessages?: { [key: string]: string };
}

class SuccessInfo extends Component<Props> {
  public render(): JSX.Element {
    const { successes, successMessages } = this.props;

    if (!successes) {
      return <Fragment />;
    }

    const successComponent: JSX.Element[] = successes.map((success: string): JSX.Element => {
      let successMessage = success;

      if (successMessages) {
        successMessage = successMessages[success];
      }

      return (
        <div className="success-state" key={successMessage}>
          <Success className="icon" />
          <div className="success-message">{successMessage}</div>
        </div>
      );
    });
    return <Fragment>{successComponent}</Fragment>;
  }
}

export default SuccessInfo;
