export const patientSummaryLink = '/patient/:patientId/summary';

export enum SECTIONS {
  BASIC_INFORMATION = 'basic-information',
  CHEMOTHERAPY_RISK = 'chemotherapy-risk',
  IMPLANTED_MEDICAL_DEVICES = 'implanted-medical-devices',
  INFECTIOUS_RISK = 'infectious-risk',
  FALLS_RISK = 'falls-risk',
  PRESSURE_INJURY = 'pressure-injury',
  WELLBEING = 'wellbeing',
  ADVANCED_CARE_DIRECTIVE = 'advance-care-directive',
  ALLERGIES = 'allergies',
  REVIEW_AND_SUBMIT = 'review-and-submit',
}

export const routes = {
  basicInformation: {
    sectionTitle: 'Basic Information',
    path: '/patient/:patientId/nurse/prectchartcheck/:formId/basic-information',
    name: 'basic-information',
  },
  chemoRisk: {
    sectionTitle: 'Chemotherapy Risk',
    path: '/patient/:patientId/nurse/prectchartcheck/:formId/chemotherapy-risk',
    name: 'chemotherapy-risk',
  },
  implantedMedicalDevices: {
    sectionTitle: 'Implanted Medical Devices',
    path: '/patient/:patientId/nurse/prectchartcheck/:formId/implanted-medical-devices',
    name: 'implanted-medical-devices',
  },
  infectiousRisk: {
    sectionTitle: 'Infectious Risk',
    path: '/patient/:patientId/nurse/prectchartcheck/:formId/infectious-risk',
    name: 'infectious-risk',
  },
  fallsRisk: {
    sectionTitle: 'Falls Risk',
    path: '/patient/:patientId/nurse/prectchartcheck/:formId/falls-risk',
    name: 'falls-risk',
  },
  pressureInjuryRisk: {
    sectionTitle: 'Pressure Injury Risk',
    path: '/patient/:patientId/nurse/prectchartcheck/:formId/pressure-injury',
    name: 'pressure-injury',
  },
  wellbeing: {
    sectionTitle: 'Wellbeing',
    path: '/patient/:patientId/nurse/prectchartcheck/:formId/wellbeing',
    name: 'wellbeing',
  },
  advancedCareDirective: {
    sectionTitle: 'Advance Care Directive/NFR',
    path: '/patient/:patientId/nurse/prectchartcheck/:formId/advance-care-directive',
    name: 'advance-care-directive',
  },
  allergies: {
    sectionTitle: 'Allergies',
    path: '/patient/:patientId/nurse/prectchartcheck/:formId/allergies',
    name: 'allergies',
  },
  reviewAndSubmit: {
    sectionTitle: 'Review & Submit',
    path: '/patient/:patientId/nurse/prectchartcheck/:formId/review-and-submit',
    name: 'review-and-submit',
  },
};
