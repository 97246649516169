import React from 'react';
import { styled } from '@mui/system';
import { Link } from 'react-router-dom';
import { DeviceUtilities } from 'shared-components/utils';
import { Button } from '@mui/material';
import { LoadingButton } from '@mui/lab';

const FooterContainer = styled('div')`
  z-index: 1000;
  display: flex;
  background: white;
  border-top: 2px solid ${(props) => props.theme.palette.primary.dark};
  padding: 12px 24px;
  flex: 1;
  flex-grow: 0;
`;

const SaveContinueContainer = styled('div')`
  display: flex;
  flex-direction: row;
  margin-left: auto;
  > * {
    margin-right: 10px;
  }
`;

const SaveContinueContainerMobile = styled(SaveContinueContainer)`
  margin-right: auto;
`;

const ExitButton = styled(Link)`
  margin-right: 10px;
  text-decoration: none;
`;

interface Props {
  pageNumber?: number;
  backLink?: string;
  saveAndExitLink?: string;
  continueLink?: string;
  handleClickOnBack?: any;
  handleClickOnContinue?: any;
  submitForm?: () => void;
  showSaveAndExit?: boolean;
  isLocked?: boolean;
  isAmendment?: boolean;
  deleteModal: () => void;
  setDeleteModalIsOpen?: (value: boolean) => void;
  createAmendmentAndRedirect?: () => void;
  sidePanelWidth?: string;
  submitButtonText?: string;
  showDiscard: boolean;
  footerPosition?: string;
  isLoading?: boolean;
  continueDisabled?: boolean;
  isShowSaveExitConfirm?: boolean;
  handleShowSaveExitConfirm?: () => void;
}

const FormFooter = ({
  pageNumber,
  backLink,
  saveAndExitLink,
  continueLink,
  handleClickOnBack,
  handleClickOnContinue,
  submitForm,
  showSaveAndExit = true,
  isLocked,
  deleteModal,
  setDeleteModalIsOpen,
  createAmendmentAndRedirect,
  sidePanelWidth,
  submitButtonText = 'Submit',
  showDiscard = true,
  footerPosition = 'fixed',
  isLoading = false,
  continueDisabled = false,
  isShowSaveExitConfirm = false,
  handleShowSaveExitConfirm,
}: Props): JSX.Element => {
  const styledProps = {
    $sidePanelWidth: sidePanelWidth || '320px',
    $footerPosition: footerPosition,
  };
  const isMobile = DeviceUtilities.isMobileDevice();
  const ButtonContainer = isMobile ? SaveContinueContainerMobile : SaveContinueContainer;

  return (
    <FooterContainer {...styledProps} id="form-footer">
      <>
        {deleteModal()}
        {!isMobile && showSaveAndExit && !isShowSaveExitConfirm && (
          <ExitButton to={saveAndExitLink ? saveAndExitLink : ''}>
            <Button size="large" variant="outlined" data-testid="exit-button">
              {isLocked ? 'Exit to patient summary' : 'Save and Exit'}
            </Button>
          </ExitButton>
        )}
        {!isMobile && showSaveAndExit && isShowSaveExitConfirm && handleShowSaveExitConfirm && (
          <Button
            size="large"
            variant="outlined"
            data-testid="save-and-exit"
            onClick={() => handleShowSaveExitConfirm()}>
            Save and Exit
          </Button>
        )}
        {isLocked && createAmendmentAndRedirect && (
          <Button
            size="large"
            variant="outlined"
            data-testid="amendAssessment"
            onClick={() => createAmendmentAndRedirect()}>
            Amend assessment
          </Button>
        )}
        {!isLocked && showDiscard && setDeleteModalIsOpen && (
          <Button size="large" variant="outlined" data-testid="discard" onClick={() => setDeleteModalIsOpen(true)}>
            Discard
          </Button>
        )}
        {!isLocked && (
          <ButtonContainer>
            {pageNumber && pageNumber > 1 && (
              <Link to={backLink ? backLink : ''} style={{ textDecoration: 'unset' }}>
                <Button size="large" variant="outlined" data-testid="Back" onClick={handleClickOnBack}>
                  Back
                </Button>
              </Link>
            )}
            {continueLink && (
              <Link to={continueLink ? continueLink : ''} style={{ textDecoration: 'unset' }}>
                <Button size="large" data-testid="Continue" variant="contained" onClick={handleClickOnContinue}>
                  Continue
                </Button>
              </Link>
            )}
            {submitForm && (
              <LoadingButton
                size="large"
                data-testid="Submit"
                variant="contained"
                disabled={continueDisabled}
                loading={isLoading}
                onClick={() => submitForm()}>
                {submitButtonText}
              </LoadingButton>
            )}
          </ButtonContainer>
        )}
      </>
    </FooterContainer>
  );
};

export default FormFooter;
