// eslint-disable-next-line no-use-before-define
import { useEffect, useRef } from 'react';
import { gql, useMutation } from '@apollo/client';

export const EXTEND_LOCK_MUTATION = gql`
  mutation ExtendLock($accessPatientId: ID!) {
    extendLock(accessPatientId: $accessPatientId) {
      success
      patient {
        lock {
          lockedBy
          readOnly
          lockedByName
        }
      }
    }
  }
`;

const headers = import.meta.env.AWS_WAF_MAGIC
  ? {
      'x-aws-waf-magic': import.meta.env.AWS_WAF_MAGIC,
    }
  : {};

/**
 * Provide heartbeat mutations to the server to let it know the user is still requiring lock on the patient record.
 */
const ExtendLock = ({ accessPatientId }: { accessPatientId: string }): JSX.Element => {
  const [extendLock] = useMutation(EXTEND_LOCK_MUTATION, {
    context: {
      headers,
    },
  });

  useEffect(() => {
    setTimeout(() => {
      extendLock({ variables: { accessPatientId } });
    }, 500);

    const interval = setInterval(() => {
      extendLock({ variables: { accessPatientId } });
    }, 5000);

    return () => {
      clearInterval(interval);
    };
  }, [accessPatientId]);

  return <></>;
};

export default ExtendLock;
