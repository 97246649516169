import React from 'react';
import { styled } from '@mui/system';
import Tooltip, { TooltipProps, tooltipClasses } from '@mui/material/Tooltip';
import { Stack, Typography } from '@mui/material';
import { EditNote } from '@mui/icons-material';
import { useHistory } from 'react-router-dom';

import './ToolTip.scss';
import { Add as AddIcon } from '@mui/icons-material';

export const HtmlTooltip = styled(({ className, ...props }: TooltipProps) => (
  <Tooltip {...props} arrow classes={{ popper: className }} enterDelay={200} />
))(({ theme }) => ({
  [`& .${tooltipClasses.tooltip}`]: {
    maxWidth: 300,
    fontSize: '14px',
    border: '1px solid #dadde9',
    background: '#fff',
    backgroundColor: '#F8F7FF',
    color: 'black',
    boxShadow: ' 0px 4px 8px rgb(113 110 106 / 50%)',
    position: 'relative',
    top: -20,
  },
  [`& .${tooltipClasses.arrow}`]: {
    color: '#fff',
    '&:before': {
      border: '1px solid #dadde9',
    },
  },
}));

export const TooltipContainer = (props: any): JSX.Element => {
  const { tooltipType, treatmentWeek, patient } = props;
  const history = useHistory();
  const patientId = patient.patientId;
  const treatmentWeekIndex = treatmentWeek ? treatmentWeek - 1 : 0;
  return (
    <div className="patient-tracker-tool-tip-container">
      {tooltipType === 'initialConsult' && (
        <>
          <Typography color="inherit" className="heading">
            Initial Consult
          </Typography>
          <table>
            <tbody>
              <tr>
                <td className="key">Appointment:</td>
                <td className="spacing"></td>
                <td className="value">
                  {patient.initialConsultAppntDate} {patient.initialConsultAppntTime}
                </td>
              </tr>
              <tr>
                <td className="key">Status:</td>
                <td className="spacing"></td>
                <td className="value">{patient.initialConsultStatus}</td>
              </tr>
              <tr>
                <td className="key">Additional Info:</td>
                <td className="spacing"></td>
                <td className="value">{patient.initialConsultAdditionalInfo}</td>
              </tr>
              <tr>
                <td className="key">Owner:</td>
                <td className="spacing"></td>
                <td className="value">{patient.initialConsultOwner}</td>
              </tr>
            </tbody>
          </table>
        </>
      )}

      {tooltipType === 'preAuth' && (
        <>
          <Typography color="inherit" className="heading">
            Pre-Auth
          </Typography>
          <table>
            <tbody>
              <tr>
                <td className="key">Due date:</td>
                <td className="spacing"></td>
                <td className="value">{patient.preAuthDueDate}</td>
              </tr>
              <tr>
                <td className="key">Status:</td>
                <td className="spacing"></td>
                <td className="value">{patient.preAuthStatus}</td>
              </tr>
              <tr>
                <td className="key">Owner:</td>
                <td className="spacing"></td>
                <td className="value">{patient.preAuthOwner}</td>
              </tr>
            </tbody>
          </table>
        </>
      )}

      {tooltipType === 'SIMRequest' && (
        <>
          <Typography color="inherit" className="heading">
            SIM Request
          </Typography>
          <table>
            <tbody>
              <tr>
                <td className="key">Due date:</td>
                <td className="spacing"></td>
                <td className="value">{patient.SIMRequestDueDate}</td>
              </tr>
              <tr>
                <td className="key">Status:</td>
                <td className="spacing"></td>
                <td className="value">{patient.SIMRequestStatus}</td>
              </tr>
              <tr>
                <td className="key">Owner:</td>
                <td className="spacing"></td>
                <td className="value">{patient.SIMRequestOwner}</td>
              </tr>
            </tbody>
          </table>
          {patient.SIMRequestStatus !== 'Completed' && (
            <button
              onClick={() => {
                history.push(`/radiation/patient/${patientId}/careplan/create/`);
              }}>
              <AddIcon sx={{ position: 'relative', left: '-5px', top: '-1px' }} />
              <span>Create SIM Request</span>
            </button>
          )}
        </>
      )}

      {tooltipType === 'prescription' && (
        <>
          <Typography color="inherit" className="heading">
            Prescription
          </Typography>
          <table>
            <tbody>
              <tr>
                <td className="key">Due date:</td>
                <td className="spacing"></td>
                <td className="value">{patient.prescriptionDueDate}</td>
              </tr>
              <tr>
                <td className="key">Status:</td>
                <td className="spacing"></td>
                <td className="value">{patient.prescriptionStatus}</td>
              </tr>
              <tr>
                <td className="key">Owner:</td>
                <td className="spacing"></td>
                <td className="value">{patient.prescriptionOwner}</td>
              </tr>
            </tbody>
          </table>
          {patient.prescriptionStatus !== 'Completed' && (
            <button
              onClick={() => {
                history.push(`/radiation/patient/${patientId}/careplan/create/`);
              }}>
              <AddIcon sx={{ position: 'relative', left: '-5px', top: '-1px' }} />
              <span>Create Careplan</span>
            </button>
          )}
        </>
      )}

      {tooltipType === 'complianceDocs' && (
        <>
          <Typography color="inherit" className="heading">
            Compliance Documents
          </Typography>
          <table>
            <tbody>
              <tr>
                <td className="key">Due date:</td>
                <td className="spacing"></td>
                <td className="value">{patient.complianceDocsDueDate}</td>
              </tr>
              <tr>
                <td className="key">Status:</td>
                <td className="spacing"></td>
                <td className="value">{patient.complianceDocsStatus}</td>
              </tr>
              <tr>
                <td className="key">Additional Info:</td>
                <td className="spacing"></td>
                <td className="value">{patient.complianceDocsAdditionalInfo}</td>
              </tr>
              <tr>
                <td className="key">Owner:</td>
                <td className="spacing"></td>
                <td className="value">{patient.complianceDocsOwner}</td>
              </tr>
            </tbody>
          </table>
        </>
      )}

      {tooltipType === 'simulation' && (
        <>
          <Typography color="inherit" className="heading">
            Simulation
          </Typography>
          <table>
            <tbody>
              <tr>
                <td className="key">Appointment:</td>
                <td className="spacing"></td>
                <td className="value">
                  {patient.simulationAppntDueDate} {patient.simulationAppntTime}
                </td>
              </tr>
              <tr>
                <td className="key">Status:</td>
                <td className="spacing"></td>
                <td className="value">{patient.simulationStatus}</td>
              </tr>
              <tr>
                <td className="key">Additional Info:</td>
                <td className="spacing"></td>
                <td className="value">{patient.simulationAdditionalInfo}</td>
              </tr>
              <tr>
                <td className="key">Owner:</td>
                <td className="spacing"></td>
                <td className="value">{patient.simulationOwner}</td>
              </tr>
            </tbody>
          </table>
        </>
      )}

      {tooltipType === 'fusion' && (
        <>
          <Typography color="inherit" className="heading">
            Fusion
          </Typography>
          <table>
            <tbody>
              <tr>
                <td className="key">Due date:</td>
                <td className="spacing"></td>
                <td className="value">{patient.fusionDueDate}</td>
              </tr>
              <tr>
                <td className="key">Status:</td>
                <td className="spacing"></td>
                <td className="value">{patient.fusionStatus}</td>
              </tr>
              <tr>
                <td className="key">Owner:</td>
                <td className="spacing"></td>
                <td className="value">{patient.fusionOwner}</td>
              </tr>
            </tbody>
          </table>
        </>
      )}

      {tooltipType === 'targetVolume' && (
        <>
          <Typography color="inherit" className="heading">
            Target Volume
          </Typography>
          <table>
            <tbody>
              <tr>
                <td className="key">Due date:</td>
                <td className="spacing"></td>
                <td className="value">{patient.targetVolumeDueDate}</td>
              </tr>
              <tr>
                <td className="key">Status:</td>
                <td className="spacing"></td>
                <td className="value">{patient.targetVolumeStatus}</td>
              </tr>
              <tr>
                <td className="key">Owner:</td>
                <td className="spacing"></td>
                <td className="value">{patient.targetVolumeOwner}</td>
              </tr>
            </tbody>
          </table>
        </>
      )}

      {tooltipType === 'contouring' && (
        <>
          <Typography color="inherit" className="heading">
            Contouring
          </Typography>
          <table>
            <tbody>
              <tr>
                <td className="key">Due date:</td>
                <td className="spacing"></td>
                <td className="value">{patient.contouringDueDate}</td>
              </tr>
              <tr>
                <td className="key">Status:</td>
                <td className="spacing"></td>
                <td className="value">{patient.contouringStatus}</td>
              </tr>
              <tr>
                <td className="key">Owner:</td>
                <td className="spacing"></td>
                <td className="value">{patient.contouringOwner}</td>
              </tr>
            </tbody>
          </table>
        </>
      )}

      {tooltipType === 'planApproval' && (
        <>
          <Typography color="inherit" className="heading">
            Plan Approval
          </Typography>
          <table>
            <tbody>
              <tr>
                <td className="key">Due date:</td>
                <td className="spacing"></td>
                <td className="value">{patient.planApprovalDueDate}</td>
              </tr>
              <tr>
                <td className="key">Status:</td>
                <td className="spacing"></td>
                <td className="value">{patient.planApprovalStatus}</td>
              </tr>
              <tr>
                <td className="key">Owner:</td>
                <td className="spacing"></td>
                <td className="value">{patient.planApprovalOwner}</td>
              </tr>
            </tbody>
          </table>
          {patient.planApprovalStatus !== 'Completed' && (
            <button>
              <EditNote style={{ position: 'relative', left: '-5px', top: '-1px' }} />
              <span>Review Plan</span>
            </button>
          )}
        </>
      )}
      {tooltipType === 'treatment' && patient.treatment && (
        <>
          <Typography color="inherit" className="heading">
            Week {treatmentWeek} Fractions #{patient.treatment[treatmentWeekIndex].fractions}
          </Typography>
          <table>
            <tbody>
              <tr>
                <td className="key">Due date:</td>
                <td className="spacing"></td>
                <td className="value">{patient.treatment[treatmentWeekIndex].date}</td>
              </tr>
              <tr>
                <td className="key">Status:</td>
                <td className="spacing"></td>
                <td className="value">{patient.treatment[treatmentWeekIndex].status}</td>
              </tr>
              <tr>
                <td className="key">Owner:</td>
                <td className="spacing"></td>
                <td className="value">{patient.treatment[treatmentWeekIndex].owner}</td>
              </tr>
            </tbody>
          </table>
          {patient.treatment[treatmentWeekIndex].showRecordOtv && (
            <button onClick={() => {}}>
              <Stack direction="row" alignItems="center">
                <AddIcon sx={{ position: 'relative', left: '-5px', top: '-1px' }} />
                <span>Record OTV</span>
              </Stack>
            </button>
          )}
        </>
      )}
    </div>
  );
};
