import { gql } from '@apollo/client';

export const FORGOT_PASSWORD_MUTATION_GQL = gql`
  mutation ResetPassword($username: String!) {
    resetPassword(username: $username) {
      resetLink
      errors
    }
  }
`;
