// eslint-disable-next-line no-use-before-define
import React, { Component, Fragment } from 'react';
import { RouteComponentProps, withRouter } from 'react-router-dom';
import { WithApolloClient, withApollo } from '@apollo/client/react/hoc';

import CovidInformation from './CovidInformation';

interface Props extends WithApolloClient<{}>, RouteComponentProps<{ patientId: string; ratingPage: string }> {}

class CovidInformationContentApollo extends Component<Props> {
  public render(): JSX.Element {
    return (
      <Fragment>
        <CovidInformation />
      </Fragment>
    );
  }
  /**
   * This function will generate the graph ql query that is required for fetching the question data that will be displayed to the patient.
   * This is used instead of static gql export is because the query will need to include various variables for caching purposes.
   * @returns {any} The graph ql tag that will be used to fetch the data.
   */
  //@ts-ignore
}

const apolloComponent = withApollo<Props>(CovidInformationContentApollo);
const routedComponent = withRouter(apolloComponent);
export default routedComponent;
