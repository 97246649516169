import { styled } from '@mui/system';
import { Grid } from '@mui/material';

// TODO there should be a standard one of these
export const FixedGrid = styled(Grid)`
  height: calc(100% - 60px);
`;

export const FixedGridItem = styled(Grid)`
  height: 100%;
`;

export const TumourStreamContainer = styled('div')`
  border: 1px solid ${(props) => props.theme.palette.grey[300]};
  border-radius: 8px;
  width: 100%;
  overflow: auto;
  max-height: 100%;
`;

export const ProtocolContainer = styled('div')`
  box-shadow: 0px 2px 8px ${(props) => props.theme.palette.grey[600]};
  padding: 16px;
  border-radius: 8px;
  width: 100%;
  max-height: 100%;
  overflow-y: auto;
`;

export const ProtocolSummaryTableContainer = styled('div')`
  padding: 16px;
  padding-top: 0;
  margin: 8px;
  background-color: ${(props) => props.theme.palette.grey[100]};
  max-height: 500px;
  overflow: auto;
  font-weight: 100;
  p {
    font-weight: 700;
  }
  .MuiSvgIcon-root {
    padding-bottom: 3px;
  }
`;

export const ProtocolSummaryTableInfo = styled('div')`
  position: relative;
  top: 15px;
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  gap: 40px;
  font-weight: 700;
`;

export const StyledProtocolSummaryTable = styled('table')`
  margin-bottom: 10px;
  width: 100%;
  background-color: white;
  & thead {
    font-weight: 700;
  }
  & td {
    padding: 8px;
    border: 1px solid ${(props) => props.theme.palette.grey[300]};
    &.data {
      width: 15%;
    }
  }
  && tbody {
    font-weight: 100;
  }
`;
