// eslint-disable-next-line no-use-before-define
import { Form, Formik, FormikProps, FormikValues } from 'formik';
import { FormContainer } from 'op-components';
import { RegistrationContext } from 'op-contexts';
import React, { useContext } from 'react';
import { generatePath } from 'react-router';
import { useHistory, useParams } from 'react-router-dom';
import { FormRow } from 'shared-components/components';
import {
  CONTINUE_BUTTON_TEXT,
  HeaderSubTitle,
  HeaderTitle,
  linkedField,
  PSO_SUMMARY_LINK,
  registrationPath,
  RETURN_TO_SUBMIT_TEXT,
  sharedFormContainerProps,
  standardField,
  StyledHorizontalLine,
} from '../Helper';
import { PatientContactRego, RegistrationFormUSIProps } from '../interfaces';
import { CONTACT_FIELDS } from './constants';
import { generateValidationSchema } from './validation';

interface ContactDetailsFormIProps extends RegistrationFormUSIProps {
  patient: PatientContactRego;
}

const ContactDetailsForm = (props: ContactDetailsFormIProps): JSX.Element => {
  const { updateField, patient, handleShowSaveExitConfirm, previousPageLink, isPso } = props;

  const registrationContext = useContext(RegistrationContext);
  const { patientId } = useParams<{ patientId: string }>();
  const history = useHistory();
  const goToSummary = registrationContext?.registrationSummaryVisited && !isPso;
  const referringPage = sessionStorage.getItem('referringPage');

  return (
    <Formik
      initialValues={patient}
      validate={(values: FormikValues) => generateValidationSchema(values)}
      validateOnChange={false}
      onSubmit={() => history.push(registrationPath(patientId, goToSummary ? 'summary' : 'address'))}>
      {({ submitForm, setFieldTouched, errors }: FormikProps<PatientContactRego>) => {
        const sharedProps = sharedFormContainerProps('Contact Details', 2, previousPageLink, !isPso);
        const formContainerProps = {
          ...sharedProps,
          submitButtonText: goToSummary ? RETURN_TO_SUBMIT_TEXT : CONTINUE_BUTTON_TEXT,
          continueDisabled: Object.keys(errors).length > 0,
          handleShowSaveExitConfirm,
          saveAndExitLink: isPso && generatePath(referringPage || PSO_SUMMARY_LINK, { patientId }),
        };

        return (
          <FormContainer
            {...formContainerProps}
            submitForm={submitForm}
            handleValidation={(event: React.MouseEvent<HTMLInputElement>): void => {
              void event;
            }}>
            <Form>
              <HeaderTitle data-test-id="us-rego-page-title">{CONTACT_FIELDS.FORM_HEADING.TITLE}</HeaderTitle>
              <HeaderSubTitle data-test-id="us-rego-page-subtitle">* = required field</HeaderSubTitle>

              {linkedField(CONTACT_FIELDS.MOBILE, CONTACT_FIELDS.HOME_PHONE, updateField, setFieldTouched)}
              <FormRow labelClass={'label-form-row'}>
                <StyledHorizontalLine>
                  <span>OR</span>
                </StyledHorizontalLine>
              </FormRow>
              {linkedField(CONTACT_FIELDS.HOME_PHONE, CONTACT_FIELDS.MOBILE, updateField, setFieldTouched)}
              {standardField(CONTACT_FIELDS.EMAIL, updateField)}
              {standardField(CONTACT_FIELDS.EMPLOYER, updateField)}
              {standardField(CONTACT_FIELDS.WORK_PHONE, updateField)}
            </Form>
          </FormContainer>
        );
      }}
    </Formik>
  );
};

export default ContactDetailsForm;
