import React from 'react';
import { styled } from '@mui/system';

const StyledTrigger = styled('button')`
  background-color: ${(props) => props.theme.palette.primary.dark};
  position: absolute;
  cursor: pointer;
  transform: translateX(-100%);
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  color: ${(props) => props.theme.palette.primary.contrastText};
  font-weight: 700;
  text-align: center;
  font-size: 13px;
  border-radius: 4px 0 0 4px;
  border: none;
  text-transform: uppercase;
  width: 64px;
  transition: background-color 0.15s ease-in-out;
  height: 94px;

  &:hover,
  &:focus {
    background-color: ${(props) => props.theme.palette.primary.main};
  }

  svg {
    margin-bottom: 13px;
  }
`;

interface IProps extends React.HTMLAttributes<HTMLButtonElement> {
  onClick?: React.MouseEventHandler<HTMLButtonElement>;
  primaryIcon?: JSX.Element | React.FunctionComponent<React.SVGProps<SVGSVGElement>>;
  statusIcon?: JSX.Element | React.FunctionComponent<React.SVGProps<SVGSVGElement>>;
  showStatusIcon?: boolean;
  text: string | JSX.Element;
}

const SideDrawerToggle = ({
  onClick,
  primaryIcon,
  statusIcon,
  showStatusIcon,
  text,
  ...other
}: IProps): JSX.Element => {
  return (
    <StyledTrigger onClick={onClick} {...other}>
      <>
        {primaryIcon}
        {text}
        {showStatusIcon && statusIcon}
      </>
    </StyledTrigger>
  );
};

export default SideDrawerToggle;
