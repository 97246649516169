// eslint-disable-next-line no-use-before-define
import React, { Component } from 'react';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemText from '@mui/material/ListItemText';
import Divider from '@mui/material/Divider';
import { RouteComponentProps, withRouter } from 'react-router-dom';
import './UKSideBarList.scss';
import ListItemIcon from '@mui/material/ListItemIcon';
import { SvgIcon } from '@mui/material';

interface ListItemObject {
  text: string;
  component: string;
  path: string;
  iconType: string;
}

interface Props extends RouteComponentProps {
  heading: string;
  items: any;
  displayText: boolean;
}

interface State {
  selectedIndex: number;
}

class UKSideBarList extends Component<Props, State> {
  public constructor(props: Props) {
    super(props);
    this.state = {
      selectedIndex: 0,
    };
  }

  private handleListItemClick(event: any, index: number, componentName: string) {
    this.setState({ selectedIndex: index });
    this.props.history.push(componentName);
  }

  public render(): JSX.Element {
    const { items, displayText } = this.props;
    return (
      <div className="list-wrapper">
        <List className="list-body-wrapper">
          {items.map((listItemObj: ListItemObject, index: number) => (
            <ListItem
              selected={this.state.selectedIndex === index}
              onClick={(event) => this.handleListItemClick(event, index, listItemObj.component)}
              button
              key={listItemObj.text}>
              <ListItemIcon>
                <SvgIcon viewBox="0 0 32 32">
                  <path d={listItemObj.path} />
                </SvgIcon>
              </ListItemIcon>
              <ListItemText style={{ display: displayText ? 'flex' : 'none' }} primary={listItemObj.text} />
            </ListItem>
          ))}
        </List>
        <Divider />
      </div>
    );
  }
}

export default withRouter(UKSideBarList);
