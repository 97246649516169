import {
  TextAreaField,
  ToggleButtonGroupField,
  SelectField,
  SelectFieldCustomFooter,
} from 'shared-components/components/FormikComponents';

export const FIELDS = {
  FORM_HEADING: {
    NAME: 'formHeading',
    TITLE: 'Demographics',
  },
  COUNTRY_OF_BIRTH: {
    NAME: 'countryOfBirth',
    TITLE: 'Country of birth*',
    PLACE_HOLDER: 'Please select an option',
    COMPONENT: SelectFieldCustomFooter,
  },
  LANGUAGE_AT_HOME: {
    NAME: 'languageAtHome',
    TITLE: 'Language spoken at home*',
    PLACE_HOLDER: 'Please select an option',
    COMPONENT: SelectFieldCustomFooter,
  },
  INTERPRETER: {
    NAME: 'interpreterRequired',
    TITLE: 'Do you require an interpreter?',
    COMPONENT: ToggleButtonGroupField,
  },
  OCCUPATION: {
    NAME: 'occupation',
    TITLE: 'What is your primary occupation?',
    PLACE_HOLDER: '',
    COMPONENT: TextAreaField,
  },
  HERITAGE: {
    NAME: 'heritage',
    TITLE: 'Are you of Aboriginal or Torres Strait Islander heritage?*',
    PLACE_HOLDER: 'Please select an option',
    COMPONENT: SelectFieldCustomFooter,
  },
  MARITAL_STATUS: {
    NAME: 'maritalStatus',
    TITLE: 'Marital status*',
    PLACE_HOLDER: 'Please select an option',
    COMPONENT: SelectFieldCustomFooter,
  },
  AMBULANCE_MEMBERSHIP: {
    NAME: 'ambulanceMembership',
    TITLE: 'Do you have an ambulance membership?',
    COMPONENT: ToggleButtonGroupField,
  },
  AMBULANCE_MEMBERSHIP_NUMBER: {
    NAME: 'ambulanceMembershipNumber',
    TITLE: 'Ambulance membership number',
    COMPONENT: TextAreaField,
  },
};
