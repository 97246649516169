// eslint-disable-next-line no-use-before-define
import { useEffect } from 'react';
import { useQuery } from '@apollo/client';
import { useRouteMatch } from 'react-router';
import { useHistory } from 'react-router-dom';
import OuterContainer from '../PatientSummary/OuterContainer';
import { Typography, Stack, AccordionDetails } from '@mui/material';
import { AddCircleOutline as AddCircleOutlineIcon, Circle as CircleIcon } from '@mui/icons-material';
import { useMutation } from '@apollo/client';
import { useErrorModalContext } from 'op-contexts';
import { ROPatientContextProvider } from '../PatientSummary/context';
import { GET_OUTCOMES_BY_DIAGNOSIS, CREATE_OUTCOME } from './Queries';
import { OutcomesWrapper, OutcomesContainer } from './StyledComponents';
import { LoadingSpinner } from 'shared-components/components';
import { OutcomeInterface } from './Interfaces';
import { Accordion, AccordionSummary } from '../OnTreatmentReview/Accordion';
import TimePointCard from '../OnTreatmentReview/ReviewCard';
import dayjs from 'dayjs';

const Status = ({ status, ...props }: { status: string }): JSX.Element => {
  const colourMap: { [status: string]: 'warning' | 'success' | 'secondary' } = {
    'In Progress': 'warning',
    'Unsubmitted changes': 'warning',
    Submitted: 'success',
    'Not started': 'secondary',
  };

  return (
    <Stack direction="row" gap={1} alignItems="center" {...props}>
      <CircleIcon fontSize="small" color={colourMap[status]} />
      <Typography variant="body1">{status}</Typography>
    </Stack>
  );
};

interface ROPatientOutcomesNavType {
  id: string;
}

const OutcomesPage = (): JSX.Element => {
  const history = useHistory();

  const match = useRouteMatch<ROPatientOutcomesNavType>();
  const { id: patientId } = match.params;
  const { setError } = useErrorModalContext();

  const {
    data: diagnosisListData,
    loading: diagnosisListLoading,
    error: diagnosisListError,
  } = useQuery(GET_OUTCOMES_BY_DIAGNOSIS, {
    variables: { patientId },
    fetchPolicy: 'network-only',
  });

  const [createOutcome, { error: createOutcomeError }] = useMutation(CREATE_OUTCOME, {
    refetchQueries: [{ query: GET_OUTCOMES_BY_DIAGNOSIS, variables: { patientId: patientId } }],
  });

  useEffect(() => {
    if (diagnosisListError || createOutcomeError) return setError();
  }, [diagnosisListError, createOutcomeError]);

  const handleCreateOutcome = (diagnosisId: string): void => {
    createOutcome({
      variables: {
        diagnosisId,
      },
    })
      .then((response) => {
        // Navigate to form after creating a new custom time point
        const outcomeId = response?.data?.createOutcome?.outcome?.id;

        if (outcomeId) {
          history.push(`/radiation/patient/${patientId}/outcomes/${outcomeId}`, {
            diagnosisId,
          });
        }
      })
      .catch(() => {
        setError();
      });
  };

  return (
    <ROPatientContextProvider>
      <OuterContainer>
        <OutcomesWrapper style={{ height: '720px' }}>
          <OutcomesContainer>
            {diagnosisListLoading ? (
              <LoadingSpinner
                loadingText={'Loading Outcomes Dashboard'}
                subtitle={'Please wait while we set things up for you'}
              />
            ) : (
              <Stack gap={2}>
                <Typography variant="h6" color="primary">
                  Outcomes
                </Typography>
                <Stack gap={1}>
                  {diagnosisListData?.outcomesByDiagnosis?.length > 0 ? (
                    diagnosisListData.outcomesByDiagnosis.map(
                      ({
                        diagnosis,
                        diagnosisId,
                        diagnosisDate,
                        outcomes,
                      }: { [key: string]: any } & {
                        outcomes: OutcomeInterface[];
                      }) => (
                        <Accordion defaultExpanded key={diagnosisId}>
                          <AccordionSummary>
                            <Stack direction="row" spacing={4} alignItems="center">
                              <Typography variant="subtitle1">{diagnosis}</Typography>
                              <Typography variant="body1">Primary Diagnosis Date: {diagnosisDate}</Typography>
                            </Stack>
                          </AccordionSummary>
                          <AccordionDetails sx={{ paddingX: 0 }}>
                            <Stack direction="row" gap={2} flexWrap="wrap">
                              <TimePointCard
                                data-testid="add-outcome"
                                onClick={() => {
                                  handleCreateOutcome(diagnosisId);
                                }}>
                                <Stack direction="column" spacing={1}>
                                  <Typography variant="body1">Add time point</Typography>
                                  <AddCircleOutlineIcon fontSize="medium" color="primary" />
                                </Stack>
                              </TimePointCard>
                              {outcomes
                                .map((outcome) => ({
                                  followUpDate: dayjs(
                                    outcome.formInstance.values.find((value) => value.field === 'followUpDate')
                                      ?.value ?? outcome.createdAt,
                                  ),
                                  outcome,
                                }))
                                .sort((a, b) => {
                                  const first = a.followUpDate.isValid() ? a.followUpDate : dayjs(a.outcome.createdAt);
                                  const second = b.followUpDate.isValid() ? b.followUpDate : dayjs(b.outcome.createdAt);
                                  return first.diff(second);
                                })
                                .map(({ outcome, followUpDate }) => {
                                  const goToOutcome = () => {
                                    const outcomeId = outcome.id;
                                    history.push(`/radiation/patient/${patientId}/outcomes/${outcomeId}`, {
                                      diagnosisId,
                                    });
                                  };
                                  return (
                                    <TimePointCard
                                      onClick={goToOutcome}
                                      data-testid={`outcome-${outcome.id}`}
                                      key={outcome.id}>
                                      <Stack direction="column" spacing={1}>
                                        <Typography variant="subtitle1" data-testid="fraction">
                                          Follow up:{' '}
                                          {followUpDate.isValid()
                                            ? followUpDate.format('DD-MMM-YYYY')
                                            : 'date not entered'}
                                        </Typography>
                                        <Typography variant="subtitle2" data-testid="review-date">
                                          Last modified: {dayjs(outcome.lastModified).format('DD-MMM-YYYY')}
                                        </Typography>
                                        <Status status={outcome.status} data-testid="status" />
                                      </Stack>
                                    </TimePointCard>
                                  );
                                })}
                            </Stack>
                          </AccordionDetails>
                        </Accordion>
                      ),
                    )
                  ) : (
                    <Typography variant="body1">No diagnosis recorded, therefore no follow up available.</Typography>
                  )}
                </Stack>
              </Stack>
            )}
          </OutcomesContainer>
        </OutcomesWrapper>
      </OuterContainer>
    </ROPatientContextProvider>
  );
};

export default OutcomesPage;
