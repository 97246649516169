import React from 'react';
import { DrugOrderScheduleInterface, CycleDayInterface, DoseDay, EmptyColumnDoseDay } from '../interfaces';
import { getDrugName } from '../../../utils';
import { DoseCellContainer, DrugNameContainer, EmptyContainer } from './StyledComponents';
import { Pill } from './Pill';
import { Draggable } from 'react-beautiful-dnd';
import { ReactiveTruncatedText } from 'shared-components/components/ReactiveTruncatedText/ReactiveTruncatedText';
import { DRUG_CATEGORY } from '../../Constants';
import { IconButton, Theme } from '@mui/material';
import { DeleteOutline, DragIndicator } from '@mui/icons-material';
import DoseCell from './DoseCell';
import { useTheme } from '@mui/material';

const getCardStyle = (isDragging: boolean, draggableStyle: any, theme: Theme) => ({
  background: isDragging ? theme.palette.info.light : 'white',
  ...draggableStyle,
});

interface DrugRowProps {
  drugOrder: DrugOrderScheduleInterface;
  drugOrderIndex: number;
  drugDeleted: boolean;
  openDeleteDrugOrder: any;
  selectedCycle: number;
  closestCycleDay: CycleDayInterface | undefined;
  todayInCycle: CycleDayInterface | undefined;
  openDoseEdit: any;
}

const DrugRow = ({
  drugOrder,
  drugOrderIndex,
  drugDeleted,
  openDeleteDrugOrder,
  selectedCycle,
  closestCycleDay,
  todayInCycle,
  openDoseEdit,
}: DrugRowProps): JSX.Element => {
  const drugName = getDrugName(drugOrder);
  const rowIdKey = `${drugOrder.drugOrderId}`;
  const theme = useTheme();
  return (
    <Draggable isDragDisabled={drugOrder.dragDisabled} key={rowIdKey} draggableId={rowIdKey} index={drugOrderIndex}>
      {(provided, snapshot) => (
        <tr
          key={rowIdKey}
          ref={provided.innerRef}
          {...provided.draggableProps}
          {...provided.dragHandleProps}
          style={getCardStyle(snapshot.isDragging, provided.draggableProps.style, theme)}>
          <td style={{ backgroundColor: drugOrder.dragDisabled ? '' : theme.palette.primary.light }}>
            <DrugNameContainer data-cy={`cycle-table-drug-name-${drugOrderIndex}`}>
              <ReactiveTruncatedText id={drugOrderIndex} text={drugName} />
              {drugOrder.type === 'Take At Home' && (
                <Pill backgroundColor={'neutral'} fontColor={'information'} width={'130px'}>
                  Discharge Med
                </Pill>
              )}

              {drugOrder.drugCategory === DRUG_CATEGORY.SUPPORTING && !drugDeleted && (
                <IconButton
                  style={{ padding: 0 }}
                  data-cy="drug-order-delete"
                  onClick={() => {
                    const doseDay = drugOrder.doseDays.find((doseDay) => doseDay.dose) || drugOrder.doseDays[0];
                    openDeleteDrugOrder(
                      {
                        doseDay,
                        drugOrder,
                        cycle: selectedCycle,
                      },
                      closestCycleDay?.day,
                    );
                  }}
                  size="large">
                  <DeleteOutline />
                </IconButton>
              )}
              {!drugOrder.dragDisabled && <DragIndicator />}
            </DrugNameContainer>
          </td>
          {drugOrder.doseDays.map((day: DoseDay | EmptyColumnDoseDay, doseIndex) => {
            if (day.isEmptyColumn) {
              return (
                <td className={'empty'} key={doseIndex} data-cy={`dose-cell-${drugOrderIndex}-${doseIndex}`}>
                  <EmptyContainer />
                </td>
              );
            } else {
              return (
                <td
                  className={day.day === todayInCycle?.day ? 'currentDate' : ''}
                  key={doseIndex}
                  data-cy={`dose-cell-${drugOrderIndex}-${doseIndex}`}>
                  <DoseCellContainer>
                    <DoseCell
                      justText={!drugOrder.dragDisabled}
                      day={day}
                      drugOrder={drugOrder}
                      onPillClick={() => {
                        !day.dose?.doseAdjustment?.isDoseDeleted &&
                          openDoseEdit({
                            doseDay: day,
                            drugOrder,
                            cycle: selectedCycle,
                          });
                      }}
                    />
                  </DoseCellContainer>
                </td>
              );
            }
          })}
        </tr>
      )}
    </Draggable>
  );
};

export default DrugRow;
