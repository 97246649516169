import React, { useContext } from 'react';
import { Field, useFormikContext } from 'formik';

import { FormRow, HelperMessage, Divider } from 'shared-components/components';
import { ToggleButtonGroupField } from 'shared-components/components/FormikComponents';

import { getLastUpdateUser } from '../../../helpers';
import { LastUpdateUserDetails } from '../../../shared/interfaces';
import { preCtChartCheckContent, yesNKAOptions } from '../../constants';
import FormContext from '../../contexts';
import AllergyCardGroupField from './AllergyCardGroupField';

interface Props {
  assessmentId: string;
  handleMutation: any;
  lastUpdateUserData: LastUpdateUserDetails[];
}

const Allergies = ({ assessmentId, handleMutation, lastUpdateUserData }: Props): JSX.Element => {
  const { setFieldValue, setFieldTouched, values }: any = useFormikContext();
  const formContext = useContext(FormContext);
  const { createAllergy, deleteAllergy } = formContext.mutations;

  const { hasPatientAllergies, pcccAllergy } = preCtChartCheckContent.allergies.fields;
  const { infoHelpers, actionLabels } = preCtChartCheckContent.allergies;

  const conditionalHandleChange = (value: boolean) => {
    if (value === true && values.pcccAllergy.length === 0) {
      createAllergy({
        variables: { assessmentId, allergyType: '', additionalDetails: '' },
      }).then(({ data }: { data: any }) => {
        const firstAllergy = data.createPcccAllergy.preCtChartCheck.pcccAllergy[0];
        setFieldValue('pcccAllergy', [firstAllergy]);
      });
    }
    if (value === false && values.pcccAllergy.length > 0) {
      values.pcccAllergy.forEach((item: any) => {
        deleteAllergy({ variables: { id: item.id } });
      });
      setFieldValue('pcccAllergy', []);
      setFieldTouched('pcccAllergy', []);
    }
  };

  return (
    <>
      <FormRow fieldLabel={hasPatientAllergies} fieldName={'hasPatientAllergies'}>
        <Field
          name="hasPatientAllergies"
          component={ToggleButtonGroupField}
          label={hasPatientAllergies}
          options={yesNKAOptions}
          handleMutation={handleMutation}
          conditionalHandleChange={conditionalHandleChange}
          lastUpdateUser={getLastUpdateUser(lastUpdateUserData, 'hasPatientAllergies')}
        />
        {values.hasPatientAllergies === false && (
          <HelperMessage fieldName={'hasPatientAllergies'} fieldText={infoHelpers.allergyAlert} helperType="info" />
        )}
      </FormRow>
      {values.hasPatientAllergies === true && (
        <>
          <Divider />
          <AllergyCardGroupField
            name="pcccAllergy"
            label={pcccAllergy}
            actionButtonLabel={actionLabels.addAllergy}
            assessmentId={assessmentId}
          />
        </>
      )}
    </>
  );
};

export default Allergies;
