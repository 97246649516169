import React, { useState, useEffect, useContext } from 'react';
import { styled } from '@mui/system';
import RODoseCard, { CardValue, CardStatusHeader } from '../DoseCard/DoseCard';
import moment from 'moment';
import { doseRounding, groupBy, sortByNewestToOldest, computeGroupTotal, display } from 'op-utils';
import lodash from 'lodash';
import { Stack, Slide } from '@mui/material';
import { ChevronLeft as ChevronLeftIcon, ChevronRight as ChevronRightIcon } from '@mui/icons-material';
import { CurrentAppConfig } from 'op-pages/RO/Careplan/AppConfig';
import { TenantContext } from 'op-contexts';
const COMPLETED_EARLY = 'CompletedEarly';

interface RODoseSiteCarouselProps {
  handleSelect(selectedIndex: number): any;
  index: number;
  doseSize: number;
  ariaDoses?: Array<any>;
  mqDoses?: Array<any>;
}

const StyledCarousel = styled(Stack)`
  color: ${(props) => props.theme.palette.text.primary};
  border-radius: 4px;
  height: 100%;
  overflow: auto;
  background-color: white;

  .carousel-inner {
    overflow: auto !important;
  }
`;

const StyledEmptyCol = styled(Stack)`
  height: 100%;
  display: flex;
  flex-direction: column;
  padding: 12px 0;
  padding-left: 8px;
`;

const StyledDDSCol = styled(Stack)`
  overflow-y: auto;
`;

const StyledContentRow = styled(Stack)`
  padding: 0 15px;
`;

const SiteLabel = styled('div')`
  font-size: 18px;
  line-height: 28px;
  align-items: center;
  text-align: center;
`;

const TotalDoseRow = styled(Stack)`
  padding: 10px;
  align-items: center;
  border: 1px solid ${(props) => props.theme.palette.grey[300]};
  border-radius: 4px;
  margin: 0px 0px 8px 0px;
`;

const TotalDoseCol = styled(Stack)`
  display: flex;
  align-items: center;
  justify-content: center;
`;

const RODoseSiteCarousel = (props: RODoseSiteCarouselProps): JSX.Element => {
  const { index: parentIndex, handleSelect, mqDoses, ariaDoses, doseSize } = props;
  const [index, setIndex] = useState(parentIndex);
  const [previousIndex, setPreviousIndex] = useState(parentIndex);
  const tenantContext = useContext(TenantContext);
  const doseUnit = tenantContext?.doseUnit;

  useEffect(() => {
    setPreviousIndex(index);
    setIndex(parentIndex);
  }, [parentIndex]);

  const hideChevron = doseSize <= 1;
  const titleHeader = (siteName: string) => (
    <Stack
      sx={{
        display: 'flex',
        flexDirection: 'row',
        justifyContent: hideChevron ? 'center' : 'space-between',
        margin: '0 15px 0',
      }}>
      {hideChevron ? null : index ? (
        <ChevronLeftIcon
          color="primary"
          data-test-id="prev-dose-arrow"
          style={{ cursor: 'pointer' }}
          onClick={() => {
            if (index) handleSelect(index - 1);
          }}
        />
      ) : (
        <ChevronLeftIcon color="disabled" />
      )}
      <SiteLabel data-test-id={`dose-${siteName}-header`}>{siteName}</SiteLabel>
      {hideChevron ? null : index + 1 < doseSize ? (
        <ChevronRightIcon
          color="primary"
          data-test-id="next-dose-arrow"
          style={{ cursor: 'pointer' }}
          onClick={() => {
            if (index + 1 < doseSize) handleSelect(index + 1);
          }}
        />
      ) : (
        <ChevronRightIcon color="disabled" />
      )}
    </Stack>
  );

  const formatDate = (date: string) => {
    return date ? moment(date).format(CurrentAppConfig.DoseSiteSummary.DateFormat) : '-';
  };

  const renderAriaCard = (dose: any, idx: number) => {
    const actualDose = dose.deliveredDosageQuantity || 0;
    const remainingDose = dose.remainingDoseQuantity || 0;
    const totalDose = actualDose + remainingDose;
    const actualFraction = dose.deliveredDosageRatio || 0;
    const totalFraction = dose.plannedDosageRatio || 0;

    // Ongoing until proven otherwise
    let status = 'Ongoing';

    if (dose.statusReason === COMPLETED_EARLY) {
      status = 'Completed early';
    } else if (remainingDose === 0.0) {
      status = 'Completed';
    }

    const lastTreatment = formatDate(dose.latestTreatment);
    const endDate = status.startsWith('Complete') ? lastTreatment : 'See Appointments';

    const attr = {
      status: status,
      source: 'ARIA',
      course: display(dose.medicationReference),
      actualDose: `${doseRounding(actualDose, true, tenantContext)}${doseUnit}/${actualFraction}#`,
      totalDose: `${doseRounding(totalDose, true, tenantContext)}${doseUnit}/${totalFraction}#`,
      startDate: formatDate(dose.firstTreatment),
      endDate: endDate,
      lastTreatment: formatDate(dose.latestTreatment),
      siteName: dose.identifierSite,
      technique: display(dose.technique),
    };
    return <RODoseCard key={idx} attribute={attr} />;
  };

  const createDoseGroup = (groupName: string, doses: Array<any>) => {
    const doseGroupData = sortByNewestToOldest(doses);
    const showTotal = doseGroupData.length > 1;
    const { totalDeliveredDose, totalDoseMax } = computeGroupTotal(doseGroupData);
    const doseGroupCards = doseGroupData.map((dose: any, idx: number) => renderAriaCard(dose, idx));

    return {
      groupName: groupName,
      totalDeliveredDose: totalDeliveredDose,
      totalDoseMax: totalDoseMax,
      isAria: true,
      sortDate: doseGroupData[0].sortDate,
      showTotal: showTotal,
      doseGroupCards: doseGroupCards,
    };
  };

  const formatDoses = () => {
    const modifiedMqDoses = lodash.cloneDeep(mqDoses)?.map((element: any) => {
      element.sortDate = moment(element.latestTreatment).format(CurrentAppConfig.DoseSiteSummary.DateFormat);
      return element;
    });
    const modifiedAriaDoses = lodash.cloneDeep(ariaDoses)?.map((element: any) => {
      element.sortDate = moment(element.latestTreatment).format(CurrentAppConfig.DoseSiteSummary.DateFormat);
      return element;
    });

    const ariaDoseObj = groupBy(modifiedAriaDoses, 'categorySiteGroup');
    const formatedAria: Array<any> = [];
    Object.entries(ariaDoseObj).forEach((element: any) => {
      formatedAria.push(createDoseGroup(element[0], element[1]));
    });
    const combinedDoses = modifiedMqDoses?.concat(formatedAria);
    return sortByNewestToOldest(combinedDoses);
  };

  const renderGroupTotal = (actualDose: any, totalDose: any) => (
    <TotalDoseRow>
      <TotalDoseCol>
        <CardStatusHeader>Actual/Total</CardStatusHeader>
        <CardValue>
          {actualDose}
          {doseUnit}/{totalDose}
          {doseUnit}
        </CardValue>
      </TotalDoseCol>
    </TotalDoseRow>
  );

  const renderAriaGroup = (dose: any, idx: number) => {
    return (
      <Stack key={idx}>
        {titleHeader(dose.groupName)}
        <StyledContentRow>
          <StyledDDSCol style={{ display: 'flex', height: '100%' }}>
            {dose.showTotal && renderGroupTotal(dose.totalDeliveredDose, dose.totalDoseMax)}
            {dose.doseGroupCards}
          </StyledDDSCol>
        </StyledContentRow>
      </Stack>
    );
  };

  const renderMqDose = (dose: any, idx: number) => {
    if (!dose) return <></>;
    const actualDose = doseRounding(dose.deliveredDosageQuantity, false, tenantContext);
    const totalDose = doseRounding(dose.plannedDosageQuantity, false, tenantContext);
    const actualFraction = dose.deliveredDosageRatio || 0;
    const totalFraction = dose.plannedDosageRatio || 0;
    const status = actualFraction === totalFraction ? 'Complete' : 'Ongoing';

    const attr = {
      status: status,
      source: 'MOSAIQ',
      course: display(dose.medicationReference),
      actualDose: `${actualDose}${doseUnit}/${actualFraction}#`,
      totalDose: `${totalDose}${doseUnit}/${totalFraction}#`,
      startDate: formatDate(dose.firstTreatment),
      endDate: formatDate(dose.endDate),
      lastTreatment: formatDate(dose.latestTreatment),
      siteName: dose.identifierSite,
      technique: display(dose.technique),
    };
    return (
      <Stack key={idx}>
        {titleHeader(dose.identifierSite)}
        <StyledContentRow>
          <StyledDDSCol style={{ display: 'flex', height: '100%' }}>
            <RODoseCard attribute={attr} />
          </StyledDDSCol>
        </StyledContentRow>
      </Stack>
    );
  };

  const renderAllCards = () => {
    const orderedDose = formatDoses();
    return orderedDose.map((element: any, idx: any) => {
      const direction = previousIndex < index ? 'left' : 'right';
      const show = idx === index;
      return (
        <Slide direction={direction} in={show} key={`slide-${idx}`} appear={false}>
          {!show ? <div></div> : !element?.isAria ? renderMqDose(element, idx) : renderAriaGroup(element, idx)}
        </Slide>
      );
    });
  };
  if (!doseSize) return <StyledEmptyCol>Treatment has not started yet.</StyledEmptyCol>;
  return (
    <StyledCarousel data-testid="dose-site-carousel" sx={{ overflowY: 'auto', overflowX: 'hidden' }}>
      {renderAllCards()}
    </StyledCarousel>
  );
};

export default RODoseSiteCarousel;
