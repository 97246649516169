// eslint-disable-next-line no-use-before-define
import React, { Component } from 'react';
import classnames from 'classnames';

interface Props {
  id?: string;
  className?: string;
  children: React.ReactNode;
  isCompact?: boolean;
}

class ROContentContainerBody extends Component<Props> {
  public render(): JSX.Element {
    const { id, isCompact, className = '' } = this.props;
    return (
      <div
        id={id}
        className={classnames('content-body-container', className, {
          compact: isCompact,
        })}>
        {this.props.children}
      </div>
    );
  }
}

export default ROContentContainerBody;
