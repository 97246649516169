export const patientSummaryLink = '/patient/:patientId/summary';
export const managementDashboardLink = '/patient/:patientId/management';

export enum SECTIONS {
  MANAGEMENT = 'management',
  CONSULTATION = 'consultation',
  REFERRAL = 'referral',
}

export const formRoutes = {
  consultation: {
    sectionTitle: 'Consultation',
    path: '/patient/:patientId/management/:formId/consultation',
    name: 'consultation',
  },
  referral: {
    sectionTitle: 'Referral & existing services',
    path: '/patient/:patientId/management/:formId/referral',
    name: 'referral',
  },
};
