import { styled } from '@mui/system';
import { Accordion } from 'shared-components/components';
import { Careplan } from './Interface';
import { Logger } from 'shared-components/utils';

export interface IsShownValueField {
  value?: string;
  isShown?: boolean;
}

export const isFieldVisible = (value: IsShownValueField): boolean => {
  if (value && value.isShown) {
    return true;
  }
  return false;
};

export const setYesNo = (value: string): string => {
  if (value === ('true' || 'yes' || '1')) return 'yes';
  if (value === ('false' || 'no' || '0')) return 'no';
  return '';
};

const sortIntegerArrayToStrings = (values: any[]): string[] => {
  const sortedInts = [...values].sort((a, b) => parseFloat(a) - parseFloat(b));
  return sortedInts.map(String);
};

export const alphabeticalOrder = (input: any[], attr: any): any[] => {
  /*
    Provide an array of objects to be sorted alphabetically.

    Use the attr parameter when comparing attribute values.
      For example, User.name

    Don't provide an attr value when comparing the base value
      For example, User
  */
  const num_inputs: number[] = [];
  const string_inputs: string[] = [];

  input.forEach((value: any) => {
    if (!isNaN(parseFloat(value))) {
      num_inputs.push(value);
    } else {
      string_inputs.push(value);
    }
  });

  const sortedIntegerInputs = sortIntegerArrayToStrings(num_inputs);

  // Checks if input is an object with an attribute before sorting
  const sortedStringInputs = [...string_inputs].sort((a: any, b: any) => {
    return attr && a.hasOwnProperty(attr) && b.hasOwnProperty(attr)
      ? a[attr].localeCompare(b[attr])
      : a.toString().localeCompare(b);
  });

  return sortedIntegerInputs.concat(sortedStringInputs);
};

export const getSiteName = (site: any): string => {
  let siteName = site.treatmentSite.treatmentSite;
  if (site.treatmentSite.treatmentSite.toLowerCase().includes('specify')) {
    const location = site.sitevaluesSet.filter((values: any): any => values.field.name === 'Location');
    if (location[0]) {
      siteName = `${siteName} (${JSON.parse(location[0].value)})`;
    }
  }
  return siteName;
};

export const getTreatmentSiteWithLocation = (site: any): string =>
  site.treatmentSite
    ? CleanSpecifyFromSiteName(
        isSpecifySite(site.treatmentSite) ? `${site.treatmentSite} (${site.location})` : site.treatmentSite,
      )
    : '';

export const getTreatmentSiteHeader = (site: any): string =>
  site.treatmentSite ? getTreatmentSiteWithLocation(site) + ` (${site.laterality})` : '';

export const extractValueFromSite = (site: any, name: string): string[] | string => {
  const siteValue = site.sitevaluesSet.filter((values: any): any => values.field.name.toLowerCase() === name);
  return siteValue[0] ? JSON.parse(siteValue[0].value) : '';
};

export const cleanDoseValue = (dose: string | number): string => {
  if (typeof dose === 'string') {
    const number = parseFloat(dose);
    return !isNaN(number) && number % 1 === 0 ? number.toString().replace(/\.0$/, '') : dose.toString();
  } else if (typeof dose === 'number') {
    return dose.toString();
  }
  return dose;
};

export const getSiteRepresentation = (site: any): string => {
  const siteName = CleanSpecifyFromSiteName(getSiteName(site));
  const technique = extractValueFromSite(site, 'technique');
  const dose = extractValueFromSite(site, 'dose');
  const fractions = extractValueFromSite(site, 'fractions');
  const cleanedDose = typeof dose === 'string' ? cleanDoseValue(dose) : dose?.map(cleanDoseValue);
  const doseString = fractions ? cleanedDose + '/' + fractions : cleanedDose;

  return [siteName, technique, doseString].filter(Boolean).join(' ');
};

export const templatesWithValues = (templateSet: any) => templateSet.filter((item: any) => Boolean(item.siteSet[0]));

export const CleanSpecifyFromSiteName = (site: string): string => {
  // Determine whether there is a specified site
  // Group 1: Specify - (location)
  // Group 2: location

  const expression = /\w+\s-\s(Specify\s\((.*)\))/;

  const matchedExpression = site.match(expression);

  // No match was found, specify doesn't exist in this site - no manipulation will take place
  if (matchedExpression === null) {
    return site;
  }

  // Replace group 1 with group 2
  const group1 = matchedExpression[1];
  const group2 = matchedExpression[2];

  return site.replace(group1, group2);
};

export const ScrollToTop = (document: Document): void => {
  const header = document.getElementsByClassName('page-header');
  if (header && header.length >= 1) {
    header[0].scrollIntoView({
      behavior: 'smooth',
      block: 'center',
      inline: 'start',
    });
  }
};

export const redirectCareplanPath = (careplan: Careplan, patientId: string): string => {
  const { id, latestPageUrl, careplanStatus, simulationModification, prescriptionModification, diagnosisModification } =
    careplan;

  const mapPageToStatus = {
    SIMULATION: 'simulation',
    PLAN_AIM: 'prescription',
    PRESCRIPTION: 'submission',
    PRESCRIPTION_DIRECTIVE: 'prescription',
  };
  const redirectToLastPage =
    careplanStatus === CAREPLAN_STATUS.draft ||
    simulationModification ||
    prescriptionModification ||
    diagnosisModification ||
    careplanStatus === CAREPLAN_STATUS.cpot;
  const path = redirectToLastPage
    ? latestPageUrl
    : //@ts-ignore
      `/radiation/patient/${patientId}/careplan/${id}/${mapPageToStatus[careplanStatus]}`;
  return path;
};

export const removeUnicodeQuotes = (value: string): string => {
  return value?.replace(/[\u201c\u201d]/g, '"');
};

export const CAREPLAN_STATUS = {
  planAim: 'PLAN_AIM',
  prescriptionDirective: 'PRESCRIPTION_DIRECTIVE',
  prescription: 'PRESCRIPTION',
  simulation: 'SIMULATION',
  draft: 'DRAFT',
  deleted: 'DELETED',
  cpot: 'CPOT',
  voided: 'VOIDED',
};

export enum CONDITIONAL_FIELDS {
  ABDOMEN_COMPRESSION = 'abdomenCompression',
  BLADDER = 'bladder',
  BOLUS = 'bolus',
  BOLUS_LOCATION = 'treatmentBolusLocation',
  BOLUS_THICKNESS = 'treatmentBolusThickness',
  BOLUS_THICKNESS_CUSTOM = 'treatmentBolusThicknessCustom',
  BOLUS_FREQUENCY = 'treatmentBolusFrequency',
  BOWEL_PREP = 'bowelPrep',
  BREATH_HOLD = 'breathHold',
  DIBH = 'dibh',
  FOUR_DCT = 'fourDct',
  GATING = 'gating',
  GOLD_SEEDS = 'goldSeeds',
  MASK = 'mask',
  MOUTH_PIECE = 'mouthpiece',
  SPACE_OAR = 'spacerGel',
  STOMACH = 'stomach',
  TONGUE_POSITION = 'tonguePosition',
  TREATMENT_BREAK = 'treatmentBreak',
  TREATMENT_BREAK_DURATION = 'treatmentBreakDuration',
  TREATMENT_BREAK_FRACTION = 'treatmentBreakFraction',
  VAGINAL_TAMPON = 'vaginalTampon',
}

export const CONDITIONAL_FIELD_MAP = {
  bolus: 'Bolus',
  bolusLocation: 'Bolus_Location',
  bolusThickness: 'Bolus_Thickness',
  bolusThicknessCustom: 'Bolus_Thickness_Custom',
  bolusFrequency: 'Bolus_Frequency',
  treatmentBreak: 'Treatment_Break',
  treatmentBreakDuration: 'Treatment_Break_Duration',
  treatmentBreakFraction: 'Treatment_Break_Fraction',
  bladder: 'Bladder',
  bowelPrep: 'Bowel_Prep',
  spacerGel: 'Spacer_Gel',
  goldSeeds: 'Gold_Seeds',
  stomach: 'Stomach',
  abdomenCompression: 'Abdomen_Compression',
  tonguePosition: 'Tongue_Position',
  mask: 'Mask',
  mouthpiece: 'Mouthpiece',
  gating: 'Gating',
  vaginalTampon: 'Vaginal_Tampon',
  fourDct: 'Four_DCT',
  breathHold: 'Breath_Hold',
  dibh: 'DIBH',
};

export const mapConditionalFields = (map: { [key: string]: string }, fieldNameArr: string[]): string[] => {
  return fieldNameArr.map((fieldName) => map[fieldName]);
};

export const MANUAL_SITE_TUMOUR_STREAM_MAP_V2 = ((): { [key: string]: string[] } => {
  const {
    ABDOMEN_COMPRESSION,
    BLADDER,
    BOLUS,
    BOLUS_FREQUENCY,
    BOLUS_LOCATION,
    BOLUS_THICKNESS,
    BOLUS_THICKNESS_CUSTOM,
    BOWEL_PREP,
    BREATH_HOLD,
    FOUR_DCT,
    GATING,
    GOLD_SEEDS,
    MASK,
    MOUTH_PIECE,
    SPACE_OAR,
    STOMACH,
    TONGUE_POSITION,
    TREATMENT_BREAK,
    TREATMENT_BREAK_DURATION,
    TREATMENT_BREAK_FRACTION,
    VAGINAL_TAMPON,
  } = CONDITIONAL_FIELDS;

  const BOLUS_FIELDS = [BOLUS, BOLUS_FREQUENCY, BOLUS_LOCATION, BOLUS_THICKNESS, BOLUS_THICKNESS_CUSTOM];
  const TREATMENT_BREAK_FIELDS = [TREATMENT_BREAK, TREATMENT_BREAK_DURATION, TREATMENT_BREAK_FRACTION];

  return {
    'GI, Colo-rectal + Abdo': [...BOLUS_FIELDS, ABDOMEN_COMPRESSION, BLADDER, BOWEL_PREP, FOUR_DCT, STOMACH],
    Breast: [...BOLUS_FIELDS, BREATH_HOLD, FOUR_DCT],
    Skin: [...BOLUS_FIELDS, ...TREATMENT_BREAK_FIELDS],
    'H&N': [...BOLUS_FIELDS, MASK, TONGUE_POSITION, MOUTH_PIECE],
    Miscellaneous: [...BOLUS_FIELDS, MASK],
    Gynae: [...BOLUS_FIELDS, BLADDER, BOWEL_PREP, VAGINAL_TAMPON],
    Metastasis: [...BOLUS_FIELDS, FOUR_DCT, GATING, MASK],
    'Sarcoma - Soft Tissue': [...BOLUS_FIELDS],
    Benign: [...BOLUS_FIELDS],
    Haematological: [...BOLUS_FIELDS, MASK],
    Brain: [MASK],
    Urogenital: [BLADDER, BOWEL_PREP, GOLD_SEEDS, SPACE_OAR],
    Lung: [GATING, BREATH_HOLD, FOUR_DCT],
    'Skin - Melanoma': [],
    General: [],
  };
})();

export const MANUAL_SITE_TUMOUR_STREAM_MAP_V1 = ((): { [key: string]: string[] } => {
  const {
    ABDOMEN_COMPRESSION,
    BLADDER,
    BOLUS,
    BOLUS_FREQUENCY,
    BOLUS_LOCATION,
    BOLUS_THICKNESS,
    BOLUS_THICKNESS_CUSTOM,
    BOWEL_PREP,
    DIBH,
    FOUR_DCT,
    GATING,
    GOLD_SEEDS,
    MASK,
    MOUTH_PIECE,
    SPACE_OAR,
    STOMACH,
    TONGUE_POSITION,
    TREATMENT_BREAK,
    TREATMENT_BREAK_DURATION,
    TREATMENT_BREAK_FRACTION,
    VAGINAL_TAMPON,
  } = CONDITIONAL_FIELDS;

  const BOLUS_FIELDS = [BOLUS, BOLUS_FREQUENCY, BOLUS_LOCATION, BOLUS_THICKNESS, BOLUS_THICKNESS_CUSTOM];
  const TREATMENT_BREAK_FIELDS = [TREATMENT_BREAK, TREATMENT_BREAK_DURATION, TREATMENT_BREAK_FRACTION];

  return {
    'GI, Colo-rectal + Abdo': [...BOLUS_FIELDS, ABDOMEN_COMPRESSION, BLADDER, BOWEL_PREP, FOUR_DCT, STOMACH],
    Breast: [...BOLUS_FIELDS, DIBH, FOUR_DCT],
    Skin: [...BOLUS_FIELDS, ...TREATMENT_BREAK_FIELDS],
    'H&N': [...BOLUS_FIELDS, MASK, TONGUE_POSITION, MOUTH_PIECE],
    Miscellaneous: [...BOLUS_FIELDS, MASK],
    Gynae: [...BOLUS_FIELDS, BLADDER, BOWEL_PREP, VAGINAL_TAMPON],
    Metastasis: [...BOLUS_FIELDS, FOUR_DCT, GATING, MASK],
    'Sarcoma - Soft Tissue': [...BOLUS_FIELDS],
    Benign: [...BOLUS_FIELDS],
    Haematological: [...BOLUS_FIELDS, MASK],
    Brain: [MASK],
    Urogenital: [BLADDER, BOWEL_PREP, GOLD_SEEDS, SPACE_OAR],
    Lung: [GATING, DIBH, FOUR_DCT],
    'Skin - Melanoma': [],
    General: [],
  };
})();

export const getManualSiteTumourStreamMap = (version: number): { [key: string]: string[] } => {
  return version >= 4 ? MANUAL_SITE_TUMOUR_STREAM_MAP_V2 : MANUAL_SITE_TUMOUR_STREAM_MAP_V1;
};
export const ManualSiteAccordion = styled(Accordion)`
  margin-bottom: 42px;
  max-width: 648px;

  .bolus-heading-style:first-child {
    margin-top: 0;
  }
`;

export const isSpecifySite = (siteName: any): boolean => {
  return (
    siteName &&
    ([
      'Skin - H&N',
      'Skin - Upper Limb',
      'Skin - Lower Limb',
      'Skin - Torso',
      'Skin - Face',
      'Skin - Sub-total Scalp',
      'Skin - Sub-total Nose',
    ].includes(siteName) ||
      siteName.toLowerCase().includes('specify'))
  );
};

export const logMissingDiagnosisCode = (logger: Logger, data: any, page: string) => {
  if (data && data.diagnosis && !data.diagnosis.diagnosisCode) {
    logger.error(`Missing Diagnosis Code from ${page} Page`, JSON.stringify(data));
  }
};
