// eslint-disable-next-line no-use-before-define
import { useState } from 'react';
import { Stack, Typography, ToggleButton } from '@mui/material';
import { StandardDialog } from 'shared-components/components';

interface Props {
  isOpen: boolean;
  dismissFunction: () => void;
  submitForm: (value: string) => void;
}

const ModalSelect = ({ dismissFunction, isOpen, submitForm }: Props): JSX.Element => {
  const [selectedButton, setSelectedButton] = useState('');
  const componentClass = 'modal-select';

  return (
    <StandardDialog
      id={componentClass}
      open={isOpen}
      onClose={dismissFunction}
      maxWidth="xs"
      fullWidth
      onSubmit={() => submitForm(selectedButton)}
      submitText="Create"
      title="Create New Assessment">
      <Stack paddingY={2}>
        <Typography marginBottom={2}>Select an option to create a new assessment</Typography>
        <Stack direction="row" width={1} gap={3}>
          <ToggleButton
            value="Initial Chart Check"
            fullWidth
            data-testid="chart-check-btn"
            selected={'Initial Chart Check' === selectedButton}
            onClick={() => setSelectedButton('Initial Chart Check')}
            sx={{
              color: 'text.primary',
              width: '100%',
            }}>
            Initial Chart Check
          </ToggleButton>
          <ToggleButton
            value="Initial Triage"
            fullWidth
            data-testid="initial-triage-btn"
            selected={'Initial Triage' === selectedButton}
            onClick={() => setSelectedButton('Initial Triage')}
            sx={{
              color: 'text.primary',
              width: '100%',
            }}>
            Initial Triage
          </ToggleButton>
        </Stack>
      </Stack>
    </StandardDialog>
  );
};

export default ModalSelect;
