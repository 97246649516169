import React, { Fragment } from 'react';

import { SummaryCard, SummaryRow } from 'op-components';
import { renderArrow } from 'op-pages/OP/HealthAssessment/HASharedComponents/HAModalFields';
import { Edit, Success } from 'shared-components/images';

import { useQuery } from '@apollo/client';
import { evaluateDetailText } from 'op-components/OP/SummaryRow/SummaryRow';
import { BIOLOGICAL_FIELDS, YES } from 'op-pages/OP/HealthAssessment/HAPages/constants';
import { HA_BIOLOGICAL_QUERY } from 'op-pages/OP/HealthAssessment/HASharedComponents/queries';
import { HABiologicalItem, HASubComponentProps } from 'op-pages/OP/HealthAssessment/HASharedComponents/types';
import { displayRawDate } from 'shared-components/utils';

const renderMaleBioHistory = (healthAssessment: HABiologicalItem) => {
  const { psaDateRawDay, psaDateRawMonth, psaDateRawYear, psaLocation, psaScore, stdMale } = healthAssessment;
  return (
    <>
      <SummaryRow
        headingText={'Male history'}
        detailText={''}
        displayAsError={false}
        displayAsOptional={false}
        allowEmptyDetailText={true}
      />
      {renderArrow()}
      {renderSummaryRow(
        BIOLOGICAL_FIELDS.PSA_DATE.TITLE,
        displayRawDate(psaDateRawMonth, psaDateRawYear, psaDateRawDay),
        true,
      )}
      {renderSummaryRow(BIOLOGICAL_FIELDS.PSA_SCORE.TITLE, psaScore, true)}
      {renderSummaryRow(BIOLOGICAL_FIELDS.PSA_LOCATION.TITLE, psaLocation, true)}
      {renderSummaryRow(BIOLOGICAL_FIELDS.STD_MALE.TITLE, stdMale)}
    </>
  );
};

const renderFemaleHistorySection = (healthAssessment: HABiologicalItem) => {
  const { periodAge, periodWhen, abnormalMammograms, breastBiopsy } = healthAssessment;

  return (
    <>
      <SummaryRow
        headingText={'Female history'}
        detailText={''}
        displayAsError={false}
        displayAsOptional={false}
        allowEmptyDetailText={true}
      />
      {renderArrow()}
      {renderSummaryRow(BIOLOGICAL_FIELDS.PERIOD_AGE.TITLE, periodAge, true)}
      {renderSummaryRow(BIOLOGICAL_FIELDS.ABNORMAL_MAMMOGRAMS.TITLE, abnormalMammograms, true)}
      {renderSummaryRow(BIOLOGICAL_FIELDS.BREAST_BIOPSY.TITLE, breastBiopsy, true)}
      {renderSummaryRow(BIOLOGICAL_FIELDS.PERIOD_WHEN.TITLE, periodWhen, true)}
    </>
  );
};

const renderMenoPauseSection = (healthAssessment: HABiologicalItem) => {
  const { menopause, menopauseAge } = healthAssessment;

  return (
    <>
      {renderSummaryRow(BIOLOGICAL_FIELDS.MENOPAUSE.TITLE, menopause)}
      {menopause === YES && renderArrow()}
      {menopause === YES && renderSummaryRow(BIOLOGICAL_FIELDS.MENOPAUSE_AGE.TITLE, menopauseAge, true)}
    </>
  );
};

const renderPregnancySection = (healthAssessment: HABiologicalItem) => {
  const { biologicalPregnant, pregnantAge, pregnantCount, pregnantDeliveries } = healthAssessment;

  return (
    <>
      {renderSummaryRow(BIOLOGICAL_FIELDS.PREGNANT.TITLE, biologicalPregnant)}
      {renderSummaryRow(BIOLOGICAL_FIELDS.PREGNANT_COUNT.TITLE, pregnantCount)}
      {renderSummaryRow(BIOLOGICAL_FIELDS.PREGNANT_DELIVERIES.TITLE, pregnantDeliveries)}
      {renderSummaryRow(BIOLOGICAL_FIELDS.PREGNANT_AGE.TITLE, pregnantAge)}
    </>
  );
};

const renderRemaningFemaleSection = (healthAssessment: HABiologicalItem) => {
  const {
    hormones,
    hormonesDuration,
    breastfeed,
    braSize,
    nippleDischarge,
    nippleDischargeExplain,
    vaginalBleeding,
    vaginalDischarge,
    vaginalBleedingExplain,
    vaginalDischargeExplain,
    stdFemale,
    papSmearDateRawDay,
    papSmearDateRawMonth,
    papSmearDateRawYear,
    mammogramDateRawDay,
    mammogramDateRawMonth,
    mammogramDateRawYear,
  } = healthAssessment;
  return (
    <>
      {renderSummaryRow(BIOLOGICAL_FIELDS.BREASTFEED.TITLE, breastfeed)}
      {renderSummaryRow(BIOLOGICAL_FIELDS.HORMONES.TITLE, hormones)}
      {hormones === YES && (
        <>
          {renderArrow()}
          {renderSummaryRow(BIOLOGICAL_FIELDS.HORMONES_DURATION.TITLE, hormonesDuration, true)}
        </>
      )}
      {renderSummaryRow(BIOLOGICAL_FIELDS.BRA_SIZE.TITLE, braSize)}

      {renderSummaryRow(BIOLOGICAL_FIELDS.NIPPLE_DISCHARGE.TITLE, nippleDischarge)}
      {nippleDischarge === YES && (
        <>
          {renderArrow()}
          {renderSummaryRow(BIOLOGICAL_FIELDS.NIPPLE_DISCHARGE_EXPLAIN.TITLE, nippleDischargeExplain, true)}
        </>
      )}

      {renderSummaryRow(BIOLOGICAL_FIELDS.VAGINAL_BLEEDING.TITLE, vaginalBleeding)}
      {vaginalBleeding === YES && (
        <>
          {renderArrow()}
          {renderSummaryRow(BIOLOGICAL_FIELDS.VAGINAL_BLEEDING_EXPLAIN.TITLE, vaginalBleedingExplain, true)}
        </>
      )}

      {renderSummaryRow(BIOLOGICAL_FIELDS.VAGINAL_DISCHARGE.TITLE, vaginalDischarge)}
      {vaginalDischarge === YES && (
        <>
          {renderArrow()}
          {renderSummaryRow(BIOLOGICAL_FIELDS.VAGINAL_DISCHARGE_EXPLAIN.TITLE, vaginalDischargeExplain, true)}
        </>
      )}
      {renderSummaryRow(BIOLOGICAL_FIELDS.STD_FEMALE.TITLE, stdFemale)}

      {renderSummaryRow(
        BIOLOGICAL_FIELDS.PAP_SMEAR_DATE.TITLE,
        displayRawDate(papSmearDateRawMonth, papSmearDateRawYear, papSmearDateRawDay),
        false,
      )}

      {renderSummaryRow(
        BIOLOGICAL_FIELDS.MAMMOGRAM_DATE.TITLE,
        displayRawDate(mammogramDateRawMonth, mammogramDateRawYear, mammogramDateRawDay),
        false,
      )}
    </>
  );
};

const renderSummaryRow = (titleText: string, detailText: string, subQuestion = false) => {
  return (
    <SummaryRow
      headingText={titleText}
      detailText={evaluateDetailText(detailText)}
      displayAsError={false}
      displayAsOptional={false}
      subQuestion={subQuestion}
    />
  );
};
const renderFemaleBioHistory = (healthAssessment: HABiologicalItem) => {
  return (
    <>
      {renderFemaleHistorySection(healthAssessment)}
      {renderMenoPauseSection(healthAssessment)}
      {renderPregnancySection(healthAssessment)}
      {renderRemaningFemaleSection(healthAssessment)}
    </>
  );
};

const MedicalHistoryBiological = (props: HASubComponentProps): JSX.Element => {
  const { patientId, isPso, navigate } = props;

  const { data, loading } = useQuery<{ healthAssessment: HABiologicalItem }>(HA_BIOLOGICAL_QUERY, {
    variables: { patientId },
  });

  const renderContent = (data: { healthAssessment: HABiologicalItem }): JSX.Element => {
    if (data.healthAssessment?.biologicalGender === 'MALE') return renderMaleBioHistory(data.healthAssessment);
    else if (data.healthAssessment?.biologicalGender === 'FEMALE') return renderFemaleBioHistory(data.healthAssessment);
    else
      return (
        <SummaryRow
          headingText={'Biological Gender'}
          detailText={data.healthAssessment?.biologicalGender === 'NOT STATED' ? 'Not stated' : null}
          displayAsError={false}
          displayAsOptional={false}
        />
      );
  };

  if (!data || loading) return <></>;

  return (
    <Fragment>
      <SummaryCard
        title={'Medical history 3'}
        primaryIcon={isPso ? <Fragment></Fragment> : <Edit className="primary-icon" />}
        secondaryIcon={<Success className="secondary-icon valid-icon" />}
        showEdit={!isPso}
        onClick={(): void => {
          if (!isPso) {
            navigate('biological');
          }
        }}>
        {renderContent(data)}
      </SummaryCard>
    </Fragment>
  );
};

export default MedicalHistoryBiological;
