// eslint-disable-next-line no-use-before-define
import { HANavigatorApollo, HeaderBar } from 'op-components';
import { HAPSOReviewApollo } from 'op-pages';
import React, { Component, Fragment } from 'react';
import { withApollo, WithApolloClient } from '@apollo/client/react/hoc';

import { Redirect, Route, RouteComponentProps, Switch, withRouter } from 'react-router-dom';
import { Stepper } from 'shared-components/components';
import { SavingStatus } from 'shared-components/enums';
import { StepperLink } from 'shared-components/interfaces';
import { US_PAGE_LINKS, AU_PAGE_LINKS } from './constants';
import {
  HAAllergiesApollo,
  HAMedicalHistoryCancerApollo,
  HAPainAssessment,
  HAMedicalHistoryOtherApollo,
  HAMedication,
  HAOperationsApollo,
  HAOtherMedicalProblemsApollo,
  HAPatientInformationApollo,
  HAReviewSummaryApollo,
  HAMedicalHistoryIllnessApollo,
  HAReviewSummaryUS,
  HASocialAssessmentApollo,
  HAMobility,
  HABiological,
  HAMedicalHistoryIllnessPageUS,
  HASocialUS,
} from '../HAPages';
import { isUs } from 'op-utils';
import { DeviceUtilities } from 'shared-components/utils';
import './HAContainer.scss';
import HAPatientConsent from '../HAPages/HAPatientConsentUS/HAPatientConsent';

interface Props extends WithApolloClient<{}>, RouteComponentProps<{ patientId: string }> {}

// Store a count of the pages visited, as zero will be the first open and any subsequent ones will be a repeat visit
interface State {
  visitedPages: { [key: string]: number };
  saveStatus: SavingStatus;
  currentPage: string;
  previousPageTitle: string;
}

const HAFormHeadingText = 'Health Assessment';

class HAContainer extends Component<Props, State> {
  public constructor(props: Props) {
    super(props);

    const {
      location: { pathname },
    } = props;

    // Get the current form page and set it as visited on HAContainer load
    const pathSegments = pathname.split('/');
    const currentPage = pathSegments[pathSegments.length - 1];

    this.state = {
      visitedPages: { [currentPage]: 0 },
      saveStatus: SavingStatus.SAVED,
      currentPage: currentPage,
      previousPageTitle: '',
    };
  }

  componentDidMount() {
    this.setState({
      previousPageTitle: document.title,
    });
    document.title = `${document.title} - ${HAFormHeadingText}`;
  }

  componentWillUnmount() {
    const previousPageTitle = this.state.previousPageTitle;
    document.title = previousPageTitle ? previousPageTitle : 'Horizon'; // Will probably be replaced with a constant
  }

  public static getDerivedStateFromProps(props: Props, state: State): State {
    const {
      location: { pathname },
    } = props;

    // Only if we visit another page mark it as visited. Some Apollo pages will refetch data triggering a page reload
    // So we don't want to store them as visited again
    const pathSegments = pathname.split('/');
    const currentPage = pathSegments[pathSegments.length - 1];

    if (currentPage !== state.currentPage) {
      state.currentPage = currentPage;
      // Add to state if it's not there.
      if (!Object.keys(state.visitedPages).includes(currentPage)) {
        state.visitedPages[currentPage] = 0;
      } else {
        state.visitedPages[currentPage] += 1;
      }
    }

    return state;
  }

  public render(): JSX.Element {
    const {
      match: {
        path,
        params: { patientId },
      },
    } = this.props;

    const { visitedPages } = this.state;

    const PAGE_LINKS: StepperLink[] = isUs() ? US_PAGE_LINKS : AU_PAGE_LINKS;
    const BASE_PATH = `/patient/${patientId}/health`;

    const { client } = this.props;
    const { currentPage } = this.state;
    const isMobile = DeviceUtilities.isMobileDevice();
    return (
      <Fragment>
        <HeaderBar
          headingText={!isMobile ? 'Health Assessment' : undefined}
          menuButton
          menuButtonText={isMobile ? 'Save & Exit' : ''}
        />
        <div id="ha-container" className="page-container full-viewport">
          {!isMobile && currentPage !== 'review' && (
            <>
              <div className="right-container d-flex flex-wrap px-2">
                <div id="reg-container-stepper">
                  <div id="reg-container-header">
                    <div id="reg-container-header-title">{HAFormHeadingText}</div>
                  </div>

                  <Stepper basePath={BASE_PATH} links={PAGE_LINKS} />
                </div>
              </div>
            </>
          )}

          <div id="ha-content-root">
            <Switch>
              <Route path={`${path}/info`}>
                <HAPatientInformationApollo
                  client={client}
                  updateSaveStatus={this.updateSaveStatus}
                  validateOnLoad={visitedPages['info'] > 0}
                />
              </Route>
              <Route path={`${path}/history-cancer`}>
                <HAMedicalHistoryCancerApollo
                  client={client}
                  updateSaveStatus={this.updateSaveStatus}
                  validateOnLoad={visitedPages['history-cancer'] > 0}
                />
              </Route>
              <Route path={`${path}/history-illness`}>
                {isUs() ? (
                  <HAMedicalHistoryIllnessPageUS />
                ) : (
                  <HAMedicalHistoryIllnessApollo
                    client={client}
                    updateSaveStatus={this.updateSaveStatus}
                    validateOnLoad={visitedPages['history-illness'] > 0}
                  />
                )}
              </Route>
              <Route path={`${path}/biological`}>
                <HABiological validateOnLoad={visitedPages['biological'] > 0} />
              </Route>
              <Route path={`${path}/history-other`}>
                <HAMedicalHistoryOtherApollo
                  client={client}
                  updateSaveStatus={this.updateSaveStatus}
                  validateOnLoad={visitedPages['history-other'] > 0}
                />
              </Route>
              <Route path={`${path}/mobility`}>
                <HAMobility validateOnLoad={visitedPages['mobility'] > 0} />
              </Route>
              <Route path={`${path}/painAssessment`}>
                <HAPainAssessment validateOnLoad={visitedPages['painAssessment'] > 0} />
              </Route>
              <Route path={`${path}/operations`}>
                <HAOperationsApollo
                  client={client}
                  updateSaveStatus={this.updateSaveStatus}
                  validateOnLoad={visitedPages['operations'] > 0}
                />
              </Route>
              <Route path={`${path}/social`}>
                <HASocialAssessmentApollo
                  client={client}
                  updateSaveStatus={this.updateSaveStatus}
                  validateOnLoad={visitedPages['social'] > 0}
                />
              </Route>
              <Route path={`${path}/social-us`}>
                <HASocialUS />
              </Route>
              <Route path={`${path}/medications`}>
                <HAMedication validateOnLoad={visitedPages['medications'] > 0} />
              </Route>
              <Route path={`${path}/allergies`}>
                <HAAllergiesApollo
                  client={client}
                  updateSaveStatus={this.updateSaveStatus}
                  validateOnLoad={visitedPages['allergies'] > 0}
                />
              </Route>
              <Route path={`${path}/other`}>
                <HAOtherMedicalProblemsApollo
                  client={client}
                  updateSaveStatus={this.updateSaveStatus}
                  validateOnLoad={visitedPages['other'] > 0}
                />
              </Route>
              <Route path={`${path}/consent`}>
                <HAPatientConsent />
              </Route>
              <Route path={`${path}/summary`}>{isUs() ? <HAReviewSummaryUS /> : <HAReviewSummaryApollo />}</Route>

              <Route path={`${path}/review`}>
                <HAPSOReviewApollo client={client} />
              </Route>
              {/* This is the catch all, where it currently it will just take the user back to login, however should reconsider to an error page instead. */}
              {/* visited={visitedPages.includes('info')} */}
              <Route path={`${path}`}>
                <Redirect to="/patient" />
              </Route>
            </Switch>
          </div>
          {currentPage !== 'review' && <HANavigatorApollo links={PAGE_LINKS} />}
        </div>
      </Fragment>
    );
  }

  private updateSaveStatus = (saveStatus: SavingStatus): void => {
    this.setState({ saveStatus });
  };
}

const apolloComponent = withApollo<Props>(HAContainer);
const routedComponent = withRouter(apolloComponent);
export default routedComponent;
