import {
  ctSimulation,
  nurseEducation as attendingYourAppointments,
  whileOnTreatment,
  support,
} from 'shared-components/images/';

export const FAQ_CONSTANTS = {
  radiotherapy: {
    title: 'Radiotherapy',
    name: 'radiotherapy',
    image: ctSimulation,
  },
  'attending-your-appointments': {
    title: 'Attending Your Appointments',
    name: 'attending-your-appointments',
    image: attendingYourAppointments,
  },
  'while-on-treatment': {
    title: 'While on Treatment',
    name: 'while-on-treatment',
    image: whileOnTreatment,
  },
  support: {
    title: 'Support',
    name: 'support',
    image: support,
  },
};
