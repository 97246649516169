import { gql } from '@apollo/client';
import { HA_SOCIAL_US_FRAGMENT } from 'op-graphql/fragments';

export const HA_SOCIAL_US_QUERY = gql`
  query HealthAssessment($patientId: ID!) {
    healthAssessment(id: $patientId) {
      id
      ...HealthAssessmentSocialUS
    }
    alcoholFrequencyRefData: listData(category: "alcohol_frequency") {
      id
      name
    }
    smokingFrequencyRefData: listData(category: "smoking_frequency") {
      id
      name
    }
    streetDrugsRefData: listData(category: "streetDrugs") {
      id
      name
    }
  }

  ${HA_SOCIAL_US_FRAGMENT}
`;

export const UPDATE_HA_SOCIAL_US = gql`
  mutation UpdateHaSocialUS(
    $haId: ID!
    $patientId: ID!
    $smokingBool: Boolean
    $smokingHistory: String
    $smokingFrequency: String
    $smokingStopRawMonth: String
    $smokingStopRawYear: String
    $tobaccoChew: Boolean
    $tobaccoChewHistory: String
    $tobaccoStopRawMonth: String
    $tobaccoStopRawYear: String
    $tobaccoStopClassesRawMonth: String
    $tobaccoStopClassesRawYear: String
    $alcoholBool: Boolean
    $alcoholFrequency: String
    $alcoholStopRawMonth: String
    $alcoholStopRawYear: String
    $streetDrugs: Boolean
    $streetDrugsUse: String
    $medicalMarijuanaCard: Boolean
    $socialSupportSystem: Boolean
    $socialSupportPeople: String
    $religiousBeliefs: Boolean
    $religiousBeliefsDescription: String
    $stillWorking: Boolean
    $stillWorkingReason: String
    $financialAssisstance: Boolean
    $financialAssisstanceReason: String
    $servedInMilitary: Boolean
    $servedInMilitaryBranch: String
    $chemicals: Boolean
    $chemicalsExposed: String
    $physicallyActive: Boolean
    $physicallyActiveExercise: String
    $difficultyTravellingBool: Boolean
    $difficultyTravelling: String
  ) {
    updateHaSocialUS(
      id: $haId
      patientId: $patientId
      smokingBool: $smokingBool
      smokingHistory: $smokingHistory
      smokingFrequency: $smokingFrequency
      smokingStopRawMonth: $smokingStopRawMonth
      smokingStopRawYear: $smokingStopRawYear
      tobaccoChew: $tobaccoChew
      tobaccoChewHistory: $tobaccoChewHistory
      tobaccoStopRawMonth: $tobaccoStopRawMonth
      tobaccoStopRawYear: $tobaccoStopRawYear
      tobaccoStopClassesRawMonth: $tobaccoStopClassesRawMonth
      tobaccoStopClassesRawYear: $tobaccoStopClassesRawYear
      alcoholBool: $alcoholBool
      alcoholFrequency: $alcoholFrequency
      alcoholStopRawMonth: $alcoholStopRawMonth
      alcoholStopRawYear: $alcoholStopRawYear
      streetDrugs: $streetDrugs
      streetDrugsUse: $streetDrugsUse
      medicalMarijuanaCard: $medicalMarijuanaCard
      socialSupportSystem: $socialSupportSystem
      socialSupportPeople: $socialSupportPeople
      religiousBeliefs: $religiousBeliefs
      religiousBeliefsDescription: $religiousBeliefsDescription
      stillWorking: $stillWorking
      stillWorkingReason: $stillWorkingReason
      financialAssisstance: $financialAssisstance
      financialAssisstanceReason: $financialAssisstanceReason
      servedInMilitary: $servedInMilitary
      servedInMilitaryBranch: $servedInMilitaryBranch
      chemicals: $chemicals
      chemicalsExposed: $chemicalsExposed
      physicallyActive: $physicallyActive
      difficultyTravelling: $difficultyTravelling
      physicallyActiveExercise: $physicallyActiveExercise
      difficultyTravellingBool: $difficultyTravellingBool
    ) {
      healthAssessment {
        id
        ...HealthAssessmentSocialUS
      }
    }
  }
  ${HA_SOCIAL_US_FRAGMENT}
`;
