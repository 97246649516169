import React, { useContext } from 'react';
import Table, { DATATYPE, SORT_TYPES, TableHeaderProps } from 'shared-components/components/Table/Table';
import moment from 'moment';
import { styled } from '@mui/system';
import { useHistory, useRouteMatch } from 'react-router-dom';
import { PatientCareplans } from './interfaces';
import { TABLE_KEYS } from './Constants';
import { UserContext } from 'op-contexts/index';
import { useTheme } from '@mui/material';

const StyledDiv = styled('div')`
  && table {
    min-height: 400px;
    overflow: auto;
    display: table;
    min-width: 1500px !important;
  }
  display: flex;
`;
const StyledDot = styled('div')`
  height: 16px;
  width: 16px;
  border-radius: 50%;
  display: inline-block;
  margin-right: 8px;
  aspect-ratio: 1/1;
`;
const StyledText = styled('div')`
  font-size: 16px;
  line-height: 1.4;
`;

const MOCareplanStatus = (props: any) => {
  return (
    <>
      {!['Careplan completed', 'Ended early'].includes(props.status) && <StyledDot {...props} />} {props.status}
    </>
  );
};

interface MOCareplanTableProps {
  careplans: PatientCareplans[];
  headers: TableHeaderProps[];
}

const getFormattedTreatmentStartDate = (careplanInfo: PatientCareplans, timezone: string) => {
  const treatmentStartDate = moment(careplanInfo.treatmentStartDate, 'YYYY-MM-DD').tz(timezone);
  if (!treatmentStartDate.isValid()) return '-';
  return treatmentStartDate.format('L');
};

const getFormattedTreatmentEndDate = (careplanInfo: PatientCareplans, timezone: string) => {
  const treatmentEndDate = moment(careplanInfo.treatmentEndDate, 'YYYY-MM-DD').tz(timezone);
  if (!treatmentEndDate.isValid()) return '-';
  const treatmentCompleted = treatmentEndDate.isSameOrAfter(moment(), 'days');
  return `${treatmentEndDate.format('L')}${treatmentCompleted ? ' (expected)' : ''}`;
};
const getFormattedNextTreatment = (careplanInfo: PatientCareplans, timezone: string) => {
  const nextTreatmentDate = moment(careplanInfo.nextTreatmentDate, 'YYYY-MM-DD').tz(timezone);
  if (!nextTreatmentDate.isValid()) return '-';
  return `${nextTreatmentDate.calendar({
    sameDay: '[Today]',
    nextDay: '[Tomorrow]',
    lastWeek: 'DD/MM/YYYY',
    nextWeek: 'DD/MM/YYYY',
    sameElse: 'DD/MM/YYYY',
  })}, ${careplanInfo.nextTreatmentInfo}`;
};
const getFormattedCurrentCycle = (currentCycle: string): string => {
  return ['', null].includes(currentCycle) ? '-' : currentCycle;
};
const getFormattedLastModified = (careplanInfo: PatientCareplans, timezone: string): string => {
  const momentDate = moment(careplanInfo.lastModified);
  if (!momentDate.isValid()) return '-';
  return momentDate.tz(timezone).format('L LT');
};

const getFormattedCareplanName = (careplanInfo: PatientCareplans): string => {
  if (careplanInfo.careplanName === '' || careplanInfo.careplanName === null) return '-';
  return careplanInfo.careplanName;
};
const PatientCareplanTable = ({ careplans, headers }: MOCareplanTableProps): JSX.Element => {
  const history = useHistory();
  const match = useRouteMatch<{ id: string }>();
  const theme = useTheme();
  const { id: patientId } = match.params;
  const { state: userDetails } = useContext(UserContext);
  const tableData = careplans
    .sort((a: any, b: any): any => {
      return moment(a.nextTreatmentDate, 'YYYY-MM-DD').diff(moment(b.nextTreatmentDate, 'YYYY-MM-DD'));
    })
    .map((careplanInfo: any) => ({
      tableRows: [
        {
          key: TABLE_KEYS.TREATING_DEPARTMENT,
          width: '15.2%',
          component: <StyledText>{careplanInfo.treatingDepartment}</StyledText>,
        },
        {
          key: TABLE_KEYS.CAREPLAN,
          width: '24.9%',
          component: <StyledText>{getFormattedCareplanName(careplanInfo)}</StyledText>,
        },
        {
          key: TABLE_KEYS.TREATMENT_START_DATE,
          width: '11.6%',
          component: <StyledText>{getFormattedTreatmentStartDate(careplanInfo, userDetails.timezone)}</StyledText>,
        },
        {
          key: TABLE_KEYS.TREATMENT_END_DATE,
          width: '11.6%',
          component: <StyledText>{getFormattedTreatmentEndDate(careplanInfo, userDetails.timezone)}</StyledText>,
        },
        {
          key: TABLE_KEYS.CURRENT_CYCLE,
          width: '8.9%',
          component: <StyledText>{getFormattedCurrentCycle(careplanInfo.currentCycle)}</StyledText>,
        },
        {
          key: TABLE_KEYS.NEXT_TREATMENT,
          width: '8.9%',
          component: <StyledText>{getFormattedNextTreatment(careplanInfo, userDetails.timezone)}</StyledText>,
        },
        {
          key: TABLE_KEYS.STATUS,
          width: '9.1%',
          component: <MOCareplanStatus status={careplanInfo.status} />,
        },
        {
          key: TABLE_KEYS.LAST_MODIFIED,
          sort: true,
          width: '10%',
          component: <StyledText>{getFormattedLastModified(careplanInfo, userDetails.timezone)}</StyledText>,
        },
      ],
      onRowClick: () => {
        careplanInfo.nextTreatmentCycleId === null || careplanInfo.nextTreatmentCycleId === undefined
          ? history.push(careplanInfo.latestPageUrl)
          : history.push(
              `/medonc/patient/${patientId}/careplan/${careplanInfo.id}/prescription/${careplanInfo.nextTreatmentCycleId}`,
            );
      },
      rowHoverColor: theme.palette.primary.light,
    }));

  const tableProps = {
    tableHeaders: headers,
    tableData: tableData,
    defaultSortState: { key: TABLE_KEYS.NEXT_TREATMENT, order: SORT_TYPES.ASC, type: DATATYPE.DATE },
    hoverScroll: true,
    noRowsText: 'No careplan available.',
  };
  return (
    <StyledDiv>
      <Table testId="careplan-table" {...tableProps} />
    </StyledDiv>
  );
};

export default PatientCareplanTable;
