export class CovidBase {
  public id: string;

  public constructor() {
    this.id = '';
  }
}

export class CovidAssessmentItem extends CovidBase {
  public hasSymptoms: boolean;
  public hasBeenDiagnosed: boolean;
  public hasBeenTested: boolean;
  public advisedToQuarantine: boolean;
  public hadContact: boolean;
  public travelledToHotspot: boolean;
  public dateOfFeverSymptoms: Date | string;
  public symptomDay: string;
  public symptomMonth: string;
  public symptomYear: string;
  public status: string;

  public constructor() {
    super();
    this.hasSymptoms = false;
    this.hasBeenDiagnosed = false;
    this.hasBeenTested = false;
    this.advisedToQuarantine = false;
    this.hadContact = false;
    this.travelledToHotspot = false;
    this.dateOfFeverSymptoms = new Date();
    this.symptomDay = '';
    this.symptomMonth = '';
    this.symptomYear = '';
    this.status = '';
  }

  public loadData = (object: any): CovidAssessmentItem => {
    this.id = object.id;
    this.hasSymptoms = object.hasSymptoms;
    this.hasBeenDiagnosed = object.hasBeenDiagnosed;
    this.hasBeenTested = object.hasBeenTested;
    this.advisedToQuarantine = object.advisedToQuarantine;
    this.hadContact = object.hadContact;
    this.travelledToHotspot = object.travelledToHotspot;
    this.dateOfFeverSymptoms = object.dateOfFeverSymptoms;
    this.symptomDay = object.symptomDay;
    this.symptomMonth = object.symptomMonth;
    this.symptomYear = object.symptomYear;
    this.status = object.status;
    return this;
  };
}
