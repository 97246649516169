const roundToTwoDigits = (value: number): number => Math.round((value + Number.EPSILON) * 100) / 100;

export const calculateBSA = (height: number | '', weight: number | ''): number => {
  if (!height || !weight) return NaN;
  return roundToTwoDigits(0.007184 * Math.pow(height, 0.725) * Math.pow(weight, 0.425));
};

export const calculateCreatinineClearance = (
  weight: number | '',
  age: number,
  serum: number | '',
  gender: string,
): number => {
  if (!weight || !gender || !serum) return NaN;
  const ccCalculation = ((140 - age) * weight) / (0.814 * serum);
  return roundToTwoDigits(gender === 'Male' ? ccCalculation : ccCalculation * 0.85);
};
