import React, { useState } from 'react';
import { StandardDialog } from 'shared-components/components';
import { Typography } from '@mui/material';

interface ModalResolveConflictsIProps {
  isOpen: boolean;
  dismissFunction: () => void;
  resolveConflictsPage: () => void;
  resolveConflictsText?: string;
}

const ModalResolveConflicts = (props: ModalResolveConflictsIProps): JSX.Element => {
  const { dismissFunction, isOpen, resolveConflictsPage, resolveConflictsText = 'Resolve conflicts' } = props;
  const [locked, setLocked] = useState(false);

  return (
    <StandardDialog
      open={isOpen}
      onClose={dismissFunction}
      title="Are you sure you want to resolve these conflicts?"
      submitText={resolveConflictsText}
      onSubmit={() => {
        setLocked(true);
        resolveConflictsPage();
      }}
      submitButtonProps={{
        disabled: locked,
      }}>
      <Typography>
        Please ensure all value selections are correct before resolving. When resolved, you will then be taken to the
        Review & Submit section.
      </Typography>
    </StandardDialog>
  );
};

export default ModalResolveConflicts;
