// eslint-disable-next-line no-use-before-define
import React, { Component, Fragment } from 'react';
import classNames from 'classnames';

interface Props {
  title?: React.ReactNode;
  children?: React.ReactNode;
  style?: string;
}

class ROAlert extends Component<Props> {
  public render(): JSX.Element {
    const { title, children, style = 'error' } = this.props;
    return (
      <Fragment>
        <div className={classNames('ro-alert', style)}>
          {title && <div className="ro-alert-title">{title}</div>}
          <div className="ro-alert-body-container">
            <div className="ro-alert-body">{children}</div>
          </div>
        </div>
      </Fragment>
    );
  }
}

export default ROAlert;
