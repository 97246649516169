// eslint-disable-next-line no-use-before-define
import React, { Component } from 'react';
import { Stack, Tooltip, Button } from '@mui/material';
import { styled } from '@mui/system';
import { LoadingButton } from '@mui/lab';

interface Props {
  onReset?: () => void;
  onBack?: () => void;
  onNext?: () => void;
  onAdditionLeft?: () => void;
  onAdditionLeftMouseEnter?: () => void;
  onAdditionLeftMouseLeave?: () => void;
  showAdditionLeftTooltip?: boolean;
  onAdditionRight?: () => void;
  resetText?: string;
  backText?: string;
  backButtonId?: string;
  nextTooltip?: string;
  nextText?: string;
  nextTextId?: string;
  additionLeftText?: string;
  additionLeftTextId?: string;
  additionRightText?: string;
  additionRightTextId?: string;
  nextDisabled?: boolean;
  backDisabled?: boolean;
  resetDisabled?: boolean;
  nextHidden?: boolean;
  resetHidden?: boolean;
  additionRightDisabled?: boolean;
  additionLeftDisabled?: boolean;
  nextLoading?: boolean;
  additionRightLoading?: boolean;
  backHidden?: boolean;
  additionalLeftTooltip?: string;
  backButtonTooltip?: string;
}

const StyledNavbarFooter = styled(Stack)`
  display: flex;
  flex-direction: row;
  border-top: 2px solid ${(props) => props.theme.palette.primary.main};
  padding: 12px 16px;
  background: white;
  transition: box-shadow 0.15s ease-in-out;
`;

class ROPatientCarePlanPageFooter extends Component<Props> {
  public render(): JSX.Element {
    const {
      onReset,
      onBack,
      onNext,
      onAdditionLeft,
      onAdditionRight,
      resetText = 'Reset page',
      backText = 'Back',
      backHidden,
      nextText = 'Continue',
      nextTextId,
      additionLeftText = '',
      additionLeftTextId,
      additionRightText = '',
      additionRightTextId,
      nextDisabled,
      backDisabled,
      resetDisabled,
      nextHidden,
      resetHidden,
      additionRightDisabled,
      additionLeftDisabled,
      nextLoading,
      additionRightLoading,
      additionalLeftTooltip = '',
      backButtonTooltip,
    } = this.props;
    return (
      <div className="gc-navbar-footer-container">
        <StyledNavbarFooter className="gc-navbar-footer" gap="4px">
          <div className="button-list">
            {!resetHidden && resetText && !!onReset && (
              <Button
                size="large"
                variant="outlined"
                data-testid="gc-reset-button"
                id="gc-reset-button"
                onClick={onReset}
                disabled={resetDisabled}>
                {resetText}
              </Button>
            )}
          </div>
          <Stack marginLeft="auto" direction="row" gap={2}>
            {onAdditionLeft && (
              <Tooltip title={additionalLeftTooltip} arrow>
                {/* Span is needed to get tooltips to show on disabled buttons */}
                <span>
                  <Button
                    size="large"
                    variant="outlined"
                    disabled={additionLeftDisabled}
                    data-testid={additionLeftTextId || 'additional-left-text'}
                    id={additionLeftTextId || 'additional-left-text'}
                    onClick={onAdditionLeft}>
                    {additionLeftText}
                  </Button>
                </span>
              </Tooltip>
            )}
            {!backHidden && (
              <Tooltip title={backButtonTooltip} arrow>
                <Button
                  size="large"
                  variant="outlined"
                  id="footer-button-back"
                  data-testid="footer-button-back"
                  onClick={onBack}
                  disabled={backDisabled}>
                  {backText}
                </Button>
              </Tooltip>
            )}
            {!nextHidden && (
              <Tooltip title={this.props.nextTooltip || ''} arrow>
                <LoadingButton
                  size="large"
                  id={nextTextId || 'footer-button-continue'}
                  data-testid="footer-button-continue"
                  loading={nextLoading}
                  onClick={onNext}
                  variant="contained"
                  disabled={nextDisabled}>
                  {nextText}
                </LoadingButton>
              </Tooltip>
            )}
            {!!onAdditionRight && (
              <LoadingButton
                size="large"
                id={additionRightTextId}
                data-testid={additionRightTextId}
                loading={additionRightLoading}
                onClick={onAdditionRight}
                disabled={additionRightDisabled}
                variant="contained">
                {additionRightText}
              </LoadingButton>
            )}
          </Stack>
        </StyledNavbarFooter>
      </div>
    );
  }
}

export default ROPatientCarePlanPageFooter;
