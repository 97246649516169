import { useMemo } from 'react';
import { useQuery } from '@apollo/client';
import { gql } from '@apollo/client';

export const LIST_DATA = gql`
  query carepathDataReferenceList($listCategory: [String!]!, $patientId: ID) {
    carepathDataReferenceList(listCategory: $listCategory, patientId: $patientId) {
      id
      data {
        id
        option
      }
      listWeight
      category {
        id
        category
      }
    }
  }
`;

interface CarepathDataReferenceList {
  id: number;
  data: {
    id: number;
    option: string;
  };
  listWeight: number;
  category: {
    id: number;
    category: string;
  };
}
export interface OptionType {
  option: string;
  listWeight: number;
}
export default function useOncologyListData(
  listCategory: string[],
  patientId?: string | undefined,
): Record<string, OptionType[]> {
  const { data } = useQuery(LIST_DATA, { variables: { listCategory, patientId } });
  const listData = useMemo<Record<string, OptionType[]>>(() => {
    if (!data || !data.carepathDataReferenceList) return {};
    return data.carepathDataReferenceList.reduce(
      (
        mappedData: Record<string, OptionType[]>,
        referenceData: CarepathDataReferenceList,
      ): Record<string, OptionType[]> => {
        if (mappedData[referenceData.category.category] === undefined) {
          mappedData[referenceData.category.category] = [];
        }
        mappedData[referenceData.category.category].push({
          option: referenceData.data.option,
          listWeight: referenceData.listWeight,
        });
        return mappedData;
      },
      {},
    );
  }, [data]);
  return listData;
}
