// eslint-disable-next-line no-use-before-define
import React, { Component, useState, useEffect } from 'react';

import { Region } from 'shared-components/enums';
import { ROAutocomplete, ROTextField, SelectOptionType } from 'shared-components/components/FormFields';
import { AddressInputs } from 'shared-components/components/FormFields/types';
import { ListData, GraphUpdate } from 'shared-components/interfaces';
import { Logger, UK_POSTCODE_REGEX, UK_CITY_REGEX } from 'shared-components/utils';
import { getOptionByValue } from 'op-pages/RO/Careplan/DiagnosisPages/Utils';
import './AddressFieldRO.scss';

const logger = new Logger('AddressField');

const LINE1_POSTAL_LINE_HEADING = 'Postal address line 1';
const COUNTRY_FIELD_HEADING = 'Country';
const COUNTRY_NAME = 'United Kingdom';
const LINE1_RESIDENTIAL_FIELD_HEADING = 'Address line 1';
const LINE2_HEADING = 'Address line 2';
const CITYSUBURB_FIELD_HEADING = 'City / Town';
const REGION = 'County';
const ZIP_POSTCODE_HEADING = 'Postcode';

interface Props {
  isMandatory: boolean;
  inputName: string;
  addressInputs: AddressInputs;
  onBlur: (graphItem: [GraphUpdate]) => void;
  countries: ListData[];
  ausStates: ListData[];
  validateForm?: boolean;
  disabled?: boolean;
}

const AddressFieldRO = (props: Props) => {
  const [viewed, setViewed] = useState<Set<string>>(new Set());
  const [line1, setLine1] = useState<string>(props.addressInputs.line1.defaultValue);
  const [line2, setLine2] = useState<string>(props.addressInputs.line2.defaultValue);
  const [city, setCity] = useState<string>(props.addressInputs.city.defaultValue);
  const [state, setAddressState] = useState<string>(props.addressInputs.state.defaultValue);
  const [zipPostcode, setZipPostcode] = useState<string>(props.addressInputs.zipPostcode.defaultValue);

  useEffect(() => {
    setLine1(props.addressInputs.line1.defaultValue);
  }, [props.addressInputs.line1.defaultValue]);
  useEffect(() => {
    setLine2(props.addressInputs.line2.defaultValue);
  }, [props.addressInputs.line2.defaultValue]);
  useEffect(() => {
    setCity(props.addressInputs.city.defaultValue);
  }, [props.addressInputs.city.defaultValue]);
  useEffect(() => {
    setAddressState(props.addressInputs.state.defaultValue);
  }, [props.addressInputs.state.defaultValue]);
  useEffect(() => {
    setZipPostcode(props.addressInputs.zipPostcode.defaultValue);
  }, [props.addressInputs.zipPostcode.defaultValue]);

  const australianIndex = props.countries.findIndex((country) => {
    return country.name === COUNTRY_NAME;
  });
  const australianIdInCountry = props.countries[australianIndex].id;

  const onBlur = (graphKey: string[], value: string[], type: string[], validationKeys: string[]): void => {
    const shouldAddKey = (key: string) => {
      // Check if the key is for state, and if it arrived with country, do not process it, and remove it
      // This is to enable, wiping of state data if country changes.
      if (key === 'state') {
        if (validationKeys.includes('country')) {
          viewed.delete(key);
        }
      } else if (!viewed.has(key)) {
        viewed.add(key);
      }
    };

    const graphItems: [GraphUpdate] = [{ key: graphKey[0], value: value[0], type: type[0] }];
    shouldAddKey(validationKeys[0]);

    for (let i = 1; i < graphKey.length; i++) {
      graphItems.push({ key: graphKey[i], value: value[i], type: type[i] });
      shouldAddKey(validationKeys[i]);
    }

    setViewed(viewed);
    props.onBlur(graphItems);
  };

  /**
   * This method is to only edit the residential or postal state if and when either the new country value is changing to Australia, or the existing value is Australia.
   *
   * This is to ensure that when the country is changed from Australia, the state will not have some random id value that had been set. When the country is being changed to Australia, the placeholder of selecting a state should be chosen instead of the first state in the list.
   * @param {string} newCountryValue The new value that the country is being set to.
   */
  const updateStateWithCountry = (newCountryValue: string): void => {
    logger.debug('updateStateWithCountry', 'The country value is: ', newCountryValue);
    const { addressInputs } = props;
    const changingFromAusToAnother =
      addressInputs.country.defaultValue === australianIdInCountry && newCountryValue !== australianIdInCountry;

    const changingFromOtherToAus =
      addressInputs.country.defaultValue !== australianIdInCountry && newCountryValue === australianIdInCountry;

    // Set up the default values to be passed into the on blur function
    const graphKeyValues = [addressInputs.country.graphArgumentKey];
    const graphValues = [newCountryValue];
    const graphValueTypes = ['String'];
    const validationKeys = ['country'];

    if (changingFromOtherToAus || changingFromAusToAnother) {
      logger.debug('updateStateWithCountry', 'The state should be updated');
      // The country is changing to Australia or from Australia to something else, therefore the state should be reset.
      graphKeyValues.push(addressInputs.state.graphArgumentKey);
      graphValues.push('');
      graphValueTypes.push('String');
      validationKeys.push('state');
    }

    onBlur(graphKeyValues, graphValues, graphValueTypes, validationKeys);
  };

  const { addressInputs, inputName } = props;
  let stateValidationFormatPattern = '[a-zA-Z\\s]+|';
  let postCodeValidationFormatPattern = '.*';
  // Australia state dropdown validation messages and checks
  if (addressInputs.country.defaultValue === australianIdInCountry) {
    stateValidationFormatPattern = '.*'; // State is stored as digit so allow any values
  }

  // UK has a specific postcode type.
  if (addressInputs.country.defaultValue === australianIdInCountry) {
    postCodeValidationFormatPattern = UK_POSTCODE_REGEX;
  }

  let addressValidationRules: { [key: string]: object } = {};
  if (props.isMandatory) {
    addressValidationRules = {
      line1: {
        presence: {
          allowEmpty: false,
          message: 'Please enter your street address.',
        },
      },
      city: {
        presence: {
          allowEmpty: false,
          message: 'Please enter your City / Town',
        },
        format: {
          pattern: UK_CITY_REGEX,
          message: 'Please enter a valid city/town',
        },
      },
      country: {
        presence: {
          allowEmpty: false,
          message: 'Please enter your Country.',
        },
        list: {
          listdata: props.countries,
          message: 'Please enter a valid country.',
        },
      },
      state: {
        presence: {
          allowEmpty: addressInputs.country.defaultValue !== australianIdInCountry,
          message: 'Please enter your County.',
        },
        format: {
          pattern: stateValidationFormatPattern,
          message: 'Please enter your county',
        },
      },
      zipPostcode: {
        presence: {
          allowEmpty: addressInputs.country.defaultValue !== australianIdInCountry,
          message: 'Please enter your postcode.',
        },
        format: {
          pattern: postCodeValidationFormatPattern,
          message: 'Please enter valid postcode.',
        },
      },
    };
  } else {
    addressValidationRules = {
      line1: {
        presence: {
          allowEmpty: true,
          message: 'Please enter your street address.',
        },
      },
      city: {
        presence: {
          allowEmpty: true,
          message: 'Please enter your City / Town',
        },
      },
      country: {
        presence: {
          allowEmpty: true,
          message: 'Please enter your Country.',
        },
      },
      state: {
        presence: {
          allowEmpty: true,
          message: 'Please enter your County.',
        },
      },
      zipPostcode: {
        presence: {
          allowEmpty: true,
          message: 'Please enter your postcode.',
        },
        format: {
          pattern: postCodeValidationFormatPattern,
          message: 'Please enter valid postcode.',
        },
      },
    };
  }

  // Ensure fields that been viewed only have validation run on them
  const specificValidationRules: { [key: string]: object } = {};
  let viewedFields = viewed.keys();
  // If validate form is true ensure all the fields are validated
  if (props.validateForm) {
    viewedFields = new Set(['line1', 'city', 'country', 'state', 'zipPostcode']).keys();
  }
  for (const viewed of viewedFields) {
    specificValidationRules[viewed] = addressValidationRules[viewed];
  }

  const addressObject: { [key: string]: string } = {};
  for (const key of Object.keys(addressInputs)) {
    addressObject[key] = addressInputs[key].defaultValue;
  }

  let line1FieldHeading: string = LINE1_RESIDENTIAL_FIELD_HEADING;
  if (inputName === 'postal') {
    line1FieldHeading = LINE1_POSTAL_LINE_HEADING;
  }

  const autoCompleteOptions = props.countries.map((value: ListData): SelectOptionType => {
    return { label: value?.name, value: value?.id };
  });

  return (
    <>
      <ROTextField
        fieldlabel={line1FieldHeading}
        disabled={props.disabled}
        name={`${inputName}-line1`}
        value={line1}
        id="residential-line1"
        maxLength={60}
        onChange={(e): void => {
          setLine1(e.target.value);
        }}
        onBlur={(e): void => {
          setLine1(e.target.value);
          onBlur([addressInputs.line1.graphArgumentKey], [e.target.value], ['String'], ['line1']);
        }}
      />
      <ROTextField
        fieldlabel={LINE2_HEADING}
        disabled={props.disabled}
        name={`${inputName}-line2`}
        value={line2}
        id="residential-line2"
        maxLength={60}
        onChange={(e): void => {
          setLine2(e.target.value);
        }}
        onBlur={(e): void => {
          onBlur([addressInputs.line2.graphArgumentKey], [e.target.value], ['String'], ['line2']);
        }}
      />
      <ROTextField
        disabled={props.disabled}
        fieldlabel={CITYSUBURB_FIELD_HEADING}
        name={`${inputName}-city`}
        id="residential-city"
        value={city}
        maxLength={40}
        onChange={(e): void => {
          setCity(e.target.value);
        }}
        onBlur={(e): void => {
          onBlur([addressInputs.city.graphArgumentKey], [e.target.value], ['String'], ['city']);
        }}
      />
      <ROAutocomplete
        inputProps={{
          name: `${inputName}-country`,
        }}
        id="residential-country"
        fieldlabel={COUNTRY_FIELD_HEADING}
        placeholder="Type to search"
        options={autoCompleteOptions}
        value={getOptionByValue(autoCompleteOptions, addressInputs.country.defaultValue)}
        onChange={(option: SelectOptionType | string): void => {
          const value = typeof option === 'string' ? option : option?.value;
          updateStateWithCountry(value);
        }}
      />
      <ROTextField
        fieldlabel={REGION}
        disabled={props.disabled}
        id="residential-state"
        name={`${inputName}-state`}
        value={state}
        maxLength={100}
        onChange={(e: any): void => {
          setAddressState(e.target.value);
          viewed.add('state');
        }}
        onBlur={(e): void => {
          onBlur([addressInputs.state.graphArgumentKey], [e.target.value], ['String'], ['state']);
        }}
      />
      <ROTextField
        disabled={props.disabled}
        fieldlabel={ZIP_POSTCODE_HEADING}
        name={`${inputName}-zipPostcode`}
        id="residential-zipPostcode"
        value={zipPostcode}
        maxLength={25}
        onChange={(e): void => {
          setZipPostcode(e.target.value);
        }}
        onBlur={(e): void => {
          onBlur([addressInputs.zipPostcode.graphArgumentKey], [e.target.value], ['String'], ['zipPostcode']);
        }}
      />
    </>
  );
};

export default AddressFieldRO;
