// eslint-disable-next-line no-use-before-define
import { ContentTile, MainLayout } from 'op-pages/PX/Components';

import {
  chemotherapy,
  chemotherapyIcon,
  ctSimulation,
  faq,
  faqIcon,
  radiotherapyIcon,
} from 'shared-components/images/';

import './Information.scss';
import Grid from '@mui/material/Grid';
import Box from '@mui/material/Box';

const Information = (): JSX.Element => {
  return (
    <MainLayout>
      <Box className="px-information-page-container" sx={{ width: '100%' }}>
        <div className="px-information-banner-container">
          <h1 className="px-information-heading">Information</h1>
        </div>
        <div className="px-information-content-outer-container" data-test-id="information-container">
          <Grid container spacing={2} className="px-information-content-inner-container">
            <Grid item lg={3} md={6} sx={{ width: '100%' }}>
              <ContentTile
                title="Frequently Asked Questions"
                url="/px/information/faq"
                image={faq}
                icon={faqIcon}
                testId="faq-content-tile"
              />
            </Grid>
            <Grid item lg={3} md={6} sx={{ width: '100%' }}>
              <ContentTile
                title="Radiotherapy"
                url="/px/information/initial-consultation"
                image={ctSimulation}
                icon={radiotherapyIcon}
                testId="radiotherapy-content-tile"
              />
            </Grid>
            <Grid item lg={3} md={6} sx={{ width: '100%' }}>
              <ContentTile
                title="Chemotherapy"
                url="/px/information/chemotherapy-initial-consultation"
                image={chemotherapy}
                icon={chemotherapyIcon}
                testId="chemotherapy-content-tile"
              />
            </Grid>
          </Grid>
        </div>
      </Box>
    </MainLayout>
  );
};

export default Information;
