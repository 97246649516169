import React from 'react';
import classnames from 'classnames';

import './SideNavContainer.scss';

interface SideNavContainerIProps extends React.HTMLProps<HTMLDivElement> {
  children?: React.ReactNode;
}

const SideNavContainer = (props: SideNavContainerIProps): JSX.Element => {
  const componentId = 'side-nav-container';
  const { children, className, ...otherProps } = props;

  return (
    <div id={componentId} className={classnames(className)} {...otherProps}>
      {children}
    </div>
  );
};

export default SideNavContainer;
