import React, { Component, FocusEvent } from 'react';

import './DTQuestionOtherProblems.scss';

import { DTOtherProblemsItem } from 'op-classes';
import { BaseTextField } from 'shared-components/components/FormFields';

const CONTENT_HEADING = 'Other concerns';

const FIELDS = {
  OTHER_PROBLEMS: {
    NAME: 'otherProblems',
    PLACEHOLDER: 'Start typing...',
    INPUT_HEADING: 'Please specify',
  },
};

interface Props {
  autosave: (updateObject: object) => void;
  data: DTOtherProblemsItem;
  distressId: string;
  patientId: string;
}

class DTQuestionOtherProblems extends Component<Props> {
  public render(): JSX.Element {
    const { autosave, distressId, data, patientId } = this.props;

    return (
      <div id="dt-question-content">
        <div id="dt-other-question-title">{CONTENT_HEADING}</div>
        <div id="dt-other-problems">
          <div style={{ fontWeight: 'bold', padding: '8px 4px' }}>{FIELDS.OTHER_PROBLEMS.INPUT_HEADING}</div>
          <BaseTextField
            id={FIELDS.OTHER_PROBLEMS.NAME}
            multiline
            maxRows={12}
            minRows={12}
            placeholder={FIELDS.OTHER_PROBLEMS.PLACEHOLDER}
            onBlur={(event: FocusEvent<HTMLTextAreaElement>): void => {
              const updateObject = {
                id: distressId,
                patientId,
                otherProblems: event.target.value,
              };
              autosave(updateObject);
            }}
            defaultValue={data.otherProblems}
            sx={{
              height: '400px',
              width: '400px',
            }}
            inputProps={{ style: { fontSize: '16px' } }}
          />
        </div>
      </div>
    );
  }
}

export default DTQuestionOtherProblems;
