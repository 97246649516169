// eslint-disable-next-line no-use-before-define
import React, { Component } from 'react';
import classnames from 'classnames';
import { Link } from 'react-router-dom';

import { Region } from 'shared-components/enums';
import { GreenTickNoBorder } from 'shared-components/images';
import { ErrorOutline as ErrorOutlineIcon } from '@mui/icons-material';

import './StepperButton.scss';
const REACT_APP_REGION = import.meta.env.REACT_APP_REGION;

const region = REACT_APP_REGION;

interface Props {
  linkHref: string;
  linkNumber?: number;
  linkText: string;
  onStepperButtonClick?: () => void;
  isActive: boolean;
  visited?: boolean;
  alert?: boolean;
  disabled?: boolean;
  id?: string;
}

class StepperButton extends Component<Props> {
  public render(): JSX.Element {
    const { linkText, linkNumber, linkHref, onStepperButtonClick, isActive, visited, alert, disabled, id } = this.props;

    return (
      <Link
        id={id || this.generateStepperIDString(linkText)}
        className={classnames(
          'stepper-button',
          { 'stepper-button-UK': region === Region.UK },
          { active: isActive },
          { visited: visited && !isActive },
          { disabled: disabled },
        )}
        onClick={onStepperButtonClick}
        to={linkHref}>
        <div className="link-number-wrapper">
          {alert ? (
            <ErrorOutlineIcon color="warning" />
          ) : (
            <div className="link-number-white-border">
              <div className="link-number">
                {visited && !isActive ? <GreenTickNoBorder className="stepper-tick" /> : linkNumber}
              </div>
            </div>
          )}
        </div>
        <div className="link-text-wrapper">
          <div className={`link-text ${alert ? 'alert-color' : ''} ${disabled ? 'disabled' : ''}`}>{linkText}</div>
        </div>
      </Link>
    );
  }

  /**
   * Function that generates an ID string for a stepper item based on the link text
   * @param {string} linkText Variable that provides the link's text
   * @returns {string} A HTML5 friendly ID string
   */
  private generateStepperIDString = (linkText: string) => {
    const lowercaseText = linkText.toLowerCase();
    const idFriendlyText = lowercaseText.replace(/ /g, '-');
    return `stepper-item-${idFriendlyText}`;
  };
}

export default StepperButton;
