// eslint-disable-next-line no-use-before-define
import React, { Component, Fragment } from 'react';

import classNames from 'classnames';

import './Checkbox.scss';

import { CheckBoxUnselected, CheckBoxSelected, CheckBoxError } from 'shared-components/images';
import { ErrorInfo } from 'shared-components/components/FormFields';

/**
 * Props
 * @property {string} inputName The name of this field.
 * @property {string\JSX.Element} inputLabel The checkbox label.
 * @property {boolean} isChecked True if the checkbox should be checked, false otherwise.
 * @property {string[]} errors The errors that are to be displayed.
 * @property {function} onChange Function that will get called when the checkbox is checked/unchecked.
 */
interface Props {
  inputName: string;
  inputLabel: string | JSX.Element;
  isChecked: boolean;
  errors?: string[];
  onChange: (isChecked: boolean) => void;
  disabled?: boolean;
  labelFirst?: boolean;
  required?: boolean;
}

class Checkbox extends Component<Props> {
  public render(): JSX.Element {
    const { inputName, errors, disabled, labelFirst } = this.props;
    const inError = errors && errors.length > 0;
    const labelId = `checkbox-label-${inputName}`;

    return (
      <Fragment>
        <label
          id={labelId}
          className={classNames('checkbox-container', {
            'validation-error': errors && errors.length > 0,
            'disabled-label': disabled,
            'no-padding': labelFirst,
          })}>
          {this.props.isChecked && !inError && (
            <CheckBoxSelected
              className={classNames('icon checkbox-custom', {
                disabled: this.props.disabled,
                'checkbox-right': labelFirst,
                'checkbox-left': !labelFirst,
              })}
            />
          )}
          {!this.props.isChecked && !inError && (
            <CheckBoxUnselected
              className={classNames('icon checkbox-custom', {
                disabled: this.props.disabled,
                'checkbox-right': labelFirst,
                'checkbox-left': !labelFirst,
              })}
            />
          )}
          {inError && (
            <CheckBoxError
              className={classNames('icon checkbox-custom', {
                'checkbox-right': labelFirst,
                'checkbox-left': !labelFirst,
              })}
            />
          )}
          <input
            type="checkbox"
            name={inputName}
            checked={this.props.isChecked}
            aria-checked={this.props.isChecked}
            onChange={(): void => {
              this.props.onChange(!this.props.isChecked);
            }}
            className={classNames('checkbox', { 'validation-error': inError })}
            disabled={this.props.disabled}
          />
          {this.renderLabel()}
        </label>
        <ErrorInfo errors={errors} />
      </Fragment>
    );
  }

  private renderLabel = (): JSX.Element => {
    const { inputLabel, required } = this.props;
    if (typeof inputLabel === 'string') {
      if (required) {
        return (
          <>
            <p>
              {required && <span style={{ color: 'red' }}>*</span>} <span>{inputLabel}</span>
            </p>
          </>
        );
      }

      return <span>{inputLabel}</span>;
    } else {
      return inputLabel;
    }
  };
}

export default Checkbox;
