import React, { Component, ComponentClass, Fragment } from 'react';
import { gql } from '@apollo/client';

import { Logger } from 'shared-components/utils';

import { GraphQLHelper } from 'op-utils';
import { DocumentNode } from 'graphql';

const logger = new Logger('CovidScreening');

export interface WithCovidScreening {
  getCovidMutation?: (updateObject: object, replaceId?: string) => object;
}

function withCovidScreening<Props>(WrappedComponent: React.ComponentType<Props>): ComponentClass<Props> {
  return class extends Component<Props> {
    public render(): JSX.Element {
      return (
        <Fragment>
          <WrappedComponent
            getCovidMutation={(updateObject: object, replaceId?: string) => {
              return this.getCovidScreeningMutation(updateObject, replaceId);
            }}
            {...this.props}
          />
        </Fragment>
      );
    }

    /**
     * Get the GQL variables that will be ued to call the mutation.
     * @param {object} updateObject The object that contains the keys and values to be updated.
     */
    private getGQLVariables = (covidId: string, updateObject: object): object => {
      // Always need an id for the mutation.
      const variables: { [key: string]: any } = {
        id: covidId,
      };

      for (const updateEntry of Object.entries(updateObject)) {
        const [key, value] = updateEntry;

        // If the value is an object, need to do a deep copy
        variables[key] = typeof value === 'object' ? { ...value } : value;
      }

      logger.debug('getGQLVariables', JSON.stringify(variables));
      return variables;
    };

    /**
     * Function that is used to build the mutation string for distress thermometer. It will accept an object to build the required string.
     * @param {object} updateObject The update object that will build the graphql mutation string
     */
    private getGQLUpdateCovidMutationString = (updateObject: object, replaceId?: string): DocumentNode => {
      const mutationParams = GraphQLHelper.mapObjectToMutationParams(updateObject);
      const functionParams = GraphQLHelper.mapObjectToFunctionParams(updateObject);
      const functionValues = GraphQLHelper.flattenObjectToQueryString(updateObject);

      let graphMutation = `mutation UpdateCovidScreening(${mutationParams}) {
        updateCovidScreening(${functionParams}) {
          covidScreening ${functionValues}
        }
      }`;

      if (replaceId) {
        graphMutation = graphMutation.replace(/#replaceId/g, `"${replaceId}"`);
      }

      logger.debug('getGQLUpdateCovidMutationString', graphMutation);
      return gql(graphMutation);
    };

    private getCovidScreeningMutation = (updateObject: object, replaceId?: string): object => {
      // Check to make sure that the update object has a key defined as id, and if it doesn't display a warning in console for developers.
      // @ts-ignore
      const updateObjectId = updateObject.id;
      if (updateObjectId === null || updateObjectId === undefined || updateObjectId === '') {
        logger.debug(
          'getCovidScreeningMutation',
          '**** MISSING THE ID FROM THE UPDATE OBJECT ****',
          JSON.stringify(updateObject),
        );
      }

      const variables = this.getGQLVariables(updateObjectId, updateObject);
      const covidResponse = { ...variables, __typename: 'CovidScreeningType' };

      return {
        mutation: this.getGQLUpdateCovidMutationString(updateObject, replaceId),
        variables: this.getGQLVariables(updateObjectId, updateObject),
        optimisticResponse: {
          updateCovidScreening: {
            covidScreening: covidResponse,
            errors: null,
            __typename: 'UpdateCovidScreening',
          },
        },
      };
    };
  };
}

export default withCovidScreening;
