import { Box, Typography } from '@mui/material';
import React from 'react';
import { styled } from '@mui/system';

const ExtensionBarComponent = styled('div')`
  border-left: 1px solid rgba(0, 0, 0, 0.2);
`;

const ExtensionBar = () => {
  return (
    <Box component={ExtensionBarComponent} height={1} m={1} width={476}>
      <Box mx={2} my={1} fontWeight="fontWeightBold">
        <Typography variant="h6" gutterBottom>
          Extension Bar
        </Typography>
      </Box>
    </Box>
  );
};

export default ExtensionBar;
