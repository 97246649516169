import { RouteComponentProps } from 'react-router-dom';

export interface ItemProps {
  name: string;
  value: string;
  valueClass?: string;
  linkClass?: string;
  otherValue?: string;
  otherValueClass?: string;
}

export interface TableDataProps extends RouteComponentProps {
  patient: {
    tableData: {
      id: number | string;
    };
  };
}

interface AllowedLocations {
  alias: string;
  id: string;
}
export enum formTypes {
  registration = 'registration',
  covid = 'covid',
}
export interface ReviewRequiredForm {
  id: string;
  ida: string;
  formType: formTypes;
  patientId: string;
  firstName: string;
  lastName: string;
  horizonCenterId?: number | null;
  overallStatus?: string;
  status?: string;
  hasDataConflicts?: boolean;
  updatedAt: string;
}

export interface FormsQueryVariables {
  roles: string[];
  hasOther: boolean;
  filterOption: string;
  start: number;
  limit: number;
}

export interface FormsPaginationType {
  start: number;
  limit: number;
}

export interface FormsTableProps extends RouteComponentProps {
  allowedLocations: AllowedLocations[];
  forms: ReviewRequiredForm[];
  totalCount: number;
  loading: boolean;
  getFormsInReview: (options: any) => void;
  showNewRego?: boolean;
}
