// eslint-disable-next-line no-use-before-define
import React from 'react';
import { HeaderBar } from 'op-components';
import { RouteComponentProps } from 'react-router-dom';

import './HomeRegoEndSession.scss';

const CONTENT = {
  TITLE: 'End session',
  BODY: 'All your information has been saved. If required, you can return to the form(s) by accessing the link in your email.',
};

const HomeRegoEndSession = (props: RouteComponentProps | any): JSX.Element => {
  const { history } = props;
  const isDtEot = history?.location?.state?.isDtEot;

  return (
    <div id="session-end-page">
      <HeaderBar headingText={isDtEot ? '' : 'Patient Registration'} defaultHeader preventQuery={true} />
      <div className="session-container">
        <div id="session-end-image">
          <div className="image"></div>
        </div>
        <main>
          <h1 id="session-title">{CONTENT.TITLE}</h1>
          <p id="session-detail">{CONTENT.BODY}</p>
        </main>
      </div>
      <div className="end-home-bottom-navigator"></div>
    </div>
  );
};
export default HomeRegoEndSession;
