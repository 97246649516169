import React, { Component, FocusEvent, KeyboardEvent } from 'react';

import { ErrorInfo } from 'shared-components/components/FormFields';
import './FreeTextField.scss';

/**
 * Free Text Field Props interface
 * @property {string=} modelValue - default value to be selected on render
 * @property {boolean=} secure - renders a secure text input. False by default.
 * @property {number=} maxLength - default value to be selected on render
 * @property {string} fieldName - name and attribute of the form model this element ties into
 * @property {string} placeholder - string to show when no content is typed in
 * @property {boolean=} invalidInput - manually set if input is invalid if this is handled outside the validateField function
 * @property {boolean=} showErrorMessage - Default false - set if any validation messages are displayed
 * @property {boolean=} disabled - controls whether the input field is disabled. False by default.
 * @property {string} errorMessage - the error message to show if the invalid input has been manually set
 * @property {function} validateField - string to handle any data changes in the field
 * @property {boolean=} validateOnKeyUp - boolean that signifies we should run validation on keyup events
 */
interface Props {
  modelValue?: string;
  secure?: boolean;
  maxLength?: number;
  fieldName: string;
  placeholder?: string;
  invalidInput?: boolean;
  showErrorMessage?: boolean;
  disabled?: boolean;
  errorMessage?: string;
  updateParent?: () => void;
  validateField?: (
    key: string,
    value: string,
    errorHandler: (message: string) => void,
    successHandler: () => void,
  ) => void;
  validateOnKeyUp?: boolean;
}

/**
 * Free Text Field State interface
 * @property {boolean} invalidInput flag to validate the current error state of the component
 * @property {string=} invalidMessage error message containing cause of validation error
 */
interface State {
  invalidInput: boolean;
  invalidMessage: string;
  showPassword: boolean;
}

/**
 * Free Text component. Provides a means to write free text
 */
class FreeTextField extends Component<Props, State> {
  private invalidState = false;
  private invalidMessage = '';

  public static defaultProps = {
    secure: false,
    invalidInput: false,
    showErrorMessage: true,
    disabled: false,
  };

  public constructor(props: Props) {
    super(props);

    const errorMessage = this.props.errorMessage ? this.props.errorMessage : 'An error occurred';
    // Initialise default state. Input is valid by default
    this.invalidState = this.props.invalidInput ? this.props.invalidInput : false;
    this.state = {
      // Set in defaultProps so we can safely upwrap
      /* eslint-disable-next-line */
      invalidInput: this.props.invalidInput!,
      invalidMessage: errorMessage,
      showPassword: false,
    };
  }

  /**
   * Function to handle the on blur event.
   * It calls the function defined by the prop with the values of the field
   */
  private onBlurHandler = (onBlurEvent: FocusEvent<HTMLInputElement>): void => {
    this.onSubmitHandler(onBlurEvent.target.value);
  };
  private onChangeHandler = (onChangeEvent: FocusEvent<HTMLInputElement>): void => {
    this.onSubmitHandler(onChangeEvent.target.value);
  };
  /**
   * Function to handle the on key up event.
   * It calls the function defined by the prop with the values of the field
   */
  private onKeyUpHandler = (onKeyUpEvent: KeyboardEvent<HTMLInputElement>): void => {
    if (this.props.validateOnKeyUp === true) {
      this.onSubmitHandler((onKeyUpEvent.target as HTMLInputElement).value);
    }
  };

  private onSubmitHandler = (value: string): void => {
    const {
      props: { fieldName, validateField },
    } = this;
    if (validateField) {
      validateField(fieldName, value, this.invalidInputHandler, (): void => {
        if (this.props.updateParent) {
          this.props.updateParent();
        } else {
          this.setState({
            invalidInput: false,
          });
        }
      });
    }
  };

  /**
   * Function used to handle an invalid input onBlur
   */
  private invalidInputHandler = (message: string): void => {
    if (this.props.updateParent) {
      this.props.updateParent();
    } else {
      this.setState({
        invalidInput: true,
        invalidMessage: message,
        showPassword: false,
      });
    }
  };

  /**
   * Return's input type (password or text)
   * @returns {string} Input type
   */
  private getInputType(): string {
    if (this.props.secure && !this.state.showPassword) {
      return 'password';
    }
    return 'text';
  }

  public render(): JSX.Element {
    const {
      props: { fieldName, placeholder, modelValue },
    } = this;
    this.invalidState = this.props.invalidInput ? this.props.invalidInput : false;

    // TODO: Remove the props and state once it is all coming through props
    if (this.props.errorMessage) {
      this.invalidMessage = this.props.errorMessage;
    } else if (this.state.invalidMessage) {
      this.invalidMessage = this.state.invalidMessage;
    }

    const inputField = (
      <input
        className={
          'field-input ' + (this.invalidState ? 'validation-error ' : '') + (this.props.secure ? 'password-field' : '')
        }
        type={this.getInputType()}
        name={fieldName}
        placeholder={placeholder || ''}
        onBlur={this.onBlurHandler}
        onChange={this.onChangeHandler}
        defaultValue={modelValue}
        onKeyPress={(event: KeyboardEvent<HTMLInputElement>): void => {
          const code = event.keyCode || event.which;
          const enterKey = 13;
          const value = (event.target as HTMLInputElement).value;
          if (code === enterKey) {
            this.onSubmitHandler(value);
          }
        }}
        onKeyUp={this.onKeyUpHandler}
        maxLength={this.props.maxLength}
        disabled={this.props.disabled}
      />
    );
    const errorPrompt =
      this.invalidState && this.props.showErrorMessage ? <ErrorInfo errors={[this.invalidMessage]} /> : null;

    return (
      <React.Fragment>
        {this.props.secure ? (
          <div className="input-show-hide">
            {inputField}
            <span
              onClick={(): void => {
                this.setState({
                  showPassword: !this.state.showPassword,
                });
              }}>
              {this.state.showPassword ? 'Hide' : 'Show'}
            </span>
            {errorPrompt}
          </div>
        ) : (
          <React.Fragment>
            {inputField}
            {errorPrompt}
          </React.Fragment>
        )}
      </React.Fragment>
    );
  }
}

export default FreeTextField;
