import { FormDataModel } from 'shared-components/interfaces';
import validate from 'validate.js';

// Interface that defines the data model for the Multifactor Auth Form
export interface MFAFormModel extends FormDataModel {
  formData: {
    [key: string]: string;
    pin: string;
  };
}

export default class MFAFormViewModel {
  public mfaFormModel: MFAFormModel;
  private validationRules = {};

  // Configuration options for the validator
  private validationOptions = {
    fullMessages: false,
    format: 'flat',
  };

  public constructor() {
    this.mfaFormModel = {
      formData: {
        pin: '',
      },
    };
  }

  /**
   * Function that updates the form data stored within this object
   * Once updated, it executes a request to Apollo to ensure the database is synced.
   * @param key - Property of MFAFormModel form data object
   * @param newValue - Value to be assigned to the key
   */
  public validateField = (
    key: string,
    newValue: string,
    errorHandler?: (message: string) => void,
    successHandler?: () => void,
  ): void => {
    // Create the data to be validated
    const validationObject: { [key: string]: string } = {};
    validationObject[key] = newValue;

    let invalidMessage: string | undefined = 'errors.invalid_message';
    invalidMessage = validate(validationObject, this.validationRules, this.validationOptions);

    // No validation issues
    if (invalidMessage === undefined) {
      // Call the success handler to update states
      if (successHandler) {
        successHandler();
      }
      // Update the form data object with the new value
      this.mfaFormModel.formData[key] = newValue;
    } else {
      if (errorHandler) {
        errorHandler(invalidMessage[0]);
      }
    }
  };
}
