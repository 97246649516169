import React from 'react';
import { CardTitle, CardBody, CardBodyGroup, CardBodyBold } from './styles';
import CONSTANTS from './constants';
import { region } from 'op-components';
import { Region } from 'shared-components/enums';
import moment from 'moment';

interface InsuranceType {
  expiryDate: string;
  payor: { name: string };
  policyNumber: string;
}
interface Props {
  name: string;
  patientDetails: {
    healthMedicareNumber: string;
    healthMedicareIrn: string;
    medicareExpiryRawMonth: string;
    medicareExpiryRawYear: string;
    insurances: InsuranceType[];
  };
}

const InsuranceCard = ({ name, patientDetails }: Props): JSX.Element => {
  const { healthMedicareNumber, healthMedicareIrn, medicareExpiryRawMonth, medicareExpiryRawYear, insurances } =
    patientDetails;
  const formattedExpiry = medicareExpiryRawMonth + '/' + medicareExpiryRawYear.slice(-2);
  const hasMedicareExpiry = medicareExpiryRawMonth && medicareExpiryRawYear;
  const hasMedicareNumber = healthMedicareNumber && healthMedicareIrn;
  const { MEDICARE_NO, MEDICARE_EXP, INSURANCE, EXPIRATION_DATE } = CONSTANTS;
  const MedicareInfo = (
    <>
      <CardBodyGroup data-testid="otherInfoMedicareNo">
        {MEDICARE_NO}
        <CardBodyBold>{hasMedicareNumber ? `${healthMedicareNumber} ${healthMedicareIrn}` : '-'}</CardBodyBold>
      </CardBodyGroup>
      <CardBodyGroup data-testid="otherInfoMedicareExp">
        {MEDICARE_EXP}
        <CardBodyBold>{hasMedicareExpiry ? `${formattedExpiry}` : '-'}</CardBodyBold>
      </CardBodyGroup>
    </>
  );
  const InsuranceInfo = insurances.map((insurance: InsuranceType) => (
    <>
      <CardBodyGroup data-testid="insuranceCardInsuranceName">
        <CardBodyBold>{insurance.payor?.name || '-'}</CardBodyBold>
      </CardBodyGroup>
      <CardBodyGroup data-testid="insuranceCardInsuranceNumber">
        {INSURANCE}
        <CardBodyBold>{insurance.policyNumber || '-'}</CardBodyBold>
      </CardBodyGroup>
      <CardBodyGroup data-testid="insuranceCardInsuranceExpiry">
        {EXPIRATION_DATE}
        <CardBodyBold>
          {insurance.expiryDate ? `${moment(new Date(insurance.expiryDate)).format('L')}` : '-'}
        </CardBodyBold>
      </CardBodyGroup>
    </>
  ));
  return (
    <div data-testid="insuranceCard">
      <CardTitle>{name}</CardTitle>
      <CardBody>
        {region === Region.AU && MedicareInfo}
        {region === Region.AU && <hr />}
        {InsuranceInfo}
      </CardBody>
    </div>
  );
};

export default InsuranceCard;
