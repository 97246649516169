// eslint-disable-next-line no-use-before-define
import React, { Component } from 'react';

import './PageTitle.scss';

interface Props {
  title: string;
  idPrefix: string;
}

class PageTitle extends Component<Props> {
  public render(): JSX.Element {
    const { title, idPrefix } = this.props;

    return (
      <h2 id={`${idPrefix}-page-title`} className="form-page-title">
        {title}
      </h2>
    );
  }
}

export default PageTitle;
