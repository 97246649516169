const INVALID_SECTIONS = {
  BASIC: 'basicDetails',
  CONTACT: 'contactDetails',
  ADDRESS: 'address',
  ALT_CONTACT: 'alternateContact',
  UK_ALT_CONTACT: 'altContactInfo',
  GP: 'gpInformation',
  UK_GP: 'gpInfo',
  MEDICARE: 'medicare',
  DEMOGRAPHICS: 'demographics',
  UK_DEMOGRAPHICS: 'ukDemographics',
  INFORMATION_NOTICE: 'informationNotice',
  PREFERENCES: 'preferences',
  INSURANCE: 'insurance',
  CLINIC: 'clinic',
  CLINIC_PAYOR: 'clinicPayor',
  PATIENT: 'patient',
  REFERRAL: 'referral',
  APPOINTMENT: 'appointment',
  ATTACHMENTS: 'attachments',
};

const MEDICARE_TYPES = {
  MEDICARE: 'healthFundMedicare',
  MEDICARE_DVA: 'healthFundMedicareDVA',
  NONE: 'healthFundNone',
};

export { INVALID_SECTIONS, MEDICARE_TYPES };
