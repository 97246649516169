import { History } from 'history';
import { MainLayout } from 'op-pages/PX/Components';
import { memo } from 'react';
import { useHistory, useLocation } from 'react-router-dom';
import FeedbackContent from './FeedbackContent';

const Feedback = (): JSX.Element => {
  const history = useHistory<History>();
  const location = useLocation<any>();

  const handleBackButtonPressed = (): void => {
    history.replace(location?.state?.from);
  };

  return (
    <MainLayout clickLogoFn={handleBackButtonPressed}>
      <FeedbackContent />
    </MainLayout>
  );
};

export default memo(Feedback);
