import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import { HorizonWhiteLogo } from 'shared-components/images';

import './HorizonLogoNavigator.scss';

interface Props {
  linkToSearch?: boolean;
}

class HorizonLogoNavigator extends Component<Props> {
  public render() {
    const { linkToSearch } = this.props;

    if (linkToSearch) {
      return (
        <Link to="/search" id="gc-logo-navigator">
          <HorizonWhiteLogo id="gc-logo-navigator-svg" className="icon" />
        </Link>
      );
    } else {
      return <HorizonWhiteLogo id="gc-logo-navigator-svg" className="icon" />;
    }
  }
}

export default HorizonLogoNavigator;
