import React from 'react';
import Button from '@mui/material/Button';
import { Typography, DialogContentText } from '@mui/material';
import { BannerType, OnTreatmentReviewType } from './Interface';
import dayjs from 'dayjs';
import { StandardDialog } from 'shared-components/components';

interface DiscardDialogProps {
  discardCallback: () => void;
  cancelCallback: () => void;
  open: boolean;
  bannerType: BannerType;
  review: OnTreatmentReviewType;
}

const DiscardDialog = ({
  discardCallback,
  cancelCallback,
  bannerType,
  open,
  review,
}: DiscardDialogProps): JSX.Element => {
  const title = bannerType === BannerType.DRAFT ? 'Discard draft' : 'Discard unsubmitted changes';
  const bodyText =
    bannerType === BannerType.DRAFT ? (
      <>
        <Typography variant="body1" style={{ fontSize: '16px' }}>
          You are about to discard the changes to {review.careplan}?
        </Typography>
      </>
    ) : (
      <>
        <Typography variant="body1" style={{ fontSize: '16px' }}>
          You'll lose all unsubmitted changes, and revert to the last submitted version at{' '}
          {dayjs(review.lastSubmittedAt).format('DD MMM YYYY, hh:mm A')}.
        </Typography>
        <Typography variant="body1" style={{ fontSize: '16px' }}>
          Are you sure you want to discard unsubmitted changes?
        </Typography>
      </>
    );
  const okText = bannerType === BannerType.DRAFT ? 'DISCARD DRAFT' : 'DISCARD CHANGES';
  return (
    <React.Fragment>
      <StandardDialog
        open={open}
        onClose={cancelCallback}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
        title={title}
        onSubmit={discardCallback}
        destructiveSubmit
        submitText={okText}>
        <DialogContentText id="alert-dialog-description">{bodyText}</DialogContentText>
      </StandardDialog>
    </React.Fragment>
  );
};

export default DiscardDialog;
