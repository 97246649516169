// eslint-disable-next-line no-use-before-define
import React, { Component, Fragment, RefObject } from 'react';
import { gql } from '@apollo/client';
import { Mutation } from '@apollo/client/react/components';
import { useIdleTimer } from 'react-idle-timer';

export const HEARTBEAT = gql`
  mutation Heartbeat {
    heartbeat {
      success
    }
  }
`;

const IdleTimer = (props: any): JSX.Element => {
  const { onAction, throttle, timeout, events, crossTab } = props;
  useIdleTimer({
    onAction,
    throttle,
    timeout,
    events,
    crossTab,
  });

  return <div></div>;
};

/**
 * Provide heartbeat mutations to the server to let it know the user is still performing actions
 */
class Heartbeat extends Component {
  private idleTimer: RefObject<JSX.Element> | null;

  public constructor(props: {}) {
    super(props);
    this.idleTimer = null;
  }

  public render(): JSX.Element {
    return (
      <Fragment>
        <Mutation mutation={HEARTBEAT}>
          {(heartbeat: any): JSX.Element => {
            return (
              <IdleTimer
                ref={(ref: RefObject<JSX.Element>) => {
                  this.idleTimer = ref;
                }}
                events={['mousedown', 'touchstart', 'MSPointerDown']}
                element={document}
                onAction={heartbeat}
                throttle={5000}
                timeout={1000 * 60 * 15}
              />
            );
          }}
        </Mutation>
      </Fragment>
    );
  }
}

export default Heartbeat;
