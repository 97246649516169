import { gql } from '@apollo/client';

export const REGISTRATION_REFERRER_QUERY = gql`
  query RegistrationReferrer($id: ID!) {
    patient(id: $id) {
      id
      primaryProvider
      referringProvider
      advancedDirective
      powerOfAttorney
      livingWill
    }
    user {
      id
      isPso
    }
  }
`;

export const UPDATE_REGISTRATION_REFERRER = gql`
  mutation UpdatePatientUsReferrer(
    $id: ID!
    $primaryProvider: String
    $referringProvider: String
    $advancedDirective: Boolean
    $powerOfAttorney: Boolean
    $livingWill: Boolean
  ) {
    updatePatientUsReferrer(
      id: $id
      primaryProvider: $primaryProvider
      referringProvider: $referringProvider
      advancedDirective: $advancedDirective
      powerOfAttorney: $powerOfAttorney
      livingWill: $livingWill
    ) {
      patient {
        id
        primaryProvider
        referringProvider
        advancedDirective
        powerOfAttorney
        livingWill
      }
    }
  }
`;
