// eslint-disable-next-line no-use-before-define
import React, { Component } from 'react';
import classNames from 'classnames';
import { RouteComponentProps, withRouter } from 'react-router-dom';

import ROContentContainerBody from 'op-components/RO/ContentContainer/ContentContainerBody';
import { UserContext, UserContextType } from 'op-contexts';
import Accordion from '@mui/material/Accordion';
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { useTheme } from '@mui/material';
import { theme } from 'theme';

import './UKROStepper.scss';
import { Typography } from '@mui/material';

interface Props extends RouteComponentProps {
  data: any;
  activePageKey: string;
  validationObject: any;
}

const ROStepper = (props: Props) => {
  const { data, activePageKey, validationObject, history } = props;
  const theme = useTheme();
  return (
    <div className="ro-stepper-wrapper">
      <div className="carepath-steps-container" style={{ backgroundColor: theme.palette.grey[300] }}>
        {data.sections.map((section: any, index: number) => {
          return (
            <ROStepperSection
              onPageClick={(path: string) => history.push(path)}
              section={section}
              sectionKey={index}
              activePageKey={activePageKey}
              validationObject={validationObject}
              data={data}
            />
          );
        })}
      </div>
    </div>
  );
};

interface SectionProps {
  section: any;
  sectionKey: number;
  activePageKey: string;
  onPageClick: any;
  validationObject: any;
  data: any;
}

interface SectionState {
  open: boolean;
}

class ROStepperSection extends Component<SectionProps, SectionState> {
  public constructor(props: SectionProps) {
    super(props);
    this.state = {
      open: true,
    };
  }
  public render(): JSX.Element {
    const { section, sectionKey, activePageKey, validationObject, data } = this.props;
    return (
      <UserContext.Consumer>
        {(userContext: UserContextType): JSX.Element => (
          <div className="carepath-step-container" key={sectionKey}>
            <Accordion
              defaultExpanded
              sx={{
                backgroundColor: 'transparent',
                border: 'none',
                boxShadow: 'none',
              }}>
              <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                <Typography variant="button" color={theme.palette.grey[600]}>
                  <b>{userContext.state.navShow ? section.title : section.short}</b>
                </Typography>
              </AccordionSummary>
              <AccordionDetails sx={{ padding: '0px' }}>
                <ROContentContainerBody className="carepath-step-body" isCompact>
                  <div className="timeline-container compact">
                    {section.pages.map((page: any, index: number) => {
                      return (
                        <ROStepperPage
                          onPageClick={this.props.onPageClick}
                          page={page}
                          pageKey={parseInt('' + this.props.sectionKey + index)}
                          activePageKey={activePageKey}
                          validationObject={validationObject}
                          data={data}
                        />
                      );
                    })}
                  </div>
                </ROContentContainerBody>
              </AccordionDetails>
            </Accordion>
          </div>
        )}
      </UserContext.Consumer>
    );
  }
}

interface PageProps {
  page: any;
  pageKey: number;
  activePageKey: string;
  onPageClick: any;
  validationObject: any;
  data: any;
}

class ROStepperPage extends Component<PageProps> {
  private getActivePage = () => {
    const { data, activePageKey } = this.props;
    let activePage: any = {};
    data.sections.forEach((section: any) => {
      section.pages.forEach((page: any) => {
        if (page.key === activePageKey) {
          activePage = page;
        }
      });
    });
    return activePage;
  };

  private getValidStatus = (page: any): boolean => {
    const { validationObject } = this.props;

    const allProps = validationObject[page.key];
    for (const key of Object.keys(allProps)) {
      if (allProps[key]) {
        return false;
      }
    }
    return true;
  };
  public render(): JSX.Element {
    const { page, pageKey, activePageKey } = this.props;
    const activePage: any = this.getActivePage();
    const futurePage: boolean = page.pos >= activePage.pos;
    const validStatus = this.getValidStatus(page);
    return (
      <div
        className={classNames('timeline-item', {
          current: activePageKey === page.key,
        })}
        key={pageKey}>
        <div className="timeline-item-body">
          <div className="timeline-bubble-container">
            <div
              className={classNames('timeline-bubble', {
                current: activePageKey === page.key,
                complete: activePageKey !== page.key && !futurePage && validStatus,
                red: activePageKey !== page.key && !futurePage && !validStatus,
              })}>
              {futurePage && page.pos}
            </div>
          </div>
          <div className={classNames('timeline-title', { green: false })}>{page.title}</div>
        </div>
      </div>
    );
  }
}

export default withRouter(ROStepper);
