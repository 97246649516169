import { Fragment, useState, useContext, useEffect } from 'react';
import { useFormikContext, Field, FormikProps } from 'formik';
import {
  ROTextField,
  BaseSelect,
  ROHelperText,
  FormRow,
  ROToggleButtons,
  CheckBoxGroup,
  RODatePicker,
} from 'shared-components/components/FormFields';
import { isFieldLowCertainty, LOW_CERTAINTY, ClinexTooltip } from '../Clinex/helpers';
import { FormRowToolTip } from 'shared-components/components/FormFields';
import { ClinexContext } from '../Clinex/context';
import { IntakePageProps, FormikValues } from './Interface';
import {
  GLEASON_LEVEL_OPTIONS,
  POSITIVE_SITE_OPTIONS,
  PSMA_OPTIONS,
  YES_NO_OPTIONS,
  getIntakeISUPGrade,
} from './helpers';
import { ChildSectionWrapper } from 'shared-components/components/FormFields/common';
import dayjs from 'dayjs';
import { Dayjs } from 'dayjs';
import ClinexFieldWrapper from '../Clinex/ClinexFieldWrapper';
import { Stack } from '@mui/system';

const ROPatientDiagnosisIntakeUrogenitalPage = ({
  updateIntakeHandler,
  setDateError,
}: IntakePageProps): JSX.Element => {
  const { values }: FormikProps<FormikValues> = useFormikContext();
  const clinexContexts = useContext(ClinexContext);
  const { clinexData } = clinexContexts;
  const tooltip = { header: 'PSA', body: "The PSA level recorded should be the patient's maximum PSA result" };
  const [psa, setPsa] = useState(values?.intake?.urogenital?.psa);

  useEffect(() => {
    // Some absolute jank to get around the initial formik values being null
    if (psa === undefined) setPsa(values?.intake?.urogenital?.psa);
  }, [values?.intake?.urogenital]);

  if (values.intake?.urogenital) {
    const { gleasonOne, gleasonTwo, isupGrade, psaDate, prostatectomy, psma, positiveSite } = values.intake.urogenital;

    if (isupGrade === '' && gleasonOne && gleasonTwo) {
      updateIntakeHandler({ isupGrade: getIntakeISUPGrade(gleasonOne.toString(), gleasonTwo.toString()) });
    }

    return (
      <Fragment>
        <ClinexFieldWrapper field="gleason_score_highest">
          <FormRow id="row-intakeGleasonOne" fieldlabel="Gleason" alignItems="center">
            <Stack gap="4px" width="400px">
              <ClinexTooltip
                clinexData={clinexData}
                clinexField={'gleason_score_highest'}
                formValues={values.intake.urogenital}>
                <Stack direction="row" alignItems="center" gap="8px">
                  <Field
                    name="intakeGleasonOne"
                    component={BaseSelect}
                    id="intakeGleasonOne"
                    options={GLEASON_LEVEL_OPTIONS}
                    placeholder="-"
                    value={gleasonOne ? gleasonOne.toString() : '-'}
                    onChange={(event: React.ChangeEvent<HTMLInputElement>): void => {
                      const value = event.target.value;
                      let isupGrade = '';
                      if (value && gleasonTwo) {
                        const score = getIntakeISUPGrade(value || '0', gleasonTwo.toString());
                        isupGrade = `${score}`;
                      }
                      updateIntakeHandler({ gleasonOne: parseInt(value) || 0, isupGrade });
                    }}
                    sx={{
                      width: '70px',
                    }}
                  />
                  <span>+</span>
                  <Field
                    name="intakeGleasonTwo"
                    component={BaseSelect}
                    id="intakeGleasonTwo"
                    options={GLEASON_LEVEL_OPTIONS}
                    value={gleasonTwo ? gleasonTwo.toString() : '-'}
                    placeholder="-"
                    onChange={(event: React.ChangeEvent<HTMLInputElement>): void => {
                      const value = event.target.value;
                      let isupGrade = '';
                      if (gleasonOne && value) {
                        const score = getIntakeISUPGrade(gleasonOne.toString(), value || '0');
                        isupGrade = `${score}`;
                      }
                      updateIntakeHandler({ gleasonTwo: parseInt(value) || 0, isupGrade });
                    }}
                    sx={{
                      width: '70px',
                    }}
                  />
                  {gleasonOne && gleasonTwo ? (
                    <Fragment>
                      <span>=</span>
                      <span id="intakeGleasonTotal">{parseInt(gleasonOne, 10) + parseInt(gleasonTwo, 10)}</span>
                    </Fragment>
                  ) : null}
                </Stack>
              </ClinexTooltip>
              <ROHelperText
                id="intakeGleasonOne"
                warning={isFieldLowCertainty(clinexData, 'gleason_score_highest', values)}
                helperText={
                  isFieldLowCertainty(clinexData, 'gleason_score_highest', values) ? LOW_CERTAINTY : undefined
                }
              />
            </Stack>
          </FormRow>
        </ClinexFieldWrapper>
        <Field
          name="intakeISUPGrade"
          component={ROTextField}
          id="intakeISUPGrade"
          fieldlabel="ISUP Grade"
          value={
            isupGrade
              ? `Grade ${isupGrade}`
              : gleasonOne && gleasonTwo
              ? `Grade ${getIntakeISUPGrade(gleasonOne.toString(), gleasonTwo.toString())}`
              : ''
          }
          disabled
        />
        <ClinexFieldWrapper field="total_psa">
          <ClinexTooltip clinexData={clinexData} clinexField={'total_psa'} formValues={values.intake.urogenital}>
            <Field
              name="intakePSA"
              component={ROTextField}
              fieldlabel="PSA"
              id="intakePSA"
              tooltip={<FormRowToolTip tooltip={tooltip} />}
              value={psa}
              onChange={(event: React.ChangeEvent<HTMLInputElement>): void => {
                setPsa(event.target.value);
              }}
              onBlur={(): void => {
                updateIntakeHandler({ psa: psa || '' });
              }}
              fullWidth
              warning={isFieldLowCertainty(clinexData, 'total_psa', values)}
              helperText={LOW_CERTAINTY}
              sx={{
                width: '400px',
              }}
            />
          </ClinexTooltip>
        </ClinexFieldWrapper>
        <ClinexFieldWrapper field="total_psa_date">
          <RODatePicker
            id="psaDate"
            fieldlabel="PSA Date"
            disableFuture
            value={psaDate ? dayjs(psaDate) : null}
            onChange={(date: Dayjs | null, context: any) => {
              if (context.validationError) {
                setDateError(context.validationError);
                return;
              }
              const dateString = date ? dayjs(date).format('YYYY-MM-DD') : null;
              updateIntakeHandler({ psaDate: dateString });
            }}
            onError={setDateError}
            warning={isFieldLowCertainty(clinexData, 'total_psa_date', values)}
            helperText={isFieldLowCertainty(clinexData, 'total_psa_date', values) ? LOW_CERTAINTY : undefined}
            sx={{ marginRight: '150px' }}
          />
        </ClinexFieldWrapper>
        <ROToggleButtons
          id="psma"
          fieldlabel={'PSMA'}
          warning={isFieldLowCertainty(clinexData, 'psma', values)}
          helperText={LOW_CERTAINTY}
          options={PSMA_OPTIONS}
          value={psma || ''}
          handleChange={(value: string): void => {
            if (value !== 'Positive') {
              updateIntakeHandler({ psma: value, positiveSite: null });
            } else {
              updateIntakeHandler({ psma: value });
            }
          }}
        />
        {psma === 'Positive' && (
          <ChildSectionWrapper>
            <FormRow id="positiveSite" fieldlabel="Positive Sites">
              <CheckBoxGroup
                id="positiveSite"
                value={positiveSite || []}
                options={POSITIVE_SITE_OPTIONS}
                onChange={(value: string[]): void => {
                  updateIntakeHandler({ positiveSite: value });
                }}
              />
            </FormRow>
          </ChildSectionWrapper>
        )}
        <ClinexFieldWrapper field="prostatectomy">
          <ROToggleButtons
            id="prostatectomy"
            fieldlabel={'Prostatectomy'}
            warning={isFieldLowCertainty(clinexData, 'prostatectomy', values)}
            helperText={LOW_CERTAINTY}
            options={YES_NO_OPTIONS}
            value={prostatectomy || ''}
            handleChange={(value: string): void => {
              updateIntakeHandler({ prostatectomy: value });
            }}
          />
        </ClinexFieldWrapper>
      </Fragment>
    );
  }
  return <> </>;
};

export default ROPatientDiagnosisIntakeUrogenitalPage;
