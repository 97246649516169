// eslint-disable-next-line no-use-before-define
import React, { Fragment, useContext } from 'react';
import UserContext, { UserContextType } from '../../contexts/UserContext/UserContext';
import { RoleType } from 'op-enums';

import ROSidePatientNav from './ROSidePatientNav';
import PSOSidePatientNav from 'op-components/SideNav/PSOSidePatientNav';
import NurseSidePatientNav from './NurseSidePatientNav';

interface Props {
  patientId: any;
  refreshChartSummary?: any;
  createManagementPlan?: any;
  createReferralExistingService?: any;
  disableCreateButton?: boolean;
  showButtons?: boolean;
}

const SidePatientNav = (props: Props): JSX.Element => {
  const { state } = useContext<UserContextType>(UserContext);
  const { RO, PSO, NURSE } = RoleType;

  const sideComponentMap = {
    [RO]: ROSidePatientNav,
    [PSO]: PSOSidePatientNav,
    [NURSE]: NurseSidePatientNav,
  };

  if (state.primaryRole) {
    const Card = sideComponentMap[state.primaryRole];
    return <Card {...props} />;
  }

  return <Fragment />;
};

export default SidePatientNav;
