import { useTheme } from '@mui/material';
import React from 'react';

export interface CircledCrossProps {
  id?: string;
  className?: string;
}

const CircledCross = ({ id, className }: CircledCrossProps): JSX.Element => {
  const theme = useTheme();
  return (
    <svg id={id} width="16" height="16" viewBox="0 0 16 16" xmlns="http://www.w3.org/2000/svg" className={className}>
      <path
        id="cross"
        fillRule="evenodd"
        clipRule="evenodd"
        d="M0 8C0 3.584 3.584 0 8 0C12.416 0 16 3.584 16 8C16 12.416 12.416 16 8 16C3.584 16 0 12.416 0 8ZM5.73726 11.3936L8 9.13091L10.2627 11.3936L11.3941 10.2623L9.13137 7.99953L11.3941 5.73679L10.2627 4.60542L8 6.86816L5.73726 4.60542L4.60589 5.73679L6.86863 7.99953L4.60589 10.2623L5.73726 11.3936Z"
        fill={theme.palette.grey[300]}
      />
    </svg>
  );
};

export default CircledCross;
