import React from 'react';
import { styled } from '@mui/system';

interface StyledRowProps {
  width?: string;
}

const StyledRow = styled('div')<StyledRowProps>`
  display: flex;
  flex-direction: row;
  align-items: center;
  width: ${(props: StyledRowProps): string => props?.width || '100%'};
`;

interface Props extends React.HTMLAttributes<HTMLDivElement> {
  children: React.ReactNode;
  width?: string;
}

const StyledFieldRow = ({ children, ...other }: Props): JSX.Element => {
  return <StyledRow {...other}>{children}</StyledRow>;
};

export default StyledFieldRow;
