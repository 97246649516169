import { gql } from '@apollo/client';
import { HEALTH_ASSESSMENT_ALLERGY_FRAGMENT } from 'op-graphql/fragments';

export const HA_ALLERGY_QUERY = gql`
  query HealthAssessment($patientId: ID!) {
    healthAssessment(id: $patientId) {
      id
      allergyBool
      allergies {
        id
        ...HealthAssessmentAllergy
      }
    }
    sensitivityRefData: listData(category: "sensitivity") {
      id
      name
    }
    reactionRefData: listData(category: "reaction") {
      id
      name
    }
  }

  ${HEALTH_ASSESSMENT_ALLERGY_FRAGMENT}
`;
