const SegmentedInputBoolean = [
  { id: '1', name: 'Yes' },
  { id: '2', name: 'No' },
];

const SegementedInputYesNoUnsure = [
  { id: 'YES', name: 'Yes' },
  { id: 'NO', name: 'No' },
  { id: 'UNSURE', name: 'Unsure' },
];

const SegementedInputGender = [
  { id: 'FEMALE', name: 'Female' },
  { id: 'MALE', name: 'Male' },
  { id: 'NOT STATED', name: 'Not stated' },
];

export { SegmentedInputBoolean, SegementedInputYesNoUnsure, SegementedInputGender };
