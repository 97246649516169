import { useQuery } from '@apollo/client';
import { SummaryCard, SummaryRow } from 'op-components';
import { evaluateDetailText } from 'op-components/OP/SummaryRow/SummaryRow';
import { renderArrow } from 'op-pages/OP/HealthAssessment/HASharedComponents/HAModalFields';
import { HA_OPERATIONS_QUERY } from 'op-pages/OP/HealthAssessment/HASharedComponents/queries';
import {
  HAOperation,
  HAOperationItem,
  HASubComponentProps,
} from 'op-pages/OP/HealthAssessment/HASharedComponents/types';
import React, { Fragment, useMemo } from 'react';
import { Edit, Success } from 'shared-components/images';
import { SerializedListDataObject } from 'shared-components/interfaces';
import { displayRawDate } from 'shared-components/utils';
import { convertListDataToObject } from 'shared-components/utils/ListDataHelpers';
import { OP_FIELDS } from 'op-pages/OP/HealthAssessment/HAPages/constants';

const HAOperationsUS = (props: HASubComponentProps) => {
  const { patientId, isPso, navigate } = props;

  const { data: haOperationsData, loading } = useQuery(HA_OPERATIONS_QUERY, {
    variables: { patientId },
  });
  const operationsRefDataSerialized = useMemo<SerializedListDataObject>(
    () => convertListDataToObject(haOperationsData?.operationsRefData),
    [haOperationsData?.operationsRefData],
  );
  const data = useMemo<HAOperation>(() => haOperationsData?.healthAssessment, [haOperationsData]);

  if (loading) {
    return <></>;
  }

  if (haOperationsData) {
    return (
      <SummaryCard
        id="operations"
        title={'Operations'}
        primaryIcon={isPso ? <></> : <Edit className="primary-icon" />}
        secondaryIcon={<Success className="secondary-icon valid-icon" />}
        showEdit={!isPso}
        onClick={() => {
          if (!isPso) {
            navigate('operations');
          }
        }}>
        <SummaryRow
          headingText={OP_FIELDS.PREV_OPERATION.TITLE}
          detailText={evaluateDetailText(data.prevOperation)}
          displayAsError={false}
          displayAsOptional={false}
        />
        {data.prevOperation && (
          <>
            {renderArrow()}
            {data.operations.map((operation: HAOperationItem, index: number) => {
              const operationName = operationsRefDataSerialized[operation.operationSpecify];
              const operationIsOther = operationName?.toLowerCase() === 'Other'.toLowerCase();
              const operationIsPacemaker = operationName?.toLowerCase() === 'Pacemaker placement'.toLowerCase();

              return (
                <Fragment key={index}>
                  <SummaryRow
                    headingText={`Operation ${index + 1}`}
                    detailText={evaluateDetailText(operationName)}
                    displayAsError={false}
                    displayAsOptional={false}
                    subQuestion
                  />
                  <SummaryRow
                    headingText={'Operation date'}
                    detailText={displayRawDate(
                      operation.operationRawMonth,
                      operation.operationRawYear,
                      operation.operationRawDay,
                    )}
                    displayAsError={false}
                    displayAsOptional={false}
                    subQuestion
                  />
                  {operationIsOther && operation.operationOther && (
                    <SummaryRow
                      headingText={'Description'}
                      detailText={evaluateDetailText(operation.operationOther)}
                      displayAsError={false}
                      displayAsOptional={false}
                      subQuestion
                    />
                  )}
                  {operationIsPacemaker && (
                    <SummaryRow
                      headingText={'Type/Model'}
                      detailText={evaluateDetailText(operation.operationOther)}
                      displayAsError={false}
                      displayAsOptional={false}
                      subQuestion
                    />
                  )}
                </Fragment>
              );
            })}
          </>
        )}
      </SummaryCard>
    );
  }
  return <></>;
};

export default HAOperationsUS;
