import React from 'react';
import { styled } from '@mui/system';

import { Badge } from 'shared-components/components';

const Title = styled('h3')`
  font-size: 18px;
  color: ${(props) => props.theme.palette.primary.dark};
  margin-bottom: 12px;
`;

const Container = styled('div')`
  display: flex;
  overflow-x: hidden;
  margin-bottom: 10px;
  min-height: 32px;

  &:hover {
    overflow-x: overlay;
  }
`;

const OptionContainer = styled('div')`
  display: flex;
  flex-direction: column;
  margin: 0 16px 0 0;
`;

const OptionButton = styled('button')<{ $active: boolean }>`
  border: none;
  background: none;
  padding: 0;
  margin-bottom: 4px;
  white-space: nowrap;
  font-weight: ${({ $active }: { $active: boolean }) => ($active ? 'bold' : 'normal')};
`;

const OptionText = styled('div')`
  margin-bottom: 4px;
  font-size: 16px;
  display: flex;
  align-items: center;
`;

const ActiveOption = styled('div')`
  width: 100%;
  height: 3px;
  background-color: ${(props) => props.theme.palette.primary.dark};
`;

const TabSelectHeader = (props: any): JSX.Element => {
  const { options, title, onClickHandler, activeIndex, setActiveIndex } = props;

  const scrollIntoView = (e: any) => {
    if (e?.target) {
      e.target.scrollIntoView({ behaviour: 'smooth' });
    }
  };

  return (
    <>
      <Title>{title}</Title>
      <Container>
        {options.map((option: any, index: number) => {
          const { text, notification } = option;
          const activeTab = activeIndex === index;
          return (
            <OptionContainer key={`${text}-${index}`}>
              <OptionButton
                type="button"
                onClick={(e: any) => {
                  setActiveIndex(index);
                  scrollIntoView(e);
                  onClickHandler(e);
                }}
                $active={activeTab}>
                <OptionText>
                  {text}
                  {notification && <Badge $bgColor={notification.color}>{notification.text}</Badge>}
                </OptionText>
              </OptionButton>
              {activeTab && <ActiveOption />}
            </OptionContainer>
          );
        })}
      </Container>
    </>
  );
};

export default TabSelectHeader;
