import {
  syncConfig,
  chartSpacingConfig,
  creditsConfig,
  exportingConfig,
  handlePointClick,
  TopChartParams,
  setHeight,
  selectPoint,
} from './DefaultConfig';

const columnSeriesMapping = (leftSeriesFields: string[]) => {
  const trimmedFields = leftSeriesFields.slice(1);
  return trimmedFields.map((field, index) => ({
    seriesId: 'series-' + index,
    data: ['Date', field],
  }));
};

const seriesMapping = (leftSeriesFields: string[]) => {
  const trimmedFields = leftSeriesFields.slice(1);

  return trimmedFields.map((field, index) => ({
    id: 'series-' + index,
    type: 'column',
    name: field,
    color: seriesColorMapping[field as keyof typeof seriesColorMapping] || 'rgba(158, 158, 158)',
    fillOpacity: 0.75,
  })) as Highcharts.SeriesOptionsType[];
};

const seriesColorMapping: { [key: string]: string } = {
  IMRT: 'rgba(170, 161, 253)',
  VMAT: 'rgba(62, 167, 178)',
  Stereotactic: 'rgba(255, 189, 91)',
  Conformal: 'rgba(251, 106, 118)',
  Brachytherapy: 'rgba(128, 0, 128)',
  Electrons: 'rgba(89, 39, 218)',
};

const ChartStackedColumnPercent = ({
  onPointClick,
  selectedPoint,
  selectedIndex,
  seeMoreUpdated,
  leftSeriesTitle,
  leftSeriesFields,
  rightSeriesFields,
}: TopChartParams): Dashboards.Board.Options => ({
  components: [
    {
      cell: 'top-chart',
      connector: {
        id: 'top-chart-data',
        columnAssignment: columnSeriesMapping(leftSeriesFields).concat({
          seriesId: 'series-' + leftSeriesFields.length + 1,
          data: rightSeriesFields,
        }),
      },
      sync: syncConfig,
      type: 'Highcharts',
      chartOptions: {
        exporting: exportingConfig,
        chart: {
          type: 'column',
          animation: false,
          spacing: chartSpacingConfig,
          styledMode: false,
          events: {
            load() {
              setHeight(this);
            },
            redraw() {
              selectPoint(this, selectedPoint, selectedIndex);
            },
          },
        },
        title: { text: '' },
        xAxis: {
          type: 'category',
        },
        yAxis: {
          title: { text: leftSeriesTitle },
          max: 100,
          endOnTick: false,
          labels: {
            format: '{value}%',
          },
        },
        legend: {
          itemStyle: {
            fontSize: '12px',
          },
        },
        credits: creditsConfig,
        tooltip: {
          formatter: function () {
            let tooltip = '';
            this.points!.forEach(function (point) {
              if (!point.series.name.includes('VS')) {
                tooltip += `${point.series.name}: ${point.y?.toFixed(0)} plans<br/>`;
              }
            });

            return tooltip;
          },
          shared: true,
        },
        series: seriesMapping(leftSeriesFields).concat({
          id: 'series-' + leftSeriesFields.length + 1,
          type: 'spline',
          name: 'IMRT/VMAT/Stereotactic plans VS total plans percentage',
          marker: {
            enabled: false,
          },
          color: 'rgba(245, 0, 255, 1)',
        }),
        plotOptions: {
          column: {
            stacking: 'percent',
          },
          series: {
            cursor: 'pointer',
            animation: selectedPoint === '' && !seeMoreUpdated,
            states: {
              select: {
                enabled: false,
              },
            },
            point: {
              events: {
                click: function () {
                  handlePointClick(this, onPointClick);
                },
              },
            },
          },
        },
      },
    },
  ],
});

export default ChartStackedColumnPercent;
