// eslint-disable-next-line no-use-before-define
import React, { Component } from 'react';

import { PatientCoverage } from 'op-interfaces';
import { ListData } from 'shared-components/interfaces';
import { SavingStatus } from 'shared-components/enums';
import validate from 'shared-components/utils/validate';

import { RegistrationContainer } from 'op-components';
import {
  AutoSuggestTextField,
  DropDownField,
  FreeTextField,
  SectionField,
} from 'shared-components/components/FormFields';

const FORM_HEADING = 'Insurance';

interface State {
  viewed: Set<string>;
}

const FIELD_INFO = {
  PAYOR: {
    NAME: 'payor',
    TITLE: 'Insurance company',
    KEY: 'payor',
    IS_MANDATORY: false,
    MISSING_INPUT_ERROR: 'Insurance company is mandatory if you entered a policy number',
    INVALID_INPUT_ERROR: 'Please select an insurance company',
    MAX_FIELD_LENGTH: 100,
    PLACEHOLDER: 'Start typing to search',
  },
  RELATIONSHIP: {
    NAME: 'coverageRelationship',
    TITLE: 'Patient relationship to policy holder',
    KEY: 'coverageRelationship',
    IS_MANDATORY: false,
    MISSING_INPUT_ERROR: 'Please select relationship',
    INVALID_INPUT_ERROR: 'Please select relationship',
    PLACEHOLDER: 'Please select relationship',
  },
  PRE_AUTH_NUMBER: {
    NAME: 'preAuthNumber',
    TITLE: 'Pre auth number',
    KEY: 'preAuthNumber',
    IS_MANDATORY: false,
    MISSING_INPUT_ERROR: '',
    MAX_FIELD_LENGTH: 100,
    PLACEHOLDER: '',
  },
  POLICY_NUMBER: {
    NAME: 'policyNumber',
    TITLE: 'Insurance policy number',
    KEY: 'policyNumber',
    IS_MANDATORY: false,
    MISSING_INPUT_ERROR: '',
    MAX_FIELD_LENGTH: 100,
    PLACEHOLDER: '',
  },
};

const VALIDATION_FIELDS = [FIELD_INFO.PAYOR.KEY, FIELD_INFO.POLICY_NUMBER.KEY, FIELD_INFO.RELATIONSHIP.KEY];

interface Props {
  autosave: (patient: PatientCoverage, key: string, value: string) => Promise<void>;
  coverage: PatientCoverage;
  genderRefData: ListData[];
  insurerRefData: ListData[];
  relationshipRefData: ListData[];
  saveStatus: SavingStatus;
  validateOnLoad: boolean;
  isPso: boolean;
}

interface ValidationObject {
  [key: string]: string[];
  payor: string[];
}

class RegistrationInsurance extends Component<Props, State> {
  public constructor(props: Props) {
    super(props);
    this.state = {
      viewed: new Set(),
    };
  }

  public static getDerivedStateFromProps(props: Props, state: State): State {
    if (props.validateOnLoad && props.coverage.lock && !props.coverage.lock.readOnly) {
      const fields = Object.keys(VALIDATION_FIELDS).map((keyName: string) => {
        // @ts-ignore
        return VALIDATION_FIELDS[keyName];
      });
      const viewed = new Set(fields);
      return { viewed: viewed };
    }

    return state;
  }

  public render(): JSX.Element {
    const { coverage, genderRefData, isPso, saveStatus } = this.props;
    return (
      <RegistrationContainer patient={coverage} genderRefData={genderRefData} isPso={isPso} saveStatus={saveStatus}>
        {this.renderRegistrationBasicDetailsForm(coverage)}
      </RegistrationContainer>
    );
  }

  /**
   * Autosave a field and it's values to the database
   * @param patient
   * @param key
   * @param value
   * @param type
   */
  private autosave = async (
    patient: PatientCoverage,
    key: string,
    value: string,
    validateOnBlur = true,
  ): Promise<void> => {
    this.props.autosave(patient, key, value).then(() => {
      if (validateOnBlur) {
        const viewed = this.state.viewed.add(key);
        this.setState({ viewed });
      }
    });
  };

  private validateObject = (patient: PatientCoverage): any => {
    const { insurerRefData, relationshipRefData } = this.props;

    // Global rules for the validator, must match the patient interface keys
    const globalValidationRules: { [key: string]: object } = {
      payor: {
        presence: {
          allowEmpty: !patient.policyNumber,
          message: FIELD_INFO.PAYOR.MISSING_INPUT_ERROR,
        },
        list: {
          listdata: insurerRefData,
          message: FIELD_INFO.PAYOR.INVALID_INPUT_ERROR,
        },
      },
      policyNumber: {
        presence: {
          allowEmpty: !FIELD_INFO.POLICY_NUMBER.IS_MANDATORY,
          message: FIELD_INFO.POLICY_NUMBER.MISSING_INPUT_ERROR,
        },
      },
      coverageRelationship: {
        presence: {
          allowEmpty: !FIELD_INFO.RELATIONSHIP.IS_MANDATORY,
          message: FIELD_INFO.RELATIONSHIP.MISSING_INPUT_ERROR,
        },
        list: {
          listdata: relationshipRefData,
          message: FIELD_INFO.RELATIONSHIP.INVALID_INPUT_ERROR,
        },
      },
    };

    // Ensure fields that been viewed only have validation run on them
    const specificValidationRules: { [key: string]: object } = {};
    for (const viewed of this.state.viewed.keys()) {
      specificValidationRules[viewed] = globalValidationRules[viewed];
    }
    // Disable pre-appending of argument name to error messages
    const disableFullMessages = { fullMessages: false };
    // Run validation on all the fields
    return validate(patient, specificValidationRules, disableFullMessages);
  };

  /**
   * Renders the registration form
   * @param {Patient['patient']} patient The patient object for the form we are updating
   * @return {JSX.Element}
   */
  // FIXME: The eslint ignore is only here till insurance gets fixed
  /* eslint-disable-next-line */
  private renderRegistrationBasicDetailsForm(patient: PatientCoverage): JSX.Element {
    const { insurerRefData, coverage, relationshipRefData } = this.props;

    const validationObject: ValidationObject = this.validateObject(coverage);

    return (
      <div className="form-page">
        <form className="form-container">
          <div className="form-heading basic-details">{FORM_HEADING}</div>
          <SectionField
            isValid={validationObject && validationObject.payor ? false : true}
            htmlFor={FIELD_INFO.PAYOR.NAME}
            title={FIELD_INFO.PAYOR.TITLE}>
            <div style={{ width: '100%' }}>
              <AutoSuggestTextField
                disabled={coverage.lock && coverage.lock.readOnly}
                inputName={FIELD_INFO.PAYOR.NAME}
                suggestionList={insurerRefData}
                maxLength={FIELD_INFO.PAYOR.MAX_FIELD_LENGTH}
                placeholder={FIELD_INFO.PAYOR.PLACEHOLDER}
                onBlur={(value): void => {
                  this.autosave(coverage, FIELD_INFO.PAYOR.KEY, value);
                }}
                defaultValue={coverage.payor}
                errors={validationObject && validationObject.payor ? validationObject.payor : undefined}
              />
            </div>
          </SectionField>
          <SectionField
            isValid={validationObject && validationObject.coverageRelationship ? false : true}
            htmlFor={FIELD_INFO.RELATIONSHIP.NAME}
            title={FIELD_INFO.RELATIONSHIP.TITLE}>
            <DropDownField
              disabled={coverage.lock && coverage.lock.readOnly}
              inputName={FIELD_INFO.RELATIONSHIP.NAME}
              placeholder={FIELD_INFO.RELATIONSHIP.PLACEHOLDER}
              defaultValue={coverage.coverageRelationship}
              options={relationshipRefData}
              onChange={(e): Promise<void> => this.autosave(coverage, FIELD_INFO.RELATIONSHIP.KEY, e.target.value)}
              errors={
                validationObject && validationObject.coverageRelationship
                  ? validationObject.coverageRelationship
                  : undefined
              }
            />
          </SectionField>
          <SectionField
            isValid={validationObject && validationObject.preAuthNumber ? false : true}
            htmlFor="preAuthNumber"
            title={FIELD_INFO.PRE_AUTH_NUMBER.TITLE}>
            <FreeTextField
              disabled={patient.lock && patient.lock.readOnly}
              inputName={FIELD_INFO.PRE_AUTH_NUMBER.NAME}
              placeholder={FIELD_INFO.PRE_AUTH_NUMBER.PLACEHOLDER}
              defaultValue={patient.preAuthNumber}
              onBlur={(e): Promise<void> => this.autosave(patient, 'preAuthNumber', e.target.value)}
              errors={validationObject && validationObject.preAuthNumber ? validationObject.preAuthNumber : undefined}
            />
          </SectionField>
          <SectionField
            isValid={validationObject && validationObject.policyNumber ? false : true}
            htmlFor={FIELD_INFO.POLICY_NUMBER.NAME}
            title={FIELD_INFO.POLICY_NUMBER.TITLE}>
            <FreeTextField
              disabled={coverage.lock && coverage.lock.readOnly}
              inputName={FIELD_INFO.POLICY_NUMBER.NAME}
              maxLength={30}
              placeholder={FIELD_INFO.POLICY_NUMBER.PLACEHOLDER}
              defaultValue={coverage.policyNumber}
              onBlur={(e): Promise<void> => this.autosave(coverage, FIELD_INFO.POLICY_NUMBER.NAME, e.target.value)}
              errors={validationObject && validationObject.policyNumber ? validationObject.policyNumber : undefined}
            />
          </SectionField>
        </form>
      </div>
    );
  }
}

export default RegistrationInsurance;
