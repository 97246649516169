import { Stack } from '@mui/material';
import React from 'react';
import { StandardDialog } from 'shared-components/components';

interface Props {
  startingCycle: number;
  maxCycles: number;
  isOpen: boolean;
  onCancel: () => void;
  onConfirm: () => void;
}
const ProtocolCyclesErrorModal = (props: Props): JSX.Element => {
  return (
    <StandardDialog
      maxWidth="sm"
      open={props.isOpen}
      title="Protocol cycles exceeded"
      onClose={props.onCancel}
      onSubmit={props.onConfirm}
      submitText="Confirm">
      <Stack paddingY={2} gap={2}>
        <div
          style={{
            display: 'grid',
            gridAutoFlow: 'row',
            gridTemplateColumns: '1fr 0.6fr',
            gridTemplateRows: '1fr 1fr',
            gap: '16px 16px',
            fontWeight: 700,
          }}>
          <div>Total protocol cycles:</div>
          <div>{props.maxCycles}</div>
          <div>Starting cycle:</div>
          <div>{props.startingCycle}</div>
        </div>
        <div>The starting cycle will be a copy of the last protocol cycle.</div>
      </Stack>
    </StandardDialog>
  );
};

export default ProtocolCyclesErrorModal;
