// eslint-disable-next-line no-use-before-define
import React, { Component } from 'react';
import { RouteComponentProps, withRouter, Redirect } from 'react-router-dom';

import './DTQuestions.scss';

import { StepperLink } from 'shared-components/interfaces';
import { DT_CATEGORY } from 'op-enums';

import { Stepper } from 'shared-components/components';
import DTQuestionContentApollo from './DTQuestionContent/DTQuestionContentApollo';
import DTQuestionOtherProblemsApollo from './DTQuestionOtherProblems/DTQuestionOtherProblemsApollo';

const RATING_PAGES = ['physical', 'emotional', 'social', 'practical', 'spiritual', 'other'];

interface Props extends RouteComponentProps<{ ratingPage: string }> {
  stepperLinks: StepperLink[];
  stepperBasePath: string;
  visitedPages: { [key: string]: number };
}

class DTQuestions extends Component<Props> {
  public render(): JSX.Element {
    // Redirect the user to the patient login if the url does not match the constants
    if (!this.checkRatingPage()) {
      return <Redirect to="/patient" />;
    }

    const {
      match: {
        params: { ratingPage },
      },
      stepperBasePath,
      stepperLinks,
      visitedPages,
    } = this.props;

    // Based on the DT page we're on, we query different set of answers/questions
    // Convert the url param into a DT category
    let category = DT_CATEGORY.NONE;
    for (const categoryEnum in DT_CATEGORY) {
      //@ts-ignore
      if (DT_CATEGORY[categoryEnum] === ratingPage) {
        //@ts-ignore
        // This category is used as the variable for our content render
        category = DT_CATEGORY[categoryEnum];
        break;
      }
    }
    const showQuestionContent = category !== DT_CATEGORY.OTHER && category !== DT_CATEGORY.NONE;
    const showOtherProblems = category === DT_CATEGORY.OTHER;

    return (
      <div id="dt-question-root">
        <Stepper links={stepperLinks} basePath={stepperBasePath} visitedLinks={visitedPages} />
        {showOtherProblems && <DTQuestionOtherProblemsApollo />}
        {showQuestionContent && <DTQuestionContentApollo />}
      </div>
    );
  }

  private checkRatingPage = (): boolean => {
    const {
      match: {
        params: { ratingPage },
      },
    } = this.props;

    // Check if the rating page exists in the RATING_PAGE constant
    return RATING_PAGES.includes(ratingPage);
  };
}

const routedComponent = withRouter(DTQuestions);
export default routedComponent;
