// eslint-disable-next-line no-use-before-define
import React, { useContext } from 'react';
import { Button, Grid, MenuItem, Stack, Typography } from '@mui/material';
import { AddCircleOutline, RemoveCircleOutline } from '@mui/icons-material';
import { BaseTextField, BaseSelect, ROTwoValueTextField } from 'shared-components/components/FormFields';
import { TenantContext } from 'op-contexts';

interface FieldValue {
  dosetoPrefixOperator: string | undefined;
  firstValue: string | undefined;
  firstValueUnit: string | undefined;
  operator: string | undefined;
  secondValue: string[] | undefined;
  secondValueUnit: string | undefined;
}

interface ROConstraintRowProps {
  id: string;
  label: string;
  placeholder?: string;
  value: FieldValue;
  required?: boolean;
  disabled?: boolean;
  onChange?: (index: number, value: FieldValue) => void;
  onBlur?: () => void;
  block?: boolean;
  index: number;
  isVolumeData: boolean;
  length: number;
  addButton?: boolean;
  percentage?: boolean;
  removeConstraint: () => void;
}

interface Props {
  id: string;
  label: string;
  placeholder?: string;
  value: FieldValue[];
  required?: boolean;
  readOnly?: boolean;
  onChange?: (index: number, value: FieldValue) => void;
  onBlur?: () => void;
  block?: boolean;
  validated?: boolean | undefined;
  invalid?: boolean;
  percentage?: boolean;
  isVolumeData: boolean;

  addConstraint?: () => void;
  removeConstraint?: (item: any) => void;
}

const WIDTH = '79px';
const LESS_THAN_OR_EQUAL = '≤';

const CONSTRAINT_OPERATOR_OPTIONS = [
  { value: LESS_THAN_OR_EQUAL, label: LESS_THAN_OR_EQUAL },
  { value: '<', label: '<' },
];

const VOL_SECOND_VALUE_UNIT_OPTIONS = [
  { value: '%', label: '%' },
  { value: 'cc', label: 'cc' },
];

const D_FIRST_VALUE_UNIT_OPTIONS = [
  { value: '%', label: '%' },
  { value: 'cc', label: 'cc' },
];

const ROConstraintRow = (props: ROConstraintRowProps) => {
  const { id, required } = props;
  const label: string = props.index === 0 ? props.label : ' ';
  const { doseUnit } = useContext(TenantContext);

  const objValue = props.value;
  const firstValueUnit = props.value.firstValueUnit || doseUnit;
  const secondValueUnit = props.value.secondValueUnit || '%';
  const operator = props.value.operator || LESS_THAN_OR_EQUAL;
  const isVolumeData = props.isVolumeData;

  const valueUnitOptions = [
    { value: doseUnit, label: doseUnit },
    { value: '%', label: '%' },
  ];

  const firstValueUnitOptions = isVolumeData ? valueUnitOptions : D_FIRST_VALUE_UNIT_OPTIONS;
  const secondValueUnitOptions = isVolumeData ? VOL_SECOND_VALUE_UNIT_OPTIONS : valueUnitOptions;
  return (
    <Stack direction="row" paddingBottom={1} alignItems="center">
      <Stack direction="row" width={'200px'} minWidth={'200px'} justifyContent="space-between" alignItems="center">
        <Typography data-testid={`label-${id}`} variant="body1">
          {required ? `${label}*` : label}
        </Typography>
        {!isVolumeData && (
          <BaseTextField
            size="small"
            id={`${id}-prefixOperator`}
            select
            value={objValue.dosetoPrefixOperator}
            disabled={props.disabled}
            sx={{ width: '4rem', marginRight: '4px' }}
            onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
              if (props.onChange) {
                objValue.dosetoPrefixOperator = event.target.value;
                props.onChange(props.index, objValue);
              }
            }}>
            <MenuItem value="">blank</MenuItem>
            <MenuItem value=">">{'>'}</MenuItem>
          </BaseTextField>
        )}
      </Stack>
      <Grid container>
        <Grid item container xs={10}>
          <Grid item container gap="4px">
            <Grid item xs={2}>
              <BaseTextField
                id={`firstValue-${id}`}
                type="number"
                value={props.value.firstValue || ''}
                onChange={(e: React.ChangeEvent<HTMLInputElement>): void => {
                  const value = e.target.value;
                  if (props.onChange) {
                    objValue.firstValue = value || undefined;
                    props.onChange(props.index, objValue);
                  }
                }}
                disabled={props.disabled}
                onBlur={(): void => {
                  if (props.onBlur) props.onBlur();
                }}
              />
            </Grid>
            <Grid item>
              <BaseSelect
                id={`firstValueUnit-${id}`}
                onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                  const value = event.target.value;
                  if (props.onChange) {
                    objValue.firstValueUnit = value;
                    props.onChange(props.index, objValue);
                  }
                }}
                value={firstValueUnit}
                options={firstValueUnitOptions}
                disabled={props.disabled}
                sx={{ width: WIDTH }}
              />
            </Grid>
            <Grid item>
              <div className="oar-operator">
                <div className="oar-vertical-border-line"></div>
                <BaseSelect
                  id={`oarOperator-${id}`}
                  onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                    const value = event.target.value;
                    if (props.onChange) {
                      objValue.operator = value;
                      props.onChange(props.index, objValue);
                    }
                  }}
                  value={operator}
                  options={CONSTRAINT_OPERATOR_OPTIONS}
                  disabled={props.disabled}
                  sx={{ width: WIDTH }}
                />
                <div className="oar-vertical-border-line"></div>
              </div>
            </Grid>
            <Grid item md={3}>
              <ROTwoValueTextField
                id={`secondValue-${id}`}
                value={props.value.secondValue || ['']}
                onChange={(values: string[]): void => {
                  if (props.onChange) {
                    objValue.secondValue = values || undefined;
                    props.onChange(props.index, objValue);
                  }
                }}
                onBlur={(): void => {
                  if (props.onBlur) props.onBlur();
                }}
                readOnly={props.disabled}
              />
            </Grid>
            <Grid item>
              <BaseSelect
                id={`secondValueUnit-${id}`}
                onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                  const value = event.target.value;
                  if (props.onChange) {
                    objValue.secondValueUnit = value;
                    props.onChange(props.index, objValue);
                  }
                }}
                value={secondValueUnit}
                options={secondValueUnitOptions}
                disabled={props.disabled}
                sx={{ width: WIDTH }}
              />
            </Grid>
          </Grid>
        </Grid>
        {props.length > 1 && (
          <Grid item xs={2}>
            <Button
              variant="text"
              onClick={(): void => props.removeConstraint()}
              startIcon={<RemoveCircleOutline color="primary" />}>
              Remove
            </Button>
          </Grid>
        )}
        {props.length <= 1 && (
          <Grid item xs={2}>
            <div></div>
          </Grid>
        )}
      </Grid>
    </Stack>
  );
};

const ROConstraintField = (props: Props) => {
  const { doseUnit } = useContext(TenantContext);

  const onAddConstraint = (): void => {
    props.addConstraint && props.addConstraint();
  };

  const onRemoveConstraint = (item: any): void => {
    props.removeConstraint && props.removeConstraint(item);
  };

  const { id, label } = props;

  const valueList =
    props.value && props.value.length
      ? props.value
      : [
          {
            firstValue: undefined,
            firstValueUnit: props.isVolumeData ? doseUnit : '%',
            operator: LESS_THAN_OR_EQUAL,
            secondValue: undefined,
            secondValueUnit: props.isVolumeData ? '%' : doseUnit,
          },
        ];

  return (
    <div>
      {valueList.map((item: any, index: number): any => (
        <ROConstraintRow
          key={label + index}
          removeConstraint={() => onRemoveConstraint(index)}
          index={index}
          value={item}
          id={`${id}-${index}`}
          placeholder={props.placeholder}
          label={props.label}
          onChange={props.onChange}
          disabled={props.readOnly}
          length={valueList.length}
          isVolumeData={props.isVolumeData}
        />
      ))}
      <Stack id="imagingAdd" marginLeft={'200px'} width={'fit-content'}>
        <Button
          style={{ display: 'flex', alignItems: 'center' }}
          onClick={(): void => {
            onAddConstraint();
          }}
          variant="text"
          startIcon={<AddCircleOutline color="primary" />}
          disabled={props.readOnly}>
          Add constraint
        </Button>
      </Stack>
    </div>
  );
};

export default ROConstraintField;
