import React from 'react';
import { TextField, StandardTextFieldProps, useTheme } from '@mui/material';
import ROHelperText from '../ROHelperText/ROHelperText';
import { getBorderColor } from '../common';

export interface Props extends StandardTextFieldProps {
  maxLength?: number;
  warning?: boolean;
  info?: boolean;
  helperText?: string;
  alwaysShowHelper?: boolean;
  neverShowHelper?: boolean; // TODO: come up with a better way of handling helper text
  fieldLabel?: string;
}

/*
  Simple wrapper for the MUI text field to apply the correct border colors
  and other desired styles. Use this if you want the standard text field
  but don't need the label/wrapping for use in a form that comes with
  the ROTextField
*/
const BaseTextField = ({
  error,
  warning,
  info,
  helperText,
  neverShowHelper,
  alwaysShowHelper,
  sx,
  size,
  maxLength,
  inputProps,
  autoComplete,
  fieldLabel,
  ...rest
}: Props): JSX.Element => {
  const borderColor = getBorderColor(error, warning, info);
  const showHelperText = !neverShowHelper && (alwaysShowHelper || error || warning || info);

  const helperTextProps = {
    error,
    warning,
    info,
    helperText,
    id: rest.id,
  };
  const borderOverrides =
    error || warning || info
      ? {
          '.MuiOutlinedInput-notchedOutline': {
            border: `1px solid ${borderColor} !important`,
          },
          ':hover .MuiOutlinedInput-notchedOutline': {
            border: `2px solid ${borderColor} !important`,
          },
        }
      : {
          '.MuiOutlinedInput-notchedOutline': {},
          ':hover .MuiOutlinedInput-notchedOutline': {},
        };
  const textFieldSx = {
    ...{
      'input[type="number"]::-webkit-inner-spin-button': {
        '-webkit-appearance': 'none',
        margin: 0,
      },
      'input[type="number"]::-webkit-outer-spin-button': {
        '-webkit-appearance': 'none',
        margin: 0,
      },
      '.MuiFormHelperText-root': {
        marginLeft: 0,
      },
    },
    // Need the border overrides so that warning, error and info borders work
    ...borderOverrides,
    ...sx,
  };
  const textFieldInputProps = {
    ...{ maxLength: maxLength || 200, 'data-testid': rest.id }, // Not sure why defaulting to 200 chars ????
    ...inputProps,
  };
  return (
    <TextField
      sx={textFieldSx}
      inputProps={textFieldInputProps}
      size={size || 'small'}
      label={fieldLabel}
      autoComplete={autoComplete || 'off'}
      helperText={showHelperText ? <ROHelperText {...helperTextProps} /> : undefined}
      {...rest}
    />
  );
};

export default BaseTextField;
