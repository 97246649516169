import { FormikValues, validateYupSchema, yupToFormErrors } from 'formik';
import { CurrentAppConfig } from 'op-pages/RO/Careplan/AppConfig';
import { EMAIL_LENGTH_VALIDATION, EMAIL_VALIDATION } from 'op-pages/Shared/constants';
import { AU_PHONE_REGEX, EMAIL_STRING_REGEX } from 'shared-components/utils/Regex';
import * as Yup from 'yup';
import { PHONE_REQUIRED, PHONE_VALIDATION, VALUE_REQUIRED } from '../Helper';

export const generateValidationSchema = (values: FormikValues): any => {
  const multiFieldSchema = Yup.object().shape(
    {
      firstName: Yup.string().ensure().required(VALUE_REQUIRED),
      lastName: Yup.string().ensure().required(VALUE_REQUIRED),
      relationship: Yup.string().ensure().required(VALUE_REQUIRED),
      authorisedForEnquiries: Yup.string().ensure().required(VALUE_REQUIRED),
      supportPerson: Yup.string().ensure().required(VALUE_REQUIRED),
      email: Yup.string()
        .matches(EMAIL_STRING_REGEX, EMAIL_VALIDATION)
        .max(CurrentAppConfig.EmailMaxLength, EMAIL_LENGTH_VALIDATION),
      nextOfKinContact: Yup.object()
        .nullable()
        .when('altContactProvided', {
          is: true,
          then: Yup.object()
            .nullable()
            .shape({
              email: Yup.string()
                .nullable()
                .matches(EMAIL_STRING_REGEX, EMAIL_VALIDATION)
                .max(CurrentAppConfig.EmailMaxLength, EMAIL_LENGTH_VALIDATION),
            }),
        }),
      mobilePhoneNumber: Yup.string()
        .matches(AU_PHONE_REGEX, { message: PHONE_VALIDATION, excludeEmptyString: true })
        .ensure()
        .when('homePhoneNumber', {
          is: '',
          then: Yup.string().required(PHONE_REQUIRED),
        }),
      homePhoneNumber: Yup.string()
        .matches(AU_PHONE_REGEX, { message: PHONE_VALIDATION, excludeEmptyString: true })
        .ensure()
        .when('mobilePhoneNumber', {
          is: '',
          then: Yup.string().required(PHONE_REQUIRED),
        }),
    },
    [['mobilePhoneNumber', 'homePhoneNumber']],
  );

  try {
    validateYupSchema<FormikValues>(values, multiFieldSchema, true);
  } catch (err) {
    return yupToFormErrors(err);
  }

  return {};
};
