import React from 'react';
import { HelperMessage } from 'shared-components/components';
import { sharedContent } from '../constants';
import { styled } from '@mui/system';

const MainSection = styled('section')`
  font-size: 1.125rem;
  margin-bottom: 10px;
  line-height: 28px;
`;

const { mainText, helperText } = sharedContent.reviewAndSubmit.proceedToTriage;

const ModalContentProceedToTriage = (): JSX.Element => {
  return (
    <>
      <MainSection>{mainText}</MainSection>
      <HelperMessage fieldName={'proceed-to-triage'} fieldText={helperText} helperType="info" fontSize={13} />
    </>
  );
};

export default ModalContentProceedToTriage;
