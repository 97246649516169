import { styled } from '@mui/system';
import { GridContainer, GridSection } from 'shared-components/components/Grid';
import { Stack, TextField, TextFieldProps, Typography, TypographyProps, Box } from '@mui/material';

const OutcomesWrapper = styled(GridContainer)`
  position: relative;
  display: flex;
`;

const OutcomesContainer = styled(GridSection)`
  width: 100%;
  background-color: white;
`;

const Container = styled('div')`
  height: 100%;
  display: flex;
  flex-direction: column;
`;

const ListItem = styled(Box)`
  min-height: 56px;
  flex-direction: row;
  display: flex;
  align-items: center;
`;

const ListItemDiagnosisTypography = styled('span')`
  font-size: 16px;
  line-height: 20px;
  font-weight: 600;
`;

const ListItemDiagnosisDateTypography = styled('span')`
  font-size: 16px;
  line-height: 20px;
  font-weight: 400;
  padding-left: 32px;
`;

const ListItemContainer = styled(Stack)`
  display: flex;
  flex-direction: row;
  height: 56px;
  background-color: ${(props) => props.theme.palette.grey[100]};
  margin-bottom: 8px;
  margin-top: 8px;
`;

const OutcomeCard = styled('div')<{ outcomecardcount: number }>((props) => ({
  boxSizing: 'border-box',
  background: 'white',
  border: `1px solid ${props.theme.palette.grey[300]}`,
  borderRadius: '4px',
  minHeight: '116px',
  flexBasis: `calc((100% - 16px * ${props.outcomecardcount - 1}) / ${props.outcomecardcount})`,
  padding: '16px',
  '&:hover': {
    cursor: 'pointer',
    backgroundColor: props.theme.palette.action.hover,
  },
}));

const OutcomeCardContainer = styled('div')`
  display: flex;
  flex-wrap: wrap;
  justify-content: flex-start;
  gap: 16px;
`;

const ListItemsContainer = styled('div')`
  overflow: auto;
  &:hover {
    cursor: pointer;
  }
`;

const OutcomeContentRow = styled(Box)`
  display: flex;
  flex-direction: row;
  align-items: center;
`;

const OutcomeCardBody = styled('p')`
  font-size: 16px;
  line-height: 24px;
  font-weight: 400;
  color: ${(props) => props.theme.palette.text.primary};
`;

const ModalErrorMessage = styled('p')`
  font-size: 13px;
  line-height: 15px;
  font-weight: 400;
  color: ${(props) => props.theme.palette.error.main};
`;

const ModalFieldLabel = styled('span')`
  font-size: 14px;
  line-height: 20px;
  font-weight: 700;
  color: ${(props) => props.color};
`;

const ModalDiagnosisTitle = styled('p')`
  font-size: 16px;
  line-height: 24px;
  font-weight: 500;
  color: ${(props) => props.theme.palette.text.primary};
`;

const ModalFormLabel = styled('div')`
  margin-bottom: 8px;
`;

const ModalRowFormControl = styled(Box)`
  display: flex;
  flex-direction: row;
  align-items: flex-end;
`;

const ModalTextField = (props: TextFieldProps): JSX.Element => {
  return <TextField size="small" sx={{ maxWidth: '168px', borderRadius: '4px', border: '1px' }} {...props} />;
};

const ModalTextFieldLabel = (props: TypographyProps): JSX.Element => {
  return <Typography variant="body1" sx={{ marginLeft: '16px' }} {...props} />;
};

const ModalRowValidationMessage = styled(Stack)`
  margin-top: 8px;
`;

const BackButtonWrapper = styled('div')`
  margin-right: 12px;
`;

const OutcomeNoteContainer = styled('div')`
  box-shadow: 0px 2px 8px rgba(113, 110, 106, 0.4);
  margin: 16px;
  width: 30%;
  background-color: white;
  display: flex;
  flex-direction: column;
  padding: 16px;
`;

const NoteTitle = styled('span')`
  font-style: normal;
  font-weight: 700;
  font-size: 18px;
  line-height: 28px;
  color: ${(props) => props.theme.palette.primary.dark};
`;

const NoteAuthor = styled('span')`
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  line-height: 20px;
  align-items: center;
  color: ${(props) => props.theme.palette.text.primary};
`;

const NoteDate = styled('span')`
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
  align-items: center;
  color: ${(props) => props.theme.palette.text.primary};
`;

const NoteType = styled('span')`
  margin-top: 8px;
  background: ${(props) => props.theme.palette.grey[100]};
  border-radius: 16px;
  padding: 0px 8px;
  display: inline-flex;
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 20px;
  align-items: center;
  color: ${(props) => props.theme.palette.text.primary};
  max-width: 100%;
`;

const NoteTypeWrapper = styled('div')`
  display: flex;
  flex-direction: row;
  align-items: center;
`;

const NoteMessage = styled('div')<{ status: string }>((props) => ({
  fontStyle: 'normal',
  fontWeight: 400,
  fontSize: '16px',
  lineHeight: '24px',
  color: props.theme.palette.text.primary,
}));

const NoteMessageContainer = styled('div')<{ status: string }>((props) => ({
  padding: `${props.status !== 'Submitted' ? '8px' : undefined}`,
  height: '100%',
  background: `${props.status !== 'Submitted' ? props.theme.palette.grey[100] : undefined}`,
  border: `${props.status !== 'Submitted' ? `1px solid ${props.theme.palette.grey[300]}` : undefined}`,
  borderRadius: `${props.status !== 'Submitted' ? '4px' : undefined}`,
  marginTop: '8px',
  overflow: 'auto',
}));

const NoteSubHeading = styled('span')`
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
  color: ${(props) => props.theme.palette.grey[600]};
  margin-top: 8px;
`;

const NoteMetaDataContainer = styled('div')`
  display: flex;
  justify-content: space-between;
  margin-top: 8px;
`;

export {
  OutcomesWrapper,
  OutcomesContainer,
  Container,
  ListItem,
  ListItemContainer,
  OutcomeCard,
  OutcomeContentRow,
  OutcomeCardContainer,
  OutcomeCardBody,
  ModalErrorMessage,
  ModalFieldLabel,
  ModalDiagnosisTitle,
  ModalFormLabel,
  ModalRowFormControl,
  ModalTextField,
  ModalTextFieldLabel,
  ModalRowValidationMessage,
  ListItemsContainer,
  ListItemDiagnosisTypography,
  ListItemDiagnosisDateTypography,
  BackButtonWrapper,
  OutcomeNoteContainer,
  NoteTitle,
  NoteAuthor,
  NoteDate,
  NoteType,
  NoteMessage,
  NoteMessageContainer,
  NoteSubHeading,
  NoteMetaDataContainer,
  NoteTypeWrapper,
};
