import React, { useEffect } from 'react';
import { useQuery, useMutation } from '@apollo/client';
import { useParams, useHistory } from 'react-router-dom';

import { useErrorModalContext } from 'op-contexts';
import { DistressThermometerVersion, FormStatus } from 'op-enums';
import { LoadingSpinner } from 'shared-components/components';

import FormContext from './contexts';
import {
  HEALTH_ASSESSMENT_QUERY,
  REGISTRATION_FORM_QUERY,
  DT_RATING_QUERY,
  LATEST_SUBMITTED_CHART_CHECK_QUERY,
} from './graphql/OtherAssessmentQueries';
import { PRE_CT_TRIAGE_QUERY, UPDATE_PRE_CT_TRIAGE, SUBMIT_PRE_CT_TRIAGE } from './graphql/PreCtTriageQueries';

import PreCtTriage from './PreCtTriage';
import { QUERY_NURSE } from '../../PatientFormSummary/PatientFormSummaryQueries';
import { GET_ALLOWED_LOCATIONS } from '../shared/queries';

const PreCtTriageApollo = (): JSX.Element => {
  const { patientId, formId } = useParams<{ patientId: string; formId: string }>();
  const { setError } = useErrorModalContext();

  const history = useHistory();

  const {
    loading: dtLoading,
    data: distressThermometerData,
    error: dtError,
  } = useQuery(DT_RATING_QUERY, {
    variables: { patientId: patientId, version: DistressThermometerVersion.INITIAL, status: FormStatus.REG_SUBMITTED },
  });

  const {
    loading: registrationFormLoading,
    data: registrationFormData,
    error: registrationFormError,
  } = useQuery(REGISTRATION_FORM_QUERY, {
    variables: { id: patientId },
  });

  const {
    loading: healthAssessmentLoading,
    data: healthAssessmentData,
    error: healthAssessmentError,
  } = useQuery(HEALTH_ASSESSMENT_QUERY, {
    variables: { id: patientId, status: FormStatus.REG_SUBMITTED },
  });

  const {
    loading: patientDetailsLoading,
    data: patientDetailsData,
    error: patientDetailsError,
  } = useQuery(QUERY_NURSE, {
    variables: { id: patientId },
  });

  const {
    loading: preCtTriageLoading,
    data: preCtTriageData,
    error: preCtTriageError,
    refetch: refetchPreCtTriage,
  } = useQuery(PRE_CT_TRIAGE_QUERY, {
    variables: { id: formId, patientId: patientId },
  });

  const {
    loading: allowedLocationsLoading,
    data: allowedLocationsData,
    error: allowedLocationsError,
  } = useQuery(GET_ALLOWED_LOCATIONS, {
    variables: { roles: ['Nurse'], hasOther: false },
  });

  const {
    data: latestChartCheckData,
    refetch: fetchLatestChartCheck,
    error: latestChartCheckDataError,
  } = useQuery(LATEST_SUBMITTED_CHART_CHECK_QUERY, {
    variables: { patientId: patientId },
  });

  useEffect(() => {
    if (
      dtError ||
      registrationFormError ||
      healthAssessmentError ||
      patientDetailsError ||
      preCtTriageError ||
      allowedLocationsError ||
      latestChartCheckDataError
    )
      return setError();
  }, [
    dtError,
    registrationFormError,
    healthAssessmentError,
    patientDetailsError,
    preCtTriageError,
    allowedLocationsError,
    latestChartCheckDataError,
  ]);

  const [updatePreCtTriage] = useMutation(UPDATE_PRE_CT_TRIAGE);
  const [submitPreCtTriage] = useMutation(SUBMIT_PRE_CT_TRIAGE, {
    refetchQueries: [{ query: PRE_CT_TRIAGE_QUERY, variables: { id: formId, patientId } }],
  });

  const mutations = {
    updatePreCtTriage,
    submitPreCtTriage,
    refetchPreCtTriage,
  };

  const isDataLoading =
    dtLoading &&
    registrationFormLoading &&
    healthAssessmentLoading &&
    patientDetailsLoading &&
    preCtTriageLoading &&
    allowedLocationsLoading;

  const isDataAvailable =
    distressThermometerData &&
    registrationFormData &&
    healthAssessmentData &&
    patientDetailsData &&
    preCtTriageData &&
    preCtTriageData.preCtTriage &&
    allowedLocationsData;

  if (preCtTriageData && !preCtTriageData.preCtTriage) {
    history.push(`/patient/${patientId}/summary`);
  }

  return (
    <>
      {isDataLoading && <LoadingSpinner />}
      {isDataAvailable && (
        <FormContext.Provider value={{ mutations, typeOfResidenceRefData: preCtTriageData.typeOfResidenceRefData }}>
          <PreCtTriage
            allowedLocationsData={allowedLocationsData.allowedLocations}
            distressThermometerData={distressThermometerData.distressThermometer}
            registrationFormData={registrationFormData}
            healthAssessmentData={healthAssessmentData}
            patientDetailsData={patientDetailsData.patient}
            preCtTriageData={preCtTriageData.preCtTriage}
            loggedInUserData={patientDetailsData && patientDetailsData.user}
            fetchLatestChartCheck={fetchLatestChartCheck}
            latestChartCheckData={latestChartCheckData && latestChartCheckData.lastSubmittedPreCtChartCheck}
          />
        </FormContext.Provider>
      )}
    </>
  );
};

export default PreCtTriageApollo;
