// eslint-disable-next-line no-use-before-define
import React, { useEffect, useContext } from 'react';
import { styled } from '@mui/system';
import { useQuery, useMutation } from '@apollo/client';
import { FileContext } from 'op-contexts';
import { GET_UPLOAD_DETAILS, CREATE_ATTACHMENT, GET_PATIENT_ATTACHMENTS } from './queries';
import { UPLOAD_ERRORS } from './constants';
import { ErrorCross } from 'shared-components/images';
import { Logger } from 'shared-components/utils';
import { useTheme } from '@mui/material';
import { CheckCircleOutlined as CheckCircleOutlinedIcon } from '@mui/icons-material';

const logger = new Logger('UploadToS3');

interface Props {
  index: number;
  patientId: string;
  userId: string;
}

const FileNameWrapper = styled('div')`
  display: flex;
  flex-direction: column;
  margin-top: 5px;
`;

const SuccessName = styled('div')`
  font-size: 16px;
  line-height: 24px;
  cursor: pointer;
  text-decoration-line: underline;
  color: ${(props) => props.theme.palette.info.main};
`;

const SuccessContainer = styled('div')`
  display: flex;
  flex-direction: row;
  font-size: 13px;
  line-height: 15px;
  align-items: center;
  color: black;
`;

const LoadingName = styled('div')`
  font-size: 16px;
  line-height: 24px;
`;

const LoadingContainer = styled('div')`
  display: flex;
  flex-direction: row;
  font-size: 13px;
  line-height: 15px;
  align-items: center;
  color: black;
`;

const FailedName = styled('div')`
  font-size: 16px;
  line-height: 24px;
  color: ${(props) => props.theme.palette.error.main};
`;

const FailedContainer = styled('div')`
  display: flex;
  flex-direction: row;
  font-size: 13px;
  line-height: 15px;
  align-items: center;
  color: ${(props) => props.theme.palette.error.main};
`;

const SuccessIcon = styled(CheckCircleOutlinedIcon)`
  width: 16px;
  height: 16px;
  margin-right: 5px;
`;

const ErrorIcon = styled(ErrorCross)`
  width: 18px;
  height: 18px;
  margin-right: 5px;
`;

const UploadToS3 = ({ index, patientId }: Props): JSX.Element => {
  const { activeFiles, setActiveFiles } = useContext(FileContext);
  const [createAttachment, { error: mutationError }] = useMutation(CREATE_ATTACHMENT, {
    awaitRefetchQueries: true,
    refetchQueries: [{ query: GET_PATIENT_ATTACHMENTS, variables: { id: patientId } }],
  });
  const theme = useTheme();

  // Upload files to S3 bucket
  const uploadFileToS3 = (index: number): void => {
    if (activeFiles[index].uploadErrors !== '' || activeFiles[index].uploadingToS3 === true) {
      return;
    }

    activeFiles[index].uploadingToS3 = true;

    const xhr = new XMLHttpRequest();
    const formData = new FormData();

    const parsedPayload = JSON.parse(activeFiles[index].s3Fields.awsPayload);
    for (const [key, value] of Object.entries(parsedPayload.fields)) {
      formData.append(key, String(value));
    }
    formData.append('Content-Type', activeFiles[index].baseFile['type']);
    formData.append('file', activeFiles[index].baseFile);

    xhr.addEventListener('load', (): void => {
      const statusCode = xhr.status;
      if ([200, 201, 204].includes(statusCode)) {
        createAttachment({
          variables: {
            awsKey: activeFiles[index].s3Fields.awsKey,
            patientPk: patientId,
            documentType: '',
            encounterDate: activeFiles[index].encounterDate,
          },
        }).then((response) => {
          // addresses missing attachmentPk issue
          if (!response.data.errors) {
            const attachment = response.data.createAttachment.attachment;
            activeFiles[index].id = attachment.id;
            activeFiles[index].loading = false;
          }
        });
      } else {
        activeFiles[index].loading = false;
        activeFiles[index].uploadErrors = UPLOAD_ERRORS['other'];
        setActiveFiles([...activeFiles]);
      }
    });

    xhr.addEventListener('error', (): void => {
      activeFiles[index].loading = false;
      activeFiles[index].uploadErrors = UPLOAD_ERRORS['other'];
      setActiveFiles([...activeFiles]);
    });

    xhr.open('POST', activeFiles[index].baseS3Url);
    xhr.send(formData);
  };

  // Retrieve details needed to upload file to S3 bucket
  const skip = Boolean(
    !(activeFiles[index].accepted && activeFiles[index].previewUrl === '' && activeFiles[index].uploadErrors === ''),
  );

  if (!activeFiles[index].baseFile['name'] && !skip) {
    // TODO Added logging for Sentry issue OP-PROD-1WS "$fileName" of required type "String!"
    logger.error(`UploadToS3.tsx may be attempting to upload a file without a name. File ${activeFiles[index]}`);
  }
  const { data, error } = useQuery(GET_UPLOAD_DETAILS, {
    variables: { fileName: activeFiles[index].baseFile['name'] },
    skip: skip,
  });

  if (data?.fileUploadPayload && !skip) {
    activeFiles[index].baseS3Url = data.fileUploadPayload.url;
    activeFiles[index].s3Fields = data.fileUploadPayload.fields;
    uploadFileToS3(index);
  }

  useEffect(() => {
    if (error || mutationError) {
      activeFiles[index].uploadErrors = 'Upload failed. Please try again.';
      activeFiles[index].loading = false;
    }
    setActiveFiles([...activeFiles]);
  }, [error, mutationError]);

  const status = activeFiles[index].loading ? 'Loading' : activeFiles[index].uploadErrors === '' ? 'Success' : 'Failed';

  return (
    <FileNameWrapper>
      {status === 'Loading' ? (
        <>
          <LoadingName>{activeFiles[index].baseFile['name']}</LoadingName>
          <LoadingContainer>{'Uploading...'}</LoadingContainer>
        </>
      ) : status === 'Success' ? (
        <>
          <a href={activeFiles[index].previewUrl} target="_blank" rel="noopener noreferrer">
            <SuccessName>{activeFiles[index].baseFile['name']}</SuccessName>
          </a>
          <SuccessContainer>
            <SuccessIcon color="primary" />
            {'Uploaded'}
          </SuccessContainer>
        </>
      ) : (
        <>
          <FailedName>{activeFiles[index].baseFile['name']}</FailedName>
          <FailedContainer>
            <ErrorIcon />
            {activeFiles[index].uploadErrors}
          </FailedContainer>
        </>
      )}
    </FileNameWrapper>
  );
};

export default UploadToS3;
