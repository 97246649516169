// eslint-disable-next-line no-use-before-define
import { HAOperationsItem } from 'op-classes';
import { PageTitle } from 'op-components';
import { HAOperationsDateItem } from 'op-interfaces';
import { appendZeroInFront, isUs, resolveListDataValue } from 'op-utils';
import {
  fetchRules,
  getErrorsForSingleViewedField,
  getValidationRule,
  ValidationKeys,
} from 'op-utils/HealthAssessmentValidation/HealthAssessmentValidation';
import React, { Component, Fragment } from 'react';
import { DropDownRODay, DropDownROMonth, DropDownROYear } from 'op-pages/OP/HealthAssessment/HASharedComponents';
import { DropDownField, FreeTextField, SectionField, SegmentedInput } from 'shared-components/components/FormFields';
import { Months } from 'shared-components/enums';
import { MinusNoFill } from 'shared-components/images';
import { ListData, StringDictionary } from 'shared-components/interfaces';
import { SegmentedInputBoolean } from 'shared-components/utils';
import validate from 'validate.js';
import { OP_FIELDS, OTHER } from '../constants';
import './HAOperations.scss';
import lodash from 'lodash';
import { Add as AddIcon } from '@mui/icons-material';

const FIELD_VALIDATION_KEYS: StringDictionary = {
  PREV_OPERATION: 'prevOperation',
};

const CRUD_OPERATION = {
  CREATE: 'CREATE',
  DELETE: 'DELETE',
};

interface State {
  viewedFields: Set<string>;
}

interface Props {
  autosave: (updateObject: object, replaceId?: string, refetch?: any) => Promise<void>;
  patientId: string;
  haOperations: HAOperationsItem;
  validateOnLoad: boolean;
  performCRUD: (haId: string, operation: string, objectType: string, itemId?: string, refetch?: any) => void;
  refetch: any;
  operationsRefData: ListData[];
}

class HAOperations extends Component<Props, State> {
  public constructor(props: Props) {
    super(props);

    this.state = {
      viewedFields: new Set(),
    };
  }

  public static getDerivedStateFromProps(props: Props, state: State): State {
    const { haOperations } = props;
    if (props.validateOnLoad) {
      const fields = Object.keys(FIELD_VALIDATION_KEYS).map((keyName: string) => {
        return FIELD_VALIDATION_KEYS[keyName];
      });
      const viewed = new Set(fields);
      Object.keys(haOperations).forEach(function (key) {
        if (Array.isArray(haOperations[key])) {
          haOperations[key].forEach(function (item: any, index: number) {
            Object.keys(item).forEach(function (field) {
              if (field !== 'id' && field !== '__typename') {
                const place = index + 1;
                viewed.add(key + '-' + place + '-' + field);
              }
            });
          });
        }
      });
      return { viewedFields: viewed };
    }

    return state;
  }

  public render(): JSX.Element {
    const { haOperations } = this.props;

    return (
      <Fragment>
        <PageTitle title={'Operations'} idPrefix="ha" />
        <div id="ha-fields">
          <SectionField htmlFor={OP_FIELDS.PREV_OPERATION.NAME} title={OP_FIELDS.PREV_OPERATION.TITLE}>
            <SegmentedInput
              fieldName={OP_FIELDS.PREV_OPERATION.NAME}
              options={SegmentedInputBoolean}
              optionAreBoolean={true}
              defaultSelected={haOperations.prevOperation}
              itemSelected={(selectedItem): void => {
                const selectedValue = selectedItem as boolean;
                this.autoSaveAndValidate(
                  {
                    id: parseInt(this.props.patientId),
                    prevOperation: selectedValue,
                  },
                  FIELD_VALIDATION_KEYS.PREV_OPERATION,
                  this.props.refetch,
                );
              }}
            />
          </SectionField>
          {haOperations.prevOperation && this.renderOperation()}
        </div>
      </Fragment>
    );
  }

  private renderExpandableElement = (
    index: number,
    inputType: string,
    inputTitle: string,
    stateList: HAOperationsDateItem[],
    onChange: (object: any) => void,
    removeFunction: () => void,
    removable = true,
  ): JSX.Element => {
    const inputName = `${inputType}-${index + 1}`;

    const dateOfTreatment = `${appendZeroInFront(stateList[index].operationRawDay)}${appendZeroInFront(
      stateList[index].operationRawMonth,
    )}${stateList[index].operationRawYear}`;

    stateList[index].operationDate = dateOfTreatment;

    const day = (
      <DropDownRODay
        id={`${inputName}-day`}
        value={stateList[index].operationRawDay ?? ''}
        onChange={(val): void => {
          const saveObject = { operationRawDay: val };
          this.addToViewedFields(`${inputName}-operationRawDay`);
          onChange(saveObject);
        }}
        selectedMonth={parseInt(stateList[index].operationRawMonth)}
        selectedYear={parseInt(stateList[index].operationRawYear)}
      />
    );
    const month = (
      <DropDownROMonth
        id={`${inputName}-month`}
        value={
          parseInt(stateList[index].operationRawMonth) > 0
            ? Months[parseInt(stateList[index].operationRawMonth) - 1]
            : ''
        }
        onChange={(val): void => {
          const resultVal = Months.indexOf(val) >= 0 ? Months.indexOf(val) + 1 : '';
          const saveObject = { operationRawMonth: resultVal.toString() };
          this.addToViewedFields(`${inputName}-operationRawMonth`);
          onChange(saveObject);
        }}
        selectedYear={parseInt(stateList[index].operationRawYear)}
      />
    );

    const conditionalShowFields = [OTHER, 'Pacemaker placement'];
    const conditionalTitle = (field: string) => {
      if (field === 'Pacemaker placement') return 'Type/model';
      return 'Please describe';
    };
    const operationListData = isUs()
      ? resolveListDataValue(stateList[index].operationSpecify, this.props.operationsRefData)
      : '';

    return (
      <div key={inputName} className="infinite-item">
        {!isUs() && (
          <SectionField htmlFor={inputName} title={`${inputTitle} ${index + 1}`}>
            <FreeTextField
              inputKey={stateList[index].operationSpecify}
              inputName={`${inputName}-operationSpecify`}
              placeholder={'Please describe'}
              inputType="text"
              defaultValue={stateList[index].operationSpecify}
              onBlur={(e): void => {
                const saveObject = { operationSpecify: e.target.value };
                this.addToViewedFields(inputName + '-operationSpecify');
                onChange(saveObject);
              }}
            />
          </SectionField>
        )}
        {isUs() && (
          <SectionField htmlFor={inputName} title={`${inputTitle} ${index + 1}`}>
            <DropDownField
              inputKey={stateList[index].operationSpecify}
              inputName={`${inputName}-operationSpecify`}
              placeholder={'Please select'}
              options={this.props.operationsRefData}
              defaultValue={stateList[index].operationSpecify}
              controlled={true}
              onChange={(e): void => {
                const saveObject = { operationSpecify: e.target.value };
                this.addToViewedFields(inputName + '-operationSpecify');
                onChange(saveObject);
              }}
            />
          </SectionField>
        )}
        {isUs() && conditionalShowFields.includes(operationListData) && (
          <SectionField htmlFor={`${inputName}-other`} title={conditionalTitle(operationListData)}>
            <FreeTextField
              inputKey={stateList[index].operationOther}
              inputName={`${inputName}-operationOther`}
              placeholder={'Please describe'}
              inputType="text"
              defaultValue={stateList[index].operationOther}
              onBlur={(e): void => {
                const saveObject = { operationOther: e.target.value };
                this.addToViewedFields(inputName + '-operationOther');
                onChange(saveObject);
              }}
              errors={
                this.state.viewedFields.has(inputName + '-operationOther')
                  ? getErrorsForSingleViewedField(
                      { operationOther: stateList[index].operationOther },
                      { operationOther: getValidationRule('operationOther') },
                      'operationOther',
                    )
                  : undefined
              }
            />
          </SectionField>
        )}
        <SectionField title={'Operation date'} htmlFor={`operationDate${index + 1}`}>
          <div className="operation-date">
            {isUs() ? month : day}
            {isUs() ? day : month}
            <DropDownROYear
              id={`${inputName}-year`}
              value={stateList[index].operationRawYear ?? ''}
              onChange={(val): void => {
                const saveObject = { operationRawYear: val };
                this.addToViewedFields(`${inputName}-operationRawYear`);
                onChange(saveObject);
              }}
            />
          </div>
        </SectionField>

        {removable && (
          <div className="remove-item">
            <div
              onClick={(): void => {
                removeFunction();
              }}>
              <MinusNoFill className="icon" />
              {'Remove'}
            </div>
          </div>
        )}
      </div>
    );
  };

  private renderAdditionalItem = (buttonText: string, onClick: () => void): JSX.Element => {
    return (
      <div
        className="additional-item-button"
        onClick={(): void => {
          onClick();
        }}>
        <AddIcon className="icon" color="primary" />
        {buttonText}
      </div>
    );
  };

  private renderOperation = (): JSX.Element => {
    const { haOperations, autosave, performCRUD, refetch, patientId } = this.props;
    const operationSelection: JSX.Element[] = haOperations.operations.map((value, index): JSX.Element => {
      let removable = false;
      if (haOperations.operations.length !== 1) {
        removable = true;
      }
      return this.renderExpandableElement(
        index,
        'operations',
        'Operation',
        lodash.cloneDeep(haOperations.operations),
        (object: any): void => {
          autosave({ id: parseInt(patientId), operations: { id: value.id, ...object } }, value.id);
        },
        (): void => {
          performCRUD(haOperations.id, CRUD_OPERATION.DELETE, 'operation', value.id, refetch);
        },
        removable,
      );
    });
    return (
      <Fragment>
        {operationSelection}
        {this.renderAdditionalItem('Add another operation', (): void => {
          performCRUD(haOperations.id, CRUD_OPERATION.CREATE, 'operation', undefined, refetch);
        })}
      </Fragment>
    );
  };

  private validateObject = (haOperations: HAOperationsItem): any => {
    const validationRules = fetchRules(ValidationKeys.Operations, this.state.viewedFields);

    const options = { fullMessages: false };

    return validate(haOperations, validationRules, options);
  };

  private addToViewedFields = (validationKey: string): any => {
    const viewedFields = [...this.state.viewedFields];

    //Add updated field
    viewedFields.push(validationKey);

    //Update with new validation set
    this.setState({ viewedFields: new Set(viewedFields) });
  };
  private autoSaveAndValidate = async (
    updateObject: object,
    validationKey: string,
    setRefetch: boolean,
  ): Promise<void> => {
    const { autosave, refetch } = this.props;

    //Save the data
    const autoSave = () => {
      return setRefetch ? autosave(updateObject, undefined, refetch) : autosave(updateObject);
    };
    await autoSave().then(() => {
      this.addToViewedFields(validationKey);
    });
  };
}

//@ts-ignore
export default HAOperations;
