import './InlinePatientSearch.scss';
// eslint-disable-next-line no-use-before-define
import React, { useEffect, useState } from 'react';
import debounce from 'lodash.debounce';
import { useHistory } from 'react-router-dom';
import { useQuery } from '@apollo/client';
import moment from 'moment';
import { LoadingSpinner } from 'shared-components/components';
import PhotoWidget from 'shared-components/components/UIFormComponents/PhotoWidget';
import { PATIENT_SEARCH_RO } from './Queries';
import { useErrorModalContext } from 'op-contexts';
import { COPY } from './constants';
import { Region } from 'shared-components/enums';
import { calculateMomentAge } from 'shared-components/utils';
import { RoleType } from 'op-enums';
import { useTheme, TextField } from '@mui/material';
import { Search } from '@mui/icons-material';

const { MO } = RoleType;

interface ROInlinePatientSearchProps {
  closeSearch: () => void;
  recentPatients?: any;
  primaryRole: string;
}
interface patientRecordType {
  id: string;
  fullName: string;
  ida: string;
  city: string;
  dob: string;
  line1: string;
  line2: string;
  postcode: string;
  state: string;
  emrInstance: string;
}
const EMPTY_SEARCH_RESULTS_HELPERS = [
  {
    text: COPY.CHECK_SPELLING,
  },
  {
    text: COPY.CHECK_ID_TRY_AGAIN,
  },
  {
    text: COPY.NEW_MOSAIQ_PATIENT_WAIT,
  },
  {
    text: COPY.NAME_OR_ID,
  },
];
const REACT_APP_REGION = import.meta.env.REACT_APP_REGION;
const formatAddress = (address: patientRecordType): string => {
  const mappedAddress = ('address' in address ? address['address'] : address) as patientRecordType;
  const addressFields = ['line1', 'line2', 'city', 'state', 'postcode'];
  if (!mappedAddress) return '';
  const addressValues = addressFields.map((key) => {
    // @ts-ignore
    if (key in mappedAddress) return mappedAddress[key];
    return '';
  });
  const addressFiltered = addressValues.filter((value) => value && value !== '');
  return addressFiltered.join(', ');
};
const EmptySearchResults = (props: any): JSX.Element => {
  const { searchText } = props;
  const theme = useTheme();
  return (
    <div className="zero-results-wrapper">
      <div className="empty-search-pat-list">{`${COPY.NO_RESULTS_MATCHING} "${searchText}"`}</div>
      <span className="search-type-title">SEARCH TIPS</span>
      <ul className="search-tips">
        {EMPTY_SEARCH_RESULTS_HELPERS.map((data: any, index: number) => (
          <li key={`search-tip-${index}`}>
            <div className="search-tip">
              <div
                style={{
                  width: '8px',
                  height: '8px',
                  backgroundColor: theme.palette.primary.main,
                  borderRadius: '50%',
                  marginRight: '16px',
                }}></div>
              {data.text}
            </div>
          </li>
        ))}
      </ul>
    </div>
  );
};

const ROInlinePatientSearch = (props: ROInlinePatientSearchProps): JSX.Element => {
  const [focused, setFocused] = useState<boolean>(false);
  const [searchText, setSearchText] = useState('');
  const [cursor, setCursor] = useState(-1);
  const history = useHistory();
  const { setError } = useErrorModalContext();
  const theme = useTheme();
  const isUkRegion = REACT_APP_REGION === Region.UK;
  const {
    loading,
    error,
    data: searchList,
  } = useQuery(PATIENT_SEARCH_RO, {
    variables: { search: searchText },
    fetchPolicy: 'network-only',
    skip: searchText === '',
  });
  useEffect(() => {
    if (error) return setError();
  }, [error]);
  //Patients list is the state that holds either recentList or searchList depending upon the state of the component.
  const [patientsList, setPatientsList] = useState<patientRecordType[]>([]);
  useEffect(() => {
    if (cursor && cursor >= 0) {
      const element = document.getElementById('patRecord' + cursor) as any;
      if (element) {
        element.scrollIntoView({ behavior: 'auto', block: 'nearest' });
      }
    }
  }, [cursor]);
  useEffect(() => {
    if (searchText === '') {
      setPatientsList(props.recentPatients);
    } else {
      const searchResults = searchList ? searchList.searchPatientsRo : [];
      setPatientsList(searchResults);
    }
  }, [searchText, loading]);
  useEffect(() => {
    function handleSearchClick(e: any) {
      const wrapper = document.getElementById('patientSearchWrapper') as any;
      if (wrapper && !wrapper.contains(e.target)) {
        props.closeSearch();
      }
    }
    function handleKeyStroke(e: any) {
      if (e.keyCode === 27) {
        props.closeSearch();
      } else if (e.keyCode === 38 && cursor && cursor > 0) {
        setCursor(cursor - 1);
      } else if (e.keyCode === 40 && patientsList && cursor && cursor < patientsList?.length - 1) {
        setCursor(cursor + 1);
      } else if (e.keyCode === 13 && cursor && cursor >= 0 && cursor < patientsList?.length - 1) {
        history.push(`/radiation/patient/${patientsList[cursor].id}/summary`);
        props.closeSearch();
      }
    }
    document.addEventListener('click', handleSearchClick, false);
    document.addEventListener('keydown', handleKeyStroke, false);
    return () => {
      document.removeEventListener('click', handleSearchClick, false);
      document.removeEventListener('keydown', handleKeyStroke, false);
    };
  }, []);
  const openPatientRecord = (id: number): any => {
    props.closeSearch();
    if (props.primaryRole === MO) {
      history.push(`/medonc/patient/${id}/summary`);
    } else {
      history.push(`/radiation/patient/${id}/summary`);
    }
  };
  const changeSearchText = debounce((value: string) => {
    setSearchText(value);
  }, 150);

  const title = searchText === '' ? 'Recently Viewed' : 'Patient Search';
  return (
    <div className="patient-search-wrapper" id="patientSearchWrapper">
      <div
        style={{
          borderColor: focused ? theme.palette.primary.main : 'default',
          backgroundColor: theme.palette.primary.contrastText,
        }}
        className="patient-search-inp-wrapper">
        <Search color="primary" />
        <TextField
          fullWidth
          variant="standard"
          autoFocus
          onBlur={() => setFocused(false)}
          onFocus={() => setFocused(true)}
          onInput={(e: any) => changeSearchText(e?.target?.value)}
          type="text"
          data-testid="patient-search-input"
          placeholder="Search patient name or patient ID…"
          InputProps={{ disableUnderline: true }}
          sx={{
            '.MuiInputBase-root:hover': {
              backgroundColor: 'white',
            },
          }}
        />
      </div>
      <div className="inline-patient-search-wrapper" id="inlinePatientSearchWrapper">
        <span data-test-id="search-title" className="search-type-title">
          {title}
        </span>
        {loading && (
          <div style={{ width: '100%', height: '100%', display: 'flex' }}>
            <LoadingSpinner relativeSpinner={true} loadingText={'Searching for ' + searchText} />
          </div>
        )}
        {!loading && searchText !== '' && patientsList && patientsList?.length === 0 && (
          <EmptySearchResults searchText={searchText} />
        )}
        {searchText === '' && patientsList && patientsList?.length === 0 && (
          <div className="zero-results-wrapper">
            <div className="empty-recent-pat-list">{COPY.EMPTY_RECENT_PAT_LIST}</div>
          </div>
        )}
        {!loading && !error && patientsList && patientsList?.length > 0 && (
          <div className="pat-search-table">
            <table>
              <tbody>
                {patientsList?.map((patient: any, index: number) => {
                  const formattedDateOfBirth = patient.dob ? moment(patient.dob).format('Do MMM YYYY') : '-';
                  const calculatedAge = patient.dob ? calculateMomentAge(moment(patient.dob)) : '-';
                  const fullAge =
                    formattedDateOfBirth !== '-'
                      ? `${formattedDateOfBirth} (Age ${calculatedAge})`
                      : formattedDateOfBirth;
                  return (
                    <tr
                      key={patient.id}
                      onClick={() => openPatientRecord(patient.id)}
                      onMouseOver={() => setCursor(index)}
                      onMouseOut={() => setCursor(-1)}
                      className={cursor === index ? 'active' : undefined}
                      id={'patRecord' + index}>
                      <td>
                        <PhotoWidget patientId={isUkRegion ? null : patient.id} enableOverlay={true} size={'sm'} />
                      </td>
                      <td style={{ width: '30%', maxWidth: '108px' }}>
                        <div className="patient-det">
                          <span data-test-id="patient-name" className="patient-name">
                            {patient.fullName}
                          </span>
                          <span data-test-id="patient-ida" className="patient-ida">
                            ID: {patient.ida}
                          </span>
                          <span data-test-id="patient-emr" className="patient-emr">
                            EMR: {patient.emrInstance || '-'}
                          </span>
                        </div>
                      </td>
                      <td style={{ width: '20%' }}>
                        <div data-test-id="patient-full-age">{fullAge}</div>
                      </td>
                      <td style={{ width: '50%' }}>
                        <div data-test-id="patient-address">{formatAddress(patient)}</div>
                      </td>
                    </tr>
                  );
                })}
              </tbody>
            </table>
          </div>
        )}
      </div>
    </div>
  );
};
export default ROInlinePatientSearch;
