// eslint-disable-next-line no-use-before-define
import React from 'react';

import { Button } from '@mui/material';
import { GenesisCareLogoMobileGreenText } from 'shared-components/images';

import './LoginBox.scss';

interface Props {
  header: string;
  handleClick: (event: React.MouseEvent<HTMLButtonElement>) => void;
}

const LoginBox: React.FC<Props> = ({ header, handleClick }): JSX.Element => {
  return (
    <div className="login-box">
      <GenesisCareLogoMobileGreenText />
      <div className="login-header">{header}</div>
      <Button onClick={handleClick} name="login" variant="contained">
        Login
      </Button>
    </div>
  );
};

export default LoginBox;
