export const TABLE_KEYS = {
  PATIENT_NAME: 'patientName',
  TREATING_DEPARTMENT: 'treatingDepartment',
  CAREPLAN: 'careplan',
  NEXT_TREATMENT: 'next_treatment',
  STATUS: 'status',
  PRESCRIBING_DOCTOR: 'prescribingDoctor',
  LAST_MODIFIED: 'lastModified',
};
export const TABLE_LABELS = {
  PATIENT_NAME: 'Patient',
  TREATING_DEPARTMENT: 'Treating department',
  CAREPLAN: 'Careplan',
  NEXT_TREATMENT: 'Next treatment',
  STATUS: 'Status',
  PRESCRIBING_DOCTOR: 'Prescribing doctor',
  LAST_MODIFIED: 'Last modified',
};

export const CAREPLAN_STATUS = {
  APPROVED: 'Approved',
  APPROVAL_REQUIRED: 'Approval Required',
  SAVE_PRINT_REQUIRED: 'Save/Print Required',
  DRAFT: 'Draft',
};
