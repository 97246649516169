import { Field, Form, Formik, FormikValues } from 'formik';
import { FormContainer } from 'op-components';
import { RegistrationContext } from 'op-contexts';
import { mapListData } from 'op-utils';
import React, { useMemo, useContext } from 'react';
import { useHistory } from 'react-router-dom';
import { FormRow } from 'shared-components/components';
import { ListData } from 'shared-components/interfaces';
import { styled } from '@mui/system';
import {
  CONTINUE_BUTTON_TEXT,
  HeaderSubTitle,
  HeaderTitle,
  registrationPath,
  RETURN_TO_SUBMIT_TEXT,
  sharedFormContainerProps,
  standardField,
  StandardFieldProps,
  StyledIndentedSection,
  yesNoOptions,
  isFormValid,
  DateField,
} from '../Helper';
import { PatientInsuranceOtherBenefit, RegistrationFormUSIProps } from '../interfaces';
import {
  FIELDS,
  medicareFieldsToDelete,
  dvaFieldsToDelete,
  pensionFieldsToDelete,
  privateFieldsToDelete,
} from './constants';
import { generateValidationSchema } from './validation';

interface InsuranceFormIProps extends RegistrationFormUSIProps {
  patient: PatientInsuranceOtherBenefit;
  readonly refData: {
    readonly medicareTypeRefData: ListData[];
    readonly dvaTypeRefData: ListData[];
    readonly concessionCardTypeRefData: ListData[];
  };
}

interface InputProps {
  maxLength?: number;
  type?: string;
}

const { FORM_HEADING } = FIELDS;

const monthFieldWidth = '80px';
const monthFieldInputProps = {
  maxLength: 2,
  type: 'string',
};

const yearFieldWidth = '80px';
const yearFieldInputProps = {
  maxLength: 4,
  type: 'string',
};

const InfoDiv = styled('div')`
  margin-top: 5px;
  font-size: 14px;
`;

const InputField = (
  field: StandardFieldProps,
  updateField: (field: string, value: string | number | boolean) => void,
  width?: string,
  inputProps?: InputProps,
) => (
  <FormRow fieldLabel={field.TITLE} fieldName={field.NAME} labelClass={'label-form-row'}>
    <Field
      name={field.NAME}
      component={field.COMPONENT}
      updateMutation={(value: string) => updateField(field.NAME, value)}
      handleMutation={updateField}
      width={width}
      placeholder={field.PLACE_HOLDER}
      inputProps={inputProps}
    />
    {field.NAME === 'healthMedicareIrn' && (
      <InfoDiv>*IRN - Individual Reference Number (the number which appears next to your name)</InfoDiv>
    )}
  </FormRow>
);

const InsuranceForm = (props: InsuranceFormIProps): JSX.Element => {
  const {
    updateField,
    patient,
    previousPageLink,
    handleShowSaveExitConfirm,
    refData: { medicareTypeRefData, dvaTypeRefData, concessionCardTypeRefData },
  } = props;

  const registrationContext = useContext(RegistrationContext);
  const history = useHistory();

  const medicareTypeOptions = useMemo(() => mapListData(medicareTypeRefData), medicareTypeRefData);
  const dvaTypeOptions = useMemo(() => mapListData(dvaTypeRefData), dvaTypeRefData);
  const concessionTypeOptions = useMemo(() => mapListData(concessionCardTypeRefData), concessionCardTypeRefData);
  const goToSummary = registrationContext?.registrationSummaryVisited;
  return (
    <Formik
      initialValues={patient}
      validate={(values: FormikValues) =>
        generateValidationSchema({
          values,
          refData: { medicareTypeRefData, dvaTypeRefData, concessionCardTypeRefData },
        })
      }
      validateOnBlur
      validateOnChange={false}
      onSubmit={() => history.push(registrationPath(patient?.id, goToSummary ? 'summary' : 'demographics'))}>
      {({ submitForm, values, errors, setFieldValue, setFieldTouched, setTouched, touched, submitCount }) => {
        const sharedProps = sharedFormContainerProps('Insurance and other benefits', 2, previousPageLink);

        const MedicareNumberField = (
          field: StandardFieldProps,
          updateField: (field: string, value: string | number | boolean) => void,
        ) => (
          <FormRow fieldLabel={field.TITLE} fieldName={field.NAME} labelClass={'label-form-row'}>
            <Field
              name={field.NAME}
              component={field.COMPONENT}
              updateMutation={(value: string) => {
                setFieldValue(field.NAME, value.replace(/ /g, ''), true);
                updateField(field.NAME, value.replace(/ /g, ''));
              }}
              handleMutation={(field: string, value: string) => {
                setFieldValue(field, value.replace(/ /g, ''), true);
                updateField(field, value.replace(/ /g, ''));
              }}
              initialMask="**** ***** *"
              mask="9999 99999 9"
              maskChar="X"
            />
          </FormRow>
        );

        const formContainerProps = {
          ...sharedProps,
          submitButtonText: goToSummary ? RETURN_TO_SUBMIT_TEXT : CONTINUE_BUTTON_TEXT,
          continueDisabled: isFormValid(errors, touched),
          handleShowSaveExitConfirm,
        };

        const clearFields = (fieldNames: string[]) => {
          fieldNames.forEach((fieldName: string) => setFieldValue(fieldName, '', true));
        };
        const updateInsuranceField = (field: string, value: string | boolean | number) => {
          // Clear out formik values on fields we want to delete
          if (field === FIELDS.MEDICARE_OR_DVA.NAME) {
            switch (value) {
              case 'None':
                clearFields(medicareFieldsToDelete);
              case 'Medicare':
                clearFields(dvaFieldsToDelete);
                setTouched(
                  {
                    ...touched,
                    healthDvaNumber: false,
                    healthDvaType: false,
                    dvaExpiryRawMonth: false,
                    dvaExpiryRawYear: false,
                  },
                  false,
                );
                break;
              case 'Medicare and DVA':
                break;
              default:
            }
          }

          if (field === FIELDS.HAS_PRIVATE_INSURANCE.NAME && value === false) clearFields(privateFieldsToDelete);
          if (field === FIELDS.HAS_PENSION_CARD.NAME && value === false) {
            clearFields(pensionFieldsToDelete);
            setTouched(
              {
                ...touched,
                healthPensionCardType: false,
                healthPensionCardNumber: false,
                healthPensionCardStart: false,
                healthPensionCardExpiry: false,
              },
              false,
            );
          }
          // If field is emptied (value = MASK) send an empty string
          if (field === FIELDS.MEDICARE_CARD_NUMBER.NAME && ['XXXXXXXXXX', ''].includes(value.toString())) {
            clearFields([FIELDS.MEDICARE_CARD_NUMBER.NAME]);
            return updateField(field, '');
          }

          updateField(field, value);
          setFieldValue(field, value, true);
        };
        return (
          <FormContainer
            {...formContainerProps}
            submitForm={submitForm}
            handleValidation={(event: React.MouseEvent<HTMLInputElement>): void => {
              void event;
            }}>
            <Form style={{ paddingBottom: '50%' }}>
              <HeaderTitle data-test-id="rego-insurance-page-title">{FORM_HEADING.TITLE}</HeaderTitle>
              <HeaderSubTitle>* = required field</HeaderSubTitle>
              {standardField(FIELDS.MEDICARE_OR_DVA, updateInsuranceField, medicareTypeOptions)}
              {['Medicare', 'Medicare and DVA'].includes(values.healthMedicareDvaOption) && (
                <StyledIndentedSection>
                  {MedicareNumberField(FIELDS.MEDICARE_CARD_NUMBER, updateInsuranceField)}
                  {InputField(FIELDS.MEDICARE_CARD_IRN, updateInsuranceField, '50px', {
                    maxLength: 1,
                    type: 'string',
                  })}
                  {InputField(
                    FIELDS.MEDICARE_CARD_EXPIRY_MONTH,
                    updateInsuranceField,
                    monthFieldWidth,
                    monthFieldInputProps,
                  )}
                  {InputField(
                    FIELDS.MEDICARE_CARD_EXPIRY_YEAR,
                    updateInsuranceField,
                    yearFieldWidth,
                    yearFieldInputProps,
                  )}
                </StyledIndentedSection>
              )}
              {['Medicare and DVA'].includes(values.healthMedicareDvaOption) && (
                <StyledIndentedSection>
                  {InputField(FIELDS.DVA_CARD_NUMBER, updateInsuranceField, 'default', {
                    maxLength: 9,
                  })}
                  {standardField(FIELDS.DVA_CARD_TYPE, updateInsuranceField, dvaTypeOptions)}
                  {InputField(
                    FIELDS.DVA_CARD_EXPIRY_MONTH,
                    updateInsuranceField,
                    monthFieldWidth,
                    monthFieldInputProps,
                  )}
                  {InputField(FIELDS.DVA_CARD_EXPIRY_YEAR, updateInsuranceField, yearFieldWidth, yearFieldInputProps)}
                </StyledIndentedSection>
              )}
              {standardField(FIELDS.HAS_PRIVATE_INSURANCE, updateInsuranceField, yesNoOptions)}
              {values.healthPrivateHealthInsurance && (
                <StyledIndentedSection>
                  {standardField(FIELDS.HEALTH_FUND_NAME, updateInsuranceField)}
                  {standardField(FIELDS.HEALTH_FUND_NUMBER, updateInsuranceField)}
                </StyledIndentedSection>
              )}
              {standardField(FIELDS.HAS_PENSION_CARD, updateInsuranceField, yesNoOptions)}
              {values.healthPensionCard && (
                <StyledIndentedSection>
                  {standardField(FIELDS.CONCESSION_CARD_TYPE, updateInsuranceField, concessionTypeOptions)}
                  {InputField(FIELDS.PENSION_CRN, updateInsuranceField, 'default', {
                    maxLength: 10,
                  })}
                  {DateField(
                    FIELDS.PENSION_CARD_START,
                    values?.healthPensionCardStart,
                    !((submitCount > 0 || !!touched['healthPensionCardStart']) && !!errors['healthPensionCardStart']),
                    updateField,
                    setFieldTouched,
                    setFieldValue,
                  )}
                  {DateField(
                    FIELDS.PENSION_CARD_EXPIRY,
                    values?.healthPensionCardExpiry,
                    !((submitCount > 0 || !!touched['healthPensionCardExpiry']) && !!errors['healthPensionCardExpiry']),
                    updateField,
                    setFieldTouched,
                    setFieldValue,
                  )}
                </StyledIndentedSection>
              )}
            </Form>
          </FormContainer>
        );
      }}
    </Formik>
  );
};

export default InsuranceForm;
