// eslint-disable-next-line no-use-before-define
import React from 'react';
import { useMutation, useQuery } from '@apollo/client';
import { useParams } from 'react-router-dom';
import { registrationPath } from '../Helper';
import ReferrerForm from './ReferrerForm';
import { REGISTRATION_REFERRER_QUERY, UPDATE_REGISTRATION_REFERRER } from './ReferrerQueries';
import { FormProps } from '../interfaces';

const Referrer = (props: FormProps): JSX.Element => {
  const { exitForm } = props;
  const { patientId: id } = useParams<{ patientId: string }>();

  const [updateRegistration] = useMutation(UPDATE_REGISTRATION_REFERRER);
  const { data, error } = useQuery(REGISTRATION_REFERRER_QUERY, {
    variables: { id },
  });

  if (error || !data) return <></>;

  const { patient, user } = data || {};

  const updateField = (field: string, value: string | boolean | number) => {
    updateRegistration({ variables: { id, [field]: value } });
  };

  return (
    <ReferrerForm
      updateField={updateField}
      patient={patient}
      handleShowSaveExitConfirm={() => exitForm(true)}
      previousPageLink={registrationPath(id, 'emergencyContact')}
      isPso={user.isPso}
    />
  );
};

export default Referrer;
