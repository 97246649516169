import { Paper, PaperProps, useTheme } from '@mui/material';

const ReviewCard = (props: PaperProps): JSX.Element => {
  const theme = useTheme();
  return (
    <Paper
      variant="outlined"
      sx={{
        p: 2,
        width: 256,
        cursor: 'pointer',
        ':hover': { bgcolor: theme.palette.grey[100] },
        ':active': { bgcolor: theme.palette.grey[200] },
      }}
      {...props}
    />
  );
};

export default ReviewCard;
