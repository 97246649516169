import React from 'react';
import { useFormikContext, Field, FormikProps } from 'formik';

import { InfoCard } from 'op-components';
import { HelperMessage, FormRow } from 'shared-components/components';
import { ToggleButtonGroupField } from 'shared-components/components/FormikComponents';
import { yesNoOptions } from 'op-utils';

import { choiceToBooleanOptions, getLastUpdateUser } from '../../helpers';
import { LastUpdateUserDetails } from '../../shared/interfaces';
import { preCtChartCheckContent } from '../constants';
import { HealthAssessmentData, PreCtChartCheckFormValues } from '../interfaces/PreCtChartCheckInterfaces';

interface Props {
  handleMutation: (name: string, value: string) => void;
  healthAssessmentData: HealthAssessmentData;
  lastUpdateUserData: LastUpdateUserDetails[];
}

const PressureInjury = ({ handleMutation, healthAssessmentData, lastUpdateUserData }: Props): JSX.Element => {
  const { values }: FormikProps<PreCtChartCheckFormValues> = useFormikContext();
  const { treatmentOverHalfHour, currentInpatient, requiresMaskCast, existingPressureInjuryWound } =
    preCtChartCheckContent.pressureInjuryRisk.fields;
  const { pressureInjuryAlert, pressureInjuryQCL } = preCtChartCheckContent.pressureInjuryRisk.infoHelpers;
  const { activeInjuryReasonTitle } = preCtChartCheckContent.pressureInjuryRisk.tableTitles;

  let hasActiveInjury;
  if (healthAssessmentData.healthAssessment) {
    //@ts-ignore
    hasActiveInjury = choiceToBooleanOptions[String(healthAssessmentData.healthAssessment.activeInjury)];
  }

  return (
    <>
      <FormRow fieldLabel={treatmentOverHalfHour} fieldName={'treatmentOverHalfHour'}>
        <Field
          name="treatmentOverHalfHour"
          component={ToggleButtonGroupField}
          label={treatmentOverHalfHour}
          options={yesNoOptions}
          handleMutation={handleMutation}
          lastUpdateUser={getLastUpdateUser(lastUpdateUserData, 'treatmentOverHalfHour')}
        />
        {values.treatmentOverHalfHour === true && (
          <>
            <HelperMessage fieldName={'treatmentOverHalfHour'} fieldText={pressureInjuryAlert} helperType="info" />
            <HelperMessage fieldName={'treatmentOverHalfHour'} fieldText={pressureInjuryQCL} helperType="info" />
          </>
        )}
      </FormRow>
      <FormRow fieldLabel={currentInpatient} fieldName={'currentInpatient'}>
        <Field
          name="currentInpatient"
          component={ToggleButtonGroupField}
          label={currentInpatient}
          options={yesNoOptions}
          handleMutation={handleMutation}
          lastUpdateUser={getLastUpdateUser(lastUpdateUserData, 'currentInpatient')}
        />
        {values.currentInpatient === true && (
          <>
            <HelperMessage fieldName={'currentInpatient'} fieldText={pressureInjuryAlert} helperType="info" />
            <HelperMessage fieldName={'currentInpatient'} fieldText={pressureInjuryQCL} helperType="info" />
          </>
        )}
      </FormRow>
      <FormRow fieldLabel={requiresMaskCast} fieldName={'requiresMaskCast'}>
        <Field
          name="requiresMaskCast"
          component={ToggleButtonGroupField}
          label={requiresMaskCast}
          options={yesNoOptions}
          handleMutation={handleMutation}
          lastUpdateUser={getLastUpdateUser(lastUpdateUserData, 'requiresMaskCast')}
        />
        {values.requiresMaskCast === true && (
          <>
            <HelperMessage fieldName={'requiresMaskCast'} fieldText={pressureInjuryAlert} helperType="info" />
            <HelperMessage fieldName={'requiresMaskCast'} fieldText={pressureInjuryQCL} helperType="info" />
          </>
        )}
      </FormRow>
      <FormRow fieldLabel={existingPressureInjuryWound} fieldName={'existingPressureInjuryWound'}>
        <Field
          name="existingPressureInjuryWound"
          component={ToggleButtonGroupField}
          label={existingPressureInjuryWound}
          options={yesNoOptions}
          handleMutation={handleMutation}
          lastUpdateUser={getLastUpdateUser(lastUpdateUserData, 'existingPressureInjuryWound')}
        />
        {values.existingPressureInjuryWound === true && (
          <>
            <HelperMessage
              fieldName={'existingPressureInjuryWound'}
              fieldText={pressureInjuryAlert}
              helperType="info"
            />
            <HelperMessage fieldName={'existingPressureInjuryWound'} fieldText={pressureInjuryQCL} helperType="info" />
          </>
        )}
      </FormRow>
      {hasActiveInjury === true && (
        <InfoCard
          title="Existing Pressure Injury/Wound"
          subTitle="(Patient reported)"
          rowDetails={[
            [
              {
                title: activeInjuryReasonTitle,
                value: healthAssessmentData.healthAssessment.activeInjuryReason,
              },
            ],
          ]}
          itemSize={12}
        />
      )}
    </>
  );
};

export default PressureInjury;
