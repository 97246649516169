export const COVID_SUBMIT = {
  HEADER_TEXT_EXIT: 'Are you sure you want to submit and exit the form?',
  HEADER_TEXT_CONTINUE: 'Are you sure you want to submit and continue to the next form?',
  HEADER_TEXT_ERROR: 'Please fix the errors to submit',
  SUBMIT_AND_EXIT: 'Submit and exit',
  SUBMIT_AND_CONTINUE: 'Submit and continue',
  FIX_ERRORS: "I'll fix the errors",
  MODAL_TEXT_ERROR: 'To submit this form you must answer all required questions and fix all errors.',
  MODAL_TITLE: 'Change of circumstances',
  MODAL_TEXT: 'If your circumstances change before your next visit, please let us know prior to arrival',
};
