// eslint-disable-next-line no-use-before-define
import lodash from 'lodash';
import { Component } from 'react';
import './RORegInsurance.scss';

import { WithApolloClient, withApollo } from '@apollo/client/react/hoc';
import { ROMiniRegoHeader } from 'op-components/index';
import { PatientCoverage } from 'op-interfaces/PatientInterfaces';

import { ROAutocomplete, ROTextField, SelectOptionType } from 'shared-components/components/FormFields';
import { SavingStatus } from 'shared-components/enums';
import { GraphUpdate, ListData } from 'shared-interfaces/index';
import { getOptionByValue } from 'op-pages/RO/Careplan/DiagnosisPages/Utils';

interface State {
  policyNumber: string | undefined;
  preAuthNumber: string | undefined;
  insuranceNotes: string | undefined;
}

interface Props extends WithApolloClient<{}> {
  autosave: (patient: PatientCoverage, updateItems: [GraphUpdate], forceFetch?: object[]) => void;
  saveStatus?: SavingStatus;
  isActive?: boolean;
  patient: PatientCoverage;
  user: any;
  insurerRefData: ListData[];
  relationshipRefData: ListData[];
}

class RORegInsurance extends Component<Props, State> {
  public constructor(props: Props) {
    super(props);
    const { patient } = props;
    this.state = {
      policyNumber: patient.policyNumber,
      preAuthNumber: patient.preAuthNumber,
      insuranceNotes: patient.preAuthNotes,
    };
  }

  private autosave(key: string, value: string, type = 'String'): void {
    const { patient } = this.props;

    // @ts-ignore
    const update = {
      key: key,
      value: value,
      type: type,
    };
    // @ts-ignore
    const propAutosave = async () => {
      await this.props.autosave(patient, [update]);
    };
    propAutosave().then(() => {});
  }

  public render(): JSX.Element {
    const { patient, insurerRefData, relationshipRefData } = this.props;
    const { policyNumber, preAuthNumber, insuranceNotes } = this.state;

    const insurerRefDataClone = lodash.cloneDeep(insurerRefData);
    const relationshipRefDataClone = lodash.cloneDeep(relationshipRefData);

    const insurerOptions = [...insurerRefDataClone]
      .sort((a, b) => (a.name > b.name ? 1 : -1))
      .map((insurer) => ({
        value: insurer.id,
        label: insurer.name,
      }));
    const relationshipOptions = [...relationshipRefDataClone]
      .sort((a, b) => (a.name > b.name ? 1 : -1))
      .map((relationship) => ({
        value: relationship.id,
        label: relationship.name,
      }));

    const notesError = (insuranceNotes?.length || 0) > 2048;
    return (
      <div className="main-container ro-uk-full-width">
        <ROMiniRegoHeader title="Insurance" summary="Please enter insurance details." />
        <ROAutocomplete
          fieldlabel="Insurance company"
          id="payor"
          value={getOptionByValue(
            insurerOptions.map((data) => ({ label: data.label, value: data.value })),
            patient.payor,
          )}
          onChange={(option: SelectOptionType | string): void => {
            const value = typeof option === 'string' ? option : option.value;
            this.autosave('payor', value);
          }}
          placeholder="Select insurance company"
          options={insurerOptions}
        />
        <ROAutocomplete
          id="relationship"
          fieldlabel="Relationship to policy holder"
          options={relationshipOptions}
          value={getOptionByValue(
            relationshipOptions.map((data) => ({ label: data.label, value: data.value })),
            patient.coverageRelationship,
          )}
          onChange={(option: SelectOptionType | string): void => {
            const value = typeof option === 'string' ? option : option.value;
            this.autosave('coverageRelationship', value);
          }}
          placeholder="Select relationship to policy holder"
        />
        <ROTextField
          id="policyNumber"
          fieldlabel="Policy number"
          value={policyNumber || ''}
          onChange={(event: React.ChangeEvent<HTMLInputElement>): void => {
            this.setState({ ...this.state, policyNumber: event.target.value });
          }}
          onBlur={() => {
            if (policyNumber) {
              this.autosave('policyNumber', policyNumber);
            }
          }}
        />
        <ROTextField
          id="preAuthNumber"
          fieldlabel={'Pre-auth number'}
          value={preAuthNumber || ''}
          onChange={(event: React.ChangeEvent<HTMLInputElement>): void => {
            this.setState({ ...this.state, preAuthNumber: event.target.value });
          }}
          onBlur={() => {
            if (preAuthNumber) {
              this.autosave('preAuthNumber', preAuthNumber);
            }
          }}
        />
        <ROTextField
          id="preAuthNotes"
          fieldlabel="Insurance notes"
          defaultValue={insuranceNotes || ''}
          error={notesError}
          helperText={
            notesError
              ? 'Please reduce note length. Maximum is 2048 characters.'
              : `Maximum 2048 characters. ${2048 - (insuranceNotes?.length || 0)} remaining.`
          }
          placeholder="e.g. Including procedures authorised"
          multiline
          minRows={5}
          maxRows={5}
          alwaysShowHelper={true}
          maxLength={2048}
          onBlur={(): void => {
            if (typeof insuranceNotes !== 'undefined') {
              this.autosave('preAuthNotes', insuranceNotes);
            }
          }}
          onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
            this.setState({ insuranceNotes: event?.target.value });
          }}
        />
      </div>
    );
  }
}

const apolloComponent = withApollo<Props>(RORegInsurance);
export default apolloComponent;
