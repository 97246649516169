// eslint-disable-next-line no-use-before-define
import { useMutation } from '@apollo/client';
import { Field, Form, Formik, FormikProps } from 'formik';
import { FormContainer, ModalPractitionerSearch } from 'op-components';
import { ReferralType } from 'op-enums';
import { RegistrationContext } from 'op-contexts';
import { LOG_EVENT } from 'op-graphql/Logging';
import React, { useMemo, useRef, useState, useContext } from 'react';
import { useHistory } from 'react-router-dom';
import { FormRow } from 'shared-components/components';
import { SuccessInfo } from 'shared-components/components/FormFields';
import { Search } from 'shared-components/images';
import { GraphQLID } from 'shared-components/interfaces';
import { logPage, PRACTITIONER_LOG_EVENTS } from 'shared-components/utils/ElasticsearchHelpers';
import { styled } from '@mui/system';
import {
  CONTINUE_BUTTON_TEXT,
  generatePractitionerValidation,
  HeaderSubTitle,
  HeaderTitle,
  registrationPath,
  RETURN_TO_SUBMIT_TEXT,
  SectionTitle,
  sharedFormContainerProps,
  standardField,
  StandardFieldProps,
  StyledHorizontalLine,
} from '../Helper';
import { PatientReferrerRego, RegistrationFormUSIProps } from '../interfaces';
import { FIELDS } from './constants';
import { REGISTRATION_REFERRER_QUERY, UPDATE_REGISTRATION_REFERRER_LOOKUP } from './ReferrerQueries';

interface ReferrerFormIProps extends RegistrationFormUSIProps {
  patient: PatientReferrerRego;
  enableGpLookup: boolean;
  enableLogging?: boolean;
  logTime?: boolean;
}

interface PatientReferrerMutationData {
  id: GraphQLID;
  referrerType: string;
  referrerId: GraphQLID;
  firstName: string;
  lastName: string;
  practice: string;
  referrersOtherSpecialists: string;
}

const StyledReferrerSearch = styled('div')`
  cursor: pointer;
  color: ${(props) => props.theme.palette.text.primary};
  display: flex;
  text-decoration: underline;
  margin: 15px 0;
  align-items: center;
  .icon {
    filter: invert(38%) sepia(91%) saturate(3068%) hue-rotate(137deg) brightness(100%) contrast(101%);
    margin-right: 11px;
  }
`;

const disabledField = (
  field: StandardFieldProps,
  updateField: (field: string, value: string | number | boolean, referralType: ReferralType) => void,
  disabled: boolean,
  referralType: ReferralType,
): JSX.Element => (
  <FormRow fieldLabel={field.TITLE} fieldName={field.NAME} labelClass={'label-form-row'}>
    <Field
      name={field.NAME}
      component={field.COMPONENT}
      alternateStyle={true}
      updateMutation={(value: string) => updateField(field.NAME, value, referralType)}
      handleMutation={updateField}
      placeholder={field.PLACE_HOLDER}
      disabled={disabled}
    />
  </FormRow>
);

const ReferrerForm = (props: ReferrerFormIProps): JSX.Element => {
  const {
    patient,
    handleShowSaveExitConfirm,
    previousPageLink,
    updateField,
    isPso,
    enableGpLookup,
    enableLogging = true,
    logTime = true,
  } = props;

  const registrationContext = useContext(RegistrationContext);
  const showLookup = useMemo(() => isPso && enableGpLookup, [isPso, enableGpLookup]);

  const history = useHistory();
  const [showModal, setShowModal] = useState(false);
  const [disableGp, setDisableGp] = useState(showLookup);
  const [disableReferrer, setDisableReferrer] = useState(showLookup);
  const [referralType, setReferralType] = useState(ReferralType.GP);
  const goToSummary = registrationContext?.registrationSummaryVisited;
  const gpLookupUsed = registrationContext?.gpLookupUsed;
  const referrerLookupUsed = registrationContext?.referrerLookupUsed;
  const setGpLookupUsed = registrationContext?.setGpLookupUsed;
  const setReferrerLookupUsed = registrationContext?.setReferrerLookupUsed;

  const refetchQueries = [{ query: REGISTRATION_REFERRER_QUERY, variables: { id: patient?.id } }];

  const startTime = useRef<number>(new Date().getTime());
  const [logEvent] = useMutation(LOG_EVENT);
  const [updatePatientReferrerByLookupMutation] = useMutation(UPDATE_REGISTRATION_REFERRER_LOOKUP, {
    refetchQueries,
  });

  const recordMatched = (referralType: ReferralType, values: PatientReferrerRego): boolean => {
    let allFieldsAvailable = false;

    if (referralType === ReferralType.GP) {
      allFieldsAvailable = !!(values.gpFirstName && values.gpLastName && values.gpPractice && values.gpIsInMosaiq);
    } else if (referralType === ReferralType.SPECIALIST) {
      allFieldsAvailable = !!(
        values.referringFirstName &&
        values.referringLastName &&
        values.referringPractice &&
        values.referringIsInMosaiq
      );
    }

    if (enableLogging && showLookup && allFieldsAvailable) {
      // Log Exact Match
      const currentTime = new Date().getTime();
      const timeOnPage = (currentTime - startTime?.current) / 1000;
      const pageData = {
        timeOnPage,
        patientHzId: patient.id,
        referralType,
        eventName: `${PRACTITIONER_LOG_EVENTS.PRACTITIONER_EXACT_MATCH}`,
      };
      logPage(startTime?.current, pageData, logEvent, logTime);
    }

    return allFieldsAvailable;
  };

  const referringSpecialistMatched = useMemo<boolean>(() => recordMatched(ReferralType.SPECIALIST, patient), [patient]);
  const gpMatched = useMemo<boolean>(() => recordMatched(ReferralType.GP, patient), [patient]);

  return (
    <Formik
      initialValues={patient}
      enableReinitialize={isPso}
      validateOnChange={false}
      onSubmit={() => {
        history.push(registrationPath(patient?.id, goToSummary ? 'summary' : 'insurance'));
      }}>
      {({ submitForm, errors }: FormikProps<PatientReferrerRego>) => {
        const sharedProps = sharedFormContainerProps('Referrers', 2, previousPageLink);
        const formContainerProps = {
          ...sharedProps,
          submitButtonText: goToSummary ? RETURN_TO_SUBMIT_TEXT : CONTINUE_BUTTON_TEXT,
          continueDisabled: Object.keys(errors).length > 0,
          handleShowSaveExitConfirm,
        };

        const displayReferrerSearch = (referralType: ReferralType, lookupCount: boolean): JSX.Element => {
          const generateErrorMessage = (lookupCount: boolean): JSX.Element => {
            if (lookupCount) return generatePractitionerValidation(referralType, 'warning');
            return generatePractitionerValidation(referralType, 'error');
          };

          return (
            <>
              <StyledReferrerSearch
                data-test-id={`${referralType}-search`}
                onClick={(e): void => {
                  e.preventDefault();
                  setReferralType(referralType);
                  setShowModal(true);
                }}>
                <Search className="icon"></Search>
                Referring specialist lookup
              </StyledReferrerSearch>
              {((referralType === ReferralType.GP && !gpMatched) ||
                (referralType === ReferralType.SPECIALIST && !referringSpecialistMatched)) &&
                !showModal &&
                generateErrorMessage(lookupCount)}
            </>
          );
        };

        const handleUpdateReferrer = (
          field: string,
          value: string | number | boolean,
          referralType: ReferralType,
        ): void => {
          let variables: Partial<PatientReferrerMutationData> = {
            id: patient?.id,
          };

          if (referralType === ReferralType.GP) {
            variables = {
              ...variables,
              referrerType: 'generalPractitioner',
              firstName: patient.gpFirstName,
              lastName: patient.gpLastName,
              practice: patient.gpPractice,
            };
          } else if (referralType === ReferralType.SPECIALIST) {
            variables = {
              ...variables,
              referrerType: 'referringSurgeon',
              firstName: patient.referringFirstName,
              lastName: patient.referringLastName,
              practice: patient.referringPractice,
            };
          }

          // Override the variable field
          const mapFieldOverride = {
            gpFirstName: 'firstName',
            gpLastName: 'lastName',
            gpPractice: 'practice',
            referringFirstName: 'firstName',
            referringLastName: 'lastName',
            referringPractice: 'practice',
          };

          const overrideField = mapFieldOverride[field as keyof typeof mapFieldOverride] || '';
          if (overrideField) variables = { ...variables, [overrideField]: value };

          updatePatientReferrerByLookupMutation({ variables });
        };

        return (
          <>
            <ModalPractitionerSearch
              isOpen={showModal}
              dismissFunction={(_status: boolean): void => {
                setShowModal(false);
              }}
              userInfo={patient}
              referralType={referralType}
              makeFieldsEditable={(referralType: ReferralType) => {
                if (referralType === ReferralType.GP) {
                  setDisableGp(false);
                  setGpLookupUsed(true);
                }
                if (referralType === ReferralType.SPECIALIST) {
                  setDisableReferrer(false);
                  setReferrerLookupUsed(true);
                }
              }}
              disableFields={(referralType: ReferralType) => {
                if (referralType === ReferralType.GP) {
                  setDisableGp(true);
                }
                if (referralType === ReferralType.SPECIALIST) {
                  setDisableReferrer(true);
                }
              }}
              enableLogging={showLookup && enableLogging}
            />
            <FormContainer {...formContainerProps} submitForm={submitForm}>
              <Form>
                <HeaderTitle data-test-id="au-rego-page-title">{FIELDS.FORM_HEADING.TITLE}</HeaderTitle>
                {!isPso && (
                  <HeaderSubTitle>
                    This information may be used by your care team at GenesisCare to provide correspondence updates
                    and/or request additional information.
                  </HeaderSubTitle>
                )}
                <SectionTitle>Referring specialist</SectionTitle>
                {referringSpecialistMatched && showLookup && <SuccessInfo successes={['Record matched in database']} />}
                {!isPso && <div>Referring specialist is the doctor who sent you to GenesisCare.</div>}
                {disabledField(
                  FIELDS.REFERRING_FIRST_NAME,
                  handleUpdateReferrer,
                  disableReferrer,
                  ReferralType.SPECIALIST,
                )}
                {disabledField(
                  FIELDS.REFERRING_LAST_NAME,
                  handleUpdateReferrer,
                  disableReferrer,
                  ReferralType.SPECIALIST,
                )}
                {disabledField(
                  FIELDS.REFERRING_PRACTICE,
                  handleUpdateReferrer,
                  disableReferrer,
                  ReferralType.SPECIALIST,
                )}

                {showLookup && displayReferrerSearch(ReferralType.SPECIALIST, referrerLookupUsed)}

                {showLookup && <StyledHorizontalLine />}
                <div style={{ paddingTop: '32px' }}>
                  <SectionTitle>{'GP (General practitioner)'}</SectionTitle>
                </div>
                {gpMatched && showLookup && <SuccessInfo successes={['Record matched in database']} />}
                {disabledField(FIELDS.GP_FIRST_NAME, handleUpdateReferrer, disableGp, ReferralType.GP)}
                {disabledField(FIELDS.GP_LAST_NAME, handleUpdateReferrer, disableGp, ReferralType.GP)}
                {disabledField(FIELDS.GP_PRACTICE, handleUpdateReferrer, disableGp, ReferralType.GP)}

                {showLookup && displayReferrerSearch(ReferralType.GP, gpLookupUsed)}

                {showLookup && <StyledHorizontalLine />}
                <div style={{ paddingTop: '32px' }}>
                  <SectionTitle>{'Other specialists'}</SectionTitle>
                  {standardField(FIELDS.OTHER_SPECIALISTS, updateField)}
                </div>
              </Form>
            </FormContainer>
          </>
        );
      }}
    </Formik>
  );
};

export default ReferrerForm;
