import {
  HAIssueItem,
  HAMedication,
  HAOperationsDateItem,
  CancerItem,
  RadiotherapyCondition,
  HAAllergy,
  FamilyHistoryItem,
} from 'op-interfaces';

export class HABase {
  id: string;
  [key: string]: any;
  public constructor() {
    this.id = '';
  }
}

export class HAPatientInformationItem extends HABase {
  public patient: {
    fullName: string;
    dob: string;
  };
  public height: string;
  public weight: string;

  public constructor() {
    super();
    this.patient = {
      fullName: '',
      dob: '',
    };
    this.height = '';
    this.weight = '';
  }

  public loadData = (object: any): HAPatientInformationItem => {
    this.id = object.id;
    this.patient = object.patient;
    this.height = object.height;
    this.weight = object.weight;
    return this;
  };
}

export class HAOtherMedicalProblemsItem extends HABase {
  public otherMedProblemBool: boolean;
  public otherMedProblemDescription: string;
  public altMedicineBool: boolean;
  public altMedicineDescription: string;

  public constructor() {
    super();
    this.otherMedProblemBool = false;
    this.otherMedProblemDescription = '';
    this.altMedicineBool = false;
    this.altMedicineDescription = '';
  }

  public loadData = (object: any): HAOtherMedicalProblemsItem => {
    this.id = object.id;
    this.otherMedProblemBool = object.otherMedProblemBool;
    this.otherMedProblemDescription = object.otherMedProblemDescription;
    this.altMedicineBool = object.altMedicineBool;
    this.altMedicineDescription = object.altMedicineDescription;
    return this;
  };
}
export class HAMedicalHistoryOtherItem extends HABase {
  public mobilityAid: string | null;
  public mobilityAids: HAIssueItem[];
  public wellbeingIssue: string | null;
  public wellbeingIssues: HAIssueItem[];
  public activeInjury: string | null;
  public activeInjuryReason: string;
  public inPain: string | null;
  public inPainReason: string;
  public fallen: string | null;
  public fallenReason: string;
  public pregnant: string | null;
  public advancedCareDirective: string | null;
  public advancedCareAdditionalInformation: string | null;

  public constructor() {
    super();
    this.mobilityAid = null;
    this.mobilityAids = [];
    this.wellbeingIssue = null;
    this.wellbeingIssues = [];
    this.activeInjury = null;
    this.activeInjuryReason = '';
    this.inPain = null;
    this.inPainReason = '';
    this.fallen = null;
    this.fallenReason = '';
    this.pregnant = null;
    this.advancedCareDirective = null;
    this.advancedCareAdditionalInformation = null;
  }

  public loadData = (object: any): HAMedicalHistoryOtherItem => {
    this.id = object.id;
    this.mobilityAid = object.mobilityAid;
    this.mobilityAids = object.mobilityAids;
    this.wellbeingIssue = object.wellbeingIssue;
    this.wellbeingIssues = object.wellbeingIssues;
    this.activeInjury = object.activeInjury;
    this.activeInjuryReason = object.activeInjuryReason;
    this.inPain = object.inPain;
    this.inPainReason = object.inPainReason;
    this.fallen = object.fallen;
    this.fallenReason = object.fallenReason;
    this.pregnant = object.pregnant;
    this.advancedCareDirective = object.advancedCareDirective;
    this.advancedCareAdditionalInformation = object.advancedCareAdditionalInformation;
    return this;
  };
}

export class HASocialAssessmentItem extends HABase {
  public alcoholBool: boolean;
  public alcoholFrequency: string | '';
  public smokingBool: boolean;
  public smokingHistory: string | '';
  public smokingFrequency: string | '';
  public smokingStopDate: string | '';
  public smokingStopRawMonth: string | '';
  public smokingStopRawYear: string | '';
  public exerciseBool: boolean;
  public exerciseFrequency: string | '';
  public accommodationAssistance: boolean;
  public accommodationAssistanceReason: string | '';
  public transportationAssistance: boolean;
  public transportationAssistanceReason: string | '';

  public constructor() {
    super();
    this.alcoholBool = false;
    this.alcoholFrequency = '';
    this.smokingBool = false;
    this.smokingHistory = '';
    this.smokingFrequency = '';
    this.smokingStopDate = '';
    this.smokingStopRawMonth = '';
    this.smokingStopRawYear = '';
    this.exerciseBool = false;
    this.exerciseFrequency = '';
    this.accommodationAssistance = false;
    this.accommodationAssistanceReason = '';
    this.transportationAssistance = false;
    this.transportationAssistanceReason = '';
  }

  public loadData = (object: any): HASocialAssessmentItem => {
    this.id = object.id;
    this.alcoholBool = object.alcoholBool;
    this.alcoholFrequency = object.alcoholFrequency;
    this.smokingBool = object.smokingBool;
    this.smokingHistory = object.smokingHistory;
    this.smokingFrequency = object.smokingFrequency;
    this.smokingStopDate = object.smokingStopDate;
    this.smokingStopRawMonth = object.smokingStopRawMonth;
    this.smokingStopRawYear = object.smokingStopRawYear;
    this.exerciseBool = object.exerciseBool;
    this.exerciseFrequency = object.exerciseFrequency;
    this.accommodationAssistance = object.accommodationAssistance;
    this.accommodationAssistanceReason = object.accommodationAssistanceReason;
    this.transportationAssistance = object.transportationAssistance;
    this.transportationAssistanceReason = object.transportationAssistanceReason;
    return this;
  };
}
export class HAMedicalHistoryIllnessItem extends HABase {
  public internalDevice: string | null;
  public internalDevices: HAIssueItem[];
  public bloodClot: string | null;
  public bloodClotAnswer: string;
  public heartOrLungProblem: string | null;
  public heartOrLungAnswer: string;
  public highOrLowBloodPressure: string | null;
  public bloodPressureAnswer: string;
  public diabetes: string | null;
  public diabetesResponses: HAIssueItem[];
  public intractableInfectionOrIllness: string | null;
  public intractableInfectionOrIllnesses: HAIssueItem[];
  public immunosuppressiveCondition: string | null;
  public immunosuppressiveConditions: HAIssueItem[];

  public constructor() {
    super();
    this.internalDevice = null;
    this.internalDevices = [];
    this.bloodClot = null;
    this.bloodClotAnswer = '';
    this.heartOrLungProblem = null;
    this.heartOrLungAnswer = '';
    this.highOrLowBloodPressure = null;
    this.bloodPressureAnswer = '';
    this.diabetes = null;
    this.diabetesResponses = [];
    this.intractableInfectionOrIllness = null;
    this.intractableInfectionOrIllnesses = [];
    this.immunosuppressiveCondition = null;
    this.immunosuppressiveConditions = [];
  }

  public loadData = (object: any): HAMedicalHistoryIllnessItem => {
    this.id = object.id;
    this.internalDevice = object.internalDevice;
    this.internalDevices = object.internalDevices;
    this.bloodClot = object.bloodClot;
    this.bloodClotAnswer = object.bloodClotAnswer;
    this.heartOrLungProblem = object.heartOrLungProblem;
    this.heartOrLungAnswer = object.heartOrLungAnswer;
    this.highOrLowBloodPressure = object.highOrLowBloodPressure;
    this.bloodPressureAnswer = object.bloodPressureAnswer;
    this.diabetes = object.diabetes;
    this.diabetesResponses = object.diabetesResponses;
    this.intractableInfectionOrIllness = object.intractableInfectionOrIllness;
    this.intractableInfectionOrIllnesses = object.intractableInfectionOrIllnesses;
    this.immunosuppressiveCondition = object.immunosuppressiveCondition;
    this.immunosuppressiveConditions = object.immunosuppressiveConditions;
    return this;
  };
}
export class HAMedicationsItem extends HABase {
  public medicationBool: boolean | null;
  public pharmacyName: string;
  public pharmacyPhone: string;
  public medications: HAMedication[];

  public constructor() {
    super();
    this.medicationBool = false;
    this.pharmacyName = '';
    this.pharmacyPhone = '';
    this.medications = [];
  }
  public loadData = (object: any): HAMedicationsItem => {
    this.id = object.id;
    this.medicationBool = object.medicationBool;
    this.pharmacyName = object.pharmacyName;
    this.pharmacyPhone = object.pharmacyPhone;
    this.medications = object.medications;
    return this;
  };
}

export class HAOperationsItem extends HABase {
  public prevOperation: boolean | null;
  public operations: HAOperationsDateItem[];

  public constructor() {
    super();
    this.prevOperation = false;
    this.operations = [];
  }

  public loadData = (object: any): HAOperationsItem => {
    this.id = object.id;
    this.prevOperation = object.prevOperation;
    this.operations = object.operations;
    return this;
  };
}

export class HACancerItem extends HABase {
  public previousCancer: string | null;
  public cancer: CancerItem[];
  public otherRadiotherapy: string | null;
  public otherRadiotherapyConditions: RadiotherapyCondition[];
  public familyHistory: string | null;
  public familyHistoryAnswer: string | undefined;
  public usFamilyHistory: FamilyHistoryItem[];
  public constructor() {
    super();
    this.previousCancer = null;
    this.cancer = [];
    this.otherRadiotherapy = '';
    this.otherRadiotherapyConditions = [];
    this.familyHistory = '';
    this.familyHistoryAnswer = '';
    this.usFamilyHistory = [];
  }

  public loadData = (object: any): HACancerItem => {
    this.id = object.id;
    this.previousCancer = object.previousCancer;
    this.cancer = object.cancer;
    this.otherRadiotherapy = object.otherRadiotherapy;
    this.otherRadiotherapyConditions = object.otherRadiotherapyConditions;
    this.familyHistory = object.familyHistory;
    this.familyHistoryAnswer = object.familyHistoryAnswer;
    this.usFamilyHistory = object.usFamilyHistory;
    return this;
  };
}

export class HAAllergiesItem extends HABase {
  public allergyBool: boolean;
  public allergies: HAAllergy[];

  public constructor() {
    super();
    this.allergyBool = false;
    this.allergies = [];
  }
  public loadData = (object: any): HAAllergiesItem => {
    this.id = object.id;
    this.allergyBool = object.allergyBool;
    this.allergies = object.allergies;
    return this;
  };
}

export class HAReviewSummaryItem extends HABase {
  public patient: {
    fullName: string;
    dob: string;
  };
  public height: string;
  public weight: string;

  public otherMedProblemBool: boolean;
  public otherMedProblemDescription: string;
  public altMedicineBool: boolean;
  public altMedicineDescription: string;

  public mobilityAid: string | null;
  public mobilityAids: HAIssueItem[];
  public wellbeingIssue: string | null;
  public wellbeingIssues: HAIssueItem[];
  public activeInjury: string | null;
  public activeInjuryReason: string;
  public inPain: string | null;
  public inPainReason: string;
  public fallen: string | null;
  public fallenReason: string;
  public pregnant: string | null;
  public advancedCareDirective: string | null;
  public advancedCareAdditionalInformation: boolean | null;

  public alcoholBool: boolean;
  public alcoholFrequency: string | '';
  public smokingBool: boolean;
  public smokingHistory: string | '';
  public smokingFrequency: string | '';
  public smokingStopDate: string | '';
  public smokingStopRawMonth: string | '';
  public smokingStopRawYear: string | '';
  public exerciseBool: boolean;
  public exerciseFrequency: string | '';
  public accommodationAssistance: boolean;
  public accommodationAssistanceReason: string | '';
  public transportationAssistance: boolean;
  public transportationAssistanceReason: string | '';

  public internalDevice: string | null;
  public internalDevices: HAIssueItem[];
  public bloodClot: string | null;
  public bloodClotAnswer: string;
  public heartOrLungProblem: string | null;
  public heartOrLungAnswer: string;
  public highOrLowBloodPressure: string | null;
  public bloodPressureAnswer: string;
  public diabetes: string | null;
  public diabetesResponses: HAIssueItem[];
  public intractableInfectionOrIllness: string | null;
  public intractableInfectionOrIllnesses: HAIssueItem[];
  public immunosuppressiveCondition: string | null;
  public immunosuppressiveConditions: HAIssueItem[];

  public medicationBool: boolean | null;
  public medications: HAMedication[];

  public prevOperation: boolean | null;
  public operations: HAOperationsDateItem[];

  public previousCancer: string | null;
  public cancer: CancerItem[];
  public otherRadiotherapy: string | null;
  public otherRadiotherapyConditions: RadiotherapyCondition[];
  public familyHistory: string | null;
  public familyHistoryAnswer: string | null;

  public allergyBool: boolean;
  public allergies: HAAllergy[];

  public constructor() {
    super();
    this.patient = {
      fullName: '',
      dob: '',
    };
    this.height = '';
    this.weight = '';

    this.otherMedProblemBool = false;
    this.otherMedProblemDescription = '';
    this.altMedicineBool = false;
    this.altMedicineDescription = '';

    this.mobilityAid = null;
    this.mobilityAids = [];
    this.wellbeingIssue = null;
    this.wellbeingIssues = [];
    this.activeInjury = null;
    this.activeInjuryReason = '';
    this.inPain = null;
    this.inPainReason = '';
    this.fallen = null;
    this.fallenReason = '';
    this.pregnant = null;
    this.advancedCareDirective = null;
    this.advancedCareAdditionalInformation = null;

    this.alcoholBool = false;
    this.alcoholFrequency = '';
    this.smokingBool = false;
    this.smokingHistory = '';
    this.smokingFrequency = '';
    this.smokingStopDate = '';
    this.smokingStopRawMonth = '';
    this.smokingStopRawYear = '';
    this.exerciseBool = false;
    this.exerciseFrequency = '';
    this.accommodationAssistance = false;
    this.accommodationAssistanceReason = '';
    this.transportationAssistance = false;
    this.transportationAssistanceReason = '';

    this.internalDevice = null;
    this.internalDevices = [];
    this.bloodClot = null;
    this.bloodClotAnswer = '';
    this.heartOrLungProblem = null;
    this.heartOrLungAnswer = '';
    this.highOrLowBloodPressure = null;
    this.bloodPressureAnswer = '';
    this.diabetes = null;
    this.diabetesResponses = [];
    this.intractableInfectionOrIllness = null;
    this.intractableInfectionOrIllnesses = [];
    this.immunosuppressiveCondition = null;
    this.immunosuppressiveConditions = [];

    this.medicationBool = false;
    this.medications = [];

    this.prevOperation = false;
    this.operations = [];

    this.previousCancer = null;
    this.cancer = [];
    this.otherRadiotherapy = '';
    this.otherRadiotherapyConditions = [];
    this.familyHistory = '';
    this.familyHistoryAnswer = '';

    this.allergyBool = false;
    this.allergies = [];
  }

  public loadData = (object: any): HAReviewSummaryItem => {
    this.id = object.id;
    this.patient = object.patient;
    this.height = object.height;
    this.weight = object.weight;

    this.otherMedProblemBool = object.otherMedProblemBool;
    this.otherMedProblemDescription = object.otherMedProblemDescription;
    this.altMedicineBool = object.altMedicineBool;
    this.altMedicineDescription = object.altMedicineDescription;

    this.mobilityAid = object.mobilityAid;
    this.mobilityAids = object.mobilityAids;
    this.wellbeingIssue = object.wellbeingIssue;
    this.wellbeingIssues = object.wellbeingIssues;
    this.activeInjury = object.activeInjury;
    this.activeInjuryReason = object.activeInjuryReason;
    this.inPain = object.inPain;
    this.inPainReason = object.inPainReason;
    this.fallen = object.fallen;
    this.fallenReason = object.fallenReason;
    this.pregnant = object.pregnant;
    this.advancedCareDirective = object.advancedCareDirective;
    this.advancedCareAdditionalInformation = object.advancedCareAdditionalInformation;

    this.alcoholBool = object.alcoholBool;
    this.alcoholFrequency = object.alcoholFrequency;
    this.smokingBool = object.smokingBool;
    this.smokingHistory = object.smokingHistory;
    this.smokingFrequency = object.smokingFrequency;
    this.smokingStopDate = object.smokingStopDate;
    this.smokingStopRawMonth = object.smokingStopRawMonth;
    this.smokingStopRawYear = object.smokingStopRawYear;
    this.exerciseBool = object.exerciseBool;
    this.exerciseFrequency = object.exerciseFrequency;
    this.accommodationAssistance = object.accommodationAssistance;
    this.accommodationAssistanceReason = object.accommodationAssistanceReason;
    this.transportationAssistance = object.transportationAssistance;
    this.transportationAssistanceReason = object.transportationAssistanceReason;

    this.internalDevice = object.internalDevice;
    this.internalDevices = object.internalDevices;
    this.bloodClot = object.bloodClot;
    this.bloodClotAnswer = object.bloodClotAnswer;
    this.heartOrLungProblem = object.heartOrLungProblem;
    this.heartOrLungAnswer = object.heartOrLungAnswer;
    this.highOrLowBloodPressure = object.highOrLowBloodPressure;
    this.bloodPressureAnswer = object.bloodPressureAnswer;
    this.diabetes = object.diabetes;
    this.diabetesResponses = object.diabetesResponses;
    this.intractableInfectionOrIllness = object.intractableInfectionOrIllness;
    this.intractableInfectionOrIllnesses = object.intractableInfectionOrIllnesses;
    this.immunosuppressiveCondition = object.immunosuppressiveCondition;
    this.immunosuppressiveConditions = object.immunosuppressiveConditions;

    this.medicationBool = object.medicationBool;
    this.medications = object.medications;

    this.prevOperation = object.prevOperation;
    this.operations = object.operations;

    this.previousCancer = object.previousCancer;
    this.cancer = object.cancer;
    this.otherRadiotherapy = object.otherRadiotherapy;
    this.otherRadiotherapyConditions = object.otherRadiotherapyConditions;
    this.familyHistory = object.familyHistory;
    this.familyHistoryAnswer = object.familyHistoryAnswer;

    this.allergyBool = object.allergyBool;
    this.allergies = object.allergies;

    return this;
  };
}
