// eslint-disable-next-line no-use-before-define
import React, { useEffect } from 'react';
import { PatientBasic } from 'op-interfaces';
import { gql, useQuery } from '@apollo/client';
import { ModalSearchResults } from 'op-components/index';
import { useErrorModalContext } from 'op-contexts';

interface DupeProps {
  patient: PatientBasic;
  redirect?: string;
  variables: { [id: string]: string };
}

interface SearchResults {
  ukDedupSearch: PatientBasic[];
}

const searchQuery = gql`
  query search($search: String!, $dob: Date, $existingPatient: String) {
    ukDedupSearch(search: $search, dob: $dob, existingPatient: $existingPatient) {
      id
      firstName
      lastName
      dob
      ida
      residentialAddressLine1
      residentialAddressLine2
      residentialAddressCity
      residentialAddressPostcode
    }
  }
`;

export const DeduplicationModal = (props: DupeProps) => {
  const { patient, variables } = props;
  const [closed, setClosed] = React.useState(false);
  const blankSearch = { search: '' };
  const { data, error } = useQuery<SearchResults>(searchQuery, { variables: closed ? blankSearch : variables });
  const { setError } = useErrorModalContext();
  useEffect(() => {
    if (error) return setError();
  }, [error]);
  useEffect(() => {
    setClosed(false);
  }, [variables.search, variables.dob, variables.existingPatient]);
  if (data) {
    return (
      <ModalSearchResults
        isOpen={data && data.ukDedupSearch && data.ukDedupSearch.length > 0}
        data={data}
        redirect={props.redirect ? props.redirect : '/opclinic/temppatientid/patient'}
        dismissFunction={() => setClosed(true)}
        patient={patient}
      />
    );
  }
  return <></>;
};
