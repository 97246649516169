import { gql } from '@apollo/client';

export const PATIENT_QUERY_GQL = gql`
  query Patient {
    patient {
      firstName
      lastName
    }
  }
`;
