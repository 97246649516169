import { TextAreaField } from 'shared-components/components/FormikComponents';

export const CONTACT_FIELDS = {
  FORM_HEADING: {
    NAME: 'formHeading',
    TITLE: 'Contact Details',
  },
  MOBILE: {
    NAME: 'primaryPhone',
    TITLE: 'Mobile*',
    PLACE_HOLDER: 'Enter at least one phone number',
    COMPONENT: TextAreaField,
  },
  HOME_PHONE: {
    NAME: 'secondaryPhone',
    TITLE: 'Home phone*',
    PLACE_HOLDER: 'Enter at least one phone number',
    COMPONENT: TextAreaField,
  },
  EMAIL: {
    NAME: 'email',
    TITLE: 'Email',
    PLACE_HOLDER: 'e.g. yourname@email.com',
    COMPONENT: TextAreaField,
  },
};
