import { useMutation, useQuery } from '@apollo/client';
import React from 'react';
import { useParams } from 'react-router-dom';
import { ListData } from 'shared-components/interfaces';
import { OPUser } from 'op-interfaces';
import { FIELDS } from './constants';
import { registrationPath } from '../Helper';
import { REGISTRATION_INSURANCE_QUERY, UPDATE_REGISTRATION_INSURANCE } from './InsuranceQueries';
import InsuranceForm from './InsuranceForm';
import { FormProps, PatientInsuranceOtherBenefit } from '../interfaces';

const Insurance = (props: FormProps): JSX.Element => {
  const { exitForm } = props;
  const { patientId } = useParams<{ patientId: string }>();

  const [updateRegistrationInsurance] = useMutation(UPDATE_REGISTRATION_INSURANCE);

  const { data, error, loading } = useQuery<{
    patient: PatientInsuranceOtherBenefit;
    relationshipRefData: ListData[];
    user: OPUser;
  }>(REGISTRATION_INSURANCE_QUERY, {
    variables: { id: patientId },
  });
  const insuranceId = data?.patient?.primaryInsurance?.id;
  const secondaryInsuranceId = data?.patient?.secondaryInsurance?.id;

  const deleteVeteranData = () => {
    const veteranBenefitFields = {
      receiveVeteransAdminBenefits: false,
      veteransAdminName: '',
      veteransContactNumber: '',
      veteransCity: '',
      veteransStateProvince: '',
      veteransZip: '',
    };
    return updateRegistrationInsurance({ variables: { id: patientId, ...veteranBenefitFields } });
  };

  const updateField = (field: string, value: string | boolean | number) => {
    if (field === FIELDS.OTHER_BENEFITS.NAME && !value) {
      return deleteVeteranData();
    }

    return updateRegistrationInsurance({
      variables: { id: patientId, insuranceId, secondaryInsuranceId, [field]: value },
    });
  };

  if (loading || !data || error) return <></>;

  const { patient, relationshipRefData, user } = data;

  return (
    <InsuranceForm
      refData={{
        relationshipRefData,
      }}
      patient={patient}
      updateField={updateField}
      handleShowSaveExitConfirm={() => exitForm(true)}
      previousPageLink={registrationPath(patient.id, 'referrers')}
      isPso={!!user.isPso}
    />
  );
};

export default Insurance;
