// eslint-disable-next-line no-use-before-define
import classnames from 'classnames';
import { useEffect, useState } from 'react';

import { RouteComponentProps, withRouter } from 'react-router';

import { FAQ_CONSTANTS } from '../faqConstants';
import './FAQContent.scss';

import { FaqContent } from '../../../interfaces';

import { DeviceUtilities } from 'op-pages/PX/utils';
import { QAAccordion } from 'shared-components/components';
import { Button } from '@mui/material';

interface Props extends RouteComponentProps {
  data: FaqContent;
  pageCategory: keyof typeof FAQ_CONSTANTS;
}

const FAQContent = (props: Props): JSX.Element => {
  const [contentState, setContentState] = useState({
    formattedCategoryHeading: '',
    headerBackgroundImage: '',
  });
  const { data, pageCategory, history } = props;

  useEffect((): void => {
    const content = {
      headerBackgroundImage: FAQ_CONSTANTS[pageCategory]['image'],
      formattedCategoryHeading: FAQ_CONSTANTS[pageCategory]['title'],
    };
    setContentState(content);
  }, []);

  const backgroundImageStyle = {
    backgroundImage: `linear-gradient(rgba(46, 46, 46, 0.5), rgba(46, 46, 46, 0.5)), url(${contentState.headerBackgroundImage})`,
  };

  return (
    <>
      <div className="px-faq-page-container">
        <div
          className={classnames('px-faq-header-container', {
            [`${pageCategory.split(' ').join('-')}`]: pageCategory,
          })}
          style={backgroundImageStyle}>
          <h1 className="px-faq-heading">{contentState.formattedCategoryHeading}</h1>
        </div>
        <div className="px-faq-list-container">
          {data.questions.map(
            (question, index): JSX.Element => (
              <QAAccordion title={question} content={data.answers[index]} key={question} />
            ),
          )}
        </div>
        {DeviceUtilities.isDesktopDevice() && (
          <div className="px-desktop-back-button">
            <Button onClick={(): void => history.push('/px/information/faq')}>Back</Button>
          </div>
        )}
      </div>
    </>
  );
};

export default withRouter(FAQContent);
