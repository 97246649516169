import { FormikValues, validateYupSchema, yupToFormErrors } from 'formik';
import { addressLabelForRegion } from 'shared-components/enums';
import { AU_POSTCODE_REGEXP, UK_POSTCODE_REGEXP, US_POSTCODE_REGEXP } from 'shared-components/utils/Regex';
import * as yup from 'yup';
import 'yup-phone-lite';
import { PHONE_REQUIRED, PHONE_VALIDATION, VALUE_REQUIRED, ADDRESS_LINE1_REQUIRED } from './common';

export const generateValidationSchema = (values: FormikValues): any => {
  const homeAndMobileValidationMessage = PHONE_REQUIRED;
  let postcodeValidation: yup.StringSchema<string | undefined, object> = yup.string();
  const addressLabels = addressLabelForRegion();
  const postcodeLabel = `Please enter your correct ${addressLabels.postcode}`;

  switch (values.address.country) {
    case 'Australia':
      postcodeValidation = yup.string().matches(AU_POSTCODE_REGEXP, postcodeLabel);
      break;
    case 'United Kingdom':
    case 'Scotland':
    case 'England':
    case 'Northern Ireland':
    case 'Wales':
      postcodeValidation = yup.string().matches(UK_POSTCODE_REGEXP, postcodeLabel);
      break;
    case 'US':
    case 'USA':
    case 'United States':
    case 'United States of America':
      postcodeValidation = yup.string().matches(US_POSTCODE_REGEXP, postcodeLabel);
      break;
    default:
    // no validation
  }

  const patientValidationSchema = yup.object().shape(
    {
      firstName: yup.string().ensure().required(VALUE_REQUIRED),
      lastName: yup.string().ensure().required(VALUE_REQUIRED),
      gender: yup.string().ensure().required(VALUE_REQUIRED),
      dateOfBirth: yup.string().ensure().required(VALUE_REQUIRED),
      responsiblePhysicianId: yup.string().ensure().required(VALUE_REQUIRED),
      address: yup.object().shape(
        {
          // If any part of Address is filled in, require all parts to be filled in.
          // Allow no parts of Address to be filled in.
          autofilled: yup.string().when('postcode', {
            is: (postcode: string) => postcodeValidation.isValidSync(postcode),
            then: (schema: any) => schema.ensure(),
            otherwise: () => postcodeValidation,
          }),
          line1: yup.string().when(['postcode', 'country', 'city', 'state'], {
            is: (postcode: string, country: string, city: string, state: string) =>
              !!postcode || !!country || !!city || !!state,
            then: yup.string().required(VALUE_REQUIRED),
          }),
          line2: yup.string().test('line2-required', ADDRESS_LINE1_REQUIRED, function (value) {
            // if Line 2 is filled in, require address line1
            if (value) {
              return !!this.parent.line1;
            } else {
              return true;
            }
          }),
          country: yup.string().when(['line1', 'city', 'state', 'postcode'], {
            is: (line1: string, city: string, state: string, postcode: string) =>
              Boolean(line1) || Boolean(city) || Boolean(state) || Boolean(postcode),
            then: yup.string().required(VALUE_REQUIRED),
          }),
          city: yup.string().when(['line1', 'country', 'state', 'postcode'], {
            is: (line1: string, country: string, state: string, postcode: string) =>
              Boolean(line1) || Boolean(country) || Boolean(state) || Boolean(postcode),
            then: yup.string().required(VALUE_REQUIRED),
          }),
          state: yup.string().when(['line1', 'country', 'city', 'postcode'], {
            is: (line1: string, country: string, city: string, postcode: string) =>
              Boolean(line1) || Boolean(country) || Boolean(city) || Boolean(postcode),
            then: yup.string().required(VALUE_REQUIRED),
          }),
          postcode: yup.string().when(['line1', 'country', 'city', 'state'], {
            is: (line1: string, country: string, city: string, state: string) =>
              Boolean(line1) || Boolean(country) || Boolean(city) || Boolean(state),
            then: yup.string().required(VALUE_REQUIRED),
          }),
        },
        // Resolve the dependency 'circle'. This tells yup which order to resolve the dependencies
        // Failure to do this will result in a yup circular validation error.
        [
          ['country', 'city'],
          ['country', 'postcode'],
          ['line1', 'country'],
          ['state', 'country'],
          ['state', 'city'],
          ['state', 'postcode'],
          ['line1', 'state'],
          ['line1', 'postcode'],
          ['line1', 'city'],
          ['postcode', 'city'],
        ],
      ),
      mobileNumber: yup
        .string()
        //@ts-ignore
        .phone(import.meta.env.REACT_APP_REGION, PHONE_VALIDATION)
        .test('home-number-required', homeAndMobileValidationMessage, function (value) {
          return !!value || !!this.parent.homeNumber;
        }),
      homeNumber: yup
        .string()
        //@ts-ignore
        .phone(import.meta.env.REACT_APP_REGION, PHONE_VALIDATION)
        .test('mobile-number-required', homeAndMobileValidationMessage, function (value) {
          return !!value || !!this.parent.mobileNumber;
        }),
    },
    [['mobileNumber', 'homeNumber']],
  );
  try {
    validateYupSchema<FormikValues>(values, patientValidationSchema, true, {
      validatePostcode: values.address.postcode && values.address.country ? true : false,
    });
  } catch (err) {
    return yupToFormErrors(err);
  }

  return {};
};
