import { useEffect, useState } from 'react';
import { useApolloClient, useMutation, useQuery } from '@apollo/client';
import { Typography } from '@mui/material';
import { CACHE_ERROR_MSG_QUERY, GET_CONTENT_SHOWN } from 'op-components/ErrorModalContextProvider/ErrorModalQueries';
import { ErrorModalContext } from 'op-contexts';
import { LOGOUT_MUTATION } from 'op-graphql/Logout';
import { CurrentAppConfig } from 'op-pages/RO/Careplan/AppConfig';
import { Logger, navigateToExternalURL } from 'shared-components/utils';
import { StandardDialog } from 'shared-components/components';

const logger = new Logger('ROErrorDialog');

interface Props {
  children: React.ReactNode;
}

const ErrorModal = (error: any): JSX.Element => {
  const [state, setState] = useState(false);
  const [isAuthError, setIsAuthError] = useState(false);
  const [isNonBlockingError, setIsNonBlockingError] = useState(false);
  const { data: cacheData } = useQuery(CACHE_ERROR_MSG_QUERY);
  const client = useApolloClient();
  const [logoutMutation] = useMutation(LOGOUT_MUTATION);
  const logout = (): void => {
    client.clearStore().then((): void => {
      client.writeQuery({
        query: GET_CONTENT_SHOWN,
        data: {
          contentShown: false,
        },
      });
    });

    logoutMutation({ variables: {} }).then(({ data }): void => {
      if (data.logout.errors !== null) {
        logger.error('logout', ['Unable to logout', data.logout.errors]);
        return;
      }
      navigateToExternalURL('/sso/logout');
    });
  };

  useEffect((): (() => void) => {
    if (cacheData && cacheData.error.statusCode !== -1) setState(true);

    if (cacheData && cacheData.error.statusCode >= 400 && cacheData.error.statusCode < 500) {
      if (error.errorType && error.errorType === 'ACCOUNT') {
        setIsAuthError(true);
      }
    }

    if (error.errorType && error.errorType === 'NON_BLOCKING') {
      setIsNonBlockingError(true);
    }

    return (): void => {
      setState(false);
      setIsAuthError(false);
    };
  }, [cacheData, error]);

  if (!(state || isAuthError) || isNonBlockingError) {
    return <></>;
  }
  const body = isAuthError ? CurrentAppConfig.ErrorPopup.Practitioner : CurrentAppConfig.ErrorPopup.Generic;
  return (
    <StandardDialog
      open
      title={isAuthError ? 'Account Error' : 'Something went wrong'}
      submitText={isAuthError ? 'Logout' : 'Refresh page'}
      onSubmit={(): void => {
        if (isAuthError) {
          logout();
        } else {
          window.location.reload();
        }
      }}>
      <div style={{ lineHeight: 1.2 }} dangerouslySetInnerHTML={{ __html: body }} />
    </StandardDialog>
  );
};

const ErrorModalContextProvider = (props: Props): JSX.Element => {
  const [error, setError] = useState('');

  return (
    <ErrorModalContext.Provider value={{ state: { error }, setError }}>
      <ErrorModal errorType={error} />
      {props.children}
    </ErrorModalContext.Provider>
  );
};

export default ErrorModalContextProvider;
