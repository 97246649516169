// eslint-disable-next-line no-use-before-define
import { useMutation, useQuery } from '@apollo/client';
import { MoreInfoText, PageTitle } from 'op-components';
import {
  ADD_MOBILITY_AID,
  DELETE_MOBILITY_AID,
  HA_MOBILITY_QUERY,
  UPDATE_HA_MOBILITY,
  UPDATE_MOBILITY_AID,
} from 'op-pages/OP/HealthAssessment/HASharedComponents/queries';
import { MobilityAidItem } from 'op-pages/OP/HealthAssessment/HASharedComponents/types';
import { resolveListDataValue } from 'op-utils';
import React, { useState } from 'react';

import { useParams } from 'react-router-dom';
import {
  DropDownField,
  FreeTextArea,
  FreeTextField,
  SectionField,
  SegmentedInput,
} from 'shared-components/components/FormFields';
import { MinusNoFill } from 'shared-components/images';
import { ListData } from 'shared-components/interfaces';
import { SegementedInputYesNoUnsure } from 'shared-components/utils';
import { MOBILITY_FIELDS } from '../constants';
import { Add as AddIcon } from '@mui/icons-material';

const initialViewedFields = [
  'mobilityAid',
  'fallen',
  'fallenHowMany',
  'fallenInjuries',
  'footPain',
  'unsteady',
  'lightheaded',
];

const mobilityFields = ['value', 'other'];

interface Props {
  validateOnLoad: boolean;
}

const YES = 'YES';

/*
  Adds all fields to the viewed fields list. Used for validating when navigating back to this page after
  viewing it already
*/
const initalValidationFields = (length: number, staticFields: string[], infinteFields: string[]): string[] => {
  const validationFields = staticFields;
  for (let i = 0; i < length; i++) {
    infinteFields.forEach((field: string) => validationFields.push(`mobilityAids.${i}.${field}`));
  }
  return validationFields;
};

const HAMobility = (props: Props): JSX.Element => {
  const { validateOnLoad } = props;
  const { patientId } = useParams<{ patientId: string }>();

  const [viewedFields, setViewedFields] = useState(new Set<string>());

  const { data, error } = useQuery(HA_MOBILITY_QUERY, {
    variables: { patientId },
    onCompleted: (data) => {
      if (!validateOnLoad) return; // Only run the below if returning to the page
      const length = data?.healthAssessment?.mobilityAids?.length || 0;
      const newViewedFields = new Set(initalValidationFields(length, initialViewedFields, mobilityFields));
      setViewedFields(newViewedFields);
    },
  });
  const [updateHaMobility] = useMutation(UPDATE_HA_MOBILITY);
  const [addMobilityAid, { loading: addMobilityAidLoading }] = useMutation(ADD_MOBILITY_AID, {
    refetchQueries: ['HealthAssessment'],
  });
  const [deleteMobilityAid, { loading: deleteMobilityAidLoading }] = useMutation(DELETE_MOBILITY_AID, {
    refetchQueries: ['HealthAssessment'],
  });
  const [updateMobilityAid] = useMutation(UPDATE_MOBILITY_AID, {
    refetchQueries: ['HealthAssessment'],
  });

  if (error || !data?.healthAssessment) return <></>;

  const healthAssessment = data.healthAssessment;
  const haId = healthAssessment.id;
  const referenceData = data.mobilityAidRefData;
  const showMobilities = healthAssessment.mobilityAid === YES;

  const addToViewedFields = (field: string) => {
    const newViewedFields = [...viewedFields];
    newViewedFields.push(field);
    setViewedFields(new Set(newViewedFields));
  };

  const updateField = (field: string, value: string) => {
    updateHaMobility({ variables: { haId, patientId, [field]: value } });
    addToViewedFields(field);
  };

  const updateMobilityAidField = (id: string, field: string, value: string, index: number) => {
    updateMobilityAid({ variables: { id, patientId, [field]: value } });
    addToViewedFields(`mobilityAids.${index}.${field}`);
  };

  const renderMobilityAids = () => {
    const removable = healthAssessment.mobilityAids.length > 1;
    return healthAssessment.mobilityAids.map((mobilityAid: MobilityAidItem, index: number) => {
      const inputName = `mobilityAids ${index}`;
      const mobilityAidValue = resolveListDataValue(mobilityAid.value, referenceData);
      return (
        <div key={inputName} className="infinite-dropdown-item">
          <SectionField htmlFor={inputName} title={`${'Mobility Aid'} ${index + 1}`}>
            <DropDownField
              inputName={inputName}
              placeholder={'Please select'}
              options={referenceData}
              defaultValue={mobilityAid.value}
              controlled={true}
              onChange={(e: React.ChangeEvent<HTMLSelectElement>): void => {
                updateMobilityAidField(mobilityAid?.id, 'value', e.target.value, index);
              }}
            />
            {mobilityAidValue === 'Other' && (
              <FreeTextField
                inputKey={mobilityAid.other}
                inputName={`${inputName}-other`}
                placeholder={'Please describe'}
                inputType="text"
                defaultValue={mobilityAid.other}
                onBlur={(e: React.ChangeEvent<HTMLInputElement>): void => {
                  updateMobilityAidField(mobilityAid?.id, 'other', e.target.value, index);
                }}
              />
            )}
          </SectionField>
          {removable && (
            <div className="remove-item">
              <div
                onClick={(): void => {
                  if (!deleteMobilityAidLoading) {
                    deleteMobilityAid({ variables: { id: mobilityAid.id, patientId } });
                  }
                }}>
                <MinusNoFill className="icon" />
                {'Remove'}
              </div>
            </div>
          )}
        </div>
      );
    });
  };

  return (
    <>
      <PageTitle title={'Mobility-fall risk assessment'} idPrefix="ha" />
      <div id="ha-fields" className="ha-medical-history-cancer">
        <SectionField htmlFor={MOBILITY_FIELDS.MOBILITY_AID.NAME} title={MOBILITY_FIELDS.MOBILITY_AID.TITLE}>
          <MoreInfoText moreInfoText={MOBILITY_FIELDS.MOBILITY_AID.MORE_INFO}>
            <SegmentedInput
              fieldName={MOBILITY_FIELDS.MOBILITY_AID.NAME}
              options={SegementedInputYesNoUnsure}
              defaultSelected={healthAssessment.mobilityAid}
              itemSelected={(selectedItem): void => {
                const selectedValue = (selectedItem as ListData).id;
                updateField('mobilityAid', selectedValue);

                // Add a mobility aid item if Yes selected
                if (selectedValue === YES && selectedValue !== healthAssessment?.mobilityAid) {
                  addMobilityAid({ variables: { haId, patientId } });
                }
              }}
            />
          </MoreInfoText>
        </SectionField>
        {showMobilities && renderMobilityAids()}
        {!!healthAssessment.mobilityAids.length && showMobilities && (
          <div
            className="additional-item-button"
            onClick={(): void => {
              if (!addMobilityAidLoading) addMobilityAid({ variables: { haId, patientId } });
            }}>
            <AddIcon className="icon" color="primary" />
            {'Add another mobility aid'}
          </div>
        )}
        <SectionField htmlFor={MOBILITY_FIELDS.FALLEN.NAME} title={MOBILITY_FIELDS.FALLEN.TITLE}>
          <SegmentedInput
            fieldName={MOBILITY_FIELDS.FALLEN.NAME}
            options={SegementedInputYesNoUnsure}
            defaultSelected={healthAssessment.fallen}
            itemSelected={(selectedItem): void => {
              const selectedValue = (selectedItem as ListData).id;
              updateField('fallen', selectedValue);
            }}
          />
        </SectionField>
        {healthAssessment.fallen === YES && (
          <div key={'fallenYes'} className="infinite-item">
            <SectionField htmlFor={MOBILITY_FIELDS.FALLEN_HOW_MANY.NAME} title={MOBILITY_FIELDS.FALLEN_HOW_MANY.TITLE}>
              <FreeTextField
                inputKey={healthAssessment.fallenHowMany}
                inputName="fallenHowMany"
                inputType="number"
                defaultValue={healthAssessment.fallenHowMany}
                onBlur={(e: React.ChangeEvent<HTMLInputElement>): void => {
                  updateField('fallenHowMany', e.target.value);
                }}
              />
            </SectionField>
            <SectionField htmlFor={MOBILITY_FIELDS.FALLEN_INJURIES.NAME} title={MOBILITY_FIELDS.FALLEN_INJURIES.TITLE}>
              <FreeTextArea
                inputName={MOBILITY_FIELDS.FALLEN_INJURIES.NAME}
                defaultValue={healthAssessment.fallenInjuries}
                onBlur={(e: React.ChangeEvent<HTMLTextAreaElement>): void => {
                  updateField('fallenInjuries', e.target.value);
                }}
              />
            </SectionField>
          </div>
        )}
        <SectionField htmlFor={MOBILITY_FIELDS.FOOT_PAIN.NAME} title={MOBILITY_FIELDS.FOOT_PAIN.TITLE}>
          <SegmentedInput
            fieldName={MOBILITY_FIELDS.FOOT_PAIN.NAME}
            options={SegementedInputYesNoUnsure}
            defaultSelected={healthAssessment.footPain}
            itemSelected={(selectedItem): void => {
              const selectedValue = (selectedItem as ListData).id;
              updateField('footPain', selectedValue);
            }}
          />
        </SectionField>
        <SectionField htmlFor={MOBILITY_FIELDS.UNSTEADY.NAME} title={MOBILITY_FIELDS.UNSTEADY.TITLE}>
          <SegmentedInput
            fieldName={MOBILITY_FIELDS.UNSTEADY.NAME}
            options={SegementedInputYesNoUnsure}
            defaultSelected={healthAssessment.unsteady}
            itemSelected={(selectedItem): void => {
              const selectedValue = (selectedItem as ListData).id;
              updateField('unsteady', selectedValue);
            }}
          />
        </SectionField>
        <SectionField htmlFor={MOBILITY_FIELDS.LIGHTHEADED.NAME} title={MOBILITY_FIELDS.LIGHTHEADED.TITLE}>
          <SegmentedInput
            fieldName={MOBILITY_FIELDS.LIGHTHEADED.NAME}
            options={SegementedInputYesNoUnsure}
            defaultSelected={healthAssessment.lightheaded}
            itemSelected={(selectedItem): void => {
              const selectedValue = (selectedItem as ListData).id;
              updateField('lightheaded', selectedValue);
            }}
          />
        </SectionField>
      </div>
    </>
  );
};

export default HAMobility;
