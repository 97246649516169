import { SelectField, TextAreaField } from 'shared-components/components/FormikComponents';

export const FIELDS = {
  FORM_HEADING: {
    NAME: 'formHeading',
    TITLE: 'Your feedback',
  },
  HEARD_ABOUT_US: {
    NAME: 'heardAboutUs',
    TITLE: 'How did you hear about us?',
    PLACE_HOLDER: 'Please select',
    COMPONENT: SelectField,
  },
  HEARD_ABOUT_US_OTHER: {
    NAME: 'heardAboutUsOther',
    TITLE: 'Please specify',
    PLACE_HOLDER: 'Please specify',
    COMPONENT: TextAreaField,
  },
  INTERNET_FOR_RESEARCH: {
    NAME: 'internetForResearch',
    TITLE: 'When conducting your own research, how often do you use the internet for gathering information?',
    PLACE_HOLDER: 'Please select',
    COMPONENT: SelectField,
  },
  AGREE_TO_SURVEY: {
    NAME: 'agreeToSurvey',
    TITLE:
      'By checking yes, you agree that you understand these risks and to receive surveys and/or requests for online reviews through standard unsecure (unencrypted) email, and/or mobile text messaging.',
    COMPONENT: null,
  },
};

export const EXPERIENCE_TEXT =
  'At GenesisCare, we know you have a choice in where you receive your medical care and we thank you for choosing GenesisCare.  We would like to invite you to share your experience by completing surveys and/or online reviews. Sharing this information can help others who are interested in knowing more about the patient services provided by GenesisCare and can help promote our mission of providing high-quality, patient-centered care.  Surveys and/or online review requests may  be sent to you via US mail, email, mobile text messaging, and/or telephone calls. Communication platforms using standard email/mobile text messaging may not utilize encryption, which can place your information at risk of being read or accessed by an unintended third party.';

export const WILLING_TEXT =
  'If you are willing to allow GenesisCare to share your online review or testimonial, please let us know so we can get your written permission.';
