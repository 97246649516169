import ToggleButtons, { Props as ToggleButtonProps } from './ToggleButtons';
import FormRow, { Props as FormRowProps } from '../FormRow/FormRow';
import React from 'react';

interface Props extends ToggleButtonProps, FormRowProps {
  id: string;
  fieldlabel?: string;
  required?: boolean;
  alwaysShowHelper?: boolean; // Forces helper text to show (when it is not for info/warning/error state)
}

/*
  Adds a field label, validation, helper text logic and other nice things to the
  BaseTextField to make this suitable for use in forms. Generally use this TextField
  unless you don't need a field label.
*/
const ROToggleButtons = (props: Props): JSX.Element => {
  const { fullWidth, error, warning, info, fieldlabel, id, required, sx, tooltip } = props;
  const color = error ? 'error' : warning ? 'warning' : info ? 'info' : 'primary';

  return (
    <FormRow {...{ fieldlabel, id, required, tooltip }}>
      <ToggleButtons
        {...props}
        fullWidth={fullWidth === undefined ? true : fullWidth}
        color={color}
        sx={{ ...{ width: '400px', maxWidth: '400px' }, ...sx }}
      />
    </FormRow>
  );
};

export default ROToggleButtons;
