import { gql } from '@apollo/client';

export const SUBMIT_FEEDBACK_QUERY = gql`
  mutation CreateFeedback($page: String!, $message: String!) {
    createFeedback(page: $page, message: $message) {
      feedback {
        id
      }
    }
  }
`;
