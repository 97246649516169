import React from 'react';
import { styled } from '@mui/system';
import { Grid, GridProps } from '@mui/material';

export const PageHeader = styled('div')`
  margin-bottom: 16px;
  h1 {
    font-weight: 500;
    font-size: 24px;
    line-height: 28px;
    color: ${(props) => props.theme.palette.text.primary};
  }
  h2 {
    margin-top: 8px;
  }
  h3 {
    font-size: 20px;
    line-height: 28px;
    color: ${(props) => props.theme.palette.grey[600]};
    font-weight: 100;
  }
`;
export const SpaceBetweenDiv = styled('div')`
  display: flex;
  align-items: center;
  justify-content: space-between;
  font-size: 20px;
  line-height: 28px;
`;

export const ViewWidthMinusSidebars = styled('div')`
  width: calc(100vw - 520px);
`;

export const SideBar = styled('div')`
  border-left: 1px solid ${(props) => props.theme.palette.grey[300]};
  width: 250px;
  height: 100%;
  padding: 0px 10px;
`;

export const AlwaysHorizontalScroll = styled('div')`
  width: 100%;
  position: relative;
  overflow-x: scroll;
  display: flex;
  margin-bottom: -8px;

  ::-webkit-scrollbar {
    -webkit-appearance: none;
    width: 3px;
    height: 8px;
  }

  ::-webkit-scrollbar-thumb {
    border-radius: 6px;
    background-color: ${(props) => props.theme.palette.grey[300]};
    box-shadow: 0 0 1px rgba(255, 255, 255, 0.5);
  }
`;

export const GreyBox = styled('div')`
  display: flex;
  background-color: ${(props) => props.theme.palette.grey[100]};
  padding: 8px 16px;
  margin-top: 8px;
  flex-direction: column;
`;

export const StyledCycleTable = styled('table')`
  display: block;
  overflow-y: auto;
  overflow-x: auto;
  max-height: calc(100vh - 457px);

  &::-webkit-scrollbar {
    width: 0px;
    height: 0px;
    background: transparent; /* make scrollbar transparent */
  }

  & thead tr {
    height: 40px;
  }

  & tbody tr {
    height: 48px;
  }

  & thead tr th {
    text-align: center;
    border-left: 1px solid ${(props) => props.theme.palette.grey[300]};
    border-bottom: 1px solid ${(props) => props.theme.palette.grey[300]};
    border-right: 1px solid ${(props) => props.theme.palette.grey[300]};
    background-color: white;
    vertical-align: middle;
    cursor: pointer;
    &:hover {
      background-color: ${(props) => props.theme.palette.grey[100]};
    }
    position: sticky;
    top: 0;
    z-index: 1;
  }

  & tbody tr td {
    background-color: white;
    border: 1px solid ${(props) => props.theme.palette.grey[300]};
    vertical-align: middle;
  }

  & td.currentDate,
  th.currentDate {
    border-left: 3px solid ${(props) => props.theme.palette.primary.main};
    background-color: ${(props) => props.theme.palette.primary.light};
  }

  & thead tr th.empty {
    background-color: ${(props) => props.theme.palette.grey[300]};
    color: ${(props) => props.theme.palette.grey[600]};
    cursor: default;
  }

  & tbody tr td.empty {
    background-color: ${(props) => props.theme.palette.grey[300]};
  }

  & thead tr th:first-child {
    cursor: inherit;
    border-left: 0;
    position: -webkit-sticky;
    position: sticky;
    left: 0;
    z-index: 2;
    text-align: left;
    border: 0;
    border-right: 1px solid ${(props) => props.theme.palette.grey[300]};
    background-color: ${(props) => props.theme.palette.grey[100]};
  }

  & tbody tr td:first-child {
    left: 0;
    border-left: 0;
    position: -webkit-sticky;
    position: sticky;
  }
`;

export const DayLabel = styled('p')`
  color: ${(props) => props.theme.palette.grey[600]};
  font-size: 0.7rem;
`;

const TableContentContainer = styled('div')`
  display: flex;
  align-items: center;
  padding: 8px;
`;

export const DrugNameContainer = styled(TableContentContainer)`
  width: 550px;
  justify-content: space-between;
`;

export const DoseCellContainer = styled(TableContentContainer)`
  justify-content: center;
`;

export const HeaderContainer = styled('div')`
  display: flex;
  align-items: center;
  padding: 8px;
  flex-direction: column;
`;

export const FirstHeaderContainer = styled(TableContentContainer)`
  flex-direction: column;
  font-size: 16px;
  padding-left: 0;
`;

export const EmptyContainer = styled(TableContentContainer)`
  width: 60px;
  flex-direction: column;
  font-size: 0.6rem;
`;

export const CardLabel = styled((props: GridProps) => <Grid item xs={8} {...props} />)`
  font-weight: 700;
  color: ${(props) => props.theme.palette.grey[600]};
`;

export const CardData = styled((props: GridProps) => <Grid item xs={4} {...props} />)`
  font-weight: 700;
`;

export const ScrollbarDiv = styled('div')`
  &::-webkit-scrollbar {
    width: 8px;
    height: 8px;
  }
  &::-webkit-scrollbar-track {
    all: auto !important;
    background-color: ${(props) => props.theme.palette.grey[100]};
  }
  &::-webkit-scrollbar-thumb {
    all: auto !important;
    background-color: ${(props) => props.theme.palette.grey[300]};
    border-radius: 8px;
  }
  &::-webkit-scrollbar-corner {
    background-color: ${(props) => props.theme.palette.grey[100]};
  }
`;
