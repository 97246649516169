import SimulationBolusFormV1, { validationV1 } from './SimulationBolusFormV1';
import SimulationBolusFormV2, { validationV2 } from './SimulationBolusFormV2';

export interface BolusFormProps {
  version: number;
  options: any;
  updateSimulation: (v: any) => void;
  fieldsId: string;
  SIM_NAMES: any;
  siteGroup: any;
  listValuesLookup: (listValues: any, idToMatch: string, defaultValue: any) => string;
  bolusLocationValues: any;
  setTextFields: (name: string, id: string, values: any, newValue: string, reset?: boolean) => void;
  isFieldValid: (id: string) => boolean;
  bolusThicknessCustomValues: any;
}

export const getBolusValidationStructure = (version: number) => {
  if (version >= 2) {
    return validationV2;
  } else if (version >= 1) {
    return validationV1;
  }
  return [];
};

const BolusForm = (props: BolusFormProps) => {
  if (props.version >= 2) {
    return <SimulationBolusFormV2 {...props} />;
  } else if (props.version >= 1) {
    return <SimulationBolusFormV1 {...props} />;
  }
  return <></>;
};

export default BolusForm;
