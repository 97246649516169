import { gql } from '@apollo/client';

export const PRACTITIONER_SEARCH_QUERY = gql`
  query PractitionerSearch($searchTerm: String!) {
    searchPractitionersFuzzy(search: $searchTerm) {
      id
      prefix
      firstName
      lastName
      location
      providerNumber
      isInMosaiq
    }
    user {
      id
    }
  }
`;

export const GP_PRACTITIONER_SELECTED = gql`
  mutation UpdatePatient($id: ID!, $gpId: Int!) {
    updatePatient(id: $id, gpId: $gpId) {
      patient {
        id
        gpFirstName
        gpLastName
        gpPractice
        gpIsInMosaiq
      }
    }
  }
`;

export const SPECIALIST_SELECTED = gql`
  mutation UpdatePatient($id: ID!, $referringId: Int!) {
    updatePatient(id: $id, referringId: $referringId) {
      patient {
        id
        referringFirstName
        referringLastName
        referringPractice
        referringIsInMosaiq
      }
    }
  }
`;

export const UPDATE_REGISTRATION_REFERRER = gql`
  mutation UpdatePatientAuReferrer(
    $id: ID!
    $referringFirstName: String
    $referringLastName: String
    $referringPractice: String
    $referringId: String
    $gpFirstName: String
    $gpLastName: String
    $gpPractice: String
    $gpId: String
    $referrersOtherSpecialists: String
  ) {
    updatePatientAuReferrer(
      id: $id
      referringFirstName: $referringFirstName
      referringLastName: $referringLastName
      referringPractice: $referringPractice
      referringId: $referringId
      gpFirstName: $gpFirstName
      gpLastName: $gpLastName
      gpPractice: $gpPractice
      gpId: $gpId
      referrersOtherSpecialists: $referrersOtherSpecialists
    ) {
      patient {
        id
        referringFirstName
        referringLastName
        referringPractice
        referringIsInMosaiq
        gpFirstName
        gpLastName
        gpPractice
        gpIsInMosaiq
        referrersOtherSpecialists
      }
    }
  }
`;
