import React from 'react';
import { styled } from '@mui/system';

export interface Props {
  selected: boolean;
}

const OuterCircle = styled('div')`
  flex-shrink: 0;
  background: white;
  border: 1px solid silver;
  border-radius: 50%;
  height: 20px;
  width: 20px;
  margin: 8px;
  padding: 4px;
`;

const InnerCircle = styled('div')<Props>`
    border-radius: 50%;
    height: 100%;
    width: 100%;
    background-color: ${(props) => (props.selected ? props.theme.palette.primary.main : 'white')};
  }
`;

const FakeRadioButton = (props: Props): JSX.Element => (
  <OuterCircle>
    <InnerCircle {...props}></InnerCircle>
  </OuterCircle>
);

export default FakeRadioButton;
