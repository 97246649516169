import React, { ReactNode, useRef } from 'react';
import { useOverflow } from 'shared-components/utils/CustomHooks';

interface CareplanContainerProps {
  children: ReactNode;
  className?: string;
}

const CareplanContainer = ({ children, className }: CareplanContainerProps): JSX.Element => {
  const container = useRef<HTMLDivElement>(null);
  const { refYOverflowing, refYScrollEnd } = useOverflow(container);
  const hasShadow = refYOverflowing && !refYScrollEnd;

  return (
    <div
      ref={container}
      className={`main-container create-careplan-page ${className ? className : ''} ${
        hasShadow ? 'create-careplan-page--scroll' : ''
      }`}>
      {children}
    </div>
  );
};

export default CareplanContainer;
