// eslint-disable-next-line no-use-before-define
import React, { Component } from 'react';

import { HeaderBar } from 'op-components';

import './LinkExpired.scss';

const CONTENT = {
  TITLE: 'Link expired',
  BODY: 'You have either submitted your online registration or the link has expired. Please contact your local centre for assistance. You can find your centre details at',
};

// eslint-disable-next-line
interface Props {}
class LinkExpired extends Component<Props> {
  public render(): JSX.Element {
    return (
      <div id="session-expired-page">
        <HeaderBar headingText="Patient registration" defaultHeader />
        <div className="session-container">
          <div id="session-expired-image">
            <div className="image"></div>
          </div>
          <main>
            <h1 id="session-title">{CONTENT.TITLE}</h1>
            <p id="session-detail">{CONTENT.BODY}</p>
            <a href="https://www.genesiscare.com/au/our-centres/" target="_blank">
              https://www.genesiscare.com/au/our-centres/
            </a>
          </main>
        </div>
        <div className="end-home-bottom-navigator"></div>
      </div>
    );
  }
}
export default LinkExpired;
