import React, { ReactElement } from 'react';
import { Warning } from 'shared-components/images';
import { Box, Tooltip } from '@mui/material';
import { styled } from '@mui/system';

export const WarningIcon = styled(Warning)`
  width: 20px;
  height: 20px;
  margin-right: 5px;
  margin-left: 3px;
`;

interface Props {
  message: string;
  disabled?: boolean;
  children: ReactElement;
}

const TooltipWarning = ({ message, children, disabled }: Props): JSX.Element => (
  <Tooltip
    arrow
    disableFocusListener={disabled}
    disableHoverListener={disabled}
    disableTouchListener={disabled}
    title={
      <React.Fragment>
        <Box style={{ textAlign: 'center', fontSize: '14px' }}>
          <WarningIcon />
          {message}
        </Box>
      </React.Fragment>
    }>
    {children}
  </Tooltip>
);

export default TooltipWarning;
