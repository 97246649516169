// eslint-disable-next-line no-use-before-define
import React from 'react';
import { Stack, Typography } from '@mui/material';
import { CPOT_MODAL } from './constants';
import { StandardDialog } from 'shared-components/components';

interface Props {
  isOpen: boolean;
  dismissFunction: () => void;
  submitFunction: () => void;
}

const ModalCpotCancel = (props: Props): JSX.Element => {
  const { isOpen, dismissFunction, submitFunction } = props;

  return (
    <StandardDialog
      title="Quit change prescription on treatment"
      open={isOpen}
      onClose={dismissFunction}
      onSubmit={() => {
        submitFunction();
        dismissFunction();
      }}>
      <Typography paddingY={2}>
        All changes will be cleared and revert back to the last submitted prescription. Are you sure you would like to
        quit change prescription on treatment?
      </Typography>
    </StandardDialog>
  );
};

export default ModalCpotCancel;
