import React, { createContext, useState } from 'react';

export const FileContext = createContext<any>({});

export const FileContextProvider = ({ children }: any): JSX.Element => {
  const [activeFiles, setActiveFiles] = useState<any>([]);
  return (
    <FileContext.Provider
      value={{
        activeFiles,
        setActiveFiles,
      }}>
      {children}
    </FileContext.Provider>
  );
};
