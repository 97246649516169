// eslint-disable-next-line no-use-before-define
import { useMutation, useQuery } from '@apollo/client';
import React from 'react';
import { useParams } from 'react-router-dom';
import { registrationPath } from '../Helper';
import InformationNoticeForm from './InformationNoticeForm';
import { REGISTRATION_INFORMATION_NOTICE_QUERY, UPDATE_REGISTRATION } from './InformationNoticeQueries';

interface Props {
  exitForm: (status: boolean) => void;
}

const InformationNotice = (props: Props): JSX.Element => {
  const { exitForm } = props;
  const { patientId } = useParams<{ patientId: string }>();
  const [updateRegistration] = useMutation(UPDATE_REGISTRATION);
  const { data, error } = useQuery(REGISTRATION_INFORMATION_NOTICE_QUERY, {
    variables: { id: patientId },
  });

  if (error || !data) return <></>;
  const { patient, informationNoticeContent, user } = data;

  const updateField = (field: string, value: string | boolean | number) => {
    updateRegistration({
      variables: {
        patientId,
        [field]: value,
      },
    });
  };

  return (
    <InformationNoticeForm
      updateField={updateField}
      patient={patient}
      informationNoticeContent={informationNoticeContent}
      handleShowSaveExitConfirm={() => exitForm(true)}
      previousPageLink={registrationPath(patient.id, 'demographics')}
      isPso={user.isPso}
    />
  );
};

export default InformationNotice;
