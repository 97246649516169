// eslint-disable-next-line no-use-before-define
import React, { Component, Fragment } from 'react';

import './ErrorInfo.scss';

import { ErrorCross } from 'shared-components/images';
import classnames from 'classnames';

interface Props {
  errors?: string[];
  type?: string;
  link?: JSX.Element;
  errorMessages?: { [key: string]: string };
}

class ErrorInfo extends Component<Props> {
  public render(): JSX.Element {
    const { errors, errorMessages, type, link } = this.props;

    if (!errors) {
      return <Fragment />;
    }

    const errorComponent: JSX.Element[] = errors.map((error: string): JSX.Element => {
      let errorMessage = error;
      if (!errorMessage) {
        return <></>;
      }
      if (errorMessages) {
        errorMessage = errorMessages[error];
      }

      return (
        <div
          className={classnames(
            'error-state',
            { 'error-state-table-uk': type === 'table-error' },
            { 'error-state-doc-upload': type === 'document-upload-table' },
          )}
          key={errorMessage}>
          <ErrorCross className="icon" />
          <div className="error-message">
            {errorMessage}
            {link !== undefined && <p>{link}</p>}
          </div>
        </div>
      );
    });
    return <Fragment>{errorComponent}</Fragment>;
  }
}

export default ErrorInfo;
