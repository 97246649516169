// eslint-disable-next-line no-use-before-define
import React, { Component, Fragment, useEffect } from 'react';
import { gql } from '@apollo/client';
import { RouteComponentProps, withRouter } from 'react-router-dom';
import { WithApolloClient, withApollo } from '@apollo/client/react/hoc';
import { Query } from '@apollo/client/react/components';

import withDistressThermometer, { WithDistressThermometer } from '../../DistressThermometer';

import DTQuestionContent from './DTQuestionContent';

import { DT_CATEGORY } from 'op-enums';
import { DTAnswerItem } from 'op-classes';

import { Logger } from 'shared-components/utils';
import { LoadingSpinner } from 'shared-components/components';
import { useErrorModalContext } from 'op-contexts';

const logger = new Logger('DTQuestionContentApollo');

const CATEGORY_TYPE: { [key: string]: string } = {
  physical: 'Physical',
  emotional: 'Emotional',
  social: 'Social',
  practical: 'Practical',
  spiritual: 'Spiritual/Religious',
};

interface DTQuestionQueryData {
  distressThermometer: {
    [key: string]: string | DTAnswerItem[] | undefined;
    id: string;
    answers: DTAnswerItem[];
  };
}

interface Props
  extends WithApolloClient<{}>,
    WithDistressThermometer,
    RouteComponentProps<{ patientId: string; distressId: string; ratingPage: string }> {}

class DTQuestionContentApollo extends Component<Props> {
  public render(): JSX.Element {
    const {
      match: {
        params: { patientId, distressId, ratingPage },
      },
    } = this.props;

    // Based on the DT page we're on, we query different set of answers/questions
    // Convert the url param into a DT category
    let category = DT_CATEGORY.NONE;
    for (const categoryEnum in DT_CATEGORY) {
      //@ts-ignore
      if (DT_CATEGORY[categoryEnum] === ratingPage) {
        //@ts-ignore
        // This category is used as the variable for our content render
        category = DT_CATEGORY[categoryEnum];
      }
    }
    return (
      <Fragment>
        <Query<DTQuestionQueryData> query={this.createQueryGQL()} variables={{ patientId, id: distressId }}>
          {({ loading, data, error }): JSX.Element => {
            const { setError } = useErrorModalContext();
            useEffect(() => {
              if (error) return setError();
            }, [error]);
            if (loading) return <LoadingSpinner relativeSpinner={true} />;

            if (data && data.distressThermometer) {
              return (
                <DTQuestionContent
                  distressId={distressId}
                  category={category}
                  answers={data.distressThermometer.answers}
                  autosave={this.autosave}
                  patientId={patientId}
                />
              );
            }

            return <div></div>;
          }}
        </Query>
      </Fragment>
    );
  }
  /**
   * This function will generate the graph ql query that is required for fetching the question data that will be displayed to the patient.
   * This is used instead of static gql export is because the query will need to include various variables for caching purposes.
   * @returns {any} The graph ql tag that will be used to fetch the data.
   */
  //@ts-ignore
  private createQueryGQL = (): any => {
    const {
      match: {
        params: { ratingPage },
      },
    } = this.props;

    const gqlString = `query DTQuestions($patientId: ID!, $id: ID!) {
      distressThermometer(patientId: $patientId, id: $id) {
        id
        answers(category:"${CATEGORY_TYPE[ratingPage]}") {
          id
          question
          value
        }
      }
    }`;

    logger.debug('createQueryGQL', 'The query string is: ', gqlString);

    return gql(gqlString);
  };

  private autosave = (updateObject: object, replaceId?: string): void => {
    const { client, getDTMutation } = this.props;
    if (!getDTMutation) throw new Error('getDTMutation is not defined');
    // Call the client to mutate the distress thermometer
    //@ts-ignore
    client?.mutate(getDTMutation(updateObject, replaceId)).catch(() => {
      logger.debug('autosave', '**** FAILED TO SAVE ****', JSON.stringify(updateObject));
    });
  };
}

const apolloComponent = withApollo<Props>(DTQuestionContentApollo);
const routedComponent = withRouter(apolloComponent);
const dtComponent = withDistressThermometer(routedComponent);
export default dtComponent;
