// eslint-disable-next-line no-use-before-define
import React, { Component } from 'react';
import { MandatoryPatient } from 'op-interfaces/PatientInterfaces';
import { gql } from '@apollo/client';
import { WithApolloClient, withApollo } from '@apollo/client/react/hoc';
import { RouteComponentProps, withRouter } from 'react-router';
import { ARCHIVE } from './constants';
import { StandardDialog } from 'shared-components/components';
import { Typography, Stack, Button } from '@mui/material';

interface Props extends WithApolloClient<{}>, RouteComponentProps {
  patient: MandatoryPatient;
  redirectPath: string;
}

interface State {
  isOpen: boolean;
}

const mutation = gql`
  mutation archivePatient($patientId: ID!) {
    archivePatient(pk: $patientId) {
      ok
    }
  }
`;

class ArchiveButton extends Component<Props, State> {
  public constructor(props: Props) {
    super(props);

    this.state = {
      isOpen: false,
    };
  }

  private toggleDialog = (): void => {
    this.setState({
      isOpen: !this.state.isOpen,
    });
  };

  private archivePatient = (): void => {
    const { patient, client, history, redirectPath } = this.props;
    // @ts-ignore
    client
      .mutate({
        mutation,
        variables: {
          patientId: patient.id,
        },
      })
      .then(() => {
        history.push(redirectPath);
      })
      .catch(() => {
        history.push(redirectPath);
      });
  };

  public render(): JSX.Element {
    const { patient } = this.props;
    const { isOpen } = this.state;

    let confirmationMessage = ARCHIVE.CONFIRMATION.NO_NAME;
    if (patient.firstName || patient.lastName) {
      confirmationMessage = `${ARCHIVE.CONFIRMATION.NAME_PRE}${patient.firstName} ${patient.lastName}${ARCHIVE.CONFIRMATION.NAME_POST}`;
    }
    const disabled = !!patient.ida;

    return (
      <>
        <Stack paddingLeft={1}>
          <Button
            size="large"
            color={disabled ? 'primary' : 'error'}
            onClick={(): void => {
              this.toggleDialog();
            }}
            disabled={disabled}>
            Delete patient
          </Button>
        </Stack>
        <StandardDialog
          open={isOpen}
          onClose={this.toggleDialog}
          title={ARCHIVE.MODAL.HEADER}
          maxWidth="sm"
          submitText="Delete patient"
          onSubmit={this.archivePatient}
          destructiveSubmit>
          <Typography color="error">{confirmationMessage}</Typography>
        </StandardDialog>
      </>
    );
  }
}
// @ts-ignore
export default withRouter(withApollo(ArchiveButton));
