import React, { Fragment } from 'react';
import { styled } from '@mui/system';
import { GCTextLogo, HorizonGreenLogo } from 'shared-components/images';

interface Props {
  patientFacing?: boolean;
  headingText?: string | null;
}

const HeaderContainer = styled('div')`
  display: flex;
  justify-content: space-between;
  padding: 0px 15px 0px 15px;
  background-color: white;
  height: 56px;
  position: sticky;
  z-index: 50;
  box-shadow: 0px 4px 8px rgba(113, 110, 106, 0.25);
  align-items: center;
`;

const StyledGCLogo = styled(GCTextLogo)`
  height: 28px;
`;

const StyledHeadingText = styled('div')`
  color: black;
  font-size: 23px;
  font-weight: bold;
`;

const DefaultHeader = ({ patientFacing, headingText }: Props): JSX.Element => {
  return (
    <Fragment>
      <HeaderContainer>
        {patientFacing ? <StyledGCLogo id="gc-logo-patient-facing" /> : <HorizonGreenLogo />}
        {headingText && <StyledHeadingText>{headingText}</StyledHeadingText>}
      </HeaderContainer>
    </Fragment>
  );
};

export default DefaultHeader;
