// eslint-disable-next-line no-use-before-define
import React, { Component, Fragment } from 'react';

import validate from 'validate.js';

import './HAOtherMedicalProblems.scss';

import { StringDictionary } from 'shared-components/interfaces';
import { SegmentedInputBoolean } from 'shared-components/utils';

import { HAOtherMedicalProblemsItem } from 'op-classes';
import { PageTitle } from 'op-components';
import { fetchRules, ValidationKeys } from 'op-utils/HealthAssessmentValidation/HealthAssessmentValidation';
import { FreeTextArea, SectionField, SegmentedInput } from 'shared-components/components/FormFields';

const FIELD_NAMES: StringDictionary = {
  OTHER_MEDICAL_PROBLEMS_TOGGLE: 'otherMedicalProblemsBool',
  OTHER_MEDICAL_PROBLEMS_TEXT: 'otherMedicalProblemsInput',
  ALT_MEDICINE_PROBLEMS_TOGGLE: 'alternativeMedBool',
  ALT_MEDICINE_PROBLEMS_TEXT: 'alternativeMedInput',
};

const FIELD_VALIDATION_KEYS: StringDictionary = {
  MEDICAL_PROBLEMS_BOOL: 'otherMedProblemBool',
  MEDICAL_PROBLEMS_DESCRIPTION: 'otherMedProblemDescription',
  ALT_MEDICINE_BOOL: 'altMedicineBool',
  ALT_MEDICINE_DESCRIPTION: 'altMedicineDescription',
};

interface State {
  viewedFields: Set<string>;
}

interface Props {
  autosave: (updateObject: object) => Promise<void>;
  patientId: string;
  haPatientInfo: HAOtherMedicalProblemsItem;
  validateOnLoad: boolean;
}

class HAOtherMedicalProblems extends Component<Props, State> {
  public constructor(props: Props) {
    super(props);

    this.state = {
      viewedFields: new Set(),
    };
  }

  public static getDerivedStateFromProps(props: Props, state: State): State {
    if (props.validateOnLoad) {
      const fields = Object.keys(FIELD_VALIDATION_KEYS).map((keyName: string) => {
        return FIELD_VALIDATION_KEYS[keyName];
      });
      const viewed = new Set(fields);
      return { viewedFields: viewed };
    }

    return state;
  }

  public render(): JSX.Element {
    const { haPatientInfo } = this.props;

    return (
      <Fragment>
        <PageTitle title={'Other medical problems'} idPrefix="ha" />
        <div id="ha-fields">
          <SectionField
            title={'Do you have any other medical problems?'}
            htmlFor={FIELD_NAMES.OTHER_MEDICAL_PROBLEMS_TOGGLE}>
            <SegmentedInput
              options={SegmentedInputBoolean}
              fieldName={FIELD_NAMES.OTHER_MEDICAL_PROBLEMS_TOGGLE}
              optionAreBoolean={true}
              defaultSelected={haPatientInfo.otherMedProblemBool}
              itemSelected={(selectedItem): void => {
                const selectedValue = selectedItem as boolean;
                this.autoSaveAndValidate(
                  {
                    id: parseInt(this.props.patientId),
                    otherMedProblemBool: selectedValue,
                  },
                  FIELD_VALIDATION_KEYS.MEDICAL_PROBLEMS_BOOL,
                );
              }}
            />
          </SectionField>
          {this.renderOtherMedicalProblemsDescription()}
          <SectionField
            title={'Do you use complementary or alternative medicine?'}
            htmlFor={FIELD_NAMES.ALT_MEDICINE_PROBLEMS_TOGGLE}>
            <SegmentedInput
              options={SegmentedInputBoolean}
              fieldName={FIELD_NAMES.ALT_MEDICINE_PROBLEMS_TOGGLE}
              optionAreBoolean={true}
              defaultSelected={haPatientInfo.altMedicineBool}
              itemSelected={(selectedItem): void => {
                const selectedValue = selectedItem as boolean;
                this.autoSaveAndValidate(
                  {
                    id: parseInt(this.props.patientId),
                    altMedicineBool: selectedValue,
                  },
                  FIELD_VALIDATION_KEYS.MEDICAL_PROBLEMS_BOOL,
                );
              }}
            />
          </SectionField>
          {this.renderAltMedicineDescription()}
        </div>
      </Fragment>
    );
  }

  public renderOtherMedicalProblemsDescription(): JSX.Element {
    const { haPatientInfo } = this.props;

    if (haPatientInfo.otherMedProblemBool) {
      return (
        <SectionField title={'Other medical problems description'} htmlFor={FIELD_NAMES.OTHER_MEDICAL_PROBLEMS_TEXT}>
          <FreeTextArea
            inputName={FIELD_NAMES.OTHER_MEDICAL_PROBLEMS_TEXT}
            defaultValue={haPatientInfo.otherMedProblemDescription}
            placeholder=""
            onBlur={(e): void => {
              this.autoSaveAndValidate(
                {
                  id: parseInt(this.props.patientId),
                  otherMedProblemDescription: e.target.value,
                },
                FIELD_VALIDATION_KEYS.MEDICAL_PROBLEMS_DESCRIPTION,
              );
            }}
          />
        </SectionField>
      );
    } else {
      return <Fragment></Fragment>;
    }
  }

  public renderAltMedicineDescription(): JSX.Element {
    const { haPatientInfo } = this.props;

    if (haPatientInfo.altMedicineBool) {
      return (
        <SectionField title={'Alternative medicine description'} htmlFor={FIELD_NAMES.ALT_MEDICINE_PROBLEMS_TEXT}>
          <FreeTextArea
            inputName={FIELD_NAMES.ALT_MEDICINE_PROBLEMS_TEXT}
            defaultValue={haPatientInfo.altMedicineDescription}
            placeholder=""
            onBlur={(e): void => {
              this.autoSaveAndValidate(
                {
                  id: parseInt(this.props.patientId),
                  altMedicineDescription: e.target.value,
                },
                FIELD_VALIDATION_KEYS.ALT_MEDICINE_DESCRIPTION,
              );
            }}
          />
        </SectionField>
      );
    } else {
      return <Fragment></Fragment>;
    }
  }

  private validateObject = (haPatientInfo: HAOtherMedicalProblemsItem): any => {
    const validationRules = fetchRules(ValidationKeys.OtherMedicalProblems, this.state.viewedFields);

    const options = { fullMessages: false };

    return validate(haPatientInfo, validationRules, options);
  };

  private autoSaveAndValidate = async (updateObject: object, validationKey: string): Promise<void> => {
    const { autosave } = this.props;

    //Save the data

    await autosave(updateObject).then(() => {
      //Get viewed fields
      const viewedFields = [...this.state.viewedFields];

      //Add updated field
      viewedFields.push(validationKey);

      //Update with new validation set
      this.setState({ viewedFields: new Set(viewedFields) });
    });
  };
}

//@ts-ignore
export default HAOtherMedicalProblems;
