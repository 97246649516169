import { RouteComponentProps, withRouter } from 'react-router-dom';
import { Tab, Tabs } from '@mui/material';

interface Props extends RouteComponentProps {
  pageShown: string;
  showSmsLogHeader: boolean;
}

const ReviewFormsHeader = (props: Props): JSX.Element => {
  const { pageShown, history, showSmsLogHeader } = props;

  const tabObject = [
    {
      name: 'search',
      text: 'Patient Search',
    },
    {
      name: 'review-forms',
      text: 'Review Forms',
    },
  ];

  return (
    <Tabs
      value={pageShown}
      onChange={(_event, selected) => {
        history.push(selected);
      }}>
      {tabObject.map((tab): JSX.Element => {
        return <Tab data-test-id={tab.name} key={tab.name} value={`/${tab.name}`} label={tab.text} />;
      })}
    </Tabs>
  );
};

export default withRouter(ReviewFormsHeader);
