import Client, { AutocompleteSuccess } from 'getaddress-api';

const apiKey = import.meta.env.UK_POSTCODE_API_KEY;
const api = new Client(apiKey);

export const fetchAddresses = async (postcode: string): Promise<AutocompleteSuccess | undefined> => {
  try {
    const autocompleteResult = await api.autocomplete(postcode, {
      template: '{line_1}|{line_2}|{locality}|{town_or_city}|{county}|{postcode}|{country}',
    });
    if (autocompleteResult.isSuccess) {
      return autocompleteResult.toSuccess();
    } else {
      throw new Error('Postcode lookup failed');
    }
  } catch (error) {
    throw error;
  }
};
