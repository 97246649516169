// eslint-disable-next-line no-use-before-define
import React, { Fragment } from 'react';

import './CircleInfo.scss';

import { Info } from 'shared-components/images';

interface Props {
  messages?: string[];
  infoMessages?: { [key: string]: string };
}

const CircleInfo = (props: Props): JSX.Element => {
  const { messages, infoMessages } = props;

  if (!messages) {
    return <Fragment />;
  }

  const infoComponent: JSX.Element[] = messages.map((info: string): JSX.Element => {
    let infoMessage = info;

    if (infoMessages) {
      infoMessage = infoMessages[info];
    }

    return (
      <div className="circle-state" key={infoMessage}>
        <Info className="icon" />
        <div className="circle-message">{infoMessage}</div>
      </div>
    );
  });
  return <>{infoComponent}</>;
};

export default CircleInfo;
