import React, { Fragment } from 'react';

import { SummaryCard, SummaryRow } from 'op-components';
import { convertKgToPound } from 'op-utils';
import { convertCmToFeetAndInches } from 'op-utils/MeasurementConverters';
import { Edit, Success } from 'shared-components/images';
import { DateTimeConverter } from 'shared-components/utils';

import { HAReviewSummaryUSItem } from 'op-pages/OP/HealthAssessment/HASharedComponents/types';

import './../../HAReviewSummaryUS.scss';
import { Region } from 'shared-components/enums';

interface IPatientInformation {
  isPso: boolean;
  navigate: (link: string) => void;
  healthAssessment: HAReviewSummaryUSItem;
}

const getHeightInFeetAndInches = (height: string) => {
  if (height === '0' || height === '') return '-';

  const convertedHeight = convertCmToFeetAndInches(height);

  return `${convertedHeight.heightFt} ft, ${convertedHeight.heightIn} in`;
};

export const PatientInformation = ({ isPso, navigate, healthAssessment }: IPatientInformation): JSX.Element => {
  const patInfo = healthAssessment.patient;

  return (
    <Fragment>
      <SummaryCard
        id="patient-information"
        title={'Patient information'}
        primaryIcon={isPso ? <></> : <Edit className="primary-icon" />}
        secondaryIcon={<Success className="secondary-icon valid-icon" />}
        showEdit={!isPso}
        onClick={() => {
          if (!isPso) {
            navigate('info');
          }
        }}>
        <SummaryRow
          headingText={'Patient Name'}
          detailText={patInfo.fullName}
          displayAsError={false}
          displayAsOptional={false}
        />
        <SummaryRow
          headingText={'Date of Birth'}
          detailText={DateTimeConverter.getFormattedDateAsDDMonthYYYY(
            patInfo.dob,
            import.meta.env.REACT_APP_REGION as Region,
          )}
          displayAsError={false}
          displayAsOptional={false}
        />
        <SummaryRow
          headingText={'Height'}
          detailText={getHeightInFeetAndInches(healthAssessment.height)}
          displayAsError={false}
          displayAsOptional={false}
        />
        <SummaryRow
          headingText={'Weight'}
          detailText={healthAssessment.weight ? `${convertKgToPound(parseFloat(healthAssessment.weight), 2)} lbs` : '-'}
          displayAsError={false}
          displayAsOptional={false}
        />
      </SummaryCard>
    </Fragment>
  );
};

export default PatientInformation;
