import React from 'react';

import { Attributes } from './types';
import { Tooltip, Typography } from '@mui/material';
import './PatientCard.scss';

interface Props {
  attributes: Attributes;
}

const PatientId = ({ attributes }: Props): JSX.Element => {
  return (
    <div>
      <Typography data-testid="patient-name-block" variant="h6">
        {attributes.fullName}
      </Typography>
      <Tooltip
        enterDelay={5000}
        arrow
        title={
          attributes.emrPatientId && (
            <>
              {' '}
              <div className="header-text">
                EMR ID <b>{attributes.emrPatientId}</b>
              </div>
              <div className="header-text">
                EMR Instance <b>{attributes.emrInstance}</b>
              </div>
            </>
          )
        }>
        <div className="header-text" id="patient-id-block">
          Patient ID: <b>{attributes.ida}</b>
        </div>
      </Tooltip>
    </div>
  );
};

export default PatientId;
