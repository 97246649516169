import { ReferralType } from 'op-enums';
import { PractitionerSearch } from 'op-pages';
import { StandardDialog } from 'shared-components/components';
import { PatientReferrerRego } from 'shared-components/interfaces';
import { Stack } from '@mui/material';

const GP_HEADER_TITLE = 'General practitioner lookup';
const SPECIALIST_HEADER_TITLE = 'Referring specialist lookup';
interface ModalPractitionerSearchIProps {
  userInfo: PatientReferrerRego;
  header?: string;
  isOpen: boolean;
  referralType: ReferralType;
  dismissFunction: (status: boolean) => void;
  makeFieldsEditable: (referral: ReferralType) => void;
  disableFields: (referral: ReferralType) => void;
  logTime?: boolean;
  enableLogging?: boolean;
  popOverOpen?: boolean;
  popOverClose?: () => void;
}

const ModalPractitionerSearch = (props: ModalPractitionerSearchIProps): JSX.Element => {
  const {
    userInfo,
    isOpen,
    referralType,
    dismissFunction,
    makeFieldsEditable,
    disableFields,
    logTime = true,
    enableLogging = false,
    popOverOpen,
    popOverClose,
  } = props;

  const handleCancelModal = () => dismissFunction(true);
  const handleCloseModal = (status: boolean) => dismissFunction(status);

  return (
    <StandardDialog
      open={isOpen}
      onClose={handleCancelModal}
      maxWidth="md"
      fullWidth
      title={referralType === ReferralType.GP ? GP_HEADER_TITLE : SPECIALIST_HEADER_TITLE}>
      <Stack paddingY={2}>
        <PractitionerSearch
          referralType={referralType}
          userInfo={userInfo}
          closeModal={handleCloseModal}
          makeFieldsEditable={makeFieldsEditable}
          disableFields={disableFields}
          logTime={logTime}
          enableLogging={enableLogging}
          popOverOpen={popOverOpen}
          popOverClose={popOverClose}
        />
      </Stack>
    </StandardDialog>
  );
};

export default ModalPractitionerSearch;
