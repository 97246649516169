import { gql } from '@apollo/client';

export const PCCC_ALLERGY_FRAGMENT = gql`
  fragment Allergy on PcccAllergyType {
    id
    allergyType
    additionalDetails
    pcccReaction {
      id
      baseValue
      otherValue
      lastUpdateUser
    }
    lastUpdateUser
  }
`;

export const PCCC_IMPLANTED_DEVICE_FRAGMENT = gql`
  fragment ImplantedDevice on ImplantedMedicalDevicesType {
    id
    name
    deviceField
    assessment {
      id
    }
    lastUpdateUser
  }
`;

export const PCCC_INFECTIOUS_DISEASE_FRAGMENT = gql`
  fragment InfectiousDisease on InfectiousDiseaseType {
    id
    baseValue
    otherValue
    assessment {
      id
    }
    lastUpdateUser
  }
`;

export const PCCC_MRO_INFECTION_FRAGMENT = gql`
  fragment MroInfection on MroInfectionType {
    id
    baseValue
    otherValue
    assessment {
      id
    }
    lastUpdateUser
  }
`;
