import React, { useContext, useState } from 'react';
import { styled } from '@mui/system';

import { CurrentAppConfig } from 'op-pages/RO/Careplan/AppConfig';
import { ROPatientContext } from '../context';
import { getReducedAppointments, ReducedAppointment } from '../PatientDetailsCards/AppointmentsCard';
import { useReferrers } from '../constants';
import CONSTANTS from '../PatientDetailsCards/constants';
import { region } from 'op-components';
import { Region } from 'shared-components/enums';
import moment from 'moment';

import { TabSelectHeader } from 'shared-components/components/Grid';

const { NO_UPCOMING } = CONSTANTS;

const OtherInfoWrapper = styled('div')`
  height: 100%;
  display: flex;
  flex-direction: column;
`;

const ContentContainer = styled('div')`
  overflow: overlay;
  flex-grow: 1;
  min-height: 0;
`;

const TabContainer = styled('div')`
  display: flex;
  flex-direction: column;
  font-size: 16px;
`;

const AppointmentHeader = styled('div')`
  display: flex;
  justify-content: space-between;
  padding: 14px 16px;
  border-radius: 8px;
  background-color: ${(props) => props.theme.palette.grey[100]};
  font-weight: bold;
`;

const AppointmentBody = styled('div')`
  padding: 16px 16px 0;
  overflow: auto;
  font-size: 16px;
`;

const SingleAppointment = styled('div')`
  display: flex;
  min-height: 40px;
  font-size: 16px;
  margin-bottom: 16px;
`;

const DateWrapper = styled('div')`
  width: 30%;
  display: flex;
  align-items: center;
`;

const DescriptionWrapper = styled('div')`
  word-break: break-word;
  padding: 0 8px;
  border-left: 1px solid ${(props) => props.theme.palette.primary.main};
  width: 40%;
  font-weight: bold;
  display: flex;
  align-items: center;
`;

const LocationWrapper = styled(DescriptionWrapper)`
  width: 30%;
  font-weight: normal;
`;

const RowInfoWrapper = styled('div')`
  display: flex;
  margin-bottom: 16px;
`;

const ColumnInfoWrapper = styled('div')<any>`
  width: ${({ $width }): { $width: string } => $width};
  line-height: 24px;
`;

const InfoHeader = styled('div')`
  :after {
    content: '_';
    visibility: hidden;
  }
`;

const InfoSubtitle = styled('div')`
  font-weight: bold;
`;

const StyledHr = styled('hr')`
  width: 100%;
`;
const OtherInformation = (): JSX.Element => {
  const [activeIndex, setActiveIndex] = useState(0);
  const { patientDetails } = useContext(ROPatientContext);

  const appointments = patientDetails?.appointments;

  const allReferrers = patientDetails?.allReferrers || [];
  const { responsiblePhysician, primaryPhysician, referringSurgeon } = useReferrers(allReferrers);

  const medicareNumber = patientDetails?.healthMedicareNumber;
  const medicareIrn = patientDetails?.healthMedicareIrn;
  const medicareMonth = patientDetails?.medicareExpiryRawMonth;
  const medicareYear = patientDetails?.medicareExpiryRawYear;

  // Insurance details
  const insurances = patientDetails?.insurances;

  const emergencyContact = patientDetails?.emergencyContact;

  const reducedAppointments = appointments?.length ? getReducedAppointments(appointments) : null;

  const renderInfoCol = (item: any) => (
    <ColumnInfoWrapper $width={item.width || '50%'}>
      <InfoHeader>{item.header}</InfoHeader>
      <InfoSubtitle>{item.subtitle || '-'}</InfoSubtitle>
    </ColumnInfoWrapper>
  );

  const AppointmentsTab = () =>
    reducedAppointments?.length ? (
      reducedAppointments.map((appt: ReducedAppointment) => (
        <TabContainer>
          <AppointmentHeader>
            <div>{appt.formattedDate}</div>
            <div>{appt.isToday ? 'Today' : appt.isTomorrow ? 'Tomorrow' : null}</div>
          </AppointmentHeader>
          <AppointmentBody>
            {appt.appts.map((item: any) => (
              <SingleAppointment>
                <DateWrapper>{item.displayTime}</DateWrapper>
                <DescriptionWrapper>{item.description || '-'}</DescriptionWrapper>
                <LocationWrapper>{item.location || '-'}</LocationWrapper>
              </SingleAppointment>
            ))}
          </AppointmentBody>
        </TabContainer>
      ))
    ) : (
      <TabContainer>{NO_UPCOMING}</TabContainer>
    );

  const TreatingPhysiciansTab = () => (
    <TabContainer>
      <RowInfoWrapper>
        {renderInfoCol({
          header: CurrentAppConfig.ReferringPhysicians.oncologist,
          subtitle: responsiblePhysician?.practitioner
            ? `${responsiblePhysician.practitioner.firstName} ${responsiblePhysician.practitioner.lastName}`
            : null,
          width: '100%',
        })}
      </RowInfoWrapper>
      <RowInfoWrapper>
        {renderInfoCol({
          header: CurrentAppConfig.ReferringPhysicians.generalPractitioner,
          subtitle: primaryPhysician?.practitioner
            ? `${primaryPhysician.practitioner.firstName} ${primaryPhysician.practitioner.lastName}`
            : null,
        })}
        {primaryPhysician?.practitioner?.phone &&
          renderInfoCol({
            subtitle: `Contact number: ${primaryPhysician.practitioner.phone}`,
          })}
      </RowInfoWrapper>
      {referringSurgeon.length > 0 ? (
        referringSurgeon.map((item: any, index: number) => (
          <RowInfoWrapper key={`${item.practitioner.firstName}-${index}`} data-testid={`referringPhysician-${index}`}>
            {renderInfoCol({
              header:
                CurrentAppConfig.ReferringPhysicians[
                  item.relationshipType as keyof typeof CurrentAppConfig.ReferringPhysicians
                ],
              subtitle: `${item.practitioner.firstName} ${item.practitioner.lastName}`,
            })}
            {renderInfoCol({
              subtitle: `${item.practitioner.phone && `Contact number: ${item.practitioner.phone}`}`,
            })}
          </RowInfoWrapper>
        ))
      ) : (
        <RowInfoWrapper>
          {renderInfoCol({
            header: 'Referring Physician',
          })}
        </RowInfoWrapper>
      )}
    </TabContainer>
  );

  const MedicareInfo = () => (
    <>
      <RowInfoWrapper>
        {renderInfoCol({
          header: 'Medicare card number/IRN',
          subtitle: medicareNumber && medicareIrn && `${medicareNumber} ${medicareIrn}`,
        })}
        {renderInfoCol({
          header: 'Medicare card expiry',
          subtitle: medicareMonth && medicareYear && `${medicareMonth}/${medicareYear.slice(-2)}`,
        })}
      </RowInfoWrapper>
      <StyledHr />
    </>
  );

  const InsuranceTab = () => (
    <TabContainer>
      {region === Region.AU && MedicareInfo()}
      {insurances?.map((insurance: any) => (
        <>
          <RowInfoWrapper>
            {renderInfoCol({
              subtitle: insurance.payor?.name || '-',
            })}
          </RowInfoWrapper>
          <RowInfoWrapper>
            {renderInfoCol({
              header: 'Insurance policy number',
              subtitle: insurance.policyNumber || '-',
            })}
            {renderInfoCol({
              header: 'Expiration date',
              subtitle: insurance.expiryDate ? moment(new Date(insurance.expiryDate)).format('L') : '-',
            })}
          </RowInfoWrapper>
        </>
      ))}
    </TabContainer>
  );

  const EmergencyContactTab = () => (
    <TabContainer>
      <RowInfoWrapper>
        {emergencyContact ? (
          <>
            {renderInfoCol({
              header: emergencyContact?.relationship || '-',
              subtitle: `${emergencyContact?.firstName} ${emergencyContact?.lastName}`,
            })}
            {renderInfoCol({
              subtitle: `Contact number: ${
                emergencyContact?.mobilePhoneNumber || emergencyContact?.homePhoneNumber || '-'
              }`,
            })}
          </>
        ) : (
          <div>No emergency contact assigned</div>
        )}
      </RowInfoWrapper>
    </TabContainer>
  );

  const tabOptions = [
    {
      text: 'Appointments',
      component: <AppointmentsTab />,
      hide: false,
    },
    {
      text: 'Treating physicians',
      component: <TreatingPhysiciansTab />,
      hide: false,
    },
    {
      text: 'Insurance',
      component: <InsuranceTab />,
      hide: false,
    },
    {
      text: 'Emergency contact',
      component: <EmergencyContactTab />,
      false: false,
    },
  ];
  const regionalTabObtions = tabOptions.filter((option) => !option.hide);
  return (
    <OtherInfoWrapper>
      <TabSelectHeader
        title={'Other Information'}
        options={regionalTabObtions}
        onClickHandler={() => {}}
        activeIndex={activeIndex}
        setActiveIndex={setActiveIndex}
      />
      <ContentContainer>{regionalTabObtions[activeIndex].component}</ContentContainer>
    </OtherInfoWrapper>
  );
};

export default OtherInformation;
