import React from 'react';

export interface RegistrationContextType {
  addressPlaceId: string;
  setAddressPlaceId: (addressPlaceId: string) => void;
  registrationSummaryVisited: boolean;
  setRegistrationSummaryVisited: (registrationSummaryVisited: boolean) => void;
  haSummaryVisited: boolean;
  setHaSummaryVisited: (haSummaryVisited: boolean) => void;
  gpLookupUsed: boolean;
  setGpLookupUsed: (lookupUsed: boolean) => void;
  referrerLookupUsed: boolean;
  setReferrerLookupUsed: (lookupUsed: boolean) => void;
}

const initialRegistrationContext = {
  addressPlaceId: '',
  setAddressPlaceId: (_addressPlaceId: string): void => {},
  registrationSummaryVisited: false,
  setRegistrationSummaryVisited: (_registrationSummaryVisited: boolean): void => {},
  haSummaryVisited: false,
  setHaSummaryVisited: (_haSummaryVisited: boolean): void => {},
  gpLookupUsed: false,
  setGpLookupUsed: (_lookupUsed: boolean): void => {},
  referrerLookupUsed: false,
  setReferrerLookupUsed: (_lookupUsed: boolean): void => {},
};

const RegistrationContext = React.createContext<RegistrationContextType>(initialRegistrationContext);

export default RegistrationContext;
