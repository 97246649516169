import React from 'react';
import { useMutation } from '@apollo/client';
import { styled } from '@mui/system';
import { Checkbox } from 'shared-components/components/FormFields';
import { ACKNOWLEDGMENT } from './constants';
import { UPDATE_ACKNOWLEDGMENT } from './queries';
import { PATIENT_AND_USER_DETAILS_QUERY } from '../OP/RegistrationNavigator/queries';

export interface AcknowledgmentProps {
  patientId: string;
  attachmentAcknowledged: boolean | undefined;
}

const Heading = styled('div')`
  font-weight: bold;
  font-size: 18px;
  line-height: 28px;
  margin: 8px 0px;
`;

const Acknowledgment = ({ patientId, attachmentAcknowledged }: AcknowledgmentProps): JSX.Element => {
  const [updateAcknowledgmentMutation] = useMutation(UPDATE_ACKNOWLEDGMENT, {
    awaitRefetchQueries: true,
    refetchQueries: [{ query: PATIENT_AND_USER_DETAILS_QUERY, variables: { id: patientId } }],
  });

  const updateAcknowledgment = (isChecked: boolean) =>
    updateAcknowledgmentMutation({ variables: { id: patientId, attachmentAcknowledged: isChecked } });

  return (
    <>
      <Heading>{ACKNOWLEDGMENT.HEADING}</Heading>
      <Checkbox
        inputName="attachmentAcknowledged"
        inputLabel={ACKNOWLEDGMENT.INPUT_LABEL}
        isChecked={attachmentAcknowledged ? true : false}
        onChange={(isChecked) => updateAcknowledgment(isChecked)}
      />
    </>
  );
};

export default Acknowledgment;
