import { MockedResponse } from '@apollo/client/testing';
import { gql } from '@apollo/client';
import { REGISTRATION_ADDRESS_QUERY } from 'op-pages/OP/RegistrationFormUS/Address/AddressQueries';
import { REGISTRATION_ADDRESS_QUERY as AU_QUERY } from 'op-pages/OP/RegistrationFormAU/Address/AddressQueries';
import { UPDATE_PATIENT_ADDRESS } from 'op-pages/OP/RegistrationFormUS/RegistrationFormQueries';

const COUNTRY_LIST = gql`
  query CountryList {
    data: listData(category: "countryOfBirth") {
      id
      name
    }
  }
`;

const AUSTRALIA_STATES_LIST = gql`
  query AustraliaStatesList {
    data: listData(category: "australiaStates") {
      id
      name
    }
  }
`;

export const countriesMock: MockedResponse = {
  request: {
    query: COUNTRY_LIST,
  },
  result: {
    data: {
      data: [
        {
          id: 1,
          name: 'Australia',
          __typename: 'ListDataType',
        },
        {
          id: 2,
          name: 'United States of America',
          __typename: 'ListDataType',
        },
        {
          id: 3,
          name: 'England',
          __typename: 'ListDataType',
        },
      ],
    },
  },
};

export const ausStatesMock: MockedResponse = {
  request: {
    query: AUSTRALIA_STATES_LIST,
  },
  result: {
    data: {
      data: [
        {
          id: 1,
          name: 'NSW',
          __typename: 'ListDataType',
        },
        {
          id: 2,
          name: 'VIC',
          __typename: 'ListDataType',
        },
        {
          id: 3,
          name: 'QLD',
          __typename: 'ListDataType',
        },
      ],
    },
  },
};

export const regisrationAddressAuQueryMock: MockedResponse = {
  request: {
    query: AU_QUERY,
    variables: { id: '1' },
  },
  result: {
    data: {
      patient: {
        id: '1',
        lastVisitedSection: null,
        regFormStatus: 'filterInProgress',
        address: {
          id: '377',
          formattedAddress: '12 Boston St, Seattle Washington, United States of America, 98109-2319',
          line1: '12 Boston St',
          line2: '',
          city: 'Seattle',
          state: 'Washington',
          country: 'United States of America',
          postcode: '98109-2319',
          __typename: 'AddressType',
        },
        postalAddressSameAsResidential: null,
        __typename: 'PatientType',
      },
      user: {
        id: '1',
        isPso: false,
        __typename: 'UserType',
      },
    },
  },
};

export const regisrationAddressQueryMock: MockedResponse = {
  request: {
    query: REGISTRATION_ADDRESS_QUERY,
    variables: { id: '1' },
  },
  result: {
    data: {
      patient: {
        id: '1',
        lastVisitedSection: null,
        regFormStatus: 'filterInProgress',
        address: {
          id: '377',
          formattedAddress: '12 Boston St, Seattle Washington, United States of America, 98109-2319',
          line1: '12 Boston St',
          line2: '',
          city: 'Seattle',
          state: 'Washington',
          country: 'United States of America',
          postcode: '98109-2319',
          __typename: 'AddressType',
        },
        facilityAddress: null,
        inHospitalHospiceFacility: null,
        facilityName: '',
        facilityContactNumber: '',
        __typename: 'PatientType',
      },
      user: {
        id: '1',
        isPso: false,
        __typename: 'UserType',
      },
    },
  },
};

export const addressMutateMock: MockedResponse = {
  request: {
    query: UPDATE_PATIENT_ADDRESS,
    variables: {
      patientId: '1',
      relatedName: 'address',
      line1: '12 Boston St',
      line2: '',
      city: 'Seattle',
      postcode: '98109-2319',
      state: 'Washington',
      country: 'United States of America',
    },
  },
  result: {
    data: {
      updatePatientAddress: {
        address: {
          id: '382',
          formattedAddress: '12 Boston St, unit 10, Seattle, Washington, 98109-2319',
          line1: '12 Boston St',
          line2: '',
          city: 'Seattle',
          state: 'Washington',
          country: 'United States of America',
          postcode: '98109-2319',
          __typename: 'AddressType',
        },
        __typename: 'UpdatePatientAddress',
      },
    },
  },
};

export const invalidAddressMutateMock: MockedResponse = {
  request: {
    query: UPDATE_PATIENT_ADDRESS,
    variables: {
      patientId: '1',
      relatedName: 'address',
      line1: '',
      line2: '',
      city: '',
      postcode: '',
      state: '',
      country: '',
    },
  },
  result: {
    data: {
      updatePatientAddress: {
        address: {
          id: '',
          formattedAddress: '',
          line1: '',
          line2: '',
          city: '',
          state: '',
          country: '',
          postcode: '',
          __typename: 'AddressType',
        },
        __typename: 'UpdatePatientAddress',
      },
    },
  },
};

export const mockUpdatePatientAddressEmpty: MockedResponse = {
  request: {
    query: UPDATE_PATIENT_ADDRESS,
    variables: {
      patientId: '1',
      relatedName: 'address',
      line1: '',
      line2: '',
      city: '',
      postcode: '',
      state: '',
      country: '',
    },
  },
  result: {
    data: {
      updatePatientAddress: {
        address: {
          id: '1',
          formattedAddress: '',
          line1: 'line1',
          line2: '',
          city: 'Seattle',
          state: 'Washington',
          country: 'United States of America',
          postcode: '98109-2319',
        },
      },
    },
  },
};

export const mockUpdatePatientAddress: MockedResponse = {
  request: {
    query: UPDATE_PATIENT_ADDRESS,
    variables: {
      patientId: 1,
      relatedName: 'address',
      line1: '12 Boston St',
      line2: '',
      city: 'Seattle',
      postcode: '98109-2319',
      state: 'Washington',
      country: 'United States of America',
    },
  },
  result: {
    data: {
      updatePatientAddress: {
        address: {
          id: '1',
          formattedAddress: '',
          line1: 'line1',
          line2: '',
          city: 'Seattle',
          state: 'Washington',
          country: 'United States of America',
          postcode: '98109-2319',
        },
      },
    },
  },
};
