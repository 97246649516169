// eslint-disable-next-line no-use-before-define
import { Form, Formik, FormikProps, FormikValues } from 'formik';
import { FormContainer } from 'op-components';
import { mapListData } from 'op-utils';
import { RegistrationContext } from 'op-contexts';
import React, { useContext } from 'react';
import { useHistory } from 'react-router-dom';
import { ListData } from 'shared-components/interfaces';
import {
  CONTINUE_BUTTON_TEXT,
  getDisplayValue,
  HeaderSubTitle,
  HeaderTitle,
  registrationPath,
  RETURN_TO_SUBMIT_TEXT,
  sharedFormContainerProps,
  standardField,
  isFormValid,
  DateField,
} from '../Helper';
import { FormPatientBasicRego, RegistrationFormUSIProps } from '../interfaces';
import { BASIC_FIELDS } from './constants';
import { generateValidationSchema } from './validation';
import './Basic.scss';

interface Props extends RegistrationFormUSIProps {
  patient: FormPatientBasicRego;
  readonly refData: {
    readonly genderRefData: ListData[];
    readonly genderIdentificationRefData: ListData[];
    readonly titleReferenceData: ListData[];
  };
}

const BasicForm = (props: Props): JSX.Element => {
  const {
    updateField,
    patient: savedPatient,
    handleShowSaveExitConfirm,
    refData: { titleReferenceData, genderRefData, genderIdentificationRefData },
  } = props;

  const registrationContext = useContext(RegistrationContext);
  const patient = {
    ...savedPatient,
    namePrefix: getDisplayValue(titleReferenceData, savedPatient.namePrefix),
    gender: getDisplayValue(genderRefData, savedPatient.gender),
    genderIdentification: getDisplayValue(genderIdentificationRefData, savedPatient.genderIdentification),
  };
  const goToSummary = registrationContext?.registrationSummaryVisited;

  const history = useHistory();

  return (
    <Formik
      initialValues={patient}
      validate={(values: FormikValues) => generateValidationSchema(values)}
      validateOnChange
      validateOnBlur
      onSubmit={() => history.push(registrationPath(patient?.id, goToSummary ? 'summary' : 'contact'))}>
      {({ errors, submitForm, values, setFieldTouched, setFieldValue, touched }: FormikProps<FormPatientBasicRego>) => {
        const sharedProps = sharedFormContainerProps(
          BASIC_FIELDS.FORM_HEADING.TITLE,
          2,
          registrationPath(patient.id, 'infonotice'),
        );
        const formContainerProps = {
          ...sharedProps,
          submitButtonText: goToSummary ? RETURN_TO_SUBMIT_TEXT : CONTINUE_BUTTON_TEXT,
          continueDisabled: isFormValid(errors, touched),
          handleShowSaveExitConfirm,
        };
        const dobValid = !(errors.dob && touched.dob);

        const updateGenderIdentificationField = (field: string, value: string | boolean | number) => {
          if (field === 'genderIdentification' && value !== 'Different Term') {
            setFieldValue('genderIdentificationOther', null, true);
          }
          updateField(field, value);
        };
        return (
          <FormContainer
            {...formContainerProps}
            submitForm={submitForm}
            handleValidation={(event: React.MouseEvent<HTMLInputElement>): void => {
              void event;
            }}>
            <Form style={{ paddingBottom: '50%' }}>
              <HeaderTitle data-test-id="rego-page-title">{BASIC_FIELDS.FORM_HEADING.TITLE}</HeaderTitle>
              <HeaderSubTitle>* = required field</HeaderSubTitle>
              {standardField(BASIC_FIELDS.TITLE_PREFIX, updateField, mapListData(titleReferenceData))}
              {standardField(BASIC_FIELDS.FIRST_NAME, updateField)}
              {standardField(BASIC_FIELDS.MIDDLE_NAME, updateField)}
              {standardField(BASIC_FIELDS.LAST_NAME, updateField)}
              {standardField(BASIC_FIELDS.PREFERRED_NAME, updateField)}
              {standardField(BASIC_FIELDS.GENDER, updateField, mapListData(genderRefData))}
              {standardField(
                BASIC_FIELDS.GENDER_IDENTIFICATION,
                updateGenderIdentificationField,
                mapListData(genderIdentificationRefData),
              )}
              {patient.genderIdentification === 'Different Term' &&
                standardField(BASIC_FIELDS.SPECIFY_GENDER, updateField)}
              {DateField(
                BASIC_FIELDS.DOB_RAW,
                values?.dob,
                dobValid,
                updateField,
                setFieldTouched,
                setFieldValue,
                true,
              )}
            </Form>
          </FormContainer>
        );
      }}
    </Formik>
  );
};

export default BasicForm;
