export const ADDITIONAL_OAR_INFO = 'Additional_OAR_Info';
export const SELECTED_TEMPLATE = 'Selected_Template';
export const OAR = 'OAR';

export interface ConstraintData {
  firstValue: number;
  firstValueUnit: string;
  operator: string;
  secondValue: number[];
  secondValueUnit: string;
  isVolumeData: boolean;
}
