import React from 'react';
import { styled } from '@mui/system';
import { yesNoOptions } from 'op-utils';
import { sharedContent } from '../../../shared/constants';
import { ReviewBox } from 'shared-components/components';
import { Question, Answer, QuestionContainer } from './ReviewSharedComponents';

interface Props {
  fields: any;
  values: any;
  error: boolean;
  link: string;
  allowedLocations: any;
  isLocked: boolean;
}

const convertValueToLabel = (value: any, fieldName?: string, arrayLabel?: any, locationLabel?: any) => {
  for (const option of arrayLabel) {
    if (option.value === value) return option.label;
  }
  if (fieldName === 'nurseLocation') {
    return locationLabel.filter((location: any) => location.id === value.toString())[0]['name'];
  }
  if (fieldName === 'patientHeight' && value) return `${value}cm`;
  if (fieldName === 'patientWeight' && value) return `${value}kg`;
};

const renderQuestionRow = (item: string, fields: any, values: any, index: number, locationList?: any): JSX.Element => {
  return (
    <QuestionContainer aria-label={`review-${fields[item]}`} key={index}>
      <Question value={values[item]}>{fields[item]}</Question>
      <Answer value={values[item]}>
        {!['', null].includes(values[item])
          ? convertValueToLabel(values[item], item, yesNoOptions, locationList)
          : sharedContent.generic.label.notProvided}
      </Answer>
    </QuestionContainer>
  );
};
const ReviewBasicInfo = (props: Props): JSX.Element => {
  const { fields, values, error, link, allowedLocations, isLocked } = props;

  return (
    <ReviewBox title={'Basic Information'} error={error} link={link} isLocked={isLocked}>
      {Object.keys(fields).map((item: any, index: number) => {
        return renderQuestionRow(item, fields, values, index, allowedLocations);
      })}
    </ReviewBox>
  );
};

export default ReviewBasicInfo;
