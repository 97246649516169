import classNames from 'classnames';
import { NavItemType } from 'op-pages/PX/models';
import { pxNavList } from 'op-pages/PX/utils';
import { useMemo } from 'react';

import { useHistory, useLocation } from 'react-router-dom';
import './HeaderNavigation.scss';

const HeaderNavigation = (): JSX.Element => {
  const componentId = 'header-navigation';

  const location = useLocation();
  const history = useHistory();
  const pathname = useMemo<string>(() => location.pathname, [location]);

  const handleNavClick = (url: string): void => history.push(url);

  return (
    <div id={componentId} style={{ display: 'flex', justifyContent: 'flex-end' }}>
      {pxNavList.map((navItem: NavItemType) => {
        const urlComponent = navItem.url;
        const selected = pathname.includes(urlComponent);

        return (
          <div
            key={`${componentId}-${navItem.title}`}
            onClick={(): void => {
              if (!selected || urlComponent === '/px/information') handleNavClick(navItem.url);
            }}
            className="nav-item text-nowrap">
            <span className={classNames('link-text', { selected })} data-testid={navItem.dataTestId}>
              {navItem.title}
            </span>
          </div>
        );
      })}
    </div>
  );
};

export default HeaderNavigation;
