export const categoryFieldNames = {
  basicDetails: {
    categoryLabel: 'Basic Details',
    name_prefix: {
      label: 'Title',
    },
    first_name: {
      label: 'First name',
    },
    middle_name: {
      label: 'Middle name',
    },
    last_name: {
      label: 'Last name',
    },
    preferred_name: {
      label: 'Preferred name',
    },
    gender: {
      label: 'What was your sex recorded at birth',
    },
    gender_identification: {
      label: 'How would you describe your gender',
    },
    gender_identification_other: {
      label: 'Gender description specify',
    },
  },
  contactDetails: {
    categoryLabel: 'Contact Details',
    primary_phone: {
      label: 'Mobile phone number',
    },
    secondary_phone: {
      label: 'Landline phone number',
    },
    email: {
      label: 'Email',
    },
  },
  emergencyContact: {
    categoryLabel: ' Emergency contact',
    first_name: {
      label: 'First name',
    },
    last_name: {
      label: 'Last name',
    },
    relationship: {
      label: 'Relationship',
    },
    mobile_phone_number: {
      label: 'Mobile phone number',
    },
    home_phone_number: {
      label: 'Landline phone number',
    },
    email: {
      label: 'Email',
    },
  },
  healthMedicareDva: {
    categoryLabel: 'Medicare / heath fund',
    health_medicare_number: {
      label: 'Medicare number',
    },
    health_medicare_irn: {
      label: 'Medicare IRN',
    },
    health_medicare_expiry: {
      label: 'Medicare expiry',
      formatAs: 'dayMonth',
    },
    health_dva_number: {
      label: 'DVA number',
    },
    health_dva_expiry: {
      label: 'Health DVA expiry',
      formatAs: 'dayMonth',
    },
  },
  demographics: {
    categoryLabel: 'Demographics',
    country_of_birth: {
      label: 'Country of birth',
    },
    occupation: {
      label: 'Occupation',
    },
    heritage: {
      label: 'Are you of Aboriginal or Torres Strait Islander',
    },
    marital_status: {
      label: 'Marital status',
    },
  },
};
