import { Button, ButtonProps } from '@mui/material';
import { LoadingButton, LoadingButtonProps } from '@mui/lab';

/*
  This is here until we properly refactor all the buttons, then
  these can be set at the top level theme
*/

export const DialogButton = ({ sx, children, variant = 'text', ...rest }: ButtonProps) => {
  return (
    <Button
      sx={{
        ...{
          textTransform: 'uppercase',
          // Subtitle 1 Typography
          fontWeight: 500,
          fontSize: '16px',
        },
        ...sx,
      }}
      variant={variant}
      {...rest}>
      {children}
    </Button>
  );
};

export const DialogLoadingButton = ({ sx, children, ...rest }: LoadingButtonProps) => {
  return (
    <LoadingButton
      sx={{
        ...{
          textTransform: 'uppercase',
          // Subtitle 1 Typography
          fontWeight: 500,
          fontSize: '16px',
        },
        ...sx,
      }}
      {...rest}>
      {children}
    </LoadingButton>
  );
};
