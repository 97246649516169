// eslint-disable-next-line no-use-before-define
import { useMutation, useQuery } from '@apollo/client';
import React from 'react';
import { useParams } from 'react-router-dom';
import { FormProps } from '../interfaces';
import EmergencyContactForm from './EmergencyContactForm';
import { EMERGENCY_CONTACT_QUERY, UPDATE_EMERGENCY_CONTACT } from './EmergencyContactQueries';

const EmergencyContact = (props: FormProps): JSX.Element => {
  const { exitForm } = props;
  const { patientId: id } = useParams<{ patientId: string }>();

  const [updateRegistration] = useMutation(UPDATE_EMERGENCY_CONTACT);
  const { data, error } = useQuery(EMERGENCY_CONTACT_QUERY, { variables: { id } });

  if (error || !data) return <></>;

  const { relationshipsRefData, patient, user } = data;

  const updateField = (field: string, value: string | number | boolean) => {
    updateRegistration({ variables: { id, [field]: value } });
  };

  return (
    <EmergencyContactForm
      refData={{
        relationshipsRefData,
      }}
      patient={patient}
      updateField={updateField}
      handleShowSaveExitConfirm={() => exitForm(true)}
      isPso={user.isPso}
    />
  );
};

export default EmergencyContact;
