// eslint-disable-next-line no-use-before-define
import React from 'react';
import { styled } from '@mui/system';
import { useHistory } from 'react-router';
import { useMutation, useQuery } from '@apollo/client';
import DocumentUpload from 'op-components/DocumentUpload/DocumentUpload';
import { FileContextProvider } from 'op-contexts/FileContext/FileContext';
import { GET_PATIENT_ATTACHMENTS, SUBMIT_ATTACHMENT } from 'op-components/DocumentUpload/queries';
import { PatientAttachment } from 'op-interfaces/PatientInterfaces';
import User from 'shared-components/interfaces/User';
import { ROPatientContextProvider } from '../PatientSummary/context';
import OuterContainer from '../PatientSummary/OuterContainer';
import { GridContainer } from 'shared-components/components/Grid';
import { Logger } from 'shared-components/utils';
const logger = new Logger('ROPatientForms');

interface Attachment extends PatientAttachment {
  uploader: User;
  staging: boolean;
}

const DocumentsWrapper = styled(GridContainer)`
  position: relative;
  display: flex;
  margin: 0px;
  padding-bottom: 8px;
`;

const StyledOuterContainer = styled(OuterContainer)`
  padding: 0;
`;

interface props {
  patientId: string;
}

const DocumentsUploadPage = (props: props): JSX.Element => {
  const { patientId } = props;
  const history = useHistory();
  const { data } = useQuery(GET_PATIENT_ATTACHMENTS, {
    variables: { id: props.patientId },
  });
  const [submitAttachment] = useMutation(SUBMIT_ATTACHMENT, {
    awaitRefetchQueries: true,
    refetchQueries: [{ query: GET_PATIENT_ATTACHMENTS, variables: { id: patientId } }],
  });

  const userId = data?.user.id;

  // Filter attachments:
  // 1. Those not submitted or submitting + user ID; we only want to show unsubmitted attachments for uploader
  // 2. Non-registration attachments should not be displayed
  const filteredAttachments =
    data?.patient?.attachments?.filter(
      (file: Attachment) =>
        !file.submitted && file.uploader?.id === userId && !file.staging && !file.isRegistrationAttachment,
    ) || [];

  return (
    <FileContextProvider>
      <ROPatientContextProvider>
        <StyledOuterContainer marginBottom="42px">
          <DocumentsWrapper>
            <DocumentUpload
              patientId={patientId}
              userId={userId}
              currentDocuments={filteredAttachments}
              onSubmitDocumentUpload={async (): Promise<boolean> => {
                // Submit documents to Mosaiq
                const success = await submitAttachment({
                  variables: {
                    patientID: props.patientId,
                  },
                }).then((response) => {
                  logger.debug(
                    'submitRegistration',
                    `Response came back: /server/media/${response.data.submitAttachment}`,
                  );
                  return response.data.submitAttachment.success;
                });
                if (success) history.push(`/radiation/patient/${props.patientId}/documents/view`);
                return success;
              }}
              onCancelDocumentUpload={() => {
                history.push(`/radiation/patient/${props.patientId}/documents/view`);
              }}
            />
          </DocumentsWrapper>
        </StyledOuterContainer>
      </ROPatientContextProvider>
    </FileContextProvider>
  );
};

export default DocumentsUploadPage;
