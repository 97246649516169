import { FormikProps, useFormikContext } from 'formik';
// eslint-disable-next-line no-use-before-define
import React, { useContext, useEffect } from 'react';
import { RouteComponentProps, withRouter } from 'react-router-dom';
import { useErrorModalContext } from 'op-contexts';
import { GET_CAREPLAN } from '../Queries';
import ROPatientCarePlanContext from '../ROPatientCarePlanContext';
import { RoCarePlanPages } from 'shared-components/enums';
import { getDiagnosisCode } from '../CarePlanPage';
import { previousURL } from '../TreatmentInfoPage/TreatmentInfoPage';
import { useQuery } from '@apollo/client';
import { CurrentAppConfig } from '../AppConfig';
import { Divider, Stack } from '@mui/material';
import ROStepperSummary from './ROStepperSummary';
import ROStepperSteps, { Step } from './ROStepperSteps';

const {
  DX_LANDING_PG,
  DX_STAGING_PG,
  DX_INTAKE_PG,
  CP_TREATMENT_INFO_PG,
  CP_CAREPLAN_SELECTION_PG,
  CP_SITESETUP_PG,
  TM_SIMULATION_PG,
  TM_PRESCRIPTION_PG,
  TM_VOLUMING_PG,
  TM_SUBMISSION_PG,
  TM_SUBMISSION_PG_US,
  TM_SPECIAL_PHYSICS_CONSULT,
} = RoCarePlanPages;

interface Props extends RouteComponentProps<{ id: string; careplanId: string; diagnosisCodeSet?: any }> {
  diagnosisCodeSet?: any;
}

interface StepObject {
  key: string;
  steps: Step[];
}

const ROPatientDiagnosisSteps = (props: Props) => {
  const { id: patientId, careplanId } = props.match.params;
  const { values }: FormikProps<any> = useFormikContext();
  const { state: careplanState } = useContext(ROPatientCarePlanContext);

  const showSpecialPhysicsConsultPage = CurrentAppConfig.SpecialPhysicsConsultVisible;

  const diagnosis = values?.diagnosis;
  const stepObjects = [
    {
      key: 'dx',
      steps: [
        { idx: 1, text: 'Diagnosis selection', page: DX_LANDING_PG },
        { idx: 2, text: 'Staging', page: DX_STAGING_PG },
        { idx: 3, text: 'Intake information', page: DX_INTAKE_PG },
      ],
    },
    {
      key: 'cp',
      steps: [
        { idx: 4, text: 'Treatment information', page: CP_TREATMENT_INFO_PG },
        { idx: 5, text: 'Careplan selection', page: CP_CAREPLAN_SELECTION_PG },
        { idx: 6, text: 'Site setup', page: CP_SITESETUP_PG },
      ],
    },
    {
      key: 'tx',
      steps: [
        { idx: 7, text: 'Simulation', page: TM_SIMULATION_PG },
        { idx: 8, text: 'Prescription', page: TM_PRESCRIPTION_PG },
        { idx: 9, text: 'Voluming', page: TM_VOLUMING_PG },
        ...(showSpecialPhysicsConsultPage
          ? [{ idx: 10, text: 'Special Physics Consult', page: TM_SPECIAL_PHYSICS_CONSULT }]
          : []),
        {
          idx: showSpecialPhysicsConsultPage ? 11 : 10,
          text: 'Submission',
          page: showSpecialPhysicsConsultPage ? TM_SUBMISSION_PG_US : TM_SUBMISSION_PG,
        },
      ],
    },
  ];

  const diagnosisCodeSet = getDiagnosisCode(diagnosis);
  const { data: careplanData, error: careplanError } = useQuery(GET_CAREPLAN, {
    variables: { id: careplanId },
    skip: careplanId === 'create',
    fetchPolicy: 'cache-and-network',
  });

  const status = careplanData?.careplan?.careplanStatus || 'DRAFT';
  const statusText = status?.replace('_', ' ').toLowerCase();
  const { setError } = useErrorModalContext();

  useEffect((): void => {
    const activePanelElements = document.getElementsByClassName('timeline-item current');
    if (!activePanelElements || !activePanelElements.length) return;
    activePanelElements[0].scrollIntoView({
      behavior: 'smooth',
      block: 'end',
      inline: 'start',
    });
    (activePanelElements[0] as HTMLElement).focus({ preventScroll: true });
  });

  const isExistingDiagnosis =
    !!diagnosis &&
    (!diagnosis?.isPrimaryDiagnosis || !careplanData?.careplan?.isPrimDiagnosisOwner) &&
    status !== 'DRAFT';

  const previousUrl =
    diagnosis && careplanData
      ? previousURL(diagnosis, careplanData?.careplan, patientId, careplanId, careplanState)
      : '';

  const disabled = careplanData?.careplan?.changeType === 'CPOT' && status === 'PRESCRIPTION';

  if (careplanError) return setError();

  return (
    <Stack>
      <ROStepperSummary status={status} statusText={statusText} diagnosisCodeSet={diagnosisCodeSet} />
      {stepObjects.map(
        (stepObject: StepObject, index: number): JSX.Element => (
          <Stack key={index}>
            <Divider sx={{ margin: '8px 0' }} />
            <ROStepperSteps
              careplanId={careplanId}
              patientId={patientId}
              diagnosisId={diagnosis?.id}
              steps={stepObject.steps}
              isExistingDiagnosis={isExistingDiagnosis}
              previousUrl={previousUrl}
              disabled={disabled}
              statusText={statusText}
            />
          </Stack>
        ),
      )}
    </Stack>
  );
};

export default withRouter(ROPatientDiagnosisSteps);
