import React, { useState, Fragment, useEffect } from 'react';
import { useQuery } from '@apollo/client';
import { useRouteMatch } from 'react-router';
import OuterContainer from '../PatientSummary/OuterContainer';
import { styled } from '@mui/system';
import { LabPanelType } from './Interfaces';
import ResultsTable from './ResultsTable';
import DetailTable from './DetailTable';
import { Card } from 'shared-components/components';
import { getRemSize } from 'shared-components/StyledComponents/functions';
import moment from 'moment';
import { GET_PATIENT_LAB_PANELS } from '../PatientSummary/PatientProfileQueries';
import { useWindowSize } from 'shared-components/utils/CustomHooks';
import { useErrorModalContext } from 'op-contexts';
import { ROPatientContextProvider } from '../PatientSummary/context';
import AddNotes from '../Notes/AddNotes';
import { LARGE_DISPLAY_SIZE } from 'shared-components/enums';
import { Grid, Stack, Typography, useTheme } from '@mui/material';
import { FindInPageOutlined as FindInPageOutlinedIcon } from '@mui/icons-material';

const ResultsContainer = styled(Card)`
  height: 100%;
`;

const Date = styled('span')`
  font-size: ${getRemSize(16)};
`;

const NoDataContainer = styled('div')`
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: 50px;
`;

const AddNotesStyled = styled(AddNotes)`
  &&& {
    margin-right: 16px;
  }
`;

const NO_DATA = 'No results to show';

interface ROPatientLabsNavType {
  id: string;
}

const ROPatientLabs = (): JSX.Element => {
  const windowSize = useWindowSize();
  const [activeResult, setActiveResult] = useState<LabPanelType | undefined>();
  const [activeDocument, setActiveDocument] = useState<string | undefined>();
  const isSmallDevice = windowSize.width < LARGE_DISPLAY_SIZE;
  const match = useRouteMatch<ROPatientLabsNavType>();
  const { id } = match.params;
  const { setError } = useErrorModalContext();
  const { data, loading, error } = useQuery(GET_PATIENT_LAB_PANELS, {
    variables: { patientId: id },
  });
  const theme = useTheme();

  useEffect(() => {
    if (error) return setError();
  }, [error]);

  if (loading) return <>Loading...</>;

  return (
    <ROPatientContextProvider>
      <OuterContainer>
        <Grid container sx={{ width: '100%', paddingLeft: '16px' }}>
          <Grid xs={isSmallDevice ? 4 : 3}>
            <Stack sx={{ height: '100%' }}>
              <ResultsContainer>
                <Typography paddingBottom={1} variant="h6" id="patient-summary-header">
                  Lab Results
                </Typography>
                <ResultsTable
                  data={data.labPanels}
                  setActiveResult={setActiveResult}
                  setActiveDocument={setActiveDocument}
                />
              </ResultsContainer>
            </Stack>
          </Grid>
          <Grid xs={isSmallDevice ? 7 : 6} sx={{ paddingLeft: '16px' }}>
            <Card $filled>
              {activeResult && activeResult.observations?.length ? (
                <Fragment>
                  <Stack paddingBottom={1} alignItems="center" justifyContent="space-between" direction="row">
                    <Stack data-testid="labs-results-table-header" direction="row" gap={0.5}>
                      <Typography variant="h6" data-test-id="document-preview-header">
                        {activeResult.panelName || 'No title available'}
                      </Typography>
                      <Typography variant="h6" color={theme.palette.text.secondary}>
                        ({activeResult.status})
                      </Typography>
                    </Stack>
                    <Date>{moment(activeResult.observationDate).utc(false).format('D MMMM YYYY h:mm:ss A')}</Date>
                  </Stack>
                  <DetailTable
                    testId={'labs-results-table'}
                    activeResult={activeResult}
                    activeDocument={activeDocument}
                    setActiveDocument={setActiveDocument}
                  />
                </Fragment>
              ) : (
                <NoDataContainer>
                  <FindInPageOutlinedIcon fontSize="large" />
                  <Typography paddingTop={2} variant="body1">
                    {NO_DATA}
                  </Typography>
                </NoDataContainer>
              )}
            </Card>
          </Grid>
          <Grid xs={isSmallDevice ? 1 : 3} sx={{ width: '100%' }}>
            <AddNotesStyled className="add-notes-styled" />
          </Grid>
        </Grid>
      </OuterContainer>
    </ROPatientContextProvider>
  );
};

export default ROPatientLabs;
