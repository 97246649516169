import { gql } from '@apollo/client';
// Query diagnosis
export const GET_DIAGNOSIS_FROM_CAREPLAN = gql`
  query careplan($careplanId: ID!) {
    careplan(id: $careplanId) {
      id
      careplanStatus
      isPrimDiagnosisOwner
      isMetsDiagnosisOwner
      diagnosis {
        hasTreatmentStarted
        id
        stageType
        stageTCodePathological {
          id
          subCode
          description
          mainCode
        }
        stageNCodePathological {
          id
          subCode
          description
          mainCode
        }
        stageMCodePathological {
          id
          subCode
          description
          mainCode
        }
        stageTCodeClinical {
          id
          subCode
          description
          mainCode
        }
        stageMCodeClinical {
          id
          subCode
          description
          mainCode
        }
        stageNCodeClinical {
          id
          subCode
          description
          mainCode
        }
        morphology {
          id
          code
          displayName
        }
        otherInfo
        laterality
        diagnosisDate
        diagnosisCode {
          id
          diagnosisCode
          diagnosisDescription
          stageNumber
          tumourStream {
            id
            name
          }
        }
        isPrimaryDiagnosis
        draft
        relatedPrimaryDiagnosis {
          id
          stageType
          stageTCodePathological {
            id
            subCode
            description
            mainCode
          }
          stageNCodePathological {
            id
            subCode
            description
            mainCode
          }
          stageMCodePathological {
            id
            subCode
            description
            mainCode
          }
          stageTCodeClinical {
            id
            subCode
            description
            mainCode
          }
          stageMCodeClinical {
            id
            subCode
            description
            mainCode
          }
          stageNCodeClinical {
            id
            subCode
            description
            mainCode
          }
          morphology {
            id
            code
            displayName
          }
          otherInfo
          laterality
          diagnosisDate
          diagnosisCode {
            id
            diagnosisCode
            diagnosisDescription
            stageNumber
            tumourStream {
              id
              name
            }
          }
          isPrimaryDiagnosis
          draft
        }
      }
    }
  }
`;

// Query Careplan Sites
export const GET_CAREPLAN_SITES = gql`
  query careplan {
    templateSites @client {
      treatmentSite
    }
  }
`;

export const GET_PEER_REVIEW = gql`
  query peerReview($careplanId: ID!) {
    peerReview(careplanId: $careplanId) {
      isActive
      credentials
      alwaysTrigger
      notes
      disableNotes
      violations {
        site {
          siteId
          name
        }
        ruleSets {
          rules {
            field
            careplanValue
            peerReviewValue
            operator
          }
        }
      }
    }
  }
`;

export const UPDATE_PEER_REVIEW_NOTES = gql`
  mutation updatePeerReviewNotes($careplanId: ID!, $notes: String!) {
    updatePeerReviewNotes(careplanId: $careplanId, notes: $notes) {
      success
    }
  }
`;
