import * as React from 'react';
import { BaseSelectProps } from '@mui/material/Select';
import { SelectChangeEvent, Checkbox, Select, ListItemText, MenuItem, Stack, useTheme } from '@mui/material';

export interface SelectCheckboxOptionType {
  value: string;
  id: string;
  checked: boolean;
}

interface Props extends BaseSelectProps {
  options: SelectCheckboxOptionType[];
  showSelectAll?: boolean;
  badgeContent?: string;
}

// Need this jank to make it work with old components
export const legacyOnChange = (
  event: SelectChangeEvent<any>,
  options: SelectCheckboxOptionType[],
  updateValues?: (ids: string[], checked: boolean) => void,
  selectAllValue: string = 'selectAll',
) => {
  /* This jank is here until we stop using multiple values in the note types (id, value etc.) */
  const {
    target: { value },
  } = event;
  // Value is an array with the current options and the last option as a string
  const optionValue = value.at(-1);

  // If this is a selectAll then set all the ids, unless they are all set then unset them
  if (optionValue === selectAllValue) {
    if (options.every((option) => option.checked)) {
      updateValues &&
        updateValues(
          options?.map((option) => option.id),
          false,
        );
      return;
    }
    updateValues &&
      updateValues(
        options?.map((option) => option.id),
        true,
      );
    return;
  }

  // Get the id which the upstream jank needs
  const currentOption = options.find((option) => option?.value === optionValue);
  const selectOption = value.slice(0, -1).find((option: SelectCheckboxOptionType) => option?.value === optionValue);
  if (currentOption) {
    updateValues && updateValues([currentOption.id], !selectOption.checked);
  }
};

const Badge = ({ badgeNumber }: { badgeNumber: string }) => {
  const theme = useTheme();
  return (
    <Stack
      alignItems="center"
      justifyContent="center"
      sx={{
        backgroundColor: theme.palette.info.main,
        color: theme.palette.info.contrastText,
        border: `1px solid ${theme.palette.info.main}`,
        borderRadius: '50%',
        width: '18px',
        height: '18px',
        minWidth: '18px',
        minHeight: '18px',
        maxWdth: '18px',
        maxHeight: '18px',
        fontSize: '12px',
        position: 'absolute',
        right: '30px',
        pointerEvents: 'none',
      }}>
      {badgeNumber}
    </Stack>
  );
};

const BaseSelectCheckbox = ({ id, options, showSelectAll = false, placeholder, badgeContent, sx, ...rest }: Props) => {
  const selectAllIsChecked = options.every((option) => option.checked);
  return (
    <Select
      id={id}
      data-testid={`${id}-dropdown`}
      multiple
      value={options}
      renderValue={() => placeholder}
      endAdornment={badgeContent && <Badge badgeNumber={badgeContent} />}
      MenuProps={{
        PaperProps: {
          style: { maxHeight: '300px' },
        },
      }}
      inputProps={{
        sx: { textAlign: 'left', paddingRight: badgeContent ? '40px !important' : '32px' },
      }}
      sx={{
        ...{ height: '38px' },
        ...sx,
      }}
      {...rest}>
      {showSelectAll && (
        <MenuItem key="selectAll" value="selectAll" data-testid={'select-all-option'}>
          <Checkbox checked={selectAllIsChecked} />
          <ListItemText primary="Select All" />
        </MenuItem>
      )}
      {options.map((option: SelectCheckboxOptionType) => {
        const optionValue = option.value;
        const checked = option.checked;
        return (
          <MenuItem key={optionValue} value={optionValue} data-testid={`${optionValue.toLowerCase()}-option`}>
            <Checkbox checked={checked} />
            <ListItemText primary={optionValue} />
          </MenuItem>
        );
      })}
    </Select>
  );
};

export default BaseSelectCheckbox;
