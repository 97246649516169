import React, { useContext } from 'react';
import { styled } from '@mui/system';
import { useFormikContext, Field, FormikProps } from 'formik';

import { FormRow, HelperMessage, UserIcon } from 'shared-components/components';
import { yesNoOptions } from 'op-utils';
import { StyledFieldRow } from 'shared-components/components/FormikComponents';
import {
  ToggleButtonGroupField,
  MultiSelectButtonGroup,
  TextAreaFieldGroup,
} from 'shared-components/components/FormikComponents';

import { preCtChartCheckContent } from '../constants';
import { getLastUpdateUser } from '../../helpers';
import { sharedContent } from '../../shared/constants';
import FormContext from '../contexts';
import {
  PreCtChartCheckData,
  PreCtChartCheckFormValues,
  ImplantedMedicalDevice,
} from '../interfaces/PreCtChartCheckInterfaces';

interface Props {
  assessmentId: any;
  handleMutation: (name: string, value: string) => void;
  preCtChartCheckData: PreCtChartCheckData;
}

interface IHeading {
  invalid?: boolean;
}

const ImplantedDevices = styled('div')`
  margin-top: 60px;
`;

const Heading = styled('div')<IHeading>`
  color: ${(props) => (props.invalid ? props.theme.palette.error.main : props.theme.palette.text.primary)};
  font-size: 1.125rem;
  font-weight: bold;
  margin-bottom: 8px;
`;

const ImplantedMedicalDevices = ({ assessmentId, handleMutation, preCtChartCheckData }: Props): JSX.Element => {
  const { setFieldValue, setFieldTouched, values, touched }: FormikProps<PreCtChartCheckFormValues> =
    useFormikContext();
  const formContext = useContext(FormContext);
  const { createAssessmentDevice, deleteAssessmentDevice, updateAssessmentDevice } = formContext.mutations;
  const otherNotListedValues = values.otherNotListed.filter((item: ImplantedMedicalDevice) => item.name !== '');
  const { subSection, fields, infoHelpers, options, parentField } = preCtChartCheckContent.implantedMedicalDevices;
  const { hasImplantedMedicalDevice, cied, venousAccessType, otherImplantedDevices, otherNotListed } = fields;
  const allDeviceValues = [
    ...values.cied,
    ...values.venousAccessType,
    ...values.otherImplantedDevices,
    ...otherNotListedValues,
  ];
  const deviceFieldsTouched = [
    touched.cied,
    touched.venousAccessType,
    touched.otherImplantedDevices,
    touched.otherNotListed,
  ];
  const sectionIsEmpty =
    values.hasImplantedMedicalDevice === true && allDeviceValues.length === 0 && deviceFieldsTouched.includes(true);
  const { lastUpdateUser: lastUpdateUserData, implantedMedicalDevice: implantedMedicalDeviceData } =
    preCtChartCheckData;

  const createMutation = async (field: string, value: string) => {
    const data = await createAssessmentDevice({ variables: { assessmentId, deviceField: field, name: value } });
    return data;
  };

  const deleteMutation = (id: string) => {
    deleteAssessmentDevice({ variables: { id } });
  };

  const getDevicesLastUpdateUser = (deviceType: string): string => {
    let lastUpdateUser = '';
    const devices = implantedMedicalDeviceData.filter((item) => item.deviceField === deviceType);

    if (devices.length > 0) {
      lastUpdateUser = devices.some((device) => device.lastUpdateUser === 'nurse') ? 'nurse' : 'patient';
    }

    return lastUpdateUser;
  };

  const shouldClearAllDevices = (value: any) => {
    const deviceFields = ['cied', 'venousAccessType', 'otherImplantedDevices', 'otherNotListed'];
    const allDevices = [
      ...values.cied,
      ...values.venousAccessType,
      ...values.otherImplantedDevices,
      ...values.otherNotListed,
    ];
    if (value === false) {
      allDevices.map((item) => {
        return deleteMutation(item.id);
      });
      if (values.otherNotListed) {
        deviceFields.forEach((device) => {
          setFieldValue(device, []);
          setFieldTouched(device, false);
        });
      }
    }
  };

  const handleDevicesMutation = (fieldName: string, value: any) => {
    handleMutation(fieldName, value);
    shouldClearAllDevices(value);
  };

  const ciedLastUpdateUser = getDevicesLastUpdateUser('cied');
  const venousLastUpdateUser = getDevicesLastUpdateUser('venousAccessType');
  const otherDevicesLastUpdateUser = getDevicesLastUpdateUser('otherImplantedDevices');

  return (
    <>
      <FormRow fieldLabel={hasImplantedMedicalDevice} fieldName={'hasImplantedMedicalDevice'}>
        <Field
          name="hasImplantedMedicalDevice"
          component={ToggleButtonGroupField}
          label={hasImplantedMedicalDevice}
          options={yesNoOptions}
          handleMutation={handleDevicesMutation}
          lastUpdateUser={getLastUpdateUser(lastUpdateUserData, 'hasImplantedMedicalDevice')}
        />
      </FormRow>
      {values.hasImplantedMedicalDevice === true && (
        <ImplantedDevices>
          <Heading invalid={sectionIsEmpty}>Implanted Devices</Heading>
          <div>{subSection.summary}</div>
          {sectionIsEmpty === true && (
            <HelperMessage fieldName={'implanted-devices'} fieldText={subSection.validation} />
          )}
          <FormRow fieldLabel={cied} fieldName={'cied'}>
            <StyledFieldRow>
              <Field
                name="cied"
                component={MultiSelectButtonGroup}
                options={options.cied}
                label={cied}
                createMutation={createMutation}
                deleteMutation={deleteMutation}
                parentField={parentField}
              />
              <UserIcon userType={ciedLastUpdateUser} />
            </StyledFieldRow>
            {values.cied.length > 0 && (
              <>
                <HelperMessage fieldName={'cied'} fieldText={infoHelpers.ciedAlert} helperType="info" />
                <HelperMessage fieldName={'cied'} fieldText={infoHelpers.ciedQcl} helperType="info" />
              </>
            )}
          </FormRow>
          <FormRow fieldLabel={venousAccessType} fieldName={'venousAccessType'}>
            <StyledFieldRow>
              <Field
                name="venousAccessType"
                component={MultiSelectButtonGroup}
                options={options.venousAccessType}
                label={venousAccessType}
                createMutation={createMutation}
                deleteMutation={deleteMutation}
                parentField={parentField}
              />
              <UserIcon userType={venousLastUpdateUser} />
            </StyledFieldRow>
            {values.venousAccessType.length > 0 && (
              <HelperMessage
                fieldName={'venousAccessType'}
                fieldText={infoHelpers.venousAccessType}
                helperType="info"
              />
            )}
          </FormRow>
          <FormRow fieldLabel={otherImplantedDevices} fieldName={'otherImplantedDevices'}>
            <StyledFieldRow>
              <Field
                name="otherImplantedDevices"
                component={MultiSelectButtonGroup}
                options={options.otherImplantedDevices}
                label={otherImplantedDevices}
                createMutation={createMutation}
                deleteMutation={deleteMutation}
                parentField={parentField}
              />
              <UserIcon userType={otherDevicesLastUpdateUser} />
            </StyledFieldRow>
            {values.otherImplantedDevices.length > 0 && (
              <HelperMessage
                fieldName={'otherImplantedDevices'}
                fieldText={infoHelpers.otherImplantedDevices}
                helperType="info"
              />
            )}
          </FormRow>
          <FormRow fieldLabel={otherNotListed} fieldName={'otherNotListed'}>
            <TextAreaFieldGroup
              name="otherNotListed"
              deleteMutation={deleteMutation}
              createMutation={createMutation}
              updateMutation={updateAssessmentDevice}
              fieldText={infoHelpers.otherNotListed}
              placeholder={sharedContent.generic.label.specifyValue}
              addButtonText="Add another device"
              helperMessageType="info"
              values={values.otherNotListed}
              parentField={parentField}
              textFieldValue="name"
              textFieldName="deviceField"
              userIconFieldName="lastUpdateUser"
              userTypeValue="nurse"
            />
          </FormRow>
        </ImplantedDevices>
      )}
    </>
  );
};

export default ImplantedMedicalDevices;
