// eslint-disable-next-line no-use-before-define
import React, { useRef, useState, Fragment, useContext, useMemo } from 'react';
import { withApollo, WithApolloClient } from '@apollo/client/react/hoc';
import { RouteComponentProps, withRouter, useLocation } from 'react-router-dom';
import { useMutation, useQuery, gql } from '@apollo/client';
import { sharedContent } from './constants';
import { UX_FEATURE_OPTION_QUERY } from 'op-graphql/queries';
import { getRemSize } from 'shared-components/StyledComponents/functions';
import ROProfilePhoto from 'shared-components/components/ROProfilePhoto/ROProfilePhoto';
import { useOnClickOutside } from 'shared-components/utils/CustomHooks';
import { Logger, navigateToExternalURL } from 'shared-components/utils';
import { NewPatientSummary } from './NewPatientSummary';
import { Features, ThemeContext, UserContext } from 'op-contexts';
import { GCTheme, LumonusTheme } from 'theme';
import { ToggleButtons } from 'shared-components/components/FormFields';
import { Stack, styled, Button, Typography } from '@mui/material';
import {
  PollOutlined as PollOutlinedIcon,
  ExitToApp as ExitToAppIcon,
  AccountCircleOutlined as AccountCircleOutlinedIcon,
  Close as CloseIcon,
  ManageAccounts,
} from '@mui/icons-material';
import { CurrentAppConfig } from 'op-pages/RO/Careplan/AppConfig';
import { StandardDialog } from 'shared-components/components';
import { FeedbackForm } from './FeedbackForm';

const logger = new Logger('LogoutPage');

const LOGOUT_MUTATION = gql`
  mutation Logout {
    logout {
      errors
    }
  }
`;
interface Props extends RouteComponentProps, WithApolloClient<{}> {
  firstName: string;
  lastName: string;
  name: string;
  isRo: boolean;
  forROPortal: boolean;
  ukSide?: boolean;
}

interface MenuLinkItem {
  title: string;
  url: string;
  icon?: JSX.Element;
  isROPortal: boolean;
}

const StyleNavDropdownMenu = styled('div')<{ $dropdownOpen: boolean }>`
  position: absolute;
  background: white;
  top: 60px;
  right: 0;
  flex-direction: column;
  font-weight: bold;
  cursor: default;
  color: ${(props) => props.theme.palette.grey[600]};
  height: calc(100vh - 60px);
  right: 0;
  transform: translateX(101%);
  ${(props) =>
    props.$dropdownOpen &&
    `
      transform: translateX(0);
      transition: 0.3s;
    `};
  min-width: 320px;
  border-left: 1px solid ${(props) => props.theme.palette.grey[300]};
  transition-timing-function: ease-in-out;
  transition: 0.2s;
`;

const StyledDropdownMobileOverlay = styled('div')<{ $dropdownOpen: boolean }>`
  width: 100vw;
  height: 100vh;
  position: absolute;
  right: 0;
  top: 62px;
  visibility: hidden;
  pointer-events: none;
  background: none;

  @media (max-width: 767px) {
    ${(props) =>
      props.$dropdownOpen &&
      `
        visibility: visible;
        background: rgba(46, 46, 46, 0.25);
        pointer-events: auto;
      `};
  }
`;

const StyledDropdownContainerOP = styled('div')`
  padding: 8px 12px;
  margin: -13px 0;
  margin-right: 16px;
  height: 100%;
  cursor: pointer;
  display: flex;
  justify-content: center;
  align-items: center;
  border: none;
  border-bottom: 3px solid transparent;
  background: transparent;
  transition: border-color 0.1s ease-in-out;

  .icon {
    pointer-events: none;
  }

  &:hover,
  &:focus {
    border-color: white;
  }
`;

const StyledH3 = styled('h3')`
  font-style: normal;
  font-weight: bold;
  font-size: ${getRemSize(18)};
  line-height: 1.55em;
  color: ${(props) => props.theme.palette.text.primary};
`;

const StyledDropdownControls = styled('div')`
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 10px 16px 5px;
  background: ${(props) => props.theme.palette.grey[100]};

  svg {
    width: 25px;
    height: 25px;
    padding: 5px;

    path {
      fill: ${(props) => props.theme.palette.primary.main};
    }
  }
`;

const StyledDropdownCloseButton = styled('div')`
  cursor: pointer;
  padding: 0;
  background: none;
  border: none;

  path {
    transition: fill 0.15s ease-in-out;
  }

  &:hover,
  &:focus {
    path {
      fill: ${(props) => props.theme.palette.grey[600]};
    }
  }
`;

const StyledUserInformation = styled('div')`
  display: flex;
  border-bottom: solid 1px ${(props) => props.theme.palette.grey[300]};
  padding-right: 18px;

  svg {
    margin: 16px;
  }
`;

const StyledUserName = styled('div')`
  font-style: normal;
  font-weight: bold;
  font-size: ${getRemSize(18)};
  text-transform: uppercase;
  color: ${(props) => props.theme.palette.text.primary};
  display: flex;
  align-items: center;
  max-width: 225px;
  line-height: 130%;
`;

const StyledMenuLinks = styled('ul')`
  list-style: none;
  margin-left: 0;
`;

const StyledMenuLinkItem = styled('li')``;

const StyledMenuLink = styled('div')`
  border: none;
  background: transparent;
  border-bottom: solid 1px ${(props) => props.theme.palette.grey[300]};
  padding: 15px 18px;
  display: flex;
  align-items: center;
  color: ${(props) => props.theme.palette.text.primary};
  cursor: pointer;
  margin-bottom: 0;
  width: 100%;
  font-weight: bold;
  transition: background-color 0.15s ease-in-out;

  svg {
    margin-right: 10px;
  }

  span {
    font-size: ${getRemSize(14)};
  }

  &:hover,
  &:focus {
    color: ${(props) => props.theme.palette.text.primary};
    text-decoration: none;
    background-color: ${(props) => props.theme.palette.grey[100]};
  }
`;

const StyledMenuLinkIcon = styled('div')`
  svg {
    width: 25px;
    height: 25px;
  }

  path {
    fill: ${(props) => props.theme.palette.primary.main}};
  }
`;

const NavigationDropDown = (props: Props): JSX.Element => {
  const { client, forROPortal = false, name, ukSide } = props;
  const themeContext = useContext(ThemeContext);
  const [openDropDown, setOpenDropDown] = useState(false);
  const [openLogoutModal, setOpenLogoutModal] = useState(false);
  const [isFeedbackFormOpen, setIsFeedbackFormOpen] = useState(false);

  const { hasFeature } = useContext(UserContext);

  const { data: featureOption } = useQuery(UX_FEATURE_OPTION_QUERY, {
    variables: { featureOptionName: 'ShowThemeSwitch' },
  });
  const showThemeSwitch = useMemo(() => featureOption?.uxFeatureOption?.active, [featureOption]);
  const ShowResetDemoDataMenuLink = CurrentAppConfig.ShowResetDemoDataMenuLink;
  const menuLinks: MenuLinkItem[] = [
    {
      title: sharedContent.menuLinks.newSummary,
      url: '/',
      icon: <PollOutlinedIcon color="primary" />,
      isROPortal: forROPortal,
    },
    {
      title: sharedContent.menuLinks.manageProtocols,
      url: (CurrentAppConfig.MenuItems.manageProtocols ? '/sso/carepath_redirect' : '') || '',
      icon: <ManageAccounts color="primary" />,
      isROPortal: forROPortal,
    },
    {
      title: sharedContent.menuLinks.logout,
      url: '/',
      icon: <ExitToAppIcon htmlColor={themeContext?.theme.palette.grey[600]} />,
      isROPortal: forROPortal,
    },
  ];

  const userName = forROPortal ? `Dr. ${name}` : name;

  const dropdownRef = useRef<HTMLDivElement>(null);

  useOnClickOutside(dropdownRef, () => {
    closeDropDown();
    setIsFeedbackFormOpen(false);
  });

  const closeLogoutModal = (): void => {
    setOpenLogoutModal(false);
    setOpenDropDown(false);
  };

  const openDropDownHandler = (): void => {
    if (!openDropDown) setOpenDropDown(true);
  };

  const closeDropDown = (): void => {
    setOpenDropDown(false);
  };

  const feedbackFormHandler = (bool: boolean): void => {
    setIsFeedbackFormOpen(bool);
  };

  const location = useLocation();

  const logout = (): void => {
    if (!client) return;
    client.clearStore().then((): void => {
      client.writeQuery({
        query: gql`
          query {
            contentShown
          }
        `,
        data: {
          contentShown: false,
        },
      });
    });
    client
      .mutate({
        mutation: LOGOUT_MUTATION,
        variables: {},
      })
      .then((response): void => {
        if (response.data.logout.errors !== null) {
          logger.error('logout', 'Unable to logout');
          logger.error('logout', response.data.logout.errors);
          return;
        }
        navigateToExternalURL('/sso/logout');
      });
  };

  const generateMenuLinks = (): JSX.Element[] => {
    return menuLinks
      .filter((menuLink: MenuLinkItem) => menuLink.url)
      .map((menuLink: MenuLinkItem, index: number): JSX.Element => {
        if (menuLink.title === sharedContent.menuLinks.newSummary) {
          return <NewPatientSummary key={index} menuLink={menuLink} index={index} defaultButtonState={'disable'} />;
        }

        if (menuLink.title === sharedContent.menuLinks.feedback && !menuLink.isROPortal) {
          return <Fragment key={menuLink.title}></Fragment>;
        }

        const clickHandler = (): void => {
          if (menuLink.title === sharedContent.menuLinks.feedback) {
            feedbackFormHandler(true);
          } else if (menuLink.title === sharedContent.menuLinks.logout) {
            setOpenLogoutModal(true);
          } else if (menuLink.title === sharedContent.menuLinks.manageProtocols) {
            // eslint-disable-next-line no-restricted-globals
            window.open(menuLink.url, '_blank');
          } else {
            // eslint-disable-next-line no-restricted-globals
            props.history.push({ pathname: menuLink.url, state: { from: location.pathname } });
          }
        };
        if (menuLink.title === sharedContent.menuLinks.newSummary && !hasFeature(Features.PATIENT_SUMMARY))
          return <></>;

        return (
          <StyledMenuLinkItem key={menuLink.title.replace(/\s+/g, '-').toLowerCase()}>
            <StyledMenuLink
              key={index}
              className="nav-drop-down-link"
              data-test-id={`menu-title-${menuLink.title.replace(/\s+/g, '-').toLowerCase()}`}
              role="button"
              tabIndex={0}
              onClick={clickHandler}
              onKeyPress={(e): void => {
                e.key === 'Enter' && clickHandler();
              }}>
              <StyledMenuLinkIcon key={index}>{menuLink.icon}</StyledMenuLinkIcon>
              <span className="nav-drop-down-link-text">{menuLink.title}</span>
            </StyledMenuLink>
          </StyledMenuLinkItem>
        );
      });
  };

  const renderMenuList = (): JSX.Element => {
    return (
      <>
        <StyleNavDropdownMenu $dropdownOpen={openDropDown} id="navigation-dropdown-menu">
          <StyledDropdownControls key="dropdownControls">
            <StyledH3>
              {isFeedbackFormOpen ? sharedContent.generic.label.feedback : sharedContent.generic.label.myAccount}
            </StyledH3>
            <StyledDropdownCloseButton
              tabIndex={0}
              role={'button'}
              id="closeDropdownButton"
              onKeyPress={(e): void => {
                e.key === 'Enter' && closeDropDown();
              }}
              onClick={closeDropDown}>
              <CloseIcon color="primary" />
            </StyledDropdownCloseButton>
          </StyledDropdownControls>
          {userName ? (
            isFeedbackFormOpen ? (
              <FeedbackForm setOpenDropDown={setOpenDropDown} setIsFeedbackFormOpen={setIsFeedbackFormOpen} />
            ) : (
              <>
                <StyledUserInformation>
                  <ROProfilePhoto />
                  <StyledUserName>{userName}</StyledUserName>
                </StyledUserInformation>
                <StyledMenuLinks key="menuLink">{generateMenuLinks()}</StyledMenuLinks>
              </>
            )
          ) : (
            <StyledMenuLinks key="menuLink">{generateMenuLinks()}</StyledMenuLinks>
          )}
          {showThemeSwitch && (
            <Stack sx={{ padding: '8px' }}>
              <ToggleButtons
                id="theme-toggle"
                fullWidth
                options={[
                  { label: 'GC', value: 'GC' },
                  { label: 'Lumonus', value: 'Lumonus' },
                ]}
                value={themeContext?.theme === GCTheme ? 'GC' : 'Lumonus'}
                handleChange={(value: string): void => {
                  if (value === 'GC') {
                    themeContext?.setTheme(GCTheme);
                  } else if (value === 'Lumonus') {
                    themeContext?.setTheme(LumonusTheme);
                  }
                }}
              />
            </Stack>
          )}
          {ShowResetDemoDataMenuLink && (
            <Stack sx={{ padding: '8px' }}>
              <Button
                onClick={() => {
                  navigateToExternalURL('/server/demo/reset-demo-data/');
                }}>
                Reset Demo Data
              </Button>
            </Stack>
          )}
        </StyleNavDropdownMenu>
        <StyledDropdownMobileOverlay
          key="mobileOverlay"
          $dropdownOpen={openDropDown}
          className="nav-drop-down-mobile-overlay"
          onClick={() => {
            // Need this empty onclick for Safari to work correctly
          }}
        />
      </>
    );
  };

  return (
    <>
      <StyledDropdownContainerOP
        ref={dropdownRef}
        role="presentation"
        id="dropdown-nav-link"
        tabIndex={0}
        onKeyPress={(e) => {
          e.key === 'Enter' && openDropDownHandler();
        }}
        onClick={openDropDownHandler}>
        {forROPortal ? <ROProfilePhoto inHeader={!ukSide} /> : <AccountCircleOutlinedIcon htmlColor="white" />}
        {renderMenuList()}
      </StyledDropdownContainerOP>
      <StandardDialog
        id="logoutModal"
        title="Logout"
        open={openLogoutModal}
        onClose={closeLogoutModal}
        submitText="Logout"
        closeText="Cancel"
        onSubmit={() => {
          logout();
          closeLogoutModal();
        }}>
        <Typography paddingY={2}>Are you sure you want to logout?</Typography>
      </StandardDialog>
    </>
  );
};

const routedComponent = withRouter(withApollo<Props>(NavigationDropDown));
export default routedComponent;
