import React from 'react';
import { styled } from '@mui/system';
import { getRemSize } from 'shared-components/StyledComponents/functions';

interface valueResponse {
  value?: any;
}

export const Question = styled('div')<valueResponse>`
  width: 50%;
  height: 40px;
  font-size: ${getRemSize(14)};
  line-height: 20px;
  display: flex;
  align-items: center;
  color: ${(props) =>
    props.value !== null && props.value !== ''
      ? `${props.theme.palette.text.primary}`
      : `${props.theme.palette.error.main}`};
`;

export const Answer = styled('div')<valueResponse>`
  width: 50%;
  font-weight: bold;
  font-size: ${getRemSize(14)};
  line-height: 20px;
  display: flex;
  align-items: center;
  color: ${(props) =>
    props.value !== null && props.value !== ''
      ? `${props.theme.palette.text.primary}`
      : `${props.theme.palette.error.main}`};
`;

export const QuestionContainer = styled('div')`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  margin-left: 27px;
`;
