import { useQuery } from '@apollo/client';
import React, { useEffect, useState } from 'react';
import { useRouteMatch } from 'react-router-dom';
import { LoadingSpinner } from 'shared-components/components';
import { DATATYPE } from 'shared-components/components/Table/Table';
import useOncologyListData from '../Careplan/useOncologyListData';
import { CAREPLAN_STATUS, TABLE_KEYS, TABLE_LABELS } from './Constants';
import { GET_PATIENT_CAREPLANS } from './PatientSummaryQueries';
import { DropDownObject, PatientCareplans } from './interfaces';
import PatientCareplanTable from './PatientCareplanTable';

const createOptions = (options: string[]): DropDownObject[] => {
  return options.map((option, index) => {
    return { id: index.toString(), name: option, checked: true };
  });
};
const statusOptions = createOptions(Object.values(CAREPLAN_STATUS));

const PatientCareplanTableApollo = (): JSX.Element => {
  const match = useRouteMatch<any>();
  const { id: patientId } = match.params;
  const { data, loading } = useQuery(GET_PATIENT_CAREPLANS, {
    variables: { patientId },
    fetchPolicy: 'cache-and-network',
  });
  const referenceData = useOncologyListData(['treatingDepartment']);
  const [statusFilterOptions, setStatusFilterOptions] = useState(statusOptions);
  const [treatingDepartmentOptions, setTreatingDepartmentOptions] = useState<DropDownObject[]>([]);
  const [careplans, setCareplans] = useState<PatientCareplans[]>([]);
  const headers = [
    {
      title: TABLE_LABELS.TREATING_DEPARTMENT,
      key: TABLE_KEYS.TREATING_DEPARTMENT,
      type: DATATYPE.STRING,
      filterOptions: treatingDepartmentOptions,
      onFilterChange: (ids: string[], checked: boolean) => {
        setTreatingDepartmentOptions((treatingDepartmentFilter) =>
          treatingDepartmentFilter?.map((filter) => {
            return { ...filter, checked: ids.includes(filter.id) ? checked : filter.checked };
          }),
        );
      },
      dropdownTitle: `${TABLE_LABELS.TREATING_DEPARTMENT}${
        treatingDepartmentOptions.every((item) => item.checked) ? ' (All)' : ''
      }`,
      width: '15.2%',
    },
    {
      title: TABLE_LABELS.CAREPLAN,
      key: TABLE_KEYS.CAREPLAN,
      type: DATATYPE.STRING,
      width: '24.9%',
      dropdownTitle: TABLE_LABELS.CAREPLAN,
    },
    {
      title: TABLE_LABELS.TREATMENT_START_DATE,
      key: TABLE_KEYS.TREATMENT_START_DATE,
      type: DATATYPE.DATE,
      width: '11.8%',
    },
    {
      title: TABLE_LABELS.TREATMENT_END_DATE,
      key: TABLE_KEYS.TREATMENT_END_DATE,
      type: DATATYPE.DATE,
      width: '11.8%',
    },
    {
      title: TABLE_LABELS.CURRENT_CYCLE,
      key: TABLE_KEYS.CURRENT_CYCLE,
      type: DATATYPE.STRING,
      width: '8.6%',
    },
    {
      title: TABLE_LABELS.NEXT_TREATMENT,
      key: TABLE_KEYS.NEXT_TREATMENT,
      type: DATATYPE.STRING,
      width: '8.6%',
    },
    {
      title: TABLE_LABELS.STATUS,
      key: TABLE_KEYS.STATUS,
      type: DATATYPE.STRING,
      width: '9.1%',
      filterOptions: statusFilterOptions,
      onFilterChange: (ids: string[], checked: boolean) => {
        setStatusFilterOptions((statusFilter) =>
          statusFilter.map((filter) => {
            return { ...filter, checked: ids.includes(filter.id) ? checked : filter.checked };
          }),
        );
      },
      dropdownTitle: `${TABLE_LABELS.STATUS}${statusFilterOptions.every((item) => item.checked) ? ' (All)' : ''}`,
    },
    {
      title: TABLE_LABELS.LAST_MODIFIED,
      key: TABLE_KEYS.LAST_MODIFIED,
      type: DATATYPE.DATE,
      width: '10%',
    },
  ];

  useEffect(() => {
    // Filter the data shown in table
    if (data) {
      const statusList = statusFilterOptions.filter((status) => status.checked).map((status) => status.name);
      const treatingDepartmentList = treatingDepartmentOptions
        ?.filter((treatingDepartment) => treatingDepartment.checked)
        .map((department) => department.name);
      const fullTreatingDepartmentList = treatingDepartmentOptions.map((department) => department.name);
      const tmpCareplans = data.moPatientCareplans.filter(
        (careplan: PatientCareplans) =>
          statusList.includes(careplan.status) &&
          (!treatingDepartmentList ||
            treatingDepartmentList.includes(careplan.treatingDepartment) ||
            careplan.treatingDepartment === null ||
            careplan.treatingDepartment === '' ||
            !fullTreatingDepartmentList.includes(careplan.treatingDepartment)),
      );
      setCareplans(tmpCareplans);
    }
  }, [data, statusFilterOptions, treatingDepartmentOptions]);
  useEffect(() => {
    if (referenceData.treatingDepartment) {
      setTreatingDepartmentOptions(createOptions(referenceData.treatingDepartment.map((option) => option.option)));
    }
  }, [referenceData]);
  if (loading && !referenceData) return <LoadingSpinner />;
  return <PatientCareplanTable careplans={careplans} headers={headers} />;
};

export default PatientCareplanTableApollo;
